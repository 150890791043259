import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import axios from 'axios';
import {
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  LoadingContainer,
  LoadingText,
  StyledTable,
  TableHeadStyled,
  TableRowStyled,
  WrappedContainer,
} from './style';
import * as XLSX from 'xlsx';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import {
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseButton,
  LoadingIndicator,
} from 'components';

import { IoseAddFilterButton, IoseBackButton } from 'components/IoseButtonIcon';
import { LocalStorage, Permissions } from 'common';
import { useCreateLogs, useGroupPermissions } from 'hooks';

const useStyles = makeStyles((theme) => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#015A9E', // Cor de fundo do cabeçalho
    color: '#fff', // Cor do texto do cabeçalho (amarelo)
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column-reverse', // Botões empilhados verticalmente, mas em ordem inversa
    alignItems: 'flex-end', // Alinha os botões à direita
    marginBottom: theme.spacing(2), // Espaço abaixo do contêiner dos botões
  },
  downloadButton: {
    // Este é o botão que aparece no topo devido ao 'column-reverse'
    marginTop: theme.spacing(2), // Adiciona espaço abaixo deste botão, que efetivamente empurra para cima o botão "Voltar"
  },
  backButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2), // Adiciona um espaço entre os botões, se necessário
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Isso coloca o espaço igual entre os elementos
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4), // Garante espaço na direita
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
  },
  filterDisplay: {
    backgroundColor: '#FFFFFF', // Cor de fundo branca
    border: '1px solid #CCCCCC', // Borda sólida cinza
    padding: theme.spacing(0.5), // Espaçamento interno reduzido
    borderRadius: theme.shape.borderRadius, // Bordas arredondadas do tema
    display: 'flex',
    alignItems: 'center', // Alinhamento vertical dos itens
    justifyContent: 'space-between', // Espaçamento entre os itens
    maxWidth: 'fit-content',
  },
  closeButton: {
    backgroundColor: '#D32F2F', // Fundo vermelho para o botão
    color: '#FFFFFF', // Texto branco
    fontSize: '0.75rem', // Tamanho de fonte reduzido para o texto 'X'
    lineHeight: '1', // Altura da linha ajustada para que o 'X' esteja centralizado
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(2), // Altura do botão
    width: theme.spacing(2), // Largura do botão
    borderRadius: '50%', // Bordas arredondadas para torná-lo circular
    padding: 0, // Sem preenchimento extra
    marginLeft: theme.spacing(1), // Espaço entre o texto e o botão
    '&:hover': {
      backgroundColor: '#F44336', // Cor de fundo mais clara ao passar o mouse
    },
    cursor: 'pointer', // Mãozinha ao passar o mouse para indicar clicabilidade
    border: 'none', // Sem borda
    outline: 'none', // Remove o contorno quando focado (para acessibilidade, considere manter algum foco visível)
  },
  filterButton: {
    marginLeft: '10px', // Espaço entre o texto e o botão
    backgroundColor: 'transparent', // Fundo transparente para o botão
    border: 'none', // Sem borda
    cursor: 'pointer', // Mãozinha ao passar o mouse para indicar clicabilidade
    color: 'white', // Cor branca para o texto 'X' do botão
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  select: {
    backgroundColor: 'white', // Define o fundo como branco
    color: 'black', // Define a cor do texto para preto ou outra cor que desejar
    border: '1px solid #ccc', // Adiciona uma borda cinza clara
    borderRadius: '4px', // Opcional: bordas arredondadas para o Select
    padding: '3px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Opcional: adiciona uma sombra leve
    '&:before': {
      // Remove a linha inferior por padrão do Material-UI
      borderBottom: 'none',
    },
    '&:after': {
      // Remove a linha inferior quando o Select está focado
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      // Remove a linha inferior no hover
      borderBottom: 'none',
    },
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

// Função para formatar datas
const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('pt-BR');
};

const itemsPerPage = 50;

export default function TelemetryCircuitsContainer() {
  const apiUrl =
    'https://vby6vsofe3.execute-api.us-east-1.amazonaws.com/get/getTelemetryCircuits';
  const [responseData, setResponseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Estado adicionado para os dados filtrados
  const classes = useStyles();
  const [filterValue, setFilterValue] = useState('');
  const [filterBy, setFilterBy] = useState('concessionaire');
  const [filterOptions, setFilterOptions] = useState([]);
  const [isFilterMenuVisible, setIsFilterMenuVisible] = useState(false);
  const [isSelectVisible, setIsSelectVisible] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCircuitMeasures, setSelectedCircuitMeasures] = useState(null);
  const [loadingCircuitMeasures, setLoadingCircuitMeasures] = useState(false);
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { groupPermissions } = useGroupPermissions();
  const { createLog } = useCreateLogs();
  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['telemetria', 'equipamento_telemetria', 'leitura'];
    const reportPath = [
      'telemetria',
      'equipamento_telemetria',
      'emitir_relatorio',
    ];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        const data = JSON.parse(response.data.body);
        setResponseData(data);
        // Inicializa displayData com os primeiros itens
        setDisplayData(data.slice(0, itemsPerPage));
        // Verifica se ainda há mais dados para carregar inicialmente
        setHasMore(data.length > itemsPerPage);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };
    fetchData();
  }, []);

  const loadMoreData = () => {
    const nextPage = page + 1;
    const startIndex = nextPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const nextItems = filteredData.slice(startIndex, endIndex);

    setDisplayData((prevDisplayData) => [...prevDisplayData, ...nextItems]);
    setPage(nextPage);
    setHasMore(endIndex < filteredData.length);
  };

  useEffect(() => {
    const uniqueOptions = Array.from(
      new Set(responseData.map((data) => data[filterBy]))
    );
    setFilterOptions(uniqueOptions);
  }, [responseData, filterBy]);

  const handleFilterOptionClick = (option) => {
    setFilterBy(option);
    setIsSelectVisible(true);
    setFilterValue(''); // Resetar o valor do filtro
  };

  const handleApplyFilterClick = () => {
    const newFilteredData = filterValue
      ? responseData.filter((data) => data[filterBy] === filterValue)
      : responseData;

    setFilteredData(newFilteredData);
    setCurrentFilter({ filterBy, filterValue }); // Armazena o filtro atual

    // Reinicia a paginação e exibe os primeiros itens após o filtro
    setDisplayData(newFilteredData.slice(0, itemsPerPage));
    setPage(0);
    setHasMore(newFilteredData.length > itemsPerPage);

    setIsSelectVisible(false);
    setIsFilterMenuVisible(false);
  };

  const handleClearFilter = () => {
    setFilteredData(responseData);
    setCurrentFilter(null); // Limpa o filtro atual
    setFilterValue('');

    // Reinicia a exibição para os primeiros itens originais, considerando que não há filtro
    setDisplayData(responseData.slice(0, itemsPerPage));
    setPage(0);
    setHasMore(responseData.length > itemsPerPage);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const exportMeasuresToExcel = (measures) => {
    // Preparando os dados das medições com apenas os campos desejados
    const dataToExport = measures.map((measure) => ({
      'UUID Measure': measure.uuid_measure,
      'Active Power': measure.active_power,
      'Apparent Power': measure.apparent_power,
      'Arrival Date': formatDate(measure.arrival_date),
      'Counter': measure.counter,
      'Data de Criação': formatDate(measure.created_at),
      'Current': measure.current,
      'Export Active Energy': measure.export_active_energy,
      'Export Reactive Energy': measure.export_reactive_energy,
      'Line Frequency': measure.line_frequency,
      'Power Factor': measure.power_factor,
      'Reactive Power': measure.reactive_power,
      'Status Measure': measure.status_measure,
      'System Status': measure.system_status,
      'Thermistor Voltage': measure.thermistor_voltage,
      'Circuito': measure.uuid_circuit,
      'Voltage': measure.voltage,
    }));

    // Gerando o arquivo XLSX
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Medições do Circuito');
    XLSX.writeFile(wb, 'medicoes_circuito.xlsx');
  };

  const handleExportMeasures = async (measures) => {
    exportMeasuresToExcel(measures);

    try {
      const operacao = 'EMITIR RELATÓRIO';
      const form = 'MEDIÇÕES DE TELEMETRIA';
      const logContent = {};

      await createLog(operacao, form, logContent);
    } catch (error) {
      console.error(error);
    }
  };

  const exportToExcel = () => {
    // Preparando os dados filtrados com apenas os campos desejados
    const dataToExport = filteredData.map((data) => ({
      'UUID Circuito': data.uuid_circuit,
      'Data de Criação': formatDate(data.created_at),
      'Número de Identificação': data.identification_number,
      'UUID Cliente': data.uuid_client,
      'UUID Grupo': data.uuid_group,
      'UUID Unidade': data.uuid_unity,
    }));

    // Gerando o arquivo XLSX
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Relatório');
    XLSX.writeFile(wb, 'relatorio_telemetria.xlsx');
  };

  const handleExportToExcel = async () => {
    exportToExcel();

    try {
      const operacao = 'EMITIR RELATÓRIO';
      const form = 'EQUIPAMENTOS DE TELEMETRIA';
      const logContent = {};

      await createLog(operacao, form, logContent);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCircuitMeasures = async (uuidCircuit) => {
    try {
      setLoadingCircuitMeasures(true); // Inicia o carregamento
      const response = await axios.get(
        `https://vby6vsofe3.execute-api.us-east-1.amazonaws.com/get/getTelemetryMeasures?uuid_circuit=${uuidCircuit}`
      );
      const allMeasures = JSON.parse(response.data.body);
      const measuresForCircuit = allMeasures.filter(
        (measure) => measure.uuid_circuit === uuidCircuit
      );
      setSelectedCircuitMeasures(measuresForCircuit);
    } catch (error) {
      console.error('Erro ao buscar medidas do circuito:', error);
    } finally {
      setLoadingCircuitMeasures(false); // Finaliza o carregamento
    }
  };

  const handleRowClick = (uuidCircuit) => {
    fetchCircuitMeasures(uuidCircuit);
  };

  const handleBackButtonClick = () => {
    setSelectedCircuitMeasures(null);
  };

  const renderSubHeader = () => {
    return (
      <>
        <WrappedContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <h3
                style={{
                  fontSize: '23px',
                  paddingTop: '5px',
                  color: '#015A9E',
                  marginTop: '15px',
                }}
              >
                {selectedCircuitMeasures.length > 0
                  ? `Medições do equipamento ${selectedCircuitMeasures[0].uuid_circuit}`
                  : `Tabela de medições`}
              </h3>
              <h2
                style={{
                  color: 'grey',
                  paddingTop: '11px',
                  marginBottom: '10px',
                }}
              >
                Filtre os dados para exibição
              </h2>
            </div>
            <div>
              <IoseBackButton onClick={() => handleBackButtonClick()} />
            </div>
          </div>
        </WrappedContainer>
      </>
    );
  };

  const renderMeasuresTable = (measures) => {
    return (
      <>
        <WrappedContainer>
          <IoseInfinityScroll
            dataLength={displayData.length}
            next={loadMoreData}
            hasMore={hasMore}
            height={600}
          >
            <TableContainer component={Paper}>
              <StyledTable>
                <TableHeadStyled>
                  <TableRowStyled align="center">
                    <TableHeaderCellCentered>CIRCUITO</TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      POTÊNCIA ATIVA
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      POTÊNCIA APARENTE
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      DATA DE RECEBIMENTO
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      DATA DE CRIAÇÃO
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>CONTADOR</TableHeaderCellCentered>
                    <TableHeaderCellCentered>CORRENTE</TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      ENERGIA ATIVA EXPORTADA
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      ENERGIA REATIVA EXPORTADA
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      FREQUÊNCIA DA REDE
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      FATOR DE POTÊNCIA
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      POTÊNCIA REATIVA
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      STATUS DA MEDIÇÃO
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      STATUS DO SISTEMA
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>
                      TEMPERATURA INTERNA DO MÓDULO
                    </TableHeaderCellCentered>
                    <TableHeaderCellCentered>VOLTAGEM</TableHeaderCellCentered>
                  </TableRowStyled>
                </TableHeadStyled>
                <TableBody>
                  {measures.map((measure, index) => (
                    <TableRow key={index}>
                      <TableCellCentered>
                        {measure.uuid_circuit}
                      </TableCellCentered>
                      <TableCellCentered>
                        {measure.active_power}
                      </TableCellCentered>
                      <TableCellCentered>
                        {measure.apparent_power}
                      </TableCellCentered>
                      <TableCellCentered>
                        {formatDate(measure.arrival_date)}
                      </TableCellCentered>
                      <TableCellCentered>
                        {formatDate(measure.created_at)}
                      </TableCellCentered>
                      <TableCellCentered>{measure.counter}</TableCellCentered>
                      <TableCellCentered>{measure.current}</TableCellCentered>
                      <TableCellCentered>
                        {measure.export_active_energy}
                      </TableCellCentered>
                      <TableCellCentered>
                        {measure.export_reactive_energy}
                      </TableCellCentered>

                      <TableCellCentered>
                        {measure.line_frequency}
                      </TableCellCentered>
                      <TableCellCentered>
                        {measure.power_factor}
                      </TableCellCentered>
                      <TableCellCentered>
                        {measure.reactive_power}
                      </TableCellCentered>
                      <TableCellCentered>
                        {measure.status_measure === 'received'
                          ? 'recebida'
                          : 'perdida'}
                      </TableCellCentered>
                      <TableCellCentered>
                        {measure.system_status}
                      </TableCellCentered>
                      <TableCellCentered>
                        {measure.thermistor_voltage}
                      </TableCellCentered>
                      <TableCellCentered>{measure.voltage}</TableCellCentered>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </IoseInfinityScroll>
        </WrappedContainer>
      </>
    );
  };

  const MeasuresTable = ({ measures, onBack, loading }) => {
    if (loading) {
      return (
        <>
          <LoadingIndicator loading={loading} margin="auto" />
        </>
      );
    }

    return (
      <>
        {hasReadPermission ? (
          <>
            {renderSubHeader()}
            {renderMeasuresTable(measures)}
          </>
        ) : (
          <>
            <IoseAlertLoadingCards
              text={
                'Seu grupo de usuários não tem permissão de leitura destes dados!'
              }
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      {loadingCircuitMeasures ? (
        <LoadingContainer>
          <LoadingIndicator loading={true} margin="auto" />
          <LoadingText>
            Carregando medições do circuito selecionado...
          </LoadingText>
        </LoadingContainer>
      ) : (
        <>
          {selectedCircuitMeasures ? (
            // Se medidas do circuito estiverem selecionadas, renderize a tabela de medidas
            <MeasuresTable
              measures={selectedCircuitMeasures}
              onBack={() => setSelectedCircuitMeasures(null)}
            />
          ) : (
            // Se não, renderize o conteúdo da tabela de circuitos como antes
            <>
              {hasReadPermission ? (
                <>
                  <WrappedContainer>
                    <h3
                      style={{
                        fontSize: '23px',
                        paddingTop: '5px',
                        color: '#015A9E',
                        marginTop: '15px',
                      }}
                    >
                      Tabela de equipamentos de telemetria
                    </h3>
                    <h2
                      style={{
                        color: 'grey',
                        paddingTop: '11px',
                        marginBottom: '10px',
                      }}
                    >
                      Filtre os dados para exibição
                    </h2>
                  </WrappedContainer>

                  <div className={classes.filterContainer}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <IoseAddFilterButton
                        onClick={() => {
                          setIsFilterMenuVisible((prev) => !prev);
                          toggleFilters();
                        }}
                        tooltip="ADICIONAR FILTRO"
                      >
                        Filtro
                      </IoseAddFilterButton>

                      {isFilterMenuVisible && (
                        <>
                          <IoseButton
                            onClick={() =>
                              handleFilterOptionClick('uuid_circuit')
                            }
                          >
                            Circuito
                          </IoseButton>
                          <IoseButton
                            onClick={() =>
                              handleFilterOptionClick('identification_number')
                            }
                          >
                            Unidade Consumidora
                          </IoseButton>
                        </>
                      )}

                      {isSelectVisible && (
                        <>
                          <Select
                            value={filterValue}
                            onChange={(e) => setFilterValue(e.target.value)}
                            displayEmpty
                            className={classes.select}
                          >
                            <MenuItem value="">Mostrar Todos</MenuItem>
                            {filterOptions.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                          <IoseAddButton onClick={handleApplyFilterClick}>
                            Adicionar Filtro
                          </IoseAddButton>
                        </>
                      )}

                      {currentFilter && (
                        <div className={classes.filterDisplay}>
                          <span>
                            Filtros Selecionados: {currentFilter.filterValue}
                          </span>
                          <button
                            className={classes.closeButton}
                            onClick={handleClearFilter}
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>

                    {/* Botão de gerar relatório é posicionado separadamente à direita */}
                    {hasReportPermission ? (
                      <div>
                        <IoseButton
                          className={classes.downloadButton}
                          onClick={() =>
                            selectedCircuitMeasures
                              ? handleExportMeasures(selectedCircuitMeasures)
                              : handleExportToExcel()
                          }
                        >
                          Download XLSX
                        </IoseButton>
                      </div>
                    ) : null}
                  </div>

                  <WrappedContainer>
                    <IoseInfinityScroll
                      dataLength={displayData.length}
                      next={loadMoreData}
                      hasMore={hasMore}
                      height={650}
                    >
                      <TableContainer component={Paper}>
                        <StyledTable>
                          <TableHeadStyled>
                            <TableRowStyled align="center">
                              {/* Aqui reintegramos o cabeçalho da tabela */}
                              <TableHeaderCellCentered>
                                CIRCUITO
                              </TableHeaderCellCentered>
                              {/* <TableHeaderCellCentered>
                                DATA DE CRIAÇÃO
                              </TableHeaderCellCentered> */}
                              <TableHeaderCellCentered>
                                UNIDADE CONSUMIDORA
                              </TableHeaderCellCentered>
                            </TableRowStyled>
                          </TableHeadStyled>
                          <TableBody>
                            {/* Aqui garantimos que estamos usando displayData para renderizar as linhas */}
                            {displayData.map((data, index) => (
                              <TableRow
                                key={index}
                                onClick={() =>
                                  handleRowClick(data.uuid_circuit)
                                }
                              >
                                <TableCellCentered>
                                  {data.uuid_circuit}
                                </TableCellCentered>
                                {/* <TableCellCentered>
                                  {formatDate(data.created_at)}
                                </TableCellCentered> */}
                                <TableCellCentered>
                                  {data.identification_number}
                                </TableCellCentered>
                              </TableRow>
                            ))}
                          </TableBody>
                        </StyledTable>
                      </TableContainer>
                    </IoseInfinityScroll>
                  </WrappedContainer>
                </>
              ) : (
                <div style={{ marginTop: '35px' }}>
                  <IoseAlertLoadingCards
                    text={
                      'Seu grupo de usuários não tem permissão de leitura destes dados!'
                    }
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

TelemetryCircuitsContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
