import React, { useEffect, useState, useContext } from 'react';
import PropType from 'prop-types';
import { Grow } from '@material-ui/core/';
import ioseApiLib from 'iose-api-lib';
import { useAlert } from 'react-alert';

import {
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseCardWithInfo,
  IoseCircuitOpenObjects,
  IoseCircuitChartModal,
  IoseInfinityScroll,
  IoseSubHeaderBar,
  IoseSearch,
} from 'components';

import {
  AccountContext,
  ioseAPI,
  LocalStorage,
  Messages,
  useClient,
} from 'common';

import { ContainerCards, WrappedContainer, CircuitsIcon } from './style';

import { useEnvironments, useObjects, useGroupPermissions } from 'hooks';
import Permissions from 'common/permissions';
import { ioseIotAPI } from 'common/ioseIotAPI';

export default function CircuitContainer({ getcards }) {
  const [allCircuits, setAllCircuits] = useState([]);
  const [cards, setCards] = useState([]);
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [chartMode, setChartMode] = useState('Diário');
  const [clientData, setClientData] = useState({});
  //eslint-disable-next-line
  const [dailyEstimateConsumption, setDailyEstimateConsumption] = useState([]);
  const [error, setError] = useState('');
  //eslint-disable-next-line
  const [formatedData, setformatedData] = useState([]);
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  //eslint-disable-next-line
  const [lastMeasuresError, setLastMeasuresError] = useState('');
  const [measures, setMeasures] = useState([]);
  //eslint-disable-next-line
  const [nextPage, setNextPage] = useState(0);
  const [openObjectsModal, setOpenObjectsModal] = useState(false);
  const [openChartModal, setOpenChartModal] = useState(false);
  const [search, setSearch] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  //eslint-disable-next-line
  const [selectedCircuit, setSelectedCircuit] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [unitys, setUnitys] = useState([]);
  const [uuidClient, setuuidClient] = useState();

  const [hasReadPermission, setHasReadPermission] = useState(true);

  const alert = useAlert();
  const { client } = useClient();
  const { getSession } = useContext(AccountContext);

  const { environments } = useEnvironments();
  const { objects, getObjects } = useObjects();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['cadastro', 'circuitos', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllUnity(clientData);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  useEffect(() => {
    handleSelectedCircuit(selectedCard);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  useEffect(() => {
    handleSelectedObject(selectedCard);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  useEffect(() => {
    getAllClientCircuit();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  useEffect(() => {
    getLastMeasureAllCircuits();
    //eslint-disable-next-line
  }, [allCircuits]);

  useEffect(() => {
    prepareData(measures, allCircuits, objects, unitys);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCircuits, measures, objects, unitys]);

  const page_size = 300;

  const handleOpenObjectsModal = () => {
    setOpenObjectsModal(true);
  };

  const handleCloseObjectsModal = () => {
    setSelectedCard(null);
    setOpenObjectsModal(false);
  };

  const handleOpenChartModal = () => {
    setOpenChartModal(true);
  };

  const handleCloseChartModal = () => {
    setOpenChartModal(false);
    setChartMode('Diário');
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    setuuidClient(clientData.uuid_client);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  //Functions to GET ALL CIRCUITS
  function getAllClientCircuit() {
    // setLoading(true);
    getSession().then(({ headers }) => {
      ioseAPI
        .getAllClientCircuits(headers, uuidClient, 0, page_size)
        .then((response) => {
          let allCircuits =
            response.data.data[0] === null ? [] : response.data.data;
          // setCards(allCircuits);
          setAllCircuits(allCircuits);

          // setLoading(false);
        });
    });
  }

  async function getLastMeasureAllCircuitsRequisition(circuits, headers) {
    const currentDate = new Date();
    const initialDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    const lastMeasuresAllCircuit = await Promise.all(
      circuits?.map(({ uuid_circuit }) =>
        ioseIotAPI.getLastMeasure(
          headers,
          uuid_circuit,
          initialDate.toISOString(),
          currentDate.toISOString()
        )
      )
    );

    return lastMeasuresAllCircuit;
  }

  async function getLastMeasureAllCircuits() {
    try {
      const { headers } = await getSession();
      const allClientCircuits = allCircuits?.length > 0 ? allCircuits : [];
      const data = await getLastMeasureAllCircuitsRequisition(
        allClientCircuits,
        headers
      );
      setMeasures(data);
    } catch (error) {
      setLastMeasuresError('Erro ao buscar medidas de algum circuito');
    }
  }

  function prepareData(measures, circuits, objects, unitys) {
    const circuitDataArray = [];

    const circuitsWithMeasures = circuits
      ?.map((circuit) => {
        const { uuid_circuit, uuid_unity } = circuit;

        const matchingMeasure = measures?.find(
          (measure) => measure?.data?.data?.uuid_circuit === uuid_circuit
        );

        if (!matchingMeasure) {
          return null;
        }

        const matchingObjects = objects?.filter(
          (object) => object.uuid_circuit === uuid_circuit
        );

        if (matchingObjects?.length === 0) {
          return [];
        }

        const totalPowerObjects = objects?.reduce(
          (sum, object) => sum + object?.power,
          0
        );

        const cumulativeConsumption =
          matchingMeasure?.data?.data?.measures?.arrayConsumed ?? [];

        const formattedCumulativeConsumption = cumulativeConsumption?.map(
          (measure) => Number((measure / 1000).toFixed(2))
        );

        const dailyConsumption = [cumulativeConsumption[0]];

        for (let i = 0; i < cumulativeConsumption?.length - 1; i++) {
          const dailyValue =
            cumulativeConsumption[i + 1] - cumulativeConsumption[i];
          dailyConsumption.push(dailyValue);
        }

        const formattedDailyConsumption = dailyConsumption?.map((num) =>
          Number((num / 1000).toFixed(2))
        );

        const maxValueDaily = Math.max(...formattedDailyConsumption) + 0.5;
        const minValueDaily = Math.min(...formattedDailyConsumption) - 0.5;
        const maxValueCumulative =
          Math.max(...formattedCumulativeConsumption) + 1.0;
        const minValueCumulative =
          Math.min(...formattedCumulativeConsumption) - 1.0;

        const measuresPeriod =
          matchingMeasure?.data?.data?.measures?.dates ?? [];
        const formattedDates = measuresPeriod?.map((date) =>
          new Date(date).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
          })
        );

        const matchingUnity = unitys.find(
          (unity) => unity.uuid_unity === uuid_unity
        );

        if (!matchingUnity) {
          return null;
        }

        const circuitData = {
          ...circuit,
          cumulative_consumption: formattedCumulativeConsumption,
          daily_consumption: formattedDailyConsumption,
          dates: formattedDates,
          objects: matchingObjects,
          unity_name: matchingUnity.name,
          max_value: maxValueDaily,
          min_value: minValueDaily,
          max_value_cumulative: maxValueCumulative,
          min_value_cumulative: minValueCumulative,
        };

        const currentDate = new Date();
        const daysInCurrentMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate();

        //eslint-disable-next-line
        const daysRemaining = daysInCurrentMonth - currentDate.getDate();

        circuitData.estimated_consumption = Number(
          (
            (totalPowerObjects * 8 * daysInCurrentMonth) /
            1000 /
            daysInCurrentMonth
          ).toFixed(2)
        );

        circuitData.daily_estimated_consumption = formattedDates?.map(
          (date) => {
            const dailyEstimatedConsumption = circuitData.estimated_consumption;
            setDailyEstimateConsumption(dailyEstimatedConsumption);
            return dailyEstimatedConsumption;
          }
        );

        circuitData.max_value_estimated =
          Math.max(...circuitData.daily_estimated_consumption) + 0.5;

        circuitData.cumulative_estimated_consumption = circuitData.daily_estimated_consumption
          ?.reduce((acc, value) => {
            // O valor acumulado é a soma do valor anterior e do valor atual
            const accumulatedValue = acc?.length
              ? acc[acc?.length - 1] + value
              : value;

            // Adiciona o valor acumulado no novo array
            return [...acc, accumulatedValue];
          }, [])
          .map((value) => Number(value.toFixed(2)));

        circuitData.max_value_cumulative_estimated =
          Math.max(...circuitData.cumulative_estimated_consumption) + 0.5;

        circuitDataArray.push(circuitData);

        return circuitData;
      })
      ?.filter(Boolean);

    setformatedData(circuitsWithMeasures);
    setCards(circuitDataArray);
  }

  const getAllUnitys = async (uuid_client, nextpage = 0) => {
    try {
      let response = await ioseApiLib.Unity.getAllUnity(uuid_client, nextpage);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const getAllUnity = async (clientData) => {
    let allUnitys = [];
    allUnitys = await getAllUnitys(clientData.uuid_client);

    setUnitys(allUnitys.data);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const getMoreUnitys = () => nextPage && getClientData(true);

  //Function to filter Unity
  const filterCircuits = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppcase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards?.filter((item) =>
        item.name.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered?.length !== 0
        ? setCardsFiltered(itemFiltered)
        : setError(Messages.noFindCircuits);
    } else {
      setSearch(false);
    }
  };

  //Functions to RENDER
  // eslint-disable-next-line
  const renderAlert = (message, type) => {
    return alert.show(`${message}`, {
      title: type,
      closeCopy: 'Fechar',
    });
  };

  const renderCards = (card) => {
    return (
      <IoseCardWithInfo
        key={card.uuid_circuit}
        uuid_environment={card.uuid_circuit}
        name={card.uuid_circuit}
        description={card.code_module}
        handleOpenObjectsModal={handleOpenObjectsModal}
        handleOpenChartModal={handleOpenChartModal}
        onClick={() => handleCardClick(card)}
        cardDeleteTitle={'DELETAR CIRCUITO'}
        cardEditTitle={'EDITAR CIRCUITO'}
        modalTitle={'OBJETOS DO CIRCUITO'}
        chartModalTitle={'MEDIÇÕES DO CIRCUITO'}
        cardIcon={<CircuitsIcon />}
        showDeleteButton={false}
        showEditButton={false}
        showChartButton={true}
        selectedCard={selectedCard}
        showInfo={true}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="Criar Ambiente" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle="Circuitos"
        description={'Total: ' + cards?.length}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar Circuitos…"
          funcSearch={filterCircuits}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderAllCards = () => {
    return (
      <ContainerCards>
        {search ? cardsFiltered?.map(renderCards) : cards?.map(renderCards)}
      </ContainerCards>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards?.length}
            next={getMoreUnitys}
            hasMore={true}
            loading={loading}
            scrollableTarget={'container'}
          >
            {hasReadPermission
              ? renderAllCards()
              : setError(
                  'Seu grupo de usuários não tem permissão de leitura destes dados!'
                )}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <IoseCircuitOpenObjects
          open={openObjectsModal}
          selectedObject={selectedObject}
          onClose={handleCloseObjectsModal}
          group={group}
          clientData={clientData}
          getMoreUnitys={getMoreUnitys}
          loading={loading}
          environments={environments}
          getObjects={getObjects}
          allCircuits={allCircuits}
          errorAlertText={'Nenhum objeto vinculado a este circuito!'}
        />
        <IoseCircuitChartModal
          open={openChartModal}
          onClose={handleCloseChartModal}
          selectedCard={selectedCard}
          chartMode={chartMode}
          setChartMode={setChartMode}
        />
      </>
    );
  };

  ////////////////// OPERATIONS //////////////////

  function handleSelectedCircuit(selectedCard) {
    const selectedCircuit = allCircuits.find(
      (circuit) => circuit.uuid_circuit === selectedCard?.uuid_circuit
    );

    setSelectedCircuit(selectedCircuit);
  }

  function handleSelectedObject(selectedCard) {
    if (selectedCard) {
      let newObject = {};

      if (selectedCard.objects) {
        newObject = {
          name: selectedCard.name ? selectedCard.name : 'Não cadastrado',
          description: selectedCard.description,
          objects: selectedCard.objects,
        };
      } else {
        newObject = {
          name: selectedCard.name ? selectedCard.name : 'Não cadastrado',
          description: selectedCard.description,
          objects: [],
        };
      }

      setSelectedObject(newObject);
    }
  }

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </WrappedContainer>
    </Grow>
  );
}

CircuitContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
