export const itens = [
  {
    'uuid_items': '83e8123a-023b-4d3a-8fab-7625963a4f8d',
    'identification_number': 106972529,
    'item_consumption_1': 345,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 134.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b868f96-2fef-4890-8cd5-c6a75384adc2',
    'identification_number': 110907434,
    'item_consumption_1': 115,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 44.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d1dd96a-0c1e-4417-abb6-9c6bdbe57e5e',
    'identification_number': 16916484,
    'item_consumption_1': 3530,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1376.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9f6c000-08ae-4958-8ea9-790273399bcd',
    'identification_number': 18466907,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 41.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '87f3678d-5f08-4c4d-a19e-281e4171ec86',
    'identification_number': 14334399,
    'item_consumption_1': 28658,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3657.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e504019a-a923-4bc8-a97b-93917e5a4e92',
    'identification_number': 3031570722,
    'item_consumption_1': 100,
    'item_description_1': 'Custo Disp Uso Sistema TUSD NOV/23',
    'item_total_value_1': 50.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4a51218-7e7b-4985-8841-58d9c17ef0c6',
    'identification_number': 59059729,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '88c2d7f4-0131-4429-bb68-8a136755e09f',
    'identification_number': 109260473,
    'item_consumption_1': 951,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 404.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aee77536-ed6c-41b5-bbe1-fe8bd26ee442',
    'identification_number': 106568485,
    'item_consumption_1': 8275,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3236.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67253eec-c88b-40e2-a134-b27bc1d20e5c',
    'identification_number': 40701301,
    'item_consumption_1': 30180,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 17626.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d960f5e-ce7b-472d-a9ef-96b901724226',
    'identification_number': 54073901,
    'item_consumption_1': 1317,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1992.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e4cb1f6-05e9-4162-aa83-82cb30a31f6f',
    'identification_number': 109482590,
    'item_consumption_1': 366,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 142.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63ad1a1c-08ae-4b7f-96ea-24ecea9575ed',
    'identification_number': 109375947,
    'item_consumption_1': 258,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 109.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6eadbf8b-1a52-47e8-a0f6-a00cfd839bdc',
    'identification_number': 95067590,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b0ad775e-c6e4-40f3-a876-955434ab9744',
    'identification_number': 17023491,
    'item_consumption_1': 19495,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7255,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ebe7126-62a4-46b4-ad4d-87e548356305',
    'identification_number': 17690200,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cd8603ea-569f-4228-8804-7288d25e1322',
    'identification_number': 16003500,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7cd5bc70-cce7-472f-bad4-1078b7550b9f',
    'identification_number': 40855783,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '18cdfc7c-3165-407a-97d8-a7c6fe57cda0',
    'identification_number': 90934377,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '74717621-df5d-4c78-a71f-b09425d34d49',
    'identification_number': 92159257,
    'item_consumption_1': 192,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 75.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e922ab54-6744-4873-947a-a7f86193bd74',
    'identification_number': 17667089,
    'item_consumption_1': 1761,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 686.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bad88894-ce5e-4562-a3ea-bbc7e6905433',
    'identification_number': 43484506,
    'item_consumption_1': 8.46,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 396.19,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1588c2d0-fc56-4d02-bc0c-1aed1cbd0e2f',
    'identification_number': 109204018,
    'item_consumption_1': 1121,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 438.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c16ffab9-8047-4014-ae65-87e8183c20ec',
    'identification_number': 101400748,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3079a046-5265-476e-bd9c-52da8c328e77',
    'identification_number': 105611271,
    'item_consumption_1': 227.07,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 5415.75,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c249d217-f378-4ab6-8b7f-2e7dda04df4f',
    'identification_number': 35119624,
    'item_consumption_1': 207117,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 77078.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20ea92a8-c1b0-4ec0-a0dc-82b3036326f5',
    'identification_number': 44873719,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17ea5114-6601-4007-90d6-e154da320b63',
    'identification_number': 47509910,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c23afcbb-7250-40db-9add-9ff241c58d0b',
    'identification_number': 111658004,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fea02849-a329-4d4f-b10f-a77c04368c0a',
    'identification_number': 31640427,
    'item_consumption_1': 7182,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 916.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb4e9954-1875-44ff-ac96-d7e4f8c1340f',
    'identification_number': 46861009,
    'item_consumption_1': 1842,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2786.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89ea5d62-2793-42c5-a42f-a86f615f13e5',
    'identification_number': 31818030,
    'item_consumption_1': 20288,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2589.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c69ea4f7-c70a-428a-91ca-b0a802862b9e',
    'identification_number': 45239282,
    'item_consumption_1': 4.57,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 214.03,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '135869d3-c32b-4cfe-98ff-7d25f2156742',
    'identification_number': 72092556,
    'item_consumption_1': 8557,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1092.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93235b07-5e40-4644-ba85-8dc2d2b7a132',
    'identification_number': 104713585,
    'item_consumption_1': 4854,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1892.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db457154-4c40-4370-a265-0d3191ec2030',
    'identification_number': 60092904,
    'item_consumption_1': 10735,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3995,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40537385-1338-4ff7-9e97-9d121b8e3138',
    'identification_number': 8115699,
    'item_consumption_1': 130,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 50.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '687f4b79-e70f-4670-b4c5-933ae06ff3c3',
    'identification_number': 98019210,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a9eb5e8-8d78-4b50-88c7-b0ab744b660c',
    'identification_number': 70064164,
    'item_consumption_1': 3382,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1323.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9ff42e4-cf4e-46cd-9083-e2071f596e03',
    'identification_number': 19452160,
    'item_consumption_1': 28036,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 16374.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '701c939e-24ed-4587-b290-d3dc8ee5acf6',
    'identification_number': 103764755,
    'item_consumption_1': 20688,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2640.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3556cbfd-8be6-4056-934e-5f97994b2ed6',
    'identification_number': 22358609,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8e895a61-3263-4c66-b764-b16e49274570',
    'identification_number': 94623694,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ad73509c-cb1b-4434-b592-6b41fa368b95',
    'identification_number': 47280816,
    'item_consumption_1': 6.57,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 156.71,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6f5a50eb-67a6-4e91-86a2-1f64468955d5',
    'identification_number': 18006540,
    'item_consumption_1': 2420,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1028.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46289487-0946-42d3-b9e9-1b29a6b163bc',
    'identification_number': 11468319,
    'item_consumption_1': 4249,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1803.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c0cf079-3ed8-448f-aed8-d472062f427e',
    'identification_number': 41370198,
    'item_consumption_1': 6689,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2606.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ee1d20b-8e93-48f5-a642-04b3d55a730e',
    'identification_number': 28976746,
    'item_consumption_1': 5466,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2128.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af1dee39-a7ee-4df5-877b-23477a5e2045',
    'identification_number': 32151179,
    'item_consumption_1': 249.24,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 7249.37,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cad558a8-4bff-45cb-8356-377077e0cf3e',
    'identification_number': 103102434,
    'item_consumption_1': 147,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 62.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a38acd3e-a710-4c07-bc3a-e1643f355978',
    'identification_number': 109434765,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 55.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34eef3d2-2919-435d-8624-b7098ba1e264',
    'identification_number': 111198577,
    'item_consumption_1': 238,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 92.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf21a729-a344-42e0-b93a-ab49f42472f2',
    'identification_number': 15981401,
    'item_consumption_1': 68959,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 27817.93,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': '2372f87b-5716-4d5e-a522-8b530631f4e4',
    'identification_number': 102654549,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '002fe917-8190-4b2d-a810-b4be57bd7235',
    'identification_number': 18339603,
    'item_consumption_1': 44.94,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1307.12,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '31049c60-21e3-4f5e-927f-3ac5587b004c',
    'identification_number': 80788190,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '057e9911-e524-4a39-b2e1-58632aeca93d',
    'identification_number': 99353598,
    'item_consumption_1': 18316,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6816.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '361fb301-483e-4ed6-8257-b0da4d1f60a9',
    'identification_number': 32140215,
    'item_consumption_1': 2303,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3484.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc555bf7-c044-4a27-928c-b9c6a74d5a31',
    'identification_number': 38847280,
    'item_consumption_1': 100.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2912.66,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '44bd884f-90ff-4e0d-a227-add5fd98a5be',
    'identification_number': 92850391,
    'item_consumption_1': 1691,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 659.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48b8f034-fc1e-4547-a963-5e0733b0a123',
    'identification_number': 37998447,
    'item_consumption_1': 460616,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 58791.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b153240c-38b4-43ee-bef1-5852d6274dd9',
    'identification_number': 15719464,
    'item_consumption_1': 10312,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3837.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9eb4bbbc-d5e7-491c-898d-500a333e2aeb',
    'identification_number': 62339389,
    'item_consumption_1': 328,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 127.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '053967f0-f373-488a-be21-f41b9aad2615',
    'identification_number': 56544545,
    'item_consumption_1': 14119,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5254.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '787ffa30-b355-4d95-ab40-a4fd8c6c12f7',
    'identification_number': 40026043,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.06,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '87f63c0a-b2d3-42cb-b1b3-b75ddeb711a9',
    'identification_number': 56698151,
    'item_consumption_1': 19.8,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 575.89,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2f7fc7b5-c6f2-4aac-a820-0557c7fc2c7e',
    'identification_number': 105703648,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 005/006',
    'item_total_value_1': 177.33,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '49f7e126-b1c7-402e-a403-aab12cd4bf4b',
    'identification_number': 19450133,
    'item_consumption_1': 190,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 74.33,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1f4c52dc-76a7-414a-943e-51d5efbe0cac',
    'identification_number': 109530608,
    'item_consumption_1': 513,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 776.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e03758ec-48b6-453b-aa7e-515b87e9a3a1',
    'identification_number': 20587147,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '48e63e35-be34-4c4c-a383-176d4f5d9ce4',
    'identification_number': 46364013,
    'item_consumption_1': 1789,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1044.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '758460e3-164c-4c9e-9e35-6667f27e574d',
    'identification_number': 62557777,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 120.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a7ff1103-182b-4533-8852-626fca2d2e4c',
    'identification_number': 28858913,
    'item_consumption_1': 12.2,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 291,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '16a843b3-9f83-4f41-9216-4f8c06bcc86f',
    'identification_number': 102512426,
    'item_consumption_1': 5660,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2106.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8f91b72-96fb-45f9-a865-fd00b6fc1272',
    'identification_number': 107941589,
    'item_consumption_1': 41750,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5328.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '254c86b1-f543-4ece-881c-e09d591a5889',
    'identification_number': 18229077,
    'item_consumption_1': 3750,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2190.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6473af48-11fb-4cbb-a3a6-6e9968d82aab',
    'identification_number': 17005230,
    'item_consumption_1': 147,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 62.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34c15a76-b818-4385-90bc-2d140ad71b1e',
    'identification_number': 103962298,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '36a831aa-e3dc-40e6-9f99-6038cf68bc59',
    'identification_number': 37969064,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '13f3ca7d-4b8c-4b99-87fa-f4166ea94f93',
    'identification_number': 15981401,
    'item_consumption_1': 1,
    'item_description_1': 'En Exc Reativo fora de ponta',
    'item_total_value_1': 0.3,
    'reference_month': '11/2023',
    'referente': 'Energia Reativa Excedente Fora Ponta',
  },
  {
    'uuid_items': 'da988974-97c8-4336-9659-82f6fcec600d',
    'identification_number': 14478870,
    'item_consumption_1': 605,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 256.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7105e30-d902-412c-8bd3-44e4098be9c8',
    'identification_number': 103066330,
    'item_consumption_1': 2085,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 815.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f61da85a-148b-4229-a86f-a5f91d83bdad',
    'identification_number': 105098930,
    'item_consumption_1': 4378,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1712.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83ae8aa6-745c-4d13-9449-f9dd67af24ef',
    'identification_number': 10983848,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 208.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '32864d69-365e-40ff-9caa-e5511f77c683',
    'identification_number': 14104709,
    'item_consumption_1': 5102,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 2058.13,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': '9d59adb3-b667-498c-ac29-590b21a819af',
    'identification_number': 82220239,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 106.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6cfd9d7d-5466-4905-8e5f-721999e0041d',
    'identification_number': 929824133,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 51.97,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1a1a20b2-f093-414d-88f7-160473690a46',
    'identification_number': 79663036,
    'item_consumption_1': 15.54,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 370.64,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3279ebb1-17c4-4c0b-8a81-f311e83d49f4',
    'identification_number': 3030784010,
    'item_consumption_1': 430,
    'item_description_1': 'Demanda Ponta [kW] TUSD NOV/23',
    'item_total_value_1': 23452.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1b77fbcb-ffac-4364-ae8c-1edc0a47fde4',
    'identification_number': 26030420,
    'item_consumption_1': 6353,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2697.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4178e55-036a-4157-8d68-bf59331e3e88',
    'identification_number': 13960261,
    'item_consumption_1': 2836,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1108.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '811bf855-658f-40cc-a597-e2fa3836b489',
    'identification_number': 100990924,
    'item_consumption_1': 4120,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1608.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f20d734-0f36-4c06-913c-9798c6b435fc',
    'identification_number': 11187069,
    'item_consumption_1': 839,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 326.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '017169ec-40c7-4e4f-a480-3ed36da51ac0',
    'identification_number': 18536611,
    'item_consumption_1': 1667,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 706.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebe050ae-3b38-49ff-a081-1cf737e5411c',
    'identification_number': 91798965,
    'item_consumption_1': 11029,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4689.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0df859b0-b574-4af4-8ea8-47fcab1ea429',
    'identification_number': 91670942,
    'item_consumption_1': 36.06,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1048.83,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0358f886-246c-4c00-a2b0-26f079c319df',
    'identification_number': 105042536,
    'item_consumption_1': 18245,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 7120.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a057777-a380-4507-97ff-4602977a989b',
    'identification_number': 90790499,
    'item_consumption_1': 3048,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 389.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b795a8e4-60cb-4b1c-8e29-3c448c07d213',
    'identification_number': 29896991,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '68d47ed8-85cf-4b5e-9882-692213878007',
    'identification_number': 29935091,
    'item_consumption_1': 1479,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 578.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba217015-6bbf-411a-ad3e-c4ad02d25c82',
    'identification_number': 106382373,
    'item_consumption_1': 6364,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 9627.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b54a3966-3313-490c-9968-cf57b76be231',
    'identification_number': 17093457,
    'item_consumption_1': 454,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 177.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '291ea341-4c91-463e-8bfd-5d614bc162a0',
    'identification_number': 62467344,
    'item_consumption_1': 523,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 204.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8a039fa-cc9d-43e2-a552-da73cce9a7ed',
    'identification_number': 79722970,
    'item_consumption_1': 11901,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5040.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52442854-61be-42c2-9c78-adf63fda96c0',
    'identification_number': 40178579,
    'item_consumption_1': 1064,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 451.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7037e76f-421e-4af0-8b37-4e2dfc21cbb8',
    'identification_number': 75687267,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9c5b0e0-e563-481d-adb0-c0d3a5b7ae67',
    'identification_number': 105479187,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4c0c5065-3da5-42be-8a85-f6c5e1e3778a',
    'identification_number': 40207650,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4300ae33-058d-4146-8528-ee93bee5f626',
    'identification_number': 102960216,
    'item_consumption_1': 1480,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 576.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3420d472-4df7-43d5-9b2f-f8f613aedde1',
    'identification_number': 99753154,
    'item_consumption_1': 2783,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4210.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '880b1807-1f74-4101-a6f4-1b6abc86ba80',
    'identification_number': 13884140,
    'item_consumption_1': 12.37,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 359.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'df03095c-7199-4221-be85-3d28bd3c8661',
    'identification_number': 8998310,
    'item_consumption_1': 7430,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2897.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a3db4c5-0b0d-47c3-ba84-1b4ed5bf59fa',
    'identification_number': 93511388,
    'item_consumption_1': 1633,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2470.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e406cec7-2b66-4c22-aed5-0a766846db2f',
    'identification_number': 42694310,
    'item_consumption_1': 5259,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3071.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5704e4e4-9375-46e4-8f6a-85effc1380b6',
    'identification_number': 9211225,
    'item_consumption_1': 1868,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 791.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ebf201b-35de-4950-b0bc-1587db3fc55c',
    'identification_number': 15107434,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a04db6a1-aa3f-4c5d-875a-90b83b4a4087',
    'identification_number': 13299530,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ad0a65ae-d156-4c3d-9aef-1699d814f79f',
    'identification_number': 93256876,
    'item_consumption_1': 747,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 317.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64b1ec7d-99f7-4c3a-9eed-8cd7e01f4e10',
    'identification_number': 96026782,
    'item_consumption_1': 5.16,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 300.16,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e58c933d-1410-40d2-a137-01c01f1a045f',
    'identification_number': 109675185,
    'item_consumption_1': 2402,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 306.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb6b795f-55c7-4176-a597-4c7dcf08d327',
    'identification_number': 16961757,
    'item_consumption_1': 10615,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4140.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4e0f32a-cc31-493e-b2d9-2b25e889f2a7',
    'identification_number': 6208282,
    'item_consumption_1': 6575,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2566.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac7ad80d-090b-4d59-b663-e21ba3c5a6f4',
    'identification_number': 16111451,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d73cff2-b495-4670-8fd3-f6a5181214a9',
    'identification_number': 62270010,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '68c864a9-0564-44d6-b5f6-fae7c05bfc55',
    'identification_number': 44001681,
    'item_consumption_1': 40849,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 5213.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '532ea432-a9b5-4df3-919f-13779a43bc00',
    'identification_number': 99102935,
    'item_consumption_1': 544,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 822.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d0af2ad-5b7c-410d-8b64-f47682709b79',
    'identification_number': 58734210,
    'item_consumption_1': 1648,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 962.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd10fb6e-358b-4ff6-b222-13f0e4a91d27',
    'identification_number': 19451113,
    'item_consumption_1': 589,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 230.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '32954a22-6019-427d-8797-b2f6328e5415',
    'identification_number': 13164570,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88fdce94-198a-4345-9bf7-f783f1ade963',
    'identification_number': 109822285,
    'item_consumption_1': 76.16,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2215.17,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b42174fa-c552-4278-99a7-6c27a329f833',
    'identification_number': 41971817,
    'item_consumption_1': 3.18,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 148.94,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '6157acc3-ac5d-4359-9fe1-501b4907f4bf',
    'identification_number': 28803809,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 93.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7a714be7-0f6f-4411-bf23-84afd23100fa',
    'identification_number': 99666995,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '21e3e40a-7985-4410-8ab2-bac3cb07c05b',
    'identification_number': 44503849,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '483c00fa-689b-4303-b683-bbb14537d0e5',
    'identification_number': 95205390,
    'item_consumption_1': 1850,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 722.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffa70b2a-b1bc-4c88-a349-183e51bc1ca0',
    'identification_number': 19288026,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69f628e2-875a-439a-ad07-affc660666ff',
    'identification_number': 27549607,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-650.79",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'ddc6bf9b-757b-4cde-96e8-5766af8f76cd',
    'identification_number': 12281301,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 91.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1b74aa47-89e8-4c3c-b4f8-e89bc0356871',
    'identification_number': 81572280,
    'item_consumption_1': 345,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 134.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f665c70c-a3ec-42f9-be3d-e682d8351732',
    'identification_number': 11346221,
    'item_consumption_1': 0.14,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 7.98,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ed59a6a6-1fca-4e0f-aec0-7f28de250d54',
    'identification_number': 14671638,
    'item_consumption_1': 2.86,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 68.23,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8986fc71-fcdf-429d-8f60-0ede1e89913a',
    'identification_number': 104455250,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 156.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '90f019ff-a3f6-44f0-916e-73bcf54ccca2',
    'identification_number': 73615145,
    'item_consumption_1': "'-15.204",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-5295.46",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '5498d77e-8ffc-488e-97f8-cd4b53d32452',
    'identification_number': 32827598,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7aef5b2-f6df-451a-8469-9125f1450614',
    'identification_number': 14562545,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6943e5ba-d570-4a02-8df2-ae49e995dafa',
    'identification_number': 17435048,
    'item_consumption_1': 2.3,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 54.87,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b208d971-d0d2-43c2-ad6b-d2ff27b33794',
    'identification_number': 105068730,
    'item_consumption_1': 589,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 249.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1300a279-0b67-47c2-9822-fc317225e03f',
    'identification_number': 94694648,
    'item_consumption_1': 13180,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5603.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c001500d-2129-4e7d-bf00-d49102b2fdc6',
    'identification_number': 106607090,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'de9c81b4-9329-4fd2-9466-ee79aae843e5',
    'identification_number': 43154069,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5eee5dde-5904-4aa1-9948-27a43dd8bc4c',
    'identification_number': 74504908,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbbf895c-8015-4082-a2c8-0e409ddfd2b4',
    'identification_number': 18898068,
    'item_consumption_1': 20931,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2671.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '738fdbba-842b-4988-b22a-fd7e31773733',
    'identification_number': 35559217,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a4521b9d-8757-4fb8-a352-bd2d27d2f81e',
    'identification_number': 97063800,
    'item_consumption_1': 15514,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6586.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '868d2fa3-48ac-4e3a-b4bc-819552145962',
    'identification_number': 45986010,
    'item_consumption_1': 769,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 326.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22eb39c5-4410-4e37-b0dd-fcda20b92137',
    'identification_number': 76229815,
    'item_consumption_1': 28.64,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 683.08,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2db12dc2-10a5-423d-91dc-4720e22be3c6',
    'identification_number': 19453299,
    'item_consumption_1': 432,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 169.02,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3417aa17-1479-4a83-b095-11b686e690c5',
    'identification_number': 111219248,
    'item_consumption_1': 3504,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1304,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e56a1ac0-17fe-4415-bdf6-830ad2851cbb',
    'identification_number': 97353590,
    'item_consumption_1': 4236,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6408.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02e26d64-7669-4901-9c5a-ba6df0616968',
    'identification_number': 8138559,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05ac165a-dc90-4d3b-a9a2-e352cfb59c1d',
    'identification_number': 10566813,
    'item_consumption_1': 6627,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2585.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb11062b-b88c-4508-9c97-9ae950d88edc',
    'identification_number': 29700485,
    'item_consumption_1': 1884,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 240.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81bdaeff-ada7-4a77-88c1-6e55004d23ed',
    'identification_number': 14671638,
    'item_consumption_1': 13,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 5.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c0d063c7-5514-4e9f-99be-a4d6ab094eab',
    'identification_number': 108198804,
    'item_consumption_1': 1547,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 604.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99f47d7c-e80e-408c-90d0-b7a0cb13e098',
    'identification_number': 107678977,
    'item_consumption_1': 324,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 137.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e7c2022-67ef-402c-9d1f-6a863ce11d62',
    'identification_number': 91393973,
    'item_consumption_1': 2807,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1639.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0fe3287-2650-4159-be19-e75fa659cf2d',
    'identification_number': 108601676,
    'item_consumption_1': 22.54,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 537.61,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5996687a-b08e-4cac-97ad-1c0fd2cd6e25',
    'identification_number': 79885756,
    'item_consumption_1': 373,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 156.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6fac935-574a-4be4-9a02-dcf87c5fe42e',
    'identification_number': 24039128,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58d308fe-ed4d-483c-8ccc-3bcf2b9135c7',
    'identification_number': 66168090,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eefd5d10-ac10-4b2f-b311-0ca182fb2e90',
    'identification_number': 30035465,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6058026c-216f-464d-81c5-c3cd7fa6b8c7',
    'identification_number': 42390001,
    'item_consumption_1': 101722,
    'item_description_1': 'Energia Ativa Consumo Fora Ponta',
    'item_total_value_1': 38594.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7776aa94-ad48-4441-9b2c-a76337cb6eea',
    'identification_number': 10959653,
    'item_consumption_1': 4670,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1821.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '714aa095-8bf1-445e-886e-6edcca448fc3',
    'identification_number': 92232949,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2af6f081-5f12-4428-9175-59c8d0e65e88',
    'identification_number': 14354306,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '020435f6-e594-47d3-b685-cf4eb76cf78c',
    'identification_number': 80508820,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c84a4656-ceb4-41b3-884e-20ab8e51e796',
    'identification_number': 70343985,
    'item_consumption_1': 7535,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3193.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'feca79c1-c494-4e19-a93f-a16011c0de7b',
    'identification_number': 44634374,
    'item_consumption_1': 68.78,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2000.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1f7ac0f2-2fae-4fe0-86da-23cbc8e0fc9e',
    'identification_number': 7376880,
    'item_consumption_1': 1993,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 777.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '893da4b9-2da8-4ac8-b9c0-39313431ece5',
    'identification_number': 18267211,
    'item_consumption_1': 21.89,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 522.1,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '07d1cf31-3d74-40d6-ab23-d719fa70823d',
    'identification_number': 71982604,
    'item_consumption_1': 4166,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1626.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35466313-dc81-467b-939a-8f4466f02045',
    'identification_number': 65626079,
    'item_consumption_1': 3543,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1505.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1828dd2-adba-4746-b943-318be82dbd4c',
    'identification_number': 103770488,
    'item_consumption_1': 537,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 227.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e4e55cc-cdab-4f3b-8499-d0d46748dad6',
    'identification_number': 76403955,
    'item_consumption_1': 1336,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 522.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c6ac56dc-cb3a-4610-bf44-be9740804e71',
    'identification_number': 108565726,
    'item_consumption_1': 500,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 195.63,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '98c4d7ff-6857-408e-98ee-666fb0e14481',
    'identification_number': 16724275,
    'item_consumption_1': 4326,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1833.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a60be0d-60c3-4e54-ad92-24bd503f175a',
    'identification_number': 67678564,
    'item_consumption_1': 513,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 776.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46cfac34-76fe-4a50-bb19-1e36a7b95ac0',
    'identification_number': 48432130,
    'item_consumption_1': 666,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 260.57,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4bab471e-9342-42c4-9640-5ac1a3ce8c12',
    'identification_number': 11199954,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3aa4bd09-159e-45a4-bc91-2da38d7cae1f',
    'identification_number': 22357890,
    'item_consumption_1': 5788,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2450.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15c5d1bc-9335-40b9-9e88-4af4517c3f45',
    'identification_number': 12991996,
    'item_consumption_1': 7107,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3021.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a8d3b2d-494b-47fc-9c89-d18f6a2f4650',
    'identification_number': 107674149,
    'item_consumption_1': 14872,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1898.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '062723ed-b60e-4725-81ab-290b2f8620c3',
    'identification_number': 68559593,
    'item_consumption_1': 628,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 266.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a25fc04-584d-4b66-a60d-255fb9d91301',
    'identification_number': 103033890,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a120ae5-eab3-432e-bffa-4dd2ee0d7d0f',
    'identification_number': 20587155,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 228905.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b12ccb02-eb2d-460c-a5aa-b54bda850ea8',
    'identification_number': 21718989,
    'item_consumption_1': 15,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 5.86,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ddef0d75-0497-481d-bc70-4acf4246b16e',
    'identification_number': 14642484,
    'item_consumption_1': 9434,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1204.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0c89afc-a793-4f04-9ede-6c95caa11e56',
    'identification_number': 68029098,
    'item_consumption_1': 4.34,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 203.26,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a7c06e3b-0de1-4954-b448-49ad41dfbe09',
    'identification_number': 30290635,
    'item_consumption_1': 6.79,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 317.99,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'db04075f-8022-4336-a014-eb324647a581',
    'identification_number': 37969064,
    'item_consumption_1': 79,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 46.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4470772e-bc1e-41dd-9431-3347280d89bb',
    'identification_number': 62191780,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3636.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74780a7d-84c7-4d57-9aed-84a794623cc7',
    'identification_number': 11346221,
    'item_consumption_1': 0.14,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e8ff4796-ea2e-4570-83c4-44024e04ab05',
    'identification_number': 103721835,
    'item_consumption_1': 1.26,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 30.06,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '33c5e318-bffa-42d7-a415-70b135151f5d',
    'identification_number': 15167640,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13986514-d610-4f02-8437-9f8dd815960c',
    'identification_number': 75402351,
    'item_consumption_1': 1480,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2238.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '246af5d5-4cb1-434d-ab8a-1c18f2536297',
    'identification_number': 84599391,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 106.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a73ebe56-66af-4d3e-8ae8-5b65e058bea8',
    'identification_number': 13924605,
    'item_consumption_1': 21.1,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 503.25,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5a7f5c7f-f1db-4620-9074-55a201daef37',
    'identification_number': 99713349,
    'item_consumption_1': 436072,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 55658.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f2937ff-4367-4cff-83d4-67a624600c03',
    'identification_number': 14225093,
    'item_consumption_1': 0.59,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 27.64,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '84a6bdf0-3900-492f-970e-83f885bdbe0b',
    'identification_number': 12991996,
    'item_consumption_1': 7107,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2780.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffef4bcf-2719-47ca-bfba-f74a85974ea9',
    'identification_number': 19302711,
    'item_consumption_1': "'-832.0",
    'item_description_1': 'ENERGIA INJ. QUC MPT TUSD 11/2023',
    'item_total_value_1': "'-290.06",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '81d3961f-3868-454a-b62b-e70e7b958a4b',
    'identification_number': 106141864,
    'item_consumption_1': 601,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 909.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab21af24-be49-4611-89a9-859b894723f0',
    'identification_number': 30035473,
    'item_consumption_1': 2355,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1001.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0072ad50-b76d-4d45-814b-cd735390c212',
    'identification_number': 100612075,
    'item_consumption_1': 1486,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2248.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1eb44f65-c279-4db6-b873-dd448a6ac228',
    'identification_number': 21966230,
    'item_consumption_1': 75,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 1788.78,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'b3f1e2be-5f63-4ef0-80a1-d0b50b68a23e',
    'identification_number': 34009655,
    'item_consumption_1': 1342,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 524.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3a71b5b-22bf-48ba-9a31-ed7833ea5fd1',
    'identification_number': 46425152,
    'item_consumption_1': 2365,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 921.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87a5198a-305d-4bd4-8a46-df6a1d66e12c',
    'identification_number': 107049910,
    'item_consumption_1': 115,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 44.99,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cfb23826-bc56-45d8-b28f-7a8b5b84d689',
    'identification_number': 107158876,
    'item_consumption_1': 286,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9cbda610-a3b2-4d66-bbd9-171c45766671',
    'identification_number': 75789981,
    'item_consumption_1': 6.08,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 145.02,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '05ae3324-97fb-4b91-a163-95f6bfe88435',
    'identification_number': 43505554,
    'item_consumption_1': 3935,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1672.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d9ff427-ca30-4726-8e4d-abb8e6da225b',
    'identification_number': 31903991,
    'item_consumption_1': 72932,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 27141.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c60a4709-50a9-4a9b-b610-2e8bd496bc9b',
    'identification_number': 29896975,
    'item_consumption_1': 73,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd171250f-0c1b-426f-bb98-66fbb8f1b165',
    'identification_number': 44476256,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b681f978-67e6-4cca-9848-d33a3772ac0f',
    'identification_number': 16307828,
    'item_consumption_1': 39.7,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1154.71,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8ef3f04b-c9ab-4205-ba77-00bcb005e881',
    'identification_number': 47472006,
    'item_consumption_1': 45.85,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1333.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9126ed54-b434-4765-92c6-351b21cceec9',
    'identification_number': 37328867,
    'item_consumption_1': 6115,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2593.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc292f22-3fd4-4984-988e-8b625dadc6c9',
    'identification_number': 19450745,
    'item_consumption_1': 57.6,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1373.8,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4273a8b4-dfa7-4b90-842d-82968f12fdf8',
    'identification_number': 33760900,
    'item_consumption_1': 304.29,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 8850.56,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ea07848c-fe9f-4fe5-aa5b-2f8289e7d219',
    'identification_number': 38747570,
    'item_consumption_1': 680,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 288.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd03d1b03-1b3d-4145-be94-58e2e93d673c',
    'identification_number': 30085918,
    'item_consumption_1': 2011,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 256.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca533c4e-18c3-44f6-ac97-f71a9ad1cd5c',
    'identification_number': 12718750,
    'item_consumption_1': 19294,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2462.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f7fba18-77c9-470a-a2d3-44fe9b6835d5',
    'identification_number': 58741798,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f71e47ab-2b06-4003-8f31-f7e3adb050fb',
    'identification_number': 32727917,
    'item_consumption_1': 155.79,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4531.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9c18129e-7a59-430c-9c6c-553d4a005231',
    'identification_number': 68079257,
    'item_consumption_1': 48.36,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1406.59,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '86dc4184-8174-4cc9-b846-8e687693aa32',
    'identification_number': 16879562,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97705bed-a5b1-4243-bdb4-c8a8b8f01ed0',
    'identification_number': 72307706,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9602c74f-102e-4059-bea5-5f5ad860b3fa',
    'identification_number': 55524567,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'be4ff0cb-1d3d-428f-960e-bc56a3a3d831',
    'identification_number': 103215310,
    'item_consumption_1': 2391,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1015.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3dfc6454-2e8c-4991-b53b-5c4f1274f221',
    'identification_number': 9189939,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 158.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9e93aa85-6b6e-472b-9a95-a719fda8baaf',
    'identification_number': 21185123,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37a0ad10-ceb3-4b08-9dc4-77217e29eb83',
    'identification_number': 28065557,
    'item_consumption_1': 5073,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1976.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f44ef85-9c85-47ad-b153-e7e8ffb9f624',
    'identification_number': 37423258,
    'item_consumption_1': 444,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 173.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92ca9a2d-35c0-479b-9a3d-8e5aa54417b5',
    'identification_number': 91868041,
    'item_consumption_1': 3181,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1348.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32fb6cff-1369-4483-8ac8-a86aa14f3f36',
    'identification_number': 26705230,
    'item_consumption_1': 1715,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 728.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8502267d-58e0-414b-9340-311802e2913e',
    'identification_number': 55316891,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '917b6bea-42d4-4e5d-9d09-bd7480340625',
    'identification_number': 107941392,
    'item_consumption_1': 599,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 349.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d60bcab-8ab7-4f45-bf40-21d93f530b49',
    'identification_number': 84648635,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95035d1c-db3b-4f82-a122-c61897419187',
    'identification_number': 28293991,
    'item_consumption_1': 373,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 158.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a3b85de-3f9a-41a4-a645-cff34a91909d',
    'identification_number': 90934520,
    'item_consumption_1': 10133,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4296.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d10b44d-13dd-4760-bcc7-e48426e03734',
    'identification_number': 90604679,
    'item_consumption_1': 3509,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2049.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54a8bd44-ff54-40d0-9ef4-70815e013387',
    'identification_number': 96292962,
    'item_consumption_1': 5262,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2228.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6166a108-3224-4700-87b6-47a213f773f9',
    'identification_number': 15084175,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '66de2f36-bf9a-4da0-976e-035fe5ea1b23',
    'identification_number': 39452336,
    'item_consumption_1': 861,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1302.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7119d632-f17e-4fce-9c4c-64158f8852ef',
    'identification_number': 11013958,
    'item_consumption_1': 2872,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1677.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '435267d0-41d1-430a-acd2-4a95644ba3cd',
    'identification_number': 84969466,
    'item_consumption_1': 25.53,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 742.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '44d93eea-df10-40ff-80eb-798cd29741fe',
    'identification_number': 103164111,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4d9d0765-7014-49f6-aa47-4672a5294e59',
    'identification_number': 91358469,
    'item_consumption_1': 2359,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 918.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb85b826-822a-4336-8ddd-4b351c3dcc14',
    'identification_number': 93430442,
    'item_consumption_1': 18184,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2320.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07ba7628-4740-4c2a-b407-36c6c80d0c75',
    'identification_number': 78143977,
    'item_consumption_1': 997,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1508.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51e55ed8-a4aa-49d4-9a11-9eced9004daf',
    'identification_number': 27054535,
    'item_consumption_1': 1210,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 471.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7a95197-dc72-45bc-bc11-bac2a71805ae',
    'identification_number': 101400470,
    'item_consumption_1': 343,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 145.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74dce766-c8a5-4c53-9a01-b0301d3feaf0',
    'identification_number': 104585102,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ef00602b-8cbf-4afb-8f9d-31234aabe75c',
    'identification_number': 110480252,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a9c5701d-34a5-400a-a45e-af47c84cf6f3',
    'identification_number': 18643582,
    'item_consumption_1': 6.44,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 153.61,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '233dde88-a944-4faf-90ef-a9717ec30ece',
    'identification_number': 36253642,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 68.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '745898c3-7867-41fd-bc40-310998ade600',
    'identification_number': 102014990,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f07b3e1b-b77f-477b-82ca-86f11bdc36e4',
    'identification_number': 107539012,
    'item_consumption_1': 190,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 80.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c535aeff-0e61-4293-8486-9cb30e16c32d',
    'identification_number': 17401682,
    'item_consumption_1': 26,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 39.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '798cf0fe-1636-4478-96d0-2c1560d85438',
    'identification_number': 20514310,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fc9e413-48a7-4f1f-94c7-6abcf1ad4ea5',
    'identification_number': 43750583,
    'item_consumption_1': 136269,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 17392.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b045542-9475-413e-9d4e-b4afb1d3a7ac',
    'identification_number': 20476183,
    'item_consumption_1': 7937,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3098.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '199b7294-db38-4f20-86f1-af97baef8a4b',
    'identification_number': 12222615,
    'item_consumption_1': 91110,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 33906.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4aa88ff7-1f09-4d6f-8e84-8c0cd27aca68',
    'identification_number': 68630999,
    'item_consumption_1': 432,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 183.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b186be79-71c7-4c96-8fc2-326fbc4064f3',
    'identification_number': 79948790,
    'item_consumption_1': 3532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1500.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2fe0e095-ec8b-4b0c-9921-bbcee7efc457',
    'identification_number': 33625930,
    'item_consumption_1': 182.88,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 5319.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'be181f94-1101-4afa-9c10-085f851262d3',
    'identification_number': 46349030,
    'item_consumption_1': 548967,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 70068.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b29832b4-670a-421b-8adb-1d2ef8310305',
    'identification_number': 67678564,
    'item_consumption_1': 13.89,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 331.28,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '87723055-2e73-483d-a736-006fd5ef45da',
    'identification_number': 109398998,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39db4cc3-a75d-4729-b72c-1b85ca230d68',
    'identification_number': 86283154,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8b53da15-93fb-4a3a-ac59-636212b22b31',
    'identification_number': 63572486,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.67,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bdbf9cac-19a6-4647-a1f8-a46990bdb7b6',
    'identification_number': 34346007,
    'item_consumption_1': 2123,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1239.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f1a48e2-97d6-4490-b588-0ac6ce6ccff0',
    'identification_number': 91350930,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e54d058b-ba48-4f29-b5ed-01f3693a52f3',
    'identification_number': 81217846,
    'item_consumption_1': 14139,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 8257.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23e982fb-c7bf-4569-b01a-518d30c8602b',
    'identification_number': 59101580,
    'item_consumption_1': 4934,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1836.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e04c757-13db-41b2-9185-2ab322f03b1f',
    'identification_number': 107480409,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f74cef5e-29f9-4a7b-9f10-5731663fb4cc',
    'identification_number': 99678195,
    'item_consumption_1': 2541,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 990.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11f4fbd5-2c57-421d-af9c-1428478bd2dc',
    'identification_number': 37781456,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '02cc2668-2ed1-4553-b19f-dab5a84aee27',
    'identification_number': 97856681,
    'item_consumption_1': 114,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 44.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '941377b4-a4ba-458b-84c3-2d39f2afc211',
    'identification_number': 33375453,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 0.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0233377-9211-4216-ba34-71aeb264d151',
    'identification_number': 42710529,
    'item_consumption_1': 1953,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1140.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '200d3a9c-0406-4b2b-bcd3-cfc5fede5a1f',
    'identification_number': 98913972,
    'item_consumption_1': 118,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 46.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e71a1def-06d2-4263-9c4f-1344b7dea882',
    'identification_number': 4003474899,
    'item_consumption_1': 23.8128,
    'item_description_1': 'Demanda [kW] TUSD NOV/23',
    'item_total_value_1': 770.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c4357b3f-370f-4e8f-8895-b22e4c2a72f5',
    'identification_number': 8084661,
    'item_consumption_1': 437,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 661.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6cfeed95-4cc0-4877-969c-afd35e020db7',
    'identification_number': 42433339,
    'item_consumption_1': 6409,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2713.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd366986b-1452-4983-998b-a4032f6aeaa8',
    'identification_number': 110636392,
    'item_consumption_1': 1053,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 411.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac4380b3-7752-411c-8f44-5592632d7368',
    'identification_number': 18017703,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 177.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '05a8a85c-1b17-449e-86fa-3cd69cc40ac3',
    'identification_number': 12673650,
    'item_consumption_1': 70.79,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2058.98,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dcb9d3b8-b7b3-4e1a-b764-5b4fc5929ddf',
    'identification_number': 13008404,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d4ae726-31c6-41df-98c5-b5e896e7a381',
    'identification_number': 12804940,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bc5c79d8-72a0-4c70-818e-b26f1e34db7b',
    'identification_number': 40768538,
    'item_consumption_1': 1547,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 655.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed7a6aa6-4a72-4cb7-854e-c0a3a79acdfc',
    'identification_number': 19486235,
    'item_consumption_1': 4475,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1749.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cde2fd2f-b545-4b75-a5a1-1e438d0347fa',
    'identification_number': 12977489,
    'item_consumption_1': 20004,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7444.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c9a2357-ee17-45f6-8a7d-090981ec3106',
    'identification_number': 13715666,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd1f698ee-4009-451a-a03d-bc9f5543adb5',
    'identification_number': 7752628,
    'item_consumption_1': 2524,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1070.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '310b5f89-071e-441f-ae7a-f6b03d1b0c95',
    'identification_number': 108331024,
    'item_consumption_1': 10888,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 6358.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58e3f909-6c9e-463c-b9da-cca0ccd6c28f',
    'identification_number': 28168631,
    'item_consumption_1': 4553,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1927.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25978f26-6265-4c5c-9784-b0d908c49144',
    'identification_number': 18217290,
    'item_consumption_1': 542,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 211.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ce3f0fb-a530-490d-8621-a0ab6d982780',
    'identification_number': 40768090,
    'item_consumption_1': 1422,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 602,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2570d225-743e-4f30-a60f-72b4e96a8a90',
    'identification_number': 5345227,
    'item_consumption_1': 4094,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1596.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a24844ca-028a-4d18-8be6-90d6a3769cba',
    'identification_number': 35137010,
    'item_consumption_1': 346,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 146.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfede357-0cce-4b12-b4f5-003f40cf2d3c',
    'identification_number': 109034333,
    'item_consumption_1': 32.76,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 952.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '409f2cda-5bef-4589-a75b-0579cd85df07',
    'identification_number': 22734318,
    'item_consumption_1': 1.58,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 583.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b44fdbb-49b3-404b-b55c-b296c7875bca',
    'identification_number': 33688907,
    'item_consumption_1': 1063,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 415.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0296b4e-e01f-4a87-bfb7-5cc568cac770',
    'identification_number': 46523286,
    'item_consumption_1': 2697,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1575.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30c2e5d0-788a-4fe4-ba4f-06d57a2992df',
    'identification_number': 96215445,
    'item_consumption_1': 7958,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3375.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5db200a-1acb-48df-a76f-128d93dbbb81',
    'identification_number': 30035473,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '79e7c441-4450-4ef0-beed-6e61a6643dfb',
    'identification_number': 9921273,
    'item_consumption_1': 8.14,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 381.21,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f5369754-fa52-46b4-b73c-f36f23bd27af',
    'identification_number': 96197080,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22d41c50-629c-4eda-a3e0-e6a983534e5d',
    'identification_number': 95917160,
    'item_consumption_1': 20474,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7612.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2077a8c1-40c1-4825-93fd-0e59ae495a48',
    'identification_number': 18647480,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1128957-8945-456b-8bbf-88c1ea61504f',
    'identification_number': 40768333,
    'item_consumption_1': 1021,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 434.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ef3ffe0-07d8-488f-9341-e9a0e6121695',
    'identification_number': 19123310,
    'item_consumption_1': 2980,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1161.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c90ca52a-e737-4227-b392-5d7b76a251ef',
    'identification_number': 40768430,
    'item_consumption_1': 1261,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 493.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '587fef70-a40a-4ed9-a244-eda21d575d21',
    'identification_number': 91304083,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b8b6ff27-cb32-4e68-9f40-85e35c5f85da',
    'identification_number': 97575941,
    'item_consumption_1': 10302,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3833.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a40a44bf-cea1-4052-bff4-f508e47c88e3',
    'identification_number': 6949738,
    'item_consumption_1': 4842,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1892.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de4ce758-badd-45de-8cd6-bfcdbb8a44be',
    'identification_number': 76408922,
    'item_consumption_1': 8137,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3181.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51415103-0315-4507-b459-75c9d15669a6',
    'identification_number': 97856681,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '66d63d06-4136-4512-9314-62746d625621',
    'identification_number': 93007264,
    'item_consumption_1': 462,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 195.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f7d6a3d-7314-4074-a909-4e6e720b6545',
    'identification_number': 33670420,
    'item_consumption_1': 6934,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 885.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cea14ea7-0d51-4012-915f-578efc793734',
    'identification_number': 68081251,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 7.83,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6e257376-0a0f-4167-9605-8c256395284f',
    'identification_number': 33235953,
    'item_consumption_1': 972.72,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 28292.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '81b53040-2a30-4aec-ab9d-b30dbbd5eb30',
    'identification_number': 106708120,
    'item_consumption_1': 2991,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1746.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb3c5fcf-8f88-4d45-a539-86ac7231dea2',
    'identification_number': 110227379,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b9e02eb-8ec2-4fc5-be6c-47e397f78421',
    'identification_number': 92140394,
    'item_consumption_1': 66.61,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1937.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7b564a24-2bdc-4100-a0a0-b8e8907afbc9',
    'identification_number': 44634374,
    'item_consumption_1': 2586,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3912.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1da26c3a-9058-4043-9e98-9de2eb9d5977',
    'identification_number': 99051770,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3dbf079e-9734-4ce7-ad59-963545be6cd2',
    'identification_number': 79645674,
    'item_consumption_1': 72,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 30.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd707d3e4-f898-4d2a-b35b-926058303bd9',
    'identification_number': 17401682,
    'item_consumption_1': 26,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 15.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a18d262-9396-44d6-adf4-c06f8622ed5c',
    'identification_number': 91670942,
    'item_consumption_1': 17788,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2270.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6dddccee-38dc-4ec4-a147-40207c126b83',
    'identification_number': 85177342,
    'item_consumption_1': 16167,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6316.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e646fd61-d2ac-41bb-bab7-ec199e347c5e',
    'identification_number': 46259430,
    'item_consumption_1': 436,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 170.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40502e7f-1b29-4cad-ad98-ecaa952f2f72',
    'identification_number': 45907137,
    'item_consumption_1': 719,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 419.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23867293-109f-4fcf-803a-02f10e6c379f',
    'identification_number': 111833825,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21497645-3541-4604-a1f4-aa9d20dd0cbb',
    'identification_number': 19307098,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 011/016',
    'item_total_value_1': 167.83,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': 'ecb0e435-f9ff-43d7-8796-59b02ace52d4',
    'identification_number': 62072137,
    'item_consumption_1': 2886,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1125.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b88d988-94bb-405e-ac0c-a589ddef8cc1',
    'identification_number': 93580061,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c8b2118-2f42-4e5c-9daa-8ae6fe2cc18b',
    'identification_number': 101937792,
    'item_consumption_1': 152,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 59.47,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c0ef3123-dcd0-4846-85f8-31cbdf31d4be',
    'identification_number': 12426393,
    'item_consumption_1': 10180,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3967.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ef528a9-b714-4260-a6f7-dcc9fd4ba5ee',
    'identification_number': 110006623,
    'item_consumption_1': 1289,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 503.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44dad508-7fa9-47f7-8e61-c0f3b183df90',
    'identification_number': 60720514,
    'item_consumption_1': 71.48,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2079.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd718d7b7-e7fe-4c6a-9797-5d3a274ebea8',
    'identification_number': 11346221,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '39f01362-b0a4-449d-a3aa-a64d40995524',
    'identification_number': 101890419,
    'item_consumption_1': 0.39,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 11.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '63063e31-ed80-4e8c-9d62-f5f1c02779cc',
    'identification_number': 63964430,
    'item_consumption_1': 7438,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 949.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d914b19-9b71-4586-b2c9-e37a85da0cb0',
    'identification_number': 48843075,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c2577ca4-e96b-410e-96c0-38ada79ddff4',
    'identification_number': 103306633,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ddf140c-a8fd-4d09-8638-abf6d52c3fcf',
    'identification_number': 110019377,
    'item_consumption_1': 75,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 29.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bfa00d64-df9e-4256-855a-43969a093831',
    'identification_number': 100214193,
    'item_consumption_1': 8494,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3161.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1aebce6c-b7a9-446f-a75c-c094b6d08341',
    'identification_number': 47023090,
    'item_consumption_1': 2441,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1425.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56166378-984d-47a8-ae5f-4be11a77d360',
    'identification_number': 44010141,
    'item_consumption_1': 34,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 13.29,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'dd2bcb02-1436-401b-9a95-7cf6518a5b4a',
    'identification_number': 18898068,
    'item_consumption_1': 107.68,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 12299.2,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f436506b-72f2-4135-9ed3-71a9cbef5ff0',
    'identification_number': 104764503,
    'item_consumption_1': 577,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 244.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55570c63-cff5-4075-bbc9-28dc2a50f93d',
    'identification_number': 44010141,
    'item_consumption_1': 548.64,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 15957.72,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '41927fb0-2f9e-4ed1-b27f-1993cdd2964b',
    'identification_number': 101073593,
    'item_consumption_1': 47,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 18.39,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7b23e2f0-2570-4439-b766-cdf397561f91',
    'identification_number': 13877984,
    'item_consumption_1': 30.03,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 873.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0b1f12c1-4a27-4e7d-9f05-88b654fb676e',
    'identification_number': 32074123,
    'item_consumption_1': 45222,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16829.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dab0c252-9261-4894-ab8f-0e8328bd4a9a',
    'identification_number': 65432940,
    'item_consumption_1': 653,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 277.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aec3f5ce-d5d4-4434-9717-079d38587d01',
    'identification_number': 65191404,
    'item_consumption_1': 8.11,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 379.8,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3d06816d-3e10-4c9c-b6dd-76918dc72ea4',
    'identification_number': 33558540,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c6a02f6-eaa1-4199-9190-fef761f4aa60',
    'identification_number': 102765707,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eef22dec-f6c8-467d-aed4-5d3fd72fb044',
    'identification_number': 84327855,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6bf0f36-ec08-474c-a60b-3bb4b015fa91',
    'identification_number': 9257780,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 121.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1c00b70e-8cfc-4cfe-acf3-40651256cb9d',
    'identification_number': 32008465,
    'item_consumption_1': 1895,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 803.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '896628de-42e8-4eaf-9a83-a391f705673d',
    'identification_number': 109785983,
    'item_consumption_1': 4438,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1651.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65a11504-a884-4f8e-89f4-031e35a8ad45',
    'identification_number': 17582890,
    'item_consumption_1': 919,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 359.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4311389a-03ce-4432-b203-72ee06533d3a',
    'identification_number': 111198542,
    'item_consumption_1': 1987,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 843.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bcdf728a-5134-4876-953f-8d22b6888c34',
    'identification_number': 97851000,
    'item_consumption_1': 97,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 41.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a42e5a6e-151c-4c16-8299-536eca028adf',
    'identification_number': 15444740,
    'item_consumption_1': 2355,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1000.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ac00033-1a7d-4b4b-b79d-4c76bacbf9a8',
    'identification_number': 32595921,
    'item_consumption_1': 23114,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8601.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c6bb843-e949-4961-86de-5118a0273bee',
    'identification_number': 7752628,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 105.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ee9be7b7-f91d-4e0c-b0d6-f4cfd6296e1f',
    'identification_number': 105088730,
    'item_consumption_1': 59446,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 22122.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04aa18b8-acbf-4709-bb79-4cf8c11db7e9',
    'identification_number': 81860684,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22de7506-6f34-4afe-81b7-0ecd3feac328',
    'identification_number': 8992053,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 209.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c62ea9a0-08e6-4c1e-a1c3-52109f4fdb40',
    'identification_number': 54613612,
    'item_consumption_1': 813,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 317.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '812d4872-9773-49e9-ae6a-0c3a2da49620',
    'identification_number': 43195300,
    'item_consumption_1': 123.54,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 7055.35,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1edd117b-98e1-4d93-8b7b-46112fbf7c09',
    'identification_number': 109594916,
    'item_consumption_1': 1314,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1987.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8bddb59-d113-4ff7-9fa6-33ebe8e84796',
    'identification_number': 12174726,
    'item_consumption_1': 10632,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4516.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24bd4236-94a6-47a7-94d7-cef8505d0540',
    'identification_number': 45340340,
    'item_consumption_1': 7.39,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 429.88,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8593a517-71a4-451d-b545-b50cbad329e5',
    'identification_number': 6307272,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '631be195-fbfa-4447-a29a-9fa3d2757bbd',
    'identification_number': 935147792,
    'item_consumption_1': 1028,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 896.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83023d55-1fa6-416f-a414-7b43e07a3142',
    'identification_number': 33625930,
    'item_consumption_1': 6278,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3666.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b589fa2-66b0-4ca9-b5fb-b27e16a8a06f',
    'identification_number': 104871563,
    'item_consumption_1': 1553,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2349.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a296659c-20fc-439b-af8e-20b2c623bf47',
    'identification_number': 33235953,
    'item_consumption_1': 40248,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 60887.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f595e5f7-1d79-47c1-b073-e68b5e7e09a2',
    'identification_number': 57109346,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79369185-5977-4779-ad7a-4c394f9a888b',
    'identification_number': 99102935,
    'item_consumption_1': 6.42,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 186.72,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '69be3aeb-874f-4a7a-9cef-9d4a77f04f66',
    'identification_number': 38109280,
    'item_consumption_1': 83.44,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2426.92,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '668328ed-1cd2-4872-9769-68b2fa313a33',
    'identification_number': 68902824,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 125.06,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '82cb3e9d-e33e-4f54-a262-e467f0fc4856',
    'identification_number': 105025437,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e7a9a6a6-e9ca-43a8-b63a-b1e4f34b73da',
    'identification_number': 98780956,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 183.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38f4c8ac-a427-462d-96fa-43e12e849c32',
    'identification_number': 8115710,
    'item_consumption_1': 551,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 214.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a2354e6-0dbc-4eec-84e3-db37b6df5341',
    'identification_number': 38370492,
    'item_consumption_1': 7376,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 11158.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '971a161b-8541-4b48-a2ee-5b4e694553c4',
    'identification_number': 34346007,
    'item_consumption_1': 19889,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2538.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1ccdc16-7b51-4a1d-83dd-ac52ea34131c',
    'identification_number': 32348533,
    'item_consumption_1': 16.15,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 756.31,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e459189e-1687-4e6d-8f89-4cfe584db37a',
    'identification_number': 105470708,
    'item_consumption_1': 12733,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4738.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be99cc9e-b250-4189-b464-d9ee5a3c20d8',
    'identification_number': 99211823,
    'item_consumption_1': 60.27,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1753,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8cc1eb1d-fa4e-4385-81b5-98715a8c0346',
    'identification_number': 80200052,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 105.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '58eb0be8-da32-4365-bb81-031f1116ef34',
    'identification_number': 52773426,
    'item_consumption_1': 614,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 239.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a09acc60-f871-47eb-8452-f96e3851e340',
    'identification_number': 98005472,
    'item_consumption_1': 6414,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2504.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64414eba-4556-4dc5-a14d-b233dfe885ab',
    'identification_number': 110920813,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3005ca4b-e132-4adb-bd81-b8ed63b9d13c',
    'identification_number': 4334221,
    'item_consumption_1': 19253,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 11244.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f03e6a83-24c7-4e30-b93b-b0a11f6a9eef',
    'identification_number': 93811535,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c92da05e-0a5f-4086-a810-504748b3a244',
    'identification_number': 79723055,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b1f7b180-93ed-48f5-b697-c7c5b66412c7',
    'identification_number': 32927061,
    'item_consumption_1': 9272,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1183.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1db244f3-0f58-4f99-bffa-cae8e0134219',
    'identification_number': 23274310,
    'item_consumption_1': 19533,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7269.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68a4358a-90ec-442e-bf75-9a93d7b8ac63',
    'identification_number': 107238195,
    'item_consumption_1': 1489,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 580.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5594457-b1a7-45eb-aae8-f0cd7e98d1f5',
    'identification_number': 12797707,
    'item_consumption_1': 3.7,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 88.27,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '68fed5a4-3afe-4fec-8ec5-4634b0f957a6',
    'identification_number': 108788954,
    'item_consumption_1': 41.82,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1216.35,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5131b2c4-a8e5-405b-a283-cbf21b92a4df',
    'identification_number': 17229162,
    'item_consumption_1': 4002,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1559.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1b748e0-ad4c-4f74-b266-8ce2899f52e3',
    'identification_number': 15538320,
    'item_consumption_1': 2800,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1190.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73dea2b7-507f-446e-bb8e-b06d4b4bb79b',
    'identification_number': 110111184,
    'item_consumption_1': 208,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 81.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a38a1b5c-e62d-40a0-933d-9b64f4da615c',
    'identification_number': 94315540,
    'item_consumption_1': 3263,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1270.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77f5cedf-e73c-4f72-96f1-1e0b2ac39046',
    'identification_number': 21818983,
    'item_consumption_1': 4162,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1624.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36d8e626-98c1-4e1c-8f5c-3f2e2ac2a049',
    'identification_number': 19403216,
    'item_consumption_1': 122,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 47.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f3d4aad-3998-43c0-bb19-f5e989b7c559',
    'identification_number': 101941714,
    'item_consumption_1': 40.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1164.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '35188e15-be82-43c0-b7ad-d3aa66514140',
    'identification_number': 93102895,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9245817b-d30c-4e7f-a09a-747dbdf2ddb8',
    'identification_number': 59355573,
    'item_consumption_1': 387,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 585.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dccb9905-b858-4cc1-aab8-d7e93ae94537',
    'identification_number': 39162567,
    'item_consumption_1': 2858,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1116.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd33aa3ff-0725-4066-8c91-a2f45b38e0f9',
    'identification_number': 63416794,
    'item_consumption_1': 295,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 115.41,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a50aa3c5-02df-4162-b8f2-ae8cc84fee50',
    'identification_number': 19873727,
    'item_consumption_1': 3720,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 474.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1695637-ac8d-4ae2-8713-0b9500361727',
    'identification_number': 10125949,
    'item_consumption_1': 4665,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1980.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7649245-dbab-4ded-937a-d9a7cd471a6e',
    'identification_number': 101837720,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'af0a4ed3-6a8a-4aac-acda-671f3da3b861',
    'identification_number': 99688816,
    'item_consumption_1': 919,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 390.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a1f484a-3901-45be-90af-a5ff2235f7b1',
    'identification_number': 48004936,
    'item_consumption_1': 49.88,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1450.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '020f73e3-4734-4d9a-b5c8-504230cdfbdd',
    'identification_number': 18295134,
    'item_consumption_1': 725,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 283.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92ffa0c1-37b2-489a-bc1e-72f898f1248f',
    'identification_number': 58072004,
    'item_consumption_1': 38419,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4903.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75521ff9-8143-40ba-bdb8-3df86c1f1eae',
    'identification_number': 103192255,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e3c46fe3-d108-4162-8463-611110046d46',
    'identification_number': 100968422,
    'item_consumption_1': 2978,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1160.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e88fef97-8afc-4247-92c5-49784e0a10a0',
    'identification_number': 15002578,
    'item_consumption_1': 39177,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5000.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a5153e7-0aea-46d5-b701-1335104aafa0',
    'identification_number': 52512576,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ae9c1e1b-e01a-462e-9593-d96f0aee4f40',
    'identification_number': 107433427,
    'item_consumption_1': 3058,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1191.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea8b5fb5-d0b5-4a9a-b7ab-5069eac4c3b9',
    'identification_number': 68447450,
    'item_consumption_1': 10118,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3765.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fac0ec9d-1075-4f8f-b85c-c77e01636ec3',
    'identification_number': 97837784,
    'item_consumption_1': 188,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 79.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '496349b5-07fb-444c-baad-8d7c4fea92b2',
    'identification_number': 59044659,
    'item_consumption_1': 722,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1092.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3aa6eb1-51b3-44d2-8926-07a45e8d685d',
    'identification_number': 59422009,
    'item_consumption_1': 584,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 883.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a67e282-ea0c-499e-ac74-782c7156dc05',
    'identification_number': 82220239,
    'item_consumption_1': 2505,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1060.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62179fe6-a987-4ffd-923e-683d15d82ea4',
    'identification_number': 19295855,
    'item_consumption_1': 3614,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1412.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4121e0db-b4de-4db7-b4d2-37fc83b2ab54',
    'identification_number': 50973665,
    'item_consumption_1': 3413,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1329.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3bbccac8-0fc5-4db5-a623-ce4e60a2a2db',
    'identification_number': 30085900,
    'item_consumption_1': 1619,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 206.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e7244ae-f2cc-4108-b9b9-46f32774e8f6',
    'identification_number': 36878685,
    'item_consumption_1': 3811,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5765.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef6b2067-049c-4d0b-abe1-88098efd25c4',
    'identification_number': 22963677,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '612b01d5-023f-4c78-bca3-1d4ae66d3ddd',
    'identification_number': 96338210,
    'item_consumption_1': 3676,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1434.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3581d6fe-7f8e-4693-8324-258833592bd9',
    'identification_number': 19875460,
    'item_consumption_1': 868,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 368.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fc0cf56-7694-44fa-89c8-8c6fc60aef18',
    'identification_number': 64167364,
    'item_consumption_1': 3719,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2172.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7a8157f-fa16-4050-8400-4837183b1631',
    'identification_number': 69703515,
    'item_consumption_1': 11398,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4832.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '064f01e3-b693-459b-8b19-27e2751516ad',
    'identification_number': 108309835,
    'item_consumption_1': 20945,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2673.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6e429c9-1c04-435f-b148-1894dce48d67',
    'identification_number': 22634207,
    'item_consumption_1': 2240,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 872.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3028c9dd-4d76-41ec-ae9f-46b1a5f8054a',
    'identification_number': 73311235,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2bee7a83-049b-4d59-a333-284679504588',
    'identification_number': 91355540,
    'item_consumption_1': 4908,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1826.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98cae480-a00f-470b-8d9e-109b115a053d',
    'identification_number': 104764503,
    'item_consumption_1': 577,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 225.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f63d099-50d8-4ec6-a8b4-c2c070124085',
    'identification_number': 62391453,
    'item_consumption_1': 29925,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 3819.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16dea668-5a0a-4e6b-a3f4-a0dd674eb6a4',
    'identification_number': 56706499,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 68.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0eeb6faa-c43e-47f7-aa5e-a2fe029ce959',
    'identification_number': 8086451,
    'item_consumption_1': 71889,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 26753.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96cdadfe-d72f-4452-b25a-5a6bc328376c',
    'identification_number': 98831968,
    'item_consumption_1': 0.28,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 6.69,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5386d4d3-daf6-4fd0-b962-a67e5579106f',
    'identification_number': 91319293,
    'item_consumption_1': 3379,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1435.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '111b62f2-0a16-4fd8-9d8d-7e9aa758d812',
    'identification_number': 17141168,
    'item_consumption_1': 4920,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2083.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9db934fe-75d6-4e98-84f2-93d058cc5dee',
    'identification_number': 923310436,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3586adcc-2430-426d-8433-8d417a79b2ab',
    'identification_number': 27092313,
    'item_consumption_1': 5092,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2161.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f07646f4-85bd-4ea0-b87e-52c8443c3d4a',
    'identification_number': 82211701,
    'item_consumption_1': 566,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 220.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '103b3bf6-3f21-4384-975c-396cc08f0dcf',
    'identification_number': 83644202,
    'item_consumption_1': 2965,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1259.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67fa0a1a-30eb-4a6e-af2c-2d5cb2ed387d',
    'identification_number': 9027092,
    'item_consumption_1': 21452,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2738.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '020bfb74-a02f-4dc0-b5a9-b4e1f9bfbc31',
    'identification_number': 109260473,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1ebb6cf5-6789-45e7-91b7-e96e11125003',
    'identification_number': 111114993,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10d49be7-c9f1-46ec-8e6b-3bb3ca391726',
    'identification_number': 6123341,
    'item_consumption_1': 2631,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1026.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9618446a-eacc-4873-bd1b-4136d5b03ab4',
    'identification_number': 108788954,
    'item_consumption_1': 1225,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 715.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6583f444-53db-4443-91b6-6176a0bb04dc',
    'identification_number': 79229093,
    'item_consumption_1': 199,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 84.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3da75a4f-8783-4795-a1fa-23552aaf4acf',
    'identification_number': 25976850,
    'item_consumption_1': 10515,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4456.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3cf5954-a166-48b7-9e06-84ed126e0476',
    'identification_number': 23175575,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a4b7b99-d6bc-4842-af06-7586140d7025',
    'identification_number': 95632867,
    'item_consumption_1': 1525,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2307.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96948078-60be-44ff-bc85-e928388500f5',
    'identification_number': 8115559,
    'item_consumption_1': 501,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 195.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b44a4f36-561b-44bd-8e41-63a204a90fbc',
    'identification_number': 97425907,
    'item_consumption_1': 279,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 162.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a465f924-fef6-44bc-b410-209f7ee3e00f',
    'identification_number': 9857389,
    'item_consumption_1': 1015,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 430.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2938067-0333-4a5a-b815-a527980e6ad8',
    'identification_number': 923325012,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '472182c9-6dc7-4606-a8b7-9c0fccb20f4f',
    'identification_number': 32556268,
    'item_consumption_1': 6849,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2671.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '634eae09-e7d2-446c-8efd-c677d76077fe',
    'identification_number': 41002768,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '86a701fb-d08b-4489-a262-35ad7b411d30',
    'identification_number': 98784870,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7061bad2-748d-45a6-9100-3aea414a9d4c',
    'identification_number': 19013230,
    'item_consumption_1': 1465,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 570.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7e30a54-69ef-46dd-a61e-462e3e537778',
    'identification_number': 109847059,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30437f48-cb61-4f0e-a9f5-c3b34b83751a',
    'identification_number': 16861469,
    'item_consumption_1': 1727,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1008.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51c37abc-0d81-4182-87f0-0b01ffbfd351',
    'identification_number': 101046820,
    'item_consumption_1': 4122,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1745.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8feac3e7-a1af-4cb1-a723-3b12350e85c3',
    'identification_number': 82743339,
    'item_consumption_1': 44545,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16577.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb0d3859-d196-4f39-8834-44acf625f3b3',
    'identification_number': 107367807,
    'item_consumption_1': 122,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 47.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24cb3fb0-4977-4109-b428-0cbae6b7793a',
    'identification_number': 99530600,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6b97cb2f-bd18-44ad-8219-da74ae5f6945',
    'identification_number': 92950310,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '10d79b8b-e9b9-472e-adc8-3c88e9aad910',
    'identification_number': 73533424,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b6596b8b-dd58-47c9-9e8e-61aa40ca236c',
    'identification_number': 103143742,
    'item_consumption_1': 1293,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 548.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6572668-5813-4489-9018-c223645af53e',
    'identification_number': 15658660,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 116.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7e373302-9aa4-4b22-b3af-0910d416ee60',
    'identification_number': 99801736,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c65082c8-51da-4c1e-99f7-bdbd20974ec9',
    'identification_number': 19301340,
    'item_consumption_1': 1639,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 640.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1338879-5b93-4b8a-8937-301dffc82561',
    'identification_number': 34346007,
    'item_consumption_1': 2.67,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 155.3,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '79ef3e97-b8d4-4bcc-b289-dcdbfdb14edc',
    'identification_number': 109859154,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8c8d701d-94a4-4284-98eb-0e59ee34841c',
    'identification_number': 104842334,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf4f2af1-8ee8-4153-91d9-439e43f5a820',
    'identification_number': 98514733,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cd0d1ba-078a-4787-b633-db9a2486b15c',
    'identification_number': 90469259,
    'item_consumption_1': 575,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 224.98,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '31ac3b1a-d36b-4e23-8fb6-406b1ef4336d',
    'identification_number': 57109346,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2dd71938-7688-40bf-becc-c90ead9dde15',
    'identification_number': 75695006,
    'item_consumption_1': 545,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 231.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f36f21ca-8a2c-4020-b2b5-507f04432f98',
    'identification_number': 56168748,
    'item_consumption_1': 1348,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 570.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e9a718e-592f-4d68-b88e-57cb9287cffc',
    'identification_number': 20587171,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 138587.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6fac4834-cd89-468a-98f2-069fd6e24ee3',
    'identification_number': 103424873,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a0e98139-6e10-43d9-a7ab-2fd21c93e505',
    'identification_number': 18563430,
    'item_consumption_1': 9500,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4027.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b728894b-2294-4dcd-91bb-c6f879df208c',
    'identification_number': 79211747,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-182.52",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '7cfda27d-c8dc-4a32-8047-6663cbd4c0a5',
    'identification_number': 43255280,
    'item_consumption_1': 5640,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2196.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d2ea12a-e574-46c6-ac0b-13ba49dd1fee',
    'identification_number': 74517686,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a867de35-e490-4980-b713-24429ec2a05c',
    'identification_number': 90990684,
    'item_consumption_1': 2829,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1201.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b603bec3-be1d-46aa-a873-3e2517fbd18c',
    'identification_number': 19541627,
    'item_consumption_1': 5551,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3241.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b95876aa-2b68-46a1-b110-b0846b78ae41',
    'identification_number': 936095230,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 87.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42bc678a-73e0-4668-aa9e-6daea6afac29',
    'identification_number': 14819821,
    'item_consumption_1': 29.96,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 1403.05,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4f72cb78-4f3f-4bf4-a7a0-7702f7c097c3',
    'identification_number': 36523402,
    'item_consumption_1': 380,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 161.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f12e0321-9303-4d68-952b-23b752dd894c',
    'identification_number': 108849104,
    'item_consumption_1': 1394,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 591.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54d9c6c7-7ba1-43e6-9e9c-33677bcd25e9',
    'identification_number': 43752187,
    'item_consumption_1': 449,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 190.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b7816bc-7e73-457f-bf30-5ac24e6a6c1d',
    'identification_number': 104906430,
    'item_consumption_1': 16950,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7204.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40d7e3b2-8ebb-4a67-8922-fc36a4cfaeed',
    'identification_number': 42709717,
    'item_consumption_1': 1811,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1057.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edefbf5c-3c8d-4243-96bd-cd725bd0eb69',
    'identification_number': 8899738,
    'item_consumption_1': 27360,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 10692.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9dbab4a-bec9-4743-bd32-e85e3373b181',
    'identification_number': 106242040,
    'item_consumption_1': 269,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 114.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24ea22f9-d5f4-4617-bbcb-7407f4812b36',
    'identification_number': 24039128,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd75ae669-a0ef-4611-9ddc-89cffe924741',
    'identification_number': 93636423,
    'item_consumption_1': 7923,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3363.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8215767-4ca0-4f5a-a5b7-fe38ba6a4bf7',
    'identification_number': 103001000,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2113e8c1-0936-4593-a3dc-c55134f1938e',
    'identification_number': 44502931,
    'item_consumption_1': 6.69,
    'item_description_1': 'DEMANDA REAT EXCED USD FP',
    'item_total_value_1': 194.57,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'de1478aa-ae85-404a-8838-f55f084f41ae',
    'identification_number': 76129136,
    'item_consumption_1': 10574,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4124.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8db6b9df-7bd6-4fd5-a7dd-db94ecb92410',
    'identification_number': 105623733,
    'item_consumption_1': 645,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 251.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab43e69f-fe06-4f5f-9447-91c810c9c00a',
    'identification_number': 98005138,
    'item_consumption_1': 1348,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 573.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '918f037f-fa39-4220-a5b9-c4fdee4950e6',
    'identification_number': 5330939,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '162c5838-2480-4fce-bdc5-9af14f21636d',
    'identification_number': 102780927,
    'item_consumption_1': 8500,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3606.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17fba623-6f54-4dcd-b695-bc80758aa3a1',
    'identification_number': 47280816,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '542dbf77-0681-4942-8c31-ea01fdf0fb49',
    'identification_number': 108178781,
    'item_consumption_1': 36.11,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1050.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3b958ab7-a196-49ca-9c93-131d970a8401',
    'identification_number': 54453844,
    'item_consumption_1': 38,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 14.86,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3192a8ae-1834-43f1-8099-d420b2fca1b4',
    'identification_number': 32076614,
    'item_consumption_1': 148,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 57.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3451aaf8-ea0c-4a38-ba00-f2fddc5e483c',
    'identification_number': 102341460,
    'item_consumption_1': 508,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 197.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a6700ba-97be-41bd-82f7-feecbd152ec0',
    'identification_number': 80647588,
    'item_consumption_1': 13362,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1705.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8797623d-f4fa-4b37-bf5c-b94345901d98',
    'identification_number': 107520028,
    'item_consumption_1': 3578,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1515.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11547cb0-f42c-49f6-8b2d-b923aa8e1fdc',
    'identification_number': 37423258,
    'item_consumption_1': "'-32.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2022',
    'item_total_value_1': "'-10.26",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '982b0d8a-7c4f-4f78-970e-f08f5b2830f3',
    'identification_number': 109447514,
    'item_consumption_1': 187.87,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 4480.81,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '258c93c6-c39b-40c8-b627-a34290641ceb',
    'identification_number': 105763101,
    'item_consumption_1': 44.96,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1307.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6606aab8-3e6d-480d-ad29-4ee436ae1fce',
    'identification_number': 106814427,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.93,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0312421d-3b4d-4192-a726-371ee03098df',
    'identification_number': 10980997,
    'item_consumption_1': 2125,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 830.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '639b67fb-f84d-444b-b1ee-f1488983123e',
    'identification_number': 96314443,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b93ed985-5061-433a-afaf-cfced5c9ddb1',
    'identification_number': 56699077,
    'item_consumption_1': 943,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 550.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '724fddfb-1f46-430f-99c8-e276a7487c21',
    'identification_number': 103727434,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV. JUROS AJUSTE FATURAMENTO',
    'item_total_value_1': "'-0.55",
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '490d275e-9e95-47e0-8074-12e883f0218d',
    'identification_number': 77724895,
    'item_consumption_1': 28036,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10433.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4396b700-cc79-449d-86b9-2154228ee13b',
    'identification_number': 94315540,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cdea8f66-fe77-4768-9a58-826b00c273ee',
    'identification_number': 13977873,
    'item_consumption_1': 1234,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 720.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25d9423f-133b-4ed8-8501-575bc59183a2',
    'identification_number': 3030777641,
    'item_consumption_1': 207,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 61.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '360a1721-282a-4c29-a5fa-fbafba4c7cba',
    'identification_number': 90604679,
    'item_consumption_1': 3509,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5308.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ac36eb5-13dc-41a4-a08a-d373ad611f2d',
    'identification_number': 75403943,
    'item_consumption_1': 27217,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10128.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efd583ef-859b-4acd-8e9a-272ae10a519d',
    'identification_number': 103202153,
    'item_consumption_1': 3317,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1292.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5227935e-2ccd-401e-b8f4-86181827620f',
    'identification_number': 22731530,
    'item_consumption_1': 2.417,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 892.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aab808d6-4552-4216-86ac-827f817d6108',
    'identification_number': 14489317,
    'item_consumption_1': 6448,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2732.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b123a7c-b9c5-49a5-bb0e-48b8baa8a46e',
    'identification_number': 101517556,
    'item_consumption_1': 4061,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1581.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74bfda48-f6ca-4c9c-93a5-e311da1f3be4',
    'identification_number': 83076654,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ecade555-b187-45f8-a541-baa98de838d2',
    'identification_number': 107350840,
    'item_consumption_1': 1045,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 407.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfa1abf1-bc20-4306-9adc-ac7f45485d09',
    'identification_number': 43141269,
    'item_consumption_1': 4458,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1741.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a11a2a9-8b0e-48cb-8d28-c4ecab50bdbb',
    'identification_number': 59863382,
    'item_consumption_1': 262,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 111.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d97012a-08e2-492f-994c-ff3d33d9809e',
    'identification_number': 60720514,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 7.83,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e983c319-3078-405a-b7cb-2865e7b04be5',
    'identification_number': 71395474,
    'item_consumption_1': 3321,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1409.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '309073e3-0f0b-41e0-8cdb-33366cd7e665',
    'identification_number': 9965394,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5db15243-5620-4d38-95e6-01d576fe409e',
    'identification_number': 3030777633,
    'item_consumption_1': 100,
    'item_description_1': 'Custo Disp Uso Sistema TUSD NOV/23',
    'item_total_value_1': 50.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6a3b7ae-c905-4d8e-9ca8-f02ad60edc66',
    'identification_number': 45766500,
    'item_consumption_1': 0,
    'item_description_1': 'COMP VIOL META CONTINUIDADE 09/202',
    'item_total_value_1': "'-2.86",
    'reference_month': '11/2023',
    'referente': 'Indicador de Qualidade de Energia',
  },
  {
    'uuid_items': '28cfb8b3-34f5-4b9e-9e6b-fa2c1dc9bf9f',
    'identification_number': 106141864,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e48f112e-8651-4647-9254-d8d76f672a64',
    'identification_number': 32151179,
    'item_consumption_1': 13546,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1728.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a31b10fa-0bf7-4858-9302-01cefa339444',
    'identification_number': 9027467,
    'item_consumption_1': 8157,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3454.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'faf54bd4-e179-48b1-b0a9-42bc0350bd8b',
    'identification_number': 44595972,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b31048d-a4d9-4c7f-8fbf-86f7ace14a9d',
    'identification_number': 70941378,
    'item_consumption_1': 6643,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2472.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '583234d2-aac7-4663-b5b1-a335b4c947d1',
    'identification_number': 40429458,
    'item_consumption_1': 323.98,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 18502.47,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5a9c4595-916a-4ec0-b7f9-9ec4616eee16',
    'identification_number': 19005210,
    'item_consumption_1': 384,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 163.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05425c98-8ad4-4f4b-8864-dc4a0fa9a64c',
    'identification_number': 96072091,
    'item_consumption_1': 11457,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4864.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a30e903-1988-47af-9119-59c9d3c2a95e',
    'identification_number': 99801736,
    'item_consumption_1': 20132,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2569.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf18a1c6-1626-4817-ac42-43a0432a4dea',
    'identification_number': 16105850,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a094f0b3-efef-425b-a056-db787c2028f0',
    'identification_number': 40013987,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a2a32a4-ade6-48e3-ba7d-554b27db6b36',
    'identification_number': 72702141,
    'item_consumption_1': 127.52,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 3041.43,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a364da2f-4d14-4651-86e3-59a499711825',
    'identification_number': 20602510,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7d4a5cc0-4e73-453e-ba0c-c3da78cda0e1',
    'identification_number': 12743542,
    'item_consumption_1': 0.04,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'd1e3f1fb-cdd3-47b3-abe0-e5e833bf3bd1',
    'identification_number': 22905139,
    'item_consumption_1': 4.264,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 1574.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '598651c6-38fb-49fc-8cd8-11751492078e',
    'identification_number': 59541288,
    'item_consumption_1': 9.54,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 277.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '51668090-e913-42ac-92e5-727d7fafeb7b',
    'identification_number': 81859945,
    'item_consumption_1': 24412,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3115.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed30d2bd-1715-425f-937b-5206f6103c6e',
    'identification_number': 21170762,
    'item_consumption_1': 1921,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 816.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '690223a6-d73d-4541-8bbb-f2eda87740f0',
    'identification_number': 103617515,
    'item_consumption_1': 1089,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1647.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8061fe71-393a-4c57-afdc-5a6e0cd4cdd7',
    'identification_number': 110543360,
    'item_consumption_1': 4691,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7096.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43a7ced8-2043-4fb5-84f4-d2ad3e958a07',
    'identification_number': 105154180,
    'item_consumption_1': 1297,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 507.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edb5346e-3496-430d-b946-8f46e5502846',
    'identification_number': 92739245,
    'item_consumption_1': 2423,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3665.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2729bfb2-66ef-4e97-8065-bf9796d85440',
    'identification_number': 64554481,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1782.39,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '550e687e-a65d-4109-9cb4-817998e9d18b',
    'identification_number': 15214133,
    'item_consumption_1': "'-12.786",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-4438.57",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'd87dd58f-c80a-434b-a008-b61c1bd11679',
    'identification_number': 46699236,
    'item_consumption_1': 20.68,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 601.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c61dda2f-f013-408e-a20b-00f64cef4c08',
    'identification_number': 108369650,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1b5fb53-a177-4741-80c4-cd10e42fcb5b',
    'identification_number': 15981452,
    'item_consumption_1': 14,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 216.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8970672e-8e5b-427b-8d70-b7450f4cce53',
    'identification_number': 106104438,
    'item_consumption_1': 1343,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 784.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b741e5f4-df2e-4fc8-a522-0ca325e04b45',
    'identification_number': 19394829,
    'item_consumption_1': 6010,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2545.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2005d9e8-15d6-4e2e-83ba-0935446a3c35',
    'identification_number': 100612075,
    'item_consumption_1': 16257,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6049.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce7e4889-36ee-405d-b831-265e4f437165',
    'identification_number': 8859060,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 045/086',
    'item_total_value_1': 81.54,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': 'e22864b8-a64c-4731-aa0a-aeee0ec55571',
    'identification_number': 98713175,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6cb28ce-86eb-439d-a1a6-626e50f4907f',
    'identification_number': 67678564,
    'item_consumption_1': 5302,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1973.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47306366-2256-4906-887d-076edf5d9a9e',
    'identification_number': 101166605,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3ef267a-43b5-45dd-83fd-6bba5f54b44a',
    'identification_number': 104616890,
    'item_consumption_1': 16000,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2042.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46075423-9fbe-4018-b358-780d0e77e301',
    'identification_number': 70574502,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-1718.19",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '9d47a649-5a90-4f3f-88fd-bb111e52f311',
    'identification_number': 15685420,
    'item_consumption_1': 176,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 102.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40a521fd-f690-4c72-9c77-a5d2d11309fe',
    'identification_number': 69901910,
    'item_consumption_1': 1041,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 406.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03dbab64-d4ba-4027-b034-ac30495cb3f2',
    'identification_number': 36200697,
    'item_consumption_1': 263,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 111.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ac58897-dff2-40c6-859a-8bbccacaffd6',
    'identification_number': 8115621,
    'item_consumption_1': 240,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 101.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6c01181-56fb-4946-994b-d7ca12ce4380',
    'identification_number': 78564662,
    'item_consumption_1': 2133,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 272.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '755b9d20-3673-4c57-a3ed-559f7b40ca9f',
    'identification_number': 108669009,
    'item_consumption_1': 2390,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 931.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7971ef1-fe7f-4ee9-a769-8b6b8a310249',
    'identification_number': 43908764,
    'item_consumption_1': 752,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 439.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0bf21e9-71ac-4243-b803-92ecfa8a47d1',
    'identification_number': 26677210,
    'item_consumption_1': 43.91,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1277.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '49f4ab8c-9fcf-49e7-b93b-e71e301055a0',
    'identification_number': 81217846,
    'item_consumption_1': 255.74,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 7438.45,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b77f107c-ad9f-4a62-9c25-a5b7c4e3d358',
    'identification_number': 90978501,
    'item_consumption_1': 1,
    'item_description_1': 'Iluminação Pública',
    'item_total_value_1': 55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0d464004-5ea6-43ac-9203-471212ce5cc0',
    'identification_number': 103466118,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f28bee04-826b-40c4-ab7e-aeaf7d00472a',
    'identification_number': 39466787,
    'item_consumption_1': 67.6,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3860.63,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7fa17924-39ab-4a7d-a7d7-452e93bb45d5',
    'identification_number': 69492026,
    'item_consumption_1': 18405,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 10749.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2660e841-dfba-4fac-b8bc-f4ff78f7f394',
    'identification_number': 80200389,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4adc92df-3fef-4b0f-b278-a0bc72225d59',
    'identification_number': 37608304,
    'item_consumption_1': 5813,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2266.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac23341a-3356-40e9-b225-8d3ea8bacac8',
    'identification_number': 75890380,
    'item_consumption_1': 8789,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3270.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf2a0995-4eb3-4ab9-a455-fc86765303da',
    'identification_number': 97819298,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d3c579f-1710-4042-b9ec-31a9f3483f91',
    'identification_number': 15526194,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b6fde1f3-1a14-4181-ac3e-6f8d08ad208c',
    'identification_number': 32627572,
    'item_consumption_1': 286806,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 106734.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff48d659-d132-41c1-ac1d-8306bbc3d0fc',
    'identification_number': 8115672,
    'item_consumption_1': 169,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 71.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '397475f0-12f1-4445-898f-5a4dc7101285',
    'identification_number': 109308204,
    'item_consumption_1': 1872,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 794.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7dde21a6-209a-4ba5-8aea-ddae994efcc3',
    'identification_number': 91490170,
    'item_consumption_1': 179,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 75.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e22f4b3-5700-43b7-be4c-889dafdca472',
    'identification_number': 87412233,
    'item_consumption_1': 1523,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 645.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35498a06-2d46-4453-8f7f-aaf68f674d4c',
    'identification_number': 19451601,
    'item_consumption_1': 564.48,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 32237.43,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '40c663e9-0b1f-4a27-a629-8eddb31ede47',
    'identification_number': 39452336,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '523da8da-7884-468b-af4c-7a40b7b22ddb',
    'identification_number': 80350844,
    'item_consumption_1': 13440,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5001.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14247e51-7ee8-4684-a95d-47cc91c78119',
    'identification_number': 77887930,
    'item_consumption_1': 7249,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2697.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '022af749-2f20-4dd9-9bd8-4dedd9def3b0',
    'identification_number': 18273424,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '02c8ff1b-7d5c-49d1-960c-c367bc2449cb',
    'identification_number': 96215445,
    'item_consumption_1': 7958,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3105.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36c89e8e-0aad-4c01-a8a1-7e525dbc17df',
    'identification_number': 82743339,
    'item_consumption_1': 5.93,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 172.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '0941b822-95ec-4c64-bcd0-5956f77ed25f',
    'identification_number': 27578801,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea894fee-38b4-4c04-b53f-b73dda29ffd8',
    'identification_number': 64551059,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aa36830f-1085-4de6-9c0c-a53274eb788e',
    'identification_number': 8860513,
    'item_consumption_1': 3410,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1331.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '249169ca-fd27-44b6-a922-5dced61d9046',
    'identification_number': 9928561,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b3ef382-36b6-4d96-adcb-af37e4fe921d',
    'identification_number': 40855708,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2fbba6b2-bfdf-4b14-b29d-7d0eeda8f171',
    'identification_number': 14225093,
    'item_consumption_1': 2825,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1649.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5da0835c-c6ac-4335-af73-d7f111a05ff0',
    'identification_number': 109655885,
    'item_consumption_1': 8692,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1109.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c2e0e6f-0e7a-47e1-ae05-6d04e89476ed',
    'identification_number': 92562302,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'acba9017-7ce2-4bcb-a060-d5d65ac865eb',
    'identification_number': 93308965,
    'item_consumption_1': "'-628.0",
    'item_description_1': 'ENERGIA INJ. PT OUC MPT TUSD 12/2',
    'item_total_value_1': "'-779.05",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '8744141e-4b6f-4935-b767-d574de07386d',
    'identification_number': 111114993,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '470f021d-6dc7-43c4-977c-a25cf9c30886',
    'identification_number': 19288026,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b54a996-9aea-4da2-bec5-d373254e1bda',
    'identification_number': 99704820,
    'item_consumption_1': 3651,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1424.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2a94a3a-4322-44ba-82f3-08a80e619a52',
    'identification_number': 70926115,
    'item_consumption_1': 24406,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9082.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66647f1c-7372-463d-a963-5a9a2581f6cd',
    'identification_number': 103772561,
    'item_consumption_1': 12882,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 7523.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '032fa7ac-6534-48cc-a73c-486ffaece2a4',
    'identification_number': 48827657,
    'item_consumption_1': "'-3.344",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 04/2021',
    'item_total_value_1': "'-1067.94",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '1aba96f8-b14b-4141-b96a-66050ff3db59',
    'identification_number': 31792634,
    'item_consumption_1': 9391,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5484.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0fe0508f-20eb-4e39-a833-f07051f5be3d',
    'identification_number': 110270410,
    'item_consumption_1': 26.46,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 769.6,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0988e141-e8f9-43de-a932-ec47e74197d0',
    'identification_number': 47927755,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '943954f6-6929-4db8-96d9-a91301ff63fd',
    'identification_number': 44818815,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e63b6639-962d-4550-8b6b-a2fc53a9d860',
    'identification_number': 62557777,
    'item_consumption_1': 1410,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2133.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '282074c0-93b5-4caf-a4f0-fc929374c353',
    'identification_number': 92414796,
    'item_consumption_1': 7496,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3182.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be9c9f75-686f-439d-aeb8-bef7aaf08cc6',
    'identification_number': 93811535,
    'item_consumption_1': 1565,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 611.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50a2605e-9163-457d-a5ee-ccc376dc4397',
    'identification_number': 16861469,
    'item_consumption_1': 29.52,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1685.87,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f6c657de-39da-4cdf-99dc-4ce117c2d66b',
    'identification_number': 12273945,
    'item_consumption_1': 9300,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3636.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b74c760-2182-4a1f-bbbf-ecb98c9480be',
    'identification_number': 33485631,
    'item_consumption_1': 3065,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1197.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5e13190-cf45-404a-8a90-448b7c5f597b',
    'identification_number': 103093109,
    'item_consumption_1': 5979,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2534.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86616ab0-93b1-4621-968b-72dbb3e42fb3',
    'identification_number': 11310928,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 112.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'de6a3fea-7500-4123-99c9-b09188ec250d',
    'identification_number': 14693100,
    'item_consumption_1': 18.71,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 446.25,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'deacc953-25d6-475e-860b-2be74adfe6bf',
    'identification_number': 72702141,
    'item_consumption_1': 1909.44,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 109048.1,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e08e83cf-902d-4193-ba9b-9546297db5bb',
    'identification_number': 14601737,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3ab7d05-fd7a-4828-abe0-e05d73a884d3',
    'identification_number': 95633359,
    'item_consumption_1': 1152,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1742.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc244c71-9337-4234-8344-f3fd64cc44f0',
    'identification_number': 104895209,
    'item_consumption_1': 1230,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 520.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '225c30ac-d30c-499f-8a88-a997063dc69a',
    'identification_number': 8115524,
    'item_consumption_1': 206,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 87.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '324fae6a-06e8-40f6-923e-9f7cbd2cbc4a',
    'identification_number': 34086242,
    'item_consumption_1': 1250,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 531.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eded6058-8b2c-4eb0-b30a-e36adb383f49',
    'identification_number': 23135395,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 233.3,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'c0153cbb-0b3a-42bb-a69d-d19747b81a15',
    'identification_number': 103706992,
    'item_consumption_1': 2.42,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 57.73,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a2f243d5-f8f0-49e5-90f9-0a9122136da1',
    'identification_number': 48875325,
    'item_consumption_1': 4247,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6424.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2d3bec9-802d-4494-abce-01e880831bac',
    'identification_number': 5702500,
    'item_consumption_1': 2964,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1255.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b18f3f9-8472-43ed-a2ea-435684da1c0d',
    'identification_number': 64554481,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7c3e9ec8-31ca-42b0-b739-e452e1034e7d',
    'identification_number': 65485416,
    'item_consumption_1': 8593,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3197.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0e2a250-458c-46e2-b8e6-a7d38dd0ed08',
    'identification_number': 96491833,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c4888ccf-6253-4df0-b822-aecad02e9def',
    'identification_number': 67030165,
    'item_consumption_1': 0.1,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 2.39,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1d4d1b09-4631-4975-9f50-63a370f082c0',
    'identification_number': 105060097,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6b80e65-6e45-4c95-896e-12911f363ca2',
    'identification_number': 109643372,
    'item_consumption_1': 1684,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 983.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe218835-d784-4142-b016-a9299ebd1908',
    'identification_number': 98010751,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9b156b2e-6e69-4c98-ad7b-69f2c4d534ec',
    'identification_number': 55454500,
    'item_consumption_1': 8528,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 12901.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '672d6327-532c-470d-89ba-3cb25780910f',
    'identification_number': 94697094,
    'item_consumption_1': 774,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 301.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a2e664b-cfb0-40b3-87e9-033b96b61b6f',
    'identification_number': 5330351,
    'item_consumption_1': 396,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 154.94,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '166e5dfc-2117-4969-ac9a-2ca7ca840eca',
    'identification_number': 102890269,
    'item_consumption_1': 22629,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 9610.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7c75a2e-393d-4259-8cd8-a41906318f01',
    'identification_number': 110683617,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ad73255c-7e23-4362-b376-5d8804c82046',
    'identification_number': 56706499,
    'item_consumption_1': 17.29,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 502.89,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1a04e26e-908b-404e-be63-fa601284c4ba',
    'identification_number': 29862620,
    'item_consumption_1': 2778,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1180.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ba2ef88-fc05-4c41-aaab-2d6fdad68e0a',
    'identification_number': 34739297,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-64.32",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '539c3edc-f86d-400d-b47e-008ffe7c2afc',
    'identification_number': 26323249,
    'item_consumption_1': 206,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 87.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a981f568-ea53-47af-822e-a2d9d77e8b2a',
    'identification_number': 86724860,
    'item_consumption_1': 624,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 243.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '295b8cc3-8202-4a5c-b602-5f17a0149ae4',
    'identification_number': 16093020,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6bf02061-bbf5-45f4-8be2-183e763f7f1c',
    'identification_number': 68738110,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2806beed-0238-4102-b797-06570a535017',
    'identification_number': 69492026,
    'item_consumption_1': 421.63,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 12263.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '370e17a9-d8df-4f41-aa2a-600fdd5343d6',
    'identification_number': 18761704,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '414af656-3f57-4bf0-9c1c-34b3851322a8',
    'identification_number': 45508399,
    'item_consumption_1': 348,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 147.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f25e42e-621b-4904-86df-ae6fe93137c5',
    'identification_number': 107330288,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0f1dcbfa-f3ba-4cda-adca-1e5cdf87f453',
    'identification_number': 87540550,
    'item_consumption_1': 3139,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1226.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b7afbd5-f629-4df3-b31f-9bc162583e0b',
    'identification_number': 49096397,
    'item_consumption_1': 10.08,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 293.17,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1b7d2807-8aa2-403d-b06a-63a6bf645cf4',
    'identification_number': 84777117,
    'item_consumption_1': 1192,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 696.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5e641ba-91d5-4422-9c26-e76d1905dd7d',
    'identification_number': 15348075,
    'item_consumption_1': 123.78,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 7069.06,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '478ee9f6-d1d9-4461-950c-2b854a6d6c11',
    'identification_number': 108654990,
    'item_consumption_1': 2611,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1106.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73e283ff-8c87-46da-8dbd-16124ceaa334',
    'identification_number': 8115680,
    'item_consumption_1': 254,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 99.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc2a9924-629d-4d7b-abb4-9d99d4287257',
    'identification_number': 94042659,
    'item_consumption_1': 3517,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1371.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab45015e-f1da-45a3-9955-8b4722ff3d11',
    'identification_number': 36878685,
    'item_consumption_1': 146.61,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4264.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e7a67416-e0f8-432c-8b08-573a2a303984',
    'identification_number': 59044659,
    'item_consumption_1': 17.73,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 422.88,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f5c15c85-36d9-4774-9592-cab6567ecd8f',
    'identification_number': 927293026,
    'item_consumption_1': 49353.15,
    'item_description_1': 'Consumo em kWh Ponta',
    'item_total_value_1': 105186.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f8d0e91-4c33-4639-86d1-fb7230a97cc4',
    'identification_number': 49853295,
    'item_consumption_1': 3454,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1350.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba5aa283-e135-4180-ae4e-68c7c8d068a2',
    'identification_number': 16386760,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cfd378a4-49d6-4ef6-bcf6-e252fd6f6f1c',
    'identification_number': 17708206,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0a8d1dfd-58bc-4929-a846-bea8ac6d0cf7',
    'identification_number': 97988804,
    'item_consumption_1': 1229,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1859.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24598c43-b5c7-4256-90b7-d77408461b4c',
    'identification_number': 24039128,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 18.21,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '04a0bed4-8c5f-44fd-82d3-d77b39c59869',
    'identification_number': 86489984,
    'item_consumption_1': 746,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 316.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd40b990-88ed-4f13-a9b2-3bc01805bf08',
    'identification_number': 82739935,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '163c296c-94df-4c18-a5d2-f4e7122217c1',
    'identification_number': 104616890,
    'item_consumption_1': 33.07,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 788.75,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c80bf9ba-0f85-458d-830e-e5725e3691f6',
    'identification_number': 17187923,
    'item_consumption_1': 830,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 484.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '882b3f19-fd85-4d01-a01c-3ac7e877e96d',
    'identification_number': 76715760,
    'item_consumption_1': 2987,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1167.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8ed0b64-d6d7-4d05-b025-6ca87ae1f057',
    'identification_number': 101941714,
    'item_consumption_1': 19.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 476.07,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e56e659c-0a17-4897-b4ed-29a06475035f',
    'identification_number': 107119366,
    'item_consumption_1': 4595,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1796.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e3cc0c6-4a12-486f-8530-44e746811e9c',
    'identification_number': 70993734,
    'item_consumption_1': 17865,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6961.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9835d42d-d545-4062-9a28-e16c65b3eef6',
    'identification_number': 76229815,
    'item_consumption_1': 2337,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3535.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2fc3045-7afa-48fc-9ca6-741da5efaf57',
    'identification_number': 92735746,
    'item_consumption_1': 7271,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2835.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb868b8f-4e11-4971-9ddb-a8dd049fce5e',
    'identification_number': 29984971,
    'item_consumption_1': 2010,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3040.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6a9cd98-602e-4b61-a725-340446b38298',
    'identification_number': 103001735,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c57e040c-3245-4eda-ac93-bd88713050cc',
    'identification_number': 105564443,
    'item_consumption_1': 264,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 112.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5f29b0c-858f-4db3-952a-d91c572dc6af',
    'identification_number': 98706810,
    'item_consumption_1': 1085,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 422.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2873ce02-5ba5-4b4c-8dd2-894b82b896dc',
    'identification_number': 16476727,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 171.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9066d844-ed40-4b5e-81ea-b2d056e287cf',
    'identification_number': 84777117,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 105.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c59045be-dd18-46a5-a47a-2bd31f0dac87',
    'identification_number': 70085439,
    'item_consumption_1': 11961,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4666.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bb49165-914d-430a-8759-a11a5dab4257',
    'identification_number': 91392454,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c2d2bb04-a365-4e78-9f49-d054ec31eba1',
    'identification_number': 103103163,
    'item_consumption_1': 660,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 279.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b32c423d-f580-4874-8256-7f00f3a371a4',
    'identification_number': 39214699,
    'item_consumption_1': 676,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 394.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83043307-56dc-4b37-b2b5-6d0d99d86822',
    'identification_number': 4334671,
    'item_consumption_1': 27049,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3452.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4309c8b-3ad0-4a83-9731-5ddbe8fdd843',
    'identification_number': 108552764,
    'item_consumption_1': 3486,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1482.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69402173-e35e-4d46-b851-493ac373e614',
    'identification_number': 15906698,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc7e9f61-9db4-4093-85fd-11003d42e07c',
    'identification_number': 94681511,
    'item_consumption_1': 3401,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1330,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7d25d73-4db1-4083-9082-4f52ff0d3f8f',
    'identification_number': 104783206,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92320d4e-3f68-4c79-9320-4b5638d6fbbd',
    'identification_number': 106293257,
    'item_consumption_1': 799,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 311.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64b1aaa0-2587-4703-9071-867612ff1908',
    'identification_number': 60602643,
    'item_consumption_1': 11.8,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 281.44,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'dea6816b-edcb-4ab6-9ced-4296302cee0d',
    'identification_number': 11346221,
    'item_consumption_1': 81.56,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 3819.49,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ece6d897-9b72-404a-b2e7-97bc5743cd85',
    'identification_number': 79644848,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfa811b9-9987-42da-aeed-ac97738fac3a',
    'identification_number': 97186759,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 120,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '370a10a2-44df-4d0a-8050-3f1484690163',
    'identification_number': 17628318,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '34a93d91-40f9-479f-a7e9-7ea6a025af60',
    'identification_number': 16175573,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 237.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '30863a3c-0e4a-45ea-bbcc-31cfa92677ad',
    'identification_number': 83699864,
    'item_consumption_1': 526,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 205.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ae2d9ee-d16d-4ea3-a366-b9e70289fb33',
    'identification_number': 65141024,
    'item_consumption_1': 83145,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 10612.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4817b48d-cf35-4685-b454-53818d7bfffa',
    'identification_number': 10400184,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e7f379de-c408-4ed0-8398-a62a37717a72',
    'identification_number': 102175845,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '124eeb35-4a61-4880-8e2c-a42b52f5e09f',
    'identification_number': 104652780,
    'item_consumption_1': 685,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 266.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bc89dbb-2d0d-4c03-8363-a3f717272198',
    'identification_number': 6922058,
    'item_consumption_1': 833,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 352.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df5e3bdf-5de2-41e1-80d0-512f933f3597',
    'identification_number': 13924605,
    'item_consumption_1': 3025,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 386.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fe840e9-3bb3-48fe-a6c4-58f24798c5f2',
    'identification_number': 59479078,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fcbd4e4f-dec2-402e-8c83-41e1a8233893',
    'identification_number': 18383971,
    'item_consumption_1': 3546,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1501.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c14553c-d308-4148-85fe-269927979170',
    'identification_number': 81859945,
    'item_consumption_1': 2440,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3691.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ae8bf7a-8a62-4f98-9237-eee7a2369fb3',
    'identification_number': 13884140,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.21,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6208f192-7312-4cd6-bed0-fe91589bde6f',
    'identification_number': 16649427,
    'item_consumption_1': 7702,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3001.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4faab43f-bd06-4782-aa30-f71150649c4a',
    'identification_number': 91360250,
    'item_consumption_1': 3.29,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 154.07,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c8e62917-928b-4a45-bef9-bb9292d1da59',
    'identification_number': 13870068,
    'item_consumption_1': "'-2.031",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 09/2023',
    'item_total_value_1': "'-707.83",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '79396059-4ca0-4e21-986a-796d6ca1948c',
    'identification_number': 60602643,
    'item_consumption_1': 634,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 959.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9dbea36c-596d-4e80-8312-e778dbbe66a4',
    'identification_number': 44038380,
    'item_consumption_1': 582,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 339.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c61eef2c-b27a-42e5-beb2-33a049088b08',
    'identification_number': 105025437,
    'item_consumption_1': 3838,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1500.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03061be3-7de7-4025-8510-8e6bc97ea402',
    'identification_number': 107297671,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5755775b-a052-49ab-a1f1-1ff36353449b',
    'identification_number': 17778085,
    'item_consumption_1': 3214,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1255.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e01b156e-9594-4c64-8e9b-dbdaf818a9ee',
    'identification_number': 58696172,
    'item_consumption_1': 25.53,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 742.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'aa43b3a6-2f4c-48f3-8f06-af6f73859951',
    'identification_number': 101395868,
    'item_consumption_1': 4141,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1615.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '184aca90-beff-44b2-a1e0-a7ab7d8d14ff',
    'identification_number': 100186920,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62b1ad5d-4bf1-4265-9a96-a5b0782d69a5',
    'identification_number': 110755170,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f3ea99ff-e097-4d40-945b-9ecf67fea0ab',
    'identification_number': 27895602,
    'item_consumption_1': 3362,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1963.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a2f783c-ff26-43f7-bdba-4bbb2616ca12',
    'identification_number': 55454500,
    'item_consumption_1': 35,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 834.77,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '69f99df4-2a43-4aad-b860-c97fc79a83ea',
    'identification_number': 52358259,
    'item_consumption_1': 38272,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4884.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e6136b4-77db-49ab-b34b-4975aabb826c',
    'identification_number': 103048340,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0c9ced70-97a9-489e-8045-d75db26cb82b',
    'identification_number': 103025790,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '435a82cc-9475-4db3-b410-26209fe2af31',
    'identification_number': 45768279,
    'item_consumption_1': 100,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 36.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5916d95c-2b2f-4111-a103-229a9d69d5a6',
    'identification_number': 110920988,
    'item_consumption_1': 437,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 185.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b19528a9-8c9b-4d5f-bd88-fab6ecd073a9',
    'identification_number': 39196461,
    'item_consumption_1': "'-2.147",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 09/2023',
    'item_total_value_1': "'-687.76",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '404e73f7-f47c-4efc-90fd-80ae6df1fc44',
    'identification_number': 33676208,
    'item_consumption_1': 23514,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8750.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d14bab5-1c14-4367-99fd-acb7f54e9881',
    'identification_number': 80982255,
    'item_consumption_1': 352,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 149.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08bf2d6f-c215-4b2e-bc3c-61e9255eac1c',
    'identification_number': 8115656,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a3033fb4-78d3-4db9-8804-66eaa61b1c85',
    'identification_number': 48875325,
    'item_consumption_1': 4247,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2480.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af2c68d5-5aab-486e-981c-a66603577a80',
    'identification_number': 93754680,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '391d869a-6a0a-47b3-9dbd-943a6c05c0f7',
    'identification_number': 18267211,
    'item_consumption_1': 372,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 217.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd63db606-4023-486c-afcd-98cccb3d5afd',
    'identification_number': 106622218,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '850956bf-2898-418d-a235-52273a25ceba',
    'identification_number': 32076614,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.23,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7d070c40-deda-4287-a9a8-a7ef05526d43',
    'identification_number': 69811369,
    'item_consumption_1': 1886,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 735.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33cb33a4-11f7-4e74-a676-3a8e00ec92f2',
    'identification_number': 91355540,
    'item_consumption_1': 567,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 857.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb0356f2-d652-4dce-acd0-48fac870ea3a',
    'identification_number': 56995806,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a52bc14b-2dbf-427c-98ee-49ef1d3b2398',
    'identification_number': 9742069,
    'item_consumption_1': 3402,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1324.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e108a6fc-6411-4352-a840-311bb667f83d',
    'identification_number': 94120676,
    'item_consumption_1': 12396,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4613.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab8e9d2c-43d3-4a73-b66c-ad9a9a626697',
    'identification_number': 18798594,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '96731e97-8746-4ae3-a455-9b8e9e601a1c',
    'identification_number': 82220239,
    'item_consumption_1': 2505,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 975.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9338ea0-4fc0-46e0-b2f6-ad9ae232ae8d',
    'identification_number': 28408683,
    'item_consumption_1': 1235,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 482.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e432981a-42dd-4db1-8d16-5f30b4405c0a',
    'identification_number': 97058343,
    'item_consumption_1': 1461,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 569.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3326b5be-6415-426b-914c-387a0a250f9e',
    'identification_number': 58072004,
    'item_consumption_1': 63.76,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1854.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5f0eb6b1-f95d-4aa7-b4c4-dca746968d7b',
    'identification_number': 45853290,
    'item_consumption_1': 1102,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 429.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aebb4f8d-6a9c-498f-8a87-6d0f6b1efb19',
    'identification_number': 104783192,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9eabfb92-5437-40e7-911b-b779735a0893',
    'identification_number': 97817910,
    'item_consumption_1': 1046,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 444.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44f4d24e-6665-49b2-b6d6-4eba5ce8bbb0',
    'identification_number': 99733579,
    'item_consumption_1': 9740,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3800.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19993163-ec59-44d7-b29e-4e155046c011',
    'identification_number': 106509039,
    'item_consumption_1': 687,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 268.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0db77f32-4355-4c1c-99bb-a94e6fab7249',
    'identification_number': 13789414,
    'item_consumption_1': 14.7,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 350.61,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '20ce35ba-b322-4889-af6c-650ecb48a02a',
    'identification_number': 36235334,
    'item_consumption_1': 9.56,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 228.02,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9fcd02c0-7134-4a68-bd29-bcdc058dd010',
    'identification_number': 85443875,
    'item_consumption_1': 836,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 354.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fbd0c58-8f1a-44b2-9c28-ebfacec3384a',
    'identification_number': 15981444,
    'item_consumption_1': 10203,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 4115.87,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': '9ed8f1d1-b3de-419e-9f09-de52aaf59a80',
    'identification_number': 101920652,
    'item_consumption_1': 5462,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2319.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9321e744-bfba-4266-adf0-1ed9976e88f8',
    'identification_number': 68902824,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 334.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9508785-b6ac-40f6-90f7-fc41d0b7ee5e',
    'identification_number': 107297671,
    'item_consumption_1': 28.94,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 690.26,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9612f649-5d04-4120-9767-b8098516b7ae',
    'identification_number': 45501157,
    'item_consumption_1': 32,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 12.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5e86d8fc-0ad6-43b2-8650-e21ec690fd62',
    'identification_number': 63896931,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 215.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ca1e3a83-96c8-44d1-90d9-ca3c86eea347',
    'identification_number': 69983577,
    'item_consumption_1': 8179,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3187.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '188dd75d-fdc0-4826-a507-c567748948fb',
    'identification_number': 51527472,
    'item_consumption_1': 17034,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6339.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f79f75b5-995c-4eb5-8047-0e3a84b2ccb9',
    'identification_number': 99540509,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a78b6504-a2b7-4055-922a-1e819c58e8c4',
    'identification_number': 32629095,
    'item_consumption_1': 265533,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 98817.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16765307-fea1-4332-ae6e-a097dac60ad4',
    'identification_number': 44503849,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0c97f86-4c3e-42ec-bd88-0ff03dfcaad2',
    'identification_number': 64643760,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '05074209-87b2-4f07-bb1f-b5b4003caec8',
    'identification_number': 96125225,
    'item_consumption_1': 16,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 6.25,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4d6923f8-913f-4930-b294-8824e67d6141',
    'identification_number': 62467344,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '45bb08f2-e454-401c-85c4-c97d86d9ba7d',
    'identification_number': 46590889,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8bf2bd6f-72e4-4dab-8762-f59c18533cc0',
    'identification_number': 48339610,
    'item_consumption_1': 110.99,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 6338.62,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7c2e6f7f-18b9-4919-b56c-c71305c63fe2',
    'identification_number': 7676760,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c633bee-e185-49f0-903d-93f210228719',
    'identification_number': 96029641,
    'item_consumption_1': 492,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 208.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28d0af00-e578-4afd-919b-667d84f86b90',
    'identification_number': 30290635,
    'item_consumption_1': 55.3,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1608.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4d3b5526-0bb1-4c82-a03c-cf4a9fbe5b7f',
    'identification_number': 109350022,
    'item_consumption_1': 1377,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 538.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5788a543-4ebc-47cf-96e8-299307214fcf',
    'identification_number': 111452465,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7243c553-f6fe-4bfe-b9fa-ffec0a62a8f3',
    'identification_number': 90469259,
    'item_consumption_1': 144.64,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4206.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4431711b-dee2-4df4-80c1-ebfdccfe0dd1',
    'identification_number': 95867562,
    'item_consumption_1': 1416,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 601.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b059ea60-f9aa-42c0-aa1e-af8939482c75',
    'identification_number': 34739157,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-67.24",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '17ad2228-4828-4445-a5eb-7ff59eeb81d9',
    'identification_number': 56812477,
    'item_consumption_1': 3256,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 415.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f4327df-0c35-44bd-959a-403293c1a6cb',
    'identification_number': 17742935,
    'item_consumption_1': 5076,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2154.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7e5b9db-3c71-4b1b-9af4-be2738adfafe',
    'identification_number': 44634374,
    'item_consumption_1': 21950,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8168.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39c8143e-505e-4b6d-8ad4-a5a4cd60f6f6',
    'identification_number': 42223750,
    'item_consumption_1': 103083,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 155945.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb3565f2-c759-4292-b402-7a97938d97dc',
    'identification_number': 94480648,
    'item_consumption_1': 1102,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 467.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90381e0b-f78a-4c4c-a924-2fa57dd378df',
    'identification_number': 111834066,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9484424d-aa0e-4436-9685-8bed7ec01e3c',
    'identification_number': 68079257,
    'item_consumption_1': 1633,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2470.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd0f1439-ee3b-459d-95e4-b202b6cd74ca',
    'identification_number': 41290984,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '97d3b67b-aef2-4dc6-9a1a-74e9d5044d9d',
    'identification_number': 90978501,
    'item_consumption_1': 35,
    'item_description_1': 'Demanda KW',
    'item_total_value_1': 560.88,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '48bfe397-15a0-41c6-8c00-9a3cb4f3b2c0',
    'identification_number': 15173011,
    'item_consumption_1': 155,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 60.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bcbdecd-b59c-4337-83f1-680c6932c617',
    'identification_number': 66345820,
    'item_consumption_1': 666,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 259.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c171a19-ded2-44ef-8008-e260f5a70ee7',
    'identification_number': 53243846,
    'item_consumption_1': 2927,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1141.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d39e8d3-b7d5-429d-abb1-7107fe95ed9c',
    'identification_number': 58746005,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '579450ca-65c7-4fc9-8988-03b93e9cb045',
    'identification_number': 10293752,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '72870bf3-48b7-4465-bf14-96a0c0acbdb6',
    'identification_number': 23376414,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f5b05fb3-521e-4331-a009-f9193091bd3c',
    'identification_number': 97425907,
    'item_consumption_1': 22.23,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 530.21,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '27e6c43a-b3e6-4332-9080-18e278af93ae',
    'identification_number': 92256686,
    'item_consumption_1': 3275,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1279.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4826d5e2-63da-4b08-9974-da6daa1187dc',
    'identification_number': 30405467,
    'item_consumption_1': 363,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 141.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3db7769-7ded-46bf-86c5-5d4f828f6198',
    'identification_number': 71435964,
    'item_consumption_1': 9512,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3706.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba698c19-5771-43b0-a294-546368b15b7a',
    'identification_number': 52599400,
    'item_consumption_1': 951,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1438.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef3234a6-e09e-4e55-9a26-6bc11b1e53df',
    'identification_number': 59541288,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '829bc23a-eb61-4c4a-9daf-887cddf1651a',
    'identification_number': 6338526,
    'item_consumption_1': 20796,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2654.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb0583d3-d869-420d-bb83-820a44c09b09',
    'identification_number': 39258777,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '18f18eba-0fb7-46cc-8c6e-8f8a1727f873',
    'identification_number': 41418417,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7b85a16b-b9fe-42dd-9170-5c12cd6d26c0',
    'identification_number': 8899738,
    'item_consumption_1': "'-13.478",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-4694.32",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'acdae1a7-ef7f-4ef3-9cf5-bd02b35913cd',
    'identification_number': 16105702,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c79e0e02-c828-4bdc-afe2-9eabb6232e40',
    'identification_number': 43616526,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5d4ad33d-093c-4f3d-99ab-f0dac5736fed',
    'identification_number': 100968422,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 143.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6c02a954-79fc-4226-8627-2d7ddebbaa4e',
    'identification_number': 100081592,
    'item_consumption_1': 5877,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2497.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e039931-de78-4f54-9597-f92e112b892e',
    'identification_number': 20195303,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd033a18d-f36f-4bf9-99f1-3467d5222185',
    'identification_number': 93763395,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 74.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '721d2ac3-1b7a-47bb-bb98-60912f279b76',
    'identification_number': 17187923,
    'item_consumption_1': 5943,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2211.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d7244fd-c32a-4b24-8bdb-c81fbdd65bbf',
    'identification_number': 12673994,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 332.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2d63486-59d9-4f3e-9922-75c221fb8286',
    'identification_number': 105479551,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b3a8d6fd-da7e-43e1-97ec-9d3af5c9703f',
    'identification_number': 11103892,
    'item_consumption_1': 1269,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 538.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50b0f5bf-d380-412a-965c-cde0fd33b1db',
    'identification_number': 43154115,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd20f2145-798c-4c42-bd49-b4e8ab20a052',
    'identification_number': 8805504,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fdfa6252-eff3-406b-92a9-058d89431f95',
    'identification_number': 19310994,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '044dcdda-9ae8-4f41-ac00-ae1e3f5927b1',
    'identification_number': 107202522,
    'item_consumption_1': 366,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 142.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6b4fe6c-fb11-4b4b-bb25-b094f1d97f59',
    'identification_number': 17954509,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '802c12f1-a18b-4f22-8a6e-30356499596b',
    'identification_number': 102676208,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '203b8533-cc83-4a65-afdf-75648609b9e7',
    'identification_number': 72733748,
    'item_consumption_1': 10947,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4635.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5859bafb-907c-4a84-b81d-ac036b97b25d',
    'identification_number': 16148533,
    'item_consumption_1': 7305,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2853.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '824c186d-861d-40bf-9f67-ba058997a29c',
    'identification_number': 32008465,
    'item_consumption_1': 1895,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 739.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5368f36d-7829-4d43-94f2-1d890858ed53',
    'identification_number': 81859945,
    'item_consumption_1': 5.74,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 136.92,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4f96e8c0-0332-43a9-b7e5-c47a313e1ffd',
    'identification_number': 92158064,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '99628dc4-2f82-40a5-a412-dbac351d9dd1',
    'identification_number': 107173115,
    'item_consumption_1': 111.74,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 2665.07,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '22e7f630-79ad-41ff-91e9-ca4a5757e6c1',
    'identification_number': 14834642,
    'item_consumption_1': 340,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 132.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe3cfcc4-d704-4166-bf47-4979acbd4f98',
    'identification_number': 106706632,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '594331d1-42c0-419c-9bba-fd59ab8fdda5',
    'identification_number': 102036500,
    'item_consumption_1': 27.47,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 655.19,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b62eaff2-86ae-4555-b963-8c58d00ffcf9',
    'identification_number': 5683840,
    'item_consumption_1': 15663,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6640.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94b641ba-16d0-4e48-b19c-31235824a55e',
    'identification_number': 14859513,
    'item_consumption_1': 33627,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12514.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5362b732-a7d7-42ee-9a2a-89f3bfb4d167',
    'identification_number': 104092076,
    'item_consumption_1': 1914,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 748.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4e977a0-fa14-4572-b72e-ebb10bb1ab39',
    'identification_number': 38351749,
    'item_consumption_1': 13347,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5651.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '745b1dd4-c82b-4e98-98e4-47ee1e36a77a',
    'identification_number': 99723476,
    'item_consumption_1': 4315,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1833.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c705b81-8fb9-4cc2-b17d-949c08c12acb',
    'identification_number': 13924613,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '833051b0-bec9-4287-bb0e-6187c31b6e97',
    'identification_number': 100627188,
    'item_consumption_1': 158.4,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4607.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '40f4bb47-715e-4ea3-8a84-a5cd6d78e8dd',
    'identification_number': 41360818,
    'item_consumption_1': 27524,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 11702.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'facfd09b-46c0-4e9a-99e0-d1ce863c041a',
    'identification_number': 59059729,
    'item_consumption_1': 3462,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2021.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '969558b2-6c21-4615-a1dd-13599e8ecfb7',
    'identification_number': 49514113,
    'item_consumption_1': 885,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 374.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c504408-8609-49a9-972d-ae6df9bfa756',
    'identification_number': 13008404,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 145.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9d3415c6-648c-467c-9940-b2d0afe37915',
    'identification_number': 109594916,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0a4240c7-345e-4c14-b414-36c0a364ca89',
    'identification_number': 106509039,
    'item_consumption_1': 687,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 291.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dff2c45e-1a18-4acf-8983-501a52d5ad0b',
    'identification_number': 58072004,
    'item_consumption_1': 3646,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 465.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '880507b5-4f9e-469f-aa4f-2ad56eedd0b9',
    'identification_number': 32594852,
    'item_consumption_1': 34051,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 51513,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dba59a13-2055-43ec-9267-a9ca7a544d0b',
    'identification_number': 109207882,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0d8935b-cf4d-49dc-a100-54f44a6456a8',
    'identification_number': 111819130,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e20b193-4265-4514-8b41-9354f1380ed5',
    'identification_number': 12956465,
    'item_consumption_1': 158.76,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 9066.77,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5e41c1b7-5dda-40be-864e-27ad6ca35269',
    'identification_number': 32727917,
    'item_consumption_1': 57141,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 21264.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9dbb514d-9b27-42ad-85eb-4bc862074d58',
    'identification_number': 11100800,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '52acc01d-c1ec-4bd9-b3f2-b765f3272808',
    'identification_number': 50792180,
    'item_consumption_1': 29753,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11072.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1382d05c-a102-41cf-ac22-102b1e604776',
    'identification_number': 73378100,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '992306af-63cb-407e-9d6a-f853f482bb00',
    'identification_number': 103093109,
    'item_consumption_1': 5979,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2331.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3670d8c-6055-4899-b015-98c4f221d604',
    'identification_number': 95830260,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a11e55b3-057b-4df8-bb0b-54681b53563a',
    'identification_number': 41379136,
    'item_consumption_1': 68928,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8797.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92c035ea-87b2-4eb9-b7ed-636bd772d3d4',
    'identification_number': 32595921,
    'item_consumption_1': 66.49,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1933.91,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1a447b35-0d52-4c29-ad0a-25cd618fed2a',
    'identification_number': 16111168,
    'item_consumption_1': 9493,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3698.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41e4a28d-7f82-4ad8-8d60-5fac626741fe',
    'identification_number': 28259513,
    'item_consumption_1': 38,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 14.86,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2c0bab84-b373-4c1a-ac82-72dcb3742b9b',
    'identification_number': 99864029,
    'item_consumption_1': 366,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 143,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82a303f8-b0c1-4bad-be6c-8ae51fc29d10',
    'identification_number': 42627117,
    'item_consumption_1': 22,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 8.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '234ceafe-8d6d-4a03-98b4-82b10baa69b9',
    'identification_number': 61827843,
    'item_consumption_1': 798,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 311.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4aba5cd3-b877-46e9-808e-8b6ae1c0164a',
    'identification_number': 39452336,
    'item_consumption_1': 30.85,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 735.81,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6eeb5fb4-a052-4490-a794-bc8cdc1789ef',
    'identification_number': 36721697,
    'item_consumption_1': 216,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 84.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98350305-74b6-46a5-901d-3858a888e238',
    'identification_number': 102220824,
    'item_consumption_1': 12619,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4696.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '061104d1-19aa-4b00-aac7-ec5ad0da567e',
    'identification_number': 56527144,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 126.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1f221403-96c5-41ab-a04d-75d693573f57',
    'identification_number': 16915470,
    'item_consumption_1': 831,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 324.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1b8780d-1e95-4eff-89f1-7cea42ff74c6',
    'identification_number': 79730701,
    'item_consumption_1': 5122,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2991.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fab539a-636b-468f-bf0c-f8fb807355ac',
    'identification_number': 17355265,
    'item_consumption_1': 4458,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1739.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ca54ea4-a88f-4f64-bf60-6fb78c56908b',
    'identification_number': 32234058,
    'item_consumption_1': 34742,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4434.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16d43f99-9c1d-4ce4-9b16-09035b107577',
    'identification_number': 106606824,
    'item_consumption_1': 1195,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 465.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd44bae48-59f5-492e-899d-c77dc279f024',
    'identification_number': 9161449,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 198.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '638f210f-0144-483a-be10-a000398556c2',
    'identification_number': 43875718,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cf4ec995-d79e-41c7-8146-6a0bdd7d6df4',
    'identification_number': 103823760,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '61dc7175-cc14-4c19-b1d6-11ec850d0a2f',
    'identification_number': 12673544,
    'item_consumption_1': 43.24,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1257.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4e6e98ea-fc78-4111-be3e-fad77c95ad54',
    'identification_number': 109530608,
    'item_consumption_1': 11,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 4.3,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4dc9b146-b61d-4b76-b7ae-62505f8fe298',
    'identification_number': 94193053,
    'item_consumption_1': 3033,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1287.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13cb3036-8105-421d-a347-767c24fb4834',
    'identification_number': 80119646,
    'item_consumption_1': 741,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 288.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '690b2ae2-628e-4654-87ec-fc37d9382f08',
    'identification_number': 45268835,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1660e2ec-2f86-4f72-9e15-315b0be9d682',
    'identification_number': 46861009,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c6c5f336-8d79-4155-a11e-c86a4d9ebf94',
    'identification_number': 102159459,
    'item_consumption_1': 12683,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1618.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9114d38-6bc0-4b69-acb8-23885bbc96c8',
    'identification_number': 38660334,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 117.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d046c13-e3ee-4aab-b6e1-60df5ce94550',
    'identification_number': 12446645,
    'item_consumption_1': 45673,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16997.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c063d0d9-d6bb-4d94-b920-b875dd232b75',
    'identification_number': 107699265,
    'item_consumption_1': 332,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 129.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f1e5999-52fc-4c5b-b421-c7d8e0e42385',
    'identification_number': 45580804,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f3616c14-cdb3-4bfd-8d13-cf60b54a07e8',
    'identification_number': 100728391,
    'item_consumption_1': 416,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 162.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12638439-9fb7-471d-a77a-4887dd67f5de',
    'identification_number': 17109833,
    'item_consumption_1': 6742,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 860.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fdb3418-2ac3-4c98-a0a2-b07483a6e5bf',
    'identification_number': 5329582,
    'item_consumption_1': 3640,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 464.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed5dc107-f322-496e-b175-308fb5f3615b',
    'identification_number': 56525354,
    'item_consumption_1': 2955,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1255.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e3bc2d1-02f8-4f45-8e8c-5822806bac42',
    'identification_number': 31846882,
    'item_consumption_1': 12899,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1646.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e87bc0e-b567-4376-9be2-d139d6640dc7',
    'identification_number': 61389617,
    'item_consumption_1': 436,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 169.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd59d7fc-efc4-4088-b38d-785941a0aec5',
    'identification_number': 46349030,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e3f0550e-a911-4be4-a7a3-5ba0220caa86',
    'identification_number': 61588555,
    'item_consumption_1': 42981,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5485.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f835641-be7d-43db-844f-0e2f6f732ec9',
    'identification_number': 8115796,
    'item_consumption_1': 1067,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 452.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1edaa22-25de-471c-9557-76fc0573a49d',
    'identification_number': 49867245,
    'item_consumption_1': 1045,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 443.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71a98bdf-730b-4482-9429-691d58f19f34',
    'identification_number': 46225668,
    'item_consumption_1': 485,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 189.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0de18076-9123-4b57-b0dd-a2d5cb18e7aa',
    'identification_number': 11380888,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '846c34be-f943-456c-a71a-305ba874479d',
    'identification_number': 99042223,
    'item_consumption_1': 402,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 157.29,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '238612da-9454-478b-aee2-2b6767708c55',
    'identification_number': 29251354,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ff347dd-afdb-4993-89c5-2021ad15dcd7',
    'identification_number': 12718750,
    'item_consumption_1': 2462,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3724.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1998e578-59b8-491b-8b2b-f77160e74b41',
    'identification_number': 58734899,
    'item_consumption_1': 8.56,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 248.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd72496c5-a262-459b-bd90-cb12aeb52f22',
    'identification_number': 100797113,
    'item_consumption_1': 1495,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 873.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e368a06f-f38f-45eb-9149-339f515a8602',
    'identification_number': 46021191,
    'item_consumption_1': 280.53,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 6690.79,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '91889c7c-11f8-4332-a5dd-eaa207d0cbd0',
    'identification_number': 40705188,
    'item_consumption_1': 19746,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2520.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e01c975-31a6-4ec6-9b2b-bdca6462cbab',
    'identification_number': 111991420,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6349ec80-1236-418e-803d-c3df1a87a046',
    'identification_number': 56875592,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'acd309b8-6d11-406a-af88-49227271f69f',
    'identification_number': 11685913,
    'item_consumption_1': 2965,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1255.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffd059ac-4bed-4722-a293-19bacbd181a1',
    'identification_number': 48018422,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-645.25",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '3ad2f203-d926-4847-807c-f0733fd1cbbb',
    'identification_number': 107552078,
    'item_consumption_1': 271,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 105.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed3d187b-008e-4020-8563-b6ce38bc9c53',
    'identification_number': 15720322,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c41ce186-b241-4b2e-81bb-daec82f9abde',
    'identification_number': 18469990,
    'item_consumption_1': 1345,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 525.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82d7fcda-dce0-4a28-a70b-fc5cd87d7f06',
    'identification_number': 40174220,
    'item_consumption_1': 25.42,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 739.35,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5c5e2cf8-0db4-44b7-b731-afcdb0fc95dc',
    'identification_number': 105807010,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b969e237-a17f-41ec-a0d6-a751fa9edf97',
    'identification_number': 59070960,
    'item_consumption_1': 11432,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1459.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e010b328-8fd2-40ed-824d-b5442c65495f',
    'identification_number': 102302561,
    'item_consumption_1': 668,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 282.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15a94769-8f0a-4d8e-9801-a278c5fa2214',
    'identification_number': 19453779,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e7b886ad-472d-4a5c-99f6-81b4b0a176bc',
    'identification_number': 927293026,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0b779e26-6f76-466c-ac03-cefd500cc024',
    'identification_number': 93297874,
    'item_consumption_1': 2095,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 819.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89c5becb-aae5-486d-b219-8485c2f59dd2',
    'identification_number': 19451113,
    'item_consumption_1': "'-21.0",
    'item_description_1': 'ENERGIA INJETADA FP TE 11/2023',
    'item_total_value_1': "'-7.81",
    'reference_month': '11/2023',
    'referente': 'Compensação crédito energia',
  },
  {
    'uuid_items': 'ba921759-9db3-4141-b07d-f90e96d0194d',
    'identification_number': 48432130,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ae5262b5-f324-4045-9e6c-8f705c11c6fa',
    'identification_number': 55619851,
    'item_consumption_1': 9592,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3736.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55bf87d3-687f-4f01-951b-aca1078c0780',
    'identification_number': 35436778,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-833.92",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '4f61c9fe-080f-4b38-acc9-154fc4367c7e',
    'identification_number': 19296398,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 147.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dc274d9e-d7ac-4eaa-a444-78bab1d52d0a',
    'identification_number': 95591850,
    'item_consumption_1': 15273,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5969.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1c6fee5-12ea-477c-a2ef-f91a452e3eee',
    'identification_number': 103100520,
    'item_consumption_1': 968,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 377.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c477c1f-14d9-4b4f-94cc-a797d7009de1',
    'identification_number': 15751740,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 64.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8e9c7551-4a58-4c77-91ad-ae2326622762',
    'identification_number': 44818815,
    'item_consumption_1': 483.84,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 14072.94,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8355f78b-2b3f-48d1-908d-9cdad986c0cc',
    'identification_number': 41108353,
    'item_consumption_1': 596,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 252.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2dabe329-834c-4e65-838d-d03bad5a2a27',
    'identification_number': 10138455,
    'item_consumption_1': 2137,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 834.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c242a5d-7889-4af2-b682-08e40fe48c34',
    'identification_number': 42264316,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b9638386-4174-4341-ad39-6d0b795921c9',
    'identification_number': 10402136,
    'item_consumption_1': 4500,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1907.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '625b59fa-4f1a-4ad1-b44b-fdc4cc5982e5',
    'identification_number': 41213947,
    'item_consumption_1': 60328,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 22450.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '930fab5d-0a98-44a2-aea2-f1c91c935abf',
    'identification_number': 42909058,
    'item_consumption_1': 11357,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4818.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '288a1e9d-f64f-45d7-b322-a60e195262cb',
    'identification_number': 61481467,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2198.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '90887709-481c-4ba1-afbf-e90bcaf31e75',
    'identification_number': 84983981,
    'item_consumption_1': 923,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 361.13,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b7b2ecd7-d764-4a18-984d-b2ddb9069a66',
    'identification_number': 8891800,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 2.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '829fae6f-b1b0-4e68-9fe2-64c53e3b9069',
    'identification_number': 46349030,
    'item_consumption_1': 984.96,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 28648.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '36de31ab-6529-4593-9ad2-1b87d41103ea',
    'identification_number': 10647422,
    'item_consumption_1': 24042,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 9394.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40b1da75-e95d-46c3-829f-df703a1751af',
    'identification_number': 62191780,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6567.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c07458f-2be9-4b7b-a1c8-96e0a9a2c945',
    'identification_number': 94843813,
    'item_consumption_1': 1988,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 775.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '374c7a4c-84f4-4333-b96a-90c09ac24587',
    'identification_number': 101515600,
    'item_consumption_1': 2964,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1257.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a628a18-49c4-4c0d-b4d2-b80a60977276',
    'identification_number': 38109280,
    'item_consumption_1': 28.56,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 681.18,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c7f70195-7afc-406b-b775-6485ff4129c1',
    'identification_number': 39515974,
    'item_consumption_1': 8292,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3522.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d36c19a-b645-4f2d-9d7a-1da4d77eaff8',
    'identification_number': 948743702,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a6328c73-ad97-4ac6-a79c-5fa3d48ac7db',
    'identification_number': 9068236,
    'item_consumption_1': 1608,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 628.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f295c068-7056-4a3b-bfa4-0c65998a10c7',
    'identification_number': 33570191,
    'item_consumption_1': 1887,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 737.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47d885db-2ebd-4b9a-b3ed-23d23a1ede56',
    'identification_number': 100689620,
    'item_consumption_1': 52,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 22.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca9216d0-8290-4584-b130-f8e227041e01',
    'identification_number': 37328549,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e70c752d-3380-498d-b390-ab1cbb5cc02a',
    'identification_number': 110001796,
    'item_consumption_1': 262,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 111.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35bc0f4c-c7ca-4deb-a78f-ffdebdd8178d',
    'identification_number': 57029911,
    'item_consumption_1': 7.47,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 434.55,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '81894342-3cad-4a0f-935f-816483bd7d00',
    'identification_number': 94029539,
    'item_consumption_1': 10.32,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 246.14,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ac8470c3-f9d0-44eb-ae7e-86a6a053a8f9',
    'identification_number': 935371459,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 83.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aa56beee-d247-4d83-af0e-8553f4044232',
    'identification_number': 15348075,
    'item_consumption_1': 51365,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6556.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e529e2f-815b-4237-a109-bcb75d2805e9',
    'identification_number': 100059961,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e55a0fa-81f3-40af-8bb2-8bdc9f3610cf',
    'identification_number': 41674340,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '29926f48-f1a0-43e0-bf32-7783915fbec1',
    'identification_number': 56334044,
    'item_consumption_1': 1753,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2651.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc1e947d-e2d2-4953-9892-3851cbfd015b',
    'identification_number': 32881681,
    'item_consumption_1': 3644,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1424.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '388f10f8-633a-428c-9d52-e49771dcd7f5',
    'identification_number': 54126754,
    'item_consumption_1': 714,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 91.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d9b0df0-8dd1-4332-a410-386fb11383cf',
    'identification_number': 41739043,
    'item_consumption_1': 2399,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 936.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c13b416-d9d3-4a7a-9463-f2b9fdc3e33f',
    'identification_number': 48681261,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83f0401c-d058-447e-b995-d7b77e55a0f5',
    'identification_number': 82724873,
    'item_consumption_1': 32.93,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1880.63,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7a2049bc-79d9-4b74-a7c7-530569d1f0a2',
    'identification_number': 106144480,
    'item_consumption_1': 647,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 252.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c65a3a39-bc7f-4b7d-b212-13fb254f9ed0',
    'identification_number': 107678977,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.58,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0a655523-801b-454a-bbc5-32eed94bdb6a',
    'identification_number': 17073030,
    'item_consumption_1': 1184,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 691.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af397436-481b-4595-8826-a3754f2fc12d',
    'identification_number': 8782571,
    'item_consumption_1': 11310,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4420.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3985b81-827a-4a73-974d-18f84d1e37b8',
    'identification_number': 79830919,
    'item_consumption_1': 2067,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 805.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f93d3205-edeb-4fe5-a32f-5f006bf0e306',
    'identification_number': 12399752,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 71.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'df406287-3309-4bf6-88ed-c7483ce1ba0e',
    'identification_number': 105704008,
    'item_consumption_1': 53,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1264.08,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ab1eddea-72d1-42e5-8d2b-160964fc5c23',
    'identification_number': 58734899,
    'item_consumption_1': 429,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 54.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea6d065e-9cc8-4530-a745-b3287fafff52',
    'identification_number': 27207692,
    'item_consumption_1': 4522,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1915.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '922ff429-5a5d-4993-a22d-d2241df350cb',
    'identification_number': 72507136,
    'item_consumption_1': 1797,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1049.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8948bcf-f8b0-4be4-87c0-e42bd3de85c9',
    'identification_number': 8705100,
    'item_consumption_1': 350,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 136.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79f87423-f157-4af8-9f4e-5d6b0182c6c1',
    'identification_number': 43626955,
    'item_consumption_1': 22.06,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1259.82,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '14b4b6a9-7f46-4809-8146-e7a3b8790d73',
    'identification_number': 107521431,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b5d298a-8941-4280-bbfd-68fc6abaeafd',
    'identification_number': 16191757,
    'item_consumption_1': 5754,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2444.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95c79909-2e66-419e-8201-a2b98bc86fa3',
    'identification_number': 76510883,
    'item_consumption_1': 519,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 220.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fe96ca3-8e42-426d-8c87-0f8c2461bff5',
    'identification_number': 102594350,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bec1289d-1573-4bd3-95f4-54ef22bb071f',
    'identification_number': 99541343,
    'item_consumption_1': 128,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 50,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd0f6b99-d72c-4ee7-aed6-70d4d8cb203f',
    'identification_number': 480819,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '30efc8e8-13f8-4c09-9179-1c4f1396cedc',
    'identification_number': 107740516,
    'item_consumption_1': 67.45,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1961.82,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3a56a067-47f7-41bc-974c-21c51e4b9859',
    'identification_number': 99042223,
    'item_consumption_1': 21,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 8.21,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '91093247-9909-422f-9f00-d66d3749276e',
    'identification_number': 92763219,
    'item_consumption_1': 4413,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6676.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b85338d-9499-48a2-babe-f694c6cd071a',
    'identification_number': 37423258,
    'item_consumption_1': "'-185.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2022',
    'item_total_value_1': "'-64.46",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'a2bc9af4-fba2-45f1-b300-5155dfc1d372',
    'identification_number': 32845570,
    'item_consumption_1': 1.66,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 39.61,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '54d3dee4-6255-47fe-95d9-c6a9c8f1eac8',
    'identification_number': 9027467,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ffc338a3-f84e-4314-9375-c746094bb48a',
    'identification_number': 16570936,
    'item_consumption_1': 62.48,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1817.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dd058086-41a3-4570-bd01-2c222aceaf5b',
    'identification_number': 21718970,
    'item_consumption_1': 1352,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2045.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70886879-97bd-47ce-b2c5-e31ca5f44077',
    'identification_number': 23647299,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0eb62476-6131-4e96-b218-37d749ed0bc6',
    'identification_number': 32927061,
    'item_consumption_1': 1093,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 638.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aeaf373a-3a93-4a97-893c-4c9e1176c126',
    'identification_number': 79665306,
    'item_consumption_1': 74781,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 27829.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c701566-da8f-4314-a3b1-4b5fca84d045',
    'identification_number': 44219393,
    'item_consumption_1': 8.61,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 205.36,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1abfffd0-0b4c-4ff0-8059-883487e292ea',
    'identification_number': 16105664,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2473cd53-c750-4bdc-91d7-50fb9d19d6a4',
    'identification_number': 13010476,
    'item_consumption_1': 19.18,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1095.35,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a2cd0c34-9eaf-4cf1-be5a-c3bb582c99db',
    'identification_number': 12469084,
    'item_consumption_1': 915,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 357.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '444fbc56-ffc5-4473-a835-a4d06877e776',
    'identification_number': 27549607,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '98be8317-4070-4e57-b640-442dbf800e6e',
    'identification_number': 63964112,
    'item_consumption_1': 0.35,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 10.18,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '0efd98eb-c9f1-4f6b-b824-55c546ac3f48',
    'identification_number': 93977352,
    'item_consumption_1': 12423,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5280.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e64636d7-4a69-4987-aad1-75499a02067f',
    'identification_number': 16092970,
    'item_consumption_1': 8743,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 6640.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfb7cdcf-5529-4569-a8ce-e9caf83df2e2',
    'identification_number': 15660818,
    'item_consumption_1': 670,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 260.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d4bbe80-6b52-4ba5-9e06-a01baedbfa55',
    'identification_number': 54126754,
    'item_consumption_1': 26.76,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 638.24,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'aaece15f-7967-4591-babc-1f6884eee3a7',
    'identification_number': 107740516,
    'item_consumption_1': 2539,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3841.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8aa91fef-ffd1-4202-ac4e-9b008f4c9c28',
    'identification_number': 19873727,
    'item_consumption_1': 34975,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13015.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e816eb5-c825-45b3-9589-99e8141f0034',
    'identification_number': 16105672,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7dd40ac5-ac6b-4469-bb36-3465062bc439',
    'identification_number': 101853777,
    'item_consumption_1': 564,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f91aa57-6dff-49d8-8cdd-04a4e6bffb96',
    'identification_number': 19452934,
    'item_consumption_1': 127678,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 74568.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50bac7f4-83a4-4903-8f95-0aefadb8760d',
    'identification_number': 9214810,
    'item_consumption_1': 6071,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2578.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe8d91ce-17fb-471d-ad42-9d2a5e15fd58',
    'identification_number': 26770334,
    'item_consumption_1': 3186,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1352.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79116b2e-ce43-46d1-8d9d-fc1b314a058b',
    'identification_number': 48193399,
    'item_consumption_1': 120,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 46.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '167e2668-2e5a-4da9-85ca-019434c88b42',
    'identification_number': 6164536,
    'item_consumption_1': 2519,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1067.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0aa2e490-fe65-4743-bfc7-6e8b0e0bfd5d',
    'identification_number': 9204237,
    'item_consumption_1': 950,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 370.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aff98367-3ee9-4672-bcf6-fdeabfa8f61b',
    'identification_number': 42496390,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4724e006-1aa8-41c4-8a78-c620438033b9',
    'identification_number': 78084113,
    'item_consumption_1': 24292,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3100.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f538d76-0a01-473f-8fb0-29039070f427',
    'identification_number': 18197736,
    'item_consumption_1': 2915,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1135.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51d32c69-3613-4506-bcd8-ef413a9d6370',
    'identification_number': 43739458,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '610801fa-0d81-4f85-87b2-6bb547298728',
    'identification_number': 68447450,
    'item_consumption_1': 10118,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1291.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '471c2f32-4d51-4aa8-8454-f51395715872',
    'identification_number': 109603940,
    'item_consumption_1': 716,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 279.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00f399ef-7e92-4746-b41d-c800ad20d39b',
    'identification_number': 40859401,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '49a16ecf-9119-4d11-b1ba-71fb9f93ae9a',
    'identification_number': 15645835,
    'item_consumption_1': 64.25,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1868.77,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '33898aa2-95cb-442f-abd3-ea4a46e00312',
    'identification_number': 15369080,
    'item_consumption_1': 1068,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 623.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b427404-7b3d-491a-b70c-f3cb8729f169',
    'identification_number': 83623256,
    'item_consumption_1': 4960,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2896.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '964e9af0-5fe2-4da0-8cae-e1f16a321eab',
    'identification_number': 21161348,
    'item_consumption_1': 2533,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 988.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '144c39d9-7c72-4d3f-bf4a-9b2a208751ff',
    'identification_number': 15672239,
    'item_consumption_1': 4604,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1949.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66f6e4d1-512f-48cd-a7bd-4c1028e003ed',
    'identification_number': 14822725,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e39ab34d-1033-411a-82c7-3ba7b93cd50f',
    'identification_number': 40683680,
    'item_consumption_1': 4947,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1928.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88641b60-4e10-4994-b7e1-c69f5250c0c7',
    'identification_number': 98182501,
    'item_consumption_1': 5862,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2181.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '934df481-77e5-4a45-bf99-1d1c5c6d3d33',
    'identification_number': 107996448,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3f30ed12-35fe-42ea-8dbc-6018d9d41d82',
    'identification_number': 7374933,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd687d86-07f1-4b83-99e3-cf1c5ea5fed7',
    'identification_number': 92947026,
    'item_consumption_1': 139,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 59.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70fd1671-7e4a-4b29-bfe5-bb23d7589a8e',
    'identification_number': 18273424,
    'item_consumption_1': 382,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 223.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89bff07f-612f-411d-bcbf-c62ac799a4fc',
    'identification_number': 103020063,
    'item_consumption_1': 1114,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 435.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2c977a2-9acb-4dc2-a860-03505757b00c',
    'identification_number': 31818030,
    'item_consumption_1': 2780,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1623.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54ab2d12-d967-4121-a35c-130b21a084cb',
    'identification_number': 91024897,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8eed59a1-4783-4336-96ff-d97fb56676cd',
    'identification_number': 70327610,
    'item_consumption_1': 1497,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 583.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7cb40766-30fd-4d40-a1eb-8137019477bf',
    'identification_number': 73221473,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28eb2ab2-6c1e-40d7-96f1-6cfa9253c950',
    'identification_number': 22679430,
    'item_consumption_1': 122,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 51.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70abb4bd-dd4e-42da-9552-a7741b1cda2a',
    'identification_number': 98624792,
    'item_consumption_1': 563,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 328.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32326019-20a3-4994-bec5-f6fb74878847',
    'identification_number': 10980997,
    'item_consumption_1': 2125,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 903.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1265282d-1031-47ce-9b89-37af9f97c20a',
    'identification_number': 96843330,
    'item_consumption_1': 3853,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1636.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef1e14e1-21b6-43e4-849e-e7db44e5d92a',
    'identification_number': 20587171,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 53503.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e52d2070-fe5c-478e-8e2f-88892b0701fc',
    'identification_number': 103033750,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0b602d2-fac5-4c7d-b8ce-746d5cecd13c',
    'identification_number': 16105206,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8e7ef7b-d0ed-4eba-9eb5-122bd5f85300',
    'identification_number': 45838313,
    'item_consumption_1': 60,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 23.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7497a5d0-1561-4ff5-8759-2f7f47ae468c',
    'identification_number': 44001479,
    'item_consumption_1': 12791,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4760.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8188866-1c32-437c-b316-3d3efca80aff',
    'identification_number': 43061842,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2f83dbb9-3476-479b-8f12-d5e5a5f4a65c',
    'identification_number': 21374792,
    'item_consumption_1': 9.712,
    'item_description_1': 'CONSUMO PONTA TUSD',
    'item_total_value_1': 1225.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7403642e-323b-47ac-bcdb-558bd88ed0a9',
    'identification_number': 19304722,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.21,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b241680e-818c-4bc3-8c94-99b1960811e6',
    'identification_number': 40768864,
    'item_consumption_1': 3997,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1556.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3dca199-0678-42f3-99c0-2716a0ff10d7',
    'identification_number': 106612662,
    'item_consumption_1': 16642,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 25176.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a6d1b20-5244-40b6-93a5-9b77df98e75c',
    'identification_number': 108852148,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '84d35ff8-ca72-460c-a478-76014f4db8a3',
    'identification_number': 19452306,
    'item_consumption_1': 153208,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 19554.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f31dfb90-b238-4df6-80c0-e2ba25a16494',
    'identification_number': 41739140,
    'item_consumption_1': 4119,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1747.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f1fb47f-bf6e-4ec2-8341-b4c18e75d2d7',
    'identification_number': 91390958,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a023af4c-0545-4a09-a79f-8049bde03c70',
    'identification_number': 9671471,
    'item_consumption_1': 10197,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4323.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9280551-34a2-47b8-a3ea-34bb63231f23',
    'identification_number': 38718502,
    'item_consumption_1': 10383,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3864,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '635b6478-8bc0-4613-a8f3-17f8c3f92557',
    'identification_number': 64716929,
    'item_consumption_1': 250.92,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 7298.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3aecd710-c49f-45a2-83d5-f9f1ce85a0db',
    'identification_number': 13532626,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd5d4c63d-4319-40f7-852d-466a20d552b5',
    'identification_number': 106354736,
    'item_consumption_1': 850,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 331.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '074a0371-aada-4852-b35f-906152394fc1',
    'identification_number': 109864905,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 10.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '145168b7-1ed8-4154-9e2f-3c1dc290144c',
    'identification_number': 41002717,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 102.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f6dd69a-b9bd-4ff6-a981-3836533d9f70',
    'identification_number': 38738147,
    'item_consumption_1': 5469,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2133.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4753821d-e1cb-4a32-a15e-1ce696629538',
    'identification_number': 64417093,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e7ded10-0a68-4bb0-a707-455ef96d1a07',
    'identification_number': 35436778,
    'item_consumption_1': 21080,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2690.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd62b618-baba-4ff9-b293-94e02e33050b',
    'identification_number': 96181915,
    'item_consumption_1': 1172,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 497.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dce7371a-0aff-4e28-9e4d-8a4308861baa',
    'identification_number': 105978000,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 120.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ce4e0159-3f81-4c48-9790-7849baacfaeb',
    'identification_number': 35326263,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '34be7890-7556-4646-9de2-0dfb1ee756b7',
    'identification_number': 35183543,
    'item_consumption_1': 108.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3145.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '12d1f41c-24c9-414a-a7b8-75b61ca32931',
    'identification_number': 29966060,
    'item_consumption_1': 3280,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1220.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '082cbfed-3c08-4c28-93d6-0f4893bdb9e0',
    'identification_number': 62515438,
    'item_consumption_1': 5221,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3049.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46f4f74e-5f95-4e65-bce9-d50248ebc5a9',
    'identification_number': 100797113,
    'item_consumption_1': 1495,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2261.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d75144c-48b4-45a1-bce9-ed2af552e9d9',
    'identification_number': 52358259,
    'item_consumption_1': 11.69,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 278.81,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '67780761-f30e-47a9-b552-36b2dcea1169',
    'identification_number': 101232772,
    'item_consumption_1': 179,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a9f34f0-2726-485b-a3bf-1e4c2298c7ce',
    'identification_number': 108565726,
    'item_consumption_1': 51,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 29.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb2fb303-285b-494e-9e0a-6c9670a3d728',
    'identification_number': 20444060,
    'item_consumption_1': 49.22,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1431.61,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6938e9d9-3157-48fb-98da-b721d96dc73e',
    'identification_number': 101073593,
    'item_consumption_1': 80.83,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4616.19,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e57d249b-77b5-4aa5-bf9e-7f2a25335ae4',
    'identification_number': 46171622,
    'item_consumption_1': 159827,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 93344.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8dc0f952-74f4-43b5-ad5f-a68ecee4f089',
    'identification_number': 70941467,
    'item_consumption_1': 7728,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3016.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec701f0b-0b36-41bc-a2e3-e2a93c9633eb',
    'identification_number': 44024924,
    'item_consumption_1': 116932,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 14924.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3039474b-10e3-4e8f-8dfb-6c09fb44b05e',
    'identification_number': 83718230,
    'item_consumption_1': 1765,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 690.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36bf2e7e-c342-42bb-b60a-c0ea0a82c37c',
    'identification_number': 105683833,
    'item_consumption_1': 3784,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1477.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0579893b-f16c-40ef-becd-57ff32e26b81',
    'identification_number': 32264615,
    'item_consumption_1': 983,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 416.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8bc15d0-91e1-4357-83f4-3fe7d712a7d4',
    'identification_number': 95284001,
    'item_consumption_1': 1457,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 569.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c8fbbce-d80c-4cdf-9d8f-f4e559b3772f',
    'identification_number': 42804663,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e5756307-34d6-45da-b324-a88fdcd136c6',
    'identification_number': 12866,
    'item_consumption_1': 24,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 1066.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8596dc90-bcbd-41eb-89de-13a6f0c0083a',
    'identification_number': 14642484,
    'item_consumption_1': 9.71,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 231.6,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7dc7dde6-bf7d-4f01-8dd0-9c12ee68df04',
    'identification_number': 75192250,
    'item_consumption_1': 139,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 59.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1efa640-30f5-4f9d-a982-a5fa941f2053',
    'identification_number': 32519036,
    'item_consumption_1': 2944,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4453.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b15c9f56-a6c3-4fa6-8061-d83d84f39867',
    'identification_number': 64554481,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 918.52,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a515f4a3-83c5-4bc0-9bdf-951a4ac7a1d8',
    'identification_number': 93811870,
    'item_consumption_1': 811,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 343.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '475f7125-f6c6-4605-963e-412006c9f1b5',
    'identification_number': 84702893,
    'item_consumption_1': 19906,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2540.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec77bc71-b8e3-4b6c-8a7e-b0de8bbe540c',
    'identification_number': 22164391,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '567e0c2e-21c3-46a5-95f1-a5943d1471cb',
    'identification_number': 93988621,
    'item_consumption_1': 21065,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 8953.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efc6b37b-6357-40cc-8ec2-3c4123b4a7a6',
    'identification_number': 99379422,
    'item_consumption_1': 53.67,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1561.02,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '24661bea-4f79-4f24-96ab-9e9c815c9611',
    'identification_number': 15365824,
    'item_consumption_1': 3602,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1529,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a0d3f91-9d04-4842-8622-e00e22dbaf6d',
    'identification_number': 28426193,
    'item_consumption_1': 10006,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 15137.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '862da4eb-54d2-45cd-950a-f7656194fb4c',
    'identification_number': 105816280,
    'item_consumption_1': 6199,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3620.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd5f2d95-90f7-4338-828e-3b9b876d309f',
    'identification_number': 103727434,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 130.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5bb79767-a6ed-488a-b4a9-b7e42472374a',
    'identification_number': 15107434,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6e31aa25-0b4a-473e-b672-a8b7749fbc79',
    'identification_number': 45219893,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5eb16293-5e83-4aa2-91a6-0d5facaef463',
    'identification_number': 27578801,
    'item_consumption_1': 2624,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1112.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34a85a61-a31a-4f8d-800f-53429ce0c12a',
    'identification_number': 62515438,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6e9c5aab-d932-4dcb-bea3-5da992fb44bd',
    'identification_number': 96505087,
    'item_consumption_1': 2934,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1246.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '597d6e7e-e6d8-4b1e-9990-08184f5f5dda',
    'identification_number': 32528620,
    'item_consumption_1': 1010,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1527.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f438395c-5095-4cdc-8a49-31a80dd483a6',
    'identification_number': 104766190,
    'item_consumption_1': 3395,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1982.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d411a69-247f-4dda-a96d-ae7209aa9729',
    'identification_number': 98802739,
    'item_consumption_1': 14.54,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 845.8,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'cb4b3c9c-4fb5-4ddb-9cde-a864af712fac',
    'identification_number': 41499255,
    'item_consumption_1': 969,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1465.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '604f76bb-3331-42f1-ba56-6e63d73e1d99',
    'identification_number': 99929929,
    'item_consumption_1': 16334,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6380.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1edf258f-d9c3-488f-875e-6aae2b57d35b',
    'identification_number': 16597966,
    'item_consumption_1': 4942,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1927.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b25e78b-289e-4e10-8cf4-6fc35fa5b700',
    'identification_number': 76002241,
    'item_consumption_1': 2947,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1248.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3ff2bbc-cfb8-4998-8750-19d1f188aa41',
    'identification_number': 45483779,
    'item_consumption_1': 9.79,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 233.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '76b950cf-a112-4e46-b8d6-2326c639fef3',
    'identification_number': 97833363,
    'item_consumption_1': 1685,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 659.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f353e974-c231-4ab8-9aa0-a723c0b787fa',
    'identification_number': 32578725,
    'item_consumption_1': 2203,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 859.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ee21e49-a575-438d-9221-4d6a582a51d9',
    'identification_number': 56527136,
    'item_consumption_1': 403,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 609.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2468af0-a37e-4129-83cf-e242288bd6ca',
    'identification_number': 18678,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'Retenção I.R.F.',
    'item_total_value_1': "'-11.47",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '0775b924-9d80-4f80-aede-b6d910c7a8b2',
    'identification_number': 107376245,
    'item_consumption_1': 112,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 43.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8424b6db-d927-4c61-80ec-aad8abb8793b',
    'identification_number': 99353598,
    'item_consumption_1': 2083,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3151.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15343799-1dc9-447c-81e1-8e305b113ad7',
    'identification_number': 96446498,
    'item_consumption_1': 3772,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1474.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '887ba42b-b06d-42ea-aea9-4cb912d9a31d',
    'identification_number': 30071089,
    'item_consumption_1': 6710,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2622.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd66eae04-37fd-4af1-aae6-96dcb133182f',
    'identification_number': 106592432,
    'item_consumption_1': 212,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 90.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3ebd766-282f-48ef-bac2-f39373d9d1e6',
    'identification_number': 106547321,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b0a3375-43e3-40b7-b540-c05df0c7e167',
    'identification_number': 101853777,
    'item_consumption_1': 564,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 220.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81e813d2-e7c8-46c4-ad40-df51b383fb30',
    'identification_number': 34722262,
    'item_consumption_1': 750,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 293.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f09389c-e06d-441a-8da0-22274f393174',
    'identification_number': 84983981,
    'item_consumption_1': 35197,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13098.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db4ad692-3db1-4a96-a70b-c8b0d2b5a6d3',
    'identification_number': 15101797,
    'item_consumption_1': 12138,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4517.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b844ce6-b42f-40e4-90de-8e4fbfd78750',
    'identification_number': 28351681,
    'item_consumption_1': 1480,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 577.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65d28706-7eb7-4ce0-975c-457a48e5cf58',
    'identification_number': 103438157,
    'item_consumption_1': 33.89,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 985.7,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '38a00cd8-b81f-4ae9-acd1-7967ad419b3e',
    'identification_number': 108788954,
    'item_consumption_1': 1225,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1853.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '360ce8cc-ce1c-4459-b573-59213cffa923',
    'identification_number': 64716929,
    'item_consumption_1': 12643,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1613.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a738c1da-5afc-4cca-8d3b-900df6536733',
    'identification_number': 69324719,
    'item_consumption_1': 5873,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2292.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4157d6e-f21a-4073-b11c-3c08198b9584',
    'identification_number': 99578336,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2e1db0f8-f17e-4ad8-822a-21ee6f2d2f34',
    'identification_number': 84702893,
    'item_consumption_1': 20.08,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 478.94,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '990fa8df-a656-460c-b08e-2c6c8a13b7f5',
    'identification_number': 102896224,
    'item_consumption_1': 1,
    'item_description_1': 'DIF.NDEV/SDO NEG VIOL.META 1',
    'item_total_value_1': 2797.14,
    'reference_month': '11/2023',
    'referente': 'Indicador de Qualidade de Energia',
  },
  {
    'uuid_items': 'e76ded77-2582-44cb-a9ce-c35ed50b59d9',
    'identification_number': 31789366,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2202.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5df63085-41b6-4052-9298-aae48ee66cac',
    'identification_number': 29385350,
    'item_consumption_1': 1614,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 685.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6bde284-80da-4b3a-bb20-f919259ec9e1',
    'identification_number': 42584418,
    'item_consumption_1': 4559,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6896.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dddfeb34-c91a-4066-b316-3e9941ee09f7',
    'identification_number': 98942468,
    'item_consumption_1': 33.75,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 981.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '15d05f3b-ec10-4b6b-b3c5-ee876d440c3b',
    'identification_number': 47509910,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 2148.23,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '4e23a66f-7b55-4078-addd-9a7786f3a9ee',
    'identification_number': 47023090,
    'item_consumption_1': 2441,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3692.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cdd06ee7-3e72-4948-a816-29d017f872cd',
    'identification_number': 31639267,
    'item_consumption_1': 1301,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 759.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44ccab83-f501-4534-b27b-ae74176d4794',
    'identification_number': 55900291,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3ab54b0-1319-4daa-b75b-9d335df76bd4',
    'identification_number': 19451113,
    'item_consumption_1': 79313,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 46321.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8a4a0f1-e51e-4e59-bd05-e31097b00668',
    'identification_number': 79722970,
    'item_consumption_1': 11901,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4637.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1e9733f-1d3f-465c-ac4e-32aac7f330ea',
    'identification_number': 80647588,
    'item_consumption_1': 41.62,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1210.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c925ca56-b2b5-4dcf-9c6a-128238711f31',
    'identification_number': 78084237,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 54.63,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd41e4136-50be-494e-97b6-3dfb1caa8a47',
    'identification_number': 103192255,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56042dc2-00dd-4db9-8398-e910a3ff821b',
    'identification_number': 79702236,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce1793ed-77b7-4b87-92ab-19fbbda9d664',
    'identification_number': 22679430,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9ba052f2-2ecd-4a1a-ba01-59bed7304858',
    'identification_number': 35717661,
    'item_consumption_1': 446,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 189.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c77d6b3-cb7a-430d-9cd5-d62e74fc96cc',
    'identification_number': 18616909,
    'item_consumption_1': 52,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 20.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b29b462e-3544-46be-98d2-c646e1c8d2ac',
    'identification_number': 98265172,
    'item_consumption_1': 8294,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3086.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd729fb97-85e2-47e7-b4b8-3a431c968eb5',
    'identification_number': 21539340,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd4f7ca4b-8a20-4dc1-8d16-300622ad4daa',
    'identification_number': 10400184,
    'item_consumption_1': 8760,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3712.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e211882-0b45-4474-b587-e5ce10ab8ed8',
    'identification_number': 83937510,
    'item_consumption_1': 561,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 237.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7062f647-e306-4bd2-a38c-6fa5c946e627',
    'identification_number': 11110597,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-159.35",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'f999fec3-270f-4e9d-8b92-8ce11b2e176e',
    'identification_number': 5348730,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '887b5d16-f999-45f0-ae39-127b6b42ad43',
    'identification_number': 93763395,
    'item_consumption_1': 17336,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2212.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46b457d1-923b-48b7-9b23-77d65cc75a29',
    'identification_number': 97762245,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90d5b623-7fdb-45af-a118-ed64eb8f25df',
    'identification_number': 109655885,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '03feedc6-d710-450c-be7b-0db2b1cd44d9',
    'identification_number': 96626801,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aa87e9ae-a1cc-4488-8578-33c8d31f003c',
    'identification_number': 923318660,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6e03ca3-00bc-49f2-9288-739971a15856',
    'identification_number': 40994724,
    'item_consumption_1': 176.23,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 5125.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '43b37d8c-493c-4797-8be0-3a56316617c2',
    'identification_number': 6941044,
    'item_consumption_1': "'-450.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-144.27",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '79f91fac-dab9-4c44-a2ca-6ce96d1d74a4',
    'identification_number': 6189512,
    'item_consumption_1': 37,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 14.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'caecbd63-1d67-41c2-a342-f96c50f4cca8',
    'identification_number': 929824562,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 72.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd1015990-7159-4190-a1bd-128ba532325f',
    'identification_number': 109530608,
    'item_consumption_1': 0,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 003/004',
    'item_total_value_1': 357.87,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '68fdfdf9-9d62-4ac7-aeb5-ebd3725f5f92',
    'identification_number': 63896419,
    'item_consumption_1': 629,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 951.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ed3d52e-fb35-42e2-845c-0ab8100938b5',
    'identification_number': 71720979,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3099.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2770b441-11b1-4b23-bc3c-88f9a6781a46',
    'identification_number': 102339066,
    'item_consumption_1': 1456,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 568.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '178f757f-396c-45b8-8906-345514893733',
    'identification_number': 101941714,
    'item_consumption_1': 11199,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1429.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af23d6f5-7e3c-4131-af7b-1c3dcbad5bf9',
    'identification_number': 59264993,
    'item_consumption_1': 22.53,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 655.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd21dcb9a-1926-4871-8b71-0eabe43b7478',
    'identification_number': 28351681,
    'item_consumption_1': 1480,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 627.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f416ffb4-c199-4cbf-b970-bfb2fe17b9de',
    'identification_number': 58696172,
    'item_consumption_1': 1277,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 745.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05a79ffe-4140-4e20-8994-4159e395827b',
    'identification_number': 102625093,
    'item_consumption_1': 3254,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1270.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f33261d4-40a5-421d-bf69-37620ec9dabc',
    'identification_number': 19452160,
    'item_consumption_1': 308236,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 114709.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63b2eaea-f1d0-4ebb-90a2-44775572d832',
    'identification_number': 100662110,
    'item_consumption_1': 1462,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 570.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0dabf07-581f-4e55-b270-966f924403a4',
    'identification_number': 65432940,
    'item_consumption_1': 653,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 254.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25dbd21f-6e3f-4807-8bd6-ed3c44a9dd19',
    'identification_number': 8712085,
    'item_consumption_1': 2720,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1061.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a61e139-33e9-4cad-a9da-ca323cbeb3e4',
    'identification_number': 106595121,
    'item_consumption_1': 7188,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2811.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0c5d997-3d95-4742-901a-b019d3b1ed42',
    'identification_number': 20587163,
    'item_consumption_1': 64675,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 97841.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3f19f5b-168b-4021-892b-f8350e8584dc',
    'identification_number': 30298482,
    'item_consumption_1': 22564,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2879.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58d9487f-5a5e-4437-b940-b2e334eef378',
    'identification_number': 44818815,
    'item_consumption_1': 209156,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 26695.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '495bc634-915a-432c-b455-733500cbcd4a',
    'identification_number': 105680370,
    'item_consumption_1': 766,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 298.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd81328d-9e73-4812-bcda-f3390e579069',
    'identification_number': 40709140,
    'item_consumption_1': 22992,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8556.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0fa011fb-1982-479c-8b82-b4cafd9d8174',
    'identification_number': 60602643,
    'item_consumption_1': 634,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 370.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc1895e2-5896-4356-aa0c-2e272002e1ba',
    'identification_number': 13935739,
    'item_consumption_1': 19013,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7075.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f118f5e5-3bf9-46e6-9aba-4f5f69266485',
    'identification_number': 104906430,
    'item_consumption_1': "'-16.85",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 08/2023',
    'item_total_value_1': "'-5872.55",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'a32eb034-131e-4406-b229-baed60824809',
    'identification_number': 58696172,
    'item_consumption_1': 42,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 16.41,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9d9ce357-e6be-43e6-96c9-696adfe059d5',
    'identification_number': 109423410,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '821f71cd-ae89-4c11-8613-1141016e38c9',
    'identification_number': 96026782,
    'item_consumption_1': 1306,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1975.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f53f50f-00aa-48d4-a572-32458d9b96f0',
    'identification_number': 15194191,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9263cf5-9200-4efc-926f-1109aaed4a85',
    'identification_number': 16574923,
    'item_consumption_1': 1607,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 627.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6de886d0-4afb-48fb-9bd3-af99d9a13f5f',
    'identification_number': 39717313,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1f0d2289-b9e6-4094-a677-9aa595cac21a',
    'identification_number': 108788954,
    'item_consumption_1': 33.18,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 791.37,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9ec3ccdd-8f54-4aa0-b346-8ee1938d2505',
    'identification_number': 64716929,
    'item_consumption_1': 22,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 8.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cf12c27a-d29d-49f5-a6d9-d2d662ac3ef2',
    'identification_number': 36752118,
    'item_consumption_1': 731,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 426.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca09b82c-3411-4579-bd1f-d8c8e59487ab',
    'identification_number': 42627117,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8fc87621-fe1d-42a0-a8b4-6f83ba7395ae',
    'identification_number': 31792634,
    'item_consumption_1': 83821,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 10698.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4bd3e24-9c25-45dd-bac2-5f44c0419fde',
    'identification_number': 78084237,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2756.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0da37e9e-ba52-4bfd-a9d9-e8784bdd95e4',
    'identification_number': 73695190,
    'item_consumption_1': 1275,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 498.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '336c2030-483c-4126-a02e-32793ec53d11',
    'identification_number': 110216385,
    'item_consumption_1': 35,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 13.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49e4a101-1b11-4089-8a68-bea887eeb8a5',
    'identification_number': 59602619,
    'item_consumption_1': 1890,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 800.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5638c063-9b75-43dc-b19b-a4b81e2cf3b1',
    'identification_number': 63964112,
    'item_consumption_1': 322,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 125.97,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c96993b1-501d-4e23-bb4c-cbd179861940',
    'identification_number': 88478319,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'adc3842b-21e1-4354-ba63-75844b2b2a2b',
    'identification_number': 55193994,
    'item_consumption_1': 47.92,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1393.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'acc4ca4e-473e-4615-9681-9bd894e9bf12',
    'identification_number': 108332438,
    'item_consumption_1': 142508,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 53033.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d8c8dce-5fb4-4af2-a8c8-31cf0e341601',
    'identification_number': 14115506,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de0ab770-6e78-4725-a7db-c95ebd53f4e3',
    'identification_number': 41318536,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27078a47-184f-4717-8879-1422cb10ae44',
    'identification_number': 14674890,
    'item_consumption_1': 4999,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2116.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '089eb44c-94c5-4d0d-b2ea-5a286b509397',
    'identification_number': 82989958,
    'item_consumption_1': 940,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 367.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5303e38-1de6-4420-b505-89a5bb5553bc',
    'identification_number': 101260890,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-71.64",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '51e29c88-9a78-430e-a7db-eb05be7b44b5',
    'identification_number': 34842110,
    'item_consumption_1': 2630,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1025.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3878776-58d5-49e2-89de-d3f7e6e4fd57',
    'identification_number': 47011440,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da8084bc-7621-4957-8348-9c0322730f18',
    'identification_number': 108560015,
    'item_consumption_1': 45111,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16787.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '629c30bc-20e8-4f4f-86da-a41fd0e1ce01',
    'identification_number': 95016066,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6b6c539-1181-4971-8642-fb04ceff46d2',
    'identification_number': 27222489,
    'item_consumption_1': 6079,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2576.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '618834af-7aab-4cd4-a7d9-7482f6fe6e32',
    'identification_number': 77800699,
    'item_consumption_1': 6910,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2571.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22e88c48-c78d-473b-9439-e9059af740bf',
    'identification_number': 106755030,
    'item_consumption_1': 2156,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3261.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2104629-24ef-48e5-9ce2-1a6bf30d1eb7',
    'identification_number': 96256346,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '987f4c05-5d7f-473b-99b9-698c72fc6136',
    'identification_number': 18643582,
    'item_consumption_1': 10640,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3959.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ff7ae07-ee07-44cc-b5e7-641f9a616927',
    'identification_number': 30950317,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebe68c3f-be74-4eb4-9288-3e0278eef328',
    'identification_number': 85890669,
    'item_consumption_1': 2415,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 943.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65060d83-bd70-420b-bf5e-68850415a67d',
    'identification_number': 28528646,
    'item_consumption_1': 105777,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 13500.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c302d27c-d3fe-4aee-8bf1-ea94c2391459',
    'identification_number': 106851217,
    'item_consumption_1': 7216,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2685.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '586efcaf-7b4e-484b-93be-6166d855f3b3',
    'identification_number': 32153430,
    'item_consumption_1': 624,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 364.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b84b8fb-e177-41e2-aa4f-265a620e391e',
    'identification_number': 109495470,
    'item_consumption_1': 30.25,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 879.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd9ddc1b2-3530-4780-b95f-f4f1cf777043',
    'identification_number': 36752118,
    'item_consumption_1': 11136,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4144.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7aa32a97-1ec5-4511-a2ea-d93fe1955030',
    'identification_number': 74656333,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a25cb56-76b7-46c4-ac5f-ffcdfae7e9c5',
    'identification_number': 75403943,
    'item_consumption_1': 3741,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5659.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2749a7a7-213b-4b90-87e7-e513d9179215',
    'identification_number': 40184188,
    'item_consumption_1': 1913,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2894.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '378bef79-041e-4fbe-8b63-c811c3a2a17e',
    'identification_number': 96839775,
    'item_consumption_1': 8636,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3660.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dba6f543-4c71-4415-a975-7e42587756ca',
    'identification_number': 79992250,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67dfe6e9-01f2-4d4f-9839-5805028687cc',
    'identification_number': 14982960,
    'item_consumption_1': 543,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 211.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e728a3c-8487-4b1a-b4f4-29e574f6d1bd',
    'identification_number': 14674890,
    'item_consumption_1': 4999,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1947.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5be4552-91f6-4b51-abab-54915b61b248',
    'identification_number': 38432005,
    'item_consumption_1': "'-10.367",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-3310.82",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '4836eefd-fbdc-41cd-8eca-b822e637d388',
    'identification_number': 41422775,
    'item_consumption_1': 1340,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 782.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d16d6b7-8e8f-4067-b44c-2585a9c80dd7',
    'identification_number': 32348533,
    'item_consumption_1': 6971,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2594.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c4f0b47-bbfa-46af-8469-918194a8fecb',
    'identification_number': 13877984,
    'item_consumption_1': 14198,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1812.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c11b077f-01cc-4349-ac99-f40738c8e3cd',
    'identification_number': 41082605,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8c74a189-b212-456e-8fc8-e9303c4eb9d3',
    'identification_number': 102014868,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7487a3c8-dba5-4a68-946b-a95f49d67675',
    'identification_number': 102005141,
    'item_consumption_1': 2047,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 798.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48af1642-0648-4eee-821e-102bdc3822c3',
    'identification_number': 22905139,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 75.2,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'a3ccb10c-419c-4610-920b-34bbfa381acd',
    'identification_number': 36525448,
    'item_consumption_1': 8170,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3471.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4f52117-239c-4452-ad52-e61a8b2fc118',
    'identification_number': 92739245,
    'item_consumption_1': 2423,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1415.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '560900d6-f8bd-4651-a00e-8ed4b1add757',
    'identification_number': 77217896,
    'item_consumption_1': 22651,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2891.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14e1495d-4498-4872-bb8e-fa7de04c1805',
    'identification_number': 44219393,
    'item_consumption_1': 4.12,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 192.97,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2c326aec-f209-4b1f-a2ae-01d47b46c04c',
    'identification_number': 28858913,
    'item_consumption_1': 33198,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12354.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '212f0038-a0f1-4889-b174-78d077d9d4c6',
    'identification_number': 42127564,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-4263.22",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'af411063-424b-4f0c-a3bd-c4069e8c3ac4',
    'identification_number': 47198850,
    'item_consumption_1': 7909,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3362.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8135887e-62b7-40be-be3d-bc1e7626b06d',
    'identification_number': 59507853,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a02f193-9807-4990-8d81-48a4dcf8adae',
    'identification_number': 69966710,
    'item_consumption_1': 15990,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6235.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67b2871a-6679-4f01-a2c7-6c0e8036ec03',
    'identification_number': 110226402,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90e9259b-ffd9-45cf-b11d-74336a260813',
    'identification_number': 19612516,
    'item_consumption_1': 630,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 267.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '768cb648-a1ad-4249-bee4-5918102982ce',
    'identification_number': 14138034,
    'item_consumption_1': 20443,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7607.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0977b8fa-7214-42b0-b799-764e9f7625a5',
    'identification_number': 5587069,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e0eec6e-c775-4bea-ab24-77ca64dcf90c',
    'identification_number': 106120310,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '06e5c0b4-fefd-4b3d-9af5-c51e4a25c22a',
    'identification_number': 102481172,
    'item_consumption_1': 89.34,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2598.52,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0a0c427c-d131-4b81-b03d-08a38d748b1f',
    'identification_number': 61588555,
    'item_consumption_1': 57.02,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1359.97,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7ff756b4-ef64-4674-aa7a-01ef9c43a829',
    'identification_number': 101837593,
    'item_consumption_1': 8583,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3644.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'faccf832-b551-4c8c-a065-4cf8cdbb5117',
    'identification_number': 8708320,
    'item_consumption_1': "'-2.577",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 09/2023',
    'item_total_value_1': "'-895.46",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '9898aeb7-d5cb-47f1-88f1-8e0fdb4c2154',
    'identification_number': 73822671,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '148edb2c-a6e1-4570-858c-b70917842cd8',
    'identification_number': 33143986,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bda1ddc2-2d01-4543-bc6a-7a8b3b4d4915',
    'identification_number': 72307650,
    'item_consumption_1': 578,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 226.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3fe7690-8ce5-4362-bfe6-1bfa2d68e784',
    'identification_number': 56525354,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4286bb7f-e131-47e9-b001-0fbee48d01ed',
    'identification_number': 104616890,
    'item_consumption_1': 37.93,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1103.21,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a13415c6-38ea-4a97-a9d2-000e11ce64df',
    'identification_number': 31560792,
    'item_consumption_1': 738,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 288,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ad37aaa-ad4a-44c5-ace1-6947e00ac3af',
    'identification_number': 68457782,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52a21924-ef3f-4596-a209-64b10a21c72b',
    'identification_number': 18280382,
    'item_consumption_1': 3228,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1258.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff2b4f35-f775-4d06-ac0c-6d4061c723b9',
    'identification_number': 107674149,
    'item_consumption_1': 144.48,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4202.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '17edbaf8-138c-4ce4-ab60-c5d62cb340d0',
    'identification_number': 8860513,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.93,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3f5aa32a-aa16-4b67-ae83-242998421d15',
    'identification_number': 13977873,
    'item_consumption_1': 9.86,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 235.17,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '8377f181-fef9-4c1f-bd6d-b039e3104a37',
    'identification_number': 56874081,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 113.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '265f3470-d187-4428-8f39-3100aebd664d',
    'identification_number': 106493922,
    'item_consumption_1': 1169,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 495.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec773fa8-e364-4691-9256-d10a9866e589',
    'identification_number': 38433141,
    'item_consumption_1': 905,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 383.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82f515e0-1251-424d-bfbf-49c55a9d715c',
    'identification_number': 3033182789,
    'item_consumption_1': 30,
    'item_description_1': 'Disp Sistema-TE NOV/23',
    'item_total_value_1': 8.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41cf6fd7-8b9d-45d7-8b16-8a65b4615ed2',
    'identification_number': 42433347,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f34b2b16-ec3f-4ec3-b1f1-c6dfd6f159a2',
    'identification_number': 95720022,
    'item_consumption_1': 8982,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3504.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6897493-a77c-4fde-b7f3-678225b61112',
    'identification_number': 44502931,
    'item_consumption_1': 8318,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4858.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba1ff453-051f-48ce-919b-50a518ef1c66',
    'identification_number': 103164111,
    'item_consumption_1': 1549,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 658.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87da0874-e1ca-4eaf-bca5-9537fb7af383',
    'identification_number': 45564094,
    'item_consumption_1': 4277,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1816.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96d29da4-b56f-403a-986b-e825a5cc651d',
    'identification_number': 42584418,
    'item_consumption_1': 4559,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2662.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aadf5270-1b12-4d44-9318-e9f9bf4bf12e',
    'identification_number': 16861469,
    'item_consumption_1': 15530,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5779.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74db15ea-66c0-4e59-85a8-e53891cb557d',
    'identification_number': 100335870,
    'item_consumption_1': 829,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 324.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4bc7299-dac1-41b4-9291-075f82879d7d',
    'identification_number': 103785558,
    'item_consumption_1': 1998,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3022.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e3f1464-d8b2-44fa-8d60-eddaa20a9104',
    'identification_number': 106227823,
    'item_consumption_1': 802,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 340.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8946f3c1-5926-479e-a1e2-248f721fc77a',
    'identification_number': 34659307,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4dc8335-f651-4da9-9471-debce6a6870d',
    'identification_number': 109633962,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '725d63a7-ef2a-4d7d-bf9b-c9dfef54b4e7',
    'identification_number': 42433339,
    'item_consumption_1': 6409,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2496.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35581e06-5769-4a6b-a063-969ebc8a004c',
    'identification_number': 93490089,
    'item_consumption_1': 8873,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3457.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3ae89ca-0fa8-478f-ac90-02f798fb0288',
    'identification_number': 102011311,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3ca8b35f-2561-4e0a-884f-b85d4600ad51',
    'identification_number': 40026043,
    'item_consumption_1': 106,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 44.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '112aaa91-76f3-4182-a0bf-9d69f1c511ce',
    'identification_number': 33558540,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7cbb80d2-8730-4524-9f5e-e668af0f4b85',
    'identification_number': 40174220,
    'item_consumption_1': 5198,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 663.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e43d368-5837-4039-ac15-67d582b0cff3',
    'identification_number': 18898068,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3ae8b48a-6bbd-44cf-b7ef-6bbcf162ba28',
    'identification_number': 36149730,
    'item_consumption_1': 16560,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2113.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93213fbd-70f8-477e-9899-6a538adf845b',
    'identification_number': 42390001,
    'item_consumption_1': 214,
    'item_description_1': 'Demanda Máxima kW',
    'item_total_value_1': 4182.21,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '375ffc09-c234-4772-a15a-ddd988682ba3',
    'identification_number': 102892784,
    'item_consumption_1': 1040,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 406.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02d6fc36-43e5-4a60-8ac7-6eb9e205a218',
    'identification_number': 109655885,
    'item_consumption_1': 806,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 470.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05128363-035b-451f-9bcd-4dec70307dfe',
    'identification_number': 29862620,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4c4dc61f-9cee-4451-bc0e-3cc2a995e31a',
    'identification_number': 90469259,
    'item_consumption_1': 8735,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 13214.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e0534e9-1b1a-4a43-a6e1-55c90c9f60ea',
    'identification_number': 35809329,
    'item_consumption_1': 6,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'df58627b-713b-40e7-a6ad-d98afdf39eb1',
    'identification_number': 14152037,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '067b6bd7-7649-4ab1-872c-5e45ebb0e598',
    'identification_number': 71175644,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '967369d9-338d-4383-bef0-bc3095576564',
    'identification_number': 44478046,
    'item_consumption_1': 26690,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3406.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fadbf3b6-d306-48d8-b8ae-1ed2d59f24f8',
    'identification_number': 13924613,
    'item_consumption_1': 1531,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 195.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de7f3708-7707-441c-a1be-61625af443c9',
    'identification_number': 21718989,
    'item_consumption_1': 4.64,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 134.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9e0c87c6-0516-4465-ae7b-aa7bf20a39da',
    'identification_number': 32046634,
    'item_consumption_1': 369,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 215.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c58e31f0-fffb-49b0-b287-29034028a743',
    'identification_number': 26168146,
    'item_consumption_1': 645,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 252.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e60d572f-b331-44a8-b00e-6ae88fb5c0e0',
    'identification_number': 103157980,
    'item_consumption_1': 122.01,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3548.77,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '42fd9af3-c8d4-48e0-a245-dd4188ef3896',
    'identification_number': 16121490,
    'item_consumption_1': 25895,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 44575.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9dbcc540-7eb0-4406-a62e-9ccc65767610',
    'identification_number': 44219393,
    'item_consumption_1': 8.61,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 205.36,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '56180e56-8768-40ca-b6ef-e3f827ef588d',
    'identification_number': 108637840,
    'item_consumption_1': 1466,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 571.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd51ab497-089a-4038-872b-fc0eda4e0ec7',
    'identification_number': 96256443,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e122096-df6a-467e-aee1-090047592543',
    'identification_number': 32503784,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f24de1bb-93f5-4c71-9cc6-95cfe869daeb',
    'identification_number': 13789422,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd8c2fb96-c70c-463b-95e6-8ebdcebdd4ca',
    'identification_number': 39214800,
    'item_consumption_1': 581,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 339.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00a516e7-d14d-41c0-8c44-018dfdb683f0',
    'identification_number': 34659307,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcbd54fa-adf7-4b0f-8e3d-7d85d6f2b586',
    'identification_number': 39678318,
    'item_consumption_1': 13227,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1688.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73f2fb14-4af9-4e24-97b1-7c3ae119bc7d',
    'identification_number': 43612067,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c235481d-1391-488c-a554-04355c43f826',
    'identification_number': 9822950,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 142.4,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '58d64a32-397e-4c81-8219-997e7b43e5c1',
    'identification_number': 35778962,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a263920a-591f-4539-aaae-07636f926e04',
    'identification_number': 106104438,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b56cbc3-06b8-4f66-8986-7913b062549f',
    'identification_number': 71433449,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '78ae41c3-05d6-4331-98a6-970d9253ef6b',
    'identification_number': 111834066,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c9e3828-5cdc-4faa-95b2-c6f4d990c930',
    'identification_number': 110006623,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cadfc1ea-0eb5-4e09-8425-cccea0f54930',
    'identification_number': 108065006,
    'item_consumption_1': 104.35,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 2488.82,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ea780280-6163-4679-ad2f-3e70e27476d0',
    'identification_number': 23660872,
    'item_consumption_1': 1556,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 908.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ebe8a6f-7d55-4764-9742-de07a2a05bf7',
    'identification_number': 95641963,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2127.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40d9127d-de32-468c-9f95-8829794882e8',
    'identification_number': 15561054,
    'item_consumption_1': 8.95,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 213.47,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '342bf172-e179-4c74-a5f0-84c0e26f30ff',
    'identification_number': 20934947,
    'item_consumption_1': 13162,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1679.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdcb3895-0cab-41ac-b024-b64e841aba3b',
    'identification_number': 23691530,
    'item_consumption_1': 768,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1161.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4163ee9-f3a2-46de-8cf0-2b6825a9fe4d',
    'identification_number': 105763101,
    'item_consumption_1': 16563,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2114.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d326f9b-6343-475b-a6c5-8ff350ba5d50',
    'identification_number': 28671139,
    'item_consumption_1': 8120,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1036.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7d23582-da8e-4578-9fbf-06cbe024368c',
    'identification_number': 99134993,
    'item_consumption_1': 506,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 197.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3eb8e66-f0e7-463d-a5d3-afcb021a5356',
    'identification_number': 71132996,
    'item_consumption_1': 23575,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 9996.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b736612-00fb-4180-822f-329bf1aa6400',
    'identification_number': 98713175,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ef7ab05-44a1-4a1c-94d1-6f89ca8d93df',
    'identification_number': 37133438,
    'item_consumption_1': 1852,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 724.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a2dc8e0-7098-4803-98c9-a3ef16ba4a47',
    'identification_number': 96029641,
    'item_consumption_1': 492,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 191.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2655a247-be82-41e1-bf39-641141015ccd',
    'identification_number': 92994105,
    'item_consumption_1': 3664,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1551.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f42ce04-2c38-4d21-90b5-97b0adf10878',
    'identification_number': 14671638,
    'item_consumption_1': 489,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 285.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61f811bd-5901-4380-9c55-ec3fd54f7814',
    'identification_number': 18205704,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c5bec018-3514-4547-8e72-6ae2bdd72e27',
    'identification_number': 78479010,
    'item_consumption_1': 1643,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 641.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01a93ccf-ca1e-4dc2-9dea-1c74500a9891',
    'identification_number': 39422470,
    'item_consumption_1': 27031,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10059.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2828acfd-440c-4c8d-8c1a-875fd6f8fccc',
    'identification_number': 19119852,
    'item_consumption_1': 3340,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1417.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb902bff-f7f8-4b0f-90a0-3b0ca61a2ba8',
    'identification_number': 56886314,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ca432182-8b43-4538-b979-a9c974ce0534',
    'identification_number': 3031570722,
    'item_consumption_1': 100,
    'item_description_1': 'Disp Sistema-TE NOV/23',
    'item_total_value_1': 29.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aea7af17-902f-4a53-8b92-599b1c1b20cb',
    'identification_number': 66557402,
    'item_consumption_1': 14235,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1816.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c96c009a-aeb6-466f-a9c9-74e4faa25d25',
    'identification_number': 106732358,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7e2c9f5c-75bf-48e0-bf47-527557f2ba85',
    'identification_number': 100474144,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 41.23,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ba3ae6d1-cfb4-4983-8fa0-0bb32b6af619',
    'identification_number': 110008758,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6c9467e-eafb-4f39-8698-e20cafd5103b',
    'identification_number': 106595121,
    'item_consumption_1': 7188,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3056.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0eb0d0a2-fcfc-4cc4-b7d5-52071edebe8a',
    'identification_number': 5736234,
    'item_consumption_1': 15454,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6032.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ae639a8-29c3-409c-a9c2-809a7e5b8bb9',
    'identification_number': 4334221,
    'item_consumption_1': 206270,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 76762.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f437e33-1e68-4e54-a094-61d918bfb21f',
    'identification_number': 26780003,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef6e5c9d-ba54-42fb-a661-6827ea51310d',
    'identification_number': 59144017,
    'item_consumption_1': 42.9,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1247.78,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e796b086-4ca4-4cbf-a1c2-312175a2370e',
    'identification_number': 102654549,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3edf6a4a-f491-468b-9e2b-f60c48ef6032',
    'identification_number': 98925083,
    'item_consumption_1': 687,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1039.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b4ad2ed-2c83-4e7f-aee6-a07974387f94',
    'identification_number': 74517686,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49ea6f35-df50-4003-affb-e96c478467bb',
    'identification_number': 103727434,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-236.31",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'b13e5c31-5ce3-4dcd-9d19-9736194f5db4',
    'identification_number': 91494877,
    'item_consumption_1': 229,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 89.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab3f1074-65c5-432f-97e3-680d89f3a99f',
    'identification_number': 111219248,
    'item_consumption_1': 552,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 322.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15c99281-832c-42db-bc7b-b0f52c2bd928',
    'identification_number': 110527348,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0cdf21b1-e7e3-466a-8bf7-9863d812829d',
    'identification_number': 11013958,
    'item_consumption_1': 803,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 314.18,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd2c72527-a802-4230-b386-39ce70144ee0',
    'identification_number': 16882881,
    'item_consumption_1': 2150,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 910.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76b771b7-41b8-4bde-9152-9d551bd29a7a',
    'identification_number': 44038380,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8add7241-bf72-47e4-be5c-1df683ab0052',
    'identification_number': 12393916,
    'item_consumption_1': 4032,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1714.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fd9f24b-8cb2-42d5-93d0-f88937dd55b8',
    'identification_number': 19450672,
    'item_consumption_1': 440.64,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 12816.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '431f959c-1833-40ae-95ab-83a7a7ffd25a',
    'identification_number': 106607120,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5204c8a2-a0ec-4dd2-ae78-a82acf9a1784',
    'identification_number': 85543691,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'badd93c4-1041-413b-9cad-2c7509d9fa58',
    'identification_number': 44126760,
    'item_consumption_1': 3253,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1267.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7e1e313-c183-4b20-93d1-ab9ca632bd75',
    'identification_number': 94658676,
    'item_consumption_1': 1221,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 517.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fbb9f25-31c2-470b-b90b-a5f7a535e5b9',
    'identification_number': 41611187,
    'item_consumption_1': 9714,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5673.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea37c5df-9b6a-4bf1-a32a-ef789e9459c5',
    'identification_number': 92828760,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 67.52,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ab48e83d-83e3-4f50-9073-f0981c7ffc63',
    'identification_number': 51632381,
    'item_consumption_1': 2480,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3751.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0b428f7-ddfa-49aa-80f3-f942c8dcb9f3',
    'identification_number': 49457012,
    'item_consumption_1': 40196,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14958.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '078e7764-b177-40f1-a5e8-8a6cf1b0b093',
    'identification_number': 95256164,
    'item_consumption_1': 218,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 92.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c53c9cba-72d7-4fc7-ae57-78d3ef3e2fae',
    'identification_number': 93842295,
    'item_consumption_1': 589,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 229.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07953e91-98cd-4d74-99d6-6ceddaaa8022',
    'identification_number': 103034412,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a76bb71a-e5ad-4971-86f3-cb40f3069d8c',
    'identification_number': 46424342,
    'item_consumption_1': 752,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 293.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9d022b3-0ba7-41ea-8cbf-a4c3b5b7034f',
    'identification_number': 95618082,
    'item_consumption_1': 642,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 251.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c85025a-0f54-4259-b51b-16a948dde231',
    'identification_number': 43616526,
    'item_consumption_1': 1489,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 582.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fc2b8a3-149f-4956-b1f1-890426f10884',
    'identification_number': 104289465,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a1e2201e-9985-4f48-82c1-89cb84f2f8bf',
    'identification_number': 13973932,
    'item_consumption_1': 1346,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 571.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1ceaf04-0012-4109-a2fe-c0af7644d801',
    'identification_number': 109421183,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6221f6fe-f35e-426e-bcb0-7be96e27225e',
    'identification_number': 31437695,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 75.2,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'bdc9be4e-f9d8-4821-b515-4baeaebae36c',
    'identification_number': 102220824,
    'item_consumption_1': 12619,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1610.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5281ad1e-33d7-48f7-8524-021f43eeaed1',
    'identification_number': 110480252,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 112.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '448e80ca-9fc9-40c1-99cf-a9c214274ba5',
    'identification_number': 22963677,
    'item_consumption_1': 20711,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 12095.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3fbf034-254c-4e2a-855b-f066ae36ffe7',
    'identification_number': 45239282,
    'item_consumption_1': 2816,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 359.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95e9513f-6db1-4bcc-93ec-43d0ba6c53cd',
    'identification_number': 32191871,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '46e0aa60-c571-4c20-bcd4-669ca76bb612',
    'identification_number': 111114381,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1085af7-3761-43b5-a40e-b77419be9438',
    'identification_number': 106861182,
    'item_consumption_1': 8885,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3775.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '659b048b-77fd-466e-902b-943850405c3c',
    'identification_number': 19452667,
    'item_consumption_1': 794245,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 101374.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '609eb2fb-2582-4191-b7a9-146ada1a116c',
    'identification_number': 94317976,
    'item_consumption_1': 2472,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 963.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72843dfb-e429-4668-962c-59721f1078f4',
    'identification_number': 45603391,
    'item_consumption_1': 23.51,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 683.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6b65745d-9ac0-462a-bcf6-cb1fb1d35443',
    'identification_number': 14225093,
    'item_consumption_1': 2825,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 360.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28a2fdd3-9593-40be-b5e0-66044452cc21',
    'identification_number': 106136712,
    'item_consumption_1': 46.34,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1347.82,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4241d37d-d731-4bce-8997-6bbf8b42139d',
    'identification_number': 15138674,
    'item_consumption_1': 3813,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1488.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c216005c-8bfe-40ee-acd1-332867481b77',
    'identification_number': 9063455,
    'item_consumption_1': 7042,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2753.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3af941d2-a556-4329-8afa-e4bf0ce1fc4f',
    'identification_number': 7947267,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59d69e3f-8f0c-486e-8cff-25317b7100ba',
    'identification_number': 15695646,
    'item_consumption_1': 3410,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1333.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53218d8a-f643-4395-9b6c-f910af3551cc',
    'identification_number': 33625930,
    'item_consumption_1': 47.12,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1123.84,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '85d2f301-f683-4742-ab6f-c04230e64190',
    'identification_number': 103764755,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.66,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '570cccf6-7ce9-4500-9842-7ddfb3bd2d23',
    'identification_number': 10105387,
    'item_consumption_1': 1203,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 509.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'abbd5f87-d39f-4325-b337-c3c51c136fd4',
    'identification_number': 86398652,
    'item_consumption_1': 11.11,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 264.99,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0ed795f9-3b75-4d81-8b73-8823ae93024b',
    'identification_number': 1205102,
    'item_consumption_1': 17833,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2276.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3db6ac6d-8b5e-43c0-ac7a-e49d4c0b8f95',
    'identification_number': 53031423,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5571a92c-4f5b-4d32-b5d9-e6aea48c94b1',
    'identification_number': 8543844,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b8871b6-b7f2-459e-aa20-9b87ca5e08fb',
    'identification_number': 94120676,
    'item_consumption_1': 12396,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1582.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0fe96a13-ab5b-4115-897d-52785a9abadb',
    'identification_number': 29706980,
    'item_consumption_1': 6057,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2369.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f36620ff-4424-413e-9f30-509070e925ab',
    'identification_number': 19013230,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a8b92dcb-a893-4f69-9f23-45b38437a115',
    'identification_number': 64175251,
    'item_consumption_1': 2.1,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 50.09,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6486082c-ef89-4df7-9290-e270b6eeaf4e',
    'identification_number': 50783840,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63791a08-3b4e-4ef7-88ba-0acff219ccb8',
    'identification_number': 56507844,
    'item_consumption_1': 33.06,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 961.57,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dcb6cd38-30df-44ad-ab6f-1be0d34d1113',
    'identification_number': 97779113,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 415.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1460471e-11b7-41fd-a05b-16324e4b9e91',
    'identification_number': 70810737,
    'item_consumption_1': 2398,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 938.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89929411-0e4e-4cb0-9672-c1335b038636',
    'identification_number': 105003727,
    'item_consumption_1': 13218,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5619.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3cece757-c6ba-4e63-9586-619ecf20d3ee',
    'identification_number': 43338739,
    'item_consumption_1': 551,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 321.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd96054c-f711-463b-a284-9d1888409cda',
    'identification_number': 16121490,
    'item_consumption_1': 239073,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 96441.56,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': 'fce2ec8b-43d5-4d0e-ac54-b0f61e7ed8de',
    'identification_number': 98105248,
    'item_consumption_1': 7286,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2839.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1be55296-f848-4878-a76f-502d722e9ce0',
    'identification_number': 58904727,
    'item_consumption_1': 327,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 138.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c66c2e07-9683-4370-9968-16ed9421ec44',
    'identification_number': 99959739,
    'item_consumption_1': 4541,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 579.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e00a99b-7900-414b-8cf2-0b7a2f6446e9',
    'identification_number': 20587147,
    'item_consumption_1': 1069,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 418.27,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6b7c9864-7332-4138-b46b-7d9f3bea72d0',
    'identification_number': 40569144,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7abe398-49bf-49d1-a361-0f58c6518949',
    'identification_number': 5736234,
    'item_consumption_1': "'-682.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-237.25",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '9c731f5f-5f26-43f2-8ad6-2ea76c4ab535',
    'identification_number': 91654734,
    'item_consumption_1': 4667,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1982.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36db030c-64b5-4717-bcc5-c16c58fcb2ca',
    'identification_number': 100217290,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1d3ef762-5753-4ddd-a0d5-cfaa5c37e2b7',
    'identification_number': 41213947,
    'item_consumption_1': 14,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 5.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '79fe7af4-eb07-4504-915a-4b4560d1fdd0',
    'identification_number': 91804701,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fa5bb8a7-0c7d-492b-8d8a-00f372fc9119',
    'identification_number': 109655885,
    'item_consumption_1': 8692,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3234.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0650ee73-2071-4cf5-a789-8c5183ca17d9',
    'identification_number': 14063476,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd56c718d-7f7a-4f9d-abda-89a443361167',
    'identification_number': 106851730,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c832c8c5-8ef9-4f96-a2fc-a9243bb40cc2',
    'identification_number': 39024091,
    'item_consumption_1': 10202,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3973.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '818e6b76-52b8-4055-a8c2-2cc19b9877e7',
    'identification_number': 77405340,
    'item_consumption_1': 109497,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 40749.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b2b063e-4185-4152-b0c9-af1a495465fb',
    'identification_number': 18273424,
    'item_consumption_1': 3045,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 388.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b298e1f5-03ea-4794-80a9-43c94ac73a40',
    'identification_number': 31818030,
    'item_consumption_1': 5.14,
    'item_description_1': 'DEMANDA REAT EXCED USD PONTA',
    'item_total_value_1': 149.49,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '9151fe22-561d-4b08-bbb3-a80d7396141c',
    'identification_number': 44461461,
    'item_consumption_1': 1007,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 427.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ea1ac9c-34d8-4447-9887-45f6481b47f2',
    'identification_number': 42627117,
    'item_consumption_1': 27895,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10381.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c93e37f3-e0ac-44e2-b480-5f6fdce1b8fb',
    'identification_number': 29701759,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 143.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3eca85ed-b331-4163-82f2-113d00935699',
    'identification_number': 9063455,
    'item_consumption_1': 7042,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2992.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '309360a7-0559-4008-8653-02080ae77fa8',
    'identification_number': 40122654,
    'item_consumption_1': 14.34,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 342.04,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '68dce53d-1fda-4bd5-bfbb-bb9b8a340358',
    'identification_number': 16973720,
    'item_consumption_1': 3833,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1497.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e628cc8-da3d-40f0-b741-df4fd6c3e9dd',
    'identification_number': 32140215,
    'item_consumption_1': 973,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 380.71,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e43d4d43-6a8b-4d12-b6bf-07ded455f5eb',
    'identification_number': 92850391,
    'item_consumption_1': 1691,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 716.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c452498-d1f5-4776-ae91-5569f017511d',
    'identification_number': 102159459,
    'item_consumption_1': 38.62,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1123.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '85692fa5-2752-49fd-bfd0-8dc4f235c1a2',
    'identification_number': 106613979,
    'item_consumption_1': 20390,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2602.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33ea4467-80ff-4bd4-9f00-90355e579195',
    'identification_number': 103752234,
    'item_consumption_1': 2508,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3794.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03cbb7e1-ea9c-4aa2-8923-6f39f1799f0f',
    'identification_number': 37328549,
    'item_consumption_1': 7139,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3028.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28a4f65b-ed1c-4963-a8dc-0fc24604d16c',
    'identification_number': 93754680,
    'item_consumption_1': 9594,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4066.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a07eac0-65b8-4cbb-b8d3-fd7330071f98',
    'identification_number': 31778178,
    'item_consumption_1': 2146,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 837.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce376e99-08b6-4e81-b844-542d68c6e11d',
    'identification_number': 19450303,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 12525.57,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'dbb61fb3-3835-45ef-a09e-c9e654cde680',
    'identification_number': 68135580,
    'item_consumption_1': 14.06,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 335.34,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9f6e21e7-2108-4fe0-848b-f025c0aa373b',
    'identification_number': 47989050,
    'item_consumption_1': 3271,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1910.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7071868-3ec1-465c-9c0f-1d882ff44c7c',
    'identification_number': 31497861,
    'item_consumption_1': 175.2,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 10005.67,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '9a0dcdb9-2b32-4e4d-9d0d-1c1fa1c0e27c',
    'identification_number': 17394244,
    'item_consumption_1': 2970,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1160.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc7a6b5b-8d50-4c88-86f3-9a40394b2eaa',
    'identification_number': 29160758,
    'item_consumption_1': 38,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 16.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b25003db-a805-4feb-a64e-009e32fdddc3',
    'identification_number': 7284012,
    'item_consumption_1': "'-65.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 05/2023',
    'item_total_value_1': "'-20.76",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'b22114fa-dbde-4f7b-b16b-e2d45ac719ba',
    'identification_number': 16633210,
    'item_consumption_1': 5140,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2002.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '540bc89d-ac31-42b0-9d4d-8878b080b628',
    'identification_number': 108178781,
    'item_consumption_1': 1518,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2296.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '172573cd-7a6d-447e-bfe0-993ea1d7f007',
    'identification_number': 19296460,
    'item_consumption_1': 120,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 50.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8a092bd-1038-41d3-bab8-a37cf045c299',
    'identification_number': 12743542,
    'item_consumption_1': 69.96,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3995.4,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3aef2bd0-f57f-408c-821d-8a98978282b0',
    'identification_number': 13103806,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '16c44e07-0ceb-4412-81b0-163d6e2898ac',
    'identification_number': 44478046,
    'item_consumption_1': 26690,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9932.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd25b91a1-db25-4999-8b18-d4d064b0c01b',
    'identification_number': 43814166,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '18b53afb-67ad-4b11-a2e6-6dae238e6c44',
    'identification_number': 62072137,
    'item_consumption_1': 2886,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1223.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5c15d34-1e78-47e1-954b-42951559458f',
    'identification_number': 19452160,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '48d8989d-e4e4-499e-b176-a38916aae4d4',
    'identification_number': 99801736,
    'item_consumption_1': 20132,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7492.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b50d763-fb24-44d8-8dd5-032aa1d3a869',
    'identification_number': 32500459,
    'item_consumption_1': 15.22,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 712.78,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a6bd25ea-fdbe-4bac-9d3b-4584eaf8cbd8',
    'identification_number': 23078758,
    'item_consumption_1': 20.03,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 582.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b79cb661-626b-4c93-a232-fe5dba3a45df',
    'identification_number': 94604916,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c11b404-cf62-4c62-a705-37f92f74591c',
    'identification_number': 4334671,
    'item_consumption_1': 2623,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 334.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbb9e947-d030-4ee2-8336-313f549416e3',
    'identification_number': 45677468,
    'item_consumption_1': 1129,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 478.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '790d9748-7f3e-49f8-b550-0859644ca0aa',
    'identification_number': 64175251,
    'item_consumption_1': 97.9,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2847.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7aff9ffc-4d6a-4cda-a0e8-1ee5f2e84a22',
    'identification_number': 22905139,
    'item_consumption_1': 4.264,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 1616.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b3d13bc-2be6-482f-9fde-06566a5681fb',
    'identification_number': 8157944,
    'item_consumption_1': 11503,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4487.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7644a8f-b3d9-4fc5-9940-db2fa944c17a',
    'identification_number': 29957176,
    'item_consumption_1': 2489,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1056.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4bae9c8-a397-4079-adb5-1a222244d87b',
    'identification_number': 94172170,
    'item_consumption_1': 13718,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5363.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c564138-bc3b-44d1-8685-de90f2769904',
    'identification_number': 92638775,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1133.18,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ec1f57e8-ba33-4bf8-95b3-d1a3d6003ec8',
    'identification_number': 38893614,
    'item_consumption_1': 21946,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2801.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd90aa62d-ae50-410d-b736-37515e9c16cb',
    'identification_number': 103120114,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '478c7146-26c3-482e-8d24-7ef8bfb34fa8',
    'identification_number': 11310928,
    'item_consumption_1': 4380,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1855.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb2e5991-8286-42b8-9fc0-56027d8c082c',
    'identification_number': 24017353,
    'item_consumption_1': 26.66,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 775.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c28ff875-69e2-4108-a27d-b5bda60e3853',
    'identification_number': 32403011,
    'item_consumption_1': 26811,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3422.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7ef08a9-0763-484a-aed3-ea7ea80b33c7',
    'identification_number': 32727917,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 7.83,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a3ce415d-3d7f-4954-9703-3d8fa97ed66b',
    'identification_number': 80788580,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f3649ca-5354-4a83-bf64-204c78e726bf',
    'identification_number': 93324138,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0ab95da7-fe98-44f6-9f65-1c82b8b9d91e',
    'identification_number': 27383067,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aecfb96e-52f7-4675-b827-b40f4a92ca8d',
    'identification_number': 97988804,
    'item_consumption_1': 11066,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4118.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bba379b1-6342-416b-a4c7-b8cedf4e22a2',
    'identification_number': 78610729,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ee5cb648-7365-448f-b7c2-3fb199328805',
    'identification_number': 29396735,
    'item_consumption_1': 5280,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2236.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c011b64d-4096-43b0-9105-7eb202ab5a6f',
    'identification_number': 72063564,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-2160.38",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '12c3dc68-0552-4c71-aa75-a2855d1585ba',
    'identification_number': 19296568,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '543326a8-bcdb-4d85-b0e9-03cadb251b8a',
    'identification_number': 62191780,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '745f0438-2c00-4e19-8e57-a551c0f20bff',
    'identification_number': 103284770,
    'item_consumption_1': 23486,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2997.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49d3bd8b-79c5-484f-b8ec-a95a7089b706',
    'identification_number': 66198666,
    'item_consumption_1': 52.25,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1519.73,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a9707e92-ca21-4ee5-b14d-0f0a2db2cc19',
    'identification_number': 49686518,
    'item_consumption_1': 4141,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1616.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7fc95cf-df97-44cd-8c08-c7643276c6ac',
    'identification_number': 33651868,
    'item_consumption_1': "'-2.173",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-695.41",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '45ad4c32-437a-4d6a-8edf-d699e32506ed',
    'identification_number': 102844976,
    'item_consumption_1': 277,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 117.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1098df9f-5ebd-453e-abab-deeabee3c535',
    'identification_number': 15576388,
    'item_consumption_1': 4311,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1826.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e73f6ff-0259-4368-b367-87845c0616a7',
    'identification_number': 37438581,
    'item_consumption_1': 752,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 294.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e653060d-fab6-4c4f-b7cd-11cdcffcc317',
    'identification_number': 41381823,
    'item_consumption_1': 109579,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 13986.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef92464c-2330-4bbd-a4f1-da476a374dcc',
    'identification_number': 61481467,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4914.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d56fb07-e24b-410e-9e3e-ef0004b6d705',
    'identification_number': 45017336,
    'item_consumption_1': 138133,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 17630.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d19026c-3be9-4316-95d8-a7050f97d928',
    'identification_number': 68007493,
    'item_consumption_1': 1307,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 486.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56ddec08-956a-45c8-8f61-54f6c391288f',
    'identification_number': 106939203,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.85,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4efdc0c4-7338-4ca9-8843-b22f0ce9cfa2',
    'identification_number': 18855636,
    'item_consumption_1': 3104,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1210.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5eff6b9d-dc3a-4621-a976-f23c17e78293',
    'identification_number': 36149730,
    'item_consumption_1': 16560,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6162.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e287c508-85e6-4767-ade1-f76d6746fb98',
    'identification_number': 32629095,
    'item_consumption_1': 322,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 125.97,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6e312f50-4d6d-4ca5-9153-b56cd638d5a3',
    'identification_number': 56026684,
    'item_consumption_1': 7607,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2830.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42f5f97a-5db7-4ea8-a5f3-11264b71d11c',
    'identification_number': 92603360,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '840a408f-039d-478a-9e28-42cab00cc791',
    'identification_number': 101395868,
    'item_consumption_1': 4141,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1755.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae4f310c-5978-46b1-b360-03ba939fa242',
    'identification_number': 91392454,
    'item_consumption_1': 46799,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 17416.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9b78692-58a2-4a3d-bcb4-1665f75434ec',
    'identification_number': 82500312,
    'item_consumption_1': 6952,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2708.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7916014-cf65-4560-955e-44b266144ae0',
    'identification_number': 58734899,
    'item_consumption_1': 429,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 250.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3009aea-efcd-4292-b774-f869a65c71d1',
    'identification_number': 102404070,
    'item_consumption_1': 1178,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 687.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9fad1e8-b3ce-465e-88b5-0d371c500b5e',
    'identification_number': 15158241,
    'item_consumption_1': 9.69,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 231.11,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fdafc9a1-9f83-4330-b7c3-beab6b0997ef',
    'identification_number': 33799733,
    'item_consumption_1': 685,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 87.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4fe33f7a-fffd-4c7d-842f-7853d709aeae',
    'identification_number': 99786184,
    'item_consumption_1': 3679,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5565.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f84cfd5-aefc-4a00-a481-864baaa0a360',
    'identification_number': 43154581,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c119832e-f78e-426f-8265-825cbb65386e',
    'identification_number': 20515553,
    'item_consumption_1': 4192,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1639.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '318e17f7-189e-437e-8999-4f66e90ed8f8',
    'identification_number': 12381519,
    'item_consumption_1': 46.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1342.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '47189dc7-d7c6-431f-bdd6-9ded73aef60c',
    'identification_number': 33625930,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'edd9f445-4569-4672-86a9-f9cea6cb10f6',
    'identification_number': 33569932,
    'item_consumption_1': 5494,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2335.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5295174-b3cb-4b1b-bdb6-6a6daa11a8a5',
    'identification_number': 106471228,
    'item_consumption_1': 206,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 80.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c61777cb-a844-4d3e-a1d8-d09e83b066a0',
    'identification_number': 111740649,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a846fc5-4e36-4bd1-b48c-0651ad91f80f',
    'identification_number': 8749116,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f3b6a31e-b5ce-45a1-b079-55ff9822d71f',
    'identification_number': 95077677,
    'item_consumption_1': 4468,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1893.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8d702de-acbc-4d1e-99a1-ccbd899cfac5',
    'identification_number': 45017336,
    'item_consumption_1': 15284,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 8926.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86cb0384-fc97-43cb-81b5-1017328d9afe',
    'identification_number': 28993055,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bbcc481a-66d0-435d-82de-595c02b07919',
    'identification_number': 76387054,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.67,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c4f05649-f3f5-4462-8989-1ae6c0ec96e8',
    'identification_number': 16128060,
    'item_consumption_1': 11464,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 19734.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8613e273-d027-49d7-8c9d-34be7da661e5',
    'identification_number': 95332162,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70b5bb93-2502-4d38-945d-36195f9411f8',
    'identification_number': 84777117,
    'item_consumption_1': 14104,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1800.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'afe869cc-4048-47b8-9b2c-244b3494a5e4',
    'identification_number': 63195666,
    'item_consumption_1': 6689,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2607.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b70f5cc-0ad9-4237-b45c-f5f0a634e0f6',
    'identification_number': 45478260,
    'item_consumption_1': 73.45,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2136.35,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '06585040-00da-41ef-b6c9-91441f090cb8',
    'identification_number': 80201555,
    'item_consumption_1': 41968,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15618.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50101ba8-29bc-4331-9a25-4bec3aaa105b',
    'identification_number': 948807515,
    'item_consumption_1': 452,
    'item_description_1': 'Consumo em kWh LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 394.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3aaf6a2-085b-4bcc-957d-abeec7d362a4',
    'identification_number': 63896931,
    'item_consumption_1': 10759,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4003.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0a423f7-1369-4fbf-a512-5445e77fa169',
    'identification_number': 108608042,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3b151e79-9a54-40dc-9e8f-9808b86bbfd4',
    'identification_number': 92334865,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1263.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff8d8807-ae34-4a50-b5db-969bb589f7b2',
    'identification_number': 97072494,
    'item_consumption_1': 324,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 126.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ed5e7bdb-491f-4c72-bc5c-95c198ff189e',
    'identification_number': 5856930,
    'item_consumption_1': 18350,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 7167.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35481bb1-5b87-4704-8f15-e8bd93f46446',
    'identification_number': 16179307,
    'item_consumption_1': 256,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 100.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9f1b0a3-e536-4417-9a4b-5770206a96b7',
    'identification_number': 29862620,
    'item_consumption_1': 2778,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1085.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6b83942-b008-4f25-a38e-982d6f3d4ebc',
    'identification_number': 8585857,
    'item_consumption_1': 20157,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 8563.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ace987dc-8875-4a89-be6d-2b6d7e488155',
    'identification_number': 106965883,
    'item_consumption_1': 2125,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 828.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b6b7734-ffc8-4f1d-b882-76bb4e773bfa',
    'identification_number': 106035770,
    'item_consumption_1': 51,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c405bfe-7abf-4218-94b3-7409b1bdb4d3',
    'identification_number': 50940694,
    'item_consumption_1': 8521,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1087.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ebad1f1-6139-4f9d-b184-09a80880d42f',
    'identification_number': 43626955,
    'item_consumption_1': 1172,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 684.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f905209-13d5-4def-95a1-2068f83498bf',
    'identification_number': 100693490,
    'item_consumption_1': 16731,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7098.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9a8c935-be23-4892-ae71-45e8d4ff777b',
    'identification_number': 99185296,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ad855ea1-50f8-42ee-8e31-9f304bce25aa',
    'identification_number': 36948209,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 97.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3a740788-8593-47cb-a998-a32bf53611b0',
    'identification_number': 91980380,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'afe9ad44-9019-4f09-8b54-abf3d168dcc8',
    'identification_number': 91996058,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1f86b8b0-6172-4a21-a922-857e69cb2e57',
    'identification_number': 29966060,
    'item_consumption_1': 61,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f3ce924a-7bd5-4002-9991-18922143810e',
    'identification_number': 47573481,
    'item_consumption_1': 26117,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9719.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e745c443-82fc-4c27-bf94-161be85042f6',
    'identification_number': 16026314,
    'item_consumption_1': 5123,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1997.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67b06e73-7a27-437b-9660-c2eebc9af902',
    'identification_number': 105323640,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f536bde-2228-4182-8331-0962b0e541f5',
    'identification_number': 109858859,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a475509-8e5d-4cde-a38c-3ecb7c1f3bc6',
    'identification_number': 98254006,
    'item_consumption_1': 1111,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 471.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a05c6456-e022-4bca-be56-be1cd5f5547d',
    'identification_number': 31900852,
    'item_consumption_1': 4220,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6384.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7081802c-766c-4fcb-aedf-92e26a62b8d4',
    'identification_number': 12718750,
    'item_consumption_1': 6.03,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 350.77,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8c0bc5c7-6219-4b83-8d06-8d20a0ddf421',
    'identification_number': 65399331,
    'item_consumption_1': 3365,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1313.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f3576d5-e5ac-4366-b998-8f0dfc041060',
    'identification_number': 95867562,
    'item_consumption_1': 1416,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 553.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63b0b28a-8535-4e7a-857c-e708c41c710c',
    'identification_number': 29705932,
    'item_consumption_1': 15,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 22.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd117f298-5a52-4c92-b753-53d84f398737',
    'identification_number': 23660872,
    'item_consumption_1': 25.78,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 749.81,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '830b333a-05f3-4cf4-99ca-76081e99290f',
    'identification_number': 36289515,
    'item_consumption_1': 2257,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 958.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c1f58ec-732b-43dd-a022-1a9c52064cc0',
    'identification_number': 27135357,
    'item_consumption_1': 935,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 397.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '498c36e6-6eea-4edf-bcb7-40d2cf81efa2',
    'identification_number': 106868250,
    'item_consumption_1': 369,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 558.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4610d8d-62fb-4db4-a7fa-ea02f0942b9b',
    'identification_number': 68007493,
    'item_consumption_1': 1307,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 166.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2857dda3-95d8-425c-b186-b670d87c8a51',
    'identification_number': 106868250,
    'item_consumption_1': 60.95,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1772.78,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5b59576e-0673-4ffb-a86c-0c2d0b06581a',
    'identification_number': 41611187,
    'item_consumption_1': 112456,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 14353.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbce8438-aad0-4ebc-bf07-383ddb85ee0b',
    'identification_number': 99505266,
    'item_consumption_1': 275,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 107.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fd4938d-68cf-4add-ada9-468a2b2a8c14',
    'identification_number': 15547159,
    'item_consumption_1': 3510,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1370.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e11ea36-6419-48cf-aa63-69cbb080d9d8',
    'identification_number': 108608042,
    'item_consumption_1': 4694,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1993.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb369b4f-713a-42eb-b5f6-a5cd985788d7',
    'identification_number': 69637067,
    'item_consumption_1': 3095,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1310.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee80ef00-81ca-4cb7-9cda-9d69596e14f1',
    'identification_number': 38432005,
    'item_consumption_1': "'-832.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-288.78",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '82100f84-2c6a-4c52-91cd-88fe7a02c635',
    'identification_number': 70581150,
    'item_consumption_1': 4366,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1702.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca847273-6054-4efa-a6e1-eb669b2c2cf4',
    'identification_number': 106607120,
    'item_consumption_1': 1195,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 505.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8f44c5a-0fda-4f84-be70-0effd7e9b15a',
    'identification_number': 109785983,
    'item_consumption_1': 600,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 907.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7f13a3a-211e-479f-90d3-d3979a431ce7',
    'identification_number': 95733086,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b4a1bd8-d2d9-44f7-b215-c62b717e8952',
    'identification_number': 17372674,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 112.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9d1d677-889e-4c43-9593-c6ed5414b2ed',
    'identification_number': 38089807,
    'item_consumption_1': 4363,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1851.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfd16a81-46b2-405a-bb72-99b3f41febba',
    'identification_number': 11011130,
    'item_consumption_1': 7386,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2886.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6ebf806-430c-435c-9001-8123a0d156ed',
    'identification_number': 14671549,
    'item_consumption_1': 5.46,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 158.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e786dcc5-7bf4-48c2-9428-700c94723525',
    'identification_number': 936267839,
    'item_consumption_1': 159,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 138.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcf148ef-2668-4e98-baf8-e7dc9c649fe0',
    'identification_number': 103100520,
    'item_consumption_1': 968,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 410.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4579527d-2ebf-46dc-a940-1b5fb44d0c9c',
    'identification_number': 31818030,
    'item_consumption_1': 184,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 71.99,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7683c357-c087-4ee2-aa52-6aacffc99787',
    'identification_number': 8115621,
    'item_consumption_1': 240,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 93.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52678388-34af-499e-81da-d068ae6df328',
    'identification_number': 27222489,
    'item_consumption_1': 6079,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2370.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '500b2274-c6d5-4472-8e91-99692995be2f',
    'identification_number': 56334044,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ccbf4981-f339-42cd-af33-44c9b12d4a87',
    'identification_number': 36235334,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8c64a34d-809f-4411-9b2e-ee9aff0f5d15',
    'identification_number': 56881444,
    'item_consumption_1': 1079,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1632.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1998b728-ff9d-4ce5-99b3-e2a424808098',
    'identification_number': 43750583,
    'item_consumption_1': 241.92,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 7036.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '80ff5cc8-e640-42d7-8617-4e7317cdc55e',
    'identification_number': 78610729,
    'item_consumption_1': 5753,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3359.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9795facc-4147-4bb5-a6e8-c41237a1e045',
    'identification_number': 106410687,
    'item_consumption_1': 3742,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1462.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f358e297-2050-42ab-b953-95353cc1f3c0',
    'identification_number': 30405467,
    'item_consumption_1': 363,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 153.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bf49c4e-1bfd-4b56-8608-dc510e2b5603',
    'identification_number': 68029098,
    'item_consumption_1': 60.66,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3464.28,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e758d20f-11b7-48e1-9bab-dc171808d0df',
    'identification_number': 74517686,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a22b335b-e966-451d-9fb2-6eba95554b10',
    'identification_number': 28034422,
    'item_consumption_1': 6014,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2554.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49e9a5d5-6901-480a-b0bf-c04a22c56a74',
    'identification_number': 38052687,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b917c88c-50e5-4d19-bee9-b7d5f35edec1',
    'identification_number': 9073639,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f02f5dd-5607-408a-be1b-e322be7d8880',
    'identification_number': 67030165,
    'item_consumption_1': 42.9,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1247.78,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0e4d50b1-b014-493f-8bd3-57733559520a',
    'identification_number': 99713349,
    'item_consumption_1': 1083.6,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 31517.53,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '837fb8e9-0508-419f-adef-b008a4815143',
    'identification_number': 97851000,
    'item_consumption_1': 97,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 37.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec5ee3f4-33cc-4596-acff-39165c968dfe',
    'identification_number': 91975522,
    'item_consumption_1': 1360,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 575.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e475ed15-930f-446d-87d6-9b7e9094e670',
    'identification_number': 56698151,
    'item_consumption_1': 10.2,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 243.28,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '12ea525c-2e74-407a-b028-ee6a70b9dfd1',
    'identification_number': 79703070,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5fb01b3b-4ff1-4117-9a27-de414f30c33c',
    'identification_number': 14389754,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 234.58,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7750389c-ce24-4b7e-936b-a8bb7778b553',
    'identification_number': 6270476,
    'item_consumption_1': 267,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 104.41,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '97b43fd0-aa0b-4e45-a788-763a1431f119',
    'identification_number': 97490601,
    'item_consumption_1': 3295,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1284.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa5b20c7-63a6-4444-9d25-8ce5507bd1a8',
    'identification_number': 44628200,
    'item_consumption_1': 6642,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2595.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11980d94-2fed-464b-96fe-d1c2d78f7efa',
    'identification_number': 58724591,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ba2cf42-7c7b-4ada-b1ba-1c6e33f5def4',
    'identification_number': 15130185,
    'item_consumption_1': 755,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 294.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53db93aa-55a1-4f76-b1b4-e42080f27392',
    'identification_number': 92158064,
    'item_consumption_1': 1058,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1600.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8955ef87-b9fc-48c9-a814-393889744efa',
    'identification_number': 79663036,
    'item_consumption_1': 4412,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6674.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa585858-a145-4b08-944e-3bfac08d539e',
    'identification_number': 107622823,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0369428a-fb6a-4281-a50b-25d40a5b1040',
    'identification_number': 38220474,
    'item_consumption_1': 2559,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 999.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4a098be-15ae-4667-ab60-74ad73b44e63',
    'identification_number': 14000105,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62320396-06d1-4f0c-a364-33c59f679549',
    'identification_number': 68111720,
    'item_consumption_1': 6654,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2600.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7475bcce-9d13-4f48-b3cb-86239e2fe302',
    'identification_number': 44024924,
    'item_consumption_1': 116932,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 43515.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82ce1c52-73f7-4f02-9987-0a419f47bf1d',
    'identification_number': 106278860,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd81f7233-7348-405f-a73a-34d826d7504c',
    'identification_number': 59541288,
    'item_consumption_1': 0.46,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 21.56,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b3276bec-19de-4756-b9ea-04b76d7807ba',
    'identification_number': 41460510,
    'item_consumption_1': 206,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 120.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6b7cf17-2abf-4292-8589-564e35ca6d87',
    'identification_number': 81777302,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 344.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a8c9b74-583e-41d5-b025-4145591bf7fb',
    'identification_number': 16125320,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8f26fa69-0196-481f-8d72-1bf4bcb28953',
    'identification_number': 100765076,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV.CORR. MONETARIA AJUSTE FAT.',
    'item_total_value_1': "'-9.37",
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '15beb44c-09b0-4f2b-9957-5b01fd88ac1f',
    'identification_number': 33133077,
    'item_consumption_1': 232,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 90.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72973d70-9a94-4d7d-95bb-6c9647647ba1',
    'identification_number': 22902694,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4858a961-1a71-4c66-bff1-71e60b496c13',
    'identification_number': 77217896,
    'item_consumption_1': 3277,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1913.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e676784-72bc-4718-bf7e-ef5518a0476e',
    'identification_number': 29677289,
    'item_consumption_1': 1648,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 642.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '543fb146-a02a-4576-9a2f-4ad7ea3fc064',
    'identification_number': 104616890,
    'item_consumption_1': 16000,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5954.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7ca022b-a4aa-4110-be72-2d3792cd4c4b',
    'identification_number': 90908600,
    'item_consumption_1': 2826,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1102.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4ffd897-deaa-4f75-be81-eb49f593e440',
    'identification_number': 69948100,
    'item_consumption_1': 8446,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3581.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd8bdb99-219f-4759-b9a4-9ba9541d9734',
    'identification_number': 16111621,
    'item_consumption_1': 913,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 693.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64d6e85c-9ea5-4020-a16d-157c3e1ed83e',
    'identification_number': 109530608,
    'item_consumption_1': 61.49,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1466.58,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '194bb363-0aaa-4328-98a5-96e88b5bc4e1',
    'identification_number': 100217290,
    'item_consumption_1': 492,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 191.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09ea089c-2e27-4b58-bc8a-d9a399045550',
    'identification_number': 36222852,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6bad8b6c-11a1-4bd1-8792-8b3c0a286eb7',
    'identification_number': 94480524,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '76fe6e4e-661d-4c45-a2ca-aed9c1ce4d52',
    'identification_number': 92096115,
    'item_consumption_1': 128.9,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 7361.47,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e10688f7-8047-4e7f-975a-a6102e93f197',
    'identification_number': 32046634,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0f4c01cd-8119-4225-b497-3275dded42ff',
    'identification_number': 69811369,
    'item_consumption_1': 1886,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 799.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f817c19f-c14c-4173-92b8-003eef842e8d',
    'identification_number': 70086389,
    'item_consumption_1': 3259,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1271.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3cd59ea4-7165-4850-8b3f-099c463cc0a5',
    'identification_number': 97637238,
    'item_consumption_1': 10304,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3834.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80654d74-d687-4415-9266-90d721396686',
    'identification_number': 22866639,
    'item_consumption_1': 672,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 285.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60557a69-57e2-44ba-b97d-f85cf8275a78',
    'identification_number': 108902919,
    'item_consumption_1': 257,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 100.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd444ba95-340d-49d6-94bb-c50df4779c5e',
    'identification_number': 53263081,
    'item_consumption_1': 3650,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5521.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a5411b9-bc8f-4b8a-bf8d-3357f1b09339',
    'identification_number': 49715615,
    'item_consumption_1': 3335,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1417.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d1e4b03-32a7-4426-9af3-d3001db8620e',
    'identification_number': 41490533,
    'item_consumption_1': 54.69,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1304.39,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '91bef8dc-484f-4a57-b1e3-ce3308ea7239',
    'identification_number': 32191871,
    'item_consumption_1': 1199,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 700.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f0273b1-14cc-48e1-9b1b-0355e7580da9',
    'identification_number': 14227096,
    'item_consumption_1': 101,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31b44164-55ce-4ad1-97ac-3a362da95663',
    'identification_number': 110411196,
    'item_consumption_1': 119,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 46.56,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '74c3b726-620a-4b88-81d3-7a4a19847897',
    'identification_number': 18006540,
    'item_consumption_1': 2420,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 945.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '156ed003-689d-43f2-a61d-e01c280fbd39',
    'identification_number': 9671471,
    'item_consumption_1': "'-10.097",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 07/2023',
    'item_total_value_1': "'-3230.42",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'c8b46543-263d-4d0a-b3a8-05ad398b45cf',
    'identification_number': 41700929,
    'item_consumption_1': 27,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b18527d-a313-41ad-9ccf-b5420bd49ff2',
    'identification_number': 107328160,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5e93468-301d-4d26-a875-e091e1368c91',
    'identification_number': 108940128,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b30d50a8-2c53-4a2a-b28d-c0a1f4a2dfe2',
    'identification_number': 36849510,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.85,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1d71c205-6177-4fca-b223-ea13fdfe12a9',
    'identification_number': 22902694,
    'item_consumption_1': 1405,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2125.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca06bdee-b925-491e-9056-4afbfac98f22',
    'identification_number': 100627188,
    'item_consumption_1': "'-2.584",
    'item_description_1': 'ENERGIA INJETADA PT TE 11/2023',
    'item_total_value_1': "'-1237.5",
    'reference_month': '11/2023',
    'referente': 'Compensação crédito energia',
  },
  {
    'uuid_items': '4de5ea6e-ecbb-4813-9129-276ffd9bc24d',
    'identification_number': 54453844,
    'item_consumption_1': 986,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1491.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a3b869a-1224-4ced-b395-767c4e9907f3',
    'identification_number': 34560980,
    'item_consumption_1': 10306,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4015.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cca5c25-a7f9-4255-b8ef-5d80980428f6',
    'identification_number': 5415870,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3ac1a8bf-80c7-4f2b-8901-208d46efd82b',
    'identification_number': 75403943,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '758d7c30-867a-4324-a0b7-760bfb38a01d',
    'identification_number': 108393798,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7784b49-7022-475a-89e6-3c87c542323c',
    'identification_number': 67162657,
    'item_consumption_1': 2127,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 829.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '420a8139-9007-4efe-9947-80aa81052a3a',
    'identification_number': 70073996,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 96.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf914a61-28a7-4815-acd3-3b8acb2b9e07',
    'identification_number': 15830543,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.06,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b24c2d7b-93e2-4cfe-b57f-e60940240c48',
    'identification_number': 7090587,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.96,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b5a30a9c-aa50-4344-87ad-ab6431418138',
    'identification_number': 27112101,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bdd2bdf0-fffe-43bf-8110-9107e0551cfc',
    'identification_number': 99786796,
    'item_consumption_1': 165,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 64.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6791cc7-bb9a-4c3b-b471-d89b4a4aee8e',
    'identification_number': 40528111,
    'item_consumption_1': 666,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 259.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24aec474-4bf1-489f-9e48-f14d43a6a037',
    'identification_number': 10703454,
    'item_consumption_1': 3196,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1354.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2850b95b-2d21-42a0-8f8e-39bd506a6fb9',
    'identification_number': 14982960,
    'item_consumption_1': 543,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 230.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eed1f6e0-83eb-4615-9dca-c0efe5fa7732',
    'identification_number': 96393505,
    'item_consumption_1': 12384,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4833.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0636d476-99ec-43b3-b994-2aa6b7018131',
    'identification_number': 20579071,
    'item_consumption_1': 734,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 311.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b837bed-565b-4764-aa13-db1a9a5b0fb2',
    'identification_number': 92334865,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 122.13,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a47a6d17-e814-4adb-a9b2-1b31603471d9',
    'identification_number': 99540584,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ae876904-5dd9-4ae2-b5d9-51233c2a7415',
    'identification_number': 88703614,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '51e79db2-1ddd-4062-ba39-bd18adddf7aa',
    'identification_number': 98688987,
    'item_consumption_1': 6698,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2610.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c64f5054-3c15-474f-bc6a-36ba5c7129b5',
    'identification_number': 37133438,
    'item_consumption_1': 1852,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 787.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '682f4f43-4e36-40fa-955b-5b24004dfc7c',
    'identification_number': 43814166,
    'item_consumption_1': 21.2,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 505.64,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'eabb92f0-b572-4bdf-ac05-23c6e5786b45',
    'identification_number': 47108690,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6de1142d-767c-407b-a9db-c1deec6169fc',
    'identification_number': 58745289,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6e303c9-e067-4d06-b9e7-d63bb1213b64',
    'identification_number': 103175954,
    'item_consumption_1': 6821,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2660.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4323bac-17d5-4f60-8721-b9826dae0171',
    'identification_number': 67917283,
    'item_consumption_1': 124.86,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 7130.74,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '089585dd-f573-4482-bba1-171346a72a90',
    'identification_number': 53747755,
    'item_consumption_1': 33804,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12580.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38cb9c90-2bda-4f54-96c9-6ef48cceaee5',
    'identification_number': 29700485,
    'item_consumption_1': 19176,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2447.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33adfcb7-feb6-414b-bdd0-ccbed0ee64e3',
    'identification_number': 28166159,
    'item_consumption_1': 5852,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3417.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25d42c03-80ce-4185-abed-c068b46bcc39',
    'identification_number': 92141200,
    'item_consumption_1': 123.98,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3606.07,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a71e3b9d-288a-4262-a69f-730b92f2d4eb',
    'identification_number': 18267211,
    'item_consumption_1': 2546,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 947.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53a57e80-801b-46ce-a144-00407311902e',
    'identification_number': 18536611,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd29dd66c-ebad-40b3-83da-4809727b8f77',
    'identification_number': 16485262,
    'item_consumption_1': 11422,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4451.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8bc5058-b53b-420d-8e64-2bd0e079b8dd',
    'identification_number': 79937667,
    'item_consumption_1': 6.28,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 149.8,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '365393d5-c5b1-4df6-9b38-cd21f8096a11',
    'identification_number': 59806753,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a9c7e32-5c5d-4882-ae22-5b7b1cd6fab6',
    'identification_number': 14619555,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4fe9a64e-831d-4ffe-b9cb-4645faf068ff',
    'identification_number': 103157980,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aab669e7-b310-4520-8f42-8d3c41135700',
    'identification_number': 23136863,
    'item_consumption_1': 1075,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 420.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef4a0213-72c6-4adc-bb1d-581d22681cc1',
    'identification_number': 111186056,
    'item_consumption_1': 224,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 94.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6868fd78-b2e2-4b11-aa0a-ef1a39ca7f75',
    'identification_number': 47509910,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-2229.54",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'aaa3bc59-ed53-4011-8f88-f5e9b56ae091',
    'identification_number': 104517905,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1fea1c3-5cc7-4755-964d-75f36ffb7cfe',
    'identification_number': 99134993,
    'item_consumption_1': 506,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 214.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '903b7700-420a-4ec0-8ac7-e33c972da5b9',
    'identification_number': 104642505,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5f6773b2-fc7d-4d2e-b4cf-45b96e40bbdc',
    'identification_number': 78368286,
    'item_consumption_1': 3645,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1421.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4f28e08-4745-47b9-910d-411b82343b5d',
    'identification_number': 42056306,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV.CORR. MONETARIA AJUSTE FAT.',
    'item_total_value_1': "'-0.1",
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': 'bd1ab890-1d15-491e-937a-7a466f726e79',
    'identification_number': 89080637,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '47a06ddf-e3ab-4cde-b627-8786660934b1',
    'identification_number': 111203015,
    'item_consumption_1': 321,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 136.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07691846-8765-4fed-8a1e-14cdf658ad67',
    'identification_number': 20513917,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '899bc506-8b60-4048-872b-c1950d6fc206',
    'identification_number': 99753154,
    'item_consumption_1': 44.96,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1307.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b12af153-aae2-44d1-8f12-039ec459c1e5',
    'identification_number': 33760900,
    'item_consumption_1': 87972,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 11228.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74fb128d-6b46-4e10-93f9-c07da8d88453',
    'identification_number': 13924613,
    'item_consumption_1': 16154,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6011.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10726110-bb34-4794-964c-9d48d50b8155',
    'identification_number': 16246780,
    'item_consumption_1': 11,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 4.3,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ff751cee-9e3b-4c07-ad20-3151e4882acb',
    'identification_number': 105089060,
    'item_consumption_1': 1087,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 634.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd75ffe92-6bca-4daf-9138-a83dce59b1d0',
    'identification_number': 101853777,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9591c80b-5b11-4c85-bfa3-d2ea5afc8686',
    'identification_number': 45219893,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef76ed7b-e87b-46d8-a8bb-3a86817679df',
    'identification_number': 40032060,
    'item_consumption_1': 3.26,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 152.67,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0c757540-7b1d-4c53-ba1e-86e5a1b38be0',
    'identification_number': 111295815,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 41.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aac2b962-3ba8-453b-af82-b38ca6dfad92',
    'identification_number': 19451601,
    'item_consumption_1': 16.64,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 396.88,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '9b27ce24-6686-429d-bd26-d138fccc2898',
    'identification_number': 107350840,
    'item_consumption_1': 1045,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 442.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d35b538-b5b5-4597-b0ea-65488464cf6f',
    'identification_number': 5736234,
    'item_consumption_1': "'-682.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-218.31",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '8d69d80e-f8ed-4a0c-b0ad-0269d3c994e1',
    'identification_number': 68677413,
    'item_consumption_1': 931,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 394.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1358841e-a3bb-4ae2-b3d8-ecbfccaf5028',
    'identification_number': 18205704,
    'item_consumption_1': 241,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 102.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8682d207-a4dc-4532-81a7-407b831854f9',
    'identification_number': 103772561,
    'item_consumption_1': 131149,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 16739.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76d76e26-ce70-40d4-b420-88849d0ec6a8',
    'identification_number': 69703671,
    'item_consumption_1': 338,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 143.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3219fa64-6334-4795-bb8c-6c40645554d0',
    'identification_number': 33651868,
    'item_consumption_1': 13127,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5123.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34fbf874-f38a-4bf3-8ece-630b6ef47a35',
    'identification_number': 55619851,
    'item_consumption_1': 9592,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4061.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '135aa7e1-5221-4928-9d24-7220e8946ff8',
    'identification_number': 96313897,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a3f96cf3-5f2f-4f07-9934-4ea39a32926f',
    'identification_number': 32563256,
    'item_consumption_1': 421,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 164.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '639937bf-5bff-4b59-98a4-0e35aa7d8b69',
    'identification_number': 32845570,
    'item_consumption_1': 2209,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3341.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06bdef41-1b5f-4001-ba07-f822ce05eb24',
    'identification_number': 12404934,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b11e41c1-041d-472e-8873-2916481b41b9',
    'identification_number': 100637795,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c029573-40dd-4d9e-9dc4-35215213e235',
    'identification_number': 39672131,
    'item_consumption_1': 131.75,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3832.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd53955e2-d20b-4fd3-9a92-c2a71859f6ba',
    'identification_number': 100394060,
    'item_consumption_1': 275,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 116.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10c2ef47-62de-4687-93f5-688b91804f7b',
    'identification_number': 97676950,
    'item_consumption_1': 5885,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2300.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5af66e8f-b9c6-4a9f-a0e1-df315e8debf5',
    'identification_number': 22642803,
    'item_consumption_1': 172,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 72.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'abd033c6-b87f-4749-a3cd-8c5b1776085f',
    'identification_number': 63368005,
    'item_consumption_1': 100,
    'item_description_1': 'C.DISPONIB',
    'item_total_value_1': 77.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43935d81-4eec-4ad6-a680-ba491b10e49f',
    'identification_number': 92590292,
    'item_consumption_1': 1921,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 749.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab213c8e-b742-420d-a202-25e39ddc544e',
    'identification_number': 14886308,
    'item_consumption_1': 4300,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1824.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f733da4-dda3-4ef0-b0d7-a623601e5ad4',
    'identification_number': 33324964,
    'item_consumption_1': 5765,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2447.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa2ea967-adf0-429b-aa67-1a0164a42b2a',
    'identification_number': 11346221,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3c444fd-0112-47cb-904d-0276b12dae8c',
    'identification_number': 56706499,
    'item_consumption_1': 5598,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 714.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9222610-01ea-4f30-854f-bc0cb971cb03',
    'identification_number': 17023491,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 97.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd61e36d3-d0a5-41de-a006-a11d1822382c',
    'identification_number': 79702791,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd669cbce-089f-4f2a-b9c6-0ca6a2af84df',
    'identification_number': 33143986,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efc12188-db85-4555-ac04-827fda625483',
    'identification_number': 32486804,
    'item_consumption_1': 28570,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3646.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6dd745e3-8373-498c-bc7f-f988c0270b8d',
    'identification_number': 80508839,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7a4f220-57eb-4531-9a06-213d81a54ee1',
    'identification_number': 91980380,
    'item_consumption_1': 7834,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3329.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3763d5a6-19b9-4616-8bab-8320233bf100',
    'identification_number': 99042223,
    'item_consumption_1': 24091,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3074.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1198381-f12b-4e76-8a0a-01b18fa3d7d5',
    'identification_number': 105597554,
    'item_consumption_1': 376,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 159.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc7fc85c-8367-42cb-9a08-819cc2079b04',
    'identification_number': 102512426,
    'item_consumption_1': 18.35,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 533.71,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '46bc5981-b04c-4931-9c3f-3f15d7827002',
    'identification_number': 51210533,
    'item_consumption_1': 17.36,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 414.05,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f4695d0f-d726-465e-940b-47a5bb6dc3e8',
    'identification_number': 15465187,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fc34980e-2b12-4eba-a923-68db3cd9221f',
    'identification_number': 97970034,
    'item_consumption_1': 17134,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6376.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd6697de-fba5-4a73-bd4b-085eb0dea0f5',
    'identification_number': 42034515,
    'item_consumption_1': 65263,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 24287.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de7a2c31-de49-4c1d-865c-56276415a895',
    'identification_number': 100839100,
    'item_consumption_1': 1160,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 492.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '525a400c-40f0-4730-875f-05932ca25fa9',
    'identification_number': 56876815,
    'item_consumption_1': 21.89,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 636.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0852d386-4f3f-4659-aba7-153b6e9e2c13',
    'identification_number': 57740755,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e43a185-ad16-4a0d-8f58-1dfc553fc9ad',
    'identification_number': 41213947,
    'item_consumption_1': 112.32,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3266.92,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '24470259-2c81-4969-a11e-b0dc5fc9bfbe',
    'identification_number': 18898068,
    'item_consumption_1': 447.84,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 13025.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '79d9f6e4-b8ff-4d2e-ae4f-3e7905a358b2',
    'identification_number': 46886320,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c19124f1-e4ed-43d6-ac4a-e98f580d230c',
    'identification_number': 107956403,
    'item_consumption_1': 130,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 50.85,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7a72fc75-361d-485a-a16a-c126886e2b54',
    'identification_number': 19162090,
    'item_consumption_1': 1103,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 429.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb7b9f83-d24a-4546-8e0c-e04d1fd4f204',
    'identification_number': 103748601,
    'item_consumption_1': 6248,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2439.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2bbf2c2-bdc6-4db2-a027-df635457c7c0',
    'identification_number': 79969135,
    'item_consumption_1': 1201,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 509.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6905e87c-7489-41bf-900d-46f91852af70',
    'identification_number': 18539505,
    'item_consumption_1': 1838,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 780.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eec207e6-0d16-4e33-8bea-d771b7cc268e',
    'identification_number': 25945335,
    'item_consumption_1': 3966,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1549.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce880639-1e6b-42a2-b39c-7d2c9083d10a',
    'identification_number': 104328037,
    'item_consumption_1': 579,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 225.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09f0645d-71b2-4813-8d70-39432684ddfe',
    'identification_number': 40709140,
    'item_consumption_1': 22992,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2934.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d677128-28d9-428f-a5fe-cae327c519b9',
    'identification_number': 57109346,
    'item_consumption_1': 2.65,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 77.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7d901ede-9463-4a1b-86fe-fbbcb119e1d8',
    'identification_number': 103317600,
    'item_consumption_1': 9790,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3823.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42e832fe-f1a2-40e9-9aea-f628228f15d5',
    'identification_number': 18623549,
    'item_consumption_1': 1741,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 739.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27892227-4ac8-4801-86bc-89808d5ac502',
    'identification_number': 49715615,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '11550222-cdd9-4eea-b55c-2238b4e96794',
    'identification_number': 40207650,
    'item_consumption_1': 97510,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 12445.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51c56671-0b5b-42ed-9bc3-54c5f61176c9',
    'identification_number': 23691530,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3da6942b-9ca3-4a48-99e9-c885628c9d08',
    'identification_number': 42694310,
    'item_consumption_1': 5259,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7955.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49dfab4f-9c47-480a-b7b9-7d34604ce335',
    'identification_number': 110921399,
    'item_consumption_1': 859,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 336.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3783856a-cb0b-4e2b-b5bc-93b7069333ca',
    'identification_number': 67030165,
    'item_consumption_1': 19893,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7403.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd07cd1ae-c257-4987-8c62-af153947e444',
    'identification_number': 109460847,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '71a351d6-f89e-4990-916e-7556e29fc9b1',
    'identification_number': 102261016,
    'item_consumption_1': 94,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 142.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34b207a7-0722-466e-8d87-c33dc31674be',
    'identification_number': 111740592,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fde7dac-8a62-4f6c-bca4-fe4de89e0011',
    'identification_number': 39261565,
    'item_consumption_1': 641,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 250.8,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b6759463-e325-447c-8b9a-dca4643c2a27',
    'identification_number': 63897148,
    'item_consumption_1': 518,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 302.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc55c69b-8935-4f35-ba2a-4989d2a09aa6',
    'identification_number': 32046634,
    'item_consumption_1': 43.29,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1259.13,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '97c079f6-d13d-4c35-ac01-e807e5619566',
    'identification_number': 17697743,
    'item_consumption_1': 970,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 377.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fde94823-d01a-41ec-b681-523e13d83d8a',
    'identification_number': 84245590,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12d4c173-ca17-4e4b-954d-5bb1e6cff277',
    'identification_number': 36368431,
    'item_consumption_1': 3354,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1421.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec1dca4b-0a35-4748-9c8a-8af455eb702e',
    'identification_number': 30345359,
    'item_consumption_1': 19.43,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 463.44,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '639a83ca-1ba7-48ab-8461-2d05de92df03',
    'identification_number': 47766654,
    'item_consumption_1': 1128,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 478.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aaec411b-b303-4e4b-b437-3d754c453ef3',
    'identification_number': 107941589,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 156.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '47bfb6ca-f126-460c-950d-4ecc8f2fb246',
    'identification_number': 111203015,
    'item_consumption_1': 321,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 125.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3f91a86-34d2-4194-97f0-cf9ed3bec22f',
    'identification_number': 94384126,
    'item_consumption_1': "'-12.094",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-3874.33",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '15993f21-2658-4269-b4b3-b7eadd1f0a9b',
    'identification_number': 106116991,
    'item_consumption_1': 1425,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 603.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20463a03-1967-4b94-8c35-b0befbc80539',
    'identification_number': 8529205,
    'item_consumption_1': 17.31,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 412.86,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3ef41a03-6db1-4bbc-a81f-0ea8cb22be46',
    'identification_number': 95923047,
    'item_consumption_1': 265,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 103.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22aead5a-8b94-4ee9-a7a0-d695fcd33652',
    'identification_number': 96314443,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4e4bad37-2248-446b-849c-bfffc4cf736b',
    'identification_number': 31640427,
    'item_consumption_1': 7182,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2672.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3927fab4-7c55-406c-a1a4-243c8a1b6bb7',
    'identification_number': 9822950,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5660.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22476f14-9577-46c8-851f-e3a0f9e2fb76',
    'identification_number': 10361472,
    'item_consumption_1': 12092,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4712.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3bf9096-f1ff-404e-a9af-15bb95c30c50',
    'identification_number': 82743339,
    'item_consumption_1': 1853,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 725.02,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2291ce0c-1394-4501-a22a-664dc72ce45b',
    'identification_number': 75299372,
    'item_consumption_1': 1315,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1989.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61c8fbcd-ad01-46b5-8200-76add05b5213',
    'identification_number': 58904727,
    'item_consumption_1': 327,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 127.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '052f6705-3239-45e7-b774-1e4cf862ebc1',
    'identification_number': 30085918,
    'item_consumption_1': 2011,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1174.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17fc7b5e-6121-4bc1-84c3-90263b5c07bd',
    'identification_number': 12222615,
    'item_consumption_1': 8827,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 13353.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94717df7-0e6c-443c-ab98-96d4094a8aef',
    'identification_number': 55272584,
    'item_consumption_1': 591,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 250.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83d03139-e700-4d62-911f-a382259ea2b6',
    'identification_number': 101837593,
    'item_consumption_1': 8583,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3352.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3fb84b6-4e4c-4048-89a5-49d12e6db116',
    'identification_number': 98182501,
    'item_consumption_1': 551,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 833.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d048e5c-ee25-404d-b518-b080d3fa582e',
    'identification_number': 18469990,
    'item_consumption_1': 1345,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 571.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5879229c-6116-4a08-b53c-b009c9e9f20a',
    'identification_number': 106141864,
    'item_consumption_1': 170,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 66.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f50cbe41-1b77-49e8-b8e9-17ce0a3afdb0',
    'identification_number': 33751102,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '437bb972-0d41-4f59-9667-c4e779da506a',
    'identification_number': 19429525,
    'item_consumption_1': 17137,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6377.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42d35344-c137-4ce7-ba04-08edf7a3a79c',
    'identification_number': 23274310,
    'item_consumption_1': 19533,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2493.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1767d65-96ab-4cb0-b4cc-f78cfe6dc478',
    'identification_number': 16222539,
    'item_consumption_1': 6099,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2385.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a3a512e-ecdd-4000-b016-9df134da8204',
    'identification_number': 75597667,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b92a0b1-0525-4547-93d2-ea6a71d62659',
    'identification_number': 99327392,
    'item_consumption_1': 1894,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 804.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab27baba-03eb-47d0-ad52-09213d3a2ad5',
    'identification_number': 9790519,
    'item_consumption_1': 3181,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1240.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbfd3fc4-e31d-404a-8619-6912ad39dc72',
    'identification_number': 22672397,
    'item_consumption_1': 7767,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3300.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7969a0b2-49d0-4ac4-abf5-fcb5d2bfbb9e',
    'identification_number': 40117626,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c63f226a-7871-4a7e-8faf-0d68078f84ad',
    'identification_number': 45563560,
    'item_consumption_1': 316,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 134.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c030efb-e65b-46e4-a213-a619afc367f9',
    'identification_number': 48043907,
    'item_consumption_1': 1051,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1589.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbf1a40c-e597-4f08-a720-843e675c24fc',
    'identification_number': 67030165,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 538.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7695d641-733c-4ea8-88ad-a6e4ea8f8d3a',
    'identification_number': 7090587,
    'item_consumption_1': 588,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 229.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '100697fe-6d1d-434c-ad55-2088818cf6bb',
    'identification_number': 90950917,
    'item_consumption_1': 6232,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2434.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '821f4895-36dd-450e-8e3b-59bf1e3b1752',
    'identification_number': 71998012,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '18d104a5-44eb-4af2-9908-a8f910264b2d',
    'identification_number': 30132860,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '72f49f23-6a73-44b0-ac2c-a58332553370',
    'identification_number': 103100520,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-50.38",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'bab5748e-476a-4f8b-9cc6-e18c56e50a7c',
    'identification_number': 96386649,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d334e56-bb56-4c35-a95d-3cacc6e36ed0',
    'identification_number': 91654912,
    'item_consumption_1': 9633,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4091.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '898b50a1-a020-4388-b8f1-8354d1b50e82',
    'identification_number': 109421183,
    'item_consumption_1': 2184,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 851.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1ed741b-6856-4a8f-a654-e60e47b6882e',
    'identification_number': 91858194,
    'item_consumption_1': 10.52,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 611.96,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '42cb24aa-fd14-4343-8fb3-ba7f3fe8e077',
    'identification_number': 35137010,
    'item_consumption_1': 346,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 134.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a439ca3-353f-4a54-995f-3fbf81be036d',
    'identification_number': 40709140,
    'item_consumption_1': 53,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 20.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c2af070d-2c03-43e0-ad3e-615824c4971a',
    'identification_number': 84968559,
    'item_consumption_1': 0.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd72f945c-f591-4ebc-a9a1-171a3bb7c3c7',
    'identification_number': 96898550,
    'item_consumption_1': 510,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 215.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61266b7e-92e2-45f8-9cbc-a2cd832f7070',
    'identification_number': 19302711,
    'item_consumption_1': "'-832.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-266.9",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'd5bf2cbc-4485-4860-8795-250e4482022a',
    'identification_number': 18077838,
    'item_consumption_1': 2383,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 931.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4833d5f5-5517-4840-89a8-45011e377d0a',
    'identification_number': 3030777617,
    'item_consumption_1': 9777,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 2916.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82e03e8d-dd1e-4723-8672-e042e7bc0035',
    'identification_number': 48630209,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 10.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b128b8e-eeaa-49db-8c26-b473e3cdb8c0',
    'identification_number': 100822959,
    'item_consumption_1': 239,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 101.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82f0e9f4-f20a-4e4a-9f9c-8a13f2c136cc',
    'identification_number': 77405340,
    'item_consumption_1': 109497,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 13975.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5c4a2df-f1f5-4617-be4e-54423333bae3',
    'identification_number': 98254006,
    'item_consumption_1': 1111,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 434.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4cb6338e-dd08-459e-887d-0844c15a9222',
    'identification_number': 15232506,
    'item_consumption_1': 2371,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 926.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b86e05d-735d-44be-be2d-591c5066ee89',
    'identification_number': 29984971,
    'item_consumption_1': 15511,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5772.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffef2511-69ba-4e58-96dc-ddbaeb2428b8',
    'identification_number': 43875939,
    'item_consumption_1': 71.04,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4057.08,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'd7913c01-0746-4f37-9fd5-79270de76baf',
    'identification_number': 94658676,
    'item_consumption_1': 1221,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 475.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc469d2e-530f-4bd5-8566-9dba40fc6b51',
    'identification_number': 106228633,
    'item_consumption_1': 467,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 182.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '104d52eb-4e82-4d7b-beac-6524c5e59e00',
    'identification_number': 107622823,
    'item_consumption_1': 2164,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 919.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3787de4a-01e8-4730-8e2f-b7350f8711d0',
    'identification_number': 106358340,
    'item_consumption_1': 190,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 80.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b1bfc92-f09f-4d25-8ecc-2733c17d765a',
    'identification_number': 23029196,
    'item_consumption_1': 800,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 312.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c7e38d3-0327-4b22-946a-41c3a67078c6',
    'identification_number': 94650977,
    'item_consumption_1': 11704,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1493.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4ae0ae6-13c8-48f4-8b07-5d8ad12c5d0f',
    'identification_number': 15741036,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 42.96,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a22496a8-aa60-45fd-8d44-d9baf5dcde19',
    'identification_number': 62191780,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 9418.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ac7814c-0f20-40cb-aa3f-56ffca9ead93',
    'identification_number': 100797075,
    'item_consumption_1': 595,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 900.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03d0639b-cf19-4a3b-b842-db7a7a3bbdea',
    'identification_number': 97614858,
    'item_consumption_1': 2622,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 334.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6edaa697-4811-4577-9209-4e84333369d7',
    'identification_number': 18456669,
    'item_consumption_1': 5642,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 720.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '055ca917-efe5-4f6b-9aa9-21e28eb642e8',
    'identification_number': 6270476,
    'item_consumption_1': 1476,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 627.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c902878f-5a2b-4b00-8452-5fd7d5b731c5',
    'identification_number': 98270257,
    'item_consumption_1': 330,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 140.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46546dc7-d819-44d5-867b-fa6746eac85c',
    'identification_number': 5352533,
    'item_consumption_1': 1989,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 844.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc2afa68-78db-480a-8bb1-0cd889117fcd',
    'identification_number': 107462036,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '70a394c0-abc5-4f95-8b8f-791be9546256',
    'identification_number': 43739580,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9712ee47-b302-4291-889a-769912e796e1',
    'identification_number': 77411609,
    'item_consumption_1': 262894,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 33554.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ba3c96b-b7c9-4680-8564-b70a565759ee',
    'identification_number': 59264993,
    'item_consumption_1': 7.47,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 178.17,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1bdfbd85-9c8c-4aeb-9661-a61cad0c2047',
    'identification_number': 26771560,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac12dce4-725f-420f-9b75-2eb7807a24d6',
    'identification_number': 75565730,
    'item_consumption_1': 28687,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10675.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9afaba7f-f0fa-4e8e-8a67-05dd2de8aca5',
    'identification_number': 56912447,
    'item_consumption_1': 8576,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3632.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '523dede9-a544-433e-bde7-45633ef58ebd',
    'identification_number': 49074423,
    'item_consumption_1': 11097,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1416.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9ce182e-fb2e-4d35-86df-3e9eaa24b4ad',
    'identification_number': 44219393,
    'item_consumption_1': 123.39,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 3588.9,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3c39b27c-ac70-4c63-8056-e0a48cc3d171',
    'identification_number': 51632381,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '811a1af7-1057-4cb2-86b1-aafa0dbbc1d7',
    'identification_number': 69855315,
    'item_consumption_1': 3895,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1520.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6cda070-dffb-4284-acc3-a1980b33db89',
    'identification_number': 92881548,
    'item_consumption_1': 1270,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 538.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d756117-febb-4a6c-894a-7dbe93972973',
    'identification_number': 51026279,
    'item_consumption_1': 30,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 1404.9,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e5035f11-9ba8-475f-bbb3-f4a181c9a76c',
    'identification_number': 8664668,
    'item_consumption_1': 1474,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 575.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c07b55ec-f1aa-4acc-8eba-01f1d17a1236',
    'identification_number': 48999784,
    'item_consumption_1': 2497,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1059.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26d6624b-2df7-4549-87b4-c3ce2d194134',
    'identification_number': 11380888,
    'item_consumption_1': 1641,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 695.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'caa60848-4186-45de-8a49-0c195d0f8968',
    'identification_number': 19451113,
    'item_consumption_1': 1918.08,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 55789.17,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6604c2ba-aff9-40e9-97f4-6fbaad42e2b7',
    'identification_number': 13296523,
    'item_consumption_1': 13053,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5103.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52e5e51a-ca87-41ce-a4b9-f663d98f6561',
    'identification_number': 18184006,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea21d8b9-d6f5-4bf8-9254-fa2b5d9d8c0a',
    'identification_number': 17355265,
    'item_consumption_1': 4458,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1890.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60d1c821-dbc3-4926-9d16-28dffdbb521c',
    'identification_number': 103285369,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '955f28fb-537b-4772-9adf-c3ff478a1e91',
    'identification_number': 6287727,
    'item_consumption_1': 11040,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4314.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0341296d-f558-46c8-8385-f1bf0237be04',
    'identification_number': 105798908,
    'item_consumption_1': 1.38,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 32.93,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7f546104-0128-4caf-b479-63a58ac5cb02',
    'identification_number': 68079257,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '274ea001-0358-40d0-93d7-f93d903dc693',
    'identification_number': 48511978,
    'item_consumption_1': 4474,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 571.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3339999f-fad0-4eee-a2a4-24e1a148cdf2',
    'identification_number': 91845947,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.71,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '745cb59f-4f27-4438-9677-9a06732387c5',
    'identification_number': 14671549,
    'item_consumption_1': 24.54,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 585.29,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2ab4319a-5441-40f4-bd00-7595e972032b',
    'identification_number': 97397652,
    'item_consumption_1': 642,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 271.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '748432d2-ef10-4b53-ae81-e960b29d08af',
    'identification_number': 79665306,
    'item_consumption_1': 7273,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 11002.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '765c7d8e-45c5-4b4a-ba2b-d475282c3d8f',
    'identification_number': 86207563,
    'item_consumption_1': 236.65,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6883.18,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1d712ead-c64e-4e59-a7c4-3265bd5d9949',
    'identification_number': 27516008,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '37d0fda9-f0e3-4070-9fac-da2aa4ce0792',
    'identification_number': 100909183,
    'item_consumption_1': 11201,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4760.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20f9b68b-b164-4203-a6ec-e6908e7ca8a4',
    'identification_number': 61588555,
    'item_consumption_1': 55,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 21.5,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2b97c527-7609-4d9c-9fa0-73536c6f2052',
    'identification_number': 77431650,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.04,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '23991998-5de9-4ac8-b6c1-1d3125b4845d',
    'identification_number': 104092076,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0a47f702-93ee-4603-86a3-d78ff72b7d0e',
    'identification_number': 15672239,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '81504a04-3bc8-4100-9a60-400c928ff114',
    'identification_number': 94436800,
    'item_consumption_1': 1101,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 429.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9c519eb-2721-42c6-a84f-12f8bfe8b0b9',
    'identification_number': 69441952,
    'item_consumption_1': 633,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 247.27,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c9d84cea-c499-4415-8e1d-35e0e315177c',
    'identification_number': 75192900,
    'item_consumption_1': 1353,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 790.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49301dee-eb56-4277-b310-5abc9d2c73d2',
    'identification_number': 14601737,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6831879a-e92d-414e-b5d8-68829c42d4c1',
    'identification_number': 48843075,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5ec29829-fd7a-4fd6-9590-3e79625aa38f',
    'identification_number': 94120676,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ca8c994f-057b-43fd-8947-47ec4712573d',
    'identification_number': 64620441,
    'item_consumption_1': 942,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 399.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4f16900-7de8-4b65-9da7-78eaad011e47',
    'identification_number': 52191362,
    'item_consumption_1': 567,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 240.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fa3fa80-41ad-4228-b01a-9a5605b0607b',
    'identification_number': 15543161,
    'item_consumption_1': 6952,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2709.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '265e8128-96a1-412d-a3cb-6101bf90b70f',
    'identification_number': 54453844,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22678e15-bd67-47b4-9352-ab318bd7fd46',
    'identification_number': 70678456,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bd027b3b-1827-4708-ad2d-07cbbd6ceefd',
    'identification_number': 18900267,
    'item_consumption_1': 1039,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 405.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7790a8f0-f675-4f0a-b86f-e43839035c4f',
    'identification_number': 43875939,
    'item_consumption_1': 3693,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 471.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b801ae31-db89-4fa1-9546-c138fcc35351',
    'identification_number': 90453450,
    'item_consumption_1': 2.63,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 76.47,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '17952577-a159-4f5b-80be-ecce602c1087',
    'identification_number': 32234449,
    'item_consumption_1': 5281,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3084.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b955a57-b062-4e8d-822c-8c474136f372',
    'identification_number': 31945252,
    'item_consumption_1': 79.5,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2312.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '899f4c3b-a655-4b3a-8d44-cda39e69e79b',
    'identification_number': 95969896,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8fe0cd3-3f27-4c02-b3cc-969de12bbca8',
    'identification_number': 40934756,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '12b98ee5-e1a1-4bbe-acab-4b811419d441',
    'identification_number': 94211272,
    'item_consumption_1': 333,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 141.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3674b58-dc67-471b-87ad-ec70fdfb8539',
    'identification_number': 41379136,
    'item_consumption_1': 5.97,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 142.39,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2af7ccd6-be45-4140-9cbc-2981e7dfad49',
    'identification_number': 98182501,
    'item_consumption_1': 551,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 321.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a278a046-c795-48c7-b3bc-a591f051f449',
    'identification_number': 96560339,
    'item_consumption_1': 1063,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 415.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2680d5f-9204-4616-b4c4-54de0267637f',
    'identification_number': 47816600,
    'item_consumption_1': 1649,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 700.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e60dde66-82ca-404c-9172-c568ce61707b',
    'identification_number': 10402136,
    'item_consumption_1': 4500,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1754.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '115dacf0-0120-4ee3-9033-a2a01302df8b',
    'identification_number': 96809361,
    'item_consumption_1': 3257,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1379.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9cd3822f-df60-479a-ab32-436f9b4556be',
    'identification_number': 105623733,
    'item_consumption_1': 645,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 273.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52c6ccea-f4ff-421a-a643-2fc49bc7c723',
    'identification_number': 70941149,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4ca92541-ac69-4b01-a30d-1bd74753bc54',
    'identification_number': 16125320,
    'item_consumption_1': 1978,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 3404.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bc18b62-abda-4bdf-b80b-9d2eb015ab19',
    'identification_number': 109861035,
    'item_consumption_1': 1317,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1992.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '748f4bb3-374f-4197-a545-b30a395dec15',
    'identification_number': 37708678,
    'item_consumption_1': 1480,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 626.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8faa4029-2a65-4ad9-879c-ec61360828d5',
    'identification_number': 12866,
    'item_consumption_1': 1359,
    'item_description_1': 'CONSUMO na ponta',
    'item_total_value_1': 3274.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa5c35e2-b6a4-44bd-85e5-7f139113b3a1',
    'identification_number': 21374792,
    'item_consumption_1': 94.161,
    'item_description_1': 'CONSUMO FORA PONTA TE',
    'item_total_value_1': 32888.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b2bc066-1551-4e5b-b17f-ec308179b597',
    'identification_number': 68376391,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '44c2cd42-4a93-4911-8e25-1ccac18b7b51',
    'identification_number': 53226429,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cc1f0105-fde5-4788-aa2a-5b394523ff7e',
    'identification_number': 40528111,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 74.96,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b6541063-d91c-4a94-a82b-7edd1b73ece6',
    'identification_number': 38055660,
    'item_consumption_1': 24248,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 36682.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdcfe70a-7969-4a47-bfb2-9c9b7c2f3426',
    'identification_number': 110411897,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31a17af2-d3df-444a-a263-a56c9bb2c7b1',
    'identification_number': 9928561,
    'item_consumption_1': 7844,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3064.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e5670a8-1fe6-4698-8bfc-f90e1ec86cda',
    'identification_number': 37763989,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf1c09c3-5807-4a11-88a5-723f408f96b1',
    'identification_number': 109643372,
    'item_consumption_1': 33.89,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 808.29,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7ddedb1e-60f8-4e9f-8f96-5659cb9b1dc2',
    'identification_number': 109487877,
    'item_consumption_1': 217,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 84.89,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '07776c70-4c13-411d-ab31-0d3bd31de967',
    'identification_number': 77776500,
    'item_consumption_1': 33.06,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 961.57,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ef435604-1ace-4db9-8b3d-dc9f97e4576c',
    'identification_number': 18616909,
    'item_consumption_1': 12.19,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 290.75,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '68572ad4-cce0-42c8-af1c-75361e5455ea',
    'identification_number': 58755748,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23b189ee-a353-4e03-a426-11faedafda26',
    'identification_number': 96849860,
    'item_consumption_1': 1186,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 503.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd288125f-48c9-45cf-b2e0-8a103a12e41f',
    'identification_number': 18339654,
    'item_consumption_1': 12098,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4502.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6644d813-87f0-4a91-adea-32ba45d19a1c',
    'identification_number': 34098380,
    'item_consumption_1': 588,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 229.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7faf6f7d-0c80-42e9-8e30-3a2a45d65afa',
    'identification_number': 63137399,
    'item_consumption_1': 14.07,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 409.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '06399c16-fce8-43fe-963a-d8a8327af57f',
    'identification_number': 16026314,
    'item_consumption_1': 5123,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2171.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '177ef1f6-2d4b-4762-942c-3fa2d4409bc9',
    'identification_number': 101937792,
    'item_consumption_1': 4.05,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 117.79,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'e935103f-a222-4aa7-8aaf-55161d3a4481',
    'identification_number': 29700485,
    'item_consumption_1': 7.86,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 368.1,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '48fbac86-93d0-4daf-9086-6d0fe26504cf',
    'identification_number': 68924186,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '806a5067-8613-4dd8-948a-a7717792eadf',
    'identification_number': 100240682,
    'item_consumption_1': 963,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 408.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7537b5b8-9b54-4a99-96f0-f73c65050f78',
    'identification_number': 39214800,
    'item_consumption_1': 4.72,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 112.58,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '775e75d2-c6d0-4d0c-95e1-5ac12b654c7f',
    'identification_number': 102025630,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af22a75d-87b8-4afb-9d54-15261cb4a9d7',
    'identification_number': 55826539,
    'item_consumption_1': 420,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 178.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc098077-c360-42e5-8111-3409c17990f9',
    'identification_number': 95106839,
    'item_consumption_1': 6443,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2518.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd51bb0df-8d90-44b4-a246-c0e644cab1f3',
    'identification_number': 104265000,
    'item_consumption_1': 749,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 292.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6f912d8-6100-4ff7-af84-3e5e034ffa5c',
    'identification_number': 16476727,
    'item_consumption_1': 1681,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 655.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f96a517a-99c3-4b9b-98c0-84e614366624',
    'identification_number': 109139402,
    'item_consumption_1': 254,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 99.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f6b1e362-e3b9-4724-a8c3-75070cf21c4d',
    'identification_number': 105653691,
    'item_consumption_1': 44.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1294.89,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '392762e0-f189-4dda-94a6-b54bb64988c6',
    'identification_number': 44478046,
    'item_consumption_1': 23.2,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1349.58,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '426c9f7c-aa91-48e7-86f9-f2cd7d5a87b5',
    'identification_number': 94724741,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9866b1cb-a91d-4c6e-8b3c-dff434a712f9',
    'identification_number': 41213947,
    'item_consumption_1': 6130,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3580.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54f63767-0a0b-424b-baca-06d9632abec5',
    'identification_number': 15871282,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ed7720d5-4788-46be-afff-e1d70dd498f6',
    'identification_number': 3030777625,
    'item_consumption_1': 4884,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 2466.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b60d55c-1c7c-4f3b-8fca-e6173ee98ead',
    'identification_number': 48339610,
    'item_consumption_1': 52367,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 19488.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f75c5f7-db78-4d97-b0dd-26b3c66ac24d',
    'identification_number': 47989050,
    'item_consumption_1': 3271,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 417.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '897c8db8-3a7b-4383-94f0-6cda6706837a',
    'identification_number': 64871274,
    'item_consumption_1': 6607,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2579.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5444e0b2-4a23-4177-94fd-dc297e5cc028',
    'identification_number': 100387993,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fd3203f5-405f-4a40-ab56-4f658875c8c5',
    'identification_number': 98624792,
    'item_consumption_1': 4132,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 527.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f07e192-c9aa-4bc1-983b-fb05796c2ffb',
    'identification_number': 34210229,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22253c89-5b35-4505-9a49-b67760cbed27',
    'identification_number': 12381519,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '761b8477-a345-4074-961a-b4d65fd996eb',
    'identification_number': 32234449,
    'item_consumption_1': 42799,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5462.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59ec46a4-d107-4d91-8074-83dddd7f1540',
    'identification_number': 95923047,
    'item_consumption_1': 265,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 112.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d6df35c-89a4-4798-8a0e-6653d8b72a84',
    'identification_number': 8115800,
    'item_consumption_1': 752,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 318.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b776a8af-f822-461e-b029-49619e5a7a2e',
    'identification_number': 48449520,
    'item_consumption_1': 411,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 174.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1b12eab-9886-4caa-9608-d20462ac3b40',
    'identification_number': 14225093,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e63878a6-88ac-4bfc-8982-3b53d8482691',
    'identification_number': 33558540,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4d1f3d5d-5cf8-4522-bf82-a85702e106df',
    'identification_number': 17130972,
    'item_consumption_1': 2446,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1037.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be97f5f4-e600-436f-95c0-463cef111dbb',
    'identification_number': 107678977,
    'item_consumption_1': 324,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 126.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d61ba38-0201-49b2-8913-605fc402d6a0',
    'identification_number': 109939050,
    'item_consumption_1': 15469,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6559.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2c923fe-e5a8-49c6-a536-e39019aab277',
    'identification_number': 3031341090,
    'item_consumption_1': 205,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 103.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4ed644c-15c6-4c22-bacc-7d12a9a1aae2',
    'identification_number': 17578566,
    'item_consumption_1': 6160,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2404.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd831c6b4-dc65-4ee1-aaeb-504b749950d1',
    'identification_number': 14909413,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 101.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3a745819-1858-40b8-b18f-f78ed1728fe7',
    'identification_number': 108178781,
    'item_consumption_1': 1518,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 886.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a87c314-5628-49ee-b104-06a84b0ef346',
    'identification_number': 93288255,
    'item_consumption_1': 12709,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5403.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fba81557-2d1f-4673-a1ce-5192f71b5801',
    'identification_number': 103592458,
    'item_consumption_1': 1739,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 739.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '064823f5-639a-49de-9448-12618b8aa67f',
    'identification_number': 95077677,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0d4feb3d-0c00-4f2a-94c3-bfc1e59c37d8',
    'identification_number': 53573749,
    'item_consumption_1': 66,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 25.82,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2b04e190-c263-4e75-8d6b-66c935424e42',
    'identification_number': 43612067,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b418e7b4-e3b7-40b5-98a1-4b0268895583',
    'identification_number': 102217750,
    'item_consumption_1': 619,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 262.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '774944f8-c2bc-455b-800a-5ee7cb973d3a',
    'identification_number': 106104438,
    'item_consumption_1': 8341,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1064.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69a2a199-f24e-4ef1-9899-1b4789215004',
    'identification_number': 44628200,
    'item_consumption_1': 6642,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2820.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44aeb024-1015-405e-9c40-219eb258e1d7',
    'identification_number': 95563610,
    'item_consumption_1': 456,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 266.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0703c317-63dd-411f-8df4-fdc8999cbaec',
    'identification_number': 95012818,
    'item_consumption_1': 1782,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 756.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a768bc8-9410-4ee5-8b25-c4c7dfcafb2a',
    'identification_number': 96072091,
    'item_consumption_1': 11457,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4475.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9380a279-3b82-494c-944d-45fdc6e8adba',
    'identification_number': 70926115,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b9dcb479-8db3-46c4-bf73-d009df6d3f51',
    'identification_number': 64167364,
    'item_consumption_1': 3719,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5626.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bb381bb-3532-4361-a7f3-cbb4846d713c',
    'identification_number': 60131829,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '064c304f-30d6-41ad-a533-39e81c08d461',
    'identification_number': 97485128,
    'item_consumption_1': 7434,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3150.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb295ef2-a5c4-4ee2-a98a-095e64ca3b05',
    'identification_number': 102560935,
    'item_consumption_1': 30.55,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 888.57,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3c565196-0f86-4671-a8bb-02ff90e5f285',
    'identification_number': 102654549,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F1 (02)',
    'item_total_value_1': "'-582.37",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '243a4395-afeb-4497-93cb-45036f17f66e',
    'identification_number': 95633359,
    'item_consumption_1': 7449,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2772.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb25ab80-dd12-4b5c-a720-d1cb42d1526a',
    'identification_number': 935304310,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b1388993-80a5-453e-8910-a23caece787c',
    'identification_number': 89771249,
    'item_consumption_1': 1666,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 705.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1bbb5c1-4244-4a69-aa56-485d727004a8',
    'identification_number': 18643582,
    'item_consumption_1': 6.44,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 153.61,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '16392eab-6cb1-449b-a24c-79d006cc6a4e',
    'identification_number': 58738428,
    'item_consumption_1': 920,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1391.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fee583af-4cc4-40ad-a8a5-7f2f86fcaf42',
    'identification_number': 17714796,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 71.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f3eef12-d29c-4435-ae1f-cf883d2a7df2',
    'identification_number': 94814805,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf3d97bf-ca36-4544-84e4-09403940699a',
    'identification_number': 107018195,
    'item_consumption_1': 624,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 243.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47d313af-610a-46ba-9a98-60466ba67deb',
    'identification_number': 12222615,
    'item_consumption_1': 147.6,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4293.07,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '21c27161-1e59-4bd2-b4ad-f40e5ccecffd',
    'identification_number': 23274310,
    'item_consumption_1': 642,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 374.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3aaa38f3-16cf-4f2a-96e2-58d41bdd5d47',
    'identification_number': 109909704,
    'item_consumption_1': 2733,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1068.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2942b5c5-cb7c-4729-8894-5fea5875a071',
    'identification_number': 111114497,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '018bd6ff-332c-4389-9494-e41864ab96b2',
    'identification_number': 32403011,
    'item_consumption_1': 26811,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9977.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a82b9e3-6408-4bb3-b4e2-91dd25761663',
    'identification_number': 41360818,
    'item_consumption_1': 27524,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 10767.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bda48de-41d5-41e9-b8e6-4f2d207fbb26',
    'identification_number': 95892567,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3d4cd8a-6225-4308-9da7-07f46b4fff56',
    'identification_number': 18519555,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1ae643c1-c0b8-4751-9d2d-95d199a0aa66',
    'identification_number': 12673650,
    'item_consumption_1': 41594,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5308.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83670371-3752-4267-974e-a6e341ff68c1',
    'identification_number': 19310994,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c03f6106-b9c0-4072-bb9e-917f5989224f',
    'identification_number': 68457782,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eab82fc4-c3bb-4c49-8d7e-54acac9e40c5',
    'identification_number': 46424792,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '37a8c6ba-c826-4cf4-8deb-1cb6f4a901ec',
    'identification_number': 56582854,
    'item_consumption_1': 51.51,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1498.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8e263506-9968-47ad-a719-f4e243a8ee54',
    'identification_number': 40528111,
    'item_consumption_1': 666,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 282.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4423d71e-4131-4f57-af19-9755f990971d',
    'identification_number': 12027740,
    'item_consumption_1': 1530,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 650.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ff87335-5848-492a-a2d5-ddaa395085bd',
    'identification_number': 16876709,
    'item_consumption_1': 13932,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5184.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '382e4c1a-c442-46af-83de-1957e5bc519d',
    'identification_number': 106755030,
    'item_consumption_1': 32003,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4084.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd69064c6-2e86-4daa-ac50-46a563e27e20',
    'identification_number': 16247590,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 139.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '65ce192e-b5c1-41bc-bbf0-eb1128d0277f',
    'identification_number': 18339654,
    'item_consumption_1': 7.09,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 169.11,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '33cac95b-1bcc-4da8-9cba-8fa814ac685f',
    'identification_number': 29677459,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0630ff4b-2c47-4b06-ba50-8cb3211921bb',
    'identification_number': 27143805,
    'item_consumption_1': 4122,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1611.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3192aca5-f527-4c9b-92cc-e915426f95c3',
    'identification_number': 56874081,
    'item_consumption_1': 39.7,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1154.71,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'aa6e58f0-2af2-4e00-9bca-3c0f42812a7f',
    'identification_number': 100214193,
    'item_consumption_1': 6.39,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 152.42,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9fb06ffe-c36c-44fa-a256-cabfdb852133',
    'identification_number': 32605579,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '73f4eaaa-2ed6-491c-a737-1a1183be9f22',
    'identification_number': 29873584,
    'item_consumption_1': 3671,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1436.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e75421e-6960-46b7-a700-aa65c4747e22',
    'identification_number': 99353598,
    'item_consumption_1': 18316,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2337.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42e16b16-665f-4743-ad87-4cc93d5b9334',
    'identification_number': 94525994,
    'item_consumption_1': 1576,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 920.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8907b427-0bdc-4a7b-950f-014a0915ad56',
    'identification_number': 111115469,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '032f40a8-3781-49b6-9327-2055c4921c0b',
    'identification_number': 80982255,
    'item_consumption_1': 352,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 137.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2c120be-8b29-4219-b0b9-482a6b1347f8',
    'identification_number': 102690987,
    'item_consumption_1': 121,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5a87557-4356-4ff7-91a8-21df4adc2ef2',
    'identification_number': 16247590,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11911dcf-49d9-4413-b6ad-ab9b6e356be3',
    'identification_number': 47573481,
    'item_consumption_1': 2.5,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 59.64,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '21a4e08f-a09c-4918-aba7-205cfe20a44b',
    'identification_number': 101340958,
    'item_consumption_1': 566,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 220.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9045d697-2dcf-4d35-9f77-09a4d296aeb0',
    'identification_number': 110019377,
    'item_consumption_1': 7.48,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 178.42,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '72daa1e6-8667-4654-a70b-be2e8da6a712',
    'identification_number': 106957317,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1005.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd11a9090-f81d-49ac-9dec-2dd0f20d03db',
    'identification_number': 93691076,
    'item_consumption_1': 2493,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 974.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8186f22-dfe4-4bb0-ac40-fa1a4582ffae',
    'identification_number': 14232979,
    'item_consumption_1': 7379,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2746.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4eb83661-9d66-4627-a2e5-d96983333f19',
    'identification_number': 107740516,
    'item_consumption_1': 6,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cf6e2dae-b70b-4e72-a22d-909f651541b2',
    'identification_number': 100137890,
    'item_consumption_1': 311,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 121.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a794a5e-255d-4ded-b56c-510c4dba1084',
    'identification_number': 74464264,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f6c4056e-9af3-49a5-ab4a-263085ff81fe',
    'identification_number': 27125610,
    'item_consumption_1': 16178,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6867.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f98d525f-b38d-4da5-87d7-0098157b5e8d',
    'identification_number': 104920009,
    'item_consumption_1': 603,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 255.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '615dc17c-2c7f-42e9-bd00-461fea66d01b',
    'identification_number': 18787487,
    'item_consumption_1': 2268,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 886.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd167c183-aa14-4ce0-b2b1-195b84f43b55',
    'identification_number': 40100642,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1538d45-8d8d-4750-88dc-ee9ab8af47d0',
    'identification_number': 25919032,
    'item_consumption_1': 10498,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4103.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6d55513-f132-495b-912c-98c5ed36d0ac',
    'identification_number': 63721651,
    'item_consumption_1': 1002,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 424.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '573ca1a0-c215-4e54-b326-1b345657ce2c',
    'identification_number': 101937792,
    'item_consumption_1': 15230,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1943.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb34317d-6141-47cc-8880-a97715c2a1aa',
    'identification_number': 19310609,
    'item_consumption_1': 619,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 262.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4c263f5-d5c8-4b66-bc24-ccd47466ecba',
    'identification_number': 14354306,
    'item_consumption_1': 8154,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1040.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '496c7631-48a4-43e9-bcec-b1592028eef0',
    'identification_number': 96809361,
    'item_consumption_1': 3257,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1269.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81988812-9d4c-457e-852c-5820efd8b17e',
    'identification_number': 17159679,
    'item_consumption_1': 398,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 232.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ce51bee-b8f2-4a47-8a29-f1a3fa897b5b',
    'identification_number': 45980586,
    'item_consumption_1': 1731,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 675.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab65a900-27c7-43c7-a582-73617a1c62cd',
    'identification_number': 94419990,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c920db2c-3a2c-4285-bdc5-93adaa1066f0',
    'identification_number': 66548128,
    'item_consumption_1': 11726,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4568.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb379b35-c849-41e1-a042-b8346e890f90',
    'identification_number': 26988879,
    'item_consumption_1': 2368,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1002.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad5fd866-33ac-4414-8556-16599a494fa1',
    'identification_number': 99570181,
    'item_consumption_1': 191,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 74.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fccd9000-654d-433b-9628-fb6a9dc8e5ae',
    'identification_number': 56697198,
    'item_consumption_1': 610,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 922.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3da8d17-0a2f-4cd8-8031-fcf70d465b7c',
    'identification_number': 11685913,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-123.92",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '55cf96ac-695c-4344-8ee4-a99102c8f8bc',
    'identification_number': 70941378,
    'item_consumption_1': 13.94,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 405.45,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '06408290-64b8-4095-8f05-9f6de866d255',
    'identification_number': 72507136,
    'item_consumption_1': 1797,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2718.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2643d86a-7969-4aa1-a1c8-b0bf57f1995e',
    'identification_number': 45446212,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 40.53,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'b10dbc2e-9529-4547-9e0c-0acd543e5896',
    'identification_number': 17026695,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a7af76b-ef96-418e-93b4-ff2e62c381be',
    'identification_number': 70128235,
    'item_consumption_1': 14.83,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 431.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b38ec7cf-cfa1-43e9-bfd4-43c85c505926',
    'identification_number': 90881656,
    'item_consumption_1': 1495,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 634.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09eb4c33-317e-42f7-8025-3d6e88dc6220',
    'identification_number': 16102169,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6363d185-98e0-4df4-83fa-1a9f4e3bee8c',
    'identification_number': 14039362,
    'item_consumption_1': 21444,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7980.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b807900-93f5-4530-88e5-83934696ed66',
    'identification_number': 936159663,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ae19052b-b18b-49dd-af4c-30f59d1a1de9',
    'identification_number': 71391274,
    'item_consumption_1': 6744,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2867.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '829a250a-5eff-4c5f-9b8f-a28a279ed11d',
    'identification_number': 93490089,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2179b037-20e3-450a-b1c9-316637153204',
    'identification_number': 41360869,
    'item_consumption_1': "'-2.16",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-692.9",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '94f94273-033c-44a6-904a-9220523a9b11',
    'identification_number': 62088777,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6dab1a88-3f92-49b4-ad58-e0ff6ee1a12e',
    'identification_number': 8115699,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9570872c-4af4-48c1-ae0e-c7af95a3fd4e',
    'identification_number': 108668630,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f3b4a8ef-9d26-4397-9c8d-31d2ac43a36d',
    'identification_number': 78478782,
    'item_consumption_1': 4955,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1935.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88585e02-d3a3-4130-9c29-ca212bf70530',
    'identification_number': 38432005,
    'item_consumption_1': 25786,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 10914.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4cf2d591-eb1e-4cf5-aa3d-4db204b83bf5',
    'identification_number': 12384933,
    'item_consumption_1': 272,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 411.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7cc3d98e-1fef-493e-bedc-db264dafec99',
    'identification_number': 26275287,
    'item_consumption_1': 30275,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11266.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7efa7bc5-2d72-4a6b-ae11-a06b54b51a98',
    'identification_number': 108863026,
    'item_consumption_1': 0.54,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 15.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '75f6796a-da5f-404c-9dec-2f252e195ccd',
    'identification_number': 106613979,
    'item_consumption_1': 2043,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1193.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b579e6ef-a8d2-4024-b65c-af268aa15737',
    'identification_number': 93760175,
    'item_consumption_1': 1946,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 758.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77d80484-6e0f-4a0e-9295-2ad68012dd7d',
    'identification_number': 92562302,
    'item_consumption_1': 64.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1549.34,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fe137f2f-7a3e-4c56-a196-555bd1187ab8',
    'identification_number': 92483119,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f10e1554-3600-44e4-8288-00dd2efa1a28',
    'identification_number': 96560339,
    'item_consumption_1': 1063,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 451.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '584f4937-057e-4b67-996c-a6dee4185f6b',
    'identification_number': 12446645,
    'item_consumption_1': 121.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3534.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '261fa621-bef1-40e6-8917-f8bfec928d42',
    'identification_number': 55193994,
    'item_consumption_1': 2647,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1545.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b325f1d5-5c57-4101-b76b-f27d93697016',
    'identification_number': 21424640,
    'item_consumption_1': 9595,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3739.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ee6b03f-0233-4cbf-bae4-0ad5942656e7',
    'identification_number': 39672131,
    'item_consumption_1': 0.98,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 28.5,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '9d1bd956-9f87-4239-8ed8-164ed542f278',
    'identification_number': 28858913,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 126.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fe08c709-176c-4155-9360-1dffe72b56d7',
    'identification_number': 18188109,
    'item_consumption_1': 1866,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 729.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebe7f9c3-0f02-4ed5-9cc8-a714028710b0',
    'identification_number': 66547385,
    'item_consumption_1': 3167,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1233.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1928354-7d75-4b77-a108-3ba3752711cd',
    'identification_number': 42469805,
    'item_consumption_1': 226,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 88.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '753d8ae8-00db-4650-b3d4-c4388bf7541b',
    'identification_number': 91359511,
    'item_consumption_1': 1007,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1523.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7bd3f102-6ca8-4182-a58b-4cd9fd1a23d0',
    'identification_number': 40828000,
    'item_consumption_1': 12408,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5251.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2610ff4-d766-48dc-8481-5f1d233bd092',
    'identification_number': 92135811,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e9ff3c41-8425-4058-8d31-db39adcbf432',
    'identification_number': 80788661,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a13d3dbc-e8f7-4a99-816d-85f2a2c42986',
    'identification_number': 104766190,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ab6aa01b-b1ba-4baf-9a16-795f5fd89834',
    'identification_number': 62852485,
    'item_consumption_1': 1590,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 620.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bcd20703-025b-4fc0-9a70-4c1d2bd5e594',
    'identification_number': 17229162,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 93.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f148c13d-1ef1-4ad7-9780-e52bdfcddb37',
    'identification_number': 12275468,
    'item_consumption_1': 24384,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3112.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '997a144f-a9ac-40ba-99b5-a3e201031ba6',
    'identification_number': 11342870,
    'item_consumption_1': 1451,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 614.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d92f4a8-3b01-4920-985e-36be6499c444',
    'identification_number': 39208869,
    'item_consumption_1': 72.88,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1738.23,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e7d5a3b6-332c-46a6-bd32-57994eae2f7e',
    'identification_number': 109419944,
    'item_consumption_1': 432,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 168.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f80d18e8-e134-42b2-8956-068d51ca5b80',
    'identification_number': 32843615,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1a2e3f75-8486-40fc-823d-641b1f3b4ec7',
    'identification_number': 58724591,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f5b1d8b0-fc3d-4028-a7f9-fcb54cdcaef2',
    'identification_number': 4334221,
    'item_consumption_1': 20.28,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 483.69,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd0802a2e-3cdd-4d3b-a261-9af7c22732e2',
    'identification_number': 74880462,
    'item_consumption_1': 2268,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3431.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6776bf49-83ac-490f-90a8-b724f4f798ef',
    'identification_number': 41499255,
    'item_consumption_1': 27704,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3536.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac297c0a-eceb-4f35-af63-d8de225a7244',
    'identification_number': 98913972,
    'item_consumption_1': 14852,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1895.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a73ffb2-ea34-4c5f-babc-d7b88915e9e0',
    'identification_number': 18987133,
    'item_consumption_1': 549,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 233.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76d6e706-ff72-4983-ad19-4368ca28d5e2',
    'identification_number': 105957569,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 207.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '791d7703-537a-42e9-91b9-166270ef0e8d',
    'identification_number': 33557004,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 31.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eae6327a-b72e-43d4-9048-f7a27182e25e',
    'identification_number': 106227823,
    'item_consumption_1': 802,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 313.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98a8fbc7-628a-4812-b37e-bc2c2a63c747',
    'identification_number': 59440287,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6dc1bd20-8b40-4928-840a-da66cebe5b9b',
    'identification_number': 103466118,
    'item_consumption_1': 3232,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1374.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83fc467e-ebc2-49be-bd6f-022e6758f930',
    'identification_number': 35902051,
    'item_consumption_1': 366,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 155,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea3c891a-6f5a-41a8-aaac-c7370a579aae',
    'identification_number': 28748735,
    'item_consumption_1': 783,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 332.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ef17c8e-aaf6-4176-8be0-07f705bf2ab1',
    'identification_number': 73695360,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '36c40725-497e-4a1c-a372-da14b913ca37',
    'identification_number': 29966060,
    'item_consumption_1': 14.06,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 335.36,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0fedccbc-d420-4611-8ba4-a77986b7b514',
    'identification_number': 96692979,
    'item_consumption_1': 86.59,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2518.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5f9e9a21-e264-4095-a2a4-622c91b84fdd',
    'identification_number': 12174726,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.23,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a161e78b-37f5-44e9-a951-a1c26f1e25ef',
    'identification_number': 32495056,
    'item_consumption_1': 113.7,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3307.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c41855a1-f4bc-4ba4-a71a-43d61315421a',
    'identification_number': 21374792,
    'item_consumption_1': 94.161,
    'item_description_1': 'CONSUMO FORA PONTA TUSD',
    'item_total_value_1': 11879.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16437cf5-5e86-4df7-ab09-af3132f04d2b',
    'identification_number': 6589278,
    'item_consumption_1': 2033,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 794.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13731e7b-0ab1-40a9-95ce-7aa82eeb9001',
    'identification_number': 16072022,
    'item_consumption_1': 268,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 203.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01b912f6-790e-4f30-ae37-fb00324662e4',
    'identification_number': 47766336,
    'item_consumption_1': 1541,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 600.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0679fdf8-22b6-4d21-a796-599d475607f1',
    'identification_number': 81777302,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2212.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbf2cd48-b3ff-491b-93cb-73669da0bdfc',
    'identification_number': 48043907,
    'item_consumption_1': 9094,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3384.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '439d02aa-d0d7-4c78-bf8b-4b59a5504abe',
    'identification_number': 18247733,
    'item_consumption_1': 247,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 96.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39588106-d866-48f9-bf3e-ca960b6a9a84',
    'identification_number': 90439325,
    'item_consumption_1': 36931,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4713.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f860a675-fdb3-4945-8683-83d0e0c3e857',
    'identification_number': 111295890,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 41.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '346bf545-3441-4565-a5e0-342f95a86c7b',
    'identification_number': 31564496,
    'item_consumption_1': 13713,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1750.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0277e8ec-4efc-4081-a2a6-5eb4e924edf2',
    'identification_number': 14474301,
    'item_consumption_1': 5547,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2357.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'daa17d1a-8336-4f8c-b81b-2882cfe6f0c2',
    'identification_number': 21718970,
    'item_consumption_1': 52.98,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1540.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c518044e-7d5f-4ec5-947b-ef10c8463016',
    'identification_number': 105089060,
    'item_consumption_1': 1087,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1644.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8d1c7d0-d319-4883-995b-a7012f99ef0a',
    'identification_number': 35614595,
    'item_consumption_1': 352,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 532.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bd27037-8944-484d-879d-be36017793c8',
    'identification_number': 65191404,
    'item_consumption_1': 10091,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1287.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '669a2354-485b-490d-961f-ca64b5877abb',
    'identification_number': 95284001,
    'item_consumption_1': 1457,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 619.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84e72cd3-35a3-4d0a-a60d-b6d0e858f8c2',
    'identification_number': 70447330,
    'item_consumption_1': 447,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 676.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a32ffc22-4811-4ae4-be91-b1b6e2d8982e',
    'identification_number': 81267843,
    'item_consumption_1': 579,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 338.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dffbac11-ad46-4e1f-9033-e9198436e5e2',
    'identification_number': 13877984,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '786dab0d-f371-4215-b021-11fb70f2ef1c',
    'identification_number': 948747554,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '092e8cf6-5ddc-4f5e-9920-d9d0dd5d9f82',
    'identification_number': 107674149,
    'item_consumption_1': 5428,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2123.83,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b04d1269-57c2-43ac-91d0-133adf804090',
    'identification_number': 57109346,
    'item_consumption_1': 277,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 35.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0843640b-6a0d-48a9-a1b8-531bc43d3cfd',
    'identification_number': 56705514,
    'item_consumption_1': 1244,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 726.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a7983e9-020e-4b88-9c08-865c7690a974',
    'identification_number': 19514247,
    'item_consumption_1': 3973,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1688.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f31b9ee-4b4d-4636-8b5e-6f80d27cc968',
    'identification_number': 34617426,
    'item_consumption_1': 6,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 2.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '67c0a757-b4e5-4753-b471-91f0c8ec9948',
    'identification_number': 101421087,
    'item_consumption_1': 911,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 385.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ad5f91c-18d8-4593-860d-bacfaad139d8',
    'identification_number': 16105141,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd73c5308-7a3d-4954-af7b-93339a1d5e84',
    'identification_number': 32500459,
    'item_consumption_1': 25.28,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1443.71,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '98d58270-7dea-4649-8a73-75632f546340',
    'identification_number': 22317996,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 93.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '08791284-9f38-4139-9d32-7845f198f6b0',
    'identification_number': 108989615,
    'item_consumption_1': 162,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 68.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'daa3a747-38d5-4b3c-8bfd-3436f8400582',
    'identification_number': 57673160,
    'item_consumption_1': 2193,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1280.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74b031b4-0725-47ef-a46e-73f248b02252',
    'identification_number': 106805037,
    'item_consumption_1': 185,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 72.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3c2d62b-c4e7-48c9-843c-9aade1a3d55d',
    'identification_number': 109207882,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd0dee29-577c-4251-b8cd-b9951d9f1ce7',
    'identification_number': 12187569,
    'item_consumption_1': 280,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 109.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27c59552-e20e-477e-9625-eea3cadc2340',
    'identification_number': 35088974,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 4.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '78cee2cf-4229-44f9-b534-c535ab244d5a',
    'identification_number': 106291238,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '089f05bc-3b7f-4182-8ecb-d36758bf0541',
    'identification_number': 92562302,
    'item_consumption_1': 609,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 355.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de8f7df4-ca47-4e44-88bd-d20b51613d26',
    'identification_number': 6219012,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3f031975-04ba-4c17-8fd9-67ef5f527e07',
    'identification_number': 93950993,
    'item_consumption_1': 7023,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4101.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c58bad04-d747-44b0-a73b-eeb34ba78818',
    'identification_number': 22067248,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a9025c21-a422-4ae1-9527-1a6a79eb979e',
    'identification_number': 12797707,
    'item_consumption_1': 953,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 556.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '064772d0-4df8-4775-aac2-cf61c2cff2ad',
    'identification_number': 923318215,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2c84024a-dabd-4669-8ebb-4621ded53920',
    'identification_number': 6210309,
    'item_consumption_1': 7953,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3103.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '021ff8e5-57c4-4524-93b1-45e83c94ef3b',
    'identification_number': 101163231,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17db9717-e50b-4ea2-b529-53f6e8816142',
    'identification_number': 94079692,
    'item_consumption_1': 10260,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3998.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67c5c8b4-c32e-484c-9785-ee636ad7cd9c',
    'identification_number': 78084237,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 413.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9f2fe590-5d9d-4567-85c3-bd0bce35a488',
    'identification_number': 22866639,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0a53384d-e540-463a-9df2-b7008f8ab231',
    'identification_number': 77204816,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66240abc-77a4-4d7d-96c7-451adb260326',
    'identification_number': 38781654,
    'item_consumption_1': 370,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 144.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f4440da-14e8-4afb-8c21-4244f5518673',
    'identification_number': 105797634,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '097abedd-21c3-49ce-a4cd-cd09a27578e0',
    'identification_number': 14905612,
    'item_consumption_1': 13612,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1737.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7f50f8f-37c8-4515-ba49-f7cd75bf53fb',
    'identification_number': 12804428,
    'item_consumption_1': 888,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 346.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '589d9e28-3bc6-4614-a02f-a34a648b8680',
    'identification_number': 59572310,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea3cff34-e315-4c8c-b0fa-4c023b09192f',
    'identification_number': 32843615,
    'item_consumption_1': 4856,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2063.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37f66ee4-d17d-4e6b-8d07-d3f769c63570',
    'identification_number': 19306580,
    'item_consumption_1': 189,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 80.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f35c642-7d5b-4ed9-9782-a8ddafca7d47',
    'identification_number': 31645313,
    'item_consumption_1': 20354,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7574.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30fbfd8c-974c-4e9b-b336-35731070afe7',
    'identification_number': 87252031,
    'item_consumption_1': 1925,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 817.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f45627b2-8d69-4cc4-a646-665040bdd32e',
    'identification_number': 109350022,
    'item_consumption_1': 1377,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 584.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3370ee8-dd58-42c9-baf2-44f60e29b3f6',
    'identification_number': 6092578,
    'item_consumption_1': "'-10.368",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-3604.47",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'b72dd456-0b4b-4587-9feb-7a05c9040842',
    'identification_number': 29984971,
    'item_consumption_1': 7,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 2.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd1eb6fe0-2b33-444c-b2d0-e974281ab6d8',
    'identification_number': 22162330,
    'item_consumption_1': 18,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 7.03,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ae303760-364c-4ece-bf08-f7f389a9aee5',
    'identification_number': 72742917,
    'item_consumption_1': 11037,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4309.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3040f680-0c97-46cc-83c8-89f3bc5b84bf',
    'identification_number': 72702141,
    'item_consumption_1': 1176360,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 437779.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a1046b5-debc-4bb8-9d97-9568d6fb3ba0',
    'identification_number': 110994906,
    'item_consumption_1': 2311,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 901.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5ae074f-cbb0-4403-beac-c4e19523ddc2',
    'identification_number': 28053605,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.66,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b22bc38e-9074-4aee-80c8-ca8dda59cccd',
    'identification_number': 104480050,
    'item_consumption_1': 31.68,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 921.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7d3ca8b8-ff32-4b3e-b792-70a2b61a7289',
    'identification_number': 21818983,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e4a35b9c-f537-49c3-93cf-64b02c63ca06',
    'identification_number': 92672949,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9551450a-9cda-42be-b887-d2d285290f8b',
    'identification_number': 14232979,
    'item_consumption_1': 7379,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 941.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d66a456-02c7-44c1-ae52-ff411b3d6ab4',
    'identification_number': 83699864,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '78a3de35-b5d2-4f3e-a106-b6ac34a2b637',
    'identification_number': 56526849,
    'item_consumption_1': 18.1,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 526.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6cbf1751-5e81-4223-a5fc-ecebcffa726a',
    'identification_number': 105953148,
    'item_consumption_1': 8255,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3498.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ab60845-f33e-4145-bdab-4d17d906b9cf',
    'identification_number': 92674488,
    'item_consumption_1': 2368,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 925.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f07e5725-a71d-4738-9b32-d48746c1f60d',
    'identification_number': 13789414,
    'item_consumption_1': 15.3,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 445,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cb8eceb7-6c84-4577-b633-d402f5e188ad',
    'identification_number': 90439325,
    'item_consumption_1': 63.36,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3618.48,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '41c9129f-d0b6-41d9-bfdd-3619fe84278b',
    'identification_number': 56812477,
    'item_consumption_1': 11.97,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 285.49,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3818a764-894b-4478-9b39-7391295cbc08',
    'identification_number': 19451512,
    'item_consumption_1': 395,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 154.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f4ca3f01-d6e3-4bde-9827-a00a90271c6d',
    'identification_number': 91360250,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1534e9b9-1657-42db-accc-ad1f00c15d23',
    'identification_number': 94984778,
    'item_consumption_1': 4315,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1832.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '737fa4fa-fedc-48bc-8b7f-aa204e7217aa',
    'identification_number': 85258105,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a3e4cc59-6492-49eb-ad59-7f379d6ad1cd',
    'identification_number': 74880462,
    'item_consumption_1': 0.88,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 25.58,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '51940ca2-33f1-42c6-b877-ec58af1dbcf8',
    'identification_number': 81860684,
    'item_consumption_1': 4966,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1941.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a5377e4-950d-4914-9689-bd4e90763c1f',
    'identification_number': 56881444,
    'item_consumption_1': 9613,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3577.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57bd4543-95ad-4e7d-9d6d-f301f3fcf575',
    'identification_number': 105077488,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1dde3cf8-bbb5-4267-bbae-08581fc510e5',
    'identification_number': 48293750,
    'item_consumption_1': 7305,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2845.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79cf459f-7a4f-440c-aa86-1a4b92b90f51',
    'identification_number': 83979069,
    'item_consumption_1': 243,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 102.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4cf66645-65f8-4a0e-b8c5-2389e39686ce',
    'identification_number': 106814427,
    'item_consumption_1': 525,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 306.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4250b645-f709-4761-b7f1-5c70a7037853',
    'identification_number': 18561713,
    'item_consumption_1': 9710,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4117.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8297f84b-87fc-4bf3-b011-297641a7675f',
    'identification_number': 7360169,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f35f25d7-253f-4315-b184-5e5ec9847bd0',
    'identification_number': 110411196,
    'item_consumption_1': 39,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 22.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46731496-616d-4dc8-9578-8e11e1df9675',
    'identification_number': 105799653,
    'item_consumption_1': 1428,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 834,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd082a08f-4b12-48c7-b4de-0d2c6a84843f',
    'identification_number': 6941044,
    'item_consumption_1': "'-37.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-12.88",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '379d411e-4c07-4880-b0b5-4bc70109edf6',
    'identification_number': 54072719,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3ffadc87-ca16-4847-837e-46a4e3fe57d5',
    'identification_number': 16078985,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7402b852-8c4a-4f06-abac-09049a9631e5',
    'identification_number': 20818343,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 114.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e1ff32cd-3896-4127-bfc2-28027dbab231',
    'identification_number': 45653313,
    'item_consumption_1': 5299,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2066.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce041cb3-3a00-4fd5-aa70-29dbc5d1964a',
    'identification_number': 110019377,
    'item_consumption_1': 78.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2283.81,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'db22971c-dfe7-40c7-b8eb-c04f9683a27e',
    'identification_number': 19450672,
    'item_consumption_1': 13890,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 21013.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18aa8db7-208a-4673-8421-64f853b5ee86',
    'identification_number': 97224596,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 207.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29506732-929e-4c7c-944c-19cc8a5c3d17',
    'identification_number': 63897148,
    'item_consumption_1': 3849,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1432.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2303d563-3fad-4896-bd1c-ce74028fe5d8',
    'identification_number': 111848520,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b9277e7-c2e1-4fb3-b1a9-4f7c4ae0fac7',
    'identification_number': 32486804,
    'item_consumption_1': 4.18,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 243.15,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '71b7238d-ec88-46ba-aa90-f4f6d1185d77',
    'identification_number': 41456440,
    'item_consumption_1': 4415,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1871.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1831ce4-e549-4013-ab47-a350ce6a985b',
    'identification_number': 14662302,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e22f28a4-ba4d-4cc2-9239-f7c7a9cb1b58',
    'identification_number': 935371632,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2b2f45af-8f32-444d-ab4c-21b78654e467',
    'identification_number': 46861009,
    'item_consumption_1': 17943,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2290.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '386c4fef-beb9-401d-a286-b338eb35a4ec',
    'identification_number': 106141864,
    'item_consumption_1': 49.56,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1182.03,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e5ff149d-4143-4908-b375-bd13ace81b8b',
    'identification_number': 22902694,
    'item_consumption_1': 9651,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3591.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b15fe71-71a0-4e70-8cac-194bf7f74a7a',
    'identification_number': 59486260,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ab88240-5e13-4641-aa32-bb6562b25d75',
    'identification_number': 44001479,
    'item_consumption_1': 1442,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 842.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97a8eaac-f134-4a37-80a9-b64fa25d173d',
    'identification_number': 98370200,
    'item_consumption_1': 422,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 165.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '979771d7-5382-4a8d-a0de-cfd0308c7e0f',
    'identification_number': 70941149,
    'item_consumption_1': 577,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 336.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b54a645-478a-4974-9611-bafdb97200ca',
    'identification_number': 62557777,
    'item_consumption_1': 9945,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3701,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '560b55c0-d50f-48e3-a9d2-da3323e5bc5e',
    'identification_number': 19310994,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '223d95b5-6831-4db7-b8e2-15c9d1cd9642',
    'identification_number': 36253642,
    'item_consumption_1': 6446,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2518.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0afc9c5-1379-4778-af53-a2e078182bce',
    'identification_number': 65141024,
    'item_consumption_1': 14,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 1599.08,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a9da9a92-afbd-47bc-bc67-4f05a681c71e',
    'identification_number': 29705932,
    'item_consumption_1': 13699,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5098.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb53ebc6-6157-4b5a-a8db-458c78a9ec15',
    'identification_number': 15872050,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'be9163eb-bb57-4f32-825a-ddd2eab77b1d',
    'identification_number': 56874081,
    'item_consumption_1': 16936,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2161.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ee21cf3-555e-44c8-a7d2-3ec955655d88',
    'identification_number': 94450145,
    'item_consumption_1': 5077,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2150.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2fc9838-3e5c-44cc-bded-a88d93db0e15',
    'identification_number': 80642322,
    'item_consumption_1': 133,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 51.81,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9d2d149c-666d-4ec0-9cc8-609f21d81389',
    'identification_number': 46006583,
    'item_consumption_1': 49,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 19.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f1374970-6c7c-4557-916d-fdaadef6e9d9',
    'identification_number': 13789414,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea5b511b-60ec-4d81-b0f7-b3a239a09893',
    'identification_number': 100228500,
    'item_consumption_1': 345,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 134.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ba69612-e3d1-494f-8031-88c8b7f3e9d8',
    'identification_number': 64104770,
    'item_consumption_1': 36493,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13580.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'acbc2efa-f754-42bc-b90b-2afce41943af',
    'identification_number': 101171714,
    'item_consumption_1': 251,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 106.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9eae2d2e-4a0e-489e-9eb1-b827ee50ab9f',
    'identification_number': 9788166,
    'item_consumption_1': 11436,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1459.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '424188aa-e98c-4bda-a32c-4728f41ce8e1',
    'identification_number': 16695135,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '96b73e60-a040-47c0-b617-408e5e860638',
    'identification_number': 22162330,
    'item_consumption_1': 2494,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3772.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a48562d-0210-4707-bac2-1307f5f1a770',
    'identification_number': 18339603,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bc3b498c-6d9c-4d63-8e9e-d6e3a9ae46a3',
    'identification_number': 72858710,
    'item_consumption_1': 237,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 92.72,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ca2a5dc5-e39d-409d-b2fa-187012680d56',
    'identification_number': 23647299,
    'item_consumption_1': 8758,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3412.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b7af1da-9e64-46ed-80ab-28468a74edcc',
    'identification_number': 13977873,
    'item_consumption_1': 9.86,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 235.17,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '38de0fac-62d6-4417-bc1a-7606f66e5c5b',
    'identification_number': 109018451,
    'item_consumption_1': 8807,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1124.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a113c975-e2b9-4a6d-bf33-4d72f0f9fe11',
    'identification_number': 30298482,
    'item_consumption_1': 0.49,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 11.69,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '92c4d243-e15e-417b-8345-090445736eba',
    'identification_number': 86190695,
    'item_consumption_1': 2500,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 976.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1de7b405-9b90-4f9d-9fd0-334dad0fa7d9',
    'identification_number': 81267843,
    'item_consumption_1': 13.57,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 774.96,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3e4ca90c-671d-4354-a3f2-c006e85bd757',
    'identification_number': 17671973,
    'item_consumption_1': 4120,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1743.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3eb04a77-3aa7-4ae2-a2cb-48dc6b4cd868',
    'identification_number': 92096115,
    'item_consumption_1': 133.08,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 3870.75,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4b54e168-2a99-482e-9857-d697d1130c89',
    'identification_number': 82500312,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4f8d52de-59fc-4ea6-b826-5f735630c0e1',
    'identification_number': 68902824,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1905.98,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e1b3f539-ff19-4ad2-be23-a88363700420',
    'identification_number': 110019377,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b43340c8-15d5-477d-ae30-345c812b0b56',
    'identification_number': 55316883,
    'item_consumption_1': 37.56,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2145.03,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '9ef5fcce-7ec1-46f8-8d48-7cc2b0f6cd3f',
    'identification_number': 95892567,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c43680fd-81af-4c2c-9854-35b8df42d150',
    'identification_number': 23667966,
    'item_consumption_1': 223,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 87.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1739e5fc-fae5-47ba-a8f2-9977204cdec0',
    'identification_number': 68502982,
    'item_consumption_1': 14087,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5489.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41a55485-d662-4edc-998b-75b189fb3831',
    'identification_number': 5685885,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c5eff6ed-daf9-4a26-adf8-d2257595bfd9',
    'identification_number': 40709140,
    'item_consumption_1': 216,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 84.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7d348746-2a87-4e27-bb3e-fa8070c29ea0',
    'identification_number': 50265938,
    'item_consumption_1': 2345,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 995.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e6810e4-0289-4c55-946d-e54b4bd3aa24',
    'identification_number': 110679393,
    'item_consumption_1': 3175,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1241.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2945d2da-412a-47c3-8836-d1d8a0ead435',
    'identification_number': 94436800,
    'item_consumption_1': 1101,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 466.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbbebdac-ff7f-49ab-b17a-7ea732406449',
    'identification_number': 45478260,
    'item_consumption_1': 1.55,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 36.97,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ad0c344a-a871-4ce6-af39-9b5b8df83cc0',
    'identification_number': 103284770,
    'item_consumption_1': 92.49,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2690.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '98909c1d-68e9-4060-9f9c-a1f1f1ef37ea',
    'identification_number': 50128434,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e494af3b-e499-4f5b-a213-9331e0a84b8a',
    'identification_number': 96699124,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad1f5ade-4bb5-4e72-81a9-576c98a9e384',
    'identification_number': 109697693,
    'item_consumption_1': 19810,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2528.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa43a45c-09c7-4b61-9b05-3adfb3ee5247',
    'identification_number': 69637067,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '16006f6b-7b8d-4709-9bf6-27ece6a1dced',
    'identification_number': 54613612,
    'item_consumption_1': 813,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 345.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2eb8d1aa-889a-4169-bf9a-03637008af33',
    'identification_number': 106605712,
    'item_consumption_1': 3909,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1454.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e16a12c-0692-42dc-a5ca-e1f4db4004c2',
    'identification_number': 104126388,
    'item_consumption_1': 593,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 231.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5203334a-d42e-4d8f-a12c-e26a37e0f59c',
    'identification_number': 99178990,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cff33a54-7ade-4fc4-b493-2f2601441599',
    'identification_number': 91711630,
    'item_consumption_1': 14.51,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 422.02,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8a2c0fe8-01d1-4ed2-9487-318dc1b7acc6',
    'identification_number': 40504077,
    'item_consumption_1': 4.06,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 96.83,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8b328252-5734-4c5e-8ab7-6fb8b36e1042',
    'identification_number': 17810914,
    'item_consumption_1': 867,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 367.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0df4f776-aa31-4978-ac46-6adb62422d67',
    'identification_number': 101712626,
    'item_consumption_1': 1107,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 470.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a81d8752-ef24-48d2-9d93-0be899c44a4b',
    'identification_number': 44001479,
    'item_consumption_1': 0,
    'item_description_1': 'DESLIGAMENTO PROGRAMADO',
    'item_total_value_1': 257.62,
    'reference_month': '11/2023',
    'referente': 'Desligamento',
  },
  {
    'uuid_items': '1197c456-3e6c-45a9-a24b-d0f7a8ad0ad2',
    'identification_number': 39024091,
    'item_consumption_1': 10202,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4318.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5393c7f-40d1-4e21-a0bf-1301b563f1cb',
    'identification_number': 17472652,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2edb50aa-60ab-4f69-98b0-9aa5013e54fd',
    'identification_number': 9733531,
    'item_consumption_1': 6090,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2379.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4c419b3-78a4-4f40-9ab6-dc5a6463aea1',
    'identification_number': 55272584,
    'item_consumption_1': 591,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 230.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4c6c378-fab5-40e8-ac97-e8999aa3be21',
    'identification_number': 95632867,
    'item_consumption_1': 9975,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3712.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b3f6cf9-8b56-40d5-86d4-5a0c15fd856b',
    'identification_number': 103208348,
    'item_consumption_1': 4807,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1879.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7775e69a-13bd-4596-9eda-53ce1315f44c',
    'identification_number': 17241170,
    'item_consumption_1': 6354,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2691.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44923693-7acc-4a33-9c63-c919f18bf97c',
    'identification_number': 93430442,
    'item_consumption_1': 42.31,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1230.62,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2c102a25-7fc6-4821-a007-e709c9fca9b5',
    'identification_number': 37229303,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '393bd8a1-db97-47dd-b28e-a11e82284681',
    'identification_number': 84156171,
    'item_consumption_1': 16.68,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 485.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'd8fb0025-3683-43d5-b3ba-3bb60df5f4fd',
    'identification_number': 86398652,
    'item_consumption_1': 70.89,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2061.89,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cdfa5565-01ee-42f5-a051-11962589716e',
    'identification_number': 95917160,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '673d1373-b17f-485b-a662-e1d56c47a841',
    'identification_number': 86283154,
    'item_consumption_1': 296,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 172.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c209df9e-690a-4ac4-889d-8a4f317ff7ca',
    'identification_number': 109447514,
    'item_consumption_1': 102.13,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2970.52,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3dc2f444-4a14-4615-a33c-d91dc632489b',
    'identification_number': 16191757,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c81068c1-f499-4e81-9c4c-1f9ea577e6c1',
    'identification_number': 105564443,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9edc4e50-2f19-440e-ae33-d843613a8ff5',
    'identification_number': 45412839,
    'item_consumption_1': 463,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 178.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ddba0e8-2850-4478-acc8-62becfb10816',
    'identification_number': 68029098,
    'item_consumption_1': 31408,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4008.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0065bcfb-5131-462d-9729-3e998dee67e1',
    'identification_number': 18236189,
    'item_consumption_1': 7019,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2735.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '435a68e7-258d-4e9d-bce7-28f9bdcb9279',
    'identification_number': 39247260,
    'item_consumption_1': 997,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 389.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f0fd8cf-3d01-47de-9a29-68a1c41f72f1',
    'identification_number': 105060097,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdc343e3-8612-4bcc-99c0-fe852a970114',
    'identification_number': 105798908,
    'item_consumption_1': 9895,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1262.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '551e5e4f-3cc1-4d9a-99e2-3c8128a2f0d9',
    'identification_number': 9857389,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '04e99334-7b86-404d-a64a-eeeea1f084c1',
    'identification_number': 46577173,
    'item_consumption_1': 12.92,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 308.17,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '95c605bf-184f-4722-8414-390278b0dbf8',
    'identification_number': 39422470,
    'item_consumption_1': 63.17,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1837.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'eea92214-9b2c-4bfa-a096-ec6acfdb90aa',
    'identification_number': 19622252,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e3f29b47-0d67-4e75-a7ed-38d9d30e56fb',
    'identification_number': 111609844,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40b538ad-3624-4fd8-ac0c-83ac1985c49d',
    'identification_number': 100359140,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58a74c3e-bb35-434f-b57e-71b7dc7d958b',
    'identification_number': 14945304,
    'item_consumption_1': 1887,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 736.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37f180eb-6f01-4514-a413-09dd12af8388',
    'identification_number': 32121644,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5cbe085a-68c4-4bcd-ac7f-f6d8c42005d0',
    'identification_number': 16125312,
    'item_consumption_1': 20537,
    'item_description_1': 'Consumo lora ponta',
    'item_total_value_1': 8284.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7775ac9-2e37-4472-96d5-363559aba20d',
    'identification_number': 20304137,
    'item_consumption_1': 2.98,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 173.35,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'cf47f814-34ff-41f6-8137-fc04a9a64ccd',
    'identification_number': 14799200,
    'item_consumption_1': 784,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 333.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5dedbd5f-031d-4a8b-be68-fad839b2478c',
    'identification_number': 17073057,
    'item_consumption_1': 11547,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4297.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29bc9691-2fa3-4fc4-a4fb-eaf7b0b8cf41',
    'identification_number': 11404639,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 4.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '37e9eaf8-b2a3-4912-8e2a-7401c725c784',
    'identification_number': 40995305,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 143.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bc003186-8cc7-414f-bc7b-08644c1d0cbb',
    'identification_number': 92096115,
    'item_consumption_1': 7336,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 936.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55a6dfce-6f54-441c-90d1-94eadba79997',
    'identification_number': 58770500,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7934bbff-0ac4-432d-b670-bfa497a19fbd',
    'identification_number': 106382373,
    'item_consumption_1': 32,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 12.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '52c35878-2ffd-4105-8bac-76621bb0cb9b',
    'identification_number': 10466177,
    'item_consumption_1': 227,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 96.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '147d0c31-e8bb-432a-91e9-386f98e15e0b',
    'identification_number': 18270786,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 114.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7bf98676-6cd4-4868-8f62-fa51525f100c',
    'identification_number': 107941589,
    'item_consumption_1': 936,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 366.22,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd648a891-c640-4996-83ba-d3a5cd77228f',
    'identification_number': 17765099,
    'item_consumption_1': 2855,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1212.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7326f57-18f8-4a0f-ad72-e2669c7c5132',
    'identification_number': 106706632,
    'item_consumption_1': 121,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 47.33,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f29b1932-11de-45f5-90e0-a0f59022565a',
    'identification_number': 98113739,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2a29645e-1774-4fa3-858d-4149cb6e2c30',
    'identification_number': 74328336,
    'item_consumption_1': 375,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 158.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ea39ac3-3f97-4ff3-8fd0-93d6bcca9b80',
    'identification_number': 28426193,
    'item_consumption_1': 167029,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 62159.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7dd8b7b4-852d-41d2-a58c-37577992f899',
    'identification_number': 95332162,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '018e4f42-94e6-4c9d-944b-e9b18782ae0e',
    'identification_number': 71243690,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fc22c27e-2e49-4994-aed0-d9ac07e57265',
    'identification_number': 43083315,
    'item_consumption_1': 1532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 598.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d506dcb-5e30-4315-b314-3363e699ce21',
    'identification_number': 60656360,
    'item_consumption_1': 2374,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1005.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44f394b5-b9c0-45c7-94ab-5aa08e31fffa',
    'identification_number': 99990610,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2959d09d-5c1a-49df-9ebf-404ec471aa79',
    'identification_number': 38288800,
    'item_consumption_1': 11234,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4384.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2886ee64-ba7b-4444-b812-df7be6f905b2',
    'identification_number': 109633962,
    'item_consumption_1': 1542,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 573.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28f5da32-c1c1-4e69-9e33-b958db23e2f2',
    'identification_number': 98798510,
    'item_consumption_1': 112.77,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3280.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8eed48c3-adf0-4cca-b2de-052e82b6501f',
    'identification_number': 27817563,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72e1b5fc-a85b-4e06-9e40-6db6258a4aec',
    'identification_number': 28993055,
    'item_consumption_1': 242,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 94.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24b3011a-379e-41bc-bad9-890179b8b1d8',
    'identification_number': 75890380,
    'item_consumption_1': 734,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 428.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26d9bd8f-447d-4a4f-a176-4820ac9c2fee',
    'identification_number': 22634207,
    'item_consumption_1': 2240,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 948.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0af1235-d543-4a80-a63a-0dfdea1c854b',
    'identification_number': 36752118,
    'item_consumption_1': 11136,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1421.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6dd7577b-48a8-406a-8772-69cb71047ed2',
    'identification_number': 94708231,
    'item_consumption_1': 605,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 236.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '042ff55b-95b9-4a1d-9bac-b71df4f3df58',
    'identification_number': 27716210,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2ce195b-214e-4995-a4dd-c9bdb7e96da6',
    'identification_number': 16128060,
    'item_consumption_1': 5,
    'item_description_1': 'En Exc Reativo na ponta',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': '48a4cbd3-54dd-45ef-9fa6-496c76f89127',
    'identification_number': 70810737,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7bd948c3-45c5-47d7-9bfe-3de50b448912',
    'identification_number': 52779386,
    'item_consumption_1': 2278,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 891.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '093b9523-a6a1-4467-a45e-6951a9f21c49',
    'identification_number': 108298035,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 15490,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7889d003-877b-4690-9776-24d1e7b15e68',
    'identification_number': 6776582,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.84,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '52372c79-ccee-46ca-8156-3c2534ff2ab0',
    'identification_number': 9105760,
    'item_consumption_1': 2342,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 993.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e54972e-b199-498c-8c08-94adb894a7f2',
    'identification_number': 52599400,
    'item_consumption_1': 951,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 555.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07393f55-7a1a-4f07-ae42-c7480f38c35e',
    'identification_number': 56877250,
    'item_consumption_1': 38,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 22.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b8b171b-1ad8-4764-b87c-ddd42b323b9b',
    'identification_number': 21374792,
    'item_consumption_1': 0.07,
    'item_description_1': 'DEMANDA PONTA TUSD ISENTA ICMS',
    'item_total_value_1': 2.62,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0c25302a-37dc-462b-8a78-253dcb210ab6',
    'identification_number': 29661129,
    'item_consumption_1': 10100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4276.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c4885b3-9b0e-49fd-a6b8-20f157d7dc3f',
    'identification_number': 43546900,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '81d8086b-8b24-4dce-98be-a7afc054c97e',
    'identification_number': 108167879,
    'item_consumption_1': 3390,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1979.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e560ac8-28c7-411e-be82-9315b86ed9dc',
    'identification_number': 92672051,
    'item_consumption_1': 1,
    'item_description_1': 'SERVICO DE RELIGACAO',
    'item_total_value_1': 10.27,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '763ad82a-8a57-47c7-ba87-413a7b217446',
    'identification_number': 60730781,
    'item_consumption_1': 1740,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1016.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ee2f7ab-7902-4594-b9ef-e6e4610f135a',
    'identification_number': 14693100,
    'item_consumption_1': 3155,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 402.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd650ccdc-4b6b-4d51-a19b-088cdd34efda',
    'identification_number': 35685948,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '638ad5e1-6541-4eb8-9b99-af7735f2ef5b',
    'identification_number': 13924613,
    'item_consumption_1': 5.02,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 119.73,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e6ae98f6-6769-4a76-9ae2-5e1fa42fab01',
    'identification_number': 9802886,
    'item_consumption_1': 3348,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1421.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a94032aa-0349-4633-927f-2489f8bfb973',
    'identification_number': 27817563,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a96fdc3a-4255-4241-9e1b-f1aed97d8e1e',
    'identification_number': 13846817,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75d0056d-8244-43cc-a895-c2874b65a4c9',
    'identification_number': 7381956,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 145.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5fed7a67-136d-4f36-9504-b6859e6be77d',
    'identification_number': 44010141,
    'item_consumption_1': 60,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 23.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'fadce637-26f8-4e1f-9454-6152c3fd7698',
    'identification_number': 106643517,
    'item_consumption_1': 35356,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4512.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '356cef6e-b16d-4389-9c25-2d92188838a0',
    'identification_number': 22533249,
    'item_consumption_1': 2688,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1051.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff989bfa-889b-4b0f-9293-58e006a4d2c8',
    'identification_number': 104378417,
    'item_consumption_1': 504,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 213.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99b58996-b9b5-4017-b46d-fc4598d6996d',
    'identification_number': 32629095,
    'item_consumption_1': 63,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 24.64,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '58cf8223-ec2a-45b6-bea2-9fe833fa67a1',
    'identification_number': 99042223,
    'item_consumption_1': 24091,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8965.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '187aa6d5-b3cb-49fa-abd9-bc8b1946db8d',
    'identification_number': 67917283,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '75ffd684-4d40-4423-9a45-cdc47dc942ee',
    'identification_number': 16175573,
    'item_consumption_1': 2482,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 1885.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5828f92d-dd89-4790-98bc-1441d4c00962',
    'identification_number': 15740056,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 96.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '89bbc3f1-e5b9-45c1-9974-a9e192fb62e1',
    'identification_number': 54073901,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.99,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7dab8e1d-a99b-4bf1-a632-3693793b6fe7',
    'identification_number': 12273945,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '669d302f-3864-494a-adb9-4b636a7fa950',
    'identification_number': 35088974,
    'item_consumption_1': 65,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 25.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8dd20b33-7707-4f76-a327-dcd10deb6734',
    'identification_number': 46349030,
    'item_consumption_1': 548967,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 204296.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c7ce515-10cb-4f43-b0a4-f8050849455b',
    'identification_number': 29891833,
    'item_consumption_1': 742,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1122.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '925b2d0b-9893-4a00-b26e-e93f5e33e042',
    'identification_number': 109922204,
    'item_consumption_1': 2708,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1147.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd1e6fbc-f125-4475-92e8-4ad1e1705855',
    'identification_number': 16002016,
    'item_consumption_1': 20,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 376.87,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bf83dafc-4c4c-49ab-8f92-cedf7c71510f',
    'identification_number': 41582608,
    'item_consumption_1': 3712,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1447.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b92af75-1b7e-40dc-9f8a-88a4e9de669a',
    'identification_number': 12977489,
    'item_consumption_1': 20004,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2553.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e60820b4-fc96-4a9f-bba0-bd11b67b6ef2',
    'identification_number': 31686729,
    'item_consumption_1': 4835,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 617.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '509ba496-88a2-422e-ba7a-3e401f885f6f',
    'identification_number': 27925145,
    'item_consumption_1': 1517,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 644.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e21989fe-a2b2-4b6d-ad2a-5ecfc922942f',
    'identification_number': 101144555,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d06872d-a195-4844-acd1-9c90820052d3',
    'identification_number': 110598350,
    'item_consumption_1': 306,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 129.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e65bf9cf-9840-4237-b9eb-1c45476915b3',
    'identification_number': 13010476,
    'item_consumption_1': 8062,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1029.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f39ee9db-251b-4946-bb2a-b0339f64ebc7',
    'identification_number': 72062649,
    'item_consumption_1': 2767,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1173.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72bbe48a-9229-47b9-9674-d43ee54ea603',
    'identification_number': 46171622,
    'item_consumption_1': 3576.96,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 104039.3,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b95663a6-7073-4b90-bcbc-39cb31a8a92b',
    'identification_number': 60065699,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e853969c-5aac-464f-8331-572393af28ec',
    'identification_number': 6338526,
    'item_consumption_1': 2123,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3211.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b6293bd-99d6-467c-b5f5-ec8a73ecdc1f',
    'identification_number': 14104709,
    'item_consumption_1': 49,
    'item_description_1': 'Demanda faturada FP',
    'item_total_value_1': 757.12,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '435e0849-8291-4ab1-83ca-cc91f5fb9958',
    'identification_number': 84768754,
    'item_consumption_1': 7203,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2680.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ab06e20-2075-4ec3-abf7-9592ce7b71c9',
    'identification_number': 45239282,
    'item_consumption_1': 2816,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1644.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e6cdf31-1e47-4127-b98d-4e298bfa9ffc',
    'identification_number': 33557004,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '848e2d2c-e3c3-4f69-a861-fb96dd152ed7',
    'identification_number': 68079257,
    'item_consumption_1': 12503,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4652.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1381b713-844b-4f08-b87d-a7d950e72011',
    'identification_number': 79665306,
    'item_consumption_1': 57.66,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 3354.18,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '60e28b2e-6ff1-4586-bfc9-b49e1e2824ae',
    'identification_number': 92572154,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a0f1bea-172d-4df7-8591-c9c5ae7dd486',
    'identification_number': 100081592,
    'item_consumption_1': 5877,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2297.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e465c68e-21e5-4378-9a6c-efa0460c7f45',
    'identification_number': 28942981,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 115.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '10e3154f-b8c6-44b4-8bc1-363e6ba9f9cc',
    'identification_number': 59440287,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '57ee6b0d-a69e-465f-a359-5f7d9f3aa9df',
    'identification_number': 106972529,
    'item_consumption_1': 345,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 146.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fbddeff-0789-4b64-9ec9-74626c3550d2',
    'identification_number': 106868250,
    'item_consumption_1': 17829,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6635,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63aae077-2995-40c6-96ef-acc05f6d57e4',
    'identification_number': 93451962,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '115e5ab6-24cf-49c6-8d5c-659c758459b1',
    'identification_number': 41932510,
    'item_consumption_1': 28752,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10699.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a62f2cc-6b2c-4e49-8892-98e1c6f5e727',
    'identification_number': 109530608,
    'item_consumption_1': 0,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 003/004',
    'item_total_value_1': 267.98,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '09f47af1-43e9-4dc9-8390-4ce0ce483b01',
    'identification_number': 32629095,
    'item_consumption_1': 19506,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 29509.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbb40480-63f6-4a88-8382-81ed3c6ceb49',
    'identification_number': 8906700,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 180.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '72fad200-df83-485c-a5ee-1507bd42fbab',
    'identification_number': 55456812,
    'item_consumption_1': 2978,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4505.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a5dca9a-2814-43f5-8eab-d05283a21ec1',
    'identification_number': 70000050,
    'item_consumption_1': 30176,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12777.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c49028a-a91f-4a2c-bf11-b07ecdf5d83c',
    'identification_number': 30734541,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd712a12-b907-404d-82e1-2ec29d0456ba',
    'identification_number': 11467185,
    'item_consumption_1': 5609,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2381.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4ff1f99-4648-487b-a6c6-0e694440644f',
    'identification_number': 20513917,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebd4ee71-7fa7-48fe-8911-f371b322c637',
    'identification_number': 107936704,
    'item_consumption_1': 579,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 338.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14aed28d-8dce-48d5-a6d0-9ec9b4ac8d9c',
    'identification_number': 92158064,
    'item_consumption_1': 39,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 15.25,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '917fc2d5-f6be-410a-9d14-1d1354040e39',
    'identification_number': 92122345,
    'item_consumption_1': 177,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 75.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5f1a5f5-819b-4fe1-9a2e-e50b2f5f472e',
    'identification_number': 92739245,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bb121542-c7d0-48b5-9a76-e70dc2968f47',
    'identification_number': 39247260,
    'item_consumption_1': 997,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 423.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '446d7ab7-ca2d-470d-93d7-f96259f604de',
    'identification_number': 110228022,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e9286be-5010-42b7-aa1d-4dd875af9fdc',
    'identification_number': 44085737,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85a8be45-2bfd-42c9-92cf-88066e62b052',
    'identification_number': 70343985,
    'item_consumption_1': 7535,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2938.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b79c3677-eb64-4c2d-806b-71d6017341dd',
    'identification_number': 13789430,
    'item_consumption_1': 23.58,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 562.41,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd16167fc-720e-4da6-889b-c46390b07ca5',
    'identification_number': 39965678,
    'item_consumption_1': 786,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 307.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2cac98d-0ee0-4754-a886-4e5b92458687',
    'identification_number': 81494394,
    'item_consumption_1': 4835,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2051.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66234a0b-70aa-4984-a40a-2b927b6da954',
    'identification_number': 30326834,
    'item_consumption_1': 2378,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1009.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ba3a208-28da-49d2-b96d-0a1274366a18',
    'identification_number': 105904872,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78442590-0401-412e-9466-b616d519fb3e',
    'identification_number': 45766500,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 31.79,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'bebf6677-c1e5-47aa-9d3f-541388b87242',
    'identification_number': 42034515,
    'item_consumption_1': 4683,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7084.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6be8a3fa-8bff-4f2f-b074-c49fdcf9a96e',
    'identification_number': 45483779,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '20057917-87d3-4844-8e81-735a3de410da',
    'identification_number': 42556198,
    'item_consumption_1': 84.27,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2451.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b695a023-583b-4eeb-9d4c-3fc63ac13980',
    'identification_number': 19451610,
    'item_consumption_1': 242,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 94.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '10132f21-0849-4c50-ab43-024867de2e93',
    'identification_number': 41692063,
    'item_consumption_1': 2217,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 866.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6561793-6524-4165-84c6-948b96f1e8a2',
    'identification_number': 49457012,
    'item_consumption_1': 40196,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5130.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a60c850-b680-468c-b41e-0fe39bcfae01',
    'identification_number': 17602114,
    'item_consumption_1': 12854,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5441.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a0ff1a7-e929-488b-897f-710b163730b1',
    'identification_number': 93683553,
    'item_consumption_1': 654,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 255.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33759a17-df21-4c8f-a524-c33feef1bb8b',
    'identification_number': 66530822,
    'item_consumption_1': 6578,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2569.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89b96c6f-1ba3-47c3-b1e6-02d89fafc889',
    'identification_number': 111157285,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ac17aa4-3297-46a2-8975-692b06c80062',
    'identification_number': 28259513,
    'item_consumption_1': 24.28,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 706.19,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cfff3673-9a50-4515-b9ad-af7dc99fd135',
    'identification_number': 42403359,
    'item_consumption_1': 2290,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 969.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f258a6e3-415a-4009-b936-4f1d751fe2c0',
    'identification_number': 50876279,
    'item_consumption_1': 891,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 378.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce172570-b9c5-4b47-beec-577bde71382b',
    'identification_number': 73311235,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1315.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0e1fe32-efdc-4ffa-9fe5-6c0900bd0801',
    'identification_number': 102131813,
    'item_consumption_1': 16035,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6266.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '777d8e22-04ad-458b-8771-44bf9d3a487d',
    'identification_number': 84363690,
    'item_consumption_1': 73,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2caafb1d-576b-4f9c-800d-8abf8e1e80cb',
    'identification_number': 103879889,
    'item_consumption_1': 1078,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 456.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8721e15e-e4a8-4775-bd83-9b0b422deb33',
    'identification_number': 52131718,
    'item_consumption_1': 3323,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1940.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e9a9ed6-becc-40ed-81b4-5b0ae230e3f6',
    'identification_number': 94604916,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3c5bb2e-b7ee-4a1d-8733-ef8b1bdf8bc6',
    'identification_number': 8115524,
    'item_consumption_1': 206,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 80.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a86ebb3a-b456-4291-b79c-59253c3ff21c',
    'identification_number': 91858194,
    'item_consumption_1': 1559,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2358.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '061f6427-1e64-494e-b453-cf1ac0312d80',
    'identification_number': 82739935,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5bcb04fb-82dd-4c05-869f-f063aeb657c7',
    'identification_number': 76715760,
    'item_consumption_1': 2987,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1268.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b2c1b13-dfe3-4f8a-a712-b89e2bf0cef3',
    'identification_number': 99379422,
    'item_consumption_1': 438,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 255.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5e59545-3c06-4d16-9d06-9bbae468bbc2',
    'identification_number': 9788166,
    'item_consumption_1': 11436,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4255.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c503906-26ce-4f17-a73e-8bb02b7585c1',
    'identification_number': 36523402,
    'item_consumption_1': 380,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 148.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0fa822a4-353c-4470-b2f0-47b91b177b4d',
    'identification_number': 25644220,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c59a6b7c-fcf4-4a03-a13f-919ed42324ef',
    'identification_number': 33998299,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '450980be-1162-4a65-b61b-2817a5f4e37d',
    'identification_number': 38087715,
    'item_consumption_1': 534,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 226.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b04dc8c0-49d2-4a90-938c-d9878fdf862d',
    'identification_number': 56995806,
    'item_consumption_1': 1333,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2016.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f10a1b10-30e3-406f-9160-952fefaf386e',
    'identification_number': 104707127,
    'item_consumption_1': 1866,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 792.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8819e4a1-6186-4a6d-adf0-eb8d50b40b90',
    'identification_number': 14478870,
    'item_consumption_1': 605,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 236.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6895189f-eb22-449d-855a-a1672305ef9c',
    'identification_number': 19451610,
    'item_consumption_1': 1.6,
    'item_description_1': 'DEMANDA REAT EXCED USD PONTA',
    'item_total_value_1': 46.53,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '873da424-0c66-4776-ae6d-bd622c3ba0f5',
    'identification_number': 102742820,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7e9b0967-5efa-4dbe-b3ad-5e1f4d78415f',
    'identification_number': 93825579,
    'item_consumption_1': 2032,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 792.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8e4811f-cd7f-488c-9091-eacef211f647',
    'identification_number': 16105176,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f310925c-f136-4d62-96d4-ef5097d564b8',
    'identification_number': 32403011,
    'item_consumption_1': 2392,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 305.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6630b14-5ef5-499b-98a9-4ded4fa2fed7',
    'identification_number': 95225641,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5de6cc91-9fed-4185-af0a-9c607e99b1eb',
    'identification_number': 105059552,
    'item_consumption_1': 1217,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 515.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '406d2ef7-b2e1-4b76-9f55-48f542c4bcd7',
    'identification_number': 9889566,
    'item_consumption_1': 5610,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2378.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68ddec7e-15be-44c8-9fbe-e38215ec8b4b',
    'identification_number': 45340340,
    'item_consumption_1': 1438,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2175.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42b24bc0-1dcf-4c3c-b7f7-507c4c6aef07',
    'identification_number': 17970806,
    'item_consumption_1': 1003,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 585.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41275924-4c8a-4766-a620-65ab98bc1378',
    'identification_number': 105663247,
    'item_consumption_1': 2.65,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 77.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'db16db4c-4e4d-423a-8791-b264e1c4b83a',
    'identification_number': 27132226,
    'item_consumption_1': 1412,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 551.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e34ea858-df3a-450f-b6d6-9168fb3a921e',
    'identification_number': 936153898,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '36a0e12e-09f9-406b-b275-2341442af6aa',
    'identification_number': 81037600,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '196d4bd6-4f35-4509-8aa6-4e1075262136',
    'identification_number': 15048861,
    'item_consumption_1': 903,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 383.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df6ccb60-897d-45b3-a8c6-3553bc54f6a1',
    'identification_number': 63896931,
    'item_consumption_1': 1.65,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 39.36,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fcf46196-1867-4147-bc28-cb80ae6d1990',
    'identification_number': 98715500,
    'item_consumption_1': 1604,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 627.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22636588-8aac-47c4-939b-570e2f967ff3',
    'identification_number': 14474301,
    'item_consumption_1': 5547,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2169.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9382c12-e30e-4f93-b4d3-ee6c9cd196a8',
    'identification_number': 69442347,
    'item_consumption_1': 1514,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 640.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45e44115-187a-4ec7-be85-ccc006c967dc',
    'identification_number': 45501157,
    'item_consumption_1': 4540,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2651.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06cd85cb-225a-46b5-8558-99ff79620243',
    'identification_number': 3030777625,
    'item_consumption_1': 4884,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 1456.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32a36102-479a-4972-9370-33bf89050b50',
    'identification_number': 14333910,
    'item_consumption_1': 54235,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6922.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db745b87-590d-4916-b6b4-ee6d9458260d',
    'identification_number': 35436778,
    'item_consumption_1': 7.39,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 176.27,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7ac2f2e5-3455-46db-8f07-bb99ced424ed',
    'identification_number': 20818343,
    'item_consumption_1': 2092,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 817.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56680975-761c-4b52-9cc4-db5c03b3bd85',
    'identification_number': 111221153,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 18.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '657988e5-af2c-474b-9b1a-1f80169748dd',
    'identification_number': 19873727,
    'item_consumption_1': 4.42,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 105.44,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '71dc35af-60ac-4ac5-9f79-b28ef8caf59a',
    'identification_number': 9876600,
    'item_consumption_1': 3840,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1628.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa7b77c6-b817-49b0-8423-20d23967a146',
    'identification_number': 107936925,
    'item_consumption_1': 175.03,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 4174.56,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7315b9e4-f8ec-4fe6-9239-c71f80cf6b82',
    'identification_number': 935258912,
    'item_consumption_1': 3675.96,
    'item_description_1': 'Consumo em kWh Ponta',
    'item_total_value_1': 7834.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '774cef7f-237f-4114-becf-240c7ef418fa',
    'identification_number': 18229077,
    'item_consumption_1': 4.59,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 109.49,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '704789c0-c8ec-41f9-a069-7f30e8c95978',
    'identification_number': 95632867,
    'item_consumption_1': 1525,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 890.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '568f18c5-d54b-4b95-849e-1fc69b4d8c0b',
    'identification_number': 8843015,
    'item_consumption_1': 6349,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2697.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7161530-dac2-4cde-934e-ffba38487e15',
    'identification_number': 106291238,
    'item_consumption_1': 6855,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2908.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7142724f-7a44-4129-96bc-75d751d18896',
    'identification_number': 27724280,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '82bb6149-42aa-44f4-b1d5-21f1195b3013',
    'identification_number': 105186520,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10022.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1225bd94-e784-4736-946b-971b6650e4b8',
    'identification_number': 100552765,
    'item_consumption_1': 2212,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 862.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49694527-5601-42a3-a5f2-b323d1f3d41c',
    'identification_number': 108435970,
    'item_consumption_1': 1213,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 514.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6fb87275-f04a-4945-b0cd-26e3dc059087',
    'identification_number': 81217846,
    'item_consumption_1': 144679,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 18466.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ec202a5-3d9c-490a-b6f0-6b06466b2e9d',
    'identification_number': 37752871,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e09a8af9-e7f8-4fcd-8923-ea0a594cc05d',
    'identification_number': 29589134,
    'item_consumption_1': 7819,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3313.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc1ec782-35ea-4665-8293-f51d531b0103',
    'identification_number': 43814166,
    'item_consumption_1': 1348,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2039.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70c0c906-e7c6-4278-8c2b-e16a6063fcea',
    'identification_number': 15369080,
    'item_consumption_1': 8298,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3088.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f156595c-5856-4033-8363-20ad39e1a2c0',
    'identification_number': 100841937,
    'item_consumption_1': 72,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 28.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1d43941-6eae-4fd8-ac9c-89fc2324e790',
    'identification_number': 100474144,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45374c1b-4e35-4171-8a24-47b47233787a',
    'identification_number': 105470708,
    'item_consumption_1': 36.9,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1073.25,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0b05e56b-aa7d-4d9d-a414-6231ecf2f2f0',
    'identification_number': 20587147,
    'item_consumption_1': 46771,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 27316,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0cfadb5d-bfe0-47e1-80c1-b80200e7f0a3',
    'identification_number': 77818750,
    'item_consumption_1': 2927,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1143.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5f7f71e-2d16-4451-a5b2-bda3c3f84f40',
    'identification_number': 56812477,
    'item_consumption_1': 386,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 225.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec5bbba2-588c-413f-b8b5-5ee725a4e9fb',
    'identification_number': 103561404,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30a41efb-915b-475e-a713-ee20fe9b66e5',
    'identification_number': 41460510,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c6106582-6389-41af-b580-ff6b02513f0c',
    'identification_number': 22974113,
    'item_consumption_1': 3742,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1462.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc12ad9e-81b5-4193-b58e-b7fb85f858f0',
    'identification_number': 102175845,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b6ebf472-5aeb-422a-a7c4-a69c90e21457',
    'identification_number': 10983848,
    'item_consumption_1': 1988,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 776.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40e638c8-4fae-46bd-9271-e2aa128286c4',
    'identification_number': 46424342,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 94.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '061f93f4-5d0e-4d27-a5d1-61166efaa3b1',
    'identification_number': 32503784,
    'item_consumption_1': 55056,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 20488.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a1885d9-24e8-4675-8d41-6d5145e92021',
    'identification_number': 19296398,
    'item_consumption_1': 6963,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2712.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0af30f94-6dd5-448a-9dca-90086759821a',
    'identification_number': 76403955,
    'item_consumption_1': 149,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 58.29,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6c306776-8f34-4347-84fa-dc9a6ced2f33',
    'identification_number': 103752234,
    'item_consumption_1': 67,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 26.21,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '877df8d6-eecd-4a8c-a241-c0d25ec09ce2',
    'identification_number': 105302570,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99027716-be9e-449c-bf2a-edefdeb5791a',
    'identification_number': 47623578,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a03daf17-b849-422b-911a-e9a5a4862818',
    'identification_number': 108601676,
    'item_consumption_1': 17.46,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 507.82,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '807dd539-b65d-4002-842e-45757ffff132',
    'identification_number': 107119366,
    'item_consumption_1': 4595,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1952.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33aba70a-2f7e-49c9-8dab-eaad89b352b5',
    'identification_number': 14115506,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14d3947b-89ce-47bc-a7c0-a5c0af13dd3c',
    'identification_number': 78610729,
    'item_consumption_1': 53249,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 19816.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f58faf6-39c3-4ab4-81b9-cc5e5999a894',
    'identification_number': 92167942,
    'item_consumption_1': 16901,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6602.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06cd5818-d40c-4b6a-906f-75e5ed051a68',
    'identification_number': 35963310,
    'item_consumption_1': 3122,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1221.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3defc1b6-286d-4d49-8bb6-254c14b4ee16',
    'identification_number': 101108290,
    'item_consumption_1': 4215,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1790.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e73a8e7-9faf-4f04-ab23-d9a04c7cde88',
    'identification_number': 97491632,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5af1422-c92a-43d6-a1b2-7821ba41034a',
    'identification_number': 15885348,
    'item_consumption_1': 6060,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2570.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75e60b8c-d646-45ff-aa1f-ca8188e08713',
    'identification_number': 40254836,
    'item_consumption_1': 3294,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1398.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f1883c4-68c5-4072-adcc-d929f7f9c831',
    'identification_number': 42127564,
    'item_consumption_1': 10590,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4137.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34e05389-df4b-4bb7-b700-3516541767bb',
    'identification_number': 109398998,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49252617-10fb-4b1e-b7d0-8cd32f428ca9',
    'identification_number': 87807360,
    'item_consumption_1': 2223,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 945.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44d4c061-c9cb-4055-98bd-37338f47fe88',
    'identification_number': 923309206,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02c73142-f8e6-47d9-80f6-cd9c749c9e83',
    'identification_number': 74311875,
    'item_consumption_1': 4190,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6338.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '959ba567-1b12-475b-a38f-cde9770320fa',
    'identification_number': 84222417,
    'item_consumption_1': 186,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 72.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0be9a5b3-5770-41c2-a889-d7688e8f78e7',
    'identification_number': 12908398,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2537,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dac9ef10-a35b-4d26-8a7c-5146ae0dbe50',
    'identification_number': 59438592,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1092750-f329-40ec-9d6c-5bebd9ff3049',
    'identification_number': 11013958,
    'item_consumption_1': 24844,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9245.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c616e24-5941-40da-b623-a2aa6cd67eb2',
    'identification_number': 95012818,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '02bfefc5-8c66-4674-b331-11234ba45bbf',
    'identification_number': 32594852,
    'item_consumption_1': 34051,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 19887.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aca4954c-cd39-4af7-bc2b-9694c95016cb',
    'identification_number': 38847280,
    'item_consumption_1': 27314,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3486.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '847a1fe9-4201-4830-907e-1c21f01ec0c1',
    'identification_number': 61792098,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7a9fed49-95f7-4666-a008-b2b5814da512',
    'identification_number': 99990407,
    'item_consumption_1': 3992,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1695.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f00ba08d-4a01-40ed-bf4c-2e8990b7f3ae',
    'identification_number': 107936925,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dcff0b9a-6571-42b2-8e1f-67763262c5e9',
    'identification_number': 108024326,
    'item_consumption_1': 873,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 340.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbbac490-93a7-4817-ae5a-65739f554ae6',
    'identification_number': 109826582,
    'item_consumption_1': 340,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 144.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c381c61e-b728-4276-bd25-58f159e75fcf',
    'identification_number': 19450745,
    'item_consumption_1': 27080,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 40967.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f465caf3-93a8-4653-aacc-c2ef3fcd0c66',
    'identification_number': 17865069,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 51.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4ce3274c-9ca3-443d-b52e-a05cba316acb',
    'identification_number': 104455250,
    'item_consumption_1': "'-13.822",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-4812.91",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '27e68af2-05b9-4006-af07-1d006b3aa21c',
    'identification_number': 18339654,
    'item_consumption_1': 1257,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 160.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7d7d0c1-8ea8-4cf8-9152-0da7deb690a1',
    'identification_number': 105663247,
    'item_consumption_1': 27.35,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 652.32,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '79cd6fe0-638d-4535-86f6-325500c2e377',
    'identification_number': 110227980,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'deca230d-4e1b-4e30-ad3f-5ad319f39a7f',
    'identification_number': 4334221,
    'item_consumption_1': 23.32,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 1092.1,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '6ecc8599-d8df-46dc-929a-92992f2623df',
    'identification_number': 12804924,
    'item_consumption_1': 680,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 265.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6744e910-a259-4a36-9c43-0cedad5f15c7',
    'identification_number': 100662110,
    'item_consumption_1': 1462,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 620.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39218d03-2f90-40ac-b145-1eca0fc80bea',
    'identification_number': 75687267,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fd7dcb7-80f2-40fc-9c68-3cdc5b5f22c6',
    'identification_number': 29772230,
    'item_consumption_1': 25748,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 10057.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b478aed9-1131-4803-a522-7fd32311a71a',
    'identification_number': 101126441,
    'item_consumption_1': 5589,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2177.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e214b30-231e-4c32-ac18-586a21a773a2',
    'identification_number': 110924622,
    'item_consumption_1': 4029,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1708.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e449a609-5cdf-45d1-9ed3-08aa446a716d',
    'identification_number': 99801736,
    'item_consumption_1': 590,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 892.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77af5a61-ddc5-47dc-96a6-9aeb58be87c1',
    'identification_number': 46424520,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1928c257-428b-4405-a1a4-7ebb43861a91',
    'identification_number': 500020,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'Retenção I.R.F.',
    'item_total_value_1': "'-39.28",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '5d4aa233-a1d2-41a3-8fe2-43f134c587fd',
    'identification_number': 39466787,
    'item_consumption_1': 25011,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9307.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3b9bd58-5b89-4bab-bf50-af2aeda30ed1',
    'identification_number': 95830260,
    'item_consumption_1': 1460,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 619.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5bff74c-318d-4c23-af59-d601925d94a8',
    'identification_number': 102780927,
    'item_consumption_1': 8500,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3318.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8527e4dc-8105-4948-b672-ff9df610ca1d',
    'identification_number': 47766654,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 120,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e8a440f2-4345-427b-b5d0-4a10f60a34fe',
    'identification_number': 88789152,
    'item_consumption_1': 5237,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2046.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e66f55fc-6274-4ab3-9b47-7d6cd02822d3',
    'identification_number': 12750948,
    'item_consumption_1': 830,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 351.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '879a99c5-f1be-4f1a-81fd-09f720c27e37',
    'identification_number': 96596120,
    'item_consumption_1': 155,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 60.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27e375e4-779d-45f3-9ea3-6e1f7aa9049a',
    'identification_number': 106621912,
    'item_consumption_1': 1803,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 762.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47042d98-36a2-41a5-b0e8-123fa2173152',
    'identification_number': 40131157,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '90b121e2-f583-475d-974d-1b08ff179ab4',
    'identification_number': 75192900,
    'item_consumption_1': 31.66,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 920.85,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '55e7f406-019c-4ec2-86d8-10e4e08bd50a',
    'identification_number': 80724906,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cd7c5df9-27e3-4d80-81ee-e26c86c5ea42',
    'identification_number': 95555234,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b3f163ce-686c-44de-8aa0-f3d9d2254b1b',
    'identification_number': 15348075,
    'item_consumption_1': 126.73,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 3686.05,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0c584810-b4f6-4e1c-bbd8-c84dc6f5f905',
    'identification_number': 104289970,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5468c23c-b635-4633-825c-f4be586b74b5',
    'identification_number': 19451610,
    'item_consumption_1': 567.36,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 16502.19,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9ee5a3b2-43bc-45c9-b1b3-d4fd8e7b997b',
    'identification_number': 61792098,
    'item_consumption_1': 26564,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3390.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a37e80a6-4e39-406b-9b99-ef182c05298d',
    'identification_number': 12804401,
    'item_consumption_1': 3900,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1522.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd727de0b-f761-4aa5-9b89-d9e452271ee3',
    'identification_number': 11177101,
    'item_consumption_1': 1850,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 721.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e21d5df5-ead4-4196-8799-6294011464b1',
    'identification_number': 97833363,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.71,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '89ee235a-8866-45f3-95a0-6c17502f9e4c',
    'identification_number': 26609517,
    'item_consumption_1': 3558,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1507.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6022d0d1-be11-4013-8088-6ab72031b09e',
    'identification_number': 96801689,
    'item_consumption_1': 378,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 147.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0e1071b-3b61-4a6d-a5a4-1342a163229c',
    'identification_number': 111833884,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03019cf5-a315-446c-94ba-8462cdcef443',
    'identification_number': 44436637,
    'item_consumption_1': 3.19,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 76.11,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '22467e09-55bd-434f-b3a1-39a6f400f994',
    'identification_number': 92562302,
    'item_consumption_1': 35,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 13.69,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '79c468fe-0fca-427a-bd2e-8dbe1bea644d',
    'identification_number': 29935091,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 128.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2037d7fe-9684-480e-a069-26b5d97ef6d6',
    'identification_number': 41082605,
    'item_consumption_1': 1037,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 404.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35cec751-2d25-4cf1-af11-a0495c3d24a7',
    'identification_number': 103025758,
    'item_consumption_1': 4402,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 561.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '664fe59e-1485-4233-8ad4-6ec6d1b927c3',
    'identification_number': 13870068,
    'item_consumption_1': "'-2.031",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 09/2023',
    'item_total_value_1': "'-651.3",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '2bddc03c-163e-4465-883d-ffbb29d30a70',
    'identification_number': 40934756,
    'item_consumption_1': 115810,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 14781.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7bbe5aaf-e33b-4afc-9a46-5d97a3a8e8e7',
    'identification_number': 52635260,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO EM DOBRO',
    'item_total_value_1': "'-5629.72",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'e58ef858-927e-489a-85a9-58e3177d300a',
    'identification_number': 30290635,
    'item_consumption_1': 20610,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2630.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb186c08-6a12-4986-a13a-51e93a35cbcf',
    'identification_number': 110227417,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2a157ac-489a-4a1d-9396-481d847d7516',
    'identification_number': 56493371,
    'item_consumption_1': 23.46,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 682.35,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f9742c12-8daa-4267-bda1-ad1009121df3',
    'identification_number': 93102895,
    'item_consumption_1': 2528,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 987.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ff4bc7e-3e93-4e58-b966-bc91d19de1e4',
    'identification_number': 61583758,
    'item_consumption_1': 23313,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2975.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef897ed1-b742-42e1-87ae-9c678f1c76e9',
    'identification_number': 71655956,
    'item_consumption_1': 916,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 388.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4417cb1e-d041-47a8-aadb-ec346816d2e0',
    'identification_number': 75402351,
    'item_consumption_1': 634,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 248.06,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '44838896-9b3f-47eb-9472-28102388ed2f',
    'identification_number': 97072494,
    'item_consumption_1': 15.54,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 451.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0859d956-918f-4668-9ca8-081440aeed69',
    'identification_number': 15474380,
    'item_consumption_1': 10000,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3905.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bdbb3c7-c3c1-41f8-8e57-39df6bd6ca38',
    'identification_number': 97837784,
    'item_consumption_1': 188,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 73.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f8efe8b-6e0b-4060-abd1-bd6342a42963',
    'identification_number': 18339654,
    'item_consumption_1': 35.91,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1044.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5d9c905a-8040-4f98-983b-2ff333b4aa3d',
    'identification_number': 47573481,
    'item_consumption_1': 3837,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5804.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4eec1ee7-51be-42b5-8f1a-574d52c9b4fe',
    'identification_number': 91601479,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a5d013a-9d14-4801-a44b-732ff68152cc',
    'identification_number': 15667219,
    'item_consumption_1': 449,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 190.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78d29592-89c6-4e51-83a8-025218ca4117',
    'identification_number': 33799733,
    'item_consumption_1': 13.55,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 323.19,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8b45d370-932e-4781-af23-b7c9b2f08262',
    'identification_number': 37528319,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 121.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8631c327-a1d8-405e-aeb3-31d277d78e9d',
    'identification_number': 48843075,
    'item_consumption_1': 2574,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1503.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61469b86-7a96-4711-a15c-c5667b59b5c4',
    'identification_number': 936159663,
    'item_consumption_1': 2457,
    'item_description_1': 'Consumo em kWh LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 2141.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '218cb8a0-fc4d-41b4-bd66-3b358334e0cc',
    'identification_number': 102934096,
    'item_consumption_1': 1467,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 623.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bfbc888-4da5-4145-b9c8-213abb03cf11',
    'identification_number': 110238826,
    'item_consumption_1': 4899,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1913.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30c7e043-9b9e-4272-bb33-a3d7c8c73e94',
    'identification_number': 100059856,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54d29f75-b1b3-4f4b-b488-2da74892375a',
    'identification_number': 100582974,
    'item_consumption_1': 6652,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2594.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2720f3ed-1705-4355-ae9e-c8e986af0b4b',
    'identification_number': 108331024,
    'item_consumption_1': 120184,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 44726.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2a9ec8f-5ed0-4d58-a3d3-0893e0ac031d',
    'identification_number': 43154549,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e04ce5d9-58d0-4423-a567-ff1b367797e2',
    'identification_number': 33140111,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bde7a69e-afa8-4325-80f8-6719785c872e',
    'identification_number': 9206787,
    'item_consumption_1': 9654,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3760.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed927c66-25f4-426a-ba15-8084cdf97e98',
    'identification_number': 79701710,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48a2c5b7-ec35-4e17-bda2-74574873192e',
    'identification_number': 107462036,
    'item_consumption_1': 453,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 192.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8cddfe8-ee66-4898-8ec3-133b137e4de9',
    'identification_number': 44502931,
    'item_consumption_1': 9.72,
    'item_description_1': 'DEMANDA REAT EXCED USD PONTA',
    'item_total_value_1': 282.71,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'ab06888f-0d36-411c-b8b8-1fff9cb5a720',
    'identification_number': 42537711,
    'item_consumption_1': 120,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3448.26,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '58b05b87-78d9-482d-8e8a-20c0a47c3fba',
    'identification_number': 91804701,
    'item_consumption_1': 4843,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2057.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd33ed863-be62-4c51-8514-f57ff5cdf419',
    'identification_number': 55193994,
    'item_consumption_1': 22342,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8314.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72059919-0399-4d7c-861e-587524081f44',
    'identification_number': 17678757,
    'item_consumption_1': 6931,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2939.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9afbac6-fbf0-44a3-a373-19aaa05e159b',
    'identification_number': 34739297,
    'item_consumption_1': 1624,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 687.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1fadbe8-f937-400c-8e54-a077ad6d8c22',
    'identification_number': 53747755,
    'item_consumption_1': 24.95,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1451.39,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2c373e7c-a2bf-4bbb-b98a-170db589b3ba',
    'identification_number': 22533249,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 112.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'da2a7c6f-c94c-44fd-bbd2-7724293dbb44',
    'identification_number': 100214193,
    'item_consumption_1': 8494,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1084.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67c99ef0-63a8-4fbc-b842-1d5ffc038f93',
    'identification_number': 48511978,
    'item_consumption_1': 4474,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2612.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3cf1b0d-73bb-4248-8dd5-13920ed3a952',
    'identification_number': 99991276,
    'item_consumption_1': 662,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 280.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f6f2f52-3f6c-4466-8a26-816caf87e640',
    'identification_number': 98913972,
    'item_consumption_1': 14852,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5527.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e5d002f-7677-4af0-944b-316e7ea9e50c',
    'identification_number': 23179554,
    'item_consumption_1': 84,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 32.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37514808-0144-4581-86a1-ab5cb8ae0bb3',
    'identification_number': 23647841,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9c6c27a8-9a7d-44c3-8684-27dd09178915',
    'identification_number': 91360250,
    'item_consumption_1': 3.34,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 79.68,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '16db3ae3-2e8b-4406-9b9a-83eab1538974',
    'identification_number': 111295459,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0e70c43a-c973-4810-9e0d-4b5c0b4a4a6e',
    'identification_number': 46006583,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '492e9cc9-0c41-4297-9b9e-edb5e81273d8',
    'identification_number': 31639267,
    'item_consumption_1': 53,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 20.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c9db710e-3fac-4edc-b175-8d08c862b9fa',
    'identification_number': 109160959,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd09bcb1-7aff-4f1e-8c77-4cf7f7e91c0c',
    'identification_number': 110270410,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.65,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ffff4bda-f7e6-49e4-a3a2-660a4e762c3e',
    'identification_number': 98113739,
    'item_consumption_1': 3320,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1405.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5cd33b4-1740-4749-ae0a-cdd4d2e51ef3',
    'identification_number': 31818030,
    'item_consumption_1': 50.18,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2865.77,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '88b5fa59-0658-4da0-821e-3ffd6bdba6b4',
    'identification_number': 35825448,
    'item_consumption_1': 1242,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 725.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3471e06d-bbb7-4787-bc06-cc6b656735f3',
    'identification_number': 43195300,
    'item_consumption_1': 48856,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6235.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68d12e95-57cb-4988-a806-00a70f7a18b6',
    'identification_number': 18922813,
    'item_consumption_1': 2120,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 826.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d5cac1e-ed0c-461f-8672-239edf3f6255',
    'identification_number': 103727434,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV.CORR. MONETARIA AJUSTE FAT.',
    'item_total_value_1': "'-0.12",
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '18a4e0d6-51c3-4bd9-9a99-fb95efa69365',
    'identification_number': 14819821,
    'item_consumption_1': 3289,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1223.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14f40576-1954-4621-af98-00952eabc689',
    'identification_number': 97356530,
    'item_consumption_1': 201,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 85.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da1308a4-68ea-443f-84f6-3b246fc26866',
    'identification_number': 108989887,
    'item_consumption_1': 330,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 139.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3aa671fd-f07c-4d72-96b9-60356f4bfe42',
    'identification_number': 70080798,
    'item_consumption_1': 9758,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3812.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9a4e741-ef89-46e7-956e-92a7ec69d555',
    'identification_number': 89126610,
    'item_consumption_1': 1910,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 746.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a22d1a1d-1da2-4588-975b-6e87a8efb407',
    'identification_number': 40244040,
    'item_consumption_1': 2516,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1065.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1774cc06-0238-456f-a98f-eaf426ad8676',
    'identification_number': 32528620,
    'item_consumption_1': 4.74,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 113.07,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2ab0bfb6-13b9-4451-9f18-6b448687e64d',
    'identification_number': 73615145,
    'item_consumption_1': "'-1.221",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-391.29",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '5d6359ea-aacf-4568-9f93-5bfc1cdeead9',
    'identification_number': 92950310,
    'item_consumption_1': 10564,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3931.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '995ddf3a-1d52-405b-9f25-6e423aabf04a',
    'identification_number': 81809883,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f880930b-5fd2-4dd6-abb8-e6868051e1b9',
    'identification_number': 98105132,
    'item_consumption_1': 846,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 330.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f956a47-ee7e-445f-8086-df2c4300ec1a',
    'identification_number': 48367583,
    'item_consumption_1': 1037,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 405.74,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2390d28d-3ea8-4c06-b423-4d9d3f78be4d',
    'identification_number': 75890380,
    'item_consumption_1': 61.69,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1794.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ec5a229d-180f-4b93-99b4-c1fa1d8c02f4',
    'identification_number': 73695360,
    'item_consumption_1': 580,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 226.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '923f3524-16ee-416f-a298-67ec9f59650c',
    'identification_number': 108332438,
    'item_consumption_1': 13549,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1729.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a78a0ec-781a-4724-a919-1c40bf766e05',
    'identification_number': 46708154,
    'item_consumption_1': 8.5,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 202.73,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '18161c0e-63b2-4f00-a3a4-f13f235e2ab2',
    'identification_number': 70941378,
    'item_consumption_1': 827,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1251.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '775befe0-7181-4120-883f-12fa27f71520',
    'identification_number': 103877266,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '98811db2-ea22-4f78-bb91-9abd1f9caaf2',
    'identification_number': 95250670,
    'item_consumption_1': 224,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 95.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc6b277d-d1e3-4ea0-9c3d-00765d2c8ed3',
    'identification_number': 96336242,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-25.78",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'ac6afef2-4f34-4b59-8c8a-27e6ac184fad',
    'identification_number': 35963310,
    'item_consumption_1': 3122,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1327.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a6df15f-3eb5-4f2b-860f-6417ea7ab0c9',
    'identification_number': 93181914,
    'item_consumption_1': 260,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 101.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5d67b72-e0da-456c-b7ab-d58f220db790',
    'identification_number': 43908764,
    'item_consumption_1': 752,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 95.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88cae61a-4d8a-49a8-b265-a0f192b5a945',
    'identification_number': 99837188,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-146.94",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'be2d1edc-f5fb-43b3-b11c-6fb1b0ed6f32',
    'identification_number': 21633762,
    'item_consumption_1': 628,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 266.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff3840f7-ea41-497a-a8e4-117228211cc5',
    'identification_number': 103124098,
    'item_consumption_1': 3.37,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 80.39,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '158aad10-be3a-4d82-a570-fd652f8101b3',
    'identification_number': 34210334,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c03aa889-87c0-4c91-935f-71bc0353573f',
    'identification_number': 35825448,
    'item_consumption_1': 1242,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1878.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bcd993f2-be62-400b-88cc-8811bb33d72a',
    'identification_number': 56876815,
    'item_consumption_1': 1308,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 763.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1056620b-0fc1-4e9e-a38f-2c3a6b0d9a77',
    'identification_number': 27904660,
    'item_consumption_1': 73,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 28.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8e49d71b-f45c-4740-9da9-4ff284b08c94',
    'identification_number': 8669490,
    'item_consumption_1': 306,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 119.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f75f8af-3013-4fa3-a8d5-c02ac8657c6c',
    'identification_number': 30102901,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1029.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b4af1b3-62f5-42bb-bb3f-50cb56bdb473',
    'identification_number': 46708154,
    'item_consumption_1': 11345,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4222.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b87cd90-36b3-44ac-b603-fa06a45e6b87',
    'identification_number': 91494877,
    'item_consumption_1': 229,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 97.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '275cb224-88bf-4918-a310-67f4fb3d3924',
    'identification_number': 3078469,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-61.07",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '0f5b0dc1-59dd-458a-8591-4baca7017b99',
    'identification_number': 92171273,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f7b57aec-049b-42e3-8865-aa04f7923066',
    'identification_number': 74755897,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6d2baf48-6894-4195-8a89-98fc22c5c77f',
    'identification_number': 101729456,
    'item_consumption_1': 1200,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 509.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41795dd2-4811-4093-944a-a59fb0264527',
    'identification_number': 16916484,
    'item_consumption_1': 3530,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1495.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e71e3ca0-60df-4cc1-adbb-df007051b80f',
    'identification_number': 106851730,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.99,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a186934b-fd63-4030-8def-a94ba2c949a9',
    'identification_number': 75062046,
    'item_consumption_1': 11406,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4244.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10534379-0683-44c1-8c2e-fb031ef99ddc',
    'identification_number': 33799733,
    'item_consumption_1': 685,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 400.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ae97709-dbd8-452d-828e-5124060c3373',
    'identification_number': 56875592,
    'item_consumption_1': 45.11,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1312.07,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1aad27f3-4c56-43af-a34b-71ebd19fd5aa',
    'identification_number': 12021720,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 75.2,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'b7ac3970-8ef4-4190-abcf-46745428b101',
    'identification_number': 95555927,
    'item_consumption_1': 3831,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1625.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e636c52-0fdb-4186-a018-0388d2d972af',
    'identification_number': 27526690,
    'item_consumption_1': 2536,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 987.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b120d8c3-7847-4136-afa6-5284ae97d5bc',
    'identification_number': 55316883,
    'item_consumption_1': 17973,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6688.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1590098-05b3-4b65-9ee8-66e597206dc2',
    'identification_number': 105760510,
    'item_consumption_1': 574,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 243.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '792623f0-332f-448f-bfa3-791551f3ff7f',
    'identification_number': 18462154,
    'item_consumption_1': 2303,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 978.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36504382-f90a-41bd-90cb-9c0742cc9ab8',
    'identification_number': 16177401,
    'item_consumption_1': 624,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 473.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f9e4b4c-2c12-4306-8564-c05080e66aa7',
    'identification_number': 111372879,
    'item_consumption_1': 637,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 248.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06aad444-44ad-473a-8f2b-4f8de340f862',
    'identification_number': 106621610,
    'item_consumption_1': 1803,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 701.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4a12bb0-e4e9-4138-8650-ee61939490e4',
    'identification_number': 107936925,
    'item_consumption_1': 1867,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2824.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba0c97b6-a0ba-48e6-ba44-cdb71a0fce6c',
    'identification_number': 46343466,
    'item_consumption_1': 24.99,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 726.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '36432c11-e81f-47ae-99a8-e4edaa53e4ca',
    'identification_number': 109770846,
    'item_consumption_1': 323,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 137.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac77ff08-67ea-4013-8b9a-419cd20e032d',
    'identification_number': 36149730,
    'item_consumption_1': 58.79,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1709.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '95c0cb62-00ca-4bc1-a6f9-95bab8615a3f',
    'identification_number': 41640870,
    'item_consumption_1': 8070,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1030.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1745565-3745-4632-bf4f-dd718fe1d254',
    'identification_number': 47037318,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd43edcfc-6062-411e-a9d9-62a56bdba156',
    'identification_number': 65191404,
    'item_consumption_1': 1198,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 699.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bd3c97b-11a1-467e-8a6f-05c3044e43a5',
    'identification_number': 106035770,
    'item_consumption_1': 51,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bca679d7-c5ae-411d-bcad-e05ca8a6970d',
    'identification_number': 110977360,
    'item_consumption_1': 37.78,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2157.59,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'cce51092-2a88-4550-a305-da45740b6432',
    'identification_number': 100627188,
    'item_consumption_1': 21905,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8151.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07879a0e-f0b2-4dba-ba06-7c5ea14fe3f6',
    'identification_number': 41423690,
    'item_consumption_1': 447,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 189.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9a0d519-2572-4fb6-a6b9-da106a2cf0cb',
    'identification_number': 79007651,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2e049528-8046-4526-8c22-69866194480e',
    'identification_number': 98010751,
    'item_consumption_1': 8928,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3791.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4706a548-0d37-46a7-9742-89a9f03ff8d5',
    'identification_number': 95555927,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b2380422-fbaa-4143-a001-a92f7682c34d',
    'identification_number': 91840252,
    'item_consumption_1': 5046,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2140.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1dba17b-dc29-4bd8-bee8-0d1d0589986d',
    'identification_number': 13924613,
    'item_consumption_1': 3.28,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 153.62,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'd110fcff-fe1b-4a93-b18e-af80216fc704',
    'identification_number': 106713493,
    'item_consumption_1': 664,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 258.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f45b99e4-f85d-4a5b-aa2f-e2abf425d3bd',
    'identification_number': 23899166,
    'item_consumption_1': 5122,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 653.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0caedac-afaa-4730-a0d8-8c2c7c279012',
    'identification_number': 19293763,
    'item_consumption_1': 2469,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1049.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ccdcbf1-5573-474b-ba0f-1cea4c1d3261',
    'identification_number': 56026684,
    'item_consumption_1': 17.13,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 408.57,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '08578e69-1c83-44d0-82ac-dacd82fb8287',
    'identification_number': 30290635,
    'item_consumption_1': 1982,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 252.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c6b5947-fc14-4de0-8e88-fb8ef0f3c141',
    'identification_number': 14859424,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '83dd44e6-1b8a-45d6-ac0c-d8b760f4e3e7',
    'identification_number': 5811848,
    'item_consumption_1': 2075,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 810.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '151a1dad-81d8-4298-9a36-f53c5d43e554',
    'identification_number': 96849754,
    'item_consumption_1': 190,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 80.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6a437fb-7529-4090-aedc-b7b5e5d60cf5',
    'identification_number': 46343466,
    'item_consumption_1': 5.01,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 119.5,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '49ea3b87-ad4a-4c2d-8522-5d5f1f013693',
    'identification_number': 32627572,
    'item_consumption_1': 505.44,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 14701.19,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ba87d0b2-d618-4d58-b278-906a32a65c2e',
    'identification_number': 948748156,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '660c30d7-23a9-4e4b-830f-596b07cd4b58',
    'identification_number': 36878685,
    'item_consumption_1': 34406,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12804.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8abd1c2c-0788-49a6-815b-5df312b38fcf',
    'identification_number': 79702791,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a2e0ac1a-64ee-4b5b-b818-aee4dd8f383a',
    'identification_number': 9921273,
    'item_consumption_1': 67.37,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1959.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '063d7736-c4c5-4ced-81e7-1a300369bbb3',
    'identification_number': 923309628,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd10777a2-64bf-42bf-aa43-74fbfaf3dd08',
    'identification_number': 40701301,
    'item_consumption_1': 286948,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 106786.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0481199-c596-4943-a80e-1627be2b1ad1',
    'identification_number': 99102935,
    'item_consumption_1': 27838,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10359.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72cdd5b9-705a-4f30-b93d-9d99d9216359',
    'identification_number': 16105168,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b97ca61-e27d-4b35-bcc6-6017450ada0c',
    'identification_number': 46708154,
    'item_consumption_1': 1308,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1978.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c313471-3610-46e8-b97b-80c758ef8b53',
    'identification_number': 95224343,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a01e23ec-5f09-4457-a24a-1c13a4f3d930',
    'identification_number': 101040245,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f57e97eb-55b2-410b-981e-1ff4b1235296',
    'identification_number': 16093526,
    'item_consumption_1': 5946,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2517.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '418002c1-39b1-4ed4-b162-83548c9cedd1',
    'identification_number': 90790499,
    'item_consumption_1': 0.35,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 8.36,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8f5cf85a-233e-4d99-b597-71420ca62749',
    'identification_number': 96336242,
    'item_consumption_1': 514,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 200.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dca8f3ab-65a7-45c9-a23b-cbac42f052fa',
    'identification_number': 15686396,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a15faa4c-a0b6-4878-892d-b0a5af40c0b6',
    'identification_number': 92405967,
    'item_consumption_1': 4397,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1865.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df6b48e5-8d70-4494-8045-c31f45813226',
    'identification_number': 48482897,
    'item_consumption_1': 53.65,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1560.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c37b2b9a-4817-4479-b53f-98329efd531a',
    'identification_number': 39258777,
    'item_consumption_1': 3218,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1255.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7282082-32ee-4bfa-a445-8a4c9467624a',
    'identification_number': 17711754,
    'item_consumption_1': 272,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 106.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70be16b7-e21e-47b7-8480-6b5400ef37d3',
    'identification_number': 106731971,
    'item_consumption_1': 12319,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4812.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4251a45b-86d4-4488-842a-fdfaa25c7705',
    'identification_number': 88094030,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '81f8cd5c-1b77-4fb0-811b-e08e694e1c07',
    'identification_number': 98370200,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22793eb4-f441-4374-adbc-d1c4d31800bd',
    'identification_number': 102684650,
    'item_consumption_1': 2867,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1066.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65c55bce-4be3-4e71-9523-4afeae46bedd',
    'identification_number': 110411196,
    'item_consumption_1': 6197,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2306.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be785ebe-aa04-4a5e-9ddf-d92ea633a971',
    'identification_number': 45851654,
    'item_consumption_1': 4815,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2041.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38c4c2d9-b8f5-4f81-a65e-55dfc25c7dbf',
    'identification_number': 23135395,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-722.13",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'daaaae57-fc91-4ebc-aa9f-b931443cbefc',
    'identification_number': 94391645,
    'item_consumption_1': 112,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 65.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7942c19-555b-4793-87bc-cfee10e2a7cb',
    'identification_number': 87554968,
    'item_consumption_1': 1220,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 516.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f03cd65-eba7-4165-b557-b8c7f61074bc',
    'identification_number': 109086678,
    'item_consumption_1': 360,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 152.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4aa36286-eb60-41a2-9d06-6207970e2c7d',
    'identification_number': 18284507,
    'item_consumption_1': 1078,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 421.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64c22751-9cab-4c69-8ec9-bbeef10bded2',
    'identification_number': 15071987,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 5.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fcdc1015-6bd1-4f56-bd01-c4b55ff7f87b',
    'identification_number': 94743100,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-13.65",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'a880bc0e-b3ac-4f21-b054-2fafdcf9bac6',
    'identification_number': 87914808,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2d373c33-8c1e-4367-8e67-e35db9daccb0',
    'identification_number': 84238470,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'db992de2-de00-4573-895b-702e85ff813f',
    'identification_number': 52131718,
    'item_consumption_1': 61.79,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1797.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bb609f2f-6400-44b1-98ff-de0f017989a7',
    'identification_number': 32715811,
    'item_consumption_1': 9114,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1163.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31fb7add-e492-439c-9e57-9cfb38d21209',
    'identification_number': 14671638,
    'item_consumption_1': 5315,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1977.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '977a82c8-a853-4fa3-8222-45720ce5f4f6',
    'identification_number': 35559217,
    'item_consumption_1': 135.59,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 7743.52,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1d2c8225-3e59-4989-b32b-488500ebf6fe',
    'identification_number': 13372688,
    'item_consumption_1': 12440,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1587.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1aa9e43a-59df-4cf8-8d91-43cfc07795dd',
    'identification_number': 11013958,
    'item_consumption_1': 24844,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3170.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b539f248-f396-48da-80f5-5f60eebca7e5',
    'identification_number': 110200080,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 126.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '52c3152b-a90f-4a1a-bfb6-db1220e3872e',
    'identification_number': 19304749,
    'item_consumption_1': 1682,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 713.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48151056-b42f-430e-aa7d-4f6f706507a8',
    'identification_number': 45340340,
    'item_consumption_1': 11911,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1520.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47b38cc6-cc12-4124-8968-bbd3a82e7643',
    'identification_number': 58739149,
    'item_consumption_1': 28,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 10.94,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1c1f2313-3a77-4ec7-beef-ccdb4ed97fba',
    'identification_number': 94450145,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 18.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4bef7a82-77d1-410c-b994-c4aa1c2ccd4f',
    'identification_number': 19299281,
    'item_consumption_1': 249,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 105.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b31e990c-4372-4d83-8797-2549f27ec23e',
    'identification_number': 33676208,
    'item_consumption_1': 2840,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1658.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aee2c4b9-61ec-42d9-a331-f881c63bc5a0',
    'identification_number': 7284012,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f6086e0c-5082-4c05-99ba-6f9126278582',
    'identification_number': 86283154,
    'item_consumption_1': 4008,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1491.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '810fb6e5-0879-4dd2-a338-3de113318680',
    'identification_number': 8708320,
    'item_consumption_1': "'-2.577",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 09/2023',
    'item_total_value_1': "'-823.94",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '1f796b3c-c1d0-4996-9de8-2b22684993d4',
    'identification_number': 94871400,
    'item_consumption_1': 6251,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2646.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa28acf6-2362-4e82-b243-beaa25e44465',
    'identification_number': 79937667,
    'item_consumption_1': 3.73,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 174.7,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4cedc010-c6b5-4bfc-8bd7-fde60aeef751',
    'identification_number': 68081251,
    'item_consumption_1': 1906,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2883.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0aa22fd-d33b-48cc-88d2-c862529dacb3',
    'identification_number': 17578582,
    'item_consumption_1': 136,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 53.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae495c42-e567-4af3-9102-fc37a2d78fc7',
    'identification_number': 105953261,
    'item_consumption_1': 17173,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2191.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33c0f221-8194-4654-865b-34ca4fe9ba6b',
    'identification_number': 34739157,
    'item_consumption_1': 1694,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 717.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1831dde8-c5fc-4a9c-aa05-8723b5150f5b',
    'identification_number': 103876502,
    'item_consumption_1': 14887,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5540.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f05e0b7c-7ee6-410b-8912-9df55341c2e1',
    'identification_number': 39214800,
    'item_consumption_1': 25.28,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 735.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c9c0d6fb-19ed-40ff-a4cf-3abbe877ce1a',
    'identification_number': 94624038,
    'item_consumption_1': 666,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 259.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4907435c-1f48-47cb-b919-278fcabeed3e',
    'identification_number': 108940128,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22e0cdb0-2d08-4ab1-804b-c36fb6f1c594',
    'identification_number': 66244013,
    'item_consumption_1': 42.2,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1006.52,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '90743bb9-af69-4c30-a300-6b45002a5f1b',
    'identification_number': 42627117,
    'item_consumption_1': 58.2,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1692.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dc0b6574-a284-4a5e-92e4-2e006ff106b1',
    'identification_number': 13846817,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be876c36-67f6-41eb-aab8-12794db75b66',
    'identification_number': 104179228,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 25.42,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bb7e8d4b-c33f-41bc-b3c8-9a9d6e3179a2',
    'identification_number': 13977873,
    'item_consumption_1': 20.02,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1143.34,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2cfaa692-57c1-41fd-8766-410d81d6d027',
    'identification_number': 47074965,
    'item_consumption_1': 18718,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7951.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'abc37ec1-418f-4f26-8aad-ab830160408a',
    'identification_number': 99447495,
    'item_consumption_1': 899,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 381.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fd388d9-5d62-473e-9e45-e06a9adcc70e',
    'identification_number': 27316114,
    'item_consumption_1': 145.18,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 8291.22,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5a220c45-cebd-414d-8d2d-59849efa3cdd',
    'identification_number': 80201555,
    'item_consumption_1': 142,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 55.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '69384e32-3ae6-48bb-9e0a-c46940e55783',
    'identification_number': 101940122,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2347480e-cd46-4092-9612-21975a0a1bea',
    'identification_number': 102436053,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b99e3764-46f9-4ee3-bbb5-14aba32854b0',
    'identification_number': 33651868,
    'item_consumption_1': 13127,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5567.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76045077-e021-43e2-a861-6aba2525eb27',
    'identification_number': 111221153,
    'item_consumption_1': 107,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 41.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c433c9e-1c72-4844-a0d1-3b5bd1dfdb21',
    'identification_number': 107845490,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ab22f28-a935-40c8-9d99-aadf8cf379f2',
    'identification_number': 80508820,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eefb8522-48f3-4be3-8f18-f8b7c213764f',
    'identification_number': 108298035,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 892.98,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4f64bf31-b707-4007-a546-970925cd1e4e',
    'identification_number': 94172170,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 109.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '221bf6a3-ad38-40e6-a744-d41d8d2c53a2',
    'identification_number': 87333597,
    'item_consumption_1': 5717,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2232.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87f9b4de-b97e-4a50-8bac-4d2a6835edcb',
    'identification_number': 34617418,
    'item_consumption_1': 1803,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2727.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '051b56cc-5ef5-4c3c-b4da-de7d47b216df',
    'identification_number': 95839267,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '863e4baf-cb44-4f52-9225-8539946c15dd',
    'identification_number': 69613206,
    'item_consumption_1': 1353,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 527.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73454827-1583-4d52-b7ef-855ba1979753',
    'identification_number': 81267843,
    'item_consumption_1': 579,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 73.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c80f0d0e-3d7c-4751-83b1-e678207e35ef',
    'identification_number': 36222852,
    'item_consumption_1': 1682,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2544.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11491b92-a54d-45eb-b2a5-4240042eb56a',
    'identification_number': 61792098,
    'item_consumption_1': 2781,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1624.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da7425ad-c690-4d1e-8cf6-f4677c07be62',
    'identification_number': 106643517,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5cfcaf20-a56c-4e52-8cc0-3c51eb60c6d3',
    'identification_number': 40032060,
    'item_consumption_1': 5790,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3381.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43431002-a955-4f03-9b9d-d701a0718b60',
    'identification_number': 93680180,
    'item_consumption_1': 10936,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4266.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c84c31c3-e0ee-417f-89d9-0c1eda33522a',
    'identification_number': 81267843,
    'item_consumption_1': 0.43,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 20.15,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '6071e239-7d6f-4af8-bbad-49ce42712fce',
    'identification_number': 28166159,
    'item_consumption_1': 45354,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16878.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '025a2b8c-c58b-4fd9-b1ee-0b61e7d61aba',
    'identification_number': 108023265,
    'item_consumption_1': 1278,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 541.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8834d27-73fa-4c1f-95cf-48e0a33c3202',
    'identification_number': 67917283,
    'item_consumption_1': 45789,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 17040.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5437d16-c68a-4c5c-b9f5-8971eb21d16b',
    'identification_number': 49666711,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad4ce832-f14f-4f9c-ba33-d74f8b46cb81',
    'identification_number': 28259513,
    'item_consumption_1': 1399,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 178.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6977ae50-8d3c-4f18-9934-ff70c5e7b386',
    'identification_number': 96032073,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23dec354-5951-40ac-8a0e-87a3cea659d7',
    'identification_number': 60602643,
    'item_consumption_1': 5468,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 697.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36bafebd-ae33-46a8-835b-450c7de65783',
    'identification_number': 99778106,
    'item_consumption_1': 1325,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 562.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a0dca2e-da4e-458f-a4d4-5c0f8b91d24c',
    'identification_number': 46343466,
    'item_consumption_1': 778,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1176.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81e9f85c-76d5-44d4-abcf-01e3e69f57cd',
    'identification_number': 98913972,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3f854893-163b-4c16-b112-ff28393e5300',
    'identification_number': 96125225,
    'item_consumption_1': 2917,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4412.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5dfe9e4f-097a-4f9d-827f-e25a722344ee',
    'identification_number': 17970806,
    'item_consumption_1': 17.73,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 515.68,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b1b01f3b-478c-4e27-9a72-cdc13625a48a',
    'identification_number': 34216170,
    'item_consumption_1': 2375,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1008.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd98b16c6-ba2b-4590-8779-5174cd7e9339',
    'identification_number': 33676208,
    'item_consumption_1': 1.57,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 37.47,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '52ca24bc-fa5b-47cb-b36b-138dd1f73e38',
    'identification_number': 10267832,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENT (02)',
    'item_total_value_1': "'-3637.5",
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': 'b5a092ca-5aea-47da-a186-91c082173abf',
    'identification_number': 50940694,
    'item_consumption_1': 8521,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3171.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6cacc43-124a-4c92-9230-e41629ba1f40',
    'identification_number': 9992820,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 101.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b1067328-0e21-48fa-89cc-97e95e19ce3b',
    'identification_number': 91057728,
    'item_consumption_1': 2764,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1169.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dca98e0c-daa4-4c06-a566-11b84b3feb15',
    'identification_number': 42709717,
    'item_consumption_1': 30.59,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 889.73,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '86e6c511-1b98-4013-a872-489c266cd9e4',
    'identification_number': 101540620,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e5a7b6ee-50a7-4a04-9c42-4defd48cb2aa',
    'identification_number': 68782624,
    'item_consumption_1': 159,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 62.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8583c5b-7c2e-4642-9cde-4b8b6bfb8281',
    'identification_number': 111113792,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e6225cd-6a4e-4dec-a022-cf8160c09c55',
    'identification_number': 32500459,
    'item_consumption_1': 11184,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1427.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25251f73-63f9-4ff8-8476-ad611ce7feac',
    'identification_number': 100365302,
    'item_consumption_1': 480,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 203.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e055ad26-21dc-462d-b9a2-b8e622f4ceba',
    'identification_number': 44502931,
    'item_consumption_1': 143.86,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4184.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '16b1c5b4-4847-4199-87fb-f549176bda75',
    'identification_number': 80393306,
    'item_consumption_1': 5150,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2014.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9552d19-f070-4c57-8723-cf081e1d3ae3',
    'identification_number': 12255351,
    'item_consumption_1': 4018,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1570.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6df3f54b-bec3-4a3b-b035-e2c8e3aeec76',
    'identification_number': 102362378,
    'item_consumption_1': 13816,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1763.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c8d6fa7-65a9-4012-a4cb-0a8706e5b15b',
    'identification_number': 6192319,
    'item_consumption_1': 1185,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 502.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58b7f678-f900-40b0-9b3e-dc241e8309ab',
    'identification_number': 59355573,
    'item_consumption_1': 387,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 226.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99d8ff0c-44a4-4ac8-ac2b-c0bc5569dd85',
    'identification_number': 17182069,
    'item_consumption_1': 357,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 139.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1213be9-7e0d-48fc-93a9-317c0bcd4d38',
    'identification_number': 74880462,
    'item_consumption_1': 14324,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5330.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ad548a6-7e8b-4255-9305-f60fcbf3dd93',
    'identification_number': 74865145,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12391.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e55e4130-13d9-4262-9f48-14edf4aae412',
    'identification_number': 13028855,
    'item_consumption_1': 27.94,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1595.64,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f3b1088c-90d0-41a4-8ab2-ecfbdda04870',
    'identification_number': 48042994,
    'item_consumption_1': 6809,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2885.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4080b0dd-d2fb-4263-994d-7c4ca119010d',
    'identification_number': 2727684,
    'item_consumption_1': 3609,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1528.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d6dfae5-ae89-46ea-9124-638cf4d6ee1b',
    'identification_number': 41279263,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f0c8d0f-8356-43f0-900b-caae7370c02e',
    'identification_number': 16951662,
    'item_consumption_1': 25.43,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 739.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6890a6e0-6af8-48b8-b71a-4e1cb49e8834',
    'identification_number': 8782571,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '96e4665f-18b0-469a-88ca-fec6eac87362',
    'identification_number': 15091520,
    'item_consumption_1': 8481,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1082.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72b41266-8c65-4928-a034-0f24c22c22e7',
    'identification_number': 68447450,
    'item_consumption_1': 930,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 118.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c0560f9-b2dc-4adb-ade7-03c21357a118',
    'identification_number': 105799653,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 143.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cdd50eb7-f683-4a14-a9b8-5daf8918c2db',
    'identification_number': 15002578,
    'item_consumption_1': 5176,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7830.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '028f1df8-b889-443c-8be4-523e11c9f081',
    'identification_number': 99540819,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11cd449e-5d10-4495-8088-b6c1f45ee568',
    'identification_number': 30085900,
    'item_consumption_1': 10432,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3882.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c9a0c85-d69c-4ad2-a71c-6af703fea71a',
    'identification_number': 19304722,
    'item_consumption_1': 4660,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1818.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8298b937-e98f-4749-b57b-edf3730c7b19',
    'identification_number': 98624792,
    'item_consumption_1': 11.11,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 323.13,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '198b48be-f9bd-42e2-ba13-dbd173b274a6',
    'identification_number': 108167879,
    'item_consumption_1': 32956,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12264.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88e05ed8-7f5b-4c3d-93cf-3164237d7b49',
    'identification_number': 106708120,
    'item_consumption_1': 2991,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4524.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d65a2b8-acf1-4b7e-97e6-4521244f406a',
    'identification_number': 17401682,
    'item_consumption_1': 14.68,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 350.13,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'cc43d320-4a3c-487a-8b29-b63e88a83465',
    'identification_number': 16125320,
    'item_consumption_1': 52,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 979.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b3f17397-90ed-4ee0-b260-425b0483e86d',
    'identification_number': 59820535,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '46d1e0de-f02c-4b32-88f6-8524878bcbb3',
    'identification_number': 103704728,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e22eb42c-3f3c-4743-8a68-db02bd020bfe',
    'identification_number': 85312185,
    'item_consumption_1': 2232,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1303.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a3b0de7-ff79-434c-a51b-3842bca3c2b0',
    'identification_number': 73822671,
    'item_consumption_1': 9805,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1251.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1941d62-d136-4fa5-8b12-9bcd29d2a04d',
    'identification_number': 104652780,
    'item_consumption_1': 685,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 289.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6f216e5-02c5-49e8-b23b-0244352e319f',
    'identification_number': 91801435,
    'item_consumption_1': 222,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 86.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9897242e-78f4-4aa8-9f42-84a993961d66',
    'identification_number': 109859154,
    'item_consumption_1': 1654,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 646.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd1a9f5d-1977-4da8-8730-f9b46ec781aa',
    'identification_number': 26343592,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 63.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9883b83d-0d36-4fba-9cfa-558587b28d06',
    'identification_number': 107539012,
    'item_consumption_1': 190,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 74.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12741dbd-af54-48b0-a745-64bd99d5a1e9',
    'identification_number': 12673544,
    'item_consumption_1': 2639,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1541.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56848833-2a80-4288-84d0-256923fb7274',
    'identification_number': 91892287,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '36a3cd70-23a3-4ebf-9bf9-1a1178197f11',
    'identification_number': 30015570,
    'item_consumption_1': 2312,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 901.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49b5daf8-6023-4803-98cc-5b98070af41a',
    'identification_number': 104652780,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd8deb9eb-4873-4901-8404-8572e34dec51',
    'identification_number': 32264615,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7637a5e9-716e-445d-aea6-22c0b797dcc8',
    'identification_number': 96409312,
    'item_consumption_1': 1776,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 752.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3bb905f3-3d9e-4fdf-97c2-c2ea11ff8f53',
    'identification_number': 15507971,
    'item_consumption_1': 4534,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1925.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c07e99d-1215-462f-869b-748818d9d449',
    'identification_number': 15719464,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 113.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3352c14c-0595-4a6f-831b-42826b755b96',
    'identification_number': 101575220,
    'item_consumption_1': 2820,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1196.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3cec673d-3848-4bda-a7ed-ac75d9ecabf1',
    'identification_number': 29692377,
    'item_consumption_1': 256,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 108.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14caca89-59d2-4aaa-9762-e9af4a9cafeb',
    'identification_number': 20559755,
    'item_consumption_1': 1111,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 648.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbfbc860-ded8-450d-9646-a6d150d0a938',
    'identification_number': 59437650,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea3b61d7-a4a2-4690-b8ca-90afd907f0ab',
    'identification_number': 105760510,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.97,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '52ef381d-7fbd-40c3-bcff-8ff933411e6c',
    'identification_number': 14075423,
    'item_consumption_1': 278,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 108.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54202815-2d1e-4e11-80ea-dc5b9f7c407f',
    'identification_number': 109487826,
    'item_consumption_1': 1333,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 519.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '457c34ff-2ce1-41e9-893f-1945827a2a70',
    'identification_number': 94724741,
    'item_consumption_1': 40.49,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1177.68,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bcba5dc9-84e0-435c-bbe2-24a5546753af',
    'identification_number': 10391746,
    'item_consumption_1': 1483,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 629.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '153e1287-15d9-4448-9eae-b5ae51e5c4b8',
    'identification_number': 97072494,
    'item_consumption_1': 5088,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1893.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5a0db71-58d5-4679-bd03-25422fb33397',
    'identification_number': 46708154,
    'item_consumption_1': 1308,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 763.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96b936c6-5f38-4ce3-b06f-3a88343b4a2e',
    'identification_number': 37612220,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 68.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '19a148f2-bddb-4195-93dc-7fe7d856c2eb',
    'identification_number': 106814427,
    'item_consumption_1': 4156,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 530.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68a87324-9335-45e6-b5ea-43f56aae9544',
    'identification_number': 14152037,
    'item_consumption_1': 283,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 120.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99529682-f514-4572-b5ed-65aa5a5f56fc',
    'identification_number': 100854982,
    'item_consumption_1': 12974,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4828.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f780c57a-f1d1-4272-97ee-fc93251ee6d9',
    'identification_number': 32845570,
    'item_consumption_1': 2209,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1290.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34705852-dbc2-4c0e-8918-25a06597993b',
    'identification_number': 65485416,
    'item_consumption_1': 8593,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1096.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b6f1490-6acd-40d1-afd0-75e860e1b731',
    'identification_number': 61773409,
    'item_consumption_1': 1248,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 528.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3158bba9-b6be-45cc-ac44-d0d394889a00',
    'identification_number': 72777320,
    'item_consumption_1': 11961,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1526.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb934275-4dc1-40e7-b320-78f7f6001943',
    'identification_number': 102512426,
    'item_consumption_1': 5660,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 722.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '902ae825-7c53-40bd-bbe5-3758a955bad1',
    'identification_number': 102985693,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bb05c840-c067-4092-83af-e529fab92f27',
    'identification_number': 13299573,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f15cf41-14b4-48d5-a845-13d3aca893e7',
    'identification_number': 101068484,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2e328be3-32a9-4e2a-b9a2-65b6f78a6721',
    'identification_number': 77791428,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71f8e4a6-81be-4363-ae0c-12d8470cd0c9',
    'identification_number': 44502931,
    'item_consumption_1': 549,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 214.8,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bf47fb3e-bd1d-4681-9c22-5917dd33efab',
    'identification_number': 102625093,
    'item_consumption_1': 3254,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1381.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea67b722-12d7-4977-873e-3b6efd1949ff',
    'identification_number': 14135795,
    'item_consumption_1': 1098,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 428.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b57c38eb-186e-47dd-bfa9-63a9dd9301c2',
    'identification_number': 29891833,
    'item_consumption_1': 21.1,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 613.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '98377287-0809-4eb6-8c37-95ba8405fb4c',
    'identification_number': 98798510,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '46064ae4-7f30-41f8-9c3d-b556876baf12',
    'identification_number': 43658954,
    'item_consumption_1': 477,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 186.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8f9a34e-77ff-43aa-93d2-b353f189499a',
    'identification_number': 29984971,
    'item_consumption_1': 65.09,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1893.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e85acc4f-5f82-49ae-98fc-0b4d3e8f8d43',
    'identification_number': 47599596,
    'item_consumption_1': 1158,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 491.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e78b9968-9905-4c50-bdcd-1d8bcb560b5d',
    'identification_number': 99959739,
    'item_consumption_1': 42201,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15704.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9dedb94c-3038-4136-9a78-5cb7e62fa9f2',
    'identification_number': 42404576,
    'item_consumption_1': 31992,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11905.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00d93549-2ca5-4078-a151-7640deae372e',
    'identification_number': 109545907,
    'item_consumption_1': 2590,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1512.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d08b2aa-2ec9-4629-92ea-4ecbc190c732',
    'identification_number': 87227509,
    'item_consumption_1': 5599,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2378.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c223d404-ec47-476e-919a-4b0395906fe1',
    'identification_number': 41640870,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '27aeb554-83be-4025-8628-b646bbe070eb',
    'identification_number': 106643517,
    'item_consumption_1': 60.87,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1451.81,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f7dee29f-c831-4662-b739-da74df798892',
    'identification_number': 53371127,
    'item_consumption_1': 30.59,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1779.47,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7fcb01d8-c71e-4cf9-bf73-5627d6425cf1',
    'identification_number': 106706632,
    'item_consumption_1': 9645,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3589.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b13400df-e133-4e10-a87d-f6e673a59ba6',
    'identification_number': 110907760,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c42c29bf-529f-44ab-a9c2-a9eae93a66f6',
    'identification_number': 103752234,
    'item_consumption_1': 23125,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2951.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a7f310c-98cc-40a3-83c2-e70bd4e0602e',
    'identification_number': 100990924,
    'item_consumption_1': 4120,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1748.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35198d72-8624-4d2e-b257-fbb870157c34',
    'identification_number': 16307836,
    'item_consumption_1': 23724,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3028.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a941858d-2d5c-4a03-9550-bd27799f1ef4',
    'identification_number': 92912290,
    'item_consumption_1': 986,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 385.79,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4ba58d5d-3186-480f-a05e-cc596629278a',
    'identification_number': 11346221,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 0.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42de101c-4fe6-495c-b0fe-1d3c7a8836bd',
    'identification_number': 56708890,
    'item_consumption_1': 1910,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1115.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '541c6473-c165-4615-b159-4009f90a09df',
    'identification_number': 40709140,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '05c2c69e-3c08-4afa-ab80-a1ad26f54c90',
    'identification_number': 10610464,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cb88b5f3-953a-40e1-8680-498ad8813781',
    'identification_number': 18798594,
    'item_consumption_1': 17639,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2251.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e4e07dd-dd7c-402d-9939-4ef7b8395eb8',
    'identification_number': 9921273,
    'item_consumption_1': 7.63,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 181.98,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '98c67da5-5a47-4c09-9e49-5dc1c29fb16e',
    'identification_number': 36235334,
    'item_consumption_1': 2346,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3549.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4672d7d-f61f-42d8-bedc-74aa52da34ec',
    'identification_number': 94450145,
    'item_consumption_1': 5077,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1978.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f616e960-0b4a-40a9-be03-43f38c9d1521',
    'identification_number': 52282040,
    'item_consumption_1': 353,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 149.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14c54074-c320-43fc-aaec-7aa5eb001631',
    'identification_number': 94460566,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 18.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '19f260df-5f00-4d3b-bc12-9a97969cac46',
    'identification_number': 31997651,
    'item_consumption_1': 3490,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1480.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f9dd4d0-0765-4432-87e1-7c4fc96aba67',
    'identification_number': 95618082,
    'item_consumption_1': 642,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 272.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e25651d-7792-44ad-ad18-8b28e3171513',
    'identification_number': 31705901,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c84aa757-5276-4883-80fc-69cdcc8cff30',
    'identification_number': 19281552,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '47410c55-6b0a-47e6-97d4-ff8517323d10',
    'identification_number': 96174137,
    'item_consumption_1': 699,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 296.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '239254ac-f2d8-4acf-abf9-61ffe59728c2',
    'identification_number': 97675237,
    'item_consumption_1': 244,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 103.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed6bd268-d9f2-461b-840d-8a234111d089',
    'identification_number': 98925083,
    'item_consumption_1': 129.39,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3763.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fdda0c0e-1f82-4879-9aed-41a328f05e6b',
    'identification_number': 27516440,
    'item_consumption_1': 829,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 351.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3895866-d7d9-4c32-9432-abc3c943cde5',
    'identification_number': 58325549,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2387e2db-25aa-4a34-83e0-aebe24a76bc1',
    'identification_number': 108309835,
    'item_consumption_1': 36.64,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 873.89,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e2b6d26e-ad46-4a3f-a9e3-5e97873786cb',
    'identification_number': 56526580,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '366e6c5d-d592-4232-85c7-7a136ddd1f47',
    'identification_number': 105816280,
    'item_consumption_1': 67983,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 25299.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f3980a3-1eb7-4294-a002-4ce8776a6784',
    'identification_number': 18798594,
    'item_consumption_1': 1953,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2954.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6cdd01ef-0a9e-465c-9640-06bd2ce32e3d',
    'identification_number': 73564630,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '335735a2-cc4d-4b30-9776-781ee358287e',
    'identification_number': 19755511,
    'item_consumption_1': 1291,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 504.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd72572d6-8a0a-49fc-b2da-58d719e61973',
    'identification_number': 29677459,
    'item_consumption_1': 1678,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 712.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5642b0da-10b5-41de-aab0-b4bf5e1f2988',
    'identification_number': 16877454,
    'item_consumption_1': 2680,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1047.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25addd23-dca7-4a11-b071-6c5ae84a0a48',
    'identification_number': 93308965,
    'item_consumption_1': "'-7.09",
    'item_description_1': 'ENERGIA INJ. FP OUC MPT TUSD 01/2',
    'item_total_value_1': "'-742.05",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'b9844803-6e5a-4a58-90c9-bed96932307b',
    'identification_number': 104483083,
    'item_consumption_1': 884,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 345.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed5cbb50-c97a-401d-afc3-e6db7833f9b6',
    'identification_number': 99705257,
    'item_consumption_1': 497,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 193.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99c2fc90-dbae-4ec6-b40a-67108beeb5bd',
    'identification_number': 29896975,
    'item_consumption_1': 73,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 28.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fce84c64-5e12-435e-be51-7a4e6a0d78e4',
    'identification_number': 94384126,
    'item_consumption_1': "'-1.89",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-605.46",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '3d19716c-759f-4ab5-a900-10b3620e9054',
    'identification_number': 88478319,
    'item_consumption_1': 67,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 28.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8b507e4-6b2c-48a4-b25c-87d961b44045',
    'identification_number': 72920467,
    'item_consumption_1': 4904,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1913.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b4cfe88-c740-44cb-8c65-1a15774be32e',
    'identification_number': 20476183,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 278.96,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4dc422b5-e035-4b93-92df-4d059398b802',
    'identification_number': 97637238,
    'item_consumption_1': 1277,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 745.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfcbeb6c-cd81-4e47-8910-c69c9a81695a',
    'identification_number': 108048110,
    'item_consumption_1': 239,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 93.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97db146b-c3ae-4c1e-be95-a80ee626999c',
    'identification_number': 100797113,
    'item_consumption_1': 35.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1033.1,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a03b6233-448d-4507-b55f-62fc0c019b81',
    'identification_number': 14138069,
    'item_consumption_1': 55.05,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1601.17,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '484e7e38-b54f-49e6-99f1-b31d3fc43e2b',
    'identification_number': 41971817,
    'item_consumption_1': 4.09,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 97.56,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f5ad121f-9dbb-4be5-8f37-d137f7fde932',
    'identification_number': 49620622,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '869eaca1-8f1c-4586-a13e-cffecdc7fb39',
    'identification_number': 14859513,
    'item_consumption_1': 33627,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4292.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59fe62db-7e1a-44c9-add3-762d902a1d0f',
    'identification_number': 101420625,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0cff4d67-e2c2-48c1-93c8-4fae2867eb22',
    'identification_number': 29891833,
    'item_consumption_1': 15,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 5.86,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '02a15e4a-0139-4674-9eeb-ff4a016ca2cc',
    'identification_number': 94428506,
    'item_consumption_1': 3002,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1276.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '288d0e8e-d7f8-4414-aa45-ad02b237ccc5',
    'identification_number': 12673650,
    'item_consumption_1': 4359,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2545.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd16d970-3382-4c18-a74e-c1c360a3f429',
    'identification_number': 19450133,
    'item_consumption_1': 4873,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7371.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '257a75d7-a84b-4248-b6a0-90750c1e8cb6',
    'identification_number': 16570936,
    'item_consumption_1': 29056,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10813.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd2594fc-8e7b-4c34-b3af-9f99a35497b5',
    'identification_number': 10318453,
    'item_consumption_1': 2033,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 793.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6c2da28-5dc0-4b9b-8253-a390d80c37ea',
    'identification_number': 93009470,
    'item_consumption_1': 3864,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 493.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d302915-b90a-427f-93c8-d2ab5fded03d',
    'identification_number': 101912331,
    'item_consumption_1': 631,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 954.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5fb587f-ed53-45fb-becb-cfe77e9fa972',
    'identification_number': 110994906,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1c96d59a-cc8f-4c13-a872-f4688419e801',
    'identification_number': 10361472,
    'item_consumption_1': 12092,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5122.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc12bf48-b53d-4204-b491-7fb2fc9248ca',
    'identification_number': 59541288,
    'item_consumption_1': 20.46,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 487.99,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '53ca9525-d686-4984-ad43-2added67f263',
    'identification_number': 19452934,
    'item_consumption_1': 114,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 44.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f7e2698c-ff89-4c50-8851-69e6b179c563',
    'identification_number': 16612183,
    'item_consumption_1': 2241,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 950.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d687007-211c-4961-80ce-fd4d74506c8b',
    'identification_number': 61389617,
    'item_consumption_1': 436,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 184.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9bd999a-d514-42e6-83ba-4ed0572acd6d',
    'identification_number': 98463888,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dc584509-ba4a-4033-b438-9d1ac47e39d3',
    'identification_number': 94650977,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '08a13c35-ed0c-4c3d-9930-db61afea25bc',
    'identification_number': 22731530,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 75.2,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '80c60b7d-8ae6-4261-a799-c4ae7d757a07',
    'identification_number': 42302617,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 152.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'deb45253-2c71-41b1-b52d-c368c76863b2',
    'identification_number': 62515438,
    'item_consumption_1': 365.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 10630.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9abd78bb-13d9-4372-8f07-7d440598973d',
    'identification_number': 15981401,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6baad160-68c5-4cc2-b132-1690d2fd551b',
    'identification_number': 51301563,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '32d0ac26-f8e3-4da0-8723-35b2720bc9c9',
    'identification_number': 75064430,
    'item_consumption_1': 1550,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2344.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a307f5f4-7e0c-45c3-b2d1-e1982a0ac09c',
    'identification_number': 19875460,
    'item_consumption_1': 868,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 339.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f03423f-3829-43e4-804a-8dd79018c2ea',
    'identification_number': 110411196,
    'item_consumption_1': 39,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 58.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dab3bde0-cace-4973-96f1-5583ca2ace26',
    'identification_number': 109089553,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6450330f-ca05-43f1-8164-d29bb889f8f1',
    'identification_number': 102159459,
    'item_consumption_1': 12683,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4719.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c1ee079-ab52-4287-bb47-8a40efa41bea',
    'identification_number': 10781862,
    'item_consumption_1': 463,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 181.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c6aa78d-a0da-49da-9696-028f2a9742bc',
    'identification_number': 63006472,
    'item_consumption_1': 4639,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1812.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0eb62a0f-6983-447b-9dec-021f94e9f9bc',
    'identification_number': 106077406,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69d5741c-e87f-4a35-a0b4-308b49bf874b',
    'identification_number': 35766514,
    'item_consumption_1': 30.82,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 896.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f2f700fa-829e-4586-81b8-2f544706c653',
    'identification_number': 99929929,
    'item_consumption_1': 16334,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6934.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89240e9a-f26e-4b61-b91e-dbe9df9f6da3',
    'identification_number': 14619563,
    'item_consumption_1': 4.21,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 197.16,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '28afc19d-c679-4452-a5cc-c2320826c8a6',
    'identification_number': 102507864,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1426a1ab-7a18-4809-9ad6-b4237b4d167f',
    'identification_number': 35322101,
    'item_consumption_1': 177332,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 22634,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc7a82c4-7ab0-4292-9205-31bb27469800',
    'identification_number': 11679751,
    'item_consumption_1': 33575,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12494.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ea0b0e7-0407-4b54-8e75-d8ecb5e79c21',
    'identification_number': 96254378,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ae00ab7-64ce-4c48-aeca-bd568905dd77',
    'identification_number': 91490170,
    'item_consumption_1': 179,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 69.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b126e71-9b15-4a06-8ce1-61651e3db8d9',
    'identification_number': 17633419,
    'item_consumption_1': 3151,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1336.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4359b98e-b42d-4cb8-95f3-0370cca2bed3',
    'identification_number': 8899738,
    'item_consumption_1': "'-13.478",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-4319.33",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '674f5d7b-a63d-4075-8e86-cc67ed20d240',
    'identification_number': 108669009,
    'item_consumption_1': 2390,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1012.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5af6889c-ef8f-4dce-a308-76112d39c9ee',
    'identification_number': 79545793,
    'item_consumption_1': 26442,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3374.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89999aa8-5f5b-47e2-b1dd-541329a365ba',
    'identification_number': 62915401,
    'item_consumption_1': 249,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 105.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e59ecfb5-37ee-40ac-8fd8-11b5ad8e59cf',
    'identification_number': 82521310,
    'item_consumption_1': "'-10.54",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 07/2023',
    'item_total_value_1': "'-3370.52",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '6619bea5-c285-4e3a-a283-d046e2acbd9f',
    'identification_number': 103852468,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '971de43a-0277-41f7-9ffd-3a2227a4305c',
    'identification_number': 34810986,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88370cfc-a238-4180-8e66-c8920d838155',
    'identification_number': 3032256118,
    'item_consumption_1': 3223,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 961.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2463506-69d2-4ec7-bfda-56158a8cc472',
    'identification_number': 106607057,
    'item_consumption_1': 1195,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 465.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '077544d6-80c1-4f40-ab2a-f13cd3e2a002',
    'identification_number': 32727917,
    'item_consumption_1': 7143,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4171.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0bc5d55-277a-4680-b60c-5395ffb82970',
    'identification_number': 75192900,
    'item_consumption_1': 13631,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5072.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2aa2aead-46d2-423c-ab31-75477444bd6a',
    'identification_number': 74865145,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1585.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '07c278d8-1a55-4193-b16c-b0ac466fdc3d',
    'identification_number': 111658004,
    'item_consumption_1': 1,
    'item_description_1': 'SERVICO DE VISTORIA DE UC',
    'item_total_value_1': 25.73,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '4a4f1cbd-297b-4e5c-90a7-ad2358a430d2',
    'identification_number': 98253409,
    'item_consumption_1': 438,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 171.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ac9a109-94d1-4a70-b8d8-6d1de57c3af7',
    'identification_number': 97368415,
    'item_consumption_1': 210,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 82.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cec028b6-cb1a-4c97-873d-e54a3b99f079',
    'identification_number': 22357890,
    'item_consumption_1': 5788,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2255.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2f2c91d-ae91-4480-9c91-1e8a3871c80e',
    'identification_number': 91840252,
    'item_consumption_1': 5046,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1969.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4fd0384e-ef0a-4bfd-b39a-583667a43223',
    'identification_number': 52358259,
    'item_consumption_1': 659,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 996.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18cac440-ae33-4225-9102-742dbec503d4',
    'identification_number': 935258912,
    'item_consumption_1': 1092,
    'item_description_1': 'Demanda Potência Não Consumida Ponta',
    'item_total_value_1': 27589.27,
    'reference_month': '11/2023',
    'referente': 'Demanda não consumida',
  },
  {
    'uuid_items': '851bc77b-d4fd-4521-ae9a-668707f3a446',
    'identification_number': 12275468,
    'item_consumption_1': 5.05,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 120.46,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '34bf9af2-d549-4b7c-be91-f8791727bb1e',
    'identification_number': 100769578,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 75.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '75c290eb-1ec8-471a-b6db-75d6ea5c71fb',
    'identification_number': 98193210,
    'item_consumption_1': 81.77,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2378.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2f14a7bc-dce5-4374-8150-96ae80503ed7',
    'identification_number': 20587163,
    'item_consumption_1': 1188769,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 442397.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2bba5341-8ba2-46d6-85cf-8f8300c67930',
    'identification_number': 56091680,
    'item_consumption_1': 1781,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 696.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60a46a2c-fbc2-4866-9e48-bdfe5a036cf2',
    'identification_number': 32845570,
    'item_consumption_1': 21752,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8094.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c02abe38-f40f-4f73-87dc-6ac96bbac830',
    'identification_number': 15543161,
    'item_consumption_1': 6952,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2944.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42a96547-cf2d-4fdf-940c-2fb665a54172',
    'identification_number': 10983848,
    'item_consumption_1': 1988,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 844.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b7f83df-006c-49b3-be53-93eb49cbab94',
    'identification_number': 88214990,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cc428d46-f1d4-41db-8092-a917800065c8',
    'identification_number': 95641963,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1689.87,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8c497779-95aa-48bc-9576-e98565bd6f32',
    'identification_number': 67578314,
    'item_consumption_1': 1649,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 699.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ce93334-3976-4f4e-8096-b24f1c8bb5db',
    'identification_number': 106041983,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '889d9097-b002-426f-baea-d2e12be8c391',
    'identification_number': 26925630,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14667b81-fcde-4d97-a44c-1096898b9f2a',
    'identification_number': 8554471,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f10e1cc7-6950-47f6-8dfd-2015ef538772',
    'identification_number': 97161926,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a51aa060-3e8e-4d61-ad52-e6dd1039389c',
    'identification_number': 14225093,
    'item_consumption_1': 52.69,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1532.52,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fafe9002-06ec-4be6-aa62-fb95a95b9d6b',
    'identification_number': 70059810,
    'item_consumption_1': 1101,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 468.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7095406a-f875-4a6e-8c68-fb15e0133f71',
    'identification_number': 10138455,
    'item_consumption_1': 2137,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 907.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c4b4a58-dc83-4865-af57-45b4d64a10de',
    'identification_number': 60065524,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4fb6e40-9940-43d2-89e9-940d2515c43d',
    'identification_number': 10214143,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 41.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd1348ddf-0497-4c16-832c-72be43c956c3',
    'identification_number': 95847952,
    'item_consumption_1': 32498,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4147.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb6ed32e-1a30-41fa-9fff-756fb092eb49',
    'identification_number': 74755897,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '715a92ce-fac9-4079-a1f5-6bd68b8b5579',
    'identification_number': 33235953,
    'item_consumption_1': 451031,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 167850,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04af3f17-4817-4b89-902d-ab230a75b5a3',
    'identification_number': 66832330,
    'item_consumption_1': 10.4,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 302.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'af89713d-477d-404c-b8c8-34896913d8d3',
    'identification_number': 10105387,
    'item_consumption_1': 1203,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 469.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53640664-948d-41a7-b807-a71afc2ed790',
    'identification_number': 102483175,
    'item_consumption_1': 912,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 356.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8aeea397-c4be-4f6f-bf24-a9f94b3280fc',
    'identification_number': 14886308,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 41.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b4772b54-cc59-494b-af8e-00744919f3d9',
    'identification_number': 13164546,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 6.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5c60a960-02dc-49b3-b152-d376b705c895',
    'identification_number': 16386671,
    'item_consumption_1': 12740,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4982.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '510f5607-85b2-48c1-9192-58e3b99b11b7',
    'identification_number': 32140215,
    'item_consumption_1': 17.7,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 422.17,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '244ff299-d8ee-4635-91d9-02821a06e110',
    'identification_number': 99645599,
    'item_consumption_1': 45988,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 17114.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6026c7d3-f8cc-4b5d-bc12-8753d63e03cc',
    'identification_number': 107466732,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2e7ad8f5-8d2c-4767-8475-ac12cede42eb',
    'identification_number': 108380513,
    'item_consumption_1': 56.97,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1657.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '08770819-d5e5-41bd-ba37-ebedf4a8ae39',
    'identification_number': 105717606,
    'item_consumption_1': 2347,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 917.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5ccef70-5e9f-4f78-acb0-b0736e2ef141',
    'identification_number': 19281404,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cae7dff5-5d08-487f-81e1-332d34a4f0a7',
    'identification_number': 62467344,
    'item_consumption_1': 523,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 222.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '101fb9a1-a691-4718-8789-96c9e08412a0',
    'identification_number': 75441233,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3d888197-5d62-41a2-980a-caedc787e55b',
    'identification_number': 30085900,
    'item_consumption_1': 7.15,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 415.92,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '3396b023-0af4-4d17-b12c-bcf7302d3e49',
    'identification_number': 44001681,
    'item_consumption_1': 4693,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2740.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2216b0ac-e746-4169-84bd-5b4c0a820e90',
    'identification_number': 16093020,
    'item_consumption_1': 122,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 47.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c124222e-c777-429b-b4e4-1923780ffc82',
    'identification_number': 109922204,
    'item_consumption_1': 2708,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1055.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6ae217f-4508-4030-a838-3ab2e9eb34f5',
    'identification_number': 103477217,
    'item_consumption_1': 773,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 301.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33cc08bf-7af7-4b26-8c3e-138c4f6d89ee',
    'identification_number': 32074123,
    'item_consumption_1': 115,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 44.99,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3ac058b6-de07-4ce7-b21a-ded3c0afdd7b',
    'identification_number': 109493664,
    'item_consumption_1': 432,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 168.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '747e738f-11ef-4c23-bdd7-7c7282108b39',
    'identification_number': 94391645,
    'item_consumption_1': 2639,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 336.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86ff7dda-5693-46aa-9428-7c5d1d6c4f35',
    'identification_number': 3031341138,
    'item_consumption_1': 7445,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 3760.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67c46dd4-bfd0-4c89-824f-049bb3e2704f',
    'identification_number': 947580121,
    'item_consumption_1': 230.74,
    'item_description_1': 'Energia Reativa Exced em KWh Ponta',
    'item_total_value_1': 95.37,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': '318353f3-3b79-4cf3-b098-24332605d3cb',
    'identification_number': 923309792,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9cd3257-1d03-4a79-846d-1f63f735c0c5',
    'identification_number': 105275247,
    'item_consumption_1': 13404,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5229.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '802b9f07-df38-47e3-a302-941b0534a919',
    'identification_number': 68376391,
    'item_consumption_1': 3.79,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 90.4,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9b9df1e8-ede6-4978-84d2-a699ff032bb9',
    'identification_number': 3031341162,
    'item_consumption_1': 3130,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 1580.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e8ed571-60ce-4bb3-88f0-381112dae8bc',
    'identification_number': 32501480,
    'item_consumption_1': 1095,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1656.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf4348be-bfb7-4cef-9215-eb4085080882',
    'identification_number': 12469084,
    'item_consumption_1': 915,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 388.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a29ebda2-9951-4268-ae51-21a935334b9e',
    'identification_number': 11110708,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eb922dce-70d9-48da-b2c3-137eb7574a56',
    'identification_number': 19123310,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f02c27cb-fdc6-4367-a3a9-6333262f5fb5',
    'identification_number': 23647841,
    'item_consumption_1': 16252,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6354.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50cdfb32-8d54-4b23-bce5-af1ef9e56711',
    'identification_number': 15449653,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 164.84,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7d88597f-c3e2-49eb-8c11-44ad4c86ef80',
    'identification_number': 99379422,
    'item_consumption_1': 32323,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12028.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb817607-225b-4997-bb4a-ee9a6bd84ef4',
    'identification_number': 102339929,
    'item_consumption_1': 2551,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1081.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19727220-a534-4574-9ee9-90973f36f928',
    'identification_number': 83623256,
    'item_consumption_1': 50605,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 18832.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f7a4d8a-b058-478c-ae4a-83a0e605f667',
    'identification_number': 17159660,
    'item_consumption_1': 459,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 268.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2cfbf12-7b87-4718-b6dc-9ec1234a6925',
    'identification_number': 96460067,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '35b6c9ff-f656-4599-a219-bbb3aca1adff',
    'identification_number': 105807435,
    'item_consumption_1': 547,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 212.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98e45af0-5376-4817-b15f-d9ebd5dc3a78',
    'identification_number': 84742011,
    'item_consumption_1': 227,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 88.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b71b3ddb-2c27-47f4-b0f3-b8fd26f1d57e',
    'identification_number': 109204328,
    'item_consumption_1': 819,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 320.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8b6a3e0-b320-450d-bec1-51ec2a66e6d1',
    'identification_number': 94487316,
    'item_consumption_1': 9875,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4193.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98538407-90be-4641-807c-235797439a23',
    'identification_number': 10549005,
    'item_consumption_1': 3260,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1380.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cda8e950-452b-4817-9e9e-a52ddd50936d',
    'identification_number': 12026174,
    'item_consumption_1': 869,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 369.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c5159f2-f05a-436c-98c2-6bfc5075f6ed',
    'identification_number': 14970910,
    'item_consumption_1': 3470,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1469,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2b6ba7e-4c80-4a8e-9228-c72752811d2a',
    'identification_number': 12750948,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 73.23,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '89784c1b-c209-4ba7-a95a-86fd9f49d32c',
    'identification_number': 14334399,
    'item_consumption_1': 2919,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 372.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b5edd29-7653-4176-adb3-ae3999189ceb',
    'identification_number': 21695660,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c946b800-8906-4123-8e62-02dce27e8b0e',
    'identification_number': 99060434,
    'item_consumption_1': 3944,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1536.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4206733-fe27-4166-ba1a-bcd5a1205058',
    'identification_number': 55932550,
    'item_consumption_1': 3277,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1277.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '646a05ad-e549-4fe2-91d3-2196d3925731',
    'identification_number': 18761704,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9768fbe5-a983-49d9-97aa-0e30aab82237',
    'identification_number': 43875939,
    'item_consumption_1': 75.66,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2200.64,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2e0d002b-2203-4538-9ff5-d8086182a988',
    'identification_number': 109545907,
    'item_consumption_1': 23.41,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 558.34,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'cf9804d9-73d8-4b4d-97e2-36095836ed06',
    'identification_number': 102259178,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '600ff396-d3d2-45ce-9375-d026fd92e511',
    'identification_number': 35322101,
    'item_consumption_1': 177332,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 65993.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44055e81-7b25-48c9-b0ef-b1b3f59d82df',
    'identification_number': 8086451,
    'item_consumption_1': 123.54,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3593.27,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0facf2b5-f39d-4b8b-8201-ea0be650fcf7',
    'identification_number': 36896918,
    'item_consumption_1': 10141,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4297.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb037153-5450-42a3-8e0d-b35968f03fca',
    'identification_number': 10959653,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 106.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a601b8fb-50e6-4cfb-9e94-4c2cc2d12965',
    'identification_number': 83322361,
    'item_consumption_1': 2969,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1156.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a24d5a2e-72a7-4dc0-9976-4023240ae179',
    'identification_number': 96386649,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01260da4-4b2c-4615-b0c5-5157b31781fa',
    'identification_number': 63137399,
    'item_consumption_1': 7721,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 985.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5950e7fc-638f-4577-90e7-8ce8dedb2ca0',
    'identification_number': 32140215,
    'item_consumption_1': 67.3,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1957.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9a544436-561d-4503-8cc0-d1db6dea8cdc',
    'identification_number': 91394031,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6a8fca8d-5ecf-45d0-ba7b-927385b07506',
    'identification_number': 8874280,
    'item_consumption_1': 804,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 340.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3192c34-f370-40eb-8d47-db6e3e503a00',
    'identification_number': 105150134,
    'item_consumption_1': 638,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 249.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ecba5504-c1f1-4db2-ae7c-275e99fc6399',
    'identification_number': 17578612,
    'item_consumption_1': 1883,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 735.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9888d3f9-8237-4e08-b2ba-126a3eca7531',
    'identification_number': 67627641,
    'item_consumption_1': 304,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 129.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2c9c086-c53d-4cae-a17f-d8ba792fca3d',
    'identification_number': 109044002,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e511d627-ac7e-4edc-af60-b658a42ac91d',
    'identification_number': 27135357,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dd1e2350-67d8-44aa-9dfb-df9fa20d86a7',
    'identification_number': 64669360,
    'item_consumption_1': 6.69,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 194.57,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8006d60c-e2d6-409f-b7cf-1af44e8ab3a9',
    'identification_number': 43814697,
    'item_consumption_1': 53.72,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1562.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ebd752b5-aca8-484c-b143-2c7d32749916',
    'identification_number': 41379136,
    'item_consumption_1': 8490,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1083.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '030c6d01-7242-4de4-a095-9219ba77006a',
    'identification_number': 106606964,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '784f4276-26c9-46fc-b1d0-2bae8735de17',
    'identification_number': 41755138,
    'item_consumption_1': 7372,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 11152.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28e62245-9760-4d0a-b0f0-b30f6d34ff28',
    'identification_number': 97332631,
    'item_consumption_1': 8823,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3443.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '195b039b-4dbe-427a-9610-d40b138d3e88',
    'identification_number': 43750583,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e4dbfc43-c961-41df-8df4-d752f59e517e',
    'identification_number': 23542438,
    'item_consumption_1': 5868,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2290.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb770c29-309a-409b-bc26-6e408271d143',
    'identification_number': 8664668,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6670b957-7316-481a-b5ee-8106f2661bd7',
    'identification_number': 12995061,
    'item_consumption_1': 132,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 51.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d9615dc-e91c-4e96-98d0-7bd985e7634d',
    'identification_number': 21424640,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7a11bbb1-ba61-4604-b2c3-94688a66172a',
    'identification_number': 27132110,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 120.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e3b33097-8c69-4242-bf96-cc150b0503db',
    'identification_number': 55316883,
    'item_consumption_1': 2.44,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 114.29,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8d495e1a-d748-4fcc-99e5-830085fe468b',
    'identification_number': 26677210,
    'item_consumption_1': 389,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 227.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d1aaaa6-e63f-45cc-b9c8-642e8e5eff19',
    'identification_number': 44567898,
    'item_consumption_1': 123,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 52.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cce4e1f7-9a67-4ccb-b21a-97bc8e64ab76',
    'identification_number': 37076922,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dba971e5-8af6-4c48-8109-86a048c829aa',
    'identification_number': 59070960,
    'item_consumption_1': 1316,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 768.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '312f58b7-1079-4bc7-bda6-aff6d3868204',
    'identification_number': 109382595,
    'item_consumption_1': 1073,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 455.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0752b075-d428-4865-a861-3644ada1bfde',
    'identification_number': 27895610,
    'item_consumption_1': 98.51,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 2349.52,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'bf0884ba-c31c-4d21-bb6f-b9c1fd90ffc0',
    'identification_number': 101073593,
    'item_consumption_1': 4.34,
    'item_description_1': 'DEMANDA REAT EXCED USD FP',
    'item_total_value_1': 126.23,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '70c11dfa-bdaa-49e4-be1c-75b876e68581',
    'identification_number': 64416534,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fd1ec48-57c1-460e-80b7-62ffcfacc65d',
    'identification_number': 15751740,
    'item_consumption_1': 705,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1066.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ec7d1a3-61fd-41de-bb6f-35114b4cd22b',
    'identification_number': 92135811,
    'item_consumption_1': 1664,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 648.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd88eecc7-50b0-4d97-b5a9-a2b3f9012937',
    'identification_number': 43338739,
    'item_consumption_1': 4957,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1844.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b03b49f-ee0e-4f23-94b5-dac408102968',
    'identification_number': 35778962,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e88663af-148b-4d8b-9e03-d7b7240c157b',
    'identification_number': 33921148,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 127.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bddaf725-1d53-4a86-8135-2a060c8c8983',
    'identification_number': 59355573,
    'item_consumption_1': 95,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 37.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '19ede523-ae88-4adc-8fe8-f2b869a5a276',
    'identification_number': 12019670,
    'item_consumption_1': 2.264,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 835.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57050d00-4b0b-40c0-ad6e-999a766fc247',
    'identification_number': 64222942,
    'item_consumption_1': 9138,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3878.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6fb0c21-6f68-4bf9-9af1-78867396e73e',
    'identification_number': 49066226,
    'item_consumption_1': 1845,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 781.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a721681e-0792-4a72-b6cd-27c41f8aa867',
    'identification_number': 95368957,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7d40ca8-fab8-43c5-b37a-1b6dcaaba6e7',
    'identification_number': 11187069,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-75.09",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '1adb3ed6-0cee-476b-be5c-4460bf946526',
    'identification_number': 14906864,
    'item_consumption_1': 4290,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1671.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff3f835b-a374-46e8-afee-cbb47b646d2e',
    'identification_number': 94602123,
    'item_consumption_1': 214,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 83.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81bd98e0-b164-4062-9a42-e671cd278e09',
    'identification_number': 101073593,
    'item_consumption_1': 3.38,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 80.63,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'cb8258c4-b165-4c15-a413-f6d0560c3221',
    'identification_number': 13299530,
    'item_consumption_1': 1806,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 230.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93f1b5be-899c-4856-a802-1cb40a42564b',
    'identification_number': 46006583,
    'item_consumption_1': 0,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 002/014',
    'item_total_value_1': 2145.59,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '73f775d5-8238-48b8-b9aa-45b444d6e2fd',
    'identification_number': 15152731,
    'item_consumption_1': 213,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 83.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa9f18fc-1641-4512-93cd-543610fca1b9',
    'identification_number': 926575282,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0afe031d-f1cc-4fda-ac20-ef9dd96fc6d3',
    'identification_number': 96513381,
    'item_consumption_1': 14312,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6064.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53028a17-64be-4b71-ac5a-bba45fc83434',
    'identification_number': 55061648,
    'item_consumption_1': 1692,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 659.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b745fb3-a39a-4e13-8994-60bca2070e55',
    'identification_number': 66557402,
    'item_consumption_1': 1489,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 869.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0bf109e-b1e4-48b1-9cf5-e3daa53137fc',
    'identification_number': 39208869,
    'item_consumption_1': 10484,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 15860.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd657959-1b3d-4430-b5fb-a9146edead06',
    'identification_number': 68085559,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '65fc1489-bdc3-42b5-9a32-2805b861703b',
    'identification_number': 9027092,
    'item_consumption_1': 21452,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7983.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7e02972-a7bb-4fb5-82e7-95897c3ce2a0',
    'identification_number': 17435048,
    'item_consumption_1': 47647,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 17731.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b92b2ce1-2a9b-4e6a-9304-36a14ac03dc5',
    'identification_number': 97186759,
    'item_consumption_1': 2448,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 957.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5823276a-afcd-4e6d-93e5-b23190fc2de1',
    'identification_number': 19311508,
    'item_consumption_1': 5060,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2144.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3932a959-ea8a-48c7-a49c-d17d5b8a36de',
    'identification_number': 23562200,
    'item_consumption_1': "'-6.912",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-2210.35",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '3ffdf227-45df-43bb-b4c6-c7a148eef211',
    'identification_number': 53869303,
    'item_consumption_1': 2581,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1507.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea7f894f-a0df-4174-a82e-fcdd4ce75d75',
    'identification_number': 102261016,
    'item_consumption_1': 26.37,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 766.98,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1402b214-568a-41a2-b2e6-10eb017b38e4',
    'identification_number': 14075423,
    'item_consumption_1': 278,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 117.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32544d80-2658-4385-b63c-e7d705b075ae',
    'identification_number': 101837461,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd01a5d48-a612-4a88-98ce-b14ea6d72fc9',
    'identification_number': 32605579,
    'item_consumption_1': 18084,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 10561.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2788358a-b72b-4ed9-ba65-5cd884f3d0cb',
    'identification_number': 34009655,
    'item_consumption_1': 1342,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 570.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f44d9e36-5a7f-4c2e-b9f3-fe6280b557c2',
    'identification_number': 14333910,
    'item_consumption_1': 15.46,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 899.33,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'e6e4b66c-59c1-4d8b-9e1c-08921a1837a3',
    'identification_number': 94664480,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-203.12",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '303ed486-4faa-4e7c-8084-db7b900d15d3',
    'identification_number': 93324138,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2500.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec55c20e-f449-430e-9e9f-58bcdb71a46f',
    'identification_number': 89347722,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aae68025-b4b0-4737-bba5-3b4d1c12f21a',
    'identification_number': 103087605,
    'item_consumption_1': 556,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 217.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75474a4d-c24d-4c80-82f1-edd8f24bc124',
    'identification_number': 34346007,
    'item_consumption_1': 19889,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7401.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1093a895-0502-40ea-ba69-2c9ef5d007d2',
    'identification_number': 41739140,
    'item_consumption_1': 4119,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1607.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b28e60cf-c880-49dc-ae0e-ad214777ecfd',
    'identification_number': 105628271,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8f03ef46-1d74-47e1-8c7f-96beab3e1020',
    'identification_number': 3030777250,
    'item_consumption_1': 533,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 158.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '179d3311-76d4-4432-8059-a283c881f398',
    'identification_number': 63137399,
    'item_consumption_1': 15.93,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 379.94,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '32385a27-8a9d-4766-b605-fdbcb9be7465',
    'identification_number': 53371127,
    'item_consumption_1': 2861,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1670.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '241a7c49-ff0a-42b4-b6f1-661eac5b92c9',
    'identification_number': 47766654,
    'item_consumption_1': 1128,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 440.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ad2efa4-9e61-471f-9e1f-723734d3913d',
    'identification_number': 37969064,
    'item_consumption_1': 24.64,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 716.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '19b698c4-bc7a-4b86-9c69-7f92ea6cbd9d',
    'identification_number': 105816280,
    'item_consumption_1': 6199,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 9377.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bcedc6bd-6ae8-448c-b10a-a72c3bf60781',
    'identification_number': 71522107,
    'item_consumption_1': 4021,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1704.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba0c2fbd-bb6e-441f-af57-ac6322f8ae1b',
    'identification_number': 35000430,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0a8949d6-e68c-4752-8bdd-3a2d10fea95c',
    'identification_number': 40720829,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d7d41ab-96ed-460d-a6ee-360e5c280eb5',
    'identification_number': 70425914,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 130.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd906a591-fa98-42b8-a8fe-7b8bd5400a8b',
    'identification_number': 31686729,
    'item_consumption_1': 1375,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 538,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4e8b750c-f65a-453f-b9f5-a479551fcaf5',
    'identification_number': 18094694,
    'item_consumption_1': 11528,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4885.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '388b36b8-3189-4fd9-b202-e23cb33e1f04',
    'identification_number': 92828760,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1662.82,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ff4c3017-506e-4a8b-a32d-ebb0c5e3cdb7',
    'identification_number': 99801736,
    'item_consumption_1': 62.33,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1812.91,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2d3409ee-db33-4250-973d-24159ae50858',
    'identification_number': 46225668,
    'item_consumption_1': 485,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 205.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f285318e-1112-40f9-b9ad-d7430419ba78',
    'identification_number': 95847952,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 60.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a83592eb-aab9-4ef6-8434-b33b16ff1a92',
    'identification_number': 101166605,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b991774-73e0-4a06-b3cd-34998d1ebc5f',
    'identification_number': 102014990,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO EM DOBRO',
    'item_total_value_1': "'-220.29",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '71450da3-0046-44f9-aaa7-4238b777c75d',
    'identification_number': 9050426,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 329.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0042010f-1753-400a-a039-0639a559139d',
    'identification_number': 14938170,
    'item_consumption_1': 3960,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1542.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01eefb43-751e-4e80-9cfd-a23f3d2eec46',
    'identification_number': 28942981,
    'item_consumption_1': 9373,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3656.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98cfdaf9-eee5-478e-b45c-16421eda8832',
    'identification_number': 45838313,
    'item_consumption_1': 1683,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 214.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14aac169-38c0-4e3d-b334-f40592505aec',
    'identification_number': 17422760,
    'item_consumption_1': 5780,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2455.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c97d96f1-92a4-4f2e-a183-b7e016d8c709',
    'identification_number': 42223750,
    'item_consumption_1': 1214693,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 452044.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f19bd1c3-36c7-47a8-a7be-b5f190a0c10e',
    'identification_number': 96280522,
    'item_consumption_1': 6138,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2396.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64dedf36-1b91-4f86-8ec5-92251dc7c5ea',
    'identification_number': 106141864,
    'item_consumption_1': 40.44,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1176.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f639a304-8dfe-41ac-a3ad-9a5421cad358',
    'identification_number': 93507798,
    'item_consumption_1': 3962,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1680.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e160e39-0691-47d8-8ef7-db9e7dc99742',
    'identification_number': 96736291,
    'item_consumption_1': 14213,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5545.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8721159-cd0a-404c-b5c9-73f161c05583',
    'identification_number': 70701989,
    'item_consumption_1': 254,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 99.03,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e373068c-09b7-4382-a1f9-f9df27b1c1b3',
    'identification_number': 42162823,
    'item_consumption_1': 4688,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1833.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37198b43-8f79-46c7-9aaa-8ab0f1063fb2',
    'identification_number': 27092313,
    'item_consumption_1': 5092,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1989.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'feaa2522-679f-4fb1-8256-fe4c44f413ad',
    'identification_number': 41002717,
    'item_consumption_1': 1075,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 420.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0bce388-b5be-48f2-8235-e90c837433b6',
    'identification_number': 6949738,
    'item_consumption_1': 4842,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2056.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9e76831-f77d-4488-a6ac-04a9373dc3cf',
    'identification_number': 103764755,
    'item_consumption_1': 20688,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7698.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf05d141-afd4-4414-ab19-7d261afaa4fb',
    'identification_number': 43061842,
    'item_consumption_1': 15604,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5806.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8c5cbc4-4312-4738-9898-853fa6988a0d',
    'identification_number': 51301563,
    'item_consumption_1': 1336,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 516.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e4cdb7b-fe12-4ca6-ba9f-c2266285622a',
    'identification_number': 111740797,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c06ed76-6b15-422a-a757-043968c93f7a',
    'identification_number': 81859945,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '66cdf389-71dd-466b-b7c8-ebafd0bf2c33',
    'identification_number': 70509204,
    'item_consumption_1': 4072,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1515.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db71a299-7b6d-47fc-9ace-c85e1b61794f',
    'identification_number': 103215310,
    'item_consumption_1': 2391,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 934.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54fa3656-e7c1-4ae0-b97b-f9b92a71060d',
    'identification_number': 32266006,
    'item_consumption_1': 1,
    'item_description_1': 'Iluminação Pública',
    'item_total_value_1': 55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '03f216bb-f9f3-4b9c-88a3-ab0513b73888',
    'identification_number': 67030165,
    'item_consumption_1': 41.22,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2354.06,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'faa75fe4-30e2-46b7-abe0-de42ea425e8b',
    'identification_number': 923318231,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '99eea4b1-7971-494f-a9a9-ebb0cb8003a2',
    'identification_number': 107956403,
    'item_consumption_1': 2371,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1384.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c650632f-ba7b-40a7-9d0c-2b5ea3b8a7cf',
    'identification_number': 98913972,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1b3c5240-cd09-47c9-b42d-50e23d50548a',
    'identification_number': 30618886,
    'item_consumption_1': 190,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 74.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd028ae74-0863-486e-8bd2-2975f9230485',
    'identification_number': 99569280,
    'item_consumption_1': 39913,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5094.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '859a3e5d-6c21-4b78-a772-a789ed2b52bd',
    'identification_number': 16111630,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ed224ad6-eec3-4e07-8f40-47690b7d5acd',
    'identification_number': 104309555,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2463.72,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0f407671-adfb-4c66-86ff-83d47c68d6b1',
    'identification_number': 29160758,
    'item_consumption_1': 38,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 14.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e796751e-50d6-4360-b201-8d35b864011e',
    'identification_number': 12629,
    'item_consumption_1': 870,
    'item_description_1': 'UFER fora de ponta(Reativo)',
    'item_total_value_1': 295.33,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': '8bba42e4-b86c-4e1e-8cc4-c00b1f3eb644',
    'identification_number': 59438312,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c334440d-c1f1-4651-8b28-0ac03bac915f',
    'identification_number': 8529205,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4219b969-56e9-4238-b552-1467f670aef2',
    'identification_number': 9823000,
    'item_consumption_1': 2775,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4198.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9405ef73-8427-4d2e-98bf-93fbce2d2abe',
    'identification_number': 109462190,
    'item_consumption_1': 4663,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1816.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b4a87f8-a2ae-449c-902e-6792aa876287',
    'identification_number': 57109346,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 96.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a9372278-0711-4645-bb8e-81da584b76f4',
    'identification_number': 99666995,
    'item_consumption_1': 2566,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1001.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '383c2673-539f-4bd3-b73b-25e0b100c048',
    'identification_number': 109822285,
    'item_consumption_1': 2818,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1645.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '909a63a3-955a-4eab-bf23-d6d53e2e33b4',
    'identification_number': 17187923,
    'item_consumption_1': 16,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 6.25,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'fdf61725-b657-4d12-921d-2c4fd20e63f9',
    'identification_number': 91800536,
    'item_consumption_1': 574,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 243.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f5c0717-7545-4d83-83b1-d02ff9eda590',
    'identification_number': 14449005,
    'item_consumption_1': 3200,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1248.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f93e583-0f1a-4b25-9b0d-44ec757d0496',
    'identification_number': 27895602,
    'item_consumption_1': 58130,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 21632.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b49a2940-9787-4e2e-8d71-28c1fac9fdfb',
    'identification_number': 82724873,
    'item_consumption_1': 34.21,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 995.02,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0c013d02-ecb5-4cc4-8c93-1f0eb929b79f',
    'identification_number': 14039362,
    'item_consumption_1': 44.28,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1287.91,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2ccc542d-b88b-4725-bb58-7ab5cd8a4ea0',
    'identification_number': 5576369,
    'item_consumption_1': 643,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 251.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7519a063-16cd-47aa-9cfd-54fe08690af3',
    'identification_number': 107936925,
    'item_consumption_1': 62.97,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1831.52,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6e717c92-52e3-41aa-a0e6-767c196a1295',
    'identification_number': 38432005,
    'item_consumption_1': "'-10.367",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-3598.25",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'b0971525-f8a9-4e32-8b23-913341a8ea1e',
    'identification_number': 46123253,
    'item_consumption_1': 2961,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1254.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea6b15f5-19ac-4090-afc3-67648f6e7dd3',
    'identification_number': 5415870,
    'item_consumption_1': 22.62,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 539.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'dae0f428-13df-425d-aae7-6b7adbd75842',
    'identification_number': 98554840,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f33fb406-52d9-4484-91b3-068eb6085a1d',
    'identification_number': 16961439,
    'item_consumption_1': 1434,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 559.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2c55da5-3ca2-473f-8c57-c1207ca3f6c7',
    'identification_number': 94317976,
    'item_consumption_1': 2472,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1046.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd872f2b8-d4e4-4f20-826e-27398ac7305e',
    'identification_number': 110922751,
    'item_consumption_1': 790,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 334.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '397f5750-2c35-4e11-9214-4bd59dc77faf',
    'identification_number': 17015391,
    'item_consumption_1': 5515,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2156.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fcd54c66-cf5d-49fb-b495-42d9a7962abb',
    'identification_number': 99786184,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'da2e50aa-82ba-4df0-adcb-2da748fe98e4',
    'identification_number': 31927807,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f8ec213d-e45a-476a-b9f6-4897e71bc054',
    'identification_number': 66832330,
    'item_consumption_1': 3482,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 444.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb0b2ea9-1eec-4645-9517-364d2fb4a544',
    'identification_number': 92171273,
    'item_consumption_1': 2787,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1087.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '679724a3-54f5-4bdd-8efc-6fbf66aefe7a',
    'identification_number': 79702236,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52ebf2eb-802e-4566-85a0-a4d31d812e2c',
    'identification_number': 30950317,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '96be9227-e571-48aa-b3ed-508f0c43691a',
    'identification_number': 27047970,
    'item_consumption_1': 1170,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 495.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98c7ec31-861e-4856-b2c7-0c4300328a40',
    'identification_number': 77008421,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a292a900-710a-461d-9eb1-eeb5f83d927f',
    'identification_number': 35825448,
    'item_consumption_1': 4.17,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 99.46,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '15859e33-2e04-4ea5-8cca-2eed4234788b',
    'identification_number': 41499255,
    'item_consumption_1': 5.94,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 141.7,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f7ca3e9b-fbc4-4ffe-9444-46333e50eba2',
    'identification_number': 79722970,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 183.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2840e61c-c33e-4378-8c09-c14a3341b162',
    'identification_number': 109909437,
    'item_consumption_1': 1587,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 618.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aad62283-04b4-4bdd-b16a-f82a02a30653',
    'identification_number': 56026684,
    'item_consumption_1': 760,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1149.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c58df35-3eb1-46aa-8be3-a5bafeb14445',
    'identification_number': 15915727,
    'item_consumption_1': 1740,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 679.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4824e688-e809-4300-99c4-00035443efe6',
    'identification_number': 15092909,
    'item_consumption_1': 4388,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1861.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4eb5285-0476-4f5d-b35d-8c13ea3ae8af',
    'identification_number': 12493961,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-4736.17",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'a7cdfbb0-536d-42df-be16-4974fc09b3d7',
    'identification_number': 59090413,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 67.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '44f3efc4-aec2-44c1-858d-b1f136881495',
    'identification_number': 23074221,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5f34dd54-f75f-4268-9944-f48350a33229',
    'identification_number': 93182228,
    'item_consumption_1': 1177,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 498.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc23a67e-12a9-4837-9680-0b65500feed1',
    'identification_number': 42089808,
    'item_consumption_1': 63309,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8080.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ffd03d4-7a16-4eb6-9406-911769a12e82',
    'identification_number': 109592441,
    'item_consumption_1': 691,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 270.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1acf081-1d5c-4fdb-a12f-9514b1f5d060',
    'identification_number': 56525230,
    'item_consumption_1': 26.12,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 759.7,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2338f77a-4af2-489f-8b52-36f4014f496d',
    'identification_number': 43626955,
    'item_consumption_1': 9,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 3.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '48279909-f2ed-4549-98c5-f8ce71c6af96',
    'identification_number': 75062046,
    'item_consumption_1': 5.26,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 125.45,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'dc76d862-3e6b-4288-b0ae-3579d1252522',
    'identification_number': 109675185,
    'item_consumption_1': 257,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 388.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bc49344-647c-4aa9-9d31-d39be7923f58',
    'identification_number': 10894055,
    'item_consumption_1': 1709,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 724.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '323a9092-8244-4a85-8e26-779ff68b1820',
    'identification_number': 104532823,
    'item_consumption_1': 599,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 253.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fccf482b-400a-4291-bd4e-b4f67d3f397b',
    'identification_number': 111132290,
    'item_consumption_1': 16.33,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 474.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2e36e27d-d0fa-4d51-a994-974fa6456594',
    'identification_number': 23660872,
    'item_consumption_1': 4.22,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 100.67,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a4bb6cba-6a8a-4d43-8c4a-225279340807',
    'identification_number': 96174137,
    'item_consumption_1': 699,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 272.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '159d0bf0-3fcf-4925-90c0-7a495a2fdde4',
    'identification_number': 56525230,
    'item_consumption_1': 8510,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3166.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df7b8283-d77b-41c2-b90e-9b4b560201a4',
    'identification_number': 106606824,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e750e5f3-9df6-4ce9-824c-272b7c46df3c',
    'identification_number': 69671516,
    'item_consumption_1': 3283,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1281.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b39d7b0-c4f0-48a0-8a1a-26c9897cabc6',
    'identification_number': 13786067,
    'item_consumption_1': 7440,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2899.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1428bec-357c-4bb5-98cf-d20d267f26cb',
    'identification_number': 33252785,
    'item_consumption_1': 3204,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1251.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4990950-b5a9-4ad8-bfc6-126db082574f',
    'identification_number': 91392454,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-4130.89",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'b123c1ff-5082-496a-afbf-19039cd6fab6',
    'identification_number': 92003311,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99007ea8-0f07-4b9d-8657-931a07d5d9e1',
    'identification_number': 74430980,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 228.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f0a91c4-3507-438e-ac0d-aeb9eafb9ed7',
    'identification_number': 98182501,
    'item_consumption_1': 5862,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 748.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1a274ed-3b6b-479f-92a0-b6331acf6d81',
    'identification_number': 87807360,
    'item_consumption_1': "'-287.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2020',
    'item_total_value_1': "'-92.06",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '5458311b-7e9d-4d06-9b2f-0d2aa220f617',
    'identification_number': 92950310,
    'item_consumption_1': 10564,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1348.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ce6344c-1015-49e5-aa4f-4b35038466db',
    'identification_number': 93324138,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 431.05,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c08abeeb-ebb2-42d0-857a-e4bd3884569c',
    'identification_number': 56875592,
    'item_consumption_1': 1335,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2019.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '631796fa-b175-4363-b5ba-e47449b2e19c',
    'identification_number': 19452179,
    'item_consumption_1': 23887,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 13950.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49cf7cbe-021c-4a1c-af79-11ca2821724a',
    'identification_number': 63416794,
    'item_consumption_1': 42.36,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1232.07,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '362f1de4-c79a-4af1-8add-e95ddc2d6759',
    'identification_number': 32153430,
    'item_consumption_1': 624,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 944,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '504bfcba-39d8-4be1-a702-a8f044bf75a8',
    'identification_number': 111132290,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0c611530-e552-47db-a566-603067c16909',
    'identification_number': 39214699,
    'item_consumption_1': 12035,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4478.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93b8cbc1-c6d0-4432-9ac8-2e90b4a8f404',
    'identification_number': 38288800,
    'item_consumption_1': 11234,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4764.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94e305c3-7fef-4527-85bf-b1eed19dc56d',
    'identification_number': 12446645,
    'item_consumption_1': 1.48,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 35.31,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '90260d68-5ff8-458f-8646-8bd9381678eb',
    'identification_number': 56868057,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92bebdad-a3fc-4eca-80a6-a743b3a0a7b1',
    'identification_number': 105277002,
    'item_consumption_1': 0.52,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 15.11,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'f1d32822-17fb-4ecd-9e02-94ddf5a14074',
    'identification_number': 14819821,
    'item_consumption_1': 28.6,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 831.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7184c91b-851f-4453-ba97-8c9a0229eaeb',
    'identification_number': 68902824,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1529.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffc50fc7-9107-4c39-a01a-38854dd545b0',
    'identification_number': 104132779,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1d07fff-9f86-42ab-810d-e22aa62186df',
    'identification_number': 61583758,
    'item_consumption_1': 175.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 5094.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9ce52ddf-9390-4d18-b930-ae59c29245e6',
    'identification_number': 82730652,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7e3cbb68-f4e6-4340-af5e-b5838b3a44f5',
    'identification_number': 108380513,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5e967c86-0c54-49fc-b5df-4d0942110a27',
    'identification_number': 19306105,
    'item_consumption_1': 421,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 164.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c0d5200-d272-4657-ba9c-80b672aed34e',
    'identification_number': 27549607,
    'item_consumption_1': 1252,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 488.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd471432b-30da-4382-95bd-08b795180067',
    'identification_number': 14689146,
    'item_consumption_1': 6.65,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 386.83,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '427189f4-9899-4588-9dbc-c63230429a59',
    'identification_number': 93087225,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddfa2a92-9255-4d43-97ac-e8fc399f54bf',
    'identification_number': 19453779,
    'item_consumption_1': 32.48,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 774.69,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b587a3a0-fd09-4a80-8b17-cea3cbd2f1c4',
    'identification_number': 79147453,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '953efcb7-9f9e-422b-8fee-0b9842073333',
    'identification_number': 32403011,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e84a56d0-9f3a-451b-adb0-2e5b9482c9fe',
    'identification_number': 7664214,
    'item_consumption_1': 3681,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1438.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd294aa5c-5345-40d4-8e2b-a89eb3b30df6',
    'identification_number': 91302749,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f47dc6d0-7b7a-49fe-be9a-1300d4a137e4',
    'identification_number': 12917257,
    'item_consumption_1': 258,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 109.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab91896c-f47d-456a-b254-4e72cbda429c',
    'identification_number': 15465187,
    'item_consumption_1': 6457,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2516.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '981eab5a-6fe6-40b8-9955-d646f6d91977',
    'identification_number': 104345136,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '184f33d5-df5c-4dc6-8724-fc3aa9bcd241',
    'identification_number': 38370492,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 112.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f005ec12-4a15-4164-9fac-cdaadba78538',
    'identification_number': 39538842,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dfcd4bc0-ea69-4a38-9ff1-551abc53d7d5',
    'identification_number': 923332497,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa6989fe-8830-42c5-9f5c-2c71ce252bbc',
    'identification_number': 50792180,
    'item_consumption_1': 95.34,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 5444.85,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '80b0b3d4-7086-4d01-a4a2-ba0f78ff5998',
    'identification_number': 10633162,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2f5c9dd-881c-4904-8a64-8ca065db8548',
    'identification_number': 53263081,
    'item_consumption_1': 3650,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2131.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d7b3cb0-8c6c-4756-9c59-d0b1e7adec10',
    'identification_number': 97630454,
    'item_consumption_1': 402,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 156.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '898a49aa-de3f-4670-a168-f3f45e96ff2c',
    'identification_number': 108322440,
    'item_consumption_1': 53.08,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1543.87,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7df03cab-a3a0-456e-ab22-523a4cb5c091',
    'identification_number': 101870728,
    'item_consumption_1': 9466,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1208.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3cb5ca91-9414-4c24-b9e7-39884c556aff',
    'identification_number': 78084237,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 338.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '604d3a8c-01af-44d1-90b9-604bdac81058',
    'identification_number': 107055198,
    'item_consumption_1': 61,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 25.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f32766d0-ed74-48cf-a766-c588499f5fde',
    'identification_number': 16699696,
    'item_consumption_1': 39,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 22.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71f9d689-08f5-466b-a717-0ae9ac766a64',
    'identification_number': 109419944,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 60.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '715039eb-ee47-4f24-bf93-a7065ec52e11',
    'identification_number': 37645331,
    'item_consumption_1': 455,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 177.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3eb395de-e1f1-4b4e-958a-4388e4c72968',
    'identification_number': 100765076,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV. ATUALIZ. MONET. CORR FAT',
    'item_total_value_1': "'-9.37",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '4c420b79-784c-40a9-a682-9d1d9e5473dd',
    'identification_number': 15091520,
    'item_consumption_1': 1197,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1810.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fc14223-e1c2-486a-808a-1f5c678c0cd6',
    'identification_number': 75896974,
    'item_consumption_1': 45.75,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1330.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '80ed8b09-52d0-4669-a1e7-b6088ad28855',
    'identification_number': 94050635,
    'item_consumption_1': 4934,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2090.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '160a02c6-dbef-4636-a7fe-2a454c9b6f91',
    'identification_number': 56697198,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2f2699b4-58f9-42f0-a4c0-f5985a07e277',
    'identification_number': 14225093,
    'item_consumption_1': 24618,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9161.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c59c2e9-91fc-4860-a729-010641dea287',
    'identification_number': 8704236,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b7c6a66-5037-4929-803a-a7e003e234fe',
    'identification_number': 109423410,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d394f5f-030e-453d-9638-1b34f3c9e2d8',
    'identification_number': 111268524,
    'item_consumption_1': 5921,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2514.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74617f2d-03cb-4caa-838e-3e0d66a740cb',
    'identification_number': 15444740,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 323.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a8c4438e-fd2a-400c-a3e2-a67fe5539183',
    'identification_number': 92782221,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efdfc3b9-2034-4a33-8e91-eefa471c24c7',
    'identification_number': 19123310,
    'item_consumption_1': 2980,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1262.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f67aa9f2-c49d-4823-9a7d-9ead1fc1e052',
    'identification_number': 91996384,
    'item_consumption_1': 234,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 91.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e913cc77-f764-4d31-ae8f-fe4d3d9f87d7',
    'identification_number': 84702893,
    'item_consumption_1': 2388,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 934.36,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd0964305-ea71-4181-9198-6f9e52a9bb0c',
    'identification_number': 56708890,
    'item_consumption_1': 1.78,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 203.29,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c55624b1-9e26-4ab6-933a-3f1e45edb92a',
    'identification_number': 8813442,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 45.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '35680bcc-1d27-4793-9188-350bcd6a3879',
    'identification_number': 109181093,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6521b7ad-8bb2-4ddc-a41e-a5ebbc2c20a2',
    'identification_number': 56527136,
    'item_consumption_1': 2.35,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 56.07,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'dc2f4ba0-d1d4-4c2f-b8dc-eeadca5fbc49',
    'identification_number': 8661758,
    'item_consumption_1': 70.7,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2056.36,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f71238fa-ecb7-4a18-81ff-28d7b62e6a91',
    'identification_number': 93712847,
    'item_consumption_1': 8225,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3211.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1652faf2-2bf3-4f43-b379-82d330de51c9',
    'identification_number': 99178990,
    'item_consumption_1': 7405,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3136.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20079619-a2e9-42ea-ae5e-34502735a044',
    'identification_number': 64716929,
    'item_consumption_1': 248.85,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 14211.82,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'd76744b0-cc56-4753-9529-43a66bef2475',
    'identification_number': 59541288,
    'item_consumption_1': 5383,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2003.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8cd568a-f3b3-45c6-9f03-4caa6b64760a',
    'identification_number': 41081757,
    'item_consumption_1': 16652,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7057.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '619db3e4-da36-4c7b-8905-130d8151c4c2',
    'identification_number': 102684650,
    'item_consumption_1': 417,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 243.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '349a3416-92e9-4bcf-a8b4-e077b7ed24c6',
    'identification_number': 75192250,
    'item_consumption_1': 139,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 54.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07227371-39ae-470a-a313-35eb65b78ab1',
    'identification_number': 84222417,
    'item_consumption_1': 186,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 78.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15c5b9d0-dbce-4507-a0cb-6267cae5f1da',
    'identification_number': 19272170,
    'item_consumption_1': 396,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 154.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8f58ca1-ae62-40b1-b533-e0e6627f5183',
    'identification_number': 100567924,
    'item_consumption_1': 759,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 321.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'faf405b2-1685-4635-8caa-ed27a27711fd',
    'identification_number': 95563610,
    'item_consumption_1': 3832,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 489.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5359dae5-1caf-4978-a688-fd7b973beddb',
    'identification_number': 9027092,
    'item_consumption_1': 16,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 6.25,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6a144a0c-c6fe-40c7-aae9-6576da25f74c',
    'identification_number': 80350844,
    'item_consumption_1': 118,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 46.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f9579bc5-728a-4568-b136-27e38bf5f78e',
    'identification_number': 92865534,
    'item_consumption_1': 17807,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7550.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b1a4a46-1790-4280-87b9-c6faa46a93ce',
    'identification_number': 20305290,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '79b8f33b-daec-44af-8a11-f9a93dbb6c4f',
    'identification_number': 110479890,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f965800-5f48-4e35-a2bb-bcb59f631e28',
    'identification_number': 104126388,
    'item_consumption_1': 593,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 251.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7a08112-e61c-4716-ad22-ca56587cb367',
    'identification_number': 35322101,
    'item_consumption_1': 16525,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 9651.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a9faad1-2175-4179-b907-7a107f0769d4',
    'identification_number': 19229747,
    'item_consumption_1': 10.52,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 250.91,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '670b9577-faad-4840-b139-ac385b99fba0',
    'identification_number': 111583748,
    'item_consumption_1': 512,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 199.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2944b10-90d3-4f7e-8268-9bd77fddd662',
    'identification_number': 33101493,
    'item_consumption_1': 110,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 46.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b97b186f-19ae-4bb5-97f8-fc5c5d577b97',
    'identification_number': 12382671,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-3.1",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '3dd7e313-3919-40db-bafb-90ee6dc40272',
    'identification_number': 18649092,
    'item_consumption_1': 626,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 243.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e980b04-fac0-4909-872f-fc3c5f5ac8ba',
    'identification_number': 105683833,
    'item_consumption_1': 3784,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1605.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3ee6826-ade6-499d-ab6d-9edd064bcd8a',
    'identification_number': 37969064,
    'item_consumption_1': 79,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 119.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94ca6521-0629-4e82-bcc7-c79fda0847ee',
    'identification_number': 8661758,
    'item_consumption_1': 2282,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1332.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dee99f28-3ec7-4d70-91b0-ffef42780fd8',
    'identification_number': 108153746,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '309d9606-2686-4947-b54e-9b6872b7f19b',
    'identification_number': 14825430,
    'item_consumption_1': 3184,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1353.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a07781ba-9ed5-463e-8702-e3260dc14076',
    'identification_number': 36527661,
    'item_consumption_1': 283,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 120.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aff255b8-f4c1-4106-b1c5-3f8223f9b734',
    'identification_number': 42272688,
    'item_consumption_1': 556,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 216.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6dc167f5-1795-4494-a157-1ddc619cc1a6',
    'identification_number': 32689250,
    'item_consumption_1': 0.36,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 10.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2df11703-e7d9-4903-903f-b4fec319e326',
    'identification_number': 31497861,
    'item_consumption_1': 184.13,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 5355.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd9f6bdea-e7d6-4c1e-8490-ce8f7810ba7a',
    'identification_number': 13299530,
    'item_consumption_1': 37.04,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1077.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '900de6c9-1570-4e1e-a970-11ff607af710',
    'identification_number': 22902694,
    'item_consumption_1': 12,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 286.21,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6a9172a5-36d7-43ae-9702-08d9b9c21ac3',
    'identification_number': 64175251,
    'item_consumption_1': 2.69,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 125.98,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'bde7b619-5939-4e7b-bad3-7b4b7a0648cc',
    'identification_number': 101256507,
    'item_consumption_1': 3833,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1494.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a8b8f37-64b7-4f0a-8010-639202a6c28f',
    'identification_number': 59440589,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2cc97966-d4d9-4dc4-9c52-9717f0900e61',
    'identification_number': 52191362,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a8c171e9-74ac-4b8e-b94e-5a6108d99a54',
    'identification_number': 97614858,
    'item_consumption_1': 20.36,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 485.6,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd89c9bf6-2f5c-440f-9fb9-911b20d0f44f',
    'identification_number': 16307836,
    'item_consumption_1': 23724,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8828.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd823c5bc-a8c4-4f15-9752-0bf5a65f0cfe',
    'identification_number': 93926677,
    'item_consumption_1': 2335,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 988.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53350560-063b-4946-a35f-a6cbdcaae17d',
    'identification_number': 23029196,
    'item_consumption_1': 800,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 340.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd587d63b-49bc-4b24-aa36-3be9ecef5f1b',
    'identification_number': 18456669,
    'item_consumption_1': 5642,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2099.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86c1decc-2482-4ff4-aede-6e286c3d5f3c',
    'identification_number': 90978501,
    'item_consumption_1': 9846,
    'item_description_1': 'Energia Ativa - Consumo Fora Ponta',
    'item_total_value_1': 3735.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92a6c0e8-f20c-4480-99b9-702f438566cd',
    'identification_number': 98150740,
    'item_consumption_1': 846,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 358.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34d5f9b2-deaf-48ce-a573-8656464556f9',
    'identification_number': 18339603,
    'item_consumption_1': 7.4,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 845.23,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2d40001c-954f-4e61-b705-6899105c92b2',
    'identification_number': 101918925,
    'item_consumption_1': 179,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 75.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94b13369-7aaf-4920-9d83-b3996f2de19b',
    'identification_number': 75565730,
    'item_consumption_1': 2534,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1479.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb8227d7-9626-41a9-a5d3-efbb194c0c4e',
    'identification_number': 18010024,
    'item_consumption_1': 2846,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1111.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f0a032c-dfd2-452d-a7ed-6717e11eff49',
    'identification_number': 22971300,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8aa9a218-9b1d-4417-bf8a-6bff7f1d3a41',
    'identification_number': 98784870,
    'item_consumption_1': 2682,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1048.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cccdfe57-06d2-414f-9446-9f5add72aff4',
    'identification_number': 16977823,
    'item_consumption_1': 900,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 381.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17d7ff0f-059b-47dc-bdf3-19a6073e213f',
    'identification_number': 59223677,
    'item_consumption_1': 255,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 99.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '337f0584-64d4-4065-a34a-03fdac23c00f',
    'identification_number': 74755897,
    'item_consumption_1': 64.84,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1885.91,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7713db6b-6bd4-4315-9316-98cb09fb4718',
    'identification_number': 39261565,
    'item_consumption_1': 3328,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1943.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '213a0e87-280c-49e7-b5df-4cb87dde5329',
    'identification_number': 44028601,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f49783de-470c-4db0-8b42-a75c9c52c956',
    'identification_number': 96383038,
    'item_consumption_1': 4529,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1766.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e96af62a-5fde-4ad2-a212-14db9712e2d4',
    'identification_number': 86490087,
    'item_consumption_1': 2010,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 784.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f929348b-133b-48a2-b4ae-aba094e10d79',
    'identification_number': 12426393,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf3c57ec-11b9-4025-b2ec-d7323a412279',
    'identification_number': 97676950,
    'item_consumption_1': 5885,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2500.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f83e268-95d3-4980-b604-237787d60b20',
    'identification_number': 48630209,
    'item_consumption_1': 131,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 51.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b0e7a69-b059-40ea-8004-105cda48d58f',
    'identification_number': 19450672,
    'item_consumption_1': 133437,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 49658.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41e817a9-3b6d-4d16-9424-8d02d1659e5e',
    'identification_number': 67524362,
    'item_consumption_1': 722,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 306.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '508d25f0-44a6-492b-a3a9-ef7ebc1aa644',
    'identification_number': 103202153,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '597a0c93-15f9-4212-a744-f94bf09975ab',
    'identification_number': 97830593,
    'item_consumption_1': 8435,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3290.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a183a0f4-27ad-461a-bccf-9a79af6caac2',
    'identification_number': 17711754,
    'item_consumption_1': 272,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 115.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bc48476-0003-468f-95b7-626712483e75',
    'identification_number': 82534500,
    'item_consumption_1': 1434,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 560.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85a8e7c3-55a6-474c-b31c-b2577226e9d3',
    'identification_number': 109418468,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56c2634c-aed4-4c5f-85d3-315f68bdea08',
    'identification_number': 57046450,
    'item_consumption_1': 6068,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2577.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce25645b-9946-49aa-92a0-a489c670936b',
    'identification_number': 63897148,
    'item_consumption_1': 52,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 20.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'be38cea7-7c2a-444d-b151-120cb9bb6b18',
    'identification_number': 84479175,
    'item_consumption_1': 554,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 235.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ece8f2f-dbec-456e-9d76-5006d479c8dc',
    'identification_number': 100637612,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9567329-f8a3-45fa-9959-5fd81b6d11dc',
    'identification_number': 23555661,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0248d6b7-ed3f-40a5-9cec-61c6da1d7e51',
    'identification_number': 9211225,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 152.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '568b2b7b-9b85-490e-b6f8-4234220d2173',
    'identification_number': 18623549,
    'item_consumption_1': 1741,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 680.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09f4c37c-945c-40a8-a793-075227c43f54',
    'identification_number': 94029539,
    'item_consumption_1': 64.74,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1883.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '78b02452-cea6-4c5c-b511-412a56b79c14',
    'identification_number': 106046691,
    'item_consumption_1': 3537,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1380.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ef01c66-ed98-49ab-8406-77262b2964a1',
    'identification_number': 106460323,
    'item_consumption_1': 5520,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2345,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1819cf1-893f-4207-a1be-5007a2cd0c4e',
    'identification_number': 100854982,
    'item_consumption_1': 64,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1861.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3bae4a9e-3356-4ef4-b9e1-53a1c16c33f2',
    'identification_number': 78143977,
    'item_consumption_1': 23.86,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 693.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '282985f7-433e-41d0-8fae-8ab0e73920f0',
    'identification_number': 106592432,
    'item_consumption_1': 212,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 82.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c140de9-1e98-487e-a55c-da80674e340c',
    'identification_number': 69966710,
    'item_consumption_1': 15990,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6777.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1200e60f-e4d6-4c31-9214-e4d6923235b5',
    'identification_number': 95992871,
    'item_consumption_1': 5711,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2417.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb5cee9c-d1db-4392-9389-4ebacefe1b50',
    'identification_number': 14333910,
    'item_consumption_1': 125.46,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 3649.12,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b3381d5a-80e5-4153-876a-fcf09a1d120d',
    'identification_number': 106532880,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '221b1ea1-c827-43ec-b208-d32ecaf33cc7',
    'identification_number': 39515974,
    'item_consumption_1': 8292,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3240.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1dcbf7d-4351-4adc-9781-fb1aa41f1d21',
    'identification_number': 13924613,
    'item_consumption_1': 1531,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 894.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a07d01c1-edd1-4d45-beb1-b109493f45bf',
    'identification_number': 99991276,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 45.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aba3891c-b084-416e-b990-516442a50d48',
    'identification_number': 73901610,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '02a6139e-bfe9-4024-a4e5-83782329860c',
    'identification_number': 16978293,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 130.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8dda35b0-a156-4b44-ae7d-2b2b659ede52',
    'identification_number': 3030784010,
    'item_consumption_1': 0,
    'item_description_1': 'Parcela Art. 113 01 / 02',
    'item_total_value_1': 66637.62,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '02dd5410-798b-430f-bfc7-05bc697518ee',
    'identification_number': 59422009,
    'item_consumption_1': 5073,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1887.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8621ff2-9160-4d2d-8123-a69fe8db25e9',
    'identification_number': 32403011,
    'item_consumption_1': 1.73,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 41.28,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fde2b50a-b316-4eb8-ae59-99b3082862de',
    'identification_number': 8661758,
    'item_consumption_1': 19491,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2487.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebf4a5d6-50c8-47fe-a01e-e0b3d6672d16',
    'identification_number': 103337300,
    'item_consumption_1': 2397,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3626.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6a16f59-cb85-498b-a763-88c8c0c88e66',
    'identification_number': 105796506,
    'item_consumption_1': 795,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 311,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1ac4180-8f9b-4fd4-b301-49e1cc644254',
    'identification_number': 90439325,
    'item_consumption_1': 6.36,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 726.43,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1c0b3180-4431-4b69-b703-3f6808e97461',
    'identification_number': 110554329,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f3f625dc-212f-43f5-af8a-da107452eb80',
    'identification_number': 44502931,
    'item_consumption_1': 8.86,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 515.4,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '1dc3191d-662b-4bd5-8d57-141bb7c35c92',
    'identification_number': 70509204,
    'item_consumption_1': 4072,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 519.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9591ee31-3ee2-4527-88e0-1d61d5e02932',
    'identification_number': 17578582,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9062c732-e479-4f38-9166-e13a1f60c5fe',
    'identification_number': 14448726,
    'item_consumption_1': 164,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 64.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '352a18ab-a44e-4083-b3df-f5d0c6d1bc23',
    'identification_number': 16570936,
    'item_consumption_1': 60.9,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3477.98,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f467f64f-7f1f-4dc3-967b-9f364c3f37db',
    'identification_number': 7752628,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 056/084',
    'item_total_value_1': 52.55,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': 'a70ed594-00b0-4359-841d-e7a000759de2',
    'identification_number': 14333910,
    'item_consumption_1': 109.91,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 6276.95,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '353e6451-b067-4336-9dc8-60cde7d1bf8b',
    'identification_number': 97752320,
    'item_consumption_1': 582,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 246.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc8ca757-fcf0-49ff-98fd-7e09ffa82772',
    'identification_number': 108654990,
    'item_consumption_1': 2611,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1018.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5e907fd-3bdc-4531-b658-24a871f92c0d',
    'identification_number': 35211229,
    'item_consumption_1': 776,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 303.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14959bc7-990f-4c3e-b6bd-a6f22bc58361',
    'identification_number': 63964112,
    'item_consumption_1': 214,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 124.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c7ff55e-f400-4e06-9b8b-53bec4fd678c',
    'identification_number': 9027092,
    'item_consumption_1': 3233,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1888.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1510d17a-e186-45a6-9473-406ed235dcf0',
    'identification_number': 16699696,
    'item_consumption_1': 39,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 58.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '992da29b-a1fd-4730-85d1-a5b62b85bc21',
    'identification_number': 43001300,
    'item_consumption_1': 1087,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 460.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdf23f6a-c607-4b65-a6c8-520e439edbca',
    'identification_number': 109635914,
    'item_consumption_1': 66.71,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1940.31,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8ee507ba-9251-48e8-93b6-afac9a1c677d',
    'identification_number': 9257780,
    'item_consumption_1': 4345,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1699.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1248d5f7-5ce3-45fa-9a94-3c77358128fc',
    'identification_number': 16570936,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f76905da-a595-4ca8-89f4-a1043c7cb0c4',
    'identification_number': 32266847,
    'item_consumption_1': "'-231.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 01/2023',
    'item_total_value_1': "'-80.37",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'e2f234dc-acb7-4ff7-b829-16452afb7fbd',
    'identification_number': 14689146,
    'item_consumption_1': 36.65,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1065.99,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4a30af2c-b7c6-416b-b90f-b602ebc388a9',
    'identification_number': 91075700,
    'item_consumption_1': 34,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 13.29,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c1b099de-17d0-418e-a313-6ee9c3b326a5',
    'identification_number': 104642505,
    'item_consumption_1': 4465,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1739.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f8fa329-f4b4-428d-b38d-32aa561e5753',
    'identification_number': 17919657,
    'item_consumption_1': 4071,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1727.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'daf40430-4cca-43e2-81b8-97982e6d5d8c',
    'identification_number': 28259513,
    'item_consumption_1': 1399,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 817.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '981958c0-f8bc-4c79-912b-f044be0167d8',
    'identification_number': 80268250,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 115.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '848cbeae-8434-48ec-b797-120eda267d71',
    'identification_number': 100185681,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '334b213d-89ad-4772-b622-616c7ea11f4a',
    'identification_number': 66423759,
    'item_consumption_1': 205,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 79.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4ebf307-290a-43d3-898e-14b538b744cd',
    'identification_number': 73822671,
    'item_consumption_1': 9805,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3648.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '998f18a8-7dfc-4f51-8da8-52089b641d8e',
    'identification_number': 33235953,
    'item_consumption_1': 451031,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 57567.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28f68097-2a43-4a1b-bdd0-d75c2dec06be',
    'identification_number': 95633359,
    'item_consumption_1': 7449,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 950.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7941e1f-58c2-4fe1-bf19-4cea18ebef26',
    'identification_number': 106397281,
    'item_consumption_1': 1917,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 749.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0881d74-657c-4b02-b4b2-b5a2f754995c',
    'identification_number': 46021191,
    'item_consumption_1': 39572,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5050.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f07fa510-2e8a-4386-9895-3c66e786dde6',
    'identification_number': 56544545,
    'item_consumption_1': 0.37,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 17.35,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7e3cb965-e4d0-4c47-aecf-f7026519d8eb',
    'identification_number': 109635914,
    'item_consumption_1': 1981,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2996.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce63aafd-c193-4f5f-aeff-45b44851a244',
    'identification_number': 73887692,
    'item_consumption_1': 7.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 189.87,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5620ef7c-95b8-42b5-865b-fe0df6d5915b',
    'identification_number': 88788768,
    'item_consumption_1': 2254,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 956.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5a77654-7ee2-452d-ade2-2780ce694890',
    'identification_number': 91627184,
    'item_consumption_1': 23194,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 9047.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '385dea52-f4f8-470d-a33b-5a6d807a8534',
    'identification_number': 36752118,
    'item_consumption_1': 731,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1105.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49f1c539-8d19-4240-8ad7-fa16ed9a8e1c',
    'identification_number': 92141200,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f7096813-9abb-4bf2-942d-a910dbeb5500',
    'identification_number': 41640870,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '760766d7-f941-4698-8ec3-fa6762a81c9b',
    'identification_number': 91024897,
    'item_consumption_1': 1902,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 742.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '743dca0f-931e-40b4-b281-20ac65c96a24',
    'identification_number': 30085918,
    'item_consumption_1': 17144,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6380.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89b9083a-8e30-4742-8f82-43403f217ab5',
    'identification_number': 23542438,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 114.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ab4c570b-b194-4aa4-945a-885c3811c35d',
    'identification_number': 94984778,
    'item_consumption_1': 4315,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1685.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44054e96-8a68-486c-8a7c-4171953693b1',
    'identification_number': 66557402,
    'item_consumption_1': 1489,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2252.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9e892d8-3359-4801-94e1-933614635716',
    'identification_number': 32500459,
    'item_consumption_1': 1188,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 151.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '271984cd-55e0-41fd-809c-87b08ef3b05a',
    'identification_number': 99628554,
    'item_consumption_1': 885,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 375.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fee9c7c-e536-44e2-b152-f107595a03c6',
    'identification_number': 31818030,
    'item_consumption_1': 1154,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 451.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4189461f-d54f-468b-b631-76f42e24ad16',
    'identification_number': 31639267,
    'item_consumption_1': 1301,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1968.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '319efecd-20cc-4cd8-bf35-387c431af184',
    'identification_number': 14354306,
    'item_consumption_1': 11.95,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 285.03,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '02c20b04-6cb8-4277-8b3a-36ad0cfc76be',
    'identification_number': 109487877,
    'item_consumption_1': 6870,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2556.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6e5dcfd-37a0-4b6d-8c66-ee04dfb70311',
    'identification_number': 13935739,
    'item_consumption_1': 43.73,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1271.91,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8a44d2cd-e9ae-4fb3-a346-74b4e6ba1e55',
    'identification_number': 87456583,
    'item_consumption_1': 1426,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 605.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6c0321c-a36d-4e4f-901d-b626b966bcf2',
    'identification_number': 87412233,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 072/080',
    'item_total_value_1': 3,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '8c385dfb-84e7-4546-9aaa-e5443067fa39',
    'identification_number': 105168114,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '002ba696-ef6f-42ce-b7de-dc0a98fb7ed5',
    'identification_number': 8918074,
    'item_consumption_1': 1486,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 630.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d191335-b14d-4f4e-94eb-7958e9b8abde',
    'identification_number': 103840923,
    'item_consumption_1': 25092,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9337.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d1cdd36-1ac8-4bfa-ba07-c94dbd2ec236',
    'identification_number': 76800563,
    'item_consumption_1': 8237,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3213.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd82cda79-541f-46d8-aa6d-3a529b7e0c7a',
    'identification_number': 40435288,
    'item_consumption_1': 2964,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4483.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3c020df-2074-49f8-bd46-05dbd9eeafe5',
    'identification_number': 11342870,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4e15c2af-f3e0-4b91-9d4a-8cb446880971',
    'identification_number': 30168961,
    'item_consumption_1': 332,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 129.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed9721c2-1a27-4b0c-bb05-2de0413e0803',
    'identification_number': 75836491,
    'item_consumption_1': 7426,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2897.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7355d05-883e-42db-9697-bd7be80d1851',
    'identification_number': 20024142,
    'item_consumption_1': 2250,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 879.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a6c6258-b909-491e-94a4-687ab86d37a2',
    'identification_number': 30543860,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c1c38d5-3bbc-40ab-b659-4d696be45fd6',
    'identification_number': 102404070,
    'item_consumption_1': 24.93,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 594.61,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'efa19f7f-7081-49af-a05b-c46f3752b86f',
    'identification_number': 40504077,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '51ec8aa0-9e8c-475c-983c-311d0c8e7fbc',
    'identification_number': 32629095,
    'item_consumption_1': 473.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 13771.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fa893cc7-a3eb-4bd2-8323-4f7f3edda591',
    'identification_number': 109675185,
    'item_consumption_1': 11.51,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 334.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5166e393-7a31-4c3a-bbce-a007fd08d6a6',
    'identification_number': 105323640,
    'item_consumption_1': 9609,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4080.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb49b2dd-e2ba-4b40-baaf-6073537480ef',
    'identification_number': 91392454,
    'item_consumption_1': 5263,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 671.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a23a447-e2f2-40f3-a7c7-112ed2333f6a',
    'identification_number': 60767928,
    'item_consumption_1': 27689,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3534.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '709feb51-9881-4cc9-b9b0-d5b4031f8c5b',
    'identification_number': 84768754,
    'item_consumption_1': 898,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1358.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '157c8355-8e60-4be0-a98a-6b8bc632fff9',
    'identification_number': 102014990,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO (02)',
    'item_total_value_1': 20.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f4dcb0f6-8557-4769-b69f-4949caa487df',
    'identification_number': 107956403,
    'item_consumption_1': 2371,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3586.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '798be76b-e7f2-478d-a737-b416231cace0',
    'identification_number': 48999784,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '452ed34a-dfc9-48d8-998a-569f9e412bef',
    'identification_number': 91355540,
    'item_consumption_1': 4.29,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 249.55,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '931c164f-b97b-4889-91d8-f636cc54adff',
    'identification_number': 91064058,
    'item_consumption_1': 1243,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 528.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75947b1d-1e4f-4f14-b881-972bd5dfea0c',
    'identification_number': 1272543,
    'item_consumption_1': 2747,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1071.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7cca586-4a29-440c-bb47-4eaa1aa4aa91',
    'identification_number': 4334671,
    'item_consumption_1': 11.75,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 280.25,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '57ffabaf-3f23-40f4-a5b7-a0be1f00a808',
    'identification_number': 70447330,
    'item_consumption_1': 4848,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1804.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f91a6054-9f6c-478c-8164-de00a2d68d67',
    'identification_number': 108565726,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '663a3c32-47da-452f-9f5b-dde3f15cd580',
    'identification_number': 70000662,
    'item_consumption_1': 2234,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 870.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4edee5c-2640-4d4a-a6b7-01835adfa911',
    'identification_number': 108309835,
    'item_consumption_1': 20945,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7794.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cd31374-795c-4320-89dc-96427d4f9479',
    'identification_number': 43723985,
    'item_consumption_1': 2730,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1067.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22b55943-e00e-4e82-a032-c77b712b89a6',
    'identification_number': 88788369,
    'item_consumption_1': 1650,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 699.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b8a1bc1-9a28-46a5-b9b5-3cec4907661d',
    'identification_number': 38847280,
    'item_consumption_1': 376,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 147.11,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'be6b29fb-f62b-4a6f-a053-1813461a8267',
    'identification_number': 15981452,
    'item_consumption_1': 798,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 1373.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61ef6508-9671-4958-918c-db69f98a2eb5',
    'identification_number': 106940210,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42e7fac4-1c2a-46eb-b0d7-7be6f381346d',
    'identification_number': 8115796,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '099226dc-3e91-41a9-b1ff-970c48f7520f',
    'identification_number': 16095200,
    'item_consumption_1': 4977,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2107.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '782a0730-948a-4f8d-8ff7-c47220a68133',
    'identification_number': 9027092,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eb6451df-47a6-4b44-96ee-28da38a52be5',
    'identification_number': 68029098,
    'item_consumption_1': 3455,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2017.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f270bdf-9db2-457b-ab11-cc85d0dd6921',
    'identification_number': 102404070,
    'item_consumption_1': 12765,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1629.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61b026c9-7215-4e2c-855d-baa7bbc75b53',
    'identification_number': 94814805,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3110031a-0bff-43c5-ad6c-d1202e3b591c',
    'identification_number': 77008421,
    'item_consumption_1': 6715,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2852.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22e663ff-56e1-448c-a649-fae62c4bb549',
    'identification_number': 14389754,
    'item_consumption_1': 3.09,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 144.72,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c726ccca-fbbf-49ad-8dd4-38e067317c0c',
    'identification_number': 104457260,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b87f54f-8e30-40b8-b81c-a9311fd1772a',
    'identification_number': 92603360,
    'item_consumption_1': 4853,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2057.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccb46ff6-14e3-4a6d-a4b0-cdf0159c7faa',
    'identification_number': 41082605,
    'item_consumption_1': 1037,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 439.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7cdca071-e278-4588-b400-f81c2f56eacf',
    'identification_number': 97005304,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 0.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b9d2a4d-5dcc-48dc-843c-a0263f07d08a',
    'identification_number': 68559593,
    'item_consumption_1': 628,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 244.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15747279-dd17-418f-8411-5d0a701758c3',
    'identification_number': 35322101,
    'item_consumption_1': 16525,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 24999.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b196cfcf-e254-4e3f-a8f4-9ad86a6dfbc9',
    'identification_number': 78234646,
    'item_consumption_1': 98,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 41.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb5b19c6-8e6f-4585-81ce-34a0706a8c18',
    'identification_number': 43444229,
    'item_consumption_1': 6411,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2497.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '794b7329-3aef-49ee-9761-ee7795a399c7',
    'identification_number': 92003311,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11b20c54-e24c-4373-a7be-8ee6a2a6405e',
    'identification_number': 49626876,
    'item_consumption_1': 601,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 254.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7f5457f-c956-4d1f-a031-85a6d0686893',
    'identification_number': 111219248,
    'item_consumption_1': 552,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 835.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '409c22f0-e5a0-4dbd-beb1-b50c561f6ad1',
    'identification_number': 97830593,
    'item_consumption_1': 8435,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3576.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35f8b9d1-c932-43de-b597-a8f81ba49341',
    'identification_number': 99786796,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd4d2263c-5a59-47e9-b100-1cebf7bd789d',
    'identification_number': 93580061,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7063cfc1-783c-4ceb-89df-1287c5e26cc1',
    'identification_number': 44443498,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca3c8e68-84a8-4abe-85fb-12d35408ee4b',
    'identification_number': 40429458,
    'item_consumption_1': 211302,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 78635.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2779a31d-bc3d-4eaa-8b93-bdb031f5d26c',
    'identification_number': 104309555,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6187.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e844593b-fd0b-4d33-b7b8-ce6b8a516763',
    'identification_number': 17372674,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 122.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9fa8fc1-8f28-409b-97c4-66c951a995cc',
    'identification_number': 111014468,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9eaab05e-ce19-4c7a-b4c1-a4fb9b728db1',
    'identification_number': 107622823,
    'item_consumption_1': 2164,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 845.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0907f56d-5dfa-40a7-a52e-ce440872b729',
    'identification_number': 10293540,
    'item_consumption_1': 872,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 369.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c2c6c91-fef1-4c28-ba19-f6575e43a45a',
    'identification_number': 16961757,
    'item_consumption_1': 10615,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4500.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2e1e812-9cd9-4e32-bf7b-5fee0cff12d4',
    'identification_number': 39678318,
    'item_consumption_1': 1846,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1078.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9664c56c-140c-489d-9729-74022f68ff27',
    'identification_number': 92994105,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '248e327c-a78f-4683-b072-97f45511b039',
    'identification_number': 45131171,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35ee7b0b-6050-443d-955a-4b1ab99f4fc7',
    'identification_number': 33670420,
    'item_consumption_1': 6934,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2580.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d2e59b0-da56-4e42-a222-d56077dd2539',
    'identification_number': 109018451,
    'item_consumption_1': 51.07,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1218.04,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f1a4cfe8-0893-4bec-945e-f314ada6b053',
    'identification_number': 95720022,
    'item_consumption_1': 8982,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3808.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d440623-85d0-459f-a698-3725e983bfd0',
    'identification_number': 15561054,
    'item_consumption_1': 768,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 448.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b409b157-7279-4e34-a0d0-469f7414d533',
    'identification_number': 98831968,
    'item_consumption_1': 2294,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1339.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '736a2317-6e6d-4c7d-b54e-fb2c27a596aa',
    'identification_number': 44001479,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c35a2627-c0de-4faf-a15f-1589ab71bfcb',
    'identification_number': 16246780,
    'item_consumption_1': 1204,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 471.08,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '81728891-aa4b-4ba9-b1ef-bc3f7cda97ec',
    'identification_number': 6208282,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a28bad07-e48e-4bd5-8ff8-ce48003eaf84',
    'identification_number': 5605369,
    'item_consumption_1': 7050,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2987.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '740d6f34-83b1-4a20-b39f-c156672df9ca',
    'identification_number': 105479187,
    'item_consumption_1': 1584,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 673.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31533b64-acbf-43f1-beef-0f35911161d2',
    'identification_number': 109449045,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '33f45fb1-c16e-4cca-942d-08c9bf219dbf',
    'identification_number': 62304208,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdbc095f-ffbf-4f59-bf64-f26f88bfc141',
    'identification_number': 96197080,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6ee41da-22a7-494e-9989-f6f2548dfc0f',
    'identification_number': 50776029,
    'item_consumption_1': 11166,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4155.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90365579-27ab-40cc-b221-c997c578e594',
    'identification_number': 44443498,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '375aca08-d11d-4541-a2c7-d63becfd06bd',
    'identification_number': 10977007,
    'item_consumption_1': 1873,
    'item_description_1': 'Energia Ativa Consumo kWh',
    'item_total_value_1': 1454.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a995dc1-cccb-4867-8670-24436e076fd5',
    'identification_number': 10103554,
    'item_consumption_1': "'-500.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-159.87",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '20334816-b60b-4d76-98c8-a3cd37ffcee5',
    'identification_number': 97404713,
    'item_consumption_1': 1001,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 425.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7990606d-b46e-4962-b0ff-c4eab515801f',
    'identification_number': 108309835,
    'item_consumption_1': 2294,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1339.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53763325-566a-4a7e-815a-8222646cf2c8',
    'identification_number': 18267211,
    'item_consumption_1': 372,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 562.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd702ebf6-8b0a-4ff3-b32a-3a7a05c353ae',
    'identification_number': 54453844,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '30505c2a-586c-4903-a558-e547c26cd520',
    'identification_number': 15071987,
    'item_consumption_1': 56,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 21.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '739838f0-e6fc-4dcb-8780-f1b82cd0c7e9',
    'identification_number': 44461461,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c2e37d9f-a88b-497d-b17f-5e12a2420afc',
    'identification_number': 106814893,
    'item_consumption_1': 6077,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2575.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56030250-7371-450a-ac35-077ae5f7dba7',
    'identification_number': 17774160,
    'item_consumption_1': 1112,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 433.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ad5b552-8ca8-4765-b6d8-2ed429cdbdc0',
    'identification_number': 99801736,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4cac41a6-b713-44c2-b469-e6441a6e76c7',
    'identification_number': 98624792,
    'item_consumption_1': 563,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 851.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71bb4dc3-e665-4769-92aa-67b55a10316b',
    'identification_number': 46761101,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3ff0f356-74ab-49a3-8437-9acb1fbf4b51',
    'identification_number': 10774,
    'item_consumption_1': 630,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 558.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9608dd0-9dff-44d7-a936-d9ec85a3901a',
    'identification_number': 9063455,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 64.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c04120c6-9d08-4f56-be73-577e70c8a1d5',
    'identification_number': 110636392,
    'item_consumption_1': 1053,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 447.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b95972e-85e4-421a-9f6c-572ef1f5bee2',
    'identification_number': 14232979,
    'item_consumption_1': 13.35,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 318.42,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'eeb5b5a5-ef08-4e3e-944d-23c5d847d4ed',
    'identification_number': 75768879,
    'item_consumption_1': 23717,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8826.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c40fc8a4-0422-49a4-9dfb-cb94b4858162',
    'identification_number': 74865145,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 347.02,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4ce7c336-59fc-4b5c-a150-1a3b32336509',
    'identification_number': 51210533,
    'item_consumption_1': 18686,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6953.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e7b2ab1-ab27-4e03-bf51-137c9d9bc6d2',
    'identification_number': 91392454,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7ba096fc-604e-4f92-826c-05b44404807a',
    'identification_number': 14662655,
    'item_consumption_1': 971,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 411.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '755b24c2-6a45-42b7-ad17-8eee229ff50c',
    'identification_number': 70064164,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.06,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd6dc0c62-2662-469f-a11f-40743dd393e0',
    'identification_number': 37328751,
    'item_consumption_1': 12172,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5168.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd339f156-aa89-4cf0-b4cc-8f0138ccbfa0',
    'identification_number': 46886320,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2802b367-2fad-4f93-955a-c02a4bde8325',
    'identification_number': 51169530,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 75.2,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '8f76cdd0-2488-4314-89f8-5af0d5cc5673',
    'identification_number': 8084661,
    'item_consumption_1': 437,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 255.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd5e71a3-8588-4ed3-8328-9f720ae04e13',
    'identification_number': 34098380,
    'item_consumption_1': 588,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 248.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd682672b-8155-4650-b1f9-3d03da2bab92',
    'identification_number': 101712626,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '556a1a1a-81c9-4669-b920-204088a19a53',
    'identification_number': 35559217,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6bb714df-c313-477b-b5f9-5d1ae4a46cb0',
    'identification_number': 72771356,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2833b29-27f2-49f0-8cbb-9bf91fa4b62b',
    'identification_number': 79937667,
    'item_consumption_1': 10108,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1290.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c169498-a4d2-46e3-b47d-7d8f31937661',
    'identification_number': 99541360,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f094bb64-221c-4494-a68d-7d158c67f246',
    'identification_number': 16093526,
    'item_consumption_1': 5946,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2316.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27211d9e-e0ef-4e74-aeb8-3bf4e2f608cc',
    'identification_number': 110755170,
    'item_consumption_1': 2203,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 935.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1f6325c-cd4b-4b00-ada3-72268ec56d94',
    'identification_number': 55454500,
    'item_consumption_1': 73697,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 9406.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2030b619-44c7-4ec4-a414-520f85efa2f9',
    'identification_number': 110411196,
    'item_consumption_1': 6197,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 790.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cc317ea-6736-424b-95df-77bebe9f3e0e',
    'identification_number': 93451962,
    'item_consumption_1': 3509,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1485.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07ea2f10-fe67-47be-8467-45ae62efe65e',
    'identification_number': 103367268,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '340280eb-a4b6-4c7e-9080-542ba852bb51',
    'identification_number': 80671551,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9f05e7ad-40f4-4b0f-a2b5-a3acd77f4e60',
    'identification_number': 58734899,
    'item_consumption_1': 3146,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1170.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4df634f8-32e1-4397-b163-a4ed27cccd83',
    'identification_number': 58734210,
    'item_consumption_1': 10857,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1385.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14cc8e49-519d-42ee-8dfc-bad3c35c655a',
    'identification_number': 72858710,
    'item_consumption_1': 34,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 13.29,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9411680e-c89a-401a-a120-64308f1a3883',
    'identification_number': 108300412,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bd6ae5a-8580-4425-9ad5-9ecf6dd6949b',
    'identification_number': 27125610,
    'item_consumption_1': 16178,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6318.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9169760-1236-454b-90fe-222aa29b35c9',
    'identification_number': 107791463,
    'item_consumption_1': 375,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 146.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b1bb9cc-d168-4583-b5f9-72960dab3b20',
    'identification_number': 35959037,
    'item_consumption_1': 6405,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2494.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '268697a5-6b7b-4b51-970c-c3260c311a2a',
    'identification_number': 100318550,
    'item_consumption_1': 8649,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3669.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23e2a518-6b1a-45cd-8842-14d10fcda9c2',
    'identification_number': 108646785,
    'item_consumption_1': 2670,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1041.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de88dbaa-f4be-41cf-b1c8-28c8c351055a',
    'identification_number': 31846882,
    'item_consumption_1': 119558,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 15259.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '555427a5-9da9-44ff-a666-05e04f586ee2',
    'identification_number': 92334865,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 830.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fcc12def-e91d-431e-b13c-6b7b7d82b750',
    'identification_number': 109530608,
    'item_consumption_1': 513,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 299.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a260b090-b614-4dc4-b8c0-155a4985775d',
    'identification_number': 92938418,
    'item_consumption_1': 8066,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3421.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2350fac1-2ea4-4249-97fb-6175e26af497',
    'identification_number': 948748404,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c1558be1-ee24-4327-aaae-cd4a24db101d',
    'identification_number': 93636423,
    'item_consumption_1': 7923,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3095.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11128271-2bff-48e0-b48d-24331c32c51b',
    'identification_number': 15721400,
    'item_consumption_1': 7202,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2814.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bc45089-1ae7-402c-a53e-c1aa32680f63',
    'identification_number': 79937667,
    'item_consumption_1': 90623,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 11566.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '441592b5-c8da-4859-a150-b6e21216c19d',
    'identification_number': 37528319,
    'item_consumption_1': 4080,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1732.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25d73bc4-a583-466c-8004-31d4bece1a4b',
    'identification_number': 94384126,
    'item_consumption_1': "'-971.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-338.06",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '1d7d0dc5-dd2d-46b3-b9e7-b4216f3a28ff',
    'identification_number': 34617426,
    'item_consumption_1': 177217,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 65950.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c8055ca-92f7-47fd-b578-6de923fafc92',
    'identification_number': 67097898,
    'item_consumption_1': 1083,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 632.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe288f85-c348-4333-97ef-971809057d94',
    'identification_number': 31437695,
    'item_consumption_1': 1.837,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 696.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1f8dc9b-cd8b-4345-ad53-a114e146ee86',
    'identification_number': 60767928,
    'item_consumption_1': 2213,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 865.88,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '32d7167a-4176-40c5-b0ca-90833c8749a2',
    'identification_number': 30085918,
    'item_consumption_1': 0.99,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 46.37,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'bc81da2f-455e-43f8-ba75-eda94324d817',
    'identification_number': 5680638,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e3723a7c-7878-48b3-8347-405b28acdc18',
    'identification_number': 90934377,
    'item_consumption_1': 1308,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 556.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93c52fab-c1f6-41c8-81e6-1b3361915bc5',
    'identification_number': 41314352,
    'item_consumption_1': "'-12.958",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-4153.37",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '35fdaa9c-752d-4859-82ab-493a0e69f57c',
    'identification_number': 100240682,
    'item_consumption_1': 963,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 376.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd34b52a3-932f-4481-9aba-80dfc7ff9b2d',
    'identification_number': 91862833,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '03627c64-8ce3-4e3e-b6b2-1caebea1a8c7',
    'identification_number': 68135580,
    'item_consumption_1': 565,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 854.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b1ddfd0-8f62-4c88-bf1f-163841bc4fdf',
    'identification_number': 56092326,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61e30ace-6acd-46d3-9d9d-0a6d084462d2',
    'identification_number': 94496919,
    'item_consumption_1': 1628,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 635.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '887e0572-bf1d-484c-b93e-6e2a98429fcb',
    'identification_number': 16105869,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '146d11be-5a95-482d-a095-e6bbd0dbd4b1',
    'identification_number': 73822671,
    'item_consumption_1': 1484,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 866.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f269f2b4-cc6c-4f27-8b91-9c9f13634306',
    'identification_number': 78564662,
    'item_consumption_1': 36.7,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2095.92,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'bd9ce7dd-1fc8-46a1-aa72-db09ae2250e1',
    'identification_number': 16003500,
    'item_consumption_1': 52,
    'item_description_1': 'Demanda faturada',
    'item_total_value_1': 2021.88,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6acbf630-f319-4b09-a983-9556163078a3',
    'identification_number': 39452336,
    'item_consumption_1': 129.15,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3756.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4ef9edfb-4f0b-40c7-9e96-3e5f96547581',
    'identification_number': 92950310,
    'item_consumption_1': 9,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6113cfe1-40a0-4759-ac43-cd543cb6c24a',
    'identification_number': 101835981,
    'item_consumption_1': 31739,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 12391.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c49f0cf1-1a05-428c-90ac-aab7d86faf81',
    'identification_number': 59455896,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-2.68",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'cafe4be7-0144-4b8d-a1a6-b3d7b5079b4e',
    'identification_number': 106607120,
    'item_consumption_1': 1195,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 465.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f49cb8ab-1c43-44c7-bffe-196cffe740e7',
    'identification_number': 18188109,
    'item_consumption_1': 1866,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 793.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dece3973-25c3-4a9f-8df9-d219e849020d',
    'identification_number': 9742069,
    'item_consumption_1': 3402,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1439.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06ef10d7-076c-4cce-95ec-afeb28bb2da8',
    'identification_number': 94120676,
    'item_consumption_1': 1996,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1165.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbd02d9c-7e72-40a1-af5e-52266ece5d56',
    'identification_number': 32594852,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2a789407-85eb-4079-9e76-eb10e9a239ba',
    'identification_number': 18641547,
    'item_consumption_1': 252,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 106.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc43b868-7b0b-4130-affc-1b364a20f443',
    'identification_number': 74880462,
    'item_consumption_1': 46.05,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1339.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '90550cdd-f028-4f05-ac6c-ef8ff20cab82',
    'identification_number': 58740147,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bef85e02-5352-4ad3-a920-b9b26a4770eb',
    'identification_number': 41290984,
    'item_consumption_1': 5574,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2361.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2914abb1-d60d-46d3-9170-e842c4133eea',
    'identification_number': 101144555,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2380ab2f-087e-41ee-ba74-e3712a26c856',
    'identification_number': 16386760,
    'item_consumption_1': 3108,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1215.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2908cd6-68f1-4771-a008-8223ada84fa7',
    'identification_number': 37924117,
    'item_consumption_1': 866,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 367.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84e81f00-acf2-443c-aafd-28a87395df6f',
    'identification_number': 109139402,
    'item_consumption_1': 47.37,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1377.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '508f58bb-5a09-4f6b-8a61-dcafa23d2d6b',
    'identification_number': 18006540,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '994748be-dcb7-4bba-aa27-ecf87a4eacc8',
    'identification_number': 16133854,
    'item_consumption_1': 345,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 262.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '729a9e5e-9875-4831-9fed-6c6e5e10c036',
    'identification_number': 49074423,
    'item_consumption_1': 11097,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4129.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55750564-6ba7-475a-acf6-fb002037bb81',
    'identification_number': 45017336,
    'item_consumption_1': 15284,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 23121.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2511e98f-56c0-4d30-ad4f-e0d0f98cc4ab',
    'identification_number': 10549005,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 67.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f61f2fa9-f885-471f-9207-1aae37c0b83e',
    'identification_number': 78143977,
    'item_consumption_1': 6.14,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 146.45,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b4276dc3-a0a5-4586-83fa-2f2b92fbb763',
    'identification_number': 63137399,
    'item_consumption_1': 799,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1208.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76341758-22b0-4f7e-846c-4d7ca1437707',
    'identification_number': 31497861,
    'item_consumption_1': 213,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 83.33,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ecc7be3c-19e5-444b-9e47-0baf24175f63',
    'identification_number': 12629,
    'item_consumption_1': 2269,
    'item_description_1': 'CONSUMO na ponta',
    'item_total_value_1': 5467.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c5c304b-dfd2-4edd-8471-b632fa87128b',
    'identification_number': 92141200,
    'item_consumption_1': 2776,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4199.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9079dbf1-8f3e-4102-a519-cf795dabfbcd',
    'identification_number': 3030784010,
    'item_consumption_1': 195222,
    'item_description_1': 'Consumo Fora Ponta [KWh]-TUSD NOV/23',
    'item_total_value_1': 28727.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27f35b1c-609c-4d51-8c82-fb2268be85d1',
    'identification_number': 3030777242,
    'item_consumption_1': 38.1792,
    'item_description_1': 'Demanda [kW] TUSD NOV/23',
    'item_total_value_1': 1235.82,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '47019bd7-749f-4bde-b9c2-5187f7d55750',
    'identification_number': 53226429,
    'item_consumption_1': 62.13,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1807.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4a5d0250-0fdc-480b-9497-8e672996772e',
    'identification_number': 19452900,
    'item_consumption_1': 16404,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 24816.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebe1ae8b-6107-4083-a229-5ba0ea2584c8',
    'identification_number': 94516014,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70b750c1-b495-4f4e-9e22-9b536d31ee3a',
    'identification_number': 5856930,
    'item_consumption_1': 18350,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7789.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '235b30c8-0f12-4790-a281-b6ecd548d792',
    'identification_number': 60730781,
    'item_consumption_1': 16239,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2072.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d8aef90-2e43-4b2b-82ce-18f6256d236d',
    'identification_number': 32727917,
    'item_consumption_1': 153.87,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 8787.51,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '740948c0-fee5-4bc8-af71-26fb7f8adab7',
    'identification_number': 12918385,
    'item_consumption_1': 11.91,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 346.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1c654c4e-5e42-4b37-aa7e-9dd2cc22e1ef',
    'identification_number': 9790268,
    'item_consumption_1': 119,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 46.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '151c8f5c-aed9-43d0-a898-3bdf559afdca',
    'identification_number': 9992820,
    'item_consumption_1': 3328,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1409.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5a434ef-7e62-447a-8db2-3b359f2add70',
    'identification_number': 84156171,
    'item_consumption_1': 19021,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2427.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34dbd007-8a9a-4b79-aee7-a366922f37d8',
    'identification_number': 20587171,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 605018.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ebf038c-c25d-433d-946b-d26d553b3922',
    'identification_number': 44436637,
    'item_consumption_1': 146.81,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4270.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '84ffdb6f-f66d-4680-ac1a-9e7e19156dcb',
    'identification_number': 65141024,
    'item_consumption_1': 176.57,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 5135.7,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3843baf0-05d4-4ff8-8984-06bf86922457',
    'identification_number': 100203400,
    'item_consumption_1': 126,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 49.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab1a8f16-9eba-462e-b1d4-0ede74656822',
    'identification_number': 38932040,
    'item_consumption_1': 2052,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 799.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1244c4c7-7023-44e2-b964-29eb6219563c',
    'identification_number': 103770968,
    'item_consumption_1': 720,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 306.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a515f35c-e079-4c99-9a9b-0a08e344e81d',
    'identification_number': 104289465,
    'item_consumption_1': 2701,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1053.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a5beb0d-c07e-48d1-a5af-8c981ab12302',
    'identification_number': 53869303,
    'item_consumption_1': 44.4,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2535.68,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '561285ce-aadd-4b62-9780-67e87a97f58c',
    'identification_number': 42404576,
    'item_consumption_1': 3952,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2308.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8931215f-208c-4d67-908c-c1151f69ed85',
    'identification_number': 56189060,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a57c6bbb-0198-40bf-bbaa-141a04ab3a3c',
    'identification_number': 33162557,
    'item_consumption_1': 7018,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 895.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cd07195-9238-443c-b61d-7b961b9b4e29',
    'identification_number': 22424725,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 19.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd59a94a8-d730-403a-b210-abd161075dcc',
    'identification_number': 14755068,
    'item_consumption_1': 500,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 212.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13d7e330-3c99-4a57-ac52-ec1b02367946',
    'identification_number': 93308965,
    'item_consumption_1': 709,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1072.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93b16ffc-b5c0-4f90-baf1-415da53236e2',
    'identification_number': 103001735,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9277e962-6475-4a43-ae29-d07a03216059',
    'identification_number': 39530205,
    'item_consumption_1': 4240,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1794.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd866ea75-5380-41b1-a41c-84b46d0d6ed9',
    'identification_number': 60730781,
    'item_consumption_1': 1740,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 222.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95dae52e-24ea-4b55-bfca-1358aad903d2',
    'identification_number': 18138373,
    'item_consumption_1': 1962,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 250.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e20f0df7-87b3-437e-a938-2e402ab86074',
    'identification_number': 58745289,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2330917-dfde-44db-8537-e9aa6a54ea58',
    'identification_number': 87914808,
    'item_consumption_1': 1168,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 494.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0d05b9e-47b4-4ede-9599-75df1c464bea',
    'identification_number': 94391645,
    'item_consumption_1': 32.1,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 765.61,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fb030032-e930-46ab-beaa-626172231258',
    'identification_number': 12804428,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6958c1e5-94b7-4c34-91c7-c5799a3a2a5e',
    'identification_number': 12918385,
    'item_consumption_1': 3874,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1441.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b64e5a17-e4cb-4ed3-a19e-704519932cd8',
    'identification_number': 59101580,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c1deabba-5e9d-459e-8309-61618b04347c',
    'identification_number': 98925083,
    'item_consumption_1': 10.61,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 253.07,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e81f5d15-e32f-4dab-9e1d-16574eddf882',
    'identification_number': 110001451,
    'item_consumption_1': 552,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 234.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83cf21dd-1337-42de-9ed8-39e977eec70c',
    'identification_number': 102687560,
    'item_consumption_1': 4503,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2629.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e414194-aa28-4d0a-88a0-32e94c32695d',
    'identification_number': 29982685,
    'item_consumption_1': 5403,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2107.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8e22928-5792-4537-824b-bc8807b2dc59',
    'identification_number': 15913805,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac5ea76a-0f2a-4caf-9616-bfd1cd2c07eb',
    'identification_number': 103366512,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '849709d9-ced4-4d86-a75e-2406bd8d34c5',
    'identification_number': 12866,
    'item_consumption_1': 12583,
    'item_description_1': 'CONSUMO fora de ponta',
    'item_total_value_1': 5686.22,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': 'b6392f44-9ae8-4cf7-adf8-64da7c58ef0b',
    'identification_number': 63726432,
    'item_consumption_1': 683,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 289.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '207b3058-0520-43c2-b81d-7b530df6626b',
    'identification_number': 95411437,
    'item_consumption_1': 2737,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1160.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc5462e7-8f0f-4692-87f6-69600710f2cc',
    'identification_number': 14138018,
    'item_consumption_1': 11020,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4101.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eda5af76-03c7-4bec-bee6-1fb49508c698',
    'identification_number': 27316114,
    'item_consumption_1': 65927,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 24534.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8b02184-1754-4511-87ae-b68ec78cf96d',
    'identification_number': 32500459,
    'item_consumption_1': 1188,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 693.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1e01243-0c98-4399-a8cd-34e4baabbf43',
    'identification_number': 105699047,
    'item_consumption_1': 3438,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5201.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17d039b0-96df-464c-843a-09960894618a',
    'identification_number': 56912447,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '53c09cb5-6244-444c-b870-5ce5daf55012',
    'identification_number': 42390001,
    'item_consumption_1': 9788,
    'item_description_1': 'Energia Ativa Consumo Ponta',
    'item_total_value_1': 19197.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5eeb29f0-3097-47f9-b2e0-b2d2f1c14553',
    'identification_number': 100388396,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '56ec2baf-8f15-4e44-8053-5118297e4cab',
    'identification_number': 56699077,
    'item_consumption_1': 943,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1426.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '329d62e0-3bb9-4f87-8578-89c22d35fe42',
    'identification_number': 10610472,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b438a45e-39f0-4723-9a30-b0920b37ada1',
    'identification_number': 100812260,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fc2e3569-4755-4dc5-aeae-5bdf334025c6',
    'identification_number': 26677210,
    'item_consumption_1': 19439,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7234.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3dfbd4c-5822-42ee-b905-5cb16584a1ec',
    'identification_number': 12683841,
    'item_consumption_1': 5678,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2404.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a02ea081-4463-4e8c-a787-5c9c7d4aaa49',
    'identification_number': 15538320,
    'item_consumption_1': 2800,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1095,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00a33cfb-7e8d-46bb-9788-73bc52fbc1ea',
    'identification_number': 103402578,
    'item_consumption_1': 5597,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2184.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd666baaa-a925-45bc-bbcb-7e35a09468ee',
    'identification_number': 39009378,
    'item_consumption_1': 1729,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 733.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bcbf0c57-b46f-41d5-b326-c3ea09ace432',
    'identification_number': 45478260,
    'item_consumption_1': 4210,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2458.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3558bf2-c2c4-4ecd-9c78-131e0cc31b4e',
    'identification_number': 31818030,
    'item_consumption_1': 9.18,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 534,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'd9b61c7a-4384-4987-a6ed-796fbfc9da76',
    'identification_number': 19786700,
    'item_consumption_1': 10943,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4276.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31f94ee2-7153-4275-a447-634deb8236d5',
    'identification_number': 96560339,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '14c05868-6fd2-44f5-8786-298d3d86fcd1',
    'identification_number': 102255830,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1188a871-9fb0-426c-8321-7f545a7a5d49',
    'identification_number': 40026043,
    'item_consumption_1': 106,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 41.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01e57340-1c63-435f-8ba2-43e33af5b86b',
    'identification_number': 63604116,
    'item_consumption_1': 1911,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1116.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '638d28e6-221f-4619-9580-e85b3854c9eb',
    'identification_number': 11078022,
    'item_consumption_1': 168,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 65.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3651cd1-542b-44f5-b83b-db60a386e24d',
    'identification_number': 105418188,
    'item_consumption_1': 347,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 147.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa2c68bc-918e-4e7e-b917-ad53bfda3516',
    'identification_number': 45431027,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5085f684-3838-4074-81d5-bbd8a33554dc',
    'identification_number': 65191404,
    'item_consumption_1': 7.94,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 189.38,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '51cbe3c5-3085-42a4-907d-a111c85f0c49',
    'identification_number': 38227436,
    'item_consumption_1': 1922,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 815.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee1c38e4-01c9-4886-9f28-f82b0527c173',
    'identification_number': 91274940,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '259649ac-596b-462b-9702-e4fc74a13aa0',
    'identification_number': 103617515,
    'item_consumption_1': 1089,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 636,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ad9af92-b782-4049-808e-f290c58159e9',
    'identification_number': 105953261,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'faf6eda5-d98b-474b-88b8-929a5f8ec322',
    'identification_number': 99837188,
    'item_consumption_1': 170,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 71.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bae6d444-1894-49f5-a59c-8ebb12f9c43e',
    'identification_number': 77111311,
    'item_consumption_1': 2285,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 890.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9cac2530-ff8e-4b98-a55e-214a3e684dc2',
    'identification_number': 103306633,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b28ee10d-d550-485c-acb6-f8f202e42573',
    'identification_number': 78234646,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 51.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fd37495b-7238-45ca-b931-6bd031987c89',
    'identification_number': 73829447,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd30fa327-00fd-4c3c-ac99-a77b611da745',
    'identification_number': 93712847,
    'item_consumption_1': 8225,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3489.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aab66a0a-7311-4562-b82d-f2f50aacc8a9',
    'identification_number': 923323694,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68dc54e7-2a9d-4e25-9f39-e7140ee5ca82',
    'identification_number': 17619343,
    'item_consumption_1': 2266,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 951.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c6e3abc-7f43-46a2-9846-5619be6751c5',
    'identification_number': 948747588,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d87e09e-7c5b-4afe-aa58-a3af3ac94152',
    'identification_number': 42469716,
    'item_consumption_1': 664,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 259.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b411fd2-6437-4afd-8d8f-8e826633708f',
    'identification_number': 32153430,
    'item_consumption_1': 14187,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5279.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6fba5c3-1a77-47e9-83e4-ad28edcc7cd7',
    'identification_number': 947580121,
    'item_consumption_1': 12714.6,
    'item_description_1': 'Consumo em kWh Fora Ponta',
    'item_total_value_1': 6624.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3687362-e0de-44ed-9a41-1c9a9396a5fe',
    'identification_number': 19622252,
    'item_consumption_1': 428,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 181.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6dd896ed-bd24-4709-ac5a-c592ad507b3c',
    'identification_number': 109603940,
    'item_consumption_1': 716,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 304.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbe8cc2c-668c-45b3-a8fa-4cd78c26e4c8',
    'identification_number': 7360169,
    'item_consumption_1': 9734,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3797.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d54fd43-2f18-461e-aadf-ffc3592669f6',
    'identification_number': 92912290,
    'item_consumption_1': 150.65,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4381.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '96737994-f912-4e2b-8dc6-11c20e9b3c0b',
    'identification_number': 65141024,
    'item_consumption_1': 15,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 5.86,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'aab37257-1e15-466e-bfc9-c8236cbd8afa',
    'identification_number': 21718989,
    'item_consumption_1': 2926,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 373.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c2d54df-2484-4cdd-ac34-b6e28de501ec',
    'identification_number': 99540355,
    'item_consumption_1': 945,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 401.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99760c19-ebad-411e-8416-256760a3d3d7',
    'identification_number': 99004003,
    'item_consumption_1': 789,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 334.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82014f62-5fcf-4af9-905a-997fd0271f2e',
    'identification_number': 42627117,
    'item_consumption_1': 27895,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3560.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3930f73d-27ca-41fe-9636-0e057289d91c',
    'identification_number': 19450753,
    'item_consumption_1': 911759,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 339308.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dab54afc-a999-405f-830a-c686c76e52c4',
    'identification_number': 26022460,
    'item_consumption_1': 207,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 80.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3664cb9-6e01-4c50-bfc1-e01f8959491b',
    'identification_number': 37854496,
    'item_consumption_1': 2605,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1016.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '297a2edc-64dd-4150-983a-4e105ba80664',
    'identification_number': 32501480,
    'item_consumption_1': 1095,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 639.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e711568-bd8b-4bb0-8474-22d8a8b454e9',
    'identification_number': 38055660,
    'item_consumption_1': 291414,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 108448.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f46f00c-aa27-470d-bc53-874b9741fafb',
    'identification_number': 34645497,
    'item_consumption_1': 15256,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5941.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16749103-3094-4b51-b0ea-be80a958c3ed',
    'identification_number': 95765840,
    'item_consumption_1': 9710,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3613.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6c872e6-4454-4b78-8d82-a191d41ccf89',
    'identification_number': 16125312,
    'item_consumption_1': 1,
    'item_description_1': 'En Exc Realivo fora de ponta',
    'item_total_value_1': 0.31,
    'reference_month': '11/2023',
    'referente': 'Energia Reativa Excedente Fora Ponta',
  },
  {
    'uuid_items': '4bf3f4fe-34d1-4bde-82b3-d0a9d9462df5',
    'identification_number': 9105760,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '36bb931d-0955-46bf-b940-6310ffff03de',
    'identification_number': 77887930,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b5cba7bb-80bf-4b13-8547-cc977e34480d',
    'identification_number': 41314352,
    'item_consumption_1': "'-1.04",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-333.34",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '087c092b-c4b1-44d0-8232-489261534997',
    'identification_number': 91274940,
    'item_consumption_1': 6793,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2878.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9231e551-3b19-4448-aea1-971635a05127',
    'identification_number': 59866969,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '301dca6f-23fc-46e3-a122-6d4cf23df0fd',
    'identification_number': 82521310,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cd522f75-af75-43c0-865c-c1322c48d2c6',
    'identification_number': 100726704,
    'item_consumption_1': 1775,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 693.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'deeeae85-d2cd-4ce2-93f4-b01aacf7f20e',
    'identification_number': 61588555,
    'item_consumption_1': 425,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 248.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1249b13b-7966-495e-995a-997b19598364',
    'identification_number': 5330939,
    'item_consumption_1': 105749,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 13497.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b2e3d85-8b35-418e-b458-6630a11d452e',
    'identification_number': 13164570,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bcb8d274-27a7-4c60-9ff9-10c9badb03c0',
    'identification_number': 45431027,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 20.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff368a89-5a39-4ead-85da-5f72d12a1f3f',
    'identification_number': 10698825,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 144.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '86c1b3af-3613-4d5c-a620-cc934f64fd8b',
    'identification_number': 10072004,
    'item_consumption_1': 2583,
    'item_description_1': 'Energia Ativa Consumo kWh',
    'item_total_value_1': 2005.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc9b681b-fc48-4292-a601-16ab70bf01eb',
    'identification_number': 110019377,
    'item_consumption_1': 6667,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 850.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '044152ff-df96-4d9b-a35d-963823cf4443',
    'identification_number': 14862336,
    'item_consumption_1': 8968,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3337.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6065a73c-ab78-412f-94e3-f980a6639131',
    'identification_number': 77800699,
    'item_consumption_1': 25,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 9.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8c9cdaf2-a714-459c-99e1-5f05fd7e77e5',
    'identification_number': 36235334,
    'item_consumption_1': 32520,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12102.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9963c711-2770-4f3e-abae-abd38e517bed',
    'identification_number': 13008404,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4c77a64-29d4-47e6-811a-83ae885baf40',
    'identification_number': 43699553,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2316c3be-e540-4a61-9c06-6e905f621f1b',
    'identification_number': 40254836,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-5318.0",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '4bd1cab4-ccd4-4831-8937-d5017c8d6bf6',
    'identification_number': 39906035,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c6730479-28db-4957-9b23-1453f2a88a26',
    'identification_number': 67989950,
    'item_consumption_1': 1249,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 487.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23ca5a67-c755-4599-a720-d28e12c6d0ed',
    'identification_number': 29475716,
    'item_consumption_1': 1248,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 530.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd9a1640-9db2-4f80-8ed0-6f638e702660',
    'identification_number': 108331024,
    'item_consumption_1': 120184,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 15339.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'abf278e3-5a54-499f-8785-ec778ca1096c',
    'identification_number': 9823000,
    'item_consumption_1': 54.09,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1573.25,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '890fff2a-f566-4510-afe9-150229b23460',
    'identification_number': 98799681,
    'item_consumption_1': 715,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 303.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b609a14-1c69-4dbc-8b94-d72768fa4e14',
    'identification_number': 93009470,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e0b17133-9824-4e2c-b885-f7021c48ee08',
    'identification_number': 68904401,
    'item_consumption_1': 23135,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2952.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1986eb7f-c65d-4a93-9ed3-55bf433d31d0',
    'identification_number': 22539530,
    'item_consumption_1': 6900,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2933.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5919c3a3-aa03-498b-b069-dc9a886a5c02',
    'identification_number': 65141024,
    'item_consumption_1': 83145,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 30942.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09ca4c53-4f3d-4c1d-8415-8c760b6e51b3',
    'identification_number': 46021191,
    'item_consumption_1': 74,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 28.95,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0bcd0dbe-7aef-45e4-9898-08d2ce57c31f',
    'identification_number': 102507864,
    'item_consumption_1': 493,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 745.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '473bc3bd-c36d-4649-a572-c4c4bbdc4614',
    'identification_number': 65626079,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9fc27b67-01e7-4e1d-bbc5-4c6874a8303e',
    'identification_number': 74311875,
    'item_consumption_1': 44126,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5632.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83d5bb69-13a6-40d9-b3fd-2b26c834e5d2',
    'identification_number': 43195300,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e955e57f-0a55-4e09-8767-e5bc78e37682',
    'identification_number': 43061842,
    'item_consumption_1': 12,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 4.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9c6bb3b7-ca57-4d17-941f-9e8af68b6979',
    'identification_number': 98041371,
    'item_consumption_1': 10377,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3861.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2fd8fdf5-360c-446f-a625-48f2cb30acf8',
    'identification_number': 49867245,
    'item_consumption_1': 1045,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 408.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a65e5cd-ae82-4106-8377-a2067bfab489',
    'identification_number': 104188120,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 554.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ed4a5b2-3797-48f6-9685-89a4bf0fc32c',
    'identification_number': 106814893,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 105.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a055f9f2-c1ec-4431-b5b9-08aadf0d84fa',
    'identification_number': 70080798,
    'item_consumption_1': 9758,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4143.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5bd61f38-e2d5-4ccb-9a5b-eff18dc587b8',
    'identification_number': 51527987,
    'item_consumption_1': 11348,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4223.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a89853a-c5e3-45ff-97cb-71d685c1274a',
    'identification_number': 54072719,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ddf08ea-091b-479f-9931-6a52602297d0',
    'identification_number': 46349030,
    'item_consumption_1': 32135,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 48614.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0603988b-9e48-4f7d-a140-f8a87d9bfe42',
    'identification_number': 78334640,
    'item_consumption_1': 2401,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 306.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '486e0cfd-937f-445d-bdc1-5db59dcee382',
    'identification_number': 103102450,
    'item_consumption_1': 759,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 295.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4542db59-080a-4972-af28-707d3f7550c7',
    'identification_number': 18678,
    'item_consumption_1': 1763,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 956.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26ee0842-dac2-4236-b6fe-9a4828a64909',
    'identification_number': 105186520,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1483.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '779d221a-37b2-444b-9fb2-d7d560c211e3',
    'identification_number': 19305818,
    'item_consumption_1': 971,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 378.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01d928a3-f955-43f6-bbbb-fa7c2bccab6e',
    'identification_number': 26343592,
    'item_consumption_1': 3903,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1524.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c74cd42a-4052-48bb-8d5e-69a625d75ea8',
    'identification_number': 14232979,
    'item_consumption_1': 16.65,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 484.26,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4e97d044-819b-4870-92fe-aa498afed810',
    'identification_number': 38055660,
    'item_consumption_1': 291414,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 37195.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5f5a24a-f6d4-4c7b-8711-8fa629125bc0',
    'identification_number': 93133332,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd81177e2-0e0c-404e-9d5d-a2c072391a30',
    'identification_number': 92638775,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 263.31,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6b6eba8b-a7ea-45e5-a66e-4cced7c9561e',
    'identification_number': 67917283,
    'item_consumption_1': 5697,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3327.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '483c7d51-1f7c-4b1d-a869-b4b77b1a64db',
    'identification_number': 95258620,
    'item_consumption_1': 19268,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 8180.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f0657aa-4e22-4807-b74f-faefa54d918f',
    'identification_number': 12404934,
    'item_consumption_1': 220,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 85.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3774568-90a8-4e57-b1a2-c04cbdb04f73',
    'identification_number': 92003311,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'edd3141d-6396-4d42-8c91-6c2c85b3a6c5',
    'identification_number': 73887692,
    'item_consumption_1': 9711,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3613.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ec367c6-583a-4b7a-b998-fba3b9efc11d',
    'identification_number': 62557777,
    'item_consumption_1': 0.71,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 16.94,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f8b27880-2848-46b4-aad9-598e9df67feb',
    'identification_number': 71395474,
    'item_consumption_1': 3321,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1297.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '443066b9-bb29-4e62-bb20-1df5758ec279',
    'identification_number': 67531741,
    'item_consumption_1': 1337,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 567.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1f8b5aa-1f2a-404a-ae4d-2e2c9e0b76af',
    'identification_number': 12424188,
    'item_consumption_1': 13.88,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 1585.36,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e3d9e623-8844-4bde-9e84-37abe0753524',
    'identification_number': 35718064,
    'item_consumption_1': 1552,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 659.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4606fdaa-0ef9-4cde-bcda-db16f7a70a2a',
    'identification_number': 16127064,
    'item_consumption_1': 9725,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 7386.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b04efef8-aa32-41d9-96b2-1bbca82840c2',
    'identification_number': 12673544,
    'item_consumption_1': 26536,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9875.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1ce6357-dcb2-487b-9995-72d52f14c438',
    'identification_number': 29661129,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fe32483b-8255-488e-8bc0-e38012821c2c',
    'identification_number': 59355573,
    'item_consumption_1': 12,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 4.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '475e7770-4952-420c-91c8-0277e6ae2012',
    'identification_number': 102194424,
    'item_consumption_1': 951,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 404.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3be8c375-8fc2-4c09-bf7a-ab4737ffac09',
    'identification_number': 89679229,
    'item_consumption_1': 360,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 140.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e661418-c057-4688-b7e5-7aa9f002b7de',
    'identification_number': 48482897,
    'item_consumption_1': 19240,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2455.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55e2f245-1f00-4022-aa8d-c359d02c4226',
    'identification_number': 17108462,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '828ce9b5-e7ea-4b97-9897-e3e38b9ad48e',
    'identification_number': 107438755,
    'item_consumption_1': 339,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 143.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65acb960-5d97-4f6c-961f-12954894e679',
    'identification_number': 93087225,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.33,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b20d5f8-095a-46ac-8c68-787fb00c0c42',
    'identification_number': 59355573,
    'item_consumption_1': 3262,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1213.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c2fd100-8cf9-41ac-b326-7b430fd4799d',
    'identification_number': 46758690,
    'item_consumption_1': 479,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 203.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7b909fd-3121-46e3-921a-3734213d11ec',
    'identification_number': 31640427,
    'item_consumption_1': 21.82,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 634.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '97ea4b6b-b0e9-4665-a7ba-849fcb666646',
    'identification_number': 18922813,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '35927b01-975d-4681-b45b-ed627f3b20d8',
    'identification_number': 77724895,
    'item_consumption_1': 15.73,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 915.04,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'a0bbb603-faa8-43f8-bb28-76f559893c07',
    'identification_number': 40174220,
    'item_consumption_1': 636,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 371.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd72e520b-40ee-429c-bc51-1b6233845d48',
    'identification_number': 36235334,
    'item_consumption_1': 32520,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4150.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d83c986-f3be-42bd-a7ad-22e9d25cbaaf',
    'identification_number': 12673994,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2666.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd904be92-47ac-4c58-a490-e93afdbcffbf',
    'identification_number': 17034620,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d47171f-d7bd-420c-9502-15dbefcca22c',
    'identification_number': 5330351,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6e522305-3135-4011-bb68-5a6bcad37d0a',
    'identification_number': 17175143,
    'item_consumption_1': 4405,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1722.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e6f0b18-1897-40ce-95ff-7cbd1ed5e007',
    'identification_number': 16125312,
    'item_consumption_1': 29,
    'item_description_1': 'Ultrapassagem Convencional',
    'item_total_value_1': 1092.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '6f30235c-47c7-41ee-ad64-e55ccbd5c728',
    'identification_number': 95590927,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd3d2f008-ce10-453d-a52f-703b40b0ff0d',
    'identification_number': 12275468,
    'item_consumption_1': 42.9,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2450.01,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4ec133f0-a874-413a-ab94-15fb3fd646e2',
    'identification_number': 19452306,
    'item_consumption_1': 14542,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 8493.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a92e8dd1-537a-45f9-ac84-621cfd8a0afc',
    'identification_number': 58238077,
    'item_consumption_1': 178,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 75.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5d3b1e8-fe9c-4c5c-8cff-6d61d63e0d38',
    'identification_number': 103721835,
    'item_consumption_1': 17005,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2170.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba764edd-f59a-4fae-a880-8b985bbf8fac',
    'identification_number': 33041644,
    'item_consumption_1': 7156,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2791.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4613c9a1-3cbd-46ae-875c-676ec6d089eb',
    'identification_number': 70059810,
    'item_consumption_1': 1101,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 430.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c974182-8470-4096-bd1c-f650f705915b',
    'identification_number': 41755138,
    'item_consumption_1': 67281,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8587.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b42b9b2-6895-4492-bfe6-bbb2c1a1e812',
    'identification_number': 91390958,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42c612a3-c584-44d0-873e-4a092c5a3dad',
    'identification_number': 19451601,
    'item_consumption_1': 567.36,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 16502.19,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '22884345-cf6c-4f01-9fd3-7ed1055d51ca',
    'identification_number': 12908398,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1994.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aae63a6b-8b50-46e2-85d6-b58036259c37',
    'identification_number': 92671810,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '55eba828-167d-4e49-a6e1-150787e26adc',
    'identification_number': 97072494,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5ce8459a-cbe4-4a03-ac95-7a34e8cdb8b2',
    'identification_number': 19296460,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1629b5ad-22a1-4226-b99b-7cc43d6dcfc3',
    'identification_number': 15365824,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 105.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd3583acf-c265-4e04-aa4e-80685714db83',
    'identification_number': 71381651,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6671702-02d8-4350-b261-8f7f0bff576d',
    'identification_number': 82724873,
    'item_consumption_1': 13945,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5189.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c92306cf-8480-455b-95a9-6348deaec71b',
    'identification_number': 111132290,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 175.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5399f1a-8279-48c1-a762-fc705313c730',
    'identification_number': 33069182,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c087abef-7b5e-44d0-bec8-65ddd60f3ede',
    'identification_number': 44634374,
    'item_consumption_1': 7.22,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 172.21,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '81e170b9-b316-4e6d-a75a-2105e0e8e454',
    'identification_number': 108369650,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7458c66a-b925-470d-87fa-d2e06be6bf6b',
    'identification_number': 43255280,
    'item_consumption_1': 5640,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2387.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02fd73c2-53f3-48e1-8124-296fc0551202',
    'identification_number': 16105702,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd2815540-aa25-4ec0-aaea-209e4fb8427d',
    'identification_number': 17816920,
    'item_consumption_1': 10903,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4263.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '979cc8e1-1b79-48a7-a848-fe585ceaddb9',
    'identification_number': 23899166,
    'item_consumption_1': 600,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 350.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16559251-4478-4f5f-afbd-ff50a391d1e1',
    'identification_number': 111740649,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 5.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8bb5f1a8-1b22-4de5-abcb-74a3753a6d4e',
    'identification_number': 16246780,
    'item_consumption_1': 312,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 472,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe7ce595-59fd-4722-a1a0-350e215f0a10',
    'identification_number': 110019377,
    'item_consumption_1': 6667,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2481.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81af2f96-5482-4512-9487-4797967af8db',
    'identification_number': 80788661,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b039dc6-472e-488f-a457-53ffff96de86',
    'identification_number': 96843330,
    'item_consumption_1': 3853,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1505.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18c4af8b-068a-4acb-a005-b1ab6646ed32',
    'identification_number': 108248321,
    'item_consumption_1': 3061,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1301.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcb5646e-ccda-4e8b-8b0d-7f5919d6f755',
    'identification_number': 64554481,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2371.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '316053b1-4948-4518-9572-f4874157439d',
    'identification_number': 108726983,
    'item_consumption_1': 779,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 304.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa1fc53b-61f5-4617-9eb9-04491516ec87',
    'identification_number': 80994539,
    'item_consumption_1': 3352,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1306.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f8b4117-e920-4cd4-8bfd-a4d028b13aed',
    'identification_number': 75836491,
    'item_consumption_1': 7426,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3148.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d44de43-f3a8-4ff1-8523-99074311f82f',
    'identification_number': 96692979,
    'item_consumption_1': 30639,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3910.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db5dc3f5-370a-4620-bf82-dd42f694d059',
    'identification_number': 39678318,
    'item_consumption_1': 2.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 70.61,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5cc1545e-8401-41cd-9f10-1f3425cef042',
    'identification_number': 935920818,
    'item_consumption_1': 669,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 583.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea5d8067-e39e-482e-81ac-c2ce79492c6e',
    'identification_number': 103809783,
    'item_consumption_1': 6518,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2543.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7bedd3e-340e-4c17-8bb6-2e8d27f9f3eb',
    'identification_number': 32446292,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6f4344d6-269d-461f-baa0-cee21de356e5',
    'identification_number': 93313357,
    'item_consumption_1': 4119,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1746.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfc2366c-da42-4616-9422-8f52c7147287',
    'identification_number': 16622383,
    'item_consumption_1': 2964,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1255.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '825ac990-5305-4b12-ab96-43136954be2a',
    'identification_number': 14660601,
    'item_consumption_1': 66,
    'item_description_1': 'Demanda faturada',
    'item_total_value_1': 2566.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '36c43996-5865-45ea-b0be-fc33c65db9f9',
    'identification_number': 100641555,
    'item_consumption_1': 247,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 96.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61a70460-98e4-459e-9b10-3df2b00868c9',
    'identification_number': 32727917,
    'item_consumption_1': 213,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 83.33,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '99ea8dc5-85aa-4492-a886-23a8d84e433d',
    'identification_number': 49626876,
    'item_consumption_1': 601,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 234.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '048b3536-3eb0-4a8f-9d67-2390a5d13a8e',
    'identification_number': 109633962,
    'item_consumption_1': 214,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 124.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0cdbbfbf-ddfd-4db9-930b-4ac845da1f72',
    'identification_number': 40391159,
    'item_consumption_1': 819,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 347.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '807e6d4c-e1aa-495e-9a13-ac4942932ca1',
    'identification_number': 68906161,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83254aa0-fc29-4a6d-a532-bed50fdf1e77',
    'identification_number': 57387206,
    'item_consumption_1': 10030,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3732.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0dd057be-d572-4753-a4fa-503cdfdc8c86',
    'identification_number': 101540620,
    'item_consumption_1': 11092,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4324.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1794d64-1e53-46aa-a770-e04c09a9c008',
    'identification_number': 90469259,
    'item_consumption_1': 93030,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 34620.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd59d0eaf-34a0-44cf-8d6a-97c48b9c67e0',
    'identification_number': 28219872,
    'item_consumption_1': 519,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 202.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '699e3ba1-2bc6-4f0c-98c9-9ccf5002ee47',
    'identification_number': 13884140,
    'item_consumption_1': 3670,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 468.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e94735fa-29f2-444d-8a97-d6329c7b7ccf',
    'identification_number': 91650291,
    'item_consumption_1': 550,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 232.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bfd68fd-e7d2-4707-b6ca-315205159638',
    'identification_number': 16002016,
    'item_consumption_1': 893,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 1537.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41099a48-b58e-43bc-969a-6ce4dad033fa',
    'identification_number': 96491353,
    'item_consumption_1': 5095,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1990.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89b8380e-57fe-4caf-9dd7-783e9ec0145c',
    'identification_number': 100002676,
    'item_consumption_1': 194,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 75.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8861af95-6ada-423d-b73c-3c6cd53ad504',
    'identification_number': 69466866,
    'item_consumption_1': 4054,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1720.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5dc638e-81c8-4317-a64b-fb85e9fd1b43',
    'identification_number': 32519036,
    'item_consumption_1': 105.75,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3075.83,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '88ba2fc8-de2a-42a6-8b49-e2c835a91acf',
    'identification_number': 92603360,
    'item_consumption_1': 4853,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1893.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4d4d066-7515-4478-83b9-ba71f66231ac',
    'identification_number': 39961320,
    'item_consumption_1': 1132,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1712.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0acfd09-34cc-40be-802c-38d69c55a0cf',
    'identification_number': 83974520,
    'item_consumption_1': 308,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 120.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78e7a62a-da6f-4e70-9f7a-315b0649bebb',
    'identification_number': 92672051,
    'item_consumption_1': 1,
    'item_description_1': 'SERVICO DE VISTORIA DE UC',
    'item_total_value_1': 8.99,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'f84a6ff0-a27a-4cc6-b01c-a5f52df6f178',
    'identification_number': 9788166,
    'item_consumption_1': 1479,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2237.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7c274f0-b0e0-4674-b338-dca9883aed4d',
    'identification_number': 18466907,
    'item_consumption_1': 140,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 59.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5f11f07-c9fd-48aa-a44e-edab9a440e1a',
    'identification_number': 75789981,
    'item_consumption_1': 3495,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5287.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43de3569-241c-4f6c-abfd-77638ffca6bb',
    'identification_number': 93763395,
    'item_consumption_1': 2566,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1498.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd2e7da0-4fd9-4ecc-b948-b1af1db260fd',
    'identification_number': 5329582,
    'item_consumption_1': 71.14,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4062.8,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2679c95d-a5e7-42f5-bafc-10001ae2620c',
    'identification_number': 98182501,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '85a0b4d4-c41d-4f1a-9c86-88401a897a18',
    'identification_number': 100317022,
    'item_consumption_1': 893,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 349.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ee332dd-56a0-4062-ab5a-e37fc8d2e656',
    'identification_number': 29745918,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52f8db28-5430-41ad-9e9a-afcc03678772',
    'identification_number': 948928709,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c246a15-3e59-4329-9fc4-2cc7c594a99a',
    'identification_number': 19559658,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 105.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6a983b99-9e3b-421c-98a0-7ff2eaadaf90',
    'identification_number': 20587147,
    'item_consumption_1': 553578,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 70656.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10cdddb1-55b6-485d-a95a-a50d28975a04',
    'identification_number': 101941714,
    'item_consumption_1': 11199,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4167.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd75dfa65-0995-458a-a835-222aee8d04f2',
    'identification_number': 31927807,
    'item_consumption_1': 2727,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1064.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91f4bd0d-ed90-4c0c-9b65-e9218d3a1cf8',
    'identification_number': 100728294,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22ed8710-3cdd-404a-b369-efafdff99940',
    'identification_number': 923294226,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '466ab201-be23-4aa4-80fe-ba5f031e4fec',
    'identification_number': 110284801,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea28bded-c0c0-4647-8504-38c942d787f9',
    'identification_number': 84969466,
    'item_consumption_1': 4.47,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 106.63,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '727c41f3-ef60-42f5-a489-a0d6b044a60e',
    'identification_number': 110527062,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7904da82-a648-446b-82ff-3dcb81fd3ff2',
    'identification_number': 108298035,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 53130.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fef14b11-5a2b-4a54-adfc-b67c8c9f711b',
    'identification_number': 14232979,
    'item_consumption_1': 217,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 126.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4dd2bd6c-b8e7-4d1f-8539-722d681aa8c8',
    'identification_number': 20513917,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7c2972e8-c0fb-4140-8d80-6c31ab955b54',
    'identification_number': 16127072,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 13.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '03129f07-029e-4cf0-a7c0-ca1b069e98f6',
    'identification_number': 44502931,
    'item_consumption_1': 81403,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 10389.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9f53bdb-5f5e-407c-8e1d-caf9a7c4b49f',
    'identification_number': 31789366,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2427.17,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '46c710c4-f80f-4cd5-9de3-5b65a52b64d2',
    'identification_number': 104309555,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 280.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6f90812-a7ee-49e6-af43-17f28fdb0077',
    'identification_number': 36222852,
    'item_consumption_1': 71558,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 9133.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb45c92a-4c59-44a0-ad3d-76f16717e94e',
    'identification_number': 97404713,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd53975c2-e8d4-4775-bca4-468e63b9aa7b',
    'identification_number': 19306903,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '211f3932-35c6-4b72-bbb5-233a4a7759a1',
    'identification_number': 14859513,
    'item_consumption_1': 70.18,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4007.96,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a36692c3-4b23-40dd-9064-53224c98812c',
    'identification_number': 43908764,
    'item_consumption_1': 23.81,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 692.52,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '85f8a0e8-8c59-405e-abce-5b91d252f2aa',
    'identification_number': 56874081,
    'item_consumption_1': 16936,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6302.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef3b593e-4ae1-426a-bb19-61b02eca2f01',
    'identification_number': 110270410,
    'item_consumption_1': 13.54,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 322.95,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6187c9f2-20ce-4b09-a90e-ffb00efaefdb',
    'identification_number': 40490688,
    'item_consumption_1': 2036,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1189.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb728897-b4ce-41b0-aac9-0342b2588c6b',
    'identification_number': 100728391,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd5ef0b3c-82cd-477b-aee4-4c588a92b9ee',
    'identification_number': 14981556,
    'item_consumption_1': 87,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 33.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6cce6d14-28fd-4a28-8e6a-40b12d1220b6',
    'identification_number': 96460210,
    'item_consumption_1': 280,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 118.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec616427-980f-482c-a7bd-288beb1fa34a',
    'identification_number': 56708890,
    'item_consumption_1': 31.98,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 930.16,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '78f65a53-747f-492d-b873-59af6e9f0f86',
    'identification_number': 43195300,
    'item_consumption_1': 6080,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 776.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '928958ce-6487-4d1d-9e8e-c36fdcdaf396',
    'identification_number': 96125225,
    'item_consumption_1': 13.35,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 776.58,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '19d661f9-c319-4c47-892e-f9ddaf77908f',
    'identification_number': 56527144,
    'item_consumption_1': 10935,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1395.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da027b57-7186-4f5f-8dcb-07d0db4e3849',
    'identification_number': 13847678,
    'item_consumption_1': 15.94,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 463.62,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '027b763d-8ce3-4345-b092-0311f4827b27',
    'identification_number': 18267211,
    'item_consumption_1': 2546,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 324.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '523ac3b1-b5ba-4281-951d-438318f4c5c2',
    'identification_number': 33799733,
    'item_consumption_1': 150.45,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4375.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c99926e2-71c7-40d1-a784-fac7b411655a',
    'identification_number': 91360250,
    'item_consumption_1': 14,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 5.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'db6f96db-7d0b-44c2-8b62-6969425439e1',
    'identification_number': 71720979,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2851.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26c55cb6-485e-46ee-9041-e316b6df0004',
    'identification_number': 6941044,
    'item_consumption_1': "'-71.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-22.76",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'e4861d02-e83d-42ee-8fda-0b7209d9a5e1',
    'identification_number': 75062046,
    'item_consumption_1': 11406,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1455.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2b753a9-9744-41d1-91d2-013426b39e94',
    'identification_number': 5330351,
    'item_consumption_1': 1.21,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 56.69,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'aa070a30-b0d7-450d-9951-b4adb479fdfe',
    'identification_number': 40683680,
    'item_consumption_1': 4947,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2096.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3623a2af-140e-47f8-80a4-463756ad21ed',
    'identification_number': 46913378,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7ec3a930-df5a-4967-a6b8-e97375738a52',
    'identification_number': 29771242,
    'item_consumption_1': 2117,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 827.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9acfdcf0-b053-47dc-a487-895a34e4ffc0',
    'identification_number': 18339654,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 183.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3420eed9-9909-4d7b-baea-6c095d7d931b',
    'identification_number': 66254787,
    'item_consumption_1': 843,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 357.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca41437a-6cb6-4e1c-8ff3-38f23f079d7a',
    'identification_number': 56599927,
    'item_consumption_1': 367,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 143.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37775fdb-0b8e-4566-9f26-c5b135f14e98',
    'identification_number': 99578336,
    'item_consumption_1': 8119,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1036.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad9ceb58-d1e1-4089-841e-28f90fe25972',
    'identification_number': 41932510,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO (0',
    'item_total_value_1': 104.65,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7a81e1e2-55e0-4701-91dc-4f7b8601bce2',
    'identification_number': 99971852,
    'item_consumption_1': 10463,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1335.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f453a998-6cce-457e-8348-faf67ea070e3',
    'identification_number': 22373101,
    'item_consumption_1': 902,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 115.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e09cc719-e7c0-428d-a912-71937c314071',
    'identification_number': 44916264,
    'item_consumption_1': 4283,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1670,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf62d5e1-b647-416d-a509-814271e57bed',
    'identification_number': 16105168,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0b536d1-7b19-40a3-abc5-597666252a8a',
    'identification_number': 31497861,
    'item_consumption_1': 12.13,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 705.61,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '75585985-f6e6-4055-959f-61a5d81ddd70',
    'identification_number': 97630454,
    'item_consumption_1': 402,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 170.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d0e8fac-34b1-4db5-ac68-8336f1618d49',
    'identification_number': 98882104,
    'item_consumption_1': 2485,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 969.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '463d7eda-93f0-42a7-8091-0c1122158d05',
    'identification_number': 32843615,
    'item_consumption_1': 4856,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1899.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3303f99d-5452-418a-b39e-1e6fd5b0d0a6',
    'identification_number': 10267832,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 1559.9,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '43bd820d-11ab-47bb-b7ac-a43f1103cc38',
    'identification_number': 99676354,
    'item_consumption_1': 3092,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1208.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7b20fc5-c3ae-48ed-a1fa-00532d625c5c',
    'identification_number': 105251542,
    'item_consumption_1': 406,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 172.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93c8542b-2cd3-4b08-90b5-ddc368f2d518',
    'identification_number': 65685326,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0df8b104-f1e7-4315-b7de-1d6b9d189026',
    'identification_number': 91858194,
    'item_consumption_1': 72.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2109.3,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '53f042de-4066-4e3c-b48b-07f815fb997d',
    'identification_number': 8138559,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7e801a81-bbfa-452f-a695-9f939d15d073',
    'identification_number': 27895602,
    'item_consumption_1': 1793,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 701.54,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2a9cb10e-fcef-4ca3-afce-0c724c72ada9',
    'identification_number': 102024804,
    'item_consumption_1': 1607,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 681.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd64b98b-43b6-423e-9f15-14cf921bf2b3',
    'identification_number': 103764755,
    'item_consumption_1': 1.51,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 36.03,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd0a1c4ad-d0b7-4f94-92ba-a97195210e84',
    'identification_number': 16374525,
    'item_consumption_1': 1852,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 722.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fd91516-0f6d-4e27-8f54-88ddeae2cf3c',
    'identification_number': 40504077,
    'item_consumption_1': 95.94,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2790.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5226ca81-9481-41e3-86de-797efcb39e22',
    'identification_number': 111833825,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bae76ad6-f7b9-40ba-8bfe-6879096c0519',
    'identification_number': 95229388,
    'item_consumption_1': 815,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 317.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27de55fe-b72a-444e-93c2-f64b9d57658c',
    'identification_number': 73221473,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'afc2ecd2-0d00-4c7a-8e40-a782f73df603',
    'identification_number': 12797707,
    'item_consumption_1': 8492,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1083.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d2ea5da-8a79-41e9-9064-30411e689135',
    'identification_number': 50783947,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42fde232-19a3-4bf6-b8b0-c9e02515011c',
    'identification_number': 38674670,
    'item_consumption_1': 9804,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4151.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a1fe6d0-816d-4c35-bb23-21b28ba8548d',
    'identification_number': 84599391,
    'item_consumption_1': 1.82,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 43.42,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '19b58da8-3d12-4372-81e0-04050009bb19',
    'identification_number': 57673160,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 16.82,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9ebce36f-f150-45e8-9a60-ce0e358276d4',
    'identification_number': 17175143,
    'item_consumption_1': 4405,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1871.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '264ef598-eb3c-47a0-b545-cc8ec0a6dc21',
    'identification_number': 935258912,
    'item_consumption_1': 35958.6,
    'item_description_1': 'Consumo em kWh Fora Ponta',
    'item_total_value_1': 18733.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8d98b94-054a-4a14-94af-d5439bbf9bb0',
    'identification_number': 46699236,
    'item_consumption_1': 12475,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4642.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f895d33d-c04b-4ed7-9455-e1fbdd94472c',
    'identification_number': 19312334,
    'item_consumption_1': 2807,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1190.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c4c5653-7175-4a85-8450-fc544eeb0b88',
    'identification_number': 66902983,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f91066a7-b732-4998-8e20-023151dc321f',
    'identification_number': 99929929,
    'item_consumption_1': "'-6.911",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-2213.83",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '7797151f-58f9-4d8c-ae06-362b9e1c268f',
    'identification_number': 15667219,
    'item_consumption_1': 449,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 175.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f042fb9c-33b6-4158-882a-ccf9d4327ccb',
    'identification_number': 14819821,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 0.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66f35ef2-f15f-4cc1-b248-6976fa182b6e',
    'identification_number': 105277002,
    'item_consumption_1': 2034,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 795.85,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b81e152e-5344-44f5-8c51-c870897ec2ad',
    'identification_number': 14104709,
    'item_consumption_1': 482,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 275.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3bad7df-c3cf-4d40-ac71-6c2ea8d3cede',
    'identification_number': 14862336,
    'item_consumption_1': 8968,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1144.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '541030bd-2f83-4146-9dec-daef51fe325f',
    'identification_number': 20587155,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 27.36,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '53414098-35e7-4bdb-bbad-48651974dad9',
    'identification_number': 75565730,
    'item_consumption_1': 20.15,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 480.59,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '53e79f6f-3bb6-4ce3-8ae2-7b87ba8953bd',
    'identification_number': 75062046,
    'item_consumption_1': 307,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 464.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d0ec237-20ef-4894-b642-3870ea3fc1d2',
    'identification_number': 19450745,
    'item_consumption_1': 768,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 300.49,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '64939fb5-8095-492c-ac70-928a224ab6c0',
    'identification_number': 33354537,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 4101.84,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '5a171dab-f1ce-4fac-9c90-f3aba645355a',
    'identification_number': 41809050,
    'item_consumption_1': 82,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 34.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fd67ccb-2db0-4e36-a30d-7825ce11e47b',
    'identification_number': 108098680,
    'item_consumption_1': 1245,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 463.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67268dfd-f207-496a-9c59-41d6d959593c',
    'identification_number': 20587163,
    'item_consumption_1': 498,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 194.84,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4f32bc51-6507-42a9-884e-e966f71ee459',
    'identification_number': 23136863,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.67,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '81cd0485-9fa2-4e8a-8fe5-cc8277cdd8fe',
    'identification_number': 13015346,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '96d2f8c4-46a0-4b51-bbdd-bf4c19aef78e',
    'identification_number': 107297671,
    'item_consumption_1': 26987,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10043.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'faa4c1e4-0337-48e6-8d3b-21cf78b4a95d',
    'identification_number': 38629232,
    'item_consumption_1': 1124,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 438.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cfba45b3-89dc-4aad-bcc6-52bc45a696ba',
    'identification_number': 15872050,
    'item_consumption_1': 676,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 286.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2cce6698-d4e1-4602-b8a0-1321eeacdc3a',
    'identification_number': 94984859,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64713bba-7fe3-4c01-8088-078d61c777f3',
    'identification_number': 79645119,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ca19376-23ec-47c4-96c9-ef9641fcc4a7',
    'identification_number': 106814427,
    'item_consumption_1': 4156,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1546.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5032da26-5eb7-4e7e-aa67-59f3685a0d92',
    'identification_number': 82744181,
    'item_consumption_1': 1180,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1785.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0aa356a5-dab4-43c8-b70a-e064dcccea93',
    'identification_number': 87333597,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 130.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7da8c4ff-9bb2-4a84-a359-c44e0869dea5',
    'identification_number': 18278140,
    'item_consumption_1': 1630,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 691.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f3f274d-5bce-4b3c-85c0-a3968b710c37',
    'identification_number': 95332162,
    'item_consumption_1': 1,
    'item_description_1': 'DEBITOS S/DEVOL.INDEVIDA',
    'item_total_value_1': 626.79,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'e583ea13-48f1-47fa-b5d7-1315ece1e005',
    'identification_number': 77411609,
    'item_consumption_1': 25457,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3249.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec9c1c2b-e1c6-4542-9af6-406d3c997fe5',
    'identification_number': 40709140,
    'item_consumption_1': 2938,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1715.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f1369fc-07e1-409c-afc4-7565344768fe',
    'identification_number': 59716606,
    'item_consumption_1': 6164,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2404.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6b07f30-ed03-46be-b30a-5b43bc433a65',
    'identification_number': 92294367,
    'item_consumption_1': 2978,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1161.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'afbb7a10-df70-43da-b319-45636735295d',
    'identification_number': 107520028,
    'item_consumption_1': 3578,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1394.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8eced185-f75e-44e0-b870-36932125f0f0',
    'identification_number': 109655885,
    'item_consumption_1': 37.58,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1093.04,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0545b369-da63-4a53-9b8a-da50db352af3',
    'identification_number': 101400470,
    'item_consumption_1': 343,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 134.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a0c5713-7ff4-4bde-8c61-295a9a419776',
    'identification_number': 106382373,
    'item_consumption_1': 303.66,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 8832.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cb299b05-ea7f-45a4-977c-b71b8d768e8e',
    'identification_number': 58741798,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '741245e2-9e61-465e-93c8-f1437c185207',
    'identification_number': 107297671,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cd2160b6-61e1-437f-bddb-77cb0ca6aabb',
    'identification_number': 72858710,
    'item_consumption_1': 14259,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5306.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91e81afb-92f7-4fdf-9f89-f52ef77a09df',
    'identification_number': 45094519,
    'item_consumption_1': 1133,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 481.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '235e9e72-fa1e-4210-b0be-80da288f5004',
    'identification_number': 17656265,
    'item_consumption_1': 1737,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 737,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22f8397b-4127-4834-8df5-826d847570c7',
    'identification_number': 86773526,
    'item_consumption_1': "'-66.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 06/2022',
    'item_total_value_1': "'-21.08",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '7130c28e-dfd7-4854-8b0e-8fd58391e0a0',
    'identification_number': 83974520,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9a736ede-1498-4408-9eac-815cdbdd1022',
    'identification_number': 40855708,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2b0c9ff6-9f79-4887-be76-ccb6e308be4b',
    'identification_number': 8115656,
    'item_consumption_1': 230,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 89.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff9a79d5-1882-4a15-8020-bee7b6f08bd4',
    'identification_number': 46861009,
    'item_consumption_1': 1842,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1075.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9ea0e5c-da78-45af-8bc3-21f67c74fd58',
    'identification_number': 107936704,
    'item_consumption_1': 38.2,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 911.11,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'de94664f-8994-4c62-96ba-52a489bddb31',
    'identification_number': 34851151,
    'item_consumption_1': 2966,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 378.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69ba835f-68a3-4b28-b9d2-8231774ec0e9',
    'identification_number': 30345359,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 39.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '14e9f040-a6cb-4f0f-8cce-e698a46e26b2',
    'identification_number': 99541360,
    'item_consumption_1': 124,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 48.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29c7a7b4-177e-4fb2-bba0-68adcc1097d4',
    'identification_number': 80647588,
    'item_consumption_1': 1541,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2331.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eab60600-9ab6-4454-8ad6-9a67b250dc5d',
    'identification_number': 76083241,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc6a743f-2922-4485-b3f7-d95243e287f8',
    'identification_number': 92135811,
    'item_consumption_1': 1664,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 705.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2af850c3-1121-4c8e-a8e1-9a35267bd2ec',
    'identification_number': 102260460,
    'item_consumption_1': 3262,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4934.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc93c81d-c4e6-4de7-a62e-9ae9ddafc5a7',
    'identification_number': 93308965,
    'item_consumption_1': "'-628.0",
    'item_description_1': 'ENERGIA INJ. PT OUC MPT TE 12/2020',
    'item_total_value_1': "'-300.75",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'baca5a2a-526c-4830-9a75-0479bb2e26cb',
    'identification_number': 70972443,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '19c542eb-e6d1-4c71-8a22-143234ee4c5b',
    'identification_number': 37328751,
    'item_consumption_1': 12172,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4755.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86fe841f-0b2c-42c9-848c-15ac081d8bcb',
    'identification_number': 106653733,
    'item_consumption_1': 175,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 68.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2cc86dd-abe9-49b7-b70d-f8fc95888a1e',
    'identification_number': 110480406,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.84,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '360e8d55-ffeb-496d-a542-f4159eec9308',
    'identification_number': 103840923,
    'item_consumption_1': 25092,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3202.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f5fa275-2006-485c-b457-845a4583ddd4',
    'identification_number': 13028855,
    'item_consumption_1': 14384,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5352.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de1e02be-882b-4b0d-bc62-7650d05b76ee',
    'identification_number': 101835981,
    'item_consumption_1': "'-13.131",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-4568.59",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '4c836d66-b535-4e01-b396-2f7c75cea13d',
    'identification_number': 70152845,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4be5071-5a04-4a37-a06d-5941a1926f9a',
    'identification_number': 78757894,
    'item_consumption_1': 2616,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1020.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e075b213-df47-4db8-b18a-c55430dbcc47',
    'identification_number': 96269391,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc0d5d68-af11-4ce2-8cfb-23324f6dbcca',
    'identification_number': 100856349,
    'item_consumption_1': 7917,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3095.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c71fcb8e-a0e8-4f4d-b408-8e481ae41983',
    'identification_number': 8906041,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19a80c6f-5daf-4381-bda4-0c31a04f5517',
    'identification_number': 61792098,
    'item_consumption_1': 1.89,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 45.09,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b44d469c-b04e-4305-b9ba-0a2a5a25416e',
    'identification_number': 29885680,
    'item_consumption_1': 1349,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 527.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d023236-1c5c-45cf-80e1-5194c4d09efe',
    'identification_number': 62191780,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 19149.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61096add-836d-42d8-bd62-241279ae550c',
    'identification_number': 43879772,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb4a3b78-daac-485f-a0be-806687490e7c',
    'identification_number': 5348730,
    'item_consumption_1': 3789,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1604.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'feded42f-ef3a-48fc-9232-a17a9dbe0360',
    'identification_number': 27132110,
    'item_consumption_1': 1954,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 827.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d5c99d0-28fc-46ed-9509-4f1138d02c0d',
    'identification_number': 19450303,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 43056.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '30cf95b1-ba51-4378-b9a8-94a1d3218144',
    'identification_number': 70039356,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '74e85e23-6327-42aa-b6c6-6c149f1a98fb',
    'identification_number': 101937792,
    'item_consumption_1': 1365,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 534.08,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'edee6b78-2078-402f-8637-98999154a637',
    'identification_number': 28671139,
    'item_consumption_1': 895,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 114.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25a3affd-9688-471e-b7e0-515bae87017e',
    'identification_number': 6338526,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '179a74c8-fb56-46ab-a480-3dd410736e6a',
    'identification_number': 102679347,
    'item_consumption_1': 2409,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1023.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '001eb100-ab30-49d2-9369-a1d89ec2bfe7',
    'identification_number': 109782909,
    'item_consumption_1': 89.58,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 2136.53,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ee8a0fa7-0304-46b3-aa01-a56dffdf613d',
    'identification_number': 107297671,
    'item_consumption_1': 113.06,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3288.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd14b2f97-6f7f-49ae-8274-308ccac68a5e',
    'identification_number': 6287727,
    'item_consumption_1': 11040,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4689.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '749e6cf4-503f-4ace-8364-8d6d2cfa3f1f',
    'identification_number': 20304137,
    'item_consumption_1': 1012,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 591.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c5e25e1-02d3-4a4a-9a7d-7ba4e2b653c8',
    'identification_number': 8899738,
    'item_consumption_1': 27360,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 11621.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad1c4e22-35b1-4db7-bf4e-d3bac22bdd0a',
    'identification_number': 63964112,
    'item_consumption_1': 18348,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6828.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ebd8aee-d96f-49f4-8aa6-ea45abe5b2a5',
    'identification_number': 34617418,
    'item_consumption_1': 13162,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1679.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad3e2fe0-5025-4644-9e96-1d3d0e90dd7f',
    'identification_number': 109594916,
    'item_consumption_1': 36.3,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 865.78,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '17828df1-3269-43b4-bc82-d0907e517098',
    'identification_number': 15353435,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.99,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '99cb7562-56be-4211-b92d-5d10eafec43d',
    'identification_number': 98624792,
    'item_consumption_1': 18.89,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 450.54,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0a27588e-28d5-4f29-87eb-299594bf4daf',
    'identification_number': 107112981,
    'item_consumption_1': 633,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 246.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '223487d9-0947-43a4-aed8-70a521e80efe',
    'identification_number': 101940122,
    'item_consumption_1': 16.04,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 382.57,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '23d1d03e-0894-460b-b882-c3666e4e8194',
    'identification_number': 106868250,
    'item_consumption_1': 17829,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2275.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4fbc771-8edb-4fad-8184-a70c2375c98b',
    'identification_number': 44502931,
    'item_consumption_1': 81403,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 30293.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0ccaa14-85ac-4a20-8056-f95fbaa5030a',
    'identification_number': 61115576,
    'item_consumption_1': 971,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 378.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5458ad9-09b5-4b21-a5d1-f9cd11040993',
    'identification_number': 44038380,
    'item_consumption_1': 582,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 880.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea41d3ae-6737-4f01-9609-96c2993a9c96',
    'identification_number': 46023429,
    'item_consumption_1': 820,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 347.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3291c51-9fc1-4aec-a0ba-3496a2d395fe',
    'identification_number': 106595415,
    'item_consumption_1': 683,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 267.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d798866-4dfc-44e0-90c9-ea7411178947',
    'identification_number': 11679751,
    'item_consumption_1': 64.42,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1873.7,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '726cc395-f2af-4a85-9de9-65729b728dd6',
    'identification_number': 10406832,
    'item_consumption_1': 3120,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1321.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'faed2b88-fc56-4213-99f9-39901045a41c',
    'identification_number': 44038380,
    'item_consumption_1': 10.22,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 297.25,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c12257e2-4cd4-4be7-a9c8-99e016de9423',
    'identification_number': 18247733,
    'item_consumption_1': 247,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 104.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '186fe19c-2d12-4b44-a5ec-524fcbe63423',
    'identification_number': 38768534,
    'item_consumption_1': 429,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 167.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9586db94-4ece-4bb9-9754-8fdb7ede56ff',
    'identification_number': 15575675,
    'item_consumption_1': 4428,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1726.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d6eb82f-8d2f-428b-a83e-24f5a8df4436',
    'identification_number': 28408683,
    'item_consumption_1': 1235,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 524.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f4d53a5-30bd-44fd-a926-563bdf719af8',
    'identification_number': 96736291,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '35420857-8374-4e89-b60f-cc13a098a06f',
    'identification_number': 91640504,
    'item_consumption_1': 1049,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 409.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71c0c798-6aef-4456-a0c2-1675d6510a70',
    'identification_number': 9726659,
    'item_consumption_1': 10535,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4460.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '089b6483-4c00-49f8-9f02-51a702fe1ac0',
    'identification_number': 935371459,
    'item_consumption_1': 848,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 739.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf441608-31a3-4390-be13-e57cab5f7107',
    'identification_number': 12918385,
    'item_consumption_1': 137,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 207.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0fc8adf7-1565-4ccb-8d50-abe215271273',
    'identification_number': 948827679,
    'item_consumption_1': 305,
    'item_description_1': 'Consumo em kWh',
    'item_total_value_1': 265.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4ee0aa8-e1e8-4ab3-81f3-ba3c8083c567',
    'identification_number': 9965394,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ab2217f1-bf93-41a2-907d-88666f1cb862',
    'identification_number': 32747217,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 86.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '16b6423f-213a-4c37-8cf7-5c3b05a58956',
    'identification_number': 106622218,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11aabcbf-db97-4036-82a7-1a925084057d',
    'identification_number': 110226399,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e8a0509-e13e-4984-ada6-e09ead67a260',
    'identification_number': 31690955,
    'item_consumption_1': 894,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 349.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '902a6e26-d751-4c34-b778-6574fe65569d',
    'identification_number': 17015391,
    'item_consumption_1': 5515,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2343.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04da7642-6333-437b-b0b4-6851c9eb07fe',
    'identification_number': 101439350,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a73588c-5ec7-4454-bb8e-9d2c2bd11b86',
    'identification_number': 103281924,
    'item_consumption_1': 6054,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2571.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'adbc48d9-31e5-438e-bcf3-b7d124da9f80',
    'identification_number': 65625528,
    'item_consumption_1': 387,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 164.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c29c5d4-7fda-4bcc-bdba-bc7a2c120be8',
    'identification_number': 103281924,
    'item_consumption_1': 6054,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2365.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3f5f9ce-4979-4d08-89b5-a326ce85adb3',
    'identification_number': 81170629,
    'item_consumption_1': 2194,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 929.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff475f12-e58c-4db0-8d94-bd06ee1fdff0',
    'identification_number': 59101580,
    'item_consumption_1': 15.12,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 360.62,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6a8afd29-7da7-4e4a-81c2-c657ecc8344b',
    'identification_number': 40017826,
    'item_consumption_1': 16279,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6341.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ad9a19f-d076-465a-abd5-f747a4b5589a',
    'identification_number': 69617244,
    'item_consumption_1': 6898,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2925.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b638bb6-0cbb-4f05-9083-150af903647c',
    'identification_number': 93950993,
    'item_consumption_1': 2.2,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 52.48,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f236096e-a4c6-44c5-afc5-dca6f43273cb',
    'identification_number': 59070960,
    'item_consumption_1': 1316,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 167.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78fcba5c-c1ac-4781-81c1-d5a4381b12a2',
    'identification_number': 4334671,
    'item_consumption_1': 46.54,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2657.89,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '94cab202-0c9f-4961-91b3-a5f066eff5e0',
    'identification_number': 39466787,
    'item_consumption_1': 25011,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3192.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c43fcb5e-6b85-4e39-9d99-de654c074d5d',
    'identification_number': 99426544,
    'item_consumption_1': 348,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 147.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fd58d02-f3ce-4f35-b1b9-876eaf50f3dc',
    'identification_number': 18197736,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'be81a18f-517b-4923-81d8-3e91ad1deb43',
    'identification_number': 4001194279,
    'item_consumption_1': 30,
    'item_description_1': 'Custo Disp Uso Sistema TUSD NOV/23',
    'item_total_value_1': 15.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35836973-31f3-41d1-837b-0bc42e29cbed',
    'identification_number': 8661758,
    'item_consumption_1': 19491,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7253.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e4952d2-c7c0-4559-9715-7ba4107f90ce',
    'identification_number': 103799125,
    'item_consumption_1': 345,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 144.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbc87b1b-5d2c-4161-b0bb-66af45325378',
    'identification_number': 9248420,
    'item_consumption_1': 5977,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2334.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10ea1206-ec14-4f01-917b-db8cbc15bc26',
    'identification_number': 56705514,
    'item_consumption_1': 1244,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1881.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '047526e0-fde9-4707-86c0-955e9187a16e',
    'identification_number': 105797634,
    'item_consumption_1': 6508,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2421.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7d035a6-04cf-4a61-96ea-79ad865497b5',
    'identification_number': 99258870,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8cb7a502-4fa5-468b-880d-8cbd87585078',
    'identification_number': 63991373,
    'item_consumption_1': 4390,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1633.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f79680b-bdb8-4623-bed6-0ea702a8c74f',
    'identification_number': 102436053,
    'item_consumption_1': 770,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 326.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81bdeb27-dce7-4118-8ec0-23f65c1379ac',
    'identification_number': 91169410,
    'item_consumption_1': 838,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 355.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8009151-901c-42cd-b534-0e13cd864607',
    'identification_number': 63896419,
    'item_consumption_1': 17.66,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 421.21,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a0603f4e-cb60-46c9-88e1-9a00569b4293',
    'identification_number': 42824699,
    'item_consumption_1': 5744,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2442.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '824d1bfb-13a1-470e-91dc-bdb8fcb2eae5',
    'identification_number': 102940746,
    'item_consumption_1': 8723,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3246.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ea6281b-20e5-40d2-b495-5239ffd05ff4',
    'identification_number': 41971817,
    'item_consumption_1': 28,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 10.94,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e516412d-7ada-4ec8-a45f-322b72bd1678',
    'identification_number': 27112101,
    'item_consumption_1': 5.04,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 236.05,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0918629d-36f5-4c75-8cef-d9638c78bf2b',
    'identification_number': 19450672,
    'item_consumption_1': 42,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 16.41,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '47582835-73c0-4c72-be10-0f423cc9c686',
    'identification_number': 18280099,
    'item_consumption_1': 819,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 347.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d5d5549-2d52-43c6-8ffa-a81a32d22a15',
    'identification_number': 69852634,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'faaac784-aa00-48fd-a8c9-4f7939b437fd',
    'identification_number': 41971817,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2554db6d-01f1-4782-a95e-54b641920084',
    'identification_number': 49867245,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '608eb62f-b91f-417e-ae7f-3cb0ed962fa7',
    'identification_number': 74865145,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 406.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b356335-6b6d-44f8-9302-a60083d2c878',
    'identification_number': 109993217,
    'item_consumption_1': 0.84,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 20.04,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '691e9baf-740d-457e-84e5-bd98df50988b',
    'identification_number': 105953261,
    'item_consumption_1': 17173,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6390.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01f356cc-6668-4298-a6b5-4bb1787e2bf5',
    'identification_number': 53869303,
    'item_consumption_1': 2581,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 329.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f78564c-70b8-469c-b045-724eeb927ccf',
    'identification_number': 43814697,
    'item_consumption_1': 22737,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8461.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a699ca0-b105-4534-974f-c0adcae12d51',
    'identification_number': 37998447,
    'item_consumption_1': 46477,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 27144.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a092d59c-ebbb-4a7e-bd3e-67dff65f233f',
    'identification_number': 103004874,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e437c836-ef8e-484d-8f8f-c4569cfa5073',
    'identification_number': 102765707,
    'item_consumption_1': 52,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 20.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b8cccb5-03d8-4c55-8e46-cf06480fdcf0',
    'identification_number': 32810300,
    'item_consumption_1': 38673,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14392.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97b14e0b-edfc-41f4-8988-1941e3a35cfc',
    'identification_number': 106410687,
    'item_consumption_1': 3742,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1589.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0971cb58-7b11-4d83-94ad-4fa529506a63',
    'identification_number': 33576718,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '94af8860-a61c-4382-9f0c-79f975ff5d63',
    'identification_number': 99676354,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-51.94",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '0d6c30c6-637f-4f48-a21c-3ea7af9786a9',
    'identification_number': 57673160,
    'item_consumption_1': 17009,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2170.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf1b3baf-21fe-4781-a744-9a581fe4cd52',
    'identification_number': 36222852,
    'item_consumption_1': 57,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 22.3,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '51efb546-47a4-4c12-b417-7365847d6f50',
    'identification_number': 16003500,
    'item_consumption_1': 48,
    'item_description_1': 'Demanda failrada EP',
    'item_total_value_1': 904.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '37e175b0-8bd1-4c6d-b014-15eaa27a0de2',
    'identification_number': 5348730,
    'item_consumption_1': 3789,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1476.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7bd955f-1740-4a6c-aa4c-6630ab29f8cd',
    'identification_number': 38055660,
    'item_consumption_1': 532.56,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 15490.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'de069abf-3645-4d4c-a488-baa511e7e81c',
    'identification_number': 38747570,
    'item_consumption_1': 680,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 265.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1faf678-73ae-4cd7-8e36-25f7405ac7d9',
    'identification_number': 19013256,
    'item_consumption_1': 5360,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2096.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af1faf5f-32ba-4400-8d0b-805421812716',
    'identification_number': 48482897,
    'item_consumption_1': 50.25,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2869.76,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '167d8d13-2577-4e3a-9203-7474a04e4eb0',
    'identification_number': 101401116,
    'item_consumption_1': 1500,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 636.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b09a8b70-d424-44a8-9236-356e7a479e40',
    'identification_number': 35854952,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95e6b475-b7e4-46b4-b879-e8d38626159b',
    'identification_number': 56868057,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e332f944-cfc2-4581-8a3c-c81010a538c2',
    'identification_number': 93878710,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd91f135b-c43c-43d2-a5d5-d8bd9af171da',
    'identification_number': 33235953,
    'item_consumption_1': 197.28,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 4705.23,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9bfe9599-650c-4ee0-b157-be95b1e4598b',
    'identification_number': 14140276,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.21,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '06f72ffa-ec47-4b73-8df2-3e06d38b746b',
    'identification_number': 12019670,
    'item_consumption_1': 2.264,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 858.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29c6e4e4-5723-436c-a93d-450ffbfac060',
    'identification_number': 98518933,
    'item_consumption_1': 6152,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2399.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1f4de51-5a5b-46c9-bb6d-0c6815a08b65',
    'identification_number': 30734541,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8108dbbe-2e7c-4672-897a-0ea456edaa18',
    'identification_number': 77776500,
    'item_consumption_1': 1663,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2515.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe5eb32d-f9c3-44c0-a8d2-8753bba4303e',
    'identification_number': 79885756,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3db761e7-228d-4954-9737-01b897a75238',
    'identification_number': 70128235,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b2044c86-490d-473c-ac60-a8544c8c0aa9',
    'identification_number': 97445339,
    'item_consumption_1': 426,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 166.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a36da1be-006b-423a-869c-6b9481dbcf6e',
    'identification_number': 96256524,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7650cb67-b6eb-4aba-8197-b563dcd8c88e',
    'identification_number': 103352562,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bd1c0eac-4f2c-4a9f-9ac3-7d75cfe08086',
    'identification_number': 14389754,
    'item_consumption_1': 11933,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4440.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23bcd248-e127-4b1b-898e-879558c81e06',
    'identification_number': 44476108,
    'item_consumption_1': 582,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 216.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e86a11ba-4166-485a-a118-8718102390be',
    'identification_number': 3030777617,
    'item_consumption_1': 0,
    'item_description_1': 'Parcela Art 113 01 / 02',
    'item_total_value_1': 8162.14,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '06cbd0c9-7ebf-47fc-a1c7-157eb9f98f1f',
    'identification_number': 40701301,
    'item_consumption_1': 9.94,
    'item_description_1': 'DEMANDA REAT EXCED USD PONTA',
    'item_total_value_1': 289.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '46504633-01e5-4ba7-9275-8d0cdbf9918f',
    'identification_number': 100891918,
    'item_consumption_1': 1227,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 520.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eaa20cab-e7f7-4462-acb8-0b2c46a34493',
    'identification_number': 104467010,
    'item_consumption_1': 451,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 175.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fe69033-5d81-46ce-a2b1-c1c9f1a88d3c',
    'identification_number': 103087605,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '99c66a05-3240-4979-9fbe-17387ad4d76f',
    'identification_number': 48651940,
    'item_consumption_1': 37,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 14.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e2a7bf06-d50c-443e-b7e9-be3aae926ddf',
    'identification_number': 49721690,
    'item_consumption_1': 225,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 87.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cc37e6c-4241-4df1-9640-6105baea3cd3',
    'identification_number': 31792634,
    'item_consumption_1': 23.9,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 1119.25,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7b67fe39-fa6e-45bd-b1ca-d9a7f581ccba',
    'identification_number': 92333435,
    'item_consumption_1': 1813,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 767.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '891ae07c-bf49-4943-a83d-1f8b54b530a2',
    'identification_number': 106607090,
    'item_consumption_1': 1195,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 505.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9caf30c3-0d9f-4f5c-993e-b8370503156b',
    'identification_number': 12804401,
    'item_consumption_1': "'-1.4",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 03/2021',
    'item_total_value_1': "'-486.92",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '2bb5519e-071f-4fff-814d-ab49ba5cfce3',
    'identification_number': 88214990,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '412fc901-cfb8-4667-a04d-40a62fd954ca',
    'identification_number': 62515438,
    'item_consumption_1': 108303,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 13823.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc036013-da6b-4d97-85b3-c75a141966a0',
    'identification_number': 41466888,
    'item_consumption_1': 1514,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 592.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89d548b9-5a9a-4866-9bc6-eac8710e8ee5',
    'identification_number': 111658004,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '506a25ab-60b3-4ffc-b6f8-c3dd8a8d3810',
    'identification_number': 28860136,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8c1a7785-71db-4ac5-8db2-e831cae589aa',
    'identification_number': 103904310,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9326f40-88e9-47b4-a0e2-42f6ebfbde4e',
    'identification_number': 46394974,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a6c35c26-3821-4642-8b2e-6b2ab847f38b',
    'identification_number': 102676844,
    'item_consumption_1': 1472,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 575.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ddbf67a-6bc9-4d13-b8ee-ed04fe6e9aed',
    'identification_number': 73887692,
    'item_consumption_1': 1035,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 604.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f95ae07-098e-4332-ab7c-09607ede67d8',
    'identification_number': 71893156,
    'item_consumption_1': 13387,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5223.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3abaacbe-5316-454f-a12c-352efe8ae99d',
    'identification_number': 56877250,
    'item_consumption_1': 11893,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4425.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c609b0f-13c7-4962-a390-6ca452818d0b',
    'identification_number': 105953261,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e3138406-1eb4-4406-b9a9-77f3ab62fde7',
    'identification_number': 9790268,
    'item_consumption_1': 119,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 50.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b36f40a-130e-4882-9e4b-161a29841df0',
    'identification_number': 91360250,
    'item_consumption_1': 75,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 29.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '87b3de94-8d22-4cfe-bd3c-5901afeb92d7',
    'identification_number': 8453624,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8b61f0e2-64a7-4538-8732-fc7470fa1473',
    'identification_number': 32927061,
    'item_consumption_1': 6.73,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 160.53,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '34bd8807-4c9a-4e40-9990-26f6e54dc693',
    'identification_number': 101195435,
    'item_consumption_1': 1343,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 568.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9be21ce-4038-4bfb-a264-2b21b9155d9d',
    'identification_number': 96026782,
    'item_consumption_1': 11169,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1425.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ced29798-2e44-463e-8c0f-83581f337fe6',
    'identification_number': 15353435,
    'item_consumption_1': 10060,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3922.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '187d6db6-e331-4daf-99d4-3f2bc0652b0c',
    'identification_number': 45677468,
    'item_consumption_1': 1129,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 440.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10ca33b9-1fa1-4c60-b823-f9539bbdf9e0',
    'identification_number': 936300028,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cac53457-bdfe-4c72-ae04-99b18d49a59b',
    'identification_number': 59161485,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a24edff-1669-4c3c-bb8b-84a3af97286f',
    'identification_number': 94602123,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-11.89",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '33685880-9356-415d-bad1-87b95e4d4984',
    'identification_number': 31945252,
    'item_consumption_1': 24923,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3181.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f55ebd9a-d8b5-43ae-9eea-bc7078ec27a7',
    'identification_number': 12493961,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bda3282d-01a8-485d-b50c-b2dc5cec7fd9',
    'identification_number': 15740056,
    'item_consumption_1': 3232,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1369.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '012ccc4b-358f-4436-a42f-46141ecd0747',
    'identification_number': 110527062,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3cc7af05-1808-4ab3-a516-5df94c14378f',
    'identification_number': 58742662,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1b5ff9d-f586-4b3b-8f0d-835e4dd77fa9',
    'identification_number': 110284801,
    'item_consumption_1': 2775,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1175.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f51c64dc-82f7-40d2-9bb8-2bb05232ccad',
    'identification_number': 74277103,
    'item_consumption_1': 83,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 35.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d6df799-aed4-468e-b062-608886e4f48b',
    'identification_number': 2727684,
    'item_consumption_1': 3609,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1406.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb9e74b6-1709-4cf9-b252-fe45b5419cfa',
    'identification_number': 37998447,
    'item_consumption_1': 939.6,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 27329.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c1c96141-ebce-4148-ab99-b9993f9e3667',
    'identification_number': 85312185,
    'item_consumption_1': 2232,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 284.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be311308-b729-40db-905e-84e08e74261b',
    'identification_number': 102512426,
    'item_consumption_1': 490,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 286.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e67bab9c-3b2d-4719-b187-a74a84d7c136',
    'identification_number': 101108290,
    'item_consumption_1': 4215,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1647.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a93da09-a06b-4156-a24b-26d310802c6e',
    'identification_number': 29659043,
    'item_consumption_1': 5111,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2166.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e4f7f38-d35f-456d-951e-396aefce03df',
    'identification_number': 102571538,
    'item_consumption_1': 554,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 235.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '615fa1df-3872-45b7-b0e4-55d13a9dc31f',
    'identification_number': 110227980,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31416d17-79da-44b0-9962-cc318b85f8fe',
    'identification_number': 44805616,
    'item_consumption_1': 2477,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3747.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6db1660-ef26-48bf-a855-3c16477cee3e',
    'identification_number': 79701531,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd236cd73-3056-4c23-b77b-32146e9259b4',
    'identification_number': 70427054,
    'item_consumption_1': 6933,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 10488.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbcb1472-f7e9-4e00-b048-086a5789d54c',
    'identification_number': 31786430,
    'item_consumption_1': 2405,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1018.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e137dce3-da6a-45ea-843f-3f0f390cac08',
    'identification_number': 30085900,
    'item_consumption_1': 10432,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1331.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2f1dc9d-794f-4303-878a-ebc1a63504c8',
    'identification_number': 42584418,
    'item_consumption_1': 22,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 8.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '18d99fe5-0f99-4cfd-b920-3656dafb12e8',
    'identification_number': 83979069,
    'item_consumption_1': 243,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 94.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42fd5c93-d07f-4eb9-b960-94da4b8b0482',
    'identification_number': 108332438,
    'item_consumption_1': 44.28,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 1056.11,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'f4e2f4a0-e065-48e7-83db-21b375a64239',
    'identification_number': 91711630,
    'item_consumption_1': 8978,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3341.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '936154f6-3c6e-4fb0-ac0f-1731bdd6df60',
    'identification_number': 33375453,
    'item_consumption_1': 41.32,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1201.81,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '55e99be3-bec1-4361-841f-51aa12cb26fc',
    'identification_number': 64175251,
    'item_consumption_1': 5103,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 651.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bec543a9-aeda-4c40-9eba-b9e913c7fafd',
    'identification_number': 27724280,
    'item_consumption_1': 821,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 320.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '185c641f-8cf5-4a03-867c-03e695967035',
    'identification_number': 62852485,
    'item_consumption_1': 1590,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 673.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c9d7fc0-cda9-444d-bbae-5d84ea433ca7',
    'identification_number': 18485774,
    'item_consumption_1': 2609,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1020.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49adc931-7ff5-49a9-8904-f4f88ba5470a',
    'identification_number': 45201137,
    'item_consumption_1': 385,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 150.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f2dab5d-83a7-4d56-b7f2-07abc3ebfc94',
    'identification_number': 14039362,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '87c2a3a4-628f-4dfe-bf24-8065fa242c0a',
    'identification_number': 83687114,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ff880ac5-e5e2-402a-bc2e-1004f64ee7ca',
    'identification_number': 108065006,
    'item_consumption_1': 2263,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3423.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6e74e84-19c3-4708-80e6-6654943431fd',
    'identification_number': 99541343,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2ebbba1e-1ff6-4520-8b01-cb681ebf01ac',
    'identification_number': 91699959,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0830910f-afb1-40ab-97a1-04a6ba832265',
    'identification_number': 28858913,
    'item_consumption_1': 698,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 273.11,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '74796206-1f79-4adc-bc91-bba009765358',
    'identification_number': 108309835,
    'item_consumption_1': 63.36,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1842.87,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8420c90c-3e1a-465f-a260-aa77763b2b48',
    'identification_number': 91711630,
    'item_consumption_1': 8978,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1145.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4741ba0-4495-4b2e-aaab-fd717feacd21',
    'identification_number': 40429458,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-14539.87",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '0b571fc2-f773-4e64-b9bb-00b13a19d77f',
    'identification_number': 103992146,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e22110f0-e1cf-45e7-8d8e-0ff9341ab93f',
    'identification_number': 22317996,
    'item_consumption_1': 1076,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 456.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '682d0b08-01f8-4949-90bc-8626896d9706',
    'identification_number': 107718421,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e8aa8559-8b84-4bb5-9df3-491f7eed0c31',
    'identification_number': 16386671,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7622350f-2b62-4c59-9839-7d3e83be7ac1',
    'identification_number': 80644554,
    'item_consumption_1': 3293,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1285.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80e58da8-14ca-4ad7-a57e-5305feeb0a29',
    'identification_number': 98199544,
    'item_consumption_1': 1646,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 641.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad124a6a-4606-4b99-9b44-ffe8a33918d8',
    'identification_number': 91392454,
    'item_consumption_1': 5263,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3073.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a62c1da5-3eb4-467b-b082-a9f4d5aab143',
    'identification_number': 10103554,
    'item_consumption_1': 21982,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 8571.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2de2dbf1-00e7-44b4-aeaf-2f16090ab773',
    'identification_number': 21025070,
    'item_consumption_1': 238,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 100.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90f59f7a-f098-4c3b-9ffb-bb41915e7526',
    'identification_number': 46677615,
    'item_consumption_1': 10934,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1395.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad1ffaf9-6ad9-47e6-ab98-3804642b33a3',
    'identification_number': 105953148,
    'item_consumption_1': 8255,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3218.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '647e96a9-c2c7-4e53-b42f-14a49110dca4',
    'identification_number': 71277552,
    'item_consumption_1': 9.05,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 215.85,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b57f32e8-e242-4869-a9ab-7c62570d4560',
    'identification_number': 45825319,
    'item_consumption_1': 11644,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4946.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92ae5da7-610a-410f-bc21-231a00143dcb',
    'identification_number': 19312458,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 210.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c84a96c1-3d0d-4087-99f9-5afcefdbc195',
    'identification_number': 92530214,
    'item_consumption_1': 10565,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4477.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b7ed1a6-8cb7-4d93-83b7-a891ecd0aeb8',
    'identification_number': 8859060,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.93,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '18872641-9b53-43a0-8921-8466d3acaada',
    'identification_number': 103770895,
    'item_consumption_1': 2287,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 891.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '336040bb-9542-42b5-859d-5d369cae4c48',
    'identification_number': 18616917,
    'item_consumption_1': 7.37,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 175.79,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c0e25f31-2cf8-4194-9add-909fb6c786be',
    'identification_number': 31497861,
    'item_consumption_1': 22,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 8.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '72ebd8b7-d135-40b9-8399-18342816ce2a',
    'identification_number': 97614858,
    'item_consumption_1': 2622,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 975.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dad998ae-5363-49bc-8d96-2e032f732f22',
    'identification_number': 96117176,
    'item_consumption_1': 605,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 256.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1f3071a-e1c6-4589-841f-c28a9dc47d49',
    'identification_number': 102135541,
    'item_consumption_1': 40.09,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1166.04,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8a5ca098-4bf0-4e1d-8f37-68d63bad1334',
    'identification_number': 110008758,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ab7f0d64-5c36-4e9a-8300-7c755b027ad2',
    'identification_number': 33800677,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dfc6c4f7-c6e4-4f1d-b895-f110c7e6f7b1',
    'identification_number': 84803932,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c36f8bd-2b3c-415a-bacf-af9b2ca0de3a',
    'identification_number': 95566651,
    'item_consumption_1': 650,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 379.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6418f2c-807b-4f4c-9f25-9dd641dcc77e',
    'identification_number': 108565726,
    'item_consumption_1': 22803,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2910.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b2fbfba-2581-4964-aa1e-c03c7d61da15',
    'identification_number': 16125320,
    'item_consumption_1': 20559,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 8293.45,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': 'c07aa1b2-9b42-4438-b6a7-757d03bdef7f',
    'identification_number': 41378687,
    'item_consumption_1': 4059,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1581.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99cb103d-6c68-4c4b-b6eb-0f7f5a90e4fe',
    'identification_number': 99645599,
    'item_consumption_1': 45988,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5869.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6000f717-a132-40ec-8e3f-e377a459c223',
    'identification_number': 28597095,
    'item_consumption_1': 822,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 348.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43e499b4-3e49-499d-9112-57f6a55b2447',
    'identification_number': 101778910,
    'item_consumption_1': 369,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 156.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9eecb38e-6661-48e0-ba7c-44423f6ece20',
    'identification_number': 48362832,
    'item_consumption_1': 24944,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9282.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '106f5f13-6bf2-46c8-9c36-5c58b75f0882',
    'identification_number': 105219304,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6a49e738-606d-4562-a424-421466d485ab',
    'identification_number': 94664480,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7fc2642b-f472-4098-b7c9-7516aaa0a43e',
    'identification_number': 94724741,
    'item_consumption_1': 4396,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1635.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c840add7-d11c-4198-ab44-079b41dfd62f',
    'identification_number': 45218471,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '211c3af6-2f33-4f35-bcfd-022a5b4c3f8d',
    'identification_number': 108065006,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8dadebce-e6ed-427c-8fa9-5bb672a3ac6f',
    'identification_number': 5576334,
    'item_consumption_1': 13400,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5237.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f4b5553-b10d-4d65-aa5d-7185edccc318',
    'identification_number': 14104709,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a06a282f-ef00-4f2d-96b8-7c975171ce73',
    'identification_number': 42374030,
    'item_consumption_1': 50,
    'item_description_1': 'C.DISPONIB',
    'item_total_value_1': 38.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdd2a080-47fb-4140-82ee-72d13421ec80',
    'identification_number': 78143977,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0aa20512-a10b-40a7-8110-99084f75c30a',
    'identification_number': 92763219,
    'item_consumption_1': 11.6,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 276.67,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2e1ba9db-9731-4708-92be-53007338354b',
    'identification_number': 17109833,
    'item_consumption_1': 817,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 477.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e761c976-cdb6-4d01-965f-1e666dec7050',
    'identification_number': 95371184,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1d5171ef-385d-498f-8d9d-04fb05315977',
    'identification_number': 20444060,
    'item_consumption_1': 19537,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7270.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1a4abec-3741-4593-b499-bc071d39fe7c',
    'identification_number': 96839775,
    'item_consumption_1': 8636,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3367.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64e638da-657f-4bda-a5a2-087cf35c71f0',
    'identification_number': 34459715,
    'item_consumption_1': 2077,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 808.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '258d5f15-7f61-4e77-9a3c-4901de9f5e8e',
    'identification_number': 39214699,
    'item_consumption_1': 11.89,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 283.59,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'db21dff3-b00b-4e74-95b0-7cbab4cb900e',
    'identification_number': 18389473,
    'item_consumption_1': 13276,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5632.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c776ddf-71f7-4aa2-81e0-2cf197a7e4cb',
    'identification_number': 60602643,
    'item_consumption_1': 18.2,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 529.35,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '59956f65-96ca-4fdb-acb1-367b6d805504',
    'identification_number': 23135395,
    'item_consumption_1': 487,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 189.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '396ca273-5566-4fcc-a584-6d9e1b07705c',
    'identification_number': 41081757,
    'item_consumption_1': 16652,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6493.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebf4d953-a33b-4142-8890-64c500dd4605',
    'identification_number': 51528029,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.97,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c5cb8d03-3b0c-4a67-8e61-357b63d9e675',
    'identification_number': 70128235,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '480e199b-fd5b-41b6-807d-f053c6080295',
    'identification_number': 46171622,
    'item_consumption_1': 2138522,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 795845.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a2688b3-1f94-4e97-80d7-26f3d8c34539',
    'identification_number': 95258620,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 121.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6751d43b-c609-4229-b48d-b0c3e5647fb2',
    'identification_number': 100797075,
    'item_consumption_1': 71.22,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1698.65,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5ddfad3e-1dfc-4ca1-b261-15bdd06f5d0e',
    'identification_number': 27895602,
    'item_consumption_1': 58130,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7419.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41ce309f-697d-4106-b45e-8dc8d9d79eb9',
    'identification_number': 100138268,
    'item_consumption_1': 988,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 419.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5332eb00-ed46-4a29-ae04-0774733d8dd0',
    'identification_number': 8866163,
    'item_consumption_1': 29325,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3742.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43912406-5e9a-4747-a342-48d96dab3584',
    'identification_number': 107619962,
    'item_consumption_1': 993,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 388.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e31c196-0493-48c5-a7b9-83c0e1ae0a90',
    'identification_number': 108436128,
    'item_consumption_1': 293,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 114.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c891c66-6570-488e-b952-94004a04b6e2',
    'identification_number': 41279263,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-9.34",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'cb720408-1fee-46b9-89b0-e62613cc90b6',
    'identification_number': 56706499,
    'item_consumption_1': 639,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 966.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9cb3bd4c-ef77-4d82-9979-ae2c51e521cc',
    'identification_number': 48339610,
    'item_consumption_1': 111.09,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 3231.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '94a3081d-1570-4741-9cbd-3b39ad105d5f',
    'identification_number': 32605579,
    'item_consumption_1': 192076,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 24515.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa5a1ca0-1689-46c7-b6cb-de78e7d8de13',
    'identification_number': 10894608,
    'item_consumption_1': 4758,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2017.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '488f18fe-f468-4785-8a59-bdeb9d2c9f0e',
    'identification_number': 107936925,
    'item_consumption_1': 21305,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7928.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '227ccf7b-0cf3-4738-9fdb-44b5e276bf62',
    'identification_number': 35000430,
    'item_consumption_1': 23131,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2952.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d1f1336-87fa-44f2-a73c-94cc264f88aa',
    'identification_number': 51527472,
    'item_consumption_1': 258,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 390.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfd2bc0c-abfa-4117-9662-3272fc2e8886',
    'identification_number': 18036244,
    'item_consumption_1': "'-1.89",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-657.66",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '767891b3-85a6-4bed-84f7-f8f69abd4739',
    'identification_number': 111268524,
    'item_consumption_1': 5921,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2313.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '771bf15a-4db4-40a6-b6c1-8fefedf9c634',
    'identification_number': 18456669,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '816d406f-33e8-4058-96a6-caf5f1d7d128',
    'identification_number': 56812477,
    'item_consumption_1': 9,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 3.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cc9576ad-6bc5-4643-b9e5-0130ccb6f086',
    'identification_number': 109682424,
    'item_consumption_1': 192,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 81.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60bfde41-0c6d-4f11-a942-cb8f88a448f4',
    'identification_number': 87262061,
    'item_consumption_1': 3906,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1659.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34f9b8b0-fac9-49bb-b0c2-201b8156c509',
    'identification_number': 43444229,
    'item_consumption_1': 6411,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2714.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c072802d-5d5f-4912-a19b-1938ef9bb234',
    'identification_number': 95466096,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '565f0305-b13a-44e0-885a-88a9bbd61441',
    'identification_number': 92140394,
    'item_consumption_1': 2098,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3173.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c6cd94d-04e7-4c55-910b-d5bd04f52e1f',
    'identification_number': 88426726,
    'item_consumption_1': 3109,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1319.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49ff295e-af56-4f49-bf8c-9f0cdd5de4c4',
    'identification_number': 101068484,
    'item_consumption_1': 20458,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2611.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6961d15d-1115-4ed9-a672-a0076512905a',
    'identification_number': 14619555,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 320.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6172a48e-4d94-4cf4-9ec5-42d5b9fb4f7a',
    'identification_number': 17902991,
    'item_consumption_1': 932,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 363.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84f86ea3-eb13-47a1-9e70-f5acb9bc1e13',
    'identification_number': 56698151,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fd157dbf-0a28-4789-8cfd-bcf715bedd4a',
    'identification_number': 110216385,
    'item_consumption_1': 35,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 14.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b22d71e-4688-4385-bfe3-8f536dd9df41',
    'identification_number': 103102450,
    'item_consumption_1': 759,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 321.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ffe4e57-2356-4661-8047-b5dc7db6a378',
    'identification_number': 28942981,
    'item_consumption_1': 9373,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3973.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0e565fb-5558-4df9-8840-5d47133f916b',
    'identification_number': 96215445,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f7c4078-3252-4e0d-b436-153229af4b0a',
    'identification_number': 91302412,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25e9fa2b-2da7-4130-ba6c-436cf3cf49bd',
    'identification_number': 82724873,
    'item_consumption_1': 22,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 8.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '79d86b63-2eeb-4a29-927a-5b0491ab8a23',
    'identification_number': 80817610,
    'item_consumption_1': 1133,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 443.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd3c7c4d-ea87-4474-9e22-281f848eea15',
    'identification_number': 40705188,
    'item_consumption_1': 6.1,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 354.85,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1d27cb42-bb3f-4974-b0b8-8d8bb6f8da80',
    'identification_number': 92096115,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7584f2ab-08fc-4332-8721-3af518a398a2',
    'identification_number': 70152845,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1f346465-1da1-47ac-87b9-ea31607984d5',
    'identification_number': 96727101,
    'item_consumption_1': 9352,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3649.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ae10c67-4cb4-429f-aa3b-522c8b3c192d',
    'identification_number': 42537711,
    'item_consumption_1': 30593,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3857.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3748b38b-a1e4-4689-8c96-de266b515806',
    'identification_number': 22808213,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.04,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '158306b7-84cb-4577-b86f-2018be48648e',
    'identification_number': 22526846,
    'item_consumption_1': 13947,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5907.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '099ad288-c632-4a46-be60-81455460f32b',
    'identification_number': 53869303,
    'item_consumption_1': 25378,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9444.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '606990a5-8022-4e93-aa3b-09be2dbebd4b',
    'identification_number': 13877984,
    'item_consumption_1': 1682,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2544.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22bb5f1c-3a40-4aba-af73-d6bf6251d1db',
    'identification_number': 31903991,
    'item_consumption_1': 4795,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2800.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '270c0589-b4f8-4fba-aba4-4a081727a026',
    'identification_number': 35559217,
    'item_consumption_1': 20.41,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 955.82,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '6ed7d5bf-4e45-4edf-9478-ae42799c4b30',
    'identification_number': 22539530,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 116.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a29cab0f-ed7a-471d-8466-7809d6c81306',
    'identification_number': 111221153,
    'item_consumption_1': 107,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 45.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04a25d26-7f1c-42d6-b5e5-25a02aabf176',
    'identification_number': 80982255,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6734123f-4405-4ac2-9777-45a8a611b4ec',
    'identification_number': 104186020,
    'item_consumption_1': 625,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 365.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ccbf89e-dd09-4ea9-86b2-59e1f4a75f92',
    'identification_number': 19450745,
    'item_consumption_1': 1742.4,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 50679.35,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dbe000ad-4190-4ec7-9ebc-dc7fb4107a03',
    'identification_number': 34617418,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '15ff2c78-f00c-4f12-b9b2-324b1d71814e',
    'identification_number': 92739245,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b3cb45bb-b806-4e29-bdf6-fb2645b1e201',
    'identification_number': 41999932,
    'item_consumption_1': 921,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 390.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b66cf4a-680b-4f14-a50d-ad72e0317bc0',
    'identification_number': 6338526,
    'item_consumption_1': 372,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 145.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4e2f3905-fbde-4c0c-bd90-ab9db1fff90a',
    'identification_number': 94480648,
    'item_consumption_1': 1102,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 430.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a82e0f53-9f07-414c-bf03-197e140cbea6',
    'identification_number': 100839100,
    'item_consumption_1': 1160,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 453.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '973490ff-764c-452e-9bee-0810e6eb530f',
    'identification_number': 102261016,
    'item_consumption_1': 3.63,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 86.59,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f614425b-8ff0-4794-befe-c85e8e0b9bd6',
    'identification_number': 93067895,
    'item_consumption_1': 42.1,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2404.33,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '395c2233-75eb-4acb-a3a4-dd8404bb836d',
    'identification_number': 46920617,
    'item_consumption_1': 7618,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 11524.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d4413a2-5abb-4729-ac55-891048dbd6ac',
    'identification_number': 28801768,
    'item_consumption_1': 3313,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1293.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc443fe1-792f-4278-bbb2-7b8c3370dfe4',
    'identification_number': 93879695,
    'item_consumption_1': 1749,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 683.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb21ca2b-d303-4f90-8df3-358009b357af',
    'identification_number': 110655109,
    'item_consumption_1': 336,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 130.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11ce36cc-4f43-4973-af8a-e0009dbff722',
    'identification_number': 54126754,
    'item_consumption_1': 96,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 56.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5fd1383-ecb0-476a-bf71-156b2777533c',
    'identification_number': 92457460,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8a944905-3a1a-46f9-846d-909eac8baae6',
    'identification_number': 12382671,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7783129-297e-4b07-9e90-55eff1ea74f1',
    'identification_number': 111115469,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70ccdc99-9b40-443c-a716-afe290e2d0d7',
    'identification_number': 47816600,
    'item_consumption_1': 1649,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 644.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a922b8b6-f8d9-4c5a-bc6c-a93f3109f662',
    'identification_number': 14489317,
    'item_consumption_1': 6448,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2514.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9f83df6-1b16-4133-a76d-c3ec6a014130',
    'identification_number': 16861469,
    'item_consumption_1': 0.48,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 22.49,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4d80087c-377b-482f-88ed-16e58efe8a65',
    'identification_number': 102560935,
    'item_consumption_1': 4773,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1776.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddcafe79-85d2-4775-8ca6-e81399421a1e',
    'identification_number': 105699047,
    'item_consumption_1': 28773,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10707.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aee683d1-33d3-4fbb-8a3b-1de36f36a882',
    'identification_number': 55524567,
    'item_consumption_1': 6108,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2273.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81c3482a-7f37-482f-8374-f23fd7508f43',
    'identification_number': 32528620,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd96c03d9-ebe0-4c32-9ab8-9fd0802b3028',
    'identification_number': 5591783,
    'item_consumption_1': 250,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 105.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7df36bbd-ae6a-4664-ae20-343036ad03df',
    'identification_number': 99541343,
    'item_consumption_1': 128,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 54.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65b1bdbb-c855-46bf-826c-3abe42096194',
    'identification_number': 93324138,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1166.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68c7a4fe-8fb0-4929-88af-bbba25a59d9e',
    'identification_number': 15924866,
    'item_consumption_1': 9910,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3859.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c006af58-b50b-432f-8c10-b02f2587532c',
    'identification_number': 91519276,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10525197-697c-4124-ba6e-244eae69ceb5',
    'identification_number': 19453779,
    'item_consumption_1': 47038,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 27471.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73d1a2c4-aea4-4e64-a48b-e306893f06c5',
    'identification_number': 14210215,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e89c0895-66de-456a-8d66-f396fdde3387',
    'identification_number': 107108402,
    'item_consumption_1': 1504,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 588.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6575e6b-cd9d-4cf2-afba-ec94a487d04d',
    'identification_number': 43750583,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '99b916d5-aa73-4d6d-9d7a-b70694b403ed',
    'identification_number': 104783192,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c580a8fe-91e5-49c3-8ab0-138c05c1348a',
    'identification_number': 105628271,
    'item_consumption_1': 57.19,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1364.01,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd9624a6f-ad6e-4f5f-bdcd-7e5ec077d3c9',
    'identification_number': 103002456,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a1433df-50a2-46c8-a2a7-d2bc49b97acf',
    'identification_number': 69492026,
    'item_consumption_1': 18405,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2349.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '459b7884-ffa0-4954-97f4-6fab8ad81f95',
    'identification_number': 35614595,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd93f45bc-e6fc-4029-a0d0-c5ecc45e63ab',
    'identification_number': 19450303,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 13549.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '890efb9c-b8ab-49e3-b24f-eccf99f63d76',
    'identification_number': 46896163,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1b3f1aa-8710-4e36-8f71-ff88548a345a',
    'identification_number': 73615145,
    'item_consumption_1': 37638,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 15986.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06787fd2-c410-43dc-9a30-f67fd33715cb',
    'identification_number': 59510196,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22c1ac28-ca00-4a56-a3f4-67eb15f10dce',
    'identification_number': 102742820,
    'item_consumption_1': 102,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 43.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50904fe8-2cad-4ea1-a694-9ee83abcf08b',
    'identification_number': 106390015,
    'item_consumption_1': 9742,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3809.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2f76a39-f6ad-4fc3-8393-ce4ad9a083e2',
    'identification_number': 93500777,
    'item_consumption_1': 328,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 138.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47b3cacf-dab4-4a61-a6d5-d35ac532792c',
    'identification_number': 53747755,
    'item_consumption_1': 3242,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4904.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71abac1e-b3cf-4114-8dcd-0034bec22ce1',
    'identification_number': 99569280,
    'item_consumption_1': 18,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 7.03,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3442771f-2fd6-456a-a515-923824a25031',
    'identification_number': 19296460,
    'item_consumption_1': 120,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 46.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25a756b2-7ff7-4545-8961-0acf773b343c',
    'identification_number': 102315698,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f47e4836-6b9b-4ccc-a849-c488c0f37c83',
    'identification_number': 96314443,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2143251-d1c0-410f-abe5-1330cb7a35fd',
    'identification_number': 101785941,
    'item_consumption_1': 2172,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 847.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75f61171-81ac-4169-b26e-a216dc873101',
    'identification_number': 68007493,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e9ec9ab1-bc02-4606-987a-024e5d20ac91',
    'identification_number': 108864855,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '772d558d-307c-4547-bd2f-ec08948da765',
    'identification_number': 97752320,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1da0eca8-f3d7-4a08-98ae-da1182dd891a',
    'identification_number': 103438157,
    'item_consumption_1': 0.91,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 21.72,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ac7c5f09-f88e-4882-a71b-7c97c97bc1a0',
    'identification_number': 83687114,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 453.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b099769b-f625-4d40-b760-9eb1afa5c445',
    'identification_number': 40780163,
    'item_consumption_1': 3058,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1194.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7525bf5c-d42f-469a-b210-f9df6215c828',
    'identification_number': 102935165,
    'item_consumption_1': 1616,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 630.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4860187-1ad9-4615-91fe-74fd82d0be96',
    'identification_number': 40855783,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '150f31b5-c852-4eb0-876b-fdcddbff5c76',
    'identification_number': 32845570,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '35282986-6a02-4279-9393-3e90f57ec037',
    'identification_number': 21161348,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c8a8d824-0d96-4514-8e75-ef49cfe99d78',
    'identification_number': 34210334,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '23a58ab8-d82b-40f0-8800-2aeb0d6ccdf4',
    'identification_number': 14115506,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'da5f28ba-ea3c-4486-917d-5c2390033f60',
    'identification_number': 18094694,
    'item_consumption_1': 11528,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4494.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebd25c36-8f16-4178-97c0-ef2b4f8945f9',
    'identification_number': 103001000,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59e2288b-4493-4b78-ab79-1b3d177167a8',
    'identification_number': 49891294,
    'item_consumption_1': 4899,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1823.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72f3b0d1-e8c3-48cd-8919-59a05e4ad5cd',
    'identification_number': 17509980,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 75.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '99c4f115-34d8-453d-9d59-211bdde0e105',
    'identification_number': 14562545,
    'item_consumption_1': 9133,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3398.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbea2e00-b7c2-4467-91ec-ed456fac7331',
    'identification_number': 42556198,
    'item_consumption_1': 3628,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5488.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c67ad60-1d2b-4bda-a73a-3b3420751a14',
    'identification_number': 41614518,
    'item_consumption_1': 10960,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4639.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e9c9a63-8a94-4147-85e9-63623b19034f',
    'identification_number': 106755030,
    'item_consumption_1': 2156,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1259.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72d1e4df-9280-434e-93ea-4b4a7cf5f55a',
    'identification_number': 9857737,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a8e7b36-4945-4f75-a11a-4a5a746af673',
    'identification_number': 43633226,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c9d1384-e224-4272-a121-01f82464c3df',
    'identification_number': 21108218,
    'item_consumption_1': 1890,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 803.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b8ad13a-363f-4862-8ef2-233749580c56',
    'identification_number': 106605712,
    'item_consumption_1': 49,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 19.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '104553ef-78ea-4f8a-9b0b-cbb554b8356a',
    'identification_number': 48004936,
    'item_consumption_1': 2959,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1728.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ec1aff3-6438-4550-9d68-5cae65f40f84',
    'identification_number': 19451601,
    'item_consumption_1': 32829,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 19173.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26ea8ce1-75d8-4f19-b02a-b2167902f2ea',
    'identification_number': 106648462,
    'item_consumption_1': 313,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 133,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c827b8d2-e875-438f-a3cb-cc1e0613f522',
    'identification_number': 53839242,
    'item_consumption_1': 134,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 56.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae7d11db-c39f-49c6-94c2-47059a020105',
    'identification_number': 40879356,
    'item_consumption_1': 1005,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 392.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbe2fbaa-cdaf-4850-a7d4-faecff49c9b6',
    'identification_number': 60543051,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63908ea9-2270-490c-9aa3-a09bf4ad467a',
    'identification_number': 103306633,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f213a93a-6bac-422a-a8bb-bd791d89b972',
    'identification_number': 16111460,
    'item_consumption_1': 422,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 320.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5074b78-b00b-4c57-9536-a3b1a0d5ce80',
    'identification_number': 7284012,
    'item_consumption_1': "'-65.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 05/2023',
    'item_total_value_1': "'-22.57",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '29354a42-787d-40ab-8c9a-799f7dc3bc03',
    'identification_number': 77890043,
    'item_consumption_1': 491,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 286.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd20fb254-52a9-4135-8d36-e22d4e4ebf80',
    'identification_number': 22509470,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bb452bc-aea3-489a-8b60-c597788cdd12',
    'identification_number': 72858710,
    'item_consumption_1': 67.18,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1953.99,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '68609328-63f6-4ff5-8319-25fa088ec1ce',
    'identification_number': 91845947,
    'item_consumption_1': 218,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 84.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5c41a6f-1610-4b84-8b19-d6ee62e72a4a',
    'identification_number': 948743686,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd2980129-3f87-4d8f-9978-1f7d8084c64f',
    'identification_number': 18922813,
    'item_consumption_1': 2120,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 898.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c55c5c11-893a-4059-b1a7-7a7bcf8d5986',
    'identification_number': 77431650,
    'item_consumption_1': 164,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 69.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b56ca6e7-6064-43cf-877c-c22f4da304b5',
    'identification_number': 108565726,
    'item_consumption_1': 57.07,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1659.92,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'aa31a810-8238-4f90-8ef3-cc97dd0fef5d',
    'identification_number': 15004821,
    'item_consumption_1': 2870,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1217.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '322c526d-7622-4fd6-8837-947d6ff5e0a3',
    'identification_number': 16574923,
    'item_consumption_1': 1607,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 682.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7897a0b8-205c-489c-8782-4a826dfbccda',
    'identification_number': 40122654,
    'item_consumption_1': 23.66,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 688.15,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '464be53a-50df-4e05-bb7a-8741cccbe221',
    'identification_number': 28671139,
    'item_consumption_1': 13.01,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 609.27,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '70596a3a-d73e-41de-9035-09b494945166',
    'identification_number': 27467759,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '341f01b2-a877-41bf-80e8-e9433188d182',
    'identification_number': 31560792,
    'item_consumption_1': 738,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 313.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '265a6777-6c6d-4ad0-acd7-b7b65d1b2562',
    'identification_number': 41499255,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0a9a35d3-9f51-4f3b-93af-b21e6c0fab4b',
    'identification_number': 107674149,
    'item_consumption_1': 1405,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2125.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd43c0e3-8938-4fc0-9052-9aa9ac8669b1',
    'identification_number': 50265938,
    'item_consumption_1': 2345,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 916.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be816129-49ad-4fb5-acee-c935475fc24b',
    'identification_number': 110216687,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '950c0f75-54a7-437c-be3e-5ef26f3c02f5',
    'identification_number': 15915727,
    'item_consumption_1': 1740,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 738.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb291aed-b993-4fa6-a1c0-f9d45de32538',
    'identification_number': 34617418,
    'item_consumption_1': 1803,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1053.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5aa5f390-40e1-403b-8e29-dfa192a7147a',
    'identification_number': 14642484,
    'item_consumption_1': 1052,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 614.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '342ba55d-28a9-4206-8902-1404b222fe21',
    'identification_number': 111270561,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b550a8b-9485-43d1-8dcb-8cb366b613ae',
    'identification_number': 17353173,
    'item_consumption_1': 1322,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 515.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7faa90f8-119e-4282-acdd-adfe9c9ab837',
    'identification_number': 102103348,
    'item_consumption_1': 693,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 294.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c7e6a8b-ceaf-4734-a823-0b4098234153',
    'identification_number': 77279719,
    'item_consumption_1': 10.3,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 245.67,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e6b329c0-b9af-45e2-8e1b-a456b27d8deb',
    'identification_number': 105683574,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a5b2080-5a7f-4e1c-aabb-f2113dc59636',
    'identification_number': 90790499,
    'item_consumption_1': 48.65,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1415.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '34256ac8-fa8e-4b50-be21-9ba6f00aa22b',
    'identification_number': 53330668,
    'item_consumption_1': 1343,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2031.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e88fee3c-7200-4f57-80e5-b0bac7c6ee8a',
    'identification_number': 94079692,
    'item_consumption_1': 10260,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4345.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07c0f3a2-5e6a-4fe4-8b4d-af23b424106c',
    'identification_number': 43154514,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '934758a2-068a-4dce-b44c-ead99dc580b4',
    'identification_number': 44476256,
    'item_consumption_1': 10027,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 15169.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9136f4b9-bf00-47c0-aec0-d1ebfec58466',
    'identification_number': 36849510,
    'item_consumption_1': 409,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 159.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3744a60-2694-4a9e-938f-717fcb7d77e5',
    'identification_number': 96460210,
    'item_consumption_1': 280,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 109.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '372793cd-97c8-4f14-9f8d-40e9f49101c4',
    'identification_number': 67678564,
    'item_consumption_1': 513,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 299.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c21d7d3-c570-4fce-89e1-7df26f16dd90',
    'identification_number': 106077406,
    'item_consumption_1': 0.09,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2.6,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b59bc06e-3c5e-499f-b288-c8ab96587dab',
    'identification_number': 10947604,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bec5516b-c542-440b-880e-21158f42a93a',
    'identification_number': 30345359,
    'item_consumption_1': 8817,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5149.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c0c1fc1-af14-47f1-80de-fc4a4d5e0f4c',
    'identification_number': 110001796,
    'item_consumption_1': 262,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 102.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c39fa0b-8abf-495e-824f-ef820e15963f',
    'identification_number': 111819130,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0bb794db-08be-4251-a74b-ef9612204298',
    'identification_number': 90604679,
    'item_consumption_1': 30287,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3865.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f978be7-f083-4304-9170-4af7e28664b0',
    'identification_number': 14905612,
    'item_consumption_1': 39.33,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2246.12,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '52505bee-d12f-4cff-88db-c9be2e1d1706',
    'identification_number': 28151895,
    'item_consumption_1': 9649,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3769.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4724e03-1d02-49ff-8dac-731d1a8411db',
    'identification_number': 19289022,
    'item_consumption_1': "'-555.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-193.45",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '0b4e4482-5f31-4306-9f63-e95a212ac79b',
    'identification_number': 33799733,
    'item_consumption_1': 143.46,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 8192.98,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '552237d9-53d5-4316-8931-4bbba2d12068',
    'identification_number': 42712190,
    'item_consumption_1': 1994,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1164.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a33b8bd0-dd14-4e86-a5e8-84c3f58d5525',
    'identification_number': 6092578,
    'item_consumption_1': 25311,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 9873.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd757cf0f-45a0-48d5-addb-7598c8f8ba6f',
    'identification_number': 111198542,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd585d3ef-a25a-4e20-a2c6-823f99b9df24',
    'identification_number': 105658430,
    'item_consumption_1': 2188,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 853.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f75649d-d6ad-43c9-bc03-631284a1d281',
    'identification_number': 106980645,
    'item_consumption_1': 3251,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1376.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5eb3ba68-162b-4523-9093-c59658e594e0',
    'identification_number': 8084661,
    'item_consumption_1': 3828,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 488.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ad66929-104b-4624-bd86-e81eed8005bf',
    'identification_number': 56886314,
    'item_consumption_1': 30.33,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1732.13,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c19a6098-31a5-46fe-b3a9-d85c850b6af3',
    'identification_number': 27577244,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59914306-bed6-4e2b-ac16-0389b160ec3e',
    'identification_number': 61588555,
    'item_consumption_1': 31,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 12.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'aa8f90b3-9fe3-4940-9223-91eb231e6300',
    'identification_number': 59820888,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'afc82842-b91f-44cc-a90c-6b49975c4837',
    'identification_number': 27047970,
    'item_consumption_1': 1170,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 456.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a0a3c58-c283-4de8-b0bb-074de6e8ab4c',
    'identification_number': 19453302,
    'item_consumption_1': 0,
    'item_description_1': 'RELIGACAO PROGRAMADA',
    'item_total_value_1': 257.62,
    'reference_month': '11/2023',
    'referente': 'Religação',
  },
  {
    'uuid_items': 'eba1e1b8-46d8-42ec-b0df-d40276f6cdf1',
    'identification_number': 76636291,
    'item_consumption_1': 104600,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 38926.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6f16211-1bd4-41b8-90ed-f38dff7cf3da',
    'identification_number': 25318535,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38eed581-775f-42d0-8900-0d1401129768',
    'identification_number': 15522229,
    'item_consumption_1': 82,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 47.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ef9c40d-72d9-4602-b2a8-365c893b3782',
    'identification_number': 101575220,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e3fdf43a-8fa5-4191-ad7a-12dcab4bc6dd',
    'identification_number': 44888848,
    'item_consumption_1': 1593,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 674.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ecb56616-31e6-442d-98ad-3d4a37274da4',
    'identification_number': 95641963,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3689.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc7d32b6-0163-473f-a30b-2e727fcbd437',
    'identification_number': 104713585,
    'item_consumption_1': 4854,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2057.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d3577ed-7ae2-49c3-9260-3e1fba540b5d',
    'identification_number': 108782441,
    'item_consumption_1': 1168,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 494.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9eaf0a8-05b4-483d-b17b-b3b55a4f5fa8',
    'identification_number': 59484381,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '91f5dd31-d9b4-4f0f-8616-c1871a393615',
    'identification_number': 38749645,
    'item_consumption_1': 39740,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14789.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '502f540a-5a25-49ca-b1a7-242b0764bdf4',
    'identification_number': 41314352,
    'item_consumption_1': 30477,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11913.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eca58bec-0b8b-430e-ab5c-fadf6b69c785',
    'identification_number': 53869303,
    'item_consumption_1': 44.47,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1293.44,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0a3bdcf7-39b0-4af9-bbaf-797fe7f1984e',
    'identification_number': 63726432,
    'item_consumption_1': 683,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 266.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d302bcd-e91c-49b9-9751-f322a26485f2',
    'identification_number': 19452160,
    'item_consumption_1': 519,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 203.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8fe7c949-6779-40fc-92f2-911f94ecae56',
    'identification_number': 31846882,
    'item_consumption_1': 12899,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 7533.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dea74ef8-3a6a-45e2-98d0-99343167e1bd',
    'identification_number': 16191757,
    'item_consumption_1': 5754,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2249.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2e92810-c2b9-4667-9a1b-7eeb66f730f6',
    'identification_number': 41490533,
    'item_consumption_1': 390,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 152.58,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e5207b6c-d0b1-4f16-ab4a-b3b1a1c57391',
    'identification_number': 60602643,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 143.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8d6702c0-d416-4060-b26f-c9e8a95f73c8',
    'identification_number': 7284012,
    'item_consumption_1': "'-399.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 06/2023',
    'item_total_value_1': "'-138.53",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '876d6d5c-fd7f-4d39-ad20-d3f9a4e93cea',
    'identification_number': 29896991,
    'item_consumption_1': 4765,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1862.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36941f55-9faa-4dc5-a172-214791a22ebb',
    'identification_number': 58739149,
    'item_consumption_1': 4830,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 616.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe7d0ab0-cbfb-4549-a714-9ca631f1fa70',
    'identification_number': 43723985,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 116.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4840b41a-2c0e-4cb6-a060-356c3187af82',
    'identification_number': 47472006,
    'item_consumption_1': 16791,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2143.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2089507f-0b7c-4ba9-bfbb-4de051067780',
    'identification_number': 92334865,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 927.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e2f02a3c-2101-4424-9888-c4f38cb47565',
    'identification_number': 35809329,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 0.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93f36545-a5cc-4c24-820f-1a4ac625f146',
    'identification_number': 71277552,
    'item_consumption_1': 20.95,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 609.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '47bf4201-2624-4885-ae63-903833da869d',
    'identification_number': 50776029,
    'item_consumption_1': 46.59,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1355.12,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0856a458-83ea-4edc-9d52-2a77216fcbe7',
    'identification_number': 80994539,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '15f01a13-5f41-41ee-8b07-559fd802ba5e',
    'identification_number': 19453302,
    'item_consumption_1': 78443,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 45813.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45d5f44a-d83b-41cd-b39b-ebaef42d8534',
    'identification_number': 18377602,
    'item_consumption_1': 1879,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 798.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd669e894-6bf1-43da-9632-c8c5a3d914d4',
    'identification_number': 111186056,
    'item_consumption_1': 224,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 87.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '329d2396-c535-417c-9926-3c85cc128fdb',
    'identification_number': 32605579,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '20ec5fb3-f8be-487b-89ff-11e74eed065f',
    'identification_number': 95077677,
    'item_consumption_1': 4468,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1742.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32c45e2a-17ae-4e98-a96a-1d9d283cc28c',
    'identification_number': 45483779,
    'item_consumption_1': 5421,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3166.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c87bc7df-7a75-4302-b051-c8809056948a',
    'identification_number': 35562854,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b52896c-a92e-45e6-b8e6-9a24c192a902',
    'identification_number': 14601737,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.65,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aa9ede82-0450-4ffd-8775-71dbdee4f7dc',
    'identification_number': 102003211,
    'item_consumption_1': 17008,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6329.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fd7c346-1e42-440e-918c-6bfa2215b2e6',
    'identification_number': 65191404,
    'item_consumption_1': 1198,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 152.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '869033b1-b8e4-43d4-a296-2f0ec717638b',
    'identification_number': 80724906,
    'item_consumption_1': 3727,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2176.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15381b51-81eb-4654-a1d9-1863af8211bc',
    'identification_number': 76636291,
    'item_consumption_1': 14.8,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 353,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '016389ab-f1bc-437b-b371-d7e060dbd874',
    'identification_number': 55456812,
    'item_consumption_1': 80607,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 29997.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc5b01d8-3694-4e25-9a2d-af7ef01ec4dc',
    'identification_number': 90337310,
    'item_consumption_1': 508,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 198.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39f18beb-8541-49fe-acc6-fc01b84fda2e',
    'identification_number': 102940746,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6523729b-66e5-4113-984a-9e6bdb39cc6e',
    'identification_number': 15232506,
    'item_consumption_1': 2371,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1007.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd2a8a9a-1839-43e5-8dce-3e71aadf0613',
    'identification_number': 77405340,
    'item_consumption_1': 190.08,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 5528.64,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '755bfe1d-2232-4a76-9ac0-d1887e257881',
    'identification_number': 94050635,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '93244af9-f185-4463-a26a-3a2df54849af',
    'identification_number': 100942270,
    'item_consumption_1': 8500,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3325.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc9538ee-1455-4add-9e41-91bad2a4edfd',
    'identification_number': 12977489,
    'item_consumption_1': 53.52,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3056.52,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'af40a831-7e81-44fe-86d7-17acb25fedb9',
    'identification_number': 31997651,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 82.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c83f30c7-cdd8-46db-8882-1dee4b532c2c',
    'identification_number': 104895209,
    'item_consumption_1': 89,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 34.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '52bdf41e-6cda-41c4-9e4b-ddaf93102930',
    'identification_number': 44476108,
    'item_consumption_1': 582,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 74.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53f92a0c-0c74-4961-9d51-3183392caa0e',
    'identification_number': 22731530,
    'item_consumption_1': 2.417,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 916.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3c756fe-f8f0-4252-abf2-9001f5a532ad',
    'identification_number': 103048340,
    'item_consumption_1': 2143,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 837.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4588cd1-32e7-4405-861a-adda4ea4c3c9',
    'identification_number': 74755897,
    'item_consumption_1': 20844,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 2660.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f0d69b2-fcd3-49c2-9abe-a1b3c51b307d',
    'identification_number': 87807360,
    'item_consumption_1': 2223,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 869.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce34e482-6500-433b-8a27-bdaa8b2d4463',
    'identification_number': 100203400,
    'item_consumption_1': 126,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 53.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '268ce62c-3848-4fc7-8d52-816243bd7435',
    'identification_number': 106612662,
    'item_consumption_1': 75,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 29.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ae115d81-851e-405c-b9c9-d87df1ba3b9b',
    'identification_number': 56881444,
    'item_consumption_1': 24.16,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 702.7,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ee869a1e-83d6-48f7-bc90-a928e059c4d7',
    'identification_number': 33101493,
    'item_consumption_1': 110,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 42.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '703983f8-58e8-42b6-beee-3773ef26ca3e',
    'identification_number': 19310188,
    'item_consumption_1': 141,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 55.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94cd9cd4-3807-4b27-a288-b6f573df8623',
    'identification_number': 79730701,
    'item_consumption_1': 49291,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6291.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d21e2e0-6429-44c2-a316-2fe7a7bd7155',
    'identification_number': 5329582,
    'item_consumption_1': 3640,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2125.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5043815b-02c0-4e23-9ad4-7102740334ed',
    'identification_number': 105957852,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e41f59ac-ff4f-43b9-b09b-875d7fca3de1',
    'identification_number': 62391453,
    'item_consumption_1': 2712,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 346.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbdb721d-eb08-4328-9fbc-b282b506dc73',
    'identification_number': 105277002,
    'item_consumption_1': 3940,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5960.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d99492d-b782-421b-9803-53d9e30bb437',
    'identification_number': 10610464,
    'item_consumption_1': 652,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 276.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '743590c5-fcd9-4cc2-a04c-16ef300281ea',
    'identification_number': 59440015,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd5a60651-ee64-4f15-90fb-985c4f87cd20',
    'identification_number': 94843813,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e7795390-f0cd-4102-b105-61910df2fcf1',
    'identification_number': 31945252,
    'item_consumption_1': 6.5,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 155.04,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6f06db4d-6c88-4a49-9b08-5393b3342d38',
    'identification_number': 923318710,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0457aee0-b0cd-4a42-ba39-5b25a271faa3',
    'identification_number': 9857389,
    'item_consumption_1': 1015,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 396.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b6f7128-cdaf-4cac-96a6-da9553474ab5',
    'identification_number': 107469553,
    'item_consumption_1': 424,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 180.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9aa5a1a7-a4aa-4e3b-937a-8a0d7be3ab31',
    'identification_number': 36149730,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cd6a28cf-447a-4818-9343-a25a2fb6e792',
    'identification_number': 25958100,
    'item_consumption_1': 8721,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3704.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fecb6409-52a8-4683-9476-f72e5249f3d3',
    'identification_number': 35717661,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-124.29",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'fe7a5af0-40f2-4f86-b64b-12aadccf3033',
    'identification_number': 49620622,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee31e6ca-4212-4e6c-9df3-54f3fa1cd39a',
    'identification_number': 104179228,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3379.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f96fc08-0c5d-4d0f-8bb8-24eef7f26db6',
    'identification_number': 43658954,
    'item_consumption_1': 477,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 202.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '493ef87c-e4ae-47ac-8d8f-d42ff8859c22',
    'identification_number': 12918385,
    'item_consumption_1': 42,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 16.41,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'da81dac8-9b02-4dad-84ea-098941d6dd73',
    'identification_number': 56699077,
    'item_consumption_1': 10.84,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 258.54,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '64cc4c38-0917-44a1-b398-9bf8bcb0e52d',
    'identification_number': 101870728,
    'item_consumption_1': 72,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 28.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '466a5513-6fac-40af-a93c-33a722d11e2f',
    'identification_number': 80350844,
    'item_consumption_1': 46.98,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1366.45,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e8ca6276-a053-49bd-840f-37bc31c5d2c2',
    'identification_number': 41486544,
    'item_consumption_1': 2093,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 889.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e8c419c-7d32-4773-a8d6-ad3b5b14b4e2',
    'identification_number': 37612220,
    'item_consumption_1': 493,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 209.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d861ee6-7c81-4a8c-b6f6-1cefc811aee1',
    'identification_number': 14805162,
    'item_consumption_1': 5267,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2052.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fa464d2-bb1d-4e45-a015-766dbe332ff9',
    'identification_number': 37490109,
    'item_consumption_1': 5406,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2113.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bffdaf8-d9be-40d1-ae0f-d2a069ee2150',
    'identification_number': 73533386,
    'item_consumption_1': 590,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 229.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7674be94-4823-4746-b81a-9fd3f424a24a',
    'identification_number': 40803635,
    'item_consumption_1': 2928,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1144.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10e47993-dee4-44d0-bbc1-6d8a375ac982',
    'identification_number': 109594916,
    'item_consumption_1': 12306,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4579.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a73300cc-b287-4537-8893-bcafecf1b772',
    'identification_number': 60066245,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a43c4f3c-da98-4b80-b4fe-e683c879b2f4',
    'identification_number': 109592441,
    'item_consumption_1': 691,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 293.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95940ca5-d189-4a7f-9d84-1acd570bfc25',
    'identification_number': 105042536,
    'item_consumption_1': 18245,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7738.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4972825f-f2c8-4511-9cd0-15de86d030c9',
    'identification_number': 107783096,
    'item_consumption_1': 2291,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 972.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd2f2371-bed6-4615-b693-8ed7970af666',
    'identification_number': 34617426,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.66,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '199bab32-6539-4300-8aa2-a7a1fc50fe2c',
    'identification_number': 99837188,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b288be3a-55cc-4155-917b-e21a018a08cc',
    'identification_number': 106643517,
    'item_consumption_1': 3257,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1902.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc1b9a67-d126-43ac-856c-a7103f3aea58',
    'identification_number': 12275468,
    'item_consumption_1': 2427,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1417.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9e58713-ef85-41ed-9418-ab147e665629',
    'identification_number': 70447330,
    'item_consumption_1': 20.76,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 495.14,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4f001a50-c40b-48bb-a115-e7002da14b17',
    'identification_number': 47023090,
    'item_consumption_1': 24237,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3093.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e64a72f-a384-43ee-9618-5e7a645b03de',
    'identification_number': 92763219,
    'item_consumption_1': 237,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 92.72,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '83a529c8-b73a-4766-bbac-65a185ac9199',
    'identification_number': 19453299,
    'item_consumption_1': 627.48,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 18250.85,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '492a3068-d83f-4db4-b46a-656d8e2acde0',
    'identification_number': 92739245,
    'item_consumption_1': 22839,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8499.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29103293-d1a7-4e5a-be9f-13afa5724929',
    'identification_number': 3030784010,
    'item_consumption_1': 0.1167,
    'item_description_1': 'Dem Reat Exced Ponta-TUSD NOV/23',
    'item_total_value_1': 3.78,
    'reference_month': '11/2023',
    'referente': 'Demanda reativa excedente',
  },
  {
    'uuid_items': '8da9afd1-9e73-44e9-bf53-4e89fdc02485',
    'identification_number': 49314181,
    'item_consumption_1': 1236,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 482.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3747f956-567f-48f4-a50c-65aae419414f',
    'identification_number': 107791463,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0ca6b046-8092-49ec-b58f-cc3bd9d65ecf',
    'identification_number': 35088974,
    'item_consumption_1': 65,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 27.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ead59fc-2619-4833-b577-55f20ce1ef67',
    'identification_number': 103752234,
    'item_consumption_1': 2508,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1464.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89ac5c1d-cac3-4c75-986a-7cd41bd08e4d',
    'identification_number': 98252992,
    'item_consumption_1': 1207,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 471.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e03c02e6-601c-41fc-983b-452dd3b7366f',
    'identification_number': 13008404,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.85,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e44e1bd7-ceea-4595-9a3d-8f955845a727',
    'identification_number': 14952688,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 295.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7569d73a-937d-4a7a-9789-7cc29c4115fa',
    'identification_number': 102194424,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '086e5fdf-1f86-4a4e-b2a9-c5ab45b4c5ec',
    'identification_number': 91404045,
    'item_consumption_1': 10365,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4053.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd08ad154-0d74-411e-957a-b52081c21962',
    'identification_number': 17509980,
    'item_consumption_1': 10331,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4026.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a953a0c3-7ce9-4b00-adee-72d44c8fdc60',
    'identification_number': 17141168,
    'item_consumption_1': 4920,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1917.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '325b69d4-8fca-44b9-bef9-1a07ae4e428d',
    'identification_number': 91694035,
    'item_consumption_1': 4686,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1827.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcbcf832-5a5b-42a6-b691-dbcbe9892a7e',
    'identification_number': 40429458,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bd225e3d-04f2-4b81-b5fb-5a318f39e020',
    'identification_number': 105699047,
    'item_consumption_1': 28773,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3672.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5cc735a-3d0e-4105-b206-0bd62e6ebb6e',
    'identification_number': 59440015,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '366cd8f0-b977-488e-afaf-8cb122d2af3d',
    'identification_number': 35559217,
    'item_consumption_1': 6843,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 873.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bddc6dc9-4bde-4491-8d4d-04f97607857b',
    'identification_number': 14905612,
    'item_consumption_1': 1641,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 958.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0678ab3e-e625-42e4-95f4-7121140033d1',
    'identification_number': 16876709,
    'item_consumption_1': 13932,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1778.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e11e458-69ac-4cdd-a511-a9413a5ee94a',
    'identification_number': 99258692,
    'item_consumption_1': 7109,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2769.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a0d92ba-ed34-466b-b935-d7a1d56893e5',
    'identification_number': 68782624,
    'item_consumption_1': 159,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 67.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2eedf954-6484-44b2-b6af-0d65d539aedf',
    'identification_number': 84156171,
    'item_consumption_1': 5188,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2029.93,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ade4d168-f225-4972-a776-0216b1fe1c6a',
    'identification_number': 27577244,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54d8b561-6e52-4fa8-a6e0-7bdee9f86771',
    'identification_number': 43036830,
    'item_consumption_1': 190.5,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 5540.86,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '364afba7-10a4-45c6-a8b3-e02ac955779f',
    'identification_number': 31923917,
    'item_consumption_1': 21595,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 2756.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bddfd3f-ce4d-443a-9b14-a49cc5fce0d7',
    'identification_number': 42089808,
    'item_consumption_1': 6255,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 798.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f527118-aeaf-4c46-8308-a050f49677de',
    'identification_number': 94763607,
    'item_consumption_1': 9015,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3827.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0ce41b4-9d09-4c20-85e8-da240d7c6d94',
    'identification_number': 71236252,
    'item_consumption_1': 829,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 323.64,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ef875c6d-cd05-4041-b5cc-8c72ad42580e',
    'identification_number': 97005304,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a57fc312-4bb5-4cba-b33e-4e246f3b81d7',
    'identification_number': 101093497,
    'item_consumption_1': 9759,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3802.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2761b723-061d-4505-b2ba-896b74a6f497',
    'identification_number': 93918143,
    'item_consumption_1': 1586,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 671.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7deafd1e-3da6-4193-875c-3c4d6f22493c',
    'identification_number': 67330274,
    'item_consumption_1': 211,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 89.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17f1c903-cc97-4886-a81a-a906eed8a538',
    'identification_number': 93067895,
    'item_consumption_1': 42.28,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1229.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '82bd0451-4396-4f05-8d89-8723f71a7c01',
    'identification_number': 34853642,
    'item_consumption_1': 165,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 64.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1a20d8e-8261-4375-b4f6-0e1409268eea',
    'identification_number': 90881656,
    'item_consumption_1': 1495,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 584.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eae062f9-011a-420e-9932-2a129681ca9e',
    'identification_number': 92002927,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd2679804-841c-4244-894f-b9e9755bf498',
    'identification_number': 17044790,
    'item_consumption_1': 4975,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2112.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d81bae8-60df-48cb-bcd0-865c11dc1660',
    'identification_number': 107160498,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2d94cc39-5fd6-4735-b289-31f4f0227ad4',
    'identification_number': 92950310,
    'item_consumption_1': 15.75,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 916.21,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e0a9ea11-8730-4f62-bff3-6c3c69f3c88a',
    'identification_number': 42162823,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3069528a-dfec-4e24-8207-1dd17caed4e2',
    'identification_number': 11095504,
    'item_consumption_1': 1261,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 534.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63588702-143e-46be-ad3b-59e72bb2357e',
    'identification_number': 91404045,
    'item_consumption_1': 10365,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4405.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8358a5e0-d2ca-4217-8c92-496a0a56566f',
    'identification_number': 46343466,
    'item_consumption_1': 6136,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2283.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9188aab3-aac9-4beb-bbb1-6414e5f7e160',
    'identification_number': 98511467,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fd3dd86-6d99-4b24-a0e2-cb9e7ee5faf2',
    'identification_number': 95563610,
    'item_consumption_1': 10.77,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 313.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'aec6b9ad-df29-42a7-87d5-a36bc1e8ed30',
    'identification_number': 102983011,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '92f8cc7c-262a-433c-8405-31f900b362e6',
    'identification_number': 35256869,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3717caf-dee4-4a5e-8df8-249a09447387',
    'identification_number': 45602387,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '189b148e-cd83-43ef-94c6-e82bad0a92d7',
    'identification_number': 92256686,
    'item_consumption_1': 3275,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1391.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e3fe03a-40fe-4df2-931d-4bd752685b95',
    'identification_number': 19306628,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.23,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9eefa412-ff14-422a-ac22-052c3e6fb459',
    'identification_number': 20700806,
    'item_consumption_1': 125,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 48.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2311010b-5b44-4894-8d78-ecca0aab99a9',
    'identification_number': 10103554,
    'item_consumption_1': "'-500.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-173.73",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '7d5d8412-3b98-4b7d-b596-dc81b8330b55',
    'identification_number': 105202878,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8300f184-7ab5-4029-8c0a-8c3b4214c327',
    'identification_number': 105953261,
    'item_consumption_1': 2319,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1354.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b8c66cb-d675-41c0-a9f3-783a5e2d0412',
    'identification_number': 109633962,
    'item_consumption_1': 214,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 323.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c465833-a903-475b-ae5e-604692268276',
    'identification_number': 11095504,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bc9ea79a-9f73-4b0b-835e-36b1bb7565a2',
    'identification_number': 93324138,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1378.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cdcc6af1-6295-4a1f-ab94-f016b6d7e294',
    'identification_number': 22914315,
    'item_consumption_1': 3894,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1521.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b895a451-9d2c-44b5-b988-ac1e2e89eda5',
    'identification_number': 17159660,
    'item_consumption_1': 16803,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6253.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '908a7724-9925-4ac6-9569-dde12a88e32c',
    'identification_number': 58734210,
    'item_consumption_1': 6.89,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 164.35,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '11904a3e-455d-47c2-9fd9-6a57cdc38a14',
    'identification_number': 32501480,
    'item_consumption_1': 21.4,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 622.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '85095cc6-8f3f-4d95-b3ce-22fa5819a3c6',
    'identification_number': 17678757,
    'item_consumption_1': 6931,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2705,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac6ebc08-0a38-4f76-a041-835bd014c058',
    'identification_number': 58770240,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91552014-5932-4572-b4ad-1e25d772938f',
    'identification_number': 21817405,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5e55ff75-322d-4635-bfaf-b49db7b6e467',
    'identification_number': 110977360,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f0747f37-9c30-4bf3-9126-2b491c77ab04',
    'identification_number': 105077488,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdf2bf06-df98-4e4c-b196-4b2089e0735a',
    'identification_number': 25681656,
    'item_consumption_1': 60,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 25.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3c6d718-4bb5-4e87-a57b-8fdcaba42808',
    'identification_number': 106819135,
    'item_consumption_1': 2603,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1018.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '117d9f27-d31b-435f-8b8f-711941fca397',
    'identification_number': 99990610,
    'item_consumption_1': 1093,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 427.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a77abf52-4ff0-42e9-a823-ad0bedfb7988',
    'identification_number': 39672131,
    'item_consumption_1': 6752,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3943.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1aa5940a-aacc-4e61-90ff-328efcf92970',
    'identification_number': 61792098,
    'item_consumption_1': 103.61,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3013.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '03710d6d-75df-4e48-9ef0-fdb9435574f9',
    'identification_number': 12743542,
    'item_consumption_1': 3024,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 385.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e397530-abd3-43d5-bd49-a5974213468f',
    'identification_number': 105628271,
    'item_consumption_1': 1159,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 676.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c310fc9c-b89e-4b83-a8b5-dfce0c2ba551',
    'identification_number': 79893112,
    'item_consumption_1': 1302,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 553.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88111669-1682-41ad-8c86-2d038ce840b4',
    'identification_number': 5330939,
    'item_consumption_1': 12452,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 7272.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'faac8091-247d-4024-8b51-bf58050f8975',
    'identification_number': 16951662,
    'item_consumption_1': 11152,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1423.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3e2034c-1e3c-41e9-802e-0075e537ccf9',
    'identification_number': 93232489,
    'item_consumption_1': 2155,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 914.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55571638-ee2f-4588-981c-044cf992c73f',
    'identification_number': 43484506,
    'item_consumption_1': 1231,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 718.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0dc8f6d-70fa-4335-9c52-ff558a453bb8',
    'identification_number': 94657190,
    'item_consumption_1': 1782,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 696.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bd16ff6-bf1e-4f0f-b0f7-edebfc61b006',
    'identification_number': 48043907,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c5579158-8b2c-48b2-8591-0c4aca66c723',
    'identification_number': 92232949,
    'item_consumption_1': 299,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 116.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf1dde11-14b8-4d2e-add6-4554a17bfb5d',
    'identification_number': 91670942,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c9635ac7-32ae-409a-9b1d-ac90fef71b53',
    'identification_number': 12640255,
    'item_consumption_1': 1367,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 579.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '990e5c92-b147-46df-a75f-ae9b4026d148',
    'identification_number': 85205672,
    'item_consumption_1': 4026,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 513.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8feaee5-e063-48f3-91bb-a4fa2492099d',
    'identification_number': 100797075,
    'item_consumption_1': 47,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 18.39,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '75831d17-8bf1-4162-b653-fa8f6cf32001',
    'identification_number': 41499255,
    'item_consumption_1': 27704,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10309.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a850e58-0833-4380-858d-0feae78daf55',
    'identification_number': 83076654,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c6cfc6f-9b0f-411d-9f7b-600a420a3805',
    'identification_number': 63416794,
    'item_consumption_1': 14118,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5253.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a26d038-13e0-443c-8d23-62d9fa8b4c39',
    'identification_number': 14671514,
    'item_consumption_1': 7357,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3115.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fb16694-833c-419c-9e4a-c2c5d2a67615',
    'identification_number': 42496390,
    'item_consumption_1': 98.99,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2879.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a48b13c5-6d76-4e8b-8bc3-765498157e62',
    'identification_number': 107674149,
    'item_consumption_1': 535.52,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 12772.43,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'eea0c5af-d802-4f24-8e96-376caf2da258',
    'identification_number': 11490390,
    'item_consumption_1': 1346,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 524.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a80394b-6395-494f-a7b8-adb9a4623a2b',
    'identification_number': 92739245,
    'item_consumption_1': 41.82,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1216.35,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '87509a86-aabe-402b-a885-e1c75434b200',
    'identification_number': 59436530,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b917d2a-e489-4353-93d4-c579f446344c',
    'identification_number': 51527472,
    'item_consumption_1': 28.33,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 824,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '41b83c35-549b-4ca6-8f03-9a83cfa46480',
    'identification_number': 75753758,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd6327ec1-d0da-4770-9e98-624f260b481b',
    'identification_number': 91404045,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '02874424-f018-4f0f-babd-4a97832a8376',
    'identification_number': 38091844,
    'item_consumption_1': 1094,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 427.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c66474de-adaf-4009-9659-21d30b835938',
    'identification_number': 63991373,
    'item_consumption_1': 18.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 452.22,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7158e734-9614-402a-aa6e-5b7891ade7f8',
    'identification_number': 38433141,
    'item_consumption_1': 905,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 352.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49f3945b-0c6c-48d4-91d9-db68bad5885d',
    'identification_number': 53263081,
    'item_consumption_1': 36995,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4721.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88c0f8ce-1c23-4f30-acad-9a7791015648',
    'identification_number': 31645313,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a23c6017-ab2b-4a31-9f30-2982d7d3413e',
    'identification_number': 61115576,
    'item_consumption_1': 971,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 410.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce35eb0d-5a0a-4de1-830a-f4de4cb3d059',
    'identification_number': 107074117,
    'item_consumption_1': 121,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 51.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '430bea5b-94e5-41a9-87be-5c8ec2340f04',
    'identification_number': 95229388,
    'item_consumption_1': 815,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 345.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44055e19-0822-444b-9ea3-de05c98db012',
    'identification_number': 75299372,
    'item_consumption_1': 16266,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6053.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0bc0460-5695-40ac-b9e0-560fc579f520',
    'identification_number': 56526849,
    'item_consumption_1': 5889,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2191.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18326bf1-e47d-45f3-ab11-a4780cb9110c',
    'identification_number': 70870969,
    'item_consumption_1': 10455,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4084.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3acdaa89-e073-42f0-b65f-a6009807a72c',
    'identification_number': 74446975,
    'item_consumption_1': 455,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 192.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7beaf935-2ef2-4843-8521-cb23b75562cf',
    'identification_number': 100060110,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc552ced-68c2-4b95-8ef6-eba70535baf6',
    'identification_number': 43348017,
    'item_consumption_1': 2019,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 788.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a6a33c0-6a4b-44ab-80f8-93c464569f3c',
    'identification_number': 70945195,
    'item_consumption_1': 189,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 73.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efd7ec76-aa81-4d75-abc7-71a1145f9f55',
    'identification_number': 19452667,
    'item_consumption_1': 1411.2,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 41046.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4e68c644-04f2-4caa-890f-48769863f05b',
    'identification_number': 100812260,
    'item_consumption_1': 264,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 102.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62026eaa-0fdb-402b-9f58-8c0f7da19426',
    'identification_number': 68904401,
    'item_consumption_1': 66.12,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1923.16,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e4688f61-e737-4ac9-ba83-6707990967be',
    'identification_number': 31778178,
    'item_consumption_1': 2146,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 910.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e48110e-3faa-4848-b4f4-eb4423344b6f',
    'identification_number': 9050426,
    'item_consumption_1': 6154,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2611.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '470a2dc4-2bca-4015-93ed-880c747fd12d',
    'identification_number': 101919638,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e7c6883-8c5e-4e2f-b26e-d9e342b1b29b',
    'identification_number': 34851151,
    'item_consumption_1': 2966,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1732.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7710e5c2-bd04-4158-9f03-4060f1f94922',
    'identification_number': 22358609,
    'item_consumption_1': 90.92,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2644.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a063dc72-46f7-4cf5-b75d-82d538780734',
    'identification_number': 48362832,
    'item_consumption_1': 4095,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2391.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51c20bf8-be93-4101-8465-473f11f7acc1',
    'identification_number': 108178781,
    'item_consumption_1': 15190,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5652.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '024c5544-38b5-47d8-87d1-a20fe31264b3',
    'identification_number': 92171273,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-169.14",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '2fc5de1f-9020-493e-bb78-5456bf246834',
    'identification_number': 22963677,
    'item_consumption_1': 517646,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 66070.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '673d9e05-9081-4f9a-b0bf-59ea5c09e7d9',
    'identification_number': 11177101,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea285c21-17b4-4760-b4d3-b4282cf8b7e3',
    'identification_number': 71277552,
    'item_consumption_1': 11138,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1421.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '473f4dce-4d84-4be2-aa8a-87eecef33173',
    'identification_number': 17073057,
    'item_consumption_1': 8.86,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 211.32,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '64c2d665-1f24-41db-8212-f071c4a1d8a8',
    'identification_number': 27895610,
    'item_consumption_1': 126.49,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3679.07,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '96578ae3-b80a-4d3b-9452-1c8c5d25fae7',
    'identification_number': 15484483,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7e85e476-fff5-4ea1-8732-55d958737ea7',
    'identification_number': 924063026,
    'item_consumption_1': 280,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 244.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46919faf-d657-4f88-9f6e-83560bf6b8f6',
    'identification_number': 77800699,
    'item_consumption_1': 6910,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 881.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb38594a-71ac-4e91-8c94-96d56580c4a3',
    'identification_number': 100922961,
    'item_consumption_1': 3419,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1448.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9907699a-3651-43f9-8fa7-91c86afb6ac9',
    'identification_number': 29985404,
    'item_consumption_1': 1537,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2325.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4f4a33a-8b4f-46ae-81e8-29a282d49589',
    'identification_number': 43616526,
    'item_consumption_1': 1489,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 633.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e4aaa0e-a255-45e0-b096-8ca3202cef5b',
    'identification_number': 13178032,
    'item_consumption_1': 1036,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 605.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '148a0c40-7747-4562-85b7-33fa0894ff25',
    'identification_number': 14138018,
    'item_consumption_1': 9.19,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 219.2,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e10b3db2-1b7d-4c3e-968f-6da1480ceab2',
    'identification_number': 98253247,
    'item_consumption_1': 381,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 148.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8385c4ba-3404-4401-93a8-e9956f1d98c5',
    'identification_number': 44085737,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '85f0a230-6f15-4e2a-97ad-7e3287d3d80b',
    'identification_number': 16013239,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '11b73784-6f5f-42e1-87f6-3207f525f5f4',
    'identification_number': 110227131,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9154b44-2998-4da6-b4af-5aabd4d80cd6',
    'identification_number': 13877984,
    'item_consumption_1': 69,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 26.99,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '42208335-f772-4892-ba5a-7c02acbde32b',
    'identification_number': 107462036,
    'item_consumption_1': 453,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 176.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b722b0ab-d097-40e7-aed4-dde33c759a51',
    'identification_number': 14334399,
    'item_consumption_1': 4.2,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 196.69,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '66c05c75-11b7-4719-8122-e11d712a823f',
    'identification_number': 107429535,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c18d2fe-d9d8-4ab0-b550-8675332edc5a',
    'identification_number': 103424873,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbf2ed81-869d-4c90-b48c-27eb35094e27',
    'identification_number': 46699236,
    'item_consumption_1': 12475,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1592.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd272796c-53bc-448e-8a1a-15534356cc99',
    'identification_number': 50940694,
    'item_consumption_1': 1198,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1812.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fccf2abc-6b26-4480-9435-b37fb49be7c0',
    'identification_number': 28223152,
    'item_consumption_1': 1975,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 838.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc6bd38d-26cb-4281-9348-13b1fd4f9497',
    'identification_number': 8805504,
    'item_consumption_1': 8040,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3405.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a182352d-8e57-4fab-8615-c926e1f9b0df',
    'identification_number': 45563560,
    'item_consumption_1': 316,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 123.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2799a7bf-9f88-4275-8f7c-2a4f8ba6cb18',
    'identification_number': 40410978,
    'item_consumption_1': 2950,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1253,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3e00537-67fa-49f9-bb86-2028dd037fd7',
    'identification_number': 9788131,
    'item_consumption_1': 69.37,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3961.7,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'd6edef43-81b5-48d4-9ad0-550be222f983',
    'identification_number': 71798595,
    'item_consumption_1': 170.2,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4950.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5f47ea8b-c8c7-45e2-baf6-4cae0e901e90',
    'identification_number': 80201555,
    'item_consumption_1': 13.62,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 324.86,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e8e472c0-56ce-4280-8d37-db2a7e1341dd',
    'identification_number': 42496390,
    'item_consumption_1': 3927,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2293.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '942a25af-4fd1-4bad-9fb0-68a243edb92a',
    'identification_number': 60360046,
    'item_consumption_1': 3033,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1185.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e3b2bea-3904-4b32-ab6f-92ce3e38724c',
    'identification_number': 75768879,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '25bf62a6-9cc7-4040-b0a0-c632ae96f0f4',
    'identification_number': 93451962,
    'item_consumption_1': 3509,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1366.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34014e3a-31ec-4b80-ba5a-f81b57307170',
    'identification_number': 101870728,
    'item_consumption_1': 39.63,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1152.66,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a50d084d-0bf8-4684-b9b8-04395e401707',
    'identification_number': 38768534,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 35.58,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '47e40c2b-ea14-4c89-9873-c1b90f734eeb',
    'identification_number': 10145230,
    'item_consumption_1': 537,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 209.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4282924-2901-47a8-9dfa-10692a6cdd8c',
    'identification_number': 47989050,
    'item_consumption_1': 58.4,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1698.6,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '775d4df9-1411-4aa4-a1e8-9a8e480f10cc',
    'identification_number': 19453302,
    'item_consumption_1': 1911,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 109137.21,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '722693e5-9a17-4d76-afb8-3fc8e2139513',
    'identification_number': 105628271,
    'item_consumption_1': 11163,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1424.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1d10516-1316-4b72-83f6-587fc416ce3c',
    'identification_number': 923323603,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cec4c9bb-53a5-4677-99ab-1e4e650c4c1f',
    'identification_number': 9612297,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2cf81a7-7ee5-48ca-99bd-dc68722368c8',
    'identification_number': 56697198,
    'item_consumption_1': 5175,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1925.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53999844-311e-4fd4-805f-366ffb12ce26',
    'identification_number': 104480050,
    'item_consumption_1': 6,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1b3f73c7-61af-4bc9-97be-3549c3c06b13',
    'identification_number': 110483774,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 112.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d2fe49b-0318-4120-9d1d-67fcdd339f25',
    'identification_number': 58229809,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfbdedd0-ff51-46f6-94c9-08f7d5aa540e',
    'identification_number': 100060110,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7237f9c-c8a2-4b55-a0e9-a7f3c45f9dc5',
    'identification_number': 923309255,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1d144c44-aaa5-4914-a05e-f9546f454d10',
    'identification_number': 99541076,
    'item_consumption_1': 298,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 116.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '057e15c9-c760-4a51-a3af-054aaaea2933',
    'identification_number': 60978880,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 84.67,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1b1cd0c5-1da6-44af-9708-efc9133bd7f4',
    'identification_number': 105799653,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 7.83,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'db5a72da-f4f3-45e6-a328-8463418f7acd',
    'identification_number': 98784870,
    'item_consumption_1': 2682,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1139.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '176c5a09-f4a4-428d-8f43-b8b7ca034bc7',
    'identification_number': 75789981,
    'item_consumption_1': 68.92,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2004.59,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '50e4accb-fb8e-45aa-9a04-aa7e84c126e2',
    'identification_number': 97575941,
    'item_consumption_1': 10302,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1314.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a53579db-4dee-47cd-9ed0-c2d3cfca809e',
    'identification_number': 34743278,
    'item_consumption_1': 850,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 361.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2e9d2c4-97b5-47af-8898-2a5960cbf284',
    'identification_number': 93297874,
    'item_consumption_1': 2095,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 890.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5284b42-8284-46da-92a3-93ebf259a4ed',
    'identification_number': 10647422,
    'item_consumption_1': 24042,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 10210.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45f4ff9f-9e99-4f50-afac-577a7255b75a',
    'identification_number': 98518933,
    'item_consumption_1': 6152,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2608.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c0ee756-f242-4f67-bf7d-976cf5abdb0c',
    'identification_number': 63008084,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2295cf69-06bd-4e7f-8e00-fa7f2bdbc290',
    'identification_number': 91627184,
    'item_consumption_1': 23194,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 9832.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '271849f8-e5f7-4278-987a-b77688dbcc94',
    'identification_number': 39678318,
    'item_consumption_1': 120.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3491.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5322e97b-8e1b-4683-afcc-8d55fefd8c30',
    'identification_number': 31509983,
    'item_consumption_1': 2627,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1114.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58e0fb8d-f1ab-4bd6-b65a-aac863db8cef',
    'identification_number': 14905612,
    'item_consumption_1': 39.55,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1150.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7094acc1-70d7-4dd8-b19f-8dd3e1984efc',
    'identification_number': 37709631,
    'item_consumption_1': 2234,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 871.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2b3e41e-5756-44ef-a249-d7d67d71fd7c',
    'identification_number': 109618866,
    'item_consumption_1': 192,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 81.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70c98567-c47b-4000-a7f9-10933f40c58f',
    'identification_number': 104480050,
    'item_consumption_1': 900,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1361.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c32a37f0-c465-42a0-a727-d997c073acdb',
    'identification_number': 936166486,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6c1c100a-c3b7-4c5b-bb88-28f5dd3f197f',
    'identification_number': 95920862,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c0fdac86-73d0-47b4-8a33-3b49a3013c40',
    'identification_number': 45825319,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7a076f94-b270-4b4e-a194-f2887393542d',
    'identification_number': 30035473,
    'item_consumption_1': 2355,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 921.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '135de55c-5ca0-4412-a695-61e05ba80570',
    'identification_number': 93825579,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '14fe4dde-4c56-491a-bf14-444b2ff3ba8e',
    'identification_number': 37781430,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4acd2a9d-f534-41f4-b064-e4f1fd4a39d7',
    'identification_number': 103006370,
    'item_consumption_1': 2740,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1164.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65c0aa66-4dac-42ae-a650-70bdd6c84163',
    'identification_number': 70082090,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87a52bac-471f-4b58-aaea-cdf046bb9dc8',
    'identification_number': 102153094,
    'item_consumption_1': 350,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 136.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0dd882cb-1143-43b5-b24a-0310bc0f7dc8',
    'identification_number': 105479187,
    'item_consumption_1': 1584,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 619.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2510fbcb-a96a-4094-b6d3-56f7581e9dd1',
    'identification_number': 68749899,
    'item_consumption_1': 688,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 291.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5d353fd-9f93-485f-a5c0-47e541c80da1',
    'identification_number': 109368380,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92e2f8cb-3891-46bf-a77d-51956af59714',
    'identification_number': 65625960,
    'item_consumption_1': 5156,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2016.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ca85eb4-268c-4915-8224-5dfb4b2cf2c2',
    'identification_number': 16000862,
    'item_consumption_1': 594,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 1022.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '672551fe-8522-4684-82a6-ad0964e30833',
    'identification_number': 19306105,
    'item_consumption_1': 421,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 178.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d81d077-7e9b-4b44-b7c1-c11ca9629d7e',
    'identification_number': 106851217,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4c803718-915d-4532-929d-a327ac766d58',
    'identification_number': 17671973,
    'item_consumption_1': 4120,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1604.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4499346d-2db9-469a-ad5d-7c159f8c9047',
    'identification_number': 42404576,
    'item_consumption_1': 3952,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5978.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c8d2de9-dab7-4ac2-9c86-fc0dfa2be5e5',
    'identification_number': 29957150,
    'item_consumption_1': 9286,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3629.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b544a68a-c6e8-4deb-bc81-d2a302b7c244',
    'identification_number': 27549615,
    'item_consumption_1': 2880,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1123.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d444e97-3128-472e-812c-f2f4d074f425',
    'identification_number': 47573481,
    'item_consumption_1': 26117,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3333.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43e40e87-3b65-482f-b318-afa05039682a',
    'identification_number': 101269161,
    'item_consumption_1': 438,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 171.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17373ed9-2a28-47b6-8d1c-cb278d32779e',
    'identification_number': 6589278,
    'item_consumption_1': 2033,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 863.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fff16eb0-c6b0-4d14-91e3-0626960ac4ce',
    'identification_number': 102131791,
    'item_consumption_1': 2789,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1087.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b13e9567-541f-45ae-b35e-7385bd95183e',
    'identification_number': 105611271,
    'item_consumption_1': 2716,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4108.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '811f9272-b208-43a3-a222-352e95e1bd55',
    'identification_number': 25823604,
    'item_consumption_1': 4157,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1621.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd53db63-56bf-4e90-b1f3-e082778d5a03',
    'identification_number': 19450753,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aa4aacfd-f3f1-40c3-b0ee-6ef5dff532f0',
    'identification_number': 98780956,
    'item_consumption_1': 10966,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4276.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e4c28fd-1c1e-426b-9063-f8baeb234dde',
    'identification_number': 110226399,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e267e65-9225-413d-894c-16b7df7ef943',
    'identification_number': 99540525,
    'item_consumption_1': 66,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 25.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f256ac2-b11e-4a5a-ad24-5001db6057be',
    'identification_number': 15449653,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 107.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cb7ebff9-a328-4056-9599-00b6814e1401',
    'identification_number': 63604116,
    'item_consumption_1': 17130,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2186.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f29abc1-ecbb-454a-aba3-59a4047ac6a2',
    'identification_number': 81766408,
    'item_consumption_1': 1929,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 753.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eba2b9a6-6eb1-4905-9114-9f42ef07afdd',
    'identification_number': 9189939,
    'item_consumption_1': 2006,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 782.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3aae4de-6a17-43b5-ae94-916d76799502',
    'identification_number': 50792180,
    'item_consumption_1': 91.24,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2653.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '87d715b1-1c00-445d-b069-963678b2efe6',
    'identification_number': 107552078,
    'item_consumption_1': 271,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 115.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d316431-5640-4386-8c52-6abded997b19',
    'identification_number': 5605369,
    'item_consumption_1': 7050,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2748.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10e345b3-d3b4-43b3-a576-31b29f16f727',
    'identification_number': 75063077,
    'item_consumption_1': 601,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 909.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5dc82a9-ce55-4209-8a13-af894a3c803a',
    'identification_number': 32927061,
    'item_consumption_1': 23.27,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 676.81,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0bbd6bd8-b049-463c-9943-8067e7b5e8b3',
    'identification_number': 17970806,
    'item_consumption_1': 1003,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1517.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cc0f993-b268-467d-bc88-fc7da333ace2',
    'identification_number': 18040918,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b59da43-e57e-453c-909e-f221d85703a9',
    'identification_number': 95765840,
    'item_consumption_1': 23.41,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1361.79,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1711dacb-9ad4-4cfe-ae81-88c4cfb4e25b',
    'identification_number': 77887930,
    'item_consumption_1': 986,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 575.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d2bcc5a-0b52-43a5-8ba5-5dda9368a046',
    'identification_number': 14334399,
    'item_consumption_1': 49.1,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1428.1,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5e1e203b-dcce-4aa8-884a-df00bfb8558d',
    'identification_number': 104766190,
    'item_consumption_1': 24902,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9267.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '449f3a85-65ea-4636-b5cd-778d3f247997',
    'identification_number': 23266414,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4dbb3f6-0746-42b3-ab2b-754171cefbfb',
    'identification_number': 108560015,
    'item_consumption_1': 216.59,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 5165.8,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fc35b167-f67d-4616-80aa-0c40cf2b218a',
    'identification_number': 103341013,
    'item_consumption_1': 775,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 329.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cac94077-eb03-4670-bf3e-7c20ce380f35',
    'identification_number': 58742387,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db777568-450b-4aa9-aa02-719bd449eadb',
    'identification_number': 101312660,
    'item_consumption_1': 2341,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 914.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8eba51a-e098-4de5-85da-78e777d65643',
    'identification_number': 16977823,
    'item_consumption_1': 900,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 351.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '179a1d7a-f947-42ee-8f3a-712ec9655c97',
    'identification_number': 30015189,
    'item_consumption_1': 2203,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 933.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0824f10-03d3-46d9-9415-00b1abaabaf4',
    'identification_number': 100765076,
    'item_consumption_1': 6229,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2638.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72e6c52e-b548-42e2-bb93-21816e4625db',
    'identification_number': 99379422,
    'item_consumption_1': 32323,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4125.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89cb7547-eaf6-4e03-8365-0d36cd269099',
    'identification_number': 16065590,
    'item_consumption_1': 1299,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 505.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a681aa4a-b30f-4f6f-bc98-c0425c1f092f',
    'identification_number': 12404926,
    'item_consumption_1': 4038,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1711.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0323d6bf-e114-4a83-b6b3-ae049f17129c',
    'identification_number': 42469805,
    'item_consumption_1': 226,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 96.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '442575aa-bbb2-4583-982e-893c1eb6793e',
    'identification_number': 24017353,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 19.56,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd8fd4bcd-c1a1-4d88-b1d7-9d70f22074b0',
    'identification_number': 15842231,
    'item_consumption_1': 1391,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 589.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef894177-41dc-4ceb-bce3-6d02937d145d',
    'identification_number': 17422760,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 109.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1516ec2b-4599-4882-8e89-b0f2f1b9c915',
    'identification_number': 58737863,
    'item_consumption_1': 2.01,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 116.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '91cc254c-0835-4dc6-8b07-c17eb75e4e13',
    'identification_number': 87807360,
    'item_consumption_1': "'-1.836",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2020',
    'item_total_value_1': "'-588.96",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '86dd3617-230e-4a4c-8c0f-3c924a860013',
    'identification_number': 31945252,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 156.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5d7a70fe-12a5-41fa-81e6-67c318c9cd53',
    'identification_number': 105653691,
    'item_consumption_1': 15613,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5810.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de096738-fc7d-4825-b472-379680de7644',
    'identification_number': 98127373,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b0aa1014-ece3-40dc-9924-6c1059186523',
    'identification_number': 33375453,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 92.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '56b9a8b6-ad4c-450c-a4d4-9dd67f587555',
    'identification_number': 105849944,
    'item_consumption_1': 812,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1228.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5616ecd7-62ff-40cb-8fbb-d73e2f7a0c50',
    'identification_number': 44219393,
    'item_consumption_1': 71327,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 26544.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1075f42-a37a-4bd0-83a5-6e020f6a1e01',
    'identification_number': 87807360,
    'item_consumption_1': "'-287.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2020',
    'item_total_value_1': "'-100.06",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'fd659506-8275-45f5-b17c-6afc45de5722',
    'identification_number': 70556997,
    'item_consumption_1': 2836,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1656.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf71234e-d0f0-4512-8d19-5327cbca9f30',
    'identification_number': 34346007,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2d43f3a3-444c-4882-8b8e-7eeba5c84184',
    'identification_number': 103039023,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6a06a47c-3ceb-4940-ad49-482ceb9f07ca',
    'identification_number': 17005230,
    'item_consumption_1': 147,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 57.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da81248b-c78c-44e2-b025-7ead47137304',
    'identification_number': 16677412,
    'item_consumption_1': 2.68,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 125.51,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '56ce7289-3f4d-4084-bab3-15d6bd09b0c5',
    'identification_number': 23088460,
    'item_consumption_1': 1784,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 696.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71cb4057-9d15-4316-af32-0a422b41cf32',
    'identification_number': 56414762,
    'item_consumption_1': 100,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 36.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f744301-3b4a-4ab4-9225-d796f70dc4e8',
    'identification_number': 46021191,
    'item_consumption_1': 5610,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3276.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4969eab9-eee2-451c-b021-f7b11809558c',
    'identification_number': 31686729,
    'item_consumption_1': 79.4,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4534.52,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '9496cee8-14d8-4908-871c-34ffea8751ee',
    'identification_number': 13789430,
    'item_consumption_1': 1058,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 135.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '263c30f3-ea02-4827-93c3-2970f162b7fe',
    'identification_number': 102481172,
    'item_consumption_1': 37698,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4811.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba3e3cfe-5c5f-4fe5-86cb-044b61a17f2d',
    'identification_number': 96506954,
    'item_consumption_1': 9509,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4042.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9a863fb-80f9-4acf-bde5-3ae7f3e03833',
    'identification_number': 107941589,
    'item_consumption_1': 5601,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3271.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30d6cf2d-6a53-4aa6-9497-5da1a78e31fb',
    'identification_number': 56708890,
    'item_consumption_1': 31.78,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1814.95,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ff280a9c-7f32-4884-9cfd-bdc719f65890',
    'identification_number': 13845551,
    'item_consumption_1': 155,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 65.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fea1657-2f6e-43d5-b32b-bc9feb9025ec',
    'identification_number': 103438157,
    'item_consumption_1': 2064,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1205.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '714ab856-e96f-4403-b5d3-bf5919a2edc9',
    'identification_number': 8115770,
    'item_consumption_1': 261,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 101.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d5d3b42-bebb-4553-bc17-2e4c88e8fffd',
    'identification_number': 105361690,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e0fd592e-9de7-4b7f-84f7-5b81b54af7ad',
    'identification_number': 100741770,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e784b5d6-0e9b-4832-927d-73ad726c629c',
    'identification_number': 62557777,
    'item_consumption_1': 9945,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1269.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c97dd68-6eee-44d1-b3c0-5bc6049e7303',
    'identification_number': 83790802,
    'item_consumption_1': 129,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 50.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65f3c54a-12bc-423b-84fa-acbdc0f95c0e',
    'identification_number': 93627246,
    'item_consumption_1': 2703,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1147.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de91d283-03c1-4d88-810d-873d66b1cf30',
    'identification_number': 59455896,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '036eb01a-61db-4bcf-a462-ea325efd835d',
    'identification_number': 58741070,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90b85ee3-04b5-42a0-9fd7-ee24adb15de4',
    'identification_number': 36704296,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'deca3224-fd69-4a2c-b4fc-e83dca7a7bcd',
    'identification_number': 105455172,
    'item_consumption_1': 8094,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3439.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f5a8bec-01e9-49b7-954b-86baf151f354',
    'identification_number': 37612280,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8f6382fb-6e72-4e03-9671-a632901f23fd',
    'identification_number': 16105869,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '507537b8-f629-4ff7-a7f4-9277e42fb0d8',
    'identification_number': 106129864,
    'item_consumption_1': 652,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 276.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca90bb65-da89-442c-a14b-dc3b9cbfa803',
    'identification_number': 39672131,
    'item_consumption_1': 6752,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 10214.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1448594b-3d21-4fb9-9eee-d6f7c99a81d2',
    'identification_number': 81170629,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a2c0f9fb-e86a-47de-89e7-7498673e5d52',
    'identification_number': 27718077,
    'item_consumption_1': 2327,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 985.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '858a117c-9b55-49f1-8dce-c2ee9975b197',
    'identification_number': 32046634,
    'item_consumption_1': 369,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 558.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3738b4e1-90fd-4696-b64d-65eac9e05ccb',
    'identification_number': 27716309,
    'item_consumption_1': 1370,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 535.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc15884d-ae64-4477-b065-42302d312d59',
    'identification_number': 103202153,
    'item_consumption_1': 3317,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1405.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ecdf7f70-5203-4c35-99ae-a49567e37527',
    'identification_number': 110679393,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c167b012-3ae4-4e70-bed3-8e2ac1b37fa7',
    'identification_number': 28180240,
    'item_consumption_1': 4509,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1909.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddabef63-7446-44e6-9158-4e7d54ca67d0',
    'identification_number': 42246873,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '01c66b1a-e9b1-4e38-95f1-d177e23f0baa',
    'identification_number': 107328160,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3510b529-e865-40af-b07a-391ba9bcb15f',
    'identification_number': 71998012,
    'item_consumption_1': 10004,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4249.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '152cc2e4-8bd8-445d-8eaf-3130eb9c5ad6',
    'identification_number': 99540509,
    'item_consumption_1': 151,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 64.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77c5af81-4374-4744-aa43-154c159edf50',
    'identification_number': 75789981,
    'item_consumption_1': 3495,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2041.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0044ebf1-f46e-44e7-b964-e386835d4170',
    'identification_number': 84367539,
    'item_consumption_1': 381,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 148.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0814736a-b484-456d-8c8d-4a124526c224',
    'identification_number': 106636286,
    'item_consumption_1': 109,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 46.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e639855e-89a0-4682-9789-49c4a0e06f1b',
    'identification_number': 83790802,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38cb7ce2-1250-44bc-ad32-c4e94abe5b69',
    'identification_number': 33485631,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.3,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cdc80124-3717-45cc-a8aa-c0bfc8b0c6cb',
    'identification_number': 12673544,
    'item_consumption_1': 2639,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3992.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8850d042-86c6-4bd1-bbc3-93eaf8fcde7f',
    'identification_number': 100812260,
    'item_consumption_1': 264,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 111.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c14c3be5-7e13-48be-bd0c-732d45540190',
    'identification_number': 102259178,
    'item_consumption_1': 7576,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 966.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa118c81-5f7c-459f-9567-7c0a1411232d',
    'identification_number': 41674340,
    'item_consumption_1': 2055,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 873.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f667689a-0b8d-4196-8991-b3beaa7fcbc3',
    'identification_number': 44520603,
    'item_consumption_1': 15418,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6541.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d81342f-3964-41c5-ab97-7340cf1957ed',
    'identification_number': 97779113,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2152.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1149118-bc22-4cb4-a1a4-5ed0cad74195',
    'identification_number': 26987415,
    'item_consumption_1': 12.51,
    'item_description_1': 'CONSUMO FORA PONTA TE',
    'item_total_value_1': 4369.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08b4b17f-e73f-447b-8fad-b198920db2d0',
    'identification_number': 9868941,
    'item_consumption_1': 108,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 42.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7f9c7fc-cc21-42df-9341-46fa03163ffd',
    'identification_number': 107087308,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54d0d14c-1b62-4656-920d-5efe93f5e73c',
    'identification_number': 15547159,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ba6905e0-7d9a-4735-b687-39a4d58af1c2',
    'identification_number': 29885680,
    'item_consumption_1': 1349,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 573.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '681d1867-633c-4730-bb44-8c9d6d45459c',
    'identification_number': 103501967,
    'item_consumption_1': 1810,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 769.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68fc0dd2-73bb-4eec-844c-0c9f441e15ce',
    'identification_number': 100582974,
    'item_consumption_1': 6652,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2819.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8989c8f6-4369-4422-a13b-382ef4e9328f',
    'identification_number': 15158241,
    'item_consumption_1': 374,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 565.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '244f78ad-da8a-4b7b-94f8-e61fae5fb30b',
    'identification_number': 80994539,
    'item_consumption_1': 3352,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1419.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4ecd836-1377-4643-907b-b1095f594b9c',
    'identification_number': 42556198,
    'item_consumption_1': 28848,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10735.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c00920fc-7bd7-48ba-8531-ec683d81ae25',
    'identification_number': 83154566,
    'item_consumption_1': 1074,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 419.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18c98c7c-fa98-4b13-bae0-2bcad2dfa9d6',
    'identification_number': 9248420,
    'item_consumption_1': 5977,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2537.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6128d104-6558-49d3-8ee8-2840cfeb2cf4',
    'identification_number': 17970806,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 196.54,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '063465e5-e3df-4386-9039-ac0014e533cb',
    'identification_number': 18517110,
    'item_consumption_1': 1402,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 594.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63ecf30b-94d1-49d8-8b0e-15a9accc85ba',
    'identification_number': 102315698,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '912f22a8-e8e0-4c6d-a87e-ff37cfe4d487',
    'identification_number': 106041983,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 225.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80ea9c8e-5eb7-4c5c-8c1b-39948b38c3ce',
    'identification_number': 85650307,
    'item_consumption_1': 503,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 213.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c5ab633-1411-4e64-b97b-62833624657f',
    'identification_number': 32403011,
    'item_consumption_1': 1.73,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 41.28,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'c1ff7943-b93f-4303-912c-d72d1b4f0e53',
    'identification_number': 100365302,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '35d6761a-9ed3-4458-a2bb-3d52503790e6',
    'identification_number': 17109833,
    'item_consumption_1': 2.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 70.61,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd68f6691-285c-4ad9-92ac-85ea45d3208f',
    'identification_number': 45508399,
    'item_consumption_1': 348,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 135.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c01122c2-d3e4-4413-b27d-769b43d5a33a',
    'identification_number': 50776029,
    'item_consumption_1': 6,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 2.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '183b2be9-4bb2-4a4c-a064-ab932b789226',
    'identification_number': 63964112,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ecebead5-f7f3-430d-860c-b6a56ad961eb',
    'identification_number': 60720514,
    'item_consumption_1': 83,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 32.47,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ab58af86-44b8-4315-991c-ac6354d969a7',
    'identification_number': 91517303,
    'item_consumption_1': 389,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 151.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0060db78-a66a-44aa-b591-62d427a2c716',
    'identification_number': 44502931,
    'item_consumption_1': 65,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 25.42,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b33fc257-3541-49b6-ac08-7fac35266c90',
    'identification_number': 102014868,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO (02)',
    'item_total_value_1': 14.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6d40d384-c815-47d3-8d5a-7e930356330e',
    'identification_number': 68085559,
    'item_consumption_1': 5.39,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 128.56,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b7c11e13-dc47-4b00-9a9f-73dac1f2e542',
    'identification_number': 107833158,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e969765-c866-48a5-a8c7-0da658292288',
    'identification_number': 110373383,
    'item_consumption_1': 322,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 136.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07358825-005d-4944-ab52-049b712f8a4f',
    'identification_number': 95847952,
    'item_consumption_1': 3613,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5465.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a01ce816-53b2-4704-9d2d-e39469e727ed',
    'identification_number': 15575675,
    'item_consumption_1': 4428,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1876.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcd6a8d1-8d7d-4361-9528-eb0d348c9328',
    'identification_number': 101912331,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 16.82,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0f1862a0-0515-494e-9122-dcace094282f',
    'identification_number': 14115344,
    'item_consumption_1': 4730,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1847.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2459fc1a-bf6d-4e16-bbeb-4dd69759ddca',
    'identification_number': 38893614,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '357a6988-366c-46a8-8047-078827636d47',
    'identification_number': 56525230,
    'item_consumption_1': 8510,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1086.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17de1000-078b-47f1-b5d2-95d0e604323c',
    'identification_number': 73378100,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63e5374c-6f0e-46af-ba17-7187d448d48c',
    'identification_number': 96491833,
    'item_consumption_1': 4081,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1590.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29304fcd-4228-49dc-a7c2-c01a08738fd0',
    'identification_number': 32563256,
    'item_consumption_1': 421,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 178.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '120cc2c7-77e1-4240-a513-f49bcb89ea53',
    'identification_number': 90950917,
    'item_consumption_1': 6232,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2646.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53e76e2e-09ea-4f3c-af77-2d3fab6cd1e2',
    'identification_number': 19452632,
    'item_consumption_1': 564347,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 72031.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e60f9538-bdc4-4d51-9e6f-b1e347361d46',
    'identification_number': 106980645,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '58b72c19-9be3-47d0-a262-8e9f60df1b9c',
    'identification_number': 99540355,
    'item_consumption_1': 945,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 369.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4927af0d-bf52-451f-84c2-fb1bf442c10f',
    'identification_number': 53263081,
    'item_consumption_1': 45.97,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1096.42,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '72fc6d33-d7c6-4cc2-b088-b34c1f87202d',
    'identification_number': 81859945,
    'item_consumption_1': 2440,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1425.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '180dc609-bdbe-4975-a8b4-96109034c7fe',
    'identification_number': 98798510,
    'item_consumption_1': 109,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 42.63,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ea89d2a6-78bc-4f8b-a4f3-ff7cae7185b3',
    'identification_number': 56697198,
    'item_consumption_1': 19.12,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 456.04,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '87bc02d6-af81-40bf-b74d-3d007f9874cf',
    'identification_number': 48999881,
    'item_consumption_1': 2267,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 962.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a09e1e2-bcdf-4a3b-bcda-4f89f6eca887',
    'identification_number': 85205672,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ba2192eb-0686-4f9d-8b4f-1f5f57bc2260',
    'identification_number': 91319293,
    'item_consumption_1': 3379,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1320.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d7a195e-83ad-416a-ab91-fae56bc66066',
    'identification_number': 104345136,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04d294ef-ca2c-4170-945b-4a9874b82b24',
    'identification_number': 45851654,
    'item_consumption_1': 4815,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1878.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8738878-63d8-44fb-8f71-1172df70c177',
    'identification_number': 97988804,
    'item_consumption_1': 1229,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 717.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c14a712c-1796-4ac1-9a3b-311e1ad7e4c4',
    'identification_number': 53507460,
    'item_consumption_1': 29.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 714.58,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c942ae99-41f3-4722-b956-1e0b4839f31c',
    'identification_number': 105455172,
    'item_consumption_1': 8094,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3164.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '320868b4-fda7-43f3-908c-9b8232c9b38d',
    'identification_number': 104467010,
    'item_consumption_1': 451,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 190.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8c290b6-fd67-40b8-bd23-b2412ea6eb7f',
    'identification_number': 111267366,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c93dc6b1-aaae-476c-a99d-cf79e85957db',
    'identification_number': 40611914,
    'item_consumption_1': 2110,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3192.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd069e285-45dd-4540-bcc6-4307b0e2247d',
    'identification_number': 50792180,
    'item_consumption_1': 3446,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 439.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d86e605-4929-49ce-be69-d397ea024a00',
    'identification_number': 98193210,
    'item_consumption_1': 3956,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2310.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '457973e2-1747-4dc9-9d6d-37b6c77aed1c',
    'identification_number': 102014990,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 118.33,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '4c40c741-5635-4917-b68d-29b278383255',
    'identification_number': 71998012,
    'item_consumption_1': 10004,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3909.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '805fd49a-00e3-45cc-895d-0003b8bd6a11',
    'identification_number': 32827598,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05631984-436f-4c8d-be64-5c05bd7f3b9b',
    'identification_number': 44001479,
    'item_consumption_1': 12791,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1632.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4287575d-a623-4e65-98da-ab68ac500d75',
    'identification_number': 18910874,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4de8a132-09d3-4729-9f0a-2f995029d227',
    'identification_number': 39162567,
    'item_consumption_1': 2858,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1213.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '375fd82a-78ce-436a-accb-2aead7e12b2e',
    'identification_number': 31789366,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1305.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ecd4649a-fada-43b4-861a-d6ef29cf83c8',
    'identification_number': 95591850,
    'item_consumption_1': 15273,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6488.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '813c8a6b-54e2-42f0-8f02-a8f5583d8276',
    'identification_number': 44606931,
    'item_consumption_1': 1455,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 616.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12fea036-dd7b-4503-ab80-1ac7566c2009',
    'identification_number': 112252974,
    'item_consumption_1': 1,
    'item_description_1': 'SERVICO DE LIGACAO PROVISORIA',
    'item_total_value_1': 54.54,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '6e0c4f2d-ddb1-4c78-8391-293e6a763b76',
    'identification_number': 56876815,
    'item_consumption_1': 10485,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1338.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33e38e89-12df-48ad-b493-4ba13e673e1a',
    'identification_number': 16105192,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a5f1edf1-73b0-44eb-985b-af882eb02086',
    'identification_number': 19450133,
    'item_consumption_1': 354,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 138.5,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '46eb3a02-6b09-4d9f-aecc-485d01fa800c',
    'identification_number': 72507136,
    'item_consumption_1': 58,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 22.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '557e34ce-08c0-4d02-afa4-503dc40db0bf',
    'identification_number': 39672131,
    'item_consumption_1': 53239,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 19812.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2db4b54a-2b1b-4986-854d-8508dae38a50',
    'identification_number': 81494394,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '250d6189-4b7d-487e-b528-83302f1bf251',
    'identification_number': 86489771,
    'item_consumption_1': 3576,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1516.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d6367c8-7f29-4d93-a828-2513bde64c67',
    'identification_number': 65141024,
    'item_consumption_1': 9499,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1212.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f192f2ac-2790-4b34-8f6f-b4e1d815f522',
    'identification_number': 67330142,
    'item_consumption_1': 425,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 180.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd229318-4f45-4893-b6d7-db367b6b8934',
    'identification_number': 98644548,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '733e707a-6afc-4d5a-8884-58ac64a86bcd',
    'identification_number': 42396905,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b2c73760-2f33-490d-9967-ba479d6b1512',
    'identification_number': 99531054,
    'item_consumption_1': 5100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1990.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15c62b53-d856-46fb-bccc-755484cfc93d',
    'identification_number': 59264993,
    'item_consumption_1': 14118,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1801.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffb64213-db2e-45cc-9d62-1ce703ec1f97',
    'identification_number': 84238828,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ddd99699-893c-4377-9010-9ddc4e7430e6',
    'identification_number': 111671965,
    'item_consumption_1': 1,
    'item_description_1': 'SERVICO DE VISTORIA DE UC',
    'item_total_value_1': 25.73,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'be58908b-b9a6-4c8b-a51e-ef02d11927e8',
    'identification_number': 103004874,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '802b3bfe-c765-47d3-8571-02e4ea4eb1e7',
    'identification_number': 36691143,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.04,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4d909cba-3680-4cd3-9665-c5a9cc03ced7',
    'identification_number': 85780499,
    'item_consumption_1': 365,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 154.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a06ac51a-544a-4043-a9d4-f806c7449b3f',
    'identification_number': 13968297,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8a60993d-0c34-4a29-ac54-61094e885def',
    'identification_number': 16246837,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '728522a1-066d-48fa-a874-61d434131b68',
    'identification_number': 100692990,
    'item_consumption_1': 1351,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 527.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a344ceab-cae3-4bde-86ae-03335f092b3e',
    'identification_number': 104585102,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17a3183b-50e3-4e1f-9477-0c428d76bb02',
    'identification_number': 58739092,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e3b17e3-8e28-4f33-8887-fe7680b20711',
    'identification_number': 12027740,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b4867b5-7f04-49f5-a520-fb09d9f8efba',
    'identification_number': 15909581,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '76b24b51-f074-44fe-a3b7-d76af6365d1e',
    'identification_number': 93683553,
    'item_consumption_1': 654,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 277.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c80e86d9-2840-4715-aae8-c529f64ac4c1',
    'identification_number': 29985404,
    'item_consumption_1': 416.16,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 12104.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3513e818-432b-4a92-b62d-82c4739d5841',
    'identification_number': 42390001,
    'item_consumption_1': 636,
    'item_description_1': 'UFER Fora de Ponta (Reativo)',
    'item_total_value_1': 236.64,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': '24b64ea9-d82d-4cbf-bcdd-f8dfdc006c15',
    'identification_number': 20893310,
    'item_consumption_1': 1163,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 453.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fe34d9b-5c58-4e70-bcd7-e8b5159bf27c',
    'identification_number': 18339603,
    'item_consumption_1': 11814,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4396.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7b80b19-097f-4bfe-b4bd-e875d951faf3',
    'identification_number': 58734210,
    'item_consumption_1': 1648,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 210.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd84e05a-ceaa-4ae2-8463-017a25afc646',
    'identification_number': 103152253,
    'item_consumption_1': 1973,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 770.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a1d8b1e-2d53-4226-81e3-d577fe18328d',
    'identification_number': 71655956,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e5f37d8c-6c5d-48b7-9d2f-c48b49448758',
    'identification_number': 56544545,
    'item_consumption_1': 35.32,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1027.3,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8da539ac-f0a8-4d2b-bc2b-fb133ca382ec',
    'identification_number': 15981452,
    'item_consumption_1': 16,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 301.49,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ee16ff39-fe68-4fec-a637-d6e6da80c6c8',
    'identification_number': 56666462,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '426c8dee-5747-40a3-8069-346b05ed26e6',
    'identification_number': 100394060,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ef92c38a-3b8f-4860-8741-d6a9d9e50b2f',
    'identification_number': 78084237,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 211.22,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '47b1e9ee-6943-4091-b22c-081ea436fdf8',
    'identification_number': 39466655,
    'item_consumption_1': 5740,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2238.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbe3f828-9c87-47b1-823b-6d81a65b4a63',
    'identification_number': 62391453,
    'item_consumption_1': 29925,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11136.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1cdc86b-c50e-453f-87e9-2c9a88b4cf5b',
    'identification_number': 74755897,
    'item_consumption_1': 20844,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7757.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b57f6d9-c2fa-4f77-bf04-0cddceb63b4a',
    'identification_number': 50792180,
    'item_consumption_1': 88,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 34.41,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ed37730c-0273-4259-aa8e-87e83046a42d',
    'identification_number': 19450303,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 5.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '228cfe47-b938-4b43-84ff-cdcbad07b916',
    'identification_number': 68904401,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2256ea21-b8a8-4d01-88df-acd3706d5029',
    'identification_number': 34617418,
    'item_consumption_1': 31.26,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 909.21,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ab2c9f78-a548-40c6-9df8-2cacbdbf9fcf',
    'identification_number': 15138704,
    'item_consumption_1': 4858,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2061.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '929b6dae-d220-410f-9d8d-bc1f98e4d8ea',
    'identification_number': 22162330,
    'item_consumption_1': 17939,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6675.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7afc4faf-97f8-417f-a978-d578a2123689',
    'identification_number': 28803809,
    'item_consumption_1': 5996,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2540.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95d50b4e-1c4c-4b60-9709-39070ae979e3',
    'identification_number': 101517556,
    'item_consumption_1': 4061,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1719.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23bd70ba-898d-44cf-a7ac-2493faf735ca',
    'identification_number': 96898550,
    'item_consumption_1': 510,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 198.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3634f9c-ee98-45cd-9fc5-cee324742e94',
    'identification_number': 104186020,
    'item_consumption_1': 625,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 945.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be854fd3-626f-4cdb-ba47-04c49ee390be',
    'identification_number': 28748735,
    'item_consumption_1': 783,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 305.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d131015-1ccc-4026-8a4c-b294d6e8eebb',
    'identification_number': 100187161,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec9fa7ad-a3a0-4ffc-b791-5fed1a2a74bb',
    'identification_number': 23078758,
    'item_consumption_1': 10036,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1280.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22af5cf4-19ff-4e3c-ba48-14f50d7f3b50',
    'identification_number': 83154566,
    'item_consumption_1': 1074,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 455.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ee5ac5c-70ef-4140-9b1f-f3e4298cc0e2',
    'identification_number': 68904401,
    'item_consumption_1': 23135,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8609.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4a30f80-2b54-4744-883b-ae36a1e72814',
    'identification_number': 97353590,
    'item_consumption_1': 37133,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13818.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2fc887e-eb42-4f4e-af9c-b478ad728010',
    'identification_number': 45268835,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '481383e9-eb29-4d9e-b992-2329798c869c',
    'identification_number': 106835122,
    'item_consumption_1': 4624,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1806.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc2120ab-18b0-4da8-bce7-8fb019fd83cd',
    'identification_number': 108552764,
    'item_consumption_1': 3486,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1363.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3183b540-c5f2-4e41-8f0b-46483b574aad',
    'identification_number': 103124098,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8bb9363c-fe07-4946-8c90-4f917f95cde0',
    'identification_number': 48827657,
    'item_consumption_1': "'-3.344",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 04/2021',
    'item_total_value_1': "'-1160.65",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'bc22f0b4-f034-429d-a0ef-2ab2cfa6873d',
    'identification_number': 97368415,
    'item_consumption_1': 210,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 89.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e206c1f-3fca-4cb8-b32a-75dd2f845e51',
    'identification_number': 99780720,
    'item_consumption_1': 7076,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2762.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdf30caf-702a-46b1-9814-6a69c1aad126',
    'identification_number': 41490533,
    'item_consumption_1': 23740,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 13865.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6521bc9c-9048-45b2-b549-618e632f0ed5',
    'identification_number': 105619990,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e6e5251d-19e4-49de-9841-bc54baa5bd4e',
    'identification_number': 96254378,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '19df236d-98b2-4406-b5bc-c17dbf2f0473',
    'identification_number': 59510196,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51d4dad2-7fe3-4e77-b3de-c5cdccec7c43',
    'identification_number': 8866163,
    'item_consumption_1': 29325,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10913.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a59b8540-bdb3-4b45-b50d-9a532b86d294',
    'identification_number': 34617426,
    'item_consumption_1': 3156,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4774.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5532ef44-4cfd-4ac1-a652-8e7ff3c3ac0d',
    'identification_number': 96254777,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6368d54c-b792-41c2-a442-7d51c187147f',
    'identification_number': 32076622,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87734a32-b059-49df-9004-d32173f673f6',
    'identification_number': 28562992,
    'item_consumption_1': 8653,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3673.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69d23fe8-efb7-4d0c-9f8b-d98c2ff00f24',
    'identification_number': 5368073,
    'item_consumption_1': 225,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 95.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ada5d271-5e7f-46cf-88bc-e21f243d84ab',
    'identification_number': 65399331,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 115.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '064ba7b3-2752-4f8d-a625-b6699a4826a7',
    'identification_number': 12797707,
    'item_consumption_1': 953,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 121.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd42a5115-9112-43ca-9f71-d3c1a8f18c04',
    'identification_number': 98629859,
    'item_consumption_1': 1060,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 414.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af7f1d59-d4f2-4f43-9e01-7a6aa399083f',
    'identification_number': 13924613,
    'item_consumption_1': 24.98,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 726.56,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b40bb719-b62a-4cfa-9e16-388ac471db7e',
    'identification_number': 18213260,
    'item_consumption_1': 2431,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 949.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ffc2c99-7478-4480-8c38-fb0324509aed',
    'identification_number': 46989153,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa0dcd6e-e998-4cc8-8d61-2fc356fb9dd8',
    'identification_number': 19873727,
    'item_consumption_1': 170,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 66.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e879915c-e0e3-4754-b574-9a3dcc870c0a',
    'identification_number': 36111260,
    'item_consumption_1': 501,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 212.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d467184-67c5-4349-a933-4b183122d2fa',
    'identification_number': 39422470,
    'item_consumption_1': 1964,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2971.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e3b2b71-1faa-4987-b726-8c29c6fadfe3',
    'identification_number': 91360250,
    'item_consumption_1': 4294,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 548.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2236db1f-0090-4433-a7ad-ff6d3a3d576d',
    'identification_number': 15522229,
    'item_consumption_1': 82,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 124.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a390aa38-f853-46d6-a84f-cb2b45492b4e',
    'identification_number': 103765450,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-256.45",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'c60987b5-324b-4eec-9ddd-3609ed9b3c20',
    'identification_number': 42433223,
    'item_consumption_1': 7199,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3056.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ecb7f58-7662-41bb-b083-ccf997b4fa84',
    'identification_number': 106046608,
    'item_consumption_1': 854,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 361.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae8cd0fb-5c34-4e30-b053-856a44a4bdb0',
    'identification_number': 44818815,
    'item_consumption_1': 209156,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 77836.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad69d1a0-8dad-4c4a-8886-bf5507ed3dfe',
    'identification_number': 26777258,
    'item_consumption_1': 1324,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 560.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '578dced6-6d89-4c3c-8667-57fe87078731',
    'identification_number': 42056306,
    'item_consumption_1': 439,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 171.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4309795d-6072-4a39-96f7-3e379619fb8c',
    'identification_number': 8985510,
    'item_consumption_1': 7434,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2900,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ad0d606-e9d7-4a1c-bcc8-4f32c47c7895',
    'identification_number': 15369080,
    'item_consumption_1': 1068,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1615.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be76d877-f052-48e3-b73b-6fa498f59a5c',
    'identification_number': 15915727,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ce92d035-a177-4e7d-ab6b-f91d4b032576',
    'identification_number': 109545907,
    'item_consumption_1': 18986,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7065.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91b10f15-ee4c-45e1-a917-16f402993169',
    'identification_number': 66832330,
    'item_consumption_1': 337,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 509.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f6738a0-535e-4cc1-bc7e-c1dac91ca19b',
    'identification_number': 99540509,
    'item_consumption_1': 151,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 58.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e331f85-aae4-4017-abfb-2cf6c26b52e0',
    'identification_number': 57029911,
    'item_consumption_1': 112.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3271.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '232eaf25-3f22-4e84-8cdb-e956cd08ffe6',
    'identification_number': 102645698,
    'item_consumption_1': 6861,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2553.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1281fb9-0994-4082-994b-c8cfc818aec6',
    'identification_number': 47509910,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61b07c15-b9bb-4ae2-8c33-eb506aaf9a7c',
    'identification_number': 100002676,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a3467aa7-9f79-42a8-919d-359bb5693644',
    'identification_number': 109826582,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '395858f7-7e54-44ea-8376-065a7b94e8fc',
    'identification_number': 85205672,
    'item_consumption_1': 68.78,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3928.01,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7b164ec7-b875-42de-9a30-d00bc8746d49',
    'identification_number': 63964430,
    'item_consumption_1': 121,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 70.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbc6af0c-bf18-4357-b3d6-a89a364fc8e3',
    'identification_number': 92661246,
    'item_consumption_1': 4244,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1657.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf729f74-dcc9-4455-8833-5343f7710dbc',
    'identification_number': 68906161,
    'item_consumption_1': 14.76,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 429.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a00825d3-e159-4c2e-a698-72ed7fda443c',
    'identification_number': 23078758,
    'item_consumption_1': 894,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 522.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c23c7024-ceb4-4bf7-b812-744642f83eba',
    'identification_number': 14642484,
    'item_consumption_1': 253,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 98.99,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9bc19da7-a622-4e22-b98e-a1666353c5bf',
    'identification_number': 107871289,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c79f9510-728d-4f95-b25f-7a0a45b291e1',
    'identification_number': 70941467,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 67.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3687767a-ce9d-4c5e-9bee-8b539bbfc937',
    'identification_number': 36033111,
    'item_consumption_1': 22732,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 34389.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94b9b0f7-2c99-4aa0-9109-73a8bd602fad',
    'identification_number': 19873727,
    'item_consumption_1': 4.42,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 105.44,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '44ce037e-a373-4513-88ae-ff498640fb56',
    'identification_number': 13262599,
    'item_consumption_1': 6520,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2766.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ea92928-e475-45f3-bb2b-037f89e3ad87',
    'identification_number': 43036830,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1466a58a-942f-4c63-b8a8-5722e2a521d6',
    'identification_number': 98631012,
    'item_consumption_1': 3477,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1358.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '832abfdc-e751-4b17-b9fc-3e25e588b801',
    'identification_number': 102950563,
    'item_consumption_1': 1360,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 575.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d3e9609-9614-44df-a8cc-b90d4762642b',
    'identification_number': 16109422,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0d9e554-ea3a-44db-b9b9-46b2d1b0f017',
    'identification_number': 89771249,
    'item_consumption_1': 1666,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 649.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c49394b-246f-4564-b0cc-2283108c9253',
    'identification_number': 104782420,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6860869c-0868-423b-b74c-4d558ec0bee5',
    'identification_number': 103620788,
    'item_consumption_1': 7252,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2824.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22318d7c-9493-4d36-937e-979e42d3f506',
    'identification_number': 103840923,
    'item_consumption_1': 275,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 160.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77580ec6-14ab-455c-a61a-b35cf70bdc6c',
    'identification_number': 65625960,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2a7dc363-f4e0-4346-b0da-cb17753e91a2',
    'identification_number': 108357902,
    'item_consumption_1': 9277,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3931.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98c58d5f-e6cd-4e7e-8b2f-87c4caab8682',
    'identification_number': 28351681,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 71.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '65763f27-9cc5-46e6-bc59-3ff2ffdd3423',
    'identification_number': 18377602,
    'item_consumption_1': 1879,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 734.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51d1dcaf-9a02-4ac3-9e40-2b9b5888493f',
    'identification_number': 935371277,
    'item_consumption_1': 4391,
    'item_description_1': 'Consumo em kWh LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 3827.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea4a6f06-190c-46c8-8c00-de06cf3311cb',
    'identification_number': 935371657,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2b7c1763-03a5-42e0-b6ab-02e2c09241ba',
    'identification_number': 110926005,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6710f2b-9206-411a-81ae-d9226d39c4ad',
    'identification_number': 74865145,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1861.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7787e384-ddb6-4495-8dd0-5b11afa04ed1',
    'identification_number': 104188120,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3319.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9f6dd69-d745-42bc-94a3-01b750427f6b',
    'identification_number': 84777117,
    'item_consumption_1': 1192,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1803.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d43a82b-d5d0-4d50-b311-f67cbb3cbb39',
    'identification_number': 31564496,
    'item_consumption_1': 3390,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5128.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4611117d-f4de-45cf-be61-69ac81c06ae1',
    'identification_number': 11430990,
    'item_consumption_1': 2635,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1117.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02aeb05e-e44c-4829-9e52-4546dcc9b332',
    'identification_number': 93231903,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49f4a20b-0453-40c1-a623-2ab431f46dd0',
    'identification_number': 110226402,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '697e2807-694e-44f9-8bcf-9329ec5b5d6d',
    'identification_number': 6270760,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '48a82f76-5260-4034-af64-757490f58154',
    'identification_number': 18328504,
    'item_consumption_1': 173,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 73.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a325cf19-e2e0-4e6c-9e3e-6decadaccf7f',
    'identification_number': 32715811,
    'item_consumption_1': 20.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 595.37,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2e63c8dd-0e99-4552-bcb3-4161a68297e9',
    'identification_number': 9757481,
    'item_consumption_1': 12057,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5118.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06502201-f6e7-4f5c-9332-98dbb3ccede3',
    'identification_number': 4003474899,
    'item_consumption_1': 61.1872,
    'item_description_1': 'Demanda [kW] TUSD NOV/23',
    'item_total_value_1': 1624.05,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9101369c-26af-4a99-96ba-3537fc29d382',
    'identification_number': 554243,
    'item_consumption_1': 3584,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 1943.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae5bccc0-8233-4d90-b05d-4fcf07037357',
    'identification_number': 79211747,
    'item_consumption_1': 182,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 77.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b87cb7e-92da-441d-b7ac-b4e0600af17b',
    'identification_number': 17708206,
    'item_consumption_1': 2460,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1045.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2357d40-f96d-4de6-9f8c-0492d3c29879',
    'identification_number': 9788131,
    'item_consumption_1': 3613,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2110.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14b9b3e9-1df4-4b9a-ba2e-74ee57e656c8',
    'identification_number': 105816280,
    'item_consumption_1': 67983,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8677.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '851276cb-2e6a-4384-b2b5-ce35b6b48429',
    'identification_number': 93308965,
    'item_consumption_1': 7090,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2638.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac77d39e-3a79-49dc-82d0-0da6cadef861',
    'identification_number': 40994724,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ba8b9a14-26eb-464c-af77-450af1314466',
    'identification_number': 55454500,
    'item_consumption_1': 235,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6835.19,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '09981f51-5ee6-4e9a-8b8e-79b6a009e1d8',
    'identification_number': 42223750,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5518dcb2-d6b6-450d-b56b-eda8befefb42',
    'identification_number': 41381823,
    'item_consumption_1': 9594,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 14513.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e966ef7-9a73-4862-818d-0372e0b78aff',
    'identification_number': 72858710,
    'item_consumption_1': 1388,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2099.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a36b0716-f0d9-41a9-b473-621568e99f43',
    'identification_number': 100922961,
    'item_consumption_1': 3419,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1333.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48d76f7f-a460-4765-9756-ed0ea066cd6e',
    'identification_number': 70081760,
    'item_consumption_1': 7318,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3106.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68b307df-027c-451f-b2e5-165e4333d234',
    'identification_number': 32495056,
    'item_consumption_1': 4.3,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 102.57,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'cb5182ad-b8d2-437f-badd-984ab14b7959',
    'identification_number': 59541288,
    'item_consumption_1': 9.54,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 544.81,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4fceea3b-f712-47da-9f6d-7dea4128a737',
    'identification_number': 98942468,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1669f977-58e6-4f41-a256-2846c74dabe6',
    'identification_number': 67439314,
    'item_consumption_1': 89,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 34.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c8f170d-ded6-4764-88d4-6a58f66e7451',
    'identification_number': 97261297,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c277990-a52f-4e3a-b868-12533fc78fa2',
    'identification_number': 43597564,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 35.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '177b4377-d627-4603-aa2f-08c089123a75',
    'identification_number': 101762038,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-10.19",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '8c27d0b3-99ff-4102-8a72-ec621865caea',
    'identification_number': 106732358,
    'item_consumption_1': 340,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 144.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24468cb1-9888-4956-9df8-e4dbf8fecd3e',
    'identification_number': 109530608,
    'item_consumption_1': 63.61,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1850.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6c1cbb69-8d45-4050-99d1-bd5886d79b15',
    'identification_number': 59355573,
    'item_consumption_1': 3262,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 416.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17db0598-41ef-4b14-b8f0-2a7e72c29862',
    'identification_number': 110527100,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bb72340-5675-4f05-8b76-696877916684',
    'identification_number': 37466232,
    'item_consumption_1': 7369,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2870.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba0e3815-64d2-43e6-8b1d-d113e5a0aa5e',
    'identification_number': 17159660,
    'item_consumption_1': 46.19,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1343.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7d7fc660-738a-4591-a5c1-b5dbe850f74a',
    'identification_number': 101354827,
    'item_consumption_1': 86,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 33.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4dd1a09f-f805-40f8-a3fc-e47cf47d41a5',
    'identification_number': 23376414,
    'item_consumption_1': 5998,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2542.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01e71db0-56fe-4625-ab32-9260e31c1274',
    'identification_number': 111534518,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 112.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aeb32ff9-7153-41eb-8a6d-2d90fcc323cc',
    'identification_number': 27132226,
    'item_consumption_1': 1412,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 599.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a959a882-5ccb-4485-ad60-69c1ebd7a1e0',
    'identification_number': 19282087,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 60.67,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5644316b-2e91-43d7-a8dc-9529d8c8588a',
    'identification_number': 15871282,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddaa0a10-dd0f-4799-8f1d-ed97d85c08a4',
    'identification_number': 105957577,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f8685b3-65cc-46a8-9ec8-c6844738e439',
    'identification_number': 59485230,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c10b3d9-5296-45fb-9658-759dd87104a3',
    'identification_number': 81809883,
    'item_consumption_1': 899,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 381.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc04944f-3309-4b82-8ab1-6d336252e729',
    'identification_number': 19452632,
    'item_consumption_1': 1004.4,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 29213.93,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '787063e5-ad03-4f90-9708-03886cc5469e',
    'identification_number': 31564496,
    'item_consumption_1': 13713,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5103.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2c5e6c7-03f0-4e8c-8bf2-42519811557c',
    'identification_number': 15572862,
    'item_consumption_1': 54.8,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3129.63,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '199f48f0-91d8-45f2-a1b0-94b1067cd8f0',
    'identification_number': 19395370,
    'item_consumption_1': 548,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 213.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98392b6e-77f2-4d8e-a58e-dfc1093a88b7',
    'identification_number': 14671549,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-426.34",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '8dfab9aa-fc6d-4421-bce9-ea36639ba44a',
    'identification_number': 107330288,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7137be93-d79b-4fb9-b43d-091576c21020',
    'identification_number': 72063564,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2923263e-305e-4834-be7f-d558168d6c20',
    'identification_number': 5330351,
    'item_consumption_1': 175.57,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 5106.62,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd16bdf48-ead0-4308-9aa3-9a2ac5d42ac9',
    'identification_number': 19309490,
    'item_consumption_1': 9385,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3984.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '218994fd-c88c-4204-921e-891efe7a27f4',
    'identification_number': 5361494,
    'item_consumption_1': 3043,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1291.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28bf8d8a-444c-4700-b189-c922621b2422',
    'identification_number': 82744181,
    'item_consumption_1': 1.32,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 31.49,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '92d5a864-d7d1-4c23-b6d2-2b935c5d6df7',
    'identification_number': 3030777242,
    'item_consumption_1': 3.8208,
    'item_description_1': 'Demanda [kW] TUSD NOV/23',
    'item_total_value_1': 101.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f56c1865-0c3a-4ea0-8cfc-d77062012f74',
    'identification_number': 103034838,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b941f71-1375-4b6d-9be0-b8da61b62ebc',
    'identification_number': 109675185,
    'item_consumption_1': 77.49,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1848.2,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4c715c2d-9d5e-4858-a3e2-a5d0c8104069',
    'identification_number': 22963677,
    'item_consumption_1': 1656,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 48166.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8332870b-83e1-4799-a289-bf2e3aab1629',
    'identification_number': 948743546,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a79d6b72-bf7c-4f1e-8b6c-67e6f8d23aae',
    'identification_number': 55523331,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55bb0ee3-04f9-436a-8f09-fa7d0b93ada9',
    'identification_number': 21718989,
    'item_consumption_1': 2926,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1088.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41097eb8-b424-44e4-bf0b-e529ec1a2b85',
    'identification_number': 99676354,
    'item_consumption_1': 3092,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1313.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d24904e-2411-43fa-9f9d-1de970e77b7f',
    'identification_number': 45580804,
    'item_consumption_1': 1012,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 430.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e08ccdfb-7f6d-4bc3-bf83-50dd55c1733d',
    'identification_number': 65485416,
    'item_consumption_1': 25.83,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 751.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f9b0c13c-9611-4218-9478-88d61cefe734',
    'identification_number': 94843813,
    'item_consumption_1': 1988,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 843.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10820008-69ca-4564-9973-bea56fbb1c8b',
    'identification_number': 16117450,
    'item_consumption_1': 9122,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3556.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'badb4ed9-849e-466e-93a5-d87f871b524b',
    'identification_number': 77890043,
    'item_consumption_1': 1.68,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 40.08,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5d5982ec-4ce4-4e3c-a5b9-d7d596332037',
    'identification_number': 28671139,
    'item_consumption_1': 13.18,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 314.37,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c360a4bd-2efb-4956-bf5f-d832bf850e6b',
    'identification_number': 26480344,
    'item_consumption_1': 13776,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5374.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3672a1f4-49a5-4958-a8d6-6f2384e2b3b8',
    'identification_number': 96849207,
    'item_consumption_1': 750,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 293.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '578c31bb-28ca-494b-bfdb-f96c6b5b2e91',
    'identification_number': 73887692,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '78f7211c-2248-4769-810f-2cea0d7ca8ae',
    'identification_number': 46424709,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ba1b75de-2386-4831-bd5c-9226d0b80940',
    'identification_number': 19452667,
    'item_consumption_1': 73101,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 9330.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '154ff8d9-4e02-4c86-a55f-a1ed3b4bb7dc',
    'identification_number': 15685420,
    'item_consumption_1': 3.98,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 94.94,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd72aadbf-8b26-413b-af95-6c6bdd76ed6c',
    'identification_number': 100984070,
    'item_consumption_1': 266,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 104.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53c45f76-07ea-408c-bfb5-6e7d76c685b3',
    'identification_number': 56526580,
    'item_consumption_1': 633,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 369.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '571d83f3-fd89-4ccc-bd7a-26e22b710124',
    'identification_number': 19394829,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.99,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a077438b-6b1f-4762-9012-71321929673d',
    'identification_number': 111113792,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f077289-253f-4b36-a5ff-c7e4fa5c6c83',
    'identification_number': 102483175,
    'item_consumption_1': 912,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 387.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad91f456-3997-4834-98b1-eec071d03c42',
    'identification_number': 107941392,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ddb80f1e-4e49-4b3e-860a-cfcc095c4691',
    'identification_number': 31139388,
    'item_consumption_1': 732,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 286,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31b6075b-a840-46f1-9ccb-d6707759084b',
    'identification_number': 93811870,
    'item_consumption_1': 811,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 316.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a62ea3c-f62b-4c67-b94e-2c4ba21bcf86',
    'identification_number': 101729456,
    'item_consumption_1': 1200,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 468.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bb1ae7e-3d45-4fc1-94b3-96421a773d8b',
    'identification_number': 40855708,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6fe8f07a-83f7-4d87-b989-32ced0dc00c8',
    'identification_number': 14671638,
    'item_consumption_1': 489,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 739.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f8c0bb5-0119-457f-9192-17dd629c873e',
    'identification_number': 53031423,
    'item_consumption_1': 1427,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 556.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a970038-c758-4a25-9969-c6219aeeed6f',
    'identification_number': 97941778,
    'item_consumption_1': 5828,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2276.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7296ab9f-d734-41d4-be15-be8b173f3978',
    'identification_number': 6092578,
    'item_consumption_1': 25311,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 10731.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5120c0f8-3177-4ea9-8b72-399cb1a9b65c',
    'identification_number': 65191404,
    'item_consumption_1': 22.06,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 641.62,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b7a4e24c-4aa1-49e4-a211-ea1b0dd2452a',
    'identification_number': 96596120,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-19.81",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'dc7be1c2-7177-45c3-9890-c31225bd3d3c',
    'identification_number': 20587155,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 144412.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1efa42f-5cd2-4e8c-8620-a4ba8ae24701',
    'identification_number': 109487877,
    'item_consumption_1': 28,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 10.94,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd2ac1de1-f379-43e3-b41c-afca6a6a0a21',
    'identification_number': 75192900,
    'item_consumption_1': 1353,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2046.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf11bd47-234e-4a7a-bdd3-5d252fb0bc53',
    'identification_number': 17619343,
    'item_consumption_1': 2266,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 875.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed30bd21-ed4c-42cc-81ab-a6a58e866d37',
    'identification_number': 75565730,
    'item_consumption_1': 2534,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3833.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5e22260-b7c7-42c4-89c8-f76997febc74',
    'identification_number': 101912331,
    'item_consumption_1': 7379,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2746.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efb7d327-8d69-40d3-80d5-6248ff3ed579',
    'identification_number': 108646360,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '125eb387-970f-414f-a62e-8c1dc06bb26d',
    'identification_number': 99060434,
    'item_consumption_1': 3944,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1669.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3e5bdf8-b52b-4e25-91e9-4643c81dba96',
    'identification_number': 92056920,
    'item_consumption_1': 3099,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 395.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10afd184-6f50-48da-990f-158dc8a55423',
    'identification_number': 15741036,
    'item_consumption_1': 317,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 123.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50afe42d-bf50-424b-a9a7-5aa27365a50a',
    'identification_number': 42699312,
    'item_consumption_1': 2146,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 909.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34666fe7-32c5-46dc-8e85-809894295a36',
    'identification_number': 109861035,
    'item_consumption_1': 13122,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1674.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fe7f00b-593c-4f3e-ab3f-484fe61d162c',
    'identification_number': 107941589,
    'item_consumption_1': 41750,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15537.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0f29527-6ac3-4d5a-8932-417483e9d012',
    'identification_number': 11222565,
    'item_consumption_1': 3065,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1298.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57b51cdd-bacf-4f5f-b90c-524bba8b7e01',
    'identification_number': 63964430,
    'item_consumption_1': 8.68,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 207.04,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fedc1f4f-7888-4edd-ad8c-0470146d5d96',
    'identification_number': 82989958,
    'item_consumption_1': 940,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 399.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd952e6d6-c585-4739-9b60-b41c038e187d',
    'identification_number': 91075700,
    'item_consumption_1': 43807,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5591.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e177ce8-6596-4725-a2b7-230cc19df416',
    'identification_number': 75063077,
    'item_consumption_1': 19.6,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 467.48,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '11d8ea5d-0b18-41aa-8085-b90a16872d0a',
    'identification_number': 43505457,
    'item_consumption_1': 2746,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1166.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5b12e27-d640-49ab-b974-80aae5853025',
    'identification_number': 99713349,
    'item_consumption_1': 41493,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 62771.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1db58754-f44e-482e-86fa-2da25d0534c6',
    'identification_number': 91627176,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35e37396-a2fc-4b2c-9b7b-6ebca75e644a',
    'identification_number': 11100800,
    'item_consumption_1': 2050,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 869.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '656d7134-cc16-4175-b7d0-9b6c7ea2b97b',
    'identification_number': 53839242,
    'item_consumption_1': 134,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 52.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e41c89fb-b7e2-4c2b-935c-c7235205d373',
    'identification_number': 101937792,
    'item_consumption_1': 3.25,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 77.53,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd3e58bf2-7231-4f57-9b6e-418b6879e81c',
    'identification_number': 21851336,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2183c9e-1165-4233-af17-6b092a93c476',
    'identification_number': 97614858,
    'item_consumption_1': 24.64,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 716.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1705ac17-0ed5-4e98-8fcb-ba3e95b9373a',
    'identification_number': 99850559,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5d842b7e-0388-43d3-bda7-84dca42cdcea',
    'identification_number': 64167364,
    'item_consumption_1': 32938,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4204.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b954fc7c-981b-4721-b58d-38aaaafe41af',
    'identification_number': 42694310,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cc9748ff-5e94-4d05-b60c-1671956a3c98',
    'identification_number': 66636973,
    'item_consumption_1': 320,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 124.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5a1f387-b2ab-4d17-9e95-88446beb4ced',
    'identification_number': 21252050,
    'item_consumption_1': 3191,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1354.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e862dfa-891b-4ff9-8092-457f96ced83d',
    'identification_number': 36033111,
    'item_consumption_1': 8.81,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 210.14,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0e05632e-b872-4053-b163-b330b8dd2839',
    'identification_number': 25472267,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd5807b4d-5967-4904-ad05-819836684be8',
    'identification_number': 110755170,
    'item_consumption_1': 2203,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 860.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf1f70aa-1aac-4270-a7ed-3758a791faea',
    'identification_number': 923333479,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'db1b627f-45d8-403f-90a9-9abac41aa9f1',
    'identification_number': 49891294,
    'item_consumption_1': 483,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 282.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22a9094a-dea3-44f1-a5b9-8005310de183',
    'identification_number': 96338369,
    'item_consumption_1': 5905,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2503.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72227185-58a2-4849-bf83-68f991447b03',
    'identification_number': 109948289,
    'item_consumption_1': 586,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 248.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f59583c9-e479-4c03-9ee0-9ea5e17ad1c3',
    'identification_number': 75896974,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '25cf095c-3705-4168-a0d4-79e9513d2e77',
    'identification_number': 19451601,
    'item_consumption_1': 32829,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4190.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b5b8991-bd81-4b78-a602-1279b8a4e01d',
    'identification_number': 69572968,
    'item_consumption_1': 723,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 422.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f103d015-58dc-4eb1-afd6-e9040bfd29f4',
    'identification_number': 76510883,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '817ceb87-f1b3-4dd9-920e-60dd915a9e64',
    'identification_number': 40504077,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '49700830-c74c-49bd-b5ad-4290c08c393c',
    'identification_number': 56874081,
    'item_consumption_1': 2087,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1218.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64df3801-da27-47e1-a7a0-0e170386b78a',
    'identification_number': 48681261,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 0.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57351846-b37c-4a6e-a8c0-177a8d4e5d56',
    'identification_number': 53226429,
    'item_consumption_1': 2.87,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 68.47,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '59300ce1-cde7-42bc-bd38-660cc0996860',
    'identification_number': 97856681,
    'item_consumption_1': 2465,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1439.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fb11783-0edd-4c34-bbee-2223df159258',
    'identification_number': 102645698,
    'item_consumption_1': 28.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 818.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dc8bedc3-9f0d-45e6-a1e4-1ce75a3f3d55',
    'identification_number': 16649427,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd1ff0a0d-5370-4f31-9a95-6c7e88c16266',
    'identification_number': 92002927,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '847ecf2c-f23b-4ae0-ac05-40458b6a4a75',
    'identification_number': 40848302,
    'item_consumption_1': 4992,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2116.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0920350-50a5-4be0-9125-c019cd7c3de2',
    'identification_number': 17665868,
    'item_consumption_1': 3025,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1281.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50276faa-34d7-4ba3-9d51-a7795b86f5b8',
    'identification_number': 15522229,
    'item_consumption_1': 11464,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1463.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae765b52-e274-4c32-ac2c-8c98945ffee1',
    'identification_number': 42002591,
    'item_consumption_1': 437,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 170.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d20f048-0a41-42c3-ab9e-b9013f7c9d98',
    'identification_number': 77818792,
    'item_consumption_1': 341,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 132.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7456619a-c388-4b87-b2a3-8b4e7087afdc',
    'identification_number': 17026695,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85a79be0-876a-4282-a233-280e339a3e06',
    'identification_number': 98942468,
    'item_consumption_1': 1383,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2092.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15cf7094-e549-4a7a-b809-5650bab44e09',
    'identification_number': 40490688,
    'item_consumption_1': 18111,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6739.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d50d4ee-8f9e-4911-86c8-6878722979f0',
    'identification_number': 51200511,
    'item_consumption_1': 3084,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1202.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d638046-4ee4-4b87-92f2-48dfddb1a59a',
    'identification_number': 16612183,
    'item_consumption_1': 2241,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 874.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4603eccb-3a39-45e1-88c3-2f2f314343d4',
    'identification_number': 70503974,
    'item_consumption_1': 11.04,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 263.31,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e0c75acc-58fc-4344-8334-6df4c448a86b',
    'identification_number': 64716929,
    'item_consumption_1': 12643,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 7383.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a0cda63-9307-4dde-a802-83de4bfcdf76',
    'identification_number': 13935739,
    'item_consumption_1': 20.27,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 483.46,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ed202b6d-e678-419b-8115-edb1fa9862a3',
    'identification_number': 101805829,
    'item_consumption_1': 1671,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 708.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '921bc8f3-4b75-4d67-9a63-fb6b77de3aee',
    'identification_number': 39964973,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7f0af5f4-66c7-4e27-8b92-95de42ede16f',
    'identification_number': 19559658,
    'item_consumption_1': 1821,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 772.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5bad80b-c154-40ab-a71e-e0141d34f82c',
    'identification_number': 105953148,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '910e6b48-e7bf-42f8-85c8-f55a0f3f66de',
    'identification_number': 44805616,
    'item_consumption_1': 140.41,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4083.93,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c321b784-10f1-4931-9f7d-449fa4ab26fc',
    'identification_number': 98270257,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a1f69393-f06e-4e9e-a6b1-16fc331845cb',
    'identification_number': 936185122,
    'item_consumption_1': 500,
    'item_description_1': 'Consumo em kWh',
    'item_total_value_1': 435.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49abd2e4-d709-4448-a67b-8bd66de67254',
    'identification_number': 32234058,
    'item_consumption_1': 3583,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2092.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e08269d-a87f-4a5b-9935-40045c8358a4',
    'identification_number': 67097898,
    'item_consumption_1': 1083,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1638.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fbcdc7d-892b-4b34-8988-ddf2b2cfa8b3',
    'identification_number': 16307828,
    'item_consumption_1': 18507,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2362.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41748bb7-eb01-4c0e-8a8b-e185114a2572',
    'identification_number': 59044659,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9f415c45-aca2-4b06-a70c-ada0e9b66de1',
    'identification_number': 18052126,
    'item_consumption_1': 3656,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1547.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76db174e-8ada-438a-adf7-1d85e3bff24d',
    'identification_number': 93742428,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b9a07e71-6f33-4751-854b-b717c3a5aaff',
    'identification_number': 16386760,
    'item_consumption_1': 3108,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1320.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a7ec55d-a1ed-4c3b-8868-97e4a90755df',
    'identification_number': 18643582,
    'item_consumption_1': 1255,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 732.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2eebacf-3665-4c65-93ca-dac94f75df21',
    'identification_number': 110457927,
    'item_consumption_1': 325,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 126.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c22c15ff-dbf7-49f4-9afb-d5a83112efdf',
    'identification_number': 80642322,
    'item_consumption_1': 7257,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2827.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '284e3852-e193-40f7-9eb4-ad972da0c232',
    'identification_number': 34853642,
    'item_consumption_1': 165,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 70.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21095839-8f27-444d-9768-9628f8e424f3',
    'identification_number': 95225641,
    'item_consumption_1': 501,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 195.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f29c8892-3bb9-42d4-b058-0fd2e0a249ea',
    'identification_number': 12973092,
    'item_consumption_1': 343,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 145.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2de2630-c935-4312-a974-61c95f3ef868',
    'identification_number': 26010658,
    'item_consumption_1': 1428,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 557.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '781fc8cc-af2d-4485-9bbd-be21db0f8fb1',
    'identification_number': 19280270,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 95.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b5dc51ea-6076-40cb-ae38-4a4d5175b54c',
    'identification_number': 103102434,
    'item_consumption_1': 147,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 57.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d954b66-9652-45d1-985c-183e7c2e76b5',
    'identification_number': 105251542,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8c4d0cc0-6345-4edb-a2a0-cf160a1329bf',
    'identification_number': 37969064,
    'item_consumption_1': 8192,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3048.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0579db28-e0bc-4ad8-a8df-974c07eaa4ae',
    'identification_number': 19453779,
    'item_consumption_1': 522486,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 194441.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e31f8b42-df02-441a-9462-23c72cac144a',
    'identification_number': 109697693,
    'item_consumption_1': 19810,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7372.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1d4b5f0-40cd-4b47-b39a-988d35360f43',
    'identification_number': 93288255,
    'item_consumption_1': 12709,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4971.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60e2018a-ec36-4979-b9ed-5fc2849342a9',
    'identification_number': 46913190,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1adc51d1-cef1-45b3-81c4-e5501cea8dbf',
    'identification_number': 21374792,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 1090.74,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '86a3dbec-90b0-4bb1-9ec3-d985f6e6ff23',
    'identification_number': 65625706,
    'item_consumption_1': 1658,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 648.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d057833-e724-4918-aa51-791c6e43ab7a',
    'identification_number': 70064164,
    'item_consumption_1': 3382,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1437.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55cc2bfc-a071-4407-b6e5-491f35c341ac',
    'identification_number': 109495470,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 30.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9639ac2-c191-4376-9444-1e28c71ff6a5',
    'identification_number': 15731707,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1c816591-3403-4d57-9c45-760ad70dcccc',
    'identification_number': 106805037,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '577b6d9d-2d26-4555-b83f-9cce0e2c9576',
    'identification_number': 4334221,
    'item_consumption_1': 414.72,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 12062.52,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f127ed5c-caa4-45a1-8a73-cce5a67ce7a9',
    'identification_number': 32074123,
    'item_consumption_1': 4595,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6951.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8fb401a-8b96-44bd-aabc-c8b2a691b4dc',
    'identification_number': 81766408,
    'item_consumption_1': 1929,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 818.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a803b71-977c-4a74-bd47-db026878401c',
    'identification_number': 92096115,
    'item_consumption_1': 7336,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4284.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f2bf6ff-6d82-4ad1-9d50-365bec3b943a',
    'identification_number': 100841937,
    'item_consumption_1': 72,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 30.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '139eb370-38ca-4e0f-882f-9ba47ea43428',
    'identification_number': 84969466,
    'item_consumption_1': 6672,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2482.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '081d02dd-6aa9-4fd9-af39-77c1485a7484',
    'identification_number': 31900852,
    'item_consumption_1': 6.57,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 382.18,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '14102043-3b87-4dfc-a928-33874842ef19',
    'identification_number': 109495470,
    'item_consumption_1': 15.75,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 375.65,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5465e366-640e-4c0c-9c4a-d9971a7aa608',
    'identification_number': 38893614,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 156.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5577386d-1006-4b24-a52a-b913f77b2bbf',
    'identification_number': 17740738,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5966028d-3a30-479c-ac48-9f8db3dc1b3b',
    'identification_number': 96197552,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf6eccbb-9d82-443a-972c-67e6c86c0bb0',
    'identification_number': 19451601,
    'item_consumption_1': 19.52,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 914.15,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '44b67e23-2d99-47c3-b195-6a86fb460201',
    'identification_number': 91274940,
    'item_consumption_1': 6793,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2648.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a78190a4-3c69-46f1-9edf-a4fb6a473570',
    'identification_number': 7374933,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e05ca269-b458-4b8b-b8ef-058148110a43',
    'identification_number': 14448726,
    'item_consumption_1': 164,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 69.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1347336-f7e3-4532-a5ca-0133244fa37c',
    'identification_number': 70755507,
    'item_consumption_1': 12341,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5244.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c52f6066-611e-444d-a5f3-28f9d16c0a66',
    'identification_number': 111834228,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30ccaf7c-c39f-4927-b942-214b0bf967a3',
    'identification_number': 80268250,
    'item_consumption_1': 1010,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 427.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b987fea-73a7-4bee-acd5-5424c8021a6a',
    'identification_number': 19306059,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f13e475-39c5-428f-81ce-998cec422668',
    'identification_number': 104188120,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1138.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0398cde-4d66-4db8-9df6-398254df1435',
    'identification_number': 97174831,
    'item_consumption_1': 264,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 112.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0fe0dc9-3df9-4800-9a10-7885933c98e7',
    'identification_number': 32074123,
    'item_consumption_1': 131.32,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3819.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8cfcd1df-cf31-4fc5-830a-dafe87581850',
    'identification_number': 92167942,
    'item_consumption_1': 16901,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7175.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44d788dd-0064-4314-8b65-d13c4981c141',
    'identification_number': 68571186,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87921529-9cef-45c5-90b6-0866aa19ebb0',
    'identification_number': 110527062,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c7b1e96-f303-40cd-b6f4-35eefe7341ff',
    'identification_number': 91809908,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 1763.81,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '3b39d1a9-b4c4-4be6-8d07-5724092c334b',
    'identification_number': 108309835,
    'item_consumption_1': 2294,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3470.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0f1d487-4688-4ed1-b43a-00781864340a',
    'identification_number': 59455896,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78b57d19-7032-4127-9536-201ed55786ea',
    'identification_number': 59361859,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9f5cca96-e75b-4d92-b1e7-b47590fca99e',
    'identification_number': 69901910,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fb7a2862-743f-4eb3-9ab3-9e668bd0bbdf',
    'identification_number': 94984859,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd92ffd0-69a0-4f02-9ef6-c598aab9e0e0',
    'identification_number': 100456391,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97c86aaf-3970-4a8e-89f7-33437de65ac4',
    'identification_number': 91858194,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3c7825b9-79a7-4f2c-a887-abb229cfd13a',
    'identification_number': 40705188,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1369b0f1-d27b-4ae1-b744-f48547501cb3',
    'identification_number': 110457927,
    'item_consumption_1': 325,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 137.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85ef32ab-37d6-4b2b-9b5f-0c3c650bc900',
    'identification_number': 16612183,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 86.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5795708f-c041-4fb2-8a56-289925e498e4',
    'identification_number': 82139776,
    'item_consumption_1': 3087,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1207.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4e24276-9ffe-4669-b328-cd73a97e9b87',
    'identification_number': 34993550,
    'item_consumption_1': 1315,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 558.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2789fbb-2486-4f2f-af68-0bab2773a4d5',
    'identification_number': 97791091,
    'item_consumption_1': 181,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 76.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6399935-faad-4695-bd43-f7432aee39c2',
    'identification_number': 51527987,
    'item_consumption_1': 154,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 89.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5af0eaf-9bc8-4d8a-b6d6-5c59a4d69cf1',
    'identification_number': 16744764,
    'item_consumption_1': 450,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 190.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63ad8d48-ebf1-47ec-95a5-13233318554a',
    'identification_number': 38635518,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd95cabff-01c0-43df-ba9d-86ff31e2672b',
    'identification_number': 56527136,
    'item_consumption_1': 7844,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2919.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '128a5b63-6723-49ce-8bd3-730f947bfe49',
    'identification_number': 17159660,
    'item_consumption_1': 738,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 288.74,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '739fd43e-738d-4a02-a435-6610d21ba08f',
    'identification_number': 97616931,
    'item_consumption_1': 6861,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2684.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e956d38-3870-4d03-b476-3cc3456aec09',
    'identification_number': 77169689,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '56032e56-3e29-488d-8f34-423d51885091',
    'identification_number': 96072091,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c51c3c05-408f-4cb0-b0cf-9b9e3465fcfc',
    'identification_number': 38718502,
    'item_consumption_1': 1444,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2184.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9495eda-64cb-481e-8ed0-61a7052fb774',
    'identification_number': 14689146,
    'item_consumption_1': 2234,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1304.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '378205dc-3a75-4d30-8e7b-05f1096c67c5',
    'identification_number': 32747217,
    'item_consumption_1': 13,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 7.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca2fa505-57e6-4582-a42d-b47a84fddc70',
    'identification_number': 71798595,
    'item_consumption_1': 99176,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 12658.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a648c89-c560-415c-a701-7205c944f981',
    'identification_number': 108902919,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO (02)',
    'item_total_value_1': 38.28,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b53aaf8f-e21b-4bc0-ba6a-d98ff48a1f40',
    'identification_number': 40232182,
    'item_consumption_1': 2078,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 883.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b646edc-9aa6-44fd-a733-fd68e0880187',
    'identification_number': 105662470,
    'item_consumption_1': 429,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 181.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '611b48c8-08df-4269-8b22-be3982c512f6',
    'identification_number': 76002241,
    'item_consumption_1': 2947,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1149.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4f57452-5750-495f-be27-6a616a62d41c',
    'identification_number': 26806886,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8435e4fa-3d09-40d4-9cd0-d5faae7208ad',
    'identification_number': 66168090,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9159da83-7ed1-4728-9cb2-7d3d391b83f6',
    'identification_number': 42162653,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7ca1f1d9-f3bb-4e31-a6c7-de3dcd881b3d',
    'identification_number': 103365087,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36a66f86-38dd-43f0-8dfc-a1e65b01924c',
    'identification_number': 14642484,
    'item_consumption_1': 9434,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3510.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b919483-b90a-4a13-9392-b9e696e07d59',
    'identification_number': 3078469,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '704da058-652e-4e8c-8fb2-3dd6520718ae',
    'identification_number': 80671551,
    'item_consumption_1': 12439,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5272.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0282b00f-84cd-4ba5-9ba3-275ef25d840b',
    'identification_number': 29705932,
    'item_consumption_1': 13699,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1748.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12b19d69-aefa-47c9-8eed-5f344372f274',
    'identification_number': 51632381,
    'item_consumption_1': 22754,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8467.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f2a4efb-7e0d-4a3f-abfc-2f6b6de08489',
    'identification_number': 45838313,
    'item_consumption_1': 12864,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 1641.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0093f222-0693-48be-8c99-491e5a8f2c72',
    'identification_number': 38503328,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '707f211c-61bc-4a9a-9059-7be89825b726',
    'identification_number': 27549607,
    'item_consumption_1': 1252,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 530.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56b03580-7d98-4186-95c5-25540531cef8',
    'identification_number': 17725755,
    'item_consumption_1': 1635,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 639.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '677ffd01-8e3f-413c-8bf0-d0b00db3ceeb',
    'identification_number': 16127064,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '15f52044-ccb6-493c-9478-8d32594dece0',
    'identification_number': 53330668,
    'item_consumption_1': 14192,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5281.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b05cf83-91cc-49ce-97e9-d00e8a39006a',
    'identification_number': 28166159,
    'item_consumption_1': 45354,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5788.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0e1d464-5092-495c-8062-b2dcc199f923',
    'identification_number': 44873719,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec5d5460-46dc-46aa-aa35-6042afa4de23',
    'identification_number': 90337310,
    'item_consumption_1': 508,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 215.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15cc1b22-ba26-4f85-adc4-9ab336cafade',
    'identification_number': 13870068,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '69836a34-ae33-4d0b-9a69-4e8027d0b8ed',
    'identification_number': 103317600,
    'item_consumption_1': 9790,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4155.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4ee6792-14c3-4fa5-8590-513049fa4285',
    'identification_number': 99971852,
    'item_consumption_1': 1565,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2367.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca90bc8c-be53-4520-84bf-ce4d647733be',
    'identification_number': 3034303075,
    'item_consumption_1': 50,
    'item_description_1': 'Disp Sistema-TE NOV/23',
    'item_total_value_1': 14.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b058bfd7-7a4d-4168-bcfb-4eb826cdf0ff',
    'identification_number': 22343814,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '664d2a5e-d1aa-4db1-b33d-2a2c16b396ab',
    'identification_number': 31792634,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 7.83,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'be465697-5ca8-4ca1-809f-866776d2acde',
    'identification_number': 40709140,
    'item_consumption_1': 14.12,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 336.78,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1666bf02-0207-4cda-859d-8c44a133aa65',
    'identification_number': 45340340,
    'item_consumption_1': 46.39,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1349.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '610c30ae-1b28-45a8-acc0-205903c54c3a',
    'identification_number': 99591537,
    'item_consumption_1': 11261,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4404.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '743b072f-c429-4383-83de-820e2f6ecd4f',
    'identification_number': 40934756,
    'item_consumption_1': 11143,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1422.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e77eefa-b59e-4d2c-9e30-1ffdbf4f6a5f',
    'identification_number': 4334671,
    'item_consumption_1': 2623,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1531.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cdd1538a-2a8d-4de9-a6db-53072586c19a',
    'identification_number': 55826539,
    'item_consumption_1': 420,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 163.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da163fbf-5355-4a59-8752-c3c2a306478c',
    'identification_number': 93037279,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bd0d8cf2-1617-4ab1-9dc2-ef59ebbecd3d',
    'identification_number': 55624146,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7ae7047-9217-4b94-8046-c61333abc47d',
    'identification_number': 90790499,
    'item_consumption_1': 28594,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3649.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2fce8f40-2fb4-4f7f-bc3f-fd2470557022',
    'identification_number': 41422775,
    'item_consumption_1': 1340,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2027.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd20bc875-9ff3-44fb-a4e5-ca26ec4f244d',
    'identification_number': 43875718,
    'item_consumption_1': 224,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 87.63,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7c6b6ce3-2a3a-464b-84dd-2207568c0ffa',
    'identification_number': 27796833,
    'item_consumption_1': 11073,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4313.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d8ca3dd-4613-4a4d-bf02-62067517fd5e',
    'identification_number': 111132290,
    'item_consumption_1': 2126,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 791.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f3c0e27-2b10-4487-b198-55dbe1e573aa',
    'identification_number': 17073030,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 68.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fdb513fd-88e4-4bba-9532-3155acc367ff',
    'identification_number': 20304137,
    'item_consumption_1': 19140,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7122.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c84c38d-3fab-4566-8827-6e66abdaa64c',
    'identification_number': 56875592,
    'item_consumption_1': 14993,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1913.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cd5ee6f-b848-4292-95b7-62ce8058b1b3',
    'identification_number': 13028855,
    'item_consumption_1': 1638,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 956.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aeb4e6d9-57ec-4f78-af22-d77289badeee',
    'identification_number': 39545377,
    'item_consumption_1': 1.8,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 42.93,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3a1f7680-855d-492a-b17c-2ac07d47698e',
    'identification_number': 14693100,
    'item_consumption_1': 359,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 209.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a76a262-e23f-4a60-8476-2b3a547be4da',
    'identification_number': 16166558,
    'item_consumption_1': 2885,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1210.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24b85f26-4392-4b58-b25e-f29df06e2c33',
    'identification_number': 15091520,
    'item_consumption_1': 8481,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3156.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bcbd571-51d2-4e7c-ad93-d4573c5295ab',
    'identification_number': 32151179,
    'item_consumption_1': 99662,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 37088.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb5a02ac-b899-4a3a-aa90-c413a568af42',
    'identification_number': 14138069,
    'item_consumption_1': 18311,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6814.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3db7c19-cb83-453b-8a1c-16aa5deed7ab',
    'identification_number': 109495470,
    'item_consumption_1': 2500,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 319.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f003d621-ff34-49da-aaf7-9726d66ec8c9',
    'identification_number': 56698151,
    'item_consumption_1': 8141,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3029.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd58606f2-63d1-4bbd-921b-57c8b5047cef',
    'identification_number': 70085072,
    'item_consumption_1': 2306,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 980.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd88040e2-d8b2-4487-a927-6708706ce573',
    'identification_number': 31923917,
    'item_consumption_1': 2272,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3437.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b0c2755-de9c-4c7c-b6ae-05ed96e08662',
    'identification_number': 106104438,
    'item_consumption_1': 21.87,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 521.63,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e4aac8a4-db3c-477a-8c3a-7a64f97c5e80',
    'identification_number': 45702276,
    'item_consumption_1': 3897,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1655.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10f84d14-74bc-40b7-a1c4-bca03715d43f',
    'identification_number': 90453450,
    'item_consumption_1': 6220,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 9409.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c420572e-4940-436c-b3c8-a49d58e4e97d',
    'identification_number': 70993734,
    'item_consumption_1': 17865,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7566.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7421aad5-f62b-4199-8a4a-8c527c59c892',
    'identification_number': 72240431,
    'item_consumption_1': 11541,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4905.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd706d95e-cc52-42e2-ba72-b2cbf510668d',
    'identification_number': 101119780,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2f69ff68-3307-43c7-8158-5d74098acca7',
    'identification_number': 109594916,
    'item_consumption_1': 33.7,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 980.18,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd565062d-bf68-4b16-ba81-98db747cc35d',
    'identification_number': 19282281,
    'item_consumption_1': 116,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 49.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2b578ea-94fe-4c46-a53e-877597ff433d',
    'identification_number': 64417093,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddb28e7f-fead-452d-9da1-1a83ff0dbd9a',
    'identification_number': 81777302,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 75.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '690c64e6-f201-4bf2-b1f5-c47e4677fb03',
    'identification_number': 106612662,
    'item_consumption_1': 738,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 288.74,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9f09e2c1-9274-4329-83da-54549fdcfd8a',
    'identification_number': 99753154,
    'item_consumption_1': 29905,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11129.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3e16250-2735-484c-acc0-848547159022',
    'identification_number': 68502982,
    'item_consumption_1': "'-10.951",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 09/2023',
    'item_total_value_1': "'-3499.33",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '7ccc1f1a-d3a5-4987-bb54-c7043da73979',
    'identification_number': 111114195,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c190eb4-5573-4324-80bb-3cca3966c793',
    'identification_number': 101195435,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '19cd11f8-0c37-49fb-bde7-e9543d2641be',
    'identification_number': 111219248,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'fdd20b86-0971-45f4-8964-907dc7dc78d7',
    'identification_number': 47290897,
    'item_consumption_1': 2159,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 915.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bdb2bad1-9a35-49c9-9dbf-1f3d42df334b',
    'identification_number': 104895209,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '895cc785-976b-4c3f-8c00-2294781c1c28',
    'identification_number': 105289086,
    'item_consumption_1': 763,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 297.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62f8e28c-1b09-4d1d-89bd-f32e4ec8ef5a',
    'identification_number': 42710529,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 16.82,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c32eca32-e9c5-478c-96e8-cbcb7edf41ff',
    'identification_number': 50973665,
    'item_consumption_1': 3413,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1445.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf44dd0f-0aa5-4c30-92d6-869d201bad93',
    'identification_number': 41971817,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ef69ddc3-a83b-470c-861a-eb57ee5a31aa',
    'identification_number': 19301340,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0379b9bd-1f93-4b2f-82e4-9e133ddfae11',
    'identification_number': 16002016,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '64804348-ee13-49d3-a728-61c6afea8b72',
    'identification_number': 109785983,
    'item_consumption_1': 14.12,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 410.68,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '35e8aeee-4ecc-454e-832a-d3961aa76f8a',
    'identification_number': 12446645,
    'item_consumption_1': 45673,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5829.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aabe019b-32d0-4843-8f55-4feef1b9b208',
    'identification_number': 19229747,
    'item_consumption_1': 579,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 73.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c047ecb-a6b1-402e-b52a-18b1dca11213',
    'identification_number': 95371184,
    'item_consumption_1': 1015,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 396.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2fa68799-05c9-4c1a-86ce-9a87066deb0f',
    'identification_number': 25868047,
    'item_consumption_1': 1340,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 568.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '482102a4-83c2-4a2f-990d-e74cbdfa51f1',
    'identification_number': 36715808,
    'item_consumption_1': 6330,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2688.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64bd7ef3-4646-46b3-839d-bda9da2f35e3',
    'identification_number': 53330668,
    'item_consumption_1': 21.51,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 625.62,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9e8b249d-4b5a-46d5-b581-332ff6a42253',
    'identification_number': 101340958,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bd59fcd6-c877-48cb-9436-1034a20fc014',
    'identification_number': 95106839,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 112.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c5b00bea-eddf-4699-9e09-3e42d8c2d0a0',
    'identification_number': 45340340,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1e63d8f2-d982-43e6-935f-53c48704bfc8',
    'identification_number': 52599400,
    'item_consumption_1': 2.39,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 57.01,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ef6f0ba0-a8e5-4862-b987-cd1eb0bb5369',
    'identification_number': 27874850,
    'item_consumption_1': 2546,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 996,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c79e04a-4086-485b-90cc-67f462b28d72',
    'identification_number': 62162705,
    'item_consumption_1': 433,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 168.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e27614e3-98be-49c0-8bad-6dadb47e2387',
    'identification_number': 72702141,
    'item_consumption_1': 2112.48,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 61443.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b5ae7e1a-8041-4ad8-b5c2-08c0609d2785',
    'identification_number': 56544545,
    'item_consumption_1': 1600,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 204.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a0a3f50-57c2-4f77-80d5-e9e271383fa3',
    'identification_number': 15214133,
    'item_consumption_1': "'-1.027",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-356.51",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'cba27971-20d8-4a21-905b-479fa9f001d4',
    'identification_number': 40490688,
    'item_consumption_1': 50.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1467.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '06db8085-793f-451e-9c4c-07bedec1d08c',
    'identification_number': 42089808,
    'item_consumption_1': 154.68,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4499.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1787807b-58c3-47e5-8517-85d500ef4ce6',
    'identification_number': 98197622,
    'item_consumption_1': 174,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 73.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51d84ef4-4d04-4f4f-8e50-db9fcf847df7',
    'identification_number': 100186769,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33a3c072-7d46-4db9-9426-7c854e73aadf',
    'identification_number': 111114683,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ac8b96a-7c34-4fa8-8c5d-9ebbfc82e962',
    'identification_number': 53350227,
    'item_consumption_1': 334,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 130.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1d53065-4d64-49f7-a8c9-69b006a7f891',
    'identification_number': 106682199,
    'item_consumption_1': 547,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 231.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc47e4db-f5d4-45eb-92f2-710ea08dc892',
    'identification_number': 1205102,
    'item_consumption_1': 2466,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1440.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ff8dc96-7749-42c5-8720-0b76c54b0f2a',
    'identification_number': 110653726,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39377003-91c1-4ed4-b091-fb7d39c267b4',
    'identification_number': 64716929,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '74e34f62-241a-4969-8a77-8ec10682742b',
    'identification_number': 45446212,
    'item_consumption_1': 453,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 167.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a8174d5-3da3-4385-8aa3-7ce049e114b6',
    'identification_number': 93430442,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1a6ad01e-6e5b-43d9-a6c6-9468493bfa78',
    'identification_number': 84702893,
    'item_consumption_1': 68.92,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2004.59,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e170feed-2cd3-49aa-ab7b-04b5ea4f2f63',
    'identification_number': 57638519,
    'item_consumption_1': 677,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 264.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e8366d0-4bfb-4812-8e50-2beea1f8cd40',
    'identification_number': 108098680,
    'item_consumption_1': 29,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 16.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71020a91-5e0e-47fa-a012-94b1a7eaf422',
    'identification_number': 48875325,
    'item_consumption_1': 1236,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 483.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'aee16fbc-45ee-430b-b89d-0ac6c13b27cc',
    'identification_number': 55653898,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4829d51e-728d-4950-988e-8c49ad3185cb',
    'identification_number': 44826044,
    'item_consumption_1': 1891,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 800.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e67b3cd-ed74-4891-97c9-d22c4132f352',
    'identification_number': 55456812,
    'item_consumption_1': 80607,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 10288.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0913b1ca-2127-4473-a458-82af85f2f22c',
    'identification_number': 26323249,
    'item_consumption_1': 206,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 80.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11741921-769d-4a20-b9fb-24a60181c71e',
    'identification_number': 15048861,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 94.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f4a6d03-667a-47aa-80e6-32b546b86ab7',
    'identification_number': 96644990,
    'item_consumption_1': 261,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 102.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '407a6df4-b5db-452f-a621-81d37798f012',
    'identification_number': 51642654,
    'item_consumption_1': 626,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 265.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c0d9f8c-ab5a-4a53-9ce2-963df04a7e62',
    'identification_number': 20195303,
    'item_consumption_1': 2040,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 865.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7e7d272-3b5e-4572-aa35-7cc7a380eb2f',
    'identification_number': 14859513,
    'item_consumption_1': 70.55,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2052.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '93183137-24bd-4627-b826-006fe6942884',
    'identification_number': 8974748,
    'item_consumption_1': 498,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 194.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ea05495-35a0-4363-a7a3-6191771127ee',
    'identification_number': 44478046,
    'item_consumption_1': 1.13,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 32.85,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'ffc67f53-e88e-41fa-8779-033fcda0ccdf',
    'identification_number': 16105206,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fb575ad0-7acb-418c-bdcb-7a7f3c7c7194',
    'identification_number': 11346221,
    'item_consumption_1': 46,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '681c2644-ca06-4ec9-8698-e723f306a027',
    'identification_number': 19306059,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '06348dca-4670-4d28-a65f-d11e2e857ba5',
    'identification_number': 42469716,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c49cf867-24fd-4d7c-b7b3-35b45ada3605',
    'identification_number': 22905171,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 75.2,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '1809d90d-eb61-4558-ab8e-4e2aa1c07eb5',
    'identification_number': 65141024,
    'item_consumption_1': 172,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 9822.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '01ef3443-fb3e-48aa-912e-ecd642254897',
    'identification_number': 111285739,
    'item_consumption_1': 4333,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1691.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4922e21-6c85-4fc2-9354-20b24544b4c2',
    'identification_number': 71433449,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV. ATUALIZ. MONET. CORR FAT',
    'item_total_value_1': "'-3.88",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '05b4a5d3-0c20-48ab-8eb0-80fc88fab390',
    'identification_number': 42584418,
    'item_consumption_1': 141.69,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4121.18,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fcf801d3-38d7-4124-8237-b38f432e2518',
    'identification_number': 16106806,
    'item_consumption_1': 876,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 665.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e2eb0bb-59a1-44b9-9fee-a98d2cf65583',
    'identification_number': 96382279,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8dd0ed63-fd83-4b7c-946d-85808836c7f0',
    'identification_number': 40701301,
    'item_consumption_1': 5192,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2031.49,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'fc99d26f-2057-49f1-8432-d338007435a6',
    'identification_number': 108646360,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-221.56",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'c9de0891-2f3c-4764-a835-a39a2dfee82c',
    'identification_number': 101940122,
    'item_consumption_1': 733,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1108.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3185d20d-e0e8-467d-8ef9-ddca090acac2',
    'identification_number': 103209328,
    'item_consumption_1': 1848,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 720.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b64dc22b-dc16-48bb-9429-31772fe5322b',
    'identification_number': 3030777544,
    'item_consumption_1': 1497,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 756.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21ed06ac-2af0-4841-b172-dcc469bb1cd7',
    'identification_number': 32486804,
    'item_consumption_1': 56.18,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1634.05,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ee711031-fbf7-4db2-a8d3-db2165eeb67c',
    'identification_number': 44888848,
    'item_consumption_1': 1593,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 620.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6f00b4e-f951-4f56-aeb2-973482952d36',
    'identification_number': 19541627,
    'item_consumption_1': 52553,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 19557.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a5da4a8-d8b2-4bac-8c72-1cdf36af2248',
    'identification_number': 53913493,
    'item_consumption_1': 1055,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 411.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e03a35f9-90b2-4aef-ac7e-01ea1e9e535f',
    'identification_number': 32852878,
    'item_consumption_1': 5614,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2187.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e152775-59cb-4d4d-ad25-7589f97cd481',
    'identification_number': 103152130,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd61a7b54-ed24-4d49-8c7b-d55cb9e8a2b1',
    'identification_number': 106787020,
    'item_consumption_1': 2926,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1140.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53d1cc9a-e5e0-4d38-8af8-03ffe70bc7ab',
    'identification_number': 13028855,
    'item_consumption_1': 14384,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1835.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38901840-8ae4-4859-b74f-d2b669b432ee',
    'identification_number': 104616890,
    'item_consumption_1': 9,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5cd5e439-4a7f-46f7-a53b-43cc7fef7150',
    'identification_number': 105202290,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91f5e67d-5646-4d95-ab79-d7dddb70a8aa',
    'identification_number': 103617515,
    'item_consumption_1': 27.84,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 809.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd8570f2d-4749-4df8-a79f-d2c8b0fc1fd5',
    'identification_number': 41700929,
    'item_consumption_1': 0.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1e20415a-9635-4f64-9e57-a440d122ca05',
    'identification_number': 51920220,
    'item_consumption_1': 1640,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 695.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4a1a16c-a1a2-428f-b271-82cf388877ff',
    'identification_number': 65079469,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bbff49d2-053c-4065-845b-92cfc11a8e63',
    'identification_number': 46677615,
    'item_consumption_1': 3.35,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 194.86,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'd664ef4c-496c-4b54-add2-abc33a99a3e0',
    'identification_number': 28259513,
    'item_consumption_1': 5.72,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 136.43,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ec033d70-7273-4b52-8b60-e0d936c93cec',
    'identification_number': 20830807,
    'item_consumption_1': 99827,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 37150.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '622168a6-8398-4934-aed9-b1c1e96e8e08',
    'identification_number': 32266847,
    'item_consumption_1': "'-28.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 01/2023',
    'item_total_value_1': "'-9.74",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'ba7b44bd-26c8-4fb8-abd2-2548466c1668',
    'identification_number': 36752118,
    'item_consumption_1': 4.4,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 104.96,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '27a740c2-f68e-456c-aa8b-439cbf7c26b2',
    'identification_number': 69972931,
    'item_consumption_1': 5617,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2194.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '014f8eed-21e9-4e5b-a554-b3c592784d7e',
    'identification_number': 61331180,
    'item_consumption_1': 231,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 97.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a99173ad-e3ee-4f91-95ec-eae245c45252',
    'identification_number': 20176228,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a5a7cc6f-2922-40e6-a82f-f76f7aa9c9d2',
    'identification_number': 19453302,
    'item_consumption_1': 1944.6,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 56560.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3cd37b60-4b9e-4f3d-b220-69cba6fb03f6',
    'identification_number': 91355540,
    'item_consumption_1': 567,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 331.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c575780c-d3ab-4fdf-b5fc-b2c66d969e4c',
    'identification_number': 105059552,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '244c1a32-48d6-43bb-916b-3f7c56e8fb55',
    'identification_number': 58696172,
    'item_consumption_1': 4.47,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 106.63,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '5b7ff547-ad5d-4a44-ba1c-6f6180173e44',
    'identification_number': 29046092,
    'item_consumption_1': 6154,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2405.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '003c3035-63ac-4ae6-8694-5c73ffc01214',
    'identification_number': 14562553,
    'item_consumption_1': 15.39,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 367.07,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ad9fb62a-d575-4f58-af57-b751235643c7',
    'identification_number': 23542438,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4931406d-1ca7-4aad-8f1f-8bc3bcb9bde7',
    'identification_number': 20817290,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61de36ff-19df-4b69-a9eb-bed29515015c',
    'identification_number': 33921180,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.85,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '072276c7-bbb2-437f-8819-11aa6b934533',
    'identification_number': 110227662,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61c476d5-adec-46e0-a647-003a86b42225',
    'identification_number': 18339603,
    'item_consumption_1': 44.4,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2535.68,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '25d6de62-ad8e-409e-9c12-f4954b9b394d',
    'identification_number': 80647588,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'fcfbc598-a31b-4f3f-9544-999986447c9b',
    'identification_number': 57673160,
    'item_consumption_1': 42.7,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2438.59,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b7ddd329-7375-491b-8be2-8602fc281196',
    'identification_number': 92724493,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0b2fa454-89b3-494b-9b71-340c6bbc9161',
    'identification_number': 71307745,
    'item_consumption_1': 4030,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1575.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5cf0b7b-aeea-4c81-9772-0c227944d2ba',
    'identification_number': 103143742,
    'item_consumption_1': 1293,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 504.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb9e1b5e-b5ec-444f-88dd-02f246790ff1',
    'identification_number': 45582459,
    'item_consumption_1': 4157,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1760.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62f4b74f-3e9d-4acb-b7dd-37d47aa038ef',
    'identification_number': 44502931,
    'item_consumption_1': 139.23,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 7951.42,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ff5a8a9a-402c-4f96-b6af-b17d6c2ba41b',
    'identification_number': 10006508,
    'item_consumption_1': 6082,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2378.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd23b142-9423-4e75-a4b5-d976bacdee36',
    'identification_number': 70941149,
    'item_consumption_1': 577,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 872.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59fe778c-413c-4c4c-af19-e76c5517e6fb',
    'identification_number': 26987415,
    'item_consumption_1': 1.386,
    'item_description_1': 'CONSUMO PONTA TE',
    'item_total_value_1': 767.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e804859-c597-4184-8677-69e0c164f865',
    'identification_number': 10361472,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ebcab941-fe26-420c-8221-a63dcef681f7',
    'identification_number': 103876502,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5d3e57a0-0a8c-4bd7-b8db-3aaa951cbfef',
    'identification_number': 75789981,
    'item_consumption_1': 29,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 11.35,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '10d8c94c-f12d-47d2-9daa-aafdef483ef5',
    'identification_number': 103284770,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '722535a4-bf83-4482-9857-8ef5f7e58498',
    'identification_number': 8866163,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 7.83,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '76f6744a-1288-4f3b-90df-bea178a83a7c',
    'identification_number': 35211229,
    'item_consumption_1': 776,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 329.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '949d4d5c-0cad-4ac7-97af-e7643c40b4a5',
    'identification_number': 9788131,
    'item_consumption_1': 11.01,
    'item_description_1': 'DEMANDA USD E PONTA ISENTA ICM',
    'item_total_value_1': 262.6,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd35e89d7-929a-47ff-a2bf-52833cebfc38',
    'identification_number': 15645835,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 116.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '031dc446-653e-475d-a08c-dfcca6869102',
    'identification_number': 40994724,
    'item_consumption_1': 8992,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5251.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b05298b3-bc7c-42cd-a584-b250222febd7',
    'identification_number': 101940122,
    'item_consumption_1': 292,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 114.24,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ca501417-0a22-4b82-8e55-2de1aabfd4d7',
    'identification_number': 97970034,
    'item_consumption_1': 378,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 147.89,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bbecfca2-8487-4ba5-b6b3-4c1db4df05d2',
    'identification_number': 14389754,
    'item_consumption_1': 1447,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 845.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03efe7d2-b9bc-4d0c-8de0-98e8cd1a06df',
    'identification_number': 17777852,
    'item_consumption_1': 667,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 283.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ef913f2-94e4-4c7c-a969-56ad21c45c0a',
    'identification_number': 79665306,
    'item_consumption_1': 227.66,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6621.7,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c65edcdd-15ba-4718-a0ce-ed3c55b4421f',
    'identification_number': 84968559,
    'item_consumption_1': 29.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 714.58,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '33d70097-3d8b-47a5-a020-b339f47c4b18',
    'identification_number': 93430442,
    'item_consumption_1': 2.31,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 134.37,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'eb292154-9a77-423e-836d-5beaed6d770b',
    'identification_number': 106046691,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a54e334e-635d-4ac8-9be1-f5b4b594b19d',
    'identification_number': 110373383,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '06ae1cb4-f58f-44d6-99c1-33bfe920d39a',
    'identification_number': 108726983,
    'item_consumption_1': 779,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 330.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '480c6c73-de0c-45a6-b90d-51172ec6d24b',
    'identification_number': 19452667,
    'item_consumption_1': 6455,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2525.67,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cf3f84db-32ca-4e2f-80a7-9a3a64704606',
    'identification_number': 104991402,
    'item_consumption_1': 5541,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2158.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29400610-55f4-4550-b200-6bd48800fc8c',
    'identification_number': 46424520,
    'item_consumption_1': 3381,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1434.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9b1ee53-e722-48e9-a795-ec357273f854',
    'identification_number': 66353564,
    'item_consumption_1': 779,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 303.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db74e20e-1ef3-4b37-90bf-03f69a24f53e',
    'identification_number': 40100812,
    'item_consumption_1': 8264,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3502.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94188d1a-f95d-4dc1-a33b-08ee52fe213c',
    'identification_number': 86773526,
    'item_consumption_1': 166,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 70.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2232283c-3e02-4ea9-a581-c184a0c64c04',
    'identification_number': 47154152,
    'item_consumption_1': 340,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 144.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ff89220-12f8-4055-a0d3-3f59bb53a90a',
    'identification_number': 40994724,
    'item_consumption_1': 22.33,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 532.58,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2abb79c5-75ab-44df-9dfa-3cdd4ca975a0',
    'identification_number': 68904401,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a4e16b5d-3545-4f5d-9c12-efa300ece70c',
    'identification_number': 55605451,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e02fa6bb-f72d-4b1e-8e13-8c22f3beeb4d',
    'identification_number': 99569280,
    'item_consumption_1': 21,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 8.21,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'edda27a7-e1cc-41f4-ac96-6362aa37c6fa',
    'identification_number': 84599391,
    'item_consumption_1': 14070,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1795.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e780a589-021b-49fc-89cc-07ca973319ed',
    'identification_number': 98706810,
    'item_consumption_1': 1085,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 459.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edc285b4-504a-438a-a643-dbc6e9469c72',
    'identification_number': 12424188,
    'item_consumption_1': 15.06,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 876.06,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '78e6b6bc-a9a2-487d-8b4e-c5c9649a22d8',
    'identification_number': 98913972,
    'item_consumption_1': 1682,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 214.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '685944a4-7ab2-4055-a715-96cd2503f740',
    'identification_number': 30290635,
    'item_consumption_1': 51.21,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2924.59,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '86a41ca4-8864-4b2f-a7ee-6c95b8fc79a9',
    'identification_number': 101143010,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c7940d46-c636-4216-bad7-e70b1fcb1a16',
    'identification_number': 108248321,
    'item_consumption_1': 3061,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1197.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e40e8552-56a1-4fcc-a334-0329b3e919a7',
    'identification_number': 80508839,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'faf25dc2-6c2d-41cb-8f54-8f8ef5b82f52',
    'identification_number': 85312185,
    'item_consumption_1': 20987,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2678.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c652695-e102-479e-a201-2c9c470b4fd6',
    'identification_number': 29891833,
    'item_consumption_1': 742,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 433.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a501e8c7-079a-4d11-8b79-d7b55113d2f0',
    'identification_number': 105297313,
    'item_consumption_1': 9769,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4135.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26f5ba22-b3b2-4578-82be-bf1ae761671a',
    'identification_number': 28671139,
    'item_consumption_1': 16.82,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 489.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '70c97139-abff-4c9e-8dd2-26c511c4ad4f',
    'identification_number': 109181093,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '961a9e61-eaef-4d4b-bfb0-1e1b2b542b4a',
    'identification_number': 56886314,
    'item_consumption_1': 360,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 140.85,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '77dd24c4-0803-4810-99ce-f9bd2c925100',
    'identification_number': 22067248,
    'item_consumption_1': 7779,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3035.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8af9388-ff75-4164-bbf9-e3dcb5dacab2',
    'identification_number': 40934756,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 120,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6e4359e4-9609-4658-a823-0e11e2fa22cf',
    'identification_number': 36368431,
    'item_consumption_1': 3354,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1308.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69bb3322-64d8-4e5a-b46a-c78305f7411b',
    'identification_number': 14642484,
    'item_consumption_1': 1052,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 134.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f0a9348-8e11-4225-b7f7-06dd3711b71c',
    'identification_number': 95920862,
    'item_consumption_1': 671,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 284.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52189e3f-c6ea-4ff9-9995-2e71009afa3c',
    'identification_number': 99353598,
    'item_consumption_1': 2083,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1216.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c17539d-e0f9-4587-8101-c052b558d788',
    'identification_number': 94120676,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e2aab12e-b9b5-41ee-b756-39721a064ac2',
    'identification_number': 105302570,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '36f28c00-f060-4140-9495-9986838d8d95',
    'identification_number': 43061842,
    'item_consumption_1': 15604,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1991.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3787448-b507-4b22-b790-54fd9482ab98',
    'identification_number': 4129199,
    'item_consumption_1': 220,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 93.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22e54421-7a15-462e-b407-2c95a7d54b4f',
    'identification_number': 64222942,
    'item_consumption_1': 9138,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3568.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43fa549e-ffd9-4516-987c-00466802537c',
    'identification_number': 33651868,
    'item_consumption_1': "'-1.08",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-345.62",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '418e02eb-30f3-4771-9b94-417c6642b259',
    'identification_number': 39482146,
    'item_consumption_1': 2392,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 934.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64f9896a-b2d8-49bb-983f-ee45453746b9',
    'identification_number': 18552099,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '554eaabf-671a-467a-88cf-31b1532bc316',
    'identification_number': 102622639,
    'item_consumption_1': 14287,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6051.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a031ac8-7c2b-49fa-898d-6f405251f5a8',
    'identification_number': 100968520,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62fddad1-8c75-40a1-ab19-2ebd18eab1b9',
    'identification_number': 60203390,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 274.1,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'b1a18266-b805-4921-9b21-d750d0d33fef',
    'identification_number': 72307706,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 127.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a703a067-bba2-4cf7-9802-2b4a2d641fb8',
    'identification_number': 33162557,
    'item_consumption_1': 16.53,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 480.78,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b6c37163-fba5-4d8f-98bb-f3460e98c324',
    'identification_number': 27709353,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6318f925-3bf7-4462-aae1-08040d5705a2',
    'identification_number': 19451113,
    'item_consumption_1': 31.92,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 761.32,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'eafc3a48-d9ab-407c-b7f3-6350ce23f143',
    'identification_number': 84969466,
    'item_consumption_1': 707,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1069.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f53aa7c9-73e7-4508-a71c-813d645b3db7',
    'identification_number': 33676208,
    'item_consumption_1': 23514,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3001.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1417a54a-a0bb-4b40-b972-2825ab4d40c7',
    'identification_number': 70128235,
    'item_consumption_1': 5861,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2181.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc824821-959b-41ca-80fc-dd485ad3cb0e',
    'identification_number': 10566813,
    'item_consumption_1': 6627,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2809.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12fc40b1-fe01-45be-be0c-5eb6131470e6',
    'identification_number': 13786067,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0a11254f-d038-4946-98f4-50cd223b7066',
    'identification_number': 45478260,
    'item_consumption_1': 4210,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 537.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5eb1a2eb-2360-4382-bc9f-313bce168624',
    'identification_number': 48367583,
    'item_consumption_1': 114,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 44.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c0bf6137-1e07-498c-9da7-05acd249b84a',
    'identification_number': 49074423,
    'item_consumption_1': 30.7,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 892.92,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '93936773-7353-46a6-865b-46805d247e9e',
    'identification_number': 105088730,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf2b1037-1aa5-42d6-9743-d25d5f8add94',
    'identification_number': 107480409,
    'item_consumption_1': 5349,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2269.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d395dd8-c41d-445c-9c5c-0ce867baca86',
    'identification_number': 70926115,
    'item_consumption_1': 57.9,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1684.07,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6b850365-bc12-4ef4-a747-c3c6e5fe0924',
    'identification_number': 53747755,
    'item_consumption_1': 33804,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4314.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6ead578-e6a5-4855-9e10-0a77d132a70e',
    'identification_number': 37423258,
    'item_consumption_1': "'-197.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2022',
    'item_total_value_1': "'-63.17",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '8729233f-9c79-4e51-8943-e34d04893ba5',
    'identification_number': 103352562,
    'item_consumption_1': 8041,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3141.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2167fc7-e890-4a6c-90ab-a1c190a5d77a',
    'identification_number': 100059643,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4f14be4-a7f1-4b9b-bcb6-e280c8458082',
    'identification_number': 14389754,
    'item_consumption_1': 27.1,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 788.21,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '301e1235-e8e5-485b-8bda-88f0d580d742',
    'identification_number': 22634207,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 228.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '65e0cf81-22ed-4373-9cec-ea74ac7f963c',
    'identification_number': 3031341111,
    'item_consumption_1': 6309,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 3186.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4c79dd1-0764-43a7-9d63-8c9f40278fe8',
    'identification_number': 33576718,
    'item_consumption_1': 1644,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 696.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ebacd83-e25c-45cb-afc0-0e32332b3b1d',
    'identification_number': 72777320,
    'item_consumption_1': 67.5,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1963.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c339384e-8cf6-46e1-a278-0b9ce871c728',
    'identification_number': 22164391,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '46187a83-4b57-48d2-aee3-5e9a9946de6b',
    'identification_number': 21059128,
    'item_consumption_1': 30,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 715.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'cf3286f9-b3d2-4b4e-a6db-7320f505a366',
    'identification_number': 15803600,
    'item_consumption_1': 956,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 726.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd422c114-0eb0-4c0a-8a2d-f9c73d72d339',
    'identification_number': 9623990,
    'item_consumption_1': 3393,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1321.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df1ac34e-c374-4272-a19e-8f7a41859b53',
    'identification_number': 16639626,
    'item_consumption_1': 8548,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3338.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8eda9e8-3846-4f97-a1b3-79544201fd2e',
    'identification_number': 45412839,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b97badba-00c5-4d11-b879-37e8dcd7ec45',
    'identification_number': 33204071,
    'item_consumption_1': 3855,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1506.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1fd05ef-e69a-4a94-8fa4-13f0559676dd',
    'identification_number': 59437308,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '263447ce-ad1b-4677-9005-60ee8673c01d',
    'identification_number': 41279263,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce32cb41-1b6a-40df-ae0a-6804a56016e6',
    'identification_number': 105313076,
    'item_consumption_1': 1252,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 530.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '417d5ff9-b309-4c11-bce4-76c0c344228b',
    'identification_number': 15885348,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8ea65fc4-5b2a-4bc8-ae80-352c1d6dc638',
    'identification_number': 93878710,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f46bf4a9-e747-4b7b-b3c1-f9167ca7dfe6',
    'identification_number': 105957283,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b62f5f0c-112d-4b3c-a2f6-f52606812dc7',
    'identification_number': 19453299,
    'item_consumption_1': 77.48,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 4507.16,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '50e55364-1c2d-463e-872f-213217d4d0a1',
    'identification_number': 96692979,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '63327c37-8344-4f73-92a6-3db111a86410',
    'identification_number': 101073593,
    'item_consumption_1': 81.62,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2373.98,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a436f522-5248-4e1e-9bb7-d01272c8f70d',
    'identification_number': 62557777,
    'item_consumption_1': 29.29,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 851.91,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '87c367a3-d6d3-4953-888b-27f1155634a0',
    'identification_number': 10145230,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 93.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '071b6ef4-f101-4746-92dc-9529dd279a72',
    'identification_number': 51528029,
    'item_consumption_1': 176,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 68.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6df7f024-0fb2-4794-a888-6f8ed36bb2c0',
    'identification_number': 73615145,
    'item_consumption_1': "'-15.204",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-4872.46",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'be7f860b-9ccf-478e-9756-c63d5c37fc17',
    'identification_number': 16307828,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 551.58,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '54927f44-a99f-4695-b9b9-1585a3381e78',
    'identification_number': 99753154,
    'item_consumption_1': 29905,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3816.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c0ae0f3-0f8e-4e08-8d05-12f15a95c42f',
    'identification_number': 108322440,
    'item_consumption_1': 891,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 520.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb4e067a-de90-48b1-b408-357af1ef3a44',
    'identification_number': 85192171,
    'item_consumption_1': 925,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 392.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08304560-6498-4cb9-b6ed-186d2caa2dc4',
    'identification_number': 14671638,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-710.12",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '52b5b24a-7c44-46d0-9aad-462050f80541',
    'identification_number': 95591850,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2b3e1b26-c5a0-454a-9eed-1e92645669c5',
    'identification_number': 18456669,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f85bd5cd-9b76-42dc-bcbb-738c1bf906ae',
    'identification_number': 68475594,
    'item_consumption_1': 601,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 234.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ea4ab14-2159-4b33-8192-7a6484fd50ad',
    'identification_number': 96692979,
    'item_consumption_1': 30639,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11402.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b02b09d-a968-4acf-bd6e-e2e12d052ed6',
    'identification_number': 102726450,
    'item_consumption_1': 162,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 68.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1e4b012-f1be-4482-8816-a81ef5957adc',
    'identification_number': 85070661,
    'item_consumption_1': 3122,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1322.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13b62018-49fe-4d42-878b-f9e1aa8ce2a1',
    'identification_number': 106928317,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5bd8129b-a5c7-4a66-a714-2194d4b20187',
    'identification_number': 16879562,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13ba1489-e05c-41f2-bb6d-42b47b387037',
    'identification_number': 6050093,
    'item_consumption_1': 631,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 267.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8108e73-94be-4c48-aa1f-67a2cc94586b',
    'identification_number': 16121490,
    'item_consumption_1': 562,
    'item_description_1': 'Demanda KW',
    'item_total_value_1': 10589.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4e7a87e1-701d-42b2-a7d2-bcf138a42c4f',
    'identification_number': 97425907,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '378fbd0a-4197-44f8-b766-fd471ddd056b',
    'identification_number': 41466888,
    'item_consumption_1': 1514,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 643.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50ae77d8-1fbd-463f-9eb5-92b8d844cd43',
    'identification_number': 62272039,
    'item_consumption_1': 3077,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1202.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef4d1e66-5693-4dfd-9a4c-c58a79ddf9bc',
    'identification_number': 102213330,
    'item_consumption_1': 201,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 85.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ebed8d2-4d04-4542-9b2d-ca358cc177fa',
    'identification_number': 42576636,
    'item_consumption_1': 1124,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 439.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cc5da66-1593-475b-8086-468d505d4595',
    'identification_number': 91100810,
    'item_consumption_1': 3101,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1317.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ff6710f-68a4-4869-a261-0c5f2faa9dbe',
    'identification_number': 15186075,
    'item_consumption_1': 2470,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1047.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1be7ad34-8b54-49b5-ba3e-7b309b256a0d',
    'identification_number': 6085954,
    'item_consumption_1': 16950,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6615.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd9685fb-a7cd-4f87-bbb6-03b13dcf40d6',
    'identification_number': 68902824,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3730.98,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0f7ecd70-17d6-40bb-a09f-5ee40d290513',
    'identification_number': 109379365,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '494b51a0-1b46-422f-aaa4-538ee61286b8',
    'identification_number': 42712190,
    'item_consumption_1': 14483,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5389.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95f3df37-18ff-4c17-b00b-a67d3ca1b46c',
    'identification_number': 17073030,
    'item_consumption_1': 24.99,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 726.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0447a2ad-6b94-4ace-bc2c-b1f45aa796b7',
    'identification_number': 56414762,
    'item_consumption_1': 100,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 37.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a337bf35-0ad1-4c67-b377-56af953e23f2',
    'identification_number': 21108218,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f0b55958-1957-4a1a-b437-6ce15b3ac84a',
    'identification_number': 8115710,
    'item_consumption_1': 551,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 233.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5e35f4b-4350-4947-8a64-c4ffd02ff117',
    'identification_number': 20587163,
    'item_consumption_1': 64675,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 37772.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad7a7e37-d013-40a9-b8f8-f3455c8bee43',
    'identification_number': 103192255,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d96f3e9-cabb-4cc9-9058-368b45ff05b7',
    'identification_number': 108987973,
    'item_consumption_1': 24931,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 9749.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a31e1eac-f426-4732-a291-14c71bd4b449',
    'identification_number': 107871084,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fafadb4e-bec8-4d9a-858a-5963012c7f43',
    'identification_number': 32495056,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e402c409-fe0b-4ff1-a55c-ae0d02629f25',
    'identification_number': 74865145,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4249.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '439bf6e8-9efa-4148-869b-1616dfbe7d99',
    'identification_number': 94814805,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 112.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14d3fdab-cc72-46e4-978a-f975a55e1ba8',
    'identification_number': 44478046,
    'item_consumption_1': 2720,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4114.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09b50391-7c40-4724-aa8b-b3eee7f67ca4',
    'identification_number': 111114039,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92412b26-6a4c-4472-967a-9ad503b8ee03',
    'identification_number': 41381823,
    'item_consumption_1': 9594,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5603.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8429f0a-e2e1-46a1-866e-1e8c8f628a11',
    'identification_number': 33557004,
    'item_consumption_1': 6424,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2719.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84f4242a-e9b2-4560-86aa-f8e9eac0527c',
    'identification_number': 94657190,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7d1a2cd2-0c79-4610-aea3-891c40e8a8bc',
    'identification_number': 91302102,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3fb1449-1e9a-41d7-960a-0a043e8d20c0',
    'identification_number': 32264615,
    'item_consumption_1': 983,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 383.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de9bf098-4bfb-4fc7-b5c4-e9e7aabd472a',
    'identification_number': 44028601,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0884a13d-c2bc-45b4-9d23-4ede61f231e6',
    'identification_number': 103039023,
    'item_consumption_1': 217,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 91.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd845c4b1-3353-4bda-a3cd-22a91ed654a5',
    'identification_number': 109034333,
    'item_consumption_1': 42.24,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1007.45,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'acb8c2cc-ca7f-496a-84b4-f77892c45186',
    'identification_number': 43626955,
    'item_consumption_1': 1172,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 149.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40cc4457-3f76-4f47-973a-c55a8bd48649',
    'identification_number': 53507460,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe1dc224-9d45-4d8a-af73-6e34d88e6bb4',
    'identification_number': 105456993,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 67.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '29b1c326-43b4-45b1-96b9-8f3020c526e2',
    'identification_number': 110216741,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9601062a-e4f9-4b8a-9eff-e170a4205bf5',
    'identification_number': 30405467,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '37e3f9c6-631a-4e2d-b0e3-1d3ea5d045ce',
    'identification_number': 109782909,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d3bcc11-675b-4c14-b426-35db3084e34a',
    'identification_number': 98508148,
    'item_consumption_1': 1960,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 831.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f55a2c1a-2ab9-431c-9649-c4343960a528',
    'identification_number': 99971852,
    'item_consumption_1': 10463,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3893.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69b8ab33-e5c1-46b3-8201-57f4a4f7dbfb',
    'identification_number': 95963391,
    'item_consumption_1': 922,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 360.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '706d751a-6f7c-4b78-b6cc-7c4a2d3a9e7e',
    'identification_number': 101340958,
    'item_consumption_1': 566,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 239.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0890684-0782-46fb-b70b-18acbacdd1a6',
    'identification_number': 64669360,
    'item_consumption_1': 53,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 30.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccd7883d-7a0d-46fe-93dd-084fbb67a4bf',
    'identification_number': 95839267,
    'item_consumption_1': 129,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 50.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54e41084-e619-4d19-96a9-a5028d7d1456',
    'identification_number': 74464264,
    'item_consumption_1': 1207,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 470.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a685ada3-887b-45ce-ba14-81d70a570592',
    'identification_number': 35000430,
    'item_consumption_1': 2.85,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 165.78,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '25dab9d4-35e4-44c3-8748-44b7d1f2f5c9',
    'identification_number': 57723354,
    'item_consumption_1': 2070,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 806.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48cc1b91-a026-4aaa-a516-a0cb3ca6590e',
    'identification_number': 38749645,
    'item_consumption_1': 4649,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2715.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33f07532-ade0-4770-b914-ffe777a949d4',
    'identification_number': 98560905,
    'item_consumption_1': 340,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 144.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d4310df-e663-4551-975a-2fabd3593179',
    'identification_number': 8115710,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6eccc99a-a947-4a0b-856b-ea0e5cf6c3df',
    'identification_number': 42698936,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed867545-a964-42c6-91fe-5b35fed01357',
    'identification_number': 68111720,
    'item_consumption_1': 6654,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2826.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '982d6358-e228-4b56-af73-7504e8f74c08',
    'identification_number': 68441347,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b1ad19c-b5d5-4da5-8d62-ccb94d74156f',
    'identification_number': 35000430,
    'item_consumption_1': 2893,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1689.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72fa8f9d-9440-4ca9-b9da-6136e0a55d3e',
    'identification_number': 5736234,
    'item_consumption_1': 15454,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6556.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c1010d3-e4ff-4308-b22a-5da91538970e',
    'identification_number': 55606890,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27b968af-863d-4022-bf4b-779c4caa3f57',
    'identification_number': 36794392,
    'item_consumption_1': 14434,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5646.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '367632ec-0f61-4f60-84b2-8e4fe463dd81',
    'identification_number': 109018451,
    'item_consumption_1': 260,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 101.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f1e8e552-bf5c-4e4c-bb51-3fadb0462c50',
    'identification_number': 96460210,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'afadb85e-c9c9-4881-bffa-003f9e9e21e4',
    'identification_number': 8529205,
    'item_consumption_1': 219,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 85.67,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '32d15a7d-5ad2-4d5a-952f-42fb74253e90',
    'identification_number': 99713349,
    'item_consumption_1': 41493,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 24233.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4aaff04-8422-4e0d-827d-5dd43c5f3f53',
    'identification_number': 11432616,
    'item_consumption_1': 10980,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4652.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e972994-d293-4335-b456-c111739fd464',
    'identification_number': 42310016,
    'item_consumption_1': 1,
    'item_description_1': 'Iluminação Pública',
    'item_total_value_1': 5.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8f518744-af45-44e9-a03d-098a9602475b',
    'identification_number': 105165468,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbd85467-b9c5-49d3-a2f0-9e1866838bcf',
    'identification_number': 14689146,
    'item_consumption_1': 36.7,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2095.93,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b9501c54-8f79-48af-9b0d-aedb523a212f',
    'identification_number': 18223680,
    'item_consumption_1': 267,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 104.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cad9c9c-bcba-4663-bf7a-f00cebe3bc1e',
    'identification_number': 55746560,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '87b0163a-00b0-476f-8d53-648042dea8b0',
    'identification_number': 45602387,
    'item_consumption_1': 7.74,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 184.62,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '14ffd67e-81b7-4c3f-b3af-f12850d7f58c',
    'identification_number': 73901857,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22bb2682-136e-43fe-b1b3-4f4ba05a5cf4',
    'identification_number': 94029539,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '134f442d-9405-4f4e-a5cd-8a9ceabafa89',
    'identification_number': 56876815,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6d5a3ac8-f023-4919-9135-3f76138542de',
    'identification_number': 91304083,
    'item_consumption_1': 11470,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4871.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '762bce28-b4b8-4bf3-92d1-ea610b270b19',
    'identification_number': 64175251,
    'item_consumption_1': 206,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 80.61,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8a1e1254-be4e-4fde-9fcb-1ceafc6f469b',
    'identification_number': 59059729,
    'item_consumption_1': 21.38,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1243.7,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7fcdbce0-3faa-4b5d-a3ee-3164e9e9f882',
    'identification_number': 18273424,
    'item_consumption_1': 3045,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1133.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '717bc713-4c26-46e9-b7fd-e535b8e9652d',
    'identification_number': 61827843,
    'item_consumption_1': 798,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 338.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10a937b9-5b90-40aa-81ad-029ca3fd9ad4',
    'identification_number': 3030777242,
    'item_consumption_1': 7799.8318,
    'item_description_1': 'Cons FPonta TE NOV/23',
    'item_total_value_1': 2203.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6f85029-a842-49b8-9318-a1e7732fe543',
    'identification_number': 84599391,
    'item_consumption_1': 14070,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5236.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '648925aa-9427-4bab-ba17-43e1f747ccc6',
    'identification_number': 82744181,
    'item_consumption_1': 8829,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1126.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ddb26d4-9370-4d26-aec9-c455657e3a5f',
    'identification_number': 111081076,
    'item_consumption_1': 120,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 46.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '983317dd-776f-4840-a56b-1613b5fcf516',
    'identification_number': 110994191,
    'item_consumption_1': 3056,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1296.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad64d726-1415-4157-9f6b-2a01386df573',
    'identification_number': 103337300,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9000202d-946c-44ea-be8f-192d5756a9d1',
    'identification_number': 59144017,
    'item_consumption_1': 24238,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9020.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a23b2eb7-5ccb-4d33-9300-dade955ead33',
    'identification_number': 34851151,
    'item_consumption_1': 50.33,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2874.34,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f2040c43-5513-42e1-b2f4-f0d7eb242d19',
    'identification_number': 38227436,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '55bb6463-4291-4bb9-aad3-c893fc579c27',
    'identification_number': 40435288,
    'item_consumption_1': 2964,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1731.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ab34e96-67a0-403d-a083-d8d4149ca1ad',
    'identification_number': 52512576,
    'item_consumption_1': 1226,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 478.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d2fddd4-e1c6-4fa8-a5a0-d8d176e4b4b9',
    'identification_number': 48339610,
    'item_consumption_1': 6196,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 790.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1109073b-26ff-40cf-af6e-b327649e3d9a',
    'identification_number': 28426193,
    'item_consumption_1': 167029,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 21318.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55f9322f-4e49-4555-b616-775a8e9e6b5a',
    'identification_number': 31900852,
    'item_consumption_1': 39498,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5041.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4d814db-46bc-443c-85b2-e9e1f8afe5a3',
    'identification_number': 111114535,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1a5fd86-8c15-499a-9c64-a59861cc8d48',
    'identification_number': 16877454,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 147.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '86ff9724-92ea-4533-bfa2-9097ed84d0c8',
    'identification_number': 107833220,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db225705-750e-4237-b95c-730f8d80c954',
    'identification_number': 47023090,
    'item_consumption_1': 24237,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9019.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8b574d4-eed9-4655-b618-ce81c353ec25',
    'identification_number': 6211275,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0cfe4cfb-cc95-43a1-af67-d168b1cf9e1e',
    'identification_number': 59438592,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '875b9e0a-7f80-48b4-bf26-38683d289989',
    'identification_number': 103337300,
    'item_consumption_1': 17244,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6417.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd63198d-a8c4-4ca1-a344-401dd8fec32a',
    'identification_number': 56526849,
    'item_consumption_1': 846,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 494.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6f2e236-74e5-444f-bbf2-2993e5c336f6',
    'identification_number': 59479078,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '47ed20a2-77f8-4444-9f56-503fd952e0c7',
    'identification_number': 22974113,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 50.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cd5eb5a1-02d0-4c2f-9827-59b7da4f1d86',
    'identification_number': 88703614,
    'item_consumption_1': 578,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 245.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b57f6dc-178c-4889-a187-300b7488c0b7',
    'identification_number': 76288714,
    'item_consumption_1': 1163.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 33842.08,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1498f297-74e4-4b10-adfa-78567e79831f',
    'identification_number': 37752871,
    'item_consumption_1': 7206,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2806.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c7ea1ce-9382-46fe-b7eb-dcb8994accf3',
    'identification_number': 24083542,
    'item_consumption_1': 41019,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15265.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04979024-a681-4f83-bfcb-e0f97acefb40',
    'identification_number': 101151675,
    'item_consumption_1': 1022,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 433.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '136464af-3250-44ab-a588-06f906604323',
    'identification_number': 59101580,
    'item_consumption_1': 14.88,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 432.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9f92f381-78ec-4b27-8687-8fe7f34f2009',
    'identification_number': 15444015,
    'item_consumption_1': 3480,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1360.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57038c6e-ef34-4567-903c-abf227fa7abb',
    'identification_number': 72792914,
    'item_consumption_1': 410,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 173.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e863425-737c-41e1-a8d4-ef1b64630280',
    'identification_number': 14671638,
    'item_consumption_1': 5315,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 678.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2bc6b4ca-0edb-44dd-919c-1cf3e839b8dd',
    'identification_number': 1272543,
    'item_consumption_1': 2747,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1164.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74f5b857-686e-457a-a13b-87b78a06d972',
    'identification_number': 105088730,
    'item_consumption_1': 16.48,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 479.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '7923fb97-0e63-4154-a206-af42a9f1eaa2',
    'identification_number': 101919638,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4306bc01-125f-4cd4-a302-aeff927c88cd',
    'identification_number': 98942468,
    'item_consumption_1': 10833,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4031.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf894bb7-16d7-4868-b6d7-7cb931647763',
    'identification_number': 92773621,
    'item_consumption_1': 4457,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1891.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21e0a77d-101b-4944-9b94-6833ac0ed039',
    'identification_number': 101341547,
    'item_consumption_1': 2379,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1007.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01b1251c-3c78-47bd-955e-85bd36524725',
    'identification_number': 94391645,
    'item_consumption_1': 17.9,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 520.62,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a90faa60-2f88-4933-b0bb-716611582ebb',
    'identification_number': 107936704,
    'item_consumption_1': 6463,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2405.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1307c728-9370-4b60-ac2a-3b4629d14c4f',
    'identification_number': 51333376,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d8decb6-a410-4544-9970-9804d3552a18',
    'identification_number': 26987415,
    'item_consumption_1': 17.95,
    'item_description_1': 'EXCED DE DEMANDA REATIVA TUSD',
    'item_total_value_1': 418.67,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'cb011833-2115-4811-a7f9-1903194005a5',
    'identification_number': 40435288,
    'item_consumption_1': 57.07,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1659.92,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'eb1fa9df-a15d-43c7-a7e5-bd543e037a6c',
    'identification_number': 59070960,
    'item_consumption_1': 1.62,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 38.64,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f805ee7d-e763-4c5e-a514-3a49f432da54',
    'identification_number': 98799681,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 130.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '071eab29-20bb-4f99-b25b-8c0c60c765ea',
    'identification_number': 105297313,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bb240922-5773-4112-b9b3-132e22ab4edf',
    'identification_number': 15101797,
    'item_consumption_1': 1.19,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 28.38,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '31dbbd61-aac1-4531-b077-6e8839916837',
    'identification_number': 32348533,
    'item_consumption_1': 31.1,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 904.56,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3e019cd2-5f36-4c50-81fd-d3b2dfeba8b6',
    'identification_number': 41360869,
    'item_consumption_1': "'-1.11",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-386.98",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'cf83cf11-58f9-4fbe-9e17-f7740bfd966c',
    'identification_number': 48999644,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f53813f-d034-4c4a-938e-0e291e4ffc65',
    'identification_number': 15507971,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '34ef7ba6-da2a-4284-8e10-e8006af59d70',
    'identification_number': 105906280,
    'item_consumption_1': 6845,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2907.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6731b4a-1850-40c9-8c94-a4fb3fcc1e28',
    'identification_number': 22526846,
    'item_consumption_1': 13947,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5435.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'abb2fc62-0534-44eb-b0e1-6f01f804c172',
    'identification_number': 15102009,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eee35893-84d1-4d25-9661-c34d85d6baed',
    'identification_number': 106613979,
    'item_consumption_1': 1.86,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 44.38,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '65179b59-5506-484e-8218-757f63b834cd',
    'identification_number': 104186020,
    'item_consumption_1': 4067,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1513.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78792523-1b72-453f-b65f-fcc82d391ccd',
    'identification_number': 111740592,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5968b0e9-8487-41ee-9b25-e705f74c7fdc',
    'identification_number': 91862833,
    'item_consumption_1': 5756,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2446.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0535a4e0-e5a8-474f-aea1-524330e6d675',
    'identification_number': 44461461,
    'item_consumption_1': 1007,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 393.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd74a337-df94-4b0f-a4ba-d6bdb1a6f5bc',
    'identification_number': 75896974,
    'item_consumption_1': 1347,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 527.04,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '56388488-77f6-4662-b9c1-f69d4aedd913',
    'identification_number': 97988804,
    'item_consumption_1': 85,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 33.25,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '92b8ca5e-589f-4142-a160-0a4588be4943',
    'identification_number': 98548255,
    'item_consumption_1': 1994,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 776.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67ec0c7b-b114-40df-9494-f225cadaab20',
    'identification_number': 22742840,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fc0b632-c6f6-40db-a52d-3a5b0c52070f',
    'identification_number': 8115583,
    'item_consumption_1': 150,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 58.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54cc90f0-db98-4b3c-9975-a63fb3f84766',
    'identification_number': 8882991,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '68e88447-0223-4566-9524-136d2ffbc988',
    'identification_number': 39538842,
    'item_consumption_1': 215,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 83.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fbbb47b-aea4-476a-98fc-1a4382dafedc',
    'identification_number': 74880462,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '95dcee08-7447-46cf-b503-ea5cf17a7d6d',
    'identification_number': 69720185,
    'item_consumption_1': 6412,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2500.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5dbd1935-04d1-4447-9555-442e459f76e4',
    'identification_number': 60092904,
    'item_consumption_1': 20.61,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 599.45,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a87bd1c2-450e-4efc-a8e9-ee2d561edf7f',
    'identification_number': 58734210,
    'item_consumption_1': 6.99,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 327.35,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '9a936708-3f2f-42fa-aaa9-861256b630a3',
    'identification_number': 97259810,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f18aa19b-99eb-496e-850a-f51b0f0d53be',
    'identification_number': 5329582,
    'item_consumption_1': 31417,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4009.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61940a94-6486-4d19-8ff7-7684120cb00d',
    'identification_number': 31645313,
    'item_consumption_1': 55,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3141.04,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1c2819a5-1ec9-4be6-8cc2-977eba526f14',
    'identification_number': 94011591,
    'item_consumption_1': 1376,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 583.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5803becf-c90c-4806-a9b2-92e8360a5594',
    'identification_number': 30298482,
    'item_consumption_1': 45.51,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1323.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '443e2a76-8662-4838-824d-8dc371162590',
    'identification_number': 22963677,
    'item_consumption_1': 180,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 70.42,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ae0104e9-326e-4be5-94cc-a7f98c7c5dc3',
    'identification_number': 92782221,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3e6825dc-aabf-4995-98c4-20beb76d6bff',
    'identification_number': 17241170,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '31102016-b386-420b-99c2-29230c7ec0c1',
    'identification_number': 16677412,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8934067f-c098-41c4-87f4-e92e5da06ab0',
    'identification_number': 108689042,
    'item_consumption_1': 5578,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2366.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88e3e754-8a77-4d1b-af3e-0cd5acfe891e',
    'identification_number': 99569280,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cdd60270-8225-477b-9736-fcefb376589d',
    'identification_number': 94984859,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a65eed64-1ee4-43de-8293-5a9bf199fec0',
    'identification_number': 31640427,
    'item_consumption_1': 988,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 577.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96a5ac7c-eae4-45d4-a676-1407ade035da',
    'identification_number': 81500513,
    'item_consumption_1': 707,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 276.63,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7488cdf3-fa44-4703-9b47-17156968bc42',
    'identification_number': 5586860,
    'item_consumption_1': 2556,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1086.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97f2fef3-e846-424f-a65c-790e797baed6',
    'identification_number': 69983577,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b639d02e-6ce8-44bd-bade-dc62e2a6378c',
    'identification_number': 106043919,
    'item_consumption_1': 428,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 181.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de553683-eadc-474f-9ebf-a332875bcbb0',
    'identification_number': 27047970,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3e49b266-5957-4fcf-b315-eeb4b5ab8980',
    'identification_number': 19450737,
    'item_consumption_1': 1286391,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 164190.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e261bb8-6706-4867-9d32-db8e3106f1bc',
    'identification_number': 54126754,
    'item_consumption_1': 714,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 265.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa4c970e-95c7-47f3-8168-cf45ce21725f',
    'identification_number': 69873070,
    'item_consumption_1': 12874,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5456.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '307f31dc-fe4c-4116-a6e9-90edd08d2cbb',
    'identification_number': 99801736,
    'item_consumption_1': 590,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 344.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ab20942-e97a-4fe2-a136-139ecbf6e898',
    'identification_number': 80724906,
    'item_consumption_1': 36196,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4619.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd86371b7-05a7-4e33-a6b4-4db3687fe84f',
    'identification_number': 98265172,
    'item_consumption_1': 9.93,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 236.84,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6c5939b6-0d92-4301-a53f-00a729cb25e3',
    'identification_number': 57046450,
    'item_consumption_1': 6068,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2371.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e72c90bd-02c0-46ba-b4d0-86bda9a7a29d',
    'identification_number': 68904401,
    'item_consumption_1': 3040,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4598.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8fb9f10-1ff7-4d34-b742-a5c1406ad04a',
    'identification_number': 11679751,
    'item_consumption_1': 0.58,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 13.85,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e46814c7-34ef-404c-8743-1d89a8f52f87',
    'identification_number': 84742011,
    'item_consumption_1': 227,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 96.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4468c597-ddc7-4e67-a93b-0de0d3ab7a6e',
    'identification_number': 15686396,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-6.74",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '82c5d3ac-8ca6-4bd8-8ad9-d7b6772fc091',
    'identification_number': 92828760,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1288.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6937adbd-7091-4f9a-b4d0-f7782c4c38c5',
    'identification_number': 45789061,
    'item_consumption_1': 1883,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 735.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc397781-6c90-420a-afec-c67bcc764019',
    'identification_number': 935371590,
    'item_consumption_1': 3249,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 2832.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc911297-d688-4832-aa83-847108c53213',
    'identification_number': 13262599,
    'item_consumption_1': 6520,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2545.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4abe174e-906e-445b-8d42-99f592d8ddff',
    'identification_number': 79007651,
    'item_consumption_1': 3295,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1378.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8666781b-eb5f-4732-9372-47b7ea2dad43',
    'identification_number': 107684187,
    'item_consumption_1': 3478,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1359.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da8c3a99-65dd-421d-9480-0bb9bcafe65e',
    'identification_number': 31497861,
    'item_consumption_1': 10061,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5876,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24111c7d-3775-442a-b78d-8599966c98e1',
    'identification_number': 88703614,
    'item_consumption_1': 578,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 225.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5eee7572-daa9-4d5e-8aec-390927a3e515',
    'identification_number': 65960017,
    'item_consumption_1': 2486,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 971.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba34b10e-5d18-44ea-9121-5f4d58eaff12',
    'identification_number': 56526849,
    'item_consumption_1': 59,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 23.08,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '75c70eb3-5618-4ac1-99f1-7dd1a192b359',
    'identification_number': 55193994,
    'item_consumption_1': 2647,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4004.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7d86b13-09f3-41f3-9704-b356e8c55068',
    'identification_number': 92333435,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e1effefa-3295-4c4b-a069-3d6348968970',
    'identification_number': 40490688,
    'item_consumption_1': 9.53,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 227.31,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a95df85b-f49c-419e-a733-ebee6d298396',
    'identification_number': 41614518,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3406f9ad-eb6c-401b-beb5-7a449a2bc08d',
    'identification_number': 19450753,
    'item_consumption_1': 59,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 23.08,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '17836351-14dc-46bd-aea8-39ec014b4e8b',
    'identification_number': 68085559,
    'item_consumption_1': 66799,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8525.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee86fd78-cc9b-4e87-b551-a5d1d7ef1ae6',
    'identification_number': 98271903,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5ff97ec9-55cb-4b04-aac5-81df4108259b',
    'identification_number': 20540205,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '384ce202-7237-4604-b06b-d3072f647395',
    'identification_number': 44219393,
    'item_consumption_1': 71327,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 9103.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4ffb312-ed57-412b-b8b3-5f0f89590fdf',
    'identification_number': 106621610,
    'item_consumption_1': 1803,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 762.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebf70365-a238-4a87-8eef-a63ac3e8686f',
    'identification_number': 70503974,
    'item_consumption_1': 18.96,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 551.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fa5aba5f-6b29-4095-8731-75ae5b2944cf',
    'identification_number': 68559593,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8571d76a-d9fe-4b71-a7f9-2ff3121bf178',
    'identification_number': 100797075,
    'item_consumption_1': 68.78,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2000.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '175932ed-8cd6-41b9-b954-07ded4479108',
    'identification_number': 48362832,
    'item_consumption_1': 74.48,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2166.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ae7f3f41-cec1-4911-9401-244e1d594513',
    'identification_number': 94650977,
    'item_consumption_1': 23.32,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 678.26,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f5b39cea-1272-493c-9998-3ba902d4e2f2',
    'identification_number': 99580977,
    'item_consumption_1': 5792,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2259.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76ca3ba9-471e-4561-b880-332935b281f0',
    'identification_number': 65065662,
    'item_consumption_1': 12083,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5120.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a62f565d-390b-451a-8b74-ab8e195a23ca',
    'identification_number': 6050093,
    'item_consumption_1': 631,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 246.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6d8caf5-6e8a-40be-867b-e44545139a3e',
    'identification_number': 91302102,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '604f5afb-6b93-4835-b463-a200f4fe0e42',
    'identification_number': 105805572,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cc7ab4b3-8890-4e7e-aa6d-4a14fa8d6bbc',
    'identification_number': 103034579,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d5b0104-441a-4675-8f51-5cac3ab9fe18',
    'identification_number': 99753154,
    'item_consumption_1': 2783,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1625.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9abd9fd7-a7e7-4879-bc46-4df8e6592a5d',
    'identification_number': 15843939,
    'item_consumption_1': 6770,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2869.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33b8b4cc-b36c-4039-8e0f-9516bc55583d',
    'identification_number': 52131718,
    'item_consumption_1': 29931,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3820.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38ee4e0f-cf51-4f39-b0df-3ef283f5b0ff',
    'identification_number': 108065006,
    'item_consumption_1': 2263,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1321.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27246e3b-3db5-47ef-8cbc-13ebd0099490',
    'identification_number': 99786796,
    'item_consumption_1': 165,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 70.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8631aa8a-d097-49e9-8612-bbe19f56ff95',
    'identification_number': 107049910,
    'item_consumption_1': 25276,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9406.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53557913-a927-49d0-a9ee-7a9a0cf039d7',
    'identification_number': 81267843,
    'item_consumption_1': 6.09,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 145.27,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '81663aa9-122c-4bdf-a208-0d83117dfe5f',
    'identification_number': 43333680,
    'item_consumption_1': 3243,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1252.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b2a9558-8c29-4fcc-b288-1765b027d02f',
    'identification_number': 34208089,
    'item_consumption_1': 4958,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2106.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84b95219-e62d-43ba-978e-aa0049bccc68',
    'identification_number': 55456812,
    'item_consumption_1': 2978,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1739.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97a8c48e-14ea-4a08-ab18-fc769275936c',
    'identification_number': 110994809,
    'item_consumption_1': 2303,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 976.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02ad45db-8423-4075-bc4a-e5167d037715',
    'identification_number': 15158241,
    'item_consumption_1': 2816,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1047.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a38b35de-6bf7-4d0b-b499-079cdbf17ca7',
    'identification_number': 71434690,
    'item_consumption_1': 6109,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2380.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1fd10b7-c236-4469-8b32-ef582a581565',
    'identification_number': 46006583,
    'item_consumption_1': 22200,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2833.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e084b5b7-5910-4445-bcd4-dce059c50329',
    'identification_number': 108601676,
    'item_consumption_1': 874,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 325.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e0cf257-40aa-4068-93fd-19b74d1639d3',
    'identification_number': 42396905,
    'item_consumption_1': 390.24,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 11350.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8d92d4d0-9b5f-4111-81aa-a909d2c7b5c6',
    'identification_number': 100185681,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd48fe9fc-0cc8-4506-8812-fe9e3d6aaf90',
    'identification_number': 38432005,
    'item_consumption_1': "'-832.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-265.7",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'bfb05eb5-82eb-4cdc-985e-949a4319bbe0',
    'identification_number': 103208348,
    'item_consumption_1': 4807,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2042.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cce90e69-f680-45a5-8285-4ff98968d6e9',
    'identification_number': 13877984,
    'item_consumption_1': 1682,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 982.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72076ceb-40e1-4249-bb1a-614f1b01e5da',
    'identification_number': 98150936,
    'item_consumption_1': 336,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 130.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18949338-704d-448b-9bd4-3d15315e6d63',
    'identification_number': 32501480,
    'item_consumption_1': 10739,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3996.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45dcf756-81d2-4818-853a-e85f0ca8b6ed',
    'identification_number': 11685913,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '93a76da5-834c-4c70-a48d-5c2de2839186',
    'identification_number': 108167879,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '763ee895-6be7-4828-867c-1fab16e0405e',
    'identification_number': 93830130,
    'item_consumption_1': 1069,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 416.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eba79710-ed5a-47b9-adf1-68e4b54dff4b',
    'identification_number': 98127373,
    'item_consumption_1': 2693,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1142.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d0d2fe2-0224-40c8-a931-36d1b0462cb5',
    'identification_number': 104638621,
    'item_consumption_1': 827,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 322.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '094e1db5-17f3-4449-9acd-47659f66d8dd',
    'identification_number': 5329582,
    'item_consumption_1': 6.8,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 395.56,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'f9c3403e-01f7-4e77-9250-2bef15242f36',
    'identification_number': 102014868,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4af31ac2-a07a-4730-a52d-96d2f2e6069e',
    'identification_number': 14642484,
    'item_consumption_1': 34.19,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1952.57,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '941d06b9-f774-45e3-b8e9-5e32872feab3',
    'identification_number': 46913190,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '23674aaf-31e3-439e-82bb-0819353ee4be',
    'identification_number': 93133774,
    'item_consumption_1': 949,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 371.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c28e52da-c7e4-4325-a809-6c20768bc732',
    'identification_number': 33354537,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-4216.57",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'd396e2d5-9797-4a02-b0df-db3a6d85270d',
    'identification_number': 98370200,
    'item_consumption_1': 30243,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3860.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72049421-eb29-4ca0-89ff-bb98ec8e9342',
    'identification_number': 49951670,
    'item_consumption_1': 43050,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16020.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf0aaf1c-4bc0-4c75-90b3-222ad1fcec0d',
    'identification_number': 45239282,
    'item_consumption_1': 27594,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10269.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7cebd70-aad1-4c90-b0e6-8856f66f47c4',
    'identification_number': 99678195,
    'item_consumption_1': 2541,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1076.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e57a571-da7b-433d-a02a-1119ba8a5895',
    'identification_number': 101837461,
    'item_consumption_1': 3245,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1267.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d40e719-1fc7-490b-a49b-f0d5a578af48',
    'identification_number': 91975522,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '416ea813-d267-44a6-bc79-27778fb64a6f',
    'identification_number': 95847952,
    'item_consumption_1': 3613,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2110.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41c1c776-8bbf-423c-8d71-8ddbe11caff2',
    'identification_number': 14905612,
    'item_consumption_1': 1641,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 209.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca1e9df7-61db-45aa-a571-a5ee8c89d7d6',
    'identification_number': 101232772,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '60e5cd73-f8a3-4537-8dce-d9e2747fc00f',
    'identification_number': 15397009,
    'item_consumption_1': 19,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 293.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e609c7b5-5058-42e1-87fa-18ae266241fa',
    'identification_number': 8529205,
    'item_consumption_1': 124,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 72.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f5ba6af-9153-48f5-bc05-4e37f52b1204',
    'identification_number': 103840923,
    'item_consumption_1': 0.44,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 10.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '272a8a1d-6b77-4bab-b33a-0413c4a47c6e',
    'identification_number': 948743595,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bde15862-a28a-4098-8e49-63a0b0b641b9',
    'identification_number': 12424188,
    'item_consumption_1': 74186,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 9468.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef0a7822-07d0-4534-bedf-439f22904347',
    'identification_number': 64554481,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 226.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88ea25ad-46d4-4bc1-95e0-194a65a57c1b',
    'identification_number': 38055660,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3bbc8e10-4e5e-4cea-85e9-966e2933788f',
    'identification_number': 40100642,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1defd1dc-3510-4830-a66a-77941b41bd0d',
    'identification_number': 14825430,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1cf7f7cc-cfa9-4bcd-90f0-cff96f93a667',
    'identification_number': 48004936,
    'item_consumption_1': 33342,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4255.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd12ad6a7-2719-43c8-b87b-da8ef828ac02',
    'identification_number': 97762245,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ffcbe43-8769-4897-8456-dcd49d47091e',
    'identification_number': 104028190,
    'item_consumption_1': 101,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2cb8b1e-9ff5-42a9-a1b5-0c1e170ca2e5',
    'identification_number': 22642803,
    'item_consumption_1': 172,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 67.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7739763-24b2-41e7-835f-fa73c0653ad7',
    'identification_number': 103967923,
    'item_consumption_1': 1118,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 474.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4cc55a4f-ebc5-4b47-b7f7-26fc5d361986',
    'identification_number': 104871563,
    'item_consumption_1': 5.43,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5f84502e-528a-4d21-9722-0c751278ec26',
    'identification_number': 16000862,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c35fe32d-aeab-4a96-9098-03313d0f3891',
    'identification_number': 27895602,
    'item_consumption_1': 224.64,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6533.85,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ec574562-83a3-413c-baf6-a9d65b0a8f0c',
    'identification_number': 109350022,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0bf58777-00e3-4a75-87ab-1fdb42ef3eac',
    'identification_number': 99102935,
    'item_consumption_1': 27838,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3553.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2940da6a-8025-400b-9640-174ce8929133',
    'identification_number': 69967318,
    'item_consumption_1': 25891,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 11002.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a6a0ddd-3151-41d8-951c-6b1e873bf796',
    'identification_number': 11708522,
    'item_consumption_1': 2988,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1269.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e8c27d8-abd5-48ab-90d4-e9e99925e60c',
    'identification_number': 23660872,
    'item_consumption_1': 15379,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5723.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a754a36-a5f6-4762-ae08-f21a75b449b2',
    'identification_number': 5695015,
    'item_consumption_1': 1014,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 394.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5c6ce90-5b56-4688-af9e-d321118d7bd0',
    'identification_number': 111834171,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6f97053-4548-4357-af2e-e6e39aed880b',
    'identification_number': 28202619,
    'item_consumption_1': 3774,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1470.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65ddec88-862f-48e8-916c-f481d61f8cad',
    'identification_number': 98718410,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '759b9f1e-dadf-4391-ab5a-47c42e94b143',
    'identification_number': 40780163,
    'item_consumption_1': 3058,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1298.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e4a6749-3186-4c30-b5af-66b29ad5ba86',
    'identification_number': 80146139,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a5574a19-fd1a-4255-9e97-f78160bfc6d5',
    'identification_number': 99569280,
    'item_consumption_1': 4.78,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 272.96,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '387b8151-0db1-4af8-b44f-09e66220eb6e',
    'identification_number': 53371127,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 116.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2b6f0c83-8e52-4184-9714-e131ecadefab',
    'identification_number': 12381519,
    'item_consumption_1': 877,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 512.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9accf3cc-310e-41be-b843-cdac4e097c70',
    'identification_number': 95765840,
    'item_consumption_1': 60.41,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1757.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6c0ebd68-2f98-4e02-b293-72ac54a06340',
    'identification_number': 12117498,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96f6509b-4f6e-4196-a08a-dedd696ff235',
    'identification_number': 56493371,
    'item_consumption_1': 11442,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4258.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f65b13f-93f3-41de-9e2a-17591277dc88',
    'identification_number': 15369080,
    'item_consumption_1': 29.32,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 852.78,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cdf0ba0f-fdb9-447b-8e88-d0d841937b81',
    'identification_number': 84803932,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0291bc72-bd17-4847-b6b3-34400965d9d6',
    'identification_number': 14071240,
    'item_consumption_1': 42,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e8e8b7d-a728-4fa0-8ea3-a2a8276203b6',
    'identification_number': 106077406,
    'item_consumption_1': 53.91,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1285.79,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4b2eaf4e-5550-4896-ab68-0069fc343c85',
    'identification_number': 94699020,
    'item_consumption_1': 8105,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3158.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '255deedc-70a4-46b3-af92-995935f49830',
    'identification_number': 103904212,
    'item_consumption_1': 5105,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2166.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16290de9-7dfc-4290-8f0e-028c215d0220',
    'identification_number': 105479551,
    'item_consumption_1': 2328,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 989.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00dde453-e6c1-47b0-bb60-456cc2b8e131',
    'identification_number': 55606890,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1fc2b404-7f16-4ea5-a1af-e27b5de662bd',
    'identification_number': 111740649,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b68a659e-4ce3-47d2-ba2f-170559b5f846',
    'identification_number': 50940694,
    'item_consumption_1': 25.92,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 753.9,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0d9941cd-9402-4e40-8f39-5266e20f5e8e',
    'identification_number': 16916700,
    'item_consumption_1': 11418,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4838.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e603f58-3fe0-4b3f-8354-d4e6d1d82acf',
    'identification_number': 59070960,
    'item_consumption_1': 28.38,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 825.45,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8d19483e-587f-4e3a-b6d1-08cb6c286e3a',
    'identification_number': 103337300,
    'item_consumption_1': 26.45,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 630.86,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '45238877-fb62-4ff4-b80f-54a8cd332cd4',
    'identification_number': 62304208,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ad17c789-34e6-420b-ae85-a21c01a11bf6',
    'identification_number': 47899816,
    'item_consumption_1': 1206,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 704.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '237796f3-1cc1-47db-a91b-60ddbdfbd4b4',
    'identification_number': 3032256118,
    'item_consumption_1': 3223,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 1627.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42ecfd51-d8d6-4347-b796-53d73fc12d3b',
    'identification_number': 29891833,
    'item_consumption_1': 6767,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2518.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ffe5a87-ec69-4663-82b6-438f68e13f9c',
    'identification_number': 20367384,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2ee07ca-8597-4c20-8ea1-7f49d36d613b',
    'identification_number': 87807360,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '85a68f19-2c82-493d-9394-bd91ca8b6643',
    'identification_number': 11490586,
    'item_consumption_1': 1278,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 542.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '330a1520-f9eb-4a3c-844f-6bdd870a3e46',
    'identification_number': 107956403,
    'item_consumption_1': 1000,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 391.28,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '51182cab-1974-4b3d-a121-7e43c35ff6d8',
    'identification_number': 41971817,
    'item_consumption_1': 2127,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1242.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ab33eef-2f98-4f7f-a810-3983f1d62507',
    'identification_number': 17159679,
    'item_consumption_1': 398,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 602.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c53bf09a-513b-426a-be6a-0dc81cc63f37',
    'identification_number': 45766500,
    'item_consumption_1': 52,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 19.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a912df2-d1ac-4692-8724-a077a6a3f23c',
    'identification_number': 108597199,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e6da2f8-8a2b-4b32-9aa0-7ad3cf0826a1',
    'identification_number': 67439314,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 16.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c0580501-b551-42a7-96a7-74b6dc65c2c5',
    'identification_number': 55524567,
    'item_consumption_1': 14.41,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 343.7,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7723dcb4-20fb-4f8c-8416-52e16f228c30',
    'identification_number': 43626955,
    'item_consumption_1': 7.94,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 371.85,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '19bb7c85-1c7e-4351-8d0d-88481d5a249b',
    'identification_number': 948743496,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6fbffb12-88e5-4d94-801e-69dd3b995257',
    'identification_number': 96197080,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f389e705-a128-4018-8235-55abef38ef44',
    'identification_number': 32121644,
    'item_consumption_1': 10413,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4424.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad4e8381-7f85-43d1-8a40-c94030a76439',
    'identification_number': 63726432,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1492b547-5566-40ff-9586-17ab6302de90',
    'identification_number': 43609902,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74333c6f-421f-40df-9255-01fccdc2110c',
    'identification_number': 104920009,
    'item_consumption_1': 603,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 234.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c874103d-2305-477e-a435-bfbfa4e3ed4e',
    'identification_number': 65685326,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f162fa3d-a5c8-4666-9ba6-ebe1f4e3bda5',
    'identification_number': 63896931,
    'item_consumption_1': 33.75,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 981.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'aa8c41e6-2440-4a75-b371-763bb95c738f',
    'identification_number': 45017336,
    'item_consumption_1': 138133,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 51405.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91c442bf-07b7-4838-8a98-d87dc209f350',
    'identification_number': 59820535,
    'item_consumption_1': 503,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 213.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c7aaca6-4d00-4aa3-933f-d92a45403c04',
    'identification_number': 16003500,
    'item_consumption_1': 19193,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 7742.42,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': '06a0aefc-a3cc-44d4-8c42-62aa88395a8f',
    'identification_number': 105106151,
    'item_consumption_1': 1141,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 484.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc7ff00b-2923-4672-b6a1-5b4493fc207d',
    'identification_number': 109018451,
    'item_consumption_1': 31,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 12.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '15ebf12b-156f-4067-a8bd-cb1d74d7554a',
    'identification_number': 75037653,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38b00df0-af3f-4928-8329-65c8828ae3b8',
    'identification_number': 19686560,
    'item_consumption_1': 329,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 139.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec47ecc7-d2c2-432f-a2aa-6ae62e42c838',
    'identification_number': 28801768,
    'item_consumption_1': 3313,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1405.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6331886e-66bd-449e-b9f2-63b3b2ac2145',
    'identification_number': 14210215,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 16.58,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b9a40b8-8a85-4df9-b272-2c2598f25ad8',
    'identification_number': 11380888,
    'item_consumption_1': 1641,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 639.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1a18c27-b07a-4e05-a102-5234e1678011',
    'identification_number': 65399331,
    'item_consumption_1': 3365,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1427.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c687e69-6628-4381-99a6-0bf1968464c1',
    'identification_number': 99990407,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 67.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b47acfb3-7748-4052-9869-27070c181185',
    'identification_number': 83645640,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '33f2f904-e614-4611-80d8-4cd78bb3ca95',
    'identification_number': 15561054,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e158cd43-aad3-49db-a236-942f1bd90cf4',
    'identification_number': 32403011,
    'item_consumption_1': 8.74,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 409.32,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ea823d77-5b03-4b4e-983b-2d56b5d600e0',
    'identification_number': 74703102,
    'item_consumption_1': 774,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 329.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bc8b34c-7e09-4af0-88cc-9395f6da88e9',
    'identification_number': 78143977,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5d182100-09a8-4318-ac24-3015b3e630c5',
    'identification_number': 14562545,
    'item_consumption_1': 9.09,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 216.81,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '34320bef-6e6c-419a-b972-4279ff008550',
    'identification_number': 17435048,
    'item_consumption_1': 1518,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 886.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3b234d3-1ad5-422a-841d-3946f20c9a7f',
    'identification_number': 105277002,
    'item_consumption_1': 31132,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11585.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd49e5546-b55b-40b6-b511-22a7ab5be403',
    'identification_number': 19450303,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3a7cdafd-21df-41eb-840d-569ee3fb0bc4',
    'identification_number': 59264993,
    'item_consumption_1': 1391,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 812.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '968a8a9f-866f-46aa-a20a-b47d9749a61f',
    'identification_number': 31690955,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 469.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e9d6b5f3-bbfd-42e2-aafa-b9bf00f1deb9',
    'identification_number': 99540819,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cc448908-ca1a-4e29-9f55-37ea572e2bc7',
    'identification_number': 77724895,
    'item_consumption_1': 13.7,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 1564.8,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3b56aa52-2241-46b8-a844-d686a8db4d76',
    'identification_number': 108331024,
    'item_consumption_1': 248.47,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 7226.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ec19b3de-e29c-4db3-9bba-0d4d0df85d3e',
    'identification_number': 20587147,
    'item_consumption_1': 46771,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 70756.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81349c08-9e34-4c86-a1cb-bfcf39a58977',
    'identification_number': 52131718,
    'item_consumption_1': 29931,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11138.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23e11f5f-f02c-4987-bd6a-fe9f8e2f0d0d',
    'identification_number': 47567244,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7b3759d7-7f3c-4bfb-bfc7-19e5d4fce72e',
    'identification_number': 99042223,
    'item_consumption_1': 40.38,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 2348.96,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ab94e411-d8ff-49fb-9110-79d94d5f4441',
    'identification_number': 28168631,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6aeaa6e4-d7f3-47f6-8c04-e27a9f10182d',
    'identification_number': 49721690,
    'item_consumption_1': 225,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 95.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f0fba47-ddb8-4b98-ba01-fd1c662c9cc7',
    'identification_number': 105703648,
    'item_consumption_1': 531,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 224.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea18fc6c-3a21-47ea-b057-f6d312935954',
    'identification_number': 107049910,
    'item_consumption_1': 362,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 547.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '229b0c53-4a52-4d7f-996f-0b3d6c0c24ff',
    'identification_number': 56527144,
    'item_consumption_1': 10935,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4069.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f809f90d-2308-46fb-80a0-b9948908dacd',
    'identification_number': 88789586,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87c609e2-2044-4766-8e9a-48f5a2e6c49d',
    'identification_number': 15507971,
    'item_consumption_1': 4534,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1771.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec33783f-0aed-454a-b1ff-2186b5524bfa',
    'identification_number': 40994724,
    'item_consumption_1': 71916,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 26763.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c164d41-795e-4a5a-91eb-f7ac0d2dc53b',
    'identification_number': 35119624,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '899460c6-f15f-45dc-a201-5b1cc643388c',
    'identification_number': 19451512,
    'item_consumption_1': 3144,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 1230.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3db622c0-beaa-4a7e-86d2-07e27cb49ee3',
    'identification_number': 94984921,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29823d29-53f7-49ee-bfc3-ed1b21353398',
    'identification_number': 22509470,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '14b5005b-d8f4-4c6c-8e02-f9cd5078ff6c',
    'identification_number': 104678518,
    'item_consumption_1': 174,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 73.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16817b89-ce2e-4941-9ec1-78d2fe815a1f',
    'identification_number': 109861035,
    'item_consumption_1': 13122,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4883.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57090d7a-27f3-46ce-8679-02347ede4a71',
    'identification_number': 105291242,
    'item_consumption_1': 339,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 143.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f24282fa-2339-413c-93cc-19effc8fffd7',
    'identification_number': 17241170,
    'item_consumption_1': 6354,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2476.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd838d09e-a005-4550-93c8-5f6acb87a4e9',
    'identification_number': 107359278,
    'item_consumption_1': 227,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 96.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a94abf4d-9e65-45c9-a65e-dd7069250888',
    'identification_number': 45412839,
    'item_consumption_1': 463,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 194.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df6c7836-fc19-46df-818c-037dd5e5f008',
    'identification_number': 42162823,
    'item_consumption_1': 4688,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1992.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4cafa13-96e4-4dbc-9cce-6e0e896bdf8c',
    'identification_number': 32098120,
    'item_consumption_1': 5561,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2069.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52bab3d5-a1d3-48ee-aaa5-1be67a16ef69',
    'identification_number': 103992146,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44afc49a-9371-4c42-869c-679b87d3298b',
    'identification_number': 33914028,
    'item_consumption_1': 277,
    'item_description_1': 'IMPORTE CONSUMO',
    'item_total_value_1': 236.17,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'ded3107e-6cba-493f-8f6a-75be83ecd381',
    'identification_number': 12399752,
    'item_consumption_1': 600,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 350.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fe0488e-8d5d-4b9b-add9-77806267d2d4',
    'identification_number': 36385352,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 610.1,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'db610d8e-cebe-4a7c-a9a2-9d4e560b86d4',
    'identification_number': 99353598,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '02381d5d-9c05-408b-9dd1-15737af06c7c',
    'identification_number': 110977360,
    'item_consumption_1': 140.94,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 3361.5,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '51dd77cc-0a1d-47b9-b695-a1165467f7ed',
    'identification_number': 87456583,
    'item_consumption_1': 1426,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 557.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '038bb383-bec5-489a-aeb9-9332f286f841',
    'identification_number': 100394060,
    'item_consumption_1': 275,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 107.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5faebe47-00a2-4bac-b62b-66e8de1e9d64',
    'identification_number': 91588103,
    'item_consumption_1': 387,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 164.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a56a1dbe-204c-4f07-8474-93346de2c405',
    'identification_number': 43750583,
    'item_consumption_1': 136269,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 50712.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5022354-afe5-40ce-a7f8-2ef837528792',
    'identification_number': 15966798,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c6e96ebf-3e72-4b37-adab-d2804b1fd496',
    'identification_number': 108668614,
    'item_consumption_1': 23526,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 9192.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9e4f7a6-c2da-4e53-a6ec-27757225ab9d',
    'identification_number': 46394974,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 127.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b938ce74-86b2-44c7-939e-887cac461f19',
    'identification_number': 94496919,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 54.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f8cc33d1-509c-466b-afe7-ba60087a5c69',
    'identification_number': 8115532,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0becbc9d-7541-4d69-8631-0b13bc8e6cd4',
    'identification_number': 75299372,
    'item_consumption_1': 16266,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2076.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '698afc5c-0eda-4777-9fb3-1b3c4e21b24b',
    'identification_number': 923309925,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38d915d1-318a-4c3f-8a1f-544766449dca',
    'identification_number': 108206572,
    'item_consumption_1': 1119,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 437.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60ada21d-2d36-4e3b-a7c8-d859d5343c0d',
    'identification_number': 31923917,
    'item_consumption_1': 21595,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8036.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '388e74f8-fb50-4a95-828f-0aa99a28dfeb',
    'identification_number': 101940122,
    'item_consumption_1': 7593,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2825.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48d07f5f-b54e-4ed4-b35d-00a95b2127b0',
    'identification_number': 948743454,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fdf7c8eb-6854-42ee-ae4e-880056132808',
    'identification_number': 18787487,
    'item_consumption_1': 2268,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 963.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d0b06e4-4dc2-4503-a10a-2cf0c413bd74',
    'identification_number': 91481139,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1745ee26-de6d-4214-a77e-ff0caa560160',
    'identification_number': 37134191,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a5ec3d1a-f191-419c-9581-f0248ad55448',
    'identification_number': 33670420,
    'item_consumption_1': 499,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 291.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6ad7ed1-3cb3-40f0-8c34-bb5c4ef037c2',
    'identification_number': 30298482,
    'item_consumption_1': 2743,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 350.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f173bbd1-64a1-4928-abeb-9de6b7264a91',
    'identification_number': 40855856,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a15e570b-bff8-4878-9147-a90a251412cf',
    'identification_number': 38749645,
    'item_consumption_1': 39740,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5072.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd31b7fc-7623-4c3d-a928-345b0b539536',
    'identification_number': 22640061,
    'item_consumption_1': 12688,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4949.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7886765a-f203-4977-95bc-74e290eda708',
    'identification_number': 10647422,
    'item_consumption_1': "'-833.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-290.09",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'b1427e4b-b8b5-4d13-b84f-e2af1b177e63',
    'identification_number': 33403210,
    'item_consumption_1': 5594,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2369.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f6968bb-bcf4-4aa2-bbd7-b511f4d0cff1',
    'identification_number': 19307098,
    'item_consumption_1': 2788,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1183.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4dc1faa-f544-4034-bbd7-2136d1ef40d1',
    'identification_number': 64167364,
    'item_consumption_1': 4.18,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 99.71,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a42b71c7-ea25-4f66-8795-c6372c096b8c',
    'identification_number': 103025758,
    'item_consumption_1': 21.79,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 633.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e01db27f-fc38-4fc8-ac89-b9130a78eaf3',
    'identification_number': 8669490,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-67.98",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'f98ebe16-d27e-47f3-82ca-a09aa10f96ff',
    'identification_number': 91057728,
    'item_consumption_1': 2764,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1076.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e214ed9f-3be7-41d5-857d-3f873c70f85c',
    'identification_number': 9822950,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4699.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fa608e7-e13a-4983-a509-5dcf1f784dc0',
    'identification_number': 7677359,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 120.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9de21f69-605f-4e20-9ec1-061150979361',
    'identification_number': 13010476,
    'item_consumption_1': 1015,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 129.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35e2e885-32b5-4975-895f-6349b5cc3cf0',
    'identification_number': 110977360,
    'item_consumption_1': 19722,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2517.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a741067-a0c2-4ba9-9cdf-8086fd75cd03',
    'identification_number': 18229077,
    'item_consumption_1': 4.15,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 194.36,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'bc7b0292-06c4-4e1b-b9c6-a501334a7f7d',
    'identification_number': 78962188,
    'item_consumption_1': 23.54,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 561.45,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd4e0e2e9-e270-4df7-9277-e6b5f2b4d575',
    'identification_number': 98802739,
    'item_consumption_1': 80.54,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2342.57,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cb7ce524-a5fb-43c2-adb5-a23521bdbf27',
    'identification_number': 13010476,
    'item_consumption_1': 8062,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3000.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6ed226f-dfbd-475c-9fd1-f3685d8400b9',
    'identification_number': 106708120,
    'item_consumption_1': 7.5,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 178.9,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd98ed900-db80-4531-ab32-a66e658e4d4a',
    'identification_number': 107922983,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '39aff432-6811-47cd-9fb9-e224e61c6c81',
    'identification_number': 49721690,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0da39e96-0080-4143-a2c8-348f92b74889',
    'identification_number': 44126891,
    'item_consumption_1': 2135,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 833.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7cff04b2-1624-403f-9852-3ee0fbdf7e74',
    'identification_number': 17665868,
    'item_consumption_1': 3025,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1178.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '501fd8d1-95ea-464e-a288-6377454e5db7',
    'identification_number': 102560935,
    'item_consumption_1': 386,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 225.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2d17be8-5555-48e8-ab3b-f3b253e30032',
    'identification_number': 18559980,
    'item_consumption_1': 2662,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1038.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e352bda6-081b-4e74-a845-603646852928',
    'identification_number': 36648329,
    'item_consumption_1': 36,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 14.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a277784b-ff16-4715-b612-f2638d0827dc',
    'identification_number': 8899738,
    'item_consumption_1': "'-1.083",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-377.2",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'c0dbcc88-1613-4fcf-b844-0e5f83eea26c',
    'identification_number': 41381823,
    'item_consumption_1': 29.44,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 702.17,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e508a0cf-d39c-4e4f-b70c-c9ee55cc8950',
    'identification_number': 70000662,
    'item_consumption_1': 2234,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 945.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f069eeab-ea6d-4a77-8721-602388f28771',
    'identification_number': 96849711,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '59d70275-ba62-419f-8be5-36486a5df5b9',
    'identification_number': 22358609,
    'item_consumption_1': 39660,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14759.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f667acbc-f4c8-4b1b-b8d1-38b5d85d13de',
    'identification_number': 48339610,
    'item_consumption_1': 6196,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3618.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8631800c-b2c1-4097-8d83-1fbfff50c655',
    'identification_number': 20387954,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aad68c92-bb07-4930-b171-b95e27a06e8f',
    'identification_number': 43814166,
    'item_consumption_1': 19011,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2426.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae0d8106-360d-47fd-8908-afe5a25b00d6',
    'identification_number': 107540860,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '05eb8034-8da9-490a-b123-96f53f448daf',
    'identification_number': 56697198,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3d918258-6a13-48d2-8108-640c43aec552',
    'identification_number': 97779113,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1075.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a97f0691-9f32-4fd2-94ee-44557aae628b',
    'identification_number': 44774494,
    'item_consumption_1': 180326,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 23016.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4718d4c4-3cec-4406-9cb5-afefbacc7ced',
    'identification_number': 21817405,
    'item_consumption_1': 6767,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 863.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd5d8f23-61a4-4a8e-91ab-9ad78ac24383',
    'identification_number': 105010286,
    'item_consumption_1': 4274,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1669.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e9eb530-d729-4da2-af71-a9f2fc500ed9',
    'identification_number': 46364013,
    'item_consumption_1': 0.37,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 8.84,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0727ed8a-c5e5-4c83-a9dd-b22eb44c786c',
    'identification_number': 101006675,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '862bc3ed-fbdf-4689-b2d1-a73369e36230',
    'identification_number': 104124555,
    'item_consumption_1': 1719,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 671.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d6714ea-50a2-44ac-99ce-1f0a22075c40',
    'identification_number': 33799733,
    'item_consumption_1': 121,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 47.33,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '62f7f608-e8be-4b90-a7c6-182018f04afb',
    'identification_number': 95765840,
    'item_consumption_1': 9710,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1239.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d487608-4e95-47e6-8d83-cd00fd9784a3',
    'identification_number': 97005304,
    'item_consumption_1': 624,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 244.15,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f48a2b3f-9414-4d26-91aa-4483e762fb97',
    'identification_number': 103120114,
    'item_consumption_1': 60,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 23.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b60c548a-914d-444f-b9c7-1767db601faf',
    'identification_number': 16105672,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27e39f7a-23f9-4012-a95a-b40cf59531c7',
    'identification_number': 69441952,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '58fcb36b-23a9-405f-9b66-de1dd5d5cedf',
    'identification_number': 92938418,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ffb09b3b-a3bd-4483-ac24-9b5aa68777b8',
    'identification_number': 100228500,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.93,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ba6a19b5-f172-4f75-b1ac-5e18f108e81f',
    'identification_number': 5345227,
    'item_consumption_1': 4094,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1734.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f13e91e2-e28f-4caf-a70b-5c56787c56e8',
    'identification_number': 106729136,
    'item_consumption_1': 304,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 128.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '245c7fa8-0e4a-4a49-82e8-a7df6d5fff53',
    'identification_number': 83623256,
    'item_consumption_1': 50605,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6459.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab9aeba8-61c9-49f7-8e15-028466324b41',
    'identification_number': 109793692,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ce0d7cd-645a-495d-8140-6430a8c22993',
    'identification_number': 77724895,
    'item_consumption_1': 73.73,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2144.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a9ef4a30-295f-4e4d-8828-4c37398d8aef',
    'identification_number': 102481172,
    'item_consumption_1': 483,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 730.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ef15f83-17a7-440e-8812-f2c7eadd0384',
    'identification_number': 110994809,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3f6d5728-be40-4ea5-98a5-0153ee4e6753',
    'identification_number': 32845570,
    'item_consumption_1': 21752,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2776.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84d9d869-c077-425e-a3a5-03415eb26fe3',
    'identification_number': 105957410,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc9f21d8-ea61-43fe-aae2-ff619660f1c9',
    'identification_number': 19514247,
    'item_consumption_1': 3973,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1553.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2623e5e0-0453-4b6d-b465-086ba7187b53',
    'identification_number': 98370200,
    'item_consumption_1': 4.8,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 224.79,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '015c1f01-c90f-47af-85d1-62393c773abf',
    'identification_number': 26422590,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bbac768-cbed-417d-9d4b-3e3673310102',
    'identification_number': 936267839,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 41.58,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '54fe2892-81f5-412c-af70-63c4845ca8b6',
    'identification_number': 28180240,
    'item_consumption_1': 4509,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1757.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8f9bd3e-bf1c-4aad-a380-0f90fac2e564',
    'identification_number': 6164536,
    'item_consumption_1': 2519,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 981.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39c40d32-b168-4461-b153-a527faf9b3cd',
    'identification_number': 48004936,
    'item_consumption_1': 33342,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12408.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fbdeed7-4bd5-4460-ad2a-6ab994bf957f',
    'identification_number': 109139402,
    'item_consumption_1': 4865,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1810.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c4e34b9-ccd0-4a41-87f4-a6dec0a523ba',
    'identification_number': 19450745,
    'item_consumption_1': 759625,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 282692.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed0b5a61-b80e-4cfe-b95b-2a0256197f65',
    'identification_number': 68904401,
    'item_consumption_1': 126,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 49.29,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '31c02898-79e2-4bf4-b493-ad66dff6f9dc',
    'identification_number': 17919657,
    'item_consumption_1': 4071,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1589.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97919d4d-c68c-4b6b-97ac-2821d96dd49d',
    'identification_number': 106613979,
    'item_consumption_1': 2043,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3090.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79912e92-14a0-4c6a-bcec-238fdf49043e',
    'identification_number': 68007493,
    'item_consumption_1': 182,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 275.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ef518d8-4014-4d4a-a555-0f2b2e475fa6',
    'identification_number': 80146139,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 2200.86,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'a8c10579-81b1-470b-b423-45bbc416bbf5',
    'identification_number': 12683841,
    'item_consumption_1': 5678,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2212.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8010569c-24e3-4425-82d3-40dccebd919a',
    'identification_number': 109697693,
    'item_consumption_1': 4.32,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 103.05,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '93164979-0690-46ea-a320-45468a5f2672',
    'identification_number': 22616519,
    'item_consumption_1': 4120,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1746.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '797555b7-4edf-485c-9896-f54fb14abb77',
    'identification_number': 16485262,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 92.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9a83012b-7272-4582-9ccd-62119f6cf963',
    'identification_number': 101835981,
    'item_consumption_1': "'-13.131",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-4203.66",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'e2087b0b-4a25-40ce-9b89-e39780e77236',
    'identification_number': 109495470,
    'item_consumption_1': 2500,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 930.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29cb84d1-6c2f-4793-a13d-5224baa35907',
    'identification_number': 15016005,
    'item_consumption_1': 7755,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3025.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4bfe8ab-ff87-4328-b586-d8b172a30000',
    'identification_number': 18539505,
    'item_consumption_1': 1838,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 717.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2147260-b1c1-4e7b-b148-e5f92557e613',
    'identification_number': 65191404,
    'item_consumption_1': 21.89,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1250.12,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '665b14d4-54c0-43da-9c82-a4245b19b4f1',
    'identification_number': 17633419,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a7598aa7-9799-413d-9cef-087f50802ac5',
    'identification_number': 96849754,
    'item_consumption_1': 190,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 74.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23d81ad1-faf2-4a41-95a3-5e16b00d6d0c',
    'identification_number': 12775240,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40f206c2-ad9d-4630-8a19-7e34646a99e8',
    'identification_number': 81859945,
    'item_consumption_1': 39.26,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1141.89,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2e7fe6b6-694f-4048-866b-fbae7f77cf01',
    'identification_number': 77279719,
    'item_consumption_1': 16,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 6.25,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '738b5412-19f6-41b0-8466-2dc029829ee9',
    'identification_number': 45431108,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ffda19a-7319-4349-847c-0be117d75bd2',
    'identification_number': 91394031,
    'item_consumption_1': 6.76,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 161.24,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4d8e0079-bb14-4f1e-8585-90678c5c527d',
    'identification_number': 97675237,
    'item_consumption_1': 244,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 95.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5aa1d6d2-ccb2-4a12-91c1-0d796b787a2b',
    'identification_number': 103561048,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f753c77-3faf-4ae2-b953-3223e853648a',
    'identification_number': 81333480,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '711121ff-c302-4257-abaa-d4168d8de6b7',
    'identification_number': 8669490,
    'item_consumption_1': 306,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3eb56e63-695f-4f8d-854c-df2f6923e14b',
    'identification_number': 43505554,
    'item_consumption_1': 3935,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1538.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06f6c4ea-5772-4653-82d8-80725390aa25',
    'identification_number': 100318550,
    'item_consumption_1': 8649,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3376.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47108963-0df6-47c4-85ea-3da58be93165',
    'identification_number': 92735746,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4d46de80-3e89-4871-b1ce-7c29e1c0c457',
    'identification_number': 97970034,
    'item_consumption_1': 1949,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1138.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7a21f52-24e6-4687-952b-b542df438346',
    'identification_number': 16882881,
    'item_consumption_1': 2150,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 837.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4efd3032-900e-4fcc-8b31-ec7c62f8c14f',
    'identification_number': 25617737,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 0.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eeb3d21b-c2bd-414e-b107-c3424ca59b45',
    'identification_number': 105202878,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b5e2407-8fb2-4a03-a939-d81315b6f5cd',
    'identification_number': 91640504,
    'item_consumption_1': 1049,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 444.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4634251e-5d78-4dd1-bd71-fe68837c0d87',
    'identification_number': 32627572,
    'item_consumption_1': 27924,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3564.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39292a36-57b1-4257-8f7b-322d45d39581',
    'identification_number': 45907137,
    'item_consumption_1': 14.63,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 425.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '34a2354d-3096-405e-a31d-7adaa2505c6b',
    'identification_number': 38525275,
    'item_consumption_1': 3906,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1660.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be606f16-889d-4ec9-97a7-56402db2286a',
    'identification_number': 81809883,
    'item_consumption_1': 899,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 351.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a513b9bf-ae93-4a5f-a6aa-a8493fee60f4',
    'identification_number': 72858710,
    'item_consumption_1': 1388,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 810.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6bfac86-fc3c-4866-9dbb-8d6f899e9f7b',
    'identification_number': 19005210,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3daaa464-a2f9-4b8d-8319-63ed8da72687',
    'identification_number': 17969743,
    'item_consumption_1': 2701,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1147.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53adf7fc-309e-4b74-935b-521e49005b54',
    'identification_number': 102913102,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 61.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7abcb806-3f28-4d00-96b9-36048bd69e75',
    'identification_number': 46424792,
    'item_consumption_1': 1716,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 668.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12b17bfe-cef2-4588-9511-fa050582f994',
    'identification_number': 96692979,
    'item_consumption_1': 5087,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2970.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e2e6c8b-0f34-466d-af2c-0664b6503222',
    'identification_number': 88926923,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '57ca69f3-99d4-43ca-9d60-794ce6e28db4',
    'identification_number': 16165543,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 145.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0c3a72ef-8195-48b6-bd80-72128839132a',
    'identification_number': 19310609,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b60c6bb-d9d3-449a-82e0-723874fa3b47',
    'identification_number': 14225093,
    'item_consumption_1': 51.41,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2936.01,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2ac39603-7cb3-4125-aaa8-5248b6c28378',
    'identification_number': 90453450,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ecfe8dbc-430e-4748-8a21-b30a04cb818e',
    'identification_number': 11117540,
    'item_consumption_1': 197,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 77.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fec8496-ac81-43a5-a758-1b162bb16de9',
    'identification_number': 20304137,
    'item_consumption_1': 19140,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2442.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50acc9b2-801f-4d2f-a777-c882328a3cc8',
    'identification_number': 78329051,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd7e55a60-9bfc-4c1e-b1b0-1a4f4116d08a',
    'identification_number': 55524567,
    'item_consumption_1': 7,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 2.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '570e5b60-97ba-4bfd-9c81-cc0b21650280',
    'identification_number': 100726704,
    'item_consumption_1': 1775,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 753.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a19defc0-bf36-4cac-b010-4ca89e2ecb28',
    'identification_number': 16632702,
    'item_consumption_1': 2515,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1067.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6b97db7-c51e-49b0-8103-72662e14669a',
    'identification_number': 104179228,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1304.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '429bcb23-48c8-4bcd-8e1c-16729948f02c',
    'identification_number': 99590786,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e8b36a7-8312-42a9-a83f-966df712e7f9',
    'identification_number': 36794392,
    'item_consumption_1': 14434,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6136.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1a21c61-1dbd-4fea-bbbc-45588d1cc4f7',
    'identification_number': 79730701,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 156.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'df9783d9-4a9c-43dd-a6e8-90408f2156a3',
    'identification_number': 47766336,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 45.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ac8edd02-91a1-423f-a0cd-841c2c547d22',
    'identification_number': 80072844,
    'item_consumption_1': 1210,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 472.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28c2e1e2-b530-4fc3-bf5b-38b7d89c2d11',
    'identification_number': 9788131,
    'item_consumption_1': 12.63,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 591.49,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2f331e7b-5178-4932-bbe3-bca8c562d3ce',
    'identification_number': 39545377,
    'item_consumption_1': 1398,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2114.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7965c4e3-34c1-4174-b17e-3ed063786044',
    'identification_number': 86207563,
    'item_consumption_1': 11953,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 18082.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb912771-7d99-46c9-9b7e-15f5a3f236af',
    'identification_number': 12775240,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3079dab-1fce-4715-a979-407649381b06',
    'identification_number': 103877266,
    'item_consumption_1': 1099,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 465.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd04854ec-26dd-4419-9b45-76ee1c1c779c',
    'identification_number': 103721835,
    'item_consumption_1': 17005,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6328.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ae2c44a-1741-429a-a2d7-fc32fe88554c',
    'identification_number': 72733748,
    'item_consumption_1': 10947,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4265.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28b3cfe6-7f92-4cd1-a009-e1c9628d9732',
    'identification_number': 107466732,
    'item_consumption_1': 9.58,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 228.5,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2bc7f0a7-c36d-44d2-844c-4fc2af77aeca',
    'identification_number': 79545793,
    'item_consumption_1': 2973,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4497.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64e2bbf2-5ed3-4265-9449-9bec9b1d330e',
    'identification_number': 36200697,
    'item_consumption_1': 263,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 102.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4c58867-6f75-4f8c-ba0b-72398ceadb43',
    'identification_number': 96625104,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f5c6af2-f2b5-4702-833e-0e3f1a80981f',
    'identification_number': 33204071,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 116.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5eecdb1c-b31d-4f98-8d59-9198c0d1f847',
    'identification_number': 70701989,
    'item_consumption_1': 5576,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2174.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '143e533c-f7e2-48a0-ab3e-178ba1561e1f',
    'identification_number': 14071240,
    'item_consumption_1': 42,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 17.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db29ab16-023a-4ed7-8ad5-744c798d6a36',
    'identification_number': 110200080,
    'item_consumption_1': 17818,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7555.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bf8e8bd-a321-450c-ab93-c5d36c3fb1f9',
    'identification_number': 102507864,
    'item_consumption_1': 493,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 287.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24f060bf-13b7-42f4-b71c-d6f040474e96',
    'identification_number': 17933404,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 18.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3df9da01-d8c6-4f4d-bc37-e0ab35cb3375',
    'identification_number': 68902824,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14897.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '184a575f-f7bd-4f4f-a251-32a1b163ec7d',
    'identification_number': 98351036,
    'item_consumption_1': 1708,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 667.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2004bee9-a20e-469e-960f-6507ad6c0821',
    'identification_number': 62508679,
    'item_consumption_1': 14.56,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 347.27,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1ab4b93f-4c24-482e-b30c-f0599c2d1336',
    'identification_number': 36948209,
    'item_consumption_1': 2692,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1141.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb6ad44f-0958-457a-ba7b-5c6d353b6a1f',
    'identification_number': 91976715,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58994519-8797-471b-95a9-852d3a11ac68',
    'identification_number': 15447790,
    'item_consumption_1': 1048,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 409,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '441982f5-9e40-464d-af0b-9c02f601ddb2',
    'identification_number': 62986724,
    'item_consumption_1': 11227,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4382.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41f4d899-478c-49d7-9d58-895b13bbac2c',
    'identification_number': 48339610,
    'item_consumption_1': 0.91,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 21.72,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '04080088-feca-4dd1-95db-c2dabf1c1986',
    'identification_number': 14354306,
    'item_consumption_1': 8154,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3034.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '019003ca-ea4e-47c7-a527-36f31ba64826',
    'identification_number': 92763219,
    'item_consumption_1': 44512,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5681.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b94c9845-384c-46b2-84f5-4967975323f4',
    'identification_number': 100765076,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO EM DOBRO',
    'item_total_value_1': "'-4863.96",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '40e22e48-235f-4e6d-92f7-01932868d790',
    'identification_number': 92881548,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.99,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0f6e0220-75ee-4992-9bf9-fd78ac54a1a9',
    'identification_number': 92673872,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb068c75-3d23-4ccd-9245-cdab6c434833',
    'identification_number': 41314336,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e0f1865-82fc-46c1-8453-e4fc12eccbfc',
    'identification_number': 109859154,
    'item_consumption_1': 1654,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 702.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43338ccf-644f-40bb-ac7a-94e9a6078f93',
    'identification_number': 108298035,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2569.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a6d4fef-2b93-4faf-9002-f6385e327085',
    'identification_number': 18209793,
    'item_consumption_1': 2637,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1028.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64716344-e593-4161-bdbd-cff2d3e400a3',
    'identification_number': 19611439,
    'item_consumption_1': 5084,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1980.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6077bba3-0c80-4057-9dc1-a8799f3f08ee',
    'identification_number': 48875325,
    'item_consumption_1': 82.7,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2405.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '28d5c9d3-00f2-4606-9a8f-e81f06d621e8',
    'identification_number': 35479833,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7b8e5fb-7fc2-43d5-87ac-09014423df17',
    'identification_number': 107173115,
    'item_consumption_1': 1249,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1889.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a91f14ee-fc4f-4d8d-83e5-083fe991f200',
    'identification_number': 101073593,
    'item_consumption_1': 3.38,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 80.63,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1c10647c-70b2-4b93-b171-bc5f7320a89e',
    'identification_number': 109863887,
    'item_consumption_1': 2041,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 865.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c1d4fb6-3562-4be6-8e9c-e95da804a47e',
    'identification_number': 22922521,
    'item_consumption_1': 2.611,
    'item_description_1': 'CONSUMO FORA PONTA TE',
    'item_total_value_1': 911.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05faad1b-18b0-430b-9138-354868297c75',
    'identification_number': 13296523,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea4ff4a3-4d6b-41b3-9506-85c7d09368a2',
    'identification_number': 18898068,
    'item_consumption_1': 127.84,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 7436.69,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'c22da792-0a85-4454-8610-6c6494edbfe3',
    'identification_number': 110527348,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6a62e38f-713d-4a38-80df-89a6c3983b6f',
    'identification_number': 105760480,
    'item_consumption_1': 445,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 188.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6cc8cdba-9998-49e8-9836-4e816e8ee167',
    'identification_number': 103620788,
    'item_consumption_1': 7252,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3070.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e596dd73-9d6a-4bcb-9950-1940895e4cd3',
    'identification_number': 39492265,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '851416d7-5f4c-47f6-9913-4c73b2c9f3c8',
    'identification_number': 92850030,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '101afae7-583a-4c1e-82e1-70d667d77fee',
    'identification_number': 102890269,
    'item_consumption_1': "'-693.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-222.05",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '331a2857-3afb-4d6c-91d9-cba79e2276a5',
    'identification_number': 101591039,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '553566eb-f1c8-4a99-93f1-bcf833dae241',
    'identification_number': 83623256,
    'item_consumption_1': 4960,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7503.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8620d61d-c00a-424a-9f2d-e2612498d977',
    'identification_number': 93067895,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5dfdebd8-1dbe-452f-8ada-bcb2a826700f',
    'identification_number': 93748604,
    'item_consumption_1': 2598,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1100.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddd4b571-a91e-4253-b7ff-6b9b9497cff8',
    'identification_number': 70574502,
    'item_consumption_1': 3359,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5081.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5aca6f3-3f0e-4089-ae5f-4b934c545cd2',
    'identification_number': 97058343,
    'item_consumption_1': 1461,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 619.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3dbbc9d2-9af0-49a2-b252-9a635b8980f3',
    'identification_number': 23274310,
    'item_consumption_1': 37.68,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1095.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '856b3e37-3a5c-4c2c-a286-ee039ad168e3',
    'identification_number': 68738110,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb2aba84-8664-4702-90eb-fdb9dbf6c80e',
    'identification_number': 21817405,
    'item_consumption_1': 6767,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3952.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ab2ba4e-d4e4-4306-9292-d92937e5ec44',
    'identification_number': 96849754,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b87e312e-9b9a-4699-bc60-d3a25a7cccac',
    'identification_number': 100968422,
    'item_consumption_1': 2978,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1261.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f60c1d1-21ea-441c-bf6d-a0dfad98deb1',
    'identification_number': 25318535,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14474e25-66c3-4f53-ace4-ea7a6cfb27b5',
    'identification_number': 12718750,
    'item_consumption_1': 2462,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1437.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45e3b1f1-c43e-47d0-b2e8-937c6100d275',
    'identification_number': 102159459,
    'item_consumption_1': 667,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 389.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c781b5f-297e-4a64-9ad7-839526de9b2d',
    'identification_number': 42556198,
    'item_consumption_1': 0.29,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 6.91,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6b5f7750-a724-4e8f-ae09-339a04af07e1',
    'identification_number': 50784390,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1058fac9-021d-466b-aff7-78bcee0e7dc5',
    'identification_number': 87246996,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd208794a-bcf3-471c-b00d-49d8ad0872b4',
    'identification_number': 102261016,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6b76be09-e4a9-49a6-b048-58987bdc0fbe',
    'identification_number': 96460067,
    'item_consumption_1': 5356,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2269.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82442c50-da0e-4387-a6ae-070d8b310ed3',
    'identification_number': 31903991,
    'item_consumption_1': 4795,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7253.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0766d3d-d2ab-4a54-9dac-517c77952520',
    'identification_number': 35809329,
    'item_consumption_1': 16,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '857b34f3-716a-4de2-a824-533e7778f339',
    'identification_number': 95839267,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-4.62",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '297c4990-f6dc-48df-80c6-f1d43192d636',
    'identification_number': 106199960,
    'item_consumption_1': 155,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 60.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed3ef405-fd52-4f5a-869f-eef888bd9fd9',
    'identification_number': 70062838,
    'item_consumption_1': 6662,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2604.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33b79e42-18ae-42c1-8ac9-6931eb8abff4',
    'identification_number': 106755030,
    'item_consumption_1': 32003,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11909.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88784d41-1aac-48cd-9b68-19f1735fb27d',
    'identification_number': 100627188,
    'item_consumption_1': 2584,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3909.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2c9aa76-3862-4334-ab81-7678de44839a',
    'identification_number': 59090413,
    'item_consumption_1': 15959,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6226.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4dacd55f-d005-41a9-9ff5-3a3cda5432d6',
    'identification_number': 5683840,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8f4f518d-214f-49d8-839c-ed8ba7c42c2a',
    'identification_number': 31564496,
    'item_consumption_1': 132.54,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3855.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7411a9eb-ad79-4784-bcfa-83f34562994e',
    'identification_number': 3078469,
    'item_consumption_1': 2360,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 999.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9820947-3c7c-485b-8b48-5e5d36ab19a3',
    'identification_number': 83687114,
    'item_consumption_1': 22.33,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 649.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a7640018-6fff-4b48-8afb-170ed092c137',
    'identification_number': 17726425,
    'item_consumption_1': 1711,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 727.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70eda1b3-58e9-40d2-b03d-3069c3186804',
    'identification_number': 72858710,
    'item_consumption_1': 0,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 006/010',
    'item_total_value_1': 1067.83,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': 'c11c1a0d-5436-4b12-8208-a4e40acff8b6',
    'identification_number': 66198666,
    'item_consumption_1': 2210,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 282.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe645716-3ffb-4236-80d7-4300838bf8e4',
    'identification_number': 33799733,
    'item_consumption_1': 20.54,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 961.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '297f8d80-a52d-4fed-b663-7321709f25e7',
    'identification_number': 39099652,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7dc4da55-55f6-43cc-804f-9edf7cf4ee28',
    'identification_number': 109487877,
    'item_consumption_1': 1058,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 617.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18b898b7-47b8-4d97-ac57-50e3c042e54d',
    'identification_number': 15474380,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '32e88fbc-1a0a-4a62-afbc-c6155bfe7f59',
    'identification_number': 104871563,
    'item_consumption_1': 107,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 41.85,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8aceee81-bfc9-41c7-bbd7-7c4af8d70134',
    'identification_number': 107588226,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '085490f2-cb34-4141-a07b-fb6cde1ccd1d',
    'identification_number': 6286755,
    'item_consumption_1': 2824,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1103.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7dea1c98-0620-4de9-bdce-bdd7d75dfd82',
    'identification_number': 70870969,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e65f670e-ac4a-435b-8cd2-622ad964690e',
    'identification_number': 26770334,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ef1092a2-4a5e-4af1-aea9-7c84ae4dc84f',
    'identification_number': 41672658,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2ad5d56-5530-4797-8609-610dd2ae237c',
    'identification_number': 99578336,
    'item_consumption_1': 1062,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1606.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c57e19c-947d-485a-8495-90eb4b0522ae',
    'identification_number': 94487316,
    'item_consumption_1': 9875,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3858.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7846100b-fe99-44de-a71a-30e45873bf1c',
    'identification_number': 109375947,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'df7f8364-c8c5-4717-b981-9c71bdfeb80d',
    'identification_number': 55316883,
    'item_consumption_1': 0.9,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 21.48,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd98a4e07-5b78-4f05-b879-9d818475a8e9',
    'identification_number': 111295459,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9babf102-e375-4d28-9aa4-63e71bf3a341',
    'identification_number': 30290635,
    'item_consumption_1': 1982,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1157.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f8e69de-a007-48e3-b21d-235deb4349cd',
    'identification_number': 47010991,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3f7e6df-f87f-4f9f-80e4-d4d93044dd46',
    'identification_number': 52773426,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd881da39-6174-465d-8947-d0390e4e747d',
    'identification_number': 42712190,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 74.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '889960a2-1615-43c2-a1c9-7e5a791eeebb',
    'identification_number': 32495056,
    'item_consumption_1': 6838,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 10344.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2644dec-603f-44fe-bc14-82f156de27b5',
    'identification_number': 39261565,
    'item_consumption_1': 62.77,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1825.71,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a8f15fbc-a2f3-4831-b76b-808f281a8fa1',
    'identification_number': 94525994,
    'item_consumption_1': 32.86,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 955.75,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '20bcf939-b4ae-454f-91e4-1291923a9331',
    'identification_number': 10790195,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '214378f4-c984-41a5-b33f-9110487073e9',
    'identification_number': 19013256,
    'item_consumption_1': 5360,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2278.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c266539-56bb-4209-b58c-a34fd207036e',
    'identification_number': 64429857,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b93daebe-8084-4e37-8bc6-24c2872e8286',
    'identification_number': 49314491,
    'item_consumption_1': 306,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 119.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84b4e093-8151-4053-b94d-4fdc5fe0615c',
    'identification_number': 14115344,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2f81a612-5c9d-40d7-a72a-a6e69947139e',
    'identification_number': 111198577,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3fb836f9-6650-4d0a-9f99-735d27bec952',
    'identification_number': 40207285,
    'item_consumption_1': 53815,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 20027.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa89b8ad-e0ac-48e9-a02f-1cd7af23817b',
    'identification_number': 97490601,
    'item_consumption_1': 3295,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1396.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b614fd8b-9c12-493a-a3b5-7b14f1ec83b6',
    'identification_number': 8115567,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '308cc391-14b5-4b3a-b5f8-9f5bf5930aa7',
    'identification_number': 26771560,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06eef389-3165-41a4-9bdb-f1ef70387703',
    'identification_number': 12381519,
    'item_consumption_1': 8030,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1024.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f198b0d-8560-4f9b-a72a-882d4b4d490a',
    'identification_number': 17628318,
    'item_consumption_1': 2962,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1243.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0aa69ecd-3851-4688-a1e1-c4d693f378b6',
    'identification_number': 56525354,
    'item_consumption_1': 2955,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1155.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0fb42bbb-0f09-4276-b3b6-f67dfc1f3f8c',
    'identification_number': 72507136,
    'item_consumption_1': 55.35,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1609.9,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd5a8f82c-27a8-45b4-9be1-b8e2c056301f',
    'identification_number': 45159335,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'edc32401-4309-49ae-9926-3d352d7a47f2',
    'identification_number': 936185122,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 67.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f894b464-8063-4669-90d2-1c5b988ef9c8',
    'identification_number': 32234058,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 367.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fb664f16-b919-4b12-98c9-14fc79384d60',
    'identification_number': 84355409,
    'item_consumption_1': 586,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 248.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98a23a83-fd0a-46fc-b8b5-2ec8cb2e1c03',
    'identification_number': 41803558,
    'item_consumption_1': 162,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 63.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '910dedbc-dd54-434a-a78d-14c43623c277',
    'identification_number': 108106128,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 12.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9b34ba2-07a5-4df8-9af1-937559e5d04c',
    'identification_number': 58739092,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd0faa9d-46a7-4027-9851-40c758b75226',
    'identification_number': 109847059,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 41.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72df6e3d-5c04-4639-a0ce-eec88eb1ed89',
    'identification_number': 106536451,
    'item_consumption_1': 189,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 73.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f376454d-0452-474e-b651-0b8a51a34048',
    'identification_number': 59486260,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a06a394b-3d13-4a41-b806-d32ee3f6f495',
    'identification_number': 41803558,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aa429431-e8c6-40ad-a5f1-1644be16ae55',
    'identification_number': 44503660,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c29cfd0e-fb25-4fa3-8500-6419ce8bfffc',
    'identification_number': 18280099,
    'item_consumption_1': 819,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 319.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65f68eab-d218-4ca3-9eb4-4d87d4048f9b',
    'identification_number': 12917257,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '267b9925-c055-40e6-afea-7deaab3be41a',
    'identification_number': 107049910,
    'item_consumption_1': 362,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 211.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ebf22b1-0a52-4686-9637-b808d99fe619',
    'identification_number': 80200052,
    'item_consumption_1': 4386,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1863.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58508442-207b-4b37-8070-9d8077ae3a34',
    'identification_number': 59361859,
    'item_consumption_1': 2133,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 831.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68fb4477-87fa-4207-8f54-8e18723cea2f',
    'identification_number': 95720022,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 67.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e62ff2ce-69cb-4bbb-becf-d773b57d803e',
    'identification_number': 89667646,
    'item_consumption_1': 3874,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1511.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b11aa3f-f141-40f8-b556-f486d482c8b5',
    'identification_number': 102892784,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ee83819e-41a7-4ec5-9548-c4f582db6885',
    'identification_number': 85312185,
    'item_consumption_1': 41.67,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1212,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7e471121-109c-4eac-b228-8dfeab369d3b',
    'identification_number': 108532348,
    'item_consumption_1': 1561,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 608.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eefd7994-6bf8-4e8c-a9b1-29376549d735',
    'identification_number': 20387954,
    'item_consumption_1': 1440,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 561.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73cb7297-fdce-487b-a993-fcaf23c91a78',
    'identification_number': 111114926,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '756277fb-4df3-4c38-b187-4442c1249e33',
    'identification_number': 17742935,
    'item_consumption_1': 5076,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1982.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '540d3d8f-9392-47b5-ae76-f7c9510910b2',
    'identification_number': 47290897,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c8094612-df47-4f7b-86ed-4c818f0a9ba7',
    'identification_number': 20540205,
    'item_consumption_1': 220,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 93.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3823269b-8e9a-4406-bdb9-97cb21f911a4',
    'identification_number': 60092904,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3a8c2909-9279-442f-82c4-017f8271074e',
    'identification_number': 9921273,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b54e5aaf-b384-4af2-9e20-5b9b5fbea1b9',
    'identification_number': 45485569,
    'item_consumption_1': 848,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 330.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21b77f3e-8c55-464f-bc79-fca7cc96e01f',
    'identification_number': 105284556,
    'item_consumption_1': 1245,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 485.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35275fe6-2101-444f-9213-5e39a36476c8',
    'identification_number': 96771410,
    'item_consumption_1': 673,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 262.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd45df1d4-ee4f-4187-9756-4c5375234932',
    'identification_number': 91394031,
    'item_consumption_1': 36913,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4711.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20d5ea29-6436-47a1-bb93-609505852341',
    'identification_number': 18616909,
    'item_consumption_1': 43.81,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1274.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dde12f99-fe39-4e4b-b0c9-851cb6d03df6',
    'identification_number': 53869303,
    'item_consumption_1': 25378,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3239.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ac28215-bf0a-4194-b5b9-c2246220a4c3',
    'identification_number': 18009492,
    'item_consumption_1': 3046,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1295.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3c01343-4f3a-43aa-9df0-9558108f53f1',
    'identification_number': 12275468,
    'item_consumption_1': 42.95,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1249.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f1257366-a3bb-4b52-9c92-756420ea8fa1',
    'identification_number': 32594852,
    'item_consumption_1': 653.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 19008.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '357a1e38-6384-4419-a859-36fe3a797415',
    'identification_number': 91393973,
    'item_consumption_1': 53.13,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1545.31,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c03bb5db-2dee-42eb-abf9-b0fbd480ad44',
    'identification_number': 9834001,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b333f132-01d6-4308-a9c5-5c170902ebad',
    'identification_number': 98913972,
    'item_consumption_1': 6.88,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 785.83,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0bfbe556-35be-419d-8ae2-abff6b94ba4f',
    'identification_number': 101073593,
    'item_consumption_1': 3.73,
    'item_description_1': 'DEMANDA REAT EXCED USD PONTA',
    'item_total_value_1': 108.48,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'deca7587-7238-4fcf-81d5-8104b6c669dd',
    'identification_number': 77178572,
    'item_consumption_1': 1466,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 622.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26739d5d-065f-49cd-b9bb-69757a491a97',
    'identification_number': 8992053,
    'item_consumption_1': 3118,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1319.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0c04dc5-fe48-472c-8407-5609ada60617',
    'identification_number': 14671514,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-709.9",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'bbf5633f-4d82-4c24-947a-6b1f21e44aaa',
    'identification_number': 101073593,
    'item_consumption_1': 4.17,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 195.29,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'df78d155-a0b4-4c3f-b52d-8ff9a74df5d6',
    'identification_number': 101073593,
    'item_consumption_1': 44898,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16708.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d36f5d5-47f2-484e-ba9e-6f0510a8b9f8',
    'identification_number': 97425907,
    'item_consumption_1': "'-252.0",
    'item_description_1': 'ENERGIA INJETADA FP TE 11/2023',
    'item_total_value_1': "'-76.89",
    'reference_month': '11/2023',
    'referente': 'Compensação crédito energia',
  },
  {
    'uuid_items': '6e74f60a-6e7e-44d1-8dcf-9d647d0a3a32',
    'identification_number': 14671549,
    'item_consumption_1': 431,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 652.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8f1c79a-0fba-45fe-bf5d-d8977d7a4c66',
    'identification_number': 107350840,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '71f030ca-6653-4f84-83c9-b45db081f373',
    'identification_number': 74277103,
    'item_consumption_1': 83,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 32.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1546ad90-1ab0-4cce-b95f-290fa8036acf',
    'identification_number': 28168631,
    'item_consumption_1': 4553,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1773.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d66c021-32ac-4368-b9d3-d15db0499213',
    'identification_number': 13299530,
    'item_consumption_1': 18367,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6835.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9aba1247-34c4-4b32-b116-d0931040940b',
    'identification_number': 98662007,
    'item_consumption_1': 1204,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 469.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6bd24c5-62ef-462f-bcdd-c2f486ccae70',
    'identification_number': 15924866,
    'item_consumption_1': 9910,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4194.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41575572-3c6f-4636-879e-e57c499536a8',
    'identification_number': 15572862,
    'item_consumption_1': 55,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1599.72,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '687dfa0d-6993-4c2f-9bb4-ffb4c4a9d2ba',
    'identification_number': 34086242,
    'item_consumption_1': 1250,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 488.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19ddc6ea-696a-42c5-9ba7-785d3a1946d9',
    'identification_number': 18485774,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '36b2843b-ae3e-4f05-9a82-0a4e6a8effdb',
    'identification_number': 91804698,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30d0f3ef-68b5-48b3-82d2-8c179e2b8e16',
    'identification_number': 15645835,
    'item_consumption_1': 14648,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5451.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c25bf22e-34ac-4b36-a7d1-759349c4c7df',
    'identification_number': 15871789,
    'item_consumption_1': 6777,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 864.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'acb5b93a-e404-41d8-840f-4b8af26b699a',
    'identification_number': 13372688,
    'item_consumption_1': 1138,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 664.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f875fcc8-5e2b-4f1d-8c8e-1b4f39104506',
    'identification_number': 75768879,
    'item_consumption_1': 3281,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1916.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '315dfbfb-4369-4b93-aa0e-7b9a95c44fca',
    'identification_number': 57739129,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f10af10-4e18-4e81-bff2-832479d8eee3',
    'identification_number': 38932040,
    'item_consumption_1': 2052,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 869.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2da298f-c95f-46a4-9b95-28bbfed00365',
    'identification_number': 19452900,
    'item_consumption_1': 74.16,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 4314.02,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8e815c5c-e1cd-4b2c-9caf-ee797eb5cfc2',
    'identification_number': 26816458,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cec7d3fd-f010-404a-807d-c1c8f648824d',
    'identification_number': 103876502,
    'item_consumption_1': 1748,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2644.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58b3ee63-7151-44a7-bf8c-e35440539c90',
    'identification_number': 5330351,
    'item_consumption_1': 164.79,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 9411.13,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5bd620ea-f27f-4a09-8cda-56fda6261e13',
    'identification_number': 27925145,
    'item_consumption_1': 1517,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 592.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e95b81e2-daf8-45c0-a569-26beac7ded91',
    'identification_number': 104713585,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 67.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b1d1f278-7907-40d3-8577-ac3f983d52d0',
    'identification_number': 13914839,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '819181c3-021a-413e-a925-a1a1f323ee81',
    'identification_number': 63368005,
    'item_consumption_1': 1,
    'item_description_1': 'luminação Pública',
    'item_total_value_1': 10.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '52988c18-88f6-4611-b425-eec26d64cd8b',
    'identification_number': 96912278,
    'item_consumption_1': 2889,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1127.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a7057f6-2269-4cca-8485-519296c7e63d',
    'identification_number': 107721538,
    'item_consumption_1': 3284,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1391.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b919c844-1fb2-435c-af89-5de1d884a1e3',
    'identification_number': 102429537,
    'item_consumption_1': 292,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 123.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9b3a47d-943b-4c67-b764-d5bde083f7e3',
    'identification_number': 68502982,
    'item_consumption_1': 14087,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5966.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45296c51-de96-43fc-a283-cfee614d6f5b',
    'identification_number': 96305770,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 127.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'da722b3c-dd91-49f9-abe4-08072c0be69b',
    'identification_number': 109460227,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f84d4f7-b039-47ac-8134-f1a9ab79600e',
    'identification_number': 75896974,
    'item_consumption_1': 143,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 55.95,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1c9177e9-21d3-4365-84df-1ef538d00087',
    'identification_number': 38227436,
    'item_consumption_1': 1922,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 750.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1e92247-2c29-4b3a-9981-a114c45926be',
    'identification_number': 23562200,
    'item_consumption_1': 15960,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6224.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48cd6ed5-a20e-4cd2-927f-b5a85508e824',
    'identification_number': 18898602,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b672f4e-28ce-4db6-80ae-5d7122122572',
    'identification_number': 93760175,
    'item_consumption_1': 1946,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 824.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d28e869-7460-4524-b720-1ec3fa57683a',
    'identification_number': 97837350,
    'item_consumption_1': 29327,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10913.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eda7aaf4-0afa-41dc-8c0c-e2b45d9f29cf',
    'identification_number': 16574907,
    'item_consumption_1': 2510,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1065.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfc9f9fc-3af6-4846-be3a-5508ffe08ed2',
    'identification_number': 103177477,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82429869-ebb0-4d07-9fb6-1626ba53c4b0',
    'identification_number': 16246780,
    'item_consumption_1': 5.07,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 147.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '201e451e-8444-4621-9d9d-e5b12fc05af0',
    'identification_number': 106706632,
    'item_consumption_1': 60.49,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1442.73,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'eb9ee527-a60a-48fb-9de0-039bd711e418',
    'identification_number': 13977873,
    'item_consumption_1': 1234,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 157.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ff1bec0-873d-452a-8aa2-036a1a772c9b',
    'identification_number': 62508679,
    'item_consumption_1': 643,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 375.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ebf2093-bde7-4130-aa05-3491671ff211',
    'identification_number': 47074965,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8f987ffb-ec24-48dd-ba39-030c962685a2',
    'identification_number': 39196461,
    'item_consumption_1': "'-2.091",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-669.82",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '5054ce8b-8ab8-4232-aeeb-175ce7d6a24d',
    'identification_number': 33162557,
    'item_consumption_1': 13.47,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 321.27,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f7305f0c-d8a5-4fa7-85e9-e329a2629b3d',
    'identification_number': 104614285,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4576.37,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8e08abaa-eeb4-4fa4-9d1e-d3cb993f6fff',
    'identification_number': 13847678,
    'item_consumption_1': 356,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 207.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd665b02-dcf4-4a77-a7bd-0f70ce21e92b',
    'identification_number': 103034838,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11bb4948-3e65-4c3a-a4b5-7b7494d81373',
    'identification_number': 71242902,
    'item_consumption_1': 4179,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1632.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0e09a7c-2c06-4320-aade-676ff478e902',
    'identification_number': 19452667,
    'item_consumption_1': 73101,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 42693.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd958eaab-c4a4-4233-96d3-c42897c08758',
    'identification_number': 18036244,
    'item_consumption_1': "'-1.89",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-605.13",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'ad19ee8b-2246-4e6f-82b2-1690d1887253',
    'identification_number': 12255351,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fc9807c0-c7c5-467b-b965-9b786272ddeb',
    'identification_number': 19119631,
    'item_consumption_1': 1025,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 400.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab027be4-6932-43bb-b0e3-118554ccbf32',
    'identification_number': 75768879,
    'item_consumption_1': 23717,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3027.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab0545bc-16c8-4703-bb97-6cb9ea52c023',
    'identification_number': 101477040,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'beb20d06-8248-402e-a18b-8344fba50d6a',
    'identification_number': 45985880,
    'item_consumption_1': 3881,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1649.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b15cfa88-4b9c-41f3-8913-3d80a6871427',
    'identification_number': 71242902,
    'item_consumption_1': 4179,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1774.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94088d78-a49a-410c-ad63-da82de73413b',
    'identification_number': 55193994,
    'item_consumption_1': 2.08,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 49.61,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3ed1bddb-06f2-49d8-a975-63592a2bcde8',
    'identification_number': 15871789,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 149.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7358e0f7-2e00-4c0e-b704-80e0d40b5248',
    'identification_number': 103103163,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-35.72",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '522fe8eb-dfbf-4c53-ba0c-3d731b2edca5',
    'identification_number': 36222852,
    'item_consumption_1': 177.98,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 5176.72,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '665cf7a3-3165-4ec4-a9f5-86270be11bdc',
    'identification_number': 47472006,
    'item_consumption_1': 4.15,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 98.98,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '165d225b-740c-4c38-8caa-bf21df1d4bdd',
    'identification_number': 19451113,
    'item_consumption_1': 1069245,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 397916.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '453ba420-9057-4380-a3f0-2f3092e65fff',
    'identification_number': 18647480,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ddac0fd-ac4d-4775-875a-2311daca3fbc',
    'identification_number': 31923917,
    'item_consumption_1': 57.21,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1664,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4fcdde50-8380-4270-91de-840a4e36cebb',
    'identification_number': 16597966,
    'item_consumption_1': 4942,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2095.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a487c914-89d4-43a0-a3b9-397ee38b5c52',
    'identification_number': 98925083,
    'item_consumption_1': 95,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 37.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6a22e28c-bc86-4f1e-b632-6c750f0095a6',
    'identification_number': 69855315,
    'item_consumption_1': 3895,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1652.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71bb971d-3209-4d0d-b333-2508d57d8b00',
    'identification_number': 109434765,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4878b4f9-3c28-4ff6-82af-e05436a37849',
    'identification_number': 32151179,
    'item_consumption_1': 5.76,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 137.39,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '767baef9-baae-4721-90f4-571895d43430',
    'identification_number': 29131251,
    'item_consumption_1': 2865,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1119.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '353d932c-655a-4813-b4c0-7a4909649e1f',
    'identification_number': 77776500,
    'item_consumption_1': 10686,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1363.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3961c7e-4791-4d0c-8db1-498f9a602775',
    'identification_number': 15087239,
    'item_consumption_1': 6533,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2548.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa98dea4-ff0f-4983-85a9-7646328e7d43',
    'identification_number': 49666711,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2016d253-0415-44bd-b885-70d8da18e7a8',
    'identification_number': 41755138,
    'item_consumption_1': 7372,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4305.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b79687ef-275d-4942-8af9-e2913ad7849f',
    'identification_number': 47899816,
    'item_consumption_1': 1206,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1824.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58a6bec3-c8d5-40bb-82b0-4717fa34e8c4',
    'identification_number': 935177955,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0a276d58-61be-4df4-814b-d736bc08ff85',
    'identification_number': 28219872,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4e76d240-669c-4925-85de-f32746fb8f2c',
    'identification_number': 62362801,
    'item_consumption_1': 3789,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 483.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9f54fc1-b841-4456-a6bb-4969aef1c012',
    'identification_number': 39961320,
    'item_consumption_1': 85.01,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2472.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dacacb2f-925d-4da6-b02c-e954a41bcdab',
    'identification_number': 92661246,
    'item_consumption_1': 4244,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1801.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f28afda-89cc-4dda-aa81-c94507e944aa',
    'identification_number': 11110597,
    'item_consumption_1': 3102,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1210.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f2d3a84-d898-4c79-8dbc-d994cc078e23',
    'identification_number': 63964112,
    'item_consumption_1': 50.36,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1464.75,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '33bf6d5a-0aba-4199-8284-435c27ce79a8',
    'identification_number': 27135357,
    'item_consumption_1': 935,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 365.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11638951-14f1-4ff2-a4ff-8dfac71d8182',
    'identification_number': 43875718,
    'item_consumption_1': 105.78,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3076.7,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2c509c8f-aaf5-469c-9f23-d08216eee55c',
    'identification_number': 69572968,
    'item_consumption_1': 15.38,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 447.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bbcd7527-25c1-41dc-a034-9b23ad046fe1',
    'identification_number': 106532880,
    'item_consumption_1': 49,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59a594de-f4c5-4a33-802e-d5e44df02eb3',
    'identification_number': 14660601,
    'item_consumption_1': 65,
    'item_description_1': 'Demanda faturada EP',
    'item_total_value_1': 1004.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '24eb4b1e-ddad-4256-9ede-b0a8cdc71912',
    'identification_number': 32747217,
    'item_consumption_1': 0.51,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 12.18,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5e3a11aa-68e5-473d-9ecd-ea5a2cd52344',
    'identification_number': 100797113,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cecd46e4-4f79-43c8-a31b-a6dd854a4b80',
    'identification_number': 97837350,
    'item_consumption_1': 3991,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6037.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '709a4459-f923-4c0d-8eb3-57dd741d1541',
    'identification_number': 69324719,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '487bfba2-3efb-40fe-9bcc-7d8c2c113505',
    'identification_number': 16628063,
    'item_consumption_1': 1320,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 516.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd08d3b2d-6d63-45e0-bb6e-16d0b8d48cbd',
    'identification_number': 56698151,
    'item_consumption_1': 959,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1450.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e15b798-a9ef-466a-91df-22056bf84248',
    'identification_number': 73694932,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c046b68-71ff-4f33-8f6b-c14ca8c4c55e',
    'identification_number': 83718230,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 106.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dd6cd727-cfd9-45e9-9d3b-48024cc74a22',
    'identification_number': 36704296,
    'item_consumption_1': 775,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 329.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76a58764-dd1e-42d2-aaa0-99ef1aa26d5f',
    'identification_number': 27112101,
    'item_consumption_1': 20324,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7563.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17674be7-8ec1-4cc9-966a-40b73a29578c',
    'identification_number': 15444740,
    'item_consumption_1': 2355,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 920.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '199f3903-faab-42c8-a14a-bf350ee3628d',
    'identification_number': 100797113,
    'item_consumption_1': 13.48,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 321.53,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8c80072b-82c7-4b14-ba5e-06d89f5e3770',
    'identification_number': 935472026,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 41.58,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3cd121ab-4859-4369-ac26-4721761ed2aa',
    'identification_number': 100854982,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f960225a-68bc-459c-93a2-50894f0f2e7c',
    'identification_number': 19451610,
    'item_consumption_1': 1.64,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 39.13,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8f67a2b3-6bf4-4478-a02a-3f3dc134a14b',
    'identification_number': 92773621,
    'item_consumption_1': 4457,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1740.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8091b64-bd4e-4667-a38c-69c472f2c404',
    'identification_number': 102891028,
    'item_consumption_1': 18014,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 7021.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70ca8e73-262d-4c2b-bc78-af3da48bc0b0',
    'identification_number': 99959739,
    'item_consumption_1': 4541,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2652.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0c040f0-ee33-4e2d-9a95-4d8aa1dc7636',
    'identification_number': 18671870,
    'item_consumption_1': 1249,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 530.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16257618-adb8-4c1b-b393-93f9932a20eb',
    'identification_number': 73615145,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f1098bb2-1466-4038-863a-12143c48c7eb',
    'identification_number': 42584418,
    'item_consumption_1': 51223,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6537.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0242c3a5-3aaa-4a33-a689-34504c06c411',
    'identification_number': 14354306,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 57.06,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '35c4119e-0a09-4824-a64a-8b78f17a069a',
    'identification_number': 104783206,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f72ea002-cc8a-4064-8f5b-1d4dfb159f8b',
    'identification_number': 37423258,
    'item_consumption_1': "'-197.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2022',
    'item_total_value_1': "'-68.65",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'a24414f5-c3f3-442e-bee0-0e9e52ab0aff',
    'identification_number': 56699077,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3b6f4b37-562b-4cd6-afae-ce9835d6c237',
    'identification_number': 40768090,
    'item_consumption_1': 1422,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 553.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a86e0996-a198-42de-82bd-7f9060398f68',
    'identification_number': 56706499,
    'item_consumption_1': 9,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'fe3fe0be-ee0a-4478-bdbe-19c3aaa3003b',
    'identification_number': 40174220,
    'item_consumption_1': 636,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 962.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c2b07c0-5219-4aea-9557-59f8c38dff4a',
    'identification_number': 50792180,
    'item_consumption_1': 16.24,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 944.72,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '77916869-d698-4043-a0c5-b387f2770f8b',
    'identification_number': 5361494,
    'item_consumption_1': 3043,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1188.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a712627e-88f8-4192-a4cb-b08d8767c773',
    'identification_number': 50863207,
    'item_consumption_1': 5600,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2378.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '713ad449-426e-4cad-a2bf-cff4a5b154c1',
    'identification_number': 29700485,
    'item_consumption_1': 84.18,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2448.45,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5eb9f8d0-242f-4269-8144-af960f4a8838',
    'identification_number': 109863887,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '394a473c-8794-442f-afbc-2537f753b354',
    'identification_number': 37998447,
    'item_consumption_1': 46477,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5932.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '112a1603-c1e5-4374-8494-c97a2accfade',
    'identification_number': 109822285,
    'item_consumption_1': 28901,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3688.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '288bb24b-b7ef-4d45-9961-c88223f42a20',
    'identification_number': 46577173,
    'item_consumption_1': 5739,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2135.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d720d33-f3f0-45ae-9d39-2325a5c8c23b',
    'identification_number': 93825579,
    'item_consumption_1': 2032,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 860.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c49a1169-c182-4099-9e1d-2cc79fa090ab',
    'identification_number': 48482897,
    'item_consumption_1': 6.75,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 316.12,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '727ba981-3fcb-40e5-9007-1bfcaffdfc94',
    'identification_number': 110519132,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '66826d04-4aaf-44f6-b793-7dc4a6db9b53',
    'identification_number': 14389754,
    'item_consumption_1': 26.91,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1536.81,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0010e096-04fd-457f-884b-be0748031a74',
    'identification_number': 100637612,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ffc4049f-cca3-4f87-8162-5628cbfbe641',
    'identification_number': 91490316,
    'item_consumption_1': 625,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 244.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28ed0e79-5432-4697-8f3c-5b5e34a27812',
    'identification_number': 104186020,
    'item_consumption_1': 6,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '61bbfcc0-70f6-4554-8393-da0d8e1d4247',
    'identification_number': 65191404,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ba95505f-6830-4e3e-b4cb-dbe82bd963e7',
    'identification_number': 109139402,
    'item_consumption_1': 4865,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 620.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '072b2cd0-f38d-4c78-9098-03f3c94294d6',
    'identification_number': 15981401,
    'item_consumption_1': 6468,
    'item_description_1': 'Consumo nu ponta',
    'item_total_value_1': 11134,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67e775eb-32b1-4def-8b09-597027643430',
    'identification_number': 21539340,
    'item_consumption_1': 9911,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4212.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6eca99ff-dd88-4a83-8222-96e85022b0c0',
    'identification_number': 41611187,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '11cbf3c5-eb07-411d-afff-d2d5eea95394',
    'identification_number': 108065006,
    'item_consumption_1': 16796,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2143.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5c38e2c-9607-454a-854f-32bd4a915463',
    'identification_number': 82724873,
    'item_consumption_1': 2.93,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 334.65,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '709f90fb-9282-4c4e-8a5b-61381010bd93',
    'identification_number': 32151179,
    'item_consumption_1': 99662,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 12720.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8004490-1cf7-4be5-aa66-1f6d02f712ce',
    'identification_number': 61389617,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f95bcaad-d6dc-45ad-ab43-56a3a980db86',
    'identification_number': 37528319,
    'item_consumption_1': 4080,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1593.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c545aac5-919a-45ff-882b-7dc877d7ccf4',
    'identification_number': 32348533,
    'item_consumption_1': 784,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 100.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd282b548-6753-4481-b354-a944956e1eab',
    'identification_number': 39545377,
    'item_consumption_1': 11398,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4241.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6db522d0-034d-410d-96f4-cc9ae18a3999',
    'identification_number': 103438157,
    'item_consumption_1': 1.1,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 51.53,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '57fc8c9e-55e4-47e7-b693-1c6d4b92cfde',
    'identification_number': 74311875,
    'item_consumption_1': 16.91,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 403.32,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a8039861-ccf7-41f5-9ed9-5112f3cb8d07',
    'identification_number': 59438029,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b3ce22f-6be7-485e-9496-cec9ccbaff5f',
    'identification_number': 19450753,
    'item_consumption_1': 911759,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 116373.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32105bec-cdb8-4fbf-806d-fc6de7ce9d51',
    'identification_number': 106939203,
    'item_consumption_1': 292,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 113.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8c1eff4-2560-4ea5-9807-2df87502af9e',
    'identification_number': 923584014,
    'item_consumption_1': 14872,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 12964.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4cb6428-1d17-493f-b158-8a2240a81c17',
    'identification_number': 56525230,
    'item_consumption_1': 412,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 240.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3c6834e-ce2f-493c-a10a-8b1508cc357b',
    'identification_number': 33354537,
    'item_consumption_1': 123,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 47.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '308170b6-ea70-4453-aae0-a5b3806574aa',
    'identification_number': 18295134,
    'item_consumption_1': 725,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 308.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8f452c2-3e0d-467d-bde4-bb0aa62b8b44',
    'identification_number': 108646785,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c66280f8-2f71-4829-9ad6-85a7a4837b9f',
    'identification_number': 27904660,
    'item_consumption_1': 5.46,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 130.23,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'bb65ff3c-44e3-4c81-8baf-266fbce80ffb',
    'identification_number': 6991386,
    'item_consumption_1': 2303,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 975.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0ca3af9-c9c0-42eb-bc3d-a2fef1a51b14',
    'identification_number': 109782909,
    'item_consumption_1': 106,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 13.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a737bae-6369-4eca-a5a6-1098714dce08',
    'identification_number': 27057852,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '90527d7c-ad62-4f4c-b4f8-8d62a24921e0',
    'identification_number': 103752234,
    'item_consumption_1': 332.79,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 7937.22,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1c4887df-1065-491a-ba1e-6780fcb287b0',
    'identification_number': 935258912,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f144e6d9-8e18-41ac-b924-4701f207a6cd',
    'identification_number': 28976746,
    'item_consumption_1': 5466,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2313.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6e6ddf1-c981-4396-8be5-b13ee3bc2398',
    'identification_number': 108309835,
    'item_consumption_1': 246,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 96.24,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b2524984-8730-4365-83db-befe10ce983e',
    'identification_number': 31849733,
    'item_consumption_1': 822,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 321.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2f52128-db74-44b8-8d53-a23fe47d4a22',
    'identification_number': 106390015,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6725f759-99ce-4278-8a7c-be10cb348b71',
    'identification_number': 10698825,
    'item_consumption_1': 2830,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1102.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89eb441c-522d-43ae-a7d8-d48896585f86',
    'identification_number': 103341013,
    'item_consumption_1': 775,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 302.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82ec3369-b986-4f02-8308-5c3444710303',
    'identification_number': 17714796,
    'item_consumption_1': 458,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 194.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f760f05-69c9-4726-a015-46d7f8872245',
    'identification_number': 59422009,
    'item_consumption_1': 584,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 341.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c961c6b6-ddb3-4c43-8ee6-d462b0e93beb',
    'identification_number': 86190695,
    'item_consumption_1': 2500,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1061.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d929b15-4658-4170-b938-fb9db1ec0a63',
    'identification_number': 6579051,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 2514.17,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'ba2d9a04-3905-4110-981c-6fc883605959',
    'identification_number': 48843075,
    'item_consumption_1': 2574,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3894,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'feecd7d8-8b5b-422b-9dfa-ed811bca0cb7',
    'identification_number': 91392454,
    'item_consumption_1': 90.62,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 5175.31,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b30b7607-f888-41cd-8fba-fef9a214e4f4',
    'identification_number': 107977915,
    'item_consumption_1': 253,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 107.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48c1ea33-fa78-4f46-8e18-6a4d0787adda',
    'identification_number': 71242902,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ab455886-99a5-4602-ac1b-f91a485378f4',
    'identification_number': 73533386,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'de9a451d-c717-416e-88b6-d721676acbc6',
    'identification_number': 97970034,
    'item_consumption_1': 44,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 17.22,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7597314d-a7be-4f3f-99f2-ebc6bf6f2e14',
    'identification_number': 63137399,
    'item_consumption_1': 799,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 466.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd6065b7-7c18-4305-9e34-4f7a68266fa0',
    'identification_number': 85205672,
    'item_consumption_1': 68.97,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2006.04,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '97fe5441-0680-4f4a-8025-45ee8274212e',
    'identification_number': 58734210,
    'item_consumption_1': 30.11,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 875.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ea4a69af-1ad6-4db5-88ec-a4d346b25ea4',
    'identification_number': 17775418,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1eaf603c-1cc3-434f-9265-c507b1846efe',
    'identification_number': 46708154,
    'item_consumption_1': 11345,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1448.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac181eb4-a0b8-41d4-ad80-560978911364',
    'identification_number': 41640870,
    'item_consumption_1': 130.72,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 7465.41,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b1481b26-9ed0-41e5-bc1a-24aeba39c7aa',
    'identification_number': 70556997,
    'item_consumption_1': 8.03,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 191.53,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '278cfa40-c836-4ee0-9571-1e4cbb62a200',
    'identification_number': 106612662,
    'item_consumption_1': 394.68,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 9413.32,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '042e37f7-5907-4de0-8835-343a55c4f724',
    'identification_number': 41700929,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9d5a7fe1-1595-4882-b91d-3fd682bee399',
    'identification_number': 9921273,
    'item_consumption_1': 66.86,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3818.36,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '317e6071-1470-449f-bbfd-6b50838c68d9',
    'identification_number': 93205589,
    'item_consumption_1': 10.52,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 250.91,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1e64a977-d822-4f3a-b211-d675be7a57ec',
    'identification_number': 7284012,
    'item_consumption_1': "'-56.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 05/2023',
    'item_total_value_1': "'-17.88",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'ae8eeb7a-1bce-4303-b06d-a3a54d9c8acf',
    'identification_number': 62462733,
    'item_consumption_1': 1948,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 760.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7af0b34b-141c-464d-9b64-15fd17cd87b6',
    'identification_number': 13028855,
    'item_consumption_1': 27.99,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 814.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'acf81ff7-c76a-4c13-832c-167e2083b4f5',
    'identification_number': 103366512,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd38e0f88-e151-4b00-a8c0-d20013acdd4d',
    'identification_number': 31923917,
    'item_consumption_1': 2272,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1326.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '243c0423-eb9c-4a17-bf2e-6a2843fc245d',
    'identification_number': 93133774,
    'item_consumption_1': 949,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 403.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aeed2805-30f8-4259-b654-873f667c3bf4',
    'identification_number': 69609780,
    'item_consumption_1': 1837,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 715.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e62a81c9-a7e9-49ac-a440-6c05fe8f13ad',
    'identification_number': 49514113,
    'item_consumption_1': 885,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 344.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b06a505-bf80-4b8e-a4c7-bfcb39c4bec0',
    'identification_number': 56705514,
    'item_consumption_1': 32.37,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 941.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '98f74624-366f-4e66-b3a0-4f536796c93e',
    'identification_number': 37998447,
    'item_consumption_1': 60.4,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 1440.59,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1179014f-b24e-43e2-81c5-ece548ce259e',
    'identification_number': 12673650,
    'item_consumption_1': 0.4,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 18.73,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3a64002c-c678-48dc-b81c-2ea89dac1865',
    'identification_number': 14970910,
    'item_consumption_1': 3470,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1351.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '014f70fa-92b7-40e0-aaae-0593f7d00d90',
    'identification_number': 18855636,
    'item_consumption_1': 3104,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1315.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01f85336-3d75-41b3-bd55-92756a76ca84',
    'identification_number': 25919032,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '239f2c08-1efc-47be-b4d2-1e926e34371b',
    'identification_number': 12956465,
    'item_consumption_1': 9553,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1219.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91899d26-1134-49a2-9333-937eef6b4c68',
    'identification_number': 18339654,
    'item_consumption_1': 12098,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1544.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'adcf31ef-7ac4-4365-a38d-c90490229192',
    'identification_number': 63897148,
    'item_consumption_1': 14.93,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 356.1,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0762fb84-8ec0-44eb-a2e3-799baf42fc92',
    'identification_number': 44805616,
    'item_consumption_1': 59891,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7644.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10556a83-d54a-4c55-8fb5-5b408d910963',
    'identification_number': 11177101,
    'item_consumption_1': 1850,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 783.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4b311c7-5480-4ffe-92fa-1759eb9140c6',
    'identification_number': 20168225,
    'item_consumption_1': 1037,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 405.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33ff19cb-694b-4fd1-934a-02edf56676f4',
    'identification_number': 82142068,
    'item_consumption_1': 1226,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 520.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b66b4b67-9a25-4a52-a1db-349c3c3e668d',
    'identification_number': 48875325,
    'item_consumption_1': 31885,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4069.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4061cae0-c19d-4c0b-942a-d43fb404546b',
    'identification_number': 3030784010,
    'item_consumption_1': 195222,
    'item_description_1': 'Cons FPonta TE NOV/23',
    'item_total_value_1': 55141.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '093a3e84-9b07-4806-bc46-d21e582df7de',
    'identification_number': 95332162,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 1476.48,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'f7490612-c212-491a-958c-3df1d56a4523',
    'identification_number': 39452336,
    'item_consumption_1': 861,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 502.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b5c82a3-551b-437f-abf8-cd9cfbeee4b8',
    'identification_number': 102571538,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 102.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cdc5c3a6-41d5-4176-8215-a97a13582083',
    'identification_number': 47312459,
    'item_consumption_1': 740,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 314.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1aeaf401-e414-45ff-a399-c2a476b25d76',
    'identification_number': 111609844,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce5d170d-5c68-4b8d-85a6-f55c7f79b247',
    'identification_number': 111114926,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef486058-e14a-4561-adc1-2c0d1e98a2d9',
    'identification_number': 15071987,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-3.59",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '2033bff1-d4ca-471e-bcf0-fb0753d6d18f',
    'identification_number': 102135541,
    'item_consumption_1': 20087,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2563.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e15d77d-e8ce-4366-af29-89313b985919',
    'identification_number': 95009914,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd61708f2-62f1-4de3-9df2-115f0031de51',
    'identification_number': 15871789,
    'item_consumption_1': 818,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1237.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '171c133a-4b1b-4d05-88ec-cc620102cf17',
    'identification_number': 38893614,
    'item_consumption_1': 3103,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4694.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bcedebe-fe3a-4d4d-99eb-850687826ee8',
    'identification_number': 46171622,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3e7c128e-2d9e-4b7a-a8de-ce1a152e8d0a',
    'identification_number': 12117498,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49f1cb36-0a2a-4965-a22e-8ddd78aa2c5e',
    'identification_number': 32747217,
    'item_consumption_1': 11882,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4421.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ac33fd7-c094-473e-97d1-fa734b24fc0c',
    'identification_number': 35614595,
    'item_consumption_1': 109,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 42.63,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ad0e8906-a259-41ec-b737-0135ae505b3e',
    'identification_number': 108668630,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4683d33c-8de7-4911-b967-07f6a751b6aa',
    'identification_number': 27143805,
    'item_consumption_1': 4122,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1751.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0dffb323-e313-441e-9fc5-e1bb57792c49',
    'identification_number': 44085737,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca845d3f-b020-4166-96b2-bf491f27edbf',
    'identification_number': 75876957,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4e2ae48c-b535-4b4d-a3b6-405b82bee2d4',
    'identification_number': 33235953,
    'item_consumption_1': 16,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 6.25,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '47138bcc-e7c9-4f31-99b6-1a7d4c86538e',
    'identification_number': 104186020,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2f004509-9760-4886-8b95-c76f32c4dab0',
    'identification_number': 47198850,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '860ff7e1-0810-4cf9-b37e-325c51237228',
    'identification_number': 58739149,
    'item_consumption_1': 534,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 311.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '345bded8-2908-4022-8f01-e97132ee3c9f',
    'identification_number': 107540860,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c474bd03-35ad-4578-921e-1c98322f6db5',
    'identification_number': 36324876,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9b499267-b42a-4438-9dbd-c71a59963f74',
    'identification_number': 34459715,
    'item_consumption_1': 2077,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 879.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'deb16b9f-738a-4225-80c0-b1a2a563c097',
    'identification_number': 45159335,
    'item_consumption_1': 1659,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 696.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ebc2905-e34b-42ba-b9ab-505d9070d7da',
    'identification_number': 88214990,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c3487e1-744b-4e33-bea8-a04dabda1dad',
    'identification_number': 103721835,
    'item_consumption_1': 6,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '97fc57d7-882f-493e-8af3-17d04b5e7648',
    'identification_number': 105653691,
    'item_consumption_1': 1603,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2425.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1d41d2f-4d1a-40e1-b5ac-7ba67430e8a6',
    'identification_number': 102684650,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '57db8fcf-6148-40c0-a08d-8ce750d6bb88',
    'identification_number': 97005304,
    'item_consumption_1': 22.23,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 646.57,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8f9dc30a-a03b-4d01-b657-9427fa696ac8',
    'identification_number': 99713349,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ffec0ab2-06f9-48a5-829c-0ce64d851b6d',
    'identification_number': 84355409,
    'item_consumption_1': 586,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 228.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ef72651-e5d3-405e-8dac-080a98323983',
    'identification_number': 99447495,
    'item_consumption_1': 899,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 350.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '140df82a-6219-401b-9d39-366bb1f86b58',
    'identification_number': 57470162,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b5c9ff1-223c-4581-9dd4-ad831c16c032',
    'identification_number': 104480050,
    'item_consumption_1': 9075,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1158.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd93ba786-0227-4117-9c53-1ddafd397e9b',
    'identification_number': 11342870,
    'item_consumption_1': 1451,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 565.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f366ff7-2c7a-4b94-b9dd-c808bc8c7035',
    'identification_number': 40709140,
    'item_consumption_1': 2938,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4444.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73934c0b-9909-4cf4-af4d-599e4fccd955',
    'identification_number': 110528603,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1c52293a-83e6-49fb-913c-334a8a72873f',
    'identification_number': 110373383,
    'item_consumption_1': 322,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 125.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c852c475-b097-4748-8ee8-8665cc24f536',
    'identification_number': 69901910,
    'item_consumption_1': 1041,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 441.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13a3e087-d800-4e25-9e69-c7f5f50acf4b',
    'identification_number': 32594852,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c9c62416-c68b-4794-867c-d95d82e8e300',
    'identification_number': 14619563,
    'item_consumption_1': 63234,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8070.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c6cb11d-bea6-4e71-aa8b-23f955edd91e',
    'identification_number': 65626079,
    'item_consumption_1': 3543,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1385.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '469eaceb-03b7-40a5-b3d4-fd184fc7e8bc',
    'identification_number': 16701917,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 112.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '39fca07c-505e-46a4-98c6-90fd8fdf7acf',
    'identification_number': 14945304,
    'item_consumption_1': 1887,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 800.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e97684e0-78d8-4711-b214-5a15d84555b6',
    'identification_number': 68135580,
    'item_consumption_1': 565,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 329.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f10065ca-17fb-47af-9833-9069c9a1e70a',
    'identification_number': 35090448,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 4.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c64fb834-b946-4d79-9d0a-c97e496bcad1',
    'identification_number': 97833363,
    'item_consumption_1': 1685,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 716.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9019c520-3300-438d-888c-953c501b2e46',
    'identification_number': 58325549,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d66586f-a34a-4418-a0a2-dc0be9ebda82',
    'identification_number': 92638775,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '64e70284-38b2-4b55-abe8-aae0ff2fd748',
    'identification_number': 44010141,
    'item_consumption_1': 3005,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1755.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38c2c876-e87f-4deb-9c32-337b0b131294',
    'identification_number': 55072755,
    'item_consumption_1': 171,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 66.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cacdd03-b697-4aac-9335-3797203c6d98',
    'identification_number': 103765450,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 6.37,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'bbb1bd61-3a00-40d4-a588-6a06273c7625',
    'identification_number': 107330288,
    'item_consumption_1': 0,
    'item_description_1': 'SERVICO DE RELIGACAO',
    'item_total_value_1': 128.81,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'f00dda4f-47b4-48dd-a388-cda20216d599',
    'identification_number': 9921273,
    'item_consumption_1': 28283,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10525.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec23bbfc-e0a2-4f0a-aaf3-eb1409bbfcba',
    'identification_number': 99864029,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '366a88b9-bfd6-4b99-a31b-602f2759a590',
    'identification_number': 32689250,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.54,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7830b473-0bc5-4259-a3c6-c834188800be',
    'identification_number': 923318231,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd9ebbef-dbfb-412d-8716-48f6f9bd021e',
    'identification_number': 93699395,
    'item_consumption_1': 915,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 387.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f14921fc-d546-4f57-a5e0-0c3c356f777c',
    'identification_number': 56507844,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'caa2b94b-972f-4b99-afde-a73487343e06',
    'identification_number': 39530205,
    'item_consumption_1': 4240,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1651.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6469f2d3-08e7-4ea0-85c4-6c0d175be088',
    'identification_number': 9612297,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6d62561-57bd-4199-acd7-b5bf28b0d68d',
    'identification_number': 23094338,
    'item_consumption_1': 909,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 355.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '549dd299-d5e7-4110-842e-fa24a44e1087',
    'identification_number': 100689620,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bb7ebe0b-73ff-477f-9f9d-eda1fa06f0f5',
    'identification_number': 109993217,
    'item_consumption_1': 21648,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2763.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba028dc3-1945-4fe6-8094-e13d98dae625',
    'identification_number': 948743496,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4cf636ce-46df-402d-b30c-6985d744eea0',
    'identification_number': 59716606,
    'item_consumption_1': 6164,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2613.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e1e7a58-a94e-4586-8c2c-1b1253eb5872',
    'identification_number': 98718410,
    'item_consumption_1': 128,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 49.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e77b18a-efe9-4c8f-91c3-a269f4342801',
    'identification_number': 47010991,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '508b685a-a627-4494-a2f9-51fddd491c9b',
    'identification_number': 41971817,
    'item_consumption_1': 42.82,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2445.43,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'd2ab4092-d15e-410b-8af0-b524dd2fcd0a',
    'identification_number': 41499255,
    'item_consumption_1': 969,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 565.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20eff697-92dc-4883-8340-1358cf5f6c09',
    'identification_number': 105105945,
    'item_consumption_1': 10890,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4622.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01b2086a-c9c4-4744-a881-0219d7554f35',
    'identification_number': 63572486,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e7cdfba-3552-4479-b771-f60634cf5ef2',
    'identification_number': 48875325,
    'item_consumption_1': 31885,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11865.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9c58b6c-5e7e-48ae-950b-8160a8f40519',
    'identification_number': 107674149,
    'item_consumption_1': 14872,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5534.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5aceb049-26e0-482f-9c50-bdb23c326378',
    'identification_number': 111285739,
    'item_consumption_1': 4333,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1837.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd45e7a32-891a-437d-a1d6-02d221b28160',
    'identification_number': 91670942,
    'item_consumption_1': 575,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 335.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32feba6a-5ec8-4eb6-b516-915c6aa55ab6',
    'identification_number': 85890669,
    'item_consumption_1': 2415,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1025.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92b6ad13-e8c3-456b-a8ff-600877a961a0',
    'identification_number': 16622383,
    'item_consumption_1': 2964,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1154.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eedce11d-8868-4196-bcf7-7a3928840a04',
    'identification_number': 11222565,
    'item_consumption_1': 3065,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1195.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52b40597-54b7-4790-9d2d-d7cfbcc27be0',
    'identification_number': 80146139,
    'item_consumption_1': 9021,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3822.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2a0fbe5-fa46-4864-b876-2eea29c120de',
    'identification_number': 63416794,
    'item_consumption_1': 14118,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1801.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46ce0825-3f84-4728-9c5f-a7dd16ec184e',
    'identification_number': 106622218,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f6e1c3b-0ef7-4116-8b8a-68537164309d',
    'identification_number': 87381796,
    'item_consumption_1': 1058,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 413.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2789bea-4ea2-4167-b503-cc2caabefbe8',
    'identification_number': 36897140,
    'item_consumption_1': 721,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 305.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c49b129f-9ec3-4f57-ad30-41c50298e8ec',
    'identification_number': 96383860,
    'item_consumption_1': 2169,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 846.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be240651-f251-458f-bf43-69853023b000',
    'identification_number': 77217896,
    'item_consumption_1': 353,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 138.11,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e9dbbec7-3e49-41f8-8731-bda663ca6d36',
    'identification_number': 14333910,
    'item_consumption_1': 54235,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 20183.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e300e5e6-df50-44a7-affc-cbc8386862fb',
    'identification_number': 102896224,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1781544e-af1d-4ef3-a786-2bfcb3902b72',
    'identification_number': 77279719,
    'item_consumption_1': 9506,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3537.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbe6acde-ef08-444b-b030-1e8b018f7a4c',
    'identification_number': 111469554,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e6b0d34-5c0e-4951-ade0-5e91671a897d',
    'identification_number': 77724895,
    'item_consumption_1': 3349,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1955.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7a33199-4937-46f7-ab5f-487eb63f285d',
    'identification_number': 14562553,
    'item_consumption_1': 2281,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 848.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c8cc66d-1ac3-4041-a558-4901fdd8ad26',
    'identification_number': 104289970,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79f712d2-6c7d-4ea2-81f4-3f770bf658df',
    'identification_number': 54126754,
    'item_consumption_1': 3.24,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 94.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '945de1e4-d322-4329-96fb-80b5e67d73bb',
    'identification_number': 56877021,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'accee4b4-9083-44d0-87db-81c4392a522d',
    'identification_number': 101885610,
    'item_consumption_1': 5500,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 702,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59b1ade4-1fd6-4e2a-8a53-4ec741c017f5',
    'identification_number': 59437308,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75b9de64-03ce-43ce-b2af-5b583ad64570',
    'identification_number': 107941589,
    'item_consumption_1': 5601,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 8473.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8db6f24d-07a5-4053-92f6-359632d3f31e',
    'identification_number': 6991386,
    'item_consumption_1': 2303,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 897.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1873ef9-22c4-4998-86ae-6b3c6618ea0f',
    'identification_number': 104179228,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2407.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44f837fe-9f3c-49b7-bd60-b94c5b414183',
    'identification_number': 96895993,
    'item_consumption_1': 9039,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3532.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bec2c8ae-f568-4128-ad60-e58ebf3eb8cf',
    'identification_number': 12977489,
    'item_consumption_1': 2423,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1415.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fb5c979-72de-4fe5-a011-3b4c5ffacaf8',
    'identification_number': 63401568,
    'item_consumption_1': 3111,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1215.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4aaa1a8-3cb6-4813-a8eb-46ec2d891c5a',
    'identification_number': 43814697,
    'item_consumption_1': 3075,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4651.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78fab040-3128-46d8-837b-2a2fb2dffee7',
    'identification_number': 103617515,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a3549316-d104-47d5-9a90-13cce58c7db9',
    'identification_number': 106013467,
    'item_consumption_1': 7052,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2756.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c076f32-f6bc-475f-9754-2a1fbef49f50',
    'identification_number': 61481467,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5676.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2637f105-4277-4461-92c8-ede9c0826c2e',
    'identification_number': 50940694,
    'item_consumption_1': 1198,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 699.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec3c7a08-e8cd-4df5-b4dc-49101cb91c0a',
    'identification_number': 92912290,
    'item_consumption_1': 58034,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 21597.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62924904-d3c5-4948-a53f-d0766d0679ba',
    'identification_number': 109408586,
    'item_consumption_1': 3316,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1408.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c02369f-b1d4-4257-b400-025e8d6475a1',
    'identification_number': 93500777,
    'item_consumption_1': 328,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 127.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9290ac05-4b61-4b8b-9157-21d843359300',
    'identification_number': 43814697,
    'item_consumption_1': 22737,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2902.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6aa7ba65-dff0-4bfc-9426-4d2da995b3f5',
    'identification_number': 105778273,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b08032ad-cb3e-4104-8b78-a610c817eb64',
    'identification_number': 42348579,
    'item_consumption_1': 996,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 389.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e997d57-92c0-4374-8d4d-83c0316f3366',
    'identification_number': 93205589,
    'item_consumption_1': 399,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 603.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b11d23c3-14d9-4044-861a-9976c2104e0f',
    'identification_number': 13973932,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6069948e-394d-4232-aa42-9094a460b4e0',
    'identification_number': 58724591,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5eda33fd-b5a7-4207-9f73-1166ed80ab7d',
    'identification_number': 16951662,
    'item_consumption_1': 5.57,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 132.87,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8c580e9d-59ab-4040-90c0-300a7de14581',
    'identification_number': 45986223,
    'item_consumption_1': 678,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 286.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '348252bf-f82f-423f-96f5-41b93bc3869e',
    'identification_number': 106595415,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '60604719-7f7c-4ada-89bf-e276d50eb1d7',
    'identification_number': 11680148,
    'item_consumption_1': 14652,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5452.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f578c78-4635-4f7e-85fa-9e31d5609ad4',
    'identification_number': 16125312,
    'item_consumption_1': 59,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 1111.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c4d3090a-9ba7-4641-8081-9f3c86271f63',
    'identification_number': 47075066,
    'item_consumption_1': 8841,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3755.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '352bf438-884d-4d28-b17d-f4ff119c060e',
    'identification_number': 109379365,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef5e8b8d-4b78-47c0-8bbd-8aba2978457b',
    'identification_number': 12797707,
    'item_consumption_1': 8492,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3160.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a348bdb8-5b79-4cc1-9b73-023bc37f69d8',
    'identification_number': 97174831,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bc6c4199-3e53-4266-880a-75c51866f4ac',
    'identification_number': 102658960,
    'item_consumption_1': 9643,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3768.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '339bb0fa-12a0-44b0-a998-cd403846e403',
    'identification_number': 75917050,
    'item_consumption_1': 11899,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5054.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a5fe38b-8fc3-4849-aa33-2ed53657585b',
    'identification_number': 19450303,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 12.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '20125d95-334f-4d8b-8fc7-7114f2d2946e',
    'identification_number': 93234724,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34c77729-f919-446e-817e-c82988410503',
    'identification_number': 100185860,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c86b93df-8c80-4ffe-9316-075853d83711',
    'identification_number': 20587147,
    'item_consumption_1': 907.2,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 26386.78,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8d714ef7-d0d3-4168-9556-57fe9b369333',
    'identification_number': 36527661,
    'item_consumption_1': 283,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 110.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7679ad1-e4d7-4134-ac3e-72ca3a662d72',
    'identification_number': 103034579,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae4457e9-4f67-40f9-b379-ab3ef1c78bb5',
    'identification_number': 86903055,
    'item_consumption_1': 297,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 125.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ccd1519-9caa-4de3-a918-7611930b3b5c',
    'identification_number': 105760480,
    'item_consumption_1': 445,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 173.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c65d818f-9f28-4a78-8f86-645a2dcbbd32',
    'identification_number': 34346007,
    'item_consumption_1': 2.52,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 287.82,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'cf4dad08-eace-4d19-b105-2789c75535f3',
    'identification_number': 19451601,
    'item_consumption_1': 140,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 54.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '53b0ab43-dfb4-40a1-9d6f-55ad5356fe1f',
    'identification_number': 58738428,
    'item_consumption_1': 6798,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 867.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd86e1e5-8be0-4084-be36-6145f78b69f6',
    'identification_number': 48449520,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ac8ac8e3-d99a-4246-8e1c-9c3a9091ecc4',
    'identification_number': 95632867,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '56560480-2649-459a-a79d-1bb0d774221d',
    'identification_number': 47572884,
    'item_consumption_1': 1334,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 521.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc9c1bec-9a5f-4153-9b90-1a9e328f622f',
    'identification_number': 14000105,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '73fd78d7-dd7a-4185-b5ec-420fc8ffb0db',
    'identification_number': 62056727,
    'item_consumption_1': 106,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 44.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2455f60a-c17f-46d4-85b6-c5fed6dff5ce',
    'identification_number': 62508679,
    'item_consumption_1': 6715,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2498.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2424ea34-73d4-402b-a2a8-3d563007f2b5',
    'identification_number': 49715615,
    'item_consumption_1': 3335,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1304,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7b027ec-9fde-4691-9af8-5dcde43bd4ac',
    'identification_number': 16695135,
    'item_consumption_1': 3829,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1492.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc1dc996-4e7c-416b-a154-995a4a9e6ec3',
    'identification_number': 103039023,
    'item_consumption_1': 217,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 84.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce075afb-89d4-43d5-b0d7-57d8f44e1bd2',
    'identification_number': 27895602,
    'item_consumption_1': 50.36,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1201.12,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0709db41-eff2-4964-b316-0ce47a6c7fa9',
    'identification_number': 103157980,
    'item_consumption_1': 41114,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5247.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3badeda5-fa76-44c3-82e9-2d6940cc34ae',
    'identification_number': 106607057,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7320faec-ec0e-4345-9557-0ec871e94218',
    'identification_number': 59362057,
    'item_consumption_1': 612,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 259.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbaab611-806f-4433-82fb-08ffc2602aef',
    'identification_number': 31900852,
    'item_consumption_1': 4220,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2464.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b09f6ee1-706e-4df7-9930-fa08377d3c3a',
    'identification_number': 39214699,
    'item_consumption_1': 146,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 57.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '101c66fc-a550-4667-8b57-16122a971e7d',
    'identification_number': 77776500,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '936944d4-0fd7-48b8-9886-0760d7a533e8',
    'identification_number': 32627572,
    'item_consumption_1': 45.44,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 2643.32,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '9a0a0611-e69a-48c1-ac7d-725523ad86fe',
    'identification_number': 99569280,
    'item_consumption_1': 14.79,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 860.35,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'c3741c1b-5fa4-46ea-8485-f13d76dcef79',
    'identification_number': 104092076,
    'item_consumption_1': 1914,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 813.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f5ba2cc-954b-4eed-844c-f64e42a0b3d5',
    'identification_number': 16246837,
    'item_consumption_1': 34.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 833.84,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b10ff130-4bd0-462e-b90f-1548d22064f1',
    'identification_number': 110411196,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-440.3",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '9c93d177-5c53-4fc1-b4e1-eb478188dabe',
    'identification_number': 99082179,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0ed02d27-59e0-4674-92cd-baf45596bd31',
    'identification_number': 13847678,
    'item_consumption_1': 3693,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 471.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ede6312-a8ae-4a9f-aeb1-1cce2d10f708',
    'identification_number': 58745050,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c7124dc-bd91-4e57-b900-f52f3f86158b',
    'identification_number': 28053605,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd958118b-4e65-46a4-a57e-a9b4f9b035d3',
    'identification_number': 9741925,
    'item_consumption_1': 4039,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1573.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f027c1ef-5adb-4b5a-bd87-186ffd91d14d',
    'identification_number': 59090413,
    'item_consumption_1': 15959,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6767.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23edd204-e173-4312-ae24-314925543952',
    'identification_number': 29700485,
    'item_consumption_1': 12,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 4.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '957b1eb1-f931-43ef-a07f-9dde290cc8a8',
    'identification_number': 18273424,
    'item_consumption_1': 16.5,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 393.54,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b6543ce6-b397-4322-91f3-ffb0ed135e2d',
    'identification_number': 14430754,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a620c146-a9d1-4cdf-b01b-ff6b078dbd15',
    'identification_number': 18798594,
    'item_consumption_1': 47.72,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1387.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '05b91294-8c9f-4a58-b10b-552c55de79ec',
    'identification_number': 100223699,
    'item_consumption_1': 702,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 297.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a109a2c3-698c-47bd-8b5b-39d5565cb8a2',
    'identification_number': 104906430,
    'item_consumption_1': "'-16.85",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 08/2023',
    'item_total_value_1': "'-5403.46",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '3c204457-d25f-461c-bb6f-743258a42773',
    'identification_number': 99959739,
    'item_consumption_1': 78.52,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2283.82,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f0413c32-7ac0-4a36-a06e-9fb4f0a8a500',
    'identification_number': 63604116,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '90cd73ae-2d39-4edc-8092-0a36a47f4317',
    'identification_number': 96849860,
    'item_consumption_1': 1186,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 463.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c14b3cf-5ddd-4e29-ae37-10f3074f0daf',
    'identification_number': 109847059,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd105deca-7770-4bbb-ade8-77becba0977b',
    'identification_number': 105557668,
    'item_consumption_1': 17250,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7331.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4af63bf-6280-4a10-ada6-26a6d12c47a7',
    'identification_number': 33800677,
    'item_consumption_1': 436,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 55.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8e57d2b-6810-4296-a25f-d6f2e1effca3',
    'identification_number': 78084113,
    'item_consumption_1': 24292,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9040.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67df3bee-1430-4a9f-b269-34b59d2e1689',
    'identification_number': 111132290,
    'item_consumption_1': 23.67,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 564.55,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2ac4cc49-10c7-426c-a51d-83ae9a26f946',
    'identification_number': 41932510,
    'item_consumption_1': 2683,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4058.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '988b30d4-5aba-46dd-a38d-374e799b94d0',
    'identification_number': 92850294,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 102.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bba688f3-ca9a-4ba8-a900-563c8e8ca2a0',
    'identification_number': 75402351,
    'item_consumption_1': 77,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 30.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '80ff775f-099e-41ea-b7ea-095fc28275a8',
    'identification_number': 16133854,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 33.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd592814a-fa4d-4155-abfc-e8723bffe984',
    'identification_number': 105660434,
    'item_consumption_1': 703,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 274.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61fea40e-19c8-4987-bfcb-6f8bfd7e1c4a',
    'identification_number': 91393973,
    'item_consumption_1': 2807,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4246.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '868760f7-4f92-403f-8a88-3bf3abec58e1',
    'identification_number': 101533900,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '150d45eb-b52b-47a0-be92-f81daf52000a',
    'identification_number': 84648635,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f38c2fbd-73fc-4cd7-bc06-52ead4e10b9b',
    'identification_number': 19452900,
    'item_consumption_1': 152,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 59.47,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9bc4465c-8968-4228-9a77-772f1f899620',
    'identification_number': 68502982,
    'item_consumption_1': "'-1.588",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 08/2023',
    'item_total_value_1': "'-551.48",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '1a5fa4f4-df54-4da6-9062-053b7d6c2008',
    'identification_number': 53226429,
    'item_consumption_1': 2832,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4284.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f4c8806-314f-4ce3-8d22-b4bdbf3a5df1',
    'identification_number': 100627188,
    'item_consumption_1': 2341.6,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 55848.3,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e6705034-7d84-43f8-a266-f0b09375ff76',
    'identification_number': 72771356,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4ce4e39a-c976-4ea3-ab1c-8b0677183938',
    'identification_number': 58696172,
    'item_consumption_1': 25.58,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1460.85,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ad701b8c-e0a8-42c0-95a1-b779d1567a72',
    'identification_number': 28671139,
    'item_consumption_1': 895,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 522.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4e00d68-ae74-4ea9-9567-b64bd1aac633',
    'identification_number': 14138069,
    'item_consumption_1': 18311,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2337.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55738532-73cd-4b07-8687-74cb7ce9692a',
    'identification_number': 40705188,
    'item_consumption_1': 19746,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7348.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4e7c44c-641a-40d2-9af6-bb1e2aa722d4',
    'identification_number': 71243690,
    'item_consumption_1': 6649,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2590.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4b1a9a4-ccae-4215-bef0-cd7efdbe1a2f',
    'identification_number': 46912797,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf69b4a2-02b1-46f5-9321-a95faff08641',
    'identification_number': 16103882,
    'item_consumption_1': 7073,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 5372.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bf12511-72fe-4efc-9e15-adc03e438d7d',
    'identification_number': 9921273,
    'item_consumption_1': 7.63,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 181.98,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '8948f65b-1cd1-4f30-a3c8-46800688c2ff',
    'identification_number': 70972443,
    'item_consumption_1': 12300,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4809.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1732bbc5-f4ed-4db9-8e7e-d0d6ca3108b6',
    'identification_number': 65960017,
    'item_consumption_1': 2486,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1056.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7200b109-facc-41f7-916c-581f2dffd4b0',
    'identification_number': 923309628,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fd4d1917-ff3e-40d9-818b-6bcb61e110c3',
    'identification_number': 16109422,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '17d22d37-d4d8-4226-bcc7-04ac864d79f2',
    'identification_number': 8115699,
    'item_consumption_1': 130,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 55.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2648b0b-9f74-456b-aded-07dc33d92683',
    'identification_number': 12021720,
    'item_consumption_1': 4.59,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 1694.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ddbcc8d-d17e-43c7-85b0-9a41026b2e7b',
    'identification_number': 78084113,
    'item_consumption_1': 2.96,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 338.08,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e3e20836-a9d9-4caf-af7d-d2aa37764582',
    'identification_number': 71132996,
    'item_consumption_1': 23575,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 9198.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab8a6a36-9227-45ee-9414-caf0505c1e93',
    'identification_number': 50265938,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7a92e08e-cd8c-4554-8f7a-7e8b59876a15',
    'identification_number': 40569144,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3fd8ec2a-f86c-48a3-a41c-6dcbce968089',
    'identification_number': 12101397,
    'item_consumption_1': 59,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 24.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee6af91f-1d2e-4301-8fe6-844ec4340d96',
    'identification_number': 95917160,
    'item_consumption_1': 45.85,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1332.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5eaca0be-8267-4cc9-a44f-8f217a38c224',
    'identification_number': 34959300,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b90b5fad-4b70-4096-a590-506dc0962643',
    'identification_number': 46425470,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a968e6bf-8e47-47e0-8c7f-2686cc4af1ff',
    'identification_number': 73311235,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8295.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '205ae2fe-7b1e-406c-99a9-8cb5c50295b3',
    'identification_number': 19312326,
    'item_consumption_1': 5699,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2223.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0fa4d6cf-5047-4214-ab3b-ca6e5de8d3f6',
    'identification_number': 13643045,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8cf49f9d-469b-448e-8849-d7eefd1085a8',
    'identification_number': 31846882,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0970351d-78dc-4a6a-8a24-e6150949838d',
    'identification_number': 14755068,
    'item_consumption_1': 500,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 195.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4e521ee-0b5e-452a-b96c-a062dd9a4f4e',
    'identification_number': 39672131,
    'item_consumption_1': 1384,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 541.52,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b89275a8-e79c-4c59-bc76-7516c88bbdaa',
    'identification_number': 15981444,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ec3d0547-1c76-436b-b611-858d8700d9d5',
    'identification_number': 94952957,
    'item_consumption_1': 1051,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 409.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78b21a21-42ad-4eb9-b9ae-4324f6a1ae7d',
    'identification_number': 10103554,
    'item_consumption_1': "'-6.221",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-2161.71",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'fa085ea4-b857-4bb7-a170-5ef17eda4927',
    'identification_number': 98802739,
    'item_consumption_1': 3713,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2168.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8f06281-c0be-434c-9be0-13e59cd01920',
    'identification_number': 15091538,
    'item_consumption_1': 8548,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3618.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec44d361-f283-4479-bee5-cc6a1b7a29f5',
    'identification_number': 22679430,
    'item_consumption_1': 122,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 47.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bb9ea1c-ce6e-4b10-a112-d1c3f165cd25',
    'identification_number': 14140276,
    'item_consumption_1': 1617,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 686.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3175743d-0e12-48af-8d2c-26d39712dcb2',
    'identification_number': 84238828,
    'item_consumption_1': 303,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 118.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1500673a-1852-444d-8088-bd84ff7068d9',
    'identification_number': 77279719,
    'item_consumption_1': 19.7,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 572.98,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cfbb6492-3d60-475d-af12-c12179a13ab4',
    'identification_number': 105277002,
    'item_consumption_1': 77.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2240.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'aeca981a-ae97-4fd1-8606-3b180bbb1f04',
    'identification_number': 40359808,
    'item_consumption_1': 758,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 321.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b55b7ef6-d591-498e-a84b-e45d20f19a50',
    'identification_number': 48795402,
    'item_consumption_1': 164,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 69.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e47d66e1-5dd6-497d-80ef-e35132478670',
    'identification_number': 14799200,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-86.25",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'ac9de5d2-7e5d-4472-bf16-8c76e2f5cea4',
    'identification_number': 14660601,
    'item_consumption_1': 490,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 197.66,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': '21bde233-dd0d-427a-85eb-e10441ffe45e',
    'identification_number': 99591537,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd0539dff-ee28-4904-9e33-1779e8a48ef2',
    'identification_number': 101885610,
    'item_consumption_1': 416,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 629.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1088956d-ab26-4694-a340-6494da6e1870',
    'identification_number': 16013239,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 76.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3f1a28e3-a0ce-4289-8b0b-9f38e5514fc0',
    'identification_number': 38629232,
    'item_consumption_1': 1124,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 476.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '854fec4f-e37e-427b-9153-3b621df453d2',
    'identification_number': 46259430,
    'item_consumption_1': 436,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 184.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'caee6355-da88-458d-9b79-89d7e78cc044',
    'identification_number': 108560015,
    'item_consumption_1': 83.41,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2426.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0d74e8e0-8a18-495b-aaa5-3be847d531a1',
    'identification_number': 91611377,
    'item_consumption_1': 8000,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3125.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be94bf08-67c1-464e-8fe2-377e81ca7d93',
    'identification_number': 13789422,
    'item_consumption_1': 20.41,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 486.8,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f3e1cbc8-abf4-4dae-818d-3fad9892edb3',
    'identification_number': 95992871,
    'item_consumption_1': 5711,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2224.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bbc710f-3f65-4e28-8491-949eb1a2e6d0',
    'identification_number': 10267832,
    'item_consumption_1': 2550,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1082.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2522b836-a775-4ae3-abf3-35805b704cd0',
    'identification_number': 50940694,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '167b2ee8-b601-4678-bb1a-003d0bed7f9f',
    'identification_number': 55581439,
    'item_consumption_1': 1673,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 652.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eec0e5cf-ac8a-4fe5-9b80-a412c39106de',
    'identification_number': 29385350,
    'item_consumption_1': 1614,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 630.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10d01316-6906-40d7-95c9-2ef5c07fc7ae',
    'identification_number': 108098680,
    'item_consumption_1': 1245,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 158.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84b7467f-f5ec-40b9-bf0e-340b8d701430',
    'identification_number': 53350227,
    'item_consumption_1': 334,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 141.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85da2305-ea36-45c2-aa4e-ffb0043da9e0',
    'identification_number': 74430980,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 210.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc2c8221-2c98-4766-9ac4-0af9d44d34b3',
    'identification_number': 91798965,
    'item_consumption_1': 11029,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4314.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '624adee4-4d93-40ce-b73d-e1fd3422130e',
    'identification_number': 101119780,
    'item_consumption_1': 7074,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2995.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b82b981-9def-40a4-add3-4bad545e90ae',
    'identification_number': 14819821,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 0.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e39df4fd-a007-40a7-ba8f-de863317874d',
    'identification_number': 108646360,
    'item_consumption_1': 4200,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1783.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6227b6b2-2cd8-4344-a3bf-d21ee6ed871a',
    'identification_number': 30345359,
    'item_consumption_1': 87210,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 32454.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aeea19eb-0c98-4a0c-b735-328dce0cae58',
    'identification_number': 67536913,
    'item_consumption_1': 53.03,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1542.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fde67430-a779-406b-a688-ae6d99023b63',
    'identification_number': 107956403,
    'item_consumption_1': 20092,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2564.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ecd773ac-7ae8-4915-b696-e29bf793f6ee',
    'identification_number': 19450753,
    'item_consumption_1': 1814.4,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 52773.55,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e990ac22-75f6-4cb5-a75e-6be83067eb82',
    'identification_number': 97174831,
    'item_consumption_1': 264,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 103.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ada30d2-4ff5-4f1c-91fd-1085c8eca07b',
    'identification_number': 30569621,
    'item_consumption_1': 4021,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1701.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '486c8ce3-e6d1-4b5b-b7db-70309c057f0d',
    'identification_number': 17213711,
    'item_consumption_1': 4481,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1902.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05634b0e-39fb-414d-94ce-e71615ca5718',
    'identification_number': 43626955,
    'item_consumption_1': 7.86,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 187.47,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '891f3712-06d0-4b1c-b66f-1dfce2ef9ec6',
    'identification_number': 18517110,
    'item_consumption_1': 1402,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 547.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '719b9cde-62f6-488d-a86f-704faec810da',
    'identification_number': 65625528,
    'item_consumption_1': 387,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 151.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '228b9a94-b683-496f-bbcd-7b8427f37456',
    'identification_number': 42584418,
    'item_consumption_1': 51223,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 19062.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77c5964b-041d-4b1c-b3de-7e6f57707683',
    'identification_number': 39964973,
    'item_consumption_1': 4143,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2419.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3ca5bb2-7bfc-49de-ae5a-aa2c1a12f74e',
    'identification_number': 52933423,
    'item_consumption_1': 8074,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3418.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e19d788-ce8d-460f-8e6f-d0f254ed3a2d',
    'identification_number': 27577244,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 128.96,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '36b188c2-a98a-407f-9442-ce028d417616',
    'identification_number': 59436530,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '948a9621-91bd-4b4c-be54-c8a295b99cc6',
    'identification_number': 107977915,
    'item_consumption_1': 253,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 98.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2204818f-d5f3-4172-bdfd-f75da9a0f363',
    'identification_number': 58742115,
    'item_consumption_1': 432,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 168.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd38463aa-22fd-4788-8007-820c73bde0f8',
    'identification_number': 109421183,
    'item_consumption_1': 2184,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 925.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd214859-eb56-41d5-a35d-e38d198f6154',
    'identification_number': 14693100,
    'item_consumption_1': 3155,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1174.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f240a82-e606-4432-8d2b-3ad3a25f55ef',
    'identification_number': 109188640,
    'item_consumption_1': 531,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 225.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3b9182a-8207-422b-acba-9a3f19a89077',
    'identification_number': 70174458,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '21b3470a-dc81-4a70-b963-0f5ea41b81cb',
    'identification_number': 15087239,
    'item_consumption_1': 6533,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2770.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '623c84ff-613f-4786-89cf-a7749168fa8e',
    'identification_number': 109545907,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '48c2979c-fe7b-4195-a570-b72aea467b5d',
    'identification_number': 110527348,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e388c78-28f5-4d0d-ab9c-2cde4ffe1835',
    'identification_number': 56527144,
    'item_consumption_1': 27.06,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1545.37,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '38244cac-ccaa-4dce-9ded-20ec788f3f63',
    'identification_number': 26987415,
    'item_consumption_1': 33.65,
    'item_description_1': 'DEMANDA TUSD',
    'item_total_value_1': 784.86,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '13a36430-38f8-4381-ae26-92cc57894aef',
    'identification_number': 109592441,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9b0acbfa-715b-4b2a-b700-daae00ca8c78',
    'identification_number': 19451512,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '59b8f955-a5dd-46b5-86e4-883367839ffb',
    'identification_number': 97058343,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '427fd120-82d1-4b6a-a7ab-be3928bdce2d',
    'identification_number': 40244040,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fd8deee7-3ce6-48bd-a436-b5e63cbb9bcd',
    'identification_number': 29970474,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.21,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f9ef2dac-6362-4f39-a7c3-66d05c660c5b',
    'identification_number': 20408390,
    'item_consumption_1': 6060,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2361.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f69d0a57-94f3-4fb6-8b82-d29f6f2a506c',
    'identification_number': 110554329,
    'item_consumption_1': 2091,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 817.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2886eed7-64fb-4a1a-ac6d-4f3a4bab4b80',
    'identification_number': 13178032,
    'item_consumption_1': 19.5,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 567.17,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '160418f6-e5a6-424b-9a55-979684bf9479',
    'identification_number': 7360169,
    'item_consumption_1': 9734,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4127.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f741df00-d7fe-4085-b5d0-8c8ff384b1a6',
    'identification_number': 101499531,
    'item_consumption_1': 6753,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2633.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22e37d20-aa82-425a-b057-46ca15eb078c',
    'identification_number': 19450745,
    'item_consumption_1': 759625,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 96955.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2fc1bff6-673f-4607-8c9b-79b222f9e10b',
    'identification_number': 59455624,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7ddfa357-c484-423c-9ca7-354f4757bef4',
    'identification_number': 106732358,
    'item_consumption_1': 340,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 132.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04fff8bd-4c36-4c4d-93fd-9b7ceddedc74',
    'identification_number': 91481139,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1552d3a-46de-4a16-828a-c9bc76b8282d',
    'identification_number': 32154836,
    'item_consumption_1': 6470,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2521.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c6c8386-d205-4858-a4d8-ddb73eef70bf',
    'identification_number': 96517956,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65b65094-e8f3-4477-ab06-78d937bf0cb1',
    'identification_number': 95102132,
    'item_consumption_1': 214,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 83.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '065658b8-dc47-49bb-9cd0-1332bf3f6a56',
    'identification_number': 38432005,
    'item_consumption_1': 25786,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 10042.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '248f107f-4f2c-4ef5-b458-f9a9c92dd5ec',
    'identification_number': 5811848,
    'item_consumption_1': 2075,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 880.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6266edab-e444-4099-8d76-d0d850d65070',
    'identification_number': 44606931,
    'item_consumption_1': 172,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 67.11,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '823a3527-f44c-4876-bd3d-91e7f28a0002',
    'identification_number': 110973240,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b5108cf2-bb90-49cf-bb3f-c7d537dfa562',
    'identification_number': 103765450,
    'item_consumption_1': 307,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 130.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35d5b2ef-b0f0-4c0a-a8f1-cd99b56effc7',
    'identification_number': 43505457,
    'item_consumption_1': 2746,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1072.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a70bec0-a935-423b-90f3-e2f95104804a',
    'identification_number': 59362057,
    'item_consumption_1': 612,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 239.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c172d690-11a8-4c18-a1de-2f28e07adf11',
    'identification_number': 11013958,
    'item_consumption_1': 0.18,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 4.32,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c73ce820-ca8c-45b7-bf2c-122f26926fc6',
    'identification_number': 101302622,
    'item_consumption_1': 1460,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 617.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e55c59f8-1601-49a3-afe9-269df10ecc06',
    'identification_number': 32154836,
    'item_consumption_1': 6470,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2740.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7068380-c018-428e-9bc8-28335e4dc6ee',
    'identification_number': 90879791,
    'item_consumption_1': 5712,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2425.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '412d00c5-d282-4207-8e08-26d06cd0890f',
    'identification_number': 55454500,
    'item_consumption_1': 28,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 10.94,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8c8e07ea-362e-48af-93e6-cc95904e6eba',
    'identification_number': 62088777,
    'item_consumption_1': 4766,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2020.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '820f2bd8-d2fa-4e60-a161-b3b028df8a9b',
    'identification_number': 37438581,
    'item_consumption_1': 752,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 319.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9868f927-3489-45dc-8f9c-4992f683219c',
    'identification_number': 34438050,
    'item_consumption_1': 71,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 30.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b70262c8-daf9-4a73-9790-e708f8a8fde1',
    'identification_number': 29511666,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b2843e2-46ba-458e-8d8a-2bb20c5b9f80',
    'identification_number': 14625415,
    'item_consumption_1': 1060,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 448.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49d74696-17d4-4cbb-8fdc-ecd49f95eb5c',
    'identification_number': 102362378,
    'item_consumption_1': 1905,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1112.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b496c85e-50f6-4238-864a-0dac690c1176',
    'identification_number': 64416534,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87266f71-4e4a-40b7-9b02-07e2d87e1c4e',
    'identification_number': 104871563,
    'item_consumption_1': 16269,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6054.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dddfa8f6-4558-4416-9e2f-e7d5e4500460',
    'identification_number': 38250489,
    'item_consumption_1': 554,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 216.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f27fb45-0e32-48fd-bac8-7cdbdaf73700',
    'identification_number': 37423258,
    'item_consumption_1': "'-32.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2022',
    'item_total_value_1': "'-11.14",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '13627686-eb24-410b-b53f-cd106c04bc16',
    'identification_number': 64175251,
    'item_consumption_1': 97.31,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 5557.36,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8e0d0b01-e133-414c-b637-a2940fd12481',
    'identification_number': 17355265,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0bedc678-77ed-412c-9b61-204fa5172631',
    'identification_number': 19453299,
    'item_consumption_1': 22927,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 13390.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a687b7c-fb11-420d-b704-198a1c9dc7a5',
    'identification_number': 108309835,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2defb6ee-b1a9-485c-b1f4-bd53ab7c48fd',
    'identification_number': 6776582,
    'item_consumption_1': 941,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 366.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80b52671-35a3-40f3-b31a-fbe706e82493',
    'identification_number': 39208869,
    'item_consumption_1': 109173,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 40628.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fd873ad-1a67-4f55-aed9-d3f8457aa684',
    'identification_number': 93102895,
    'item_consumption_1': 2528,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1073.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e5acd06-34be-4a87-839d-f614aed9588b',
    'identification_number': 44503660,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46663bce-2c8c-4790-ab54-5428ec983681',
    'identification_number': 101885610,
    'item_consumption_1': 416,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 242.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c88cc14-0bea-48bf-9db9-e06092c62421',
    'identification_number': 96580976,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a24e2bd-2787-422b-ad73-585444e96869',
    'identification_number': 96313897,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e85c8080-000a-4683-849e-451e24ab3273',
    'identification_number': 17735530,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6fe6d3fc-4cde-41af-ac56-e7692a7fdf1c',
    'identification_number': 41456440,
    'item_consumption_1': 4415,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1721.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42f53e6b-b048-41ff-86b2-074bdfc60ff8',
    'identification_number': 108601676,
    'item_consumption_1': 160,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 93.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f80e8aa-0a10-4b51-8cd1-b5b63b41d164',
    'identification_number': 19452934,
    'item_consumption_1': 1981011,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 252848.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f96e4f72-a6d6-4049-bb12-2f13c39a7ab7',
    'identification_number': 96279257,
    'item_consumption_1': 490,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 207.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6547dae-3d42-4103-afd9-8466e5714fd9',
    'identification_number': 39196461,
    'item_consumption_1': "'-2.147",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 09/2023',
    'item_total_value_1': "'-747.46",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'c370e1bf-7d8f-497d-b040-24857a2cbcb6',
    'identification_number': 36878685,
    'item_consumption_1': 34406,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4391.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ce734ee-ae74-4d58-be16-0a14f6361b97',
    'identification_number': 55900291,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0754af1d-946b-4e3a-b2c3-b02494a242ad',
    'identification_number': 53507460,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c37bbda0-6db5-4610-a60d-f09fe3278f7d',
    'identification_number': 59437308,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '58a29822-1723-4eea-98f5-803f53f46d86',
    'identification_number': 40995305,
    'item_consumption_1': 57413,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7327.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6298fc04-16b3-4532-b08c-87e7d6a2825f',
    'identification_number': 11432616,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 240.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4dda6cb4-5e7a-48a5-9e01-12224e3ecc43',
    'identification_number': 105251542,
    'item_consumption_1': 406,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 158.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa7a9f26-8fb0-4a74-9f0c-15090700e5dd',
    'identification_number': 91302749,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1781a428-3023-4376-a100-d0e46ef6c699',
    'identification_number': 81777302,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2655.02,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3edeb6c9-28e7-46d0-beda-a78737fbc5f4',
    'identification_number': 32234058,
    'item_consumption_1': 34742,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12929.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '204a85a5-b724-47a6-bad4-e1d508967de8',
    'identification_number': 101835981,
    'item_consumption_1': 31739,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 13466.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ad58608-cb7b-4c37-a85a-6a50698baadc',
    'identification_number': 18502016,
    'item_consumption_1': 453,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 177.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6a1205d-ae56-4099-960b-c11ccb851316',
    'identification_number': 93234724,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed1354c9-d959-4f00-8fe7-f39158a312d3',
    'identification_number': 53573749,
    'item_consumption_1': 134,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 78.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe0fff81-e9bb-4cbe-ae16-a47ad3d4e260',
    'identification_number': 26022460,
    'item_consumption_1': 207,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 87.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e8b8c04-5606-46a6-9d0c-5ca4d6e6b8e1',
    'identification_number': 26750546,
    'item_consumption_1': 2602,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1105.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff168212-51ba-478e-9a9c-491fbe60fb19',
    'identification_number': 34617426,
    'item_consumption_1': 3156,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1843.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d4d94fd-24cf-4e8e-b500-fbe3db1080ef',
    'identification_number': 91641209,
    'item_consumption_1': 698,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 272,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbd7429d-c4f6-4411-996e-937a3a96963a',
    'identification_number': 106358340,
    'item_consumption_1': 190,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 74.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88dc1612-795d-4c29-aeb4-38dad79ab488',
    'identification_number': 108048110,
    'item_consumption_1': 239,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 101.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4638f173-105b-42bc-b360-caa67b1b8886',
    'identification_number': 98662007,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4378dc30-fd63-4d25-8de7-ed6f2124064e',
    'identification_number': 17023491,
    'item_consumption_1': 0.78,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 18.62,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8e327573-d26c-475a-b2ab-e04672428ec0',
    'identification_number': 94385394,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '05a2da6b-e89e-4eec-8dbf-7b32cb1001c8',
    'identification_number': 5415870,
    'item_consumption_1': 148,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 223.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f7f234e-879c-4f42-b30e-d67401448489',
    'identification_number': 96336242,
    'item_consumption_1': 514,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 218.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5933d52-4cc5-436a-a7a2-d55b65eda42d',
    'identification_number': 37507940,
    'item_consumption_1': 94.66,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2753.26,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '857c1517-9264-4009-9abf-806417fa882b',
    'identification_number': 97970034,
    'item_consumption_1': 17134,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2186.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6514773-a9fe-43b8-9c50-4797d09b31ea',
    'identification_number': 3033182789,
    'item_consumption_1': 30,
    'item_description_1': 'Custo Disp Uso Sistema TUSD NOV/23',
    'item_total_value_1': 15.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ec7b124-f4fb-4a6f-8b61-a83e3388b4dc',
    'identification_number': 79665306,
    'item_consumption_1': 74781,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 9544.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f3686e8-f03e-4731-a89b-e8d7158dd298',
    'identification_number': 108206572,
    'item_consumption_1': 1119,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 475.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6abbfb6-3660-4ef5-ac21-bca5110ac3d5',
    'identification_number': 65685326,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '70ce25bd-5c6f-4566-b77e-1529b63641b6',
    'identification_number': 48875325,
    'item_consumption_1': 2.3,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 54.86,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'dacf418b-29fa-4403-84f1-ed335260828f',
    'identification_number': 101515600,
    'item_consumption_1': 2964,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1157.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8899096c-a811-430e-986c-248221f39cd8',
    'identification_number': 5576369,
    'item_consumption_1': 643,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 273.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '689114f3-2c51-44a3-bec8-2fc22b248ba7',
    'identification_number': 95400044,
    'item_consumption_1': 7098,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3013.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62fbd936-b092-4d21-855e-e4ed1ef37d11',
    'identification_number': 111132290,
    'item_consumption_1': 2126,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 271.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe20c20e-f63e-43b7-a17c-9829a83310e2',
    'identification_number': 97261297,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '289b17da-721f-4851-9f75-ec24b498911c',
    'identification_number': 97140775,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b323b53-eb06-4047-bd6d-14af42f1474a',
    'identification_number': 99580977,
    'item_consumption_1': 5792,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2455.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a0d9725-0dc3-440c-895d-1664185dc50d',
    'identification_number': 37969064,
    'item_consumption_1': 8192,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1045.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '021a1205-d5c8-4556-8ca9-625b93da01ba',
    'identification_number': 13894838,
    'item_consumption_1': 1618,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 686.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0dc4d7d9-c794-4747-a169-b1b187eb59cb',
    'identification_number': 56527144,
    'item_consumption_1': 179,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 70.03,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2aa398a7-436d-479e-8f38-8c5e5b6919d0',
    'identification_number': 101572816,
    'item_consumption_1': 643,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 272.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e742e9c-04df-4ecb-8f6e-e60674643e30',
    'identification_number': 8576513,
    'item_consumption_1': 9144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3882.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ea5a8c5-8e3b-4b33-8546-2b7cfa8dc544',
    'identification_number': 14619563,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '74bad65b-bdee-460b-9a60-6877e2185250',
    'identification_number': 106729136,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3426df10-996e-443b-a7b9-f8606e055855',
    'identification_number': 77431650,
    'item_consumption_1': 164,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 63.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1999c5f0-e176-4bac-9163-b5c1b2f0445d',
    'identification_number': 105562840,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a040eac3-c4b1-458b-a275-888c473aad25',
    'identification_number': 52635260,
    'item_consumption_1': 246,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 104.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4b49cb7-7324-43e3-b297-2c4754770ccb',
    'identification_number': 97819298,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f076d45b-2cc7-47b7-ab9e-c6a6b78d42c0',
    'identification_number': 33485631,
    'item_consumption_1': 3065,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1301.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef7dbe6b-c038-4710-a7f2-a85be9288537',
    'identification_number': 108106128,
    'item_consumption_1': 168,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 62.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b053817a-23e1-4562-b80c-bd616e1aa1c7',
    'identification_number': 108852148,
    'item_consumption_1': 2261,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 883.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5e652e6-dc1b-4734-8dc2-3f1f58fb2fd5',
    'identification_number': 19281552,
    'item_consumption_1': 417,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 177.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0413326d-a34a-4a33-bb82-60211fe87edc',
    'identification_number': 110977360,
    'item_consumption_1': 1959,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1144.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6da50e4b-5069-407c-a411-3f2edbf12c7f',
    'identification_number': 97779113,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bac75661-c056-45da-af91-5f75d4d730d2',
    'identification_number': 94623996,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '51365886-ba9e-46ca-89ad-42dda53256ae',
    'identification_number': 103005242,
    'item_consumption_1': 805,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 341.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af9dd3c4-32bc-4107-8df1-53af40b1512e',
    'identification_number': 89667646,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '706c67a9-73b6-436d-9dca-c438fa24ff40',
    'identification_number': 81333480,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3d6cc0a5-fb8d-44b4-a5a4-352d45e55e1f',
    'identification_number': 18616909,
    'item_consumption_1': 6980,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 890.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e3f4d8c-4571-4d0c-917b-e61be74f6dab',
    'identification_number': 103879889,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f67e3857-2f43-4210-b855-c96321378473',
    'identification_number': 37612280,
    'item_consumption_1': 633,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 247.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0906a36-6bcb-4fd9-9857-2d97c58ebfb8',
    'identification_number': 105186520,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 23.39,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9b8b0123-af33-464b-a4a6-44f1eff6ef1f',
    'identification_number': 97425907,
    'item_consumption_1': 1906,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 243.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea1071c7-b684-4fa7-986d-1b1594589d16',
    'identification_number': 105953261,
    'item_consumption_1': 2.55,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 148.33,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5225dbde-28fb-4805-9899-c8f0d515a57e',
    'identification_number': 9921273,
    'item_consumption_1': 28283,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3609.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77e0bd49-ead7-47d8-aea4-ea9f5c024ddb',
    'identification_number': 47623578,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff647c5e-8c91-459f-9bd4-04677b57e9e8',
    'identification_number': 39365980,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93bf99d0-afd7-4136-8290-001a82e9ba4d',
    'identification_number': 64554481,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6915.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d6325ea-7cdc-4126-b5ac-da0030def47d',
    'identification_number': 109697693,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1f38438c-63a5-40af-9fbe-c16ac9ea3204',
    'identification_number': 34680292,
    'item_consumption_1': 2818,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1645.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e41b3690-5e36-4db9-ad69-63cceeec18b6',
    'identification_number': 22935940,
    'item_consumption_1': 15080,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5611.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7558214-8405-484e-9a73-6a1ef2e25507',
    'identification_number': 16701917,
    'item_consumption_1': 11626,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4931.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '941b78c1-dd15-41c0-8eb0-fb2eae57022c',
    'identification_number': 48681261,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c18f2a2d-2705-4d81-87e9-180334f241b0',
    'identification_number': 44805616,
    'item_consumption_1': 59891,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 22288.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c691d83-924b-4b7c-8840-f8b1b5abf4a4',
    'identification_number': 929824059,
    'item_consumption_1': 2042,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 1780.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0789adb8-d55f-4c14-b3ea-1dc39e2c4b34',
    'identification_number': 46424709,
    'item_consumption_1': 3845,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1627.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ed5d682-937e-43ef-8e5e-0d1f74da3fa4',
    'identification_number': 103367268,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '19a5b403-d78c-4c6a-880b-55a748b55625',
    'identification_number': 19295855,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 107.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf983a80-532a-4e2c-aab7-d0e1f92a2ad2',
    'identification_number': 100797113,
    'item_consumption_1': 12114,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4508.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d1df9a3-0778-449b-af54-ef81d3872eb0',
    'identification_number': 23899166,
    'item_consumption_1': 19.38,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 462.24,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'dcaeabdd-f551-475b-beb8-4117a657d9dc',
    'identification_number': 110994809,
    'item_consumption_1': 2303,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 898.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '234431f8-a981-4e4d-b238-f0ad3b90e906',
    'identification_number': 18855636,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4969cd72-dd0e-42d5-919c-1b6869cb0e36',
    'identification_number': 35717661,
    'item_consumption_1': 446,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 174.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5516c206-989b-4b2c-af29-a948dbe07ec3',
    'identification_number': 93988621,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '646f4e71-e20b-49bc-a7c8-b099bd6b45d3',
    'identification_number': 15695646,
    'item_consumption_1': 3410,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1449.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5471641e-24b1-4266-9713-3f2a118751a0',
    'identification_number': 85543691,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd48104dc-a91e-4a73-9ea3-4b512d5bff18',
    'identification_number': 30491100,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '712f84d0-e229-4742-b8aa-b0f3c97effe8',
    'identification_number': 48432130,
    'item_consumption_1': 5078,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2965.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '727a54cd-356a-43c5-9dc7-0483f3f00536',
    'identification_number': 110411846,
    'item_consumption_1': 285,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 111.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e88dda9-d9a4-4317-aef9-3496629f9ead',
    'identification_number': 35119624,
    'item_consumption_1': 18925,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 28630.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9863a26c-590f-4cef-a356-bfa0df73eaea',
    'identification_number': 94728526,
    'item_consumption_1': 769,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 299.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18699967-0628-4fd6-ae32-0cc6f975536f',
    'identification_number': 91799376,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c28f4462-b8ba-45ca-b5c8-21655ed56a37',
    'identification_number': 15353435,
    'item_consumption_1': 10060,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4263.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9dd58d10-d006-44fc-b9ec-e4b1632c9a27',
    'identification_number': 103501967,
    'item_consumption_1': 1810,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 707.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7924928-63ba-40b8-a980-b20aca42422a',
    'identification_number': 92002927,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83539eb5-0af8-4d9f-8c9f-fc8bccc65a5b',
    'identification_number': 91975522,
    'item_consumption_1': 1360,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 529.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24b8c1fb-86a0-4eef-b396-8fa5b415206c',
    'identification_number': 44503660,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e5385221-bdd3-4c98-8559-b0d854685363',
    'identification_number': 107202522,
    'item_consumption_1': 366,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 155.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7ae6670-bf94-4571-8595-82d1983f3d41',
    'identification_number': 40432980,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52fb1166-af63-4059-adea-9574bc53eda2',
    'identification_number': 108637840,
    'item_consumption_1': 1466,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 620.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cdeb3d89-10f6-4bd0-9b6c-aa431d4a74e6',
    'identification_number': 8576513,
    'item_consumption_1': 9144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3571.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a6c386f-7963-435a-8054-466ba77f8e0a',
    'identification_number': 107297671,
    'item_consumption_1': 5350,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3124.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ab70aee-bf7f-4217-8527-47be6be80361',
    'identification_number': 43036830,
    'item_consumption_1': 189.61,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 10828.62,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5987df87-ea04-42e1-b530-7fe1860901b3',
    'identification_number': 13327623,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5828d268-a9c4-498b-aaa8-d2a2077ad6f6',
    'identification_number': 100872433,
    'item_consumption_1': 105,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 44.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '821975a6-aee2-42df-b3b3-89a9c638675f',
    'identification_number': 32605579,
    'item_consumption_1': 66,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 25.82,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5407f80e-dd45-4691-a573-7616eb1a17f4',
    'identification_number': 44774494,
    'item_consumption_1': 14,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 333.92,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '91b25e99-cf18-4ccf-90d9-00342ea55a5d',
    'identification_number': 103033750,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1b6b69f9-8590-4548-8f67-c22cdf568671',
    'identification_number': 13164570,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '27aa552b-0691-4aa3-84f9-2a4245b0c3f7',
    'identification_number': 97072494,
    'item_consumption_1': 655,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 990.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87b5e260-74ca-44ee-b4cb-dfb222cb26df',
    'identification_number': 72702141,
    'item_consumption_1': 111759,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 65271.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8cc6feed-243c-41c2-bcf6-662b077e7ef8',
    'identification_number': 7376880,
    'item_consumption_1': 1993,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 844.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08426897-36a4-4245-9cb0-b852e597110b',
    'identification_number': 96256346,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9307116-19c4-4d6a-883a-d75a3202c276',
    'identification_number': 14619563,
    'item_consumption_1': 7714,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4505.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6caa8e3b-246d-4694-8e9c-4d7a7c6d3a7c',
    'identification_number': 96026782,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '963aadbf-2896-46d5-adc0-13e7aa526367',
    'identification_number': 101870728,
    'item_consumption_1': 1644,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2487.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '055743ee-cf49-48de-91a8-302ee7cbdc1e',
    'identification_number': 38089807,
    'item_consumption_1': 4363,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1703.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69167d42-268b-4b39-8a22-fae69bf83eff',
    'identification_number': 16246837,
    'item_consumption_1': 0.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '32f300a0-2f79-4b49-a2fb-f3e5f5be4480',
    'identification_number': 106939203,
    'item_consumption_1': 292,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 123.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27ee8261-3afd-4d65-be04-ef20b56446ef',
    'identification_number': 40856089,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cec9271b-997f-4f0c-af5c-ea338324124d',
    'identification_number': 32715811,
    'item_consumption_1': 90,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 52.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9aab9310-0c3c-4af3-9012-bb9c827640bb',
    'identification_number': 40207285,
    'item_consumption_1': 10.84,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 1238.13,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'edcb5d70-c471-4b71-a2dd-326b6945b2d6',
    'identification_number': 38738147,
    'item_consumption_1': 5469,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2319.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74279e2a-8c08-4680-a7a7-a55112b2826a',
    'identification_number': 91394031,
    'item_consumption_1': 4186,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6332.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4cfe5504-859e-4c20-abe9-e27f985a29af',
    'identification_number': 33800677,
    'item_consumption_1': 3148,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 401.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a23e198-677f-4898-a50a-b243cdf6acab',
    'identification_number': 92672051,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '37d56021-d4b2-42df-9d67-f7b5daa58cea',
    'identification_number': 16246780,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c71a7913-947b-4a4e-b64d-e24b873f8cf4',
    'identification_number': 101439075,
    'item_consumption_1': 3155,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1233.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1dfb13e3-d089-4baa-9d82-15358b828f9d',
    'identification_number': 15682277,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '68cc8109-4122-4f52-98ed-22628bcdbc83',
    'identification_number': 102261016,
    'item_consumption_1': 763,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 97.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edb79cb0-90a4-4e67-8559-b6e3ced4bd67',
    'identification_number': 99258870,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 4.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '52755516-1ac5-4ee9-839b-1443ab06a855',
    'identification_number': 92562302,
    'item_consumption_1': 19.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 553.78,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6f6ca15c-ad7b-4d44-b55d-6931acb24468',
    'identification_number': 110270410,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c529528e-8cfe-4b12-9b59-794560163405',
    'identification_number': 19229747,
    'item_consumption_1': 579,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 338.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80875e59-fc3f-41a0-8841-4a8bfeb17348',
    'identification_number': 34617426,
    'item_consumption_1': 151,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 59.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3ce4d1ce-b161-44d3-b78b-7ab556a88b99',
    'identification_number': 6924999,
    'item_consumption_1': 295,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 114.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '371b29ea-a7af-4a2e-8902-4f60a72a3dcd',
    'identification_number': 41290984,
    'item_consumption_1': 5574,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2172.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1ece9c0-c6eb-406e-ae8b-da957bdc9f3a',
    'identification_number': 15474380,
    'item_consumption_1': 10000,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4244.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2429664d-d8f9-4849-8aae-538800ca7a73',
    'identification_number': 16111478,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 55.85,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '114ebf92-599d-4e8e-b33c-06295c4b91a3',
    'identification_number': 75403943,
    'item_consumption_1': 64.15,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1865.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '69e4f94a-5fc5-47e1-bed4-14f1feef371a',
    'identification_number': 60767928,
    'item_consumption_1': 33.44,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 797.57,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2b8656e7-1b8f-465d-a99a-78ef53548505',
    'identification_number': 23074221,
    'item_consumption_1': 72,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 28.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '814f8f0f-ecdd-420c-ba39-3990c81c1767',
    'identification_number': 104764503,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 41.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c981c482-d98e-42ce-a987-bdf5c5728530',
    'identification_number': 102005141,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '056461ef-08ef-45bd-bcd2-0eed21ac7c62',
    'identification_number': 101540620,
    'item_consumption_1': 11092,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4700.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8799e39-7f5b-44b3-bced-db0467af0d40',
    'identification_number': 96254777,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3810f25-28d5-47f9-b91e-4813f0c344ab',
    'identification_number': 48827657,
    'item_consumption_1': "'-7.113",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 04/2021',
    'item_total_value_1': "'-2271.62",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '3692dca1-2150-41f3-8339-acbe50a14a59',
    'identification_number': 40207285,
    'item_consumption_1': 7482,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4369.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1c45fc6-891a-48cd-98e0-ac426550d954',
    'identification_number': 91863899,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f25646fa-99f1-43d4-8915-f77c217fcc00',
    'identification_number': 59806753,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4acb4e13-1f9e-483f-8728-f6a2b94a4990',
    'identification_number': 104179228,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 243.52,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '440c87e4-e7bb-44f2-8dd5-625ec6e57c36',
    'identification_number': 108600157,
    'item_consumption_1': 5730,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2429.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a65f8a9f-39ce-4796-b661-ad0cc893e6d9',
    'identification_number': 110228308,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '700b9f0e-7158-4ac3-a142-8fdbc3a1603e',
    'identification_number': 22358609,
    'item_consumption_1': 4287,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2503.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82aab1b6-61b3-4cf6-b453-61e5363603e1',
    'identification_number': 75402351,
    'item_consumption_1': 1480,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 864.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '600c5eea-f9d2-4fa3-a4ef-cbf2ee82602b',
    'identification_number': 97838233,
    'item_consumption_1': 1100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 430.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2a2357d-8c11-48e9-901c-d8dc6b2a6806',
    'identification_number': 11095504,
    'item_consumption_1': 1261,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 491.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4a2007b-713e-4e0b-b32d-8e17dde763d0',
    'identification_number': 95847952,
    'item_consumption_1': 74.43,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2164.85,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cfc9199d-9146-4439-b8b3-d2547909f182',
    'identification_number': 66353564,
    'item_consumption_1': 779,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 330.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19016e4b-320c-4f7b-9979-34ce57b587dd',
    'identification_number': 99288621,
    'item_consumption_1': 5301,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2246.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a97c3a2d-5ef0-4e7e-b5de-444331e8fd12',
    'identification_number': 23221275,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47603c90-c8f4-4697-a59b-1d9810bbc3ba',
    'identification_number': 109034333,
    'item_consumption_1': 384,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 224.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82ac358d-6ffe-4449-913a-d801c9ca0812',
    'identification_number': 54073901,
    'item_consumption_1': 1317,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 769.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '174ffe91-7cbe-4784-8fa4-0961bc7d8874',
    'identification_number': 81500513,
    'item_consumption_1': 13574,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5051.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a762f84b-f2c0-4c01-bdb4-b5f828c7266e',
    'identification_number': 40611914,
    'item_consumption_1': 19970,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7431.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '272c9c80-48e2-445f-ac06-d8eb56be105c',
    'identification_number': 15101797,
    'item_consumption_1': 1677,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2537,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2083bc89-9e20-4c19-a10c-a583fdee3c30',
    'identification_number': 12804924,
    'item_consumption_1': 680,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 288.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b254f77b-1880-496a-a5a3-463f5cdaf308',
    'identification_number': 71435964,
    'item_consumption_1': 9512,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4028.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f50d7c5-efb4-4f82-8813-f35bbc5d391d',
    'identification_number': 13977873,
    'item_consumption_1': 11394,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1454.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '383634bf-de91-4c88-8fa6-16d55f29cef3',
    'identification_number': 105219304,
    'item_consumption_1': 565,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 239.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '226131a3-66a1-42cf-aba2-e4a732bc365f',
    'identification_number': 36878685,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'daf27eb0-e965-47d6-82bb-208a3087cfcd',
    'identification_number': 105611271,
    'item_consumption_1': 2716,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1586.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccacbbe1-bd56-48e8-b5f7-dc02c61deb31',
    'identification_number': 10894608,
    'item_consumption_1': 4758,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1856.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0d379ad-e898-4159-acbb-e402ed1befcd',
    'identification_number': 108098680,
    'item_consumption_1': 81.81,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2379.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'eabd22d7-a0be-4d0c-80a2-6c9c7e005059',
    'identification_number': 10471847,
    'item_consumption_1': 3274,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1385.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ebeff08-c1f5-469c-b541-d528d06679a3',
    'identification_number': 69703515,
    'item_consumption_1': 11398,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4446.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a54f7b9-af95-4d39-b73a-951a0559b65d',
    'identification_number': 87252031,
    'item_consumption_1': 1925,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 751.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '199b27b5-b63f-4a31-9eee-b0917442fe26',
    'identification_number': 97779113,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1025.27,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '39b5d363-8f83-48bb-9c8c-437d8c9e08ab',
    'identification_number': 9733531,
    'item_consumption_1': 6090,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2586.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23520b13-4afd-454a-861d-d7c41d07ba08',
    'identification_number': 12222615,
    'item_consumption_1': 91110,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 11628.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '209e9397-c4e4-42e2-aa65-e1df4c79c9cf',
    'identification_number': 105761338,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1894293-0b14-4a4a-abb0-dd4ade2f7078',
    'identification_number': 66066760,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5ce65919-720a-4ff5-abcf-3af0d7fd8c95',
    'identification_number': 29659043,
    'item_consumption_1': 5111,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1993.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e06a8e7-3e59-4778-8dd7-9ae57a92bc14',
    'identification_number': 60066245,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93f23701-6448-422f-8ff8-431d76a99bb9',
    'identification_number': 40859401,
    'item_consumption_1': 2346,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 995.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fe8b37c-9000-4b05-b6fb-046cd3874ef6',
    'identification_number': 93748604,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '981d620a-4071-4059-8c0a-6f8935f4e5d4',
    'identification_number': 106136712,
    'item_consumption_1': 9250,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3442.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f6ab4b1-7ec1-45c5-8cd9-aa2af9001bbc',
    'identification_number': 91860369,
    'item_consumption_1': 626,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 244.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52fd4234-9bb5-4c3e-acba-f575962e029e',
    'identification_number': 103466118,
    'item_consumption_1': 3232,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1264.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41053435-6a9f-4bfa-baef-84518d86bef1',
    'identification_number': 106868250,
    'item_consumption_1': 369,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 215.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f90f8be-dad0-4a4b-aea0-5b8fd672d171',
    'identification_number': 19450303,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 204429.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfa26913-af5f-4c5c-82ab-7ef9aae2e4d7',
    'identification_number': 103438157,
    'item_consumption_1': 2064,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 263.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eef2f36f-074f-4d10-80ff-9b77d0f8388d',
    'identification_number': 56544545,
    'item_consumption_1': 1600,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 934.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a46c6260-03ae-46b5-bd61-50d1060e187e',
    'identification_number': 93953674,
    'item_consumption_1': 1054,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 411.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e704e89b-5771-4de9-b02c-d1f9feb387ef',
    'identification_number': 59814993,
    'item_consumption_1': 4424,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1875.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '892b732f-49a2-431f-955f-98efc5a7d742',
    'identification_number': 105804339,
    'item_consumption_1': 622,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 242.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c545c0b-5b63-4c1d-bd5f-6a036aff9582',
    'identification_number': 66530822,
    'item_consumption_1': 6578,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2792.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fbe8c80-91ab-4536-8fc0-c806b5b4eee0',
    'identification_number': 104707127,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38b00cf3-eeed-4994-80d3-1139bf6c3041',
    'identification_number': 98349821,
    'item_consumption_1': 431,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 182.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '155328f2-6ed8-45dd-86e0-3bb12c215539',
    'identification_number': 59436956,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5bb7c40e-cffc-45ba-86e0-b498fdfdc789',
    'identification_number': 45115745,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'da34e226-fd2c-4527-9070-6ce37354a47d',
    'identification_number': 17725755,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c3cc0c74-c72e-4f6a-992a-9f138a6a79db',
    'identification_number': 108380513,
    'item_consumption_1': 18075,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6726.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a97209ae-fc5f-4118-b312-2ba077bab3cc',
    'identification_number': 106605712,
    'item_consumption_1': 13,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 5.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'dd205685-03e2-443d-b615-66b848e35283',
    'identification_number': 61773042,
    'item_consumption_1': 5236,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2040.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a313f90-a6c4-41ef-a601-3bee3012ba72',
    'identification_number': 43036830,
    'item_consumption_1': 9927,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5797.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69dd024e-1b54-41fc-87a0-283e241cd404',
    'identification_number': 13028855,
    'item_consumption_1': 1638,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 209.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '385f469c-1082-49b5-bb83-898c7ae4f0bc',
    'identification_number': 107466732,
    'item_consumption_1': 1210,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1830.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a28b5b6-19e3-4bba-8b00-3b1c6c005fcd',
    'identification_number': 14905612,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 41.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4e318b4a-bf35-4642-8525-bc568e74d57d',
    'identification_number': 97807141,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f7e5ab65-94e6-48c1-8b58-f89f39774f18',
    'identification_number': 32076614,
    'item_consumption_1': 148,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 62.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5f21df7-9340-4e5c-accb-8860f55ccf8f',
    'identification_number': 107433427,
    'item_consumption_1': 3058,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1295.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f404e96c-05a8-4702-9fa2-7db878931a03',
    'identification_number': 18184006,
    'item_consumption_1': 2508,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 977.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f40249e-353d-4120-946b-781675132e77',
    'identification_number': 42396905,
    'item_consumption_1': 17574,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 10263.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ede0f6ec-2df8-4705-913e-2fca5583ecbe',
    'identification_number': 18643582,
    'item_consumption_1': 1255,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 160.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ead183d6-d875-4f63-99b9-d3843b455306',
    'identification_number': 79229093,
    'item_consumption_1': 199,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 77.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0bf88df0-c5e6-485d-831c-f1f12d0a9ed4',
    'identification_number': 14819821,
    'item_consumption_1': 1.4,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 33.4,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '44f76168-76ee-4dff-b78e-3cd23252ffe1',
    'identification_number': 64175251,
    'item_consumption_1': 2.1,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 50.09,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '70d2b651-ba92-4fad-93a5-835c9741005a',
    'identification_number': 19452934,
    'item_consumption_1': 1981011,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 737228.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e00700d-af27-4d3c-a960-d9c9c8d4cfa0',
    'identification_number': 15913805,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '070e9b8c-c616-46de-9978-aae62d2ad8ab',
    'identification_number': 6167187,
    'item_consumption_1': 266,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 103.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69542c56-cfa6-41a1-b230-4fb8f9895560',
    'identification_number': 12026242,
    'item_consumption_1': 0,
    'item_description_1': 'COMP VIOL META CONTINUIDADE 09/202',
    'item_total_value_1': "'-77.65",
    'reference_month': '11/2023',
    'referente': 'Indicador de Qualidade de Energia',
  },
  {
    'uuid_items': 'ace6a551-b4a3-433a-84b2-e7ea607332f4',
    'identification_number': 107951533,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0f8faa73-6710-4eed-a8af-343e3aaedce7',
    'identification_number': 11103892,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b15b19d2-3f05-4748-9458-d1efa0e6de8b',
    'identification_number': 64416534,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1546a1c4-5781-4cc2-a60f-c61c0fdc2087',
    'identification_number': 99505266,
    'item_consumption_1': 275,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 116.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '140c0788-b47e-4c4a-b83d-6e292ae93662',
    'identification_number': 96625104,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6467f282-f171-469f-bdc7-c853a4aaa3f7',
    'identification_number': 75192900,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6b44c768-c7bc-4db5-855a-f5019edd583d',
    'identification_number': 13894838,
    'item_consumption_1': 1618,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 631.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3de664c9-4ea6-4b37-bc5b-de00836af325',
    'identification_number': 40122654,
    'item_consumption_1': 13771,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1757.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6049f635-2197-4ce1-9e8f-df6fae44ea15',
    'identification_number': 56527144,
    'item_consumption_1': 27.06,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 787.04,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a40f3db3-4eb9-4c2d-aee2-6bd2902e1f08',
    'identification_number': 107438755,
    'item_consumption_1': 339,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 132.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b0def1d-27cb-40b8-b489-28ef2b1b65c1',
    'identification_number': 20534310,
    'item_consumption_1': 1303,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 507.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a34d7440-4284-4d1e-bcea-d10cb6ddc759',
    'identification_number': 10610472,
    'item_consumption_1': 1029,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 435.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd225fe54-23c1-4e1e-a427-68e8c84f617a',
    'identification_number': 33355215,
    'item_consumption_1': 8427,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3136.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9845d9d9-b4cc-4a1c-9e52-f15409fdaa97',
    'identification_number': 55746560,
    'item_consumption_1': 9.99,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 581.14,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4f7c5633-5260-45f4-93f2-cc25aa09eae8',
    'identification_number': 103551107,
    'item_consumption_1': 3443,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1346.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '074982d5-de50-428d-8e31-63338fbe9662',
    'identification_number': 18339654,
    'item_consumption_1': 1257,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 734.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03219c49-69c7-4889-81dd-34e39b4c893f',
    'identification_number': 48651940,
    'item_consumption_1': 1774,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2683.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbac2830-55a3-4722-8582-82290aabc0ce',
    'identification_number': 59223677,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 144.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf143087-f424-4d3c-b5a1-3d8703bcead8',
    'identification_number': 17774160,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ccdcee01-f73f-4d66-8a13-9c5132185e88',
    'identification_number': 45603391,
    'item_consumption_1': 7612,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 971.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a0e9a5c-3f25-4714-ad0e-534acd12d869',
    'identification_number': 29700485,
    'item_consumption_1': 3.82,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 91.11,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'c781d58a-b955-4b60-9f5b-b5246838363d',
    'identification_number': 13299530,
    'item_consumption_1': 1806,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1054.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85ffa808-f1ee-448a-93c0-72e09dd6f2a2',
    'identification_number': 95597727,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 004/034',
    'item_total_value_1': 111.24,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '67427f6a-5365-4240-a44c-42bb7a33e331',
    'identification_number': 17192021,
    'item_consumption_1': 675,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 263.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8816460a-9db6-472e-81e3-82decbd67813',
    'identification_number': 98370200,
    'item_consumption_1': 3063,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1788.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a15ccf85-1be7-4634-a56a-eab3e3148ba8',
    'identification_number': 105763101,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7e3cdfdd-e53f-4882-9351-c6cbd6f18e84',
    'identification_number': 109188640,
    'item_consumption_1': 531,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 207.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23f22512-37ff-4642-b512-a42ed30698f7',
    'identification_number': 25472267,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '233ce327-6b92-41d0-9868-30bc25f8b313',
    'identification_number': 110480406,
    'item_consumption_1': 1068,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 452.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96399459-3863-4408-b4b0-6e308766a408',
    'identification_number': 107173115,
    'item_consumption_1': 11,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 4.3,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3c97a97a-dd14-4821-8091-91ac53dc2415',
    'identification_number': 23691530,
    'item_consumption_1': 24607,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3140.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f27be87-6964-4dd2-8ec7-f0f47bd54781',
    'identification_number': 34680292,
    'item_consumption_1': 25490,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9486.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e1ba7ea-0f82-43e3-849c-f85a50534734',
    'identification_number': 41418417,
    'item_consumption_1': 85.11,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2475.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '70b7d551-98ba-4282-8461-f0e3c47bf05f',
    'identification_number': 108206572,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-34.05",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'e2936c77-21f4-4ef5-b919-f9d44bc67661',
    'identification_number': 65960017,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'de67c9ee-4cfa-41b3-9cad-97819adbc503',
    'identification_number': 5415870,
    'item_consumption_1': 1445,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 537.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '247f8134-2c1e-43de-b3b4-769aaa4b48b7',
    'identification_number': 4003474899,
    'item_consumption_1': 13852.9734,
    'item_description_1': 'Cons FPonta TE NOV/23',
    'item_total_value_1': 3912.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f730e3c-7ff8-40e3-a50a-e26da446d792',
    'identification_number': 66557402,
    'item_consumption_1': 14235,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5297.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2184954-4954-467b-9fdd-31ad667ce348',
    'identification_number': 12918385,
    'item_consumption_1': 3874,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 494.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb5d2e1c-8437-4131-a80e-03440e439621',
    'identification_number': 13921789,
    'item_consumption_1': 4090,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1594.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7b483d2-cc16-4caf-a12c-a0c4f12c0f20',
    'identification_number': 45216878,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eeb4a210-9562-4d7e-ad8e-5066d771a86e',
    'identification_number': 57673160,
    'item_consumption_1': 17009,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6329.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '294e7356-1825-484c-82e0-f5f8cab1b3d9',
    'identification_number': 43875718,
    'item_consumption_1': 53288,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 19830.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae31aec2-4280-47d4-97cc-6bac240cc7ce',
    'identification_number': 111585252,
    'item_consumption_1': 80,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 31.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd8d93c4-0038-4441-a3c2-6eef54858b61',
    'identification_number': 16916700,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 86.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '89edb50c-eb53-44d6-9bd5-26e61a343b8e',
    'identification_number': 49066226,
    'item_consumption_1': 1845,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 718.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16b9de97-0cb9-4b5e-af8b-792797f576ac',
    'identification_number': 929824133,
    'item_consumption_1': 205,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 178.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd599f39-586d-4e7a-b400-f51c1fd0d14c',
    'identification_number': 71522107,
    'item_consumption_1': 4021,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1568.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09b34840-d3de-4dc4-8cad-80f68274fad0',
    'identification_number': 5863570,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fa3690b8-c432-48cb-b86d-77a3ec5de2f6',
    'identification_number': 19451601,
    'item_consumption_1': 16.64,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 396.88,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0987e399-8929-44d2-ac65-23cc08a0abeb',
    'identification_number': 55524567,
    'item_consumption_1': 15.59,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 453.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '54b1c686-e49b-4ede-bff1-262febc1ea3e',
    'identification_number': 67536913,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4148f7bc-09da-4a22-880a-16260eacf721',
    'identification_number': 108380513,
    'item_consumption_1': 2603,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1520.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '433a0f62-3fcc-4f4c-ada6-e3235c13d6ca',
    'identification_number': 107833158,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2776137b-bf68-47d1-8a0a-f4fb40c6d852',
    'identification_number': 13178032,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-1078.93",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '7124c6ee-d3f0-498f-a66b-ecaff147507d',
    'identification_number': 41582608,
    'item_consumption_1': 3712,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1573.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '652c0b3c-6202-47d4-a871-2912540b5daa',
    'identification_number': 8992053,
    'item_consumption_1': 3118,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1214.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44bf0086-8349-4df0-996f-a98df1a615e2',
    'identification_number': 104378417,
    'item_consumption_1': 504,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 196.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf3231f7-b681-4bf7-b013-ad6bea5a6333',
    'identification_number': 96529091,
    'item_consumption_1': 1941,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 756.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a29c5ab-c1fa-4224-8eee-a2ddebace27c',
    'identification_number': 91863899,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d95d255-b069-46fe-babc-773ff652e18f',
    'identification_number': 19403216,
    'item_consumption_1': 122,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 51.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2c0d722-79b6-4bc4-802d-7f0dd3b627b1',
    'identification_number': 38250489,
    'item_consumption_1': 554,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 234.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f643824-e620-413c-9d35-ec69917c6b46',
    'identification_number': 92605788,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '57c67067-acf0-4ace-985b-08921f6b969d',
    'identification_number': 96125225,
    'item_consumption_1': 189,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 73.94,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9bd28ba0-04cf-4e3e-9e9c-325601276d3b',
    'identification_number': 103066411,
    'item_consumption_1': 968,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 377.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4720025-fa83-4c54-8392-24f67d5d0898',
    'identification_number': 55456812,
    'item_consumption_1': 147,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4275.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0759022e-8099-4bb0-9262-180866ee769b',
    'identification_number': 38220474,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1b39fc85-30c7-4d07-aef2-5f8567763aaa',
    'identification_number': 78371023,
    'item_consumption_1': 4176,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1631.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bce5b76a-442a-404f-be6b-cc3458c91693',
    'identification_number': 20587171,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 98125.83,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e3e5d815-02d3-4c28-b304-4d638602b075',
    'identification_number': 5910650,
    'item_consumption_1': 2093,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 885.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87e3d9a9-a42f-462f-8c7e-4002538515cd',
    'identification_number': 48651940,
    'item_consumption_1': 1774,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1036.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ad29e47-7185-46e6-9b69-2fca3cc66e46',
    'identification_number': 111114535,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f497176d-5265-4ee3-8a3c-9ab25f365d21',
    'identification_number': 35559217,
    'item_consumption_1': 70733,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 26323.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '287a4a33-2a49-4e1d-95c4-65090b646250',
    'identification_number': 17581737,
    'item_consumption_1': 9817,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4155.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1834609-984f-4ec7-9c06-c6808e93f163',
    'identification_number': 39214699,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'dd7b6949-811d-4799-845e-bf690dee8581',
    'identification_number': 111295602,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b36fd3a0-e10a-4a98-a876-15132f1f02ae',
    'identification_number': 90453450,
    'item_consumption_1': 44221,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16456.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b6e5326-9400-42e9-983d-3f822a259fe2',
    'identification_number': 96072237,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd39e4bc6-d5cd-4f7f-b963-056e4f45d4bb',
    'identification_number': 102011311,
    'item_consumption_1': 506,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 197.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd2adcc5-981d-4759-8a55-bc2e7bc644b6',
    'identification_number': 59438312,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9119ef7a-ec0a-4141-83c0-6771511418d6',
    'identification_number': 59532777,
    'item_consumption_1': 960,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 406.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '823e4412-efc7-4f82-8f57-3b9defb3dfaa',
    'identification_number': 8704236,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4032f3c1-6ffb-4fb9-bb67-d2358e47d53d',
    'identification_number': 40701301,
    'item_consumption_1': 286948,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 36624.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a360db7-a761-47cf-a10e-0df02beb2803',
    'identification_number': 75789981,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5c55df61-ab74-49a3-996c-8525080549cb',
    'identification_number': 103438157,
    'item_consumption_1': 13445,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5003.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e44d2788-b3da-45fe-af09-f5312251d612',
    'identification_number': 26677210,
    'item_consumption_1': 389,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 588.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4226f396-851b-4033-a74a-1c2b3bea8bca',
    'identification_number': 101068484,
    'item_consumption_1': 12.12,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 705.04,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '32ebf4a6-4005-4c00-92a4-c3a761dee83d',
    'identification_number': 9068236,
    'item_consumption_1': 1608,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 682.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a733d38f-6e14-4d52-a4c7-c52149b4006c',
    'identification_number': 42959128,
    'item_consumption_1': 2654,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1124.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4d2273f-43d8-42e0-96e8-7f091bf386da',
    'identification_number': 71381651,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8fd59e7e-13d2-4478-a91a-ab882921b8d4',
    'identification_number': 106851217,
    'item_consumption_1': 7216,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 921.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c588c84-7829-40fa-956c-c94b141a3039',
    'identification_number': 56877250,
    'item_consumption_1': 21.64,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 629.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '73ffbcec-8424-4b3f-bdd0-0020f03e4404',
    'identification_number': 76083241,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7d5c01e-0c52-4af0-9ebb-bc096bd0e8bb',
    'identification_number': 74311875,
    'item_consumption_1': 388,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 151.8,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '35e37dd5-4f11-4969-b6e9-80c2eecfe946',
    'identification_number': 92141200,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a63b5b56-83a3-4024-a04d-8abe14874445',
    'identification_number': 19450737,
    'item_consumption_1': 1286391,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 478727.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a92a2724-a963-4433-a16d-9a4e65faf2ef',
    'identification_number': 27716309,
    'item_consumption_1': 1370,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 581.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34f60e79-50f2-4dec-bd4e-918794f13ef7',
    'identification_number': 105168114,
    'item_consumption_1': 476,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 202.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68a3dcc1-5583-4e59-ad31-d228467af9a7',
    'identification_number': 16476727,
    'item_consumption_1': 1681,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 712.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8248b134-29c4-4bb6-8da4-dd9f6023d3ab',
    'identification_number': 48042994,
    'item_consumption_1': 6809,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2654.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90f536c4-d115-48f3-a2df-6382ce52457e',
    'identification_number': 61773042,
    'item_consumption_1': 5236,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2217.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4089e3e3-3431-44e0-b25e-d96dba74141c',
    'identification_number': 12797707,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e527a623-8b8d-49f4-93f3-c0b8cdeb34d5',
    'identification_number': 101835981,
    'item_consumption_1': "'-1.054",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-366.72",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '0bdcc2f2-6a3d-46f4-bdd3-98f4a6801d50',
    'identification_number': 85650307,
    'item_consumption_1': 503,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 196.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '724972e2-e352-4d54-9be6-054d1fdd9aa6',
    'identification_number': 100424198,
    'item_consumption_1': 530,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 207,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57c52d38-9cdd-438d-b833-ab116f2d2906',
    'identification_number': 19452632,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '52546020-9b86-44bf-b782-e3b4f0dc8a20',
    'identification_number': 38768534,
    'item_consumption_1': 429,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 181.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7de0758-b6b6-4bd3-a4b4-798e5bdfcf07',
    'identification_number': 56666462,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b91f7f44-1754-4831-8669-c3233f7e9fe0',
    'identification_number': 106612662,
    'item_consumption_1': 118126,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 43960.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d4b0b87-b98f-49a5-b783-1f885defcb39',
    'identification_number': 31939821,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8736fed4-854d-4edb-aa8f-aad9ed14d950',
    'identification_number': 46913378,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cb04889-c411-46cb-ad0d-3ccc8cc63c79',
    'identification_number': 36425532,
    'item_consumption_1': 1473,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 623.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5100ea6-3d51-42c1-9fec-4809429eff2c',
    'identification_number': 9077839,
    'item_consumption_1': 7153,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2794.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c489850-5f56-485e-a211-ed3a6cf86d99',
    'identification_number': 97445339,
    'item_consumption_1': 426,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 180.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3dd72a90-587b-4f4f-8a18-a1ce5e254f4c',
    'identification_number': 24017353,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '95fdfd14-e4e5-4d0c-846b-d292e182f74d',
    'identification_number': 39920984,
    'item_consumption_1': 874,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 370.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4135f9d2-086e-484a-a445-674101469d7b',
    'identification_number': 6941044,
    'item_consumption_1': "'-71.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-24.73",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'd5d06684-4ace-4367-a608-e3719bf259c1',
    'identification_number': 108332438,
    'item_consumption_1': 52.35,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 2451.58,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8849f367-25c8-432a-9831-a6ad849a2d94',
    'identification_number': 77800699,
    'item_consumption_1': 633,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 369.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25f0c5fd-0680-40fc-9d82-47c8e80517a4',
    'identification_number': 44476256,
    'item_consumption_1': 179.87,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 5231.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b9778b5f-07ae-49a5-ba92-d879abbe0605',
    'identification_number': 67627641,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5732a77c-281c-4025-89d0-280986c4f4cd',
    'identification_number': 73431044,
    'item_consumption_1': 7280,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3093.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a7c126b-cf93-468b-9461-ddb1248bdc55',
    'identification_number': 94625409,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '185a00e3-a456-4d90-a545-87c00851452f',
    'identification_number': 3030777242,
    'item_consumption_1': 783.7806,
    'item_description_1': 'Consumo Ponta [KWh] TUSD NOV/23',
    'item_total_value_1': 1384.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b18ac9e-c9e6-44bd-87ed-437f9f525a90',
    'identification_number': 99780720,
    'item_consumption_1': 7076,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3002.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c915b4a-49a6-4af6-b212-c9a972e73459',
    'identification_number': 39899403,
    'item_consumption_1': 703,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 298.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af82bdc7-f026-416e-a555-3ca446dc3160',
    'identification_number': 80119646,
    'item_consumption_1': 741,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 314.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15e7c624-7df8-4acf-91dc-0419f91abdc0',
    'identification_number': 82730652,
    'item_consumption_1': 168,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 71.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a64f2ee4-6dbc-437e-a3ee-998b9df00f94',
    'identification_number': 91359511,
    'item_consumption_1': 96603,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 35950.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9416b9e0-59fb-4ca9-9b4b-2a23097a7ce5',
    'identification_number': 29957176,
    'item_consumption_1': 2489,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 972.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b49205a5-191b-464f-b38c-b7c0680c1ec5',
    'identification_number': 14642484,
    'item_consumption_1': 29,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 11.35,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9d9550ec-fecb-40f5-a204-424252b99578',
    'identification_number': 62462733,
    'item_consumption_1': 1948,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 826.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89becd2c-1544-4bcc-9d47-56ea8bd374b3',
    'identification_number': 35090448,
    'item_consumption_1': 99,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '038f19fd-a04d-4b6e-bacb-96c0b758dd8c',
    'identification_number': 10391746,
    'item_consumption_1': 1483,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 579.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd07e2d1-9a5e-4a40-a14f-5a1fc454838a',
    'identification_number': 56173806,
    'item_consumption_1': 4601,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1948.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a645aed-c529-4190-a3f6-901ebd554333',
    'identification_number': 22162330,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 183.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9c2c4be9-be03-47df-bbf8-327a4827f222',
    'identification_number': 15572862,
    'item_consumption_1': 8.8,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 1005.13,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e4aea09b-9a26-4709-bad0-832c89750f32',
    'identification_number': 54582881,
    'item_consumption_1': 865,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 337.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ca6c74d-22b9-41d5-b576-dc2838218a53',
    'identification_number': 56582854,
    'item_consumption_1': 16289,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2079.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cecd2efc-3683-4462-81a4-b43a4cd4ed31',
    'identification_number': 89679229,
    'item_consumption_1': 360,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 153,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '622b805d-295e-4f9b-9fe4-68b180d6e9a0',
    'identification_number': 56527136,
    'item_consumption_1': 403,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 235.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4a3a742-44e6-4f99-a6dd-9e9faed9538c',
    'identification_number': 91868041,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 86.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '74f416f3-9091-44dd-b26a-906599acb1bc',
    'identification_number': 948748404,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7819222e-da78-4af0-9d53-1228e0b6fa2a',
    'identification_number': 90934520,
    'item_consumption_1': 10133,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3953.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f5cd7b4-125a-48ce-9dfa-e1842b5cf154',
    'identification_number': 13372688,
    'item_consumption_1': 40.61,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1181.17,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7b9acd36-f1a6-4725-8682-479fdd7b5d68',
    'identification_number': 39492265,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb676965-ed6e-4c36-8a4c-b721a4004288',
    'identification_number': 35322101,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a4d1a29c-b294-4dea-9d3b-a282558c82ab',
    'identification_number': 35000430,
    'item_consumption_1': 23131,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8608.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbbbf220-6a82-4b19-86ec-9e3f1ce4520d',
    'identification_number': 99327392,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3d5f84ce-4175-4603-852d-172390ca403e',
    'identification_number': 15645835,
    'item_consumption_1': 1517,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 885.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2d01751-3e20-4964-bae9-8c86e77d0fc2',
    'identification_number': 56876815,
    'item_consumption_1': 17.11,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 408.09,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6dd45db9-12fb-4444-9258-b168d7de99b4',
    'identification_number': 19296568,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1de9d0f8-d355-4f10-b8ff-5f804f2a791e',
    'identification_number': 34959300,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48f6e509-4f64-4441-b186-10264242078c',
    'identification_number': 29896975,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '20c3266b-b5f5-430c-a2f3-55b4deb67312',
    'identification_number': 41692063,
    'item_consumption_1': 2217,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 942.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f50b16ef-ae3b-4983-ad51-98d2f41bda2e',
    'identification_number': 92334865,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 487.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cdb244b7-b210-489e-bbaf-a86bb5ab1c4e',
    'identification_number': 13178032,
    'item_consumption_1': 11292,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4202.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a558b670-f69d-4032-be17-4be02cd355e3',
    'identification_number': 102429537,
    'item_consumption_1': 292,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 113.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46335167-5f04-46dc-825e-abf7923200d8',
    'identification_number': 70556997,
    'item_consumption_1': 166,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 64.94,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1a43d18d-ec9f-4e72-9a5d-aa78dafd5675',
    'identification_number': 30338190,
    'item_consumption_1': 1887,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 800.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfb97007-1b12-4c98-94b2-cbe86f45997a',
    'identification_number': 9834001,
    'item_consumption_1': 458,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 178.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a584941b-e088-4d35-8cdb-e86a06878515',
    'identification_number': 23554681,
    'item_consumption_1': 1560,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 609.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc019b7c-fb22-40aa-811f-cb1f8a3d6b65',
    'identification_number': 9788131,
    'item_consumption_1': 70.99,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2064.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '76543cc9-8d69-44a1-a77a-f8d8ce5d4612',
    'identification_number': 108560015,
    'item_consumption_1': 4445,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2596.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7457a37-d8e0-425f-95aa-268486a81ba0',
    'identification_number': 10813993,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3140ceca-6433-4656-ae1d-67fb996ad0ff',
    'identification_number': 12026174,
    'item_consumption_1': 869,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 339.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72ee7aff-6c03-4d93-bc0c-210bdaee1dd8',
    'identification_number': 15158837,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41aec61b-77a3-4c5d-9f50-fedbbbcabcef',
    'identification_number': 73822671,
    'item_consumption_1': 2.94,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 70.14,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0e391b71-ed01-44ab-aeff-09fb71a668db',
    'identification_number': 39261565,
    'item_consumption_1': 5.23,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 124.76,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '175f6c10-28da-47a1-ab2b-f7ebb34aa452',
    'identification_number': 16307836,
    'item_consumption_1': 47.97,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2739.54,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '804154b2-7425-4bfe-b5ff-dd1227413b8f',
    'identification_number': 47899816,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eceff43d-3af2-4b37-a3f7-487b3b24425b',
    'identification_number': 8084661,
    'item_consumption_1': 3828,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1424.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc8bbd1a-cb24-4a3f-a4a5-0bd6527a21e5',
    'identification_number': 92140394,
    'item_consumption_1': 13726,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1751.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06018795-f986-4319-9ffd-61d5a827b377',
    'identification_number': 104766190,
    'item_consumption_1': 8.61,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 205.37,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4dae1bf5-5bc6-4fb2-a099-8f73eb20fe98',
    'identification_number': 68677413,
    'item_consumption_1': 931,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 363.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9535c42e-306d-41e4-a49c-988365f2432f',
    'identification_number': 38370492,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '256196ef-d396-402d-8409-43caec6480d1',
    'identification_number': 68906161,
    'item_consumption_1': 7782,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 993.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c33271bd-53db-43c1-b275-3886686fde29',
    'identification_number': 102159459,
    'item_consumption_1': 26.38,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 629.18,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3ead5d40-a349-4fd1-a140-abd08f4f01c1',
    'identification_number': 92763219,
    'item_consumption_1': 44512,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16565.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea5a711b-72aa-442b-aa0f-b5ffbec91ec7',
    'identification_number': 50761900,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8281432f-5034-44ab-b3c4-4d6351916297',
    'identification_number': 14354306,
    'item_consumption_1': 779,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 454.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2dc85257-12f9-498b-bb31-df4a131cbb42',
    'identification_number': 106136712,
    'item_consumption_1': 1523,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 889.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '537fdb3f-951c-4443-81fa-7837e34b5599',
    'identification_number': 27434826,
    'item_consumption_1': 4031,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1708.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4a11c37-951c-4085-b646-1eeaf44cfb19',
    'identification_number': 39452336,
    'item_consumption_1': 12383,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1580.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd2f8786-c6a3-40dc-8259-d5a73f8fb462',
    'identification_number': 44436637,
    'item_consumption_1': 1678,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 980.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75507dfb-3557-4e85-931f-e035e10a5b78',
    'identification_number': 17778085,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'adb831c5-a1d9-4374-8e46-fdc3f34c11cb',
    'identification_number': 32153430,
    'item_consumption_1': 0.4,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 9.56,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '56fc8845-eba7-422d-b7f3-fa4da1b64319',
    'identification_number': 12908398,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1993.25,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '898f639a-25a0-4158-8b63-ddede6d91824',
    'identification_number': 38847280,
    'item_consumption_1': 27314,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10164.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8004665b-0562-483d-881d-1829bb39119f',
    'identification_number': 44818815,
    'item_consumption_1': 9626,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 14562.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '423691a2-3803-45bf-9264-ac2ca3a1849b',
    'identification_number': 98994859,
    'item_consumption_1': 4057,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1582.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9bab5d1-1317-45e4-8627-9cb8c9452258',
    'identification_number': 103124098,
    'item_consumption_1': 16592,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6174.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c59dd8f6-ee2d-43df-99ec-4731395376b5',
    'identification_number': 80201555,
    'item_consumption_1': 4903,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2863.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9511825a-935d-42a5-b01d-5c9e2b5b700e',
    'identification_number': 17774160,
    'item_consumption_1': 1112,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 471.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8edac8f9-5e04-45e0-8144-dd8c3d6f72f5',
    'identification_number': 97356530,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c2684100-75f1-4b9d-a6c1-433327c9b136',
    'identification_number': 28053605,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8428ba8-b946-4265-9010-e3693da850e9',
    'identification_number': 33921393,
    'item_consumption_1': 96,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 40.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8aff92a5-6f9a-49dc-b06c-c77380c48507',
    'identification_number': 19451610,
    'item_consumption_1': 33482,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4273.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '777a0f0e-ee94-4526-8000-6299d7a27319',
    'identification_number': 8086451,
    'item_consumption_1': 71889,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 9175.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '419d63a5-140d-490a-bc56-8b0932b0f91c',
    'identification_number': 18094694,
    'item_consumption_1': "'-11.428",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 06/2023',
    'item_total_value_1': "'-3653.88",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '0f138a40-c224-4f28-97d9-de28d611bdd7',
    'identification_number': 18898602,
    'item_consumption_1': 2702,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1146.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5abc3acb-da73-4eee-913b-fa6dc3e01597',
    'identification_number': 74953974,
    'item_consumption_1': 306,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 130.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3ebeacd-f823-489b-a88a-8f0fbfaa62dc',
    'identification_number': 80788190,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5048ea0b-3343-434b-8f98-ded235a8b09b',
    'identification_number': 7090587,
    'item_consumption_1': 588,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 249.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2a15f4f-b0c3-49e2-9e0c-5d4126304dce',
    'identification_number': 86952790,
    'item_consumption_1': 1281,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 544.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4553d6e-cf03-4bdf-a3cf-c9eb9b899a0e',
    'identification_number': 98511467,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e73f6d90-a590-4867-9415-8e96e56c96b8',
    'identification_number': 79147453,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '790522ee-a8d6-4703-b936-e6d501268797',
    'identification_number': 101073593,
    'item_consumption_1': 5066,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2958.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04bb5ad8-2adb-4104-a026-5fdf69691417',
    'identification_number': 76288714,
    'item_consumption_1': 48833,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 73875.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa835988-a44a-43df-ab6c-42c31f23ddc2',
    'identification_number': 40768333,
    'item_consumption_1': 1021,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 399.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0983c56c-0f26-4067-9161-b4b1593297b0',
    'identification_number': 103840923,
    'item_consumption_1': 275,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 416.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '589208ed-c070-4da9-a129-582c07e9cc7a',
    'identification_number': 104718242,
    'item_consumption_1': 1423,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 602.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f10aa0f5-96d7-4f64-a8a3-976f3f101106',
    'identification_number': 72092556,
    'item_consumption_1': 978,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 571.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '422870b0-bc1e-4cbc-aa99-273c5dab368c',
    'identification_number': 43154514,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10eeed8f-e629-4926-a2db-a68caa9661f3',
    'identification_number': 31846882,
    'item_consumption_1': 21.38,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 2442.02,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '282f01f3-c585-49c2-b468-47e6a3c7b474',
    'identification_number': 22866450,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '453f3edb-26e8-43f9-bb5d-356b803d734a',
    'identification_number': 106605712,
    'item_consumption_1': 1.18,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 28.16,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '29400657-39f4-4b45-a2de-7c89bd46a70a',
    'identification_number': 35322101,
    'item_consumption_1': 269.02,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 7824.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '38e67665-acc8-44a0-98c6-887f8f42e7c0',
    'identification_number': 72062649,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3715e0e2-4ac6-44bc-878f-4b21fc9eb15b',
    'identification_number': 51169530,
    'item_consumption_1': 4.324,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 1639.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5733aa02-908c-4239-897f-0913a43ef7a2',
    'identification_number': 36293067,
    'item_consumption_1': 6889,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2928.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c04c253-f4cc-4c35-aa7b-b0402d88786c',
    'identification_number': 101940122,
    'item_consumption_1': 18.96,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 551.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3dbdc980-a7eb-4a10-acee-430d33db7040',
    'identification_number': 93842481,
    'item_consumption_1': 545,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 230.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67310975-5426-42fe-b809-b3213ed62256',
    'identification_number': 106278789,
    'item_consumption_1': 11800,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4608.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'faa3f012-77cc-4e69-aa4e-6f3e00fa545d',
    'identification_number': 58738428,
    'item_consumption_1': 32.07,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 932.78,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b6c1c6e1-cacf-4c20-9481-dc8cd18698c8',
    'identification_number': 42348579,
    'item_consumption_1': 996,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 423.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '226584f3-032d-4e95-b45f-4afa13c4af68',
    'identification_number': 100637701,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1dad12c4-4329-4e57-bc98-d57f000bbbca',
    'identification_number': 56877021,
    'item_consumption_1': 9254,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3443.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50d460be-9bb0-47f5-9865-a71532c3c9e0',
    'identification_number': 77672925,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 572.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df79af31-63cf-4bf7-8442-99669cc64788',
    'identification_number': 96181915,
    'item_consumption_1': 1172,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 457.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7d96056-478b-4128-b36d-f8e37833cc02',
    'identification_number': 15397009,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '603617e5-3f26-4c4f-a133-4b138e089a84',
    'identification_number': 41999932,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.21,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dfdb015a-93ae-42b9-9a43-6d6b52493f34',
    'identification_number': 56526849,
    'item_consumption_1': 7,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 2.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '265f405d-ceb3-48f0-86a9-5d6855c23015',
    'identification_number': 105628271,
    'item_consumption_1': 11163,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4154.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c16fd62c-293e-4cd8-a81e-176b52dbe587',
    'identification_number': 32151179,
    'item_consumption_1': 32,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 12.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8c1ea751-2788-4a25-b6c7-3fa5ab8a646d',
    'identification_number': 111295688,
    'item_consumption_1': 444,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 188.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfc4d125-4085-42a6-af01-e97934ffee55',
    'identification_number': 14952688,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7121.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5fc7d88-3ec3-4a2d-b0a5-b82defbde77b',
    'identification_number': 94653992,
    'item_consumption_1': 668,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 260.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe269209-2454-443f-88e5-de58ff86e88c',
    'identification_number': 41700929,
    'item_consumption_1': 29.86,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 712.18,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ce0bd406-30d1-4fdc-b524-c8562b59eccf',
    'identification_number': 98798510,
    'item_consumption_1': 7408,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 945.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37505c5b-adf0-435e-a103-01e1d20d6953',
    'identification_number': 41971817,
    'item_consumption_1': 41.91,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1218.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '18d517b6-0d86-4e16-9f38-617785bef671',
    'identification_number': 96128003,
    'item_consumption_1': 724,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 307.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ddf2179-9d95-46a7-9861-e287cd763deb',
    'identification_number': 42223750,
    'item_consumption_1': 103083,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 60204.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b285bc6-107e-41d0-a10a-a09485ad3d3d',
    'identification_number': 17799880,
    'item_consumption_1': 223,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 86.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd782408-07cd-4d4e-b8de-15134745986e',
    'identification_number': 43061842,
    'item_consumption_1': 1.56,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 73.07,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3bd88db4-331d-40e8-9331-01b668e4f742',
    'identification_number': 42396905,
    'item_consumption_1': 159.76,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 3810.35,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'cf7aca81-002f-475e-94c9-4406c1fbeb61',
    'identification_number': 48511978,
    'item_consumption_1': 1.11,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 52,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '69d721ac-79b8-4917-b22c-f5aa214dcbd8',
    'identification_number': 111159695,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3218cbb4-23db-437d-b4e3-fb1aaa932b01',
    'identification_number': 100872433,
    'item_consumption_1': 105,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 40.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1d2af65-2f84-43e1-add3-2ce8b38754a0',
    'identification_number': 20168225,
    'item_consumption_1': 1037,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 440.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '576ad5bc-d165-4f46-a3f7-bd2d4de980e6',
    'identification_number': 19873727,
    'item_consumption_1': 3720,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2172.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52fd18fa-0ac1-4397-9e89-72697a0c6305',
    'identification_number': 41674340,
    'item_consumption_1': 2055,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 803.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7145c8a5-7f6b-49f0-acf5-8252509e319f',
    'identification_number': 110992040,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '225fb7a8-1be9-438c-b07b-7e07e567c9cc',
    'identification_number': 34617418,
    'item_consumption_1': 17,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 6.64,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '32959718-9e3d-40d4-afc6-31900692607f',
    'identification_number': 30427290,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 621.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '12683d27-f52a-4fae-8ee2-e0ce51d06f2b',
    'identification_number': 98182501,
    'item_consumption_1': 12.29,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 714.92,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'adee0097-90e9-4cdd-8447-afd1c045eb5d',
    'identification_number': 102003211,
    'item_consumption_1': 33.97,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 810.21,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b3be3d54-d8b5-4185-b48a-eadfc51962e5',
    'identification_number': 40705188,
    'item_consumption_1': 2526,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3821.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '696e6e02-bc81-4f7b-b237-82ac42c684a6',
    'identification_number': 60602643,
    'item_consumption_1': 5468,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2034.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9db4471-3523-4386-972a-0e7e6b013a1a',
    'identification_number': 15016005,
    'item_consumption_1': 7755,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3287.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb8188f5-ffcf-4328-936f-6acc1a99f577',
    'identification_number': 54420636,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-42.03",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '9958f4ce-959c-4b66-ae69-fe3f509b4a1d',
    'identification_number': 29891833,
    'item_consumption_1': 8.9,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 212.28,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '458c33b0-464e-4cb2-80f2-771f22f41468',
    'identification_number': 23081120,
    'item_consumption_1': 2951,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1153.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '706e84e7-182e-4007-9ede-c0420179d13c',
    'identification_number': 40490688,
    'item_consumption_1': 18111,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2311.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9aec398-e42f-4e30-a2dc-f15bfb0eab66',
    'identification_number': 48193399,
    'item_consumption_1': 120,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 50.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e81c174e-6fca-40a8-bc8f-8111cd07219e',
    'identification_number': 71978097,
    'item_consumption_1': 7559,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2949.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c517e1f8-3c4c-4759-8c57-d1d65bc77d51',
    'identification_number': 40701301,
    'item_consumption_1': 710,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 277.79,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ca23f02a-07d5-496a-85e8-dc1a59a6d9dd',
    'identification_number': 12026242,
    'item_consumption_1': 1.483,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 562.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4950953-f706-408f-9192-2674b986e85a',
    'identification_number': 110973003,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6818511-e48b-4143-9fa4-6590b034fb46',
    'identification_number': 103785558,
    'item_consumption_1': 51.21,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1489.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6640b997-312b-4088-839d-9d535b675909',
    'identification_number': 42117402,
    'item_consumption_1': 3138,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1224.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03948ed2-2d0c-435d-b698-6dc2d3b12ba1',
    'identification_number': 53839242,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9fa257ef-f8f8-4573-9eff-d62bebb86533',
    'identification_number': 99807483,
    'item_consumption_1': 3298,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1397.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3eec3e0-95bb-4676-882a-6cdf7f026fe6',
    'identification_number': 17093457,
    'item_consumption_1': 454,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 192.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db7988f9-c717-4b12-ba6b-27107c127504',
    'identification_number': 14978229,
    'item_consumption_1': 3160,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1231.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea65daed-2a9c-4785-8ef0-9990eca5efe5',
    'identification_number': 16677412,
    'item_consumption_1': 44.67,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1299.25,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7600c685-1e7c-47ab-8dbe-2529afa1b561',
    'identification_number': 82521310,
    'item_consumption_1': "'-2.721",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 06/2023',
    'item_total_value_1': "'-945.66",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'acce00d8-f3b4-4694-87a3-f5a04ca7f23e',
    'identification_number': 104356014,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7ce2a810-056f-4035-8e86-1798caa59d4a',
    'identification_number': 76229815,
    'item_consumption_1': 2337,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1364.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5deb548-693c-4372-acb3-719c9f72b50b',
    'identification_number': 37507940,
    'item_consumption_1': 35.34,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 842.9,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '253ed343-4669-4951-9ee8-5f1d13e9e723',
    'identification_number': 33651868,
    'item_consumption_1': "'-1.08",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-375.62",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '3a71c740-f576-43e2-a5da-3503a25d8000',
    'identification_number': 33324980,
    'item_consumption_1': 1959,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 763.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f829696-9c31-44d7-b3f7-03f316d0ae35',
    'identification_number': 107225158,
    'item_consumption_1': 674,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 262.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8449bace-8faa-42ce-86f1-e17ad63fd7ff',
    'identification_number': 77241924,
    'item_consumption_1': 361,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 140.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b874f39-6ae8-4480-9635-3b3440c2bc0a',
    'identification_number': 85205672,
    'item_consumption_1': 5.03,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 119.99,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e5cfb4d7-581b-4fb6-844b-3d2ba19dfd75',
    'identification_number': 11100800,
    'item_consumption_1': 2050,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 800.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '180eb369-b9a2-4a6d-8e2e-f9beb8b10fe6',
    'identification_number': 108565726,
    'item_consumption_1': 51,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 77.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d8f7387-2f81-437a-a848-fdb1cbf01e46',
    'identification_number': 97161926,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49d08c91-14a1-4f94-9a7f-ef37270da9bc',
    'identification_number': 79663036,
    'item_consumption_1': 44192,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5640.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a86d549a-f4fd-40fe-96c9-cfa87120fe14',
    'identification_number': 94391645,
    'item_consumption_1': 112,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 169.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccb2da22-aacf-4566-8b92-6eb8718069ff',
    'identification_number': 56507844,
    'item_consumption_1': 19358,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7204.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5dc93ed-7d84-4602-80e7-d79fd452399e',
    'identification_number': 105662860,
    'item_consumption_1': 559,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 218.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4e79ff7-a3f5-42b9-8c49-814116bd3935',
    'identification_number': 107466732,
    'item_consumption_1': 44.42,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1291.98,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9b0530e2-624c-4d42-b4ae-9ad8633ac35c',
    'identification_number': 111834171,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b068601f-7a0a-458c-865b-1d54eeb6d3ff',
    'identification_number': 33760900,
    'item_consumption_1': 16,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 6.25,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0af72e8e-c86f-4fe7-8ca7-cae594a6e92c',
    'identification_number': 105623733,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '247265f9-23bc-412f-9bc1-cb52e7fa999e',
    'identification_number': 103157980,
    'item_consumption_1': 41114,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15300.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '285f9cb7-422d-4ce4-9033-68b49fe94027',
    'identification_number': 17372682,
    'item_consumption_1': 12613,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5358.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3a74deb-4c59-4fd1-b89b-0b9415bf7b23',
    'identification_number': 109487877,
    'item_consumption_1': 29.22,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 696.91,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '181e76e4-b620-4725-aed1-fb2a931e1b39',
    'identification_number': 102934096,
    'item_consumption_1': 1467,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 573.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccbb8642-f6b4-4771-bbd7-fe5d9a6874bd',
    'identification_number': 95765840,
    'item_consumption_1': 1319,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1995.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04ba730e-e1fe-4cea-8f32-c4549d95ff99',
    'identification_number': 100769578,
    'item_consumption_1': 503,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 196.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e621645-8229-4ae4-8c1b-b32de413b737',
    'identification_number': 100612075,
    'item_consumption_1': 16257,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2074.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df40af8c-47a3-49fa-98cd-5047047180e5',
    'identification_number': 56026684,
    'item_consumption_1': 760,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 443.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fcf8654-7b29-4590-a810-c0725b2bdc80',
    'identification_number': 10299343,
    'item_consumption_1': 2086,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 883.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f560b54c-576b-42fa-a9a1-e59c8bef4a82',
    'identification_number': 22905171,
    'item_consumption_1': 1.741,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 642.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0f6007a-555d-4828-8355-a25b305176f2',
    'identification_number': 38772043,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7017410f-7029-4817-b388-1260227a61df',
    'identification_number': 104309555,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 674.21,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '2bca0d5e-509b-4b28-b2eb-8282833ec667',
    'identification_number': 101912331,
    'item_consumption_1': 3.74,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 89.22,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b4c1f6d0-a7e7-4125-b074-aa35e000c4b1',
    'identification_number': 44805616,
    'item_consumption_1': 3.59,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 85.65,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '79d5b5a9-749e-4471-8f63-8339f6af309c',
    'identification_number': 105699047,
    'item_consumption_1': 110.94,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3226.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'baf6acea-aab4-4170-bcfd-44f1069605b4',
    'identification_number': 36235334,
    'item_consumption_1': 332,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 129.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4a6de92a-e635-4502-b0ec-c2f2f9b9eff5',
    'identification_number': 109858891,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '878d7a79-3c06-423c-b51b-db3818084fe4',
    'identification_number': 99441047,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '792d6da3-22ec-4f98-b124-c4358c1dc504',
    'identification_number': 52933423,
    'item_consumption_1': 8074,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3145.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d029c17-bdfe-42b7-b89b-d737e57b4fa2',
    'identification_number': 75402351,
    'item_consumption_1': 2.67,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 63.68,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b392a972-e4b0-401a-ada2-1eb0a108770b',
    'identification_number': 59479329,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f5612dc5-4852-485e-8a27-f26a2fd7a26f',
    'identification_number': 19229747,
    'item_consumption_1': 19.48,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1112.48,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e6995172-f309-4e30-9584-14bef1465d59',
    'identification_number': 92605788,
    'item_consumption_1': 3792,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1607.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b10361f0-2d94-487e-9b05-c06eaf704b53',
    'identification_number': 73534609,
    'item_consumption_1': 1227,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 478.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7f40ce8-6767-44b0-8ec2-9f2a00fec0c8',
    'identification_number': 42056306,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a68a8bf9-f6f6-4371-b83d-9d87f047d672',
    'identification_number': 8670277,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '141c867d-4753-4386-b346-14ccf4971de0',
    'identification_number': 109530608,
    'item_consumption_1': 4696,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1747.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8d2c0b8-b9b5-4f0c-8059-517983310c93',
    'identification_number': 94391645,
    'item_consumption_1': 11,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 4.3,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9241db94-cdfc-4b86-b050-4ca4e818b9da',
    'identification_number': 28034422,
    'item_consumption_1': 6014,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2350.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f2b7dac-a60a-4a41-96cf-6a779b9fc252',
    'identification_number': 98518933,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a6c6fd1b-4d5b-49b8-a321-52270bf3d6e4',
    'identification_number': 63991373,
    'item_consumption_1': 598,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 349.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0681b90-8607-4fa7-86a4-72b67281ce78',
    'identification_number': 13927060,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6d3c326-f929-4a4e-b648-c62446bf8192',
    'identification_number': 49620622,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '541e39a0-80c4-4bb1-8e82-e7d65a3d6744',
    'identification_number': 98718410,
    'item_consumption_1': 128,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 54.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58be8757-f4b5-42ba-8540-058715f06ea3',
    'identification_number': 49686518,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6185f0f8-e4d0-4980-b7e8-0011d0c00129',
    'identification_number': 69758409,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 67.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bb129a38-5960-4611-858c-7df27dc9d0cb',
    'identification_number': 66636973,
    'item_consumption_1': 320,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 135.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3cb90e4d-bfe7-4df5-a908-feac5ef446b8',
    'identification_number': 32098120,
    'item_consumption_1': 19.48,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1112.48,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '946f3971-ec25-4180-986f-a347090a59fb',
    'identification_number': 102172803,
    'item_consumption_1': 1593,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 675.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11867635-6425-486b-a690-550c8132735c',
    'identification_number': 94391645,
    'item_consumption_1': 2639,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 982.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2c6dd96-0df2-4d47-9a3b-eb0d4d4b10fc',
    'identification_number': 32715811,
    'item_consumption_1': 462,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 180.76,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '26e02e33-d284-4108-abb2-e05586324cec',
    'identification_number': 91355540,
    'item_consumption_1': 34.29,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 997.35,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2af92144-d3b6-4521-b7d8-8a3c6aecdc56',
    'identification_number': 41423690,
    'item_consumption_1': 447,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 174.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56371928-0464-45ab-bd44-521bc0be81dd',
    'identification_number': 32528620,
    'item_consumption_1': 23131,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8608.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29eb9fb3-6084-4a5b-8e6a-e036b3bbc2e8',
    'identification_number': 81572280,
    'item_consumption_1': 345,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 146.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ddff752-b539-46cb-a020-ef4653e93025',
    'identification_number': 23899166,
    'item_consumption_1': 600,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 907.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c61574cd-70c8-4809-8d4b-05ccd3385fd2',
    'identification_number': 45768279,
    'item_consumption_1': 0,
    'item_description_1': 'DEVOLUCAO DE AJUSTE DE FATURAMEI',
    'item_total_value_1': "'-2093.95",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '7f295abf-faa5-4d28-875f-eaca9b732eff',
    'identification_number': 100846971,
    'item_consumption_1': 1590,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 675.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7857f8a-50f2-4fc0-9480-15b609772a59',
    'identification_number': 109618866,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a680d5da-2a91-45a5-91db-36ad30e23d11',
    'identification_number': 5980798,
    'item_consumption_1': 1572,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 613.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d1a08bc-cf53-4f64-af1b-d8f602ebe083',
    'identification_number': 93093012,
    'item_consumption_1': 1440,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 563.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38d07d5c-7f93-4004-a6bc-12796181acda',
    'identification_number': 104547863,
    'item_consumption_1': 580,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 226.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '914386a6-3829-4c84-9f7d-23540b7e3780',
    'identification_number': 14819821,
    'item_consumption_1': 0.04,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2.26,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '725b25d3-8e4c-455b-8dd3-45555fb8489c',
    'identification_number': 46349030,
    'item_consumption_1': 32135,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 18768.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4fd276d3-cef7-41b9-84a5-1168ae3f8105',
    'identification_number': 72733748,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '51c60db0-7a94-4ecb-9edf-5c8dfe7f5bbf',
    'identification_number': 94724741,
    'item_consumption_1': 176,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 68.85,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '045bce3c-d3c2-478d-b4ec-7038d1c01bef',
    'identification_number': 64429857,
    'item_consumption_1': 873,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 370.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6117ace-c5f2-41e3-a0d9-4c6a4b3cc092',
    'identification_number': 40032060,
    'item_consumption_1': 105.18,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 3059.26,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '70e49907-b0aa-4cd1-8fc3-d963c7b0e122',
    'identification_number': 19450737,
    'item_consumption_1': 91731,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 138772.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65f10b6d-0913-440e-b9f5-c5f540132543',
    'identification_number': 58696172,
    'item_consumption_1': 10819,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4026.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12408dde-1d41-48b4-a831-213fe391b684',
    'identification_number': 12977489,
    'item_consumption_1': 3.72,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 216.4,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'f2f20b3f-2461-4c44-9773-ef83d4cd743e',
    'identification_number': 56995806,
    'item_consumption_1': 31.61,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 919.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '546f4e86-78f0-44cc-adcc-59ea38bcfc7e',
    'identification_number': 70926115,
    'item_consumption_1': 238,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 360.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f112aa43-e98e-4f14-b686-7c69726cf588',
    'identification_number': 38091844,
    'item_consumption_1': 1094,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 464.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95b83e34-9181-4118-bef8-895ef4b78b2d',
    'identification_number': 59362057,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 102.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7e70705d-42b1-4f2c-afb3-4eb27910b679',
    'identification_number': 107845490,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43911b65-e15e-434d-8526-41f486653b78',
    'identification_number': 71277552,
    'item_consumption_1': 11138,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4144.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e43af3b2-e87b-44dc-ab61-695dabf78471',
    'identification_number': 37911252,
    'item_consumption_1': 1907,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 745.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ec33790-c70b-4434-99a5-f4118ba096d9',
    'identification_number': 39672131,
    'item_consumption_1': 0.25,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 5.97,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'dc2c7d7b-f6ac-42d7-96fe-6ea6ea5ed7f8',
    'identification_number': 93636105,
    'item_consumption_1': 10199,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3983.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5156d7cb-cfeb-440c-a633-450e4bd32561',
    'identification_number': 35825448,
    'item_consumption_1': 25.83,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 751.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '75809b88-80e9-458f-9ba9-3eac5c93e049',
    'identification_number': 36648329,
    'item_consumption_1': 348,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 136.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b7b4d424-c7d0-4149-bc11-2f184fa93ccb',
    'identification_number': 17810914,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a66d55e1-617a-477c-8cb0-01cc73593a90',
    'identification_number': 46343466,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8040b987-d56b-41fb-9299-1051c57c9e3b',
    'identification_number': 60203390,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-1261.72",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '4ea8633c-a98f-4bd2-b347-cf75ba41759c',
    'identification_number': 106087266,
    'item_consumption_1': 46.93,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1364.99,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0a6af2fa-3db0-4904-97f2-2a1444b3c15a',
    'identification_number': 14138034,
    'item_consumption_1': 20443,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2609.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '380374a8-2868-4ef6-a8a0-576a793dc042',
    'identification_number': 56876815,
    'item_consumption_1': 10485,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3901.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '242c4e3a-b47f-43da-b9c2-c1048fafd229',
    'identification_number': 93742428,
    'item_consumption_1': 6165,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2612.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21a9e234-5e9d-420b-abf1-00a55f2dffe9',
    'identification_number': 100474144,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6886510a-e27e-45ab-9b65-4940ff5f3564',
    'identification_number': 56698151,
    'item_consumption_1': 8141,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1039.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85286465-f4e0-4b84-8894-3ad9248ff571',
    'identification_number': 68085559,
    'item_consumption_1': 6721,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3925.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe69b083-5412-4faa-826c-4b963fed52e1',
    'identification_number': 27904660,
    'item_consumption_1': 2685,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1568.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1bccdab-b210-4365-826a-7e401b3ca2f6',
    'identification_number': 101870728,
    'item_consumption_1': 1644,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 960.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6dcd2723-42c8-4fa3-862e-11de2d63310b',
    'identification_number': 29985404,
    'item_consumption_1': 1537,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 897.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14b2e0ca-c4c2-4bcc-bfc3-503718b4a138',
    'identification_number': 94650977,
    'item_consumption_1': 646,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 252.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '364bdfef-0128-4907-ade4-a899dc6491c0',
    'identification_number': 64175251,
    'item_consumption_1': 5103,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2980.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b76f76c-4ff8-49e6-abc3-25827b52c663',
    'identification_number': 69466866,
    'item_consumption_1': 4054,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1583.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f23442b-1221-4e2d-aa71-21dabcdef1bd',
    'identification_number': 13789414,
    'item_consumption_1': 3121,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 398.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50d4716e-16f8-42e5-9d6d-eff8af25f27f',
    'identification_number': 93699395,
    'item_consumption_1': 915,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 356.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c61b79ff-e412-4a6b-b3d7-6ee476c17723',
    'identification_number': 28316037,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42ee828c-3c14-4ca3-937e-88e5d6f449a4',
    'identification_number': 6192807,
    'item_consumption_1': 4596,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1792.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '153e7967-53cd-4c3b-be54-4762450680b6',
    'identification_number': 40768430,
    'item_consumption_1': 1261,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 535.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cacece8-63d1-4f4b-a4dc-8c5453f790bc',
    'identification_number': 71031120,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '43e42fe0-7918-4c16-b498-67619c9c4f08',
    'identification_number': 46677615,
    'item_consumption_1': 1004,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 586.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16b21cc8-a314-4605-b54b-3c83f926fa02',
    'identification_number': 59455624,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffecd589-99a8-489d-a848-318dde176824',
    'identification_number': 31818030,
    'item_consumption_1': 5.23,
    'item_description_1': 'DEMANDA REAT EXCED USD FP',
    'item_total_value_1': 152.11,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '57682923-8334-4143-a0e9-8600a3435eef',
    'identification_number': 9897321,
    'item_consumption_1': 2756,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1076.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc75df16-ab2d-4d86-9737-70d97a307722',
    'identification_number': 92661300,
    'item_consumption_1': 1072,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 455.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21e2f219-2578-4e97-a7e0-235fa4ebe0e7',
    'identification_number': 22922521,
    'item_consumption_1': 16.72,
    'item_description_1': 'DEMANDA TUSD ISENTA ICMS',
    'item_total_value_1': 319.8,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8f63aca4-97a4-4e6a-a985-8d26bb469422',
    'identification_number': 104126337,
    'item_consumption_1': 3257,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1379.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3526d496-958d-42f0-a99c-1a19d512e4bc',
    'identification_number': 56493371,
    'item_consumption_1': 11442,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1460.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40d5827a-16ed-43d7-920c-c5fa32299b17',
    'identification_number': 14138069,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0bb11ccd-4625-4275-8649-f88f1346e36a',
    'identification_number': 94625581,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '317ada5a-8645-4d54-91bd-f724f0d4ea7e',
    'identification_number': 20994206,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38b5f994-8517-4352-89e9-d504ffae79d0',
    'identification_number': 44024924,
    'item_consumption_1': 11354,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 17176.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93b2a339-3dac-4b94-b786-53c5671cc264',
    'identification_number': 108747883,
    'item_consumption_1': 3201,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1357.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2331bdc8-ffe3-4f17-9275-48ddf6eaf2b0',
    'identification_number': 17401682,
    'item_consumption_1': 3778,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 482.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e44bdc4-5931-431b-80a8-b71c02a3f965',
    'identification_number': 105277002,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 112.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cde52fa7-1ab3-49a7-a048-df51b8ae1f8f',
    'identification_number': 96032073,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f05e57a-08c4-48a3-abf5-a71146e569df',
    'identification_number': 32266847,
    'item_consumption_1': 3775,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1473.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68492812-e2eb-4d78-835b-981bdb35c215',
    'identification_number': 103352562,
    'item_consumption_1': 8041,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3414.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8eb57287-ab6a-47b3-9633-3e7e3d4b933f',
    'identification_number': 45907404,
    'item_consumption_1': 3757,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1398.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32e4397d-aa89-4244-ad03-311b32d6c580',
    'identification_number': 99353598,
    'item_consumption_1': 0.42,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 10.03,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f8722210-a7ea-4292-b1de-70974c634368',
    'identification_number': 31080448,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80453538-3a7f-4916-a81e-297ec1950e31',
    'identification_number': 31789366,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 117.08,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '668a6581-6b0a-40b7-95f0-222f19a92ceb',
    'identification_number': 60720514,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '95be74c7-e338-4675-825f-390e8760371a',
    'identification_number': 103143742,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '15264d81-7a5f-44ef-8299-a230b776deb8',
    'identification_number': 109447514,
    'item_consumption_1': 4363,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6600.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e33cde8-3db7-4472-a70b-6380f977e494',
    'identification_number': 106706632,
    'item_consumption_1': 9645,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1231.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '996a16d3-f833-453b-8ae9-e0d49d774dea',
    'identification_number': 51527472,
    'item_consumption_1': 17034,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2174.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9c62d02-b938-4d46-ad16-5c00e2e2c67f',
    'identification_number': 42333628,
    'item_consumption_1': 1022,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 398.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77363dec-2452-401f-9ac1-9e044b8fd48e',
    'identification_number': 41614518,
    'item_consumption_1': 10960,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4269.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bbe0a2c-15e1-4d2e-b06c-101b040f9a87',
    'identification_number': 106708120,
    'item_consumption_1': 36442,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13561.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f39ab818-ae82-473b-a02f-61d50bfc5a49',
    'identification_number': 80788580,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd310ead5-8f6d-4852-b106-67ad8a018b35',
    'identification_number': 109869613,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6bc1eeed-6247-4f72-ac37-153067c07e4a',
    'identification_number': 77217896,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '38e7e8ce-1d55-426e-96aa-5a26847fb591',
    'identification_number': 45653313,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2171afe6-c331-44be-8534-916dd233f6fc',
    'identification_number': 48004936,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8907de27-beee-4c49-973b-ad0ec6c73d85',
    'identification_number': 102481172,
    'item_consumption_1': 483,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 282.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff2c20cb-062c-46f9-af2a-8ee02a7132cc',
    'identification_number': 45478260,
    'item_consumption_1': 35165,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4488.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec03aa8e-60ea-4068-9d6f-98805246ec59',
    'identification_number': 33625930,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '57f5713d-1108-44ac-82ae-df97d3cf3d99',
    'identification_number': 40429458,
    'item_consumption_1': 211302,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 26969.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70200a8a-2728-4800-979f-c31927b9d17d',
    'identification_number': 16307828,
    'item_consumption_1': 2153,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 274.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a62c569-f2e8-4234-90ec-711da10439bb',
    'identification_number': 41360818,
    'item_consumption_1': "'-971.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-311.48",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '235cc6d8-53e0-4d7c-ac2c-41cbacf6e9d1',
    'identification_number': 38718502,
    'item_consumption_1': 10383,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1325.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86b16205-df71-46ec-9ccf-959a8f13e8cb',
    'identification_number': 9823000,
    'item_consumption_1': 2775,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1620.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'acc852d3-a511-423a-b483-5f43668799af',
    'identification_number': 35436778,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b6ffcbb1-d950-42c6-b516-c487d4dfbc42',
    'identification_number': 15719499,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '95a85a2f-4e99-4e46-800e-0213126c8b6f',
    'identification_number': 27271633,
    'item_consumption_1': 3271,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1279.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cde8f481-8025-4dfc-95c5-bdf68b612a02',
    'identification_number': 103033890,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21e144c7-eb7d-4059-95a7-123002ca6d3a',
    'identification_number': 18274080,
    'item_consumption_1': 2292,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 970.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5514590-0942-45ec-9f87-e03618fb7786',
    'identification_number': 60092904,
    'item_consumption_1': 9.39,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 223.96,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0afe6e34-230e-402d-9df0-4e4cf810caa4',
    'identification_number': 66423759,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bbec347d-ffe7-4e36-8cd1-3993632b9bd4',
    'identification_number': 75896974,
    'item_consumption_1': 2081,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3148.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ce2a04e-c7b3-4f36-89d7-3925f0a70a4e',
    'identification_number': 8882991,
    'item_consumption_1': 2022,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 856.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6ef47a4-4f07-4b8e-a23d-2d00e90c9d8d',
    'identification_number': 46920617,
    'item_consumption_1': 84381,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 31402.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24781517-afa2-41a8-8fcf-040e597924fd',
    'identification_number': 22922521,
    'item_consumption_1': 13.28,
    'item_description_1': 'DEMANDA TUSD',
    'item_total_value_1': 309.73,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5d48e1ab-ff40-4c8a-be7a-624888a78f15',
    'identification_number': 22539530,
    'item_consumption_1': 6900,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2699.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7154067d-3ec2-4b67-90bc-235c71d71b0d',
    'identification_number': 19451113,
    'item_consumption_1': 1069245,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 136474.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '433bbe8b-e210-456c-aba1-5eb95b47939c',
    'identification_number': 79885756,
    'item_consumption_1': 373,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 144.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1ef13c4-5f98-49b3-bce4-155b4e8f97c1',
    'identification_number': 109139402,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5c76faf8-20a8-49ad-bfcf-d3ac1d2a087f',
    'identification_number': 107740516,
    'item_consumption_1': 2539,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1482.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea597799-fc04-4edb-83bf-644668f3790f',
    'identification_number': 40435288,
    'item_consumption_1': 29078,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10821.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58376642-67c5-4bd1-bcb4-54a74df468c1',
    'identification_number': 38055660,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'db790e05-7b19-4fe6-93dd-4f1eb7c5a8e4',
    'identification_number': 109770846,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7882422f-78f3-44a4-a192-fdfa82a8da63',
    'identification_number': 923323603,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd9245673-9af4-4074-9b34-19091c426709',
    'identification_number': 30345359,
    'item_consumption_1': 8817,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 13338.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8511c221-9eaf-4ba2-a517-827cc1691b5a',
    'identification_number': 68081251,
    'item_consumption_1': 3.25,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 94.52,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '14ef9444-2bd0-473a-882e-85cf22b215f7',
    'identification_number': 29863589,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25e49f67-5ff8-4c04-8b33-a896201e6a26',
    'identification_number': 19452632,
    'item_consumption_1': 51842,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6616.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a51189cf-885d-49f0-b27b-0b1918ab5f6c',
    'identification_number': 10103554,
    'item_consumption_1': 21982,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 9315.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a948334-5a39-45a1-ba7a-56b26b032d70',
    'identification_number': 96125225,
    'item_consumption_1': 31009,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11539.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5db35c9a-176a-40ae-88ea-c79a38f02b99',
    'identification_number': 6287727,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4ea1abae-29e8-4169-8156-c7d0352bd811',
    'identification_number': 32501480,
    'item_consumption_1': 10739,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1370.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed0b2bd9-f3be-48ef-82f2-6d687761070c',
    'identification_number': 110921399,
    'item_consumption_1': 859,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 365.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07e5f9ff-3fb6-434b-998b-bc007da4846e',
    'identification_number': 28858913,
    'item_consumption_1': 33198,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4237.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6feaf9b0-0372-4f4c-ae65-5d91f773e01a',
    'identification_number': 18205704,
    'item_consumption_1': 241,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '754b1e76-73ac-4418-bae6-dfb8bb77d04f',
    'identification_number': 75064430,
    'item_consumption_1': 1550,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 905.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71e933b9-ab1e-4f0c-a82f-f15e86ad04d9',
    'identification_number': 80788190,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83be7d77-d9ea-4f9f-9024-7029913bd2a3',
    'identification_number': 107783096,
    'item_consumption_1': 2291,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 894.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '387cccd1-6fa0-4822-bb07-5b7ee6046e4f',
    'identification_number': 79665306,
    'item_consumption_1': 57,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 22.3,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b80e2137-2fcd-489a-ab72-d63859bb9c8a',
    'identification_number': 78564662,
    'item_consumption_1': 20148,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7498.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'baa75833-1726-4d83-b227-b73d297955ac',
    'identification_number': 45138354,
    'item_consumption_1': 1488,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 630.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87841c84-a3e8-4f47-a76a-da0d00eeff33',
    'identification_number': 41971817,
    'item_consumption_1': 22126,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8234.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7c2587e-5d71-4b3b-af3e-90d42347da50',
    'identification_number': 94525994,
    'item_consumption_1': 1576,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2384.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d043760-ea41-4d46-9181-d81dda96613f',
    'identification_number': 6100350,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2fa183be-bf8c-40cd-8366-5eb283827dbf',
    'identification_number': 102025630,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f90773c-08e3-4e85-b2bc-0d51d23a97b3',
    'identification_number': 32594852,
    'item_consumption_1': 346.48,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 8263.73,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '216952d8-73dc-435c-9bb7-8a057d69eb31',
    'identification_number': 15091520,
    'item_consumption_1': 2.75,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 65.59,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4fe0b373-431c-4341-9934-033d496ceb29',
    'identification_number': 79969135,
    'item_consumption_1': 1201,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 469.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '757e5147-5766-4e74-b82d-a8005039e2bb',
    'identification_number': 10834184,
    'item_consumption_1': 126,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 49.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c6b89ea-2b9f-4539-b6a0-5bec559f5c0f',
    'identification_number': 56886314,
    'item_consumption_1': 1.96,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 46.75,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0b47c993-8651-4415-96a6-12fde34a1ee0',
    'identification_number': 31900852,
    'item_consumption_1': 81.57,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2372.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a7438f65-b7bb-4cf5-ac1f-b36988918a83',
    'identification_number': 41611187,
    'item_consumption_1': 112456,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 41850.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f548e5ee-05a0-4a31-ac54-6bedf25436ea',
    'identification_number': 16296060,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65766970-fe8e-4487-935a-85a5d86a702f',
    'identification_number': 54073901,
    'item_consumption_1': 9285,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1185.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6b827ba-6d79-4040-a171-09130c24bd02',
    'identification_number': 106713493,
    'item_consumption_1': 664,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 281.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa8346c7-f563-4f8a-a732-51da5b010849',
    'identification_number': 105470708,
    'item_consumption_1': 12733,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1625.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4857dba-c908-4f35-a055-872dd0b7fa30',
    'identification_number': 12399752,
    'item_consumption_1': 8354,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3108.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b298dd5d-9177-4946-9736-07a444ee39d4',
    'identification_number': 90469259,
    'item_consumption_1': 25.36,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 604.86,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c21e9563-5c81-4da7-8665-d3cd9c0f40ea',
    'identification_number': 14225093,
    'item_consumption_1': 24618,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3142.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '689a50e8-70b9-41e5-98f5-afd478c6f168',
    'identification_number': 103002456,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1299dd80-0f16-49a5-a4ad-b9d75e8cdf07',
    'identification_number': 8115567,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3308428-a065-4ae2-b5f5-0cf0e621ecf0',
    'identification_number': 93636105,
    'item_consumption_1': 10199,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4329.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70c4c1c3-233c-4646-9947-0521151c2677',
    'identification_number': 58696172,
    'item_consumption_1': 10819,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1380.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a22e421-1c00-4a38-a8f0-ef671820ec6c',
    'identification_number': 58072004,
    'item_consumption_1': 60.61,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3461.44,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'bfe26622-dd73-454b-9226-575f7682bd0a',
    'identification_number': 108466051,
    'item_consumption_1': 890,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 346.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b86e2786-c96e-4da5-9bb9-a4fccc7b6faf',
    'identification_number': 16951662,
    'item_consumption_1': 1533,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2319.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bde9d49b-dff9-4923-bc28-3377ba29040e',
    'identification_number': 102571538,
    'item_consumption_1': 554,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 216.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd401226c-f52a-443a-918a-7f88a42f262d',
    'identification_number': 15906698,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b0843b1-a2eb-44c6-b854-140a9a021737',
    'identification_number': 92483119,
    'item_consumption_1': 9876,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3857.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6bd95aa-67ab-4f6a-9b0b-f9de2da8ee43',
    'identification_number': 97742236,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7bf0d8b-78cc-469f-8e85-a693a0b18ab8',
    'identification_number': 92828760,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2077.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac9e9eac-d405-440a-96c9-e9f0877a9059',
    'identification_number': 70447330,
    'item_consumption_1': 447,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 261.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1f24ef3-27c0-43f4-8bbf-c5c2482301f4',
    'identification_number': 26925630,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e78b6774-97c4-452f-8420-b53604df6db7',
    'identification_number': 96517956,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88a1a4ff-563e-4905-bb72-61a3ccc24496',
    'identification_number': 92122345,
    'item_consumption_1': 177,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 69.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cd1cdfb-c2c2-4e24-accd-3ade6c6330d9',
    'identification_number': 93003064,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e5a64d60-1653-4491-a37b-e2661016c690',
    'identification_number': 46343466,
    'item_consumption_1': 22,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 8.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ea6f77e6-8850-4cda-9a43-b5d777afb405',
    'identification_number': 102935165,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a4fc3599-d60a-4592-af15-272b2e04c78d',
    'identification_number': 77672925,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 338.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de602c97-931c-4a5b-9b7b-5f2b50a4329a',
    'identification_number': 77771460,
    'item_consumption_1': 90,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 38.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19ff4b88-30df-46db-a9a9-a61029e8dc59',
    'identification_number': 98265172,
    'item_consumption_1': 878,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 512.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51c0f027-f288-4331-b96d-bcc82486d101',
    'identification_number': 84983981,
    'item_consumption_1': 4004,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2338.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a23f9e97-8854-4719-b055-6385eebd55bf',
    'identification_number': 15002578,
    'item_consumption_1': 39177,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14579.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd3e8902-3959-4587-8cdc-18a875f3666a',
    'identification_number': 102259178,
    'item_consumption_1': 32.66,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 949.93,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a95d1984-80fc-4d43-94d1-feaa87cf05fd',
    'identification_number': 54313481,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0cf09675-2860-41f1-a403-a6c3d10230cc',
    'identification_number': 101302622,
    'item_consumption_1': 1460,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 568.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24593d3d-2a0d-4c41-9db0-ad62a6d0b226',
    'identification_number': 39335283,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1fa9fd51-1d11-43a9-9b13-f7bc04ad07bd',
    'identification_number': 55746560,
    'item_consumption_1': 1273,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 743.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a8d31a8-9a8b-4899-9f66-94f7fd9f64b1',
    'identification_number': 5415870,
    'item_consumption_1': 148,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 86.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24fce461-8a17-4aeb-bc8b-695f1e7c83ab',
    'identification_number': 39920984,
    'item_consumption_1': 874,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 340.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3105754-d652-45fa-8bb7-7bf553ae1e1d',
    'identification_number': 45115745,
    'item_consumption_1': 244.51,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 7111.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b3f660cf-9137-458f-abab-abce91b58e66',
    'identification_number': 102036500,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '05a39258-1e42-414b-8e48-a4ff4ef12894',
    'identification_number': 15645835,
    'item_consumption_1': 14648,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1869.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f6ae2b6-1b3f-4bdc-9908-717e7cb02283',
    'identification_number': 93134495,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ce2d419-5bbe-4ddb-a723-14be2f214081',
    'identification_number': 103706992,
    'item_consumption_1': 81590,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 30363.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9db28f2f-c585-4fcf-95bf-fde2f23972db',
    'identification_number': 936166486,
    'item_consumption_1': 1148,
    'item_description_1': 'Consumo em kWh',
    'item_total_value_1': 1000.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57ebad7a-0539-441d-a24d-9c038de93559',
    'identification_number': 56599927,
    'item_consumption_1': 367,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 155.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b97a182-57f0-4d7f-809c-86f9dbd4afc8',
    'identification_number': 9623990,
    'item_consumption_1': 3393,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1436.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73bb0cf4-f530-4f92-b81c-0e8603b42f9e',
    'identification_number': 65331389,
    'item_consumption_1': 137,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 58.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee0a6eff-5189-4961-bb6b-662119b7f844',
    'identification_number': 19282281,
    'item_consumption_1': 116,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 45.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2178d966-6dd7-4b7c-bf45-d274ac2d4da4',
    'identification_number': 81773021,
    'item_consumption_1': 445,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 173.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '809b47ee-ebe8-424e-9367-16cb26fc1ced',
    'identification_number': 48681261,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 93.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '00fae9c8-5004-4f03-880c-ec458467d677',
    'identification_number': 98182501,
    'item_consumption_1': 46.29,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1346.38,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '33f4d94c-5424-4058-a99c-3f25fb352a81',
    'identification_number': 27434826,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 125.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4c23b139-76e9-4e7b-90eb-1dc16340a66b',
    'identification_number': 60035498,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8a7c773-063c-4c3f-9909-ef23563a9948',
    'identification_number': 14334399,
    'item_consumption_1': 3.9,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 93.04,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b45b1357-b3fa-4022-9f8a-5171778cec08',
    'identification_number': 103840923,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c21759a0-4fb8-44b6-b48d-ae79c76a0c70',
    'identification_number': 40995305,
    'item_consumption_1': 6726,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3928.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba5f403a-4898-4e1e-a113-b56f88aec9a6',
    'identification_number': 15719464,
    'item_consumption_1': 10312,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1316.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8daf6647-27ce-4e80-86a9-dfed0c0bec11',
    'identification_number': 99530600,
    'item_consumption_1': 2318,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 904.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9ebd037-72c6-4b83-86c9-39ab3176e505',
    'identification_number': 72507136,
    'item_consumption_1': 14267,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5309.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c5ae8ac-3e24-4265-a084-ddda91b82a0e',
    'identification_number': 105611271,
    'item_consumption_1': 28160,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10479.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b4de54c-cba4-43f5-808c-50816980f7cf',
    'identification_number': 18559980,
    'item_consumption_1': 2662,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1128.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39dc8d34-ddd0-41a8-a0d3-203dffd07e0f',
    'identification_number': 21374792,
    'item_consumption_1': 9.712,
    'item_description_1': 'CONSUMO PONTA TE',
    'item_total_value_1': 5376.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0297c162-e9c0-448a-b5f9-18eeb7350af6',
    'identification_number': 100317022,
    'item_consumption_1': 893,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 379.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8dbdecf-b1e8-4f0b-b9bc-fe3aca0a93b6',
    'identification_number': 68385447,
    'item_consumption_1': 2759,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 352.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3275e1e-c46a-48d9-bb1b-2e5b5a37cf7b',
    'identification_number': 99887878,
    'item_consumption_1': 1747,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 681.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01c0802e-bdc3-4602-a729-9ad2a0a6d7c6',
    'identification_number': 30085918,
    'item_consumption_1': 0.35,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 8.37,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e882fd2f-3804-4477-8329-e5667c83c237',
    'identification_number': 103561404,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cd9e31c-e610-4c32-a6b4-1e27abd77ff7',
    'identification_number': 106682199,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '74538d88-00f9-4c8c-b5d9-19c1dca9e191',
    'identification_number': 58072004,
    'item_consumption_1': 3.76,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 218.72,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '9bdf5b81-6e1c-4cbb-842f-54f3d954c971',
    'identification_number': 35345322,
    'item_consumption_1': 4309,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1832.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba45b1c4-c9b6-435d-956a-d30d9ff30906',
    'identification_number': 42403359,
    'item_consumption_1': 2290,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 892.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0e59f82-917c-41ed-879e-83dc63ccf039',
    'identification_number': 81777302,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 31.26,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '81fe7d37-4380-4c27-ae66-1a6055159847',
    'identification_number': 30569621,
    'item_consumption_1': 4021,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1566.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f29d7430-540d-4b3b-abf1-d2b5ece4255e',
    'identification_number': 45564094,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 41.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '394128cc-92a1-4891-9c73-c6c496ce513e',
    'identification_number': 28858913,
    'item_consumption_1': 158,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 239.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26821694-c011-4ff7-9eab-276649722320',
    'identification_number': 41579577,
    'item_consumption_1': 3215,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1366.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b90c730e-e9ff-4a9b-ad51-a82dcff7925b',
    'identification_number': 40995305,
    'item_consumption_1': 57413,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 21366.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db6a3e84-b924-44ae-a6e8-8918cf3beac8',
    'identification_number': 46259430,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '67d6b23f-ef74-4091-a599-fc8d59c2261a',
    'identification_number': 105662470,
    'item_consumption_1': 429,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 167.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2626479a-1821-459b-b84b-6bdfad9c58ab',
    'identification_number': 94525994,
    'item_consumption_1': 12600,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1608.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30924f96-3304-4188-98fa-ae6b9ead7735',
    'identification_number': 22922521,
    'item_consumption_1': 2.611,
    'item_description_1': 'CONSUMO FORA PONTA TUSD',
    'item_total_value_1': 329.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffa6086d-3784-4d15-87be-45d35243f78d',
    'identification_number': 94625409,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bee2ff20-dc0c-43f2-8a6a-94e7b05f3eac',
    'identification_number': 109633962,
    'item_consumption_1': 1542,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 196.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '120a1d2b-72fa-475a-a07f-58b2fc35ebae',
    'identification_number': 92562302,
    'item_consumption_1': 6023,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2241.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7640d032-b197-46d7-b954-11bcb1c95eac',
    'identification_number': 91358469,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 84.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '46a3901a-2c6d-4f93-a5bf-fb2a8aebdc0c',
    'identification_number': 33800677,
    'item_consumption_1': 5.74,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 333.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'c5ec140a-382d-4821-9869-d07c2a571b72',
    'identification_number': 62362801,
    'item_consumption_1': 29360,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10926.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac32319b-e4fa-4cf9-b44c-c81d5a5c92bc',
    'identification_number': 76229815,
    'item_consumption_1': 24584,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3137.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3788ad5e-8f7c-4414-9e3b-580d7f4f0d5e',
    'identification_number': 18223680,
    'item_consumption_1': 267,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 113.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0facba95-95bd-4a83-ad16-a11244b5e881',
    'identification_number': 107941392,
    'item_consumption_1': 3988,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 509,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63ca1f71-4d36-4f7b-bc4a-bd945282044e',
    'identification_number': 92950310,
    'item_consumption_1': 1209,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 706.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd347b8f3-144a-4c75-b28c-668f15c9c645',
    'identification_number': 81118546,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 229.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6084295c-578e-4e15-b27a-b5b200e4e514',
    'identification_number': 14689146,
    'item_consumption_1': 24473,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3123.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87230b64-d0e3-401a-926e-2696bd490dc3',
    'identification_number': 41069420,
    'item_consumption_1': 804,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 340.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a549493e-f23b-4a3e-89ea-2f4ec38d8181',
    'identification_number': 14354306,
    'item_consumption_1': 779,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1178.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c43763ac-87fc-4e78-86d3-0cd367b7cb18',
    'identification_number': 75597667,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 4.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a7ea1fb6-8792-40e1-8c2c-811bc006be0f',
    'identification_number': 97821403,
    'item_consumption_1': 1284,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 500.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9b5e243-7e6c-4c66-9ef7-740b3a74e655',
    'identification_number': 98005472,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 97.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c85d239b-4a65-4938-86c4-0abb540fb663',
    'identification_number': 93612397,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7b11121f-d1f8-4489-af74-54af9a0fd9c6',
    'identification_number': 69852634,
    'item_consumption_1': 1019,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 432.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '219e1b5b-dacd-41d4-825e-43ffa5e9fb30',
    'identification_number': 14662655,
    'item_consumption_1': 971,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 378.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc43c171-cdf9-4a8a-a8dd-57e9a06b3627',
    'identification_number': 77241924,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '48cb3b7e-07ad-4094-b459-f73e029ca1b9',
    'identification_number': 91487978,
    'item_consumption_1': 977,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 415.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9ee1e72-58ca-4064-8c16-d0356ee8765c',
    'identification_number': 12804401,
    'item_consumption_1': "'-2.333",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 03/2021',
    'item_total_value_1': "'-811.43",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '391966fb-8d98-40fc-8a71-14cb444e6262',
    'identification_number': 29957141,
    'item_consumption_1': 3483,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1479.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91cc5b10-7e26-41fd-8eee-43e4ac055cb1',
    'identification_number': 108298035,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6656.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86915b8f-a1d9-4e63-8212-bf560675288a',
    'identification_number': 64669360,
    'item_consumption_1': 527,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 67.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a79c2e8-05cd-49ee-89ae-d99e82a10e41',
    'identification_number': 15981401,
    'item_consumption_1': 14,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 216.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e1348e45-51d5-45f5-b2a9-4d91573ab8ec',
    'identification_number': 92334865,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 156.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '111e8183-9177-4dae-a34c-d35cde85f2bd',
    'identification_number': 46861009,
    'item_consumption_1': 46.64,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1356.56,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9fd46e74-f6f3-4d74-84fc-f1e591b4f6cd',
    'identification_number': 15152731,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-10.68",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'd0423567-8d02-4a0f-944f-1fe965904862',
    'identification_number': 106046691,
    'item_consumption_1': 3537,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1500.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f8e7fc1-dd86-4569-9135-072a553f437e',
    'identification_number': 99850559,
    'item_consumption_1': 11962,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4659.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e2cea3a-5b55-4e80-a955-2cdf654091ca',
    'identification_number': 38847280,
    'item_consumption_1': 2609,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3946.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'abd1bc43-da59-481b-b6ce-bfec2c4e12bf',
    'identification_number': 105098930,
    'item_consumption_1': 4378,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1860.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9f32f61-d030-4e51-866a-6c8376da232d',
    'identification_number': 16246780,
    'item_consumption_1': 312,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 182.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1379cc8c-cfb2-4427-919f-5ccf1e659d65',
    'identification_number': 48795402,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '769757c2-dd57-407c-863c-2047b409b44f',
    'identification_number': 85192171,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22824fc0-4c08-47b2-a660-5a47fd15a00c',
    'identification_number': 11222549,
    'item_consumption_1': 22560,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 8796.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4092611-9e8b-4c89-9eec-833e1a8a0cd5',
    'identification_number': 103770488,
    'item_consumption_1': 537,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 209.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57ae9300-f621-401d-99d8-1a93946811c6',
    'identification_number': 97610089,
    'item_consumption_1': 12231,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5189.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b982e99d-809b-4756-8b8e-1df9ae271779',
    'identification_number': 15572862,
    'item_consumption_1': 3165,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 403.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edc5ca05-a8db-45aa-aa09-24fb6c708803',
    'identification_number': 95229388,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dad6cc06-5235-4f6a-ad82-be1f69e129de',
    'identification_number': 75890380,
    'item_consumption_1': 734,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1110.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '800e387d-6ae9-492a-8b1d-35e4b4a7d7c1',
    'identification_number': 100214193,
    'item_consumption_1': 984,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 574.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5569d478-0490-48ce-bdf4-525a0c5fb57c',
    'identification_number': 106940210,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60f3cbb8-5ea7-4fbf-947f-80a02e7a5982',
    'identification_number': 18798594,
    'item_consumption_1': 17639,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6564.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1a7344d-ebcf-46c6-8766-f212857a7918',
    'identification_number': 99633671,
    'item_consumption_1': 5904,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2306.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96cc0f79-ee7c-4b9c-8b78-be87a0ba591b',
    'identification_number': 96692979,
    'item_consumption_1': 18.41,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 439.1,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '03d4e7c4-349c-4030-b30f-cb0a6d98d947',
    'identification_number': 111097410,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '19e992b6-818b-4c89-9587-d5b17260f3b5',
    'identification_number': 40122654,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1f4d725a-2a5c-487c-86a8-9551071af258',
    'identification_number': 98528785,
    'item_consumption_1': 1072,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 455.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7520422-938b-4b30-96b2-0dd85bc48f3c',
    'identification_number': 67917283,
    'item_consumption_1': 128.11,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 3726.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '40c5949c-dd76-44a2-9ae4-bd3c805d8cb9',
    'identification_number': 57387206,
    'item_consumption_1': 795,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1202.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7e1eeac-3003-4036-a3b1-6726ffd81488',
    'identification_number': 93918143,
    'item_consumption_1': 1586,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 617.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '968e1fa9-4f55-4690-8a2b-9183e72936e5',
    'identification_number': 35559217,
    'item_consumption_1': 15.34,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 365.87,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '969525c6-c7c1-4182-bc28-3afb716d6fd9',
    'identification_number': 101046820,
    'item_consumption_1': 4122,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1605.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98e5c02c-5f2c-4735-86ba-1d6d0f66db05',
    'identification_number': 31640427,
    'item_consumption_1': 988,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1494.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96e5d0c6-e3be-4cad-99fc-fa82d97d5bce',
    'identification_number': 948743702,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5840b650-45a9-4993-b9f8-93fb5645a970',
    'identification_number': 5826373,
    'item_consumption_1': 11050,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4679.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5a23046-6a2a-4394-8be7-33ff835e9d74',
    'identification_number': 109858859,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c79efd84-b575-4766-9ef7-3dcea6471145',
    'identification_number': 20602510,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 76.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '92742c11-b9ec-4579-9c2b-e794143d8222',
    'identification_number': 110001451,
    'item_consumption_1': 552,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 215.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '370b626a-0bed-4743-b0c9-f5d045e04862',
    'identification_number': 104991402,
    'item_consumption_1': 5541,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2345.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d7a6f26-59e3-4955-a50c-67247e6e3f56',
    'identification_number': 45768279,
    'item_consumption_1': 0,
    'item_description_1': 'CREDITO NAO DEVOLVIDO',
    'item_total_value_1': 2000.07,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '0b7f4314-f2a6-4dcf-94e7-157b1531fba6',
    'identification_number': 84968559,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fc27657-1a52-4e87-89fa-a404d1035ec7',
    'identification_number': 91711630,
    'item_consumption_1': 15.49,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 369.45,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e4680c3b-56b1-4c9a-8691-538524f1506e',
    'identification_number': 46006583,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f39b6bd3-95a3-4ccf-b579-6e410c842b24',
    'identification_number': 8866163,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.93,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c39df5bf-b9f6-4cdf-bae1-5b1744466f22',
    'identification_number': 19450672,
    'item_consumption_1': 29.36,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 700.27,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a868f0fb-baec-4e3b-8729-7265b407e0b0',
    'identification_number': 43484506,
    'item_consumption_1': 12352,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4596.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d31d39c-84d4-41ff-8c87-b5e42e1db5d2',
    'identification_number': 13220462,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c9946786-a100-479f-a2de-d6b962f1951f',
    'identification_number': 19612516,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'db4f278c-92e6-464b-9422-34adf593fc2a',
    'identification_number': 38503328,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3de4b451-8c99-4aab-b92f-c0493ea8394f',
    'identification_number': 34976469,
    'item_consumption_1': 290,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 113.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd06afbaf-a76e-4e80-9217-f7d37b3617af',
    'identification_number': 44502931,
    'item_consumption_1': 8318,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1061.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3f24643-7466-4c71-a1e6-89aef5ad56cc',
    'identification_number': 20700806,
    'item_consumption_1': 125,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 52.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e43e590-1860-4ead-960b-a4fe9fb838f7',
    'identification_number': 63897148,
    'item_consumption_1': 323,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 126.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'fbbcaa17-bdf0-4f0a-a9e8-1e82860bf847',
    'identification_number': 100335870,
    'item_consumption_1': 829,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 352.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9c52e32-3f61-4253-a62e-263e8bba901e',
    'identification_number': 3034303075,
    'item_consumption_1': 50,
    'item_description_1': 'Custo Disp Uso Sistema TUSD NOV/23',
    'item_total_value_1': 25.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c5b4d8d-80ab-4eb5-96df-b5dd43f10239',
    'identification_number': 40701301,
    'item_consumption_1': 501.12,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 14575.55,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1329c10e-32c1-4226-9959-3706c99ec932',
    'identification_number': 33799733,
    'item_consumption_1': 5888,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2191.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16f27cb9-0135-4f80-8d0e-0fbb83e4b8b8',
    'identification_number': 8157944,
    'item_consumption_1': 11503,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4876.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76039c79-1eaa-481c-b716-b5306e171983',
    'identification_number': 18616917,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '53b67bbc-a898-43a4-913b-db7ace0d84f7',
    'identification_number': 66244013,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'eb3af20d-0aa7-47cf-9351-e8f20c4dd635',
    'identification_number': 12026242,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 75.2,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '800b82f3-eedb-4ca6-b5d8-c9cb18e4cb3b',
    'identification_number': 75062046,
    'item_consumption_1': 24.74,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 719.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '54b3f2a9-ef0f-480c-b58f-82538464b158',
    'identification_number': 56526849,
    'item_consumption_1': 11.9,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 283.84,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fa54e683-7ccd-41e7-964c-7033acdbae40',
    'identification_number': 101073593,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5edb0486-996c-489e-84cd-d78cc481d030',
    'identification_number': 26954370,
    'item_consumption_1': 1245,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 528.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62c713bc-2222-4c64-81b5-f1a91d509a4e',
    'identification_number': 13884140,
    'item_consumption_1': 11,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 6.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36435714-f3bb-44ef-92f1-fb1ca7d9878c',
    'identification_number': 18017703,
    'item_consumption_1': 7826,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3055.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '824bec0b-10f5-427c-853f-87a6ebbb029a',
    'identification_number': 14039362,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '07a379a3-4a30-40ab-8a09-069b4528cb17',
    'identification_number': 109139402,
    'item_consumption_1': 2267,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 887.01,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '70cea072-85e5-4a5b-abd2-10e6ff890eed',
    'identification_number': 33162557,
    'item_consumption_1': 7018,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2611.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f81bb01e-2186-45fa-886a-23f990ce7d13',
    'identification_number': 22358609,
    'item_consumption_1': 4287,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6485.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8dd75cdb-38c2-42a5-a132-7d9afeb23794',
    'identification_number': 54072719,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6495dd1-46d1-4c28-9d54-8dffdf2f4285',
    'identification_number': 32503784,
    'item_consumption_1': 4668,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7061.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0bb428c3-a1f8-4a78-855c-bff463961f3b',
    'identification_number': 29511666,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be4e14d4-ee2f-48de-a7be-e76de4026176',
    'identification_number': 31818030,
    'item_consumption_1': 2780,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 354.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7306599b-a9cb-41b6-9c37-f7e358e65db8',
    'identification_number': 98798510,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 7.83,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b18cbddd-0e9a-4e79-be09-622413ac44ee',
    'identification_number': 6941044,
    'item_consumption_1': "'-37.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-11.85",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '8ee030d1-ad01-44fa-a343-bde9b1e6957b',
    'identification_number': 40994724,
    'item_consumption_1': 71916,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 9179.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8acbebc1-74d2-4914-9fba-2ea1f2a27063',
    'identification_number': 32202032,
    'item_consumption_1': 100,
    'item_description_1': 'C.DISPONIB',
    'item_total_value_1': 77.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9564b91-80a9-429e-ab5d-c770ab2024b4',
    'identification_number': 36289515,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 012/064',
    'item_total_value_1': 9.22,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '0111708e-c955-4f77-a1f5-9b456382b74f',
    'identification_number': 53573749,
    'item_consumption_1': 185.48,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 5394.85,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '90dcf5f6-a9c8-459e-b368-794e7e1049d9',
    'identification_number': 108023265,
    'item_consumption_1': 1278,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 498.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18ae8022-7103-4606-809b-689e1ee82b17',
    'identification_number': 19312334,
    'item_consumption_1': 2807,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1095.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f477012-37a9-4b4a-8495-855a6bd3ed23',
    'identification_number': 34739297,
    'item_consumption_1': 1624,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 632.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ecc1824-ccf6-4255-a69b-cc8871daf00e',
    'identification_number': 19207611,
    'item_consumption_1': 2342,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 993.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bf7d214-b379-46d8-9412-a2125ed4d006',
    'identification_number': 97332631,
    'item_consumption_1': 8823,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3742.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b52d95ad-1a5e-4da8-8249-31c90f2e2044',
    'identification_number': 19451113,
    'item_consumption_1': "'-21.0",
    'item_description_1': 'ENERGIA INJETADA FP TUSD 11/2023',
    'item_total_value_1': "'-2.18",
    'reference_month': '11/2023',
    'referente': 'Compensação crédito energia',
  },
  {
    'uuid_items': '6665bbac-0cc0-4b1a-824f-59ee65f63ff3',
    'identification_number': 110411897,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b83dd9d1-4a1a-46cd-91e6-4ec0c860996e',
    'identification_number': 39214699,
    'item_consumption_1': 676,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1022.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '018a2795-9284-49de-95f6-18bba2f37847',
    'identification_number': 109203771,
    'item_consumption_1': 139,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 59.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4f1c814-f029-4d24-93bb-0e6085ce8274',
    'identification_number': 12673994,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1519.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6f4707e-5b1c-4058-ad00-a95213964fef',
    'identification_number': 100217290,
    'item_consumption_1': 492,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 208.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aff21d39-5216-43a0-8377-9d5ce8cf01f6',
    'identification_number': 41176111,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '582bb3c0-ec6e-4ca7-9a85-9485fd8436c8',
    'identification_number': 91996058,
    'item_consumption_1': 1504,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 638.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50075356-add3-45e2-bcac-6c381a1e2df4',
    'identification_number': 93302096,
    'item_consumption_1': 1484,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 580.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2306104-44bd-4183-b1eb-9a68b950085b',
    'identification_number': 19307098,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cc3a63b1-6a84-4c81-8a5e-293ffa475a37',
    'identification_number': 46699236,
    'item_consumption_1': 1278,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 163.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1953edab-7279-4e89-b8dd-0aee98c37927',
    'identification_number': 53573749,
    'item_consumption_1': 99612,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 37070.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0b7f0f2-ed73-45d0-b8f9-4a1dbd4a3503',
    'identification_number': 11222549,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 75.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '07eb8d33-ad8e-426d-84f0-f15b912fae65',
    'identification_number': 96504722,
    'item_consumption_1': 2872,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1220.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6865c37-c503-4969-8ba9-bc463b13b3e7',
    'identification_number': 41460510,
    'item_consumption_1': 12.05,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 287.41,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'cb3e6c4f-33c0-4b2d-bbf9-86f5fa5db029',
    'identification_number': 107429535,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd91205f3-aef3-4d4e-974a-28cc1704dfb3',
    'identification_number': 30015189,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-94.3",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '0f20a8ee-74a3-4bbc-8679-261ca8eace69',
    'identification_number': 54073901,
    'item_consumption_1': 9285,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3455.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '898acece-ea9a-4184-b4c4-df0f96dde881',
    'identification_number': 106885880,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5524542b-e791-407a-bc66-87eaca131425',
    'identification_number': 89118162,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 192.49,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'fe9fce00-e1b4-46e3-82b8-06f438f41d9b',
    'identification_number': 77217896,
    'item_consumption_1': 3277,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4957.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de716651-7316-4342-b87b-0a33dc3d258d',
    'identification_number': 87263220,
    'item_consumption_1': 179,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 69.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'acce02d3-ba86-4510-afab-2d8a3544387e',
    'identification_number': 8843015,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '688ae224-de1b-4f1c-8338-d84a1e94bdca',
    'identification_number': 42627117,
    'item_consumption_1': 3228,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4883.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9773422-2105-4e3d-8002-f66ade9fd222',
    'identification_number': 111833868,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72efc7cb-dc7e-49ff-ac3b-aa27a2db427b',
    'identification_number': 111114802,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '138b82bc-1720-4333-8275-0bdc9cbb82e3',
    'identification_number': 5403367,
    'item_consumption_1': 1410,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 549.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f089d99-e4f6-43da-b621-3fbd74e54ee5',
    'identification_number': 102687560,
    'item_consumption_1': 4.29,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 249.55,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '25da0c52-ad39-4005-a0e7-ee2031c88d42',
    'identification_number': 16701917,
    'item_consumption_1': 11626,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4537.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b4bdbea-deda-4a5d-abd3-1f56953b3efb',
    'identification_number': 39196461,
    'item_consumption_1': "'-2.091",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-727.96",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '6e78a02a-b101-486b-9ed3-e30cca778d64',
    'identification_number': 40429458,
    'item_consumption_1': 19147,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 11182.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '265ab2c7-cc33-4e18-bc66-2802a9961536',
    'identification_number': 46364013,
    'item_consumption_1': 34.63,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1007.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '96cfa6c3-d8c3-4fa7-847a-72db1794e79b',
    'identification_number': 14138018,
    'item_consumption_1': 20.81,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 605.26,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6fb29145-e0c6-4268-b78c-ea19789fcc47',
    'identification_number': 106605712,
    'item_consumption_1': 493,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 287.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad9e8ac0-ac12-4eae-98cd-e26faacd7c59',
    'identification_number': 110519132,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4ace607-03be-47d8-80df-a3dcef8caa45',
    'identification_number': 106861182,
    'item_consumption_1': 8885,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3474.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21033148-5cc3-4a2f-8e0a-8b7784357007',
    'identification_number': 68021348,
    'item_consumption_1': 1456,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 569.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24ac2e5f-f34a-43c3-b761-84958c3e46ca',
    'identification_number': 94525994,
    'item_consumption_1': 12600,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4689.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35e9ad36-f9a3-491c-a0d7-b15e15a86ac2',
    'identification_number': 103175954,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c809db3d-7b65-4841-b784-9bdea20c2d86',
    'identification_number': 51527987,
    'item_consumption_1': 112,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 43.8,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b49dd603-90bf-4905-bf51-2ba9e41cc301',
    'identification_number': 75064430,
    'item_consumption_1': 23,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 8.98,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2ed8910e-780c-42b5-9c8e-2552b849609f',
    'identification_number': 32595921,
    'item_consumption_1': 3131,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4736.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8b37635-07d7-4a0a-bf27-2be19777f2cd',
    'identification_number': 109447514,
    'item_consumption_1': 42446,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5417.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'afb64a6d-e6bc-4694-a73d-a7a9298c7066',
    'identification_number': 31690955,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b8ba846e-010f-431e-997a-f5ac9ec91040',
    'identification_number': 43699553,
    'item_consumption_1': 4998,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2119.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9ebe80f-5d79-4b39-93f5-72d7f69774f4',
    'identification_number': 105816280,
    'item_consumption_1': 136.57,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3972.25,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9bbdc290-3a57-4db8-bfef-be67a2a35ffd',
    'identification_number': 56812477,
    'item_consumption_1': 13.35,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 625.19,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0da07149-df82-42fd-8b4c-47f26278fb81',
    'identification_number': 44184727,
    'item_consumption_1': 3217,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1362.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce801a94-cd0d-4bc6-ae78-d91e7e115286',
    'identification_number': 32794088,
    'item_consumption_1': 289,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 122.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8193aa6-6157-4db6-9974-691401cc51dc',
    'identification_number': 31789366,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 285.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '055c5c54-04c4-42c6-bb2d-5dc4bd3335d5',
    'identification_number': 30180392,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '210f0a5e-c8db-42ac-b66a-914a6102242d',
    'identification_number': 81170629,
    'item_consumption_1': 2194,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 855.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0953b1b5-7a43-4d8f-ae0c-9c114f21b9ed',
    'identification_number': 100612075,
    'item_consumption_1': 1.99,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 47.47,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '02387e0e-b1eb-4fd5-9798-58582501e28a',
    'identification_number': 10877100,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 120.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '127675c9-1f84-49e1-99c2-b27f7d1a0407',
    'identification_number': 32151179,
    'item_consumption_1': 13.73,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 642.99,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4207d023-817c-4422-8676-a11afdfb308d',
    'identification_number': 105628271,
    'item_consumption_1': 32.81,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 954.3,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a452f086-fac5-45df-adad-c64566b52d78',
    'identification_number': 95411437,
    'item_consumption_1': 2737,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1067.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'beec5140-2425-4a9a-86bd-081e97361c7e',
    'identification_number': 92850030,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d8e3190-4dba-43be-9050-90e28b76e990',
    'identification_number': 41755138,
    'item_consumption_1': 67281,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 25038.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b1aa4b8-f477-4438-b6a0-c64cf910143e',
    'identification_number': 26275287,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '949ffdab-6f14-4346-ac96-4ae72c84bfa5',
    'identification_number': 43739458,
    'item_consumption_1': 8548,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3619.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf71e2d2-a7a9-4638-a78b-d828c4d4623a',
    'identification_number': 59455896,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7d4f2ea-8b12-4b56-994e-a4a1bb54a026',
    'identification_number': 101941714,
    'item_consumption_1': 1029,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1556.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1e5ecb1-2602-46d4-8692-a067688c4980',
    'identification_number': 105849944,
    'item_consumption_1': 6779,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 865.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eba63c13-e8f7-4476-a04d-8b451842d67b',
    'identification_number': 97171425,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5b826d5-6c07-4519-9ded-e75b11690d3c',
    'identification_number': 19450133,
    'item_consumption_1': 20.48,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 488.47,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '549705fe-c40c-4a0a-8d3b-3f2c65352d6d',
    'identification_number': 56617968,
    'item_consumption_1': 1034,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 439.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '553c2bb2-2713-4669-a89c-b6c68ead13a5',
    'identification_number': 103887695,
    'item_consumption_1': 42956,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18215.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d3b2534-0de0-413f-8773-82f0f760f851',
    'identification_number': 45825319,
    'item_consumption_1': 11644,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4551.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b7136b0-79e6-429e-9759-18aa57ef5fea',
    'identification_number': 11044675,
    'item_consumption_1': 4016,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1700.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3893db68-fd6f-41b1-8d5c-467c86c4d94f',
    'identification_number': 42404576,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '56a56fe7-0ff5-4fcc-a0a8-eee76d9a14ed',
    'identification_number': 91359511,
    'item_consumption_1': 1007,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 588.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b9ce441-7b0e-4474-82b3-3938dd8a4a5f',
    'identification_number': 1841165,
    'item_consumption_1': 3360,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1425.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa5333ed-cca0-4bd6-9d58-ad7891fe612e',
    'identification_number': 40611914,
    'item_consumption_1': 19970,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2548.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7efcac64-48b9-400d-8032-8cd611207a29',
    'identification_number': 6210309,
    'item_consumption_1': 7953,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3373.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d034087-aebf-48ea-b135-fde2c908b309',
    'identification_number': 109460227,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4536640-ccee-4d91-96b8-e97689d9c4d9',
    'identification_number': 93308965,
    'item_consumption_1': 30.01,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 872.85,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '94f9855e-a2c6-49d0-857a-21f542e71864',
    'identification_number': 110226534,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '396677fd-4df3-4f9a-9063-929d998bb810',
    'identification_number': 99569280,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2967326c-c265-44ec-a4ff-8149c26975bb',
    'identification_number': 101912331,
    'item_consumption_1': 313,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 122.45,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '83fafdf2-c07b-4e05-904d-4f45fdbc171d',
    'identification_number': 100627188,
    'item_consumption_1': 21905,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2795.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '629f7fee-3b41-4cbb-b098-9ae7ebf95c2c',
    'identification_number': 98370200,
    'item_consumption_1': 5.03,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 119.99,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '540f1e84-64f0-4f81-9dac-acd21cae8376',
    'identification_number': 107438755,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '665a0b94-22c7-45a0-8c50-68767d077754',
    'identification_number': 103177477,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '87f940ff-1882-461a-a068-765ae55562f3',
    'identification_number': 69941459,
    'item_consumption_1': 2152,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 841.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1b55fff-d6b4-4fb4-b7e7-e690c00da0bc',
    'identification_number': 8860513,
    'item_consumption_1': 3410,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1447.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'deb1947c-fd6f-4096-8ba0-1b6f34fae67e',
    'identification_number': 10072004,
    'item_consumption_1': 1,
    'item_description_1': 'Iluminação Pública',
    'item_total_value_1': 55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6f5d339e-9ace-4dbc-91d3-b7f29d7989eb',
    'identification_number': 40032060,
    'item_consumption_1': 52593,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 19572.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37e03b95-5ba4-430f-a040-b9755542a086',
    'identification_number': 14138018,
    'item_consumption_1': 1236,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 721.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb4aa33d-1a11-44c9-8128-04ab242acbab',
    'identification_number': 92457460,
    'item_consumption_1': 4428,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1728.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d3b0dd1-3729-418a-a179-40b69f0b171f',
    'identification_number': 86724860,
    'item_consumption_1': 624,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 264.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af83ed60-960c-4571-9145-eaaacfdb7332',
    'identification_number': 103284770,
    'item_consumption_1': 3271,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4948.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8e5d77f-7686-4bec-abc9-157dd96fb525',
    'identification_number': 106601253,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c77e2d4f-c9cf-4284-8837-1221b41ba6e8',
    'identification_number': 923340193,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '971efe28-721c-4c3a-b7e2-cf20e0868cd0',
    'identification_number': 12995061,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '81cae6d8-9ccc-417f-9a45-df7292394d35',
    'identification_number': 98264370,
    'item_consumption_1': 481,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 187.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '068242e3-498f-4708-80a1-2bbd6db68714',
    'identification_number': 81506740,
    'item_consumption_1': 9848,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4185.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb99fe3c-818d-4494-96ed-75ebb8fc8d50',
    'identification_number': 60716940,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12b3ed63-9912-4921-b61c-9120764561d6',
    'identification_number': 94919909,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a4bbc44-b2c5-412b-ad1e-bd445b7c06a8',
    'identification_number': 59716606,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c11b1be1-9656-498b-8a66-c2b64adb1216',
    'identification_number': 105150134,
    'item_consumption_1': 638,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 271.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '534da67c-1bd7-4625-90ce-e4462b13baf3',
    'identification_number': 107521431,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2952766-a402-4466-ba25-08c6ad8b9875',
    'identification_number': 62515438,
    'item_consumption_1': 5221,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7898.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '379b5ed3-00a6-44b7-bb1f-4826ba1c0903',
    'identification_number': 38055660,
    'item_consumption_1': 24248,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 14161.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe72b660-7228-4345-9847-eba5c325bfc6',
    'identification_number': 106116991,
    'item_consumption_1': 1425,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 555.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96893ba6-c2f7-4797-b03f-48fc2531b143',
    'identification_number': 37998447,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e63e66e0-fd60-40d1-9856-8c1ca2211eef',
    'identification_number': 19429525,
    'item_consumption_1': 38.37,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1116.02,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6b0d9133-a66d-4a04-b1dc-0e8e64a3ca15',
    'identification_number': 103337300,
    'item_consumption_1': 2397,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1399.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49a94348-b108-4aa5-b6d6-b2ce400c0b19',
    'identification_number': 96504722,
    'item_consumption_1': 2872,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1122.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3913776e-44b1-48af-b8ad-3eff8424ad1b',
    'identification_number': 45677468,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fe87cf8a-9425-41a1-9ad3-99927dfc7a33',
    'identification_number': 33570191,
    'item_consumption_1': 1887,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 801.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f888be09-64c2-4202-83c8-9f0c2b85090d',
    'identification_number': 102003211,
    'item_consumption_1': 56.03,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1629.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '444abe51-ccc3-43e3-a28d-f8ce0fcc2e54',
    'identification_number': 15002578,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd0331b0d-0d96-475f-a3d4-14ad66ba35de',
    'identification_number': 94497451,
    'item_consumption_1': 255,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 99.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2865f3a2-bb4d-40c3-91ae-a22e6c031a4d',
    'identification_number': 15397009,
    'item_consumption_1': 575,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 989.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d30e790-4f51-4a42-ac79-add36296244e',
    'identification_number': 72777320,
    'item_consumption_1': 11961,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4451.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fb9f2cc-37c2-4167-8dde-aa0fa9f24642',
    'identification_number': 100797075,
    'item_consumption_1': 7086,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 904.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4b089f8-09c4-42d1-9792-2cccb7e76606',
    'identification_number': 9857737,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe999a4a-4862-4713-8bb6-a5a73830dd11',
    'identification_number': 102654549,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV.CORR. MONETARIA AJUSTE FAT.',
    'item_total_value_1': "'-4.66",
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '17c31302-1a02-49ef-819d-9f76b08ba626',
    'identification_number': 95917160,
    'item_consumption_1': 20474,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2611.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cfdd7e73-d25f-4f99-93f1-2fca60bf346e',
    'identification_number': 45838313,
    'item_consumption_1': 12864,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4787.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e00804b9-c990-452f-b30c-739de7725857',
    'identification_number': 104547863,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1af2069d-d911-45f4-ac45-281b34020836',
    'identification_number': 92842216,
    'item_consumption_1': 1180,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 499.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '506c3ca8-548b-4707-b6ee-9aaf83a71ea2',
    'identification_number': 93953674,
    'item_consumption_1': 1054,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 446.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64843d0a-33ff-4325-b1d9-55d1fc3f0dcb',
    'identification_number': 72063564,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 1926.7,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '002f96cc-9677-41ca-ba4d-8575a52288d5',
    'identification_number': 37192744,
    'item_consumption_1': 1267,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 493.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '700dabb3-8994-42a1-b6e7-d385d6c4c3b5',
    'identification_number': 98041371,
    'item_consumption_1': 10377,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1324.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c45eec43-f1ff-4cc5-91c8-0609393b2a0a',
    'identification_number': 97575941,
    'item_consumption_1': 29.56,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 859.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd3f925ed-cb48-441a-ad12-fddc858770df',
    'identification_number': 46523286,
    'item_consumption_1': 34.83,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 830.74,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2dd422a8-fb26-40c5-a693-c4dc4ecbb08a',
    'identification_number': 28860136,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '963e3681-e20e-4f33-b4a5-51c7bb74c2f5',
    'identification_number': 104480050,
    'item_consumption_1': 900,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 525.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ac5e060-5943-4489-8af0-18654cb098e6',
    'identification_number': 100343244,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '37ec3a83-502d-4518-a3aa-a56f4fb36bcb',
    'identification_number': 17023491,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 120.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1cba4d71-310c-43c6-b42e-5ad3a0684e1b',
    'identification_number': 85070661,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '46233579-3b07-4e54-86d2-3947dfe0d589',
    'identification_number': 34498605,
    'item_consumption_1': 1845,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 719.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8710655-aafa-4f65-964b-597d9051bc1b',
    'identification_number': 70086389,
    'item_consumption_1': 3259,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1381.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5799f5b2-fedd-4e67-9048-364025c1a363',
    'identification_number': 12991996,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '05f144a2-b2b3-42dd-b6b5-f5ec272626a2',
    'identification_number': 15092909,
    'item_consumption_1': 4388,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1713.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '328a5871-2dad-4b26-ba38-463f4a49ce35',
    'identification_number': 13877984,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 82.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '674163c5-a630-4c0b-8e05-d08e0bd310b5',
    'identification_number': 101341547,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 96.71,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cff4f4a6-bc06-4ac4-8ef5-f6bb765e795a',
    'identification_number': 92842216,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6d752dba-64fb-4155-9672-41edd44be031',
    'identification_number': 110227123,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0184e6b1-fb5a-4330-9780-4ccacb843029',
    'identification_number': 63991373,
    'item_consumption_1': 11.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 321.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd7e93d31-4350-4c45-8fe1-1b20938984c5',
    'identification_number': 92912290,
    'item_consumption_1': 16.21,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 471.47,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '59c4f44b-f263-463d-8571-fc0d39994882',
    'identification_number': 93256876,
    'item_consumption_1': 747,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 291.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b8caa23-0ba9-4d17-912c-fd14d3e6bb1f',
    'identification_number': 60767928,
    'item_consumption_1': 3164,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1847.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '557dca05-733b-4287-b527-ac1d2a838abb',
    'identification_number': 48681261,
    'item_consumption_1': 11.61,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 276.93,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '63a85579-808c-4c42-b8c3-504be583cbff',
    'identification_number': 106869132,
    'item_consumption_1': 5111,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2167.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c217537-a978-45e1-890b-f1a49048992f',
    'identification_number': 102611033,
    'item_consumption_1': 936,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 397.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'caeabd2b-04c6-49c4-b1aa-323e13eaaafb',
    'identification_number': 69972079,
    'item_consumption_1': 9552,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3730.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a8808b8-ecf2-41c5-94f6-fcf491f8fce8',
    'identification_number': 85205672,
    'item_consumption_1': 38941,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14491.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2239fecf-8c22-4dc5-99b1-2e15190993b5',
    'identification_number': 8115559,
    'item_consumption_1': 501,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 212.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd492dbe6-04ec-44f9-b23e-d3ef5cb366f3',
    'identification_number': 35559217,
    'item_consumption_1': 6843,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3996.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76934377-e56b-4aae-b33c-b6b6f32cb0fa',
    'identification_number': 69032467,
    'item_consumption_1': 4995,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1951.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbb1f75b-f93b-45cc-8abe-f5e589b50789',
    'identification_number': 14938170,
    'item_consumption_1': 3960,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1676.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6090c789-b5a7-4b8d-8361-a26a4a499e8e',
    'identification_number': 49074377,
    'item_consumption_1': 30,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 715.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f6490dce-6b77-4683-a4fd-e29e0ab9fc50',
    'identification_number': 95555234,
    'item_consumption_1': 1430,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 607.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fe58e88-ca88-4bd7-beae-984fd1988356',
    'identification_number': 57742944,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2174a6fb-aafe-4869-8f42-c29e21c629f4',
    'identification_number': 46989153,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0c2daee-7e55-4c64-9f6a-fe652c501a37',
    'identification_number': 75403943,
    'item_consumption_1': 5.29,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 153.85,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '7b13bd4c-af07-4e1b-91eb-1d39b1b6e6b6',
    'identification_number': 92056920,
    'item_consumption_1': 389,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 588.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66a22b98-39df-4cf7-a760-fc115f62da95',
    'identification_number': 66832330,
    'item_consumption_1': 3482,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1295.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04a0e3dc-0eb7-4add-b0d7-6a848c3050e1',
    'identification_number': 111320550,
    'item_consumption_1': 58,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 22.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75574197-f70b-4cb2-98df-ceda66e99a45',
    'identification_number': 19309490,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a67006c5-1b9d-47d6-868f-2e81a97bde09',
    'identification_number': 45789061,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '41a827eb-5e14-4de5-967a-66b04af27261',
    'identification_number': 40184188,
    'item_consumption_1': 2.99,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 71.33,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '312b5e2e-f18e-40f1-ab1a-927030f1e5e5',
    'identification_number': 31564496,
    'item_consumption_1': 27.46,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 654.96,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd0759961-fb46-4478-a4e3-f7e397509b1f',
    'identification_number': 14938170,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '02222a47-c9c2-4ac0-ac17-6ab0523db48f',
    'identification_number': 5863473,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56a59329-07a0-41cc-bf4b-fa600bb87d6f',
    'identification_number': 92167942,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 121.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2dd3370e-4a19-4a13-a89a-b45430130fa1',
    'identification_number': 99042223,
    'item_consumption_1': 2964,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4483.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddb9c14d-72e9-4a90-861a-dddd8605f428',
    'identification_number': 40767884,
    'item_consumption_1': 387,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 151.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99252476-ad43-4cb3-8f90-5d075082c195',
    'identification_number': 79663036,
    'item_consumption_1': 4412,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2576.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3767b5f-b7e9-4fec-be97-353a46c87870',
    'identification_number': 110323912,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89edb5e3-2b87-48a1-bfef-611ec0518d2f',
    'identification_number': 32899890,
    'item_consumption_1': 628,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 245.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e05bc40a-363e-4756-96e7-2e822eebb5ec',
    'identification_number': 17073057,
    'item_consumption_1': 1310,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 765.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f00485d-896e-4bc9-9160-6ecc31fa42a8',
    'identification_number': 21718989,
    'item_consumption_1': 25.36,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 604.85,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e3ade482-4fe5-418b-b4ce-8f6b741485f8',
    'identification_number': 103025790,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ed5d033-41b0-400a-8baf-daee680b5033',
    'identification_number': 31846882,
    'item_consumption_1': 269.81,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 7847.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bfcdc07a-81b9-47a1-9edd-f838f41b117d',
    'identification_number': 101918925,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '655a7e31-a86e-4c89-b662-f4f7523a80e5',
    'identification_number': 91996058,
    'item_consumption_1': 1504,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 587.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b36ed1a-f472-4dd4-a27a-46cbc2a242b9',
    'identification_number': 17889243,
    'item_consumption_1': 9040,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3536.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e4c43ef-9bed-46a1-b1f6-0564e7b92a7c',
    'identification_number': 41360869,
    'item_consumption_1': 35948,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 15283.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf3febae-3f0a-4619-b43d-a09250973df3',
    'identification_number': 106999060,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 207.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f54bfdb0-0872-40ce-91fb-36edd39f5842',
    'identification_number': 57638519,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38a13a2b-a79b-4b7a-ac1e-cf491fd6977b',
    'identification_number': 55522815,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbe77270-6a8d-4b4a-984e-35f0e3f84398',
    'identification_number': 105763101,
    'item_consumption_1': 90.04,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 2147.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'bcb0bcf4-b14d-45e6-9c45-8328ce918f1a',
    'identification_number': 37192744,
    'item_consumption_1': 1267,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 536.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87d61c96-40a2-4efe-bb83-8d977bb07f50',
    'identification_number': 4334221,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'be700d20-e8de-4197-9a4d-d0071d5c3782',
    'identification_number': 23135395,
    'item_consumption_1': 487,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 206.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee00d274-e3bb-4790-a2fb-cd8d18a9061a',
    'identification_number': 94694648,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '44bd2f0c-b409-4f6c-b939-f4316f1ee5d4',
    'identification_number': 103706992,
    'item_consumption_1': 81590,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 10413.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a11d95fc-6b26-48ed-b3b8-9ea736aff37d',
    'identification_number': 17690200,
    'item_consumption_1': 1200,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 467.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73597697-a15b-4444-815d-1cbec62927f4',
    'identification_number': 19450737,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22eed6c2-46ef-48ec-a1b4-a719e2c7b0fb',
    'identification_number': 40803635,
    'item_consumption_1': 2928,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1243.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3d5b6dc-4e9f-4acc-b73d-fc6a1f67d7b0',
    'identification_number': 111926106,
    'item_consumption_1': 471,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 183.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42dd797f-3985-4506-9907-0e12f9d34400',
    'identification_number': 14063476,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '476ccd3d-4af1-4a45-99fb-8abab185e743',
    'identification_number': 99569280,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 25.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e889dae-1d4e-4229-8e29-d529e7e3bdce',
    'identification_number': 106382373,
    'item_consumption_1': 64130,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8185.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ce81ad5-d098-462f-a57d-beed017a87e8',
    'identification_number': 98193210,
    'item_consumption_1': 39561,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5049.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd70e271-8369-4489-bbec-b1bba04b5065',
    'identification_number': 32727917,
    'item_consumption_1': 7143,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 911.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5318209-667a-44f0-b5f2-c8069ef54689',
    'identification_number': 923325012,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27ba8427-b752-4812-ad16-f8a3d1343661',
    'identification_number': 46171622,
    'item_consumption_1': 2138522,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 272953.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a6860c4-cf73-4755-8fd8-d07be1d99124',
    'identification_number': 103377271,
    'item_consumption_1': 1696,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 663.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3ffa8c4-9776-4b34-a1db-a8248864bf7e',
    'identification_number': 91393973,
    'item_consumption_1': 21.43,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 511.13,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ce5d34d8-c4b8-4327-9ce1-b0ddd4884607',
    'identification_number': 28259513,
    'item_consumption_1': 5.97,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 279.58,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5dd56a22-623b-4e09-90cb-e355d0588934',
    'identification_number': 53243846,
    'item_consumption_1': 2927,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1240.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd8f876e-6b06-4a23-bffe-d30b0e8610ea',
    'identification_number': 14138034,
    'item_consumption_1': 7.99,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 374.18,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'dfdf7ad8-be88-4549-9cd6-68ccdb4d7dd4',
    'identification_number': 109635914,
    'item_consumption_1': 1981,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1156.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5272ccfb-dc1c-40a2-8962-c5abeb68e755',
    'identification_number': 56874081,
    'item_consumption_1': 2087,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3157.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e591503-2997-421e-82cf-8bee5d4ab3e4',
    'identification_number': 106706632,
    'item_consumption_1': 103.51,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3010.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd93ef737-6f52-482a-98f0-2cba0955ad03',
    'identification_number': 19311311,
    'item_consumption_1': 9212,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3598.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99143d43-7ece-403c-a538-889b9cd84d57',
    'identification_number': 100846971,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9b1e29d8-8f33-46a9-bc94-94db0d1ecd60',
    'identification_number': 88748278,
    'item_consumption_1': 2164,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 842.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d5c4878-3f8f-4b88-b7d3-ceeeeba3b076',
    'identification_number': 102279683,
    'item_consumption_1': 203,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 86.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fe03776-cc2f-4613-afb6-c8736a457d89',
    'identification_number': 59355573,
    'item_consumption_1': 15.61,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 372.32,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8e9b57d5-6022-4d17-a592-ae234814c88b',
    'identification_number': 75064430,
    'item_consumption_1': 28.51,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 829.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cad493e1-1721-46da-b370-2f2f14b4e2e9',
    'identification_number': 15484483,
    'item_consumption_1': 1320,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 515.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6cd7d6ec-24f1-4daf-bfe8-712d1b4002ad',
    'identification_number': 80200389,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bdd66aca-a8a3-416e-a5b6-7a0d6674f9e4',
    'identification_number': 59863382,
    'item_consumption_1': 262,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 102.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d587148-78dc-48c1-9cbd-f033751f0a1a',
    'identification_number': 32234449,
    'item_consumption_1': 42799,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15927.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6afdf63f-424b-4fa6-b641-369e67a8a78a',
    'identification_number': 31939821,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ae3959a-d293-46f7-a9da-8a0a6ce5b851',
    'identification_number': 81037600,
    'item_consumption_1': 40620,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5184.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e36ccee2-5550-4d92-9282-6bd2dc076acd',
    'identification_number': 80787932,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f43dd0dc-ff98-4e1b-9a6d-53ba7c7539f9',
    'identification_number': 59438312,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0d31ff0-4fe9-4a2d-9234-9f7081f9efa2',
    'identification_number': 82521310,
    'item_consumption_1': "'-3.229",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 06/2023',
    'item_total_value_1': "'-1032.59",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'b138ff11-bb5f-4ac4-b7a8-2ea0125b642f',
    'identification_number': 69941459,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf66c072-b81c-4984-9cb9-4858fc67e11b',
    'identification_number': 108436128,
    'item_consumption_1': 293,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 124.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a2b79ca-7d50-4e99-aaf9-9d06f6b342bd',
    'identification_number': 53869303,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0883bcbf-d88a-485a-895a-99c08962b567',
    'identification_number': 14805162,
    'item_consumption_1': 5267,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2231.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da199794-32c9-4214-83d5-7e5030c707d6',
    'identification_number': 106043919,
    'item_consumption_1': 428,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 166.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8b5b83c-cd20-475f-8b2f-14fc2ed0fca5',
    'identification_number': 101477040,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 62.1,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '5ffd7f0d-bbd8-4ecb-a60a-2ab476e2a05b',
    'identification_number': 103006672,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54ab042d-fced-4051-9882-769d68943f13',
    'identification_number': 61583758,
    'item_consumption_1': 496,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 194.06,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9fa21e11-01bf-4106-b9d7-e8bde5f5ceff',
    'identification_number': 66592747,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45dc823e-33be-45f1-a3c2-15e594b2b659',
    'identification_number': 17810590,
    'item_consumption_1': 8538,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3621.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03560ec4-29f3-4609-aeab-8810648228c6',
    'identification_number': 106087266,
    'item_consumption_1': 23740,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8834.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6abbbdee-7344-4573-8c86-1789c777fbc3',
    'identification_number': 109022084,
    'item_consumption_1': 2143,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 837.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7b97ac5-0818-465f-be3c-b3e278737d54',
    'identification_number': 74446975,
    'item_consumption_1': 455,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 177.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '898df04e-d361-4c6c-8f3f-f6444226bd03',
    'identification_number': 40504077,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7327435c-a5a7-4fde-8a1e-625904a55916',
    'identification_number': 68021348,
    'item_consumption_1': 1456,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 619.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4a1db32-bc45-4b51-9900-987ff3446054',
    'identification_number': 60767928,
    'item_consumption_1': 56.56,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1645.08,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '568d1d94-4cb5-42c8-8333-31e5b7303cbc',
    'identification_number': 100343511,
    'item_consumption_1': 8497,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3598.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab78458e-ed8c-46df-aff9-7947d958988a',
    'identification_number': 79545793,
    'item_consumption_1': 2973,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1736.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c841c26b-ba61-459e-a66a-3a51110fbee5',
    'identification_number': 106136712,
    'item_consumption_1': 9250,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1180.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b90ff3e-8d6e-46fa-90aa-47eba7ee3624',
    'identification_number': 82743339,
    'item_consumption_1': 44545,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5685.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4216ff7f-0998-4c81-8b0a-4de35995ff5d',
    'identification_number': 84777117,
    'item_consumption_1': 14104,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5248.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d291e75-9353-4af3-b8ab-fef4b2ad2dd4',
    'identification_number': 41279263,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV.CORR. MONETARIA AJUSTE FAT.',
    'item_total_value_1': "'-1.32",
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '08e5ec8a-1b73-4a56-a28f-564a6a9986e4',
    'identification_number': 19451512,
    'item_consumption_1': 907.2,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 26386.78,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '747d757b-668f-4b22-a28c-4309c051ed23',
    'identification_number': 43814166,
    'item_consumption_1': 33.8,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 983.08,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a7472e9b-bacd-488a-af24-941a11cea942',
    'identification_number': 39961320,
    'item_consumption_1': 14.99,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 357.52,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '289c70e3-d8ce-48a9-8113-7b959e8404da',
    'identification_number': 78334640,
    'item_consumption_1': 4.49,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 512.83,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5e626c94-5c28-4979-8c28-d0af4f93d00d',
    'identification_number': 104722991,
    'item_consumption_1': 552,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 215.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a3a379f-e766-49b2-984e-c43b84ed6492',
    'identification_number': 71433449,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'JUROS S/DEV. DE AJUSTE DE FATUR.',
    'item_total_value_1': "'-31.36",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '5689404a-bd96-478c-b930-97dd914cb0f3',
    'identification_number': 68502982,
    'item_consumption_1': "'-1.448",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 08/2023',
    'item_total_value_1': "'-502.86",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '25236c49-0586-4e41-8ae4-a19a43e3312b',
    'identification_number': 32556268,
    'item_consumption_1': 6849,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2903.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06ec82eb-9aec-4062-ae57-5b680fdb3635',
    'identification_number': 85411396,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfcc20e7-5438-4729-a0b8-1483162a2eab',
    'identification_number': 109675185,
    'item_consumption_1': 257,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 150.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7d93716-36d7-4456-bb34-09e560dcf686',
    'identification_number': 94029539,
    'item_consumption_1': 21804,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8114.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3433489-6097-43c9-b905-052bf6a01bd4',
    'identification_number': 19119852,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 158.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ab82fc0d-ff66-4eaa-94f1-c1ca98b099bd',
    'identification_number': 78143977,
    'item_consumption_1': 8601,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1097.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fcbc1dd-f6d4-42bf-b270-0dde3fbbabe3',
    'identification_number': 72742917,
    'item_consumption_1': 11037,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4683.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '963d661a-c97d-4123-8ba7-f56cb7de8861',
    'identification_number': 81037600,
    'item_consumption_1': 4147,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6273.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1dc5722d-7ef3-46eb-9c47-765c9e18b237',
    'identification_number': 16000862,
    'item_consumption_1': 48,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 904.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4ed9df43-3624-4c99-a9bc-aa9da8bc620f',
    'identification_number': 48754110,
    'item_consumption_1': 10250,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4357.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33a34b4d-99c3-4372-ac8b-e45c9adbdf80',
    'identification_number': 46708154,
    'item_consumption_1': 21.5,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 625.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'af4e4eb2-20e9-4544-9d76-b944d596657b',
    'identification_number': 6270476,
    'item_consumption_1': 1476,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 577.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6a52cac-b63a-4653-bec1-282f5b076938',
    'identification_number': 110483774,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8ad32c98-b83d-4014-9ed7-1d2ca1e69ace',
    'identification_number': 67097898,
    'item_consumption_1': 13973,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5200.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69f4acf9-3bc5-4ca2-b798-33b565bf191c',
    'identification_number': 73434302,
    'item_consumption_1': 3057,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1195.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a98e9a7-c4c2-49e7-80ec-858fea6287fa',
    'identification_number': 99379422,
    'item_consumption_1': 1.08,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 25.77,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7a138d54-0702-4f48-92a7-715fee1d7106',
    'identification_number': 23555661,
    'item_consumption_1': 2739,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1066.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1796b43b-202d-46aa-9264-aa0f15cd9ded',
    'identification_number': 18182399,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd791974d-bcc6-4d81-8379-cfd1af8fe590',
    'identification_number': 32810300,
    'item_consumption_1': 4900,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 625.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d8938f2-bdbc-430d-9796-6722c49a5f2b',
    'identification_number': 8974748,
    'item_consumption_1': 498,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 211.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ba90b09-c5b7-4fc7-a225-9a60c849e97a',
    'identification_number': 12281301,
    'item_consumption_1': 955,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 725.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ff5112a-0ec5-408e-82c2-387ca9aae978',
    'identification_number': 103561048,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '269ca0ce-23d5-4bec-85ce-a23afd1a71b2',
    'identification_number': 96150211,
    'item_consumption_1': 1484,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 578.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '784a053e-d535-4d7d-993d-a717fb3e3880',
    'identification_number': 28166159,
    'item_consumption_1': 5852,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 8853.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9385d9d-ffad-4673-88b0-cb3def7dd126',
    'identification_number': 91588103,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '056cdce0-1c99-428a-8002-1386cfba845a',
    'identification_number': 70039356,
    'item_consumption_1': 2501,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1060.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9935a6a-a26f-45ba-8283-943ea94c768a',
    'identification_number': 12673650,
    'item_consumption_1': 0.21,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 5.02,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8726f4a4-7da6-4412-8ce5-cf5924565335',
    'identification_number': 94984778,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e1b946d5-de68-4098-819a-cc4e6db70278',
    'identification_number': 98113739,
    'item_consumption_1': 3320,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1293.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53ad5890-4ad5-4f12-9148-3947919de221',
    'identification_number': 47927755,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd79576a1-d760-4aa4-9c0a-d59c1fa6a15f',
    'identification_number': 81267843,
    'item_consumption_1': 23.91,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 695.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '18c2e088-737d-418d-b66f-aa69b8c19fe2',
    'identification_number': 18563023,
    'item_consumption_1': 8413,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3281.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28478669-a5ab-4835-bc81-8fd4e599a1a2',
    'identification_number': 77672925,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 268.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4ba2f248-f451-4df2-b715-55ae2e61a77c',
    'identification_number': 56995806,
    'item_consumption_1': 9931,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3695.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9605a84c-10d2-474a-a376-79aa62c13b91',
    'identification_number': 70427054,
    'item_consumption_1': 6933,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4049.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3dd8d95d-c32e-4532-bf7f-907c1f434ed7',
    'identification_number': 11236264,
    'item_consumption_1': 10298,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4359.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ddf731f-c8e3-4009-95d4-7aff97ed5c66',
    'identification_number': 101890419,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 12.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7555b767-d44c-4970-a833-76651d92096d',
    'identification_number': 37854496,
    'item_consumption_1': 2605,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1104.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a7dee0c-f4e6-4440-96f8-045e991c686f',
    'identification_number': 22672397,
    'item_consumption_1': 7767,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3036.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11195df1-8068-4307-b469-af01ad0b1101',
    'identification_number': 15686396,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6754aee1-7558-4c72-9d3e-7cb2137d6390',
    'identification_number': 101899955,
    'item_consumption_1': 1205,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 471.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '989d6124-b1d9-4398-889b-cb7e29a4311b',
    'identification_number': 14138018,
    'item_consumption_1': 11020,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1406.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1616904d-4dbd-4ba5-a3a8-b5e1f2dba103',
    'identification_number': 31997686,
    'item_consumption_1': 9228,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3600.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0cd3f1a-7967-4519-be7b-299dbf384422',
    'identification_number': 106493922,
    'item_consumption_1': 1169,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 455.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3a6b06f-f239-44a6-81e0-9988305f99a2',
    'identification_number': 22902694,
    'item_consumption_1': 9651,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1231.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '296afdfe-2640-41d9-b940-1c183029eb00',
    'identification_number': 18236189,
    'item_consumption_1': 7019,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2972.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c0b3855-7331-496e-b84a-8b0e30f8ad0f',
    'identification_number': 6941044,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fbc6df55-dfe3-4759-9352-cf8ab765c0da',
    'identification_number': 17229162,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 145.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a4893ebd-db20-49b5-ab6c-281b20a3f9d6',
    'identification_number': 106473930,
    'item_consumption_1': 3833,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1493.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60d53091-fc2c-4455-b596-14739a8bbac7',
    'identification_number': 78368286,
    'item_consumption_1': 3645,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1544.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1216be2-c595-4a47-99cb-8361e285ec4d',
    'identification_number': 99553465,
    'item_consumption_1': 177,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 75.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bc53cf2-e14f-424f-9088-25c225a5d3c5',
    'identification_number': 31564496,
    'item_consumption_1': 3390,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1979.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6ba6254-1659-4e2c-970c-4883eebbf326',
    'identification_number': 47899816,
    'item_consumption_1': 45.11,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1312.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '576a4ba6-d3b2-42ab-b1f1-f79ed972a267',
    'identification_number': 90439325,
    'item_consumption_1': 63.56,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1848.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b7cfefeb-5e58-41a3-b2b6-994d95907e69',
    'identification_number': 20994206,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '128b969b-b6c2-4c8d-b5ad-c9576204f039',
    'identification_number': 3032863758,
    'item_consumption_1': 3828,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 1141.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f71fdec-7a55-4674-99cf-c3b8c67ad07a',
    'identification_number': 52131718,
    'item_consumption_1': 3323,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5027.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9facee7-7a92-4a5f-8dae-7173b2e218ae',
    'identification_number': 105763101,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b48d612-4cff-4ad2-8bf6-b88c257af272',
    'identification_number': 104124555,
    'item_consumption_1': 1719,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 729.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cf7d4b3-fdac-4345-840e-f569c8d61de2',
    'identification_number': 19280270,
    'item_consumption_1': 1914,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 810.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbc89d2f-1d4d-4bb9-abe7-1f09db54be52',
    'identification_number': 50783947,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '328b85b6-6847-46eb-aec9-3f863a3e33b8',
    'identification_number': 59070960,
    'item_consumption_1': 4.49,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 210.27,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'dd31074c-96e5-4313-86db-9b49c2c19911',
    'identification_number': 68447450,
    'item_consumption_1': 33.06,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1888.04,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1ff52d6a-9c64-4ebd-a32c-f870b1d54b5c',
    'identification_number': 20559755,
    'item_consumption_1': 70.15,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2040.36,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '01bd8387-4696-447b-a8ed-7dc71d1408c3',
    'identification_number': 38109280,
    'item_consumption_1': 3589,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5429.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7522cb4-2697-4e9f-a3cb-42601b02d7c9',
    'identification_number': 6037291,
    'item_consumption_1': 1654,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 701.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de9b4f12-3ecb-4679-8f2b-e89442b5664d',
    'identification_number': 107087308,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '333349a4-3e27-48c8-b183-514d23232fa3',
    'identification_number': 63896931,
    'item_consumption_1': 1538,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 898.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a244cf56-00ee-4949-9dff-c7eedf551fe5',
    'identification_number': 29966060,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '45aa23df-1121-4ed0-9a5d-d481c78cd166',
    'identification_number': 101151675,
    'item_consumption_1': 1022,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 398.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9a8cda5-c926-4ce4-9494-dd448324a82e',
    'identification_number': 16111451,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2026f330-9d2e-4e0a-973c-0f7aea67d08f',
    'identification_number': 5345227,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fca379c2-370b-4617-93b9-2e8281285c61',
    'identification_number': 101785941,
    'item_consumption_1': 2172,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 921.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '284bc08b-9ed0-46c1-889b-99bf12700b76',
    'identification_number': 36648329,
    'item_consumption_1': 42.85,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1246.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ff4a7d9d-441b-451b-9cf4-b383cec1363b',
    'identification_number': 27054535,
    'item_consumption_1': 1210,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 512.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c092d74a-2a69-4ff0-832e-11dae1e2f279',
    'identification_number': 44038380,
    'item_consumption_1': 5330,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 680.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e34a4823-4528-43ad-8584-3496a7e35efb',
    'identification_number': 76387054,
    'item_consumption_1': 2263,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 960.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '569ffe4b-612c-4804-9193-eec6ff77fd83',
    'identification_number': 57387206,
    'item_consumption_1': 10030,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1280.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c9cd47a-cca2-4ea3-a639-ed0eaea8367e',
    'identification_number': 935920818,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 72.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9bfa1363-945b-41e8-8d0b-103aac898b12',
    'identification_number': 76514587,
    'item_consumption_1': 16779,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 9799.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b1a2082-c947-4593-8558-2abb6f5c85b1',
    'identification_number': 97397652,
    'item_consumption_1': 642,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 250.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e566858-2c8e-49ee-a8f5-06dab9edd90e',
    'identification_number': 70941378,
    'item_consumption_1': 827,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 482.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '646fb250-5426-4823-a72e-4eba027d173e',
    'identification_number': 91360250,
    'item_consumption_1': 750,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 95.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '322b042f-1560-485c-889f-ee0487508a6a',
    'identification_number': 99704820,
    'item_consumption_1': 3651,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1547.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccf092c1-10ab-4d5f-a34f-1e83ca31ea7e',
    'identification_number': 9823000,
    'item_consumption_1': 0.16,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 3.83,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b7e0e2f4-2483-4db4-bb87-5e54f521a637',
    'identification_number': 98833715,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f59a1d5a-6779-41a5-b69a-877b3fe46b3b',
    'identification_number': 109139402,
    'item_consumption_1': 514,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 300.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2e1e599-8341-4d54-bccd-52764f70e0eb',
    'identification_number': 78610729,
    'item_consumption_1': 93.43,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 5335.78,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e224e7d0-6278-4723-b550-c1ee54a5dbbe',
    'identification_number': 91670942,
    'item_consumption_1': 17788,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6619.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '011e9fed-bbde-4c61-837f-cb6b138de94e',
    'identification_number': 110411196,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 3.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c78a5d60-ef89-4280-adbf-f1fc9b7b57a7',
    'identification_number': 106643517,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '41ee5a87-7498-4b46-a6de-bbc5b68172c5',
    'identification_number': 15645835,
    'item_consumption_1': 6.25,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 363.57,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c60d1ba8-68c6-4b34-b09f-4c36a4f90fc3',
    'identification_number': 43875939,
    'item_consumption_1': 36270,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13497.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a93a42a-b51a-4bcb-83b4-8f39524b8da1',
    'identification_number': 107941392,
    'item_consumption_1': 599,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 906.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fb9b4e7-2dee-4cad-a58b-8d7f58334b1b',
    'identification_number': 8529205,
    'item_consumption_1': 1938,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 247.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b75a00bc-366d-4fd4-9594-59c76b423622',
    'identification_number': 89118162,
    'item_consumption_1': 1289,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 546.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '414318eb-5daa-45cb-8589-81bbc287d5e1',
    'identification_number': 936300044,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '69e9c633-1a68-4b52-aa67-638edfd3f655',
    'identification_number': 80647588,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9c201265-0351-4286-90cc-b5727e542778',
    'identification_number': 56091680,
    'item_consumption_1': 1781,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 756.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c3528aa-f1dc-4f13-a9b0-45690a309524',
    'identification_number': 11116102,
    'item_consumption_1': 2969,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1258.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '942672b2-cf94-4ee6-90b8-37f9c790d82e',
    'identification_number': 59485841,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c24b50c-9959-464a-98be-0bfb86d2d279',
    'identification_number': 58742662,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9471c91e-9797-44c4-9e6a-e6c88ff984d6',
    'identification_number': 107627418,
    'item_consumption_1': 474,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 184.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2665e274-4218-4d53-a482-75779af6480b',
    'identification_number': 111534518,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'caf80922-ca4a-4f3b-badf-13b331fd7e32',
    'identification_number': 936153898,
    'item_consumption_1': 3944,
    'item_description_1': 'Consumo em kWh LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 3438.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17c859b4-4001-47d4-aa14-45c8e4a238c2',
    'identification_number': 105105945,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fd813524-a6ec-46ea-8dd9-33cdb8b8028d',
    'identification_number': 69492026,
    'item_consumption_1': 162644,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 20759.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '052e6bf2-5a89-4d9b-8ff6-05ee09859843',
    'identification_number': 100388396,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7eb2f5a5-fa1f-4d06-8223-50f9e533424c',
    'identification_number': 12273945,
    'item_consumption_1': 9300,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3952.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee56c9d1-e467-4ae5-8ad1-2b34e9f6abf8',
    'identification_number': 4334671,
    'item_consumption_1': 52.25,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1519.73,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd4b1a814-92cc-42b7-96c5-f01f7bb0e7a8',
    'identification_number': 102690987,
    'item_consumption_1': 121,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 51.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c62f56fa-bd8e-4a8a-9223-a5aad54567cc',
    'identification_number': 42556198,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ff733470-ba45-4927-a193-b44b0d36bbc1',
    'identification_number': 111482259,
    'item_consumption_1': 3923,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1528.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49fc7380-d3b3-46ff-9df2-6d1598967410',
    'identification_number': 94120676,
    'item_consumption_1': 6.32,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 150.75,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2d5af3fa-13b0-4755-9ddf-25cb60b32c69',
    'identification_number': 9802886,
    'item_consumption_1': 3348,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1308.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3a6a223-d446-4a35-b574-a49f9bc782f3',
    'identification_number': 77279719,
    'item_consumption_1': 1092,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 637.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89ef825c-a0d4-4af7-bf0b-4cfb820d63e5',
    'identification_number': 32098120,
    'item_consumption_1': 21.64,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 629.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3fcc4413-e58c-4ef9-9bee-b32fad5a07ea',
    'identification_number': 8543844,
    'item_consumption_1': 3682,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1440.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5bdf339-b986-4c80-8652-040429bf1c71',
    'identification_number': 32563256,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.23,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e70c24c5-5b85-43bb-881f-aaa04debb0f2',
    'identification_number': 19452306,
    'item_consumption_1': 32,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 12.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd8c9bcb4-0581-4466-aecc-572f34057264',
    'identification_number': 11110708,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2289a982-f68d-47f2-a164-ac0551fe08d0',
    'identification_number': 75063077,
    'item_consumption_1': 4362,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1623.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '341a5e34-9948-416d-af89-9699b0e6c76b',
    'identification_number': 48511978,
    'item_consumption_1': 38597,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4926.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '313e4b14-b3b6-46f4-a607-62ae45ebb391',
    'identification_number': 10521119,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 228.68,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'd9436b7c-979d-46ac-a0b6-095876b0863f',
    'identification_number': 4504925,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'addcce2f-a33f-468b-b147-efa17fd68f02',
    'identification_number': 81500513,
    'item_consumption_1': 36.28,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1055.21,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'eed59edc-1d4b-4540-a41a-405603d9bda2',
    'identification_number': 67627641,
    'item_consumption_1': 304,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 118.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5954d93a-c2be-4580-932b-ad8af11489de',
    'identification_number': 96749024,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '427cc82d-d694-4bc1-bccc-8bdcbe182868',
    'identification_number': 96279257,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0f8de3c7-ba2d-4604-850e-45de6aee2dea',
    'identification_number': 15576388,
    'item_consumption_1': 4311,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1680.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b4c7031-a363-4a54-964e-2bf3be735232',
    'identification_number': 39899403,
    'item_consumption_1': 703,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 274.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee3eed76-619d-4c82-976b-1b68ab62d2cb',
    'identification_number': 109018451,
    'item_consumption_1': 1234,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 720.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cfc1775-74f6-4eae-8f5d-66a76b3ff3d9',
    'identification_number': 18229077,
    'item_consumption_1': 88.85,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 5074.2,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3d00b838-09ba-4afa-a38e-7c36bc150e8d',
    'identification_number': 95632867,
    'item_consumption_1': 24.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 711.71,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c4d17eac-99be-4399-8061-5299d2a15f35',
    'identification_number': 19306628,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a3ce6d2-9be7-43ec-9a8b-04658d0877a5',
    'identification_number': 111671965,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1ebad8d-1fc8-45dd-959d-a53f42cb24cd',
    'identification_number': 42002591,
    'item_consumption_1': 437,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 185.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b6a6408-30a2-4df4-ab0a-4c896139cc31',
    'identification_number': 108332438,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9f89d2d2-ea6d-4eff-b32b-717db411b610',
    'identification_number': 51169530,
    'item_consumption_1': 4.324,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 1596.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44b80880-6f89-4b8a-b6c8-7cc515d23d3a',
    'identification_number': 12381519,
    'item_consumption_1': 8030,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2988.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0645fbd1-9f2a-43ea-b124-ca7b35da8952',
    'identification_number': 79663036,
    'item_consumption_1': 94.46,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2747.45,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5666bb3a-4256-47e9-9ad1-6c8849b0362f',
    'identification_number': 97425907,
    'item_consumption_1': 1906,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 709.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04c9d775-fd98-46f7-a15f-ad4acf94cf1e',
    'identification_number': 29970474,
    'item_consumption_1': 407,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 158.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3bb7946-b87a-434b-b1fc-5e361fd49521',
    'identification_number': 70000050,
    'item_consumption_1': 30176,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11757.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26b4db19-4fd7-48a7-b027-71d3478a5eed',
    'identification_number': 34438050,
    'item_consumption_1': 71,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 27.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '327a6bc2-3866-41f6-ba56-dd0ff5055263',
    'identification_number': 97072494,
    'item_consumption_1': 655,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 382.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51511ee2-56ed-4a73-8afc-f19edd3d99b3',
    'identification_number': 39335283,
    'item_consumption_1': 4235,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1654.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33e7a268-769c-484c-9fe5-9ec27e27989a',
    'identification_number': 109487826,
    'item_consumption_1': 1333,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 564.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3bb1f42-74c7-4846-a3b6-b48a376930b7',
    'identification_number': 55581439,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 60.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a53651de-eb0a-4fec-ab76-177b38f7ebc3',
    'identification_number': 948748172,
    'item_consumption_1': 53,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 46.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '456cd6e5-ff3d-4338-b6ba-9d01735d459e',
    'identification_number': 20559755,
    'item_consumption_1': 4.85,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 115.68,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '188ede48-9b09-43aa-bc8f-061d8b6850ee',
    'identification_number': 34216170,
    'item_consumption_1': 2375,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 927.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b7d483c-3f91-418b-b4f2-00b9dfcf01e6',
    'identification_number': 8453624,
    'item_consumption_1': 1114,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 434.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f779876-31a6-41d9-b7c3-ab006b277eb9',
    'identification_number': 25823604,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8edfd1f0-3813-41fb-9b64-7b7afce88bf9',
    'identification_number': 27904660,
    'item_consumption_1': 21502,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2744.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb496ebe-78ee-42bf-bbb2-e2d1cc53f0ce',
    'identification_number': 67329942,
    'item_consumption_1': 1760,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 747.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50b170cb-924b-4da4-a2dd-b781a902f261',
    'identification_number': 110411846,
    'item_consumption_1': 285,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9a5d7fb-11d6-4941-b9a6-3488460ea903',
    'identification_number': 59541288,
    'item_consumption_1': 571,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 72.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '829186a1-92bc-467b-a225-1787481acc9f',
    'identification_number': 27143805,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cce4b7db-0fac-494d-a2de-8b7f6d98eeea',
    'identification_number': 15800083,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3bcd6ab0-d39b-4bfc-81f8-8cf8545c69ee',
    'identification_number': 17602114,
    'item_consumption_1': 12854,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5007,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37920299-f7d4-41f0-82cf-f9882d141cc8',
    'identification_number': 58745785,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1216b94d-dbb3-4377-8d52-1a85398d7949',
    'identification_number': 103748601,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd7620324-5ec0-4f8e-b25b-dfcdf31fab56',
    'identification_number': 19288042,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9515e8c7-f49e-43e0-a2d6-5f850217fbe2',
    'identification_number': 18339603,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 16.82,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4427bab0-1c57-48fb-9712-80a67fb2df9c',
    'identification_number': 15871789,
    'item_consumption_1': 6777,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2522.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78fc6606-4751-4ae6-aa0d-1b6468777b90',
    'identification_number': 108331024,
    'item_consumption_1': 65.53,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 1562.94,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2de9a7b8-7d1b-45de-9295-200cfb08ad8b',
    'identification_number': 111452465,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb14ef68-8c9b-4605-ad3d-be4c2cf33b6e',
    'identification_number': 27895602,
    'item_consumption_1': 3362,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5086.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6e42522-8440-4e77-977e-735c7ad53194',
    'identification_number': 18641547,
    'item_consumption_1': 252,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 98.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e18e563-cbee-4510-b8dc-928e7553e67f',
    'identification_number': 94460566,
    'item_consumption_1': 18782,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6989.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b1feba4-7b56-4a99-a135-69dae62d15d2',
    'identification_number': 33651868,
    'item_consumption_1': "'-9.774",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-3127.92",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '80935617-f30e-472f-aace-e703b2428ab7',
    'identification_number': 98598945,
    'item_consumption_1': 105.95,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 6163.3,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f3f47490-6c67-4557-b43d-9f1ed14f3e4f',
    'identification_number': 62191780,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 7524.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd8cd124c-3859-4c77-9bc4-c8158bbb3b80',
    'identification_number': 9206787,
    'item_consumption_1': 9654,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4086.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '897a1786-47ef-4386-8f58-d3a95c1aa192',
    'identification_number': 19450133,
    'item_consumption_1': 232872,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 29722.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c5da14b-424f-4a10-9f37-8739ff01229f',
    'identification_number': 13884140,
    'item_consumption_1': 3670,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1365.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e5e60c1-235a-4b5d-b70a-39cfbadc13ed',
    'identification_number': 103337300,
    'item_consumption_1': 17244,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2200.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b7e9064-63d7-484f-862e-f5a359a20355',
    'identification_number': 17970806,
    'item_consumption_1': 12.27,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 292.65,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e7bc9952-54d3-4a7e-9dff-a6c97e1eee53',
    'identification_number': 15224287,
    'item_consumption_1': 1053,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 447.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb095c6e-bba1-440d-ac80-10638c30381c',
    'identification_number': 5980798,
    'item_consumption_1': 1572,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 666.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f3d3b6b-5869-44e1-aa60-e47a77c61bec',
    'identification_number': 39466655,
    'item_consumption_1': 5740,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2432.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5dac24c-f513-45aa-a6c6-60f916365088',
    'identification_number': 39965678,
    'item_consumption_1': 786,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 333.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '753aac7a-96c6-4852-9250-c0f92e2ff212',
    'identification_number': 33355215,
    'item_consumption_1': 0.21,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 5.01,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b3a3d320-c70d-4480-8edd-e285b8ebeb17',
    'identification_number': 28266790,
    'item_consumption_1': 5270,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2060.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d6db390-ecd4-49b3-935f-3097ae3b83eb',
    'identification_number': 35183543,
    'item_consumption_1': 24663,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9178.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78aa81e2-00da-406f-ac19-2f0d6849d662',
    'identification_number': 14562553,
    'item_consumption_1': 241,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 140.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4342c87c-beed-4a09-b8d0-3830a36413f6',
    'identification_number': 102593540,
    'item_consumption_1': 2531,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 989.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcb5655a-a75d-44ac-ad3a-a440ae25a433',
    'identification_number': 109022084,
    'item_consumption_1': 2143,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 909.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b52ef989-a57e-443e-a60a-e819011a2c64',
    'identification_number': 33688818,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '97b6dc2b-ad44-4ff3-aa5a-7b0afc2ada6d',
    'identification_number': 19450753,
    'item_consumption_1': 70295,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 106343.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b12215de-4bef-4f6b-bf8a-08c788f9be95',
    'identification_number': 102036500,
    'item_consumption_1': 56.53,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1644.21,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ae827f1f-96a4-48f2-82c4-7875c591f450',
    'identification_number': 29966060,
    'item_consumption_1': 608,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 355.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7cadad6-0cab-4b2a-8f3b-2edda51a5455',
    'identification_number': 42709717,
    'item_consumption_1': 12948,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4818.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3cd01c79-3da0-4f2d-a959-7202b5650b6c',
    'identification_number': 35825448,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e599904b-6109-4d10-b687-1a5a31a621a3',
    'identification_number': 99540355,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a7675075-e3ff-4af3-a3fc-e12e2a909619',
    'identification_number': 105799653,
    'item_consumption_1': 13825,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1764.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a368993a-90de-47d4-ab6b-ba9d5ae828be',
    'identification_number': 105611271,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5dd62487-a149-440f-aa7a-fd6f2213dc30',
    'identification_number': 17216168,
    'item_consumption_1': 11669,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4948.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9191749-96df-4730-adba-270b5d9dad8b',
    'identification_number': 37763989,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65539450-1b92-4e8a-b9ad-0074cd446050',
    'identification_number': 87747570,
    'item_consumption_1': 1695,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 719.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce490ca0-9908-4a27-bb01-f0a03b811f3e',
    'identification_number': 16570936,
    'item_consumption_1': 29056,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3708.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a204160-ad25-4de0-bf03-18f69a367079',
    'identification_number': 45094519,
    'item_consumption_1': 1133,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 443.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c307c936-3c4a-423a-b04a-01e71fa2cb89',
    'identification_number': 79701531,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05b4cea3-3ceb-4509-b5b8-24dfd4b07c3c',
    'identification_number': 74915355,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c72dfd5-97e3-454b-b888-a694753c5647',
    'identification_number': 18398499,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2df84499-642c-4413-97ac-4128195c9212',
    'identification_number': 103438157,
    'item_consumption_1': 13445,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1716.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c4f42e7-309f-499a-8270-290034441add',
    'identification_number': 105611271,
    'item_consumption_1': 54.93,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1597.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd66eef5f-f991-41a9-abf0-c9f715e988b6',
    'identification_number': 10947604,
    'item_consumption_1': 1118,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 474.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdf96a28-50f2-41d5-85e1-5eb1f390096c',
    'identification_number': 58734899,
    'item_consumption_1': 56,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 21.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8b684c30-b638-498b-8b54-71c1cbc4c4ab',
    'identification_number': 8661758,
    'item_consumption_1': 34,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 13.29,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '43f844f8-bf09-40bb-bb12-9aba316b2cbd',
    'identification_number': 13028855,
    'item_consumption_1': 4.06,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 190.15,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5e43e0f4-e3b1-424e-b6a9-318f714f69e5',
    'identification_number': 74915355,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f43a75c4-78de-4680-9c6c-fe236e196e45',
    'identification_number': 69617244,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 462.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6d344ec1-3cfb-49ba-ad2a-995c0f2d99dc',
    'identification_number': 92674488,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '50d761a9-fbe1-4aef-8318-9be1c77c4950',
    'identification_number': 72307706,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 117.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae74425a-3c56-4016-a07b-263dbfa02550',
    'identification_number': 18138373,
    'item_consumption_1': 17591,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2245.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce71beb0-30dc-4710-ad5c-6e89ce6925e1',
    'identification_number': 31639267,
    'item_consumption_1': 42.01,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1221.89,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd39c0d25-48a0-4be5-a436-f50d5bd8256b',
    'identification_number': 27577244,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-210.45",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'd5ea37ba-c82d-411c-8fd8-051b768168fd',
    'identification_number': 97941778,
    'item_consumption_1': 5828,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2473.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8010b76-cc99-4231-9223-7aefcbd01944',
    'identification_number': 96491353,
    'item_consumption_1': 5095,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2162.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '845278e7-add8-4c00-a5bb-3a48932afbff',
    'identification_number': 21185123,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c84dd3d-c839-4132-9cb0-d8c9e071810a',
    'identification_number': 103617515,
    'item_consumption_1': 5233,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 667.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '923449a1-3c94-4abf-9588-7ad89108d1de',
    'identification_number': 94623694,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08821da3-2a6b-4946-b202-e9a1e92788bc',
    'identification_number': 94984921,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '48f95a35-238b-4187-ad79-c5b40456d5a8',
    'identification_number': 83865349,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fafda4b2-91f3-4492-827f-15785c36d8ab',
    'identification_number': 93511388,
    'item_consumption_1': 10734,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3994.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb50e977-4d3f-4bb2-a9b3-3e6dcbafca29',
    'identification_number': 6941044,
    'item_consumption_1': 1155,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 490.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1561b189-56f3-468f-a4c0-f4a5d667f097',
    'identification_number': 42223750,
    'item_consumption_1': 62.4,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1488.28,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '147fbca8-31b0-4fb6-8e3f-4901d5bfb716',
    'identification_number': 36111260,
    'item_consumption_1': 501,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 195.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7006f485-556a-4313-9a58-66138a4c3805',
    'identification_number': 46758690,
    'item_consumption_1': 479,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 187.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '401b8942-4c46-4afc-b1c9-d334815355dc',
    'identification_number': 35718064,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-256.23",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '2563b49d-724a-45a0-ba06-71b5a9b7c90f',
    'identification_number': 98041371,
    'item_consumption_1': 26.02,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 756.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5ce97c68-cb94-429e-866a-5b794676d20b',
    'identification_number': 102653194,
    'item_consumption_1': 479,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 187.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a00a930b-e1c9-4581-ab95-92df279c7c57',
    'identification_number': 105699047,
    'item_consumption_1': 3438,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2007.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb40692c-8f43-4cb1-924c-b28cd060dbff',
    'identification_number': 36149730,
    'item_consumption_1': 1811,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2739.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f5d8ca9-4c6b-4c5e-a412-749ee3e690ed',
    'identification_number': 110483456,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 112.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40042e8a-6436-41b7-8408-0654ee73c205',
    'identification_number': 86398652,
    'item_consumption_1': 4032,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2354.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2595c915-7d0f-4b36-b583-a7f0d895da0d',
    'identification_number': 43875718,
    'item_consumption_1': 6277,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 9495.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc4c2486-7bef-4d47-885e-42685e03708e',
    'identification_number': 102676208,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96ecb6ca-b259-40d4-bfca-ae44f5015b78',
    'identification_number': 93830130,
    'item_consumption_1': 1069,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 452.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63b5f0fa-c649-42e5-a724-8d00dee8878e',
    'identification_number': 90934377,
    'item_consumption_1': 1308,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 511.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86386cb2-8d04-408e-89c1-bba3fbf5eeaf',
    'identification_number': 17394244,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c79ba7c8-fb7b-4f64-b1ee-9afa75526f22',
    'identification_number': 5330939,
    'item_consumption_1': 12452,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 18837.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05da6f84-ee83-4de9-8f5d-6d14885d7dd4',
    'identification_number': 48367583,
    'item_consumption_1': 1783,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1041.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e582aa65-b6ec-4e54-8cba-28953d8794af',
    'identification_number': 40450554,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-5.46",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '3e2e0b3b-28b9-4447-b596-4d578f6b2f78',
    'identification_number': 93763395,
    'item_consumption_1': 2566,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3881.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1f549ed-4599-4e30-9e07-52a1b9b77340',
    'identification_number': 83645640,
    'item_consumption_1': 807,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 342.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4f8ffe8-831d-4e78-8256-d0b26fc9f53f',
    'identification_number': 78610729,
    'item_consumption_1': 93.67,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2724.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9b201c11-0f3a-4e27-b330-e6a4a5360c7f',
    'identification_number': 42710529,
    'item_consumption_1': 0.22,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 6.39,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '0824198c-33a0-49c1-a36c-9543f189bc12',
    'identification_number': 17073030,
    'item_consumption_1': 5.01,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 119.5,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a560c903-693a-4ba2-b70d-2229b58f78b7',
    'identification_number': 33676208,
    'item_consumption_1': 1.57,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 37.47,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5143d7bf-a31b-409b-9019-779f57e92f29',
    'identification_number': 31216595,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86d64927-1864-4266-9c91-4e91d33eaf4a',
    'identification_number': 105683833,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b8bc2c9c-9bce-4222-b66d-c84fc67324f2',
    'identification_number': 13977873,
    'item_consumption_1': 11394,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4240.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23c7c42e-aa68-4035-9648-4aababc91d66',
    'identification_number': 64871274,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 75.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dfde7a37-e877-40c2-a26b-0c00da0b92b3',
    'identification_number': 95259520,
    'item_consumption_1': 891,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 378.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29386d72-5f15-4037-aee9-2afa84c292ff',
    'identification_number': 95609679,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1752e9b6-af82-4c3d-8215-f05d0bcdfdce',
    'identification_number': 94984921,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd65b1a30-5a9d-458e-8bd7-1d8279215aa0',
    'identification_number': 100627188,
    'item_consumption_1': 470,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 183.89,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd7533c1a-1838-4975-a9cd-090b3434bd30',
    'identification_number': 45789061,
    'item_consumption_1': 1883,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 798.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '905f10cb-6088-4964-8cb2-544746568e96',
    'identification_number': 43814697,
    'item_consumption_1': 10.78,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 257.13,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5f782991-8088-413c-be18-14e1eafea364',
    'identification_number': 59863382,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b9b2a422-81f6-41fb-a3aa-6084bd1af2b9',
    'identification_number': 98127373,
    'item_consumption_1': 2693,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1051.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '383bb70e-0248-4e36-ac46-7e7b4e4d6239',
    'identification_number': 107941589,
    'item_consumption_1': 104.2,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3030.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6069732f-37ae-4372-9e17-40f754f59e68',
    'identification_number': 32503784,
    'item_consumption_1': 4668,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2726.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd60197ba-e2ca-4030-9a9c-0d745c193b8c',
    'identification_number': 94603995,
    'item_consumption_1': 4073,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1731.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e484eb6-98ed-4dc6-ab14-7e0531242114',
    'identification_number': 73434302,
    'item_consumption_1': 3057,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1299.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6310a039-0730-4364-b48d-2d4350e1786e',
    'identification_number': 100240682,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 91.93,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd1402046-c1bb-4821-bd7a-be99f8f702e3',
    'identification_number': 27112101,
    'item_consumption_1': 44.96,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2567.64,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ff98418a-a5f9-41c5-a7f0-ddfec2b6a585',
    'identification_number': 45218471,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c50cfa5-c4a7-4894-85a3-339d701b5ff9',
    'identification_number': 100612075,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '524022fe-02a5-4b20-90c2-a9d1e6410b9e',
    'identification_number': 77826191,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.54,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '129e0327-2225-474c-84d4-8bfd54556b84',
    'identification_number': 63897148,
    'item_consumption_1': 15.07,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 438.31,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4bb7c93e-67f2-4f18-a870-bb05cc797050',
    'identification_number': 52512576,
    'item_consumption_1': 1226,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 520.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1fbe1ff-1dbe-441a-b01f-adcd2662a7ff',
    'identification_number': 35809329,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32b97d61-dc8a-4c52-9034-8daee7cb3e98',
    'identification_number': 59484381,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ee94265-5aa7-4d88-85e5-4f3dab847143',
    'identification_number': 48018660,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef24aa0d-5381-4882-b223-9533bece66cf',
    'identification_number': 57109346,
    'item_consumption_1': 27.35,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 652.32,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'bdace321-6d58-4d5a-9ee1-e119e4b0a759',
    'identification_number': 45138869,
    'item_consumption_1': 2256,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 956.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9703ad9-4648-4c83-addc-16089d3f806d',
    'identification_number': 106621360,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f10dfd0c-b287-428b-ba0c-0a4782aa05a0',
    'identification_number': 9741925,
    'item_consumption_1': 4039,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1709.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1709d571-b0a1-4122-b71d-d9ff597ff4ae',
    'identification_number': 70081760,
    'item_consumption_1': 7318,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2857.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f422356-3fd4-492d-a53c-8e332ea3f99d',
    'identification_number': 19541627,
    'item_consumption_1': 52553,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6707.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0b577fb-109a-4285-ae42-3a2001cdd664',
    'identification_number': 11467185,
    'item_consumption_1': 5609,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2191.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f9e4058-a977-4c3d-96b4-4e04d2269105',
    'identification_number': 8115656,
    'item_consumption_1': 230,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 97.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c46e8d20-a0ff-43a3-8db7-c2b03af93537',
    'identification_number': 28168615,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fea2b2f0-724a-4dc0-906a-b7e7338c81e4',
    'identification_number': 45017336,
    'item_consumption_1': 363.74,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 10579.71,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dab3088a-ba4b-44a6-bc58-611af16d5c7a',
    'identification_number': 48999644,
    'item_consumption_1': 3442,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1341.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d297184-ddad-49f3-9373-263e8fccaa37',
    'identification_number': 103771131,
    'item_consumption_1': 1741,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 680.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3992e54e-3fa5-4e26-b00d-f212a69b504b',
    'identification_number': 40017826,
    'item_consumption_1': 16279,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6891.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01f665ba-b0cf-4c48-8697-db2b791e7e7e',
    'identification_number': 59144017,
    'item_consumption_1': 2309,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3493.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de9cdc0c-d812-4600-a00d-03460ee57d95',
    'identification_number': 18138373,
    'item_consumption_1': 1962,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1145.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c03e99c-8450-4e4a-ac66-cad657ad5287',
    'identification_number': 111926106,
    'item_consumption_1': 471,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 199.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5c757d7-78da-4e13-93e4-f8799e2f7a56',
    'identification_number': 15073114,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '437b17af-4e1b-4919-b25d-74c7c7b71015',
    'identification_number': 49297902,
    'item_consumption_1': 869,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 339.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84fea9b3-49d2-45c2-8850-9f51dc4c1115',
    'identification_number': 17668956,
    'item_consumption_1': 3386,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1318.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59f096ce-330f-4baa-83da-27079b833b5b',
    'identification_number': 68630999,
    'item_consumption_1': 432,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 168.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c395134f-c3d8-4482-bdef-00583241b943',
    'identification_number': 16961757,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 164.84,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '992fdde5-a5e8-4ef6-82cb-6f795e22b58f',
    'identification_number': 27207692,
    'item_consumption_1': 4522,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1762.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e7c4610-7415-4d7b-8fcb-2b6523790624',
    'identification_number': 91075700,
    'item_consumption_1': 2.69,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 64.17,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '128135c7-366a-4d17-b987-c0b35992fdbc',
    'identification_number': 64554481,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 83.83,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'cc8a8cc9-1069-4811-b0d7-7e01260e9e2f',
    'identification_number': 107429535,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 5.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4891c0cd-d08a-4676-94c3-dcdccc987b33',
    'identification_number': 97819298,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '817e48e3-3e7e-4d0d-90b2-e6a1c5b3592e',
    'identification_number': 8664668,
    'item_consumption_1': 1474,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 625.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98d4cbe5-e58d-48fe-87de-e8b040c8e7fc',
    'identification_number': 109260473,
    'item_consumption_1': 951,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 371.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4eb463f-f3de-4c97-b992-f723ec0a64ba',
    'identification_number': 16117450,
    'item_consumption_1': 9122,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3865.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c35e3c0-e540-46f2-a636-1ebfa3ae8be6',
    'identification_number': 31686729,
    'item_consumption_1': 2.6,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 121.77,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e727d670-6776-408a-b352-426bb708f9f6',
    'identification_number': 110653726,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0cbbf2fe-e094-4cb0-897b-7f245873fe25',
    'identification_number': 43061842,
    'item_consumption_1': 40.44,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2309.51,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '6fb9fb70-50ae-447f-8b62-9653e05b96df',
    'identification_number': 94546592,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c02283f-e7b4-4488-9533-ea965800c056',
    'identification_number': 107941392,
    'item_consumption_1': 3988,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1484.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fba7391-f5d6-4d77-ab32-62c71a237189',
    'identification_number': 110655109,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '172ce5e5-2cd7-4cdc-aa40-91b5567bbb70',
    'identification_number': 100846971,
    'item_consumption_1': 1590,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 621.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '164cabad-7bb3-4801-8a71-b1ef7b52f31e',
    'identification_number': 41213947,
    'item_consumption_1': 6130,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 9273.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e9a4887-224c-40db-a093-f29cb23be5c4',
    'identification_number': 59541288,
    'item_consumption_1': 5383,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 687.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a9aef0a-0ee0-4fd7-bbb5-9c76392cb7c5',
    'identification_number': 10105387,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5e56952c-724a-4e01-bcc8-c9d19f9a3a27',
    'identification_number': 78084237,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1765.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '126e2586-34bc-4ced-976b-70b0ce48df61',
    'identification_number': 45602387,
    'item_consumption_1': 22.26,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 647.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '36b8f34b-3cd1-47bd-b257-16d696857fc8',
    'identification_number': 91487978,
    'item_consumption_1': 977,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 382.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b361d8a-fb33-49ae-98cd-01c7ef560f5f',
    'identification_number': 4334221,
    'item_consumption_1': 206270,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 26327.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '189d3d4b-d0e9-41ca-a42a-7694fe7990ec',
    'identification_number': 78757894,
    'item_consumption_1': 2616,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1109.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cba484c3-ff38-4180-8f7a-9a8178298881',
    'identification_number': 30085918,
    'item_consumption_1': 33.01,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1885.19,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5c3645d1-d132-4d76-b5ec-62295aec344d',
    'identification_number': 56708890,
    'item_consumption_1': 14404,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5360.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d067794-157e-4c34-952e-c63373e86f5d',
    'identification_number': 49297902,
    'item_consumption_1': 869,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 369.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fff78d5-6296-445c-b9f0-b405a066e17b',
    'identification_number': 40636399,
    'item_consumption_1': 2.33,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 55.58,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e19a1866-598e-4b86-9ad2-962e4f959c97',
    'identification_number': 61588555,
    'item_consumption_1': 42981,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15995.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b365130e-c1bf-4629-9f53-6b2bb4382f8c',
    'identification_number': 98165895,
    'item_consumption_1': 888,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 346.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f8e8137-c745-434b-8870-439aeaf7e85a',
    'identification_number': 32503784,
    'item_consumption_1': 55056,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7027.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b8f8426-5b20-42c7-9f66-385b6df2f4ca',
    'identification_number': 11680148,
    'item_consumption_1': 1.25,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 29.83,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3f47f064-c662-4962-a740-0588aa804b8f',
    'identification_number': 79893112,
    'item_consumption_1': 1302,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 509.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d737047-a824-488f-923c-10c5b89ac7ef',
    'identification_number': 92605788,
    'item_consumption_1': 3792,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1479.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '339a451c-8291-433a-9b22-086bc7e11730',
    'identification_number': 55316883,
    'item_consumption_1': 17973,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2293.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c76a75e5-0072-421c-a6f3-712bd266a20b',
    'identification_number': 100228500,
    'item_consumption_1': 345,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 146.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e74d2599-9bc5-4c09-be1f-f96b0699a76a',
    'identification_number': 17141168,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8d148534-2b45-4af4-a1af-d4b4bf3c441f',
    'identification_number': 14562545,
    'item_consumption_1': 1038,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 606.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bc8945f-eda6-44c4-8b62-4e0de1be56c0',
    'identification_number': 97464333,
    'item_consumption_1': 1752,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 744.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96959f2b-b59e-4f2c-b507-d6daddfe1c37',
    'identification_number': 58737863,
    'item_consumption_1': 42.01,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1221.89,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a17a413d-4403-453e-b2c8-03034162bfe4',
    'identification_number': 30132860,
    'item_consumption_1': 7667,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2996.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '261ddc0a-ecfc-4757-bdc2-3e68fe636639',
    'identification_number': 109203771,
    'item_consumption_1': 139,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 54.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a35c0105-c445-4177-a56d-994d2916cf85',
    'identification_number': 47573481,
    'item_consumption_1': 64.5,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1876.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '72161e1d-05c4-4fec-8fa9-da83670ec2f3',
    'identification_number': 13010476,
    'item_consumption_1': 10.82,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 506.71,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a78d1f30-003f-4a02-99c9-0860863da1c0',
    'identification_number': 60720514,
    'item_consumption_1': 71.34,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4074.21,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '38ec86d5-fa59-4372-a946-c7ac6640df11',
    'identification_number': 16125312,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b19884cf-c62a-4ca8-b617-7e8acd700383',
    'identification_number': 77890043,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 109.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4be9e981-3b30-4fac-a916-56cc583fa0c1',
    'identification_number': 10895132,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 95.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fdb96a95-46c6-45d9-a945-0ea6717abd1e',
    'identification_number': 105684228,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '88e91796-15f1-483d-ad10-12c2562af04c',
    'identification_number': 59507853,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5bcd1cad-9cf6-4c33-b4c5-f1b8928ec899',
    'identification_number': 102622639,
    'item_consumption_1': "'-14.187",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 08/2023',
    'item_total_value_1': "'-4534.17",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'bccab676-1590-4c1c-815a-c2cf9dc583d3',
    'identification_number': 93627246,
    'item_consumption_1': 2703,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1055.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd85c9c71-ea1c-42ef-8350-63be14e5bb8d',
    'identification_number': 15800083,
    'item_consumption_1': 10800,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4206.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2db72ba-8f5b-486c-9a31-0a19834e2769',
    'identification_number': 40207285,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ccd5f294-0ff8-4ca2-b937-3480daf374c5',
    'identification_number': 13220462,
    'item_consumption_1': 642,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 272.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b59e535d-e6cc-4e3a-9ac8-eb05d4da4517',
    'identification_number': 35479833,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '792cf956-4b1b-405a-b6e5-de0017e1c13f',
    'identification_number': 20534310,
    'item_consumption_1': 1303,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 551.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '457fd3e6-86dc-4ec0-988d-45a071210624',
    'identification_number': 19280270,
    'item_consumption_1': 1914,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 745.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50f5edb7-8471-4db8-bc83-c36ccf2b842c',
    'identification_number': 57723354,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f80fad3-0c20-4aa3-85cc-9bb7263e4037',
    'identification_number': 42694310,
    'item_consumption_1': 145.68,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4237.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bb1c3e10-48cc-456e-9a5c-b80a9fdf442e',
    'identification_number': 40032060,
    'item_consumption_1': 5790,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 739.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c57ca3d-c1cd-4df7-aca2-5d57200f2a0e',
    'identification_number': 46699236,
    'item_consumption_1': 1278,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 746.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51a9d3b4-65b3-472d-8764-9c61c87d3cc7',
    'identification_number': 103285369,
    'item_consumption_1': 7691,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2997.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f1cb388-0da9-438b-a1e1-71bf3db44f1d',
    'identification_number': 105313076,
    'item_consumption_1': 1252,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 488.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80e706a5-f0a1-4174-ba16-dcb1a0a8c1fb',
    'identification_number': 99530600,
    'item_consumption_1': 2318,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 983.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac501aef-160f-4672-acf9-66f492be1645',
    'identification_number': 31849733,
    'item_consumption_1': 822,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 349.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a72937d3-9430-440a-8c90-a653b19f6155',
    'identification_number': 15966798,
    'item_consumption_1': 2145,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 908.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd44dab90-0c86-424d-a728-3460a9a91bbb',
    'identification_number': 13789414,
    'item_consumption_1': 113,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 65.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '949cb024-0aa3-47e6-accf-776b25cb82e6',
    'identification_number': 109181093,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0bd806a0-2a96-450c-a402-cfe00142ccda',
    'identification_number': 45603391,
    'item_consumption_1': 6.49,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 154.8,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '92866c9b-6106-429e-8700-bd89800ad220',
    'identification_number': 79665306,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '375ca4b1-785a-4860-a4ad-392046bcee3e',
    'identification_number': 19306580,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3dcd0228-cb1d-491a-aabf-b9ea3d37332c',
    'identification_number': 107466732,
    'item_consumption_1': 7518,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2797.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5fc9f2e-cae9-4a8c-80bb-a5f72fdb9cdd',
    'identification_number': 100137890,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4fba704d-dcf1-4b18-843b-b673b7ef2125',
    'identification_number': 101937792,
    'item_consumption_1': 1413,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2137.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee30e600-11d8-4eaf-bef9-7547ecd87f0c',
    'identification_number': 19310609,
    'item_consumption_1': 619,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 241.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6bd0174-38d9-454b-b7f5-1342bcb01c7c',
    'identification_number': 40207650,
    'item_consumption_1': 97510,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 36288.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19eaef99-cf0b-4800-b9d6-56ac7ac0d45f',
    'identification_number': 93009470,
    'item_consumption_1': 16.87,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 402.38,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '30dd726c-ff6b-4771-95c2-379d24e2047a',
    'identification_number': 99344173,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'baafc005-c371-4bba-85eb-e518c0406bb5',
    'identification_number': 7347430,
    'item_consumption_1': 3511,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1490.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e644de73-61b1-4b39-9e7f-b00a869adda6',
    'identification_number': 37705342,
    'item_consumption_1': 1948,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 826.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b87c601-bf57-4073-8e7b-9769897ce767',
    'identification_number': 15092909,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38f4f92d-2731-4be5-ae71-bcbcb0ae41f0',
    'identification_number': 62056727,
    'item_consumption_1': 106,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 41.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eef44e0a-1368-4922-b16b-3ecbd4e9ce00',
    'identification_number': 95618082,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 94.28,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '67dbffd5-c37a-451a-af04-4e7661b918cb',
    'identification_number': 6085954,
    'item_consumption_1': 16950,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7189.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04b2f467-924f-4f08-b769-aa0380653115',
    'identification_number': 20559755,
    'item_consumption_1': 13404,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4988.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c8103c5-cc49-4c82-bf08-a0dd63785401',
    'identification_number': 26987415,
    'item_consumption_1': 3.65,
    'item_description_1': 'DEMANDA ULTRAP. TUSD',
    'item_total_value_1': 170.25,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '3477ad8a-e624-4086-b7f3-c81c063d5d05',
    'identification_number': 47011980,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68a949f2-913e-4811-9333-0096d0fa8a49',
    'identification_number': 65065662,
    'item_consumption_1': 12083,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4711.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e66fa9dc-b590-4b5d-85d7-9d6c7f32b182',
    'identification_number': 30455790,
    'item_consumption_1': 2649,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1032.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89e5ee6d-28d3-480e-8e29-4d5b18b69468',
    'identification_number': 8115532,
    'item_consumption_1': 417,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 162.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7cdee510-772d-43a2-aa5e-c864fe2e0d79',
    'identification_number': 56026684,
    'item_consumption_1': 12.87,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 374.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '01299476-c646-4f6b-9e57-f9d3a58522a7',
    'identification_number': 15741036,
    'item_consumption_1': 317,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 134.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbf00aed-d8f4-4d08-bdb4-03ae119f28e4',
    'identification_number': 6941044,
    'item_consumption_1': "'-338.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-117.77",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'e9c5a2f6-0b08-4564-92a7-2cdbfa37d62f',
    'identification_number': 96338369,
    'item_consumption_1': 5905,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2303.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35d83c98-b8bb-4766-9137-9e0f40e8b48c',
    'identification_number': 17109833,
    'item_consumption_1': 6742,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2509.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81d867c3-d93a-44b1-9d8c-ce6b5b81a822',
    'identification_number': 76408922,
    'item_consumption_1': 8137,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3457.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5232230c-b787-471b-992d-f6d965e611ae',
    'identification_number': 56173806,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '06eaca91-10b9-4df1-8eef-4f8447b43444',
    'identification_number': 99050226,
    'item_consumption_1': 15216,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5933.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc74046e-9799-4720-a6d9-604bf44de8a3',
    'identification_number': 56526580,
    'item_consumption_1': 12.49,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 297.9,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '758de7ec-2208-413e-99ae-733fd7278dff',
    'identification_number': 92333141,
    'item_consumption_1': 4368,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1851.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a14c2a48-f1b0-43b6-b787-263f9804beda',
    'identification_number': 91860369,
    'item_consumption_1': 626,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 265.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d038985-d5b1-4d82-9f7a-b98486a64b5f',
    'identification_number': 55316883,
    'item_consumption_1': 2073,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 264.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2a707a1-fcd8-4814-8601-5785f2263dbc',
    'identification_number': 55316883,
    'item_consumption_1': 39.6,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1151.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0fb1a5e4-236d-4855-82e6-258d3f2131b7',
    'identification_number': 14360241,
    'item_consumption_1': 813,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 344.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa2c3477-a0c6-481d-8548-1ac13cf3a245',
    'identification_number': 105060097,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c1cb3f6e-a84e-42bf-b4ec-264990736b82',
    'identification_number': 19453299,
    'item_consumption_1': 361439,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 134508.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6236f1c7-1190-4f47-b762-37fd4810aba3',
    'identification_number': 89080637,
    'item_consumption_1': 11786,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4606.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e60058c-96a6-43ef-baec-af2c045dfb3a',
    'identification_number': 105816280,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '341c89cd-0c9b-4372-9eea-eb21f8ff4572',
    'identification_number': 50792180,
    'item_consumption_1': 29753,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3797.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f67eb937-6e71-462f-aa69-f74f1e7d4b43',
    'identification_number': 17005230,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5fb3b052-bb00-4220-af57-9c3314d1b58a',
    'identification_number': 102220824,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.93,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '390c7a55-b7e8-4a12-9372-06d0034690d3',
    'identification_number': 7284012,
    'item_consumption_1': 620,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 262.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61c22807-6478-406b-b9cd-de4f81fa129b',
    'identification_number': 13914839,
    'item_consumption_1': 3290,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1281.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93f87aed-4293-492f-9637-e9c41db5f060',
    'identification_number': 77638050,
    'item_consumption_1': 938,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 397.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9c18718-9e2a-4b4d-a764-e3645f075ac3',
    'identification_number': 60092904,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9f95f98d-598d-426f-92f2-44bf8b4f5708',
    'identification_number': 107466732,
    'item_consumption_1': 7518,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 959.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5751ce16-3419-42c6-9bcb-7e99143dc380',
    'identification_number': 24017353,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd4754c72-3cf9-4be4-9ffd-19d8ab8e5c62',
    'identification_number': 40783308,
    'item_consumption_1': 2812,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1099.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ce22efb-e300-4f0f-9fec-7f13d66d7e07',
    'identification_number': 41378687,
    'item_consumption_1': 4059,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1718.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07d716a4-752d-4b6e-8843-192299d20cd3',
    'identification_number': 14039362,
    'item_consumption_1': 2173,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1269.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34e92aba-157b-42a8-af26-824a98ba19e9',
    'identification_number': 33676208,
    'item_consumption_1': 2840,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 362.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd60c8edc-e073-4391-9f1e-d2d9b62b4cec',
    'identification_number': 13015419,
    'item_consumption_1': 1700,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 721.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5aae1284-6d76-40d0-95d0-8ee3e6fae588',
    'identification_number': 26705230,
    'item_consumption_1': 1715,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 670.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd12e284e-a297-4f1b-9a47-faec745f4dad',
    'identification_number': 44634374,
    'item_consumption_1': 21950,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2801.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '422f5f2d-2c6e-4ac7-b54f-509ee97bc84b',
    'identification_number': 47074965,
    'item_consumption_1': 18718,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 7316.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '180594bc-e450-4e33-bd8f-0ff400fb96c4',
    'identification_number': 101920652,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e5427eba-a7b3-4bd0-b1c5-0120742b2217',
    'identification_number': 17962552,
    'item_consumption_1': 2040,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 866.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd02173c7-126f-4b04-beb2-0892d3bb9a80',
    'identification_number': 23221275,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f64902e4-e89b-4367-bc05-a1f13167a7bc',
    'identification_number': 105470708,
    'item_consumption_1': 6.1,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 145.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a4177f7c-9c2d-435d-ab67-d512d656d815',
    'identification_number': 9726659,
    'item_consumption_1': 10535,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4104.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f98c232-a1f6-46ee-807f-350da2bcb815',
    'identification_number': 31792634,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '80d0d534-8134-4af7-b593-96ebd14640e2',
    'identification_number': 45431108,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98511f45-8928-4834-98e9-64f7154132e3',
    'identification_number': 111186056,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6d2ceab2-8778-41f5-8b65-c00c3b615f89',
    'identification_number': 83687114,
    'item_consumption_1': 3800,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 485.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42f74e2e-f573-4e0f-b658-899f4f3ccb4e',
    'identification_number': 22107860,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 210.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3c85cc1-169b-4307-831c-ba1a48385967',
    'identification_number': 10293540,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6d9888c1-e846-4e75-b868-8af138a9ebbc',
    'identification_number': 79992250,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6c0f55f-25d3-484c-8309-2e72fe4313e2',
    'identification_number': 97637238,
    'item_consumption_1': 41.67,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 993.85,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2ec6c6b3-78dd-45b1-8367-18af1d65a060',
    'identification_number': 11404639,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-7.63",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'ec073723-ab78-42d3-8379-7fbf33836cbd',
    'identification_number': 15101797,
    'item_consumption_1': 12138,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1549.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29db25f5-8773-4951-a2d2-604d0438d1b9',
    'identification_number': 21966230,
    'item_consumption_1': 75,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 1788.78,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8e7b5892-71db-474b-b9ee-9e12edafd246',
    'identification_number': 82500312,
    'item_consumption_1': 6952,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2943.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1dde1c5-1792-454e-81f2-ebf060086322',
    'identification_number': 8882991,
    'item_consumption_1': 2022,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 788.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8de8fce5-0c54-4505-8322-d369d9285591',
    'identification_number': 10521119,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 125.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '76b6c298-1140-467f-9dd1-2f9032aec79e',
    'identification_number': 99645599,
    'item_consumption_1': 43.23,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 2514.78,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'bd56c412-8c8b-4c08-975c-17351b8f1433',
    'identification_number': 15561054,
    'item_consumption_1': 6152,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2289.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '551354b1-af8a-4964-8892-c2ea851ba93c',
    'identification_number': 22742840,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2b8074f-ff5b-4dec-b04b-927223497cf8',
    'identification_number': 48043907,
    'item_consumption_1': 9094,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1160.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9c8a2f1-7104-4f7c-8f69-135e30b2e75c',
    'identification_number': 69941459,
    'item_consumption_1': 2152,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 914.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a43cfce7-feab-46ac-8c5a-1d0ca53dccfb',
    'identification_number': 43036830,
    'item_consumption_1': 3.89,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 182.17,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3e276e4a-6ebe-42ad-81e0-b60824bb20f1',
    'identification_number': 95043551,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7c23d68a-4e13-4bae-a2a5-3388841c760e',
    'identification_number': 79723055,
    'item_consumption_1': 373,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 158.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c15e672-c46f-4082-a9c9-fe5b70ed4d66',
    'identification_number': 103048340,
    'item_consumption_1': 2143,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 910,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c522e6b5-f13a-41fd-94bc-0b45efbbcab2',
    'identification_number': 59144017,
    'item_consumption_1': 14.1,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 336.3,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '57d9cd47-769a-48b3-9504-b4e5daaf2214',
    'identification_number': 46425152,
    'item_consumption_1': 2365,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1001.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e36ba43b-a914-4d03-9ed0-11d21f1c05b2',
    'identification_number': 101835981,
    'item_consumption_1': "'-1.054",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-337.41",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '10482e0b-c2be-443c-8cb4-1be8bea8e7a9',
    'identification_number': 60203390,
    'item_consumption_1': 1213,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 514.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95d2573f-6a8d-42f2-a1cb-82f9437fc299',
    'identification_number': 29692377,
    'item_consumption_1': 256,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 99.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c0d5e99-afac-4c4f-a227-4f029a5ccd5b',
    'identification_number': 103764755,
    'item_consumption_1': 339,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 197.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68b292e5-6645-4ad0-8c2d-9d314ef24633',
    'identification_number': 44001479,
    'item_consumption_1': 1442,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2181.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d4579c4-a489-40f6-8c04-cfb8809a9d57',
    'identification_number': 12493961,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6209cb1f-45a9-41db-98b4-a313ae480894',
    'identification_number': 120479001,
    'item_consumption_1': 1,
    'item_description_1': 'Iluminação Pública',
    'item_total_value_1': 10.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '698854a4-e960-46fd-90eb-6cffd877556d',
    'identification_number': 32153430,
    'item_consumption_1': 56.6,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1646.25,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0fc5f1c3-a2bc-455e-8da7-b8f5a88e13c5',
    'identification_number': 105186520,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f943b71b-9b8c-4314-bee3-92b06180182e',
    'identification_number': 110227760,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b974b19e-734f-49a1-b23c-b9dae2c20e54',
    'identification_number': 76376249,
    'item_consumption_1': 164.42,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 9390.02,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'de4f6ffd-db03-49fa-8f64-ec73b337e810',
    'identification_number': 35685743,
    'item_consumption_1': 90,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 38.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b601860a-544d-4802-b783-a0eff6728d11',
    'identification_number': 30071089,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 107.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '009306b6-e9dd-44d5-b8d1-71bb9db56dd5',
    'identification_number': 62508679,
    'item_consumption_1': 15.44,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 449.07,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '44fbee67-b2fd-4e41-bcd2-215213333c93',
    'identification_number': 36425532,
    'item_consumption_1': 1473,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 574.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34591d4c-ac3d-4fd5-84f4-e6cb87d12edc',
    'identification_number': 16961757,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6a5a6532-be3b-4a54-a8ed-5c0860899e5d',
    'identification_number': 12026174,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6805e701-80b9-43b0-82ba-0091cee52c2a',
    'identification_number': 101068484,
    'item_consumption_1': 20458,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7613.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27069725-ac17-4416-bb9d-e956d8f0cb2d',
    'identification_number': 12797707,
    'item_consumption_1': 21.91,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1251.26,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '54dae9e1-d54e-41bf-ae47-b7f7295bd8a7',
    'identification_number': 50128434,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1052f06d-847d-4333-af9b-20dfe9b30981',
    'identification_number': 56526580,
    'item_consumption_1': 36,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 14.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ae314570-54ad-4622-9743-d1f18622112f',
    'identification_number': 102036500,
    'item_consumption_1': 8771,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3264.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da60b8b3-4e1d-454b-9985-802b89ceb919',
    'identification_number': 105663247,
    'item_consumption_1': 26,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 39.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41e7c3bd-10f8-4a6d-85ee-5590c9ecefde',
    'identification_number': 101312660,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4e0c15fa-8f9c-4abc-b303-218d4005ab93',
    'identification_number': 59814993,
    'item_consumption_1': 4424,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1725.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fdf188b-dd6b-4e30-bd37-288f0350a8ec',
    'identification_number': 10834184,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dedfdace-6117-4eb4-9ccc-08befe3bbf6c',
    'identification_number': 23179554,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd5fb3535-6cd3-43d7-9649-82e53cc5bca4',
    'identification_number': 15084175,
    'item_consumption_1': 4797,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1870.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa1ae060-c0e1-4014-877f-f3d48f06e7fa',
    'identification_number': 49096397,
    'item_consumption_1': 4703,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 600.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be98e1b1-f55e-4473-b1aa-e81d4491dd0e',
    'identification_number': 40918637,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fe213e4f-81f8-4f12-bde3-598a4429f6c5',
    'identification_number': 98236563,
    'item_consumption_1': 178,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 75.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e91be0e-d249-4cc0-adfc-b80d5e72721c',
    'identification_number': 20934947,
    'item_consumption_1': 13162,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4898.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6069180c-459c-4109-bdb2-25893843a675',
    'identification_number': 16570936,
    'item_consumption_1': 6.1,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 285.68,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '6290a0ac-d98f-421a-89f0-127757285171',
    'identification_number': 103721835,
    'item_consumption_1': 58.74,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1708.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '167477cb-9fe7-482e-8fb8-4e5feb106f5a',
    'identification_number': 78962188,
    'item_consumption_1': 1852,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1081.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab8c2e57-d80a-497a-a1b5-289df1b82251',
    'identification_number': 15214133,
    'item_consumption_1': "'-12.786",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-4084.03",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '122117f9-6302-4ce4-ba3b-4d1e6ba0945f',
    'identification_number': 18052126,
    'item_consumption_1': 3656,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1424.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '062c836e-a10c-4f10-83e2-c3e28a9a7d19',
    'identification_number': 107049910,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1ac88551-fee9-48c0-998e-c1414f264668',
    'identification_number': 106318543,
    'item_consumption_1': 562,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 237.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '752727f8-371c-4eea-bbb5-aaf9dc76a869',
    'identification_number': 76002039,
    'item_consumption_1': 2570,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1090.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a747d57-dc32-4967-8448-12d450ce5923',
    'identification_number': 76376249,
    'item_consumption_1': 8532,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1088.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41f6a328-88c4-4007-a43d-40d0307f1ea4',
    'identification_number': 71798595,
    'item_consumption_1': 29.8,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 710.76,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b3488967-8bd0-436d-8adc-d68649e0781a',
    'identification_number': 98253336,
    'item_consumption_1': 1189,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 503.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02d12c02-0730-4fda-bdef-8ba0d0ae1e83',
    'identification_number': 110543360,
    'item_consumption_1': 79.6,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2315.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cd7a271c-a6a2-4679-bbfa-5aedc6a8c9f1',
    'identification_number': 110227999,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e88290f-3a37-40d1-87bc-f9c3a9ca6220',
    'identification_number': 14619555,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 571.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2aa5eeb8-eebb-429f-85fc-9b1899119cdb',
    'identification_number': 56414762,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 19.07,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '976da957-0639-4150-81d9-0b4e2aa378f5',
    'identification_number': 40359808,
    'item_consumption_1': 758,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 295.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42dd8b58-6944-4e5d-9529-c4838b927d44',
    'identification_number': 103876502,
    'item_consumption_1': 14887,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1900.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ec2cb2d-e127-49e1-9597-b5f3140c01b3',
    'identification_number': 66066760,
    'item_consumption_1': 555,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 216.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbcab394-d24a-4f78-957f-543f7eca00d0',
    'identification_number': 9027092,
    'item_consumption_1': 5.17,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 123.31,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8f79fc97-6cb0-4fd0-b8e7-6a3d6721e7e2',
    'identification_number': 92564186,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5780164-b987-4497-ae44-c9643047c822',
    'identification_number': 27661865,
    'item_consumption_1': 7586,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2967.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68762215-a609-4159-9560-184218df9ccd',
    'identification_number': 77776500,
    'item_consumption_1': 10686,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3976.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a90be2b7-9d72-4d8d-a60b-125a3a4a9082',
    'identification_number': 8543844,
    'item_consumption_1': 3682,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1565.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ffefbac-a714-4bda-90dd-a09fd3993c4a',
    'identification_number': 104923407,
    'item_consumption_1': 386,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 164.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a08cccb2-f5d5-4b21-8655-718665007fa3',
    'identification_number': 111848520,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f46cb84c-2d41-486a-b970-4b35b50cc424',
    'identification_number': 110973240,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ade8bbc9-4e1c-428e-b5eb-313c57f050c5',
    'identification_number': 13015346,
    'item_consumption_1': 287,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e02740e-4559-45dd-92d2-9c29c87a132c',
    'identification_number': 66254787,
    'item_consumption_1': 843,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 329.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0a203e8-ab62-49ea-82ce-99719523203a',
    'identification_number': 76403955,
    'item_consumption_1': 54.31,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1579.64,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7773e323-a418-48ec-bb46-1ef81b0d09c7',
    'identification_number': 46861009,
    'item_consumption_1': 7.86,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 187.47,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '15782653-63d7-4cf0-b8f1-26cc9529ebc3',
    'identification_number': 95633359,
    'item_consumption_1': 1152,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 672.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18eb6d4f-d5e1-4f19-87f3-0b545c328266',
    'identification_number': 64620441,
    'item_consumption_1': 942,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 367.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82557068-ffae-4793-ab7e-1455c3c605c5',
    'identification_number': 97676950,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea1f0444-9fde-4cb3-a6bc-9743905360bb',
    'identification_number': 109018451,
    'item_consumption_1': 1234,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1866.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'daf17a66-31f8-476b-b0c9-7e41e28fce98',
    'identification_number': 56706499,
    'item_consumption_1': 12.71,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 303.14,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '461b5396-c9ef-4255-a149-d5fc801d381f',
    'identification_number': 73311235,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2845.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f3602d5-a98c-48dc-81ed-ca883fbc351f',
    'identification_number': 46699236,
    'item_consumption_1': 9.32,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 436.46,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e5d141c7-b152-48c5-a68f-2c2784c67504',
    'identification_number': 41360869,
    'item_consumption_1': "'-1.11",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-356.07",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'fb52f3ae-baa0-4356-9fd6-c296ca008b19',
    'identification_number': 16916700,
    'item_consumption_1': 11418,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4452.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8236abe9-fa96-407e-a002-35775b3553fa',
    'identification_number': 84983981,
    'item_consumption_1': 35197,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4492.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2176abd1-6659-4066-85ae-4efe6726d047',
    'identification_number': 23562200,
    'item_consumption_1': "'-6.912",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-2402.23",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '683c0a1a-960e-40b3-a604-d78f000f2130',
    'identification_number': 18328504,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f1af9c8e-3802-4dbb-a945-378a7ebc82bf',
    'identification_number': 18632165,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d2bbf03-223a-48a1-8544-2c456e2dfc4f',
    'identification_number': 14389754,
    'item_consumption_1': 1447,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 184.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47f3b8f5-44b3-4769-8dbb-f455f8602cba',
    'identification_number': 75890380,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6c3d3491-d6ac-4ed3-9adc-21618e726702',
    'identification_number': 76376249,
    'item_consumption_1': 7.09,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 169.12,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '207cba59-026e-4b57-ac0b-29f5f7023d84',
    'identification_number': 34743278,
    'item_consumption_1': 850,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 332.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a3355ca-1bbd-4c16-89fc-4519b63e070a',
    'identification_number': 58738428,
    'item_consumption_1': 2.02,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 58.74,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '5159159d-55c7-4398-83e9-2a93fe9eab70',
    'identification_number': 59101580,
    'item_consumption_1': 4934,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 629.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f0f9493-98cd-4fd6-82a0-7ad3ce1098fd',
    'identification_number': 46761101,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6336bd49-897c-44e3-aad9-7eb0b63f0323',
    'identification_number': 17865069,
    'item_consumption_1': 740,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 289.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2f4d80b-e95d-4f71-b808-ad17df5b236a',
    'identification_number': 89442601,
    'item_consumption_1': 391,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 152.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '803442df-80be-4c18-920c-5cfeb6bc21b4',
    'identification_number': 106120310,
    'item_consumption_1': 971,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 412.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8140fe4-c45b-4852-b588-2faeaecb59fb',
    'identification_number': 84245590,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3e4c6a24-3cb3-4050-a01e-7126ef2c7188',
    'identification_number': 42491428,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9028be11-2333-4adb-8c0e-bea09241097c',
    'identification_number': 21718989,
    'item_consumption_1': 0.47,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 22.03,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '833c19db-b1ef-41de-9c6c-526adda7326f',
    'identification_number': 29896991,
    'item_consumption_1': 4765,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2023.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '436a18a8-8217-4de9-9a33-55e3f4fbe3a7',
    'identification_number': 56527144,
    'item_consumption_1': 1670,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 213.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c258c38d-a8f7-4952-bd70-19e4c32f501a',
    'identification_number': 18229077,
    'item_consumption_1': 32944,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4204.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af96aaeb-f84f-45fa-963f-dfd3e7300709',
    'identification_number': 15173011,
    'item_consumption_1': 155,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 65.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '685ffe27-ed80-4b77-b640-d68db0097ddd',
    'identification_number': 99244055,
    'item_consumption_1': 1433,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 606.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '145f1032-b036-41ce-a979-bc7228fe9e99',
    'identification_number': 55818439,
    'item_consumption_1': 320,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 124.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39fa1f1a-8ca3-4cd4-9058-b4c2228d67f1',
    'identification_number': 51527987,
    'item_consumption_1': 3.98,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 94.94,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5fe89b53-a249-4549-936e-ccfb6273f72a',
    'identification_number': 109682424,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd849208c-9055-4507-9115-0eba87b7eca1',
    'identification_number': 17550254,
    'item_consumption_1': 6277,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2659.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc2b4280-5906-4223-94a7-de832984934c',
    'identification_number': 92739245,
    'item_consumption_1': 4.18,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 99.71,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '23bae0e9-340f-4284-bc4d-281f7a77b737',
    'identification_number': 19451610,
    'item_consumption_1': 1.64,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 76.81,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1fa25932-16df-4682-8f06-880ad1a39a37',
    'identification_number': 78610729,
    'item_consumption_1': 5753,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 734.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a89dd75-5963-4a98-b403-2f4e61e796e2',
    'identification_number': 81777302,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1358.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3b99418a-4088-4bd5-ae22-d6fc1ea8ea48',
    'identification_number': 935177955,
    'item_consumption_1': 2805,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 2445.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de91bda9-ceda-4626-926a-6e9a0741a853',
    'identification_number': 111834228,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0c819780-8724-4cfe-bf6d-bced99d47ce3',
    'identification_number': 71433449,
    'item_consumption_1': 6284,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2665.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43ff423e-442f-4431-8bd1-29aa5133e07f',
    'identification_number': 18036244,
    'item_consumption_1': "'-11.358",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-3952.27",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '14bdc380-5797-451d-a46d-9adb0b15e9e1',
    'identification_number': 59484381,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce227bd5-3d30-45e1-975c-670c26454c70',
    'identification_number': 98799681,
    'item_consumption_1': 715,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 279.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02e1606b-50b6-4d95-b752-f986b0c093dc',
    'identification_number': 14333910,
    'item_consumption_1': 5898,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3444.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9a18eeb-a1b6-4f1e-9878-a4a4d0869c1c',
    'identification_number': 9841709,
    'item_consumption_1': 6394,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2714.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbae9511-a2f2-4b9a-87e7-c61d77ec765f',
    'identification_number': 90453450,
    'item_consumption_1': 53,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 20.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5f13454a-b6e4-4d08-a10d-75effa757245',
    'identification_number': 98942468,
    'item_consumption_1': 1383,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 807.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4faa61d7-a8af-490c-ae5d-eb9fc05e3f34',
    'identification_number': 5736234,
    'item_consumption_1': "'-6.912",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-2404.67",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '6a6ddf20-5a4a-42de-8804-7f9e2ffd1a94',
    'identification_number': 73311235,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 298.38,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '517c2f84-f876-49cd-934b-fd4ec11b9fb7',
    'identification_number': 4334671,
    'item_consumption_1': 10.46,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 489.85,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '9b3db1e6-c183-42ca-a0a7-f60caccaa572',
    'identification_number': 103034412,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '012f87d8-dcc0-434f-86f8-24df2c5cab82',
    'identification_number': 18040918,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '676de08e-9b79-47ce-ad52-a06634c717d9',
    'identification_number': 45602387,
    'item_consumption_1': 109,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 42.63,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e4accc85-2f3d-4192-8163-76990e5fa40b',
    'identification_number': 23691530,
    'item_consumption_1': 768,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 448.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '669bd4fa-ee66-44ef-bb77-e276feb852ef',
    'identification_number': 103402578,
    'item_consumption_1': 5597,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2374.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55a065e6-9072-47a0-9167-6470f4dbace3',
    'identification_number': 19451601,
    'item_consumption_1': 343273,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 127748.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '668ed9c3-42f4-4de9-ac9e-6644e8fb3072',
    'identification_number': 94602123,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cf242d29-2a90-4f0f-b568-38487e4b3dfc',
    'identification_number': 43195300,
    'item_consumption_1': 2.46,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 115.22,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ab24677a-828c-4866-9cdc-69ecdcbfe847',
    'identification_number': 105737720,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e104b8be-dda6-441c-8a91-3b19afc6e65d',
    'identification_number': 19282281,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO (02)',
    'item_total_value_1': 22.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7dc1ce44-fa35-4a2d-af78-384d1e35a90f',
    'identification_number': 14562553,
    'item_consumption_1': 241,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 30.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c836f24c-a6eb-4141-aeb2-945c14b9ea03',
    'identification_number': 35614595,
    'item_consumption_1': 352,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 205.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3fdc06f-3a7b-4565-a390-e9f1557f55e5',
    'identification_number': 10566813,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 75.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2f48b43d-5585-4f24-bb1a-931174c2c513',
    'identification_number': 66592747,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63f0435b-eb8f-4def-a7fd-bb3304ce64cc',
    'identification_number': 89340639,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6670efce-5f6a-4d2d-ad14-7756a56afe0d',
    'identification_number': 95159320,
    'item_consumption_1': 4318,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1682.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01d9f071-da48-4aa3-8ca5-ec970473e6a9',
    'identification_number': 48482897,
    'item_consumption_1': 19240,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7160.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fe9874d-86ef-465c-bee0-ca58c97698ca',
    'identification_number': 39920984,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '62963dbb-6798-4071-8f91-2e85d52a9249',
    'identification_number': 106885880,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7012dd47-1f70-4243-afdf-d92ac46131d6',
    'identification_number': 102687560,
    'item_consumption_1': 47705,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6088.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4206f86b-767a-4294-b67d-f9f48806a928',
    'identification_number': 15719464,
    'item_consumption_1': 1291,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 753.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b8017c4-cb87-4ee4-a5b4-129effe1f35d',
    'identification_number': 92483119,
    'item_consumption_1': 9876,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4192.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5b5f4f4-8b35-4396-8ca0-ca139dd1158c',
    'identification_number': 61481467,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14328.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62606d55-bd92-4f8a-8fae-cc81e9d3ac57',
    'identification_number': 37612280,
    'item_consumption_1': 633,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 268.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e33b3b34-a2ef-4d52-b415-1bb289e598f7',
    'identification_number': 74755897,
    'item_consumption_1': 1334,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2018.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '841a267c-f7b1-4dec-b599-91d6030913e3',
    'identification_number': 91896568,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dc16401f-b1a4-4d40-84b9-fce3a7ebea30',
    'identification_number': 58734210,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd7f65b56-4a4a-480e-b98c-e8b03737f97b',
    'identification_number': 38226928,
    'item_consumption_1': 1730,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 734.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '533089db-b392-461d-be47-6583d00de505',
    'identification_number': 94708231,
    'item_consumption_1': 605,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 256.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a82b6dc-cf5f-4219-8b7f-b4f45a1a10fb',
    'identification_number': 68135580,
    'item_consumption_1': 5873,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2185.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0ecd8ba-edd7-4255-a16f-38e548a97aab',
    'identification_number': 18339603,
    'item_consumption_1': 11814,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1507.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '056b3cf9-110a-4175-9e95-7e6b6347b973',
    'identification_number': 93134495,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '016a49b1-a5c5-4c0d-9941-315403bb79cc',
    'identification_number': 92994105,
    'item_consumption_1': 3664,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1427.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0189502-4e6f-4bd2-8cad-4b05a492d9cb',
    'identification_number': 75064430,
    'item_consumption_1': 11363,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1450.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4eb22b34-d436-48bd-9709-b75cc66dd293',
    'identification_number': 17578566,
    'item_consumption_1': 6160,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2613.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd388c98e-4a79-4b0f-b6c3-e7e96ded0437',
    'identification_number': 80644554,
    'item_consumption_1': 3293,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1397.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5714a0cb-eac8-4250-a3a6-43f2eed782cb',
    'identification_number': 47380128,
    'item_consumption_1': 25.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 728.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '749474c3-5f06-41da-8317-91f43f243071',
    'identification_number': 96313323,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44e8bb80-0b8b-4efc-975b-0782a52d3f59',
    'identification_number': 42396905,
    'item_consumption_1': 185372,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 68985.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d642033-8101-4f85-95ce-ced521ee2c11',
    'identification_number': 45701679,
    'item_consumption_1': 828,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 323.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5999fd2-6853-4f6e-ae92-b02cc83f0bf3',
    'identification_number': 96749024,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c62df0f0-7d91-4b4c-ade2-4d6d163f720e',
    'identification_number': 19452179,
    'item_consumption_1': 261411,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 97283.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd67e6c6d-4fc0-4832-91db-9d8c5f9201b3',
    'identification_number': 84363690,
    'item_consumption_1': 73,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 28.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da6af250-76db-4540-9188-f6d67ef7d07d',
    'identification_number': 52981533,
    'item_consumption_1': 798,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 311.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51993eab-c028-4ec1-af45-d8c07441757d',
    'identification_number': 111282756,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6222bc5-47bd-41fb-ac91-f9c4cdb5341a',
    'identification_number': 66345820,
    'item_consumption_1': 666,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 282.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbf9f8fe-97da-4e8d-add7-26e5c364c88d',
    'identification_number': 12469084,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.85,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4007f173-6dd8-49e2-8433-6ad34bc127d6',
    'identification_number': 47952555,
    'item_consumption_1': 417,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 163.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a44fc37-b149-4073-a7bc-159f423dc5b7',
    'identification_number': 110479890,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e7ee184b-567e-47a6-9480-efeee75e5123',
    'identification_number': 78084237,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1550.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '881d1e58-9c28-4805-8c67-5cf14078a33b',
    'identification_number': 68081251,
    'item_consumption_1': 14165,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1807.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11cd18d3-6370-40de-83f2-299590c89dcb',
    'identification_number': 19281404,
    'item_consumption_1': 7868,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3066.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '628635c5-0796-4500-918e-ee9fdc6a0cce',
    'identification_number': 39482146,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '17c5522a-9881-4b07-b49c-b3f47770ccad',
    'identification_number': 41932510,
    'item_consumption_1': 2683,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1566.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f51deaeb-b94f-4def-9537-1984f1cea9c0',
    'identification_number': 16695135,
    'item_consumption_1': 3829,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1621.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09d08fbb-3a66-486e-95c4-668f4b64e7c8',
    'identification_number': 12773840,
    'item_consumption_1': 6565,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2790.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8744445-8c71-462a-a8e0-3d992861ae42',
    'identification_number': 102251967,
    'item_consumption_1': 964,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 409.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfe60f20-13c7-43aa-81d8-5ab1a8959539',
    'identification_number': 103005056,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48822c82-3265-463f-a4f3-c1dc2fb2b246',
    'identification_number': 28426193,
    'item_consumption_1': 394.84,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 11484.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f2afd69d-8bd7-4e50-b894-f22832cb7088',
    'identification_number': 94664480,
    'item_consumption_1': 192,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 81.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '832d273c-57bd-4064-8749-28799d7fce8d',
    'identification_number': 109247140,
    'item_consumption_1': 6003,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2546.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d2f54c1-9c0c-4974-8728-39d1923f629b',
    'identification_number': 15091520,
    'item_consumption_1': 57,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 22.3,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b139e8c7-c6af-4c9a-91d2-aaff08c5595c',
    'identification_number': 68079257,
    'item_consumption_1': 12503,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1595.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6566770c-f864-48c0-ad28-e549c39e1f32',
    'identification_number': 14862336,
    'item_consumption_1': 1118,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1691.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4448d80-22c8-4475-bba4-35c1c5d7ddc0',
    'identification_number': 92056920,
    'item_consumption_1': 19.53,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 568.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'aebc6aa9-a750-4ac2-9c74-3fdc655ea4ad',
    'identification_number': 11011130,
    'item_consumption_1': 7386,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3137.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70a8b36d-b893-436c-864b-742d1daa65a6',
    'identification_number': 72507136,
    'item_consumption_1': 14267,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1820.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf1e2dab-42c8-4c6b-bda6-1f5a65d17664',
    'identification_number': 96256346,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b10d8242-0fff-4e2c-a67a-8b5dd7bb3e25',
    'identification_number': 109118553,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e591f388-3944-4282-a8c7-f1bd47004eaf',
    'identification_number': 35809329,
    'item_consumption_1': 29.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 714.58,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4c509871-d605-4065-b8c2-9c237529ece5',
    'identification_number': 105154180,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 102.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd5816058-209c-4788-8a63-e617973c5978',
    'identification_number': 111282756,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6225022a-4e2b-4dd5-9b28-7cd33ad8f575',
    'identification_number': 96072237,
    'item_consumption_1': 2468,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 963.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '401e0b4c-95b0-4673-9a58-626b90585a81',
    'identification_number': 43814166,
    'item_consumption_1': 1348,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 787.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7da868e-8967-45e0-a855-b9c3992e42b8',
    'identification_number': 14689146,
    'item_consumption_1': 6.7,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 765.28,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5b997e64-544b-4253-aada-8fdeacbc0c47',
    'identification_number': 108852148,
    'item_consumption_1': 2261,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 960.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b02eb0c-2560-44da-b596-2d52fe13dd85',
    'identification_number': 15348075,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '554fad3c-fe68-4f58-914d-983eb3289558',
    'identification_number': 69861412,
    'item_consumption_1': 25522,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 9947.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4bb7b92-4680-480c-8d05-f104a2a76c6d',
    'identification_number': 35119624,
    'item_consumption_1': 17,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 6.64,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6bdc213e-2ec8-4a3c-990f-c1a34052d0ee',
    'identification_number': 14982960,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e393d9e2-210c-4c99-8660-ba7e5336accc',
    'identification_number': 88748278,
    'item_consumption_1': 2164,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 915.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40123929-7b46-4084-8c31-e2817342324f',
    'identification_number': 6338526,
    'item_consumption_1': 20796,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7739.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f62e43a4-23c3-4e92-a90c-0989fb1c4ba6',
    'identification_number': 36704296,
    'item_consumption_1': 775,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 302.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54153110-a242-4a94-83d9-d408bfa25f37',
    'identification_number': 105699047,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a3cc1025-99ef-4e25-8813-88d89768b853',
    'identification_number': 103823760,
    'item_consumption_1': 2159,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 916.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71079250-79d0-4977-8a48-558edf255dca',
    'identification_number': 19452160,
    'item_consumption_1': 308236,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 39342.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7eebee01-a8f3-4d60-af3b-a4c08f1124ca',
    'identification_number': 28860136,
    'item_consumption_1': 14.79,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 430.17,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ae3a9ba4-f5f0-4010-9dce-6c777e4b203d',
    'identification_number': 90439325,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 93.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5c1b961b-7f9d-4273-9f58-a8f3ba8fbace',
    'identification_number': 96313897,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fea56e39-da10-4ca3-9bd0-c8cfd15f3b76',
    'identification_number': 80201555,
    'item_consumption_1': 41968,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5356.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6963cefd-8405-4b65-9dd7-1d3d939de92f',
    'identification_number': 65191404,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd3347aa4-07e4-4f74-9c4a-bfe95fcea27e',
    'identification_number': 16187130,
    'item_consumption_1': 218,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 92.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0159fd21-dff1-432a-8fad-79f975a101d9',
    'identification_number': 98074008,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37874413-8d28-4789-b0a0-f633da7edfa9',
    'identification_number': 6123341,
    'item_consumption_1': 2631,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1115.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ebcac43-c442-4b1a-928d-52f4824620bb',
    'identification_number': 97699217,
    'item_consumption_1': 242,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 94.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6b75313-4762-473e-a908-b1e9936a31e7',
    'identification_number': 107330288,
    'item_consumption_1': 30,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 715.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '20d26320-0283-4691-a234-533ae7684bda',
    'identification_number': 41231058,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4c89fafa-6750-41cb-aaeb-76ea2cffdfe9',
    'identification_number': 104871563,
    'item_consumption_1': 16269,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2076.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df83f0f1-e26c-4ab0-b389-ac7ab05eb884',
    'identification_number': 102341460,
    'item_consumption_1': 508,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 215.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de5a3c4e-75de-4b54-ba04-053004240cb9',
    'identification_number': 37367790,
    'item_consumption_1': 3223,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1259.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '681766b2-38ec-46d7-94b8-548c5a26cc2f',
    'identification_number': 108198804,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f648e214-41f4-4af3-8db7-41c1511ed0dc',
    'identification_number': 5679443,
    'item_consumption_1': 5717,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2424.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0648ce58-f30d-455b-9909-ccd62b969bd5',
    'identification_number': 99505266,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '509e23c9-727e-43f2-9b25-1f74dc058370',
    'identification_number': 99051770,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1464a097-6a03-4875-9278-c217c15cbed1',
    'identification_number': 102003211,
    'item_consumption_1': 1581,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2391.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b030fafa-e5cb-4233-9eba-f7ef79005b9c',
    'identification_number': 83757988,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 45.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a4db1f19-0605-453e-8395-178bd3991be1',
    'identification_number': 13789430,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '327acd3d-68a6-42f4-b9d5-ea77be86b55f',
    'identification_number': 59070960,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a27d1aab-ca6d-4895-b013-18a5bece3409',
    'identification_number': 94460566,
    'item_consumption_1': 5.62,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 134.05,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '69744c20-6c08-410b-a2be-793a22601c99',
    'identification_number': 109034333,
    'item_consumption_1': 4866,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 621.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75f5ab40-7e33-4b33-b39a-d8700c18787a',
    'identification_number': 103785558,
    'item_consumption_1': 18950,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7052.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff93c0af-be57-42f1-8af0-d2bd013f5179',
    'identification_number': 70157510,
    'item_consumption_1': 7872,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3339.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05c2efdc-0463-4e83-acd9-5145b54ac811',
    'identification_number': 18223680,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-12.05",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'da48fe34-5bf6-4ba3-aa82-f6d891504788',
    'identification_number': 14140276,
    'item_consumption_1': 1617,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 631.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3b090bf-2698-4d83-8ebd-3cdb354e57f8',
    'identification_number': 9757481,
    'item_consumption_1': 12057,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4709.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca9cc52a-6421-421e-916c-ef80c8c9157e',
    'identification_number': 18217290,
    'item_consumption_1': 542,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 229.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b550fcdd-25bb-4138-a324-3e861759d4ce',
    'identification_number': 42162653,
    'item_consumption_1': 5702,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2229.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7655c875-3294-44a5-b44f-0d08b0520e7e',
    'identification_number': 9788131,
    'item_consumption_1': 28164,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10481.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be109d88-69cf-4d82-a59a-ee7055f9efbd',
    'identification_number': 56699077,
    'item_consumption_1': 19.16,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 557.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '868daed2-4a4a-4895-ab5a-85cc3454882d',
    'identification_number': 27549615,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '64003ced-0b75-4bbc-9ca8-76bb6337082a',
    'identification_number': 99178990,
    'item_consumption_1': 7405,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2886.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba36278c-99b6-47dd-bffe-09ecd10897e6',
    'identification_number': 102765600,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4307bcb6-0fae-4e44-8a72-5dd887363ab1',
    'identification_number': 101778910,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f39f4d10-13b3-4d5c-8967-4ebc85b67f80',
    'identification_number': 58739149,
    'item_consumption_1': 4830,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1797.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a74e0d09-3248-423e-aa64-1830742106e6',
    'identification_number': 59479078,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54efd30a-4642-4178-882b-0b538d2eec9d',
    'identification_number': 67531741,
    'item_consumption_1': 1337,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 521.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60871db0-4216-4ac7-bf19-164ed3b6063c',
    'identification_number': 46758690,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 60.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f5307ee8-06ae-4d82-a15d-c4d36a36998d',
    'identification_number': 40131157,
    'item_consumption_1': 3361,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1424.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90054f58-ee1a-4ad9-a3fe-7ec4fab8964d',
    'identification_number': 107160498,
    'item_consumption_1': 210,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 81.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '944ee5c3-de4c-4fc0-a72f-1dee47c68b7e',
    'identification_number': 83790802,
    'item_consumption_1': 129,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 54.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d5f7202-ebdc-4a5a-afa9-ebfb75d28cea',
    'identification_number': 95566651,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5842e6a4-7cf9-4c0e-a704-c6be709557b8',
    'identification_number': 56705514,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b8a92898-94ad-4cb0-8490-eb091aea4eb1',
    'identification_number': 96386673,
    'item_consumption_1': 912,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 387.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9485a795-586d-44bf-82b0-ac25bca4c19c',
    'identification_number': 78143977,
    'item_consumption_1': 8601,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3200.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '122c2de2-7e88-4bed-8fc8-8fb635507149',
    'identification_number': 101421087,
    'item_consumption_1': 911,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 354.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3de322d4-3978-4878-870e-8dfe50c639df',
    'identification_number': 108300412,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 0.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5c5c32c3-1ea9-4ea9-8039-d665c661f3e1',
    'identification_number': 43908764,
    'item_consumption_1': 6.49,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 303.94,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b4715226-d412-49b8-addf-c9fd572315f6',
    'identification_number': 102507864,
    'item_consumption_1': 5317,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 678.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96dca426-6d19-4ad9-b0a9-2909b9cbbf46',
    'identification_number': 48999881,
    'item_consumption_1': 2267,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 885.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1b7388f-d84b-4d12-bd1a-2c0def9015be',
    'identification_number': 57673160,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-1232.67",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '119cb6dd-9c97-4441-8803-771a9962aade',
    'identification_number': 42712190,
    'item_consumption_1': 33.6,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 977.26,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2fa1782f-2aa0-472e-b30c-0902b1b65db8',
    'identification_number': 96029641,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f7bb75c2-1991-454d-9a40-6d581ecd940c',
    'identification_number': 12629,
    'item_consumption_1': 9,
    'item_description_1': 'DEMANDA ISENTA DE ICMS',
    'item_total_value_1': 327.96,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '84e53838-8ecb-41d6-9100-a95b7694a833',
    'identification_number': 50776029,
    'item_consumption_1': 1742,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1017.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84ca6b08-db23-4f91-8eca-8df4d46b1148',
    'identification_number': 102844976,
    'item_consumption_1': 277,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 108.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85280d55-35f2-442c-a8de-7d4acf0684f6',
    'identification_number': 18485774,
    'item_consumption_1': 2609,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1109.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83eb5ebe-1b34-4082-9bf2-8752d8ea97a5',
    'identification_number': 105323640,
    'item_consumption_1': 9609,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3754.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f00e81c3-054c-4306-a0e8-029fe7266814',
    'identification_number': 94625581,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a9b29e52-9467-4656-b0be-d41cca42a552',
    'identification_number': 82521310,
    'item_consumption_1': 16590,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7031.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aad26f6b-3a32-46b4-ac57-4614a6d755a6',
    'identification_number': 27895610,
    'item_consumption_1': 1617,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 944.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '476655b2-37b8-4118-a81e-da2d1bb7f366',
    'identification_number': 109204328,
    'item_consumption_1': 819,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 347.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '585bd52a-176e-430d-8a19-c852066cc328',
    'identification_number': 32191871,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '172611c2-8fe2-4a3b-948a-4cd9251dc3b6',
    'identification_number': 97856681,
    'item_consumption_1': 2465,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3729.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60dbe2ef-1e1f-4ecb-89ee-eff95978c8d5',
    'identification_number': 29659043,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 143.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ccb4f2f3-6d01-4fd0-b830-412e75aac3f0',
    'identification_number': 103152253,
    'item_consumption_1': 1973,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 837.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69d43b51-05af-49a0-a6b9-64171cc8213e',
    'identification_number': 99887878,
    'item_consumption_1': 1747,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 740.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1818818-f396-44ab-a698-eacaed9aad41',
    'identification_number': 85192171,
    'item_consumption_1': 925,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 361.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a26d518-319f-4558-8cee-1ebff6eb2641',
    'identification_number': 35766514,
    'item_consumption_1': 16135,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2059.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d518f8c-ecaa-4a7e-ab6f-2bf52cd4be91',
    'identification_number': 46424709,
    'item_consumption_1': 3845,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1497.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef4ad349-4598-494c-ac8d-d0c432c170f7',
    'identification_number': 18616917,
    'item_consumption_1': 5405,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3156.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eaae796e-8863-49a0-939f-1771e36e832c',
    'identification_number': 98598945,
    'item_consumption_1': 17101,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6364.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aea7976f-4ef0-475a-9da8-06ba0c0fb103',
    'identification_number': 13178032,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2b556e32-ffe2-4578-ba54-35b17c167205',
    'identification_number': 91996384,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '451d5c3d-79c4-4fb3-a41a-de9bf74468aa',
    'identification_number': 62515438,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '36ec1dce-ec71-47ff-a4cd-338c72e55591',
    'identification_number': 111198542,
    'item_consumption_1': 1987,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 776.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '271f9ecc-66e9-4265-ad9b-9d847196465f',
    'identification_number': 102654549,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c74184a6-8e5e-45dd-bacf-5f493ad267bf',
    'identification_number': 104842334,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '942d57fa-a9a9-45e2-9d8d-ec1da5002932',
    'identification_number': 100138268,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3c17ac34-9d1f-483f-bf19-818610c2817c',
    'identification_number': 69967318,
    'item_consumption_1': 25891,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 10123.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1635ae0-0297-4fbe-b587-9db8e6b02b27',
    'identification_number': 14859424,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '76e02e90-420f-475e-badd-717b54716cfb',
    'identification_number': 110270410,
    'item_consumption_1': 13,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 19.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2ca274c-9aa1-4a65-a561-563ed53867e4',
    'identification_number': 91360250,
    'item_consumption_1': 750,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 438.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a5110d2-3d59-4880-a02a-6ff3815165cd',
    'identification_number': 100637833,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '882df4ac-3e54-418e-92dd-b8d6c54c1007',
    'identification_number': 41611187,
    'item_consumption_1': 239.89,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6977.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '405061e6-5a9c-4246-a9f9-a9c64af1fdb1',
    'identification_number': 17582890,
    'item_consumption_1': 919,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 390.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be4be9b4-480f-4676-9a7b-65b5a5dbc78c',
    'identification_number': 16951662,
    'item_consumption_1': 1533,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 895.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d7ad8c3-db09-44fb-b871-dc14e705bacc',
    'identification_number': 19295855,
    'item_consumption_1': 3614,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1535.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a5e192b-7807-4477-bce5-359101d1031f',
    'identification_number': 37507940,
    'item_consumption_1': 25018,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3193.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c819e2c6-915c-4216-965e-60ff133cf09d',
    'identification_number': 40701301,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7d676b3f-d190-4058-83b8-3585c789efdc',
    'identification_number': 14862336,
    'item_consumption_1': 40.63,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1181.75,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6c49240c-f97f-4297-8417-f0d8909be028',
    'identification_number': 110323912,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ce457605-91a0-4f8e-9846-7d895f0d3709',
    'identification_number': 8708320,
    'item_consumption_1': "'-8.736",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 08/2023',
    'item_total_value_1': "'-2793.17",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '2377adf7-b10c-425f-aeb4-5360476eadc2',
    'identification_number': 91845947,
    'item_consumption_1': 218,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 92.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f4a0679-a626-413b-80e7-1d18fcf04caa',
    'identification_number': 100424198,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.67,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '411f3604-3be9-4c43-83dc-0cbdf4f67ece',
    'identification_number': 99426544,
    'item_consumption_1': 348,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 135.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a455fa2-3294-4bc0-b296-09c031ac897e',
    'identification_number': 111114039,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2c12bae-2fa8-43e0-9761-992236bff782',
    'identification_number': 103284770,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '65d54b4f-c029-4bf7-9a1f-6a04b57ff7a0',
    'identification_number': 85205672,
    'item_consumption_1': 7,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 2.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4b5b2482-dd0e-478b-9c60-1028e2765ca0',
    'identification_number': 80788661,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd76d95f4-91d9-466c-96ff-0e4acf6687a3',
    'identification_number': 106605712,
    'item_consumption_1': 3909,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 498.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ddf3fe3-51ae-4199-9d21-3db49aeb8899',
    'identification_number': 43814697,
    'item_consumption_1': 3075,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1795.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '524df74a-1773-4c0e-b70a-aaf138d6c7cb',
    'identification_number': 59484381,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-1.71",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'f95453b6-c3e6-46eb-8766-cd9de884de8b',
    'identification_number': 73901610,
    'item_consumption_1': 3518,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1495.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fe8d46d-ea37-4c4e-bd12-ce19c880c841',
    'identification_number': 33921148,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 117.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9a2a5a6-8176-4990-8ee7-cabfec69cb5a',
    'identification_number': 106141864,
    'item_consumption_1': 4911,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1827.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fa3ee19-f592-471d-af0f-1b4299e1d07d',
    'identification_number': 30085900,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '48b14b54-d8ea-4f3b-a74d-e200de6efc6f',
    'identification_number': 37076922,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ae88a94-0480-4097-b3d6-cca9b85ba034',
    'identification_number': 96211008,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c68d74db-f58c-4f23-b6bc-15c982e4791b',
    'identification_number': 95633359,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 126.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd5a7575e-88b3-4bce-b6eb-3b2fc22207a7',
    'identification_number': 8782571,
    'item_consumption_1': 11310,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4804.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2839e148-1166-4f4c-ae40-35fe4df02507',
    'identification_number': 102985693,
    'item_consumption_1': 8590,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3642.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a412d9c3-d785-4165-b676-2ffb766dc9d3',
    'identification_number': 47380128,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e683237c-8851-406f-ad84-8c13ac4be88e',
    'identification_number': 109822285,
    'item_consumption_1': 106.84,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 2548.2,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd6785f93-6204-491f-ae00-f19024e765eb',
    'identification_number': 75917050,
    'item_consumption_1': 11899,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4650.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4882292d-cdf4-4d2e-b12e-475b5fb33003',
    'identification_number': 94658439,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '007b48bd-9dfb-4d55-b052-66f1a4aff76a',
    'identification_number': 28671139,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '921eb532-b743-4d60-bf0f-9d44c0b4c9e8',
    'identification_number': 14689146,
    'item_consumption_1': 2234,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 285.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b05c3653-3258-4335-84c9-c8679edde92d',
    'identification_number': 12424188,
    'item_consumption_1': 136.38,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 7788.65,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '51d7bb0d-dd65-4f1a-a09e-33ad5f4a96ea',
    'identification_number': 92530214,
    'item_consumption_1': 10565,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4119.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '223ec97e-f725-4f5f-926b-ee57ab94bf29',
    'identification_number': 101885610,
    'item_consumption_1': 6.52,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 155.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'feafa490-06e7-4faf-8e30-072ef655f66a',
    'identification_number': 62088777,
    'item_consumption_1': 4766,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1858.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc45e5b9-8f77-4e3f-ad8a-9bab7a53e92f',
    'identification_number': 55523331,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72ebc703-9f69-4ece-a6e8-94aa54e4aae2',
    'identification_number': 102734607,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '051f1cfa-4f74-4c0d-94ba-a1112d99b6b9',
    'identification_number': 97630454,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7d7569bf-f9f1-4651-ab9e-6fd4468ba350',
    'identification_number': 46761101,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f59c69d7-2acf-4b1c-9f6f-f92552dce7b5',
    'identification_number': 76403955,
    'item_consumption_1': 11946,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4445.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2fd0d085-0359-4c3a-a009-96858c373da6',
    'identification_number': 11446366,
    'item_consumption_1': 8062,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3426.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59f8290f-7c49-4826-9b07-b9bde1d04188',
    'identification_number': 47989050,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c0a3c894-af71-453c-8c23-732f2a9d5a85',
    'identification_number': 69826145,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c00e13c9-78c4-4334-a631-32ce22fb6eb1',
    'identification_number': 91799376,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40f42dd2-5a26-411f-87b7-cc00bcaec269',
    'identification_number': 78610729,
    'item_consumption_1': 53249,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6796.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca107614-f768-49e5-b7fd-84830d717a59',
    'identification_number': 33670420,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '611042d7-046c-4bf0-a88b-f0eb0e26fcbe',
    'identification_number': 102654549,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 483.35,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'cc0a52a2-052b-446d-9078-eb81b0cb063d',
    'identification_number': 50940694,
    'item_consumption_1': 4.08,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 97.31,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '03324b3b-74a7-4bd5-a42b-5ea60fd6e433',
    'identification_number': 36337803,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2a1dadd5-2990-4099-97ad-eb1cb61386ab',
    'identification_number': 76229815,
    'item_consumption_1': 24584,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9148.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bdb3b571-8b9a-4f01-a2b0-e00df766a93e',
    'identification_number': 16677412,
    'item_consumption_1': 2483,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1450.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '642c4401-9d81-4e4c-a5a9-3bf4f0c8c786',
    'identification_number': 108153746,
    'item_consumption_1': 6301,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2668.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f30e0c2-6d34-43ca-9bf9-af17d5ffacbc',
    'identification_number': 45838313,
    'item_consumption_1': 1683,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 982.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5302a193-1907-4c50-90d1-9e2f0b091245',
    'identification_number': 13299530,
    'item_consumption_1': 2.17,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 247.84,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '6a2b3ed7-0db9-4a0d-809c-b1b3c3ae78ee',
    'identification_number': 93669860,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '322bec47-4bc2-4166-932d-7bad5fa2ef0a',
    'identification_number': 13935739,
    'item_consumption_1': 19013,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2426.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c454adb0-6955-4e5f-a656-82fb3aa09415',
    'identification_number': 45907137,
    'item_consumption_1': 5159,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1919.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42f40622-300f-4508-8189-bf5dfed71e6d',
    'identification_number': 23376414,
    'item_consumption_1': 5998,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2339.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fdef425-4dee-4f16-9050-5ae08f61e737',
    'identification_number': 19229747,
    'item_consumption_1': 4922,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 628.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03df7538-0e8a-4b0b-ba62-033aab4103ee',
    'identification_number': 75064430,
    'item_consumption_1': 1.49,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 35.54,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e249db5e-c021-4d05-81a8-af33c6c5ca40',
    'identification_number': 15714063,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 94.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd40a11d1-7096-4825-bf98-37d5270fc943',
    'identification_number': 97821403,
    'item_consumption_1': 1284,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 544.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f1040a9-5393-4871-b809-fadb8739ed6b',
    'identification_number': 10895132,
    'item_consumption_1': 3422,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1450.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1986816-77b6-41ff-b2e5-59a494a745a6',
    'identification_number': 58229809,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a4adea3-8284-4915-ae90-d2ef7ee2cd6c',
    'identification_number': 102239436,
    'item_consumption_1': 9343,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3640.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fc7de99-8f68-4d0a-bc9a-07d226a4d147',
    'identification_number': 41640870,
    'item_consumption_1': 70253,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8966.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd33f1155-759d-4251-b844-123e38e94ffa',
    'identification_number': 100922961,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b20ad67c-a9d4-4e19-8347-832987f26524',
    'identification_number': 40995305,
    'item_consumption_1': 136.38,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3966.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '101a94c4-b16a-46f1-aea3-cd96c69a4404',
    'identification_number': 21966230,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3a3504da-f279-48e3-b49a-ad72d0d4a13b',
    'identification_number': 28202619,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8f09d67b-cc95-4c69-9214-4c185d0dcbb3',
    'identification_number': 31792634,
    'item_consumption_1': 10.42,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 248.54,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6c91a4fb-fe6f-405d-89a0-f6b515e0d515',
    'identification_number': 105796506,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5ff2c448-8c07-417a-ab4f-56aed9f9cfa1',
    'identification_number': 45985553,
    'item_consumption_1': 809,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 343.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae323ca3-aff5-4b80-96b9-74e1ec4b2fe7',
    'identification_number': 102765600,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '942fac5d-c6c9-4261-a5c4-a5990f37c188',
    'identification_number': 29200091,
    'item_consumption_1': 12288,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5222.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '519a65f0-847c-4d4c-95f2-390c21c1ef81',
    'identification_number': 95067590,
    'item_consumption_1': 2079,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 810.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '828ee36e-9821-4941-bc68-9fdee5c07256',
    'identification_number': 3030777641,
    'item_consumption_1': 207,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 104.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fdc67cc-5ebf-498e-ae87-daa9f93d3e63',
    'identification_number': 96338210,
    'item_consumption_1': 3676,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1559.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fac770ca-4c42-41d2-967d-6e078bd249fc',
    'identification_number': 25617737,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbb7b683-99db-4d05-bc73-467a31d57cb1',
    'identification_number': 16003500,
    'item_consumption_1': 48,
    'item_description_1': 'Demanda faturada',
    'item_total_value_1': 2276.05,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '19f3f17c-22f2-4526-82b0-40bf9392d10d',
    'identification_number': 79663036,
    'item_consumption_1': 44192,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16445.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a4d117f-13c0-4eca-aad6-4c514cce69d5',
    'identification_number': 11110597,
    'item_consumption_1': 3102,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1315.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a37f73b-39e9-4a52-9f10-81dae554cb46',
    'identification_number': 14619563,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bd01f7b3-50c7-4928-af22-b4bc4075b08f',
    'identification_number': 109530608,
    'item_consumption_1': 4696,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 599.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d2abb87-3a65-463b-916d-138e12665665',
    'identification_number': 84702893,
    'item_consumption_1': 19906,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7407.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7adeb9e-5d79-44e9-95cf-f62fe1cb6426',
    'identification_number': 76387054,
    'item_consumption_1': 2263,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 884.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa9304a9-6826-4dc6-924f-ec4df4d52675',
    'identification_number': 13924605,
    'item_consumption_1': 3025,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1125.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a2fa38c-4d57-480c-8935-bbc19d13f7f5',
    'identification_number': 28528646,
    'item_consumption_1': 105777,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 39364.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84686a9a-51de-416a-a2e4-1c1ac93d8595',
    'identification_number': 56886314,
    'item_consumption_1': 13151,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4894.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0620995a-df16-4f0f-b11a-847a88bfb8e9',
    'identification_number': 77672925,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1668.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd075e4f6-707f-40ad-9fda-3518c3909730',
    'identification_number': 5352533,
    'item_consumption_1': 1989,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 777.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6caab85a-f5e8-4fca-ac13-8b326338d57c',
    'identification_number': 59355573,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0f80e33b-b1af-4fa0-95e3-c82d3843eff3',
    'identification_number': 48018660,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '953a6fbd-a318-4a7d-8a70-b6d0fcae3b39',
    'identification_number': 20818343,
    'item_consumption_1': 2092,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 889,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a72b30f-014f-496d-b7e8-da0bb6497481',
    'identification_number': 105799653,
    'item_consumption_1': 4.9,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 116.88,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9d77977b-ff43-4216-94ec-164901936efe',
    'identification_number': 45563314,
    'item_consumption_1': 6057,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2574.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8019c8c0-eba1-4582-bc44-e26a885dd814',
    'identification_number': 61588555,
    'item_consumption_1': 425,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 642.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a51090d0-95cc-4e97-8703-d6be4592de07',
    'identification_number': 109861035,
    'item_consumption_1': 1317,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 769.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ab118f7-3e25-4018-be63-437950c84362',
    'identification_number': 98253409,
    'item_consumption_1': 438,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 186.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9f5e444-b7e3-4edd-bf2d-1c897c5350e9',
    'identification_number': 44478046,
    'item_consumption_1': 197,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 77.08,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8c4f8480-fa44-48d0-9a20-6673aa742a9d',
    'identification_number': 91858194,
    'item_consumption_1': 18383,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6841.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f75d5c8-8f96-452a-9caa-4df78fd7e420',
    'identification_number': 98925083,
    'item_consumption_1': 5870,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 749.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f5f7b9f-bf4b-467b-a6e7-5559608b64a6',
    'identification_number': 13028855,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e031c40a-d146-41da-8b9a-f38725f74b92',
    'identification_number': 8084661,
    'item_consumption_1': 19.18,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 457.46,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ba6eac63-6273-4b38-b2b1-15cf0cad11cc',
    'identification_number': 73533386,
    'item_consumption_1': 590,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 249.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6438c88b-656b-4b72-9223-fde44cf99d04',
    'identification_number': 13924605,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '07660a5d-3756-49ad-883d-0ebd7d79ea7f',
    'identification_number': 17000424,
    'item_consumption_1': 2523,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1070.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '330c4283-8b63-4bcc-a34d-a97509f5ee59',
    'identification_number': 108300412,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a204926f-cf81-4f90-8dbf-a802938d037d',
    'identification_number': 63991373,
    'item_consumption_1': 598,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 904.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '291bcc1a-73c8-49d6-93e2-8ee0ca80f90a',
    'identification_number': 71433449,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO EM DOBRO',
    'item_total_value_1': "'-753.6",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'de03623c-af10-4cef-8c0d-c03f40697978',
    'identification_number': 96849860,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eb2a1c57-e048-483f-bad8-1cbf6cc6d4d0',
    'identification_number': 103704728,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19314b28-9844-4cb5-babe-a2b68972272e',
    'identification_number': 36897140,
    'item_consumption_1': 721,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 281.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '606691d3-3f6f-44c8-a0d9-46e82e650096',
    'identification_number': 106869132,
    'item_consumption_1': 5111,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1994.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e44db668-6cde-47e4-a2ee-14c01d6f9197',
    'identification_number': 103630678,
    'item_consumption_1': 170,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 72.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a331480-6c5d-4a86-85b6-b04536e29d8d',
    'identification_number': 109697693,
    'item_consumption_1': 2205,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3335.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81bd530c-bf6b-40a7-ad83-14b9c58e2811',
    'identification_number': 26609517,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-317.56",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '83344583-4eb4-496b-a3b2-5401a1269006',
    'identification_number': 35778962,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd618fa51-1710-414b-abed-382100803547',
    'identification_number': 98642910,
    'item_consumption_1': 14677,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5716.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4274bf21-9c0d-491d-b268-74a574fa60b0',
    'identification_number': 935371590,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fd7217d0-99ab-43e2-a70a-7a95368883a7',
    'identification_number': 44805853,
    'item_consumption_1': 2396,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1399.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '122dc078-f7f8-493a-8244-cdf342d8d2b1',
    'identification_number': 21718989,
    'item_consumption_1': 271,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 158.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '470ba352-1dfe-42d6-8735-db085a76c256',
    'identification_number': 103034579,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '698a830e-8196-4cd5-abaf-d5ffb794945c',
    'identification_number': 58745050,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4066d772-69db-4d66-9396-a66b3f746e9e',
    'identification_number': 14693100,
    'item_consumption_1': 18.79,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 879.94,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '83ae08be-a8c9-420a-b7ef-c7be64ea6662',
    'identification_number': 69968705,
    'item_consumption_1': 8487,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3307.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34ec92ac-e686-4d6e-80fc-660f4f80f5a7',
    'identification_number': 77411609,
    'item_consumption_1': 12.82,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 305.77,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3754b544-3c81-4464-ada0-46d128d6c535',
    'identification_number': 69758409,
    'item_consumption_1': 400,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 233.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '175d327e-1c14-474c-b4b4-f7e37d4c7086',
    'identification_number': 35436778,
    'item_consumption_1': 2554,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3863.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10c28c66-e41f-4718-8bc0-845d9b167f38',
    'identification_number': 48875325,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f60f7526-e744-4203-a09c-2867e55e92c9',
    'identification_number': 35119624,
    'item_consumption_1': 207117,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 26435.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '651e9e95-3458-445c-aac8-41094d7d3fba',
    'identification_number': 111267366,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 4.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '24582f13-a375-4538-a317-703e42bff516',
    'identification_number': 12399752,
    'item_consumption_1': 8354,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1066.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '620af150-f8ed-428e-88f2-e943ea11cd4d',
    'identification_number': 19541627,
    'item_consumption_1': 133.72,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3889.37,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd9add73b-7756-4acf-aaae-f396d858b530',
    'identification_number': 56697198,
    'item_consumption_1': 10.88,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 316.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ba26a17a-a7d7-40af-ae31-69e6f9ac4668',
    'identification_number': 14449005,
    'item_consumption_1': 3200,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1357.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48c2b7eb-d3b9-4201-ad88-1dcc21d39a46',
    'identification_number': 14619555,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1665.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b13ac7d-0f13-4d59-b04c-0212ce283ec2',
    'identification_number': 20700776,
    'item_consumption_1': 11703,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4572.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e9a5861-99d8-4eae-8488-b11329027af5',
    'identification_number': 62508679,
    'item_consumption_1': 6715,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 857.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e173af6-2a62-4ed4-84b2-b60c1f2ea341',
    'identification_number': 106761161,
    'item_consumption_1': 2031,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 794.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ed3935b-c5c7-4627-b448-5af9c9e5e67e',
    'identification_number': 41739043,
    'item_consumption_1': 2399,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1017.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9698bcc0-45cb-4f12-927b-0280a374c890',
    'identification_number': 68902824,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 58.94,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '99a96d7c-cedb-4987-a272-d1784abd2d77',
    'identification_number': 68902824,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5109.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4b4a804-d7c4-47d7-9f80-effcd9f3bcc0',
    'identification_number': 56705514,
    'item_consumption_1': 9654,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3592.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee9a620f-2dd3-4c77-adb7-b13b9bb32194',
    'identification_number': 95566651,
    'item_consumption_1': 47.97,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1395.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '831bd8d2-c89d-4238-9405-50f2b61ca863',
    'identification_number': 80788319,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23c912a2-f81d-437c-a00b-f4b93cdc69c1',
    'identification_number': 927293026,
    'item_consumption_1': 658282.8,
    'item_description_1': 'Consumo em kWh Fora Ponta',
    'item_total_value_1': 342955.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7010c163-941c-4c29-a058-79b7caebbca0',
    'identification_number': 12743542,
    'item_consumption_1': 23164,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8620.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9f1cb18-b7d0-4ab6-8b26-39da1195c928',
    'identification_number': 108628990,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '498abe6c-7810-48b6-940d-fb51595102ad',
    'identification_number': 105799653,
    'item_consumption_1': 128,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 50.08,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '836523d1-f798-4932-9cc4-b8fb35dc9336',
    'identification_number': 107740516,
    'item_consumption_1': 15809,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 2017.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26bafb86-dd8a-4004-9abb-c1d21d925b5d',
    'identification_number': 72920467,
    'item_consumption_1': 4904,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2079.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec72fb2f-c9f6-40bb-b9fa-2d265551b42b',
    'identification_number': 27923460,
    'item_consumption_1': 773,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 302.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0cbadfe6-5ffd-4ad7-9dd2-65bfce1b3efd',
    'identification_number': 62362801,
    'item_consumption_1': 18.04,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 844.83,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2a996565-1545-4585-a034-b50642dab4c9',
    'identification_number': 23179554,
    'item_consumption_1': 84,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 35.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3482571d-484d-4f13-8ebb-38ee047ceeae',
    'identification_number': 87263220,
    'item_consumption_1': 179,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 75.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21ecb7a1-97cd-4ea3-96e1-27421b580390',
    'identification_number': 105088730,
    'item_consumption_1': 5437,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2127.35,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e8382f74-3411-4982-bcc0-72a8a6300a89',
    'identification_number': 86283154,
    'item_consumption_1': 4008,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 511.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d63d656-b64e-4fef-a61e-4b82a5d4f8be',
    'identification_number': 105680370,
    'item_consumption_1': 766,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 324.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72a90578-05a3-4bb0-ad1a-174c0f8fe8da',
    'identification_number': 14138069,
    'item_consumption_1': 1946,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2943.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22d6eea9-9162-48b7-a4e3-5b1b37d60b04',
    'identification_number': 85780499,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bc3d3938-f6fb-4c51-8578-5ef2a8399e11',
    'identification_number': 72062649,
    'item_consumption_1': 2767,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1080.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21827641-07c9-4ef4-80dc-cf4230f4dda5',
    'identification_number': 44606931,
    'item_consumption_1': 1455,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 567.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f39a9bd7-a0fe-420c-8998-8c133d5fc283',
    'identification_number': 77221630,
    'item_consumption_1': 106,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 45.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84ceec97-5c4c-458b-b108-f33f09f97c5b',
    'identification_number': 78371023,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.67,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0cafce2c-f49b-46b9-9944-26967af65251',
    'identification_number': 108726983,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 130.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22532077-97e5-420a-b3b8-be7dc797a829',
    'identification_number': 55456812,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3f6b1cc8-e59d-4813-9c0d-0e1407667d49',
    'identification_number': 104923407,
    'item_consumption_1': 386,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 150.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fcbe22e8-e0c9-45e6-8b33-adb62f7e9673',
    'identification_number': 59044659,
    'item_consumption_1': 12.27,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 356.87,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a45316bb-6af2-4f69-83e4-1091067d9498',
    'identification_number': 96409312,
    'item_consumption_1': 1776,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 691.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '575d5f9b-dd8d-4e35-bccf-a57102be2289',
    'identification_number': 14819821,
    'item_consumption_1': 3289,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 419.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9e251d6-7d7a-4f18-8577-d1a6231b8bfc',
    'identification_number': 103706992,
    'item_consumption_1': 7943,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 12016.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4dab409-7320-47be-8a6a-9c537d7c235f',
    'identification_number': 18339603,
    'item_consumption_1': 7.94,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 461.88,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '9673fff9-c990-48ef-96ea-6b037f55901b',
    'identification_number': 17714796,
    'item_consumption_1': 458,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 178.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9079f612-511e-449f-a455-557f2790b395',
    'identification_number': 18188109,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-326.78",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'e0226667-084e-483d-98d2-6569a51a0802',
    'identification_number': 90604679,
    'item_consumption_1': 30287,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11271.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15e442cd-46b4-400d-a930-7c7548b08dde',
    'identification_number': 35718064,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b1fb7690-ae5c-48a9-8bdd-8f1e53334506',
    'identification_number': 18898068,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 88.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bb7388bd-16f2-4204-a880-0ede21e7f942',
    'identification_number': 108630323,
    'item_consumption_1': 1326,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 516.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56d709b6-4db9-4581-adae-054ae4fbd9a9',
    'identification_number': 948743595,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5cfe031a-512c-43c1-a58c-2e5ce191ba8c',
    'identification_number': 11679751,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a25a1fd2-56b0-49e2-adce-466d27ceadbd',
    'identification_number': 105025437,
    'item_consumption_1': 3838,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1631.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec9e635a-dcff-471a-9464-2ae08932d667',
    'identification_number': 41531728,
    'item_consumption_1': 1552,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 606.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8608c327-83d8-4d80-bace-03da5d23a9a2',
    'identification_number': 18502016,
    'item_consumption_1': 453,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 192.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a33b247-b0ee-439f-93ce-d123cbc347e2',
    'identification_number': 111113962,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a48679f-a9a1-4276-b545-1e11493a051d',
    'identification_number': 15818284,
    'item_consumption_1': 1689,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 658.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51fe133d-b595-4c01-b67d-8a8d08e5ecdc',
    'identification_number': 92056920,
    'item_consumption_1': 389,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 227.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61a1691a-46f2-4853-952d-7a9d0541827a',
    'identification_number': 45603391,
    'item_consumption_1': 7612,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2832.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36ecfce4-5815-44bc-a3ef-50390a9e2ff2',
    'identification_number': 105805572,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 207.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26fb2f04-7611-40f3-99f8-f5178ef533c1',
    'identification_number': 108332438,
    'item_consumption_1': 271.65,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 15513.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '799d511f-d3d9-4f9b-9e88-98c1c3e1fae7',
    'identification_number': 5558735,
    'item_consumption_1': 12784,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5411.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a778c2a-511b-4d25-a283-e1df28e1168f',
    'identification_number': 106087266,
    'item_consumption_1': 2561,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1495.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0987b9bc-1a23-45ba-bc53-1e9927d41c7a',
    'identification_number': 97837350,
    'item_consumption_1': 72.27,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2102.02,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '38f5e42e-54e1-408d-8c9c-864ed65db641',
    'identification_number': 109782909,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 12.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e7ddab5-23c2-45a2-ae96-f4ba61e44cf5',
    'identification_number': 105761338,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7684cd1-d4ca-42b1-a539-9135f7c2e8dc',
    'identification_number': 22866450,
    'item_consumption_1': 101,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd511ac88-9176-44d1-95d0-d441b57314ad',
    'identification_number': 71978097,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9b323e76-be2e-40cd-85ed-d1e6ff6126ec',
    'identification_number': 95159320,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 112.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dd0511a0-835e-4781-9333-dc89a89a6654',
    'identification_number': 110483456,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9459d695-b21d-4a85-add0-9df98d665ba8',
    'identification_number': 108989887,
    'item_consumption_1': 330,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 128.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10b7bc65-c18f-47ad-b515-eabcbc681fa5',
    'identification_number': 10856501,
    'item_consumption_1': 7524,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2935.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82fc9e7a-c813-4001-9bc1-7c7ad0d72c82',
    'identification_number': 82142068,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f5ccffb3-af66-4370-af33-415e1d38f003',
    'identification_number': 58737863,
    'item_consumption_1': 1737,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2627.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b91e0119-e833-4332-b13b-a3496cbd6f16',
    'identification_number': 17775418,
    'item_consumption_1': 3551,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1384.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1e010a9-d37b-4fbc-91cf-7f0bdbf80602',
    'identification_number': 69671516,
    'item_consumption_1': 3283,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1392.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03f370ca-039b-4e1c-9028-94fc12457862',
    'identification_number': 15152731,
    'item_consumption_1': 213,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 90.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31b5d594-0570-40bf-8aaf-c9019f76a3cc',
    'identification_number': 80817610,
    'item_consumption_1': 1133,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 481.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86e6b74e-0cf0-4484-b974-03cef7595740',
    'identification_number': 18339654,
    'item_consumption_1': 34.61,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1976.57,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c908b035-c36a-4b7f-8b63-6d70b40d3910',
    'identification_number': 103630678,
    'item_consumption_1': 170,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 66.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63b18dd6-0d5e-4e19-bea3-f44e697ee80f',
    'identification_number': 76785378,
    'item_consumption_1': 503,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 213.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b199f10-b91a-4299-9b53-ff80dd09a895',
    'identification_number': 43609902,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0459deab-6a11-4169-91a1-6b5bcb71097a',
    'identification_number': 102846529,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '977263df-500d-432b-8d72-344f5ff0a777',
    'identification_number': 97814105,
    'item_consumption_1': 1034,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 438.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'baa2ab2a-4722-4ac9-804c-6ebf963e5d02',
    'identification_number': 101073593,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-2393.73",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'ce2362af-9c99-401f-987c-d893f3132f2c',
    'identification_number': 34346007,
    'item_consumption_1': 35.67,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1037.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ff9908a5-4b77-4838-b1c5-4d5767b84c3e',
    'identification_number': 14104709,
    'item_consumption_1': 49,
    'item_description_1': 'Demanda faturada',
    'item_total_value_1': 1905.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4c8577ba-d921-44ea-9cfc-c4cb59152218',
    'identification_number': 15186075,
    'item_consumption_1': 2470,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 963.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f5400fc-8e02-4469-bf4e-db4d693454f9',
    'identification_number': 40435288,
    'item_consumption_1': 14.93,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 356.11,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'eaaceeff-0910-4fc4-a8e9-3cf8225a43a9',
    'identification_number': 28528646,
    'item_consumption_1': 5075,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2963.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e65a9e20-fdc9-4478-92cd-8d720b17ebe2',
    'identification_number': 97072494,
    'item_consumption_1': 5088,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 649.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3901996d-f813-415e-ac3c-dd20683f9dec',
    'identification_number': 107367807,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '80af3154-b7eb-45d4-86e9-2f91757cb85d',
    'identification_number': 26010658,
    'item_consumption_1': 1428,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 605.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf84b79c-2096-4e14-9b03-56af69a3b280',
    'identification_number': 14039362,
    'item_consumption_1': 2173,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3287.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '801da326-d6fd-4b00-ba69-92a5e8e19bf3',
    'identification_number': 22902694,
    'item_consumption_1': 1405,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 820.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a8e3868-db74-44f9-acc7-798ec41ae622',
    'identification_number': 77411609,
    'item_consumption_1': 262894,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 97835.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8b269a9-31f1-4d92-b696-d35577d90c3b',
    'identification_number': 78084113,
    'item_consumption_1': 44.05,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1281.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '66afb230-d76c-4f17-826b-fcb3baca8f3e',
    'identification_number': 32074123,
    'item_consumption_1': 1.68,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 40.09,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '48cd95e9-eccd-4a46-9ff0-513a172ce05c',
    'identification_number': 948747554,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5e83615c-bb38-4445-b795-2cc5c5015379',
    'identification_number': 45986010,
    'item_consumption_1': 769,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 300.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7dc663d-4067-4eaa-bf5b-87fce0e5d99e',
    'identification_number': 9063005,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b849b4a-e6e5-4a15-89dd-8bdbfca556a7',
    'identification_number': 94029539,
    'item_consumption_1': 31,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 12.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '720b0c96-3c8f-4858-90bf-239501dc49ca',
    'identification_number': 31686729,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '707cb330-23da-482c-be87-36f16690326d',
    'identification_number': 17228328,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '11297a2a-26d8-4a85-8a82-33db2951998e',
    'identification_number': 105904872,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bb164cab-51aa-4588-9ee5-32c1c135a29f',
    'identification_number': 94763607,
    'item_consumption_1': 9015,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3521.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c33ade9-326a-4ac7-bf0c-7271307cca7b',
    'identification_number': 19450133,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '580438b0-cb10-4fd9-aecc-560c48d5ea1e',
    'identification_number': 19299281,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '446d7369-e21a-4903-99da-8fa88d7d17c1',
    'identification_number': 10145214,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c5c53693-12a9-4b0f-8273-4f98debf9f5f',
    'identification_number': 77111311,
    'item_consumption_1': 2285,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 967.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '842e3fd1-4a2c-44bc-983e-db52c0ab3494',
    'identification_number': 90453450,
    'item_consumption_1': 44221,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5644.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d5086ec-ace8-4a18-8406-014d58c1ad08',
    'identification_number': 63572486,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91f15b49-b3f1-4bd7-ad92-cf5878fd1da1',
    'identification_number': 15714063,
    'item_consumption_1': 2381,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1011.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5caaa46d-979c-4318-b539-ca6fb12a6133',
    'identification_number': 85205672,
    'item_consumption_1': 38941,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4970.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ff44339-4c38-4125-80d2-0f1c1b5d6d9f',
    'identification_number': 20540205,
    'item_consumption_1': 220,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 85.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '805739db-68ea-4040-8f39-c8159b84384d',
    'identification_number': 19281404,
    'item_consumption_1': 7868,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3332.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1e04e0f-fcf3-41a5-9820-d4af3b2bfe12',
    'identification_number': 14824558,
    'item_consumption_1': 2737,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1160.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09cbc709-9621-4c65-bfe1-1171bb4c9932',
    'identification_number': 98253336,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-68.26",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'a5b07ccc-d7d9-4fff-8d38-9cab3ab2acb4',
    'identification_number': 102684650,
    'item_consumption_1': 17.51,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 509.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '519d0011-6624-4b94-a09d-f36fb4107d1d',
    'identification_number': 42034515,
    'item_consumption_1': 114.73,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3337.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '775099d0-8891-4a23-ab72-545e383429d4',
    'identification_number': 19429525,
    'item_consumption_1': 7.63,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 181.98,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5c6e0287-ec6a-46b4-9871-eda476601605',
    'identification_number': 12424188,
    'item_consumption_1': 7901,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1008.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6955c29a-8919-43e4-afd3-5ca1680ae2f3',
    'identification_number': 18284507,
    'item_consumption_1': 1078,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 457.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5df572ae-1297-4b06-a39c-564dcc382def',
    'identification_number': 16879562,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.54,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '579fc4d0-690b-4cc8-9b37-a609a6cd08cb',
    'identification_number': 109697693,
    'item_consumption_1': 2205,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1287.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2db1f669-114f-49de-93a7-db38d8e13ed8',
    'identification_number': 111115175,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cce1e52d-b6de-49a3-829f-cc55a9f41642',
    'identification_number': 31945252,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9243ae79-fb43-4f7c-ad7a-c2e54ed6e220',
    'identification_number': 67536913,
    'item_consumption_1': 1328,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 775.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78db5e23-5c19-4d27-9f19-b699fed1c43b',
    'identification_number': 91980380,
    'item_consumption_1': 7834,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3063.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27d3149c-5505-4481-bdb7-cc19c4545d04',
    'identification_number': 45907404,
    'item_consumption_1': 3757,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 479.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b965497-a3c3-43c3-ada5-a5c46ba9695c',
    'identification_number': 94384126,
    'item_consumption_1': "'-971.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-311.05",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '9c62bbf1-a4cb-4036-a17a-8b534e5a909c',
    'identification_number': 21108218,
    'item_consumption_1': 1890,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 739.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c944cddb-be43-4646-8ff3-785901e784dd',
    'identification_number': 91804701,
    'item_consumption_1': 4843,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1892.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb6151a7-0ba0-483a-a721-6840169ead4c',
    'identification_number': 17216168,
    'item_consumption_1': 11669,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4552.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78d20741-30fc-4dfe-bfd2-1a148fd54355',
    'identification_number': 59742631,
    'item_consumption_1': 903,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 352.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a604d74-5690-4e58-9020-a50f19419018',
    'identification_number': 106469940,
    'item_consumption_1': 2066,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 807.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e38c5ae-30d6-48eb-895a-1744d3c57757',
    'identification_number': 104329530,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b2acbaf-a6a7-429b-bb20-9d978e58b0e4',
    'identification_number': 102622639,
    'item_consumption_1': 14287,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5568.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6d033f0-060a-4010-b2ca-72d85f9f3faa',
    'identification_number': 43001300,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.97,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b8f9dfda-350a-427f-95b8-be0fcf7d0be1',
    'identification_number': 99929929,
    'item_consumption_1': "'-555.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-177.78",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '8ee3a277-26c5-403b-b743-8dee66ac4468',
    'identification_number': 15576388,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a1607ef5-13f8-4d53-b92e-749de1c803c4',
    'identification_number': 105479551,
    'item_consumption_1': 2328,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 910.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a75f679-f998-4bc2-8983-45b6af0b9051',
    'identification_number': 80788319,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '476e50fb-505b-475b-a9f7-1e57295c97b8',
    'identification_number': 19451610,
    'item_consumption_1': 33482,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 19554.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c7aa5cd-b7b4-4d54-819a-bcbbbd4c4ad0',
    'identification_number': 14978229,
    'item_consumption_1': 3160,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1338.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67f48e21-aeac-4ab4-9cca-053f8283eb1e',
    'identification_number': 103802045,
    'item_consumption_1': 414,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 161.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1faee56b-2f3f-4d52-91c7-a3afb4313ba2',
    'identification_number': 49074377,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1f6c755d-1f77-426d-985d-6e147d2d932b',
    'identification_number': 19311311,
    'item_consumption_1': 9212,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3910.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae3c45d6-a17b-4648-9fbf-157890fa808e',
    'identification_number': 92096115,
    'item_consumption_1': 1.1,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 51.51,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '74df8d79-e95e-4ff2-90e1-954fbaae7684',
    'identification_number': 948928709,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '07bcbcd9-599a-4647-8dc9-a5d685798b80',
    'identification_number': 923310436,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20a6adfb-e0fc-41be-aed1-dd6dd35bd9a7',
    'identification_number': 73564630,
    'item_consumption_1': 8680,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3386.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b51a52e-1349-464c-bda1-3cf1c6d46ff3',
    'identification_number': 19304749,
    'item_consumption_1': 1682,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 656.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e80d4929-d0ce-4c5d-9834-615d1bb96595',
    'identification_number': 45985553,
    'item_consumption_1': 809,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 316.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa9b3bb4-daaa-4772-8ff6-5f6193a8d24b',
    'identification_number': 17187923,
    'item_consumption_1': 5943,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 758.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93181e5a-9095-4341-9857-62c2a73e4d51',
    'identification_number': 105557668,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c07abe18-5a62-4f72-80ec-53f38e122b8e',
    'identification_number': 18036244,
    'item_consumption_1': "'-4.526",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-1449.12",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '222b371e-c76e-4bef-bc4b-64087b12b025',
    'identification_number': 97455121,
    'item_consumption_1': 207,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 87.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67f61adf-489c-4610-aa02-601f3452cb71',
    'identification_number': 99801736,
    'item_consumption_1': 0.67,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 15.99,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '32913fbb-a913-40c0-8c2b-0c558519d194',
    'identification_number': 59422009,
    'item_consumption_1': 9.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 275.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '82ce16a5-5e3e-47b3-bc41-0be55949bcfa',
    'identification_number': 80982379,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6d909585-f731-4596-952f-29bea2d163b5',
    'identification_number': 33124302,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.99,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7383e20d-70bf-47de-b6ad-e5237b13fae6',
    'identification_number': 16374525,
    'item_consumption_1': 1852,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 785.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4111761-6814-470e-90c9-0272d9e3cc3a',
    'identification_number': 100059856,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47c98431-5b2b-4315-9162-6a81ef9a3771',
    'identification_number': 47899816,
    'item_consumption_1': 11320,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1444.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c713afa2-16c9-4718-933b-a6d1a7b1552e',
    'identification_number': 16877454,
    'item_consumption_1': 2680,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1138.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7269496e-564e-44a5-b5b9-b1877cb4c68b',
    'identification_number': 105653691,
    'item_consumption_1': 1603,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 936.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ce24566-03f9-461c-bcb6-becb2fd90c28',
    'identification_number': 56582854,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8418309d-ff14-465c-b84b-92f46f003503',
    'identification_number': 106755030,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd9dc076c-7d82-434d-93b9-3618ea39352d',
    'identification_number': 6270476,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '42bae621-e8a6-47e2-8677-d1d0dadbb1d8',
    'identification_number': 102259178,
    'item_consumption_1': 7576,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2819.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6dcbcf5-9a02-4190-baa2-2dd7026e4e37',
    'identification_number': 34851151,
    'item_consumption_1': 23754,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8839.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '821c6922-164c-4301-8088-897cd9440b17',
    'identification_number': 20444060,
    'item_consumption_1': 2120,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3207.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60bf61ab-8b32-4842-8e95-27c6513e36b7',
    'identification_number': 18671870,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3109110b-b9e3-4919-bc22-12533c80f680',
    'identification_number': 35436778,
    'item_consumption_1': 70.11,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2039.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c5124d00-084b-4c84-b4c7-ef6adfc35e0a',
    'identification_number': 110006623,
    'item_consumption_1': 1289,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 547.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '262feb2b-b467-4def-930e-1730247e79b0',
    'identification_number': 68079257,
    'item_consumption_1': 1633,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 953.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b656f542-ba41-42e5-b9aa-c19ebb1ca0e3',
    'identification_number': 73615145,
    'item_consumption_1': "'-1.221",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-425.26",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'e2c19123-d759-437e-976a-26e63977611c',
    'identification_number': 11679751,
    'item_consumption_1': 373,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 217.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a6ed380-966f-414e-a84e-9d126e167d93',
    'identification_number': 107941392,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '805f7ac5-0685-4936-8f25-73e16b919917',
    'identification_number': 18916996,
    'item_consumption_1': 3429,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1339.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa3a0a63-898a-4401-aaf2-8d98510dd2cc',
    'identification_number': 5683840,
    'item_consumption_1': 15663,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6110.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6036ba2-290e-4be7-b4e8-6c0160de697a',
    'identification_number': 32191871,
    'item_consumption_1': 11509,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4283.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e904fcc-4a68-45bc-9b32-368a3ccfedd7',
    'identification_number': 67917283,
    'item_consumption_1': 41.11,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 2391.44,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '6dda5248-1ebd-4d5f-a689-b7d72c0f3a93',
    'identification_number': 27516440,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e3d8be52-5dc2-4d1c-9203-5e21122a5f83',
    'identification_number': 47989050,
    'item_consumption_1': 33421,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12437.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd516dcd9-63ef-4e78-8395-78369c7320a9',
    'identification_number': 92735746,
    'item_consumption_1': 7271,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3081.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b30d3781-7795-4847-8d08-979101df4a98',
    'identification_number': 110992393,
    'item_consumption_1': 2425,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 946.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29e0eff8-48a9-4a4f-b323-4b24c743c396',
    'identification_number': 96072237,
    'item_consumption_1': 2468,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1047.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c4ee803-b76a-404d-a15a-35236c2992ed',
    'identification_number': 43195300,
    'item_consumption_1': 6080,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3550.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f072670a-8965-4796-8990-57ba3c526917',
    'identification_number': 44010141,
    'item_consumption_1': 3005,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4546.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9e227c2-e186-4536-9c4a-5cfdc3d120f6',
    'identification_number': 108167879,
    'item_consumption_1': 32956,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4206.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2da318b6-3d65-4555-9557-14a8ff89c444',
    'identification_number': 78084237,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b2cc9d52-b759-4d3f-93c6-8c8efb01a1de',
    'identification_number': 44024924,
    'item_consumption_1': 236.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6865.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0fb29ee6-6aab-4228-b4a7-261e546a8cd3',
    'identification_number': 103904212,
    'item_consumption_1': 5105,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1993.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55cec86d-79a8-4022-9b1f-a07b7e052b80',
    'identification_number': 15397009,
    'item_consumption_1': 11,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 207.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd97c5a37-c8e5-4feb-9ae1-30dd7266bdc4',
    'identification_number': 94339546,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '955975af-8a90-43ce-b51f-e5235246e5e8',
    'identification_number': 32403011,
    'item_consumption_1': 63.27,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1840.25,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6f105416-1d9e-4b50-885b-24502595b460',
    'identification_number': 99102935,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 112.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd3a4aec3-96d2-47a2-ac8c-f61d787056d1',
    'identification_number': 36033111,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 16.82,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5db6f211-04d8-4028-aef0-780501c57a4a',
    'identification_number': 77169689,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-6.55",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '7970a3c1-aa8e-4bba-9888-23ea02e18861',
    'identification_number': 19429525,
    'item_consumption_1': 2119,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3205.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd679b90b-c186-422e-b9c2-2da653714ea7',
    'identification_number': 13028855,
    'item_consumption_1': 4.01,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 95.67,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '841bbb39-5399-400f-a922-0b96dd6f4be2',
    'identification_number': 48511978,
    'item_consumption_1': 38597,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14363.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b748ef7-df15-4788-8ccb-3ea44aa9ba11',
    'identification_number': 105105945,
    'item_consumption_1': 10890,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4253.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '060a0af6-005a-423a-9966-3feaac17525a',
    'identification_number': 104455250,
    'item_consumption_1': "'-13.822",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-4428.44",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'd525746b-a5ae-4c46-a8b5-8d244050b08c',
    'identification_number': 18270786,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '11b7abaf-896d-4749-9ab7-6e27a40e1174',
    'identification_number': 104611537,
    'item_consumption_1': 1179,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 460.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24061bbe-b6e0-46cc-aa6b-2fb40e27b8ea',
    'identification_number': 12187569,
    'item_consumption_1': 280,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 118.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2992aa88-ce7b-4738-bcb2-6d74345deffc',
    'identification_number': 32629095,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e0306884-61fd-4c6b-a55a-7f7aededaa35',
    'identification_number': 17933404,
    'item_consumption_1': 4066,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1725.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96ce86db-783d-49ad-93ff-9f04b2b822aa',
    'identification_number': 923309735,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6fb05ec2-d5ec-4bf5-940a-c7eda62fd7f0',
    'identification_number': 98264370,
    'item_consumption_1': 481,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 204.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '150536c7-fef6-486b-a619-c8bb007b918a',
    'identification_number': 68502982,
    'item_consumption_1': "'-1.588",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 08/2023',
    'item_total_value_1': "'-507.43",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'e8771ef2-75b0-47bb-96d4-586c963329f2',
    'identification_number': 26422590,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c28c063a-03a7-4718-b405-d537d8cebfe7',
    'identification_number': 106729985,
    'item_consumption_1': 157.97,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 3767.67,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '81010dbf-a9a3-4e3b-bc8b-b3b8bff9c240',
    'identification_number': 14642484,
    'item_consumption_1': 9.81,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 459.42,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '647b9552-f3a4-4fb6-a782-ae2bca08b90a',
    'identification_number': 87246996,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b3f8854-23b0-4d12-b5d6-96dadfc57e05',
    'identification_number': 59850817,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b54a2aa-b86c-475d-b5c5-681735facb6a',
    'identification_number': 107480409,
    'item_consumption_1': 5349,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2088.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5368ed00-e881-4c33-86a4-df0d813ed167',
    'identification_number': 19450745,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6a8ec2b2-1ca9-4b38-aa90-a49e2c1587b7',
    'identification_number': 42496390,
    'item_consumption_1': 0.56,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 13.37,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '44897ffa-5642-4e38-ae77-b98458727969',
    'identification_number': 27057852,
    'item_consumption_1': 2871,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1220.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c34e886-5af3-4a57-921b-8da92828a48a',
    'identification_number': 18456669,
    'item_consumption_1': 639,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 966.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8a30d1b-d739-40ff-badc-d8921a8bcf00',
    'identification_number': 33800677,
    'item_consumption_1': 96.72,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 5523.68,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b03cb56c-f8e6-49f4-95d0-23db68a05477',
    'identification_number': 78564662,
    'item_consumption_1': 20148,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2571.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ead8e573-1ae6-4710-b362-c8b8a2a85603',
    'identification_number': 20830807,
    'item_consumption_1': 353.37,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 10278.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5312c420-866d-4605-aa8b-1e172bf8876b',
    'identification_number': 92710123,
    'item_consumption_1': 1663,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 704.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18d9551a-e16b-488f-aa57-e99c426b55c7',
    'identification_number': 91862833,
    'item_consumption_1': 5756,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2251.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '645d40ef-bd4f-4376-b806-d6e42961f638',
    'identification_number': 48367583,
    'item_consumption_1': 1783,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2697.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bc0c099-3292-4d3f-8e19-caa988ae1575',
    'identification_number': 97425907,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7700c8b9-8ca7-4011-9d97-116e291909db',
    'identification_number': 24083542,
    'item_consumption_1': 41019,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5235.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74d86f25-1ffb-4e03-9d87-a4f8dc5bd155',
    'identification_number': 18187749,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d1b320f-68d2-4804-bbb0-b39772fcd985',
    'identification_number': 10877100,
    'item_consumption_1': 2676,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1135.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '315a5970-dd46-4cf4-ab17-bbff957ec0b3',
    'identification_number': 91868041,
    'item_consumption_1': 3181,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1240.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51a0a440-7b7d-4776-9f6a-69c38ef7a234',
    'identification_number': 75403943,
    'item_consumption_1': 2641,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 1033.35,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e8807743-d3f9-4fd7-8bab-4127879191cd',
    'identification_number': 32234058,
    'item_consumption_1': 3583,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 457.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be41f9b6-bb3d-4921-9722-7bc9e25b40fd',
    'identification_number': 103363165,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '017ed09a-464d-481d-96ed-5bb1079c4b78',
    'identification_number': 100641555,
    'item_consumption_1': 247,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 104.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '452f9b21-b6f5-43f8-a24e-0dbf61e67169',
    'identification_number': 70556997,
    'item_consumption_1': 2836,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4290.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de25c739-804f-4a8e-84ac-59ac5adfdbc2',
    'identification_number': 18036244,
    'item_consumption_1': 17874,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6979.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5aa452a0-9539-401d-8adb-1636ec8af6e3',
    'identification_number': 68904401,
    'item_consumption_1': 3040,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1775.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b5906e2-7e41-432e-b5f1-68cde738d2f9',
    'identification_number': 101805829,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 128.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f250e4fd-975f-4431-955e-3187be5894a7',
    'identification_number': 69851000,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 120,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ab84838b-8f1b-4897-a290-5812ae96df2d',
    'identification_number': 39466787,
    'item_consumption_1': 3183,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 406.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9df4ce96-40dd-468b-a0b8-769118fe4066',
    'identification_number': 43752187,
    'item_consumption_1': 449,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 175.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1aa0f107-fccb-47a2-91a6-5e57e2630e8e',
    'identification_number': 15885348,
    'item_consumption_1': 6060,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2365.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '823d6a1a-3773-4304-af5e-6d450643fdd7',
    'identification_number': 100854982,
    'item_consumption_1': 6,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 349.03,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'efefa81c-de8f-4eef-9fd0-95aee8fe2548',
    'identification_number': 100918719,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f50a37d3-8ce9-4eef-90cf-7c2d6128f3d0',
    'identification_number': 97427063,
    'item_consumption_1': 4341,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1696.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34eea457-8477-4a8a-8994-b9c758745aa6',
    'identification_number': 75402351,
    'item_consumption_1': 27.33,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 794.91,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b57c61ed-90ed-4cda-9509-de257533d09f',
    'identification_number': 16973720,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e5f42669-acd7-4299-8eea-4b64cae2b9f1',
    'identification_number': 18898602,
    'item_consumption_1': 2702,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1054.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4ae8980-85e6-4af8-bbf7-1bde318952ee',
    'identification_number': 70556997,
    'item_consumption_1': 24284,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9037.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91ffeecc-96aa-4360-9517-5c6017c910df',
    'identification_number': 92710123,
    'item_consumption_1': 1663,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 648.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9db6b101-7900-43c5-ae63-bd404dc0a611',
    'identification_number': 83865349,
    'item_consumption_1': 970,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 412.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c440bfa2-330e-4aa9-a48b-0a5d4039d78e',
    'identification_number': 39365980,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab8700f6-3f67-4169-ac91-19ec208aa1ab',
    'identification_number': 103477217,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '69a69e4f-44d5-4d15-93dd-894698d90f7f',
    'identification_number': 22866450,
    'item_consumption_1': 101,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50d3e7f3-709d-4a5f-9560-4b0c11359cbb',
    'identification_number': 71982604,
    'item_consumption_1': 4166,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1767.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f47c361-e142-4da3-ac06-72a61646bfcc',
    'identification_number': 93842481,
    'item_consumption_1': 545,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 212.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc9e4c78-ded6-4a54-b1d4-5f57a3e989ef',
    'identification_number': 47108690,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6574288b-cab8-4225-a37e-610fd8019ba6',
    'identification_number': 35559217,
    'item_consumption_1': 137.66,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4003.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd163f139-024a-4a8b-b619-80bbb2fce4c0',
    'identification_number': 27271633,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a7eefd95-62fd-4a95-ae61-1ecc8e87874f',
    'identification_number': 40768538,
    'item_consumption_1': 1547,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 603.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1604d63-ea39-4b1f-a4ea-e98956a532db',
    'identification_number': 107742497,
    'item_consumption_1': 632,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 246.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93960080-7d93-487d-b6ca-e0071f96fcb2',
    'identification_number': 42127564,
    'item_consumption_1': 10590,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4496.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71a7b0d6-a63a-410f-ac9d-2b03589d2820',
    'identification_number': 20587147,
    'item_consumption_1': 8562,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3350.08,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6eba78ea-a99a-4f18-955e-9a070d2c9d47',
    'identification_number': 11310928,
    'item_consumption_1': 4380,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1707.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '946a2f39-2a5a-4756-965d-60d18c8c68c1',
    'identification_number': 106851217,
    'item_consumption_1': 2.06,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 49.15,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '66f5c3f2-e530-4de8-9376-384c4e3922b1',
    'identification_number': 80788319,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6996a545-7723-4f4b-a547-f305bc44ee59',
    'identification_number': 64167364,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c4e35e23-b8f5-4c09-aeaf-e1de68cf0ea6',
    'identification_number': 110479890,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 112.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e87fa44b-7961-4782-8dc1-e74abc6a8c85',
    'identification_number': 19450303,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 70113.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6e9c75d-c3c7-4605-a889-2cb57a0646ec',
    'identification_number': 41611187,
    'item_consumption_1': 9714,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 14695.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e68758a-ed9a-4a08-a1d2-0b7206eeb494',
    'identification_number': 16628063,
    'item_consumption_1': 1320,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 561.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '750abebb-ee18-4470-82dc-d4581961c20a',
    'identification_number': 70343411,
    'item_consumption_1': 1725,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 672.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '496ea800-8065-41d1-89d1-bcc3af1ce6c1',
    'identification_number': 68924186,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f66d14c-36be-4812-9afd-27cff336cc8b',
    'identification_number': 46590889,
    'item_consumption_1': 2064,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 873.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e16282e-a662-400e-95df-e3b79bbf753e',
    'identification_number': 14642484,
    'item_consumption_1': 34.29,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 997.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8551546b-e761-4420-8e34-27a29638733c',
    'identification_number': 94460566,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '31bff640-76eb-4aa0-adb8-b283a823aaaf',
    'identification_number': 109487877,
    'item_consumption_1': 6870,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 876.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c30d87e-7072-4136-aeed-4289120d5fa1',
    'identification_number': 107951533,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70b41614-be69-4554-b4f2-05a4bf90aad4',
    'identification_number': 20934947,
    'item_consumption_1': 3.95,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 94.21,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'bec2af54-e8b5-400a-a6bb-d4f337c2d9fa',
    'identification_number': 101401116,
    'item_consumption_1': 1500,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 586.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '719d177b-bb7a-474e-9cc8-cda267f3e6a0',
    'identification_number': 100456391,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e9ac7b0a-74aa-4eb1-a6e4-74aa703ec4bd',
    'identification_number': 63195666,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2ceb9ac9-7aae-4557-b6c8-83ce4881f80c',
    'identification_number': 19452900,
    'item_consumption_1': 16404,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 9580.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b09cb8e-f037-495f-9186-1bb4a7a62b40',
    'identification_number': 5679443,
    'item_consumption_1': 5717,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2231.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79ab5e6b-40ea-4e3f-a475-4390c4578b48',
    'identification_number': 41360869,
    'item_consumption_1': "'-171.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-59.62",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '193d8b42-e375-4c2f-a6d2-586175f6019b',
    'identification_number': 17889243,
    'item_consumption_1': 9040,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3843.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd95460d3-e164-43f8-a050-3f11c7b03f72',
    'identification_number': 93977352,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 68.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2c58da7c-b429-4b1c-9830-5009f67bfbe2',
    'identification_number': 61583758,
    'item_consumption_1': 523,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 305.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1a3100c-ea21-4ef9-9d07-5c5febcc4eed',
    'identification_number': 29396735,
    'item_consumption_1': 5280,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2057.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c0b64c5-f12a-4e5c-b07f-3387f3a9d30b',
    'identification_number': 8708320,
    'item_consumption_1': 13318,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5643.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2dcb88eb-ca77-45dc-9fc6-1d484250361a',
    'identification_number': 6286755,
    'item_consumption_1': 2824,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1199.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ecd0496-0072-49d4-acbe-9c510d33c7a7',
    'identification_number': 948743645,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c0990936-cea5-4b35-ac11-f2f893fc1e0d',
    'identification_number': 100741770,
    'item_consumption_1': 2902,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1230.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0640f99-5275-4ea5-b0b0-8cc0377d2c54',
    'identification_number': 59541288,
    'item_consumption_1': 571,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 333.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5066a6d9-ac4e-485a-8dc4-f29ac0358827',
    'identification_number': 81267843,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0b9461ad-8976-4cda-8a9e-ee23ac36f3b9',
    'identification_number': 106851217,
    'item_consumption_1': 27.94,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 812.64,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0d135c83-64cd-4063-8382-26ee4471eb26',
    'identification_number': 105797634,
    'item_consumption_1': 10.13,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 241.62,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e077ce9d-33a0-43cb-9efc-32b08de2f989',
    'identification_number': 98713299,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a81c5745-06f3-4b37-8ddc-14d16c73dc21',
    'identification_number': 16166558,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c511582f-6bca-48af-ae90-d0c4efd8c40e',
    'identification_number': 97614858,
    'item_consumption_1': 412,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 623.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0774f3af-b57b-4274-ade0-1f37d0174e3f',
    'identification_number': 4334671,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7e754d1e-c3c8-462b-a686-2a8d0c50496a',
    'identification_number': 96386673,
    'item_consumption_1': 912,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 356.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4053d9eb-72ac-4645-94b6-bf6be35375b5',
    'identification_number': 20176228,
    'item_consumption_1': 2218,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 866.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14427e28-dfcf-4225-a09f-7926c5c59eea',
    'identification_number': 19296568,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 16.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '50dd1700-67c0-4454-8cb4-6344773c7b11',
    'identification_number': 77411609,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '82abd75d-c87c-4401-9aab-42416c17a74d',
    'identification_number': 50792180,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '09489821-ade2-4a13-8a67-1b0dfd2f35f3',
    'identification_number': 95224343,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f25fbf39-0285-41aa-a5ca-7b5c34f52d35',
    'identification_number': 94384126,
    'item_consumption_1': "'-1.89",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-658.02",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '86549d3f-1204-4e7e-a564-6761b4f4638a',
    'identification_number': 100854982,
    'item_consumption_1': 1392,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2105.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '378b613b-5be9-4be3-b8d7-faad522b9b18',
    'identification_number': 97171425,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bce498a6-6f90-42d4-a821-828eb07da862',
    'identification_number': 69851000,
    'item_consumption_1': 5995,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2545.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f40fd699-0285-4b14-ac40-5e3ad4727037',
    'identification_number': 27895610,
    'item_consumption_1': 33374,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4259.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5cdfc60-ece7-40da-8aa5-f32402075e92',
    'identification_number': 13786067,
    'item_consumption_1': 7440,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3151.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52be5ba7-2374-4afa-aade-09249726bba2',
    'identification_number': 17192021,
    'item_consumption_1': 675,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 286.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6270976f-d4c0-44cb-9634-0126db0c1a2b',
    'identification_number': 28426193,
    'item_consumption_1': 154,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 60.24,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '59cbe565-f963-4281-9779-bbc4e940e905',
    'identification_number': 41379136,
    'item_consumption_1': 68928,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 25651.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5f028ec-7d2d-433f-89c2-dd07d7ef3476',
    'identification_number': 110200080,
    'item_consumption_1': 17818,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6952.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2968e6a-8646-46ef-9e8c-b6681c7bc923',
    'identification_number': 93205589,
    'item_consumption_1': 64,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 25.02,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1d17b3fe-8890-4719-842b-cd979646fe58',
    'identification_number': 56582854,
    'item_consumption_1': 2142,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3240.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72c9bccc-bbe8-4805-aa73-fefff3e3620e',
    'identification_number': 18278140,
    'item_consumption_1': 1630,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 635.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00b10bd9-94f2-4c34-8500-b9b53835cd01',
    'identification_number': 103087745,
    'item_consumption_1': 1612,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 684.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ecd7825-4b29-483c-9c19-29fe1c3b0c3a',
    'identification_number': 42709717,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 74.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4ea9a0c6-be6b-436f-bba8-ac31bbef0f0b',
    'identification_number': 111115213,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd012125-d87b-44d4-b472-4ec2d4613752',
    'identification_number': 52981533,
    'item_consumption_1': 798,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 338.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e5c6cca-76bb-4125-819e-688062583618',
    'identification_number': 95962239,
    'item_consumption_1': 6384,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2486.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '483fde12-f2b4-4c08-b7f6-b0edb8271a2a',
    'identification_number': 40184188,
    'item_consumption_1': 64956,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8290.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21ba6568-8c5d-424e-abcf-8049fae4e226',
    'identification_number': 111452465,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-2.29",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'e056b52e-17ca-4b57-b273-11edaa7ab25e',
    'identification_number': 35825448,
    'item_consumption_1': 16638,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6191.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd747124e-1f75-4b3b-b90e-984b9290abb5',
    'identification_number': 16013239,
    'item_consumption_1': 2478,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 953.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0c10783-04ae-4f27-a37e-a0f7195f9be1',
    'identification_number': 32154836,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd1814531-bfa4-45a7-9a4a-bb7a8cfc0c0d',
    'identification_number': 97871508,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '56a3d298-e7e1-4666-b3e6-49e55c481a51',
    'identification_number': 106087266,
    'item_consumption_1': 2561,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3874.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '379a2a6f-a1b6-4fd3-b7cc-ad210d71c6ae',
    'identification_number': 92158064,
    'item_consumption_1': 1058,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 617.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3ae1a98-7182-46c5-8b9f-697ce83fd66c',
    'identification_number': 46913190,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce507e99-11d0-406d-8875-731570c54eef',
    'identification_number': 55746560,
    'item_consumption_1': 39.99,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1163.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '681f10ae-d518-4b12-bd22-38f966f3d75d',
    'identification_number': 100343244,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad533b0e-f80a-4abe-9166-b4ded2d4991d',
    'identification_number': 56617968,
    'item_consumption_1': 1034,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 404.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aaa56ac2-d0c6-4b71-85d3-4d2a17ef185e',
    'identification_number': 91024897,
    'item_consumption_1': 1902,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 807.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d5dddfc-182a-4772-ac38-81e7f3dd8b6a',
    'identification_number': 40636399,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ed7f516-5476-483f-baf3-58a135725203',
    'identification_number': 51210533,
    'item_consumption_1': 2139,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3235.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a40e44da-7f34-40ff-904a-17199b394874',
    'identification_number': 96698527,
    'item_consumption_1': 6134,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2396.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7136fa26-d641-4d16-ac15-1bc7b3059385',
    'identification_number': 106965883,
    'item_consumption_1': 2125,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 899.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a75c290e-8cbf-4237-91f9-01c3d8b0d00b',
    'identification_number': 19403224,
    'item_consumption_1': 1565,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 663.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cededc94-5186-4aad-b12d-b41299e450a2',
    'identification_number': 17898927,
    'item_consumption_1': 338,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 131.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00a45ea1-dcb1-46c2-a28f-23c046fcdf0d',
    'identification_number': 48043907,
    'item_consumption_1': 12.19,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 290.74,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ad3ce839-9d10-43f6-b15a-5556c50b94ff',
    'identification_number': 32899890,
    'item_consumption_1': 628,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 266.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb9173d3-f940-4948-b676-4aec4b2c9292',
    'identification_number': 20167725,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b589b088-e1c4-4813-b618-b6562a964bd5',
    'identification_number': 33620717,
    'item_consumption_1': 5653,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2206.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4dfa9900-aba8-4d19-8b79-91ec9cbd713f',
    'identification_number': 18910874,
    'item_consumption_1': 374,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 146.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73c3a6ff-e8ab-4ea5-ab4a-7cf961910c67',
    'identification_number': 107674149,
    'item_consumption_1': 1405,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 820.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c18ca6c-f72b-4b4b-9e5f-38e23d543698',
    'identification_number': 97079006,
    'item_consumption_1': 444,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 173.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '421c88a7-4d7b-4af4-aaf8-679fd680fd9b',
    'identification_number': 98252992,
    'item_consumption_1': 1207,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 512.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0818aeb-bd4a-4f9e-b8d0-d68e5bc406ca',
    'identification_number': 14905612,
    'item_consumption_1': 21,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 8.21,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'de6466c1-3a3e-4a05-b093-0dae87a33d2b',
    'identification_number': 18216994,
    'item_consumption_1': 8358,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3539.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bc4b64e-8391-4726-afcf-588a3318f080',
    'identification_number': 95555234,
    'item_consumption_1': 1430,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 558.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6cc22098-ebdb-4ac3-a3a9-7014ae08247d',
    'identification_number': 27316114,
    'item_consumption_1': 6950,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4059.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a2ad722-616f-47a5-807a-6a015069d404',
    'identification_number': 39196461,
    'item_consumption_1': "'-15.607",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 09/2023',
    'item_total_value_1': "'-5433.49",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '92b01991-f98d-400e-a82a-f5af2f855c04',
    'identification_number': 109675185,
    'item_consumption_1': 2402,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 893.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c8c36f0-fe6b-4f44-b123-462a3029a141',
    'identification_number': 100854982,
    'item_consumption_1': 1392,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 812.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07286ff1-4fa1-42bc-adc0-ecaee8316a2c',
    'identification_number': 105562840,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '188d4919-a520-452f-8fb1-94f897d1f488',
    'identification_number': 103630678,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7b03db6b-0ecb-4ecd-9e26-2de305e51a5e',
    'identification_number': 69873070,
    'item_consumption_1': 12874,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5020.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c49a29d-08cb-41e4-9b61-710c459c4375',
    'identification_number': 948743686,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e69e3e2a-852e-4660-aa62-422fe6405c5e',
    'identification_number': 87412411,
    'item_consumption_1': 4166,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1626.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc53ddf3-e840-4ed7-94aa-26c6f8201f23',
    'identification_number': 30035465,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1a9117ef-3dd6-4124-bcc5-9717ec7b35b5',
    'identification_number': 99042223,
    'item_consumption_1': 93.38,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2716.04,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b18ba74c-5ebb-4d06-9cb4-3c0c1b5d5338',
    'identification_number': 72771356,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69b88045-4531-4b83-9737-29962f65e1c4',
    'identification_number': 87262061,
    'item_consumption_1': 3906,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1526.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e09f296-6a0e-415a-92e5-72214b68f3fd',
    'identification_number': 43875718,
    'item_consumption_1': 53288,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6801.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6fae8eea-8667-42b1-a827-fc62df8f936a',
    'identification_number': 34438050,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c75a0b0b-511f-405a-9ac8-18b1536ca722',
    'identification_number': 46677615,
    'item_consumption_1': 33.35,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 970.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b2c5b51c-388b-4e5b-81d5-abb99fe684e6',
    'identification_number': 33670420,
    'item_consumption_1': 499,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 754.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fd75018-119a-490f-a88e-bf08eb6bf159',
    'identification_number': 40767884,
    'item_consumption_1': 387,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 164.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6911277-110f-4bb0-93ee-92c3696276f3',
    'identification_number': 106278789,
    'item_consumption_1': 11800,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5008.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6ebe1e0-5612-4771-ae8a-627376b31675',
    'identification_number': 39964973,
    'item_consumption_1': 45680,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5830.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1f6370c-e4a6-4035-b6bc-80e11fe96b41',
    'identification_number': 12176532,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65e742a1-cf74-4745-9a6d-1736e34bba9b',
    'identification_number': 99215020,
    'item_consumption_1': 1839,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 718.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f90a8f84-0d06-4afe-86c6-6304e46d250d',
    'identification_number': 102658960,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5f2a4a0d-3a84-41ed-8559-270305804d6f',
    'identification_number': 56705514,
    'item_consumption_1': 4.63,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 110.44,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e0b07d15-9b18-40f5-a183-14640241f350',
    'identification_number': 16111168,
    'item_consumption_1': 9493,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4019.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '212d3497-ef83-479e-afa5-cc4d51fe8608',
    'identification_number': 76514587,
    'item_consumption_1': 301870,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 112340.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1919772d-a434-41dd-b687-444582ee1fce',
    'identification_number': 29982685,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2bd540d8-21a9-45ed-a916-f34a67daf39d',
    'identification_number': 45702276,
    'item_consumption_1': 3897,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1523.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26d6bfb1-36c4-476e-ab8a-5c79e378d8e3',
    'identification_number': 110977360,
    'item_consumption_1': 142.22,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 6660.22,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0b62c167-6064-47ac-bcf1-9addfcce810d',
    'identification_number': 108015955,
    'item_consumption_1': 1328,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 564.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '060d9022-0e71-4a9e-b7d0-02554ae43f55',
    'identification_number': 33324980,
    'item_consumption_1': 1959,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 829.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '611496e1-945c-417a-87e3-dc7098fe722a',
    'identification_number': 10318453,
    'item_consumption_1': 2033,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 862.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3da83ce5-9776-427d-9417-a9158291f95b',
    'identification_number': 97425907,
    'item_consumption_1': "'-252.0",
    'item_description_1': 'ENERGIA INJETADA EP TUSD 11/2023',
    'item_total_value_1': "'-26.36",
    'reference_month': '11/2023',
    'referente': 'Compensação crédito energia',
  },
  {
    'uuid_items': '1d6ea6c0-d20e-4898-9e5f-f51441e189c2',
    'identification_number': 84355409,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd7be1aa4-db6a-43ea-9a78-54f87c9ca7a0',
    'identification_number': 58738428,
    'item_consumption_1': 6798,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2529.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f94c0b6a-4709-487c-8f7b-b2ff8456db6c',
    'identification_number': 15158241,
    'item_consumption_1': 2816,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 359.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85942db3-9d41-48ac-82b9-8098960fc0ee',
    'identification_number': 64429857,
    'item_consumption_1': 873,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 340.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b11d6b3-5d11-4ab7-b18c-7f1ccb207628',
    'identification_number': 91515386,
    'item_consumption_1': 2114,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 825.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d22dd2a-a292-46b1-9a76-f0496780d9e0',
    'identification_number': 100624650,
    'item_consumption_1': 2108,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 893.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f573cfa-5ae9-4587-a8d5-4069f213c3c7',
    'identification_number': 78334640,
    'item_consumption_1': 49.49,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2826.35,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b3d8d0a8-ee3b-405b-a649-90f3e13dea5a',
    'identification_number': 14799200,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7e10ba1a-2b6b-4977-a30b-c56e39f63c90',
    'identification_number': 108849104,
    'item_consumption_1': 1394,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 544.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6682568-2389-4a9a-ac19-a5c57e478939',
    'identification_number': 84927798,
    'item_consumption_1': 801,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 312.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7862d1b0-cbca-4cb7-a095-a70eae79f4a2',
    'identification_number': 5693608,
    'item_consumption_1': 5572,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2171.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '074e9c1e-21a8-4bd5-a302-2f70cf83b4ef',
    'identification_number': 50783840,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e28eb9d9-f344-4aa0-9a6a-24205df5696e',
    'identification_number': 95641963,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1265.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7dea6a71-f7cf-44d8-b670-86486ce0a164',
    'identification_number': 44805616,
    'item_consumption_1': 2477,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1446.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1de04e2a-2cd6-4d12-bc40-1594cd8751e9',
    'identification_number': 96843330,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 86.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e64438b1-52e6-46b2-9d01-c55897f49af6',
    'identification_number': 26343592,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f38e4033-0119-457e-8275-707bdeced4b9',
    'identification_number': 46577173,
    'item_consumption_1': 41,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 16.03,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5db8d619-d7e1-425e-ab51-21b23602ba6c',
    'identification_number': 101870728,
    'item_consumption_1': 9466,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3522.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a4e70cc-0c3b-47b7-8b18-7f163ef143e8',
    'identification_number': 22162330,
    'item_consumption_1': 141,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 55.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ec5f66c7-6a66-4fe0-babc-509bae079b41',
    'identification_number': 49074423,
    'item_consumption_1': 1575,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2382.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b358eb6d-b714-4f6f-ab3b-c06bb9235850',
    'identification_number': 11346221,
    'item_consumption_1': 82.66,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 1971.48,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'f90d39ae-0227-4576-9645-c53d15d16107',
    'identification_number': 15232506,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 86.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '48d4b410-2446-4b74-9788-e01499a4142f',
    'identification_number': 5826373,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b8708b39-fffa-47f3-be64-6435801f44dc',
    'identification_number': 104614285,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7137.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b3a7b2b-7196-4d96-a22d-9422255a8da2',
    'identification_number': 93586086,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b40e1bf1-c5c7-4390-a91f-34f8f7093319',
    'identification_number': 38932040,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3f354174-02eb-48fb-9063-ce583be50f6e',
    'identification_number': 94664480,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 26.26,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'c0f53adc-bcfe-4139-b3c9-d4b01f4c29fd',
    'identification_number': 50792180,
    'item_consumption_1': 20.34,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 2323.22,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'cd951c0f-d9aa-408e-b0b3-7ba28a45f23b',
    'identification_number': 29985404,
    'item_consumption_1': 108.84,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 2595.89,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '583a7f6c-f0f6-44e5-bdbc-5a09605a2443',
    'identification_number': 70075778,
    'item_consumption_1': 4858,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1895.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45870b95-d922-4425-8f67-2c263bdc6c99',
    'identification_number': 51632381,
    'item_consumption_1': 50.57,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1470.86,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f6c4c0fa-b249-4427-9dd5-4c6939e68560',
    'identification_number': 74591371,
    'item_consumption_1': 16030,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6813.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67923ec3-cec5-4067-9f3c-3bf48668edec',
    'identification_number': 31792634,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '38a8166c-d852-4402-8bac-94c975c53447',
    'identification_number': 16861469,
    'item_consumption_1': 15530,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1982.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac969c89-45de-4d18-acab-5a75732adca5',
    'identification_number': 13220462,
    'item_consumption_1': 642,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 250.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f34a3186-13d4-4de9-b4ee-963e63a9c458',
    'identification_number': 53913493,
    'item_consumption_1': 1055,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 447.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02fd79b3-651a-4d0a-bf7e-dfa90acba1d5',
    'identification_number': 70556997,
    'item_consumption_1': 47.97,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1395.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0ce041cd-9a2a-4460-bac5-4bcc756a2b19',
    'identification_number': 30085918,
    'item_consumption_1': 17144,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2188.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3226b52-8d92-4b45-bb06-2fc041b5b90f',
    'identification_number': 12021720,
    'item_consumption_1': 4.59,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 1740.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5bec7a8-0833-46de-a631-3e65cb91a291',
    'identification_number': 100941699,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '094a4bc6-fd5b-439e-b310-ad76ac394747',
    'identification_number': 103475494,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1a56de33-1105-425e-9bf5-b9f1861a5351',
    'identification_number': 76403955,
    'item_consumption_1': 1122,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1697.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2821cd5d-e5a0-4055-8b1c-b16d3680a1b6',
    'identification_number': 105472336,
    'item_consumption_1': 6281,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2663.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46ca4366-312a-4ab0-8d29-fd51a01cc86e',
    'identification_number': 26142961,
    'item_consumption_1': 4664,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1819.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf509896-c15a-4acd-a29d-a9465dbcddc8',
    'identification_number': 60716940,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9927062a-420a-4e95-b801-eb1c5cab020d',
    'identification_number': 39466787,
    'item_consumption_1': 527,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 206.19,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6aa37736-fd62-41ed-9d88-9ed64876b1a9',
    'identification_number': 27057852,
    'item_consumption_1': 2871,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1122.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8297b994-adcd-4c1e-89cd-61f699bee16d',
    'identification_number': 16968859,
    'item_consumption_1': 609,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 258.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f96246d-c51a-476e-9b55-8ebd3060a192',
    'identification_number': 19450745,
    'item_consumption_1': 390,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 152.58,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '45ec2dba-b9c9-4307-b1a3-a000c61e5f37',
    'identification_number': 111671965,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f6971bb-8f63-4d99-a380-997d60e2b31e',
    'identification_number': 12424188,
    'item_consumption_1': 137.56,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4001.05,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f09ff552-8c82-4bd3-a2bd-a551aa79b56e',
    'identification_number': 111372879,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8971b3a0-a169-47f4-91c2-614de95fb35e',
    'identification_number': 100116094,
    'item_consumption_1': 620,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 241.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e0e159b-8d1b-42c6-b928-ab4851782bd9',
    'identification_number': 12446645,
    'item_consumption_1': 140,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 54.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'af8eaa94-297a-4a48-b45b-9a7cf1484d25',
    'identification_number': 19289022,
    'item_consumption_1': 17929,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7621.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0cd6764-92a0-4817-9737-036461f93d56',
    'identification_number': 107074117,
    'item_consumption_1': 121,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 47.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1789d154-1804-4251-9d7f-84489dc05358',
    'identification_number': 12468347,
    'item_consumption_1': 3457,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1349.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c016626-e5bb-4330-9dd8-f938a46da42c',
    'identification_number': 58734899,
    'item_consumption_1': 337,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 131.85,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cfc51b5e-cadd-4fad-b4f1-7e00c628cc66',
    'identification_number': 98264370,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7ac6cb6e-fc2e-4118-9e81-63a26a76eb33',
    'identification_number': 40122654,
    'item_consumption_1': 1478,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2235.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90297af3-1337-4825-b3c8-c7a153a1ce2e',
    'identification_number': 33354537,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.65,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0208ce6e-610e-490f-9d03-d21c146b6c29',
    'identification_number': 45563896,
    'item_consumption_1': 2618,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1021.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3da6e3f1-e39d-4789-a942-7cab0c8e200e',
    'identification_number': 89126610,
    'item_consumption_1': 1910,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 811.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61af6914-c41f-48c6-84f7-af5a2bdf7a9c',
    'identification_number': 91394031,
    'item_consumption_1': 117.24,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3410.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cb011bb8-1dd0-46e5-93ec-6a38437793a2',
    'identification_number': 107225158,
    'item_consumption_1': 674,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 285.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '434fb181-28f2-4a7d-978f-5517c0e7bbcd',
    'identification_number': 10145214,
    'item_consumption_1': 941,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 398.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e76d6612-32c3-48da-9abe-288993786a37',
    'identification_number': 59828080,
    'item_consumption_1': 5050,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1969.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8c10eef-fa80-40e4-ab56-28654efcfe32',
    'identification_number': 62391453,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a7116025-2e45-4dd1-b5f2-090dcdc3c333',
    'identification_number': 70503974,
    'item_consumption_1': 233,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 352.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2afad1aa-788d-4d12-8640-96a990b50322',
    'identification_number': 33751102,
    'item_consumption_1': 1602,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 678.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5a979f7-d280-413f-a293-0db634010823',
    'identification_number': 50761900,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f89825dc-4db7-4231-b259-a1dcb96b9ae5',
    'identification_number': 31945252,
    'item_consumption_1': 2441,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3692.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3545eaa-1361-4de3-aa58-e5ff12856924',
    'identification_number': 80200052,
    'item_consumption_1': 4386,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1714.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8eb8d6f-42ec-4999-b8d4-8607f21e97f6',
    'identification_number': 97970034,
    'item_consumption_1': 8.28,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 481.66,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3fd9493d-763d-4b0e-a0d2-180b49b36944',
    'identification_number': 104732156,
    'item_consumption_1': 4204,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1782.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e096cf0a-409f-4c6b-b46c-8c8697405449',
    'identification_number': 31645313,
    'item_consumption_1': 20354,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2597.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2997d9db-5f96-44ae-8b6b-b0a47c23fe70',
    'identification_number': 8866163,
    'item_consumption_1': 77.73,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2260.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '60d817d9-8f26-4d83-a901-90b7f7323d0f',
    'identification_number': 14862336,
    'item_consumption_1': 10.63,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 618.35,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'dd657f75-1ef4-488a-9ce7-90cdad2d41f6',
    'identification_number': 14671549,
    'item_consumption_1': 4322,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 551.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98025ae3-de2a-43dc-b75e-55dd2c4794cf',
    'identification_number': 35562854,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8e10b96-986e-49b1-863c-9c450ade8750',
    'identification_number': 49686518,
    'item_consumption_1': 4141,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1756.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b751d6b1-a23d-4157-8246-9d02cbe23884',
    'identification_number': 10633162,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.33,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f2902c17-7cfb-4b47-a97a-e18fab354603',
    'identification_number': 56886314,
    'item_consumption_1': 13151,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1678.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23572209-53dc-43b0-a551-41b73edf295f',
    'identification_number': 14333910,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9e4ef764-dad9-4f22-a69e-31726ed36999',
    'identification_number': 99134993,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '06270169-b7cb-49ed-a098-b5a480e8c675',
    'identification_number': 18383971,
    'item_consumption_1': 3546,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1381.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14d89d1a-4d94-49e9-a78e-c2b3247ccd3c',
    'identification_number': 82743339,
    'item_consumption_1': 4312,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2518.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '300e062e-b98a-4fae-953b-108e862d809c',
    'identification_number': 10797289,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a6fc4f8-62c8-4ffe-b404-31b1d79dc753',
    'identification_number': 91840252,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf4f47c2-5faf-4f34-93fd-350f37fd3e8e',
    'identification_number': 19282087,
    'item_consumption_1': 588,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 248.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3dd034e8-f855-44dc-a93f-3d67adceec28',
    'identification_number': 58734899,
    'item_consumption_1': 8.53,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 487.13,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ef8f89b0-06f4-4f34-8fdc-3d89b3f1f82e',
    'identification_number': 109434765,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8394722-4487-49fd-841d-a99aed639b8a',
    'identification_number': 102611033,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '97964423-103f-4b7c-b722-11833361c1f2',
    'identification_number': 39214699,
    'item_consumption_1': 12035,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1536.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd97ca002-5d3f-4f34-8f08-131c59ae699a',
    'identification_number': 102734607,
    'item_consumption_1': 398,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 154.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b16be846-6759-4163-b784-602f46d84c15',
    'identification_number': 11346221,
    'item_consumption_1': 46,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 17.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00202193-f35a-4f17-904f-3a4face12c08',
    'identification_number': 20367384,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-1111.9",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '3f10b034-6ad7-494e-a50f-0ed637cc3e7c',
    'identification_number': 67917283,
    'item_consumption_1': 37.86,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 4324.36,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b823b15f-a3b2-4720-ae4e-46e00deb8aaa',
    'identification_number': 20579071,
    'item_consumption_1': 734,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 286.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08ca06ea-73a3-48dc-8333-c62e7e6f11b4',
    'identification_number': 49457012,
    'item_consumption_1': 90.18,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2622.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'defd046e-7d11-4321-af6e-2b881d1caebd',
    'identification_number': 16078985,
    'item_consumption_1': 5240,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2041.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba08f581-a69c-4383-bf62-bf43190749ac',
    'identification_number': 26460491,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b38fb07-3bbb-460e-9208-7093b726d55d',
    'identification_number': 69758409,
    'item_consumption_1': 400,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 605.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '695e6ada-f59e-4713-adbf-25a733f9b491',
    'identification_number': 109118030,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '352023b7-e01d-4d7b-85cf-de1b1bb72949',
    'identification_number': 98370200,
    'item_consumption_1': 30243,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11254.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db21216f-66c9-4dd6-90fb-32b5bbef5ed1',
    'identification_number': 48843075,
    'item_consumption_1': 2.03,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 48.43,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a3b080db-87e7-4df8-ac4e-aac29766a51c',
    'identification_number': 56706499,
    'item_consumption_1': 5598,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2083.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec4a697f-82e8-4a8b-9fa3-ae779d3c7907',
    'identification_number': 19453302,
    'item_consumption_1': 78443,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 10012.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd2ce39f-b09f-4203-9326-0bf702484e05',
    'identification_number': 16106806,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 83.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ecbf4e72-d038-4bb9-bb41-20f6d181de5b',
    'identification_number': 106473930,
    'item_consumption_1': 3833,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1623.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91fc06ba-b635-4cdf-b351-e19e3f8ffe96',
    'identification_number': 105088730,
    'item_consumption_1': 59446,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7587.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbe4c38b-6324-4fee-9281-1fb66c9ac465',
    'identification_number': 11013958,
    'item_consumption_1': 56.58,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3231.26,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '98c3bd82-9e63-4a0b-a75c-6b01cfabc6a3',
    'identification_number': 91809908,
    'item_consumption_1': 123,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 48.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ecbf305-d24c-4934-a258-9f51e0949e0b',
    'identification_number': 67330274,
    'item_consumption_1': 211,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 82.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a28a54f8-862d-4320-bd68-7450159479be',
    'identification_number': 29873584,
    'item_consumption_1': 3671,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1560.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02d46b6a-36d8-4721-8bea-f4a816e9059c',
    'identification_number': 50792180,
    'item_consumption_1': 3446,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2012.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5b06441-852a-47e6-83b6-f8a0132b2876',
    'identification_number': 46225668,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b4287d99-8c71-451b-9d52-3a89c7cb62bb',
    'identification_number': 102658960,
    'item_consumption_1': 9643,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4095.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2626e2c-d8a7-4e69-82e6-4cd23be76f41',
    'identification_number': 37192744,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '16737db0-0da1-4d84-9929-f05e5ff5fb1d',
    'identification_number': 109375580,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0c2285a-7fc0-400a-b11f-9a20f4449d9c',
    'identification_number': 98802739,
    'item_consumption_1': 3713,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5617.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5bd43805-325e-4333-bbc1-cc0e24b599fa',
    'identification_number': 98351036,
    'item_consumption_1': 1708,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 725.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa693608-8c94-4608-a8cb-a2dc592c2648',
    'identification_number': 34680292,
    'item_consumption_1': 25490,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3253.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7bd939ba-a906-446e-a946-1627c5ccc8e9',
    'identification_number': 63964430,
    'item_consumption_1': 121,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 183.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee803758-e670-4071-bdb2-c73e71437ee2',
    'identification_number': 12673994,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1349.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2d3dd869-294e-4df3-a1ac-ca485fa03e39',
    'identification_number': 107520028,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c9f25c43-4959-41e1-bd22-1396cb32c057',
    'identification_number': 102890269,
    'item_consumption_1': 22629,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 8842.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03e17c93-65a9-47fa-ad72-8b9e2ea4dfe9',
    'identification_number': 45131171,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdf98893-d5cb-4169-aec8-1cd9b54fc7b7',
    'identification_number': 43338739,
    'item_consumption_1': 508.4,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 12125.59,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '67d1fb19-a184-46c2-bd92-dabf3e854e5a',
    'identification_number': 20387954,
    'item_consumption_1': 1440,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 610.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7ee8d48-f445-451a-aaef-9e5ccfeb96a9',
    'identification_number': 103034838,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a22cf123-2836-42e2-9c7f-78303be360f4',
    'identification_number': 53371127,
    'item_consumption_1': 54094,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6904.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87bd98ed-2630-415c-8aec-dc22b75660a8',
    'identification_number': 55603840,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea6f1013-09bd-47a0-bba2-a22634744a23',
    'identification_number': 39492265,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-2.29",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'a07e906b-33fc-44c0-bfe6-3b6bccfa74b9',
    'identification_number': 108198804,
    'item_consumption_1': 1547,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 656.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fff3950b-fb50-40ed-926b-d979207eb889',
    'identification_number': 106706632,
    'item_consumption_1': 15,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 5.86,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '26b13dbd-a71e-45e3-81ff-9b1a516958e2',
    'identification_number': 41381823,
    'item_consumption_1': 211.56,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6153.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e3c2c8ff-b3f1-4f6c-94fe-1e17a5b0d866',
    'identification_number': 20367449,
    'item_consumption_1': 2585,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1009.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c2ea053-046f-46c3-95f0-96c3203f9257',
    'identification_number': 94625409,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6496cd1-5f63-4ef5-bbb0-30027699e8e1',
    'identification_number': 92122345,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 3334.13,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '4a893727-fe0b-4ca8-b42e-7f9344d30ff9',
    'identification_number': 14693100,
    'item_consumption_1': 359,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 45.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f8a315b-6eb9-4eb2-8716-8b7eb45e73f7',
    'identification_number': 41672658,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e1c9b4d-d912-40c7-b069-c3baf9583190',
    'identification_number': 40232182,
    'item_consumption_1': 2078,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 812.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05727064-d9b4-4bff-9ebe-e0975198c6ea',
    'identification_number': 10781862,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '65cfb864-d32e-4aeb-839f-48e8a7b17022',
    'identification_number': 10781862,
    'item_consumption_1': 463,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 196.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ed7ff7d-1c75-4583-a52f-ec8faafb89ae',
    'identification_number': 109139402,
    'item_consumption_1': 514,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 777.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50d9bfde-a7f1-440b-a11b-80397239c2cb',
    'identification_number': 108864855,
    'item_consumption_1': 428,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 181.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1574c5e6-4892-4d75-917b-48c3b202c9d5',
    'identification_number': 15138704,
    'item_consumption_1': 4858,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1896.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b049d18c-d87b-45ad-b41f-c57a74f4b6ff',
    'identification_number': 110994191,
    'item_consumption_1': 3056,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1192.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91979a73-7a0d-4c1e-996a-6c3c662e1f61',
    'identification_number': 108065006,
    'item_consumption_1': 70.65,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2054.9,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bfc23b1c-e5b6-432b-8e18-dc04883ddb1f',
    'identification_number': 9921273,
    'item_consumption_1': 7,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0b712da5-7769-4569-a7b2-a53c3c78e58a',
    'identification_number': 42537711,
    'item_consumption_1': 3397,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1960.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ad291a2-af17-4e33-ba51-66d925da2854',
    'identification_number': 43739717,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1072ae9-b401-46eb-bab6-1eb872155c6e',
    'identification_number': 47899816,
    'item_consumption_1': 24.89,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 593.64,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '681e34f9-0db9-4d9e-8efe-496ee1c8dbd7',
    'identification_number': 14935481,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8d73c9b-a631-45d6-9f24-db85ece889a1',
    'identification_number': 42709717,
    'item_consumption_1': 1.41,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 33.64,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '538a59fd-694c-4bc6-80cf-87f935cd4f47',
    'identification_number': 102699127,
    'item_consumption_1': 2178,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 850.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02240e93-5969-4fce-a0b9-674236e61355',
    'identification_number': 100187994,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd257f1f-4146-4152-afdd-e3387d3bf6dd',
    'identification_number': 52933423,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '24e66faf-609d-44c4-bbd6-985978daee02',
    'identification_number': 75876957,
    'item_consumption_1': 259,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 101.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d33b464-0673-419f-98b1-5c807441004b',
    'identification_number': 45851654,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 126.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '94af496f-4deb-41e3-80df-824ed4a76e0c',
    'identification_number': 93308965,
    'item_consumption_1': 139.99,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 3338.84,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '41166d22-6d00-4f52-97b8-8f5232e91e16',
    'identification_number': 77279719,
    'item_consumption_1': 9506,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1213.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5b91091-cbba-4c2a-b453-14a0a354cdff',
    'identification_number': 47154152,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0eaad6dd-9af3-4db9-9c13-345659460f4d',
    'identification_number': 22115226,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bd51a54-98fe-4bb5-ab4b-b400106ed493',
    'identification_number': 64175251,
    'item_consumption_1': 36519,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13590.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c96cd9d-2de5-42c3-942d-0dfbc73713a7',
    'identification_number': 12446645,
    'item_consumption_1': 4088,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6184.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b14c2f95-05aa-47ca-865b-ea8648a8c51b',
    'identification_number': 45768279,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 19.07,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'b71dd202-4030-4212-8c0c-fdc88245ad25',
    'identification_number': 110907760,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7313e03-6805-485a-a12f-ff5a6ac79669',
    'identification_number': 98925083,
    'item_consumption_1': 687,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 401.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be1a7c79-aa21-4e1b-a26c-7e0119bbd4ae',
    'identification_number': 59264993,
    'item_consumption_1': 1391,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2104.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4972962-13d8-4f11-bf60-55c617d2fd4e',
    'identification_number': 22609903,
    'item_consumption_1': 272,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 115.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '311e66f6-029c-4f15-8810-844ebc6d23e1',
    'identification_number': 102054690,
    'item_consumption_1': 147,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 57.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b363f14c-1ddd-474a-a8cd-20b1571b2a5a',
    'identification_number': 110922751,
    'item_consumption_1': 790,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 307.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d46c8f8-c376-40dc-b77c-c389904fcb53',
    'identification_number': 6208282,
    'item_consumption_1': 6575,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2788.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee277c3d-4dfe-4e39-ba9d-ef454888e9ad',
    'identification_number': 93760175,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '742b1b8f-9095-4089-86dc-4c4b0621d6a6',
    'identification_number': 23376414,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 323.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4ca1af14-24f1-4954-aaae-b94d544bb4bb',
    'identification_number': 98925083,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '17a0abc2-bf52-4e17-9ee9-544ffe89003e',
    'identification_number': 50863207,
    'item_consumption_1': 5600,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2188.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc92a983-4be8-418c-b9a3-68ba2c2a4f0b',
    'identification_number': 42433320,
    'item_consumption_1': 10332,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4379.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60711aac-6fe3-4aa0-b001-239cc4f60e0a',
    'identification_number': 80350844,
    'item_consumption_1': 1343,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 784.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e727f191-8457-4d36-8916-5809955675ea',
    'identification_number': 14635232,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1867aeee-5939-4674-a9e6-44cca9e73f46',
    'identification_number': 104704837,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9e2512bc-9f00-44be-b847-1a9d63c8d2c5',
    'identification_number': 46421939,
    'item_consumption_1': 3650,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1547.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9de88a79-d216-4551-8e1c-5b461f1201a8',
    'identification_number': 93699395,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 74.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5591e9b8-2197-473d-aba2-ce29524f4597',
    'identification_number': 62088416,
    'item_consumption_1': 1394,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 590.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c9b65de-4a38-4656-ad36-128cc24346dd',
    'identification_number': 41486544,
    'item_consumption_1': 2093,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 818.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01fbd00f-c6bb-4a1d-8847-bf88880ef13c',
    'identification_number': 19289022,
    'item_consumption_1': "'-555.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-178.0",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '6508346f-85d8-4d3f-8605-895e0263b2d8',
    'identification_number': 12404926,
    'item_consumption_1': 4038,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1575.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '306b286e-0061-467c-9912-7c6c19722c98',
    'identification_number': 98913972,
    'item_consumption_1': 38.17,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1110.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5c651b23-9f42-4f74-babf-682dc4345f54',
    'identification_number': 45701679,
    'item_consumption_1': 828,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 351.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53312568-e0bc-4fdd-af11-8cdb1e543436',
    'identification_number': 94650977,
    'item_consumption_1': 26,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 39.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be83e7b4-0e3a-44f3-9e8b-2e072b83c407',
    'identification_number': 101890419,
    'item_consumption_1': 29.61,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 706.23,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '591dd633-6ec9-40c0-850a-168383b99101',
    'identification_number': 111833825,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80ff7295-8fc0-4543-a391-73cdff942602',
    'identification_number': 101341547,
    'item_consumption_1': 2379,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 926.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f77c75ff-701e-4be3-9424-51e290b7eb8b',
    'identification_number': 93964188,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5a2a670-e651-4de1-b94e-b728bace7b73',
    'identification_number': 40184188,
    'item_consumption_1': 64956,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 24173.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2108b4fd-597c-4427-bde6-c1df050edf2c',
    'identification_number': 59602619,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4212dd1c-4af0-4a8e-b6c8-e35c702f4324',
    'identification_number': 18563430,
    'item_consumption_1': 9500,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3705.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a49bac2-fe02-4c4e-bd12-b81416a0b3b3',
    'identification_number': 100059643,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35388d92-53dd-43b2-a2c5-facabcb56595',
    'identification_number': 47380128,
    'item_consumption_1': 4.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 118.31,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd43f721d-c625-40fc-ac52-742d62d98069',
    'identification_number': 48511978,
    'item_consumption_1': 0.96,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 22.92,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2dfd9e45-ceb2-4b2d-96b5-9e4a60f8bd96',
    'identification_number': 12404934,
    'item_consumption_1': 220,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 93.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4b060c4-e7a1-4238-adb9-6c0d1a822738',
    'identification_number': 57740755,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0849ec18-56f5-418c-acb7-cc8e4a5a9995',
    'identification_number': 70327610,
    'item_consumption_1': 1497,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 634.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd00d6b54-adc3-4f24-833f-bcf069e1916c',
    'identification_number': 28562992,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '150f56a5-e7c9-4e9b-8052-c320574d9915',
    'identification_number': 16105141,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd68f19b5-4ebe-40b8-b08f-f784250de799',
    'identification_number': 95475133,
    'item_consumption_1': 1977,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 840.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6cd8d581-250f-4563-911a-c31ce94d1eb0',
    'identification_number': 67513212,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 48.22,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '680318d2-ae02-4d43-9d31-a2142568496d',
    'identification_number': 30226007,
    'item_consumption_1': 12810,
    'item_description_1': 'Energia Ativa Consumo kWh',
    'item_total_value_1': 9946.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dedff07c-f1ef-4e7a-bef2-5881f30ac640',
    'identification_number': 100158609,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4832c63a-b7e1-4702-a673-87d173b3beca',
    'identification_number': 948743546,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f766d54c-3ed3-44a7-9629-5ad6c750ddec',
    'identification_number': 55522815,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd289aac2-001a-444b-b932-b0f308ca0723',
    'identification_number': 10266178,
    'item_consumption_1': 3289,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1284.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '201fde16-b93a-48ed-b012-2a3a292d9852',
    'identification_number': 78084237,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3340.34,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'fc08b9e5-6e82-4845-8ac7-afd284a4ce4a',
    'identification_number': 26749068,
    'item_consumption_1': 2656,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1036.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d2b1a48-551a-45ff-9335-6108496ee207',
    'identification_number': 90604679,
    'item_consumption_1': 6.72,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 160.3,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'cc9cd854-7824-498c-a5da-b130512fde88',
    'identification_number': 110973240,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48a1588f-80b1-4c2b-a202-eb0cf857c28c',
    'identification_number': 110994191,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '408d09ac-8e13-4bdf-8f61-8652eda99959',
    'identification_number': 104718242,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0de72183-c5bf-4c58-8683-ff6f82fa6cc1',
    'identification_number': 46577173,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '64a78444-4711-4e08-b5ca-5ed902bd43f2',
    'identification_number': 77887930,
    'item_consumption_1': 24.35,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 708.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '29b35ba5-1461-4f92-8bc3-0765f68f09de',
    'identification_number': 105169226,
    'item_consumption_1': 2351,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 918.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '297722f7-6b67-4195-b7c9-07dcdeb27c11',
    'identification_number': 15484483,
    'item_consumption_1': 1320,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 559.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67dbbec1-584c-422d-90f9-8f31d2a0ef75',
    'identification_number': 22373101,
    'item_consumption_1': 30.5,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 887.12,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3b6fd9d4-32a9-4504-8031-79b0ee9a4630',
    'identification_number': 56877250,
    'item_consumption_1': 38,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 57.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '116a31a0-8d49-43af-ba11-dfd1eeac46f6',
    'identification_number': 92850294,
    'item_consumption_1': 1279,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 541.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e38c1b1e-3ca2-421e-b842-d0326f956051',
    'identification_number': 41490533,
    'item_consumption_1': 2635,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 1031,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ffdc2e57-a50d-4ef3-9128-2880e509957d',
    'identification_number': 93950993,
    'item_consumption_1': 122.8,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3571.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2bbbf8f1-2dba-4a74-878b-84de55be09c7',
    'identification_number': 23385731,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb883138-b2f8-41fe-b4cc-193fba098db1',
    'identification_number': 72777320,
    'item_consumption_1': 17.5,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 417.39,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5fe253f0-7a40-4e47-b52d-b5b599451d1e',
    'identification_number': 40131157,
    'item_consumption_1': 3361,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1311.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7aaed69-bb9e-4ecb-bd05-c653269d3675',
    'identification_number': 108668630,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db2fbf1d-2698-4021-85f0-386850eafc1b',
    'identification_number': 111469554,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '633c295d-5d1f-4803-8233-09ebb74cd232',
    'identification_number': 32151179,
    'item_consumption_1': 13546,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 7911.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0ef7da5-089a-4fe3-a5a6-1c5fa92352fa',
    'identification_number': 92158064,
    'item_consumption_1': 7382,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 942.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b0b6423-72c8-464a-a672-1418d3c77c5f',
    'identification_number': 95224343,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e62fb01a-ccec-4049-9b1f-c4736de0924d',
    'identification_number': 70810737,
    'item_consumption_1': 72,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 28.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bbf9efbd-689a-475a-8981-9676d06a822c',
    'identification_number': 102213330,
    'item_consumption_1': 201,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 78.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc8bd7b3-c903-4213-851b-b85f3c9b071c',
    'identification_number': 29985404,
    'item_consumption_1': 93406,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 11921.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '291feb44-bb29-4083-8c7d-fa2bd3f1f36c',
    'identification_number': 14662302,
    'item_consumption_1': 23052,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 9299.12,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': '4a79f8f2-f892-4f22-885a-a197e051b5d1',
    'identification_number': 107367807,
    'item_consumption_1': 122,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 51.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a71ebad6-fca3-43e1-982d-fbce5958409c',
    'identification_number': 42537711,
    'item_consumption_1': 30593,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11247.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3335d1d-4cc0-4b9a-bff3-2d06831a2a30',
    'identification_number': 38847280,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '332afe3b-d596-4b94-b0dd-102d3d83b24f',
    'identification_number': 41176987,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '085cbffc-4f85-43e3-a854-8e85780bda72',
    'identification_number': 41379136,
    'item_consumption_1': 9.66,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 452.39,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '25f34bbd-4528-4443-b4b3-5a871b87d7a2',
    'identification_number': 35614595,
    'item_consumption_1': 3024,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 385.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90254479-6a80-485b-aa7f-8a7d70f34c62',
    'identification_number': 15720322,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a0b191a-3632-4acb-af32-5dbb849afbd8',
    'identification_number': 100187161,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a0e9c13-7f38-4af4-b650-f357c0eb73a3',
    'identification_number': 15138674,
    'item_consumption_1': 3813,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1617.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b58f21d4-15e7-44a8-8881-c99a93ccb885',
    'identification_number': 49448986,
    'item_consumption_1': 2475,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 966.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25dd78f8-3ec6-47d9-8e2c-8237c262b072',
    'identification_number': 19311508,
    'item_consumption_1': 5060,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1972.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6db55285-99bf-4e36-961f-13bf30df52e5',
    'identification_number': 19289022,
    'item_consumption_1': "'-6.912",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-2216.92",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '69c48cd8-7ed5-463d-94ba-4b31ea3d0346',
    'identification_number': 55603840,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf7e34dd-0db2-458a-bc56-e501f78ce447',
    'identification_number': 18456669,
    'item_consumption_1': 639,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 373.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '410c33d6-fee0-4dde-bd82-1a6c4321cb09',
    'identification_number': 16002016,
    'item_consumption_1': 8824,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 3559.59,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': 'ab989822-a8d2-43ac-b7f0-58d807fc721b',
    'identification_number': 102362378,
    'item_consumption_1': 55.54,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1615.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b6cbde11-0c25-4a8f-8b8d-a1217fb41ca1',
    'identification_number': 21635935,
    'item_consumption_1': 12508,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5296.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '892dcffc-f0f9-4111-802b-d1d730f49bcb',
    'identification_number': 41755138,
    'item_consumption_1': 121.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3533.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5111acab-ac57-4ede-a1c0-157446c75b40',
    'identification_number': 59485841,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51c3f74b-2f03-4611-ba3d-527c5d58bcc5',
    'identification_number': 105807010,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 228.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f1f16cf-a4b8-4ece-bced-5916218cf89b',
    'identification_number': 40032060,
    'item_consumption_1': 101.74,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 5810.36,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'dafbe94c-642b-4421-b909-ceea75c7b7ed',
    'identification_number': 108668614,
    'item_consumption_1': 23526,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 9990.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee1aa89d-a8c7-4f55-8ac1-443e1836f086',
    'identification_number': 98015052,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '962f7e14-eb88-4d34-ab08-632068ea7049',
    'identification_number': 16111478,
    'item_consumption_1': 583,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 442.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2897d371-2fd4-4b61-b5b6-d4da3c7be687',
    'identification_number': 103772561,
    'item_consumption_1': 131149,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 48806.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7dfc99fd-ff11-4298-bdf3-142ffdb27dce',
    'identification_number': 26677210,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '50b3b701-91de-4f4f-ad19-bd607b8200d3',
    'identification_number': 99778106,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea9be754-b6db-4110-89be-da13eae0d46c',
    'identification_number': 39672131,
    'item_consumption_1': 207,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 80.99,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a6c0adc1-eb32-4987-bfcd-ebe59dae4bb4',
    'identification_number': 58737863,
    'item_consumption_1': 13233,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1689,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72041e04-e3a0-424b-a725-c4edd3ef6e4b',
    'identification_number': 28223152,
    'item_consumption_1': 1975,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 771.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e777fbc-4d8d-4a50-b38f-675d9f127d3a',
    'identification_number': 59436956,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c6ee0d05-7bdd-48b0-b122-40285b515ea7',
    'identification_number': 93964188,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcea0951-eb29-440b-8228-3f9cd62a374e',
    'identification_number': 25472267,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '983f4e98-b246-4168-b33a-ce98eab65981',
    'identification_number': 105953261,
    'item_consumption_1': 2319,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3508.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2dc640c8-1f55-42e9-97c5-e746dd9af307',
    'identification_number': 78479010,
    'item_consumption_1': 1643,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 697.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7eca4fc0-2f72-405d-90fd-bcebcc31ac5e',
    'identification_number': 91627176,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf240216-7bd2-43fe-9dd7-090afcec39bb',
    'identification_number': 5736234,
    'item_consumption_1': "'-6.912",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-2212.59",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'c65d2859-86f7-433e-8223-3c163198c4ae',
    'identification_number': 15444015,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '55ed9000-090a-4389-ab71-36ae78c46c68',
    'identification_number': 103034412,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a0baaf70-b3b8-42ac-995a-f5d6df780cb8',
    'identification_number': 1205102,
    'item_consumption_1': 17833,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6636.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66d7883e-5e02-4497-9f5b-6a12db12a2ce',
    'identification_number': 93763395,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f2e4817f-59ee-4bdd-90ba-b62c28d11d6d',
    'identification_number': 46577173,
    'item_consumption_1': 17.08,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 496.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '35b6d68c-25f9-439c-9028-dd9aa71cb48c',
    'identification_number': 45239282,
    'item_consumption_1': 27594,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3522,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b36d25fb-3c87-45ed-85d2-a489d969fab8',
    'identification_number': 84969466,
    'item_consumption_1': 707,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 412.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87041d29-c737-4e13-a998-c5bcfabbe321',
    'identification_number': 66861292,
    'item_consumption_1': 170,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 72.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c5001a8-9713-47a9-806f-0dff80762536',
    'identification_number': 103103163,
    'item_consumption_1': 660,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 257.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d69db68-5172-4434-b679-0c65ba099321',
    'identification_number': 12797707,
    'item_consumption_1': 569,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 222.62,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0f797fff-dae6-4950-a63d-1ea8585ac4e7',
    'identification_number': 12977489,
    'item_consumption_1': 53.72,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1562.49,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '40d729e2-6497-454f-b436-f8c40f557111',
    'identification_number': 102260460,
    'item_consumption_1': 3262,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1905.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f763db9d-9312-4fea-909c-897e3bd0b1be',
    'identification_number': 33625930,
    'item_consumption_1': 6278,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 9497.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '918eb87a-1b72-428a-9cec-ef67063d292c',
    'identification_number': 65079469,
    'item_consumption_1': 683,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 289.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e46b8689-f725-4422-b707-1186f55aac4d',
    'identification_number': 56881444,
    'item_consumption_1': 1079,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 630.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55e9b39d-2161-407c-bc1f-bf77b1bdf6fd',
    'identification_number': 110480406,
    'item_consumption_1': 1068,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 416.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '987a8f16-82bf-409d-b726-f61c26cf358e',
    'identification_number': 106814427,
    'item_consumption_1': 367.27,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 8759.58,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '952da5bb-082b-48c1-8762-540ec9032327',
    'identification_number': 12384933,
    'item_consumption_1': 22.26,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 530.91,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7a2fe5b9-f015-4877-8376-fb0cbd5c0396',
    'identification_number': 102940746,
    'item_consumption_1': 1186,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1794.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '732984d8-ee5e-4ab8-a869-b2395def06ac',
    'identification_number': 106136712,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a8039f45-9077-491d-87d7-422ca72d6796',
    'identification_number': 9077839,
    'item_consumption_1': 7153,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3036.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01b7b526-a9d5-4a8d-a040-2fe9b5846c49',
    'identification_number': 108565726,
    'item_consumption_1': 22803,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8486.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4cd7a4f-f58a-4fc8-ad68-8fca94b9a87a',
    'identification_number': 106835122,
    'item_consumption_1': 4624,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1962.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b7c63d8-a487-4f72-b82e-3cbabb4a1007',
    'identification_number': 32810300,
    'item_consumption_1': 86.34,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4930.86,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '506595f4-e243-4ecb-bb94-487eabcfc66d',
    'identification_number': 49448986,
    'item_consumption_1': 2475,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1050.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '083f4d40-6755-4edc-b456-00dae9eba2a0',
    'identification_number': 43633226,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b50a2607-46c0-4a45-8b3e-c7575ddf5a22',
    'identification_number': 103475494,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58063e79-0ff6-41dd-9da4-79de99e8ef1d',
    'identification_number': 58734899,
    'item_consumption_1': 3146,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 401.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf307b43-8a89-42fe-9450-37a367f1e8da',
    'identification_number': 79645674,
    'item_consumption_1': 72,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 28.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a22de80c-3b43-4973-922c-1bffd2f08973',
    'identification_number': 34498605,
    'item_consumption_1': 1845,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 782.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59661f1c-4b3a-4c9a-a53e-d952120d3515',
    'identification_number': 23848367,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fde1a6c5-b22f-4e8e-98f3-57c5215c5309',
    'identification_number': 59440015,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4b48659-939a-4ff7-98ae-21bc34862abb',
    'identification_number': 16187130,
    'item_consumption_1': 218,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 84.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd425c30e-fa48-4c33-b6f2-164a182b80d9',
    'identification_number': 108332438,
    'item_consumption_1': 142508,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 18189.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81c51b39-c504-4d40-978c-d29711a83b3f',
    'identification_number': 84354925,
    'item_consumption_1': 613,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 238.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9926435a-f66b-4d89-b5a1-e3d3349599c9',
    'identification_number': 71236252,
    'item_consumption_1': 4228,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1793.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '024e6cd3-a300-4842-89c6-fb77945fa025',
    'identification_number': 75896974,
    'item_consumption_1': 2081,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1215.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b6bf8a3-818d-4cac-934e-d47cd753789a',
    'identification_number': 108322440,
    'item_consumption_1': 6338,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 808.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'feeefbbb-64a6-463f-813e-00094ac9aa03',
    'identification_number': 109018451,
    'item_consumption_1': 30.99,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 901.36,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '003b5e19-bb11-436d-9a80-7680884f68d0',
    'identification_number': 108065006,
    'item_consumption_1': 23,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 8.98,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'be7bc185-b4ad-4ab3-a18f-4f99ecc784c6',
    'identification_number': 60656360,
    'item_consumption_1': 2374,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 925.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c1f03cc-a6ea-490e-bcd5-6eb861e7c607',
    'identification_number': 100185568,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e58fb540-4275-45bb-80b0-9c36510bc27e',
    'identification_number': 18229077,
    'item_consumption_1': 4.59,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 109.49,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '05526eb0-7266-441e-abc4-56ebc379809b',
    'identification_number': 109460847,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '081c6bc8-1f34-46f3-8728-2d5789b18ed8',
    'identification_number': 14660601,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 52.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5c13cb26-022b-4aed-b64d-54b663b93d31',
    'identification_number': 96211008,
    'item_consumption_1': 3442,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1342.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8aee16db-ca15-47ab-bfc9-488b60048966',
    'identification_number': 16632702,
    'item_consumption_1': 2515,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 982.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e4c437b-fc29-494f-952b-a06022e2de7b',
    'identification_number': 110270410,
    'item_consumption_1': 13,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 7.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14780476-df16-424b-94de-6ba37c443351',
    'identification_number': 98722425,
    'item_consumption_1': 1631,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 637.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '198cb06b-140a-4ea8-849b-5b14e79b9131',
    'identification_number': 38893614,
    'item_consumption_1': 12.93,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 308.4,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '125da7e5-6d0e-4d6b-9479-372bc3bb5b75',
    'identification_number': 15073114,
    'item_consumption_1': 1292,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 504.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'febae351-e6da-450c-8fd2-d146bcc9a682',
    'identification_number': 109530608,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 56.33,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '64dea6ea-72ad-484f-878d-06ba3654a178',
    'identification_number': 31497861,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 41.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b9969826-ad68-4db4-98e3-580c8f7bf348',
    'identification_number': 93763395,
    'item_consumption_1': 17336,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6451.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '356b598e-5cbf-49d2-93d0-4640020b1485',
    'identification_number': 55746560,
    'item_consumption_1': 1273,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1925.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b970237e-5bef-438d-ac0b-78aa75754745',
    'identification_number': 18229077,
    'item_consumption_1': 32944,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12260.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f754d71c-bcc1-457a-beed-baa5d31317fc',
    'identification_number': 17778085,
    'item_consumption_1': 3214,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1364.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '433ef0f2-5a84-4fc5-8b58-f8d8a02627f3',
    'identification_number': 77887930,
    'item_consumption_1': 986,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1491.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07c92511-a0ad-49d3-bdf1-8a4aca791047',
    'identification_number': 94525994,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '30b1398a-1341-4828-976e-4695597b3251',
    'identification_number': 554243,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'Retenção I.R.F.',
    'item_total_value_1': "'-23.32",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '817ab1e0-87d9-400d-a73a-f8b588e1c38f',
    'identification_number': 34403566,
    'item_consumption_1': 815,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 317.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d3fa62f-e607-4ecd-ba89-47396fb94011',
    'identification_number': 44478046,
    'item_consumption_1': 1681,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 657.72,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9f2e5dbe-ae83-4a3f-8dd0-f1dbead22103',
    'identification_number': 91858194,
    'item_consumption_1': 1559,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 910.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef1019c4-c2a7-451b-bf66-ce062acd10f1',
    'identification_number': 19403224,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '48c9acbc-e1fa-41ef-b823-08c0e04deda8',
    'identification_number': 16000862,
    'item_consumption_1': 8,
    'item_description_1': 'Ultrapassagem Convencional',
    'item_total_value_1': 301.48,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '438309fa-69b6-4bcd-bf55-ccbe4554d8fe',
    'identification_number': 97343536,
    'item_consumption_1': 1945,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 759.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6e3137d-8f8c-47cd-8983-fce5441c518e',
    'identification_number': 70945195,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9d3113ef-c977-426b-9718-dff778961ecc',
    'identification_number': 64104770,
    'item_consumption_1': 112.66,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3276.82,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a09317c8-3e68-466c-abf7-c1801fbf2a62',
    'identification_number': 35211229,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '749208fd-be33-44b5-9b21-75c43ba911a6',
    'identification_number': 76778606,
    'item_consumption_1': 66,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 25.8,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd94f5aa3-fb11-4de6-8a42-a096f7a0fadc',
    'identification_number': 79644848,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0859d094-61ac-407c-a1c2-0a9966685d09',
    'identification_number': 83322361,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'efed0181-8fed-4e92-b8c1-860b03b70e3d',
    'identification_number': 92850030,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fea7a58d-1623-48de-97a3-d91f32c6f961',
    'identification_number': 108560015,
    'item_consumption_1': 4445,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6724.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd07df086-5535-490b-aea1-1b223fdbcc82',
    'identification_number': 91809908,
    'item_consumption_1': 123,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 52.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e31781d-d2f0-4532-b5b3-d9748c191b02',
    'identification_number': 7248709,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c037f834-fe9c-4f0a-902d-37ccd00bfae4',
    'identification_number': 99569280,
    'item_consumption_1': 80.22,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 3756.74,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'cd03f789-6424-424e-bc82-0429ffe62667',
    'identification_number': 98831968,
    'item_consumption_1': 44.72,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1300.71,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'eb018c7c-3ef7-4b67-8ac7-44278acc9354',
    'identification_number': 40934756,
    'item_consumption_1': 181.05,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 5266.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4948d2f6-e781-456f-b3e6-9b489d9565fa',
    'identification_number': 91823455,
    'item_consumption_1': 293,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 124.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8eadb1d5-d2c1-4edd-9497-3305c1c1dfa1',
    'identification_number': 19451113,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '893b4a13-1302-45fb-aced-b3a5e0af5035',
    'identification_number': 36648329,
    'item_consumption_1': 1796,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2717.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95c2ab5e-b5eb-47a1-9331-95d5acafb748',
    'identification_number': 111219248,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-271.68",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '0b267a9e-b7e4-4654-acc2-647b2a73b84a',
    'identification_number': 84363690,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '37e99b41-a03f-4e38-a307-92e207632a17',
    'identification_number': 31080448,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51696b14-c2fc-46d9-b715-29d674abf958',
    'identification_number': 42333628,
    'item_consumption_1': 1022,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 432.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c96a193c-0a5e-416f-abb6-e097299944a1',
    'identification_number': 41640870,
    'item_consumption_1': 1.28,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 59.95,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3678399e-3dbf-477f-8ed8-920a96b6c63d',
    'identification_number': 81859945,
    'item_consumption_1': 24412,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9084.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b679ec3-1cfd-4f9e-9a5e-860ebae90bc3',
    'identification_number': 99185296,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9b6f81e-21eb-4bf2-a112-88ed0414b06c',
    'identification_number': 43348017,
    'item_consumption_1': 2019,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 857.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77cc4b94-f9e0-4912-8f3c-1a77fa0623d3',
    'identification_number': 71547169,
    'item_consumption_1': 3075,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1202.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '766cf5f1-46b9-4c88-a4cf-261019db0e73',
    'identification_number': 63964112,
    'item_consumption_1': 18348,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2341.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64df94b2-0a46-4f0f-aabe-bdc020da59fb',
    'identification_number': 39261565,
    'item_consumption_1': 77,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 30.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a3183473-a406-4f75-8bca-347d73f2cbe1',
    'identification_number': 17682061,
    'item_consumption_1': 7995,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3120.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc2f02f3-5363-4903-9a99-bedf062654f8',
    'identification_number': 110227760,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9a73c2a-29ae-497a-ab21-86261f03aade',
    'identification_number': 19302711,
    'item_consumption_1': 23532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 10005.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26874f8a-d0ba-42d8-9ff5-86aafb8bd715',
    'identification_number': 61481467,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2191.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c2541b8-6aa2-4d17-b389-86a4f637ea48',
    'identification_number': 9161449,
    'item_consumption_1': 2074,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 1575.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c91b6284-9fe5-48df-9f2f-9a0eb50f10fa',
    'identification_number': 32234449,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 399.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5d694921-475e-4447-9622-d2a65d76db01',
    'identification_number': 29891833,
    'item_consumption_1': 6767,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 863.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd933c12-27cf-4093-b03f-58958575096a',
    'identification_number': 102890269,
    'item_consumption_1': "'-8.639",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-3008.41",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'a6bf93d5-1380-4b59-9905-e2c0f6941174',
    'identification_number': 92387578,
    'item_consumption_1': 1511,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2285.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30fb3ed1-1d33-4ce3-b084-61c192dbaeda',
    'identification_number': 84354925,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '461affb8-04b9-439b-9706-5d1db6aedf07',
    'identification_number': 21576696,
    'item_consumption_1': 1620,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 688.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4bdf347-92fc-4179-be29-f6d0ec02f5e8',
    'identification_number': 108646360,
    'item_consumption_1': 4200,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1641.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c74e71a-a1e1-4949-9652-a562a3ccc4af',
    'identification_number': 8086451,
    'item_consumption_1': 2711,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1583.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66d34680-231d-413d-9932-d1c1558ee9fa',
    'identification_number': 13299530,
    'item_consumption_1': 38.17,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2179.88,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '260cdd83-e707-44f4-bfd8-8f70883b1670',
    'identification_number': 923332497,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '71f10a0e-8da6-48ab-b909-90e41f32ce52',
    'identification_number': 19191839,
    'item_consumption_1': 1746,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 740.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '416830b3-6e84-4348-8d07-36d9bf038ff5',
    'identification_number': 106077406,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '06346c5c-464e-41da-b373-d810f1b02d04',
    'identification_number': 40178579,
    'item_consumption_1': 1064,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 415.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd02c6d47-ab00-42e6-8d27-efc7c9b064d8',
    'identification_number': 105798908,
    'item_consumption_1': 9895,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3682.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd459f374-1dc4-4b76-8fcd-3693e56e6e5f',
    'identification_number': 44476256,
    'item_consumption_1': 79413,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 29553.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '671a4278-bff5-468c-8237-7ca72a61d146',
    'identification_number': 107297671,
    'item_consumption_1': 5350,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 8093.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fafd4b75-aa49-465a-9b95-74fa9bb7593a',
    'identification_number': 14138034,
    'item_consumption_1': 36.08,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1049.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '70784c7b-5d84-41ff-842e-73a7424f33b1',
    'identification_number': 25976850,
    'item_consumption_1': 10515,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4100.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9181f90f-3523-48ea-b33f-e41db3037119',
    'identification_number': 98548387,
    'item_consumption_1': 998,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 423.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43217283-405e-4590-8432-3af99afe64f9',
    'identification_number': 66547385,
    'item_consumption_1': 3167,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1341,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '184f9bc2-2986-45fa-a8f7-ff704b75d2e3',
    'identification_number': 11103892,
    'item_consumption_1': 1269,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 495.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8856fb20-b288-48b8-94ca-4abecdbfa733',
    'identification_number': 15751740,
    'item_consumption_1': 22.73,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 661.1,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f9fe8948-3a9d-40c8-94ee-c393bd45f00d',
    'identification_number': 36849510,
    'item_consumption_1': 409,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 173.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a894249e-cc27-4b57-b0a7-ab400ff59f3e',
    'identification_number': 32234449,
    'item_consumption_1': 106.07,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3085.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd63969c2-8044-4965-981f-878919141cae',
    'identification_number': 85594733,
    'item_consumption_1': 464,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 196.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef92842a-3446-4b2d-a7c8-8ada00e27160',
    'identification_number': 15447790,
    'item_consumption_1': 1048,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 444.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fcc1ea1-6da9-493e-9e37-cdb661070df1',
    'identification_number': 91627184,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5f110bfa-bf80-48af-bdee-87ed080f7862',
    'identification_number': 93964188,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a0f7ac0d-c628-4e1e-aa86-02cf0dccf601',
    'identification_number': 23660872,
    'item_consumption_1': 15379,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1962.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '187ec2ad-1a89-482e-91c6-e97c2ffa6db9',
    'identification_number': 88508048,
    'item_consumption_1': 6431,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2511.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76229888-88bb-4c49-8784-4a5c215a0f23',
    'identification_number': 103093109,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'beb2c7b0-a134-4f9a-bfe7-12bfe8d0c198',
    'identification_number': 5330351,
    'item_consumption_1': 2750,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1076,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '23f54ffc-acea-48f8-82ec-5d2a3b30c62a',
    'identification_number': 32098120,
    'item_consumption_1': 8.36,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 199.4,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '5bf7e53d-aa91-46d5-8730-907ff24f45f3',
    'identification_number': 98105132,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '03b66617-b3a1-40a3-81e0-b29a66b89621',
    'identification_number': 76288714,
    'item_consumption_1': 48833,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 28520.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4964beaf-d454-42d4-8221-ea70c28b51cc',
    'identification_number': 97970034,
    'item_consumption_1': 56.28,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1636.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '06b10d24-8cb0-434e-8678-372b9e74be96',
    'identification_number': 91359511,
    'item_consumption_1': 12.38,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 295.27,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '925e55bb-37a6-4d85-8ad3-d4bd3b28e8d5',
    'identification_number': 77800699,
    'item_consumption_1': 18.72,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 544.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd9ac01d6-9ead-4f93-8b15-62ce0de4c924',
    'identification_number': 96292962,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a064a1a9-c049-4d68-8aac-64c96ce82dbd',
    'identification_number': 32191871,
    'item_consumption_1': 54.75,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1592.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e5b23e55-71a2-4bc8-852d-826ab25690f8',
    'identification_number': 46343466,
    'item_consumption_1': 6136,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 783.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ba9f281-26c9-421a-8332-2e487bcd85c1',
    'identification_number': 11490586,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-222.41",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '924f7bab-3572-45b3-98f6-112319d1b887',
    'identification_number': 30298482,
    'item_consumption_1': 22564,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8397.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd6f264d-4916-4815-ad30-d6717217a0ec',
    'identification_number': 28408683,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c329577d-0ab3-4676-85ea-02dfd8d87728',
    'identification_number': 111132290,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 453.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65d8521f-d124-4ded-b6ca-3b5e1aefc2b6',
    'identification_number': 51527472,
    'item_consumption_1': 1.67,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 39.83,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c295f16d-c3bf-429e-b21a-0fbe7fe04fe9',
    'identification_number': 83322361,
    'item_consumption_1': 2969,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1257.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bcba316-3956-49e3-af94-9311d68c2135',
    'identification_number': 13176846,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ebdd023e-fc35-486f-9561-7ef3567115ab',
    'identification_number': 19229747,
    'item_consumption_1': 10.52,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 492.67,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b137797f-2d06-43a8-bf4a-8dcd81e62e5e',
    'identification_number': 19452306,
    'item_consumption_1': 249,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 97.42,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f912a296-dbb0-43b8-83b9-30220fc0f30d',
    'identification_number': 9027092,
    'item_consumption_1': 65.33,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1900.18,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7d56e93d-25aa-40d4-8c46-357b1a0fee25',
    'identification_number': 103025758,
    'item_consumption_1': 482,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 729.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47a23b53-a86a-44ae-9dbb-b3bcba2404b9',
    'identification_number': 70157510,
    'item_consumption_1': 7872,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3073.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2310ad4a-910f-458a-968c-aa87a8d73f96',
    'identification_number': 18616917,
    'item_consumption_1': 95.34,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 5444.85,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'eab429b8-c447-401d-b133-ecadd57e85e4',
    'identification_number': 32810300,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '10528712-0616-42f1-a157-d42ef969499e',
    'identification_number': 109449045,
    'item_consumption_1': 432,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 166.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '146aa1f3-a53b-45b2-8cf5-215b03aa60f0',
    'identification_number': 41932510,
    'item_consumption_1': 28752,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3669.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8cfbaf83-5519-47f6-b832-d60df42fa455',
    'identification_number': 40504077,
    'item_consumption_1': 4294,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2507.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '956218ac-88f3-4415-b2d5-ad234e376257',
    'identification_number': 42034515,
    'item_consumption_1': 65263,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8329.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd8ff852-c2fd-4f66-81cc-b162a7253239',
    'identification_number': 111115299,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39a0b599-9262-4dbc-9773-e9b18aac1ede',
    'identification_number': 24017353,
    'item_consumption_1': 10515,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1342.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d9089e8-1930-403b-a53a-8a1a990723c0',
    'identification_number': 102958017,
    'item_consumption_1': 2572,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1092.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77854f58-0f3b-4320-8045-05b64f4bc435',
    'identification_number': 59161485,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26bcf09b-1f10-44cc-b532-de1090cea3fe',
    'identification_number': 12446645,
    'item_consumption_1': 133,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 52.02,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c6a4b403-a07a-47f3-a46b-3aa616885940',
    'identification_number': 105168114,
    'item_consumption_1': 476,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 186.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41d6c1fa-2f02-43de-8240-ff17dba35b30',
    'identification_number': 110067266,
    'item_consumption_1': 180,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 76.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0747f0f4-8b20-4ae3-8c00-37e2b78a188b',
    'identification_number': 20444060,
    'item_consumption_1': 5.22,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 303.66,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '295d69c2-6d9b-4d5a-8c41-464489c1a5c5',
    'identification_number': 31997686,
    'item_consumption_1': 9228,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3913.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd1973c8-bb15-4573-add8-e73491d15bf9',
    'identification_number': 44805853,
    'item_consumption_1': 55077,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7029.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4db646c-8bb2-4274-be3b-13c9a76bd3a1',
    'identification_number': 32852878,
    'item_consumption_1': 5614,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2377.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf90a411-12d5-4949-8fcc-0d1cc38cd1af',
    'identification_number': 75363666,
    'item_consumption_1': 191,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 81.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7280e03c-1e07-4418-afff-89e885a33599',
    'identification_number': 62915401,
    'item_consumption_1': 249,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 96.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd804233-1ecc-4cba-b949-99acc35f52da',
    'identification_number': 19452179,
    'item_consumption_1': 23887,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 36136.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5fa447d-c4fb-4edf-9739-418525bb6a52',
    'identification_number': 56507844,
    'item_consumption_1': 1971,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 251.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05831e27-0225-43d0-84fc-a99a48e00c87',
    'identification_number': 73802174,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd74a7051-d688-4158-ae2a-2260f5cb1369',
    'identification_number': 32556268,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 109.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '80a6f744-02a4-4120-bf24-d2ae9ba4b6d6',
    'identification_number': 17130972,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-162.26",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '9289661e-bb04-42b8-a39b-c3029ad1d371',
    'identification_number': 27549607,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV.CORR. MONETARIA AJUSTE FAT.',
    'item_total_value_1': "'-0.33",
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '65368bea-c4a3-4e6d-815a-af996e381e1d',
    'identification_number': 44520603,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7461c949-794e-4c24-95b4-5fb9d0e0da74',
    'identification_number': 110216741,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '412a5145-670b-4039-95ba-2e433668bd91',
    'identification_number': 25945335,
    'item_consumption_1': 3966,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1684.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '740f3537-bbe0-45a1-adf7-655367cc2e25',
    'identification_number': 31645313,
    'item_consumption_1': 0.7,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 16.72,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '2b9b957a-9393-424f-8ddf-4cbeaa85d5f7',
    'identification_number': 40122654,
    'item_consumption_1': 1478,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 863.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79fe463a-702c-42ad-a579-f302ac6f608c',
    'identification_number': 80787932,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2bf50ea0-cb00-4c22-9e43-8e2b8110d73f',
    'identification_number': 8115540,
    'item_consumption_1': 269,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 104.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39b9f8f0-e1e1-4fab-8ad2-094409c4f2b7',
    'identification_number': 78143977,
    'item_consumption_1': 24,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 9.39,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b84295ee-8f2b-4bca-a23e-b129d6c19c50',
    'identification_number': 19452900,
    'item_consumption_1': 884.16,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 25716.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b02f669f-68a8-48bb-a30b-534bec80faab',
    'identification_number': 14219743,
    'item_consumption_1': 4040,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1710.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18dfa26b-58de-4d13-9df8-fbbaa731bbe3',
    'identification_number': 67513212,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENT (02)',
    'item_total_value_1': "'-461.02",
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': 'e0c47c85-617d-4a98-b919-55c2f8b231c8',
    'identification_number': 96727101,
    'item_consumption_1': 9352,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3966.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '109f1882-9c37-4d61-b76c-ef21ef24f661',
    'identification_number': 90453450,
    'item_consumption_1': 123.19,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3583.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e74e2a79-6153-4219-b914-ee13a2db7ae5',
    'identification_number': 18036244,
    'item_consumption_1': 17874,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7584.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f8c6642-ea07-4606-a923-52c3dc5b49fa',
    'identification_number': 29771242,
    'item_consumption_1': 2117,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 898.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbbb7608-e238-45ec-8fd1-744bf7b352f2',
    'identification_number': 109939050,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 126.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd4379ee9-8577-456d-9d23-bf512f27ccd8',
    'identification_number': 14619563,
    'item_consumption_1': 137.56,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4001.04,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dbea629f-7967-4cba-bc46-aef59a115969',
    'identification_number': 105611441,
    'item_consumption_1': 2734,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1161.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da06e466-46e1-44ff-ad7b-16681e417912',
    'identification_number': 37645331,
    'item_consumption_1': 455,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 193.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d031441-3cc1-4518-bb4c-08d9ee6ef354',
    'identification_number': 94460566,
    'item_consumption_1': 2163,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3272.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb730445-7767-4e34-8576-dafd9a28078f',
    'identification_number': 100359140,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96e18211-5950-47f0-82a4-b996a9592ba6',
    'identification_number': 49821210,
    'item_consumption_1': 4981,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1946.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69ed80a9-d78b-431e-8073-1f48750bf7ac',
    'identification_number': 56527136,
    'item_consumption_1': 27.65,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 804.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6fa17058-11e7-4ef6-8854-d47aec1f6f87',
    'identification_number': 100968520,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '038f6f33-fcf4-4ac0-b86e-a5158adc25e1',
    'identification_number': 44478046,
    'item_consumption_1': 73.6,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2140.72,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a64b773f-3603-4b6c-b4c9-e9ecf619df86',
    'identification_number': 56886314,
    'item_consumption_1': 1.96,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 46.75,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '56b7c8ba-951e-4591-a1d8-5babf600efe6',
    'identification_number': 108322440,
    'item_consumption_1': 6338,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2358.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50358624-ba44-44d8-ac42-1484e8e501d4',
    'identification_number': 22373101,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8979c86b-f7f7-4208-ac17-b9349f8d6a3e',
    'identification_number': 62362801,
    'item_consumption_1': 3789,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2212.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1411fdbf-4120-442e-98ec-1c3e9a991062',
    'identification_number': 44001681,
    'item_consumption_1': 23.06,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 550,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd846f818-574b-404a-97d6-54a7198d66e1',
    'identification_number': 28180240,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1a918e1d-3a2e-490d-9b4f-422c6d86f417',
    'identification_number': 8708320,
    'item_consumption_1': 13318,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5192.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1b7dda9-3f3b-4f1d-ac0b-78915584aa61',
    'identification_number': 43154581,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87383364-06ef-4c68-85c6-3c99c4577efb',
    'identification_number': 33355215,
    'item_consumption_1': 73,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 110.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3195ce9-d6c5-4fcc-9876-6732832e6976',
    'identification_number': 18247733,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e7d1c5d5-b804-4b31-a86f-e831cd2ed1db',
    'identification_number': 102679347,
    'item_consumption_1': 2409,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 941.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61d7305c-f781-4b80-8529-2756df8766e2',
    'identification_number': 32605579,
    'item_consumption_1': 10.2,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 243.29,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'daebf747-f293-4566-b5d6-7f772b18982a',
    'identification_number': 11044675,
    'item_consumption_1': 4016,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1564.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6345b07-2347-4dad-a09c-43676bdf394e',
    'identification_number': 95563610,
    'item_consumption_1': 456,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 689.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0978f06-2466-4be7-8b5b-af0b1c042e19',
    'identification_number': 100765076,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV. JUROS AJUSTE FATURAMENTO',
    'item_total_value_1': "'-42.11",
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': 'bed70803-1995-4d78-9f5e-450bcb2ae2e3',
    'identification_number': 104611537,
    'item_consumption_1': 1179,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 500.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e642124-841d-4b24-8ff3-98062bbb63c4',
    'identification_number': 14689146,
    'item_consumption_1': 24473,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9107.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03f1ce8e-bcd6-42ba-9383-d21a30966c82',
    'identification_number': 40117626,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55bd5f3b-be82-4108-a0a8-01f60cd3572b',
    'identification_number': 100637795,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2eb24b55-52d2-4146-a0ab-60ef01e37d48',
    'identification_number': 42712190,
    'item_consumption_1': 14483,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1848.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53132f72-7755-4ca5-8215-5d52ab527b72',
    'identification_number': 9050426,
    'item_consumption_1': 6154,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2402.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b576438-1908-4380-8e89-d7bcd54aab7f',
    'identification_number': 45201137,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '210e4a2f-251b-416a-8322-8c4c1f7e23c2',
    'identification_number': 110227999,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eaa803a1-b682-4a73-8d7c-29e73e16e3b7',
    'identification_number': 92141200,
    'item_consumption_1': 2776,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1621.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ae14939-e1a8-4820-80e2-aae39c0e832b',
    'identification_number': 105361690,
    'item_consumption_1': 2020,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 856.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14b58c51-f399-4c72-aef4-64b9273062a8',
    'identification_number': 8115800,
    'item_consumption_1': 752,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 293.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a62cd91-264d-4f55-a038-8d45d200225f',
    'identification_number': 102259178,
    'item_consumption_1': 934,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1412.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d4b7703-8e32-4b81-ad2d-a91018701565',
    'identification_number': 106612662,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e68d6d8d-d0f8-4793-9f22-771c8b7112e1',
    'identification_number': 33998299,
    'item_consumption_1': 549,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 233.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e8fc122-4fbb-4f34-a233-0eb2a83f6580',
    'identification_number': 77405340,
    'item_consumption_1': 11004,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1404.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0b89ca4-e9e7-4b58-be84-0f42e67f42d7',
    'identification_number': 111834066,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81a27af9-e8d1-47f6-abda-916c463b9e0e',
    'identification_number': 22963677,
    'item_consumption_1': 74,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 28.95,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b4b0032a-71f9-4e0f-809f-e07a0a444744',
    'identification_number': 91350930,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7355ce5-c5ed-4d50-b083-e86db57353c4',
    'identification_number': 103005242,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bef60002-376a-426c-a5df-2cd02c940490',
    'identification_number': 22609903,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd9d380bc-4030-4a63-9b08-76c03c3156b0',
    'identification_number': 41360869,
    'item_consumption_1': "'-13.822",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-4818.86",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'a179ae6d-ce5f-4cbc-a2bd-7b7862c9ed88',
    'identification_number': 12222615,
    'item_consumption_1': 3.4,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 81.1,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3d51b0a8-9b22-4df5-b52f-40a43a09db87',
    'identification_number': 13715666,
    'item_consumption_1': 9676,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3769.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f6a99b3-3d89-4518-ac6e-1f1f2d8b5f9d',
    'identification_number': 31437695,
    'item_consumption_1': 1.837,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 678.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1533c69-3136-4b82-86b8-0a3e2f24a1b3',
    'identification_number': 106547321,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79b376ef-5c91-4306-84c0-e35a593c6746',
    'identification_number': 84238828,
    'item_consumption_1': 303,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 128.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '498f31a2-2b60-4018-a258-ae6ef04c9935',
    'identification_number': 63721651,
    'item_consumption_1': 1002,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 390.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46d67a43-3b4e-4e9c-98ec-3aaeb376bcdf',
    'identification_number': 59068140,
    'item_consumption_1': 1345,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 571.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '777ec06a-cf13-4e52-a12f-93e410b2a998',
    'identification_number': 31296084,
    'item_consumption_1': 760,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 296.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eadc0caa-72a9-4f34-8108-51f63738659b',
    'identification_number': 97018260,
    'item_consumption_1': 2115,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 824.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2a36f0c-cada-42e1-ab0e-0a5fbfca38cc',
    'identification_number': 24017353,
    'item_consumption_1': 859,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 501.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1321a7f1-976e-4a06-ad3f-066b746f00b3',
    'identification_number': 101517556,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3ec11296-7d82-4c44-bc7b-61de64acfd2b',
    'identification_number': 6189512,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1e0aec39-3a58-48ef-b735-25cca22ababe',
    'identification_number': 96294345,
    'item_consumption_1': 3991,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1693.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d0845a3-62b3-45bc-9076-94752f9bb064',
    'identification_number': 110973003,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68c5cab4-99fa-4cfb-b94f-2fe946ff0f86',
    'identification_number': 67524362,
    'item_consumption_1': 722,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 281.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c52fa1cc-e13a-4066-b433-d04a56afe2b8',
    'identification_number': 63008084,
    'item_consumption_1': 548,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 232.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb184c2a-e0c2-45a6-b899-604220eac93f',
    'identification_number': 76203310,
    'item_consumption_1': 3986,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1687.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3171d98-a429-4976-8aa6-a2cf48a10be1',
    'identification_number': 107160560,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fafe3b9b-542c-4d5f-95a5-9dbbd8963285',
    'identification_number': 99244055,
    'item_consumption_1': 1433,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 557.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b41897a1-9be0-475f-bc07-370e7b3913cf',
    'identification_number': 19611439,
    'item_consumption_1': 5084,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2151.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2cb5cbb-d5cb-4b97-ae26-54c9fcd4a5ee',
    'identification_number': 14834642,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '150c433b-e149-4c7d-b6a6-ad6b9d6a1982',
    'identification_number': 86207563,
    'item_consumption_1': 11.65,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 677.69,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '97e3d1c2-e539-4f78-b83d-ce3d6d4fc60d',
    'identification_number': 63604116,
    'item_consumption_1': 17.12,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 408.33,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2c87177f-db4e-46da-8c7f-dae038627939',
    'identification_number': 101890419,
    'item_consumption_1': 65,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 25.42,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'dd270365-9606-4238-92f4-0ecc66563722',
    'identification_number': 42433339,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '553d0c11-05e5-4601-ba8f-3ef9d58b0c56',
    'identification_number': 108380513,
    'item_consumption_1': 18075,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2307.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e995fdc6-4714-4e6e-b4f2-356ea0f939c0',
    'identification_number': 12275468,
    'item_consumption_1': 2427,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 309.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'addd240e-89f8-49c5-9532-cbba35c766a0',
    'identification_number': 38718502,
    'item_consumption_1': 1528,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 597.85,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1622adb9-466b-4886-b7e1-f735ed67ee73',
    'identification_number': 75441233,
    'item_consumption_1': 21969,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 9340.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ece47e2-862b-47fd-95a9-ea81aec56f80',
    'identification_number': 59070960,
    'item_consumption_1': 11432,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4254.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3d0bd13-2a86-47d4-a116-723c88150110',
    'identification_number': 8661758,
    'item_consumption_1': 2282,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3452.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e50655b-d9b0-4fc7-b27b-53e34a45c5f3',
    'identification_number': 27383067,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0aba62fc-c238-4ed5-8695-cd28865d794b',
    'identification_number': 94681511,
    'item_consumption_1': 3401,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1445.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ee876fd-0482-44f6-885c-f5b045d82bae',
    'identification_number': 53672275,
    'item_consumption_1': 1631,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 637.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4b188ad-2008-4fcb-90ca-cf618d15d056',
    'identification_number': 99578336,
    'item_consumption_1': 27.84,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 809.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '48a57282-0985-43f1-9f59-c48649a063e8',
    'identification_number': 12956465,
    'item_consumption_1': 9553,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5579.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6af2f3c3-e2cb-4e1f-a262-d8b459655c52',
    'identification_number': 33355215,
    'item_consumption_1': 73,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 42.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9d7dd82-e68c-4375-970d-44b3b6fdd56e',
    'identification_number': 43141269,
    'item_consumption_1': 4458,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1893.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8840b142-4967-49c0-91a6-f3963b7c8727',
    'identification_number': 108991741,
    'item_consumption_1': 197,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 83.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8b4fad0-59a4-45ef-a477-8b439ef33a52',
    'identification_number': 16968859,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b8bc90a-0085-412e-9161-70c0f4397cc8',
    'identification_number': 27112101,
    'item_consumption_1': 2557,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 326.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73f1a1d7-568b-47de-afe1-8763132e35c1',
    'identification_number': 106113208,
    'item_consumption_1': 720,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 305.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66162b3e-3549-4dfb-8f52-7223bc108c32',
    'identification_number': 95743081,
    'item_consumption_1': 368,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 143.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10925c6e-b808-4717-a1b0-11c6715ac129',
    'identification_number': 32881681,
    'item_consumption_1': 3644,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1548.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34e716cc-7d2f-40d8-b742-d30ed51227ac',
    'identification_number': 106729985,
    'item_consumption_1': 15,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 8.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c8b070f-3ee3-42c9-b22d-f6563a5186c9',
    'identification_number': 35088974,
    'item_consumption_1': "'-15.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 12/2022',
    'item_total_value_1': "'-5.21",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '34c90c4e-eda7-47cf-b2a8-f3e142575a83',
    'identification_number': 14905612,
    'item_consumption_1': 13612,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5065.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f36dbb75-fec5-4d2a-84bd-a1f148ff4f7b',
    'identification_number': 105456993,
    'item_consumption_1': 7730,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3277.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6fd2590a-7673-418a-8283-e27f88dc82bb',
    'identification_number': 76403955,
    'item_consumption_1': 11946,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1524.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ae280f2-7a41-4703-9ce0-1c20ff982a2b',
    'identification_number': 109993217,
    'item_consumption_1': 2888,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1686.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e34e18c4-3661-4c0b-92a8-c3bd616152ac',
    'identification_number': 101401116,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dd171515-42df-44fa-a5ac-71341083808b',
    'identification_number': 45582459,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2a5ed23c-5180-458f-a68c-29314bf3b2cc',
    'identification_number': 18632165,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b09956d4-7c83-4206-8fbf-929027276ba8',
    'identification_number': 35256869,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '635082c2-fcef-4515-9703-4dc18f3e7ccd',
    'identification_number': 52599400,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b827053-a2bb-496e-b2b9-0132fde04c70',
    'identification_number': 22373101,
    'item_consumption_1': 902,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 335.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bdfd0ef2-f8fd-4002-ba5d-a0cfbe8716fa',
    'identification_number': 81037600,
    'item_consumption_1': 96.92,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2819,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b27494fa-0d92-4350-aa68-5d68fa48bed9',
    'identification_number': 18456669,
    'item_consumption_1': 12.15,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 289.78,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '867da7f2-ba8e-45c7-adfd-c7c1982bb866',
    'identification_number': 59162015,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 156.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '973bd99a-788e-49d0-bb47-92e66fdb5888',
    'identification_number': 12804908,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8663377b-fa38-4157-a12e-ec933f41332d',
    'identification_number': 105098930,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '95a1e84a-47f2-4c6d-b0b2-ceae78cc6a26',
    'identification_number': 109643372,
    'item_consumption_1': 17386,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2219.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a135b54-f5cf-483d-93d7-80bc7dfeefad',
    'identification_number': 102315698,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3589ee7d-008c-4e66-80c6-243b8ab610e0',
    'identification_number': 104648201,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a977c048-fcb4-4147-a3a6-1f20d56133a3',
    'identification_number': 15751740,
    'item_consumption_1': 4995,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1858.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce4ffb33-af1c-41cc-98dc-ef8782f3fc8b',
    'identification_number': 105089060,
    'item_consumption_1': 2.33,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 67.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'bf639d51-c277-47f8-9cfb-fa285db9dd2d',
    'identification_number': 100214193,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 183.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ce7d1e34-7c50-4b46-9a14-9ac91d021010',
    'identification_number': 33800677,
    'item_consumption_1': 436,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 254.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e968486b-dd50-42b2-8b55-63f212a5f6ee',
    'identification_number': 79830919,
    'item_consumption_1': 2067,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 875.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab5252b0-c0c4-42d5-9926-c7bbec391459',
    'identification_number': 98722425,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '41d8dd13-ac55-4bab-81ff-075449af047d',
    'identification_number': 17810914,
    'item_consumption_1': 867,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 338.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26d81ad7-6b39-4d2f-b21c-f6430b1f6e69',
    'identification_number': 10293752,
    'item_consumption_1': 8468,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3591.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a75a076-1f0e-4a06-b09e-f56965f23009',
    'identification_number': 14945304,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '916288dc-bf35-4d0e-bb7a-d9b6cc7b7396',
    'identification_number': 17247799,
    'item_consumption_1': 1240,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 483.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13f91c93-d0f7-4e49-a08e-fdc29b324be1',
    'identification_number': 76376249,
    'item_consumption_1': 84294,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 10758.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd193fe61-897b-482d-a92e-881676579b07',
    'identification_number': 91064058,
    'item_consumption_1': 1243,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 486.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd57833f1-3ffb-4fa6-9bcc-df5492b9bd20',
    'identification_number': 12866,
    'item_consumption_1': 679,
    'item_description_1': 'UFER fora de ponta(Reativo)',
    'item_total_value_1': 230.49,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': 'd38cda53-73a3-4fd3-be5b-76d3bfe7a0a3',
    'identification_number': 100909183,
    'item_consumption_1': 11201,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4380.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '105e2dc8-5f22-49cb-89ef-b52bc7058e33',
    'identification_number': 14671514,
    'item_consumption_1': 7357,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2866.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7378999b-95bf-4a1a-ac04-2aa221b986df',
    'identification_number': 103025758,
    'item_consumption_1': 4402,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1638.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0b3811f-f04b-4b38-a715-18dbea6aedc9',
    'identification_number': 26988879,
    'item_consumption_1': 2368,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 922.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4abef116-959d-4e42-a231-234a258c9017',
    'identification_number': 95159320,
    'item_consumption_1': 4318,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1828.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8cd4f7eb-f040-434f-9ffe-3c98bcf2d3b5',
    'identification_number': 12973092,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '82e5684b-25af-4470-a6a0-73516daf9f50',
    'identification_number': 83623256,
    'item_consumption_1': 5.96,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 346.7,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e569911f-c6e7-454b-be22-d27b5c79f6be',
    'identification_number': 31686729,
    'item_consumption_1': 4835,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2823.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '870345a4-84d6-4c66-be16-e9487952c018',
    'identification_number': 13894838,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-79.03",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '67cef024-1216-44b6-b77a-2e9f4deb9ac1',
    'identification_number': 101805829,
    'item_consumption_1': 1671,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 651.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8c636cd-f568-48da-b722-e75670dfdcef',
    'identification_number': 59162015,
    'item_consumption_1': 5257,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2225.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4ca0c9d-417e-466f-b8d4-f7e3f3774fae',
    'identification_number': 104356014,
    'item_consumption_1': 178,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 69.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc2abf7a-5c32-40d0-aba6-0a00947a8ea0',
    'identification_number': 97005304,
    'item_consumption_1': 7.77,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 185.32,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd88cb085-3476-4f26-ae50-622c98887c41',
    'identification_number': 8115672,
    'item_consumption_1': 169,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 65.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '220d5751-f6d3-4f80-bb05-c38da81d4019',
    'identification_number': 39214800,
    'item_consumption_1': 4627,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1721.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0556092-d4aa-4f3b-b02c-54f9e66f1c71',
    'identification_number': 100765076,
    'item_consumption_1': 6229,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2427.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '097981cc-0204-47a6-899f-249d8f62c029',
    'identification_number': 32810300,
    'item_consumption_1': 6.54,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 380.43,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '43c4b91c-8202-4204-9995-7b2c442b429e',
    'identification_number': 25327283,
    'item_consumption_1': 2016,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 786.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5b06161-824a-4e84-add9-1a0014b606e7',
    'identification_number': 106046608,
    'item_consumption_1': 854,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 332.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ffc1a48-b246-4a04-a89e-a08ca4bcb70d',
    'identification_number': 14671549,
    'item_consumption_1': 431,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 251.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db65b94a-a9c2-45cc-8791-920b506584d3',
    'identification_number': 95864792,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'df04799b-9d1c-4050-b421-e302ae92df14',
    'identification_number': 108400034,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de2e8122-7607-4d6e-afc5-371dc3716ce3',
    'identification_number': 31639267,
    'item_consumption_1': 12.01,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 698.63,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '808073e9-6404-4b82-b43d-14998c76b71d',
    'identification_number': 92256686,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e6f25605-1f20-4e0d-b83f-9b82c76c0037',
    'identification_number': 5351030,
    'item_consumption_1': 1350,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 573.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a6edaca-839f-4718-b8ef-1a4d7aa3ecf1',
    'identification_number': 21718989,
    'item_consumption_1': 25.36,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 604.85,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '39086bd7-5080-4a8c-9c7c-95a1e41ad887',
    'identification_number': 22616519,
    'item_consumption_1': 4120,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1606.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfd9f874-79da-4569-a7e4-dd44cbc0def0',
    'identification_number': 47599596,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8bc75182-6518-41be-8f0d-ec786ce1a7a3',
    'identification_number': 68081251,
    'item_consumption_1': 119,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 46.56,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b219b44d-9f13-4b1d-ab37-6e826adb09b5',
    'identification_number': 91360250,
    'item_consumption_1': 32.71,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1868.06,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'aac8eed1-0f2b-430a-b489-15b10f86ead9',
    'identification_number': 39717313,
    'item_consumption_1': 1996,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 778.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5eaae588-c9dd-4a87-8c0d-e136035a1425',
    'identification_number': 90879791,
    'item_consumption_1': 5712,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2231.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9901dc19-aa30-46eb-a6d2-b0a4238711ff',
    'identification_number': 43908764,
    'item_consumption_1': 23.51,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1342.64,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0ea52468-49c8-44a8-bca4-52397bbedd61',
    'identification_number': 96256524,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc548798-4bfb-41fd-b73b-6fe15d8a57d6',
    'identification_number': 99723476,
    'item_consumption_1': 4315,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1687.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '384dd535-f8ff-458e-aff8-4c9ba88dd03d',
    'identification_number': 74591371,
    'item_consumption_1': 16030,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6268.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d958a35-6d75-48f7-bf24-28432280fb64',
    'identification_number': 89667646,
    'item_consumption_1': 3874,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1642.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '477eba9d-55c2-49c3-b071-4a9b395b732f',
    'identification_number': 53243846,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0ff99ceb-1f38-409c-b6c4-ce2cc6974c5b',
    'identification_number': 33140111,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c372a3a-462f-4818-b6e9-483e31048bf0',
    'identification_number': 12673544,
    'item_consumption_1': 4.76,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 113.54,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6a5f1762-b971-49fb-ace1-42bc24b77162',
    'identification_number': 927293026,
    'item_consumption_1': 0,
    'item_description_1': 'LANCAMENTOS E SERVICOS',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '99023ef5-e9f6-42ac-a6f6-00350d20a020',
    'identification_number': 18006540,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 164.84,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cd1926a2-76b3-4ac2-b588-1c70d99eacfc',
    'identification_number': 18798594,
    'item_consumption_1': 2.28,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 54.38,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1d262406-d2cc-4b4d-91a3-255945971061',
    'identification_number': 104766190,
    'item_consumption_1': 89.39,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2599.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f762cf7d-afd9-4b43-a43e-b285714bb6d8',
    'identification_number': 94658439,
    'item_consumption_1': 619,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 262.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9500bbab-122f-46e5-97ca-ce7e714e8874',
    'identification_number': 17159679,
    'item_consumption_1': 17422,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6483.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2f74ba8-00d4-461c-9b3c-6c7aa9d764a5',
    'identification_number': 106868250,
    'item_consumption_1': 2.95,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 171.6,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '353fa2dd-ac93-470f-880b-7c45380f9132',
    'identification_number': 97018260,
    'item_consumption_1': 2115,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 896.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7de47f5-a248-44e8-b45d-d9c543eed81f',
    'identification_number': 31497861,
    'item_consumption_1': 84904,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 31596.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea825d18-15d4-4706-b63f-224def71fc3f',
    'identification_number': 93067895,
    'item_consumption_1': 18771,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2395.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65b83350-750b-4a96-b873-f9b968c97826',
    'identification_number': 10647422,
    'item_consumption_1': "'-10.368",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-3322.24",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'caf1100d-0591-4ba8-8cd7-feade8168ce6',
    'identification_number': 37367790,
    'item_consumption_1': 3223,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1368.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c409f21-95e3-48f8-8222-20a32b5b5c9d',
    'identification_number': 110924622,
    'item_consumption_1': 4029,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1571.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e87769b-f983-45d7-8622-51bd4512d2d0',
    'identification_number': 46523286,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3be39559-f63d-4aa6-ac1e-84f9f2eecb09',
    'identification_number': 12157406,
    'item_consumption_1': 7650,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 5810.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af1151c8-8b74-4049-8e54-111187a62147',
    'identification_number': 21170568,
    'item_consumption_1': 435,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 169.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8637d078-6464-47e6-8232-22104cf26e99',
    'identification_number': 93811535,
    'item_consumption_1': 1565,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 664.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '619e9d14-cd79-4312-9458-7cd265485db5',
    'identification_number': 99050226,
    'item_consumption_1': 15216,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6449.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05f385df-c644-430b-93da-9ea293e5e7de',
    'identification_number': 108944247,
    'item_consumption_1': 86,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 36.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5d8a26f-e9ac-48ec-8d61-538ffc8f5e99',
    'identification_number': 8806446,
    'item_consumption_1': 905,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 383.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8db41f42-18af-466f-8527-3f4ed149cc3c',
    'identification_number': 16105826,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ac116b8-d55c-479d-b924-9dde6427d340',
    'identification_number': 19514247,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '405f0956-1261-401b-8141-1584e6b3daee',
    'identification_number': 109495470,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ce6e7aa-12fc-43ca-8901-47def048f5bb',
    'identification_number': 55603840,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40c359f9-88eb-455b-8c91-80aa90a90350',
    'identification_number': 75403943,
    'item_consumption_1': 3741,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2184.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd19f90d2-382c-4635-9795-4fbc9829bb27',
    'identification_number': 19453299,
    'item_consumption_1': 396,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 154.94,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '59832409-3021-4591-b586-696772771542',
    'identification_number': 97647616,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e52ed92e-a177-4cfe-b18d-9dd6b622becc',
    'identification_number': 22935940,
    'item_consumption_1': 1957,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2960.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3dbeee9c-095c-4287-81e6-ebb4e2b7ef95',
    'identification_number': 92638775,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2311.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ad424b9-e6a4-4c3c-88e9-5c070543b433',
    'identification_number': 105763101,
    'item_consumption_1': 1760,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1027.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c652c983-c650-4fbf-95e4-3ac2bc4a37a6',
    'identification_number': 16105680,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e76b41d-1ffa-4b27-a0f4-48e5e3c437b4',
    'identification_number': 56525230,
    'item_consumption_1': 412,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 623.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e510bbc8-72c0-474d-896b-732a7ed461c5',
    'identification_number': 38660334,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 127.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd84a6884-d285-44c0-940c-0fa299abf718',
    'identification_number': 64669360,
    'item_consumption_1': 527,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 196.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8766b40c-be9d-4f9c-b3ec-d82c6d197d76',
    'identification_number': 46394974,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 116.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea125712-c6bf-4ef2-a370-d91f3cd9f838',
    'identification_number': 99569280,
    'item_consumption_1': 39913,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14853.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69186700-33a4-4885-9f18-edfe61a28c9b',
    'identification_number': 103785558,
    'item_consumption_1': 1.79,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 42.71,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5b70db7f-c284-46ea-9fb8-f7de614fb876',
    'identification_number': 13010476,
    'item_consumption_1': 10.74,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 256.16,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e70d4eb6-9660-474a-bfb3-cb134ed469ea',
    'identification_number': 93430442,
    'item_consumption_1': 2214,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1293.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '307368c3-7103-4721-98dc-65a77dda095a',
    'identification_number': 98514733,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '386aeca7-9eb9-480a-9ae2-90cdd2ad341c',
    'identification_number': 42804663,
    'item_consumption_1': 1141,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 444.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bc16abc-8ba0-4cdf-8e4a-e0832b875573',
    'identification_number': 65485416,
    'item_consumption_1': 935,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 546.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8eda2b3d-6672-477b-bd7d-028b3c57620e',
    'identification_number': 18616909,
    'item_consumption_1': 30.69,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 1437.22,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '769cc48c-6ac4-4162-935f-50f74bb2ba2e',
    'identification_number': 22107860,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 74.96,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ad531788-f5fc-4168-a06a-26fd4fc1aceb',
    'identification_number': 22164391,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '466f4d2a-e3d7-486f-9476-58563db44f38',
    'identification_number': 36222852,
    'item_consumption_1': 71558,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 26630.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'abf63e10-fc00-4826-9af2-216e9fa9a06c',
    'identification_number': 57387206,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5fd87966-19af-4dea-bb43-dc4ea7b4db43',
    'identification_number': 80647588,
    'item_consumption_1': 6.38,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 152.19,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'dfb07852-c55c-43ef-881a-2ee76a547784',
    'identification_number': 12384933,
    'item_consumption_1': 7.74,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 225.12,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5b34411e-1807-4ee6-9bf6-65c519776995',
    'identification_number': 57739129,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d6f86df-aaa4-4207-a455-10f3b74d16ee',
    'identification_number': 32303912,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1428.1,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cc58e4ea-dbec-4151-a246-abad82a538a1',
    'identification_number': 39422470,
    'item_consumption_1': 27031,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3450.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6443ba4-204e-43f7-9fdb-d48b525faf4d',
    'identification_number': 12426393,
    'item_consumption_1': 10180,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4312.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17ddb418-04c5-46b5-9c84-69d6aa0f6809',
    'identification_number': 17472652,
    'item_consumption_1': 4752,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1851.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79a46da4-efea-4d23-9736-563e57f24dd6',
    'identification_number': 99215020,
    'item_consumption_1': 1839,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 780.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'beb9a567-47a7-4344-bf24-659682d25b94',
    'identification_number': 7752628,
    'item_consumption_1': 2524,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 985.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b5b8e62-f5b2-446d-b738-03bdaa18006f',
    'identification_number': 64167364,
    'item_consumption_1': 65.82,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1914.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fe41dab9-ec4b-4ce6-a79b-60fac088e525',
    'identification_number': 94385394,
    'item_consumption_1': 7294,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2853.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7379475-f6c2-4b28-86cb-bfa9566de74e',
    'identification_number': 19453779,
    'item_consumption_1': 522486,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 66688.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a16fa327-159e-4da2-80e2-e525ab371da6',
    'identification_number': 13176846,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '854e318d-c1cb-4ec7-a991-36cd81ee1e94',
    'identification_number': 53672275,
    'item_consumption_1': 1631,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 692.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95e98555-1202-43c9-9c5e-c63e6a80f60d',
    'identification_number': 11110708,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25620ded-08c0-486f-a4fc-d65bb947cb8b',
    'identification_number': 78962188,
    'item_consumption_1': 1852,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2801.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48de53d1-a076-49cc-aa28-65875c3256ba',
    'identification_number': 34681230,
    'item_consumption_1': 151,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 59.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81b8c85d-7328-4181-934b-337c6993f95a',
    'identification_number': 37998447,
    'item_consumption_1': 460616,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 171417.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '140636e6-a4c0-418a-a552-fc481e265b54',
    'identification_number': 109247140,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0f45f2ef-014c-469c-b01c-6bc76b75e213',
    'identification_number': 93067895,
    'item_consumption_1': 2315,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1352.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bf192d2-6579-49dd-911a-edd9a6e55c0c',
    'identification_number': 50776029,
    'item_consumption_1': 48,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 18.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '55b96e67-7db1-4f0b-a091-371da7e15ea6',
    'identification_number': 107359278,
    'item_consumption_1': 227,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 88.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76cdee88-c030-41f8-ae05-d472fc5b5f46',
    'identification_number': 4288262,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ebc5878b-18a4-4312-ba07-0395d5669dbb',
    'identification_number': 29984971,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c158ff0a-fd11-410b-8d28-a661c5ad4a6f',
    'identification_number': 107941589,
    'item_consumption_1': 13.8,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 329.15,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c673012a-4fca-4cd8-a8cd-0ba62c3b8178',
    'identification_number': 35854952,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72a6ac10-7c04-4917-8f60-7773cbab1416',
    'identification_number': 40636399,
    'item_consumption_1': 11086,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4125.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52e12d40-8125-4745-9e1d-afe120f56007',
    'identification_number': 33799733,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a5665b54-65bb-4ef6-8d13-35d5039a8278',
    'identification_number': 60131829,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '517c8c50-bab1-439d-a6a3-9c30f1a4ec06',
    'identification_number': 8115621,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c7374f31-0a4f-4025-bb15-86b7883db849',
    'identification_number': 39466787,
    'item_consumption_1': 10.4,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 487.04,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '080fa37c-eea7-4fd7-8f3e-bef4f8d2625b',
    'identification_number': 23081120,
    'item_consumption_1': 2951,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1254.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd4a5c12-50f3-43c3-8903-5a5c5701f199',
    'identification_number': 100856349,
    'item_consumption_1': 7917,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3364.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5eb874a-8385-4620-94dc-af9144694d55',
    'identification_number': 93133332,
    'item_consumption_1': 1779,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 753.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cca79e85-3f59-4da7-b211-eea480704540',
    'identification_number': 91667810,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85059b5c-da19-4679-bfbe-9141cd53cd34',
    'identification_number': 43036830,
    'item_consumption_1': 97611,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 36325.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbd85157-8e25-4751-853b-d74b8e6e9d38',
    'identification_number': 103005242,
    'item_consumption_1': 805,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 314.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70dd618f-3826-41ba-baae-9d1fa98096ee',
    'identification_number': 15682277,
    'item_consumption_1': 249,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 105.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b3bf1a2-5a1b-4809-9f82-d7a514ef8304',
    'identification_number': 56886314,
    'item_consumption_1': 2.07,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 96.95,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0c45c3cc-c212-4c6c-be20-807bb5d195a0',
    'identification_number': 9671471,
    'item_consumption_1': "'-10.097",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 07/2023',
    'item_total_value_1': "'-3510.87",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'e6188826-de24-40d2-8b2d-32b017b1da52',
    'identification_number': 19306105,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7b134e69-c1c1-40e5-b2f3-6c670c36d45f',
    'identification_number': 92405967,
    'item_consumption_1': 4397,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1716.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9251646f-1473-4cd1-b39d-89c5dbd67677',
    'identification_number': 35119624,
    'item_consumption_1': 464.83,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 13520.02,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c550cf2d-466b-4a81-b360-1f89cfaeffd9',
    'identification_number': 48651940,
    'item_consumption_1': 3.53,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 84.21,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b33cb4eb-17b0-46a2-ab16-1a2dee5c66b8',
    'identification_number': 948807515,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 67.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9ecf29e9-9ced-4f2d-b8d1-f7a148063231',
    'identification_number': 67536913,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd2e9c21d-194e-4e15-8548-9a1e0ee2465c',
    'identification_number': 42056306,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-208.79",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '6d3a56bd-ff1c-456d-9b32-20b07fa5e1f8',
    'identification_number': 56995806,
    'item_consumption_1': 9931,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1267.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3bfe6e57-578a-42bf-8429-24be3e5702e7',
    'identification_number': 68502982,
    'item_consumption_1': "'-10.951",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 09/2023',
    'item_total_value_1': "'-3803.12",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'eeca4da9-15fe-44ba-962f-906a210d066e',
    'identification_number': 19207611,
    'item_consumption_1': 2342,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 913.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b28a530c-3751-4bb5-bd91-5325051f775f',
    'identification_number': 105957283,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 225.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '274027cd-04a1-40c5-8467-97a6f7a74868',
    'identification_number': 105805947,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9e031a88-5008-4562-9308-bdb2a9cc01a5',
    'identification_number': 70945195,
    'item_consumption_1': 189,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 80.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d159da0-4926-4898-a8d1-c1589d77626f',
    'identification_number': 70485070,
    'item_consumption_1': 2387,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3611.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4afd78f-7a9c-45cc-8952-e8ae9631bcf4',
    'identification_number': 31818030,
    'item_consumption_1': 50.18,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1459.53,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8d40cb72-6fbc-4314-a489-3151f4c98f93',
    'identification_number': 85312185,
    'item_consumption_1': 20987,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7810.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97d62e26-ceec-4883-8589-a14298ae0ac6',
    'identification_number': 94623996,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc44e046-def7-49b6-8f60-465454575b40',
    'identification_number': 16633210,
    'item_consumption_1': 5140,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2176.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bba8ce04-83fd-438a-a59e-4fc512f9851f',
    'identification_number': 96280522,
    'item_consumption_1': 6138,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2605.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e802f2eb-b197-4e34-a253-c77d88c156ac',
    'identification_number': 84969466,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6b509c28-4690-4efb-aef7-0e7072adfc4e',
    'identification_number': 11199954,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '533fa1cd-e163-4073-ae3c-663e8475e4f0',
    'identification_number': 35825448,
    'item_consumption_1': 16638,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2123.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15722aa7-8947-444c-8d1f-4c0738ea4b66',
    'identification_number': 51210533,
    'item_consumption_1': 2139,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1249.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c55d2da-cf02-413a-8c4e-17ff835a0b30',
    'identification_number': 106077406,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cde3e416-9fd9-4ca9-ab49-53e90cf4a620',
    'identification_number': 22358609,
    'item_consumption_1': 4.08,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 97.32,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd714910a-a143-465c-9475-ea903ff6d47e',
    'identification_number': 95466096,
    'item_consumption_1': 2519,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1066.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fda3507c-06ec-407e-82ed-c1bb1569f3cf',
    'identification_number': 64643760,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6e60272-75a4-46a2-ad0a-4241443711f5',
    'identification_number': 43154115,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23318acd-01cc-4398-b4ca-01b31c43f507',
    'identification_number': 33751102,
    'item_consumption_1': 1602,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 624.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea3acd90-f05f-4a38-b738-d08d397d0140',
    'identification_number': 111270928,
    'item_consumption_1': 36,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e11d2cd8-dac5-45b2-ad6a-213f1096df80',
    'identification_number': 14360241,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '095de4ea-fe1b-4014-b358-5f32b0d1a91d',
    'identification_number': 99929929,
    'item_consumption_1': "'-6.911",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-2406.02",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '2d3a12c1-ffc2-443d-b588-905c38b9baa4',
    'identification_number': 109278461,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31327a0d-c74e-425f-bf8e-310d4e0318fd',
    'identification_number': 94446547,
    'item_consumption_1': 6281,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2452.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7740470d-aeff-4a13-94e8-f5cedb078e95',
    'identification_number': 28597095,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 74.96,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd53c90c0-a863-4c0e-8ba8-8bca178d33bb',
    'identification_number': 51527472,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c2196f7d-2660-4cdd-b1a0-3637d7badafa',
    'identification_number': 32594852,
    'item_consumption_1': 379586,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 141261.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4f6c708-5a5c-49c7-9e23-b338e1d934d5',
    'identification_number': 16296060,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62bd4fcf-492a-4f3b-a5e5-0a01d6bdcd2b',
    'identification_number': 107936704,
    'item_consumption_1': 33.8,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 983.08,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '99361383-d8b0-4e9a-8d39-53783bd29397',
    'identification_number': 109618866,
    'item_consumption_1': 192,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 75.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52578be2-5579-47ca-a958-e4a420468268',
    'identification_number': 91670942,
    'item_consumption_1': 2.06,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 119.83,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8d2bef0e-e5a4-4cf4-b30c-aa48aa5729d8',
    'identification_number': 111115213,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42e2bc90-a279-4a6e-a08a-2e5e9e4fa977',
    'identification_number': 31903991,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4f085d4a-1908-4cc4-8c49-94c9c5a282bf',
    'identification_number': 43612067,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-9.18",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '070e1c8a-722b-49cc-9e90-3ab8a861bf6c',
    'identification_number': 109402049,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 45.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f7175f86-eaa8-4082-8626-066d7d623383',
    'identification_number': 103152130,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c934c48d-a22b-4f8b-9e27-553770df7367',
    'identification_number': 20602510,
    'item_consumption_1': 1565,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 663.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b218f9d-c9c2-48c6-911d-01e3dd289255',
    'identification_number': 108331024,
    'item_consumption_1': 247.96,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 14160.98,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'abb55304-52da-41db-b551-00754d01876d',
    'identification_number': 92850391,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fa72f8ed-5f43-425f-9432-d31a118a5e14',
    'identification_number': 101068484,
    'item_consumption_1': 215,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 84.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0002bdc8-758d-4e99-9d0f-c2e3f18fa58e',
    'identification_number': 103877266,
    'item_consumption_1': 1099,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 428.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c425d53-ef39-4af6-9af2-a044ce5f3e26',
    'identification_number': 67536913,
    'item_consumption_1': 1328,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2009.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfaf7422-d2fe-457b-8097-5fdbaea764cb',
    'identification_number': 94697094,
    'item_consumption_1': 774,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 327.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dea71772-e40f-4db8-bf63-a6320ac08857',
    'identification_number': 98598945,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9c8b2a95-9931-4557-a6c8-6526c24d1d88',
    'identification_number': 58738428,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6b2a6258-8218-4630-8ea6-55125e67ad6c',
    'identification_number': 94391645,
    'item_consumption_1': 188,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 73.56,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '72778b58-2ad2-43ab-b1e5-605d7c3c44ff',
    'identification_number': 48043907,
    'item_consumption_1': 1051,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 613.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '602f39d6-ce43-428a-81e9-f8bab0661508',
    'identification_number': 18274099,
    'item_consumption_1': 1372,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 581.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ffd4308-cc6e-42f3-bbec-aeab5eb17123',
    'identification_number': 81500513,
    'item_consumption_1': 1310,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1981.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9375a6ba-2a80-4b4e-903c-2a53e101f3fc',
    'identification_number': 100637833,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '145465fa-b7c7-40fe-8932-c2a1e0a17980',
    'identification_number': 64104770,
    'item_consumption_1': 12.66,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 736.45,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '514db52d-ac60-4278-800a-5d0e5ea576c8',
    'identification_number': 91393973,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '66c33fde-62ac-4b1c-80ce-66286c470890',
    'identification_number': 12956465,
    'item_consumption_1': 159.01,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4624.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0162164d-05ab-43cc-9466-ec860f8016fc',
    'identification_number': 59484896,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '106bdac0-331e-499a-bba6-80bf1090cadb',
    'identification_number': 43338739,
    'item_consumption_1': 4957,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 632.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a31a8777-2f06-4988-9f08-7bd65bd0810c',
    'identification_number': 91417953,
    'item_consumption_1': 1049,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 445.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd45854ba-b84a-4527-8bf8-103374d4a200',
    'identification_number': 106460323,
    'item_consumption_1': 5520,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2157.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6f0888b-cdca-4e51-ad4d-ce2f03ddf90c',
    'identification_number': 107010062,
    'item_consumption_1': 221,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 86.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cea13ac5-d704-44dd-9500-1e1190f41850',
    'identification_number': 16978293,
    'item_consumption_1': 8340,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3540.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb0e7f6c-3b57-4d70-8702-49ce685b9dfd',
    'identification_number': 13015346,
    'item_consumption_1': 287,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 112.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02fe8842-e1d0-480b-8778-eb33bca3391d',
    'identification_number': 32191871,
    'item_consumption_1': 1.25,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 29.84,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd3f3e4f1-8c10-4859-b4bb-68e8084d5f15',
    'identification_number': 46424792,
    'item_consumption_1': 1716,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 726.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c620617-b39b-4fae-8981-0bd2c299fed1',
    'identification_number': 85443875,
    'item_consumption_1': 836,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 325.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d5e6145-9dcc-48e5-a882-285069fedbad',
    'identification_number': 34560980,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0f4c4e46-62ac-499f-bdb0-aa520bbeac19',
    'identification_number': 26139111,
    'item_consumption_1': 141.59,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4118.26,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f9188e0d-565f-4d71-a875-4e4a44c42c5f',
    'identification_number': 58696172,
    'item_consumption_1': 358,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 140.06,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3b05f234-0147-401a-9543-cc55133bdc21',
    'identification_number': 25214934,
    'item_consumption_1': 7819,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3051.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3818ca5a-5681-4b09-819d-1c20eb4a6d24',
    'identification_number': 96912278,
    'item_consumption_1': 2889,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1225.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99f75ee4-a178-4fea-a04d-3fe93190ba8f',
    'identification_number': 41418417,
    'item_consumption_1': 20491,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7625.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a2211e2-e3b8-4bfd-8119-f236aac21432',
    'identification_number': 104114550,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12ae3ee7-c3c0-4336-a2ff-8a3c95290ff2',
    'identification_number': 42117402,
    'item_consumption_1': 3138,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1331,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e4875e1-2a78-42fc-8970-56df66659f0f',
    'identification_number': 77217896,
    'item_consumption_1': 72.02,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2094.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '04a6ed8f-e896-45f7-95fc-aaecba83a7e2',
    'identification_number': 37924117,
    'item_consumption_1': 866,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 338.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e53bd59a-0035-48a8-bf1e-6bfdd82019a3',
    'identification_number': 87333597,
    'item_consumption_1': 5717,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2426.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4099e787-398f-4e54-b006-29484872d0af',
    'identification_number': 91394031,
    'item_consumption_1': 36913,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13737.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2476f4d2-aa9e-480e-90c6-281344a8f3f1',
    'identification_number': 18280382,
    'item_consumption_1': 3228,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1367.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f997a5d-ae08-4688-aa5d-d20a3fd51669',
    'identification_number': 79702236,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1304755c-bb29-4a62-a269-65094bc32273',
    'identification_number': 19450133,
    'item_consumption_1': 232872,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 86662.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fbbd4e0-c2ad-47ee-858b-e52d4034ee12',
    'identification_number': 94805199,
    'item_consumption_1': 4658,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1972.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd0c1cbf-b226-42f7-bb7e-372e77360159',
    'identification_number': 923309255,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13bd169d-3d89-4358-909f-cca8dec513f5',
    'identification_number': 105849944,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b1cf38ca-6ef6-47b6-9946-760a8d48733a',
    'identification_number': 54582881,
    'item_consumption_1': 865,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 366.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '912f8cb5-165d-46b6-a71a-c23f6afe6aba',
    'identification_number': 48432130,
    'item_consumption_1': 14.9,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 866.74,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '996abb08-10ee-4e43-960c-8acebc203cb6',
    'identification_number': 38718502,
    'item_consumption_1': 42.28,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1229.73,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd2bf6dd7-45db-4a23-8ddd-acd27c4c2a49',
    'identification_number': 83757988,
    'item_consumption_1': 744,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 315.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c0c2322-ec0a-49d2-b5e7-e0abdea0de72',
    'identification_number': 28803809,
    'item_consumption_1': 5996,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2337.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '725300ea-a767-4357-ba07-6774691ffed9',
    'identification_number': 27796833,
    'item_consumption_1': 11073,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4687.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e5c7c09-36e5-4fb6-a49b-4bb8e479d51d',
    'identification_number': 97791091,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0fd8fd2d-56fa-4842-99f0-614c40eea45b',
    'identification_number': 43879772,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efcb011d-9483-4c43-96a7-2aa3aa8b21cb',
    'identification_number': 52358259,
    'item_consumption_1': 659,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 384.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '934748e3-b8a7-4d43-a0fe-a80c14812a3e',
    'identification_number': 63964430,
    'item_consumption_1': 7438,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2768.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f205334-43fd-4533-8259-d1a53c2474ca',
    'identification_number': 929824562,
    'item_consumption_1': 564,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 491.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad5d64f0-4e2f-4670-a6df-07928f3a0712',
    'identification_number': 23078758,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f740f52a-53a0-4e44-9e48-2f8cce7fa564',
    'identification_number': 55581439,
    'item_consumption_1': 1673,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 708.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8eff9f4d-7637-4db0-ac86-25b24bca4923',
    'identification_number': 109446852,
    'item_consumption_1': 2256,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 881.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4fe9ad0b-d2e2-4060-a5bd-baa8ecbc767d',
    'identification_number': 43626955,
    'item_consumption_1': 70,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 27.36,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '11d14a77-935b-4624-8711-958930eee53a',
    'identification_number': 79948790,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22838594-f4c6-4ce1-9959-b9c0b277ccc5',
    'identification_number': 17159660,
    'item_consumption_1': 16803,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2144.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06ecdf45-571c-4912-aca4-f26da9a2d491',
    'identification_number': 40657663,
    'item_consumption_1': 7475,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3173.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '387503c9-bb17-41ac-88b5-5adbe8108de4',
    'identification_number': 33375453,
    'item_consumption_1': 20361,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2598.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '141a144a-8e4f-42ab-b10d-4c454ad27716',
    'identification_number': 94172170,
    'item_consumption_1': 13718,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5829.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60b70fcf-20c7-424c-ba35-d3ec3f2ea577',
    'identification_number': 16476727,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '35320cb5-acad-4ba1-a8a2-7cb335c683a5',
    'identification_number': 28168615,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78e5c3a4-49c0-4d03-87d5-c0903d350d7f',
    'identification_number': 18798594,
    'item_consumption_1': 1953,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1140.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3386e94-e3e0-4018-abfc-2c1838a86465',
    'identification_number': 75390990,
    'item_consumption_1': 5194,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2205.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70b8647e-66f0-4cbf-9f6b-e84041c52d5d',
    'identification_number': 21817405,
    'item_consumption_1': 69114,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8821.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '791308b0-8fb9-4bb4-87c3-6aa84453f835',
    'identification_number': 23078758,
    'item_consumption_1': 10036,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3734.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab8f1924-2bb9-4a09-920d-a0bfd828adac',
    'identification_number': 100214193,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a8408235-ed4e-4ec8-8d11-ade6e9be4ff3',
    'identification_number': 98715500,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bd527eaa-8e7a-48c9-9aeb-aef443326c92',
    'identification_number': 92334865,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ec33da2f-13e2-4e89-be31-4199233872c5',
    'identification_number': 95009914,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ff12615-a81d-4c3f-b63b-5012d5c6cb6c',
    'identification_number': 19875460,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '576bb623-e9f5-4cfe-917f-7d3330c9e32a',
    'identification_number': 96988312,
    'item_consumption_1': 131,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 55.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed864eba-b284-4dc3-b682-997600f9a8d1',
    'identification_number': 18398499,
    'item_consumption_1': 6719,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2855.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ec0fc7f-67d9-44bf-a301-5758bfe1c6a6',
    'identification_number': 13921789,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0d171e2c-3ccb-4a3e-8415-c2a760ee4d19',
    'identification_number': 27516008,
    'item_consumption_1': 740,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 289.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3053f58-af84-4bac-8522-4ea65855bcf5',
    'identification_number': 92159257,
    'item_consumption_1': 192,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 81.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ed0cd0f-173d-49e9-b7d1-bc3cdedff2b7',
    'identification_number': 35559217,
    'item_consumption_1': 70733,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 9028.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a774e91-352b-45a3-b0b4-48e47a1a51d5',
    'identification_number': 16386671,
    'item_consumption_1': 12740,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5414.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e92fdc71-364a-40b9-9f25-b7527a1e8765',
    'identification_number': 98015052,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb916e82-5665-492a-8db7-8b1b94d77a69',
    'identification_number': 16105192,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55e16001-59fc-4634-9cd9-108e9a74cf5f',
    'identification_number': 101143010,
    'item_consumption_1': 151,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 58.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42219704-a21d-4d67-80c6-8d9dc0c484b6',
    'identification_number': 23691530,
    'item_consumption_1': 1.76,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 41.98,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '099f1ec5-6a45-4ef5-bd43-bd46841ec1cb',
    'identification_number': 58526137,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9139c07-bf9a-4d1e-abd1-f3b9013a73ba',
    'identification_number': 40859401,
    'item_consumption_1': 2346,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 915.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccb59f51-c437-4fba-9b2d-42e7299d6d4c',
    'identification_number': 37490109,
    'item_consumption_1': 5406,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2296.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f78c8bf-c7f3-4295-813a-42d99c8525de',
    'identification_number': 32715811,
    'item_consumption_1': 9.53,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 227.31,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '39cb9d24-784a-4d30-95ca-2992bf4120cc',
    'identification_number': 35315628,
    'item_consumption_1': 9194,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3894.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b27fb870-0d79-412d-99b3-b8c885b16a6c',
    'identification_number': 56912447,
    'item_consumption_1': 8576,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3342.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e912fc3-fe81-4527-a4ca-2d8d5dedebaf',
    'identification_number': 948743454,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a62390de-7066-43d6-84f3-d4b882681e78',
    'identification_number': 68475594,
    'item_consumption_1': 601,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 254.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9043efec-9770-4794-923d-cecd59355b06',
    'identification_number': 18270786,
    'item_consumption_1': 956,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 405.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36c91f87-f60c-4fe4-897b-17836b65a466',
    'identification_number': 58696172,
    'item_consumption_1': 4.42,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 207,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '547d82f4-51ab-4328-b4c5-ecd868addc66',
    'identification_number': 20367384,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 1030.29,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '1c2a481d-3daa-4ebe-af1e-6843526c4d93',
    'identification_number': 101073593,
    'item_consumption_1': 44898,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5730.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd5d3d1f-1307-4987-abbc-8d2a56c07307',
    'identification_number': 55932550,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0cd2ca9a-4dc5-4569-94bb-60736971f0ab',
    'identification_number': 32008120,
    'item_consumption_1': 2283,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 968.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0782627e-2500-48ae-9fd7-c031986d530b',
    'identification_number': 104707127,
    'item_consumption_1': 1866,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 728.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45b10de0-9597-423d-88d3-333fd8fa161c',
    'identification_number': 96692979,
    'item_consumption_1': 56,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 21.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '723d2c20-dc50-4eb6-9c3a-bb153f8a8d8a',
    'identification_number': 109161017,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b470d69b-f4f6-4f36-9e79-166a84bdf09e',
    'identification_number': 102362378,
    'item_consumption_1': 13816,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5141.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d20b917-7361-4846-84be-0a92dcd84982',
    'identification_number': 34680292,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd0e3c6f8-9946-4488-b9d2-1472da6eacc2',
    'identification_number': 56526580,
    'item_consumption_1': 5110,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 652.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1610c41-d48f-4f9e-a182-0bbf56da7f19',
    'identification_number': 70574502,
    'item_consumption_1': 13.29,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 316.99,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4710f93f-4c1d-4674-8bbd-a6d433471492',
    'identification_number': 94502706,
    'item_consumption_1': 1147,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 448.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11204797-596e-4c6b-ab73-e5299196ca75',
    'identification_number': 923309206,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fec85e0e-44fc-4813-87aa-7cef2648336b',
    'identification_number': 53507460,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48d3e608-d5f3-4a4c-bf78-e49716759602',
    'identification_number': 36293067,
    'item_consumption_1': 6889,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2695.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb26ca0b-d3a7-4869-a354-c8f6a90bc751',
    'identification_number': 44888848,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '69d4150d-0497-48d3-818a-cd4244d2cfc8',
    'identification_number': 102891028,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0b808062-6f77-4acd-ae87-9cccd4a812d0',
    'identification_number': 52191362,
    'item_consumption_1': 567,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 221.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8cc9ab34-4ebc-453e-9d73-ef8768fbd687',
    'identification_number': 26010658,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.58,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f4a753b3-a49f-4971-b4ca-02ebbb3d61ff',
    'identification_number': 95609679,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.85,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '46af166c-0cf3-4542-82cc-e586c8cbd2fe',
    'identification_number': 43154069,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01563723-554e-4cb9-861a-d046daa56d38',
    'identification_number': 16179307,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b9554320-c887-4b92-8895-e787811d27ca',
    'identification_number': 77411609,
    'item_consumption_1': 21.46,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 1004.99,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7f393cd6-e315-40e3-a1b1-b3a117d65dff',
    'identification_number': 12381519,
    'item_consumption_1': 14,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 5.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ff499233-da6d-454e-8b74-1fa87a33ba1f',
    'identification_number': 108435970,
    'item_consumption_1': 1213,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 473.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e59427df-4758-46d9-a60a-008097c13276',
    'identification_number': 67536913,
    'item_consumption_1': 21762,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2777.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb621b12-2c5c-4587-bc8f-c8966be191c9',
    'identification_number': 101006675,
    'item_consumption_1': 236,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 100.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05e17490-0a3c-463d-9261-afd61d92e35e',
    'identification_number': 39922898,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42145f54-dfba-425f-a89d-8a5c34e23d89',
    'identification_number': 101256507,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd5c2dee6-f623-45b6-ad91-409f7ef2d588',
    'identification_number': 75037653,
    'item_consumption_1': 633,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 268.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73588ea7-ec87-40e1-9551-3ad8b427497e',
    'identification_number': 41360869,
    'item_consumption_1': "'-171.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-54.85",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'c2ed861d-ee90-40e3-9c1e-adfcb1d1824b',
    'identification_number': 32076622,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a87a67bb-e08e-44d5-97e3-c3586edc094f',
    'identification_number': 42034515,
    'item_consumption_1': 4683,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2735.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c10ac89-b0d6-425b-ac51-f4ca404753dd',
    'identification_number': 41422775,
    'item_consumption_1': 8.46,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 201.78,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0bf071d6-f717-4bc1-a708-cda9bcdbe051',
    'identification_number': 31945252,
    'item_consumption_1': 2441,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1425.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f5ac27a-c41c-4ea1-b327-a1d9749ae4a8',
    'identification_number': 40184188,
    'item_consumption_1': 153.01,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4450.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '81f2ee95-97c1-46a7-b4ff-fa59766b387a',
    'identification_number': 104408405,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4440353a-e69d-4152-84bf-9311310a424b',
    'identification_number': 14619563,
    'item_consumption_1': 63234,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 23532.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aabf3888-7b4c-430b-ad8f-a1001c4165b4',
    'identification_number': 103752234,
    'item_consumption_1': 90.21,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2623.82,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a9beb401-bfcc-4c8d-91de-3ecd1dd72409',
    'identification_number': 80146139,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-9652.12",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'b0d9c138-cccb-413a-9d76-5f9f36ab490b',
    'identification_number': 68385447,
    'item_consumption_1': 2759,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1611.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'caaa687e-a9c2-42da-84d9-d9c4f2e973e5',
    'identification_number': 32266847,
    'item_consumption_1': "'-28.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 01/2023',
    'item_total_value_1': "'-8.95",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'cf77793d-033d-4eea-bf9f-77fbe1a2190a',
    'identification_number': 70082286,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV.CORR. MONETARIA AJUSTE FAT (02)',
    'item_total_value_1': "'-40.47",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'f72c227c-1a06-48d6-ba09-f380b3fe7c3e',
    'identification_number': 102983011,
    'item_consumption_1': 1946,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 758.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d3399df-68d3-4007-9e01-158216c2818e',
    'identification_number': 14430754,
    'item_consumption_1': 57,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1359.48,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5ce66bdd-0cf7-4940-bf25-a0a523ccca24',
    'identification_number': 57387206,
    'item_consumption_1': 19.88,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1156.44,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '28442d1b-d880-4e0c-ab7f-d62c542eec98',
    'identification_number': 82724873,
    'item_consumption_1': 1663,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 971.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e73a6a2-3523-4e1a-8671-bc7c7ba3b1d2',
    'identification_number': 57673160,
    'item_consumption_1': 2193,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 279.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31a3ee2a-88fe-48e5-ae29-bd344063bcfe',
    'identification_number': 54073901,
    'item_consumption_1': 45.43,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1321.36,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'aaac3190-0b91-47fb-b9b1-f1d863bdf33b',
    'identification_number': 16724275,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 92.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '616a7876-ded4-49ef-b5fd-2b4e618d4117',
    'identification_number': 59486058,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4fef4ea-593e-4025-a18e-6d7304098131',
    'identification_number': 23135328,
    'item_consumption_1': 91,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 35.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6f215b4-e208-405f-9771-49d4f8e111fc',
    'identification_number': 32446292,
    'item_consumption_1': 833,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 354.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0410a12-7f40-4cb1-8fdd-0eaef268a77e',
    'identification_number': 1272543,
    'item_consumption_1': 21,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 8.18,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '162639a9-5f00-4260-98be-d8e0035e78c2',
    'identification_number': 108393798,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cadbcf38-ddb8-450b-beaf-8bfa9f531f8a',
    'identification_number': 8866163,
    'item_consumption_1': 3.77,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 89.93,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e3334929-e755-4021-9bae-4dc83b77bc86',
    'identification_number': 53263081,
    'item_consumption_1': 90.03,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2618.59,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fa20ae0c-7ced-4e88-9e04-d2e79558771e',
    'identification_number': 102153094,
    'item_consumption_1': 350,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 148.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92d908c3-ae3b-4152-a2e1-0f0d95e5fb95',
    'identification_number': 82724873,
    'item_consumption_1': 4.21,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 244.9,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '3bebebac-2b68-40f7-8b21-e37b61e55045',
    'identification_number': 97851256,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bdfbf8c7-ab6a-4972-9b2d-df2e1e5f8a98',
    'identification_number': 30298482,
    'item_consumption_1': 45.41,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2593.35,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '89fa3797-d55f-4e5c-9d73-bfb16dcc909b',
    'identification_number': 6307272,
    'item_consumption_1': 1197,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 468.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d87247f-d08b-487f-9c0c-fe381664653e',
    'identification_number': 98662007,
    'item_consumption_1': 1204,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 510.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0106a76-87a4-4ff8-af54-2da25bc6c487',
    'identification_number': 61583758,
    'item_consumption_1': 23313,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8675.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab725800-7b6b-45cf-971d-dd19ba9537af',
    'identification_number': 101940122,
    'item_consumption_1': 27,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 10.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bfb28ddd-c65f-40bb-a8c8-3b4e4b3b3e21',
    'identification_number': 47075066,
    'item_consumption_1': 8841,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3455.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9c327ec-5bbd-464f-8d42-72143dfb3008',
    'identification_number': 97353590,
    'item_consumption_1': 4236,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2473.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bace039d-5360-4b73-ae60-1b1c2a4f4dbc',
    'identification_number': 46920617,
    'item_consumption_1': 6,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'af4efbf7-b56a-4d35-b785-e5feb8d89cd0',
    'identification_number': 44478046,
    'item_consumption_1': 2720,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1588.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f742b76c-ce3e-449d-8bb1-b4946ec354ff',
    'identification_number': 40432980,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d439608-16ca-4e4b-a140-7ce935819b05',
    'identification_number': 17578566,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 106.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a09fa7d6-9f88-4bb0-925c-2878927c7642',
    'identification_number': 103928065,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '89373033-bc20-4606-91e3-d49850b8981e',
    'identification_number': 48293750,
    'item_consumption_1': 7305,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3092.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74fc10a4-bed0-4722-8f7d-7d04da1dd663',
    'identification_number': 104906430,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 116.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fe20762e-4552-46d7-bde9-54b7dec27f9a',
    'identification_number': 12718750,
    'item_consumption_1': 78.03,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2269.56,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '48c938f5-f9bd-419e-b327-5b0c47d5ffbc',
    'identification_number': 108601676,
    'item_consumption_1': 160,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 242.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32e55062-7597-469f-b9f8-44eb21bd8cc7',
    'identification_number': 27709353,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5c3d7cd-7bbb-4c9c-a1ca-cb7abc1de328',
    'identification_number': 17578566,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 95.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ca6dba11-0f12-4f6a-8f69-ae524513ecd7',
    'identification_number': 84983981,
    'item_consumption_1': 69.07,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2008.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4dc9fb45-eef5-499f-bbc9-71dd92439cf7',
    'identification_number': 16166558,
    'item_consumption_1': 2885,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1114.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6eef351b-7f9a-4718-ad8a-b97d851c395a',
    'identification_number': 105663247,
    'item_consumption_1': 212,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 27.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '023f9526-ff24-4816-a34f-9fc05ea874ac',
    'identification_number': 52635260,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV. ATUALIZ. MONET. CORR FAT',
    'item_total_value_1': "'-10.85",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '86640aba-8f22-4ba2-a4f7-27808fd4aa4c',
    'identification_number': 112252974,
    'item_consumption_1': 5070,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1973.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cdb568f6-b3e5-4afd-95a8-87ef32f96212',
    'identification_number': 10932151,
    'item_consumption_1': 8554,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3345.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60e1c6a3-e181-4031-aa0e-8d66d8903e20',
    'identification_number': 51920220,
    'item_consumption_1': 1640,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 639.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3344095-701a-40ad-a919-f55f9d0e95f2',
    'identification_number': 17034620,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3bb0dfa-dc6c-40e4-8268-85c22a4cb3cb',
    'identification_number': 104766190,
    'item_consumption_1': 3395,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5136.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fcf77176-871f-4683-a4dd-7663b187c10e',
    'identification_number': 32503784,
    'item_consumption_1': 107.84,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3136.6,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6df5a955-6dd8-4494-98f5-95b36e7277c5',
    'identification_number': 102560935,
    'item_consumption_1': 4773,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 609.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a69e47e-7942-4e9e-acc2-6a4fb68511fa',
    'identification_number': 77724895,
    'item_consumption_1': 71.7,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4094.78,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '413e6143-5827-4563-b452-9f9478006852',
    'identification_number': 60092904,
    'item_consumption_1': 20.51,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1171.33,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'dae5a594-436d-4ab1-a2f4-71a0a26470d0',
    'identification_number': 68081251,
    'item_consumption_1': 14165,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5271.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2827a3e2-e9c7-4c3f-b129-a46bb81fd9be',
    'identification_number': 13877984,
    'item_consumption_1': 500,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 195.63,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '81d6e8aa-732f-4ba0-8374-5d4343c4e744',
    'identification_number': 90790499,
    'item_consumption_1': 48.56,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2773.24,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ade98a01-4616-4862-881b-73a7500cad90',
    'identification_number': 12866,
    'item_consumption_1': 90,
    'item_description_1': 'UFER na ponta(Reativo)',
    'item_total_value_1': 30.55,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': '7623fc8f-ffa5-4b5e-8509-bfa480266717',
    'identification_number': 103365087,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a29a3fa0-59ab-4dc5-98be-120b05c6aecb',
    'identification_number': 103799125,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8353cd67-66bb-45dd-8824-6300febcbb99',
    'identification_number': 95597727,
    'item_consumption_1': 12460,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4870.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fb30303-16a8-4cb1-9a8f-01ccf852e34a',
    'identification_number': 79948790,
    'item_consumption_1': 3532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1380.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08f61100-e917-4ab3-bdb8-ff879c33d152',
    'identification_number': 96026782,
    'item_consumption_1': 45.16,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1313.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b164684b-75ed-4786-bc04-e60b8a601e24',
    'identification_number': 85661236,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53b591be-6449-43f2-8a4f-c30375308370',
    'identification_number': 38772043,
    'item_consumption_1': 3370,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1314.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7c7a2a6-351c-4fe5-b308-cc28b5c6cc63',
    'identification_number': 111834171,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4e3c846-6283-47e0-930a-8a4559590e44',
    'identification_number': 17000424,
    'item_consumption_1': 2523,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 984.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57175947-1763-4e18-a894-e13ac3121fee',
    'identification_number': 47023090,
    'item_consumption_1': 47.67,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1386.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '26c6ea46-df13-4743-9184-afee15294360',
    'identification_number': 105275247,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1b386e46-987d-4eb6-b4fc-9e362eb8e215',
    'identification_number': 19452179,
    'item_consumption_1': 420,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 164.32,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c9e559fb-7b8c-402e-b213-9543123f2e51',
    'identification_number': 69572968,
    'item_consumption_1': 5084,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1891.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c64bacb-e44c-44ff-9eb4-084f72debd2f',
    'identification_number': 108197271,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69bad487-977e-46ae-9995-775c6aa20e3a',
    'identification_number': 97259810,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f75f02c8-4ddf-4631-8934-fb8242beefb5',
    'identification_number': 97647616,
    'item_consumption_1': 151,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 58.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9cb6828-8844-4999-95c5-e79fe3baffbe',
    'identification_number': 99211823,
    'item_consumption_1': 22794,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2909.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b39ed960-ecbb-409a-8c36-f6e37cb0e5b3',
    'identification_number': 14389754,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '32e7eb9e-bf9e-422c-bb11-96353ca815db',
    'identification_number': 44001681,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4b10b705-cad5-4fa0-9bc7-93380be485e5',
    'identification_number': 72858710,
    'item_consumption_1': 14259,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1819.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16b0e543-248d-44d8-9bda-e2968ea60e12',
    'identification_number': 98713299,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0e17010-8198-48b2-ab80-5ae827bbc740',
    'identification_number': 110992040,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a5a650e5-f3fa-4398-aca9-705e8c453eec',
    'identification_number': 18987133,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '810ecdac-e271-43cc-8205-f238903260d5',
    'identification_number': 96460067,
    'item_consumption_1': 5356,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2088.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f7516f3-da4c-419d-904e-5f81171c42b6',
    'identification_number': 34680292,
    'item_consumption_1': 2818,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4263.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60185bcc-823f-4543-81ba-64ec590f7399',
    'identification_number': 81217846,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38740fd0-6f2a-4c62-ad9c-2ae8a09f6b5d',
    'identification_number': 107791463,
    'item_consumption_1': 375,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 159.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55d9f4d4-0bca-4672-a6a1-a4772f38bdb9',
    'identification_number': 93612397,
    'item_consumption_1': 1426,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 606.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4c7ae13-232f-40f4-8480-5decdb5d8301',
    'identification_number': 77724895,
    'item_consumption_1': 28036,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3578.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c804c402-38ae-4771-bb45-c939cebbd8c9',
    'identification_number': 28293991,
    'item_consumption_1': 373,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 145.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84a4e8e1-113f-47bd-ad53-f1eea6096c6b',
    'identification_number': 9992820,
    'item_consumption_1': 3328,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1297.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39d2744d-8f1f-45a5-a502-9636c4df0536',
    'identification_number': 68447450,
    'item_consumption_1': 930,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 543.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81e29522-2148-4479-a638-fa8860c4141d',
    'identification_number': 33140111,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 35.24,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'd1eccf92-1bcf-4b89-ac0a-9906757d0d46',
    'identification_number': 107376245,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '772cd126-6416-4844-9e50-678e3f08cd41',
    'identification_number': 63896419,
    'item_consumption_1': 4367,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1625.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7496aec0-38b1-491e-b9d9-86eb299d66a9',
    'identification_number': 49314181,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '96d42359-b0ed-4b10-bb8e-8053515c797d',
    'identification_number': 107202522,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '65881b03-6b6e-4f8e-900e-40a3d4167092',
    'identification_number': 936166486,
    'item_consumption_1': 0,
    'item_description_1': 'LANÇAMENTOS E SERVIÇOS',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'ab469c5e-eaf8-4f78-8a1e-93d42c99ad61',
    'identification_number': 93308965,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2e5ffcad-f818-4937-8472-1344c32be74a',
    'identification_number': 20167725,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39db9745-83b4-4a35-b8d2-d7d3d44ac6de',
    'identification_number': 92572154,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01bd342e-c624-41d8-b753-c5feca0b1edb',
    'identification_number': 111295602,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6fdb8f96-e86a-4ca4-b2c1-088d93a63183',
    'identification_number': 4003474899,
    'item_consumption_1': 1238.8437,
    'item_description_1': 'Cons Ponta TE NOV/23',
    'item_total_value_1': 558.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9594082c-8ea2-4862-9061-181c82e6eb7c',
    'identification_number': 104547863,
    'item_consumption_1': 580,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 245.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0bb64282-9e7e-40ff-b9d4-147abcf239bf',
    'identification_number': 17690200,
    'item_consumption_1': 1200,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 508.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '089a741b-5f2c-4f45-8cbf-1ddf55d78e56',
    'identification_number': 101256507,
    'item_consumption_1': 3833,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1624.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0e6a36d-4d02-4566-930f-4416393abaf0',
    'identification_number': 14360241,
    'item_consumption_1': 813,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 316.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ed43f81-2bb9-40c6-bfe8-6a80910154ef',
    'identification_number': 15842231,
    'item_consumption_1': 1391,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 542.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3035be6-0f7b-4a6a-818a-a9929fb29fe1',
    'identification_number': 16128060,
    'item_consumption_1': 122442,
    'item_description_1': 'Consumo lora ponta',
    'item_total_value_1': 49392.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8168d1c4-110b-4e82-af89-5bd5a4cb5806',
    'identification_number': 8115524,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8424b0e4-b13f-4ba3-8ef5-6ee24010c057',
    'identification_number': 99990610,
    'item_consumption_1': 1093,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 464.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec475fde-274c-451a-bafc-94605e684860',
    'identification_number': 34680292,
    'item_consumption_1': 101.15,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2942.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ffea45b5-40de-4def-851d-29d7a01784a2',
    'identification_number': 11346221,
    'item_consumption_1': 82.66,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 1971.48,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'bda74c3a-86ac-467d-ae7b-e975f256748e',
    'identification_number': 31080448,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 4.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '35cb4edd-98a4-4a15-8929-caa847cdfaa6',
    'identification_number': 66557402,
    'item_consumption_1': 5.65,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 134.76,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ca90b487-7d32-4537-b261-48082037d4de',
    'identification_number': 35809329,
    'item_consumption_1': 16,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 2.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '852bcbd0-868b-4be5-ac0b-be89b3428f83',
    'identification_number': 30543860,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3c93e84-6ac1-499f-b35a-9acd903d6c52',
    'identification_number': 15101797,
    'item_consumption_1': 32.81,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 954.3,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cebc0efc-1224-45a8-9b40-7e5547b7530c',
    'identification_number': 13924613,
    'item_consumption_1': 24.72,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1411.75,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a01888d8-a9db-4625-8083-27e291eccc29',
    'identification_number': 67030165,
    'item_consumption_1': 1.78,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 83.37,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b3f36822-7318-4e3b-9b1c-5cb6aa64d7cf',
    'identification_number': 105088730,
    'item_consumption_1': 138.74,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4035.37,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '091738cd-921a-443b-9642-b8c1bfb4c340',
    'identification_number': 81860684,
    'item_consumption_1': 4966,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2109.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f20570d-7c73-45f3-ade2-6e30ea512eca',
    'identification_number': 39208869,
    'item_consumption_1': 177.12,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 5151.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '639e0262-326a-4889-b287-4a18396f6c56',
    'identification_number': 63401738,
    'item_consumption_1': 616,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 261.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '801906b1-fc52-4af1-8d83-3041af369390',
    'identification_number': 67030165,
    'item_consumption_1': 19893,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2539.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9b9acb3-7399-416c-9437-80b8a8cd34d2',
    'identification_number': 57470162,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccf1f05d-8dc5-4ec9-a020-c6539034c986',
    'identification_number': 14952688,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 89.93,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'd2d625d4-4a20-4bd7-8c87-5c8a0b8395de',
    'identification_number': 32098120,
    'item_consumption_1': 407,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 51.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5aba9160-c8d4-45eb-842b-8ccae618c410',
    'identification_number': 923318710,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41943b52-e3f6-4a67-88ee-472a94ad0c2e',
    'identification_number': 5558735,
    'item_consumption_1': 12784,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4978.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fdf8773-e811-4980-8a3e-4afd971f2608',
    'identification_number': 107871289,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfa48ec7-565a-4d56-be63-cfaf06ef4769',
    'identification_number': 73694932,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2af79699-82c9-455e-bb58-02e870c9bdba',
    'identification_number': 87252627,
    'item_consumption_1': 1662,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 705.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09c73b0f-dda2-465c-b2cf-ed0e280c0c20',
    'identification_number': 72858710,
    'item_consumption_1': 17.18,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 999.38,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5b2786ae-c1a5-4213-9e5d-0a16094411e3',
    'identification_number': 57387206,
    'item_consumption_1': 795,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 464.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7d9f0ad-13e5-4129-96e4-4947fe0753cf',
    'identification_number': 77204816,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73fce132-ec46-4699-8cfb-5300444d2bda',
    'identification_number': 56334044,
    'item_consumption_1': 0.39,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 9.3,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7edecdb8-0b54-4a29-8e60-402cd0143e26',
    'identification_number': 105957828,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e2bca88-ded0-45f1-a90f-2f1668bc698a',
    'identification_number': 100689620,
    'item_consumption_1': 52,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 20.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '663404df-22f7-4078-bedd-b3e8bcb1e196',
    'identification_number': 80072844,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '02b5f9f2-1611-492a-ae70-f4014906a471',
    'identification_number': 948827679,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 62.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '46177263-8bf6-4ae2-a207-56407c74cf60',
    'identification_number': 87747570,
    'item_consumption_1': 1695,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 662.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1727844d-ed84-427e-a433-9ab3436aca9d',
    'identification_number': 104126825,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '053443d0-33a2-4449-87b5-628e53827a6b',
    'identification_number': 110483456,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '77389928-06dc-45c6-bd47-090736b7aecd',
    'identification_number': 91481309,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa980b70-f974-44dc-89da-5668383a3910',
    'identification_number': 91823455,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '47af55e7-bb03-4ad5-8535-18455d980e23',
    'identification_number': 41999932,
    'item_consumption_1': 921,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 358.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f8184ca-0b2e-4b17-a11a-cc4c69419c8d',
    'identification_number': 109161017,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '117ae3f2-2c03-47a3-a920-1052b5e74db2',
    'identification_number': 75753758,
    'item_consumption_1': 3512,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2051.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fca46f0c-cf3b-46ee-a351-656bebf03fa4',
    'identification_number': 17765099,
    'item_consumption_1': 2855,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1115.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '308c29a3-dbcf-45d4-b48b-6fe46d208563',
    'identification_number': 22922521,
    'item_consumption_1': 286,
    'item_description_1': 'CONSUMO PONTA TE',
    'item_total_value_1': 158.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '852e1deb-fba5-4d25-97bf-10539bd2e3b8',
    'identification_number': 41579577,
    'item_consumption_1': 3215,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1257.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a1bd208-28a4-410c-97c0-c6cba89575d8',
    'identification_number': 19451601,
    'item_consumption_1': 343273,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 43814.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7f4bbe3-4068-43de-96b5-906f3ada4af4',
    'identification_number': 95566651,
    'item_consumption_1': 650,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 983.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebca7dd6-dbb1-4019-bc49-2c62a0a7ce14',
    'identification_number': 50784390,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd237825c-fa04-4e44-aa6c-f35eff2da1cd',
    'identification_number': 8453624,
    'item_consumption_1': 1114,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 472.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec7c80dd-4908-4106-9cc2-ad7291dec0ac',
    'identification_number': 106653733,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.04,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3c04f49b-8d23-4906-95fd-d434fd0cdc88',
    'identification_number': 76785378,
    'item_consumption_1': 503,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 196,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '554dcc22-2ba1-411a-a3df-a039553b20b1',
    'identification_number': 37911252,
    'item_consumption_1': 1907,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 809.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06bd7335-a9aa-4c8e-a365-6c5b352be3b3',
    'identification_number': 32503784,
    'item_consumption_1': 7.16,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 170.8,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6de33e5d-e395-49e4-bac7-e9f0671e1694',
    'identification_number': 45582459,
    'item_consumption_1': 4157,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1620,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3daa17e-22c3-4fd3-844c-0e1b3d4216d3',
    'identification_number': 91892287,
    'item_consumption_1': 5210,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2212.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41bd8baf-f8de-4455-8124-cb509c934953',
    'identification_number': 36878685,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1ac49aeb-1abf-48b9-8d2a-953557ccbf72',
    'identification_number': 12384933,
    'item_consumption_1': 170,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 66.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1be21637-7e61-48ab-aa29-e10148d9e096',
    'identification_number': 27222489,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 93.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9831ffc1-2164-4062-9c60-a0de42ab4c2b',
    'identification_number': 111014468,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2bdac9b-9e4c-4384-91a5-36bbac3a920c',
    'identification_number': 24017353,
    'item_consumption_1': 859,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1299.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15b2eb86-ce7e-4bcd-adae-03f287f2a061',
    'identification_number': 32627572,
    'item_consumption_1': 0.36,
    'item_description_1': 'DEMANDA REAT EXCED USD FP',
    'item_total_value_1': 10.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '1c814297-0f06-44a4-af0f-cf74041e77fb',
    'identification_number': 35000430,
    'item_consumption_1': 57.66,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3292.95,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3415b8b4-f1fe-4cfc-b562-e52aebff1cb0',
    'identification_number': 101073593,
    'item_consumption_1': 5066,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 646.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1320855-00b4-4af8-a1dd-2d0f4949258c',
    'identification_number': 15714063,
    'item_consumption_1': 2381,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 930.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e6c0181-cd05-44fd-9a50-65219f5b4dbb',
    'identification_number': 108322440,
    'item_consumption_1': 9,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '19d51d8a-1549-4142-9e17-459c2cc3ea4a',
    'identification_number': 32151179,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 75.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '361a1508-28d4-4a4e-aedc-bac8af8e524e',
    'identification_number': 103475192,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89bd4e7a-cdaa-4a4d-9549-f9d8dc9043e6',
    'identification_number': 91075700,
    'item_consumption_1': 79.31,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2306.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '714c8728-6b8e-4573-94d0-ab0392e975a3',
    'identification_number': 16246837,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95fdf77b-da74-41cc-9233-c4357cc60836',
    'identification_number': 41971817,
    'item_consumption_1': 2127,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 271.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67b4fb81-8283-4b11-9aea-b099655f58d6',
    'identification_number': 101138717,
    'item_consumption_1': 214,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 90.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88f144d2-bfb4-4b3f-989f-d681c6b3c754',
    'identification_number': 111740797,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ccc64ab6-ae0a-4ca3-b629-5e2fca560208',
    'identification_number': 43750583,
    'item_consumption_1': 8078,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 12220.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3381b4d-0f58-4780-94d8-952c957313b1',
    'identification_number': 935304310,
    'item_consumption_1': 4897,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 4269.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '667e734d-c3a9-44fb-9a82-37fffb9a2e9b',
    'identification_number': 8661758,
    'item_consumption_1': 14.3,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 341.07,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ddd3b10e-4038-4a40-a308-7d9f7aad9bf6',
    'identification_number': 111295602,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '925b2cc2-6701-4c01-a7a0-70b9009aa00a',
    'identification_number': 57029911,
    'item_consumption_1': 4335,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2531.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4b62d45-6bee-4c70-8083-edf48b094274',
    'identification_number': 54453844,
    'item_consumption_1': 7959,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1015.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6ea7cef-e3af-4120-83b0-9e5b8afe3cfd',
    'identification_number': 40611914,
    'item_consumption_1': 2110,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1232.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bcfdd637-cf32-45aa-b319-5ba1f57bbe47',
    'identification_number': 110977360,
    'item_consumption_1': 1959,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 250.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bdaa1f7d-abad-43f5-9551-cb0566ab7f44',
    'identification_number': 97616931,
    'item_consumption_1': 6861,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2917.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e6cb15a-2d38-4a48-8013-561ec2c5b58e',
    'identification_number': 15224287,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'db609d14-50a2-4286-b9bb-d809e07d441b',
    'identification_number': 85594733,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 35.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a559a19d-419a-4908-9daa-5cd67ffda61e',
    'identification_number': 80724906,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c73f2f80-bed5-4cc6-9b19-98720e924239',
    'identification_number': 42909058,
    'item_consumption_1': 11357,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4433.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63aecdb8-f389-4dc4-9f50-ebee5e0b2ec3',
    'identification_number': 75753758,
    'item_consumption_1': 70.99,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2064.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f022cf4a-4aaa-4530-858e-dd302232e20e',
    'identification_number': 77800699,
    'item_consumption_1': 633,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 957.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2eef56f-bd84-4a77-b7fc-1d1c3186c319',
    'identification_number': 32627572,
    'item_consumption_1': 1571,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 614.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6f2fcd71-75a0-46b4-9ee8-e05494d6504f',
    'identification_number': 109939050,
    'item_consumption_1': 15469,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6035.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2dbc05b1-c94b-4e88-9a0b-a0aeb204beaa',
    'identification_number': 41318536,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4abe8c69-9eed-47d8-82cb-d08b1a40e634',
    'identification_number': 111014468,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7a6c917d-fb87-4f2a-94e2-59cd686219cd',
    'identification_number': 91601479,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9e12ece-e0f5-4081-ac56-e55b43066454',
    'identification_number': 8529205,
    'item_consumption_1': 49.29,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1433.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b9b7c038-f2c6-42ea-9096-62c0a2fe2178',
    'identification_number': 14619555,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 656.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4e32105-b7b4-4052-aaba-701b9a528562',
    'identification_number': 25919032,
    'item_consumption_1': 10498,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4459.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c6f4e00-7bfc-4489-a964-f49bc3fdbe4c',
    'identification_number': 16916484,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 86.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd02f3a01-71d1-4fa6-825f-21d34d6a559e',
    'identification_number': 16179307,
    'item_consumption_1': 256,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 108.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '705fb4d3-63a4-4965-85a2-182c7b324b15',
    'identification_number': 17816920,
    'item_consumption_1': 10903,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4633.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0332a79b-a714-4c7a-9fc3-fec319127c3a',
    'identification_number': 4288262,
    'item_consumption_1': 13621,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5322.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a18aa40b-1c1b-4cb1-a5e7-e9a63da6bc7c',
    'identification_number': 8806446,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 45.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '233926c0-8e87-4bac-b4cb-9f250a30eff3',
    'identification_number': 480380,
    'item_consumption_1': 1001,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 760.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6224ae27-52c2-43b2-8b30-e9a76df23b13',
    'identification_number': 110527100,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '82579347-cddb-4f31-8124-e0357b381ebc',
    'identification_number': 97637238,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ee1ed9e2-7f15-4a09-87c5-b77b8cf11aac',
    'identification_number': 105277002,
    'item_consumption_1': 9.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 237.57,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fc6c0256-bf18-4417-879f-fd31a4892b6b',
    'identification_number': 38749645,
    'item_consumption_1': 85.53,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4884.62,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b710be42-f3fe-4549-8707-c09c615af819',
    'identification_number': 18328504,
    'item_consumption_1': 173,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 67.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df2851e5-17dd-444a-bc49-1571bb1fe427',
    'identification_number': 19452632,
    'item_consumption_1': 564347,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 210020.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4ba3ac2-86a4-44be-9733-3abeb990a07c',
    'identification_number': 69324719,
    'item_consumption_1': 5873,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2491.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c286415-2cd0-4c72-ad1b-967e75f15d70',
    'identification_number': 13532626,
    'item_consumption_1': 8025,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3130.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '002ed019-fbc1-4e13-82c8-e7a102558658',
    'identification_number': 108167879,
    'item_consumption_1': 3390,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5128.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ec41936-ed59-46aa-a6d3-fc9c887c7a41',
    'identification_number': 97616931,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c0db33d4-caf7-4664-b163-0401d5344aee',
    'identification_number': 6092578,
    'item_consumption_1': "'-833.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-266.46",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '234cf18f-7e48-4adb-9b72-e984f1087e25',
    'identification_number': 100726704,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-94.92",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'd76ef433-0ac3-4ee7-bde3-d75f3862a5cb',
    'identification_number': 97397652,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 107.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1403e23f-ae4d-4f11-9e99-c5e8a3b51358',
    'identification_number': 16117450,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.3,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '901d3f9c-3041-4583-9538-17e37aaa0507',
    'identification_number': 58309195,
    'item_consumption_1': 3311,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1291.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5bb287c9-288c-4144-8681-f0d40785cb6a',
    'identification_number': 35429585,
    'item_consumption_1': 821,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 321.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7c27ad2-cafc-43a5-b0c5-e70c646648d5',
    'identification_number': 101512783,
    'item_consumption_1': 275,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 116.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16842054-a632-4b2b-8b8d-16daefd4ecc7',
    'identification_number': 33058474,
    'item_consumption_1': 1357,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 576.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f3d4e27-116e-4636-8e23-0e2f9b8e733e',
    'identification_number': 85594733,
    'item_consumption_1': 464,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 180.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffaccd98-7288-4365-849a-025f815d8ca8',
    'identification_number': 64669360,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd28d47fe-d63a-47f1-9311-7d408441f8b9',
    'identification_number': 76608107,
    'item_consumption_1': 14186,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6025.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6963b641-5fe3-42bf-b6ca-c9ef9427ecf9',
    'identification_number': 55606890,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c494b69a-30d5-4f90-af94-a824cbfcb9f7',
    'identification_number': 105791130,
    'item_consumption_1': 497,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 194.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38b7d011-8d77-4b1e-8c24-f4590e150543',
    'identification_number': 20934947,
    'item_consumption_1': 1376,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 803.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55c834f4-849a-4755-a89b-b3954ab5c754',
    'identification_number': 42556198,
    'item_consumption_1': 28848,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3682.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b027bbd-277a-4a53-a9bf-a1bdf7121f50',
    'identification_number': 33800677,
    'item_consumption_1': 6.72,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 767.56,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8409558f-b117-434c-96aa-6571e72a4d5d',
    'identification_number': 49951670,
    'item_consumption_1': 4629,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 590.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9de8ce62-8d08-4780-9537-9cae02660e1b',
    'identification_number': 97812846,
    'item_consumption_1': 724,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 282.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca720c11-1d1b-44c9-8906-20f3142346cd',
    'identification_number': 102260460,
    'item_consumption_1': 22395,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8334.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93230d3e-f936-485e-b9b6-3c7f68491819',
    'identification_number': 78334640,
    'item_consumption_1': 16,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 6.25,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '999ea2d9-e71e-41b0-86d0-7d813047a1ed',
    'identification_number': 24083542,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ec953660-a279-43d2-b111-0e9cb460a62c',
    'identification_number': 40657663,
    'item_consumption_1': 7475,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2920.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c4e684c-26e8-4a7d-ae04-1c9b7a7c2f3f',
    'identification_number': 8899738,
    'item_consumption_1': "'-1.083",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-347.06",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '10cdf743-2e9f-4141-8bda-807a8d54936c',
    'identification_number': 106390015,
    'item_consumption_1': 9742,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4139.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea8868ea-d980-45af-8664-c389f9d87814',
    'identification_number': 18552099,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6396e94-ab40-4a82-a373-d23868915800',
    'identification_number': 92387578,
    'item_consumption_1': 14818,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1891.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '833e5701-1b55-4c32-927c-0aa83fa2b379',
    'identification_number': 38370492,
    'item_consumption_1': 53788,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6865.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01106d61-24eb-4f17-8096-a907aab6e379',
    'identification_number': 86773526,
    'item_consumption_1': 166,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 64.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ae23c70-e9bf-4cc8-8550-c9f8cc93df76',
    'identification_number': 35766514,
    'item_consumption_1': 16135,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6004.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b03e782-c738-4056-a558-2e9d67f31d18',
    'identification_number': 3030777579,
    'item_consumption_1': 8125,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 4103.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70c9ab86-67c5-4f70-bdc3-61aa1d8c45dd',
    'identification_number': 92763219,
    'item_consumption_1': 4413,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2577.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c95b570-b190-486a-bb46-b0c4a48db951',
    'identification_number': 5826373,
    'item_consumption_1': 11050,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4305.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fb84cde-41b6-4a55-a248-25a3a6b9df31',
    'identification_number': 103124098,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 126.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fdf4dc08-757c-4f21-bbb6-c187445f29e5',
    'identification_number': 927293026,
    'item_consumption_1': 1255.8,
    'item_description_1': 'Demanda de Potência Medida Fora Ponta',
    'item_total_value_1': 38692.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8620e344-376f-4851-845e-1571923e5d11',
    'identification_number': 17865069,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 152.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '08d19d10-842d-40fc-8584-7500a6199c06',
    'identification_number': 32486804,
    'item_consumption_1': 1260,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1906.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b8ef202-7ee3-42d4-b91a-2a658f23c5a5',
    'identification_number': 79665306,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '30705f91-cd71-464b-b983-c9d3cf629d5b',
    'identification_number': 84479175,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ac3c3b9e-1474-4fc9-9d54-278b7a22ff8d',
    'identification_number': 10797289,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89e540c6-2bfd-499c-b4f4-e1758476cac8',
    'identification_number': 36324876,
    'item_consumption_1': 1491,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 631.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '625b30c9-4884-4cc1-85d2-fa4ee8d045dd',
    'identification_number': 30618886,
    'item_consumption_1': 190,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 80.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e38c7d1-b87a-4777-ac96-ea046d00bc45',
    'identification_number': 94871400,
    'item_consumption_1': 6251,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2434.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3681c324-6654-4ba3-803f-c7cc4ea5f78f',
    'identification_number': 103496718,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5f70fd54-1ba0-4fe2-87e4-35a23e1ffb45',
    'identification_number': 49891294,
    'item_consumption_1': 25.41,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 739.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9201072a-8ed0-440c-97d7-a1dbc05705b6',
    'identification_number': 101269161,
    'item_consumption_1': 438,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 186.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eeaad7d5-809d-4f32-b06a-6fc871b6fc06',
    'identification_number': 8115559,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '29c5f381-60e1-45ed-a382-51e8a594b019',
    'identification_number': 15101797,
    'item_consumption_1': 1677,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 979.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8671a9ee-e091-43e0-83f9-d07e0d7d273a',
    'identification_number': 94384126,
    'item_consumption_1': "'-12.094",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-4210.67",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'd28f4eba-ae6d-4ed8-993a-005617cc8b13',
    'identification_number': 97353590,
    'item_consumption_1': 37133,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4739.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16099ffa-371f-4ef7-a2dc-8cb8bdd9648a',
    'identification_number': 69609780,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '711f81ae-2ad7-4949-bba5-2942e160cd07',
    'identification_number': 102854165,
    'item_consumption_1': 648,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 253.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b19e41c7-a74f-46b2-9912-a08b906dfd87',
    'identification_number': 28065557,
    'item_consumption_1': 5073,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2148.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'afbbe7d7-80c5-4987-904c-a0ea919717f8',
    'identification_number': 45483779,
    'item_consumption_1': 53133,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 19773.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1988b22-8f73-471d-923a-51ab647d92b9',
    'identification_number': 75402351,
    'item_consumption_1': 10849,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4037.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '604f4312-ea74-4d56-8582-4d1b3ba513e3',
    'identification_number': 51527987,
    'item_consumption_1': 154,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 232.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb27972f-7d88-4ad3-94c0-7f287de040d5',
    'identification_number': 15091520,
    'item_consumption_1': 1197,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 699.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae1e11f3-5076-4713-a8ae-a9b008418424',
    'identification_number': 98349821,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 035/038',
    'item_total_value_1': 9.26,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '8fc52e75-298b-4c9d-8732-49733d75ec77',
    'identification_number': 96017015,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '327dce4b-80e2-4dfc-8d9e-fc5c05cd5f5b',
    'identification_number': 64871274,
    'item_consumption_1': 6607,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2803.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28cc6a64-cb1c-4cb8-9f90-07afdb6c558a',
    'identification_number': 97837350,
    'item_consumption_1': 3991,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2330.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4565394b-6879-48cd-8a82-056d2b6b6e96',
    'identification_number': 60730781,
    'item_consumption_1': 36.65,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2093.07,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2c0d3ced-4dcd-4273-85c6-a2bdc7936180',
    'identification_number': 107160560,
    'item_consumption_1': 409,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 159.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d3656ef-137f-45b5-a84f-2ceca706803c',
    'identification_number': 94699020,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0b152999-c79d-4273-96cd-01e51c5d6921',
    'identification_number': 8115532,
    'item_consumption_1': 417,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 176.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a497210-3b3c-4431-9580-899bc06f1005',
    'identification_number': 106648462,
    'item_consumption_1': 313,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 122.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e56e33bc-ef29-456e-acd7-2293ac71c4e3',
    'identification_number': 84768754,
    'item_consumption_1': 898,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 524.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '482954b3-7b28-44f1-ba96-e8de34e274cd',
    'identification_number': 19453299,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eeda021c-63c1-49d9-a62c-528e864a1be9',
    'identification_number': 67030165,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 18.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b4a2c187-e6f3-412c-a09e-1dac732b9f44',
    'identification_number': 102345945,
    'item_consumption_1': 590,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 229.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81c05984-a207-4250-9ff2-e34444e15aff',
    'identification_number': 98010751,
    'item_consumption_1': 8928,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3488.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7e068c6-e7ca-4bde-acb8-90879ac88139',
    'identification_number': 30168961,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e8c29e45-943a-4aff-8a6c-5aba90d3d7d7',
    'identification_number': 102302561,
    'item_consumption_1': 668,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 260.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab4d9efb-cfa5-411d-88ef-70882d425eeb',
    'identification_number': 923318660,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '14ceb5d0-1626-46e6-831d-fb1566e8dd6f',
    'identification_number': 16069030,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 60.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8413aeb0-cc36-4e45-9ed1-333bd1334529',
    'identification_number': 45115745,
    'item_consumption_1': 8917,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5207.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30814b11-a570-4902-9064-9257b9b754f1',
    'identification_number': 45980586,
    'item_consumption_1': 1731,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 733.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b31e2db7-5ec3-402e-b0a1-4e19de13df5b',
    'identification_number': 94428506,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea6b5eec-28b5-4d68-8322-a7d9ebff9b01',
    'identification_number': 16695135,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 177.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1e3c4ea0-5330-493e-8b3d-0c5c1fd738cc',
    'identification_number': 87246996,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '968d8518-ff32-4fa4-b28f-edba1162115f',
    'identification_number': 106382373,
    'item_consumption_1': 293,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 114.63,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3f3f2748-714b-4299-82e8-7024ce2e5d1d',
    'identification_number': 105165468,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '216bad3d-4406-4add-b6f1-3e5f176473f8',
    'identification_number': 14075423,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.23,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b46916b0-86b4-44a9-a6fa-eaff444c866a',
    'identification_number': 110598571,
    'item_consumption_1': 270,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 114.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c8091e5-f506-4af5-9466-8b914cad6dca',
    'identification_number': 105699047,
    'item_consumption_1': 25.94,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1508.97,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'dee10473-f8ef-493e-8bea-0554d1b8d3ac',
    'identification_number': 66832330,
    'item_consumption_1': 247,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 96.63,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '22b60b75-7e2f-416b-bb9d-c73491e024e4',
    'identification_number': 18184006,
    'item_consumption_1': 2508,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1062.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70551b5e-ea76-4582-b2e9-c92b033da038',
    'identification_number': 29836573,
    'item_consumption_1': 2245,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 877.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c234f488-96fc-4a9d-b524-4e818bd450bc',
    'identification_number': 106601253,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 42.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2bbecf34-b08d-4167-bd80-47e980299a9a',
    'identification_number': 79937667,
    'item_consumption_1': 90623,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 33725.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1ea8622-619c-4b8f-a8ee-a16a30c72365',
    'identification_number': 17213711,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22ceeeaa-c33e-491f-8bf6-8d687aabbbb3',
    'identification_number': 70941149,
    'item_consumption_1': 5059,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 645.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '784f05dc-31a8-4b2b-b3a4-9ea7f5316440',
    'identification_number': 42491428,
    'item_consumption_1': 32032,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 13607.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22018c76-9fde-438a-8749-984999b4f573',
    'identification_number': 14984369,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4474dfc6-9ea2-40e4-b4b1-32177890cf40',
    'identification_number': 96988312,
    'item_consumption_1': 131,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 51.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '541c91c1-174e-48a0-9e9e-8b461de3251d',
    'identification_number': 109633962,
    'item_consumption_1': 55.39,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1611.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '41e23440-1e4e-4a1a-9fd7-c67b93a5cdb7',
    'identification_number': 68906161,
    'item_consumption_1': 15.24,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 363.49,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1534c97a-91a4-481d-bdbf-4d2ab31fa943',
    'identification_number': 99244055,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '80d202bc-3654-4de7-8a24-6bc4e3c7720e',
    'identification_number': 22734318,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 75.2,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'c612b258-1b70-4e04-b248-fd292d83bfc9',
    'identification_number': 11187069,
    'item_consumption_1': 839,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 355.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02ad9e7b-1e2c-400c-9f32-c015ec734874',
    'identification_number': 40429458,
    'item_consumption_1': 325.9,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 9479.11,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5e5bdb6b-34d5-4886-8d50-30bcd7f0aadf',
    'identification_number': 15966798,
    'item_consumption_1': 2145,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 835.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '710784c5-cadc-4ed2-8e52-65270271ac8b',
    'identification_number': 98548255,
    'item_consumption_1': 1994,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 844.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5319bdb5-5f1f-475a-9ce3-ed45a8b22872',
    'identification_number': 6192319,
    'item_consumption_1': 1185,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 462.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec6cefc0-1123-428f-8b41-b4034a03ac05',
    'identification_number': 56526849,
    'item_consumption_1': 5889,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 751.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21776a67-a461-4c67-9ff2-0e7c0bbc3795',
    'identification_number': 106868250,
    'item_consumption_1': 25,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 9.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f2e201d8-6e98-407c-bc62-58c7db88816d',
    'identification_number': 65079469,
    'item_consumption_1': 683,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 266.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52506dd9-6eda-4d36-ae67-8adcb2562a5f',
    'identification_number': 105797634,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bc47afe1-13ad-442f-9183-6aa6b758c175',
    'identification_number': 40803635,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6e497726-8d89-4cba-8301-79d8f9716df4',
    'identification_number': 106928317,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06bd2cc6-a1db-43aa-ac29-3b98ff885314',
    'identification_number': 105797634,
    'item_consumption_1': 793,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1199.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '929240be-528b-4f0c-afbc-2e1c586a498a',
    'identification_number': 103724702,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59425447-4fa1-4f39-abb3-b93511d079eb',
    'identification_number': 63896419,
    'item_consumption_1': 12.34,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 358.9,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '926d4f96-0b2b-444f-b6b6-200cd650e9de',
    'identification_number': 106291238,
    'item_consumption_1': 6855,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2676.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f794fc2-03ed-465c-9f9d-6046356ef183',
    'identification_number': 102433283,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd50250c0-7ceb-4c96-9e58-49a9b52d2c20',
    'identification_number': 17422760,
    'item_consumption_1': 5780,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2259.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a719110-935e-439c-b3de-b603aa52eb60',
    'identification_number': 59484896,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9553c06-fb3e-42d2-812d-67622c48cd64',
    'identification_number': 108232700,
    'item_consumption_1': 6386,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2706.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0eaaedad-33b5-4646-b765-5d743b485573',
    'identification_number': 29701759,
    'item_consumption_1': 6013,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2344.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f06f05d-466c-4632-a2f4-e1753a3ce436',
    'identification_number': 103124098,
    'item_consumption_1': 40.63,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1181.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a0f663dd-3828-43a8-ae01-5367418aae36',
    'identification_number': 22846670,
    'item_consumption_1': 1594,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 621.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4cf091d0-d8f7-4a86-aa20-ec6f91aa8bbe',
    'identification_number': 28202619,
    'item_consumption_1': 3774,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1598.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff6ea66c-8292-4738-a45b-b230933a93c4',
    'identification_number': 15561054,
    'item_consumption_1': 768,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1161.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bdc6c23-c3c9-4aa9-a203-85893b3c2987',
    'identification_number': 95641963,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 45.34,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3acc84ce-a28e-4266-a8c8-e65306880f8d',
    'identification_number': 10145214,
    'item_consumption_1': 941,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 366.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '013dda39-0412-46fa-91de-351929e470ef',
    'identification_number': 13845551,
    'item_consumption_1': 155,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 60.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f39f727-5b1d-4f26-a21c-11a0669aef1f',
    'identification_number': 59223677,
    'item_consumption_1': 255,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 107.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9efb8c10-da2b-4425-a583-482b9ad9bde5',
    'identification_number': 102339066,
    'item_consumption_1': 1456,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 618.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45cfdff6-5d7f-4b08-9cad-dc4d50783378',
    'identification_number': 65141024,
    'item_consumption_1': 16.57,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 963.9,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '0749812e-900b-4427-abe9-dc98d2af1aef',
    'identification_number': 108197271,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7aaece6a-0fc9-4114-81be-ca381ebe5d6d',
    'identification_number': 93430442,
    'item_consumption_1': 41.62,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2376.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c2a9077d-7619-4d8d-84b8-d3989b25f334',
    'identification_number': 7381956,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9a616159-56b2-44c8-b96d-f7e5aaace6e5',
    'identification_number': 45485569,
    'item_consumption_1': 848,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 359.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed284d8c-8e73-4676-9688-31ea8b67676b',
    'identification_number': 104991402,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4d48646d-a260-4af8-90b1-3dcc9f0d57a3',
    'identification_number': 14662655,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 348.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '64b41eff-480f-43d3-886f-04e64e422bef',
    'identification_number': 44024924,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8f0a4616-7ce8-4d64-8807-73549f1acd1f',
    'identification_number': 17509980,
    'item_consumption_1': 10331,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4376.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7840ca32-4a64-4640-b0ec-3c7da089cbc7',
    'identification_number': 106957317,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1092.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0ae39b8-b3ba-419f-9294-2d07596387c6',
    'identification_number': 46006583,
    'item_consumption_1': 74.73,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2173.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c7043a9b-5d19-4695-a938-7157ef61f1ca',
    'identification_number': 42710529,
    'item_consumption_1': 0.63,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 15.04,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fbb8f204-ec4c-415c-93fd-ad6aec63eb01',
    'identification_number': 38220474,
    'item_consumption_1': 2559,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1085.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ae0ed2f-bdec-4109-ab58-664750a11fb2',
    'identification_number': 34210229,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b0a6684-97df-4ffa-8b40-39f304ae3880',
    'identification_number': 75768879,
    'item_consumption_1': 154,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 60.24,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4ecb52b7-6bf2-4374-8cc5-577432739df1',
    'identification_number': 34851151,
    'item_consumption_1': 50.7,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1474.66,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e6d795b3-dc90-486f-9dfd-7956c42f99ce',
    'identification_number': 14952688,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1350.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8cb1c5e5-3d49-4b3b-b657-c9cc7e564769',
    'identification_number': 60730781,
    'item_consumption_1': 1.35,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 63.23,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1a409cb4-5270-471e-8827-830dccc13937',
    'identification_number': 39024091,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ee2c03ca-ea4a-4e64-abbe-869f67a57f0e',
    'identification_number': 32501480,
    'item_consumption_1': 8.6,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 205.13,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '46616780-f59e-402a-a9fb-655d1f0e2041',
    'identification_number': 8712085,
    'item_consumption_1': 2720,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1154.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98e3caaf-59c1-437c-bd5e-01a9e3ed2250',
    'identification_number': 97856681,
    'item_consumption_1': 58.44,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1699.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7c2d2bbb-e026-4d33-bd17-3b7dabfa0264',
    'identification_number': 56812477,
    'item_consumption_1': 3256,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1211.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '909d2311-2ed4-4f95-a6f1-584ae60dca6d',
    'identification_number': 93418175,
    'item_consumption_1': 773,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 327.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '997c4e33-1370-4c8a-b927-765142d7dfb4',
    'identification_number': 31509983,
    'item_consumption_1': 2627,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1025.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '584338b7-7c22-42ee-be11-9d11588ac5c3',
    'identification_number': 20587171,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 30.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'fe4b314c-637f-483f-9fe5-ee0d54bd84a8',
    'identification_number': 104766190,
    'item_consumption_1': 24902,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 3178.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9da410c9-44d7-4556-ab19-ea2ef8efcc75',
    'identification_number': 64104770,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3dda3109-bf21-4177-8795-d1bdd799d32f',
    'identification_number': 23094338,
    'item_consumption_1': 909,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 386.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6fedff54-7f33-4a9a-a2de-41d18ddae242',
    'identification_number': 26460491,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32029975-d806-4aa3-b937-d1be912b87d0',
    'identification_number': 88926923,
    'item_consumption_1': 8453,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3584.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28c5d6fe-8202-4448-b302-21bccc58fc1d',
    'identification_number': 105957283,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 207.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95a988e8-2883-42c4-9fa0-f2012119b369',
    'identification_number': 72092556,
    'item_consumption_1': 8557,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3184.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca897822-be2b-40e5-8d54-7c1d4156c9d3',
    'identification_number': 41460510,
    'item_consumption_1': 5760,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2143.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dad1b606-c7f8-461c-8221-727c408c5cc5',
    'identification_number': 70503974,
    'item_consumption_1': 6913,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 882.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2412f7af-6412-40a3-8a42-2c32d629ce48',
    'identification_number': 111219248,
    'item_consumption_1': 3504,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 447.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15dfe1aa-347e-43fe-a821-29acbc8f5a8c',
    'identification_number': 69810001,
    'item_consumption_1': 5924,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2508.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75e31c80-7942-48cb-9e4f-62193722d365',
    'identification_number': 99786184,
    'item_consumption_1': 301013,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 38420.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2da5cf36-b549-4fbb-a44d-37ba8ef8b8af',
    'identification_number': 63896931,
    'item_consumption_1': 1538,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2326.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1d12611-c4c8-486e-8935-35459c4e58a6',
    'identification_number': 86490087,
    'item_consumption_1': 2010,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 852.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c8a5109-cd07-44c1-8117-af21c08272fe',
    'identification_number': 102890269,
    'item_consumption_1': "'-693.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-241.33",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'e2f1ab1a-a355-441a-95b9-0e5585200560',
    'identification_number': 47011440,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc389cff-27b0-4a2c-b8ba-81b12da0d7dd',
    'identification_number': 44774494,
    'item_consumption_1': 396,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 11518.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '670bad24-2f02-4931-aff0-36a5b3f17aa4',
    'identification_number': 13010476,
    'item_consumption_1': 19.26,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 560.18,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8fb05a9b-5fb1-4f74-aefb-530352edac0c',
    'identification_number': 59508345,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39ef3acf-026e-4951-8180-382cd0927042',
    'identification_number': 6938795,
    'item_consumption_1': 4443,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1738.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b49a6a83-4a3a-4607-b02d-ebece5adc792',
    'identification_number': 84156171,
    'item_consumption_1': 1.56,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 37.22,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5101d29a-43eb-4a34-8e0a-5e418d8014f4',
    'identification_number': 66244013,
    'item_consumption_1': 330436,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 42175.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c9058de-0a5f-42ad-b47b-1bc51b3e8259',
    'identification_number': 111219248,
    'item_consumption_1': 2.87,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 68.46,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7afcf084-b324-4982-9245-3159ccfd8abd',
    'identification_number': 30102901,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1023.82,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b0b67969-a56c-4e06-8fa6-e2b5a6bd50c1',
    'identification_number': 94011591,
    'item_consumption_1': 1376,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 537.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b3b4fad-832c-4153-86c9-509afd6c9683',
    'identification_number': 92158064,
    'item_consumption_1': 22.03,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 525.44,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3e8f09fa-2c06-4f9d-8e87-778ddd491185',
    'identification_number': 56493371,
    'item_consumption_1': 1394,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2108.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed9927ba-b4d4-4081-984a-098e05c0f44f',
    'identification_number': 33576718,
    'item_consumption_1': 1644,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 641.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eaa60ebd-c914-4dd1-9b0d-97c2f212c3c6',
    'identification_number': 36033111,
    'item_consumption_1': 7,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 2.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1e5c3998-ffc4-4230-984c-492febd71e26',
    'identification_number': 41971817,
    'item_consumption_1': 22126,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2824.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1d2c706-a37c-4971-92d2-dda37b31d558',
    'identification_number': 65191404,
    'item_consumption_1': 10091,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3755.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7e26443-02c2-4412-a083-99e19811e873',
    'identification_number': 5863570,
    'item_consumption_1': 1430,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 605.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48800c79-fe7d-403b-9dde-14fcf22dd57d',
    'identification_number': 55619851,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38d8b209-999e-4a93-b072-adc5baac7227',
    'identification_number': 98041371,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b75dfb99-f021-47f7-b118-ab27fdf528c6',
    'identification_number': 62162705,
    'item_consumption_1': 433,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 183.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd0581b7-8a27-4d57-a00c-90d753234ba5',
    'identification_number': 12399752,
    'item_consumption_1': 600,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 907.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '762e197d-f896-4a40-b71c-4f4ce63e6175',
    'identification_number': 108668614,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '57fd9554-4c5a-4034-97c0-42598f487d56',
    'identification_number': 83974520,
    'item_consumption_1': 308,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 130.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '010a9960-b6f5-4589-a7fd-8c4e0c858584',
    'identification_number': 70080615,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b278c289-5fde-499f-a3eb-3713633e370a',
    'identification_number': 102481172,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e7c40a97-1d12-4de6-bb23-d3538a614081',
    'identification_number': 103764755,
    'item_consumption_1': 55.49,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1613.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'af65e02e-aad8-45d5-82dd-74aec6b0558d',
    'identification_number': 32403011,
    'item_consumption_1': 2392,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1397,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22ff7feb-b91f-4abc-9f13-7469cca60d7d',
    'identification_number': 17401682,
    'item_consumption_1': 3778,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1405.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3256b075-0c6d-4ff2-a127-cc0bd7bbdcbe',
    'identification_number': 93926677,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7d96ea09-97ad-4086-a65c-95e202fa856f',
    'identification_number': 99578336,
    'item_consumption_1': 8119,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3021.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66b1646e-cce4-4db0-a636-587c680fac32',
    'identification_number': 20587171,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 207504.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82f9551e-0464-431f-946b-5a21a70a7f12',
    'identification_number': 108298035,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2648581a-2852-4971-a864-37467346932f',
    'identification_number': 16078985,
    'item_consumption_1': 5240,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2218.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9c247f2-c6fd-4a4e-a9e0-8407cd5fed10',
    'identification_number': 69441952,
    'item_consumption_1': 5863,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2489.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35a3b5be-55d3-4641-8be5-5b407f2aed79',
    'identification_number': 105557668,
    'item_consumption_1': 17250,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6746.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27d3f901-3f95-49d5-a9f1-9607c17fcf9a',
    'identification_number': 104467010,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '23179c9c-e12a-4ccf-a058-12f7b799fa09',
    'identification_number': 70581150,
    'item_consumption_1': 4366,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1850.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '548e43ef-8dbf-4ddd-a70f-d38ca012e483',
    'identification_number': 46523286,
    'item_consumption_1': 25330,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3233.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f57a69db-3eef-401c-b256-b764198ad42c',
    'identification_number': 35854952,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6631d538-122c-4f36-89e2-845cf2f17b40',
    'identification_number': 99540819,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbfcf1b4-905d-4cf6-818f-e14c218a26ed',
    'identification_number': 100214193,
    'item_consumption_1': 23.61,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 686.7,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0e3852d2-6184-4a86-af25-22f449a39040',
    'identification_number': 75403943,
    'item_consumption_1': 1.85,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 44.14,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4c8aae79-1d8a-4078-baec-5936a72408f7',
    'identification_number': 85205672,
    'item_consumption_1': 5.03,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 119.99,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '0960b11c-928a-4705-b115-254ec8ae7579',
    'identification_number': 32098120,
    'item_consumption_1': 407,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 237.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09cc8f06-fbb1-4cdc-aa1d-df606cf79f54',
    'identification_number': 88478319,
    'item_consumption_1': 67,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ee1bd65-048f-41b7-9a5e-0ee6b7f96ea2',
    'identification_number': 25945335,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5e604ec4-4f20-423d-9baf-ae2efbb97e7c',
    'identification_number': 45986223,
    'item_consumption_1': 678,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 264.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e16fdc2-342c-4ca9-995e-bac5b20c45a5',
    'identification_number': 108902919,
    'item_consumption_1': 257,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 108.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a95e8ae8-5d39-488e-a73e-2eadf300e980',
    'identification_number': 97343536,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9dc7ff51-56e8-4fe5-bd2d-fed4c880fe29',
    'identification_number': 76636291,
    'item_consumption_1': 168.48,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 9621.87,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '84018b0d-f1bd-42eb-a9a8-0ae074e89a0e',
    'identification_number': 98913972,
    'item_consumption_1': 6.17,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 358.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'c4df6a0b-6060-4b51-bed3-c40561bc47c0',
    'identification_number': 91800536,
    'item_consumption_1': 574,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 224.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0cc76112-3602-45d0-b9ba-8a093ada1a00',
    'identification_number': 107740516,
    'item_consumption_1': 27.55,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 657.1,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a5c1c5ca-e0fa-4e60-a92a-4262ff31eb6c',
    'identification_number': 14066017,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '21f484c8-22e1-48ca-b3d3-b237888772c5',
    'identification_number': 72755520,
    'item_consumption_1': 623,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 263.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f59cbc51-ace7-406b-8fbd-7960d74dee54',
    'identification_number': 59436956,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1e72dd2-b26f-443e-a58e-885090203a3e',
    'identification_number': 41409973,
    'item_consumption_1': 8548,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3631.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81c89c71-16f5-4db1-8bd3-a8450d4ab2cc',
    'identification_number': 8115680,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '967d8416-baa4-41a1-8442-925b5bad6d68',
    'identification_number': 91481309,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ba55692-7e0e-4257-9943-4aa1e35a0e56',
    'identification_number': 97851000,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0f50bff7-4d98-420c-a076-e34fd5f26a37',
    'identification_number': 102846529,
    'item_consumption_1': 2918,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1236.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f99220ed-8619-40d4-a5be-db7dbb5d3824',
    'identification_number': 16677412,
    'item_consumption_1': 44.62,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2548.24,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '33c59b50-1a65-496e-a14c-7aeb0162a55d',
    'identification_number': 95368957,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ae19da4-3bd4-42b4-a274-5413c561fc4d',
    'identification_number': 1841165,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b318b3e9-a1c3-4b47-9cab-8352141c55af',
    'identification_number': 95920625,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-21.83",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '89308ca7-e3c6-46ab-9fd3-db255c0e1ff5',
    'identification_number': 17023491,
    'item_consumption_1': 47,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 71.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c138784d-06fe-4296-b8df-10f6a5082a4c',
    'identification_number': 91976715,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6d0a8931-08c8-4832-9239-1bcc273b0078',
    'identification_number': 95475133,
    'item_consumption_1': 1977,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 773.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b763504-a3bc-4336-998d-66c5bfa3936a',
    'identification_number': 72418745,
    'item_consumption_1': 227,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 88.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25e02709-59cc-4e06-b83f-b4787e842c71',
    'identification_number': 32098120,
    'item_consumption_1': 8.36,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 199.4,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e11b96c9-786f-45da-a54e-c46548dd394c',
    'identification_number': 43750583,
    'item_consumption_1': 8078,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4717.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e4a0ee0-7228-4249-a96e-5f6cb7716616',
    'identification_number': 30290635,
    'item_consumption_1': 6.7,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 159.82,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd3bfb094-54ae-48b6-bcfe-22977bbb47d8',
    'identification_number': 87540550,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '20722e81-f189-4cd1-9189-1097b8deca3b',
    'identification_number': 99578336,
    'item_consumption_1': 1062,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 620.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9294b312-63b2-48e2-a46d-e5a0007d40a3',
    'identification_number': 96125225,
    'item_consumption_1': 31009,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3957.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f5e54aa-51c0-420d-ad9e-f495cc3628c1',
    'identification_number': 15444015,
    'item_consumption_1': 3480,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1478.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3df0f5f-0d04-4479-a098-9cf7e797da34',
    'identification_number': 1205102,
    'item_consumption_1': 61.4,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1785.86,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6b28c64a-aba6-46ce-95c2-f024de80ea93',
    'identification_number': 98938622,
    'item_consumption_1': 1280,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 542.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed5d0481-c4cb-49c7-84a4-bea5c5c54374',
    'identification_number': 105683574,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4de4d15c-8870-49eb-934e-a7d5d87b2462',
    'identification_number': 5693608,
    'item_consumption_1': 5572,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2359.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a86cf630-cd99-4362-b083-143b6a9237f4',
    'identification_number': 90978501,
    'item_consumption_1': 880,
    'item_description_1': 'Energia Ativa Consumo Ponta',
    'item_total_value_1': 1725.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae7d2000-1f92-4c03-8faa-2fe213dcf5a3',
    'identification_number': 79211747,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 7.8,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '25ed48e7-ffe0-4cdf-89cd-03df761103c9',
    'identification_number': 16247590,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef97786c-c7d2-456c-b6d7-97d2b8b0b683',
    'identification_number': 65141024,
    'item_consumption_1': 9499,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5547.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52e70a94-1425-4224-85b8-2f1933c7fabb',
    'identification_number': 101163231,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e537e4ec-2b1c-4d44-95b8-583e85161b44',
    'identification_number': 110019377,
    'item_consumption_1': 1027,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 599.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a668a9b8-1d63-4a9c-bea6-d145232ce00e',
    'identification_number': 16639626,
    'item_consumption_1': 8548,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3628.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38680a5e-5b61-4cab-9b90-c333a6e6870b',
    'identification_number': 52599400,
    'item_consumption_1': 9286,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1185.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2aa570ec-b446-4b7b-a5de-e0706d2d201f',
    'identification_number': 102645698,
    'item_consumption_1': 6861,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 875.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'face74c3-bf66-42d2-9917-a26c2fe371f4',
    'identification_number': 77169689,
    'item_consumption_1': 136,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 57.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a63bb53-beb6-4b65-8438-39a43c860f16',
    'identification_number': 77890043,
    'item_consumption_1': 15254,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5676.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd800496d-df3b-4ea6-9f77-3fd63a4608bf',
    'identification_number': 103001735,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5440a736-f1fe-4f9c-9e7b-92449771089b',
    'identification_number': 106278860,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fb9d25c3-f595-4cb1-bc31-d62c35c50012',
    'identification_number': 19403224,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 6.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dd3b3263-9f07-41b4-819c-568c82b875ce',
    'identification_number': 109462190,
    'item_consumption_1': 4663,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1973.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25c6ef42-fe05-4724-8dcf-621ffcebf9a6',
    'identification_number': 3030777692,
    'item_consumption_1': 757,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 382.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b672d7c3-a855-4a33-b43d-4cb616a7b28a',
    'identification_number': 108607658,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '129ac557-32c4-4123-bbac-aa4cbd468fa6',
    'identification_number': 105685291,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25351bf4-3351-4739-9634-92844dd75f41',
    'identification_number': 19403216,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 16.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9cb07974-a4cf-44b6-a350-309458528240',
    'identification_number': 59486058,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '300b0c56-ea99-4c82-bc91-c0cdae478e44',
    'identification_number': 111157285,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20471e41-190e-472c-b991-89a6c39ae180',
    'identification_number': 19452667,
    'item_consumption_1': 866,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 338.84,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '87a4043d-3b4d-4549-915a-5c172e082866',
    'identification_number': 31997651,
    'item_consumption_1': 3490,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1361.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44e53576-22ef-4b38-bd19-6eaab19f4664',
    'identification_number': 41360818,
    'item_consumption_1': "'-12.094",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-4216.41",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'aa49a3c2-a183-4894-b480-37ad92d248a7',
    'identification_number': 106846779,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34ab1b72-4a43-42f7-a3f9-b195ea2904c3',
    'identification_number': 76636291,
    'item_consumption_1': 9743,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5690.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16bd7884-5b42-4e9f-b9d4-7e37b6023816',
    'identification_number': 83593039,
    'item_consumption_1': 2394,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 933.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a3e628c-d310-43c5-8861-9e9b2aa7829e',
    'identification_number': 35685743,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '95f90196-2fd5-435d-9d19-eb0a0a832580',
    'identification_number': 106708120,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f5485a57-fee9-46a9-888a-e272df277eef',
    'identification_number': 12673544,
    'item_consumption_1': 26536,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3386.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59e69cf7-c827-49ea-ae74-996693522ff7',
    'identification_number': 23899166,
    'item_consumption_1': 5122,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1906.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c571b3c-20f9-4a35-949c-237dd3b83590',
    'identification_number': 100942270,
    'item_consumption_1': 8500,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3613.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2083ecec-9f72-4492-95bf-c47b704ca0fb',
    'identification_number': 73549223,
    'item_consumption_1': 1566,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 610.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e143ea3-276c-4140-8a10-d1b232fa96a7',
    'identification_number': 100612075,
    'item_consumption_1': 48.01,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1396.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ca818602-bb51-44de-afa4-a72e1a53bebd',
    'identification_number': 6227627,
    'item_consumption_1': 3481,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1358.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '977f42d5-c3cb-4248-98fd-357ea4f4999d',
    'identification_number': 69344639,
    'item_consumption_1': 6092,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2578.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '378cc2b9-be68-47ac-9321-3afb92fb67b1',
    'identification_number': 12908398,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5816.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7aba5f59-ec38-44ec-9d64-7efecf9e0e59',
    'identification_number': 16105176,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c43a7e43-d230-45f0-a92d-89b9606f55d1',
    'identification_number': 44567898,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ee397efc-0dbc-4540-8abf-b5552f028a58',
    'identification_number': 13877984,
    'item_consumption_1': 14198,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5283.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a113d558-2d43-41e9-8e93-8a1766779ae7',
    'identification_number': 92232949,
    'item_consumption_1': 299,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 126.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a32c40c9-1465-4834-91a7-3416d460750c',
    'identification_number': 103438157,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c3bd6b21-d944-48e7-a24f-8cdc1717dbd8',
    'identification_number': 62362801,
    'item_consumption_1': 17.01,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 405.7,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8dd41ee1-9402-41a3-bcb7-79920bf8ff55',
    'identification_number': 15751740,
    'item_consumption_1': 705,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 411.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '217a5cd1-3954-4b5d-8207-9009e842bea7',
    'identification_number': 13914839,
    'item_consumption_1': 3290,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1393.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99823eeb-028c-4bf2-acbe-be1e9a8c156f',
    'identification_number': 70425914,
    'item_consumption_1': 6867,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2682.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ac0d2c3-9767-4d88-9eaa-1c1688526003',
    'identification_number': 99591537,
    'item_consumption_1': 11261,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4786.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1bcde84-810e-43c6-beb0-561bd5c5bad3',
    'identification_number': 111295688,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 60.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'de9cc2c7-4146-4a91-a480-66ff9f4b56e4',
    'identification_number': 80073301,
    'item_consumption_1': 1463,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 571.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37cb17bb-7f41-4abf-81a2-2d20b1fe2e86',
    'identification_number': 66244013,
    'item_consumption_1': 330436,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 122970.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b93d9b1-1712-4518-84e7-dc61a858eb7e',
    'identification_number': 49096397,
    'item_consumption_1': 174,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 263.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '600a2628-84b2-4482-a741-b51db0dde9d0',
    'identification_number': 94952957,
    'item_consumption_1': 1051,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 445.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48d62ea0-c55d-4768-b88c-3214adad9ff6',
    'identification_number': 109594916,
    'item_consumption_1': 12306,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1570.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29e8c31e-ac0e-4e3d-898f-5fbc96608fec',
    'identification_number': 96305770,
    'item_consumption_1': 490,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 207.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8635a2b5-1c7b-4818-b6da-017ec1a66f07',
    'identification_number': 33688907,
    'item_consumption_1': 1063,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 451.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74d92f71-3057-4992-953a-81aa28532ae0',
    'identification_number': 15348075,
    'item_consumption_1': 5740,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3352.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b460dee-38a3-45de-9315-e305fc736ba0',
    'identification_number': 13921789,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 133.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '293d5dfd-3f34-411b-a6b3-8e917122557e',
    'identification_number': 70941467,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'df7d13ae-da4a-445e-bb85-33e442073a85',
    'identification_number': 13977873,
    'item_consumption_1': 20.14,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 585.78,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '77780021-d917-4b06-b602-0c8ac39d41b8',
    'identification_number': 10647422,
    'item_consumption_1': "'-833.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-266.92",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '4651d2f9-6092-4a18-9502-da7ca6ec93dd',
    'identification_number': 21374792,
    'item_consumption_1': 197.93,
    'item_description_1': 'DEMANDA PONTA TUSD',
    'item_total_value_1': 8994.45,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f029bd51-5a69-47c7-bb22-6dd0b47286e9',
    'identification_number': 99258404,
    'item_consumption_1': 10256,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4341.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edd9f19d-601a-465f-b24c-a2491536d150',
    'identification_number': 11013958,
    'item_consumption_1': 2.65,
    'item_description_1': 'DEMANDA REAT EXCED USD FP',
    'item_total_value_1': 77.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'cc3f631e-bfba-4d75-ad95-42f4e2fd9d34',
    'identification_number': 39545377,
    'item_consumption_1': 1398,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 816.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f4d04f5-4f8a-4b4b-95e4-f33c57179cff',
    'identification_number': 92674488,
    'item_consumption_1': 2368,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1005.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b1f67de-a665-4700-b992-6517bdb4faae',
    'identification_number': 30015189,
    'item_consumption_1': 2203,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 859.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96112b78-7624-4264-847b-d8256ab7cb22',
    'identification_number': 106129864,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.04,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2a6f6830-c729-441d-9736-cb21f68049e6',
    'identification_number': 64716929,
    'item_consumption_1': 115227,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 14707.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b6a9c3a-12d4-4d80-ba11-77924a4df54e',
    'identification_number': 75403943,
    'item_consumption_1': 27217,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3473.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '205cb24b-72a1-483f-baca-1149a2ed3eca',
    'identification_number': 67511929,
    'item_consumption_1': 211,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 89.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9d28dbf-87fd-4946-895f-8a9019a8abb6',
    'identification_number': 16105826,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e8b6aef9-206c-4f29-a2bb-2af467dfa73a',
    'identification_number': 32747217,
    'item_consumption_1': 11882,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1516.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26615d7b-f1ef-47c9-8739-4c03305a09a2',
    'identification_number': 29706980,
    'item_consumption_1': 6057,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2575.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd491e46b-474c-4385-b58e-6d2979b5be18',
    'identification_number': 103772561,
    'item_consumption_1': 534.48,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 12747.62,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd477eb0e-15e3-435a-baf3-3f7c20b5b2c9',
    'identification_number': 77819047,
    'item_consumption_1': 343,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 145.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c12201d6-ed0b-42cc-9f7a-f2f8e8fe1ab3',
    'identification_number': 17726425,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '77492ffa-3c92-460a-9d36-958943089b5f',
    'identification_number': 100343511,
    'item_consumption_1': 8497,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3311.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5154ec4e-1d8a-408a-ba41-2f3ba29f4c54',
    'identification_number': 935371632,
    'item_consumption_1': 2627,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 2290.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '945fc97e-416f-4b27-b956-d96cac891d99',
    'identification_number': 71675787,
    'item_consumption_1': 2683,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1047.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '113ec2f2-9357-40f5-a5af-f4da610396a7',
    'identification_number': 42496390,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '12878073-aaad-4744-ad9f-fdda12b52e95',
    'identification_number': 18616909,
    'item_consumption_1': 878,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 512.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06cbf2d4-115f-4a4f-a8a8-a375971a57cf',
    'identification_number': 106595415,
    'item_consumption_1': 683,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 290.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfadb1fd-18a9-4d56-91c0-2fb075569e0b',
    'identification_number': 105807435,
    'item_consumption_1': 547,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 231.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf86e7d6-99b1-4e88-a845-8bf6bd62d941',
    'identification_number': 5693608,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '85f37693-ef01-47eb-a9e1-cde9d604554c',
    'identification_number': 70503974,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4e7347e3-afdf-4fa9-a725-08f37e263936',
    'identification_number': 15740056,
    'item_consumption_1': 3232,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1260.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec4f776e-846e-470c-a850-0199d9f8af18',
    'identification_number': 42712190,
    'item_consumption_1': 1994,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3016.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c25d5a19-0058-49a5-bc53-efd6f0e079fe',
    'identification_number': 101171714,
    'item_consumption_1': 251,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 97.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '778d0466-7310-4b56-ae8a-655a4e325a38',
    'identification_number': 10521119,
    'item_consumption_1': 2500,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 973.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2dba1a91-3295-450e-bbc7-0bd15c89a0a2',
    'identification_number': 41700929,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7ad31ef-7b9e-45d0-99c1-f34858fbe1ab',
    'identification_number': 95839267,
    'item_consumption_1': 129,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 54.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83a86d50-3523-45dd-8408-2ca5519834c9',
    'identification_number': 85205672,
    'item_consumption_1': 5.22,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 244.46,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5561272f-f372-453b-b537-d62676518a23',
    'identification_number': 106443780,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '26e20919-bbeb-499d-9ecf-bf5cb75d9152',
    'identification_number': 105611271,
    'item_consumption_1': 34,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 13.29,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cdf56fb8-62a2-4456-8202-2e57223666cc',
    'identification_number': 103284770,
    'item_consumption_1': 37.51,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 894.66,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6dc1295b-bcdc-4cd0-bc60-78d65164121b',
    'identification_number': 13299573,
    'item_consumption_1': 5099,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2159.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6d334ad-1012-4612-aa33-ab6c9026c5cf',
    'identification_number': 13178032,
    'item_consumption_1': 10.5,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 250.43,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0bf25e30-a3ec-4f44-b7a1-41801c9fe865',
    'identification_number': 46912797,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d9d7ba9-44a6-42f4-8f3b-565fc9e9c663',
    'identification_number': 62391453,
    'item_consumption_1': 49.51,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2827.51,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'df32c7a1-9f80-4412-ae65-e0197422bde0',
    'identification_number': 71893156,
    'item_consumption_1': 13387,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5676.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ce4cb58-1f17-4b0d-af59-699f73f406d9',
    'identification_number': 19452934,
    'item_consumption_1': 127678,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 193153.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '192d95fe-8c6c-45fb-8d30-687a5eb2859e',
    'identification_number': 107112981,
    'item_consumption_1': 633,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 268.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d0f87cc-313e-4186-af77-12556eac38ed',
    'identification_number': 94516227,
    'item_consumption_1': 910,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 385.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf371a7d-eb33-4bc4-b274-603fb3028acc',
    'identification_number': 94896330,
    'item_consumption_1': 1161,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 452.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59afbfc4-a243-4f13-9e01-404ac75c526b',
    'identification_number': 89126610,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 83.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '14441642-44a1-41e5-b06b-46e43f2f230a',
    'identification_number': 108015955,
    'item_consumption_1': 1328,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 519.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00bc77fd-503f-4717-9876-4e4089c53540',
    'identification_number': 16177401,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib iluminação Pública',
    'item_total_value_1': 59.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b91b2d73-0300-406e-bcad-12950497bd25',
    'identification_number': 77405340,
    'item_consumption_1': 42.52,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 1991.24,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c61844ef-2759-49f7-8298-8c486029054c',
    'identification_number': 75768879,
    'item_consumption_1': 97.9,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2847.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '13434fb3-9e79-463a-a36f-28e881ed338f',
    'identification_number': 35614595,
    'item_consumption_1': 23.98,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 571.94,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '647ce8dd-ed0c-4213-85a0-28eb1240e48d',
    'identification_number': 17633419,
    'item_consumption_1': 3151,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1229.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71257598-1bd2-4a79-9730-83a6574a7ce2',
    'identification_number': 100769578,
    'item_consumption_1': 503,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 213.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a73f531-2653-411b-b41e-66d55380c257',
    'identification_number': 42710529,
    'item_consumption_1': 14947,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5562.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'afb65609-c93a-4b5e-932a-2e71b8d1d0c7',
    'identification_number': 5591783,
    'item_consumption_1': 250,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 97.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a86d754e-672d-4a52-8fb7-6ffbe3c8a9a8',
    'identification_number': 12956465,
    'item_consumption_1': 91158,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 33924.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a24c4911-2f27-4358-865d-986cb079f7f0',
    'identification_number': 102259178,
    'item_consumption_1': 934,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 545.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '902351f5-03a6-45da-844b-faa362b99908',
    'identification_number': 77672925,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 875.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18889bd2-ff7e-456f-b01f-16a057b240d9',
    'identification_number': 108788954,
    'item_consumption_1': 12834,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4776.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd80947bc-1cdb-4cab-84df-dc741b2a7b8d',
    'identification_number': 50128434,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57984c93-f079-413a-a7e2-809203bdbe5b',
    'identification_number': 8115770,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0687335d-6bee-486d-b0f9-27be74955c80',
    'identification_number': 93763395,
    'item_consumption_1': 4.2,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 100.18,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b85a9d78-4864-4117-9b7e-bbe0245bce64',
    'identification_number': 93683553,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '06d7eff4-ebdf-4705-95d8-4540658b7210',
    'identification_number': 12381519,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '75439ee4-bc9d-443a-953f-90b928e0685d',
    'identification_number': 16574907,
    'item_consumption_1': 2510,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 980.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8568c206-7a12-4cfe-9cf2-ebc3c4537e23',
    'identification_number': 108864855,
    'item_consumption_1': 428,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 166.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50754a81-ed0e-492f-b021-811a094bad6c',
    'identification_number': 44634374,
    'item_consumption_1': 2586,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1510.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7d2a470-5e07-437d-aa85-4dab0fd8324d',
    'identification_number': 39214800,
    'item_consumption_1': 4627,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 590.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bc4d6e3-36aa-424f-8076-5133d507b871',
    'identification_number': 42709717,
    'item_consumption_1': 30.14,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1721.28,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4e6a1a36-ba11-4e3e-84d7-ccf9dce1db8b',
    'identification_number': 65625706,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-55.31",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '24f874e7-cc58-4040-a4a7-8fd792fd169c',
    'identification_number': 23175575,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ad0a12a-1bbb-43be-bd55-905d6f3d29a3',
    'identification_number': 14822725,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '090c40c0-b8b7-4f37-8cfe-94365df70224',
    'identification_number': 13973932,
    'item_consumption_1': 1346,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 525.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38c87ad0-744f-4ec1-832c-b55f9ff1c3c6',
    'identification_number': 48432130,
    'item_consumption_1': 5078,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7682.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90d0add3-0cdd-4047-a49b-303fe9b125ae',
    'identification_number': 84768754,
    'item_consumption_1': 8.23,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 196.29,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '43f26e40-612c-46f5-a5cf-25addee5d24b',
    'identification_number': 41422775,
    'item_consumption_1': 14172,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5274.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd512ab9e-05fd-40b8-a0f6-205fb64e9e67',
    'identification_number': 73534609,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd1c98306-fbd8-4510-9572-34c6f5b586bf',
    'identification_number': 94623694,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d658b7d-6314-4165-982a-5a50dc3ea39f',
    'identification_number': 36289515,
    'item_consumption_1': 2257,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 881.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2ed357b-f682-418c-a924-10b9324a1f48',
    'identification_number': 95733086,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 112.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa0cdcbb-8a89-4058-bd6f-91bbc8d4f3ae',
    'identification_number': 37507940,
    'item_consumption_1': 25018,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9310.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3bb15464-0e35-4968-93ac-3acabe927d2b',
    'identification_number': 100365302,
    'item_consumption_1': 480,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 187.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0bf352c-3d83-46da-9017-7d0f5c277f8a',
    'identification_number': 108178781,
    'item_consumption_1': 23.89,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 569.79,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'eca0a274-17f8-41fe-aa8d-382993134568',
    'identification_number': 70427054,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4cf5ed1b-bf35-41ff-82b2-92794c2eda6b',
    'identification_number': 40013987,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '016fa8d1-7c49-4a94-9c96-e8953502647c',
    'identification_number': 111270561,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74db331a-20e6-48d4-a593-7412ed4713a3',
    'identification_number': 69443149,
    'item_consumption_1': 337,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 142.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ded0168-f258-43e9-b31b-fa582b48db6f',
    'identification_number': 14039362,
    'item_consumption_1': 79,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 30.89,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c05ad98b-2f9e-4019-98c2-0a836f27ed01',
    'identification_number': 90439325,
    'item_consumption_1': 36931,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13743.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e680052-017d-472e-9254-4eaf783b0a39',
    'identification_number': 86398652,
    'item_consumption_1': 35321,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4508.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5004add4-ee6b-4202-9905-327e1a9165d1',
    'identification_number': 109086678,
    'item_consumption_1': 360,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 140.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11a1a66d-1598-473e-9733-c62e14c7e974',
    'identification_number': 99733579,
    'item_consumption_1': 9740,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4130.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd528cb5c-3dfa-4ac5-9f94-6cf3bd9584a7',
    'identification_number': 34098380,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a5402074-ae8b-4bb6-90d5-9b1b256e347d',
    'identification_number': 109545907,
    'item_consumption_1': 83.59,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2431.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '385bbbb2-8be1-4f95-8f4f-16c5a5dfa033',
    'identification_number': 19450737,
    'item_consumption_1': 91731,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 53574.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '819a3f13-3669-4217-a1d6-3483ea8dcd60',
    'identification_number': 101420625,
    'item_consumption_1': 896,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 379.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0eae4a67-fa5d-462b-a865-73961573c0f8',
    'identification_number': 105611271,
    'item_consumption_1': 28160,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3594.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4627c4f4-bb56-4388-899c-785a472d0f12',
    'identification_number': 26480344,
    'item_consumption_1': 13776,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5840.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9723022c-8bf2-47f2-aa72-2216ce4cd2b2',
    'identification_number': 39922898,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1e907b6-ee27-46ab-9886-cb756be10f93',
    'identification_number': 56812477,
    'item_consumption_1': 386,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 49.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8dcee0ae-f4c8-410e-87a5-21ec9c93c9c9',
    'identification_number': 62339389,
    'item_consumption_1': 328,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 138.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1f853df-1d53-499d-ae32-420799a1a1e3',
    'identification_number': 41640870,
    'item_consumption_1': 8070,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4713.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f71fbd90-e50a-4d46-9de6-9df98ce57749',
    'identification_number': 98233920,
    'item_consumption_1': 265,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 103.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb62c386-c70a-4671-9c6d-c2085166d17a',
    'identification_number': 23554231,
    'item_consumption_1': 234,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 91.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f10980d3-650e-41a5-beb6-aa0d18ac1071',
    'identification_number': 44259867,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4fe6008c-b5c7-419f-8049-e20a2e6a3db7',
    'identification_number': 103377271,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.06,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '70fc5589-e7f0-419b-85f9-89a5b2f638b5',
    'identification_number': 40934756,
    'item_consumption_1': 180.76,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 10323.19,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e5b97114-139b-496b-a0e1-5b4675c31db8',
    'identification_number': 101068484,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '028fd199-4415-4514-9bfc-f571a3dd6804',
    'identification_number': 27904660,
    'item_consumption_1': 47.67,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2722.41,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2f0cf970-a283-407a-8dde-d03f3036331a',
    'identification_number': 107941392,
    'item_consumption_1': 39.85,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1159.07,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '830bbe0f-8d5a-47d7-8b96-b086884022a4',
    'identification_number': 108630323,
    'item_consumption_1': 1326,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 561.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa47ca02-a31d-4acc-88d1-65f09fa0047f',
    'identification_number': 98598945,
    'item_consumption_1': 1922,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1122.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d3f8b16-dbbb-476e-b330-78a2d0dfe05f',
    'identification_number': 105092126,
    'item_consumption_1': 182,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 77.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'deae58a0-a87c-4ea4-ba6c-c88947ab759d',
    'identification_number': 103748601,
    'item_consumption_1': 6248,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2651.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96f48cba-22cb-4322-a57a-9d88b5436e0f',
    'identification_number': 99786184,
    'item_consumption_1': 6.52,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 155.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'dfb1da60-a566-4360-9f14-9b81276780e4',
    'identification_number': 59485469,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8953c978-fbf3-4bdd-a9c8-ee5470ef4280',
    'identification_number': 3078469,
    'item_consumption_1': 2360,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 919.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0042408c-8e4c-40ce-86ff-94dcbe738795',
    'identification_number': 98349821,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ca24dd54-c4a3-45fa-a787-9cb684dcb663',
    'identification_number': 12743542,
    'item_consumption_1': 70.45,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2049.08,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8e94eab2-a89d-42b1-9237-c441505f7ddf',
    'identification_number': 90453450,
    'item_consumption_1': 6220,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3632.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01a1e029-dcfb-4a9e-b638-93566eae4bcc',
    'identification_number': 10266178,
    'item_consumption_1': 3289,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1396.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33902f51-f261-49b8-ab4b-529ebdd84456',
    'identification_number': 105797634,
    'item_consumption_1': 6508,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 830.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9614a46-5f3a-413e-a1df-5c5475be79bf',
    'identification_number': 101499531,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '00738cc7-0c49-47d3-ab2d-3b38cd6ffa1f',
    'identification_number': 15719464,
    'item_consumption_1': 1291,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1953.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a29c87f6-7f55-4b57-9130-d4b479d729a0',
    'identification_number': 21817405,
    'item_consumption_1': 141.25,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 8066.78,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8d9118c6-9c4e-4088-bf85-bad468d1f13f',
    'identification_number': 55605451,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb9be44d-549e-4bfb-94ea-bc0943d4ab31',
    'identification_number': 67097898,
    'item_consumption_1': 1.28,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 30.53,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1520bb1b-9f97-49fd-8789-249397d348f6',
    'identification_number': 94724741,
    'item_consumption_1': 1.56,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 45.36,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'f55157eb-4fb9-4531-93f8-84ec450a24e4',
    'identification_number': 92387578,
    'item_consumption_1': 14818,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5514.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64bd35d2-7ed0-469f-9569-b42765088185',
    'identification_number': 48339610,
    'item_consumption_1': 52367,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6683.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1b394cd-60cb-493b-8548-c5aaaf6b2b26',
    'identification_number': 41499255,
    'item_consumption_1': 71.06,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2066.82,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a0ed7733-3f18-4f4c-a4e8-103b6c91795f',
    'identification_number': 37507940,
    'item_consumption_1': 877,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1326.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d086154-116e-4c2c-b32b-068f4752fe89',
    'identification_number': 101068484,
    'item_consumption_1': 72.12,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2097.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1b960690-3174-4a65-ad4a-f8f8f8a93c91',
    'identification_number': 12743542,
    'item_consumption_1': 3024,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1766.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24b058d0-4b8a-47c9-bdce-ec0e7a9a182c',
    'identification_number': 93087225,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ca33365-a00a-4fad-ad05-76c65328f774',
    'identification_number': 108197271,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '74720e30-ef3e-459b-930c-4036678388ea',
    'identification_number': 106508938,
    'item_consumption_1': 1994,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 847.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c891897b-6661-414f-bc15-bb45bfd25453',
    'identification_number': 98178679,
    'item_consumption_1': 3414,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1445.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ec249ad-fa0c-48a0-b8b3-be096d6c5af6',
    'identification_number': 99379422,
    'item_consumption_1': 9,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 3.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4d454a4b-af8a-4893-9ad6-e50d8f1a090c',
    'identification_number': 88735800,
    'item_consumption_1': 335,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 130.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd7a0d89-a2b5-4fc2-a71d-9d9cd5e78c4f',
    'identification_number': 35717661,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f3da39fb-4b02-4095-bec2-dbfce2a8736d',
    'identification_number': 51026279,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7942b475-cba4-430d-b8d3-1bf2cb42c309',
    'identification_number': 106120310,
    'item_consumption_1': 971,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 379.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4f92983-52a7-47d2-852b-3c201f6bc0e9',
    'identification_number': 56189060,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de39f541-5a8c-419e-aaf9-3badb5d5c5a9',
    'identification_number': 97742236,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2e7a83e5-505f-4920-a834-0893c42c591a',
    'identification_number': 9214810,
    'item_consumption_1': 6071,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2372.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7094c97-cae0-480e-9282-944c477930c5',
    'identification_number': 23660872,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '82d1b6fb-fd69-4b87-aeb3-263ac97874c3',
    'identification_number': 33921148,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '92013d79-2459-49df-aab2-71bf6001ec8f',
    'identification_number': 76514587,
    'item_consumption_1': 612,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 17800.6,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f53394f3-860e-4bbc-9dc3-84945fde446a',
    'identification_number': 93232489,
    'item_consumption_1': 2155,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 841.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96c9361e-80cc-44ae-a100-3c5a8111ad9d',
    'identification_number': 104648201,
    'item_consumption_1': 596,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 232.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a93c83d9-b37f-406b-8429-facd12d5eb54',
    'identification_number': 102436053,
    'item_consumption_1': 770,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 300.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d903759-158d-4be1-90fe-fe7a9450d7a9',
    'identification_number': 42246873,
    'item_consumption_1': 2483,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 967.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4dc78448-3425-4d65-815a-cb967a2b8be5',
    'identification_number': 68447450,
    'item_consumption_1': 37.19,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1081.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'deb35fe6-36aa-4883-876a-6b3e7820aa77',
    'identification_number': 59850213,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0a21f85-e930-427e-bda0-2d354509680a',
    'identification_number': 56877250,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bcd6a1a8-ef82-42b9-96b1-745a2541af89',
    'identification_number': 92828760,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3338.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea04b709-ba22-4edb-9e7a-d3237847c97a',
    'identification_number': 56527144,
    'item_consumption_1': 7.94,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 189.4,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fb2e629e-fd75-4e4d-8612-dbb176ce4a69',
    'identification_number': 86207563,
    'item_consumption_1': 115978,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 14803,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d0c9f0a-8d82-4992-8434-1594876f97bc',
    'identification_number': 51527987,
    'item_consumption_1': 26.02,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 756.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f789dc40-6f97-4bec-9582-55da99bfff39',
    'identification_number': 17435048,
    'item_consumption_1': 1518,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2296.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '941ebaee-e60c-4250-b5f2-32527e94a525',
    'identification_number': 47011980,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ecf89962-566f-438d-ba1a-318b6a7ccc9b',
    'identification_number': 16003500,
    'item_consumption_1': 2191,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 1253.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d005de0-2bab-4ece-afd5-d4bff206ee50',
    'identification_number': 46861009,
    'item_consumption_1': 17943,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6677.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6eda90c-ba5c-4a33-9821-b1df7432a253',
    'identification_number': 87381796,
    'item_consumption_1': 1058,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 449.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a315468d-b757-4c2d-be68-7246bf837820',
    'identification_number': 21059128,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2411d184-f3b1-44cb-9886-b4572b878c09',
    'identification_number': 86489771,
    'item_consumption_1': 3576,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1395,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a96f275-9720-46c2-8033-34a221344ba4',
    'identification_number': 17073030,
    'item_consumption_1': 1184,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1791.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1dbfba15-c644-4c67-89c0-5b030355d2c2',
    'identification_number': 12797707,
    'item_consumption_1': 26.3,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 764.93,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '42e917a0-f8dc-4e6d-8030-b2fbf208c7eb',
    'identification_number': 97863084,
    'item_consumption_1': 389,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 165.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a48ec503-3d83-4b6b-bc05-b61aa5f8ac1b',
    'identification_number': 91976715,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3bcb07d-8a04-4112-a4da-de4dfcfaa88d',
    'identification_number': 93093110,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90dfb61e-9613-4e5b-9543-ac133411bf0b',
    'identification_number': 66244013,
    'item_consumption_1': 16501,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 9637.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc15ad82-4188-49bd-9633-e55a0f570102',
    'identification_number': 48843075,
    'item_consumption_1': 21426,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2734.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '288bc13e-49c1-4d96-a7b2-f39dd4e68cfe',
    'identification_number': 55932550,
    'item_consumption_1': 3277,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1387.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e4b429e-f075-404d-80b7-52e8bb9caa58',
    'identification_number': 58072004,
    'item_consumption_1': 3646,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2129.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea6be837-27c2-4df5-83d7-02bab49fed85',
    'identification_number': 14859513,
    'item_consumption_1': 1099,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 641.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '613915f0-d610-45b5-9557-95f5d88e4ff0',
    'identification_number': 55193994,
    'item_consumption_1': 22342,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2851.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '951b89b0-9d11-4208-9ce3-eda7aac045f7',
    'identification_number': 69851000,
    'item_consumption_1': 5995,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2341.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '011d6f0e-e8bc-4637-a6da-db54ffd3fff1',
    'identification_number': 77672925,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 495.14,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '80c99176-3626-49b3-8b01-675a59582f2b',
    'identification_number': 26806886,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f76cf23-807d-4409-a84f-aa2685fdd399',
    'identification_number': 29677459,
    'item_consumption_1': 1678,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 655.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02f77f96-4078-465a-807f-f07ce7301c0a',
    'identification_number': 69855315,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '10841f34-350a-4a89-83e1-481f6f17bdfd',
    'identification_number': 59532777,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-47.35",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '796d8b5d-4ea9-4e27-b464-b2254fc36196',
    'identification_number': 102684650,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e7cfc359-320d-4ff0-822f-61982b5606cb',
    'identification_number': 93302096,
    'item_consumption_1': 1484,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 630.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3107a29-81b5-4892-b680-e558fafafdc3',
    'identification_number': 32500459,
    'item_consumption_1': 27.15,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 789.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3bb9fc22-f639-4f0c-ae71-63747c7e64e5',
    'identification_number': 84983981,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-3482.16",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '4e5d7bde-1a9e-43e4-86ae-1afcb15bc3dd',
    'identification_number': 35959037,
    'item_consumption_1': 6405,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2711.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4544c90-8606-410e-b34c-553678625050',
    'identification_number': 95106839,
    'item_consumption_1': 6443,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2737.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '096646fe-8dd8-46d4-b0f4-d03bad6ba731',
    'identification_number': 31639267,
    'item_consumption_1': 11847,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4408.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54e7b246-ec4f-4955-8fb3-06a7a911501f',
    'identification_number': 27125700,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'deed2db1-f9de-4e54-9004-5e0345b4c5fc',
    'identification_number': 14138069,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '395f01d6-a680-42f9-815e-778319ba63de',
    'identification_number': 16628063,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4d810ab5-6043-4228-8c61-7c5fd070d457',
    'identification_number': 108106128,
    'item_consumption_1': 125.91,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 3003.02,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f741b861-f4ac-4444-b63d-5f2f2b2f2397',
    'identification_number': 55524567,
    'item_consumption_1': 672,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1016.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc7280fc-918c-4634-8727-1c28a3c8ed26',
    'identification_number': 74656333,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ad07ee6-1511-41a7-b822-926830499175',
    'identification_number': 12222615,
    'item_consumption_1': 8827,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5155.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6f2871a-35ff-4efc-a03b-f93f8c4664db',
    'identification_number': 69758409,
    'item_consumption_1': 186,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 72.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e8d4b3fc-50db-4880-92de-e6bfd17b84f2',
    'identification_number': 8661758,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '767f7e6d-194f-4310-b626-b6ee83f8a50f',
    'identification_number': 39208869,
    'item_consumption_1': 109173,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 13934.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6f27076-1d24-4a38-8ff1-ad6e6f25d81c',
    'identification_number': 51026279,
    'item_consumption_1': 30,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 715.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '95a918d1-8c79-4b17-83b2-113765bee711',
    'identification_number': 9204237,
    'item_consumption_1': 950,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 402.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a03b6a7-9fbc-497f-acde-dce510362ba9',
    'identification_number': 71675787,
    'item_consumption_1': 2683,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1138.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0c409bc-ef1e-4baa-96b1-0742f47d209c',
    'identification_number': 26780003,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87cf355d-edcd-46dc-9bfc-2b7352495743',
    'identification_number': 16246780,
    'item_consumption_1': 15.57,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 905.72,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '043d837a-df52-4356-a31a-c42054e6a0d7',
    'identification_number': 64669360,
    'item_consumption_1': 53,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 80.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be6f6185-4e20-4d5c-8bc3-7edf467e4b35',
    'identification_number': 75403943,
    'item_consumption_1': 397,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 155.32,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '204f4416-121f-4275-922b-ccb02ed22e6e',
    'identification_number': 21817405,
    'item_consumption_1': 141.35,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4111.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '488dac41-2a6b-4472-86dc-0ea473adb781',
    'identification_number': 105849944,
    'item_consumption_1': 125,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 48.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '11bf11e4-9ee5-40c4-b013-241d6102c68b',
    'identification_number': 109863887,
    'item_consumption_1': 2041,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 796.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ca43193-7742-41d5-b2f3-49268917ba3a',
    'identification_number': 10836128,
    'item_consumption_1': 12210,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5177.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31b3bc46-83fb-4258-9bd8-e62bcf610919',
    'identification_number': 39678318,
    'item_consumption_1': 1846,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2792.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfcc0753-6e72-4c8a-808e-962a1736ef06',
    'identification_number': 95371184,
    'item_consumption_1': 1015,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 431.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c20e3182-5945-454f-84ce-01c751002d5e',
    'identification_number': 109118553,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb44f04d-a615-4321-b372-8a5af4851b8f',
    'identification_number': 47623578,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c8ea93c-0a0b-43a6-9229-a57d161ce253',
    'identification_number': 74328336,
    'item_consumption_1': 375,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 146.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7e327a5-ec86-42ec-8179-c892e7f08408',
    'identification_number': 48827657,
    'item_consumption_1': 12546,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4886.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e49ef74f-3c42-45c3-9791-51a1e8cfadf0',
    'identification_number': 93205589,
    'item_consumption_1': 3227,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 411.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '923b3085-d3a4-4a60-9448-d992823bb99b',
    'identification_number': 34216251,
    'item_consumption_1': 7461,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3169.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ecfc674-4bc0-4d94-8a13-93ac689f1689',
    'identification_number': 110007948,
    'item_consumption_1': 139,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 58.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd617e67a-87d2-4e00-bcd3-ffbf845f6302',
    'identification_number': 101195435,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-41.99",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '0c5115cb-8c4c-4d7c-a8db-409eea432384',
    'identification_number': 948747588,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '75455f80-181e-439c-8193-da62aafd181e',
    'identification_number': 45017336,
    'item_consumption_1': 87.17,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 2079.06,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '99d7c52b-46cb-4816-9571-39d13c7717d8',
    'identification_number': 82521310,
    'item_consumption_1': "'-3.229",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 06/2023',
    'item_total_value_1': "'-1122.22",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '8a98ce8e-1160-40bf-aa4e-c608ebc748b0',
    'identification_number': 104457260,
    'item_consumption_1': 268,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 104.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fee4db12-1170-48dc-bd0e-ac5b20ac9773',
    'identification_number': 103764755,
    'item_consumption_1': 339,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 512.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1956aba5-257b-42b2-b768-8a9dcfc9d50e',
    'identification_number': 103772561,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6da39e2f-1f11-42da-9800-4901aee39944',
    'identification_number': 19452160,
    'item_consumption_1': 551.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 16041.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cd16682a-7930-4bda-b157-76e7e5e5265d',
    'identification_number': 91358469,
    'item_consumption_1': 2359,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 998.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f3f9ce4-4ea6-4f21-8e7d-9347c8fd7b75',
    'identification_number': 41460510,
    'item_consumption_1': 17.95,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 522.07,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '67f7d7c4-2c5f-441c-b846-e4c42af119c5',
    'identification_number': 5695015,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.58,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c5c0be41-7752-47ee-b178-c50c7e46616a',
    'identification_number': 12804401,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1821f3c3-0732-44d3-90ce-19c0b1f08f7b',
    'identification_number': 14115344,
    'item_consumption_1': 4730,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2007.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e26e0fef-428d-4960-9349-05d649af8141',
    'identification_number': 79937667,
    'item_consumption_1': 161.27,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 9210.1,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a77bd40b-218a-4dab-b3cf-bd5a8b8894b6',
    'identification_number': 51200511,
    'item_consumption_1': 3084,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1307.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af8d7fe7-31e0-4e41-b128-8cd8d98f7479',
    'identification_number': 105472336,
    'item_consumption_1': 6281,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2450.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8907aa20-7a92-44cd-bfc9-6db5753e417e',
    'identification_number': 76608107,
    'item_consumption_1': 14186,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5544.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d7f202e-d28e-402b-b920-dd5751b5895e',
    'identification_number': 111848520,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4fbb878-7584-4165-ad75-2b0fc3b0a40f',
    'identification_number': 93037279,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9259a1d-ae7d-4a05-bca2-557cdea60244',
    'identification_number': 21695660,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6240fa9b-273e-4b6d-b13f-e5edb017f5e5',
    'identification_number': 41490533,
    'item_consumption_1': 230630,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 29436.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'faa5e938-4ec8-4d2b-a4fd-1a26393eea9b',
    'identification_number': 64104770,
    'item_consumption_1': 4614,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2694.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f80989c-ce6f-42fd-aaac-43d19760e581',
    'identification_number': 3030777501,
    'item_consumption_1': 100,
    'item_description_1': 'Disp Sistema-TE NOV/23',
    'item_total_value_1': 29.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ba44383-66ff-4f78-a4c1-34cfa53379c4',
    'identification_number': 110527100,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67f8b3b2-9a7c-489d-a26f-f66d3e5d5e16',
    'identification_number': 43036830,
    'item_consumption_1': 3,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 71.56,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6fd02f26-ab86-47cf-8f30-2d0c4a41a012',
    'identification_number': 108466051,
    'item_consumption_1': 890,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 376.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae7dc6da-8ce8-4cbf-9528-fdb35809c147',
    'identification_number': 19873727,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 7.83,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2807af41-73d6-4988-a37a-e52270a70066',
    'identification_number': 111113962,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ca8eff9-6d3f-4f6e-85a1-67f0cc12d1d9',
    'identification_number': 41360869,
    'item_consumption_1': "'-2.16",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-753.05",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'e13e9319-d42d-49c8-a62f-2a1d6b852181',
    'identification_number': 102220824,
    'item_consumption_1': 1465,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 855.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5b33bc7-e3b7-408a-80c1-716701125223',
    'identification_number': 28259513,
    'item_consumption_1': 11883,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4422.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f313086-8e81-4d30-845d-ad6f9031b00d',
    'identification_number': 32715811,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '37b4e66e-273b-4177-bd2c-1b0677550d09',
    'identification_number': 111583748,
    'item_consumption_1': 512,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 217.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f6c29e8-d14b-46b8-a501-f2840f45464f',
    'identification_number': 31560792,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2c91a0d9-7714-446b-b7e9-5a48ef3d6cdb',
    'identification_number': 19306679,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4a631d75-858b-49a3-86bd-f161ee2aebb2',
    'identification_number': 6100350,
    'item_consumption_1': 2718,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1152.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '339fc6f3-d50c-4852-8ff1-42b38e0afc85',
    'identification_number': 41932510,
    'item_consumption_1': 43.44,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1263.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f356bfff-f624-45c5-ae85-4ea55ec36c3a',
    'identification_number': 67097898,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '10281701-cba0-4835-8767-a10666870afb',
    'identification_number': 18616909,
    'item_consumption_1': 6980,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2597.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '790d30e7-8780-488f-b3d3-16b1377499c8',
    'identification_number': 56881444,
    'item_consumption_1': 9613,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1226.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95ec3ec0-c56f-473a-83b8-a90328c1c7ed',
    'identification_number': 103341013,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '179bfe1b-ea2b-4c94-bd7f-cbd45a02f24f',
    'identification_number': 84930756,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3140213c-a0b2-4b7a-b96b-678413fa7150',
    'identification_number': 79703070,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a0ab4ff-5c22-4d77-807c-db020b6d26a6',
    'identification_number': 101006675,
    'item_consumption_1': 236,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 92.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '306eb44b-3196-4b4a-ba0a-f11a260ec3b4',
    'identification_number': 42246873,
    'item_consumption_1': 2483,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1051.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1db5e891-3e84-48d9-b41f-2cf959b9c574',
    'identification_number': 102611033,
    'item_consumption_1': 936,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 365.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be6c46f9-c37c-484f-b144-af37d413e01e',
    'identification_number': 21818983,
    'item_consumption_1': 4162,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1765.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a35230dc-b4cf-47a8-bb3b-63274fe56a0b',
    'identification_number': 91304083,
    'item_consumption_1': 11470,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4482.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '393997b5-f125-4e1e-8976-dec7b668f14a',
    'identification_number': 96256443,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15da5212-141c-43fb-8145-0b206f702206',
    'identification_number': 16968859,
    'item_consumption_1': 609,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 237.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5948d8c2-3264-4b57-8345-28518f1f1b23',
    'identification_number': 36425532,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd64a895c-af7e-4d14-a2c5-ce081e1b91b9',
    'identification_number': 104532823,
    'item_consumption_1': 599,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 233.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf915b33-3aec-479f-8362-e8f71ac3f7d6',
    'identification_number': 111295890,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db342046-0497-4be1-8361-230f1406f3b4',
    'identification_number': 76514587,
    'item_consumption_1': 16779,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 25383.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30ffd45e-d863-4a34-99ef-f7e59516d275',
    'identification_number': 38772043,
    'item_consumption_1': 3370,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1428.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f945b4ca-e1e8-4df0-843d-c462463d2422',
    'identification_number': 17085071,
    'item_consumption_1': 2050,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 801.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af5bc9fd-267b-43bb-a3e9-d83a4c6bd225',
    'identification_number': 28671139,
    'item_consumption_1': 16.99,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 970.28,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5f5f1e8c-78d0-4527-b22a-c04105d5ee6f',
    'identification_number': 22971300,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6dd3429c-17c9-4347-a397-486411451c3e',
    'identification_number': 70651515,
    'item_consumption_1': 10988,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4665.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca6f2488-0f19-48ff-805d-a19a5eb53c34',
    'identification_number': 108178781,
    'item_consumption_1': 15190,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1938.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95f38d4c-319f-439a-a27c-071ce62e5991',
    'identification_number': 8806446,
    'item_consumption_1': 905,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 353.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4fdb7e36-a15f-45b3-8849-0d14f1523045',
    'identification_number': 13010476,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '60ab343a-d3ac-4e7e-8022-39d237f3b7e9',
    'identification_number': 19312458,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '88e61ce0-9012-402a-894b-5797a233ab6c',
    'identification_number': 47280816,
    'item_consumption_1': 3767,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 480.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ada6d922-8b0d-4de7-bdc0-106a77e713d5',
    'identification_number': 69492026,
    'item_consumption_1': 162644,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 60527.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06df8a0c-0127-4c2a-a549-45d5d32e3c38',
    'identification_number': 68385447,
    'item_consumption_1': 25800,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9601.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a01abf83-0bc5-4f39-9e87-ba6ea5232f30',
    'identification_number': 106653733,
    'item_consumption_1': 175,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 74.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f1772f0-653b-4cdb-bc26-910f04b607a1',
    'identification_number': 10125949,
    'item_consumption_1': 4665,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1822.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5aa06407-78ed-46c6-adc1-4595f8ada50d',
    'identification_number': 45340340,
    'item_consumption_1': 11911,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4432.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '549ce3da-3fca-4565-9675-297737a152e9',
    'identification_number': 77890043,
    'item_consumption_1': 35.32,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1027.3,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd4d1c1b3-cd29-4bbc-821a-b2f36820bf48',
    'identification_number': 79701531,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'db113453-f8e7-4d6d-818f-4a144af0e990',
    'identification_number': 96749024,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c375558c-8596-481b-975e-b58c2ef5f25e',
    'identification_number': 105760510,
    'item_consumption_1': 574,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 223.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '673be0f6-15a1-45d8-8980-f9837009325d',
    'identification_number': 55818439,
    'item_consumption_1': 320,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 135.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4198402d-43f9-4a78-b866-53a7c4faa4f6',
    'identification_number': 101885610,
    'item_consumption_1': 5500,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2046.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76a49b4f-3ad3-4c90-baf8-a2ee9416230c',
    'identification_number': 61588555,
    'item_consumption_1': 217.98,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6340.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4423bd8a-683d-4ba3-8940-e09fe94e240c',
    'identification_number': 98019210,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd09f6756-10f2-46f1-bac1-15a7cd95b202',
    'identification_number': 93842295,
    'item_consumption_1': 589,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 249.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce660258-9937-40f6-85b8-eb27c985ef0b',
    'identification_number': 53226429,
    'item_consumption_1': 24865,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3173.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '954ccef6-1317-46e5-959c-c89c879ea70f',
    'identification_number': 101885610,
    'item_consumption_1': 31,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 12.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '26bb6673-f466-4963-b881-e83b8597c403',
    'identification_number': 77890043,
    'item_consumption_1': 15254,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1946.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'baeb8f3e-f935-4d31-8dce-0b2d0a753a34',
    'identification_number': 100918719,
    'item_consumption_1': 1686,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 715.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd71fed07-1936-4ba9-8ff8-ede3b3f4edcf',
    'identification_number': 39530205,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0098858c-8a92-44b1-98d4-12e6c0d2daf5',
    'identification_number': 43750583,
    'item_consumption_1': 13.08,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 311.97,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ecdb671e-76a5-427b-92e1-8db3a7e19d88',
    'identification_number': 109785983,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4429db3e-59b9-4512-9153-7cb9323cdb5f',
    'identification_number': 92141200,
    'item_consumption_1': 6.02,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 143.59,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '69be87ea-a0e3-4901-8f9e-066bf04ae751',
    'identification_number': 100552765,
    'item_consumption_1': 2212,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 936.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20ac023f-bb36-439a-a37f-4849f29193c8',
    'identification_number': 13968297,
    'item_consumption_1': 220,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 93.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80f4bbd7-d5bb-4ba0-96ec-b4fccdf2cee6',
    'identification_number': 108987973,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO (02)',
    'item_total_value_1': 104.65,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a0487905-364b-4eab-a141-67aae2e57f34',
    'identification_number': 43626955,
    'item_consumption_1': 22.14,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 643.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '62d7d0a6-40d5-4169-99c1-6c31e63721ee',
    'identification_number': 30015189,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '10634cc4-aab6-4cbb-9934-5691f9c4d4a9',
    'identification_number': 41700929,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cc42e7d-309b-44c0-8787-66de57fe9d8b',
    'identification_number': 76636291,
    'item_consumption_1': 170.2,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4950.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a297c67b-0b0a-49c0-bf92-6a9bdf058ff3',
    'identification_number': 63897148,
    'item_consumption_1': 3849,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 491.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '915a1154-3a35-48e4-accc-92e9d4c8a18d',
    'identification_number': 44436637,
    'item_consumption_1': 1678,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2538.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f82c1ba-b9bf-4b42-a801-96481cbbbe93',
    'identification_number': 75890380,
    'item_consumption_1': 8789,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1121.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2d72c3a-ba0e-4868-9539-446218bc3219',
    'identification_number': 104704837,
    'item_consumption_1': 736,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 287.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc7ba447-5a7c-41f4-943e-9300996b9297',
    'identification_number': 92828760,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6056.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5d2b904-9574-4816-849d-28b065ea1dc9',
    'identification_number': 28562992,
    'item_consumption_1': 8653,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3380.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4df1d92e-8c32-41d0-9dcf-8c3d02983b6a',
    'identification_number': 94029539,
    'item_consumption_1': 533,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 208.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c66cee6b-e7f2-4e08-a2c9-03c2ddc1500b',
    'identification_number': 68902824,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 58.94,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '48fc199d-1fee-4916-90f8-11c0d2414ea9',
    'identification_number': 32303912,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 379.24,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b65c3735-0bb1-4f12-919f-59cd2534942a',
    'identification_number': 100854982,
    'item_consumption_1': 12974,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1655.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e466799-dcda-4d85-81d4-b1fd581da389',
    'identification_number': 56881444,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-426.18",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'bcb991c8-38c2-471d-8b2f-b3e7acbb67aa',
    'identification_number': 92881548,
    'item_consumption_1': 1270,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 495.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f165de0c-2512-4a2d-8c78-749a500e01a5',
    'identification_number': 19293763,
    'item_consumption_1': 2469,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 965.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0768277-dc76-45fc-9743-369184248894',
    'identification_number': 40636399,
    'item_consumption_1': 27.67,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 804.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1026a77e-5e25-4f2a-984c-64573c8fbefa',
    'identification_number': 43300154,
    'item_consumption_1': 1710,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 724.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8404b011-dccb-4447-ac22-8f4297064151',
    'identification_number': 11680148,
    'item_consumption_1': 33.75,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 981.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '353b4157-1015-48ff-88a4-4d6f49aa87c3',
    'identification_number': 56874081,
    'item_consumption_1': 3.7,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 215.23,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e6d01675-b797-4fe1-bccc-61658414e76e',
    'identification_number': 16570936,
    'item_consumption_1': 4.52,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 107.81,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '779e27b7-0ea2-4906-9e0d-dee4359121cd',
    'identification_number': 92140394,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2edb3a40-599c-4fcd-8fe0-dd2bebf28eaa',
    'identification_number': 98041371,
    'item_consumption_1': 3.98,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 94.94,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3c5cc4c6-9d85-4cc6-b95f-12643d47c3e0',
    'identification_number': 5700493,
    'item_consumption_1': 5488,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2138.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bee506d6-ce8f-4f3e-8093-a0da23767f64',
    'identification_number': 63897148,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ce4fcb8c-002b-4b06-94c6-4f3dd35163bf',
    'identification_number': 7381956,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac26a020-f4d0-41a3-a444-046d7229f18d',
    'identification_number': 95641963,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '94b89664-1702-479c-b1c4-7f492ca876c0',
    'identification_number': 99753154,
    'item_consumption_1': 7.04,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 167.92,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fdbf520b-e102-4dc0-9824-546781fb670c',
    'identification_number': 17902991,
    'item_consumption_1': 932,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 394.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eef764f0-7231-45ae-9d39-e31fc70b1be3',
    'identification_number': 67917283,
    'item_consumption_1': 45789,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5844.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b5a9a80-0e39-41c1-9122-bc72cac9fe5d',
    'identification_number': 71798595,
    'item_consumption_1': 99176,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 36908.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '185e0bb3-7261-4d9d-beaf-d728bfdd7a0f',
    'identification_number': 32074123,
    'item_consumption_1': 45222,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5771.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '953d1f2f-96d7-4a03-9605-25cb7fbfb490',
    'identification_number': 30455790,
    'item_consumption_1': 2649,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1122.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4e0c244-609b-47c0-b165-3e31b0fd5c6f',
    'identification_number': 35559217,
    'item_consumption_1': 15.34,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 365.87,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'bbed1deb-0e08-4f4a-a203-5632e759c6c7',
    'identification_number': 84702893,
    'item_consumption_1': 68.78,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3928.01,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '08cd73e7-3585-400d-b48b-d14e06581b45',
    'identification_number': 107010062,
    'item_consumption_1': 221,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 93.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3518dfa-7b96-4f86-831c-9caea515021f',
    'identification_number': 28316037,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80ad2ed8-303d-4131-b914-844b20082b1e',
    'identification_number': 105663247,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8abf57d4-7c14-4f4f-ab17-d6b538187994',
    'identification_number': 98074008,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e01ee82-6ec5-49f5-8c51-5bee44388741',
    'identification_number': 105849944,
    'item_consumption_1': 18.74,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 545.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '19f677a4-7a90-4ea2-af10-6c4db5509e91',
    'identification_number': 33058474,
    'item_consumption_1': 1357,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 530.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfe1fdf9-76e6-481f-b06e-8078e97eb7da',
    'identification_number': 92324924,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '25a65daf-c176-4f44-9185-03d9cf15715c',
    'identification_number': 46764544,
    'item_consumption_1': 14.007,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 5171.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43b82c7d-979b-4541-af94-e94ddeba53a7',
    'identification_number': 77800699,
    'item_consumption_1': 46.28,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1103.81,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '23de29f0-c386-4f84-9f8f-83f346ea5218',
    'identification_number': 45115745,
    'item_consumption_1': 141889,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 52803.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7bccf7d7-ce97-4b26-9af4-ee496fe02604',
    'identification_number': 83718230,
    'item_consumption_1': 1765,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 750.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fadd835-baea-4d72-91fd-eb9b46b579b5',
    'identification_number': 68081251,
    'item_consumption_1': 41.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1207.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4c36a816-36fb-4b54-a022-83347aad7a2f',
    'identification_number': 104455250,
    'item_consumption_1': "'-1.11",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-386.51",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'e1a13dbb-f5f6-49b1-ad6c-5137911ba3ab',
    'identification_number': 72702141,
    'item_consumption_1': 724,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 283.28,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5c6e05f1-f5a2-4384-b913-5d40d8f42aa1',
    'identification_number': 39208869,
    'item_consumption_1': 10484,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 6123.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a9f0167-98e8-45a9-8f99-af8a363b4bca',
    'identification_number': 38087715,
    'item_consumption_1': 534,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 208.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f14c2e2-822c-4861-81d9-5931542bad7d',
    'identification_number': 91303516,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04da156e-544c-4e54-9688-7b040c6e5a5b',
    'identification_number': 103358960,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c876e2d6-2195-4252-a028-4741788d016a',
    'identification_number': 26749068,
    'item_consumption_1': 2656,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1126.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c9d9f10-ec7c-4e01-843c-a1b10b0320ac',
    'identification_number': 14909413,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b16da6ea-adda-4efd-9db0-1402628825a7',
    'identification_number': 20830807,
    'item_consumption_1': 99827,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 12741.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1af857aa-3a73-4fe1-9927-ad2a7f4dfd55',
    'identification_number': 40504123,
    'item_consumption_1': 3568,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1516.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '068a1725-f18b-4f9e-bfee-cc28e0ccd547',
    'identification_number': 26777258,
    'item_consumption_1': 1324,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 515.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0ee56cb-0ea3-411e-9bb9-a11f370b9c5c',
    'identification_number': 106242040,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 144.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5a79dea5-d34f-491d-829c-576c1300319f',
    'identification_number': 11222565,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '71e0fec8-eeeb-4409-86ab-bed0ef1b58ea',
    'identification_number': 47567244,
    'item_consumption_1': 8807,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3435.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae2f2e4f-20dc-48f0-ad89-c289cea40234',
    'identification_number': 97614858,
    'item_consumption_1': 412,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 240.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4d3d3d1-6807-4bc0-b45a-3df37146f46a',
    'identification_number': 89347722,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d01de32-0ed2-470a-b2f1-1295a4749191',
    'identification_number': 48651940,
    'item_consumption_1': 16616,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2120.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1848ba54-8d0f-44ed-8cfe-41e7d405a455',
    'identification_number': 56582854,
    'item_consumption_1': 16289,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6061.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '117c1ff1-ba9b-404e-9653-c5f18578cfa6',
    'identification_number': 52358259,
    'item_consumption_1': 88.31,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2568.57,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'da4df91e-08b1-4ccf-967d-ee8169ede341',
    'identification_number': 20514310,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6e86246a-c571-46c6-9a45-b0ca9ba5de77',
    'identification_number': 13847678,
    'item_consumption_1': 14.06,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 335.34,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4843afd5-d790-4252-ad79-a71d84e5dc20',
    'identification_number': 15073114,
    'item_consumption_1': 1292,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 548.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86a9426a-0b4b-47f5-b62a-4b76a8e6358a',
    'identification_number': 79211747,
    'item_consumption_1': 182,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 70.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c3f4e64-6d01-4358-aee6-ef6311ce5211',
    'identification_number': 35183543,
    'item_consumption_1': 2897,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1691.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38391d12-7469-4b23-9d49-e9da5a18bca7',
    'identification_number': 94529256,
    'item_consumption_1': 1948,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 761.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fc023e9-5996-40a4-8e66-3d8ddb4d1dc2',
    'identification_number': 5348730,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 57.06,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3b363ada-a772-41eb-89b6-01db520769c6',
    'identification_number': 102594350,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff26384e-5b93-4ee8-8d5d-9fd8c670b580',
    'identification_number': 105799653,
    'item_consumption_1': 1428,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2160.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58ccd6a7-466e-4eb4-b341-3e89fb55cfc7',
    'identification_number': 37708678,
    'item_consumption_1': "'-20.0",
    'item_description_1': 'ENERGIA INJETADA TUSD 11/2023',
    'item_total_value_1': "'-6.94",
    'reference_month': '11/2023',
    'referente': 'Compensação crédito energia',
  },
  {
    'uuid_items': 'dd176569-1b14-4207-ae5e-7a4787fefed6',
    'identification_number': 90790499,
    'item_consumption_1': 28594,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10641.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6cff2dad-c119-45f5-8558-8bc200868552',
    'identification_number': 18616909,
    'item_consumption_1': 225,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 88.03,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '30a00f97-7315-4251-add8-bc0985462dfe',
    'identification_number': 12797707,
    'item_consumption_1': 70,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 27.36,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f9ee15f0-eab2-49ff-b231-99eb88e04e4c',
    'identification_number': 17093457,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '34dfc88c-0f57-4465-a849-8c30bc45be48',
    'identification_number': 44038380,
    'item_consumption_1': 5330,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1983.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c116dca-142b-45ca-aa41-7c5c98d1fc9b',
    'identification_number': 20700806,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9734af8f-0afa-4066-9243-987a7a857d0e',
    'identification_number': 42127564,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 1016.35,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '25ab9cc5-07fb-4800-821f-7b0dc25b23a8',
    'identification_number': 16092970,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e0599863-2071-43d1-86c4-d02e03f21af2',
    'identification_number': 15561054,
    'item_consumption_1': 21.05,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 612.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '36d10799-07ed-467d-af97-012abc310a0d',
    'identification_number': 21716480,
    'item_consumption_1': 4509,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1915.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d37b0b4-383a-4642-af9e-edbb361ee0ca',
    'identification_number': 41640870,
    'item_consumption_1': 135.3,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 3935.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cb2c8d00-318e-484e-81e3-b96b83a9ca40',
    'identification_number': 15575675,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9a3963b1-995a-4866-bc85-40761e0c0d80',
    'identification_number': 107996448,
    'item_consumption_1': 146,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 61.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ce1b83b-9871-48c4-94b2-b8a42e8d2752',
    'identification_number': 15800083,
    'item_consumption_1': 10800,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4571.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfeb2eb9-d78a-4fa9-be65-5dc09a336b3d',
    'identification_number': 42709717,
    'item_consumption_1': 1811,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 231.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2c1c6bd-ab19-4d1c-bee8-a6523f791fcc',
    'identification_number': 70678456,
    'item_consumption_1': 2360,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 919.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6686f901-b8d9-4c71-bdb5-182866ad9806',
    'identification_number': 926575282,
    'item_consumption_1': 2190,
    'item_description_1': 'Consumo em kWh LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 1909.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'afae4deb-ed5d-4810-a3bb-c0b17563557f',
    'identification_number': 96699124,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1289ed43-9a57-46b9-be9f-dc1b4408e1c1',
    'identification_number': 9921273,
    'item_consumption_1': 3643,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2127.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9881e1ca-5ebd-4bd2-92bd-135fdbdfc9c9',
    'identification_number': 21718989,
    'item_consumption_1': 4.53,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 258.69,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b6b82404-1a57-4c32-aeda-94141424e56f',
    'identification_number': 91169410,
    'item_consumption_1': 838,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 326.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38f7c5ec-7f7b-428c-972d-1f14b5dfe1ff',
    'identification_number': 34722262,
    'item_consumption_1': 750,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 318.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7545d04a-c4f9-4d80-bb4e-dac32067c6d2',
    'identification_number': 11078022,
    'item_consumption_1': 168,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 71.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98f91604-c990-42de-9767-0f7db025816f',
    'identification_number': 9756825,
    'item_consumption_1': 6243,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2650.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5eb77ff-5c98-4579-8548-fa64e13f5f7c',
    'identification_number': 56875592,
    'item_consumption_1': 14993,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5579.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9009a43b-e962-48d4-959c-b9d99f518159',
    'identification_number': 107936704,
    'item_consumption_1': 6463,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 824.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12af7279-230b-43d2-900f-4936c3b811aa',
    'identification_number': 28266790,
    'item_consumption_1': 5270,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2239.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87c04144-f4c7-4c70-bd3b-3d33586b34a5',
    'identification_number': 5415870,
    'item_consumption_1': 7.38,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 214.64,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1b41e976-3116-4ca6-822f-84f32ddaac20',
    'identification_number': 32810300,
    'item_consumption_1': 38673,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4936.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a80220e7-aaf8-4b62-83aa-7bba09030046',
    'identification_number': 3032863758,
    'item_consumption_1': 0,
    'item_description_1': 'Parcela Art 113 01 / 02',
    'item_total_value_1': 875.9,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'dd40dd51-e659-4a77-b86f-23d6d1cfa0a3',
    'identification_number': 38749645,
    'item_consumption_1': 85.6,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2489.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5b462e23-1c60-4862-999f-66d2bb0a87b4',
    'identification_number': 56544545,
    'item_consumption_1': 14119,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1802.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3172615c-da81-4ddc-881e-375c35c425c9',
    'identification_number': 21966230,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '652b0426-b06c-4cb8-a387-f44874e65eb9',
    'identification_number': 34617418,
    'item_consumption_1': 0.98,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 28.5,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'c4b388ad-15a4-4a59-a059-a1f5fbab865c',
    'identification_number': 46920617,
    'item_consumption_1': 155.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4521.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '142262ba-12bc-4652-8cf4-bdd21b79fb07',
    'identification_number': 36293067,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '58f423c7-e20b-4671-bf75-82db76f373be',
    'identification_number': 32519036,
    'item_consumption_1': 27580,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10263.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffaa7ece-1aef-424a-a59e-7b80e4f26309',
    'identification_number': 69972079,
    'item_consumption_1': 9552,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4054.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b6eacb8-2ab7-4949-be16-c39b521e7224',
    'identification_number': 102279683,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'af9bf092-ec72-48b6-9e5c-b3234f30a683',
    'identification_number': 48999881,
    'item_consumption_1': 14,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 5.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bb83e3d5-aef6-4eab-80b7-832a80693883',
    'identification_number': 84930756,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e19630cf-b629-4961-9e33-918f99b8e8be',
    'identification_number': 88601900,
    'item_consumption_1': 857,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 363.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd9fcbe9-2cf1-476c-8225-8732b15602c1',
    'identification_number': 100797075,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf2e44fc-ad1d-42bc-8d9c-1939d8ff857f',
    'identification_number': 109643372,
    'item_consumption_1': 36.11,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1050.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8747d6aa-3678-4303-8653-c5afbc222f30',
    'identification_number': 6270760,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6fac3ad0-565c-42c6-b16f-7c5e0340bd82',
    'identification_number': 45768279,
    'item_consumption_1': 100,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 37.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6b9d463-6789-46cf-9f29-8f5256b8a3b1',
    'identification_number': 43723985,
    'item_consumption_1': 2730,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1160.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3297e4a-ab7b-4c64-a4a3-96ca0d80cfc3',
    'identification_number': 3030777579,
    'item_consumption_1': 8125,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 2423.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73e1df42-450e-45ce-8b7e-84573304df0a',
    'identification_number': 103886362,
    'item_consumption_1': 1168,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 495.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77c1bb8c-6532-4b96-a296-b32d253a38ee',
    'identification_number': 67454879,
    'item_consumption_1': 1140,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 484.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba8e4dbc-628b-47fc-918b-6ccd9b13dd45',
    'identification_number': 91350816,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffaf729d-2168-479d-97ed-d4715ef6135c',
    'identification_number': 30345359,
    'item_consumption_1': 87210,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 11131.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d2fb6e9-b3d4-43c9-ab52-451bdeb3f409',
    'identification_number': 98688987,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf29c69f-8b2e-47b7-a30a-ac6575da1781',
    'identification_number': 10521119,
    'item_consumption_1': 2500,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1058.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4bc01f4-ac4d-4757-8e41-4c106dea636d',
    'identification_number': 61283851,
    'item_consumption_1': 3361,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1310.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8768338-cde4-4af0-afa5-54c3cdeca3d8',
    'identification_number': 100188060,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73457602-4a0b-4522-8ff5-cc28070b1813',
    'identification_number': 17187923,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '795284ac-7fdb-4e58-bd1c-c7773986bd7d',
    'identification_number': 102103348,
    'item_consumption_1': 693,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 270.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '392010bd-c700-42cf-a95e-5c354e911aa8',
    'identification_number': 38893614,
    'item_consumption_1': 3103,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1812.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c86c1768-d445-4ae8-9da7-e049bc1eb28b',
    'identification_number': 22642803,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '52eace8e-82a8-4347-9776-2af1482b45c5',
    'identification_number': 27895602,
    'item_consumption_1': 164,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 64.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a933e796-74bf-48d5-9869-3631825a215b',
    'identification_number': 98024809,
    'item_consumption_1': 128,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 49.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b4154e1-48f9-4ef0-a29b-bfca9a53479c',
    'identification_number': 40720829,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72d2bf76-2dc8-4f15-aeb7-6fe364d2255c',
    'identification_number': 66832330,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f054f1b9-610a-40d1-8890-42970ae35160',
    'identification_number': 9788166,
    'item_consumption_1': 0.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 22.91,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'da3fffff-d36e-479e-999a-a613fefa1551',
    'identification_number': 106681664,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '46e6ced7-c84a-414b-a741-b8d0c8eba55e',
    'identification_number': 16876709,
    'item_consumption_1': 1690,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 987.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66c72bdb-b143-420b-b3f5-3c07e81ef9e0',
    'identification_number': 79645119,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '792e1db1-8dfd-4d0a-8a30-27201cae5dc2',
    'identification_number': 45446212,
    'item_consumption_1': 0,
    'item_description_1': 'COMP VIOL META CONTINUIDADE 09/202',
    'item_total_value_1': "'-11.7",
    'reference_month': '11/2023',
    'referente': 'Indicador de Qualidade de Energia',
  },
  {
    'uuid_items': '0056e428-1474-4e79-bd38-3979ced04891',
    'identification_number': 42433320,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '523bf685-fe55-402e-a948-8041f4cc3d99',
    'identification_number': 111585252,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3c9e7f0b-1733-4976-bc38-c097e75492e3',
    'identification_number': 91654912,
    'item_consumption_1': 9633,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3764.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fd83c56-7995-4e6c-92a7-64b6845f4227',
    'identification_number': 33688907,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0f7c5f05-f5c6-4968-9168-73b7575fd492',
    'identification_number': 26677210,
    'item_consumption_1': 1.09,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 26.02,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2e624851-9ae7-4606-9a1f-aece05d3243c',
    'identification_number': 5558735,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 323.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '879e7d14-a84d-463b-9021-54085ca57795',
    'identification_number': 56699077,
    'item_consumption_1': 5157,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 658.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf92b004-97a8-4c95-8007-96f0d5e18074',
    'identification_number': 94633070,
    'item_consumption_1': 7779,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3306.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e632400-eef5-4d37-a868-219a5bf28534',
    'identification_number': 111372879,
    'item_consumption_1': 637,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 270.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f77bae9-ea94-40ca-9696-e6838fa0fea0',
    'identification_number': 43152767,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2a445c1-9591-4a24-843f-506c8b0b8727',
    'identification_number': 30226007,
    'item_consumption_1': 1,
    'item_description_1': 'Iluminação Pública',
    'item_total_value_1': 55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b2b29fc0-7fb9-484c-a2a0-7e9c853aaccf',
    'identification_number': 99786184,
    'item_consumption_1': 3393,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1327.58,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '38bd9780-dc4d-4f96-af1d-91fe2550df21',
    'identification_number': 92671810,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43bca899-b329-4881-b91f-8133db26195e',
    'identification_number': 72792914,
    'item_consumption_1': 410,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 160.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56b07ef7-21a5-4dec-bd62-f72a7c43f86b',
    'identification_number': 44774494,
    'item_consumption_1': 180326,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 67107.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd01f8611-c6db-4761-8e10-3dc2eb395b1a',
    'identification_number': 93680180,
    'item_consumption_1': 10936,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4637.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3c07a4a-44ed-4f1d-bd19-b4562f64947a',
    'identification_number': 76778606,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 109.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '91bd5707-4cdd-48c8-9823-13024b4a8bb5',
    'identification_number': 86283154,
    'item_consumption_1': 26.94,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 642.55,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '88d837c9-b890-4777-af2c-4865680f6c77',
    'identification_number': 29966060,
    'item_consumption_1': 27.94,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 812.64,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ccebdcf1-f19e-4f4e-8c24-775575f9a055',
    'identification_number': 62362801,
    'item_consumption_1': 69.96,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3995.4,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'd5dd0bfe-db37-4676-a2de-b1074f05e3b3',
    'identification_number': 101912331,
    'item_consumption_1': 36.26,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1054.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '42954145-ab24-401a-a6eb-8eca8271f31d',
    'identification_number': 102593540,
    'item_consumption_1': 2531,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1075.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4792c977-07a3-41d2-bfd9-879a7e18f278',
    'identification_number': 107540860,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14d70f9c-5ef3-4faa-8da3-911c930fd1cf',
    'identification_number': 10813993,
    'item_consumption_1': 8732,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3704.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3145866e-1e85-4e35-9cd5-87a6beb4c235',
    'identification_number': 49821210,
    'item_consumption_1': 4981,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2116.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '825b3007-f86f-46a9-80d3-2c812896bbba',
    'identification_number': 91393973,
    'item_consumption_1': 23877,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3047.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e02956d9-2fb0-4f91-8461-505db63bcdd1',
    'identification_number': 72702141,
    'item_consumption_1': 125,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 48.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'fe2c8d4c-7bc4-49b3-82d8-679689f8d724',
    'identification_number': 96256524,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '815ca69e-83f5-454f-bd76-496ec947774c',
    'identification_number': 17435048,
    'item_consumption_1': 110.7,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3219.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6984b9cd-90b5-4955-acfd-56944c7e3e27',
    'identification_number': 13572008,
    'item_consumption_1': 115,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 87.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62892ab6-be22-411a-93b5-d410d287964f',
    'identification_number': 22115226,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd69b969-4a87-47f8-a7be-4afe55459c0c',
    'identification_number': 16307836,
    'item_consumption_1': 2802,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1636.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd4ff43a-e54b-42c8-8981-fcc0a6fe590b',
    'identification_number': 104408405,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 4.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b0db1645-79fe-442e-8f08-a861628f2840',
    'identification_number': 69969620,
    'item_consumption_1': 410,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 173.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad0d6a96-6e0e-44dd-9010-14ffc59c2045',
    'identification_number': 29957141,
    'item_consumption_1': 3483,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1361.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c1dd30e-84b7-43a5-823b-b196b82372c1',
    'identification_number': 36033111,
    'item_consumption_1': 252502,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 93967.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd821339b-0ebb-4523-abd3-5587728f2090',
    'identification_number': 70755507,
    'item_consumption_1': 12341,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4825.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdaeb322-7180-4ba6-9275-b9682e80d694',
    'identification_number': 923584014,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b57b2027-12d7-4d44-9604-8746e971f1a3',
    'identification_number': 19450745,
    'item_consumption_1': 27080,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 15815.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51c0f580-fc12-4122-bcbb-303a25c0b0bf',
    'identification_number': 17074991,
    'item_consumption_1': 3950,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1543.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5af74346-f8f1-42c3-aaa5-73a8abb082ef',
    'identification_number': 53747755,
    'item_consumption_1': 3242,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1893.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae203396-c294-495b-b803-35f37f4550c4',
    'identification_number': 34659307,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c9f90cc8-96fb-43cd-9799-84a1512138bd',
    'identification_number': 32627572,
    'item_consumption_1': 55.44,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 6332.34,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e26e3d12-a8bc-465d-9b74-05350f81c852',
    'identification_number': 70574502,
    'item_consumption_1': 3359,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1961.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78e4899b-887f-4a04-9ee6-0324cf768155',
    'identification_number': 106242040,
    'item_consumption_1': 269,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 104.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be122dab-a30c-4b9d-b753-78ed3348ad0c',
    'identification_number': 85258105,
    'item_consumption_1': 3049,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1295.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb32b177-165f-46f8-89dc-056796a70851',
    'identification_number': 91611377,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cb75ce19-3874-40be-b80e-d71db2932df9',
    'identification_number': 83644202,
    'item_consumption_1': 2965,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1158.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e601b8af-6595-47ce-a90f-6ced7a613149',
    'identification_number': 103706992,
    'item_consumption_1': 7943,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4639,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b5b9cec-f970-4371-bc1e-e201caac33ed',
    'identification_number': 97575941,
    'item_consumption_1': 1288,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1948.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60218cd5-9453-4954-8376-9242aa178d0c',
    'identification_number': 34560980,
    'item_consumption_1': 10306,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4364.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24603ff1-bdb4-4b14-a216-57e7402acd0d',
    'identification_number': 27718077,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3a3b8bbd-5395-47b0-9108-bc2dfc63ecc0',
    'identification_number': 41314352,
    'item_consumption_1': "'-1.04",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-362.28",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'fa7d8cfd-b0d0-42e8-b2d2-39b740e4fb5a',
    'identification_number': 63137399,
    'item_consumption_1': 7721,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2873.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6f34c56-b44b-470d-80d8-d77ca93faa59',
    'identification_number': 16677412,
    'item_consumption_1': 23323,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8679.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af0a74c9-368f-419d-baf5-d5553effdcad',
    'identification_number': 29863589,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a20afd1b-6afd-484f-9c87-edb849e9c54f',
    'identification_number': 93093012,
    'item_consumption_1': 1440,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 611.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49999548-8e5a-4b7e-99c0-08903f9a18fb',
    'identification_number': 9889566,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 50.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '639551d3-8cfd-4fcf-8df7-be765e56622f',
    'identification_number': 13164546,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '770906bb-91a5-41ac-b909-2f75405d9a43',
    'identification_number': 92672949,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3121a42-6568-4131-a06c-20d805c490ae',
    'identification_number': 111282756,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc1f6d89-32ea-4411-8d18-9da60142fd44',
    'identification_number': 7284012,
    'item_consumption_1': 620,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 241.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62f3e8d7-4421-4700-b82f-8521b593f16e',
    'identification_number': 91359511,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6542c081-c1af-4831-ba39-17be00c42fb6',
    'identification_number': 102983011,
    'item_consumption_1': 1946,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 824.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b1cb175-89eb-46c7-9ca0-add69b4955aa',
    'identification_number': 81037600,
    'item_consumption_1': 4147,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2421.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dae88f3a-712d-4437-8d86-020362d86d2f',
    'identification_number': 38503328,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78b4b0d1-e60d-48d7-8d42-78660198f418',
    'identification_number': 22902694,
    'item_consumption_1': 32,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 930.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5f0546eb-5feb-4548-b1a6-107fb160b2e8',
    'identification_number': 12399752,
    'item_consumption_1': 8.21,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 195.83,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f4bdbd1e-4e6c-490f-9866-087172720795',
    'identification_number': 101885610,
    'item_consumption_1': 28.48,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 828.36,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1502b214-6882-44c4-9362-9962d786d5c8',
    'identification_number': 108989615,
    'item_consumption_1': 162,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 63.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '166441a0-277e-4855-9556-0c0df4fb65ef',
    'identification_number': 98463888,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37cf0fd9-efcf-44b2-bab0-e0ffff640fb3',
    'identification_number': 84238470,
    'item_consumption_1': 883,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 344.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '745a8f33-47ea-4be2-8e91-b366d79ee4a0',
    'identification_number': 48193399,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fd857836-310d-4146-8465-64d122bbd3af',
    'identification_number': 98193210,
    'item_consumption_1': 39561,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14722.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c99c21d9-52df-4a7f-817f-5f5a29d454f0',
    'identification_number': 63964430,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 112.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dcb609b3-7710-4817-b29a-1c9bd49b36fe',
    'identification_number': 40504077,
    'item_consumption_1': 4294,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6496.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b87bed7-ab3b-4d2d-9116-e5762f8f4424',
    'identification_number': 32627572,
    'item_consumption_1': 286806,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 36606.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad6a83d1-cf01-488d-8dbd-eba25c39e476',
    'identification_number': 32595921,
    'item_consumption_1': 23114,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2950.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44a30b3c-a01d-45b5-954e-058d515e04c7',
    'identification_number': 99042223,
    'item_consumption_1': 2964,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1731.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1c0b1a10-5c7b-4817-bf2e-7ebf6a55dca3',
    'identification_number': 15818284,
    'item_consumption_1': 1689,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 716.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e8a4eda-affb-4376-b3b7-d8438afeb823',
    'identification_number': 72702141,
    'item_consumption_1': 1176360,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 150146.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1695174b-bdee-4b20-86a1-f0f235f840c6',
    'identification_number': 105978000,
    'item_consumption_1': 8320,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3523.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f7f868c-007b-4604-b8c9-bd04b31bab14',
    'identification_number': 104186020,
    'item_consumption_1': 5.06,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 120.69,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '954dcd72-8dc3-4b56-b28e-697d429e26a8',
    'identification_number': 98359770,
    'item_consumption_1': 11593,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4530.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b69a65e-c60a-4663-a15a-6460e270c2f1',
    'identification_number': 69861412,
    'item_consumption_1': 25522,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 10810.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54076793-bcba-4515-8862-c0f1b711af91',
    'identification_number': 68007493,
    'item_consumption_1': 25.85,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 616.55,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c18f871a-15c0-420b-823c-b5afca3b21de',
    'identification_number': 25617737,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61f7e483-f04b-4dc4-a170-d9054112d46f',
    'identification_number': 32715811,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'de355012-6b77-4155-8f5a-77b82a065a79',
    'identification_number': 56507844,
    'item_consumption_1': 33.01,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1885.2,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b95d84ce-3f0c-45dd-b449-e3693d8b8349',
    'identification_number': 102481172,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9e7ddb5f-5356-43b0-a445-5eff184196f9',
    'identification_number': 14562545,
    'item_consumption_1': 20.91,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 608.17,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0f432d13-719c-4c8a-84a1-efb4e48d8b32',
    'identification_number': 92334067,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ae3c3d6d-360a-48b9-ae73-871a3bea22cc',
    'identification_number': 12804401,
    'item_consumption_1': "'-2.333",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 03/2021',
    'item_total_value_1': "'-746.62",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '776f36ce-d7e2-43d5-98e5-f8e8ab01fa0c',
    'identification_number': 44916264,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd82e0ad9-1782-495b-9536-5b0746515334',
    'identification_number': 98271903,
    'item_consumption_1': 59,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 23.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f0640bf-deb4-4fba-82d3-86af5eae9482',
    'identification_number': 71277552,
    'item_consumption_1': 950,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1437.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0db3b5df-fee7-448e-a6e8-3aae67264743',
    'identification_number': 104704837,
    'item_consumption_1': 736,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 312.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd38cc4d2-f741-4baf-a099-792e2904e46c',
    'identification_number': 109402049,
    'item_consumption_1': 636,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 269.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37758786-3d57-4ca7-bc53-b5dc40f137f6',
    'identification_number': 27716210,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f3c139f-4226-40ae-8bfe-10b07e95ad3b',
    'identification_number': 92671810,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1390e0e-9072-42cb-9a79-6edaee615bf7',
    'identification_number': 92638775,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4690.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96359a06-e313-40ea-aa45-62c76e34c71a',
    'identification_number': 101912331,
    'item_consumption_1': 631,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 368.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6860e1c8-c9c3-4d74-b516-8ea41a3a7925',
    'identification_number': 102622639,
    'item_consumption_1': "'-14.187",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 08/2023',
    'item_total_value_1': "'-4927.8",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'ea322a4c-4ab7-4070-9369-5bee3176fce7',
    'identification_number': 96771410,
    'item_consumption_1': 673,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 285.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f0f4643-ae39-430e-a858-45082c185884',
    'identification_number': 15751740,
    'item_consumption_1': 7.27,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 173.41,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '33f54ac2-b39c-41c5-bee4-f7c76c4c7200',
    'identification_number': 6210309,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7b0b6415-9c14-4a81-9891-6f97d33dadb6',
    'identification_number': 36648329,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3696ca38-bcfc-4a26-b8b4-6dea12ac829c',
    'identification_number': 33375453,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce9e26bd-012d-43e6-9e93-2937155275af',
    'identification_number': 108637840,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '24473401-1ebb-4ab4-833b-ba5253d24a1f',
    'identification_number': 82724873,
    'item_consumption_1': 1663,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 212.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b27f5e46-87eb-4805-8cd4-c6c46af83716',
    'identification_number': 70073996,
    'item_consumption_1': 8630,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3364.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c81c30f0-33ae-4df5-a877-2c8c7a992a50',
    'identification_number': 12174726,
    'item_consumption_1': 10632,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4155.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2418bed-fff2-48e1-a1b8-06d26e6bdd98',
    'identification_number': 107469553,
    'item_consumption_1': 424,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 165.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5be21c20-e2e9-49c6-ba26-98fa0efb3d38',
    'identification_number': 109635914,
    'item_consumption_1': 18439,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2353.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbe74a7b-11ce-4cc4-9dbd-4817b5bc665b',
    'identification_number': 19559658,
    'item_consumption_1': 1821,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 711.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9b3461f-739e-44f7-acab-310f722be994',
    'identification_number': 12804401,
    'item_consumption_1': "'-67.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 04/2021',
    'item_total_value_1': "'-21.43",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '27bb63c6-15d3-453d-a5ac-1ee28c096102',
    'identification_number': 41418417,
    'item_consumption_1': 1965,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1147.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b6f0f7c-57d4-463a-8eb0-6820e99b8aa5',
    'identification_number': 49096397,
    'item_consumption_1': 4703,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1750.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e02c3bd4-399b-4e97-b84a-be53c9c48c51',
    'identification_number': 104638621,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8ac765e5-e176-406c-93a4-a9d147c8a08a',
    'identification_number': 41279263,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEV. JUROS AJUSTE FATURAMENTO',
    'item_total_value_1': "'-3.27",
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '8230c6e2-cba2-4d5c-ac61-92af772ec720',
    'identification_number': 91801435,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '06744d73-09ea-4ebd-8ad9-aadf92223ec9',
    'identification_number': 96382279,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a10c8c5-2c7d-4a27-a9d5-88ca0fbc1780',
    'identification_number': 94693714,
    'item_consumption_1': 3109,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1317.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7d18cdc-7c85-4dc1-b657-1e2355a1141c',
    'identification_number': 45907137,
    'item_consumption_1': 719,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1087.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '499218ec-0e49-483d-a2b5-3ee9a1f4e982',
    'identification_number': 9897321,
    'item_consumption_1': 2756,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1170.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '569a6c8e-eec1-41e1-a1ec-0bd28df49021',
    'identification_number': 86398652,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 19.56,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e8bcf666-8e1d-4b9a-bfaf-71611e23a202',
    'identification_number': 12697451,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a567153d-bdc8-47e8-96d6-01d0d2cbde3c',
    'identification_number': 14984369,
    'item_consumption_1': 5720,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2229.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fb2da3f-9987-4630-ba8d-b4b0cd320007',
    'identification_number': 95632867,
    'item_consumption_1': 9975,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1273.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f09e8f0c-4426-4f99-b33e-05e08c9f753a',
    'identification_number': 3030777242,
    'item_consumption_1': 0.0306,
    'item_description_1': 'Consumo Reativo Exo Ponta NOV/23',
    'item_total_value_1': 0.01,
    'reference_month': '11/2023',
    'referente': 'Energia Reativa Excedente Ponta',
  },
  {
    'uuid_items': '94dfc108-8c5d-4ed3-a815-f85f278b4c4d',
    'identification_number': 44038380,
    'item_consumption_1': 19.78,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 471.77,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7546de93-8e68-4a1a-b565-3932965b6281',
    'identification_number': 96580976,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bc98855-1010-4faf-92b0-73b48742d4e5',
    'identification_number': 95563610,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8b074873-7580-4848-8880-c857edbc854a',
    'identification_number': 56877250,
    'item_consumption_1': 320,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 125.2,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2f6ff9df-8253-47d6-afcf-0cb507bacc81',
    'identification_number': 78368286,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0c6987fb-5683-49b6-aa54-44a939634b26',
    'identification_number': 62391453,
    'item_consumption_1': 50.84,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1478.72,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8b004adc-9679-47b4-93a9-cace626b4fd1',
    'identification_number': 34993550,
    'item_consumption_1': 1315,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 513.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '388ba78c-381b-4623-9361-1dd77f47b0af',
    'identification_number': 10959653,
    'item_consumption_1': 4670,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1979.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af501a9f-69d9-4a93-9d60-afa41cf563fe',
    'identification_number': 73695360,
    'item_consumption_1': 580,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 246.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2431f38b-bb6e-4ebe-af3c-9fec9636c8d7',
    'identification_number': 16699696,
    'item_consumption_1': 39.45,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1147.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7048f4bc-5d3b-4e13-9fe8-b1bb157924e2',
    'identification_number': 106318543,
    'item_consumption_1': 562,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 218.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1970737a-1617-4296-b099-6fbe211e706c',
    'identification_number': 101941714,
    'item_consumption_1': 1029,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 600.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb2dd3dd-6f0b-40c8-b51a-5dc49f56f9ab',
    'identification_number': 93950993,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3acf0ce0-568f-4d56-af45-b9d7914ae5db',
    'identification_number': 15183319,
    'item_consumption_1': 734,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 311.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2101dc99-898d-40ef-9adc-72da7fae506e',
    'identification_number': 45603391,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ae388cb5-9a8e-4cf6-8dc7-b1ba20197df4',
    'identification_number': 96506954,
    'item_consumption_1': 9509,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3719.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05c78665-b9d6-4a5f-8fcb-fda525b0620a',
    'identification_number': 28860136,
    'item_consumption_1': 7321,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2724.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21de7616-4ce3-4a63-8d70-4b4b3ffad80c',
    'identification_number': 42390001,
    'item_consumption_1': 80,
    'item_description_1': 'UFER Ponta (Reativo)',
    'item_total_value_1': 29.78,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': '499cee69-67ae-4196-b57b-3ee42f07ae75',
    'identification_number': 59059729,
    'item_consumption_1': 31031,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 3960.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0bde35e2-9ae6-4836-a656-19e40f6d1c2d',
    'identification_number': 53573749,
    'item_consumption_1': 134,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 202.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2775d448-15b6-4d43-883f-82726a91c810',
    'identification_number': 108380513,
    'item_consumption_1': 23.03,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 549.28,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c55e0886-212e-4876-bd9c-05c5906d86c2',
    'identification_number': 9756825,
    'item_consumption_1': 6243,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2438.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2cffd4b0-1dab-45e9-b379-5466355b7882',
    'identification_number': 102560935,
    'item_consumption_1': 386,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 583.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'defcb221-c47c-4609-8f17-27e26fcc8e2c',
    'identification_number': 110992393,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '42cbc9c0-7359-40a0-820e-81fd28d93f35',
    'identification_number': 22963677,
    'item_consumption_1': 394,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 9397.09,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'caed9f94-152e-43dc-a0b9-6d4059782c22',
    'identification_number': 43484506,
    'item_consumption_1': 21.89,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 636.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '87bfce27-281a-432c-bf02-568eb9d59ac7',
    'identification_number': 40856089,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '148614ba-fce4-409f-b03a-233dc4662ed3',
    'identification_number': 19453302,
    'item_consumption_1': 411,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 160.8,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '370ff597-c955-4d25-bf55-49769c61e631',
    'identification_number': 104678518,
    'item_consumption_1': 174,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 67.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b64a762-559c-4a5c-bb8d-0bd638d1aea2',
    'identification_number': 99102935,
    'item_consumption_1': 2404,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 940.62,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '779908a2-158a-4135-8e57-c206fd686d75',
    'identification_number': 92590292,
    'item_consumption_1': 1921,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 814.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c74e0a4b-df00-44d7-9624-b8dc79c1ee35',
    'identification_number': 94120676,
    'item_consumption_1': 41.18,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1197.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '861f479f-1cb7-4287-b46a-c38265924ce1',
    'identification_number': 55072755,
    'item_consumption_1': 171,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 72.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dffacbe7-c0d3-4176-a75b-e7da289621d8',
    'identification_number': 111114497,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af3558d7-6616-479f-9189-06c7ec8fe80a',
    'identification_number': 16307836,
    'item_consumption_1': 5.03,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 235.58,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4a76019f-d06c-4ae4-bb48-89ba03d8c0d8',
    'identification_number': 9841717,
    'item_consumption_1': 7139,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2788.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f11c0f75-25b8-406f-9cf5-300abd1f0523',
    'identification_number': 16105680,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b3c95bcb-8727-4589-86fb-832a56d7d087',
    'identification_number': 97988804,
    'item_consumption_1': 772,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 302.06,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b2b171bf-6532-404e-828d-828dda8dc4ce',
    'identification_number': 38749645,
    'item_consumption_1': 4649,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 593.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cc1f258-20fe-4cbe-b3fc-d806a957f1ee',
    'identification_number': 12384933,
    'item_consumption_1': 2118,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 270.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42c29a30-228d-410f-a43e-d2b98b4656ac',
    'identification_number': 19873727,
    'item_consumption_1': 34975,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4464.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '402ebaae-c448-400e-8344-0f87dcaf1948',
    'identification_number': 93134495,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7220bbd4-c0e3-4eaa-8e69-3191bcb00ded',
    'identification_number': 32234058,
    'item_consumption_1': 78.57,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2285.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1293afca-f8b9-4f72-b220-21f2c505f494',
    'identification_number': 929824059,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22fda256-6b49-441b-8857-d16b1e68c3a8',
    'identification_number': 17808677,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c00e6ae4-b3bd-465e-ad34-181ffe16dde0',
    'identification_number': 1205102,
    'item_consumption_1': 3.6,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 85.87,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '82973529-8201-44a2-ab26-aaa4a25a7d1d',
    'identification_number': 22358609,
    'item_consumption_1': 39660,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5062.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '048767bb-e677-4080-b518-767f1d6695a1',
    'identification_number': 32500459,
    'item_consumption_1': 14.85,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 354.19,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0f96f831-403d-4fc1-a9c8-f90bf8d12bba',
    'identification_number': 57029911,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4db3476f-8d0c-4cf3-b73a-00159565786e',
    'identification_number': 948743512,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94942091-32ba-474f-b2ac-cdfccbf11f65',
    'identification_number': 99288621,
    'item_consumption_1': 5301,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2067.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b546e89f-a026-4281-9a35-f0b80714e037',
    'identification_number': 82139776,
    'item_consumption_1': 3087,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1312.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8b5733e-63c6-43d8-b49f-985d6a1e778e',
    'identification_number': 42710529,
    'item_consumption_1': 35.37,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1028.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4c26d1e7-3297-4f35-8254-ebb2aafce966',
    'identification_number': 106508938,
    'item_consumption_1': 1994,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 779.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cdf13728-d764-4323-a426-a8b7e218ee56',
    'identification_number': 46425470,
    'item_consumption_1': 3481,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1356.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f2444ac-f191-4589-8813-2cb8670b4b43',
    'identification_number': 108669009,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 106.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '19dc91c9-bcfd-4817-b1c1-c4e7f4dde5e6',
    'identification_number': 97970034,
    'item_consumption_1': 1949,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2948.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77bcc964-3d36-4d19-ab15-e267a940a5a1',
    'identification_number': 92529909,
    'item_consumption_1': 1473,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 625.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f16111e1-45f9-427b-8a3d-bc9a90c76f94',
    'identification_number': 77411609,
    'item_consumption_1': 428.54,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 24473.9,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1097595a-e08e-4c77-ada4-e13ddda2e683',
    'identification_number': 105807010,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 210.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f704a6a2-a153-4224-b0f5-e23c605571b8',
    'identification_number': 98041371,
    'item_consumption_1': 1411,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2134.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32a8b2e1-201e-453c-b0a6-e3b864848e9e',
    'identification_number': 94516227,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b2c3bf9b-5538-4f15-93f1-2862bfdf79e1',
    'identification_number': 33324964,
    'item_consumption_1': 5765,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2251.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3be5f340-65e2-44d0-801d-1d3e69fe3545',
    'identification_number': 108466051,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 35.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c668c35c-28c1-42af-8809-b6dbbfbf0c1e',
    'identification_number': 13164546,
    'item_consumption_1': 2070,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 807.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44885f0c-4621-446a-826a-5af8cfb8b03c',
    'identification_number': 41381823,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '878f858e-b837-4db9-8f18-fb8b667ae054',
    'identification_number': 15091520,
    'item_consumption_1': 374,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 146.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '06e68ae4-3de9-4141-9bdc-95c0bff50b90',
    'identification_number': 34617418,
    'item_consumption_1': 13162,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4898.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eedfab55-a196-4539-8be4-22ecc1b09289',
    'identification_number': 60720514,
    'item_consumption_1': 16.66,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 780.21,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c56a6e48-c115-4e37-b0bb-f0785cb0d321',
    'identification_number': 21025070,
    'item_consumption_1': 238,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 92.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46981975-5fd2-4781-b249-f0dbe1255a4a',
    'identification_number': 90469259,
    'item_consumption_1': 93030,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 11874,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '918a81aa-964e-4d32-ba49-fce6abefdb62',
    'identification_number': 19486235,
    'item_consumption_1': 4475,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1901.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7623c57-d9ec-4b70-a1d6-2398d6885ef2',
    'identification_number': 69442886,
    'item_consumption_1': 324,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 126.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29780d1e-c44a-49b8-8e95-4c32b2082df2',
    'identification_number': 32348533,
    'item_consumption_1': 784,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 457.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '480ca903-bab1-40bf-b9b6-f72be5361827',
    'identification_number': 29589134,
    'item_consumption_1': 7819,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3049.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00b1198f-426a-479a-b781-8c9c5bcbacab',
    'identification_number': 109375580,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 16.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '467dd2b0-eba1-483d-ae3c-78adc766735a',
    'identification_number': 17073057,
    'item_consumption_1': 21.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 614.86,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a57e859d-e606-40d2-b626-a85a4bc06297',
    'identification_number': 17073057,
    'item_consumption_1': 11547,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1473.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '611ae69d-5279-4f02-a697-cd727c417922',
    'identification_number': 12718750,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5f8964d9-3f29-4739-b4a4-1540327d662a',
    'identification_number': 111081076,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '66ed81a5-7e18-4dc7-bbe0-eb454a5d4de0',
    'identification_number': 13847678,
    'item_consumption_1': 3693,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1374.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e036048-dc8d-402c-be06-ae5c8b018617',
    'identification_number': 19451610,
    'item_consumption_1': 1747,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 683.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6b7ecadc-2f54-430c-b519-847618af980e',
    'identification_number': 14978229,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '891367cb-24d9-4990-bf70-784725099f37',
    'identification_number': 105083097,
    'item_consumption_1': 542,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 211.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd64ecd87-3e15-4651-9ee6-9cdf5c1f3bff',
    'identification_number': 109161017,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.66,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b6a1606-090e-4cd9-b2b1-5dcdf03d3ff9',
    'identification_number': 23136863,
    'item_consumption_1': 1075,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 456.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4512a9a-f6eb-44ae-a52d-e51bf2aa5d62',
    'identification_number': 75565730,
    'item_consumption_1': 145,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 56.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e3565c09-f494-45ff-8d27-296295bd3b60',
    'identification_number': 94428506,
    'item_consumption_1': 3002,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1174.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aaa786b1-cc77-4aa9-b58b-83e380d39b1c',
    'identification_number': 98165895,
    'item_consumption_1': 888,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 376.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb1940b5-3bfa-41e0-9f4c-dcd5fe87011c',
    'identification_number': 108106128,
    'item_consumption_1': 74.09,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2154.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a89eb16c-165d-49fb-8af3-e97b17abe0a9',
    'identification_number': 48482897,
    'item_consumption_1': 4.35,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 103.78,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '70195448-fdea-44a5-8ae3-0accc82e0f57',
    'identification_number': 32046634,
    'item_consumption_1': 21844,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2788.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5be9d0d9-e93b-452d-99bc-2e089a533bc5',
    'identification_number': 42804663,
    'item_consumption_1': 1141,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 483.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e068a260-984a-44a9-8b03-95d6e4c5bd46',
    'identification_number': 56526580,
    'item_consumption_1': 272,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 106.42,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd84047ef-9986-4b27-b47a-78c483c962d0',
    'identification_number': 19450672,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '205b7ad3-b400-4249-87aa-522f678cf834',
    'identification_number': 19453779,
    'item_consumption_1': 147,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 57.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd368b52f-d537-4f98-9b71-04d20d850a37',
    'identification_number': 19295413,
    'item_consumption_1': 9186,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3589.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab593a5e-32d5-4e2a-9f95-45c2ff679b9f',
    'identification_number': 10790195,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd011313d-7a9d-42b7-84ea-1c92bd38ba6f',
    'identification_number': 43061842,
    'item_consumption_1': 42.73,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1242.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9f518fa2-c880-46c5-91d6-5e8598bc2db8',
    'identification_number': 37507940,
    'item_consumption_1': 877,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 512.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d7b263e-ded9-4fdf-b1ba-429895988c52',
    'identification_number': 98802739,
    'item_consumption_1': 15,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 5.86,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '28d31956-de55-4544-8b44-d21bfa451bd9',
    'identification_number': 100822959,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd657cb68-2b5a-4ffd-8a2a-dbe39bb6b6dd',
    'identification_number': 9211225,
    'item_consumption_1': 1868,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 728.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac6e1e7f-6434-44d5-ae6e-67d5c0e7cf00',
    'identification_number': 24083542,
    'item_consumption_1': 5042,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7627.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e676b96-71df-45cb-bc9a-0310b071741d',
    'identification_number': 92912290,
    'item_consumption_1': 8378,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 12674.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46ede7dc-11ff-4f14-b9ac-3de94df040b9',
    'identification_number': 97356530,
    'item_consumption_1': 201,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 78.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84bbc797-3ebd-4f50-894a-a0beda6a1a08',
    'identification_number': 106613979,
    'item_consumption_1': 46.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1342.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd4972498-79bd-4797-9be6-fba036abc466',
    'identification_number': 97610089,
    'item_consumption_1': 12231,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4775.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca93a838-8790-4db7-a873-556d6a44b7c0',
    'identification_number': 19873727,
    'item_consumption_1': 65.58,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1907.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0b4ec0ab-31f9-47b8-8472-61bd1e7077be',
    'identification_number': 100186920,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9366f12f-7eb7-4e69-bc75-b92e36e1f5e1',
    'identification_number': 94653992,
    'item_consumption_1': 668,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 283.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3db1d4d8-39eb-4b1d-b723-71fde8d119ec',
    'identification_number': 93612397,
    'item_consumption_1': 1426,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 557.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fad5c3f-f650-4394-a1ca-82673e6aba34',
    'identification_number': 39422470,
    'item_consumption_1': 1964,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1147.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '820e2d79-a3c6-40c8-b560-daa5e82f3d34',
    'identification_number': 47573481,
    'item_consumption_1': 3837,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2240.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89779d8a-097b-4f65-b3f9-0a211bfc3d01',
    'identification_number': 26988879,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '479e595f-8cff-4526-b3ef-a8513d1f1629',
    'identification_number': 14952688,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2442.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb156e30-0cf9-4146-93f9-17fcd3e6b06f',
    'identification_number': 19452306,
    'item_consumption_1': 367.2,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 10680.35,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '600f12d5-9179-4a8f-9a3b-6fbaef50cbf7',
    'identification_number': 32578725,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1debe750-602a-4b79-897c-102b24f56f94',
    'identification_number': 37229303,
    'item_consumption_1': 1798,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 703.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0ce0bf3-6df9-478f-98fe-70a280f9e6f2',
    'identification_number': 106607057,
    'item_consumption_1': 1195,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 505.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a354484b-d1c7-447b-ae93-38fd7c87714f',
    'identification_number': 96029641,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-43.82",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '8d5d55e6-8822-493e-a551-00eea6998c80',
    'identification_number': 97817910,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cf3d7490-4b33-43fb-90a8-7f94e85d9378',
    'identification_number': 8115583,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4ba040b1-892c-4b52-b671-ddd1e600516d',
    'identification_number': 57469580,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00d7ebac-f240-4fbb-9e78-a8ca2b58536d',
    'identification_number': 49951670,
    'item_consumption_1': 4629,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2703.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76e02860-d896-4685-a141-8c717c6a716d',
    'identification_number': 99219751,
    'item_consumption_1': 2609,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1018.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f386518-9000-4f7d-8459-70c3940227bd',
    'identification_number': 42496390,
    'item_consumption_1': 3927,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5940.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a5c046b-ada8-4d1f-b655-5b40052c594f',
    'identification_number': 15981444,
    'item_consumption_1': 1094,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 1883.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '473f03ce-8e94-4aad-a88c-0b1c5434bfe8',
    'identification_number': 107718421,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bdad6873-e289-4e92-a4ce-44696f730ee2',
    'identification_number': 70509204,
    'item_consumption_1': 20.04,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 477.97,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1935a719-13b8-4861-a8c5-5c96576c355c',
    'identification_number': 92056920,
    'item_consumption_1': 3099,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1153.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c2f0252-d381-41eb-8f9c-e9216ffd4c82',
    'identification_number': 96117176,
    'item_consumption_1': 605,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 235.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4059d9e2-89a8-4714-9949-55754fb24d29',
    'identification_number': 32845570,
    'item_consumption_1': 40.34,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1173.31,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0c2665e2-912e-445e-8d50-93fe16351d38',
    'identification_number': 96931973,
    'item_consumption_1': 2730,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1065.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b478ccee-0d6f-4bf4-877d-39600254dfd8',
    'identification_number': 9027092,
    'item_consumption_1': 3233,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4890.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51e8a512-0042-4366-9cc5-8cd9abf77cee',
    'identification_number': 45289310,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ef63d1e-20e5-452a-98be-459c619bf9ee',
    'identification_number': 32076622,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3fad1083-b155-473d-90f0-4eb3b20202dd',
    'identification_number': 9189939,
    'item_consumption_1': 2006,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 850.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6193e42-1f59-4753-95f0-11a1a1d5c69a',
    'identification_number': 15872050,
    'item_consumption_1': 676,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 263.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '973aa749-ac60-4fed-920f-2e1e6a794d7c',
    'identification_number': 32348533,
    'item_consumption_1': 27,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 10.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0c43d0b9-1206-458b-889a-ddd1f33587a1',
    'identification_number': 41700929,
    'item_consumption_1': 27,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddd04a65-2822-482e-a2bf-867469e6ad0e',
    'identification_number': 106605712,
    'item_consumption_1': 47.82,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1390.87,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '123ad3b3-bdd1-4cef-9a30-4f5dfb0fac94',
    'identification_number': 53226429,
    'item_consumption_1': 2832,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1654,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3bd13f35-74e9-4759-af73-a44236511b6d',
    'identification_number': 47380128,
    'item_consumption_1': 9263,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3447.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20b9ba03-6759-4ffd-a96a-53b1864c2ea7',
    'identification_number': 111295815,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb31e262-79da-499d-9c8e-d5e95647f608',
    'identification_number': 80724906,
    'item_consumption_1': 88.56,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2575.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0cad3bb4-e6ca-4d91-918a-2f340b404852',
    'identification_number': 33651868,
    'item_consumption_1': "'-2.173",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-755.78",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '956afd6e-7c73-4f27-abb4-90f061a946d1',
    'identification_number': 93830130,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1bf4cb79-f3af-47b3-801d-a4401eae8062',
    'identification_number': 40611914,
    'item_consumption_1': 68.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1979,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c001bc68-f29d-40cf-9d87-6694cc6857cf',
    'identification_number': 14970910,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '021195b6-81cd-4ef9-9d3b-989ff8a49ba4',
    'identification_number': 5403367,
    'item_consumption_1': 1410,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 597.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '185e6aac-3ba5-45bd-b3ed-b35af54aadb3',
    'identification_number': 75753758,
    'item_consumption_1': 23837,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8870.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24f6e866-485e-456e-bb5d-27881de7b23a',
    'identification_number': 39162567,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c5a68b92-a572-4967-9689-809419f7bc3c',
    'identification_number': 17804876,
    'item_consumption_1': 1562,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 610.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '015eaca5-7101-4996-a448-bdf30be7eec1',
    'identification_number': 29745918,
    'item_consumption_1': 0.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2dffcfb4-a17e-45e1-a30a-84dad180fc12',
    'identification_number': 18226280,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44b7a500-d2db-4523-ac2f-245b77b40df3',
    'identification_number': 94694648,
    'item_consumption_1': 13180,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5156.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98e0b7e3-a31f-43d0-bd46-d5a84ca48e51',
    'identification_number': 27923460,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.21,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0c5a90ec-64ff-4245-9889-0448ebd82ca1',
    'identification_number': 76376249,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '83aafb6a-2f0f-4b0a-811a-0077f956d338',
    'identification_number': 101260890,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '41cb8133-2307-43f3-ad95-f3be979b650b',
    'identification_number': 45289310,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ce4f801-1d93-4796-959d-be1173c6dcef',
    'identification_number': 34810986,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '510d2a64-6e52-4b8e-b962-7b5990916a7f',
    'identification_number': 94497451,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '053fb4a1-38ba-410b-9c15-734e2bc3710e',
    'identification_number': 49891294,
    'item_consumption_1': 483,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 730.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53180e81-f4b0-4f09-ac24-dbe266648f87',
    'identification_number': 26139111,
    'item_consumption_1': 43718,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16269.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '594f5bd0-d0f1-4332-b27d-6aee1cce4212',
    'identification_number': 86301322,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7f9e8357-4e4f-4b9f-8129-526e4c645627',
    'identification_number': 109139402,
    'item_consumption_1': 695.63,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 16591.12,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e4ebaf90-0ffc-4b27-a348-d21702ee0eb4',
    'identification_number': 78334640,
    'item_consumption_1': 25339,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3234.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2518e3b7-aa79-4e3d-9b83-a588241949fb',
    'identification_number': 27796833,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 143.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '789a53d7-8dca-4c35-9ce2-7b82d65b041b',
    'identification_number': 75063077,
    'item_consumption_1': 601,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 351,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89846d62-0bad-4f07-a3b4-a692cad373ba',
    'identification_number': 90881656,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '938f4d74-4319-46b7-ab21-e084b3da7043',
    'identification_number': 60716940,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a2436fd7-8482-42e0-9bcd-a97a47294c5f',
    'identification_number': 109487877,
    'item_consumption_1': 41.91,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1218.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '545d9afa-beb9-4d18-8c0e-4308ef351566',
    'identification_number': 37328751,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7dc413d1-0cff-430a-950f-6d23374c663f',
    'identification_number': 39961320,
    'item_consumption_1': 11388,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4238,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2a41cca-4181-44ee-a0f6-af9d3aca035e',
    'identification_number': 81267843,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4678b269-eb35-4c94-b0a0-af1b3a96c054',
    'identification_number': 8998310,
    'item_consumption_1': 7430,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3149.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1564496d-cefc-48a9-8b1f-9c455b0331b6',
    'identification_number': 15184196,
    'item_consumption_1': 3088,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1308.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05ce92e1-c53b-4287-b84d-957be8259da7',
    'identification_number': 70941378,
    'item_consumption_1': 16.06,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 383.04,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '60077f20-0827-4d3b-8266-31ed7040b0bc',
    'identification_number': 5736234,
    'item_consumption_1': "'-556.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-193.43",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '1cbc7555-77a5-4316-9565-4f9fdc0ad75d',
    'identification_number': 105068730,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e7b02ff1-2cb6-448e-8125-fd0ee062e384',
    'identification_number': 109278461,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28899bea-1339-4cdb-a5b4-7a2300c04707',
    'identification_number': 14834642,
    'item_consumption_1': 340,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 143.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '387785db-4032-4361-9b52-6df7eb0504eb',
    'identification_number': 25681656,
    'item_consumption_1': 60,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 23.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a549256-97bd-4e6d-a62d-ee9f69d99c84',
    'identification_number': 42310016,
    'item_consumption_1': 50,
    'item_description_1': 'C.DISPONIB',
    'item_total_value_1': 38.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28234cef-e68d-4e8c-9010-343314a1823c',
    'identification_number': 88601900,
    'item_consumption_1': 857,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 334.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a44f8d2-73be-458a-bf5d-7ca9d9d5abb3',
    'identification_number': 42496390,
    'item_consumption_1': 39299,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5015.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80fcdd16-e70e-4072-ae9e-dfe6ca950b76',
    'identification_number': 57387206,
    'item_consumption_1': 54.88,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1596.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c2052450-60bd-4945-ac88-63c508a91062',
    'identification_number': 95102132,
    'item_consumption_1': 214,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 90.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '787713dd-10bc-4873-a680-cea208dac1ef',
    'identification_number': 20587163,
    'item_consumption_1': 1188769,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 151730.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a13c7c4-f5bd-4d57-b327-897f184ac091',
    'identification_number': 99211823,
    'item_consumption_1': 15.45,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 368.5,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '24f65c47-d407-466d-8346-80b24d337174',
    'identification_number': 106708120,
    'item_consumption_1': 36442,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4651.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77bae10f-55d0-4516-adf5-8a7732a4db59',
    'identification_number': 96254777,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed0e6e94-f23c-4344-829c-d7c43f95bb89',
    'identification_number': 82142068,
    'item_consumption_1': 1226,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 478.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78f169db-a405-46c4-8c6d-9800ac099272',
    'identification_number': 101439075,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 67.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bd98507e-a037-4939-9422-24fd31bb8863',
    'identification_number': 102687560,
    'item_consumption_1': 74.29,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2160.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e067641e-fa32-46e3-9800-27e988c909d4',
    'identification_number': 29985404,
    'item_consumption_1': 403,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 157.67,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'fc3b1a64-4d5f-42ad-b35f-afb83732a7b1',
    'identification_number': 74504908,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '453e78e4-9725-4bce-920f-050a2eb679d8',
    'identification_number': 107173115,
    'item_consumption_1': 8847,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1129.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a180f216-598d-4ecd-b1f2-e54044baa2b8',
    'identification_number': 111115175,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f888e34-f617-4646-be41-de9ab44eb734',
    'identification_number': 19450133,
    'item_consumption_1': 479.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 13947.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e4a9d563-c6ec-43ad-af85-bd51c9ad2bc6',
    'identification_number': 105202126,
    'item_consumption_1': 2211,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 864.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '336242a1-58bc-4e8e-9ff9-12ae7c5af9c0',
    'identification_number': 935371277,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0fe0a3cd-14ae-455e-9b75-f3d43ce863fc',
    'identification_number': 19295413,
    'item_consumption_1': 9186,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3900.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57d3ca39-c892-4b58-b36a-0315452226fe',
    'identification_number': 84327855,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '067de25c-dc7c-49f1-9afd-3a735afb1934',
    'identification_number': 52779386,
    'item_consumption_1': 2278,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 968.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1405d32-6382-46cf-9104-3371b059902b',
    'identification_number': 27125610,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'be9d2087-146b-48ef-b8cb-0be0a1a8ab39',
    'identification_number': 19280270,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5946a6e4-4178-42e7-b103-1fa8f21c7b4b',
    'identification_number': 103087745,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '68b490d2-addb-4068-ac79-7978dec2b6f8',
    'identification_number': 91670942,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '82f7398c-e396-4d0a-8e28-95abee6b51bf',
    'identification_number': 91611377,
    'item_consumption_1': 8000,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3396.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2636c983-8967-4dc1-9675-c20fcb6a3bab',
    'identification_number': 105169226,
    'item_consumption_1': 2351,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 998.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf9f6edf-6a86-44b7-b144-dd6e62858e8c',
    'identification_number': 12918385,
    'item_consumption_1': 18.09,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 431.46,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0b24a64a-0aca-4633-875d-0302010d5524',
    'identification_number': 12804401,
    'item_consumption_1': 3900,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1654.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd99824db-5367-4654-b9b5-93797fa611ef',
    'identification_number': 8115770,
    'item_consumption_1': 261,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 110.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '812ddbd1-582e-4c14-b88e-8762cbe0eb65',
    'identification_number': 25214934,
    'item_consumption_1': 7819,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3316.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e29e1fc-92aa-43d1-9a9b-22ab03c12512',
    'identification_number': 66244013,
    'item_consumption_1': 16501,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 24963.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a523dde5-9a7f-41b5-8604-2b875cf27725',
    'identification_number': 68081251,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.93,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6f5dac11-8a16-4647-aecd-a29d72032c99',
    'identification_number': 107018195,
    'item_consumption_1': 624,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 264.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3034c59e-a30d-4afc-81e5-bbb27c42a3a0',
    'identification_number': 948743603,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37e5f246-87e0-4910-972d-dca935d617aa',
    'identification_number': 3030777501,
    'item_consumption_1': 100,
    'item_description_1': 'Custo Disp Uso Sistema TUSD NOV/23',
    'item_total_value_1': 50.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b590c8d-f9a8-4b93-9be6-1e725ac0f1d8',
    'identification_number': 33140111,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-116.55",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '26e94ec4-4620-4f4d-b54f-329f14df0f12',
    'identification_number': 111320550,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b955b774-3f3e-4358-923a-4ee5feb810f4',
    'identification_number': 41409973,
    'item_consumption_1': 8548,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3341.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '942fbd32-befe-41f0-96d1-db57c89bb5e0',
    'identification_number': 46677615,
    'item_consumption_1': 1004,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1518.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b13d624-63b6-48c9-9e05-f764b84a5ce7',
    'identification_number': 18339603,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 183.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a1a0b539-69e2-4964-9679-f44b1005ad8b',
    'identification_number': 42034515,
    'item_consumption_1': 6.27,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 149.55,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '46b2ff12-c567-48c5-adea-267a8e903cd4',
    'identification_number': 42824699,
    'item_consumption_1': 5744,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2247.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53014329-2cec-43b3-8611-485abbcf966e',
    'identification_number': 100158609,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ffae426-f8f3-4282-b494-398164085e48',
    'identification_number': 44126760,
    'item_consumption_1': 3253,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1377.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06fde78d-18e2-4753-a6e1-a38f3e7f029d',
    'identification_number': 42162653,
    'item_consumption_1': 5702,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2423.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84326d7f-8515-422d-be44-bac339b174f4',
    'identification_number': 92672051,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5e8490d-2c51-4ca9-8ac0-112b312e1e2c',
    'identification_number': 110019377,
    'item_consumption_1': 7,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 2.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'aac4da9a-45f6-4ad3-988b-06dfc1006434',
    'identification_number': 96269391,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f023558-807f-43af-986f-596462879c6c',
    'identification_number': 18898068,
    'item_consumption_1': 217261,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 80853.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd16f9717-4cb9-4ee0-9ce6-d7a4f23cc408',
    'identification_number': 111198577,
    'item_consumption_1': 238,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 100.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '185545d4-b113-44f4-99ee-a932b88a2a45',
    'identification_number': 39196461,
    'item_consumption_1': "'-15.607",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 09/2023',
    'item_total_value_1': "'-4999.45",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '03b55ee0-3a50-499a-afa3-877ef664c6df',
    'identification_number': 42433347,
    'item_consumption_1': 11085,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4336.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '401ca2a2-e6a4-49a3-933a-b49e49cdaabf',
    'identification_number': 19229747,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a689879c-6959-408c-8657-25a678a5cca3',
    'identification_number': 28528646,
    'item_consumption_1': 25,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 9.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5a7a994d-bb0c-4645-85ff-40ca2c3439b6',
    'identification_number': 106787020,
    'item_consumption_1': 2926,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1239.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2dbc058c-b7d4-431a-af7c-35d29cb13f7f',
    'identification_number': 16020,
    'item_consumption_1': 1,
    'item_description_1': 'TX SERV. IL. PUBLICA',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f4d655f6-155d-4ee1-a118-f7757c40218e',
    'identification_number': 20587155,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 55751.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7373d885-14a9-4530-8fd6-8c09ad128cda',
    'identification_number': 70485070,
    'item_consumption_1': 81.91,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2382.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '06d487db-e579-4950-b8dd-300025415711',
    'identification_number': 32927061,
    'item_consumption_1': 1093,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1653.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3c7599e-0f06-4d4c-b0c3-dc6d7e79ce79',
    'identification_number': 99569280,
    'item_consumption_1': 99.79,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2902.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9ef32ffe-d700-4d67-8ce2-f4fa17720e72',
    'identification_number': 19451512,
    'item_consumption_1': 49756,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 75271.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe7de3a0-af53-425f-8afe-375a56e2c7ad',
    'identification_number': 97637238,
    'item_consumption_1': 10304,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1315.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40482a4b-d200-4be7-a4d4-122dc81d4600',
    'identification_number': 18229077,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '00d49805-c490-4ed3-b3e5-ccafd3047f6a',
    'identification_number': 14138034,
    'item_consumption_1': 2221,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1297.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb16a478-d721-4e58-a83c-3c6bba86d6c4',
    'identification_number': 63896419,
    'item_consumption_1': 4367,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 557.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71c42243-63b6-4f1c-959b-b1afeaf27041',
    'identification_number': 98193210,
    'item_consumption_1': 3956,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5984.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8d32e0a-44ca-4a41-a104-10ee2364671a',
    'identification_number': 73887692,
    'item_consumption_1': 1035,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1565.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd419ab0b-df4a-40c8-a12b-3f3561293269',
    'identification_number': 104529121,
    'item_consumption_1': 5050,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2144.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '013faca1-8df5-4428-9b58-f6655a483751',
    'identification_number': 42433223,
    'item_consumption_1': 7199,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2812.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '355f37c3-fcc3-4a49-ad83-410f5fc6f01c',
    'identification_number': 94384126,
    'item_consumption_1': 23072,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 9013.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0de5e527-dc82-4c9e-9778-8310ef23aa3b',
    'identification_number': 17711754,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '082b448e-3bf8-4564-9f0c-f5acd1277617',
    'identification_number': 84599391,
    'item_consumption_1': 1815,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2745.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd372c90-64d7-4f21-acb2-b4ab785504ed',
    'identification_number': 6338526,
    'item_consumption_1': 41,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 977.88,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4a751659-9da3-4966-8f57-d4d0108a98bd',
    'identification_number': 16111621,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib lluminação Póblica',
    'item_total_value_1': 87.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '591d6f68-4d95-4216-b308-a9746ab8a065',
    'identification_number': 32121644,
    'item_consumption_1': 10413,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4071.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc68785a-8fa5-4a7f-8d3f-602fb6662a04',
    'identification_number': 106612662,
    'item_consumption_1': 16642,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 9719.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed7f594f-761f-438f-b94c-f244300f17fb',
    'identification_number': 45501157,
    'item_consumption_1': 103.81,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3019.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a03590bf-f4c5-4842-a7ab-b59ad443bb73',
    'identification_number': 15981444,
    'item_consumption_1': 1,
    'item_description_1': 'En Exc Reativo fora de ponta',
    'item_total_value_1': 0.31,
    'reference_month': '11/2023',
    'referente': 'Energia Reativa Excedente Fora Ponta',
  },
  {
    'uuid_items': '324dd72b-34a8-4b0f-a645-404ecfd5fd85',
    'identification_number': 30290635,
    'item_consumption_1': 20610,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7669.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4df16ccf-2c1e-44db-8482-b20e8262fb3d',
    'identification_number': 105662860,
    'item_consumption_1': 559,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 237,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4a39bfb-576d-4ea3-89b8-7606b5baee1e',
    'identification_number': 76800563,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '33b6fa72-3be2-443a-9d7a-d8f4d0e1fd50',
    'identification_number': 17159679,
    'item_consumption_1': 51.21,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1489.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6878eea9-2cf2-4c76-bc4c-11caa347f41f',
    'identification_number': 67454879,
    'item_consumption_1': 1140,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 445.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7616d8f2-d6b6-40d7-ad6e-b19fe04ffd99',
    'identification_number': 94446547,
    'item_consumption_1': 6281,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2665.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '891703a3-5251-407e-811f-6ace75cff472',
    'identification_number': 105685291,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd0a0c06-7d46-4f58-aabe-213e6527b06e',
    'identification_number': 108630323,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd0aa5320-d36e-4fbe-9695-2dcf2f3c78c8',
    'identification_number': 84156171,
    'item_consumption_1': 55.44,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1612.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5d016ac2-4b40-4894-acbe-d1756550bd6d',
    'identification_number': 93181914,
    'item_consumption_1': 260,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 110.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06ef63b3-d361-45b7-b510-b458710eda42',
    'identification_number': 59820888,
    'item_consumption_1': 2594,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1011.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa52a569-671a-4e62-b70c-9768174c66fd',
    'identification_number': 5702500,
    'item_consumption_1': 2964,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1155.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb049bbf-84ac-4767-acb8-91e3d4f4176c',
    'identification_number': 46523286,
    'item_consumption_1': 2697,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4080.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e254d3f7-1afd-45c8-96c3-c7cb3b16604a',
    'identification_number': 106621360,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f411c99-c15b-4af9-801e-1d1a041752f2',
    'identification_number': 60360046,
    'item_consumption_1': 3033,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1288.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '405a22de-a7cc-414e-a032-6f97f7a3e3b4',
    'identification_number': 14671638,
    'item_consumption_1': 28.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 818.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '992283ce-04ab-462c-84ee-46e5febac4a0',
    'identification_number': 43001300,
    'item_consumption_1': 1087,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 423.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '328fdba5-3f9f-4e68-b48f-77847e35779b',
    'identification_number': 96491833,
    'item_consumption_1': 4081,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1728.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2613b1ad-3282-4764-a035-dab1abdc0d61',
    'identification_number': 98906470,
    'item_consumption_1': 2939,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1146.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b20fd664-9804-4ee1-b0c3-d5d9410d2573',
    'identification_number': 35614595,
    'item_consumption_1': 3024,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1125.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'add5f238-4edf-4862-aa0b-d6b794f6203b',
    'identification_number': 95963391,
    'item_consumption_1': 922,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 391.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '802b4f0f-b352-49ee-ae68-20aa13bf7cc2',
    'identification_number': 87412233,
    'item_consumption_1': 1523,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 594.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '012af50e-4800-4a5a-9e10-1e46e1c5b80e',
    'identification_number': 95847952,
    'item_consumption_1': 32498,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12094.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76d41219-84e9-4e06-8876-5bf081474b4c',
    'identification_number': 78329051,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5eaf535d-8189-4678-8b63-0b6b162a1e8a',
    'identification_number': 91360250,
    'item_consumption_1': 4294,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1598,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5310442f-e7f0-4e6d-9076-a65d3ff88050',
    'identification_number': 19453302,
    'item_consumption_1': 0,
    'item_description_1': 'DESLIGAMENTO PROGRAMADO',
    'item_total_value_1': 257.62,
    'reference_month': '11/2023',
    'referente': 'Desligamento',
  },
  {
    'uuid_items': '11552046-592a-48c6-82c7-d2388bc2c985',
    'identification_number': 94480524,
    'item_consumption_1': 5309,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2075.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a823463b-ad66-4c71-86b8-55916bc17442',
    'identification_number': 108646785,
    'item_consumption_1': 2670,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1132.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '556a6dea-bf87-448c-b8b6-d8494f6018ce',
    'identification_number': 41213947,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '673efe2b-862d-46b7-a5a0-19f4df3cf0b7',
    'identification_number': 32500459,
    'item_consumption_1': 11184,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4162.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b09d577f-dbd4-4a71-afdd-56a6eed28aa8',
    'identification_number': 83699864,
    'item_consumption_1': 526,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 223.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c203d415-72c7-48f4-b7ac-b8160ceb0080',
    'identification_number': 97807141,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62ffdbc5-4407-4f55-a536-8d042e30f7dc',
    'identification_number': 38370492,
    'item_consumption_1': 209.39,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6090.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c3bdcbcf-9462-410d-b326-5afbddc67596',
    'identification_number': 44126891,
    'item_consumption_1': 2135,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 905.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dafb801e-dd65-4351-8aba-add5debc0c2b',
    'identification_number': 40450554,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68ae400b-a578-4b7f-83f9-708f625bb263',
    'identification_number': 100380433,
    'item_consumption_1': 6844,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2898.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7dcdb104-7976-4e48-a546-17ad6ffe706c',
    'identification_number': 19013230,
    'item_consumption_1': 1465,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 620.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a083d0fe-c50e-4cdb-820b-63bcc9692692',
    'identification_number': 88926923,
    'item_consumption_1': 8453,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3298.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7780ab9-3e2c-4f48-9d77-4cbfd71dfdac',
    'identification_number': 26677210,
    'item_consumption_1': 19439,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2481.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09aeb1b4-053e-4729-aeb8-aa3b47907474',
    'identification_number': 49706152,
    'item_consumption_1': 2844,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1108.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f93e6d01-d931-463f-a414-a8e856f859b5',
    'identification_number': 101840098,
    'item_consumption_1': 1153,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 450.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c6785bf-4be0-4ca3-85d1-d7323b563d9b',
    'identification_number': 935540210,
    'item_consumption_1': 10456,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 9115.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f65a1df2-08f0-4f9a-a77b-64381f73fb0d',
    'identification_number': 103876502,
    'item_consumption_1': 32.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 944.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f7598894-a4a6-46bb-b044-2826b7ae57e8',
    'identification_number': 33800677,
    'item_consumption_1': 95.74,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2784.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2752971d-0963-4d1b-bf80-d78f30fd181a',
    'identification_number': 41379136,
    'item_consumption_1': 157.34,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 8985.67,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a5c844b8-7feb-4b3a-a7b8-23657a9135d2',
    'identification_number': 67513212,
    'item_consumption_1': 474,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 185.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3409a6a9-3edc-48df-a80e-940f9974a5b5',
    'identification_number': 6307272,
    'item_consumption_1': 1197,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 508.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ba74de0-84a3-45af-8411-381667ad68fa',
    'identification_number': 15830543,
    'item_consumption_1': 2780,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1181.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e27a8056-1881-4824-84bf-10d191430418',
    'identification_number': 40701301,
    'item_consumption_1': 500.25,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 28569.27,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2d94606b-adad-41d2-acbb-8b7a25f861fc',
    'identification_number': 18678,
    'item_consumption_1': 1,
    'item_description_1': 'TX. SERV. IL. PUBLICA',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e03c313e-102a-4b1c-b338-36682bc6e964',
    'identification_number': 32266847,
    'item_consumption_1': 3775,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1601.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3bb9d39-7639-4f55-9f09-99aad2c9131e',
    'identification_number': 14562553,
    'item_consumption_1': 16.13,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 755.39,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '772b57fe-7867-4e39-a3ee-f561eb9bdf0f',
    'identification_number': 10293540,
    'item_consumption_1': 872,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 339.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e35f0613-16ec-4215-a0ba-ffc1b1d4abcc',
    'identification_number': 59144017,
    'item_consumption_1': 24238,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3093.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b17d8dff-e4b2-403a-9197-5318cea431cb',
    'identification_number': 19304749,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.21,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eed5bfc3-6abc-4809-937d-783f031684dc',
    'identification_number': 105456993,
    'item_consumption_1': 7730,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3016,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '497439f5-a1bd-4af6-bddd-de4f62b1e2c4',
    'identification_number': 12024970,
    'item_consumption_1': 10748,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4198.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab6716bb-18eb-4172-ba94-a5028090258b',
    'identification_number': 47599596,
    'item_consumption_1': 1158,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 452.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bc99975-a86c-4d18-907b-890ee1674f53',
    'identification_number': 41002768,
    'item_consumption_1': 851,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 331.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3efa61ae-0506-464e-820c-dac5185649a3',
    'identification_number': 14859513,
    'item_consumption_1': 1099,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 140.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17f36cbc-969e-4459-83d3-cf25f2d26263',
    'identification_number': 108601676,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 18.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd664b62a-42cb-474d-8a7a-7fad9438da42',
    'identification_number': 95205390,
    'item_consumption_1': 1850,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 785.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60628a28-49ce-44d1-9ddb-878675564763',
    'identification_number': 36113743,
    'item_consumption_1': 1672,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 652.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54a15f37-874f-4e52-9691-146fefaed467',
    'identification_number': 22935940,
    'item_consumption_1': 15080,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1924.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2ef1c33-0d61-482a-a9c1-f0782ed2e144',
    'identification_number': 70556997,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '93924273-deb8-4d90-83a8-d579b703fcb7',
    'identification_number': 95258620,
    'item_consumption_1': 19268,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 7527.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fa82917-ec4f-4583-9cea-86579ec56e79',
    'identification_number': 91517303,
    'item_consumption_1': 389,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 164.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e32df6f-7fe8-4675-95ca-aec1d52b70f7',
    'identification_number': 18616909,
    'item_consumption_1': 878,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 112.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e67f63a3-d75c-4bc4-a7fc-1f718ea3f3b7',
    'identification_number': 98199544,
    'item_consumption_1': 1646,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 696.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d3e755e-0076-4da0-b5d3-285c2280d16f',
    'identification_number': 45220352,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb43d0fa-62a9-427f-bcf5-b84834420aa4',
    'identification_number': 28858913,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 107.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '28f2606a-9878-4561-9025-3cf27340ea03',
    'identification_number': 103438157,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 126.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9fd652f9-315a-4406-8318-93511705609d',
    'identification_number': 45488606,
    'item_consumption_1': 209,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 88.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2327445-648f-4a9b-a130-47156a415996',
    'identification_number': 75768879,
    'item_consumption_1': 22.9,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1332.12,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5f2aa25a-4d08-4807-8e7b-cbe6304cc973',
    'identification_number': 36525405,
    'item_consumption_1': 376,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 159.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e3beeb6-64d6-427f-be67-6a5eff22c688',
    'identification_number': 25327283,
    'item_consumption_1': 2016,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 854.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'daa17ad4-ee23-455b-8f97-4d87a847a324',
    'identification_number': 923340193,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf0e5df0-3565-4450-b2aa-f88fd0a4fd33',
    'identification_number': 72092556,
    'item_consumption_1': 12.19,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 290.74,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9bc618e9-d1a7-436e-98ee-e76b358330f7',
    'identification_number': 88789152,
    'item_consumption_1': 5237,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2224.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '632cda5a-da32-4b87-b9ec-401de79f7686',
    'identification_number': 103323392,
    'item_consumption_1': 669,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 283.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79ddd0b1-e660-4e94-a827-41e77a701ef7',
    'identification_number': 32140215,
    'item_consumption_1': 16626,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6187.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '006ff2ea-5b5e-4796-aa18-03d4bca6f14f',
    'identification_number': 32348533,
    'item_consumption_1': 4.9,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 116.88,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'be4f4db4-4143-4c03-b689-55413fa86b19',
    'identification_number': 17023491,
    'item_consumption_1': 47,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 27.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1703a5b4-2f46-4321-ba9d-3d48df47df3a',
    'identification_number': 22789023,
    'item_consumption_1': 590,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 223.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd822cfb-a191-40d1-a7a8-5746f49c2d68',
    'identification_number': 92096115,
    'item_consumption_1': 57801,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7377.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '421790fc-54da-4ba9-9d6a-85251e4daef4',
    'identification_number': 56507844,
    'item_consumption_1': 1971,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1151.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4441a6d7-10e6-4bb7-89de-4dae634a1e9b',
    'identification_number': 17085071,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 69.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7b9ffab0-ad54-44ab-b89a-d636dec9f08e',
    'identification_number': 97837350,
    'item_consumption_1': 29327,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3743.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56af9a8e-e94b-4bd1-9cd0-159b72454d0f',
    'identification_number': 19282087,
    'item_consumption_1': 588,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 229.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dda224ea-409c-4e01-bbd9-bdecb50cd473',
    'identification_number': 93748604,
    'item_consumption_1': 2598,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1012.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a59e2d58-25e3-4bbb-a3c5-9cd1c9b7fa69',
    'identification_number': 12804428,
    'item_consumption_1': 888,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 376.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53397f1d-7f0f-448f-893b-6e75387d0dfb',
    'identification_number': 76288714,
    'item_consumption_1': 700703,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 89435.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5832a5da-893b-4f3d-abc1-873b84e837bd',
    'identification_number': 98253336,
    'item_consumption_1': 1189,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 463.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7327672-18e5-4c15-a8b3-ca0da4a4176e',
    'identification_number': 93763395,
    'item_consumption_1': 42.8,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1244.86,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8d1a9840-b8a8-45f2-9222-5726d99fef86',
    'identification_number': 64669360,
    'item_consumption_1': 23.31,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 555.96,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '85ab1bc3-2944-4b67-bd7c-5edfdae3ba27',
    'identification_number': 15101797,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 132.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '44da1b56-1489-4a9e-bbcc-42c54da74ec6',
    'identification_number': 99082179,
    'item_consumption_1': 295,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 115.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '198bbe3d-5e2c-45a4-9b82-3e31d08e7f17',
    'identification_number': 22107860,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 228.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14601eb9-4d2f-46a1-a9b9-1ae7d81a7836',
    'identification_number': 103337300,
    'item_consumption_1': 64.55,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1877.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5fb975a3-6437-4e75-a366-97e1d50d1b04',
    'identification_number': 13789430,
    'item_consumption_1': 6.42,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 186.71,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '758aa46f-f25b-4046-8cbf-6f0fdd2089f9',
    'identification_number': 93586086,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f0590c1-1fe5-4b1b-9a55-7ddcbb362732',
    'identification_number': 45239282,
    'item_consumption_1': 53.43,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3051.37,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'dfb817e6-5cfa-42de-bb64-0cbef9cce667',
    'identification_number': 17656265,
    'item_consumption_1': 1737,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 678.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b1ad48f-0f0c-4679-a74b-0caa468b9258',
    'identification_number': 18138373,
    'item_consumption_1': 0.6,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 28.11,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '6f3c460c-b793-461c-845b-89247f7f32c3',
    'identification_number': 27316114,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ddd42f0c-f568-477a-8446-e0ac9c11c9c5',
    'identification_number': 107936925,
    'item_consumption_1': 1867,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1090.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '355f1a84-4a72-484b-b685-de8b6a7c9d41',
    'identification_number': 6189512,
    'item_consumption_1': 1395,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 814.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23c4e41d-ce94-4cb6-a740-fd20d9435709',
    'identification_number': 3030777242,
    'item_consumption_1': 783.7806,
    'item_description_1': 'Cons Ponta TE NOV/23',
    'item_total_value_1': 353.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05c9c8e2-9688-412a-abfd-23090877ec7b',
    'identification_number': 104328037,
    'item_consumption_1': 579,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 245.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8f56063-2c55-4a36-9ece-699996b15c20',
    'identification_number': 79404367,
    'item_consumption_1': 444,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 188.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4745f17a-91e9-42c3-8b2b-3f200cdd08c9',
    'identification_number': 41108353,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '54859d9d-df90-4c65-8abe-a5856391bea8',
    'identification_number': 27923460,
    'item_consumption_1': 773,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 328.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31939c88-3ddb-4507-bdb1-eb37c1a0e866',
    'identification_number': 95969896,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b11c3066-b2b9-4d81-96df-12412887a146',
    'identification_number': 97637238,
    'item_consumption_1': 1277,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1931.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e7e83c7-498a-4647-8843-7657fdc2f40e',
    'identification_number': 59437650,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a57d8290-c88a-4334-a563-6558a758f256',
    'identification_number': 78234646,
    'item_consumption_1': 98,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '502de2d9-ba8f-46bd-89f8-f357d407749d',
    'identification_number': 91168988,
    'item_consumption_1': 4329,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1834.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50aa057e-5922-4476-abe4-6f16ebd6a1ae',
    'identification_number': 10894055,
    'item_consumption_1': 1709,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 666.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58f7042a-7911-41d1-9d17-32d11bf32e8d',
    'identification_number': 16307828,
    'item_consumption_1': 2153,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1257.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '184c7d79-bac2-4d8d-a784-f7dd749f64f5',
    'identification_number': 77887930,
    'item_consumption_1': 7249,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 925.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd932931f-df59-46c8-ba1f-3f1ae16f028f',
    'identification_number': 105763101,
    'item_consumption_1': 16563,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6163.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1821fd48-259e-40c8-a720-5a4cdce6a98a',
    'identification_number': 81500513,
    'item_consumption_1': 63.72,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1519.77,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '522e58f1-b19c-4ebe-b7d1-191d32a9a8ef',
    'identification_number': 16876709,
    'item_consumption_1': 1690,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2556.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d78cf56-3440-439b-8f8f-4ec2efba679d',
    'identification_number': 101046820,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 105.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '84ea9e3b-5c5d-4768-8e09-e3d90f90276c',
    'identification_number': 63416794,
    'item_consumption_1': 0.73,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 34.2,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'fcb6ccdb-a0c2-43c7-919a-a06a581572c1',
    'identification_number': 20304137,
    'item_consumption_1': 1012,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1530.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63158d1a-e477-4b62-ba2d-f9d85f1cf2bb',
    'identification_number': 99051770,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '847b9d84-60e4-4a9f-bd8b-29530f4c84e7',
    'identification_number': 103284770,
    'item_consumption_1': 23486,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8740.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '340b4c5f-7e9c-4a91-beb1-d6d3463114b9',
    'identification_number': 44184727,
    'item_consumption_1': 3217,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1253.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67c63fbd-3f63-4da2-b1f3-cd0ccd98f356',
    'identification_number': 75390990,
    'item_consumption_1': 5194,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2029.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d568d4b-2e4f-4a92-95c6-fb052a6064e2',
    'identification_number': 109826582,
    'item_consumption_1': 340,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 132.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60aa4e7f-e3b8-4924-ae38-d1dfe56a9e6b',
    'identification_number': 103887695,
    'item_consumption_1': 42956,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16760.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a32334e9-2332-4471-94d5-81912fbe09ee',
    'identification_number': 50761900,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8cfdd01-a807-4b1f-b132-cfb4bec78cfa',
    'identification_number': 57946116,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ea6cded-173e-4ffa-afa7-36964c256365',
    'identification_number': 29966060,
    'item_consumption_1': 93,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 36.37,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3035fa2c-16f5-4879-b9ed-b09583fc116d',
    'identification_number': 12673994,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7775.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0676064-965a-4d8f-8c7d-92027378c9ae',
    'identification_number': 105202126,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.06,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '280586d7-06ca-4b45-99fe-9def68d5932f',
    'identification_number': 68376391,
    'item_consumption_1': 51.21,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1489.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b6737b24-1846-468b-a7a7-44c27874722e',
    'identification_number': 56881444,
    'item_consumption_1': 5.84,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 139.3,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4da0b02e-b24d-495a-bbfe-4b6b414df697',
    'identification_number': 40828000,
    'item_consumption_1': 12408,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4831.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7355db3-a07c-4d38-b752-c75d086c3677',
    'identification_number': 40207285,
    'item_consumption_1': 153.5,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4464.68,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8bbb3de5-c500-4e7f-ac05-abc9b71f4411',
    'identification_number': 102011311,
    'item_consumption_1': 506,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 214.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af8332da-c11b-43de-9da4-4907b79d6c07',
    'identification_number': 97079006,
    'item_consumption_1': 444,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 188.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18c562cf-c4e2-4feb-a8a3-a2b10805c596',
    'identification_number': 101040245,
    'item_consumption_1': 5805,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2465.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '166212b1-22db-442c-9127-86f386ed583a',
    'identification_number': 6189512,
    'item_consumption_1': 34.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 992.98,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6637b778-22b0-43cd-a251-9555ccf7ed06',
    'identification_number': 97699217,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cb191638-79d2-44d6-b90f-b5d6c13ab2a1',
    'identification_number': 102676844,
    'item_consumption_1': 1472,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 625.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0704ce09-e069-48a9-8851-8dac030c4170',
    'identification_number': 59357053,
    'item_consumption_1': 1230,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 480.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c30e96e-2b66-45a2-8b67-56b0ab82d80d',
    'identification_number': 107619962,
    'item_consumption_1': 993,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 421.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '903536f2-353a-41f5-84a2-8bd730862cd0',
    'identification_number': 45789061,
    'item_consumption_1': 203,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 79.24,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5942d29f-c99d-48fc-a59d-819a0c9c3d9c',
    'identification_number': 56881444,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 115.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e13b5bcd-4b9e-4d47-a166-dd8b7ce3d3e3',
    'identification_number': 17970806,
    'item_consumption_1': 10932,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1395.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '434fbece-befd-4942-b875-419a6c214a0e',
    'identification_number': 110679393,
    'item_consumption_1': 3175,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1349.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee7cdaba-9e10-415f-b2a0-d1c4dee546af',
    'identification_number': 73311235,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1090.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '570c37d4-d5aa-4dd7-b68e-f22f51c9bba8',
    'identification_number': 63964112,
    'item_consumption_1': 214,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 323.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3cac9fa5-0250-42f7-8fa2-3b39913e6482',
    'identification_number': 13178032,
    'item_consumption_1': 1036,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1567.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69315a83-2033-4633-9c40-919f914f7513',
    'identification_number': 17550254,
    'item_consumption_1': 6277,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2447.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '135accb1-87d9-4710-85e9-99bf9a7d785c',
    'identification_number': 71391274,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '91fe9c5c-8d0d-4e54-a1fe-77599d33adbf',
    'identification_number': 32266847,
    'item_consumption_1': "'-231.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 01/2023',
    'item_total_value_1': "'-73.94",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '1b8c2522-2e12-44d5-bba5-47e8074cc410',
    'identification_number': 95102132,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ba6fb31f-e977-4038-a1cf-ecd6bbaac2f6',
    'identification_number': 32689250,
    'item_consumption_1': 46.64,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1112.39,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f5886a81-1d4e-49cc-9e3a-756deffb45f3',
    'identification_number': 40207285,
    'item_consumption_1': 53815,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6868.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1822e701-8a8c-42c6-b8e7-d004af33c9cb',
    'identification_number': 106136712,
    'item_consumption_1': 1523,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2304.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'add54b20-f25f-4cc1-9291-fdf3d86def17',
    'identification_number': 63008084,
    'item_consumption_1': 548,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 214.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a8fac53-05f8-4f69-8d77-53dfb1c9a815',
    'identification_number': 48018422,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd9edaa32-036d-4564-8678-222cacea2cd0',
    'identification_number': 91359511,
    'item_consumption_1': 96603,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 12330.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75fe3a98-e7d1-4d6d-83e3-c14791b2cab4',
    'identification_number': 4129199,
    'item_consumption_1': 220,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 85.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'deeb3946-e114-46ac-b4ff-9704765fdd90',
    'identification_number': 109858891,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '30e27cf7-f649-4a98-bfdc-501a5295643f',
    'identification_number': 42967236,
    'item_consumption_1': 1103,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 429.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c7ae25e-effd-4520-82ca-2610d7d72d92',
    'identification_number': 102684650,
    'item_consumption_1': 417,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 630.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96d45dca-6058-449e-884c-d483b2af663d',
    'identification_number': 97614858,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ff62ac5c-b2ff-4fe2-a919-0f885262a576',
    'identification_number': 19452900,
    'item_consumption_1': 328698,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 122324.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1dc684f4-e4d6-4039-b62a-c4c54a90f640',
    'identification_number': 92122345,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-3478.46",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '0a5d614a-d2b8-45b7-8483-69b6e3812f9f',
    'identification_number': 923309735,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd073749f-450c-426b-8a92-655fce730cbe',
    'identification_number': 84156171,
    'item_consumption_1': 252,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 147.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a094199e-fcc8-4ad1-ba29-31550b4b639b',
    'identification_number': 26987415,
    'item_consumption_1': 1.386,
    'item_description_1': 'CONSUMO PONTA TUSD',
    'item_total_value_1': 1703.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b252d509-fd63-4b98-9374-419c12d6de52',
    'identification_number': 92334067,
    'item_consumption_1': 9031,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3519.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f373aed-8bc7-4f89-ac91-a94d5900f6ca',
    'identification_number': 14138018,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b67eec39-7917-4fd8-b52c-8715e4c7406b',
    'identification_number': 12399752,
    'item_consumption_1': 21.79,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 633.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ed2dbdb2-461c-4ea2-aa96-649c01575d87',
    'identification_number': 8115788,
    'item_consumption_1': 335,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 130.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a53fb9ba-aa81-418b-ac8b-51f6f7848f4e',
    'identification_number': 107627418,
    'item_consumption_1': 474,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 200.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '289c0c12-21e4-4419-8e37-89710a8a519c',
    'identification_number': 53535448,
    'item_consumption_1': 1947,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 758.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ecc643fb-e0cf-4913-ac56-da0eef0b3041',
    'identification_number': 103904212,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd3bbe83b-a10e-477c-92e5-0c0ac8ec7d9f',
    'identification_number': 102260460,
    'item_consumption_1': 22395,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2858.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ede70ec6-7d9f-4819-9904-145cb0eb2a5c',
    'identification_number': 18209793,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7ed875f6-da0b-45e9-806b-a1cd8590c4cd',
    'identification_number': 16978293,
    'item_consumption_1': 8340,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3257.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f3a8690-70d9-4147-a4d2-657f2a3d7cf0',
    'identification_number': 8906041,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c48d556a-583b-42a9-9a2a-cf2c73c58a2f',
    'identification_number': 5330939,
    'item_consumption_1': 105749,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 39354.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd6bc61c-b869-40b3-ae28-868b87394fd6',
    'identification_number': 108736490,
    'item_consumption_1': 409,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 159.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa53589a-54b3-40ef-81e5-c48887df20da',
    'identification_number': 28426193,
    'item_consumption_1': 10006,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5843.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db95ab51-06ef-4ad6-abb7-88c072a1e94b',
    'identification_number': 102676844,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b39b9d07-4b4a-4d53-8561-39b10059b47d',
    'identification_number': 102220824,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '00bef8be-0dd6-4cbe-af78-90bc107b5fea',
    'identification_number': 93003064,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-1066.63",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'af26cf9f-f64d-479a-81d7-d735a9a5b4e2',
    'identification_number': 16246837,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4cf8fe0a-4f5e-40c7-b54c-c9dc41488085',
    'identification_number': 107939380,
    'item_consumption_1': 187,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 73.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b0c78f2-4d77-4d0e-af10-713b8d6d8e29',
    'identification_number': 110655109,
    'item_consumption_1': 336,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 142.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01a1298a-7b61-4582-9d25-e5de8bcc18dc',
    'identification_number': 71978097,
    'item_consumption_1': 7559,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3205.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a282cd91-307c-497c-b8f4-68fa3d7bebdb',
    'identification_number': 98629859,
    'item_consumption_1': 1060,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 449.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1279149-570a-4b71-8e05-c779f3c86ee7',
    'identification_number': 107936925,
    'item_consumption_1': 21305,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2719.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df32928e-e14b-48d5-a919-402bd62de4b1',
    'identification_number': 30085918,
    'item_consumption_1': 33.65,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 978.71,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '17ad9c0c-f045-4fba-b6a2-eb2ba77b889b',
    'identification_number': 107692015,
    'item_consumption_1': 19437,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 7591.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e1f40f7-8fe4-4423-940b-1c3deeb6a3d9',
    'identification_number': 93133332,
    'item_consumption_1': 1779,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 692.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cb540b3-80e0-4437-8daa-b0c2a543f121',
    'identification_number': 98150740,
    'item_consumption_1': 846,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 329.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bb423eb-1ba4-4ac6-ad51-72c3214c3e61',
    'identification_number': 15348075,
    'item_consumption_1': 5740,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 732.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5988a46-68d4-4d44-a8ca-60628beb4aca',
    'identification_number': 6219012,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db9f667e-4b76-4dcf-8f51-06d83599a813',
    'identification_number': 29046092,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e4da01cc-cecd-4ede-8d8d-f7d914a9a452',
    'identification_number': 64175251,
    'item_consumption_1': 1.81,
    'item_description_1': 'DEMANDA REAT EXCED USD FP',
    'item_total_value_1': 52.64,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '0e944d41-b44b-4547-9fad-f1055f72cae2',
    'identification_number': 99990407,
    'item_consumption_1': 3992,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1560.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6df7b487-0cd4-4083-9ddf-4bc5df0c9147',
    'identification_number': 99633671,
    'item_consumption_1': 5904,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2507.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86669fd7-c083-4031-a418-86b402213103',
    'identification_number': 108607658,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '244e283c-d1b2-4cd3-afe6-a15a6a4a36e8',
    'identification_number': 80350844,
    'item_consumption_1': 1343,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2031.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f6fef9a-6841-41d6-a1df-d6e67591b046',
    'identification_number': 106141864,
    'item_consumption_1': 4911,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 626.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfb17e1d-8fea-440d-8ac0-a2ca96996b96',
    'identification_number': 15909581,
    'item_consumption_1': 284,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 110.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78ff0419-a13b-4e8d-a7ae-ad8588b8b845',
    'identification_number': 103438157,
    'item_consumption_1': 33.7,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1924.59,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '99324b98-4e10-426a-8794-2350646dc11f',
    'identification_number': 92724493,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ab5bcbf-2bfd-43bd-ab20-850af9de3c10',
    'identification_number': 97779113,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 738.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab47061c-0ad5-4055-968e-3843913b8e8a',
    'identification_number': 36896918,
    'item_consumption_1': 10141,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3954.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1685cd7c-2374-46c0-aae3-67e439c4330d',
    'identification_number': 18001050,
    'item_consumption_1': 3586,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1521.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44d51277-7746-4692-a3f8-35672092400f',
    'identification_number': 43333680,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '546fa44e-29af-4c47-866d-47105201acf8',
    'identification_number': 100380433,
    'item_consumption_1': 6844,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2667.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aedacfe8-d0d4-41ac-9608-7aacf385fdde',
    'identification_number': 44818815,
    'item_consumption_1': 9626,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5621.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81a5a581-6b1f-42a3-a84a-f6492863823e',
    'identification_number': 19312458,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 228.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be94cb75-474f-483b-830e-9218f5e662ad',
    'identification_number': 48004936,
    'item_consumption_1': 2959,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4476.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a08967a-fb37-442e-ba61-f8df0df9fd33',
    'identification_number': 96895993,
    'item_consumption_1': 9039,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3839.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '570a495a-3d16-446f-b851-51eed6d508af',
    'identification_number': 107922983,
    'item_consumption_1': 409,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 159.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2462ae2d-d819-46e0-b9c4-66275aebfd0b',
    'identification_number': 51632381,
    'item_consumption_1': 22754,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2904.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4807a3d0-ca0a-456e-8173-1456f1dd8a2d',
    'identification_number': 110228022,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bbf7c2b-3a32-4524-9d0f-8b2ded25a103',
    'identification_number': 16128060,
    'item_consumption_1': 8,
    'item_description_1': 'En Exc Realivo fora de ponta',
    'item_total_value_1': 2.5,
    'reference_month': '11/2023',
    'referente': 'Energia Reativa Excedente Fora Ponta',
  },
  {
    'uuid_items': '0ffb94f8-e363-4471-a8d0-6e4e87810a0c',
    'identification_number': 14825430,
    'item_consumption_1': 3184,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1245.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88187773-10a5-4b18-b3e5-3a4bb1d8dd97',
    'identification_number': 11680148,
    'item_consumption_1': 14652,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1870.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8863cff-6d16-469e-886b-58a5c2a6d98d',
    'identification_number': 30085900,
    'item_consumption_1': 37.15,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1080.52,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4406e188-f7ff-4799-a135-57f54a58053a',
    'identification_number': 100637612,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea51fbf5-2330-42ab-b522-966b1e0d0b43',
    'identification_number': 32446292,
    'item_consumption_1': 833,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 325.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c8c8fe0-9374-4164-a179-79ce1a6f92b0',
    'identification_number': 56173806,
    'item_consumption_1': 4601,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1792.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29b80445-673d-44b1-9f70-cc1b1547f541',
    'identification_number': 47472006,
    'item_consumption_1': 16791,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6248.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29a6dae3-8227-4b80-bbf6-dd8735174ffa',
    'identification_number': 23266414,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b346a6a4-d3da-468c-b6f8-a3697c108f99',
    'identification_number': 108106128,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8cc0520e-7021-40b2-8457-8f442bace565',
    'identification_number': 106851217,
    'item_consumption_1': 933,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 544.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbf10e90-31e1-4125-b589-871762f6855a',
    'identification_number': 46577173,
    'item_consumption_1': 1.99,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 93.2,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'dd4f466a-0f3e-4893-9681-8a57d5eee5d5',
    'identification_number': 72063564,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08649c96-7cf6-483c-b8b2-e01c707997c9',
    'identification_number': 103879889,
    'item_consumption_1': 1078,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 420.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '605bbb31-86ed-40e3-88c0-335a9d6d2bcf',
    'identification_number': 39545377,
    'item_consumption_1': 11398,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1454.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4859a57b-af34-4d34-b5fb-4c02ba18f706',
    'identification_number': 93950993,
    'item_consumption_1': 55540,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 20669.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc943438-c2c0-4df8-b91c-2869c55144ae',
    'identification_number': 105202126,
    'item_consumption_1': 2211,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 939.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5d58b9c-0d2e-4d31-9808-874129cdcc0d',
    'identification_number': 99313987,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-382.66",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '628d59d3-49d2-4230-a8f7-e9ba3a3e04bc',
    'identification_number': 53573749,
    'item_consumption_1': 17,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 6.64,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b51327e5-f177-4f2c-9ea6-8a607555b1cd',
    'identification_number': 71236252,
    'item_consumption_1': 4228,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1650.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c102b20-2e3e-4054-87ed-0dbb4b6aa106',
    'identification_number': 105683574,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5454878a-be3b-42ac-bf85-18d4a8deb754',
    'identification_number': 46349030,
    'item_consumption_1': 55,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 21.5,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6ac4f792-f8a2-4171-9254-16dd3f3d042f',
    'identification_number': 44261802,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a94c754-bd78-454d-94be-45e10ed8b2fd',
    'identification_number': 31789366,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 57.26,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9c6d20bb-6622-467e-bc95-f3e983edf6eb',
    'identification_number': 28426193,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e90cce61-057c-461f-8fd8-b9d1624bec19',
    'identification_number': 31789366,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6422.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ba10c2a-2d95-4de3-be97-e42efbbce83c',
    'identification_number': 98560905,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-86.68",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '867f59fe-530d-4a23-b671-dc208457214d',
    'identification_number': 18787487,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '963bfbd6-9320-4019-b749-e1c18d623d75',
    'identification_number': 108863026,
    'item_consumption_1': 69.46,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1656.66,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e4304673-a375-49bc-b712-9fb55ffa4b73',
    'identification_number': 19453302,
    'item_consumption_1': 160,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 62.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c76bb3ed-9e12-4fec-b7e5-a09d3eea749d',
    'identification_number': 102645698,
    'item_consumption_1': 541,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 315.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2271129d-f44c-433e-bd4d-1c73fe9a47eb',
    'identification_number': 935258912,
    'item_consumption_1': 108,
    'item_description_1': 'Demanda de Potência Medida Fora Ponta',
    'item_total_value_1': 3327.57,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0214f3cb-5389-402e-851b-29bb351bf2db',
    'identification_number': 92140394,
    'item_consumption_1': 33.39,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 796.36,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'fd526a79-e0e0-4b33-a21f-45cb0d442a1f',
    'identification_number': 14066017,
    'item_consumption_1': 30,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 715.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '65e399d8-5624-4732-b2d3-d336643b823a',
    'identification_number': 95566651,
    'item_consumption_1': 5487,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 700.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e5994b5-e6a5-41a1-a765-98cffcbf7191',
    'identification_number': 105737720,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fc3cc13-64e7-4a59-b93d-ff21bb1612ed',
    'identification_number': 78564662,
    'item_consumption_1': 0.3,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 14.07,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3206d95e-77ef-4a98-b42b-d9bb7a3285a6',
    'identification_number': 59422009,
    'item_consumption_1': 5073,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 647.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18586a1e-9e95-4f8a-a912-41b66b1e69ea',
    'identification_number': 69032467,
    'item_consumption_1': 4995,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2121.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54636a02-b5ac-46e2-a971-a0981635817d',
    'identification_number': 64104770,
    'item_consumption_1': 36493,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4657.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '837def03-a66c-4920-b489-ba8a41e17ab1',
    'identification_number': 41108353,
    'item_consumption_1': 596,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 232.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'beb5ebf3-4b61-4f5d-a9b9-76d6393cb28d',
    'identification_number': 17187923,
    'item_consumption_1': 11.14,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 265.71,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'bf6d98da-cbc0-432f-b853-67289714b8a5',
    'identification_number': 91801435,
    'item_consumption_1': 222,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 94.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66dde19e-2da0-4b3f-a824-433e5f662a75',
    'identification_number': 21170762,
    'item_consumption_1': 1921,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 751.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff26dfee-8d75-4a4f-8861-ba2c289a8ca5',
    'identification_number': 19311761,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8669717-55b0-4739-859c-4cb03fa379c3',
    'identification_number': 8115680,
    'item_consumption_1': 254,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 107.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32dc81f2-e863-42cd-9539-8a491eac43a4',
    'identification_number': 30085900,
    'item_consumption_1': 36.9,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2107.35,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e11ff9aa-2165-40cd-9aa0-93b07c17c63a',
    'identification_number': 73533424,
    'item_consumption_1': 5521,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2339.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c7e4e40-2d7a-4428-9392-49a4bc25cedb',
    'identification_number': 38718502,
    'item_consumption_1': 1444,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 843.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '760fc222-a6e9-473c-9ffb-939bdd36e968',
    'identification_number': 927293026,
    'item_consumption_1': 756,
    'item_description_1': 'Energia Reativa Exced em KWh EPonta',
    'item_total_value_1': 312.48,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': 'e98c5851-3fd3-4dc1-a75b-78c493d20095',
    'identification_number': 42396905,
    'item_consumption_1': 185372,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 23660.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4041c8af-ac0e-48ef-a2dc-1c0f7c848554',
    'identification_number': 12629,
    'item_consumption_1': 114,
    'item_description_1': 'UFER na ponta(Reativo)',
    'item_total_value_1': 38.69,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': '93c084c2-e3b9-4666-a287-92cd9703e3b5',
    'identification_number': 33688818,
    'item_consumption_1': 903,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 383.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb4825a8-5127-48d7-a67a-278ac66a24df',
    'identification_number': 51527987,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e49d3121-1884-4402-add5-eb9acba6eb8b',
    'identification_number': 41314352,
    'item_consumption_1': "'-12.958",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-4513.94",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '6522023e-0467-4c85-be97-861a622b18cb',
    'identification_number': 103764755,
    'item_consumption_1': 14,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 5.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '51da3711-c891-4b7c-9428-ac4387db058c',
    'identification_number': 31645313,
    'item_consumption_1': 2997,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 382.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3cd67135-6e37-48c0-b18c-17ac650d3c46',
    'identification_number': 52635260,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 5440.43,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '68729f39-4343-48f6-9324-d83cf49b55d2',
    'identification_number': 104265000,
    'item_consumption_1': 749,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 318.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e1d2d85-a677-4be7-b3c4-41f9f32390a6',
    'identification_number': 84702893,
    'item_consumption_1': 3638,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2124.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2e6baf8-704e-4c8d-8b7b-ead59aec9db2',
    'identification_number': 108653765,
    'item_consumption_1': 626,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 265.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42e08863-c788-4558-98a2-505c574ed347',
    'identification_number': 103886362,
    'item_consumption_1': 1168,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 456.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7d7c065-30e0-4560-acb5-f57d9ec661a6',
    'identification_number': 37645331,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6a72dcbb-cd1d-437d-b65b-2c0f0e4fb44e',
    'identification_number': 28151895,
    'item_consumption_1': 9649,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4096.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14a8f981-7028-49e7-87e0-09c57f50674c',
    'identification_number': 19452160,
    'item_consumption_1': 28036,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 42413.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ba11434-7931-468d-b3f2-a4d7bd98c351',
    'identification_number': 95633359,
    'item_consumption_1': 18.94,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 550.87,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3c4ac4e1-2c03-4295-b9f8-b21342b58b6d',
    'identification_number': 102404070,
    'item_consumption_1': 1178,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1782.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea764a9d-a84c-4771-9f40-1f485d262edf',
    'identification_number': 101477040,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '56e16d3f-51c4-4ffc-be08-c82c2b2b53bf',
    'identification_number': 110111184,
    'item_consumption_1': 208,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 88.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c18afa8f-b0bc-4afd-b303-cfea48398cac',
    'identification_number': 104499664,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1f2b8c3a-234e-4b17-bf82-7eac4019b933',
    'identification_number': 33355215,
    'item_consumption_1': 8427,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1075.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '705f4e80-8864-4c9e-aa62-0fcb536a67db',
    'identification_number': 42496390,
    'item_consumption_1': 39299,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14625.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1dfe21c3-8727-4646-ac7a-2f9b46fe4831',
    'identification_number': 107049910,
    'item_consumption_1': 25276,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3226.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c085bdd-b232-41e5-b422-bfbd5cdc85a1',
    'identification_number': 63896419,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '911630fa-6ca7-44a9-9a33-69e58b8534b1',
    'identification_number': 91516730,
    'item_consumption_1': 1692,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 718.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8782469-eff6-4939-8fc7-3d38ee0e92a6',
    'identification_number': 17697743,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 71.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '792e7626-7209-4aaf-bcbb-c2b1f2e8bbb9',
    'identification_number': 21718970,
    'item_consumption_1': 15524,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1981.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8adceed-d241-49f3-a422-b734556805aa',
    'identification_number': 108098680,
    'item_consumption_1': 29,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 43.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a40968b0-9e68-4cc8-ab35-b1fe8ba6c70a',
    'identification_number': 109785983,
    'item_consumption_1': 21.88,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 521.86,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '62239cdb-edb3-40d9-8c6d-b52f1c043db6',
    'identification_number': 32074123,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 116.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c171c5b4-240b-461f-a1f9-39b98100daf6',
    'identification_number': 77890043,
    'item_consumption_1': 491,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 742.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e19bbde4-f009-4c54-b61b-7e150db43cf4',
    'identification_number': 17619343,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1c373b00-714c-40a7-8951-ce6db304b4b6',
    'identification_number': 18270786,
    'item_consumption_1': 956,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 373.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c49e87b-87a8-4835-9d09-6db769a663b0',
    'identification_number': 96313323,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c399c16-2394-4673-8cbe-aed705fcaac0',
    'identification_number': 42699312,
    'item_consumption_1': 2146,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 837.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '415c656f-cb31-45d2-8a15-97b13f25768e',
    'identification_number': 62088416,
    'item_consumption_1': 1394,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 543.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65704b47-6acd-4a09-9f62-baab5730b1ea',
    'identification_number': 12908398,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 60.24,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c977efba-f2ff-4c0c-9c25-bdbc00caadee',
    'identification_number': 56525230,
    'item_consumption_1': 3.88,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 92.56,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '48aae2e7-bcbd-40d4-9235-9532261b3be5',
    'identification_number': 99778106,
    'item_consumption_1': 1325,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 517.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c7d2034-e837-4f27-a932-031b02be02ef',
    'identification_number': 15685420,
    'item_consumption_1': 176,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 266.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a157825-ecfe-44fe-9c18-f0473ccfa0fb',
    'identification_number': 13176846,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-7.5",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'ad74fa51-cc3e-4573-9ffa-d59a03948eee',
    'identification_number': 111114802,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '589961c7-fe20-499a-818b-714f428c0663',
    'identification_number': 14824558,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 25.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '96a9e3c0-e0a7-4970-9e4d-adee6a79ebff',
    'identification_number': 56526849,
    'item_consumption_1': 846,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1279.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2f0707c-f547-4ff2-af52-a49d98399699',
    'identification_number': 98508148,
    'item_consumption_1': 1960,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 765.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14a3dc8c-bbb0-4b05-bcb8-258e75463e17',
    'identification_number': 33760900,
    'item_consumption_1': 87972,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 32738.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de35d0cb-45d3-4fe2-ae6f-1bcfbca137ec',
    'identification_number': 15658660,
    'item_consumption_1': 12629,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5369.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60ae616b-a961-4e54-99eb-130a7f3562f0',
    'identification_number': 37229303,
    'item_consumption_1': 1798,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 764.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e882e125-2741-4aee-93fc-45a719eafa58',
    'identification_number': 79701710,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cf06e571-4ecf-41cb-88fe-6a6ff6445ace',
    'identification_number': 49096397,
    'item_consumption_1': 174,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 101.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f204183b-3859-4272-9c90-3e2be106a572',
    'identification_number': 107692015,
    'item_consumption_1': 19437,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 8250.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a69bbfa8-c7e9-4ceb-8dce-c6c8bd757776',
    'identification_number': 11236264,
    'item_consumption_1': 10298,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4011.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7a2ab48-cbca-4367-801e-6f0bf56e8a9d',
    'identification_number': 96801689,
    'item_consumption_1': 378,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 160.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98663d8b-e6df-40a6-a8d5-5ff9e81501e5',
    'identification_number': 97699217,
    'item_consumption_1': 242,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 102.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f00c66c-cb01-4c03-8ef1-7d03a3a95154',
    'identification_number': 106814427,
    'item_consumption_1': 650,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 254.33,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b56ba2a1-87f7-4eef-bd3a-6739ee285c76',
    'identification_number': 92638775,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 892.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59d01f9d-b06c-4883-a187-837f1dc0e208',
    'identification_number': 25868047,
    'item_consumption_1': 1340,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 522.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6668e86-9943-4be3-82c4-23f3ec427f82',
    'identification_number': 61481467,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 130.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aced6f28-e546-4b1f-bb17-f38188ffe245',
    'identification_number': 40918637,
    'item_consumption_1': 838,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 354.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd7fd823-481b-42f0-bdc1-198b59c98840',
    'identification_number': 81773021,
    'item_consumption_1': 445,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 188.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8d1aa10-bb0f-4e47-8812-45908696b11f',
    'identification_number': 15369080,
    'item_consumption_1': 8298,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1059.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4b1c768-131b-4609-a398-2dd8cea69eea',
    'identification_number': 21424640,
    'item_consumption_1': 9595,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4064.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00b579b8-e771-4866-8b5f-dcea2b36a56a',
    'identification_number': 103727434,
    'item_consumption_1': 2636,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1118.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57fd75f4-3b2c-4868-b548-45bc3bba73b0',
    'identification_number': 64643760,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28bb53bc-e5ba-49e4-9d32-e1ad74143986',
    'identification_number': 11013958,
    'item_consumption_1': 2872,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 366.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9485b49-905e-41af-841e-c2e313785a28',
    'identification_number': 51642654,
    'item_consumption_1': 626,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 244.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33e8429e-8ac3-4184-9bb6-9867cfde338e',
    'identification_number': 78334640,
    'item_consumption_1': 2401,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1402.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc90cbf1-ea77-4e4a-b769-bfe814acad0d',
    'identification_number': 14334399,
    'item_consumption_1': 48.8,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2786.96,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'abc00e07-5f9d-4dff-bbea-361c99eabfc3',
    'identification_number': 79644848,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66d5b7cd-1615-4427-9376-27e4ded07f15',
    'identification_number': 32140215,
    'item_consumption_1': 16626,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2122.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '711305d4-beca-4242-8099-c42a05bff6bb',
    'identification_number': 56886314,
    'item_consumption_1': 1691,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 987.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '954314b7-8c0b-4680-946d-4c4de8dc992f',
    'identification_number': 6037291,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 141.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '83869232-099d-4e74-83c3-5393cfed7350',
    'identification_number': 68441347,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8e9d33f4-d211-447d-899b-5a42eb7c85ef',
    'identification_number': 59059729,
    'item_consumption_1': 31031,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11548.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ed27ad3-96f5-4608-b8f4-341892edbb21',
    'identification_number': 93205589,
    'item_consumption_1': 399,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 233.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f282081-ae97-4ee8-ac8f-087ca50cf6bf',
    'identification_number': 17665868,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'de4dd4f2-ca38-4daa-91de-b60c2807d28f',
    'identification_number': 22922521,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 436.37,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'b4661d40-df0f-4d1d-9662-639d12e7dee7',
    'identification_number': 45907404,
    'item_consumption_1': 21.1,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 613.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '115eaf96-ddb7-481f-b7c5-205cf215bde6',
    'identification_number': 92912290,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5e67b13c-901a-4a01-9170-a9dde4825093',
    'identification_number': 42302617,
    'item_consumption_1': 8341,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3255.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c2ef1db-beed-4f1f-8e1e-b096922b2fa3',
    'identification_number': 40174220,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '508b8a84-2470-4ab5-be25-f38e38a0093c',
    'identification_number': 96292962,
    'item_consumption_1': 5262,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2050.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c92cde8-0891-4ae8-8338-11215a1654ec',
    'identification_number': 77771460,
    'item_consumption_1': 90,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 35.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d05504f-ec85-451a-a5d3-4b2cf811e63c',
    'identification_number': 95250670,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '34c23d9b-a8b4-4e4e-bd4e-2f8f89d3e351',
    'identification_number': 60065699,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69fb505b-e1fa-4c5c-aac9-34dcc1e16284',
    'identification_number': 110411196,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8dc41b4f-5d6d-41ac-ace2-334f4173976b',
    'identification_number': 89080637,
    'item_consumption_1': 11786,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5006.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e904ca0d-5169-474a-a658-8ba8c8039576',
    'identification_number': 105068730,
    'item_consumption_1': 589,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 229.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35d60dbb-b377-4c07-b0da-fb20c25e202e',
    'identification_number': 45546428,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e01752e8-9e4f-43a0-81c7-85ea293efb32',
    'identification_number': 106787020,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '31b40207-13b6-450f-994e-f447fb39c249',
    'identification_number': 110122160,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cdcbccb0-1daa-4352-9fdd-eddbd017a271',
    'identification_number': 102890269,
    'item_consumption_1': "'-8.639",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-2768.1",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '7a4ff0a3-4233-41f8-9afb-daebd88c5380',
    'identification_number': 20700776,
    'item_consumption_1': 11703,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4969.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '578a5479-cdc8-4809-9f97-814aaf746e6d',
    'identification_number': 54453844,
    'item_consumption_1': 7959,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2961.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4681e952-bd37-4a61-9aff-03b01fcd2696',
    'identification_number': 29701759,
    'item_consumption_1': 6013,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2548.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89e898fb-5041-431b-88d6-270a1f4ad240',
    'identification_number': 37608304,
    'item_consumption_1': 5813,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2463.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cfe2cc7a-f978-4f81-9e10-f9d47c52c6ac',
    'identification_number': 53573749,
    'item_consumption_1': 1.52,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 36.26,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6d6c5dd2-08a1-4249-8cdd-88aefb1a78f2',
    'identification_number': 91394384,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6600af48-bad7-4d0c-86c3-18425c5bd761',
    'identification_number': 41176111,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32a99f76-8e26-4f36-a566-ae68f10ed6d9',
    'identification_number': 14066017,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '213d6b7f-0cf8-4f20-abec-dc9c5f1b03fd',
    'identification_number': 103477217,
    'item_consumption_1': 773,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 327.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '686c4a67-2596-484f-a1bf-f64bd23e0081',
    'identification_number': 88094030,
    'item_consumption_1': 124,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 52.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c012213-9213-4c61-aaff-bf8f957b1e06',
    'identification_number': 109207882,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2e9363ad-5e7e-449a-a600-2fc4d389233d',
    'identification_number': 109034333,
    'item_consumption_1': 4866,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1810.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0cfc6cc8-4c59-43b6-a48f-56e3b608503a',
    'identification_number': 97455121,
    'item_consumption_1': 207,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 80.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa17fbe1-94a3-4c3c-83ae-d3dcf3d7ec98',
    'identification_number': 102362378,
    'item_consumption_1': 1905,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2881.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ab2461c-a5bf-45ec-b5bd-0b217ab23426',
    'identification_number': 59437650,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64c8105c-554c-4243-8a16-e1b5cf15883e',
    'identification_number': 32348533,
    'item_consumption_1': 6971,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 889.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4182a0b-20a3-41bc-bd1d-9d53e91a2842',
    'identification_number': 95259520,
    'item_consumption_1': 891,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 348.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31321f6d-c411-4b00-9308-2e8745870bd1',
    'identification_number': 106999060,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1b490906-3310-4f29-8241-3f3cc8ef5baf',
    'identification_number': 47075066,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '679ac5ca-7fd5-4567-8910-40b6b1f90db6',
    'identification_number': 92842216,
    'item_consumption_1': 1180,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 459.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73e92598-3514-4f5e-a8c2-2d4e44b9b7ca',
    'identification_number': 93490089,
    'item_consumption_1': 8873,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3757.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09322071-1f8f-4902-a597-757d1c1af33c',
    'identification_number': 20559755,
    'item_consumption_1': 13404,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1710.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e94ff0f-211a-4b04-8e5c-359ab0a29f54',
    'identification_number': 18182399,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9aa17daf-2587-4e67-8413-b35da586e4a1',
    'identification_number': 32528620,
    'item_consumption_1': 23131,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2952.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b903ac6-62da-4c64-9e62-be2281ff3a63',
    'identification_number': 58738428,
    'item_consumption_1': 479,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 187.41,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c4a07f70-fad6-479d-abbd-2d620b4ffcff',
    'identification_number': 98546627,
    'item_consumption_1': 468,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 182.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1148512-154b-4fe6-9c7e-1cdedc2ad66f',
    'identification_number': 38109280,
    'item_consumption_1': 3589,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2096.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5bbcd67-cd3f-4101-b474-ea11fe0e2a83',
    'identification_number': 13164546,
    'item_consumption_1': 2070,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 878,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bef0ec2f-92b0-45d5-91eb-7f855b524e3f',
    'identification_number': 5680638,
    'item_consumption_1': 52,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 20.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '517d1fa7-83b0-4a03-9dd9-3d8f07f19952',
    'identification_number': 29661129,
    'item_consumption_1': 10100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3935.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51cd4668-8d1c-4121-a5b2-f3b2c58eeb84',
    'identification_number': 108065006,
    'item_consumption_1': 16796,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6250.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e20b457b-21c1-4120-b7b7-518ec104ff67',
    'identification_number': 17777852,
    'item_consumption_1': 667,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 260.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72a43af6-91a6-4849-ba2a-4439e3084b01',
    'identification_number': 96849207,
    'item_consumption_1': 750,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 318.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16a3b119-ec3c-44b9-bccf-34abdf8b7e69',
    'identification_number': 94919909,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '205d5fa8-dd9f-4f95-a9f0-05ae405b5d8b',
    'identification_number': 14135795,
    'item_consumption_1': 1098,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 465.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '991d395c-f681-4e17-9f08-7eb2d95259b3',
    'identification_number': 10610464,
    'item_consumption_1': 652,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 253.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88571d0d-e1cf-47f7-b1e7-6abd354992d0',
    'identification_number': 17628318,
    'item_consumption_1': 2962,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1143.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '007afe29-2056-453c-9514-5f1b00fc22f4',
    'identification_number': 91519276,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3e28a47-6182-44f3-9acf-6a6c88f482a8',
    'identification_number': 12995061,
    'item_consumption_1': 132,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 56.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41d6be78-3e7a-4397-a927-3f9bfb8eb7dd',
    'identification_number': 10214143,
    'item_consumption_1': 960,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 375.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72c21309-fa95-4ece-8213-fbeeb7aa0881',
    'identification_number': 77542720,
    'item_consumption_1': 7974,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3380.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '347588d3-ec89-4813-9f96-c8c0591247e0',
    'identification_number': 107086638,
    'item_consumption_1': 3710,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1575.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77c536a8-2040-490f-b4ee-333109eabb02',
    'identification_number': 53330668,
    'item_consumption_1': 17.49,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 417.15,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '276f9696-2548-4166-9c7d-b5da079d2da5',
    'identification_number': 47312459,
    'item_consumption_1': 740,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 289.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb598c32-2c32-4034-9b86-fe6072b665f1',
    'identification_number': 97161667,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6eeb804b-7f3b-48d6-a9ba-7d56df5b83e2',
    'identification_number': 5910650,
    'item_consumption_1': 2093,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 815.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5dfbd59-bacb-4c59-8f10-22e07349543a',
    'identification_number': 15685420,
    'item_consumption_1': 3787,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 483.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca6471d5-e776-4bd7-a9c4-161db2418bec',
    'identification_number': 50876279,
    'item_consumption_1': 891,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 348.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e4cdaab-3020-4de9-a9c9-510533b44aca',
    'identification_number': 92294367,
    'item_consumption_1': 2978,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1262.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44b77281-c05e-4778-b302-e976dfb014ce',
    'identification_number': 101937792,
    'item_consumption_1': 1413,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 825.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9becbda-acee-485d-aedb-35835356a6d9',
    'identification_number': 102172803,
    'item_consumption_1': 1593,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 621.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfacb04a-4e5f-4be6-85ea-43fbb2e56318',
    'identification_number': 43338739,
    'item_consumption_1': 21.6,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 628.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1e9212e8-ba68-4c55-a434-cf9edfd4fe61',
    'identification_number': 7381956,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '686dea7d-bffa-4ce3-b160-bf67445ccfcd',
    'identification_number': 73581046,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e6686c42-1b62-4ec8-a6be-cc6eec493856',
    'identification_number': 31497861,
    'item_consumption_1': 0.27,
    'item_description_1': 'DEMANDA REAT EXCED USD PONTA',
    'item_total_value_1': 7.85,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '4b1ff35e-ba03-419c-9159-803e13552eaf',
    'identification_number': 98511467,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8510dcf9-8aff-47c3-90b9-0d3b37cf2568',
    'identification_number': 47567244,
    'item_consumption_1': 8807,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3733.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8076298-7628-489d-be31-4acda3538482',
    'identification_number': 16002016,
    'item_consumption_1': 10,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 154.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e3104b0a-eacc-44b5-902c-8200d56983ab',
    'identification_number': 32403011,
    'item_consumption_1': 51.26,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2927.43,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'cb78f6dc-2fcb-4297-8a36-fbc35422aa08',
    'identification_number': 105653691,
    'item_consumption_1': 15613,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1992.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97b2dae4-e6a0-4c12-8b0b-16c228437413',
    'identification_number': 39961320,
    'item_consumption_1': 1132,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 661.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de6892a0-2777-4e28-b0ca-168277f7904d',
    'identification_number': 107833220,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea355fff-9d34-474c-aa56-b9be1b7a962f',
    'identification_number': 105797634,
    'item_consumption_1': 793,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 463.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1bd5374-9b4b-4d66-813b-3fab85282afb',
    'identification_number': 100797075,
    'item_consumption_1': 7086,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2637.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92fe01f6-9da8-4d6e-8556-fae5d205f480',
    'identification_number': 53371127,
    'item_consumption_1': 54094,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 20130.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64794e78-6b10-4fcd-bdaf-051ee92ac87b',
    'identification_number': 101260890,
    'item_consumption_1': 3400,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1325.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '433b6cf7-b7bd-48b9-8028-2060c851da72',
    'identification_number': 53747755,
    'item_consumption_1': 99,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 38.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bca9ccde-99bc-4fd0-b8eb-39c13033ebad',
    'identification_number': 109782909,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bdecedce-e7b2-4b44-8737-9e2f000de0f5',
    'identification_number': 98005138,
    'item_consumption_1': 1348,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 527.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7906f7d7-48f4-4d9b-b4b2-66337a19b35e',
    'identification_number': 99837188,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-16.68",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '6661823d-d062-4aac-a935-51559f981329',
    'identification_number': 55826539,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '353a2be0-9f3b-41e0-9ea3-c63715de321b',
    'identification_number': 93133774,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fed37f53-a7cd-458c-86cc-cd33c740c2b2',
    'identification_number': 104137436,
    'item_consumption_1': 222,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 86.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e866332b-7e30-41b3-ac19-2104cc768523',
    'identification_number': 75768879,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '93c12275-78ef-44eb-91da-ad0cbce9aeba',
    'identification_number': 45017336,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0db02a22-273b-4668-b677-c65227573c68',
    'identification_number': 103561404,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a6ce7ef3-0df0-4648-9bea-1674d2d646f9',
    'identification_number': 29046092,
    'item_consumption_1': 6154,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2614.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '614a3c81-0772-41cc-ad34-e6da52e86ca5',
    'identification_number': 110227131,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee98c486-3093-4304-b71e-855a5a289933',
    'identification_number': 107049910,
    'item_consumption_1': 47.03,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1367.9,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '022614a7-617c-4617-83dc-b5964a5bdc0a',
    'identification_number': 11013958,
    'item_consumption_1': 134,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 52.42,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '05487f81-5fe5-4da4-95d0-f3caef48a121',
    'identification_number': 110007948,
    'item_consumption_1': 139,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 54.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b62be160-df40-4a26-bd2b-79eac42a154a',
    'identification_number': 65626079,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-117.16",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'f574330a-ee2b-468b-bcf4-b60cad11f2ec',
    'identification_number': 42627117,
    'item_consumption_1': 0.54,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 15.69,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'bf16944c-552e-4e58-965b-83908002ae83',
    'identification_number': 106861182,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 92.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2c0ab81f-260e-4e9b-a456-75484adf8e07',
    'identification_number': 25318535,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18734db1-c656-404c-ba34-04ead1560790',
    'identification_number': 103475494,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b9e76cf-a641-46de-ae43-bcd1b924e5c5',
    'identification_number': 107049910,
    'item_consumption_1': 2.97,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 70.84,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f70d16d6-16ff-46ac-8537-a7e2169d265c',
    'identification_number': 19295855,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 077/150',
    'item_total_value_1': 26.42,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '76a92391-965c-4d58-8e65-b7ed4ba9669e',
    'identification_number': 58737863,
    'item_consumption_1': 13233,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4924.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51e964fb-4646-46ff-81bd-450b425cd286',
    'identification_number': 82521310,
    'item_consumption_1': "'-2.721",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 06/2023',
    'item_total_value_1': "'-870.13",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'ffc8d68e-aeea-4903-b66e-2fa683b982d6',
    'identification_number': 82724873,
    'item_consumption_1': 13945,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1779.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4604686f-e53b-4065-8a40-6d67fac3a3b6',
    'identification_number': 110554329,
    'item_consumption_1': 2091,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 888.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '465b3817-e538-48cb-b54f-d12ad15a2421',
    'identification_number': 36721697,
    'item_consumption_1': 216,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 91.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '923915d0-4fca-4c56-8cdb-6c7d4013e7b0',
    'identification_number': 57946116,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24f36094-a906-48d5-84a4-69f2157a9ee8',
    'identification_number': 32234058,
    'item_consumption_1': 77.04,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4399.74,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '9c146044-d9f0-4f86-81f6-2bbf0ef3c256',
    'identification_number': 33620717,
    'item_consumption_1': 5653,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2398.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38c79e5c-32a9-4927-a491-e8dd96ff2595',
    'identification_number': 17394244,
    'item_consumption_1': 2970,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1261.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95f1c7be-f467-423f-b958-79d59fba954b',
    'identification_number': 39214699,
    'item_consumption_1': 45.11,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1312.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6026717b-b7ca-4100-b0a5-f4ffacc7ebba',
    'identification_number': 17726425,
    'item_consumption_1': 1711,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 669.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa362844-0d77-4d7d-9343-d158e1cf9a2c',
    'identification_number': 42089808,
    'item_consumption_1': 63309,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 23560.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3c8c44e-5f75-4e77-9458-3673334b56e7',
    'identification_number': 106568485,
    'item_consumption_1': 8275,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3517.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b27576b3-0e2d-4182-9b0c-f42075124e40',
    'identification_number': 48347728,
    'item_consumption_1': 1712,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 669.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9dc4db8a-9444-4ef7-b70b-55b47804bb02',
    'identification_number': 91394031,
    'item_consumption_1': 93,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 36.37,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b33e5bd3-e3ee-4527-a2be-ea8f29b8a6cf',
    'identification_number': 106851217,
    'item_consumption_1': 933,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1411.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63cbdf08-24b1-4c42-82e4-fb10b41612f1',
    'identification_number': 15091520,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b4873ae-dc8e-4539-98c1-60140f1d6dfd',
    'identification_number': 78962188,
    'item_consumption_1': 66.46,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1933.04,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'df64552c-c186-4def-8b36-b643fc166534',
    'identification_number': 57046450,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2350bca9-c3b4-4a09-92c6-48fd8ac67d44',
    'identification_number': 102339929,
    'item_consumption_1': 2551,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 994.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd1d5fb9-600b-4857-b6a8-0498d37d6623',
    'identification_number': 91711630,
    'item_consumption_1': 87,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 50.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d691e7c-140d-4d60-a2e7-6982192b31a9',
    'identification_number': 97630926,
    'item_consumption_1': 784,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 332.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '110f5d13-acf1-4fed-80d1-f551e5eb09ab',
    'identification_number': 104871563,
    'item_consumption_1': 1553,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 907,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6858ccaa-d0ad-48eb-8c3f-9d93bb92811a',
    'identification_number': 56886314,
    'item_consumption_1': 1691,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 215.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1a5fddf-4458-469a-9947-1c3f5643e332',
    'identification_number': 110457757,
    'item_consumption_1': 549,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 213.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98f2a389-eb05-45b7-9b81-baefc853353d',
    'identification_number': 100728391,
    'item_consumption_1': 416,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 176.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15c9d7d2-ea0c-4423-8f3f-72eec142ba02',
    'identification_number': 108065006,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ba9c9b3b-5a05-4cff-8719-1dc601eda2c2',
    'identification_number': 14334399,
    'item_consumption_1': 28658,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10664.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a54d6b1-9fe5-4589-8ce8-5da4e06015d4',
    'identification_number': 10379444,
    'item_consumption_1': 5827,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2279.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ed1dbaa-353e-4a02-af51-c4127932b874',
    'identification_number': 108400034,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cccb1efc-3744-4649-8636-545c9e6763df',
    'identification_number': 42264316,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e05918f6-9ef1-4211-98f6-65ae161efc7f',
    'identification_number': 13178032,
    'item_consumption_1': 11292,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1441.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51b2ff47-e104-4bd1-a266-b230c074fe5e',
    'identification_number': 14619563,
    'item_consumption_1': 135.79,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 7754.95,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8cf3efcc-14f4-4373-b7ab-b368cb93d55d',
    'identification_number': 95597727,
    'item_consumption_1': 12460,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5293.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0cf88f08-ac70-4aa6-a2f4-32e07cead481',
    'identification_number': 84156171,
    'item_consumption_1': 19021,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7078.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2b0ce1b-dd19-417f-84a0-dd9cfb6c4139',
    'identification_number': 3030777617,
    'item_consumption_1': 9777,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 4938.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2213aa6d-2d32-42b0-b0d5-54010687baef',
    'identification_number': 110992393,
    'item_consumption_1': 2425,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1028.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25df06c7-3a3d-4fc1-b4e1-aef1d9531776',
    'identification_number': 101138717,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-22.84",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '66102225-c652-43d0-877e-d5c5598f8ada',
    'identification_number': 56697198,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '256f9da8-beb7-4b77-bd2c-cca4f3d01de2',
    'identification_number': 110922751,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 64.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '33249473-a2d6-4b0a-9fe2-5f1563ba3de0',
    'identification_number': 32627572,
    'item_consumption_1': 182,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 71.2,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f4c3fc50-80a4-4bc7-b442-a6b5de4868dd',
    'identification_number': 70574502,
    'item_consumption_1': 106.71,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3103.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ba38d24c-3651-47c4-be8b-c2aa3dd8c37b',
    'identification_number': 17740738,
    'item_consumption_1': 103,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 43.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86992b27-8d1c-4613-bcee-803b5c96494d',
    'identification_number': 15214133,
    'item_consumption_1': "'-1.027",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-328.03",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '3008612f-4c2d-4edf-a7ab-59a5054d29b4',
    'identification_number': 110227123,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d4ee4c8-c30d-4035-8c51-aa677f4b30b1',
    'identification_number': 56699077,
    'item_consumption_1': 5157,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1919.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de8a42dc-6ef3-452c-8d85-5460948168db',
    'identification_number': 58745785,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a95bb8fc-6042-4a97-8b0f-c0581d911317',
    'identification_number': 49706152,
    'item_consumption_1': 2844,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1204.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a3bbdd3-3270-4a10-85fe-0cae189b96af',
    'identification_number': 44606931,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.97,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c27df07f-818c-406d-a102-abbcb0170db5',
    'identification_number': 45546428,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c8fde290-275a-4e96-8edd-bfd21b8c961a',
    'identification_number': 6167187,
    'item_consumption_1': 266,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 112.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ec8089e-af84-4863-93e0-c5c736784ceb',
    'identification_number': 41456521,
    'item_consumption_1': 1256,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 534,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d3f14d3-e194-430f-ac1f-f6e8ebdec198',
    'identification_number': 16677412,
    'item_consumption_1': 2483,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 316.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af3d9f2f-90f3-46b5-9b9f-b477cce70cb8',
    'identification_number': 48018660,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-7.65",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '582f0672-b78b-482e-a1b6-2f62b645cb05',
    'identification_number': 111740797,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3f3e1a2-4ad2-4609-9f15-192b072a1edf',
    'identification_number': 62072137,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.3,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2056a1e5-8151-49f9-a857-4e43b0b4046d',
    'identification_number': 32495056,
    'item_consumption_1': 45829,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 17055.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68ee5b46-5816-4a3e-a682-7c73ad91623e',
    'identification_number': 106104438,
    'item_consumption_1': 1343,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2031.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4ba63aa-cee2-4595-bc19-74628d943223',
    'identification_number': 23385731,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90434429-6ac9-4769-8359-9d3407200a2b',
    'identification_number': 102940746,
    'item_consumption_1': 34.98,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1017.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c06d89db-dd0d-4438-9d39-df08f64f827d',
    'identification_number': 40848302,
    'item_consumption_1': 4992,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1947.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa314cca-4026-4ace-9950-afdbedacf4ad',
    'identification_number': 41490533,
    'item_consumption_1': 23740,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 35914.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d41400d-7347-480d-a9f6-d59005180dfb',
    'identification_number': 72702141,
    'item_consumption_1': 111759,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 14264.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3bacecf4-2e36-4682-8b16-3748ffd328c6',
    'identification_number': 75789981,
    'item_consumption_1': 35738,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4561.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a4ea16c-7100-47ea-ba49-8285b5218c97',
    'identification_number': 31497861,
    'item_consumption_1': 10061,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1284.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6529c00-1d6b-4407-b6ac-b8d4dae10a23',
    'identification_number': 14562545,
    'item_consumption_1': 1038,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 132.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '313be111-2915-4cfb-9977-347598ee97a0',
    'identification_number': 99807483,
    'item_consumption_1': 3298,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1286.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9e3f528-6181-44b0-a5c8-4e0335f447a5',
    'identification_number': 37998447,
    'item_consumption_1': 889.92,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 50823.32,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '92d06332-a784-4a53-bce2-a0f7372ddb5d',
    'identification_number': 3031341111,
    'item_consumption_1': 6309,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 1882,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f330994d-d3bc-4895-8e56-4372f6008125',
    'identification_number': 33676208,
    'item_consumption_1': 62.38,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3562.51,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '28201d0c-bade-4cdf-ae1e-5734d5224a10',
    'identification_number': 105849944,
    'item_consumption_1': 812,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 474.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68c85fce-b7fe-4911-b201-92f8fcf6d8ad',
    'identification_number': 14625415,
    'item_consumption_1': 1060,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 413.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '271fe0a7-e909-440b-b14a-757a5f1549c7',
    'identification_number': 99211823,
    'item_consumption_1': 3068,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1791.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48dcdedf-2f8d-4029-924a-2ddc5c3f3761',
    'identification_number': 102765707,
    'item_consumption_1': 52,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 22.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da36c7b7-45af-4435-bda5-646fb04f27b2',
    'identification_number': 22905171,
    'item_consumption_1': 1.741,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 660.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a29246bb-d8b6-4744-928b-e0a52e0b2b2e',
    'identification_number': 22640061,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '66355585-7066-4ee1-8f1a-77e32926262b',
    'identification_number': 43484506,
    'item_consumption_1': 12352,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1576.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f8fd09c-ac58-4c99-aaa3-1129e9423c96',
    'identification_number': 44476256,
    'item_consumption_1': 24.87,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1446.73,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0ddf1d71-82d5-4acc-ae09-7c8072c92aec',
    'identification_number': 62515438,
    'item_consumption_1': 21.47,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1248.95,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '9bdab812-95f3-4ec7-82c9-5dafee21b6a1',
    'identification_number': 19451512,
    'item_consumption_1': 582043,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 216605.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c1d3645-6d10-4f2b-b28b-b1ad9b56d3ef',
    'identification_number': 69572968,
    'item_consumption_1': 14.62,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 348.71,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5ea645d1-8db5-4937-a452-8a204f1362aa',
    'identification_number': 9612297,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f2c49075-3038-42fe-b6c0-486509ed8107',
    'identification_number': 16699696,
    'item_consumption_1': 14574,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1860.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd092bc65-5d45-437b-9c7e-fc081c16bbaa',
    'identification_number': 33800677,
    'item_consumption_1': 3148,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1171.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e8191d1-5c1b-4b14-a957-caae0190f388',
    'identification_number': 109419944,
    'item_consumption_1': 432,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 182.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2847e83a-521c-4919-8cfd-ff8dbe2cbfc5',
    'identification_number': 62391453,
    'item_consumption_1': 2712,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1583.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f7063b6-c068-4d94-88b2-34d2911926fe',
    'identification_number': 4003474899,
    'item_consumption_1': 1238.8437,
    'item_description_1': 'Consumo Ponta [KWh] TUSD NOV/23',
    'item_total_value_1': 2187.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24c18e8b-e45e-4477-99d3-7b5fc3a0db50',
    'identification_number': 30674620,
    'item_consumption_1': 8719,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3409.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c28fe47f-a599-4817-b7fb-888f62153f30',
    'identification_number': 19302711,
    'item_consumption_1': 23532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 9205.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e347e312-10dd-4ef4-b501-a14a3cf9633b',
    'identification_number': 106759280,
    'item_consumption_1': 5310,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2074,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '249913ac-d930-473e-8f8f-b27744387cd0',
    'identification_number': 103358960,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '417b23e0-c2b2-434f-9a95-d71f16468774',
    'identification_number': 34680292,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ff1340f8-f4b5-46d5-9317-6ba941a1895c',
    'identification_number': 79665306,
    'item_consumption_1': 7273,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4247.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea09acb1-7c24-4221-9e1a-dceda5f8af25',
    'identification_number': 67030165,
    'item_consumption_1': 2218,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 283.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe8c5760-ff16-4313-8ccc-f4c9dd069844',
    'identification_number': 92334067,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-1183.51",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '20a31c0f-494c-4aee-9d1d-53b06eb6b795',
    'identification_number': 42871034,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3ff1c01-ba73-42f5-85f7-e9d07ddea869',
    'identification_number': 12673994,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 62.26,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c4a1294a-6a5d-4cdb-a434-916d4caecc1d',
    'identification_number': 23088460,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ead72d70-e2a9-49b7-8aab-9be85a9430c5',
    'identification_number': 15093093,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '16a26c22-cd41-4f64-afdb-d4458f4e74b6',
    'identification_number': 19451610,
    'item_consumption_1': 3.04,
    'item_description_1': 'DEMANDA REAT EXCED USD FP',
    'item_total_value_1': 88.4,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '32daec4d-7e91-4908-84b5-f2f0a6ff0345',
    'identification_number': 109993217,
    'item_consumption_1': 2888,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4369.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '585cdd94-1c21-4510-adbc-b2f9ea600c0b',
    'identification_number': 107922983,
    'item_consumption_1': 409,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 173.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ad03d56-9d08-47c5-b472-712585d659af',
    'identification_number': 105470708,
    'item_consumption_1': 1568,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2372.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c9d1053-ca5f-4809-90cc-a22671a75c69',
    'identification_number': 95765840,
    'item_consumption_1': 1319,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 770.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b9f9275-b60c-4898-90cd-4fa00c002957',
    'identification_number': 56168748,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9f85c31f-b0f0-46e7-8d43-260d9c27841c',
    'identification_number': 100728294,
    'item_consumption_1': 716,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 303.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fead0f59-2903-4db0-af1e-e52de0fa56bb',
    'identification_number': 9790519,
    'item_consumption_1': 3181,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1347.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c196cde0-eada-4f70-95a5-48486bcb55a0',
    'identification_number': 91392454,
    'item_consumption_1': 46799,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5973.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7718058-7a39-4db4-88ba-be00b04616eb',
    'identification_number': 46006583,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73de4243-9087-48c2-9ed7-d93324bd2109',
    'identification_number': 101890419,
    'item_consumption_1': 76,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 9.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea3b25c0-89cb-4763-8a38-3b68fc3b33d8',
    'identification_number': 61283851,
    'item_consumption_1': 3361,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1424.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e99d02d-46bf-4748-982f-f0cd0ecb3efc',
    'identification_number': 108597199,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd315e339-2f46-4b42-b0fe-56fb7e37a570',
    'identification_number': 37612220,
    'item_consumption_1': 493,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 192.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1575d6b7-e45e-4a62-8997-31f40e6b6b06',
    'identification_number': 13789414,
    'item_consumption_1': 113,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 170.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e4b0afa-a723-4565-b8de-3e6c0d8724de',
    'identification_number': 102217750,
    'item_consumption_1': 619,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 241.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b12c41b-66c2-4e33-817c-cc9db2567ce1',
    'identification_number': 98938622,
    'item_consumption_1': 1280,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 499.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e4b6f33-03ae-488b-a26f-da9f811829e6',
    'identification_number': 40207650,
    'item_consumption_1': 2.57,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 61.31,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4e2ff16d-70dd-44b0-8a14-96bf5589d842',
    'identification_number': 80817610,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ef9c7233-a2f6-4b21-a8df-ad8e65eb6fe4',
    'identification_number': 98015052,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb5763ff-cf3a-4fb5-805a-8166da4ba2d3',
    'identification_number': 40100812,
    'item_consumption_1': 8264,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3222.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ada2552b-897d-4d74-b32b-651ceea5eb73',
    'identification_number': 31900852,
    'item_consumption_1': 39498,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14699.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69626ecd-4270-4360-966b-4a9d4493a9e6',
    'identification_number': 42433223,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0d362071-e77d-4084-b78c-5c55d90d2eda',
    'identification_number': 98105132,
    'item_consumption_1': 846,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 358.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00fdfa77-7c1b-4763-be95-396ea29d8195',
    'identification_number': 109493664,
    'item_consumption_1': 432,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 182.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f338ee88-a2fa-42d0-a856-035226dfd9ac',
    'identification_number': 99258404,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '347c23ad-c1d1-4d91-a445-f9c15f21f278',
    'identification_number': 68904401,
    'item_consumption_1': 7.12,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 414.18,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e0c94144-3194-4143-967a-c29eb2084f0b',
    'identification_number': 56493371,
    'item_consumption_1': 6.54,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 155.98,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7fbf09e4-a848-4e0d-8c4b-49dc6bcffdef',
    'identification_number': 27316114,
    'item_consumption_1': 153.11,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4453.35,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0e8bea47-ecd2-4deb-9c67-dbc27ee77c25',
    'identification_number': 35685948,
    'item_consumption_1': 57,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 22.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ac51b8f-d1a4-4a80-a7ef-4125a43fcd26',
    'identification_number': 108153746,
    'item_consumption_1': 6301,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2454.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e76311e8-24ec-4c92-adc1-9c59637dba9a',
    'identification_number': 51527987,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f5bd6fe0-abc5-4788-8914-f76252de8194',
    'identification_number': 38052687,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1ffe8a65-75fa-4739-aadd-1e3bdff42265',
    'identification_number': 110227379,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9bf69eb-d137-437a-b7cb-ac9bcd245b6e',
    'identification_number': 18643582,
    'item_consumption_1': 6.49,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 303.94,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '98996a9c-2c9f-4135-918a-38c6a0a6d544',
    'identification_number': 28259513,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cdbc42a9-83a2-4f03-a97c-d1d0d0f56db8',
    'identification_number': 22424725,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1518cbb0-9c36-4527-bd91-adabd372fa2f',
    'identification_number': 13789414,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '44ffb31f-060c-49ce-ba7b-ccf923f61489',
    'identification_number': 67030165,
    'item_consumption_1': 2218,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1295.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c25255ef-63f2-49b8-b9ef-2bdf73040115',
    'identification_number': 23667966,
    'item_consumption_1': 223,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 94.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fea27fb0-ea9c-4abd-a2e0-089c5a47f669',
    'identification_number': 45483779,
    'item_consumption_1': 180.21,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 5241.56,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b70039ef-8ea3-4fa6-b36f-2cde255ec1c5',
    'identification_number': 10813993,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 152.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ccaed496-2b4a-4dc5-ade2-0085e09a8177',
    'identification_number': 80671551,
    'item_consumption_1': 12439,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4851.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0be46516-9839-4d09-baff-d18280c2dbcf',
    'identification_number': 51182459,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 10,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '62cbf4f1-cc9b-426c-a9ae-ee61441d3fc9',
    'identification_number': 17353173,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '28795555-c6e5-432c-8009-404d7599ef2c',
    'identification_number': 94724741,
    'item_consumption_1': 646,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 977.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3db9739-addc-4ec2-b479-7cf26d5cbbfe',
    'identification_number': 6164536,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '35f821d1-6ecf-4254-8acc-9994f10a0e93',
    'identification_number': 85205672,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 19.56,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7c35389e-46c6-45d6-9e7d-5bf0c1e7ab3f',
    'identification_number': 107939380,
    'item_consumption_1': 187,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 79.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e867812-521e-4657-a1b4-13bf012d01d6',
    'identification_number': 106583840,
    'item_consumption_1': 1207,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 470,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0d3ee4f-fbbc-4e13-bec8-c6c8d9bb782b',
    'identification_number': 59059729,
    'item_consumption_1': 71.38,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2076.16,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1f7bee6a-523c-447d-8fd1-9758af0c95d1',
    'identification_number': 94650977,
    'item_consumption_1': 6.68,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 159.34,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ed94ec80-ac33-4bf3-85fe-fcfaf14fcf8f',
    'identification_number': 44436637,
    'item_consumption_1': 38742,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14417.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bfda3d5-1971-4d81-88a8-8752facbf199',
    'identification_number': 69572968,
    'item_consumption_1': 5084,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 648.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23ef76c8-f9ba-4e46-b4b5-5adb427b3948',
    'identification_number': 29935091,
    'item_consumption_1': 1479,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 628.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c0fa8fc-4041-482e-8a3f-760c2f125541',
    'identification_number': 35685743,
    'item_consumption_1': 90,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 35.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24a83561-529f-458b-93ae-e5d5a1703667',
    'identification_number': 18187749,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6c0a9ef-bc72-47d1-862d-dce6fa853f4b',
    'identification_number': 99082179,
    'item_consumption_1': 295,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 125.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c64e631-067f-4a89-822d-263b2a195a1a',
    'identification_number': 42699312,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eaab2fc6-2509-43a7-8357-8539349b9832',
    'identification_number': 17962552,
    'item_consumption_1': 2040,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 796.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7370c0c1-4dac-4248-9f1f-93bc2fbab530',
    'identification_number': 30035465,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56e8a820-be11-4a4c-a695-2564eddf1fbd',
    'identification_number': 35766514,
    'item_consumption_1': 1236,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 721.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7cfddc2-557e-4dd2-8d69-db0eaa975267',
    'identification_number': 68385447,
    'item_consumption_1': 53.82,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3073.64,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5819a6a1-c126-43ab-8d86-a1f274aaa903',
    'identification_number': 103876502,
    'item_consumption_1': 1748,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1020.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0443726e-ed94-40a3-81a2-ae4bb182b38d',
    'identification_number': 97742236,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b90e4af2-ddb2-456e-b8f6-aab9bd0bbab1',
    'identification_number': 14660601,
    'item_consumption_1': 2,
    'item_description_1': 'Demanda taturada',
    'item_total_value_1': 94.83,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '51622a8f-e33e-4193-b261-d482029d02a9',
    'identification_number': 109482590,
    'item_consumption_1': 366,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 154.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c548555-693e-436e-b99b-a83a4c79e5c2',
    'identification_number': 12866,
    'item_consumption_1': 21,
    'item_description_1': 'DEMANDA ISENTA DE ICMS',
    'item_total_value_1': 765.24,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'de7ab533-f097-4b26-ad59-dc69a00d8e18',
    'identification_number': 16570936,
    'item_consumption_1': 3471,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2027.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e82ae8d-f2ca-46ff-b097-255b285e3725',
    'identification_number': 11201541,
    'item_consumption_1': 1188,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 504.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfc0401a-dc54-490b-bf1e-c89a2c6e0062',
    'identification_number': 19452306,
    'item_consumption_1': 153208,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 57015.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a79f2eb8-d1d7-47df-adac-640b199211b8',
    'identification_number': 36648329,
    'item_consumption_1': 27.15,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 647.55,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '23c555b8-0db8-4585-b7cb-052d7a95143f',
    'identification_number': 103592458,
    'item_consumption_1': 1739,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 680.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96b70887-6499-4a32-b2bc-dfa6b7ec9bf2',
    'identification_number': 110228278,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '147521d7-611c-493f-a273-9ed4f99a1468',
    'identification_number': 936300028,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 26.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bb1473d-a827-42db-a755-c11fd95acf2e',
    'identification_number': 109675185,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b0d5696c-7940-4825-aca8-bcc48ef06415',
    'identification_number': 20994206,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a38eef4-36c4-424f-b216-41f83e6ede12',
    'identification_number': 80268250,
    'item_consumption_1': 1010,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 393.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8ebd77e-17a4-4a7f-851e-3f6df8c2cf19',
    'identification_number': 72092556,
    'item_consumption_1': 17.81,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 518.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '499c30e2-2ee3-49b3-a9c1-7836835317b9',
    'identification_number': 19312326,
    'item_consumption_1': 5699,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2416.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57a01668-7c6a-44f4-8b40-8e69e025dd19',
    'identification_number': 91798965,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e451dac1-089e-42f2-acde-51ae689af1f0',
    'identification_number': 81037600,
    'item_consumption_1': 40620,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15116.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af8cd9bd-b586-4fcd-8f9d-981adc8acbaa',
    'identification_number': 92334067,
    'item_consumption_1': 9031,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3825.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46ac030a-e868-43ad-b2ae-51af03735b44',
    'identification_number': 15130185,
    'item_consumption_1': 755,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 320.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca21314f-784f-4528-a772-8f614e444d6d',
    'identification_number': 96026782,
    'item_consumption_1': 11169,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4156.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8ed3935-a46d-40a9-9bf9-571cbde39577',
    'identification_number': 59438592,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd89529dd-343a-4da9-b326-183cc66effad',
    'identification_number': 40855856,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9ce3f34-a81b-4ded-b2ae-9d7c05b6129f',
    'identification_number': 32151179,
    'item_consumption_1': 241.27,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 13778.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '60f2e655-dfcf-46bd-a283-6894c503d7e5',
    'identification_number': 56526580,
    'item_consumption_1': 5110,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1901.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f56d2de-ce29-4185-af76-f7d96dfb576b',
    'identification_number': 104329530,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00ccc270-ad61-4d0b-ac27-219a499a8320',
    'identification_number': 87457091,
    'item_consumption_1': 1488,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 631.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0388b319-98e3-4cfe-8d66-61abdc3dee5f',
    'identification_number': 56705514,
    'item_consumption_1': 9654,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1232.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29dd7e89-51a0-464f-b780-a38becaf0baa',
    'identification_number': 70485070,
    'item_consumption_1': 20214,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2580.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d469327-d777-4cf2-a644-00c540778809',
    'identification_number': 97063800,
    'item_consumption_1': 15514,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6060.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6a25a3a-57a7-47ab-ac4d-ca059ea4f8e5',
    'identification_number': 95733086,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea297346-946e-4fa6-93d3-757abdbbd06f',
    'identification_number': 94502706,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 84.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '67685def-5351-4187-9323-b7dde934b752',
    'identification_number': 52358259,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8ad0a891-c723-4131-acab-0387e844b7bb',
    'identification_number': 67097898,
    'item_consumption_1': 13973,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1783.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5882edba-ca79-4c4e-949e-dd1bef885586',
    'identification_number': 36149730,
    'item_consumption_1': 1811,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1057.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd29f681b-4797-4a7c-8812-bbbb200fb41c',
    'identification_number': 81777302,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 70.73,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e78cec02-80bd-42f8-9988-5aaf10988e96',
    'identification_number': 5574889,
    'item_consumption_1': 6910,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2701.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d7c13d1-6e9c-49a0-b1df-1c74605889a4',
    'identification_number': 92158064,
    'item_consumption_1': 50.97,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1482.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5049b3c9-5ce9-4cf5-bb1d-b454c5cb39dd',
    'identification_number': 27316114,
    'item_consumption_1': 9.11,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 529.94,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'e8432796-975d-410d-86a5-e0a617f83410',
    'identification_number': 30199824,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2bc28d0-d70c-48b4-b29c-389d38af0ef4',
    'identification_number': 94029539,
    'item_consumption_1': 21804,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2782.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33be6542-ef2d-4f1c-94f9-84b4923f0878',
    'identification_number': 96256443,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bda1c4a3-84be-4770-a8bf-6b748afd4488',
    'identification_number': 101890419,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79b60c79-1847-49fe-bc90-7ec7c8185362',
    'identification_number': 2727684,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c1b4a7c7-c439-421a-9ba0-c49a6d35298e',
    'identification_number': 21718970,
    'item_consumption_1': 2.98,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 173.35,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ee050185-69b0-4cec-aada-bbda048794fd',
    'identification_number': 96383038,
    'item_consumption_1': 4529,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1920.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c58dea2f-bd2c-4180-8da1-6032e56c4275',
    'identification_number': 39152537,
    'item_consumption_1': 5693,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2223.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb0a741a-c9bf-4604-a27b-e94154ee9d2d',
    'identification_number': 38052687,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49786e2e-724e-4eeb-bbdb-43a0c6d903c0',
    'identification_number': 77819047,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dd26e47e-3f11-4fc0-86f5-90b32351a55f',
    'identification_number': 58755748,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccc4c78d-9c2a-4b41-b7a6-407d3c7a2bf9',
    'identification_number': 37781456,
    'item_consumption_1': 6791,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2646.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4cbf3da8-d399-4626-8e0b-e1e02511cfca',
    'identification_number': 6092578,
    'item_consumption_1': "'-10.368",
    'item_description_1': 'ENERGIA IN.I. OUC MPT TE 11/2023',
    'item_total_value_1': "'-3316.54",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'a7168ae5-c47f-4c6f-9636-d73824fd21d4',
    'identification_number': 108628990,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0b71ed1-5d1f-4751-b4ba-46db64fac4f3',
    'identification_number': 47129271,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '046e110b-68c1-4c8a-8d67-336c67a54a25',
    'identification_number': 81506740,
    'item_consumption_1': 9848,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3851.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '900f2909-1cb5-4bdd-9032-f58c07683e43',
    'identification_number': 46920617,
    'item_consumption_1': 7618,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4449.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b932388-09e5-4b0b-8729-fbc713b637ef',
    'identification_number': 56877021,
    'item_consumption_1': 19.4,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 564.25,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '29c86527-c4d9-415a-98c5-469debc64b75',
    'identification_number': 39672131,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4e7958ef-64cf-4024-9aa2-842bfd30c364',
    'identification_number': 33921393,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c29a8071-7d80-47c0-a89b-0ca430be38fc',
    'identification_number': 82744181,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '367ea40b-92da-4ecf-aadf-ce9afa9f1492',
    'identification_number': 62304208,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b52353e-5d0d-4a31-a541-27ffb41d9598',
    'identification_number': 101835981,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e5b414fc-42a2-48d7-9e64-84de2b6c4a8f',
    'identification_number': 8115567,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4ee88802-9285-40a2-b33c-f043835c5d92',
    'identification_number': 107977915,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1d0ef5ab-96a3-439f-9a3b-d2c8e27b57b7',
    'identification_number': 71131051,
    'item_consumption_1': 4530,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1767.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '843431b7-6a5d-4d06-8caa-371fa2ff3c8b',
    'identification_number': 30015570,
    'item_consumption_1': 2312,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 979.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32a7d2da-d0ca-4ce9-8115-261daf629e03',
    'identification_number': 52131718,
    'item_consumption_1': 6.21,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 148.14,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '046582f9-568f-46fa-a805-4e4ebc0ab6f6',
    'identification_number': 97079006,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 59.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '777fddb8-7948-4c69-9269-bce5bf0cbd88',
    'identification_number': 47472006,
    'item_consumption_1': 2180,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1273.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e199f61b-589b-4a46-9dff-048a9de074e7',
    'identification_number': 17898927,
    'item_consumption_1': 338,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 143.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3db8c86b-f7ad-4301-918c-b7b22a4db687',
    'identification_number': 87227509,
    'item_consumption_1': 5599,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2188.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '763577f8-f48a-4112-b67d-ab7542b5b1ec',
    'identification_number': 105597554,
    'item_consumption_1': 376,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 146.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0da35ad4-c5e5-477e-9943-0b47fb1e6e38',
    'identification_number': 15818284,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 71.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f9614c76-ff08-4f0a-b86b-13f2a43cf438',
    'identification_number': 100627188,
    'item_consumption_1': 2584,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1509.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6770c5db-5d4d-4c9b-b990-fa300d4fa666',
    'identification_number': 16246780,
    'item_consumption_1': 59.97,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1744.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '41ee77ca-75a2-4fdd-8a92-60fce28d4db0',
    'identification_number': 76403955,
    'item_consumption_1': 1122,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 655.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7997b1c4-8269-4cd3-8145-2cd21b9765c4',
    'identification_number': 14138018,
    'item_consumption_1': 1236,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1869.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fed5dc8-a3ac-47ac-8e91-c0db5c819685',
    'identification_number': 39964973,
    'item_consumption_1': 80.39,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2338.21,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1f5f5ec9-e6f2-47c3-99f7-2363f47a1319',
    'identification_number': 104186020,
    'item_consumption_1': 24.94,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 725.39,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8475bb46-a47f-41e6-adc5-c008536861f6',
    'identification_number': 27526690,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '65cd0c0b-7053-46e3-8ff1-fad330076e77',
    'identification_number': 41490533,
    'item_consumption_1': 525.31,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 15279.13,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7d3d37cc-9764-409a-9e67-fc69ed425359',
    'identification_number': 35846445,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f69226af-b2b4-4cd6-b644-d0a6dae969ef',
    'identification_number': 90469259,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c75864c9-6a34-41f7-acf7-5df23b51d3ad',
    'identification_number': 8708320,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '858d8b43-ec9d-4963-90d9-8e7e30281345',
    'identification_number': 84702893,
    'item_consumption_1': 458,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 179.2,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ca7b391c-7eef-4984-b2b6-265eb17a1366',
    'identification_number': 19453299,
    'item_consumption_1': 361439,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 46132.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b7cf56f-88d3-4b6e-ba58-a3c9e80eaf57',
    'identification_number': 10877100,
    'item_consumption_1': 2676,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1045.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e14049f7-6e3c-4537-bd92-ca49c7dffa76',
    'identification_number': 15158241,
    'item_consumption_1': 374,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 218.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fcff4169-f281-4e6d-a62f-5bf1bb09d00f',
    'identification_number': 27895610,
    'item_consumption_1': 33374,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12420.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d85c463-5303-4ab5-9ba0-a64be6304018',
    'identification_number': 73887692,
    'item_consumption_1': 9711,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1239.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77f6be4c-6070-47b5-b12b-7efbfb3217ff',
    'identification_number': 33041644,
    'item_consumption_1': 7156,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3033.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04481783-f9b7-4673-8611-8361a37e1708',
    'identification_number': 39422470,
    'item_consumption_1': 9.83,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 234.47,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4dd407b2-5038-4856-8576-3907b7347538',
    'identification_number': 94480524,
    'item_consumption_1': 5309,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2256.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a714dfbf-43ce-467e-bdf8-c26314b2dd76',
    'identification_number': 74703102,
    'item_consumption_1': 774,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 302.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcbfe4a0-3e64-4279-ac76-eda9eea55ebf',
    'identification_number': 62362801,
    'item_consumption_1': 29360,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3747.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56ce1113-d64f-4de2-9d95-431623200ce8',
    'identification_number': 101400470,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4093b8d8-d7ab-4747-9804-7f8c1f39155b',
    'identification_number': 19451512,
    'item_consumption_1': 4.86,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 141.35,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '7de6fcaf-97de-4e4a-bb88-42f392f65410',
    'identification_number': 107173115,
    'item_consumption_1': 1249,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 729.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89575eb9-50a7-4eed-9ce4-20c20f1c01fb',
    'identification_number': 111295327,
    'item_consumption_1': 259,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 109.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c05bf0c-1cdb-4810-92bf-3261577113a5',
    'identification_number': 43505520,
    'item_consumption_1': 2812,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1194.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5756dce2-8bda-44f6-909d-204ef974364c',
    'identification_number': 104309555,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 1272.4,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'fe645d69-670d-48c1-a588-4da320eb3892',
    'identification_number': 16307828,
    'item_consumption_1': 18507,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6887.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b127084e-ab06-4cd4-a326-04eed6151e32',
    'identification_number': 101591039,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbd3f8bb-aa00-4cfb-a5ed-fed2a4a076a4',
    'identification_number': 48827657,
    'item_consumption_1': 12546,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5310.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d77b7ba-8180-4c09-9b37-274921c1e55e',
    'identification_number': 100612075,
    'item_consumption_1': 1486,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 867.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89c7eb2a-6a9a-4d84-b38e-3d12c5626f8b',
    'identification_number': 56705514,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5c582840-1bd4-4f5c-9217-0e0841719956',
    'identification_number': 92387578,
    'item_consumption_1': 31.78,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 924.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ea3bb5ed-6562-4982-952f-719e4a5eb75b',
    'identification_number': 23899166,
    'item_consumption_1': 10.62,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 308.87,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9e8b0d4c-0934-41e9-bcfa-1c4ce8488d2c',
    'identification_number': 15348075,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5d4908d5-765e-4df8-af85-ac159875a79a',
    'identification_number': 98554840,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4151925-59aa-4b96-b970-2ef3e7ed1aac',
    'identification_number': 33688818,
    'item_consumption_1': 903,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 352.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc409361-6905-4f6a-afe9-f72f039a042b',
    'identification_number': 93712847,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cec5aa14-8634-4428-8369-95ec2f6d33ae',
    'identification_number': 14662302,
    'item_consumption_1': 3792,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 6527.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3656d1a6-f621-4410-87ea-edcc71c88536',
    'identification_number': 16246780,
    'item_consumption_1': 27117,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3461.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b37b202-311d-4d61-b78a-5178f4be99f9',
    'identification_number': 75671387,
    'item_consumption_1': 417,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 162.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4eabacd-49d9-43ff-ba74-f3bda1a68472',
    'identification_number': 41360818,
    'item_consumption_1': "'-12.094",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-3879.61",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'd6b2f070-c125-4676-9689-a1eb23fbc2a6',
    'identification_number': 76376249,
    'item_consumption_1': 8532,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4982.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7148bbb-c8a1-4ab7-8c45-5500d690c0c4',
    'identification_number': 39261565,
    'item_consumption_1': 32498,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4147.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dadf3497-26c0-45ca-be4b-2a8c3d8dff08',
    'identification_number': 41002768,
    'item_consumption_1': 851,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 360.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4a7e9a1-ed35-4cad-963a-de9d887b0ebf',
    'identification_number': 44774494,
    'item_consumption_1': 9376,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 14184.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfff6dab-7c7f-4de5-bf9f-b6a24b19591c',
    'identification_number': 70343411,
    'item_consumption_1': 1725,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 730.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f170241-8c01-46da-92ee-33f9ddf5d3cb',
    'identification_number': 110227328,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23072d6d-0f1f-4ed5-a74c-7335d8fd7e3e',
    'identification_number': 28748735,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bab9d8e3-450b-4fe6-b4c2-04730115a022',
    'identification_number': 17213711,
    'item_consumption_1': 4481,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1750.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7dd9992-e4b7-4c2c-9c81-94e05efd33ab',
    'identification_number': 46896163,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45e53509-befe-4b2f-81db-83b8f8ded302',
    'identification_number': 90604679,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 121.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '27622c35-3fc5-4312-b62a-fc6c82ed7799',
    'identification_number': 7664214,
    'item_consumption_1': 3681,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1563.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57a3fc44-c243-4b87-b8b2-056e33099dcb',
    'identification_number': 34498605,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e839898f-62f1-4f0e-b1b9-d881cb7e5745',
    'identification_number': 103592458,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '43b6c767-39aa-4c3c-b3ee-795411b6bb10',
    'identification_number': 44805853,
    'item_consumption_1': 135.59,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3943.75,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5dd40da1-1703-4736-901b-fd5b0ec3bea9',
    'identification_number': 95598421,
    'item_consumption_1': 2712,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1056.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '109e8cdb-d069-4974-9353-fd53a65dfe43',
    'identification_number': 22343814,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c345ebfb-a498-49cf-8c24-c5c30c582cd2',
    'identification_number': 16124197,
    'item_consumption_1': 7277,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2837.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43b30165-0e0b-49db-a9d5-f17daec2b8fd',
    'identification_number': 45907404,
    'item_consumption_1': 518,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 783.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4357d17c-c66f-4da6-9682-82e5e357e8f3',
    'identification_number': 14219743,
    'item_consumption_1': 4040,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1574.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c55e513-89e0-4400-8714-ecb1f69483af',
    'identification_number': 103424873,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22dae248-9069-4c83-bc21-f33fc2c1975c',
    'identification_number': 105470708,
    'item_consumption_1': 1568,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 915.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b62a3c63-f973-4fb3-a10f-1a2dd900e722',
    'identification_number': 54126754,
    'item_consumption_1': 96,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 145.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84a0d0c3-1a3d-48d8-91e2-2745d28ec285',
    'identification_number': 9822950,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2185.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '709606ea-cf07-4e68-88eb-b9564097e916',
    'identification_number': 10145230,
    'item_consumption_1': 537,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 227.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2fc18413-db8c-48f4-b2dd-f19f2f46b5c5',
    'identification_number': 40429458,
    'item_consumption_1': 19147,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2443.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0faa2fff-2e92-497f-b039-9a48951fd3fb',
    'identification_number': 10379444,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd6b39fc6-8e6d-437c-8a22-cabf63947a17',
    'identification_number': 108218511,
    'item_consumption_1': 1066,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 451.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54d3f4d9-53fb-45ec-8272-ff02dd5c3c5f',
    'identification_number': 109993217,
    'item_consumption_1': 21648,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8056.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59ed1373-4034-4074-b882-25365729eb13',
    'identification_number': 20587147,
    'item_consumption_1': 553578,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 206012.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '078d3cfc-34b1-44ea-a65e-49f0a4b4c0ae',
    'identification_number': 44476108,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '59cea901-da19-4f2d-aacf-db4039681d33',
    'identification_number': 16961439,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 74.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '78f8cc4e-384e-4514-9ef6-c0bddbabf095',
    'identification_number': 17962552,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 120,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '70779b47-bdbd-4b4b-bad1-93246821b046',
    'identification_number': 32605579,
    'item_consumption_1': 405.36,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 11790.26,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '38086916-6281-451b-b5ea-894fa5bf99d6',
    'identification_number': 52599400,
    'item_consumption_1': 9286,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3455.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8612be4a-dedf-4225-8b88-69dc7ce9833a',
    'identification_number': 8813442,
    'item_consumption_1': 372,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 157.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b417601b-84e8-4f74-86f8-80556da3eb9b',
    'identification_number': 15660818,
    'item_consumption_1': 670,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 283.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69e0934a-0ef7-4eae-819a-85bc7a1f427f',
    'identification_number': 107845490,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '031a1b2d-2440-4670-8c52-f5fa4e1de887',
    'identification_number': 62272039,
    'item_consumption_1': 3077,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1306.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7894b704-6ef3-4427-94cd-c572db949edb',
    'identification_number': 108167879,
    'item_consumption_1': 141.17,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 3366.98,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7df0fce0-9fd8-4851-a712-638a4bdac430',
    'identification_number': 30326834,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e4d3c105-80fb-41bd-8b83-e84854ae7da3',
    'identification_number': 92950310,
    'item_consumption_1': 1209,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1829,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '805691f8-cdc5-4758-8417-c7f11ea2dc57',
    'identification_number': 104755920,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6983aa79-c50a-4178-9f2c-e648da9a2f90',
    'identification_number': 76229815,
    'item_consumption_1': 46.36,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1348.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '05fc1294-ce74-4365-adfa-d2cc3a929a81',
    'identification_number': 5330351,
    'item_consumption_1': 4.04,
    'item_description_1': 'DEMANDA REAT EXCED USD FP',
    'item_total_value_1': 117.49,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'ec9b0f85-3bf9-402f-94f9-c483a3e2f87d',
    'identification_number': 101762038,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9404dee-4173-4b24-b98d-9725c0443eec',
    'identification_number': 15906698,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6e203944-0a28-462b-8f81-2cd77a352574',
    'identification_number': 44261802,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80721097-4b15-482e-8b0a-72f9c8456277',
    'identification_number': 108331024,
    'item_consumption_1': 10888,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1389.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a0d33a0-8a9a-477f-97bb-f7822246fa69',
    'identification_number': 60730781,
    'item_consumption_1': 16239,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6043.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '572e4662-d276-4725-b14f-f66082584bb0',
    'identification_number': 12918385,
    'item_consumption_1': 137,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 80,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c974dd89-54ca-4bf0-b396-d4d0f427779c',
    'identification_number': 12027740,
    'item_consumption_1': 1530,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 598.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a3bda0a-52e7-45c3-bb73-c20429c39e0e',
    'identification_number': 11708522,
    'item_consumption_1': 2988,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1167.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71a82cbe-2dff-42a5-ad05-a40ddcace48f',
    'identification_number': 73581046,
    'item_consumption_1': 1264,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 535.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bea5259d-db17-4d01-93fc-a97f66ed30db',
    'identification_number': 59440589,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fc931d09-cb68-49bb-b8e9-c67cd63875ab',
    'identification_number': 104188120,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 975.83,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a2a51e4a-7a97-462b-96b4-3b60a469135b',
    'identification_number': 103284770,
    'item_consumption_1': 3271,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1910.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6def9cac-0e4e-452b-a169-be7bdc5084af',
    'identification_number': 97170720,
    'item_consumption_1': 407,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 172.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92bf4239-67f4-411a-9149-176e387f8a5e',
    'identification_number': 45602387,
    'item_consumption_1': 984,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1488.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21086ec2-2def-48a7-a327-eb0f986fbf17',
    'identification_number': 104499664,
    'item_consumption_1': 5589,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2370.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5073e3f0-54ee-4986-b04c-5256e17f276f',
    'identification_number': 68376391,
    'item_consumption_1': 21585,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8032.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9289a24-a40c-4345-ba00-435e9432db70',
    'identification_number': 102892784,
    'item_consumption_1': 1040,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 441.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21af61bc-7c98-404b-ae73-e0aef8cc1d90',
    'identification_number': 17073898,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd9120cd-5b21-4e64-9d88-898bc085c381',
    'identification_number': 109697693,
    'item_consumption_1': 40.68,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1183.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0b1c4b3a-52c4-4fca-823d-4d2666644f2d',
    'identification_number': 101068484,
    'item_consumption_1': 1888,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2856.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb070715-e96c-4e13-9ea7-50e0aa8c0e45',
    'identification_number': 100388396,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e4afd2e-a655-4b45-852e-e81f7c5f9125',
    'identification_number': 103785558,
    'item_consumption_1': 1998,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1166.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '884a89c8-807d-49f9-9693-bb5815869826',
    'identification_number': 18910874,
    'item_consumption_1': 374,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 159.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c43e4c7d-7dd2-4f69-9233-2ac710291e24',
    'identification_number': 98005472,
    'item_consumption_1': 6414,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2721.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7487b95e-771d-4d49-a661-4ca4506eca4d',
    'identification_number': 91303192,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2edb53d7-e6b5-4e3e-9ecd-c4516625a495',
    'identification_number': 41418417,
    'item_consumption_1': 1965,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2972.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6639147-c07b-40ac-a3fa-d1ff4f183ffd',
    'identification_number': 949190432,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f00ea6e-1065-42c4-9bf9-7d4b7ecbd073',
    'identification_number': 98370200,
    'item_consumption_1': 58.2,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3323.79,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '6dbe65fc-9312-4ab3-92b4-5a56b5bd1c44',
    'identification_number': 100214193,
    'item_consumption_1': 984,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1488.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5d198d2-5fd4-4e2e-a6ae-ba121f862a28',
    'identification_number': 45602387,
    'item_consumption_1': 6728,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2503.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e59b1c31-0945-4b01-a6c3-03855e8bd2fb',
    'identification_number': 59068140,
    'item_consumption_1': 1345,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 525.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9cd8155-e901-4777-ab2e-138ab068509e',
    'identification_number': 69443149,
    'item_consumption_1': 337,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 131.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd99697d4-a927-4c26-8cce-f74bd4b93ab6',
    'identification_number': 43036830,
    'item_consumption_1': 97611,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 12458.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25b6cbba-2c3e-43e0-8e02-f0be8a63c6d7',
    'identification_number': 17159660,
    'item_consumption_1': 24,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 9.39,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bd71d9ff-3d88-4780-bf71-41ba97bb8660',
    'identification_number': 98802739,
    'item_consumption_1': 36607,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4672.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9bd20ae-0698-4bea-a179-a25bf16ba7e5',
    'identification_number': 19394829,
    'item_consumption_1': 6010,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2342.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8df83ad4-3bcf-46f8-9a45-e861e85bf073',
    'identification_number': 61583758,
    'item_consumption_1': 29.86,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 712.2,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '321fb8b5-ce8f-4774-bae3-1d7e31cf25b6',
    'identification_number': 89340639,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7495942a-1eec-46af-9080-71e4638fa04e',
    'identification_number': 65625960,
    'item_consumption_1': 5156,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2191.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5853c144-9039-466f-adbd-3ffdfefdf50a',
    'identification_number': 63897148,
    'item_consumption_1': 518,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 783.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '653afcd6-d776-45f6-b784-0ce25890f7e8',
    'identification_number': 30102901,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 10.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9a6daf97-9b44-413a-bd34-6578dd314cd6',
    'identification_number': 92333141,
    'item_consumption_1': 4368,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1703.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e64cc4c-3a5f-4074-8a72-d73af82670d4',
    'identification_number': 43908764,
    'item_consumption_1': 6.19,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 147.64,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'fdf8a463-9096-4f67-99a3-45b4e36cd400',
    'identification_number': 29677289,
    'item_consumption_1': 1648,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 698.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fef2447f-2d12-47f0-9e9f-0f86e079cd85',
    'identification_number': 20367384,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41494a75-7c04-4fc1-a898-1890e36391b8',
    'identification_number': 70503974,
    'item_consumption_1': 233,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 136.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3414cfc1-a1a0-461a-8e4f-450eb794aa46',
    'identification_number': 6338526,
    'item_consumption_1': 35,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 13.69,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '954f8731-d7fa-4c42-84a3-31c3a32db52f',
    'identification_number': 102036500,
    'item_consumption_1': 763,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1154.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e94cfae-d140-4423-96de-b2aab70db4b1',
    'identification_number': 94029539,
    'item_consumption_1': 3206,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1872.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd8c879f-2a36-488f-b0d8-74b28482aadb',
    'identification_number': 96625104,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26d613aa-aafa-4a5f-9213-355cc6a67887',
    'identification_number': 26030420,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '113ed2b2-544d-4744-8a63-f0c55c7683b9',
    'identification_number': 37708678,
    'item_consumption_1': 1480,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 576.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29821c60-d51b-4cb1-91be-982179be1e3a',
    'identification_number': 105010286,
    'item_consumption_1': 4274,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1814.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7486d01a-a177-4525-9193-5c2eac87348c',
    'identification_number': 33921180,
    'item_consumption_1': 391,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 165.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fafaf5c-a5fb-4661-8566-f3e84b85f8bb',
    'identification_number': 106706632,
    'item_consumption_1': 873,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1320.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a4d2465-6105-4d73-a372-9b889e549bbd',
    'identification_number': 107951533,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c5063dd-e68b-4133-b6a4-526eec2bf16f',
    'identification_number': 16115660,
    'item_consumption_1': 1116,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 847.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be40b6d1-352a-4cf6-96e3-4d2a80c8706a',
    'identification_number': 99433486,
    'item_consumption_1': 2434,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1034.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72898743-93d7-4854-ba70-0806cc7e0623',
    'identification_number': 8670277,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4dc3b874-8573-4d1f-96d9-f1d9ab01458d',
    'identification_number': 23135328,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.28,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fb44f5d6-b443-4172-a311-cf5e2fc0811f',
    'identification_number': 14662655,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1e7dc526-5181-4786-8702-c9ab56825765',
    'identification_number': 106643517,
    'item_consumption_1': 3257,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4927.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac6a1d0a-5bfc-4c39-9314-bcc7bdd6b451',
    'identification_number': 76229815,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b770fe20-ff0a-4d5a-86eb-92122540b3f0',
    'identification_number': 40934756,
    'item_consumption_1': 115810,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 43098.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80fd8d29-38d1-4545-8e90-a9de5bd1333b',
    'identification_number': 91858194,
    'item_consumption_1': 18383,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2346.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c77b3b6-af34-46f3-871d-91eb03d9c8c5',
    'identification_number': 14138069,
    'item_consumption_1': 1946,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1136.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3fc9697-cb7c-41c0-b659-fc14822ec5d0',
    'identification_number': 4334671,
    'item_consumption_1': 27049,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10066.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0f2987a-bac3-4870-a5fd-0eff474c89ec',
    'identification_number': 58770240,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbf42f64-b54f-46cb-953c-e93677285400',
    'identification_number': 39261565,
    'item_consumption_1': 32498,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12094.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8aa4149-14d2-41a4-91d7-5d298a00de97',
    'identification_number': 104455250,
    'item_consumption_1': "'-1.11",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-355.63",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'c3b61bd0-1aef-4e6a-ad0d-8ff3a432bc65',
    'identification_number': 106729985,
    'item_consumption_1': 51,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 19.94,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4b9ccbc1-c9d8-4d2e-8398-1e1ccf02e350',
    'identification_number': 104616890,
    'item_consumption_1': 1816,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1060.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '975866db-ca07-47b7-a5f2-4efac2b766e2',
    'identification_number': 14389754,
    'item_consumption_1': 2.9,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 69.18,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '88cb894d-99d2-4b72-87b2-87d23ce8cec7',
    'identification_number': 30950317,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b22c04f-a4ab-47fc-a93f-2ccaf6953247',
    'identification_number': 103706992,
    'item_consumption_1': 154.58,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4496.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '292d1786-f905-4cb6-a4f0-5355295b0663',
    'identification_number': 75299372,
    'item_consumption_1': 3.83,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 91.37,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9d714b35-9a83-44c4-8bee-7071df5276f4',
    'identification_number': 22067248,
    'item_consumption_1': 7779,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3299.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5ba1cd1-d04f-482f-b24c-4a759b61849b',
    'identification_number': 56697198,
    'item_consumption_1': 610,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 356.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ed607c8-9f27-4a28-a7bf-230ac81c9478',
    'identification_number': 105219304,
    'item_consumption_1': 565,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 220.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06131095-d1c7-4fcf-a80f-de2c0c5da4fb',
    'identification_number': 3031341111,
    'item_consumption_1': 0,
    'item_description_1': 'Parcela Art 06',
    'item_total_value_1': 282.56,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '89656836-9f9a-438a-a70f-d456f2efdbd3',
    'identification_number': 78564662,
    'item_consumption_1': 2133,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1245.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3bcaf7f-8e76-423f-ad00-988c55e1f235',
    'identification_number': 97838233,
    'item_consumption_1': 1100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 467.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8ba0142-0d4d-491f-aa90-45a094ca1c06',
    'identification_number': 92562302,
    'item_consumption_1': 6023,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 768.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27db7c7d-911f-451d-8672-5c4825b60399',
    'identification_number': 41640870,
    'item_consumption_1': 70253,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 26144.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7c393fd-ff91-4d01-a1eb-87800a54f82c',
    'identification_number': 74464264,
    'item_consumption_1': 1207,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 511.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f856a80-e0c2-42fd-a3a9-86c669fee4d6',
    'identification_number': 105805947,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 225.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7ce9956-6605-408f-84bd-dfbbe250e37f',
    'identification_number': 76636291,
    'item_consumption_1': 104600,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 13350.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c637d019-c053-4e21-b628-93340386f0eb',
    'identification_number': 101870728,
    'item_consumption_1': 3.63,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 211.15,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1608b23c-c005-406e-a1f9-868cdf7c426e',
    'identification_number': 92387578,
    'item_consumption_1': 34.22,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 816.19,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ecb1d8d5-b225-47a7-ae8b-fe70722843fc',
    'identification_number': 89118162,
    'item_consumption_1': 1289,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 503.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4150922-e694-463f-965d-552edd8d39e8',
    'identification_number': 85177342,
    'item_consumption_1': 16167,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6865.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75922cdf-5cf3-47c7-8da7-d2e3ed5d9048',
    'identification_number': 4288262,
    'item_consumption_1': 13621,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5784.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9cc0d5c7-952e-4d74-bb76-0358a5019c0b',
    'identification_number': 86398652,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-4939.45",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '11952d3d-9086-4112-ba93-9c182bcbbe6d',
    'identification_number': 104289465,
    'item_consumption_1': 2701,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1144.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '089457b3-9dff-4e0d-ab42-88aa56f55cfa',
    'identification_number': 84983981,
    'item_consumption_1': 103,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 40.29,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c82b3370-517a-46fd-bed1-ddb0a225d119',
    'identification_number': 48362832,
    'item_consumption_1': 24944,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3183.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd37a43ef-ba6d-4074-88ae-a7d893b4320f',
    'identification_number': 58740147,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18e33233-5069-4f2b-82a4-fd12be94c94e',
    'identification_number': 103004874,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4d8e5622-f0df-4b97-b087-9b68ffcc84a3',
    'identification_number': 100567924,
    'item_consumption_1': 759,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 295.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '875c168b-e6cf-47f1-8966-9a3f42442c22',
    'identification_number': 98833715,
    'item_consumption_1': 4905,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1916.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72e6e411-2f85-4db9-8726-22dbbfdef71d',
    'identification_number': 14187205,
    'item_consumption_1': 2111,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 824.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0627209-bd1f-4783-b4f0-2b2922bbb5d0',
    'identification_number': 23555661,
    'item_consumption_1': 2739,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1159.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7c54892-d7df-40f4-bd1d-96f8d2c3b1fa',
    'identification_number': 47280816,
    'item_consumption_1': 23.43,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 681.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fb66b0e6-948d-4deb-b825-7fbaf298cc25',
    'identification_number': 18339654,
    'item_consumption_1': 8.39,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 392.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5581e855-ba70-4a02-b15b-c01e7fcec360',
    'identification_number': 57109346,
    'item_consumption_1': 277,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 103.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '028171f8-c6c0-49bd-9f8a-577dbb867486',
    'identification_number': 47397276,
    'item_consumption_1': 108,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 45.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6e41dcf-a313-4065-a5f9-9ca9a737c425',
    'identification_number': 61251810,
    'item_consumption_1': 2542,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1077.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05ea04f8-b0a5-4f1d-8e5b-60bc679a7f93',
    'identification_number': 51333376,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31223543-03c4-47fe-8793-55b8c608ab68',
    'identification_number': 36253642,
    'item_consumption_1': 6446,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2736.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83339eec-8031-4107-a814-2426166fdfbb',
    'identification_number': 72063564,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 152.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '58424959-254b-4e93-979e-a79ebd182757',
    'identification_number': 105418188,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.99,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7ccc25fb-eb4f-42c0-a1e9-2ef46ceaae0b',
    'identification_number': 10834184,
    'item_consumption_1': 126,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 53.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b4075bc-fe8e-47f9-900c-e37b54889869',
    'identification_number': 14635232,
    'item_consumption_1': 1721,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 672.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4a5184b-74ce-4d0c-9cf0-32c1a92d335b',
    'identification_number': 98359770,
    'item_consumption_1': 11593,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4923.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2102a3df-48ef-4d54-98e2-4b5e34e0764d',
    'identification_number': 29517478,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7c65b746-ae17-4a59-a539-1294d870379d',
    'identification_number': 45985880,
    'item_consumption_1': 3881,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1517.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ba9d464-87b8-4a57-8535-2f9d92754eb4',
    'identification_number': 66198666,
    'item_consumption_1': 2210,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1290.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c32274dc-cc3a-4fc8-a5a2-f86d11268402',
    'identification_number': 36338958,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9b2d4e58-9ff7-4b3a-aa7d-a80f5b260404',
    'identification_number': 50792180,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '86b05dda-32cc-4fe3-b3ee-83794af298e8',
    'identification_number': 27125700,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5115e6d-dd03-4328-9dd4-551845e60963',
    'identification_number': 35183543,
    'item_consumption_1': 2897,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4382.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce87e998-938b-4dd1-9f83-51c4b8d2c0ca',
    'identification_number': 75565730,
    'item_consumption_1': 28687,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3661.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '227ccd41-86db-46c8-9232-19090d7284c0',
    'identification_number': 39466787,
    'item_consumption_1': 3183,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1858.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db4b3511-78c6-453a-99ac-9752648ea982',
    'identification_number': 79645119,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1367c98f-951e-47c7-a223-bc0ffb9f90b3',
    'identification_number': 32810300,
    'item_consumption_1': 86.54,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2517.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '49585262-d434-48d6-a268-4507e2bad0cf',
    'identification_number': 41692063,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 161.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '324d206f-b445-428d-a1ca-504f0c9b4e89',
    'identification_number': 55746560,
    'item_consumption_1': 12837,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4777.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1e61357-3129-4360-8e90-0b88fe235943',
    'identification_number': 79937667,
    'item_consumption_1': 10108,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5903.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a72b4ff-c357-4069-91cb-8ffb05d38dc3',
    'identification_number': 21161348,
    'item_consumption_1': 2533,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1073.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd409b4a-e62f-48c4-91df-9d3bcb799d6f',
    'identification_number': 42223750,
    'item_consumption_1': 1214693,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 155038.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74c78d07-dbf5-4a59-b5d3-35d5bf4fd00c',
    'identification_number': 26030420,
    'item_consumption_1': 6353,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2482.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd452d64e-ec0c-4c7b-bc6b-a314c815818b',
    'identification_number': 80485774,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '050a7eab-ccc7-4d85-b5e7-1e1c473bd6ef',
    'identification_number': 64175251,
    'item_consumption_1': 0.83,
    'item_description_1': 'DEMANDA REAT EXCED USD PONTA',
    'item_total_value_1': 24.14,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'ce94c4af-535e-4c22-a4ab-1c5de06f44db',
    'identification_number': 39099652,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30091780-16ff-452a-8023-e7c79aa0e1bc',
    'identification_number': 93009470,
    'item_consumption_1': 70,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 27.36,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '62c4ee45-18bd-4fbe-8d7c-d8eaf07474d5',
    'identification_number': 30427290,
    'item_consumption_1': 3867,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1638.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c318a856-3f33-4a05-9bf4-b0601d1cef85',
    'identification_number': 106529889,
    'item_consumption_1': 147,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 57.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aea4792b-86cc-4351-a450-b004f7f5c320',
    'identification_number': 37134191,
    'item_consumption_1': 3135,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1221.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17e9ec1e-721e-4191-afba-0d23128d4a0e',
    'identification_number': 96505087,
    'item_consumption_1': 2934,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1147.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7178de5-518e-4662-b5fa-53a751c2b4d8',
    'identification_number': 106199960,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8339050e-862b-48d5-83b4-e9e6c4450d6a',
    'identification_number': 66198666,
    'item_consumption_1': 23.12,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 1082.72,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '853e30c5-2eb8-42ee-a889-93827f17dd95',
    'identification_number': 76510883,
    'item_consumption_1': 519,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 202.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd4f0c85-3320-4558-b324-482f8f0deffc',
    'identification_number': 40432980,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 1054.72,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'bea87692-dd3c-468a-aad4-4847bf70cb5c',
    'identification_number': 53747755,
    'item_consumption_1': 94.95,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2761.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f4b7520f-c07a-42df-bc32-ec1693727c5a',
    'identification_number': 100692990,
    'item_consumption_1': 1351,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 573.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3213fe4c-7299-4cc8-967a-95ec665797f2',
    'identification_number': 108380513,
    'item_consumption_1': 2603,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3937.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8dc88770-7759-4a00-89bc-7fc28b9a8ab3',
    'identification_number': 64724689,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20775cf7-0218-496e-a871-2718d6f52a32',
    'identification_number': 11430990,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 144.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cffd98db-d51e-44ee-815c-aa8f6939c66a',
    'identification_number': 91075700,
    'item_consumption_1': 4586,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2678.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cdae437e-1497-44dd-a5e0-eebde2f73d15',
    'identification_number': 94576165,
    'item_consumption_1': 3922,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1531.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5eb00b23-47c5-41d5-be0e-aa3314544a0d',
    'identification_number': 45268835,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd66e29dd-20f7-4559-8b56-8c38e979e83f',
    'identification_number': 29982685,
    'item_consumption_1': 5403,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2289.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '322028f9-2d2f-46c9-a5a7-8f9b35ed6944',
    'identification_number': 100637701,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5bfe787d-0ec7-4bac-adad-e670e79551f9',
    'identification_number': 28259513,
    'item_consumption_1': 251,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 98.2,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '245367fc-24aa-4e38-b250-3f2e2a17eeb1',
    'identification_number': 12024970,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '43057477-820c-4642-8259-9a31a5c9e77a',
    'identification_number': 58742115,
    'item_consumption_1': 432,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 182.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a63b21e9-5255-4fec-b683-6518f11ec95b',
    'identification_number': 16246780,
    'item_consumption_1': 27117,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10091.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a84fd6c-e4c1-4d19-a173-a932babd103f',
    'identification_number': 30298482,
    'item_consumption_1': 0.59,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 27.64,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '461ef4c3-1122-4192-92e2-a332337fb202',
    'identification_number': 26816458,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a2f5a43-a6e2-4941-9c89-b9703d076cb7',
    'identification_number': 100223699,
    'item_consumption_1': 702,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 274.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '333c1a1e-cdc8-4f36-8408-c6373c6ff460',
    'identification_number': 72507136,
    'item_consumption_1': 9.65,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 230.16,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '392b8111-9500-485a-9aca-d039c45ccd0d',
    'identification_number': 80485774,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73ec44da-4599-4d0a-a773-2b6e5edcf3cc',
    'identification_number': 58737863,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f7e527ba-cc30-4740-8442-d332442f2999',
    'identification_number': 106568485,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 208.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cbe2be57-1f23-4ed0-ac30-23b7011a6da8',
    'identification_number': 81267843,
    'item_consumption_1': 6945,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 886.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b7107dd-d4a9-4805-81b7-b10da9731808',
    'identification_number': 98528785,
    'item_consumption_1': 1072,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 418.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ae7e2c9-5a85-4f30-8870-d78887732257',
    'identification_number': 15658660,
    'item_consumption_1': 12629,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4940.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a73c0f60-5790-4ec8-a7dc-f30efe24fc5a',
    'identification_number': 97575941,
    'item_consumption_1': 1288,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 752.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3489d23-adff-4f5a-a81c-3b93babc11ca',
    'identification_number': 103323392,
    'item_consumption_1': 669,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 261.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef17b475-de15-46a9-a646-80036c91c917',
    'identification_number': 108331024,
    'item_consumption_1': 66.04,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 3092.68,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '03c9a899-4f6e-44cb-9e5d-b91ec5551a4c',
    'identification_number': 103066330,
    'item_consumption_1': 2085,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 886.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3aa93e1-ee1a-4412-a7ee-37e867d019a4',
    'identification_number': 33355215,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'de837e28-6a32-411e-ac29-cbc5ca80422b',
    'identification_number': 69613206,
    'item_consumption_1': 1353,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 573.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e3ebda2-efc0-419a-a82d-dc13dd826a90',
    'identification_number': 105957852,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ddd5885-f5ee-429b-8f08-92a7493eb15b',
    'identification_number': 1205102,
    'item_consumption_1': 49,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 19.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '97c6352a-4118-4de6-99bb-ae6cc2263188',
    'identification_number': 81217846,
    'item_consumption_1': 144679,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 53841.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0f6a43b-ef2a-42f2-81e7-92417116f176',
    'identification_number': 106729985,
    'item_consumption_1': 4.03,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 117.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bee675c4-3791-4d2b-b32f-ce57894431e8',
    'identification_number': 75063077,
    'item_consumption_1': 10.4,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 302.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3c532c7e-5452-42ef-98d6-96f80ba9092a',
    'identification_number': 58745548,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3578dd5a-a07b-48da-ae23-b2d19933e9fb',
    'identification_number': 16961439,
    'item_consumption_1': 1434,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 607.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e5d3aee-9d9f-41e9-9fc6-f5b0eaec6d0f',
    'identification_number': 75363666,
    'item_consumption_1': 191,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 74.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de1d87e8-adf8-42f1-ba1e-607a2c2ff851',
    'identification_number': 948743512,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '577d32ea-97fb-4f1c-bd0a-a962b360f01d',
    'identification_number': 36691143,
    'item_consumption_1': 198,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 77.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '007914d0-a1aa-4ab0-bee5-39df95745085',
    'identification_number': 106605712,
    'item_consumption_1': 493,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 745.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6652e8f-7e65-45bf-95a8-0111c10a037f',
    'identification_number': 72702141,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '861828f9-1d42-4b40-b18e-4cc10cc6d718',
    'identification_number': 86301322,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89a61e31-3460-4159-8e5e-99aeece02045',
    'identification_number': 91711630,
    'item_consumption_1': 87,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 131.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92a7e219-d195-4ec0-93fc-2b2a0af18302',
    'identification_number': 83593039,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a7f994bb-bfad-4c81-88e2-bdda6dcc4d10',
    'identification_number': 107112981,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2caf2a15-290a-4704-9478-e03b84e249d5',
    'identification_number': 99553465,
    'item_consumption_1': 177,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 69.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbef8e28-1cf0-4dbf-a69e-09923d58d0a3',
    'identification_number': 47952555,
    'item_consumption_1': 417,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 177.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67e9d878-0201-4002-ba10-b0fc23acad66',
    'identification_number': 47312459,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7a0ebc64-860a-45da-8015-2a11f344b59d',
    'identification_number': 15981444,
    'item_consumption_1': 24,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 452.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ac9609de-b71b-46d5-a621-3957cfdb5f8f',
    'identification_number': 32747217,
    'item_consumption_1': 55.49,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1613.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5930f657-b6b5-478b-bb0b-f166a2522ffd',
    'identification_number': 94385394,
    'item_consumption_1': 7294,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3101.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdece6e7-dd37-4c30-99cd-ed464addde16',
    'identification_number': 101477040,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-156.44",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'a9b1d693-800d-4098-baaa-0cd8528829f1',
    'identification_number': 17435048,
    'item_consumption_1': 47647,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6081.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aff35975-a108-44f9-9db8-84e78e3bba75',
    'identification_number': 103823760,
    'item_consumption_1': 2159,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 842.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c7010d2-6633-4ba3-b312-53c9ddc1b1ab',
    'identification_number': 41379136,
    'item_consumption_1': 8490,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4958.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '032bd3d4-78c2-4416-b9b7-af4182ded5b8',
    'identification_number': 41809050,
    'item_consumption_1': 82,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 32.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '352a6d37-2afa-46b9-8ddd-2661481637b0',
    'identification_number': 94525994,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4ca84e9c-e1ab-48ad-b9a3-ff7801e6e664',
    'identification_number': 94540209,
    'item_consumption_1': 162,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 63.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f9585be-3923-4d19-a677-e7f35e2b119c',
    'identification_number': 47989050,
    'item_consumption_1': 2.15,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 100.69,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8cce859e-362c-4be2-a434-aea54b7f806c',
    'identification_number': 31564496,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6be405af-2ad0-43e3-96a7-7d281a6c7c15',
    'identification_number': 20305290,
    'item_consumption_1': 3743,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1585.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ba82d33-0232-4ab8-b553-3c0c5ee02526',
    'identification_number': 88788768,
    'item_consumption_1': 2254,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 879.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd3f1a10-80e1-44f9-9806-e4b39be901a6',
    'identification_number': 26750546,
    'item_consumption_1': 2602,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1017.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9704164c-7d52-4e4b-9445-6a793d12be32',
    'identification_number': 94724741,
    'item_consumption_1': 4396,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 561.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0fcef535-dd50-43b4-9edf-90a591b2bcbf',
    'identification_number': 17578582,
    'item_consumption_1': 136,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 57.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5218bb24-f1c1-407b-87eb-1b763c858f00',
    'identification_number': 16102169,
    'item_consumption_1': 22108,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 16792.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40c8a5ab-8293-404c-98c7-acfaa5cb4085',
    'identification_number': 45478260,
    'item_consumption_1': 71.58,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4087.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b6a99973-c2dd-4d55-8d31-e7a6cd1369c2',
    'identification_number': 11430990,
    'item_consumption_1': 2635,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1028.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd927a28-7873-4daf-b459-25cbe3686dcb',
    'identification_number': 17725755,
    'item_consumption_1': 1635,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 694.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '652c4fce-26b4-4689-9995-90a3d88d1368',
    'identification_number': 82744181,
    'item_consumption_1': 1180,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 689.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc850c4f-22d0-4f57-9db4-65a175211552',
    'identification_number': 33921393,
    'item_consumption_1': 96,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 37.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8dc69dcd-9753-4a7e-b718-bb7884574735',
    'identification_number': 102985693,
    'item_consumption_1': 8590,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3351.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5157fef8-8a4f-4441-be2f-8e847e11ce43',
    'identification_number': 100764827,
    'item_consumption_1': 8713,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3692.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eaca8606-d120-4608-b3be-1747863d64bd',
    'identification_number': 14232979,
    'item_consumption_1': 36,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 14.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '915803d3-5dac-4e6e-9337-a409b2a3b6eb',
    'identification_number': 26750546,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '89be2e33-4e38-44b2-8635-32a4d0ad813d',
    'identification_number': 935147792,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '13a4ef41-4ba0-45f1-8e82-c30946952a1c',
    'identification_number': 15087239,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b4f8a999-600f-4ece-bb5f-03c3260816dc',
    'identification_number': 74865145,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3003.4,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a27b1e34-c537-47c6-9b06-5094cada0cee',
    'identification_number': 27526690,
    'item_consumption_1': 2536,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1073.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16cb3db2-2a60-48cb-b8db-8be3d241f026',
    'identification_number': 98688987,
    'item_consumption_1': 6698,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2836.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd37d1fa-a710-4204-a8cc-1d57d862f5b3',
    'identification_number': 77818792,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f5a80c0c-04b3-4b8c-8620-e39a121e6ebb',
    'identification_number': 73822671,
    'item_consumption_1': 27.06,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 787.04,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dce67911-03cc-4150-9877-64f1ff416e7e',
    'identification_number': 70509204,
    'item_consumption_1': 143,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 83.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd98c1e48-9786-4040-ad2c-bc760289236a',
    'identification_number': 47129271,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d1f5d10-4b8e-4b05-914d-4f7c69717019',
    'identification_number': 40410978,
    'item_consumption_1': 2950,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1152.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c371aa27-d68a-4cd5-922a-a7e1ef8601de',
    'identification_number': 102003211,
    'item_consumption_1': 17008,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2170.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45f55ca5-71f7-46c2-b9e0-cf521a71b724',
    'identification_number': 97170720,
    'item_consumption_1': 407,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 158.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97e003e2-3287-44a6-b010-5c011f9e7d23',
    'identification_number': 98359770,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aa9019a2-8354-46ef-b452-a30d77c62f04',
    'identification_number': 18229077,
    'item_consumption_1': 3750,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 478.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36e19147-390e-4b1b-91b0-fc53c71e410a',
    'identification_number': 34617426,
    'item_consumption_1': 336.13,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 9776.66,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '23a47492-bc91-4da2-a439-5b8cc98f0b1b',
    'identification_number': 31705901,
    'item_consumption_1': 12196,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4768.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '565e20a7-1c1d-4cfc-97fb-4cd56af30464',
    'identification_number': 71307745,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.21,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b191b87e-7908-42e2-be98-e9a8d0e2a8f1',
    'identification_number': 936095230,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f5ab055a-21ea-4b3b-b607-285a2da4f4cd',
    'identification_number': 95632867,
    'item_consumption_1': 5.53,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 131.91,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8271b794-ad7a-4e76-bc89-acd087ac1a9c',
    'identification_number': 108167879,
    'item_consumption_1': 108.83,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3165.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bbc39f95-6242-40bc-8731-e62330d0ab9f',
    'identification_number': 109044002,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd38db27d-e59a-4493-ade1-2c175078d4f4',
    'identification_number': 105807435,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7b3355c5-906e-4f78-96ba-6ca387473833',
    'identification_number': 18671870,
    'item_consumption_1': 1249,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 488.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b11e5ee-ea8c-4e42-a07f-5a569675854c',
    'identification_number': 9897321,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bb5c9ae3-8e05-4f31-8a08-c6190fc8c6de',
    'identification_number': 79701710,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '184a6eec-6cbc-44fa-a4be-7bfa6d9ff86c',
    'identification_number': 92938418,
    'item_consumption_1': 8066,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3147.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c75bcad-7f9e-4a88-8894-b53a3161fc25',
    'identification_number': 70085439,
    'item_consumption_1': 11961,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5071.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23a2eee4-820f-4985-bf11-249f2cf31442',
    'identification_number': 25327283,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0d839aea-82e0-4957-9230-46953dcd7ced',
    'identification_number': 46764544,
    'item_consumption_1': 14.007,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 5310.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c5981d8-e981-4f0a-944d-97f050b89fb8',
    'identification_number': 100918719,
    'item_consumption_1': 1686,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 658.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b564a7e1-a792-47ea-afdf-cb3e37668e28',
    'identification_number': 46699236,
    'item_consumption_1': 20.68,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1181.02,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5288a1f0-8b3f-4f06-b115-c7f4ca505146',
    'identification_number': 102742820,
    'item_consumption_1': 102,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9746c327-45bb-4045-a7cc-41ebb0c1ec2a',
    'identification_number': 68007493,
    'item_consumption_1': 182,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 106.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37cb967d-175f-43f8-821c-869688efe72a',
    'identification_number': 19451610,
    'item_consumption_1': 350373,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 130390.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3341da67-c3dc-4946-9cca-169e6f142e3f',
    'identification_number': 28166159,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7aeb07c1-d806-4d0b-a095-1bde3c3fada5',
    'identification_number': 44476256,
    'item_consumption_1': 79413,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 10135.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b0f287f-0aa3-4e30-8e51-e488d59a5c3c',
    'identification_number': 31509983,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '00802c30-68b4-4956-bf1b-62e0703a545b',
    'identification_number': 14693100,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd99d65c9-f197-473e-b55c-472ab4e9508d',
    'identification_number': 30132860,
    'item_consumption_1': 7667,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3257.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89a51e0a-0d61-4f6a-87d8-4456b16c7735',
    'identification_number': 100765076,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-1020.07",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '40280169-cab0-49b8-99cc-6a3201b256f5',
    'identification_number': 97343536,
    'item_consumption_1': 1945,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 824.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd39f09e0-7851-4163-9944-7f35874ce429',
    'identification_number': 43699553,
    'item_consumption_1': 4998,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1950.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a16bb54a-56fc-4136-86f0-40fc3b01ccf4',
    'identification_number': 48432130,
    'item_consumption_1': 40922,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5223.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e00c23c-a883-4171-94b3-613726d4d36a',
    'identification_number': 106469940,
    'item_consumption_1': 2066,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 877.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df429440-1cd6-4de4-8d0d-b47a5bfb0015',
    'identification_number': 63604116,
    'item_consumption_1': 1911,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2890.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8a6313f-74f3-4a36-bb12-db27bdf15de4',
    'identification_number': 14952688,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1373.71,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0f97540d-dfe8-4155-b4e7-9e682571f7d4',
    'identification_number': 93511388,
    'item_consumption_1': 24.13,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 575.52,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c6705fd5-5a89-485c-ac0d-41ac44ffa8ba',
    'identification_number': 15091538,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9d3f7628-d162-40d4-b6c8-8eb9a81d9b8a',
    'identification_number': 15522229,
    'item_consumption_1': 24.74,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 719.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '05f98a0b-1693-4afb-b77c-736f6520b721',
    'identification_number': 110270410,
    'item_consumption_1': 224,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 83.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e65ae1b1-d39a-421f-9736-aa71aec41b61',
    'identification_number': 15721400,
    'item_consumption_1': 7202,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3059.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb057320-2f5f-4423-84ec-f2164f815227',
    'identification_number': 31296084,
    'item_consumption_1': 760,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 322.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e4ae5bc-7267-49a8-a653-5afc1055bd91',
    'identification_number': 31846882,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2aa5c147-b25d-4f6c-a827-d62bbab3d30a',
    'identification_number': 31686729,
    'item_consumption_1': 82.36,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2395.51,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0f6d2a16-0929-4bb9-84dd-1b1cf2e91001',
    'identification_number': 105804339,
    'item_consumption_1': 622,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 263.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6d5fcf4-e18f-4831-96c5-fa03d4f6e51c',
    'identification_number': 13789422,
    'item_consumption_1': 9.59,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 278.92,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '70bbd9f2-3b17-4f5c-b8f5-5a45ccceb044',
    'identification_number': 70701989,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a722d5f4-98b3-4d40-91d9-beccf0b5df9d',
    'identification_number': 45219893,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7bdc421c-7697-477c-9758-1fbb90958f6b',
    'identification_number': 105284556,
    'item_consumption_1': 1245,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 527.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1761b07e-d70a-43a7-b1a3-82850e9b59a7',
    'identification_number': 15572862,
    'item_consumption_1': 24066,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3071.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11188eab-a566-4e37-aaf4-ddb311fe5768',
    'identification_number': 42710529,
    'item_consumption_1': 14947,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1907.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6d93a71-843b-4177-bf92-0ba62892c4aa',
    'identification_number': 10932151,
    'item_consumption_1': 8554,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3636.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86c0e39e-b995-4a20-b643-35c7231f146c',
    'identification_number': 92141200,
    'item_consumption_1': 19495,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7255,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca9b5f7f-69e5-48f8-bef9-2dd031701ced',
    'identification_number': 9211195,
    'item_consumption_1': 2059,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 803.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f060493-f833-436c-830a-9488df1ad657',
    'identification_number': 28259513,
    'item_consumption_1': 11883,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1516.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcacd01b-c280-4eeb-8ff3-763c7525064e',
    'identification_number': 15572862,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ebb226c9-688d-4efd-8c65-e28278e81394',
    'identification_number': 94664480,
    'item_consumption_1': 192,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6eaee8f-6a12-426b-8e18-9663ff52f758',
    'identification_number': 61583758,
    'item_consumption_1': 2773,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1085,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c3c7a469-4728-4dcd-a225-3034c104de8e',
    'identification_number': 99578336,
    'item_consumption_1': 2.16,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 51.52,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '44babc75-d571-47e1-ab5f-f4bfe0256535',
    'identification_number': 20367449,
    'item_consumption_1': 2585,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1097.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2cd90f3f-91be-4425-b982-0654746a43ad',
    'identification_number': 15645835,
    'item_consumption_1': 1517,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2294.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83b4970a-8bd1-40ad-9a2e-856b7dcecc1b',
    'identification_number': 8708320,
    'item_consumption_1': "'-1.905",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 08/2023',
    'item_total_value_1': "'-609.08",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'fe4ecfcb-7d68-43a0-ae6e-a6b711c247aa',
    'identification_number': 44916264,
    'item_consumption_1': 4283,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1814.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9fc229d-7f86-4dc3-864c-fc1eac5d531e',
    'identification_number': 76203310,
    'item_consumption_1': 3986,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1552.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28f2ce40-a227-4b09-94d1-790257f8ebfd',
    'identification_number': 17247799,
    'item_consumption_1': 1240,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 524.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ac900d4-72e5-4068-92c3-36a40ba085ad',
    'identification_number': 92947026,
    'item_consumption_1': 139,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 54.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c676aa9b-1eb3-4ad4-ab38-8c43a776f930',
    'identification_number': 78962188,
    'item_consumption_1': 14156,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5268.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39bac19c-c780-4440-a289-792cb45868aa',
    'identification_number': 43626955,
    'item_consumption_1': 7.86,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 187.47,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd4b0a76e-7047-4305-9555-6dfd6e2ce451',
    'identification_number': 100627188,
    'item_consumption_1': "'-21.905",
    'item_description_1': 'ENERGIA INJETADA FP TE 11/2023',
    'item_total_value_1': "'-6684.54",
    'reference_month': '11/2023',
    'referente': 'Compensação crédito energia',
  },
  {
    'uuid_items': '659d4d8c-e236-4b59-a5ca-970afd7403f7',
    'identification_number': 3030777242,
    'item_consumption_1': 1.2293,
    'item_description_1': 'Consumo Reativo Exc Fora Ponta NOV/23',
    'item_total_value_1': 0.37,
    'reference_month': '11/2023',
    'referente': 'Energia Reativa Excedente Fora Ponta',
  },
  {
    'uuid_items': 'f1a743fb-2bf7-4514-976e-905565f1708d',
    'identification_number': 13643045,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4249029-63d5-4ab2-912f-c528dc0eb6dc',
    'identification_number': 76403955,
    'item_consumption_1': 0.69,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 16.47,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '82cf9d38-d953-45e3-a8c1-91b104bf4178',
    'identification_number': 15751740,
    'item_consumption_1': 4995,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 637.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a4fff8f-11c1-4c3f-9634-75cbbbcc6b7e',
    'identification_number': 68029098,
    'item_consumption_1': 31408,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11688.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a67da9d-e567-42a5-858b-a109e203dec0',
    'identification_number': 75896974,
    'item_consumption_1': 16111,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2056.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6586d919-fee1-4a2d-aec2-b526d8e235f0',
    'identification_number': 103724702,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc9c3df2-8871-4b99-a2df-807479f98baf',
    'identification_number': 77279719,
    'item_consumption_1': 1092,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1652,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd440089c-4544-4426-b291-2e8fbf4a1028',
    'identification_number': 13299530,
    'item_consumption_1': 18367,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2344.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1730f2dc-7ba3-45a0-945a-221eb022ad30',
    'identification_number': 108331024,
    'item_consumption_1': 106,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 41.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '856bd313-fa16-4ea4-804c-d4e2d6cad6da',
    'identification_number': 18138373,
    'item_consumption_1': 39.4,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2250.12,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '9e947705-fc97-4617-8e96-383fb0933226',
    'identification_number': 42403359,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.99,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '09e32bea-43d4-47b8-9bb0-92a21ff68ac5',
    'identification_number': 6270760,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd13292a8-55c3-44af-9a92-4e731bdb3a05',
    'identification_number': 47899816,
    'item_consumption_1': 11320,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4212.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e3bffaa-0452-4eb5-b7f3-e6d5535008d2',
    'identification_number': 29957150,
    'item_consumption_1': 9286,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3944.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57dc55ae-d3b9-45b5-9b65-33b396ab5a56',
    'identification_number': 38109280,
    'item_consumption_1': 40480,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5166.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce393059-36c1-4ab7-8323-760e09c2d519',
    'identification_number': 8115672,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fd236855-daa9-4193-b4a2-57e7eb84523f',
    'identification_number': 19305818,
    'item_consumption_1': 971,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 411.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75fea33f-2b91-4583-96f7-8cbf182b5774',
    'identification_number': 48432130,
    'item_consumption_1': 119.9,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3487.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c25552cc-9646-4ee2-8d19-5b07635c5976',
    'identification_number': 46577173,
    'item_consumption_1': 615,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 78.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec96d352-d142-447b-b3bd-dc221677d7be',
    'identification_number': 41176987,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3564c72-5ceb-437b-a7c7-4263da2a9938',
    'identification_number': 90469259,
    'item_consumption_1': 8735,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5101.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bdd41b0-62af-4eed-a77f-f333b117e2e4',
    'identification_number': 73829447,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6b0cb2c-d6e7-4d70-917c-b34134ddb70b',
    'identification_number': 107684187,
    'item_consumption_1': 3478,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1477.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c4b8198-bae4-47c3-8ea5-40890edc0aa8',
    'identification_number': 93093110,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c26f0119-c9c1-4dfe-b947-925c269eba53',
    'identification_number': 46123253,
    'item_consumption_1': 2961,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1153.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61bd83f7-a046-478d-9653-32df728e451d',
    'identification_number': 58742387,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2fdb8302-d948-4722-92b7-ca4d8bc52d6b',
    'identification_number': 32727917,
    'item_consumption_1': 57141,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7293.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4469a720-ba01-4f54-8614-a8673778c1db',
    'identification_number': 102699127,
    'item_consumption_1': 2178,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 924.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa80efe3-925b-4883-8cde-cf35e99fa7f3',
    'identification_number': 48482897,
    'item_consumption_1': 2229,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 284.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bcbf42e6-a9b5-4f32-aefe-1e21e90ed74b',
    'identification_number': 6219012,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b6b9d1b-830f-4846-9dbd-de2f92b13fe8',
    'identification_number': 68385447,
    'item_consumption_1': 58.79,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1709.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '40abc87f-20b5-402d-930a-ea8be49115a8',
    'identification_number': 43195300,
    'item_consumption_1': 48856,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 18181.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2ef4ee7-25c0-4fb7-9789-45a9c62238cc',
    'identification_number': 33058474,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 126.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cbe17e56-80b7-44f3-b696-5a047c1ca99e',
    'identification_number': 87554968,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5a40998d-c985-4195-9f92-d1d3ca29734a',
    'identification_number': 4129199,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3f59bd17-2f55-46a9-b5cc-ebdb8f91ef4b',
    'identification_number': 97140775,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce318a21-0fea-42eb-9836-3b76f0861855',
    'identification_number': 110227328,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9118e72-f80d-461a-8cd9-a9ac2e998042',
    'identification_number': 51301563,
    'item_consumption_1': 1336,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 560.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd2e5ef2-5bad-45af-bc43-b17dfd9f4808',
    'identification_number': 6350992,
    'item_consumption_1': 754,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 293.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '31b0a836-e108-47b7-b494-afcf6488f779',
    'identification_number': 69758409,
    'item_consumption_1': 3910,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1455.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9821c5a3-4fff-495c-b058-6b7c0097a37a',
    'identification_number': 18519555,
    'item_consumption_1': 3445,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1463.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f33bb70-3bdd-4847-bfcf-9c1826411f42',
    'identification_number': 99258404,
    'item_consumption_1': 10256,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3995,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33c4b78f-98c7-45e7-8fa7-5d7a103f9638',
    'identification_number': 84599391,
    'item_consumption_1': 1815,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1060.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c2ec336-b920-493d-9d2c-7b13a7f1749c',
    'identification_number': 98925083,
    'item_consumption_1': 5870,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2184.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b90ad462-c11c-45b5-a3b6-e9caca38c349',
    'identification_number': 83757988,
    'item_consumption_1': 744,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 290.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9482be5e-d50f-4364-bd64-36fa552a5fe4',
    'identification_number': 93067895,
    'item_consumption_1': 18771,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6985.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '949cefb0-a8a8-443a-a024-e9e9de082924',
    'identification_number': 109382595,
    'item_consumption_1': 1073,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 419.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82b0a595-daa1-47ce-8818-8ba1ff68006f',
    'identification_number': 80073301,
    'item_consumption_1': 1463,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 621.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46d12c3c-b051-467d-a361-c855340de0b0',
    'identification_number': 40855783,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e4e9955-68f3-4a41-8c89-7a937346e17d',
    'identification_number': 42089808,
    'item_consumption_1': 151.14,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 8631.6,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'be38ac5a-2a0a-4a59-9a7d-6fd68545c70a',
    'identification_number': 107936704,
    'item_consumption_1': 579,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 875.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3aa5a569-3291-4847-9b76-b1dfa7c5b175',
    'identification_number': 91359511,
    'item_consumption_1': 164.62,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4788.12,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '367fae62-80c2-4a37-95b3-6f3960b31baf',
    'identification_number': 18213260,
    'item_consumption_1': 2431,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1032.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e572647-5ee1-418a-b123-e963df9e76aa',
    'identification_number': 12275468,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '99532e9d-e652-4612-988d-3b17bec0dcf8',
    'identification_number': 43484506,
    'item_consumption_1': 21.54,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1230.13,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4448b710-6dc1-4c19-9be8-96863037a4a0',
    'identification_number': 39196461,
    'item_consumption_1': 19945,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 8467.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c58a4915-0494-4df3-8f73-f314610e33eb',
    'identification_number': 105564443,
    'item_consumption_1': 264,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 103.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '261fc63e-9c64-4014-bdbf-c56a2113583d',
    'identification_number': 14619563,
    'item_consumption_1': 7714,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 984.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7242e323-b216-4d8d-afd1-6fbc63091a14',
    'identification_number': 105957828,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4676f214-8888-4ddf-a947-eaef14ef2c79',
    'identification_number': 12718750,
    'item_consumption_1': 19294,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7180.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18e9fd27-c855-4439-bc3f-1761c659f33e',
    'identification_number': 42871034,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a5b5b80-43b0-4045-a3d0-73cfad8e8eba',
    'identification_number': 102036500,
    'item_consumption_1': 763,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 445.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '046d98b7-fb3c-480b-9186-1c80cd52bb86',
    'identification_number': 19306059,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc05aa6d-e591-4fe5-9fd2-824053392b1a',
    'identification_number': 34976469,
    'item_consumption_1': 290,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 123.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3325515-8dcf-4467-a7d8-74c8a27a4e9c',
    'identification_number': 101920652,
    'item_consumption_1': 5462,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2134,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7557529d-a216-4362-816e-54f3b800366a',
    'identification_number': 25214934,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '49e67229-97ee-476c-8b7b-9df4647dd654',
    'identification_number': 32303912,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3549.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78b7527f-d517-46e1-a9d9-020080baf362',
    'identification_number': 30168961,
    'item_consumption_1': 332,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 140.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b991f48e-6582-499b-b696-b2d66626d7bb',
    'identification_number': 6286755,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '100223ab-95f2-4b94-aac3-87d0c719f196',
    'identification_number': 49457012,
    'item_consumption_1': 4995,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7556.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14a4cf65-b700-47f2-b2ba-c7ebc68cc19c',
    'identification_number': 13296523,
    'item_consumption_1': 13053,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5546.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8af8d3e9-bf68-436b-a491-a9533adab57b',
    'identification_number': 39717313,
    'item_consumption_1': 1996,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 845.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96b1e208-5a2b-46e9-abc0-33f19b973a53',
    'identification_number': 41231058,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af1de65e-8970-48ef-b4cd-a9af451089f3',
    'identification_number': 107158876,
    'item_consumption_1': 286,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 111.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a262ab34-1d98-43c9-91f7-91f6c1982c86',
    'identification_number': 108600157,
    'item_consumption_1': 5730,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2235.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41bbb954-e64b-4e67-ae08-4de1a31e7f95',
    'identification_number': 104309555,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2122.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a5d6092-b849-41ee-9c63-b0858dae13c5',
    'identification_number': 106613979,
    'item_consumption_1': 20390,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7588.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54d470ee-2d70-4340-bacd-51fdb4dde6f4',
    'identification_number': 103706992,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '890cb089-9bdf-43b0-9098-1676d100d3da',
    'identification_number': 77887930,
    'item_consumption_1': 5.65,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 134.76,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd535fd02-fa28-4b5c-919d-5241e66767a5',
    'identification_number': 98123521,
    'item_consumption_1': 2077,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 809.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '590b7027-a242-48fb-9a6f-cac65f3a86f0',
    'identification_number': 102507864,
    'item_consumption_1': 17.49,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 508.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b70979e7-c7f2-4b93-911c-6d8893fb3682',
    'identification_number': 68447450,
    'item_consumption_1': 6.81,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 162.43,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'eca5e567-aa58-43ca-b6f1-8d8e38c25f2a',
    'identification_number': 18649092,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.65,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '543a8e17-5333-4da6-bba1-7624e0876260',
    'identification_number': 17668956,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.54,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '83136bb9-1fb4-4f42-a07d-91b8cf58814c',
    'identification_number': 108024326,
    'item_consumption_1': 873,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 370,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb606d52-2bba-4fbe-9205-96e84e638dcb',
    'identification_number': 73901857,
    'item_consumption_1': 2080,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 810.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '178603a7-8d49-4b70-95ee-c716e844e56b',
    'identification_number': 110907434,
    'item_consumption_1': 115,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 48.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0065a754-563e-441a-93b2-a8cdec15c032',
    'identification_number': 16570936,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 126.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '811628bc-61e0-4d3c-a5a1-00c16bbf42f8',
    'identification_number': 66198666,
    'item_consumption_1': 16094,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2054.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43c72e71-ac90-4927-be90-d6ae55632a49',
    'identification_number': 45459738,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eef17f56-a53f-4e1f-bf43-60c6f0d99159',
    'identification_number': 106592432,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ead373b5-03e9-44d1-a9c4-dd1f2628e4f2',
    'identification_number': 109785983,
    'item_consumption_1': 600,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 350.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bd5eeda-9a4b-4aba-9675-b80c63edea1b',
    'identification_number': 107055198,
    'item_consumption_1': 61,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 23.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65f40981-50c3-43bf-9125-693871fa33fd',
    'identification_number': 54420636,
    'item_consumption_1': 1687,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 659.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3bf1acbd-106a-43b7-baa5-886ac24af046',
    'identification_number': 99305062,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 45.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f2c6ab72-187e-44aa-9305-c4e6081fdf93',
    'identification_number': 29251354,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8aa1973e-503a-492c-ad4d-c92034e3cb7c',
    'identification_number': 109822285,
    'item_consumption_1': 2818,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4263.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f64154d8-d10f-42bb-a5b1-528b8ebffd81',
    'identification_number': 19686560,
    'item_consumption_1': 329,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 128.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '492bb697-571f-4fb6-97cd-f3fbc1887c7e',
    'identification_number': 68376391,
    'item_consumption_1': 2867,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4337.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8af8f145-9d82-48fe-a14b-776fb1baaa21',
    'identification_number': 9073639,
    'item_consumption_1': 5476,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2327.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93c8ee97-4129-42d7-a21d-76cc630a5cad',
    'identification_number': 23848367,
    'item_consumption_1': 202,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 85.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '801d8b8e-1545-41a0-8124-9e343feae1d6',
    'identification_number': 12424188,
    'item_consumption_1': 74186,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 27608.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b6b37ad-a524-47bb-910b-ade2b86195f4',
    'identification_number': 92562302,
    'item_consumption_1': 609,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 921.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ad81f4f-5e36-4e8e-8ba0-bd44b0616b03',
    'identification_number': 111295408,
    'item_consumption_1': 546,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 213.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5319df7-7fd5-4155-9b06-8e554816edb7',
    'identification_number': 947580121,
    'item_consumption_1': 1303.38,
    'item_description_1': 'Consumo em kWh Ponta',
    'item_total_value_1': 2777.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6e0cf87-4a5d-4d66-99ff-9567e4f2dbbd',
    'identification_number': 22162330,
    'item_consumption_1': 2494,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1456.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a248150-621a-48c5-b49e-ef75eacf289e',
    'identification_number': 109869613,
    'item_consumption_1': 505,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 214.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b783034-5a26-46cc-af84-9ec49d1f0472',
    'identification_number': 21374792,
    'item_consumption_1': 201.47,
    'item_description_1': 'DEMANDA FORA PONTA TUSD',
    'item_total_value_1': 4699.19,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2d583084-8a47-4dab-a291-a6e00e7a0c89',
    'identification_number': 44805853,
    'item_consumption_1': 2396,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3624.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8c7fd25-8054-45c2-890e-022dfdd67fc7',
    'identification_number': 70941378,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '572588b4-9eb4-4edf-b6f6-3852b9b19777',
    'identification_number': 99433486,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 93.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e794a0bd-9bb0-49dd-8a6e-667dfb86aca2',
    'identification_number': 42959128,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-177.25",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '19162ff6-b62d-4732-8fdc-6b8795011364',
    'identification_number': 64643492,
    'item_consumption_1': 4747,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2014.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69fa4556-a27d-4a27-9d10-9987e1ef0bd4',
    'identification_number': 105760480,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.97,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5d6ec915-5266-4e1a-a2d6-7ec26658823e',
    'identification_number': 90790499,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '28a5d21f-a21a-4da9-8e1f-afc9fbf70c09',
    'identification_number': 93231903,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '670dfd97-9fd8-4e16-b6b1-201a3f5c9445',
    'identification_number': 15688976,
    'item_consumption_1': 3450,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1348.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6612f679-e013-4458-b772-c712b9421624',
    'identification_number': 45138869,
    'item_consumption_1': 2256,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 880.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f993f650-fafd-4242-a1ef-4ebe3144b1d0',
    'identification_number': 100941699,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10e6feda-beec-481e-9512-9b98effc2458',
    'identification_number': 13179349,
    'item_consumption_1': 9575,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3736.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01949dba-fc4d-4607-9676-2d3c301c963c',
    'identification_number': 110528603,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc7664b5-c32e-49ae-89f8-fc62e025eff0',
    'identification_number': 19013256,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 75.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4e438f87-b09d-4564-820b-64865679d5b7',
    'identification_number': 48362832,
    'item_consumption_1': 4095,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6194.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86251e50-b500-4eb0-baea-e0622fb4a81c',
    'identification_number': 111270510,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '177c6e91-6dc9-4b2e-8325-34d71940328b',
    'identification_number': 16111630,
    'item_consumption_1': 2950,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 2240.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'debacd14-119e-4b7b-b859-ec626f951af1',
    'identification_number': 94339546,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5746329-d6c3-44e2-a5af-7dacf569bcd2',
    'identification_number': 53031423,
    'item_consumption_1': 1427,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 605.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6a1889c-0503-4dc8-ab38-b198edb95d4f',
    'identification_number': 14671549,
    'item_consumption_1': 4322,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1608.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3af6f68c-eb20-4408-b3bc-15f33cf9c16e',
    'identification_number': 60730781,
    'item_consumption_1': 36.87,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1072.39,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5f601f39-955d-475a-bfce-c5b74fd1f00d',
    'identification_number': 13870068,
    'item_consumption_1': 2131,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 833.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9927e734-1e3a-4050-a782-aa9ce8366df8',
    'identification_number': 46395040,
    'item_consumption_1': 3308,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1290.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82ccd844-61cd-40dc-a8fe-7dcfd04f1917',
    'identification_number': 6092578,
    'item_consumption_1': "'-833.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-289.59",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '0bab33d9-7d58-4f41-920c-ee8e4f000994',
    'identification_number': 45340340,
    'item_consumption_1': 1438,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 839.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93b5acd9-3734-4ad5-9c73-8510ecee9007',
    'identification_number': 46171622,
    'item_consumption_1': 159827,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 241789.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57ad5479-2175-487f-baf6-504b34fc5a89',
    'identification_number': 81333480,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8ff543a-c815-4273-9270-2c154b3b7568',
    'identification_number': 47380128,
    'item_consumption_1': 9263,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1182.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '585ed819-8e2b-4953-b968-5f2766192f7b',
    'identification_number': 19311761,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07dd17d7-a6cc-4ef2-92c8-c78f6c301e00',
    'identification_number': 110411196,
    'item_consumption_1': 10.69,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 621.84,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e89dd6c2-a841-4fe4-8946-37059ed30d5d',
    'identification_number': 106013467,
    'item_consumption_1': 7052,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2995.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29ddf99d-7465-4eee-8ab5-fa3b032fb22f',
    'identification_number': 106536451,
    'item_consumption_1': 189,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 80.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56b6af28-1c29-4ee6-8697-ab6a4f1ebc48',
    'identification_number': 16246837,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8ca36278-a2f9-476c-95db-cbb34a56e6d1',
    'identification_number': 101837720,
    'item_consumption_1': 1658,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 704.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86d901d1-fa0c-49f7-a8b6-572241bf3174',
    'identification_number': 42404576,
    'item_consumption_1': 77.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2243.68,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1f2299c5-a80e-431e-9709-06e4cdadef09',
    'identification_number': 32627572,
    'item_consumption_1': 27924,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 16308.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6c33621-63e2-4473-969d-26dafd94d375',
    'identification_number': 6192807,
    'item_consumption_1': 4596,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1947.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a2b3d43-bea7-4685-b11b-6b7e775b4317',
    'identification_number': 935472026,
    'item_consumption_1': 170,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 148.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69d584e6-6e89-4cbb-a739-e57698f39f8e',
    'identification_number': 26168146,
    'item_consumption_1': 645,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 274.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69283713-84f7-44a9-9e7f-91c83c7c1759',
    'identification_number': 92141200,
    'item_consumption_1': 19495,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2488.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '699b9d14-b1e9-48ec-9ecc-c22b8731e18e',
    'identification_number': 101708440,
    'item_consumption_1': 1046,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 409.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a51659a-7fa1-4ab6-9f68-5578297c163e',
    'identification_number': 71307745,
    'item_consumption_1': 4030,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1712.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f85ee28-ba02-4be0-a6ea-caa1661a7b3c',
    'identification_number': 36525405,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 049/200',
    'item_total_value_1': 1.45,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '6182ac7e-f685-4164-93df-07955c133efe',
    'identification_number': 102159459,
    'item_consumption_1': 667,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1009.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6cd2b61-8934-4c28-a53e-19f233ff6161',
    'identification_number': 102036500,
    'item_consumption_1': 8771,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1119.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e08a8495-a303-4c06-aa83-43fd0ccf2bdc',
    'identification_number': 100187994,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce1e4a0c-3703-4ec5-856e-4c7f652239d3',
    'identification_number': 29984971,
    'item_consumption_1': 25,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 9.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '872e55d0-a2c3-43c9-8266-cf721a82004f',
    'identification_number': 42469716,
    'item_consumption_1': 664,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 282.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19d83d7a-3486-46e7-868b-be3e1c3bf461',
    'identification_number': 16148533,
    'item_consumption_1': 7305,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3100.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4591fe5d-a2e8-406d-9ae1-9b4762a0fc09',
    'identification_number': 18987133,
    'item_consumption_1': 549,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 214.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1a271e3-76d6-490b-b1cb-1bef602b4f19',
    'identification_number': 66832330,
    'item_consumption_1': 337,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 196.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ced72fa8-9a4f-408a-990a-b77e8bacb6cb',
    'identification_number': 103876502,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2a492827-8ee9-4a42-9ac6-f14859ea1442',
    'identification_number': 62515438,
    'item_consumption_1': 108303,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 40304.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0221abfc-346e-44df-b672-8e8a457d90a5',
    'identification_number': 34479686,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.93,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b54cddf8-a82c-45ac-bef8-e00a2b7a06c6',
    'identification_number': 91100810,
    'item_consumption_1': 3101,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1211.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f951a52-751f-4153-bbb4-bcf8976b496e',
    'identification_number': 91711630,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6ef8f8c2-1825-4994-bdf2-c3a57e9dd362',
    'identification_number': 10836128,
    'item_consumption_1': 12210,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4763.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3a2ecb3-74d1-4935-a07a-310205fa6083',
    'identification_number': 36235334,
    'item_consumption_1': 2346,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1370.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'acea06d8-7844-4a1c-bfc8-d64fe95ce4ce',
    'identification_number': 48681261,
    'item_consumption_1': 55.39,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1611.04,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5140bf78-d430-488c-905b-9f6b2b28a757',
    'identification_number': 48630209,
    'item_consumption_1': 131,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 55.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dda1b2d2-6a8d-4ca5-b9a9-ddfb7cc8dc52',
    'identification_number': 36149730,
    'item_consumption_1': 37,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 14.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2a1a78c4-c5fb-4754-82ae-cea9c883dfd5',
    'identification_number': 99850559,
    'item_consumption_1': 11962,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5064.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8dbaafa9-f255-4fe8-824c-7092bd4741e5',
    'identification_number': 12275468,
    'item_consumption_1': 5.1,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 238.84,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b7e3549b-f236-4360-b517-42a349a67ec7',
    'identification_number': 94603995,
    'item_consumption_1': 4073,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1593.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48521a73-4be3-4eb6-a112-26f1c627bfa7',
    'identification_number': 85070661,
    'item_consumption_1': 3122,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1216.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '180160b7-cb5e-45e9-ba56-4c6bb30a4829',
    'identification_number': 72418745,
    'item_consumption_1': 227,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 96.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1700b0db-025f-48f3-8845-f240a0edbd8a',
    'identification_number': 73802174,
    'item_consumption_1': 1856,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 786.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd71c09ff-8766-483e-9428-1e3f94a3bf3a',
    'identification_number': 92387578,
    'item_consumption_1': 1511,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 882.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c6df91e-5093-4876-a19d-5afa5de26bf5',
    'identification_number': 104179228,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7018.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a644915-77b3-4325-a931-0bafc7374596',
    'identification_number': 99786184,
    'item_consumption_1': 3679,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2148.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e692c140-76c6-4378-bca1-b8512f43c1f1',
    'identification_number': 36149730,
    'item_consumption_1': 3.21,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 76.57,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '938e2398-72ca-4481-ac76-fd132b832d41',
    'identification_number': 108782441,
    'item_consumption_1': 1168,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 455.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0220ab55-c360-412b-9293-feef5d03ef1e',
    'identification_number': 8805504,
    'item_consumption_1': 8040,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3133.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73105794-0640-4f2b-858c-001fb2198a84',
    'identification_number': 13960261,
    'item_consumption_1': 2836,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1205.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '290c84d8-ef0c-4de1-bf18-d6eaee9f594b',
    'identification_number': 8859060,
    'item_consumption_1': 6880,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2920.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2003510-82fc-47d5-b703-c1f075134428',
    'identification_number': 56526580,
    'item_consumption_1': 633,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 957.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '069e4846-296f-4a6f-958b-26f23c05c0c4',
    'identification_number': 106708120,
    'item_consumption_1': 70.5,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2050.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '9869f3f0-7c94-46b2-840f-10e8f2a91604',
    'identification_number': 106706632,
    'item_consumption_1': 873,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 509.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '842e21a1-c97c-46b0-8424-745a05cd5bfe',
    'identification_number': 26139111,
    'item_consumption_1': 5516,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3221.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '835421ef-1d29-4dbd-a9c2-23081874be11',
    'identification_number': 42698936,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ebf39fc-ac8c-445c-8aae-5de3aa5f7b1d',
    'identification_number': 43333680,
    'item_consumption_1': 3243,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1361.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c52c019f-ca2d-4b28-b1b2-d3640748d988',
    'identification_number': 46006583,
    'item_consumption_1': 22200,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8261.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca937f9e-d885-4d30-b9a4-01cb35c12a97',
    'identification_number': 18616909,
    'item_consumption_1': 12.19,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 290.75,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '12f307d7-c72e-4c9b-ae61-2ee26210acfe',
    'identification_number': 104114550,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0a3c70e-8ab8-47b3-9ca8-2ca9bfeb7ed8',
    'identification_number': 95920862,
    'item_consumption_1': 671,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 261.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f40d77c-a00a-4fb1-908a-6cd77f78b316',
    'identification_number': 105089060,
    'item_consumption_1': 18537,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2365.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eee7114f-7317-47d1-b222-e99b47249c9a',
    'identification_number': 56507844,
    'item_consumption_1': 19358,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2470.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bdcc31ab-67fb-474f-9235-99307967d774',
    'identification_number': 12026242,
    'item_consumption_1': 1.483,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 547.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9417214c-950a-4e2d-bbf8-23c0604a10e2',
    'identification_number': 26954370,
    'item_consumption_1': 1245,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 486.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1900c92-6008-4765-97cd-f75382cd1602',
    'identification_number': 109869613,
    'item_consumption_1': 505,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 197.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '885fb1e1-7aa6-4ce7-a828-b944c39a0599',
    'identification_number': 35902051,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '868dbbc3-cfe7-44d1-9cc0-35b693d3f0b6',
    'identification_number': 102260460,
    'item_consumption_1': 7.22,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 172.22,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'baaa1d1e-e327-4053-b6a5-73115d2fd60e',
    'identification_number': 16165543,
    'item_consumption_1': 1522,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 1156.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fca25d84-b422-4bb2-9cec-10f0cbb44d2a',
    'identification_number': 103840923,
    'item_consumption_1': 69.56,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2023.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ab092095-9bae-468b-8efe-e44a1af37235',
    'identification_number': 59828080,
    'item_consumption_1': 5050,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2140.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c073485-b6d7-46f1-846f-e24073f39064',
    'identification_number': 14039362,
    'item_consumption_1': 21444,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2737.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d3e7603-8034-4df1-85a7-c8d3e04de52b',
    'identification_number': 93511388,
    'item_consumption_1': 1633,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 953.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49f97ebc-2e34-4835-9e45-25d4ab63c80c',
    'identification_number': 14619555,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 452.69,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'cb6d0ec4-448d-47b1-a194-fe04cfbc9415',
    'identification_number': 106980645,
    'item_consumption_1': 3251,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1266.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b739a34-3af6-4fb3-bb44-a6eec650062a',
    'identification_number': 12985058,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7756d653-b8f7-4b00-8b00-8bedc216e2b5',
    'identification_number': 53263081,
    'item_consumption_1': 36995,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13767.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73908f76-3192-4982-b81b-3de472f6af21',
    'identification_number': 10698825,
    'item_consumption_1': 2830,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1198.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70ca6121-b1ee-4dcf-8540-581759ae114f',
    'identification_number': 92950310,
    'item_consumption_1': 45.75,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1330.68,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '88ca5a8e-db97-454b-a8e6-f2a500f5de9d',
    'identification_number': 27549615,
    'item_consumption_1': 2880,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1221.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'acaaf7e6-6297-4da6-9396-e33a27577a22',
    'identification_number': 34681230,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '098c778b-d350-4047-b8b8-681b9893b9ac',
    'identification_number': 31640427,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 71.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c045884f-ae87-4d29-a879-dfd52cab6c29',
    'identification_number': 3030784010,
    'item_consumption_1': 430,
    'item_description_1': 'Demanda Ponta KWI-TUSD NOV/23',
    'item_total_value_1': 11413.22,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ac720481-5f23-4f29-8803-4f8d3b8d1696',
    'identification_number': 78329051,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f32fa13e-d14c-43d4-9144-1ea472ad6787',
    'identification_number': 19403224,
    'item_consumption_1': 1565,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 610.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54476872-62db-4015-9f18-41bb03eea427',
    'identification_number': 14135795,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 45.67,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fe873861-b61c-4220-99ce-4b9a8f743194',
    'identification_number': 16013239,
    'item_consumption_1': 2478,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1036.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1df139d-cd73-45ef-afa4-7b802088dbd8',
    'identification_number': 46590889,
    'item_consumption_1': 2064,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 803.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4248d83-c7bc-4e35-a09b-ddfe3d184d02',
    'identification_number': 105455172,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 41.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c754db9d-b047-429d-879f-d81ca1753653',
    'identification_number': 35766514,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cdd800c1-20d2-4698-b5ed-d3284d93ae46',
    'identification_number': 49457012,
    'item_consumption_1': 4995,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2917.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab4e76f9-de94-470f-a2a5-aa20d54c4253',
    'identification_number': 95962239,
    'item_consumption_1': 6384,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2702.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '511da919-ec63-4766-862b-f800030fe9cb',
    'identification_number': 106293257,
    'item_consumption_1': 799,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 338.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b528316d-ab69-40e5-9ae1-e526998be6a6',
    'identification_number': 48681261,
    'item_consumption_1': 1060,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 394.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88e65a82-56f7-45f1-823e-4ea2d7e1110e',
    'identification_number': 95466096,
    'item_consumption_1': 2519,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 981.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4808f3ca-8b83-4b4f-be6f-c2188aad466e',
    'identification_number': 101941714,
    'item_consumption_1': 36,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 14.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '289b5b7d-8d69-465f-985c-0e65f9370ceb',
    'identification_number': 3030777544,
    'item_consumption_1': 1497,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 446.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df550775-9425-4f44-837e-97adc9391aef',
    'identification_number': 105089060,
    'item_consumption_1': 51.02,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1483.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e293a77b-a88f-450b-a904-fe79d930e0cd',
    'identification_number': 12697451,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7afeca02-cf3d-4cfb-89de-6dfffd6de482',
    'identification_number': 109368380,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f2b3f23-1e52-4071-9620-173d3a249302',
    'identification_number': 40490688,
    'item_consumption_1': 2036,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3080.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9dba345f-ee90-4c45-8cf0-092b9f328fa7',
    'identification_number': 46349030,
    'item_consumption_1': 2544,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 995.4,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e8a6a744-b781-4f9d-b9a6-6eb8f0110bbd',
    'identification_number': 30085900,
    'item_consumption_1': 6.9,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 788.12,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a400fde2-4ae5-4650-b324-9a6127afc6cc',
    'identification_number': 19451601,
    'item_consumption_1': 17,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 6.64,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bbb29c06-883f-43d9-9b3c-27b75af5711e',
    'identification_number': 8708320,
    'item_consumption_1': "'-1.905",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 08/2023',
    'item_total_value_1': "'-661.96",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '30724b56-83b6-4b72-8131-0587fe5cccc7',
    'identification_number': 105083097,
    'item_consumption_1': 542,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 229.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1080f95b-a6f4-4436-aa5f-ca89ca829ee5',
    'identification_number': 19310188,
    'item_consumption_1': 141,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 59.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccfced12-7060-4e9a-923a-97d467cebbb8',
    'identification_number': 59438029,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5d6c02c-324b-40df-a837-0eee8ff45347',
    'identification_number': 79545793,
    'item_consumption_1': 69.08,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1647.59,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '582d1c2f-a943-4efc-b9e4-4490a9ddc29b',
    'identification_number': 79730701,
    'item_consumption_1': 90.42,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2629.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '30c1de2d-2651-45f8-b44e-50082a1ecd86',
    'identification_number': 13262599,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7eb41d75-82b4-4593-9683-08756dbe4980',
    'identification_number': 64175251,
    'item_consumption_1': 36519,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4661.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8df7abf9-3bb5-4a86-ab3d-a72de12f59c3',
    'identification_number': 98802739,
    'item_consumption_1': 386,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 151.02,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c2603cbb-fb9e-4d36-a238-d69b27902e1c',
    'identification_number': 41422775,
    'item_consumption_1': 21.54,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 626.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e6756cca-df7b-4378-bef4-18aafb69c30b',
    'identification_number': 19452900,
    'item_consumption_1': 328698,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 41953.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d865eca-21cc-4222-bd02-73f4c0feedc3',
    'identification_number': 14859424,
    'item_consumption_1': 21845,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8129.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9375554b-493d-4f2c-8435-71981985972d',
    'identification_number': 15685420,
    'item_consumption_1': 26.02,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 756.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7b08d531-434e-46dc-8caa-35cc858fd94e',
    'identification_number': 14138034,
    'item_consumption_1': 7.92,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 188.91,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '051be7e5-a151-4ac3-98c1-a1883a8fe5b4',
    'identification_number': 6189512,
    'item_consumption_1': 12131,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4514.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '863ff4ec-4850-4630-8d6b-f17cefe3cb50',
    'identification_number': 92333435,
    'item_consumption_1': 1813,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 706.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95f3ff71-d10f-4219-9e88-4e7d0fbd6cec',
    'identification_number': 17678757,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f1e8d7d7-b414-4576-bef3-05de4e5dfeed',
    'identification_number': 106814427,
    'item_consumption_1': 92.73,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2697.12,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '49541893-ee1e-4959-8f0f-b489e9f20b53',
    'identification_number': 14152037,
    'item_consumption_1': 283,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 110.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc6158d4-a658-49e9-a4c4-430d9667192c',
    'identification_number': 94650977,
    'item_consumption_1': 26,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 15.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28c1b607-100a-49b4-bb3b-d9513a7c0a72',
    'identification_number': 52358259,
    'item_consumption_1': 38272,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14242.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a2ee928-b17e-4451-ae4e-b8c5b109d87b',
    'identification_number': 102239436,
    'item_consumption_1': 9343,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3956.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '564f1661-057c-4dbb-a4a2-fc459d552cfb',
    'identification_number': 44001479,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '10149c2f-04ee-4ced-a667-d6c807a1439b',
    'identification_number': 109643372,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '956f0731-c98e-4195-b8ad-a3908135fb6d',
    'identification_number': 29957206,
    'item_consumption_1': 3773,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1474.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5869add-f1ee-4e15-9e2b-79cd983627e0',
    'identification_number': 41531728,
    'item_consumption_1': 1552,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 658.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ec6bc9a5-eb2c-4422-8406-d3338c34c106',
    'identification_number': 60203390,
    'item_consumption_1': 1213,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 473.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce6a37c0-d38e-4b97-a553-fefb2939f607',
    'identification_number': 91699959,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29b5f93a-cd4a-4347-a127-939d55681b5c',
    'identification_number': 19452667,
    'item_consumption_1': 45.96,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 2152.32,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c4cd81d7-6774-4848-bcaf-575579d6f8d4',
    'identification_number': 43597564,
    'item_consumption_1': 720,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 280.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '190519e6-a047-490e-b4ef-e4cdd02b1b6e',
    'identification_number': 20587163,
    'item_consumption_1': 2542.05,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 73937.93,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7fe083fc-2e17-453e-a8d3-deb60c1a0555',
    'identification_number': 32528620,
    'item_consumption_1': 1010,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 589.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd34bc075-d89d-49ba-a291-eaa2f5a41cc3',
    'identification_number': 106606964,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 207.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eef4e06a-a145-48a1-9d6b-8493f03172c0',
    'identification_number': 105978000,
    'item_consumption_1': 8320,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3242.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5297fbc-6e7a-4953-9535-eb21e408c200',
    'identification_number': 36338958,
    'item_consumption_1': 5384,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2104.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5efa9354-efba-4baa-a6ea-19e2ebfaade0',
    'identification_number': 69609780,
    'item_consumption_1': 1837,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 777.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddee7015-2210-44f5-a10d-0cb28bee5cde',
    'identification_number': 37134191,
    'item_consumption_1': 3135,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1327.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59ad03cf-ec99-4127-8f2e-722c20da1453',
    'identification_number': 94743100,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '07d0b22d-bc19-41c4-be2c-e6f3c5bd2617',
    'identification_number': 15719499,
    'item_consumption_1': 446,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 188.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c35be3b8-0d75-4fa8-9026-6d3f234e5ae0',
    'identification_number': 15522229,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a5b24c66-b235-4ab7-87ed-f8111c5da8fe',
    'identification_number': 107936704,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '86b0746d-4e58-4fd6-8949-c4f43d7c0276',
    'identification_number': 15731707,
    'item_consumption_1': 3099,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 2353.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c3aea49-a92f-4622-baf0-6037fd775c94',
    'identification_number': 74880462,
    'item_consumption_1': 2268,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1324.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71f3b817-ea91-46e2-906e-58a0864cab34',
    'identification_number': 42119057,
    'item_consumption_1': 873,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 370.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3687caa-ad6a-43a3-b528-ff9712f6e9d2',
    'identification_number': 42264316,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-4.93",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '650233f1-1473-4b38-b9d8-980f24eabe51',
    'identification_number': 36235334,
    'item_consumption_1': 14,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 5.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'abecd596-f486-47da-821d-4332e5f7b224',
    'identification_number': 69441952,
    'item_consumption_1': 5863,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2290.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9b43231-305e-4a4f-8183-6b5e2c7342c2',
    'identification_number': 93308965,
    'item_consumption_1': "'-7.09",
    'item_description_1': 'ENERGIA INJ. FP OUC MPT TE 01/2021',
    'item_total_value_1': "'-2163.59",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '80b1a06c-6ad4-4ca7-850e-dbd854db9363',
    'identification_number': 48795402,
    'item_consumption_1': 164,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 64.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2a6540c-a895-44f2-9efa-e4293a08473a',
    'identification_number': 69607214,
    'item_consumption_1': 4571,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1783.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '158589ae-7176-474e-ae34-31a874cdf4d8',
    'identification_number': 100343244,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c375b4a4-51eb-4a78-ae1c-56cf835e6ef5',
    'identification_number': 100387993,
    'item_consumption_1': 1604,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 679.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95e3e1be-55f0-4b05-9faf-e597a690e808',
    'identification_number': 16876709,
    'item_consumption_1': 50.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1467.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '36016790-c8e8-4bf4-aa97-78c934343f29',
    'identification_number': 102687560,
    'item_consumption_1': 4503,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6812.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6cf74687-b64f-4d39-a06a-9ee7a1b36a5c',
    'identification_number': 106612662,
    'item_consumption_1': 355.32,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 10334.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '72fca6be-fab9-46d5-8e33-0b48b0b6bc57',
    'identification_number': 10400184,
    'item_consumption_1': 8760,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3416.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42413a43-df97-4115-bbd6-1096f5c841e7',
    'identification_number': 48999881,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3a066dac-fc12-46a9-aa59-ea84ace7ca26',
    'identification_number': 11708522,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cb416916-689d-411b-9daa-0691e164e5b8',
    'identification_number': 107956403,
    'item_consumption_1': 10.1,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 240.89,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c59a9901-74b3-4e5c-b1fb-1d107290197e',
    'identification_number': 56812477,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7898b94c-6455-4e63-a53b-5607d8bbddb0',
    'identification_number': 923333479,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a9db6c2-44e6-4a10-bd30-4fbba538cc4e',
    'identification_number': 70080615,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b7091e8-9bed-4d83-8838-6035c0d74488',
    'identification_number': 110008758,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b3dd439-3cb7-45dc-bf45-95221a39ba05',
    'identification_number': 95633359,
    'item_consumption_1': 11.06,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 263.8,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd38436de-b9f4-4ae1-9779-d84c38463fa9',
    'identification_number': 99628554,
    'item_consumption_1': 885,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 345.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19e040f4-35bc-4eb7-b9bd-295aed9594cd',
    'identification_number': 106583840,
    'item_consumption_1': 1207,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 510.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b877ba70-41a2-4d22-9e6d-d49c9fae516a',
    'identification_number': 91670942,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c329c492-c79d-48c0-b903-2913b9736cb0',
    'identification_number': 29966060,
    'item_consumption_1': 3280,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 418.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '449e0b5b-3bcd-449d-a3eb-5ea4b9f0f5ff',
    'identification_number': 77826191,
    'item_consumption_1': 207,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 87.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ad10b0c-d94c-4224-95a2-95bc8c9a44ca',
    'identification_number': 15843939,
    'item_consumption_1': 6770,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2640.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df699640-a495-4b90-bdea-4e81b9544c79',
    'identification_number': 38847280,
    'item_consumption_1': 0.33,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 9.59,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'abeb5f09-0438-447e-a9be-7f3a82653368',
    'identification_number': 111295890,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '166c4dd7-30ab-4601-92ba-51f23c59beb1',
    'identification_number': 12101397,
    'item_consumption_1': 59,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 22.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0427a3fb-5777-4049-b4fc-a3c8cd3cbed6',
    'identification_number': 99541076,
    'item_consumption_1': 298,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 126.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a7953c0-5b9b-4272-8975-b4a83ef048e5',
    'identification_number': 96128003,
    'item_consumption_1': 724,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 282.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fdc1833-d6df-4981-a670-0dc47b976457',
    'identification_number': 13789430,
    'item_consumption_1': 96,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 145.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a8e41c7-4860-4141-a268-9b60c2f9c59f',
    'identification_number': 14562553,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8cfbaa8c-5c99-4ef7-a646-0e40296df6c2',
    'identification_number': 106643517,
    'item_consumption_1': 59.13,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1719.82,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '850e0155-8b4a-4325-8255-ae2b51d168ea',
    'identification_number': 94576165,
    'item_consumption_1': 3922,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1664.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab6ae4f2-a8ac-4bfe-94d7-983c904d7fdf',
    'identification_number': 107297671,
    'item_consumption_1': 26987,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3444.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e791b3a2-0876-4325-97a7-8f2bb751d80d',
    'identification_number': 16915470,
    'item_consumption_1': 831,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 352.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6eadc047-684e-4cea-9d9b-c05b0caa50d9',
    'identification_number': 59820535,
    'item_consumption_1': 503,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 196.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9378943c-0763-43dc-878a-909334740f1c',
    'identification_number': 69948100,
    'item_consumption_1': 8446,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3295.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e37e4a46-c35e-4461-aa7c-043e855199ba',
    'identification_number': 6167187,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '845aadd8-3a6a-42ed-bbd2-74b2240a12da',
    'identification_number': 23660872,
    'item_consumption_1': 1556,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2353.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe230e2d-7fe5-450f-8465-d7b76ea4fd8a',
    'identification_number': 110920813,
    'item_consumption_1': 497,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 194.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f05dc2e-f2ae-443f-85e4-6c9ab345a678',
    'identification_number': 111267366,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c3f675d4-0a4c-4eee-affb-1f11a3e89dd0',
    'identification_number': 52599400,
    'item_consumption_1': 29.61,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 861.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fbf588bb-fa60-4489-ada1-966391349489',
    'identification_number': 93430442,
    'item_consumption_1': 2214,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 282.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcc98c91-3d28-4090-b637-afa2f60a63ec',
    'identification_number': 80350844,
    'item_consumption_1': 1053,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 412,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '297767d5-654a-4940-87ab-31cdbd8ffe9f',
    'identification_number': 14824558,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a10a1ca0-6afe-4b91-9285-9ecd45cb87e5',
    'identification_number': 48827657,
    'item_consumption_1': "'-7.113",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 04/2021',
    'item_total_value_1': "'-2468.82",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'a56b2ae8-e880-46ea-a9cf-c7af6a99c4a7',
    'identification_number': 92564186,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '846308c3-2587-4b48-bea1-621e2b0bb659',
    'identification_number': 75062046,
    'item_consumption_1': 307,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 179.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ec2ebe3-15a5-4d53-94fd-8977d43e96cf',
    'identification_number': 70059810,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '424dee39-359c-4864-a02b-515736f97766',
    'identification_number': 111076579,
    'item_consumption_1': 240,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 101.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dff742a3-af76-4731-923d-09146cf91769',
    'identification_number': 33569932,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.99,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '13931423-20c9-42e6-a57e-3a81ad62f4b9',
    'identification_number': 92865534,
    'item_consumption_1': 17807,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6947.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b46f403c-d30f-4df5-a033-2da7fd89c515',
    'identification_number': 99540584,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15078b8e-1d8b-4e91-b2b6-e70a34affc84',
    'identification_number': 70427054,
    'item_consumption_1': 69035,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8811.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90d24080-6db7-4347-a5cd-264938d935c9',
    'identification_number': 48449520,
    'item_consumption_1': 411,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 160.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36653ce3-fd04-40ba-aacd-bb941f44c5cb',
    'identification_number': 9834001,
    'item_consumption_1': 458,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 194.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac5426a5-2542-4a36-855c-3990bcca29c4',
    'identification_number': 36337803,
    'item_consumption_1': 2019,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 854.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b1f1d2f-5116-4e98-a5be-4ca4e9386364',
    'identification_number': 9834001,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-25.85",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '4d0910ae-2c26-4488-9c41-0783d018d264',
    'identification_number': 97837350,
    'item_consumption_1': 2.73,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 65.12,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f7dcd927-9f2a-4bc1-9887-bdf5687b63e6',
    'identification_number': 84156171,
    'item_consumption_1': 73,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 28.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a3ce0d1e-0014-4e00-9a0e-e0b160295cc3',
    'identification_number': 94699020,
    'item_consumption_1': 8105,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3432.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '669203c4-fdc4-4a1d-bdf9-3e949e114c5c',
    'identification_number': 18138373,
    'item_consumption_1': 39.45,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1147.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd3e47945-a86d-4a4f-b08a-7ed4d95f0ec0',
    'identification_number': 11117540,
    'item_consumption_1': 197,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 83.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c85e6877-9a2e-426a-9d5c-0c33b40d156b',
    'identification_number': 11679751,
    'item_consumption_1': 373,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 564.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0474c00b-4031-4ef1-82d7-a6305096cede',
    'identification_number': 60767928,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3ed44fb5-c3d8-4226-bddb-503ac7a79a50',
    'identification_number': 102131813,
    'item_consumption_1': 16035,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6810.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa907e56-0ca4-4afd-bafc-636c5f2603cd',
    'identification_number': 69758409,
    'item_consumption_1': 6.47,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 154.33,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2ab2dfb5-2c05-442f-bd31-0557fd2ca634',
    'identification_number': 13299573,
    'item_consumption_1': 5099,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1986.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5b38f9e-31ad-4455-97b8-13cbfdbc3c12',
    'identification_number': 71720979,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 91.43,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'dbd35a5f-05f1-4889-8eae-34a2e4a2d548',
    'identification_number': 95563610,
    'item_consumption_1': 3832,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1426.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea6deb6b-84c9-4106-a235-19061f7f5693',
    'identification_number': 24017353,
    'item_consumption_1': 3.34,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 79.67,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0c01069e-f59b-42b8-9652-49d32dc5df68',
    'identification_number': 93313357,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1197c408-61e2-46a5-bc10-66e2cad0d12b',
    'identification_number': 95012818,
    'item_consumption_1': 1782,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 696.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd15edbb9-8a6a-4b69-a380-4f3c7ebd20f6',
    'identification_number': 101093497,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '34672f38-cfea-40c7-ac52-a4e0c9f7c0e1',
    'identification_number': 31564496,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd07e982a-0415-4e14-ae24-9cebab5e697c',
    'identification_number': 110411196,
    'item_consumption_1': 132.69,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3859.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'df43a563-f4ce-4e8f-af93-a077fcd111ba',
    'identification_number': 68081251,
    'item_consumption_1': 1906,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1113.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '608f676a-c39c-40ce-a7de-b79d887627c9',
    'identification_number': 38226928,
    'item_consumption_1': 1730,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 675.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5dc8fbca-12ad-4007-a365-ed6c3d7267da',
    'identification_number': 32098120,
    'item_consumption_1': 10.52,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 492.67,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7421f41f-b065-4052-b7f9-991454176fa9',
    'identification_number': 111833884,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eb44b06c-9600-4a4e-98eb-5b037002e2e0',
    'identification_number': 104356014,
    'item_consumption_1': 178,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 75.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a98f8c8e-5082-4365-92be-158842de0971',
    'identification_number': 103561048,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55bd1dcd-3a14-42c4-b9b3-65eeaa4ca271',
    'identification_number': 71381651,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87d172c1-1122-47a0-b32e-044ba45c16db',
    'identification_number': 53507460,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8096dc1-391b-4c61-8055-dccdc4b504ab',
    'identification_number': 22518967,
    'item_consumption_1': 1608,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 627.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e400a203-175f-4792-9a02-cbf4cf0449b7',
    'identification_number': 104616890,
    'item_consumption_1': 1816,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2747.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff1de23e-2e41-4bac-b5d3-6f64150bd5fe',
    'identification_number': 32927061,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '269b577c-36d2-49e3-bcf9-fc71b1c5313f',
    'identification_number': 19453779,
    'item_consumption_1': 884.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 25727.08,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '614821eb-d9d2-4007-a3ab-8f2b886388db',
    'identification_number': 105186520,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3437.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8559fdcd-e5db-4955-b7b6-ee4d3eb463f6',
    'identification_number': 105805572,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-10.0",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '5695f84e-662f-4517-9488-d4ca3c100208',
    'identification_number': 93313357,
    'item_consumption_1': 4119,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1606.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b55ddf5d-0dc2-4c89-a34c-fae6f3574b2b',
    'identification_number': 91781485,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c23dc26e-854e-4847-8f2c-d1b3d6cff1f7',
    'identification_number': 40517780,
    'item_consumption_1': 1376,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 536.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b9f061f-ae98-4b93-abdb-667ff059b227',
    'identification_number': 17720028,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 481.84,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1642370d-d129-4723-adcd-68558108bf00',
    'identification_number': 73533424,
    'item_consumption_1': 5521,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2152.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6ba06cb-95ae-4a5a-9721-a9aca38e7660',
    'identification_number': 104718242,
    'item_consumption_1': 1423,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 554.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76f75c11-213f-47bc-88a9-af4f49d9f14d',
    'identification_number': 23691530,
    'item_consumption_1': 47.74,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1388.56,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6c15329f-daff-4500-a633-edb2bac5ee49',
    'identification_number': 31564496,
    'item_consumption_1': 112,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 43.8,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5e96bdcd-2837-4fa0-a6d4-97dae24fb9f0',
    'identification_number': 14952688,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '611244a2-6f25-4e59-8f98-39946821a609',
    'identification_number': 70651515,
    'item_consumption_1': 10988,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4292.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd92ae87c-64e1-459e-a99f-7dc3129269b8',
    'identification_number': 22846670,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 115.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0f32b3eb-3bde-4d2e-a04f-21e70c0db040',
    'identification_number': 98253247,
    'item_consumption_1': 381,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 161.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2b30e73-184e-42ce-a464-fe960d32d90b',
    'identification_number': 10471847,
    'item_consumption_1': 3274,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1274.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c75afe7-dc15-422e-bce8-b1573c8b4267',
    'identification_number': 101138717,
    'item_consumption_1': 214,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 83.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62d53650-e0a2-48bd-ba10-4d654c0c1524',
    'identification_number': 83865349,
    'item_consumption_1': 970,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 379.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e4b5803-ba46-42f0-a661-3950e2e98c04',
    'identification_number': 104457260,
    'item_consumption_1': 268,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 113.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af611803-76d6-4020-b7b1-7c2d8e8f08c5',
    'identification_number': 18616917,
    'item_consumption_1': 47383,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6047.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5dbbe84f-f378-4b33-bdf1-ff3c6ae07d23',
    'identification_number': 17697743,
    'item_consumption_1': 970,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 410.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb06f2b4-786b-4151-8b14-24c4653dc4a2',
    'identification_number': 109089553,
    'item_consumption_1': 103,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 40.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cdeb852-951c-40b3-b5e5-0d5b915e5bdc',
    'identification_number': 91303516,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e9d25ee-9953-4863-94d3-7e1337f20f25',
    'identification_number': 27271633,
    'item_consumption_1': 3271,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1390.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74104237-1cf7-457c-868e-6c66f2116db3',
    'identification_number': 34842110,
    'item_consumption_1': 2630,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1114.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01b95c3c-c27c-46b4-abf5-a98f29f46232',
    'identification_number': 12804940,
    'item_consumption_1': 1193,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 465.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d450919-da0e-423d-a8e6-813718ad14bc',
    'identification_number': 6338526,
    'item_consumption_1': 2123,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1239.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df565caa-f8e4-4f5d-a524-17156a349d5e',
    'identification_number': 96211008,
    'item_consumption_1': 3442,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1459.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '598f0d5f-ef7b-423f-b78f-008813facedf',
    'identification_number': 76778606,
    'item_consumption_1': 1578,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 670.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de0eb891-9713-4be9-b005-fc7be4435233',
    'identification_number': 66198666,
    'item_consumption_1': 43.88,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2505.97,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e933cbe2-c5ab-4dd2-a51d-6300d828e47d',
    'identification_number': 15158241,
    'item_consumption_1': 20.31,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 590.73,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '209d1ded-4f77-4fed-ab04-a0ad5d0686d5',
    'identification_number': 97851256,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d52db0e-7af6-467a-a6da-c86b2143d5d1',
    'identification_number': 45546428,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '751ffa1b-9a20-49e6-969c-a5c10b831231',
    'identification_number': 32303912,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1370.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5d7bda9-25b8-4d8e-835a-5229513a53cc',
    'identification_number': 33375453,
    'item_consumption_1': 0.68,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 16.24,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd0441a47-bfcb-4421-ad3e-dfbddb2e7720',
    'identification_number': 94211272,
    'item_consumption_1': 333,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 130.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d978966-38ae-4675-86e6-a8ea90205392',
    'identification_number': 108689042,
    'item_consumption_1': 5578,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2177.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '378627ca-4c1c-4aa8-9414-1e74d7fb3f8a',
    'identification_number': 108369650,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.06,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd1b38606-e01a-4426-b7e4-0928288d05f4',
    'identification_number': 5415870,
    'item_consumption_1': 1445,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 184.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94b57571-e36c-41a4-84ba-5a9110ce912d',
    'identification_number': 40801250,
    'item_consumption_1': 280,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 109.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e20301b0-c96a-4fd3-ba29-47733e62039e',
    'identification_number': 32348533,
    'item_consumption_1': 19.85,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1133.62,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a4d571c7-822a-435c-8674-ed7aa6145834',
    'identification_number': 97637238,
    'item_consumption_1': 28.33,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 824,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e04cf4ef-ffc4-4093-8f2a-4959daaedbd6',
    'identification_number': 99441047,
    'item_consumption_1': 11626,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4921.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a44fb582-1026-482e-b961-03d5612b7401',
    'identification_number': 14474301,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 32.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5d7f2613-d5a3-4645-b8b1-ef8dd0029d12',
    'identification_number': 18768695,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 207.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34197064-5066-4f86-b04d-cb6caeb02f04',
    'identification_number': 93231903,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 9.26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '82779c97-97e0-4ec1-ab4e-b4ced322bd27',
    'identification_number': 35315628,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b51fbd2e-97c5-428e-8f09-6b481bd58beb',
    'identification_number': 40254836,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 2633.16,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'f9ec5a19-5224-4c25-b4b4-5f7b4cf2b2b5',
    'identification_number': 92912290,
    'item_consumption_1': 8378,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4893.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4f367ac-b4b0-4ca2-b8e5-f6730fd42134',
    'identification_number': 44873565,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '324460e6-a98f-4c7d-8007-931e8d7da2b8',
    'identification_number': 9105760,
    'item_consumption_1': 2342,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 914.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76ae652f-4325-4848-82f2-82338b3b9dd1',
    'identification_number': 69492026,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '49d14730-c042-4fc7-8805-2941192c5d26',
    'identification_number': 15909581,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 69.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '759e1d5d-1a86-4804-a762-43b71db458fd',
    'identification_number': 95830260,
    'item_consumption_1': 1460,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 569.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93edc308-f635-40b9-a86e-b8285ca82402',
    'identification_number': 95609679,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e0f0fa4-6281-4f26-9890-52222f16f359',
    'identification_number': 949190432,
    'item_consumption_1': 4031,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 3514.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39717d58-7afd-4f4f-8fea-e77573b10a72',
    'identification_number': 98802739,
    'item_consumption_1': 36607,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13623.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79e4920b-d262-45fa-b277-bc016f13ee31',
    'identification_number': 77411609,
    'item_consumption_1': 437.18,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 12715.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6a901767-9aaa-47ab-9048-ea96f7b2bc42',
    'identification_number': 33625930,
    'item_consumption_1': 67628,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8631.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e127e902-78dc-499e-b324-0d8148439c4b',
    'identification_number': 101937792,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2a23116a-1a0f-42a1-88c9-8c2c6e974c08',
    'identification_number': 84354925,
    'item_consumption_1': 613,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 259.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56fe6f07-0723-4c45-8af9-2054c45464f8',
    'identification_number': 33101493,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f2e0d9b2-a37a-43ca-a592-2b9d1d4537a8',
    'identification_number': 16882881,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd01b7329-e51f-41c2-9bd7-4b99cb874ac0',
    'identification_number': 48827657,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf974ff8-2640-4c5e-9f45-2ee2aa7b95a7',
    'identification_number': 11222549,
    'item_consumption_1': 22560,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 9560.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79d796a6-5e8e-494b-9157-a67576ca535d',
    'identification_number': 84983981,
    'item_consumption_1': 4004,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6057.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1da76412-8bb2-4e08-a032-7f1886420830',
    'identification_number': 43814166,
    'item_consumption_1': 19011,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7074.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a97a1dd3-9a22-4c4d-be5a-a068ee845aaf',
    'identification_number': 20934947,
    'item_consumption_1': 1376,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2081.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cea2f51a-1956-4814-acd2-b5aa71c1d0d7',
    'identification_number': 107376245,
    'item_consumption_1': 112,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 47.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38e305c6-ccfb-451b-844c-de76363dfa18',
    'identification_number': 110543360,
    'item_consumption_1': 46739,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5965.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a127e694-358f-4c32-b420-f3e08b51d737',
    'identification_number': 107956403,
    'item_consumption_1': 39.9,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1160.52,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f71f0e82-e0dd-4e68-915e-143eadac8c32',
    'identification_number': 91481740,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3708c962-e8c9-4486-a9c4-45ccc8f7dec3',
    'identification_number': 3030777250,
    'item_consumption_1': 533,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 269.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b0502fa-e853-4c5e-b163-3646129e60d4',
    'identification_number': 33119635,
    'item_consumption_1': 40,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 15.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b54dae3-1563-4f5d-bcb1-a0733a52e25f',
    'identification_number': 5680638,
    'item_consumption_1': 52,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 22.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae15f917-ae67-4ee4-9027-7792b399a2ac',
    'identification_number': 10610472,
    'item_consumption_1': 1029,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 400.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '208e4f14-77e5-4a16-97d3-6f9d2a414dd9',
    'identification_number': 95710094,
    'item_consumption_1': 1860,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 724.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4417193-b99c-4b7d-b14d-300bd2226696',
    'identification_number': 71175644,
    'item_consumption_1': 6724,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2856,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb433c47-1524-4e06-9346-e79a5ea6653d',
    'identification_number': 48018422,
    'item_consumption_1': 7326,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2861.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '012ab02a-409e-41ff-8d9c-d3d57c85785d',
    'identification_number': 13789430,
    'item_consumption_1': 1058,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 393.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3cdc0a23-72f9-46ab-84f6-56bd7a11f8f6',
    'identification_number': 63137399,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '19e49e63-fc1e-4106-b0ac-0d87c9361ad2',
    'identification_number': 17671973,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.54,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5c7c6cec-ae5a-4ed8-94c9-05082957808e',
    'identification_number': 98265172,
    'item_consumption_1': 8294,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1058.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7cfef7e-1d6b-41cc-be8c-0f5f44ec01b7',
    'identification_number': 44774494,
    'item_consumption_1': 9376,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5475.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ea39812-f4ee-4c11-8b98-3cbe729d3377',
    'identification_number': 85258105,
    'item_consumption_1': 3049,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1191.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a2d48f1-42f8-444d-b069-4cfdc2ccdac1',
    'identification_number': 99705257,
    'item_consumption_1': 497,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 210.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e149b04-dc50-4e5d-ac5f-160a1c7b0439',
    'identification_number': 11685913,
    'item_consumption_1': 2965,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1154.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51f08680-459e-4c6c-92ea-67e8b4f8e13f',
    'identification_number': 3031341162,
    'item_consumption_1': 3130,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 933.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14f2919f-8ba2-4622-8e53-f045bae610fa',
    'identification_number': 70447330,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f2d6967e-4f62-47af-81ec-d4030ad692c9',
    'identification_number': 100185860,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6cb8e6c-b37a-47b6-94ca-3042f65337eb',
    'identification_number': 68029098,
    'item_consumption_1': 62.21,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1809.42,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '505806f1-3d9a-4f97-9584-485810c6edb0',
    'identification_number': 105154180,
    'item_consumption_1': 1297,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 551.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ceda9010-f566-4ee3-b787-29a5d19cd45b',
    'identification_number': 42967236,
    'item_consumption_1': 1103,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 466.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '752078c1-7ec0-42bc-a464-b3dab2c03c4f',
    'identification_number': 12384933,
    'item_consumption_1': 24,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 9.39,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '49e3b75f-d25b-4cde-83c0-7f2815a8f04b',
    'identification_number': 5564352,
    'item_consumption_1': 3995,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1696.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66e9ee44-cde8-4d1a-8202-88f62e97fc56',
    'identification_number': 106601253,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72621296-78cc-4410-aa57-58de29e8e3f4',
    'identification_number': 82724873,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '927a8c2b-8208-4801-81fc-cfa92563be3b',
    'identification_number': 98105248,
    'item_consumption_1': 7286,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3086.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c62ff73-1322-4ed2-99f3-35230523302a',
    'identification_number': 58741070,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b07f4713-1eda-4cde-8bd1-a46b10c145a5',
    'identification_number': 59485230,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2bf31fe6-32a5-4198-bb5e-5499d124536f',
    'identification_number': 42089808,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7419c217-8fb5-477a-adf4-92ee09659f19',
    'identification_number': 109909704,
    'item_consumption_1': 2733,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1161.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '295c2254-8193-42dd-9c4c-639fee09cbe3',
    'identification_number': 17159679,
    'item_consumption_1': 17422,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2223.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7d41575-0ec3-4e9f-82ec-3c7d37f266bf',
    'identification_number': 12743542,
    'item_consumption_1': 23164,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2956.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be64a76b-08b3-4e4e-844c-d0985a3a29af',
    'identification_number': 20195303,
    'item_consumption_1': 2040,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 796.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01396e86-378f-4e99-b70f-4e988e60a282',
    'identification_number': 10980997,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 208.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd5d71e9d-b04e-4189-b584-d25de5ea9840',
    'identification_number': 80724906,
    'item_consumption_1': 3727,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5638.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5335aebc-9eb7-4ddb-b5b4-8b03089e0393',
    'identification_number': 27316114,
    'item_consumption_1': 6950,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 887.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '531dd07f-2db9-44ad-8726-fb2ef6e60c48',
    'identification_number': 107466732,
    'item_consumption_1': 1210,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 706.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe3815b3-c169-42e6-90b2-e9a4e8e947eb',
    'identification_number': 98271903,
    'item_consumption_1': 59,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 25.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7db3659e-09bb-4ddc-856b-01d7661cbd66',
    'identification_number': 9889566,
    'item_consumption_1': 5610,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2188.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '992beda2-1352-4923-b1ac-5d6e2d9d9c54',
    'identification_number': 53573749,
    'item_consumption_1': 99612,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 12714.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3b89e4a-73c5-44b2-9848-69ffae4c1649',
    'identification_number': 61331180,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 35.33,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f7d35f8-8578-49b3-b30a-bdf774951cdd',
    'identification_number': 35183543,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.66,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '33e0d793-ea0a-4507-ba57-d695386ddbb5',
    'identification_number': 98833715,
    'item_consumption_1': 4905,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2083.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cbb32310-b0d3-458e-90db-8e4b5e1b3e8f',
    'identification_number': 107699265,
    'item_consumption_1': 332,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 140.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb693599-06d9-40da-bacc-023f49da4da6',
    'identification_number': 18077838,
    'item_consumption_1': 2383,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1011.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc8f2cfb-7bd8-4695-b348-97ed211bcf57',
    'identification_number': 21170568,
    'item_consumption_1': 435,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 184.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2199f2b-1174-4585-9689-8e216ea87037',
    'identification_number': 111114683,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f32ddb4-e812-4e3a-899c-b8b1e8a5ee12',
    'identification_number': 103087605,
    'item_consumption_1': 556,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 236.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41fa8511-6871-4df8-ba7a-87dd94395454',
    'identification_number': 16111460,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 40.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8f03ffd0-1697-4328-8dea-c9bd0197ad32',
    'identification_number': 88094030,
    'item_consumption_1': 124,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 48.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4426e8aa-785a-4a7d-87f4-3e174d8326e6',
    'identification_number': 26275287,
    'item_consumption_1': 11,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 6.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a8ae07a-d50b-4b6d-98a8-fcadc3a75408',
    'identification_number': 58738428,
    'item_consumption_1': 2.07,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 120.41,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'fba88b3f-20a1-4eae-9589-a4a6c586c695',
    'identification_number': 31139388,
    'item_consumption_1': 732,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 310.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '09b01a56-3677-4166-a222-8f4bc9327fdb',
    'identification_number': 9841709,
    'item_consumption_1': 6394,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2497.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0616d8ec-1db1-4a70-968e-79923eabe400',
    'identification_number': 106846779,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e8b5c0c-3221-46cf-a06c-d02e79d900b3',
    'identification_number': 99050226,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5e475785-16f9-4866-96ed-3a69fa0ed0cf',
    'identification_number': 36523402,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '60159705-7cc6-48ec-b4e5-de024a1c3fef',
    'identification_number': 110238826,
    'item_consumption_1': 4899,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2079.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a00337ad-fac0-4122-b42e-f94cf535f0ea',
    'identification_number': 103799125,
    'item_consumption_1': 345,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 133.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e270218-a39e-4434-a131-2ce098b108cf',
    'identification_number': 109676173,
    'item_consumption_1': 85,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 36.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aacbc607-b18e-4df1-b042-dd15bab7761f',
    'identification_number': 30674620,
    'item_consumption_1': 8719,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3705.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '871ad695-26ad-4c7f-b711-a23104fef22e',
    'identification_number': 33143986,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b081b66c-1e10-435c-9997-3b6220250bf8',
    'identification_number': 111226384,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd612a29-dad3-4df6-a80e-c5dcfeece28b',
    'identification_number': 15002578,
    'item_consumption_1': 93.2,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2710.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '533bd15d-3499-4402-9c98-2a53fb5f0d17',
    'identification_number': 45483779,
    'item_consumption_1': 53133,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6781.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e8314b8-e931-4523-b1bd-88e2fa2e78b6',
    'identification_number': 101232772,
    'item_consumption_1': 179,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 69.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6be4e9ff-802c-4b68-8537-ce7eaa43024c',
    'identification_number': 42694310,
    'item_consumption_1': 52970,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6760.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41edc4c1-86fb-4902-8d0f-863f694d7b3a',
    'identification_number': 92140394,
    'item_consumption_1': 13726,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5108.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53d79ffd-4f8d-437a-999f-1b914268d0ab',
    'identification_number': 44001479,
    'item_consumption_1': 27.6,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 802.75,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6db9e3a4-2f46-42b5-9d55-5bdfdf86f5b2',
    'identification_number': 77818750,
    'item_consumption_1': 2927,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1242.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41092ff0-5779-4ee6-8802-e9e2dd3cd039',
    'identification_number': 104732156,
    'item_consumption_1': 4204,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1639.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce6a2cd0-f0fc-43e0-9208-cd6927e2d5c3',
    'identification_number': 80201555,
    'item_consumption_1': 4903,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7417.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54b8e3f4-c779-4a3b-8e6f-0899f56c50e9',
    'identification_number': 78478782,
    'item_consumption_1': 4955,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2103.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ed27d6e-5861-4e3d-9af5-34092dd7ffce',
    'identification_number': 103025758,
    'item_consumption_1': 8.21,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 195.83,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ade3aac5-ed61-4627-81fc-95402f46d6be',
    'identification_number': 26275287,
    'item_consumption_1': 30275,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3864.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e66584b-5874-48fc-9e63-3b82b19a1f92',
    'identification_number': 90439325,
    'item_consumption_1': 3586,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2094.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f1247cb-b98e-4afb-acf6-18b2e26cb7ef',
    'identification_number': 36648329,
    'item_consumption_1': 18700,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2386.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2f3174e-ef68-40a0-b46f-60eb85182129',
    'identification_number': 12918385,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ecf770e9-971d-4984-aa9a-2498d2d4256d',
    'identification_number': 96839775,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ce03c437-ee31-4e14-bde2-d9a0db9d1aa4',
    'identification_number': 36222852,
    'item_consumption_1': 1682,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 982.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3a2b886-cd8f-4ed1-a807-9cdde4ee3122',
    'identification_number': 67678564,
    'item_consumption_1': 16.11,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 468.57,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b399967f-e393-42dc-add1-fa7b082fe226',
    'identification_number': 480380,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Públíca',
    'item_total_value_1': 95.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'defbc65f-412b-457b-8274-b48ea54395af',
    'identification_number': 44219393,
    'item_consumption_1': 123.88,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 7074.76,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '62050b7d-274a-40a6-8468-5bcb906e1d6e',
    'identification_number': 103840923,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9608ee8b-0baa-462b-9aab-20a9457f7408',
    'identification_number': 22734318,
    'item_consumption_1': 1.58,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 598.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c845098f-8646-47b7-84ea-49311e33e9f7',
    'identification_number': 98233920,
    'item_consumption_1': 265,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 112.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'befdad6d-3873-4ec6-91fe-2de63efcd807',
    'identification_number': 43083315,
    'item_consumption_1': 1532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 649.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24fe2fe2-3380-4568-9556-3d8eb34ca820',
    'identification_number': 77217896,
    'item_consumption_1': 22651,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8429.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'abb2fa8b-403c-4ab3-a7ca-1f86fa2cb749',
    'identification_number': 935540210,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '431dfd95-f57a-4c33-bbd4-72dd228c25aa',
    'identification_number': 105660434,
    'item_consumption_1': 703,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 298.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e079081e-6212-412e-9156-a650c2473c82',
    'identification_number': 76606473,
    'item_consumption_1': 1446,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 565.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1fd2af6-7133-4208-b3bd-71ba1c7b2f77',
    'identification_number': 32629095,
    'item_consumption_1': 23.47,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1365.29,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7e60f3e0-f06f-465d-8bba-ee33f0d65da7',
    'identification_number': 105418188,
    'item_consumption_1': 347,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 135.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ff6832dd-7b0d-40f7-bccc-1134f1afa2eb',
    'identification_number': 59162015,
    'item_consumption_1': 5257,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2047.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd529b09-7e79-48da-a8bd-ea9c52d0002a',
    'identification_number': 63401568,
    'item_consumption_1': 3111,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1321.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '686179db-1524-439e-b459-468eb70c1c83',
    'identification_number': 67097898,
    'item_consumption_1': 35.22,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1024.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '67227887-e81e-49d6-84d3-a6d769b7da67',
    'identification_number': 32266847,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '92f367e6-bfec-4dc8-b343-77e1729601f1',
    'identification_number': 31846882,
    'item_consumption_1': 103,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 40.29,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c8d649b2-2472-4b3b-8a48-cb26e20db81c',
    'identification_number': 107086638,
    'item_consumption_1': 3710,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1449.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46311538-d62e-4785-ae3e-0ffa8d8bbcfc',
    'identification_number': 58738428,
    'item_consumption_1': 80,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 31.3,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd20f2af5-7404-4c08-89cc-34dec7f28811',
    'identification_number': 19450133,
    'item_consumption_1': 4873,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2846.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05cbde7b-54ab-49e7-a20f-89a15df71679',
    'identification_number': 99570181,
    'item_consumption_1': 191,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 80.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3620e28b-c697-4ad0-ae98-7971e4c3c8a7',
    'identification_number': 69617244,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 71.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f9790e31-305d-4891-8f8a-c966c892f06c',
    'identification_number': 106104438,
    'item_consumption_1': 44.13,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1283.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '946e5b56-719e-4c5c-a693-79f559675d2c',
    'identification_number': 62557777,
    'item_consumption_1': 1410,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 823.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c42adca-3c63-48e7-a249-d100dcab4e1a',
    'identification_number': 105816280,
    'item_consumption_1': 3.43,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 81.83,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f4149461-75b5-4e52-a39c-738025e24972',
    'identification_number': 18616917,
    'item_consumption_1': 108.63,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 3159.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5e4b16b4-eb9e-43c5-a766-165c7f64b044',
    'identification_number': 38091844,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c27da2a5-9ff2-4e7f-837b-1dc413f18e23',
    'identification_number': 85312185,
    'item_consumption_1': 1.27,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 59.48,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '487de9c1-80f6-4aed-9793-4e48e109fe62',
    'identification_number': 32519036,
    'item_consumption_1': 2944,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1719.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53705c8c-5fd2-4723-9755-c9e5fcf3d4a5',
    'identification_number': 97224596,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '617bf3f5-1ba0-494f-bd99-380637a7ea05',
    'identification_number': 91823455,
    'item_consumption_1': 293,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 114.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'baeb8793-5dd1-4d64-b24e-c2c845f3604e',
    'identification_number': 8866163,
    'item_consumption_1': 4186,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2444.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa7c6214-b9e3-4151-a125-b6f57e580c87',
    'identification_number': 17775418,
    'item_consumption_1': 3551,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1505.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e9394a8-3018-41f2-ac9d-7d7ae2466e0a',
    'identification_number': 103852468,
    'item_consumption_1': 181,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 76.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52ebdde2-4545-4c75-b7d6-89635ad46fb6',
    'identification_number': 86773526,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.93,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '60cb161c-16af-4953-8fe0-deb13c852132',
    'identification_number': 40244040,
    'item_consumption_1': 2516,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 980.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5397183b-9063-496c-8c80-17200122ff22',
    'identification_number': 19450672,
    'item_consumption_1': 13890,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 8112.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76b70009-4e20-4f55-ab59-38c5fb0a6f13',
    'identification_number': 5330351,
    'item_consumption_1': 8055,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1028.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9462cc33-c96e-4718-8daa-1d23a371d8f9',
    'identification_number': 110228278,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5a5ddfe-b10f-4d17-934e-a4dfaf3786b2',
    'identification_number': 30427290,
    'item_consumption_1': 3867,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1508.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c589aed9-bc2c-49b1-a511-a15c4e942337',
    'identification_number': 47280816,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd105d3cc-74a5-4394-ba0d-86f041d053aa',
    'identification_number': 78564662,
    'item_consumption_1': 0.86,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 20.52,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2703ec3d-f7e1-447c-964b-cb8add18ccc1',
    'identification_number': 104616890,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 41.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a257b2a0-cf20-44a9-913c-6075317ad7ea',
    'identification_number': 16861469,
    'item_consumption_1': 0.37,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 8.84,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '192bbf2d-3d13-4a9a-b0e0-fe7e4e4c96df',
    'identification_number': 87231808,
    'item_consumption_1': 253,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 98.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43587a06-60c0-464b-aa03-f4ee15bcd6b2',
    'identification_number': 16307836,
    'item_consumption_1': 4.89,
    'item_description_1': 'DEMANDA USD E PONTA ISENTA ICMS',
    'item_total_value_1': 116.65,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e949b116-91bc-42e2-8595-d6d97cf84205',
    'identification_number': 22317996,
    'item_consumption_1': 1076,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 420.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b61201cf-09f4-4960-b929-35e94a90f5b3',
    'identification_number': 69983577,
    'item_consumption_1': 8179,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3464.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd865bfd7-d2a2-45aa-8bc4-b81682ced2ea',
    'identification_number': 108944247,
    'item_consumption_1': 86,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 33.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c679d29-9c74-47db-bb43-fc86ad22122c',
    'identification_number': 57109346,
    'item_consumption_1': 28.38,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 1329.04,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0bef2a9d-fcbd-4a8b-80a5-ac655e7776a2',
    'identification_number': 71798595,
    'item_consumption_1': 9540,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5571.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f432d561-4282-4e12-a705-90249f48f9e9',
    'identification_number': 106397281,
    'item_consumption_1': 1917,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 814.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f604136-aa6b-4ff4-86a6-bc6c5f6d24f9',
    'identification_number': 5766346,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b74dff3f-3831-4dae-a869-c2c9388b2bca',
    'identification_number': 78084237,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8038.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0de80f0f-3272-4204-aaf4-5f9c0a87f9dd',
    'identification_number': 16128060,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '17d66ed7-cdbc-4568-b8ea-282cc9bec8fd',
    'identification_number': 45501157,
    'item_consumption_1': 34155,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4359.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '970c6dc0-e01e-4987-9afb-649ed0d74f6f',
    'identification_number': 112252974,
    'item_consumption_1': 5070,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2144.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73f23400-a339-4dde-aecd-59acde0df82f',
    'identification_number': 29700485,
    'item_consumption_1': 80.14,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4576.78,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'd3f6516e-dc6c-4e11-b390-4f889b5df1a4',
    'identification_number': 110270410,
    'item_consumption_1': 224,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 28.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b38d9eee-8aee-4f45-946f-7fb1335a1a00',
    'identification_number': 40705188,
    'item_consumption_1': 2526,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1475.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98ade3a5-6c03-443a-9271-cbe17eecab2e',
    'identification_number': 73802174,
    'item_consumption_1': 1856,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 723.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76d81e1f-3e6c-42f4-9b8c-6d2a2e72e828',
    'identification_number': 8115583,
    'item_consumption_1': 150,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 63.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5da12ce-6748-496f-bf8e-83e0bd4f6042',
    'identification_number': 100428100,
    'item_consumption_1': 602,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 255.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59b20379-eeac-41ee-a154-e3531319949d',
    'identification_number': 45478260,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '491d6b8f-8b86-4392-982b-ed6db5aa6630',
    'identification_number': 19453302,
    'item_consumption_1': 1004136,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 128164.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76ff5ccb-ae11-4e00-9884-91ac25b71fe6',
    'identification_number': 20817290,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52946c82-2da7-45c4-8ddf-398d2db0df66',
    'identification_number': 16065590,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b94d9a4-2b38-400f-89cb-e94ebbeb1c75',
    'identification_number': 26987415,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 786.19,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '775d9228-2bda-493d-8ff0-a5080d8f837b',
    'identification_number': 111219248,
    'item_consumption_1': 47.13,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1370.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'df4373f4-a6f5-4338-9e65-d7de76a681c0',
    'identification_number': 108883515,
    'item_consumption_1': 2982,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1165.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82840ce9-36da-4c80-a227-8e57be2885e6',
    'identification_number': 71131051,
    'item_consumption_1': 4530,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1921.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df0f502b-ae39-4a94-b138-28e0f769ee07',
    'identification_number': 67578314,
    'item_consumption_1': 1649,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 643.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f12e9d1-ed7e-4c22-bd3b-f4dad92d60f0',
    'identification_number': 93926677,
    'item_consumption_1': 2335,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 909.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2c4afb0-5c60-4af8-8adb-beadcf9a4679',
    'identification_number': 98265172,
    'item_consumption_1': 20.07,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 583.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2154daf1-d1fe-4292-9508-9cdd6ea390ac',
    'identification_number': 31818030,
    'item_consumption_1': 9.18,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 1048.52,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2cd19d46-f9b5-4614-9775-4912bc9a6e61',
    'identification_number': 10904867,
    'item_consumption_1': 645,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 274.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac849b04-09e0-477a-a398-54d8fbeef68a',
    'identification_number': 99645599,
    'item_consumption_1': 148.23,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4311.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a822f4ba-4479-4860-b0e6-21932a28a1ce',
    'identification_number': 14952688,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2685.87,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '682d95c3-83ab-4e3c-a1e9-6ffa10e9051f',
    'identification_number': 98988271,
    'item_consumption_1': 5564,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2173.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ff676aa-0546-4d91-822c-1e66c1265fd7',
    'identification_number': 44873565,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3dee65b-782f-4b62-88f5-376a5de1b5ff',
    'identification_number': 30338190,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 95.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3d1202e0-ee39-4c84-966e-fe3adda69870',
    'identification_number': 17472652,
    'item_consumption_1': 4752,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2012.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ba1bb04-031a-4f7f-ad81-3594ee89a899',
    'identification_number': 106529889,
    'item_consumption_1': 147,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 62.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6e4455f0-e0cb-4b48-af65-4c9b42a6cf40',
    'identification_number': 30427290,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd293c1b2-6c4c-4837-93b5-3c89a8f11d5a',
    'identification_number': 90908600,
    'item_consumption_1': 2826,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1197.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '136b88aa-66d8-4c2f-a2df-76f18208de5f',
    'identification_number': 44476108,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-175.09",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '4a31fd14-f64a-47f8-8fa0-732262c2884c',
    'identification_number': 17720028,
    'item_consumption_1': 1280,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 542.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3010a5d-049c-48e1-9613-7dc52696c76f',
    'identification_number': 14799200,
    'item_consumption_1': 784,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 306.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7cbb5745-8732-4563-8e9c-7a1a3413135b',
    'identification_number': 19289022,
    'item_consumption_1': 17929,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 7012.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9faa7ce-2917-4490-bf4a-7fc5ce26e004',
    'identification_number': 93511388,
    'item_consumption_1': 29.37,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 854.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ab1443b5-a427-4ceb-9ec6-4099d476d3f7',
    'identification_number': 52773426,
    'item_consumption_1': 614,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 260.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56673f98-5c68-42d0-8289-d85b3fd81a32',
    'identification_number': 15719464,
    'item_consumption_1': 24.5,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 712.59,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '57333fb9-d06b-450b-b796-12cba2ce0063',
    'identification_number': 102846529,
    'item_consumption_1': 2918,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1137.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fdce675-0de9-4630-ae46-8fc6962ee66b',
    'identification_number': 107674149,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c4ce9da2-1db0-4d41-b1b2-6640b77ae89a',
    'identification_number': 86903055,
    'item_consumption_1': 297,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 115.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4280f658-0f33-4045-bf13-a5e11bb2f180',
    'identification_number': 70128235,
    'item_consumption_1': 15.17,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 361.81,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'cd1ec4fe-cabd-4ef0-aa61-e31683fd03f1',
    'identification_number': 105289086,
    'item_consumption_1': 763,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 323.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b138f0a3-b4fe-488c-b074-aa500770afac',
    'identification_number': 65485416,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bd764214-69ee-4114-b0aa-759c785ba9a4',
    'identification_number': 31792634,
    'item_consumption_1': 83821,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 31193.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ca11877-c560-4de8-87e8-07eb76cbbae2',
    'identification_number': 70485070,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4f5da654-2153-4789-af96-b692ed634dc5',
    'identification_number': 56877250,
    'item_consumption_1': 8.36,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 199.4,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '93c095fc-c303-4774-a044-fc2472c89bf4',
    'identification_number': 94079692,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e432bda7-f629-43f4-91fe-bf4ef246c0f0',
    'identification_number': 98715500,
    'item_consumption_1': 1604,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 681.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd1c49ed-8045-4298-85fd-5c0fa6a4f675',
    'identification_number': 33998299,
    'item_consumption_1': 549,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 214.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38ede935-6823-414e-bfc4-c52cf30192be',
    'identification_number': 75896974,
    'item_consumption_1': 16111,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5995.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '469165b3-a233-4aaf-8694-29938bd50ceb',
    'identification_number': 103087745,
    'item_consumption_1': 1612,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 629.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a14f81a-e65a-4b30-983c-a300b8908f8d',
    'identification_number': 17044790,
    'item_consumption_1': 4975,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1943.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d00ed6a-480d-4b6d-b542-541e0965afa2',
    'identification_number': 88508048,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 120.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6ecd29de-3946-4fa7-b6f2-3424b4131c01',
    'identification_number': 96596120,
    'item_consumption_1': 155,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 65.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00c7cd7d-f643-43f2-8acc-46d9475b1599',
    'identification_number': 17668956,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 139.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '60053447-769b-416f-bf35-8d0f4deaff49',
    'identification_number': 98598945,
    'item_consumption_1': 205.95,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 5990.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5dc27cf3-e7bc-4fb7-a951-c535c50f759f',
    'identification_number': 101941714,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '203e0a59-f857-48be-88e9-999333cfe7b7',
    'identification_number': 86773526,
    'item_consumption_1': "'-66.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 06/2022',
    'item_total_value_1': "'-22.91",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '15aac269-1a0d-4a2b-8c4e-cbfa9824b3a5',
    'identification_number': 46523286,
    'item_consumption_1': 25330,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9426.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d2ca9e0-72a8-416b-90d9-47d8241a7d52',
    'identification_number': 59572310,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 127.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5679cf7c-17bc-4ff2-ba9f-6f3db5511817',
    'identification_number': 103852468,
    'item_consumption_1': 181,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 70.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4cfe495-e9c8-430c-b7e1-bfe22887a1d9',
    'identification_number': 11446366,
    'item_consumption_1': 8062,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3152.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66f1f181-9cbd-457d-8db7-d3fdba7c34cf',
    'identification_number': 91667810,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b33a70c-a90a-4059-aeef-57d7dcedd874',
    'identification_number': 16915470,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '31a9ebb0-e9b4-4b31-978b-2c4e3396d4a7',
    'identification_number': 18761704,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79f4e6d4-f5bd-4daf-8fa5-2758cd987c0e',
    'identification_number': 23554681,
    'item_consumption_1': 1560,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 662.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ddc5802-91ee-4e74-b6d4-02e1fd77696d',
    'identification_number': 99971852,
    'item_consumption_1': 15.41,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 367.54,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '44cd066b-4c3b-453f-bd4c-fb1fd28d3ed3',
    'identification_number': 57742944,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8238f8b7-685c-43f6-b2c9-dca608747787',
    'identification_number': 104186020,
    'item_consumption_1': 4067,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 519.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0faa6507-ae11-4dca-8fb1-dd2fedbd1f20',
    'identification_number': 85543691,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44c35be3-df4b-402e-9ad4-523303efdb74',
    'identification_number': 74953974,
    'item_consumption_1': 306,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 119.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9908328f-aa7f-4578-8822-1fa8045a22c9',
    'identification_number': 38370492,
    'item_consumption_1': 7376,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4307.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d33810d-31a5-4549-9d4b-6204321ac617',
    'identification_number': 68906161,
    'item_consumption_1': 7782,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2896.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '727b534a-5116-4d1f-bbc6-9d67f1b33bd8',
    'identification_number': 102805687,
    'item_consumption_1': 415,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 161.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b3f75e7-e628-47f5-9027-7dd393437f20',
    'identification_number': 99441047,
    'item_consumption_1': 11626,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4528.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a069180-81f8-4ce3-af1d-01c8a94810c9',
    'identification_number': 94873879,
    'item_consumption_1': 250,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 97.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b69073da-d69c-4403-8059-84f520d08183',
    'identification_number': 105763101,
    'item_consumption_1': 1760,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2662.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ee11388-c6f1-4c10-96f4-1e9f8c646013',
    'identification_number': 70941467,
    'item_consumption_1': 7728,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3277.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1832f06c-e165-4bf9-b68c-06671781976b',
    'identification_number': 101762038,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6b6969d-232f-4446-ac1f-a6928f3097af',
    'identification_number': 14043106,
    'item_consumption_1': 147,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 111.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b07a4ab-32e1-4cc9-a946-b7f26b327725',
    'identification_number': 101119780,
    'item_consumption_1': 7074,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2756.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36dfbe4b-4da2-4b85-9501-16f770058fb4',
    'identification_number': 68447450,
    'item_consumption_1': 4.94,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 231.36,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '6c8bac49-f7c7-47b3-8b30-3e75f8572476',
    'identification_number': 9788131,
    'item_consumption_1': 3613,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 461.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8f52b83-3627-47d0-bf21-b4234abf28a7',
    'identification_number': 948748206,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b131b359-047d-4f3a-8b35-81ae2f2605f1',
    'identification_number': 33162557,
    'item_consumption_1': 898,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 524.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b77307e-2bf9-4eb4-aa06-f12eaa5fc17e',
    'identification_number': 13924605,
    'item_consumption_1': 287,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 167.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e559458e-ef9c-433b-b488-f1a9d5cb790e',
    'identification_number': 51528029,
    'item_consumption_1': 176,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 74.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3363de34-06ad-406e-8fc3-c6b19ea3540b',
    'identification_number': 10946055,
    'item_consumption_1': 3704,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1571.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0dbdb2c4-e613-4e23-abd9-b05954612cef',
    'identification_number': 98197622,
    'item_consumption_1': 174,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 67.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a3df51d-e3d1-43e1-b9ac-952d3ae100db',
    'identification_number': 34617418,
    'item_consumption_1': 73,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 28.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6271d494-fd7b-49ea-afa8-42b7a111e5fe',
    'identification_number': 100116094,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '60afe079-f25f-4b4c-9a74-1ddac1e05fbd',
    'identification_number': 68085559,
    'item_consumption_1': 66799,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 24859.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f64051b8-0c8d-4efa-8f8f-6fd5dd4f915d',
    'identification_number': 101572816,
    'item_consumption_1': 643,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 250.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86c31432-9c56-4dcb-8d01-0c0a027bd0dd',
    'identification_number': 99645599,
    'item_consumption_1': 6308,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 9542.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7af665b-32a1-4099-b8dc-71681b6213fc',
    'identification_number': 13884140,
    'item_consumption_1': 11,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 16.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b19fbbf7-4439-4bcb-88db-c79fc68b9876',
    'identification_number': 68029098,
    'item_consumption_1': 3455,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 440.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ceb936bb-97c4-4386-80ec-afddb982d00a',
    'identification_number': 39961320,
    'item_consumption_1': 11388,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1453.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'adaaa862-1e8a-42a4-9061-2a51a11aefef',
    'identification_number': 55605451,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dde01738-c240-4380-be0c-1e8562aa5035',
    'identification_number': 79730701,
    'item_consumption_1': 49291,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 18343.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '553b467f-3995-4ca8-b20e-8519cfd6eac9',
    'identification_number': 49314491,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '84bfd664-da76-447d-bed6-127de7be501b',
    'identification_number': 33670420,
    'item_consumption_1': 19.28,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 459.84,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8d572f84-6e3b-4838-9da0-261b4b136a72',
    'identification_number': 17228328,
    'item_consumption_1': 2313,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 901.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2fe73ae-18cc-4002-9aaf-22a925bdf315',
    'identification_number': 19289022,
    'item_consumption_1': "'-6.912",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-2409.38",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'e2707026-20ae-4250-a771-fc4a39169dfe',
    'identification_number': 10293752,
    'item_consumption_1': 8468,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3304.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1681290d-f2e6-40c7-8973-5b159abad503',
    'identification_number': 109822285,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b3a7d3af-da70-464b-b6d9-57c3d092343a',
    'identification_number': 10406832,
    'item_consumption_1': 3120,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1216.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13d771af-c838-4c28-a864-39ebc0114034',
    'identification_number': 105092126,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1632bd9c-1c91-4d85-b7d2-9add834ca4b1',
    'identification_number': 58526137,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f28f498-7d00-43b3-8234-1582e102376c',
    'identification_number': 106158007,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4478f008-b161-49de-8cf4-e94e0b83fa43',
    'identification_number': 36752118,
    'item_consumption_1': 26.6,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 773.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7925cd1a-169b-414b-b7ff-36133b6fc48c',
    'identification_number': 92763219,
    'item_consumption_1': 1664,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 651.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '927f8d24-df4c-4f46-927f-acd459934175',
    'identification_number': 106606824,
    'item_consumption_1': 1195,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 505.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20c72994-5d3f-498f-8a69-923b17a0cd5e',
    'identification_number': 103770968,
    'item_consumption_1': 720,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 281.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd96a239-bfec-4123-9524-8021405c5728',
    'identification_number': 11680148,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8704e13f-5a95-4eae-8f61-fd7cd598352d',
    'identification_number': 59422009,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 68.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '415ad8b2-82ea-4587-ad69-a077602c3388',
    'identification_number': 96313323,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3783f657-e54a-4540-b9b3-4e13b6c79d20',
    'identification_number': 40856089,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9c9cb35d-6a37-4755-820d-7d5d50ffd125',
    'identification_number': 15719499,
    'item_consumption_1': 446,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 173.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0802cce-453e-4a96-83a6-729b722a456d',
    'identification_number': 15194191,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04b89881-3e84-4dd1-a27a-9e6291479ffe',
    'identification_number': 96520361,
    'item_consumption_1': 17885,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6978.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd623f8e1-5e69-4214-bc36-7469e5345048',
    'identification_number': 54420636,
    'item_consumption_1': 1687,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 716.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f96f47bf-330d-45a6-a604-331846e82dcf',
    'identification_number': 16951662,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 74.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f126ded4-f1da-4a22-96b9-57157ef774a9',
    'identification_number': 101171714,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7733549e-d1be-470d-a6b1-1317ced327a7',
    'identification_number': 69826145,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a4b1808-8223-4922-8668-f74e4eb4daa2',
    'identification_number': 105703648,
    'item_consumption_1': 531,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 206.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e4e59ac-69fd-43e2-b6c3-2616a235ff79',
    'identification_number': 18274099,
    'item_consumption_1': 1372,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 534.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '316ddd01-743c-4469-b8ac-c395a21b07a3',
    'identification_number': 70082286,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-22.13",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'c35c75d9-1cf1-41d4-8df7-58533a7e6732',
    'identification_number': 48511978,
    'item_consumption_1': 76.89,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4391.17,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '51ecb9c6-f423-48b2-8742-45e3158e9a9d',
    'identification_number': 111226384,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62534df9-2a6f-4405-965e-f1c35be2ce6c',
    'identification_number': 92324924,
    'item_consumption_1': 1358,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 574.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7e9545a-9ad7-4ed5-b331-f17b2df6dfa6',
    'identification_number': 106606964,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 225.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4191769d-8a21-47e6-ae86-ecb5a4367367',
    'identification_number': 32578725,
    'item_consumption_1': 2203,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 934.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44bfc504-f615-43b6-b136-2ce78a61c8c3',
    'identification_number': 91490316,
    'item_consumption_1': 625,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 265.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a50331d4-c033-47dc-a4c4-fadbea058d13',
    'identification_number': 22162330,
    'item_consumption_1': 17939,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2289.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '207764a9-ac56-49f5-94c3-5dba2cbea212',
    'identification_number': 14334399,
    'item_consumption_1': 2919,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1704.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab18543f-d4f4-4c6c-b11a-48acecf5dffb',
    'identification_number': 10946055,
    'item_consumption_1': 3704,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1446.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2eada2b-b204-4f06-9638-dbc00dfd4bf6',
    'identification_number': 86398652,
    'item_consumption_1': 35321,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13144.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44515f2b-7a29-4b9f-92a4-e1cb6fcf0b88',
    'identification_number': 102622639,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '33c1b68a-761d-4dae-830e-ad3bbf8b830d',
    'identification_number': 104585102,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cfe2e4c0-9f6f-416e-b242-69e67eb2dbc4',
    'identification_number': 8749116,
    'item_consumption_1': 371,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 144.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1e44e7d-31f3-458e-a309-5c7badb8f091',
    'identification_number': 68385447,
    'item_consumption_1': 3.18,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 148.94,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4ee69432-9b4d-4042-9cd9-3dc76a1ce795',
    'identification_number': 45431027,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80b32022-51b3-4efd-9b22-779ee37a4cca',
    'identification_number': 111534518,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1449c829-3c31-45ed-a79d-50eb260071c1',
    'identification_number': 67532616,
    'item_consumption_1': 9054,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3531.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9852ad97-dc47-4b8e-a6ad-ab25d5d83e33',
    'identification_number': 23274310,
    'item_consumption_1': 642,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 971.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea2d1c21-ccbb-4e61-aeb2-1eedd2db101c',
    'identification_number': 45478260,
    'item_consumption_1': 1.55,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 36.97,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '947a9b54-0537-42a7-9186-97d072693631',
    'identification_number': 53371127,
    'item_consumption_1': 135.59,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3943.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '40adb044-8ccb-4a09-a6da-81eb19b1265d',
    'identification_number': 6100350,
    'item_consumption_1': 2718,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1060.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb4568cb-04f7-4be4-a72c-6ed9d8dbf39b',
    'identification_number': 101677804,
    'item_consumption_1': 1020,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 433.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71f31ac7-e8d5-4de2-b315-9e266d430a8d',
    'identification_number': 62508679,
    'item_consumption_1': 643,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 972.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05f06434-2852-4af4-8d1d-c22ef22a179a',
    'identification_number': 8585857,
    'item_consumption_1': 20157,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 7879.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '764a9a71-2300-431e-b6f9-825b1d6807eb',
    'identification_number': 8866163,
    'item_consumption_1': 4186,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6332.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a421ab9-6bf8-4a7f-8ebf-5f50cd5abe25',
    'identification_number': 26223422,
    'item_consumption_1': 674,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 286.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cef57161-5417-4414-a88a-fd0e8edc234a',
    'identification_number': 98913972,
    'item_consumption_1': 37.88,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2163.31,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1c13180b-d49c-4ad3-9290-8f6c5fec5e72',
    'identification_number': 100116094,
    'item_consumption_1': 620,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 262.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7062d1b9-a615-4140-92e6-e02a124d421b',
    'identification_number': 17804876,
    'item_consumption_1': 1562,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 663.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d9fd1a4-2a96-42f3-9dc8-423f039478be',
    'identification_number': 71113614,
    'item_consumption_1': 598,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 233.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63a815cf-37c7-4946-b2c1-d335862fc4f7',
    'identification_number': 74311875,
    'item_consumption_1': 17,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 6.64,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c3417e49-0f52-4683-9bdb-9129e87c780a',
    'identification_number': 83593039,
    'item_consumption_1': 2394,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1014.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56400f59-bb0a-4fc9-a0a7-51740d0954da',
    'identification_number': 91799376,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c87a2e64-e8f7-430d-ad7a-aade7626d333',
    'identification_number': 107718421,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0722d32-2189-485b-a1e9-86bc31b66645',
    'identification_number': 19306679,
    'item_consumption_1': 1250,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 530.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4289c67a-accf-4953-850e-575f4338379e',
    'identification_number': 36033111,
    'item_consumption_1': 22732,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 13276.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cccb4c70-2656-412c-ad17-482ff8ae02c4',
    'identification_number': 100627188,
    'item_consumption_1': "'-2.584",
    'item_description_1': 'ENERGIA INJETADA PT TUSD 11/2023',
    'item_total_value_1': "'-3205.48",
    'reference_month': '11/2023',
    'referente': 'Compensação crédito energia',
  },
  {
    'uuid_items': 'fd447ce8-32e4-41fb-8811-61568bdc344b',
    'identification_number': 74311875,
    'item_consumption_1': 4190,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2447.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22c2307c-ad8e-4e27-99e1-878a115ef87c',
    'identification_number': 35846445,
    'item_consumption_1': 361,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 140.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba8ab021-c87c-40a7-9a08-d262d5408912',
    'identification_number': 71243690,
    'item_consumption_1': 6649,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2815.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e71d133-c24b-4289-b6af-2f4eae204393',
    'identification_number': 64716929,
    'item_consumption_1': 115227,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 42881.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e713487-9884-4fd4-97d2-47a307542e32',
    'identification_number': 64551059,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c946cd8b-3cdc-4cb4-8f30-549e93b90ea4',
    'identification_number': 111926106,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '62c44a9f-8201-40bb-b809-18d1581e1552',
    'identification_number': 16026314,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ee3e9451-2f4c-498b-9cbf-44b203e5f6cc',
    'identification_number': 19302711,
    'item_consumption_1': "'-10.368",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-3614.67",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '4890c854-78c0-4ce1-a66d-57e4dedb917b',
    'identification_number': 40972666,
    'item_consumption_1': 2545,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 994.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1abf62b2-7fc9-40b4-887b-8c3149d678e5',
    'identification_number': 106471228,
    'item_consumption_1': 206,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 87.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1142db7a-28fe-45ad-885b-b44f7f505d8c',
    'identification_number': 55072755,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f7e06ef8-cbfd-4dcb-b2f5-72de2108cefc',
    'identification_number': 18036244,
    'item_consumption_1': "'-4.526",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-1574.92",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'dba37921-d851-401e-82f0-e4c1d725ab57',
    'identification_number': 45446212,
    'item_consumption_1': 453,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 171.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32ce1d43-c898-419b-8ab0-1d4fd046c0cd',
    'identification_number': 103752234,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c235efb6-ea41-4f02-a92e-ddec312593f6',
    'identification_number': 103992146,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 4.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4c2c4bd6-47b4-4ba8-b572-32a2cba2fc1c',
    'identification_number': 22533249,
    'item_consumption_1': 2688,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1142.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7e5234e-0b5e-4e6f-bc69-6a46ce819939',
    'identification_number': 13847678,
    'item_consumption_1': 356,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 538.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bc5ca27-83a3-4b5e-9468-c89c88dad559',
    'identification_number': 15685420,
    'item_consumption_1': 3787,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1409.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67805d28-78d0-4bcf-a86d-48351a44f4ff',
    'identification_number': 29745918,
    'item_consumption_1': 41.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1000.79,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4e0296da-e2ed-456e-bc3a-4123e7328ff3',
    'identification_number': 73615145,
    'item_consumption_1': 37638,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 14709.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55f1586a-3f70-4b7a-8e65-30b9742c3564',
    'identification_number': 15348075,
    'item_consumption_1': 8.73,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 507.84,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': 'd76c58ee-6180-4a1a-9fcf-05affee7f93a',
    'identification_number': 32810300,
    'item_consumption_1': 6.34,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 724.14,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b7939576-3a42-4866-a233-fe980f73a5b4',
    'identification_number': 96912278,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b9d0ecc0-bcb4-4410-8c91-af2e099668e2',
    'identification_number': 103496718,
    'item_consumption_1': 2601,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1017.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba32a8f7-7a94-432b-8a23-9ef0c999b128',
    'identification_number': 111834228,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c89c7b17-c43d-452d-9a75-8660d2d8c298',
    'identification_number': 105275247,
    'item_consumption_1': 13404,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5683.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1892d29a-5e47-4ea5-9472-3300cf4afcf7',
    'identification_number': 74311875,
    'item_consumption_1': 44126,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16421.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cab4f692-7180-43c5-9912-7ff86d8296e0',
    'identification_number': 98722425,
    'item_consumption_1': 1631,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 692.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '588d639e-67ef-40d6-ab8b-65af171b207c',
    'identification_number': 29131251,
    'item_consumption_1': 2865,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1216.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '233e75e3-2b00-4128-957a-4cba9f023602',
    'identification_number': 107433427,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4b93331b-5f30-4824-bcae-5e99367998f9',
    'identification_number': 108218511,
    'item_consumption_1': 1066,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 415.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8199bb7-4aa2-4269-9886-d11becfb0f02',
    'identification_number': 29517478,
    'item_consumption_1': 226,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 96.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bc58fb0-04c3-448b-8b13-6ce80c99108f',
    'identification_number': 12424188,
    'item_consumption_1': 7901,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4614.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b96e061f-4015-4646-a486-e96a59885697',
    'identification_number': 105717606,
    'item_consumption_1': 2347,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 996.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad086ad4-996f-4793-becc-39d9efafdd5e',
    'identification_number': 42824699,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '81ab007f-e7b1-4366-83f9-49f8cde5d178',
    'identification_number': 100741770,
    'item_consumption_1': 2902,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1132.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d36b403-f31b-4549-b642-1a0404ba9e9f',
    'identification_number': 101040245,
    'item_consumption_1': 5805,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2268.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '171eb20d-42c3-454f-b7fa-8c699dcd0264',
    'identification_number': 105277002,
    'item_consumption_1': 3940,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2301.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '922acaf3-a1d6-48df-9289-19dd202ce6f2',
    'identification_number': 35436778,
    'item_consumption_1': 136,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 53.21,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c183c524-5f01-49ef-ab06-057ec51ab0fe',
    'identification_number': 36691143,
    'item_consumption_1': 198,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 83.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41437ed5-333b-4afc-b582-c06d2dfa8457',
    'identification_number': 14562545,
    'item_consumption_1': 9133,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1165.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c4e6954-85f4-4c44-8f92-cf7573eb261f',
    'identification_number': 14859513,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 16.82,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e30815b2-a03d-413e-b306-2a878c1260eb',
    'identification_number': 84968559,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8e9e2eb3-b49b-46e7-a3be-590986cc0a2a',
    'identification_number': 100317022,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '04648354-c5a3-4df9-b2d5-2302c140934a',
    'identification_number': 107740516,
    'item_consumption_1': 15809,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5883.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffbb7294-7a00-412b-a133-bcec48ae286f',
    'identification_number': 110457757,
    'item_consumption_1': 549,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 232.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30e85035-838e-4509-868c-767c5afa3475',
    'identification_number': 29957206,
    'item_consumption_1': 3773,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1602.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40e4d305-0080-496c-9e3f-5546eb384ede',
    'identification_number': 38635518,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e54998ec-3c8e-4401-bc69-06af6661c6aa',
    'identification_number': 101837593,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cd74c074-c240-4a9c-a404-a84bb5f9f70c',
    'identification_number': 82743339,
    'item_consumption_1': 211,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 82.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '294877fe-1972-46af-8cce-6690877a1beb',
    'identification_number': 60720514,
    'item_consumption_1': 16.52,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 394.01,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '86118ba9-19e7-429e-9b6f-2d4ce116464b',
    'identification_number': 46021191,
    'item_consumption_1': 149.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4347.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4965bb55-cbbe-4c7e-9ba7-0a07ba528555',
    'identification_number': 110898850,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0944a600-771d-4bda-a734-a43251836640',
    'identification_number': 70509204,
    'item_consumption_1': 9.96,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 289.68,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5ca95c35-a1da-4f04-b79b-491c22c50a05',
    'identification_number': 108167879,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'efa8797d-f45a-486c-8346-a159eb485d16',
    'identification_number': 20830807,
    'item_consumption_1': 10994,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 6420.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59c66914-0019-4bfb-b71d-89422d4f1b6b',
    'identification_number': 18519555,
    'item_consumption_1': 3445,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1346.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc199d50-597b-4f1b-ae00-090c125c0aff',
    'identification_number': 27125700,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 072/166',
    'item_total_value_1': 17.63,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '117a29ec-89bc-4b9c-bc66-8d263d6c4666',
    'identification_number': 97814105,
    'item_consumption_1': 1034,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 403.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d4c6372-5516-4020-9191-d6b59c5aaaf5',
    'identification_number': 41803558,
    'item_consumption_1': 162,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 68.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a0d58a0-074a-49d1-a24a-ee514e4e5cb7',
    'identification_number': 17034620,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '72844c0f-c058-43cd-9194-d69d4cab3064',
    'identification_number': 102005141,
    'item_consumption_1': 2047,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 868.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06c6c792-b246-41f1-ac34-e7f119b03386',
    'identification_number': 102684650,
    'item_consumption_1': 12.49,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 297.9,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '854bd286-a022-4bf6-b0c7-d7221e7fa825',
    'identification_number': 106681664,
    'item_consumption_1': 547,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 231.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea53976f-ee70-420a-b45f-3032b8e221a7',
    'identification_number': 14824558,
    'item_consumption_1': 2737,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1068.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8ccc6ae-8373-46c8-b935-c3b1e0dd6e71',
    'identification_number': 40504123,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 67.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '02b3f912-070d-4f96-a2d3-e8747df9f565',
    'identification_number': 106729985,
    'item_consumption_1': 116,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 14.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd51f98f-fccb-4e9b-ad62-c30e0c0a1d11',
    'identification_number': 99211823,
    'item_consumption_1': 3068,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4641.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96e350c2-bf32-46e6-83fb-93ba434289da',
    'identification_number': 14671638,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0d15dfd6-882f-4972-be05-28fef44d707f',
    'identification_number': 63991373,
    'item_consumption_1': 4390,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 560.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78a29b24-8619-4c4e-8876-00d067091913',
    'identification_number': 27904660,
    'item_consumption_1': 2685,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 342.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a64f45f4-d096-498d-bbbc-2834fd275345',
    'identification_number': 9841717,
    'item_consumption_1': 7139,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3030.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8de7b2da-057f-48b0-9239-f491ffaf62ae',
    'identification_number': 96427361,
    'item_consumption_1': 161,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 68.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19393117-38d8-4238-b9ab-e387b860ca5b',
    'identification_number': 56527144,
    'item_consumption_1': 7.94,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 189.4,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '8d78acfb-568e-400c-ab2c-97df7f79e39c',
    'identification_number': 105106151,
    'item_consumption_1': 1141,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 445.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4c55e14-127c-419f-b45d-354d8e492628',
    'identification_number': 21851336,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fc07be9-9c9f-406d-8b40-66fb32c71bd6',
    'identification_number': 97005304,
    'item_consumption_1': 9971,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3710.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3be51e8-9c40-4780-b8e9-eb2e5e309d59',
    'identification_number': 104782420,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3f4652f-63b8-4534-aab2-e210653ead26',
    'identification_number': 39247260,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2dce13d8-875e-4f0f-bca5-20f4a7024da3',
    'identification_number': 63964430,
    'item_consumption_1': 21.32,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 620.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6c1b3d57-7da4-4e60-a35a-5ea27f7b1152',
    'identification_number': 58696172,
    'item_consumption_1': 4.47,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 106.63,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '35a7c7fd-41f2-4e33-b0d7-90b29af9ab52',
    'identification_number': 60065524,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e6c47de-24ed-45ac-874f-6b677176fc1b',
    'identification_number': 100185568,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89b9670e-1cb6-48e5-94f9-6861dd506d5d',
    'identification_number': 29475716,
    'item_consumption_1': 1248,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 487.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b8c05cf-060b-4ab3-ae04-7f919a99a420',
    'identification_number': 102854165,
    'item_consumption_1': 648,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 275.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5affc469-4213-4db4-bbda-3e47c1fabe3b',
    'identification_number': 58739149,
    'item_consumption_1': 534,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 807.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3ea036c-1f36-46e9-b3d3-9d7b98c729b4',
    'identification_number': 26987415,
    'item_consumption_1': 13,
    'item_description_1': 'CONSUMO REAT.FORA PONTA TE',
    'item_total_value_1': 4.77,
    'reference_month': '11/2023',
    'referente': 'Energia Reativa Excedente Fora Ponta',
  },
  {
    'uuid_items': '4d0bdbfe-96ec-43d6-87fc-6676fe86512f',
    'identification_number': 43752187,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd35c0210-2f31-4d20-9590-b9ef04675692',
    'identification_number': 29200091,
    'item_consumption_1': 12288,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4805.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '811313c2-c2dc-4293-87e1-872bc45fca6d',
    'identification_number': 106199960,
    'item_consumption_1': 155,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 65.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35537d34-80fd-4d0a-b39e-72a2be52214e',
    'identification_number': 105663247,
    'item_consumption_1': 212,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 78.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98c5e82d-59d2-4bcb-9bad-a151bf37b385',
    'identification_number': 32348533,
    'item_consumption_1': 4.9,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 116.88,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '64062c38-d8cc-470a-a2b9-e84efbe0e358',
    'identification_number': 105804339,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aca9977b-a83b-426e-bb08-6e6c11c4a166',
    'identification_number': 41422775,
    'item_consumption_1': 14172,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1808.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdebf565-97ca-4a84-8559-8252b870a586',
    'identification_number': 42556198,
    'item_consumption_1': 3628,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2118.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4a29b10-094e-43ef-8847-f39548453d03',
    'identification_number': 10521119,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-2385.6",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'a5777061-8b5d-4c12-940d-09753a381d95',
    'identification_number': 17108462,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc064a47-7cd9-4fc1-8260-e49a36dcdb72',
    'identification_number': 68376391,
    'item_consumption_1': 2867,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1674.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b9312b9-fe36-4a4a-ad52-ee7df37ce687',
    'identification_number': 83623256,
    'item_consumption_1': 87.96,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2558.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '803bd1b1-bb30-479a-a04f-01b4761f6309',
    'identification_number': 11680148,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2938c10-9fdc-4600-b140-a9c611ec11fe',
    'identification_number': 109449045,
    'item_consumption_1': 432,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 180.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa88b097-7c99-4cb2-8129-22b282afe6c8',
    'identification_number': 32528620,
    'item_consumption_1': 42.26,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1229.15,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'aac1c81c-89bb-4d3c-8700-985777622098',
    'identification_number': 15572862,
    'item_consumption_1': 24066,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8956.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1de2883a-444d-4e5c-b8a2-8e2e5d11afce',
    'identification_number': 5721865,
    'item_consumption_1': 1039,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 404.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12c301d0-8aa4-4abe-a282-1fdf3b623394',
    'identification_number': 84777117,
    'item_consumption_1': 23.89,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 1389.71,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'fedfc4ed-4699-4154-b077-25c1b16d7d5e',
    'identification_number': 40207650,
    'item_consumption_1': 9279,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 14037.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd48eec31-7066-4b62-b580-f5e79e33828e',
    'identification_number': 21185123,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6363c768-4ee8-4124-8628-cb14d09836bc',
    'identification_number': 63964112,
    'item_consumption_1': 0.64,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 15.28,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1fd7cf38-580d-4da8-a23a-f1ced92fdfd4',
    'identification_number': 102135541,
    'item_consumption_1': 2241,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1308.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7cf105fd-e81c-4940-956d-faa61ec385ac',
    'identification_number': 44502931,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '024111ca-44f4-4e7f-be18-f0c2cd25330e',
    'identification_number': 27132110,
    'item_consumption_1': 1954,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 761.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e2b62cf-f626-4edf-832b-45fe6d917ac7',
    'identification_number': 103772561,
    'item_consumption_1': 233.52,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6792.13,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'aa71d8cd-9f64-4323-b8e8-4a0441738ed1',
    'identification_number': 111027101,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bc07f0b-e205-4fcc-8f2a-1099e3217e70',
    'identification_number': 45220352,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d556cf0-1877-4c26-be45-9bbb1d21d664',
    'identification_number': 80073301,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6e813ef4-5d0b-400b-8b6a-b24a65c04faf',
    'identification_number': 67030165,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0256de44-8ab3-4381-a4b5-17ae23d22674',
    'identification_number': 22373101,
    'item_consumption_1': 14,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 5.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '259f5b7a-5fc4-4acf-a4a7-20af53088ea2',
    'identification_number': 97752320,
    'item_consumption_1': 582,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 226.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67b0b60d-5655-4860-8550-84d56f39d94b',
    'identification_number': 14489317,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '473c2039-deee-43a1-be01-7abcdb2e3380',
    'identification_number': 94650977,
    'item_consumption_1': 11704,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4355.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '744ac073-67eb-4cdb-81fb-d83d2a81d001',
    'identification_number': 40994724,
    'item_consumption_1': 8992,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 13603.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce5ccda2-9201-4d8d-b030-b82dbfe1b6b5',
    'identification_number': 37367790,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b1e548b-366b-4b71-89bf-52dc5d857008',
    'identification_number': 106278860,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '383662fd-afa2-4236-baf5-5a16bac06672',
    'identification_number': 5330351,
    'item_consumption_1': 71174,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 26487.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8877f3f-4ebe-4c1c-995e-e0247d57391e',
    'identification_number': 61481467,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 701.93,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd1799857-1c44-46f1-9de4-33578ee12d0c',
    'identification_number': 28259513,
    'item_consumption_1': 24.03,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1372.34,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'afd4b485-2634-4e97-a142-1088686b903a',
    'identification_number': 12956465,
    'item_consumption_1': 91158,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 11635.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ac60efde-3cfb-4447-be42-a8867ac3cfd3',
    'identification_number': 77241924,
    'item_consumption_1': 361,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 153.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48fe5860-cceb-44e8-bb44-4060de2bb0d3',
    'identification_number': 17130972,
    'item_consumption_1': 2446,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 954.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '718206ee-6914-4296-9227-3d5acd7dbb03',
    'identification_number': 29700485,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '875c1305-829a-4873-9b89-86571597fc52',
    'identification_number': 5330351,
    'item_consumption_1': 71174,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 9084.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1352e862-af75-47b5-a65f-6da24f7fa94d',
    'identification_number': 15719464,
    'item_consumption_1': 6.5,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 155.05,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd199838c-a847-41ed-a5b8-17a4fe4648f6',
    'identification_number': 27112101,
    'item_consumption_1': 20324,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2594.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8ba16d7-4f92-41c2-88b2-288100306bd8',
    'identification_number': 38109280,
    'item_consumption_1': 40480,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15064.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f920194-78f3-4f08-a85a-840023a9e14a',
    'identification_number': 45488606,
    'item_consumption_1': 209,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 81.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ba8a32b-de65-4ebc-a070-3a8a6b9fcf86',
    'identification_number': 13327623,
    'item_consumption_1': 332,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 140.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '644e7482-683d-42a6-aa21-81721b051b9f',
    'identification_number': 18138373,
    'item_consumption_1': 1.55,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 36.99,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '030a6840-ba0c-444b-8b25-225798cdff79',
    'identification_number': 105003727,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '819f090e-ea9d-43b2-8212-219c152bd4a7',
    'identification_number': 99645599,
    'item_consumption_1': 6308,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3684.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b382696-a96a-43a2-838c-1a1d6c53edd3',
    'identification_number': 23135395,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 92.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '44e4b19e-e4bf-43f2-99b1-8b4efeb42107',
    'identification_number': 98994859,
    'item_consumption_1': 4057,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1720.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d0274fb-71b2-4d7d-8983-446acd23e108',
    'identification_number': 75063077,
    'item_consumption_1': 3,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c353a956-456a-4161-afe9-8e2534ecc858',
    'identification_number': 77405340,
    'item_consumption_1': 187.48,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 10706.96,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '9a8c4901-b09d-45f8-a34b-b780fdb47256',
    'identification_number': 94743100,
    'item_consumption_1': 348,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 136.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa638146-57fa-4690-bb0f-4f74c8d062c9',
    'identification_number': 94460566,
    'item_consumption_1': 50.38,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1465.34,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '81e5bafe-10b9-4b52-a530-c1bb0c4b8eab',
    'identification_number': 106228633,
    'item_consumption_1': 467,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 198.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b4a1440-2637-4a0d-a560-65665febfe63',
    'identification_number': 31639267,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '912dcef9-ab68-40cd-89c0-dd7e993c5562',
    'identification_number': 93430442,
    'item_consumption_1': 18184,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6767.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5ddb6dd-114a-4b81-acac-63165e70498d',
    'identification_number': 59602619,
    'item_consumption_1': 1890,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 736.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7380010-55ce-4b8e-9100-c504e835209c',
    'identification_number': 34739157,
    'item_consumption_1': 1694,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 659.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fb3dd4f-421c-403b-80b0-9d608c033ba4',
    'identification_number': 17865069,
    'item_consumption_1': 740,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 314.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10612319-c023-4554-80e9-573310a22f47',
    'identification_number': 18138373,
    'item_consumption_1': 17591,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6546.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99fb4c7a-0f53-43ba-a048-f551524faa19',
    'identification_number': 12368105,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 7.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '94942c86-5c81-4ac4-a897-e661e1dcae7f',
    'identification_number': 111320550,
    'item_consumption_1': 58,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 24.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eaf47772-3657-449e-93a0-7a7269191503',
    'identification_number': 48843075,
    'item_consumption_1': 21426,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7973.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d2f891e-2268-4aa6-accb-9990a6fbeadb',
    'identification_number': 109308204,
    'item_consumption_1': 1872,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 730.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a483ff6e-a322-4786-ab5c-8dfd9c47bb48',
    'identification_number': 41460510,
    'item_consumption_1': 206,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 311.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4079682-14e0-4aea-91e7-d3a3ba8c4369',
    'identification_number': 36222852,
    'item_consumption_1': 75,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 29.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f90c31ef-c98c-4b91-b4da-8050b32f996e',
    'identification_number': 22963677,
    'item_consumption_1': 517646,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 192640.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d88fbf1-9e7f-4035-92cc-7a51b33ab997',
    'identification_number': 29984971,
    'item_consumption_1': 15511,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1979.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b75b974-a6ac-4fb7-84c5-2e59633ba09c',
    'identification_number': 101729456,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '91b3b468-7a26-4a34-bd1b-c125c83da88e',
    'identification_number': 75063077,
    'item_consumption_1': 4362,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 556.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f91d5aee-f184-402b-94cd-7aa080d5c031',
    'identification_number': 16069030,
    'item_consumption_1': 635,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 482.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d6bbcc6-7b99-4617-95c6-20418164cfc1',
    'identification_number': 15842231,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '671509ca-17ec-4938-a7ce-717968e991ae',
    'identification_number': 79663036,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f432cdeb-21bd-4d37-a9a8-262ab9b1532d',
    'identification_number': 500020,
    'item_consumption_1': 6036,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 3273.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc1672af-a617-4156-955c-0f26f296c2e0',
    'identification_number': 111295408,
    'item_consumption_1': 546,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 231.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad5032b5-3c59-4f23-814d-277199d6a942',
    'identification_number': 106819135,
    'item_consumption_1': 2603,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1106.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79d28fec-3745-409b-b781-0371077ae2f5',
    'identification_number': 102913102,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85a63937-1c3c-4fbe-9ab5-98e3d291994b',
    'identification_number': 111978050,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e5178e6-045f-4487-8e5a-a6a310ce2442',
    'identification_number': 51210533,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '349a39e7-f0f3-4261-9a20-c2e1415ae7b3',
    'identification_number': 101143010,
    'item_consumption_1': 151,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 64.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcffbbeb-5899-490b-b185-98d84a53cdf3',
    'identification_number': 6123341,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b40caa17-c1f4-48f6-9ef0-47107f36dde5',
    'identification_number': 16105664,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e92d6eb5-96e1-4a1e-a5bd-14a55948894f',
    'identification_number': 73221473,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61515bbd-7970-4759-b133-04390e727e93',
    'identification_number': 104126337,
    'item_consumption_1': 3257,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1269.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a931aa0f-f03c-4fab-9732-bd1617fe5369',
    'identification_number': 34216251,
    'item_consumption_1': 7461,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2915.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12a40a19-4aa9-4063-a811-3c18da087c70',
    'identification_number': 96032073,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '541e0085-86a4-4b56-a0d7-a72978f7efc7',
    'identification_number': 107160498,
    'item_consumption_1': 210,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '879d4ae7-a357-4b2d-bb0b-842a11a4ea6f',
    'identification_number': 41499255,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '365fa505-8bbc-4a90-a41f-bafc8a9d7e8b',
    'identification_number': 66739489,
    'item_consumption_1': 287,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 111.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05d239fe-7d70-4cd7-bc27-2bfeccdfffde',
    'identification_number': 22789023,
    'item_consumption_1': 590,
    'item_description_1': 'CONSUMO TE',
    'item_total_value_1': 217.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22519b15-8a41-428a-8366-a37ee35cc56d',
    'identification_number': 19305818,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '815170a5-e52c-4564-93b2-ea26cdc066c7',
    'identification_number': 17159679,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '68ab9af3-bdc5-46a5-8dfb-b01c854d1cb6',
    'identification_number': 19310188,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-12.17",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'f2050b69-b284-4716-9f1b-d858906764c4',
    'identification_number': 48511978,
    'item_consumption_1': 77.04,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2240.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '131d4bca-e6a3-4db3-9c2a-d665213a337f',
    'identification_number': 43338739,
    'item_consumption_1': 1616,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 632.28,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1332ecf8-06e5-4f62-b25f-54a4e692ca45',
    'identification_number': 17044790,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '53e28e33-a6e1-4a4f-bbdd-0b83ae22b72e',
    'identification_number': 8084661,
    'item_consumption_1': 382,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 149.45,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '92ebf403-0a6b-4e8f-9607-7490a011e706',
    'identification_number': 77405340,
    'item_consumption_1': 39.92,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 952.13,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd18f72e6-2210-41d7-be13-777ff6a843be',
    'identification_number': 38370492,
    'item_consumption_1': 29,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 11.35,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '96d8c5c4-fc73-4d23-9d63-91ee3b16c004',
    'identification_number': 59059729,
    'item_consumption_1': 3462,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5237.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a4756a3-9fa7-4ad8-9828-79691108f7d4',
    'identification_number': 66902983,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53d8960b-6146-40c9-8e77-3408f1159010',
    'identification_number': 30102901,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '01e0def7-c3c7-461f-92d0-b8c3deb8c432',
    'identification_number': 24083542,
    'item_consumption_1': 5042,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2944.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1e052f4-caa8-4630-8d71-723506e9375e',
    'identification_number': 105849944,
    'item_consumption_1': 6779,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2522.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08b2be46-8289-4777-ac92-3a520d11bdd1',
    'identification_number': 45838313,
    'item_consumption_1': 32.61,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 948.49,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '507279cc-bb3b-41e5-9ea5-1a3d51a1f1e6',
    'identification_number': 20444060,
    'item_consumption_1': 2120,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1238.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d1f1eb0-67c0-4b27-93ae-4c044c0b9204',
    'identification_number': 102653194,
    'item_consumption_1': 479,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 203.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3eaf7e61-97a6-42a8-a06d-96b323bcc96e',
    'identification_number': 16187130,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7f053e1e-313c-41bf-a797-14a393d01b34',
    'identification_number': 32486804,
    'item_consumption_1': 1260,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 735.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8f51643-fd55-4a8d-a093-73b1cce879fb',
    'identification_number': 20514310,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6136f7e9-06c3-4bd7-9ec7-d2236edd20ef',
    'identification_number': 70972443,
    'item_consumption_1': 12300,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5226.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8343b513-7310-4c5e-83bb-c78687b5ed5e',
    'identification_number': 49074423,
    'item_consumption_1': 1575,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 919.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '288416c0-85dc-4807-9bf2-9889981bc749',
    'identification_number': 41971817,
    'item_consumption_1': 4.09,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 97.56,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '12da8eaa-da25-4db7-b519-b08d510003c4',
    'identification_number': 14138069,
    'item_consumption_1': 2.95,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 70.37,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5d827ab2-e9cf-4880-83aa-e179c17c4eb4',
    'identification_number': 14635232,
    'item_consumption_1': 1721,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 730.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3a88d6b-fd27-4a7e-8eb4-7e25af43f68d',
    'identification_number': 13977873,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2afd64d8-fb5d-4f51-bf46-3fad954a5687',
    'identification_number': 40801250,
    'item_consumption_1': 280,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 118.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7295145-9b69-4cbe-8f0c-9c461debbe78',
    'identification_number': 70556997,
    'item_consumption_1': 24284,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3099.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5088757-72fa-4178-bc16-13db7ae0c269',
    'identification_number': 56876815,
    'item_consumption_1': 1308,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1978.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45619d1a-a46a-4470-b257-cd116c0762c7',
    'identification_number': 15071987,
    'item_consumption_1': 56,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 23.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ec8b602-b4ad-4e61-a18f-2c8a5d70ce6f',
    'identification_number': 91800536,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '176bb9f8-354c-4f07-af90-140f113d8b79',
    'identification_number': 68571186,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '581b49ee-b354-43e8-8801-931fd8810906',
    'identification_number': 102684650,
    'item_consumption_1': 2867,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 365.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b300114-ef12-4af2-b726-56e9ac325134',
    'identification_number': 13179349,
    'item_consumption_1': 9575,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4061.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ee941cc-59f6-4c07-8563-577f79e03136',
    'identification_number': 33403210,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '16f96678-2791-4609-a89d-288a705d82e5',
    'identification_number': 91394031,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-5498.18",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'd226f3c2-7e1d-4f75-a670-311339874228',
    'identification_number': 95205390,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7b9284c8-0d4b-4149-adfc-4a5e5670c19d',
    'identification_number': 31846882,
    'item_consumption_1': 271.38,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 15498.5,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'df2fdd29-9615-4b68-a0b7-57207b1b7314',
    'identification_number': 10466177,
    'item_consumption_1': 227,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 88.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '802ceaf3-65ac-4c8c-9c32-7f0db2239cc9',
    'identification_number': 99258692,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3254fc6c-adef-4114-a8af-019f2b7a6971',
    'identification_number': 64716929,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '755b61fd-18a6-4c11-b5c2-8ea038043131',
    'identification_number': 22424725,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '336b0a83-b972-4f5b-96fb-d770bc730201',
    'identification_number': 10299343,
    'item_consumption_1': 2086,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 812.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '503d2c28-6755-4a4b-a270-aa40109ac231',
    'identification_number': 94650977,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'efbbf9f3-5dd7-42d1-8a90-65929dfdbc03',
    'identification_number': 9995846,
    'item_consumption_1': 330,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 140.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '328af3ae-7ac8-4efb-b238-73aa88bc81a0',
    'identification_number': 67532616,
    'item_consumption_1': 9054,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3838.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5a8ec55-ad5d-452a-9e15-3e0ce57f6bdf',
    'identification_number': 6338526,
    'item_consumption_1': 49,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1425.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '75972b43-ef94-4ae6-afbd-b59adb0ab705',
    'identification_number': 57029911,
    'item_consumption_1': 4335,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6558.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6151125-9fd3-4c13-9607-c1c469eec25b',
    'identification_number': 68135580,
    'item_consumption_1': 5873,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 749.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f831bf03-eb30-49f8-b24f-734cff34e9cc',
    'identification_number': 59532777,
    'item_consumption_1': 960,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 374.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44af98ab-0d62-4a8d-882c-3a315083e5ed',
    'identification_number': 10647422,
    'item_consumption_1': "'-10.368",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-3610.66",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '0fe24b4b-2ef6-4c18-8847-db5287b14cfa',
    'identification_number': 79211747,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.65,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '55c387ac-af17-46e2-8698-69db31ab861a',
    'identification_number': 51632381,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a724e72f-7f57-4dcd-a3af-5044655bf585',
    'identification_number': 101899955,
    'item_consumption_1': 1205,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 512.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7504e874-43dd-49ef-a474-9df0fa1ea99a',
    'identification_number': 102896224,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67fc9e61-2958-4e50-acf3-da9c9162329d',
    'identification_number': 91394384,
    'item_consumption_1': 8846,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3751.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cdc79d7-1f12-4b27-9798-30e7ee4e964d',
    'identification_number': 103809783,
    'item_consumption_1': 6518,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2764.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e2f848a-4c05-492f-8e31-2d2665c254fa',
    'identification_number': 111270928,
    'item_consumption_1': 36,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 15.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb0c48ea-8e50-4fd3-a9ec-2c574156db04',
    'identification_number': 108788954,
    'item_consumption_1': 12834,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1638.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e36eba9c-f89d-41b5-80c8-dc794229f1f7',
    'identification_number': 105904872,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c63bb8a3-f0ab-4f6d-bc54-2869917ceb52',
    'identification_number': 45603391,
    'item_consumption_1': 1125,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1701.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f86ca487-611b-493d-88d3-e86e53b930fe',
    'identification_number': 44826044,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c9d8682c-bd84-4ecc-8234-7ee2f7d1d90f',
    'identification_number': 9876600,
    'item_consumption_1': 3840,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1498.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f9c5c09-6811-4ae8-be1d-b980829fe15a',
    'identification_number': 42959128,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '82590ede-1f94-4be4-8c6b-bca97bc2bf2e',
    'identification_number': 5984319,
    'item_consumption_1': 2660,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1128.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fff10ee-9579-4c47-9635-c47792c1bb13',
    'identification_number': 111270510,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97f33b32-1886-4515-9adb-0c68d534e66b',
    'identification_number': 35315628,
    'item_consumption_1': 9194,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3583.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0223ee08-9500-41d6-af04-1f5f32e2d6fa',
    'identification_number': 34851151,
    'item_consumption_1': 23754,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3031.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e7ae7d2-7aa4-4094-a4d8-f2d7ea4852e2',
    'identification_number': 35902051,
    'item_consumption_1': 366,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 142.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01e9d870-fcd0-4472-8afd-4aec9a9cab00',
    'identification_number': 71547169,
    'item_consumption_1': 3075,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1306.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05dfc7fb-58a4-4c3d-a120-e77572d74f0b',
    'identification_number': 12187569,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c7a619e4-2ce4-4ce1-a2ba-7c9a9b5e7d9e',
    'identification_number': 109858891,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36c1794d-45d1-4b7b-a158-cdca44ceb582',
    'identification_number': 16246837,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 0.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81f5636a-386e-4123-8fc0-8beba2f098ef',
    'identification_number': 46913378,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71bde708-84b0-4c99-bb0e-cebf8e60a49a',
    'identification_number': 42404576,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ac244878-be2c-4f92-9dcf-92dcfba89072',
    'identification_number': 36033111,
    'item_consumption_1': 252502,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 32228.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20bdd46f-5b6c-4723-b097-033f5b5f2a48',
    'identification_number': 15981444,
    'item_consumption_1': 6,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 92.71,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4b7ce372-3edb-48f7-8837-14d6365209ba',
    'identification_number': 96017015,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '419ff9b4-f88e-428e-a13b-72307c0a70c4',
    'identification_number': 104480050,
    'item_consumption_1': 60.32,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1438.67,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9f490f2f-dded-4271-8402-0990493e8f14',
    'identification_number': 69810001,
    'item_consumption_1': 5924,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2308.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b98e316-0eb0-408a-8ff1-a565b9a3a369',
    'identification_number': 66739489,
    'item_consumption_1': 287,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd05275f0-d09e-4b6b-b085-8972ee4236ef',
    'identification_number': 102940746,
    'item_consumption_1': 1186,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 692.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b4be371-2b88-4470-afc6-316bc9ba5db0',
    'identification_number': 29700485,
    'item_consumption_1': 3.82,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 91.11,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '04e00558-0eef-4e44-9bfe-7e8eb8eda06b',
    'identification_number': 95867562,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea380326-7835-4789-8f43-526204de03bf',
    'identification_number': 96254378,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e7d15eb-1d5b-4c45-a380-f6625affa31d',
    'identification_number': 91650291,
    'item_consumption_1': 550,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 214.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '684a7274-755d-4b7d-824a-3a935d2dfe0f',
    'identification_number': 77638050,
    'item_consumption_1': 938,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 365.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b55bf50-8cbf-44a0-86b7-ea7bd814b0f8',
    'identification_number': 46021191,
    'item_consumption_1': 5610,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 8486.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73e6f69f-5afa-4257-89d1-d0f93f46bf5c',
    'identification_number': 62986724,
    'item_consumption_1': 11227,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4763.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d602070-4831-4397-ae20-8a931e74ddec',
    'identification_number': 31686729,
    'item_consumption_1': 36407,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13548.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7f781a3-130e-48a4-bdc2-76fd83286dff',
    'identification_number': 47989050,
    'item_consumption_1': 33421,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4265.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c117241-e1ff-4b34-8b21-5da1cc132f1d',
    'identification_number': 19541627,
    'item_consumption_1': 5551,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 8397.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b86636f3-3b15-4165-aaf2-362b906b076c',
    'identification_number': 84156171,
    'item_consumption_1': 252,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 381.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1dceaa52-f60f-47f5-b207-32637eab2abc',
    'identification_number': 67511929,
    'item_consumption_1': 211,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 82.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00cd536c-8d59-4f36-94fc-33d559b7335d',
    'identification_number': 91224560,
    'item_consumption_1': 4822,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2046.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9c358be-fa5f-4122-af69-c19f0b96f8b1',
    'identification_number': 46699236,
    'item_consumption_1': 9.32,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 222.3,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2d23ee25-d829-4998-8b03-58475167c495',
    'identification_number': 56582854,
    'item_consumption_1': 1.49,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 35.56,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1339f7aa-c85e-4995-8cff-286439f7239c',
    'identification_number': 17682061,
    'item_consumption_1': 7995,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3391.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18d9a265-84c6-4837-87a9-b94828802035',
    'identification_number': 103366512,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd21232e4-9652-409d-91c0-bf4fba905ece',
    'identification_number': 94896330,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 71.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f7f5d97d-fdc8-4b2a-ac92-c6ea990c1e33',
    'identification_number': 93007264,
    'item_consumption_1': 462,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 180.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2f6f646-78cc-4ba0-aa83-d21eae3c618f',
    'identification_number': 103752234,
    'item_consumption_1': 23125,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8605.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb7270d3-407a-4bf2-aed7-5965127436d7',
    'identification_number': 33569932,
    'item_consumption_1': 5494,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2149.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '015a167b-4d14-4d3e-80db-b2abebff354f',
    'identification_number': 16115660,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 106.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '83523c79-9026-4bf6-8ab5-22daf51d8973',
    'identification_number': 77204816,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-37.74",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '8fdaa8b7-b15c-4b8c-b5d4-4b14abf30592',
    'identification_number': 103281924,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9c0af1ad-cfd7-49a1-8b74-a0979d3721cd',
    'identification_number': 98624792,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e0272f80-7a72-44e9-88fd-5143a5550050',
    'identification_number': 106087266,
    'item_consumption_1': 23740,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3030.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c836d341-8438-4e21-a8c8-7b2ea2b64516',
    'identification_number': 48004936,
    'item_consumption_1': 21,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 8.21,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '180abb7f-d2df-43f3-8900-a55a29125221',
    'identification_number': 71391274,
    'item_consumption_1': 6744,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2638.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c394f4cb-d735-47cd-8acf-0ad8b9d431f7',
    'identification_number': 60065230,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71285986-3f04-43a4-860d-cfd8c09a14eb',
    'identification_number': 110228308,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '535592d6-e8b5-40c4-a628-2e5bd6bedcae',
    'identification_number': 18273424,
    'item_consumption_1': 13.5,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 392.65,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '096b0e2c-ef18-4d88-978b-ceca9731f025',
    'identification_number': 92673872,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4219772-c0a6-4433-b314-2b4defa78537',
    'identification_number': 91075700,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 128.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '19a7c1ca-e504-4950-b438-fa41c8ff5bfd',
    'identification_number': 109785983,
    'item_consumption_1': 4438,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 566.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88695a2d-0040-44d7-b92f-ece5996f157b',
    'identification_number': 94315540,
    'item_consumption_1': 3263,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1381.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebbcffda-7ea5-4bb5-870a-15e0ce2d13d0',
    'identification_number': 18916996,
    'item_consumption_1': 3429,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1455.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d58c06d-e798-429a-a0af-9b0acbbdd685',
    'identification_number': 100765076,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 878.54,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'c677bc7b-7f57-4897-bfea-cd5981c1150e',
    'identification_number': 32881681,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b560d793-1bb9-4cde-988a-4141a55325cf',
    'identification_number': 22808213,
    'item_consumption_1': 148,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 62.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e41cf08-4243-4534-bf99-fb32c5484f54',
    'identification_number': 105798908,
    'item_consumption_1': 1105,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1671.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4c5d07c-2c7c-4dde-9b6f-fe008efb268a',
    'identification_number': 73549223,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f6625fa4-0b57-4128-9b08-b0dae4294661',
    'identification_number': 948827679,
    'item_consumption_1': 0,
    'item_description_1': 'Acerto Faturamento REN1000 LANCAMENTOS F SERVICOS',
    'item_total_value_1': 20.92,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '0e054f4b-0720-48b1-9bf3-8c857535e68f',
    'identification_number': 19450753,
    'item_consumption_1': 371,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 145.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c05091fd-e3e6-45f6-b9db-9d97bd31ff89',
    'identification_number': 96644990,
    'item_consumption_1': 261,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 110.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17a0e7e0-f294-4d15-a6bc-89b311d38185',
    'identification_number': 106077406,
    'item_consumption_1': 37,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '120c9c5a-5fe7-4304-8e79-6c1b5dd80332',
    'identification_number': 93754680,
    'item_consumption_1': 9594,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3741.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfc0f48d-3ed6-4869-b3cf-777d0e83f470',
    'identification_number': 13572008,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Hummação Pública',
    'item_total_value_1': 11.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b647f5d7-462f-4019-95dd-86a25adbc4ed',
    'identification_number': 16127072,
    'item_consumption_1': 139,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 105.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '330746b6-a224-4615-8a9f-fdceb1636bdf',
    'identification_number': 15093093,
    'item_consumption_1': 4672,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1982.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efbc323d-37bf-431f-9514-2f0f3c2db552',
    'identification_number': 35326263,
    'item_consumption_1': 4040,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1578.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5dcad03f-d2b7-4ba3-9404-6f8aa0047616',
    'identification_number': 70075778,
    'item_consumption_1': 4858,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2060.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '32be5706-b027-410a-b078-0dc219ed79d3',
    'identification_number': 19288042,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '626693f6-5fee-4adc-97c3-d4e20207b4ad',
    'identification_number': 68135580,
    'item_consumption_1': 15.94,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 463.62,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c14e0c99-a0ba-4bea-ae61-e26c496323f2',
    'identification_number': 37709631,
    'item_consumption_1': 2234,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 946.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '877080bd-8316-42fa-bd51-6fd18e676a89',
    'identification_number': 15871789,
    'item_consumption_1': 818,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 477.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10dc8b48-6568-46a9-aaf0-6236697cfbfa',
    'identification_number': 51182459,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4aac3c5-3be7-4ba1-89a2-7b176d77e58f',
    'identification_number': 70485070,
    'item_consumption_1': 20214,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7522.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e40e7b8-f142-41ab-a32a-426e68442568',
    'identification_number': 15002578,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6d71a688-021c-415f-b348-0880327e1621',
    'identification_number': 70174458,
    'item_consumption_1': 158,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 67.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a215b99-9596-4652-b4a1-44e2912142d0',
    'identification_number': 14662302,
    'item_consumption_1': 95,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 1790.1,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f1c2a085-2d4c-4f25-b48e-7e173616a155',
    'identification_number': 91654734,
    'item_consumption_1': 4667,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1824.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e976302-7215-45d5-9db8-5f3688207996',
    'identification_number': 15369080,
    'item_consumption_1': 4.68,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 111.63,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '73aaa31e-6bbf-40c5-b336-ac32186ca6ca',
    'identification_number': 61331180,
    'item_consumption_1': 231,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 90.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c3178e6-baa5-4b76-854f-e28cb275552d',
    'identification_number': 20934947,
    'item_consumption_1': 26.05,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 757.68,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b587faa0-0452-47a0-a4f5-c4ae53c71364',
    'identification_number': 110926005,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b53ef175-38c7-43d4-9eec-484fffbbecf0',
    'identification_number': 949409303,
    'item_consumption_1': 6276,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 5471.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1da8aef-e374-4f79-ae08-616ed028932e',
    'identification_number': 63416794,
    'item_consumption_1': 1708,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 997.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83bbf890-3823-4a49-9f1e-a44af2037c30',
    'identification_number': 60092904,
    'item_consumption_1': 10735,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1370.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe32f6f6-6be7-4f8f-853d-9645e76840d0',
    'identification_number': 92140394,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1ee9cbba-c462-4e02-8ebc-2ae2a0b7965c',
    'identification_number': 102896224,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-2878.57",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '561d657f-e599-4398-8e6d-0fac4d047a6d',
    'identification_number': 101708440,
    'item_consumption_1': 1046,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 444.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74b865c6-017b-4186-baad-407dc607947c',
    'identification_number': 72755520,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b4741abd-3d83-4e2f-bcb4-28cde1e3d298',
    'identification_number': 40569144,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9128ee3a-3dba-4dfe-896b-84948447b136',
    'identification_number': 105597554,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '70ae2aca-d3dd-45c7-8ae0-baedcd316926',
    'identification_number': 33375453,
    'item_consumption_1': 20361,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7577.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52756395-e5fe-45c1-b0fd-37d8d59b7052',
    'identification_number': 69972931,
    'item_consumption_1': 5617,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2384.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '926365e7-dc7c-42f1-8e63-60a2c5afd803',
    'identification_number': 8843015,
    'item_consumption_1': 6349,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2481.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f70164d-ed47-4b9e-a8c4-114680137ee3',
    'identification_number': 63964112,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 112.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0b5429db-9c92-4227-901d-b19590fb893c',
    'identification_number': 101439075,
    'item_consumption_1': 3155,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1340.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0b50baf-47ac-483f-95c2-e1cdc0e5ec18',
    'identification_number': 5685885,
    'item_consumption_1': 2607,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1105.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0dc6214e-337c-4e7b-b2eb-db86e83508d7',
    'identification_number': 98913972,
    'item_consumption_1': 1682,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 982.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82222c37-784f-472e-a788-572b0a3d020a',
    'identification_number': 97485128,
    'item_consumption_1': 7434,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2899.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af1c380b-c1c3-4cb7-a7fd-c660a6047b62',
    'identification_number': 17182069,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 54.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '98eb3da4-4ec6-4608-ae09-f81b2f04de02',
    'identification_number': 109643372,
    'item_consumption_1': 1684,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2547.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '759a7665-4770-4a81-a6cd-244e773fc09b',
    'identification_number': 99786184,
    'item_consumption_1': 708.48,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 20606.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7f674657-47a0-4ee1-8100-368da57d9d19',
    'identification_number': 73534609,
    'item_consumption_1': 1227,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 520.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd2b8eb1-332b-4cb5-a221-f23710c063aa',
    'identification_number': 109909437,
    'item_consumption_1': 1587,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 672.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36c41efc-25da-4f51-9174-f92df6328bae',
    'identification_number': 12750948,
    'item_consumption_1': 830,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 323.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e97efbe-d494-4c86-92b1-24835a569a63',
    'identification_number': 40504123,
    'item_consumption_1': 3568,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1395.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a09315ae-540f-4bfb-9112-56bee26ca1a6',
    'identification_number': 98236563,
    'item_consumption_1': 178,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 69.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35d09e50-1fa3-48ba-8b89-13f098c49307',
    'identification_number': 102940746,
    'item_consumption_1': 8723,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1113.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2ae5cbd-6a5d-425c-a788-31cf7bd91881',
    'identification_number': 45602387,
    'item_consumption_1': 25,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 9.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9578cc30-e33c-4132-87e0-e17e2ecfb91a',
    'identification_number': 105644366,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '611387f5-1d23-45e0-9eab-c82d8c359975',
    'identification_number': 53330668,
    'item_consumption_1': 1343,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 784.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '279b6de5-d464-4dff-bac9-bbaff86f6e0a',
    'identification_number': 93308965,
    'item_consumption_1': "'-81.0",
    'item_description_1': 'ENERGIA INJ. PT OUC MPT TE 01/2021',
    'item_total_value_1': "'-38.79",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '191e88ea-b91e-4aed-b8c6-499dc9383c81',
    'identification_number': 92763219,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '63af665c-d158-40a6-b017-08d454bb2da8',
    'identification_number': 98560905,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b10c3c1-0ce7-4af5-8972-c7bd22a4ded9',
    'identification_number': 98560905,
    'item_consumption_1': 340,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 132.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b38cff6b-9381-47d5-8d7c-fef37bc70eeb',
    'identification_number': 106607090,
    'item_consumption_1': 1195,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 465.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4cf0be55-9ad6-4448-b470-bc3eece5f516',
    'identification_number': 105778273,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed9cb1a5-7eba-4fce-9876-d4ed837aceb7',
    'identification_number': 70485070,
    'item_consumption_1': 2387,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1394.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b84a1424-20ad-473d-9fee-221348902d2f',
    'identification_number': 108206572,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b148f7d6-85eb-4e5c-875a-8a3845a6035b',
    'identification_number': 39466787,
    'item_consumption_1': 67.6,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1966.19,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0b0042c8-b607-41c4-a784-932de8365bc7',
    'identification_number': 103377271,
    'item_consumption_1': 1696,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 721.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55ee4065-075c-42c7-a861-aaafef2d68ac',
    'identification_number': 93577842,
    'item_consumption_1': 369,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 144.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f516ea57-2502-4eb8-837c-812b5861de8a',
    'identification_number': 15091520,
    'item_consumption_1': 27.25,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 792.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0b871321-2610-47f7-852c-26628c5f1b92',
    'identification_number': 67917283,
    'item_consumption_1': 5697,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 727.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0109f48a-02e3-4c97-af4d-3d2c0a85fc8e',
    'identification_number': 12673650,
    'item_consumption_1': 70.6,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4031.96,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '52228f1b-1ce9-401b-a76b-6d7a5a10265f',
    'identification_number': 69492026,
    'item_consumption_1': 415.58,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 23733.76,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '77cc3e66-6abb-4dcf-be19-7abfd650a529',
    'identification_number': 101123256,
    'item_consumption_1': 4419,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1727.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b851a0c-6217-4654-8630-d1817652d487',
    'identification_number': 37328867,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '33359e17-be6d-4165-954a-4bba7e99e08e',
    'identification_number': 31497861,
    'item_consumption_1': 84904,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 10836.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '565aa4cc-8c3c-4316-a1a6-700ad227624b',
    'identification_number': 948748172,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 20.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '29bb94b6-e5f1-44c0-a627-3ac90f64016d',
    'identification_number': 105957410,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ba217c8-ff95-418f-9480-40359453fdfb',
    'identification_number': 34645497,
    'item_consumption_1': 15256,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6457.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'acc6f26f-69d3-4aa2-acf0-1e64977b9fcd',
    'identification_number': 43739580,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50009ee6-28bb-46a8-b16d-b677d262a7df',
    'identification_number': 68079257,
    'item_consumption_1': 3.86,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 224.54,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ff3471ff-5980-472b-ba9a-4add41156eeb',
    'identification_number': 98370200,
    'item_consumption_1': 65,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 25.42,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a728ab30-e45b-44d9-adef-e9148530c975',
    'identification_number': 80642322,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2f466f82-1b61-4abd-96e9-9aeb1f847eed',
    'identification_number': 40122654,
    'item_consumption_1': 13771,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5124.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17ca7faa-4a9d-4663-87d1-6aa5ca187227',
    'identification_number': 102194424,
    'item_consumption_1': 951,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 371.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fbba213-71f9-414f-ba58-22073b6ace75',
    'identification_number': 14187205,
    'item_consumption_1': 2111,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 896.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc7dcfd7-ea90-4cf8-9952-0ef316c53996',
    'identification_number': 106443780,
    'item_consumption_1': 124,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 2957.46,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c2a855a9-e475-4bee-a3f9-27c6f2f4ad43',
    'identification_number': 102481172,
    'item_consumption_1': 37698,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14029.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9958da91-cb30-48f1-baed-6540149548c0',
    'identification_number': 14693100,
    'item_consumption_1': 11.21,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 640.19,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5ec80e68-3bd4-40b4-923e-fe77c21fbe54',
    'identification_number': 98600400,
    'item_consumption_1': 1438,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 561.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0bf952d6-3aa7-4f8b-aa98-3834f4202175',
    'identification_number': 104614285,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0190204f-78dc-410d-99f1-20ff401b2448',
    'identification_number': 103125841,
    'item_consumption_1': 2752,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1074.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '459e0886-61b6-4018-a46d-aa660ef3627f',
    'identification_number': 27316114,
    'item_consumption_1': 65927,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 8414.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed16e1a9-4f27-4214-ae1b-14586f34381c',
    'identification_number': 78564662,
    'item_consumption_1': 37.14,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1080.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd299ec0b-d3fc-4bac-a591-92dd37719e0e',
    'identification_number': 43908764,
    'item_consumption_1': 5806,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 741.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bec2f64b-1b47-4be5-8d41-6dec87e6b70c',
    'identification_number': 32303912,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3a781b83-9e3a-45e0-b268-8ee6e58f486d',
    'identification_number': 110067266,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '03e28f90-9775-4ce0-9094-ef87a980d550',
    'identification_number': 56708890,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8747fa16-c17a-455c-b1e5-4d3013e5556a',
    'identification_number': 45603391,
    'item_consumption_1': 1125,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 657.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e841e63-c4ab-46df-a414-dd81bded2f12',
    'identification_number': 94010641,
    'item_consumption_1': 1496,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 634.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4a42a52-673a-49a1-b37e-65ff414cd156',
    'identification_number': 12797707,
    'item_consumption_1': 8.09,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 378.87,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '9314810d-ad24-47cd-a6b6-de59308bcf9f',
    'identification_number': 81267843,
    'item_consumption_1': 6945,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2584.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f50a2840-a3c7-4ce9-91de-c675451638ef',
    'identification_number': 103765450,
    'item_consumption_1': 307,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 119.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23e88e56-12eb-4969-bbe4-3a969c65953c',
    'identification_number': 104871563,
    'item_consumption_1': 50.57,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1470.86,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2376caae-439f-4846-b1de-9fb94586ea74',
    'identification_number': 64167364,
    'item_consumption_1': 28,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 10.94,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e8d590ec-149a-434c-80b6-9f5d3c201cc5',
    'identification_number': 33355215,
    'item_consumption_1': 29.79,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 866.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '54705a80-dfeb-43c9-af7d-ace444e7f556',
    'identification_number': 73887692,
    'item_consumption_1': 22.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 641.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3e3a61d0-b779-4d17-8197-54f96ca70118',
    'identification_number': 97807141,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f34647f-3dc2-4d4f-ae00-7e28d5db319b',
    'identification_number': 109782909,
    'item_consumption_1': 106,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 39.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5b87106-2f51-4150-9e70-49c70a6ca036',
    'identification_number': 42433347,
    'item_consumption_1': 11085,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4712.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8e53294-17f2-4492-9dcd-877f75cc92de',
    'identification_number': 22162330,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '72e999b6-e470-40a2-81d6-67712e641203',
    'identification_number': 39402576,
    'item_consumption_1': 901,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 381.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4256ed3-203b-469c-b743-db1809ba3fd6',
    'identification_number': 66198666,
    'item_consumption_1': 14.75,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 351.8,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7bdf5fe1-c265-4c9d-b9ae-b4c643276a30',
    'identification_number': 39906035,
    'item_consumption_1': 2380,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1007.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '69ee93b2-17cb-4335-b921-e585b9cb6245',
    'identification_number': 71113614,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8decf4c9-1dd8-4f61-941e-ce161555bcb0',
    'identification_number': 63896419,
    'item_consumption_1': 629,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 367.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7582ed2f-46c9-4806-84f7-066219add2b6',
    'identification_number': 22634207,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a6436c53-3fea-49e7-81e3-df710ebb325b',
    'identification_number': 13789430,
    'item_consumption_1': 96,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 56.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4dbe629-b416-4909-a45a-13687b3fbea8',
    'identification_number': 109682424,
    'item_consumption_1': 192,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 75.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a394e56a-e824-4fbd-8c32-e9bfe44deefc',
    'identification_number': 69758409,
    'item_consumption_1': 3910,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 499.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a43c34ef-bd64-40f9-936f-b7d3812c028e',
    'identification_number': 104137436,
    'item_consumption_1': 222,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 94.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc74170a-ddc2-4560-b44e-8206567d833a',
    'identification_number': 41460510,
    'item_consumption_1': 5760,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 735.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc6961a5-4ab1-4229-ba79-9a22bb6e9b91',
    'identification_number': 99060434,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fccde227-d650-4d36-b3ef-c14aecbf026c',
    'identification_number': 17808677,
    'item_consumption_1': 1601,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 678.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4522c04-576c-45e3-a4b3-a34ba7539c06',
    'identification_number': 45478260,
    'item_consumption_1': 35165,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13086.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16d2540d-3dd4-405d-9dea-77c1f188a1ab',
    'identification_number': 105619990,
    'item_consumption_1': 260,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 110.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86558aee-1e4b-4cb4-8a52-ad432f3f9f08',
    'identification_number': 17023491,
    'item_consumption_1': 78.22,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2275.09,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2260bdf9-4721-4888-8328-cd9c8349013b',
    'identification_number': 18339603,
    'item_consumption_1': 1595,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 203.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '463f1f41-dfa6-4278-a9a2-cded8352d91b',
    'identification_number': 51210533,
    'item_consumption_1': 18686,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2385.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f4022a68-d315-4bdc-b8f1-5f9a66139156',
    'identification_number': 14859513,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '409c2732-a351-40ed-be2c-88a01414618e',
    'identification_number': 104126825,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '128ad19d-fea8-474c-8f23-712c2845a567',
    'identification_number': 36337803,
    'item_consumption_1': 2019,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 786.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1808b08-8310-437d-a8e1-d883488b152a',
    'identification_number': 59440287,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'febc74ef-823a-483e-9365-a7664d92a988',
    'identification_number': 105684228,
    'item_consumption_1': 57,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 22.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24c10e68-e23a-4656-bf73-f3ccb023ac91',
    'identification_number': 111027101,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9064905-7f47-401f-8253-a9bde5d41316',
    'identification_number': 8874280,
    'item_consumption_1': 804,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 313.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '234fb0a6-32d4-47a5-9e1e-a79a877a54dd',
    'identification_number': 97791091,
    'item_consumption_1': 181,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 70.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '133165fa-cc62-4fa2-ae6f-cd5b61820ae5',
    'identification_number': 9921273,
    'item_consumption_1': 3643,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 464.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '130b47d5-ac32-48f0-86bb-4fecf142149e',
    'identification_number': 12019670,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 75.2,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '88e46dff-020a-4303-8021-3ed3b87a1b9e',
    'identification_number': 33133077,
    'item_consumption_1': 232,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 98.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15855e22-abe2-4bc2-bfb3-7a0238daa46f',
    'identification_number': 99713349,
    'item_consumption_1': 436072,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 162283.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74529187-b98a-4b6b-9bc3-2cf5554a70f0',
    'identification_number': 64554481,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1034.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d8d3832-e90f-4da5-ad27-189c420d63e1',
    'identification_number': 106868250,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '81130b1d-4123-420b-aabf-828ed74b3c11',
    'identification_number': 46912401,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59896544-9937-4ed3-8beb-8dcde21501de',
    'identification_number': 103066411,
    'item_consumption_1': 968,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 410.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7892fe8-90f4-4116-862b-dee894a94f6c',
    'identification_number': 92562302,
    'item_consumption_1': 631,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 246.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e04f5c01-a1ed-43c7-ad38-2ad81fb0dbe4',
    'identification_number': 110528603,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f40346d-f407-4326-aa57-3a4f5f7f5d1b',
    'identification_number': 14799200,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 126.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8cab030b-99be-49fe-aea4-1e45869b46c0',
    'identification_number': 21718970,
    'item_consumption_1': 1352,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 789.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b135157b-ada6-43a8-bdf8-b8f180095766',
    'identification_number': 45501157,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '310cb851-6dbd-44f1-84ce-86f7b1fef38e',
    'identification_number': 100624650,
    'item_consumption_1': 2108,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 822.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b6db786-ea7d-47c9-9782-0e0f7b6f0468',
    'identification_number': 30102901,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2665.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc848e95-4250-4939-8f5e-b939efaa86a4',
    'identification_number': 20587163,
    'item_consumption_1': 511,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 199.93,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '93da69d8-38f6-4f34-adbb-35fda09806b3',
    'identification_number': 34617426,
    'item_consumption_1': 177217,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 22619.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7f70195-16b6-4791-b4ca-e7da8c03b10e',
    'identification_number': 40517780,
    'item_consumption_1': 1376,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 582.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ff8c6d2-3d46-4e8c-abd2-80409a022d05',
    'identification_number': 84702893,
    'item_consumption_1': 20.22,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 946.92,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b936fc57-a844-44c8-8f7d-79c6184983d5',
    'identification_number': 21576696,
    'item_consumption_1': 1620,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 633.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f24f2b6-2adc-4f69-a2cf-f48311eb6a88',
    'identification_number': 105611441,
    'item_consumption_1': 2734,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1069,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fccaae9-1688-4659-b593-93e3e3514c36',
    'identification_number': 84768754,
    'item_consumption_1': 7203,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 919.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c67485e-3fd3-44be-8a5e-f7e1b47d9abf',
    'identification_number': 12673650,
    'item_consumption_1': 0.21,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 5.02,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'ab344db2-cd8f-4b04-9ed1-241671f3f5cb',
    'identification_number': 11432616,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 144.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '95842ad4-ace4-45b5-b12f-3ea692b160ad',
    'identification_number': 94339546,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '82adeb1e-98e7-401b-929b-f09c965e41e4',
    'identification_number': 98644548,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51357d66-d144-48d4-8233-f2ce20dfb22e',
    'identification_number': 78962188,
    'item_consumption_1': 14156,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1806.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8a39ef7-0c5a-47f9-9a6d-cf695ba88c22',
    'identification_number': 17229162,
    'item_consumption_1': 4002,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1695.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b291cbc-6516-45aa-b0ec-4958b730fc6b',
    'identification_number': 35429585,
    'item_consumption_1': 821,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 348.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53ed0327-6831-430b-bfe0-eb4f90a3945f',
    'identification_number': 8715874,
    'item_consumption_1': 3002,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1170.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59315070-985c-4561-b141-b71c424b80d9',
    'identification_number': 13935739,
    'item_consumption_1': 2420,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3661.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc25f7dc-5095-4bf5-be72-eaf54e1465c0',
    'identification_number': 93680180,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 119.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '446fac9d-2700-4a2e-918e-0f792e8384fd',
    'identification_number': 102687560,
    'item_consumption_1': 47705,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 17753.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b4ba778-ecc1-438e-8a94-82f6340f8111',
    'identification_number': 14227096,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4e0f1f60-b5ad-43f7-abd6-33a375a26081',
    'identification_number': 59044659,
    'item_consumption_1': 7847,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1001.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9327f4b-19a1-430f-b562-17badc2a030a',
    'identification_number': 111833868,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '17fbbddb-b1c7-4313-a546-b49a51214ef4',
    'identification_number': 109493664,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 60.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '95705077-d58c-46e0-b4fe-d98304704a76',
    'identification_number': 9790527,
    'item_consumption_1': 1397,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 592,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d412fa4-014a-4172-becc-35368945eaf5',
    'identification_number': 98631012,
    'item_consumption_1': 3477,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1476.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf011800-f524-4a70-a760-8c8bea922fbb',
    'identification_number': 94317976,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b9f79383-ced6-4561-9f22-411c0cbff595',
    'identification_number': 108863026,
    'item_consumption_1': 11,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e762043-5d42-46d5-b4a7-6611d143bc9e',
    'identification_number': 16105850,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df578bf6-277c-4618-ae69-4b76307d0f93',
    'identification_number': 75753758,
    'item_consumption_1': 23837,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3042.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7ef0f1b-25d6-4102-bc08-7abdc00ffc59',
    'identification_number': 32495056,
    'item_consumption_1': 6838,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3993.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8fb934d-4fbe-4c85-b3d0-fa9e1aa2e5db',
    'identification_number': 97161667,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f4696ac-862c-44f3-8d02-125b9df08297',
    'identification_number': 73581046,
    'item_consumption_1': 1264,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 493.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f69adf46-d609-4217-8d3e-8f5cbdec3030',
    'identification_number': 43626955,
    'item_consumption_1': 12780,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4756.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1eae56c2-cebc-47eb-a04d-131631723e80',
    'identification_number': 80393306,
    'item_consumption_1': 5150,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2189.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ae776f2-4931-4e6b-91e7-bd22895bdaaf',
    'identification_number': 41370198,
    'item_consumption_1': 6689,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2832.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35216a5a-ab37-4221-b797-107674e25691',
    'identification_number': 110922786,
    'item_consumption_1': 811,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 317.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8800c75-6df4-4620-a895-ff242a50c0aa',
    'identification_number': 12673650,
    'item_consumption_1': 4359,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 556.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7784ba0-d0e8-4dd6-be8d-af7d19d37c75',
    'identification_number': 40879356,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 48.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8c0b28e3-419b-4bae-bd77-2c673115fc9f',
    'identification_number': 99991276,
    'item_consumption_1': 662,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 257.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9196b6f3-3015-4053-9130-b6438cd786bf',
    'identification_number': 19451512,
    'item_consumption_1': 49756,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 29059.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '548d8366-1e2a-4f1b-9501-be66d08f0df7',
    'identification_number': 14693100,
    'item_consumption_1': 11.29,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 328.36,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '77ab72e5-3d80-46b9-a588-91b4832411c2',
    'identification_number': 107173115,
    'item_consumption_1': 48.26,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1403.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '32d37e75-d583-4629-a737-8b9ad536b458',
    'identification_number': 19452306,
    'item_consumption_1': 14542,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 21999.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e70dc34-e16c-4303-bf81-d4e20e26931d',
    'identification_number': 15871789,
    'item_consumption_1': 14.09,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 336.06,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '1d38e7c5-a96a-4373-8e00-709894b7e925',
    'identification_number': 96692979,
    'item_consumption_1': 5087,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7695.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b8fcff8-5f13-4fd7-b8c7-a3dc795b42c6',
    'identification_number': 29985404,
    'item_consumption_1': 93406,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 34760.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e986bc90-0b74-4a55-ad6c-8bf92251687f',
    'identification_number': 18226280,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50de6a71-38e0-4a7e-8a94-b7d025fac2f5',
    'identification_number': 78084113,
    'item_consumption_1': 2513,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1467.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66e607ee-315c-49a0-ba43-a6a890f566e7',
    'identification_number': 106761161,
    'item_consumption_1': 2031,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 863.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44b744d3-e9bd-4ebe-9875-038a34de982c',
    'identification_number': 40855856,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f4e480d5-4117-4012-9bb4-b8cad8e62d14',
    'identification_number': 68085559,
    'item_consumption_1': 6721,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 10167.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77114a22-de2c-4dc7-aaff-c6ae4f8278fa',
    'identification_number': 99102935,
    'item_consumption_1': 93,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 36.37,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2b725225-ad81-42c1-886f-11f0223ac0af',
    'identification_number': 105957569,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '03ff0ab5-20bc-4a84-ac71-561ce47c11e8',
    'identification_number': 18036244,
    'item_consumption_1': "'-11.358",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-3636.56",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '14b9e4ce-fb0c-480e-aec8-665ca2ce8bcb',
    'identification_number': 96386673,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.19,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8ece56c6-d2f0-4bb7-b3da-1f700558354f',
    'identification_number': 97856681,
    'item_consumption_1': 44.56,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1062.8,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '39f778c7-dc17-443a-a4cc-06bf0aacaa45',
    'identification_number': 100822959,
    'item_consumption_1': 239,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 93.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef18acc9-f6df-4810-a65b-d4ab020ee2d6',
    'identification_number': 104193824,
    'item_consumption_1': 767,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 325.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f936f94c-2943-487c-8cca-218427c34254',
    'identification_number': 110284801,
    'item_consumption_1': 2775,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1081.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3253e68-4621-4d96-aaa8-2d964ec21964',
    'identification_number': 102433283,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1aa52638-df23-4b7a-93f5-05a643e6edd9',
    'identification_number': 75192900,
    'item_consumption_1': 13631,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1739.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0aff2083-249b-47bc-a04c-ec32e4e6cce2',
    'identification_number': 33204071,
    'item_consumption_1': 3855,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1637.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7bb7bf8-2b79-4830-986d-f79dfcebee22',
    'identification_number': 9248420,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 138.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bd8921ae-2aff-4c25-b147-abfcf6b50f45',
    'identification_number': 45838313,
    'item_consumption_1': 32.57,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1860.06,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '88f42c71-2a02-48b6-8218-3ed7b47da814',
    'identification_number': 108601676,
    'item_consumption_1': 874,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 111.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edb93e8b-c0f8-4cd6-95de-85730bd20532',
    'identification_number': 92638775,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1608.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43dfe691-8edb-4c9c-ae05-d7ea4423ded6',
    'identification_number': 104408405,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f526e74-a10d-475f-814e-7255cdb5a321',
    'identification_number': 93879695,
    'item_consumption_1': 1749,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 743.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15326076-3504-49db-b8d0-bb5d268530d4',
    'identification_number': 92171273,
    'item_consumption_1': 2787,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1182.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ede0d4e9-aba4-45ab-9b30-6df0fe51d26a',
    'identification_number': 103001000,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '064a6d9f-4fd1-4484-83b1-614103db3ef7',
    'identification_number': 935371657,
    'item_consumption_1': 2409,
    'item_description_1': 'Consumo em kWh LANCAMENTOS E SERVICOS',
    'item_total_value_1': 2100.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '902bfcfc-bedf-40f1-b523-e82ea9636ae5',
    'identification_number': 97812846,
    'item_consumption_1': 724,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 306.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c16dc4fb-9ac9-4629-b60f-843c0fc1ff0b',
    'identification_number': 95598421,
    'item_consumption_1': 2712,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1148.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea0f9266-bed1-4297-8137-4839c0ec6d2c',
    'identification_number': 107956403,
    'item_consumption_1': 20092,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7477.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6dafd4b6-f4f3-466e-a111-67ed172de90e',
    'identification_number': 47766336,
    'item_consumption_1': 1541,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 652.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '525efcb2-3cfc-4ccb-b569-73fbc7ef25b2',
    'identification_number': 20304137,
    'item_consumption_1': 62.48,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1817.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e734f651-f34c-4e70-92b0-444addbc77d5',
    'identification_number': 32794088,
    'item_consumption_1': 289,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 112.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '710681d0-1757-499f-9a86-9852b598a61d',
    'identification_number': 59742631,
    'item_consumption_1': 903,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 383.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebca7237-47a6-4a2c-857c-aa4870178693',
    'identification_number': 22963677,
    'item_consumption_1': 20711,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 31332,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa980ee7-1f81-4b0f-a535-0020319b40db',
    'identification_number': 34403566,
    'item_consumption_1': 815,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 344.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22cf797a-9dbb-48f7-9f3e-30cbf495fc40',
    'identification_number': 16000862,
    'item_consumption_1': 4361,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 1759.21,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': 'e0cb1a4e-8c5b-4b39-aacd-fa3b1f168ba6',
    'identification_number': 79723055,
    'item_consumption_1': 373,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 145.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b659b78-78fb-43da-9465-bb9ed2677341',
    'identification_number': 29700485,
    'item_consumption_1': 19176,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7136.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1408040-f558-44f1-b9be-e386907ed692',
    'identification_number': 48999784,
    'item_consumption_1': 2497,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 974.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c989dac-37d4-4541-a5a2-0baaf336cb17',
    'identification_number': 40611914,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ebb75435-4be2-4316-a09a-601f2966934b',
    'identification_number': 40705188,
    'item_consumption_1': 86.1,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2504.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5baded99-dfba-4381-8985-be4150e691d6',
    'identification_number': 93205589,
    'item_consumption_1': 19.48,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 566.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2c0cf3b5-056a-4b9d-bce5-db68e6abe6ee',
    'identification_number': 77818792,
    'item_consumption_1': 341,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 144.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5241e1b-0eb4-40e2-8988-fd69fcd0a51b',
    'identification_number': 15561054,
    'item_consumption_1': 6152,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 785.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a9bd7db-eb8e-453b-8fc4-66a6f8536bf3',
    'identification_number': 23562200,
    'item_consumption_1': "'-555.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-192.88",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '038dbb1a-60ab-4088-b5d9-555367c0d790',
    'identification_number': 36794392,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '47aac330-cc57-4c14-b9a2-0e71cb033152',
    'identification_number': 56526580,
    'item_consumption_1': 17.51,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 509.28,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '14e75738-7e8d-4537-97bf-428c09dbcd5f',
    'identification_number': 100059961,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f06bd581-539b-4c02-81f5-f11ef456f28c',
    'identification_number': 44001681,
    'item_consumption_1': 40849,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15201.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1433378-1fcc-4b5f-9338-366e4a38dc89',
    'identification_number': 13789422,
    'item_consumption_1': 4962,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 633.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c0d3117-539c-4eed-8417-a23031e21c29',
    'identification_number': 109676173,
    'item_consumption_1': 85,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 33.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3153092-e33b-4224-baae-ce9681bdf71f',
    'identification_number': 96125225,
    'item_consumption_1': 2917,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1703.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d50dbe7-d94e-4b8e-b7d0-17dfb6bd6740',
    'identification_number': 17109833,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bb67b1ba-20a9-4d58-b7a5-fa5899be41f4',
    'identification_number': 31645313,
    'item_consumption_1': 2997,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1750.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0cc21828-557b-4aa9-b3b5-c7b990b2c686',
    'identification_number': 6941044,
    'item_consumption_1': 1155,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 451.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77107f8b-c625-4a2d-b10c-5506f036df43',
    'identification_number': 80201555,
    'item_consumption_1': 105.38,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3065.06,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd6e0534c-9799-493b-9a77-7b3d64628ad3',
    'identification_number': 16124197,
    'item_consumption_1': 7277,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3084.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a493c3c7-b7e2-47cb-b305-b846084a7372',
    'identification_number': 29836573,
    'item_consumption_1': 2245,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 954.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b692fd2f-e579-4844-9ee3-901e6fa319e1',
    'identification_number': 101512783,
    'item_consumption_1': 275,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 107.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e9aff46-41f0-472c-a5fc-c6acbbde1221',
    'identification_number': 85872067,
    'item_consumption_1': 2510,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1064.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3aadd12-bc6f-46ce-8abe-e5eba4ad0152',
    'identification_number': 110920988,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0647e3c9-97e6-4cc2-b118-68372d92bd8c',
    'identification_number': 33252785,
    'item_consumption_1': 3204,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1360.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d48d347-ef75-44fd-9cdf-ed2974f93360',
    'identification_number': 48681261,
    'item_consumption_1': 1060,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 135.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c0fa0ed-2a4f-4b73-8923-c81aaee7fdad',
    'identification_number': 47037318,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '989f17bf-0e1e-432a-b77f-6e176aac0e6e',
    'identification_number': 17808677,
    'item_consumption_1': 1601,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 623.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6eb70396-f6f2-4d9d-91ba-ff07bd0b7d98',
    'identification_number': 17720028,
    'item_consumption_1': 1280,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 499.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbe806bd-2040-4d57-81e4-c31c36584a17',
    'identification_number': 39466787,
    'item_consumption_1': 66,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 25.82,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd2a003c4-a981-4c66-a12d-0d21ae52f210',
    'identification_number': 39678318,
    'item_consumption_1': 13227,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4922.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78a8c9ab-3c3b-400a-9379-f4f20562128f',
    'identification_number': 947580121,
    'item_consumption_1': 0,
    'item_description_1': 'LANCAMENTOS E SERVICOS',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'f786fbd5-fcba-45a0-ab43-7ff90b93ce34',
    'identification_number': 17735530,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '511378e2-3f0f-457b-a715-4172ec3c3ed8',
    'identification_number': 91996384,
    'item_consumption_1': 234,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 99.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc8c1a0d-ee4f-4c47-8c4e-b32578a3ba05',
    'identification_number': 14635232,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 133.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '527c04ca-23b0-4f12-aaab-555fd22091c6',
    'identification_number': 17192021,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 45.85,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4c1ec25d-e225-445d-88e0-33e9188f0625',
    'identification_number': 56877250,
    'item_consumption_1': 11893,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1517.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39089544-4ef0-4c3f-9105-5e1fc579f800',
    'identification_number': 71434690,
    'item_consumption_1': 6109,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2587.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c66c50d1-edaa-4191-9909-8cac1470b5ee',
    'identification_number': 101073593,
    'item_consumption_1': 377,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 147.5,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '19794d08-2779-4337-9ac2-26bc057e323e',
    'identification_number': 19306580,
    'item_consumption_1': 189,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 73.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9eefb76c-c631-452d-9afd-9c17b9df915e',
    'identification_number': 27904660,
    'item_consumption_1': 7.33,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 343.28,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '38875a5a-94b0-4583-8e20-dbf480a62bec',
    'identification_number': 42469805,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.99,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e3736871-f8a0-41d4-8485-010f4dd52932',
    'identification_number': 45239282,
    'item_consumption_1': 58,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1686.98,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd20b7e80-8f04-44bf-aa03-38f9e7b75b09',
    'identification_number': 15526194,
    'item_consumption_1': 5923,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2308.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a45c07b-ce31-403a-980c-0b6d7a7e5661',
    'identification_number': 32747217,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c726b5e6-4b00-4f48-901d-30145062fe4e',
    'identification_number': 101575220,
    'item_consumption_1': 2820,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1100.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd39a5dd-cdc0-424f-bcfe-7fd1e52ccbfc',
    'identification_number': 948743603,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f59e676d-5687-412c-b67f-9f6930ff9616',
    'identification_number': 13327623,
    'item_consumption_1': 332,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 129.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17738f8a-491f-427e-8d0e-29ecbb79d883',
    'identification_number': 104179228,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1448.18,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '07503877-ceb7-4767-85c5-ab533189ee76',
    'identification_number': 103164111,
    'item_consumption_1': 1549,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 605.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2050774b-814e-4b68-b50e-d1e950592f9f',
    'identification_number': 9788166,
    'item_consumption_1': 29.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 844.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e8110983-f754-4446-8445-ea7188c7c443',
    'identification_number': 26139111,
    'item_consumption_1': 43718,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5580,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97b2afe6-0ac4-456f-a218-9a5d4962a376',
    'identification_number': 14063476,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6880e881-6297-4e6c-992a-69417efd87f1',
    'identification_number': 15101797,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 196.54,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '13b6da26-9e07-4cca-9143-29d1a12c3c20',
    'identification_number': 109447514,
    'item_consumption_1': 42446,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15796.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be627262-2c48-41d1-bd5d-c81bba3fffad',
    'identification_number': 19304722,
    'item_consumption_1': 4660,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1976.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad639948-64e7-40f7-be12-1206a4f7757c',
    'identification_number': 58741470,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba8d38d1-9060-430b-ba73-a0a072cd203b',
    'identification_number': 98925083,
    'item_consumption_1': 643,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 251.58,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '635703dc-0736-491f-a408-d504733b68e2',
    'identification_number': 14859424,
    'item_consumption_1': 55.15,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1604.07,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd35a8ed3-e38d-4d0e-a645-2be3b71d80e4',
    'identification_number': 78084113,
    'item_consumption_1': 2513,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 320.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df6a9aef-21c3-475b-9984-409c7409c53d',
    'identification_number': 45653313,
    'item_consumption_1': 5299,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2245.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f6fee44-005a-428c-8cb1-1563e3304f05',
    'identification_number': 94724741,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'da2ec48c-c414-4cef-ae09-3c42254a4789',
    'identification_number': 92158064,
    'item_consumption_1': 7382,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2747.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6d0c274-bd90-4ccb-8d4d-0a250f534b5b',
    'identification_number': 108331024,
    'item_consumption_1': 31,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 12.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '81fc0bc1-7411-47d3-bcd0-ce277e25b79d',
    'identification_number': 53535448,
    'item_consumption_1': 1947,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 824.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eecd7aec-bd66-4ae7-a817-39a49d459272',
    'identification_number': 78334640,
    'item_consumption_1': 56.38,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1639.85,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fe2880c8-0c8c-43c3-989c-c9abb0c3e1d2',
    'identification_number': 58741470,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d289965-5c61-4dff-8a48-8ee309e5a8d2',
    'identification_number': 56877021,
    'item_consumption_1': 9254,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1181.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eeb0eb19-7030-4a03-85c2-003a70b17b30',
    'identification_number': 101870728,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 86.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ffff6d76-af4a-4c86-89b5-1b4543485abc',
    'identification_number': 40701301,
    'item_consumption_1': 12.31,
    'item_description_1': 'DEMANDA REAT EXCED USD FP',
    'item_total_value_1': 358.04,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'd0b56f88-39b5-4228-96e6-d38d300aad16',
    'identification_number': 96279257,
    'item_consumption_1': 490,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 190.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb73471a-21fe-43fb-874c-42e95cafcee7',
    'identification_number': 64417093,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b63b6c9f-dd30-4658-9e2a-9973e1942ca5',
    'identification_number': 85325872,
    'item_consumption_1': 1020,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 432.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9099691-d482-4d4f-9255-ef21222089e6',
    'identification_number': 8157944,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4af5a63b-d955-4524-b757-b75434aed15b',
    'identification_number': 8115788,
    'item_consumption_1': 335,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 141.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34644d76-ae59-4d21-9c6b-545fb4ea75c5',
    'identification_number': 97425907,
    'item_consumption_1': 7.77,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 225.98,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3c4eb396-10b8-4207-a743-77b8cce4d6df',
    'identification_number': 102481172,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '31d521a8-600f-4ee8-bcae-291c56222b7b',
    'identification_number': 25644220,
    'item_consumption_1': 167,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 70.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e6094e3-c1bb-4346-9c09-c2a926c34dbe',
    'identification_number': 42712190,
    'item_consumption_1': 2.4,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 57.27,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6a521e91-6434-4c6a-ae9f-14fb93489aa7',
    'identification_number': 30326834,
    'item_consumption_1': 2378,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 929.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18cefb33-563b-4a23-86c4-75fe02d95e34',
    'identification_number': 98019210,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf50850d-2265-462f-807d-5a1762899a27',
    'identification_number': 18898068,
    'item_consumption_1': 427.68,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 24424.79,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '537f9c62-5c46-47e0-a6ca-200c3017efce',
    'identification_number': 12381519,
    'item_consumption_1': 9.14,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 531.67,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'cb0db289-8511-454e-a68f-6c096f1d4288',
    'identification_number': 38674670,
    'item_consumption_1': 9804,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3819.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '27f1d959-6aee-49b4-b178-409202fa3af3',
    'identification_number': 99353598,
    'item_consumption_1': 74.58,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2169.21,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6042426e-ee24-48b6-84e5-aaa7b1f0b936',
    'identification_number': 30491100,
    'item_consumption_1': 1867,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 728.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf1c9f3c-7d82-4c59-bdfe-1c229c31459c',
    'identification_number': 80788580,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b685a6f-bfa7-4a08-b97a-5d84ae029d6a',
    'identification_number': 18274080,
    'item_consumption_1': 2292,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 893.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71f8e13f-4bc5-4996-ad93-2e5f6b28b515',
    'identification_number': 72755520,
    'item_consumption_1': 623,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 242.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da588db0-4486-4b67-affe-767f1e7f7d5a',
    'identification_number': 94576165,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3a538838-e372-46d8-92e5-0fdf87ca4f58',
    'identification_number': 8985510,
    'item_consumption_1': 7434,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3151.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7c6c673-f73b-490d-b1da-d609d512b0f3',
    'identification_number': 42396905,
    'item_consumption_1': 17574,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 26586.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ec4ff61-be3d-4f53-abfe-c49ee12cf36c',
    'identification_number': 16125312,
    'item_consumption_1': 2286,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 3935.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2784353-ccd2-4655-8fd2-ea9db6d0d8e9',
    'identification_number': 90790499,
    'item_consumption_1': 3048,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1780.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60414e0f-427f-439e-9c59-9aa945e44eb4',
    'identification_number': 98463888,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f66fa11a-f11b-407b-b6af-6fce38e7e5c9',
    'identification_number': 70128235,
    'item_consumption_1': 652,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 986.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51fd537d-e7ca-446d-813d-ecb38161e8ce',
    'identification_number': 23542438,
    'item_consumption_1': 5868,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2488.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f022216-f235-40e9-877b-0129f1fcdccf',
    'identification_number': 72307650,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '53fca285-ec9e-471a-9fc2-4c20c21878a9',
    'identification_number': 8115540,
    'item_consumption_1': 269,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 114,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89ef35a7-411f-48b3-ae0d-be17afc21f8e',
    'identification_number': 110598571,
    'item_consumption_1': 270,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 105.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b86a153a-6746-40ac-940b-89017329b9a0',
    'identification_number': 27516008,
    'item_consumption_1': 740,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 314.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77f16aa0-4bc4-4b8d-8d24-daaa3e4bcea0',
    'identification_number': 38893614,
    'item_consumption_1': 21946,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8167.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2e05d42-5f50-46d2-ac2c-586c4809e6dc',
    'identification_number': 98752480,
    'item_consumption_1': 959,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 373.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a020273-f3b8-48cf-8c22-6af4e470f734',
    'identification_number': 37781430,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 10.33,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1898b322-5783-406d-a9ab-d8de9640eb8d',
    'identification_number': 33651868,
    'item_consumption_1': "'-9.774",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-3399.47",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'a2c140d9-fa91-46e6-bda0-13cfe1f71ebb',
    'identification_number': 95566651,
    'item_consumption_1': 5487,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2041.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b9f5483-bc31-4253-be16-35604b38c3b4',
    'identification_number': 24083542,
    'item_consumption_1': 119.26,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3468.78,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7ddc50de-1371-4393-81b2-00a77ac7f853',
    'identification_number': 49853295,
    'item_consumption_1': 3454,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1468.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f161529f-f532-495a-a7c5-dc006546dba9',
    'identification_number': 8891800,
    'item_consumption_1': 100,
    'item_description_1': 'DISPONIB',
    'item_total_value_1': 75.95,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '78edfb35-c45b-4e33-85e2-ef97ccbb32c0',
    'identification_number': 109446852,
    'item_consumption_1': 2256,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 957.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ff11bfc-733b-45d1-aa0e-766ba79f5778',
    'identification_number': 104132779,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '366ccc04-095d-4a2f-95b2-fae735498fc0',
    'identification_number': 98624792,
    'item_consumption_1': 4132,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1537.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96b80ddd-345d-4c1e-a36d-b04efbadcf76',
    'identification_number': 90879791,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f972f82f-03bb-4158-ae30-021633c5785f',
    'identification_number': 17970806,
    'item_consumption_1': 10932,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4068.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4e72d7a-dc45-4f8e-8825-185788ef73f4',
    'identification_number': 102645698,
    'item_consumption_1': 541,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 818.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86032e91-3e66-40d2-934c-846db094e4ad',
    'identification_number': 100637833,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fbbe885a-e91c-4618-8897-e5effa6f55c0',
    'identification_number': 75037653,
    'item_consumption_1': 633,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 247.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0b74291-741a-421f-9cbc-14be20ff9208',
    'identification_number': 34479686,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ebe8428-7a4a-4ff5-8cf1-99b3383ab8b3',
    'identification_number': 100138268,
    'item_consumption_1': 988,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 386.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e702176b-03ec-4b29-9ed4-d5b29202b8f7',
    'identification_number': 70447330,
    'item_consumption_1': 9.24,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 268.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5cf24474-323e-4b4a-a1eb-022118db12cd',
    'identification_number': 43338739,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '35383627-5a08-4bde-a15b-9babe8b0640b',
    'identification_number': 947580121,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bb2718a7-6ec5-4d9d-b2e7-9bbae511d473',
    'identification_number': 110977360,
    'item_consumption_1': 19722,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7339.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6732448f-943c-4563-afb9-b302aa88406a',
    'identification_number': 101941714,
    'item_consumption_1': 352,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 137.72,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '47ed712c-8c5f-4651-95ff-091573210cd1',
    'identification_number': 111027101,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c458e6b7-3db8-4383-9ebe-88e8a437a776',
    'identification_number': 72568500,
    'item_consumption_1': 1871,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 791.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c195ffdb-f638-4039-b676-cd00404ecf42',
    'identification_number': 84367539,
    'item_consumption_1': 381,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 161.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ad8df01-4654-4393-93c1-0ab708016ed7',
    'identification_number': 13877984,
    'item_consumption_1': 1.97,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 47.01,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c9a51ac8-02a5-48ad-9347-5c40454281b2',
    'identification_number': 55454500,
    'item_consumption_1': 73697,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 27426.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '093b8f99-2ced-4d7c-b1ac-dbc4a5e1edbc',
    'identification_number': 99305062,
    'item_consumption_1': 618,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 241,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '948cfe0e-7281-42bf-a716-fd526d514ff0',
    'identification_number': 108863026,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 0.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d3b41c2-2d71-41ed-b560-a29b61a3293e',
    'identification_number': 96580976,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 10.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd5f48f97-94f7-4c99-9a84-d4eaf8735103',
    'identification_number': 109447514,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ac6ecb9c-2d9d-417a-87d3-b76758f00ba6',
    'identification_number': 103346341,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 97.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a9ffa9e4-0e6d-456f-9e8f-eb4820efcdf9',
    'identification_number': 89679229,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.84,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '738d64d9-b365-4c01-bfcb-c0d8489e5f79',
    'identification_number': 22162330,
    'item_consumption_1': 46.88,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1363.55,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a278f0b9-7e49-4c02-bab3-f4618a7afe3a',
    'identification_number': 28858913,
    'item_consumption_1': 158,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 92.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f50e92d-0d6c-4355-b132-f77cfd2dbbcd',
    'identification_number': 27724280,
    'item_consumption_1': 821,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 348.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd04b7f57-30a4-4402-80c4-e6e405ba1dd4',
    'identification_number': 57673160,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b30991a1-6d90-4569-b195-7e4e1c625f9d',
    'identification_number': 29705932,
    'item_consumption_1': 48.46,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1409.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '32c6e8c4-9361-47ab-ae7e-bfdc367e2548',
    'identification_number': 79052363,
    'item_consumption_1': 1775,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 752.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '168ee6ed-17c0-4230-99bc-f65d3ceb4922',
    'identification_number': 51527472,
    'item_consumption_1': 258,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 150.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ee69e39-370c-4065-ac87-b94fe6f9cb11',
    'identification_number': 44805853,
    'item_consumption_1': 4.41,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 105.2,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ad0f20e2-dc71-40ab-941e-da14af628485',
    'identification_number': 104455250,
    'item_consumption_1': 30087,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11755.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2bc90572-07bc-4b16-82c9-37add4c795ad',
    'identification_number': 91487978,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 74.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'af20e2f6-43c5-4433-9524-e8b880f1c70d',
    'identification_number': 94653992,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '90900be4-2cc5-465e-a3e4-5ab55862415a',
    'identification_number': 43484506,
    'item_consumption_1': 1231,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 157.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e1a90c7-2df2-4f59-8d5c-044bf601d9c1',
    'identification_number': 4003474899,
    'item_consumption_1': 13852.9734,
    'item_description_1': 'Consumo Fora Ponta [KWh]-TUSD NOV/23',
    'item_total_value_1': 2038.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2bd2ff3-9abd-4147-9e6a-8449453a3cb0',
    'identification_number': 111097410,
    'item_consumption_1': 1111,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 433.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5dcf2f52-2525-49ec-b13a-a3cbf596ac77',
    'identification_number': 5576334,
    'item_consumption_1': 13400,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5692.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd2d33db-4830-4e59-8c41-47f196f0d3d6',
    'identification_number': 75896974,
    'item_consumption_1': 4.25,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 101.37,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd2c84c6a-0795-4c50-a036-349872f2f3ca',
    'identification_number': 49951670,
    'item_consumption_1': 80.44,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2339.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '366b41d6-b99b-498b-8c37-3669e02b5f12',
    'identification_number': 91804698,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '317f28f8-f651-480d-87be-06eaf251f0fa',
    'identification_number': 16677412,
    'item_consumption_1': 2.63,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 62.74,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c2b0a79c-55cf-4b79-9a39-4f9a9728bbec',
    'identification_number': 96383860,
    'item_consumption_1': 2169,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 919.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'abd435ad-a765-46cd-a6b8-af4ae382623b',
    'identification_number': 32234058,
    'item_consumption_1': 4.04,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 461.44,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f7fde417-54f4-4ff8-bb00-d2a67fe8acdf',
    'identification_number': 79545793,
    'item_consumption_1': 111.48,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3242.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd7315f58-537c-4da4-83fd-a292a79922bd',
    'identification_number': 43546900,
    'item_consumption_1': 2038,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 863.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0a37662-565c-47bd-b0a7-17702192c077',
    'identification_number': 13010476,
    'item_consumption_1': 1015,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 592.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ade5785f-cc0a-477a-9f77-242d974b97a9',
    'identification_number': 107160560,
    'item_consumption_1': 409,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 173.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccf546da-0d8f-4b1f-91fe-8748b6a27fd7',
    'identification_number': 13015419,
    'item_consumption_1': 1700,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 663.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f431dac2-975a-49e9-a3e4-2bc4eebc0045',
    'identification_number': 87914808,
    'item_consumption_1': 1168,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 455.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25b23f3a-9b89-4f51-89d0-04be89fc4c68',
    'identification_number': 32629095,
    'item_consumption_1': 19506,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 11392.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78a7aac3-614c-47d2-8789-388845f6f376',
    'identification_number': 99258692,
    'item_consumption_1': 7109,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3009.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f8e4d62-4fa8-4e8f-a004-e08bd6b2c025',
    'identification_number': 42709717,
    'item_consumption_1': 12948,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1652.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81354137-b982-45df-84e1-0b70425c58e7',
    'identification_number': 102159459,
    'item_consumption_1': 22,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 8.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '49086ddf-f5a5-40ac-abb4-0bafa92137ae',
    'identification_number': 14334399,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '160127ad-a03a-415f-a4cf-9d8189578db1',
    'identification_number': 41231058,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddcbf8a8-0420-4cc0-8ee7-9508c5328fcf',
    'identification_number': 15084175,
    'item_consumption_1': 4797,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2032.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30fce674-4433-49b4-896c-533dbd5596ea',
    'identification_number': 35436778,
    'item_consumption_1': 1012,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 395.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '790e112c-abe7-4fb7-80ed-230d6a115d2c',
    'identification_number': 65485416,
    'item_consumption_1': 935,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1414.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8cd8587e-a112-4b43-b12c-62a7fc78fc8e',
    'identification_number': 94693714,
    'item_consumption_1': 3109,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1212.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '682e1fc7-67b8-4290-91a4-3ebeadad4ff9',
    'identification_number': 105472336,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1660acf4-9ecf-4f1d-84e5-56dfc5e9bcf4',
    'identification_number': 102891028,
    'item_consumption_1': 18014,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7630.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f371336-f41c-4784-b1b0-9878f519b292',
    'identification_number': 103175954,
    'item_consumption_1': 6821,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2890.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8215d9d4-b7e7-4d66-a70b-a2029954b576',
    'identification_number': 99379422,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e43407ba-6cfb-427a-8d8e-785d62a417b2',
    'identification_number': 56092326,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8055a417-387b-438c-b21d-447ccf545cfd',
    'identification_number': 40174220,
    'item_consumption_1': 16.58,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 395.45,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b4525175-1cf3-4af8-b5cd-bd52b12de474',
    'identification_number': 72240431,
    'item_consumption_1': 11541,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4513.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30a700b3-30ad-4282-be25-258e14be32d5',
    'identification_number': 5586860,
    'item_consumption_1': 2556,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 999.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0bcabfa5-9a11-49de-94ec-152abdc2811d',
    'identification_number': 35137010,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 41.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ae650257-8714-4a9a-8f8f-90216a01b937',
    'identification_number': 14562553,
    'item_consumption_1': 13.87,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 792.09,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '188c6f3e-7495-4287-8fd9-ef4dbde5794a',
    'identification_number': 21633762,
    'item_consumption_1': 628,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 245.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10ca5797-1c90-4999-9fd3-7571495fac4d',
    'identification_number': 55454500,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a4c43059-0762-4e77-ae97-378f1fa00179',
    'identification_number': 97224596,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 225.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84b6354f-9e7c-4eb6-9795-9cf1846c1c2c',
    'identification_number': 32810300,
    'item_consumption_1': 4900,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2861.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7a6c399-a71b-41bd-a267-9923a0753663',
    'identification_number': 39452336,
    'item_consumption_1': 12383,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4608.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '263c4757-8d46-4b44-9ab8-e4788139469a',
    'identification_number': 10267832,
    'item_consumption_1': 2550,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 995.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24bdc1bd-3086-4213-b9bd-5f7b3bae623e',
    'identification_number': 33124302,
    'item_consumption_1': 286,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'badf1c22-7da6-42fe-9cb8-a9bb8cff1447',
    'identification_number': 12176532,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.66,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '379ddc91-4f19-45b0-84a3-73351c3d5b87',
    'identification_number': 26987415,
    'item_consumption_1': 12.51,
    'item_description_1': 'CONSUMO FORA PONTA TUSD',
    'item_total_value_1': 1578.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ad162e0-3910-4a8e-b2e0-48b95004d128',
    'identification_number': 103475192,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40d0e2aa-2e84-4373-89ea-6d945b172b0e',
    'identification_number': 84777117,
    'item_consumption_1': 58.89,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1712.86,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '894b6d7f-9760-45c6-90db-fc20cdc2ce66',
    'identification_number': 41360869,
    'item_consumption_1': 35948,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 14063.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e820ed8-c661-4292-b935-cc9701309f78',
    'identification_number': 33235953,
    'item_consumption_1': 40248,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 23506.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48e9b28d-a857-4364-964e-bca597e484e9',
    'identification_number': 69968705,
    'item_consumption_1': 8487,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3594.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9e6b8e0-09c9-4bc9-9fe2-655d0095f539',
    'identification_number': 48362832,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3e3ecced-1848-4abd-b64f-475398f3a334',
    'identification_number': 96849711,
    'item_consumption_1': 228,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 96.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9c43e32-a727-48af-a2fb-8510ae063b28',
    'identification_number': 101840098,
    'item_consumption_1': 1153,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 489.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97684234-69b7-4698-98fb-54aa2ebbe9c2',
    'identification_number': 56697198,
    'item_consumption_1': 5175,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 660.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03b873b4-5862-4d99-9748-a00d4a0d6f54',
    'identification_number': 46565256,
    'item_consumption_1': 2342,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 916.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86674aa0-aa4b-4d31-8442-8c9dcfe2f70f',
    'identification_number': 67330142,
    'item_consumption_1': 425,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 166.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b412d4ae-5318-41bb-9211-843966e695f9',
    'identification_number': 28528646,
    'item_consumption_1': 214.9,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6250.56,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '715eabe7-c01a-4881-8bb8-7374fdcf30e7',
    'identification_number': 107588226,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6989a023-0f74-4954-88c5-721fa3d5ca42',
    'identification_number': 110238826,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '388caada-f86a-499a-8a72-1098ed3112dc',
    'identification_number': 77776500,
    'item_consumption_1': 1663,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 971.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c9ebf6b-1297-4b11-8f0b-40ef9aeae7c3',
    'identification_number': 43152767,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '899b9254-aec8-4c3f-b918-bd18aa21e4ac',
    'identification_number': 84968559,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b05b50e4-d702-4dc5-b5eb-33de08da97c1',
    'identification_number': 100637701,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e4e6869-2f00-4268-a7d1-58c841bdca28',
    'identification_number': 79937667,
    'item_consumption_1': 161.72,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4703.76,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '16bb9fcb-aa89-4f60-a97c-8a962f19428b',
    'identification_number': 31945252,
    'item_consumption_1': 24923,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9275.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'deca0993-1cb1-4edd-aaac-e52a8ab71922',
    'identification_number': 109655885,
    'item_consumption_1': 806,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1219.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6397e894-5e75-481e-9335-5cd14de56ef8',
    'identification_number': 17708206,
    'item_consumption_1': 2460,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 962.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cdd1d6e0-2869-48ef-8288-658a3ae70d03',
    'identification_number': 44595972,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7cfcfc2-a111-4658-ada3-ec99da7a1cec',
    'identification_number': 40636399,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '54346fa6-8a73-40f5-8de9-6b6e9837983e',
    'identification_number': 79703070,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '060ba9f0-188d-4e3a-823d-c663cc444683',
    'identification_number': 26275287,
    'item_consumption_1': 54.41,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1582.56,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5bafe0b7-22f7-4b49-a365-27ea64630aa1',
    'identification_number': 105663247,
    'item_consumption_1': 26,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 15.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d5bb190-bbd7-45e4-8147-41c9636b2b81',
    'identification_number': 45563896,
    'item_consumption_1': 2618,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1109.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ff15602-cd0d-468c-8601-3d6ec7dba05b',
    'identification_number': 35000430,
    'item_consumption_1': 2893,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 369.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddddd135-2019-4ff5-9da0-ff915da1868b',
    'identification_number': 35429585,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1517c760-2629-49bc-b928-50f5879f5f99',
    'identification_number': 18217516,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8397a86a-1aac-492f-b895-6fd979b97972',
    'identification_number': 13789414,
    'item_consumption_1': 3121,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1161.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80a1e991-73c6-4736-8e71-d8e22818d24f',
    'identification_number': 98265172,
    'item_consumption_1': 878,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1328.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3205fc14-95c5-436b-9413-2d19e30425f4',
    'identification_number': 106729136,
    'item_consumption_1': 304,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 118.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0897be78-c206-49f0-8f8a-82939f25ae29',
    'identification_number': 12384933,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-356.18",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'd6b1f512-8d49-47be-9a66-d19597542d49',
    'identification_number': 14562553,
    'item_consumption_1': 2281,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 291.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68aa5ff4-2abc-4ca0-83d8-cc0a1e0ab99c',
    'identification_number': 18209793,
    'item_consumption_1': 2637,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1117.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9be3a773-191d-488b-9f27-139ee168b5ed',
    'identification_number': 17074991,
    'item_consumption_1': 3950,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1678.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f7ff1d93-568b-434f-8556-f7e641b0f508',
    'identification_number': 94391645,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '33df3f11-1b7d-402f-9d2d-7d49c1719117',
    'identification_number': 108332438,
    'item_consumption_1': 13549,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 7913.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0c712d2-0e02-49e3-906d-7248958dc576',
    'identification_number': 56666462,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 5.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd91187cc-74fe-496a-a439-0c2edb013616',
    'identification_number': 34210229,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fbdae01-83f6-4e1f-b9b9-0aa7fc24096c',
    'identification_number': 46699236,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3058ac7e-a3e1-4c45-aa49-57f23f5d03b6',
    'identification_number': 70574502,
    'item_consumption_1': 28012,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10424.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e53bd06-ffd5-445c-af7b-ae18a33e3e57',
    'identification_number': 19306903,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a45211e-f808-47aa-b3f3-64e1900a7107',
    'identification_number': 94602123,
    'item_consumption_1': 214,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 90.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa753627-b145-40f6-ae28-b0ae21bc0c09',
    'identification_number': 5984319,
    'item_consumption_1': 2660,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1038.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce24058b-ec76-4eda-abf6-e2c5a3bf6168',
    'identification_number': 56698151,
    'item_consumption_1': 959,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 560.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1ff005a-1998-4422-99bc-71e21cdaacc0',
    'identification_number': 20384939,
    'item_consumption_1': 985,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 418.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5d07956-2568-47cd-8bb5-6aeb2cc9e907',
    'identification_number': 40972666,
    'item_consumption_1': 2545,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1081.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25eb9f51-a673-494c-a0eb-ad33d41d3bfe',
    'identification_number': 68021348,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 58.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0b798a5d-dcb4-4ac3-b351-7ca6ce420025',
    'identification_number': 27383067,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13d62bc4-0dd7-4ebd-85e3-19a80692cead',
    'identification_number': 14909413,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30591d7e-c4c6-48f8-9aef-1a829a142a6b',
    'identification_number': 30085900,
    'item_consumption_1': 1619,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 945.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b71288aa-1b94-4414-b2b0-ce0abd00c8c1',
    'identification_number': 100187390,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf3a4b5a-0a41-4d63-a912-539331cfe0ce',
    'identification_number': 15002578,
    'item_consumption_1': 6.2,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 360.67,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '3fd0e4b1-48ed-4ec6-9772-cdc03253c0a0',
    'identification_number': 59355573,
    'item_consumption_1': 14.39,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 418.52,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0ae15e60-2fdc-490e-8531-2fcb613120bc',
    'identification_number': 95043551,
    'item_consumption_1': 6808,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2890.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a94a446-057c-4403-a4b3-f3bd67ee9e0a',
    'identification_number': 15981452,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '51f7d054-7d61-42a7-8b4d-8afed5fa1d68',
    'identification_number': 109460227,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '814d66b2-1b45-437c-8c24-5bd89088521d',
    'identification_number': 103209328,
    'item_consumption_1': 1848,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 782.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ad59fff-9d1e-40b9-81ac-ec71954233b7',
    'identification_number': 30085918,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f89bab17-eee9-4c0b-9333-af0da496959f',
    'identification_number': 69703671,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6b92ae44-134c-4f2c-a158-8173cac69f66',
    'identification_number': 105737720,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '768f2321-fafb-44dc-a4dd-56c36e1cc57c',
    'identification_number': 59044659,
    'item_consumption_1': 7847,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2920.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a3c4f52-b8a3-4815-b239-a06e7547f864',
    'identification_number': 48018422,
    'item_consumption_1': 7326,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3110.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c968c7cf-db0c-4268-947c-2a8d261aa42a',
    'identification_number': 42710529,
    'item_consumption_1': 1953,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2954.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c73f38dd-1075-4c1c-b1a3-795c7b9d835c',
    'identification_number': 95864792,
    'item_consumption_1': 7682,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3263.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6385091-b9cc-4219-8a36-04df3e7f3431',
    'identification_number': 12773840,
    'item_consumption_1': 6565,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2567.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4a5b660-453a-4d57-be11-bd5d46da8d09',
    'identification_number': 106144480,
    'item_consumption_1': 647,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 274.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3558e740-31a8-44e1-a688-e04ccb4069e0',
    'identification_number': 12446645,
    'item_consumption_1': 4088,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2387.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d27cd28-eeb5-4960-ae5c-bda26810d9e5',
    'identification_number': 80982379,
    'item_consumption_1': 104,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 44.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6be7ceff-9259-4061-98e7-f98e950fc366',
    'identification_number': 55316883,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 106.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e7af0ad7-d3fe-4868-9704-36423f2eb4e7',
    'identification_number': 95633359,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'fb746796-4c84-4efb-8a67-3d3f206ef434',
    'identification_number': 19119852,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 130.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '25563b61-c0ff-4d98-98c5-477942a767f5',
    'identification_number': 72307650,
    'item_consumption_1': 578,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 245.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be94e8ad-d5b0-444b-af56-a998e9010db4',
    'identification_number': 22808213,
    'item_consumption_1': 148,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 57.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0513c930-7d0c-49cb-8b47-ecf00e441022',
    'identification_number': 19296398,
    'item_consumption_1': 6963,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2948.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b1d71e2-9b96-473a-9f60-20fb4c05a5fd',
    'identification_number': 59264993,
    'item_consumption_1': 14118,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5253.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e18ce21-d68d-4512-be7d-3456fc02ed41',
    'identification_number': 44476256,
    'item_consumption_1': 10027,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5856.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '527a48c3-2ed4-4feb-88ba-9447917da60f',
    'identification_number': 7677359,
    'item_consumption_1': 1086,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 460.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a421c213-652a-4a6a-ac20-6def8d2890e9',
    'identification_number': 75876957,
    'item_consumption_1': 259,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 110.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52743546-7bf5-4ed1-a630-c8bd551731d3',
    'identification_number': 106087266,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8c552054-4147-4f8a-a5e3-30f4b4825359',
    'identification_number': 106729985,
    'item_consumption_1': 116,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 43.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2d5227c-c123-4501-beae-42b52bceacc0',
    'identification_number': 94384126,
    'item_consumption_1': "'-3.084",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-987.96",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '0c6b9c0c-1e15-4a6b-a74d-6e6fb0acac66',
    'identification_number': 18009492,
    'item_consumption_1': 3046,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1191.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45cf4630-6224-452e-8a27-31a8f2e859e0',
    'identification_number': 43626955,
    'item_consumption_1': 12780,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1631.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75b56b10-762c-4810-9fbe-cc96791c5009',
    'identification_number': 19299281,
    'item_consumption_1': 249,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 97.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9676abbf-d002-47fc-9c96-489d08309801',
    'identification_number': 99102935,
    'item_consumption_1': 544,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 317.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34f31e77-e4a0-4014-8de2-7f698ead6743',
    'identification_number': 86283154,
    'item_consumption_1': 296,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 447.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6506398b-eb4e-4ef9-9c8c-66e28be518d3',
    'identification_number': 108863026,
    'item_consumption_1': 11,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb6d6560-fa03-4238-9da1-0b872b656cfb',
    'identification_number': 18094694,
    'item_consumption_1': "'-11.428",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 06/2023',
    'item_total_value_1': "'-3971.09",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '13bfb2da-e9c0-4e1a-a3ed-3d23b4e226c3',
    'identification_number': 68441347,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f04e27e0-a722-44df-85a7-21304a479b1f',
    'identification_number': 106077406,
    'item_consumption_1': 37,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b42be6c1-b8a9-46fd-a425-38d5258a59cf',
    'identification_number': 923323694,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '36c1f3b3-06c7-4be8-b352-21a2f263f734',
    'identification_number': 95225641,
    'item_consumption_1': 501,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 212.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ee38b73-0899-4134-8a46-cc4e5a94ac24',
    'identification_number': 88735800,
    'item_consumption_1': 335,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 141.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c321c7d9-cddc-485e-ab88-863a40175efc',
    'identification_number': 43061842,
    'item_consumption_1': 2168,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1266.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fbe079e-61c2-4d5e-b721-aebe08d44b3b',
    'identification_number': 45115745,
    'item_consumption_1': 141889,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 18110.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdfab979-4650-41ea-a4fd-b33f370be5cd',
    'identification_number': 28860136,
    'item_consumption_1': 871,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 508.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6103be3-e020-4429-b54e-1984ab0c55fc',
    'identification_number': 99344173,
    'item_consumption_1': 13239,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5603.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be70bdb0-4662-47d2-9d53-0c7ca3517376',
    'identification_number': 101499531,
    'item_consumption_1': 6753,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2861.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6d92c8c-ae7e-4e6b-8c4b-fbc3a119020a',
    'identification_number': 102054690,
    'item_consumption_1': 147,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 62.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2689aae9-ab38-4e73-8fcf-910d5a431047',
    'identification_number': 61231002,
    'item_consumption_1': 2114,
    'item_description_1': 'Energia Ativa Consumo kWh',
    'item_total_value_1': 1641.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '943fc31b-cac4-4df3-b756-572a587b1215',
    'identification_number': 28993055,
    'item_consumption_1': 242,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 102.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b250511a-48fe-47cc-80a5-68a7df83850b',
    'identification_number': 12255351,
    'item_consumption_1': 4018,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1706.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7902fd3-dbae-4851-b3ca-b003ca83b02c',
    'identification_number': 95917160,
    'item_consumption_1': 2657,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4016.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af9bc728-3fec-4a80-a257-01489c97a5f8',
    'identification_number': 14862336,
    'item_consumption_1': 1118,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 652.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d54ac04-2b5a-4e13-a9f5-7a3d724bc524',
    'identification_number': 95043551,
    'item_consumption_1': 6808,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2659.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e076564d-a077-4921-9f10-46cb179fb9c3',
    'identification_number': 59422009,
    'item_consumption_1': 20.53,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 489.67,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '24ee6597-7896-417a-8c05-62652c3cf217',
    'identification_number': 15002578,
    'item_consumption_1': 5176,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3022.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ae82124-a73b-41a9-939d-5a809b20a5e5',
    'identification_number': 46677615,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f216a640-73ea-4560-a773-12728c0abef1',
    'identification_number': 33557004,
    'item_consumption_1': 6424,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2502.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ebf4c04-b90f-4077-a908-9af888c03c1d',
    'identification_number': 27904660,
    'item_consumption_1': 583,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 228.11,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '64a14b4f-6036-4ea5-bae2-15b006e0cdfb',
    'identification_number': 924063026,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 57.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '15a47ecd-f313-4317-b9cf-02fa11419eb4',
    'identification_number': 42491428,
    'item_consumption_1': 32032,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 12520.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9878bc98-57b1-4922-9068-f5852cf4555e',
    'identification_number': 22914315,
    'item_consumption_1': 3894,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1653.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e99d7afd-9782-4f59-af57-595daa6de365',
    'identification_number': 97988804,
    'item_consumption_1': 11066,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1412.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9b4c861-18ae-4fd6-91ea-0d06545fdd6d',
    'identification_number': 27516440,
    'item_consumption_1': 829,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 323.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93485e35-1b22-445d-bc86-b9f85f93acec',
    'identification_number': 14138034,
    'item_consumption_1': 2221,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 283.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d9dac6b-1926-44db-9f03-b6e29e0ef8f6',
    'identification_number': 26139111,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b9768250-1890-403a-8ff1-56994bee4aac',
    'identification_number': 40391159,
    'item_consumption_1': 819,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 320,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce60b2fe-9463-421f-8903-78aede9e6570',
    'identification_number': 100567924,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.54,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4f5ed2a2-823a-42e0-a3ab-66da791010bb',
    'identification_number': 15107434,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b6f3879-ce31-45ab-939b-4b45e65e45a1',
    'identification_number': 102645698,
    'item_consumption_1': 6.86,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 163.63,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '84cac0ca-91be-455e-b6ad-4dbe23f8aeaa',
    'identification_number': 80508790,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe1fe863-6ed1-456e-bd13-edbdce1a1f07',
    'identification_number': 37423258,
    'item_consumption_1': 444,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 188.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6972aac8-6965-4689-a1a2-d644c6a872a1',
    'identification_number': 91393973,
    'item_consumption_1': 23877,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8885.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c174b999-8fb0-4255-a4e2-899981a554c1',
    'identification_number': 66244013,
    'item_consumption_1': 1192.8,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 34693.7,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0a2a1e15-e2e2-451a-b4e1-36a2a407c467',
    'identification_number': 43875939,
    'item_consumption_1': 4.66,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 271.07,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '461a57ea-edd5-40ef-95e1-6d40c9397dfb',
    'identification_number': 31686729,
    'item_consumption_1': 36407,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4646.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b76e8ac0-2238-47d4-8470-a789d7c90161',
    'identification_number': 44826044,
    'item_consumption_1': 1891,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 736.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ef6c0ec-f8b8-4dc0-9dfe-13354fea3400',
    'identification_number': 54453844,
    'item_consumption_1': 986,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 575.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d37514a-695d-4f6f-9c6d-a07797391874',
    'identification_number': 101472790,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4fcb839d-0837-4812-9d6b-cb6927413a8e',
    'identification_number': 43195300,
    'item_consumption_1': 127.74,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 3715.45,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4d4914f7-965a-43fe-9218-eb16b92f4033',
    'identification_number': 106814893,
    'item_consumption_1': 6077,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2369.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46bd90d9-a880-4da1-bd9f-24ccc276b0b1',
    'identification_number': 75565730,
    'item_consumption_1': 1010,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 395.18,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '62f0a7c4-caf8-41c4-af63-d86721cb0b99',
    'identification_number': 63416794,
    'item_consumption_1': 41.27,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2356.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '496746b3-0cc3-4a14-8cea-f7af373cd863',
    'identification_number': 94728526,
    'item_consumption_1': 769,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 325.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bbf4dbb4-181e-43e6-a76c-57372e6d6545',
    'identification_number': 13789422,
    'item_consumption_1': 4962,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1846.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edb1598d-39ff-405f-a1a5-43c3b3b8fb16',
    'identification_number': 108653765,
    'item_consumption_1': 626,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 244.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '491c57f2-009b-4db3-86cb-48702fd3e1c5',
    'identification_number': 78334640,
    'item_consumption_1': 25339,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9429.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c41bc9e-6493-4556-afd9-f20418773835',
    'identification_number': 60767928,
    'item_consumption_1': 3164,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4786.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6166a322-12d7-44c3-b13d-037ba40ce90e',
    'identification_number': 18389473,
    'item_consumption_1': 13276,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5183.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bae1910e-9dfd-4e7c-b833-e5ae46ddea81',
    'identification_number': 18900267,
    'item_consumption_1': 1039,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 441.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd31d8ecc-f96b-453c-83f8-563beb051d88',
    'identification_number': 98123521,
    'item_consumption_1': 2077,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 879.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ea35066-faea-4137-8580-ddd0c3746905',
    'identification_number': 19453779,
    'item_consumption_1': 47038,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 71159.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5655c5aa-cf58-4b2d-9772-b039362aab2f',
    'identification_number': 99540525,
    'item_consumption_1': 66,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 28.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee634072-7c5b-45dc-a622-4e18f0fccc6c',
    'identification_number': 99219751,
    'item_consumption_1': 2609,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1106.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f73876d-e74d-48d8-9cd2-95dcaaf93e53',
    'identification_number': 94546592,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe229008-aa2c-432c-b8a3-6c15bbe90606',
    'identification_number': 91302412,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7bbd0870-db66-42e6-be92-3a6c65e873bf',
    'identification_number': 103358960,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3b05990-dfc8-4fc9-aa25-b8959508fc5e',
    'identification_number': 19450303,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 35097.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf72b9a1-f8e4-46d0-88ec-a24a00dc38fd',
    'identification_number': 18898068,
    'item_consumption_1': 217261,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 27730.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5931312-0bc8-439c-9559-cc4e16c2eb4d',
    'identification_number': 31996841,
    'item_consumption_1': 2490,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1055.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0abe3bd0-54c5-498f-9d35-31491cca2bc5',
    'identification_number': 44001681,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b222c902-41bd-4d9a-9a7e-79b284c3692c',
    'identification_number': 38847280,
    'item_consumption_1': 48,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 18.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7d188835-7822-44a6-ae3f-9e2fdeee281b',
    'identification_number': 923309792,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fe6857db-af64-407d-a8ae-38ef4a52c2d1',
    'identification_number': 48843075,
    'item_consumption_1': 50.97,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1482.5,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '335eaec3-01a8-4c3c-a6da-f5205659ab88',
    'identification_number': 78084113,
    'item_consumption_1': 43.96,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2510.55,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '68c44891-27de-458e-9128-fcbc7efe6d42',
    'identification_number': 108248321,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '935cb43f-17c8-4da1-9385-845d2a72c61d',
    'identification_number': 97647616,
    'item_consumption_1': 151,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 63.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0eb0d586-1b6e-45a9-9ccf-1d8c4d6be28f',
    'identification_number': 44001681,
    'item_consumption_1': 101.94,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2965.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c5ccf3ff-27a3-40a3-86bc-7c2c954ac802',
    'identification_number': 58770500,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8cefde37-cc0c-47b4-a150-4f68c975328e',
    'identification_number': 67329942,
    'item_consumption_1': 1760,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 687.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9525f29d-f0be-425d-87ae-c83176af1a0e',
    'identification_number': 70082090,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 077/080',
    'item_total_value_1': 20.99,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': 'ffc1c077-2305-4ad8-a648-2c9709380665',
    'identification_number': 16699696,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b772a1ee-73de-4101-a4c9-212e3b9fbaff',
    'identification_number': 88508048,
    'item_consumption_1': 6431,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2729.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee306d6f-2fa5-4cbf-ad86-d97cc1526800',
    'identification_number': 108400034,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-8.04",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '7bbdf416-e85c-4175-8701-b3c5a9631266',
    'identification_number': 83645640,
    'item_consumption_1': 807,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 314.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b083375-e0ef-4e8f-a030-eae8ce6a7b1e',
    'identification_number': 49096397,
    'item_consumption_1': 19.92,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 475.11,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8ea4d51b-58e2-4b97-8976-54203e3a2807',
    'identification_number': 32715811,
    'item_consumption_1': 9114,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3391.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c49f93d6-ab51-4eab-be37-55d588f80327',
    'identification_number': 947580121,
    'item_consumption_1': 50.92,
    'item_description_1': 'Demanda de Potência Medida Fora Ponta',
    'item_total_value_1': 1568.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '030e1763-8867-4249-ad08-015130930b47',
    'identification_number': 35614595,
    'item_consumption_1': 6.02,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 175.08,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a9e189c8-de43-4d8b-a5aa-97d822a314fb',
    'identification_number': 40657663,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eaf79ba8-1efc-438e-b7d2-fd8e49fae80c',
    'identification_number': 92661300,
    'item_consumption_1': 1072,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 418.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '985e9b02-339d-4956-bc29-a45312d26d5f',
    'identification_number': 84768754,
    'item_consumption_1': 21.77,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 633.19,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c2a9df84-c22a-42df-ace4-3b1e1dc0cfbb',
    'identification_number': 53226429,
    'item_consumption_1': 24865,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9253.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7349d6e-7b02-4a3f-a089-4b353c143b2a',
    'identification_number': 94460566,
    'item_consumption_1': 40,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 15.63,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e2e90da3-410f-4279-a717-298b53f5491a',
    'identification_number': 70485070,
    'item_consumption_1': 11.09,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 264.52,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'ce16568c-1b67-4040-84eb-c77ce2cabef3',
    'identification_number': 91641209,
    'item_consumption_1': 698,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 295.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06472732-7f0b-4bb6-b0d4-3836c8be1361',
    'identification_number': 103617515,
    'item_consumption_1': 2.16,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 51.52,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9548db0c-d426-4c97-868b-49c02ca45a3a',
    'identification_number': 95332162,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-2184.87",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '47915c5a-9561-436e-8da7-fc771c037cac',
    'identification_number': 46006583,
    'item_consumption_1': 4.73,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 275.16,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e4146e55-8e17-47bc-8912-d17527af47e2',
    'identification_number': 38847280,
    'item_consumption_1': 2609,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1523.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d7111c1-0321-4e37-93d5-001187921719',
    'identification_number': 98942468,
    'item_consumption_1': 10833,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1382.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be82f30c-fffe-4b91-aeb5-aa41a471f623',
    'identification_number': 11490586,
    'item_consumption_1': 1278,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 499.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '866ded23-2b45-4d22-9e39-16879dfb9d9f',
    'identification_number': 8554471,
    'item_consumption_1': 1787,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 698.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '999e278d-e0cb-458a-b8cf-5e6dda9a19db',
    'identification_number': 11680148,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 0.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1134a691-24be-4951-a6ac-cfc5079a6f93',
    'identification_number': 32486804,
    'item_consumption_1': 28570,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10632.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e7c1ad3-62a2-4d78-af4d-725632b15533',
    'identification_number': 103157980,
    'item_consumption_1': 295,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 172.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd35a4a83-a0ba-4f41-860b-e5637f11079f',
    'identification_number': 15369080,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.99,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0a5e9fe7-8594-455c-a997-6bf9414a8feb',
    'identification_number': 15871789,
    'item_consumption_1': 15.91,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 462.74,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bcfe447f-c43a-4d92-b737-cc1bc3e9ee80',
    'identification_number': 96698527,
    'item_consumption_1': 6134,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2604.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edf41a18-d00e-4623-b493-b3ddbf85cf57',
    'identification_number': 87252627,
    'item_consumption_1': 1662,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 649.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f3eb588-8476-4b3e-8203-cd51509ddf63',
    'identification_number': 32715811,
    'item_consumption_1': 90,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 136.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c12b98ea-16c1-4339-986f-d32624cf2e95',
    'identification_number': 58739149,
    'item_consumption_1': 0.41,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 9.79,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4c67b5e9-2b2a-4bda-8f1f-cb5d3dcefde0',
    'identification_number': 97072494,
    'item_consumption_1': 14.46,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 344.89,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '488781e0-d589-4033-b355-673dd5932544',
    'identification_number': 84983981,
    'item_consumption_1': 1.9,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 55.26,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '1da80bbe-1896-40bd-bed9-e897e1775713',
    'identification_number': 76636291,
    'item_consumption_1': 9743,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1243.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04af1bf2-4892-437a-aad7-430efb9785e1',
    'identification_number': 102259178,
    'item_consumption_1': 22.34,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 532.83,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '255f74bb-3b4b-4f6a-bbc1-1d06c2b20dc2',
    'identification_number': 108987973,
    'item_consumption_1': 24931,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 10596.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8090851-0876-432b-bf5a-bfdc0b103a8e',
    'identification_number': 18898068,
    'item_consumption_1': 20931,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 12224.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a6a564f-5503-4297-a36b-a16100283787',
    'identification_number': 108601676,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '8091fd78-edf4-498a-8226-1271ccf77edd',
    'identification_number': 103617515,
    'item_consumption_1': 5233,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1947.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '149218b2-b9e2-4590-8eae-23530f1a2e92',
    'identification_number': 97988804,
    'item_consumption_1': 48.01,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1396.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c30c8b24-cf5d-47d8-a47d-15297eb83b2b',
    'identification_number': 927293026,
    'item_consumption_1': 842.1,
    'item_description_1': 'Energia Reativa Exced em KWh Ponta',
    'item_total_value_1': 348.07,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': '9b1f1950-e15c-49b1-86b7-4d024738d93f',
    'identification_number': 12985058,
    'item_consumption_1': 500,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 212.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9712255-c07d-4636-9244-bbc9645c3f99',
    'identification_number': 94896330,
    'item_consumption_1': 1161,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 492.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4581161d-e1ea-4ffe-a530-19b7a070ef2e',
    'identification_number': 15522229,
    'item_consumption_1': 11464,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4266.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a30ea2e1-ce30-4509-b669-a1886ce211ec',
    'identification_number': 96427361,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c6f83627-fd89-4df3-bf8b-bff921a3be8c',
    'identification_number': 7947267,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37c4fd0a-e10c-48b9-848f-c601f921d6ce',
    'identification_number': 92739245,
    'item_consumption_1': 22839,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2915.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b89b8e28-0454-4b39-97e0-176321ca5f83',
    'identification_number': 6776582,
    'item_consumption_1': 941,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 398.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b822af3f-ee30-4537-b16f-f32cf1762fa1',
    'identification_number': 45563896,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '797bc591-90db-40fa-86d2-b8733d98378f',
    'identification_number': 109861035,
    'item_consumption_1': 65.78,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1568.89,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '43dfed93-fef6-4972-850c-44cefa760470',
    'identification_number': 8529205,
    'item_consumption_1': 13,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 5.07,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e0444d62-6f5b-4c0a-81dd-1b05e1107105',
    'identification_number': 76606473,
    'item_consumption_1': 1446,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 614.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3f5ee50a-6f25-4bea-83f7-37aa22a8477d',
    'identification_number': 93950993,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2fe9b60c-6087-4cfe-98ae-51c54731feee',
    'identification_number': 106681664,
    'item_consumption_1': 547,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 212.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8fff7ffc-fae3-47a3-a4ec-2fbde15ed3a8',
    'identification_number': 98882104,
    'item_consumption_1': 2485,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1053.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a048e27e-d55e-428b-911f-b4bb395c35fa',
    'identification_number': 70039356,
    'item_consumption_1': 2501,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 976.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39a02bc9-fe21-4e9a-a6e8-ba0a3ba89076',
    'identification_number': 98988271,
    'item_consumption_1': 5564,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2362.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bcd9180-4b1b-43a4-9981-4b8c3ffae7c1',
    'identification_number': 17228328,
    'item_consumption_1': 2313,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 979.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c52edcc0-b777-42a5-b1c1-a87f1a490f0b',
    'identification_number': 12956465,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 82.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd8bf35cd-997e-4f39-bd53-4e4460205502',
    'identification_number': 42404576,
    'item_consumption_1': 31992,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4083.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4769d4c-7643-4c6a-bda0-b135cbcf698e',
    'identification_number': 14984369,
    'item_consumption_1': 5720,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2422.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da5a1381-f258-45c6-8507-525906310152',
    'identification_number': 33760900,
    'item_consumption_1': 32,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 12.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b47ad8c0-dd0c-4671-9d91-9b153794c7ae',
    'identification_number': 9995846,
    'item_consumption_1': 330,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 128.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '638fce50-d21b-4efa-a98f-8937b5c0694e',
    'identification_number': 19541627,
    'item_consumption_1': 10.72,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 623.6,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'dd78b192-0259-473b-b973-0df5fd905d25',
    'identification_number': 93950993,
    'item_consumption_1': 7023,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 10624.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e6bfaac-e9d8-40c6-87c2-a4a1ea01636c',
    'identification_number': 42537711,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ae744ba1-107e-4812-8836-873c06f24e92',
    'identification_number': 60092904,
    'item_consumption_1': 1065,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 135.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74a444b5-bae1-4464-b9c9-c7500100a1b4',
    'identification_number': 104193824,
    'item_consumption_1': 767,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 299.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5139060-90e1-49fb-b3e7-656828736877',
    'identification_number': 43036830,
    'item_consumption_1': 9927,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1267.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50829800-677d-4fbf-b5fd-daeba6052b1e',
    'identification_number': 110922786,
    'item_consumption_1': 811,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 344.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a8806a9-85ce-4406-a260-4fb2777708b0',
    'identification_number': 106358340,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '35663acd-e63f-4495-9840-35b5a908148d',
    'identification_number': 49074423,
    'item_consumption_1': 0.3,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 7.18,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '25c0101d-256a-46c7-97f9-1b55d7a31e8c',
    'identification_number': 23554681,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dc66b920-7384-485c-967f-645b64c4a56f',
    'identification_number': 101837461,
    'item_consumption_1': 3245,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1377.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75a03604-bcb7-499e-8667-b3c15fcf4b6d',
    'identification_number': 35088974,
    'item_consumption_1': "'-15.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 12/2022',
    'item_total_value_1': "'-4.8",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'f3107e75-dc45-416d-accc-f38ef7e7d89e',
    'identification_number': 98370200,
    'item_consumption_1': 59.97,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1744.26,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a05642ad-b77e-4b4e-8bbf-3dd777ba1ac9',
    'identification_number': 14227096,
    'item_consumption_1': 101,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fcdc58f8-4b99-4881-8045-4a54a15a18d2',
    'identification_number': 85325872,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.84,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '50ab380b-5482-474c-8c3a-c01ab9233f6a',
    'identification_number': 40636399,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e9859c35-ed8e-4076-b8d9-d3ec64df15aa',
    'identification_number': 57723354,
    'item_consumption_1': 2070,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 876.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77e582ab-357c-4670-8404-699965c0a0b3',
    'identification_number': 6922058,
    'item_consumption_1': 833,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 324.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cf58dd2-2a3c-4490-9414-f462a4bafc07',
    'identification_number': 33799733,
    'item_consumption_1': 5888,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 751.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f1395fa-a708-4a1f-9d62-65d5ed04b2df',
    'identification_number': 5863473,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86059c36-f8e5-466e-9a18-74ca719714bd',
    'identification_number': 18649092,
    'item_consumption_1': 626,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 265.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '850daaf0-0b4e-477e-ac7d-dd984eb8ed97',
    'identification_number': 97072494,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5d6f5547-7740-4715-b836-073519a82861',
    'identification_number': 29700485,
    'item_consumption_1': 1884,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1100.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ced5f4f3-910d-47e2-9425-102ad89b907c',
    'identification_number': 74880462,
    'item_consumption_1': 295,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 115.41,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1bc9d965-2f1a-45d2-b078-696ee147b406',
    'identification_number': 104483083,
    'item_consumption_1': 884,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 375.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ef3e75e-cbe5-4eff-9d72-c568d9b0a269',
    'identification_number': 36235334,
    'item_consumption_1': 77.44,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2252.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '05a84067-f450-4055-85db-d5b4687812cd',
    'identification_number': 8705100,
    'item_consumption_1': 350,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 148.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '608c3aa8-2b4a-48b5-9019-f396e584ca0b',
    'identification_number': 103125841,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 64.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '39983d2b-a314-465b-8570-01188b48eebe',
    'identification_number': 41213947,
    'item_consumption_1': 60328,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7700.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '137c900d-ab83-44f6-9014-12379c1db00e',
    'identification_number': 108332438,
    'item_consumption_1': 44.28,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 1056.11,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4de9188b-6756-4e3a-a518-f50971f5c540',
    'identification_number': 70174458,
    'item_consumption_1': 158,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 61.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59b8f5e3-a47d-45f9-b468-d75bf98c899d',
    'identification_number': 19450753,
    'item_consumption_1': 70295,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 41054.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '049e5fae-8ff3-493a-9bff-3bfb9c10f4f6',
    'identification_number': 96197552,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '955e22e0-8ab2-4daa-92ea-5393a4d84cab',
    'identification_number': 17000424,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ea4baa1c-8127-4672-a6da-0812ad5f02a5',
    'identification_number': 14104709,
    'item_consumption_1': 1,
    'item_description_1': 'En Exo Reativo fora de ponta',
    'item_total_value_1': 0.3,
    'reference_month': '11/2023',
    'referente': 'Energia Reativa Excedente Fora Ponta',
  },
  {
    'uuid_items': 'e5a27dfe-7269-4155-b4d0-308bf014afb4',
    'identification_number': 111115299,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e219dc5-ca05-4667-aa7b-52768b2e78c7',
    'identification_number': 75064430,
    'item_consumption_1': 11363,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4228.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75b066b6-e679-44cf-98a5-677a472b37f5',
    'identification_number': 8529205,
    'item_consumption_1': 1938,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 721.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc42d819-eb45-4a46-a365-593190318d26',
    'identification_number': 66168090,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 18.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '20890e8e-e8ef-41df-95cf-350b50d293f1',
    'identification_number': 100137890,
    'item_consumption_1': 311,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 132.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6886dd39-5db3-4d81-8593-696eae1d54d1',
    'identification_number': 31792634,
    'item_consumption_1': 234.58,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 6822.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8547c349-8547-4162-9f02-70e36b5372a6',
    'identification_number': 78371023,
    'item_consumption_1': 4176,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1773.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0ed4e89-ba3c-4266-b75a-4950fc0ebd4f',
    'identification_number': 16095200,
    'item_consumption_1': 4977,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1939.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad8f63b5-863b-402a-9fc7-348923b9c917',
    'identification_number': 51210533,
    'item_consumption_1': 52.64,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1531.07,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6d2fbc3e-46d0-4f63-b36f-1e73b19c4135',
    'identification_number': 28223152,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 221.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '09a48342-16c2-44b6-b0e0-dd4a98643a27',
    'identification_number': 85177342,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'de4bd44a-e235-4164-9d07-f52b7ca04d70',
    'identification_number': 109204166,
    'item_consumption_1': 1057,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 448.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30d4fa30-2080-4ee6-831c-531f12192a50',
    'identification_number': 102958017,
    'item_consumption_1': 2572,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1005.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3899dfb0-5e78-4f25-8ed2-2e9a0d9e031c',
    'identification_number': 106636286,
    'item_consumption_1': 109,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75f69e01-23fc-428c-ba05-bb6e0afc7182',
    'identification_number': 46912401,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6810b248-897b-4392-9af0-133f810f6880',
    'identification_number': 22518967,
    'item_consumption_1': 1608,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 682.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6fce7eea-8025-450a-999c-04a5302e1380',
    'identification_number': 76376249,
    'item_consumption_1': 84294,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 31369.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '57f9fe30-0e7e-4cf1-a156-d1693dc67c36',
    'identification_number': 32234058,
    'item_consumption_1': 15,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 5.86,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9c146c60-446e-453c-a52f-4d50803371dd',
    'identification_number': 80072844,
    'item_consumption_1': 1210,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 514.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f62fea0f-13a2-4e7d-9126-581bc33347bd',
    'identification_number': 108940128,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '712300f0-f9a0-4fe3-a044-d0b7ac06e020',
    'identification_number': 100764827,
    'item_consumption_1': 8713,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3397.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2c50c3c7-b30a-4037-8be3-55438dcf32eb',
    'identification_number': 70128235,
    'item_consumption_1': 5861,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 748.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62176f64-7276-4e27-916a-619580206ecd',
    'identification_number': 12176532,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a100eba0-798d-42d6-b3e8-9ac716011dc1',
    'identification_number': 105611271,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '59441779-5361-4c49-a881-c244c4795117',
    'identification_number': 106835122,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7efb9b11-de8a-4625-91f6-df679aa9b622',
    'identification_number': 23848367,
    'item_consumption_1': 202,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ff2e034-8a31-4e08-83d3-5fbe74e102e8',
    'identification_number': 98906470,
    'item_consumption_1': 2939,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1246.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5307cbe7-8bb5-468f-a454-052de90b24dc',
    'identification_number': 60978880,
    'item_consumption_1': 560,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 218.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b101975-cfc9-44f9-a2e9-989b9fb6636f',
    'identification_number': 12804924,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c943eeb9-23c4-4023-b9c1-7ba154b2daa9',
    'identification_number': 69703515,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.66,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6a386214-2a42-4ef5-89c3-8a4bd1638fa6',
    'identification_number': 77431650,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-11.82",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '3bde03df-2010-46e3-8603-7b9992a12f61',
    'identification_number': 28528646,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4fce1590-3f2b-42ec-aad2-8e782324f8f5',
    'identification_number': 40504077,
    'item_consumption_1': 31161,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3977.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba481cab-aaa4-47eb-a119-fd92d98aa351',
    'identification_number': 77221630,
    'item_consumption_1': 106,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 41.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da312461-5598-4ae9-849d-ff48eb1725a5',
    'identification_number': 26275287,
    'item_consumption_1': 11,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 16.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f401ae82-4250-4e3d-92d6-7d0fb7b970a8',
    'identification_number': 15572862,
    'item_consumption_1': 9,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 523.54,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '2d105e84-80b7-4d76-9d91-73a94a5d67fa',
    'identification_number': 90950917,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '58deee38-bbe3-452e-8ab6-f25dff0a042e',
    'identification_number': 33069182,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '182185c5-88f1-4733-8734-892e021e3182',
    'identification_number': 32191871,
    'item_consumption_1': 1199,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1813.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfb13a02-9138-4ce7-941f-353fea8b637d',
    'identification_number': 16105648,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '282c8381-2823-42a7-ad8c-bd9bcd24c894',
    'identification_number': 56708890,
    'item_consumption_1': 14404,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1838.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52e7c8be-492f-4a5d-a694-54029ae2003d',
    'identification_number': 109864905,
    'item_consumption_1': 187,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 79.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a197ed31-2d09-4035-9c51-b5c37ab7b9e7',
    'identification_number': 75768879,
    'item_consumption_1': 3281,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4963.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39160281-c513-4dc0-8c60-53dd4f6475ce',
    'identification_number': 65331389,
    'item_consumption_1': 137,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 53.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3ecb48b-ec78-4415-a4d6-af466df6e3c0',
    'identification_number': 61773409,
    'item_consumption_1': 1248,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 486.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8187f6ba-3379-4e5c-a329-184ed9467ce4',
    'identification_number': 99971852,
    'item_consumption_1': 64.59,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1878.65,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '23c77440-3090-48cd-8647-0737b7bd6a39',
    'identification_number': 10379444,
    'item_consumption_1': 5827,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2477.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ac7411b-b4a5-425d-be00-55a4666cc732',
    'identification_number': 71893156,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '248b0104-73a3-48f4-9c58-b2b8685687d9',
    'identification_number': 13372688,
    'item_consumption_1': 12440,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4629.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ea04c33-bb69-4c27-87a6-fc6b4479d1f6',
    'identification_number': 26705230,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-117.47",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '27653e7b-6f54-40a6-a305-5a928f2c29d4',
    'identification_number': 46343466,
    'item_consumption_1': 164,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 64.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a4feba2f-7216-4321-a43c-a2b2f24e2f2e',
    'identification_number': 104614285,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4059941b-d762-43a1-b0da-23e8f5044e47',
    'identification_number': 56527136,
    'item_consumption_1': 7844,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1001.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9af51e7c-7a15-4ffb-bcff-5961c24bcdf1',
    'identification_number': 42089808,
    'item_consumption_1': 6255,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3653.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8157a24-f3de-4b80-a343-33d8d28e2166',
    'identification_number': 17372682,
    'item_consumption_1': 12613,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4930.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a801d473-4262-4999-b6f7-821e52c58eaf',
    'identification_number': 25644220,
    'item_consumption_1': 167,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 65.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edf215bf-1a41-4c3c-933a-32a72522d9d3',
    'identification_number': 108736490,
    'item_consumption_1': 409,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 173.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cebbcd04-38af-4274-9f89-bcaa4e4814c2',
    'identification_number': 23029196,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8e0b865c-fd6a-408d-b9ba-e12b4eee491b',
    'identification_number': 108232700,
    'item_consumption_1': 6386,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2490.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1631259-11e4-42d1-9090-fa5ae2e10998',
    'identification_number': 100188060,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a05825dd-341f-4cdf-9854-806bb1ad5a03',
    'identification_number': 97171425,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b7cc54c-5d10-48ce-b161-ec4f547324b3',
    'identification_number': 9822950,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13702.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2aa18072-7643-4314-960b-29071b4e8855',
    'identification_number': 75890380,
    'item_consumption_1': 1.31,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 31.26,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6eecbde7-66be-49af-80ee-6f6bb8790852',
    'identification_number': 44805853,
    'item_consumption_1': 55077,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 20496.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cac5b115-8c94-4521-adaa-075ef6b9f8e1',
    'identification_number': 27895610,
    'item_consumption_1': 1617,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2446.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68cd6cd7-2da2-4894-bdf0-cebbe728eacc',
    'identification_number': 101940122,
    'item_consumption_1': 7593,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 969.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f38a268-6215-4e25-bc3e-b5bea421ef34',
    'identification_number': 42119057,
    'item_consumption_1': 873,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 340.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30be1499-6c5d-464c-bd79-299f8e6bbb89',
    'identification_number': 22609903,
    'item_consumption_1': 272,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 106.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '604bd22c-52bc-444e-b702-0d67877f109a',
    'identification_number': 67439314,
    'item_consumption_1': 89,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 37.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca43b219-9419-4715-af7a-22148c968e2f',
    'identification_number': 18616909,
    'item_consumption_1': 25.31,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1445.45,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f8ebc404-407c-409c-8c31-ef530b2a9c11',
    'identification_number': 5721865,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 25.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd3f7632e-64b1-4ea3-b562-892a0241abed',
    'identification_number': 36324876,
    'item_consumption_1': 1491,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 581.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f02f7a70-5768-43a3-8738-72202f751bbf',
    'identification_number': 97353590,
    'item_consumption_1': 68.09,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1980.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'd1b0f1b6-01f6-4790-975d-5c82c3340a1d',
    'identification_number': 3031341090,
    'item_consumption_1': 205,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 61.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6e919c7-4a84-49db-a9e4-72b0771c0dfc',
    'identification_number': 60767928,
    'item_consumption_1': 311,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 121.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '27bbb0c8-ae57-421d-a985-424fc64dadce',
    'identification_number': 109545907,
    'item_consumption_1': 18986,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2423.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02b1745f-92dc-46de-965a-862633387792',
    'identification_number': 93507798,
    'item_consumption_1': 3962,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1545.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60608b27-6b47-455d-9ca8-d3993db2a6e4',
    'identification_number': 13789414,
    'item_consumption_1': 642,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 251.19,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '627c163f-639f-4e43-8af3-d359590d7966',
    'identification_number': 32234449,
    'item_consumption_1': 5281,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7989.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63c1aeae-3d67-4ebd-b1f5-484a26ba1411',
    'identification_number': 13924613,
    'item_consumption_1': 16154,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2061.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45085cfc-33bb-4844-af9e-1b40afecc0de',
    'identification_number': 106613979,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0815c8d6-3603-463b-9bf4-b8cda14bb9b2',
    'identification_number': 96125225,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '245eca43-37e8-4b3f-b71e-63b41269267a',
    'identification_number': 19451113,
    'item_consumption_1': 79313,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 119986.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5e1c26f-6336-4e2d-ac88-133dbd2b7daf',
    'identification_number': 111076579,
    'item_consumption_1': 240,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 93.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8dec5338-a11c-4b39-9eab-011d02a8e101',
    'identification_number': 29772230,
    'item_consumption_1': 25748,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 10931.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'de5c0c63-1fea-4c2b-b1e4-d5ded4158b54',
    'identification_number': 98370200,
    'item_consumption_1': 3063,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 390.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df288b60-8838-42cb-b72f-3c621e8bbbe4',
    'identification_number': 109858859,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eaba1596-c71a-48c0-9eb9-e254d81a1ff6',
    'identification_number': 66254787,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 81.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '87cdb6b4-0f03-4ca3-88a0-f17c6dd8c60e',
    'identification_number': 69442347,
    'item_consumption_1': 1514,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 589.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '725dc55c-303b-44f0-90a8-0f8fe8653497',
    'identification_number': 98798510,
    'item_consumption_1': 7408,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2756.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1f88ac5-10c2-4b0e-8362-522a54047314',
    'identification_number': 10633162,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5976abb5-6257-4ce5-8df1-3097d529d419',
    'identification_number': 106113208,
    'item_consumption_1': 720,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 280.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a84ad50b-a05f-435a-b96a-999f81090ef5',
    'identification_number': 32486804,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.63,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '779fbe1a-9caf-4b36-99c7-a33c1c61f4f6',
    'identification_number': 106729985,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ff2403e2-34d0-4cfd-aff2-669ab54b4777',
    'identification_number': 17435048,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 109.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '489b6842-cb12-4e1b-a487-0329eac4320d',
    'identification_number': 87540550,
    'item_consumption_1': 3139,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1333.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39ce7b32-aa02-4409-b577-73e1cef73f62',
    'identification_number': 33625930,
    'item_consumption_1': 67628,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 25167.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca5cff5c-74dc-4261-8ce0-dcbe23639927',
    'identification_number': 19119631,
    'item_consumption_1': 1025,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 435.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f85e3c63-9747-4403-bff8-70976dc07394',
    'identification_number': 45602387,
    'item_consumption_1': 6728,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 858.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '92d526a8-98ae-4df9-9340-d4d1130cb439',
    'identification_number': 110008758,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-3.23",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '0ee3db03-09e4-470e-b1e0-f0658bb5e458',
    'identification_number': 21966230,
    'item_consumption_1': 75,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 3512.27,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'c8fa33ad-378b-41bc-9595-5e8bb20bf86a',
    'identification_number': 94529256,
    'item_consumption_1': 1948,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 827.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3b21694-082b-4053-8dbc-5d0ebac7fcc1',
    'identification_number': 61792098,
    'item_consumption_1': 26564,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9885.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42dd1ed5-6814-4418-972c-e1e6c144eb84',
    'identification_number': 41423690,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bd564bd6-0b05-4687-9b05-ab83d12b5c92',
    'identification_number': 39672131,
    'item_consumption_1': 53239,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6795.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2c9b767-202c-4384-895e-2beb18d6eb69',
    'identification_number': 17187923,
    'item_consumption_1': 830,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1255.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e19e3ac4-0feb-4ef8-be15-d2ed569e3aa6',
    'identification_number': 10138455,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.67,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '22299014-21cb-4191-82bf-498b03262903',
    'identification_number': 43908764,
    'item_consumption_1': 6.19,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 147.64,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b82ac811-abc8-4f92-a332-de22974d87c3',
    'identification_number': 96125225,
    'item_consumption_1': 113.35,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3296.9,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5f1ee56f-0a10-452f-8a53-e23e8c9b655c',
    'identification_number': 45216878,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48d2fd95-2d7d-4abf-9e13-ba45a7040549',
    'identification_number': 12381519,
    'item_consumption_1': 877,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1326.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b77b557-0e33-4996-90a5-f620ffcbbf32',
    'identification_number': 105186520,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1466.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6487ade4-e315-477d-b292-49d0cfdec6cd',
    'identification_number': 18182399,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '70cd4a2b-ed8d-45dc-bc2d-76535075def6',
    'identification_number': 75441233,
    'item_consumption_1': 21969,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 8594.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f8c1af0-7281-4a0c-ac2f-70eac3529122',
    'identification_number': 94658439,
    'item_consumption_1': 619,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 241.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fe7ca4c3-f529-4771-8383-865526a91777',
    'identification_number': 43875939,
    'item_consumption_1': 36270,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4629.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '922af058-be61-40cc-808c-8a44abefb69e',
    'identification_number': 105953261,
    'item_consumption_1': 42.55,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1237.6,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b66a7e90-8018-4129-be11-9109cadd475f',
    'identification_number': 104755920,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23c4b74a-9ca5-450f-ae42-54df974e6bc5',
    'identification_number': 74865145,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 130.95,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '853b3a25-33a5-4f0a-89eb-aef465d005e4',
    'identification_number': 56493371,
    'item_consumption_1': 1394,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 814.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae1ebf24-e72e-4a49-83d8-85d0a2342816',
    'identification_number': 21716480,
    'item_consumption_1': 4509,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1762.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '520cef8a-0a99-43d2-94dc-9c1fe2cfcc95',
    'identification_number': 17668956,
    'item_consumption_1': 3386,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1433.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a956e59e-6933-4fc7-8ad1-a644d1e80d8a',
    'identification_number': 97630926,
    'item_consumption_1': 784,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 305.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3aac6386-bc04-4d65-bd44-0653b0387fd2',
    'identification_number': 91516730,
    'item_consumption_1': 1692,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 660.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3b85b1f-4d78-4f87-921a-5cd8290f7f77',
    'identification_number': 19429525,
    'item_consumption_1': 17137,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2187.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1833441-fd8c-4325-94d1-c7069b0cc8de',
    'identification_number': 58745890,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '035a5a75-36f9-4962-9f33-ed4a20f4ccb0',
    'identification_number': 15909581,
    'item_consumption_1': 284,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 120.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fcc16eb-7894-441d-93c3-55e064152a68',
    'identification_number': 41379136,
    'item_consumption_1': 164.03,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4770.96,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6f750ba4-9fd4-4fea-b907-a26794548307',
    'identification_number': 45907137,
    'item_consumption_1': 5159,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 658.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fe03579-0381-43b3-bcd3-2500252ca5c8',
    'identification_number': 36033111,
    'item_consumption_1': 456.19,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 13268.7,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e60823d8-aecf-4785-94fa-2fa22c8931ad',
    'identification_number': 11346221,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b1b56fe5-588c-46f5-8e20-042fec359b3f',
    'identification_number': 51527987,
    'item_consumption_1': 11348,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1448.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a5ac186-2a81-43b4-bdf7-ce626b303a59',
    'identification_number': 12645,
    'item_consumption_1': 976,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 865.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f10effee-33ae-4b4f-b0cf-20b80b42387f',
    'identification_number': 81500513,
    'item_consumption_1': 13574,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1732.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dae76674-1676-4f81-9292-a516fd10e9ac',
    'identification_number': 31645313,
    'item_consumption_1': 55.3,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1608.43,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8a7bc525-9bb6-4444-ab1b-57b51bc23056',
    'identification_number': 92782221,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7497465-f44e-47c2-946a-8c45146e16d8',
    'identification_number': 105088730,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd816fd83-3b4b-43ef-a554-36c1610beba4',
    'identification_number': 60730781,
    'item_consumption_1': 3.13,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 74.66,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '47bc5719-7af4-402e-bbe0-2fb38ca90b94',
    'identification_number': 34722262,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 99.61,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a3b3471e-0214-49ec-8a86-4c617a44258b',
    'identification_number': 104499664,
    'item_consumption_1': 5589,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2181.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db18758a-8ab8-46a9-9ba6-2d4c2174f685',
    'identification_number': 75753758,
    'item_consumption_1': 3512,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5313.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e5d9b19-82df-4814-a99e-a8ddeab5db14',
    'identification_number': 43484506,
    'item_consumption_1': 8.11,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 193.44,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e4589e02-2f33-41d2-bcf0-30590daad317',
    'identification_number': 97491632,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '782d40a9-9a40-4c83-a6ea-20162d8abf01',
    'identification_number': 103962298,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e4aa480f-7f03-46c6-bdc8-2f22030873fb',
    'identification_number': 64175251,
    'item_consumption_1': 1176,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 460.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f42da59c-4395-4151-b2f0-fa3226c714bb',
    'identification_number': 46425152,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.48,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '52655a95-7d39-4d1e-89b5-30098fd8ea10',
    'identification_number': 44503849,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fb58eaf-7b09-46e5-8e02-9cb89070d0d0',
    'identification_number': 102345945,
    'item_consumption_1': 590,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 249.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9590f88b-1129-4462-a412-c295b08362e5',
    'identification_number': 93691076,
    'item_consumption_1': 2493,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1059.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01374b53-dc00-4652-917a-a4ae0d238171',
    'identification_number': 110543360,
    'item_consumption_1': 46739,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 17393.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '893abd73-9f2d-4460-b413-421797753425',
    'identification_number': 42272688,
    'item_consumption_1': 556,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 235.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d668d06-22b5-40de-983a-b2de41111173',
    'identification_number': 9671471,
    'item_consumption_1': 10197,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3978.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '372f1da4-84cc-42fb-b788-0ab267568a32',
    'identification_number': 36113743,
    'item_consumption_1': 1672,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 709.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93969780-fe29-4903-9e47-2d6c9485a44e',
    'identification_number': 76514587,
    'item_consumption_1': 301870,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 38529.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e002736d-7377-4a31-8832-a77f8d2f1baf',
    'identification_number': 106814427,
    'item_consumption_1': 525,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 794.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edb603a4-0a0e-4313-83b9-f1efd6e1ac53',
    'identification_number': 47154152,
    'item_consumption_1': 340,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 132.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df2854f4-aa1b-4c1b-b37d-9ec9be158d9f',
    'identification_number': 15981452,
    'item_consumption_1': 8004,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 3228.79,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': 'd4b51684-304f-48d3-92ec-8a8b0478ff41',
    'identification_number': 109545907,
    'item_consumption_1': 2590,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3918.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47d1a8ce-dddb-4e05-a3b2-a00e7c563010',
    'identification_number': 12804940,
    'item_consumption_1': 1193,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 506.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78dd9b71-a051-44d8-979d-2f3d7e79f16e',
    'identification_number': 27467759,
    'item_consumption_1': 2580,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1092.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c77a913-3f19-4a21-97eb-763040e56a12',
    'identification_number': 103721835,
    'item_consumption_1': 747,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 436.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6af330c7-5ca9-44fe-9732-fa846bc9f95b',
    'identification_number': 102014990,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66f63d22-7910-4b08-b67c-96b9d87490bc',
    'identification_number': 46577173,
    'item_consumption_1': 17.01,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 971.43,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'd707fd9b-75f2-4f2c-85bf-e698d9515634',
    'identification_number': 16570936,
    'item_consumption_1': 3471,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 443.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1b9f933-cf44-4a37-8230-685e507ad897',
    'identification_number': 14981556,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cdc01a84-6b46-4bad-bf64-f7d4b32f106f',
    'identification_number': 59479329,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c91a7a9-f5bd-4eee-9ad5-82fe922ff5c4',
    'identification_number': 15186075,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '722e86e6-ace6-410f-8d26-74074031e509',
    'identification_number': 43875939,
    'item_consumption_1': 3693,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2156.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd86bfdb3-9315-4a3b-8ea8-2300832019d2',
    'identification_number': 46364013,
    'item_consumption_1': 1789,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2706.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9e602cb-3201-46ee-a97a-513d7c7a5b40',
    'identification_number': 42627117,
    'item_consumption_1': 215,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 84.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a9e15be4-211e-4577-b3b2-cfdf3bacd003',
    'identification_number': 32500459,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2a39616a-522e-4996-a273-10016e79187c',
    'identification_number': 17933404,
    'item_consumption_1': 4066,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1588.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6a5e41f-efcf-471f-b69d-350969e1edf4',
    'identification_number': 38893614,
    'item_consumption_1': 57.07,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1659.92,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'be776319-89a6-49d7-be3f-1371cc69d138',
    'identification_number': 42302617,
    'item_consumption_1': 8341,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3537.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da5ec3e5-c72a-4197-bef3-94625f662165',
    'identification_number': 104906430,
    'item_consumption_1': 16950,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6628.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edde56c4-98d9-4284-974e-1d09d1a314b1',
    'identification_number': 34346007,
    'item_consumption_1': 2123,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 270.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '135d3298-3a0b-48be-bbfe-a8290a53873d',
    'identification_number': 68111720,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 27.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '28288725-32de-45b0-9a35-a59941b4b583',
    'identification_number': 97871508,
    'item_consumption_1': 23667,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 10036.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd912ae0-e539-4b08-a4c8-8a0274e22671',
    'identification_number': 86207563,
    'item_consumption_1': 115978,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 43160.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee95066b-6056-456d-95fc-89ca5a892b6f',
    'identification_number': 103124098,
    'item_consumption_1': 16592,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2117.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3f99411-5cc4-4cb9-8c60-5d7b95fc865a',
    'identification_number': 41499255,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cce507ac-6ab7-4c81-a641-6e794a61801d',
    'identification_number': 12024970,
    'item_consumption_1': 10748,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4563.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b10c18da-0eaa-4b44-b3c1-f2ba2a6ed7ff',
    'identification_number': 110216687,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b55c2fe-a150-4a8f-b7e4-801dc5d20d7c',
    'identification_number': 102024804,
    'item_consumption_1': 1607,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 626.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d1fb3d6-1af2-4014-b86e-d75fef438382',
    'identification_number': 17015391,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 97.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '75a8e35d-b83d-4931-b225-c228b9c2c726',
    'identification_number': 100203400,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b76ceb9f-768e-4e42-a2ea-20d6a9b09f3a',
    'identification_number': 43196934,
    'item_consumption_1': 821,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 320.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3396e547-7eee-4d67-8f88-925ea9463008',
    'identification_number': 34479686,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ef282d7-f2b8-46ca-b838-442b954eef33',
    'identification_number': 15365824,
    'item_consumption_1': 3602,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1406.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6a68c8e-1bd2-4c31-93c8-422fbb064f07',
    'identification_number': 94724741,
    'item_consumption_1': 646,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 377.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46867338-da10-4707-97c9-4280e3e25010',
    'identification_number': 39258777,
    'item_consumption_1': 3218,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1364.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '293d0788-32a6-4c8c-8b06-ae7b3eb6305c',
    'identification_number': 101591039,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df72c76f-8a0a-4618-b379-1d68030d61ba',
    'identification_number': 40995305,
    'item_consumption_1': 45,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 17.6,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '79767d7e-5fb8-48ab-a76a-d2c9b804397f',
    'identification_number': 22866639,
    'item_consumption_1': 672,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 262.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd34ce83-b066-4cc0-a387-5a22d0dbb0f9',
    'identification_number': 54313481,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eda90710-3719-4554-9090-be5b9a80e4c9',
    'identification_number': 11679751,
    'item_consumption_1': 33575,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4285.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4edaf37-5932-4c0b-84d8-324dd8ad4d0b',
    'identification_number': 9788166,
    'item_consumption_1': 1479,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 863.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '827877bc-1c64-4452-9b97-fc1764feb272',
    'identification_number': 40174220,
    'item_consumption_1': 5198,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1934.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06d25a3d-b017-4ccb-8f5e-b61a3ba1fdce',
    'identification_number': 5695015,
    'item_consumption_1': 1014,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 429.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8708e0c0-a86e-4b9a-8713-e1612967d85d',
    'identification_number': 26777258,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4d33ef41-a52d-41ae-be3f-35cbf7d3d5df',
    'identification_number': 72777320,
    'item_consumption_1': 1694,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 989.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '736cd1a5-8eaa-4bd9-8a71-218bf0f76c3f',
    'identification_number': 41176987,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2ce90ae1-7d18-437f-a29e-f5fd3c5ee715',
    'identification_number': 19786700,
    'item_consumption_1': 10943,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4648.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'be5bd765-029d-4a90-b8b9-f31ef086e03e',
    'identification_number': 109770846,
    'item_consumption_1': 323,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 126.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '183c6934-33ad-404a-b438-ff36d48d2bd3',
    'identification_number': 106851730,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d1227b4-56c3-43bd-b115-4dedf14271bc',
    'identification_number': 32605579,
    'item_consumption_1': 192076,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 71480.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c53965b-6026-44bc-bcfa-cbd977690d26',
    'identification_number': 41755138,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1e8d18f2-ffec-4458-9bfb-ee85f50bdd77',
    'identification_number': 35345322,
    'item_consumption_1': 4309,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1685.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f60a675d-096f-4bf4-bcb1-4bd3424a5ec1',
    'identification_number': 101778910,
    'item_consumption_1': 369,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 143.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '629ca204-f467-45b8-9220-4b116a9e653a',
    'identification_number': 63604116,
    'item_consumption_1': 17130,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6374.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33bed414-18df-4eec-b97b-270d44111eb1',
    'identification_number': 91394031,
    'item_consumption_1': 4186,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2444.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b30a5c9-b719-4ec8-967f-7ee7149e7987',
    'identification_number': 36878685,
    'item_consumption_1': 3811,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2225.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a7b31b2-b06d-4399-ae99-84d09915e011',
    'identification_number': 32202032,
    'item_consumption_1': 1,
    'item_description_1': 'Iluminação Pública',
    'item_total_value_1': 10.87,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a968b3ab-e548-47e0-b1e6-5ef001327268',
    'identification_number': 109204018,
    'item_consumption_1': 1121,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 476.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29bdb160-c9d7-453c-9947-7485c09fb9fb',
    'identification_number': 70073996,
    'item_consumption_1': 8630,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3657.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed6cfa3e-bf3c-4d27-8ee8-69abecdfb0a1',
    'identification_number': 94873879,
    'item_consumption_1': 250,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 106.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dcd1aad1-7e42-4216-9e51-e375d240740f',
    'identification_number': 102960216,
    'item_consumption_1': 1480,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 626.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0414e4e9-e84d-471d-b0ad-edc433f99a23',
    'identification_number': 18798594,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1eb589ab-7744-4f25-9f53-13f041c4db20',
    'identification_number': 18643582,
    'item_consumption_1': 10640,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1358.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0c7d016-9c33-4781-b170-b92b022a12a0',
    'identification_number': 3030784010,
    'item_consumption_1': 20316,
    'item_description_1': 'Consumo Ponta [KWh] TUSD NOV/23',
    'item_total_value_1': 2989.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8c88c24-dfce-479e-81f4-b21c0487b257',
    'identification_number': 101420625,
    'item_consumption_1': 896,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 348.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11d743a3-11c3-4d51-a7a9-6cfeab1d05e1',
    'identification_number': 85411396,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d310b84-68b1-4a6b-bc98-3a135685dd4f',
    'identification_number': 16003500,
    'item_consumption_1': 52,
    'item_description_1': 'Demanda faturada EP',
    'item_total_value_1': 803.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b9be66c6-47a3-46af-af22-cf3f6712fb46',
    'identification_number': 7664214,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 92.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a17f48d3-c24f-4f31-b775-825dfa8c356e',
    'identification_number': 94657190,
    'item_consumption_1': 1782,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 757.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad709308-e4d2-40c5-a106-b6c544293611',
    'identification_number': 30180392,
    'item_consumption_1': 1941,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 757.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '107fe026-5dd7-43f4-8655-df3e1b933c9b',
    'identification_number': 42694310,
    'item_consumption_1': 52970,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 19712.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56699660-dd74-4af0-9baf-8fe9221ea0a2',
    'identification_number': 27467759,
    'item_consumption_1': 2580,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1005.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cf0fe63-1e58-4fb3-9b1d-5299c18dd5b1',
    'identification_number': 66066760,
    'item_consumption_1': 555,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 235.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '364bfec7-6b76-4f74-9c88-d6cb040f1b4a',
    'identification_number': 16724275,
    'item_consumption_1': 4326,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1687.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ebdecf24-ea52-45b0-b3e9-be92f49f6aca',
    'identification_number': 37763989,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 16.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '928b5681-bb2e-4179-8b61-e58c789bf651',
    'identification_number': 55061648,
    'item_consumption_1': 1692,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 716.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '117896c5-4088-4215-8fa9-462ac829b703',
    'identification_number': 39906035,
    'item_consumption_1': 2380,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 927.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eee2f4ea-f0d5-48fb-a888-5a541a2574b5',
    'identification_number': 8998310,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b55bb6ef-a888-4351-8438-735590bb16ae',
    'identification_number': 15397009,
    'item_consumption_1': 4436,
    'item_description_1': 'Consumo fora ponta',
    'item_total_value_1': 1789.48,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': 'b73e54b6-80e7-4170-a7d9-f4dac364b80d',
    'identification_number': 99959739,
    'item_consumption_1': 78.32,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4472.84,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '730f19d9-7099-41e9-a755-54d00dd769b3',
    'identification_number': 80508790,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6459ac46-0ca3-4f10-bb44-3a3fe155a9a3',
    'identification_number': 91355540,
    'item_consumption_1': 4908,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 626.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77f652f6-22b3-45a3-807e-a031d8564f4d',
    'identification_number': 68502982,
    'item_consumption_1': "'-1.448",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 08/2023',
    'item_total_value_1': "'-462.69",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'c0c754ea-0d2f-4f2a-8adb-7c9d15bd8d44',
    'identification_number': 10549005,
    'item_consumption_1': 3260,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1270.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70439ed0-ac07-4bae-befb-8a46b11a7042',
    'identification_number': 49951670,
    'item_consumption_1': 43050,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5494.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '231f4ea2-62f5-421a-b6cf-41dccd74e303',
    'identification_number': 99971852,
    'item_consumption_1': 1565,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 914,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ca292c2-453f-415e-805f-f2eeb80a15f9',
    'identification_number': 63401738,
    'item_consumption_1': 616,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 240.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f010763d-4245-486d-a3b6-dfa94941454a',
    'identification_number': 13924605,
    'item_consumption_1': 8.9,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 258.85,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '432025c8-1b6d-45f1-b6b1-14ef5d2560d0',
    'identification_number': 95847952,
    'item_consumption_1': 47,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 18.39,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '13e31d59-f1c9-452b-9bda-b62f67d50c9b',
    'identification_number': 97817910,
    'item_consumption_1': 1046,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 408.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e6b8838-11df-42c1-85e7-b57be7bf8fbe',
    'identification_number': 97630926,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8ba9d18c-c3a2-4a71-ae99-f03c24f54223',
    'identification_number': 17667089,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1972a550-1f59-4335-8865-3ac7c2f0123d',
    'identification_number': 120479001,
    'item_consumption_1': 100,
    'item_description_1': 'C.DISPONIB',
    'item_total_value_1': 77.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00c67ef3-fddb-4510-a15c-9286d186fa04',
    'identification_number': 8874280,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 75.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '50f571c0-2d14-444c-aa47-c2303620dc2f',
    'identification_number': 69811369,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '20716941-688e-43d1-b41b-a259fd9e3d37',
    'identification_number': 15526194,
    'item_consumption_1': 5923,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2508.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5fb37b1e-93a9-4562-9f42-27140cc94687',
    'identification_number': 15004821,
    'item_consumption_1': 2870,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1120.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6ce7132-4904-4e1e-ac0b-9b1c441fc27b',
    'identification_number': 107692015,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bed45764-89fc-47cd-9491-71ea95e36c76',
    'identification_number': 31903991,
    'item_consumption_1': 122.21,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3554.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dea20e95-a409-42d7-80f9-f5e1028967fa',
    'identification_number': 106354736,
    'item_consumption_1': 850,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 359.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4242d726-21cd-4620-9a79-7c90a16977cf',
    'identification_number': 57673160,
    'item_consumption_1': 42.75,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1243.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0fee6483-9df8-4a20-9be9-651ed1443423',
    'identification_number': 20408390,
    'item_consumption_1': 6060,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2566.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '63a6d664-c831-411b-9450-b8c44280cba1',
    'identification_number': 64551059,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6caff703-4462-4f3b-886c-1b071c5fa873',
    'identification_number': 100456391,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '052baf07-39bb-4433-9dcc-c4b843bff369',
    'identification_number': 8115796,
    'item_consumption_1': 1067,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 416.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db06e1bf-3537-454c-a28d-e508926df8a0',
    'identification_number': 42433320,
    'item_consumption_1': 10332,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4029.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bc6e810-c870-4544-a513-d0aea7fe4145',
    'identification_number': 29589134,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 143.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8082dd31-d635-4b72-8138-f87784f7f5c4',
    'identification_number': 40995305,
    'item_consumption_1': 6726,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 10175.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ba1e0fe-eb11-41db-bf92-9ce7d2bd6512',
    'identification_number': 85780499,
    'item_consumption_1': 365,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 142.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a421a618-7b08-491b-9bbd-aa947ed66c88',
    'identification_number': 45564094,
    'item_consumption_1': 4277,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1671.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00d95f6a-c3d7-4445-a0f3-b4ceb72f2e99',
    'identification_number': 46364013,
    'item_consumption_1': 18336,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2340.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5337ccb7-d1be-42cc-bbd9-e176e3601c4f',
    'identification_number': 110543360,
    'item_consumption_1': 4691,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2739.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c54fbb6-cf31-4dc8-a79b-0a4d165f4e80',
    'identification_number': 79937667,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf77c2ab-49ef-4024-be5f-d636401c5aad',
    'identification_number': 73695190,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ffdba758-5df8-4ca5-84d9-a141c39184f5',
    'identification_number': 40450554,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9870467b-1cb8-4b24-8aa8-ef251454de92',
    'identification_number': 92724493,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '969a3419-0787-4db6-9b86-aba4c8fa0eac',
    'identification_number': 29745918,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45e0a769-511c-403e-9826-8ef3b5680d40',
    'identification_number': 101477040,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84718afa-8ba2-4470-a086-901ebc36aadc',
    'identification_number': 45907404,
    'item_consumption_1': 8.9,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 212.28,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3cc9e7a5-7453-46ee-8574-9715bc74ef82',
    'identification_number': 93181914,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b1515c90-0edc-4cd5-bab0-f7c39e2b05b8',
    'identification_number': 12384933,
    'item_consumption_1': 272,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 158.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cca13b06-916f-4697-8168-7614be26065e',
    'identification_number': 32627572,
    'item_consumption_1': 505.44,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 28865.68,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '00656e18-9b7e-4c6c-b5fb-2751e829c678',
    'identification_number': 44476108,
    'item_consumption_1': 20.6,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 491.32,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '963c94c5-27b4-47d5-975e-33585e9918a0',
    'identification_number': 93950993,
    'item_consumption_1': 55540,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7088.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b04ce11-b7ef-40f0-9611-6835cf81a1f6',
    'identification_number': 85312185,
    'item_consumption_1': 40.73,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2326.08,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7d196a15-a5e9-4969-b3c5-6f8bf04c2a69',
    'identification_number': 6941044,
    'item_consumption_1': "'-338.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2023',
    'item_total_value_1': "'-108.36",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '97b35df7-ec10-4e52-a9bc-ae64403661e9',
    'identification_number': 77724895,
    'item_consumption_1': 3349,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 427.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4383b42a-38e4-4616-897a-7f0d98189900',
    'identification_number': 70941149,
    'item_consumption_1': 19.41,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 462.94,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0340da98-f5c8-41d9-94b9-bfda023672c1',
    'identification_number': 45201137,
    'item_consumption_1': 385,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 163.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c02e24eb-3947-446d-bb7e-6059c32cf2d5',
    'identification_number': 56877021,
    'item_consumption_1': 10.6,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 252.83,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'dc3eda3c-1a1b-4b3d-8200-194db650a50b',
    'identification_number': 23078758,
    'item_consumption_1': 9.97,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 237.79,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '31c4dd0d-3c20-4c75-934a-302ceeafc117',
    'identification_number': 94460566,
    'item_consumption_1': 18782,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2397.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '392ac35b-24e4-4ece-9ba5-ca989d3e3fbb',
    'identification_number': 32266006,
    'item_consumption_1': 510,
    'item_description_1': 'Energia Ativa Consumo kWh',
    'item_total_value_1': 395.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eff70da8-221a-4b56-a505-8a03dd8907b3',
    'identification_number': 936300044,
    'item_consumption_1': 30,
    'item_description_1': 'Consumo em kWh',
    'item_total_value_1': 26.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '907e86c9-709c-460e-b6d2-4e6f77362a77',
    'identification_number': 13927060,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89392a4b-0384-4d7a-ab64-30cda4ebc2de',
    'identification_number': 95400044,
    'item_consumption_1': 7098,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2772.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '527ccf96-92af-46ab-9e23-fca76f9df930',
    'identification_number': 99541076,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9176b5ce-8774-42aa-bc46-838beee11fa3',
    'identification_number': 73822671,
    'item_consumption_1': 1484,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2245.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e2dfb21a-ea28-4437-86cc-dbf3de490105',
    'identification_number': 96736291,
    'item_consumption_1': 14213,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6026.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48cdd595-3e35-40d3-b7b2-a6bb8f2109c8',
    'identification_number': 28976746,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e0a8af5e-84da-41f5-9cb4-47b93a4a37bb',
    'identification_number': 41002717,
    'item_consumption_1': 1075,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 456.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01798857-0944-473e-bc4e-26a3a3129100',
    'identification_number': 67513212,
    'item_consumption_1': 474,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 201.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb7ab199-b61c-48ec-8552-f539540b3416',
    'identification_number': 9257780,
    'item_consumption_1': 4345,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1847.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f35be70e-3a4e-4f19-bca6-6fc65a8a3303',
    'identification_number': 16699696,
    'item_consumption_1': 14574,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5423.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a5b60ec-e715-4b86-8c23-109787dffff4',
    'identification_number': 98798510,
    'item_consumption_1': 736,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 429.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d19bb53-1ec5-4c49-a25e-ae38c2586447',
    'identification_number': 59144017,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4ab5e213-b39d-40c7-8519-517919971fad',
    'identification_number': 77405340,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5920f04a-64a6-4932-ae64-8247b376594e',
    'identification_number': 111132290,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '7509906b-234d-416e-b966-3ab729d2dfae',
    'identification_number': 11013958,
    'item_consumption_1': 56.82,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1652.64,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e6e4eeb6-69b4-4df5-8ffb-b2563ad9934f',
    'identification_number': 105957577,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71b8b32d-fd64-4cf3-8019-2c0a5c57a6b9',
    'identification_number': 14886308,
    'item_consumption_1': 4300,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1678.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79e463c7-6476-479d-a07d-c8bf2a66451f',
    'identification_number': 103704728,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8eb34ed0-3ce3-45f4-9d72-5ccec3351c7d',
    'identification_number': 10703454,
    'item_consumption_1': 3196,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1246.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da6ca6bd-03c7-460c-ae1d-cdce3d84e00a',
    'identification_number': 8115540,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'adc5cd68-118a-43e0-b06f-afeb4f3e20f9',
    'identification_number': 43505520,
    'item_consumption_1': 2812,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1098.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bc14d0a-af4f-4e8f-a423-cdbd0b15996e',
    'identification_number': 58309195,
    'item_consumption_1': 3311,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1403.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f79b4665-21c9-4f1d-819d-9b1397364c1a',
    'identification_number': 91303192,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40e80008-9384-498c-a32b-dd3c8a8878f3',
    'identification_number': 92912290,
    'item_consumption_1': 58034,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7407.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae319772-e939-4e59-934c-b5b84a06da8f',
    'identification_number': 81118546,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 210.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '116343d4-192f-4010-8d2b-2b6fdd3e83dd',
    'identification_number': 75299372,
    'item_consumption_1': 1315,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 768.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '447af864-4624-42c5-813c-ad5671eba0bd',
    'identification_number': 80787932,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9e893a1-9c2f-4068-8e1f-7d1e94aff6ff',
    'identification_number': 15449653,
    'item_consumption_1': 6360,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2477.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d3019af-934f-401e-b9d8-46586603614c',
    'identification_number': 40504077,
    'item_consumption_1': 31161,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11596.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ef0bb08-fd3a-49e7-97ec-29a335c3ec55',
    'identification_number': 47397276,
    'item_consumption_1': 108,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 42.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77ad39a5-bb13-4b7c-9fc0-4b3f958d82f8',
    'identification_number': 70926115,
    'item_consumption_1': 24406,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3115.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1819e584-918a-493f-821f-28c3b3aa0c23',
    'identification_number': 91359511,
    'item_consumption_1': 26,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 10.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c7e314ac-1b8e-4973-a827-1facff294f2f',
    'identification_number': 70810737,
    'item_consumption_1': 2398,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1019.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1d3ed55-8d01-474a-ad73-12d16effef4d',
    'identification_number': 63604116,
    'item_consumption_1': 57.88,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1683.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5517f2cf-61fe-4125-be17-b9545ff00077',
    'identification_number': 33354537,
    'item_consumption_1': 123,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 52.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79aaf883-844d-4e52-a595-0bf5a939fdbd',
    'identification_number': 41069420,
    'item_consumption_1': 804,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 313.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a26cf13a-d73b-4b3e-9038-4ada696d14d8',
    'identification_number': 107469553,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.97,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '67fc9503-c974-4508-b739-828801a9ad74',
    'identification_number': 104188120,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1437.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '714a987c-7fdb-4991-95c2-919479bcaf95',
    'identification_number': 101918925,
    'item_consumption_1': 179,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 69.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f8d41ba-b3a3-4288-bd8c-71614806c99b',
    'identification_number': 73431044,
    'item_consumption_1': 7280,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2846.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6e42f2c-06a8-487c-9970-0fa12f5dd022',
    'identification_number': 17581737,
    'item_consumption_1': 9817,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3824,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb0aae54-cb85-4785-b0b1-a3d551532e41',
    'identification_number': 31923917,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '97401f0e-245d-401c-93ea-1df073fc2895',
    'identification_number': 44010141,
    'item_consumption_1': 294399,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 109559.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd4f636d-c404-45db-a03e-0f9d9222d4be',
    'identification_number': 54453844,
    'item_consumption_1': 35.42,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1030.22,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '75efcb7d-d28a-45e3-847f-0f68826a124e',
    'identification_number': 19755511,
    'item_consumption_1': 1291,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 548.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a744ca9-a1ea-4414-8733-85192015bdc5',
    'identification_number': 94496919,
    'item_consumption_1': 1628,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 690.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3be59b6-fb3a-4814-95d0-e6393767c42a',
    'identification_number': 108357902,
    'item_consumption_1': 9277,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3617.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bcb43df-8639-4a1b-ba19-91a3583f1bb9',
    'identification_number': 81500513,
    'item_consumption_1': 142,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 55.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a9a614ac-52bd-40df-bf1b-674ea4637269',
    'identification_number': 5329582,
    'item_consumption_1': 76.8,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2233.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2728ca9b-e3da-43ec-afb0-e9fcb45a91b6',
    'identification_number': 98942468,
    'item_consumption_1': 6.25,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 149.08,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2f6b8411-2f96-4f31-94a0-741bb016ff3a',
    'identification_number': 22922521,
    'item_consumption_1': 286,
    'item_description_1': 'CONSUMO PONTA TUSD',
    'item_total_value_1': 351.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e079eeed-b848-4699-b369-c081c4d7595b',
    'identification_number': 96529091,
    'item_consumption_1': 1941,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 821.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '890b7068-c664-42a4-9aee-c2098192c359',
    'identification_number': 105805947,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 207.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37752c77-4da6-4f49-88d9-7d4821b50755',
    'identification_number': 110598350,
    'item_consumption_1': 306,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 119.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5ee63fc-53fa-4171-a83d-1020886b8e17',
    'identification_number': 101912331,
    'item_consumption_1': 7379,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 941.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64b1e3ef-34ef-4912-8442-0cf9c12cbe45',
    'identification_number': 29711738,
    'item_consumption_1': 1551,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 606,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '22dac6a2-a53b-4b7e-9d05-8474f1a5bced',
    'identification_number': 109487877,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b7ca304d-5059-42e2-a904-f0b3cbc8e290',
    'identification_number': 12275468,
    'item_consumption_1': 24384,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9074.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '88af8211-caa9-40f8-89d1-4fcd5851d8e8',
    'identification_number': 103291130,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79f10fe7-f947-4f33-a9c2-c7462883a828',
    'identification_number': 103967923,
    'item_consumption_1': 1118,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 436.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5363a7f-6dcb-4112-97d4-8121b031bdc5',
    'identification_number': 62508679,
    'item_consumption_1': 94,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 36.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '5b95de07-b86e-4947-935e-b7decacb8e19',
    'identification_number': 47989050,
    'item_consumption_1': 1.6,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 38.18,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd36d84bd-49bd-490c-8de6-5711ad8c6e7f',
    'identification_number': 103501967,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '112ce378-de86-4db3-b2da-8e8b0a8c41e0',
    'identification_number': 96269391,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 12.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '32c768f9-1361-4373-ba06-6e00e0cfaca7',
    'identification_number': 39538842,
    'item_consumption_1': 215,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 91.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '809f23b2-d12b-4268-b887-d4cb93c99e24',
    'identification_number': 3030777692,
    'item_consumption_1': 757,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 225.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4799578-7f6a-4685-8e88-150452122cf5',
    'identification_number': 99531054,
    'item_consumption_1': 5100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2162.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0df672e-85b3-434d-a4a0-d1265d188ead',
    'identification_number': 88426726,
    'item_consumption_1': 3109,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1214.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1b36918-a8c8-407e-954c-da88201311fc',
    'identification_number': 70447330,
    'item_consumption_1': 4848,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 618.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40f67e60-961d-47bb-b22f-7efd7cb889a2',
    'identification_number': 110683617,
    'item_consumption_1': 507,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 215.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86eca267-380a-43ef-87f5-76509ae74f36',
    'identification_number': 55524567,
    'item_consumption_1': 672,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 392.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a84a97b3-5996-43e4-8042-4127e2d9c54d',
    'identification_number': 30298482,
    'item_consumption_1': 2743,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1602,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39989285-2594-42a2-848e-5542ac66c27d',
    'identification_number': 56812477,
    'item_consumption_1': 16.65,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 950.86,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1f18c154-82d8-4333-a53d-3cde5d248c06',
    'identification_number': 5329582,
    'item_consumption_1': 31417,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 11691.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16397493-d3b6-4141-95af-eb87d5731870',
    'identification_number': 23562200,
    'item_consumption_1': "'-555.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-177.48",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '8a1be71a-3350-46ce-b7c0-6e9a752574fe',
    'identification_number': 52131718,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 253.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7757c0d5-94c4-4b20-9a64-5a354be42e3e',
    'identification_number': 15214133,
    'item_consumption_1': 28463,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11087.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '018fd247-0a6d-46ce-8997-f289533ef1c2',
    'identification_number': 90439325,
    'item_consumption_1': 3586,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 457.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ddd723e6-2712-4f4b-bd59-94ffd487fbe3',
    'identification_number': 42537711,
    'item_consumption_1': 3397,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5077.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50884577-f35e-4b57-9005-a9311e89257b',
    'identification_number': 948743645,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89aed75c-9718-410c-acaa-2e44cbe8405c',
    'identification_number': 59455624,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab07cb99-c0ef-4050-a756-84a083207581',
    'identification_number': 84479175,
    'item_consumption_1': 554,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 216.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1cb1ba8-5068-4ede-b45f-3bd460971706',
    'identification_number': 68906161,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '53bcb765-8ffa-4d52-81b8-e06a795e2116',
    'identification_number': 12368105,
    'item_consumption_1': 100,
    'item_description_1': 'CDISPONIB',
    'item_total_value_1': 75.95,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '477e8b3f-ec9a-41c3-927d-74a3aced670b',
    'identification_number': 14104709,
    'item_consumption_1': 11,
    'item_description_1': 'Demanda faturada EP',
    'item_total_value_1': 207.27,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ee6d59da-822e-4b78-9158-53a0fafceb2a',
    'identification_number': 17085071,
    'item_consumption_1': 2050,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 870.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e3704d9-7364-42bb-bfec-a4db22d61cdb',
    'identification_number': 100693490,
    'item_consumption_1': 16731,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6531.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a2ed4a9-52d6-46ef-9b7f-32f2d3c17c83',
    'identification_number': 93182228,
    'item_consumption_1': 1177,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 458.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b52f0c13-0be3-4a0b-8ffb-2f2960f11a93',
    'identification_number': 109948289,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '31deab96-7fd5-44ef-84af-a9becd3ee898',
    'identification_number': 110922786,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '97691f8f-7ad0-4a7b-b742-be2d07cd0232',
    'identification_number': 43739717,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd201ae8e-fea5-4316-b189-ddf4a7a8a10a',
    'identification_number': 33651868,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b23dc6de-5be4-4d00-95af-25623d0055d2',
    'identification_number': 96150211,
    'item_consumption_1': 1484,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 628.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d494d6b-5084-406b-851d-ebbe4c78f16b',
    'identification_number': 35326263,
    'item_consumption_1': 4040,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1715.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd20acd4-dda2-41a1-8e87-ad5b8bed7dde',
    'identification_number': 58238077,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 10.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a390a03c-dca4-455a-b0a4-09db6bc82a52',
    'identification_number': 97186759,
    'item_consumption_1': 2448,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1040.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '542b7d0d-9cd9-4b73-83b3-5bfbaa190e1a',
    'identification_number': 40918637,
    'item_consumption_1': 838,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 326.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd49923d-5299-4c65-ac10-8ca109142044',
    'identification_number': 109160959,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40e0ba64-c822-4bbc-bf6a-ce321ce3413e',
    'identification_number': 12468347,
    'item_consumption_1': 3457,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1466.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3522dedc-06eb-44b5-8da0-2e1d77f1583f',
    'identification_number': 63416794,
    'item_consumption_1': 1708,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 218,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0d1edd7-697a-454c-bfa0-46bbe360410f',
    'identification_number': 26770334,
    'item_consumption_1': 3186,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1244.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '273ab057-10b3-4ea0-936c-cb3f41d0eb31',
    'identification_number': 95765840,
    'item_consumption_1': 6,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1aaedadd-d562-444d-9d8c-868ac7e61967',
    'identification_number': 31786430,
    'item_consumption_1': 2405,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 937.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '73e26747-5fb1-4cf0-bd5b-ec1eacbcd32e',
    'identification_number': 18563023,
    'item_consumption_1': 8413,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3566.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a76f811-5559-4277-958f-470c45ac0a5c',
    'identification_number': 12673994,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 62.26,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': 'f11096a9-e168-4c08-ba23-3499ba3ea55b',
    'identification_number': 44628200,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 64.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '267849cd-4bb5-491b-9fd8-3fd8e924e5ab',
    'identification_number': 40429458,
    'item_consumption_1': 1.02,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 47.79,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '72621e2b-7503-4a14-9b62-5fb4b298b012',
    'identification_number': 71236252,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.53,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '64dbc8b2-1dbd-4b7f-b2e6-5d5d0ecf57d4',
    'identification_number': 105653691,
    'item_consumption_1': 0.48,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 11.46,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '10168d7a-54c5-4146-a23f-87ca8c523f47',
    'identification_number': 75565730,
    'item_consumption_1': 89.85,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2613.36,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f5f7009a-e26e-4820-9fc2-a25ea6af4d65',
    'identification_number': 98600400,
    'item_consumption_1': 1438,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 610.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc3946f7-1807-4c0c-8764-170f74eb20ff',
    'identification_number': 40207650,
    'item_consumption_1': 162.43,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 4724.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cdd6a7c5-a7aa-4a7a-8946-fed6d10d8d19',
    'identification_number': 91481740,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8d237b9-1139-4b34-8d12-e660d55f01bb',
    'identification_number': 32595921,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.06,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b4a0bd28-7ff7-4fe9-9060-05178810de33',
    'identification_number': 34346007,
    'item_consumption_1': 35.52,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2028.55,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '53ec860e-dc39-48cd-ab0a-acd35396f264',
    'identification_number': 29984971,
    'item_consumption_1': 2010,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1173.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1f364e9-3c56-4f07-b850-b051eb869a1c',
    'identification_number': 14210215,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b6702c5-4c53-4225-83b6-4d6efd613e1b',
    'identification_number': 33676208,
    'item_consumption_1': 62.43,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1815.81,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cbed26c0-e0af-485f-89aa-700dc144c6c1',
    'identification_number': 105088730,
    'item_consumption_1': 1.26,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 30.07,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'c3603bc2-0fb1-47a5-8edb-bc9a3414d9a5',
    'identification_number': 74880462,
    'item_consumption_1': 14324,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1828.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19a18478-abbf-46c4-82c9-2c1bd7d92893',
    'identification_number': 104842334,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6aba2424-a92f-4f83-9c15-f333a713068d',
    'identification_number': 76129136,
    'item_consumption_1': 10574,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4483.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9643e7e4-2d1b-48b4-bee8-c103855bcea7',
    'identification_number': 56812477,
    'item_consumption_1': 18.03,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 524.41,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fce10220-f1e7-4745-b19f-d2bb275cebf1',
    'identification_number': 11446366,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bc55c8cb-7acd-4bee-a97c-5150dd22ad79',
    'identification_number': 21025070,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'afe64f5c-9e89-488d-8dc8-e9fc34e3ff4d',
    'identification_number': 25823604,
    'item_consumption_1': 4157,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1762.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '157a2543-dec5-4d15-a9ad-ec876900e1cc',
    'identification_number': 91224560,
    'item_consumption_1': 4822,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1883.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9963a5bc-ad24-4a1f-bc28-6d1b1b76869d',
    'identification_number': 58738428,
    'item_consumption_1': 920,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 537.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08a24a58-42be-436f-badb-0afacd4b4ad2',
    'identification_number': 110683617,
    'item_consumption_1': 507,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 198.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b944ac44-49be-421d-bc9e-18a4faf3070b',
    'identification_number': 15348075,
    'item_consumption_1': 51365,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 19115.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd8ad72a-3bd6-4dd6-8f37-ec71ee8630c4',
    'identification_number': 106999060,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 225.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6e445dd-9587-4546-a79b-13e502381ac8',
    'identification_number': 91100810,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '47b37dc5-b19f-4a26-84f2-565d56a6ee10',
    'identification_number': 46006583,
    'item_consumption_1': 0.26,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 7.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '24f3f876-1cdc-4f37-8b7d-470704918306',
    'identification_number': 104532823,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 54.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a3c66cd3-04f0-43e1-aab9-d266e04883dd',
    'identification_number': 56527144,
    'item_consumption_1': 1670,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 975.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4c34fed2-131c-486c-8025-4f5d56b7cf2d',
    'identification_number': 40254836,
    'item_consumption_1': 3294,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1286.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '87ad47da-50d9-46dd-878a-6c8e3b42a90e',
    'identification_number': 15572862,
    'item_consumption_1': 3165,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1848.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '719ee715-2dd3-402c-9ff9-2ac51707ffb4',
    'identification_number': 14104709,
    'item_consumption_1': 11,
    'item_description_1': 'Demanda faturada',
    'item_total_value_1': 521.6,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '27d73cd9-1922-4a9a-8303-5f781548f523',
    'identification_number': 32495056,
    'item_consumption_1': 45829,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5849.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3570685-42f5-40e7-938d-d6db78d138b4',
    'identification_number': 17810590,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9407f9bf-356d-4286-9f24-18f5c0849f31',
    'identification_number': 92333141,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b038c13b-c7b0-4900-9541-40de05ddc3e4',
    'identification_number': 82521310,
    'item_consumption_1': 16590,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6469.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd473ddfe-17d2-402e-a5ea-edc9ce7a3353',
    'identification_number': 5368073,
    'item_consumption_1': 225,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 87.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85dfdb38-a1bc-430f-b627-d44012aa92d7',
    'identification_number': 48339610,
    'item_consumption_1': 1.01,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 47.31,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '91c1ed52-10ae-44ea-92a4-bae64d8f9558',
    'identification_number': 40636399,
    'item_consumption_1': 11086,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1414.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8fed043-63f4-462c-aed2-ba4bda658951',
    'identification_number': 98798510,
    'item_consumption_1': 736,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1113.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21a0e3c5-59a7-47f1-b30a-e3634e162c09',
    'identification_number': 16128060,
    'item_consumption_1': 206,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 3881.68,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7e4c57e3-fac4-4226-8320-671c4a7dca6a',
    'identification_number': 109793692,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8e7a2ea6-0b67-4cdb-9a65-10e7569e87f8',
    'identification_number': 19452667,
    'item_consumption_1': 1319.04,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 75330.36,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ca1728a1-6438-4f92-a088-33352ce44c1a',
    'identification_number': 92457460,
    'item_consumption_1': 4428,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1878.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e7d688f-a509-4dbc-bcfe-c6c271da4453',
    'identification_number': 45501157,
    'item_consumption_1': 4540,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6868.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ab14261e-a03d-444a-a30c-5fb1f8180690',
    'identification_number': 60767928,
    'item_consumption_1': 27689,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10304.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bd5f507f-b280-414e-b2cd-8fb3f0d0d753',
    'identification_number': 40184188,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '36510493-ef3e-415d-8b19-f0c1ff050e28',
    'identification_number': 17401682,
    'item_consumption_1': 15.32,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 445.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'de831c46-e4d9-4433-9d29-083e3bc00dd6',
    'identification_number': 44184727,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '21d4f300-9030-48bb-9536-9316ab7586ba',
    'identification_number': 31296084,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '40a568d7-8c82-4e2e-81ff-c76365290e8c',
    'identification_number': 92763219,
    'item_consumption_1': 73.4,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2134.9,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a77e9c1a-143e-4127-810d-9c4c8f01376e',
    'identification_number': 101919638,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.86,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8fb2b064-aa91-409d-b5af-30f52dd13258',
    'identification_number': 95632867,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 126.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5c151477-9734-43d7-a99a-0aabc81b4804',
    'identification_number': 65625528,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 44.21,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aee1e862-d35c-44c5-8501-9eb5ec9ea923',
    'identification_number': 15843939,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 104.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a124adc0-e453-45a7-b401-4bfe6d2a3331',
    'identification_number': 43036830,
    'item_consumption_1': 3,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 71.56,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '34e43384-7eb8-4ced-9a9b-0b956885be03',
    'identification_number': 61251810,
    'item_consumption_1': 2542,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 991.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c4438ca-5b5c-41ff-90fe-a91e69b2621f',
    'identification_number': 98642910,
    'item_consumption_1': 14677,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6212.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60743719-92bd-49ac-80c7-a88b19fd5fdf',
    'identification_number': 111658004,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e5e13c6a-75ff-4222-8fc8-01583978e9da',
    'identification_number': 39335283,
    'item_consumption_1': 4235,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1798.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7dbf8f7b-5cb4-4779-be9d-a1a258114baa',
    'identification_number': 109993217,
    'item_consumption_1': 69.66,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2026.12,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a59bc6fc-5bf8-426f-8bd7-539aad5d1a08',
    'identification_number': 91896568,
    'item_consumption_1': 5983,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2339.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f1dc3ee7-619c-40f2-8aca-d9d768d46c3c',
    'identification_number': 103904310,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d121aad-5ca5-446b-9c53-15348f8a754d',
    'identification_number': 111991420,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e869e90-924c-4640-a32e-202c6e683304',
    'identification_number': 15184196,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.04,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4c379933-5f87-49d5-bd9a-f790973ce36b',
    'identification_number': 1205102,
    'item_consumption_1': 2466,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3730.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '905bcae4-d14f-4309-8e77-9e0f1cb2d10d',
    'identification_number': 12804401,
    'item_consumption_1': "'-1.4",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 03/2021',
    'item_total_value_1': "'-448.02",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'f90e96ee-7a9f-4b42-af0e-6d9087925a6f',
    'identification_number': 77411609,
    'item_consumption_1': 25457,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 14867.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93316c9c-cc30-4843-8a02-45f0a4ed7827',
    'identification_number': 109948289,
    'item_consumption_1': 586,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 229.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'caaa7da2-04c8-4096-b4ea-ed325229b86a',
    'identification_number': 85325872,
    'item_consumption_1': 1020,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 397.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c59e58e-37d3-43e8-8193-dcb3b6e8433f',
    'identification_number': 106013467,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.71,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'de52f23d-7f1c-4ec1-8c81-778a84b5d9c0',
    'identification_number': 63896931,
    'item_consumption_1': 10759,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1373.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28f44b98-1c30-41e3-bcfa-f5cf9101209f',
    'identification_number': 45115745,
    'item_consumption_1': 8917,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 13489.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b766a4e7-4a91-4106-8569-973976ab8afa',
    'identification_number': 32727917,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7cdd240a-d9fc-493f-84a3-efd09b004343',
    'identification_number': 109204166,
    'item_consumption_1': 1057,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 413.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd08108b7-187f-42c7-be25-9ae64b6f0f14',
    'identification_number': 67030165,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '478166c7-ba34-48b2-ac08-93fdb89d35e5',
    'identification_number': 6211275,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a306f656-49f8-48a5-bdf7-2483c02545da',
    'identification_number': 61792098,
    'item_consumption_1': 2781,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4207.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52ea0046-c5d7-4c01-bbea-b53274c9778a',
    'identification_number': 8715874,
    'item_consumption_1': 3002,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1272.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ff0f013-fd53-474d-aca0-7fd5faf1d3fd',
    'identification_number': 110480252,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c02ab35-b171-4368-a405-a45a1258f598',
    'identification_number': 99305062,
    'item_consumption_1': 618,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 261.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2f99207-f4ae-4375-a60f-e27be7ab0f94',
    'identification_number': 91804698,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1d00b112-933d-42ea-8641-a1342703b9a0',
    'identification_number': 59508345,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62a52357-fe9b-4fdc-a293-91b64dfab9a4',
    'identification_number': 3030777633,
    'item_consumption_1': 100,
    'item_description_1': 'Disp Sistema-TE NOV/23',
    'item_total_value_1': 29.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5d095978-f0c4-4d47-a5d3-627be60f4136',
    'identification_number': 28860136,
    'item_consumption_1': 871,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1317.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90bf9079-3b64-4a76-bcc9-fdac73e45198',
    'identification_number': 97259810,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '193a8add-0676-48a7-b724-5faa2bb5fb8c',
    'identification_number': 98024809,
    'item_consumption_1': 128,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 54.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78842028-0210-41bd-8355-5256813c4ade',
    'identification_number': 106382373,
    'item_consumption_1': 64130,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 23865.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae57644d-134e-4811-bd7e-b550d78c7bc4',
    'identification_number': 39482146,
    'item_consumption_1': 2392,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1016,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '30900a6e-01de-4c9e-9503-d470a21c6751',
    'identification_number': 22640061,
    'item_consumption_1': 12688,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5379.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b0c13fe-6669-48a6-ac1d-811e204456bd',
    'identification_number': 20830807,
    'item_consumption_1': 446.63,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 10652.36,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '6592c0e9-aa98-4d28-8638-d298e7c4b3cb',
    'identification_number': 110898850,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc555236-1c27-4bc0-af7c-34a7841b93ca',
    'identification_number': 59572310,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 117.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '167d2470-6330-4a43-8441-8ed47a447464',
    'identification_number': 28671139,
    'item_consumption_1': 13.18,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 314.37,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '3ba7d1f3-f063-47d1-8381-cebcfe7bddd6',
    'identification_number': 84238470,
    'item_consumption_1': 883,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 374.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b51de39b-dbca-4161-908f-7b433b8e0d1e',
    'identification_number': 33403210,
    'item_consumption_1': 5594,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2180.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41cbf276-a5d2-491e-87c5-35372d7d8497',
    'identification_number': 19452667,
    'item_consumption_1': 794245,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 295576.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6be2c032-80cf-47d2-a474-4dc760140083',
    'identification_number': 68906161,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 15.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d384f58-634a-463a-afc1-a1768946b70b',
    'identification_number': 6579051,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14f25280-02d6-4567-b363-48e540aaa05c',
    'identification_number': 65625528,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-4.97",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '353fb842-0c19-4f1f-a88a-ff08a2e3e559',
    'identification_number': 103291130,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f3b16e54-da31-4b5a-acb3-cb6b8267623a',
    'identification_number': 100186769,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0c7e6c6-f57c-403e-aeae-d86f5a6a2698',
    'identification_number': 99864029,
    'item_consumption_1': 366,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 155.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3cc4dca0-ed4d-4470-958f-12056e3f67a1',
    'identification_number': 19301340,
    'item_consumption_1': 1639,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 696.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13444dc3-f090-407e-89b3-a978bea154ce',
    'identification_number': 61283851,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 136.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '923eded8-082f-4e8f-96ec-9260e72f93ae',
    'identification_number': 70062838,
    'item_consumption_1': 6662,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2831.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6f648d1-0037-4aac-b9fb-5e2e527d685e',
    'identification_number': 19611439,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 72.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f8b75765-317a-4c28-ad7d-72150ee0588a',
    'identification_number': 42967236,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.97,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c5128fe9-5ea5-4b52-aa9d-e95786b6c27c',
    'identification_number': 111295688,
    'item_consumption_1': 444,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 173.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a49dde5-0fc6-49c0-969e-40b0ef3fa137',
    'identification_number': 98780956,
    'item_consumption_1': 10966,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4647.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28507ee8-9a9a-4fac-95d1-ec6866cf0362',
    'identification_number': 70678456,
    'item_consumption_1': 2360,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 999.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b9bbec29-3e5e-4a4e-b271-eb730d1ef802',
    'identification_number': 92159257,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.65,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8ee05edb-ed2c-454d-930f-f802588dd8ed',
    'identification_number': 13103806,
    'item_consumption_1': 2836,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 2154.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '686ea5b8-fc4d-455d-818a-636901ea1f80',
    'identification_number': 40701301,
    'item_consumption_1': 30180,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3852.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c74b7a6c-37a6-4a04-a80b-46cb70955424',
    'identification_number': 78564662,
    'item_consumption_1': 0.86,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 20.52,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '81107538-4f69-413f-82af-17a29df46706',
    'identification_number': 15091538,
    'item_consumption_1': 8548,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3329.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef4bceab-6890-412b-b50e-f2571e4804f4',
    'identification_number': 105684228,
    'item_consumption_1': 57,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 24.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '337f25cb-33a5-4c0b-a15e-620ee8d82ea4',
    'identification_number': 108357902,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '19b60095-94f8-43da-843a-7fcf4706a553',
    'identification_number': 57743770,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ac1df34-4983-4ad2-98c7-956c57d7df79',
    'identification_number': 81217846,
    'item_consumption_1': 14139,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 21389.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e66f8725-c35c-4efd-a989-0d2e5ad8877a',
    'identification_number': 16148533,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 74.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bf42b9d2-3015-46c6-88b8-f444681ef1ef',
    'identification_number': 53371127,
    'item_consumption_1': 2861,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4328.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25d8cb48-3536-4e1c-a09a-1d188e46296f',
    'identification_number': 12977489,
    'item_consumption_1': 2423,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 309.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81243374-b406-4f00-9548-26638946069a',
    'identification_number': 99786184,
    'item_consumption_1': 301013,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 112021.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eeae5d19-c265-4f69-9a7d-425feaf1b25b',
    'identification_number': 7248709,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a90cc6a2-6a18-46ab-82c7-f2301d79e567',
    'identification_number': 111295327,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0b0535a0-ea0a-4066-9791-f10603ca5cd5',
    'identification_number': 19395370,
    'item_consumption_1': 548,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 232.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c90d0aa8-2fc9-460e-a1e7-28ab05bd1c20',
    'identification_number': 11346221,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4d1e9310-18d7-4ceb-9158-66c4b05bbb7d',
    'identification_number': 83937510,
    'item_consumption_1': 561,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 218.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3cd78ea-5b16-41db-a318-8655f36ada4d',
    'identification_number': 98349821,
    'item_consumption_1': 431,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 168.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62f19ce9-b043-416d-81c1-b501fc1aaa55',
    'identification_number': 33760900,
    'item_consumption_1': 6330,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3696.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7cb3528e-70c0-4c90-8e19-e41422dcc270',
    'identification_number': 31792634,
    'item_consumption_1': 9391,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1198.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e68fd6d-17a4-4253-ab7d-330308703fea',
    'identification_number': 93977352,
    'item_consumption_1': 12423,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4858.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b916d33-1476-47a4-8b03-055a970e268f',
    'identification_number': 40994724,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 143.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6d5b37b9-abb6-4e15-a838-3f51a712a912',
    'identification_number': 18217516,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '012cfa8b-0ff4-4c0b-aec8-d0c8c6744c7d',
    'identification_number': 39261565,
    'item_consumption_1': 3328,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 5034.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea168198-3bb5-4e68-bc18-f81069e0d977',
    'identification_number': 15224287,
    'item_consumption_1': 1053,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 411.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e07e176-565a-4b36-9276-441ea6965daa',
    'identification_number': 105791130,
    'item_consumption_1': 497,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 210.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93ffd798-b0c1-46d5-9a10-cc4240b307df',
    'identification_number': 923294226,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8af7d4fc-c951-468b-811e-77b539350010',
    'identification_number': 15183319,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ac46c237-c214-4b8c-aeff-a84f216be7c4',
    'identification_number': 5329582,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a0caf0ca-de76-43f5-8100-d1b5fe62c081',
    'identification_number': 68447450,
    'item_consumption_1': 6.81,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 162.43,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9e68531d-d693-4fb0-9963-2642a330a977',
    'identification_number': 17073030,
    'item_consumption_1': 10622,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1355.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a4fccdc-7eb8-448b-9f11-8be70e03ffc5',
    'identification_number': 106041983,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 207.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd668bb1-9513-4ba7-bf7e-d76f0e18e335',
    'identification_number': 110019377,
    'item_consumption_1': 1027,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1553.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '550fbbfa-bb87-4568-9130-3dc42c47247e',
    'identification_number': 60720514,
    'item_consumption_1': 4041,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 515.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '965403ff-22d7-4c07-9120-d9c2d46399f0',
    'identification_number': 77819047,
    'item_consumption_1': 343,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 133.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b8af2c8-584b-4788-a073-ad6d1309bd84',
    'identification_number': 62191780,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 746.77,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9037a70c-72f5-4bb4-a007-53f0263e714d',
    'identification_number': 48999644,
    'item_consumption_1': 3442,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1458.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97e02bc4-3711-4ce8-9cfb-d6ffb89faed3',
    'identification_number': 14938170,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 006/014',
    'item_total_value_1': 178.29,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '795f6c09-bd72-4ba5-9d73-14e6ba40fc10',
    'identification_number': 46421939,
    'item_consumption_1': 3650,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1424.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50c33d14-587a-44f2-b4a5-06abad540a7a',
    'identification_number': 88735800,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '40292cba-baad-4bbe-98c2-d5e7e05e89d5',
    'identification_number': 58734899,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 125.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5ca42c76-dd63-418e-ad82-032a7acb6a5d',
    'identification_number': 106141864,
    'item_consumption_1': 601,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 351,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81fa2561-ecdf-42a1-9711-d389f8c526bd',
    'identification_number': 86283154,
    'item_consumption_1': 33.06,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 961.56,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0898200e-e90d-4e90-92f1-b80a1cc6ffe3',
    'identification_number': 11404639,
    'item_consumption_1': 147,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 57.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa8c5441-cc1c-41a4-9d26-b7520d8e59e6',
    'identification_number': 102734607,
    'item_consumption_1': 398,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 168.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1881731d-815c-4c36-bcde-09aa396cfcd5',
    'identification_number': 9868941,
    'item_consumption_1': 108,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 45.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dece28aa-8669-4c9f-8203-842d31fbffbc',
    'identification_number': 67678564,
    'item_consumption_1': 5302,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 676.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a7a84ad-a1f2-4de3-a076-fb45322ac073',
    'identification_number': 16121490,
    'item_consumption_1': 15,
    'item_description_1': 'En Exc Reativo na ponta',
    'item_total_value_1': 4.66,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': 'ec8a5b7c-b8ee-4734-881d-024434fd057c',
    'identification_number': 18787487,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 196.54,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd78b664a-cada-48e3-ab32-d4953e23e239',
    'identification_number': 101260890,
    'item_consumption_1': 3400,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1441.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'afee5e4b-666b-4194-9d1b-5ae33faa17b7',
    'identification_number': 96931973,
    'item_consumption_1': 2730,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1157.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8c63052-197d-4792-9aa7-b3d68b5d668f',
    'identification_number': 19005210,
    'item_consumption_1': 384,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 150.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd689f60b-7e3f-4321-af40-006d2be9edb9',
    'identification_number': 12384933,
    'item_consumption_1': 2118,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 788.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7455f4c-354e-4ed0-b318-52579ca7e14f',
    'identification_number': 69344639,
    'item_consumption_1': 6092,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2372.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b15bdab-52e4-4453-a08e-7769f9d41483',
    'identification_number': 66198666,
    'item_consumption_1': 16094,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5989.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ac4eebc-23a2-4206-b85d-c016c4c9711f',
    'identification_number': 55524567,
    'item_consumption_1': 64,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 25.02,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c7a8bbd4-4c4b-458e-990e-5f44a0ab4772',
    'identification_number': 19452179,
    'item_consumption_1': 468,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 13612.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'c3c36435-5cf5-4b8b-916e-1763aa4bbe36',
    'identification_number': 105092126,
    'item_consumption_1': 182,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 70.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5f530ed-d307-421c-b057-01d72279ac44',
    'identification_number': 45159335,
    'item_consumption_1': 1659,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 640.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71a329c3-5683-4d39-84f9-185e66f9fc22',
    'identification_number': 108646785,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-193.94",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '7fcc95cc-a06c-4b63-969f-b34964d48f36',
    'identification_number': 91394031,
    'item_consumption_1': 18,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 7.03,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '70a00fc3-5c91-442e-845f-51353a1a0e5c',
    'identification_number': 23088460,
    'item_consumption_1': 1784,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 756.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4434ae04-10ad-4d98-bdf7-fa6767ce428e',
    'identification_number': 87457091,
    'item_consumption_1': 1488,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 581.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8339f9c-e010-4f0e-8763-9ad50fbbc9e3',
    'identification_number': 103785558,
    'item_consumption_1': 18950,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2418.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74600244-b34d-4fbb-bad6-b45cb33cab27',
    'identification_number': 10214143,
    'item_consumption_1': 960,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 407.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8656732-8b55-4289-8eb4-fb7add4aa5d5',
    'identification_number': 37490109,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3e1e70d4-cb30-492d-87b9-a201441204d4',
    'identification_number': 102135541,
    'item_consumption_1': 68.91,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1643.56,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd4e39efb-b54e-46a7-946a-bd171f9faa3a',
    'identification_number': 95016066,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55aed2bc-24c4-4191-9c25-a3a5ae9a6532',
    'identification_number': 19452632,
    'item_consumption_1': 963.36,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 55017.48,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '30f50aff-ea4d-4968-b495-6522d0f86c2a',
    'identification_number': 12804908,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b435d289-be9e-4e32-bfe5-80490bc3e465',
    'identification_number': 39466787,
    'item_consumption_1': 27.4,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 653.51,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '40952efd-1d93-41ed-b435-384fc798c03b',
    'identification_number': 32519036,
    'item_consumption_1': 27580,
    'item_description_1': 'ENERGIA ELETRICA USD E PONTA',
    'item_total_value_1': 3520.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd4bbbe5-6604-4fba-8f7c-9c34793deb0d',
    'identification_number': 55316883,
    'item_consumption_1': 2073,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1210.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ac7b822-21da-4c9a-90b1-108241f729be',
    'identification_number': 14755068,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ccdb81f3-77a2-4282-9a52-f8b48c48d4ce',
    'identification_number': 95641963,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 821.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e809ba5-7891-4bff-9299-5ce339aa67c4',
    'identification_number': 62270010,
    'item_consumption_1': 295,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 115.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a154e093-cc8d-4a49-b5f9-8da20dc97965',
    'identification_number': 18559980,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 196.54,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '98d97b90-7357-4904-b286-ed3d1099f222',
    'identification_number': 76288714,
    'item_consumption_1': 700703,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 260764.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51892a19-9ca5-4e5d-98cb-6819f655dea1',
    'identification_number': 107871084,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '58d79239-00b1-4350-b6d8-b91f33983565',
    'identification_number': 14660601,
    'item_consumption_1': 3,
    'item_description_1': 'Demanda falurada EP',
    'item_total_value_1': 56.53,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ac4fb704-ddd2-434f-aa19-bff158a62a65',
    'identification_number': 67511929,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 16.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '06ada862-834f-4659-a34f-3e959e6e755d',
    'identification_number': 36648329,
    'item_consumption_1': 18700,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6959.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4470fa03-e3c1-4a9f-8ac5-a52b40f94c39',
    'identification_number': 55746560,
    'item_consumption_1': 12837,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1638.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '410c0453-cdf5-4855-8bc3-9e45fbd2bbcf',
    'identification_number': 104028190,
    'item_consumption_1': 101,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '80dc5e52-a02c-4330-b133-15d45b0a958f',
    'identification_number': 15719499,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 145.82,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7a80565f-afa1-4654-bd98-45fa03dda95a',
    'identification_number': 32046634,
    'item_consumption_1': 21844,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8129.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '221d52bd-2057-415f-9b8e-c3a186f351fc',
    'identification_number': 59479329,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-1.29",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'b9483b9a-6d44-485c-9ce0-afa581f16d32',
    'identification_number': 31846882,
    'item_consumption_1': 119558,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 44493.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b801bb71-3a76-4b51-91e8-21bebe4d7766',
    'identification_number': 104309555,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1267.85,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b27cf830-ff4d-4c28-bb10-4322bfb9271b',
    'identification_number': 31640427,
    'item_consumption_1': 8.18,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 195.11,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd14a6d43-635e-4edc-82f8-81031aa4001e',
    'identification_number': 16622383,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 86.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5679780e-5545-4a7d-ad4e-cd009b855f9b',
    'identification_number': 16649427,
    'item_consumption_1': 7702,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3261.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1beeab7a-e15a-4592-b9e2-96e6f3642be2',
    'identification_number': 14935481,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c0dc2259-9490-4df5-9112-d8437ab3a0b9',
    'identification_number': 106471228,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-21.73",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'd805072f-917e-4baf-95b4-98cd1eeb13b5',
    'identification_number': 101068484,
    'item_consumption_1': 1888,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1102.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96944f2c-e45b-4257-ae54-61bf2ddc19dd',
    'identification_number': 72777320,
    'item_consumption_1': 1694,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2562.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07db54f7-e0ad-42aa-9368-4f500c52f7ac',
    'identification_number': 4001194279,
    'item_consumption_1': 30,
    'item_description_1': 'Disp Sistema-TE NOV/23',
    'item_total_value_1': 8.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa0a6d0c-3a58-4c9f-be6f-543755a99e43',
    'identification_number': 66244013,
    'item_consumption_1': 94,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 36.77,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a5822763-ceef-465d-8e96-28995513db17',
    'identification_number': 107359278,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 22.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38bed3f3-5e5a-423b-bcb6-045a7b4366f6',
    'identification_number': 58072004,
    'item_consumption_1': 38419,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14297.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd457a8b5-e057-4976-be3c-1ed0ae6f559f',
    'identification_number': 22789023,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 63.23,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '5cb25915-9965-417c-a481-b25729c17338',
    'identification_number': 45459738,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34409ab1-7c6c-4514-87b4-92c332d74512',
    'identification_number': 35766514,
    'item_consumption_1': 4.18,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 99.71,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7dfd8db4-bc69-4750-a3f4-4be464a8820a',
    'identification_number': 19302711,
    'item_consumption_1': "'-10.368",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-3325.93",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '7f27244c-9d2f-46cc-887e-1b07bff3072b',
    'identification_number': 17023491,
    'item_consumption_1': 19495,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2488.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7d1d4e9f-198c-480a-938c-bd7655146c0e',
    'identification_number': 19451610,
    'item_consumption_1': 567.36,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 32401.91,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ee5365d4-0886-40e3-8d6d-e9758aba6515',
    'identification_number': 26343592,
    'item_consumption_1': 3903,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1656.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'afa4a358-a879-42f3-aa29-3a8f5c0be616',
    'identification_number': 108552764,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0d341386-7a30-47d2-9d81-83dcdcd28cb6',
    'identification_number': 94805199,
    'item_consumption_1': 4658,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1814.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '977ca51d-1bf2-40e9-8b5f-ba55651823ff',
    'identification_number': 8669490,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.35,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a2f74fc7-7e43-409c-878e-7d33a8e07b66',
    'identification_number': 8670277,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e97b3cdc-c376-41f0-95d4-455d856d411c',
    'identification_number': 75917050,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0b443230-c500-44e8-bb63-df781f941945',
    'identification_number': 16951662,
    'item_consumption_1': 11152,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4150.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '652ec1f8-8026-40b4-a08b-6e23c2710f49',
    'identification_number': 36715808,
    'item_consumption_1': 6330,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2473.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6384d82e-b5ed-4da6-be6c-5800cdb74f51',
    'identification_number': 12804401,
    'item_consumption_1': "'-67.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 04/2021',
    'item_total_value_1': "'-23.3",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '57f46cd2-f08f-45a8-8f7f-228e4e0601d4',
    'identification_number': 55900291,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8044c3e0-5b44-4391-a468-eacdfbd4539d',
    'identification_number': 5766346,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2521f22d-1c45-431c-ae07-0b9c095277f4',
    'identification_number': 80724906,
    'item_consumption_1': 8.56,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 497.95,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '398575f8-5451-4c89-9250-7001faecc843',
    'identification_number': 93988621,
    'item_consumption_1': 21065,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 8237.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2727fdc1-eba7-4e7c-b683-934a421cadc4',
    'identification_number': 99379422,
    'item_consumption_1': 423,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 165.5,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '855e1c81-35dd-49d3-8f26-29443c51c1f7',
    'identification_number': 103125841,
    'item_consumption_1': 2752,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1167.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd297b1b7-b9aa-47ad-976f-71d87491e4b6',
    'identification_number': 19162090,
    'item_consumption_1': 1103,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 467.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fef37664-547c-4ce2-bf7f-eeab4090a8ec',
    'identification_number': 40032060,
    'item_consumption_1': 52593,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 6712.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50292e73-66d5-4c10-a165-a1daa130d5a6',
    'identification_number': 71277552,
    'item_consumption_1': 950,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 554.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df199a79-f7f3-4623-a53b-d701153c4d4a',
    'identification_number': 11490390,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5f60a56e-bfdf-4fbc-83e0-cefd882e458c',
    'identification_number': 101195435,
    'item_consumption_1': 1343,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 523.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd9e55814-d8b9-4827-a329-03330b8783e6',
    'identification_number': 61231002,
    'item_consumption_1': 1,
    'item_description_1': 'Iluminação Pública',
    'item_total_value_1': 55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4de97e7c-ba25-425b-8c43-d9704bb5ac7f',
    'identification_number': 49314491,
    'item_consumption_1': 306,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 129.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df2a9c4f-8d41-4d77-9411-33a5a1852e7f',
    'identification_number': 50973665,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '289d6a0b-2957-4b14-b2b1-c06bc471c00d',
    'identification_number': 35183543,
    'item_consumption_1': 3.86,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 92.07,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '12a85e99-62b5-43fb-80d3-af8ba2dfda74',
    'identification_number': 63006472,
    'item_consumption_1': 4639,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1969.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd87f06e8-e9dc-494c-b604-d2f9daeb0a74',
    'identification_number': 60543051,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b7987a9-c769-44bd-9ced-f5ad8f16ab40',
    'identification_number': 13935739,
    'item_consumption_1': 2420,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1413.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61a0ff14-7ec5-4b42-8ac4-c6c04aff6125',
    'identification_number': 105644366,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fcbf99b2-4df0-4924-9277-a7e64ce240ed',
    'identification_number': 35183543,
    'item_consumption_1': 24663,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3147.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e65562ba-1cab-46eb-892a-855c34389b40',
    'identification_number': 59866969,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 61.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '139737d5-47ad-4d37-b353-bf4c58085587',
    'identification_number': 16121490,
    'item_consumption_1': 161,
    'item_description_1': 'En Exc Reativo fora de ponta',
    'item_total_value_1': 50.03,
    'reference_month': '11/2023',
    'referente': 'Energia Reativa Excedente Fora Ponta',
  },
  {
    'uuid_items': '55c95632-758a-43fd-9d8b-af6c35f661ac',
    'identification_number': 8084661,
    'item_consumption_1': 14,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 5.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f8093d4f-728b-45d1-b1d2-bc9a0d1c54e5',
    'identification_number': 44001479,
    'item_consumption_1': 2.4,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 57.26,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b797a0f4-9575-4b49-8586-ba1f1cc87d04',
    'identification_number': 37423258,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 34.73,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3b701280-5c43-4c96-a584-c66a12e82993',
    'identification_number': 20168225,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7de150c2-9f6a-42a6-8be5-f7a274a0988a',
    'identification_number': 79513212,
    'item_consumption_1': 1609,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 627,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c3962d9-acc9-4571-b365-5a2a10fab6a3',
    'identification_number': 79702791,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1024a1f8-bd15-49b0-a086-ae3f2845f067',
    'identification_number': 41418417,
    'item_consumption_1': 20491,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2615.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb693550-170a-4bfd-9330-950bcc3db745',
    'identification_number': 108298035,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3af29343-cedb-46b1-bcf5-da7771647368',
    'identification_number': 84599391,
    'item_consumption_1': 40.68,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1183.2,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '76c019f5-6afe-4a17-b9be-7759c9637ac3',
    'identification_number': 93003064,
    'item_consumption_1': 8912,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3471.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2968e488-924f-4eeb-bcc9-370e1cf277a1',
    'identification_number': 12673650,
    'item_consumption_1': 41594,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15479.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '646bc4ba-1e2f-4ee8-8633-779b4ab41649',
    'identification_number': 103152253,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '25b4e824-0a63-48ed-a4fb-99b64228e8f7',
    'identification_number': 16973720,
    'item_consumption_1': 3833,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1627.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6afd451c-81f8-407e-9e7e-1ef1936bff99',
    'identification_number': 77791428,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49ac5af8-a6d3-46b1-8d9a-01433a019c43',
    'identification_number': 102362378,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'aa953ad2-dbdb-4657-b71e-93553ae959a6',
    'identification_number': 59479329,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6cdabf8-2854-4d42-a669-ed0547a06dde',
    'identification_number': 88789586,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b781b81-29d9-4fa7-bcf1-48198fc74bbd',
    'identification_number': 108298035,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 18222.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5b5aea86-f70c-415f-a841-49e61f37558e',
    'identification_number': 98041371,
    'item_consumption_1': 1411,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 824.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9816d918-5f95-4829-b696-482c3fef976f',
    'identification_number': 17777852,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c030e678-d86e-4711-8d16-6cf0df8d4241',
    'identification_number': 105799653,
    'item_consumption_1': 13825,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5144.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fdb18e9-a82e-44e6-a0a3-abef7ecc2cea',
    'identification_number': 18616917,
    'item_consumption_1': 47383,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 17633.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8e445787-9cb6-410a-b616-2e5948add073',
    'identification_number': 17900514,
    'item_consumption_1': 791,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 335.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '657d80af-3924-4aff-bbf7-3a139aa4d124',
    'identification_number': 8918074,
    'item_consumption_1': 1486,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 580.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a3abc48-0641-4957-9a14-0a0ddc21c5df',
    'identification_number': 12393916,
    'item_consumption_1': 4032,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1577.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd02beecc-876f-45e2-b9b1-3a897f009c0c',
    'identification_number': 110122160,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8bc6959a-51ec-4aed-996a-37d90ac52025',
    'identification_number': 67513212,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3748107a-b7fd-4c70-82a1-9dedf8e64cc4',
    'identification_number': 91781485,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '413f61b7-8ef6-4400-a719-dd2db5bf7bb5',
    'identification_number': 948748206,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 10.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '044e324f-6baf-4aa6-ac32-dfc27c91906d',
    'identification_number': 95962239,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 100.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '15b0aadc-94c9-4816-b837-26be8d1409ca',
    'identification_number': 99959739,
    'item_consumption_1': 42201,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5386.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96b5cc31-fcb4-405f-8b94-265a84e698ff',
    'identification_number': 96305770,
    'item_consumption_1': 490,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 191.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2e61a87-aab9-474d-9f1f-159654ebdbb0',
    'identification_number': 70062838,
    'item_consumption_1': 362,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 141.54,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a15a0ffb-a9c2-46a0-bad3-f3c616cde795',
    'identification_number': 98598945,
    'item_consumption_1': 17101,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2182.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '042d4a81-1f72-4b9c-b471-a79375d853ef',
    'identification_number': 44219393,
    'item_consumption_1': 7268,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 927.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3887002a-3eb9-46d0-b24d-8a840ebcea62',
    'identification_number': 17073030,
    'item_consumption_1': 10622,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3952.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae9f112a-e6b5-40b3-bf0b-88a40a8b8220',
    'identification_number': 110519132,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65542f87-fabf-4d0f-b0ba-5530f8e0aef4',
    'identification_number': 59440589,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '307cdd98-2c9b-4cdc-a20e-c66cd1d94057',
    'identification_number': 15688976,
    'item_consumption_1': 3450,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1465.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8866be2-6753-41a8-a5b6-da08ccd75275',
    'identification_number': 15183319,
    'item_consumption_1': 734,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 286.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83fabaf5-a7f3-487a-a1b6-d28f2b3dad00',
    'identification_number': 14935481,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 202.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cac775ed-30a8-4df5-9d37-6eb6d7b97222',
    'identification_number': 39964973,
    'item_consumption_1': 45680,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16999.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a40fb3d-ce9e-4eca-805c-caccc636e537',
    'identification_number': 56886314,
    'item_consumption_1': 51,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 19.94,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'acf62fd0-0214-4f7c-a062-91c52fab3e11',
    'identification_number': 19310609,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-22.64",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '85650d6b-dfec-4fa6-bc91-fda3851ecc06',
    'identification_number': 79007651,
    'item_consumption_1': 3295,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1268.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23ed1c69-0f28-4596-8a74-773c2f487fce',
    'identification_number': 36525405,
    'item_consumption_1': 376,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 147.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5bff9a4c-76df-48a7-97dc-27603ce371c4',
    'identification_number': 91417953,
    'item_consumption_1': 1049,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 409.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40403b0d-c2f4-4dd8-b982-c3980a13ee67',
    'identification_number': 65625706,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2b13b134-bccd-4d82-9aef-1667face3ecb',
    'identification_number': 75789981,
    'item_consumption_1': 35738,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13299.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '221e27c2-1522-4c3f-a7c4-7e8bfb34370d',
    'identification_number': 29745918,
    'item_consumption_1': 25,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dab2cade-8a2b-48ac-94f2-1fda16c00d47',
    'identification_number': 79730701,
    'item_consumption_1': 5122,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7748.65,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0481be5-26ad-4658-baac-1d365d650197',
    'identification_number': 106382373,
    'item_consumption_1': 354.34,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 8451.18,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '16e9fd23-63eb-41f6-a6cb-d81037e6bb23',
    'identification_number': 15102009,
    'item_consumption_1': 1044,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 442.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd30add04-efa8-4179-ba7f-a33d3aa586b3',
    'identification_number': 100627188,
    'item_consumption_1': 56,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 21.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'abec3154-131a-4407-9bf3-e242ba82cc68',
    'identification_number': 43739458,
    'item_consumption_1': 8548,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3330.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f292c914-64c0-4cd9-9fda-e3c43e9155c4',
    'identification_number': 94623996,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ddb33ee-b2a5-42b0-9707-da07eab2ff07',
    'identification_number': 109418468,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7058569e-0cf8-4c84-a26a-31cd18733047',
    'identification_number': 4504925,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c6911dc-4fd8-431c-9719-384729545657',
    'identification_number': 94042659,
    'item_consumption_1': 3517,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1490.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a9a1fc6-c232-4721-a2b8-4d5726de0883',
    'identification_number': 923309560,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3580a284-9990-4e34-b760-d47ac0ef260d',
    'identification_number': 12721069,
    'item_consumption_1': 1922,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 814.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ea32e71-105c-48f6-9361-a21fd7d5f120',
    'identification_number': 109034333,
    'item_consumption_1': 384,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 580.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6166f751-d037-4120-b067-d0a83c65d605',
    'identification_number': 42264316,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b11e4c2-05c5-4457-8ad4-a102f2783547',
    'identification_number': 107996448,
    'item_consumption_1': 146,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86efd586-7aa7-4867-890c-8a62e7368065',
    'identification_number': 18916996,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.41,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd39ae39a-17e5-43fc-a259-b487bc6d0432',
    'identification_number': 28166159,
    'item_consumption_1': 127.37,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3704.67,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a2c91192-38b9-4457-a1e8-76e9538a8530',
    'identification_number': 95009914,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1529d554-7679-4bdd-9329-c1b3e1929f44',
    'identification_number': 105658430,
    'item_consumption_1': 2188,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 927.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4df8fb84-ce3e-4a4a-b032-fc58947c1feb',
    'identification_number': 19873727,
    'item_consumption_1': 6.64,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 310.96,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '56458858-7331-4599-886d-3476cb689698',
    'identification_number': 55316891,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'df74cc75-bc10-4bf0-8d9f-c20263a16039',
    'identification_number': 96294345,
    'item_consumption_1': 3991,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1558.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6032be0-774b-4ef5-91b7-3aefce56c45b',
    'identification_number': 78084113,
    'item_consumption_1': 3.05,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 177.41,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '780be630-c981-438e-b12f-3064e7471a6f',
    'identification_number': 8749116,
    'item_consumption_1': 371,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 157.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f2f6317-31c6-43d4-b30b-bb20a87b7709',
    'identification_number': 95067590,
    'item_consumption_1': 2079,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 880.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '45c3b463-7c98-49ba-9391-c64983df6738',
    'identification_number': 105957569,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 225.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41fc56a0-2933-41c3-b7e2-c04668a3c582',
    'identification_number': 90990684,
    'item_consumption_1': 2829,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1105.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7c5e9318-46a7-4e5e-84ae-d46970a4610f',
    'identification_number': 47198850,
    'item_consumption_1': 7909,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3094.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0efc8bb-1f3b-440a-ab2f-13f2d17f1f28',
    'identification_number': 79513212,
    'item_consumption_1': 1609,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 681.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36eaf0f8-17e8-44fe-8823-53c631785639',
    'identification_number': 109089553,
    'item_consumption_1': 103,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 43.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad74e019-0ae8-4b1b-a6e0-0910b79b4ef5',
    'identification_number': 39152537,
    'item_consumption_1': 5693,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2417.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4758b3a-1152-44f0-afe8-f3d1f4fe6c4a',
    'identification_number': 84969466,
    'item_consumption_1': 6672,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 851.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ede09baa-9035-4671-83d4-49beafb9b5bb',
    'identification_number': 107238195,
    'item_consumption_1': 1489,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 630.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6455e2cc-62b6-47ed-90ea-8f005f13ccb1',
    'identification_number': 102512426,
    'item_consumption_1': 11.65,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 277.87,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3615777e-094b-4155-9038-b37b96535ab4',
    'identification_number': 15685420,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bdbd0ea3-f628-4d3a-a246-274736b19ffe',
    'identification_number': 101312660,
    'item_consumption_1': 2341,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 993.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '816436d2-8a7b-4072-b28b-65e70d5f2eab',
    'identification_number': 91064058,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6c8e89a9-9e15-4202-a13c-f93eb2b7a996',
    'identification_number': 91075700,
    'item_consumption_1': 4586,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6937.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb55a61b-6f6d-42de-a8f2-f1c52b822da7',
    'identification_number': 101533900,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd70faf6-9abf-4162-8e51-8b3224297f98',
    'identification_number': 104345136,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'aad1bac6-53f4-4a4e-afc0-ea64873787cf',
    'identification_number': 91168988,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-339.6",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'a5120fdb-b4c9-4cc6-99e2-cd1ac0d47e7d',
    'identification_number': 100424198,
    'item_consumption_1': 530,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 224.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f46fae8c-883b-4413-9d99-185e94343fb5',
    'identification_number': 104517905,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72a80c0c-91d0-4160-b80c-cfbfe7af99f4',
    'identification_number': 56706499,
    'item_consumption_1': 639,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 373.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c2fcc2b5-4f1d-4619-b6d2-02e8ee0bc6c7',
    'identification_number': 45853290,
    'item_consumption_1': 1102,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 466.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '912c2930-f45f-437f-8df9-f09cf8cc30a0',
    'identification_number': 6941044,
    'item_consumption_1': "'-450.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-156.8",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '44da9d67-e9f4-42ff-b234-b9700bb9395a',
    'identification_number': 109635914,
    'item_consumption_1': 18439,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6862.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11db309a-80c9-4b0c-a9c1-c6e22b9849e6',
    'identification_number': 80350844,
    'item_consumption_1': 3.02,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 72.03,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '889a8776-a81e-43dc-8c43-23139048d4bd',
    'identification_number': 102302561,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e026706f-f8d9-495f-b3e3-8e8be2b0eaef',
    'identification_number': 23074221,
    'item_consumption_1': 72,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 30.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a22eb85a-49b1-4ce6-bdbc-011a73dd5c0f',
    'identification_number': 108098680,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 85.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '58be407f-403b-4243-90ec-b4b14fa633cd',
    'identification_number': 38635518,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6202fda2-d891-4ab4-b527-30c7176d255b',
    'identification_number': 40432980,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-1136.02",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '0ea78cf1-dfb7-4bc8-b14f-616056c66270',
    'identification_number': 105291242,
    'item_consumption_1': 339,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 132.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f361d683-7dfd-40cc-8632-f94a1846ff5a',
    'identification_number': 84702893,
    'item_consumption_1': 3638,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 464.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cbc2926-322f-4d64-8dc9-4b94bf37126c',
    'identification_number': 35685948,
    'item_consumption_1': 57,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 24.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66474d72-f239-43e7-b015-c2f215e1388b',
    'identification_number': 91515386,
    'item_consumption_1': 2114,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 897.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64c603f3-8cdf-4e92-9c37-9e984f0e4150',
    'identification_number': 94193053,
    'item_consumption_1': 3033,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1184.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '299b4986-001d-4cf9-97a5-43401c0c0bea',
    'identification_number': 40184188,
    'item_consumption_1': 1913,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1117.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06e9d4fe-7d02-4bc9-af01-f7a2185e3397',
    'identification_number': 94516014,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c98c3db8-180e-49f9-b762-198ffda75a40',
    'identification_number': 31737960,
    'item_consumption_1': 3920,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1526.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c338306d-1dc1-45c2-a61a-416381042c8d',
    'identification_number': 105906280,
    'item_consumption_1': 6845,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2675.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a9db7a5-c021-4f03-a75b-adea1311142e',
    'identification_number': 59866969,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '12bdb16b-3704-463d-825b-fc665a256ae8',
    'identification_number': 16307836,
    'item_consumption_1': 48.11,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1399.31,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ac94debc-d777-4fa0-98f3-a9165a6412d1',
    'identification_number': 19453302,
    'item_consumption_1': 1004136,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 373686.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76dfbb4d-95b7-4a09-8811-f7e0b8d467f3',
    'identification_number': 58737863,
    'item_consumption_1': 1737,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1014.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '521ca98d-2043-476a-971d-f2a406dedbfe',
    'identification_number': 8661758,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6a333d6c-de76-4f98-a2a2-659be82013f9',
    'identification_number': 32191871,
    'item_consumption_1': 11509,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1468.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5acfb8a-204d-42ec-8398-b5e468757ea9',
    'identification_number': 79969135,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f54c042d-ac1c-443d-ae86-5a832d465ee2',
    'identification_number': 88788369,
    'item_consumption_1': 1650,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 644.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb76608a-f24b-46f0-addd-c6950a5af37a',
    'identification_number': 99353598,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f7a0f011-2b75-42d7-8db4-60d1b2695d38',
    'identification_number': 28860136,
    'item_consumption_1': 7321,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 934.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10cd0ba6-7af3-4101-96e6-5367d730261e',
    'identification_number': 20384939,
    'item_consumption_1': 985,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 385.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f0ef9ccc-2c37-459a-8694-481de19a8eec',
    'identification_number': 61583758,
    'item_consumption_1': 523,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 791.2,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da148bab-18fe-4454-8033-d11b008f9e34',
    'identification_number': 69607214,
    'item_consumption_1': 4571,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1938.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47ff869b-7074-4f06-92fa-9991f5bc80e8',
    'identification_number': 100728294,
    'item_consumption_1': 716,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 279.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '71c89e95-c011-4688-8413-a6cb971d7f14',
    'identification_number': 12973092,
    'item_consumption_1': 343,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 133.76,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1d344c7-4292-43e4-964f-19d498d40167',
    'identification_number': 102483175,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 96.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8c1ae52e-c050-4ed7-90d6-1e6b433f7406',
    'identification_number': 99959739,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 120,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2547b72f-ced3-4acc-b034-e8fb8a1fe811',
    'identification_number': 30180392,
    'item_consumption_1': 1941,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 823.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f55086d-5b41-403a-9dbb-b30fd67261f9',
    'identification_number': 12640255,
    'item_consumption_1': 1367,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 533.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d67fae0-9aef-43b0-a3d4-d8e6d0cc1b85',
    'identification_number': 58746005,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15b3c1a8-5d6a-4588-bba5-30f10e609115',
    'identification_number': 82744181,
    'item_consumption_1': 8829,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3285.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e32aa07-d208-4bba-81a9-7ab4881c659a',
    'identification_number': 98193210,
    'item_consumption_1': 1.23,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 29.35,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '82bfd764-0481-4bf8-9518-c9f8b9a133ad',
    'identification_number': 98150936,
    'item_consumption_1': 336,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 142.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5af68e76-11de-4bd0-8540-f38b6b8079cc',
    'identification_number': 30102901,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e8e58358-bde9-48b5-ab7f-13e2309ed8df',
    'identification_number': 58739149,
    'item_consumption_1': 199,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 77.85,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9cede1d9-27d2-4bc0-bb40-da359194a09e',
    'identification_number': 96427361,
    'item_consumption_1': 161,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 62.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4da35df8-86d9-4111-966b-72ddb3e47d80',
    'identification_number': 95920625,
    'item_consumption_1': 271,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 105.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8190fad-ad97-4511-81d8-2bf21a49e675',
    'identification_number': 108991679,
    'item_consumption_1': 124,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 52.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ab85bcc-e0f4-44f7-9df2-69ed2f690106',
    'identification_number': 37267051,
    'item_consumption_1': 1998,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 780.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b42cb524-8e1a-4f46-846e-af340a90e4ba',
    'identification_number': 31645313,
    'item_consumption_1': 1,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 46.84,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '17fb7448-dbc4-43e7-b971-7166db65ed3a',
    'identification_number': 9790527,
    'item_consumption_1': 1397,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 544.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9eaa1825-3bf4-47dd-a329-b24645a0b614',
    'identification_number': 101472790,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4d085ab3-e636-4910-9319-f314a9327d28',
    'identification_number': 14138034,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '817e4f2f-0889-429d-aa22-2109a513cb9c',
    'identification_number': 57029911,
    'item_consumption_1': 37940,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4842.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6393dda3-8f8e-430e-ad04-6d8f203fdade',
    'identification_number': 109044002,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '715b2502-efc0-4ec4-87e3-29295981e372',
    'identification_number': 106729985,
    'item_consumption_1': 15,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 22.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '380e6671-d90e-49ab-8e65-ed074b701052',
    'identification_number': 44436637,
    'item_consumption_1': 38742,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4944.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a7ff7b8-b775-4dce-bc84-efa02c45cf9d',
    'identification_number': 100856349,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5a1b6647-bfb1-4a21-be2c-39ae39dcae15',
    'identification_number': 28858913,
    'item_consumption_1': 172.8,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 5026.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'afdf3a3b-12f0-4164-bc49-21f309b44c24',
    'identification_number': 13845551,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '914a39ba-d611-45e1-aa7a-90666d0ccb15',
    'identification_number': 46577173,
    'item_consumption_1': 615,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 359.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07a37394-af7b-4382-975b-dcb8fd276555',
    'identification_number': 111295408,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2d4d3f82-3c2f-43f2-a69f-8347779f56d8',
    'identification_number': 19306679,
    'item_consumption_1': 1250,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 488.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4edd8de4-d54c-4012-b112-d44e2e9944d2',
    'identification_number': 33119635,
    'item_consumption_1': 40,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6610830d-af02-4c88-9ff4-59dd0e867264',
    'identification_number': 68749899,
    'item_consumption_1': 688,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 268.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8c615a0-dad5-49a1-9bfa-e72824022a95',
    'identification_number': 70427054,
    'item_consumption_1': 69035,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 25691.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12219f3a-fbf5-481b-b7a8-4cf737064669',
    'identification_number': 103496718,
    'item_consumption_1': 2601,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1105.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a3bff43-f4af-4ab5-bc4c-9f2587850a7d',
    'identification_number': 923309560,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANCAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a42efb2f-86b0-4f96-a10e-0672496f00a7',
    'identification_number': 100627188,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '72859a29-ffae-43d5-af10-6d440fff157a',
    'identification_number': 81500513,
    'item_consumption_1': 1310,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 765.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aad680d9-48f0-46da-9a58-80dddc190306',
    'identification_number': 80642322,
    'item_consumption_1': 7257,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3072.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96fa3e22-90c2-4b0d-b75f-260176ac4962',
    'identification_number': 5721865,
    'item_consumption_1': 1039,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 439.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21f029c7-b7b1-4424-b6a1-790d14d067e5',
    'identification_number': 18229077,
    'item_consumption_1': 88.41,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2571.47,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '98e1f0da-0192-4b3b-9ae9-6bfccb826034',
    'identification_number': 14389754,
    'item_consumption_1': 11933,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1523.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38cbf984-e0f2-44d2-9daf-61e43909d11d',
    'identification_number': 20176228,
    'item_consumption_1': 2218,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 941.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85d7b7aa-acc1-4e83-b03a-36bd6f8c0008',
    'identification_number': 91168988,
    'item_consumption_1': 4329,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1688.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ecb589ae-7aa2-476a-812a-a9cdeeb60c78',
    'identification_number': 15048861,
    'item_consumption_1': 903,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 353.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc11b686-a57a-4093-a218-bf2665e1b90d',
    'identification_number': 19452306,
    'item_consumption_1': 17.8,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 424.55,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b892e5f3-d5ee-44ef-a446-5d1eea1f2a8d',
    'identification_number': 92405967,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e8b180ac-4661-448c-a754-3c7eb99ae7a7',
    'identification_number': 68376391,
    'item_consumption_1': 21585,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2755.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4280d108-fcf3-4406-b1bf-e9d257547853',
    'identification_number': 59044659,
    'item_consumption_1': 722,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 421.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b5e4376-7011-4e6c-8ffe-897697c62645',
    'identification_number': 85872067,
    'item_consumption_1': 2510,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 979.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67d55930-24ff-4241-84aa-8a68493fb599',
    'identification_number': 19451512,
    'item_consumption_1': 582043,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 74289.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96e6c878-8049-44db-820d-cdb8bba5f15d',
    'identification_number': 14859424,
    'item_consumption_1': 1323,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 772.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ab0b9d9-9658-4052-ad0c-16878d9e0da9',
    'identification_number': 106104438,
    'item_consumption_1': 8341,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3104.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '864b144c-c237-4f17-b5e4-51a0c12e9a38',
    'identification_number': 103727434,
    'item_consumption_1': 2636,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1029.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ade0e047-b1ab-4c82-8ba4-bd138227d2e3',
    'identification_number': 74311875,
    'item_consumption_1': 68.09,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1980.45,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '529790c3-4b11-4395-9f97-470f49855c40',
    'identification_number': 91896568,
    'item_consumption_1': 5983,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2542.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a4649415-2366-4bce-9db3-5d2ab2750a2c',
    'identification_number': 9063005,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f07f784-c35f-43e7-b8c8-bd99d4ba9bb0',
    'identification_number': 96393505,
    'item_consumption_1': 12384,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5252.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f58f57dd-6215-4748-8705-24c24ac06403',
    'identification_number': 17578612,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 106.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1dc88dc7-2999-48b2-b927-76991fbf45ad',
    'identification_number': 108106128,
    'item_consumption_1': 168,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 21.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8012082c-37e7-4770-93f1-82851c8d33fc',
    'identification_number': 10977007,
    'item_consumption_1': 1,
    'item_description_1': 'Iluminação Pública',
    'item_total_value_1': 55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '25642ca2-c366-4a53-a252-ec5df0672e8a',
    'identification_number': 51632381,
    'item_consumption_1': 2480,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1448.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1f782d3-31c0-4ff5-8a85-bf78d2d9ec3c',
    'identification_number': 103120114,
    'item_consumption_1': 60,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 25.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2adeed28-01c4-4fc6-a50d-582612a897fd',
    'identification_number': 108565726,
    'item_consumption_1': 3.93,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 93.75,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '320dd954-6fd1-4c5c-bc82-34d857e5f859',
    'identification_number': 105798908,
    'item_consumption_1': 35.12,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1021.48,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6aa3cd4b-74af-4b49-9717-06ffa8e4535f',
    'identification_number': 47023090,
    'item_consumption_1': 12.33,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 294.09,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '27951ddd-142a-4898-9052-18e4c0dcdf66',
    'identification_number': 103363165,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '091e1257-708c-4d33-93f7-038d682ee4bf',
    'identification_number': 46021191,
    'item_consumption_1': 660,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 258.23,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '41bd97be-2c02-4870-b27b-010195db4635',
    'identification_number': 56527144,
    'item_consumption_1': 7.94,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 371.86,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '8d2ee08d-849d-4f85-9d35-032913d04809',
    'identification_number': 48347728,
    'item_consumption_1': 1712,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 727.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1ee1004a-9da7-4f0d-80c7-10587c22b666',
    'identification_number': 14333910,
    'item_consumption_1': 5898,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 752.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea78bf0e-4f49-41ba-9ffb-bfdf52f63588',
    'identification_number': 58739149,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38efd5be-043a-4432-b44d-bfb97750adf9',
    'identification_number': 44443498,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '11bc9406-c44a-42d7-b65d-5615f2934a73',
    'identification_number': 93511388,
    'item_consumption_1': 10734,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1370.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '160235f5-25ba-4e3b-8195-abc3cb31e49c',
    'identification_number': 12640255,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 69.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'db96b1f7-6451-4d8b-ac3d-06c120a9bc87',
    'identification_number': 31789366,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1239.04,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f3ca3a7a-0151-4060-9156-37253742d240',
    'identification_number': 15830543,
    'item_consumption_1': 2780,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1087.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bdd99cb7-3987-4846-9b5a-94a65968ca8f',
    'identification_number': 106605712,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9a94b496-209b-42fb-9f30-b199b3180723',
    'identification_number': 6037291,
    'item_consumption_1': 1654,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 645.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81ed0a05-0797-4baf-9c8c-a78bfd13bf08',
    'identification_number': 77405340,
    'item_consumption_1': 11004,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 6426.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa045d29-a0cb-48cb-a7ea-d20ab74912da',
    'identification_number': 92672051,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fefc274d-850c-4cc5-8ef0-326ba3d30c70',
    'identification_number': 94384126,
    'item_consumption_1': 23072,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 9796.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b21441f9-408d-49ce-8d49-9c51492c0d87',
    'identification_number': 19281552,
    'item_consumption_1': 417,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 162.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb0db006-d8bf-4ae5-a319-e44a81b8dfeb',
    'identification_number': 104722991,
    'item_consumption_1': 552,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 234.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7a815493-2792-4f03-b4cb-5fcc5799255d',
    'identification_number': 93418175,
    'item_consumption_1': 773,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 301.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '868fac32-c6f5-41d2-976b-0cca3e2f7a2a',
    'identification_number': 18267211,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '631caae0-bc03-40d8-abe1-15ef668335a5',
    'identification_number': 48482897,
    'item_consumption_1': 2229,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1301.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7fc2d06-b23a-400a-b46c-d12101b6ffea',
    'identification_number': 85661236,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1dbfc44d-f50c-45e7-b62e-2444c5dd8f36',
    'identification_number': 27578801,
    'item_consumption_1': 2624,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1023.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ac41110-771d-4994-b75f-45c727fea5cd',
    'identification_number': 111978050,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61f2640a-1f19-48dd-a143-59a859206233',
    'identification_number': 98546627,
    'item_consumption_1': 468,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 198.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c66b8206-4a8c-4d85-ac04-d84548dfda50',
    'identification_number': 70941378,
    'item_consumption_1': 6643,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 847.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74075eaa-3f4d-411b-afbd-2f8634973daf',
    'identification_number': 34810986,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '736efdb0-fdc9-415d-9867-83fadc663c46',
    'identification_number': 111097410,
    'item_consumption_1': 1111,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 471.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '14250000-0db5-4ac5-a819-3874eb3a2576',
    'identification_number': 106731971,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4fe1eb92-7dd8-4126-a571-75ad9b394327',
    'identification_number': 14981556,
    'item_consumption_1': 87,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 36.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e76828b0-dc8a-40df-9e4a-1ddcd3711333',
    'identification_number': 19612516,
    'item_consumption_1': 630,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 246.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c21c57dd-13df-4103-baeb-70d2f575f7c8',
    'identification_number': 24017353,
    'item_consumption_1': 10515,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3913.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77b11f00-0a41-4d37-9283-8346fda00876',
    'identification_number': 97464333,
    'item_consumption_1': 1752,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 685.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0d2347a-bd63-4da3-a2dc-3678e5562253',
    'identification_number': 5351030,
    'item_consumption_1': 1350,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 527.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78f8a88e-5fa0-4590-93d4-ccd39353194a',
    'identification_number': 18456669,
    'item_consumption_1': 17.85,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 519.18,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bf5cce91-f6f8-4084-9d9e-8e11ea865f4b',
    'identification_number': 105042536,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ade0ab8e-ae34-4db8-9bd3-397001d0f663',
    'identification_number': 102279683,
    'item_consumption_1': 203,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 79.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5149f275-2f89-4c82-8e34-fe1b59857e82',
    'identification_number': 46920617,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b4ce6d6f-7959-474f-a664-cf006c2e26bf',
    'identification_number': 32153430,
    'item_consumption_1': 14187,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1810.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67e62b27-d7a3-4459-806a-c0029a4a1a73',
    'identification_number': 76778606,
    'item_consumption_1': 1578,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 617,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '590a71a6-8bdb-4065-b56f-2ce3bee499a9',
    'identification_number': 22935940,
    'item_consumption_1': 1957,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1142.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '07168cea-c84c-44ba-9ef2-942df63c4ee7',
    'identification_number': 57743770,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd186a76b-9454-4d58-b91b-174e2418f18e',
    'identification_number': 56194471,
    'item_consumption_1': 485,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 205.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '193e231e-6a80-4bbd-a9fa-8ba768d20cb9',
    'identification_number': 44567898,
    'item_consumption_1': 123,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 48.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c62a6a15-54eb-4574-919a-9c2c1f1eb75a',
    'identification_number': 94029539,
    'item_consumption_1': 3206,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4850.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a240b6c2-c2c8-4d07-9fe5-fbeade410a97',
    'identification_number': 83687114,
    'item_consumption_1': 27.67,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 659.95,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '511370d4-5e70-4349-8b48-2b7280eb2a3f',
    'identification_number': 46395040,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ac0e0bd8-b75f-46a5-8c7b-9acb1dc969e9',
    'identification_number': 101093497,
    'item_consumption_1': 9759,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4133.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a7ff4df4-847a-44f6-bfec-9beea8740825',
    'identification_number': 56527144,
    'item_consumption_1': 31,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 12.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ab37f234-21e0-4c52-84fc-b50b06d6615c',
    'identification_number': 21817405,
    'item_consumption_1': 69114,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 25720.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a7d216f-bbee-48af-b11a-a5d0fdd4fa6d',
    'identification_number': 41490533,
    'item_consumption_1': 230630,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 85828.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c341e319-6fb1-44fe-a707-4d7a28eff06c',
    'identification_number': 26142961,
    'item_consumption_1': 4664,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1977.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0ed5551-5399-4fa8-97a9-df407e5a1391',
    'identification_number': 73549223,
    'item_consumption_1': 1566,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 663.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4eeb8880-5690-44e3-bf78-0addeb2a47a8',
    'identification_number': 109086678,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 43.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '656234ca-1a62-4594-82f4-c09551101f25',
    'identification_number': 67536913,
    'item_consumption_1': 21762,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8098.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d074d4c-245b-4cd4-9867-17922cacad0a',
    'identification_number': 17954509,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3356e458-dd86-44d9-aaed-1af5eacca4f4',
    'identification_number': 26925630,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03311391-5ea4-425c-b977-aedc0b085b18',
    'identification_number': 14822725,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '91b47bb5-e309-479a-93d0-b20e05471022',
    'identification_number': 71433449,
    'item_consumption_1': 6284,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2452.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2518a600-4e7a-42cf-9cfd-7f8b906f7396',
    'identification_number': 11013958,
    'item_consumption_1': 1.88,
    'item_description_1': 'DEMANDA REAT EXCED USD PONTA',
    'item_total_value_1': 54.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'c19f12e1-e54e-4f02-a3c2-02f10e3b2eaf',
    'identification_number': 97425907,
    'item_consumption_1': 279,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 422.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37a9ec95-4d09-46c1-8416-41033712fe45',
    'identification_number': 40207285,
    'item_consumption_1': 150.84,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 8614.46,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0c7298e7-4236-4d33-b05f-43b323337b6d',
    'identification_number': 86398652,
    'item_consumption_1': 4032,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6099.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7675b375-1fd4-4da0-bd18-7af770598583',
    'identification_number': 9788131,
    'item_consumption_1': 28164,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3594.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70870059-80b4-47e0-9b65-4016c4ae1d9c',
    'identification_number': 19119852,
    'item_consumption_1': 3340,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1304.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44425e23-5298-498a-ba1f-524355de6501',
    'identification_number': 20476183,
    'item_consumption_1': 7937,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3367.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40c1b175-f077-4d83-ac06-4792f16a6a7f',
    'identification_number': 20830807,
    'item_consumption_1': 10994,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 16631.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61b9126a-196e-4660-8dc1-8899f724da8d',
    'identification_number': 53747755,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.83,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '094addd3-39ea-4fe8-978b-68fbc71d20f8',
    'identification_number': 92414796,
    'item_consumption_1': 7496,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2927.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '801e4a52-2d25-423e-957b-61a3503f596f',
    'identification_number': 108560015,
    'item_consumption_1': 45111,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 5757.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90cd583a-0999-4fff-accd-fad0963458e3',
    'identification_number': 27718077,
    'item_consumption_1': 2327,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 906.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aaf125f1-7cf0-4ef1-bc88-16c91514f72f',
    'identification_number': 927293026,
    'item_consumption_1': 294.2,
    'item_description_1': 'Demanda Potência Não Consumida Ponta',
    'item_total_value_1': 7432.93,
    'reference_month': '11/2023',
    'referente': 'Demanda não consumida',
  },
  {
    'uuid_items': '114dc573-03db-4167-a295-b9c6592a60b7',
    'identification_number': 45483779,
    'item_consumption_1': 5421,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 8200.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7038e53-d6f4-4f83-b3e7-d22754818ddd',
    'identification_number': 45138354,
    'item_consumption_1': 1488,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 579.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e81e9bb-ab5c-41dd-9cca-5bcff3f032e0',
    'identification_number': 53507460,
    'item_consumption_1': 0.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '92ec3199-f648-4882-8247-16b188035ef3',
    'identification_number': 96513381,
    'item_consumption_1': 14312,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5580.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21507aef-f94a-4e1f-b023-9921645451b3',
    'identification_number': 10856501,
    'item_consumption_1': 7524,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3190.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '030c8acf-01e8-4847-9740-7a54b6de57e9',
    'identification_number': 108849104,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 64.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4f7c7fc1-0291-47f7-ad69-b79f72c33e4d',
    'identification_number': 50776029,
    'item_consumption_1': 1742,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2635.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a70a471d-07d0-435f-8f59-3d4c558dfb20',
    'identification_number': 38370492,
    'item_consumption_1': 0.61,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 14.56,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '032fa93c-57bf-42a3-875d-adc7f852326c',
    'identification_number': 32595921,
    'item_consumption_1': 3131,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1828.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e105d84d-5af1-45e9-90ec-77b5fae14090',
    'identification_number': 16093020,
    'item_consumption_1': 122,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 51.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '62ef5172-a8dc-4ac0-8012-eb86b565e36f',
    'identification_number': 9992820,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-208.66",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'f111099f-b93d-43d3-9e5f-37d5ce30ecab',
    'identification_number': 66832330,
    'item_consumption_1': 19.6,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 467.48,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a3974d4a-3947-47d9-b41b-14ad26c520b0',
    'identification_number': 22509470,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '02eeb08f-0cc0-477e-8900-ccbe07e7b44b',
    'identification_number': 66548128,
    'item_consumption_1': 11726,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4965.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '553c9975-3dff-400e-ad43-f7879481f2dd',
    'identification_number': 11116102,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.89,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e4a7703e-e2fa-4c1f-8774-d5039a587604',
    'identification_number': 91394384,
    'item_consumption_1': 8846,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3451.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '898aca43-6221-4500-ab1e-012d679b580b',
    'identification_number': 74755897,
    'item_consumption_1': 0.16,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 3.83,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '062f692e-e91f-4a67-a420-913559b38df2',
    'identification_number': 29705932,
    'item_consumption_1': 15,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 8.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5a6b29b-eab6-4783-be05-fac719a58e3f',
    'identification_number': 4334221,
    'item_consumption_1': 19253,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2457.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f85da3a1-2cd1-43bd-8383-40d1723fd80e',
    'identification_number': 56877021,
    'item_consumption_1': 1126,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 657.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2c022fc-0ae0-493a-888d-a215a77b34bd',
    'identification_number': 97575941,
    'item_consumption_1': 10.44,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 249.02,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4f4147d4-8e86-4418-a40e-c5c7aa420110',
    'identification_number': 3030784010,
    'item_consumption_1': 132,
    'item_description_1': 'Consumo Reativo Exc Fora Ponta NOV/23',
    'item_total_value_1': 39.34,
    'reference_month': '11/2023',
    'referente': 'Energia Reativa Excedente Fora Ponta',
  },
  {
    'uuid_items': 'dd7e76cc-521a-4c8d-b606-d104f493fff5',
    'identification_number': 21718989,
    'item_consumption_1': 271,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 34.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34eb1127-d59a-4c76-937b-da5244134cb4',
    'identification_number': 58739149,
    'item_consumption_1': 29.59,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 860.64,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '21a5a623-32e7-4fdc-936a-6cb3c9f316c6',
    'identification_number': 59101580,
    'item_consumption_1': 676,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 394.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '41651859-a4e7-4b61-a4b3-297fcc5d5b9e',
    'identification_number': 14619555,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 253.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '641aeb71-9ab2-40d1-bc4a-6a872d6cce18',
    'identification_number': 83687114,
    'item_consumption_1': 300,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 175.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '638711d9-0302-4cbf-b182-fb1952ccd45e',
    'identification_number': 65141024,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b035cd6c-7204-4ed9-a371-70cbd776614c',
    'identification_number': 32303912,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3374.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbc43aa0-ac66-4556-9aa7-edf482dce79e',
    'identification_number': 104309555,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1283.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea783aa6-41f6-4ef1-99b0-de1233abe0bd',
    'identification_number': 22846670,
    'item_consumption_1': 1594,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 674.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f264d315-723a-4f17-978a-3efba46233a2',
    'identification_number': 17159660,
    'item_consumption_1': 2.67,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 77.65,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': '4c43a1f1-cd9c-4b9d-afb1-c38bfe9593fc',
    'identification_number': 5856930,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 50.31,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0b14708e-11ec-4c14-8039-3dd638ebb595',
    'identification_number': 52635260,
    'item_consumption_1': 246,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 95.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce5c6735-7dae-46a0-adba-2e74b20afc8a',
    'identification_number': 80350844,
    'item_consumption_1': 13440,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1715.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60dbf759-5d04-468b-a68d-9aba62fceeac',
    'identification_number': 7677359,
    'item_consumption_1': 1086,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 423.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b5744fa-7077-4343-8012-4398676fd340',
    'identification_number': 98598945,
    'item_consumption_1': 1922,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2907.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e05133b5-157c-48e1-996c-fd3388b5fb13',
    'identification_number': 48754110,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1c2e671d-3bfc-47ce-aa71-3e379f7c4fc5',
    'identification_number': 44024924,
    'item_consumption_1': 470.96,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 11232.65,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '8e32f76b-deeb-417f-9877-7c05a459f785',
    'identification_number': 103002456,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f5be5d01-29b8-4da8-828e-ca56dd2340ce',
    'identification_number': 69852634,
    'item_consumption_1': 1019,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 398.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26c8cb78-b55a-4945-8605-4c7ff6a13e1f',
    'identification_number': 108883515,
    'item_consumption_1': 2982,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1266.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '579c206c-a162-48e6-9c09-0c2232c1d366',
    'identification_number': 70509204,
    'item_consumption_1': 143,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 216.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '569534c1-152d-429d-b2da-2058b642b6c4',
    'identification_number': 20587155,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 667417.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79bcacb9-1017-4233-9a5f-ed2d99623c33',
    'identification_number': 14448726,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cfcb1f44-6a41-418c-9ac5-f8c2ab004bae',
    'identification_number': 100002676,
    'item_consumption_1': 194,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 82.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ccb0448-1fc2-49b1-a118-68ca7bf6b872',
    'identification_number': 93308965,
    'item_consumption_1': 7090,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 904.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '111e6905-82b8-4b58-ab5b-f29b3860326c',
    'identification_number': 37267051,
    'item_consumption_1': 1998,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 847.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca1c0115-993c-406b-8bfe-20ee60ce17c2',
    'identification_number': 43597564,
    'item_consumption_1': 720,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 304.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd17c36b-665e-492a-91a8-c218cb769165',
    'identification_number': 101400748,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e228f27-69d2-4d12-977f-50cfb42f8d69',
    'identification_number': 18536611,
    'item_consumption_1': 1667,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 650.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3cb363b8-5fb1-4a4c-87f3-d5feeb7fae15',
    'identification_number': 103962298,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4f309756-0304-4b4a-9a5a-5a9f671f273b',
    'identification_number': 29966060,
    'item_consumption_1': 608,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 919.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1c9252a-2036-41ca-8277-1cdc64dc9635',
    'identification_number': 99004003,
    'item_consumption_1': 789,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 307.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40e3d63e-8be8-4849-a7f2-c7b562ec9a95',
    'identification_number': 100627188,
    'item_consumption_1': "'-21.905",
    'item_description_1': 'ENERGIA INJETADA FP TUSD 11/2023',
    'item_total_value_1': "'-2292.61",
    'reference_month': '11/2023',
    'referente': 'Compensação crédito energia',
  },
  {
    'uuid_items': '661b268b-7cea-47b5-8a42-15fad936b677',
    'identification_number': 70503974,
    'item_consumption_1': 6913,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2572.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e7e52fb7-a971-40e8-b609-3f85737b31ba',
    'identification_number': 70085714,
    'item_consumption_1': 3013,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1178.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a7cd5d5-f5b0-4490-8578-7c7058424258',
    'identification_number': 56582854,
    'item_consumption_1': 2142,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1251.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20b9544e-069f-4c6f-a985-d1d52ad07416',
    'identification_number': 12629,
    'item_consumption_1': 21230,
    'item_description_1': 'CONSUMO fora de ponta',
    'item_total_value_1': 9593.77,
    'reference_month': '11/2023',
    'referente': 'Consumo fora ponta',
  },
  {
    'uuid_items': 'b3fe2d05-d796-48c2-a6b7-92f408518667',
    'identification_number': 22616519,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'da33839d-3fd4-48e0-ae78-4f3afbbec076',
    'identification_number': 16677412,
    'item_consumption_1': 23323,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2976.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '85773f49-1252-48b8-9d7f-4617c52e8d63',
    'identification_number': 44024924,
    'item_consumption_1': 11354,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 6631.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7542f14-6e65-4094-9587-bdf1375ceb57',
    'identification_number': 107173115,
    'item_consumption_1': 8847,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3292.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5277f36-c8f5-4500-b2cf-3e711bfd15ea',
    'identification_number': 86207563,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 7.83,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e96ce47e-301e-45a7-bb80-e83f341f1af0',
    'identification_number': 15682277,
    'item_consumption_1': 249,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 97.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '051c4e70-e6e9-4091-8271-54b38136ee84',
    'identification_number': 45603391,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c48d2774-e31d-4b14-8fa3-428173639ecb',
    'identification_number': 40879356,
    'item_consumption_1': 1005,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 426.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b5c8275-d133-4e91-b167-0cdbb2838c86',
    'identification_number': 36338958,
    'item_consumption_1': 5384,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2287.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5167efac-a873-4e38-a2a9-dcb74e82f090',
    'identification_number': 7347430,
    'item_consumption_1': 3511,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1371.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3a0bb891-0368-4259-a7f3-37893b8d39f9',
    'identification_number': 41456521,
    'item_consumption_1': 1256,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 491.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93200c6d-57b8-418f-9702-61931889a8b1',
    'identification_number': 16744764,
    'item_consumption_1': 450,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 175.32,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42efaa17-698a-4398-aa07-f1315220a951',
    'identification_number': 105796506,
    'item_consumption_1': 795,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 338,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f198094-c97b-4636-985a-cb09a11d6b21',
    'identification_number': 19450672,
    'item_consumption_1': 133437,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 17031.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad1053e4-cb74-4829-8821-c857116084bc',
    'identification_number': 43546900,
    'item_consumption_1': 2038,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 794.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3e689e2-99b9-47c9-b470-312bf9fceebc',
    'identification_number': 37998447,
    'item_consumption_1': 32,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 12.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c748be83-eae2-4cad-ba58-9074e301811c',
    'identification_number': 108991679,
    'item_consumption_1': 124,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 48.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '787f9cf1-6f48-499b-97d0-129323e9b919',
    'identification_number': 103721835,
    'item_consumption_1': 747,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1130.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba0e2f04-0142-4779-b5a4-e634abf4bbd2',
    'identification_number': 98165895,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.24,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '32fb59a9-95c8-4b88-a8e9-109c7b39cb79',
    'identification_number': 14660601,
    'item_consumption_1': 56,
    'item_description_1': 'Consumo na ponta',
    'item_total_value_1': 32.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fa956ce-90f0-482f-be5c-0142f0fa82b6',
    'identification_number': 42056306,
    'item_consumption_1': 439,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 185.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f726f395-7248-4026-940f-a12baa600b42',
    'identification_number': 5574889,
    'item_consumption_1': 6910,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2935.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40e5e2a6-2db3-4ad0-9bac-3f215616ba37',
    'identification_number': 68559127,
    'item_consumption_1': 3430,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1337.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3d394386-2441-464e-b1fe-ea953bff02f4',
    'identification_number': 93669860,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f081231-e606-479d-9371-a10533646831',
    'identification_number': 17159660,
    'item_consumption_1': 459,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 694.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd28abc19-07e7-45bb-9304-e41b693fe912',
    'identification_number': 23078758,
    'item_consumption_1': 894,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1352.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5032f2db-1b5f-4aa6-94a4-2068f0d674ef',
    'identification_number': 10813993,
    'item_consumption_1': 8732,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3409.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd98a4da5-c2cd-4cfb-a4c0-0782355ad12b',
    'identification_number': 103285369,
    'item_consumption_1': 7691,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3257.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23530e7d-8f38-4507-aaa0-8fb6f5cbc771',
    'identification_number': 19429525,
    'item_consumption_1': 2119,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1237.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '456322e2-3a74-4aaf-91c8-bc15a2504996',
    'identification_number': 73311235,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3408.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '520782b9-edb9-44f0-8acd-4b635dfe0e81',
    'identification_number': 91319293,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd79a76c5-78c3-44b0-97f4-dc8b0a2538c3',
    'identification_number': 66902983,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98a61b22-b9d1-43e3-9584-68fca34b6fcf',
    'identification_number': 66861292,
    'item_consumption_1': 170,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 66.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ed68a271-bb4a-4776-b285-25c423e65158',
    'identification_number': 93324138,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3022.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd96de4c4-b92d-4b95-9534-e37af7463c15',
    'identification_number': 19452667,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7cbf2237-e06a-4431-9dd3-451ea1205200',
    'identification_number': 41381823,
    'item_consumption_1': 109579,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 40779.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97590901-1624-4e56-bcd5-c1c0444016ac',
    'identification_number': 103611126,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e64a937-1a8b-4738-acb7-3b04351ab2f2',
    'identification_number': 95609679,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-5.32",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '023a2d23-cc34-4b6f-9d15-a5ced677766b',
    'identification_number': 17187923,
    'item_consumption_1': 18.86,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 548.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '440c7c22-dd10-485e-8798-5823ab78681f',
    'identification_number': 97856681,
    'item_consumption_1': 19978,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2549.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ddd2512-f1d2-4de8-aee0-ee25d6736bda',
    'identification_number': 92387578,
    'item_consumption_1': 9,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.51,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ab88d4d1-6279-425c-87b7-586702f29f0f',
    'identification_number': 99379422,
    'item_consumption_1': 438,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 662.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e4433a1-da93-4a82-b75e-9873a0a64697',
    'identification_number': 21718970,
    'item_consumption_1': 15524,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5777.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aa99ccef-8088-4f1c-bdd3-92efa017d3c1',
    'identification_number': 20559755,
    'item_consumption_1': 1111,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1680.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2315fd63-c450-45fb-90f5-02b490cb7436',
    'identification_number': 90990684,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '43971b6f-9b0d-45c6-96c0-61433f6fc5bf',
    'identification_number': 79545793,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'bcde1d6f-a482-4150-bb8d-4feb7a6d275e',
    'identification_number': 62362801,
    'item_consumption_1': 70.99,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2064.8,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0ab1fac1-b17d-44c8-9abc-5dba50c51c67',
    'identification_number': 15102009,
    'item_consumption_1': 1044,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 407.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '72b545aa-1147-4a76-ad91-eac673a9e96a',
    'identification_number': 14662302,
    'item_consumption_1': 15,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 231.77,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3f33edd9-2bac-43c9-946e-96eeeb5f7ace',
    'identification_number': 105089060,
    'item_consumption_1': 10,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.9,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '1f8c1aca-df43-4154-aee2-97930cffc08e',
    'identification_number': 9822950,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2269.56,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '691b6740-5afd-4449-a51e-6b867112f340',
    'identification_number': 83687114,
    'item_consumption_1': 3800,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1414.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1559b30-7693-4100-947d-9c032fb264a2',
    'identification_number': 32348533,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f8aef2e0-c279-4f19-a83b-d5cfbd98ad71',
    'identification_number': 87231808,
    'item_consumption_1': 253,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 107.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9253044a-c9ee-4a1c-9201-b7423cae5ddf',
    'identification_number': 15803600,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 91.58,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '78a72955-7bab-4cd7-8040-8a705eed023b',
    'identification_number': 12493961,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 4654.68,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '85474cbf-4197-4a19-8b7a-2d12cf6dab22',
    'identification_number': 100359140,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 16.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6dec04eb-8d02-4051-a4bd-ff0cce1a5ac5',
    'identification_number': 8708320,
    'item_consumption_1': "'-8.736",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 08/2023',
    'item_total_value_1': "'-3035.66",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '54f54586-264d-47d9-98a6-4642d3078d65',
    'identification_number': 70425914,
    'item_consumption_1': 6867,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2914.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae84c94d-efca-489c-bd44-7f606198885f',
    'identification_number': 15981401,
    'item_consumption_1': 146,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 2751.1,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '76ecedb9-1744-478f-8b67-a916fb268d15',
    'identification_number': 18267211,
    'item_consumption_1': 8.11,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 235.87,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '91fc8cce-99ca-4d24-b4d4-f61b84bc9663',
    'identification_number': 92564186,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '06f2684c-dbdc-49af-acb1-1198fcbf3303',
    'identification_number': 5811848,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b0259aa-dc0c-4107-9329-8771ad6379a7',
    'identification_number': 69703671,
    'item_consumption_1': 338,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 131.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0720039d-0737-4c72-a98b-78bf9151cd72',
    'identification_number': 111114381,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5369285d-499c-4013-ac74-ed4bf8901939',
    'identification_number': 56026684,
    'item_consumption_1': 7607,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 970.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd698823e-c530-42df-8c1b-21520f353d60',
    'identification_number': 102261016,
    'item_consumption_1': 94,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 54.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1da30c8a-0d9e-46fd-894e-21f0a037cc0f',
    'identification_number': 48754110,
    'item_consumption_1': 10250,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4009.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f1acc2a-fff1-4b96-84f7-a28ada53edcb',
    'identification_number': 84245590,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65b9a968-58bc-4efa-8f93-de4bbdb4e1ea',
    'identification_number': 92140394,
    'item_consumption_1': 2098,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1225.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90a951d5-43c8-4ddb-aeab-903a639bcdce',
    'identification_number': 27112101,
    'item_consumption_1': 45.8,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1332.12,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0ccc6f10-d400-4abf-bbf4-5d5bc7d0eca1',
    'identification_number': 109864905,
    'item_consumption_1': 187,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 73.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7307f5f6-5f17-48b4-bd9e-ed97b53659ae',
    'identification_number': 10947604,
    'item_consumption_1': 1118,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 436.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12c1566d-a4c4-4e22-ae4d-9f69cec6a669',
    'identification_number': 103770895,
    'item_consumption_1': 2287,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 969.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '831d8fbc-f113-4b99-9ae4-0b063602603c',
    'identification_number': 17401682,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e9283653-bea4-4e31-8744-8013ec5a22de',
    'identification_number': 17799880,
    'item_consumption_1': 223,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 94.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8dfa2da0-2817-4f05-9fb8-ae8ffcceaeb5',
    'identification_number': 37969064,
    'item_consumption_1': 5.36,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 127.84,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd0f86127-e48e-4e98-8d88-3620a0058b92',
    'identification_number': 110270410,
    'item_consumption_1': 33,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 12.91,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6b6a3010-9bef-48a5-86cf-2a7f287c09e0',
    'identification_number': 14619563,
    'item_consumption_1': 2.44,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 58.22,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f7c50c9c-e00e-4399-8233-683233bc9aa9',
    'identification_number': 33760900,
    'item_consumption_1': 6330,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 9576.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fcb7ec4c-84de-4303-86ce-8dd7d8ad1080',
    'identification_number': 80146139,
    'item_consumption_1': 9021,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3517.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb70d992-2634-4949-8f51-f002ecac6ff3',
    'identification_number': 108608042,
    'item_consumption_1': 4694,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1834.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb7fcb40-43c8-470e-ab3b-b9ed1a7c8a0e',
    'identification_number': 99102935,
    'item_consumption_1': 77.39,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2250.95,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a1f78ba7-0995-45dd-adba-cf8f99508b30',
    'identification_number': 103928065,
    'item_consumption_1': 362,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 141.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6600e358-89d1-43e6-874d-6edaa08857b2',
    'identification_number': 23562200,
    'item_consumption_1': 15960,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6764.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd3d6a766-9941-410b-aae5-1fa3e16d2012',
    'identification_number': 19309490,
    'item_consumption_1': 9385,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3666.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b2d125ee-71f7-4fda-89c3-db0192fa7002',
    'identification_number': 15522229,
    'item_consumption_1': 5.26,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 125.45,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'eb4fe4e1-e27c-49ce-bb0b-5fb1f22f26ec',
    'identification_number': 45907137,
    'item_consumption_1': 15.37,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 366.59,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3bf5bc44-74d9-4a03-9c07-9574d1655f1d',
    'identification_number': 90790499,
    'item_consumption_1': 0.44,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 20.61,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '7bac9e1e-fb03-4e70-9301-47f1ab95adb5',
    'identification_number': 98178679,
    'item_consumption_1': 3414,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1329.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19b47ada-f0c0-4c3d-ac04-57aa7b745464',
    'identification_number': 97871508,
    'item_consumption_1': 23667,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 9234.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e63ef281-269b-4dbd-a2eb-0d9ab47b8da8',
    'identification_number': 100335870,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 102.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f9a9a2c2-c7ad-4537-9342-114f0493f586',
    'identification_number': 17073057,
    'item_consumption_1': 1310,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1981.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9f4a9800-984f-46f5-ab51-1a7cc3dd9d57',
    'identification_number': 79052363,
    'item_consumption_1': 1775,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 692.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b87a1e72-067c-41f5-91eb-0261bce4201c',
    'identification_number': 104614285,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2755.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd208560b-3a96-46a3-8b2a-682c76cb46e5',
    'identification_number': 79645674,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0e180e2e-851a-4367-a886-a9a3819f2a45',
    'identification_number': 94633070,
    'item_consumption_1': 7779,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3042.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdd6e824-4d80-4946-9023-34badeef8d9a',
    'identification_number': 106087266,
    'item_consumption_1': 6.93,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 403.12,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f43dae0b-d463-407d-b046-6f937956f302',
    'identification_number': 30102901,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1733.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f5afd39f-957e-4cc7-a4ae-a60192beb573',
    'identification_number': 69572968,
    'item_consumption_1': 723,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1093.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '00276fdc-65b2-4abc-8c71-237402b03740',
    'identification_number': 64104770,
    'item_consumption_1': 4614,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6980.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b3a4c50-b375-4cf4-9433-d565b7e29675',
    'identification_number': 46912797,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '38e422bc-ebe3-434b-86a6-e10dbd96f243',
    'identification_number': 19310188,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.76,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5c41345d-5b17-42a3-8609-3c235e898d9d',
    'identification_number': 70941149,
    'item_consumption_1': 5059,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1882.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bcec6c5d-5ba7-4c87-bdb6-dc49fadfec34',
    'identification_number': 31818030,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.3,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e4e5c9fa-ccbc-424a-9407-80e9d94057c9',
    'identification_number': 105849944,
    'item_consumption_1': 49.26,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1174.89,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '27c4f12c-6d5b-449f-8ebd-e421caaf62d6',
    'identification_number': 27904660,
    'item_consumption_1': 21502,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8001.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d02e97c-c320-4c7a-9d84-6d9ab3c6bef8',
    'identification_number': 106136712,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '65915f23-7459-423b-8de4-aa7747dee74f',
    'identification_number': 20444060,
    'item_consumption_1': 19537,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2493.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '15837654-8e38-4e0b-be4c-1038683ee1b8',
    'identification_number': 19306628,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0e9f0aa-a67b-40a4-ab17-a7c8ddf07072',
    'identification_number': 111295459,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '53d5d1ce-635b-4365-be5b-a8b037e63dc8',
    'identification_number': 70085714,
    'item_consumption_1': 3013,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1280.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '945e469b-da41-43c3-8d64-d6dfc23a44c8',
    'identification_number': 27434826,
    'item_consumption_1': 4031,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1571.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '36bb4119-1804-4f95-b449-a4ff3814101e',
    'identification_number': 34210334,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4ad4b827-1428-4499-b32d-e605e8d3879d',
    'identification_number': 109368380,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c9846e04-fc09-4892-8fce-b5ead12bf8e1',
    'identification_number': 15158837,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '762b32f0-ddba-4695-8ca0-53e467d1a401',
    'identification_number': 9823000,
    'item_consumption_1': 26979,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10040.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4760e9a1-7cca-402c-9b17-1f2661d8b15f',
    'identification_number': 35090448,
    'item_consumption_1': 99,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c1e4bc0-1f3b-45ed-bcd5-4d9247d35ea5',
    'identification_number': 91355540,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '99c99262-d8c2-402f-b8f6-f1b56e863977',
    'identification_number': 17578612,
    'item_consumption_1': 1883,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 798.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e1eadb2-7fd2-4191-97a6-fc3f4610539e',
    'identification_number': 16485262,
    'item_consumption_1': 11422,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4837.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39b7cf11-3866-4e86-bd1f-0071549a47e5',
    'identification_number': 32594852,
    'item_consumption_1': 379586,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 48448.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e37f35c8-43f4-4191-bcba-f0089a198abf',
    'identification_number': 15158241,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '4178e4d6-af38-49e0-9f58-8ca0ab67def8',
    'identification_number': 31818030,
    'item_consumption_1': 20288,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7550.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '878ec54b-09f4-4ca7-a8fd-5fc14187f389',
    'identification_number': 35809329,
    'item_consumption_1': 0.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1.14,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '89a9a78c-5c34-4116-9181-6adbe9b539b3',
    'identification_number': 8906700,
    'item_consumption_1': 1880,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 1428,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c605883f-7bea-4399-82a8-b25099426d0e',
    'identification_number': 10904867,
    'item_consumption_1': 645,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 252.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a910e1d-adc3-4500-b975-33360d0b0d33',
    'identification_number': 76002039,
    'item_consumption_1': 2570,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1003,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3aad30f3-a38d-49fe-8bf2-70e111553bd2',
    'identification_number': 9077839,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '418e7874-e86b-4ea9-b846-667c3a05f483',
    'identification_number': 21539340,
    'item_consumption_1': 9911,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3875.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b6c10c1-5968-4b9b-973e-c753f977837f',
    'identification_number': 36385352,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '64e76f87-11fe-4fae-a585-0dcbd3cb6f00',
    'identification_number': 32303912,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9838.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c04b2b79-8852-425b-8dbb-d627d054f4cb',
    'identification_number': 82743339,
    'item_consumption_1': 110.89,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3225.33,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '219d61c6-7d7c-48bc-a9c0-15497279e586',
    'identification_number': 79545793,
    'item_consumption_1': 26442,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9840.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '980cbadb-aca9-49cc-96bf-d95e9e9768ba',
    'identification_number': 96626801,
    'item_consumption_1': 500,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 212.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb610a1b-0c94-4064-9048-00a63570f7ec',
    'identification_number': 10103554,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.72,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '055f53c0-8108-4b15-98be-578517ffe900',
    'identification_number': 16105699,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dc4a4f60-014a-4f89-b55b-78f0afe52305',
    'identification_number': 16699696,
    'item_consumption_1': 68.55,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1634.97,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0f671629-15e2-4f70-a79d-19ccfeb4269c',
    'identification_number': 46764544,
    'item_consumption_1': 0,
    'item_description_1': 'COSIP MUNICIPAL',
    'item_total_value_1': 75.2,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '76c6f379-97b6-4a1e-b623-2a82a1d9a422',
    'identification_number': 86398652,
    'item_consumption_1': 406,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 158.84,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c0748d61-a7c0-4c2a-92b9-bf4eeb5454c6',
    'identification_number': 44476108,
    'item_consumption_1': 9.4,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 273.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7bb22b41-5226-4f73-bcc4-114114099892',
    'identification_number': 109382595,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 31.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '39ceae17-18d7-4d0a-b58e-662d7dd9c784',
    'identification_number': 5587069,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a47e9488-307e-4f4c-8016-198f30a20775',
    'identification_number': 11013958,
    'item_consumption_1': 0.42,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 19.69,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'b909829a-08f3-46f9-9f86-38999c3826d0',
    'identification_number': 41069420,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 94.69,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cac891d1-cf33-4e5f-8335-9e9e21d486d0',
    'identification_number': 19452632,
    'item_consumption_1': 51842,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 30277.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9359b8a5-7910-4fb3-83d9-d55efcbd32c4',
    'identification_number': 56334044,
    'item_consumption_1': 29.61,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 861.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dce7ebb0-872e-45f4-b4a6-eba879df5d2d',
    'identification_number': 19873727,
    'item_consumption_1': 63.36,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3618.47,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1d656561-f895-47e0-9e37-325e68df77c5',
    'identification_number': 48651940,
    'item_consumption_1': 32.47,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 944.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b8a4964a-c24b-48e6-93e6-242f0b8a7f61',
    'identification_number': 29970474,
    'item_consumption_1': 407,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 172.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '39479899-9643-4db2-8ae8-aa5d38e21f8b',
    'identification_number': 104648201,
    'item_consumption_1': 596,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 252.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2ec0c4e-fa44-4673-9cc8-19fa83b8f48f',
    'identification_number': 99211823,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '613a49e0-715a-4692-8a09-f364e4291855',
    'identification_number': 98988271,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '827273e2-38a5-404a-be28-d8e6ed0fb566',
    'identification_number': 38226928,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 29.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'abb9c771-841c-4d35-b8ac-d1010a736fe9',
    'identification_number': 71175644,
    'item_consumption_1': 6724,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2627.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2d481f0-aa0e-4aaf-a861-e7de58e90527',
    'identification_number': 90908600,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '28b03247-a7a1-4066-83fc-adafcd6ba5c8',
    'identification_number': 31216595,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aaf1063a-fab1-4c25-ace9-4cd4c1fabce8',
    'identification_number': 32747217,
    'item_consumption_1': 13,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 19.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '359e94c0-4aa3-448d-8021-6ef84213ce07',
    'identification_number': 105704008,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8865f507-9d5f-4f88-8866-e0ab642f3b4a',
    'identification_number': 95016066,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '47056039-3e21-4cde-b742-71e2c2c04839',
    'identification_number': 41314336,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6b181762-3d49-4305-a2cf-78719ac7fd78',
    'identification_number': 102251967,
    'item_consumption_1': 964,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 376.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5f85e426-6d28-4b4b-ab75-c69b0d2dc742',
    'identification_number': 46577173,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'bcea206d-8101-462a-9d14-9cf0014a5398',
    'identification_number': 48367583,
    'item_consumption_1': 11565,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4303.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bb8d246-61a4-498f-9d02-61ed03559904',
    'identification_number': 18273424,
    'item_consumption_1': 382,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 577.89,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c6291c5e-da05-481c-aff8-87fac56914a7',
    'identification_number': 95590927,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6c8bcd4b-8a6d-4e4b-82dd-da3af6223a53',
    'identification_number': 45501157,
    'item_consumption_1': 34155,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12710.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bdf9edf-86b5-475d-a1ff-6bb23430722f',
    'identification_number': 70152845,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '722b7c96-ba53-4104-a8ba-bb2fc7a5fd31',
    'identification_number': 95917160,
    'item_consumption_1': 2657,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1550.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e28aeaa6-74db-410d-bb72-cfd10072c85f',
    'identification_number': 28528646,
    'item_consumption_1': 34,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 13.29,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0e5d0ca7-273a-437a-8017-96b87ae4094f',
    'identification_number': 93302096,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 112.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a558e420-535e-4753-9368-02748dfcfc5a',
    'identification_number': 40783308,
    'item_consumption_1': 2812,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1195.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c8541e72-44cb-4bf4-94fa-53ce0a21a0c2',
    'identification_number': 17810590,
    'item_consumption_1': 8538,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3332.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3588573-9cb9-4bdf-b1de-785f196b70ac',
    'identification_number': 102054690,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 18.18,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2bb18fa5-3859-4047-829d-d1ef5f656153',
    'identification_number': 60978880,
    'item_consumption_1': 560,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 237.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b1994c6-6a74-459a-b9f9-8135cbb6a8e4',
    'identification_number': 42576636,
    'item_consumption_1': 1124,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 477.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '068720d2-294c-42cf-9e61-eb39fca9e2e0',
    'identification_number': 70085072,
    'item_consumption_1': 2306,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 902.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e9323df-9772-4a9a-96f4-226c2f6c2203',
    'identification_number': 23385731,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '651a1709-72a0-410f-b313-0ae22773160e',
    'identification_number': 44010141,
    'item_consumption_1': 294399,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 37576,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '362a0e5d-b9d0-490c-89fc-5de5db98077f',
    'identification_number': 49891294,
    'item_consumption_1': 4.59,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 109.48,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2f58a6eb-299b-4fed-a44f-8a806bceeb5f',
    'identification_number': 11116102,
    'item_consumption_1': 2969,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1158.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efb019d9-741a-477b-bc3e-51c43bbdcbb8',
    'identification_number': 71307745,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 190.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6e6e71e0-0845-4297-a196-fda2e56c31ce',
    'identification_number': 103006370,
    'item_consumption_1': 2740,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1071.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19fd12d8-6583-4b54-a7dc-b5c112069fdb',
    'identification_number': 71798595,
    'item_consumption_1': 9540,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 14432.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66731a12-6fe1-446b-944f-49eed3e4310c',
    'identification_number': 82743339,
    'item_consumption_1': 4312,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6523.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29c77e2c-190a-4194-ad83-91b482cd5b20',
    'identification_number': 95250670,
    'item_consumption_1': 224,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 87.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ffe0b496-de28-4e1a-943a-bd240580b118',
    'identification_number': 48367583,
    'item_consumption_1': 50.72,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1475.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '74290ea5-4b8b-4b27-bd8d-8773d4393a7d',
    'identification_number': 6189512,
    'item_consumption_1': 10.86,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 259.03,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'bb23d8d0-5cdd-4a56-9956-27389992d839',
    'identification_number': 101937792,
    'item_consumption_1': 15230,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5667.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'edb6e5de-958d-48dd-bbc8-c444a2d01fa2',
    'identification_number': 28597095,
    'item_consumption_1': 822,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 320.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0b3c07c-f242-4e0f-9d2c-d267eb74b385',
    'identification_number': 13715666,
    'item_consumption_1': 9676,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4097.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cfe083a1-a4fc-406e-8894-547096c3a067',
    'identification_number': 44001681,
    'item_consumption_1': 4693,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7099.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '338f5d54-715b-40e4-8bac-70a4d50b68f8',
    'identification_number': 12382671,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2cc5691b-68fa-4c0f-8eff-ddfadeae6b8f',
    'identification_number': 18216994,
    'item_consumption_1': 8358,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3257,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b47d8d96-a054-4c39-aaec-9126bb28f256',
    'identification_number': 103020063,
    'item_consumption_1': 1114,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 473.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd8a30da-7d1a-4ea8-b7d0-c10d6050c403',
    'identification_number': 23274310,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0019fb44-4104-4548-96c8-61a32ef80f2e',
    'identification_number': 68029098,
    'item_consumption_1': 2.79,
    'item_description_1': 'DEMANDA USD E PONTA ISENTA ICM',
    'item_total_value_1': 66.56,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '259ce322-0ef0-4206-99ed-ded4fa23edb3',
    'identification_number': 19450737,
    'item_consumption_1': 2689.92,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 78238.89,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f15bfc04-e0d5-45ef-8800-30314fe5609b',
    'identification_number': 102255830,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8253deb-627f-4747-bacd-1a644a90be37',
    'identification_number': 36648329,
    'item_consumption_1': 1796,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1048.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25691e5a-dcb3-41a3-af78-bb53427e775b',
    'identification_number': 104642505,
    'item_consumption_1': 4465,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1891.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd5976f45-fead-4264-bf25-1a5e5716d5d7',
    'identification_number': 45766500,
    'item_consumption_1': 52,
    'item_description_1': 'CONSUMO TUSD',
    'item_total_value_1': 19.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24785d3e-39a4-43d7-91f5-dfd5804dc872',
    'identification_number': 19312334,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0d9dec47-f63d-4b76-8129-3f0ed8b8ca9e',
    'identification_number': 101126441,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '74ad31c1-03a1-4ce3-9ddb-d675c59aad71',
    'identification_number': 101937792,
    'item_consumption_1': 76.75,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2232.32,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fa19430b-7536-415a-9d2c-e049b66968e5',
    'identification_number': 109603940,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 67.15,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3af9c01a-b773-4736-a45c-058652b0cff5',
    'identification_number': 93067895,
    'item_consumption_1': 2315,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 295.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d54b256-6be6-4cc5-b5f4-181f9bf193a0',
    'identification_number': 101940122,
    'item_consumption_1': 733,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 428.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08a1d827-ce26-46cb-bc21-d9412dd52825',
    'identification_number': 99313987,
    'item_consumption_1': 3839,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1496.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ff6266f-0bfb-4f3b-9b37-3994cc72edc8',
    'identification_number': 45563314,
    'item_consumption_1': 6057,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2368.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7bcfdd7c-14da-4f66-9611-af3acf68db57',
    'identification_number': 77008421,
    'item_consumption_1': 6715,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2624.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '429f569f-7b16-4816-a6e2-e1da84d1cc8f',
    'identification_number': 51026279,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'ddf1bcab-2c70-454a-8652-982b30f24737',
    'identification_number': 60035498,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a825f28f-a642-47a4-8e41-cc3edcfcecea',
    'identification_number': 109118030,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b85623e5-127f-4339-8282-523b309fecfd',
    'identification_number': 32927061,
    'item_consumption_1': 9272,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3450.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6dfa23fb-981c-4f8b-a5a6-aab156972e23',
    'identification_number': 105805572,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 225.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5a48229-df5c-4ae1-be01-0b76d6a9f74e',
    'identification_number': 17073057,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 68.1,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8719c731-48f9-4f27-88e5-1b129e31b5a1',
    'identification_number': 94502706,
    'item_consumption_1': 1147,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 487.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99255f72-0a02-471d-9cad-d9f1adfacff0',
    'identification_number': 98548387,
    'item_consumption_1': 998,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 389.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd393cda-f1b7-4ad6-8979-6c35140d6685',
    'identification_number': 53330668,
    'item_consumption_1': 14192,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1811.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5ecdc448-e324-46c9-aba9-e7b83a5cfbbb',
    'identification_number': 37781430,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '01f73ac0-5cb2-4642-bbb3-402a89a90cc5',
    'identification_number': 55653898,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '494bab01-1a13-4a1e-9681-30b23ca689ef',
    'identification_number': 41360818,
    'item_consumption_1': "'-971.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-338.52",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '2d90c2b9-5485-4fd5-b2fd-debed349c9bd',
    'identification_number': 104638621,
    'item_consumption_1': 827,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 350.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2f6609e0-b88e-4ecc-8695-dda0c8bd39f6',
    'identification_number': 102003211,
    'item_consumption_1': 1581,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 923.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd36171db-591a-495c-bc16-5d71b1350ccc',
    'identification_number': 99541360,
    'item_consumption_1': 124,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 52.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47830ed6-f4be-4c77-b03c-d3204da83288',
    'identification_number': 102805687,
    'item_consumption_1': 415,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 175.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '89d070e3-0896-405b-a74b-b3f0c1552710',
    'identification_number': 27904660,
    'item_consumption_1': 49.54,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1440.9,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5ba238b6-72eb-4e41-add6-64cdbfa9adeb',
    'identification_number': 30102901,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5054.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3b739b3d-43ce-410f-9484-0e0255399615',
    'identification_number': 70870969,
    'item_consumption_1': 10455,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 4438.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6da4123-5a70-4e8f-8254-0642e6025668',
    'identification_number': 29711738,
    'item_consumption_1': 1551,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 658.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f5e015b-ed27-41b5-b689-ebf5aa10e410',
    'identification_number': 100891918,
    'item_consumption_1': 1227,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 478.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '167756ee-954d-42e9-a63a-9108374a220d',
    'identification_number': 20167725,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad1fe710-b50b-426c-bf95-199d84f8a380',
    'identification_number': 103025758,
    'item_consumption_1': 482,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 281.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4574ec9e-63df-433a-ac5d-6b989cccb05a',
    'identification_number': 62270010,
    'item_consumption_1': 295,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 125.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2893c1f-f385-445f-a0d0-d390e74ba0bd',
    'identification_number': 103771131,
    'item_consumption_1': 1741,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 739.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94a5da52-d8c3-4990-9128-7ab503ba5d68',
    'identification_number': 90439325,
    'item_consumption_1': 6.56,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 381.6,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '7c00b6a5-e1f7-4019-85fb-e3297a1bda4c',
    'identification_number': 12917257,
    'item_consumption_1': 258,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 100.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '516ba35b-aa62-42ec-877b-8da7e527873c',
    'identification_number': 94743100,
    'item_consumption_1': 348,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 147.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1ac544e-7a02-4c40-a4db-fbbb4c7cf8fe',
    'identification_number': 101885610,
    'item_consumption_1': 289,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 113.06,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cb952733-46a7-45bd-a8bc-3b26d022f651',
    'identification_number': 29677270,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd546fbd-71c0-42d1-ac53-cb4b5e0834cf',
    'identification_number': 69826145,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.12,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b60cdd9a-3759-4829-8266-de08b290d294',
    'identification_number': 56995806,
    'item_consumption_1': 1333,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 778.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05d40b29-6f70-400b-ba28-47d05907fe11',
    'identification_number': 94010641,
    'item_consumption_1': 1496,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 584.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '048d4bcb-ceff-498c-b044-90b8282cbe35',
    'identification_number': 103611126,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8dce2a6-f322-44d6-a129-71688662f55d',
    'identification_number': 102220824,
    'item_consumption_1': 52.98,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1540.97,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6f4385f5-2089-42ab-960b-1743a4b278cc',
    'identification_number': 98351036,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4fded7e1-9a07-4a7d-9625-7825bd7ad35a',
    'identification_number': 17108462,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e97732e-6f7b-4a8f-86d4-8c3b516de637',
    'identification_number': 38781654,
    'item_consumption_1': 370,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 157.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96e7fcf6-6ee4-4d1f-83b6-a0c253d68bfc',
    'identification_number': 106731971,
    'item_consumption_1': 12319,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5230.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b7300850-9c9a-40b3-81f3-b43907758918',
    'identification_number': 34208089,
    'item_consumption_1': 4958,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1938.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c300dfdd-2edc-474b-be3e-82a5ebce82e4',
    'identification_number': 1841165,
    'item_consumption_1': 3360,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1311.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '734470c3-65d4-4320-b78d-78f1208b5ec0',
    'identification_number': 91588103,
    'item_consumption_1': 387,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 151.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '65e96925-dabf-4c0c-a4ad-370e528eb465',
    'identification_number': 99688816,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'de1930fc-4f17-40a3-b968-5919b92bc0c7',
    'identification_number': 9073639,
    'item_consumption_1': 5476,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2141.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fed4d93e-8a60-400f-9e53-594dc815ed2e',
    'identification_number': 70427054,
    'item_consumption_1': 123.09,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3580.18,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3cd7fbe4-011f-4ef8-b9be-b73be5941d57',
    'identification_number': 94460566,
    'item_consumption_1': 2163,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1263.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6df1cec1-c212-4cde-96f4-3d9bb327b9f3',
    'identification_number': 37752871,
    'item_consumption_1': 7206,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3050.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efd2d4bc-2d27-4b79-b441-908595a17382',
    'identification_number': 109375580,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81fad9eb-363e-4c4a-9151-c19e0cd5b967',
    'identification_number': 48651940,
    'item_consumption_1': 16616,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6183.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2b4d8b6c-dffb-4e82-b186-31ee402ba380',
    'identification_number': 109487877,
    'item_consumption_1': 1058,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1600.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '062d2654-9e3c-438d-9bce-9eae965abf1a',
    'identification_number': 16861469,
    'item_consumption_1': 1727,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 220.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '967c3525-541b-4f05-9f74-f48e12d57756',
    'identification_number': 94625581,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'faf5f583-df61-4f0e-903d-651c3f463c22',
    'identification_number': 18017703,
    'item_consumption_1': 7826,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3320.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '477f8f1e-9905-487e-8388-fddb9b3a123c',
    'identification_number': 94873879,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 36.44,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b6fd0203-d77a-467a-b5f6-56ecbb407a7a',
    'identification_number': 9868941,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.4,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '92f8d5d8-8699-43f9-bdd6-3a8f1d2301b5',
    'identification_number': 80982379,
    'item_consumption_1': 104,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 40.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f6a783be-3eeb-4039-99c1-94660611dbde',
    'identification_number': 90934520,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 46.21,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '654ff5d4-8edf-4f92-b102-b4a847e5cda9',
    'identification_number': 8115800,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 53.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b5c7db2b-bbf1-4592-b2f7-360f61154978',
    'identification_number': 48367583,
    'item_consumption_1': 11565,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1476.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4274d269-3ac1-4a96-b8e5-c513e84eb28e',
    'identification_number': 103475192,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f79bb96e-adf0-495a-8ff9-b0364c965e36',
    'identification_number': 28219872,
    'item_consumption_1': 519,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 219.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b5e41bf-5c10-45dc-b2c6-bf13d6a5b387',
    'identification_number': 7284012,
    'item_consumption_1': "'-56.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 05/2023',
    'item_total_value_1': "'-19.44",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '08511e8f-d825-4120-a8ae-96102533a072',
    'identification_number': 56812477,
    'item_consumption_1': 11.97,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 285.49,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '8affd998-ff04-4fcf-b845-eea0b9b4a39c',
    'identification_number': 102404070,
    'item_consumption_1': 12765,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4750.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d9ff428-e191-4123-b57e-a987fe5f6bed',
    'identification_number': 26762196,
    'item_consumption_1': 6782,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2647.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82009b2e-fe2f-4301-8a33-0886e9d99293',
    'identification_number': 101126441,
    'item_consumption_1': 5589,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2366.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8ecc7d76-efc4-4c8f-89f7-579be7a89a24',
    'identification_number': 70941149,
    'item_consumption_1': 10.59,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 308.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bb72fbcd-8756-49c2-ac57-5700c4ed6db7',
    'identification_number': 69758409,
    'item_consumption_1': 28.53,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 829.81,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'bb729589-917f-47e9-958c-ae8546029b28',
    'identification_number': 29985404,
    'item_consumption_1': 1399,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 547.39,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a661b749-7950-4a61-95f6-f8c57433f80d',
    'identification_number': 90881656,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 25.36,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '682bcdf6-9a92-4aa2-9a22-5643ce869a58',
    'identification_number': 57109346,
    'item_consumption_1': 1.62,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 92.51,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '14ed7ba1-8dc8-4eca-a808-01ff44f9138c',
    'identification_number': 25958100,
    'item_consumption_1': 8721,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3408.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc3f5ec9-2b7d-4283-a8b0-ffe4f0b7446a',
    'identification_number': 16222539,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 79.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4dd58f4f-f670-4968-9ff3-3c4a4e5bc962',
    'identification_number': 109643372,
    'item_consumption_1': 17386,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6470.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f04468b3-8900-4cce-a46d-ae35fd6143ed',
    'identification_number': 20587155,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 92353.72,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'cc31e48c-2783-410c-bb45-b519de87ce51',
    'identification_number': 26139111,
    'item_consumption_1': 5516,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 8344.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '648a9c37-516c-4f81-85a8-6afed6cde6d5',
    'identification_number': 103006672,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e13d7de-3c91-44e9-b33b-218bdc079b65',
    'identification_number': 77178572,
    'item_consumption_1': 1466,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 573,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c4574162-6cf8-4831-92d4-f8b47c7d0c82',
    'identification_number': 79730701,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0838beaa-6f20-4462-b059-2d676d928d95',
    'identification_number': 81494394,
    'item_consumption_1': 4835,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1887.6,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82ef0e5d-f83e-4a3c-bd02-aadfbf3883cf',
    'identification_number': 57469580,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6a53dd7a-a331-415e-bae7-7b9726ac3f84',
    'identification_number': 90469259,
    'item_consumption_1': 4703,
    'item_description_1': 'ENERGIA REAT EXCED TF F PONTA',
    'item_total_value_1': 1840.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6184519f-e641-40fb-b850-7d42e0f15d0a',
    'identification_number': 100637795,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e22057d1-0eab-4073-a940-3076b13de806',
    'identification_number': 110653726,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 16.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '48104d64-1212-4476-8703-662487572ac2',
    'identification_number': 16861469,
    'item_consumption_1': 29.63,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 861.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a9da0c57-0679-4a79-bf1c-c9733e1cd4fa',
    'identification_number': 46677615,
    'item_consumption_1': 10934,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4069.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7feabde5-e4a4-40a0-aee4-140d9f2e6fb9',
    'identification_number': 104895209,
    'item_consumption_1': 1230,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 479.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20fef2c8-d682-416c-8d75-e415dfc5190b',
    'identification_number': 94050635,
    'item_consumption_1': 4934,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1923.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5537658b-5787-437e-a6b9-b37834b78810',
    'identification_number': 99102935,
    'item_consumption_1': 7.39,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 429.88,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'a088fdb4-bb5a-4f45-87fb-2833436df989',
    'identification_number': 111833868,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e8f2fe62-a27c-4702-9ff5-f08da520abc7',
    'identification_number': 20534310,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 138.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ab9ff6de-f211-49e1-a7bd-140a5dcbec31',
    'identification_number': 110992040,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e24af52f-e561-4be4-ae09-afaae32cb90c',
    'identification_number': 98197622,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 21.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '31409e79-5537-498b-9797-69d57c090ca0',
    'identification_number': 64643492,
    'item_consumption_1': 4747,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1853.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '35319c54-d5ab-4613-8699-19f56cc207aa',
    'identification_number': 77826191,
    'item_consumption_1': 207,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 80.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98be69b8-9051-42c4-8229-88f736bec5d7',
    'identification_number': 27112101,
    'item_consumption_1': 4.2,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 100.18,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e1ecd1de-7c3a-4e35-9ec4-bbfc99b8608f',
    'identification_number': 109793692,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a8505b5-8f78-456d-a227-77dc48b47c1e',
    'identification_number': 14138034,
    'item_consumption_1': 36.01,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2056.52,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5baf7421-419c-490a-86c6-923a78752834',
    'identification_number': 102261016,
    'item_consumption_1': 763,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 283.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7a175d1-1ed5-4eba-96bb-0af41ddb8a8f',
    'identification_number': 9928561,
    'item_consumption_1': 7844,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3330.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f597fae-a024-4b30-9384-2a1e956ee991',
    'identification_number': 5330351,
    'item_consumption_1': 8055,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4704.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61fe62d2-b336-4648-a124-b884562bfc4c',
    'identification_number': 948748156,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '28ecf90e-99d5-435b-aa38-2ca3273838e4',
    'identification_number': 18339603,
    'item_consumption_1': 1595,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 931.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2174379-670b-40cf-84a8-a4eccb05841b',
    'identification_number': 70082286,
    'item_consumption_1': 3162,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1341.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8c8a3ae8-b824-4def-9a81-b3bb64683fa8',
    'identification_number': 17969743,
    'item_consumption_1': 2701,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1056.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '359306dc-d20f-42e6-8a14-d5edd839ea26',
    'identification_number': 923309925,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 15.59,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c456ab66-c0a4-4c6b-9477-9ba0ed04c796',
    'identification_number': 5564352,
    'item_consumption_1': 3995,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1560.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90edd1a2-c5bb-409f-bd61-8bb35c46a5fe',
    'identification_number': 95765840,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'f1876b95-072c-472c-8213-30e15a978237',
    'identification_number': 6938795,
    'item_consumption_1': 4443,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1889,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ef4c6caa-3cde-482f-b38b-4faa60fe57cd',
    'identification_number': 14562545,
    'item_consumption_1': 20.76,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1185.58,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'e621caea-e62b-478b-94a8-5598e4a15443',
    'identification_number': 95563610,
    'item_consumption_1': 19.23,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 458.65,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '362f68df-819d-4d76-8725-0dee4e97fcec',
    'identification_number': 15214133,
    'item_consumption_1': 28463,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12049.71,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a456da60-802c-4bc1-bda0-d178abb3f759',
    'identification_number': 105361690,
    'item_consumption_1': 2020,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 788,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ec2fe77-9b86-430f-ae99-479e8a61a26a',
    'identification_number': 16570936,
    'item_consumption_1': 4.52,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 107.81,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b8e3d2e3-0bb3-4f17-a287-4088c75daccf',
    'identification_number': 17109833,
    'item_consumption_1': 27.04,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 786.46,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '943ef80d-10e5-41f7-821b-868c2b50b4fa',
    'identification_number': 106158007,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0689f99b-9094-4f31-8a4a-1a0f8f8a05d4',
    'identification_number': 102950563,
    'item_consumption_1': 1360,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 529.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '20ce3c53-5387-4302-adc6-b4efabf8fb1a',
    'identification_number': 111114195,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '894bfb1c-62d3-4b1f-b7ba-b936e8dc368e',
    'identification_number': 4334221,
    'item_consumption_1': 391.68,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 22368.82,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f3879bd8-13be-44be-b84a-218d0021b13a',
    'identification_number': 107108402,
    'item_consumption_1': 1504,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 639.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8b63ca2c-4824-47d8-993e-408714a382ea',
    'identification_number': 106759280,
    'item_consumption_1': 5310,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2254.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '632be794-f6b5-481b-a13b-9f91a31b8106',
    'identification_number': 64554481,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 33.88,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b94a0feb-c8fb-4f4d-9fd2-8604dec0c19a',
    'identification_number': 37423258,
    'item_consumption_1': "'-185.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 10/2022',
    'item_total_value_1': "'-59.32",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'fc03ac9c-a05e-4ff5-a61c-7a9b90f6c586',
    'identification_number': 17073898,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bac35f41-241e-43aa-95f0-fcaf55c1a008',
    'identification_number': 46523286,
    'item_consumption_1': 112.17,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3262.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '421663b2-c9a8-48fe-896c-52e4bc532fb4',
    'identification_number': 71391274,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-1229.96",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '2444970b-e31d-41ca-97b3-fba93accb3b1',
    'identification_number': 15465187,
    'item_consumption_1': 6457,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2735.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bb20f9f0-c7d3-4a73-98c8-9472a7c387d1',
    'identification_number': 96849711,
    'item_consumption_1': 228,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 89.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a9fe152f-831d-4fe1-9c72-0b9b5cce1670',
    'identification_number': 14906864,
    'item_consumption_1': 4290,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1816.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd37b57f7-e3d7-4446-9d86-a4bf76b755a0',
    'identification_number': 59820888,
    'item_consumption_1': 2594,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1098.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd69193f7-369e-4917-afbf-fcc6a7e040fa',
    'identification_number': 99313987,
    'item_consumption_1': 3839,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1626.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6651746c-53db-452f-a319-d3997efcc47c',
    'identification_number': 72092556,
    'item_consumption_1': 978,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1479.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5a1ea42a-817c-40bf-a0c9-465945e9f821',
    'identification_number': 42709717,
    'item_consumption_1': 1.86,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 87.12,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'afa99703-6400-47b7-a4ec-148429b0c631',
    'identification_number': 99837188,
    'item_consumption_1': 170,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 66.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6918a74e-d218-4a5c-b1db-092798799e65',
    'identification_number': 91892287,
    'item_consumption_1': 5210,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2036.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '29139bba-a52a-443a-b06d-42bfa7ba9e8c',
    'identification_number': 26762196,
    'item_consumption_1': 6782,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2877.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5c96976e-e308-4b30-b5fd-01daea8bb942',
    'identification_number': 39009378,
    'item_consumption_1': 1729,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 674.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4a85e578-281a-42be-8540-456b8c5c468e',
    'identification_number': 18466907,
    'item_consumption_1': 140,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 54.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0800bfde-532c-406c-b361-d172e13f3b75',
    'identification_number': 41360869,
    'item_consumption_1': "'-13.822",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-4433.93",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '95f944c2-c6a2-496d-816a-4733a2d5c12a',
    'identification_number': 30338190,
    'item_consumption_1': 1887,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 736.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eba55e42-09f0-465c-bc3a-0e3901071ef7',
    'identification_number': 103346341,
    'item_consumption_1': 2148,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 912.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '572a763b-9ca7-48f1-b8b6-e9a19c766042',
    'identification_number': 102726450,
    'item_consumption_1': 162,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 63.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '484afcf5-8afe-4807-9b55-c2df92da39b3',
    'identification_number': 77169689,
    'item_consumption_1': 136,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 53.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '81a1079d-74c6-4eb6-9bb3-223c2c3ea10d',
    'identification_number': 31639267,
    'item_consumption_1': 11847,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1512.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea4a3039-25dd-4819-9d63-41f34a2e1b28',
    'identification_number': 12908398,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 979.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '18a4df80-cd2c-4bf5-b1c6-076bc4d28343',
    'identification_number': 18898068,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '68f2b225-216b-4f25-8ae1-200b4e076f84',
    'identification_number': 31737960,
    'item_consumption_1': 3920,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1659.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '484bc97c-05fd-4559-9d7b-97ec1272d191',
    'identification_number': 71655956,
    'item_consumption_1': 916,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 357.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25b98162-4545-4351-83ff-6452df6b8bd0',
    'identification_number': 10006508,
    'item_consumption_1': 6082,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2584.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd96fcaba-5274-4499-9654-ad2a1063ca87',
    'identification_number': 98938622,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '71895978-bc7a-48c2-9b8c-ef276314740c',
    'identification_number': 5736234,
    'item_consumption_1': "'-556.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-177.97",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '09c64eba-2542-47f8-934c-c62f900ac430',
    'identification_number': 6189512,
    'item_consumption_1': 1395,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2110.37,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ffe6b4c-c42c-4a09-80a5-edb1e6902dae',
    'identification_number': 4504925,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '40aca8e2-634b-4ff6-8a8b-4b536d21af26',
    'identification_number': 103157980,
    'item_consumption_1': 295,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 446.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2603fdf7-65ac-47e4-bfe2-e9b354c71a1e',
    'identification_number': 102135541,
    'item_consumption_1': 2241,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3390.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '555a0a2b-fb59-4d5b-9b95-2475f195837c',
    'identification_number': 109247140,
    'item_consumption_1': 6003,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2343.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83fb2c08-e667-45f7-bc2d-1fa8a3437759',
    'identification_number': 31737960,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 105.88,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '35161f9b-c476-4ee7-bd12-8f616507db89',
    'identification_number': 93037279,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26e36615-c565-4b6f-aadc-df5c42a807e3',
    'identification_number': 26609517,
    'item_consumption_1': 3558,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1387.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fed4a790-3ce1-4428-8c7d-a60c960ce724',
    'identification_number': 16065590,
    'item_consumption_1': 1299,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 549.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfd3634f-1f90-4b26-85eb-8a15162fa250',
    'identification_number': 23647299,
    'item_consumption_1': 8758,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3709.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b6c2b7c-0dab-4681-b057-ba4f2e6ccd99',
    'identification_number': 100984070,
    'item_consumption_1': 266,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 113.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f9b4b5ef-1277-49a5-a4b3-f692850fd9d7',
    'identification_number': 94120676,
    'item_consumption_1': 1996,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3019.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3ebd4b19-50a6-41fa-b4ed-7b95f905994a',
    'identification_number': 19312326,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '39ff208e-61af-4eed-b41e-306bfeedeacf',
    'identification_number': 92324924,
    'item_consumption_1': 1358,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 529.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '589124ce-f4f9-4915-8467-7fa142dd6756',
    'identification_number': 104480050,
    'item_consumption_1': 9075,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3377.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba3ee39c-a459-4be6-b1f7-3cf93462bda3',
    'identification_number': 22935940,
    'item_consumption_1': 51.14,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1487.44,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0e61db5e-345e-4f48-a6d3-27681a209d4a',
    'identification_number': 46364013,
    'item_consumption_1': 18336,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6823.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9be2a6fc-f423-4052-9f01-e42e189b39e4',
    'identification_number': 6350992,
    'item_consumption_1': 754,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 319.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce96bef0-eb96-4153-9022-9c536a08b20a',
    'identification_number': 9214810,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 55.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5fac5649-4da7-4dd8-8877-8281a5aee3f3',
    'identification_number': 105202290,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '75b2f950-640b-4d01-9187-791e0be67041',
    'identification_number': 36385352,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-691.59",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '09e8fadf-22cb-40a0-a542-40e98d2a78ee',
    'identification_number': 16307836,
    'item_consumption_1': 2802,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 357.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4259b0b-94b5-46d4-937a-fa9981fb67f5',
    'identification_number': 93577842,
    'item_consumption_1': 369,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 156.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '90a058db-085c-4c70-b30a-09e1cfe5802a',
    'identification_number': 28034422,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 56.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ad3cabca-6bca-4a1c-8ee2-279ffffe1445',
    'identification_number': 70082286,
    'item_consumption_1': 3162,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1234.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1508971b-a22d-455d-ae28-1e0c37e94a35',
    'identification_number': 106144480,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 77.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4df994c6-d4a6-420a-81ab-24cc98c1c5df',
    'identification_number': 45602387,
    'item_consumption_1': 984,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 574.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1bbb7629-43b8-4640-801b-9ebef55be2e5',
    'identification_number': 59485469,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ee949837-f699-480e-a50f-04fffd0926d3',
    'identification_number': 103367268,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbee26c4-b6e2-4bfd-bff7-406359fe7fb8',
    'identification_number': 16020,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'Retenção I.R.F.',
    'item_total_value_1': "'-8.95",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '23ae3fde-2fc9-4c3c-bffe-eff2f7a738d8',
    'identification_number': 89442601,
    'item_consumption_1': 391,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 166.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6191e33-f512-4ac3-8b1f-b3d8eba9ff07',
    'identification_number': 13921789,
    'item_consumption_1': 4090,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1733.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7b2a7f18-8a15-46bf-b4df-4df0a3815a4d',
    'identification_number': 96491353,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.17,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd30bee05-8750-4836-b76e-ab047ae57f20',
    'identification_number': 14562545,
    'item_consumption_1': 9.24,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 432.72,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4bdc7729-5b56-4ca2-b3ee-0b61d42fabef',
    'identification_number': 39402576,
    'item_consumption_1': 901,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 351.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b86cc638-4781-4d6b-ab77-2eb84657820e',
    'identification_number': 32629095,
    'item_consumption_1': 265533,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 33891.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6d1e3150-a26a-449a-825f-2c69bed3fb7f',
    'identification_number': 20024142,
    'item_consumption_1': 2250,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 955.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '61e10535-c72f-4b79-bd53-29896c8420c8',
    'identification_number': 18462154,
    'item_consumption_1': 2303,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 900.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6733d95c-561d-40cc-8a8b-e298e0ff373a',
    'identification_number': 13924605,
    'item_consumption_1': 287,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 434.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '105223ec-3336-4a10-beaa-504755ca64c6',
    'identification_number': 947580121,
    'item_consumption_1': 1874.93,
    'item_description_1': 'Energia Reativa Exced em KWh EPonta',
    'item_total_value_1': 774.97,
    'reference_month': '11/2023',
    'referente': 'Energia reativa excedente',
  },
  {
    'uuid_items': '37a3fd1c-0438-45a8-afed-b777e18aaa3f',
    'identification_number': 5685885,
    'item_consumption_1': 2607,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1017.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '917b72d6-a3ce-42c8-89f3-ee222c5e8353',
    'identification_number': 100797113,
    'item_consumption_1': 12114,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1546.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '59bcee3f-a577-4868-9b0a-576090999f7c',
    'identification_number': 75671387,
    'item_consumption_1': 417,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 176.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '234c2da8-0d83-4814-bacf-6e716b859a45',
    'identification_number': 37328867,
    'item_consumption_1': 6115,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2386.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '04c8b678-1405-4e00-97a2-82d629857f7a',
    'identification_number': 45501157,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 103.42,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5d221b74-658e-4988-b11e-4181bd9ac622',
    'identification_number': 102131791,
    'item_consumption_1': 2789,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1181.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '175131ac-bd26-421b-b625-3fd748654b0d',
    'identification_number': 110227662,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b6112bf-cd09-45d1-94b8-e0e340bd62c0',
    'identification_number': 103157980,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2a5f9eda-d01e-40f4-a954-05c02bd5af44',
    'identification_number': 43061842,
    'item_consumption_1': 2168,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 276.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b8d3115-219b-4031-aaa0-3e222da7d284',
    'identification_number': 92947026,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.07,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2397875d-6676-4a51-bdd4-4f848e39a719',
    'identification_number': 99540525,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '49b3664f-9715-4024-baba-a77ed94110fb',
    'identification_number': 14562553,
    'item_consumption_1': 14.61,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 424.93,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8df6d05c-54b6-4872-b241-d75ef2c62879',
    'identification_number': 99971852,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 90.66,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f3c9ef2d-c865-4236-bb75-f4a1ea9f7d1a',
    'identification_number': 56334044,
    'item_consumption_1': 14547,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1856.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c5bf93a-a307-40fc-8866-eb783315f735',
    'identification_number': 15688976,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 115.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2494ae90-185f-43dc-baa5-fe1157bcc31e',
    'identification_number': 55524567,
    'item_consumption_1': 6108,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 779.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f68a737f-8f53-4e38-a0a5-09a2f566dfcf',
    'identification_number': 110920813,
    'item_consumption_1': 497,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 211.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db58a841-7a67-4408-855e-7f1f35344699',
    'identification_number': 103124098,
    'item_consumption_1': 2452,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3709.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12c4551a-cace-47b2-a8b9-f43a213de6f0',
    'identification_number': 69617244,
    'item_consumption_1': 6898,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2691.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e141a2c1-242f-432d-aba4-9aeaf32ff894',
    'identification_number': 41486544,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 93.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f0301a36-656d-4c72-957a-133eda6ce160',
    'identification_number': 46424342,
    'item_consumption_1': 752,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 318.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6ed7d47d-4b7e-4e30-886e-a8dc283883a1',
    'identification_number': 98831968,
    'item_consumption_1': 15887,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5912.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f932ed11-2f29-4241-8075-7e73c71497ec',
    'identification_number': 19272170,
    'item_consumption_1': 396,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 167.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b52b816-5547-4b75-aa16-9657a2a56f6f',
    'identification_number': 101123256,
    'item_consumption_1': 4419,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1877.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bf6ac36c-c580-439e-9ce4-b102d55cb195',
    'identification_number': 35846445,
    'item_consumption_1': 361,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 153.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '221ae924-968e-4cb3-a5cd-b8a0ad51201b',
    'identification_number': 70556997,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '6b3e171c-b4c2-48ed-92fb-62540c518acc',
    'identification_number': 58734210,
    'item_consumption_1': 10857,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4040.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bdf32713-8736-44f2-b1bf-cba5b986ab22',
    'identification_number': 58238077,
    'item_consumption_1': 178,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 69.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1594eabf-ddab-4a02-9731-fb99ab07d8bb',
    'identification_number': 14859424,
    'item_consumption_1': 21845,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2788.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fdb49e40-f637-4ca0-8c53-df8e75a2a85b',
    'identification_number': 65485416,
    'item_consumption_1': 4.17,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 99.46,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '0230109a-50eb-4c16-8cdd-c5706fa90c10',
    'identification_number': 71113614,
    'item_consumption_1': 598,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 254.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '246a3e3b-ed6b-4c5d-a300-a572fb73df6c',
    'identification_number': 40848302,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '73f20c87-a690-4acc-b05f-25d504f6f150',
    'identification_number': 16121490,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8ce5636f-349a-4374-8da2-6fa419a7686a',
    'identification_number': 14862336,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 47.32,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c9e801db-7880-46fa-85f3-c22c993e8ca7',
    'identification_number': 35000430,
    'item_consumption_1': 57.85,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1682.62,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6d1a8430-64e5-4a2c-bbf6-01f591504bb7',
    'identification_number': 103177477,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 60.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8419aec9-c963-4b5f-95b2-6eff9102fec2',
    'identification_number': 96520361,
    'item_consumption_1': 17885,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 7583.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '518b48c9-7d70-4965-849f-a82a8800bba0',
    'identification_number': 106382373,
    'item_consumption_1': 6364,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3716.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc8255a1-5ec9-4a1c-ae32-5c349b88685c',
    'identification_number': 93205589,
    'item_consumption_1': 3227,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1200.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '262a13cf-8f61-4d38-8e30-8b193849ae42',
    'identification_number': 43300154,
    'item_consumption_1': 1710,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 666.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7daad6e8-72b3-45f6-9865-45b5ab485e2d',
    'identification_number': 66423759,
    'item_consumption_1': 205,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 86.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '872684c5-00c6-4b33-afbe-b4292ea9f5f1',
    'identification_number': 84930756,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'eaaa4fc1-8608-4da4-a4bd-e3f8d610ab7f',
    'identification_number': 87554968,
    'item_consumption_1': 1220,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 475.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3454e9e1-7487-4720-8ecf-dd6518ce1ff8',
    'identification_number': 92096115,
    'item_consumption_1': 57801,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 21510.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1af35f6f-f400-4f97-93b5-5b37b9cb730f',
    'identification_number': 17667089,
    'item_consumption_1': 1761,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 745.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e5bf745-d02c-4035-a5ab-49f0d203d5c0',
    'identification_number': 81777302,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6450.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a1c8a788-f4ff-49d8-bb86-2c6f05f9eb8e',
    'identification_number': 31645313,
    'item_consumption_1': 0.7,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 16.72,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '34df2df5-5f70-420b-abbf-07cd40f11956',
    'identification_number': 36896918,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 70.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '93a7c0be-9a7f-4cf7-88b3-843978e616d8',
    'identification_number': 108747883,
    'item_consumption_1': 3201,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1248.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21186e0d-3fb7-4e78-8cb8-a86c419a614a',
    'identification_number': 92912290,
    'item_consumption_1': 5500,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2152.01,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'cc2b0b6d-c363-4af2-9eb8-2a43419e55da',
    'identification_number': 109594916,
    'item_consumption_1': 1314,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 767.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '239c46ee-a833-4060-a902-a523667e0c95',
    'identification_number': 80647588,
    'item_consumption_1': 1541,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 900,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aae6d77f-c91c-4b78-9e4e-3466a6f9ecbd',
    'identification_number': 31690955,
    'item_consumption_1': 894,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 379.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6df71ba4-1160-4622-98f1-3e98f780f540',
    'identification_number': 42959128,
    'item_consumption_1': 2654,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1034.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fbb70c8a-423b-407f-9702-d5470788e976',
    'identification_number': 73901857,
    'item_consumption_1': 2080,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 880.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'feb752c9-87cd-47b2-b085-04535558c64f',
    'identification_number': 104179228,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.17,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '0f49cee0-5a2c-471c-9302-62d8d7535a82',
    'identification_number': 103006672,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1a5724e0-5ca0-4c88-aa5c-2779a2cd58ce',
    'identification_number': 19229747,
    'item_consumption_1': 19.48,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 566.58,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '0547a7a6-f803-46c0-b27b-1a858cd9bf3e',
    'identification_number': 6189512,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '89bec0d0-98c9-4eb4-9dc9-9ce26c3c606e',
    'identification_number': 96197552,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba631564-affb-4255-8304-8d3a0e5e4d9b',
    'identification_number': 16105648,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a8af8522-6fbc-40ba-9d1e-e8d91a0f7611',
    'identification_number': 109446852,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3f8bd802-9986-44f0-b954-74aa7e6eea08',
    'identification_number': 12985058,
    'item_consumption_1': 500,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 195.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '265985ba-953c-4c23-9707-ab3d37d4a0e0',
    'identification_number': 38718502,
    'item_consumption_1': 17.72,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 422.65,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '7960e3a2-e91e-4104-9167-5e79b41f4c11',
    'identification_number': 8554471,
    'item_consumption_1': 1787,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 759.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '789e8140-9994-44f5-ab3c-0c105e160be4',
    'identification_number': 69969620,
    'item_consumption_1': 410,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 159.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bdcc274-f9a7-4df3-bedc-39dc36f0fc72',
    'identification_number': 11490390,
    'item_consumption_1': 1346,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 569.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1140c84a-12d7-44ac-9854-8e5bc42faf5a',
    'identification_number': 31903991,
    'item_consumption_1': 72932,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 9308.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4766010a-8917-4f7f-86b8-efff2d069ab2',
    'identification_number': 98644548,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.13,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1461ba49-14bd-493f-828f-7ffb4d83a168',
    'identification_number': 59361859,
    'item_consumption_1': 2133,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 903.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '992f561c-d5d6-429a-b592-5ceb484ca071',
    'identification_number': 12629,
    'item_consumption_1': 41,
    'item_description_1': 'DEMANDA KW',
    'item_total_value_1': 1822,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '36ffa0c6-d250-4047-9d89-6308187f875e',
    'identification_number': 42374030,
    'item_consumption_1': 1,
    'item_description_1': 'Iluminação Pública',
    'item_total_value_1': 5.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '14eb6d5c-55a8-4782-a79c-eae805252700',
    'identification_number': 68007493,
    'item_consumption_1': 4.15,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 120.68,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'a828e5e9-bd90-4c75-ad7d-6e17638c64f5',
    'identification_number': 101837720,
    'item_consumption_1': 1658,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 648.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f5baa07-3fbe-49a2-9bf1-2a39f16f180f',
    'identification_number': 51182459,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '83027a8e-07d8-4724-953b-ebaffb8b3526',
    'identification_number': 480819,
    'item_consumption_1': 4373,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 3321.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ad2ed3e8-5a34-406d-b0ac-d14ee8d62527',
    'identification_number': 40207285,
    'item_consumption_1': 7482,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 954.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8068823e-0508-4ae3-8c31-4d4cb92692a8',
    'identification_number': 36385352,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a4db704-a71c-4baf-8640-6be1c6e9a1cb',
    'identification_number': 20602510,
    'item_consumption_1': 1565,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 610.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '844b5de7-049f-4bbd-b46a-67cf1a987472',
    'identification_number': 26139111,
    'item_consumption_1': 8.41,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 200.61,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '5e2e219b-5961-40d0-b663-21acb29c64b3',
    'identification_number': 105628271,
    'item_consumption_1': 1159,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1753.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e3c4b5f-c347-498d-9873-296653cbc7dd',
    'identification_number': 40934756,
    'item_consumption_1': 11143,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 6507.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11539370-dfe0-42d1-b157-392b25753291',
    'identification_number': 5863570,
    'item_consumption_1': 1430,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 557.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b11ebda-386d-4e68-b0a2-c7c59304736f',
    'identification_number': 103772561,
    'item_consumption_1': 12882,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 19488.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7fe2ddb-e502-4478-859a-8c196d9b5bd0',
    'identification_number': 109018451,
    'item_consumption_1': 8807,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3277.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4efa6cc0-7b0c-4f0d-bfd6-284945ea299f',
    'identification_number': 99590786,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5bd877b6-9bfe-456d-8a77-91e01822bb14',
    'identification_number': 96809361,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b6c56893-3b9f-459b-884e-ae2da21439fd',
    'identification_number': 87807360,
    'item_consumption_1': "'-1.836",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2020',
    'item_total_value_1': "'-640.09",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '2299e9c4-5582-43c7-9c4e-81acdef6b9ef',
    'identification_number': 56877250,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 52.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd6072b56-6707-4fc4-8d89-8c1472b54dfd',
    'identification_number': 111081076,
    'item_consumption_1': 120,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 50.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '34fdb7b3-3dfc-46ee-a392-ba140aac062c',
    'identification_number': 47573481,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '44e02dfc-eff5-48f8-a540-7434b89e9c64',
    'identification_number': 56708890,
    'item_consumption_1': 1.98,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 115.17,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '158d9d17-01d1-435c-bfd6-2be11437062f',
    'identification_number': 29677270,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '827f94eb-4adf-4a72-b047-5550be9d982f',
    'identification_number': 75402351,
    'item_consumption_1': 10849,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1384.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dda2f4e9-0ab9-4c09-a68d-69290d9b6821',
    'identification_number': 108991741,
    'item_consumption_1': 197,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 76.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8a2a9085-d2c7-4862-837c-5f047bc37c21',
    'identification_number': 102175845,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23f922a0-3c99-4476-97de-f12e014f5f95',
    'identification_number': 70701989,
    'item_consumption_1': 5576,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2362.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7fa48ce1-e0cc-40cb-a9a6-f4d7fbafd0cc',
    'identification_number': 42223750,
    'item_consumption_1': 2217.6,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 64501,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fa3471a3-2ce0-45eb-96c0-1168e169129b',
    'identification_number': 14952688,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 185.93,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '2937cb17-0d6f-4f30-bd40-a2e4464a1762',
    'identification_number': 101677804,
    'item_consumption_1': 1020,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 398.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b5869a6a-3b44-419b-9e58-5670d0da47c2',
    'identification_number': 65331389,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 26.23,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b62e5460-9aea-415d-a090-1ad80246da3f',
    'identification_number': 60065230,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b1e9bf8b-cd27-4b23-865e-6ddab4037863',
    'identification_number': 36525448,
    'item_consumption_1': 8170,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3194.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e58e4ae-689c-4672-97b9-6653113caf4a',
    'identification_number': 49891294,
    'item_consumption_1': 4899,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 625.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efa4699c-eb70-4ae9-85fa-f5995cee38b9',
    'identification_number': 76636291,
    'item_consumption_1': 16.52,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 773.66,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'ec5160af-1384-4e4c-8355-acf6c6ae5f3e',
    'identification_number': 110994906,
    'item_consumption_1': 2311,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 980.22,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6bf6921c-0308-4e71-be40-36a9646e410d',
    'identification_number': 17355265,
    'item_consumption_1': 1,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 107.78,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e8e35bb2-e21b-444c-b879-4a1e31c920f3',
    'identification_number': 91392454,
    'item_consumption_1': 90.52,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 2632.84,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dc591ae4-498f-4b41-b516-f51483364ba1',
    'identification_number': 23647841,
    'item_consumption_1': 16252,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 6906.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6ec77f0-a633-475c-8701-617d569450c0',
    'identification_number': 75597667,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd53dd709-dd80-49f3-ab18-d06a94df4a69',
    'identification_number': 103033890,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 19.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ed599968-0376-4ba7-b515-7664238720f3',
    'identification_number': 20830807,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5b4a5c89-4390-4491-882f-2da8e7f8dfae',
    'identification_number': 16103882,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '56822d92-881b-45c7-bc31-3ab73bf37d81',
    'identification_number': 109993217,
    'item_consumption_1': 12,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 4.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'de737677-7ccc-4245-a74d-2b37945d024c',
    'identification_number': 18768695,
    'item_consumption_1': 532,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 225.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '862d5833-b502-4260-90ae-bb641a00cb69',
    'identification_number': 94384126,
    'item_consumption_1': "'-3.084",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 10/2023',
    'item_total_value_1': "'-1073.73",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '9f3b9783-c29c-4409-9242-670603da7e5c',
    'identification_number': 49951670,
    'item_consumption_1': 6.44,
    'item_description_1': 'DEMANDA USD ULTRAP F PONTA',
    'item_total_value_1': 374.61,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda',
  },
  {
    'uuid_items': '23287a04-3b83-4970-b262-a4bda2b1a3af',
    'identification_number': 13968297,
    'item_consumption_1': 220,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 85.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70949868-b64c-4eab-aa56-491164ef987d',
    'identification_number': 103346341,
    'item_consumption_1': 2148,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 839.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9b2bdd5-447d-4b1a-987f-f72236f69e1b',
    'identification_number': 69637067,
    'item_consumption_1': 3095,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1205.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b0c2e286-37a9-456d-bb5c-e459698fea7d',
    'identification_number': 72568500,
    'item_consumption_1': 1871,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 728.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f81fa43c-3f95-41dd-af00-b57110c0442b',
    'identification_number': 65625706,
    'item_consumption_1': 1658,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 704.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '101c885c-3180-488a-85f4-615db205ec50',
    'identification_number': 106228633,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 37.09,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '03e72c8b-d78e-4cc3-b3b9-7117ca41e5b0',
    'identification_number': 19451610,
    'item_consumption_1': 350373,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 44720.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6fca4b90-b6fd-44cb-8407-8bf511cf8d54',
    'identification_number': 15672239,
    'item_consumption_1': 4604,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1793.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '44e74f41-d103-4598-826b-79f89fa05119',
    'identification_number': 22974113,
    'item_consumption_1': 3742,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1589.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '33a2d4f3-e960-44b6-afb7-46faa7fd1b0e',
    'identification_number': 91809908,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-1864.0",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '942c388a-539c-4211-b1ea-cacebbe63883',
    'identification_number': 13884140,
    'item_consumption_1': 17.63,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 420.48,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'a0bca2f2-c8e4-4109-af26-b4ee57537879',
    'identification_number': 82744181,
    'item_consumption_1': 28.68,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 834.17,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b6663460-e2b8-4dc9-9f54-c7e34d0f0b28',
    'identification_number': 103551107,
    'item_consumption_1': 3443,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1463.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97bf6af5-c69c-4704-934d-69c02282cdaf',
    'identification_number': 95256164,
    'item_consumption_1': 218,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 84.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a0c55162-f17e-418b-8a1b-275db60ec27f',
    'identification_number': 56875592,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2733aedb-53c0-44d7-bdbf-0155c086faf5',
    'identification_number': 23691530,
    'item_consumption_1': 24607,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9157.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ccab5562-4b3c-47cf-b1ad-cc87c620e5ce',
    'identification_number': 19453302,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.75,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '322b11f6-50eb-40b4-954e-db614dba5d2f',
    'identification_number': 93003064,
    'item_consumption_1': 8912,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3772.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '606410ed-3089-4920-a748-6fac3d1ca7f7',
    'identification_number': 15871282,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '670d9403-d33f-4337-909f-34bb32d98b64',
    'identification_number': 46421939,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40.7,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '843e19c6-1646-456e-abf6-77d21dac9a62',
    'identification_number': 46425470,
    'item_consumption_1': 3481,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1474.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a6f36244-5b0b-4ff8-af54-b1bcacf5b7de',
    'identification_number': 89340639,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '452c7c7d-9153-4dad-bda8-c23e9ecf2e38',
    'identification_number': 30071089,
    'item_consumption_1': 6710,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2850.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '67fd7446-c32d-43ae-ad28-201b75161f36',
    'identification_number': 59144017,
    'item_consumption_1': 2309,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1348.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b86fa4e7-f8fb-43f0-9b65-2506f3123d16',
    'identification_number': 14859424,
    'item_consumption_1': 10.65,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 619.51,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5111326a-de89-4502-ac60-b6700e39dea6',
    'identification_number': 99344173,
    'item_consumption_1': 13239,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5156.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd45f6a1f-0e83-4822-96a8-7a70e26db3be',
    'identification_number': 31996841,
    'item_consumption_1': 2490,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 971.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da8a6a16-a874-4a3d-b2be-db39538ea551',
    'identification_number': 7676760,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4186766c-a0fd-4b13-850e-b7d9148abf7f',
    'identification_number': 56812477,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2755d9ac-48c1-424d-bfa4-dd5025b707cd',
    'identification_number': 14039362,
    'item_consumption_1': 7.28,
    'item_description_1': 'DEMANDA USD ULTRAP',
    'item_total_value_1': 423.47,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '87b44c81-7238-43b2-ab79-5727b895b568',
    'identification_number': 106682199,
    'item_consumption_1': 547,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 212.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '621f2a3c-9b39-47fd-8aa8-f27823461558',
    'identification_number': 40720829,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 11.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd33f8d94-e1dc-4372-ab31-89f1cd007950',
    'identification_number': 15924866,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 89.3,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'be5aab9f-ec67-49dc-955a-1e56fe702349',
    'identification_number': 20587147,
    'item_consumption_1': 16.74,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 486.89,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'f0fb9851-dd29-4bba-b4d8-dad4c23dbd0c',
    'identification_number': 105619990,
    'item_consumption_1': 260,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 101.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '42f38f22-dead-40f6-bde4-c16e06cef9b2',
    'identification_number': 101762038,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 3.26,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'ad4a1c15-236b-4657-b663-b7d1bd35d941',
    'identification_number': 27112101,
    'item_consumption_1': 2557,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1493.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '242a5c3e-61f7-4e75-bff6-286841be552e',
    'identification_number': 93009470,
    'item_consumption_1': 3864,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1437.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eeca3b9b-5550-41bb-9b5c-29b7378dc851',
    'identification_number': 92529909,
    'item_consumption_1': 1473,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 575.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efc1535b-9071-4a8a-8a36-9daccd6e0ca6',
    'identification_number': 32153430,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b3e6769c-fbf1-40b9-a103-d8fdc3ab66e9',
    'identification_number': 20587163,
    'item_consumption_1': 67.95,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 1620.66,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'cadcc6dd-1532-4eed-8882-e3b9130873e0',
    'identification_number': 104614285,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 21868.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4c6758a-89dc-4ec0-b0b7-a113d2cc03c3',
    'identification_number': 16105133,
    'item_consumption_1': 50,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 37.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '198b7006-19a9-4e3b-ac89-ead4e9107a50',
    'identification_number': 82211701,
    'item_consumption_1': 566,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 240,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd50b326d-1614-4b69-a2d6-90d087b86e1e',
    'identification_number': 91360250,
    'item_consumption_1': 32.66,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 949.93,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6f10dde1-8645-4d86-8918-8cf812836a33',
    'identification_number': 11468319,
    'item_consumption_1': 4249,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1659.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f235e06c-b6f5-4444-b487-1e2e5d604435',
    'identification_number': 39214800,
    'item_consumption_1': 581,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 878.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56a7a2c4-1de9-4838-828c-0463fbdf25c8',
    'identification_number': 101533900,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6f4d933e-ae44-4690-9429-34a2ccdf8ba9',
    'identification_number': 46023429,
    'item_consumption_1': 820,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 319.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '120cde9a-1412-44ca-a78f-8177ef9b2af8',
    'identification_number': 58734899,
    'item_consumption_1': 21.47,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 1005.45,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'cabfdcb4-ca5b-4152-add4-9a054b262b62',
    'identification_number': 105186520,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3798.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dda3f862-cfb8-48b0-903d-f75a104057e2',
    'identification_number': 58745890,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5756380-52a8-47dc-a738-07392b2a6143',
    'identification_number': 19452934,
    'item_consumption_1': 3320.1,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 96568.27,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'adeefafb-2f35-4641-99f7-3e8fb23bf99e',
    'identification_number': 105277002,
    'item_consumption_1': 31132,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3973.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cb393105-1c5d-4849-aa49-41530a21aad2',
    'identification_number': 48432130,
    'item_consumption_1': 40922,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 15229,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d13c644-6395-43ad-86f6-09c2a55a6aff',
    'identification_number': 75753758,
    'item_consumption_1': 0.57,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 13.59,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '3ce44e33-2709-4687-a4ae-317d0ad12faa',
    'identification_number': 71798595,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 101.92,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f78752cc-dbd7-4697-a66b-eff423c09e0c',
    'identification_number': 46565256,
    'item_consumption_1': 2342,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 995.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bcbcf76-9c18-44cd-ae50-a27d86b33513',
    'identification_number': 73901610,
    'item_consumption_1': 3518,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1376.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a393ad8f-24ee-421d-979e-be13bebfd8db',
    'identification_number': 59070960,
    'item_consumption_1': 25.51,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1456.86,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '436481aa-f61a-4106-8630-2235b4dbd560',
    'identification_number': 40709140,
    'item_consumption_1': 117.88,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 3428.63,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '3b084c0e-035c-4f5d-bd48-77b15f9077ea',
    'identification_number': 37708678,
    'item_consumption_1': "'-20.0",
    'item_description_1': 'ENERGIA INJETADA TE 11/2023',
    'item_total_value_1': "'-6.38",
    'reference_month': '11/2023',
    'referente': 'Compensação crédito energia',
  },
  {
    'uuid_items': 'b6b1fa79-2dd5-450a-a1e0-e661e5ec1ace',
    'identification_number': 106129864,
    'item_consumption_1': 652,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 254.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9bd99c25-7888-4a5f-a73b-fb7a6b8f85d4',
    'identification_number': 39196461,
    'item_consumption_1': 19945,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 7791.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e103413e-e778-4911-939c-b5b79ddd725e',
    'identification_number': 17247799,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 105.03,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'db9acefe-bd65-48d7-9e2e-735a90dafe89',
    'identification_number': 20515553,
    'item_consumption_1': 4192,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1781.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '732f512a-1898-4b87-88e8-c629c0bf243c',
    'identification_number': 70128235,
    'item_consumption_1': 652,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 380.79,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4bac9ee7-e159-4f66-ae26-b61b87759562',
    'identification_number': 14071240,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 16.98,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f8369440-8285-4c0c-a710-4de2a9875fa4',
    'identification_number': 18001050,
    'item_consumption_1': 3586,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1400,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '959821de-60a3-4be9-a947-8d8b33afa5b5',
    'identification_number': 111295327,
    'item_consumption_1': 259,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 101.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '829acc22-4820-47d8-8236-505e1c21428d',
    'identification_number': 34459715,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-105.87",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '27463054-12dd-4e23-bb9e-3c603bf660f1',
    'identification_number': 110323912,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d666a2d-3f48-4e84-a15e-ef31185bd87a',
    'identification_number': 59101580,
    'item_consumption_1': 676,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1022.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94b56a8b-665b-47ac-8330-ce29ea6a597f',
    'identification_number': 43338739,
    'item_consumption_1': 551,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 833.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3740df93-ed70-40bd-9715-37cf1991f9a8',
    'identification_number': 9039694,
    'item_consumption_1': 3501,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1364.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82bd23cf-76b9-4931-8e30-adfb5719a9f6',
    'identification_number': 109782909,
    'item_consumption_1': 1.42,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 41.29,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '4142fb7a-80e0-4bd9-8430-38010906a4be',
    'identification_number': 110483774,
    'item_consumption_1': 288,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 121.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1169cc6-ee5f-4178-9003-8c84e5befeef',
    'identification_number': 52282040,
    'item_consumption_1': 353,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 137.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '79cb763c-1f98-4cfe-bb48-22ddb1b7df8f',
    'identification_number': 107721538,
    'item_consumption_1': 3284,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1280.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a648fccd-d2fa-4a80-91f7-6e8fce530087',
    'identification_number': 87412411,
    'item_consumption_1': 4166,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1767.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d8f6b7e-aab3-419e-a907-7a92a246c652',
    'identification_number': 28528646,
    'item_consumption_1': 5075,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 7677.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98609803-d7a1-4054-9f73-5f4b19bb05e7',
    'identification_number': 98752480,
    'item_consumption_1': 959,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 406.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3c59143e-0343-4bfb-a5ab-77106acd8bc4',
    'identification_number': 86489984,
    'item_consumption_1': 746,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 291.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6c0d711-324f-41fd-837a-3ededdcca0d0',
    'identification_number': 30199824,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c9c0d12e-0066-401a-b583-959dda53c00a',
    'identification_number': 9189939,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 144.39,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fd348664-a035-473a-a28f-8bb08432a922',
    'identification_number': 102172803,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 30.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '958af6ba-7f3a-401d-9baf-9fde4e4a86f6',
    'identification_number': 6924999,
    'item_consumption_1': 295,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3fd325e2-3651-42bd-8273-4bec996d03df',
    'identification_number': 64724689,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 2.43,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '40d4ea06-1d7d-4ca1-b24f-0cae710a8a29',
    'identification_number': 108863026,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11649d66-d43e-429d-ace6-0ed6710362a0',
    'identification_number': 77204816,
    'item_consumption_1': 1,
    'item_description_1': 'SALDO A DEVOLVER',
    'item_total_value_1': 13.37,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': '41a79144-a330-4c79-b6c0-271793526076',
    'identification_number': 71031120,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 210.54,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b6fd08b2-475d-462a-ba38-760651084735',
    'identification_number': 37911252,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b2cddccd-178f-4f6e-8320-be212b497d27',
    'identification_number': 56168748,
    'item_consumption_1': 1348,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 525.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd511a37c-3659-4008-9c03-57d3284466e2',
    'identification_number': 110067266,
    'item_consumption_1': 180,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 70.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b35f19f6-4b9f-440b-9dda-edaf364d20c7',
    'identification_number': 56877021,
    'item_consumption_1': 1126,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1703.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9dfa5255-4ced-4e27-bcf0-8469a03e2901',
    'identification_number': 40768864,
    'item_consumption_1': 3997,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1692.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '16d68ea3-14ce-45d1-9f27-ec45dd629657',
    'identification_number': 75299372,
    'item_consumption_1': 26.17,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 761.15,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e24c73e4-89ca-422c-b8de-8ca43e1746ed',
    'identification_number': 13372688,
    'item_consumption_1': 1138,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1721.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4152193f-1e4a-4d67-ae39-1a4fef1af45c',
    'identification_number': 17353173,
    'item_consumption_1': 1322,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 560.57,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd66d5d21-597c-47a8-9bf7-d958b616c9b2',
    'identification_number': 19622252,
    'item_consumption_1': 428,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 166.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da887d57-9277-4b65-bab7-472017a452c1',
    'identification_number': 6189512,
    'item_consumption_1': 12131,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1548.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fd41337c-6420-4355-9da5-316c2a891506',
    'identification_number': 42627117,
    'item_consumption_1': 3228,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1885.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6cfa8173-5292-472d-bea4-5e392a7e0f64',
    'identification_number': 35436778,
    'item_consumption_1': 2554,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1491.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d5d3eda-419b-43ad-b52e-6025a18eb50f',
    'identification_number': 80485774,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 6.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3f228e48-8539-498c-b45f-d44df36e49b3',
    'identification_number': 97856681,
    'item_consumption_1': 19978,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7434.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba014ac5-d170-4711-802c-1242d7670005',
    'identification_number': 100968520,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.96,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0795d008-301e-4f51-b311-6c5c8778f73c',
    'identification_number': 91075700,
    'item_consumption_1': 43807,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 16302.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b16ea258-0840-4ace-b69a-dcbcbd7f6f21',
    'identification_number': 102935165,
    'item_consumption_1': 1616,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 685.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1e282e20-8fc7-4ae3-927d-12dc74d88bb7',
    'identification_number': 18197736,
    'item_consumption_1': 2915,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1234.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '532198af-0625-4851-8c85-039fdef57f2d',
    'identification_number': 76376249,
    'item_consumption_1': 164.91,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 4796.54,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'e82893e3-415d-4e7f-9bd1-98b24f5079d4',
    'identification_number': 17740738,
    'item_consumption_1': 103,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 40.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e02bbbac-1727-4212-84fb-8118add99ad6',
    'identification_number': 20817290,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'c8301749-f7c5-479d-bd77-cd6531cc103e',
    'identification_number': 18616917,
    'item_consumption_1': 5405,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 689.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17ad1a1e-e7d3-4b77-b85c-d47b85d7b282',
    'identification_number': 38370492,
    'item_consumption_1': 53788,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 20017.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ca020647-e232-47d8-a203-6a6017cd316e',
    'identification_number': 102507864,
    'item_consumption_1': 5317,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1978.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9fca06a1-0af4-46bb-8045-40f27f99313c',
    'identification_number': 102220824,
    'item_consumption_1': 1465,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2216.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e316720c-5521-4aad-8f66-96ad10904080',
    'identification_number': 106643517,
    'item_consumption_1': 35356,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13157.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3227e9ce-7cc6-4602-86b2-df526a0ee143',
    'identification_number': 69442886,
    'item_consumption_1': 324,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 137.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '024edee7-d9e5-4ff7-bf0f-0eb4ccf1e7f7',
    'identification_number': 109447514,
    'item_consumption_1': 4363,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2548.14,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b583e8b3-ca85-4712-bd48-1d1368a65fa1',
    'identification_number': 95864792,
    'item_consumption_1': 7682,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3002.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbafe503-e277-48c3-8720-24d08134a2db',
    'identification_number': 71031120,
    'item_consumption_1': 540,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 228.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '757a6508-eb97-435b-9e29-b660f8de4c57',
    'identification_number': 99185296,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.13,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0c63e15-1b5c-47c9-a1a0-78cac6ec22c2',
    'identification_number': 3031341138,
    'item_consumption_1': 7445,
    'item_description_1': 'Consumo TE NOV/23',
    'item_total_value_1': 2220.88,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f06762d0-688d-429b-a0e5-732e8045346c',
    'identification_number': 19307098,
    'item_consumption_1': 2788,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1089.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9c1dcb2e-4db6-452f-8120-9f2c884ad555',
    'identification_number': 105302570,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '212b0dae-462b-4941-bc7d-4a8cd861a0fa',
    'identification_number': 100797075,
    'item_consumption_1': 595,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 347.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cfa5e5bf-9ae6-4efb-8724-b6e75d79147c',
    'identification_number': 92096115,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1dfde597-913c-4f97-9b13-599a25c8c0a1',
    'identification_number': 29808235,
    'item_consumption_1': 1533,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 597.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '400d59a4-e5c5-4c02-8ca3-70ad5ec3195d',
    'identification_number': 103363165,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7ad9870c-66e5-4d38-a0b7-e1628a7bdb6b',
    'identification_number': 58696172,
    'item_consumption_1': 1277,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 162.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e23b5ae-b1d2-44a2-a8e6-890c65020c2b',
    'identification_number': 75402351,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'be2be537-9575-4035-909e-8580690f4d48',
    'identification_number': 32140215,
    'item_consumption_1': 2303,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1345.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b8c7531b-fece-47bb-bf40-6ce01363f223',
    'identification_number': 68085559,
    'item_consumption_1': 214.61,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 6242.13,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '923352b1-7fed-4cce-8a3d-564887fd649d',
    'identification_number': 102512426,
    'item_consumption_1': 490,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 741.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10514718-7f9c-483b-9f4f-1e0268c24bdc',
    'identification_number': 19450672,
    'item_consumption_1': 106,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 41.46,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'e45da8c8-6219-4196-9ecc-9461ccc6c9dd',
    'identification_number': 40435288,
    'item_consumption_1': 29078,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3711.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8d6c73b9-d013-4ed7-87b1-2c4a496e8a11',
    'identification_number': 90604679,
    'item_consumption_1': 56.28,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1636.93,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '1e3122e6-08ba-42d1-99a6-17b8ef7cd0cf',
    'identification_number': 21851336,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 8.47,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f95d45a1-208b-494c-b44d-527b4ac6ef22',
    'identification_number': 14952688,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 89.93,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd863af94-88c5-4fe7-9b5e-ae3de2c0032e',
    'identification_number': 59438029,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e012a4a9-238c-40fb-9f04-ed07b13a825d',
    'identification_number': 74953974,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.57,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3cf7ef56-78db-4664-be3e-8f97c7949b05',
    'identification_number': 37705342,
    'item_consumption_1': 1948,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 760.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fad029b-4e9c-4418-b69e-2ea4ebd0e3b3',
    'identification_number': 111159695,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c6109f7-5cc8-4172-aaad-935fc21721af',
    'identification_number': 75687267,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 7.01,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0eed3c59-8e0a-4262-8a88-64e8e130677c',
    'identification_number': 47572884,
    'item_consumption_1': 1334,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 566.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd547321b-2467-4205-8541-65d8c45ed799',
    'identification_number': 104455250,
    'item_consumption_1': 30087,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12776.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd023209d-b1c8-4b08-899f-94d3668d97ba',
    'identification_number': 59850817,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b380ec42-36fd-4d46-99ef-9d4a8870ca06',
    'identification_number': 108106128,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'ede52511-ee96-4d1e-a549-88e06484608f',
    'identification_number': 35718064,
    'item_consumption_1': 1552,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 606.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cee5d5f8-573b-4281-a801-fbf4d92a7f39',
    'identification_number': 96446498,
    'item_consumption_1': 3772,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1602.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a578b7fc-275e-4f2f-a53d-90c2cd0fcfda',
    'identification_number': 99666995,
    'item_consumption_1': 2566,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1088.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cf6b2bc1-cbd0-4e7c-b28d-aacf418c8055',
    'identification_number': 55624146,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd52b56c3-cd62-4526-a245-22f8344042ef',
    'identification_number': 14354306,
    'item_consumption_1': 18.05,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 524.98,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '62ba62cd-aa7a-4f2c-b3a1-34ec7c4bbd09',
    'identification_number': 43154549,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 11.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd2c00a8e-8655-44c0-8dc4-26b0e8b8ed27',
    'identification_number': 95555927,
    'item_consumption_1': 3831,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1495.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ace9fe6d-604a-4f9f-a9e9-55f30e602954',
    'identification_number': 8086451,
    'item_consumption_1': 2711,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 4101.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b64e00a2-7178-41c1-99f5-307f5a0d5a71',
    'identification_number': 56886314,
    'item_consumption_1': 30.44,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 885.36,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fed4b426-48bc-4262-89c6-d8563c70fee1',
    'identification_number': 68475594,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 54.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '68562621-e4b7-4781-87eb-07aea1655e13',
    'identification_number': 16246837,
    'item_consumption_1': 20,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '339b0764-8e01-49ea-90b8-a66a624b97cd',
    'identification_number': 75695006,
    'item_consumption_1': 545,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 212.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4acd644b-aec0-45af-999b-85d061dbc382',
    'identification_number': 39961320,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '98b60914-6cdf-4010-b466-f61dc35f50f5',
    'identification_number': 12977489,
    'item_consumption_1': 3.52,
    'item_description_1': 'DEMANDA USD ULTRAP PONTA',
    'item_total_value_1': 402.05,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '54e8ae46-7828-4037-b6b4-a6281221c14b',
    'identification_number': 95566651,
    'item_consumption_1': 2.03,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 48.42,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '9e413aca-89ec-42c1-a7f5-4ee5049f65d3',
    'identification_number': 63195666,
    'item_consumption_1': 6689,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2833.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '044c148d-2000-44c8-8d89-4e767cf09327',
    'identification_number': 40178579,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.37,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0dc0f82f-2967-4dda-b6cc-c6dfb26755d9',
    'identification_number': 44259867,
    'item_consumption_1': 3388,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1434.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc219f73-624c-4a3b-ae29-ca29b064b28d',
    'identification_number': 39899403,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 102.49,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2df4e1a5-2899-478e-8088-26116cfaca59',
    'identification_number': 21252050,
    'item_consumption_1': 3191,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1246.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd958e108-b0be-449c-a3a6-2be69d4bd480',
    'identification_number': 66198666,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'dea47d74-d611-4a07-9c44-0f447f15eca3',
    'identification_number': 14043106,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 14.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6ed3355a-b5d9-4e2d-b5f2-7d29fb0b81f4',
    'identification_number': 108532348,
    'item_consumption_1': 1561,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 661.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a941aaec-ddf9-49b2-b14f-6b2b91fed355',
    'identification_number': 20305290,
    'item_consumption_1': 3743,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1458.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a5ee01b-e20c-4ee6-9372-d40114160bab',
    'identification_number': 101712626,
    'item_consumption_1': 1107,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 433.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1a6b4003-ce75-413f-8347-5d694a70945b',
    'identification_number': 110977360,
    'item_consumption_1': 39.06,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 1136.08,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b9dee752-8d20-4178-ab3d-94fc743b16c0',
    'identification_number': 14232979,
    'item_consumption_1': 217,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 328.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03c38c10-11a7-4ef5-b00e-d9078ca7b449',
    'identification_number': 97863084,
    'item_consumption_1': 389,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 151.98,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ba47e8ae-11b0-4bdc-b5f8-b01a34d0bff6',
    'identification_number': 44259867,
    'item_consumption_1': 3388,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1320.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '7e8ca3e5-7110-45b2-987b-161ef7989184',
    'identification_number': 3030784010,
    'item_consumption_1': 20316,
    'item_description_1': 'Cons Ponta TE NOV/23',
    'item_total_value_1': 9159.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7bbf7eb-b9f6-4f26-86f3-f84c1f6f3f5c',
    'identification_number': 102404070,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '9064f6dd-7839-4843-93b6-1fbcb73ce0a3',
    'identification_number': 11201541,
    'item_consumption_1': 1188,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 464.59,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd96482f4-a2c6-41f1-99da-c448a0a2c77f',
    'identification_number': 935258912,
    'item_consumption_1': 0,
    'item_description_1': 'LANCAMENTOS E SERVICOS',
    'item_total_value_1': 0,
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'd61625eb-9325-43bf-be17-efbae488f546',
    'identification_number': 18223680,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 23.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8d1ae4db-ce92-4675-8ce9-8712cfaafe7e',
    'identification_number': 57029911,
    'item_consumption_1': 37940,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14119.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8901ae16-1323-4669-9eb5-07aced5ef282',
    'identification_number': 12157406,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 239.45,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '928ebd3a-cc18-4678-bbc3-206c9c566818',
    'identification_number': 96644990,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 14.27,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '53d4b8d5-99d9-421d-890b-50b14e65b002',
    'identification_number': 5700493,
    'item_consumption_1': 5488,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2324.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'aaf8abc3-7fa8-47aa-9a91-49dbc4729c51',
    'identification_number': 109375947,
    'item_consumption_1': 258,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 100.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fc05a611-4389-4280-873e-914e8c078edd',
    'identification_number': 102512426,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4a1663df-2a7a-43f7-8139-b47a7340ce92',
    'identification_number': 11078022,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 28.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '3fff4303-09b8-4dcd-9791-b7c852036f87',
    'identification_number': 19452179,
    'item_consumption_1': 261411,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 33365.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47fb2ae4-d7ee-4e1d-892b-f258921325ce',
    'identification_number': 18398499,
    'item_consumption_1': 6719,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2627.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0bec01b6-0ce0-403b-8be0-1b58e1416686',
    'identification_number': 948827679,
    'item_consumption_1': 0,
    'item_description_1': 'VALOR PARCELADO 00/2',
    'item_total_value_1': "'-16.51",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'cc042d46-71b9-49c2-87b7-e5b053252b94',
    'identification_number': 98942468,
    'item_consumption_1': 6,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 2.34,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '92739079-5624-4a2c-b241-3536a9be4e32',
    'identification_number': 15369080,
    'item_consumption_1': 85,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 33.25,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'df12c599-47e5-43b0-b2ee-83a1d06e1c97',
    'identification_number': 19229747,
    'item_consumption_1': 4922,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1831.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '759facc9-d231-4616-99b5-016a7e4aff6d',
    'identification_number': 73564630,
    'item_consumption_1': 8680,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3680.02,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '145a5498-5834-4733-969a-a516945a5e24',
    'identification_number': 49951670,
    'item_consumption_1': 75.12,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 4290.11,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4147bcca-4152-443f-acfa-b28a2b8b1cc3',
    'identification_number': 45483779,
    'item_consumption_1': 1,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 0.38,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b68eef72-6549-451f-8ce3-66e55f938f11',
    'identification_number': 34681230,
    'item_consumption_1': 151,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 64.19,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f408c467-47b6-4f96-9cda-84836cc66caa',
    'identification_number': 12673994,
    'item_consumption_1': 0,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2604.22,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '0865f792-9426-47eb-b628-3546f072c185',
    'identification_number': 35119624,
    'item_consumption_1': 18925,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 11052.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '11fc4651-70bb-4094-b40d-e5462777e2dc',
    'identification_number': 15093093,
    'item_consumption_1': 4672,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1823.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a64a8bcb-95b7-4f3e-ae82-f335a7759f20',
    'identification_number': 11490586,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.55,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a5c37c97-4428-4fed-8ae3-b66973a8b1dc',
    'identification_number': 111482259,
    'item_consumption_1': 3923,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1661.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '66cf0724-5a1f-4008-a5ac-e8a9bca74c84',
    'identification_number': 106136712,
    'item_consumption_1': 26.66,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 635.88,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd431413a-a874-4a63-9149-fc06d42eec00',
    'identification_number': 107941589,
    'item_consumption_1': 187,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 73.16,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '2853a8e6-f3fd-4501-a8c0-463bd4cb45b2',
    'identification_number': 44219393,
    'item_consumption_1': 7268,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4244.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1fba7c5d-6e1e-4207-a6ad-6041340ca7de',
    'identification_number': 104529121,
    'item_consumption_1': 5050,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1973.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0bdc2a9b-09ad-432c-bbda-b0544591d332',
    'identification_number': 9823000,
    'item_consumption_1': 26979,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3443.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dd511d1c-285b-4e29-b6b8-1e98b05aa3f8',
    'identification_number': 51333376,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.56,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'fe4c8b72-1dee-4475-9b20-ea20957bb8e2',
    'identification_number': 47280816,
    'item_consumption_1': 3767,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 1401.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da8b227b-f34a-42cd-9c7e-38c7ef7b38c9',
    'identification_number': 99211823,
    'item_consumption_1': 22794,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 8482.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd78220ef-5f7c-46a5-81df-a354e3087c33',
    'identification_number': 102260460,
    'item_consumption_1': 74.78,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2175.02,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '437418ad-eb5f-4686-af0e-4bd57177b7af',
    'identification_number': 94525994,
    'item_consumption_1': 0.14,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 3.36,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'b02e4f57-efed-498c-83bb-22d1f2e5c3f0',
    'identification_number': 18010024,
    'item_consumption_1': 2846,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1208.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9fcfecc-2abc-40cf-a7b8-642cc3686af0',
    'identification_number': 11680148,
    'item_consumption_1': 11,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 4.3,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '537e9a44-7f17-48ef-bb4a-a7f49768a102',
    'identification_number': 38718502,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd370d55f-ea60-4f84-90db-bec1e12a5f1e',
    'identification_number': 109861035,
    'item_consumption_1': 35.22,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1024.4,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '251cd483-b880-4bf4-a991-52d90182e32d',
    'identification_number': 98831968,
    'item_consumption_1': 15887,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 2027.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a4f2368-09da-4e97-9aed-18001d12933e',
    'identification_number': 101785941,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 63.5,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '711bb0ee-f526-413e-956c-b5fbe2bbcdea',
    'identification_number': 109460847,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd117497-d315-4b1d-bb96-81e0124554c6',
    'identification_number': 105083097,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.14,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c379057d-3d34-48ab-bd88-8191e05564c1',
    'identification_number': 111833884,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae342392-1e9d-4edc-a2c7-7050708db257',
    'identification_number': 30491100,
    'item_consumption_1': 1867,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 791.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c711626a-aba9-4504-8645-0d8adc3bcc27',
    'identification_number': 33670420,
    'item_consumption_1': 10.72,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 311.79,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2159434c-dcb3-44b8-a98b-361adfac7fd2',
    'identification_number': 8866163,
    'item_consumption_1': 81,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 31.68,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '78d2a066-3d1c-4108-98f3-086c40e399fe',
    'identification_number': 11432616,
    'item_consumption_1': 10980,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4281.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cd530bcd-4c43-4884-afc6-094422f8d7b9',
    'identification_number': 109822285,
    'item_consumption_1': 28901,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 10755.43,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '51f3bf7a-80d0-47f7-96d4-bd83166d21b3',
    'identification_number': 39560368,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0f77f7f-eb0e-42b7-b015-4f38aeb71641',
    'identification_number': 29745918,
    'item_consumption_1': 25,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 9.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '646b2759-8182-4aa4-ac1b-64a969d535dd',
    'identification_number': 100187390,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '12a542d4-9ea4-45d4-b12e-e08eb3930fb6',
    'identification_number': 97427063,
    'item_consumption_1': 4341,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1843.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '60e3ea3c-0e3b-423d-ab42-80ca158df706',
    'identification_number': 8084661,
    'item_consumption_1': 10.82,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 314.69,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'dc773bdf-61bc-4c8c-a7d0-c4d2067ff666',
    'identification_number': 37998447,
    'item_consumption_1': 110.08,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 5155.08,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '5b91fa27-2eaf-4efa-b358-be913d1a4c70',
    'identification_number': 39560368,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3196abf3-16a0-41aa-86b5-8aa21ca1dbc0',
    'identification_number': 106532880,
    'item_consumption_1': 49,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 20.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '17725862-918c-4c97-bd3a-323005d66dc2',
    'identification_number': 18643582,
    'item_consumption_1': 23.51,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1342.64,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '1acca799-af37-4f9e-be5e-dcf5a9de9985',
    'identification_number': 36948209,
    'item_consumption_1': 2692,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1050.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '70f600f4-a5a2-41e5-b442-cae9aa017571',
    'identification_number': 94419990,
    'item_consumption_1': 155,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 65.7,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ddcac3e-7977-4602-bed3-060c72fb04a8',
    'identification_number': 16072022,
    'item_consumption_1': 1,
    'item_description_1': 'Contrio Iluminação Pública',
    'item_total_value_1': 25.67,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '65b656c9-6b38-4362-87ea-5609978f8732',
    'identification_number': 29836573,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 76.02,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '7b1e9654-1a6b-4ec6-9108-536a45a7fe40',
    'identification_number': 38351749,
    'item_consumption_1': 13347,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5200.25,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '46c4d316-79bf-44c3-8073-95a31c1c1825',
    'identification_number': 35766514,
    'item_consumption_1': 1236,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1869.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd0e4af50-5e8f-4412-a099-7e40a1a93bc9',
    'identification_number': 28860136,
    'item_consumption_1': 15.21,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 362.77,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'f5ded599-6da6-4eb9-af96-fa64f0ff2241',
    'identification_number': 949409303,
    'item_consumption_1': 0,
    'item_description_1': 'Contrib de Ilum Pub',
    'item_total_value_1': 103.95,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4097ceac-ccd5-4a77-8c32-1091bc585ab0',
    'identification_number': 94497451,
    'item_consumption_1': 255,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 108.26,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48da866d-2a09-482c-a62f-89026ee475b1',
    'identification_number': 16307828,
    'item_consumption_1': 39.6,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 2261.56,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '6f120d00-9eb7-473e-b51f-4394c74f65a8',
    'identification_number': 43196934,
    'item_consumption_1': 821,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 348.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6641cfc4-6186-47eb-80ca-973614300f1b',
    'identification_number': 99929929,
    'item_consumption_1': "'-555.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 11/2023',
    'item_total_value_1': "'-193.22",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '47a91186-8641-45fe-825a-14e06e0e180b',
    'identification_number': 104871563,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 3.12,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '24bc87bc-458c-4a86-a5b6-017b9e49359e',
    'identification_number': 23135328,
    'item_consumption_1': 91,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 38.55,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1816df15-ed95-4eb1-9270-e182985bd180',
    'identification_number': 92140394,
    'item_consumption_1': 24,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 9.39,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b5054db1-a137-438b-8f60-615147d54035',
    'identification_number': 91863899,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0636ffad-77c5-48d7-a081-47b4d5dc63db',
    'identification_number': 47290897,
    'item_consumption_1': 2159,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 842.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8f14cfe-adcb-490d-ad78-e04b4450e167',
    'identification_number': 5679443,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 87.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '1187b90d-1ba6-4b76-a5d3-8be9f3826dc7',
    'identification_number': 11110597,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.29,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2c8282b0-4b6b-47b0-99b0-8b11a47ee371',
    'identification_number': 64167364,
    'item_consumption_1': 32938,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 12257.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e0bf995b-bb38-4291-841c-e95a5f756ca7',
    'identification_number': 108322440,
    'item_consumption_1': 891,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1347.9,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '55b7f8ca-d96d-4ced-ad50-beb8bec83a76',
    'identification_number': 35436778,
    'item_consumption_1': 21080,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7844.85,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '40bfcd76-b65e-4c8f-adf9-dcd2ca181e56',
    'identification_number': 15167640,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fa3bd13c-43ae-42be-8179-bdb5a7e80f56',
    'identification_number': 58745548,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4b042984-fd10-4ad9-8239-da7f1797fad9',
    'identification_number': 99305062,
    'item_consumption_1': 1,
    'item_description_1': 'AJUSTE CONSU ANTERIOR 017/166',
    'item_total_value_1': 1.26,
    'reference_month': '11/2023',
    'referente': 'Ajuste de consumo anterior',
  },
  {
    'uuid_items': '41ac6552-17f4-47ed-acb9-6d31d58e6eaa',
    'identification_number': 40207650,
    'item_consumption_1': 9279,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 5419.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eae108d5-7eb5-4264-8a98-56fea3ad577b',
    'identification_number': 110227417,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 16.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8df1d3ac-2d70-4fa4-893a-c5b9d41c6e6e',
    'identification_number': 16105699,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '6413c59e-00ab-460f-8a7c-f3b6408fad26',
    'identification_number': 59357053,
    'item_consumption_1': 1230,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 521.84,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '05ac03bb-c7b5-4ca1-94c6-689b9032eb40',
    'identification_number': 16222539,
    'item_consumption_1': 6099,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2592.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '48f38c71-53c4-4d9b-8175-a6ab5b6bf7f6',
    'identification_number': 9027467,
    'item_consumption_1': 8157,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3178.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '99c3b6ef-6ee3-4c5e-8183-d9a033d2d0cd',
    'identification_number': 77111311,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 88.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '0e16fe41-b15f-4d53-80d5-dc9f0c8241b4',
    'identification_number': 49314181,
    'item_consumption_1': 1236,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 524.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8175e88a-5607-4531-b5bf-28e26be43062',
    'identification_number': 95590927,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ea61a2ed-6ee5-46e6-a5e1-11f9035ad133',
    'identification_number': 38250489,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '52d49239-15d9-42bd-9506-2b4221706a45',
    'identification_number': 27054535,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 129.52,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'd23d2ade-fee1-4d14-96a2-8b5406317f50',
    'identification_number': 23554231,
    'item_consumption_1': 234,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 99.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c55d8308-bee8-4e4c-9d48-dbc9ef158f69',
    'identification_number': 43061842,
    'item_consumption_1': 0,
    'item_description_1': 'COMPARTILHAMENTO DE POSTE',
    'item_total_value_1': 209.22,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '918a8d5a-1107-4dae-92a2-b177677adf9a',
    'identification_number': 38525275,
    'item_consumption_1': 3906,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1528.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'afc4248f-9ac0-495e-b515-70b5ae5ddce4',
    'identification_number': 56527144,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-1588.58",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '358aed90-2423-4ee8-b1a6-410e0971a0e3',
    'identification_number': 95743081,
    'item_consumption_1': 368,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 156.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e9edcb68-035e-4932-bdd1-e45ca9e892f0',
    'identification_number': 32008120,
    'item_consumption_1': 2283,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 890.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5bbf4965-7ce5-4981-9f81-c6452104fd1e',
    'identification_number': 947580121,
    'item_consumption_1': 4.08,
    'item_description_1': 'Demanda Potência Não Consumida Ponta',
    'item_total_value_1': 103.03,
    'reference_month': '11/2023',
    'referente': 'Demanda não consumida',
  },
  {
    'uuid_items': '34d87396-a415-49ed-b576-0d06fb340d25',
    'identification_number': 97368415,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '763c31ed-e389-4a8b-a3a1-ae0e1433f5da',
    'identification_number': 9965394,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd6171a99-ef62-4c96-a770-4ae26213ac83',
    'identification_number': 39545377,
    'item_consumption_1': 73.2,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2129.08,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'f7180c11-0c8b-442f-ab8e-3e2369a22da0',
    'identification_number': 106354736,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 35.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '74970c75-1118-4615-aaee-1578e9c372eb',
    'identification_number': 26223422,
    'item_consumption_1': 674,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 263.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b011c3c2-ea99-475c-89f9-ff6307b702fb',
    'identification_number': 7284012,
    'item_consumption_1': "'-399.0",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 06/2023',
    'item_total_value_1': "'-127.47",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '64b74a54-022a-4298-8ce1-96c54041b86d',
    'identification_number': 60720514,
    'item_consumption_1': 38235,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 4880.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'da802416-c46f-4bfd-8613-c3a69d1dabc8',
    'identification_number': 110226534,
    'item_consumption_1': 43,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 18.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '482603e8-8323-4a78-a988-3a9d751b174e',
    'identification_number': 100428100,
    'item_consumption_1': 602,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 235.29,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9888b33b-a513-465c-a6f0-585bad5693a2',
    'identification_number': 17109833,
    'item_consumption_1': 817,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1235.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c7436203-144a-46df-8e85-4cd3033df172',
    'identification_number': 101890419,
    'item_consumption_1': 76,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 28.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '10150588-3866-431f-837f-4b7884a18392',
    'identification_number': 60720514,
    'item_consumption_1': 4041,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2360.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5cac92f0-0045-4789-9eab-dea1b0bff97f',
    'identification_number': 8138559,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd040d817-61a0-426b-ae5e-6a373277e1e9',
    'identification_number': 111268524,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 110.68,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b6a43950-8f60-4d75-b6a5-ca7102e8f56c',
    'identification_number': 13176846,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 38.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d760761-de59-4bf4-8346-077fb5ccb874',
    'identification_number': 46424520,
    'item_consumption_1': 3381,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1319.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a65d5d64-0220-4954-ab52-146adb0212c1',
    'identification_number': 97404713,
    'item_consumption_1': 1001,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 391.21,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '452f30b8-b493-491a-a4f7-de54f7243242',
    'identification_number': 107742497,
    'item_consumption_1': 632,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 268.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1a8d2ce-3856-4961-982f-b42107487621',
    'identification_number': 13870068,
    'item_consumption_1': 2131,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 905.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0f6213df-af4c-493e-9abc-a4192b41317d',
    'identification_number': 98024809,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.46,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f2098c56-3a9b-41a7-b8e1-7bbc9c51ac6b',
    'identification_number': 94540209,
    'item_consumption_1': 162,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 68.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1c9c3ba-2afc-47c8-abb1-a39a37b72f2c',
    'identification_number': 45907404,
    'item_consumption_1': 518,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 302.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '135ee190-8d65-4e56-85b1-410032a9a523',
    'identification_number': 6227627,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 40,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8d802786-50e0-4cac-bb92-d20d2b9b3da4',
    'identification_number': 110920988,
    'item_consumption_1': 437,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 170.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dbbcd6c1-03bb-43a2-8eba-d016786af7b2',
    'identification_number': 96626801,
    'item_consumption_1': 500,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 195.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '434cef8a-4fdf-4931-94f8-2ad4b60e4bcb',
    'identification_number': 94419990,
    'item_consumption_1': 155,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 60.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13d826ea-9ec3-48de-b35b-5296d0ccf680',
    'identification_number': 105089060,
    'item_consumption_1': 18537,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 6898.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3126ad28-db09-451d-aa8f-f399a8f831df',
    'identification_number': 41314352,
    'item_consumption_1': 30477,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12947.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9a1ff97d-02fc-4e4c-b9c9-29d9b9b383c4',
    'identification_number': 19452900,
    'item_consumption_1': 3945,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 1543.57,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '92019851-fdeb-4475-b294-fe2e40e889b6',
    'identification_number': 105059552,
    'item_consumption_1': 1217,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 474.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8f207e1d-f213-4573-b195-fccc3d8568ed',
    'identification_number': 28671139,
    'item_consumption_1': 8120,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 3021.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '76f6d4da-c1ff-4cb3-9aaf-cde6a0ea5014',
    'identification_number': 69720185,
    'item_consumption_1': 6412,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2717.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'db3037a8-6d39-4620-883a-1c736ff324ca',
    'identification_number': 77542720,
    'item_consumption_1': 7974,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3110.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '47923858-17a6-4b25-8e61-7290a5d7c21d',
    'identification_number': 68559127,
    'item_consumption_1': 3430,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1453.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '56dd3339-71fc-469b-996f-ed54717ec9e1',
    'identification_number': 66557402,
    'item_consumption_1': 24.35,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 708.23,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '98bd652d-e6b5-48a1-b52d-84eb53086c70',
    'identification_number': 92850294,
    'item_consumption_1': 1279,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 498.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e6c355a1-21dd-4df9-ba1e-06da0d355cf5',
    'identification_number': 101354827,
    'item_consumption_1': 86,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 36.36,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '74c7cd12-70bb-4d92-b207-07d248e3107a',
    'identification_number': 37328549,
    'item_consumption_1': 7139,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2786.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '144e7805-3d6f-410c-ad4d-e95e29ddd718',
    'identification_number': 55193994,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 49.6,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e799522b-9bbb-47f4-b7f0-915737ed71f7',
    'identification_number': 36149730,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.51,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8e2dde8d-eb37-4def-aa51-fe61d600d036',
    'identification_number': 56875592,
    'item_consumption_1': 1335,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 779.68,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '78f02ebf-5567-4359-a0f1-fddb8ea844ca',
    'identification_number': 44001479,
    'item_consumption_1': 0,
    'item_description_1': 'RELIGACAO PROGRAMADA',
    'item_total_value_1': 257.62,
    'reference_month': '11/2023',
    'referente': 'Religação',
  },
  {
    'uuid_items': '2c3df894-def0-48c2-8161-b98e06567203',
    'identification_number': 56544545,
    'item_consumption_1': 34.63,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1977.7,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'be3fedc4-ac90-4c8c-b19b-64e29576b85c',
    'identification_number': 108106128,
    'item_consumption_1': 8,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8864788b-6831-4afd-80b8-d8ffdfc39ab0',
    'identification_number': 30734541,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 15.2,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '67808065-8b4c-4a22-b94c-09006130a0cd',
    'identification_number': 923318215,
    'item_consumption_1': 0,
    'item_description_1': 'Custo de Disponibilidade LANÇAMENTOS E SERVICOS',
    'item_total_value_1': 43.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'abb2e105-cb28-46d1-9363-9a42119c60f1',
    'identification_number': 33162557,
    'item_consumption_1': 898,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 1358.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a3a4e14a-9e04-4e5a-8de5-06ac4e351774',
    'identification_number': 44520603,
    'item_consumption_1': 15418,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 6019.27,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '068fc8d8-a4a0-4ab5-b197-e4ceefdc0cc6',
    'identification_number': 105003727,
    'item_consumption_1': 13218,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 5170.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1f09fa49-d740-4ff8-b31c-95693d477cf3',
    'identification_number': 86952790,
    'item_consumption_1': 1281,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 500.96,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b4ce7c30-c7b2-490c-8508-17bd81607cca',
    'identification_number': 60720514,
    'item_consumption_1': 38235,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14229.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bfd4e44a-51ac-48de-98aa-87b770682df8',
    'identification_number': 6579051,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-2595.74",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'c3fcfd9b-0944-409f-b9fb-5723c5936de8',
    'identification_number': 99258870,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '324f2418-7249-46e2-8828-5d7ee5fc8e55',
    'identification_number': 74755897,
    'item_consumption_1': 1334,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 779.1,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '03055ca7-cb2e-40c5-823f-f160352cd33a',
    'identification_number': 99327392,
    'item_consumption_1': 1894,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 740.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a261557e-59c0-471f-8132-2ece17e2d54c',
    'identification_number': 109993217,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 147.08,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2a3665f4-b53a-4482-a77d-16e591bd6c87',
    'identification_number': 97988804,
    'item_consumption_1': 1.99,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 47.47,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '2a941375-f8fd-406d-92e2-5ddbfda0c0f5',
    'identification_number': 97005304,
    'item_consumption_1': 9971,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1272.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0ed100f0-ace5-4031-9243-3f943ca87de6',
    'identification_number': 43875718,
    'item_consumption_1': 6277,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 3665.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4cbe6506-21b2-4da0-b165-b62901e3fccc',
    'identification_number': 18284507,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 17.71,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'f0f7ff1e-b14d-449d-bb48-c4bc4a9a3556',
    'identification_number': 6579051,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.48,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '436a1c0d-1d3e-42a3-acf7-8e53a15d0d3f',
    'identification_number': 16111567,
    'item_consumption_1': 100,
    'item_description_1': 'C DISPONIB',
    'item_total_value_1': 75.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'efd833e5-b3ed-4f55-8582-c5acb8617b77',
    'identification_number': 102404070,
    'item_consumption_1': 35.07,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1020.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '674fce42-a16e-4478-a807-def16e65afae',
    'identification_number': 19453299,
    'item_consumption_1': 22927,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 34684.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '19606904-7e1f-4aa9-a61a-88dbd2c68995',
    'identification_number': 27874850,
    'item_consumption_1': 2546,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1082.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a40cc3bc-d7ef-48ad-8f0c-02576d02ea68',
    'identification_number': 103802045,
    'item_consumption_1': 414,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 175.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ef0bf2b-d5f4-4f23-88a3-7bd562b757cd',
    'identification_number': 106612662,
    'item_consumption_1': 118126,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 15077.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '50c9c34d-a4a7-457f-be45-c391e7693896',
    'identification_number': 45478260,
    'item_consumption_1': 3.42,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 160.18,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '885a2592-179c-45ce-9aa8-002fb9798699',
    'identification_number': 100624650,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 62.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '04e66591-087a-4643-8135-e66ad114afd6',
    'identification_number': 46395040,
    'item_consumption_1': 3308,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1402.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ce5ff277-42e8-4a6b-be9c-0118ce56ccf5',
    'identification_number': 81773021,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.74,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9150ba19-36f3-4d0a-941e-5710b2b869d8',
    'identification_number': 42127564,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 65.62,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '06f5b9b9-4441-42cf-a131-534ad3b8a2f2',
    'identification_number': 39099652,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 80.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'c81c2995-4315-4905-a9de-85660165604b',
    'identification_number': 103005056,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8aeb71d9-1de3-446c-8f2f-c6dbce6350cc',
    'identification_number': 56334044,
    'item_consumption_1': 14547,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 5413.63,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d61b3e1-d72c-48c0-8d91-3f1301830812',
    'identification_number': 60092904,
    'item_consumption_1': 1065,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 621.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52f32b66-c711-4845-9729-3754d730f2df',
    'identification_number': 33124302,
    'item_consumption_1': 286,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 111.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '86b4fb81-86da-4941-95a6-b1974781a85c',
    'identification_number': 66244013,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 111.05,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'cca425e5-3e35-4682-bd9d-9dd59f80cf30',
    'identification_number': 8086451,
    'item_consumption_1': 4,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.55,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'a25060c9-40f4-45ce-b2b3-7432f96e787e',
    'identification_number': 102135541,
    'item_consumption_1': 20087,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7475.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1da046cc-5e4f-4444-af65-103c5d528222',
    'identification_number': 8859060,
    'item_consumption_1': 6880,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2687.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1cc84999-a409-4ba1-bccd-42aeed9869ee',
    'identification_number': 56194471,
    'item_consumption_1': 485,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 189.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'ae42267d-68ba-442e-83e4-e24638445869',
    'identification_number': 56334044,
    'item_consumption_1': 1753,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1023.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1668177d-1d9f-4cc4-a86f-11936b063e10',
    'identification_number': 47989050,
    'item_consumption_1': 57.85,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 3303.8,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'baf7f8d0-dccf-4c05-9198-57f58f12db3a',
    'identification_number': 94516227,
    'item_consumption_1': 910,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 354.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ae39551-c22e-4c25-91bc-0c4b66e8a4d0',
    'identification_number': 93308965,
    'item_consumption_1': "'-81.0",
    'item_description_1': 'ENERGIA INJ. PT OUC MPT TUSD 01/2',
    'item_total_value_1': "'-100.47",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '2629a328-cab5-4cc4-b835-e37cf96f3e2f',
    'identification_number': 30543860,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 1.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8ec5efaf-d3ae-4db6-b3a3-de350a161ac9',
    'identification_number': 8529205,
    'item_consumption_1': 124,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 187.58,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4eadc781-241c-45fb-ab18-7b5827557bd9',
    'identification_number': 50776029,
    'item_consumption_1': 11166,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 1425.18,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eba300a0-0a29-4f17-9710-dd657d671b16',
    'identification_number': 3032863758,
    'item_consumption_1': 3828,
    'item_description_1': 'Consumo Uso Sistema [KWh]-TUSD NOV/23',
    'item_total_value_1': 1933.49,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a2d6b99f-12ec-4347-8f9f-377d0243b8f8',
    'identification_number': 13532626,
    'item_consumption_1': 8025,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3402.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1a64868-ffa6-44b9-95d0-3db634ff4590',
    'identification_number': 42710529,
    'item_consumption_1': 253,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 98.99,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '75df3016-2c7a-4add-b9bf-5a581b892e88',
    'identification_number': 105798908,
    'item_consumption_1': 1105,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 645.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '1b36eda4-b063-4989-a0ad-79376ba7fa29',
    'identification_number': 8115788,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 39.77,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '539e6a2b-bd0c-4187-9069-879bf5e918fb',
    'identification_number': 95920625,
    'item_consumption_1': 271,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 114.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '37a4d2b0-070c-4e7b-bd4a-2aef2574c8d9',
    'identification_number': 105797634,
    'item_consumption_1': 19.87,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 577.92,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '996920cd-a8a2-4c8f-a484-c0d400722bd5',
    'identification_number': 43908764,
    'item_consumption_1': 5806,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2160.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e93f4980-d2c6-469b-bdc6-50a949c7ffd8',
    'identification_number': 19191839,
    'item_consumption_1': 1746,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 681.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '97df215c-0ae6-4ba8-b85f-2d8b641de76d',
    'identification_number': 68782624,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 45.81,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '857423cb-8ece-4e46-b350-4b018bc41c0f',
    'identification_number': 109408586,
    'item_consumption_1': 3316,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1296.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84937a25-e024-4fa0-8bc7-94decb5a9816',
    'identification_number': 89118162,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'DEVOLUCAO AJUSTE DE FATURAMENTO',
    'item_total_value_1': "'-1242.27",
    'reference_month': '11/2023',
    'referente': 'nan',
  },
  {
    'uuid_items': 'e62eceb1-767e-4417-b7fa-17034a792dc9',
    'identification_number': 70082090,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 42.47,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b54e318-e418-41b8-8442-55232cf0be69',
    'identification_number': 37781456,
    'item_consumption_1': 6791,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2876.12,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '84cb4bcd-25d9-4899-9e26-b747fef4e381',
    'identification_number': 91670942,
    'item_consumption_1': 575,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 869.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2a9067fb-f7bd-4a28-bb0e-f6a9968b24ac',
    'identification_number': 39466787,
    'item_consumption_1': 27.4,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 653.51,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '71c4cecb-ed24-46ab-a000-0347936dc30f',
    'identification_number': 15444740,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 61.9,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '5221b8d8-b30f-46ae-bee8-7a68eaa7078a',
    'identification_number': 32605579,
    'item_consumption_1': 18084,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 27357.81,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '736f7575-4c64-457d-8d78-7e936e54214d',
    'identification_number': 105798908,
    'item_consumption_1': 0,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 57.8,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a6563417-d768-42f8-b3ff-c4720f552783',
    'identification_number': 67989950,
    'item_consumption_1': 1249,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 529.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '589db0ca-cf3e-4d9c-9a4a-202e013d1e03',
    'identification_number': 12721069,
    'item_consumption_1': 1922,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 749.41,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '21a34bd9-7180-4c96-b539-b03070885325',
    'identification_number': 18643582,
    'item_consumption_1': 23.56,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 685.24,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '7d82359f-12e3-4d21-9fac-23f8c80cde2d',
    'identification_number': 82521310,
    'item_consumption_1': "'-10.54",
    'item_description_1': 'ENERGIA INJ. OUC MPT TUSD 07/2023',
    'item_total_value_1': "'-3663.13",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': 'cbb364be-55e6-4b01-a3fa-9108dd711b20',
    'identification_number': 108332438,
    'item_consumption_1': 279.72,
    'item_description_1': 'DEMANDA USD F PONTA',
    'item_total_value_1': 8135.91,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '2b98a35c-319b-4dc8-9434-df278042774d',
    'identification_number': 58734899,
    'item_consumption_1': 21.44,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICMS',
    'item_total_value_1': 511.36,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '4f275b4d-daf6-4336-834d-0a369123ec90',
    'identification_number': 92334865,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 2421.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13805a93-2d68-43e7-8b75-12d356a72f72',
    'identification_number': 70157510,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 18.34,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'a9bb00cb-498d-46be-a4b5-3fda556260a3',
    'identification_number': 14859424,
    'item_consumption_1': 1323,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 2001.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0a3148f6-65ff-42cf-a320-08390e3b6428',
    'identification_number': 15547159,
    'item_consumption_1': 3510,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1489.95,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3065a5d3-660c-4c1f-bf79-ed9d7a7262b4',
    'identification_number': 103033750,
    'item_consumption_1': 144,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 56.03,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fb1c3bc9-21e5-47b1-9052-80400f7a0c75',
    'identification_number': 70574502,
    'item_consumption_1': 28012,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3575.34,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '52bb882c-35a9-40cd-b5aa-b791a3d38327',
    'identification_number': 93009470,
    'item_consumption_1': 13.13,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 381.87,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '8104a50b-207c-4b9b-b982-2ac0326ed422',
    'identification_number': 37466232,
    'item_consumption_1': 7369,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3119.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4e5b69ea-725d-46e1-ae97-540b27c2e779',
    'identification_number': 99540584,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd970f8d9-bbe8-4630-8a0e-0c9345fde67d',
    'identification_number': 103124098,
    'item_consumption_1': 2452,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 1432.05,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '98f1537f-2550-4321-aff1-2fa80bd912b5',
    'identification_number': 15184196,
    'item_consumption_1': 3088,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1203.56,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '25f95daa-3144-4c1f-a198-89791c011f9c',
    'identification_number': 15449653,
    'item_consumption_1': 6360,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 2692.04,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9513d010-200d-45d3-8d60-077661418175',
    'identification_number': 48004936,
    'item_consumption_1': 136,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 53.21,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': '3cebb3eb-71d7-47c4-8568-275ae1e1cbef',
    'identification_number': 99688816,
    'item_consumption_1': 919,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 359.15,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bc16576d-d263-4bdf-87c9-70263167effd',
    'identification_number': 82534500,
    'item_consumption_1': 1434,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 609.39,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '5e275549-5d3f-4f57-a8f5-86aee4a8d720',
    'identification_number': 27661865,
    'item_consumption_1': 7586,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3225.31,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e3c897e5-8a08-40c5-86ab-ed5b27cab467',
    'identification_number': 96026782,
    'item_consumption_1': 1306,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 762.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0e2f6208-494c-40df-93b3-6c770914a37e',
    'identification_number': 106755030,
    'item_consumption_1': 101,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 2937.68,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '5687acad-f45e-45ee-80dc-b5fbef771891',
    'identification_number': 64724689,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eec833f1-2ce0-491f-9df6-d12db767fede',
    'identification_number': 5330939,
    'item_consumption_1': 283.24,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 8238.3,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'b8ec3e62-1dfb-4335-910e-4835e4e7f522',
    'identification_number': 93308965,
    'item_consumption_1': 709,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 414.07,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '920f42c1-d9d9-4cbe-be0f-c9d50791523c',
    'identification_number': 31705901,
    'item_consumption_1': 12196,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 5182.72,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e0ecbdd-ab4f-460f-915b-e587965d8b3c',
    'identification_number': 41611187,
    'item_consumption_1': 23.9,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 570.03,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '06886440-65d7-48a8-a17e-385dddc18717',
    'identification_number': 14333910,
    'item_consumption_1': 0.09,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 4.22,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'f4e90ce5-b54f-4454-94f9-0ceacb3d89a6',
    'identification_number': 3030777242,
    'item_consumption_1': 7799.8318,
    'item_description_1': 'Consumo Fora Ponta [KWh]-TUSD NOV/23',
    'item_total_value_1': 1147.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9b5c7041-9e49-466f-8512-2afc4fe65a97',
    'identification_number': 56708890,
    'item_consumption_1': 1910,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 243.77,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f8a671cb-9557-473d-a9f7-db9a5f844a30',
    'identification_number': 77221630,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 13.25,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '8dde48b1-725a-4c89-906a-31685738d2ce',
    'identification_number': 94871400,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 33.38,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b61689bf-dc36-4403-a231-aff9131d7e55',
    'identification_number': 16020,
    'item_consumption_1': 1376,
    'item_description_1': 'CONSUMO',
    'item_total_value_1': 746.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '38dd7c26-7036-486c-b576-f5df7fc16320',
    'identification_number': 76800563,
    'item_consumption_1': 8237,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 3492.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '8372d3d1-0c3c-45ca-942f-8c62ab83aef1',
    'identification_number': 106805037,
    'item_consumption_1': 185,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 78.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '24962cc4-47ea-46cd-b1fd-7031aa87f99e',
    'identification_number': 20893310,
    'item_consumption_1': 1163,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 492.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd7d89e11-d607-4870-adfe-f4261f18a7b9',
    'identification_number': 18561713,
    'item_consumption_1': 9710,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3788.53,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b626bc26-6937-4356-ae96-3e4fa0d64295',
    'identification_number': 17900514,
    'item_consumption_1': 791,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 308.8,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4dcdf754-c453-4e9c-8a7b-480f3087697e',
    'identification_number': 79404367,
    'item_consumption_1': 444,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 173.42,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9dde88d3-b02c-4c84-9944-12efedb7d272',
    'identification_number': 108331024,
    'item_consumption_1': 65.53,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 1562.94,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda fora ponta',
  },
  {
    'uuid_items': '4e62632a-4ce4-47a5-8ba3-ee6c8302fae9',
    'identification_number': 41176111,
    'item_consumption_1': 100,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 39.11,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd1156889-7f5b-44d2-9d88-8e67994d300e',
    'identification_number': 76288714,
    'item_consumption_1': 70,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 27.36,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'd44adb03-9f13-425a-bc44-02bd0c4eb682',
    'identification_number': 29517478,
    'item_consumption_1': 226,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 88.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '95b748e7-68bd-45e5-9aee-691106541671',
    'identification_number': 100428100,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 66.11,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '4515e3bc-2349-40dd-a637-7268e2f524c0',
    'identification_number': 46021191,
    'item_consumption_1': 39572,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 14726.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9ff6fa88-c845-405f-af49-82e312d99872',
    'identification_number': 95710094,
    'item_consumption_1': 1860,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 787.17,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '23c1c670-a52b-4b18-bd62-c6ad19e78376',
    'identification_number': 105799653,
    'item_consumption_1': 45.6,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 1326.3,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6c550443-6225-471f-91bc-4f1d270da43c',
    'identification_number': 92710123,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '9c739b1c-3ba9-4beb-89de-9b26943bcb4a',
    'identification_number': 46577173,
    'item_consumption_1': 5739,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 732.5,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd4ddbd54-f255-43b0-8176-22c23c5edc9a',
    'identification_number': 58734210,
    'item_consumption_1': 30.01,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 1713.86,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4121b406-ce71-4066-ac1a-78fe57e380ff',
    'identification_number': 29808235,
    'item_consumption_1': 1533,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 649.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '08dd9306-7978-48a7-a54f-040a96265dc6',
    'identification_number': 91350816,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2ba1de31-f477-4dcb-a0d7-d8db7f119984',
    'identification_number': 104614285,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 7500.16,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a5a85c49-d83c-4509-afce-9e38ebd88db9',
    'identification_number': 67536913,
    'item_consumption_1': 17.47,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 416.68,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'bbaa63c9-54dc-4f02-9aad-0360c85e0426',
    'identification_number': 82730652,
    'item_consumption_1': 168,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 65.44,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '93675a4e-495f-4bb5-a8a7-beb3479d6de3',
    'identification_number': 45580804,
    'item_consumption_1': 1012,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 395.75,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2fdc7071-f9f1-47e1-8de1-aef80147e83d',
    'identification_number': 84927798,
    'item_consumption_1': 801,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 340.01,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9e3759ba-6eb7-4f08-a098-4a9356d2d957',
    'identification_number': 106621912,
    'item_consumption_1': 1803,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 701.73,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc3bcc72-0689-405a-966c-d2788a96c78e',
    'identification_number': 33921180,
    'item_consumption_1': 391,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 152.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'eb0db8a4-be05-4a1d-929e-98943b13acd9',
    'identification_number': 78143977,
    'item_consumption_1': 997,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 582.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd59436b7-1a78-4c75-8377-67202a71bfb6',
    'identification_number': 98270257,
    'item_consumption_1': 330,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 128.93,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2d24f68b-1b05-407f-b2a1-c37608257a99',
    'identification_number': 101439350,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 21.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0b407466-de14-4fee-a4d5-13d43235bb22',
    'identification_number': 8813442,
    'item_consumption_1': 372,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 145.28,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6aabffc2-fcdf-4be6-ba35-677f2865c782',
    'identification_number': 93324138,
    'item_consumption_1': 0,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 7290.74,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0c335088-07b8-4635-bd77-cbb166e31a18',
    'identification_number': 86207563,
    'item_consumption_1': 11953,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 6980.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '26a8371f-df0a-4796-b6f1-062134e26765',
    'identification_number': 17182069,
    'item_consumption_1': 357,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 151.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '68e90597-2044-404e-aabe-55d0d27902cc',
    'identification_number': 111585252,
    'item_consumption_1': 80,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 33.87,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '94901ff0-3059-4da9-bf0d-75380fa57b88',
    'identification_number': 46920617,
    'item_consumption_1': 84381,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 10770.08,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '192c0cbf-abea-4c50-8290-80ce127844d7',
    'identification_number': 42127564,
    'item_consumption_1': "'-1.0",
    'item_description_1': 'CRED.CTA.ANTERIORES SDO NEG(F10)',
    'item_total_value_1': "'-5453.42",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': '7fb1cb5d-667f-489f-aaa7-c0204fed7669',
    'identification_number': 44028601,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.46,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3360b564-709b-41aa-8c3c-c959af8b2824',
    'identification_number': 31792634,
    'item_consumption_1': 221.1,
    'item_description_1': 'DEMANDA USD PONTA',
    'item_total_value_1': 12627.01,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': 'bd12dc46-d052-4ba3-a5fd-bc9ed33e1376',
    'identification_number': 16105133,
    'item_consumption_1': 1,
    'item_description_1': 'Contrib Iluminação Pública',
    'item_total_value_1': 4.79,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '703ee4dd-69d8-4f61-beea-25180992d632',
    'identification_number': 106965883,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 78.16,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '2c57dc4b-6dc1-4259-a591-917cadd45144',
    'identification_number': 38055660,
    'item_consumption_1': 0,
    'item_description_1': 'CRED VIOL META CONT 09/2023',
    'item_total_value_1': "'-6301.06",
    'reference_month': '11/2023',
    'referente': 'Crédito sobre qualidade de energia',
  },
  {
    'uuid_items': 'e440b2b5-012c-44e0-8ecf-1f87089afbae',
    'identification_number': 18616917,
    'item_consumption_1': 18.66,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 873.86,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '65e7aeb3-901f-41a3-a00f-ebf9d9efa660',
    'identification_number': 109402049,
    'item_consumption_1': 636,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 247.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '737b255b-cdf3-44de-b24d-ab8066b63175',
    'identification_number': 32074123,
    'item_consumption_1': 4595,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2683.64,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '875d73f2-37c2-408c-b528-c4d83e2987a5',
    'identification_number': 105297313,
    'item_consumption_1': 9769,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 3805.3,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9d167a32-136f-4846-b908-061f1ff69131',
    'identification_number': 99433486,
    'item_consumption_1': 2434,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 951.86,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dfc91167-eb2e-4588-abec-5911532f0140',
    'identification_number': 73695190,
    'item_consumption_1': 1275,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 541.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b3c2e870-4231-49ce-b86f-8860d42ae7e1',
    'identification_number': 91694035,
    'item_consumption_1': 4686,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1986.09,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '43e9a47b-5471-4f1f-b2bc-e50b17c92b8f',
    'identification_number': 68385447,
    'item_consumption_1': 25800,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 3293,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f2c2b166-b9fa-4aa0-9f57-c2c9a89d561a',
    'identification_number': 72702141,
    'item_consumption_1': 335.56,
    'item_description_1': 'DEMANDA USD PONTA ISENTA ICMS',
    'item_total_value_1': 15714.36,
    'reference_month': '11/2023',
    'referente': 'Ultrapassagem de demanda ponta',
  },
  {
    'uuid_items': '4195134a-5209-4101-b3e3-0215fddd8a05',
    'identification_number': 100387993,
    'item_consumption_1': 1604,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 625.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'dae0ded5-e5bb-440d-b60a-88fed018cd70',
    'identification_number': 35562854,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.51,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '6206dbd1-8e1a-4988-9ada-69b448399a1b',
    'identification_number': 31927807,
    'item_consumption_1': 2727,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1156.66,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'bceb6a43-045a-4fb0-9057-18525e01bf5e',
    'identification_number': 60730781,
    'item_consumption_1': 3.13,
    'item_description_1': 'DEMANDA USD F PONTA ISENTA ICM',
    'item_total_value_1': 74.66,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'd8865080-d46a-4081-bdc3-fb9be51b8cc2',
    'identification_number': 67162657,
    'item_consumption_1': 2127,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 901.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '3e56b02d-ff2b-4e99-8409-704da2ff983f',
    'identification_number': 46912401,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '96496f29-70d5-4ee5-a44c-1d1e14214d43',
    'identification_number': 55454500,
    'item_consumption_1': 8528,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 4980.67,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '0d72a926-42be-48bc-914d-64cf8f9e4860',
    'identification_number': 10103554,
    'item_consumption_1': "'-6.221",
    'item_description_1': 'ENERGIA INJ. OUC MPT TE 11/2023',
    'item_total_value_1': "'-1989.04",
    'reference_month': '11/2023',
    'referente': 'Compensação mesmo posto tarifário',
  },
  {
    'uuid_items': '96a25e95-8ada-48d3-93fd-09124979310c',
    'identification_number': 39964973,
    'item_consumption_1': 4143,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 6267.61,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'fde94400-2ed7-4d0a-9ff5-31527d21a83c',
    'identification_number': 103928065,
    'item_consumption_1': 362,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 153.52,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'a57a5eb7-4e94-4aae-907e-ec0f0fe02ebf',
    'identification_number': 9039694,
    'item_consumption_1': 3501,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1482.99,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f74bcbb9-4c3b-4dc6-b0c0-93d896d09df8',
    'identification_number': 48043907,
    'item_consumption_1': 17.81,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 518.01,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '12146c85-90cc-4459-a5fb-77ef73dbb1d6',
    'identification_number': 36527661,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 24.84,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'b2a436f4-2472-4972-b55f-4fa04e6e0aa0',
    'identification_number': 16121490,
    'item_consumption_1': 88,
    'item_description_1': 'Demanda KW',
    'item_total_value_1': 1359.72,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': 'fd036212-fad1-4786-9f36-7cb58ddc451c',
    'identification_number': 70926115,
    'item_consumption_1': 238,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 139,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'af8098bb-1a34-4190-b7de-b10e0aa8a79f',
    'identification_number': 103764755,
    'item_consumption_1': 5,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 1.96,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b7e82828-de6f-4558-a627-df0f64636d76',
    'identification_number': 98514733,
    'item_consumption_1': 50,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 19.45,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '77358697-758f-4edd-8fd4-b548223fcac9',
    'identification_number': 28065557,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 124.91,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '78d10b96-f3c1-45d4-ae64-31b64502cef4',
    'identification_number': 30345359,
    'item_consumption_1': 180.57,
    'item_description_1': 'DEMANDA USD',
    'item_total_value_1': 5252.03,
    'reference_month': '11/2023',
    'referente': 'Demanda',
  },
  {
    'uuid_items': '6b16d251-7687-41c3-aa78-d40f12b88b06',
    'identification_number': 92056920,
    'item_consumption_1': 10.47,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 249.73,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': '561a423a-37fa-4894-997a-be71ac0ed81c',
    'identification_number': 59850213,
    'item_consumption_1': 30,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 12.69,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'f097ac25-fb39-4520-a291-a1964d66591e',
    'identification_number': 32098120,
    'item_consumption_1': 5561,
    'item_description_1': 'ENERGIA ELETRICA USD F PONTA',
    'item_total_value_1': 709.78,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'b97057b4-6984-4bbd-84c5-aa3f5e9b66de',
    'identification_number': 85205672,
    'item_consumption_1': 4026,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 2351.33,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'cc49c1ee-f80a-4f9b-98e9-6ddef03b0d6b',
    'identification_number': 15173011,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 20.64,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': '79c31f2e-53f5-4294-bc95-59df5d0e5ba1',
    'identification_number': 21635935,
    'item_consumption_1': 12508,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 4873.35,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e1ab6274-14e7-43cf-a9a4-42f999fbbdba',
    'identification_number': 94624038,
    'item_consumption_1': 666,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 282.06,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '9cbb0ca2-8bcb-4772-8f54-1b0f5e0df3e6',
    'identification_number': 93742428,
    'item_consumption_1': 6165,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 2403.82,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '031d5e73-c6a4-4702-9c21-cc3a6d617ba0',
    'identification_number': 10895132,
    'item_consumption_1': 3422,
    'item_description_1': 'ENERGIA ELET CONSUMO',
    'item_total_value_1': 1334.92,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c5335ec7-1a15-4e26-b633-aa56220b4096',
    'identification_number': 57638519,
    'item_consumption_1': 677,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 287.83,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '82baa7e5-9a26-4eb3-8948-3589dcf72c51',
    'identification_number': 11404639,
    'item_consumption_1': 147,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 62.24,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c04ff36f-53e1-4759-89c3-685cee11d761',
    'identification_number': 48004936,
    'item_consumption_1': 0.4,
    'item_description_1': 'DEMANDA REATIVA EXCED USD',
    'item_total_value_1': 11.63,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo demanda',
  },
  {
    'uuid_items': 'ec6bc473-c10f-499d-813a-76638dfd32ee',
    'identification_number': 80647588,
    'item_consumption_1': 13362,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 4972.62,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'e44825eb-597a-4d13-8f78-5133a8f93905',
    'identification_number': 92672949,
    'item_consumption_1': 1,
    'item_description_1': 'CONT ILUMIN PUBLICA MUNICIPIO',
    'item_total_value_1': 38.94,
    'reference_month': '11/2023',
    'referente': 'CIP',
  },
  {
    'uuid_items': 'e6f1c634-c9ae-4be9-98c2-5e372f5a953e',
    'identification_number': 46343466,
    'item_consumption_1': 778,
    'item_description_1': 'ENERGIA ELETRICA TE PONTA',
    'item_total_value_1': 454.38,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '4800710b-510a-4b62-86a0-52f6ea9e392f',
    'identification_number': 98831968,
    'item_consumption_1': 2294,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3470.4,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '2e67893f-a19e-412c-92cf-701157fe0201',
    'identification_number': 47472006,
    'item_consumption_1': 2180,
    'item_description_1': 'ENERGIA ELETRICA USD PONTA',
    'item_total_value_1': 3297.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'd8ec6221-d9a9-4a8b-8d8a-69e5f87d3c02',
    'identification_number': 9211195,
    'item_consumption_1': 2059,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 872.94,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': 'c1dfb000-1a92-4a06-b10f-6660700b6c3f',
    'identification_number': 6227627,
    'item_consumption_1': 3481,
    'item_description_1': 'ENERGIA ELET USO SISTEMA',
    'item_total_value_1': 1476.97,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '13afefbb-1481-4898-b401-957becfa31ba',
    'identification_number': 16699696,
    'item_consumption_1': 1043,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 408.09,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b5169222-5552-4d24-a103-0ae477b5ab47',
    'identification_number': 33235953,
    'item_consumption_1': 2,
    'item_description_1': 'ENERGIA REAT EXCED TE PONTA',
    'item_total_value_1': 0.78,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
  {
    'uuid_items': 'b84665a2-a00e-4562-8759-f544350b917d',
    'identification_number': 80724906,
    'item_consumption_1': 36196,
    'item_description_1': 'ENERGIA ELETRICA TE F PONTA',
    'item_total_value_1': 13470.23,
    'reference_month': '11/2023',
    'referente': 'Consumo',
  },
  {
    'uuid_items': '49b7933e-1af5-4b33-b701-29f569852442',
    'identification_number': 102507864,
    'item_consumption_1': 12.51,
    'item_description_1': 'DEMANDA USD ISENTA ICMS',
    'item_total_value_1': 298.38,
    'reference_month': '11/2023',
    'referente': 'Demanda isenta',
  },
  {
    'uuid_items': 'e0606670-3965-460d-a378-e609d0890ff8',
    'identification_number': 40994724,
    'item_consumption_1': 203,
    'item_description_1': 'ENERGIA REAT EXCED TE F PONTA',
    'item_total_value_1': 79.42,
    'reference_month': '11/2023',
    'referente': 'Excedente reativo consumo',
  },
];
