// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useUniqueClassification() {
  const [noClassificationError, setNoClassificationError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getUniqueClassification = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getUniqueSearchClassification(headers);

    const classifications = response.data.data || [];

    if (classifications.length === 0) {
      setNoClassificationError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return classifications;
  };

  const {
    data: classifications,
    error: classificationsError,
    isLoading,
  } = useQuery(['classifications'], getUniqueClassification);

  return {
    classifications,
    classificationsError,
    noClassificationError,
    isLoading,
  };
}

export default useUniqueClassification;
