import React, { useState, useEffect } from 'react';
import {
  IoseChartCredit,
  IoseFilterConsumerGroup,
  IoseButton,
  IoseSelectReferenceMonth,
  NoDataMessage,
  ReportMessage,
  IoseCardCreditDash,
  IoseAlertLoadingCards,
} from 'components';

import * as S from './style';

import {
  useConsumerGroup,
  useBeneficiaryJoin,
  useGroupPermissions,
} from 'hooks';

import Permissions from 'common/permissions';

import { LocalStorage } from 'common';

import { DivPaper } from 'styles/defaultStyles';

export default function CreditDashboard() {
  const { consumerGroups } = useConsumerGroup();
  const { BeneficiaryJoinData } = useBeneficiaryJoin();
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [identificationNumber, setIdentificationNumber] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [ucGeradoraData, setUcGeradoraData] = useState([]);
  const [noDataError, setNoDataError] = useState(true);
  const [
    filteredBeneficiaryJoinData,
    setFilteredBeneficiaryJoinData,
  ] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const [hasReadPermission, setHasReadPermission] = useState(true);

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['geracao', 'dashboard_creditos', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  const formatToBrazilianDate = (date) => {
    if (date) {
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${year}`;
    }
    return '';
  };

  const handleIdentificationNumberChange = (identification_number) => {
    setIdentificationNumber(identification_number);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedMonth(formatToBrazilianDate(date));
  };

  useEffect(() => {
    setIsFilterEnabled(selectedDate || identificationNumber);
  }, [selectedDate, identificationNumber]);

  useEffect(() => {
    if (identificationNumber) {
      const filteredData = BeneficiaryJoinData?.filter(
        (item) => item.uc_geradora === identificationNumber
      );

      setUcGeradoraData(filteredData);
      setFilteredBeneficiaryJoinData(filteredData);
    }
  }, [identificationNumber, BeneficiaryJoinData]);

  useEffect(() => {
    setIsFilterEnabled(selectedMonth || identificationNumber);
  }, [selectedMonth, identificationNumber]);

  const getAuditData = () => {
    if (identificationNumber && selectedDate) {
      const selectedMonthYear = `0${
        selectedDate.getMonth() + 1
      }/${selectedDate.getFullYear()}`;

      const filteredData = BeneficiaryJoinData?.filter((item) => {
        return (
          item.uc_geradora === identificationNumber &&
          item.mes_referencia === selectedMonthYear
        );
      });

      setButtonClicked(true);

      if (filteredData.length === 0) {
        setNoDataError(true);
      } else {
        setNoDataError(false);
      }
      setFilteredData(filteredData);
    } else {
      setNoDataError(true);
    }
  };

  const consumerGroupsOptionsGeradora = consumerGroups
    ?.filter((item) => item.geradora === 'SIM')
    .map((item) => ({
      value: item.identification_number,
      label: item.identification_number,
    }));

  const consumerGroupsOptions = consumerGroupsOptionsGeradora;

  const renderUnityContainer = (filteredBeneficiaryJoinData) => {
    return (
      <div>
        <IoseChartCredit
          ucGeradoraData={ucGeradoraData}
          selectedDate={selectedDate}
          beneficiaryJoinData={filteredBeneficiaryJoinData}
          filteredData={filteredData}
        />
      </div>
    );
  };

  return (
    <S.WrapContainer>
      <h3 style={{ fontSize: '23px', paddingBottom: '11px', color: '#015A9E' }}>
        Dashboard de Créditos
      </h3>

      {hasReadPermission ? (
        <>
          <h2 style={{ color: 'grey', paddingBottom: '15px' }}>
            Filtre os dados para alterar o Dashboard
          </h2>
          <DivPaper>
            <IoseCardCreditDash
              loading={''}
              auditValues={''}
              auditConsumptions={'auditConsumptions'}
              auditExtract={'auditExtract'}
              averages={'averages'}
            />
          </DivPaper>
          <div style={{ marginBottom: '19px' }}></div>

          <S.GridContainer
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <S.DatesContainer>
              <div style={{ marginRight: '5px' }}></div>

              <IoseFilterConsumerGroup
                label="U.C."
                value={identificationNumber}
                onChange={handleIdentificationNumberChange}
                options={consumerGroupsOptions}
              />
              <div style={{ marginRight: '19px' }}></div>
              <IoseSelectReferenceMonth
                label="REF: MÊS/ANO"
                handleChange={handleDateChange}
                selectedDate={selectedDate}
              />

              <div style={{ marginRight: '12px' }}></div>

              <IoseButton onClick={getAuditData} disabled={!isFilterEnabled}>
                FILTRAR
              </IoseButton>
            </S.DatesContainer>

            {noDataError && buttonClicked ? (
              <NoDataMessage message="Ops! Nenhum dado encontrado. Por favor, verifique se as configurações do filtro estão corretas e tente novamente." />
            ) : buttonClicked ? (
              renderUnityContainer(filteredBeneficiaryJoinData, ucGeradoraData)
            ) : (
              <>
                <ReportMessage message="Para filtrar o Dashboard, selecione os filtros desejados." />
              </>
            )}
          </S.GridContainer>
        </>
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão de leitura destes dados!'
          }
        />
      )}
    </S.WrapContainer>
  );
}
