import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import IosePermissionsModal from 'components/Modals/IosePermissionsModal';

import * as S from './style';
import { IoseAlertErroModal, IoseAlertSuccessModal } from 'components';

const PermissionsContainer = ({
  permissions,
  submit,
  loading,
  showNotification,
  editNotificationType,
  editNotification,
}) => {
  const [permissionsObject, setPermissionsObject] = useState(
    permissions?.permissions
  );
  const [accordionsOpen, setAccordionsOpen] = useState({});
  const [switchActive, setSwitchActive] = useState(null);
  const [isGrantModalOpen, setIsGrantModalOpen] = useState(false);
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);

  const { uuid_group, group_name } = permissions;

  const handleSubmit = async () => {
    submit(uuid_group, group_name, permissionsObject);
  };

  const handleAccordionChange = (category) => {
    setAccordionsOpen((prevState) => ({
      ...prevState,
      [category]: !accordionsOpen[category],
    }));
  };

  const isAccordionOpen = (category) => !!accordionsOpen[category];

  const handleSwitchChange = (category, subcategory, action) => {
    setPermissionsObject((prevState) => {
      return {
        ...prevState,
        [category]: {
          ...prevState[category],
          [subcategory]: {
            ...prevState[category][subcategory],
            [action]: !prevState[category][subcategory][action],
          },
        },
      };
    });

    setSwitchActive(`${category}-${subcategory}-${action}`);
    setTimeout(() => {
      setSwitchActive(null);
    }, 500);
  };

  const handleGrantAllPermissions = () => {
    setIsGrantModalOpen(true);
  };

  const handleRevokeAllPermissions = () => {
    setIsRevokeModalOpen(true);
  };

  const confirmGrantAllPermissions = () => {
    setPermissionsObject((prevState) => {
      const updatedPermissions = { ...prevState };
      Object.keys(updatedPermissions).forEach((category) => {
        Object.keys(updatedPermissions[category]).forEach((subcategory) => {
          Object.keys(updatedPermissions[category][subcategory]).forEach(
            (action) => {
              updatedPermissions[category][subcategory][action] = true;
            }
          );
        });
      });
      return updatedPermissions;
    });
    setIsGrantModalOpen(false);
  };

  const confirmRevokeAllPermissions = () => {
    setPermissionsObject((prevState) => {
      const updatedPermissions = { ...prevState };
      Object.keys(updatedPermissions).forEach((category) => {
        Object.keys(updatedPermissions[category]).forEach((subcategory) => {
          Object.keys(updatedPermissions[category][subcategory]).forEach(
            (action) => {
              updatedPermissions[category][subcategory][action] = false;
            }
          );
        });
      });
      return updatedPermissions;
    });
    setIsRevokeModalOpen(false);
  };

  const cancelGrantModal = () => {
    setIsGrantModalOpen(false);
  };

  const cancelRevokeModal = () => {
    setIsRevokeModalOpen(false);
  };

  const accordionStyle = {
    border: '1px solid #015A9E',
  };

  const accordionSummaryStyle = {
    color: 'black',
  };

  const switchStyle = (category, subcategory, action) => {
    return {
      color:
        switchActive === `${category}-${subcategory}-${action}`
          ? '#015A9E'
          : permissionsObject[category][subcategory][action]
          ? '#015A9E'
          : 'grey',
      thumbColor:
        switchActive === `${category}-${subcategory}-${action}`
          ? '#015A9E'
          : null,
    };
  };

  const formatKey = (chave) => {
    const chaveFormatada = {
      faturas: 'Faturas',
      buscar_faturas: 'Busca de Faturas',
      recalculo_faturas: 'Recálculo de Faturas',
      mapa: 'Mapa',
      mapa_geo: 'Mapa Geo',
      cadastro: 'Cadastro',
      unidades_consumidoras: 'Unidades Consumidora',
      ambientes: 'Ambientes',
      circuitos: 'Circuitos',
      unidades: 'Unidades',
      contratos: 'Contratos',
      ecu_solar: 'ECUs',
      concessionarias: 'Concessionária',
      objetos: 'Objetos',
      tarifas: 'Tarifas',
      geracao: 'Geração',
      geradoras_beneficiarias: 'Geradoras X Beneficiárias',
      dashboard_creditos: 'Dashboard de Créditos',
      unidades_geradoras: 'Unidades Geradoras',
      unidades_beneficiarias: 'Unidades Beneficiárias',
      gerenciamento: 'Gerenciamento',
      log_faturas: 'Log de Faturas',
      log_usuarios: 'Log de Usuários',
      dashboards: 'Dashboards',
      administrativo: 'Administrativo',
      '3d': '3D',
      comparacao_consumo: 'Comparação de Consumo',
      parametros: 'Parâmetros',
      variaveis_sistema: 'Variáveis do Sistema',
      relatorios: 'Relatórios',
      biene: 'Exportar TXT BIENE',
      creditos: 'Relatório de Créditos',
      sgf: 'Exportar TXT SGF',
      medicao: 'Relatório de Medição',
      fator_co2: 'Fator CO2',
      emissao_carbono: 'Emissão de Carbono',
      simulador: 'Simulador',
      simulador_faturas: 'Simulador de Faturas',
      telemetria: 'Telemetria',
      medicao_telemetria: 'Medição',
      dashboard_telemetria: 'Dashboard Telemetria',
      equipamento_telemetria: 'Equipamento',
      mapa_telemetria: 'Mapa Telemetria',
      itens_fatura: 'Itens da Fatura',
    }[chave];

    return chaveFormatada || chave;
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '5px', marginBottom: '10px' }}>
        <Tooltip title="CONCEDER TODAS AS PERMISSÕES" arrow>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGrantAllPermissions}
            style={{ backgroundColor: '#00A65A', color: 'white' }}
          >
            <CheckIcon />
          </Button>
        </Tooltip>
        <Tooltip title="REVOGAR TODAS AS PERMISSÕES" arrow>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRevokeAllPermissions}
            style={{ backgroundColor: '#D60606', color: 'white' }}
          >
            <RemoveIcon />
          </Button>
        </Tooltip>
      </div>

      <IosePermissionsModal
        open={isGrantModalOpen}
        onClose={cancelGrantModal}
        submit={confirmGrantAllPermissions}
        cancel={cancelGrantModal}
        text={'Tem certeza que deseja conceder todas as permissões?'}
      />

      <IosePermissionsModal
        open={isRevokeModalOpen}
        onClose={cancelRevokeModal}
        submit={confirmRevokeAllPermissions}
        cancel={cancelRevokeModal}
        text={'Tem certeza que deseja revogar todas as permissões?'}
      />

      {Object.keys(permissionsObject).map((category) => (
        <Accordion
          key={category}
          expanded={isAccordionOpen(category)}
          onChange={() => handleAccordionChange(category)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
            style={accordionSummaryStyle}
          >
            <h3>{formatKey(category)}</h3>
          </AccordionSummary>
          <Collapse in={isAccordionOpen(category)}>
            <AccordionDetails>
              <div>
                {Object.keys(permissionsObject[category]).map((subcategory) => (
                  <Accordion
                    key={subcategory}
                    expanded={isAccordionOpen(subcategory)}
                    onChange={() => handleAccordionChange(subcategory)}
                    style={accordionStyle}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                      style={accordionSummaryStyle}
                    >
                      <h4>{formatKey(subcategory)}</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.keys(
                        permissionsObject[category][subcategory]
                      ).map((action) => (
                        <div key={action}>
                          <span>{action}</span>
                          <Switch
                            color="primary"
                            checked={
                              permissionsObject[category][subcategory][action]
                            }
                            onChange={() =>
                              handleSwitchChange(category, subcategory, action)
                            }
                            style={switchStyle(category, subcategory, action)}
                          />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </AccordionDetails>
          </Collapse>
        </Accordion>
      ))}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {loading ? (
          <S.DivCircularProgress>
            <S.CircularProgressStyled />
          </S.DivCircularProgress>
        ) : showNotification ? (
          <>
            {editNotificationType === 1 && (
              <IoseAlertSuccessModal text={editNotification} />
            )}

            {editNotificationType === 2 && (
              <IoseAlertErroModal text={editNotification} />
            )}
          </>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            style={{
              backgroundColor: '#015A9E',
              color: '#fff',
              marginTop: '15px',
            }}
          >
            Atualizar permissões
          </Button>
        )}
      </div>
    </div>
  );
};

export default PermissionsContainer;
