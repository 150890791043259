import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const DivPaper = styled(Paper)`
  height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
