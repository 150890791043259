import React, { useEffect, useState, useContext } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';
import { useAlert } from 'react-alert';
import * as XLSX from 'xlsx';

import { StatesAndCities } from '../../constants/index';

import {
  useContracts,
  useConsumerGroup,
  useConcessionaires,
  useUniqueAtividades,
  useUniqueConcessionaires,
  useUniqueDescriptions,
  useUniqueRegionalManagement,
  useUniqueGeneralManagement,
  useGroupPermissions,
  useAddConsumerGroup,
  useEditConsumerGroup,
  useDeleteConsumerGroup,
} from 'hooks';

import {
  ioseAPI,
  LocalStorage,
  Messages,
  AccountContext,
  useClient,
} from 'common';

import * as S from './style';

import consumerGroupsReport from 'reports/ConsumerGroups/consumerGroupReport';

import {
  IoseSubHeaderBar,
  IoseSearch,
  IoseCardConsumerGroup,
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseInfinityScroll,
  ConsumerGroupIcon,
  IoseConsumerGroupDeleteModal,
  IoseAddConsumerGroupModal,
  IoseEditConsumerGroupModal,
  LoadingIndicator,
  IoseButton,
  IoseButton2,
} from 'components';

import { IoseAddFilterButton } from 'components/IoseButtonIcon';
import IoseDynamicFilter from 'components/IoseDynamicFilter';
import Permissions from 'common/permissions';

function exportToExcel(data) {
  // Remover o campo uuid_circuit dos objetos da lista
  const filteredData = data.map((obj) => {
    const { uuid_circuit, ...rest } = obj;
    return rest;
  });

  // Reordena as colunas
  const reorderedColumns = [
    'identification_number',
    'cod_concessionaria',
    'concessionaire',
    'regional_management',
    'general_management',
    'operating_unity',
    'cod_unidade',
    'city',
    'address',
    'cogeracao',
    'csbio',
    'carbon_credit',
    'geradora',
    'beneficiaria',
    'modalidade',
    'telemetry',
    'cod_local',
    'descricao_unidade',
    'tensao',
    'subgrupo',
    'ativo',
    'cod_orgao_pagador',
    'inicio_vigencia',
    'fim_vigencia',
    'atividade',
    'cod_faturamento',
  ];

  // Reordena os dados
  const reorderedData = filteredData.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    identification_number: 'Unidade Consumidora',
    cod_concessionaria: 'Código Concessionária',
    concessionaire: 'Concessionária',
    regional_management: 'Gerência Regional',
    general_management: 'Gerência Geral',
    operating_unity: 'Unidade Operacional',
    cod_unidade: 'Código da Unidade',
    city: 'Cidade',
    address: 'Endereço',
    cogeracao: 'Cogeração',
    csbio: 'CSBIO',
    carbon_credit: 'Crédito de Carbono',
    geradora: 'Geradora',
    beneficiaria: 'Beneficiária',
    modalidade: 'Modalidade',
    telemetry: 'Telemetria',
    cod_local: 'Código do Local',
    descricao_unidade: 'Descrição da Unidade',
    tensao: 'Tensão',
    subgrupo: 'Subgrupo',
    ativo: 'Status',
    cod_orgao_pagador: 'Código do Orgão Pagador',
    inicio_vigencia: 'Ínicio Vigência',
    fim_vigencia: 'Fim Vigência',
    atividade: 'Atividade',
    cod_faturamento: 'Código do Faturamento',
  };

  // Altere o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Resumo de UCs');
  XLSX.writeFile(workbook, 'unidades-consumidoras.xlsx');
}

export default function ConsumerGroupContainer({ getcards }) {
  const [cards, setCards] = useState([]);
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [clientData, setClientData] = useState({});
  const [deleteNotificationType, setDeleteNotificationType] = useState(0);
  const [editNotificationType, setEditNotificationType] = useState(0);
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);
  const [notificationType, setNotificationType] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [search, setSearch] = useState(false);
  //eslint-disable-next-line
  const [selectedCities, setSelectedCities] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [showPaginationButtons, setShowPaginationButtons] = useState(true);
  const [filteredConsumerGroups, setFilteredConsumerGroups] = useState([]);

  //Filter states
  const [showFilters, setShowFilters] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAtividade, setSelectedAtividade] = useState('');
  const [selectedConcessionaire, setSelectedConcessionaire] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedRManagement, setSelectedRManagement] = useState('');
  const [selectedGManagement, setSelectedGManagement] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({});

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState();
  const [hasEditPermission, setHasEditPermission] = useState();
  const [hasDeletePermission, setHasDeletePermission] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 200;

  const getCurrentCards = () => {
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    return search
      ? cardsFiltered?.slice(indexOfFirstCard, indexOfLastCard)
      : cards?.slice(indexOfFirstCard, indexOfLastCard);
  };

  const alert = useAlert();

  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { contracts } = useContracts();
  const { concessionaires } = useConcessionaires();
  const { uniqueAtividades } = useUniqueAtividades();
  const { uniqueConcessionaires } = useUniqueConcessionaires();
  const { uniqueDescriptions } = useUniqueDescriptions();
  const { regionalManagements } = useUniqueRegionalManagement();
  const { generalManagements } = useUniqueGeneralManagement();

  const {
    consumerGroups,
    noConsumerGroupsError,
    refreshConsumerGroups,
    isLoading,
  } = useConsumerGroup();

  const {
    addConsumerGroup,
    addNotification,
    addErrorMessage,
  } = useAddConsumerGroup();

  const {
    editConsumerGroup,
    editErrorMessage,
    editNotification,
  } = useEditConsumerGroup();

  const {
    deleteConsumerGroup,
    deleteErrorMessage,
    deleteNotification,
  } = useDeleteConsumerGroup();

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['cadastro', 'unidades_consumidoras', 'leitura'];
    const addPath = ['cadastro', 'unidades_consumidoras', 'criar'];
    const editPath = ['cadastro', 'unidades_consumidoras', 'editar'];
    const deletePath = ['cadastro', 'unidades_consumidoras', 'deletar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);

      const editPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        editPath
      );
      setHasEditPermission(editPermission);

      const deletePermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        deletePath
      );
      setHasDeletePermission(deletePermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    filteredConsumerGroups?.length > 0
      ? setCards(filteredConsumerGroups)
      : setCards(consumerGroups);

    setShowPaginationButtons(cards?.length >= 50);

    if (filteredConsumerGroups?.length === 0 || consumerGroups?.length === 0) {
      setError(noConsumerGroupsError);
    }
  }, [filteredConsumerGroups, consumerGroups, noConsumerGroupsError, cards]);

  useEffect(() => {
    getCitiesBySelectedState(selectedState);
  }, [selectedState]);

  function handleDownloadButtonClick() {
    exportToExcel(cardsFiltered?.length > 0 ? cardsFiltered : cards);
  }

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleAddConsumerGroup = async (
    concessionaire,
    identification_number,
    contract,
    operating_unity,
    general_management,
    regional_management,
    coordinate_x,
    coordinate_y,
    address,
    city,
    carbon_credit,
    telemetry,
    meter,
    consumer_group_load,
    atividade,
    cod_local,
    cod_unidade,
    descricao_unidade,
    cod_orgao_pagador,
    cogeracao,
    csbio,
    geradora,
    beneficiaria,
    potencia_transformador,
    subgrupo,
    modalidade,
    ativo,
    mercado_livre,
    inicio_vigencia,
    fim_vigencia,
    inicio_teste,
    fim_teste,
    constante,
    relogio_reset,
    demanda_fora,
    demanda_ponta,
    tensao
  ) => {
    setOperationLoading(true);
    try {
      await addConsumerGroup(
        concessionaire,
        identification_number,
        contract,
        operating_unity,
        general_management,
        regional_management,
        coordinate_x,
        coordinate_y,
        address,
        city,
        carbon_credit,
        telemetry,
        meter,
        consumer_group_load,
        atividade,
        cod_local,
        cod_unidade,
        descricao_unidade,
        cod_orgao_pagador,
        cogeracao,
        csbio,
        geradora,
        beneficiaria,
        potencia_transformador,
        subgrupo,
        modalidade,
        ativo,
        mercado_livre,
        inicio_vigencia,
        fim_vigencia,
        inicio_teste,
        fim_teste,
        constante,
        relogio_reset,
        demanda_fora,
        demanda_ponta,
        tensao
      );

      setNotificationType(1);
      refreshConsumerGroups();
    } catch (error) {
      errorHandlingAddConcessionaires(addErrorMessage);
    } finally {
      setOperationLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseAddModal();
        setNotificationType(0);
        setShowNotification(false);
        setSelectedState(null);
      }, 2500);
    }
  };

  const handleEditConsumerGroup = async (
    uuid_consumer_group,
    concessionaire,
    identification_number,
    contract,
    operating_unity,
    general_management,
    regional_management,
    coordinate_x,
    coordinate_y,
    address,
    city,
    carbon_credit,
    telemetry,
    meter,
    consumer_group_load,
    atividade,
    cod_local,
    cod_unidade,
    descricao_unidade,
    cod_orgao_pagador,
    cogeracao,
    csbio,
    geradora,
    beneficiaria,
    potencia_transformador,
    subgrupo,
    modalidade,
    ativo,
    mercado_livre,
    startValidity,
    endValidity,
    startTest,
    endTest,
    constante,
    relogio_reset,
    demanda_fora,
    demanda_ponta,
    tensao
  ) => {
    setOperationLoading(true);
    try {
      await editConsumerGroup(
        uuid_consumer_group,
        concessionaire,
        identification_number,
        contract,
        operating_unity,
        general_management,
        regional_management,
        coordinate_x,
        coordinate_y,
        address,
        city,
        carbon_credit,
        telemetry,
        meter,
        consumer_group_load,
        atividade,
        cod_local,
        cod_unidade,
        descricao_unidade,
        cod_orgao_pagador,
        cogeracao,
        csbio,
        geradora,
        beneficiaria,
        potencia_transformador,
        subgrupo,
        modalidade,
        ativo,
        mercado_livre,
        startValidity,
        endValidity,
        startTest,
        endTest,
        constante,
        relogio_reset,
        demanda_fora,
        demanda_ponta,
        tensao
      );

      setEditNotificationType(1);
      refreshConsumerGroups();
    } catch (error) {
      errorHandlingEditConcessionaires(editErrorMessage);
    } finally {
      setOperationLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseEditModal();
        setEditNotificationType(0);
        setShowNotification(false);
        setSelectedState(null);
      }, 2500);
    }
  };

  const handleDeleteConsumerGroup = async (uuid_consumer_group) => {
    setOperationLoading(true);
    try {
      await deleteConsumerGroup(uuid_consumer_group);
      setDeleteNotificationType(1);
      refreshConsumerGroups();
    } catch (error) {
      errorHandlingDeleteConcessionaires(deleteErrorMessage);
    } finally {
      setOperationLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseDeleteModal();
        refreshConsumerGroups();
        setDeleteNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  };

  const getCitiesBySelectedState = (selectedState) => {
    if (selectedState) {
      const selectedStateObj = StatesAndCities.find((stateObj) =>
        stateObj.estados.some((estado) => estado.nomeEstado === selectedState)
      );

      if (selectedStateObj) {
        const selectedEstado = selectedStateObj.estados.find(
          (estado) => estado.nomeEstado === selectedState
        );
        setSelectedCities(selectedEstado.cidades);
      }
    }
  };

  function errorHandlingAddConcessionaires(error) {
    setLoading(false);
    setNotificationType(2);
  }

  function errorHandlingEditConcessionaires(error) {
    setLoading(false);
    setEditNotificationType(2);
  }

  function errorHandlingDeleteConcessionaires(error) {
    alert.show(error ? error : `${Messages.errorDeleteConcessionaires}`, {
      title: 'Erro!',
      closeCopy: 'Fechar',
    });
  }

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  async function getConsumerGroups(object) {
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.getFilteredConsumerGroups(
        sessionData.headers,
        object
      );

      setFilteredConsumerGroups(response.data.data);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : Messages.noRegisteredConsumerGroups;

      setError(errorMessage);
    } finally {
      setLoading(false);
      setSelectedFilters({});
    }
  }

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const filterCircuits = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppcase = searchName?.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards?.filter((item) =>
        item?.identification_number?.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered?.length !== 0
        ? setCardsFiltered(itemFiltered)
        : setError(Messages.noFindConsumerGroups);

      setShowPaginationButtons(itemFiltered?.length >= 50);
    } else {
      setSearch(false);
      setShowPaginationButtons(cards?.length >= 50);
      setCardsFiltered([]);
    }
  };

  const renderCards = (card) => {
    return (
      <IoseCardConsumerGroup
        key={card.uuid_consumer_group}
        name={card.identification_number}
        description={card.address}
        onClick={() => handleCardClick(card)}
        cardDeleteTitle={'DELETAR UNIDADE CONSUMIDORA'}
        cardEditTitle={'EDITAR UNIDADE CONSUMIDORA'}
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        cardIcon={<ConsumerGroupIcon />}
        showDeleteButton={hasDeletePermission}
        showEditButton={hasEditPermission}
        showChartButton={false}
        selectedCard={selectedCard}
        showInfo={true}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="CRIAR UNIDADE CONSUMIDORA"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle="Unidades Consumidoras"
        description={'Total: ' + (cards ? cards.length : 0)}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar por UC..."
          funcSearch={filterCircuits}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const RenderFilterContainer = () => {
    const filterOptions = [
      'Atividade',
      'Concessionaria',
      'Descrição da Unidade',
      'Gerência Regional',
      'Gerência Geral',
    ];

    const toggleFilters = () => {
      setShowFilters(!showFilters);
    };

    const handleOptionClick = (option) => {
      setSelectedOption(option);
      setShowFilters(false);
    };

    const handleAtividadeChange = (value) => {
      setSelectedAtividade(value);
    };

    const handleConcessionariaChange = (value) => {
      setSelectedConcessionaire(value);
    };

    const handleDescriptionChange = (value) => {
      setSelectedDescription(value);
    };

    const handleRManagementChange = (value) => {
      setSelectedRManagement(value);
    };

    const handleGManagementChange = (value) => {
      setSelectedGManagement(value);
    };

    const renderSelectedComponent = () => {
      const filterOptions = {
        Atividade: {
          label: 'Atividade',
          databaseName: 'atividade',
          value: selectedAtividade,
          onChange: handleAtividadeChange,
          options: uniqueAtividades,
        },
        Concessionaria: {
          label: 'Concessionaria',
          databaseName: 'concessionaire',
          value: selectedConcessionaire,
          onChange: handleConcessionariaChange,
          options: uniqueConcessionaires,
        },
        'Descrição da Unidade': {
          label: 'Descrição da Unidade',
          databaseName: 'descricao_unidade',
          value: selectedDescription,
          onChange: handleDescriptionChange,
          options: uniqueDescriptions,
        },
        'Gerência Regional': {
          label: 'Gerência Regional',
          databaseName: 'regional_management',
          value: selectedRManagement,
          onChange: handleRManagementChange,
          options: regionalManagements,
        },
        'Gerência Geral': {
          label: 'Gerência Geral',
          databaseName: 'regional_management',
          value: selectedGManagement,
          onChange: handleGManagementChange,
          options: generalManagements,
        },
      };

      const selectedFilter = filterOptions[selectedOption];

      const handleButtonClick = () => {
        if (
          selectedFilter &&
          selectedFilter.databaseName &&
          selectedFilter.value
        ) {
          const formattedFilter = {
            [selectedFilter.databaseName]: selectedFilter.value,
          };

          setSelectedFilters({ ...selectedFilters, ...formattedFilter });
        }

        setShowFilters(false);
        setSelectedOption(null);
      };

      const handleRemoveFilter = (key) => {
        const updatedFilters = { ...selectedFilters };

        if (updatedFilters[key]) {
          delete updatedFilters[key];
        }

        setSelectedFilters(updatedFilters);
      };

      return (
        <S.FilterBox>
          {selectedFilter && (
            <>
              <IoseDynamicFilter
                label={selectedFilter.label}
                value={selectedFilter.value}
                onChange={selectedFilter.onChange}
                options={selectedFilter.options}
              />
              <IoseButton onClick={handleButtonClick}>
                ADICIONAR FILTRO
              </IoseButton>
            </>
          )}

          <div style={{ marginLeft: '25px' }}>
            {Object.entries(selectedFilters).length > 0 && (
              <S.FilterCardContainer>
                <h3>Filtros Selecionados:</h3>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {Object.entries(selectedFilters).map(([key, value]) => (
                    <S.FilterCardDiv key={key}>
                      <div>{value}</div>
                      <S.CloseButton onClick={() => handleRemoveFilter(key)}>
                        <S.CloseCardIcon />
                      </S.CloseButton>
                    </S.FilterCardDiv>
                  ))}
                </div>
                <IoseButton
                  left={'15px'}
                  onClick={() => getConsumerGroups(selectedFilters)}
                >
                  FILTRAR
                </IoseButton>
              </S.FilterCardContainer>
            )}
          </div>
        </S.FilterBox>
      );
    };

    return (
      <S.FilterContainer>
        <IoseAddFilterButton
          onClick={toggleFilters}
          tooltip={'ADICIONAR FILTRO'}
        />

        {showFilters && (
          <S.FilterBox>
            {filterOptions.map((option, index) => (
              <S.OptionsButton
                key={index}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </S.OptionsButton>
            ))}
          </S.FilterBox>
        )}

        {renderSelectedComponent()}
      </S.FilterContainer>
    );
  };

  const renderAllCards = () => {
    const currentCards = getCurrentCards();

    return (
      <S.ContainerCards>{currentCards?.map(renderCards)}</S.ContainerCards>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          {hasReadPermission ? (
            <>
              {showPaginationButtons && (
                <S.PaginationContainer>
                  {renderPaginationButtons()}
                </S.PaginationContainer>
              )}
              <IoseInfinityScroll
                dataLength={cards?.length ? cards.length : 10}
                hasMore={true}
                loading={loading}
                scrollableTarget={'container'}
              >
                {renderAllCards()}
              </IoseInfinityScroll>
              {showPaginationButtons && (
                <S.PaginationContainer>
                  {renderPaginationButtons()}
                </S.PaginationContainer>
              )}
            </>
          ) : (
            setError(
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            )
          )}
        </>
      );
    }
  };

  const renderDownloadButtons = () => {
    return (
      <div style={{ display: 'flex', gap: '15px' }}>
        <IoseButton2
          onClick={handleDownloadButtonClick}
          style={{ width: '150px', margin: '0' }}
        >
          Download XLSX
        </IoseButton2>
        <IoseButton2
          onClick={() =>
            consumerGroupsReport(
              cardsFiltered?.length > 0 ? cardsFiltered : cards
            )
          }
          style={{ width: '150px', margin: '0' }}
        >
          Download PDF
        </IoseButton2>
      </div>
    );
  };

  const renderModals = () => {
    return (
      <>
        <IoseAddConsumerGroupModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          modalHeaderTitle="UNIDADE CONSUMIDORA"
          modalTitle="CADASTRAR UNIDADE CONSUMIDORA"
          submit={handleAddConsumerGroup}
          notificationType={notificationType}
          showNotification={showNotification}
          addNotification={addNotification}
          loading={operationLoading}
          selectData={concessionaires}
          contractData={contracts}
        />
        <IoseEditConsumerGroupModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          modalHeaderTitle="UNIDADE CONSUMIDORA"
          modalTitle="EDITAR UNIDADE CONSUMIDORA"
          submit={handleEditConsumerGroup}
          editNotificationType={editNotificationType}
          showNotification={showNotification}
          editNotification={editNotification}
          selectedCard={selectedCard}
          loading={operationLoading}
          selectData={concessionaires}
          contractData={contracts}
        />
        <IoseConsumerGroupDeleteModal
          modalHeaderTitle="UNIDADE CONSUMIDORA"
          modalTitle="DELETAR UNIDADE CONSUMIDORA"
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          submit={handleDeleteConsumerGroup}
          notificationType={deleteNotificationType}
          showNotification={showNotification}
          deleteNotification={deleteNotification}
          loading={operationLoading}
          selectedCard={selectedCard}
        />
      </>
    );
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    const totalPages = Math.ceil(
      (search ? cardsFiltered.length : cards.length) / cardsPerPage
    );
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    const totalPages = Math.ceil(
      (search ? cardsFiltered.length : cards.length) / cardsPerPage
    );
    setCurrentPage(totalPages);
  };

  const renderPaginationButtons = () => {
    const totalPages = Math.ceil(
      (search ? cardsFiltered.length : cards.length) / cardsPerPage
    );

    return (
      <Grow in={true} {...{ timeout: 800 }}>
        <S.PaginationContainer>
          <S.NavigationButton onClick={goToFirstPage}>
            <S.FirstIcon />
          </S.NavigationButton>
          <S.NavigationButton onClick={goToPreviousPage}>
            <S.PrevIcon />
          </S.NavigationButton>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <S.NavigationButton onClick={goToNextPage}>
            <S.NextIcon />
          </S.NavigationButton>
          <S.NavigationButton onClick={goToLastPage}>
            <S.LastIcon />
          </S.NavigationButton>
        </S.PaginationContainer>
      </Grow>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {hasReadPermission ? (
          <>
            {renderSubHeaderAndSearchBar()}
            {cards?.length > 0 ? renderDownloadButtons() : <></>}
            {RenderFilterContainer()}
            {isLoading || loading ? (
              <>
                <LoadingIndicator loading={isLoading || loading} />
              </>
            ) : (
              <>
                {renderContent()}
                {renderModals()}
              </>
            )}
          </>
        ) : (
          <div style={{ marginTop: '35px' }}>
            <IoseAlertLoadingCards
              text={
                'Seu grupo de usuários não tem permissão de leitura destes dados!'
              }
            />
          </div>
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

ConsumerGroupContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
