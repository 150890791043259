import { useContext } from 'react';
import { ioseAPI, AccountContext, Messages } from 'common';
import { useQuery } from '@tanstack/react-query';

function useSummaryBills() {
  const { getSession } = useContext(AccountContext);

  const page_size = 300;
  const page_code = 5000;

  const { headers } = getSession();

  const getAllSummaryBills = async () => {
    const response = await ioseAPI.getAllBills(headers, page_code, page_size);

    const summaryBills = response.data.data || [];

    if (summaryBills.length === 0) {
      console.error(Messages.noRegisteredEnergyBills);
      throw new Error(Messages.noRegisteredEnergyBills);
    }

    return summaryBills;
  };

  const { data: summaryBills, error: summaryBillsError } = useQuery(
    ['summaryBills'],
    getAllSummaryBills
  );

  return {
    summaryBills,
    summaryBillsError,
  };
}

export default useSummaryBills;
