import React, { useState } from 'react';
import PropType from 'prop-types';
import { Grow, Tooltip } from '@material-ui/core';

import {
  IoseEditButton,
  IoseMoreOptions,
  IoseOpenChartModal,
  IoseOpenObjectsModal,
  IoseDeleteInputModal,
} from 'components';

import * as S from './style';

export default function IoseCardWithInfo({
  name,
  description,
  handleOpenEditModal,
  handleOpenObjectsModal,
  handleOpenChartModal,
  onClick,
  cardDeleteTitle,
  cardEditTitle,
  modalTitle,
  chartModalTitle,
  cardIcon,
  showDeleteButton,
  showEditButton,
  showChartButton,
  selectedCard,
}) {
  const [moreOptions, setMoreOptions] = useState(false);

  const handleClick = () => {
    onClick();
    setMoreOptions(!moreOptions);
  };

  const renderButtonsCard = () => {
    const ObjectsData = [
      { title: 'Unidade', value: selectedCard?.unity_name ?? '' },
      { title: 'Total de objetos', value: selectedCard?.objects?.length },
      { title: 'Descrição', value: selectedCard?.description ?? '' },
      { title: 'Código do módulo', value: selectedCard?.code_module },
      {
        title: 'Possui Relé',
        value: selectedCard?.has_relay === true ? 'Sim' : 'Não',
      },
      {
        title: 'Unidade Consumidora',
        value: selectedCard?.identification_number
          ? selectedCard?.identification_number
          : 'Não cadastrado',
      },
    ];
    return (
      <Grow in={true} {...{ timeout: 1000 }}>
        <S.DivMoreInfo>
          <S.DivWrapperInfos>
            {ObjectsData?.map((info, index) => (
              <S.DivInfos key={index}>
                <p>
                  <strong>{info.title}: </strong>
                  <span>
                    <i>{info.value}</i>
                  </span>
                </p>
              </S.DivInfos>
            ))}
          </S.DivWrapperInfos>
          <S.DivButtons>
            {showDeleteButton === true ? (
              <IoseDeleteInputModal
                title={cardDeleteTitle}
                name={name}
                cardbutton={'true'}
                primarybutton={'true'}
              />
            ) : (
              <></>
            )}
            {showEditButton === true ? (
              <IoseEditButton
                primary="true"
                card="true"
                tooltip={cardEditTitle}
                onClick={() => handleOpenEditModal()}
              />
            ) : (
              <></>
            )}

            <IoseOpenObjectsModal
              primary="true"
              card="true"
              tooltip={modalTitle}
              onClick={() => handleOpenObjectsModal()}
            />

            {showChartButton ? (
              <IoseOpenChartModal
                primary="true"
                card="true"
                tooltip={chartModalTitle}
                onClick={() => handleOpenChartModal()}
              />
            ) : (
              <></>
            )}
          </S.DivButtons>
        </S.DivMoreInfo>
      </Grow>
    );
  };

  const renderSubtitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{description}</p>
    </Tooltip>
  );

  const renderTitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{name}</p>
    </Tooltip>
  );

  return (
    <Grow in={true} {...{ timeout: 1000 }} onClick={handleClick}>
      <S.CardStyled open={moreOptions}>
        <S.DivHeaderButton disableSpacing>
          <S.CardHeaderStyled
            avatar={<S.AvatarStyled>{cardIcon}</S.AvatarStyled>}
            title={renderTitle()}
            subheader={renderSubtitle()}
          ></S.CardHeaderStyled>
          <div>
            <IoseMoreOptions
              primary="true"
              card="true"
              onClick={() =>
                moreOptions ? setMoreOptions(false) : setMoreOptions(true)
              }
            />
          </div>
        </S.DivHeaderButton>

        <S.CardContentStyled>
          {moreOptions && renderButtonsCard()}
          {/* {renderData()} */}
        </S.CardContentStyled>
      </S.CardStyled>
    </Grow>
  );
}

IoseCardWithInfo.propTypes = {
  /** This prop get the name of unity */
  name: PropType.string,

  /** This prop get the description of unity */
  description: PropType.string,

  /** This prop get the function when click on the button delete*/
  clickDelete: PropType.func,

  /** This prop get the function when click on the button edit*/
  clickEdit: PropType.func,

  /** This prop get the function when click on the button schedule*/
  clickSchedule: PropType.func,

  /** This prop get the function when click on the card */
  clickCard: PropType.func,
};
