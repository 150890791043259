/* eslint-disable camelcase */
/* eslint-disable no-useless-concat */
import React, { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as LayerGroup,
} from 'ol/layer';
import { OSM, Vector as VectorSource } from 'ol/source';
import {
  MousePosition,
  OverviewMap,
  Rotate,
  ScaleLine,
  Zoom,
  FullScreen,
  Attribution,
  ZoomToExtent,
} from 'ol/control';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { format as formatCoordinate } from 'ol/coordinate';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import { Style, Stroke, Circle as CircleStyle, Fill } from 'ol/style';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import GeoJSON from 'ol/format/GeoJSON';
import { fromLonLat } from 'ol/proj';

import Overlay from 'ol/Overlay';
import 'ol-ext/dist/ol-ext.css';
import SearchPhoton from 'ol-ext/control/SearchPhoton';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { useSystemUrls } from 'hooks';

// Import from Map.ccs
import './map2.css';

export default function MapTelemetryComponent() {
  const [open, setOpen] = useState(true);

  const [totalTele, setTotalTele] = useState(0);
  let [totalGSO, setTotalGSO] = useState(0);
  let [totalGGML, setTotalGGML] = useState(0);
  let [totalGGSD, setTotalGGSD] = useState(0);
  let [totalGGND, setTotalGGND] = useState(0);
  let [totalGGNO, setTotalGGNO] = useState(0);
  let [totalGGRPV, setTotalGRPV] = useState(0);
  let [totalGRAR, setTotalGRAR] = useState(0);
  let [totalGRCP, setTotalGRCP] = useState(0);
  let [totalGRMA, setTotalGRMA] = useState(0);
  let [totalGRSP, setTotalGRSP] = useState(0);
  let [totalGIDLD, setTotalGIDLD] = useState(0);
  let [totalGRUM, setTotalGRUM] = useState(0);
  let [totalGRAP, setTotalGRAP] = useState(0);
  let [totalGRCM, setTotalGRCM] = useState(0);
  let [totalGRTB, setTotalGRTB] = useState(0);
  let [totalGRTO, setTotalGRTO] = useState(0);
  let [totalGRGA, setTotalGRGA] = useState(0);
  let [totalGPDAG, setTotalGPDAG] = useState(0);
  let [totalGRCA, setTotalGRCA] = useState(0);
  let [totalGRFI, setTotalGRFI] = useState(0);
  let [totalGRPG, setTotalGRPG] = useState(0);
  let [totalGRLI, setTotalGRLI] = useState(0);
  let [totalGRFB, setTotalGRFB] = useState(0);
  let [totalGRUV, setTotalGRUV] = useState(0);
  let [totalGGRPB, setTotalGRPB] = useState(0);

  const { systemUrls } = useSystemUrls();

  const url_telemetria_orgao =
    systemUrls?.find((item) => item.attribute === 'map_url_telemetria_pontos')
      ?.url || null;
  const comunica =
    systemUrls?.find((item) => item.attribute === 'map_url_telemetria_comunica')
      ?.url || null;

  const urlOrgao =
    systemUrls?.find((item) => item.attribute === 'map_url_telemetria_orgao')
      ?.url || null;
  const naoComunica =
    systemUrls?.find(
      (item) => item.attribute === 'map_url_telemetria_nao_comunica'
    )?.url || null;

  const url_telemetria_regiao = urlOrgao;

  const Telemetria =
    systemUrls?.find((item) => item.attribute === 'map_url_telemetria')?.url ||
    null;

  function generateFilteredUrl22(regiao) {
    const cqlFilter2 = `&cql_filter=regiao='${regiao}'`;
    const url = `${url_telemetria_regiao}${encodeURIComponent(cqlFilter2)}`;

    return url;
  }
  function generateFilteredUrl4(camada2) {
    const encodedUrl = encodeURIComponent(urlOrgao);
    return encodedUrl;
  }

  //essa é para o orgão -ibício
  function generateFilteredUrlOrgao(orgao) {
    const cqlFilter = `&cql_filter=orgao_pagador=%27${orgao}%27`;
    const encodedCqlFilter = `${url_telemetria_orgao}${encodeURIComponent(
      cqlFilter
    )}`;

    return encodedCqlFilter;
  }

  function generateFilteredUrlOrgao2(orgao) {
    const cqlFilter = `&cql_filter=orgao_pagador=%27${orgao}%27`;
    const encodedCqlFilter = `${url_telemetria_regiao}${encodeURIComponent(
      cqlFilter
    )}`;
    return encodedCqlFilter;
  }

  //essa é para o orgão - fim

  //urls para mostrar totais por regiao
  const urlregiaoggso = decodeURIComponent(generateFilteredUrl22('GGSO'));
  const urlregiaoggml = decodeURIComponent(generateFilteredUrl22('GGML'));
  const urlregiaoggsd = decodeURIComponent(generateFilteredUrl22('GGSD'));
  const urlregiaoggnd = decodeURIComponent(generateFilteredUrl22('GGND'));
  const urlregiaoggno = decodeURIComponent(generateFilteredUrl22('GGNO'));
  //urls para mostrar totais por orgão pagador
  const url_orgao_GRPV = decodeURIComponent(generateFilteredUrlOrgao2('GRPV'));
  const url_orgao_GRAR = decodeURIComponent(generateFilteredUrlOrgao2('GRAR'));
  const url_orgao_GRCP = decodeURIComponent(generateFilteredUrlOrgao2('GRCP'));
  const url_orgao_GRMA = decodeURIComponent(generateFilteredUrlOrgao2('GRMA'));
  const url_orgao_GRSP = decodeURIComponent(generateFilteredUrlOrgao2('GRSP'));
  const url_orgao_GIDLD = decodeURIComponent(
    generateFilteredUrlOrgao2('GIDLD')
  );
  const url_orgao_GRUM = decodeURIComponent(generateFilteredUrlOrgao2('GRUM'));
  const url_orgao_GRAP = decodeURIComponent(generateFilteredUrlOrgao2('GRAP'));
  const url_orgao_GRCM = decodeURIComponent(generateFilteredUrlOrgao2('GRCM'));
  const url_orgao_GRTB = decodeURIComponent(generateFilteredUrlOrgao2('GRTB'));
  const url_orgao_GRTO = decodeURIComponent(generateFilteredUrlOrgao2('GRTO'));
  const url_orgao_GRGA = decodeURIComponent(generateFilteredUrlOrgao2('GRGA'));
  const url_orgao_GPDAG = decodeURIComponent(
    generateFilteredUrlOrgao2('GPDAG')
  );
  const url_orgao_GRCA = decodeURIComponent(generateFilteredUrlOrgao2('GRCA'));
  const url_orgao_GRFI = decodeURIComponent(generateFilteredUrlOrgao2('GRFI'));
  const url_orgao_GRPG = decodeURIComponent(generateFilteredUrlOrgao2('GRPG'));
  const url_orgao_GRLI = decodeURIComponent(generateFilteredUrlOrgao2('GRLI'));
  const url_orgao_GRFB = decodeURIComponent(generateFilteredUrlOrgao2('GRFB'));
  const url_orgao_GRUV = decodeURIComponent(generateFilteredUrlOrgao2('GRUV'));
  const url_orgao_GRPB = decodeURIComponent(generateFilteredUrlOrgao2('GRPB'));

  const url_regiao = decodeURIComponent(generateFilteredUrl4('regiao'));

  function generateFilteredUrlRegiao(regiao) {
    const cqlFilter = `&cql_filter=regiao=%27${regiao}%27`;
    const encodedCqlFilter = `${url_regiao}${encodeURIComponent(cqlFilter)}`;
    return encodedCqlFilter;
  }
  // url para mostrar os vetores da vm
  const urlGGML = decodeURIComponent(generateFilteredUrlRegiao('GGML'));
  const urlGGND = decodeURIComponent(generateFilteredUrlRegiao('GGND'));

  const urlGGNO = decodeURIComponent(generateFilteredUrlRegiao('GGNO'));
  const urlGGSD = decodeURIComponent(generateFilteredUrlRegiao('GGSD'));
  const urlGGSO = decodeURIComponent(generateFilteredUrlRegiao('GGSO'));

  const urlGRPV = decodeURIComponent(generateFilteredUrlOrgao('GRPV'));
  const urlGRAR = decodeURIComponent(generateFilteredUrlOrgao('GRAR'));
  const urlGRCP = decodeURIComponent(generateFilteredUrlOrgao('GRCP'));
  const urlGRMA = decodeURIComponent(generateFilteredUrlOrgao('GRMA'));
  const urlGRSP = decodeURIComponent(generateFilteredUrlOrgao('GRSP'));
  const urlGIDLD = decodeURIComponent(generateFilteredUrlOrgao('GIDLD'));
  const urlGRUM = decodeURIComponent(generateFilteredUrlOrgao('GRUM'));
  const urlGRAP = decodeURIComponent(generateFilteredUrlOrgao('GRAP'));
  const urlGRCM = decodeURIComponent(generateFilteredUrlOrgao('GRCM'));
  const urlGRTB = decodeURIComponent(generateFilteredUrlOrgao('GRTB'));
  const urlGRTO = decodeURIComponent(generateFilteredUrlOrgao('GRTO'));
  const urlGRGA = decodeURIComponent(generateFilteredUrlOrgao('GRGA'));
  const urlGPDAG = decodeURIComponent(generateFilteredUrlOrgao('GPDAG'));
  const urlGRCA = decodeURIComponent(generateFilteredUrlOrgao('GRCA'));
  const urlGRFI = decodeURIComponent(generateFilteredUrlOrgao('GRFI'));
  const urlGRPG = decodeURIComponent(generateFilteredUrlOrgao('GRPG'));
  const urlGRLI = decodeURIComponent(generateFilteredUrlOrgao('GRLI'));
  const urlGRFB = decodeURIComponent(generateFilteredUrlOrgao('GRFB'));
  const urlGRUV = decodeURIComponent(generateFilteredUrlOrgao('GRUV'));
  const urlGRPB = decodeURIComponent(generateFilteredUrlOrgao('GRPB'));

  const [totalComunicando, setTotalComunicando] = useState(0);
  const [totalNaoComunicando, setTotalNaoComunicando] = useState(0);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [orgaoSubMenuOpen, setOrgaoSubMenuOpen] = useState(false);
  const [telemetriaOpen, setTelemetriaOpen] = useState(false);
  // eslint-disable-next-line
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef();

  const history = useHistory();
  const mapRef = useRef(null);
  const viewRef = useRef(null);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    // remover o event listener
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line
  }, []);

  const handleButtonClick = (e) => {
    e.stopPropagation();
    setInputVisible(!inputVisible);
    setInputValue('');
  };

  const handleDocumentClick = (e) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(e.target) &&
      !e.target.matches('#botao')
    ) {
      setInputVisible(false);
      setInputValue('');
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputSubmit = (e) => {
    e.preventDefault();

    setInputVisible(false);
    setInputValue('');
  };

  const menuRef = useRef();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([-50.834076, -24.69453]),
        zoom: 8,
      }),
      controls: [],
    });
    viewRef.current = map.getView();

    const rotateControl = new Rotate({
      tipLabel: 'Redefinir Norte (Shift + Alt para rotacionar)',
      autoHide: false,
    });
    map.addControl(rotateControl);

    const scaleLineControl = new ScaleLine();
    map.addControl(scaleLineControl);

    const zoomControl = new Zoom({
      zoomInTipLabel: 'Aproximar (Shift + Mouse)',
      zoomOutTipLabel: 'Afastar',
    });
    map.addControl(zoomControl);

    const attributionControl = new Attribution({
      collapsible: true,
    });
    map.addControl(attributionControl);

    const fullScreenControl = new FullScreen({
      tipLabel: 'Mudar para tela cheia',
    });
    map.addControl(fullScreenControl);

    const zoomSliderControl = new Zoom();
    map.addControl(zoomSliderControl);

    const mousePositionControl = new MousePosition({
      coordinateFormat(coordinate) {
        return `${formatCoordinate(coordinate, '{y}, {x}', 6)} (WGS 84)`;
      },
      projection: 'EPSG:4326',
      className: 'custom-mouse-position',
      target: document.getElementById('map'),
      undefinedHTML: '&nbsp;',
    });
    map.addControl(mousePositionControl);

    const zoomToExtentControl = new ZoomToExtent({
      tipLabel: 'Zoom para a extensão total do mapa',
      extent: map.getView().calculateExtent(map.getSize()),
      className: 'custom-zoom-to-extent-control',
    });

    map.getView().on('change:center', function () {
      const extent = map.getView().calculateExtent(map.getSize());
      zoomToExtentControl.setProperties({ extent });
    });
    map.getView().on('change:resolution', function () {
      const extent = map.getView().calculateExtent(map.getSize());
      zoomToExtentControl.setProperties({ extent });
    });

    map.addControl(zoomToExtentControl);

    const overviewMapControl = new OverviewMap({
      collapsed: false,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new ImageLayer({
          source: new ImageWMS({}),
        }),
      ],
    });
    map.addControl(overviewMapControl);

    const defaultStyle = new Style({
      image: new CircleStyle({
        radius: 4.5,
        fill: new Fill({ color: 'rgba(255, 255, 0, 0.0)' }), // Amarelo com 50% de opacidade
        stroke: new Stroke({ color: '#000000', width: 0.4 }),
      }),
    });

    const src_telemetria2 = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: Telemetria,
    });

    const telemetria_selecionados2 = new VectorLayer({
      name: 'telemetria',
      title: 'Telemetria',
      source: src_telemetria2,
      visible: true,
      zIndex: 1,
      style: defaultStyle,
    });

    const src_telemetria_nao_comunica = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: naoComunica,
    });

    const telemetria_nao_comunica = new VectorLayer({
      name: 'telemetriaNao',
      title: 'TelemetriaNao',
      source: src_telemetria_nao_comunica,
      visible: false,
      zIndex: 1,
      // style: defaultStyle,
    });
    const src_telemetria_comunica = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: comunica,
    });

    const telemetria_comunica = new VectorLayer({
      name: 'telemetriaComunica',
      title: 'TelemetriaComunica',
      source: src_telemetria_comunica,
      visible: false,
      zIndex: 1,
      // style: defaultStyle,
    });

    //camadas - início

    var src_orgao_pagador = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: url_telemetria_orgao,
    });

    var orgao_pagador = new VectorLayer({
      name: 'orgao_pagador',
      title: 'Orgao',
      source: src_orgao_pagador,
      visible: false,
    });

    //regiões - início
    var src_regiao = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: url_telemetria_regiao,
    });

    var regiao = new VectorLayer({
      name: 'regiao',
      title: 'regiao',
      source: src_regiao,
      visible: false,
    });

    var src_regiao_ggml = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGML,
    });

    var regiao_ggml = new VectorLayer({
      name: 'ggml',
      title: 'ggml',
      source: src_regiao_ggml,
      visible: false,
    });

    var src_regiao_ggnd = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGND,
    });

    var regiao_ggnd = new VectorLayer({
      name: 'ggnd',
      title: 'ggnd',
      source: src_regiao_ggnd,
      visible: false,
    });

    var src_regiao_ggno = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGNO,
    });

    var regiao_ggno = new VectorLayer({
      name: 'ggno',
      title: 'ggno',
      source: src_regiao_ggno,
      visible: false,
    });

    var src_regiao_ggsd = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGSD,
    });

    var regiao_ggsd = new VectorLayer({
      name: 'ggsd',
      title: 'ggsd',
      source: src_regiao_ggsd,
      visible: false,
    });

    var src_regiao_ggso = new VectorSource({
      format: new GeoJSON(),
      attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
      url: urlGGSO,
    });

    var regiao_ggso = new VectorLayer({
      name: 'ggso',
      title: 'ggso',
      source: src_regiao_ggso,
      visible: false,
    });

    //regiões - fim

    // Definir informações das camadas
    const orgaoLayers = [
      { name: 'grpv', url: urlGRPV },
      { name: 'grar', url: urlGRAR },
      { name: 'gcrp', url: urlGRCP },
      { name: 'grma', url: urlGRMA },
      { name: 'grsp', url: urlGRSP },
      { name: 'gidld', url: urlGIDLD },
      { name: 'grum', url: urlGRUM },
      { name: 'grap', url: urlGRAP },
      { name: 'grcm', url: urlGRCM },
      { name: 'grtb', url: urlGRTB },
      { name: 'grto', url: urlGRTO },
      { name: 'grga', url: urlGRGA },
      { name: 'gpdag', url: urlGPDAG },
      { name: 'grca', url: urlGRCA },
      { name: 'grfi', url: urlGRFI },
      { name: 'grpg', url: urlGRPG },
      { name: 'grli', url: urlGRLI },
      { name: 'grfb', url: urlGRFB },
      { name: 'gruv', url: urlGRUV },
      { name: 'grpb', url: urlGRPB },
    ];

    // Função para criar camadas
    function createOrgaoLayer(layerInfo) {
      const source = new VectorSource({
        format: new GeoJSON(),
        attributions: '<a href="https://avotech.com.br/">' + 'Avotech' + '</a>',
        url: layerInfo.url,
      });

      return new VectorLayer({
        name: layerInfo.name,
        title: layerInfo.name,
        source: source,
        visible: false,
      });
    }

    // Criar camadas para cada objeto no array orgaoLayers
    const orgaoLayersArray = orgaoLayers.map((layerInfo) =>
      createOrgaoLayer(layerInfo)
    );
    //camadas -fim

    const url = src_telemetria2.getUrl();

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Erro na requisição: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const { features } = data;
        telemetria_selecionados2.getSource().clear();

        features.forEach((feature) => {
          const relayState = feature.properties.relay_state;
          const ultima_comunicacao = new Date(feature.properties.arrival_date);

          const agora = new Date();
          const diferencaEmMilissegundos = agora - ultima_comunicacao;
          const diferencaEmHoras = Math.floor(
            diferencaEmMilissegundos / (1000 * 60 * 60)
          );

          let color;

          if (relayState === 'true' && diferencaEmHoras < 24) {
            color = 'rgba(255, 255, 0, 0.8)';
          } else if (relayState === 'false' || diferencaEmHoras >= 24) {
            color = 'rgba(255, 0, 0, 0.8)';
          } else {
            color = 'rgba(76, 255, 0, 0.8)';
          }

          const style = new Style({
            image: new CircleStyle({
              radius: 4.5,
              fill: new Fill({ color }),
              stroke: new Stroke({ color: '#000000', width: 0.4 }),
            }),
          });

          const pointFeature = new Feature(
            new Point(
              fromLonLat([
                feature.geometry.coordinates[0],
                feature.geometry.coordinates[1],
              ])
            )
          );
          pointFeature.setStyle(style);
          // telemetria_selecionados2.getSource().clear();

          telemetria_selecionados2.getSource().addFeature(pointFeature);
        });
      })

      .catch((error) => {
        console.error('Erro durante o fetch:', error.message);
      });

    //função para pegar os totais de telemetria, dos que estão true e false - início
    function getTotalFeatures() {
      return fetch(src_telemetria2.getUrl())
        .then((response) => response.json())
        .then((data) => {
          let trueCount = 0;
          let falseCount = 0;

          const agora = new Date();

          for (const feature of data.features) {
            const ultimaComunicacao = new Date(feature.properties.arrival_date);
            const diferencaEmMilissegundos = agora - ultimaComunicacao;
            const diferencaEmHoras =
              diferencaEmMilissegundos / (1000 * 60 * 60);

            if (
              feature.properties.relay_state === 'true' &&
              diferencaEmHoras < 24
            ) {
              trueCount++;
            } else if (
              feature.properties.relay_state === 'false' ||
              diferencaEmHoras >= 24
            ) {
              falseCount++;
            }
          }

          return {
            totalFeatures: data.totalFeatures,
            trueCount,
            falseCount,
          };
        })
        .catch((error) => {
          console.error('Error retrieving totalFeatures:', error);
          return {
            totalFeatures: 0,
            trueCount: 0,
            falseCount: 0,
          };
        });
    }

    getTotalFeatures().then(({ totalFeatures, trueCount, falseCount }) => {
      const totalTele = totalFeatures || 0;
      document.getElementById('totalTele').innerHTML = `(${totalTele})`;
      setTotalTele(totalTele);

      const totalComunicando = trueCount;
      document.getElementById(
        'totalComunicando'
      ).innerHTML = `(${totalComunicando})`;
      setTotalComunicando(totalComunicando);

      const totalNaoComunicando = falseCount;
      document.getElementById(
        'totalNaoComunicando'
      ).innerHTML = `(${totalNaoComunicando})`;
      setTotalNaoComunicando(totalNaoComunicando);
    });

    //função para pegar os totais de telemetria, dos que estão true e false - fim

    //totais dos restos -início
    function getTotalFeaturesregiao(url, setter) {
      return new Promise((resolve, reject) => {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            const totalFeatures = data.totalFeatures;
            resolve(totalFeatures);
            setter(totalFeatures);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }

    getTotalFeaturesregiao(urlregiaoggso, setTotalGSO).catch((error) => {});
    getTotalFeaturesregiao(urlregiaoggml, setTotalGGML).catch((error) => {});
    getTotalFeaturesregiao(urlregiaoggsd, setTotalGGSD).catch((error) => {});
    getTotalFeaturesregiao(urlregiaoggnd, setTotalGGND).catch((error) => {});
    getTotalFeaturesregiao(urlregiaoggno, setTotalGGNO).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRPV, setTotalGRPV).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRAR, setTotalGRAR).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRCP, setTotalGRCP).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRMA, setTotalGRMA).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRSP, setTotalGRSP).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GIDLD, setTotalGIDLD).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRUM, setTotalGRUM).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRAP, setTotalGRAP).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRCM, setTotalGRCM).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRTB, setTotalGRTB).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRTO, setTotalGRTO).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRGA, setTotalGRGA).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GPDAG, setTotalGPDAG).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRCA, setTotalGRCA).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRFI, setTotalGRFI).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRPG, setTotalGRPG).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRLI, setTotalGRLI).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRUV, setTotalGRUV).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRPB, setTotalGRPB).catch((error) => {});
    getTotalFeaturesregiao(url_orgao_GRFB, setTotalGRFB).catch((error) => {});

    //totais dos restos - fim

    const info = document.createElement('div');
    info.classList.add('info');
    const overlay = new Overlay({ element: info });
    map.addOverlay(overlay);

    const displayPopup = (evt) => {
      info.style.display = 'none';
      info.classList.remove('show');
      info.classList.remove('close');

      const feature = map.forEachFeatureAtPixel(
        evt.pixel,
        (feature) => feature
      );

      if (feature) {
        const regiaoLayer = feature?.getId();

        if (
          regiaoLayer?.indexOf('regiao') !== -1 ||
          regiaoLayer?.indexOf('pontos_geo') !== -1
        ) {
          let nome;
          if (regiaoLayer?.indexOf('regiao') !== -1) {
            nome = feature.get('regiao');
          } else if (regiaoLayer?.indexOf('pontos_geo') !== -1) {
            nome = feature.get('orgao_pagador');
          }
          const atributos = `<b><font color="#090e66">Nome: </font color></b>${nome}`;

          info.innerHTML = '';
          info.style.display = atributos ? '' : 'none';
          info.innerHTML = atributos;

          setTimeout(() => {
            info.style.display = 'block';
            info.classList.add('show');
          }, 12);

          overlay.setPosition(evt.coordinate);

          feature.setStyle(
            new Style({
              fill: new Fill({
                color: [245, 229, 9, 0.2],
              }),
              stroke: new Stroke({
                color: [255, 255, 0, 0.5],
                width: 1.5,
              }),
            })
          );

          map.once('click', (event) => {
            const clickedFeature = map.forEachFeatureAtPixel(
              map.getEventPixel(event.originalEvent),
              (feature) => feature
            );

            if (!clickedFeature || clickedFeature !== feature) {
              feature.setStyle(null);
            }
          });
        } else {
          const unidade_operacional = feature.get('unidade');
          let status = feature.get('relay_state');
          let ultimaComunicacao = feature.get('arrival_date');
          const endereco = feature.get('endereco');
          const concessionaria = feature.get('concessionaria');
          const identification_number = feature.get('identification_number');
          const uuidCircuit = feature.get('uuid_circuit');
          const regiao = feature.get('regiao');
          const orgao = feature.get('orgao_pagador');
          const municipio = feature.get('municipio');
          const importActiveEnergy = feature.get('import_active_energy');
          const importReactiveEnergy = feature.get('import_reactive_energy');

          // const agora = new Date();
          // const ultimaComunicacaoDate = new Date(ultimaComunicacao);
          // const diferencaEmMilissegundos = agora - ultimaComunicacaoDate;
          // const diferencaEmHoras = diferencaEmMilissegundos / (1000 * 60 * 60);

          if (status === 'true') {
            status = 'Comunicando';
          } else {
            status = 'Sem comunicação';
          }

          const dataObj = new Date(ultimaComunicacao);
          if (!isNaN(dataObj.getTime())) {
            ultimaComunicacao = format(dataObj, 'dd/MM/yyyy HH:mm:ss');
          } else {
            ultimaComunicacao = 'Data inválida';
          }

          let atributos =
            `<b><font color="#090e66">ID do circuito: </font color></b>` +
            `<font color="#090e66"> ${uuidCircuit}</a></font>` +
            `<br>` +
            `<b>Unidade consumidora: </b>${identification_number}<br>` +
            `<b>Unidade operacional: </b>${unidade_operacional}<br>` +
            `<b>Estado da telemetra: </b>${status}<br>` +
            `<b>Última comunicação: </b>${ultimaComunicacao}<br>` +
            `<b>Energia Ativa: </b>${importActiveEnergy}</br>` +
            `<b>Energia Reativa: </b>${importReactiveEnergy}</br>` +
            `<b>Endereço: </b>${endereco}</br>` +
            `<b>Município: </b>${municipio}</br>` +
            `<b>Concessionária: </b>${concessionaria}</br>` +
            `<b>Gerencia geral: </b>${regiao}</br>` +
            `<b>Gerencia regional: </b>${orgao}</br>` +
            // `<b>Latitude: </b>${latitude}</br>` +
            // `<b>Longitude: </b>${longitude}</br>` +
            `<button class="redirect-button" id="myButton">Ir para o Dashboard</button>`;

          info.innerHTML = '';
          info.style.display = atributos ? '' : 'none';
          info.innerHTML = atributos;

          setTimeout(() => {
            info.style.display = 'block';
            info.classList.add('show');
          }, 12);

          overlay.setPosition(evt.coordinate);

          feature.setStyle(
            new Style({
              image: new CircleStyle({
                radius: 10,
                fill: new Fill({ color: [245, 229, 9, 0.7] }),
              }),
            })
          );

          map.on('click', (event) => {
            if (!event.target || !event.target.getProperties().feature) {
              feature.setStyle(null);
            }
          });

          info.style.maxWidth = '100%';
          info.style.width = '100%';
          info.style.padding = '10px';

          const smallScreenQuery = window.matchMedia('(max-width: 600px)');
          if (smallScreenQuery.matches) {
            info.style.maxWidth = '80%';
          }

          atributos = '';

          const featureSession = String(
            feature?.values_?.identification_number
          );
          const buttonElement = document.getElementById('myButton');
          buttonElement.addEventListener('click', handleButtonClick);

          function handleButtonClick() {
            history.push({
              pathname: '/consumer-group-dashboard',
              state: featureSession,
            });
          }
        }
      }
    };

    map.on('singleclick', displayPopup);

    //seleionar comunicando e não comunicando - inicio

    //seleionar comunicando e não comunicando - fim

    const search = new SearchPhoton({
      target: document.getElementById('map'),
      lang: 'fr',
      reverse: true,
      position: true,
    });
    map.addControl(search);

    search.on('select', function (e) {
      map.getView().animate({
        center: e.coordinate,
        zoom: Math.max(map.getView().getZoom(), 16),
      });
    });

    const baseLayerGroup = new LayerGroup();
    // Adicionar camadas ao baseLayerGroup
    baseLayerGroup.getLayers().push(orgao_pagador);
    baseLayerGroup.getLayers().push(regiao);
    baseLayerGroup.getLayers().push(regiao_ggml);
    baseLayerGroup.getLayers().push(regiao_ggnd);
    baseLayerGroup.getLayers().push(regiao_ggno);
    baseLayerGroup.getLayers().push(regiao_ggsd);
    baseLayerGroup.getLayers().push(regiao_ggso);
    baseLayerGroup.getLayers().push(telemetria_selecionados2);
    baseLayerGroup.getLayers().push(telemetria_nao_comunica);
    baseLayerGroup.getLayers().push(telemetria_comunica);

    // Adicionar as camadas do orgaoLayersArray
    orgaoLayersArray.forEach((layer) => {
      baseLayerGroup.getLayers().push(layer);
    });
    map.addLayer(baseLayerGroup);

    const baseLayerElements = document.querySelectorAll(
      '.dropdown-menu-telemetria input[type="checkbox"]'
    );

    for (let element of baseLayerElements) {
      element.addEventListener('change', function (e) {
        let elementValue = this.value;
        let isChecked = this.checked;

        baseLayerGroup.getLayers().forEach(function (layer) {
          let baseLayerTitle = layer.get('title');

          if (baseLayerTitle === elementValue) {
            layer.setVisible(isChecked);
            if (
              baseLayerTitle === 'TelemetriaComunica' ||
              baseLayerTitle === 'TelemetriaNao'
            ) {
              const style = new Style({
                image: new CircleStyle({
                  radius: 4.5,
                  fill: new Fill({
                    color: isChecked ? '#e3dd9f' : '#4dff00',
                  }),
                  stroke: new Stroke({
                    color: isChecked ? 'rgba(255, 129, 0, 0.6)' : '#000000',
                    width: 1.5,
                  }),
                }),
                zIndex: isChecked ? 1 : 0, // Colocar a camada selecionada acima das outras
              });
              layer.setStyle(style);
            } else if (
              baseLayerTitle === 'ggml' ||
              baseLayerTitle === 'ggsd' ||
              baseLayerTitle === 'ggno' ||
              baseLayerTitle === 'ggso' ||
              baseLayerTitle === 'ggnd' ||
              baseLayerTitle === 'grpv' ||
              baseLayerTitle === 'grar' ||
              baseLayerTitle === 'gcrp' ||
              baseLayerTitle === 'grma' ||
              baseLayerTitle === 'grsp' ||
              baseLayerTitle === 'gidld' ||
              baseLayerTitle === 'grum' ||
              baseLayerTitle === 'grap' ||
              baseLayerTitle === 'grcm' ||
              baseLayerTitle === 'grtb' ||
              baseLayerTitle === 'grto' ||
              baseLayerTitle === 'grga' ||
              baseLayerTitle === 'gpdag' ||
              baseLayerTitle === 'grca' ||
              baseLayerTitle === 'grfi' ||
              baseLayerTitle === 'grpg' ||
              baseLayerTitle === 'grli' ||
              baseLayerTitle === 'grfb' ||
              baseLayerTitle === 'gruv' ||
              baseLayerTitle === 'grpb'
            ) {
              const style = new Style({
                fill: new Fill({
                  color: isChecked
                    ? 'rgba(227, 221, 159, 0.6)'
                    : 'rgba(77, 255, 0, 0.3)',
                }),
                stroke: new Stroke({
                  color: isChecked ? 'rgba(255, 129, 0, 0.6)' : '#000000',
                  width: 1.5,
                }),
                zIndex: isChecked ? 1 : 0, // Colocar a camada selecionada acima das outras
              });
              layer.setStyle(style);
            }
          }
        });
      });
    }

    //estilo dosmpontos na Legenda - início
    function coresPontos(id, color) {
      document.body.style.margin = '0';
      document.body.style.padding = '0';
      const radius = 4.5;
      const canvas = document.getElementById(id);
      const ctx = canvas.getContext('2d');
      ctx.beginPath();
      ctx.arc(10, 10, radius, 0, 2 * Math.PI, false);
      ctx.fillStyle = color;
      ctx.fill();
      ctx.lineWidth = 0.1;
      ctx.strokeStyle = '#000000';
      ctx.stroke();
    }
    coresPontos('canvasTotalPontos', 'rgba(76, 255, 0, 0.8)');
    coresPontos('canvasTotalComuicacao', 'rgba(255, 255, 0, 0.8)');
    coresPontos('canvasTotalNaoComuicacao', 'rgba(255, 0, 0, 0.8)');
    //estilo dosmpontos na Legenda - fim

    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      map.setTarget(null);
      document.removeEventListener('mousedown', handler);
    };
    //eslint-disable-next-line
  }, [systemUrls]);

  async function fetchDataFromURL(url) {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
      return null;
    }
  }
  // Função para encontrar a coordenada pelo número da unidade consumidora
  async function findPontoByUnidadeConsumidora(unidadeConsumidora) {
    const data = await fetchDataFromURL(Telemetria);

    if (data) {
      const { features } = data;
      for (const feature of features) {
        const { properties } = feature;

        // Verifica se tem a unidadeConsumidora ou o uuid_circuit
        if (
          properties.identification_number === unidadeConsumidora ||
          properties.uuid_circuit === unidadeConsumidora
        ) {
          const latitude = feature.geometry.coordinates[1];
          const longitude = feature.geometry.coordinates[0];
          return { latitude, longitude };
        }
      }
    }

    return null;
  }
  // Function to set the map view to a new location
  function irParaLocal(longitude_si, latitude_si) {
    const view = viewRef.current;
    if (view) {
      view.animate({
        center: fromLonLat([longitude_si, latitude_si]),
        duration: 2000,
        zoom: 19,
      });
    }
  }

  function showPopup(message) {
    const popupElement = document.getElementById('customPopup');
    const popupMessageElement = document.getElementById('popupMessage');

    popupMessageElement.textContent = message;
    popupElement.classList.add('show');

    // Add a close button ('x') to the popup
    const closeButton = document.createElement('span');
    closeButton.textContent = 'x';
    closeButton.classList.add('closeButton');
    closeButton.addEventListener('click', () => {
      popupElement.classList.remove('show');
    });
    popupElement.appendChild(closeButton);

    // Hide the popup after a few seconds (you can adjust the delay as needed)
    setTimeout(() => {
      popupElement.classList.remove('show');
    }, 15000); // 3000 milliseconds = 3 seconds
  }

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const numeroUnidadeConsumidora = inputValue.trim();
      if (numeroUnidadeConsumidora === '') {
        showPopup('Digite um número de unidade consumidora válido');
      } else {
        try {
          setLoading(true);
          const pontoSelecionado = await findPontoByUnidadeConsumidora(
            numeroUnidadeConsumidora
          );
          if (pontoSelecionado) {
            const { latitude, longitude } = pontoSelecionado;
            // setLatitude(latitude);
            // setLongitude(longitude);
            irParaLocal(longitude, latitude);
          } else {
            showPopup('Número da unidade consumidora não encontrado');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          showPopup('Error fetching data');
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const LayersLegend = [
    {
      value: 'Telemetria',
      label: ` Telemetria
      (${totalTele})`,
    },
    {
      value: 'TelemetriaComunica',
      label: ` Comunicando`,
    },
    {
      value: 'TelemetriaNao',
      label: ` Sem comunicação`,
    },

    {
      value: 'Orgao',
      label: ' GR',
      subvalues: [
        { value: 'grpv', label: ' GRPV' },
        { value: 'grar', label: ' GRAR' },
        { value: 'gcrp', label: ' GRCP' },
        { value: 'grma', label: ' GRMA' },
        { value: 'grsp', label: ' GRSP' },
        { value: 'gidld', label: ' GIDLD' },
        { value: 'grum', label: ' GRUM' },
        { value: 'grap', label: ' GRAP' },
        { value: 'grcm', label: ' GRCM' },
        { value: 'grtb', label: ' GRTB' },
        { value: 'grto', label: ' GRTO' },
        { value: 'grga', label: ' GRGA' },
        { value: 'gpdag', label: ' GPDAG' },
        { value: 'grca', label: ' GRCA' },
        { value: 'grfi', label: ' GRFI' },
        { value: 'grpg', label: ' GRPG' },
        { value: 'grli', label: ' GRLI' },
        { value: 'grfb', label: ' GRFB' },
        { value: 'gruv', label: ' GRUV' },
        { value: 'grpb', label: ' GRPB' },
      ],
    },
    {
      value: 'regiao',
      label: ' GG',
      subvalues: [
        { value: 'ggml', label: ' GGML' },
        { value: 'ggsd', label: ' GGSD' },
        { value: 'ggnd', label: ' GGND' },
        { value: 'ggno', label: ' GGNO' },
        { value: 'ggso', label: ' GGSO' },
      ],
    },
  ];

  function updateLabel(totalValue, subvalueKey) {
    const index = LayersLegend.findIndex((layer) => {
      return (
        layer.subvalues &&
        layer.subvalues.some((subvalue) => subvalue.value === subvalueKey)
      );
    });

    if (index !== -1) {
      const subvalueIndex = LayersLegend[index].subvalues.findIndex(
        (subvalue) => subvalue.value === subvalueKey
      );
      if (subvalueIndex !== -1) {
        LayersLegend[index].subvalues[
          subvalueIndex
        ].label = ` ${subvalueKey.toUpperCase()} (${totalValue})`;
      }
    }
  }
  //regiao - inicio
  updateLabel(totalGSO, 'ggso', 'totalGSO');
  updateLabel(totalGGML, 'ggml', 'totalGGML');
  updateLabel(totalGGSD, 'ggsd', 'totalGGSD');
  updateLabel(totalGGND, 'ggnd', 'totalGGND');
  updateLabel(totalGGNO, 'ggno', 'totalGGNO');
  //regiao - fim
  //orgao pagador - início
  updateLabel(totalGGRPV, 'grpv', 'totalGRPV');
  updateLabel(totalGRAR, 'grar', 'totalGRAR');
  updateLabel(totalGRCP, 'gcrp', 'totalGRCP');
  updateLabel(totalGRMA, 'grma', 'totalGRMA');
  updateLabel(totalGRSP, 'grsp', 'totalGRSP');
  updateLabel(totalGIDLD, 'gidld', 'totalGIDLD');
  updateLabel(totalGRUM, 'grum', 'totalGRUM');
  updateLabel(totalGRAP, 'grap', 'totalGRAP');
  updateLabel(totalGRCM, 'grcm', 'totalGRCM');
  updateLabel(totalGRTB, 'grtb', 'totalGRTB');
  updateLabel(totalGRTO, 'grto', 'totalGRTO');
  updateLabel(totalGRGA, 'grga', 'totalGRGA');
  updateLabel(totalGPDAG, 'gpdag', 'totalGPDAG');
  updateLabel(totalGRCA, 'grca', 'totalGRCA');
  updateLabel(totalGRFI, 'grfi', 'totalGRFI');
  updateLabel(totalGRPG, 'grpg', 'totalGRPG');
  updateLabel(totalGRLI, 'grli', 'totalGRLI');
  updateLabel(totalGRFB, 'grfb', 'totalGRFB');
  updateLabel(totalGRUV, 'gruv', 'totalGRUV');
  updateLabel(totalGGRPB, 'grpb', 'totalGGRPB');
  //orgao pagador - fim

  return (
    <div>
      {/* <MapLoading loading={loadingData} /> */}
      <div className="MapTelemetryComponent">
        <div id="customPopup">
          {/* pop up do input */}
          <p id="popupMessage" />
        </div>
        <div style={{ width: '100%', height: '93vh' }}>
          <div ref={mapRef} style={{ width: '100%', height: '100%' }}>
            {/* criando sidebar do lado direito */}
          </div>
        </div>
        <div className="menu-container" ref={menuRef}>
          {/* Botão adicionado ao mapa */}
          <div id="botao" onClick={handleButtonClick}>
            <button type="button" title="Pesquisa por UC" />
          </div>

          {/* Campo de entrada que aparecerá ao clicar no botão */}
          {inputVisible && (
            <div
              ref={inputRef}
              onSubmit={handleInputSubmit}
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
              onKeyDown={handleKeyDown}
              style={{
                position: 'absolute',
                top: '170px',
                left: '35px',
                zIndex: 9999,
                background: 'rgba(255, 255, 255, 0.7)',
                height: '22px',
                border: '2px solid black',
                borderRadius: '4px',
              }}
            >
              <input
                type="search"
                autoComplete="off"
                placeholder="Digite UC ou Circuito"
                value={inputValue}
                onChange={handleInputChange}
                id="inputNumeroPonto"
                title="Pesquisa por UC"
                style={{
                  border: 'none' /* Remove the input border */,
                  outline: 'none' /* Remove the input outline */,
                  paddingRight: loading ? '30px' : '0',
                }}
              />
              {loading && (
                <div
                  className="loading-circle"
                  style={{
                    position: 'absolute',
                    top: '9%',
                    right: '8px',
                    transform: 'translateY(-50%)',
                  }}
                />
              )}
            </div>
          )}

          <div
            className={`menu-trigger-telemetria ${open ? 'active' : ''}`}
            title="Camadas"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
            style={{
              pointerEvents: 'auto',
              // Defina outras propriedades de estilo conforme necessário
            }}
          />
          <div
            className={`dropdown-menu-telemetria ${
              open ? 'active' : 'inactive'
            }`}
          >
            <h3>
              <u>Camadas</u>
              <br />
            </h3>
            <ul>
              {LayersLegend.map((item) => (
                <label key={item.value} style={{ fontSize: '12px' }}>
                  <input
                    type="checkbox"
                    name="baseLayerCheck"
                    value={item.value}
                    defaultChecked={item.value === 'Telemetria'}
                    // disabled={item.value == 'Pontos Sanepar'}
                    style={{ marginBottom: '10px' }}
                    onClick={(e) => e.stopPropagation()}
                  />
                  {item.label}
                  {item.value === 'regiao' && item.subvalues && (
                    <div
                      className={`submenu-trigger-telemetria ${
                        subMenuOpen ? 'open' : ''
                      }`}
                      onClick={() => setSubMenuOpen(!subMenuOpen)}
                    >
                      <div className="submenu-icon-telemetria">
                        <span className="icon" />
                      </div>
                      <div
                        className={`submenu-telemetria ${
                          subMenuOpen ? 'open' : ''
                        }`}
                      >
                        {item.subvalues.map((subitem) => (
                          <div key={subitem.value} style={{ fontSize: '12px' }}>
                            <input
                              type="checkbox"
                              name="baseLayerCheck"
                              value={subitem.value}
                              style={{ marginBottom: '10px' }}
                              onClick={(e) => {
                                // e.preventDefault();
                                e.stopPropagation();
                              }}
                            />
                            {subitem.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {item.value === 'Pontos Sanepar' && item.subvalues && (
                    <div
                      className={`submenu-trigger-telemetria ${
                        telemetriaOpen ? 'open' : ''
                      }`}
                      onClick={() => setTelemetriaOpen(!telemetriaOpen)}
                    >
                      <div className="submenu-icon-telemetria">
                        <span className="icon" />
                      </div>
                      <div
                        className={`submenu-telemetria ${
                          telemetriaOpen ? 'open' : ''
                        }`}
                      >
                        {item.subvalues.map((subitem) => (
                          <div key={subitem.value} style={{ fontSize: '12px' }}>
                            <input
                              type="checkbox"
                              name="baseLayerCheck"
                              value={subitem.value}
                              style={{ marginBottom: '10px' }}
                              onClick={(e) => {
                                // e.preventDefault();
                                e.stopPropagation();
                              }}
                            />
                            {subitem.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {item.value === 'Orgao' && item.subvalues && (
                    <div
                      className={`submenu-trigger-telemetria ${
                        orgaoSubMenuOpen ? 'open' : ''
                      }`}
                      style={{ maxHeight: '250px', overflowY: 'auto' }}
                      onClick={() => setOrgaoSubMenuOpen(!orgaoSubMenuOpen)}
                    >
                      <div className="submenu-icon-telemetria">
                        <span className="icon" onClickCapture />
                      </div>
                      <div
                        className={`submenu-telemetria ${
                          orgaoSubMenuOpen ? 'open' : ''
                        }`}
                      >
                        {item.subvalues.map((subitem) => (
                          <div key={subitem.value} style={{ fontSize: '11px' }}>
                            <input
                              type="checkbox"
                              name="baseLayerCheck"
                              value={subitem.value}
                              style={{ marginBottom: '10px' }}
                              onClick={(e) => e.stopPropagation()}
                            />
                            {subitem.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <br />
                </label>
              ))}
              <table
                className="tabelaLegenda-telemetria"
                style={{
                  tableLayout: 'fixed',
                  marginTop: '10px',
                  fontSize: '10px',
                }}
              >
                <tbody>
                  <tr height="20">
                    <td colSpan="3">
                      &nbsp;&nbsp;&nbsp;
                      <u>
                        <b>Legenda:</b>
                      </u>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <canvas
                        id="canvasTotalPontos"
                        width="25"
                        height="15"
                        fontSize="12.5px"
                      />
                    </td>
                    <td type="checkbox">
                      Telemetria <span id="totalTele" />
                    </td>
                  </tr>

                  <tr
                    onMouseOver={() => {
                      document.body.style.cursor = 'pointer';
                    }}
                    onMouseOut={() => {
                      document.body.style.cursor = 'auto';
                    }}
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('highlighted');
                    }}
                  >
                    <td>
                      <canvas
                        id="canvasTotalComuicacao"
                        width="25"
                        height="15"
                        fontSize="12.5px"
                      />
                    </td>
                    <td type="checkbox">
                      Comunicando <span id="totalComunicando" />
                    </td>
                  </tr>

                  <tr
                    onMouseOver={() => {
                      document.body.style.cursor = 'pointer';
                    }}
                    onMouseOut={() => {
                      document.body.style.cursor = 'auto';
                    }}
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('highlighted');
                    }}
                  >
                    <td>
                      <canvas
                        id="canvasTotalNaoComuicacao"
                        width="25"
                        height="15"
                        fontSize="12.5px"
                      />
                    </td>
                    <td
                      type="checkbox"
                      title="
                        Telemetria não está medindo
                        Relógio não conectado
                        Aparelho queimado
                        Sem comunicação há 24 horas
                        Sobretenção
                        Curto"
                    >
                      Sem comunicação <span id="totalNaoComunicando" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
