// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useConsumerGroup() {
  const [noConsumerGroupsError, setNoConsumerGroupsError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getConsumerGroups = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllConsumerGroupV2(headers, 3000, 3000);

    const { data } = response.data;

    const consumerGroup = data || [];

    if (consumerGroup.length === 0) {
      setNoConsumerGroupsError(Messages.noRegisteredConsumerGroups);
      throw new Error(Messages.noRegisteredConsumerGroups);
    }
    return consumerGroup;
  };

  const {
    data: consumerGroups,
    error: consumerGroupsError,
    refetch: refreshConsumerGroups,
    isLoading,
  } = useQuery(['consumerGroupData'], getConsumerGroups);

  return {
    consumerGroups,
    consumerGroupsError,
    noConsumerGroupsError,
    refreshConsumerGroups,
    isLoading,
  };
}

export default useConsumerGroup;
