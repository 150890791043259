import styled from 'styled-components';
import { Grid } from '@material-ui/core/';
import LogoCopel from './img/copel-logo.jpg';
import LogoCocel from './img/logo-cocel-medium.png';

export const ContainerCards = styled(Grid).attrs((props) => ({
  container: true,
  justify: 'center',
}))``;

export const WrapContainer = styled.div`
  padding: 0px 15px 5px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-around;
  width: 100%;
`;

export const ContainerPaper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const TextFieldContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
`;

export const TitleSeparator = styled.div`
  border-bottom: 2px solid var(--primary);
`;

export const Title = styled.h2`
  margin-bottom: 5px;
`;

export const BillsContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
`;

export const BillsContainerPaper = styled.div`
  width: 50%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid ${(props) => props.borderColor || '#FFF'};
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const LogoCOPEL = styled.img.attrs(() => ({
  src: LogoCopel,
}))`
  height: auto;
  width: 70px;
  transition: all 0.15s linear;
`;

export const LogoCOCEL = styled.img.attrs(() => ({
  src: LogoCocel,
}))`
  height: auto;
  width: 100px;
  transition: all 0.15s linear;
`;

export const FormHeaderContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const FormContainerPaper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
  background-color: #fff;
  align-items: center;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;
