import React from 'react';
import { TableBody, TableCell, Paper, makeStyles } from '@material-ui/core';

import * as S from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';

const useStyles = makeStyles({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
});

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const CircuitsTableRow = ({ value, rowClick }) => {
  const { uuid_item, item_type, item_constant, item_description } = value;

  const classes = useStyles();

  //eslint-disable-next-line
  const handleEditClick = () => {
    const objeto = { uuid_item, item_type, item_constant, item_description };

    rowClick(objeto);
  };

  return (
    <S.TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered>{item_type}</TableCellCentered>
      <TableCellCentered>{item_constant}</TableCellCentered>
      {/* <TableCellCentered>{item_description}</TableCellCentered> */}
      {/* <TableCellCentered>
        {<S.EditItemIcon onClick={() => handleEditClick()} />}
      </TableCellCentered> */}
    </S.TableRowStyled>
  );
};

const CircuitsTable = ({ data, rowClick }) => (
  <S.StyledTable>
    <S.TableHeadStyled>
      <S.TableRowStyled align="center">
        <TableHeaderCellCentered>TIPO</TableHeaderCellCentered>
        <TableHeaderCellCentered>VARIÁVEL</TableHeaderCellCentered>
        {/* <TableHeaderCellCentered>DESCRIÇÃO</TableHeaderCellCentered> */}
        {/* <TableHeaderCellCentered></TableHeaderCellCentered> */}
      </S.TableRowStyled>
    </S.TableHeadStyled>

    <TableBody>
      {data.map((value, index) => (
        <CircuitsTableRow key={index} value={value} rowClick={rowClick} />
      ))}
    </TableBody>
  </S.StyledTable>
);

export default function IoseSystemVariablesTable({
  loading,
  data,
  setSelectedRow,
  handleOpenEditModal,
}) {
  const handleRowClick = (row) => {
    setSelectedRow(row);
    handleOpenEditModal();
  };

  function renderChartOrErrorMessage() {
    if (data && data?.length > 0) {
      return (
        <Paper style={{ width: '95vw' }}>
          <CircuitsTable
            data={data}
            rowClick={handleRowClick}
            id="scrollableDiv"
          />
        </Paper>
      );
    } else {
      return (
        <Paper style={{ width: '95vw' }}>
          <IoseNoDataMessage
            message="Ops... Parece que não há dados suficientes para exibir a tabela!"
            height="300px"
            width="300px"
          />
        </Paper>
      );
    }
  }
  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={data ? parseInt(data.length) : 100}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={'0'}
          padding={'0'}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={700}
        >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
      )}
    </div>
  );
}
