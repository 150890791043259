import React, { useEffect, useState, useContext } from 'react';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { AccountContext, useClient, LocalStorage, ioseAPI } from 'common';

import {
  FilterText,
  FilterWrappedContainer,
  ReportLoadingText,
  WrappedContainer,
} from './style';

import {
  IoseButton,
  IoseSubHeaderBar,
  IoseMapRedirectButton,
  LoadingIndicator,
  NoDataMessage,
  ReportMessage,
  IoseSelectReferenceMonth,
  IoseAlertLoadingCards,
} from 'components';

import { useCreateLogs, useGroupPermissions } from 'hooks';
import Permissions from 'common/permissions';
import IoseSelectWithPeriod from 'components/IoseSelectWithPeriod';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}${month}`;
  }
  return '';
};

const formatNumberWithZeros = (length, value) => {
  if (typeof value === 'string') {
    value = value.replace(/[^0-9/]/g, '');
  }

  const absValue = Math.abs(parseFloat(value));
  const absValueAsString = absValue.toString().replace('.', '');

  const formattedNumber =
    absValueAsString === '0'
      ? '0'.repeat(length)
      : absValueAsString.padStart(length, '0');

  return formattedNumber;
};

const formatNumberWithSignAndZeros = (length, value) => {
  const absValue = Math.abs(value);
  const absValueAsString = absValue.toString().replace('.', '');

  const sign = value >= 0 ? '+' : '-';

  const formattedNumber =
    (value >= 0 ? '+' : '-') +
    absValueAsString.padStart(length - sign.length, '0');

  return formattedNumber;
};

const padStringWithSpacesRight = (length, string) => {
  if (string === '0') {
    return ' '.repeat(length);
  }

  const paddedString = string.padEnd(length, ' ');
  return paddedString;
};

const padStringWithSpacesRightHistoric = (length, string) => {
  const currentLength = string ? string?.length : 0;

  if (currentLength === 0 || string === undefined) {
    return ' '.repeat(length);
  } else if (currentLength === length) {
    return string;
  } else if (currentLength < length) {
    const paddedString = string.padEnd(length, ' ');
    return paddedString;
  } else {
    return string;
  }
};

const padStringWithSpacesLeftHistoric = (length, string) => {
  const currentLength = string ? string.length : 0;

  if (currentLength === 0 || string === undefined) {
    return ' '.repeat(length);
  } else if (currentLength < length) {
    const spacesToAdd = length - currentLength;
    const paddedString = string.padStart(currentLength + spacesToAdd, ' ');
    return paddedString;
  } else {
    return string;
  }
};

const padStringWithSpacesLeft = (length, string) => {
  if (string === '0') {
    return ' '.repeat(length);
  }

  const paddedString = string.padStart(length, ' ');
  return paddedString;
};

function formatDataToTxt(array) {
  let txtData = '';
  const filler = '        ';
  array.forEach((item) => {
    const {
      con_coordenada_x,
      con_coordenada_y,
      fac_adic_bandeira,
      fac_cod_concess,
      fac_cod_contabilidade,
      fac_comp_geracao,
      fac_consumo_exc_r,
      fac_con_contrat_kwh_fp,
      fac_con_contrat_kwh_p,
      fac_con_contr_conv,
      fac_con_corr_exc_rfp,
      fac_con_corr_exc_rp,
      fac_con_exc_rcv,
      fac_con_faturado_kwh_fp,
      fac_con_faturado_kwh_p,
      fac_con_medido_kwh_fp,
      fac_con_medido_kwh_p,
      fac_con_mini_micro_ger_fp,
      fac_con_mini_micro_ger_p,
      fac_data_fatura,
      fac_dem_contratada_fps,
      fac_dem_contratada_ps,
      fac_dem_contrat_kw_fp,
      fac_dem_contrat_kw_p,
      fac_dem_contr_conv,
      fac_dem_corr_erfp,
      fac_dem_corr_exc_rp,
      fac_dem_exc_rcv,
      fac_dem_faturada_kw_fp,
      fac_dem_faturada_kw_p,
      fac_dem_medida_kw_fp,
      fac_dem_medida_kw_p,
      fac_dem_ultra_kw_fp,
      fac_dem_ultra_kw_p,
      fac_desc_mod_conta,
      fac_desc_tipo_bandeira,
      fac_desc_tipo_conta,
      fac_desc_unidade,
      fac_endereco,
      fac_estado,
      fac_fator_carga_fp,
      fac_fator_carga_p,
      fac_fator_potencia,
      fac_gerencia_atendida,
      fac_gerencia_geral,
      fac_ind_periodo_tcd,
      fac_kwh_faturado,
      fac_kwh_real,
      fac_kw_faturado,
      fac_kw_real,
      fac_kw_ultrapassagem,
      fac_leitura_final,
      fac_leitura_inicial,
      fac_numero_medidor,
      fac_num_cliente,
      fac_num_fatura_cliente,
      fac_orgao_pagador,
      fac_pot_transf_ativo,
      fac_subvencao_icms,
      fac_tipo_atividade,
      fac_tipo_bandeira,
      fac_tipo_conta,
      fac_tipo_ligacao,
      fac_val_encargos_cv,
      fac_val_encargos_hs,
      fac_val_imp_cercv,
      fac_val_imp_cons,
      fac_val_imp_cons_adic,
      fac_val_imp_cons_adic_fp,
      fac_val_imp_cons_adic_p,
      fac_val_imp_cons_cer_fp,
      fac_val_imp_cons_cer_p,
      fac_val_imp_cons_der_fp,
      fac_val_imp_cons_der_p,
      fac_val_imp_cons_fp,
      fac_val_imp_cons_p,
      fac_val_imp_dem,
      fac_val_imp_dem_adic,
      fac_val_imp_dem_adic_fp,
      fac_val_imp_dem_adic_p,
      fac_val_imp_dem_fp,
      fac_val_imp_dem_p,
      fac_val_imp_dercv,
      fac_val_imp_ult,
      fac_val_imp_ult_fp,
      fac_val_imp_ult_p,
      fac_val_outros_prod_cv,
      fac_val_outros_prod_hs,
      fac_val_taxa_iluminacao_cv,
      fac_val_taxa_ilum_hs,
      fac_val_total_fatura,
      fac_refer_fatura,
      fac_tipo_prod,
      fac_cod_fatura,
      fac_geracao,
      fac_csbio,
    } = item;

    const formattedData = [
      formatNumberWithZeros(3, fac_cod_concess),
      filler,
      fac_leitura_final,
      fac_leitura_inicial,
      fac_data_fatura,
      fac_refer_fatura,
      fac_ind_periodo_tcd,
      formatNumberWithZeros(8, fac_orgao_pagador),
      padStringWithSpacesRight(10, fac_gerencia_atendida),
      padStringWithSpacesRight(32, fac_desc_unidade),
      formatNumberWithZeros(8, fac_dem_contr_conv),
      formatNumberWithZeros(9, fac_con_contr_conv),
      formatNumberWithZeros(9, fac_kwh_real),
      formatNumberWithZeros(9, fac_kwh_faturado),
      formatNumberWithZeros(11, fac_kw_real),
      formatNumberWithZeros(11, fac_kw_faturado),
      formatNumberWithZeros(11, fac_kw_ultrapassagem),
      formatNumberWithZeros(9, fac_consumo_exc_r),
      formatNumberWithZeros(8, fac_fator_potencia),
      formatNumberWithZeros(9, fac_con_exc_rcv),
      formatNumberWithZeros(11, fac_dem_exc_rcv),
      formatNumberWithZeros(15, fac_val_imp_cons),
      formatNumberWithZeros(15, fac_val_imp_dem),
      formatNumberWithZeros(15, fac_val_imp_ult),
      formatNumberWithZeros(15, fac_val_imp_cercv),
      formatNumberWithZeros(15, fac_val_imp_dercv),
      formatNumberWithSignAndZeros(14, fac_val_taxa_iluminacao_cv),
      formatNumberWithSignAndZeros(12, fac_val_encargos_cv),
      formatNumberWithSignAndZeros(14, fac_val_outros_prod_cv),
      formatNumberWithZeros(9, fac_con_medido_kwh_p),
      formatNumberWithZeros(9, fac_con_medido_kwh_fp),
      formatNumberWithZeros(9, fac_con_contrat_kwh_p),
      formatNumberWithZeros(9, fac_con_contrat_kwh_fp),
      formatNumberWithZeros(9, fac_con_faturado_kwh_p),
      formatNumberWithZeros(9, fac_con_faturado_kwh_fp),
      formatNumberWithZeros(11, fac_dem_medida_kw_p),
      formatNumberWithZeros(11, fac_dem_medida_kw_fp),
      formatNumberWithZeros(8, fac_dem_contrat_kw_p),
      formatNumberWithZeros(8, fac_dem_contrat_kw_fp),
      formatNumberWithZeros(11, fac_dem_faturada_kw_p),
      formatNumberWithZeros(11, fac_dem_faturada_kw_fp),
      formatNumberWithZeros(11, fac_dem_ultra_kw_p),
      formatNumberWithZeros(11, fac_dem_ultra_kw_fp),
      formatNumberWithZeros(9, fac_con_corr_exc_rp),
      formatNumberWithZeros(9, fac_con_corr_exc_rfp),
      formatNumberWithZeros(11, fac_dem_corr_exc_rp),
      formatNumberWithZeros(11, fac_dem_corr_erfp),
      formatNumberWithZeros(15, fac_val_imp_cons_p),
      formatNumberWithZeros(15, fac_val_imp_cons_fp),
      formatNumberWithZeros(15, fac_val_imp_dem_p),
      formatNumberWithZeros(15, fac_val_imp_dem_fp),
      formatNumberWithZeros(15, fac_val_imp_ult_p),
      formatNumberWithZeros(15, fac_val_imp_ult_fp),
      formatNumberWithZeros(15, fac_val_imp_cons_cer_p),
      formatNumberWithZeros(15, fac_val_imp_cons_cer_fp),
      formatNumberWithZeros(15, fac_val_imp_cons_der_p),
      formatNumberWithZeros(15, fac_val_imp_cons_der_fp),
      formatNumberWithSignAndZeros(15, fac_val_taxa_ilum_hs),
      formatNumberWithZeros(12, fac_val_encargos_hs),
      formatNumberWithSignAndZeros(16, fac_val_outros_prod_hs),
      formatNumberWithZeros(5, fac_pot_transf_ativo),
      formatNumberWithZeros(6, fac_dem_contratada_ps),
      formatNumberWithZeros(6, fac_dem_contratada_fps),
      padStringWithSpacesRight(5, fac_tipo_ligacao),
      padStringWithSpacesRight(12, fac_tipo_atividade),
      formatNumberWithSignAndZeros(15, fac_val_total_fatura),
      formatNumberWithZeros(6, fac_cod_contabilidade),
      padStringWithSpacesRight(1, fac_tipo_conta),
      padStringWithSpacesRight(5, fac_desc_tipo_conta),
      padStringWithSpacesRight(1, fac_tipo_bandeira),
      padStringWithSpacesRight(8, fac_desc_tipo_bandeira),
      formatNumberWithZeros(11, fac_adic_bandeira),
      formatNumberWithZeros(11, fac_subvencao_icms),
      formatNumberWithZeros(8, fac_num_fatura_cliente),
      formatNumberWithZeros(10, fac_num_cliente),
      padStringWithSpacesRight(6, fac_tipo_prod),
      formatNumberWithZeros(15, fac_val_imp_cons_adic),
      formatNumberWithZeros(15, fac_val_imp_cons_adic_p),
      formatNumberWithZeros(15, fac_val_imp_cons_adic_fp),
      formatNumberWithSignAndZeros(15, fac_val_imp_dem_adic),
      formatNumberWithSignAndZeros(15, fac_val_imp_dem_adic_p),
      formatNumberWithSignAndZeros(15, fac_val_imp_dem_adic_fp),
      padStringWithSpacesLeft(12, fac_desc_mod_conta),
      padStringWithSpacesLeft(30, fac_endereco),
      padStringWithSpacesRight(2, fac_estado),
      padStringWithSpacesRight(4, fac_gerencia_geral),
      formatNumberWithZeros(11, fac_numero_medidor),
      formatNumberWithZeros(11, fac_fator_carga_p),
      formatNumberWithZeros(11, fac_fator_carga_fp),
      formatNumberWithZeros(7, fac_cod_fatura),
      padStringWithSpacesRight(1, fac_geracao),
      padStringWithSpacesRight(1, fac_csbio),
      formatNumberWithSignAndZeros(10, fac_con_mini_micro_ger_p),
      formatNumberWithSignAndZeros(10, fac_con_mini_micro_ger_fp),
      formatNumberWithSignAndZeros(11, fac_comp_geracao),
      formatNumberWithZeros(8, con_coordenada_x),
      formatNumberWithZeros(8, con_coordenada_y),
    ].join('');

    txtData += formattedData + '\n';
  });

  return txtData;
}

function formatDataToTxtHistoric(array) {
  let txtData = '';
  const filler = '        ';
  array.forEach((item) => {
    const {
      con_coordenada_x,
      con_coordenada_y,
      fac_adic_bandeira,
      fac_cod_concess,
      fac_cod_contabilidade,
      fac_comp_geracao,
      fac_consumo_exc_r,
      fac_con_contrat_kwh_fp,
      fac_con_contrat_kwh_p,
      fac_con_contr_conv,
      fac_con_corr_exc_rfp,
      fac_con_corr_exc_rp,
      fac_con_exc_rcv,
      fac_con_faturado_kwh_fp,
      fac_con_faturado_kwh_p,
      fac_con_medido_kwh_fp,
      fac_con_medido_kwh_p,
      fac_con_mini_micro_ger_fp,
      fac_con_mini_micro_ger_p,
      fac_data_fatura,
      fac_dem_contratada_fps,
      fac_dem_contratada_ps,
      fac_dem_contrat_kw_fp,
      fac_dem_contrat_kw_p,
      fac_dem_contr_conv,
      fac_dem_corr_erfp,
      fac_dem_corr_exc_rp,
      fac_dem_exc_rcv,
      fac_dem_faturada_kw_fp,
      fac_dem_faturada_kw_p,
      fac_dem_medida_kw_fp,
      fac_dem_medida_kw_p,
      fac_dem_ultra_kw_fp,
      fac_dem_ultra_kw_p,
      fac_desc_mod_conta,
      fac_desc_tipo_bandeira,
      fac_desc_tipo_conta,
      fac_desc_unidade,
      fac_endereco,
      fac_estado,
      fac_fator_carga_fp,
      fac_fator_carga_p,
      fac_fator_potencia,
      fac_gerencia_atendida,
      fac_gerencia_geral,
      fac_ind_periodo_tcd,
      fac_kwh_faturado,
      fac_kwh_real,
      fac_kw_faturado,
      fac_kw_real,
      fac_kw_ultrapassagem,
      fac_leitura_final,
      fac_leitura_inicial,
      fac_numero_medidor,
      fac_num_cliente,
      fac_num_fatura_cliente,
      fac_orgao_pagador,
      fac_pot_transf_ativo,
      fac_subvencao_icms,
      fac_tipo_atividade,
      fac_tipo_bandeira,
      fac_tipo_conta,
      fac_tipo_ligacao,
      fac_val_encargos_cv,
      fac_val_encargos_hs,
      fac_val_imp_cercv,
      fac_val_imp_cons,
      fac_val_imp_cons_adic,
      fac_val_imp_cons_adic_fp,
      fac_val_imp_cons_adic_p,
      fac_val_imp_cons_cer_fp,
      fac_val_imp_cons_cer_p,
      fac_val_imp_cons_der_fp,
      fac_val_imp_cons_der_p,
      fac_val_imp_cons_fp,
      fac_val_imp_cons_p,
      fac_val_imp_dem,
      fac_val_imp_dem_adic,
      fac_val_imp_dem_adic_fp,
      fac_val_imp_dem_adic_p,
      fac_val_imp_dem_fp,
      fac_val_imp_dem_p,
      fac_val_imp_dercv,
      fac_val_imp_ult,
      fac_val_imp_ult_fp,
      fac_val_imp_ult_p,
      fac_val_outros_prod_cv,
      fac_val_outros_prod_hs,
      fac_val_taxa_iluminacao_cv,
      fac_val_taxa_ilum_hs,
      fac_val_total_fatura,
      fac_refer_fatura,
      fac_tipo_prod,
      fac_cod_fatura,
      fac_geracao,
      fac_csbio,
    } = item;

    const formattedData = [
      fac_cod_concess,
      filler,
      fac_leitura_final,
      fac_leitura_inicial,
      fac_data_fatura,
      fac_refer_fatura,
      fac_ind_periodo_tcd,
      fac_orgao_pagador,
      padStringWithSpacesRightHistoric(10, fac_gerencia_atendida),
      padStringWithSpacesRightHistoric(32, fac_desc_unidade),
      fac_dem_contr_conv,
      fac_con_contr_conv,
      fac_kwh_real,
      fac_kwh_faturado,
      fac_kw_real,
      fac_kw_faturado,
      fac_kw_ultrapassagem,
      fac_consumo_exc_r,
      fac_fator_potencia,
      fac_con_exc_rcv,
      fac_dem_exc_rcv,
      fac_val_imp_cons,
      fac_val_imp_dem,
      fac_val_imp_ult,
      fac_val_imp_cercv,
      fac_val_imp_dercv,
      fac_val_taxa_iluminacao_cv,
      fac_val_encargos_cv,
      fac_val_outros_prod_cv,
      fac_con_medido_kwh_p,
      fac_con_medido_kwh_fp,
      fac_con_contrat_kwh_p,
      fac_con_contrat_kwh_fp,
      fac_con_faturado_kwh_p,
      fac_con_faturado_kwh_fp,
      fac_dem_medida_kw_p,
      fac_dem_medida_kw_fp,
      fac_dem_contrat_kw_p,
      fac_dem_contrat_kw_fp,
      fac_dem_faturada_kw_p,
      fac_dem_faturada_kw_fp,
      fac_dem_ultra_kw_p,
      fac_dem_ultra_kw_fp,
      fac_con_corr_exc_rp,
      fac_con_corr_exc_rfp,
      fac_dem_corr_exc_rp,
      fac_dem_corr_erfp,
      fac_val_imp_cons_p,
      fac_val_imp_cons_fp,
      fac_val_imp_dem_p,
      fac_val_imp_dem_fp,
      fac_val_imp_ult_p,
      fac_val_imp_ult_fp,
      fac_val_imp_cons_cer_p,
      fac_val_imp_cons_cer_fp,
      fac_val_imp_cons_der_p,
      fac_val_imp_cons_der_fp,
      fac_val_taxa_ilum_hs,
      fac_val_encargos_hs,
      fac_val_outros_prod_hs,
      fac_pot_transf_ativo,
      fac_dem_contratada_ps,
      fac_dem_contratada_fps,
      padStringWithSpacesRightHistoric(5, fac_tipo_ligacao),
      padStringWithSpacesRightHistoric(12, fac_tipo_atividade),
      fac_val_total_fatura,
      fac_cod_contabilidade,
      padStringWithSpacesRightHistoric(1, fac_tipo_conta),
      padStringWithSpacesRightHistoric(5, fac_desc_tipo_conta),
      padStringWithSpacesRightHistoric(1, fac_tipo_bandeira),
      padStringWithSpacesRightHistoric(8, fac_desc_tipo_bandeira),
      fac_adic_bandeira,
      fac_subvencao_icms,
      fac_num_fatura_cliente,
      fac_num_cliente,
      padStringWithSpacesLeftHistoric(6, fac_tipo_prod),
      fac_val_imp_cons_adic,
      fac_val_imp_cons_adic_p,
      fac_val_imp_cons_adic_fp,
      fac_val_imp_dem_adic,
      fac_val_imp_dem_adic_p,
      fac_val_imp_dem_adic_fp,
      padStringWithSpacesRightHistoric(12, fac_desc_mod_conta),
      padStringWithSpacesRightHistoric(30, fac_endereco),
      padStringWithSpacesRightHistoric(2, fac_estado),
      padStringWithSpacesRightHistoric(4, fac_gerencia_geral),
      fac_numero_medidor,
      fac_fator_carga_p,
      fac_fator_carga_fp,
      fac_cod_fatura,
      padStringWithSpacesRightHistoric(1, fac_geracao),
      padStringWithSpacesRightHistoric(1, fac_csbio),
      fac_con_mini_micro_ger_p,
      fac_con_mini_micro_ger_fp,
      fac_comp_geracao,
      con_coordenada_x,
      con_coordenada_y,
    ].join('');

    txtData += formattedData + '\n';
  });

  return txtData;
}

function downloadTxtFile(content) {
  const contentWithCRLF = content.replace(/\n/g, '\r\n');

  const element = document.createElement('a');
  const file = new Blob([contentWithCRLF], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = 'biene.txt';
  document.body.appendChild(element);
  element.click();
}

const handleDownload = (data) => {
  const formattedData = formatDataToTxt(data);
  downloadTxtFile(formattedData);
};

const handleHistoricDownload = (data) => {
  const formattedData = formatDataToTxtHistoric(data);
  downloadTxtFile(formattedData);
};

export default function BIENEExportContainer() {
  const [clientData, setClientData] = useState({});
  const [group, setGroup] = useState();
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [isHistoricFilterEnabled, setIsHistoricFilterEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [historicSelectedDate, setHistoricSelectedDate] = useState(null);
  const [referenceMonth, setReferenceMonth] = useState(null);
  const [historicReferenceMonth, setHistoricReferenceMonth] = useState(null);
  const [noDataError, setNoDataError] = useState(false);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();
  const history = useHistory();

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const reportPath = ['relatorios', 'biene', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDate !== null) {
      setIsFilterEnabled(true);
    } else {
      setIsFilterEnabled(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (historicSelectedDate !== null) {
      setIsHistoricFilterEnabled(true);
    } else {
      setIsHistoricFilterEnabled(false);
    }
  }, [historicSelectedDate]);

  function redirectToMapPage() {
    history.push('/map');
  }

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    }

    return LocalStorage.getClientData();
  };

  const getClientData = async () => {
    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const handleDateChange = (date) => {
    const formattedDate = formatToBrazilianDate(date);

    setSelectedDate(date);
    setReferenceMonth(formattedDate);
  };

  const handleHistoricDateChange = (date) => {
    const formattedDate = formatToBrazilianDate(date);

    setHistoricSelectedDate(date);
    setHistoricReferenceMonth(formattedDate);
  };

  const getAndExportBIENE = async () => {
    setLoading(true);
    const { headers } = getSession();

    const response = await ioseAPI.getBIENEForExport(headers, referenceMonth);

    const { data } = response;
    const url = data[0] === null ? [] : data;

    const url_key = url.key;

    const result = await ioseAPI.getBieneFileOnS3(headers, url_key);

    const result_content = result.data.fileContent;

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO BIENE';
    const logContent = {
      reference_month: referenceMonth,
    };

    await createLog(operacao, form, logContent);

    if (result_content?.length === 0) {
      setLoading(false);
      setSelectedDate(null);
      setReferenceMonth(null);
      setNoDataError(true);
    } else {
      setLoading(false);
      setSelectedDate(null);
      setReferenceMonth(null);
      handleDownload(result_content);
    }

    setTimeout(() => {
      setNoDataError(false);
    }, 5000);
  };

  const getAndExportHistoricBIENE = async () => {
    setLoading(true);
    const { headers } = getSession();

    const response = await ioseAPI.getBIENEForExport(
      headers,
      historicReferenceMonth
    );

    const { data } = response;
    const url = data[0] === null ? [] : data;

    const url_key = url.key;

    const result = await ioseAPI.getBieneFileOnS3(headers, url_key);

    const result_content = result.data.fileContent;

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO BIENE';
    const logContent = {
      historicReferenceMonth: historicReferenceMonth,
    };

    await createLog(operacao, form, logContent);

    if (result_content?.length === 0) {
      setLoading(false);
      setHistoricSelectedDate(null);
      setHistoricReferenceMonth(null);
      setNoDataError(true);
    } else {
      setLoading(false);
      setHistoricSelectedDate(null);
      setHistoricReferenceMonth(null);
      handleHistoricDownload(result_content);
    }

    setTimeout(() => {
      setNoDataError(false);
    }, 5000);
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle={`Exportação TXT BIENE`}
        // description={'Total: ' + allContracts.length}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <IoseMapRedirectButton
            tooltip="IR PARA MAPA DE UNIDADES CONSUMIDORAS"
            bottom="5px"
            onClick={() => redirectToMapPage()}
          />
        </div>
      </IoseSubHeaderBar>
    );
  };

  const renderFilters = () => {
    const minDate = new Date(2018, 0, 1);
    const maxDate = new Date(2022, 11, 31);

    return (
      <>
        <FilterWrappedContainer>
          <div>
            <FilterText>Filtro:</FilterText>
          </div>

          <div>
            <IoseSelectReferenceMonth
              label="REF: MÊS/ANO"
              handleChange={handleDateChange}
              selectedDate={selectedDate}
            />
          </div>

          <div>
            <IoseButton
              onClick={() => getAndExportBIENE()}
              disabled={!isFilterEnabled}
            >
              EXPORTAR TXT
            </IoseButton>
          </div>
        </FilterWrappedContainer>
        {/*
        <Tooltip
          title={
            'ESTE FILTRO É REFERENTE A BASE DE DADOS ANTIGA DO RELATÓRIO DO PERÍODO DE 01/2018 A 12/2022'
          }
          placement="right"
          arrow
        >
          <div>
            <IoseSelectReferenceMonth
              label="REF: MÊS/ANO"
              handleChange={handleDateChange}
              selectedDate={selectedDate}
            />
          </div>
        </Tooltip> */}

        <Tooltip
          title={
            'ESTE FILTRO É REFERENTE A BASE DE DADOS ANTIGA DO RELATÓRIO DO PERÍODO DE 01/2018 A 12/2022'
          }
          placement="right"
          arrow
        >
          <FilterWrappedContainer>
            <div>
              <FilterText>Filtro:</FilterText>
            </div>

            <div>
              <IoseSelectWithPeriod
                label="REF: MÊS/ANO"
                handleChange={handleHistoricDateChange}
                selectedDate={historicSelectedDate}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>

            <div>
              <IoseButton
                onClick={() => getAndExportHistoricBIENE()}
                disabled={!isHistoricFilterEnabled}
              >
                EXPORTAR HISTÓRICO
              </IoseButton>
            </div>
          </FilterWrappedContainer>
        </Tooltip>
      </>
    );
  };

  const renderTableorImage = () => {
    return (
      <div>
        <div>
          {noDataError ? (
            <NoDataMessage message="Ops! Nenhum dado encontrado. Por favor, verifique se as configurações do filtro estão corretas e tente novamente." />
          ) : (
            <ReportMessage message="Para emitir o txt do BIENE, selecione o mês desejado e clique em EXPORTAR TXT." />
          )}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {loading ? (
            <>
              <LoadingIndicator loading={loading} />
              <ReportLoadingText>
                Carregando Relatório... Por favor, aguarde enquanto preparamos o
                arquivo.
              </ReportLoadingText>
            </>
          ) : (
            <>
              {renderFilters()}
              {renderTableorImage()}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <WrappedContainer>
      {renderSubHeaderAndSearchBar()}
      {hasReportPermission ? (
        renderContent()
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão para emissão deste relatório!'
          }
        />
      )}
    </WrappedContainer>
  );
}
