import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { useLocation } from 'react-router-dom';

//Import from formik (Library to logic's form)
import { Formik, Field } from 'formik';

//Import from Yup (Library to validation's form)
import * as Yup from 'yup';

//Import from validations (File with Yup validations)
import validationsForm from '../../common/ValidationsForms';

//Import from Iose Components
import IoseButtonSideBar from 'components/IoseButton';
import IoseSelectDayMouth from 'components/IoseSelectDayMouth';
import IoseSelection from 'components/IoseSelection';

//Import from Styled Components
import {
  Container,
  Header,
  IoseTextFieldSidebarStyled,
  FormStyled,
  GridItem,
  GridContainer,
  DividerStyled,
  DivInputs,
  CircularProgressStyled,
  DivCircularProgress,
} from './style';

import {
  DivInputs2,
  IoseTextFieldSidebarStyled2,
} from 'components/IoseFormUnityAddCard/style';

/**
 * This component is a form to edit a unity in the system
 */
export default function IoseFormUnityEditCard({
  editUnity,
  getAllAgents,
  getFilterAgents,
}) {
  let location = useLocation();

  const name = location.state.unityData.name;
  const desctription = location.state.unityData.description;
  const uuid_unity = location.state.unityData.uuid_unity;
  const contracted_demand = location.state.unityData.contracted_demand;
  const tariff_data = location.state.unityData.tariff_data;
  const identification_number = location.state.unityData.identification_number;
  const bin_link = location.state.unityData.bin_link;
  const id_3d = location.state.unityData.id_3d;

  const current_year = new Date().getFullYear();
  const current_month = new Date().getMonth();
  const tariff_period = location.state.unityData.tariff_period;

  //States related tariff
  const [nameAgent, setNameAgent] = useState(tariff_data.name_agent);
  const [nameAgents, setNameAgents] = useState([]);

  const [subgroup, setSubgroup] = useState(tariff_data.subgroup);
  //eslint-disable-next-line
  const [subgroups, setSubgroups] = useState([]);

  const [modality, setModality] = useState(tariff_data.modality);
  //eslint-disable-next-line
  const [modalitys, setModalitys] = useState([]);

  const [accessor, setAccessor] = useState(tariff_data.acessor);
  //eslint-disable-next-line
  const [accessors, setAccessors] = useState([]);

  const [classe, setClasse] = useState(tariff_data.class);
  //eslint-disable-next-line
  const [classes, setClasses] = useState([]);

  const [subclass, setSubclass] = useState(tariff_data.subclass);
  //eslint-disable-next-line
  const [subclasses, setSubclasses] = useState([]);

  const [loading, setloading] = useState(false);

  useEffect(() => {
    handleGetAllAgents();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Stato to indicate fist day tariff period
  const [initialDate, setInitialDate] = useState(
    tariff_period
      ? new Date(current_year, current_month, tariff_period)
      : new Date()
  );

  //Schema validation for UnityEdit
  const UnityEditSchema = Yup.object().shape({
    name: validationsForm.name,
    description: validationsForm.name,
    demandContracted: validationsForm.number,
  });

  //Labels forms to render inputs fields
  const labels = [
    { label: 'Nome', variable: 'name' },
    { label: 'Descrição', variable: 'description' },
  ];

  const classList = [
    'TIPO 01',
    'TIPO 02',
    'RESIDENCIAL',
    'RURAL',
    'NA',
    'ILUMINAÇÃO PÚBLICA',
  ];

  const modalityList = [
    'AZUL',
    'AZUL APE',
    'GERAÇÃO',
    'DISTRIBUIÇÃO',
    'VERDE',
    'VERDE APE',
    'GERAÇÃO',
    'BRANCA',
    'CONVENCIONAL',
    'PRÉ-PAGAMENTO',
  ];

  const subClassList = [
    'NA',
    'RESIDENCIAL',
    'BAIXA RENDA',
    'COOPERATIVA DE ELETRIFICAÇÃO RURAL',
    'NÃO SE APLICA',
    'SERVIÇO PÚBLICO DE IRRIGAÇÃO RURAL',
    'ILUMINAÇÃO PÚBLICA – B4A',
    'ILUMINAÇÃO PÚBLICA – B4B',
  ];

  const subGroupList = ['A1', 'A3', 'A4', 'B', 'B1', 'B2', 'B3', 'B4'];

  const acessorList = ['CCCP', 'NÃO SE APLICA', 'CEPISA', 'EPB', ''];

  //Labels forms to render selects related tariff
  const labelsSelectsTariff = [
    {
      label: 'Concessionária',
      list: nameAgents,
      variable: nameAgent,
      func: handleSetAgentName,
    },
    {
      label: 'Subgrupos',
      list: subGroupList,
      variable: subgroup,
      func: handleSetSubgroup,
      onOpen: handleOnOpenSubgroup,
    },
    {
      label: 'Modalidade',
      list: modalityList,
      variable: modality,
      func: handleSetModality,
      onOpen: handleOnOpenModality,
    },
    {
      label: 'Acessante',
      list: acessorList,
      variable: accessor,
      func: handleSetAccessor,
      onOpen: handleOnOpenAccessor,
    },
    {
      label: 'Classe',
      list: classList,
      variable: classe,
      func: handleSetClass,
      onOpen: handleOnOpenClass,
    },
    {
      label: 'SubClasse',
      list: subClassList,
      variable: subclass,
      func: handleSetSubClass,
      onOpen: handleOnOpenSubClass,
    },
  ];

  //Function when confirm button was press
  const onSubmitForm = (values, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);

      const initialDateDay = initialDate.getDate();

      const tariff_data = {
        acessor: accessor,
        subclass: subclass,
        name_agent: nameAgent,
        class: classe,
        modality: modality,
        subgroup: subgroup,
      };

      const demandContracted = parseInt(values.demandContracted);
      const identificationNumber = parseInt(values.identificationNumber);

      editUnity(
        uuid_unity,
        values.name,
        values.description,
        initialDateDay,
        demandContracted,
        tariff_data,
        identificationNumber,
        values.binLink,
        values.id3d
      );
    }, 500);
  };

  async function handleGetAllAgents() {
    const allAgents = await getAllAgents();

    const nameAgentArray =
      allAgents && allAgents.map((value) => value.name_agent);

    if (allAgents) {
      setNameAgents(nameAgentArray);
    }

    prepareDataComboBoxes();
  }

  async function prepareDataComboBoxes() {
    setloading(true);
    const data = await getFilterAgents(
      nameAgent,
      subgroup,
      modality,
      accessor,
      classe,
      subclass
    );

    if (data) {
      setSubgroups(data.response.subgroup);
      setModalitys(data.response.modality);
      setAccessors(data.response.accessor);
      setClasses(data.response.class);
      setSubclasses(data.response.subclass);
    }

    setloading(false);
  }

  async function handleSetAgentName(agenteSelected) {
    setloading(true);
    setNameAgent(agenteSelected);

    let dataFiltered = await getFilterAgents(agenteSelected);

    if (dataFiltered) {
      setSubgroups(dataFiltered.response.subgroup);
      setSubgroup('');

      setModalitys([]);
      setModality('');

      setAccessors([]);
      setAccessor('');

      setClasses([]);
      setClasse('');

      setSubclasses([]);
      setSubclass('');
    }

    setloading(false);
  }

  async function handleSetSubgroup(subgroupSelected) {
    setloading(true);
    setSubgroup(subgroupSelected);

    let dataFiltered = await getFilterAgents(nameAgent, subgroupSelected);

    if (dataFiltered) {
      setModalitys(dataFiltered.response.modality);
      setModality('');

      setAccessors([]);
      setAccessor('');

      setClasses([]);
      setClasse('');

      setSubclasses([]);
      setSubclass('');
    }

    setloading(false);
  }

  async function handleSetModality(modalitySelected) {
    setloading(true);
    setModality(modalitySelected);

    let dataFiltered = await getFilterAgents(
      nameAgent,
      subgroup,
      modalitySelected
    );

    if (dataFiltered) {
      setAccessors(dataFiltered.response.accessor);
      setAccessor('');

      setClasses([]);
      setClasse('');

      setSubclasses([]);
      setSubclass('');
    }

    setloading(false);
  }

  async function handleSetAccessor(accessorSelected) {
    setloading(true);
    setAccessor(accessorSelected);

    let dataFiltered = await getFilterAgents(
      nameAgent,
      subgroup,
      modality,
      accessorSelected
    );

    if (dataFiltered) {
      setClasses(dataFiltered.response.class);
      setClasse('');

      setSubclasses([]);
      setSubclass('');
    }

    setloading(false);
  }

  async function handleSetClass(classSelected) {
    setloading(true);
    setClasse(classSelected);

    let dataFiltered = await getFilterAgents(
      nameAgent,
      subgroup,
      modality,
      accessor,
      classSelected
    );

    if (dataFiltered) {
      setSubclasses(dataFiltered.response.subclass);
      setSubclass('');
    }

    setloading(false);
  }

  async function handleSetSubClass(subClassSelected) {
    setloading(true);
    setSubclass(subClassSelected);

    setloading(false);
  }

  async function handleOnOpenSubgroup() {
    let dataFiltered = await getFilterAgents(nameAgent);

    if (dataFiltered) {
      setSubgroups(dataFiltered.response.subgroup);
    }
  }

  async function handleOnOpenModality() {
    let dataFiltered = await getFilterAgents(nameAgent, subgroup);

    if (dataFiltered) {
      setModalitys(dataFiltered.response.modality);
    }
  }

  async function handleOnOpenAccessor() {
    let dataFiltered = await getFilterAgents(nameAgent, subgroup, modality);

    if (dataFiltered) {
      setAccessors(dataFiltered.response.accessor);
    }
  }

  async function handleOnOpenClass() {
    let dataFiltered = await getFilterAgents(
      nameAgent,
      subgroup,
      modality,
      accessor
    );

    if (dataFiltered) {
      setClasses(dataFiltered.response.class);
    }
  }

  async function handleOnOpenSubClass() {
    let dataFiltered = await getFilterAgents(
      nameAgent,
      subgroup,
      modality,
      accessor,
      classe
    );

    if (dataFiltered) {
      setSubclasses(dataFiltered.response.subclass);
    }
  }

  function handleDisabledField(field) {
    let response = true;

    switch (field) {
      case 'Concessionária':
        response = false;
        break;

      case 'Subgrupos':
        if (nameAgent !== '') {
          response = false;
        }
        break;

      case 'Modalidade':
        if (subgroup !== '') {
          response = false;
        }
        break;

      case 'Acessante':
        if (modality !== '') {
          response = false;
        }
        break;

      case 'Classe':
        if (accessor !== '') {
          response = false;
        }
        break;

      case 'SubClasse':
        if (classe !== '') {
          response = false;
        }
        break;

      default:
        response = true;
    }

    return response;
  }
  return (
    <>
      {/**Form*/}
      <Container>
        <Formik
          initialValues={{
            name: name,
            description: desctription,
            demandContracted: contracted_demand,
            identificationNumber: identification_number,
            binLink: bin_link,
            id3d: id_3d,
          }}
          validationSchema={UnityEditSchema}
          onSubmit={onSubmitForm}
        >
          {({
            handleChange,
            handleBlur,
            // submitForm,
            touched,
            isSubmitting,
            errors,
            initialValues,
          }) => (
            <FormStyled>
              {/**Header*/}
              <Header>
                <div>Editar</div>
                <IoseButtonSideBar
                  type="submit"
                  disabled={isSubmitting}
                  // onClick={submitForm}
                >
                  Confirmar
                </IoseButtonSideBar>
              </Header>

              {/**Inputs*/}
              <DivInputs>
                {labels.map((value) => {
                  return (
                    <Field
                      component={IoseTextFieldSidebarStyled2}
                      key={value.variable}
                      id={value.variable}
                      name={value.variable}
                      type={'text'}
                      label={value.label}
                      defaultvalue={initialValues[value.variable]}
                      onchange={handleChange}
                      onblur={handleBlur}
                      helpertext={
                        errors[value.variable] && touched[value.variable]
                          ? errors[value.variable]
                          : ''
                      }
                    />
                  );
                })}
              </DivInputs>

              <DivInputs2>
                <Field
                  component={IoseTextFieldSidebarStyled}
                  id={'identificationNumber'}
                  name={'identificationNumber'}
                  type={'text'}
                  label={'Unidade Consumidora'}
                  defaultvalue={initialValues['identificationNumber']}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors['identificationNumber'] &&
                    touched['identificationNumber']
                      ? errors['identificationNumber']
                      : ''
                  }
                />
              </DivInputs2>

              {/**Tariff data comboboxes */}
              <p>Informações relacionadas ao período tarifário</p>
              <DividerStyled />

              <DivInputs2>
                <Field
                  component={IoseTextFieldSidebarStyled}
                  id={'demandContracted'}
                  name={'demandContracted'}
                  type={'text'}
                  label={'Demanda Contratante'}
                  defaultvalue={initialValues['demandContracted']}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors['demandContracted'] && touched['demandContracted']
                      ? errors['demandContracted']
                      : ''
                  }
                />
              </DivInputs2>

              <IoseSelectDayMouth
                title="Dia inicial do período tarifário"
                getDate={setInitialDate}
                initialDate={initialDate}
                margin={'0px'}
              />

              <GridContainer container>
                {loading ? (
                  <DivCircularProgress>
                    <CircularProgressStyled />
                  </DivCircularProgress>
                ) : (
                  labelsSelectsTariff.map((value) => {
                    return (
                      <GridItem key={value.label}>
                        <Field
                          component={IoseSelection}
                          id={value.variable}
                          name={value.variable}
                          type={'text'}
                          func={value.func}
                          onblur={handleBlur}
                          title={value.label}
                          object={value.list}
                          defaultvalue={value.variable}
                          sidebar="true"
                          disabled={handleDisabledField(value.label)}
                          onOpen={value.onOpen}
                        />
                      </GridItem>
                    );
                  })
                )}
              </GridContainer>

              <DivInputs>
                <Field
                  component={IoseTextFieldSidebarStyled2}
                  id={'binLink'}
                  name={'binLink'}
                  type={'text'}
                  label={'Link 3D'}
                  defaultvalue={initialValues['binLink']}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors['binLink'] && touched['binLink']
                      ? errors['binLink']
                      : ''
                  }
                />
              </DivInputs>

              <DivInputs>
                <Field
                  component={IoseTextFieldSidebarStyled2}
                  id={'id3d'}
                  name={'id3d'}
                  type={'text'}
                  label={'ID 3D'}
                  defaultvalue={initialValues['id3d']}
                  onchange={handleChange}
                  onblur={handleBlur}
                  helpertext={
                    errors['id3d'] && touched['id3d'] ? errors['id3d'] : ''
                  }
                />
              </DivInputs>
            </FormStyled>
          )}
        </Formik>
      </Container>
    </>
  );
}

IoseFormUnityEditCard.propTypes = {
  /** This props get a function to edit Unity of SidebarUnityOperationsContainer*/
  editUnity: PropType.func,
};
