import React from 'react';
import { TableBody, TableCell, Paper, makeStyles } from '@material-ui/core';

import { StyledTable, TableHeadStyled, TableRowStyled } from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import IoseNoDataMessage from 'components/IoseNoDataMessage';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

const CircuitsTableRow = ({ value, onClick }) => {
  const classes = useStyles();

  return (
    <TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered>{value.concessionaire}</TableCellCentered>
      <TableCellCentered>{value.classification_code}</TableCellCentered>
      <TableCellCentered>{value.reference_month}</TableCellCentered>
      <TableCellCentered>{value.quantity}</TableCellCentered>
    </TableRowStyled>
  );
};

const CircuitsTable = ({ data, onRowClick }) => (
  <StyledTable>
    <TableHeadStyled>
      <TableRowStyled align="center">
        <TableHeaderCellCentered>CONCESSIONÁRIA</TableHeaderCellCentered>
        <TableHeaderCellCentered>CLASSIFICAÇÃO</TableHeaderCellCentered>
        <TableHeaderCellCentered>REF: MÊS/ANO</TableHeaderCellCentered>
        <TableHeaderCellCentered>QUANTIDADE DE FATURAS</TableHeaderCellCentered>
      </TableRowStyled>
    </TableHeadStyled>

    <TableBody>
      {data?.map((value, index) => (
        <CircuitsTableRow key={index} value={value} onClick={onRowClick} />
      ))}
    </TableBody>
  </StyledTable>
);

export default function IoseEnergyBillLogsTable({ loading, data }) {
  function renderChartOrErrorMessage() {
    if (data && data.length > 0) {
      return (
        <Paper>
          <CircuitsTable data={data} id="scrollableDiv" />
        </Paper>
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={parseInt(data?.length)}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={'0'}
          padding={'0'}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={530}
        >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
      )}
    </div>
  );
}
