import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Select, MenuItem, Modal, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as S from './style';

import { TextFieldStyled, IoseModalButton } from 'components';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
  },
  whiteText: {
    color: 'white',
    opacity: '70%',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function CreateEnviromentModal({
  open,
  onClose,
  unitys,
  addEnvironment,
  loading,
}) {
  const classes = useStyles();

  const handleCreateEnvironment = (values) => {
    setTimeout(() => {
      const environmentName = values.name;
      const environmentDescription = values.description;
      const environmentId3d = values.id_3d;

      addEnvironment(
        environmentName,
        environmentDescription,
        values.unity,
        environmentId3d
      );
    }, 500);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          {/* Cabeçalho */}
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <S.RoomIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                AMBIENTES
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
          </S.StyledHeaderModal>

          {/* Formulário */}
          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <S.ModalTitle variant="h5" gutterBottom>
                CADASTRO DE AMBIENTES
              </S.ModalTitle>
              <Formik
                initialValues={{
                  unity: '',
                  name: '',
                  description: '',
                  id_3d: '',
                }}
                onSubmit={handleCreateEnvironment}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                }) => (
                  <Form>
                    <S.DivInputs>
                      <S.StyledFormControl fullWidth>
                        <S.StyledInputLabel id="unity-label">
                          Unidade
                        </S.StyledInputLabel>
                        <Field
                          as={Select}
                          name="unity"
                          labelId="unity-label"
                          id="unity-select"
                          className={classes.whiteText}
                        >
                          {unitys.map((unity) => (
                            <MenuItem
                              key={unity.uuid_unity}
                              value={unity.uuid_unity}
                            >
                              {unity.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </S.StyledFormControl>
                    </S.DivInputs>
                    <S.DivInputs>
                      <Field
                        component={TextFieldStyled}
                        fullWidth
                        id={'name'}
                        name={'name'}
                        type={'text'}
                        label={'Nome'}
                        defaultvalue={initialValues['name']}
                        onchange={handleChange}
                        onblur={handleBlur}
                      />
                    </S.DivInputs>

                    <S.DivInputs>
                      <Field
                        component={TextFieldStyled}
                        fullWidth
                        id={'description'}
                        name={'description'}
                        type={'text'}
                        label={'Descrição'}
                        defaultvalue={initialValues['description']}
                        onchange={handleChange}
                        onblur={handleBlur}
                      />
                    </S.DivInputs>

                    <S.DivInputs>
                      <Field
                        component={TextFieldStyled}
                        fullWidth
                        id={'id_3d'}
                        name={'id_3d'}
                        type={'text'}
                        label={'ID 3D'}
                        defaultvalue={initialValues['id_3d']}
                        onchange={handleChange}
                        onblur={handleBlur}
                      />
                    </S.DivInputs>

                    {loading ? (
                      <S.DivCircularProgress>
                        <S.CircularProgressStyled />
                      </S.DivCircularProgress>
                    ) : (
                      <S.ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar Ambiente
                        </IoseModalButton>
                      </S.ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </S.StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}
