import React, { useEffect, useRef, useState } from 'react';
import { Grow, Tooltip } from '@material-ui/core';
import PropType from 'prop-types';

import {
  IoseDeleteButton,
  IoseEditButton,
  IoseMoreOptions,
  IoseOpenChartModal,
} from 'components';

import * as S from './style';

/**
 * This component create a card with all information about environment and allows editing/deleting environment.
 */
export default function IoseCardConcessionaires({
  name,
  description,
  handleOpenEditModal,
  handleOpenDeleteModal,
  onClick,
  cardEditTitle,
  chartModalTitle,
  cardIcon,
  showDeleteButton,
  showEditButton,
  showChartButton,
  selectedCard,
}) {
  const [moreOptions, setMoreOptions] = useState(false);

  const handleClick = () => {
    onClick();
    setMoreOptions(!moreOptions);
  };

  const cardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setMoreOptions(false); // Fecha o card se o usuário clicar fora dele
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setMoreOptions(false); // Fecha o card se o usuário pressionar a tecla Escape
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const renderButtonsCard = () => {
    const ObjectsData = [
      { title: 'Nome', value: selectedCard?.name ?? '' },
      { title: 'Cnpj', value: selectedCard?.cnpj },
      { title: 'Cidade', value: selectedCard?.city ?? '' },
      { title: 'Estado', value: selectedCard?.state },
    ];
    return (
      <Grow in={true} {...{ timeout: 1000 }}>
        <S.DivMoreInfo>
          <S.DivWrapperInfos>
            {ObjectsData?.map((info, index) => (
              <S.DivInfos key={index}>
                <p>
                  <strong>{info.title}: </strong>
                  <span>
                    <i>{info.value}</i>
                  </span>
                </p>
              </S.DivInfos>
            ))}
          </S.DivWrapperInfos>
          <S.DivButtons>
            {showDeleteButton === true ? (
              <IoseDeleteButton
                primary="true"
                card="true"
                tooltip="DELETAR CONCESSIONÁRIA"
                onClick={() => handleOpenDeleteModal()}
              />
            ) : (
              <></>
            )}
            {showEditButton === true ? (
              <IoseEditButton
                primary="true"
                card="true"
                tooltip={cardEditTitle}
                onClick={() => handleOpenEditModal()}
              />
            ) : (
              <></>
            )}

            {showChartButton ? (
              <IoseOpenChartModal
                primary="true"
                card="true"
                tooltip={chartModalTitle}
                onClick={() => {}}
              />
            ) : (
              <></>
            )}
          </S.DivButtons>
        </S.DivMoreInfo>
      </Grow>
    );
  };

  const renderSubtitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{description}</p>
    </Tooltip>
  );

  const renderTitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{name}</p>
    </Tooltip>
  );

  return (
    <Grow in={true} {...{ timeout: 1000 }} onClick={handleClick}>
      <S.CardStyled open={moreOptions} ref={cardRef}>
        <S.DivHeaderButton disableSpacing>
          <S.CardHeaderStyled
            avatar={<S.AvatarStyled>{cardIcon}</S.AvatarStyled>}
            title={renderTitle()}
            subheader={renderSubtitle()}
          ></S.CardHeaderStyled>
          <div>
            <IoseMoreOptions
              primary="true"
              card="true"
              onClick={() =>
                moreOptions ? setMoreOptions(false) : setMoreOptions(true)
              }
            />
          </div>
        </S.DivHeaderButton>

        <S.CardContentStyled>
          {moreOptions && renderButtonsCard()}
          {/* {renderData()} */}
        </S.CardContentStyled>
      </S.CardStyled>
    </Grow>
  );
}

IoseCardConcessionaires.propTypes = {
  /** This prop get the name of unity */
  name: PropType.string,

  /** This prop get the description of unity */
  description: PropType.string,

  /** This prop get the function when click on the button delete*/
  clickDelete: PropType.func,

  /** This prop get the function when click on the button edit*/
  clickEdit: PropType.func,

  /** This prop get the function when click on the button schedule*/
  clickSchedule: PropType.func,

  /** This prop get the function when click on the card */
  clickCard: PropType.func,
};
