// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useSystemUrls() {
  const [noUrlError, setNoUrlError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getSystemUrls = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllSystemUrls(headers);

    const { data } = response;

    const systemUrls = data || [];

    if (systemUrls.length === 0) {
      setNoUrlError(Messages.noRegisteredUrls);
      throw new Error(Messages.noRegisteredUrls);
    }
    return systemUrls;
  };

  const {
    data: systemUrls,
    error: systemUrlsError,
    refetch: refreshUrls,
    isLoading,
  } = useQuery(['systemUrlsData'], getSystemUrls);

  return {
    systemUrls,
    systemUrlsError,
    noUrlError,
    refreshUrls,
    isLoading,
  };
}

export default useSystemUrls;
