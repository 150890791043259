import React, { useState, useEffect } from 'react';
import { Viewer, ViewerEvent } from '@speckle/viewer';
import { DivPaper } from './style';
import { useObjects } from 'hooks';

const SpeckleViewer = ({ onElementSelected, data, endpoint }) => {
  const { device3dMapping } = useObjects();
  const [objectGroupsState, setObjectGroupsState] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  const objectIds = device3dMapping
    ?.map((device) => device.uuid_object)
    .filter((id) => id !== '');
  const containerId = 'renderedView';
  const commitUrl = endpoint;

  useEffect(() => {
    if (data) {
      const groups = device3dMapping
        ?.map((device) => {
          const match = data.find(
            (item) => item.uuid_circuit.toString() === device.uuid_circuit
          );

          if (match && match.current > 0.1) {
            const color = 'green';
            return { objectId: device.uuid_object, color };
          }

          return null;
        })
        .filter(Boolean);

      const objectGroups = groups.map((group) => ({
        objectIds: [group.objectId],
        color: group.color,
      }));

      setObjectGroupsState(objectGroups);
      setForceUpdate((prev) => !prev);
    }
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (objectGroupsState.length > 0 && objectIds.length > 0) {
      const element = getContainerHTMLElement();

      async function loadViewerIntoContainer() {
        const newViewer = new Viewer(element);
        await newViewer.loadObject(commitUrl);
        newViewer.on(ViewerEvent.ObjectClicked, (selectionEvent) => {
          if (selectionEvent?.hits) {
            const filtered = selectionEvent.hits.filter((hit) =>
              objectIds.includes(hit.object.id)
            );
            if (filtered.length) {
              newViewer.selectObjects([filtered[0].object.id]);
              onElementSelected(filtered[0].object.id);
            } else newViewer.resetSelection();
          }
        });

        await newViewer.isolateObjects(objectIds, '', false, true);
        await newViewer.setUserObjectColors(objectGroupsState);
        // const tree = await newViewer.getDataTree();

        newViewer.requestRender();
      }

      if (!element.children || !element.children.length) {
        loadViewerIntoContainer();
      }
    }
    //eslint-disable-next-line
  }, [
    forceUpdate,
    objectGroupsState,
    objectIds,
    onElementSelected,
    device3dMapping,
  ]);

  function getContainerHTMLElement() {
    return document.getElementById(containerId);
  }

  return (
    <DivPaper>
      <div id={containerId} style={{ height: '800px', width: '1200px' }}></div>
    </DivPaper>
  );
};

export default SpeckleViewer;
