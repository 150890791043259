// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useUniqueConcessionaires() {
  const [noConcessioaniresError, setNoConcessionairesError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getUniqueConcessionaires = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getUniqueConcessionaires(headers);

    const uniqueConcessionaires = response.data.data || [];

    if (uniqueConcessionaires.length === 0) {
      setNoConcessionairesError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return uniqueConcessionaires;
  };

  const {
    data: uniqueConcessionaires,
    error: uniqueConcessionairesError,
    refetch: refreshUniqueConcessionaires,
    isLoading,
  } = useQuery(['uniqueConcessionaires'], getUniqueConcessionaires);

  return {
    uniqueConcessionaires,
    uniqueConcessionairesError,
    noConcessioaniresError,
    refreshUniqueConcessionaires,
    isLoading,
  };
}

export default useUniqueConcessionaires;
