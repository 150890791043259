import React from 'react';
import { Formik, Form, Field } from 'formik';
import { MenuItem, Modal, Grow, Input } from '@material-ui/core';
import MaskedInput from 'react-input-mask';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';

import * as S from './style';

import {
  IoseAlertErroModal,
  IoseAlertSuccessModal,
  TextFieldStyled,
  IoseModalButton,
} from 'components';

import { StatesAndCities } from '../../../constants/index.js';
import { validationsForm } from 'common';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function IoseConcessionaireAddModal({
  open,
  onClose,
  loading,
  handleStateChange,
  selectedState,
  notificationType,
  addNotification,
  showNotification,
  submit,
  selectedCities,
}) {
  const classes = useStyles();

  const handleCreateConcessionaire = (values) => {
    setTimeout(() => {
      const { name, code, cnpj, city } = values;

      submit(name, code, cnpj, selectedState, city);
    }, 500);
  };

  const concessionaireSchema = Yup.object().shape({
    name: validationsForm.required,
    code: validationsForm.number_required,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <S.RoomIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                CONCESSIONÁRIAS
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
          </S.StyledHeaderModal>

          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <S.ModalTitle variant="h5" gutterBottom>
                CADASTRO DE CONCESSIONÁRIAS
              </S.ModalTitle>
              <Formik
                initialValues={{
                  name: '',
                  code: '',
                  cnpj: '',
                }}
                validationSchema={concessionaireSchema}
                onSubmit={handleCreateConcessionaire}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  errors,
                  touched,
                  initialValues,
                }) => (
                  <Form>
                    <S.DivInputs marginBottom="10px">
                      <S.StyledField
                        component={TextFieldStyled}
                        fullWidth
                        id={'name'}
                        name={'name'}
                        type={'text'}
                        label={'Nome'}
                        defaultvalue={initialValues['name']}
                        onchange={handleChange}
                        onblur={handleBlur}
                        helpertext={
                          errors['name'] && touched['name']
                            ? errors['name']
                            : ''
                        }
                      />
                    </S.DivInputs>

                    <S.DivInputs marginBottom="10px">
                      <S.StyledField
                        component={TextFieldStyled}
                        fullWidth
                        id={'code'}
                        code={'code'}
                        label={'Código da concessionária'}
                        defaultvalue={initialValues['code']}
                        onchange={handleChange}
                        onblur={handleBlur}
                        helpertext={
                          errors['code'] && touched['code']
                            ? errors['code']
                            : ''
                        }
                      />
                    </S.DivInputs>

                    <S.DivInputs>
                      <Field name="cnpj">
                        {({ field }) => (
                          <S.StyledFormControl fullWidth>
                            <S.StyledInputLabel htmlFor="cnpj">
                              Cnpj
                            </S.StyledInputLabel>
                            <Input
                              id="cnpj"
                              type="text"
                              {...field}
                              inputComponent={MaskedInput}
                              inputProps={{
                                mask: '99.999.999/9999-99',
                                placeholder: '00.000.000/0000-00',
                              }}
                              style={{ color: '#ffff', opacity: '80%' }}
                              required={true}
                            />
                          </S.StyledFormControl>
                        )}
                      </Field>
                    </S.DivInputs>

                    <S.DivInputs>
                      <S.StyledFormControl fullWidth>
                        <S.StyledInputLabel id="state-label">
                          Estado
                        </S.StyledInputLabel>
                        <Field
                          as={S.StyledSelect}
                          name="state"
                          labelId="state-label"
                          id="state-select"
                          onChange={handleStateChange}
                          className={classes.whiteText}
                          required={true}
                        >
                          {StatesAndCities.map((stateObj) =>
                            stateObj.estados.map((estado) => (
                              <MenuItem
                                key={estado.id}
                                value={estado.nomeEstado}
                              >
                                {estado.nomeEstado}
                              </MenuItem>
                            ))
                          )}
                        </Field>
                      </S.StyledFormControl>
                    </S.DivInputs>

                    <S.DivInputs>
                      <S.StyledFormControl fullWidth>
                        <S.StyledInputLabel id="city-label">
                          Cidade
                        </S.StyledInputLabel>
                        <Field
                          as={S.StyledSelect}
                          name="city"
                          labelId="city-label"
                          id="city-select"
                          className={classes.whiteText}
                          required={true}
                        >
                          {selectedCities.map((city) => (
                            <MenuItem
                              key={city.id_cidade}
                              value={city.nomeCidade}
                            >
                              {city.nomeCidade}
                            </MenuItem>
                          ))}
                        </Field>
                      </S.StyledFormControl>
                    </S.DivInputs>

                    {loading ? (
                      <S.DivCircularProgress>
                        <S.CircularProgressStyled />
                      </S.DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <S.ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar Concessionária
                        </IoseModalButton>
                      </S.ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </S.StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}
