import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropType from 'prop-types';

//Imports from Style
import {
  StyledDeleteButton,
  StyledButton,
  StyledCalendarButton,
  StyledOperatorButton,
  StyledSearchButton,
  StyledBackButton,
  StyledCloseButton,
  StyledDashboardButton,
  StyledResendButton,
  StyledUserButton,
  StyledInfoButton,
  StyledLogButton,
  AdminIcon,
  CircularProgressStyled,
  DivCircularProgress,
  StyledCollapseButton,
  StyledAdmDashButton,
  StyledButtonAdmDash,
  ChartIcon,
  StyledMapButton,
  StyledBillsButton,
  StyledBackAuditButton,
  StyledGetAppIconButton,
  StyledCloseModalButton,
  StyledEditMeasurerIconButton,
  StyledEditTaxesIconButton,
  StyledEditItensIconButton,
} from './style';

//Imports from Material Icons
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import EventIcon from '@material-ui/icons/Event';
import GroupIcon from '@material-ui/icons/Group';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReplayIcon from '@material-ui/icons/Replay';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import FilterListIcon from '@material-ui/icons/FilterList';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LockIcon from '@material-ui/icons/Lock';

/**
 * This component has all icon buttons used in the system
 * @visibleName IoseButtonIcon
 */
export function IoseDeleteButton({ tooltip, left, primary, onClick, card }) {
  return (
    <Tooltip title={tooltip ? tooltip : 'Deletar'} placement="bottom" arrow>
      <StyledDeleteButton
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
      >
        <DeleteIcon />
      </StyledDeleteButton>
    </Tooltip>
  );
}

export function IoseEditButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={primary ? tooltip : 'Editar'} placement="bottom" arrow>
      <StyledButton
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <CreateIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IosePermissionsButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={primary ? tooltip : 'Editar'} placement="bottom" arrow>
      <StyledButton
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <LockIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseCardButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
  icon,
}) {
  return (
    <Tooltip title={primary ? tooltip : 'Editar'} placement="bottom" arrow>
      <StyledButton
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        {icon}
      </StyledButton>
    </Tooltip>
  );
}

export function IoseOpenObjectsModal({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={primary ? tooltip : 'Editar'} placement="bottom" arrow>
      <StyledButton
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <ViewModuleIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseOpenChartModal({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={primary ? tooltip : 'Editar'} placement="bottom" arrow>
      <StyledButton
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <ChartIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseLogButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
  right,
  currentsection = 1,
  section = 0,
}) {
  return (
    <Tooltip
      title={primary ? tooltip : 'Logs do Circuito'}
      placement="bottom"
      arrow
    >
      <StyledLogButton
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
        right={right}
        currentsection={currentsection}
        section={section}
      >
        <EventNoteIcon />
      </StyledLogButton>
    </Tooltip>
  );
}

export function IoseEventsButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
  right,
  currentsection = 1,
  section = 0,
}) {
  return (
    <Tooltip
      title={primary ? tooltip : 'Eventos do Circuito'}
      placement="bottom"
      arrow
    >
      <StyledLogButton
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
        right={right}
        currentsection={currentsection}
        section={section}
      >
        <DateRangeIcon />
      </StyledLogButton>
    </Tooltip>
  );
}

export function IoseInfoButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
  right,
  currentsection = 1,
  section = 0,
}) {
  return (
    <Tooltip title={primary ? tooltip : 'Informações'} placement="bottom" arrow>
      <StyledInfoButton
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
        right={right}
        currentsection={currentsection}
        section={section}
      >
        <InfoOutlinedIcon />
      </StyledInfoButton>
    </Tooltip>
  );
}

export function IoseCalendarButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  right,
  padding,
  top,
  currentsection = 1,
  section = 0,
}) {
  return (
    <Tooltip
      title={tooltip ? tooltip : 'Agendamentos'}
      placement="bottom"
      arrow
      card={card}
    >
      <StyledCalendarButton
        primary={primary}
        left={left}
        onClick={onClick}
        right={right}
        padding={padding}
        top={top}
        currentsection={currentsection}
        section={section}
        card={card}
      >
        <EventIcon />
      </StyledCalendarButton>
    </Tooltip>
  );
}

export function IoseHistoricButton({ tooltip, left, primary, onClick }) {
  return (
    <Tooltip
      title={primary ? tooltip : 'Ver Histórico'}
      placement="bottom"
      arrow
    >
      <StyledButton primary={primary} left={left} onClick={onClick}>
        <DescriptionIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseHistoricButtonDownload({
  tooltip,
  left,
  right,
  rightmobile,
  primary,
  onClick,
  loading = true,
}) {
  return (
    <Tooltip
      title={primary ? tooltip : 'Baixar Histórico'}
      placement="bottom"
      arrow
    >
      <span>
        <StyledButton
          primary={primary}
          right={right}
          left={left}
          rightmobile={rightmobile}
          onClick={onClick}
          disabled={loading}
        >
          {loading ? (
            <DivCircularProgress>
              <CircularProgressStyled />
            </DivCircularProgress>
          ) : (
            <GetAppIcon />
          )}
        </StyledButton>
      </span>
    </Tooltip>
  );
}

export function IoseAddButton({
  tooltip,
  left,
  primary,
  onClick,
  top,
  right,
  bottom,
}) {
  return (
    <Tooltip title={primary ? 'Adicionar' : tooltip} placement="bottom" arrow>
      <StyledButton
        primary={primary}
        left={left}
        onClick={onClick}
        top={top}
        bottom={bottom}
        right={right}
      >
        <AddIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseAddFilterButton({
  tooltip,
  left,
  primary,
  onClick,
  top,
  right,
  bottom,
}) {
  return (
    <Tooltip title={primary ? 'Adicionar' : tooltip} placement="bottom" arrow>
      <StyledButton
        primary={primary}
        left={left}
        onClick={onClick}
        top={top}
        bottom={bottom}
        right={right}
      >
        <FilterListIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseAddButton2({
  tooltip,
  left,
  primary,
  onClick,
  top,
  right,
  bottom,
}) {
  return (
    <Tooltip title={primary ? 'Adicionar' : tooltip} placement="bottom" arrow>
      <StyledButton
        primary={primary}
        left={left}
        onClick={onClick}
        top={top}
        bottom={bottom}
        right={right}
      >
        <AddIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseOperatorButton({ tooltip, left, primary, onClick }) {
  return (
    <Tooltip
      title={primary ? tooltip : 'FUNCIONÁRIOS'}
      placement="bottom"
      arrow
    >
      <StyledOperatorButton primary={primary} left={left} onClick={onClick}>
        <GroupIcon />
      </StyledOperatorButton>
    </Tooltip>
  );
}

export function IoseUserButton({ left, primary, onClick }) {
  return (
    <StyledUserButton onClick={onClick}>
      <AccountCircle />
    </StyledUserButton>
  );
}

export function IoseConfigButton({ left, primary, onClick }) {
  return (
    <StyledUserButton onClick={onClick}>
      <SettingsIcon />
    </StyledUserButton>
  );
}

export function IoseSearchtButton({
  tooltip,
  left,
  primary,
  onClick,
  opacity,
}) {
  return (
    <Tooltip title={primary ? tooltip : 'Pesquisar'} placement="bottom" arrow>
      <StyledSearchButton
        primary={primary}
        left={left}
        onClick={onClick}
        opacity={opacity}
      >
        <SearchIcon />
      </StyledSearchButton>
    </Tooltip>
  );
}

export function IoseBackButton({ left, headerbar, onClick, right, bottom }) {
  return (
    <StyledBackButton
      headerbar={headerbar}
      right={right}
      left={left}
      onClick={onClick}
      bottom={bottom}
    >
      <ArrowBackIos />
    </StyledBackButton>
  );
}

export function IoseBoltButton({
  tooltip,
  left,
  primary,
  onClick,
  right,
  opacity,
  bottom,
}) {
  return (
    <Tooltip title={primary ? 'Circuitos' : tooltip} placement="bottom" arrow>
      <StyledButton
        tooltip={tooltip}
        left={left}
        right={right}
        onClick={onClick}
        primary={primary}
        opacity={opacity}
        bottom={bottom}
      >
        <FlashOnIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseMoreOptions({ left, primary, onClick, card, bottom, top }) {
  return (
    <Tooltip title="VER MAIS" placement="bottom" arrow>
      <StyledButton
        left={left}
        onClick={onClick}
        primary={primary}
        card={card}
        bottom={bottom}
        top={top}
      >
        <MoreVertIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseAdminButton({ tooltip, left, primary, onClick, card }) {
  return (
    <Tooltip
      title={primary ? tooltip : 'Administradores'}
      placement="bottom"
      arrow
    >
      <StyledButton primary={primary} left={left} onClick={onClick} card={card}>
        <AdminIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseIDButton({ tooltip, left, primary, onClick, card }) {
  return (
    <Tooltip
      title={primary ? tooltip : 'Identificador'}
      placement="bottom"
      arrow
    >
      <StyledButton primary={primary} left={left} onClick={onClick} card={card}>
        ID
      </StyledButton>
    </Tooltip>
  );
}

export function IoseAddUserButton({ tooltip, left, primary, onClick, card }) {
  return (
    <Tooltip
      title={primary ? tooltip : 'Identificador'}
      placement="bottom"
      arrow
    >
      <StyledButton primary={primary} left={left} onClick={onClick} card={card}>
        <PersonAddIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseAddGroupButton({ tooltip, left, primary, onClick, card }) {
  return (
    <Tooltip
      title={primary ? tooltip : 'Identificador'}
      placement="bottom"
      arrow
    >
      <StyledButton primary={primary} left={left} onClick={onClick} card={card}>
        <GroupIcon />
      </StyledButton>
    </Tooltip>
  );
}

export function IoseCloseButton({
  tooltip,
  left,
  primary,
  onClick,
  position,
  bottom,
  right,
  top,
}) {
  return (
    <Tooltip title={primary ? tooltip : 'Fechar'} placement="bottom" arrow>
      <StyledCloseButton
        primary={primary}
        left={left}
        bottom={bottom}
        right={right}
        top={top}
        onClick={onClick}
        position={position}
      >
        <CloseIcon />
      </StyledCloseButton>
    </Tooltip>
  );
}

export function IoseDashboardButton({
  tooltip,
  left,
  primary,
  onClick,
  right,
  bottom,
  top,
}) {
  return (
    <Tooltip title={primary ? 'Dashboard' : tooltip} placement="bottom" arrow>
      <StyledDashboardButton
        left={left}
        right={right}
        onClick={onClick}
        primary={primary}
        bottom={bottom}
        top={top}
      >
        <DashboardIcon />
      </StyledDashboardButton>
    </Tooltip>
  );
}

export function IoseResendButton({
  tooltip,
  left,
  primary,
  onClick,
  right,
  bottom,
  sidebar,
}) {
  return (
    <Tooltip
      title={primary ? 'Reenviar Requisição' : tooltip}
      placement="bottom"
      arrow
    >
      <StyledResendButton
        tooltip={tooltip}
        left={left}
        right={right}
        onClick={onClick}
        primary={primary}
        bottom={bottom}
        sidebar={sidebar}
      >
        <ReplayIcon />
      </StyledResendButton>
    </Tooltip>
  );
}

export function IoseCollapseButton({
  tooltip,
  left,
  primary,
  onClick,
  right,
  bottom,
  top,
  color,
  expand,
}) {
  return (
    <StyledCollapseButton
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      onClick={onClick}
      primary={primary}
      color={color}
      expand={expand}
    >
      <ExpandMoreIcon />
    </StyledCollapseButton>
  );
}

// export function IoseDashAdmButton() {
//   return <StyledAdmDashButton />;
// }

export function IoseDashAdmButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip
      title={primary ? tooltip : 'GERENCIAMENTO DE UNIDADES'}
      placement="bottom"
      arrow
    >
      <StyledButtonAdmDash
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <StyledAdmDashButton />
      </StyledButtonAdmDash>
    </Tooltip>
  );
}

export function IoseMapRedirectButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <StyledButtonAdmDash
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <StyledMapButton />
      </StyledButtonAdmDash>
    </Tooltip>
  );
}

export function IoseCloseModalButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <StyledButtonAdmDash
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <StyledCloseModalButton />
      </StyledButtonAdmDash>
    </Tooltip>
  );
}

export function IoseAuditRedirectButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <StyledButtonAdmDash
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <StyledBackAuditButton />
      </StyledButtonAdmDash>
    </Tooltip>
  );
}

export function IoseDownloadButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <StyledButtonAdmDash
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <StyledGetAppIconButton />
      </StyledButtonAdmDash>
    </Tooltip>
  );
}

export function IoseEditBillButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <StyledButtonAdmDash
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <StyledEditMeasurerIconButton />
      </StyledButtonAdmDash>
    </Tooltip>
  );
}

export function IoseEditTaxesBillButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <StyledButtonAdmDash
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <StyledEditTaxesIconButton />
      </StyledButtonAdmDash>
    </Tooltip>
  );
}

export function IoseEditItensBillButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <StyledButtonAdmDash
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <StyledEditItensIconButton />
      </StyledButtonAdmDash>
    </Tooltip>
  );
}

export function IoseBillsRedirectButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <StyledButtonAdmDash
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <StyledBillsButton />
      </StyledButtonAdmDash>
    </Tooltip>
  );
}

export function IoseRedirectButton({
  tooltip,
  left,
  primary,
  onClick,
  card,
  bottom,
}) {
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <StyledButtonAdmDash
        primary={primary}
        left={left}
        onClick={onClick}
        card={card}
        bottom={bottom}
      >
        <StyledAdmDashButton />
      </StyledButtonAdmDash>
    </Tooltip>
  );
}

const geralProps = {
  /** This props get the description button*/
  tooltip: PropType.string,
  /** This props get the margin-left in pixels of button*/
  left: PropType.string,
  /** This props get the margin-bottom in pixels of button*/
  bottom: PropType.string,
  /** This props get the margin-right in pixels of button*/
  right: PropType.string,
  /** This props get that will be transparent like a default Material button*/
  primary: PropType.string,
  /** This props get the function that will be run when the button clicked*/
  onClick: PropType.func,
  /** This props get the 'true' or 'false' if the button was in the any card (unity or admin)*/
  card: PropType.string,
};

IoseDeleteButton.propTypes = geralProps;
IoseEditButton.propTypes = geralProps;
IoseCalendarButton.propTypes = geralProps;
IoseHistoricButtonDownload.propTypes = geralProps;
IoseAddButton.propTypes = geralProps;
IoseOperatorButton.propTypes = geralProps;
IoseSearchtButton.propTypes = geralProps;
IoseBackButton.propTypes = {
  ...geralProps,

  /** This props get the 'true' or 'false' if the button was in headerbar*/
  headerbar: PropType.string,
};
