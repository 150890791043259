import React, { useContext, useEffect, useState } from 'react';

import {
  DashFiltersAndCards,
  DashCharts,
  IoseEnergyBillsTable,
  IoseSubHeaderBarWithFilters,
  IoseButton,
  IoseSelectReferenceMonth,
  IoseSelectCompleteDate,
  IoseAlertLoadingCards,
} from 'components';

import * as S from './style';

import {
  useBillValueAudit,
  useSummaryBills,
  useConsumerGroup,
  useUniqueAtividades,
  useUniqueConcessionaires,
  useUniqueDescriptions,
  useUniqueRegionalManagement,
  useUniqueGeneralManagement,
  useCalculateTotalConsumption,
  useCalculateAllRMConsumption,
  useCalculateAllGMConsumption,
  useCalculateTotalBillValues,
  useCalculateTotalExtract,
  useGroupPermissions,
} from 'hooks';

import IoseDynamicFilter from 'components/IoseDynamicFilter';
import { IoseAddFilterButton } from 'components/IoseButtonIcon';
import { AccountContext, ioseAPI, LocalStorage } from 'common';
import Permissions from 'common/permissions';

const calculateTotalBillByUnit = (data) => {
  const billValueByUnit = {};
  const billCountByUnit = {};

  data?.forEach((item) => {
    const { identification_number, bill_value } = item;

    if (billValueByUnit[identification_number]) {
      billValueByUnit[identification_number] += bill_value;
      billCountByUnit[identification_number] += 1;
    } else {
      billValueByUnit[identification_number] = bill_value;
      billCountByUnit[identification_number] = 1;
    }
  });

  const billValueByUnitArray = Object.entries(billValueByUnit).map(
    ([identification_number, totalBillValue]) => ({
      identification_number,
      totalBillValue,
      averageBillValue: totalBillValue / billCountByUnit[identification_number],
    })
  );

  return billValueByUnitArray;
};

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

const formatToISODate = (date) => {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00.000Z`;
  }
  return '';
};

export default function EnergyBillsContainer() {
  const { billValueAudit } = useBillValueAudit();
  const { summaryBills } = useSummaryBills();
  const { consumerGroups } = useConsumerGroup();

  const { uniqueAtividades } = useUniqueAtividades();
  const { uniqueConcessionaires } = useUniqueConcessionaires();
  const { uniqueDescriptions } = useUniqueDescriptions();
  const { regionalManagements } = useUniqueRegionalManagement();
  const { generalManagements } = useUniqueGeneralManagement();

  const { totalConsumptions } = useCalculateTotalConsumption();
  const { totalRegionalConsumptions } = useCalculateAllRMConsumption();
  const { totalGeneralConsumptions } = useCalculateAllGMConsumption();
  const { totalBillValues } = useCalculateTotalBillValues();
  const { extractValues } = useCalculateTotalExtract();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  const { getSession } = useContext(AccountContext);

  const today = new Date();

  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAtividade, setSelectedAtividade] = useState('');
  const [selectedConcessionaire, setSelectedConcessionaire] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedRManagement, setSelectedRManagement] = useState('');
  const [selectedGManagement, setSelectedGManagement] = useState('');
  const [selectedRefMonth, setSelectedRefMonth] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [initialReading, setInitialReading] = useState(null);
  const [selectedInitialReading, setSelectedInitialReading] = useState(today);
  const [finalReading, setFinalReading] = useState(null);
  const [selectedFinalReading, setSelectedFinalReading] = useState(today);
  const [selectedInitialExpiration, setSelectedInitialExpiration] = useState(
    today
  );
  const [selectedFinalExpiration, setSelectedFinalExpiration] = useState(today);

  const [selectedFilters, setSelectedFilters] = useState({});

  const [filteredConsumption, setFilteredConsumption] = useState();
  const [regionalConsumption, setRegionalConsumption] = useState();
  const [
    filteredRegionalConsumption,
    setFilteredRegionalConsumption,
  ] = useState();
  const [generalConsumption, setGeneralConsumption] = useState();
  const [
    filteredGeneralConsumption,
    setFilteredGeneralConsumption,
  ] = useState();

  const [
    totalByIdentificationNumber,
    setTotalByIdentificationNumber,
  ] = useState([]);

  const [auditValues, setAuditValues] = useState({});
  const [auditConsumptions, setAuditConsumptions] = useState({});
  const [auditExtract, setAuditExtract] = useState({});
  const [filteredBillValue, setfilteredBillValue] = useState();
  const [filteredExtract, setFilteredExtract] = useState();

  const [hasReadPermission, setHasReadPermission] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    const readPath = ['dashboards', 'faturas', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    if (filteredBillValue) {
      setAuditValues({
        totalBillValue: filteredBillValue?.totalBillValue,
        totalRecalcValue: filteredBillValue?.totalRecalcValue,
        totalDifValue: filteredBillValue?.totalBalance,
        totalConsumerGroup: filteredBillValue?.totalConsumerGroup,
        altaTensaoCount: filteredBillValue?.altaTensaoCount,
        baixaTensaoCount: filteredBillValue?.baixaTensaoCount,
      });
    } else {
      setAuditValues({
        totalBillValue: totalBillValues?.totalBillValue,
        totalRecalcValue: totalBillValues?.totalRecalcValue,
        totalDifValue: totalBillValues?.totalBalance,
        totalConsumerGroup: totalBillValues?.totalConsumerGroup,
        altaTensaoCount: totalBillValues?.altaTensaoCount,
        baixaTensaoCount: totalBillValues?.baixaTensaoCount,
      });
    }
  }, [totalBillValues, filteredBillValue]);

  useEffect(() => {
    if (filteredConsumption) {
      setAuditConsumptions({
        totalBillConsumption: filteredConsumption.totalBillConsumption,
        totalRecalcConsumption: filteredConsumption.totalCalcConsumption,
        totalDifConsumption: filteredConsumption.totalBalance,
      });
    } else {
      setAuditConsumptions({
        totalBillConsumption: totalConsumptions?.totalBillConsumption,
        totalRecalcConsumption: totalConsumptions?.totalCalcConsumption,
        totalDifConsumption: totalConsumptions?.totalBalance,
      });
    }
  }, [totalConsumptions, filteredConsumption]);

  useEffect(() => {
    if (filteredRegionalConsumption) {
      setRegionalConsumption({
        series: filteredRegionalConsumption.series,
        xAxys: filteredRegionalConsumption.xAxis,
        yAxys: filteredRegionalConsumption.yAxis,
      });
    } else {
      setRegionalConsumption({
        series: totalRegionalConsumptions?.series,
        xAxys: totalRegionalConsumptions?.xAxis,
        yAxys: totalRegionalConsumptions?.yAxis,
      });
    }
  }, [totalRegionalConsumptions, filteredRegionalConsumption]);

  useEffect(() => {
    if (filteredGeneralConsumption) {
      setGeneralConsumption({
        series: filteredGeneralConsumption?.series,
        xAxis: filteredGeneralConsumption?.xAxis,
        yAxis: filteredGeneralConsumption?.yAxis,
      });
    } else {
      setGeneralConsumption({
        series: totalGeneralConsumptions?.series,
        xAxis: totalGeneralConsumptions?.xAxis,
        yAxis: totalGeneralConsumptions?.yAxis,
      });
    }
  }, [totalGeneralConsumptions, filteredGeneralConsumption]);

  useEffect(() => {
    if (filteredExtract) {
      setAuditExtract({
        totalDemanPonta: filteredExtract?.totalDemanPonta,
        totalDemanFora: filteredExtract?.totalDemanFora,
        totalDemanContPonta: filteredExtract?.totalDemanContPonta,
        totalDemanContFora: filteredExtract?.totalDemanContFora,
        totalConsumPonta: filteredExtract?.totalConsumPonta,
        totalConsumFora: filteredExtract?.totalConsumFora,
      });
    } else {
      setAuditExtract({
        totalDemanPonta: extractValues?.totalDemanPonta,
        totalDemanFora: extractValues?.totalDemanFora,
        totalDemanContPonta: extractValues?.totalDemanContPonta,
        totalDemanContFora: extractValues?.totalDemanContFora,
        totalConsumPonta: extractValues?.totalConsumPonta,
        totalConsumFora: extractValues?.totalConsumFora,
      });
    }
  }, [extractValues, filteredExtract]);

  useEffect(() => {
    const totalBillByUnit = calculateTotalBillByUnit(billValueAudit);
    setTotalByIdentificationNumber(totalBillByUnit);
  }, [billValueAudit]);

  const averages = {
    valueAverage: Number.isNaN(
      auditValues?.totalBillValue / consumerGroups?.length
    )
      ? 0
      : auditValues.totalBillValue / consumerGroups.length,
    consumptionAverage: Number.isNaN(
      auditConsumptions?.totalBillConsumption / consumerGroups?.length
    )
      ? 0
      : auditConsumptions.totalBillConsumption / consumerGroups.length,
  };

  const renderSubHeaderAndFilters = () => {
    return (
      <IoseSubHeaderBarWithFilters
        title={'Dashboard Faturas de energia'}
        description={'Filtre os dados para alterar o Dashboard'}
      />
    );
  };

  const RenderFilterContainer = () => {
    const filterOptions = [
      'Atividade',
      'Concessionaria',
      'Descrição da Unidade',
      'Gerência Regional',
      'Gerência Geral',
      'REF: MÊS/ANO',
      'Leitura anterior',
      'Vencimento',
    ];

    const toggleFilters = () => {
      setShowFilters(!showFilters);
    };

    const handleOptionClick = (option) => {
      setSelectedOption(option);
      setShowFilters(false);
    };

    const handleAtividadeChange = (value) => {
      setSelectedAtividade(value);
    };

    const handleConcessionariaChange = (value) => {
      setSelectedConcessionaire(value);
    };

    const handleDescriptionChange = (value) => {
      setSelectedDescription(value);
    };

    const handleRManagementChange = (value) => {
      setSelectedRManagement(value);
    };

    const handleGManagementChange = (value) => {
      setSelectedGManagement(value);
    };

    const handleRefMonthChange = (value) => {
      const formattedDate = formatToBrazilianDate(value);

      setSelectedDate(value);
      setSelectedRefMonth(formattedDate);
    };

    const handleInitialReadingChange = (date) => {
      const formattedData = formatToISODate(date);

      setInitialReading(formattedData);
      setSelectedInitialReading(date);
    };

    const handleFinalReadingChange = (date) => {
      const formattedData = formatToISODate(date);

      setFinalReading(formattedData);
      setSelectedFinalReading(date);
    };

    const handleInitialExpirationChange = (date) => {
      setSelectedInitialExpiration(date);
    };

    const handleFinalExpirationChange = (date) => {
      setSelectedFinalExpiration(date);
    };

    const renderSelectedComponent = () => {
      const filterOptions = {
        Atividade: {
          label: 'Atividade',
          databaseName: 'atividade',
          value: selectedAtividade,
          onChange: handleAtividadeChange,
          options: uniqueAtividades,
        },
        Concessionaria: {
          label: 'Concessionaria',
          databaseName: 'concessionaire',
          value: selectedConcessionaire,
          onChange: handleConcessionariaChange,
          options: uniqueConcessionaires,
        },
        'Descrição da Unidade': {
          label: 'Descrição da Unidade',
          databaseName: 'descricao_unidade',
          value: selectedDescription,
          onChange: handleDescriptionChange,
          options: uniqueDescriptions,
        },
        'Gerência Regional': {
          label: 'Gerência Regional',
          databaseName: 'regional_management',
          value: selectedRManagement,
          onChange: handleRManagementChange,
          options: regionalManagements,
        },
        'Gerência Geral': {
          label: 'Gerência Geral',
          databaseName: 'regional_management',
          value: selectedGManagement,
          onChange: handleGManagementChange,
          options: generalManagements,
        },
        'REF: MÊS/ANO': {
          label: 'REF: MÊS/ANO',
          databaseName: 'reference_month',
          value: selectedRefMonth,
          onChange: handleRefMonthChange,
          options: generalManagements,
        },
        'Leitura anterior': {
          label: 'Leitura anterior',
          databaseName: 'previous_reading',
          initial_value: selectedInitialReading,
          initial_iso_value: initialReading,
          final_value: selectedFinalReading,
          final_iso_value: finalReading,
          initial_onChange: handleInitialReadingChange,
          final_onChange: handleFinalReadingChange,
        },
        'Vencimento': {
          label: 'Vencimento',
          databaseName: 'vencimento',
          initial_value: selectedInitialExpiration,
          final_value: selectedFinalExpiration,
          initial_onChange: handleInitialExpirationChange,
          final_onChange: handleFinalExpirationChange,
        },
      };

      const selectedFilter = filterOptions[selectedOption];

      const handleButtonClick = () => {
        if (selectedFilter && selectedFilter.databaseName) {
          const formattedFilter = { ...selectedFilters };

          if (selectedFilter.databaseName === 'previous_reading') {
            if (selectedFilter.initial_value && selectedFilter.final_value) {
              formattedFilter[
                'previous_reading_start'
              ] = selectedFilter.initial_value.toLocaleDateString();
              formattedFilter[
                'previous_reading_end'
              ] = selectedFilter.final_value.toLocaleDateString();
            }
          }
          if (selectedFilter.databaseName === 'vencimento') {
            if (selectedFilter.initial_value && selectedFilter.final_value) {
              formattedFilter[
                'vencimento_start'
              ] = selectedFilter.initial_value.toLocaleDateString();
              formattedFilter[
                'vencimento_end'
              ] = selectedFilter.final_value.toLocaleDateString();
            }
          } else {
            if (selectedFilter.value) {
              formattedFilter[selectedFilter.databaseName] =
                selectedFilter.value;
            }
          }

          setSelectedFilters(formattedFilter);
        }

        setShowFilters(false);
        setSelectedOption(null);
      };

      const handleRemoveFilter = (key) => {
        const updatedFilters = { ...selectedFilters };

        if (updatedFilters[key]) {
          delete updatedFilters[key];

          setSelectedFilters(updatedFilters);
        }
      };

      async function getFilteredData(object) {
        try {
          const sessionData = await getSession();

          const response = await ioseAPI.getCalculatedTotalFilteredConsumption(
            sessionData.headers,
            object
          );

          const { data } = response.data;

          setFilteredConsumption(data);
        } catch (error) {
          console.log(error);
        }
      }

      async function getFilteredBillValue(object) {
        try {
          const sessionData = await getSession();

          const response = await ioseAPI.getCalculateFilteredTotalBillValues(
            sessionData.headers,
            object
          );

          const { data } = response.data;

          setfilteredBillValue(data);
        } catch (error) {
          console.log(error);
        }
      }

      async function getFilteredRMData(object) {
        try {
          const sessionData = await getSession();

          const response = await ioseAPI.getCalculatedRegionalFilteredConsumption(
            sessionData.headers,
            object
          );

          const { data } = response.data;

          setFilteredRegionalConsumption(data);
        } catch (error) {
          console.log(error);
        }
      }

      async function getFilteredGMData(object) {
        try {
          const sessionData = await getSession();

          const response = await ioseAPI.getCalculatedGeneralFilteredConsumption(
            sessionData.headers,
            object
          );

          const { data } = response.data;

          setFilteredGeneralConsumption(data);
        } catch (error) {
          console.log(error);
        }
      }

      async function getFilteredExtract(object) {
        try {
          const sessionData = await getSession();

          const response = await ioseAPI.getCalculateFilteredExtract(
            sessionData.headers,
            object
          );

          const { data } = response.data;

          setFilteredExtract(data);
        } catch (error) {
          console.log(error);
        }
      }

      async function filterAllData(object) {
        setLoading(true);

        try {
          const promises = [
            getFilteredData(object),
            getFilteredRMData(object),
            getFilteredGMData(object),
            getFilteredBillValue(object),
            getFilteredExtract(object),
          ];

          await Promise.all(promises);

          setSelectedFilters({});
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }

      return (
        <S.FilterBox>
          {selectedFilter && (
            <>
              {selectedFilter.label === 'REF: MÊS/ANO' ? (
                <IoseSelectReferenceMonth
                  label={selectedFilter.label}
                  handleChange={selectedFilter.onChange}
                  selectedDate={selectedDate}
                />
              ) : selectedFilter.label === 'Leitura anterior' ? (
                <div style={{ display: 'flex', margin: '0 0 0 15px' }}>
                  <IoseSelectCompleteDate
                    label="Data inicial"
                    handleChange={selectedFilter.initial_onChange}
                    selectedDate={selectedFilter.initial_value}
                  />

                  <IoseSelectCompleteDate
                    label="Data final"
                    handleChange={selectedFilter.final_onChange}
                    selectedDate={selectedFilter.final_value}
                  />
                </div>
              ) : selectedFilter.label === 'Vencimento' ? (
                <div style={{ display: 'flex', margin: '0 0 0 15px' }}>
                  <IoseSelectCompleteDate
                    label="Data inicial"
                    handleChange={selectedFilter.initial_onChange}
                    selectedDate={selectedFilter.initial_value}
                  />

                  <IoseSelectCompleteDate
                    label="Data final"
                    handleChange={selectedFilter.final_onChange}
                    selectedDate={selectedFilter.final_value}
                  />
                </div>
              ) : (
                <IoseDynamicFilter
                  label={selectedFilter.label}
                  value={selectedFilter.value}
                  onChange={selectedFilter.onChange}
                  options={selectedFilter.options}
                />
              )}
              <IoseButton onClick={handleButtonClick}>
                ADICIONAR FILTRO
              </IoseButton>
            </>
          )}

          <div style={{ marginLeft: '25px' }}>
            {Object.entries(selectedFilters).length > 0 && (
              <S.FilterCardContainer>
                <h3>Filtros Selecionados:</h3>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {Object.entries(selectedFilters).map(([key, value]) => (
                    <S.FilterCardDiv key={key}>
                      <S.FilterCardValue>{value}</S.FilterCardValue>
                      <S.CloseButton onClick={() => handleRemoveFilter(key)}>
                        <S.CloseCardIcon />
                      </S.CloseButton>
                    </S.FilterCardDiv>
                  ))}
                </div>
                <IoseButton
                  left={'15px'}
                  onClick={() => filterAllData(selectedFilters)}
                >
                  FILTRAR
                </IoseButton>
              </S.FilterCardContainer>
            )}
          </div>
        </S.FilterBox>
      );
    };

    return (
      <S.FilterContainer>
        <IoseAddFilterButton
          onClick={toggleFilters}
          tooltip={'ADICIONAR FILTRO'}
        />

        {showFilters && (
          <S.FilterBox>
            {filterOptions.map((option, index) => (
              <S.OptionsButton
                key={index}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </S.OptionsButton>
            ))}
          </S.FilterBox>
        )}

        {renderSelectedComponent()}
      </S.FilterContainer>
    );
  };

  const renderCards = () => {
    return (
      <DashFiltersAndCards
        loading={loading}
        auditValues={auditValues}
        auditConsumptions={auditConsumptions}
        auditExtract={auditExtract}
        averages={averages}
      />
    );
  };

  const renderCharts = () => {
    return (
      <DashCharts
        loading={loading}
        auditValues={auditValues}
        valuesByIdentificationNumber={totalByIdentificationNumber}
        regionalData={regionalConsumption}
        generalConsumption={generalConsumption}
      />
    );
  };

  const renderTable = () => {
    return (
      <IoseEnergyBillsTable loading={loading} energyBillData={summaryBills} />
    );
  };

  return (
    <S.GridContainer>
      {renderSubHeaderAndFilters()}
      {hasReadPermission ? (
        <>
          {RenderFilterContainer()}
          {renderCards()}
          {renderCharts()}
          {renderTable()}
        </>
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão de leitura destes dados!'
          }
        />
      )}
    </S.GridContainer>
  );
}
