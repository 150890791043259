import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useDropzone } from 'react-dropzone';

//Import from MUI
import {
  Modal,
  MenuItem,
  Slide,
  Grow,
  Typography,
  InputLabel,
} from '@material-ui/core';

//Import from Formik
import { Formik, Form, Field } from 'formik';

//Import from Yup (Library to validation's form)
import * as Yup from 'yup';
import validationsForm from 'common/ValidationsForms';

import {
  AtividadeDaUc,
  GeralOptions,
  ModalidadeOptions,
  MonthOptions,
  OptionsBoolean2,
  PostoOptions,
  SimpleOptions,
  SubgrupoOptions,
  TarifaOptions,
  YearOptions,
} from '../../constants';

//Import from styles
import {
  ButtonContainer,
  CircularProgressStyled,
  ContractLink,
  DeleteContractButton,
  DivCircularProgress,
  DivInputs,
  FieldDiv,
  FormConsumerGroupContainer,
  FormContainer,
  FormTariffsContainer,
  IoseTextFieldSidebarStyled3,
  ModalTitle,
  ModalTitleHeader,
  ObjectIcon,
  StyledDragContainer,
  StyledField,
  StyledFormControl,
  StyledHeaderModal,
  StyledInputLabel,
  StyledLeftHeaderModal,
  StyledRightHeaderModal,
  StyledSelect,
  StyledTextInputs,
  StyledWrappedModal,
  StyledWrappedTariffsModal,
  TextFieldStyled,
  UploadButton,
  WrappedConsumerGroupItens,
  WrappedItens,
} from './style';

//Import from components
import { IoseModalButton } from 'components/Buttons/IoseButton';
import {
  IoseAlertErroModal,
  IoseAlertSuccessModal,
} from 'components/IoseAlert';
import IoseTextField from 'components/IoseTextField';

//Import from messages
import messages from 'common/Messages';

//Import from constants
import {
  ReadjustmentIndex,
  EnergyMarket,
  ReadjustmentRate,
  TermContract,
  DaysToExpire,
  PrCities,
  GeneralManagement,
  RegionalManagement,
  VariableTypes,
} from '../../constants';

import IoseSelectDayMouth from 'components/IoseSelectDayMouth';
import { ContractsIcon } from 'containers/ConctractContainer/style';
import { UploadLoading } from 'components/IoseLoader';

import { useSystemVariables } from 'hooks';

//Styles
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  blackText: {
    color: '#015A9E',
  },
  noMargin: {
    marginTop: '0px !important',
  },
}));

export function IoseAddConsumerGroupModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
  selectData,
  contractData,
}) {
  const [selectedConcessionaire, setSelectedConcessionaire] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);

  const [startValidity, setStartValidity] = useState(new Date());
  const [endValidity, setEndValidity] = useState(new Date());
  const [startTest, setStartTest] = useState(new Date());
  const [endTest, setEndTest] = useState(new Date());

  const handleConcessionaireChange = (event) => {
    const selectedConcessionaire = event.target.value;
    setSelectedConcessionaire(selectedConcessionaire);
  };

  const handleContractChange = (event) => {
    const selectedContract = event.target.value;
    setSelectedContract(selectedContract);
  };

  const classes = useStyles();

  const handleCreateConsumerGroup = (values) => {
    const {
      identification_number,
      coordinate_x,
      coordinate_y,
      address,
      consumer_group_load,
      operating_unity,
      general_management,
      regional_management,
      city,
      meter,
      atividade,
      cod_local,
      cod_unidade,
      descricao_unidade,
      cod_orgao_pagador,
      geradora,
      beneficiaria,
      potencia_transformador,
      subgrupo,
      modalidade,
      credito_carbono,
      cogeracao,
      telemetria,
      csbio,
      ativo,
      mercado_livre,
      constante,
      reset_relogio,
      demanda_fora,
      demanda_ponta,
      tensao,
    } = values;

    const ConsumerGroupLoad = parseFloat(consumer_group_load.replace(',', '.'));
    const potenciaFormatted = parseFloat(
      potencia_transformador.replace(',', '.')
    );
    const constanteFormatted = parseFloat(constante.replace(',', '.'));
    const demandaForaFormatted = parseFloat(demanda_fora.replace(',', '.'));
    const demandaPontaFormatted = parseFloat(demanda_ponta.replace(',', '.'));

    submit(
      selectedConcessionaire,
      identification_number,
      selectedContract,
      operating_unity,
      general_management,
      regional_management,
      coordinate_x,
      coordinate_y,
      address,
      city,
      credito_carbono,
      telemetria,
      meter,
      ConsumerGroupLoad,
      atividade,
      cod_local,
      cod_unidade,
      descricao_unidade,
      cod_orgao_pagador,
      cogeracao,
      csbio,
      geradora,
      beneficiaria,
      potenciaFormatted,
      subgrupo,
      modalidade,
      ativo,
      mercado_livre,
      startValidity,
      endValidity,
      startTest,
      endTest,
      constanteFormatted,
      reset_relogio,
      demandaForaFormatted,
      demandaPontaFormatted,
      tensao
    );
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    identification_number: validationsForm.identificationNumber,
    address: validationsForm.address,
    consumer_group_load: validationsForm.value,
    potencia_transformador: validationsForm.value,
    constante: validationsForm.value,
    reset_relogio: validationsForm.number,
    demanda_fora: validationsForm.value,
    demanda_ponta: validationsForm.value,
    tensao: validationsForm.value,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  concessionaire: '',
                  identification_number: '',
                  coordinate_x: '',
                  coordinate_y: '',
                  address: '',
                  consumer_group_load: '',
                  operating_unity: '',
                  atividade: '',
                  cod_local: '',
                  cod_unidade: '',
                  descricao_unidade: '',
                  cod_orgao_pagador: '',
                  geradora: '',
                  beneficiaria: '',
                  potencia_transformador: '',
                  subgrupo: '',
                  modalidade: '',
                  credito_carbono: '',
                  cogeracao: '',
                  telemetria: '',
                  csbio: '',
                  ativo: '',
                  mercado_livre: '',
                  constante: '',
                  reset_relogio: '',
                  demanda_fora: '',
                  demanda_ponta: '',
                  tensao: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateConsumerGroup}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormConsumerGroupContainer>
                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="concessionaire"
                              className={classes.whiteText}
                            >
                              Concessionária
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="state"
                              labelId="state-label"
                              id="state-select"
                              onChange={handleConcessionaireChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {selectData?.map((concessionaire) => (
                                <MenuItem
                                  key={concessionaire.uuid_concessionaire}
                                  value={concessionaire.name}
                                >
                                  {concessionaire.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'identification_number'}
                            name={'identification_number'}
                            type={'text'}
                            label={'U.C'}
                            defaultvalue={
                              initialValues['identification_number']
                            }
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['identification_number'] &&
                              touched['identification_number']
                                ? errors['identification_number']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="city-label"
                              className={classes.whiteText}
                            >
                              Gerência Geral
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="general_management"
                              labelId="general_management-label"
                              id="general_management-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {GeneralManagement?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="city-label"
                              className={classes.whiteText}
                            >
                              Gerência Regional
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="regional_management"
                              labelId="regional_management-label"
                              id="regional_management-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {RegionalManagement?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'cod_orgao_pagador'}
                            name={'cod_orgao_pagador'}
                            type={'text'}
                            label={'Cod. Org. Pagador'}
                            defaultvalue={initialValues['cod_orgao_pagador']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_orgao_pagador'] &&
                              touched['cod_orgao_pagador']
                                ? errors['cod_orgao_pagador']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="city-label"
                              className={classes.whiteText}
                            >
                              Cidade
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="city"
                              labelId="city-label"
                              id="city-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {PrCities?.map((city) => (
                                <MenuItem
                                  key={city.id_cidade}
                                  value={city.nomeCidade}
                                >
                                  {city.nomeCidade}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'address'}
                            name={'address'}
                            type={'text'}
                            label={'Endereço'}
                            defaultvalue={initialValues['address']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['address'] && touched['address']
                                ? errors['address']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="contract_number"
                              className={classes.whiteText}
                            >
                              Contrato
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="contract_number"
                              labelId="contract_number-label"
                              id="contract_number-select"
                              onChange={handleContractChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {contractData?.map((contract) => (
                                <MenuItem
                                  key={contract.uuid_contract}
                                  value={contract.contract_number}
                                >
                                  {contract.contract_number}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'operating_unity'}
                            name={'operating_unity'}
                            type={'text'}
                            label={'Unidade Operacional'}
                            defaultvalue={initialValues['operating_unity']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['operating_unity'] &&
                              touched['operating_unity']
                                ? errors['operating_unity']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'consumer_group_load'}
                            name={'consumer_group_load'}
                            label={'Carga da U.C. (kWh)'}
                            defaultvalue={initialValues['consumer_group_load']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['consumer_group_load'] &&
                              touched['consumer_group_load']
                                ? errors['consumer_group_load']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="atividade-label"
                              className={classes.whiteText}
                            >
                              Atividade da U.C.
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="atividade"
                              labelId="atividade-label"
                              id="atividade-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {AtividadeDaUc?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'coordinate_x'}
                            name={'coordinate_x'}
                            type={'text'}
                            label={'Coordenada X'}
                            defaultvalue={initialValues['coordinate_x']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['coordinate_x'] && touched['coordinate_x']
                                ? errors['coordinate_x']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'coordinate_y'}
                            name={'coordinate_y'}
                            type={'text'}
                            label={'Coordenada Y'}
                            defaultvalue={initialValues['coordinate_y']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['coordinate_y'] && touched['coordinate_y']
                                ? errors['coordinate_y']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'descricao_unidade'}
                            name={'descricao_unidade'}
                            type={'text'}
                            label={'Descrição da unidade'}
                            defaultvalue={initialValues['descricao_unidade']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['descricao_unidade'] &&
                              touched['descricao_unidade']
                                ? errors['descricao_unidade']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'meter'}
                            name={'meter'}
                            label={'Medidor'}
                            defaultvalue={initialValues['meter']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['meter'] && touched['meter']
                                ? errors['meter']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'cod_local'}
                            name={'cod_local'}
                            type={'text'}
                            label={'Código do local'}
                            defaultvalue={initialValues['cod_local']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_local'] && touched['cod_local']
                                ? errors['cod_local']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'cod_unidade'}
                            name={'cod_unidade'}
                            type={'text'}
                            label={'Código da unidade'}
                            defaultvalue={initialValues['cod_unidade']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_unidade'] && touched['cod_unidade']
                                ? errors['cod_unidade']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="geradora-label"
                              className={classes.whiteText}
                            >
                              É Geradora?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="geradora"
                              labelId="geradora-label"
                              id="geradora-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {OptionsBoolean2?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="beneficiaria-label"
                              className={classes.whiteText}
                            >
                              É Beneficiaria?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="beneficiaria"
                              labelId="beneficiaria-label"
                              id="beneficiaria-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {OptionsBoolean2?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'potencia_transformador'}
                            name={'potencia_transformador'}
                            type={'text'}
                            label={'Pot. Transformador'}
                            defaultvalue={
                              initialValues['potencia_transformador']
                            }
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['potencia_transformador'] &&
                              touched['potencia_transformador']
                                ? errors['potencia_transformador']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="subgrupo-label"
                              className={classes.whiteText}
                            >
                              Subgrupo
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="subgrupo"
                              labelId="subgrupo-label"
                              id="subgrupo-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SubgrupoOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="modalidade-label"
                              className={classes.whiteText}
                            >
                              Modalidade
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="modalidade"
                              labelId="modalidade-label"
                              id="modalidade-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ModalidadeOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="credito_carbono-label"
                              className={classes.whiteText}
                            >
                              Credito de Carbono
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="credito_carbono"
                              labelId="credito_carbono-label"
                              id="credito_carbono-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {GeralOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="cogeracao-label"
                              className={classes.whiteText}
                            >
                              Cogeração
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="cogeracao"
                              labelId="cogeracao-label"
                              id="cogeracao-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {GeralOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs style={{ marginTop: '8px' }}>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="telemetria-label"
                              className={classes.whiteText}
                            >
                              Telemetria
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="telemetria"
                              labelId="telemetria-label"
                              id="telemetria-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {GeralOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="csbio-label"
                              className={classes.whiteText}
                            >
                              CSBIO
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="csbio"
                              labelId="csbio-label"
                              id="csbio-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {GeralOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="ativo-label"
                              className={classes.whiteText}
                            >
                              Está Ativo?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="ativo"
                              labelId="ativo-label"
                              id="ativo-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="mercado_livre-label"
                              className={classes.whiteText}
                            >
                              É Mercado Livre?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="mercado_livre"
                              labelId="mercado_livre-label"
                              id="mercado_livre-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'constante'}
                            name={'constante'}
                            type={'text'}
                            label={'Constante'}
                            defaultvalue={initialValues['constante']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['constante'] && touched['constante']
                                ? errors['constante']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'reset_relogio'}
                            name={'reset_relogio'}
                            type={'number'}
                            label={'Reset Relógio'}
                            defaultvalue={initialValues['reset_relogio']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['reset_relogio'] &&
                              touched['reset_relogio']
                                ? errors['reset_relogio']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'demanda_fora'}
                            name={'demanda_fora'}
                            type={'text'}
                            label={'Demanda FP'}
                            defaultvalue={initialValues['demanda_fora']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['demanda_fora'] && touched['demanda_fora']
                                ? errors['demanda_fora']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'demanda_ponta'}
                            name={'demanda_ponta'}
                            type={'text'}
                            label={'Demanda P'}
                            defaultvalue={initialValues['demanda_ponta']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['demanda_ponta'] &&
                              touched['demanda_ponta']
                                ? errors['demanda_ponta']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'tensao'}
                            name={'tensao'}
                            type={'number'}
                            label={'Tensão'}
                            defaultvalue={initialValues['tensao']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['tensao'] && touched['tensao']
                                ? errors['tensao']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>
                    </FormConsumerGroupContainer>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '20px',
                        width: '100%',
                        borderTop: '2px solid #4a5459',
                        padding: '10px 0 10px 0',
                      }}
                    >
                      <div>
                        <IoseSelectDayMouth
                          title="Início da vigência"
                          getDate={setStartValidity}
                          initialDate={startValidity}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim da vigência"
                          getDate={setEndValidity}
                          initialDate={endValidity}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Início do Teste"
                          getDate={setStartTest}
                          initialDate={startTest}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim do Teste"
                          getDate={setEndTest}
                          initialDate={endTest}
                          margin={'0px'}
                        />
                      </div>
                    </div>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar Unidade Consumidora
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseEditConsumerGroupModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  editNotificationType,
  showNotification,
  editNotification,
  selectedCard,
  loading,
  selectData,
  contractData,
}) {
  const [selectedConcessionaire, setSelectedConcessionaire] = useState('');
  const [selectedContract, setSelectedContract] = useState('');

  const [startValidity, setStartValidity] = useState(null);
  const [endValidity, setEndValidity] = useState(null);
  const [startTest, setStartTest] = useState(null);
  const [endTest, setEndTest] = useState(null);

  useEffect(() => {
    setSelectedContract(selectedCard?.contract);
    setSelectedConcessionaire(selectedCard?.concessionaire);
    setStartValidity(selectedCard?.inicio_vigencia);
    setEndValidity(selectedCard?.fim_vigencia);
    setStartTest(selectedCard?.inicio_teste);
    setEndTest(selectedCard?.fim_teste);
  }, [selectedCard]);

  const handleConcessionaireChange = (event) => {
    const selectedConcessionaire = event.target.value;
    setSelectedConcessionaire(selectedConcessionaire);
  };

  const handleContractChange = (event) => {
    const selectedContract = event.target.value;
    setSelectedContract(selectedContract);
  };

  const classes = useStyles();

  const handleEditConsumerGroup = (values) => {
    const {
      identification_number,
      coordinate_x,
      coordinate_y,
      address,
      consumer_group_load,
      operating_unity,
      general_management,
      regional_management,
      city,
      meter,
      atividade,
      cod_local,
      cod_unidade,
      descricao_unidade,
      cod_orgao_pagador,
      geradora,
      beneficiaria,
      potencia_transformador,
      subgrupo,
      modalidade,
      credito_carbono,
      cogeracao,
      telemetria,
      csbio,
      ativo,
      mercado_livre,
      constante,
      relogio_reset,
      demanda_fora,
      demanda_ponta,
      tensao,
    } = values;

    const ConsumerGroupLoad = parseFloat(consumer_group_load.replace(',', '.'));
    const potenciaFormatted = parseFloat(
      potencia_transformador.replace(',', '.')
    );
    const constanteFormatted = parseFloat(constante.replace(',', '.'));
    const demandaForaFormatted = parseFloat(demanda_fora.replace(',', '.'));
    const demandaPontaFormatted = parseFloat(demanda_ponta.replace(',', '.'));

    submit(
      selectedCard.uuid_consumer_group,
      selectedConcessionaire,
      identification_number,
      selectedContract,
      operating_unity,
      general_management,
      regional_management,
      coordinate_x,
      coordinate_y,
      address,
      city,
      credito_carbono,
      telemetria,
      meter,
      ConsumerGroupLoad,
      atividade,
      cod_local,
      cod_unidade,
      descricao_unidade,
      cod_orgao_pagador,
      cogeracao,
      csbio,
      geradora,
      beneficiaria,
      potenciaFormatted,
      subgrupo,
      modalidade,
      ativo,
      mercado_livre,
      startValidity,
      endValidity,
      startTest,
      endTest,
      constanteFormatted,
      relogio_reset,
      demandaForaFormatted,
      demandaPontaFormatted,
      tensao
    );
  };

  const formattedConsumerGroupLoad = selectedCard?.consumer_group_load
    ?.toString()
    .replace('.', ',');

  const formattedPotencia = selectedCard?.potencia_transformador
    ?.toString()
    .replace('.', ',');

  const formattedConstante = selectedCard?.constante
    ?.toString()
    .replace('.', ',');

  const formattedDemandaFora = selectedCard?.demanda_fora
    ?.toString()
    .replace('.', ',');

  const formattedDemandaPonta = selectedCard?.demanda_ponta
    ?.toString()
    .replace('.', ',');

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    identification_number: validationsForm.identificationNumber,
    address: validationsForm.address,
    consumer_group_load: validationsForm.value,
    meter: validationsForm.required,
    constante: validationsForm.value,
    relogio_reset: validationsForm.number,
    demanda_fora: validationsForm.value,
    demanda_ponta: validationsForm.value,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  concessionaire: selectedCard?.concessionaire,
                  identification_number: selectedCard?.identification_number,
                  contract: selectedCard?.contract,
                  operating_unity: selectedCard?.operating_unity,
                  general_management: selectedCard?.general_management,
                  regional_management: selectedCard?.regional_management,
                  coordinate_x: selectedCard?.coordinate_x,
                  coordinate_y: selectedCard?.coordinate_y,
                  address: selectedCard?.address,
                  city: selectedCard?.city,
                  credito_carbono: selectedCard?.carbon_credit,
                  telemetria: selectedCard?.telemetry,
                  meter: selectedCard?.meter,
                  consumer_group_load: formattedConsumerGroupLoad,
                  atividade: selectedCard?.atividade,
                  cod_local: selectedCard?.cod_local,
                  cod_unidade: selectedCard?.cod_unidade,
                  descricao_unidade: selectedCard?.descricao_unidade,
                  cod_orgao_pagador: selectedCard?.cod_orgao_pagador,
                  cogeracao: selectedCard?.cogeracao,
                  csbio: selectedCard?.csbio,
                  geradora: selectedCard?.geradora,
                  beneficiaria: selectedCard?.beneficiaria,
                  potencia_transformador: formattedPotencia,
                  subgrupo: selectedCard?.subgrupo,
                  modalidade: selectedCard?.modalidade,
                  ativo: selectedCard?.ativo,
                  mercado_livre: selectedCard?.mercado_livre,
                  constante: formattedConstante,
                  relogio_reset: selectedCard?.relogio_reset,
                  demanda_fora: formattedDemandaFora,
                  demanda_ponta: formattedDemandaPonta,
                  tensao: selectedCard?.tensao,
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleEditConsumerGroup}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                  setFieldValue,
                  values,
                }) => (
                  <Form>
                    <FormConsumerGroupContainer>
                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="concessionaire"
                              className={classes.whiteText}
                            >
                              Concessionária
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="concessionaire"
                              labelId="concessionaire-label"
                              id="concessionaire-select"
                              onChange={handleConcessionaireChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {selectData?.map((concessionaire) => (
                                <MenuItem
                                  key={concessionaire.uuid_concessionaire}
                                  value={concessionaire.name}
                                >
                                  {concessionaire.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'identification_number'}
                            name={'identification_number'}
                            type={'text'}
                            label={'U.C'}
                            defaultvalue={
                              initialValues['identification_number']
                            }
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['identification_number'] &&
                              touched['identification_number']
                                ? errors['identification_number']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="city-label"
                              className={classes.whiteText}
                            >
                              Gerência Geral
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="general_management"
                              labelId="general_management-label"
                              id="general_management-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {GeneralManagement?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="city-label"
                              className={classes.whiteText}
                            >
                              Gerência Regional
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="regional_management"
                              labelId="regional_management-label"
                              id="regional_management-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {RegionalManagement?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'cod_orgao_pagador'}
                            name={'cod_orgao_pagador'}
                            type={'text'}
                            label={'Cod. Org. Pagador'}
                            defaultvalue={initialValues['cod_orgao_pagador']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_orgao_pagador'] &&
                              touched['cod_orgao_pagador']
                                ? errors['cod_orgao_pagador']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="city-label"
                              className={classes.whiteText}
                            >
                              Cidade
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="city"
                              labelId="city-label"
                              id="city-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {PrCities?.map((city) => (
                                <MenuItem
                                  key={city.id_cidade}
                                  value={city.nomeCidade}
                                >
                                  {city.nomeCidade}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'address'}
                            name={'address'}
                            type={'text'}
                            label={'Endereço'}
                            defaultvalue={initialValues['address']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['address'] && touched['address']
                                ? errors['address']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="contract"
                              className={classes.whiteText}
                            >
                              Contrato
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="contract"
                              labelId="contract-label"
                              id="contract-select"
                              onChange={handleContractChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {contractData?.map((contract) => (
                                <MenuItem
                                  key={contract.uuid_contract}
                                  value={contract.contract_number}
                                >
                                  {contract.contract_number}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'operating_unity'}
                            name={'operating_unity'}
                            type={'text'}
                            label={'Unidade Operacional'}
                            defaultvalue={initialValues['operating_unity']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['operating_unity'] &&
                              touched['operating_unity']
                                ? errors['operating_unity']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'consumer_group_load'}
                            name={'consumer_group_load'}
                            label={'Carga da U.C (kWh)'}
                            defaultvalue={initialValues['consumer_group_load']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['consumer_group_load'] &&
                              touched['consumer_group_load']
                                ? errors['consumer_group_load']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="atividade-label"
                              className={classes.whiteText}
                            >
                              Atividade da U.C.
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="atividade"
                              labelId="atividade-label"
                              id="atividade-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {AtividadeDaUc?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'coordinate_x'}
                            name={'coordinate_x'}
                            type={'text'}
                            label={'Coordenada X'}
                            defaultvalue={initialValues['coordinate_x']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['coordinate_x'] && touched['coordinate_x']
                                ? errors['coordinate_x']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'coordinate_y'}
                            name={'coordinate_y'}
                            type={'text'}
                            label={'Coordenada Y'}
                            defaultvalue={initialValues['coordinate_y']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['coordinate_y'] && touched['coordinate_y']
                                ? errors['coordinate_y']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'descricao_unidade'}
                            name={'descricao_unidade'}
                            label={'Descrição da unidade'}
                            defaultvalue={initialValues['descricao_unidade']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['descricao_unidade'] &&
                              touched['descricao_unidade']
                                ? errors['descricao_unidade']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'meter'}
                            name={'meter'}
                            label={'Medidor'}
                            defaultvalue={initialValues['meter']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['meter'] && touched['meter']
                                ? errors['meter']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'cod_local'}
                            name={'cod_local'}
                            label={'Código do local'}
                            defaultvalue={initialValues['cod_local']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_local'] && touched['cod_local']
                                ? errors['cod_local']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'cod_unidade'}
                            name={'cod_unidade'}
                            label={'Código da unidade'}
                            defaultvalue={initialValues['cod_unidade']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['cod_unidade'] && touched['cod_unidade']
                                ? errors['cod_unidade']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="geradora-label"
                              className={classes.whiteText}
                            >
                              É Geradora?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="geradora"
                              labelId="geradora-label"
                              id="geradora-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {OptionsBoolean2?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="beneficiaria-label"
                              className={classes.whiteText}
                            >
                              É Beneficiaria?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="beneficiaria"
                              labelId="beneficiaria-label"
                              id="beneficiaria-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {OptionsBoolean2?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'potencia_transformador'}
                            name={'potencia_transformador'}
                            label={'Pot. Transformador'}
                            defaultvalue={
                              initialValues['potencia_transformador']
                            }
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['potencia_transformador'] &&
                              touched['potencia_transformador']
                                ? errors['potencia_transformador']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="subgrupo-label"
                              className={classes.whiteText}
                            >
                              Subgrupo
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="subgrupo"
                              labelId="subgrupo-label"
                              id="subgrupo-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SubgrupoOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="modalidade-label"
                              className={classes.whiteText}
                            >
                              Modalidade
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="modalidade"
                              labelId="modalidade-label"
                              id="modalidade-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ModalidadeOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="credito_carbono-label"
                              className={classes.whiteText}
                            >
                              Crédito de Carbono
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="credito_carbono"
                              labelId="credito_carbono-label"
                              id="credito_carbono-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="cogeracao-label"
                              className={classes.whiteText}
                            >
                              Cogeração
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="cogeracao"
                              labelId="cogeracao-label"
                              id="cogeracao-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="telemetria-label"
                              className={classes.whiteText}
                            >
                              Telemetria
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="telemetria"
                              labelId="telemetria-label"
                              id="telemetria-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="csbio-label"
                              className={classes.whiteText}
                            >
                              CSBIO
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="csbio"
                              labelId="csbio-label"
                              id="csbio-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="ativo-label"
                              className={classes.whiteText}
                            >
                              Está Ativo?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="ativo"
                              labelId="ativo-label"
                              id="ativo-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel
                              id="mercado_livre-label"
                              className={classes.whiteText}
                            >
                              É Mercado Livre?
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="mercado_livre"
                              labelId="mercado_livre-label"
                              id="mercado_livre-select"
                              onChange={handleChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {SimpleOptions?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'constante'}
                            name={'constante'}
                            type={'text'}
                            label={'Constante'}
                            defaultvalue={initialValues['constante']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['constante'] && touched['constante']
                                ? errors['constante']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'relogio_reset'}
                            name={'relogio_reset'}
                            type={'number'}
                            label={'Reset Relógio'}
                            defaultvalue={initialValues['relogio_reset']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['relogio_reset'] &&
                              touched['relogio_reset']
                                ? errors['relogio_reset']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>

                      <WrappedConsumerGroupItens>
                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'demanda_fora'}
                            name={'demanda_fora'}
                            type={'text'}
                            label={'Demanda FP'}
                            defaultvalue={initialValues['demanda_fora']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['demanda_fora'] && touched['demanda_fora']
                                ? errors['demanda_fora']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'demanda_ponta'}
                            name={'demanda_ponta'}
                            type={'text'}
                            label={'Demanda P'}
                            defaultvalue={initialValues['demanda_ponta']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['demanda_ponta'] &&
                              touched['demanda_ponta']
                                ? errors['demanda_ponta']
                                : ''
                            }
                          />
                        </StyledTextInputs>

                        <StyledTextInputs>
                          <Field
                            component={IoseTextFieldSidebarStyled3}
                            fullWidth
                            id={'tensao'}
                            name={'tensao'}
                            type={'number'}
                            label={'Tensão'}
                            defaultvalue={initialValues['tensao']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['tensao'] && touched['tensao']
                                ? errors['tensao']
                                : ''
                            }
                          />
                        </StyledTextInputs>
                      </WrappedConsumerGroupItens>
                    </FormConsumerGroupContainer>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '20px',
                        width: '100%',
                        borderTop: '2px solid #4a5459',
                        padding: '10px 0 10px 0',
                      }}
                    >
                      <div>
                        <IoseSelectDayMouth
                          title="Início da vigência"
                          getDate={setStartValidity}
                          initialDate={startValidity}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim da vigência"
                          getDate={setEndValidity}
                          initialDate={endValidity}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Início do Teste"
                          getDate={setStartTest}
                          initialDate={startTest}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim do Teste"
                          getDate={setEndTest}
                          initialDate={endTest}
                          margin={'0px'}
                        />
                      </div>
                    </div>
                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {editNotificationType === 1 && (
                          <IoseAlertSuccessModal text={editNotification} />
                        )}

                        {editNotificationType === 2 && (
                          <IoseAlertErroModal text={editNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Editar Unidade Consumidora
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export default function IoseConsumerGroupDeleteModal({
  open,
  onClose,
  loading,
  notificationType,
  deleteNotification,
  showNotification,
  submit,
  selectedCard,
  modalHeaderTitle,
  modalTitle,
}) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState('');
  const [showError, setShowError] = useState(false);

  const handleConfirmation = () => {
    if (confirmation === selectedCard?.identification_number) {
      submit(selectedCard.uuid_consumer_group);
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (showError) {
      const timeout = setTimeout(() => {
        setShowError(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [showError]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom marginBottom="35px">
                {modalTitle}
              </ModalTitle>

              <Typography gutterBottom>
                Tem certeza que deseja deletar a unidade consumidora{' '}
                <b>{selectedCard?.identification_number}</b>?
              </Typography>
              <Typography gutterBottom>
                Para isso, digite <b>{selectedCard?.identification_number}</b>{' '}
                logo abaixo e aperte o botão Deletar em seguida.
              </Typography>

              <IoseTextField
                value={confirmation}
                onChange={(event) => setConfirmation(event.target.value)}
              />

              {loading ? (
                <DivCircularProgress>
                  <CircularProgressStyled />
                </DivCircularProgress>
              ) : showNotification ? (
                <>
                  {notificationType === 1 && (
                    <IoseAlertSuccessModal text={deleteNotification} />
                  )}
                  {notificationType === 2 && (
                    <IoseAlertErroModal text={deleteNotification} />
                  )}
                  {showError && (
                    <IoseAlertErroModal
                      text={messages.confirmationTextIncorrect}
                    />
                  )}
                </>
              ) : showError ? (
                <IoseAlertErroModal text="Texto de confirmação incorreto." />
              ) : (
                <ButtonContainer>
                  <IoseModalButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleConfirmation}
                  >
                    Deletar
                  </IoseModalButton>
                </ButtonContainer>
              )}
            </div>
          </StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}

export function IoseAddContractsModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
  concessionaires,
  uploadFile,
}) {
  //Select States
  const [marketType, setMarketType] = useState(null);
  const [concessionaire, setConcessionaire] = useState(null);
  const [termOfContract, setTermOfContract] = useState(null);
  const [readjustmentIndex, setReadjustmentIndex] = useState('');
  const [readjustmentRate, setReadjustmentRate] = useState('');
  const [daysExpire, setDaysExpire] = useState('');
  const [s3Link, setS3Link] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  //State to indicate fist day tariff period
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());

  function handleMarketTypeChange(event) {
    const marketType = event.target;
    setMarketType(marketType);
  }

  function handleConcessionaireChange(event) {
    const concessionaire = event.target;
    setConcessionaire(concessionaire);
  }

  function handletermOfContractChange(event) {
    const termOfContract = event.target;
    setTermOfContract(termOfContract);
  }

  function handlereadjustmentIndexChange(event) {
    const ReadjustmentIndex = event.target;
    setReadjustmentIndex(ReadjustmentIndex);
  }

  function handlereadjustmentRateChange(event) {
    const ReadjustmentRate = event.target;
    setReadjustmentRate(ReadjustmentRate);
  }

  function handleDaysExpireChange(event) {
    const daysExpire = event.target;
    setDaysExpire(daysExpire);
  }

  const labelsTextsContracts = [
    { label: 'Número do Contrato', variable: 'contractNumber' },
    { label: 'Valor total do contrato', variable: 'totalContracts' },
    { label: 'Valor mensal', variable: 'monthlyValue' },
    { label: 'Observação', variable: 'note' },
  ];

  const HandleFileUpload = () => {
    //eslint-disable-next-line
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileLink, setFileLink] = useState(null);
    // eslint-disable-next-line
    const [fileCount, setFileCount] = useState(0);
    const [uploadLoading, setUploadLoading] = useState(false);

    const handleUpload = async (file) => {
      setUploadLoading(true);
      try {
        const response = await uploadFile(file);

        setFileLink(response.fileUrl);
        setS3Link(response.fileUrl);
        setIsFileUploaded(true);
        setUploadLoading(false);
      } catch (err) {
        console.error(err);
        setUploadLoading(false);
      }
    };

    const onDrop = (acceptedFiles) => {
      if (acceptedFiles.length === 1) {
        setSelectedFile(acceptedFiles[0]);
        handleUpload(acceptedFiles[0]);
      } else {
        setSelectedFile(null);
        console.error('Apenas 1 arquivo pode ser selecionado por contrato.');
      }
      setFileCount(acceptedFiles.length);
    };

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragReject,
    } = useDropzone({
      onDrop,
      accept: 'application/pdf, image/png, image/jpeg',
    });

    return (
      <StyledDragContainer style={{ marginTop: '30px' }}>
        {uploadLoading ? (
          <DivCircularProgress>
            <UploadLoading loading={uploadLoading} color="#015A9E" />
          </DivCircularProgress>
        ) : fileLink ? (
          <div style={{ display: 'flex' }}>
            <p style={{ fontSize: '13px' }}>Upload realizado com sucesso!</p>
          </div>
        ) : (
          <div
            {...getRootProps()}
            style={{
              border: isDragActive ? '2px dashed green' : '2px dashed gray',
              borderRadius: '10px',
              padding: '20px',
              textAlign: 'center',
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Solte o arquivo aqui...</p>
            ) : isDragReject ? (
              <p>Arquivo não suportado</p>
            ) : (
              <p>
                Arraste e solte o arquivo do contrato ou clique para selecionar{' '}
                <br />
                (Apenas 1 arquivo por contrato)
              </p>
            )}
          </div>
        )}
      </StyledDragContainer>
    );
  };

  const classes = useStyles();

  const handleCreateContracts = async (values) => {
    if (isFileUploaded) {
      const monthlyValue = parseFloat(values.monthlyValue.replace(',', '.'));
      const totalContracts = parseFloat(
        values.totalContracts.replace(',', '.')
      );

      // const formatDate = (dateString) => {
      //   const date = new Date(dateString);
      //   const day = date.getUTCDate();
      //   const month = date.getUTCMonth() + 1;
      //   const year = date.getUTCFullYear();

      //   const formattedDay = day < 10 ? `0${day}` : day;
      //   const formattedMonth = month < 10 ? `0${month}` : month;

      //   return `${formattedDay}/${formattedMonth}/${year}`;
      // };

      // const formattedInitialDate = formatDate(initialDate);
      // const formattedFinalDate = formatDate(finalDate);

      const data = {
        contractNumber: values.contractNumber,
        marketType: marketType.value,
        concessionaire: concessionaire.value,
        termOfContract: termOfContract.value,
        readjustmentIndex: readjustmentIndex.value,
        readjustmentRate: readjustmentRate.value,
        totalContracts: totalContracts,
        monthlyValue: monthlyValue,
        note: values.note,
        daysExpire: daysExpire.value,
      };

      submit(
        data.contractNumber,
        data.marketType,
        data.concessionaire,
        data.termOfContract,
        data.readjustmentIndex,
        data.readjustmentRate,
        data.totalContracts,
        data.monthlyValue,
        data.note,
        data.daysExpire,
        initialDate,
        finalDate,
        s3Link
      );
    }
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    contractNumber: validationsForm.number,
    totalContracts: validationsForm.value,
    monthlyValue: validationsForm.value,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ContractsIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  contractNumber: '',
                  totalContracts: '',
                  monthlyValue: '',
                  note: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateContracts}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormContainer>
                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="type_market">
                              Tipo de Mercado
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="type_market"
                              labelId="type_market-label"
                              id="type_market-select"
                              onChange={handleMarketTypeChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {EnergyMarket?.map((type) => (
                                <MenuItem key={type.name} value={type.value}>
                                  {type.value}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="concessionaire">
                              Concessionária
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="concessionaire"
                              labelId="concessionaire-label"
                              id="concessionaire-select"
                              onChange={handleConcessionaireChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {concessionaires?.map((concessionaire) => (
                                <MenuItem
                                  key={concessionaire.name}
                                  value={concessionaire.name}
                                >
                                  {concessionaire.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="term_contract">
                              Vigência do contrato
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="term_contract"
                              labelId="term_contract-label"
                              id="term_contract-select"
                              onChange={handletermOfContractChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {TermContract?.map((term) => (
                                <MenuItem key={term.name} value={term.value}>
                                  {term.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="term_contract">
                              Índice de reajuste
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="readjustment_index"
                              labelId="readjustment_index-label"
                              id="readjustment_index-select"
                              onChange={handlereadjustmentIndexChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ReadjustmentIndex?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="readjustment_rate">
                              Taxa de reajuste
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="readjustment_rate"
                              labelId="readjustment_rate-label"
                              id="readjustment_rate-select"
                              onChange={handlereadjustmentRateChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ReadjustmentRate?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedItens>

                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="days_expire">
                              Aviso de vencimento
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="days_expire"
                              labelId="days_expire-label"
                              id="days_expire-select"
                              onChange={handleDaysExpireChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {DaysToExpire?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <FieldDiv mt="0" mb="0">
                          {labelsTextsContracts?.map((value) => {
                            return (
                              <DivInputs>
                                <Field
                                  component={TextFieldStyled}
                                  key={value.variable}
                                  id={value.variable}
                                  name={value.variable}
                                  type={'text'}
                                  label={value.label}
                                  defaultvalue={initialValues[value.variable]}
                                  onchange={handleChange}
                                  onblur={handleBlur}
                                  helpertext={
                                    errors[value.variable] &&
                                    touched[value.variable]
                                      ? errors[value.variable]
                                      : ''
                                  }
                                />
                              </DivInputs>
                            );
                          })}
                        </FieldDiv>
                      </WrappedItens>
                    </FormContainer>

                    <div
                      style={{
                        display: 'flex',
                        marginTop: '20px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <IoseSelectDayMouth
                          title="Início do contrato"
                          getDate={setInitialDate}
                          initialDate={initialDate}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim do contrato"
                          getDate={setFinalDate}
                          initialDate={finalDate}
                          margin={'0px'}
                        />
                      </div>
                    </div>

                    {HandleFileUpload()}

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : isFileUploaded !== false ? (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting || !isFileUploaded}
                        >
                          Cadastrar Contrato
                        </IoseModalButton>
                      </ButtonContainer>
                    ) : (
                      <></>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseEditContractsModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  editNotification,
  loading,
  concessionaires,
  selectedCard,
  uploadFile,
  removeFile,
}) {
  //Select States
  const [uuidContract, setUuidContract] = useState('');
  const [marketType, setMarketType] = useState('');
  const [concessionaire, setConcessionaire] = useState('');
  const [termOfContract, setTermOfContract] = useState('');
  const [readjustmentIndex, setReadjustmentIndex] = useState('');
  const [readjustmentRate, setReadjustmentRate] = useState('');
  const [daysExpire, setDaysExpire] = useState('');
  const [s3Link, setS3Link] = useState('');
  //eslint-disable-next-line
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  //State to indicate fist day tariff period
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  useEffect(() => {
    setUuidContract(selectedCard?.uuid_contract);
    setInitialDate(selectedCard?.initial_date);
    setFinalDate(selectedCard?.final_date);
    setS3Link(selectedCard?.file_link);
    setConcessionaire(selectedCard?.concessionaire);
    setMarketType(selectedCard?.market_type);
    setTermOfContract(selectedCard?.term_contract);
    setReadjustmentIndex(selectedCard?.readjustment_index);
    setReadjustmentRate(selectedCard?.readjustment_rate);
    setDaysExpire(selectedCard?.days_expire);
  }, [selectedCard]);

  function handleMarketTypeChange(event) {
    const marketType = event.target.value;
    setMarketType(marketType);
  }

  function handleConcessionaireChange(event) {
    const concessionaire = event.target.value;
    setConcessionaire(concessionaire);
  }

  function handletermOfContractChange(event) {
    const termOfContract = event.target.value;
    setTermOfContract(termOfContract);
  }

  function handlereadjustmentIndexChange(event) {
    const ReadjustmentIndex = event.target.value;
    setReadjustmentIndex(ReadjustmentIndex);
  }

  function handlereadjustmentRateChange(event) {
    const ReadjustmentRate = event.target.value;
    setReadjustmentRate(ReadjustmentRate);
  }

  function handleDaysExpireChange(event) {
    const daysExpire = event.target.value;
    setDaysExpire(daysExpire);
  }

  const labelsTextsContracts = [
    { label: 'Número do Contrato', variable: 'contractNumber' },
    { label: 'Valor total do contrato', variable: 'totalContracts' },
    { label: 'Valor mensal', variable: 'monthlyValue' },
    { label: 'Observação', variable: 'note' },
  ];

  const HandleFileUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileLink, setFileLink] = useState(null);
    const [existingLink, setExistingLink] = useState(null);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);

    useEffect(() => {
      if (selectedCard && selectedCard.file_link) {
        setExistingLink(selectedCard.file_link);
      }
    }, []);

    const handleFileInput = (e) => {
      setSelectedFile(e.target.files[0]);
    };

    const handleUpload = async (file) => {
      setUploadLoading(true);
      try {
        const response = await uploadFile(file);

        setFileLink(response.fileUrl);
        setS3Link(response.fileUrl);
        setIsFileUploaded(true);
        setUploadLoading(false);
      } catch (err) {
        console.error(err);
        setUploadLoading(false);
      }
    };

    const getFileKeyFromLink = (fileLink) => {
      const parts = fileLink.split('/');
      return parts[parts.length - 1];
    };

    const removeExistingContract = async (file) => {
      const fileKey = getFileKeyFromLink(file);

      try {
        setLoadingRemove(true);
        await removeFile(fileKey);

        setExistingLink(null);
      } catch (err) {
      } finally {
        setLoadingRemove(false);
      }
    };

    return (
      <div
        style={{
          marginTop: '30px',
        }}
      >
        {loadingRemove || uploadLoading ? ( // Adicionando condicional de carregamento
          <DivCircularProgress>
            <UploadLoading
              loading={uploadLoading || loadingRemove}
              color="#015A9E"
            />
          </DivCircularProgress>
        ) : (
          <>
            {existingLink ? (
              <div>
                <p style={{ marginBottom: '15px' }}>Contrato atual:</p>

                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <ContractLink
                    href={existingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visualizar Contrato
                  </ContractLink>
                  <DeleteContractButton
                    onClick={() => removeExistingContract(existingLink)}
                  >
                    Remover Contrato
                  </DeleteContractButton>
                </div>
              </div>
            ) : (
              <div style={{ marginTop: '30px' }}>
                {fileLink ? (
                  <div>
                    <p>Upload realizado com sucesso!</p>
                  </div>
                ) : (
                  <>
                    <div style={{ marginBottom: '5px' }}>
                      Upload de contrato
                    </div>
                    <input type="file" onChange={handleFileInput} />

                    {selectedFile ? (
                      <>
                        <UploadButton
                          type="button"
                          onClick={() => handleUpload(selectedFile)}
                        >
                          Enviar
                        </UploadButton>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const classes = useStyles();

  const handleEditContracts = async (values) => {
    const monthlyValue = parseFloat(values.monthlyValue.replace(',', '.'));
    const totalContracts = parseFloat(values.totalContracts.replace(',', '.'));

    const data = {
      contractNumber: values.contractNumber,
      uuidContract: uuidContract,
      marketType: marketType,
      concessionaire: concessionaire,
      termOfContract: termOfContract,
      readjustmentIndex: readjustmentIndex,
      readjustmentRate: readjustmentRate,
      totalContracts: totalContracts,
      monthlyValue: monthlyValue,
      note: values.note,
      daysExpire: daysExpire,
    };

    submit(
      data.uuidContract,
      data.contractNumber,
      data.marketType,
      data.concessionaire,
      data.termOfContract,
      data.readjustmentIndex,
      data.readjustmentRate,
      data.totalContracts,
      data.monthlyValue,
      data.note,
      data.daysExpire,
      initialDate,
      finalDate,
      s3Link
    );
  };

  const formattedTotalContracts = selectedCard?.total_contracts
    .toString()
    .replace('.', ',');

  const formattedMonthlyValue = selectedCard?.monthly_value
    .toString()
    .replace('.', ',');

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ContractsIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  type_market: selectedCard?.market_type,
                  contractNumber: selectedCard?.contract_number,
                  concessionaire: selectedCard?.concessionaire,
                  term_contract: selectedCard?.term_contract,
                  readjustment_index: selectedCard?.readjustment_index,
                  readjustment_rate: selectedCard?.readjustment_rate,
                  totalContracts: formattedTotalContracts,
                  monthlyValue: formattedMonthlyValue,
                  note: selectedCard?.note,
                  days_expire: selectedCard?.days_expire,
                }}
                onSubmit={handleEditContracts}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormContainer>
                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <InputLabel id="type_market">
                              Tipo de Mercado
                            </InputLabel>
                            <Field
                              as={StyledSelect}
                              name="type_market"
                              labelId="type_market-label"
                              id="type_market-select"
                              onChange={(event) => {
                                handleMarketTypeChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {EnergyMarket?.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                  {type.value}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <InputLabel id="concessionaire">
                              Concessionária
                            </InputLabel>
                            <Field
                              as={StyledSelect}
                              name="concessionaire"
                              labelId="concessionaire-label"
                              id="concessionaire-select"
                              onChange={(event) => {
                                handleConcessionaireChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {concessionaires?.map((concessionaire) => (
                                <MenuItem
                                  key={concessionaire.name}
                                  value={concessionaire.name}
                                >
                                  {concessionaire.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <InputLabel id="term_contract">
                              Vigência do contrato
                            </InputLabel>
                            <Field
                              as={StyledSelect}
                              name="term_contract"
                              labelId="term_contract-label"
                              id="term_contract-select"
                              onChange={(event) => {
                                handletermOfContractChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {TermContract?.map((term) => (
                                <MenuItem key={term.name} value={term.value}>
                                  {term.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <InputLabel id="readjustment_index">
                              Índice de reajuste
                            </InputLabel>
                            <Field
                              as={StyledSelect}
                              name="readjustment_index"
                              labelId="readjustment_index-label"
                              id="readjustment_index-select"
                              onChange={(event) => {
                                handlereadjustmentIndexChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ReadjustmentIndex?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <InputLabel id="readjustment_rate">
                              Taxa de reajuste
                            </InputLabel>
                            <Field
                              as={StyledSelect}
                              name="readjustment_rate"
                              labelId="readjustment_rate-label"
                              id="readjustment_rate-select"
                              onChange={(event) => {
                                handlereadjustmentRateChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {ReadjustmentRate?.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>
                      </WrappedItens>

                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="days_expire">
                              Aviso de vencimento
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="days_expire"
                              labelId="days_expire-label"
                              id="days_expire-select"
                              onChange={(event) => {
                                handleDaysExpireChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {DaysToExpire?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <FieldDiv mt="0" mb="0">
                          {labelsTextsContracts?.map((value) => {
                            return (
                              <DivInputs>
                                <Field
                                  component={TextFieldStyled}
                                  key={value.variable}
                                  id={value.variable}
                                  name={value.variable}
                                  type={'text'}
                                  label={value.label}
                                  defaultvalue={initialValues[value.variable]}
                                  onchange={handleChange}
                                  onblur={handleBlur}
                                  helpertext={
                                    errors[value.variable] &&
                                    touched[value.variable]
                                      ? errors[value.variable]
                                      : ''
                                  }
                                />
                              </DivInputs>
                            );
                          })}
                        </FieldDiv>
                      </WrappedItens>
                    </FormContainer>

                    <div
                      style={{
                        display: 'flex',
                        marginTop: '20px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <IoseSelectDayMouth
                          title="Início do contrato"
                          getDate={setInitialDate}
                          initialDate={initialDate}
                          margin={'0px'}
                        />
                      </div>

                      <div>
                        <IoseSelectDayMouth
                          title="Fim do contrato"
                          getDate={setFinalDate}
                          initialDate={finalDate}
                          margin={'0px'}
                        />
                      </div>
                    </div>

                    {HandleFileUpload()}

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      // Renderizar notificação se showNotification for verdadeiro
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={editNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={editNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disableElevation={isSubmitting}
                        >
                          Editar Contrato
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseContractsDeleteModal({
  open,
  onClose,
  loading,
  notificationType,
  deleteNotification,
  showNotification,
  submit,
  selectedCard,
  modalTitle,
  modalHeaderTitle,
  removeFile,
}) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState('');
  const [showError, setShowError] = useState(false);
  const [existingLink, setExistingLink] = useState(null);
  const [uuidContract, setUuidContract] = useState(null);

  useEffect(() => {
    if (showError) {
      const timeout = setTimeout(() => {
        setShowError(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [showError]);

  useEffect(() => {
    setUuidContract(selectedCard?.uuid_contract);
    setExistingLink(selectedCard?.file_link);
  }, [selectedCard]);

  const getFileKeyFromLink = (fileLink) => {
    const parts = fileLink.split('/');
    return parts[parts.length - 1];
  };

  const removeExistingContract = async (file) => {
    const fileKey = getFileKeyFromLink(file);

    try {
      await removeFile(fileKey);

      setExistingLink(null);
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmation = () => {
    if (confirmation === 'deletar') {
      submit(uuidContract);
      removeExistingContract(existingLink);
    } else {
      setShowError(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ContractsIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom marginBottom="35px">
                {modalTitle}
              </ModalTitle>

              <Typography gutterBottom>
                Tem certeza que deseja deletar este contrato?
              </Typography>
              <Typography gutterBottom>
                Para isso, digite{' '}
                <b>
                  <i>deletar</i>
                </b>{' '}
                logo abaixo e aperte o botão Deletar em seguida.
              </Typography>

              <IoseTextField
                value={confirmation}
                onChange={(event) => setConfirmation(event.target.value)}
              />

              {loading ? (
                <DivCircularProgress>
                  <CircularProgressStyled />
                </DivCircularProgress>
              ) : showNotification ? (
                <>
                  {notificationType === 1 && (
                    <IoseAlertSuccessModal text={deleteNotification} />
                  )}
                  {notificationType === 2 && (
                    <IoseAlertErroModal text={deleteNotification} />
                  )}
                  {showError && (
                    <IoseAlertErroModal
                      text={messages.confirmationTextIncorrect}
                    />
                  )}
                </>
              ) : showError ? (
                <IoseAlertErroModal text="Texto de confirmação incorreto." />
              ) : (
                <ButtonContainer>
                  <IoseModalButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleConfirmation}
                  >
                    Deletar
                  </IoseModalButton>
                </ButtonContainer>
              )}
            </div>
          </StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}

export function IoseAddSystemVariables({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
  concessionaires,
}) {
  //Select States
  const [itemType, setItemType] = useState(null);
  const [constantType, setConstantType] = useState(null);
  const [filteredConstantType, setFilteredConstantTypes] = useState(null);
  const { systemVariables } = useSystemVariables();

  const classes = useStyles();

  function handleItemTypeChange(event) {
    const itemType = event.target.value;

    setItemType(itemType);

    const selectedItemType = systemVariables.find(
      (type) => type.item_type === itemType
    );

    console.log('selectedItemType', selectedItemType);

    setFilteredConstantTypes(selectedItemType?.item_constant);
  }

  function handleConstantChange(event) {
    const constantType = event.target.value;
    setConstantType(constantType);
  }

  const labelsTextsContracts = [
    { label: 'Descrição', variable: 'typeDescription' },
  ];

  const handleCreateSystemVariables = async (values) => {
    const data = {
      typeDescription: values.typeDescription,
      itemType: itemType,
      constantType: constantType,
    };

    submit(data.itemType, data.constantType, data.typeDescription);
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({});

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ContractsIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  contractNumber: '',
                  totalContracts: '',
                  monthlyValue: '',
                  note: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateSystemVariables}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormContainer>
                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="item_type">
                              Tipo
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="item_type"
                              labelId="item_type-label"
                              id="item_type-select"
                              onChange={handleItemTypeChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {systemVariables
                                // Filtrar valores únicos de item_type
                                .filter(
                                  (value, index, self) =>
                                    self.findIndex(
                                      (t) => t.item_type === value.item_type
                                    ) === index
                                )
                                // Mapear os valores únicos para os elementos MenuItem
                                .map((type) => (
                                  <MenuItem
                                    key={type.item_type}
                                    value={type.item_type}
                                  >
                                    {type.item_type}
                                  </MenuItem>
                                ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="constant_type">
                              Constantes
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="constant_type"
                              labelId="constant_type-label"
                              id="constant_type-select"
                              onChange={handleConstantChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {systemVariables?.map((item) => (
                                <MenuItem
                                  key={item.item_constant}
                                  value={item.item_constant}
                                >
                                  {item.item_constant}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <FieldDiv mt="0" mb="0">
                          {labelsTextsContracts?.map((value) => {
                            return (
                              <DivInputs>
                                <Field
                                  component={TextFieldStyled}
                                  key={value.variable}
                                  id={value.variable}
                                  name={value.variable}
                                  type={'text'}
                                  label={value.label}
                                  defaultvalue={initialValues[value.variable]}
                                  onchange={handleChange}
                                  onblur={handleBlur}
                                  helpertext={
                                    errors[value.variable] &&
                                    touched[value.variable]
                                      ? errors[value.variable]
                                      : ''
                                  }
                                />
                              </DivInputs>
                            );
                          })}
                        </FieldDiv>
                      </WrappedItens>
                    </FormContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar Variável
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseEditSystemVariables({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  editNotification,
  loading,
  initialValues,
}) {
  //Select States
  const [itemType, setItemType] = useState(null);
  const [constantType, setConstantType] = useState(null);
  const [filteredConstantType, setFilteredConstantTypes] = useState(null);

  const classes = useStyles();

  function handleItemTypeChange(event) {
    const itemType = event.target.value;
    setItemType(itemType);

    const selectedItemType = VariableTypes.find(
      (type) => type.value === itemType
    );

    setFilteredConstantTypes(selectedItemType?.constant_type);
  }

  function handleConstantChange(event) {
    const constantType = event.target.value;
    setConstantType(constantType);
  }

  const labelsTextsContracts = [
    { label: 'Descrição', variable: 'typeDescription' },
  ];

  const handleCreateSystemVariables = async (values) => {
    const data = {
      typeDescription: values.typeDescription,
      itemType: itemType,
      constantType: constantType,
    };

    submit(data.itemType, data.constantType, data.typeDescription);
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({});

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ContractsIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  item_type: initialValues?.item_type,
                  item_constant: initialValues?.item_constant,
                  item_description: initialValues?.item_description,
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateSystemVariables}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormContainer>
                      <WrappedItens>
                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="item_type">
                              Tipo
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="item_type"
                              labelId="item_type-label"
                              id="item_type-select"
                              onChange={(event) => {
                                handleItemTypeChange(event);
                                handleChange(event);
                              }}
                              className={classes.whiteText}
                              required={true}
                            >
                              {VariableTypes?.map((type) => (
                                <MenuItem key={type.name} value={type.value}>
                                  {type.value}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <DivInputs>
                          <StyledFormControl fullWidth>
                            <StyledInputLabel id="item_constant">
                              Constante
                            </StyledInputLabel>
                            <Field
                              as={StyledSelect}
                              name="item_constant"
                              labelId="item_constant-label"
                              id="item_constant-select"
                              onChange={handleConstantChange}
                              className={classes.whiteText}
                              required={true}
                            >
                              {filteredConstantType?.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                  {item.value}
                                </MenuItem>
                              ))}
                            </Field>
                          </StyledFormControl>
                        </DivInputs>

                        <FieldDiv mt="0" mb="0">
                          {labelsTextsContracts?.map((value) => {
                            return (
                              <DivInputs>
                                <Field
                                  component={TextFieldStyled}
                                  key={value.variable}
                                  id={value.variable}
                                  name={value.variable}
                                  type={'text'}
                                  label={value.label}
                                  defaultvalue={
                                    initialValues['item_description']
                                  }
                                  onchange={handleChange}
                                  onblur={handleBlur}
                                  helpertext={
                                    errors[value.variable] &&
                                    touched[value.variable]
                                      ? errors[value.variable]
                                      : ''
                                  }
                                />
                              </DivInputs>
                            );
                          })}
                        </FieldDiv>
                      </WrappedItens>
                    </FormContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={editNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={editNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Editar Variável
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseAddTariffsModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
}) {
  //State to indicate fist day tariff period
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());

  const classes = useStyles();

  const handleCreateTariff = (values) => {
    const { modalidade, subgrupo, posto, tarifa, valor, porcentagem } = values;

    const formattedValue = parseFloat(valor.replace(',', '.'));
    const formatedPercentage = parseFloat(porcentagem.replace(',', '.'));

    submit(
      tarifa,
      subgrupo,
      posto,
      modalidade,
      initialDate,
      finalDate,
      formattedValue,
      formatedPercentage
    );
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    valor: validationsForm.value,
    porcentagem: validationsForm.value,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedTariffsModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  modalidade: '',
                  subgrupo: '',
                  posto: '',
                  tarifa: '',
                  valor: '',
                  porcentagem: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateTariff}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormTariffsContainer>
                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="modalidade-label"
                            className={classes.whiteText}
                          >
                            Modalidade
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="modalidade"
                            labelId="modalidade-label"
                            id="modalidade-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {ModalidadeOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="subgrupo-label"
                            className={classes.whiteText}
                          >
                            Subgrupo
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="subgrupo"
                            labelId="subgrupo-label"
                            id="subgrupo-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {SubgrupoOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="posto-label"
                            className={classes.whiteText}
                          >
                            Posto
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="posto"
                            labelId="posto-label"
                            id="posto-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {PostoOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="tarifa-label"
                            className={classes.whiteText}
                          >
                            Tarifa
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="tarifa"
                            labelId="tarifa-label"
                            id="tarifa-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {TarifaOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'valor'}
                          name={'valor'}
                          type={'text'}
                          label={'R$ / MWh'}
                          defaultvalue={initialValues['valor']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['valor'] && touched['valor']
                              ? errors['valor']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'porcentagem'}
                          name={'porcentagem'}
                          type={'text'}
                          label={'Porcentagem de desconto'}
                          defaultvalue={initialValues['porcentagem']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['porcentagem'] && touched['porcentagem']
                              ? errors['porcentagem']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <div
                        style={{
                          display: 'flex',
                          marginTop: '20px',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <IoseSelectDayMouth
                            title="Início da vigência"
                            getDate={setInitialDate}
                            initialDate={initialDate}
                            margin={'0px'}
                          />
                        </div>

                        <div>
                          <IoseSelectDayMouth
                            title="Fim da vigência"
                            getDate={setFinalDate}
                            initialDate={finalDate}
                            margin={'0px'}
                          />
                        </div>
                      </div>
                    </FormTariffsContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar Tarifa
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedTariffsModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseAddUsersModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
  groupOptions,
}) {
  const classes = useStyles();

  const handleCreateUser = (values) => {
    const { name, email, group } = values;

    submit(name, email, group);
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    name: validationsForm.name,
    email: validationsForm.email,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedTariffsModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateUser}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormTariffsContainer>
                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'name'}
                          name={'name'}
                          type={'text'}
                          label={'Nome'}
                          defaultvalue={initialValues['name']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['name'] && touched['name']
                              ? errors['name']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'email'}
                          name={'email'}
                          type={'text'}
                          label={'E-mail'}
                          defaultvalue={initialValues['email']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['email'] && touched['email']
                              ? errors['email']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="group-label"
                            className={classes.whiteText}
                          >
                            Grupo
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="group"
                            labelId="group-label"
                            id="group-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {groupOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>
                    </FormTariffsContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedTariffsModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseChangeGroupModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
  groupOptions,
  seletectedRow,
}) {
  const classes = useStyles();

  const handleCreateUser = (values) => {
    const { new_group } = values;

    submit(seletectedRow.username, seletectedRow.group, new_group);
  };

  //Schema validation for UnityAdd
  const ValidantionAdd = Yup.object().shape({
    name: validationsForm.name,
    email: validationsForm.email,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>

          {/* Formulário */}
          <StyledWrappedTariffsModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  name: seletectedRow?.name,
                  email: seletectedRow?.email,
                  group: seletectedRow?.group,
                  new_group: '',
                }}
                validationSchema={ValidantionAdd}
                onSubmit={handleCreateUser}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormTariffsContainer>
                      <DivInputs marginBottom="10px">
                        <StyledField
                          component={TextFieldStyled}
                          fullWidth
                          id={'name'}
                          name={'name'}
                          type={'text'}
                          label={
                            <span style={{ color: 'white', opacity: '80%' }}>
                              Nome
                            </span>
                          }
                          defaultvalue={initialValues['name']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['name'] && touched['name']
                              ? errors['name']
                              : ''
                          }
                          disabled
                        />
                      </DivInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          disabled
                          id={'email'}
                          name={'email'}
                          type={'text'}
                          label={
                            <span style={{ color: 'white', opacity: '80%' }}>
                              E-mail
                            </span>
                          }
                          defaultvalue={initialValues['email']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['email'] && touched['email']
                              ? errors['email']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="group-label"
                            className={classes.whiteText}
                          >
                            Grupo Anterior
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="group"
                            labelId="group-label"
                            id="group-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                            inputProps={{
                              readOnly: true,
                              style: { color: 'white' },
                            }}
                          >
                            {groupOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel
                            id="new_group-label"
                            className={classes.whiteText}
                          >
                            Novo Grupo
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="new_group"
                            labelId="new_group-label"
                            id="new_group-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {groupOptions?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>
                    </FormTariffsContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Alterar
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedTariffsModal>
        </div>
      </Slide>
    </Modal>
  );
}

export function IoseAddCarbonTariffsModal({
  open,
  onClose,
  modalHeaderTitle,
  modalTitle,
  submit,
  notificationType,
  showNotification,
  addNotification,
  loading,
}) {
  const classes = useStyles();

  const handleCreateTariff = (values) => {
    const {
      month_select,
      year_select,
      yearTariff,
      fator_medio_mensal_tco2_mwh,
    } = values;

    const formattedYearTariff = parseFloat(yearTariff.replace(',', '.'));
    const formattedMonthlyFactor = parseFloat(
      fator_medio_mensal_tco2_mwh.replace(',', '.')
    );
    submit(
      formattedMonthlyFactor,
      formattedYearTariff,
      month_select,
      year_select
    ); // Passar month_select e year_select diretamente
  };

  const ValidationAdd = Yup.object().shape({
    month_select: Yup.string().required('Mês é obrigatório'),
    year_select: Yup.string().required('Ano é obrigatório'),
    fator_medio_mensal_tco2_mwh: validationsForm.value,
    yearTariff: validationsForm.value,
    // Inclua validações para outros campos conforme necessário
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          {/* ... Código do cabeçalho ... */}
          <StyledHeaderModal>
            <StyledLeftHeaderModal>
              <ObjectIcon />
            </StyledLeftHeaderModal>
            <StyledRightHeaderModal>
              <ModalTitleHeader variant="h5" gutterBottom>
                {modalHeaderTitle}
              </ModalTitleHeader>
            </StyledRightHeaderModal>
          </StyledHeaderModal>
          {/* Formulário */}
          <StyledWrappedTariffsModal>
            <div className={classes.paper}>
              <ModalTitle variant="h5" gutterBottom>
                {modalTitle}
              </ModalTitle>
              <Formik
                initialValues={{
                  month_select: '',
                  year_select: '',
                  annual_factor_rate: '',
                  annual_factor_year_select: '',
                  fator_medio_mensal_tco2_mwh: '',
                  yearTariff: '',
                }}
                validationSchema={ValidationAdd}
                onSubmit={handleCreateTariff}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <FormTariffsContainer>
                      {/* Campo de Seleção do Mês */}
                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel id="month-select-label">
                            Mês
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="month_select"
                            labelId="month-select-label"
                            id="month-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {MonthOptions.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      {/* Campo de Seleção do Ano */}
                      <DivInputs>
                        <StyledFormControl fullWidth>
                          <StyledInputLabel id="year-select-label">
                            Ano
                          </StyledInputLabel>
                          <Field
                            as={StyledSelect}
                            name="year_select"
                            labelId="year-select-label"
                            id="year-select"
                            onChange={handleChange}
                            className={classes.whiteText}
                            required={true}
                          >
                            {YearOptions.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </StyledFormControl>
                      </DivInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'fator_medio_mensal_tco2_mwh'}
                          name={'fator_medio_mensal_tco2_mwh'}
                          type={'text'}
                          label={'Tarifa mensal'}
                          defaultvalue={
                            initialValues['fator_medio_mensal_tco2_mwh']
                          }
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['fator_medio_mensal_tco2_mwh'] &&
                            touched['fator_medio_mensal_tco2_mwh']
                              ? errors['fator_medio_mensal_tco2_mwh']
                              : ''
                          }
                        />
                      </StyledTextInputs>

                      <StyledTextInputs>
                        <Field
                          component={IoseTextFieldSidebarStyled3}
                          fullWidth
                          id={'yearTariff'}
                          name={'yearTariff'}
                          type={'text'}
                          label={'Tarifa anual'}
                          defaultvalue={initialValues['yearTariff']}
                          onchange={handleChange}
                          onblur={handleBlur}
                          helpertext={
                            errors['yearTariff'] && touched['yearTariff']
                              ? errors['yearTariff']
                              : ''
                          }
                        />
                      </StyledTextInputs>
                    </FormTariffsContainer>

                    {loading ? (
                      <DivCircularProgress>
                        <CircularProgressStyled />
                      </DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={addNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={addNotification} />
                        )}
                      </>
                    ) : (
                      <ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Cadastrar Tarifa
                        </IoseModalButton>
                      </ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </StyledWrappedTariffsModal>
        </div>
      </Slide>
    </Modal>
  );
}
