const Messages = {
  //General
  generalErrorOperation: 'Ocorreu um erro na operação',
  generalAuthentication: 'Código errado ou senha fora to formado especificado!',
  errorGetData: 'Error ao buscar informações!',
  initialDateGreaterFinal: 'A data final está menor que a data inicial!',
  noServiceComunication:
    'Infelizmente, parece que não conseguimos obter comunicação com o servidor. Recarregue a página!',
  authenticationError:
    'Ocorreu um erro na autenticação do usuário, atualize a página!',
  chooseAtLeastOne: 'Escolha pelo menos uma das opções',

  //UnityContainer
  noRegisteredUnits: 'Não há unidades cadastradas',
  noRegistered3dUnits: 'Não há unidades com projetos com 3D',
  erroGetNewCircuits: 'Erro ao buscar novos circuitos, recarregue a página',
  successCommissioning: 'Sucesso a  associar todos os circuitos selecionados!',
  successDeleteUnity: 'Unidade deletada com sucesso!',
  errorDeleteUnity: 'Error ao deletar o unidade!',
  noFindUnitys: 'Nenhuma unidade encontrada!',
  errorGetAllGroups:
    'Erro ao buscar quadros da unidade, recarregue a página e tente novamente! ',
  erroCommissioning:
    'Erro a associar algum dos circuitos selecionados, tente novamente!',

  //EnvironmentContainer
  noRegisteredEnvironments: 'Não há ambientes cadastrados',
  noFindEnvironments: 'Nenhum ambiente encontrado',
  environmentsNoRegistered:
    'Não foi possível cadastrar o ambiente, tente novamente',

  //EnvironmentContainer
  noRegisteredEnergyBills: 'Não há contas cadastradas',
  noFindEnergyBills: 'Nenhuma conta encontrada',

  //ObjectsContainer
  noRegisteredObjects: 'Não há objetos cadastrados',
  noFindObjects: 'Nenhum objeto encontrado',
  objectsNoRegistered: 'Não foi possível cadastrar o objeto, tente novamente',

  //ObjectsContainer
  noRegisteredLogs: 'Não há logs cadastrados',
  noFindLogs: 'Nenhum log encontrado',

  //PermissionsContainer
  noRegisteredPermissions: 'Não há permissões de grupo cadastradas',
  noFindPermissions: 'Nenhuma permissão encontrada',

  //ConcessionairesContainer
  noRegisteredConcessionaires: 'Não há concessionárias cadastradas',
  noFindConcessionaires: 'Nenhuma concessionária encontrada',
  concessionairesNoRegistered:
    'Não foi possível cadastrar a concessionária, tente novamente',
  successDeleteConcessionaires: 'Concessionária deletada com sucesso!',
  errorDeleteConcessionaires: 'Error ao deletar a concessionária!',

  //SolarEcuContainer
  noRegisteredEcus: 'Não há ECUs cadastradas',
  noFindEcus: 'Nenhuma ECU encontrada',
  ecusNoRegistered: 'Não foi possível cadastrar a ECU, tente novamente',
  successDeleteEcus: 'ECU deletada com sucesso!',
  errorDeleteEcus: 'Error ao deletar a ECU!',
  errorUpdateEcus: 'Error ao editar a ECU!',

  //UserGroupsContainer
  noRegisteredGroups: 'Não há grupos cadastrados',
  noFindGroups: 'Nenhuma grupo encontrada',
  groupNoRegistered: 'Não foi possível cadastrar o grupo, tente novamente',
  successDeleteGroup: 'Grupo deletada com sucesso!',
  errorDeleteGroup: 'Error ao deletar o grupo!',
  errorUpdateGroup: 'Error ao editar o grupo!',

  //ConsumerGroupsContainer
  noRegisteredConsumerGroups: 'Não há unidades consumidoras cadastradas',
  noFindConsumerGroups: 'Nenhuma unidade consumidora encontrada',
  consumerGroupsNoRegistered:
    'Não foi possível cadastrar a unidade consumidora, tente novamente',
  successDeleteConsumerGroups: 'Unidade Consumidora deletada com sucesso!',
  errorDeleteConsumerGroups: 'Error ao deletar a unidade consumidora!',

  //Results
  noFindResults: 'Nenhum result encontrado',

  //Tariffs
  noRegisteredTariffs: 'Não há tarifas cadastradas',
  noFindTariffs: 'Nenhuma tarifa encontrada',

  //Users
  noRegisteredUser: 'Não há usuários cadastrados',
  noFindUser: 'Nenhum usuário encontrado',

  //ConsumerGroupsContainer
  noRegisteredComparison: 'Não há comparações de consumo cadastradas',
  noFindComparison: 'Nenhuma comparação de consumo encontrada',

  //ContractsContainer
  noRegisteredContracts: 'Não há contratos cadastrados',
  noFindContracts: 'Nenhum contrato encontrado',
  contractsNoRegistered:
    'Não foi possível cadastrar o contrato, tente novamente',
  successDeleteContracts: 'Contrato deletado com sucesso!',
  errorDeleteContracts: 'Error ao deletar o contrato!',

  //CircuitsContainer
  noRegisteredCircuits: 'Não há circuitos cadastrados',
  noFindCircuits: 'Nenhum circuito encontrado',
  circuitsNoRegistered:
    'Não foi possível cadastrar o circuito, tente novamente',

  //SystemVariables
  noSystemVariables: 'Nenhuma variável encontrada.',

  //Login
  incorrectUsernamePassword: 'E-mail ou senha incorreta',
  userNotExist: 'Usuário não existe',
  notConformPolicy: 'Senha fora do formato requerido!',
  noGroupRegistered: 'Usuário não cadastrado em um grupo!',
  requisitionPassowordCognito: 'mínimo 6 dígitos, um símbolo, e um número.',
  whiteSpaceEmail: 'Retire os espaços vazios no início ou fim do email',
  definitivePassword:
    'Modifique a senha abaixo para uma senha definitiva. A nova senha deverá conter no ',
  passwordFormatInvalid:
    'A senha não está em conformidade com os requistos pedidos acima.',

  //ForgotLogin
  limitExceededException: 'Limite de tentativas expiradas! Tente mais tarde.',
  attemptLimitExceeded: 'Limite de tentativas expiradas! Tente mais tarde',
  passwordsNoEquals: 'As senhas não estão iguais! ',
  notSamePasswords: 'As senhas não estão iguais!',
  forgotPassword:
    'Enviaremos um e-mail com um código de verificação para que você possa criar uma nova senha.',
  codeVerificationMessage:
    'Insira abaixo o código de verificação enviado para seu e email. A nova senha deve conter no',
  newPasswordConfirmartion:
    ' A Nova senha foi criada com sucesso, use o botão abaixo para efetuar seu login com a nova senha.',
  codeMismatchException:
    'Código de verificação inválido fornecido, tente novamente.',

  //SidebarSwitchboardContainer
  noRegisteredSwichboards: 'Não existe quadros cadastrados',
  noSwichboardRegistered: 'Não foi possível cadastrar o quadro!',

  //SidebarAdminContanier
  admingetErro: 'Erro ao tentar retornar os administradores',
  successDeleteAdmin: 'Administrador deletado com sucesso!',
  errorDeleteAdmin: 'Error ao deletar o administrador!',
  adminNoUpdate: 'Não foi possível editar dados do administrador!',
  adminNoRegistered:
    'Não foi possível cadastrar o administrador, possivelmente existe outro com o mesmo email.',
  noAdminsRegistered:
    'Não possui Administradores cadastrados. Para cadastrar, aperte no botão de + logo acima.',

  //SidebarClientContanier
  clientNoRegistered: 'Não foi possível cadastrar o cliente, tente novamente',
  clientNoEdited: 'Não foi possível editar o cliente, tente novamente',

  //SuperUserContainer
  successDeleteClient: 'Cliente deletado com sucesso!',
  errorDeleteClient: 'Error ao deletar o cliente!',
  noRegisteredClients: 'Não existe clientes cadastrados!',
  noFindClient: 'Nenhum cliente encontrado!',

  //IoseDeleteModal
  confirmationTextIncorret: 'Texto de confirmação incorreto.',

  //SidebarUnityContainer
  unityNoRegistered: 'Não foi possível cadastrar a unidade, tente novamente',

  //IoseEmployeedShowCard
  noEmployeeRegistered:
    'Não possui Funcionários cadastrados. Para cadastrar, aperte no botão de + logo acima.',

  //SidebarEmployeeContainer
  errorDeleteEmployee: 'Error ao deletar o funcionário!',
  successDeleteEmployee: 'Funcionário deletado com sucesso!',
  employeeNoEdited: 'Não foi possível editar o cliente, tente novamente',
  employeeGetErro:
    'Erro ao tentar retornar os funcionários, feche e abra a sidebar',
  employeeNoRegistered:
    'Não foi possível cadastrar o funcionário, possivelmente existe outro com o mesmo email.',

  //SidebarUnityOperationsContainer
  unityNoEdited: 'Não foi possível editar o a unidade, tente novamente',
  someEmptyTariffData:
    'Um ou mais campos das informações relacionadas ao período tarifário estão vazios!',

  //IoseSwitchboardShowCardList
  circuitsGetErro: 'Erro ao tentar buscar os Circuitos, feche e abra a sidebar',
  noSwitchboardRegistered:
    'Não possui Quadros cadastrados. Para cadastrar, aperte no botão de + logo acima.',
  switchboardGetErro:
    'Erro ao tentar buscar os Quadros, feche e abra a sidebar',

  //DashboardContainer
  errorDeleteSwitchboard: 'Error ao deletar o quadro!',
  successDeleteSwitchboard: 'Quadro deletado com sucesso!',
  errorDeleteCircuit: 'Error ao deletar o circuito!',
  successDeleteCircuit: 'Circuito deletado com sucesso!',
  errorTrigger: 'Erro na Requisição',

  //IoseSidebarSwichtboardContainer
  switchboardNoEdited: 'Error a editar quadro',
  circuitsDesassociateSuccess: 'Circuito(s) desassociado(s) com sucesso!',
  circuitsDesassociateErro:
    'Erro a desassociar algum(uns) circuito(s) escolhido(s), tente novamente.',

  //IoseCircuitCommissioning
  noCircuitSelected: 'Selecione ao menos um circuito!',

  //IoseSwitchboardShowCard
  noAssociateCircuits: 'Não possui circuitos associados!',
  noPermissionDisassociate: 'Você não tem permissão para desassociar circuitos',

  //IoseCircuitShowCard
  emptyScheduling: 'Não possui agendamentos relacionados!',

  //SidebarCircuitContainer
  errorGetLogs: 'Erro ao buscar Logs',

  //IoseCircuitLogTable
  emptyLogs: 'Nenhum evento encontrado!',
  noUserLog: 'Super Usuário',
  actionModule: 'Ação efetuada pelo módulo!',

  //EventsContainer
  errorEventsTable: 'Erro ao buscar dados da tabela de eventos!',
  emptyEvents: 'Nenhum evento encontrado! ',

  //Unity Consumptions
  noRegisteredUnityConsumptions: 'Não há consumo de unidades',
  noFindUnityConsumptions: 'Nenhum consumo encontrado',

  //Groups Consumptions
  noRegisteredGroupsConsumptions: 'Não há consumo de quadros',
  noFindGroupsConsumptions: 'Nenhum consumo encontrado',

  //Circuits Consumptions
  noRegisteredCircuitsConsumptions: 'Não há consumo de circuitos',
  noFindCircuitsConsumptions: 'Nenhum consumo encontrado',

  //Circuits Consumptions
  noRegisteredBillValueAudit: 'Não há auditorias de valor',
  noFindBillValueAudit: 'Nenhum recálculo encontrado',

  //System Urls
  noRegisteredUrls: 'Nenhuma URL cadastrada.',
};

export default Messages;
