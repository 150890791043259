import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export default async function energyBillCreditReport(data) {
  const totalGerado = data?.reduce(
    (accumulator, item) => accumulator + item.gerados_pta + item.gerados_fpta,
    0
  );
  const allLength = data
    ?.filter((item) => item.uc_beneficiarias)
    .map((beneficiaria) => ({
      ...beneficiaria,
      uc_beneficiarias: beneficiaria.uc_beneficiarias.filter(
        (item) => item.uc_beneficiaria !== item.uc_geradora
      ),
    }))
    .map((beneficiaria) =>
      beneficiaria.uc_beneficiarias.map((item) => item.uc_beneficiaria)
    );

  // Reduzir a matriz nested em uma única matriz de todas as uc_beneficiarias
  const flattenedBeneficiarias = allLength?.flat();

  // Remover duplicatas usando um conjunto
  const uniqueBeneficiarias = [...new Set(flattenedBeneficiarias)];

  const totalObjects = uniqueBeneficiarias?.length;

  const ucGeradorasSet = new Set(
    data.filter((item) => item.uc_geradora).map((item) => item.uc_geradora)
  );
  const uniqueUcGeradoras = Array.from(ucGeradorasSet);

  const totalGeradoras = uniqueUcGeradoras?.length;

  const totalCredito = data?.reduce((accumulator, item) => {
    const credRecebidoSum = item?.uc_beneficiarias.reduce(
      (beneficiariaSum, beneficiaria) => {
        const parsedValue = parseFloat(beneficiaria.cred_recebido);
        if (!isNaN(parsedValue)) {
          return beneficiariaSum + parsedValue;
        }
        return beneficiariaSum;
      },
      0
    );

    return accumulator + credRecebidoSum;
  }, 0);

  const logoData = await convertImageToBase64(Logo);
  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45], // left, top, right, bottom
        },
        {
          text: `Relatório de Unidades Beneficiárias `,
          // text: `Relatório de Unidades Beneficiárias ${uniqueGeradoras} `,
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];

  const generateCell = (text) => ({
    text,
    fontSize: 6,
    margin: [0, 2, 0, 2],
    alignment: 'center',
  });

  const generateHeaderCell = (text) => ({
    ...generateCell(text),
    style: 'tableHeader',
    bold: true,
  });

  const generateBeneficiariaTableRows = (beneficiarias) => {
    const headerRow = [
      generateHeaderCell('UC Beneficiária'),
      generateHeaderCell('Mês do crédito gerado'),
      generateHeaderCell('Mês do crédito utilizado'),
      generateHeaderCell('Crédito utilizado'),
      generateHeaderCell('Unidade'),
      generateHeaderCell('%'),
    ];

    const beneficiariaRows = beneficiarias
      ?.filter(
        (beneficiaria) =>
          beneficiaria?.uc_beneficiaria !== beneficiaria?.uc_geradora
      )
      .map((beneficiaria) => [
        generateCell(beneficiaria?.uc_beneficiaria),
        generateCell(beneficiaria?.mes_cred_gerado),
        generateCell(beneficiaria?.mes_cred_utilizado),
        generateCell(beneficiaria?.cred_recebido),
        generateCell('kWh'),
        generateCell(beneficiaria?.porcentagem),
      ]);

    return [headerRow, ...beneficiariaRows];
  };

  const generateGeradoraTableRows = (geradoras) => {
    const generateHeaderRow2 = [
      generateHeaderCell('UC Geradora'),
      generateHeaderCell('Gerado PT'),
      generateHeaderCell('Gerado FP'),
      generateHeaderCell('Saldo'),
      generateHeaderCell('Compensado'),
      generateHeaderCell('Unidade'),
      generateHeaderCell('Autocompensado PT'),
      generateHeaderCell('Autocompensado FP'),
      generateHeaderCell('REF: MÊS/ANO'),
      generateHeaderCell('Validade'),
      generateHeaderCell('Vencimento (dias)'),
      generateHeaderCell('%'),
    ];

    const generateBeneficiariaRow2 = geradoras?.map((geradora) => [
      generateCell(geradora.uc_geradora),
      generateCell(geradora.gerados_pta),
      generateCell(geradora.gerados_fpta),
      generateCell(geradora.total),
      generateCell(geradora.compensado),
      generateCell(geradora.unidade),
      generateCell(geradora.autocompensados_pta),
      generateCell(geradora.autocompensados_fpta),
      generateCell(geradora.mes_referencia),
      generateCell(geradora.validade),
      generateCell(geradora.indicador),
      generateCell(
        geradora.porcentagem >= '99.9999'
          ? parseFloat(geradora.porcentagem).toFixed(1)
          : geradora.porcentagem
      ),
    ]);

    return [generateHeaderRow2, ...generateBeneficiariaRow2];
  };

  const groupedTables = data?.map((ucGeradora) => {
    const beneficiariasForMonth = ucGeradora.uc_beneficiarias.filter(
      (beneficiaria) =>
        (beneficiaria.mes_cred_utilizado === ucGeradora.mes_referencia ||
          beneficiaria.mes_cred_gerado === 'NULL') &&
        ucGeradora.mes_referencia === beneficiaria.mes_cred_utilizado
    );

    const ucGeradoraTable = [
      {
        margin: [0, 15, 0, 4], // left, top, right, bottom

        table: {
          headerRows: 1,
          widths: Array(12).fill('*'),
          body: generateGeradoraTableRows([ucGeradora]),
        },
        layout: {
          fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
        },
      },
    ];

    if (beneficiariasForMonth.length > 0) {
      const beneficiariasTableRows = generateBeneficiariaTableRows(
        beneficiariasForMonth
      );

      const beneficiariasTable = [
        {
          headerRows: 1,
          table: {
            dontBreakRows: true,
            widths: Array(6).fill('*'),
            body: beneficiariasTableRows,
          },
          layout: {
            fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
          },
          pageBreak: 'after',
          margin: [0, 2, 0, 0],
        },
      ];

      return [...ucGeradoraTable, ...beneficiariasTable];
    } else {
      const emptyParagraph = {
        text: '',
        pageBreak: 'after',
      };

      return [...ucGeradoraTable, emptyParagraph];
    }
  });

  const summaryTable = {
    table: {
      widths: ['auto', 'auto'],
      body: [
        [
          {
            text: 'Resumo',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
            colSpan: 2,
            fillColor: '#CCCCCC',
          },
          {},
        ],
        [
          {
            text: 'Total de unidades geradoras',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalGeradoras.toString(),
            fontSize: 8,
            alignment: 'left',
          },
        ],
        [
          {
            text: 'Total de unidades beneficiárias',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalObjects ? totalObjects.toString() : '0',
            fontSize: 8,
            alignment: 'left',
          },
        ],
        [
          {
            text: 'Total de créditos gerados',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalGerado,
            fontSize: 8,
            alignment: 'left',
          },
        ],
        [
          {
            text: 'Total de créditos utilizados',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalCredito ? totalCredito.toString() : '0',
            fontSize: 8,
            alignment: 'left',
          },
        ],
      ],
    },
    margin: [5, 60, 0, 40],
  };

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 6,
      bold: true,
      margin: [15, 15, 35, 500], // left, top, right, bottom
    };
  };
  const reportContent = [...groupedTables, summaryTable];

  const docDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [35, 50, 15, 40],

    content: [reportContent],
    footer: renderFooter,
  };

  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
