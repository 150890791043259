import React, { useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
  LoadingIndicator,
  IoseButton,
  IoseSelectCompleteDate,
} from 'components';

import { useClient, LocalStorage } from 'common';

import { useLogs, useGroupPermissions } from 'hooks';
import IoseTableContainer from 'components/IoseTableContainer';
import IoseDynamicFilter from 'components/IoseDynamicFilter';
import { IoseAddFilterButton } from 'components/IoseButtonIcon';
import { LogOperationOptions } from '../../constants';

import Permissions from 'common/permissions';

// const formatCurrency = (value) => {
//   const formattedValue = value?.toLocaleString('pt-BR', {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//     style: 'currency',
//     currency: 'BRL',
//   });

//   return formattedValue;
// };

export default function UserLogsContainer({ getcards }) {
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);

  //Filter states
  const [showFilters, setShowFilters] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({});
  const [logData, setLogData] = useState();
  const [uniqueUsers, setUniqueUsers] = useState([]);

  const today = new Date();
  const [selectedInitialDate, setSelectedInitialDate] = useState(today);
  const [selectedFinalDate, setSelectedFinalDate] = useState(today);
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { client } = useClient();
  const { logs } = useLogs();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['gerenciamento', 'log_usuarios', 'leitura'];
    const reportPath = ['gerenciamento', 'log_usuarios', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sortedLogs = logs?.sort(
      (a, b) => new Date(b.log_date) - new Date(a.log_date)
    );

    setLogData(sortedLogs);
  }, [logs]);

  useEffect(() => {
    // Extrair usuários únicos
    if (logs) {
      const uniqueUsers = [...new Set(logs?.map((log) => log?.log_user))];

      // Criar o formato desejado
      const formattedUsers = uniqueUsers?.map((user) => ({
        value: user,
        label: user,
      }));

      setUniqueUsers(formattedUsers);
    }
  }, [logs]);

  const applyFilters = (logs, filters) => {
    const formattedFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => {
        if (key === 'log_date_start' || key === 'log_date_end') {
          const isoDate = value
            ? new Date(value.split('/').reverse().join('-'))
            : null;

          if (key === 'log_date_end' && isoDate) {
            // Adicione um dia apenas se não for um intervalo de dias
            const isSameDay =
              acc['log_date_start'] && acc['log_date_start'] === value;
            if (!isSameDay) {
              isoDate.setDate(isoDate.getDate() + 1);
            }
          }

          acc[key] = isoDate ? isoDate.toISOString() : null;
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    const filteredLogs = logs?.filter((log) => {
      return Object.entries(formattedFilters).every(([key, value]) => {
        if (key === 'log_date_start' || key === 'log_date_end') {
          const logDate = new Date(log.log_date);

          if (key === 'log_date_start' && value) {
            const startDate = new Date(value);
            return logDate >= startDate;
          } else if (key === 'log_date_end' && value) {
            const endDate = new Date(value);
            return logDate < endDate;
          }

          return true;
        } else if (key === 'contains' && value) {
          return log.log_message.includes(value);
        }

        return log[key] === value;
      });
    });

    setLogData(filteredLogs);
    setSelectedFilters({});
  };

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    // const showButton = group === 'super' || group === 'admin' ? true : false;

    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR TARIFA" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle="Logs"
        description={`Total: ${logs?.length ? logs?.length : 0}`}
        button={showButton ? button : <></>}
      >
        {/* <IoseSearch
          placeholder="Pesquisar por modalidade tarifária..."
          funcSearch={filterContracts}
          getcards={getcards}
        /> */}
      </IoseSubHeaderBar>
    );
  };

  const RenderFilterContainer = () => {
    const filterOptions = ['Operação', 'Usuário', 'Data'];

    const toggleFilters = () => {
      setShowFilters(!showFilters);
    };

    const handleOptionClick = (option) => {
      setSelectedOption(option);
      setShowFilters(false);
    };

    const handleOperationChange = (value) => {
      setSelectedOperation(value);
    };

    const handleUserChange = (value) => {
      setSelectedUser(value);
    };

    const handleInitialDateChange = (date) => {
      setSelectedInitialDate(date);
    };

    const handleFinalDateChange = (date) => {
      setSelectedFinalDate(date);
    };

    const renderSelectedComponent = () => {
      const filterOptions = {
        'Operação': {
          label: 'Operação',
          databaseName: 'log_operation',
          value: selectedOperation,
          onChange: handleOperationChange,
          options: LogOperationOptions,
        },
        'Usuário': {
          label: 'Usuário',
          databaseName: 'log_user',
          value: selectedUser,
          onChange: handleUserChange,
          options: uniqueUsers,
        },
        'Data': {
          label: 'Data',
          databaseName: 'log_date',
          initial_value: selectedInitialDate,
          final_value: selectedFinalDate,
          initial_onChange: handleInitialDateChange,
          final_onChange: handleFinalDateChange,
        },
      };

      const selectedFilter = filterOptions[selectedOption];

      const handleButtonClick = () => {
        if (selectedFilter && selectedFilter.databaseName) {
          const formattedFilter = { ...selectedFilters };

          if (selectedFilter.databaseName === 'log_date') {
            if (selectedFilter.initial_value && selectedFilter.final_value) {
              formattedFilter[
                'log_date_start'
              ] = selectedFilter.initial_value.toLocaleDateString();
              formattedFilter[
                'log_date_end'
              ] = selectedFilter.final_value.toLocaleDateString();
            }
          } else {
            if (selectedFilter.value) {
              formattedFilter[selectedFilter.databaseName] =
                selectedFilter.value;
            }
          }

          setSelectedFilters(formattedFilter);
        }

        setShowFilters(false);
        setSelectedOption(null);
      };

      const handleRemoveFilter = (key) => {
        const updatedFilters = { ...selectedFilters };

        if (updatedFilters[key]) {
          delete updatedFilters[key];
        }

        setSelectedFilters(updatedFilters);
      };

      return (
        <S.FilterBox>
          {selectedFilter && (
            <>
              {selectedFilter.label === 'Data' ? (
                <div style={{ display: 'flex', margin: '0 0 0 15px' }}>
                  <IoseSelectCompleteDate
                    label="Data inicial"
                    handleChange={selectedFilter.initial_onChange}
                    selectedDate={selectedFilter.initial_value}
                  />

                  <IoseSelectCompleteDate
                    label="Data final"
                    handleChange={selectedFilter.final_onChange}
                    selectedDate={selectedFilter.final_value}
                  />
                </div>
              ) : (
                <IoseDynamicFilter
                  label={selectedFilter.label}
                  value={selectedFilter.value}
                  onChange={selectedFilter.onChange}
                  options={selectedFilter.options}
                />
              )}
              <IoseButton onClick={handleButtonClick}>
                ADICIONAR FILTRO
              </IoseButton>
            </>
          )}

          <div style={{ marginLeft: '25px' }}>
            {Object.entries(selectedFilters).length > 0 && (
              <S.FilterCardContainer>
                <h3>Filtros Selecionados:</h3>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {Object.entries(selectedFilters).map(([key, value]) => (
                    <S.FilterCardDiv key={key}>
                      <div>{value}</div>
                      <S.CloseButton onClick={() => handleRemoveFilter(key)}>
                        <S.CloseCardIcon />
                      </S.CloseButton>
                    </S.FilterCardDiv>
                  ))}
                </div>
                <IoseButton
                  left={'15px'}
                  onClick={() => applyFilters(logs, selectedFilters)}
                >
                  FILTRAR
                </IoseButton>
              </S.FilterCardContainer>
            )}
          </div>
        </S.FilterBox>
      );
    };

    return (
      <S.FilterContainer>
        <IoseAddFilterButton
          onClick={toggleFilters}
          tooltip={'ADICIONAR FILTRO'}
        />

        {showFilters && (
          <S.FilterBox>
            {filterOptions.map((option, index) => (
              <S.OptionsButton
                key={index}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </S.OptionsButton>
            ))}
          </S.FilterBox>
        )}

        {renderSelectedComponent()}
      </S.FilterContainer>
    );
  };

  const renderTable = () => {
    return (
      <S.ContainerTable>
        <IoseTableContainer
          tableTitle=""
          searchInTable={false}
          data={logData}
          hasReportPermission={hasReportPermission}
        />
      </S.ContainerTable>
    );
  };

  const renderContent = () => {
    if (error) {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div>{renderTable()}</div>
        </>
      );
    }
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {RenderFilterContainer()}
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : hasReadPermission ? (
          renderContent()
        ) : (
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

UserLogsContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
