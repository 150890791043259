// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useUniqueRegionalManagement() {
  const [noRegionalManagementError, setNoRegionalManagementError] = useState(
    ''
  );

  const { getSession } = useContext(AccountContext);

  const getUniqueRegionalManagement = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getUniqueRegionalManagement(headers);

    const regionalManagement = response.data.data || [];

    if (regionalManagement.length === 0) {
      setNoRegionalManagementError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return regionalManagement;
  };

  const {
    data: regionalManagements,
    error: regionalManagementsError,
    refetch: refreshRegionalManagement,
    isLoading,
  } = useQuery(['regionalManagements'], getUniqueRegionalManagement);

  return {
    regionalManagements,
    regionalManagementsError,
    noRegionalManagementError,
    refreshRegionalManagement,
    isLoading,
  };
}

export default useUniqueRegionalManagement;
