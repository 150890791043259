import React from 'react';
import { Grow, Slide } from '@material-ui/core';

import * as S from './style';

import {
  IoseAdmDashChartUnity,
  IoseNoDataMessage,
  IoseAdmDashListSummaryUnitys,
} from 'components';

export default function IoseAdmDashContainerUnity({ data }) {
  function renderAdmDashOrErrorMessage() {
    const allDataZeros = data?.every(
      (dataPoint) => dataPoint.consumption === 0
    );
    if (allDataZeros || data === undefined) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
          height="250px"
          width="220px"
        />
      );
    } else {
      return <IoseAdmDashChartUnity data={data} />;
    }
  }

  return (
    <Slide
      direction="right"
      in={true}
      timeout={2000}
      mountOnEnter
      unmountOnExit
    >
      <S.GridStyled>
        <S.StyledContainer>
          <S.Header>
            <S.UnityIcon />
            <h3>UNIDADES</h3>
          </S.Header>
          <Grow in={true} {...{ timeout: 1000 }}>
            <S.WrapperData>
              <S.LeftContainer>{renderAdmDashOrErrorMessage()}</S.LeftContainer>
              <S.RightContainer>
                <IoseAdmDashListSummaryUnitys data={data} />
              </S.RightContainer>
            </S.WrapperData>
          </Grow>
        </S.StyledContainer>
      </S.GridStyled>
    </Slide>
  );
}
