import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';

const OffCanvas = ({ name, show, onHide, children }) => {
  return (
    <Drawer anchor="right" open={show} onClose={onHide}>
      <div style={{ width: 600, padding: 16 }}>
        <Typography variant="h6">{name}</Typography>
        <List>
          <ListItem>
            <ListItemText>{children}</ListItemText>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default OffCanvas;
