import React from 'react';

import {
  LoadingIndicator,
  IoseAreaChartConsumptionComparision,
  IoseBarChartConsumptionComparision,
  IoseNoDataMessage,
} from 'components';

import * as S from './style';

export default function IoseConsumptionComparisionCharts({ loading, data }) {
  const renderChartOrErrorMessage = (ChartComponent) => {
    const allDataZeros = data?.every(
      (dataPoint) =>
        dataPoint.bill_consumption === 0 && dataPoint.measured_consumption === 0
    );
    if (allDataZeros) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
          height="180px"
          width="180px"
        />
      );
    } else {
      return <ChartComponent data={data} />;
    }
  };

  return (
    <S.ChartsContainer>
      <S.ChartBox>
        <S.ChartTitle>
          <h3>COMPARATIVO DE CONSUMO CONTA VS IOSE (kWh)</h3>
        </S.ChartTitle>
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          renderChartOrErrorMessage(IoseAreaChartConsumptionComparision)
        )}
      </S.ChartBox>
      <S.ChartBox>
        <S.ChartTitle>
          <h3>COMPARATIVO DE CONSUMO ENTRE UNIDADES CONSUMIDORAS</h3>
        </S.ChartTitle>
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          renderChartOrErrorMessage(IoseBarChartConsumptionComparision)
        )}
      </S.ChartBox>
    </S.ChartsContainer>
  );
}
