import React from 'react';
import { TableBody, TableCell, Paper, makeStyles } from '@material-ui/core';

import { StyledTable, TableHeadStyled, TableRowStyled } from './style';

import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

function formatNumber(value) {
  const number = Number(value);

  if (!isNaN(number)) {
    const formattedNumber = number?.toLocaleString();
    return typeof formattedNumber === 'string' ? formattedNumber : value;
  }

  return value;
}

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

// function convertNumberToDecimalStyle(number = 0) {
//   const decimalNumber = number.toLocaleString('pt-br');
//   return decimalNumber;
// }

const TableRow = ({ value, onClick }) => {
  const classes = useStyles();

  return (
    <TableRowStyled align="center" className={classes.tableRow}>
      <TableCellCentered>{value?.name}</TableCellCentered>
      <TableCellCentered>{formatNumber(value?.consumption)}</TableCellCentered>
      <TableCellCentered>
        {' '}
        {`R$ ${formatNumber(value?.value)}`}
      </TableCellCentered>
    </TableRowStyled>
  );
};

const Table = ({ data, onRowClick }) => (
  <StyledTable>
    <TableHeadStyled>
      <TableRowStyled align="center">
        <TableHeaderCellCentered>ITEM</TableHeaderCellCentered>
        <TableHeaderCellCentered>QUANTIDADE</TableHeaderCellCentered>
        <TableHeaderCellCentered>VALOR</TableHeaderCellCentered>
      </TableRowStyled>
    </TableHeadStyled>

    <TableBody>
      {data?.map((value, index) => (
        <TableRow key={index} value={value} onClick={onRowClick} />
      ))}
    </TableBody>
  </StyledTable>
);

export default function IoseSimulatorTable({ loading, data }) {
  function renderChartOrErrorMessage() {
    // if (data && data.length > 0) {
    return (
      <Paper>
        <Table data={data} id="scrollableDiv" />
      </Paper>
    );
    // } else {
    //   return (
    //     <IoseNoDataMessage
    //       message="Ops... Parece que não há dados suficientes para exibir a tabela!"
    //       height="300px"
    //       width="300px"
    //     />
    //   );
    // }
  }

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={data ? parseInt(data.length) : 5}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={'0'}
          padding={'0'}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={550}
        >
          {renderChartOrErrorMessage()}
        </IoseInfinityScroll>
      )}
    </div>
  );
}
