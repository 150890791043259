import styled, { keyframes } from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

export const GridContainer = styled.div`
  padding: 0px 15px 5px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const FilterContainer = styled.div`
  /* background-color: white; */
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  display: flex;
  gap: 10px;
`;

export const FilterBox = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  animation: ${slideIn} 0.5s ease;

  @media (max-width: 1405px) {
    gap: 5px;
  }
`;

export const OptionsButton = styled.button`
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--secondary);
  animation: ${slideIn} 0.5s ease;
  transition: 0.5s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:not(:hover) {
    transform: scale(1);
  }

  @media (max-width: 1405px) {
    font-size: 10px;
    padding: 5px;
  }
`;

// Filter Cards

export const FilterCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilterCardDiv = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  gap: 5px;
  align-items: center;

  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 1405px) {
    font-size: 10px;
    padding: 5px;
  }
`;

export const FilterCardValue = styled.div`
  @media (max-width: 1405px) {
    font-size: 10px;
  }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  padding: 2px;
  border-radius: 5px;
  transition: 0.5s ease;
  align-items: center;

  &:hover {
    scale: 1.1;
    background-color: #e3dfdf;
  }
`;

export const CloseCardIcon = styled(CloseIcon)`
  color: var(--alert);
  width: 18px;
`;
