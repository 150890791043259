import styled from 'styled-components';

//Imports Material UI components
import TextField from '@material-ui/core/TextField';

export const IoseNewTextFieldStyled = styled(TextField).attrs((props) => ({
  variant: 'outlined',
  fullWidth: true,
  autoFocus: true,
  className: 'yellow-border',
}))`
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.error ? '20px' : '10px')};
  background: var(--base);
  border-radius: 10px;
  opacity: 80%;

  input {
    pointer-events: none;
  }

  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: bold;
  }

  && .Mui-focused fieldset,
  &&.yellow-border .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary);
  }

  && .Mui-focused fieldset {
    border-color: var(--primary);
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
    color: red;
    margin: 0;
    font-size: 0.8rem;
    margin-top: 3px;
    text-align: left;
    font-weight: 700;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }

  &.Mui-focused .MuiInputLabel-root,
  &:hover .MuiInputLabel-root {
    color: var(--primary); /* Define a cor da label quando em foco ou hover */
  }
`;
