import React from 'react';

import * as S from './style';

import {
  TrendingUp as TrendingUpIcon,
  Equalizer as EqualizerIcon,
  ShowChart as ShowChartIcon,
} from '@material-ui/icons';

const getIcon = (title) => {
  switch (title) {
    case 'Consumo total':
      return <TrendingUpIcon />;
    case 'Balanço':
      return <ShowChartIcon />;
    case 'Consumo estimado':
      return <EqualizerIcon />;
    default:
      return null;
  }
};

export default function IoseAdmDashListSummaryUnitys({ data }) {
  const totalConsumption = data?.reduce(
    (total, obj) => total + obj.consumption,
    0
  );

  const SummaryUnitysData = [
    {
      title: 'Consumo total',
      value: convertNumberToDecimalStyle(totalConsumption) + ' kWh',
    },
    {
      title: 'Consumo estimado',
      value: convertNumberToDecimalStyle(0) + ' kWh',
    },
    {
      title: 'Balanço',
      value: convertNumberToDecimalStyle(totalConsumption - 0) + ' kWh',
    },
  ];

  function convertNumberToDecimalStyle(number = 0) {
    const decimalNumber = number.toLocaleString('pt-br');
    return decimalNumber;
  }

  return (
    <S.SummaryListContainer>
      <S.TitleList>RESUMO</S.TitleList>
      <S.ListContainer>
        {SummaryUnitysData.map(({ title, value }) => (
          <S.ListStyled key={title}>
            <S.SummaryIconWrapper>{getIcon(title)}</S.SummaryIconWrapper>
            <S.ListItemTextStyled primary={title} secondary={value} />
          </S.ListStyled>
        ))}
      </S.ListContainer>
    </S.SummaryListContainer>
  );
}
