import React, { useContext, useEffect, useState } from 'react';
import PropType from 'prop-types';
import { Tooltip } from '@material-ui/core';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseAlertLoadingCards,
  IoseModalTextField,
  IoseButton,
  LoadingIndicatorDashboard,
} from 'components';

import {
  useClientData,
  useGroupPermissions,
  useConsumerGroup,
  useTariffs,
} from 'hooks';

import Permissions from 'common/permissions';

import { AccountContext, LocalStorage, ioseAPI } from 'common';
import IoseDynamicFilter from 'components/IoseDynamicFilter';
import {
  ModalidadeOptions,
  SubgrupoOptions,
  SimpleOptions,
  ModalidadeB3Options,
} from '../../constants';
import IoseSimulatorTable from 'components/IoseSimulatorTable';

const formatNumber = (number) =>
  parseFloat(number).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

function calculateAverage(energyBills) {
  const averageNumberItens = energyBills?.length > 0 ? energyBills?.length : 1;

  let averageIcms = 0;
  let averageCofins = 0;
  let averagePis = 0;
  let averageAccountValue = 0;
  let totalContractedAmounts = 0;
  let aliqCofins = 0;
  let aliqPis = 0;
  let aliqIcms = 0;

  let consumo_te_ponta = 0;
  let consumo_te_f_ponta = 0;
  let consumo_tusd_ponta = 0;
  let consumo_tusd_f_ponta = 0;
  let value_te_ponta = 0;
  let value_te_f_ponta = 0;
  let value_tusd_ponta = 0;
  let value_tusd_f_ponta = 0;
  let consumo_energia_elet_consumo = 0;
  let consumo_energia_elet_uso_sistema = 0;
  let value_energia_elet_consumo = 0;
  let value_energia_elet_uso_sistema = 0;

  energyBills?.forEach((bill) => {
    averageIcms += bill.icms_calculation_basis_total || 0;
    averageCofins += bill.cofins_calculation_basis_total || 0;
    averagePis += bill.pis_calculation_basis_total || 0;
    averageAccountValue += bill.account_value || 0;
    aliqCofins += bill.cofins_aliquot || 0;
    aliqPis += bill.pis_aliquot || 0;
    aliqIcms += bill.icms_aliquot || 0;

    totalContractedAmounts += parseFloat(bill.contracted_amounts_1) || 0;

    for (let i = 1; i <= 17; i++) {
      const descriptionKey = `item_description_${i}`;
      const consumptionKey = `item_consumption_${i}`;
      const valueKey = `item_total_value_${i}`;

      if (bill[descriptionKey] === 'ENERGIA ELETRICA TE PONTA') {
        consumo_te_ponta += bill[consumptionKey] || 0;

        value_te_ponta += bill[valueKey] || 0;
      } else if (bill[descriptionKey] === 'ENERGIA ELETRICA TE F PONTA') {
        consumo_te_f_ponta += bill[consumptionKey] || 0;
        value_te_f_ponta += bill[valueKey] || 0;
      } else if (bill[descriptionKey] === 'ENERGIA ELETRICA USD PONTA') {
        consumo_tusd_ponta += bill[consumptionKey] || 0;
        value_tusd_ponta += bill[valueKey] || 0;
      } else if (bill[descriptionKey] === 'ENERGIA ELETRICA USD F PONTA') {
        consumo_tusd_f_ponta += bill[consumptionKey] || 0;
        value_tusd_f_ponta += bill[valueKey] || 0;
      } else if (bill[descriptionKey] === 'ENERGIA ELET CONSUMO') {
        consumo_energia_elet_consumo += bill[consumptionKey] || 0;
        value_energia_elet_consumo += bill[valueKey] || 0;
      } else if (bill[descriptionKey] === 'ENERGIA ELET USO SISTEMA') {
        consumo_energia_elet_uso_sistema += bill[consumptionKey] || 0;
        value_energia_elet_uso_sistema += bill[valueKey] || 0;
      }
    }
  });

  const icmsResult = formatNumber(averageIcms / averageNumberItens);
  const cofinsResult = formatNumber(averageCofins / averageNumberItens);
  const pisResult = formatNumber(averagePis / averageNumberItens);
  const accountValueResult = formatNumber(
    averageAccountValue / averageNumberItens
  );
  const contractedAmounts = formatNumber(
    totalContractedAmounts / averageNumberItens
  );

  const consumoTEPontaResult = formatNumber(
    consumo_te_ponta / averageNumberItens
  );

  const consumoTEFPontaResult = formatNumber(
    consumo_te_f_ponta / averageNumberItens
  );
  const consumoTUSDPontaResult = formatNumber(
    consumo_tusd_ponta / averageNumberItens
  );

  const consumoTUSDFPontaResult = formatNumber(
    consumo_tusd_f_ponta / averageNumberItens
  );

  const valueTEPontaResult = formatNumber(value_te_ponta / averageNumberItens);
  const valueTEFPontaResult = formatNumber(
    value_te_f_ponta / averageNumberItens
  );
  const valueTUSDPontaResult = formatNumber(
    value_tusd_ponta / averageNumberItens
  );
  const valueTUSDFPontaResult = formatNumber(
    value_tusd_f_ponta / averageNumberItens
  );

  const consumoB3TeResult = formatNumber(
    consumo_energia_elet_consumo / averageNumberItens
  );

  const valueB3TeResult = formatNumber(
    value_energia_elet_consumo / averageNumberItens
  );

  const consumoB3TusdResult = formatNumber(
    consumo_energia_elet_uso_sistema / averageNumberItens
  );

  const valueB3TusdResult = formatNumber(
    value_energia_elet_uso_sistema / averageNumberItens
  );

  const aliqCofinsResult = parseFloat(aliqCofins / averageNumberItens);

  const aliqPisResult = parseFloat(aliqPis / averageNumberItens);

  const aliqIcmsResult = parseFloat(aliqIcms / averageNumberItens);

  return {
    icmsResult,
    cofinsResult,
    pisResult,
    accountValueResult,
    contractedAmounts,
    aliqCofinsResult,
    aliqPisResult,
    aliqIcmsResult,
    bill_items: [
      {
        name: 'ENERGIA ELETRICA TE PONTA',
        consumption: consumoTEPontaResult,
        value: valueTEPontaResult,
      },
      {
        name: 'ENERGIA ELETRICA TE F PONTA',
        consumption: consumoTEFPontaResult,
        value: valueTEFPontaResult,
      },
      {
        name: 'ENERGIA ELETRICA USD PONTA',
        consumption: consumoTUSDPontaResult,
        value: valueTUSDPontaResult,
      },
      {
        name: 'ENERGIA ELETRICA USD F PONTA',
        consumption: consumoTUSDFPontaResult,
        value: valueTUSDFPontaResult,
      },
      {
        name: 'ENERGIA ELET CONSUMO',
        consumption: consumoB3TeResult,
        value: valueB3TeResult,
      },
      {
        name: 'ENERGIA ELET USO SISTEMA',
        consumption: consumoB3TusdResult,
        value: valueB3TusdResult,
      },
    ],
  };
}
const calculateTariff = (tarifa, aliq_cofins, aliq_pis, aliq_icms) => {
  if (
    typeof tarifa !== 'number' ||
    typeof aliq_cofins !== 'number' ||
    typeof aliq_pis !== 'number' ||
    typeof aliq_icms !== 'number'
  ) {
    throw new Error('Todos os parâmetros devem ser números');
  }

  aliq_cofins = aliq_cofins / 100;
  aliq_pis = aliq_pis / 100;
  aliq_icms = aliq_icms / 100;

  const result = tarifa / ((1 - (aliq_cofins + aliq_pis)) * (1 - aliq_icms));

  return result;
};

const filterByValidityPeriod = (data) => {
  const currentDate = new Date();

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const isValid = (item) => {
    const startDate = parseDate(item?.inicio_vigencia);
    const endDate = parseDate(item?.fim_vigencia);
    return startDate <= currentDate && currentDate <= endDate;
  };

  return data?.filter(isValid);
};

const filterBySubGrupoAndModalidade = (tariffs, subGrupo, modalidade) => {
  return tariffs.filter(
    (item) => item.subgrupo === subGrupo && item.modalidade === modalidade
  );
};

const getBorderColor = (concessionaire) => {
  switch (concessionaire) {
    case 'COPEL':
      return '#ec6500';
    case 'COCEL':
      return 'red';
    default:
      return '#FFF';
  }
};

export default function SimulatorContainer() {
  const { clientData } = useClientData();
  const { groupPermissions } = useGroupPermissions();
  const { consumerGroups } = useConsumerGroup();
  const { getSession } = useContext(AccountContext);
  const { allTariffs } = useTariffs();

  const userSession = LocalStorage.getSession();

  const [consumerGroupOptions, setConsumerGroupOptions] = useState();
  const [energyBills, setEnergyBills] = useState();
  const [averages, setAverages] = useState(null);
  const [selectedConsumerGroup, setSelectedConsumerGroup] = useState(null);
  const [simulatedValues, setSimulatedValues] = useState();
  const [subGrupo, setSubGrupo] = useState(selectedConsumerGroup?.subgrupo);
  const [afterSubGrupo, setAfterSubGrupo] = useState(
    selectedConsumerGroup?.subgrupo
  );
  const [afterModalidade, setAfterModalidade] = useState(
    selectedConsumerGroup?.modalidade
  );
  const [geradora, setGeradora] = useState(selectedConsumerGroup?.geradora);
  const [currentTariffs, setCurrentTariffs] = useState();

  const [error, setError] = useState('');
  const [
    selectedIdentificationNumber,
    setSelectedIdentificationNumber,
  ] = useState(null);

  const [modalidade, setModalidade] = useState(
    selectedConsumerGroup?.modalidade
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const filteredTariffs = filterByValidityPeriod(allTariffs);
    setCurrentTariffs(filteredTariffs);
  }, [allTariffs]);

  useEffect(() => {
    const consumerGroupOptions = consumerGroups?.map((consumerGroup) => ({
      value: consumerGroup?.identification_number,
      label: consumerGroup?.identification_number,
    }));

    setConsumerGroupOptions(consumerGroupOptions);
  }, [consumerGroups]);

  useEffect(() => {
    if (selectedConsumerGroup && selectedConsumerGroup.modalidade) {
      const modalidadeUppercase = selectedConsumerGroup.modalidade.toUpperCase();

      setModalidade(modalidadeUppercase);
      setAfterModalidade(modalidadeUppercase);
    }

    if (selectedConsumerGroup && selectedConsumerGroup.subgrupo) {
      const subGrupoUppercase = selectedConsumerGroup.subgrupo.toUpperCase();

      setSubGrupo(subGrupoUppercase);
      setAfterSubGrupo(subGrupoUppercase);
    }

    if (selectedConsumerGroup && selectedConsumerGroup.geradora) {
      const geradoraToUppercase = selectedConsumerGroup.geradora.toUpperCase();

      setGeradora(geradoraToUppercase);
    }
  }, [selectedConsumerGroup]);

  useEffect(() => {
    const averages = calculateAverage(energyBills);

    setAverages(averages);
  }, [energyBills]);

  const [hasReadPermission, setHasReadPermission] = useState(true);

  useEffect(() => {
    const readPath = ['faturas', 'simulador_faturas', 'leitura'];

    if (userSession && groupPermissions) {
      setLoading(true);
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
      setLoading(false);
    }
    setLoading(false);
  }, [userSession, groupPermissions]);

  async function getEnergyBills(identification_number) {
    try {
      const sessionData = await getSession();
      const response = await ioseAPI.getAllIdentificatioNumberBills(
        sessionData.headers,
        identification_number
      );

      if (response && response.data) {
        const { data } = response.data;
        setEnergyBills(data);
        setError('');
        return response;
      } else {
        setError('Resposta da API malformada');
        return null;
      }
    } catch (error) {
      console.error('Erro na chamada da API:', error);
      setError('Erro ao buscar dados de contas de energia.');
      throw error;
    }
  }

  const handleIdentificationNumberChange = async (value) => {
    setSelectedIdentificationNumber(value);

    const selectedConsumerGroup = consumerGroups?.find(
      (consumerGroup) => consumerGroup.identification_number === value
    );

    setSelectedConsumerGroup(selectedConsumerGroup);

    try {
      await getEnergyBills(value);
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleModalidadeChange = async (value) => {
    setModalidade(value);
  };

  const handleSubgrupoChange = async (value) => {
    setSubGrupo(value);
  };

  const handleAfterSubgrupoChange = async (value) => {
    setAfterSubGrupo(value);
  };

  const handleAfterModalidadeChange = async (value) => {
    setAfterModalidade(value);
  };

  const handleGeradoraChange = async (value) => {
    setGeradora(value);
  };

  const handleOnClick = (currentTariffs, subGrupo, modalidade) => {
    if (selectedConsumerGroup.subgrupo === subGrupo) {
      const filteredTariff = filterBySubGrupoAndModalidade(
        currentTariffs,
        subGrupo,
        modalidade
      );

      const billItems = averages?.bill_items;
      const simulatedValues = [];

      if (subGrupo === 'A4') {
        billItems.map((billItem) => {
          if (billItem.name === 'ENERGIA ELETRICA TE PONTA') {
            const TePontaTariff = filteredTariff.find(
              (tariff) => tariff.posto === 'PONTA' && tariff.tarifa === 'TE'
            );

            const TePontaCalculatedTariff = calculateTariff(
              TePontaTariff?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TePontaTariff) {
              const consumptionValue = parseFloat(
                billItem.consumption.replace(',', '.')
              );

              const resultValue = consumptionValue * TePontaCalculatedTariff;

              simulatedValues.push({
                name: 'ENERGIA ELETRICA TE PONTA',
                consumption: billItem.consumption,
                value: formatNumber(resultValue),
              });
            }
          }

          if (billItem.name === 'ENERGIA ELETRICA TE F PONTA') {
            // Encontrar o item correspondente em filteredTariff
            const TeForaPontaTariff = filteredTariff.find(
              (tariff) =>
                tariff.posto === 'FORA PONTA' && tariff.tarifa === 'TE'
            );

            const TeForaPontaCalculatedTariff = calculateTariff(
              TeForaPontaTariff?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TeForaPontaTariff) {
              const consumptionValue = parseFloat(
                billItem.consumption.replace(',', '.')
              );

              const resultValue =
                consumptionValue * TeForaPontaCalculatedTariff;

              simulatedValues.push({
                name: 'ENERGIA ELETRICA TE F PONTA',
                consumption: billItem.consumption,
                value: formatNumber(resultValue),
              });
            }
          }

          if (billItem.name === 'ENERGIA ELETRICA USD PONTA') {
            const TusdPontaTariff = filteredTariff.find(
              (tariff) => tariff.posto === 'PONTA' && tariff.tarifa === 'TUSD'
            );

            const TusdPontaCalculatedTariff = calculateTariff(
              TusdPontaTariff?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TusdPontaTariff) {
              const consumptionValue = parseFloat(
                billItem.consumption.replace(',', '.')
              );

              const resultValue = consumptionValue * TusdPontaCalculatedTariff;

              simulatedValues.push({
                name: 'ENERGIA ELETRICA USD PONTA',
                consumption: billItem.consumption,
                value: formatNumber(resultValue),
              });
            }
          }

          if (billItem.name === 'ENERGIA ELETRICA USD F PONTA') {
            const TusdForaPontaTariff = filteredTariff.find(
              (tariff) =>
                tariff.posto === 'FORA PONTA' && tariff.tarifa === 'TUSD'
            );

            const TusdForaPontaCalculatedTariff = calculateTariff(
              TusdForaPontaTariff?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TusdForaPontaTariff) {
              const consumptionValue = parseFloat(
                billItem.consumption.replace(',', '.')
              );

              const resultValue =
                consumptionValue * TusdForaPontaCalculatedTariff;

              simulatedValues.push({
                name: 'ENERGIA ELETRICA USD F PONTA',
                consumption: billItem.consumption,
                value: formatNumber(resultValue),
              });
            }
          }

          setSimulatedValues(simulatedValues);
        });
      } else if (subGrupo === 'B3') {
        billItems.map((billItem) => {
          if (billItem.name === 'ENERGIA ELET CONSUMO') {
            const TEB3 = filteredTariff.find(
              (tariff) =>
                tariff.modalidade === 'CONVENCIONAL' && tariff.tarifa === 'TE'
            );

            const TePontaCalculatedTariff = calculateTariff(
              TEB3?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TEB3) {
              const consumptionValue = parseFloat(
                billItem.consumption.replace('.', '').replace(',', '.')
              );

              const resultValue =
                consumptionValue * (TePontaCalculatedTariff / 1000);

              simulatedValues.push({
                name: 'ENERGIA ELET CONSUMO',
                consumption: billItem.consumption,
                value: formatNumber(resultValue),
              });
            }
          }

          if (billItem.name === 'ENERGIA ELET USO SISTEMA') {
            const TUDSB3 = filteredTariff.find(
              (tariff) =>
                tariff.modalidade === 'CONVENCIONAL' && tariff.tarifa === 'TUSD'
            );

            const TeForaPontaCalculatedTariff = calculateTariff(
              TUDSB3?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TUDSB3) {
              const consumptionValue = parseFloat(
                billItem.consumption.replace('.', '').replace(',', '.')
              );

              const resultValue =
                consumptionValue * (TeForaPontaCalculatedTariff / 1000);

              simulatedValues.push({
                name: 'ENERGIA ELET USO SISTEMA',
                consumption: billItem.consumption,
                value: formatNumber(resultValue),
              });
            }
          }

          setSimulatedValues(simulatedValues);
        });
      }
    } else {
      const filteredTariff = filterBySubGrupoAndModalidade(
        currentTariffs,
        subGrupo,
        modalidade
      );

      const billItems = averages?.bill_items;
      const simulatedValues = [];

      billItems?.forEach((billItem) => {
        if (subGrupo === 'B3' && selectedConsumerGroup.subgrupo === 'A4') {
          if (
            billItem.name === 'ENERGIA ELETRICA TE PONTA' ||
            billItem.name === 'ENERGIA ELETRICA TE F PONTA'
          ) {
            const consumptionValue = parseFloat(
              billItem.consumption.replace(/\./g, '').replace(',', '.')
            );

            const TEB3 = filteredTariff.find(
              (tariff) =>
                tariff.modalidade === 'CONVENCIONAL' && tariff.tarifa === 'TE'
            );

            const TEB3WithTributes = calculateTariff(
              TEB3?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TEB3) {
              let recalculatedValue =
                consumptionValue * (TEB3WithTributes / 1000);

              const existingItem = simulatedValues.find(
                (item) => item.name === 'ENERGIA ELET CONSUMO'
              );

              if (existingItem) {
                // Se já existe, soma os valores
                existingItem.consumption += consumptionValue;
                existingItem.value += recalculatedValue;
              } else {
                // Se não existe, cria um novo objeto
                simulatedValues.push({
                  name: 'ENERGIA ELET CONSUMO',
                  consumption: consumptionValue,
                  value: recalculatedValue,
                });
              }
            }
          } else if (
            billItem.name === 'ENERGIA ELETRICA USD PONTA' ||
            billItem.name === 'ENERGIA ELETRICA USD F PONTA'
          ) {
            const consumptionValue = parseFloat(
              billItem.consumption.replace(/\./g, '').replace(',', '.')
            );

            const TUSDB3 = filteredTariff.find(
              (tariff) =>
                tariff.modalidade === 'CONVENCIONAL' && tariff.tarifa === 'TUSD'
            );

            const TUSDB3WithTributes = calculateTariff(
              TUSDB3?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TUSDB3) {
              let recalculatedValue =
                consumptionValue * (TUSDB3WithTributes / 1000);

              const existingItem = simulatedValues.find(
                (item) => item.name === 'ENERGIA ELET USO SISTEMA'
              );

              if (existingItem) {
                // Se já existe, soma os valores
                existingItem.consumption += consumptionValue;
                existingItem.value += recalculatedValue;
              } else {
                // Se não existe, cria um novo objeto
                simulatedValues.push({
                  name: 'ENERGIA ELET USO SISTEMA',
                  consumption: consumptionValue,
                  value: recalculatedValue,
                });
              }
            }
          }
        } else if (
          subGrupo === 'A4' &&
          selectedConsumerGroup.subgrupo === 'B3'
        ) {
          if (billItem.name === 'ENERGIA ELET CONSUMO') {
            const consumptionValue = parseFloat(
              billItem.consumption.replace(/\./g, '').replace(',', '.')
            );

            const consumptionPontaPercentage = consumptionValue * 0.125;

            const TePontaTariff = filteredTariff.find(
              (tariff) => tariff.posto === 'PONTA' && tariff.tarifa === 'TE'
            );

            const TePontaWithTariffs = calculateTariff(
              TePontaTariff?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TePontaTariff) {
              let recalculatedValue =
                consumptionPontaPercentage * (TePontaWithTariffs / 1000);

              simulatedValues.push({
                name: 'ENERGIA ELETRICA TE PONTA',
                consumption: consumptionPontaPercentage,
                value: recalculatedValue,
              });
            }

            const consumptionForaPontaValue = parseFloat(
              billItem.consumption.replace(/\./g, '').replace(',', '.')
            );

            const consumptionForaPontaPercentage =
              consumptionForaPontaValue * 0.875;

            const TeForaPontaTariff = filteredTariff.find(
              (tariff) =>
                tariff.posto === 'FORA PONTA' && tariff.tarifa === 'TE'
            );

            const TeForaPontaWithTariffs = calculateTariff(
              TeForaPontaTariff?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TeForaPontaTariff) {
              let recalculatedValue =
                consumptionForaPontaPercentage *
                (TeForaPontaWithTariffs / 1000);

              simulatedValues.push({
                name: 'ENERGIA ELETRICA TE FORA PONTA',
                consumption: consumptionForaPontaPercentage,
                value: recalculatedValue,
              });
            }
          } else if (billItem.name === 'ENERGIA ELET USO SISTEMA') {
            const consumptionValue = parseFloat(
              billItem.consumption.replace(/\./g, '').replace(',', '.')
            );

            const consumptionPontaPercentage = consumptionValue * 0.125;

            const TusdPontaTariff = filteredTariff.find(
              (tariff) => tariff.posto === 'PONTA' && tariff.tarifa === 'TUSD'
            );

            const TusdPontaWithTariffs = calculateTariff(
              TusdPontaTariff?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TusdPontaTariff) {
              let recalculatedValue =
                consumptionPontaPercentage * (TusdPontaWithTariffs / 1000);

              simulatedValues.push({
                name: 'ENERGIA ELETRICA USD PONTA',
                consumption: consumptionPontaPercentage,
                value: recalculatedValue,
              });
            }

            const consumptionForaPontaValue = parseFloat(
              billItem.consumption.replace(/\./g, '').replace(',', '.')
            );

            const consumptionForaPontaPercentage =
              consumptionForaPontaValue * 0.875;

            const TusdForaPontaTariff = filteredTariff.find(
              (tariff) =>
                tariff.posto === 'FORA PONTA' && tariff.tarifa === 'TUSD'
            );

            const TusdForaPontaWithTariffs = calculateTariff(
              TusdForaPontaTariff?.valor,
              averages.aliqCofinsResult,
              averages.aliqPisResult,
              averages.aliqIcmsResult
            );

            if (TusdForaPontaTariff) {
              let recalculatedValue =
                consumptionForaPontaPercentage *
                (TusdForaPontaWithTariffs / 1000);

              simulatedValues.push({
                name: 'ENERGIA ELETRICA USD FORA PONTA',
                consumption: consumptionForaPontaPercentage,
                value: recalculatedValue,
              });
            }
          }
        }
      });

      setSimulatedValues(simulatedValues);
    }
  };

  // const renderDynamicTable = () => {
  //   return <DynamicTable />;
  // };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle="Simulador"
        description={''}
        button={<></>}
        style={{ margin: '0' }}
      >
        {/* <IoseSearch
          placeholder="Pesquisar Unidades…"
          funcSearch={filterUnity}
        /> */}
      </IoseSubHeaderBar>
    );
  };

  const renderFormHeader = () => {
    return (
      <S.FormHeaderContainer>
        <S.FormContainerPaper>
          <S.TitleSeparator>
            <S.Title>SELECIONE A U.C.</S.Title>
          </S.TitleSeparator>

          <S.FilterContainer>
            <Tooltip title={'U.C'} placement="top" arrow>
              <div>
                <IoseDynamicFilter
                  label={'U.C'}
                  value={selectedIdentificationNumber}
                  onChange={handleIdentificationNumberChange}
                  options={consumerGroupOptions}
                />
              </div>
            </Tooltip>

            <Tooltip title={'Subgrupo'} placement="top" arrow>
              <div>
                <IoseDynamicFilter
                  label={'Subgrupo'}
                  value={subGrupo}
                  onChange={handleSubgrupoChange}
                  options={SubgrupoOptions}
                  disabled={true}
                />
              </div>
            </Tooltip>

            <Tooltip title={'Modalidade'} placement="top" arrow>
              <div>
                <IoseDynamicFilter
                  label={'Modalidade'}
                  value={modalidade}
                  onChange={handleModalidadeChange}
                  options={ModalidadeOptions}
                  disabled={true}
                />
              </div>
            </Tooltip>

            <Tooltip title={'Geradora'} placement="top" arrow>
              <div>
                <IoseDynamicFilter
                  label={'Geradora'}
                  value={geradora}
                  onChange={handleGeradoraChange}
                  options={SimpleOptions}
                  disabled={true}
                />
              </div>
            </Tooltip>
          </S.FilterContainer>
        </S.FormContainerPaper>

        <S.FormContainerPaper>
          <S.TitleSeparator>
            <S.Title>PARÂMETROS PARA A SIMULAÇÃO</S.Title>
          </S.TitleSeparator>

          <S.FilterContainer>
            <Tooltip title={'Subgrupo'} placement="top" arrow>
              <div>
                <IoseDynamicFilter
                  label={'Subgrupo'}
                  value={afterSubGrupo}
                  onChange={handleAfterSubgrupoChange}
                  options={SubgrupoOptions}
                />
              </div>
            </Tooltip>

            <Tooltip title={'Modalidade'} placement="top" arrow>
              <div>
                <IoseDynamicFilter
                  label={'Modalidade'}
                  value={afterModalidade}
                  onChange={handleAfterModalidadeChange}
                  options={
                    afterSubGrupo === 'B3'
                      ? ModalidadeB3Options
                      : ModalidadeOptions
                  }
                />
              </div>
            </Tooltip>

            {/* <Tooltip title={'Geradora'} placement="top" arrow>
              <div>
                <IoseDynamicFilter
                  label={'Geradora'}
                  value={geradora}
                  onChange={handleGeradoraChange}
                  options={SimpleOptions}
                />
              </div>
            </Tooltip> */}

            <IoseButton
              onClick={() =>
                handleOnClick(currentTariffs, afterSubGrupo, afterModalidade)
              }
            >
              SIMULAR
            </IoseButton>
          </S.FilterContainer>
        </S.FormContainerPaper>
      </S.FormHeaderContainer>
    );
  };

  const renderBeforeTable = () => {
    const bill_items = averages?.bill_items;
    let filteredData;

    if (selectedConsumerGroup?.subgrupo === 'A4') {
      filteredData = bill_items?.filter(
        (item) =>
          item.name !== 'ENERGIA ELET CONSUMO' &&
          item.name !== 'ENERGIA ELET USO SISTEMA'
      );
    } else if (selectedConsumerGroup?.subgrupo === 'B3') {
      filteredData = bill_items?.filter(
        (item) =>
          item.name === 'ENERGIA ELET CONSUMO' ||
          item.name === 'ENERGIA ELET USO SISTEMA'
      );
    }

    return (
      <div style={{ width: '100%' }}>
        <IoseSimulatorTable data={filteredData} />
      </div>
    );
  };

  const renderAfterA4Table = () => {
    return (
      <div style={{ width: '100%' }}>
        <IoseSimulatorTable data={simulatedValues} />
      </div>
    );
  };

  const renderBeforeAfterSimulation = () => {
    return (
      <>
        <S.BillsContainer>
          <S.BillsContainerPaper
            borderColor={getBorderColor(selectedConsumerGroup?.concessionaire)}
          >
            <S.TitleSeparator>
              <S.Title>ANTES</S.Title>
            </S.TitleSeparator>

            {selectedConsumerGroup ? (
              <>
                <S.TextFieldContainer>
                  <IoseModalTextField
                    label="Endereço"
                    value={selectedConsumerGroup?.address}
                  />

                  {selectedConsumerGroup?.concessionaire === 'COPEL' && (
                    <S.LogoCOPEL
                      src="./img/copel-logo.jpg"
                      alt="Logo da COPEL"
                    />
                  )}

                  {selectedConsumerGroup?.concessionaire === 'COCEL' && (
                    <S.LogoCOCEL
                      src="./img/cocel-logo.jpg"
                      alt="Logo da COCEL"
                    />
                  )}
                </S.TextFieldContainer>

                <S.TextFieldContainer>
                  <IoseModalTextField
                    label="Atividade"
                    value={selectedConsumerGroup?.atividade}
                  />

                  <IoseModalTextField
                    label="Gerência Geral"
                    value={selectedConsumerGroup?.general_management}
                  />

                  <IoseModalTextField
                    label="Gerência Regional"
                    value={selectedConsumerGroup?.regional_management}
                  />
                </S.TextFieldContainer>

                <S.TextFieldContainer>
                  <IoseModalTextField
                    label="Demanda Ponta"
                    value={selectedConsumerGroup?.demanda_ponta}
                  />
                  <IoseModalTextField
                    label="Demanda Fora Ponta"
                    value={selectedConsumerGroup?.demanda_fora}
                  />

                  <IoseModalTextField
                    label="Tensão"
                    value={selectedConsumerGroup?.tensao}
                  />
                </S.TextFieldContainer>

                <S.TextFieldContainer>
                  <IoseModalTextField
                    label="Valor da Fatura"
                    value={averages?.accountValueResult}
                  />
                  <IoseModalTextField
                    label="ICMS"
                    value={averages?.icmsResult}
                  />
                  <IoseModalTextField
                    label="COFINS"
                    value={averages?.cofinsResult}
                  />
                  <IoseModalTextField label="PIS" value={averages?.pisResult} />
                  <IoseModalTextField
                    label="Grandeza Contratada"
                    value={averages?.contractedAmounts}
                  />
                </S.TextFieldContainer>

                {renderBeforeTable()}
              </>
            ) : null}
          </S.BillsContainerPaper>
          <S.BillsContainerPaper
            borderColor={getBorderColor(selectedConsumerGroup?.concessionaire)}
          >
            <S.TitleSeparator>
              <S.Title>DEPOIS</S.Title>
            </S.TitleSeparator>

            {selectedConsumerGroup ? (
              <>
                <S.TextFieldContainer>
                  <IoseModalTextField
                    label="Endereço"
                    value={selectedConsumerGroup?.address}
                  />

                  {selectedConsumerGroup?.concessionaire === 'COPEL' && (
                    <S.LogoCOPEL
                      src="./img/copel-logo.jpg"
                      alt="Logo da COPEL"
                    />
                  )}

                  {selectedConsumerGroup?.concessionaire === 'COCEL' && (
                    <S.LogoCOCEL
                      src="./img/cocel-logo.jpg"
                      alt="Logo da COCEL"
                    />
                  )}
                </S.TextFieldContainer>

                {renderAfterA4Table()}
              </>
            ) : null}
          </S.BillsContainerPaper>
        </S.BillsContainer>
      </>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <S.ContentContainer>
            {renderFormHeader()}
            {/* {selectedConsumerGroup ? renderIdentificationNumberInfo() : null}
            {selectedConsumerGroup ? renderCalculations() : null} */}
            {/* {renderDynamicTable()} */}
            {renderBeforeAfterSimulation()}
          </S.ContentContainer>
        </>
      );
    }
  };

  return (
    <S.WrapContainer>
      {loading ? (
        <LoadingIndicatorDashboard loading={loading} />
      ) : (
        <>
          {hasReadPermission ? (
            <>
              {renderSubHeaderAndSearchBar()}
              {renderContent()}
            </>
          ) : (
            <div style={{ marginTop: '35px' }}>
              <IoseAlertLoadingCards
                text={
                  'Seu grupo de usuários não tem permissão de acesso a este módulo.'
                }
              />
            </div>
          )}
        </>
      )}
    </S.WrapContainer>
  );
}

SimulatorContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
