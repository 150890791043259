// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useConcessionaires() {
  const [noConcessionairesError, setNoConcessionairesError] = useState('');

  const { getSession } = useContext(AccountContext);

  const page_size = 300;

  const getConcessionaires = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllConcessionaires(
      headers,
      10000,
      page_size
    );

    const concessionaires = response.data.data || [];

    if (concessionaires.length === 0) {
      setNoConcessionairesError(Messages.noRegisteredConcessionaires);
      throw new Error(Messages.noRegisteredConcessionaires);
    }
    return concessionaires;
  };

  const {
    data: concessionaires,
    error: concessionairesError,
    refetch: refreshConcessionaires,
    isLoading,
  } = useQuery(['concessionairesData'], getConcessionaires);

  return {
    concessionaires,
    concessionairesError,
    noConcessionairesError,
    refreshConcessionaires,
    isLoading,
  };
}

export default useConcessionaires;
