import styled from 'styled-components';

export const NavButton = styled.button`
  width: 2vw;
  background-color: #7f8496;
  opacity: 20%;
  border-radius: 5px;
  transition: 0.2s ease;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    opacity: 40%;

    & svg {
      transform: scale(2);
      transition: transform 0.2s ease;
    }
  }
`;
