export const defaultPermissions = {
  'cadastro': {
    'ambientes': {
      'criar': false,
      'deletar': false,
      'editar': false,
      'leitura': false,
    },
    'circuitos': {
      'leitura': false,
    },
    'concessionarias': {
      'criar': false,
      'deletar': false,
      'editar': false,
      'leitura': false,
    },
    'contratos': {
      'criar': false,
      'deletar': false,
      'editar': false,
      'leitura': false,
    },
    'ecu_solar': {
      'criar': false,
      'deletar': false,
      'editar': false,
      'leitura': false,
    },
    'objetos': {
      'criar': false,
      'deletar': false,
      'editar': false,
      'leitura': false,
    },
    'tarifas': {
      'criar': false,
      'leitura': false,
    },
    'unidades': {
      'criar': false,
      'deletar': false,
      'editar': false,
      'leitura': false,
    },
    'unidades_consumidoras': {
      'criar': false,
      'deletar': false,
      'editar': false,
      'leitura': false,
    },
    'fator_co2': {
      'criar': false,
      'leitura': false,
    },
  },
  'dashboards': {
    '3d': {
      'leitura': false,
    },
    'administrativo': {
      'leitura': false,
    },
    'comparacao_consumo': {
      'leitura': false,
    },
    'faturas': {
      'leitura': false,
    },
    'emissao_carbono': {
      'leitura': false,
    },
  },
  'faturas': {
    'buscar_faturas': {
      'leitura': false,
      'emitir_relatorio': false,
    },
    'recalculo_faturas': {
      'leitura': false,
      'emitir_relatorio': false,
    },
    'log_faturas': {
      'emitir_relatorio': false,
      'leitura': false,
    },
    'simulador_faturas': {
      'leitura': false,
    },
  },
  'geracao': {
    'dashboard_creditos': {
      'leitura': false,
    },
    'geradoras_beneficiarias': {
      'leitura': false,
      'criar': false,
    },
    'unidades_beneficiarias': {
      'leitura': false,
    },
    'unidades_geradoras': {
      'emitir_relatorio': false,
      'leitura': false,
    },
  },
  'gerenciamento': {
    'contratos': {
      'emitir_relatorio': false,
      'leitura': false,
    },
    'log_usuarios': {
      'emitir_relatorio': false,
      'leitura': false,
    },
  },
  'mapa': {
    'mapa_geo': {
      'leitura': false,
    },
  },
  'parametros': {
    'variaveis_sistema': {
      'leitura': false,
      'criar': false,
    },
  },
  'relatorios': {
    'biene': {
      'emitir_relatorio': false,
    },
    'creditos': {
      'emitir_relatorio': false,
    },
    'medicao': {
      'emitir_relatorio': false,
    },
    'sgf': {
      'emitir_relatorio': false,
    },
    'emissao_carbono': {
      'emitir_relatorio': false,
    },
    'itens_fatura': {
      'leitura': false,
      'emitir_relatorio': false,
    },
  },
  'telemetria': {
    'dashboard_telemetria': {
      'leitura': false,
    },
    'equipamento_telemetria': {
      'leitura': false,
      'emitir_relatorio': false,
    },
    'medicao_telemetria': {
      'leitura': false,
      'emitir_relatorio': false,
    },
    'mapa_telemetria': {
      'leitura': false,
    },
  },
};
