import React, { useEffect, useState } from 'react';

import {
  IoseAdmDashContainerCircuits,
  IoseAdmDashContainerSolar,
  IoseAdmDashContainerSwitchboard,
  IoseAdmDashContainerUnity,
  IoseAdmDashSubHeader,
  IoseAlertLoadingCards,
  LoadingIndicatorDashboard,
} from 'components';

import * as S from './style';

import {
  useUnityConsumption,
  useGroupsConsumption,
  useCircuitsConsumption,
  useClientData,
  useCircuits,
  useSummaryEnergyByEcuList,
  useGroupPermissions,
} from 'hooks';

import Permissions from 'common/permissions';
import { LocalStorage } from 'common';

export default function AdminDashboardContainer() {
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [hasReadPermission, setHasReadPermission] = useState(true);

  const { unityConsumption } = useUnityConsumption();
  const { groupsConsumption } = useGroupsConsumption();
  const { circuitsConsumption } = useCircuitsConsumption();
  const { uuidClient } = useClientData();
  const { circuits } = useCircuits(uuidClient);
  const { energyByEcu } = useSummaryEnergyByEcuList();

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['dashboards', 'administrativo', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  const renderSubHeader = () => {
    return <IoseAdmDashSubHeader />;
  };

  const renderSolarContainer = () => {
    return <IoseAdmDashContainerSolar ecuData={energyByEcu} />;
  };

  const renderUnityContainer = () => {
    return <IoseAdmDashContainerUnity data={unityConsumption} />;
  };

  const renderGroupContainer = () => {
    return (
      <IoseAdmDashContainerSwitchboard
        loading={loading}
        allCircuits={circuits}
        data={groupsConsumption}
      />
    );
  };

  const renderCircuitsContainer = () => {
    return (
      <IoseAdmDashContainerCircuits
        loading={loading}
        data={circuitsConsumption}
      />
    );
  };

  return (
    <S.WrapContainer>
      {loading ? (
        <LoadingIndicatorDashboard loading={loading} />
      ) : (
        <S.GridContainer
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <S.GridStyled container direction="row" justify="center">
            {renderSubHeader()}
            {hasReadPermission ? (
              <>
                {renderUnityContainer()}
                {renderSolarContainer()}
                {renderGroupContainer()}
                {renderCircuitsContainer()}
              </>
            ) : (
              <IoseAlertLoadingCards
                text={
                  'Seu grupo de usuários não tem permissão de leitura destes dados!'
                }
              />
            )}
          </S.GridStyled>
        </S.GridContainer>
      )}
    </S.WrapContainer>
  );
}
