import React from 'react';

import { DivPaper } from 'styles/defaultStyles';

import { IoseCardsEnergyBills } from 'components';

export default function EnergyBillsDashHeader({
  loading,
  auditValues,
  auditConsumptions,
  auditExtract,
  averages,
}) {
  return (
    <DivPaper>
      <IoseCardsEnergyBills
        loading={loading}
        auditValues={auditValues}
        auditConsumptions={auditConsumptions}
        auditExtract={auditExtract}
        averages={averages}
      />
    </DivPaper>
  );
}
