import ReactECharts from 'echarts-for-react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

export default function IoseRadioChartConsumptionComparision({ data }) {
  const [chartData, setChartData] = useState();

  useEffect(() => {
    formatChartData();
    //eslint-disable-next-line
  }, [data]);

  function formatChartData() {
    if (!data || data.length === 0) {
      setChartData(undefined);
      return;
    }

    let maxIndicatorValue = 0;

    const indicators = data?.map((object) => {
      const { bill_consumption, measured_consumption } = object;
      const maxValue = Math.max(bill_consumption, measured_consumption) + 0.1;

      if (maxValue > maxIndicatorValue) {
        maxIndicatorValue = maxValue;
      }

      return {
        name: 'UC ' + object.identification_number,
        max: maxValue,
      };
    });

    const series = [
      {
        name: '',
        type: 'radar',
        data: [
          {
            value: data?.map((obj) => obj.bill_consumption ?? 0),
            name: 'Consumo da conta',
            itemStyle: {
              color: '#015A9E',
            },
            lineStyle: {
              type: 'dashed',
            },
          },
          {
            value: data?.map((obj) => obj.measured_consumption ?? 0),
            name: 'Consumo do Iose',
            color: '#67C6E3',
            itemStyle: {
              color: '#67C6E3',
            },
          },
        ],
      },
    ];

    const options = {
      legend: {},
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          const value = params.value[0];
          const seriesName = params.name;
          return `<div><strong>${seriesName}</strong>: ${value.toLocaleString(
            'pt-BR'
          )}</div>`;
        },
      },
      dataZoom: [
        {
          startValue: '0',
        },
        {
          type: 'inside',
        },
      ],
      radar: {
        indicator: indicators,
        axisName: {
          color: '#fff',
          backgroundColor: '#666',
          borderRadius: 3,
          padding: [5, 5],
        },
      },
      series: series,
    };

    setChartData(options);
  }

  if (!chartData || !chartData.series) {
    return null; // ou qualquer outro componente ou mensagem de carregamento
  }

  return <ReactECharts option={chartData} style={{ height: 500 }} />;
}
