import { useContext, useState } from 'react';
import { Messages, ioseAPI, AccountContext } from '../common';
import { useQuery } from '@tanstack/react-query';

function useEnvironments() {
  const { getSession } = useContext(AccountContext);

  const [noEnvironmentsError, setNoEnvironmentsError] = useState('');

  const getEnvironments = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllEnvironment(headers, 10000, 300);

    const { data } = response.data;

    const environments = data || [];

    if (environments.length === 0) {
      setNoEnvironmentsError(Messages.noRegisteredEnvironments);
      throw new Error(Messages.noRegisteredEnvironments);
    }

    return environments;
  };

  const {
    data: environments,
    error: environmentsError,
    isLoading,
    refetch: refreshEnvironments,
  } = useQuery(['environmentsData'], getEnvironments);

  return {
    environments,
    environmentsError,
    noEnvironmentsError,
    refreshEnvironments,
    isLoading,
  };
}

export default useEnvironments;
