import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, MenuItem, Slide } from '@material-ui/core';

import * as S from './style';

import {
  IoseAlertErroModal,
  IoseAlertSuccessModal,
  IoseModalButton,
  TextFieldStyled,
} from 'components';

import { validationsForm } from 'common';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  blackText: {
    color: '#015A9E',
  },
  noMargin: {
    marginTop: '0px !important',
  },
  alert: {
    color: '#D60606',
  },
}));

const optionsConditions = [
  {
    value: 'boa',
    label: 'Boa',
  },
  {
    value: 'media',
    label: 'Média',
  },
  {
    value: 'ruim',
    label: 'Ruim',
  },
];

const optionsChargeType = [
  {
    value: 'fixo',
    label: 'Fixo',
  },
  {
    value: 'variavel',
    label: 'Variável',
  },
];

export default function IoseObjectEditModal({
  open,
  onClose,
  loading,
  environments,
  allCircuits,
  selectedObject,
  editObject,
  notification,
  editNotificationType,
  showNotification,
  unitys,
}) {
  const [selectedCondition, setSelectedCondition] = useState(
    selectedObject?.condition
  );

  const [selectedChargeType, setSelectedChargeType] = useState(
    selectedObject?.charge_type
  );

  const [selectedEnvironmentUnity, setSelectedEnvironmentUnity] = useState(
    selectedObject?.uuid_unity ? selectedObject?.uuid_unity : ''
  );

  const [selectedEnvironment, setSelectedEnvironment] = useState('');

  const [selectedCircuit, setSelectedCircuit] = useState('');

  useEffect(() => {
    setSelectedCondition(selectedObject?.condition);
  }, [selectedObject]);

  useEffect(() => {
    setSelectedChargeType(selectedObject?.charge_type);
  }, [selectedObject]);

  useEffect(() => {
    setSelectedEnvironmentUnity(selectedObject?.uuid_unity);
  }, [selectedObject]);

  useEffect(() => {
    setSelectedEnvironment(selectedObject?.uuid_environment || '');
  }, [selectedObject]);

  useEffect(() => {
    setSelectedCircuit(selectedObject?.uuid_circuit || '');
  }, [selectedObject]);

  const classes = useStyles();

  const handleEnvironmentChange = (event) => {
    const selectedEnvironmentUuid = event.target.value;
    const selectedEnvironment = environments.find(
      (env) => env.uuid_environment === selectedEnvironmentUuid
    );

    setSelectedEnvironment(selectedEnvironmentUuid);
    setSelectedEnvironmentUnity(selectedEnvironment.uuid_unity);
  };

  const handleCircuitChange = (event) => {
    const value = event.target.value;
    if (value === 'remove') {
      setSelectedCircuit('');
    } else {
      setSelectedCircuit(value);
    }
  };

  const handleConditionChange = (event) => {
    setSelectedCondition(event.target.value);
  };

  const handleChargeTypeChange = (event) => {
    setSelectedChargeType(event.target.value);
  };

  const handleUpdateObject = (values) => {
    setTimeout(() => {
      const uuidObject = selectedObject.uuid_object;
      const uuidEnvironment = selectedEnvironment;
      const uuidCircuit = selectedCircuit;
      const objectName = values.name;
      const objectVoltage = parseFloat(
        values.voltage.toString().replace(',', '.')
      );
      const objectPower = parseFloat(values.power.toString().replace(',', '.'));
      const objectCurrent = parseFloat(
        values.current.toString().replace(',', '.')
      );
      const objectTemperature = parseFloat(
        values.temperature.toString().replace(',', '.')
      );
      const objectConditions = selectedCondition;
      const objectChargeType = selectedChargeType;
      const object3dId = values.id_3d;

      editObject(
        uuidObject,
        uuidEnvironment,
        uuidCircuit,
        objectName,
        objectVoltage,
        objectPower,
        objectCurrent,
        objectTemperature,
        objectConditions,
        objectChargeType,
        object3dId
      );
    }, 500);
  };

  //Schema validation for UnityAdd
  const ObjectAddSchema = Yup.object().shape({
    name: validationsForm.name,
    voltage: validationsForm.float,
    power: validationsForm.float,
    current: validationsForm.float,
    temperature: validationsForm.float,
    id_3d: validationsForm.id_3d,
  });

  const filteredCircuits = allCircuits?.filter(
    (circuit) => circuit.uuid_unity === selectedEnvironmentUnity
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide direction="up" in={open} timeout={600} mountOnEnter unmountOnExit>
        <div>
          {/* Cabeçalho */}
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <S.ObjectIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                OBJETOS
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
          </S.StyledHeaderModal>

          {/* Formulário */}
          <S.StyledWrappedModal editNotificationType={editNotificationType}>
            <div className={classes.paper}>
              <S.ModalTitle variant="h5" gutterBottom>
                EDITAR OBJETO
              </S.ModalTitle>
              <Formik
                initialValues={{
                  uuid_environment: selectedObject?.uuid_environment,
                  uuid_circuit: selectedObject?.uuid_circuit,
                  name: selectedObject?.name,
                  voltage: selectedObject?.voltage,
                  power: selectedObject?.power,
                  current: selectedObject?.current,
                  temperature: selectedObject?.temperature,
                  condition: selectedObject?.condition,
                  id_3d: selectedObject?.id_3d,
                }}
                validationSchema={ObjectAddSchema}
                onSubmit={handleUpdateObject}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  initialValues,
                  touched,
                  errors,
                }) => (
                  <Form>
                    <S.FormContainer>
                      <S.WrappedItens>
                        <S.StyledTextInputs>
                          <S.StyledFormControl fullWidth>
                            <S.StyledInputLabel id="environment-label">
                              Ambiente
                            </S.StyledInputLabel>
                            <Field
                              as={S.StyledSelect}
                              name="uuid_environment"
                              labelId="environment-label"
                              id="environment-select"
                              value={selectedEnvironment}
                              onChange={handleEnvironmentChange}
                              className={classes.whiteText}
                            >
                              {environments?.map((environments) => (
                                <MenuItem
                                  key={environments?.uuid_environment}
                                  value={environments?.uuid_environment}
                                >
                                  {environments?.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </S.StyledFormControl>
                        </S.StyledTextInputs>

                        <S.StyledTextInputs>
                          <S.StyledFormControl fullWidth>
                            <S.StyledInputLabel id="circuit-label">
                              Circuito
                            </S.StyledInputLabel>
                            <Field
                              as={S.StyledSelect}
                              name="uuid_circuit"
                              labelId="circuit-label"
                              id="circuit-select"
                              value={selectedCircuit}
                              onChange={handleCircuitChange}
                              className={classes.whiteText}
                            >
                              {filteredCircuits.length > 0 ? (
                                filteredCircuits.map((circuit) => (
                                  <MenuItem
                                    key={circuit.uuid_circuit}
                                    value={circuit.uuid_circuit}
                                  >
                                    {circuit.uuid_circuit}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  Não existem circuitos nesta unidade!
                                </MenuItem>
                              )}
                              <MenuItem
                                value="remove"
                                className={classes.alert}
                              >
                                Remover circuito do objeto!
                              </MenuItem>
                            </Field>
                          </S.StyledFormControl>
                        </S.StyledTextInputs>

                        <S.StyledTextInputs>
                          <Field
                            component={TextFieldStyled}
                            fullWidth
                            id={'name'}
                            name={'name'}
                            type={'text'}
                            label={'Nome'}
                            defaultvalue={initialValues['name']}
                            onchange={handleChange}
                            onblur={handleBlur}
                          />
                        </S.StyledTextInputs>

                        <S.StyledTextInputs>
                          <Field
                            component={TextFieldStyled}
                            fullWidth
                            id={'voltage'}
                            name={'voltage'}
                            type={'numeric'}
                            label={'Tensão (V)'}
                            defaultvalue={initialValues['voltage']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['voltage'] && touched['voltage']
                                ? errors['voltage']
                                : ''
                            }
                          />
                        </S.StyledTextInputs>
                      </S.WrappedItens>

                      <S.WrappedItens>
                        <S.StyledTextInputs>
                          <Field
                            component={TextFieldStyled}
                            fullWidth
                            id={'power'}
                            name={'power'}
                            type={'numeric'}
                            label={'Potência (W)'}
                            defaultvalue={initialValues['power']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['power'] && touched['power']
                                ? errors['power']
                                : ''
                            }
                          />
                        </S.StyledTextInputs>

                        <S.StyledTextInputs>
                          <Field
                            component={TextFieldStyled}
                            fullWidth
                            id={'current'}
                            name={'current'}
                            type={'numeric'}
                            label={'Corrente (A)'}
                            defaultvalue={initialValues['current']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['current'] && touched['current']
                                ? errors['current']
                                : ''
                            }
                          />
                        </S.StyledTextInputs>

                        <S.StyledTextInputs>
                          <Field
                            component={TextFieldStyled}
                            fullWidth
                            id={'temperature'}
                            name={'temperature'}
                            type={'numeric'}
                            label={'Temperatura de funcionamento (°C)'}
                            defaultvalue={initialValues['temperature']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['temperature'] && touched['temperature']
                                ? errors['temperature']
                                : ''
                            }
                          />
                        </S.StyledTextInputs>

                        <S.StyledTextInputs>
                          <Field
                            component={TextFieldStyled}
                            fullWidth
                            id={'id_3d'}
                            name={'id_3d'}
                            type={'text'}
                            label={'ID 3D'}
                            defaultvalue={initialValues['id_3d']}
                            onchange={handleChange}
                            onblur={handleBlur}
                            helpertext={
                              errors['id_3d'] && touched['id_3d']
                                ? errors['id_3d']
                                : ''
                            }
                          />
                        </S.StyledTextInputs>
                      </S.WrappedItens>
                    </S.FormContainer>

                    <S.StyledOptionsInputs>
                      <S.StyledFormControl fullWidth>
                        <S.StyledFormLabel component="legend">
                          Condição do objeto
                        </S.StyledFormLabel>
                        <S.StyledRadioGroup name="condition" row>
                          {optionsConditions.map((option) => (
                            <S.StyledFormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<S.StyledRadio />}
                              label={option.label}
                              checked={selectedCondition === option.value}
                              onChange={handleConditionChange}
                            />
                          ))}
                        </S.StyledRadioGroup>
                      </S.StyledFormControl>
                    </S.StyledOptionsInputs>

                    <S.StyledOptionsInputs>
                      <S.StyledFormControl fullWidth>
                        <S.StyledFormLabel component="legend">
                          Tipo de carga
                        </S.StyledFormLabel>
                        <S.StyledRadioGroup name="charge-type" row jus>
                          {optionsChargeType.map((option) => (
                            <S.StyledFormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<S.StyledRadio />}
                              label={option.label}
                              checked={selectedChargeType === option.value}
                              onChange={handleChargeTypeChange}
                            />
                          ))}
                        </S.StyledRadioGroup>
                      </S.StyledFormControl>
                    </S.StyledOptionsInputs>

                    {loading ? (
                      <S.DivCircularProgress>
                        <S.CircularProgressStyled />
                      </S.DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {editNotificationType === 1 && (
                          <IoseAlertSuccessModal text={notification} />
                        )}

                        {editNotificationType === 2 && (
                          <IoseAlertErroModal text={notification} />
                        )}
                      </>
                    ) : (
                      <S.ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Editar objeto
                        </IoseModalButton>
                      </S.ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </S.StyledWrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}
