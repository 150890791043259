import { useContext } from 'react';
import { Messages, ioseAPI, AccountContext } from '../common';
import { useQuery } from '@tanstack/react-query';

function useAuditDifference() {
  const { getSession } = useContext(AccountContext);

  const getRecalc = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllAuditDifference(headers, 10000, 300);

    const { data } = response.data;

    const differences = data || [];

    if (differences.length === 0) {
      console.error(Messages.noFindRecalc);
      throw new Error(Messages.noFindRecalc);
    }

    return differences;
  };

  const { data: differences, error: RecalcError, isLoading } = useQuery(
    ['differences'],
    getRecalc
  );

  return { differences, RecalcError, isLoading };
}

export default useAuditDifference;
