import React, { useEffect, useState, useContext } from 'react';
import { AccountContext, ioseAPI, LocalStorage, useClient } from 'common';
import { FilterText, FilterWrappedContainer, WrappedContainer } from './style';
import { useConsumerGroup } from 'hooks';
import * as S from './style';

import {
  IoseAlertLoadingCards,
  IoseButton,
  IoseSubHeaderBar,
  LoadingIndicator,
  NoDataMessage,
} from 'components';
import { GeradorXBeneficiariaTable } from 'components/IoseTables';
import IoseFilterCredit from 'components/IoseFilterCredit';
import {
  useCreditConnections,
  useBeneficiaryJoin,
  useGroupPermissions,
} from 'hooks';
import Permissions from 'common/permissions';

export default function GeradorasXBeneficiariasContainer() {
  const { client } = useClient();
  const [clientData, setClientData] = useState({});
  const { getSession } = useContext(AccountContext);
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  const [ucGeradora, setucGeradora] = useState(null);
  const [ucBeneficiaria, setucBeneficiaria] = useState(0);
  const [group, setGroup] = useState();
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [porcentagemValue, setPorcentagemValue] = useState('');
  const [estimativaInicio, setEstimativaInicio] = useState('');
  const [estimativaFim, setEstimativaFim] = useState('');
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState(true);

  const [searchQuery, setSearchQuery] = useState('');

  const {
    creditConnectionData,
    refreshCreditConnection,
  } = useCreditConnections();

  const { BeneficiaryJoinData } = useBeneficiaryJoin();

  const { consumerGroups } = useConsumerGroup();

  useEffect(() => {
    const readPath = ['geracao', 'geradoras_beneficiarias', 'leitura'];
    const addPath = ['geracao', 'geradoras_beneficiarias', 'criar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);
    }
  }, [userSession, groupPermissions]);

  const creditConnection = creditConnectionData ? creditConnectionData : [];
  const creditJoin = BeneficiaryJoinData ? BeneficiaryJoinData : [];

  const consumerGroupFiltrado = consumerGroups ? consumerGroups : [];

  const sessionData = LocalStorage.getSession();
  let userName = sessionData?.name;

  const filteredDataBeneficiario = creditConnection.map(
    (item) => item.uc_beneficiaria
  );
  const filteredDataGeradora = creditConnection.map((item) => item.uc_geradora);

  const creditoBeneficiarioDifente = consumerGroupFiltrado.filter(
    (group) =>
      !filteredDataBeneficiario.includes(group.identification_number) &&
      !filteredDataGeradora.includes(group.identification_number)
  );

  const filteredDataBeneficiario2 = creditoBeneficiarioDifente.map(
    (item) => item.identification_number
  );

  const consumerGroupsOptions = consumerGroups
    ?.filter((item) => item.beneficiaria === 'SIM' || item.geradora === 'SIM')
    .map((item) => ({
      value: item.identification_number,
      label: item.identification_number,
    }));
  const consumerGroupsOptionsGeradora = consumerGroups
    ?.filter((item) => item.geradora === 'SIM')
    .map((item) => ({
      value: item.identification_number,
      label: item.identification_number,
    }));

  const filteredDataGerador = creditConnection?.map((item) => item.uc_geradora);

  const uniqueValues = [...new Set(filteredDataGerador)];

  const filteredBeneficiarios = creditConnection?.filter(
    (item) => item.uc_geradora === ucGeradora
  );

  let somaPorcentagens = 0;
  for (const geradora of filteredBeneficiarios) {
    const porcentagemComoNumero = parseFloat(geradora.porcentagem);
    somaPorcentagens += porcentagemComoNumero;
  }

  const now = new Date();
  const formattedDate = now.toLocaleDateString('pt-BR');
  const formattedTime = now.toLocaleTimeString('pt-BR');
  const dt_cadastroValue = `${formattedDate} ${formattedTime}`;

  const withDtCadastro = creditConnection.filter((item) => item.dt_cadastro);
  const withoutDtCadastro = creditConnection.filter(
    (item) => !item.dt_cadastro
  );

  withDtCadastro.sort(
    (a, b) => new Date(b.dt_cadastro) - new Date(a.dt_cadastro)
  );

  const sortedCreditConnection = [...withDtCadastro, ...withoutDtCadastro];

  const usuarioValue = userName;

  useEffect(() => {
    if (ucGeradora !== null || ucBeneficiaria !== null) {
      setIsFilterEnabled(true);
    } else {
      setIsFilterEnabled(false);
    }
  }, [ucGeradora, ucBeneficiaria]);

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    }

    return LocalStorage.getClientData();
  };

  const getClientData = async () => {
    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };
  useEffect(() => {
    getClientData();
    //eslint-disable-next-line
  }, []);

  const handlePorcentagemChange = (event) => {
    setPorcentagemValue(event.target.value);
  };

  const handleEstimativaInicio = (event) => {
    setEstimativaInicio(event.target.value);
  };
  const handleEstimativaFim = (event) => {
    setEstimativaFim(event.target.value);
  };

  useEffect(() => {
    if (creditConnectionData && creditConnectionData.length > 0) {
      setLoading(true);

      setLoading(false);
      setShowTable(true);
    }
  }, [creditConnectionData]);

  async function addCredit(
    beneficiaryUC,
    generatorUC,
    referenceMonth,
    startEstimate,
    percentage,
    user,
    registrationDate,
    endEstimate
  ) {
    try {
      const sessionData = await getSession();
      const response = await ioseAPI.createCreditConnections(
        sessionData.headers,
        beneficiaryUC,
        generatorUC,
        referenceMonth,
        endEstimate,
        percentage,
        user,
        registrationDate,
        startEstimate
      );

      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error('Erro ao adicionar crédito:', err);
      throw err;
    }
  }

  const handleAddCredit = async () => {
    if (ucGeradora && ucBeneficiaria && porcentagemValue) {
      const newPorcentagem = parseFloat(porcentagemValue);
      const totalPorcentagens = somaPorcentagens + newPorcentagem;

      if (totalPorcentagens <= 100) {
        try {
          setLoading(true);
          const matchingJoinItem = creditJoin.find(
            (item) => item.uc_geradora === ucGeradora
          );
          const selectedEstimativaFim =
            estimativaFim || (matchingJoinItem && matchingJoinItem.indicador);
          await addCredit(
            ucBeneficiaria,
            ucGeradora,
            formattedDate,
            estimativaInicio,
            porcentagemValue,
            usuarioValue,
            dt_cadastroValue,
            selectedEstimativaFim
          );

          setShowTable(true);
          refreshCreditConnection();
        } catch (err) {
          // Handle error
        } finally {
          setLoading(false);
        }
        if (totalPorcentagens >= 100) {
        } else {
        }
      } else {
      }
    }
  };

  const handleGeradoraChange = (selectedOption) => {
    if (selectedOption) {
      const ucGeradora = selectedOption.value;
      setucGeradora(ucGeradora);
    }
  };

  const handleBeneficiariaChange = (selectedOption) => {
    if (selectedOption) {
      const ucBeneficiaria = selectedOption.value;
      setucBeneficiaria(ucBeneficiaria);

      const foundBeneficiaria = filteredDataBeneficiario2.find(
        (item) => item.identification_number === ucBeneficiaria
      );

      if (foundBeneficiaria) {
      } else {
      }
    } else {
      setucBeneficiaria(null);
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle={`Geradoras X Beneficiárias`}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        ></div>
      </IoseSubHeaderBar>
    );
  };

  const renderFilters = () => {
    return (
      <FilterWrappedContainer style={{ width: '1094px' }}>
        <div>
          <FilterText>Adicionar Beneficiária:</FilterText>
        </div>
        <div>
          <IoseFilterCredit
            label="U.C Geradora"
            value={ucGeradora}
            onChange={handleGeradoraChange}
            options={consumerGroupsOptionsGeradora}
          />
          <div style={{ position: 'relative' }}>
            <p
              style={{
                position: 'absolute',
                fontSize: '10px',
                color: somaPorcentagens === 100 ? 'red' : 'gray',
                bottom: '-15px',
              }}
            >
              {somaPorcentagens ? `${somaPorcentagens} %` : ''}
            </p>
          </div>
        </div>

        <div>
          <IoseFilterCredit
            label="U.C Beneficiária"
            value={ucBeneficiaria}
            onChange={handleBeneficiariaChange}
            options={consumerGroupsOptions}
          />
        </div>
        <div style={{ paddingTop: '21px' }}>
          <input
            placeholder="%"
            title="porcentagem"
            style={{
              height: '23px',
              width: '46px',
              borderBottom: '1px solid gray',
              transition: 'border-color 0.3s',
              cursor: 'text',
              paddingBottom: '6px',
              outline: 'none',
            }}
            value={porcentagemValue}
            onChange={handlePorcentagemChange}
            onMouseOver={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseOut={(e) => {
              e.target.style.borderBottom = '1px solid gray';
            }}
          />
        </div>
        <div style={{ paddingTop: '2px' }}>
          <label
            style={{
              color: 'gray',
              fontSize: '12px',
              paddingBottom: '5px',
            }}
          >
            estimativa de início
          </label>
          <input
            placeholder="estimativa de início"
            type="date"
            title="estimativa de início"
            style={{
              fontSize: '12.2px',
              height: '23px',
              width: '129px',
              borderBottom: '1px solid gray',
              transition: 'border-color 0.3s',
              cursor: 'text',
              paddingBottom: '7px',
              outline: 'none',
            }}
            value={estimativaInicio}
            onChange={handleEstimativaInicio}
            onMouseOver={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseOut={(e) => {
              e.target.style.borderBottom = '1px solid gray';
            }}
          />
        </div>
        <div style={{ paddingTop: '2px' }}>
          <label
            style={{
              color: 'gray',
              fontSize: '12px',
            }}
          >
            estimativa de fim
          </label>
          <input
            placeholder="estimativa de fim"
            type="date"
            title="estimativa de fim"
            style={{
              fontSize: '12.2px',
              height: '23px',
              width: '129px',
              borderBottom: '1px solid gray',
              transition: 'border-color 0.3s',
              cursor: 'text',
              paddingBottom: '7px',
              outline: 'none',
            }}
            value={estimativaFim}
            onChange={handleEstimativaFim}
            onMouseOver={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseOut={(e) => {
              e.target.style.borderBottom = '1px solid gray';
            }}
          />
        </div>
        <div>
          <IoseButton
            onClick={() => handleAddCredit()}
            disabled={!isFilterEnabled || somaPorcentagens >= 100}
          >
            ADICIONAR
          </IoseButton>
        </div>
      </FilterWrappedContainer>
    );
  };

  const renderTableorImage = () => {
    const filteredData = sortedCreditConnection.filter(
      (item) =>
        item.uc_geradora.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.uc_beneficiaria.toLowerCase().includes(searchQuery.toLowerCase())
    );
    if (showTable) {
      if (uniqueValues.length > 0) {
        return (
          <div>
            <S.TableHeaderBox>
              <S.DivSearch>
                <S.InputBaseStyled
                  type="text"
                  placeholder="Pesquisar por Unidade Consumidora..."
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
                <S.SearchIconStyled />
              </S.DivSearch>
            </S.TableHeaderBox>
            {loading ? (
              <LoadingIndicator loading={loading} />
            ) : (
              <GeradorXBeneficiariaTable
                loading={loading}
                data={filteredData}
              />
            )}
          </div>
        );
      } else {
        return (
          <div>
            <NoDataMessage message="Desculpe, não foram encontrados unidades consumidoras com os critérios de filtro informados. Por favor, revise suas seleções e tente novamente." />
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {loading ? (
            <LoadingIndicator loading={loading} />
          ) : (
            <>
              {hasAddPermission ? renderFilters() : null}
              {renderTableorImage()}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <WrappedContainer>
      {renderSubHeaderAndSearchBar()}
      {hasReadPermission ? (
        renderContent()
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão de leitura destes dados!'
          }
        />
      )}
    </WrappedContainer>
  );
}
