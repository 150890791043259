import React, { useEffect, useState, useContext } from 'react';

import { AccountContext, useClient, LocalStorage, ioseAPI } from 'common';

import {
  FilterText,
  FilterWrappedContainer,
  ReportLoadingText,
  WrappedContainer,
} from './style';

import { useHistory } from 'react-router-dom';

import {
  IoseButton,
  IoseSubHeaderBar,
  IoseMapRedirectButton,
  LoadingIndicator,
  NoDataMessage,
  ReportMessage,
  IoseSelectReferenceMonth,
  IoseAlertLoadingCards,
} from 'components';

import { useCreateLogs, useGroupPermissions } from 'hooks';

import Permissions from 'common/permissions';

// Função para formatar a data no padrão brasileiro (MM/YYYY)
const formatToReferenceMonth = (date) => {
  if (date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}${month}`;
  }
  return '';
};

function formatDataToTxt(array) {
  const formatNumberWithZero = (num) => {
    if (typeof num !== 'number') {
      return 'NaN';
    }

    const numWithoutDecimal = num.toString().replace('.', '');

    return numWithoutDecimal.padStart(15, '0');
  };

  const formatNumberWith3Zero = (num) => {
    return num === 0 ? '0'.repeat(3) : num.toString().padStart(3, '0');
  };

  let txtData = '';
  array.forEach((item) => {
    const {
      reference_month,
      unidade,
      local,
      kwh_faturado_agua,
      kwh_medido_esgoto_s,
      kwh_medido_industrial_s,
      kwh_fat_administrativo_s,
      kwh_faturado_industrial_s,
      kwh_medido_administrativo_s,
      kwh_medido_agua,
      kwh_faturado_esgoto,
    } = item;

    const formattedData = [
      reference_month,
      formatNumberWith3Zero(unidade),
      formatNumberWith3Zero(local),
      formatNumberWithZero(kwh_faturado_agua),
      formatNumberWithZero(kwh_medido_agua),
      formatNumberWithZero(kwh_faturado_esgoto),
      formatNumberWithZero(kwh_medido_esgoto_s),
      formatNumberWithZero(kwh_faturado_industrial_s),
      formatNumberWithZero(kwh_medido_industrial_s),
      formatNumberWithZero(kwh_fat_administrativo_s),
      formatNumberWithZero(kwh_medido_administrativo_s),
    ].join(';');

    txtData += formattedData + '\n';
  });

  return txtData;
}

function downloadTxtFile(content) {
  const contentWithCRLF = content.replace(/\n/g, '\r\n');

  const element = document.createElement('a');

  // Obter a data atual
  const currentDate = new Date();
  const formattedDate = currentDate
    .toISOString()
    .substring(0, 10)
    .replace(/-/g, ''); // Remover hífens
  const formattedTime = currentDate
    .toTimeString()
    .substring(0, 5)
    .replace(':', ''); // Formato: HHMM
  const fileName = `PSISSGF00-${formattedDate}-${formattedTime}.txt`;

  const file = new Blob([contentWithCRLF], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = fileName;

  document.body.appendChild(element); // Necessário para funcionar no Firefox
  element.click();
}

const handleDownload = (data) => {
  const formattedData = formatDataToTxt(data);
  downloadTxtFile(formattedData);
};

export default function SGFExportContainer() {
  const [clientData, setClientData] = useState({});
  const [group, setGroup] = useState();
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [referenceMonth, setReferenceMonth] = useState(null);
  const [noDataError, setNoDataError] = useState(false);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();
  const history = useHistory();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const reportPath = ['relatorios', 'sgf', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDate !== null) {
      setIsFilterEnabled(true);
    } else {
      setIsFilterEnabled(false);
    }
  }, [selectedDate]);

  function redirectToMapPage() {
    history.push('/map');
  }

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    }

    return LocalStorage.getClientData();
  };

  const getClientData = async () => {
    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const handleReferenceMonthChange = (date) => {
    const formattedDate = formatToReferenceMonth(date);

    setSelectedDate(date);
    setReferenceMonth(formattedDate);
  };

  const getAndExportSGF = async () => {
    setLoading(true);
    const { headers } = getSession();

    const response = await ioseAPI.getSGFForExport(headers, referenceMonth);

    const { data } = response;
    const SGFData = data[0] === null ? [] : data;

    const operacao = 'EMITIR RELATÓRIO';
    const form = 'RELATÓRIO SGF';
    const logContent = {
      reference_month: referenceMonth,
    };

    await createLog(operacao, form, logContent);

    if (SGFData.length === 0) {
      setLoading(false);
      setSelectedDate(null);
      setReferenceMonth(null);
      setNoDataError(true);
    } else {
      setLoading(false);
      setSelectedDate(null);
      setReferenceMonth(null);
      handleDownload(SGFData);
    }

    setTimeout(() => {
      setNoDataError(false);
    }, 5000);
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle={`Exportação TXT SISWEB`}
        // description={'Total: ' + allContracts.length}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <IoseMapRedirectButton
            tooltip="IR PARA MAPA DE UNIDADES CONSUMIDORAS"
            bottom="5px"
            onClick={() => redirectToMapPage()}
          />
        </div>
      </IoseSubHeaderBar>
    );
  };

  const renderFilters = () => {
    return (
      <FilterWrappedContainer>
        <div>
          <FilterText>Filtro:</FilterText>
        </div>

        <div>
          <IoseSelectReferenceMonth
            label="REF: MÊS/ANO"
            handleChange={handleReferenceMonthChange}
            selectedDate={selectedDate}
          />
        </div>

        <div>
          <IoseButton
            onClick={() => getAndExportSGF()}
            disabled={!isFilterEnabled}
          >
            EXPORTAR TXT
          </IoseButton>
        </div>
      </FilterWrappedContainer>
    );
  };

  const renderTableorImage = () => {
    return (
      <div>
        <div>
          {noDataError ? (
            <NoDataMessage message="Ops! Nenhum dado encontrado. Por favor, verifique se as configurações do filtro estão corretas e tente novamente." />
          ) : (
            <ReportMessage message="Para emitir o txt do SISWEB, selecione o mês desejado e clique em EXPORTAR TXT." />
          )}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {loading ? (
            <>
              <LoadingIndicator loading={loading} />
              <ReportLoadingText>
                Carregando Relatório... Por favor, aguarde enquanto preparamos o
                arquivo.
              </ReportLoadingText>
            </>
          ) : (
            <>
              {renderFilters()}
              {renderTableorImage()}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <WrappedContainer>
      {renderSubHeaderAndSearchBar()}
      {hasReportPermission ? (
        renderContent()
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão para emissão deste relatório!'
          }
        />
      )}
    </WrappedContainer>
  );
}
