import React, { useState } from 'react';
import { TableBody, TableCell, Paper, Grow } from '@material-ui/core';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { makeStyles } from '@material-ui/core/styles';
import { StyledTable, TableHeadStyled, TableRowStyled } from './style';
import NoDataMessage from 'components/Messages/NoDataMessage';
import IoseCircuitChartSanepar from 'components/IoseCircuitChartSanepar';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number?.toLocaleString('pt-br');
  return decimalNumber;
}

export default function BillDataTable({ loading, data, openEditItensModal }) {
  const handleRowClick = (rowData) => {
    const processedData = {};

    for (const key in rowData) {
      if (key.endsWith('_original')) {
        const originalFieldName = key.slice(0, -9);
        const originalValue = rowData[key];

        processedData[originalValue] = rowData[originalFieldName];
      }
    }

    const filterPatterns = [
      { pattern: /^item_description_/, name: 'item_description_' },
      { pattern: /^item_unity_/, name: 'item_unity_' },
      { pattern: /^item_consumption_/, name: 'item_consumption_' },
      { pattern: /^item_unitary_value_/, name: 'item_unitary_value_' },
      { pattern: /^item_total_value_/, name: 'item_total_value_' },
      { pattern: /^item_piscofins_\d+/, name: 'item_piscofins_' },
      { pattern: /^item_icms_\d+/, name: 'item_icms_' },
      { pattern: /^item_tax_/, name: 'item_tax_' },
    ];

    const filteredData = {};

    for (const patternInfo of filterPatterns) {
      for (const key in processedData) {
        if (patternInfo.pattern.test(key)) {
          filteredData[key] = processedData[key];
        }
      }
    }

    openEditItensModal(filteredData);
  };

  const TableRow = ({ value }) => (
    <TableRowStyled align="center" onClick={() => handleRowClick(value)}>
      <TableCellCentered>{value?.description}</TableCellCentered>
      <TableCellCentered>{value?.unity}</TableCellCentered>
      <TableCellCentered>
        {convertNumberToDecimalStyle(value?.consumption)}
      </TableCellCentered>
      <TableCellCentered>
        {convertNumberToDecimalStyle(value?.unitary_value)}
      </TableCellCentered>
      <TableCellCentered>
        {convertNumberToDecimalStyle(value?.total_value)}
      </TableCellCentered>
      <TableCellCentered>
        {convertNumberToDecimalStyle(value?.piscofins)}
      </TableCellCentered>
      <TableCellCentered>
        {convertNumberToDecimalStyle(value?.icms)}
      </TableCellCentered>
      <TableCellCentered>
        {convertNumberToDecimalStyle(value?.tax)}
      </TableCellCentered>
    </TableRowStyled>
  );

  const Table = ({ data }) => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>OPERAÇÃO</TableHeaderCellCentered>
          <TableHeaderCellCentered>UNIDADE DE MEDIDA</TableHeaderCellCentered>
          <TableHeaderCellCentered>QTD. FATURADA</TableHeaderCellCentered>
          <TableHeaderCellCentered>TARIFA COM TRIBUTOS</TableHeaderCellCentered>
          <TableHeaderCellCentered>TOTAL OPERAÇÃO</TableHeaderCellCentered>
          <TableHeaderCellCentered>
            BASE CÁLCULO PIS/COFINS
          </TableHeaderCellCentered>
          <TableHeaderCellCentered>BASE CALC. ICMS</TableHeaderCellCentered>
          <TableHeaderCellCentered>TARIFA UNITÁRIA</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {data?.map((value, index) => (
          <TableRow key={index} value={value} />
        ))}
      </TableBody>
    </StyledTable>
  );

  return (
    <Grow in={true} {...{ timeout: 600 }}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <IoseInfinityScroll
            dataLength={data ? data.length : 3}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={0}
            padding={0}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={340}
            scrollbarColor="#015A9E"
          >
            <Table data={data} />
          </IoseInfinityScroll>
        </Paper>
      )}
    </Grow>
  );
}

export function SummaryBillDataTable({ loading, data, openAuditModal }) {
  function openModalAndSetData(rowData) {
    openAuditModal(rowData);
  }

  function handleRowClick(rowData) {
    openModalAndSetData([rowData]);
  }

  const TableRow = ({ value, handleRowClick }) => (
    <TableRowStyled align="center" onClick={() => handleRowClick(value)}>
      <TableCellCentered>{value?.unidade_consumidora}</TableCellCentered>
      <TableCellCentered>{value?.mes_referencia}</TableCellCentered>
      <TableCellCentered>{value?.qtd_paginas}</TableCellCentered>
      <TableCellCentered>
        {convertNumberToDecimalStyle(value?.total)}
      </TableCellCentered>
    </TableRowStyled>
  );

  const Table = ({ data, handleRowClick }) => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>UNIDADE CONSUMIDORA</TableHeaderCellCentered>
          <TableHeaderCellCentered>REF: MÊS/ANO</TableHeaderCellCentered>
          <TableHeaderCellCentered>
            QUANTIDADE DE PÁGINAS
          </TableHeaderCellCentered>
          <TableHeaderCellCentered>VALOR DA CONTA (R$)</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {data?.map((value, index) => (
          <TableRow handleRowClick={handleRowClick} key={index} value={value} />
        ))}
      </TableBody>
    </StyledTable>
  );

  return (
    <Grow in={true} {...{ timeout: 500 }}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <IoseInfinityScroll
            dataLength={data ? data.length : 1}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={0}
            padding={0}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={400}
          >
            <Table handleRowClick={handleRowClick} data={data} />
          </IoseInfinityScroll>
        </Paper>
      )}
    </Grow>
  );
}

export function HomologatedTariffsTable({ loading, data }) {
  const TableRow = ({ value }) => {
    const {
      acessante,
      'base-tarifaria': baseTarifaria,
      classe,
      detalhe,
      'inicio-vigencia': inicioVigencia,
      'fim-vigencia': fimVigencia,
      modalidade,
      posto,
      'resolucao-aneel': resolucao,
      sigla,
      subclasse,
      subgrupo,
      te,
      tusd,
      unidade,
    } = value;
    return (
      <TableRowStyled align="center">
        <TableCellCentered>{acessante}</TableCellCentered>
        <TableCellCentered>{baseTarifaria}</TableCellCentered>
        <TableCellCentered>{classe}</TableCellCentered>
        <TableCellCentered>{detalhe}</TableCellCentered>
        <TableCellCentered>{inicioVigencia}</TableCellCentered>
        <TableCellCentered>{fimVigencia}</TableCellCentered>
        <TableCellCentered>{modalidade}</TableCellCentered>
        <TableCellCentered>{posto}</TableCellCentered>
        <TableCellCentered>{resolucao}</TableCellCentered>
        <TableCellCentered>{sigla}</TableCellCentered>
        <TableCellCentered>{subclasse}</TableCellCentered>
        <TableCellCentered>{subgrupo}</TableCellCentered>
        <TableCellCentered>{convertNumberToDecimalStyle(te)}</TableCellCentered>
        <TableCellCentered>
          {convertNumberToDecimalStyle(tusd)}
        </TableCellCentered>
        <TableCellCentered>{unidade}</TableCellCentered>
      </TableRowStyled>
    );
  };

  const Table = ({ data, handleRowClick }) => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>ACESSANTE</TableHeaderCellCentered>
          <TableHeaderCellCentered>BASE TARIFÁRIA</TableHeaderCellCentered>
          <TableHeaderCellCentered>CLASSE</TableHeaderCellCentered>
          <TableHeaderCellCentered>DETALHE</TableHeaderCellCentered>
          <TableHeaderCellCentered>INÍCIO DA VIGÊNCIA</TableHeaderCellCentered>
          <TableHeaderCellCentered>FIM DA VIGÊNCIA</TableHeaderCellCentered>
          <TableHeaderCellCentered>MODALIDADE</TableHeaderCellCentered>
          <TableHeaderCellCentered>POSTO</TableHeaderCellCentered>
          <TableHeaderCellCentered>RESOLUÇÃO</TableHeaderCellCentered>
          <TableHeaderCellCentered>CONCESSIONÁRIA</TableHeaderCellCentered>
          <TableHeaderCellCentered>SUBCLASSE</TableHeaderCellCentered>
          <TableHeaderCellCentered>SUBGRUPO</TableHeaderCellCentered>
          <TableHeaderCellCentered>TE</TableHeaderCellCentered>
          <TableHeaderCellCentered>TUSD</TableHeaderCellCentered>
          <TableHeaderCellCentered>UNIDADE</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {data?.map((value, index) => (
          <TableRow handleRowClick={handleRowClick} key={index} value={value} />
        ))}
      </TableBody>
    </StyledTable>
  );

  return (
    <Grow in={true} {...{ timeout: 3500 }}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <IoseInfinityScroll
            dataLength={data ? data.length : 3}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={0}
            padding={0}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={650}
          >
            <Table data={data} />
          </IoseInfinityScroll>
        </Paper>
      )}
    </Grow>
  );
}

export function TariffComponentsTable({ loading, data }) {
  const TableRow = ({ value }) => {
    const {
      acessante,
      'base-tarifaria': baseTarifaria,
      classe,
      'componente-tarifario': componenteTarifario,
      detalhe,
      'inicio-vigencia': inicioVigencia,
      'fim-vigencia': fimVigencia,
      modalidade,
      posto,
      'resolucao-aneel': resolucao,
      sigla,
      subclasse,
      subgrupo,
      unidade,
      valor,
    } = value;
    return (
      <TableRowStyled align="center">
        <TableCellCentered>{acessante}</TableCellCentered>
        <TableCellCentered>{baseTarifaria}</TableCellCentered>
        <TableCellCentered>{classe}</TableCellCentered>
        <TableCellCentered>{componenteTarifario}</TableCellCentered>
        <TableCellCentered>{detalhe}</TableCellCentered>
        <TableCellCentered>{inicioVigencia}</TableCellCentered>
        <TableCellCentered>{fimVigencia}</TableCellCentered>
        <TableCellCentered>{modalidade}</TableCellCentered>
        <TableCellCentered>{posto}</TableCellCentered>
        <TableCellCentered>{resolucao}</TableCellCentered>
        <TableCellCentered>{sigla}</TableCellCentered>
        <TableCellCentered>{subclasse}</TableCellCentered>
        <TableCellCentered>{subgrupo}</TableCellCentered>
        <TableCellCentered>{unidade}</TableCellCentered>
        <TableCellCentered>
          {convertNumberToDecimalStyle(valor)}
        </TableCellCentered>
      </TableRowStyled>
    );
  };

  const Table = ({ data, handleRowClick }) => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>ACESSANTE</TableHeaderCellCentered>
          <TableHeaderCellCentered>BASE TARIFÁRIA</TableHeaderCellCentered>
          <TableHeaderCellCentered>CLASSE</TableHeaderCellCentered>
          <TableHeaderCellCentered>
            COMPONENTE TARIFÁRIO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered>DETALHE</TableHeaderCellCentered>
          <TableHeaderCellCentered>INÍCIO DA VIGÊNCIA</TableHeaderCellCentered>
          <TableHeaderCellCentered>FIM DA VIGÊNCIA</TableHeaderCellCentered>
          <TableHeaderCellCentered>MODALIDADE</TableHeaderCellCentered>
          <TableHeaderCellCentered>POSTO</TableHeaderCellCentered>
          <TableHeaderCellCentered>RESOLUÇÃO</TableHeaderCellCentered>
          <TableHeaderCellCentered>CONCESSIONÁRIA</TableHeaderCellCentered>
          <TableHeaderCellCentered>SUBCLASSE</TableHeaderCellCentered>
          <TableHeaderCellCentered>SUBGRUPO</TableHeaderCellCentered>
          <TableHeaderCellCentered>UNIDADE</TableHeaderCellCentered>
          <TableHeaderCellCentered>VALOR</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {data?.map((value, index) => (
          <TableRow handleRowClick={handleRowClick} key={index} value={value} />
        ))}
      </TableBody>
    </StyledTable>
  );

  return (
    <Grow in={true} {...{ timeout: 3500 }}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <IoseInfinityScroll
            dataLength={data ? data.length : 3}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={0}
            padding={0}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={650}
          >
            <Table data={data} />
          </IoseInfinityScroll>
        </Paper>
      )}
    </Grow>
  );
}

export function CreditTable({ loading, data }) {
  const history = useHistory();
  const sortedData = data
    ?.slice()
    .sort((a, b) => b.porcentagem - a.porcentagem);

  function redirectToBillsPage(rowData) {
    const ucGeradora = rowData.uc_geradora;
    history.push({
      pathname: '/credit-beneficiario',
      state: { rowData: rowData, otherData: data, ucGeradora: ucGeradora },
    });
  }

  const TableRow = ({ value }) => {
    const {
      estimativa_fim,
      estimativa_inicio,
      porcentagem,
      uc_beneficiaria,
      uc_geradora,
    } = value;

    const estimativaInicioUTC = new Date(estimativa_inicio);
    const formattedEstimativa = estimativaInicioUTC.toLocaleDateString(
      'pt-BR',
      {
        timeZone: 'UTC',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }
    );
    const estimativaFimUTC = estimativa_fim ? new Date(estimativa_fim) : null;
    const formattedEstimativaFim = estimativaFimUTC
      ? estimativaFimUTC.toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
      : '';
    return (
      <TableRowStyled
        align="center"
        title="Clique para ir para as Unidades Beneficiárias"
        onClick={() => redirectToBillsPage(value)}
      >
        <TableCellCentered>{uc_beneficiaria}</TableCellCentered>
        <TableCellCentered>{uc_geradora}</TableCellCentered>
        <TableCellCentered>{porcentagem}</TableCellCentered>
        <TableCellCentered>{formattedEstimativa}</TableCellCentered>
        <TableCellCentered>{formattedEstimativaFim}</TableCellCentered>
      </TableRowStyled>
    );
  };

  const Table = ({ data, handleRowClick }) => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>UC BENEFICIÁRIA</TableHeaderCellCentered>
          <TableHeaderCellCentered>UC GERADORA</TableHeaderCellCentered>
          <TableHeaderCellCentered>%</TableHeaderCellCentered>
          <TableHeaderCellCentered>ESTIMATIVA INÍCIO</TableHeaderCellCentered>
          <TableHeaderCellCentered>ESTIMATIVA FIM</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {data?.map((value, index) => (
          <TableRow handleRowClick={handleRowClick} key={index} value={value} />
        ))}
      </TableBody>
    </StyledTable>
  );

  return (
    <Grow in={true} {...{ timeout: 500 }}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <IoseInfinityScroll
            dataLength={data ? parseInt(data.length) : 3}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={'0'}
            padding={'0'}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={650}
            scrollbarColor="#015A9E"
          >
            <Table data={sortedData} />
          </IoseInfinityScroll>
        </Paper>
      )}
    </Grow>
  );
}

export function CreditBeneficiarioTable({ loading, data }) {
  const location = useLocation();

  const ucGeradora = location.state?.ucGeradora;
  const filteredData = data?.filter((item) => item.uc_geradora === ucGeradora);

  if (filteredData?.length > 0) {
  } else {
    return (
      <NoDataMessage message="Ops... Parece que essa Unidade Geradora não possui unidades beneficiárias!" />
    );
  }

  const TableRow = ({ value }) => {
    const {
      mes_cred_gerado,
      cred_recebido,
      mes_cred_utilizado,
      uc_geradora,
    } = value;

    return (
      <TableRowStyled align="center">
        <TableCellCentered>{mes_cred_gerado}</TableCellCentered>
        <TableCellCentered>{cred_recebido}</TableCellCentered>
        <TableCellCentered>{mes_cred_utilizado}</TableCellCentered>
        <TableCellCentered>{uc_geradora}</TableCellCentered>
      </TableRowStyled>
    );
  };

  const Table = ({ data, handleRowClick }) => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>
            MÊS DO CRÉDITO GERADO
          </TableHeaderCellCentered>

          <TableHeaderCellCentered>CREDITO RECEBIDO</TableHeaderCellCentered>
          <TableHeaderCellCentered>
            MÊS DO CRÉDITO UTILIZADO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered>UC GERADORA</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {data?.map((value, index) => (
          <TableRow handleRowClick={handleRowClick} key={index} value={value} />
        ))}
      </TableBody>
    </StyledTable>
  );

  return (
    <Grow in={true} {...{ timeout: 500 }}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <IoseInfinityScroll
            dataLength={data ? parseInt(data?.length) : 3}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={'0'}
            padding={'0'}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={650}
          >
            <Table data={filteredData} />
          </IoseInfinityScroll>
        </Paper>
      )}
    </Grow>
  );
}

export function GeradorXBeneficiariaTable({ loading, data }) {
  const TableRow = ({ value }) => {
    const {
      uc_beneficiaria,
      uc_geradora,
      estimativa_inicio,
      estimativa_fim,
      porcentagem,
      isNew,
    } = value;

    const estimativaInicioUTC = new Date(estimativa_inicio);
    const formattedEstimativa = estimativaInicioUTC.toLocaleDateString(
      'pt-BR',
      {
        timeZone: 'UTC',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }
    );
    const estimativaFimUTC = estimativa_fim ? new Date(estimativa_fim) : null;
    const formattedEstimativaFim = estimativaFimUTC
      ? estimativaFimUTC.toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
      : '';

    const rowStyle = isNew
      ? {
          backgroundColor: '#e0f7d1',
        }
      : {};
    return (
      <TableRowStyled align="center" style={rowStyle}>
        <TableCellCentered>{uc_geradora}</TableCellCentered>
        <TableCellCentered>{uc_beneficiaria}</TableCellCentered>
        <TableCellCentered>{formattedEstimativa}</TableCellCentered>
        <TableCellCentered>{formattedEstimativaFim}</TableCellCentered>
        <TableCellCentered>{porcentagem}</TableCellCentered>
      </TableRowStyled>
    );
  };
  const sortedData = data
    ?.slice()
    .sort((a, b) => b.porcentagem - a.porcentagem);

  const Table = ({ data, handleRowClick }) => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>UC GERADORA</TableHeaderCellCentered>
          <TableHeaderCellCentered>UC BENEFICIÁRIA</TableHeaderCellCentered>
          <TableHeaderCellCentered>
            ESTIMATIVA DE INÍCIO
          </TableHeaderCellCentered>
          <TableHeaderCellCentered>ESTIMATIVA DE FIM</TableHeaderCellCentered>
          <TableHeaderCellCentered>%</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {data?.map((value, index) => (
          <TableRow handleRowClick={handleRowClick} key={index} value={value} />
        ))}
      </TableBody>
    </StyledTable>
  );
  return (
    <Grow in={true} {...{ timeout: 500 }}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <IoseInfinityScroll
            dataLength={data ? parseInt(data.length) : 3}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={'0'}
            padding={'0'}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={650}
          >
            <Table data={sortedData} />
          </IoseInfinityScroll>
        </Paper>
      )}
    </Grow>
  );
}

export function SaneparCreditTable({ loading, data, hasReportPermission }) {
  const history = useHistory();

  function redirectToBillsPage(rowData) {
    const ucGeradora = rowData.uc_geradora;
    history.push({
      pathname: '/saneparcreditbeneficiarias',
      state: {
        rowData: rowData,
        otherData: data,
        ucGeradora: ucGeradora,
        hasReportPermission: hasReportPermission,
      },
    });
  }
  const TableRow = ({ value }) => {
    const { uc_geradora, cred_gerado_total, porcentagem_total } = value;

    // Check if porcentagem_total is "99.99999999999999" and round it to "100.0"
    const roundedPorcentagemTotal =
      porcentagem_total >= '99.999999' ? '100.0' : porcentagem_total;

    return (
      <TableRowStyled
        align="center"
        title="Clique para ir para os Créditos Totais da Unidade"
        onClick={() => redirectToBillsPage(value)}
      >
        <TableCellCentered>{uc_geradora}</TableCellCentered>
        <TableCellCentered>{cred_gerado_total}</TableCellCentered>
        <TableCellCentered>{roundedPorcentagemTotal}</TableCellCentered>
      </TableRowStyled>
    );
  };

  const Table = ({ data, handleRowClick }) => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>UC GERADORA</TableHeaderCellCentered>

          <TableHeaderCellCentered>TOTAL DE CRÉDITO</TableHeaderCellCentered>
          <TableHeaderCellCentered>%</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {data?.map((value, index) => (
          <TableRow handleRowClick={handleRowClick} key={index} value={value} />
        ))}
      </TableBody>
    </StyledTable>
  );

  return (
    <Grow in={true} {...{ timeout: 500 }}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <IoseInfinityScroll
            dataLength={data ? parseInt(data.length) : 3}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={'0'}
            padding={'0'}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={650}
          >
            <Table data={data} />
          </IoseInfinityScroll>
        </Paper>
      )}
    </Grow>
  );
}

export function SaneparCreditBeneficiaryTable({
  loading,
  data,
  hasReportPermission,
}) {
  const [openChartModal, setOpenChartModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [chartMode, setChartMode] = useState();
  const location = useLocation();
  const ucGeradora = location.state?.ucGeradora;
  const filteredData = data?.filter((item) => item.uc_geradora === ucGeradora);

  // Ordenar os dados por mes_ref em ordem decrescente
  filteredData.sort((a, b) => {
    const [monthA, yearA] = a.mes_ref.split('/');
    const [monthB, yearB] = b.mes_ref.split('/');

    // Comparar os anos primeiro
    const yearComparison = yearB.localeCompare(yearA);

    // Se os anos forem iguais, comparar os meses
    if (yearComparison === 0) {
      return monthB.localeCompare(monthA);
    }

    return yearComparison;
  });

  if (filteredData.length === 0) {
    return (
      <NoDataMessage message="Ops... Parece que essa unidade não possui dados!" />
    );
  }

  const handleCloseChartModal = () => {
    setOpenChartModal(false);
  };

  const TableRow = ({ value }) => {
    const {
      mes_ref,
      credito_gerado,
      consumido,
      saldo,
      unidade,
      validade,
      indicador,
    } = value;

    const handleRowClick = () => {
      setSelectedCard(value);
      setOpenChartModal(true);
    };

    return (
      <TableRowStyled
        title="Ir para as Unidades Beneficiárias"
        align="center"
        onClick={handleRowClick}
      >
        <TableCellCentered>{mes_ref}</TableCellCentered>
        <TableCellCentered>{credito_gerado}</TableCellCentered>
        <TableCellCentered>{consumido}</TableCellCentered>
        <TableCellCentered>{saldo}</TableCellCentered>
        <TableCellCentered>{unidade}</TableCellCentered>
        <TableCellCentered>{validade}</TableCellCentered>
        <TableCellCentered>{indicador}</TableCellCentered>
      </TableRowStyled>
    );
  };

  const Table = () => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>REF: MÊS/ANO</TableHeaderCellCentered>
          <TableHeaderCellCentered>CRÉDITO</TableHeaderCellCentered>
          <TableHeaderCellCentered>CONSUMIDO</TableHeaderCellCentered>
          <TableHeaderCellCentered>SALDO</TableHeaderCellCentered>
          <TableHeaderCellCentered>UNIDADE</TableHeaderCellCentered>
          <TableHeaderCellCentered>VALIDADE</TableHeaderCellCentered>
          <TableHeaderCellCentered>VENCIMENTO (DIAS)</TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {filteredData.map((value, index) => (
          <TableRow key={index} value={value} />
        ))}
      </TableBody>
    </StyledTable>
  );

  return (
    <Grow in={true} timeout={500}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <IoseInfinityScroll
            dataLength={data ? parseInt(data.length) : 3}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={'0'}
            padding={'0'}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={650}
          >
            <Table />
          </IoseInfinityScroll>
          <IoseCircuitChartSanepar
            open={openChartModal}
            onClose={handleCloseChartModal}
            selectedCard={selectedCard}
            chartMode={chartMode}
            setChartMode={setChartMode}
            hasReportPermission={hasReportPermission}
          />
        </Paper>
      )}
    </Grow>
  );
}

export function SaneparCreditBeneficiaryFinalTable({ loading, data }) {
  const location = useLocation();
  const ucGeradora = location.state?.ucGeradora;
  const filteredData = data.filter((item) => item.uc_geradora === ucGeradora);

  if (filteredData.length > 0) {
  } else {
    return (
      <NoDataMessage message="Ops... Parece que essa unidade não possui dados!" />
    );
  }

  const TableRow = ({ value }) => {
    const {
      mes_cred_gerado,
      uc_beneficiaria,
      cred_recebido,
      mes_cred_utilizado,
    } = value;

    return (
      <TableRowStyled align="center" title="Unidades Beneficiárias">
        <TableCellCentered>{mes_cred_gerado}</TableCellCentered>
        <TableCellCentered>{cred_recebido}</TableCellCentered>
        <TableCellCentered>{uc_beneficiaria}</TableCellCentered>
        <TableCellCentered>{mes_cred_utilizado}</TableCellCentered>
      </TableRowStyled>
    );
  };

  const Table = ({ data, handleRowClick }) => (
    <StyledTable>
      <TableHeadStyled>
        <TableRowStyled align="center">
          <TableHeaderCellCentered>MÊS CRÉDITO GERADO</TableHeaderCellCentered>
          <TableHeaderCellCentered>CRÉDITO RECEBIDO</TableHeaderCellCentered>
          <TableHeaderCellCentered>UC BENEFICIÁRIA</TableHeaderCellCentered>
          <TableHeaderCellCentered>
            MÊS UTILIZAÇÃO CRÉDITO
          </TableHeaderCellCentered>
        </TableRowStyled>
      </TableHeadStyled>

      <TableBody>
        {data?.map((value, index) => (
          <TableRow handleRowClick={handleRowClick} key={index} value={value} />
        ))}
      </TableBody>
    </StyledTable>
  );

  return (
    <Grow in={true} {...{ timeout: 500 }}>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <Paper>
          <IoseInfinityScroll
            dataLength={data ? parseInt(data.length) : 3}
            next={() => {}}
            hasMore={false}
            loading={false}
            endList={false}
            sidebar={false}
            marginTop={'0'}
            padding={'0'}
            colortextend={null}
            sizetextend={null}
            scrollableTarget="scrollableDiv"
            height={650}
            scrollbarColor="#015A9E"
          >
            <Table data={data} />
          </IoseInfinityScroll>
        </Paper>
      )}
    </Grow>
  );
}
