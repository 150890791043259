// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useTariffs() {
  const [noTariffs, setnoTariffs] = useState('');

  const { getSession } = useContext(AccountContext);

  const getAllTariffs = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllTariffs(headers, 3000, 3000);

    const { data } = response.data;

    const tariffs = data || [];

    if (tariffs.length === 0) {
      setnoTariffs(Messages.noFindTariffs);
      throw new Error(Messages.noFindTariffs);
    }
    return tariffs;
  };

  const {
    data: allTariffs,
    error: noTariffsError,
    refetch: refreshTariffs,
    isLoading,
  } = useQuery(['tariffs'], getAllTariffs);

  return {
    allTariffs,
    noTariffsError,
    noTariffs,
    refreshTariffs,
    isLoading,
  };
}

export default useTariffs;
