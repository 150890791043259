import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import axios from 'axios';
import {
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  StyledTable,
  TableHeadStyled,
  TableRowStyled,
  WrappedContainer,
} from './style';
import * as XLSX from 'xlsx';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import format from 'date-fns/format';

import { IoseAddButton, IoseAlertLoadingCards, IoseButton } from 'components';

import { IoseAddFilterButton } from 'components/IoseButtonIcon';
import { useCreateLogs, useGroupPermissions } from 'hooks';
import { LocalStorage, Permissions } from 'common';

const useStyles = makeStyles((theme) => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#015A9E', // Cor de fundo do cabeçalho
    color: '#fff', // Cor do texto do cabeçalho (amarelo)
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Isso coloca o espaço igual entre os elementos
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4), // Garante espaço na direita
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
  },

  filterDisplay: {
    backgroundColor: '#FFFFFF', // Cor de fundo branca
    border: '1px solid #CCCCCC', // Borda sólida cinza
    padding: theme.spacing(0.5), // Espaçamento interno reduzido
    borderRadius: theme.shape.borderRadius, // Bordas arredondadas do tema
    display: 'flex',
    alignItems: 'center', // Alinhamento vertical dos itens
    justifyContent: 'space-between', // Espaçamento entre os itens
    maxWidth: 'fit-content',
  },
  closeButton: {
    backgroundColor: '#D32F2F', // Fundo vermelho para o botão
    color: '#FFFFFF', // Texto branco
    fontSize: '0.75rem', // Tamanho de fonte reduzido para o texto 'X'
    lineHeight: '1', // Altura da linha ajustada para que o 'X' esteja centralizado
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(2), // Altura do botão
    width: theme.spacing(2), // Largura do botão
    borderRadius: '50%', // Bordas arredondadas para torná-lo circular
    padding: 0, // Sem preenchimento extra
    marginLeft: theme.spacing(1), // Espaço entre o texto e o botão
    '&:hover': {
      backgroundColor: '#F44336', // Cor de fundo mais clara ao passar o mouse
    },
    cursor: 'pointer', // Mãozinha ao passar o mouse para indicar clicabilidade
    border: 'none', // Sem borda
    outline: 'none', // Remove o contorno quando focado (para acessibilidade, considere manter algum foco visível)
  },
  filterButton: {
    marginLeft: '10px', // Espaço entre o texto e o botão
    backgroundColor: 'transparent', // Fundo transparente para o botão
    border: 'none', // Sem borda
    cursor: 'pointer', // Mãozinha ao passar o mouse para indicar clicabilidade
    color: 'white', // Cor branca para o texto 'X' do botão
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  select: {
    backgroundColor: 'white', // Define o fundo como branco
    color: 'black', // Define a cor do texto para preto ou outra cor que desejar
    border: '1px solid #ccc', // Adiciona uma borda cinza clara
    padding: '3px',
    borderRadius: '4px', // Opcional: bordas arredondadas para o Select
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Opcional: adiciona uma sombra leve
    '&:before': {
      // Remove a linha inferior por padrão do Material-UI
      borderBottom: 'none',
    },
    '&:after': {
      // Remove a linha inferior quando o Select está focado
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      // Remove a linha inferior no hover
      borderBottom: 'none',
    },
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

// Função para formatar datas
const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('pt-BR');
};

const itemsPerPage = 20;

export default function TelemetryMeasuresContainer() {
  const apiUrl =
    'https://vby6vsofe3.execute-api.us-east-1.amazonaws.com/get/getTelemetryMeasures';
  const [responseData, setResponseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const classes = useStyles();
  const [filterValue, setFilterValue] = useState('');
  const [filterBy, setFilterBy] = useState('concessionaire');
  const [filterOptions, setFilterOptions] = useState([]);
  const [isFilterMenuVisible, setIsFilterMenuVisible] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { createLog } = useCreateLogs();
  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['telemetria', 'medicao_telemetria', 'leitura'];
    const reportPath = ['telemetria', 'medicao_telemetria', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        const data = JSON.parse(response.data.body);
        setResponseData(data);
        // Inicializa displayData com os primeiros itens
        setDisplayData(data.slice(0, itemsPerPage));
        // Verifica se ainda há mais dados para carregar inicialmente
        setHasMore(data.length > itemsPerPage);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };
    fetchData();
  }, []);

  const loadMoreData = () => {
    const nextPage = page + 1;
    const startIndex = nextPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const nextItems = filteredData.slice(startIndex, endIndex);

    setDisplayData((prevDisplayData) => [...prevDisplayData, ...nextItems]);
    setPage(nextPage);

    // Verifica se ainda há mais dados para carregar com base em filteredData
    setHasMore(endIndex < filteredData.length);
  };

  useEffect(() => {
    const uniqueOptions = Array.from(
      new Set(responseData.map((data) => data[filterBy]))
    );
    setFilterOptions(uniqueOptions);
  }, [responseData, filterBy]);

  const handleFilterOptionClick = (option) => {
    setFilterBy(option);
    // setIsSelectVisible(true);
    setFilterValue(''); // Resetar o valor do filtro
  };

  const handleApplyFilterClick = () => {
    let newFilteredData = responseData;

    if (filterValue) {
      newFilteredData = responseData.filter(
        (data) => data[filterBy] === filterValue
      );
    }

    if (selectedDate) {
      const formattedDate = format(selectedDate, 'yyyy-MM-dd');
      newFilteredData = newFilteredData.filter((data) => {
        const createdDate = data.created_at.split('T')[0];
        return createdDate === formattedDate;
      });
      // Defina filterBy e filterValue para data
      setCurrentFilter({ filterBy: 'created_at', filterValue: formattedDate });
    }

    setFilteredData(newFilteredData);
    // setCurrentFilter({ filterBy, filterValue }); // Não é necessário aqui

    // Reinicia a paginação e exibe os primeiros itens após o filtro
    setDisplayData(newFilteredData.slice(0, itemsPerPage));
    setPage(0);
    setHasMore(newFilteredData.length > itemsPerPage);

    setIsFilterMenuVisible(false);
  };

  const handleClearFilter = () => {
    setFilteredData(responseData);
    setCurrentFilter(null); // Limpa o filtro atual
    setFilterValue('');

    // Reinicia a exibição para os primeiros itens originais, considerando que não há filtro
    setDisplayData(responseData.slice(0, itemsPerPage));
    setPage(0);
    setHasMore(responseData.length > itemsPerPage);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const exportToExcel = () => {
    // Preparando os dados filtrados com apenas os campos desejados
    const dataToExport = filteredData.map((data) => ({
      'UUID Measure': data.uuid_measure,
      'Active Power': data.active_power,
      'Apparent Power': data.apparent_power,
      'Arrival Date': formatDate(data.arrival_date),
      'Counter': data.counter,
      'Data de Criação': formatDate(data.created_at),
      'Current': data.current,
      'Export Active Energy': data.export_active_energy,
      'Export Reactive Energy': data.export_reactive_energy,
      'Line Frequency': data.line_frequency,
      'Power Factor': data.power_factor,
      'Reactive Power': data.reactive_power,
      'Status Measure': data.status_measure,
      'System Status': data.system_status,
      'Thermistor Voltage': data.thermistor_voltage,
      'UUID Circuito': data.uuid_circuit,
      'UUID Voltage': data.voltage,
    }));

    // Gerando o arquivo XLSX
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Relatório');
    XLSX.writeFile(wb, 'relatorio_telemetria.xlsx');
  };

  const handleExportToExcel = async () => {
    exportToExcel();

    try {
      const operacao = 'EMITIR RELATÓRIO';
      const form = 'MEDIÇÕES DE TELEMETRIA';
      const logContent = {};

      await createLog(operacao, form, logContent);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateChange = (date) => {
    // Cria uma nova instância de objeto Date para garantir uma mudança de estado
    const newSelectedDate = date ? new Date(date) : null;
    setSelectedDate(newSelectedDate);

    if (newSelectedDate) {
      const formattedDate = format(newSelectedDate, 'yyyy-MM-dd');

      const newFilteredData = responseData.filter((data) => {
        const createdDate = data.created_at.split('T')[0];
        return createdDate === formattedDate;
      });

      setFilteredData(newFilteredData);
      setDisplayData(newFilteredData.slice(0, itemsPerPage));
      setPage(0);
      setHasMore(newFilteredData.length > itemsPerPage);
    } else {
      // Se nenhuma data estiver selecionada, limpe o filtro
      setFilteredData(responseData);
      setDisplayData(responseData.slice(0, itemsPerPage));
      setPage(0);
      setHasMore(responseData.length > itemsPerPage);
    }

    // Força a atualização do componente para refletir a mudança de estado
    // Isso pode ser omitido se a mudança de estado estiver funcionando corretamente
    // this.forceUpdate(); (Para classes, para funções, você pode usar um hook de estado adicional)
  };

  const applyDateFilter = () => {
    if (selectedDate) {
      // Formatar a data selecionada para corresponder ao formato de data do banco de dados
      const formattedDate = format(selectedDate, 'yyyy-MM-dd');
      const newFilteredData = responseData.filter((data) =>
        data.created_at.startsWith(formattedDate)
      );

      // Atualizar estado com dados filtrados e reiniciar a paginação
      setFilteredData(newFilteredData);
      setDisplayData(newFilteredData.slice(0, itemsPerPage));
      setPage(0);
      setHasMore(newFilteredData.length > itemsPerPage);
    }
  };

  return (
    <>
      {hasReadPermission ? (
        <>
          <WrappedContainer>
            <h3
              style={{
                fontSize: '23px',
                paddingTop: '5px',
                color: '#015A9E',
                marginTop: '15px',
              }}
            >
              Tabela de medição de telemetria
            </h3>
            <h2
              style={{
                color: 'grey',
                paddingTop: '11px',
                marginBottom: '10px',
              }}
            >
              Filtre os dados para exibição
            </h2>
          </WrappedContainer>

          <div className={classes.filterContainer}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <IoseAddFilterButton
                onClick={() => {
                  setIsFilterMenuVisible((prev) => !prev);
                  toggleFilters();
                }}
                tooltip="ADICIONAR FILTRO"
              >
                Filtro
              </IoseAddFilterButton>

              {isFilterMenuVisible && (
                <>
                  <IoseButton
                    onClick={() => handleFilterOptionClick('uuid_circuit')}
                  >
                    Circuito
                  </IoseButton>
                  <IoseButton
                    onClick={() => handleFilterOptionClick('created_at')}
                  >
                    Data de criação
                  </IoseButton>

                  {filterBy === 'created_at' && (
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={ptLocale}
                    >
                      <DatePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        format="dd/MM/yyyy"
                        onClose={applyDateFilter}
                        autoOk
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        className={classes.datePicker}
                      />
                    </MuiPickersUtilsProvider>
                  )}

                  {filterBy !== 'created_at' && (
                    <Select
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                      displayEmpty
                      className={classes.select}
                    >
                      <MenuItem value="">Mostrar Todos</MenuItem>
                      {filterOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}

                  <IoseAddButton onClick={handleApplyFilterClick}>
                    Filtrar Dados
                  </IoseAddButton>
                </>
              )}

              {currentFilter && (
                <div className={classes.filterDisplay}>
                  <span>Filtros Selecionados: {currentFilter.filterValue}</span>
                  <button
                    className={classes.closeButton}
                    onClick={handleClearFilter}
                  >
                    X
                  </button>
                </div>
              )}
            </div>

            {/* Botão de gerar relatório é posicionado separadamente à direita */}
            {hasReportPermission ? (
              <div>
                <IoseButton onClick={handleExportToExcel}>
                  Download XLSX
                </IoseButton>
              </div>
            ) : null}
          </div>

          <WrappedContainer>
            <IoseInfinityScroll
              dataLength={displayData.length}
              next={loadMoreData}
              hasMore={hasMore}
              height={600} // Ajuste conforme necessário
              // Outras props que seu IoseInfinityScroll possa precisar
            >
              <TableContainer component={Paper}>
                <StyledTable>
                  <TableHeadStyled>
                    <TableRowStyled align="center">
                      <TableHeaderCellCentered>
                        CIRCUITO
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        POTÊNCIA ATIVA
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        POTÊNCIA APARENTE
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        DATA DE RECEBIMENTO
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        DATA DE CRIAÇÃO
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        CONTADOR
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        CORRENTE
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        ENERGIA ATIVA EXPORTADA
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        ENERGIA REATIVA EXPORTADA
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        FREQUÊNCIA DA REDE
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        FATOR DE POTÊNCIA
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        POTÊNCIA REATIVA
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        STATUS DA MEDIÇÃO
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        STATUS DO SISTEMA
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        TEMPERATURA INTERNA DO MÓDULO
                      </TableHeaderCellCentered>
                      <TableHeaderCellCentered>
                        VOLTAGEM
                      </TableHeaderCellCentered>
                    </TableRowStyled>
                  </TableHeadStyled>
                  <TableBody>
                    {displayData.map((measure, index) => (
                      <TableRow key={index}>
                        <TableCellCentered>
                          {measure.uuid_circuit}
                        </TableCellCentered>
                        <TableCellCentered>
                          {measure.active_power}
                        </TableCellCentered>
                        <TableCellCentered>
                          {measure.apparent_power}
                        </TableCellCentered>
                        <TableCellCentered>
                          {formatDate(measure.arrival_date)}
                        </TableCellCentered>
                        <TableCellCentered>
                          {formatDate(measure.created_at)}
                        </TableCellCentered>
                        <TableCellCentered>{measure.counter}</TableCellCentered>
                        <TableCellCentered>{measure.current}</TableCellCentered>
                        <TableCellCentered>
                          {measure.export_active_energy}
                        </TableCellCentered>
                        <TableCellCentered>
                          {measure.export_reactive_energy}
                        </TableCellCentered>

                        <TableCellCentered>
                          {measure.line_frequency}
                        </TableCellCentered>
                        <TableCellCentered>
                          {measure.power_factor}
                        </TableCellCentered>
                        <TableCellCentered>
                          {measure.reactive_power}
                        </TableCellCentered>
                        <TableCellCentered>
                          {measure.status_measure === 'received'
                            ? 'recebida'
                            : 'perdida'}
                        </TableCellCentered>
                        <TableCellCentered>
                          {measure.system_status}
                        </TableCellCentered>
                        <TableCellCentered>
                          {measure.thermistor_voltage}
                        </TableCellCentered>
                        <TableCellCentered>{measure.voltage}</TableCellCentered>
                      </TableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            </IoseInfinityScroll>
          </WrappedContainer>
        </>
      ) : (
        <div style={{ marginTop: '35px' }}>
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        </div>
      )}
    </>
  );
}

TelemetryMeasuresContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
