import styled from 'styled-components';
import {
  Collapse,
  Table,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

export const TableStyled = styled(Table)`
  .MuiTableCell-root {
    display: table-cell;
    padding: 5px;
    font-family: 'Montserrat', sans-serif;
    color: ${(props) =>
      props.dashboard === 'true' ? 'var(--primary)' : 'rgb(255,255,255,0.6)'};
    max-width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    border-bottom: none;
    text-align: ${(props) => (props.dashboard === 'true' ? 'center' : 'left')};

    &:hover {
      .MuiTableSortLabel-root,
      .MuiTableSortLabel-icon {
        color: ${(props) =>
          props.dashboard === 'true'
            ? 'var(--secondary)'
            : 'rgb(235, 192, 0,0.7) '};
      }
    }

    .MuiTableSortLabel-active,
    .MuiTableSortLabel-iconDirectionAsc,
    .MuiTableSortLabel-iconDirectionDesc {
      color: ${(props) =>
        props.dashboard === 'true'
          ? 'var(--secondary) !important'
          : 'rgb(235, 192, 0,0.7) !important'};
    }
  }
`;

export const TableCellHeader = styled(TableCell)`
  margin: 10px 0px;
  font-weight: 600;
  text-align: ${(props) => (props.dashboard === 'true' ? 'center' : 'left')};
  padding-left: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};
  font-size: ${(props) =>
    props.collapse === 'true' ? '0.9em !important' : '2em !important'};

  .MuiButtonBase-root,
  .NoSortLabel {
    font-size: 12px;
    margin: 10px 0px;
    font-weight: 600;
    padding-left: ${(props) => (props.dashboard === 'true' ? '20px' : '0px')};
  }

  .NoSortLabel {
    cursor: auto;
  }
`;

export const TableContainerStyled = styled(TableContainer)`
  /* height: 220px; */
  background: ${(props) => (props.dashboard === 'true' ? 'white' : 'none')};
  width: 100%;
  overflow-x: none;
  overflow-y: scroll;
  cursor: pointer;
  border-radius: 4px 4px 0px 0px;
  box-shadow: ${(props) =>
    props.dashboard === 'true'
      ? '0px 2px 1px -1px rgb(0 0 0 / 20%),0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
      : 'none'};

  /* border: solid 1px red; */
  margin-top: 8px;

  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */

  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 10px;
  }
`;

export const TablePaginationStyled = styled(TablePagination)`
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  display: flex;
  align-items: baseline;
  align-content: baseline;
  justify-content: flex-end;

  color: ${(props) =>
    props.dashboard === 'true' ? 'var(--primary)' : 'var(--primary)'};

  border-radius: 0px 0px 4px 4px;
  background-color: ${(props) =>
    props.dashboard === 'true' ? 'white' : 'none'};
  box-shadow: ${(props) =>
    props.dashboard === 'true'
      ? '0px 2px 1px -1px rgb(0 0 0 / 20%),0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
      : 'none'};

  svg {
    color: ${(props) =>
      props.dashboard === 'true' ? 'var(--primary)' : 'var(--primary)'};
  }
`;

export const Error = styled.p`
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : 'center')};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '20px')};
  margin-top: ${(props) => props.top && props.top};
  font-size: ${(props) => props.font && props.font};
  font-weight: ${(props) => (props.bold === 'false' ? '500' : 'bolder')};
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  color: red;
`;

export const DivBalls = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const Ball = styled.div`
  font-weight: bolder;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;

  div {
    background: ${(props) =>
      props.active
        ? 'var(--primary)'
        : props.dashboard === 'true'
        ? 'var(--base)'
        : 'var(--dark)'};
    border-radius: 100%;
    width: 15px;
    height: 15px;
  }
`;

export const TableCellActions = styled(TableCell)`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    margin: 10px;
  }
`;

export const DivActionIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  font-weight: bolder;
  font-size: 10px;

  svg {
    color: ${(props) =>
      props.action === 'true' ? 'var(--primary)' : 'var(--dark)'};
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
`;

export const TableCellHeaderCollapse = styled(TableCellHeader)`
  padding: 4px 0px !important;
  text-align: left !important;

  @media (max-width: 767px) {
    padding-right: 32px !important;
    padding-top: 16px !important;
  }
`;

export const TableCellCollapse = styled(TableCell)`
  max-width: 270px !important;
  padding: 4px 0px !important;
  text-align: left !important;

  @media (max-width: 767px) {
    padding-right: 32px !important;
    padding-top: 16px !important;
  }
`;

export const TableCellCollapseStatus = styled(TableCell)`
  padding: 4px 0px !important;
  text-align: left !important;

  @media (max-width: 767px) {
    padding-right: 32px !important;
    padding-top: 16px !important;
  }
  color: ${(props) =>
    props.status !== 'resolved'
      ? 'red !important'
      : 'var(--success) !important'};
  font-weight: bolder;
  text-align: left;
`;

export const CollapseStyled = styled(Collapse)`
  padding: 1% 3%;
  text-align: center;
  overflow-x: scroll;
  overflow-y: none;
  scrollbar-width: 1px; /* Firefox */
  -ms-overflow-style: 1px; /* IE 10+ */

  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.dashboard === 'true ' ? 'lightgray' : 'black'};
    border-radius: 10px;
  }
`;

export const TilteRowCollapse = styled.p`
  font-weight: bolder;
  text-transform: uppercase;
  text-align: left;
  padding-bottom: 2%;

  @media (max-width: 767px) {
    padding-top: 2% !important;
  }
`;

export const TableRowStyled = styled(TableRow)`
  background: ${(props) =>
    props.open && (props.dashboard === 'true' ? '#F5F5F5' : '#212B30')};
  transition: all 0.2s ease-in-out;
`;

export const IconButtonStyled = styled(IconButton)`
  color: ${(props) =>
    props.dashboard === 'true' ? 'var(--secondary)' : 'rgb(255,255,255,0.5)'};
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: var(--primary);
  width: 30px !important;
  height: 30px !important;
`;
