import ReactECharts from 'echarts-for-react';
import React from 'react';

export const IoseBarChartConsumerGroup = ({ data }) => {
  const { date, consumption_kwh } = data;

  const option = {
    grid: {
      left: '2%',
      right: '2%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: date,
        axisTick: {
          alignWithLabel: true,
        },
        show: true,
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
      },
    ],
    series: [
      {
        name: 'Consumo (kWh)',
        type: 'bar',
        barWidth: '40%',
        data: consumption_kwh,
        color: '#015A9E',
        itemStyle: {
          emphasis: {
            barBorderRadius: [10, 10],
          },
          normal: {
            barBorderRadius: [5, 5, 0, 0],
          },
        },
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: 370 }} />;
};
