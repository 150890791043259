import React, { useEffect, useRef, useState } from 'react';
import { Grow, Tooltip } from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';

import {
  IoseDeleteButton,
  IoseEditButton,
  IoseMoreOptions,
  IoseOpenChartModal,
  IoseDeleteInputModal,
} from 'components';

//Imports from Style
import * as S from './style';
import { IoseCardButton } from 'components/IoseButtonIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function IoseCardConsumerGroup({
  name,
  description,
  handleOpenEditModal,
  handleOpenDeleteModal,
  onClick,
  cardEditTitle,
  chartModalTitle,
  cardIcon,
  showDeleteButton,
  showEditButton,
  showChartButton,
  selectedCard,
}) {
  const [moreOptions, setMoreOptions] = useState(false);

  const history = useHistory();

  const handleClick = () => {
    onClick();
    setMoreOptions(!moreOptions);
  };
  function redirectToConsumerGrouDash() {
    history.push({
      pathname: '/consumer-group-dashboard',
      state: selectedCard.identification_number,
    });
  }
  const cardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setMoreOptions(false); // Fecha o card se o usuário clicar fora dele
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setMoreOptions(false); // Fecha o card se o usuário pressionar a tecla Escape
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const renderButtonsCard = () => {
    const ObjectsData = [
      {
        title: 'Concessionária',
        value: selectedCard?.concessionaire ?? '',
      },
      { title: 'Número', value: selectedCard?.identification_number ?? '' },
      { title: 'Endereço', value: selectedCard?.address },
      { title: 'Cidade', value: selectedCard?.city ?? '' },
      { title: 'Atividade', value: selectedCard?.atividade ?? '' },
      { title: 'Código do local', value: selectedCard?.cod_local ?? '' },
      { title: 'Código da unidade', value: selectedCard?.cod_unidade ?? '' },
      {
        title: 'Descrição da unidade',
        value: selectedCard?.descricao_unidade ?? '',
      },
      {
        title: 'Número do contrato',
        value: selectedCard?.contract_number ?? 'Não cadastrado',
      },
    ];
    return (
      <Grow in={true} {...{ timeout: 1000 }}>
        <S.DivMoreInfo>
          <S.DivWrapperInfos>
            {ObjectsData?.map((info, index) => (
              <S.DivInfos key={index}>
                <p>
                  <strong>{info.title}: </strong>
                  <span>
                    <i>{info.value}</i>
                  </span>
                </p>
              </S.DivInfos>
            ))}
          </S.DivWrapperInfos>
          <S.DivButtons>
            {showDeleteButton === true ? (
              <IoseDeleteButton
                primary="true"
                card="true"
                tooltip="DELETAR CONCESSIONÁRIA"
                onClick={() => handleOpenDeleteModal()}
              />
            ) : (
              <></>
            )}
            {showEditButton === true ? (
              <IoseEditButton
                primary="true"
                card="true"
                tooltip={cardEditTitle}
                onClick={() => handleOpenEditModal()}
              />
            ) : (
              <></>
            )}

            {showEditButton === true ? (
              <IoseCardButton
                primary="true"
                card="true"
                tooltip="DASHBOARD DA UC"
                onClick={() => redirectToConsumerGrouDash()}
                icon={<EqualizerIcon />}
              />
            ) : (
              <></>
            )}

            {showChartButton ? (
              <IoseOpenChartModal
                primary="true"
                card="true"
                tooltip={chartModalTitle}
                onClick={() => {}}
              />
            ) : (
              <></>
            )}
          </S.DivButtons>
        </S.DivMoreInfo>
      </Grow>
    );
  };

  const renderSubtitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{description}</p>
    </Tooltip>
  );

  const renderTitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{name}</p>
    </Tooltip>
  );

  return (
    <Grow in={true} {...{ timeout: 1000 }} onClick={handleClick}>
      <S.CardStyled open={moreOptions} ref={cardRef}>
        <S.DivHeaderButton disableSpacing>
          <S.CardHeaderStyled
            avatar={<S.AvatarStyled>{cardIcon}</S.AvatarStyled>}
            title={renderTitle()}
            subheader={renderSubtitle()}
          ></S.CardHeaderStyled>
          <div>
            <IoseMoreOptions
              primary="true"
              card="true"
              onClick={() =>
                moreOptions ? setMoreOptions(false) : setMoreOptions(true)
              }
            />
          </div>
        </S.DivHeaderButton>

        <S.CardContentStyled>
          {moreOptions && renderButtonsCard()}
          {/* {renderData()} */}
        </S.CardContentStyled>
      </S.CardStyled>
    </Grow>
  );
}

export function IoseCardContracts({
  name,
  description,
  handleOpenEditModal,
  handleOpenDeleteModal,
  onClick,
  cardEditTitle,
  cardDeleteTitle,
  chartModalTitle,
  cardIcon,
  showDeleteButton,
  showEditButton,
  showChartButton,
  selectedCard,
}) {
  const [moreOptions, setMoreOptions] = useState(false);

  const handleClick = () => {
    onClick();
    setMoreOptions(!moreOptions);
  };

  const cardRef = useRef(null);

  function formatDate(dataString) {
    const data = new Date(dataString);

    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setMoreOptions(false); // Fecha o card se o usuário clicar fora dele
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setMoreOptions(false); // Fecha o card se o usuário pressionar a tecla Escape
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const renderButtonsCard = () => {
    const ObjectsData = [
      {
        title: 'Contrato',
        value: selectedCard?.contract_number ?? 'Não cadastrado',
      },
      {
        title: 'Concessionária',
        value: selectedCard?.concessionaire ?? 'Não cadastrada',
      },
      {
        title: 'Tipo de mercado',
        value: selectedCard?.market_type ?? '',
      },
      {
        title: 'Início do contrato',
        value: formatDate(selectedCard?.initial_date),
      },
      { title: 'Fim do contrato', value: formatDate(selectedCard?.final_date) },
      {
        title: 'Valor mensal do contrato',
        value: 'R$ ' + selectedCard?.monthly_value,
      },
      {
        title: 'Valor total do contrato',
        value: 'R$ ' + selectedCard?.total_contracts,
      },
      {
        title: 'Índice de reajuste',
        value: selectedCard?.readjustment_index ?? '',
      },
      {
        title: 'Taxa de reajuste',
        value: selectedCard?.readjustment_rate ?? '',
      },
      {
        title: 'Tempo de contato',
        value: selectedCard?.term_contract ?? '',
      },
    ];
    return (
      <Grow in={true} {...{ timeout: 1000 }}>
        <S.DivMoreInfo>
          <S.DivWrapperInfos>
            {ObjectsData?.map((info, index) => (
              <S.DivInfos key={index}>
                <p>
                  <strong>{info?.title}: </strong>
                  <span>
                    <i>{info?.value}</i>
                  </span>
                </p>
              </S.DivInfos>
            ))}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <S.ContractAnchor
                href={selectedCard?.file_link}
                target="_blank"
                rel="noreferrer"
              >
                Baixar o contrato.
              </S.ContractAnchor>
            </div>
          </S.DivWrapperInfos>
          <S.DivButtons>
            {showDeleteButton === true ? (
              <IoseDeleteButton
                primary="true"
                card="true"
                tooltip={cardDeleteTitle}
                onClick={() => handleOpenDeleteModal()}
              />
            ) : (
              <></>
            )}
            {showEditButton === true ? (
              <IoseEditButton
                primary="true"
                card="true"
                tooltip={cardEditTitle}
                onClick={() => handleOpenEditModal()}
              />
            ) : (
              <></>
            )}

            {showChartButton ? (
              <IoseOpenChartModal
                primary="true"
                card="true"
                tooltip={chartModalTitle}
                onClick={() => {}}
              />
            ) : (
              <></>
            )}
          </S.DivButtons>
        </S.DivMoreInfo>
      </Grow>
    );
  };

  const renderSubtitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{description}</p>
    </Tooltip>
  );

  const renderTitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{name}</p>
    </Tooltip>
  );

  return (
    <Grow in={true} {...{ timeout: 1000 }} onClick={handleClick}>
      <S.CardStyled open={moreOptions} ref={cardRef}>
        <S.DivHeaderButton disableSpacing>
          <S.CardHeaderStyled
            avatar={<S.AvatarStyled>{cardIcon}</S.AvatarStyled>}
            title={renderTitle()}
            subheader={renderSubtitle()}
          ></S.CardHeaderStyled>
          <div>
            <IoseMoreOptions
              primary="true"
              card="true"
              onClick={() =>
                moreOptions ? setMoreOptions(false) : setMoreOptions(true)
              }
            />
          </div>
        </S.DivHeaderButton>

        <S.CardContentStyled>
          {moreOptions && renderButtonsCard()}
          {/* {renderData()} */}
        </S.CardContentStyled>
      </S.CardStyled>
    </Grow>
  );
}

export function IoseContractsAlertCard({ title, value, color, valueColor }) {
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.CardAlertStyled color={color}>
        <S.CardAlertHeader>
          <S.TypographyHeaderStyled>{title}</S.TypographyHeaderStyled>
          {/* <IconDiv>{icon}</IconDiv> */}
        </S.CardAlertHeader>
        {/* <DividerStyled /> */}
        <S.CardAlertContent>
          <S.TypographyContentStyled valueColor={valueColor}>
            {value}
          </S.TypographyContentStyled>
        </S.CardAlertContent>
      </S.CardAlertStyled>
    </Grow>
  );
}

export function IoseMapCard({
  name,
  description,
  handleOpenEditModal,
  handleOpenObjectsModal,
  onClose,
  onClick,
  cardDeleteTitle,
  cardEditTitle,
  modalTitle,
  chartModalTitle,
  cardIcon,
  showDeleteButton,
  showEditButton,
  showChartButton,
}) {
  const [moreOptions, setMoreOptions] = useState(false);

  const handleClick = () => {
    onClick();
    setMoreOptions(!moreOptions);
  };

  const renderButtonsCard = () => {
    return (
      <Grow in={true} {...{ timeout: 1000 }}>
        <S.DivButtons>
          {showDeleteButton === true ? (
            <IoseDeleteInputModal
              title={cardDeleteTitle}
              name={name}
              cardbutton={'true'}
              primarybutton={'true'}
            />
          ) : (
            <></>
          )}
          {showEditButton === true ? (
            <IoseEditButton
              primary="true"
              card="true"
              tooltip={cardEditTitle}
              onClick={() => handleOpenEditModal()}
            />
          ) : (
            <></>
          )}

          {/* <IoseOpenObjectsModal
            primary="true"
            card="true"
            tooltip={modalTitle}
            // onClick={() => handleOpenObjectsModal()}
          /> */}

          {showChartButton ? (
            <IoseOpenChartModal
              primary="true"
              card="true"
              tooltip={modalTitle}
              onClick={() => handleOpenObjectsModal()}
            />
          ) : (
            <></>
          )}
        </S.DivButtons>
      </Grow>
    );
  };

  const renderSubtitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{description}</p>
    </Tooltip>
  );

  const renderTitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{name}</p>
    </Tooltip>
  );

  return (
    <Grow in={true} {...{ timeout: 1000 }} onClick={handleClick}>
      <S.CardStyled open={moreOptions}>
        <S.DivHeaderButton disableSpacing>
          <S.CardHeaderStyled
            avatar={<S.AvatarStyled>{cardIcon}</S.AvatarStyled>}
            title={renderTitle()}
            subheader={renderSubtitle()}
          ></S.CardHeaderStyled>
          <div>
            <IoseMoreOptions
              primary="true"
              card="true"
              onClick={() =>
                moreOptions ? setMoreOptions(false) : setMoreOptions(true)
              }
            />
          </div>
        </S.DivHeaderButton>

        <S.CardContentStyled>
          {moreOptions && renderButtonsCard()}
          {/* {renderData()} */}
        </S.CardContentStyled>
      </S.CardStyled>
    </Grow>
  );
}
