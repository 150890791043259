import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Logo from './img/logo-iose-black.jpg';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function convertImageToBase64(imagePath) {
  return fetch(imagePath)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

export default async function consumerGroupsReport(data) {
  const totalConsumerGroups = data?.length;

  function formatDate(dataISOString) {
    const data = new Date(dataISOString);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês começa do zero
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
  }

  const logoData = await convertImageToBase64(Logo);
  const reportTitle = [
    {
      columns: [
        {
          image: logoData,
          width: 50,
          margin: [15, 20, 0, 45], // left, top, right, bottom
        },
        {
          text: `Resumo de Unidades Consumidoras`,
          fontSize: 15,
          bold: true,
          margin: [30, 20, 0, 45],
        },
      ],
    },
  ];
  const generateCell = (text) => ({
    text,
    fontSize: 4,
    margin: [0, 2, 0, 2],
    alignment: 'center',
  });

  const generateHeaderCell = (text) => ({
    ...generateCell(text),
    style: 'tableHeader',
    bold: true,
  });

  const generateHeaderRow = [
    generateHeaderCell('Unidade Consumidora'),
    generateHeaderCell('Código Concessionária'),
    generateHeaderCell('Concessionária'),
    generateHeaderCell('Gerência Regional'),
    generateHeaderCell('Gerência Geral'),
    generateHeaderCell('Unidade Operacional'),
    generateHeaderCell('Código da Unidade'),
    generateHeaderCell('Cidade'),
    generateHeaderCell('Endereço'),
    generateHeaderCell('Cogeração'),
    generateHeaderCell('CSBIO'),
    generateHeaderCell('Crédito de Carbono'),
    generateHeaderCell('Geradora'),
    generateHeaderCell('Beneficiária'),
    generateHeaderCell('Modalidade'),
    generateHeaderCell('Telemetria'),
    generateHeaderCell('Código do Local'),
    generateHeaderCell('Descrição da Unidade'),
    generateHeaderCell('Tensão'),
    generateHeaderCell('Subgrupo'),
    generateHeaderCell('Status'),
    generateHeaderCell('Código do Orgão Pagador'),
    generateHeaderCell('Ínicio Vigência'),
    generateHeaderCell('Fim Vigência'),
    generateHeaderCell('Atividade'),
    generateHeaderCell('Código do Faturamento'),
  ];

  const renderData = data?.map((log) => [
    generateCell(log.identification_number),
    generateCell(log.cod_concessionaria),
    generateCell(log.concessionaire),
    generateCell(log.regional_management),
    generateCell(log.general_management),
    generateCell(log.operating_unity),
    generateCell(log.cod_unidade),
    generateCell(log.city),
    generateCell(log.address),
    generateCell(log.cogeracao),
    generateCell(log.csbio),
    generateCell(log.carbon_credit),
    generateCell(log.geradora),
    generateCell(log.beneficiaria),
    generateCell(log.modalidade),
    generateCell(log.telemetry),
    generateCell(log.cod_local),
    generateCell(log.descricao_unidade),
    generateCell(log.tensao),
    generateCell(log.subgrupo),
    generateCell(log.ativo),
    generateCell(log.cod_orgao_pagador),
    generateCell(formatDate(log.inicio_vigencia)),
    generateCell(formatDate(log.fim_vigencia)),
    generateCell(log.atividade),
    generateCell(log.cod_faturamento),
  ]);

  const details = [
    {
      table: {
        headerRows: 1,
        widths: [
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
        ],
        body: [generateHeaderRow, ...renderData],
        layout: 'autowidth',
      },
      layout: {
        fillColor: (rowIndex) => (rowIndex % 2 === 0 ? '#CCCCCC' : null),
      },
    },
  ];

  const summaryTable = {
    table: {
      widths: ['auto', 'auto'],
      body: [
        [
          {
            text: 'Resumo',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
            colSpan: 2,
            fillColor: '#CCCCCC',
          },
          {},
        ],
        [
          {
            text: 'Total de Unidades Consumidoras',
            style: 'tableHeader',
            fontSize: 8,
            alignment: 'left',
            bold: true,
          },
          {
            text: totalConsumerGroups,
            fontSize: 8,
            alignment: 'left',
          },
        ],
      ],
    },
    margin: [5, 600, 0, 40],
  };

  const renderFooter = (currentPage, pageCount) => {
    return {
      text: currentPage + ' / ' + pageCount,
      alignment: 'right',
      fontSize: 7,
      bold: true,
      margin: [0, 10, 20, 0], // left, top, right, bottom
    };
  };

  const reportContent = [...details, summaryTable];

  const docDefinitions = {
    pageMargins: [12, 50, 15, 40],
    content: [reportContent],
    footer: renderFooter,
    pageSize: 'A4',
    pageOrientation: 'landscape',
  };

  docDefinitions.header = function (currentPage, pageCount) {
    if (currentPage === 1) {
      return reportTitle;
    }
  };

  pdfMake.createPdf(docDefinitions).download();
}
