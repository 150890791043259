import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { Grow } from '@material-ui/core/';
import { useHistory, useLocation } from 'react-router-dom';
import PropType from 'prop-types';
import moment from 'moment';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseAlertLoadingCards,
  IoseAddButton,
  IoseMapRedirectButton,
  IoseBarChartConsumerGroup,
  IoseNoDataMessage,
  SummaryBillDataTable,
  IoseButton2,
  LoadingIndicator,
  TextField,
  IoseBillsAuditModal,
} from 'components';

import {
  useContracts,
  useConsumerGroupBills,
  useConsumerGroupData,
  useConsumerGroupFaturas,
} from 'hooks';

import { useClient, LocalStorage } from 'common';

import { energyBillByConsumerGroupReport } from 'reports';

//Auxiliar functions
function transformContracts(contracts) {
  const today = moment().startOf('day');

  const formattedContracts = contracts?.map((contract) => {
    const finalDate = moment(contract.final_date).startOf('day');
    const initialDate = moment(contract.initial_date).startOf('day');

    const formattedFinalDate = finalDate.format('DD/MM/YYYY');
    const formattedInitialDate = initialDate.format('DD/MM/YYYY');

    const daysDiff = finalDate.diff(today, 'days');

    return {
      ...contract,
      expires_in: daysDiff,
      final_date: formattedFinalDate,
      initial_date: formattedInitialDate,
    };
  });

  return formattedContracts;
}

function formatEnergyBills(bills) {
  const updatedList = bills?.map((obj) => {
    const referenceMonth = obj.reference_month;
    const referenceParts = referenceMonth.split('/');
    let monthYear;

    if (referenceParts.length === 2) {
      monthYear = referenceMonth;
    } else {
      monthYear = referenceParts[1] + '/' + referenceParts[2];
    }

    return { ...obj, month_year: monthYear };
  });

  return updatedList;
}

function filterContracts(filteredConsumerGroup, contracts, allContracts) {
  const contract = allContracts?.find(
    (contract) =>
      contract.contract_number === filteredConsumerGroup?.contract_number
  );

  return contract || null;
}

function exportToExcel(data) {
  // Reordena as colunas
  const reorderedColumns = [
    'unidade_consumidora',
    'mes_referencia',
    'qtd_paginas',
    'total',
  ];

  // Reordena os dados
  const reorderedData = data?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    unidade_consumidora: 'Unidade Consumidora',
    mes_referencia: 'REF: MÊS/ANO',
    qtd_paginas: 'Qtd Páginas',
    total: 'Valor da Fatura',
  };

  // Altere o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas');
  XLSX.writeFile(workbook, 'faturas-iose.xlsx');
}

function handleDownloadButtonClick(data) {
  exportToExcel(data);
}

export default function ConsumerGroupDashContainer() {
  const [allContracts, setAllContracts] = useState([]);
  const [clientData, setClientData] = useState({});
  const [completeBillData, setCompleteBillData] = useState([]);
  const [consumptionByMonth, setConsumptionByMonth] = useState([]);
  const [currentBillData, setCurrentBillData] = useState([]);
  const [error, setError] = useState('');
  const [filteredContract, setFilteredContract] = useState('');
  const [filteredConsumerGroup, setFilteredConsumerGroup] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [mapConsumerGroup, setMapConsumerGroup] = useState('');
  const [faturasCompletas, setFaturasCompletas] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const history = useHistory();
  const { client } = useClient();
  const { state } = useLocation();
  const { contracts } = useContracts();
  const { consumerGroup } = useConsumerGroupData(mapConsumerGroup);
  const { bills, refreshBills } = useConsumerGroupBills(mapConsumerGroup);
  const { faturas, refreshFaturas } = useConsumerGroupFaturas(mapConsumerGroup);

  useEffect(() => {
    if (consumerGroup?.length > 0) {
      setFilteredConsumerGroup(consumerGroup[0]);
    }
  }, [consumerGroup]);

  useEffect(() => {
    const mapConsumerGroup = state;

    setMapConsumerGroup(mapConsumerGroup);
  }, [state]);

  useEffect(() => {
    const faturasAtualizadas = mergeObjects(faturas, completeBillData);
    setFaturasCompletas(faturasAtualizadas);
  }, [faturas, completeBillData, bills]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredContract = filterContracts(
      filteredConsumerGroup,
      contracts,
      allContracts
    );

    setFilteredContract(filteredContract);
    setLoading(false);
  }, [filteredConsumerGroup, contracts, allContracts]);

  useEffect(() => {
    calculateConsumptionByMonth(currentBillData);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBillData]);

  useEffect(() => {
    processBillsData(bills);
  }, [bills]);

  useEffect(() => {
    const transformedContracts = transformContracts(contracts);
    setAllContracts(transformedContracts);
  }, [contracts]);

  useEffect(() => {
    const transformedBills = formatEnergyBills(bills);
    setCurrentBillData(transformedBills);
  }, [bills]);

  const openAuditModal = (rowData) => {
    if (openModal) {
      setOpenModal(false);
    } else {
      setSelectedRowData(rowData);
      setOpenModal(true);
    }
  };

  function redirectToMapPage() {
    history.push('/map');
  }
  function redirectToMapTelemetryPage() {
    history.push('/map-telemetry');
  }

  // function redirectToBillsPage() {
  //   history.push({
  //     pathname: '/energy-bills-audit',
  //     state: faturasCompletas,
  //   });
  // }

  function convertNumberToDecimalStyle(number = 0) {
    const decimalNumber = number.toLocaleString('pt-br');
    return decimalNumber;
  }

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  function calculateConsumptionByMonth(currentBillData) {
    const consumptionByMonth = {};

    currentBillData
      ?.filter((obj) => obj.identification_number === mapConsumerGroup)
      ?.forEach((obj) => {
        const monthYear = obj.month_year;

        if (!consumptionByMonth.hasOwnProperty(monthYear)) {
          consumptionByMonth[monthYear] = 0;
        }

        for (let i = 1; i <= 12; i++) {
          consumptionByMonth[monthYear] += obj[`kwh_consumption_${i}`] || 0;
        }
      });

    const sortedDates = Object.keys(consumptionByMonth).sort((a, b) => {
      const [monthA, yearA] = a.split('/');
      const [monthB, yearB] = b.split('/');

      const dateA = new Date(yearA, monthA - 1);
      const dateB = new Date(yearB, monthB - 1);

      return dateA - dateB;
    });

    const sortedConsumption = sortedDates.map(
      (date) => consumptionByMonth[date]
    );

    const result = {
      date: sortedDates,
      consumption_kwh: sortedConsumption,
    };

    setConsumptionByMonth(result);
  }

  function processBillsData(energyBills) {
    const result = [];

    energyBills?.forEach((data) => {
      const keys = Object.keys(data).filter((key) =>
        /^item_\w+_\d+$/.test(key)
      );

      keys.sort((a, b) => {
        const numA = parseInt(a.substring(a.lastIndexOf('_') + 1));
        const numB = parseInt(b.substring(b.lastIndexOf('_') + 1));

        return numA - numB;
      });

      const processedData = {};

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const index = key.substring(key.lastIndexOf('_') + 1);
        const propertyName = key.substring(
          key.indexOf('_') + 1,
          key.lastIndexOf('_')
        );
        const value = data[key];

        if (!processedData[index]) {
          processedData[index] = {};
        }

        processedData[index][propertyName] = value;
      }

      // // Filtrar objetos com todos os valores iguais a zero, '0' ou nulos
      // const filteredResult = Object.values(processedData).filter((obj) => {
      //   const values = Object.values(obj);
      //   return !values.every(
      //     (value) => value === 0 || value === '0' || value === null
      //   );
      // });

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const index = key.substring(key.lastIndexOf('_') + 1);
        const propertyName = key.substring(
          key.indexOf('_') + 1,
          key.lastIndexOf('_')
        );
        const value = data[key];

        if (!processedData[index]) {
          processedData[index] = {};
        }

        processedData[index][propertyName] = value;
        // Mantenha o campo original
        processedData[index][`${propertyName}_original`] = key;
      }

      const lines = 12;
      const properties = [
        'measurer',
        'magnitude',
        'timetables',
        'measurer_previous_reading',
        'measurer_current_reading',
        'measurer_constant',
        'kwh_consumption',
      ];

      const measurer_data = {};

      properties.forEach((property) => {
        for (let i = 1; i <= lines; i++) {
          measurer_data[`${property}_${i}`] = data?.[`${property}_${i}`];
        }
      });

      const taxes_data = {
        icms_calculation_basis_total: data?.icms_calculation_basis_total,
        cofins_calculation_basis_total: data?.cofins_calculation_basis_total,
        pis_calculation_basis_total: data?.pis_calculation_basis_total,
        icms_aliquot: data?.icms_aliquot,
        cofins_aliquot: data?.cofins_aliquot,
        pis_aliquot: data?.pis_aliquot,
        icms_total: data?.icms_total,
        cofins_total: data?.cofins_total,
        pis_total: data?.pis_total,
      };

      const itensLines = 17;
      const itensProperties = [
        'item_description',
        'item_unity',
        'item_consumption',
        'item_unitary_value',
        'item_total_value',
        'item_piscofins',
        'item_icms',
        'item_tax',
      ];

      const itens_data = {};

      itensProperties.forEach((property) => {
        for (let i = 1; i <= itensLines; i++) {
          itens_data[`${property}_${i}`] = data?.[`${property}_${i}`];
        }
      });

      const completeBillData = {
        uuid_energy_bill: data?.uuid_energy_bill,
        bill_name: data?.bill_name,
        classification: data?.classification,
        identification_number: data?.identification_number,
        reference_month: data?.reference_month,
        number_days: data?.number_days,
        current_reading: data?.current_reading,
        previous_reading: data?.previous_reading,
        measurer: data?.measurer,
        account_value: data?.account_value,
        cnpj: data?.cnpj,
        pdf_name: data?.pdf_name,

        measurer_data: measurer_data,

        taxes_data: taxes_data,

        itens_data: itens_data,

        table_data: Object.values(processedData),
      };

      result.push(completeBillData);
    });

    //sort the array by reference_month
    result.sort((a, b) => {
      const [monthA, yearA] = a.reference_month.split('/');
      const [monthB, yearB] = b.reference_month.split('/');

      const currentDate = new Date();

      const dateA = new Date(yearA, monthA - 1);
      const dateB = new Date(yearB, monthB - 1);

      const diffA = Math.abs(currentDate - dateA);
      const diffB = Math.abs(currentDate - dateB);

      if (diffA < diffB) {
        return -1;
      } else if (diffA > diffB) {
        return 1;
      } else {
        return 0;
      }
    });

    result.reverse();

    setCompleteBillData(result);
  }

  function mergeObjects(faturas, completeBillData) {
    return faturas?.map((fatura) => {
      const completeData = completeBillData.filter(
        (bill) =>
          bill.reference_month === fatura.mes_referencia &&
          bill.previous_reading === fatura.leitura_anterior
      );

      if (completeData) {
        const energyBills = completeData?.map((billItem) => ({
          ...billItem,
          link_completo: `${fatura.link_pdf}${billItem.pdf_name}`,
        }));

        return {
          ...fatura,
          energyBills,
        };
      }

      return fatura;
    });
  }

  const renderSubHeaderAndSearchBar = () => {
    // const showButton = group === 'super' || group === 'admin' ? true : false;

    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle={`Dashboard da Unidade Consumidora ${mapConsumerGroup}`}
        // description={'Total: ' + allContracts.length}
        button={showButton ? button : <></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          {/* <IoseBillsRedirectButton
            tooltip={`FATURAS DA UNIDADE CONSUMIDORA ${mapConsumerGroup}`}
            bottom="5px"
            onClick={() => redirectToBillsPage()}
          /> */}

          <IoseMapRedirectButton
            tooltip="RETORNAR PARA O MAPA"
            bottom="5px"
            onClick={() => redirectToMapPage()}
          />
          <IoseMapRedirectButton
            tooltip="RETORNAR PARA O MAPA DE TELEMETRIA"
            bottom="5px"
            onClick={() => redirectToMapTelemetryPage()}
          />
        </div>
      </IoseSubHeaderBar>
    );
  };

  function renderContractOrErrorMessage() {
    if (
      !filteredContract ||
      Object.keys(filteredContract).length === 0 ||
      Object.values(filteredContract).some((value) => value === undefined)
    ) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que essa unidade consumidora não possui contrato!"
          height="250px"
          width="250px"
        />
      );
    } else {
      return (
        <>
          <div style={{ display: 'flex' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>DADOS DO CONTRATO</S.TypographyTitle>
          </div>
          <div style={{ display: 'flex', gap: ' 10px' }}>
            <div style={{ width: '50%' }}>
              <TextField
                label="Número do contrato"
                value={filteredContract.contract_number}
              />
              <TextField
                label="Tipo de mercado"
                value={filteredContract.market_type}
              />
              <TextField
                label="Concessionária"
                value={filteredContract.concessionaire}
              />
              <TextField
                label="Vigência do contrato"
                value={filteredContract.term_contract}
              />
              <TextField
                label="índice de reajuste"
                value={filteredContract.readjustment_index}
              />
            </div>
            <div style={{ width: '50%' }}>
              <TextField
                label="Taxa de reajuste"
                value={filteredContract.readjustment_rate}
              />
              <TextField
                label="Valor total do contrato"
                value={`R$ ${convertNumberToDecimalStyle(
                  filteredContract.total_contracts
                )}`}
              />
              <TextField
                label="Valor mensal do contrato"
                value={`R$ ${convertNumberToDecimalStyle(
                  filteredContract.monthly_value
                )}`}
              />
              <TextField
                label="Data inicial"
                value={filteredContract.initial_date}
              />
              <TextField
                label="Data final"
                value={filteredContract.final_date}
              />
            </div>
          </div>
        </>
      );
    }
  }

  const renderContractData = () => {
    return (
      <S.ContractDataContainer>
        {renderContractOrErrorMessage()}
      </S.ContractDataContainer>
    );
  };

  function renderDashOrErrorMessage() {
    if (!consumptionByMonth || consumptionByMonth?.date?.length === 0) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
          height="150px"
          width="150px"
        />
      );
    } else {
      return <IoseBarChartConsumerGroup data={consumptionByMonth} />;
    }
  }

  const renderDashboard = () => {
    return (
      <S.DashboardContainer>
        <div style={{ display: 'flex', marginBottom: '5px' }}>
          <S.ContractsIcon />
          <S.TypographyTitle>
            GRÁFICO DE CONSUMO DA UNIDADE CONSUMIDORA (kWh)
          </S.TypographyTitle>
        </div>
        {renderDashOrErrorMessage()}
      </S.DashboardContainer>
    );
  };

  function renderTableOrErrorMessage() {
    if (!consumptionByMonth || consumptionByMonth?.date?.length === 0) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="150px"
          width="150px"
        />
      );
    } else {
      return (
        <SummaryBillDataTable
          data={faturasCompletas}
          openModal={openModal}
          openAuditModal={openAuditModal}
        />
      );
    }
  }

  const renderTable = () => {
    return (
      <S.TableContainer>
        <S.HeaderContainer>
          <div style={{ display: 'flex' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>
              FATURAS DA UNIDADE CONSUMIDORA
            </S.TypographyTitle>
          </div>
          <div>
            <IoseButton2
              onClick={() => energyBillByConsumerGroupReport(faturasCompletas)}
              width="200px"
            >
              RELATÓRIO
            </IoseButton2>
            <IoseButton2
              onClick={() => handleDownloadButtonClick(faturasCompletas)}
              width="200px"
            >
              Download XLSX
            </IoseButton2>
          </div>
        </S.HeaderContainer>
        {renderTableOrErrorMessage()}
      </S.TableContainer>
    );
  };

  function renderConsumerGroupOrErrorMessage() {
    if (
      !filteredConsumerGroup ||
      Object.keys(filteredConsumerGroup).length === 0 ||
      Object.values(filteredConsumerGroup).some((value) => value === undefined)
    ) {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que essa unidade consumidora não tem dados!"
          height="250px"
          width="250px"
        />
      );
    } else {
      return (
        <>
          <div style={{ display: 'flex' }}>
            <S.ContractsIcon />
            <S.TypographyTitle>DADOS DA UNIDADE CONSUMIDORA</S.TypographyTitle>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ width: '50%' }}>
              <TextField
                label="Concessionária"
                value={filteredConsumerGroup.concessionaire}
              />
              <TextField
                label="Endereço"
                value={filteredConsumerGroup.address}
              />
              <TextField
                label="Gerência Regional"
                value={filteredConsumerGroup.regional_management}
              />
              <TextField
                label="Gerência Geral"
                value={filteredConsumerGroup.general_management}
              />
              <TextField
                label="Unidade Operacional"
                value={filteredConsumerGroup.operating_unity}
              />
            </div>
            <div style={{ width: '50%' }}>
              <TextField label="Cidade" value={filteredConsumerGroup.city} />
              <TextField
                label="Crédito de carbono"
                value={
                  filteredConsumerGroup.carbon_credit === 'true'
                    ? 'POSSUI'
                    : 'NÃO POSSUI'
                }
              />
              <TextField
                label="Telemetria"
                value={
                  filteredConsumerGroup.telemetry === 'SIM'
                    ? 'POSSUI'
                    : 'NÃO POSSUI'
                }
              />
              <TextField
                label="Coordenada X"
                value={filteredConsumerGroup.coordinate_x}
              />
              <TextField
                label="Coordenada Y"
                value={filteredConsumerGroup.coordinate_y}
              />
            </div>
          </div>
        </>
      );
    }
  }

  const renderBillModel = () => {
    return (
      <S.ConsumerGroupDataContainer>
        {renderConsumerGroupOrErrorMessage()}
      </S.ConsumerGroupDataContainer>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              width: '98%',
            }}
          >
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {renderContractData()}
                {renderBillModel()}
                {renderDashboard()}
                {renderAuditModal()}
              </div>
              <div
                style={{
                  width: '100%',
                }}
              >
                {renderTable()}
              </div>
            </>
          </div>
        </>
      );
    }
  };

  const renderAuditModal = () => {
    return (
      <IoseBillsAuditModal
        open={openModal}
        onClose={openAuditModal}
        data={selectedRowData}
        refreshBills={refreshBills}
        refreshFaturas={refreshFaturas}
      />
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          <>{renderContent()}</>
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

ConsumerGroupDashContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
