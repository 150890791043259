import React, { useEffect, useState, useContext } from 'react';
import PropType from 'prop-types';
import { Slide } from '@material-ui/core/';

import {
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseInfinityScroll,
  IoseSubHeaderBar,
  IoseSearch,
  IoseObjectAddModal,
  IoseObjectEditModal,
  IoseCardObjects,
} from 'components';

import { AccountContext, ioseAPI, LocalStorage, Messages } from 'common';

import { ContainerCards, WrapContainer } from './style';

import {
  useObjects,
  useEnvironments,
  useClientData,
  useUnitys,
  useCircuits,
  useCreateLogs,
  useGroupPermissions,
} from 'hooks';
import Permissions from 'common/permissions';

export default function ObjectContainer({ getcards }) {
  const [addNotificationType, setAddNotificationType] = useState(0);
  const [cards, setCards] = useState([]);
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [editNotificationType, setEditNotificationType] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [search, setSearch] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState();
  const [hasEditPermission, setHasEditPermission] = useState();

  const { getSession } = useContext(AccountContext);
  const { objects, noObjectsError, refreshObjects } = useObjects();
  const { environments } = useEnvironments();
  const { clientData, uuidClient } = useClientData();
  const { unitys } = useUnitys(uuidClient);
  const { circuits } = useCircuits(uuidClient);
  const { createLog } = useCreateLogs();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['cadastro', 'objetos', 'leitura'];
    const addPath = ['cadastro', 'objetos', 'criar'];
    const editPath = ['cadastro', 'objetos', 'editar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);

      const editPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        editPath
      );
      setHasEditPermission(editPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    if (hasReadPermission) {
      objects?.length > 0 ? setCards(objects) : setError(noObjectsError);
    }
  }, [objects, noObjectsError, hasReadPermission]);

  useEffect(() => {
    function handleSelectedEnvironment(selectedCard) {
      const selectedEnvironment = environments?.find(
        (environment) =>
          environment.uuid_environment === selectedCard?.uuid_environment
      );

      setSelectedEnvironment(selectedEnvironment);
    }
    handleSelectedEnvironment(selectedCard);
  }, [selectedCard, environments]);

  useEffect(() => {
    function handleSelectedObject(selectedCard) {
      if (selectedCard) {
        const uuidUnity = environments?.find(
          (environment) =>
            environment.uuid_environment === selectedCard.uuid_environment
        )?.uuid_unity;

        const newObject = {
          uuid_object: selectedCard.uuid_object,
          uuid_circuit: selectedCard.uuid_circuit
            ? selectedCard.uuid_circuit
            : 'Não cadastrado',
          code_module: selectedCard.code_module
            ? selectedCard.code_module
            : 'Não cadastrado',
          uuid_unity: uuidUnity ? uuidUnity : 'Não cadastrado',
          name: selectedCard.name ? selectedCard.name : 'Não cadastrado',
          voltage: selectedCard.voltage
            ? selectedCard.voltage
            : 'Não cadastrado',
          power: selectedCard.power ? selectedCard.power : 'Não cadastrado',
          current: selectedCard.current
            ? selectedCard.current
            : 'Não cadastrado',
          temperature: selectedCard.temperature
            ? selectedCard.temperature
            : 'Não cadastrado',
          condition: selectedCard.condition
            ? selectedCard.condition
            : 'Não cadastrado',
          uuid_environment: selectedCard.uuid_environment
            ? selectedCard.uuid_environment
            : 'Não cadastrado',
          charge_type: selectedCard.charge_type
            ? selectedCard.charge_type
            : 'Não cadastrado',
          id_3d: selectedCard.id_3d,
        };

        setSelectedObject(newObject);
      }
    }
    handleSelectedObject(selectedCard, selectedEnvironment);
  }, [selectedCard, selectedEnvironment, environments]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedCard(null);
    setOpenEditModal(false);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const filterObjects = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppcase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards.filter((item) =>
        item.name.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered?.length !== 0
        ? setCardsFiltered(itemFiltered)
        : setError(Messages.noFindEnvironments);
    } else {
      setSearch(false);
    }
  };

  const renderCards = (card) => {
    return (
      <IoseCardObjects
        key={card.uuid_object}
        name={card.name}
        voltage={card.voltage}
        power={card.power}
        current={card.current}
        temperature={card.temperature}
        description={card.description}
        condition={card.condition}
        uuid_environment={card.uuid_environment}
        charge_type={card.charge_type}
        environments={environments}
        handleOpenEditModal={handleOpenEditModal}
        onClose={handleCloseEditModal}
        onClick={() => handleCardClick(card)}
        showEditButton={hasEditPermission}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="Criar Objeto"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle="Objetos"
        description={'Total: ' + cards?.length}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar Objetos…"
          funcSearch={filterObjects}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderAllCards = () => {
    return (
      <ContainerCards>
        {search ? cardsFiltered?.map(renderCards) : cards?.map(renderCards)}
      </ContainerCards>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards?.length}
            next={() => {}}
            hasMore={true}
            loading={loading}
            endList={false}
            scrollableTarget={'container'}
          >
            {hasReadPermission
              ? renderAllCards()
              : setError(
                  'Seu grupo de usuários não tem permissão de leitura destes dados!'
                )}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <IoseObjectAddModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          environments={environments}
          allCircuits={circuits}
          selectedObject={selectedObject}
          addObjects={addObjects}
          loading={loading}
          notification={notification}
          addNotificationType={addNotificationType}
          showNotification={showNotification}
          unitys={unitys}
        />
        <IoseObjectEditModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          environments={environments}
          allCircuits={circuits}
          selectedObject={selectedObject}
          editObject={editObject}
          loading={loading}
          notification={notification}
          editNotificationType={editNotificationType}
          showNotification={showNotification}
          unitys={unitys}
        />
      </>
    );
  };
  ////////////////// OPERATIONS //////////////////

  function errorHandlingAddObjects(error) {
    setLoading(false);

    setNotification(error);
    setAddNotificationType(2);
  }

  async function addObjects(
    uuid_environment,
    uuid_circuit,
    name,
    voltage,
    power,
    current,
    temperature,
    condition,
    charge_type,
    id_3d
  ) {
    setAddNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.createObjects(
        sessionData.headers,
        uuid_environment,
        uuid_circuit,
        name,
        voltage,
        power,
        current,
        temperature,
        condition,
        charge_type,
        id_3d
      );

      setLoading(false);
      setNotification(response.data.message);
      setAddNotificationType(1);
      setShowNotification(true);

      const operacao = 'CRIAR';
      const form = 'CRIAR OBJETOS';
      const logContent = {
        uuid_environment: uuid_environment,
        uuid_circuit: uuid_circuit,
        name: name,
        voltage: voltage,
        power: power,
        current: current,
        temperature: temperature,
        condition: condition,
        charge_type: charge_type,
        id_3d: id_3d,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.objectsNoRegistered;

      errorHandlingAddObjects(errorMessage);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setTimeout(() => {
        handleCloseAddModal();
        refreshObjects();
        setAddNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  function errorHandlingEditUnity(error) {
    setLoading(false);
    setNotification(error);
    setEditNotificationType(2);
  }

  async function editObject(
    uuid_object,
    uuid_environment,
    uuid_circuit,
    name,
    voltage,
    power,
    current,
    temperature,
    condition,
    charge_type,
    id_3d
  ) {
    setEditNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.updateObjects(
        sessionData.headers,
        uuid_object,
        uuid_environment,
        uuid_circuit,
        name,
        voltage,
        power,
        current,
        temperature,
        condition,
        charge_type,
        id_3d
      );

      setNotification(response.data.message);
      setEditNotificationType(1);
      setShowNotification(true);

      const operacao = 'EDITAR';
      const form = 'EDITAR OBJETOS';
      const logContent = {
        uuid_object: uuid_object,
        uuid_environment: uuid_environment,
        uuid_circuit: uuid_circuit,
        name: name,
        voltage: voltage,
        power: power,
        current: current,
        temperature: temperature,
        condition: condition,
        charge_type: charge_type,
        id_3d: id_3d,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.objectsNoRegistered;

      errorHandlingEditUnity(errorMessage);
      setEditNotificationType(2);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setTimeout(() => {
        handleCloseEditModal();
        refreshObjects();
        setEditNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  ////////////////// OPERATIONS //////////////////

  return (
    <Slide direction="up" in={true} timeout={600} mountOnEnter unmountOnExit>
      <WrapContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </WrapContainer>
    </Slide>
  );
}

ObjectContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
