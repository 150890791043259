import { useEffect, useCallback, useState } from 'react';
import ioseApiLib from 'iose-api-lib';

const useUnitys = (uuidClient) => {
  const [unitys, setUnitys] = useState([]);

  const getAllUnitys = useCallback(async (uuid_client, nextpage = 0) => {
    try {
      let response = await ioseApiLib.Unity.getAllUnity(uuid_client, nextpage);
      return response;
    } catch (err) {
      throw err;
    }
  }, []);

  const getAllUnity = useCallback(async () => {
    let allUnitys = [];
    allUnitys = await getAllUnitys(uuidClient);

    setUnitys(allUnitys.data);
  }, [getAllUnitys, uuidClient]);

  useEffect(() => {
    getAllUnity(uuidClient);
  }, [getAllUnity, uuidClient]);

  return {
    unitys,
  };
};
export default useUnitys;
