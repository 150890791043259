import React from 'react';
import ReactECharts from 'echarts-for-react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';

export default function IoseChartCredit({ filteredData }) {
  const filteredBeneficiaries = filteredData;

  const filterBeneficary = filteredBeneficiaries?.map((item) => ({
    ...item,
    uc_beneficiarias: item.uc_beneficiarias?.filter(
      (beneficiary) => beneficiary.uc_beneficiaria !== item.uc_geradora
    ),
  }));

  const allBeneficiarias = [];

  filterBeneficary?.forEach((item) => {
    const ucBeneficiarias = item.uc_beneficiarias || [];
    ucBeneficiarias.forEach((beneficiaria) => {
      allBeneficiarias.push(beneficiaria);
    });
  });

  const option = {
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ['line', 'bar'] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        // Verifica se o valor é válido e converte para número antes de formatar
        if (
          params.length > 0 &&
          params[0].value !== undefined &&
          params[0].value !== null
        ) {
          const numericValue =
            typeof params[0].value === 'string'
              ? parseFloat(params[0].value)
              : params[0].value;

          return numericValue.toLocaleString('pt-BR');
        }

        return 'Sem dados';
      },
    },
    grid: {
      width: '90%%',
      left: '4%',
      right: '2%',
      top: '19%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      axisLabel: { interval: 0, rotate: 30 },
    },
    yAxis: [
      {
        type: 'value',
        name: 'Energia Consumida (kWh)',
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      },
    ],
    series: [
      {
        type: 'bar',
        encode: { x: 'name', y: 'score' },
        itemStyle: {
          color: function (params) {
            var credRecebido = params.data.cred_recebido;
            return credRecebido <= 0 ? 'red' : '#015A9E';
          },
        },
        emphasis: {
          focus: 'series',
        },
      },
    ],
  };

  const option2 = {
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ['line', 'bar'] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        return params[0].value.toLocaleString('pt-BR');
      },
    },
    grid: {
      width: '90%',
      left: '4%',
      right: '2%',
      top: '19%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      axisLabel: { interval: 0, rotate: 30 },
    },
    yAxis: [
      {
        type: 'value',
        name: 'Energia Gerada (kWh)',
        axisLabel: {
          formatter: function (value) {
            return value.toLocaleString('pt-BR');
          },
        },
      },
    ],
    series: [
      {
        type: 'bar',
        encode: { x: 'name', y: 'score' },
        itemStyle: {
          color: function (params) {
            var score = params.data.score;
            return score <= 315 ? 'red' : '#015A9E';
          },
        },
        emphasis: {
          focus: 'series',
        },
      },
    ],
  };

  const renderChartOrError = (data) => {
    return (
      <>
        {data.length > 0 ? (
          <ReactECharts option={option} style={{ height: '300px' }} />
        ) : (
          <IoseNoDataMessage
            message="Ops... Parece que essa unidade consumidora não possui unidades beneficiárias."
            height="250px"
            width="220px"
          />
        )}
      </>
    );
  };

  const xAxisData = allBeneficiarias.map((item) => item.uc_beneficiaria);
  const seriesData = allBeneficiarias.map((item) => item.cred_recebido);

  console.log('seriesData:', seriesData);

  option.xAxis.data = xAxisData;
  option.series[0].data = seriesData;

  const ucGeradora = filterBeneficary[0]?.uc_geradora || '';
  const total = filterBeneficary[0]?.gerados_fpta || 0;

  const xAxisData2 = [ucGeradora];
  const seriesData2 = [total];

  option2.xAxis.data = xAxisData2;
  option2.series[0].data = seriesData2;

  return (
    <div>
      <ReactECharts option={option2} style={{ height: '300px' }} />
      {renderChartOrError(seriesData)}
    </div>
  );
}
