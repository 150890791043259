import React, { useEffect, useState } from 'react';

import * as S from './style';

import {
  TrendingUp as TrendingUpIcon,
  Equalizer as EqualizerIcon,
  ShowChart as ShowChartIcon,
} from '@material-ui/icons';

const getIcon = (title) => {
  switch (title) {
    case 'Total gerado no mês':
      return <TrendingUpIcon />;
    case 'Total gerado no ano':
      return <ShowChartIcon />;
    case 'Total gerado':
      return <EqualizerIcon />;
    default:
      return null;
  }
};

export default function IoseAdmDashListSummarySolar({ data }) {
  const [totalSum, setTotalSum] = useState(0);
  const [totalMonth, setTotalMonth] = useState(0);
  const [totalYear, setTotalYear] = useState(0);

  useEffect(() => {
    function transformAndSumData(data) {
      let totalSum = 0;
      let totalMonth = 0;
      let totalYear = 0;

      data?.forEach((item) => {
        totalSum += parseFloat(item.total);
        totalMonth += parseFloat(item.month);
        totalYear += parseFloat(item.year);
      });

      return { totalSum, totalMonth, totalYear };
    }

    const { totalSum, totalMonth, totalYear } = transformAndSumData(data);

    setTotalSum(totalSum);
    setTotalMonth(totalMonth);
    setTotalYear(totalYear);
  }, [data]);

  const SummaryUnitysData = [
    {
      title: 'Total gerado no mês',
      value: convertNumberToDecimalStyle(totalMonth) + ' kWh',
    },
    {
      title: 'Total gerado no ano',
      value: convertNumberToDecimalStyle(totalYear) + ' kWh',
    },
    {
      title: 'Total gerado',
      value: convertNumberToDecimalStyle(totalSum - 0) + ' kWh',
    },
  ];

  function convertNumberToDecimalStyle(number = 0) {
    const decimalNumber = number.toLocaleString('pt-br');
    return decimalNumber;
  }

  return (
    <S.SummaryListContainer>
      <S.TitleList>RESUMO</S.TitleList>
      <S.ListContainer>
        {SummaryUnitysData.map(({ title, value }) => (
          <S.ListStyled key={title}>
            <S.SummaryIconWrapper>{getIcon(title)}</S.SummaryIconWrapper>
            <S.ListItemTextStyled primary={title} secondary={value} />
          </S.ListStyled>
        ))}
      </S.ListContainer>
    </S.SummaryListContainer>
  );
}
