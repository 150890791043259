export const LogOperationOptions = [
  { value: 'CRIAR', label: 'CRIAR' },
  { value: 'EDITAR', label: 'EDITAR' },
  { value: 'DELETAR', label: 'DELETAR' },
  { value: 'EMITIR RELATÓRIO', label: 'EMITIR RELATÓRIO' },
  { value: 'LOGIN', label: 'LOGIN' },
  { value: 'LOGOUT', label: 'LOGOUT' },
];

export const GeneralManagement = [
  { value: 'GGML', label: 'GGML' },
  { value: 'GGND', label: 'GGND' },
  { value: 'GGNO', label: 'GGNO' },
  { value: 'GGSD', label: 'GGSD' },
  { value: 'GGSO', label: 'GGSO' },
  { value: '0', label: '0' },
];

export const AtividadeDaUc = [
  { value: 'AGUA/DISTRIB', label: 'AGUA/DISTRIB' },
  { value: 'ESGOTO      ', label: 'ESGOTO      ' },
  { value: 'AGUA/PRODUC ', label: 'AGUA/PRODUC ' },
  { value: 'AGUA/MISTA  ', label: 'AGUA/MISTA  ' },
  { value: 'AGUA/INDL   ', label: 'AGUA/INDL   ' },
  { value: 'RESID SOLIDO', label: 'RESID SOLIDO' },
  { value: 'OUTRAS ATIVI', label: 'OUTRAS ATIVI' },
];

export const Managements = [
  {
    'GGML': [
      { value: 'GEMCT', label: 'GEMCT' },
      { value: 'GPDAGC', label: 'GPDAGC' },
      { value: 'GPDAGM', label: 'GPDAGM' },
      { value: 'GPDAGS', label: 'GPDAGS' },
      { value: 'GRCTL', label: 'GRCTL' },
      { value: 'GRCTN', label: 'GRCTN' },
      { value: 'GRCTS', label: 'GRCTS' },
      { value: 'GRLI', label: 'GRLI' },
      { value: 'GTESG', label: 'GTESG' },
    ],
    'GGND': [
      { value: 'GEMND', label: 'GEMND' },
      { value: 'GIDLD', label: 'GIDLD' },
      { value: 'GRAP', label: 'GRAP' },
      { value: 'GRAR', label: 'GRAR' },
      { value: 'GRCP', label: 'GRCP' },
      { value: 'GRLC', label: 'GRLC' },
      { value: 'GRSP', label: 'GRSP' },
    ],
    'GGNO': [
      { value: 'GEMNO', label: 'GEMNO' },
      { value: 'GRCM', label: 'GRCM' },
      { value: 'GRMA', label: 'GRMA' },
      { value: 'GRUM', label: 'GRUM' },
    ],
    'GGSD': [
      { value: 'GRGA', label: 'GRGA' },
      { value: 'GRPG', label: 'GRPG' },
      { value: 'GRTB', label: 'GRTB' },
      { value: 'GRUV', label: 'GRUV' },
    ],
    'GGSO': [
      { value: 'GRCA', label: 'GRCA' },
      { value: 'GRFB', label: 'GRFB' },
      { value: 'GRFI', label: 'GRFI' },
      { value: 'GRPB', label: 'GRPB' },
      { value: 'GRTO', label: 'GRTO' },
    ],
  },
];

export const RegionalManagement = [
  { value: 'GACF', label: 'GACF' },
  { value: 'GDOP', label: 'GDOP' },
  { value: 'GEMCT', label: 'GEMCT' },
  { value: 'GEMND', label: 'GEMND' },
  { value: 'GEMNO', label: 'GEMNO' },
  { value: 'GESA', label: 'GESA' },
  { value: 'GGPINF', label: 'GGPINF' },
  { value: 'GHID', label: 'GHID' },
  { value: 'GIDLD', label: 'GIDLD' },
  { value: 'GPAG', label: 'GPAG' },
  { value: 'GPDAGC', label: 'GPDAGC' },
  { value: 'GPDAGM', label: 'GPDAGM' },
  { value: 'GPDAGS', label: 'GPDAGS' },
  { value: 'GPOND', label: 'GPOND' },
  { value: 'GPOSD', label: 'GPOSD' },
  { value: 'GRAP', label: 'GRAP' },
  { value: 'GRAR', label: 'GRAR' },
  { value: 'GRCA', label: 'GRCA' },
  { value: 'GRCM', label: 'GRCM' },
  { value: 'GRCP', label: 'GRCP' },
  { value: 'GRCTL', label: 'GRCTL' },
  { value: 'GRCTN', label: 'GRCTN' },
  { value: 'GRCTS', label: 'GRCTS' },
  { value: 'GRFB', label: 'GRFB' },
  { value: 'GRFI', label: 'GRFI' },
  { value: 'GRGA', label: 'GRGA' },
  { value: 'GRLC', label: 'GRLC' },
  { value: 'GRLI', label: 'GRLI' },
  { value: 'GRMA', label: 'GRMA' },
  { value: 'GRPB', label: 'GRPB' },
  { value: 'GRPG', label: 'GRPG' },
  { value: 'GRPV', label: 'GRPV' },
  { value: 'GRSP', label: 'GRSP' },
  { value: 'GRTB', label: 'GRTB' },
  { value: 'GRTO', label: 'GRTO' },
  { value: 'GRUM', label: 'GRUM' },
  { value: 'GRUV', label: 'GRUV' },
  { value: 'GSLOG', label: 'GSLOG' },
  { value: 'GTESG', label: 'GTESG' },
  { value: '0', label: '0' },
];

export const MonthOptions = [
  { value: '01', label: 'Janeiro' },
  { value: '02', label: 'Fevereiro' },
  { value: '03', label: 'Março' },
  { value: '04', label: 'Abril' },
  { value: '05', label: 'Maio' },
  { value: '06', label: 'Junho' },
  { value: '07', label: 'Julho' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Setembro' },
  { value: '10', label: 'Outubro' },
  { value: '11', label: 'Novembro' },
  { value: '12', label: 'Dezembro' },
  // Adicione todos os meses
];

export const YearOptions = [
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  // Adicione mais anos conforme necessário
];

export const VariableTypes = [
  {
    value: 'Grandeza',
    name: 'Grandeza',
    constant_type: [
      { value: 'Grandeza 1', name: 'Grandeza 1' },
      { value: 'Grandeza 2', name: 'Grandeza 2' },
      { value: 'Grandeza 3', name: 'Grandeza 3' },
    ],
  },
  {
    value: 'Fatura',
    name: 'Fatura',
    constant_type: [
      { value: 'Fatura 1', name: 'Fatura 1' },
      { value: 'Fatura 2', name: 'Fatura 2' },
      { value: 'Fatura 3', name: 'Fatura 3' },
    ],
  },
  {
    value: 'Credito',
    name: 'Crédito',
    constant_type: [
      { value: 'Credito 1', name: 'Credito 1' },
      { value: 'Credito 2', name: 'Credito 2' },
      { value: 'Credito 3', name: 'Credito 3' },
    ],
  },
  {
    value: 'Extrato',
    name: 'Extrato',
    constant_type: [
      { value: 'Extrato 1', name: 'Extrato 1' },
      { value: 'Extrato 2', name: 'Extrato 2' },
      { value: 'Extrato 3', name: 'Extrato 3' },
    ],
  },
];

export const OptionsBoolean = [
  {
    value: 'NAO',
    label: 'Não Possui',
  },
  {
    value: 'SIM',
    label: 'Possui',
  },
];

export const OptionsBoolean2 = [
  {
    value: 'NAO',
    label: 'Não',
  },
  {
    value: 'SIM',
    label: 'Sim',
  },
];

export const SubgrupoOptions = [
  {
    value: 'A4',
    label: 'A4',
  },
  {
    value: 'B3',
    label: 'B3',
  },
  {
    value: 'A3a',
    label: 'A3a',
  },
];

export const ModalidadeOptions = [
  {
    value: 'VERDE',
    label: 'Verde',
  },
  {
    value: 'AZUL',
    label: 'Azul',
  },
  {
    value: 'CONVENCIONAL',
    label: 'Convencional',
  },
];

export const ModalidadeB3Options = [

  {
    value: 'CONVENCIONAL',
    label: 'Convencional',
  },
];

export const GeralOptions = [
  {
    value: 'SIM',
    label: 'Possui',
  },
  {
    value: 'NAO',
    label: 'Não Possui',
  },
];

export const SimpleOptions = [
  {
    value: 'SIM',
    label: 'Sim',
  },
  {
    value: 'NAO',
    label: 'Não',
  },
];

export const PostoOptions = [
  {
    value: 'PONTA',
    label: 'Ponta',
  },
  {
    value: 'FORA PONTA',
    label: 'Fora Ponta',
  },
];

export const TarifaOptions = [
  {
    value: 'TUSD',
    label: 'TUSD',
  },
  {
    value: 'TE',
    label: 'TE',
  },
];

export const Months = [
  { value: '01', label: 'Janeiro' },
  { value: '02', label: 'Fevereiro' },
  { value: '03', label: 'Março' },
  { value: '04', label: 'Abril' },
  { value: '05', label: 'Maio' },
  { value: '06', label: 'Junho' },
  { value: '07', label: 'Julho' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Setembro' },
  { value: '10', label: 'Outubro' },
  { value: '11', label: 'Novembro' },
  { value: '12', label: 'Dezembro' },
];

export const Concessionarias = [
  { value: 'COPEL', label: 'COPEL' },
  // { value: 'CPFL', label: 'CPFL' },
  // { value: 'ELETROPAULO', label: 'ELETROPAULO' },
];

export const DaysToExpire = [
  { value: 10, name: '10 dias' },
  { value: 15, name: '15 dias' },
  { value: 30, name: '30 dias' },
  { value: 45, name: '45 dias' },
  { value: 60, name: '60 dias' },
  { value: 90, name: '90 dias' },
];

export const EnergyMarket = [
  { value: 'Livre', name: 'Livre' },
  { value: 'Outros', name: 'Outros' },
];

export const TermContract = [
  { value: '1 ano', name: '1 ano' },
  { value: '2 anos', name: '2 anos' },
  { value: '5 anos', name: '5 anos' },
];

export const ReadjustmentIndex = [
  { value: 'Mensal', name: 'Mensal' },
  { value: 'Trimestral', name: 'Trimestral' },
  { value: 'Semestral', name: 'Semestral' },
  { value: 'Anual', name: 'Anual' },
];

export const ReadjustmentRate = [
  { value: 'IGPM', name: 'IGPM' },
  { value: 'IPCA', name: 'IPCA' },
];

export const PrCities = [
  {
    'id_cidade': 4100103,
    'nomeCidade': '0',
  },
  {
    'id_cidade': 4100103,
    'nomeCidade': 'ABATIA',
  },
  {
    'id_cidade': 4100202,
    'nomeCidade': 'ADRIANOPOLIS',
  },
  {
    'id_cidade': 4100301,
    'nomeCidade': 'AGUDOS DO SUL',
  },
  {
    'id_cidade': 4100400,
    'nomeCidade': 'ALMIRANTE TAMANDARE',
  },
  {
    'id_cidade': 4100459,
    'nomeCidade': 'ALTAMIRA DO PARANA',
  },
  {
    'id_cidade': 4128625,
    'nomeCidade': 'ALTO PARAISO',
  },
  {
    'id_cidade': 4100608,
    'nomeCidade': 'ALTO PARANA',
  },
  {
    'id_cidade': 4100707,
    'nomeCidade': 'ALTO PIQUIRI',
  },
  {
    'id_cidade': 4100509,
    'nomeCidade': 'ALTONIA',
  },
  {
    'id_cidade': 4100806,
    'nomeCidade': 'ALVORADA DO SUL',
  },
  {
    'id_cidade': 4100905,
    'nomeCidade': 'AMAPORA',
  },
  {
    'id_cidade': 4101002,
    'nomeCidade': 'AMPERE',
  },
  {
    'id_cidade': 4101051,
    'nomeCidade': 'ANAHY',
  },
  {
    'id_cidade': 4101101,
    'nomeCidade': 'ANDIRA',
  },
  {
    'id_cidade': 4101150,
    'nomeCidade': 'ANGULO',
  },
  {
    'id_cidade': 4101200,
    'nomeCidade': 'ANTONINA',
  },
  {
    'id_cidade': 4101309,
    'nomeCidade': 'ANTONIO OLINTO',
  },
  {
    'id_cidade': 4101408,
    'nomeCidade': 'APUCARANA',
  },
  {
    'id_cidade': 4101507,
    'nomeCidade': 'ARAPONGAS',
  },
  {
    'id_cidade': 4101606,
    'nomeCidade': 'ARAPOTI',
  },
  {
    'id_cidade': 4101655,
    'nomeCidade': 'ARAPUA',
  },
  {
    'id_cidade': 4101705,
    'nomeCidade': 'ARARUNA',
  },
  {
    'id_cidade': 4101804,
    'nomeCidade': 'ARAUCARIA',
  },
  {
    'id_cidade': 4101853,
    'nomeCidade': 'ARIRANHA DO IVAI',
  },
  {
    'id_cidade': 4101903,
    'nomeCidade': 'ASSAI',
  },
  {
    'id_cidade': 4102000,
    'nomeCidade': 'ASSIS CHATEAUBRIAND',
  },
  {
    'id_cidade': 4102109,
    'nomeCidade': 'ASTORGA',
  },
  {
    'id_cidade': 4102208,
    'nomeCidade': 'ATALAIA',
  },
  {
    'id_cidade': 4102307,
    'nomeCidade': 'BALSA NOVA',
  },
  {
    'id_cidade': 4102406,
    'nomeCidade': 'BANDEIRANTES',
  },
  {
    'id_cidade': 4102505,
    'nomeCidade': 'BARBOSA FERRAZ',
  },
  {
    'id_cidade': 4102703,
    'nomeCidade': 'BARRA DO JACARE',
  },
  {
    'id_cidade': 4102604,
    'nomeCidade': 'BARRACAO',
  },
  {
    'id_cidade': 4102752,
    'nomeCidade': 'BELA VISTA DA CAROBA',
  },
  {
    'id_cidade': 4102802,
    'nomeCidade': 'BELA VISTA DO PARAISO',
  },
  {
    'id_cidade': 4102901,
    'nomeCidade': 'BITURUNA',
  },
  {
    'id_cidade': 4103008,
    'nomeCidade': 'BOA ESPERANCA',
  },
  {
    'id_cidade': 4103024,
    'nomeCidade': 'BOA ESPERANCA DO IGUACU',
  },
  {
    'id_cidade': 4103040,
    'nomeCidade': 'BOA VENTURA DE SAO ROQUE',
  },
  {
    'id_cidade': 4103057,
    'nomeCidade': 'BOA VISTA DA APARECIDA',
  },
  {
    'id_cidade': 4103107,
    'nomeCidade': 'BOCAIUVA DO SUL',
  },
  {
    'id_cidade': 4103156,
    'nomeCidade': 'BOM JESUS DO SUL',
  },
  {
    'id_cidade': 4103206,
    'nomeCidade': 'Bom Sucesso',
  },
  {
    'id_cidade': 4103206,
    'nomeCidade': 'BOM SUCESSO',
  },
  {
    'id_cidade': 4103222,
    'nomeCidade': 'BOM SUCESSO DO SUL',
  },
  {
    'id_cidade': 4103305,
    'nomeCidade': 'BORRAZOPOLIS',
  },
  {
    'id_cidade': 4103354,
    'nomeCidade': 'BRAGANEY',
  },
  {
    'id_cidade': 4103370,
    'nomeCidade': 'BRASILANDIA DO SUL',
  },
  {
    'id_cidade': 4103404,
    'nomeCidade': 'CAFEARA',
  },
  {
    'id_cidade': 4103453,
    'nomeCidade': 'CAFELANDIA',
  },
  {
    'id_cidade': 4103479,
    'nomeCidade': 'CAFEZAL DO SUL',
  },
  {
    'id_cidade': 4103503,
    'nomeCidade': 'CALIFORNIA',
  },
  {
    'id_cidade': 4103602,
    'nomeCidade': 'CAMBARA',
  },
  {
    'id_cidade': 4103701,
    'nomeCidade': 'CAMBE',
  },
  {
    'id_cidade': 4103800,
    'nomeCidade': 'CAMBIRA',
  },
  {
    'id_cidade': 4103909,
    'nomeCidade': 'CAMPINA DA LAGOA',
  },
  {
    'id_cidade': 4103958,
    'nomeCidade': 'CAMPINA DO SIMAO',
  },
  {
    'id_cidade': 4104006,
    'nomeCidade': 'CAMPINA GRANDE DO SUL',
  },
  {
    'id_cidade': 4104055,
    'nomeCidade': 'CAMPO BONITO',
  },
  {
    'id_cidade': 4104105,
    'nomeCidade': 'CAMPO DO TENENTE',
  },
  {
    'id_cidade': 4104204,
    'nomeCidade': 'CAMPO LARGO',
  },
  {
    'id_cidade': 4104253,
    'nomeCidade': 'CAMPO MAGRO',
  },
  {
    'id_cidade': 4104303,
    'nomeCidade': 'CAMPO MOURAO',
  },
  {
    'id_cidade': 4104402,
    'nomeCidade': 'CANDIDO DE ABREU',
  },
  {
    'id_cidade': 4104428,
    'nomeCidade': 'CANDOI',
  },
  {
    'id_cidade': 4104451,
    'nomeCidade': 'CANTAGALO',
  },
  {
    'id_cidade': 4104501,
    'nomeCidade': 'CAPANEMA',
  },
  {
    'id_cidade': 4104600,
    'nomeCidade': 'CAPITAO LEONIDAS MARQUES',
  },
  {
    'id_cidade': 4104659,
    'nomeCidade': 'CARAMBEI',
  },
  {
    'id_cidade': 4104709,
    'nomeCidade': 'CARLOPOLIS',
  },
  {
    'id_cidade': 4104808,
    'nomeCidade': 'CASCAVEL',
  },
  {
    'id_cidade': 4104907,
    'nomeCidade': 'CASTRO',
  },
  {
    'id_cidade': 4105003,
    'nomeCidade': 'CATANDUVAS',
  },
  {
    'id_cidade': 4105102,
    'nomeCidade': 'CENTENARIO DO SUL',
  },
  {
    'id_cidade': 4105201,
    'nomeCidade': 'CERRO AZUL',
  },
  {
    'id_cidade': 4105300,
    'nomeCidade': 'CEU AZUL',
  },
  {
    'id_cidade': 4105409,
    'nomeCidade': 'CHOPINZINHO',
  },
  {
    'id_cidade': 4105508,
    'nomeCidade': 'CIANORTE',
  },
  {
    'id_cidade': 4105607,
    'nomeCidade': 'CIDADE GAUCHA',
  },
  {
    'id_cidade': 4105706,
    'nomeCidade': 'CLEVELANDIA',
  },
  {
    'id_cidade': 4105805,
    'nomeCidade': 'COLOMBO',
  },
  {
    'id_cidade': 4105904,
    'nomeCidade': 'COLORADO',
  },
  {
    'id_cidade': 4106001,
    'nomeCidade': 'CONGONHINHAS',
  },
  {
    'id_cidade': 4106100,
    'nomeCidade': 'CONSELHEIRO MAIRINCK',
  },
  {
    'id_cidade': 4106209,
    'nomeCidade': 'CONTENDA',
  },
  {
    'id_cidade': 4106308,
    'nomeCidade': 'CORBELIA',
  },
  {
    'id_cidade': 4106407,
    'nomeCidade': 'CORNELIO PROCOPIO',
  },
  {
    'id_cidade': 4106456,
    'nomeCidade': 'CORONEL DOMINGOS SOARES',
  },
  {
    'id_cidade': 4106506,
    'nomeCidade': 'CORONEL VIVIDA',
  },
  {
    'id_cidade': 4106555,
    'nomeCidade': 'CORUMBATAI DO SUL',
  },
  {
    'id_cidade': 4106803,
    'nomeCidade': 'CRUZ MACHADO',
  },
  {
    'id_cidade': 4106571,
    'nomeCidade': 'CRUZEIRO DO IGUACU',
  },
  {
    'id_cidade': 4106605,
    'nomeCidade': 'CRUZEIRO DO OESTE',
  },
  {
    'id_cidade': 4106704,
    'nomeCidade': 'CRUZEIRO DO SUL',
  },
  {
    'id_cidade': 4106852,
    'nomeCidade': 'CRUZMALTINA',
  },
  {
    'id_cidade': 4106902,
    'nomeCidade': 'CURITIBA',
  },
  {
    'id_cidade': 4107009,
    'nomeCidade': 'CURIUVA',
  },
  {
    'id_cidade': 4107108,
    'nomeCidade': 'DIAMANTE DO NORTE',
  },
  {
    'id_cidade': 4107124,
    'nomeCidade': 'DIAMANTE DO SUL',
  },
  {
    'id_cidade': 4107157,
    'nomeCidade': 'DIAMANTE DOESTE',
  },
  {
    'id_cidade': 4107207,
    'nomeCidade': 'DOIS VIZINHOS',
  },
  {
    'id_cidade': 4107256,
    'nomeCidade': 'DOURADINA',
  },
  {
    'id_cidade': 4107306,
    'nomeCidade': 'DOUTOR CAMARGO',
  },
  {
    'id_cidade': 4128633,
    'nomeCidade': 'DOUTOR ULYSSES',
  },
  {
    'id_cidade': 4107405,
    'nomeCidade': 'ENEAS MARQUES',
  },
  {
    'id_cidade': 4107504,
    'nomeCidade': 'ENGENHEIRO BELTRAO',
  },
  {
    'id_cidade': 4107538,
    'nomeCidade': 'ENTRE RIOS DO OESTE',
  },
  {
    'id_cidade': 4107520,
    'nomeCidade': 'ESPERANCA NOVA',
  },
  {
    'id_cidade': 4107546,
    'nomeCidade': 'ESPIGAO ALTO DO IGUACU',
  },
  {
    'id_cidade': 4107553,
    'nomeCidade': 'FAROL',
  },
  {
    'id_cidade': 4107603,
    'nomeCidade': 'FAXINAL',
  },
  {
    'id_cidade': 4107652,
    'nomeCidade': 'FAZENDA RIO GRANDE',
  },
  {
    'id_cidade': 4107702,
    'nomeCidade': 'FENIX',
  },
  {
    'id_cidade': 4107736,
    'nomeCidade': 'FERNANDES PINHEIRO',
  },
  {
    'id_cidade': 4107751,
    'nomeCidade': 'FIGUEIRA',
  },
  {
    'id_cidade': 4107850,
    'nomeCidade': 'FLOR DA SERRA DO SUL',
  },
  {
    'id_cidade': 4107801,
    'nomeCidade': 'FLORAI',
  },
  {
    'id_cidade': 4107900,
    'nomeCidade': 'FLORESTA',
  },
  {
    'id_cidade': 4108007,
    'nomeCidade': 'FLORESTOPOLIS',
  },
  {
    'id_cidade': 4108106,
    'nomeCidade': 'FLORIDA',
  },
  {
    'id_cidade': 4108205,
    'nomeCidade': 'FORMOSA DO OESTE',
  },
  {
    'id_cidade': 4108304,
    'nomeCidade': 'FOZ DO IGUACU',
  },
  {
    'id_cidade': 4108452,
    'nomeCidade': 'FOZ DO JORDAO',
  },
  {
    'id_cidade': 4108320,
    'nomeCidade': 'FRANCISCO ALVES',
  },
  {
    'id_cidade': 4108403,
    'nomeCidade': 'FRANCISCO BELTRAO',
  },
  {
    'id_cidade': 4108502,
    'nomeCidade': 'GENERAL CARNEIRO',
  },
  {
    'id_cidade': 4108551,
    'nomeCidade': 'GODOY MOREIRA',
  },
  {
    'id_cidade': 4108601,
    'nomeCidade': 'GOIOERE',
  },
  {
    'id_cidade': 4108650,
    'nomeCidade': 'GOIOXIM',
  },
  {
    'id_cidade': 4108700,
    'nomeCidade': 'GRANDES RIOS',
  },
  {
    'id_cidade': 4108809,
    'nomeCidade': 'GUAIRA',
  },
  {
    'id_cidade': 4108908,
    'nomeCidade': 'GUAIRACA',
  },
  {
    'id_cidade': 4109005,
    'nomeCidade': 'GUAPIRAMA',
  },
  {
    'id_cidade': 4109104,
    'nomeCidade': 'GUAPOREMA',
  },
  {
    'id_cidade': 4109203,
    'nomeCidade': 'GUARACI',
  },
  {
    'id_cidade': 4109302,
    'nomeCidade': 'GUARANIACU',
  },
  {
    'id_cidade': 4109401,
    'nomeCidade': 'GUARAPUAVA',
  },
  {
    'id_cidade': 4109500,
    'nomeCidade': 'GUARAQUECABA',
  },
  {
    'id_cidade': 4109609,
    'nomeCidade': 'GUARATUBA',
  },
  {
    'id_cidade': 4109658,
    'nomeCidade': 'HONORIO SERPA',
  },
  {
    'id_cidade': 4109708,
    'nomeCidade': 'IBAITI',
  },
  {
    'id_cidade': 4109757,
    'nomeCidade': 'IBEMA',
  },
  {
    'id_cidade': 4109807,
    'nomeCidade': 'IBIPORA',
  },
  {
    'id_cidade': 4109906,
    'nomeCidade': 'ICARAIMA',
  },
  {
    'id_cidade': 4110003,
    'nomeCidade': 'IGUARACU',
  },
  {
    'id_cidade': 4110052,
    'nomeCidade': 'IGUATU',
  },
  {
    'id_cidade': 4110078,
    'nomeCidade': 'IMBAU',
  },
  {
    'id_cidade': 4110102,
    'nomeCidade': 'IMBITUVA',
  },
  {
    'id_cidade': 4110201,
    'nomeCidade': 'INACIO MARTINS',
  },
  {
    'id_cidade': 4110300,
    'nomeCidade': 'INAJA',
  },
  {
    'id_cidade': 4110409,
    'nomeCidade': 'INDIANOPOLIS',
  },
  {
    'id_cidade': 4110508,
    'nomeCidade': 'IPIRANGA',
  },
  {
    'id_cidade': 4110607,
    'nomeCidade': 'IPORA',
  },
  {
    'id_cidade': 4110656,
    'nomeCidade': 'IRACEMA DO OESTE',
  },
  {
    'id_cidade': 4110706,
    'nomeCidade': 'IRATI',
  },
  {
    'id_cidade': 4110805,
    'nomeCidade': 'IRETAMA',
  },
  {
    'id_cidade': 4110904,
    'nomeCidade': 'ITAGUAJE',
  },
  {
    'id_cidade': 4110953,
    'nomeCidade': 'ITAIPELANDIA',
  },
  {
    'id_cidade': 4111001,
    'nomeCidade': 'ITAMBARACA',
  },
  {
    'id_cidade': 4111100,
    'nomeCidade': 'ITAMBE',
  },
  {
    'id_cidade': 4111209,
    'nomeCidade': "ITAPEJARA D'OESTE",
  },
  {
    'id_cidade': 4111258,
    'nomeCidade': 'ITAPERUCU',
  },
  {
    'id_cidade': 4111308,
    'nomeCidade': 'ITAUNA DO SUL',
  },
  {
    'id_cidade': 4111407,
    'nomeCidade': 'IVAI',
  },
  {
    'id_cidade': 4111506,
    'nomeCidade': 'IVAIPORA',
  },
  {
    'id_cidade': 4111555,
    'nomeCidade': 'IVATE',
  },
  {
    'id_cidade': 4111605,
    'nomeCidade': 'IVATUBA',
  },
  {
    'id_cidade': 4111704,
    'nomeCidade': 'JABOTI',
  },
  {
    'id_cidade': 4111803,
    'nomeCidade': 'JACAREZINHO',
  },
  {
    'id_cidade': 4111902,
    'nomeCidade': 'JAGUAPITA',
  },
  {
    'id_cidade': 4112009,
    'nomeCidade': 'JAGUARIAIVA',
  },
  {
    'id_cidade': 4112108,
    'nomeCidade': 'JANDAIA DO SUL',
  },
  {
    'id_cidade': 4112207,
    'nomeCidade': 'JANIOPOLIS',
  },
  {
    'id_cidade': 4112306,
    'nomeCidade': 'JAPIRA',
  },
  {
    'id_cidade': 4112405,
    'nomeCidade': 'JAPURA',
  },
  {
    'id_cidade': 4112504,
    'nomeCidade': 'JARDIM ALEGRE',
  },
  {
    'id_cidade': 4112603,
    'nomeCidade': 'JARDIM OLINDA',
  },
  {
    'id_cidade': 4112702,
    'nomeCidade': 'JATAIZINHO',
  },
  {
    'id_cidade': 4112751,
    'nomeCidade': 'JESUITAS',
  },
  {
    'id_cidade': 4112801,
    'nomeCidade': 'JOAQUIM TAVORA',
  },
  {
    'id_cidade': 4112900,
    'nomeCidade': 'JUNDIAI DO SUL',
  },
  {
    'id_cidade': 4112959,
    'nomeCidade': 'JURANDA',
  },
  {
    'id_cidade': 4113007,
    'nomeCidade': 'JUSSARA',
  },
  {
    'id_cidade': 4113106,
    'nomeCidade': 'KALORE',
  },
  {
    'id_cidade': 4113205,
    'nomeCidade': 'LAPA',
  },
  {
    'id_cidade': 4113254,
    'nomeCidade': 'LARANJAL',
  },
  {
    'id_cidade': 4113304,
    'nomeCidade': 'LARANJEIRAS DO SUL',
  },
  {
    'id_cidade': 4113403,
    'nomeCidade': 'LEOPOLIS',
  },
  {
    'id_cidade': 4113429,
    'nomeCidade': 'LIDIANOPOLIS',
  },
  {
    'id_cidade': 4113452,
    'nomeCidade': 'LINDOESTE',
  },
  {
    'id_cidade': 4113502,
    'nomeCidade': 'LOANDA',
  },
  {
    'id_cidade': 4113601,
    'nomeCidade': 'LOBATO',
  },
  {
    'id_cidade': 4113700,
    'nomeCidade': 'LONDRINA',
  },
  {
    'id_cidade': 4113734,
    'nomeCidade': 'LUIZIANA',
  },
  {
    'id_cidade': 4113759,
    'nomeCidade': 'LUNARDELLI',
  },
  {
    'id_cidade': 4113809,
    'nomeCidade': 'LUPIONOPOLIS',
  },
  {
    'id_cidade': 4113908,
    'nomeCidade': 'MALLET',
  },
  {
    'id_cidade': 4114005,
    'nomeCidade': 'MAMBORÊ',
  },
  {
    'id_cidade': 4114104,
    'nomeCidade': 'MANDAGUAÇU',
  },
  {
    'id_cidade': 4114203,
    'nomeCidade': 'MANDAGUARI',
  },
  {
    'id_cidade': 4114302,
    'nomeCidade': 'MANDIRITUBA',
  },
  {
    'id_cidade': 4114351,
    'nomeCidade': 'MANFRINOPOLIS',
  },
  {
    'id_cidade': 4114401,
    'nomeCidade': 'MANGUEIRINHA',
  },
  {
    'id_cidade': 4114500,
    'nomeCidade': 'MANOEL RIBAS',
  },
  {
    'id_cidade': 4114609,
    'nomeCidade': 'MARECHAL CANDIDO RONDON',
  },
  {
    'id_cidade': 4114708,
    'nomeCidade': 'MARIA HELENA',
  },
  {
    'id_cidade': 4114807,
    'nomeCidade': 'MARIALVA',
  },
  {
    'id_cidade': 4114906,
    'nomeCidade': 'MARILANDIA DO SUL',
  },
  {
    'id_cidade': 4115002,
    'nomeCidade': 'MARILENA',
  },
  {
    'id_cidade': 4115101,
    'nomeCidade': 'MARILUZ',
  },
  {
    'id_cidade': 4115200,
    'nomeCidade': 'MARINGA',
  },
  {
    'id_cidade': 4115309,
    'nomeCidade': 'MARIOPOLIS',
  },
  {
    'id_cidade': 4115358,
    'nomeCidade': 'MARIPA',
  },
  {
    'id_cidade': 4115408,
    'nomeCidade': 'MARMELEIRO',
  },
  {
    'id_cidade': 4115457,
    'nomeCidade': 'MARQUINHO',
  },
  {
    'id_cidade': 4115507,
    'nomeCidade': 'MARUMBI',
  },
  {
    'id_cidade': 4115606,
    'nomeCidade': 'MATELANDIA',
  },
  {
    'id_cidade': 4115705,
    'nomeCidade': 'MATINHOS',
  },
  {
    'id_cidade': 4115739,
    'nomeCidade': 'MATO RICO',
  },
  {
    'id_cidade': 4115754,
    'nomeCidade': 'MAUA DA SERRA',
  },
  {
    'id_cidade': 4115804,
    'nomeCidade': 'MEDIANEIRA',
  },
  {
    'id_cidade': 4115853,
    'nomeCidade': 'MERCEDES',
  },
  {
    'id_cidade': 4115903,
    'nomeCidade': 'MIRADOR',
  },
  {
    'id_cidade': 4116000,
    'nomeCidade': 'MIRASELVA',
  },
  {
    'id_cidade': 4116059,
    'nomeCidade': 'MISSAL',
  },
  {
    'id_cidade': 4116109,
    'nomeCidade': 'MOREIRA SALES',
  },
  {
    'id_cidade': 4116208,
    'nomeCidade': 'MORRETES',
  },
  {
    'id_cidade': 4116307,
    'nomeCidade': 'MUNHOZ DE MELO',
  },
  {
    'id_cidade': 4116406,
    'nomeCidade': 'NOSSA SENHORA DAS GRACAS',
  },
  {
    'id_cidade': 4116505,
    'nomeCidade': 'NOVA ALIANCA DO IVAI',
  },
  {
    'id_cidade': 4116604,
    'nomeCidade': 'NOVA AMERICA DA COLINA',
  },
  {
    'id_cidade': 4116703,
    'nomeCidade': 'NOVA AURORA',
  },
  {
    'id_cidade': 4116802,
    'nomeCidade': 'NOVA CANTU',
  },
  {
    'id_cidade': 4116901,
    'nomeCidade': 'NOVA ESPERANCA',
  },
  {
    'id_cidade': 4116950,
    'nomeCidade': 'NOVA ESPERANCA DO SUDOESTE',
  },
  {
    'id_cidade': 4117008,
    'nomeCidade': 'NOVA FATIMA',
  },
  {
    'id_cidade': 4117057,
    'nomeCidade': 'NOVA LARANJEIRAS',
  },
  {
    'id_cidade': 4117107,
    'nomeCidade': 'NOVA LONDRINA',
  },
  {
    'id_cidade': 4117206,
    'nomeCidade': 'NOVA OLIMPIA',
  },
  {
    'id_cidade': 4117255,
    'nomeCidade': 'NOVA PRATA DO IGUACU',
  },
  {
    'id_cidade': 4117214,
    'nomeCidade': 'NOVA SANTA BARBARA',
  },
  {
    'id_cidade': 4117222,
    'nomeCidade': 'NOVA SANTA ROSA',
  },
  {
    'id_cidade': 4117271,
    'nomeCidade': 'NOVA TEBAS',
  },
  {
    'id_cidade': 4117297,
    'nomeCidade': 'NOVO ITACOLOMI',
  },
  {
    'id_cidade': 4117305,
    'nomeCidade': 'ORTIGUEIRA',
  },
  {
    'id_cidade': 4117404,
    'nomeCidade': 'OURIZONA',
  },
  {
    'id_cidade': 4117453,
    'nomeCidade': 'OURO PONTA DO OESTE',
  },
  {
    'id_cidade': 4117503,
    'nomeCidade': 'PAICANDU',
  },
  {
    'id_cidade': 4117602,
    'nomeCidade': 'PALMAS',
  },
  {
    'id_cidade': 4117701,
    'nomeCidade': 'PALMEIRA',
  },
  {
    'id_cidade': 4117800,
    'nomeCidade': 'PALMITAL',
  },
  {
    'id_cidade': 4117909,
    'nomeCidade': 'PALOTINA',
  },
  {
    'id_cidade': 4118006,
    'nomeCidade': 'PARAISO DO NORTE',
  },
  {
    'id_cidade': 4118105,
    'nomeCidade': 'PARANACITY',
  },
  {
    'id_cidade': 4118204,
    'nomeCidade': 'PARANAGUA',
  },
  {
    'id_cidade': 4118303,
    'nomeCidade': 'PARANAPOEMA',
  },
  {
    'id_cidade': 4118402,
    'nomeCidade': 'PARANAVAI',
  },
  {
    'id_cidade': 4118451,
    'nomeCidade': 'PATO BRAGADO',
  },
  {
    'id_cidade': 4118501,
    'nomeCidade': 'PATO BRANCO',
  },
  {
    'id_cidade': 4118600,
    'nomeCidade': 'PAULA FREITAS',
  },
  {
    'id_cidade': 4118709,
    'nomeCidade': 'PAULO FRONTIN',
  },
  {
    'id_cidade': 4118808,
    'nomeCidade': 'PEABIRU',
  },
  {
    'id_cidade': 4118857,
    'nomeCidade': 'PEROBAL',
  },
  {
    'id_cidade': 4118907,
    'nomeCidade': 'PEROLA',
  },
  {
    'id_cidade': 4119004,
    'nomeCidade': 'PEROLA DO OESTE',
  },
  {
    'id_cidade': 4119103,
    'nomeCidade': 'PIEN',
  },
  {
    'id_cidade': 4119152,
    'nomeCidade': 'PINHAIS',
  },
  {
    'id_cidade': 4119251,
    'nomeCidade': 'PINHAL DE SAO BENTO',
  },
  {
    'id_cidade': 4119202,
    'nomeCidade': 'PINHALAO',
  },
  {
    'id_cidade': 4119301,
    'nomeCidade': 'PINHAO',
  },
  {
    'id_cidade': 4119400,
    'nomeCidade': 'PIRAI DO SUL',
  },
  {
    'id_cidade': 4119509,
    'nomeCidade': 'PIRAQUARA',
  },
  {
    'id_cidade': 4119608,
    'nomeCidade': 'PITANGA',
  },
  {
    'id_cidade': 4119657,
    'nomeCidade': 'PITANGUEIRAS',
  },
  {
    'id_cidade': 4119707,
    'nomeCidade': 'PLANALTINA DO PARANA',
  },
  {
    'id_cidade': 4119806,
    'nomeCidade': 'PLANALTO',
  },
  {
    'id_cidade': 4119905,
    'nomeCidade': 'PONTA GROSSA',
  },
  {
    'id_cidade': 4119954,
    'nomeCidade': 'PONTAL DO PARANA',
  },
  {
    'id_cidade': 4120002,
    'nomeCidade': 'PORECATU',
  },
  {
    'id_cidade': 4120101,
    'nomeCidade': 'PORTO AMAZONAS',
  },
  {
    'id_cidade': 4120150,
    'nomeCidade': 'PORTO BARREIRO',
  },
  {
    'id_cidade': 4120200,
    'nomeCidade': 'PORTO RICO',
  },
  {
    'id_cidade': 4120309,
    'nomeCidade': 'PORTO VITORIA',
  },
  {
    'id_cidade': 4120333,
    'nomeCidade': 'PRADO FERREIRA',
  },
  {
    'id_cidade': 4120358,
    'nomeCidade': 'PRANCHITA',
  },
  {
    'id_cidade': 4120408,
    'nomeCidade': 'PRESIDENTE CASTELO BRANCO',
  },
  {
    'id_cidade': 4120507,
    'nomeCidade': 'PRIMEIRO DE MAIO',
  },
  {
    'id_cidade': 4120606,
    'nomeCidade': 'PRUDENTOPOLIS',
  },
  {
    'id_cidade': 4120655,
    'nomeCidade': 'QUARTO CENTENARIO',
  },
  {
    'id_cidade': 4120705,
    'nomeCidade': 'QUATIGUA',
  },
  {
    'id_cidade': 4120804,
    'nomeCidade': 'QUATRO BARRAS',
  },
  {
    'id_cidade': 4120853,
    'nomeCidade': 'QUATRO PONTES',
  },
  {
    'id_cidade': 4120903,
    'nomeCidade': 'QUEDAS DO IGUACU',
  },
  {
    'id_cidade': 4121000,
    'nomeCidade': 'QUERENCIA DO NORTE',
  },
  {
    'id_cidade': 4121109,
    'nomeCidade': 'QUINTA DO SOL',
  },
  {
    'id_cidade': 4121208,
    'nomeCidade': 'QUITANDINHA',
  },
  {
    'id_cidade': 4121257,
    'nomeCidade': 'RAMILANDIA',
  },
  {
    'id_cidade': 4121307,
    'nomeCidade': 'RANCHO ALEGRE',
  },
  {
    'id_cidade': 4121356,
    'nomeCidade': 'RANCHO ALEGRE DO OESTE',
  },
  {
    'id_cidade': 4121406,
    'nomeCidade': 'REALEZA',
  },
  {
    'id_cidade': 4121505,
    'nomeCidade': 'REBOUCAS',
  },
  {
    'id_cidade': 4121604,
    'nomeCidade': 'RENASCENCA',
  },
  {
    'id_cidade': 4121703,
    'nomeCidade': 'RESERVA',
  },
  {
    'id_cidade': 4121752,
    'nomeCidade': 'RESERVA DO IGUACU',
  },
  {
    'id_cidade': 4121802,
    'nomeCidade': 'RIBEIRAO CLARO',
  },
  {
    'id_cidade': 4121901,
    'nomeCidade': 'RIBEIRAO DO PINHAL',
  },
  {
    'id_cidade': 4122008,
    'nomeCidade': 'RIO AZUL',
  },
  {
    'id_cidade': 4122107,
    'nomeCidade': 'RIO BOM',
  },
  {
    'id_cidade': 4122156,
    'nomeCidade': 'RIO BONITO DO IGUACU',
  },
  {
    'id_cidade': 4122172,
    'nomeCidade': 'RIO BRANCO DO IVAI',
  },
  {
    'id_cidade': 4122206,
    'nomeCidade': 'RIO BRANCO DO SUL',
  },
  {
    'id_cidade': 4122305,
    'nomeCidade': 'RIO NEGRO',
  },
  {
    'id_cidade': 4122404,
    'nomeCidade': 'ROLANDIA',
  },
  {
    'id_cidade': 4122503,
    'nomeCidade': 'RONCADOR',
  },
  {
    'id_cidade': 4122602,
    'nomeCidade': 'RONDON',
  },
  {
    'id_cidade': 4122651,
    'nomeCidade': 'ROSARIO DO IVAI',
  },
  {
    'id_cidade': 4122701,
    'nomeCidade': 'SABAUDIA',
  },
  {
    'id_cidade': 4122800,
    'nomeCidade': 'SALGADO FILHO',
  },
  {
    'id_cidade': 4122909,
    'nomeCidade': 'SALTO DO ITARARE',
  },
  {
    'id_cidade': 4123006,
    'nomeCidade': 'SALTO DO LONTRA',
  },
  {
    'id_cidade': 4123105,
    'nomeCidade': 'SANTA AMELIA',
  },
  {
    'id_cidade': 4123204,
    'nomeCidade': 'SANTA CECILIA DO PAVAO',
  },
  {
    'id_cidade': 4123303,
    'nomeCidade': 'SANTA CRUZ DE MONTE CASTELO',
  },
  {
    'id_cidade': 4123402,
    'nomeCidade': 'SANTA FE',
  },
  {
    'id_cidade': 4123501,
    'nomeCidade': 'SANTA HELENA',
  },
  {
    'id_cidade': 4123600,
    'nomeCidade': 'SANTA INES',
  },
  {
    'id_cidade': 4123709,
    'nomeCidade': 'SANTA ISABEL DO IVAI',
  },
  {
    'id_cidade': 4123808,
    'nomeCidade': 'SANTA IZABEL DO OESTE',
  },
  {
    'id_cidade': 4123824,
    'nomeCidade': 'SANTA LUCIA',
  },
  {
    'id_cidade': 4123857,
    'nomeCidade': 'SANTA MARIA DO OESTE',
  },
  {
    'id_cidade': 4123907,
    'nomeCidade': 'SANTA MARIANA',
  },
  {
    'id_cidade': 4123956,
    'nomeCidade': 'SANTA MONICA',
  },
  {
    'id_cidade': 4124020,
    'nomeCidade': 'SANTA TEREZA DO OESTE',
  },
  {
    'id_cidade': 4124053,
    'nomeCidade': 'SANTA TEREZINHA DE ITAIPU',
  },
  {
    'id_cidade': 4124004,
    'nomeCidade': 'SANTANA DO ITARARE',
  },
  {
    'id_cidade': 4124103,
    'nomeCidade': 'SANTO ANTONIO DA PLATINA',
  },
  {
    'id_cidade': 4124202,
    'nomeCidade': 'SANTO ANTONIO DO CAIUA',
  },
  {
    'id_cidade': 4124301,
    'nomeCidade': 'SANTO ANTONIO DO PARAISO',
  },
  {
    'id_cidade': 4124400,
    'nomeCidade': 'SANTO ANTONIO DO SUDOESTE',
  },
  {
    'id_cidade': 4124509,
    'nomeCidade': 'SANTO INACIO',
  },
  {
    'id_cidade': 4124608,
    'nomeCidade': 'SAO CARLOS DO IVAI',
  },
  {
    'id_cidade': 4124707,
    'nomeCidade': 'SAO JERONIMO DA SERRA',
  },
  {
    'id_cidade': 4124806,
    'nomeCidade': 'SAO JOAO',
  },
  {
    'id_cidade': 4124905,
    'nomeCidade': 'SAO JOAO DO CAIUA',
  },
  {
    'id_cidade': 4125001,
    'nomeCidade': 'SAO JOAO DO IVAI',
  },
  {
    'id_cidade': 4125100,
    'nomeCidade': 'SAO JOAO DO TRIUNFO',
  },
  {
    'id_cidade': 4125308,
    'nomeCidade': 'SAO JORGE DO IVAI',
  },
  {
    'id_cidade': 4125357,
    'nomeCidade': 'SAO JORGE DO PATROCINIO',
  },
  {
    'id_cidade': 4125209,
    'nomeCidade': 'SAO JORGE DO OESTE',
  },
  {
    'id_cidade': 4125407,
    'nomeCidade': 'SAO JOSE DA BOA VISTA',
  },
  {
    'id_cidade': 4125456,
    'nomeCidade': 'SAO JOSE DAS PALMEIRAS',
  },
  {
    'id_cidade': 4125506,
    'nomeCidade': 'SAO JOSE DOS PINHAIS',
  },
  {
    'id_cidade': 4125555,
    'nomeCidade': 'SAO MANOEL DO PARANA',
  },
  {
    'id_cidade': 4125605,
    'nomeCidade': 'SAO MATEUS DO SUL',
  },
  {
    'id_cidade': 4125704,
    'nomeCidade': 'SAO MIGUEL DO IGUACU',
  },
  {
    'id_cidade': 4125753,
    'nomeCidade': 'SAO PEDRO DO IGUACU',
  },
  {
    'id_cidade': 4125803,
    'nomeCidade': 'SAO PEDRO DO IVAI',
  },
  {
    'id_cidade': 4125902,
    'nomeCidade': 'SAO PEDRO DO PARANA',
  },
  {
    'id_cidade': 4126009,
    'nomeCidade': 'SAO SEBASTIAO DA AMOREIRA',
  },
  {
    'id_cidade': 4126108,
    'nomeCidade': 'SAO TOME',
  },
  {
    'id_cidade': 4126207,
    'nomeCidade': 'SAPOPEMA',
  },
  {
    'id_cidade': 4126256,
    'nomeCidade': 'SARANDI',
  },
  {
    'id_cidade': 4126272,
    'nomeCidade': 'SAUDADE DO IGUACU',
  },
  {
    'id_cidade': 4126306,
    'nomeCidade': 'SENGES',
  },
  {
    'id_cidade': 4126355,
    'nomeCidade': 'SERRANOPOIS DO IGUACU',
  },
  {
    'id_cidade': 4126405,
    'nomeCidade': 'SERTANEJA',
  },
  {
    'id_cidade': 4126504,
    'nomeCidade': 'SERTANEOPOLIS',
  },
  {
    'id_cidade': 4126603,
    'nomeCidade': 'SIQUEIRA CAMPOS',
  },
  {
    'id_cidade': 4126652,
    'nomeCidade': 'SULINA',
  },
  {
    'id_cidade': 4126678,
    'nomeCidade': 'TAMARANA',
  },
  {
    'id_cidade': 4126702,
    'nomeCidade': 'TAMBOARA',
  },
  {
    'id_cidade': 4126801,
    'nomeCidade': 'TAPEJARA',
  },
  {
    'id_cidade': 4126900,
    'nomeCidade': 'TAPIRA',
  },
  {
    'id_cidade': 4127007,
    'nomeCidade': 'TEIXEIRA SOARES',
  },
  {
    'id_cidade': 4127106,
    'nomeCidade': 'TELEMACO BORBA',
  },
  {
    'id_cidade': 4127205,
    'nomeCidade': 'TERRA BOA',
  },
  {
    'id_cidade': 4127304,
    'nomeCidade': 'TERRA RICA',
  },
  {
    'id_cidade': 4127403,
    'nomeCidade': 'TERRA ROXA',
  },
  {
    'id_cidade': 4127502,
    'nomeCidade': 'TIBAGI',
  },
  {
    'id_cidade': 4127601,
    'nomeCidade': 'TIJUCAS DO SUL',
  },
  {
    'id_cidade': 4127700,
    'nomeCidade': 'TOLEDO',
  },
  {
    'id_cidade': 4127809,
    'nomeCidade': 'TOMAZINA',
  },
  {
    'id_cidade': 4127858,
    'nomeCidade': 'TRES BARRAS DO PARANA',
  },
  {
    'id_cidade': 4127882,
    'nomeCidade': 'TUNAS DO PARANA',
  },
  {
    'id_cidade': 4127908,
    'nomeCidade': 'TUNEIRAS DO OESTE',
  },
  {
    'id_cidade': 4127957,
    'nomeCidade': 'TUPASSI',
  },
  {
    'id_cidade': 4127965,
    'nomeCidade': 'TURVO',
  },
  {
    'id_cidade': 4128005,
    'nomeCidade': 'UBIRATA',
  },
  {
    'id_cidade': 4128104,
    'nomeCidade': 'UMUARAMA',
  },
  {
    'id_cidade': 4128203,
    'nomeCidade': 'UNIAO DA VITORIA',
  },
  {
    'id_cidade': 4128302,
    'nomeCidade': 'UNIFLOR',
  },
  {
    'id_cidade': 4128401,
    'nomeCidade': 'URAI',
  },
  {
    'id_cidade': 4128534,
    'nomeCidade': 'VENTANIA',
  },
  {
    'id_cidade': 4128559,
    'nomeCidade': 'VERA CRUZ DO OESTE',
  },
  {
    'id_cidade': 4128609,
    'nomeCidade': 'VERE',
  },
  {
    'id_cidade': 4128658,
    'nomeCidade': 'VIRMOND',
  },
  {
    'id_cidade': 4128708,
    'nomeCidade': 'VITORINO',
  },
  {
    'id_cidade': 4128500,
    'nomeCidade': 'WENCESLAU BRAZ',
  },
  {
    'id_cidade': 4128807,
    'nomeCidade': 'XAMBRE',
  },
];

export const StatesAndCities = [
  {
    'estados': [
      {
        'id': 12,
        'nomeEstado': 'Acre',
        'sigla': 'AC',
        'cidades': [
          {
            'id_cidade': 1200013,
            'nomeCidade': 'Acrelândia',
          },
          {
            'id_cidade': 1200054,
            'nomeCidade': 'Assis Brasil',
          },
          {
            'id_cidade': 1200104,
            'nomeCidade': 'Brasiléia',
          },
          {
            'id_cidade': 1200138,
            'nomeCidade': 'Bujari',
          },
          {
            'id_cidade': 1200179,
            'nomeCidade': 'Capixaba',
          },
          {
            'id_cidade': 1200203,
            'nomeCidade': 'Cruzeiro do Sul',
          },
          {
            'id_cidade': 1200252,
            'nomeCidade': 'Epitaciolândia',
          },
          {
            'id_cidade': 1200302,
            'nomeCidade': 'Feijó',
          },
          {
            'id_cidade': 1200328,
            'nomeCidade': 'Jordão',
          },
          {
            'id_cidade': 1200336,
            'nomeCidade': 'Mâncio Lima',
          },
          {
            'id_cidade': 1200344,
            'nomeCidade': 'Manoel Urbano',
          },
          {
            'id_cidade': 1200351,
            'nomeCidade': 'Marechal Thaumaturgo',
          },
          {
            'id_cidade': 1200385,
            'nomeCidade': 'Plácido de Castro',
          },
          {
            'id_cidade': 1200807,
            'nomeCidade': 'Porto Acre',
          },
          {
            'id_cidade': 1200393,
            'nomeCidade': 'Porto Walter',
          },
          {
            'id_cidade': 1200401,
            'nomeCidade': 'Rio Branco',
          },
          {
            'id_cidade': 1200427,
            'nomeCidade': 'Rodrigues Alves',
          },
          {
            'id_cidade': 1200435,
            'nomeCidade': 'Santa Rosa do Purus',
          },
          {
            'id_cidade': 1200500,
            'nomeCidade': 'Sena Madureira',
          },
          {
            'id_cidade': 1200450,
            'nomeCidade': 'Senador Guiomard',
          },
          {
            'id_cidade': 1200609,
            'nomeCidade': 'Tarauacá',
          },
          {
            'id_cidade': 1200708,
            'nomeCidade': 'Xapuri',
          },
        ],
      },
      {
        'id': 27,
        'nomeEstado': 'Alagoas',
        'sigla': 'AL',
        'cidades': [
          {
            'id_cidade': 2700102,
            'nomeCidade': 'Água Branca',
          },
          {
            'id_cidade': 2700201,
            'nomeCidade': 'Anadia',
          },
          {
            'id_cidade': 2700300,
            'nomeCidade': 'Arapiraca',
          },
          {
            'id_cidade': 2700409,
            'nomeCidade': 'Atalaia',
          },
          {
            'id_cidade': 2700508,
            'nomeCidade': 'Barra de Santo Antônio',
          },
          {
            'id_cidade': 2700607,
            'nomeCidade': 'Barra de São Miguel',
          },
          {
            'id_cidade': 2700706,
            'nomeCidade': 'Batalha',
          },
          {
            'id_cidade': 2700805,
            'nomeCidade': 'Belém',
          },
          {
            'id_cidade': 2700904,
            'nomeCidade': 'Belo Monte',
          },
          {
            'id_cidade': 2701001,
            'nomeCidade': 'Boca da Mata',
          },
          {
            'id_cidade': 2701100,
            'nomeCidade': 'Branquinha',
          },
          {
            'id_cidade': 2701209,
            'nomeCidade': 'Cacimbinhas',
          },
          {
            'id_cidade': 2701308,
            'nomeCidade': 'Cajueiro',
          },
          {
            'id_cidade': 2701357,
            'nomeCidade': 'Campestre',
          },
          {
            'id_cidade': 2701407,
            'nomeCidade': 'Campo Alegre',
          },
          {
            'id_cidade': 2701506,
            'nomeCidade': 'Campo Grande',
          },
          {
            'id_cidade': 2701605,
            'nomeCidade': 'Canapi',
          },
          {
            'id_cidade': 2701704,
            'nomeCidade': 'Capela',
          },
          {
            'id_cidade': 2701803,
            'nomeCidade': 'Carneiros',
          },
          {
            'id_cidade': 2701902,
            'nomeCidade': 'Chã Preta',
          },
          {
            'id_cidade': 2702009,
            'nomeCidade': 'Coité do Nóia',
          },
          {
            'id_cidade': 2702108,
            'nomeCidade': 'Colônia Leopoldina',
          },
          {
            'id_cidade': 2702207,
            'nomeCidade': 'Coqueiro Seco',
          },
          {
            'id_cidade': 2702306,
            'nomeCidade': 'Coruripe',
          },
          {
            'id_cidade': 2702355,
            'nomeCidade': 'Craíbas',
          },
          {
            'id_cidade': 2702405,
            'nomeCidade': 'Delmiro Gouveia',
          },
          {
            'id_cidade': 2702504,
            'nomeCidade': 'Dois Riachos',
          },
          {
            'id_cidade': 2702553,
            'nomeCidade': 'Estrela de Alagoas',
          },
          {
            'id_cidade': 2702603,
            'nomeCidade': 'Feira Grande',
          },
          {
            'id_cidade': 2702702,
            'nomeCidade': 'Feliz Deserto',
          },
          {
            'id_cidade': 2702801,
            'nomeCidade': 'Flexeiras',
          },
          {
            'id_cidade': 2702900,
            'nomeCidade': 'Girau do Ponciano',
          },
          {
            'id_cidade': 2703007,
            'nomeCidade': 'Ibateguara',
          },
          {
            'id_cidade': 2703106,
            'nomeCidade': 'Igaci',
          },
          {
            'id_cidade': 2703205,
            'nomeCidade': 'Igreja Nova',
          },
          {
            'id_cidade': 2703304,
            'nomeCidade': 'Inhapi',
          },
          {
            'id_cidade': 2703403,
            'nomeCidade': 'Jacaré dos Homens',
          },
          {
            'id_cidade': 2703502,
            'nomeCidade': 'Jacuípe',
          },
          {
            'id_cidade': 2703601,
            'nomeCidade': 'Japaratinga',
          },
          {
            'id_cidade': 2703700,
            'nomeCidade': 'Jaramataia',
          },
          {
            'id_cidade': 2703759,
            'nomeCidade': 'Jequiá da Praia',
          },
          {
            'id_cidade': 2703809,
            'nomeCidade': 'Joaquim Gomes',
          },
          {
            'id_cidade': 2703908,
            'nomeCidade': 'Jundiá',
          },
          {
            'id_cidade': 2704005,
            'nomeCidade': 'Junqueiro',
          },
          {
            'id_cidade': 2704104,
            'nomeCidade': 'Lagoa da Canoa',
          },
          {
            'id_cidade': 2704203,
            'nomeCidade': 'Limoeiro de Anadia',
          },
          {
            'id_cidade': 2704302,
            'nomeCidade': 'Maceió',
          },
          {
            'id_cidade': 2704401,
            'nomeCidade': 'Major Isidoro',
          },
          {
            'id_cidade': 2704906,
            'nomeCidade': 'Mar Vermelho',
          },
          {
            'id_cidade': 2704500,
            'nomeCidade': 'Maragogi',
          },
          {
            'id_cidade': 2704609,
            'nomeCidade': 'Maravilha',
          },
          {
            'id_cidade': 2704708,
            'nomeCidade': 'Marechal Deodoro',
          },
          {
            'id_cidade': 2704807,
            'nomeCidade': 'Maribondo',
          },
          {
            'id_cidade': 2705002,
            'nomeCidade': 'Mata Grande',
          },
          {
            'id_cidade': 2705101,
            'nomeCidade': 'Matriz de Camaragibe',
          },
          {
            'id_cidade': 2705200,
            'nomeCidade': 'Messias',
          },
          {
            'id_cidade': 2705309,
            'nomeCidade': 'Minador do Negrão',
          },
          {
            'id_cidade': 2705408,
            'nomeCidade': 'Monteirópolis',
          },
          {
            'id_cidade': 2705507,
            'nomeCidade': 'Murici',
          },
          {
            'id_cidade': 2705606,
            'nomeCidade': 'Novo Lino',
          },
          {
            'id_cidade': 2705705,
            'nomeCidade': "Olho d'Água das Flores",
          },
          {
            'id_cidade': 2705804,
            'nomeCidade': "Olho d'Água do Casado",
          },
          {
            'id_cidade': 2705903,
            'nomeCidade': "Olho d'Água Grande",
          },
          {
            'id_cidade': 2706000,
            'nomeCidade': 'Olivença',
          },
          {
            'id_cidade': 2706109,
            'nomeCidade': 'Ouro Branco',
          },
          {
            'id_cidade': 2706208,
            'nomeCidade': 'Palestina',
          },
          {
            'id_cidade': 2706307,
            'nomeCidade': 'Palmeira dos Índios',
          },
          {
            'id_cidade': 2706406,
            'nomeCidade': 'Pão de Açúcar',
          },
          {
            'id_cidade': 2706422,
            'nomeCidade': 'Pariconha',
          },
          {
            'id_cidade': 2706448,
            'nomeCidade': 'Paripueira',
          },
          {
            'id_cidade': 2706505,
            'nomeCidade': 'Passo de Camaragibe',
          },
          {
            'id_cidade': 2706604,
            'nomeCidade': 'Paulo Jacinto',
          },
          {
            'id_cidade': 2706703,
            'nomeCidade': 'Penedo',
          },
          {
            'id_cidade': 2706802,
            'nomeCidade': 'Piaçabuçu',
          },
          {
            'id_cidade': 2706901,
            'nomeCidade': 'Pilar',
          },
          {
            'id_cidade': 2707008,
            'nomeCidade': 'Pindoba',
          },
          {
            'id_cidade': 2707107,
            'nomeCidade': 'Piranhas',
          },
          {
            'id_cidade': 2707206,
            'nomeCidade': 'Poço das Trincheiras',
          },
          {
            'id_cidade': 2707305,
            'nomeCidade': 'Porto Calvo',
          },
          {
            'id_cidade': 2707404,
            'nomeCidade': 'Porto de Pedras',
          },
          {
            'id_cidade': 2707503,
            'nomeCidade': 'Porto Real do Colégio',
          },
          {
            'id_cidade': 2707602,
            'nomeCidade': 'Quebrangulo',
          },
          {
            'id_cidade': 2707701,
            'nomeCidade': 'Rio Largo',
          },
          {
            'id_cidade': 2707800,
            'nomeCidade': 'Roteiro',
          },
          {
            'id_cidade': 2707909,
            'nomeCidade': 'Santa Luzia do Norte',
          },
          {
            'id_cidade': 2708006,
            'nomeCidade': 'Santana do Ipanema',
          },
          {
            'id_cidade': 2708105,
            'nomeCidade': 'Santana do Mundaú',
          },
          {
            'id_cidade': 2708204,
            'nomeCidade': 'São Brás',
          },
          {
            'id_cidade': 2708303,
            'nomeCidade': 'São José da Laje',
          },
          {
            'id_cidade': 2708402,
            'nomeCidade': 'São José da Tapera',
          },
          {
            'id_cidade': 2708501,
            'nomeCidade': 'São Luís do Quitunde',
          },
          {
            'id_cidade': 2708600,
            'nomeCidade': 'São Miguel dos Campos',
          },
          {
            'id_cidade': 2708709,
            'nomeCidade': 'São Miguel dos Milagres',
          },
          {
            'id_cidade': 2708808,
            'nomeCidade': 'São Sebastião',
          },
          {
            'id_cidade': 2708907,
            'nomeCidade': 'Satuba',
          },
          {
            'id_cidade': 2708956,
            'nomeCidade': 'Senador Rui Palmeira',
          },
          {
            'id_cidade': 2709004,
            'nomeCidade': "Tanque d'Arca",
          },
          {
            'id_cidade': 2709103,
            'nomeCidade': 'Taquarana',
          },
          {
            'id_cidade': 2709152,
            'nomeCidade': 'Teotônio Vilela',
          },
          {
            'id_cidade': 2709202,
            'nomeCidade': 'Traipu',
          },
          {
            'id_cidade': 2709301,
            'nomeCidade': 'União dos Palmares',
          },
          {
            'id_cidade': 2709400,
            'nomeCidade': 'Viçosa',
          },
        ],
      },
      {
        'id': 16,
        'nomeEstado': 'Amapá',
        'sigla': 'AP',
        'cidades': [
          {
            'id_cidade': 1600105,
            'nomeCidade': 'Amapá',
          },
          {
            'id_cidade': 1600204,
            'nomeCidade': 'Calçoene',
          },
          {
            'id_cidade': 1600212,
            'nomeCidade': 'Cutias',
          },
          {
            'id_cidade': 1600238,
            'nomeCidade': 'Ferreira Gomes',
          },
          {
            'id_cidade': 1600253,
            'nomeCidade': 'Itaubal',
          },
          {
            'id_cidade': 1600279,
            'nomeCidade': 'Laranjal do Jari',
          },
          {
            'id_cidade': 1600303,
            'nomeCidade': 'Macapá',
          },
          {
            'id_cidade': 1600402,
            'nomeCidade': 'Mazagão',
          },
          {
            'id_cidade': 1600501,
            'nomeCidade': 'Oiapoque',
          },
          {
            'id_cidade': 1600154,
            'nomeCidade': 'Pedra Branca do Amapari',
          },
          {
            'id_cidade': 1600535,
            'nomeCidade': 'Porto Grande',
          },
          {
            'id_cidade': 1600550,
            'nomeCidade': 'Pracuúba',
          },
          {
            'id_cidade': 1600600,
            'nomeCidade': 'Santana',
          },
          {
            'id_cidade': 1600055,
            'nomeCidade': 'Serra do Navio',
          },
          {
            'id_cidade': 1600709,
            'nomeCidade': 'Tartarugalzinho',
          },
          {
            'id_cidade': 1600808,
            'nomeCidade': 'Vitória do Jari',
          },
        ],
      },
      {
        'id': 13,
        'nomeEstado': 'Amazonas',
        'sigla': 'AM',
        'cidades': [
          {
            'id_cidade': 1300029,
            'nomeCidade': 'Alvarães',
          },
          {
            'id_cidade': 1300060,
            'nomeCidade': 'Amaturá',
          },
          {
            'id_cidade': 1300086,
            'nomeCidade': 'Anamã',
          },
          {
            'id_cidade': 1300102,
            'nomeCidade': 'Anori',
          },
          {
            'id_cidade': 1300144,
            'nomeCidade': 'Apuí',
          },
          {
            'id_cidade': 1300201,
            'nomeCidade': 'Atalaia do Norte',
          },
          {
            'id_cidade': 1300300,
            'nomeCidade': 'Autazes',
          },
          {
            'id_cidade': 1300409,
            'nomeCidade': 'Barcelos',
          },
          {
            'id_cidade': 1300508,
            'nomeCidade': 'Barreirinha',
          },
          {
            'id_cidade': 1300607,
            'nomeCidade': 'Benjamin Constant',
          },
          {
            'id_cidade': 1300631,
            'nomeCidade': 'Beruri',
          },
          {
            'id_cidade': 1300680,
            'nomeCidade': 'Boa Vista do Ramos',
          },
          {
            'id_cidade': 1300706,
            'nomeCidade': 'Boca do Acre',
          },
          {
            'id_cidade': 1300805,
            'nomeCidade': 'Borba',
          },
          {
            'id_cidade': 1300839,
            'nomeCidade': 'Caapiranga',
          },
          {
            'id_cidade': 1300904,
            'nomeCidade': 'Canutama',
          },
          {
            'id_cidade': 1301001,
            'nomeCidade': 'Carauari',
          },
          {
            'id_cidade': 1301100,
            'nomeCidade': 'Careiro',
          },
          {
            'id_cidade': 1301159,
            'nomeCidade': 'Careiro da Várzea',
          },
          {
            'id_cidade': 1301209,
            'nomeCidade': 'Coari',
          },
          {
            'id_cidade': 1301308,
            'nomeCidade': 'Codajás',
          },
          {
            'id_cidade': 1301407,
            'nomeCidade': 'Eirunepé',
          },
          {
            'id_cidade': 1301506,
            'nomeCidade': 'Envira',
          },
          {
            'id_cidade': 1301605,
            'nomeCidade': 'Fonte Boa',
          },
          {
            'id_cidade': 1301654,
            'nomeCidade': 'Guajará',
          },
          {
            'id_cidade': 1301704,
            'nomeCidade': 'Humaitá',
          },
          {
            'id_cidade': 1301803,
            'nomeCidade': 'Ipixuna',
          },
          {
            'id_cidade': 1301852,
            'nomeCidade': 'Iranduba',
          },
          {
            'id_cidade': 1301902,
            'nomeCidade': 'Itacoatiara',
          },
          {
            'id_cidade': 1301951,
            'nomeCidade': 'Itamarati',
          },
          {
            'id_cidade': 1302009,
            'nomeCidade': 'Itapiranga',
          },
          {
            'id_cidade': 1302108,
            'nomeCidade': 'Japurá',
          },
          {
            'id_cidade': 1302207,
            'nomeCidade': 'Juruá',
          },
          {
            'id_cidade': 1302306,
            'nomeCidade': 'Jutaí',
          },
          {
            'id_cidade': 1302405,
            'nomeCidade': 'Lábrea',
          },
          {
            'id_cidade': 1302504,
            'nomeCidade': 'Manacapuru',
          },
          {
            'id_cidade': 1302553,
            'nomeCidade': 'Manaquiri',
          },
          {
            'id_cidade': 1302603,
            'nomeCidade': 'Manaus',
          },
          {
            'id_cidade': 1302702,
            'nomeCidade': 'Manicoré',
          },
          {
            'id_cidade': 1302801,
            'nomeCidade': 'Maraã',
          },
          {
            'id_cidade': 1302900,
            'nomeCidade': 'Maués',
          },
          {
            'id_cidade': 1303007,
            'nomeCidade': 'Nhamundá',
          },
          {
            'id_cidade': 1303106,
            'nomeCidade': 'Nova Olinda do Norte',
          },
          {
            'id_cidade': 1303205,
            'nomeCidade': 'Novo Airão',
          },
          {
            'id_cidade': 1303304,
            'nomeCidade': 'Novo Aripuanã',
          },
          {
            'id_cidade': 1303403,
            'nomeCidade': 'Parintins',
          },
          {
            'id_cidade': 1303502,
            'nomeCidade': 'Pauini',
          },
          {
            'id_cidade': 1303536,
            'nomeCidade': 'Presidente Figueiredo',
          },
          {
            'id_cidade': 1303569,
            'nomeCidade': 'Rio Preto da Eva',
          },
          {
            'id_cidade': 1303601,
            'nomeCidade': 'Santa Isabel do Rio Negro',
          },
          {
            'id_cidade': 1303700,
            'nomeCidade': 'Santo Antônio do Içá',
          },
          {
            'id_cidade': 1303809,
            'nomeCidade': 'São Gabriel da Cachoeira',
          },
          {
            'id_cidade': 1303908,
            'nomeCidade': 'São Paulo de Olivença',
          },
          {
            'id_cidade': 1303957,
            'nomeCidade': 'São Sebastião do Uatumã',
          },
          {
            'id_cidade': 1304005,
            'nomeCidade': 'Silves',
          },
          {
            'id_cidade': 1304062,
            'nomeCidade': 'Tabatinga',
          },
          {
            'id_cidade': 1304104,
            'nomeCidade': 'Tapauá',
          },
          {
            'id_cidade': 1304203,
            'nomeCidade': 'Tefé',
          },
          {
            'id_cidade': 1304237,
            'nomeCidade': 'Tonantins',
          },
          {
            'id_cidade': 1304260,
            'nomeCidade': 'Uarini',
          },
          {
            'id_cidade': 1304302,
            'nomeCidade': 'Urucará',
          },
          {
            'id_cidade': 1304401,
            'nomeCidade': 'Urucurituba',
          },
        ],
      },
      {
        'id': 29,
        'nomeEstado': 'Bahia',
        'sigla': 'BA',
        'cidades': [
          {
            'id_cidade': 2900108,
            'nomeCidade': 'Abaíra',
          },
          {
            'id_cidade': 2900207,
            'nomeCidade': 'Abaré',
          },
          {
            'id_cidade': 2900306,
            'nomeCidade': 'Acajutiba',
          },
          {
            'id_cidade': 2900355,
            'nomeCidade': 'Adustina',
          },
          {
            'id_cidade': 2900405,
            'nomeCidade': 'Água Fria',
          },
          {
            'id_cidade': 2900603,
            'nomeCidade': 'Aiquara',
          },
          {
            'id_cidade': 2900702,
            'nomeCidade': 'Alagoinhas',
          },
          {
            'id_cidade': 2900801,
            'nomeCidade': 'Alcobaça',
          },
          {
            'id_cidade': 2900900,
            'nomeCidade': 'Almadina',
          },
          {
            'id_cidade': 2901007,
            'nomeCidade': 'Amargosa',
          },
          {
            'id_cidade': 2901106,
            'nomeCidade': 'Amélia Rodrigues',
          },
          {
            'id_cidade': 2901155,
            'nomeCidade': 'América Dourada',
          },
          {
            'id_cidade': 2901205,
            'nomeCidade': 'Anagé',
          },
          {
            'id_cidade': 2901304,
            'nomeCidade': 'Andaraí',
          },
          {
            'id_cidade': 2901353,
            'nomeCidade': 'Andorinha',
          },
          {
            'id_cidade': 2901403,
            'nomeCidade': 'Angical',
          },
          {
            'id_cidade': 2901502,
            'nomeCidade': 'Anguera',
          },
          {
            'id_cidade': 2901601,
            'nomeCidade': 'Antas',
          },
          {
            'id_cidade': 2901700,
            'nomeCidade': 'Antônio Cardoso',
          },
          {
            'id_cidade': 2901809,
            'nomeCidade': 'Antônio Gonçalves',
          },
          {
            'id_cidade': 2901908,
            'nomeCidade': 'Aporá',
          },
          {
            'id_cidade': 2901957,
            'nomeCidade': 'Apuarema',
          },
          {
            'id_cidade': 2902054,
            'nomeCidade': 'Araçás',
          },
          {
            'id_cidade': 2902005,
            'nomeCidade': 'Aracatu',
          },
          {
            'id_cidade': 2902104,
            'nomeCidade': 'Araci',
          },
          {
            'id_cidade': 2902203,
            'nomeCidade': 'Aramari',
          },
          {
            'id_cidade': 2902252,
            'nomeCidade': 'Arataca',
          },
          {
            'id_cidade': 2902302,
            'nomeCidade': 'Aratuípe',
          },
          {
            'id_cidade': 2902401,
            'nomeCidade': 'Aurelino Leal',
          },
          {
            'id_cidade': 2902500,
            'nomeCidade': 'Baianópolis',
          },
          {
            'id_cidade': 2902609,
            'nomeCidade': 'Baixa Grande',
          },
          {
            'id_cidade': 2902658,
            'nomeCidade': 'Banzaê',
          },
          {
            'id_cidade': 2902708,
            'nomeCidade': 'Barra',
          },
          {
            'id_cidade': 2902807,
            'nomeCidade': 'Barra da Estiva',
          },
          {
            'id_cidade': 2902906,
            'nomeCidade': 'Barra do Choça',
          },
          {
            'id_cidade': 2903003,
            'nomeCidade': 'Barra do Mendes',
          },
          {
            'id_cidade': 2903102,
            'nomeCidade': 'Barra do Rocha',
          },
          {
            'id_cidade': 2903201,
            'nomeCidade': 'Barreiras',
          },
          {
            'id_cidade': 2903235,
            'nomeCidade': 'Barro Alto',
          },
          {
            'id_cidade': 2903300,
            'nomeCidade': 'Barro Preto',
          },
          {
            'id_cidade': 2903276,
            'nomeCidade': 'Barrocas',
          },
          {
            'id_cidade': 2903409,
            'nomeCidade': 'Belmonte',
          },
          {
            'id_cidade': 2903508,
            'nomeCidade': 'Belo Campo',
          },
          {
            'id_cidade': 2903607,
            'nomeCidade': 'Biritinga',
          },
          {
            'id_cidade': 2903706,
            'nomeCidade': 'Boa Nova',
          },
          {
            'id_cidade': 2903805,
            'nomeCidade': 'Boa Vista do Tupim',
          },
          {
            'id_cidade': 2903904,
            'nomeCidade': 'Bom Jesus da Lapa',
          },
          {
            'id_cidade': 2903953,
            'nomeCidade': 'Bom Jesus da Serra',
          },
          {
            'id_cidade': 2904001,
            'nomeCidade': 'Boninal',
          },
          {
            'id_cidade': 2904050,
            'nomeCidade': 'Bonito',
          },
          {
            'id_cidade': 2904100,
            'nomeCidade': 'Boquira',
          },
          {
            'id_cidade': 2904209,
            'nomeCidade': 'Botuporã',
          },
          {
            'id_cidade': 2904308,
            'nomeCidade': 'Brejões',
          },
          {
            'id_cidade': 2904407,
            'nomeCidade': 'Brejolândia',
          },
          {
            'id_cidade': 2904506,
            'nomeCidade': 'Brotas de Macaúbas',
          },
          {
            'id_cidade': 2904605,
            'nomeCidade': 'Brumado',
          },
          {
            'id_cidade': 2904704,
            'nomeCidade': 'Buerarema',
          },
          {
            'id_cidade': 2904753,
            'nomeCidade': 'Buritirama',
          },
          {
            'id_cidade': 2904803,
            'nomeCidade': 'Caatiba',
          },
          {
            'id_cidade': 2904852,
            'nomeCidade': 'Cabaceiras do Paraguaçu',
          },
          {
            'id_cidade': 2904902,
            'nomeCidade': 'Cachoeira',
          },
          {
            'id_cidade': 2905008,
            'nomeCidade': 'Caculé',
          },
          {
            'id_cidade': 2905107,
            'nomeCidade': 'Caém',
          },
          {
            'id_cidade': 2905156,
            'nomeCidade': 'Caetanos',
          },
          {
            'id_cidade': 2905206,
            'nomeCidade': 'Caetité',
          },
          {
            'id_cidade': 2905305,
            'nomeCidade': 'Cafarnaum',
          },
          {
            'id_cidade': 2905404,
            'nomeCidade': 'Cairu',
          },
          {
            'id_cidade': 2905503,
            'nomeCidade': 'Caldeirão Grande',
          },
          {
            'id_cidade': 2905602,
            'nomeCidade': 'Camacan',
          },
          {
            'id_cidade': 2905701,
            'nomeCidade': 'Camaçari',
          },
          {
            'id_cidade': 2905800,
            'nomeCidade': 'Camamu',
          },
          {
            'id_cidade': 2905909,
            'nomeCidade': 'Campo Alegre de Lourdes',
          },
          {
            'id_cidade': 2906006,
            'nomeCidade': 'Campo Formoso',
          },
          {
            'id_cidade': 2906105,
            'nomeCidade': 'Canápolis',
          },
          {
            'id_cidade': 2906204,
            'nomeCidade': 'Canarana',
          },
          {
            'id_cidade': 2906303,
            'nomeCidade': 'Canavieiras',
          },
          {
            'id_cidade': 2906402,
            'nomeCidade': 'Candeal',
          },
          {
            'id_cidade': 2906501,
            'nomeCidade': 'Candeias',
          },
          {
            'id_cidade': 2906600,
            'nomeCidade': 'Candiba',
          },
          {
            'id_cidade': 2906709,
            'nomeCidade': 'Cândido Sales',
          },
          {
            'id_cidade': 2906808,
            'nomeCidade': 'Cansanção',
          },
          {
            'id_cidade': 2906824,
            'nomeCidade': 'Canudos',
          },
          {
            'id_cidade': 2906857,
            'nomeCidade': 'Capela do Alto Alegre',
          },
          {
            'id_cidade': 2906873,
            'nomeCidade': 'Capim Grosso',
          },
          {
            'id_cidade': 2906899,
            'nomeCidade': 'Caraíbas',
          },
          {
            'id_cidade': 2906907,
            'nomeCidade': 'Caravelas',
          },
          {
            'id_cidade': 2907004,
            'nomeCidade': 'Cardeal da Silva',
          },
          {
            'id_cidade': 2907103,
            'nomeCidade': 'Carinhanha',
          },
          {
            'id_cidade': 2907202,
            'nomeCidade': 'Casa Nova',
          },
          {
            'id_cidade': 2907301,
            'nomeCidade': 'Castro Alves',
          },
          {
            'id_cidade': 2907400,
            'nomeCidade': 'Catolândia',
          },
          {
            'id_cidade': 2907509,
            'nomeCidade': 'Catu',
          },
          {
            'id_cidade': 2907558,
            'nomeCidade': 'Caturama',
          },
          {
            'id_cidade': 2907608,
            'nomeCidade': 'Central',
          },
          {
            'id_cidade': 2907707,
            'nomeCidade': 'Chorrochó',
          },
          {
            'id_cidade': 2907806,
            'nomeCidade': 'Cícero Dantas',
          },
          {
            'id_cidade': 2907905,
            'nomeCidade': 'Cipó',
          },
          {
            'id_cidade': 2908002,
            'nomeCidade': 'Coaraci',
          },
          {
            'id_cidade': 2908101,
            'nomeCidade': 'Cocos',
          },
          {
            'id_cidade': 2908200,
            'nomeCidade': 'Conceição da Feira',
          },
          {
            'id_cidade': 2908309,
            'nomeCidade': 'Conceição do Almeida',
          },
          {
            'id_cidade': 2908408,
            'nomeCidade': 'Conceição do Coité',
          },
          {
            'id_cidade': 2908507,
            'nomeCidade': 'Conceição do Jacuípe',
          },
          {
            'id_cidade': 2908606,
            'nomeCidade': 'Conde',
          },
          {
            'id_cidade': 2908705,
            'nomeCidade': 'Condeúba',
          },
          {
            'id_cidade': 2908804,
            'nomeCidade': 'Contendas do Sincorá',
          },
          {
            'id_cidade': 2908903,
            'nomeCidade': 'Coração de Maria',
          },
          {
            'id_cidade': 2909000,
            'nomeCidade': 'Cordeiros',
          },
          {
            'id_cidade': 2909109,
            'nomeCidade': 'Coribe',
          },
          {
            'id_cidade': 2909208,
            'nomeCidade': 'Coronel João Sá',
          },
          {
            'id_cidade': 2909307,
            'nomeCidade': 'Correntina',
          },
          {
            'id_cidade': 2909406,
            'nomeCidade': 'Cotegipe',
          },
          {
            'id_cidade': 2909505,
            'nomeCidade': 'Cravolândia',
          },
          {
            'id_cidade': 2909604,
            'nomeCidade': 'Crisópolis',
          },
          {
            'id_cidade': 2909703,
            'nomeCidade': 'Cristópolis',
          },
          {
            'id_cidade': 2909802,
            'nomeCidade': 'Cruz das Almas',
          },
          {
            'id_cidade': 2909901,
            'nomeCidade': 'Curaçá',
          },
          {
            'id_cidade': 2910008,
            'nomeCidade': 'Dário Meira',
          },
          {
            'id_cidade': 2910057,
            'nomeCidade': "Dias d'Ávila",
          },
          {
            'id_cidade': 2910107,
            'nomeCidade': 'Dom Basílio',
          },
          {
            'id_cidade': 2910206,
            'nomeCidade': 'Dom Macedo Costa',
          },
          {
            'id_cidade': 2910305,
            'nomeCidade': 'Elísio Medrado',
          },
          {
            'id_cidade': 2910404,
            'nomeCidade': 'Encruzilhada',
          },
          {
            'id_cidade': 2910503,
            'nomeCidade': 'Entre Rios',
          },
          {
            'id_cidade': 2900504,
            'nomeCidade': 'Érico Cardoso',
          },
          {
            'id_cidade': 2910602,
            'nomeCidade': 'Esplanada',
          },
          {
            'id_cidade': 2910701,
            'nomeCidade': 'Euclides da Cunha',
          },
          {
            'id_cidade': 2910727,
            'nomeCidade': 'Eunápolis',
          },
          {
            'id_cidade': 2910750,
            'nomeCidade': 'Fátima',
          },
          {
            'id_cidade': 2910776,
            'nomeCidade': 'Feira da Mata',
          },
          {
            'id_cidade': 2910800,
            'nomeCidade': 'Feira de Santana',
          },
          {
            'id_cidade': 2910859,
            'nomeCidade': 'Filadélfia',
          },
          {
            'id_cidade': 2910909,
            'nomeCidade': 'Firmino Alves',
          },
          {
            'id_cidade': 2911006,
            'nomeCidade': 'Floresta Azul',
          },
          {
            'id_cidade': 2911105,
            'nomeCidade': 'Formosa do Rio Preto',
          },
          {
            'id_cidade': 2911204,
            'nomeCidade': 'Gandu',
          },
          {
            'id_cidade': 2911253,
            'nomeCidade': 'Gavião',
          },
          {
            'id_cidade': 2911303,
            'nomeCidade': 'Gentio do Ouro',
          },
          {
            'id_cidade': 2911402,
            'nomeCidade': 'Glória',
          },
          {
            'id_cidade': 2911501,
            'nomeCidade': 'Gongogi',
          },
          {
            'id_cidade': 2911600,
            'nomeCidade': 'Governador Mangabeira',
          },
          {
            'id_cidade': 2911659,
            'nomeCidade': 'Guajeru',
          },
          {
            'id_cidade': 2911709,
            'nomeCidade': 'Guanambi',
          },
          {
            'id_cidade': 2911808,
            'nomeCidade': 'Guaratinga',
          },
          {
            'id_cidade': 2911857,
            'nomeCidade': 'Heliópolis',
          },
          {
            'id_cidade': 2911907,
            'nomeCidade': 'Iaçu',
          },
          {
            'id_cidade': 2912004,
            'nomeCidade': 'Ibiassucê',
          },
          {
            'id_cidade': 2912103,
            'nomeCidade': 'Ibicaraí',
          },
          {
            'id_cidade': 2912202,
            'nomeCidade': 'Ibicoara',
          },
          {
            'id_cidade': 2912301,
            'nomeCidade': 'Ibicuí',
          },
          {
            'id_cidade': 2912400,
            'nomeCidade': 'Ibipeba',
          },
          {
            'id_cidade': 2912509,
            'nomeCidade': 'Ibipitanga',
          },
          {
            'id_cidade': 2912608,
            'nomeCidade': 'Ibiquera',
          },
          {
            'id_cidade': 2912707,
            'nomeCidade': 'Ibirapitanga',
          },
          {
            'id_cidade': 2912806,
            'nomeCidade': 'Ibirapuã',
          },
          {
            'id_cidade': 2912905,
            'nomeCidade': 'Ibirataia',
          },
          {
            'id_cidade': 2913002,
            'nomeCidade': 'Ibitiara',
          },
          {
            'id_cidade': 2913101,
            'nomeCidade': 'Ibititá',
          },
          {
            'id_cidade': 2913200,
            'nomeCidade': 'Ibotirama',
          },
          {
            'id_cidade': 2913309,
            'nomeCidade': 'Ichu',
          },
          {
            'id_cidade': 2913408,
            'nomeCidade': 'Igaporã',
          },
          {
            'id_cidade': 2913457,
            'nomeCidade': 'Igrapiúna',
          },
          {
            'id_cidade': 2913507,
            'nomeCidade': 'Iguaí',
          },
          {
            'id_cidade': 2913606,
            'nomeCidade': 'Ilhéus',
          },
          {
            'id_cidade': 2913705,
            'nomeCidade': 'Inhambupe',
          },
          {
            'id_cidade': 2913804,
            'nomeCidade': 'Ipecaetá',
          },
          {
            'id_cidade': 2913903,
            'nomeCidade': 'Ipiaú',
          },
          {
            'id_cidade': 2914000,
            'nomeCidade': 'Ipirá',
          },
          {
            'id_cidade': 2914109,
            'nomeCidade': 'Ipupiara',
          },
          {
            'id_cidade': 2914208,
            'nomeCidade': 'Irajuba',
          },
          {
            'id_cidade': 2914307,
            'nomeCidade': 'Iramaia',
          },
          {
            'id_cidade': 2914406,
            'nomeCidade': 'Iraquara',
          },
          {
            'id_cidade': 2914505,
            'nomeCidade': 'Irará',
          },
          {
            'id_cidade': 2914604,
            'nomeCidade': 'Irecê',
          },
          {
            'id_cidade': 2914653,
            'nomeCidade': 'Itabela',
          },
          {
            'id_cidade': 2914703,
            'nomeCidade': 'Itaberaba',
          },
          {
            'id_cidade': 2914802,
            'nomeCidade': 'Itabuna',
          },
          {
            'id_cidade': 2914901,
            'nomeCidade': 'Itacaré',
          },
          {
            'id_cidade': 2915007,
            'nomeCidade': 'Itaeté',
          },
          {
            'id_cidade': 2915106,
            'nomeCidade': 'Itagi',
          },
          {
            'id_cidade': 2915205,
            'nomeCidade': 'Itagibá',
          },
          {
            'id_cidade': 2915304,
            'nomeCidade': 'Itagimirim',
          },
          {
            'id_cidade': 2915353,
            'nomeCidade': 'Itaguaçu da Bahia',
          },
          {
            'id_cidade': 2915403,
            'nomeCidade': 'Itaju do Colônia',
          },
          {
            'id_cidade': 2915502,
            'nomeCidade': 'Itajuípe',
          },
          {
            'id_cidade': 2915601,
            'nomeCidade': 'Itamaraju',
          },
          {
            'id_cidade': 2915700,
            'nomeCidade': 'Itamari',
          },
          {
            'id_cidade': 2915809,
            'nomeCidade': 'Itambé',
          },
          {
            'id_cidade': 2915908,
            'nomeCidade': 'Itanagra',
          },
          {
            'id_cidade': 2916005,
            'nomeCidade': 'Itanhém',
          },
          {
            'id_cidade': 2916104,
            'nomeCidade': 'Itaparica',
          },
          {
            'id_cidade': 2916203,
            'nomeCidade': 'Itapé',
          },
          {
            'id_cidade': 2916302,
            'nomeCidade': 'Itapebi',
          },
          {
            'id_cidade': 2916401,
            'nomeCidade': 'Itapetinga',
          },
          {
            'id_cidade': 2916500,
            'nomeCidade': 'Itapicuru',
          },
          {
            'id_cidade': 2916609,
            'nomeCidade': 'Itapitanga',
          },
          {
            'id_cidade': 2916708,
            'nomeCidade': 'Itaquara',
          },
          {
            'id_cidade': 2916807,
            'nomeCidade': 'Itarantim',
          },
          {
            'id_cidade': 2916856,
            'nomeCidade': 'Itatim',
          },
          {
            'id_cidade': 2916906,
            'nomeCidade': 'Itiruçu',
          },
          {
            'id_cidade': 2917003,
            'nomeCidade': 'Itiúba',
          },
          {
            'id_cidade': 2917102,
            'nomeCidade': 'Itororó',
          },
          {
            'id_cidade': 2917201,
            'nomeCidade': 'Ituaçu',
          },
          {
            'id_cidade': 2917300,
            'nomeCidade': 'Ituberá',
          },
          {
            'id_cidade': 2917334,
            'nomeCidade': 'Iuiu',
          },
          {
            'id_cidade': 2917359,
            'nomeCidade': 'Jaborandi',
          },
          {
            'id_cidade': 2917409,
            'nomeCidade': 'Jacaraci',
          },
          {
            'id_cidade': 2917508,
            'nomeCidade': 'Jacobina',
          },
          {
            'id_cidade': 2917607,
            'nomeCidade': 'Jaguaquara',
          },
          {
            'id_cidade': 2917706,
            'nomeCidade': 'Jaguarari',
          },
          {
            'id_cidade': 2917805,
            'nomeCidade': 'Jaguaripe',
          },
          {
            'id_cidade': 2917904,
            'nomeCidade': 'Jandaíra',
          },
          {
            'id_cidade': 2918001,
            'nomeCidade': 'Jequié',
          },
          {
            'id_cidade': 2918100,
            'nomeCidade': 'Jeremoabo',
          },
          {
            'id_cidade': 2918209,
            'nomeCidade': 'Jiquiriçá',
          },
          {
            'id_cidade': 2918308,
            'nomeCidade': 'Jitaúna',
          },
          {
            'id_cidade': 2918357,
            'nomeCidade': 'João Dourado',
          },
          {
            'id_cidade': 2918407,
            'nomeCidade': 'Juazeiro',
          },
          {
            'id_cidade': 2918456,
            'nomeCidade': 'Jucuruçu',
          },
          {
            'id_cidade': 2918506,
            'nomeCidade': 'Jussara',
          },
          {
            'id_cidade': 2918555,
            'nomeCidade': 'Jussari',
          },
          {
            'id_cidade': 2918605,
            'nomeCidade': 'Jussiape',
          },
          {
            'id_cidade': 2918704,
            'nomeCidade': 'Lafaiete Coutinho',
          },
          {
            'id_cidade': 2918753,
            'nomeCidade': 'Lagoa Real',
          },
          {
            'id_cidade': 2918803,
            'nomeCidade': 'Laje',
          },
          {
            'id_cidade': 2918902,
            'nomeCidade': 'Lajedão',
          },
          {
            'id_cidade': 2919009,
            'nomeCidade': 'Lajedinho',
          },
          {
            'id_cidade': 2919058,
            'nomeCidade': 'Lajedo do Tabocal',
          },
          {
            'id_cidade': 2919108,
            'nomeCidade': 'Lamarão',
          },
          {
            'id_cidade': 2919157,
            'nomeCidade': 'Lapão',
          },
          {
            'id_cidade': 2919207,
            'nomeCidade': 'Lauro de Freitas',
          },
          {
            'id_cidade': 2919306,
            'nomeCidade': 'Lençóis',
          },
          {
            'id_cidade': 2919405,
            'nomeCidade': 'Licínio de Almeida',
          },
          {
            'id_cidade': 2919504,
            'nomeCidade': 'Livramento de Nossa Senhora',
          },
          {
            'id_cidade': 2919553,
            'nomeCidade': 'Luís Eduardo Magalhães',
          },
          {
            'id_cidade': 2919603,
            'nomeCidade': 'Macajuba',
          },
          {
            'id_cidade': 2919702,
            'nomeCidade': 'Macarani',
          },
          {
            'id_cidade': 2919801,
            'nomeCidade': 'Macaúbas',
          },
          {
            'id_cidade': 2919900,
            'nomeCidade': 'Macururé',
          },
          {
            'id_cidade': 2919926,
            'nomeCidade': 'Madre de Deus',
          },
          {
            'id_cidade': 2919959,
            'nomeCidade': 'Maetinga',
          },
          {
            'id_cidade': 2920007,
            'nomeCidade': 'Maiquinique',
          },
          {
            'id_cidade': 2920106,
            'nomeCidade': 'Mairi',
          },
          {
            'id_cidade': 2920205,
            'nomeCidade': 'Malhada',
          },
          {
            'id_cidade': 2920304,
            'nomeCidade': 'Malhada de Pedras',
          },
          {
            'id_cidade': 2920403,
            'nomeCidade': 'Manoel Vitorino',
          },
          {
            'id_cidade': 2920452,
            'nomeCidade': 'Mansidão',
          },
          {
            'id_cidade': 2920502,
            'nomeCidade': 'Maracás',
          },
          {
            'id_cidade': 2920601,
            'nomeCidade': 'Maragogipe',
          },
          {
            'id_cidade': 2920700,
            'nomeCidade': 'Maraú',
          },
          {
            'id_cidade': 2920809,
            'nomeCidade': 'Marcionílio Souza',
          },
          {
            'id_cidade': 2920908,
            'nomeCidade': 'Mascote',
          },
          {
            'id_cidade': 2921005,
            'nomeCidade': 'Mata de São João',
          },
          {
            'id_cidade': 2921054,
            'nomeCidade': 'Matina',
          },
          {
            'id_cidade': 2921104,
            'nomeCidade': 'Medeiros Neto',
          },
          {
            'id_cidade': 2921203,
            'nomeCidade': 'Miguel Calmon',
          },
          {
            'id_cidade': 2921302,
            'nomeCidade': 'Milagres',
          },
          {
            'id_cidade': 2921401,
            'nomeCidade': 'Mirangaba',
          },
          {
            'id_cidade': 2921450,
            'nomeCidade': 'Mirante',
          },
          {
            'id_cidade': 2921500,
            'nomeCidade': 'Monte Santo',
          },
          {
            'id_cidade': 2921609,
            'nomeCidade': 'Morpará',
          },
          {
            'id_cidade': 2921708,
            'nomeCidade': 'Morro do Chapéu',
          },
          {
            'id_cidade': 2921807,
            'nomeCidade': 'Mortugaba',
          },
          {
            'id_cidade': 2921906,
            'nomeCidade': 'Mucugê',
          },
          {
            'id_cidade': 2922003,
            'nomeCidade': 'Mucuri',
          },
          {
            'id_cidade': 2922052,
            'nomeCidade': 'Mulungu do Morro',
          },
          {
            'id_cidade': 2922102,
            'nomeCidade': 'Mundo Novo',
          },
          {
            'id_cidade': 2922201,
            'nomeCidade': 'Muniz Ferreira',
          },
          {
            'id_cidade': 2922250,
            'nomeCidade': 'Muquém do São Francisco',
          },
          {
            'id_cidade': 2922300,
            'nomeCidade': 'Muritiba',
          },
          {
            'id_cidade': 2922409,
            'nomeCidade': 'Mutuípe',
          },
          {
            'id_cidade': 2922508,
            'nomeCidade': 'Nazaré',
          },
          {
            'id_cidade': 2922607,
            'nomeCidade': 'Nilo Peçanha',
          },
          {
            'id_cidade': 2922656,
            'nomeCidade': 'Nordestina',
          },
          {
            'id_cidade': 2922706,
            'nomeCidade': 'Nova Canaã',
          },
          {
            'id_cidade': 2922730,
            'nomeCidade': 'Nova Fátima',
          },
          {
            'id_cidade': 2922755,
            'nomeCidade': 'Nova Ibiá',
          },
          {
            'id_cidade': 2922805,
            'nomeCidade': 'Nova Itarana',
          },
          {
            'id_cidade': 2922854,
            'nomeCidade': 'Nova Redenção',
          },
          {
            'id_cidade': 2922904,
            'nomeCidade': 'Nova Soure',
          },
          {
            'id_cidade': 2923001,
            'nomeCidade': 'Nova Viçosa',
          },
          {
            'id_cidade': 2923035,
            'nomeCidade': 'Novo Horizonte',
          },
          {
            'id_cidade': 2923050,
            'nomeCidade': 'Novo Triunfo',
          },
          {
            'id_cidade': 2923100,
            'nomeCidade': 'Olindina',
          },
          {
            'id_cidade': 2923209,
            'nomeCidade': 'Oliveira dos Brejinhos',
          },
          {
            'id_cidade': 2923308,
            'nomeCidade': 'Ouriçangas',
          },
          {
            'id_cidade': 2923357,
            'nomeCidade': 'Ourolândia',
          },
          {
            'id_cidade': 2923407,
            'nomeCidade': 'Palmas de Monte Alto',
          },
          {
            'id_cidade': 2923506,
            'nomeCidade': 'Palmeiras',
          },
          {
            'id_cidade': 2923605,
            'nomeCidade': 'Paramirim',
          },
          {
            'id_cidade': 2923704,
            'nomeCidade': 'Paratinga',
          },
          {
            'id_cidade': 2923803,
            'nomeCidade': 'Paripiranga',
          },
          {
            'id_cidade': 2923902,
            'nomeCidade': 'Pau Brasil',
          },
          {
            'id_cidade': 2924009,
            'nomeCidade': 'Paulo Afonso',
          },
          {
            'id_cidade': 2924058,
            'nomeCidade': 'Pé de Serra',
          },
          {
            'id_cidade': 2924108,
            'nomeCidade': 'Pedrão',
          },
          {
            'id_cidade': 2924207,
            'nomeCidade': 'Pedro Alexandre',
          },
          {
            'id_cidade': 2924306,
            'nomeCidade': 'Piatã',
          },
          {
            'id_cidade': 2924405,
            'nomeCidade': 'Pilão Arcado',
          },
          {
            'id_cidade': 2924504,
            'nomeCidade': 'Pindaí',
          },
          {
            'id_cidade': 2924603,
            'nomeCidade': 'Pindobaçu',
          },
          {
            'id_cidade': 2924652,
            'nomeCidade': 'Pintadas',
          },
          {
            'id_cidade': 2924678,
            'nomeCidade': 'Piraí do Norte',
          },
          {
            'id_cidade': 2924702,
            'nomeCidade': 'Piripá',
          },
          {
            'id_cidade': 2924801,
            'nomeCidade': 'Piritiba',
          },
          {
            'id_cidade': 2924900,
            'nomeCidade': 'Planaltino',
          },
          {
            'id_cidade': 2925006,
            'nomeCidade': 'Planalto',
          },
          {
            'id_cidade': 2925105,
            'nomeCidade': 'Poções',
          },
          {
            'id_cidade': 2925204,
            'nomeCidade': 'Pojuca',
          },
          {
            'id_cidade': 2925253,
            'nomeCidade': 'Ponto Novo',
          },
          {
            'id_cidade': 2925303,
            'nomeCidade': 'Porto Seguro',
          },
          {
            'id_cidade': 2925402,
            'nomeCidade': 'Potiraguá',
          },
          {
            'id_cidade': 2925501,
            'nomeCidade': 'Prado',
          },
          {
            'id_cidade': 2925600,
            'nomeCidade': 'Presidente Dutra',
          },
          {
            'id_cidade': 2925709,
            'nomeCidade': 'Presidente Jânio Quadros',
          },
          {
            'id_cidade': 2925758,
            'nomeCidade': 'Presidente Tancredo Neves',
          },
          {
            'id_cidade': 2925808,
            'nomeCidade': 'Queimadas',
          },
          {
            'id_cidade': 2925907,
            'nomeCidade': 'Quijingue',
          },
          {
            'id_cidade': 2925931,
            'nomeCidade': 'Quixabeira',
          },
          {
            'id_cidade': 2925956,
            'nomeCidade': 'Rafael Jambeiro',
          },
          {
            'id_cidade': 2926004,
            'nomeCidade': 'Remanso',
          },
          {
            'id_cidade': 2926103,
            'nomeCidade': 'Retirolândia',
          },
          {
            'id_cidade': 2926202,
            'nomeCidade': 'Riachão das Neves',
          },
          {
            'id_cidade': 2926301,
            'nomeCidade': 'Riachão do Jacuípe',
          },
          {
            'id_cidade': 2926400,
            'nomeCidade': 'Riacho de Santana',
          },
          {
            'id_cidade': 2926509,
            'nomeCidade': 'Ribeira do Amparo',
          },
          {
            'id_cidade': 2926608,
            'nomeCidade': 'Ribeira do Pombal',
          },
          {
            'id_cidade': 2926657,
            'nomeCidade': 'Ribeirão do Largo',
          },
          {
            'id_cidade': 2926707,
            'nomeCidade': 'Rio de Contas',
          },
          {
            'id_cidade': 2926806,
            'nomeCidade': 'Rio do Antônio',
          },
          {
            'id_cidade': 2926905,
            'nomeCidade': 'Rio do Pires',
          },
          {
            'id_cidade': 2927002,
            'nomeCidade': 'Rio Real',
          },
          {
            'id_cidade': 2927101,
            'nomeCidade': 'Rodelas',
          },
          {
            'id_cidade': 2927200,
            'nomeCidade': 'Ruy Barbosa',
          },
          {
            'id_cidade': 2927309,
            'nomeCidade': 'Salinas da Margarida',
          },
          {
            'id_cidade': 2927408,
            'nomeCidade': 'Salvador',
          },
          {
            'id_cidade': 2927507,
            'nomeCidade': 'Santa Bárbara',
          },
          {
            'id_cidade': 2927606,
            'nomeCidade': 'Santa Brígida',
          },
          {
            'id_cidade': 2927705,
            'nomeCidade': 'Santa Cruz Cabrália',
          },
          {
            'id_cidade': 2927804,
            'nomeCidade': 'Santa Cruz da Vitória',
          },
          {
            'id_cidade': 2927903,
            'nomeCidade': 'Santa Inês',
          },
          {
            'id_cidade': 2928059,
            'nomeCidade': 'Santa Luzia',
          },
          {
            'id_cidade': 2928109,
            'nomeCidade': 'Santa Maria da Vitória',
          },
          {
            'id_cidade': 2928406,
            'nomeCidade': 'Santa Rita de Cássia',
          },
          {
            'id_cidade': 2928505,
            'nomeCidade': 'Santa Terezinha',
          },
          {
            'id_cidade': 2928000,
            'nomeCidade': 'Santaluz',
          },
          {
            'id_cidade': 2928208,
            'nomeCidade': 'Santana',
          },
          {
            'id_cidade': 2928307,
            'nomeCidade': 'Santanópolis',
          },
          {
            'id_cidade': 2928604,
            'nomeCidade': 'Santo Amaro',
          },
          {
            'id_cidade': 2928703,
            'nomeCidade': 'Santo Antônio de Jesus',
          },
          {
            'id_cidade': 2928802,
            'nomeCidade': 'Santo Estêvão',
          },
          {
            'id_cidade': 2928901,
            'nomeCidade': 'São Desidério',
          },
          {
            'id_cidade': 2928950,
            'nomeCidade': 'São Domingos',
          },
          {
            'id_cidade': 2929107,
            'nomeCidade': 'São Felipe',
          },
          {
            'id_cidade': 2929008,
            'nomeCidade': 'São Félix',
          },
          {
            'id_cidade': 2929057,
            'nomeCidade': 'São Félix do Coribe',
          },
          {
            'id_cidade': 2929206,
            'nomeCidade': 'São Francisco do Conde',
          },
          {
            'id_cidade': 2929255,
            'nomeCidade': 'São Gabriel',
          },
          {
            'id_cidade': 2929305,
            'nomeCidade': 'São Gonçalo dos Campos',
          },
          {
            'id_cidade': 2929354,
            'nomeCidade': 'São José da Vitória',
          },
          {
            'id_cidade': 2929370,
            'nomeCidade': 'São José do Jacuípe',
          },
          {
            'id_cidade': 2929404,
            'nomeCidade': 'São Miguel das Matas',
          },
          {
            'id_cidade': 2929503,
            'nomeCidade': 'São Sebastião do Passé',
          },
          {
            'id_cidade': 2929602,
            'nomeCidade': 'Sapeaçu',
          },
          {
            'id_cidade': 2929701,
            'nomeCidade': 'Sátiro Dias',
          },
          {
            'id_cidade': 2929750,
            'nomeCidade': 'Saubara',
          },
          {
            'id_cidade': 2929800,
            'nomeCidade': 'Saúde',
          },
          {
            'id_cidade': 2929909,
            'nomeCidade': 'Seabra',
          },
          {
            'id_cidade': 2930006,
            'nomeCidade': 'Sebastião Laranjeiras',
          },
          {
            'id_cidade': 2930105,
            'nomeCidade': 'Senhor do Bonfim',
          },
          {
            'id_cidade': 2930204,
            'nomeCidade': 'Sento Sé',
          },
          {
            'id_cidade': 2930154,
            'nomeCidade': 'Serra do Ramalho',
          },
          {
            'id_cidade': 2930303,
            'nomeCidade': 'Serra Dourada',
          },
          {
            'id_cidade': 2930402,
            'nomeCidade': 'Serra Preta',
          },
          {
            'id_cidade': 2930501,
            'nomeCidade': 'Serrinha',
          },
          {
            'id_cidade': 2930600,
            'nomeCidade': 'Serrolândia',
          },
          {
            'id_cidade': 2930709,
            'nomeCidade': 'Simões Filho',
          },
          {
            'id_cidade': 2930758,
            'nomeCidade': 'Sítio do Mato',
          },
          {
            'id_cidade': 2930766,
            'nomeCidade': 'Sítio do Quinto',
          },
          {
            'id_cidade': 2930774,
            'nomeCidade': 'Sobradinho',
          },
          {
            'id_cidade': 2930808,
            'nomeCidade': 'Souto Soares',
          },
          {
            'id_cidade': 2930907,
            'nomeCidade': 'Tabocas do Brejo Velho',
          },
          {
            'id_cidade': 2931004,
            'nomeCidade': 'Tanhaçu',
          },
          {
            'id_cidade': 2931053,
            'nomeCidade': 'Tanque Novo',
          },
          {
            'id_cidade': 2931103,
            'nomeCidade': 'Tanquinho',
          },
          {
            'id_cidade': 2931202,
            'nomeCidade': 'Taperoá',
          },
          {
            'id_cidade': 2931301,
            'nomeCidade': 'Tapiramutá',
          },
          {
            'id_cidade': 2931350,
            'nomeCidade': 'Teixeira de Freitas',
          },
          {
            'id_cidade': 2931400,
            'nomeCidade': 'Teodoro Sampaio',
          },
          {
            'id_cidade': 2931509,
            'nomeCidade': 'Teofilândia',
          },
          {
            'id_cidade': 2931608,
            'nomeCidade': 'Teolândia',
          },
          {
            'id_cidade': 2931707,
            'nomeCidade': 'Terra Nova',
          },
          {
            'id_cidade': 2931806,
            'nomeCidade': 'Tremedal',
          },
          {
            'id_cidade': 2931905,
            'nomeCidade': 'Tucano',
          },
          {
            'id_cidade': 2932002,
            'nomeCidade': 'Uauá',
          },
          {
            'id_cidade': 2932101,
            'nomeCidade': 'Ubaíra',
          },
          {
            'id_cidade': 2932200,
            'nomeCidade': 'Ubaitaba',
          },
          {
            'id_cidade': 2932309,
            'nomeCidade': 'Ubatã',
          },
          {
            'id_cidade': 2932408,
            'nomeCidade': 'Uibaí',
          },
          {
            'id_cidade': 2932457,
            'nomeCidade': 'Umburanas',
          },
          {
            'id_cidade': 2932507,
            'nomeCidade': 'Una',
          },
          {
            'id_cidade': 2932606,
            'nomeCidade': 'Urandi',
          },
          {
            'id_cidade': 2932705,
            'nomeCidade': 'Uruçuca',
          },
          {
            'id_cidade': 2932804,
            'nomeCidade': 'Utinga',
          },
          {
            'id_cidade': 2932903,
            'nomeCidade': 'Valença',
          },
          {
            'id_cidade': 2933000,
            'nomeCidade': 'Valente',
          },
          {
            'id_cidade': 2933059,
            'nomeCidade': 'Várzea da Roça',
          },
          {
            'id_cidade': 2933109,
            'nomeCidade': 'Várzea do Poço',
          },
          {
            'id_cidade': 2933158,
            'nomeCidade': 'Várzea Nova',
          },
          {
            'id_cidade': 2933174,
            'nomeCidade': 'Varzedo',
          },
          {
            'id_cidade': 2933208,
            'nomeCidade': 'Vera Cruz',
          },
          {
            'id_cidade': 2933257,
            'nomeCidade': 'Vereda',
          },
          {
            'id_cidade': 2933307,
            'nomeCidade': 'Vitória da Conquista',
          },
          {
            'id_cidade': 2933406,
            'nomeCidade': 'Wagner',
          },
          {
            'id_cidade': 2933455,
            'nomeCidade': 'Wanderley',
          },
          {
            'id_cidade': 2933505,
            'nomeCidade': 'Wenceslau Guimarães',
          },
          {
            'id_cidade': 2933604,
            'nomeCidade': 'Xique-Xique',
          },
        ],
      },
      {
        'id': 23,
        'nomeEstado': 'Ceará',
        'sigla': 'CE',
        'cidades': [
          {
            'id_cidade': 2300101,
            'nomeCidade': 'Abaiara',
          },
          {
            'id_cidade': 2300150,
            'nomeCidade': 'Acarape',
          },
          {
            'id_cidade': 2300200,
            'nomeCidade': 'Acaraú',
          },
          {
            'id_cidade': 2300309,
            'nomeCidade': 'Acopiara',
          },
          {
            'id_cidade': 2300408,
            'nomeCidade': 'Aiuaba',
          },
          {
            'id_cidade': 2300507,
            'nomeCidade': 'Alcântaras',
          },
          {
            'id_cidade': 2300606,
            'nomeCidade': 'Altaneira',
          },
          {
            'id_cidade': 2300705,
            'nomeCidade': 'Alto Santo',
          },
          {
            'id_cidade': 2300754,
            'nomeCidade': 'Amontada',
          },
          {
            'id_cidade': 2300804,
            'nomeCidade': 'Antonina do Norte',
          },
          {
            'id_cidade': 2300903,
            'nomeCidade': 'Apuiarés',
          },
          {
            'id_cidade': 2301000,
            'nomeCidade': 'Aquiraz',
          },
          {
            'id_cidade': 2301109,
            'nomeCidade': 'Aracati',
          },
          {
            'id_cidade': 2301208,
            'nomeCidade': 'Aracoiaba',
          },
          {
            'id_cidade': 2301257,
            'nomeCidade': 'Ararendá',
          },
          {
            'id_cidade': 2301307,
            'nomeCidade': 'Araripe',
          },
          {
            'id_cidade': 2301406,
            'nomeCidade': 'Aratuba',
          },
          {
            'id_cidade': 2301505,
            'nomeCidade': 'Arneiroz',
          },
          {
            'id_cidade': 2301604,
            'nomeCidade': 'Assaré',
          },
          {
            'id_cidade': 2301703,
            'nomeCidade': 'Aurora',
          },
          {
            'id_cidade': 2301802,
            'nomeCidade': 'Baixio',
          },
          {
            'id_cidade': 2301851,
            'nomeCidade': 'Banabuiú',
          },
          {
            'id_cidade': 2301901,
            'nomeCidade': 'Barbalha',
          },
          {
            'id_cidade': 2301950,
            'nomeCidade': 'Barreira',
          },
          {
            'id_cidade': 2302008,
            'nomeCidade': 'Barro',
          },
          {
            'id_cidade': 2302057,
            'nomeCidade': 'Barroquinha',
          },
          {
            'id_cidade': 2302107,
            'nomeCidade': 'Baturité',
          },
          {
            'id_cidade': 2302206,
            'nomeCidade': 'Beberibe',
          },
          {
            'id_cidade': 2302305,
            'nomeCidade': 'Bela Cruz',
          },
          {
            'id_cidade': 2302404,
            'nomeCidade': 'Boa Viagem',
          },
          {
            'id_cidade': 2302503,
            'nomeCidade': 'Brejo Santo',
          },
          {
            'id_cidade': 2302602,
            'nomeCidade': 'Camocim',
          },
          {
            'id_cidade': 2302701,
            'nomeCidade': 'Campos Sales',
          },
          {
            'id_cidade': 2302800,
            'nomeCidade': 'Canindé',
          },
          {
            'id_cidade': 2302909,
            'nomeCidade': 'Capistrano',
          },
          {
            'id_cidade': 2303006,
            'nomeCidade': 'Caridade',
          },
          {
            'id_cidade': 2303105,
            'nomeCidade': 'Cariré',
          },
          {
            'id_cidade': 2303204,
            'nomeCidade': 'Caririaçu',
          },
          {
            'id_cidade': 2303303,
            'nomeCidade': 'Cariús',
          },
          {
            'id_cidade': 2303402,
            'nomeCidade': 'Carnaubal',
          },
          {
            'id_cidade': 2303501,
            'nomeCidade': 'Cascavel',
          },
          {
            'id_cidade': 2303600,
            'nomeCidade': 'Catarina',
          },
          {
            'id_cidade': 2303659,
            'nomeCidade': 'Catunda',
          },
          {
            'id_cidade': 2303709,
            'nomeCidade': 'Caucaia',
          },
          {
            'id_cidade': 2303808,
            'nomeCidade': 'Cedro',
          },
          {
            'id_cidade': 2303907,
            'nomeCidade': 'Chaval',
          },
          {
            'id_cidade': 2303931,
            'nomeCidade': 'Choró',
          },
          {
            'id_cidade': 2303956,
            'nomeCidade': 'Chorozinho',
          },
          {
            'id_cidade': 2304004,
            'nomeCidade': 'Coreaú',
          },
          {
            'id_cidade': 2304103,
            'nomeCidade': 'Crateús',
          },
          {
            'id_cidade': 2304202,
            'nomeCidade': 'Crato',
          },
          {
            'id_cidade': 2304236,
            'nomeCidade': 'Croatá',
          },
          {
            'id_cidade': 2304251,
            'nomeCidade': 'Cruz',
          },
          {
            'id_cidade': 2304269,
            'nomeCidade': 'Deputado Irapuan Pinheiro',
          },
          {
            'id_cidade': 2304277,
            'nomeCidade': 'Ererê',
          },
          {
            'id_cidade': 2304285,
            'nomeCidade': 'Eusébio',
          },
          {
            'id_cidade': 2304301,
            'nomeCidade': 'Farias Brito',
          },
          {
            'id_cidade': 2304350,
            'nomeCidade': 'Forquilha',
          },
          {
            'id_cidade': 2304400,
            'nomeCidade': 'Fortaleza',
          },
          {
            'id_cidade': 2304459,
            'nomeCidade': 'Fortim',
          },
          {
            'id_cidade': 2304509,
            'nomeCidade': 'Frecheirinha',
          },
          {
            'id_cidade': 2304608,
            'nomeCidade': 'General Sampaio',
          },
          {
            'id_cidade': 2304657,
            'nomeCidade': 'Graça',
          },
          {
            'id_cidade': 2304707,
            'nomeCidade': 'Granja',
          },
          {
            'id_cidade': 2304806,
            'nomeCidade': 'Granjeiro',
          },
          {
            'id_cidade': 2304905,
            'nomeCidade': 'Groaíras',
          },
          {
            'id_cidade': 2304954,
            'nomeCidade': 'Guaiúba',
          },
          {
            'id_cidade': 2305001,
            'nomeCidade': 'Guaraciaba do Norte',
          },
          {
            'id_cidade': 2305100,
            'nomeCidade': 'Guaramiranga',
          },
          {
            'id_cidade': 2305209,
            'nomeCidade': 'Hidrolândia',
          },
          {
            'id_cidade': 2305233,
            'nomeCidade': 'Horizonte',
          },
          {
            'id_cidade': 2305266,
            'nomeCidade': 'Ibaretama',
          },
          {
            'id_cidade': 2305308,
            'nomeCidade': 'Ibiapina',
          },
          {
            'id_cidade': 2305332,
            'nomeCidade': 'Ibicuitinga',
          },
          {
            'id_cidade': 2305357,
            'nomeCidade': 'Icapuí',
          },
          {
            'id_cidade': 2305407,
            'nomeCidade': 'Icó',
          },
          {
            'id_cidade': 2305506,
            'nomeCidade': 'Iguatu',
          },
          {
            'id_cidade': 2305605,
            'nomeCidade': 'Independência',
          },
          {
            'id_cidade': 2305654,
            'nomeCidade': 'Ipaporanga',
          },
          {
            'id_cidade': 2305704,
            'nomeCidade': 'Ipaumirim',
          },
          {
            'id_cidade': 2305803,
            'nomeCidade': 'Ipu',
          },
          {
            'id_cidade': 2305902,
            'nomeCidade': 'Ipueiras',
          },
          {
            'id_cidade': 2306009,
            'nomeCidade': 'Iracema',
          },
          {
            'id_cidade': 2306108,
            'nomeCidade': 'Irauçuba',
          },
          {
            'id_cidade': 2306207,
            'nomeCidade': 'Itaiçaba',
          },
          {
            'id_cidade': 2306256,
            'nomeCidade': 'Itaitinga',
          },
          {
            'id_cidade': 2306306,
            'nomeCidade': 'Itapajé',
          },
          {
            'id_cidade': 2306405,
            'nomeCidade': 'Itapipoca',
          },
          {
            'id_cidade': 2306504,
            'nomeCidade': 'Itapiúna',
          },
          {
            'id_cidade': 2306553,
            'nomeCidade': 'Itarema',
          },
          {
            'id_cidade': 2306603,
            'nomeCidade': 'Itatira',
          },
          {
            'id_cidade': 2306702,
            'nomeCidade': 'Jaguaretama',
          },
          {
            'id_cidade': 2306801,
            'nomeCidade': 'Jaguaribara',
          },
          {
            'id_cidade': 2306900,
            'nomeCidade': 'Jaguaribe',
          },
          {
            'id_cidade': 2307007,
            'nomeCidade': 'Jaguaruana',
          },
          {
            'id_cidade': 2307106,
            'nomeCidade': 'Jardim',
          },
          {
            'id_cidade': 2307205,
            'nomeCidade': 'Jati',
          },
          {
            'id_cidade': 2307254,
            'nomeCidade': 'Jijoca de Jericoacoara',
          },
          {
            'id_cidade': 2307304,
            'nomeCidade': 'Juazeiro do Norte',
          },
          {
            'id_cidade': 2307403,
            'nomeCidade': 'Jucás',
          },
          {
            'id_cidade': 2307502,
            'nomeCidade': 'Lavras da Mangabeira',
          },
          {
            'id_cidade': 2307601,
            'nomeCidade': 'Limoeiro do Norte',
          },
          {
            'id_cidade': 2307635,
            'nomeCidade': 'Madalena',
          },
          {
            'id_cidade': 2307650,
            'nomeCidade': 'Maracanaú',
          },
          {
            'id_cidade': 2307700,
            'nomeCidade': 'Maranguape',
          },
          {
            'id_cidade': 2307809,
            'nomeCidade': 'Marco',
          },
          {
            'id_cidade': 2307908,
            'nomeCidade': 'Martinópole',
          },
          {
            'id_cidade': 2308005,
            'nomeCidade': 'Massapê',
          },
          {
            'id_cidade': 2308104,
            'nomeCidade': 'Mauriti',
          },
          {
            'id_cidade': 2308203,
            'nomeCidade': 'Meruoca',
          },
          {
            'id_cidade': 2308302,
            'nomeCidade': 'Milagres',
          },
          {
            'id_cidade': 2308351,
            'nomeCidade': 'Milhã',
          },
          {
            'id_cidade': 2308377,
            'nomeCidade': 'Miraíma',
          },
          {
            'id_cidade': 2308401,
            'nomeCidade': 'Missão Velha',
          },
          {
            'id_cidade': 2308500,
            'nomeCidade': 'Mombaça',
          },
          {
            'id_cidade': 2308609,
            'nomeCidade': 'Monsenhor Tabosa',
          },
          {
            'id_cidade': 2308708,
            'nomeCidade': 'Morada Nova',
          },
          {
            'id_cidade': 2308807,
            'nomeCidade': 'Moraújo',
          },
          {
            'id_cidade': 2308906,
            'nomeCidade': 'Morrinhos',
          },
          {
            'id_cidade': 2309003,
            'nomeCidade': 'Mucambo',
          },
          {
            'id_cidade': 2309102,
            'nomeCidade': 'Mulungu',
          },
          {
            'id_cidade': 2309201,
            'nomeCidade': 'Nova Olinda',
          },
          {
            'id_cidade': 2309300,
            'nomeCidade': 'Nova Russas',
          },
          {
            'id_cidade': 2309409,
            'nomeCidade': 'Novo Oriente',
          },
          {
            'id_cidade': 2309458,
            'nomeCidade': 'Ocara',
          },
          {
            'id_cidade': 2309508,
            'nomeCidade': 'Orós',
          },
          {
            'id_cidade': 2309607,
            'nomeCidade': 'Pacajus',
          },
          {
            'id_cidade': 2309706,
            'nomeCidade': 'Pacatuba',
          },
          {
            'id_cidade': 2309805,
            'nomeCidade': 'Pacoti',
          },
          {
            'id_cidade': 2309904,
            'nomeCidade': 'Pacujá',
          },
          {
            'id_cidade': 2310001,
            'nomeCidade': 'Palhano',
          },
          {
            'id_cidade': 2310100,
            'nomeCidade': 'Palmácia',
          },
          {
            'id_cidade': 2310209,
            'nomeCidade': 'Paracuru',
          },
          {
            'id_cidade': 2310258,
            'nomeCidade': 'Paraipaba',
          },
          {
            'id_cidade': 2310308,
            'nomeCidade': 'Parambu',
          },
          {
            'id_cidade': 2310407,
            'nomeCidade': 'Paramoti',
          },
          {
            'id_cidade': 2310506,
            'nomeCidade': 'Pedra Branca',
          },
          {
            'id_cidade': 2310605,
            'nomeCidade': 'Penaforte',
          },
          {
            'id_cidade': 2310704,
            'nomeCidade': 'Pentecoste',
          },
          {
            'id_cidade': 2310803,
            'nomeCidade': 'Pereiro',
          },
          {
            'id_cidade': 2310852,
            'nomeCidade': 'Pindoretama',
          },
          {
            'id_cidade': 2310902,
            'nomeCidade': 'Piquet Carneiro',
          },
          {
            'id_cidade': 2310951,
            'nomeCidade': 'Pires Ferreira',
          },
          {
            'id_cidade': 2311009,
            'nomeCidade': 'Poranga',
          },
          {
            'id_cidade': 2311108,
            'nomeCidade': 'Porteiras',
          },
          {
            'id_cidade': 2311207,
            'nomeCidade': 'Potengi',
          },
          {
            'id_cidade': 2311231,
            'nomeCidade': 'Potiretama',
          },
          {
            'id_cidade': 2311264,
            'nomeCidade': 'Quiterianópolis',
          },
          {
            'id_cidade': 2311306,
            'nomeCidade': 'Quixadá',
          },
          {
            'id_cidade': 2311355,
            'nomeCidade': 'Quixelô',
          },
          {
            'id_cidade': 2311405,
            'nomeCidade': 'Quixeramobim',
          },
          {
            'id_cidade': 2311504,
            'nomeCidade': 'Quixeré',
          },
          {
            'id_cidade': 2311603,
            'nomeCidade': 'Redenção',
          },
          {
            'id_cidade': 2311702,
            'nomeCidade': 'Reriutaba',
          },
          {
            'id_cidade': 2311801,
            'nomeCidade': 'Russas',
          },
          {
            'id_cidade': 2311900,
            'nomeCidade': 'Saboeiro',
          },
          {
            'id_cidade': 2311959,
            'nomeCidade': 'Salitre',
          },
          {
            'id_cidade': 2312205,
            'nomeCidade': 'Santa Quitéria',
          },
          {
            'id_cidade': 2312007,
            'nomeCidade': 'Santana do Acaraú',
          },
          {
            'id_cidade': 2312106,
            'nomeCidade': 'Santana do Cariri',
          },
          {
            'id_cidade': 2312304,
            'nomeCidade': 'São Benedito',
          },
          {
            'id_cidade': 2312403,
            'nomeCidade': 'São Gonçalo do Amarante',
          },
          {
            'id_cidade': 2312502,
            'nomeCidade': 'São João do Jaguaribe',
          },
          {
            'id_cidade': 2312601,
            'nomeCidade': 'São Luís do Curu',
          },
          {
            'id_cidade': 2312700,
            'nomeCidade': 'Senador Pompeu',
          },
          {
            'id_cidade': 2312809,
            'nomeCidade': 'Senador Sá',
          },
          {
            'id_cidade': 2312908,
            'nomeCidade': 'Sobral',
          },
          {
            'id_cidade': 2313005,
            'nomeCidade': 'Solonópole',
          },
          {
            'id_cidade': 2313104,
            'nomeCidade': 'Tabuleiro do Norte',
          },
          {
            'id_cidade': 2313203,
            'nomeCidade': 'Tamboril',
          },
          {
            'id_cidade': 2313252,
            'nomeCidade': 'Tarrafas',
          },
          {
            'id_cidade': 2313302,
            'nomeCidade': 'Tauá',
          },
          {
            'id_cidade': 2313351,
            'nomeCidade': 'Tejuçuoca',
          },
          {
            'id_cidade': 2313401,
            'nomeCidade': 'Tianguá',
          },
          {
            'id_cidade': 2313500,
            'nomeCidade': 'Trairi',
          },
          {
            'id_cidade': 2313559,
            'nomeCidade': 'Tururu',
          },
          {
            'id_cidade': 2313609,
            'nomeCidade': 'Ubajara',
          },
          {
            'id_cidade': 2313708,
            'nomeCidade': 'Umari',
          },
          {
            'id_cidade': 2313757,
            'nomeCidade': 'Umirim',
          },
          {
            'id_cidade': 2313807,
            'nomeCidade': 'Uruburetama',
          },
          {
            'id_cidade': 2313906,
            'nomeCidade': 'Uruoca',
          },
          {
            'id_cidade': 2313955,
            'nomeCidade': 'Varjota',
          },
          {
            'id_cidade': 2314003,
            'nomeCidade': 'Várzea Alegre',
          },
          {
            'id_cidade': 2314102,
            'nomeCidade': 'Viçosa do Ceará',
          },
        ],
      },
      {
        'id': 53,
        'nomeEstado': 'Distrito Federal',
        'sigla': 'DF',
        'cidades': [
          {
            'id_cidade': 5300108,
            'nomeCidade': 'Brasília',
          },
          {
            'id_cidade': 5300109,
            'nomeCidade': 'Águas Claras',
          },
          {
            'id_cidade': 5300110,
            'nomeCidade': 'Arniqueira',
          },
          {
            'id_cidade': 5300111,
            'nomeCidade': 'Brazlândia',
          },
          {
            'id_cidade': 5300112,
            'nomeCidade': 'Candangolândia',
          },
          {
            'id_cidade': 5300113,
            'nomeCidade': 'Ceilândia',
          },
          {
            'id_cidade': 5300114,
            'nomeCidade': 'Cruzeiro',
          },
          {
            'id_cidade': 5300115,
            'nomeCidade': 'Fercal',
          },
          {
            'id_cidade': 5300116,
            'nomeCidade': 'Gama',
          },
          {
            'id_cidade': 5300117,
            'nomeCidade': 'Guará',
          },
          {
            'id_cidade': 5300118,
            'nomeCidade': 'Itapoã',
          },
          {
            'id_cidade': 5300119,
            'nomeCidade': 'Jardim Botânico',
          },
          {
            'id_cidade': 5300120,
            'nomeCidade': 'Lago Norte',
          },
          {
            'id_cidade': 5300121,
            'nomeCidade': 'Lago Sul',
          },
          {
            'id_cidade': 5300122,
            'nomeCidade': 'Núcleo Bandeirante',
          },
          {
            'id_cidade': 5300123,
            'nomeCidade': 'Paranoá',
          },
          {
            'id_cidade': 5300124,
            'nomeCidade': 'Park Way',
          },
          {
            'id_cidade': 5300125,
            'nomeCidade': 'Planaltina',
          },
          {
            'id_cidade': 5300126,
            'nomeCidade': 'Plano Piloto',
          },
          {
            'id_cidade': 5300127,
            'nomeCidade': 'Recanto das Emas',
          },
          {
            'id_cidade': 5300128,
            'nomeCidade': 'Riacho Fundo',
          },
          {
            'id_cidade': 5300129,
            'nomeCidade': 'Riacho Fundo II',
          },
          {
            'id_cidade': 5300130,
            'nomeCidade': 'Samambaia',
          },
          {
            'id_cidade': 5300131,
            'nomeCidade': 'Santa Maria',
          },
          {
            'id_cidade': 5300132,
            'nomeCidade': 'São Sebastião',
          },
          {
            'id_cidade': 5300133,
            'nomeCidade': 'Estrutural',
          },
          {
            'id_cidade': 5300134,
            'nomeCidade': 'SIA',
          },
          {
            'id_cidade': 5300135,
            'nomeCidade': 'Sobradinho',
          },
          {
            'id_cidade': 5300136,
            'nomeCidade': 'Sobradinho II',
          },
          {
            'id_cidade': 5300137,
            'nomeCidade': 'Sol Nascente',
          },
          {
            'id_cidade': 5300138,
            'nomeCidade': 'Pôr do Sol',
          },
          {
            'id_cidade': 5300139,
            'nomeCidade': 'Sudoeste',
          },
          {
            'id_cidade': 5300140,
            'nomeCidade': 'Octogonal',
          },
          {
            'id_cidade': 5300141,
            'nomeCidade': 'Taguatinga',
          },
          {
            'id_cidade': 5300142,
            'nomeCidade': 'Varjão',
          },
          {
            'id_cidade': 5300143,
            'nomeCidade': 'Vicente Pires',
          },
        ],
      },
      {
        'id': 32,
        'nomeEstado': 'Espírito Santo',
        'sigla': 'ES',
        'cidades': [
          {
            'id_cidade': 3200102,
            'nomeCidade': 'Afonso Cláudio',
          },
          {
            'id_cidade': 3200169,
            'nomeCidade': 'Água Doce do Norte',
          },
          {
            'id_cidade': 3200136,
            'nomeCidade': 'Águia Branca',
          },
          {
            'id_cidade': 3200201,
            'nomeCidade': 'Alegre',
          },
          {
            'id_cidade': 3200300,
            'nomeCidade': 'Alfredo Chaves',
          },
          {
            'id_cidade': 3200359,
            'nomeCidade': 'Alto Rio Novo',
          },
          {
            'id_cidade': 3200409,
            'nomeCidade': 'Anchieta',
          },
          {
            'id_cidade': 3200508,
            'nomeCidade': 'Apiacá',
          },
          {
            'id_cidade': 3200607,
            'nomeCidade': 'Aracruz',
          },
          {
            'id_cidade': 3200706,
            'nomeCidade': 'Atílio Vivacqua',
          },
          {
            'id_cidade': 3200805,
            'nomeCidade': 'Baixo Guandu',
          },
          {
            'id_cidade': 3200904,
            'nomeCidade': 'Barra de São Francisco',
          },
          {
            'id_cidade': 3201001,
            'nomeCidade': 'Boa Esperança',
          },
          {
            'id_cidade': 3201100,
            'nomeCidade': 'Bom Jesus do Norte',
          },
          {
            'id_cidade': 3201159,
            'nomeCidade': 'Brejetuba',
          },
          {
            'id_cidade': 3201209,
            'nomeCidade': 'Cachoeiro de Itapemirim',
          },
          {
            'id_cidade': 3201308,
            'nomeCidade': 'Cariacica',
          },
          {
            'id_cidade': 3201407,
            'nomeCidade': 'Castelo',
          },
          {
            'id_cidade': 3201506,
            'nomeCidade': 'Colatina',
          },
          {
            'id_cidade': 3201605,
            'nomeCidade': 'Conceição da Barra',
          },
          {
            'id_cidade': 3201704,
            'nomeCidade': 'Conceição do Castelo',
          },
          {
            'id_cidade': 3201803,
            'nomeCidade': 'Divino de São Lourenço',
          },
          {
            'id_cidade': 3201902,
            'nomeCidade': 'Domingos Martins',
          },
          {
            'id_cidade': 3202009,
            'nomeCidade': 'Dores do Rio Preto',
          },
          {
            'id_cidade': 3202108,
            'nomeCidade': 'Ecoporanga',
          },
          {
            'id_cidade': 3202207,
            'nomeCidade': 'Fundão',
          },
          {
            'id_cidade': 3202256,
            'nomeCidade': 'Governador Lindenberg',
          },
          {
            'id_cidade': 3202306,
            'nomeCidade': 'Guaçuí',
          },
          {
            'id_cidade': 3202405,
            'nomeCidade': 'Guarapari',
          },
          {
            'id_cidade': 3202454,
            'nomeCidade': 'Ibatiba',
          },
          {
            'id_cidade': 3202504,
            'nomeCidade': 'Ibiraçu',
          },
          {
            'id_cidade': 3202553,
            'nomeCidade': 'Ibitirama',
          },
          {
            'id_cidade': 3202603,
            'nomeCidade': 'Iconha',
          },
          {
            'id_cidade': 3202652,
            'nomeCidade': 'Irupi',
          },
          {
            'id_cidade': 3202702,
            'nomeCidade': 'Itaguaçu',
          },
          {
            'id_cidade': 3202801,
            'nomeCidade': 'Itapemirim',
          },
          {
            'id_cidade': 3202900,
            'nomeCidade': 'Itarana',
          },
          {
            'id_cidade': 3203007,
            'nomeCidade': 'Iúna',
          },
          {
            'id_cidade': 3203056,
            'nomeCidade': 'Jaguaré',
          },
          {
            'id_cidade': 3203106,
            'nomeCidade': 'Jerônimo Monteiro',
          },
          {
            'id_cidade': 3203130,
            'nomeCidade': 'João Neiva',
          },
          {
            'id_cidade': 3203163,
            'nomeCidade': 'Laranja da Terra',
          },
          {
            'id_cidade': 3203205,
            'nomeCidade': 'Linhares',
          },
          {
            'id_cidade': 3203304,
            'nomeCidade': 'Mantenópolis',
          },
          {
            'id_cidade': 3203320,
            'nomeCidade': 'Marataízes',
          },
          {
            'id_cidade': 3203346,
            'nomeCidade': 'Marechal Floriano',
          },
          {
            'id_cidade': 3203353,
            'nomeCidade': 'Marilândia',
          },
          {
            'id_cidade': 3203403,
            'nomeCidade': 'Mimoso do Sul',
          },
          {
            'id_cidade': 3203502,
            'nomeCidade': 'Montanha',
          },
          {
            'id_cidade': 3203601,
            'nomeCidade': 'Mucurici',
          },
          {
            'id_cidade': 3203700,
            'nomeCidade': 'Muniz Freire',
          },
          {
            'id_cidade': 3203809,
            'nomeCidade': 'Muqui',
          },
          {
            'id_cidade': 3203908,
            'nomeCidade': 'Nova Venécia',
          },
          {
            'id_cidade': 3204005,
            'nomeCidade': 'Pancas',
          },
          {
            'id_cidade': 3204054,
            'nomeCidade': 'Pedro Canário',
          },
          {
            'id_cidade': 3204104,
            'nomeCidade': 'Pinheiros',
          },
          {
            'id_cidade': 3204203,
            'nomeCidade': 'Piúma',
          },
          {
            'id_cidade': 3204252,
            'nomeCidade': 'Ponto Belo',
          },
          {
            'id_cidade': 3204302,
            'nomeCidade': 'Presidente Kennedy',
          },
          {
            'id_cidade': 3204351,
            'nomeCidade': 'Rio Bananal',
          },
          {
            'id_cidade': 3204401,
            'nomeCidade': 'Rio Novo do Sul',
          },
          {
            'id_cidade': 3204500,
            'nomeCidade': 'Santa Leopoldina',
          },
          {
            'id_cidade': 3204559,
            'nomeCidade': 'Santa Maria de Jetibá',
          },
          {
            'id_cidade': 3204609,
            'nomeCidade': 'Santa Teresa',
          },
          {
            'id_cidade': 3204658,
            'nomeCidade': 'São Domingos do Norte',
          },
          {
            'id_cidade': 3204708,
            'nomeCidade': 'São Gabriel da Palha',
          },
          {
            'id_cidade': 3204807,
            'nomeCidade': 'São José do Calçado',
          },
          {
            'id_cidade': 3204906,
            'nomeCidade': 'São Mateus',
          },
          {
            'id_cidade': 3204955,
            'nomeCidade': 'São Roque do Canaã',
          },
          {
            'id_cidade': 3205002,
            'nomeCidade': 'Serra',
          },
          {
            'id_cidade': 3205010,
            'nomeCidade': 'Sooretama',
          },
          {
            'id_cidade': 3205036,
            'nomeCidade': 'Vargem Alta',
          },
          {
            'id_cidade': 3205069,
            'nomeCidade': 'Venda Nova do Imigrante',
          },
          {
            'id_cidade': 3205101,
            'nomeCidade': 'Viana',
          },
          {
            'id_cidade': 3205150,
            'nomeCidade': 'Vila Pavão',
          },
          {
            'id_cidade': 3205176,
            'nomeCidade': 'Vila Valério',
          },
          {
            'id_cidade': 3205200,
            'nomeCidade': 'Vila Velha',
          },
          {
            'id_cidade': 3205309,
            'nomeCidade': 'Vitória',
          },
        ],
      },
      {
        'id': 52,
        'nomeEstado': 'Goiás',
        'sigla': 'GO',
        'cidades': [
          {
            'id_cidade': 5200050,
            'nomeCidade': 'Abadia de Goiás',
          },
          {
            'id_cidade': 5200100,
            'nomeCidade': 'Abadiânia',
          },
          {
            'id_cidade': 5200134,
            'nomeCidade': 'Acreúna',
          },
          {
            'id_cidade': 5200159,
            'nomeCidade': 'Adelândia',
          },
          {
            'id_cidade': 5200175,
            'nomeCidade': 'Água Fria de Goiás',
          },
          {
            'id_cidade': 5200209,
            'nomeCidade': 'Água Limpa',
          },
          {
            'id_cidade': 5200258,
            'nomeCidade': 'Águas Lindas de Goiás',
          },
          {
            'id_cidade': 5200308,
            'nomeCidade': 'Alexânia',
          },
          {
            'id_cidade': 5200506,
            'nomeCidade': 'Aloândia',
          },
          {
            'id_cidade': 5200555,
            'nomeCidade': 'Alto Horizonte',
          },
          {
            'id_cidade': 5200605,
            'nomeCidade': 'Alto Paraíso de Goiás',
          },
          {
            'id_cidade': 5200803,
            'nomeCidade': 'Alvorada do Norte',
          },
          {
            'id_cidade': 5200829,
            'nomeCidade': 'Amaralina',
          },
          {
            'id_cidade': 5200852,
            'nomeCidade': 'Americano do Brasil',
          },
          {
            'id_cidade': 5200902,
            'nomeCidade': 'Amorinópolis',
          },
          {
            'id_cidade': 5201108,
            'nomeCidade': 'Anápolis',
          },
          {
            'id_cidade': 5201207,
            'nomeCidade': 'Anhanguera',
          },
          {
            'id_cidade': 5201306,
            'nomeCidade': 'Anicuns',
          },
          {
            'id_cidade': 5201405,
            'nomeCidade': 'Aparecida de Goiânia',
          },
          {
            'id_cidade': 5201454,
            'nomeCidade': 'Aparecida do Rio Doce',
          },
          {
            'id_cidade': 5201504,
            'nomeCidade': 'Aporé',
          },
          {
            'id_cidade': 5201603,
            'nomeCidade': 'Araçu',
          },
          {
            'id_cidade': 5201702,
            'nomeCidade': 'Aragarças',
          },
          {
            'id_cidade': 5201801,
            'nomeCidade': 'Aragoiânia',
          },
          {
            'id_cidade': 5202155,
            'nomeCidade': 'Araguapaz',
          },
          {
            'id_cidade': 5202353,
            'nomeCidade': 'Arenópolis',
          },
          {
            'id_cidade': 5202502,
            'nomeCidade': 'Aruanã',
          },
          {
            'id_cidade': 5202601,
            'nomeCidade': 'Aurilândia',
          },
          {
            'id_cidade': 5202809,
            'nomeCidade': 'Avelinópolis',
          },
          {
            'id_cidade': 5203104,
            'nomeCidade': 'Baliza',
          },
          {
            'id_cidade': 5203203,
            'nomeCidade': 'Barro Alto',
          },
          {
            'id_cidade': 5203302,
            'nomeCidade': 'Bela Vista de Goiás',
          },
          {
            'id_cidade': 5203401,
            'nomeCidade': 'Bom Jardim de Goiás',
          },
          {
            'id_cidade': 5203500,
            'nomeCidade': 'Bom Jesus de Goiás',
          },
          {
            'id_cidade': 5203559,
            'nomeCidade': 'Bonfinópolis',
          },
          {
            'id_cidade': 5203575,
            'nomeCidade': 'Bonópolis',
          },
          {
            'id_cidade': 5203609,
            'nomeCidade': 'Brazabrantes',
          },
          {
            'id_cidade': 5203807,
            'nomeCidade': 'Britânia',
          },
          {
            'id_cidade': 5203906,
            'nomeCidade': 'Buriti Alegre',
          },
          {
            'id_cidade': 5203939,
            'nomeCidade': 'Buriti de Goiás',
          },
          {
            'id_cidade': 5203962,
            'nomeCidade': 'Buritinópolis',
          },
          {
            'id_cidade': 5204003,
            'nomeCidade': 'Cabeceiras',
          },
          {
            'id_cidade': 5204102,
            'nomeCidade': 'Cachoeira Alta',
          },
          {
            'id_cidade': 5204201,
            'nomeCidade': 'Cachoeira de Goiás',
          },
          {
            'id_cidade': 5204250,
            'nomeCidade': 'Cachoeira Dourada',
          },
          {
            'id_cidade': 5204300,
            'nomeCidade': 'Caçu',
          },
          {
            'id_cidade': 5204409,
            'nomeCidade': 'Caiapônia',
          },
          {
            'id_cidade': 5204508,
            'nomeCidade': 'Caldas Novas',
          },
          {
            'id_cidade': 5204557,
            'nomeCidade': 'Caldazinha',
          },
          {
            'id_cidade': 5204607,
            'nomeCidade': 'Campestre de Goiás',
          },
          {
            'id_cidade': 5204656,
            'nomeCidade': 'Campinaçu',
          },
          {
            'id_cidade': 5204706,
            'nomeCidade': 'Campinorte',
          },
          {
            'id_cidade': 5204805,
            'nomeCidade': 'Campo Alegre de Goiás',
          },
          {
            'id_cidade': 5204854,
            'nomeCidade': 'Campo Limpo de Goiás',
          },
          {
            'id_cidade': 5204904,
            'nomeCidade': 'Campos Belos',
          },
          {
            'id_cidade': 5204953,
            'nomeCidade': 'Campos Verdes',
          },
          {
            'id_cidade': 5205000,
            'nomeCidade': 'Carmo do Rio Verde',
          },
          {
            'id_cidade': 5205059,
            'nomeCidade': 'Castelândia',
          },
          {
            'id_cidade': 5205109,
            'nomeCidade': 'Catalão',
          },
          {
            'id_cidade': 5205208,
            'nomeCidade': 'Caturaí',
          },
          {
            'id_cidade': 5205307,
            'nomeCidade': 'Cavalcante',
          },
          {
            'id_cidade': 5205406,
            'nomeCidade': 'Ceres',
          },
          {
            'id_cidade': 5205455,
            'nomeCidade': 'Cezarina',
          },
          {
            'id_cidade': 5205471,
            'nomeCidade': 'Chapadão do Céu',
          },
          {
            'id_cidade': 5205497,
            'nomeCidade': 'Cidade Ocidental',
          },
          {
            'id_cidade': 5205513,
            'nomeCidade': 'Cocalzinho de Goiás',
          },
          {
            'id_cidade': 5205521,
            'nomeCidade': 'Colinas do Sul',
          },
          {
            'id_cidade': 5205703,
            'nomeCidade': 'Córrego do Ouro',
          },
          {
            'id_cidade': 5205802,
            'nomeCidade': 'Corumbá de Goiás',
          },
          {
            'id_cidade': 5205901,
            'nomeCidade': 'Corumbaíba',
          },
          {
            'id_cidade': 5206206,
            'nomeCidade': 'Cristalina',
          },
          {
            'id_cidade': 5206305,
            'nomeCidade': 'Cristianópolis',
          },
          {
            'id_cidade': 5206404,
            'nomeCidade': 'Crixás',
          },
          {
            'id_cidade': 5206503,
            'nomeCidade': 'Cromínia',
          },
          {
            'id_cidade': 5206602,
            'nomeCidade': 'Cumari',
          },
          {
            'id_cidade': 5206701,
            'nomeCidade': 'Damianópolis',
          },
          {
            'id_cidade': 5206800,
            'nomeCidade': 'Damolândia',
          },
          {
            'id_cidade': 5206909,
            'nomeCidade': 'Davinópolis',
          },
          {
            'id_cidade': 5207105,
            'nomeCidade': 'Diorama',
          },
          {
            'id_cidade': 5208301,
            'nomeCidade': 'Divinópolis de Goiás',
          },
          {
            'id_cidade': 5207253,
            'nomeCidade': 'Doverlândia',
          },
          {
            'id_cidade': 5207352,
            'nomeCidade': 'Edealina',
          },
          {
            'id_cidade': 5207402,
            'nomeCidade': 'Edéia',
          },
          {
            'id_cidade': 5207501,
            'nomeCidade': 'Estrela do Norte',
          },
          {
            'id_cidade': 5207535,
            'nomeCidade': 'Faina',
          },
          {
            'id_cidade': 5207600,
            'nomeCidade': 'Fazenda Nova',
          },
          {
            'id_cidade': 5207808,
            'nomeCidade': 'Firminópolis',
          },
          {
            'id_cidade': 5207907,
            'nomeCidade': 'Flores de Goiás',
          },
          {
            'id_cidade': 5208004,
            'nomeCidade': 'Formosa',
          },
          {
            'id_cidade': 5208103,
            'nomeCidade': 'Formoso',
          },
          {
            'id_cidade': 5208152,
            'nomeCidade': 'Gameleira de Goiás',
          },
          {
            'id_cidade': 5208400,
            'nomeCidade': 'Goianápolis',
          },
          {
            'id_cidade': 5208509,
            'nomeCidade': 'Goiandira',
          },
          {
            'id_cidade': 5208608,
            'nomeCidade': 'Goianésia',
          },
          {
            'id_cidade': 5208707,
            'nomeCidade': 'Goiânia',
          },
          {
            'id_cidade': 5208806,
            'nomeCidade': 'Goianira',
          },
          {
            'id_cidade': 5208905,
            'nomeCidade': 'Goiás',
          },
          {
            'id_cidade': 5209101,
            'nomeCidade': 'Goiatuba',
          },
          {
            'id_cidade': 5209150,
            'nomeCidade': 'Gouvelândia',
          },
          {
            'id_cidade': 5209200,
            'nomeCidade': 'Guapó',
          },
          {
            'id_cidade': 5209291,
            'nomeCidade': 'Guaraíta',
          },
          {
            'id_cidade': 5209408,
            'nomeCidade': 'Guarani de Goiás',
          },
          {
            'id_cidade': 5209457,
            'nomeCidade': 'Guarinos',
          },
          {
            'id_cidade': 5209606,
            'nomeCidade': 'Heitoraí',
          },
          {
            'id_cidade': 5209705,
            'nomeCidade': 'Hidrolândia',
          },
          {
            'id_cidade': 5209804,
            'nomeCidade': 'Hidrolina',
          },
          {
            'id_cidade': 5209903,
            'nomeCidade': 'Iaciara',
          },
          {
            'id_cidade': 5209937,
            'nomeCidade': 'Inaciolândia',
          },
          {
            'id_cidade': 5209952,
            'nomeCidade': 'Indiara',
          },
          {
            'id_cidade': 5210000,
            'nomeCidade': 'Inhumas',
          },
          {
            'id_cidade': 5210109,
            'nomeCidade': 'Ipameri',
          },
          {
            'id_cidade': 5210158,
            'nomeCidade': 'Ipiranga de Goiás',
          },
          {
            'id_cidade': 5210208,
            'nomeCidade': 'Iporá',
          },
          {
            'id_cidade': 5210307,
            'nomeCidade': 'Israelândia',
          },
          {
            'id_cidade': 5210406,
            'nomeCidade': 'Itaberaí',
          },
          {
            'id_cidade': 5210562,
            'nomeCidade': 'Itaguari',
          },
          {
            'id_cidade': 5210604,
            'nomeCidade': 'Itaguaru',
          },
          {
            'id_cidade': 5210802,
            'nomeCidade': 'Itajá',
          },
          {
            'id_cidade': 5210901,
            'nomeCidade': 'Itapaci',
          },
          {
            'id_cidade': 5211008,
            'nomeCidade': 'Itapirapuã',
          },
          {
            'id_cidade': 5211206,
            'nomeCidade': 'Itapuranga',
          },
          {
            'id_cidade': 5211305,
            'nomeCidade': 'Itarumã',
          },
          {
            'id_cidade': 5211404,
            'nomeCidade': 'Itauçu',
          },
          {
            'id_cidade': 5211503,
            'nomeCidade': 'Itumbiara',
          },
          {
            'id_cidade': 5211602,
            'nomeCidade': 'Ivolândia',
          },
          {
            'id_cidade': 5211701,
            'nomeCidade': 'Jandaia',
          },
          {
            'id_cidade': 5211800,
            'nomeCidade': 'Jaraguá',
          },
          {
            'id_cidade': 5211909,
            'nomeCidade': 'Jataí',
          },
          {
            'id_cidade': 5212006,
            'nomeCidade': 'Jaupaci',
          },
          {
            'id_cidade': 5212055,
            'nomeCidade': 'Jesúpolis',
          },
          {
            'id_cidade': 5212105,
            'nomeCidade': 'Joviânia',
          },
          {
            'id_cidade': 5212204,
            'nomeCidade': 'Jussara',
          },
          {
            'id_cidade': 5212253,
            'nomeCidade': 'Lagoa Santa',
          },
          {
            'id_cidade': 5212303,
            'nomeCidade': 'Leopoldo de Bulhões',
          },
          {
            'id_cidade': 5212501,
            'nomeCidade': 'Luziânia',
          },
          {
            'id_cidade': 5212600,
            'nomeCidade': 'Mairipotaba',
          },
          {
            'id_cidade': 5212709,
            'nomeCidade': 'Mambaí',
          },
          {
            'id_cidade': 5212808,
            'nomeCidade': 'Mara Rosa',
          },
          {
            'id_cidade': 5212907,
            'nomeCidade': 'Marzagão',
          },
          {
            'id_cidade': 5212956,
            'nomeCidade': 'Matrinchã',
          },
          {
            'id_cidade': 5213004,
            'nomeCidade': 'Maurilândia',
          },
          {
            'id_cidade': 5213053,
            'nomeCidade': 'Mimoso de Goiás',
          },
          {
            'id_cidade': 5213087,
            'nomeCidade': 'Minaçu',
          },
          {
            'id_cidade': 5213103,
            'nomeCidade': 'Mineiros',
          },
          {
            'id_cidade': 5213400,
            'nomeCidade': 'Moiporá',
          },
          {
            'id_cidade': 5213509,
            'nomeCidade': 'Monte Alegre de Goiás',
          },
          {
            'id_cidade': 5213707,
            'nomeCidade': 'Montes Claros de Goiás',
          },
          {
            'id_cidade': 5213756,
            'nomeCidade': 'Montividiu',
          },
          {
            'id_cidade': 5213772,
            'nomeCidade': 'Montividiu do Norte',
          },
          {
            'id_cidade': 5213806,
            'nomeCidade': 'Morrinhos',
          },
          {
            'id_cidade': 5213855,
            'nomeCidade': 'Morro Agudo de Goiás',
          },
          {
            'id_cidade': 5213905,
            'nomeCidade': 'Mossâmedes',
          },
          {
            'id_cidade': 5214002,
            'nomeCidade': 'Mozarlândia',
          },
          {
            'id_cidade': 5214051,
            'nomeCidade': 'Mundo Novo',
          },
          {
            'id_cidade': 5214101,
            'nomeCidade': 'Mutunópolis',
          },
          {
            'id_cidade': 5214408,
            'nomeCidade': 'Nazário',
          },
          {
            'id_cidade': 5214507,
            'nomeCidade': 'Nerópolis',
          },
          {
            'id_cidade': 5214606,
            'nomeCidade': 'Niquelândia',
          },
          {
            'id_cidade': 5214705,
            'nomeCidade': 'Nova América',
          },
          {
            'id_cidade': 5214804,
            'nomeCidade': 'Nova Aurora',
          },
          {
            'id_cidade': 5214838,
            'nomeCidade': 'Nova Crixás',
          },
          {
            'id_cidade': 5214861,
            'nomeCidade': 'Nova Glória',
          },
          {
            'id_cidade': 5214879,
            'nomeCidade': 'Nova Iguaçu de Goiás',
          },
          {
            'id_cidade': 5214903,
            'nomeCidade': 'Nova Roma',
          },
          {
            'id_cidade': 5215009,
            'nomeCidade': 'Nova Veneza',
          },
          {
            'id_cidade': 5215207,
            'nomeCidade': 'Novo Brasil',
          },
          {
            'id_cidade': 5215231,
            'nomeCidade': 'Novo Gama',
          },
          {
            'id_cidade': 5215256,
            'nomeCidade': 'Novo Planalto',
          },
          {
            'id_cidade': 5215306,
            'nomeCidade': 'Orizona',
          },
          {
            'id_cidade': 5215405,
            'nomeCidade': 'Ouro Verde de Goiás',
          },
          {
            'id_cidade': 5215504,
            'nomeCidade': 'Ouvidor',
          },
          {
            'id_cidade': 5215603,
            'nomeCidade': 'Padre Bernardo',
          },
          {
            'id_cidade': 5215652,
            'nomeCidade': 'Palestina de Goiás',
          },
          {
            'id_cidade': 5215702,
            'nomeCidade': 'Palmeiras de Goiás',
          },
          {
            'id_cidade': 5215801,
            'nomeCidade': 'Palmelo',
          },
          {
            'id_cidade': 5215900,
            'nomeCidade': 'Palminópolis',
          },
          {
            'id_cidade': 5216007,
            'nomeCidade': 'Panamá',
          },
          {
            'id_cidade': 5216304,
            'nomeCidade': 'Paranaiguara',
          },
          {
            'id_cidade': 5216403,
            'nomeCidade': 'Paraúna',
          },
          {
            'id_cidade': 5216452,
            'nomeCidade': 'Perolândia',
          },
          {
            'id_cidade': 5216809,
            'nomeCidade': 'Petrolina de Goiás',
          },
          {
            'id_cidade': 5216908,
            'nomeCidade': 'Pilar de Goiás',
          },
          {
            'id_cidade': 5217104,
            'nomeCidade': 'Piracanjuba',
          },
          {
            'id_cidade': 5217203,
            'nomeCidade': 'Piranhas',
          },
          {
            'id_cidade': 5217302,
            'nomeCidade': 'Pirenópolis',
          },
          {
            'id_cidade': 5217401,
            'nomeCidade': 'Pires do Rio',
          },
          {
            'id_cidade': 5217609,
            'nomeCidade': 'Planaltina',
          },
          {
            'id_cidade': 5217708,
            'nomeCidade': 'Pontalina',
          },
          {
            'id_cidade': 5218003,
            'nomeCidade': 'Porangatu',
          },
          {
            'id_cidade': 5218052,
            'nomeCidade': 'Porteirão',
          },
          {
            'id_cidade': 5218102,
            'nomeCidade': 'Portelândia',
          },
          {
            'id_cidade': 5218300,
            'nomeCidade': 'Posse',
          },
          {
            'id_cidade': 5218391,
            'nomeCidade': 'Professor Jamil',
          },
          {
            'id_cidade': 5218508,
            'nomeCidade': 'Quirinópolis',
          },
          {
            'id_cidade': 5218607,
            'nomeCidade': 'Rialma',
          },
          {
            'id_cidade': 5218706,
            'nomeCidade': 'Rianápolis',
          },
          {
            'id_cidade': 5218789,
            'nomeCidade': 'Rio Quente',
          },
          {
            'id_cidade': 5218805,
            'nomeCidade': 'Rio Verde',
          },
          {
            'id_cidade': 5218904,
            'nomeCidade': 'Rubiataba',
          },
          {
            'id_cidade': 5219001,
            'nomeCidade': 'Sanclerlândia',
          },
          {
            'id_cidade': 5219100,
            'nomeCidade': 'Santa Bárbara de Goiás',
          },
          {
            'id_cidade': 5219209,
            'nomeCidade': 'Santa Cruz de Goiás',
          },
          {
            'id_cidade': 5219258,
            'nomeCidade': 'Santa Fé de Goiás',
          },
          {
            'id_cidade': 5219308,
            'nomeCidade': 'Santa Helena de Goiás',
          },
          {
            'id_cidade': 5219357,
            'nomeCidade': 'Santa Isabel',
          },
          {
            'id_cidade': 5219407,
            'nomeCidade': 'Santa Rita do Araguaia',
          },
          {
            'id_cidade': 5219456,
            'nomeCidade': 'Santa Rita do Novo Destino',
          },
          {
            'id_cidade': 5219506,
            'nomeCidade': 'Santa Rosa de Goiás',
          },
          {
            'id_cidade': 5219605,
            'nomeCidade': 'Santa Tereza de Goiás',
          },
          {
            'id_cidade': 5219704,
            'nomeCidade': 'Santa Terezinha de Goiás',
          },
          {
            'id_cidade': 5219712,
            'nomeCidade': 'Santo Antônio da Barra',
          },
          {
            'id_cidade': 5219738,
            'nomeCidade': 'Santo Antônio de Goiás',
          },
          {
            'id_cidade': 5219753,
            'nomeCidade': 'Santo Antônio do Descoberto',
          },
          {
            'id_cidade': 5219803,
            'nomeCidade': 'São Domingos',
          },
          {
            'id_cidade': 5219902,
            'nomeCidade': 'São Francisco de Goiás',
          },
          {
            'id_cidade': 5220058,
            'nomeCidade': 'São João da Paraúna',
          },
          {
            'id_cidade': 5220009,
            'nomeCidade': "São João d'Aliança",
          },
          {
            'id_cidade': 5220108,
            'nomeCidade': 'São Luís de Montes Belos',
          },
          {
            'id_cidade': 5220157,
            'nomeCidade': 'São Luiz do Norte',
          },
          {
            'id_cidade': 5220207,
            'nomeCidade': 'São Miguel do Araguaia',
          },
          {
            'id_cidade': 5220264,
            'nomeCidade': 'São Miguel do Passa Quatro',
          },
          {
            'id_cidade': 5220280,
            'nomeCidade': 'São Patrício',
          },
          {
            'id_cidade': 5220405,
            'nomeCidade': 'São Simão',
          },
          {
            'id_cidade': 5220454,
            'nomeCidade': 'Senador Canedo',
          },
          {
            'id_cidade': 5220504,
            'nomeCidade': 'Serranópolis',
          },
          {
            'id_cidade': 5220603,
            'nomeCidade': 'Silvânia',
          },
          {
            'id_cidade': 5220686,
            'nomeCidade': 'Simolândia',
          },
          {
            'id_cidade': 5220702,
            'nomeCidade': "Sítio d'Abadia",
          },
          {
            'id_cidade': 5221007,
            'nomeCidade': 'Taquaral de Goiás',
          },
          {
            'id_cidade': 5221080,
            'nomeCidade': 'Teresina de Goiás',
          },
          {
            'id_cidade': 5221197,
            'nomeCidade': 'Terezópolis de Goiás',
          },
          {
            'id_cidade': 5221304,
            'nomeCidade': 'Três Ranchos',
          },
          {
            'id_cidade': 5221403,
            'nomeCidade': 'Trindade',
          },
          {
            'id_cidade': 5221452,
            'nomeCidade': 'Trombas',
          },
          {
            'id_cidade': 5221502,
            'nomeCidade': 'Turvânia',
          },
          {
            'id_cidade': 5221551,
            'nomeCidade': 'Turvelândia',
          },
          {
            'id_cidade': 5221577,
            'nomeCidade': 'Uirapuru',
          },
          {
            'id_cidade': 5221601,
            'nomeCidade': 'Uruaçu',
          },
          {
            'id_cidade': 5221700,
            'nomeCidade': 'Uruana',
          },
          {
            'id_cidade': 5221809,
            'nomeCidade': 'Urutaí',
          },
          {
            'id_cidade': 5221858,
            'nomeCidade': 'Valparaíso de Goiás',
          },
          {
            'id_cidade': 5221908,
            'nomeCidade': 'Varjão',
          },
          {
            'id_cidade': 5222005,
            'nomeCidade': 'Vianópolis',
          },
          {
            'id_cidade': 5222054,
            'nomeCidade': 'Vicentinópolis',
          },
          {
            'id_cidade': 5222203,
            'nomeCidade': 'Vila Boa',
          },
          {
            'id_cidade': 5222302,
            'nomeCidade': 'Vila Propício',
          },
        ],
      },
      {
        'id': 21,
        'nomeEstado': 'Maranhão',
        'sigla': 'MA',
        'cidades': [
          {
            'id_cidade': 2100055,
            'nomeCidade': 'Açailândia',
          },
          {
            'id_cidade': 2100105,
            'nomeCidade': 'Afonso Cunha',
          },
          {
            'id_cidade': 2100154,
            'nomeCidade': 'Água Doce do Maranhão',
          },
          {
            'id_cidade': 2100204,
            'nomeCidade': 'Alcântara',
          },
          {
            'id_cidade': 2100303,
            'nomeCidade': 'Aldeias Altas',
          },
          {
            'id_cidade': 2100402,
            'nomeCidade': 'Altamira do Maranhão',
          },
          {
            'id_cidade': 2100436,
            'nomeCidade': 'Alto Alegre do Maranhão',
          },
          {
            'id_cidade': 2100477,
            'nomeCidade': 'Alto Alegre do Pindaré',
          },
          {
            'id_cidade': 2100501,
            'nomeCidade': 'Alto Parnaíba',
          },
          {
            'id_cidade': 2100550,
            'nomeCidade': 'Amapá do Maranhão',
          },
          {
            'id_cidade': 2100600,
            'nomeCidade': 'Amarante do Maranhão',
          },
          {
            'id_cidade': 2100709,
            'nomeCidade': 'Anajatuba',
          },
          {
            'id_cidade': 2100808,
            'nomeCidade': 'Anapurus',
          },
          {
            'id_cidade': 2100832,
            'nomeCidade': 'Apicum-Açu',
          },
          {
            'id_cidade': 2100873,
            'nomeCidade': 'Araguanã',
          },
          {
            'id_cidade': 2100907,
            'nomeCidade': 'Araioses',
          },
          {
            'id_cidade': 2100956,
            'nomeCidade': 'Arame',
          },
          {
            'id_cidade': 2101004,
            'nomeCidade': 'Arari',
          },
          {
            'id_cidade': 2101103,
            'nomeCidade': 'Axixá',
          },
          {
            'id_cidade': 2101202,
            'nomeCidade': 'Bacabal',
          },
          {
            'id_cidade': 2101251,
            'nomeCidade': 'Bacabeira',
          },
          {
            'id_cidade': 2101301,
            'nomeCidade': 'Bacuri',
          },
          {
            'id_cidade': 2101350,
            'nomeCidade': 'Bacurituba',
          },
          {
            'id_cidade': 2101400,
            'nomeCidade': 'Balsas',
          },
          {
            'id_cidade': 2101509,
            'nomeCidade': 'Barão de Grajaú',
          },
          {
            'id_cidade': 2101608,
            'nomeCidade': 'Barra do Corda',
          },
          {
            'id_cidade': 2101707,
            'nomeCidade': 'Barreirinhas',
          },
          {
            'id_cidade': 2101772,
            'nomeCidade': 'Bela Vista do Maranhão',
          },
          {
            'id_cidade': 2101731,
            'nomeCidade': 'Belágua',
          },
          {
            'id_cidade': 2101806,
            'nomeCidade': 'Benedito Leite',
          },
          {
            'id_cidade': 2101905,
            'nomeCidade': 'Bequimão',
          },
          {
            'id_cidade': 2101939,
            'nomeCidade': 'Bernardo do Mearim',
          },
          {
            'id_cidade': 2101970,
            'nomeCidade': 'Boa Vista do Gurupi',
          },
          {
            'id_cidade': 2102002,
            'nomeCidade': 'Bom Jardim',
          },
          {
            'id_cidade': 2102036,
            'nomeCidade': 'Bom Jesus das Selvas',
          },
          {
            'id_cidade': 2102077,
            'nomeCidade': 'Bom Lugar',
          },
          {
            'id_cidade': 2102101,
            'nomeCidade': 'Brejo',
          },
          {
            'id_cidade': 2102150,
            'nomeCidade': 'Brejo de Areia',
          },
          {
            'id_cidade': 2102200,
            'nomeCidade': 'Buriti',
          },
          {
            'id_cidade': 2102309,
            'nomeCidade': 'Buriti Bravo',
          },
          {
            'id_cidade': 2102325,
            'nomeCidade': 'Buriticupu',
          },
          {
            'id_cidade': 2102358,
            'nomeCidade': 'Buritirana',
          },
          {
            'id_cidade': 2102374,
            'nomeCidade': 'Cachoeira Grande',
          },
          {
            'id_cidade': 2102408,
            'nomeCidade': 'Cajapió',
          },
          {
            'id_cidade': 2102507,
            'nomeCidade': 'Cajari',
          },
          {
            'id_cidade': 2102556,
            'nomeCidade': 'Campestre do Maranhão',
          },
          {
            'id_cidade': 2102606,
            'nomeCidade': 'Cândido Mendes',
          },
          {
            'id_cidade': 2102705,
            'nomeCidade': 'Cantanhede',
          },
          {
            'id_cidade': 2102754,
            'nomeCidade': 'Capinzal do Norte',
          },
          {
            'id_cidade': 2102804,
            'nomeCidade': 'Carolina',
          },
          {
            'id_cidade': 2102903,
            'nomeCidade': 'Carutapera',
          },
          {
            'id_cidade': 2103000,
            'nomeCidade': 'Caxias',
          },
          {
            'id_cidade': 2103109,
            'nomeCidade': 'Cedral',
          },
          {
            'id_cidade': 2103125,
            'nomeCidade': 'Central do Maranhão',
          },
          {
            'id_cidade': 2103158,
            'nomeCidade': 'Centro do Guilherme',
          },
          {
            'id_cidade': 2103174,
            'nomeCidade': 'Centro Novo do Maranhão',
          },
          {
            'id_cidade': 2103208,
            'nomeCidade': 'Chapadinha',
          },
          {
            'id_cidade': 2103257,
            'nomeCidade': 'Cidelândia',
          },
          {
            'id_cidade': 2103307,
            'nomeCidade': 'Codó',
          },
          {
            'id_cidade': 2103406,
            'nomeCidade': 'Coelho Neto',
          },
          {
            'id_cidade': 2103505,
            'nomeCidade': 'Colinas',
          },
          {
            'id_cidade': 2103554,
            'nomeCidade': 'Conceição do Lago-Açu',
          },
          {
            'id_cidade': 2103604,
            'nomeCidade': 'Coroatá',
          },
          {
            'id_cidade': 2103703,
            'nomeCidade': 'Cururupu',
          },
          {
            'id_cidade': 2103752,
            'nomeCidade': 'Davinópolis',
          },
          {
            'id_cidade': 2103802,
            'nomeCidade': 'Dom Pedro',
          },
          {
            'id_cidade': 2103901,
            'nomeCidade': 'Duque Bacelar',
          },
          {
            'id_cidade': 2104008,
            'nomeCidade': 'Esperantinópolis',
          },
          {
            'id_cidade': 2104057,
            'nomeCidade': 'Estreito',
          },
          {
            'id_cidade': 2104073,
            'nomeCidade': 'Feira Nova do Maranhão',
          },
          {
            'id_cidade': 2104081,
            'nomeCidade': 'Fernando Falcão',
          },
          {
            'id_cidade': 2104099,
            'nomeCidade': 'Formosa da Serra Negra',
          },
          {
            'id_cidade': 2104107,
            'nomeCidade': 'Fortaleza dos Nogueiras',
          },
          {
            'id_cidade': 2104206,
            'nomeCidade': 'Fortuna',
          },
          {
            'id_cidade': 2104305,
            'nomeCidade': 'Godofredo Viana',
          },
          {
            'id_cidade': 2104404,
            'nomeCidade': 'Gonçalves Dias',
          },
          {
            'id_cidade': 2104503,
            'nomeCidade': 'Governador Archer',
          },
          {
            'id_cidade': 2104552,
            'nomeCidade': 'Governador Edison Lobão',
          },
          {
            'id_cidade': 2104602,
            'nomeCidade': 'Governador Eugênio Barros',
          },
          {
            'id_cidade': 2104628,
            'nomeCidade': 'Governador Luiz Rocha',
          },
          {
            'id_cidade': 2104651,
            'nomeCidade': 'Governador Newton Bello',
          },
          {
            'id_cidade': 2104677,
            'nomeCidade': 'Governador Nunes Freire',
          },
          {
            'id_cidade': 2104701,
            'nomeCidade': 'Graça Aranha',
          },
          {
            'id_cidade': 2104800,
            'nomeCidade': 'Grajaú',
          },
          {
            'id_cidade': 2104909,
            'nomeCidade': 'Guimarães',
          },
          {
            'id_cidade': 2105005,
            'nomeCidade': 'Humberto de Campos',
          },
          {
            'id_cidade': 2105104,
            'nomeCidade': 'Icatu',
          },
          {
            'id_cidade': 2105153,
            'nomeCidade': 'Igarapé do Meio',
          },
          {
            'id_cidade': 2105203,
            'nomeCidade': 'Igarapé Grande',
          },
          {
            'id_cidade': 2105302,
            'nomeCidade': 'Imperatriz',
          },
          {
            'id_cidade': 2105351,
            'nomeCidade': 'Itaipava do Grajaú',
          },
          {
            'id_cidade': 2105401,
            'nomeCidade': 'Itapecuru Mirim',
          },
          {
            'id_cidade': 2105427,
            'nomeCidade': 'Itinga do Maranhão',
          },
          {
            'id_cidade': 2105450,
            'nomeCidade': 'Jatobá',
          },
          {
            'id_cidade': 2105476,
            'nomeCidade': 'Jenipapo dos Vieiras',
          },
          {
            'id_cidade': 2105500,
            'nomeCidade': 'João Lisboa',
          },
          {
            'id_cidade': 2105609,
            'nomeCidade': 'Joselândia',
          },
          {
            'id_cidade': 2105658,
            'nomeCidade': 'Junco do Maranhão',
          },
          {
            'id_cidade': 2105708,
            'nomeCidade': 'Lago da Pedra',
          },
          {
            'id_cidade': 2105807,
            'nomeCidade': 'Lago do Junco',
          },
          {
            'id_cidade': 2105948,
            'nomeCidade': 'Lago dos Rodrigues',
          },
          {
            'id_cidade': 2105906,
            'nomeCidade': 'Lago Verde',
          },
          {
            'id_cidade': 2105922,
            'nomeCidade': 'Lagoa do Mato',
          },
          {
            'id_cidade': 2105963,
            'nomeCidade': 'Lagoa Grande do Maranhão',
          },
          {
            'id_cidade': 2105989,
            'nomeCidade': 'Lajeado Novo',
          },
          {
            'id_cidade': 2106003,
            'nomeCidade': 'Lima Campos',
          },
          {
            'id_cidade': 2106102,
            'nomeCidade': 'Loreto',
          },
          {
            'id_cidade': 2106201,
            'nomeCidade': 'Luís Domingues',
          },
          {
            'id_cidade': 2106300,
            'nomeCidade': 'Magalhães de Almeida',
          },
          {
            'id_cidade': 2106326,
            'nomeCidade': 'Maracaçumé',
          },
          {
            'id_cidade': 2106359,
            'nomeCidade': 'Marajá do Sena',
          },
          {
            'id_cidade': 2106375,
            'nomeCidade': 'Maranhãozinho',
          },
          {
            'id_cidade': 2106409,
            'nomeCidade': 'Mata Roma',
          },
          {
            'id_cidade': 2106508,
            'nomeCidade': 'Matinha',
          },
          {
            'id_cidade': 2106607,
            'nomeCidade': 'Matões',
          },
          {
            'id_cidade': 2106631,
            'nomeCidade': 'Matões do Norte',
          },
          {
            'id_cidade': 2106672,
            'nomeCidade': 'Milagres do Maranhão',
          },
          {
            'id_cidade': 2106706,
            'nomeCidade': 'Mirador',
          },
          {
            'id_cidade': 2106755,
            'nomeCidade': 'Miranda do Norte',
          },
          {
            'id_cidade': 2106805,
            'nomeCidade': 'Mirinzal',
          },
          {
            'id_cidade': 2106904,
            'nomeCidade': 'Monção',
          },
          {
            'id_cidade': 2107001,
            'nomeCidade': 'Montes Altos',
          },
          {
            'id_cidade': 2107100,
            'nomeCidade': 'Morros',
          },
          {
            'id_cidade': 2107209,
            'nomeCidade': 'Nina Rodrigues',
          },
          {
            'id_cidade': 2107258,
            'nomeCidade': 'Nova Colinas',
          },
          {
            'id_cidade': 2107308,
            'nomeCidade': 'Nova Iorque',
          },
          {
            'id_cidade': 2107357,
            'nomeCidade': 'Nova Olinda do Maranhão',
          },
          {
            'id_cidade': 2107407,
            'nomeCidade': "Olho d'Água das Cunhãs",
          },
          {
            'id_cidade': 2107456,
            'nomeCidade': 'Olinda Nova do Maranhão',
          },
          {
            'id_cidade': 2107506,
            'nomeCidade': 'Paço do Lumiar',
          },
          {
            'id_cidade': 2107605,
            'nomeCidade': 'Palmeirândia',
          },
          {
            'id_cidade': 2107704,
            'nomeCidade': 'Paraibano',
          },
          {
            'id_cidade': 2107803,
            'nomeCidade': 'Parnarama',
          },
          {
            'id_cidade': 2107902,
            'nomeCidade': 'Passagem Franca',
          },
          {
            'id_cidade': 2108009,
            'nomeCidade': 'Pastos Bons',
          },
          {
            'id_cidade': 2108058,
            'nomeCidade': 'Paulino Neves',
          },
          {
            'id_cidade': 2108108,
            'nomeCidade': 'Paulo Ramos',
          },
          {
            'id_cidade': 2108207,
            'nomeCidade': 'Pedreiras',
          },
          {
            'id_cidade': 2108256,
            'nomeCidade': 'Pedro do Rosário',
          },
          {
            'id_cidade': 2108306,
            'nomeCidade': 'Penalva',
          },
          {
            'id_cidade': 2108405,
            'nomeCidade': 'Peri Mirim',
          },
          {
            'id_cidade': 2108454,
            'nomeCidade': 'Peritoró',
          },
          {
            'id_cidade': 2108504,
            'nomeCidade': 'Pindaré-Mirim',
          },
          {
            'id_cidade': 2108603,
            'nomeCidade': 'Pinheiro',
          },
          {
            'id_cidade': 2108702,
            'nomeCidade': 'Pio XII',
          },
          {
            'id_cidade': 2108801,
            'nomeCidade': 'Pirapemas',
          },
          {
            'id_cidade': 2108900,
            'nomeCidade': 'Poção de Pedras',
          },
          {
            'id_cidade': 2109007,
            'nomeCidade': 'Porto Franco',
          },
          {
            'id_cidade': 2109056,
            'nomeCidade': 'Porto Rico do Maranhão',
          },
          {
            'id_cidade': 2109106,
            'nomeCidade': 'Presidente Dutra',
          },
          {
            'id_cidade': 2109205,
            'nomeCidade': 'Presidente Juscelino',
          },
          {
            'id_cidade': 2109239,
            'nomeCidade': 'Presidente Médici',
          },
          {
            'id_cidade': 2109270,
            'nomeCidade': 'Presidente Sarney',
          },
          {
            'id_cidade': 2109304,
            'nomeCidade': 'Presidente Vargas',
          },
          {
            'id_cidade': 2109403,
            'nomeCidade': 'Primeira Cruz',
          },
          {
            'id_cidade': 2109452,
            'nomeCidade': 'Raposa',
          },
          {
            'id_cidade': 2109502,
            'nomeCidade': 'Riachão',
          },
          {
            'id_cidade': 2109551,
            'nomeCidade': 'Ribamar Fiquene',
          },
          {
            'id_cidade': 2109601,
            'nomeCidade': 'Rosário',
          },
          {
            'id_cidade': 2109700,
            'nomeCidade': 'Sambaíba',
          },
          {
            'id_cidade': 2109759,
            'nomeCidade': 'Santa Filomena do Maranhão',
          },
          {
            'id_cidade': 2109809,
            'nomeCidade': 'Santa Helena',
          },
          {
            'id_cidade': 2109908,
            'nomeCidade': 'Santa Inês',
          },
          {
            'id_cidade': 2110005,
            'nomeCidade': 'Santa Luzia',
          },
          {
            'id_cidade': 2110039,
            'nomeCidade': 'Santa Luzia do Paruá',
          },
          {
            'id_cidade': 2110104,
            'nomeCidade': 'Santa Quitéria do Maranhão',
          },
          {
            'id_cidade': 2110203,
            'nomeCidade': 'Santa Rita',
          },
          {
            'id_cidade': 2110237,
            'nomeCidade': 'Santana do Maranhão',
          },
          {
            'id_cidade': 2110278,
            'nomeCidade': 'Santo Amaro do Maranhão',
          },
          {
            'id_cidade': 2110302,
            'nomeCidade': 'Santo Antônio dos Lopes',
          },
          {
            'id_cidade': 2110401,
            'nomeCidade': 'São Benedito do Rio Preto',
          },
          {
            'id_cidade': 2110500,
            'nomeCidade': 'São Bento',
          },
          {
            'id_cidade': 2110609,
            'nomeCidade': 'São Bernardo',
          },
          {
            'id_cidade': 2110658,
            'nomeCidade': 'São Domingos do Azeitão',
          },
          {
            'id_cidade': 2110708,
            'nomeCidade': 'São Domingos do Maranhão',
          },
          {
            'id_cidade': 2110807,
            'nomeCidade': 'São Félix de Balsas',
          },
          {
            'id_cidade': 2110856,
            'nomeCidade': 'São Francisco do Brejão',
          },
          {
            'id_cidade': 2110906,
            'nomeCidade': 'São Francisco do Maranhão',
          },
          {
            'id_cidade': 2111003,
            'nomeCidade': 'São João Batista',
          },
          {
            'id_cidade': 2111029,
            'nomeCidade': 'São João do Carú',
          },
          {
            'id_cidade': 2111052,
            'nomeCidade': 'São João do Paraíso',
          },
          {
            'id_cidade': 2111078,
            'nomeCidade': 'São João do Soter',
          },
          {
            'id_cidade': 2111102,
            'nomeCidade': 'São João dos Patos',
          },
          {
            'id_cidade': 2111201,
            'nomeCidade': 'São José de Ribamar',
          },
          {
            'id_cidade': 2111250,
            'nomeCidade': 'São José dos Basílios',
          },
          {
            'id_cidade': 2111300,
            'nomeCidade': 'São Luís',
          },
          {
            'id_cidade': 2111409,
            'nomeCidade': 'São Luís Gonzaga do Maranhão',
          },
          {
            'id_cidade': 2111508,
            'nomeCidade': 'São Mateus do Maranhão',
          },
          {
            'id_cidade': 2111532,
            'nomeCidade': 'São Pedro da Água Branca',
          },
          {
            'id_cidade': 2111573,
            'nomeCidade': 'São Pedro dos Crentes',
          },
          {
            'id_cidade': 2111607,
            'nomeCidade': 'São Raimundo das Mangabeiras',
          },
          {
            'id_cidade': 2111631,
            'nomeCidade': 'São Raimundo do Doca Bezerra',
          },
          {
            'id_cidade': 2111672,
            'nomeCidade': 'São Roberto',
          },
          {
            'id_cidade': 2111706,
            'nomeCidade': 'São Vicente Ferrer',
          },
          {
            'id_cidade': 2111722,
            'nomeCidade': 'Satubinha',
          },
          {
            'id_cidade': 2111748,
            'nomeCidade': 'Senador Alexandre Costa',
          },
          {
            'id_cidade': 2111763,
            'nomeCidade': 'Senador La Rocque',
          },
          {
            'id_cidade': 2111789,
            'nomeCidade': 'Serrano do Maranhão',
          },
          {
            'id_cidade': 2111805,
            'nomeCidade': 'Sítio Novo',
          },
          {
            'id_cidade': 2111904,
            'nomeCidade': 'Sucupira do Norte',
          },
          {
            'id_cidade': 2111953,
            'nomeCidade': 'Sucupira do Riachão',
          },
          {
            'id_cidade': 2112001,
            'nomeCidade': 'Tasso Fragoso',
          },
          {
            'id_cidade': 2112100,
            'nomeCidade': 'Timbiras',
          },
          {
            'id_cidade': 2112209,
            'nomeCidade': 'Timon',
          },
          {
            'id_cidade': 2112233,
            'nomeCidade': 'Trizidela do Vale',
          },
          {
            'id_cidade': 2112274,
            'nomeCidade': 'Tufilândia',
          },
          {
            'id_cidade': 2112308,
            'nomeCidade': 'Tuntum',
          },
          {
            'id_cidade': 2112407,
            'nomeCidade': 'Turiaçu',
          },
          {
            'id_cidade': 2112456,
            'nomeCidade': 'Turilândia',
          },
          {
            'id_cidade': 2112506,
            'nomeCidade': 'Tutóia',
          },
          {
            'id_cidade': 2112605,
            'nomeCidade': 'Urbano Santos',
          },
          {
            'id_cidade': 2112704,
            'nomeCidade': 'Vargem Grande',
          },
          {
            'id_cidade': 2112803,
            'nomeCidade': 'Viana',
          },
          {
            'id_cidade': 2112852,
            'nomeCidade': 'Vila Nova dos Martírios',
          },
          {
            'id_cidade': 2112902,
            'nomeCidade': 'Vitória do Mearim',
          },
          {
            'id_cidade': 2113009,
            'nomeCidade': 'Vitorino Freire',
          },
          {
            'id_cidade': 2114007,
            'nomeCidade': 'Zé Doca',
          },
        ],
      },
      {
        'id': 51,
        'nomeEstado': 'Mato Grosso',
        'sigla': 'MT',
        'cidades': [
          {
            'id_cidade': 5100102,
            'nomeCidade': 'Acorizal',
          },
          {
            'id_cidade': 5100201,
            'nomeCidade': 'Água Boa',
          },
          {
            'id_cidade': 5100250,
            'nomeCidade': 'Alta Floresta',
          },
          {
            'id_cidade': 5100300,
            'nomeCidade': 'Alto Araguaia',
          },
          {
            'id_cidade': 5100359,
            'nomeCidade': 'Alto Boa Vista',
          },
          {
            'id_cidade': 5100409,
            'nomeCidade': 'Alto Garças',
          },
          {
            'id_cidade': 5100508,
            'nomeCidade': 'Alto Paraguai',
          },
          {
            'id_cidade': 5100607,
            'nomeCidade': 'Alto Taquari',
          },
          {
            'id_cidade': 5100805,
            'nomeCidade': 'Apiacás',
          },
          {
            'id_cidade': 5101001,
            'nomeCidade': 'Araguaiana',
          },
          {
            'id_cidade': 5101209,
            'nomeCidade': 'Araguainha',
          },
          {
            'id_cidade': 5101258,
            'nomeCidade': 'Araputanga',
          },
          {
            'id_cidade': 5101308,
            'nomeCidade': 'Arenápolis',
          },
          {
            'id_cidade': 5101407,
            'nomeCidade': 'Aripuanã',
          },
          {
            'id_cidade': 5101605,
            'nomeCidade': 'Barão de Melgaço',
          },
          {
            'id_cidade': 5101704,
            'nomeCidade': 'Barra do Bugres',
          },
          {
            'id_cidade': 5101803,
            'nomeCidade': 'Barra do Garças',
          },
          {
            'id_cidade': 5101852,
            'nomeCidade': 'Bom Jesus do Araguaia',
          },
          {
            'id_cidade': 5101902,
            'nomeCidade': 'Brasnorte',
          },
          {
            'id_cidade': 5102504,
            'nomeCidade': 'Cáceres',
          },
          {
            'id_cidade': 5102603,
            'nomeCidade': 'Campinápolis',
          },
          {
            'id_cidade': 5102637,
            'nomeCidade': 'Campo Novo do Parecis',
          },
          {
            'id_cidade': 5102678,
            'nomeCidade': 'Campo Verde',
          },
          {
            'id_cidade': 5102686,
            'nomeCidade': 'Campos de Júlio',
          },
          {
            'id_cidade': 5102694,
            'nomeCidade': 'Canabrava do Norte',
          },
          {
            'id_cidade': 5102702,
            'nomeCidade': 'Canarana',
          },
          {
            'id_cidade': 5102793,
            'nomeCidade': 'Carlinda',
          },
          {
            'id_cidade': 5102850,
            'nomeCidade': 'Castanheira',
          },
          {
            'id_cidade': 5103007,
            'nomeCidade': 'Chapada dos Guimarães',
          },
          {
            'id_cidade': 5103056,
            'nomeCidade': 'Cláudia',
          },
          {
            'id_cidade': 5103106,
            'nomeCidade': 'Cocalinho',
          },
          {
            'id_cidade': 5103205,
            'nomeCidade': 'Colíder',
          },
          {
            'id_cidade': 5103254,
            'nomeCidade': 'Colniza',
          },
          {
            'id_cidade': 5103304,
            'nomeCidade': 'Comodoro',
          },
          {
            'id_cidade': 5103353,
            'nomeCidade': 'Confresa',
          },
          {
            'id_cidade': 5103361,
            'nomeCidade': "Conquista D'Oeste",
          },
          {
            'id_cidade': 5103379,
            'nomeCidade': 'Cotriguaçu',
          },
          {
            'id_cidade': 5103403,
            'nomeCidade': 'Cuiabá',
          },
          {
            'id_cidade': 5103437,
            'nomeCidade': 'Curvelândia',
          },
          {
            'id_cidade': 5103452,
            'nomeCidade': 'Denise',
          },
          {
            'id_cidade': 5103502,
            'nomeCidade': 'Diamantino',
          },
          {
            'id_cidade': 5103601,
            'nomeCidade': 'Dom Aquino',
          },
          {
            'id_cidade': 5103700,
            'nomeCidade': 'Feliz Natal',
          },
          {
            'id_cidade': 5103809,
            'nomeCidade': "Figueirópolis D'Oeste",
          },
          {
            'id_cidade': 5103858,
            'nomeCidade': 'Gaúcha do Norte',
          },
          {
            'id_cidade': 5103908,
            'nomeCidade': 'General Carneiro',
          },
          {
            'id_cidade': 5103957,
            'nomeCidade': "Glória D'Oeste",
          },
          {
            'id_cidade': 5104104,
            'nomeCidade': 'Guarantã do Norte',
          },
          {
            'id_cidade': 5104203,
            'nomeCidade': 'Guiratinga',
          },
          {
            'id_cidade': 5104500,
            'nomeCidade': 'Indiavaí',
          },
          {
            'id_cidade': 5104526,
            'nomeCidade': 'Ipiranga do Norte',
          },
          {
            'id_cidade': 5104542,
            'nomeCidade': 'Itanhangá',
          },
          {
            'id_cidade': 5104559,
            'nomeCidade': 'Itaúba',
          },
          {
            'id_cidade': 5104609,
            'nomeCidade': 'Itiquira',
          },
          {
            'id_cidade': 5104807,
            'nomeCidade': 'Jaciara',
          },
          {
            'id_cidade': 5104906,
            'nomeCidade': 'Jangada',
          },
          {
            'id_cidade': 5105002,
            'nomeCidade': 'Jauru',
          },
          {
            'id_cidade': 5105101,
            'nomeCidade': 'Juara',
          },
          {
            'id_cidade': 5105150,
            'nomeCidade': 'Juína',
          },
          {
            'id_cidade': 5105176,
            'nomeCidade': 'Juruena',
          },
          {
            'id_cidade': 5105200,
            'nomeCidade': 'Juscimeira',
          },
          {
            'id_cidade': 5105234,
            'nomeCidade': "Lambari D'Oeste",
          },
          {
            'id_cidade': 5105259,
            'nomeCidade': 'Lucas do Rio Verde',
          },
          {
            'id_cidade': 5105309,
            'nomeCidade': 'Luciara',
          },
          {
            'id_cidade': 5105580,
            'nomeCidade': 'Marcelândia',
          },
          {
            'id_cidade': 5105606,
            'nomeCidade': 'Matupá',
          },
          {
            'id_cidade': 5105622,
            'nomeCidade': "Mirassol d'Oeste",
          },
          {
            'id_cidade': 5105903,
            'nomeCidade': 'Nobres',
          },
          {
            'id_cidade': 5106000,
            'nomeCidade': 'Nortelândia',
          },
          {
            'id_cidade': 5106109,
            'nomeCidade': 'Nossa Senhora do Livramento',
          },
          {
            'id_cidade': 5106158,
            'nomeCidade': 'Nova Bandeirantes',
          },
          {
            'id_cidade': 5106208,
            'nomeCidade': 'Nova Brasilândia',
          },
          {
            'id_cidade': 5106216,
            'nomeCidade': 'Nova Canaã do Norte',
          },
          {
            'id_cidade': 5108808,
            'nomeCidade': 'Nova Guarita',
          },
          {
            'id_cidade': 5106182,
            'nomeCidade': 'Nova Lacerda',
          },
          {
            'id_cidade': 5108857,
            'nomeCidade': 'Nova Marilândia',
          },
          {
            'id_cidade': 5108907,
            'nomeCidade': 'Nova Maringá',
          },
          {
            'id_cidade': 5108956,
            'nomeCidade': 'Nova Monte Verde',
          },
          {
            'id_cidade': 5106224,
            'nomeCidade': 'Nova Mutum',
          },
          {
            'id_cidade': 5106174,
            'nomeCidade': 'Nova Nazaré',
          },
          {
            'id_cidade': 5106232,
            'nomeCidade': 'Nova Olímpia',
          },
          {
            'id_cidade': 5106190,
            'nomeCidade': 'Nova Santa Helena',
          },
          {
            'id_cidade': 5106240,
            'nomeCidade': 'Nova Ubiratã',
          },
          {
            'id_cidade': 5106257,
            'nomeCidade': 'Nova Xavantina',
          },
          {
            'id_cidade': 5106273,
            'nomeCidade': 'Novo Horizonte do Norte',
          },
          {
            'id_cidade': 5106265,
            'nomeCidade': 'Novo Mundo',
          },
          {
            'id_cidade': 5106315,
            'nomeCidade': 'Novo Santo Antônio',
          },
          {
            'id_cidade': 5106281,
            'nomeCidade': 'Novo São Joaquim',
          },
          {
            'id_cidade': 5106299,
            'nomeCidade': 'Paranaíta',
          },
          {
            'id_cidade': 5106307,
            'nomeCidade': 'Paranatinga',
          },
          {
            'id_cidade': 5106372,
            'nomeCidade': 'Pedra Preta',
          },
          {
            'id_cidade': 5106422,
            'nomeCidade': 'Peixoto de Azevedo',
          },
          {
            'id_cidade': 5106455,
            'nomeCidade': 'Planalto da Serra',
          },
          {
            'id_cidade': 5106505,
            'nomeCidade': 'Poconé',
          },
          {
            'id_cidade': 5106653,
            'nomeCidade': 'Pontal do Araguaia',
          },
          {
            'id_cidade': 5106703,
            'nomeCidade': 'Ponte Branca',
          },
          {
            'id_cidade': 5106752,
            'nomeCidade': 'Pontes e Lacerda',
          },
          {
            'id_cidade': 5106778,
            'nomeCidade': 'Porto Alegre do Norte',
          },
          {
            'id_cidade': 5106802,
            'nomeCidade': 'Porto dos Gaúchos',
          },
          {
            'id_cidade': 5106828,
            'nomeCidade': 'Porto Esperidião',
          },
          {
            'id_cidade': 5106851,
            'nomeCidade': 'Porto Estrela',
          },
          {
            'id_cidade': 5107008,
            'nomeCidade': 'Poxoréu',
          },
          {
            'id_cidade': 5107040,
            'nomeCidade': 'Primavera do Leste',
          },
          {
            'id_cidade': 5107065,
            'nomeCidade': 'Querência',
          },
          {
            'id_cidade': 5107156,
            'nomeCidade': 'Reserva do Cabaçal',
          },
          {
            'id_cidade': 5107180,
            'nomeCidade': 'Ribeirão Cascalheira',
          },
          {
            'id_cidade': 5107198,
            'nomeCidade': 'Ribeirãozinho',
          },
          {
            'id_cidade': 5107206,
            'nomeCidade': 'Rio Branco',
          },
          {
            'id_cidade': 5107578,
            'nomeCidade': 'Rondolândia',
          },
          {
            'id_cidade': 5107602,
            'nomeCidade': 'Rondonópolis',
          },
          {
            'id_cidade': 5107701,
            'nomeCidade': 'Rosário Oeste',
          },
          {
            'id_cidade': 5107750,
            'nomeCidade': 'Salto do Céu',
          },
          {
            'id_cidade': 5107248,
            'nomeCidade': 'Santa Carmem',
          },
          {
            'id_cidade': 5107743,
            'nomeCidade': 'Santa Cruz do Xingu',
          },
          {
            'id_cidade': 5107768,
            'nomeCidade': 'Santa Rita do Trivelato',
          },
          {
            'id_cidade': 5107776,
            'nomeCidade': 'Santa Terezinha',
          },
          {
            'id_cidade': 5107263,
            'nomeCidade': 'Santo Afonso',
          },
          {
            'id_cidade': 5107792,
            'nomeCidade': 'Santo Antônio do Leste',
          },
          {
            'id_cidade': 5107800,
            'nomeCidade': 'Santo Antônio do Leverger',
          },
          {
            'id_cidade': 5107859,
            'nomeCidade': 'São Félix do Araguaia',
          },
          {
            'id_cidade': 5107297,
            'nomeCidade': 'São José do Povo',
          },
          {
            'id_cidade': 5107305,
            'nomeCidade': 'São José do Rio Claro',
          },
          {
            'id_cidade': 5107354,
            'nomeCidade': 'São José do Xingu',
          },
          {
            'id_cidade': 5107107,
            'nomeCidade': 'São José dos Quatro Marcos',
          },
          {
            'id_cidade': 5107404,
            'nomeCidade': 'São Pedro da Cipa',
          },
          {
            'id_cidade': 5107875,
            'nomeCidade': 'Sapezal',
          },
          {
            'id_cidade': 5107883,
            'nomeCidade': 'Serra Nova Dourada',
          },
          {
            'id_cidade': 5107909,
            'nomeCidade': 'Sinop',
          },
          {
            'id_cidade': 5107925,
            'nomeCidade': 'Sorriso',
          },
          {
            'id_cidade': 5107941,
            'nomeCidade': 'Tabaporã',
          },
          {
            'id_cidade': 5107958,
            'nomeCidade': 'Tangará da Serra',
          },
          {
            'id_cidade': 5108006,
            'nomeCidade': 'Tapurah',
          },
          {
            'id_cidade': 5108055,
            'nomeCidade': 'Terra Nova do Norte',
          },
          {
            'id_cidade': 5108105,
            'nomeCidade': 'Tesouro',
          },
          {
            'id_cidade': 5108204,
            'nomeCidade': 'Torixoréu',
          },
          {
            'id_cidade': 5108303,
            'nomeCidade': 'União do Sul',
          },
          {
            'id_cidade': 5108352,
            'nomeCidade': 'Vale de São Domingos',
          },
          {
            'id_cidade': 5108402,
            'nomeCidade': 'Várzea Grande',
          },
          {
            'id_cidade': 5108501,
            'nomeCidade': 'Vera',
          },
          {
            'id_cidade': 5105507,
            'nomeCidade': 'Vila Bela da Santíssima Trindade',
          },
          {
            'id_cidade': 5108600,
            'nomeCidade': 'Vila Rica',
          },
        ],
      },
      {
        'id': 50,
        'nomeEstado': 'Mato Grosso do Sul',
        'sigla': 'MS',
        'cidades': [
          {
            'id_cidade': 5000203,
            'nomeCidade': 'Água Clara',
          },
          {
            'id_cidade': 5000252,
            'nomeCidade': 'Alcinópolis',
          },
          {
            'id_cidade': 5000609,
            'nomeCidade': 'Amambai',
          },
          {
            'id_cidade': 5000708,
            'nomeCidade': 'Anastácio',
          },
          {
            'id_cidade': 5000807,
            'nomeCidade': 'Anaurilândia',
          },
          {
            'id_cidade': 5000856,
            'nomeCidade': 'Angélica',
          },
          {
            'id_cidade': 5000906,
            'nomeCidade': 'Antônio João',
          },
          {
            'id_cidade': 5001003,
            'nomeCidade': 'Aparecida do Taboado',
          },
          {
            'id_cidade': 5001102,
            'nomeCidade': 'Aquidauana',
          },
          {
            'id_cidade': 5001243,
            'nomeCidade': 'Aral Moreira',
          },
          {
            'id_cidade': 5001508,
            'nomeCidade': 'Bandeirantes',
          },
          {
            'id_cidade': 5001904,
            'nomeCidade': 'Bataguassu',
          },
          {
            'id_cidade': 5002001,
            'nomeCidade': 'Batayporã',
          },
          {
            'id_cidade': 5002100,
            'nomeCidade': 'Bela Vista',
          },
          {
            'id_cidade': 5002159,
            'nomeCidade': 'Bodoquena',
          },
          {
            'id_cidade': 5002209,
            'nomeCidade': 'Bonito',
          },
          {
            'id_cidade': 5002308,
            'nomeCidade': 'Brasilândia',
          },
          {
            'id_cidade': 5002407,
            'nomeCidade': 'Caarapó',
          },
          {
            'id_cidade': 5002605,
            'nomeCidade': 'Camapuã',
          },
          {
            'id_cidade': 5002704,
            'nomeCidade': 'Campo Grande',
          },
          {
            'id_cidade': 5002803,
            'nomeCidade': 'Caracol',
          },
          {
            'id_cidade': 5002902,
            'nomeCidade': 'Cassilândia',
          },
          {
            'id_cidade': 5002951,
            'nomeCidade': 'Chapadão do Sul',
          },
          {
            'id_cidade': 5003108,
            'nomeCidade': 'Corguinho',
          },
          {
            'id_cidade': 5003157,
            'nomeCidade': 'Coronel Sapucaia',
          },
          {
            'id_cidade': 5003207,
            'nomeCidade': 'Corumbá',
          },
          {
            'id_cidade': 5003256,
            'nomeCidade': 'Costa Rica',
          },
          {
            'id_cidade': 5003306,
            'nomeCidade': 'Coxim',
          },
          {
            'id_cidade': 5003454,
            'nomeCidade': 'Deodápolis',
          },
          {
            'id_cidade': 5003488,
            'nomeCidade': 'Dois Irmãos do Buriti',
          },
          {
            'id_cidade': 5003504,
            'nomeCidade': 'Douradina',
          },
          {
            'id_cidade': 5003702,
            'nomeCidade': 'Dourados',
          },
          {
            'id_cidade': 5003751,
            'nomeCidade': 'Eldorado',
          },
          {
            'id_cidade': 5003801,
            'nomeCidade': 'Fátima do Sul',
          },
          {
            'id_cidade': 5003900,
            'nomeCidade': 'Figueirão',
          },
          {
            'id_cidade': 5004007,
            'nomeCidade': 'Glória de Dourados',
          },
          {
            'id_cidade': 5004106,
            'nomeCidade': 'Guia Lopes da Laguna',
          },
          {
            'id_cidade': 5004304,
            'nomeCidade': 'Iguatemi',
          },
          {
            'id_cidade': 5004403,
            'nomeCidade': 'Inocência',
          },
          {
            'id_cidade': 5004502,
            'nomeCidade': 'Itaporã',
          },
          {
            'id_cidade': 5004601,
            'nomeCidade': 'Itaquiraí',
          },
          {
            'id_cidade': 5004700,
            'nomeCidade': 'Ivinhema',
          },
          {
            'id_cidade': 5004809,
            'nomeCidade': 'Japorã',
          },
          {
            'id_cidade': 5004908,
            'nomeCidade': 'Jaraguari',
          },
          {
            'id_cidade': 5005004,
            'nomeCidade': 'Jardim',
          },
          {
            'id_cidade': 5005103,
            'nomeCidade': 'Jateí',
          },
          {
            'id_cidade': 5005152,
            'nomeCidade': 'Juti',
          },
          {
            'id_cidade': 5005202,
            'nomeCidade': 'Ladário',
          },
          {
            'id_cidade': 5005251,
            'nomeCidade': 'Laguna Carapã',
          },
          {
            'id_cidade': 5005400,
            'nomeCidade': 'Maracaju',
          },
          {
            'id_cidade': 5005608,
            'nomeCidade': 'Miranda',
          },
          {
            'id_cidade': 5005681,
            'nomeCidade': 'Mundo Novo',
          },
          {
            'id_cidade': 5005707,
            'nomeCidade': 'Naviraí',
          },
          {
            'id_cidade': 5005806,
            'nomeCidade': 'Nioaque',
          },
          {
            'id_cidade': 5006002,
            'nomeCidade': 'Nova Alvorada do Sul',
          },
          {
            'id_cidade': 5006200,
            'nomeCidade': 'Nova Andradina',
          },
          {
            'id_cidade': 5006259,
            'nomeCidade': 'Novo Horizonte do Sul',
          },
          {
            'id_cidade': 5006275,
            'nomeCidade': 'Paraíso das Águas',
          },
          {
            'id_cidade': 5006309,
            'nomeCidade': 'Paranaíba',
          },
          {
            'id_cidade': 5006358,
            'nomeCidade': 'Paranhos',
          },
          {
            'id_cidade': 5006408,
            'nomeCidade': 'Pedro Gomes',
          },
          {
            'id_cidade': 5006606,
            'nomeCidade': 'Ponta Porã',
          },
          {
            'id_cidade': 5006903,
            'nomeCidade': 'Porto Murtinho',
          },
          {
            'id_cidade': 5007109,
            'nomeCidade': 'Ribas do Rio Pardo',
          },
          {
            'id_cidade': 5007208,
            'nomeCidade': 'Rio Brilhante',
          },
          {
            'id_cidade': 5007307,
            'nomeCidade': 'Rio Negro',
          },
          {
            'id_cidade': 5007406,
            'nomeCidade': 'Rio Verde de Mato Grosso',
          },
          {
            'id_cidade': 5007505,
            'nomeCidade': 'Rochedo',
          },
          {
            'id_cidade': 5007554,
            'nomeCidade': 'Santa Rita do Pardo',
          },
          {
            'id_cidade': 5007695,
            'nomeCidade': 'São Gabriel do Oeste',
          },
          {
            'id_cidade': 5007802,
            'nomeCidade': 'Selvíria',
          },
          {
            'id_cidade': 5007703,
            'nomeCidade': 'Sete Quedas',
          },
          {
            'id_cidade': 5007901,
            'nomeCidade': 'Sidrolândia',
          },
          {
            'id_cidade': 5007935,
            'nomeCidade': 'Sonora',
          },
          {
            'id_cidade': 5007950,
            'nomeCidade': 'Tacuru',
          },
          {
            'id_cidade': 5007976,
            'nomeCidade': 'Taquarussu',
          },
          {
            'id_cidade': 5008008,
            'nomeCidade': 'Terenos',
          },
          {
            'id_cidade': 5008305,
            'nomeCidade': 'Três Lagoas',
          },
          {
            'id_cidade': 5008404,
            'nomeCidade': 'Vicentina',
          },
        ],
      },
      {
        'id': 31,
        'nomeEstado': 'Minas Gerais',
        'sigla': 'MG',
        'cidades': [
          {
            'id_cidade': 3100104,
            'nomeCidade': 'Abadia dos Dourados',
          },
          {
            'id_cidade': 3100203,
            'nomeCidade': 'Abaeté',
          },
          {
            'id_cidade': 3100302,
            'nomeCidade': 'Abre Campo',
          },
          {
            'id_cidade': 3100401,
            'nomeCidade': 'Acaiaca',
          },
          {
            'id_cidade': 3100500,
            'nomeCidade': 'Açucena',
          },
          {
            'id_cidade': 3100609,
            'nomeCidade': 'Água Boa',
          },
          {
            'id_cidade': 3100708,
            'nomeCidade': 'Água Comprida',
          },
          {
            'id_cidade': 3100807,
            'nomeCidade': 'Aguanil',
          },
          {
            'id_cidade': 3100906,
            'nomeCidade': 'Águas Formosas',
          },
          {
            'id_cidade': 3101003,
            'nomeCidade': 'Águas Vermelhas',
          },
          {
            'id_cidade': 3101102,
            'nomeCidade': 'Aimorés',
          },
          {
            'id_cidade': 3101201,
            'nomeCidade': 'Aiuruoca',
          },
          {
            'id_cidade': 3101300,
            'nomeCidade': 'Alagoa',
          },
          {
            'id_cidade': 3101409,
            'nomeCidade': 'Albertina',
          },
          {
            'id_cidade': 3101508,
            'nomeCidade': 'Além Paraíba',
          },
          {
            'id_cidade': 3101607,
            'nomeCidade': 'Alfenas',
          },
          {
            'id_cidade': 3101631,
            'nomeCidade': 'Alfredo Vasconcelos',
          },
          {
            'id_cidade': 3101706,
            'nomeCidade': 'Almenara',
          },
          {
            'id_cidade': 3101805,
            'nomeCidade': 'Alpercata',
          },
          {
            'id_cidade': 3101904,
            'nomeCidade': 'Alpinópolis',
          },
          {
            'id_cidade': 3102001,
            'nomeCidade': 'Alterosa',
          },
          {
            'id_cidade': 3102050,
            'nomeCidade': 'Alto Caparaó',
          },
          {
            'id_cidade': 3153509,
            'nomeCidade': 'Alto Jequitibá',
          },
          {
            'id_cidade': 3102100,
            'nomeCidade': 'Alto Rio Doce',
          },
          {
            'id_cidade': 3102209,
            'nomeCidade': 'Alvarenga',
          },
          {
            'id_cidade': 3102308,
            'nomeCidade': 'Alvinópolis',
          },
          {
            'id_cidade': 3102407,
            'nomeCidade': 'Alvorada de Minas',
          },
          {
            'id_cidade': 3102506,
            'nomeCidade': 'Amparo do Serra',
          },
          {
            'id_cidade': 3102605,
            'nomeCidade': 'Andradas',
          },
          {
            'id_cidade': 3102803,
            'nomeCidade': 'Andrelândia',
          },
          {
            'id_cidade': 3102852,
            'nomeCidade': 'Angelândia',
          },
          {
            'id_cidade': 3102902,
            'nomeCidade': 'Antônio Carlos',
          },
          {
            'id_cidade': 3103009,
            'nomeCidade': 'Antônio Dias',
          },
          {
            'id_cidade': 3103108,
            'nomeCidade': 'Antônio Prado de Minas',
          },
          {
            'id_cidade': 3103207,
            'nomeCidade': 'Araçaí',
          },
          {
            'id_cidade': 3103306,
            'nomeCidade': 'Aracitaba',
          },
          {
            'id_cidade': 3103405,
            'nomeCidade': 'Araçuaí',
          },
          {
            'id_cidade': 3103504,
            'nomeCidade': 'Araguari',
          },
          {
            'id_cidade': 3103603,
            'nomeCidade': 'Arantina',
          },
          {
            'id_cidade': 3103702,
            'nomeCidade': 'Araponga',
          },
          {
            'id_cidade': 3103751,
            'nomeCidade': 'Araporã',
          },
          {
            'id_cidade': 3103801,
            'nomeCidade': 'Arapuá',
          },
          {
            'id_cidade': 3103900,
            'nomeCidade': 'Araújos',
          },
          {
            'id_cidade': 3104007,
            'nomeCidade': 'Araxá',
          },
          {
            'id_cidade': 3104106,
            'nomeCidade': 'Arceburgo',
          },
          {
            'id_cidade': 3104205,
            'nomeCidade': 'Arcos',
          },
          {
            'id_cidade': 3104304,
            'nomeCidade': 'Areado',
          },
          {
            'id_cidade': 3104403,
            'nomeCidade': 'Argirita',
          },
          {
            'id_cidade': 3104452,
            'nomeCidade': 'Aricanduva',
          },
          {
            'id_cidade': 3104502,
            'nomeCidade': 'Arinos',
          },
          {
            'id_cidade': 3104601,
            'nomeCidade': 'Astolfo Dutra',
          },
          {
            'id_cidade': 3104700,
            'nomeCidade': 'Ataléia',
          },
          {
            'id_cidade': 3104809,
            'nomeCidade': 'Augusto de Lima',
          },
          {
            'id_cidade': 3104908,
            'nomeCidade': 'Baependi',
          },
          {
            'id_cidade': 3105004,
            'nomeCidade': 'Baldim',
          },
          {
            'id_cidade': 3105103,
            'nomeCidade': 'Bambuí',
          },
          {
            'id_cidade': 3105202,
            'nomeCidade': 'Bandeira',
          },
          {
            'id_cidade': 3105301,
            'nomeCidade': 'Bandeira do Sul',
          },
          {
            'id_cidade': 3105400,
            'nomeCidade': 'Barão de Cocais',
          },
          {
            'id_cidade': 3105509,
            'nomeCidade': 'Barão de Monte Alto',
          },
          {
            'id_cidade': 3105608,
            'nomeCidade': 'Barbacena',
          },
          {
            'id_cidade': 3105707,
            'nomeCidade': 'Barra Longa',
          },
          {
            'id_cidade': 3105905,
            'nomeCidade': 'Barroso',
          },
          {
            'id_cidade': 3106002,
            'nomeCidade': 'Bela Vista de Minas',
          },
          {
            'id_cidade': 3106101,
            'nomeCidade': 'Belmiro Braga',
          },
          {
            'id_cidade': 3106200,
            'nomeCidade': 'Belo Horizonte',
          },
          {
            'id_cidade': 3106309,
            'nomeCidade': 'Belo Oriente',
          },
          {
            'id_cidade': 3106408,
            'nomeCidade': 'Belo Vale',
          },
          {
            'id_cidade': 3106507,
            'nomeCidade': 'Berilo',
          },
          {
            'id_cidade': 3106655,
            'nomeCidade': 'Berizal',
          },
          {
            'id_cidade': 3106606,
            'nomeCidade': 'Bertópolis',
          },
          {
            'id_cidade': 3106705,
            'nomeCidade': 'Betim',
          },
          {
            'id_cidade': 3106804,
            'nomeCidade': 'Bias Fortes',
          },
          {
            'id_cidade': 3106903,
            'nomeCidade': 'Bicas',
          },
          {
            'id_cidade': 3107000,
            'nomeCidade': 'Biquinhas',
          },
          {
            'id_cidade': 3107109,
            'nomeCidade': 'Boa Esperança',
          },
          {
            'id_cidade': 3107208,
            'nomeCidade': 'Bocaina de Minas',
          },
          {
            'id_cidade': 3107307,
            'nomeCidade': 'Bocaiúva',
          },
          {
            'id_cidade': 3107406,
            'nomeCidade': 'Bom Despacho',
          },
          {
            'id_cidade': 3107505,
            'nomeCidade': 'Bom Jardim de Minas',
          },
          {
            'id_cidade': 3107604,
            'nomeCidade': 'Bom Jesus da Penha',
          },
          {
            'id_cidade': 3107703,
            'nomeCidade': 'Bom Jesus do Amparo',
          },
          {
            'id_cidade': 3107802,
            'nomeCidade': 'Bom Jesus do Galho',
          },
          {
            'id_cidade': 3107901,
            'nomeCidade': 'Bom Repouso',
          },
          {
            'id_cidade': 3108008,
            'nomeCidade': 'Bom Sucesso',
          },
          {
            'id_cidade': 3108107,
            'nomeCidade': 'Bonfim',
          },
          {
            'id_cidade': 3108206,
            'nomeCidade': 'Bonfinópolis de Minas',
          },
          {
            'id_cidade': 3108255,
            'nomeCidade': 'Bonito de Minas',
          },
          {
            'id_cidade': 3108305,
            'nomeCidade': 'Borda da Mata',
          },
          {
            'id_cidade': 3108404,
            'nomeCidade': 'Botelhos',
          },
          {
            'id_cidade': 3108503,
            'nomeCidade': 'Botumirim',
          },
          {
            'id_cidade': 3108701,
            'nomeCidade': 'Brás Pires',
          },
          {
            'id_cidade': 3108552,
            'nomeCidade': 'Brasilândia de Minas',
          },
          {
            'id_cidade': 3108602,
            'nomeCidade': 'Brasília de Minas',
          },
          {
            'id_cidade': 3108800,
            'nomeCidade': 'Braúnas',
          },
          {
            'id_cidade': 3108909,
            'nomeCidade': 'Brazópolis',
          },
          {
            'id_cidade': 3109006,
            'nomeCidade': 'Brumadinho',
          },
          {
            'id_cidade': 3109105,
            'nomeCidade': 'Bueno Brandão',
          },
          {
            'id_cidade': 3109204,
            'nomeCidade': 'Buenópolis',
          },
          {
            'id_cidade': 3109253,
            'nomeCidade': 'Bugre',
          },
          {
            'id_cidade': 3109303,
            'nomeCidade': 'Buritis',
          },
          {
            'id_cidade': 3109402,
            'nomeCidade': 'Buritizeiro',
          },
          {
            'id_cidade': 3109451,
            'nomeCidade': 'Cabeceira Grande',
          },
          {
            'id_cidade': 3109501,
            'nomeCidade': 'Cabo Verde',
          },
          {
            'id_cidade': 3109600,
            'nomeCidade': 'Cachoeira da Prata',
          },
          {
            'id_cidade': 3109709,
            'nomeCidade': 'Cachoeira de Minas',
          },
          {
            'id_cidade': 3102704,
            'nomeCidade': 'Cachoeira de Pajeú',
          },
          {
            'id_cidade': 3109808,
            'nomeCidade': 'Cachoeira Dourada',
          },
          {
            'id_cidade': 3109907,
            'nomeCidade': 'Caetanópolis',
          },
          {
            'id_cidade': 3110004,
            'nomeCidade': 'Caeté',
          },
          {
            'id_cidade': 3110103,
            'nomeCidade': 'Caiana',
          },
          {
            'id_cidade': 3110202,
            'nomeCidade': 'Cajuri',
          },
          {
            'id_cidade': 3110301,
            'nomeCidade': 'Caldas',
          },
          {
            'id_cidade': 3110400,
            'nomeCidade': 'Camacho',
          },
          {
            'id_cidade': 3110509,
            'nomeCidade': 'Camanducaia',
          },
          {
            'id_cidade': 3110608,
            'nomeCidade': 'Cambuí',
          },
          {
            'id_cidade': 3110707,
            'nomeCidade': 'Cambuquira',
          },
          {
            'id_cidade': 3110806,
            'nomeCidade': 'Campanário',
          },
          {
            'id_cidade': 3110905,
            'nomeCidade': 'Campanha',
          },
          {
            'id_cidade': 3111002,
            'nomeCidade': 'Campestre',
          },
          {
            'id_cidade': 3111101,
            'nomeCidade': 'Campina Verde',
          },
          {
            'id_cidade': 3111150,
            'nomeCidade': 'Campo Azul',
          },
          {
            'id_cidade': 3111200,
            'nomeCidade': 'Campo Belo',
          },
          {
            'id_cidade': 3111309,
            'nomeCidade': 'Campo do Meio',
          },
          {
            'id_cidade': 3111408,
            'nomeCidade': 'Campo Florido',
          },
          {
            'id_cidade': 3111507,
            'nomeCidade': 'Campos Altos',
          },
          {
            'id_cidade': 3111606,
            'nomeCidade': 'Campos Gerais',
          },
          {
            'id_cidade': 3111903,
            'nomeCidade': 'Cana Verde',
          },
          {
            'id_cidade': 3111705,
            'nomeCidade': 'Canaã',
          },
          {
            'id_cidade': 3111804,
            'nomeCidade': 'Canápolis',
          },
          {
            'id_cidade': 3112000,
            'nomeCidade': 'Candeias',
          },
          {
            'id_cidade': 3112059,
            'nomeCidade': 'Cantagalo',
          },
          {
            'id_cidade': 3112109,
            'nomeCidade': 'Caparaó',
          },
          {
            'id_cidade': 3112208,
            'nomeCidade': 'Capela Nova',
          },
          {
            'id_cidade': 3112307,
            'nomeCidade': 'Capelinha',
          },
          {
            'id_cidade': 3112406,
            'nomeCidade': 'Capetinga',
          },
          {
            'id_cidade': 3112505,
            'nomeCidade': 'Capim Branco',
          },
          {
            'id_cidade': 3112604,
            'nomeCidade': 'Capinópolis',
          },
          {
            'id_cidade': 3112653,
            'nomeCidade': 'Capitão Andrade',
          },
          {
            'id_cidade': 3112703,
            'nomeCidade': 'Capitão Enéas',
          },
          {
            'id_cidade': 3112802,
            'nomeCidade': 'Capitólio',
          },
          {
            'id_cidade': 3112901,
            'nomeCidade': 'Caputira',
          },
          {
            'id_cidade': 3113008,
            'nomeCidade': 'Caraí',
          },
          {
            'id_cidade': 3113107,
            'nomeCidade': 'Caranaíba',
          },
          {
            'id_cidade': 3113206,
            'nomeCidade': 'Carandaí',
          },
          {
            'id_cidade': 3113305,
            'nomeCidade': 'Carangola',
          },
          {
            'id_cidade': 3113404,
            'nomeCidade': 'Caratinga',
          },
          {
            'id_cidade': 3113503,
            'nomeCidade': 'Carbonita',
          },
          {
            'id_cidade': 3113602,
            'nomeCidade': 'Careaçu',
          },
          {
            'id_cidade': 3113701,
            'nomeCidade': 'Carlos Chagas',
          },
          {
            'id_cidade': 3113800,
            'nomeCidade': 'Carmésia',
          },
          {
            'id_cidade': 3113909,
            'nomeCidade': 'Carmo da Cachoeira',
          },
          {
            'id_cidade': 3114006,
            'nomeCidade': 'Carmo da Mata',
          },
          {
            'id_cidade': 3114105,
            'nomeCidade': 'Carmo de Minas',
          },
          {
            'id_cidade': 3114204,
            'nomeCidade': 'Carmo do Cajuru',
          },
          {
            'id_cidade': 3114303,
            'nomeCidade': 'Carmo do Paranaíba',
          },
          {
            'id_cidade': 3114402,
            'nomeCidade': 'Carmo do Rio Claro',
          },
          {
            'id_cidade': 3114501,
            'nomeCidade': 'Carmópolis de Minas',
          },
          {
            'id_cidade': 3114550,
            'nomeCidade': 'Carneirinho',
          },
          {
            'id_cidade': 3114600,
            'nomeCidade': 'Carrancas',
          },
          {
            'id_cidade': 3114709,
            'nomeCidade': 'Carvalhópolis',
          },
          {
            'id_cidade': 3114808,
            'nomeCidade': 'Carvalhos',
          },
          {
            'id_cidade': 3114907,
            'nomeCidade': 'Casa Grande',
          },
          {
            'id_cidade': 3115003,
            'nomeCidade': 'Cascalho Rico',
          },
          {
            'id_cidade': 3115102,
            'nomeCidade': 'Cássia',
          },
          {
            'id_cidade': 3115300,
            'nomeCidade': 'Cataguases',
          },
          {
            'id_cidade': 3115359,
            'nomeCidade': 'Catas Altas',
          },
          {
            'id_cidade': 3115409,
            'nomeCidade': 'Catas Altas da Noruega',
          },
          {
            'id_cidade': 3115458,
            'nomeCidade': 'Catuji',
          },
          {
            'id_cidade': 3115474,
            'nomeCidade': 'Catuti',
          },
          {
            'id_cidade': 3115508,
            'nomeCidade': 'Caxambu',
          },
          {
            'id_cidade': 3115607,
            'nomeCidade': 'Cedro do Abaeté',
          },
          {
            'id_cidade': 3115706,
            'nomeCidade': 'Central de Minas',
          },
          {
            'id_cidade': 3115805,
            'nomeCidade': 'Centralina',
          },
          {
            'id_cidade': 3115904,
            'nomeCidade': 'Chácara',
          },
          {
            'id_cidade': 3116001,
            'nomeCidade': 'Chalé',
          },
          {
            'id_cidade': 3116100,
            'nomeCidade': 'Chapada do Norte',
          },
          {
            'id_cidade': 3116159,
            'nomeCidade': 'Chapada Gaúcha',
          },
          {
            'id_cidade': 3116209,
            'nomeCidade': 'Chiador',
          },
          {
            'id_cidade': 3116308,
            'nomeCidade': 'Cipotânea',
          },
          {
            'id_cidade': 3116407,
            'nomeCidade': 'Claraval',
          },
          {
            'id_cidade': 3116506,
            'nomeCidade': 'Claro dos Poções',
          },
          {
            'id_cidade': 3116605,
            'nomeCidade': 'Cláudio',
          },
          {
            'id_cidade': 3116704,
            'nomeCidade': 'Coimbra',
          },
          {
            'id_cidade': 3116803,
            'nomeCidade': 'Coluna',
          },
          {
            'id_cidade': 3116902,
            'nomeCidade': 'Comendador Gomes',
          },
          {
            'id_cidade': 3117009,
            'nomeCidade': 'Comercinho',
          },
          {
            'id_cidade': 3117108,
            'nomeCidade': 'Conceição da Aparecida',
          },
          {
            'id_cidade': 3115201,
            'nomeCidade': 'Conceição da Barra de Minas',
          },
          {
            'id_cidade': 3117306,
            'nomeCidade': 'Conceição das Alagoas',
          },
          {
            'id_cidade': 3117207,
            'nomeCidade': 'Conceição das Pedras',
          },
          {
            'id_cidade': 3117405,
            'nomeCidade': 'Conceição de Ipanema',
          },
          {
            'id_cidade': 3117504,
            'nomeCidade': 'Conceição do Mato Dentro',
          },
          {
            'id_cidade': 3117603,
            'nomeCidade': 'Conceição do Pará',
          },
          {
            'id_cidade': 3117702,
            'nomeCidade': 'Conceição do Rio Verde',
          },
          {
            'id_cidade': 3117801,
            'nomeCidade': 'Conceição dos Ouros',
          },
          {
            'id_cidade': 3117836,
            'nomeCidade': 'Cônego Marinho',
          },
          {
            'id_cidade': 3117876,
            'nomeCidade': 'Confins',
          },
          {
            'id_cidade': 3117900,
            'nomeCidade': 'Congonhal',
          },
          {
            'id_cidade': 3118007,
            'nomeCidade': 'Congonhas',
          },
          {
            'id_cidade': 3118106,
            'nomeCidade': 'Congonhas do Norte',
          },
          {
            'id_cidade': 3118205,
            'nomeCidade': 'Conquista',
          },
          {
            'id_cidade': 3118304,
            'nomeCidade': 'Conselheiro Lafaiete',
          },
          {
            'id_cidade': 3118403,
            'nomeCidade': 'Conselheiro Pena',
          },
          {
            'id_cidade': 3118502,
            'nomeCidade': 'Consolação',
          },
          {
            'id_cidade': 3118601,
            'nomeCidade': 'Contagem',
          },
          {
            'id_cidade': 3118700,
            'nomeCidade': 'Coqueiral',
          },
          {
            'id_cidade': 3118809,
            'nomeCidade': 'Coração de Jesus',
          },
          {
            'id_cidade': 3118908,
            'nomeCidade': 'Cordisburgo',
          },
          {
            'id_cidade': 3119005,
            'nomeCidade': 'Cordislândia',
          },
          {
            'id_cidade': 3119104,
            'nomeCidade': 'Corinto',
          },
          {
            'id_cidade': 3119203,
            'nomeCidade': 'Coroaci',
          },
          {
            'id_cidade': 3119302,
            'nomeCidade': 'Coromandel',
          },
          {
            'id_cidade': 3119401,
            'nomeCidade': 'Coronel Fabriciano',
          },
          {
            'id_cidade': 3119500,
            'nomeCidade': 'Coronel Murta',
          },
          {
            'id_cidade': 3119609,
            'nomeCidade': 'Coronel Pacheco',
          },
          {
            'id_cidade': 3119708,
            'nomeCidade': 'Coronel Xavier Chaves',
          },
          {
            'id_cidade': 3119807,
            'nomeCidade': 'Córrego Danta',
          },
          {
            'id_cidade': 3119906,
            'nomeCidade': 'Córrego do Bom Jesus',
          },
          {
            'id_cidade': 3119955,
            'nomeCidade': 'Córrego Fundo',
          },
          {
            'id_cidade': 3120003,
            'nomeCidade': 'Córrego Novo',
          },
          {
            'id_cidade': 3120102,
            'nomeCidade': 'Couto de Magalhães de Minas',
          },
          {
            'id_cidade': 3120151,
            'nomeCidade': 'Crisólita',
          },
          {
            'id_cidade': 3120201,
            'nomeCidade': 'Cristais',
          },
          {
            'id_cidade': 3120300,
            'nomeCidade': 'Cristália',
          },
          {
            'id_cidade': 3120409,
            'nomeCidade': 'Cristiano Otoni',
          },
          {
            'id_cidade': 3120508,
            'nomeCidade': 'Cristina',
          },
          {
            'id_cidade': 3120607,
            'nomeCidade': 'Crucilândia',
          },
          {
            'id_cidade': 3120706,
            'nomeCidade': 'Cruzeiro da Fortaleza',
          },
          {
            'id_cidade': 3120805,
            'nomeCidade': 'Cruzília',
          },
          {
            'id_cidade': 3120839,
            'nomeCidade': 'Cuparaque',
          },
          {
            'id_cidade': 3120870,
            'nomeCidade': 'Curral de Dentro',
          },
          {
            'id_cidade': 3120904,
            'nomeCidade': 'Curvelo',
          },
          {
            'id_cidade': 3121001,
            'nomeCidade': 'Datas',
          },
          {
            'id_cidade': 3121100,
            'nomeCidade': 'Delfim Moreira',
          },
          {
            'id_cidade': 3121209,
            'nomeCidade': 'Delfinópolis',
          },
          {
            'id_cidade': 3121258,
            'nomeCidade': 'Delta',
          },
          {
            'id_cidade': 3121308,
            'nomeCidade': 'Descoberto',
          },
          {
            'id_cidade': 3121407,
            'nomeCidade': 'Desterro de Entre Rios',
          },
          {
            'id_cidade': 3121506,
            'nomeCidade': 'Desterro do Melo',
          },
          {
            'id_cidade': 3121605,
            'nomeCidade': 'Diamantina',
          },
          {
            'id_cidade': 3121704,
            'nomeCidade': 'Diogo de Vasconcelos',
          },
          {
            'id_cidade': 3121803,
            'nomeCidade': 'Dionísio',
          },
          {
            'id_cidade': 3121902,
            'nomeCidade': 'Divinésia',
          },
          {
            'id_cidade': 3122009,
            'nomeCidade': 'Divino',
          },
          {
            'id_cidade': 3122108,
            'nomeCidade': 'Divino das Laranjeiras',
          },
          {
            'id_cidade': 3122207,
            'nomeCidade': 'Divinolândia de Minas',
          },
          {
            'id_cidade': 3122306,
            'nomeCidade': 'Divinópolis',
          },
          {
            'id_cidade': 3122355,
            'nomeCidade': 'Divisa Alegre',
          },
          {
            'id_cidade': 3122405,
            'nomeCidade': 'Divisa Nova',
          },
          {
            'id_cidade': 3122454,
            'nomeCidade': 'Divisópolis',
          },
          {
            'id_cidade': 3122470,
            'nomeCidade': 'Dom Bosco',
          },
          {
            'id_cidade': 3122504,
            'nomeCidade': 'Dom Cavati',
          },
          {
            'id_cidade': 3122603,
            'nomeCidade': 'Dom Joaquim',
          },
          {
            'id_cidade': 3122702,
            'nomeCidade': 'Dom Silvério',
          },
          {
            'id_cidade': 3122801,
            'nomeCidade': 'Dom Viçoso',
          },
          {
            'id_cidade': 3122900,
            'nomeCidade': 'Dona Eusébia',
          },
          {
            'id_cidade': 3123007,
            'nomeCidade': 'Dores de Campos',
          },
          {
            'id_cidade': 3123106,
            'nomeCidade': 'Dores de Guanhães',
          },
          {
            'id_cidade': 3123205,
            'nomeCidade': 'Dores do Indaiá',
          },
          {
            'id_cidade': 3123304,
            'nomeCidade': 'Dores do Turvo',
          },
          {
            'id_cidade': 3123403,
            'nomeCidade': 'Doresópolis',
          },
          {
            'id_cidade': 3123502,
            'nomeCidade': 'Douradoquara',
          },
          {
            'id_cidade': 3123528,
            'nomeCidade': 'Durandé',
          },
          {
            'id_cidade': 3123601,
            'nomeCidade': 'Elói Mendes',
          },
          {
            'id_cidade': 3123700,
            'nomeCidade': 'Engenheiro Caldas',
          },
          {
            'id_cidade': 3123809,
            'nomeCidade': 'Engenheiro Navarro',
          },
          {
            'id_cidade': 3123858,
            'nomeCidade': 'Entre Folhas',
          },
          {
            'id_cidade': 3123908,
            'nomeCidade': 'Entre Rios de Minas',
          },
          {
            'id_cidade': 3124005,
            'nomeCidade': 'Ervália',
          },
          {
            'id_cidade': 3124104,
            'nomeCidade': 'Esmeraldas',
          },
          {
            'id_cidade': 3124203,
            'nomeCidade': 'Espera Feliz',
          },
          {
            'id_cidade': 3124302,
            'nomeCidade': 'Espinosa',
          },
          {
            'id_cidade': 3124401,
            'nomeCidade': 'Espírito Santo do Dourado',
          },
          {
            'id_cidade': 3124500,
            'nomeCidade': 'Estiva',
          },
          {
            'id_cidade': 3124609,
            'nomeCidade': 'Estrela Dalva',
          },
          {
            'id_cidade': 3124708,
            'nomeCidade': 'Estrela do Indaiá',
          },
          {
            'id_cidade': 3124807,
            'nomeCidade': 'Estrela do Sul',
          },
          {
            'id_cidade': 3124906,
            'nomeCidade': 'Eugenópolis',
          },
          {
            'id_cidade': 3125002,
            'nomeCidade': 'Ewbank da Câmara',
          },
          {
            'id_cidade': 3125101,
            'nomeCidade': 'Extrema',
          },
          {
            'id_cidade': 3125200,
            'nomeCidade': 'Fama',
          },
          {
            'id_cidade': 3125309,
            'nomeCidade': 'Faria Lemos',
          },
          {
            'id_cidade': 3125408,
            'nomeCidade': 'Felício dos Santos',
          },
          {
            'id_cidade': 3125606,
            'nomeCidade': 'Felisburgo',
          },
          {
            'id_cidade': 3125705,
            'nomeCidade': 'Felixlândia',
          },
          {
            'id_cidade': 3125804,
            'nomeCidade': 'Fernandes Tourinho',
          },
          {
            'id_cidade': 3125903,
            'nomeCidade': 'Ferros',
          },
          {
            'id_cidade': 3125952,
            'nomeCidade': 'Fervedouro',
          },
          {
            'id_cidade': 3126000,
            'nomeCidade': 'Florestal',
          },
          {
            'id_cidade': 3126109,
            'nomeCidade': 'Formiga',
          },
          {
            'id_cidade': 3126208,
            'nomeCidade': 'Formoso',
          },
          {
            'id_cidade': 3126307,
            'nomeCidade': 'Fortaleza de Minas',
          },
          {
            'id_cidade': 3126406,
            'nomeCidade': 'Fortuna de Minas',
          },
          {
            'id_cidade': 3126505,
            'nomeCidade': 'Francisco Badaró',
          },
          {
            'id_cidade': 3126604,
            'nomeCidade': 'Francisco Dumont',
          },
          {
            'id_cidade': 3126703,
            'nomeCidade': 'Francisco Sá',
          },
          {
            'id_cidade': 3126752,
            'nomeCidade': 'Franciscópolis',
          },
          {
            'id_cidade': 3126802,
            'nomeCidade': 'Frei Gaspar',
          },
          {
            'id_cidade': 3126901,
            'nomeCidade': 'Frei Inocêncio',
          },
          {
            'id_cidade': 3126950,
            'nomeCidade': 'Frei Lagonegro',
          },
          {
            'id_cidade': 3127008,
            'nomeCidade': 'Fronteira',
          },
          {
            'id_cidade': 3127057,
            'nomeCidade': 'Fronteira dos Vales',
          },
          {
            'id_cidade': 3127073,
            'nomeCidade': 'Fruta de Leite',
          },
          {
            'id_cidade': 3127107,
            'nomeCidade': 'Frutal',
          },
          {
            'id_cidade': 3127206,
            'nomeCidade': 'Funilândia',
          },
          {
            'id_cidade': 3127305,
            'nomeCidade': 'Galiléia',
          },
          {
            'id_cidade': 3127339,
            'nomeCidade': 'Gameleiras',
          },
          {
            'id_cidade': 3127354,
            'nomeCidade': 'Glaucilândia',
          },
          {
            'id_cidade': 3127370,
            'nomeCidade': 'Goiabeira',
          },
          {
            'id_cidade': 3127388,
            'nomeCidade': 'Goianá',
          },
          {
            'id_cidade': 3127404,
            'nomeCidade': 'Gonçalves',
          },
          {
            'id_cidade': 3127503,
            'nomeCidade': 'Gonzaga',
          },
          {
            'id_cidade': 3127602,
            'nomeCidade': 'Gouveia',
          },
          {
            'id_cidade': 3127701,
            'nomeCidade': 'Governador Valadares',
          },
          {
            'id_cidade': 3127800,
            'nomeCidade': 'Grão Mogol',
          },
          {
            'id_cidade': 3127909,
            'nomeCidade': 'Grupiara',
          },
          {
            'id_cidade': 3128006,
            'nomeCidade': 'Guanhães',
          },
          {
            'id_cidade': 3128105,
            'nomeCidade': 'Guapé',
          },
          {
            'id_cidade': 3128204,
            'nomeCidade': 'Guaraciaba',
          },
          {
            'id_cidade': 3128253,
            'nomeCidade': 'Guaraciama',
          },
          {
            'id_cidade': 3128303,
            'nomeCidade': 'Guaranésia',
          },
          {
            'id_cidade': 3128402,
            'nomeCidade': 'Guarani',
          },
          {
            'id_cidade': 3128501,
            'nomeCidade': 'Guarará',
          },
          {
            'id_cidade': 3128600,
            'nomeCidade': 'Guarda-Mor',
          },
          {
            'id_cidade': 3128709,
            'nomeCidade': 'Guaxupé',
          },
          {
            'id_cidade': 3128808,
            'nomeCidade': 'Guidoval',
          },
          {
            'id_cidade': 3128907,
            'nomeCidade': 'Guimarânia',
          },
          {
            'id_cidade': 3129004,
            'nomeCidade': 'Guiricema',
          },
          {
            'id_cidade': 3129103,
            'nomeCidade': 'Gurinhatã',
          },
          {
            'id_cidade': 3129202,
            'nomeCidade': 'Heliodora',
          },
          {
            'id_cidade': 3129301,
            'nomeCidade': 'Iapu',
          },
          {
            'id_cidade': 3129400,
            'nomeCidade': 'Ibertioga',
          },
          {
            'id_cidade': 3129509,
            'nomeCidade': 'Ibiá',
          },
          {
            'id_cidade': 3129608,
            'nomeCidade': 'Ibiaí',
          },
          {
            'id_cidade': 3129657,
            'nomeCidade': 'Ibiracatu',
          },
          {
            'id_cidade': 3129707,
            'nomeCidade': 'Ibiraci',
          },
          {
            'id_cidade': 3129806,
            'nomeCidade': 'Ibirité',
          },
          {
            'id_cidade': 3129905,
            'nomeCidade': 'Ibitiúra de Minas',
          },
          {
            'id_cidade': 3130002,
            'nomeCidade': 'Ibituruna',
          },
          {
            'id_cidade': 3130051,
            'nomeCidade': 'Icaraí de Minas',
          },
          {
            'id_cidade': 3130101,
            'nomeCidade': 'Igarapé',
          },
          {
            'id_cidade': 3130200,
            'nomeCidade': 'Igaratinga',
          },
          {
            'id_cidade': 3130309,
            'nomeCidade': 'Iguatama',
          },
          {
            'id_cidade': 3130408,
            'nomeCidade': 'Ijaci',
          },
          {
            'id_cidade': 3130507,
            'nomeCidade': 'Ilicínea',
          },
          {
            'id_cidade': 3130556,
            'nomeCidade': 'Imbé de Minas',
          },
          {
            'id_cidade': 3130606,
            'nomeCidade': 'Inconfidentes',
          },
          {
            'id_cidade': 3130655,
            'nomeCidade': 'Indaiabira',
          },
          {
            'id_cidade': 3130705,
            'nomeCidade': 'Indianópolis',
          },
          {
            'id_cidade': 3130804,
            'nomeCidade': 'Ingaí',
          },
          {
            'id_cidade': 3130903,
            'nomeCidade': 'Inhapim',
          },
          {
            'id_cidade': 3131000,
            'nomeCidade': 'Inhaúma',
          },
          {
            'id_cidade': 3131109,
            'nomeCidade': 'Inimutaba',
          },
          {
            'id_cidade': 3131158,
            'nomeCidade': 'Ipaba',
          },
          {
            'id_cidade': 3131208,
            'nomeCidade': 'Ipanema',
          },
          {
            'id_cidade': 3131307,
            'nomeCidade': 'Ipatinga',
          },
          {
            'id_cidade': 3131406,
            'nomeCidade': 'Ipiaçu',
          },
          {
            'id_cidade': 3131505,
            'nomeCidade': 'Ipuiúna',
          },
          {
            'id_cidade': 3131604,
            'nomeCidade': 'Iraí de Minas',
          },
          {
            'id_cidade': 3131703,
            'nomeCidade': 'Itabira',
          },
          {
            'id_cidade': 3131802,
            'nomeCidade': 'Itabirinha',
          },
          {
            'id_cidade': 3131901,
            'nomeCidade': 'Itabirito',
          },
          {
            'id_cidade': 3132008,
            'nomeCidade': 'Itacambira',
          },
          {
            'id_cidade': 3132107,
            'nomeCidade': 'Itacarambi',
          },
          {
            'id_cidade': 3132206,
            'nomeCidade': 'Itaguara',
          },
          {
            'id_cidade': 3132305,
            'nomeCidade': 'Itaipé',
          },
          {
            'id_cidade': 3132404,
            'nomeCidade': 'Itajubá',
          },
          {
            'id_cidade': 3132503,
            'nomeCidade': 'Itamarandiba',
          },
          {
            'id_cidade': 3132602,
            'nomeCidade': 'Itamarati de Minas',
          },
          {
            'id_cidade': 3132701,
            'nomeCidade': 'Itambacuri',
          },
          {
            'id_cidade': 3132800,
            'nomeCidade': 'Itambé do Mato Dentro',
          },
          {
            'id_cidade': 3132909,
            'nomeCidade': 'Itamogi',
          },
          {
            'id_cidade': 3133006,
            'nomeCidade': 'Itamonte',
          },
          {
            'id_cidade': 3133105,
            'nomeCidade': 'Itanhandu',
          },
          {
            'id_cidade': 3133204,
            'nomeCidade': 'Itanhomi',
          },
          {
            'id_cidade': 3133303,
            'nomeCidade': 'Itaobim',
          },
          {
            'id_cidade': 3133402,
            'nomeCidade': 'Itapagipe',
          },
          {
            'id_cidade': 3133501,
            'nomeCidade': 'Itapecerica',
          },
          {
            'id_cidade': 3133600,
            'nomeCidade': 'Itapeva',
          },
          {
            'id_cidade': 3133709,
            'nomeCidade': 'Itatiaiuçu',
          },
          {
            'id_cidade': 3133758,
            'nomeCidade': 'Itaú de Minas',
          },
          {
            'id_cidade': 3133808,
            'nomeCidade': 'Itaúna',
          },
          {
            'id_cidade': 3133907,
            'nomeCidade': 'Itaverava',
          },
          {
            'id_cidade': 3134004,
            'nomeCidade': 'Itinga',
          },
          {
            'id_cidade': 3134103,
            'nomeCidade': 'Itueta',
          },
          {
            'id_cidade': 3134202,
            'nomeCidade': 'Ituiutaba',
          },
          {
            'id_cidade': 3134301,
            'nomeCidade': 'Itumirim',
          },
          {
            'id_cidade': 3134400,
            'nomeCidade': 'Iturama',
          },
          {
            'id_cidade': 3134509,
            'nomeCidade': 'Itutinga',
          },
          {
            'id_cidade': 3134608,
            'nomeCidade': 'Jaboticatubas',
          },
          {
            'id_cidade': 3134707,
            'nomeCidade': 'Jacinto',
          },
          {
            'id_cidade': 3134806,
            'nomeCidade': 'Jacuí',
          },
          {
            'id_cidade': 3134905,
            'nomeCidade': 'Jacutinga',
          },
          {
            'id_cidade': 3135001,
            'nomeCidade': 'Jaguaraçu',
          },
          {
            'id_cidade': 3135050,
            'nomeCidade': 'Jaíba',
          },
          {
            'id_cidade': 3135076,
            'nomeCidade': 'Jampruca',
          },
          {
            'id_cidade': 3135100,
            'nomeCidade': 'Janaúba',
          },
          {
            'id_cidade': 3135209,
            'nomeCidade': 'Januária',
          },
          {
            'id_cidade': 3135308,
            'nomeCidade': 'Japaraíba',
          },
          {
            'id_cidade': 3135357,
            'nomeCidade': 'Japonvar',
          },
          {
            'id_cidade': 3135407,
            'nomeCidade': 'Jeceaba',
          },
          {
            'id_cidade': 3135456,
            'nomeCidade': 'Jenipapo de Minas',
          },
          {
            'id_cidade': 3135506,
            'nomeCidade': 'Jequeri',
          },
          {
            'id_cidade': 3135605,
            'nomeCidade': 'Jequitaí',
          },
          {
            'id_cidade': 3135704,
            'nomeCidade': 'Jequitibá',
          },
          {
            'id_cidade': 3135803,
            'nomeCidade': 'Jequitinhonha',
          },
          {
            'id_cidade': 3135902,
            'nomeCidade': 'Jesuânia',
          },
          {
            'id_cidade': 3136009,
            'nomeCidade': 'Joaíma',
          },
          {
            'id_cidade': 3136108,
            'nomeCidade': 'Joanésia',
          },
          {
            'id_cidade': 3136207,
            'nomeCidade': 'João Monlevade',
          },
          {
            'id_cidade': 3136306,
            'nomeCidade': 'João Pinheiro',
          },
          {
            'id_cidade': 3136405,
            'nomeCidade': 'Joaquim Felício',
          },
          {
            'id_cidade': 3136504,
            'nomeCidade': 'Jordânia',
          },
          {
            'id_cidade': 3136520,
            'nomeCidade': 'José Gonçalves de Minas',
          },
          {
            'id_cidade': 3136553,
            'nomeCidade': 'José Raydan',
          },
          {
            'id_cidade': 3136579,
            'nomeCidade': 'Josenópolis',
          },
          {
            'id_cidade': 3136652,
            'nomeCidade': 'Juatuba',
          },
          {
            'id_cidade': 3136702,
            'nomeCidade': 'Juiz de Fora',
          },
          {
            'id_cidade': 3136801,
            'nomeCidade': 'Juramento',
          },
          {
            'id_cidade': 3136900,
            'nomeCidade': 'Juruaia',
          },
          {
            'id_cidade': 3136959,
            'nomeCidade': 'Juvenília',
          },
          {
            'id_cidade': 3137007,
            'nomeCidade': 'Ladainha',
          },
          {
            'id_cidade': 3137106,
            'nomeCidade': 'Lagamar',
          },
          {
            'id_cidade': 3137205,
            'nomeCidade': 'Lagoa da Prata',
          },
          {
            'id_cidade': 3137304,
            'nomeCidade': 'Lagoa dos Patos',
          },
          {
            'id_cidade': 3137403,
            'nomeCidade': 'Lagoa Dourada',
          },
          {
            'id_cidade': 3137502,
            'nomeCidade': 'Lagoa Formosa',
          },
          {
            'id_cidade': 3137536,
            'nomeCidade': 'Lagoa Grande',
          },
          {
            'id_cidade': 3137601,
            'nomeCidade': 'Lagoa Santa',
          },
          {
            'id_cidade': 3137700,
            'nomeCidade': 'Lajinha',
          },
          {
            'id_cidade': 3137809,
            'nomeCidade': 'Lambari',
          },
          {
            'id_cidade': 3137908,
            'nomeCidade': 'Lamim',
          },
          {
            'id_cidade': 3138005,
            'nomeCidade': 'Laranjal',
          },
          {
            'id_cidade': 3138104,
            'nomeCidade': 'Lassance',
          },
          {
            'id_cidade': 3138203,
            'nomeCidade': 'Lavras',
          },
          {
            'id_cidade': 3138302,
            'nomeCidade': 'Leandro Ferreira',
          },
          {
            'id_cidade': 3138351,
            'nomeCidade': 'Leme do Prado',
          },
          {
            'id_cidade': 3138401,
            'nomeCidade': 'Leopoldina',
          },
          {
            'id_cidade': 3138500,
            'nomeCidade': 'Liberdade',
          },
          {
            'id_cidade': 3138609,
            'nomeCidade': 'Lima Duarte',
          },
          {
            'id_cidade': 3138625,
            'nomeCidade': 'Limeira do Oeste',
          },
          {
            'id_cidade': 3138658,
            'nomeCidade': 'Lontra',
          },
          {
            'id_cidade': 3138674,
            'nomeCidade': 'Luisburgo',
          },
          {
            'id_cidade': 3138682,
            'nomeCidade': 'Luislândia',
          },
          {
            'id_cidade': 3138708,
            'nomeCidade': 'Luminárias',
          },
          {
            'id_cidade': 3138807,
            'nomeCidade': 'Luz',
          },
          {
            'id_cidade': 3138906,
            'nomeCidade': 'Machacalis',
          },
          {
            'id_cidade': 3139003,
            'nomeCidade': 'Machado',
          },
          {
            'id_cidade': 3139102,
            'nomeCidade': 'Madre de Deus de Minas',
          },
          {
            'id_cidade': 3139201,
            'nomeCidade': 'Malacacheta',
          },
          {
            'id_cidade': 3139250,
            'nomeCidade': 'Mamonas',
          },
          {
            'id_cidade': 3139300,
            'nomeCidade': 'Manga',
          },
          {
            'id_cidade': 3139409,
            'nomeCidade': 'Manhuaçu',
          },
          {
            'id_cidade': 3139508,
            'nomeCidade': 'Manhumirim',
          },
          {
            'id_cidade': 3139607,
            'nomeCidade': 'Mantena',
          },
          {
            'id_cidade': 3139805,
            'nomeCidade': 'Mar de Espanha',
          },
          {
            'id_cidade': 3139706,
            'nomeCidade': 'Maravilhas',
          },
          {
            'id_cidade': 3139904,
            'nomeCidade': 'Maria da Fé',
          },
          {
            'id_cidade': 3140001,
            'nomeCidade': 'Mariana',
          },
          {
            'id_cidade': 3140100,
            'nomeCidade': 'Marilac',
          },
          {
            'id_cidade': 3140159,
            'nomeCidade': 'Mário Campos',
          },
          {
            'id_cidade': 3140209,
            'nomeCidade': 'Maripá de Minas',
          },
          {
            'id_cidade': 3140308,
            'nomeCidade': 'Marliéria',
          },
          {
            'id_cidade': 3140407,
            'nomeCidade': 'Marmelópolis',
          },
          {
            'id_cidade': 3140506,
            'nomeCidade': 'Martinho Campos',
          },
          {
            'id_cidade': 3140530,
            'nomeCidade': 'Martins Soares',
          },
          {
            'id_cidade': 3140555,
            'nomeCidade': 'Mata Verde',
          },
          {
            'id_cidade': 3140605,
            'nomeCidade': 'Materlândia',
          },
          {
            'id_cidade': 3140704,
            'nomeCidade': 'Mateus Leme',
          },
          {
            'id_cidade': 3171501,
            'nomeCidade': 'Mathias Lobato',
          },
          {
            'id_cidade': 3140803,
            'nomeCidade': 'Matias Barbosa',
          },
          {
            'id_cidade': 3140852,
            'nomeCidade': 'Matias Cardoso',
          },
          {
            'id_cidade': 3140902,
            'nomeCidade': 'Matipó',
          },
          {
            'id_cidade': 3141009,
            'nomeCidade': 'Mato Verde',
          },
          {
            'id_cidade': 3141108,
            'nomeCidade': 'Matozinhos',
          },
          {
            'id_cidade': 3141207,
            'nomeCidade': 'Matutina',
          },
          {
            'id_cidade': 3141306,
            'nomeCidade': 'Medeiros',
          },
          {
            'id_cidade': 3141405,
            'nomeCidade': 'Medina',
          },
          {
            'id_cidade': 3141504,
            'nomeCidade': 'Mendes Pimentel',
          },
          {
            'id_cidade': 3141603,
            'nomeCidade': 'Mercês',
          },
          {
            'id_cidade': 3141702,
            'nomeCidade': 'Mesquita',
          },
          {
            'id_cidade': 3141801,
            'nomeCidade': 'Minas Novas',
          },
          {
            'id_cidade': 3141900,
            'nomeCidade': 'Minduri',
          },
          {
            'id_cidade': 3142007,
            'nomeCidade': 'Mirabela',
          },
          {
            'id_cidade': 3142106,
            'nomeCidade': 'Miradouro',
          },
          {
            'id_cidade': 3142205,
            'nomeCidade': 'Miraí',
          },
          {
            'id_cidade': 3142254,
            'nomeCidade': 'Miravânia',
          },
          {
            'id_cidade': 3142304,
            'nomeCidade': 'Moeda',
          },
          {
            'id_cidade': 3142403,
            'nomeCidade': 'Moema',
          },
          {
            'id_cidade': 3142502,
            'nomeCidade': 'Monjolos',
          },
          {
            'id_cidade': 3142601,
            'nomeCidade': 'Monsenhor Paulo',
          },
          {
            'id_cidade': 3142700,
            'nomeCidade': 'Montalvânia',
          },
          {
            'id_cidade': 3142809,
            'nomeCidade': 'Monte Alegre de Minas',
          },
          {
            'id_cidade': 3142908,
            'nomeCidade': 'Monte Azul',
          },
          {
            'id_cidade': 3143005,
            'nomeCidade': 'Monte Belo',
          },
          {
            'id_cidade': 3143104,
            'nomeCidade': 'Monte Carmelo',
          },
          {
            'id_cidade': 3143153,
            'nomeCidade': 'Monte Formoso',
          },
          {
            'id_cidade': 3143203,
            'nomeCidade': 'Monte Santo de Minas',
          },
          {
            'id_cidade': 3143401,
            'nomeCidade': 'Monte Sião',
          },
          {
            'id_cidade': 3143302,
            'nomeCidade': 'Montes Claros',
          },
          {
            'id_cidade': 3143450,
            'nomeCidade': 'Montezuma',
          },
          {
            'id_cidade': 3143500,
            'nomeCidade': 'Morada Nova de Minas',
          },
          {
            'id_cidade': 3143609,
            'nomeCidade': 'Morro da Garça',
          },
          {
            'id_cidade': 3143708,
            'nomeCidade': 'Morro do Pilar',
          },
          {
            'id_cidade': 3143807,
            'nomeCidade': 'Munhoz',
          },
          {
            'id_cidade': 3143906,
            'nomeCidade': 'Muriaé',
          },
          {
            'id_cidade': 3144003,
            'nomeCidade': 'Mutum',
          },
          {
            'id_cidade': 3144102,
            'nomeCidade': 'Muzambinho',
          },
          {
            'id_cidade': 3144201,
            'nomeCidade': 'Nacip Raydan',
          },
          {
            'id_cidade': 3144300,
            'nomeCidade': 'Nanuque',
          },
          {
            'id_cidade': 3144359,
            'nomeCidade': 'Naque',
          },
          {
            'id_cidade': 3144375,
            'nomeCidade': 'Natalândia',
          },
          {
            'id_cidade': 3144409,
            'nomeCidade': 'Natércia',
          },
          {
            'id_cidade': 3144508,
            'nomeCidade': 'Nazareno',
          },
          {
            'id_cidade': 3144607,
            'nomeCidade': 'Nepomuceno',
          },
          {
            'id_cidade': 3144656,
            'nomeCidade': 'Ninheira',
          },
          {
            'id_cidade': 3144672,
            'nomeCidade': 'Nova Belém',
          },
          {
            'id_cidade': 3144706,
            'nomeCidade': 'Nova Era',
          },
          {
            'id_cidade': 3144805,
            'nomeCidade': 'Nova Lima',
          },
          {
            'id_cidade': 3144904,
            'nomeCidade': 'Nova Módica',
          },
          {
            'id_cidade': 3145000,
            'nomeCidade': 'Nova Ponte',
          },
          {
            'id_cidade': 3145059,
            'nomeCidade': 'Nova Porteirinha',
          },
          {
            'id_cidade': 3145109,
            'nomeCidade': 'Nova Resende',
          },
          {
            'id_cidade': 3145208,
            'nomeCidade': 'Nova Serrana',
          },
          {
            'id_cidade': 3136603,
            'nomeCidade': 'Nova União',
          },
          {
            'id_cidade': 3145307,
            'nomeCidade': 'Novo Cruzeiro',
          },
          {
            'id_cidade': 3145356,
            'nomeCidade': 'Novo Oriente de Minas',
          },
          {
            'id_cidade': 3145372,
            'nomeCidade': 'Novorizonte',
          },
          {
            'id_cidade': 3145406,
            'nomeCidade': 'Olaria',
          },
          {
            'id_cidade': 3145455,
            'nomeCidade': "Olhos-d'Água",
          },
          {
            'id_cidade': 3145505,
            'nomeCidade': 'Olímpio Noronha',
          },
          {
            'id_cidade': 3145604,
            'nomeCidade': 'Oliveira',
          },
          {
            'id_cidade': 3145703,
            'nomeCidade': 'Oliveira Fortes',
          },
          {
            'id_cidade': 3145802,
            'nomeCidade': 'Onça de Pitangui',
          },
          {
            'id_cidade': 3145851,
            'nomeCidade': 'Oratórios',
          },
          {
            'id_cidade': 3145877,
            'nomeCidade': 'Orizânia',
          },
          {
            'id_cidade': 3145901,
            'nomeCidade': 'Ouro Branco',
          },
          {
            'id_cidade': 3146008,
            'nomeCidade': 'Ouro Fino',
          },
          {
            'id_cidade': 3146107,
            'nomeCidade': 'Ouro Preto',
          },
          {
            'id_cidade': 3146206,
            'nomeCidade': 'Ouro Verde de Minas',
          },
          {
            'id_cidade': 3146255,
            'nomeCidade': 'Padre Carvalho',
          },
          {
            'id_cidade': 3146305,
            'nomeCidade': 'Padre Paraíso',
          },
          {
            'id_cidade': 3146552,
            'nomeCidade': 'Pai Pedro',
          },
          {
            'id_cidade': 3146404,
            'nomeCidade': 'Paineiras',
          },
          {
            'id_cidade': 3146503,
            'nomeCidade': 'Pains',
          },
          {
            'id_cidade': 3146602,
            'nomeCidade': 'Paiva',
          },
          {
            'id_cidade': 3146701,
            'nomeCidade': 'Palma',
          },
          {
            'id_cidade': 3146750,
            'nomeCidade': 'Palmópolis',
          },
          {
            'id_cidade': 3146909,
            'nomeCidade': 'Papagaios',
          },
          {
            'id_cidade': 3147105,
            'nomeCidade': 'Pará de Minas',
          },
          {
            'id_cidade': 3147006,
            'nomeCidade': 'Paracatu',
          },
          {
            'id_cidade': 3147204,
            'nomeCidade': 'Paraguaçu',
          },
          {
            'id_cidade': 3147303,
            'nomeCidade': 'Paraisópolis',
          },
          {
            'id_cidade': 3147402,
            'nomeCidade': 'Paraopeba',
          },
          {
            'id_cidade': 3147600,
            'nomeCidade': 'Passa Quatro',
          },
          {
            'id_cidade': 3147709,
            'nomeCidade': 'Passa Tempo',
          },
          {
            'id_cidade': 3147808,
            'nomeCidade': 'Passa Vinte',
          },
          {
            'id_cidade': 3147501,
            'nomeCidade': 'Passabém',
          },
          {
            'id_cidade': 3147907,
            'nomeCidade': 'Passos',
          },
          {
            'id_cidade': 3147956,
            'nomeCidade': 'Patis',
          },
          {
            'id_cidade': 3148004,
            'nomeCidade': 'Patos de Minas',
          },
          {
            'id_cidade': 3148103,
            'nomeCidade': 'Patrocínio',
          },
          {
            'id_cidade': 3148202,
            'nomeCidade': 'Patrocínio do Muriaé',
          },
          {
            'id_cidade': 3148301,
            'nomeCidade': 'Paula Cândido',
          },
          {
            'id_cidade': 3148400,
            'nomeCidade': 'Paulistas',
          },
          {
            'id_cidade': 3148509,
            'nomeCidade': 'Pavão',
          },
          {
            'id_cidade': 3148608,
            'nomeCidade': 'Peçanha',
          },
          {
            'id_cidade': 3148707,
            'nomeCidade': 'Pedra Azul',
          },
          {
            'id_cidade': 3148756,
            'nomeCidade': 'Pedra Bonita',
          },
          {
            'id_cidade': 3148806,
            'nomeCidade': 'Pedra do Anta',
          },
          {
            'id_cidade': 3148905,
            'nomeCidade': 'Pedra do Indaiá',
          },
          {
            'id_cidade': 3149002,
            'nomeCidade': 'Pedra Dourada',
          },
          {
            'id_cidade': 3149101,
            'nomeCidade': 'Pedralva',
          },
          {
            'id_cidade': 3149150,
            'nomeCidade': 'Pedras de Maria da Cruz',
          },
          {
            'id_cidade': 3149200,
            'nomeCidade': 'Pedrinópolis',
          },
          {
            'id_cidade': 3149309,
            'nomeCidade': 'Pedro Leopoldo',
          },
          {
            'id_cidade': 3149408,
            'nomeCidade': 'Pedro Teixeira',
          },
          {
            'id_cidade': 3149507,
            'nomeCidade': 'Pequeri',
          },
          {
            'id_cidade': 3149606,
            'nomeCidade': 'Pequi',
          },
          {
            'id_cidade': 3149705,
            'nomeCidade': 'Perdigão',
          },
          {
            'id_cidade': 3149804,
            'nomeCidade': 'Perdizes',
          },
          {
            'id_cidade': 3149903,
            'nomeCidade': 'Perdões',
          },
          {
            'id_cidade': 3149952,
            'nomeCidade': 'Periquito',
          },
          {
            'id_cidade': 3150000,
            'nomeCidade': 'Pescador',
          },
          {
            'id_cidade': 3150109,
            'nomeCidade': 'Piau',
          },
          {
            'id_cidade': 3150158,
            'nomeCidade': 'Piedade de Caratinga',
          },
          {
            'id_cidade': 3150208,
            'nomeCidade': 'Piedade de Ponte Nova',
          },
          {
            'id_cidade': 3150307,
            'nomeCidade': 'Piedade do Rio Grande',
          },
          {
            'id_cidade': 3150406,
            'nomeCidade': 'Piedade dos Gerais',
          },
          {
            'id_cidade': 3150505,
            'nomeCidade': 'Pimenta',
          },
          {
            'id_cidade': 3150539,
            'nomeCidade': "Pingo d'Água",
          },
          {
            'id_cidade': 3150570,
            'nomeCidade': 'Pintópolis',
          },
          {
            'id_cidade': 3150604,
            'nomeCidade': 'Piracema',
          },
          {
            'id_cidade': 3150703,
            'nomeCidade': 'Pirajuba',
          },
          {
            'id_cidade': 3150802,
            'nomeCidade': 'Piranga',
          },
          {
            'id_cidade': 3150901,
            'nomeCidade': 'Piranguçu',
          },
          {
            'id_cidade': 3151008,
            'nomeCidade': 'Piranguinho',
          },
          {
            'id_cidade': 3151107,
            'nomeCidade': 'Pirapetinga',
          },
          {
            'id_cidade': 3151206,
            'nomeCidade': 'Pirapora',
          },
          {
            'id_cidade': 3151305,
            'nomeCidade': 'Piraúba',
          },
          {
            'id_cidade': 3151404,
            'nomeCidade': 'Pitangui',
          },
          {
            'id_cidade': 3151503,
            'nomeCidade': 'Piumhi',
          },
          {
            'id_cidade': 3151602,
            'nomeCidade': 'Planura',
          },
          {
            'id_cidade': 3151701,
            'nomeCidade': 'Poço Fundo',
          },
          {
            'id_cidade': 3151800,
            'nomeCidade': 'Poços de Caldas',
          },
          {
            'id_cidade': 3151909,
            'nomeCidade': 'Pocrane',
          },
          {
            'id_cidade': 3152006,
            'nomeCidade': 'Pompéu',
          },
          {
            'id_cidade': 3152105,
            'nomeCidade': 'Ponte Nova',
          },
          {
            'id_cidade': 3152131,
            'nomeCidade': 'Ponto Chique',
          },
          {
            'id_cidade': 3152170,
            'nomeCidade': 'Ponto dos Volantes',
          },
          {
            'id_cidade': 3152204,
            'nomeCidade': 'Porteirinha',
          },
          {
            'id_cidade': 3152303,
            'nomeCidade': 'Porto Firme',
          },
          {
            'id_cidade': 3152402,
            'nomeCidade': 'Poté',
          },
          {
            'id_cidade': 3152501,
            'nomeCidade': 'Pouso Alegre',
          },
          {
            'id_cidade': 3152600,
            'nomeCidade': 'Pouso Alto',
          },
          {
            'id_cidade': 3152709,
            'nomeCidade': 'Prados',
          },
          {
            'id_cidade': 3152808,
            'nomeCidade': 'Prata',
          },
          {
            'id_cidade': 3152907,
            'nomeCidade': 'Pratápolis',
          },
          {
            'id_cidade': 3153004,
            'nomeCidade': 'Pratinha',
          },
          {
            'id_cidade': 3153103,
            'nomeCidade': 'Presidente Bernardes',
          },
          {
            'id_cidade': 3153202,
            'nomeCidade': 'Presidente Juscelino',
          },
          {
            'id_cidade': 3153301,
            'nomeCidade': 'Presidente Kubitschek',
          },
          {
            'id_cidade': 3153400,
            'nomeCidade': 'Presidente Olegário',
          },
          {
            'id_cidade': 3153608,
            'nomeCidade': 'Prudente de Morais',
          },
          {
            'id_cidade': 3153707,
            'nomeCidade': 'Quartel Geral',
          },
          {
            'id_cidade': 3153806,
            'nomeCidade': 'Queluzito',
          },
          {
            'id_cidade': 3153905,
            'nomeCidade': 'Raposos',
          },
          {
            'id_cidade': 3154002,
            'nomeCidade': 'Raul Soares',
          },
          {
            'id_cidade': 3154101,
            'nomeCidade': 'Recreio',
          },
          {
            'id_cidade': 3154150,
            'nomeCidade': 'Reduto',
          },
          {
            'id_cidade': 3154200,
            'nomeCidade': 'Resende Costa',
          },
          {
            'id_cidade': 3154309,
            'nomeCidade': 'Resplendor',
          },
          {
            'id_cidade': 3154408,
            'nomeCidade': 'Ressaquinha',
          },
          {
            'id_cidade': 3154457,
            'nomeCidade': 'Riachinho',
          },
          {
            'id_cidade': 3154507,
            'nomeCidade': 'Riacho dos Machados',
          },
          {
            'id_cidade': 3154606,
            'nomeCidade': 'Ribeirão das Neves',
          },
          {
            'id_cidade': 3154705,
            'nomeCidade': 'Ribeirão Vermelho',
          },
          {
            'id_cidade': 3154804,
            'nomeCidade': 'Rio Acima',
          },
          {
            'id_cidade': 3154903,
            'nomeCidade': 'Rio Casca',
          },
          {
            'id_cidade': 3155108,
            'nomeCidade': 'Rio do Prado',
          },
          {
            'id_cidade': 3155009,
            'nomeCidade': 'Rio Doce',
          },
          {
            'id_cidade': 3155207,
            'nomeCidade': 'Rio Espera',
          },
          {
            'id_cidade': 3155306,
            'nomeCidade': 'Rio Manso',
          },
          {
            'id_cidade': 3155405,
            'nomeCidade': 'Rio Novo',
          },
          {
            'id_cidade': 3155504,
            'nomeCidade': 'Rio Paranaíba',
          },
          {
            'id_cidade': 3155603,
            'nomeCidade': 'Rio Pardo de Minas',
          },
          {
            'id_cidade': 3155702,
            'nomeCidade': 'Rio Piracicaba',
          },
          {
            'id_cidade': 3155801,
            'nomeCidade': 'Rio Pomba',
          },
          {
            'id_cidade': 3155900,
            'nomeCidade': 'Rio Preto',
          },
          {
            'id_cidade': 3156007,
            'nomeCidade': 'Rio Vermelho',
          },
          {
            'id_cidade': 3156106,
            'nomeCidade': 'Ritápolis',
          },
          {
            'id_cidade': 3156205,
            'nomeCidade': 'Rochedo de Minas',
          },
          {
            'id_cidade': 3156304,
            'nomeCidade': 'Rodeiro',
          },
          {
            'id_cidade': 3156403,
            'nomeCidade': 'Romaria',
          },
          {
            'id_cidade': 3156452,
            'nomeCidade': 'Rosário da Limeira',
          },
          {
            'id_cidade': 3156502,
            'nomeCidade': 'Rubelita',
          },
          {
            'id_cidade': 3156601,
            'nomeCidade': 'Rubim',
          },
          {
            'id_cidade': 3156700,
            'nomeCidade': 'Sabará',
          },
          {
            'id_cidade': 3156809,
            'nomeCidade': 'Sabinópolis',
          },
          {
            'id_cidade': 3156908,
            'nomeCidade': 'Sacramento',
          },
          {
            'id_cidade': 3157005,
            'nomeCidade': 'Salinas',
          },
          {
            'id_cidade': 3157104,
            'nomeCidade': 'Salto da Divisa',
          },
          {
            'id_cidade': 3157203,
            'nomeCidade': 'Santa Bárbara',
          },
          {
            'id_cidade': 3157252,
            'nomeCidade': 'Santa Bárbara do Leste',
          },
          {
            'id_cidade': 3157278,
            'nomeCidade': 'Santa Bárbara do Monte Verde',
          },
          {
            'id_cidade': 3157302,
            'nomeCidade': 'Santa Bárbara do Tugúrio',
          },
          {
            'id_cidade': 3157336,
            'nomeCidade': 'Santa Cruz de Minas',
          },
          {
            'id_cidade': 3157377,
            'nomeCidade': 'Santa Cruz de Salinas',
          },
          {
            'id_cidade': 3157401,
            'nomeCidade': 'Santa Cruz do Escalvado',
          },
          {
            'id_cidade': 3157500,
            'nomeCidade': 'Santa Efigênia de Minas',
          },
          {
            'id_cidade': 3157609,
            'nomeCidade': 'Santa Fé de Minas',
          },
          {
            'id_cidade': 3157658,
            'nomeCidade': 'Santa Helena de Minas',
          },
          {
            'id_cidade': 3157708,
            'nomeCidade': 'Santa Juliana',
          },
          {
            'id_cidade': 3157807,
            'nomeCidade': 'Santa Luzia',
          },
          {
            'id_cidade': 3157906,
            'nomeCidade': 'Santa Margarida',
          },
          {
            'id_cidade': 3158003,
            'nomeCidade': 'Santa Maria de Itabira',
          },
          {
            'id_cidade': 3158102,
            'nomeCidade': 'Santa Maria do Salto',
          },
          {
            'id_cidade': 3158201,
            'nomeCidade': 'Santa Maria do Suaçuí',
          },
          {
            'id_cidade': 3159209,
            'nomeCidade': 'Santa Rita de Caldas',
          },
          {
            'id_cidade': 3159407,
            'nomeCidade': 'Santa Rita de Ibitipoca',
          },
          {
            'id_cidade': 3159308,
            'nomeCidade': 'Santa Rita de Jacutinga',
          },
          {
            'id_cidade': 3159357,
            'nomeCidade': 'Santa Rita de Minas',
          },
          {
            'id_cidade': 3159506,
            'nomeCidade': 'Santa Rita do Itueto',
          },
          {
            'id_cidade': 3159605,
            'nomeCidade': 'Santa Rita do Sapucaí',
          },
          {
            'id_cidade': 3159704,
            'nomeCidade': 'Santa Rosa da Serra',
          },
          {
            'id_cidade': 3159803,
            'nomeCidade': 'Santa Vitória',
          },
          {
            'id_cidade': 3158300,
            'nomeCidade': 'Santana da Vargem',
          },
          {
            'id_cidade': 3158409,
            'nomeCidade': 'Santana de Cataguases',
          },
          {
            'id_cidade': 3158508,
            'nomeCidade': 'Santana de Pirapama',
          },
          {
            'id_cidade': 3158607,
            'nomeCidade': 'Santana do Deserto',
          },
          {
            'id_cidade': 3158706,
            'nomeCidade': 'Santana do Garambéu',
          },
          {
            'id_cidade': 3158805,
            'nomeCidade': 'Santana do Jacaré',
          },
          {
            'id_cidade': 3158904,
            'nomeCidade': 'Santana do Manhuaçu',
          },
          {
            'id_cidade': 3158953,
            'nomeCidade': 'Santana do Paraíso',
          },
          {
            'id_cidade': 3159001,
            'nomeCidade': 'Santana do Riacho',
          },
          {
            'id_cidade': 3159100,
            'nomeCidade': 'Santana dos Montes',
          },
          {
            'id_cidade': 3159902,
            'nomeCidade': 'Santo Antônio do Amparo',
          },
          {
            'id_cidade': 3160009,
            'nomeCidade': 'Santo Antônio do Aventureiro',
          },
          {
            'id_cidade': 3160108,
            'nomeCidade': 'Santo Antônio do Grama',
          },
          {
            'id_cidade': 3160207,
            'nomeCidade': 'Santo Antônio do Itambé',
          },
          {
            'id_cidade': 3160306,
            'nomeCidade': 'Santo Antônio do Jacinto',
          },
          {
            'id_cidade': 3160405,
            'nomeCidade': 'Santo Antônio do Monte',
          },
          {
            'id_cidade': 3160454,
            'nomeCidade': 'Santo Antônio do Retiro',
          },
          {
            'id_cidade': 3160504,
            'nomeCidade': 'Santo Antônio do Rio Abaixo',
          },
          {
            'id_cidade': 3160603,
            'nomeCidade': 'Santo Hipólito',
          },
          {
            'id_cidade': 3160702,
            'nomeCidade': 'Santos Dumont',
          },
          {
            'id_cidade': 3160801,
            'nomeCidade': 'São Bento Abade',
          },
          {
            'id_cidade': 3160900,
            'nomeCidade': 'São Brás do Suaçuí',
          },
          {
            'id_cidade': 3160959,
            'nomeCidade': 'São Domingos das Dores',
          },
          {
            'id_cidade': 3161007,
            'nomeCidade': 'São Domingos do Prata',
          },
          {
            'id_cidade': 3161056,
            'nomeCidade': 'São Félix de Minas',
          },
          {
            'id_cidade': 3161106,
            'nomeCidade': 'São Francisco',
          },
          {
            'id_cidade': 3161205,
            'nomeCidade': 'São Francisco de Paula',
          },
          {
            'id_cidade': 3161304,
            'nomeCidade': 'São Francisco de Sales',
          },
          {
            'id_cidade': 3161403,
            'nomeCidade': 'São Francisco do Glória',
          },
          {
            'id_cidade': 3161502,
            'nomeCidade': 'São Geraldo',
          },
          {
            'id_cidade': 3161601,
            'nomeCidade': 'São Geraldo da Piedade',
          },
          {
            'id_cidade': 3161650,
            'nomeCidade': 'São Geraldo do Baixio',
          },
          {
            'id_cidade': 3161700,
            'nomeCidade': 'São Gonçalo do Abaeté',
          },
          {
            'id_cidade': 3161809,
            'nomeCidade': 'São Gonçalo do Pará',
          },
          {
            'id_cidade': 3161908,
            'nomeCidade': 'São Gonçalo do Rio Abaixo',
          },
          {
            'id_cidade': 3125507,
            'nomeCidade': 'São Gonçalo do Rio Preto',
          },
          {
            'id_cidade': 3162005,
            'nomeCidade': 'São Gonçalo do Sapucaí',
          },
          {
            'id_cidade': 3162104,
            'nomeCidade': 'São Gotardo',
          },
          {
            'id_cidade': 3162203,
            'nomeCidade': 'São João Batista do Glória',
          },
          {
            'id_cidade': 3162252,
            'nomeCidade': 'São João da Lagoa',
          },
          {
            'id_cidade': 3162302,
            'nomeCidade': 'São João da Mata',
          },
          {
            'id_cidade': 3162401,
            'nomeCidade': 'São João da Ponte',
          },
          {
            'id_cidade': 3162450,
            'nomeCidade': 'São João das Missões',
          },
          {
            'id_cidade': 3162500,
            'nomeCidade': 'São João del Rei',
          },
          {
            'id_cidade': 3162559,
            'nomeCidade': 'São João do Manhuaçu',
          },
          {
            'id_cidade': 3162575,
            'nomeCidade': 'São João do Manteninha',
          },
          {
            'id_cidade': 3162609,
            'nomeCidade': 'São João do Oriente',
          },
          {
            'id_cidade': 3162658,
            'nomeCidade': 'São João do Pacuí',
          },
          {
            'id_cidade': 3162708,
            'nomeCidade': 'São João do Paraíso',
          },
          {
            'id_cidade': 3162807,
            'nomeCidade': 'São João Evangelista',
          },
          {
            'id_cidade': 3162906,
            'nomeCidade': 'São João Nepomuceno',
          },
          {
            'id_cidade': 3162922,
            'nomeCidade': 'São Joaquim de Bicas',
          },
          {
            'id_cidade': 3162948,
            'nomeCidade': 'São José da Barra',
          },
          {
            'id_cidade': 3162955,
            'nomeCidade': 'São José da Lapa',
          },
          {
            'id_cidade': 3163003,
            'nomeCidade': 'São José da Safira',
          },
          {
            'id_cidade': 3163102,
            'nomeCidade': 'São José da Varginha',
          },
          {
            'id_cidade': 3163201,
            'nomeCidade': 'São José do Alegre',
          },
          {
            'id_cidade': 3163300,
            'nomeCidade': 'São José do Divino',
          },
          {
            'id_cidade': 3163409,
            'nomeCidade': 'São José do Goiabal',
          },
          {
            'id_cidade': 3163508,
            'nomeCidade': 'São José do Jacuri',
          },
          {
            'id_cidade': 3163607,
            'nomeCidade': 'São José do Mantimento',
          },
          {
            'id_cidade': 3163706,
            'nomeCidade': 'São Lourenço',
          },
          {
            'id_cidade': 3163805,
            'nomeCidade': 'São Miguel do Anta',
          },
          {
            'id_cidade': 3163904,
            'nomeCidade': 'São Pedro da União',
          },
          {
            'id_cidade': 3164100,
            'nomeCidade': 'São Pedro do Suaçuí',
          },
          {
            'id_cidade': 3164001,
            'nomeCidade': 'São Pedro dos Ferros',
          },
          {
            'id_cidade': 3164209,
            'nomeCidade': 'São Romão',
          },
          {
            'id_cidade': 3164308,
            'nomeCidade': 'São Roque de Minas',
          },
          {
            'id_cidade': 3164407,
            'nomeCidade': 'São Sebastião da Bela Vista',
          },
          {
            'id_cidade': 3164431,
            'nomeCidade': 'São Sebastião da Vargem Alegre',
          },
          {
            'id_cidade': 3164472,
            'nomeCidade': 'São Sebastião do Anta',
          },
          {
            'id_cidade': 3164506,
            'nomeCidade': 'São Sebastião do Maranhão',
          },
          {
            'id_cidade': 3164605,
            'nomeCidade': 'São Sebastião do Oeste',
          },
          {
            'id_cidade': 3164704,
            'nomeCidade': 'São Sebastião do Paraíso',
          },
          {
            'id_cidade': 3164803,
            'nomeCidade': 'São Sebastião do Rio Preto',
          },
          {
            'id_cidade': 3164902,
            'nomeCidade': 'São Sebastião do Rio Verde',
          },
          {
            'id_cidade': 3165206,
            'nomeCidade': 'São Thomé das Letras',
          },
          {
            'id_cidade': 3165008,
            'nomeCidade': 'São Tiago',
          },
          {
            'id_cidade': 3165107,
            'nomeCidade': 'São Tomás de Aquino',
          },
          {
            'id_cidade': 3165305,
            'nomeCidade': 'São Vicente de Minas',
          },
          {
            'id_cidade': 3165404,
            'nomeCidade': 'Sapucaí-Mirim',
          },
          {
            'id_cidade': 3165503,
            'nomeCidade': 'Sardoá',
          },
          {
            'id_cidade': 3165537,
            'nomeCidade': 'Sarzedo',
          },
          {
            'id_cidade': 3165560,
            'nomeCidade': 'Sem-Peixe',
          },
          {
            'id_cidade': 3165578,
            'nomeCidade': 'Senador Amaral',
          },
          {
            'id_cidade': 3165602,
            'nomeCidade': 'Senador Cortes',
          },
          {
            'id_cidade': 3165701,
            'nomeCidade': 'Senador Firmino',
          },
          {
            'id_cidade': 3165800,
            'nomeCidade': 'Senador José Bento',
          },
          {
            'id_cidade': 3165909,
            'nomeCidade': 'Senador Modestino Gonçalves',
          },
          {
            'id_cidade': 3166006,
            'nomeCidade': 'Senhora de Oliveira',
          },
          {
            'id_cidade': 3166105,
            'nomeCidade': 'Senhora do Porto',
          },
          {
            'id_cidade': 3166204,
            'nomeCidade': 'Senhora dos Remédios',
          },
          {
            'id_cidade': 3166303,
            'nomeCidade': 'Sericita',
          },
          {
            'id_cidade': 3166402,
            'nomeCidade': 'Seritinga',
          },
          {
            'id_cidade': 3166501,
            'nomeCidade': 'Serra Azul de Minas',
          },
          {
            'id_cidade': 3166600,
            'nomeCidade': 'Serra da Saudade',
          },
          {
            'id_cidade': 3166808,
            'nomeCidade': 'Serra do Salitre',
          },
          {
            'id_cidade': 3166709,
            'nomeCidade': 'Serra dos Aimorés',
          },
          {
            'id_cidade': 3166907,
            'nomeCidade': 'Serrania',
          },
          {
            'id_cidade': 3166956,
            'nomeCidade': 'Serranópolis de Minas',
          },
          {
            'id_cidade': 3167004,
            'nomeCidade': 'Serranos',
          },
          {
            'id_cidade': 3167103,
            'nomeCidade': 'Serro',
          },
          {
            'id_cidade': 3167202,
            'nomeCidade': 'Sete Lagoas',
          },
          {
            'id_cidade': 3165552,
            'nomeCidade': 'Setubinha',
          },
          {
            'id_cidade': 3167301,
            'nomeCidade': 'Silveirânia',
          },
          {
            'id_cidade': 3167400,
            'nomeCidade': 'Silvianópolis',
          },
          {
            'id_cidade': 3167509,
            'nomeCidade': 'Simão Pereira',
          },
          {
            'id_cidade': 3167608,
            'nomeCidade': 'Simonésia',
          },
          {
            'id_cidade': 3167707,
            'nomeCidade': 'Sobrália',
          },
          {
            'id_cidade': 3167806,
            'nomeCidade': 'Soledade de Minas',
          },
          {
            'id_cidade': 3167905,
            'nomeCidade': 'Tabuleiro',
          },
          {
            'id_cidade': 3168002,
            'nomeCidade': 'Taiobeiras',
          },
          {
            'id_cidade': 3168051,
            'nomeCidade': 'Taparuba',
          },
          {
            'id_cidade': 3168101,
            'nomeCidade': 'Tapira',
          },
          {
            'id_cidade': 3168200,
            'nomeCidade': 'Tapiraí',
          },
          {
            'id_cidade': 3168309,
            'nomeCidade': 'Taquaraçu de Minas',
          },
          {
            'id_cidade': 3168408,
            'nomeCidade': 'Tarumirim',
          },
          {
            'id_cidade': 3168507,
            'nomeCidade': 'Teixeiras',
          },
          {
            'id_cidade': 3168606,
            'nomeCidade': 'Teófilo Otoni',
          },
          {
            'id_cidade': 3168705,
            'nomeCidade': 'Timóteo',
          },
          {
            'id_cidade': 3168804,
            'nomeCidade': 'Tiradentes',
          },
          {
            'id_cidade': 3168903,
            'nomeCidade': 'Tiros',
          },
          {
            'id_cidade': 3169000,
            'nomeCidade': 'Tocantins',
          },
          {
            'id_cidade': 3169059,
            'nomeCidade': 'Tocos do Moji',
          },
          {
            'id_cidade': 3169109,
            'nomeCidade': 'Toledo',
          },
          {
            'id_cidade': 3169208,
            'nomeCidade': 'Tombos',
          },
          {
            'id_cidade': 3169307,
            'nomeCidade': 'Três Corações',
          },
          {
            'id_cidade': 3169356,
            'nomeCidade': 'Três Marias',
          },
          {
            'id_cidade': 3169406,
            'nomeCidade': 'Três Pontas',
          },
          {
            'id_cidade': 3169505,
            'nomeCidade': 'Tumiritinga',
          },
          {
            'id_cidade': 3169604,
            'nomeCidade': 'Tupaciguara',
          },
          {
            'id_cidade': 3169703,
            'nomeCidade': 'Turmalina',
          },
          {
            'id_cidade': 3169802,
            'nomeCidade': 'Turvolândia',
          },
          {
            'id_cidade': 3169901,
            'nomeCidade': 'Ubá',
          },
          {
            'id_cidade': 3170008,
            'nomeCidade': 'Ubaí',
          },
          {
            'id_cidade': 3170057,
            'nomeCidade': 'Ubaporanga',
          },
          {
            'id_cidade': 3170107,
            'nomeCidade': 'Uberaba',
          },
          {
            'id_cidade': 3170206,
            'nomeCidade': 'Uberlândia',
          },
          {
            'id_cidade': 3170305,
            'nomeCidade': 'Umburatiba',
          },
          {
            'id_cidade': 3170404,
            'nomeCidade': 'Unaí',
          },
          {
            'id_cidade': 3170438,
            'nomeCidade': 'União de Minas',
          },
          {
            'id_cidade': 3170479,
            'nomeCidade': 'Uruana de Minas',
          },
          {
            'id_cidade': 3170503,
            'nomeCidade': 'Urucânia',
          },
          {
            'id_cidade': 3170529,
            'nomeCidade': 'Urucuia',
          },
          {
            'id_cidade': 3170578,
            'nomeCidade': 'Vargem Alegre',
          },
          {
            'id_cidade': 3170602,
            'nomeCidade': 'Vargem Bonita',
          },
          {
            'id_cidade': 3170651,
            'nomeCidade': 'Vargem Grande do Rio Pardo',
          },
          {
            'id_cidade': 3170701,
            'nomeCidade': 'Varginha',
          },
          {
            'id_cidade': 3170750,
            'nomeCidade': 'Varjão de Minas',
          },
          {
            'id_cidade': 3170800,
            'nomeCidade': 'Várzea da Palma',
          },
          {
            'id_cidade': 3170909,
            'nomeCidade': 'Varzelândia',
          },
          {
            'id_cidade': 3171006,
            'nomeCidade': 'Vazante',
          },
          {
            'id_cidade': 3171030,
            'nomeCidade': 'Verdelândia',
          },
          {
            'id_cidade': 3171071,
            'nomeCidade': 'Veredinha',
          },
          {
            'id_cidade': 3171105,
            'nomeCidade': 'Veríssimo',
          },
          {
            'id_cidade': 3171154,
            'nomeCidade': 'Vermelho Novo',
          },
          {
            'id_cidade': 3171204,
            'nomeCidade': 'Vespasiano',
          },
          {
            'id_cidade': 3171303,
            'nomeCidade': 'Viçosa',
          },
          {
            'id_cidade': 3171402,
            'nomeCidade': 'Vieiras',
          },
          {
            'id_cidade': 3171600,
            'nomeCidade': 'Virgem da Lapa',
          },
          {
            'id_cidade': 3171709,
            'nomeCidade': 'Virgínia',
          },
          {
            'id_cidade': 3171808,
            'nomeCidade': 'Virginópolis',
          },
          {
            'id_cidade': 3171907,
            'nomeCidade': 'Virgolândia',
          },
          {
            'id_cidade': 3172004,
            'nomeCidade': 'Visconde do Rio Branco',
          },
          {
            'id_cidade': 3172103,
            'nomeCidade': 'Volta Grande',
          },
          {
            'id_cidade': 3172202,
            'nomeCidade': 'Wenceslau Braz',
          },
        ],
      },
      {
        'id': 15,
        'nomeEstado': 'Pará',
        'sigla': 'PA',
        'cidades': [
          {
            'id_cidade': 1500107,
            'nomeCidade': 'Abaetetuba',
          },
          {
            'id_cidade': 1500131,
            'nomeCidade': 'Abel Figueiredo',
          },
          {
            'id_cidade': 1500206,
            'nomeCidade': 'Acará',
          },
          {
            'id_cidade': 1500305,
            'nomeCidade': 'Afuá',
          },
          {
            'id_cidade': 1500347,
            'nomeCidade': 'Água Azul do Norte',
          },
          {
            'id_cidade': 1500404,
            'nomeCidade': 'Alenquer',
          },
          {
            'id_cidade': 1500503,
            'nomeCidade': 'Almeirim',
          },
          {
            'id_cidade': 1500602,
            'nomeCidade': 'Altamira',
          },
          {
            'id_cidade': 1500701,
            'nomeCidade': 'Anajás',
          },
          {
            'id_cidade': 1500800,
            'nomeCidade': 'Ananindeua',
          },
          {
            'id_cidade': 1500859,
            'nomeCidade': 'Anapu',
          },
          {
            'id_cidade': 1500909,
            'nomeCidade': 'Augusto Corrêa',
          },
          {
            'id_cidade': 1500958,
            'nomeCidade': 'Aurora do Pará',
          },
          {
            'id_cidade': 1501006,
            'nomeCidade': 'Aveiro',
          },
          {
            'id_cidade': 1501105,
            'nomeCidade': 'Bagre',
          },
          {
            'id_cidade': 1501204,
            'nomeCidade': 'Baião',
          },
          {
            'id_cidade': 1501253,
            'nomeCidade': 'Bannach',
          },
          {
            'id_cidade': 1501303,
            'nomeCidade': 'Barcarena',
          },
          {
            'id_cidade': 1501402,
            'nomeCidade': 'Belém',
          },
          {
            'id_cidade': 1501451,
            'nomeCidade': 'Belterra',
          },
          {
            'id_cidade': 1501501,
            'nomeCidade': 'Benevides',
          },
          {
            'id_cidade': 1501576,
            'nomeCidade': 'Bom Jesus do Tocantins',
          },
          {
            'id_cidade': 1501600,
            'nomeCidade': 'Bonito',
          },
          {
            'id_cidade': 1501709,
            'nomeCidade': 'Bragança',
          },
          {
            'id_cidade': 1501725,
            'nomeCidade': 'Brasil Novo',
          },
          {
            'id_cidade': 1501758,
            'nomeCidade': 'Brejo Grande do Araguaia',
          },
          {
            'id_cidade': 1501782,
            'nomeCidade': 'Breu Branco',
          },
          {
            'id_cidade': 1501808,
            'nomeCidade': 'Breves',
          },
          {
            'id_cidade': 1501907,
            'nomeCidade': 'Bujaru',
          },
          {
            'id_cidade': 1502004,
            'nomeCidade': 'Cachoeira do Arari',
          },
          {
            'id_cidade': 1501956,
            'nomeCidade': 'Cachoeira do Piriá',
          },
          {
            'id_cidade': 1502103,
            'nomeCidade': 'Cametá',
          },
          {
            'id_cidade': 1502152,
            'nomeCidade': 'Canaã dos Carajás',
          },
          {
            'id_cidade': 1502202,
            'nomeCidade': 'Capanema',
          },
          {
            'id_cidade': 1502301,
            'nomeCidade': 'Capitão Poço',
          },
          {
            'id_cidade': 1502400,
            'nomeCidade': 'Castanhal',
          },
          {
            'id_cidade': 1502509,
            'nomeCidade': 'Chaves',
          },
          {
            'id_cidade': 1502608,
            'nomeCidade': 'Colares',
          },
          {
            'id_cidade': 1502707,
            'nomeCidade': 'Conceição do Araguaia',
          },
          {
            'id_cidade': 1502756,
            'nomeCidade': 'Concórdia do Pará',
          },
          {
            'id_cidade': 1502764,
            'nomeCidade': 'Cumaru do Norte',
          },
          {
            'id_cidade': 1502772,
            'nomeCidade': 'Curionópolis',
          },
          {
            'id_cidade': 1502806,
            'nomeCidade': 'Curralinho',
          },
          {
            'id_cidade': 1502855,
            'nomeCidade': 'Curuá',
          },
          {
            'id_cidade': 1502905,
            'nomeCidade': 'Curuçá',
          },
          {
            'id_cidade': 1502939,
            'nomeCidade': 'Dom Eliseu',
          },
          {
            'id_cidade': 1502954,
            'nomeCidade': 'Eldorado do Carajás',
          },
          {
            'id_cidade': 1503002,
            'nomeCidade': 'Faro',
          },
          {
            'id_cidade': 1503044,
            'nomeCidade': 'Floresta do Araguaia',
          },
          {
            'id_cidade': 1503077,
            'nomeCidade': 'Garrafão do Norte',
          },
          {
            'id_cidade': 1503093,
            'nomeCidade': 'Goianésia do Pará',
          },
          {
            'id_cidade': 1503101,
            'nomeCidade': 'Gurupá',
          },
          {
            'id_cidade': 1503200,
            'nomeCidade': 'Igarapé-Açu',
          },
          {
            'id_cidade': 1503309,
            'nomeCidade': 'Igarapé-Miri',
          },
          {
            'id_cidade': 1503408,
            'nomeCidade': 'Inhangapi',
          },
          {
            'id_cidade': 1503457,
            'nomeCidade': 'Ipixuna do Pará',
          },
          {
            'id_cidade': 1503507,
            'nomeCidade': 'Irituia',
          },
          {
            'id_cidade': 1503606,
            'nomeCidade': 'Itaituba',
          },
          {
            'id_cidade': 1503705,
            'nomeCidade': 'Itupiranga',
          },
          {
            'id_cidade': 1503754,
            'nomeCidade': 'Jacareacanga',
          },
          {
            'id_cidade': 1503804,
            'nomeCidade': 'Jacundá',
          },
          {
            'id_cidade': 1503903,
            'nomeCidade': 'Juruti',
          },
          {
            'id_cidade': 1504000,
            'nomeCidade': 'Limoeiro do Ajuru',
          },
          {
            'id_cidade': 1504059,
            'nomeCidade': 'Mãe do Rio',
          },
          {
            'id_cidade': 1504109,
            'nomeCidade': 'Magalhães Barata',
          },
          {
            'id_cidade': 1504208,
            'nomeCidade': 'Marabá',
          },
          {
            'id_cidade': 1504307,
            'nomeCidade': 'Maracanã',
          },
          {
            'id_cidade': 1504406,
            'nomeCidade': 'Marapanim',
          },
          {
            'id_cidade': 1504422,
            'nomeCidade': 'Marituba',
          },
          {
            'id_cidade': 1504455,
            'nomeCidade': 'Medicilândia',
          },
          {
            'id_cidade': 1504505,
            'nomeCidade': 'Melgaço',
          },
          {
            'id_cidade': 1504604,
            'nomeCidade': 'Mocajuba',
          },
          {
            'id_cidade': 1504703,
            'nomeCidade': 'Moju',
          },
          {
            'id_cidade': 1504752,
            'nomeCidade': 'Mojuí dos Campos',
          },
          {
            'id_cidade': 1504802,
            'nomeCidade': 'Monte Alegre',
          },
          {
            'id_cidade': 1504901,
            'nomeCidade': 'Muaná',
          },
          {
            'id_cidade': 1504950,
            'nomeCidade': 'Nova Esperança do Piriá',
          },
          {
            'id_cidade': 1504976,
            'nomeCidade': 'Nova Ipixuna',
          },
          {
            'id_cidade': 1505007,
            'nomeCidade': 'Nova Timboteua',
          },
          {
            'id_cidade': 1505031,
            'nomeCidade': 'Novo Progresso',
          },
          {
            'id_cidade': 1505064,
            'nomeCidade': 'Novo Repartimento',
          },
          {
            'id_cidade': 1505106,
            'nomeCidade': 'Óbidos',
          },
          {
            'id_cidade': 1505205,
            'nomeCidade': 'Oeiras do Pará',
          },
          {
            'id_cidade': 1505304,
            'nomeCidade': 'Oriximiná',
          },
          {
            'id_cidade': 1505403,
            'nomeCidade': 'Ourém',
          },
          {
            'id_cidade': 1505437,
            'nomeCidade': 'Ourilândia do Norte',
          },
          {
            'id_cidade': 1505486,
            'nomeCidade': 'Pacajá',
          },
          {
            'id_cidade': 1505494,
            'nomeCidade': 'Palestina do Pará',
          },
          {
            'id_cidade': 1505502,
            'nomeCidade': 'Paragominas',
          },
          {
            'id_cidade': 1505536,
            'nomeCidade': 'Parauapebas',
          },
          {
            'id_cidade': 1505551,
            'nomeCidade': "Pau D'Arco",
          },
          {
            'id_cidade': 1505601,
            'nomeCidade': 'Peixe-Boi',
          },
          {
            'id_cidade': 1505635,
            'nomeCidade': 'Piçarra',
          },
          {
            'id_cidade': 1505650,
            'nomeCidade': 'Placas',
          },
          {
            'id_cidade': 1505700,
            'nomeCidade': 'Ponta de Pedras',
          },
          {
            'id_cidade': 1505809,
            'nomeCidade': 'Portel',
          },
          {
            'id_cidade': 1505908,
            'nomeCidade': 'Porto de Moz',
          },
          {
            'id_cidade': 1506005,
            'nomeCidade': 'Prainha',
          },
          {
            'id_cidade': 1506104,
            'nomeCidade': 'Primavera',
          },
          {
            'id_cidade': 1506112,
            'nomeCidade': 'Quatipuru',
          },
          {
            'id_cidade': 1506138,
            'nomeCidade': 'Redenção',
          },
          {
            'id_cidade': 1506161,
            'nomeCidade': 'Rio Maria',
          },
          {
            'id_cidade': 1506187,
            'nomeCidade': 'Rondon do Pará',
          },
          {
            'id_cidade': 1506195,
            'nomeCidade': 'Rurópolis',
          },
          {
            'id_cidade': 1506203,
            'nomeCidade': 'Salinópolis',
          },
          {
            'id_cidade': 1506302,
            'nomeCidade': 'Salvaterra',
          },
          {
            'id_cidade': 1506351,
            'nomeCidade': 'Santa Bárbara do Pará',
          },
          {
            'id_cidade': 1506401,
            'nomeCidade': 'Santa Cruz do Arari',
          },
          {
            'id_cidade': 1506500,
            'nomeCidade': 'Santa Izabel do Pará',
          },
          {
            'id_cidade': 1506559,
            'nomeCidade': 'Santa Luzia do Pará',
          },
          {
            'id_cidade': 1506583,
            'nomeCidade': 'Santa Maria das Barreiras',
          },
          {
            'id_cidade': 1506609,
            'nomeCidade': 'Santa Maria do Pará',
          },
          {
            'id_cidade': 1506708,
            'nomeCidade': 'Santana do Araguaia',
          },
          {
            'id_cidade': 1506807,
            'nomeCidade': 'Santarém',
          },
          {
            'id_cidade': 1506906,
            'nomeCidade': 'Santarém Novo',
          },
          {
            'id_cidade': 1507003,
            'nomeCidade': 'Santo Antônio do Tauá',
          },
          {
            'id_cidade': 1507102,
            'nomeCidade': 'São Caetano de Odivelas',
          },
          {
            'id_cidade': 1507151,
            'nomeCidade': 'São Domingos do Araguaia',
          },
          {
            'id_cidade': 1507201,
            'nomeCidade': 'São Domingos do Capim',
          },
          {
            'id_cidade': 1507300,
            'nomeCidade': 'São Félix do Xingu',
          },
          {
            'id_cidade': 1507409,
            'nomeCidade': 'São Francisco do Pará',
          },
          {
            'id_cidade': 1507458,
            'nomeCidade': 'São Geraldo do Araguaia',
          },
          {
            'id_cidade': 1507466,
            'nomeCidade': 'São João da Ponta',
          },
          {
            'id_cidade': 1507474,
            'nomeCidade': 'São João de Pirabas',
          },
          {
            'id_cidade': 1507508,
            'nomeCidade': 'São João do Araguaia',
          },
          {
            'id_cidade': 1507607,
            'nomeCidade': 'São Miguel do Guamá',
          },
          {
            'id_cidade': 1507706,
            'nomeCidade': 'São Sebastião da Boa Vista',
          },
          {
            'id_cidade': 1507755,
            'nomeCidade': 'Sapucaia',
          },
          {
            'id_cidade': 1507805,
            'nomeCidade': 'Senador José Porfírio',
          },
          {
            'id_cidade': 1507904,
            'nomeCidade': 'Soure',
          },
          {
            'id_cidade': 1507953,
            'nomeCidade': 'Tailândia',
          },
          {
            'id_cidade': 1507961,
            'nomeCidade': 'Terra Alta',
          },
          {
            'id_cidade': 1507979,
            'nomeCidade': 'Terra Santa',
          },
          {
            'id_cidade': 1508001,
            'nomeCidade': 'Tomé-Açu',
          },
          {
            'id_cidade': 1508035,
            'nomeCidade': 'Tracuateua',
          },
          {
            'id_cidade': 1508050,
            'nomeCidade': 'Trairão',
          },
          {
            'id_cidade': 1508084,
            'nomeCidade': 'Tucumã',
          },
          {
            'id_cidade': 1508100,
            'nomeCidade': 'Tucuruí',
          },
          {
            'id_cidade': 1508126,
            'nomeCidade': 'Ulianópolis',
          },
          {
            'id_cidade': 1508159,
            'nomeCidade': 'Uruará',
          },
          {
            'id_cidade': 1508209,
            'nomeCidade': 'Vigia',
          },
          {
            'id_cidade': 1508308,
            'nomeCidade': 'Viseu',
          },
          {
            'id_cidade': 1508357,
            'nomeCidade': 'Vitória do Xingu',
          },
          {
            'id_cidade': 1508407,
            'nomeCidade': 'Xinguara',
          },
        ],
      },
      {
        'id': 25,
        'nomeEstado': 'Paraíba',
        'sigla': 'PB',
        'cidades': [
          {
            'id_cidade': 2500106,
            'nomeCidade': 'Água Branca',
          },
          {
            'id_cidade': 2500205,
            'nomeCidade': 'Aguiar',
          },
          {
            'id_cidade': 2500304,
            'nomeCidade': 'Alagoa Grande',
          },
          {
            'id_cidade': 2500403,
            'nomeCidade': 'Alagoa Nova',
          },
          {
            'id_cidade': 2500502,
            'nomeCidade': 'Alagoinha',
          },
          {
            'id_cidade': 2500536,
            'nomeCidade': 'Alcantil',
          },
          {
            'id_cidade': 2500577,
            'nomeCidade': 'Algodão de Jandaíra',
          },
          {
            'id_cidade': 2500601,
            'nomeCidade': 'Alhandra',
          },
          {
            'id_cidade': 2500734,
            'nomeCidade': 'Amparo',
          },
          {
            'id_cidade': 2500775,
            'nomeCidade': 'Aparecida',
          },
          {
            'id_cidade': 2500809,
            'nomeCidade': 'Araçagi',
          },
          {
            'id_cidade': 2500908,
            'nomeCidade': 'Arara',
          },
          {
            'id_cidade': 2501005,
            'nomeCidade': 'Araruna',
          },
          {
            'id_cidade': 2501104,
            'nomeCidade': 'Areia',
          },
          {
            'id_cidade': 2501153,
            'nomeCidade': 'Areia de Baraúnas',
          },
          {
            'id_cidade': 2501203,
            'nomeCidade': 'Areial',
          },
          {
            'id_cidade': 2501302,
            'nomeCidade': 'Aroeiras',
          },
          {
            'id_cidade': 2501351,
            'nomeCidade': 'Assunção',
          },
          {
            'id_cidade': 2501401,
            'nomeCidade': 'Baía da Traição',
          },
          {
            'id_cidade': 2501500,
            'nomeCidade': 'Bananeiras',
          },
          {
            'id_cidade': 2501534,
            'nomeCidade': 'Baraúna',
          },
          {
            'id_cidade': 2501609,
            'nomeCidade': 'Barra de Santa Rosa',
          },
          {
            'id_cidade': 2501575,
            'nomeCidade': 'Barra de Santana',
          },
          {
            'id_cidade': 2501708,
            'nomeCidade': 'Barra de São Miguel',
          },
          {
            'id_cidade': 2501807,
            'nomeCidade': 'Bayeux',
          },
          {
            'id_cidade': 2501906,
            'nomeCidade': 'Belém',
          },
          {
            'id_cidade': 2502003,
            'nomeCidade': 'Belém do Brejo do Cruz',
          },
          {
            'id_cidade': 2502052,
            'nomeCidade': 'Bernardino Batista',
          },
          {
            'id_cidade': 2502102,
            'nomeCidade': 'Boa Ventura',
          },
          {
            'id_cidade': 2502151,
            'nomeCidade': 'Boa Vista',
          },
          {
            'id_cidade': 2502201,
            'nomeCidade': 'Bom Jesus',
          },
          {
            'id_cidade': 2502300,
            'nomeCidade': 'Bom Sucesso',
          },
          {
            'id_cidade': 2502409,
            'nomeCidade': 'Bonito de Santa Fé',
          },
          {
            'id_cidade': 2502508,
            'nomeCidade': 'Boqueirão',
          },
          {
            'id_cidade': 2502706,
            'nomeCidade': 'Borborema',
          },
          {
            'id_cidade': 2502805,
            'nomeCidade': 'Brejo do Cruz',
          },
          {
            'id_cidade': 2502904,
            'nomeCidade': 'Brejo dos Santos',
          },
          {
            'id_cidade': 2503001,
            'nomeCidade': 'Caaporã',
          },
          {
            'id_cidade': 2503100,
            'nomeCidade': 'Cabaceiras',
          },
          {
            'id_cidade': 2503209,
            'nomeCidade': 'Cabedelo',
          },
          {
            'id_cidade': 2503308,
            'nomeCidade': 'Cachoeira dos Índios',
          },
          {
            'id_cidade': 2503407,
            'nomeCidade': 'Cacimba de Areia',
          },
          {
            'id_cidade': 2503506,
            'nomeCidade': 'Cacimba de Dentro',
          },
          {
            'id_cidade': 2503555,
            'nomeCidade': 'Cacimbas',
          },
          {
            'id_cidade': 2503605,
            'nomeCidade': 'Caiçara',
          },
          {
            'id_cidade': 2503704,
            'nomeCidade': 'Cajazeiras',
          },
          {
            'id_cidade': 2503753,
            'nomeCidade': 'Cajazeirinhas',
          },
          {
            'id_cidade': 2503803,
            'nomeCidade': 'Caldas Brandão',
          },
          {
            'id_cidade': 2503902,
            'nomeCidade': 'Camalaú',
          },
          {
            'id_cidade': 2504009,
            'nomeCidade': 'Campina Grande',
          },
          {
            'id_cidade': 2504033,
            'nomeCidade': 'Capim',
          },
          {
            'id_cidade': 2504074,
            'nomeCidade': 'Caraúbas',
          },
          {
            'id_cidade': 2504108,
            'nomeCidade': 'Carrapateira',
          },
          {
            'id_cidade': 2504157,
            'nomeCidade': 'Casserengue',
          },
          {
            'id_cidade': 2504207,
            'nomeCidade': 'Catingueira',
          },
          {
            'id_cidade': 2504306,
            'nomeCidade': 'Catolé do Rocha',
          },
          {
            'id_cidade': 2504355,
            'nomeCidade': 'Caturité',
          },
          {
            'id_cidade': 2504405,
            'nomeCidade': 'Conceição',
          },
          {
            'id_cidade': 2504504,
            'nomeCidade': 'Condado',
          },
          {
            'id_cidade': 2504603,
            'nomeCidade': 'Conde',
          },
          {
            'id_cidade': 2504702,
            'nomeCidade': 'Congo',
          },
          {
            'id_cidade': 2504801,
            'nomeCidade': 'Coremas',
          },
          {
            'id_cidade': 2504850,
            'nomeCidade': 'Coxixola',
          },
          {
            'id_cidade': 2504900,
            'nomeCidade': 'Cruz do Espírito Santo',
          },
          {
            'id_cidade': 2505006,
            'nomeCidade': 'Cubati',
          },
          {
            'id_cidade': 2505105,
            'nomeCidade': 'Cuité',
          },
          {
            'id_cidade': 2505238,
            'nomeCidade': 'Cuité de Mamanguape',
          },
          {
            'id_cidade': 2505204,
            'nomeCidade': 'Cuitegi',
          },
          {
            'id_cidade': 2505279,
            'nomeCidade': 'Curral de Cima',
          },
          {
            'id_cidade': 2505303,
            'nomeCidade': 'Curral Velho',
          },
          {
            'id_cidade': 2505352,
            'nomeCidade': 'Damião',
          },
          {
            'id_cidade': 2505402,
            'nomeCidade': 'Desterro',
          },
          {
            'id_cidade': 2505600,
            'nomeCidade': 'Diamante',
          },
          {
            'id_cidade': 2505709,
            'nomeCidade': 'Dona Inês',
          },
          {
            'id_cidade': 2505808,
            'nomeCidade': 'Duas Estradas',
          },
          {
            'id_cidade': 2505907,
            'nomeCidade': 'Emas',
          },
          {
            'id_cidade': 2506004,
            'nomeCidade': 'Esperança',
          },
          {
            'id_cidade': 2506103,
            'nomeCidade': 'Fagundes',
          },
          {
            'id_cidade': 2506202,
            'nomeCidade': 'Frei Martinho',
          },
          {
            'id_cidade': 2506251,
            'nomeCidade': 'Gado Bravo',
          },
          {
            'id_cidade': 2506301,
            'nomeCidade': 'Guarabira',
          },
          {
            'id_cidade': 2506400,
            'nomeCidade': 'Gurinhém',
          },
          {
            'id_cidade': 2506509,
            'nomeCidade': 'Gurjão',
          },
          {
            'id_cidade': 2506608,
            'nomeCidade': 'Ibiara',
          },
          {
            'id_cidade': 2502607,
            'nomeCidade': 'Igaracy',
          },
          {
            'id_cidade': 2506707,
            'nomeCidade': 'Imaculada',
          },
          {
            'id_cidade': 2506806,
            'nomeCidade': 'Ingá',
          },
          {
            'id_cidade': 2506905,
            'nomeCidade': 'Itabaiana',
          },
          {
            'id_cidade': 2507002,
            'nomeCidade': 'Itaporanga',
          },
          {
            'id_cidade': 2507101,
            'nomeCidade': 'Itapororoca',
          },
          {
            'id_cidade': 2507200,
            'nomeCidade': 'Itatuba',
          },
          {
            'id_cidade': 2507309,
            'nomeCidade': 'Jacaraú',
          },
          {
            'id_cidade': 2507408,
            'nomeCidade': 'Jericó',
          },
          {
            'id_cidade': 2507507,
            'nomeCidade': 'João Pessoa',
          },
          {
            'id_cidade': 2513653,
            'nomeCidade': 'Joca Claudino',
          },
          {
            'id_cidade': 2507606,
            'nomeCidade': 'Juarez Távora',
          },
          {
            'id_cidade': 2507705,
            'nomeCidade': 'Juazeirinho',
          },
          {
            'id_cidade': 2507804,
            'nomeCidade': 'Junco do Seridó',
          },
          {
            'id_cidade': 2507903,
            'nomeCidade': 'Juripiranga',
          },
          {
            'id_cidade': 2508000,
            'nomeCidade': 'Juru',
          },
          {
            'id_cidade': 2508109,
            'nomeCidade': 'Lagoa',
          },
          {
            'id_cidade': 2508208,
            'nomeCidade': 'Lagoa de Dentro',
          },
          {
            'id_cidade': 2508307,
            'nomeCidade': 'Lagoa Seca',
          },
          {
            'id_cidade': 2508406,
            'nomeCidade': 'Lastro',
          },
          {
            'id_cidade': 2508505,
            'nomeCidade': 'Livramento',
          },
          {
            'id_cidade': 2508554,
            'nomeCidade': 'Logradouro',
          },
          {
            'id_cidade': 2508604,
            'nomeCidade': 'Lucena',
          },
          {
            'id_cidade': 2508703,
            'nomeCidade': "Mãe d'Água",
          },
          {
            'id_cidade': 2508802,
            'nomeCidade': 'Malta',
          },
          {
            'id_cidade': 2508901,
            'nomeCidade': 'Mamanguape',
          },
          {
            'id_cidade': 2509008,
            'nomeCidade': 'Manaíra',
          },
          {
            'id_cidade': 2509057,
            'nomeCidade': 'Marcação',
          },
          {
            'id_cidade': 2509107,
            'nomeCidade': 'Mari',
          },
          {
            'id_cidade': 2509156,
            'nomeCidade': 'Marizópolis',
          },
          {
            'id_cidade': 2509206,
            'nomeCidade': 'Massaranduba',
          },
          {
            'id_cidade': 2509305,
            'nomeCidade': 'Mataraca',
          },
          {
            'id_cidade': 2509339,
            'nomeCidade': 'Matinhas',
          },
          {
            'id_cidade': 2509370,
            'nomeCidade': 'Mato Grosso',
          },
          {
            'id_cidade': 2509396,
            'nomeCidade': 'Maturéia',
          },
          {
            'id_cidade': 2509404,
            'nomeCidade': 'Mogeiro',
          },
          {
            'id_cidade': 2509503,
            'nomeCidade': 'Montadas',
          },
          {
            'id_cidade': 2509602,
            'nomeCidade': 'Monte Horebe',
          },
          {
            'id_cidade': 2509701,
            'nomeCidade': 'Monteiro',
          },
          {
            'id_cidade': 2509800,
            'nomeCidade': 'Mulungu',
          },
          {
            'id_cidade': 2509909,
            'nomeCidade': 'Natuba',
          },
          {
            'id_cidade': 2510006,
            'nomeCidade': 'Nazarezinho',
          },
          {
            'id_cidade': 2510105,
            'nomeCidade': 'Nova Floresta',
          },
          {
            'id_cidade': 2510204,
            'nomeCidade': 'Nova Olinda',
          },
          {
            'id_cidade': 2510303,
            'nomeCidade': 'Nova Palmeira',
          },
          {
            'id_cidade': 2510402,
            'nomeCidade': "Olho d'Água",
          },
          {
            'id_cidade': 2510501,
            'nomeCidade': 'Olivedos',
          },
          {
            'id_cidade': 2510600,
            'nomeCidade': 'Ouro Velho',
          },
          {
            'id_cidade': 2510659,
            'nomeCidade': 'Parari',
          },
          {
            'id_cidade': 2510709,
            'nomeCidade': 'Passagem',
          },
          {
            'id_cidade': 2510808,
            'nomeCidade': 'Patos',
          },
          {
            'id_cidade': 2510907,
            'nomeCidade': 'Paulista',
          },
          {
            'id_cidade': 2511004,
            'nomeCidade': 'Pedra Branca',
          },
          {
            'id_cidade': 2511103,
            'nomeCidade': 'Pedra Lavrada',
          },
          {
            'id_cidade': 2511202,
            'nomeCidade': 'Pedras de Fogo',
          },
          {
            'id_cidade': 2512721,
            'nomeCidade': 'Pedro Régis',
          },
          {
            'id_cidade': 2511301,
            'nomeCidade': 'Piancó',
          },
          {
            'id_cidade': 2511400,
            'nomeCidade': 'Picuí',
          },
          {
            'id_cidade': 2511509,
            'nomeCidade': 'Pilar',
          },
          {
            'id_cidade': 2511608,
            'nomeCidade': 'Pilões',
          },
          {
            'id_cidade': 2511707,
            'nomeCidade': 'Pilõezinhos',
          },
          {
            'id_cidade': 2511806,
            'nomeCidade': 'Pirpirituba',
          },
          {
            'id_cidade': 2511905,
            'nomeCidade': 'Pitimbu',
          },
          {
            'id_cidade': 2512002,
            'nomeCidade': 'Pocinhos',
          },
          {
            'id_cidade': 2512036,
            'nomeCidade': 'Poço Dantas',
          },
          {
            'id_cidade': 2512077,
            'nomeCidade': 'Poço de José de Moura',
          },
          {
            'id_cidade': 2512101,
            'nomeCidade': 'Pombal',
          },
          {
            'id_cidade': 2512200,
            'nomeCidade': 'Prata',
          },
          {
            'id_cidade': 2512309,
            'nomeCidade': 'Princesa Isabel',
          },
          {
            'id_cidade': 2512408,
            'nomeCidade': 'Puxinanã',
          },
          {
            'id_cidade': 2512507,
            'nomeCidade': 'Queimadas',
          },
          {
            'id_cidade': 2512606,
            'nomeCidade': 'Quixaba',
          },
          {
            'id_cidade': 2512705,
            'nomeCidade': 'Remígio',
          },
          {
            'id_cidade': 2512747,
            'nomeCidade': 'Riachão',
          },
          {
            'id_cidade': 2512754,
            'nomeCidade': 'Riachão do Bacamarte',
          },
          {
            'id_cidade': 2512762,
            'nomeCidade': 'Riachão do Poço',
          },
          {
            'id_cidade': 2512788,
            'nomeCidade': 'Riacho de Santo Antônio',
          },
          {
            'id_cidade': 2512804,
            'nomeCidade': 'Riacho dos Cavalos',
          },
          {
            'id_cidade': 2512903,
            'nomeCidade': 'Rio Tinto',
          },
          {
            'id_cidade': 2513000,
            'nomeCidade': 'Salgadinho',
          },
          {
            'id_cidade': 2513109,
            'nomeCidade': 'Salgado de São Félix',
          },
          {
            'id_cidade': 2513158,
            'nomeCidade': 'Santa Cecília',
          },
          {
            'id_cidade': 2513208,
            'nomeCidade': 'Santa Cruz',
          },
          {
            'id_cidade': 2513307,
            'nomeCidade': 'Santa Helena',
          },
          {
            'id_cidade': 2513356,
            'nomeCidade': 'Santa Inês',
          },
          {
            'id_cidade': 2513406,
            'nomeCidade': 'Santa Luzia',
          },
          {
            'id_cidade': 2513703,
            'nomeCidade': 'Santa Rita',
          },
          {
            'id_cidade': 2513802,
            'nomeCidade': 'Santa Teresinha',
          },
          {
            'id_cidade': 2513505,
            'nomeCidade': 'Santana de Mangueira',
          },
          {
            'id_cidade': 2513604,
            'nomeCidade': 'Santana dos Garrotes',
          },
          {
            'id_cidade': 2513851,
            'nomeCidade': 'Santo André',
          },
          {
            'id_cidade': 2513927,
            'nomeCidade': 'São Bentinho',
          },
          {
            'id_cidade': 2513901,
            'nomeCidade': 'São Bento',
          },
          {
            'id_cidade': 2513968,
            'nomeCidade': 'São Domingos',
          },
          {
            'id_cidade': 2513943,
            'nomeCidade': 'São Domingos do Cariri',
          },
          {
            'id_cidade': 2513984,
            'nomeCidade': 'São Francisco',
          },
          {
            'id_cidade': 2514008,
            'nomeCidade': 'São João do Cariri',
          },
          {
            'id_cidade': 2500700,
            'nomeCidade': 'São João do Rio do Peixe',
          },
          {
            'id_cidade': 2514107,
            'nomeCidade': 'São João do Tigre',
          },
          {
            'id_cidade': 2514206,
            'nomeCidade': 'São José da Lagoa Tapada',
          },
          {
            'id_cidade': 2514305,
            'nomeCidade': 'São José de Caiana',
          },
          {
            'id_cidade': 2514404,
            'nomeCidade': 'São José de Espinharas',
          },
          {
            'id_cidade': 2514503,
            'nomeCidade': 'São José de Piranhas',
          },
          {
            'id_cidade': 2514552,
            'nomeCidade': 'São José de Princesa',
          },
          {
            'id_cidade': 2514602,
            'nomeCidade': 'São José do Bonfim',
          },
          {
            'id_cidade': 2514651,
            'nomeCidade': 'São José do Brejo do Cruz',
          },
          {
            'id_cidade': 2514701,
            'nomeCidade': 'São José do Sabugi',
          },
          {
            'id_cidade': 2514800,
            'nomeCidade': 'São José dos Cordeiros',
          },
          {
            'id_cidade': 2514453,
            'nomeCidade': 'São José dos Ramos',
          },
          {
            'id_cidade': 2514909,
            'nomeCidade': 'São Mamede',
          },
          {
            'id_cidade': 2515005,
            'nomeCidade': 'São Miguel de Taipu',
          },
          {
            'id_cidade': 2515104,
            'nomeCidade': 'São Sebastião de Lagoa de Roça',
          },
          {
            'id_cidade': 2515203,
            'nomeCidade': 'São Sebastião do Umbuzeiro',
          },
          {
            'id_cidade': 2515401,
            'nomeCidade': 'São Vicente do Seridó',
          },
          {
            'id_cidade': 2515302,
            'nomeCidade': 'Sapé',
          },
          {
            'id_cidade': 2515500,
            'nomeCidade': 'Serra Branca',
          },
          {
            'id_cidade': 2515609,
            'nomeCidade': 'Serra da Raiz',
          },
          {
            'id_cidade': 2515708,
            'nomeCidade': 'Serra Grande',
          },
          {
            'id_cidade': 2515807,
            'nomeCidade': 'Serra Redonda',
          },
          {
            'id_cidade': 2515906,
            'nomeCidade': 'Serraria',
          },
          {
            'id_cidade': 2515930,
            'nomeCidade': 'Sertãozinho',
          },
          {
            'id_cidade': 2515971,
            'nomeCidade': 'Sobrado',
          },
          {
            'id_cidade': 2516003,
            'nomeCidade': 'Solânea',
          },
          {
            'id_cidade': 2516102,
            'nomeCidade': 'Soledade',
          },
          {
            'id_cidade': 2516151,
            'nomeCidade': 'Sossêgo',
          },
          {
            'id_cidade': 2516201,
            'nomeCidade': 'Sousa',
          },
          {
            'id_cidade': 2516300,
            'nomeCidade': 'Sumé',
          },
          {
            'id_cidade': 2516409,
            'nomeCidade': 'Tacima',
          },
          {
            'id_cidade': 2516508,
            'nomeCidade': 'Taperoá',
          },
          {
            'id_cidade': 2516607,
            'nomeCidade': 'Tavares',
          },
          {
            'id_cidade': 2516706,
            'nomeCidade': 'Teixeira',
          },
          {
            'id_cidade': 2516755,
            'nomeCidade': 'Tenório',
          },
          {
            'id_cidade': 2516805,
            'nomeCidade': 'Triunfo',
          },
          {
            'id_cidade': 2516904,
            'nomeCidade': 'Uiraúna',
          },
          {
            'id_cidade': 2517001,
            'nomeCidade': 'Umbuzeiro',
          },
          {
            'id_cidade': 2517100,
            'nomeCidade': 'Várzea',
          },
          {
            'id_cidade': 2517209,
            'nomeCidade': 'Vieirópolis',
          },
          {
            'id_cidade': 2505501,
            'nomeCidade': 'Vista Serrana',
          },
          {
            'id_cidade': 2517407,
            'nomeCidade': 'Zabelê',
          },
        ],
      },
      {
        'id': 41,
        'nomeEstado': 'Paraná',
        'sigla': 'PR',
        'cidades': [
          {
            'id_cidade': 4100103,
            'nomeCidade': 'Abatiá',
          },
          {
            'id_cidade': 4100202,
            'nomeCidade': 'Adrianópolis',
          },
          {
            'id_cidade': 4100301,
            'nomeCidade': 'Agudos do Sul',
          },
          {
            'id_cidade': 4100400,
            'nomeCidade': 'Almirante Tamandaré',
          },
          {
            'id_cidade': 4100459,
            'nomeCidade': 'Altamira do Paraná',
          },
          {
            'id_cidade': 4128625,
            'nomeCidade': 'Alto Paraíso',
          },
          {
            'id_cidade': 4100608,
            'nomeCidade': 'Alto Paraná',
          },
          {
            'id_cidade': 4100707,
            'nomeCidade': 'Alto Piquiri',
          },
          {
            'id_cidade': 4100509,
            'nomeCidade': 'Altônia',
          },
          {
            'id_cidade': 4100806,
            'nomeCidade': 'Alvorada do Sul',
          },
          {
            'id_cidade': 4100905,
            'nomeCidade': 'Amaporã',
          },
          {
            'id_cidade': 4101002,
            'nomeCidade': 'Ampére',
          },
          {
            'id_cidade': 4101051,
            'nomeCidade': 'Anahy',
          },
          {
            'id_cidade': 4101101,
            'nomeCidade': 'Andirá',
          },
          {
            'id_cidade': 4101150,
            'nomeCidade': 'Ângulo',
          },
          {
            'id_cidade': 4101200,
            'nomeCidade': 'Antonina',
          },
          {
            'id_cidade': 4101309,
            'nomeCidade': 'Antônio Olinto',
          },
          {
            'id_cidade': 4101408,
            'nomeCidade': 'Apucarana',
          },
          {
            'id_cidade': 4101507,
            'nomeCidade': 'Arapongas',
          },
          {
            'id_cidade': 4101606,
            'nomeCidade': 'Arapoti',
          },
          {
            'id_cidade': 4101655,
            'nomeCidade': 'Arapuã',
          },
          {
            'id_cidade': 4101705,
            'nomeCidade': 'Araruna',
          },
          {
            'id_cidade': 4101804,
            'nomeCidade': 'Araucária',
          },
          {
            'id_cidade': 4101853,
            'nomeCidade': 'Ariranha do Ivaí',
          },
          {
            'id_cidade': 4101903,
            'nomeCidade': 'Assaí',
          },
          {
            'id_cidade': 4102000,
            'nomeCidade': 'Assis Chateaubriand',
          },
          {
            'id_cidade': 4102109,
            'nomeCidade': 'Astorga',
          },
          {
            'id_cidade': 4102208,
            'nomeCidade': 'Atalaia',
          },
          {
            'id_cidade': 4102307,
            'nomeCidade': 'Balsa Nova',
          },
          {
            'id_cidade': 4102406,
            'nomeCidade': 'Bandeirantes',
          },
          {
            'id_cidade': 4102505,
            'nomeCidade': 'Barbosa Ferraz',
          },
          {
            'id_cidade': 4102703,
            'nomeCidade': 'Barra do Jacaré',
          },
          {
            'id_cidade': 4102604,
            'nomeCidade': 'Barracão',
          },
          {
            'id_cidade': 4102752,
            'nomeCidade': 'Bela Vista da Caroba',
          },
          {
            'id_cidade': 4102802,
            'nomeCidade': 'Bela Vista do Paraíso',
          },
          {
            'id_cidade': 4102901,
            'nomeCidade': 'Bituruna',
          },
          {
            'id_cidade': 4103008,
            'nomeCidade': 'Boa Esperança',
          },
          {
            'id_cidade': 4103024,
            'nomeCidade': 'Boa Esperança do Iguaçu',
          },
          {
            'id_cidade': 4103040,
            'nomeCidade': 'Boa Ventura de São Roque',
          },
          {
            'id_cidade': 4103057,
            'nomeCidade': 'Boa Vista da Aparecida',
          },
          {
            'id_cidade': 4103107,
            'nomeCidade': 'Bocaiúva do Sul',
          },
          {
            'id_cidade': 4103156,
            'nomeCidade': 'Bom Jesus do Sul',
          },
          {
            'id_cidade': 4103206,
            'nomeCidade': 'Bom Sucesso',
          },
          {
            'id_cidade': 4103222,
            'nomeCidade': 'Bom Sucesso do Sul',
          },
          {
            'id_cidade': 4103305,
            'nomeCidade': 'Borrazópolis',
          },
          {
            'id_cidade': 4103354,
            'nomeCidade': 'Braganey',
          },
          {
            'id_cidade': 4103370,
            'nomeCidade': 'Brasilândia do Sul',
          },
          {
            'id_cidade': 4103404,
            'nomeCidade': 'Cafeara',
          },
          {
            'id_cidade': 4103453,
            'nomeCidade': 'Cafelândia',
          },
          {
            'id_cidade': 4103479,
            'nomeCidade': 'Cafezal do Sul',
          },
          {
            'id_cidade': 4103503,
            'nomeCidade': 'Califórnia',
          },
          {
            'id_cidade': 4103602,
            'nomeCidade': 'Cambará',
          },
          {
            'id_cidade': 4103701,
            'nomeCidade': 'Cambé',
          },
          {
            'id_cidade': 4103800,
            'nomeCidade': 'Cambira',
          },
          {
            'id_cidade': 4103909,
            'nomeCidade': 'Campina da Lagoa',
          },
          {
            'id_cidade': 4103958,
            'nomeCidade': 'Campina do Simão',
          },
          {
            'id_cidade': 4104006,
            'nomeCidade': 'Campina Grande do Sul',
          },
          {
            'id_cidade': 4104055,
            'nomeCidade': 'Campo Bonito',
          },
          {
            'id_cidade': 4104105,
            'nomeCidade': 'Campo do Tenente',
          },
          {
            'id_cidade': 4104204,
            'nomeCidade': 'Campo Largo',
          },
          {
            'id_cidade': 4104253,
            'nomeCidade': 'Campo Magro',
          },
          {
            'id_cidade': 4104303,
            'nomeCidade': 'Campo Mourão',
          },
          {
            'id_cidade': 4104402,
            'nomeCidade': 'Cândido de Abreu',
          },
          {
            'id_cidade': 4104428,
            'nomeCidade': 'Candói',
          },
          {
            'id_cidade': 4104451,
            'nomeCidade': 'Cantagalo',
          },
          {
            'id_cidade': 4104501,
            'nomeCidade': 'Capanema',
          },
          {
            'id_cidade': 4104600,
            'nomeCidade': 'Capitão Leônidas Marques',
          },
          {
            'id_cidade': 4104659,
            'nomeCidade': 'Carambeí',
          },
          {
            'id_cidade': 4104709,
            'nomeCidade': 'Carlópolis',
          },
          {
            'id_cidade': 4104808,
            'nomeCidade': 'Cascavel',
          },
          {
            'id_cidade': 4104907,
            'nomeCidade': 'Castro',
          },
          {
            'id_cidade': 4105003,
            'nomeCidade': 'Catanduvas',
          },
          {
            'id_cidade': 4105102,
            'nomeCidade': 'Centenário do Sul',
          },
          {
            'id_cidade': 4105201,
            'nomeCidade': 'Cerro Azul',
          },
          {
            'id_cidade': 4105300,
            'nomeCidade': 'Céu Azul',
          },
          {
            'id_cidade': 4105409,
            'nomeCidade': 'Chopinzinho',
          },
          {
            'id_cidade': 4105508,
            'nomeCidade': 'Cianorte',
          },
          {
            'id_cidade': 4105607,
            'nomeCidade': 'Cidade Gaúcha',
          },
          {
            'id_cidade': 4105706,
            'nomeCidade': 'Clevelândia',
          },
          {
            'id_cidade': 4105805,
            'nomeCidade': 'Colombo',
          },
          {
            'id_cidade': 4105904,
            'nomeCidade': 'Colorado',
          },
          {
            'id_cidade': 4106001,
            'nomeCidade': 'Congonhinhas',
          },
          {
            'id_cidade': 4106100,
            'nomeCidade': 'Conselheiro Mairinck',
          },
          {
            'id_cidade': 4106209,
            'nomeCidade': 'Contenda',
          },
          {
            'id_cidade': 4106308,
            'nomeCidade': 'Corbélia',
          },
          {
            'id_cidade': 4106407,
            'nomeCidade': 'Cornélio Procópio',
          },
          {
            'id_cidade': 4106456,
            'nomeCidade': 'Coronel Domingos Soares',
          },
          {
            'id_cidade': 4106506,
            'nomeCidade': 'Coronel Vivida',
          },
          {
            'id_cidade': 4106555,
            'nomeCidade': 'Corumbataí do Sul',
          },
          {
            'id_cidade': 4106803,
            'nomeCidade': 'Cruz Machado',
          },
          {
            'id_cidade': 4106571,
            'nomeCidade': 'Cruzeiro do Iguaçu',
          },
          {
            'id_cidade': 4106605,
            'nomeCidade': 'Cruzeiro do Oeste',
          },
          {
            'id_cidade': 4106704,
            'nomeCidade': 'Cruzeiro do Sul',
          },
          {
            'id_cidade': 4106852,
            'nomeCidade': 'Cruzmaltina',
          },
          {
            'id_cidade': 4106902,
            'nomeCidade': 'Curitiba',
          },
          {
            'id_cidade': 4107009,
            'nomeCidade': 'Curiúva',
          },
          {
            'id_cidade': 4107108,
            'nomeCidade': 'Diamante do Norte',
          },
          {
            'id_cidade': 4107124,
            'nomeCidade': 'Diamante do Sul',
          },
          {
            'id_cidade': 4107157,
            'nomeCidade': "Diamante D'Oeste",
          },
          {
            'id_cidade': 4107207,
            'nomeCidade': 'Dois Vizinhos',
          },
          {
            'id_cidade': 4107256,
            'nomeCidade': 'Douradina',
          },
          {
            'id_cidade': 4107306,
            'nomeCidade': 'Doutor Camargo',
          },
          {
            'id_cidade': 4128633,
            'nomeCidade': 'Doutor Ulysses',
          },
          {
            'id_cidade': 4107405,
            'nomeCidade': 'Enéas Marques',
          },
          {
            'id_cidade': 4107504,
            'nomeCidade': 'Engenheiro Beltrão',
          },
          {
            'id_cidade': 4107538,
            'nomeCidade': 'Entre Rios do Oeste',
          },
          {
            'id_cidade': 4107520,
            'nomeCidade': 'Esperança Nova',
          },
          {
            'id_cidade': 4107546,
            'nomeCidade': 'Espigão Alto do Iguaçu',
          },
          {
            'id_cidade': 4107553,
            'nomeCidade': 'Farol',
          },
          {
            'id_cidade': 4107603,
            'nomeCidade': 'Faxinal',
          },
          {
            'id_cidade': 4107652,
            'nomeCidade': 'Fazenda Rio Grande',
          },
          {
            'id_cidade': 4107702,
            'nomeCidade': 'Fênix',
          },
          {
            'id_cidade': 4107736,
            'nomeCidade': 'Fernandes Pinheiro',
          },
          {
            'id_cidade': 4107751,
            'nomeCidade': 'Figueira',
          },
          {
            'id_cidade': 4107850,
            'nomeCidade': 'Flor da Serra do Sul',
          },
          {
            'id_cidade': 4107801,
            'nomeCidade': 'Floraí',
          },
          {
            'id_cidade': 4107900,
            'nomeCidade': 'Floresta',
          },
          {
            'id_cidade': 4108007,
            'nomeCidade': 'Florestópolis',
          },
          {
            'id_cidade': 4108106,
            'nomeCidade': 'Flórida',
          },
          {
            'id_cidade': 4108205,
            'nomeCidade': 'Formosa do Oeste',
          },
          {
            'id_cidade': 4108304,
            'nomeCidade': 'Foz do Iguaçu',
          },
          {
            'id_cidade': 4108452,
            'nomeCidade': 'Foz do Jordão',
          },
          {
            'id_cidade': 4108320,
            'nomeCidade': 'Francisco Alves',
          },
          {
            'id_cidade': 4108403,
            'nomeCidade': 'Francisco Beltrão',
          },
          {
            'id_cidade': 4108502,
            'nomeCidade': 'General Carneiro',
          },
          {
            'id_cidade': 4108551,
            'nomeCidade': 'Godoy Moreira',
          },
          {
            'id_cidade': 4108601,
            'nomeCidade': 'Goioerê',
          },
          {
            'id_cidade': 4108650,
            'nomeCidade': 'Goioxim',
          },
          {
            'id_cidade': 4108700,
            'nomeCidade': 'Grandes Rios',
          },
          {
            'id_cidade': 4108809,
            'nomeCidade': 'Guaíra',
          },
          {
            'id_cidade': 4108908,
            'nomeCidade': 'Guairaçá',
          },
          {
            'id_cidade': 4108957,
            'nomeCidade': 'Guamiranga',
          },
          {
            'id_cidade': 4109005,
            'nomeCidade': 'Guapirama',
          },
          {
            'id_cidade': 4109104,
            'nomeCidade': 'Guaporema',
          },
          {
            'id_cidade': 4109203,
            'nomeCidade': 'Guaraci',
          },
          {
            'id_cidade': 4109302,
            'nomeCidade': 'Guaraniaçu',
          },
          {
            'id_cidade': 4109401,
            'nomeCidade': 'Guarapuava',
          },
          {
            'id_cidade': 4109500,
            'nomeCidade': 'Guaraqueçaba',
          },
          {
            'id_cidade': 4109609,
            'nomeCidade': 'Guaratuba',
          },
          {
            'id_cidade': 4109658,
            'nomeCidade': 'Honório Serpa',
          },
          {
            'id_cidade': 4109708,
            'nomeCidade': 'Ibaiti',
          },
          {
            'id_cidade': 4109757,
            'nomeCidade': 'Ibema',
          },
          {
            'id_cidade': 4109807,
            'nomeCidade': 'Ibiporã',
          },
          {
            'id_cidade': 4109906,
            'nomeCidade': 'Icaraíma',
          },
          {
            'id_cidade': 4110003,
            'nomeCidade': 'Iguaraçu',
          },
          {
            'id_cidade': 4110052,
            'nomeCidade': 'Iguatu',
          },
          {
            'id_cidade': 4110078,
            'nomeCidade': 'Imbaú',
          },
          {
            'id_cidade': 4110102,
            'nomeCidade': 'Imbituva',
          },
          {
            'id_cidade': 4110201,
            'nomeCidade': 'Inácio Martins',
          },
          {
            'id_cidade': 4110300,
            'nomeCidade': 'Inajá',
          },
          {
            'id_cidade': 4110409,
            'nomeCidade': 'Indianópolis',
          },
          {
            'id_cidade': 4110508,
            'nomeCidade': 'Ipiranga',
          },
          {
            'id_cidade': 4110607,
            'nomeCidade': 'Iporã',
          },
          {
            'id_cidade': 4110656,
            'nomeCidade': 'Iracema do Oeste',
          },
          {
            'id_cidade': 4110706,
            'nomeCidade': 'Irati',
          },
          {
            'id_cidade': 4110805,
            'nomeCidade': 'Iretama',
          },
          {
            'id_cidade': 4110904,
            'nomeCidade': 'Itaguajé',
          },
          {
            'id_cidade': 4110953,
            'nomeCidade': 'Itaipulândia',
          },
          {
            'id_cidade': 4111001,
            'nomeCidade': 'Itambaracá',
          },
          {
            'id_cidade': 4111100,
            'nomeCidade': 'Itambé',
          },
          {
            'id_cidade': 4111209,
            'nomeCidade': "Itapejara d'Oeste",
          },
          {
            'id_cidade': 4111258,
            'nomeCidade': 'Itaperuçu',
          },
          {
            'id_cidade': 4111308,
            'nomeCidade': 'Itaúna do Sul',
          },
          {
            'id_cidade': 4111407,
            'nomeCidade': 'Ivaí',
          },
          {
            'id_cidade': 4111506,
            'nomeCidade': 'Ivaiporã',
          },
          {
            'id_cidade': 4111555,
            'nomeCidade': 'Ivaté',
          },
          {
            'id_cidade': 4111605,
            'nomeCidade': 'Ivatuba',
          },
          {
            'id_cidade': 4111704,
            'nomeCidade': 'Jaboti',
          },
          {
            'id_cidade': 4111803,
            'nomeCidade': 'Jacarezinho',
          },
          {
            'id_cidade': 4111902,
            'nomeCidade': 'Jaguapitã',
          },
          {
            'id_cidade': 4112009,
            'nomeCidade': 'Jaguariaíva',
          },
          {
            'id_cidade': 4112108,
            'nomeCidade': 'Jandaia do Sul',
          },
          {
            'id_cidade': 4112207,
            'nomeCidade': 'Janiópolis',
          },
          {
            'id_cidade': 4112306,
            'nomeCidade': 'Japira',
          },
          {
            'id_cidade': 4112405,
            'nomeCidade': 'Japurá',
          },
          {
            'id_cidade': 4112504,
            'nomeCidade': 'Jardim Alegre',
          },
          {
            'id_cidade': 4112603,
            'nomeCidade': 'Jardim Olinda',
          },
          {
            'id_cidade': 4112702,
            'nomeCidade': 'Jataizinho',
          },
          {
            'id_cidade': 4112751,
            'nomeCidade': 'Jesuítas',
          },
          {
            'id_cidade': 4112801,
            'nomeCidade': 'Joaquim Távora',
          },
          {
            'id_cidade': 4112900,
            'nomeCidade': 'Jundiaí do Sul',
          },
          {
            'id_cidade': 4112959,
            'nomeCidade': 'Juranda',
          },
          {
            'id_cidade': 4113007,
            'nomeCidade': 'Jussara',
          },
          {
            'id_cidade': 4113106,
            'nomeCidade': 'Kaloré',
          },
          {
            'id_cidade': 4113205,
            'nomeCidade': 'Lapa',
          },
          {
            'id_cidade': 4113254,
            'nomeCidade': 'Laranjal',
          },
          {
            'id_cidade': 4113304,
            'nomeCidade': 'Laranjeiras do Sul',
          },
          {
            'id_cidade': 4113403,
            'nomeCidade': 'Leópolis',
          },
          {
            'id_cidade': 4113429,
            'nomeCidade': 'Lidianópolis',
          },
          {
            'id_cidade': 4113452,
            'nomeCidade': 'Lindoeste',
          },
          {
            'id_cidade': 4113502,
            'nomeCidade': 'Loanda',
          },
          {
            'id_cidade': 4113601,
            'nomeCidade': 'Lobato',
          },
          {
            'id_cidade': 4113700,
            'nomeCidade': 'Londrina',
          },
          {
            'id_cidade': 4113734,
            'nomeCidade': 'Luiziana',
          },
          {
            'id_cidade': 4113759,
            'nomeCidade': 'Lunardelli',
          },
          {
            'id_cidade': 4113809,
            'nomeCidade': 'Lupionópolis',
          },
          {
            'id_cidade': 4113908,
            'nomeCidade': 'Mallet',
          },
          {
            'id_cidade': 4114005,
            'nomeCidade': 'Mamborê',
          },
          {
            'id_cidade': 4114104,
            'nomeCidade': 'Mandaguaçu',
          },
          {
            'id_cidade': 4114203,
            'nomeCidade': 'Mandaguari',
          },
          {
            'id_cidade': 4114302,
            'nomeCidade': 'Mandirituba',
          },
          {
            'id_cidade': 4114351,
            'nomeCidade': 'Manfrinópolis',
          },
          {
            'id_cidade': 4114401,
            'nomeCidade': 'Mangueirinha',
          },
          {
            'id_cidade': 4114500,
            'nomeCidade': 'Manoel Ribas',
          },
          {
            'id_cidade': 4114609,
            'nomeCidade': 'Marechal Cândido Rondon',
          },
          {
            'id_cidade': 4114708,
            'nomeCidade': 'Maria Helena',
          },
          {
            'id_cidade': 4114807,
            'nomeCidade': 'Marialva',
          },
          {
            'id_cidade': 4114906,
            'nomeCidade': 'Marilândia do Sul',
          },
          {
            'id_cidade': 4115002,
            'nomeCidade': 'Marilena',
          },
          {
            'id_cidade': 4115101,
            'nomeCidade': 'Mariluz',
          },
          {
            'id_cidade': 4115200,
            'nomeCidade': 'Maringá',
          },
          {
            'id_cidade': 4115309,
            'nomeCidade': 'Mariópolis',
          },
          {
            'id_cidade': 4115358,
            'nomeCidade': 'Maripá',
          },
          {
            'id_cidade': 4115408,
            'nomeCidade': 'Marmeleiro',
          },
          {
            'id_cidade': 4115457,
            'nomeCidade': 'Marquinho',
          },
          {
            'id_cidade': 4115507,
            'nomeCidade': 'Marumbi',
          },
          {
            'id_cidade': 4115606,
            'nomeCidade': 'Matelândia',
          },
          {
            'id_cidade': 4115705,
            'nomeCidade': 'Matinhos',
          },
          {
            'id_cidade': 4115739,
            'nomeCidade': 'Mato Rico',
          },
          {
            'id_cidade': 4115754,
            'nomeCidade': 'Mauá da Serra',
          },
          {
            'id_cidade': 4115804,
            'nomeCidade': 'Medianeira',
          },
          {
            'id_cidade': 4115853,
            'nomeCidade': 'Mercedes',
          },
          {
            'id_cidade': 4115903,
            'nomeCidade': 'Mirador',
          },
          {
            'id_cidade': 4116000,
            'nomeCidade': 'Miraselva',
          },
          {
            'id_cidade': 4116059,
            'nomeCidade': 'Missal',
          },
          {
            'id_cidade': 4116109,
            'nomeCidade': 'Moreira Sales',
          },
          {
            'id_cidade': 4116208,
            'nomeCidade': 'Morretes',
          },
          {
            'id_cidade': 4116307,
            'nomeCidade': 'Munhoz de Melo',
          },
          {
            'id_cidade': 4116406,
            'nomeCidade': 'Nossa Senhora das Graças',
          },
          {
            'id_cidade': 4116505,
            'nomeCidade': 'Nova Aliança do Ivaí',
          },
          {
            'id_cidade': 4116604,
            'nomeCidade': 'Nova América da Colina',
          },
          {
            'id_cidade': 4116703,
            'nomeCidade': 'Nova Aurora',
          },
          {
            'id_cidade': 4116802,
            'nomeCidade': 'Nova Cantu',
          },
          {
            'id_cidade': 4116901,
            'nomeCidade': 'Nova Esperança',
          },
          {
            'id_cidade': 4116950,
            'nomeCidade': 'Nova Esperança do Sudoeste',
          },
          {
            'id_cidade': 4117008,
            'nomeCidade': 'Nova Fátima',
          },
          {
            'id_cidade': 4117057,
            'nomeCidade': 'Nova Laranjeiras',
          },
          {
            'id_cidade': 4117107,
            'nomeCidade': 'Nova Londrina',
          },
          {
            'id_cidade': 4117206,
            'nomeCidade': 'Nova Olímpia',
          },
          {
            'id_cidade': 4117255,
            'nomeCidade': 'Nova Prata do Iguaçu',
          },
          {
            'id_cidade': 4117214,
            'nomeCidade': 'Nova Santa Bárbara',
          },
          {
            'id_cidade': 4117222,
            'nomeCidade': 'Nova Santa Rosa',
          },
          {
            'id_cidade': 4117271,
            'nomeCidade': 'Nova Tebas',
          },
          {
            'id_cidade': 4117297,
            'nomeCidade': 'Novo Itacolomi',
          },
          {
            'id_cidade': 4117305,
            'nomeCidade': 'Ortigueira',
          },
          {
            'id_cidade': 4117404,
            'nomeCidade': 'Ourizona',
          },
          {
            'id_cidade': 4117453,
            'nomeCidade': 'Ouro Verde do Oeste',
          },
          {
            'id_cidade': 4117503,
            'nomeCidade': 'Paiçandu',
          },
          {
            'id_cidade': 4117602,
            'nomeCidade': 'Palmas',
          },
          {
            'id_cidade': 4117701,
            'nomeCidade': 'Palmeira',
          },
          {
            'id_cidade': 4117800,
            'nomeCidade': 'Palmital',
          },
          {
            'id_cidade': 4117909,
            'nomeCidade': 'Palotina',
          },
          {
            'id_cidade': 4118006,
            'nomeCidade': 'Paraíso do Norte',
          },
          {
            'id_cidade': 4118105,
            'nomeCidade': 'Paranacity',
          },
          {
            'id_cidade': 4118204,
            'nomeCidade': 'Paranaguá',
          },
          {
            'id_cidade': 4118303,
            'nomeCidade': 'Paranapoema',
          },
          {
            'id_cidade': 4118402,
            'nomeCidade': 'Paranavaí',
          },
          {
            'id_cidade': 4118451,
            'nomeCidade': 'Pato Bragado',
          },
          {
            'id_cidade': 4118501,
            'nomeCidade': 'Pato Branco',
          },
          {
            'id_cidade': 4118600,
            'nomeCidade': 'Paula Freitas',
          },
          {
            'id_cidade': 4118709,
            'nomeCidade': 'Paulo Frontin',
          },
          {
            'id_cidade': 4118808,
            'nomeCidade': 'Peabiru',
          },
          {
            'id_cidade': 4118857,
            'nomeCidade': 'Perobal',
          },
          {
            'id_cidade': 4118907,
            'nomeCidade': 'Pérola',
          },
          {
            'id_cidade': 4119004,
            'nomeCidade': "Pérola d'Oeste",
          },
          {
            'id_cidade': 4119103,
            'nomeCidade': 'Piên',
          },
          {
            'id_cidade': 4119152,
            'nomeCidade': 'Pinhais',
          },
          {
            'id_cidade': 4119251,
            'nomeCidade': 'Pinhal de São Bento',
          },
          {
            'id_cidade': 4119202,
            'nomeCidade': 'Pinhalão',
          },
          {
            'id_cidade': 4119301,
            'nomeCidade': 'Pinhão',
          },
          {
            'id_cidade': 4119400,
            'nomeCidade': 'Piraí do Sul',
          },
          {
            'id_cidade': 4119509,
            'nomeCidade': 'Piraquara',
          },
          {
            'id_cidade': 4119608,
            'nomeCidade': 'Pitanga',
          },
          {
            'id_cidade': 4119657,
            'nomeCidade': 'Pitangueiras',
          },
          {
            'id_cidade': 4119707,
            'nomeCidade': 'Planaltina do Paraná',
          },
          {
            'id_cidade': 4119806,
            'nomeCidade': 'Planalto',
          },
          {
            'id_cidade': 4119905,
            'nomeCidade': 'Ponta Grossa',
          },
          {
            'id_cidade': 4119954,
            'nomeCidade': 'Pontal do Paraná',
          },
          {
            'id_cidade': 4120002,
            'nomeCidade': 'Porecatu',
          },
          {
            'id_cidade': 4120101,
            'nomeCidade': 'Porto Amazonas',
          },
          {
            'id_cidade': 4120150,
            'nomeCidade': 'Porto Barreiro',
          },
          {
            'id_cidade': 4120200,
            'nomeCidade': 'Porto Rico',
          },
          {
            'id_cidade': 4120309,
            'nomeCidade': 'Porto Vitória',
          },
          {
            'id_cidade': 4120333,
            'nomeCidade': 'Prado Ferreira',
          },
          {
            'id_cidade': 4120358,
            'nomeCidade': 'Pranchita',
          },
          {
            'id_cidade': 4120408,
            'nomeCidade': 'Presidente Castelo Branco',
          },
          {
            'id_cidade': 4120507,
            'nomeCidade': 'Primeiro de Maio',
          },
          {
            'id_cidade': 4120606,
            'nomeCidade': 'Prudentópolis',
          },
          {
            'id_cidade': 4120655,
            'nomeCidade': 'Quarto Centenário',
          },
          {
            'id_cidade': 4120705,
            'nomeCidade': 'Quatiguá',
          },
          {
            'id_cidade': 4120804,
            'nomeCidade': 'Quatro Barras',
          },
          {
            'id_cidade': 4120853,
            'nomeCidade': 'Quatro Pontes',
          },
          {
            'id_cidade': 4120903,
            'nomeCidade': 'Quedas do Iguaçu',
          },
          {
            'id_cidade': 4121000,
            'nomeCidade': 'Querência do Norte',
          },
          {
            'id_cidade': 4121109,
            'nomeCidade': 'Quinta do Sol',
          },
          {
            'id_cidade': 4121208,
            'nomeCidade': 'Quitandinha',
          },
          {
            'id_cidade': 4121257,
            'nomeCidade': 'Ramilândia',
          },
          {
            'id_cidade': 4121307,
            'nomeCidade': 'Rancho Alegre',
          },
          {
            'id_cidade': 4121356,
            'nomeCidade': "Rancho Alegre D'Oeste",
          },
          {
            'id_cidade': 4121406,
            'nomeCidade': 'Realeza',
          },
          {
            'id_cidade': 4121505,
            'nomeCidade': 'Rebouças',
          },
          {
            'id_cidade': 4121604,
            'nomeCidade': 'Renascença',
          },
          {
            'id_cidade': 4121703,
            'nomeCidade': 'Reserva',
          },
          {
            'id_cidade': 4121752,
            'nomeCidade': 'Reserva do Iguaçu',
          },
          {
            'id_cidade': 4121802,
            'nomeCidade': 'Ribeirão Claro',
          },
          {
            'id_cidade': 4121901,
            'nomeCidade': 'Ribeirão do Pinhal',
          },
          {
            'id_cidade': 4122008,
            'nomeCidade': 'Rio Azul',
          },
          {
            'id_cidade': 4122107,
            'nomeCidade': 'Rio Bom',
          },
          {
            'id_cidade': 4122156,
            'nomeCidade': 'Rio Bonito do Iguaçu',
          },
          {
            'id_cidade': 4122172,
            'nomeCidade': 'Rio Branco do Ivaí',
          },
          {
            'id_cidade': 4122206,
            'nomeCidade': 'Rio Branco do Sul',
          },
          {
            'id_cidade': 4122305,
            'nomeCidade': 'Rio Negro',
          },
          {
            'id_cidade': 4122404,
            'nomeCidade': 'Rolândia',
          },
          {
            'id_cidade': 4122503,
            'nomeCidade': 'Roncador',
          },
          {
            'id_cidade': 4122602,
            'nomeCidade': 'Rondon',
          },
          {
            'id_cidade': 4122651,
            'nomeCidade': 'Rosário do Ivaí',
          },
          {
            'id_cidade': 4122701,
            'nomeCidade': 'Sabáudia',
          },
          {
            'id_cidade': 4122800,
            'nomeCidade': 'Salgado Filho',
          },
          {
            'id_cidade': 4122909,
            'nomeCidade': 'Salto do Itararé',
          },
          {
            'id_cidade': 4123006,
            'nomeCidade': 'Salto do Lontra',
          },
          {
            'id_cidade': 4123105,
            'nomeCidade': 'Santa Amélia',
          },
          {
            'id_cidade': 4123204,
            'nomeCidade': 'Santa Cecília do Pavão',
          },
          {
            'id_cidade': 4123303,
            'nomeCidade': 'Santa Cruz de Monte Castelo',
          },
          {
            'id_cidade': 4123402,
            'nomeCidade': 'Santa Fé',
          },
          {
            'id_cidade': 4123501,
            'nomeCidade': 'Santa Helena',
          },
          {
            'id_cidade': 4123600,
            'nomeCidade': 'Santa Inês',
          },
          {
            'id_cidade': 4123709,
            'nomeCidade': 'Santa Isabel do Ivaí',
          },
          {
            'id_cidade': 4123808,
            'nomeCidade': 'Santa Izabel do Oeste',
          },
          {
            'id_cidade': 4123824,
            'nomeCidade': 'Santa Lúcia',
          },
          {
            'id_cidade': 4123857,
            'nomeCidade': 'Santa Maria do Oeste',
          },
          {
            'id_cidade': 4123907,
            'nomeCidade': 'Santa Mariana',
          },
          {
            'id_cidade': 4123956,
            'nomeCidade': 'Santa Mônica',
          },
          {
            'id_cidade': 4124020,
            'nomeCidade': 'Santa Tereza do Oeste',
          },
          {
            'id_cidade': 4124053,
            'nomeCidade': 'Santa Terezinha de Itaipu',
          },
          {
            'id_cidade': 4124004,
            'nomeCidade': 'Santana do Itararé',
          },
          {
            'id_cidade': 4124103,
            'nomeCidade': 'Santo Antônio da Platina',
          },
          {
            'id_cidade': 4124202,
            'nomeCidade': 'Santo Antônio do Caiuá',
          },
          {
            'id_cidade': 4124301,
            'nomeCidade': 'Santo Antônio do Paraíso',
          },
          {
            'id_cidade': 4124400,
            'nomeCidade': 'Santo Antônio do Sudoeste',
          },
          {
            'id_cidade': 4124509,
            'nomeCidade': 'Santo Inácio',
          },
          {
            'id_cidade': 4124608,
            'nomeCidade': 'São Carlos do Ivaí',
          },
          {
            'id_cidade': 4124707,
            'nomeCidade': 'São Jerônimo da Serra',
          },
          {
            'id_cidade': 4124806,
            'nomeCidade': 'São João',
          },
          {
            'id_cidade': 4124905,
            'nomeCidade': 'São João do Caiuá',
          },
          {
            'id_cidade': 4125001,
            'nomeCidade': 'São João do Ivaí',
          },
          {
            'id_cidade': 4125100,
            'nomeCidade': 'São João do Triunfo',
          },
          {
            'id_cidade': 4125308,
            'nomeCidade': 'São Jorge do Ivaí',
          },
          {
            'id_cidade': 4125357,
            'nomeCidade': 'São Jorge do Patrocínio',
          },
          {
            'id_cidade': 4125209,
            'nomeCidade': "São Jorge d'Oeste",
          },
          {
            'id_cidade': 4125407,
            'nomeCidade': 'São José da Boa Vista',
          },
          {
            'id_cidade': 4125456,
            'nomeCidade': 'São José das Palmeiras',
          },
          {
            'id_cidade': 4125506,
            'nomeCidade': 'São José dos Pinhais',
          },
          {
            'id_cidade': 4125555,
            'nomeCidade': 'São Manoel do Paraná',
          },
          {
            'id_cidade': 4125605,
            'nomeCidade': 'São Mateus do Sul',
          },
          {
            'id_cidade': 4125704,
            'nomeCidade': 'São Miguel do Iguaçu',
          },
          {
            'id_cidade': 4125753,
            'nomeCidade': 'São Pedro do Iguaçu',
          },
          {
            'id_cidade': 4125803,
            'nomeCidade': 'São Pedro do Ivaí',
          },
          {
            'id_cidade': 4125902,
            'nomeCidade': 'São Pedro do Paraná',
          },
          {
            'id_cidade': 4126009,
            'nomeCidade': 'São Sebastião da Amoreira',
          },
          {
            'id_cidade': 4126108,
            'nomeCidade': 'São Tomé',
          },
          {
            'id_cidade': 4126207,
            'nomeCidade': 'Sapopema',
          },
          {
            'id_cidade': 4126256,
            'nomeCidade': 'Sarandi',
          },
          {
            'id_cidade': 4126272,
            'nomeCidade': 'Saudade do Iguaçu',
          },
          {
            'id_cidade': 4126306,
            'nomeCidade': 'Sengés',
          },
          {
            'id_cidade': 4126355,
            'nomeCidade': 'Serranópolis do Iguaçu',
          },
          {
            'id_cidade': 4126405,
            'nomeCidade': 'Sertaneja',
          },
          {
            'id_cidade': 4126504,
            'nomeCidade': 'Sertanópolis',
          },
          {
            'id_cidade': 4126603,
            'nomeCidade': 'Siqueira Campos',
          },
          {
            'id_cidade': 4126652,
            'nomeCidade': 'Sulina',
          },
          {
            'id_cidade': 4126678,
            'nomeCidade': 'Tamarana',
          },
          {
            'id_cidade': 4126702,
            'nomeCidade': 'Tamboara',
          },
          {
            'id_cidade': 4126801,
            'nomeCidade': 'Tapejara',
          },
          {
            'id_cidade': 4126900,
            'nomeCidade': 'Tapira',
          },
          {
            'id_cidade': 4127007,
            'nomeCidade': 'Teixeira Soares',
          },
          {
            'id_cidade': 4127106,
            'nomeCidade': 'Telêmaco Borba',
          },
          {
            'id_cidade': 4127205,
            'nomeCidade': 'Terra Boa',
          },
          {
            'id_cidade': 4127304,
            'nomeCidade': 'Terra Rica',
          },
          {
            'id_cidade': 4127403,
            'nomeCidade': 'Terra Roxa',
          },
          {
            'id_cidade': 4127502,
            'nomeCidade': 'Tibagi',
          },
          {
            'id_cidade': 4127601,
            'nomeCidade': 'Tijucas do Sul',
          },
          {
            'id_cidade': 4127700,
            'nomeCidade': 'Toledo',
          },
          {
            'id_cidade': 4127809,
            'nomeCidade': 'Tomazina',
          },
          {
            'id_cidade': 4127858,
            'nomeCidade': 'Três Barras do Paraná',
          },
          {
            'id_cidade': 4127882,
            'nomeCidade': 'Tunas do Paraná',
          },
          {
            'id_cidade': 4127908,
            'nomeCidade': 'Tuneiras do Oeste',
          },
          {
            'id_cidade': 4127957,
            'nomeCidade': 'Tupãssi',
          },
          {
            'id_cidade': 4127965,
            'nomeCidade': 'Turvo',
          },
          {
            'id_cidade': 4128005,
            'nomeCidade': 'Ubiratã',
          },
          {
            'id_cidade': 4128104,
            'nomeCidade': 'Umuarama',
          },
          {
            'id_cidade': 4128203,
            'nomeCidade': 'União da Vitória',
          },
          {
            'id_cidade': 4128302,
            'nomeCidade': 'Uniflor',
          },
          {
            'id_cidade': 4128401,
            'nomeCidade': 'Uraí',
          },
          {
            'id_cidade': 4128534,
            'nomeCidade': 'Ventania',
          },
          {
            'id_cidade': 4128559,
            'nomeCidade': 'Vera Cruz do Oeste',
          },
          {
            'id_cidade': 4128609,
            'nomeCidade': 'Verê',
          },
          {
            'id_cidade': 4128658,
            'nomeCidade': 'Virmond',
          },
          {
            'id_cidade': 4128708,
            'nomeCidade': 'Vitorino',
          },
          {
            'id_cidade': 4128500,
            'nomeCidade': 'Wenceslau Braz',
          },
          {
            'id_cidade': 4128807,
            'nomeCidade': 'Xambrê',
          },
        ],
      },
      {
        'id': 26,
        'nomeEstado': 'Pernambuco',
        'sigla': 'PE',
        'cidades': [
          {
            'id_cidade': 2600054,
            'nomeCidade': 'Abreu e Lima',
          },
          {
            'id_cidade': 2600104,
            'nomeCidade': 'Afogados da Ingazeira',
          },
          {
            'id_cidade': 2600203,
            'nomeCidade': 'Afrânio',
          },
          {
            'id_cidade': 2600302,
            'nomeCidade': 'Agrestina',
          },
          {
            'id_cidade': 2600401,
            'nomeCidade': 'Água Preta',
          },
          {
            'id_cidade': 2600500,
            'nomeCidade': 'Águas Belas',
          },
          {
            'id_cidade': 2600609,
            'nomeCidade': 'Alagoinha',
          },
          {
            'id_cidade': 2600708,
            'nomeCidade': 'Aliança',
          },
          {
            'id_cidade': 2600807,
            'nomeCidade': 'Altinho',
          },
          {
            'id_cidade': 2600906,
            'nomeCidade': 'Amaraji',
          },
          {
            'id_cidade': 2601003,
            'nomeCidade': 'Angelim',
          },
          {
            'id_cidade': 2601052,
            'nomeCidade': 'Araçoiaba',
          },
          {
            'id_cidade': 2601102,
            'nomeCidade': 'Araripina',
          },
          {
            'id_cidade': 2601201,
            'nomeCidade': 'Arcoverde',
          },
          {
            'id_cidade': 2601300,
            'nomeCidade': 'Barra de Guabiraba',
          },
          {
            'id_cidade': 2601409,
            'nomeCidade': 'Barreiros',
          },
          {
            'id_cidade': 2601508,
            'nomeCidade': 'Belém de Maria',
          },
          {
            'id_cidade': 2601607,
            'nomeCidade': 'Belém do São Francisco',
          },
          {
            'id_cidade': 2601706,
            'nomeCidade': 'Belo Jardim',
          },
          {
            'id_cidade': 2601805,
            'nomeCidade': 'Betânia',
          },
          {
            'id_cidade': 2601904,
            'nomeCidade': 'Bezerros',
          },
          {
            'id_cidade': 2602001,
            'nomeCidade': 'Bodocó',
          },
          {
            'id_cidade': 2602100,
            'nomeCidade': 'Bom Conselho',
          },
          {
            'id_cidade': 2602209,
            'nomeCidade': 'Bom Jardim',
          },
          {
            'id_cidade': 2602308,
            'nomeCidade': 'Bonito',
          },
          {
            'id_cidade': 2602407,
            'nomeCidade': 'Brejão',
          },
          {
            'id_cidade': 2602506,
            'nomeCidade': 'Brejinho',
          },
          {
            'id_cidade': 2602605,
            'nomeCidade': 'Brejo da Madre de Deus',
          },
          {
            'id_cidade': 2602704,
            'nomeCidade': 'Buenos Aires',
          },
          {
            'id_cidade': 2602803,
            'nomeCidade': 'Buíque',
          },
          {
            'id_cidade': 2602902,
            'nomeCidade': 'Cabo de Santo Agostinho',
          },
          {
            'id_cidade': 2603009,
            'nomeCidade': 'Cabrobó',
          },
          {
            'id_cidade': 2603108,
            'nomeCidade': 'Cachoeirinha',
          },
          {
            'id_cidade': 2603207,
            'nomeCidade': 'Caetés',
          },
          {
            'id_cidade': 2603306,
            'nomeCidade': 'Calçado',
          },
          {
            'id_cidade': 2603405,
            'nomeCidade': 'Calumbi',
          },
          {
            'id_cidade': 2603454,
            'nomeCidade': 'Camaragibe',
          },
          {
            'id_cidade': 2603504,
            'nomeCidade': 'Camocim de São Félix',
          },
          {
            'id_cidade': 2603603,
            'nomeCidade': 'Camutanga',
          },
          {
            'id_cidade': 2603702,
            'nomeCidade': 'Canhotinho',
          },
          {
            'id_cidade': 2603801,
            'nomeCidade': 'Capoeiras',
          },
          {
            'id_cidade': 2603900,
            'nomeCidade': 'Carnaíba',
          },
          {
            'id_cidade': 2603926,
            'nomeCidade': 'Carnaubeira da Penha',
          },
          {
            'id_cidade': 2604007,
            'nomeCidade': 'Carpina',
          },
          {
            'id_cidade': 2604106,
            'nomeCidade': 'Caruaru',
          },
          {
            'id_cidade': 2604155,
            'nomeCidade': 'Casinhas',
          },
          {
            'id_cidade': 2604205,
            'nomeCidade': 'Catende',
          },
          {
            'id_cidade': 2604304,
            'nomeCidade': 'Cedro',
          },
          {
            'id_cidade': 2604403,
            'nomeCidade': 'Chã de Alegria',
          },
          {
            'id_cidade': 2604502,
            'nomeCidade': 'Chã Grande',
          },
          {
            'id_cidade': 2604601,
            'nomeCidade': 'Condado',
          },
          {
            'id_cidade': 2604700,
            'nomeCidade': 'Correntes',
          },
          {
            'id_cidade': 2604809,
            'nomeCidade': 'Cortês',
          },
          {
            'id_cidade': 2604908,
            'nomeCidade': 'Cumaru',
          },
          {
            'id_cidade': 2605004,
            'nomeCidade': 'Cupira',
          },
          {
            'id_cidade': 2605103,
            'nomeCidade': 'Custódia',
          },
          {
            'id_cidade': 2605152,
            'nomeCidade': 'Dormentes',
          },
          {
            'id_cidade': 2605202,
            'nomeCidade': 'Escada',
          },
          {
            'id_cidade': 2605301,
            'nomeCidade': 'Exu',
          },
          {
            'id_cidade': 2605400,
            'nomeCidade': 'Feira Nova',
          },
          {
            'id_cidade': 2605459,
            'nomeCidade': 'Fernando de Noronha',
          },
          {
            'id_cidade': 2605509,
            'nomeCidade': 'Ferreiros',
          },
          {
            'id_cidade': 2605608,
            'nomeCidade': 'Flores',
          },
          {
            'id_cidade': 2605707,
            'nomeCidade': 'Floresta',
          },
          {
            'id_cidade': 2605806,
            'nomeCidade': 'Frei Miguelinho',
          },
          {
            'id_cidade': 2605905,
            'nomeCidade': 'Gameleira',
          },
          {
            'id_cidade': 2606002,
            'nomeCidade': 'Garanhuns',
          },
          {
            'id_cidade': 2606101,
            'nomeCidade': 'Glória do Goitá',
          },
          {
            'id_cidade': 2606200,
            'nomeCidade': 'Goiana',
          },
          {
            'id_cidade': 2606309,
            'nomeCidade': 'Granito',
          },
          {
            'id_cidade': 2606408,
            'nomeCidade': 'Gravatá',
          },
          {
            'id_cidade': 2606507,
            'nomeCidade': 'Iati',
          },
          {
            'id_cidade': 2606606,
            'nomeCidade': 'Ibimirim',
          },
          {
            'id_cidade': 2606705,
            'nomeCidade': 'Ibirajuba',
          },
          {
            'id_cidade': 2606804,
            'nomeCidade': 'Igarassu',
          },
          {
            'id_cidade': 2606903,
            'nomeCidade': 'Iguaracy',
          },
          {
            'id_cidade': 2607604,
            'nomeCidade': 'Ilha de Itamaracá',
          },
          {
            'id_cidade': 2607000,
            'nomeCidade': 'Inajá',
          },
          {
            'id_cidade': 2607109,
            'nomeCidade': 'Ingazeira',
          },
          {
            'id_cidade': 2607208,
            'nomeCidade': 'Ipojuca',
          },
          {
            'id_cidade': 2607307,
            'nomeCidade': 'Ipubi',
          },
          {
            'id_cidade': 2607406,
            'nomeCidade': 'Itacuruba',
          },
          {
            'id_cidade': 2607505,
            'nomeCidade': 'Itaíba',
          },
          {
            'id_cidade': 2607653,
            'nomeCidade': 'Itambé',
          },
          {
            'id_cidade': 2607703,
            'nomeCidade': 'Itapetim',
          },
          {
            'id_cidade': 2607752,
            'nomeCidade': 'Itapissuma',
          },
          {
            'id_cidade': 2607802,
            'nomeCidade': 'Itaquitinga',
          },
          {
            'id_cidade': 2607901,
            'nomeCidade': 'Jaboatão dos Guararapes',
          },
          {
            'id_cidade': 2607950,
            'nomeCidade': 'Jaqueira',
          },
          {
            'id_cidade': 2608008,
            'nomeCidade': 'Jataúba',
          },
          {
            'id_cidade': 2608057,
            'nomeCidade': 'Jatobá',
          },
          {
            'id_cidade': 2608107,
            'nomeCidade': 'João Alfredo',
          },
          {
            'id_cidade': 2608206,
            'nomeCidade': 'Joaquim Nabuco',
          },
          {
            'id_cidade': 2608255,
            'nomeCidade': 'Jucati',
          },
          {
            'id_cidade': 2608305,
            'nomeCidade': 'Jupi',
          },
          {
            'id_cidade': 2608404,
            'nomeCidade': 'Jurema',
          },
          {
            'id_cidade': 2608503,
            'nomeCidade': 'Lagoa de Itaenga',
          },
          {
            'id_cidade': 2608453,
            'nomeCidade': 'Lagoa do Carro',
          },
          {
            'id_cidade': 2608602,
            'nomeCidade': 'Lagoa do Ouro',
          },
          {
            'id_cidade': 2608701,
            'nomeCidade': 'Lagoa dos Gatos',
          },
          {
            'id_cidade': 2608750,
            'nomeCidade': 'Lagoa Grande',
          },
          {
            'id_cidade': 2608800,
            'nomeCidade': 'Lajedo',
          },
          {
            'id_cidade': 2608909,
            'nomeCidade': 'Limoeiro',
          },
          {
            'id_cidade': 2609006,
            'nomeCidade': 'Macaparana',
          },
          {
            'id_cidade': 2609105,
            'nomeCidade': 'Machados',
          },
          {
            'id_cidade': 2609154,
            'nomeCidade': 'Manari',
          },
          {
            'id_cidade': 2609204,
            'nomeCidade': 'Maraial',
          },
          {
            'id_cidade': 2609303,
            'nomeCidade': 'Mirandiba',
          },
          {
            'id_cidade': 2614303,
            'nomeCidade': 'Moreilândia',
          },
          {
            'id_cidade': 2609402,
            'nomeCidade': 'Moreno',
          },
          {
            'id_cidade': 2609501,
            'nomeCidade': 'Nazaré da Mata',
          },
          {
            'id_cidade': 2609600,
            'nomeCidade': 'Olinda',
          },
          {
            'id_cidade': 2609709,
            'nomeCidade': 'Orobó',
          },
          {
            'id_cidade': 2609808,
            'nomeCidade': 'Orocó',
          },
          {
            'id_cidade': 2609907,
            'nomeCidade': 'Ouricuri',
          },
          {
            'id_cidade': 2610004,
            'nomeCidade': 'Palmares',
          },
          {
            'id_cidade': 2610103,
            'nomeCidade': 'Palmeirina',
          },
          {
            'id_cidade': 2610202,
            'nomeCidade': 'Panelas',
          },
          {
            'id_cidade': 2610301,
            'nomeCidade': 'Paranatama',
          },
          {
            'id_cidade': 2610400,
            'nomeCidade': 'Parnamirim',
          },
          {
            'id_cidade': 2610509,
            'nomeCidade': 'Passira',
          },
          {
            'id_cidade': 2610608,
            'nomeCidade': 'Paudalho',
          },
          {
            'id_cidade': 2610707,
            'nomeCidade': 'Paulista',
          },
          {
            'id_cidade': 2610806,
            'nomeCidade': 'Pedra',
          },
          {
            'id_cidade': 2610905,
            'nomeCidade': 'Pesqueira',
          },
          {
            'id_cidade': 2611002,
            'nomeCidade': 'Petrolândia',
          },
          {
            'id_cidade': 2611101,
            'nomeCidade': 'Petrolina',
          },
          {
            'id_cidade': 2611200,
            'nomeCidade': 'Poção',
          },
          {
            'id_cidade': 2611309,
            'nomeCidade': 'Pombos',
          },
          {
            'id_cidade': 2611408,
            'nomeCidade': 'Primavera',
          },
          {
            'id_cidade': 2611507,
            'nomeCidade': 'Quipapá',
          },
          {
            'id_cidade': 2611533,
            'nomeCidade': 'Quixaba',
          },
          {
            'id_cidade': 2611606,
            'nomeCidade': 'Recife',
          },
          {
            'id_cidade': 2611705,
            'nomeCidade': 'Riacho das Almas',
          },
          {
            'id_cidade': 2611804,
            'nomeCidade': 'Ribeirão',
          },
          {
            'id_cidade': 2611903,
            'nomeCidade': 'Rio Formoso',
          },
          {
            'id_cidade': 2612000,
            'nomeCidade': 'Sairé',
          },
          {
            'id_cidade': 2612109,
            'nomeCidade': 'Salgadinho',
          },
          {
            'id_cidade': 2612208,
            'nomeCidade': 'Salgueiro',
          },
          {
            'id_cidade': 2612307,
            'nomeCidade': 'Saloá',
          },
          {
            'id_cidade': 2612406,
            'nomeCidade': 'Sanharó',
          },
          {
            'id_cidade': 2612455,
            'nomeCidade': 'Santa Cruz',
          },
          {
            'id_cidade': 2612471,
            'nomeCidade': 'Santa Cruz da Baixa Verde',
          },
          {
            'id_cidade': 2612505,
            'nomeCidade': 'Santa Cruz do Capibaribe',
          },
          {
            'id_cidade': 2612554,
            'nomeCidade': 'Santa Filomena',
          },
          {
            'id_cidade': 2612604,
            'nomeCidade': 'Santa Maria da Boa Vista',
          },
          {
            'id_cidade': 2612703,
            'nomeCidade': 'Santa Maria do Cambucá',
          },
          {
            'id_cidade': 2612802,
            'nomeCidade': 'Santa Terezinha',
          },
          {
            'id_cidade': 2612901,
            'nomeCidade': 'São Benedito do Sul',
          },
          {
            'id_cidade': 2613008,
            'nomeCidade': 'São Bento do Una',
          },
          {
            'id_cidade': 2613107,
            'nomeCidade': 'São Caitano',
          },
          {
            'id_cidade': 2613206,
            'nomeCidade': 'São João',
          },
          {
            'id_cidade': 2613305,
            'nomeCidade': 'São Joaquim do Monte',
          },
          {
            'id_cidade': 2613404,
            'nomeCidade': 'São José da Coroa Grande',
          },
          {
            'id_cidade': 2613503,
            'nomeCidade': 'São José do Belmonte',
          },
          {
            'id_cidade': 2613602,
            'nomeCidade': 'São José do Egito',
          },
          {
            'id_cidade': 2613701,
            'nomeCidade': 'São Lourenço da Mata',
          },
          {
            'id_cidade': 2613800,
            'nomeCidade': 'São Vicente Férrer',
          },
          {
            'id_cidade': 2613909,
            'nomeCidade': 'Serra Talhada',
          },
          {
            'id_cidade': 2614006,
            'nomeCidade': 'Serrita',
          },
          {
            'id_cidade': 2614105,
            'nomeCidade': 'Sertânia',
          },
          {
            'id_cidade': 2614204,
            'nomeCidade': 'Sirinhaém',
          },
          {
            'id_cidade': 2614402,
            'nomeCidade': 'Solidão',
          },
          {
            'id_cidade': 2614501,
            'nomeCidade': 'Surubim',
          },
          {
            'id_cidade': 2614600,
            'nomeCidade': 'Tabira',
          },
          {
            'id_cidade': 2614709,
            'nomeCidade': 'Tacaimbó',
          },
          {
            'id_cidade': 2614808,
            'nomeCidade': 'Tacaratu',
          },
          {
            'id_cidade': 2614857,
            'nomeCidade': 'Tamandaré',
          },
          {
            'id_cidade': 2615003,
            'nomeCidade': 'Taquaritinga do Norte',
          },
          {
            'id_cidade': 2615102,
            'nomeCidade': 'Terezinha',
          },
          {
            'id_cidade': 2615201,
            'nomeCidade': 'Terra Nova',
          },
          {
            'id_cidade': 2615300,
            'nomeCidade': 'Timbaúba',
          },
          {
            'id_cidade': 2615409,
            'nomeCidade': 'Toritama',
          },
          {
            'id_cidade': 2615508,
            'nomeCidade': 'Tracunhaém',
          },
          {
            'id_cidade': 2615607,
            'nomeCidade': 'Trindade',
          },
          {
            'id_cidade': 2615706,
            'nomeCidade': 'Triunfo',
          },
          {
            'id_cidade': 2615805,
            'nomeCidade': 'Tupanatinga',
          },
          {
            'id_cidade': 2615904,
            'nomeCidade': 'Tuparetama',
          },
          {
            'id_cidade': 2616001,
            'nomeCidade': 'Venturosa',
          },
          {
            'id_cidade': 2616100,
            'nomeCidade': 'Verdejante',
          },
          {
            'id_cidade': 2616183,
            'nomeCidade': 'Vertente do Lério',
          },
          {
            'id_cidade': 2616209,
            'nomeCidade': 'Vertentes',
          },
          {
            'id_cidade': 2616308,
            'nomeCidade': 'Vicência',
          },
          {
            'id_cidade': 2616407,
            'nomeCidade': 'Vitória de Santo Antão',
          },
          {
            'id_cidade': 2616506,
            'nomeCidade': 'Xexéu',
          },
        ],
      },
      {
        'id': 22,
        'nomeEstado': 'Piauí',
        'sigla': 'PI',
        'cidades': [
          {
            'id_cidade': 2200053,
            'nomeCidade': 'Acauã',
          },
          {
            'id_cidade': 2200103,
            'nomeCidade': 'Agricolândia',
          },
          {
            'id_cidade': 2200202,
            'nomeCidade': 'Água Branca',
          },
          {
            'id_cidade': 2200251,
            'nomeCidade': 'Alagoinha do Piauí',
          },
          {
            'id_cidade': 2200277,
            'nomeCidade': 'Alegrete do Piauí',
          },
          {
            'id_cidade': 2200301,
            'nomeCidade': 'Alto Longá',
          },
          {
            'id_cidade': 2200400,
            'nomeCidade': 'Altos',
          },
          {
            'id_cidade': 2200459,
            'nomeCidade': 'Alvorada do Gurguéia',
          },
          {
            'id_cidade': 2200509,
            'nomeCidade': 'Amarante',
          },
          {
            'id_cidade': 2200608,
            'nomeCidade': 'Angical do Piauí',
          },
          {
            'id_cidade': 2200707,
            'nomeCidade': 'Anísio de Abreu',
          },
          {
            'id_cidade': 2200806,
            'nomeCidade': 'Antônio Almeida',
          },
          {
            'id_cidade': 2200905,
            'nomeCidade': 'Aroazes',
          },
          {
            'id_cidade': 2200954,
            'nomeCidade': 'Aroeiras do Itaim',
          },
          {
            'id_cidade': 2201002,
            'nomeCidade': 'Arraial',
          },
          {
            'id_cidade': 2201051,
            'nomeCidade': 'Assunção do Piauí',
          },
          {
            'id_cidade': 2201101,
            'nomeCidade': 'Avelino Lopes',
          },
          {
            'id_cidade': 2201150,
            'nomeCidade': 'Baixa Grande do Ribeiro',
          },
          {
            'id_cidade': 2201176,
            'nomeCidade': "Barra D'Alcântara",
          },
          {
            'id_cidade': 2201200,
            'nomeCidade': 'Barras',
          },
          {
            'id_cidade': 2201309,
            'nomeCidade': 'Barreiras do Piauí',
          },
          {
            'id_cidade': 2201408,
            'nomeCidade': 'Barro Duro',
          },
          {
            'id_cidade': 2201507,
            'nomeCidade': 'Batalha',
          },
          {
            'id_cidade': 2201556,
            'nomeCidade': 'Bela Vista do Piauí',
          },
          {
            'id_cidade': 2201572,
            'nomeCidade': 'Belém do Piauí',
          },
          {
            'id_cidade': 2201606,
            'nomeCidade': 'Beneditinos',
          },
          {
            'id_cidade': 2201705,
            'nomeCidade': 'Bertolínia',
          },
          {
            'id_cidade': 2201739,
            'nomeCidade': 'Betânia do Piauí',
          },
          {
            'id_cidade': 2201770,
            'nomeCidade': 'Boa Hora',
          },
          {
            'id_cidade': 2201804,
            'nomeCidade': 'Bocaina',
          },
          {
            'id_cidade': 2201903,
            'nomeCidade': 'Bom Jesus',
          },
          {
            'id_cidade': 2201919,
            'nomeCidade': 'Bom Princípio do Piauí',
          },
          {
            'id_cidade': 2201929,
            'nomeCidade': 'Bonfim do Piauí',
          },
          {
            'id_cidade': 2201945,
            'nomeCidade': 'Boqueirão do Piauí',
          },
          {
            'id_cidade': 2201960,
            'nomeCidade': 'Brasileira',
          },
          {
            'id_cidade': 2201988,
            'nomeCidade': 'Brejo do Piauí',
          },
          {
            'id_cidade': 2202000,
            'nomeCidade': 'Buriti dos Lopes',
          },
          {
            'id_cidade': 2202026,
            'nomeCidade': 'Buriti dos Montes',
          },
          {
            'id_cidade': 2202059,
            'nomeCidade': 'Cabeceiras do Piauí',
          },
          {
            'id_cidade': 2202075,
            'nomeCidade': 'Cajazeiras do Piauí',
          },
          {
            'id_cidade': 2202083,
            'nomeCidade': 'Cajueiro da Praia',
          },
          {
            'id_cidade': 2202091,
            'nomeCidade': 'Caldeirão Grande do Piauí',
          },
          {
            'id_cidade': 2202109,
            'nomeCidade': 'Campinas do Piauí',
          },
          {
            'id_cidade': 2202117,
            'nomeCidade': 'Campo Alegre do Fidalgo',
          },
          {
            'id_cidade': 2202133,
            'nomeCidade': 'Campo Grande do Piauí',
          },
          {
            'id_cidade': 2202174,
            'nomeCidade': 'Campo Largo do Piauí',
          },
          {
            'id_cidade': 2202208,
            'nomeCidade': 'Campo Maior',
          },
          {
            'id_cidade': 2202251,
            'nomeCidade': 'Canavieira',
          },
          {
            'id_cidade': 2202307,
            'nomeCidade': 'Canto do Buriti',
          },
          {
            'id_cidade': 2202406,
            'nomeCidade': 'Capitão de Campos',
          },
          {
            'id_cidade': 2202455,
            'nomeCidade': 'Capitão Gervásio Oliveira',
          },
          {
            'id_cidade': 2202505,
            'nomeCidade': 'Caracol',
          },
          {
            'id_cidade': 2202539,
            'nomeCidade': 'Caraúbas do Piauí',
          },
          {
            'id_cidade': 2202554,
            'nomeCidade': 'Caridade do Piauí',
          },
          {
            'id_cidade': 2202604,
            'nomeCidade': 'Castelo do Piauí',
          },
          {
            'id_cidade': 2202653,
            'nomeCidade': 'Caxingó',
          },
          {
            'id_cidade': 2202703,
            'nomeCidade': 'Cocal',
          },
          {
            'id_cidade': 2202711,
            'nomeCidade': 'Cocal de Telha',
          },
          {
            'id_cidade': 2202729,
            'nomeCidade': 'Cocal dos Alves',
          },
          {
            'id_cidade': 2202737,
            'nomeCidade': 'Coivaras',
          },
          {
            'id_cidade': 2202752,
            'nomeCidade': 'Colônia do Gurguéia',
          },
          {
            'id_cidade': 2202778,
            'nomeCidade': 'Colônia do Piauí',
          },
          {
            'id_cidade': 2202802,
            'nomeCidade': 'Conceição do Canindé',
          },
          {
            'id_cidade': 2202851,
            'nomeCidade': 'Coronel José Dias',
          },
          {
            'id_cidade': 2202901,
            'nomeCidade': 'Corrente',
          },
          {
            'id_cidade': 2203008,
            'nomeCidade': 'Cristalândia do Piauí',
          },
          {
            'id_cidade': 2203107,
            'nomeCidade': 'Cristino Castro',
          },
          {
            'id_cidade': 2203206,
            'nomeCidade': 'Curimatá',
          },
          {
            'id_cidade': 2203230,
            'nomeCidade': 'Currais',
          },
          {
            'id_cidade': 2203271,
            'nomeCidade': 'Curral Novo do Piauí',
          },
          {
            'id_cidade': 2203255,
            'nomeCidade': 'Curralinhos',
          },
          {
            'id_cidade': 2203305,
            'nomeCidade': 'Demerval Lobão',
          },
          {
            'id_cidade': 2203354,
            'nomeCidade': 'Dirceu Arcoverde',
          },
          {
            'id_cidade': 2203404,
            'nomeCidade': 'Dom Expedito Lopes',
          },
          {
            'id_cidade': 2203453,
            'nomeCidade': 'Dom Inocêncio',
          },
          {
            'id_cidade': 2203420,
            'nomeCidade': 'Domingos Mourão',
          },
          {
            'id_cidade': 2203503,
            'nomeCidade': 'Elesbão Veloso',
          },
          {
            'id_cidade': 2203602,
            'nomeCidade': 'Eliseu Martins',
          },
          {
            'id_cidade': 2203701,
            'nomeCidade': 'Esperantina',
          },
          {
            'id_cidade': 2203750,
            'nomeCidade': 'Fartura do Piauí',
          },
          {
            'id_cidade': 2203800,
            'nomeCidade': 'Flores do Piauí',
          },
          {
            'id_cidade': 2203859,
            'nomeCidade': 'Floresta do Piauí',
          },
          {
            'id_cidade': 2203909,
            'nomeCidade': 'Floriano',
          },
          {
            'id_cidade': 2204006,
            'nomeCidade': 'Francinópolis',
          },
          {
            'id_cidade': 2204105,
            'nomeCidade': 'Francisco Ayres',
          },
          {
            'id_cidade': 2204154,
            'nomeCidade': 'Francisco Macedo',
          },
          {
            'id_cidade': 2204204,
            'nomeCidade': 'Francisco Santos',
          },
          {
            'id_cidade': 2204303,
            'nomeCidade': 'Fronteiras',
          },
          {
            'id_cidade': 2204352,
            'nomeCidade': 'Geminiano',
          },
          {
            'id_cidade': 2204402,
            'nomeCidade': 'Gilbués',
          },
          {
            'id_cidade': 2204501,
            'nomeCidade': 'Guadalupe',
          },
          {
            'id_cidade': 2204550,
            'nomeCidade': 'Guaribas',
          },
          {
            'id_cidade': 2204600,
            'nomeCidade': 'Hugo Napoleão',
          },
          {
            'id_cidade': 2204659,
            'nomeCidade': 'Ilha Grande',
          },
          {
            'id_cidade': 2204709,
            'nomeCidade': 'Inhuma',
          },
          {
            'id_cidade': 2204808,
            'nomeCidade': 'Ipiranga do Piauí',
          },
          {
            'id_cidade': 2204907,
            'nomeCidade': 'Isaías Coelho',
          },
          {
            'id_cidade': 2205003,
            'nomeCidade': 'Itainópolis',
          },
          {
            'id_cidade': 2205102,
            'nomeCidade': 'Itaueira',
          },
          {
            'id_cidade': 2205151,
            'nomeCidade': 'Jacobina do Piauí',
          },
          {
            'id_cidade': 2205201,
            'nomeCidade': 'Jaicós',
          },
          {
            'id_cidade': 2205250,
            'nomeCidade': 'Jardim do Mulato',
          },
          {
            'id_cidade': 2205276,
            'nomeCidade': 'Jatobá do Piauí',
          },
          {
            'id_cidade': 2205300,
            'nomeCidade': 'Jerumenha',
          },
          {
            'id_cidade': 2205359,
            'nomeCidade': 'João Costa',
          },
          {
            'id_cidade': 2205409,
            'nomeCidade': 'Joaquim Pires',
          },
          {
            'id_cidade': 2205458,
            'nomeCidade': 'Joca Marques',
          },
          {
            'id_cidade': 2205508,
            'nomeCidade': 'José de Freitas',
          },
          {
            'id_cidade': 2205516,
            'nomeCidade': 'Juazeiro do Piauí',
          },
          {
            'id_cidade': 2205524,
            'nomeCidade': 'Júlio Borges',
          },
          {
            'id_cidade': 2205532,
            'nomeCidade': 'Jurema',
          },
          {
            'id_cidade': 2205557,
            'nomeCidade': 'Lagoa Alegre',
          },
          {
            'id_cidade': 2205573,
            'nomeCidade': 'Lagoa de São Francisco',
          },
          {
            'id_cidade': 2205565,
            'nomeCidade': 'Lagoa do Barro do Piauí',
          },
          {
            'id_cidade': 2205581,
            'nomeCidade': 'Lagoa do Piauí',
          },
          {
            'id_cidade': 2205599,
            'nomeCidade': 'Lagoa do Sítio',
          },
          {
            'id_cidade': 2205540,
            'nomeCidade': 'Lagoinha do Piauí',
          },
          {
            'id_cidade': 2205607,
            'nomeCidade': 'Landri Sales',
          },
          {
            'id_cidade': 2205706,
            'nomeCidade': 'Luís Correia',
          },
          {
            'id_cidade': 2205805,
            'nomeCidade': 'Luzilândia',
          },
          {
            'id_cidade': 2205854,
            'nomeCidade': 'Madeiro',
          },
          {
            'id_cidade': 2205904,
            'nomeCidade': 'Manoel Emídio',
          },
          {
            'id_cidade': 2205953,
            'nomeCidade': 'Marcolândia',
          },
          {
            'id_cidade': 2206001,
            'nomeCidade': 'Marcos Parente',
          },
          {
            'id_cidade': 2206050,
            'nomeCidade': 'Massapê do Piauí',
          },
          {
            'id_cidade': 2206100,
            'nomeCidade': 'Matias Olímpio',
          },
          {
            'id_cidade': 2206209,
            'nomeCidade': 'Miguel Alves',
          },
          {
            'id_cidade': 2206308,
            'nomeCidade': 'Miguel Leão',
          },
          {
            'id_cidade': 2206357,
            'nomeCidade': 'Milton Brandão',
          },
          {
            'id_cidade': 2206407,
            'nomeCidade': 'Monsenhor Gil',
          },
          {
            'id_cidade': 2206506,
            'nomeCidade': 'Monsenhor Hipólito',
          },
          {
            'id_cidade': 2206605,
            'nomeCidade': 'Monte Alegre do Piauí',
          },
          {
            'id_cidade': 2206654,
            'nomeCidade': 'Morro Cabeça no Tempo',
          },
          {
            'id_cidade': 2206670,
            'nomeCidade': 'Morro do Chapéu do Piauí',
          },
          {
            'id_cidade': 2206696,
            'nomeCidade': 'Murici dos Portelas',
          },
          {
            'id_cidade': 2206704,
            'nomeCidade': 'Nazaré do Piauí',
          },
          {
            'id_cidade': 2206720,
            'nomeCidade': 'Nazária',
          },
          {
            'id_cidade': 2206753,
            'nomeCidade': 'Nossa Senhora de Nazaré',
          },
          {
            'id_cidade': 2206803,
            'nomeCidade': 'Nossa Senhora dos Remédios',
          },
          {
            'id_cidade': 2207959,
            'nomeCidade': 'Nova Santa Rita',
          },
          {
            'id_cidade': 2206902,
            'nomeCidade': 'Novo Oriente do Piauí',
          },
          {
            'id_cidade': 2206951,
            'nomeCidade': 'Novo Santo Antônio',
          },
          {
            'id_cidade': 2207009,
            'nomeCidade': 'Oeiras',
          },
          {
            'id_cidade': 2207108,
            'nomeCidade': "Olho D'Água do Piauí",
          },
          {
            'id_cidade': 2207207,
            'nomeCidade': 'Padre Marcos',
          },
          {
            'id_cidade': 2207306,
            'nomeCidade': 'Paes Landim',
          },
          {
            'id_cidade': 2207355,
            'nomeCidade': 'Pajeú do Piauí',
          },
          {
            'id_cidade': 2207405,
            'nomeCidade': 'Palmeira do Piauí',
          },
          {
            'id_cidade': 2207504,
            'nomeCidade': 'Palmeirais',
          },
          {
            'id_cidade': 2207553,
            'nomeCidade': 'Paquetá',
          },
          {
            'id_cidade': 2207603,
            'nomeCidade': 'Parnaguá',
          },
          {
            'id_cidade': 2207702,
            'nomeCidade': 'Parnaíba',
          },
          {
            'id_cidade': 2207751,
            'nomeCidade': 'Passagem Franca do Piauí',
          },
          {
            'id_cidade': 2207777,
            'nomeCidade': 'Patos do Piauí',
          },
          {
            'id_cidade': 2207793,
            'nomeCidade': "Pau D'Arco do Piauí",
          },
          {
            'id_cidade': 2207801,
            'nomeCidade': 'Paulistana',
          },
          {
            'id_cidade': 2207850,
            'nomeCidade': 'Pavussu',
          },
          {
            'id_cidade': 2207900,
            'nomeCidade': 'Pedro II',
          },
          {
            'id_cidade': 2207934,
            'nomeCidade': 'Pedro Laurentino',
          },
          {
            'id_cidade': 2208007,
            'nomeCidade': 'Picos',
          },
          {
            'id_cidade': 2208106,
            'nomeCidade': 'Pimenteiras',
          },
          {
            'id_cidade': 2208205,
            'nomeCidade': 'Pio IX',
          },
          {
            'id_cidade': 2208304,
            'nomeCidade': 'Piracuruca',
          },
          {
            'id_cidade': 2208403,
            'nomeCidade': 'Piripiri',
          },
          {
            'id_cidade': 2208502,
            'nomeCidade': 'Porto',
          },
          {
            'id_cidade': 2208551,
            'nomeCidade': 'Porto Alegre do Piauí',
          },
          {
            'id_cidade': 2208601,
            'nomeCidade': 'Prata do Piauí',
          },
          {
            'id_cidade': 2208650,
            'nomeCidade': 'Queimada Nova',
          },
          {
            'id_cidade': 2208700,
            'nomeCidade': 'Redenção do Gurguéia',
          },
          {
            'id_cidade': 2208809,
            'nomeCidade': 'Regeneração',
          },
          {
            'id_cidade': 2208858,
            'nomeCidade': 'Riacho Frio',
          },
          {
            'id_cidade': 2208874,
            'nomeCidade': 'Ribeira do Piauí',
          },
          {
            'id_cidade': 2208908,
            'nomeCidade': 'Ribeiro Gonçalves',
          },
          {
            'id_cidade': 2209005,
            'nomeCidade': 'Rio Grande do Piauí',
          },
          {
            'id_cidade': 2209104,
            'nomeCidade': 'Santa Cruz do Piauí',
          },
          {
            'id_cidade': 2209153,
            'nomeCidade': 'Santa Cruz dos Milagres',
          },
          {
            'id_cidade': 2209203,
            'nomeCidade': 'Santa Filomena',
          },
          {
            'id_cidade': 2209302,
            'nomeCidade': 'Santa Luz',
          },
          {
            'id_cidade': 2209377,
            'nomeCidade': 'Santa Rosa do Piauí',
          },
          {
            'id_cidade': 2209351,
            'nomeCidade': 'Santana do Piauí',
          },
          {
            'id_cidade': 2209401,
            'nomeCidade': 'Santo Antônio de Lisboa',
          },
          {
            'id_cidade': 2209450,
            'nomeCidade': 'Santo Antônio dos Milagres',
          },
          {
            'id_cidade': 2209500,
            'nomeCidade': 'Santo Inácio do Piauí',
          },
          {
            'id_cidade': 2209559,
            'nomeCidade': 'São Braz do Piauí',
          },
          {
            'id_cidade': 2209609,
            'nomeCidade': 'São Félix do Piauí',
          },
          {
            'id_cidade': 2209658,
            'nomeCidade': 'São Francisco de Assis do Piauí',
          },
          {
            'id_cidade': 2209708,
            'nomeCidade': 'São Francisco do Piauí',
          },
          {
            'id_cidade': 2209757,
            'nomeCidade': 'São Gonçalo do Gurguéia',
          },
          {
            'id_cidade': 2209807,
            'nomeCidade': 'São Gonçalo do Piauí',
          },
          {
            'id_cidade': 2209856,
            'nomeCidade': 'São João da Canabrava',
          },
          {
            'id_cidade': 2209872,
            'nomeCidade': 'São João da Fronteira',
          },
          {
            'id_cidade': 2209906,
            'nomeCidade': 'São João da Serra',
          },
          {
            'id_cidade': 2209955,
            'nomeCidade': 'São João da Varjota',
          },
          {
            'id_cidade': 2209971,
            'nomeCidade': 'São João do Arraial',
          },
          {
            'id_cidade': 2210003,
            'nomeCidade': 'São João do Piauí',
          },
          {
            'id_cidade': 2210052,
            'nomeCidade': 'São José do Divino',
          },
          {
            'id_cidade': 2210102,
            'nomeCidade': 'São José do Peixe',
          },
          {
            'id_cidade': 2210201,
            'nomeCidade': 'São José do Piauí',
          },
          {
            'id_cidade': 2210300,
            'nomeCidade': 'São Julião',
          },
          {
            'id_cidade': 2210359,
            'nomeCidade': 'São Lourenço do Piauí',
          },
          {
            'id_cidade': 2210375,
            'nomeCidade': 'São Luis do Piauí',
          },
          {
            'id_cidade': 2210383,
            'nomeCidade': 'São Miguel da Baixa Grande',
          },
          {
            'id_cidade': 2210391,
            'nomeCidade': 'São Miguel do Fidalgo',
          },
          {
            'id_cidade': 2210409,
            'nomeCidade': 'São Miguel do Tapuio',
          },
          {
            'id_cidade': 2210508,
            'nomeCidade': 'São Pedro do Piauí',
          },
          {
            'id_cidade': 2210607,
            'nomeCidade': 'São Raimundo Nonato',
          },
          {
            'id_cidade': 2210623,
            'nomeCidade': 'Sebastião Barros',
          },
          {
            'id_cidade': 2210631,
            'nomeCidade': 'Sebastião Leal',
          },
          {
            'id_cidade': 2210656,
            'nomeCidade': 'Sigefredo Pacheco',
          },
          {
            'id_cidade': 2210706,
            'nomeCidade': 'Simões',
          },
          {
            'id_cidade': 2210805,
            'nomeCidade': 'Simplício Mendes',
          },
          {
            'id_cidade': 2210904,
            'nomeCidade': 'Socorro do Piauí',
          },
          {
            'id_cidade': 2210938,
            'nomeCidade': 'Sussuapara',
          },
          {
            'id_cidade': 2210953,
            'nomeCidade': 'Tamboril do Piauí',
          },
          {
            'id_cidade': 2210979,
            'nomeCidade': 'Tanque do Piauí',
          },
          {
            'id_cidade': 2211001,
            'nomeCidade': 'Teresina',
          },
          {
            'id_cidade': 2211100,
            'nomeCidade': 'União',
          },
          {
            'id_cidade': 2211209,
            'nomeCidade': 'Uruçuí',
          },
          {
            'id_cidade': 2211308,
            'nomeCidade': 'Valença do Piauí',
          },
          {
            'id_cidade': 2211357,
            'nomeCidade': 'Várzea Branca',
          },
          {
            'id_cidade': 2211407,
            'nomeCidade': 'Várzea Grande',
          },
          {
            'id_cidade': 2211506,
            'nomeCidade': 'Vera Mendes',
          },
          {
            'id_cidade': 2211605,
            'nomeCidade': 'Vila Nova do Piauí',
          },
          {
            'id_cidade': 2211704,
            'nomeCidade': 'Wall Ferraz',
          },
        ],
      },
      {
        'id': 33,
        'nomeEstado': 'Rio de Janeiro',
        'sigla': 'RJ',
        'cidades': [
          {
            'id_cidade': 3300100,
            'nomeCidade': 'Angra dos Reis',
          },
          {
            'id_cidade': 3300159,
            'nomeCidade': 'Aperibé',
          },
          {
            'id_cidade': 3300209,
            'nomeCidade': 'Araruama',
          },
          {
            'id_cidade': 3300225,
            'nomeCidade': 'Areal',
          },
          {
            'id_cidade': 3300233,
            'nomeCidade': 'Armação dos Búzios',
          },
          {
            'id_cidade': 3300258,
            'nomeCidade': 'Arraial do Cabo',
          },
          {
            'id_cidade': 3300308,
            'nomeCidade': 'Barra do Piraí',
          },
          {
            'id_cidade': 3300407,
            'nomeCidade': 'Barra Mansa',
          },
          {
            'id_cidade': 3300456,
            'nomeCidade': 'Belford Roxo',
          },
          {
            'id_cidade': 3300506,
            'nomeCidade': 'Bom Jardim',
          },
          {
            'id_cidade': 3300605,
            'nomeCidade': 'Bom Jesus do Itabapoana',
          },
          {
            'id_cidade': 3300704,
            'nomeCidade': 'Cabo Frio',
          },
          {
            'id_cidade': 3300803,
            'nomeCidade': 'Cachoeiras de Macacu',
          },
          {
            'id_cidade': 3300902,
            'nomeCidade': 'Cambuci',
          },
          {
            'id_cidade': 3301009,
            'nomeCidade': 'Campos dos Goytacazes',
          },
          {
            'id_cidade': 3301108,
            'nomeCidade': 'Cantagalo',
          },
          {
            'id_cidade': 3300936,
            'nomeCidade': 'Carapebus',
          },
          {
            'id_cidade': 3301157,
            'nomeCidade': 'Cardoso Moreira',
          },
          {
            'id_cidade': 3301207,
            'nomeCidade': 'Carmo',
          },
          {
            'id_cidade': 3301306,
            'nomeCidade': 'Casimiro de Abreu',
          },
          {
            'id_cidade': 3300951,
            'nomeCidade': 'Comendador Levy Gasparian',
          },
          {
            'id_cidade': 3301405,
            'nomeCidade': 'Conceição de Macabu',
          },
          {
            'id_cidade': 3301504,
            'nomeCidade': 'Cordeiro',
          },
          {
            'id_cidade': 3301603,
            'nomeCidade': 'Duas Barras',
          },
          {
            'id_cidade': 3301702,
            'nomeCidade': 'Duque de Caxias',
          },
          {
            'id_cidade': 3301801,
            'nomeCidade': 'Engenheiro Paulo de Frontin',
          },
          {
            'id_cidade': 3301850,
            'nomeCidade': 'Guapimirim',
          },
          {
            'id_cidade': 3301876,
            'nomeCidade': 'Iguaba Grande',
          },
          {
            'id_cidade': 3301900,
            'nomeCidade': 'Itaboraí',
          },
          {
            'id_cidade': 3302007,
            'nomeCidade': 'Itaguaí',
          },
          {
            'id_cidade': 3302056,
            'nomeCidade': 'Italva',
          },
          {
            'id_cidade': 3302106,
            'nomeCidade': 'Itaocara',
          },
          {
            'id_cidade': 3302205,
            'nomeCidade': 'Itaperuna',
          },
          {
            'id_cidade': 3302254,
            'nomeCidade': 'Itatiaia',
          },
          {
            'id_cidade': 3302270,
            'nomeCidade': 'Japeri',
          },
          {
            'id_cidade': 3302304,
            'nomeCidade': 'Laje do Muriaé',
          },
          {
            'id_cidade': 3302403,
            'nomeCidade': 'Macaé',
          },
          {
            'id_cidade': 3302452,
            'nomeCidade': 'Macuco',
          },
          {
            'id_cidade': 3302502,
            'nomeCidade': 'Magé',
          },
          {
            'id_cidade': 3302601,
            'nomeCidade': 'Mangaratiba',
          },
          {
            'id_cidade': 3302700,
            'nomeCidade': 'Maricá',
          },
          {
            'id_cidade': 3302809,
            'nomeCidade': 'Mendes',
          },
          {
            'id_cidade': 3302858,
            'nomeCidade': 'Mesquita',
          },
          {
            'id_cidade': 3302908,
            'nomeCidade': 'Miguel Pereira',
          },
          {
            'id_cidade': 3303005,
            'nomeCidade': 'Miracema',
          },
          {
            'id_cidade': 3303104,
            'nomeCidade': 'Natividade',
          },
          {
            'id_cidade': 3303203,
            'nomeCidade': 'Nilópolis',
          },
          {
            'id_cidade': 3303302,
            'nomeCidade': 'Niterói',
          },
          {
            'id_cidade': 3303401,
            'nomeCidade': 'Nova Friburgo',
          },
          {
            'id_cidade': 3303500,
            'nomeCidade': 'Nova Iguaçu',
          },
          {
            'id_cidade': 3303609,
            'nomeCidade': 'Paracambi',
          },
          {
            'id_cidade': 3303708,
            'nomeCidade': 'Paraíba do Sul',
          },
          {
            'id_cidade': 3303807,
            'nomeCidade': 'Parati',
          },
          {
            'id_cidade': 3303856,
            'nomeCidade': 'Paty do Alferes',
          },
          {
            'id_cidade': 3303906,
            'nomeCidade': 'Petrópolis',
          },
          {
            'id_cidade': 3303955,
            'nomeCidade': 'Pinheiral',
          },
          {
            'id_cidade': 3304003,
            'nomeCidade': 'Piraí',
          },
          {
            'id_cidade': 3304102,
            'nomeCidade': 'Porciúncula',
          },
          {
            'id_cidade': 3304110,
            'nomeCidade': 'Porto Real',
          },
          {
            'id_cidade': 3304128,
            'nomeCidade': 'Quatis',
          },
          {
            'id_cidade': 3304144,
            'nomeCidade': 'Queimados',
          },
          {
            'id_cidade': 3304151,
            'nomeCidade': 'Quissamã',
          },
          {
            'id_cidade': 3304201,
            'nomeCidade': 'Resende',
          },
          {
            'id_cidade': 3304300,
            'nomeCidade': 'Rio Bonito',
          },
          {
            'id_cidade': 3304409,
            'nomeCidade': 'Rio Claro',
          },
          {
            'id_cidade': 3304508,
            'nomeCidade': 'Rio das Flores',
          },
          {
            'id_cidade': 3304524,
            'nomeCidade': 'Rio das Ostras',
          },
          {
            'id_cidade': 3304557,
            'nomeCidade': 'Rio de Janeiro',
          },
          {
            'id_cidade': 3304607,
            'nomeCidade': 'Santa Maria Madalena',
          },
          {
            'id_cidade': 3304706,
            'nomeCidade': 'Santo Antônio de Pádua',
          },
          {
            'id_cidade': 3304805,
            'nomeCidade': 'São Fidélis',
          },
          {
            'id_cidade': 3304755,
            'nomeCidade': 'São Francisco de Itabapoana',
          },
          {
            'id_cidade': 3304904,
            'nomeCidade': 'São Gonçalo',
          },
          {
            'id_cidade': 3305000,
            'nomeCidade': 'São João da Barra',
          },
          {
            'id_cidade': 3305109,
            'nomeCidade': 'São João de Meriti',
          },
          {
            'id_cidade': 3305133,
            'nomeCidade': 'São José de Ubá',
          },
          {
            'id_cidade': 3305158,
            'nomeCidade': 'São José do Vale do Rio Preto',
          },
          {
            'id_cidade': 3305208,
            'nomeCidade': 'São Pedro da Aldeia',
          },
          {
            'id_cidade': 3305307,
            'nomeCidade': 'São Sebastião do Alto',
          },
          {
            'id_cidade': 3305406,
            'nomeCidade': 'Sapucaia',
          },
          {
            'id_cidade': 3305505,
            'nomeCidade': 'Saquarema',
          },
          {
            'id_cidade': 3305554,
            'nomeCidade': 'Seropédica',
          },
          {
            'id_cidade': 3305604,
            'nomeCidade': 'Silva Jardim',
          },
          {
            'id_cidade': 3305703,
            'nomeCidade': 'Sumidouro',
          },
          {
            'id_cidade': 3305752,
            'nomeCidade': 'Tanguá',
          },
          {
            'id_cidade': 3305802,
            'nomeCidade': 'Teresópolis',
          },
          {
            'id_cidade': 3305901,
            'nomeCidade': 'Trajano de Moraes',
          },
          {
            'id_cidade': 3306008,
            'nomeCidade': 'Três Rios',
          },
          {
            'id_cidade': 3306107,
            'nomeCidade': 'Valença',
          },
          {
            'id_cidade': 3306156,
            'nomeCidade': 'Varre-Sai',
          },
          {
            'id_cidade': 3306206,
            'nomeCidade': 'Vassouras',
          },
          {
            'id_cidade': 3306305,
            'nomeCidade': 'Volta Redonda',
          },
        ],
      },
      {
        'id': 24,
        'nomeEstado': 'Rio Grande do Norte',
        'sigla': 'RN',
        'cidades': [
          {
            'id_cidade': 2400109,
            'nomeCidade': 'Acari',
          },
          {
            'id_cidade': 2400208,
            'nomeCidade': 'Açu',
          },
          {
            'id_cidade': 2400307,
            'nomeCidade': 'Afonso Bezerra',
          },
          {
            'id_cidade': 2400406,
            'nomeCidade': 'Água Nova',
          },
          {
            'id_cidade': 2400505,
            'nomeCidade': 'Alexandria',
          },
          {
            'id_cidade': 2400604,
            'nomeCidade': 'Almino Afonso',
          },
          {
            'id_cidade': 2400703,
            'nomeCidade': 'Alto do Rodrigues',
          },
          {
            'id_cidade': 2400802,
            'nomeCidade': 'Angicos',
          },
          {
            'id_cidade': 2400901,
            'nomeCidade': 'Antônio Martins',
          },
          {
            'id_cidade': 2401008,
            'nomeCidade': 'Apodi',
          },
          {
            'id_cidade': 2401107,
            'nomeCidade': 'Areia Branca',
          },
          {
            'id_cidade': 2401206,
            'nomeCidade': 'Arês',
          },
          {
            'id_cidade': 2401305,
            'nomeCidade': 'Augusto Severo',
          },
          {
            'id_cidade': 2401404,
            'nomeCidade': 'Baía Formosa',
          },
          {
            'id_cidade': 2401453,
            'nomeCidade': 'Baraúna',
          },
          {
            'id_cidade': 2401503,
            'nomeCidade': 'Barcelona',
          },
          {
            'id_cidade': 2401602,
            'nomeCidade': 'Bento Fernandes',
          },
          {
            'id_cidade': 2401651,
            'nomeCidade': 'Bodó',
          },
          {
            'id_cidade': 2401701,
            'nomeCidade': 'Bom Jesus',
          },
          {
            'id_cidade': 2401800,
            'nomeCidade': 'Brejinho',
          },
          {
            'id_cidade': 2401859,
            'nomeCidade': 'Caiçara do Norte',
          },
          {
            'id_cidade': 2401909,
            'nomeCidade': 'Caiçara do Rio do Vento',
          },
          {
            'id_cidade': 2402006,
            'nomeCidade': 'Caicó',
          },
          {
            'id_cidade': 2402105,
            'nomeCidade': 'Campo Redondo',
          },
          {
            'id_cidade': 2402204,
            'nomeCidade': 'Canguaretama',
          },
          {
            'id_cidade': 2402303,
            'nomeCidade': 'Caraúbas',
          },
          {
            'id_cidade': 2402402,
            'nomeCidade': 'Carnaúba dos Dantas',
          },
          {
            'id_cidade': 2402501,
            'nomeCidade': 'Carnaubais',
          },
          {
            'id_cidade': 2402600,
            'nomeCidade': 'Ceará-Mirim',
          },
          {
            'id_cidade': 2402709,
            'nomeCidade': 'Cerro Corá',
          },
          {
            'id_cidade': 2402808,
            'nomeCidade': 'Coronel Ezequiel',
          },
          {
            'id_cidade': 2402907,
            'nomeCidade': 'Coronel João Pessoa',
          },
          {
            'id_cidade': 2403004,
            'nomeCidade': 'Cruzeta',
          },
          {
            'id_cidade': 2403103,
            'nomeCidade': 'Currais Novos',
          },
          {
            'id_cidade': 2403202,
            'nomeCidade': 'Doutor Severiano',
          },
          {
            'id_cidade': 2403301,
            'nomeCidade': 'Encanto',
          },
          {
            'id_cidade': 2403400,
            'nomeCidade': 'Equador',
          },
          {
            'id_cidade': 2403509,
            'nomeCidade': 'Espírito Santo',
          },
          {
            'id_cidade': 2403608,
            'nomeCidade': 'Extremoz',
          },
          {
            'id_cidade': 2403707,
            'nomeCidade': 'Felipe Guerra',
          },
          {
            'id_cidade': 2403756,
            'nomeCidade': 'Fernando Pedroza',
          },
          {
            'id_cidade': 2403806,
            'nomeCidade': 'Florânia',
          },
          {
            'id_cidade': 2403905,
            'nomeCidade': 'Francisco Dantas',
          },
          {
            'id_cidade': 2404002,
            'nomeCidade': 'Frutuoso Gomes',
          },
          {
            'id_cidade': 2404101,
            'nomeCidade': 'Galinhos',
          },
          {
            'id_cidade': 2404200,
            'nomeCidade': 'Goianinha',
          },
          {
            'id_cidade': 2404309,
            'nomeCidade': 'Governador Dix-Sept Rosado',
          },
          {
            'id_cidade': 2404408,
            'nomeCidade': 'Grossos',
          },
          {
            'id_cidade': 2404507,
            'nomeCidade': 'Guamaré',
          },
          {
            'id_cidade': 2404606,
            'nomeCidade': 'Ielmo Marinho',
          },
          {
            'id_cidade': 2404705,
            'nomeCidade': 'Ipanguaçu',
          },
          {
            'id_cidade': 2404804,
            'nomeCidade': 'Ipueira',
          },
          {
            'id_cidade': 2404853,
            'nomeCidade': 'Itajá',
          },
          {
            'id_cidade': 2404903,
            'nomeCidade': 'Itaú',
          },
          {
            'id_cidade': 2405009,
            'nomeCidade': 'Jaçanã',
          },
          {
            'id_cidade': 2405108,
            'nomeCidade': 'Jandaíra',
          },
          {
            'id_cidade': 2405207,
            'nomeCidade': 'Janduís',
          },
          {
            'id_cidade': 2405306,
            'nomeCidade': 'Januário Cicco',
          },
          {
            'id_cidade': 2405405,
            'nomeCidade': 'Japi',
          },
          {
            'id_cidade': 2405504,
            'nomeCidade': 'Jardim de Angicos',
          },
          {
            'id_cidade': 2405603,
            'nomeCidade': 'Jardim de Piranhas',
          },
          {
            'id_cidade': 2405702,
            'nomeCidade': 'Jardim do Seridó',
          },
          {
            'id_cidade': 2405801,
            'nomeCidade': 'João Câmara',
          },
          {
            'id_cidade': 2405900,
            'nomeCidade': 'João Dias',
          },
          {
            'id_cidade': 2406007,
            'nomeCidade': 'José da Penha',
          },
          {
            'id_cidade': 2406106,
            'nomeCidade': 'Jucurutu',
          },
          {
            'id_cidade': 2406155,
            'nomeCidade': 'Jundiá',
          },
          {
            'id_cidade': 2406205,
            'nomeCidade': "Lagoa d'Anta",
          },
          {
            'id_cidade': 2406304,
            'nomeCidade': 'Lagoa de Pedras',
          },
          {
            'id_cidade': 2406403,
            'nomeCidade': 'Lagoa de Velhos',
          },
          {
            'id_cidade': 2406502,
            'nomeCidade': 'Lagoa Nova',
          },
          {
            'id_cidade': 2406601,
            'nomeCidade': 'Lagoa Salgada',
          },
          {
            'id_cidade': 2406700,
            'nomeCidade': 'Lajes',
          },
          {
            'id_cidade': 2406809,
            'nomeCidade': 'Lajes Pintadas',
          },
          {
            'id_cidade': 2406908,
            'nomeCidade': 'Lucrécia',
          },
          {
            'id_cidade': 2407005,
            'nomeCidade': 'Luís Gomes',
          },
          {
            'id_cidade': 2407104,
            'nomeCidade': 'Macaíba',
          },
          {
            'id_cidade': 2407203,
            'nomeCidade': 'Macau',
          },
          {
            'id_cidade': 2407252,
            'nomeCidade': 'Major Sales',
          },
          {
            'id_cidade': 2407302,
            'nomeCidade': 'Marcelino Vieira',
          },
          {
            'id_cidade': 2407401,
            'nomeCidade': 'Martins',
          },
          {
            'id_cidade': 2407500,
            'nomeCidade': 'Maxaranguape',
          },
          {
            'id_cidade': 2407609,
            'nomeCidade': 'Messias Targino',
          },
          {
            'id_cidade': 2407708,
            'nomeCidade': 'Montanhas',
          },
          {
            'id_cidade': 2407807,
            'nomeCidade': 'Monte Alegre',
          },
          {
            'id_cidade': 2407906,
            'nomeCidade': 'Monte das Gameleiras',
          },
          {
            'id_cidade': 2408003,
            'nomeCidade': 'Mossoró',
          },
          {
            'id_cidade': 2408102,
            'nomeCidade': 'Natal',
          },
          {
            'id_cidade': 2408201,
            'nomeCidade': 'Nísia Floresta',
          },
          {
            'id_cidade': 2408300,
            'nomeCidade': 'Nova Cruz',
          },
          {
            'id_cidade': 2408409,
            'nomeCidade': "Olho d'Água do Borges",
          },
          {
            'id_cidade': 2408508,
            'nomeCidade': 'Ouro Branco',
          },
          {
            'id_cidade': 2408607,
            'nomeCidade': 'Paraná',
          },
          {
            'id_cidade': 2408706,
            'nomeCidade': 'Paraú',
          },
          {
            'id_cidade': 2408805,
            'nomeCidade': 'Parazinho',
          },
          {
            'id_cidade': 2408904,
            'nomeCidade': 'Parelhas',
          },
          {
            'id_cidade': 2403251,
            'nomeCidade': 'Parnamirim',
          },
          {
            'id_cidade': 2409100,
            'nomeCidade': 'Passa e Fica',
          },
          {
            'id_cidade': 2409209,
            'nomeCidade': 'Passagem',
          },
          {
            'id_cidade': 2409308,
            'nomeCidade': 'Patu',
          },
          {
            'id_cidade': 2409407,
            'nomeCidade': 'Pau dos Ferros',
          },
          {
            'id_cidade': 2409506,
            'nomeCidade': 'Pedra Grande',
          },
          {
            'id_cidade': 2409605,
            'nomeCidade': 'Pedra Preta',
          },
          {
            'id_cidade': 2409704,
            'nomeCidade': 'Pedro Avelino',
          },
          {
            'id_cidade': 2409803,
            'nomeCidade': 'Pedro Velho',
          },
          {
            'id_cidade': 2409902,
            'nomeCidade': 'Pendências',
          },
          {
            'id_cidade': 2410009,
            'nomeCidade': 'Pilões',
          },
          {
            'id_cidade': 2410108,
            'nomeCidade': 'Poço Branco',
          },
          {
            'id_cidade': 2410207,
            'nomeCidade': 'Portalegre',
          },
          {
            'id_cidade': 2410256,
            'nomeCidade': 'Porto do Mangue',
          },
          {
            'id_cidade': 2410405,
            'nomeCidade': 'Pureza',
          },
          {
            'id_cidade': 2410504,
            'nomeCidade': 'Rafael Fernandes',
          },
          {
            'id_cidade': 2410603,
            'nomeCidade': 'Rafael Godeiro',
          },
          {
            'id_cidade': 2410702,
            'nomeCidade': 'Riacho da Cruz',
          },
          {
            'id_cidade': 2410801,
            'nomeCidade': 'Riacho de Santana',
          },
          {
            'id_cidade': 2410900,
            'nomeCidade': 'Riachuelo',
          },
          {
            'id_cidade': 2408953,
            'nomeCidade': 'Rio do Fogo',
          },
          {
            'id_cidade': 2411007,
            'nomeCidade': 'Rodolfo Fernandes',
          },
          {
            'id_cidade': 2411106,
            'nomeCidade': 'Ruy Barbosa',
          },
          {
            'id_cidade': 2411205,
            'nomeCidade': 'Santa Cruz',
          },
          {
            'id_cidade': 2409332,
            'nomeCidade': 'Santa Maria',
          },
          {
            'id_cidade': 2411403,
            'nomeCidade': 'Santana do Matos',
          },
          {
            'id_cidade': 2411429,
            'nomeCidade': 'Santana do Seridó',
          },
          {
            'id_cidade': 2411502,
            'nomeCidade': 'Santo Antônio',
          },
          {
            'id_cidade': 2411601,
            'nomeCidade': 'São Bento do Norte',
          },
          {
            'id_cidade': 2411700,
            'nomeCidade': 'São Bento do Trairí',
          },
          {
            'id_cidade': 2411809,
            'nomeCidade': 'São Fernando',
          },
          {
            'id_cidade': 2411908,
            'nomeCidade': 'São Francisco do Oeste',
          },
          {
            'id_cidade': 2412005,
            'nomeCidade': 'São Gonçalo do Amarante',
          },
          {
            'id_cidade': 2412104,
            'nomeCidade': 'São João do Sabugi',
          },
          {
            'id_cidade': 2412203,
            'nomeCidade': 'São José de Mipibu',
          },
          {
            'id_cidade': 2412302,
            'nomeCidade': 'São José do Campestre',
          },
          {
            'id_cidade': 2412401,
            'nomeCidade': 'São José do Seridó',
          },
          {
            'id_cidade': 2412500,
            'nomeCidade': 'São Miguel',
          },
          {
            'id_cidade': 2412559,
            'nomeCidade': 'São Miguel do Gostoso',
          },
          {
            'id_cidade': 2412609,
            'nomeCidade': 'São Paulo do Potengi',
          },
          {
            'id_cidade': 2412708,
            'nomeCidade': 'São Pedro',
          },
          {
            'id_cidade': 2412807,
            'nomeCidade': 'São Rafael',
          },
          {
            'id_cidade': 2412906,
            'nomeCidade': 'São Tomé',
          },
          {
            'id_cidade': 2413003,
            'nomeCidade': 'São Vicente',
          },
          {
            'id_cidade': 2413102,
            'nomeCidade': 'Senador Elói de Souza',
          },
          {
            'id_cidade': 2413201,
            'nomeCidade': 'Senador Georgino Avelino',
          },
          {
            'id_cidade': 2410306,
            'nomeCidade': 'Serra Caiada',
          },
          {
            'id_cidade': 2413300,
            'nomeCidade': 'Serra de São Bento',
          },
          {
            'id_cidade': 2413359,
            'nomeCidade': 'Serra do Mel',
          },
          {
            'id_cidade': 2413409,
            'nomeCidade': 'Serra Negra do Norte',
          },
          {
            'id_cidade': 2413508,
            'nomeCidade': 'Serrinha',
          },
          {
            'id_cidade': 2413557,
            'nomeCidade': 'Serrinha dos Pintos',
          },
          {
            'id_cidade': 2413607,
            'nomeCidade': 'Severiano Melo',
          },
          {
            'id_cidade': 2413706,
            'nomeCidade': 'Sítio Novo',
          },
          {
            'id_cidade': 2413805,
            'nomeCidade': 'Taboleiro Grande',
          },
          {
            'id_cidade': 2413904,
            'nomeCidade': 'Taipu',
          },
          {
            'id_cidade': 2414001,
            'nomeCidade': 'Tangará',
          },
          {
            'id_cidade': 2414100,
            'nomeCidade': 'Tenente Ananias',
          },
          {
            'id_cidade': 2414159,
            'nomeCidade': 'Tenente Laurentino Cruz',
          },
          {
            'id_cidade': 2411056,
            'nomeCidade': 'Tibau',
          },
          {
            'id_cidade': 2414209,
            'nomeCidade': 'Tibau do Sul',
          },
          {
            'id_cidade': 2414308,
            'nomeCidade': 'Timbaúba dos Batistas',
          },
          {
            'id_cidade': 2414407,
            'nomeCidade': 'Touros',
          },
          {
            'id_cidade': 2414456,
            'nomeCidade': 'Triunfo Potiguar',
          },
          {
            'id_cidade': 2414506,
            'nomeCidade': 'Umarizal',
          },
          {
            'id_cidade': 2414605,
            'nomeCidade': 'Upanema',
          },
          {
            'id_cidade': 2414704,
            'nomeCidade': 'Várzea',
          },
          {
            'id_cidade': 2414753,
            'nomeCidade': 'Venha-Ver',
          },
          {
            'id_cidade': 2414803,
            'nomeCidade': 'Vera Cruz',
          },
          {
            'id_cidade': 2414902,
            'nomeCidade': 'Viçosa',
          },
          {
            'id_cidade': 2415008,
            'nomeCidade': 'Vila Flor',
          },
        ],
      },
      {
        'id': 43,
        'nomeEstado': 'Rio Grande do Sul',
        'sigla': 'RS',
        'cidades': [
          {
            'id_cidade': 4300034,
            'nomeCidade': 'Aceguá',
          },
          {
            'id_cidade': 4300059,
            'nomeCidade': 'Água Santa',
          },
          {
            'id_cidade': 4300109,
            'nomeCidade': 'Agudo',
          },
          {
            'id_cidade': 4300208,
            'nomeCidade': 'Ajuricaba',
          },
          {
            'id_cidade': 4300307,
            'nomeCidade': 'Alecrim',
          },
          {
            'id_cidade': 4300406,
            'nomeCidade': 'Alegrete',
          },
          {
            'id_cidade': 4300455,
            'nomeCidade': 'Alegria',
          },
          {
            'id_cidade': 4300471,
            'nomeCidade': 'Almirante Tamandaré do Sul',
          },
          {
            'id_cidade': 4300505,
            'nomeCidade': 'Alpestre',
          },
          {
            'id_cidade': 4300554,
            'nomeCidade': 'Alto Alegre',
          },
          {
            'id_cidade': 4300570,
            'nomeCidade': 'Alto Feliz',
          },
          {
            'id_cidade': 4300604,
            'nomeCidade': 'Alvorada',
          },
          {
            'id_cidade': 4300638,
            'nomeCidade': 'Amaral Ferrador',
          },
          {
            'id_cidade': 4300646,
            'nomeCidade': 'Ametista do Sul',
          },
          {
            'id_cidade': 4300661,
            'nomeCidade': 'André da Rocha',
          },
          {
            'id_cidade': 4300703,
            'nomeCidade': 'Anta Gorda',
          },
          {
            'id_cidade': 4300802,
            'nomeCidade': 'Antônio Prado',
          },
          {
            'id_cidade': 4300851,
            'nomeCidade': 'Arambaré',
          },
          {
            'id_cidade': 4300877,
            'nomeCidade': 'Araricá',
          },
          {
            'id_cidade': 4300901,
            'nomeCidade': 'Aratiba',
          },
          {
            'id_cidade': 4301008,
            'nomeCidade': 'Arroio do Meio',
          },
          {
            'id_cidade': 4301073,
            'nomeCidade': 'Arroio do Padre',
          },
          {
            'id_cidade': 4301057,
            'nomeCidade': 'Arroio do Sal',
          },
          {
            'id_cidade': 4301206,
            'nomeCidade': 'Arroio do Tigre',
          },
          {
            'id_cidade': 4301107,
            'nomeCidade': 'Arroio dos Ratos',
          },
          {
            'id_cidade': 4301305,
            'nomeCidade': 'Arroio Grande',
          },
          {
            'id_cidade': 4301404,
            'nomeCidade': 'Arvorezinha',
          },
          {
            'id_cidade': 4301503,
            'nomeCidade': 'Augusto Pestana',
          },
          {
            'id_cidade': 4301552,
            'nomeCidade': 'Áurea',
          },
          {
            'id_cidade': 4301602,
            'nomeCidade': 'Bagé',
          },
          {
            'id_cidade': 4301636,
            'nomeCidade': 'Balneário Pinhal',
          },
          {
            'id_cidade': 4301651,
            'nomeCidade': 'Barão',
          },
          {
            'id_cidade': 4301701,
            'nomeCidade': 'Barão de Cotegipe',
          },
          {
            'id_cidade': 4301750,
            'nomeCidade': 'Barão do Triunfo',
          },
          {
            'id_cidade': 4301859,
            'nomeCidade': 'Barra do Guarita',
          },
          {
            'id_cidade': 4301875,
            'nomeCidade': 'Barra do Quaraí',
          },
          {
            'id_cidade': 4301909,
            'nomeCidade': 'Barra do Ribeiro',
          },
          {
            'id_cidade': 4301925,
            'nomeCidade': 'Barra do Rio Azul',
          },
          {
            'id_cidade': 4301958,
            'nomeCidade': 'Barra Funda',
          },
          {
            'id_cidade': 4301800,
            'nomeCidade': 'Barracão',
          },
          {
            'id_cidade': 4302006,
            'nomeCidade': 'Barros Cassal',
          },
          {
            'id_cidade': 4302055,
            'nomeCidade': 'Benjamin Constant do Sul',
          },
          {
            'id_cidade': 4302105,
            'nomeCidade': 'Bento Gonçalves',
          },
          {
            'id_cidade': 4302154,
            'nomeCidade': 'Boa Vista das Missões',
          },
          {
            'id_cidade': 4302204,
            'nomeCidade': 'Boa Vista do Buricá',
          },
          {
            'id_cidade': 4302220,
            'nomeCidade': 'Boa Vista do Cadeado',
          },
          {
            'id_cidade': 4302238,
            'nomeCidade': 'Boa Vista do Incra',
          },
          {
            'id_cidade': 4302253,
            'nomeCidade': 'Boa Vista do Sul',
          },
          {
            'id_cidade': 4302303,
            'nomeCidade': 'Bom Jesus',
          },
          {
            'id_cidade': 4302352,
            'nomeCidade': 'Bom Princípio',
          },
          {
            'id_cidade': 4302378,
            'nomeCidade': 'Bom Progresso',
          },
          {
            'id_cidade': 4302402,
            'nomeCidade': 'Bom Retiro do Sul',
          },
          {
            'id_cidade': 4302451,
            'nomeCidade': 'Boqueirão do Leão',
          },
          {
            'id_cidade': 4302501,
            'nomeCidade': 'Bossoroca',
          },
          {
            'id_cidade': 4302584,
            'nomeCidade': 'Bozano',
          },
          {
            'id_cidade': 4302600,
            'nomeCidade': 'Braga',
          },
          {
            'id_cidade': 4302659,
            'nomeCidade': 'Brochier',
          },
          {
            'id_cidade': 4302709,
            'nomeCidade': 'Butiá',
          },
          {
            'id_cidade': 4302808,
            'nomeCidade': 'Caçapava do Sul',
          },
          {
            'id_cidade': 4302907,
            'nomeCidade': 'Cacequi',
          },
          {
            'id_cidade': 4303004,
            'nomeCidade': 'Cachoeira do Sul',
          },
          {
            'id_cidade': 4303103,
            'nomeCidade': 'Cachoeirinha',
          },
          {
            'id_cidade': 4303202,
            'nomeCidade': 'Cacique Doble',
          },
          {
            'id_cidade': 4303301,
            'nomeCidade': 'Caibaté',
          },
          {
            'id_cidade': 4303400,
            'nomeCidade': 'Caiçara',
          },
          {
            'id_cidade': 4303509,
            'nomeCidade': 'Camaquã',
          },
          {
            'id_cidade': 4303558,
            'nomeCidade': 'Camargo',
          },
          {
            'id_cidade': 4303608,
            'nomeCidade': 'Cambará do Sul',
          },
          {
            'id_cidade': 4303673,
            'nomeCidade': 'Campestre da Serra',
          },
          {
            'id_cidade': 4303707,
            'nomeCidade': 'Campina das Missões',
          },
          {
            'id_cidade': 4303806,
            'nomeCidade': 'Campinas do Sul',
          },
          {
            'id_cidade': 4303905,
            'nomeCidade': 'Campo Bom',
          },
          {
            'id_cidade': 4304002,
            'nomeCidade': 'Campo Novo',
          },
          {
            'id_cidade': 4304101,
            'nomeCidade': 'Campos Borges',
          },
          {
            'id_cidade': 4304200,
            'nomeCidade': 'Candelária',
          },
          {
            'id_cidade': 4304309,
            'nomeCidade': 'Cândido Godói',
          },
          {
            'id_cidade': 4304358,
            'nomeCidade': 'Candiota',
          },
          {
            'id_cidade': 4304408,
            'nomeCidade': 'Canela',
          },
          {
            'id_cidade': 4304507,
            'nomeCidade': 'Canguçu',
          },
          {
            'id_cidade': 4304606,
            'nomeCidade': 'Canoas',
          },
          {
            'id_cidade': 4304614,
            'nomeCidade': 'Canudos do Vale',
          },
          {
            'id_cidade': 4304622,
            'nomeCidade': 'Capão Bonito do Sul',
          },
          {
            'id_cidade': 4304630,
            'nomeCidade': 'Capão da Canoa',
          },
          {
            'id_cidade': 4304655,
            'nomeCidade': 'Capão do Cipó',
          },
          {
            'id_cidade': 4304663,
            'nomeCidade': 'Capão do Leão',
          },
          {
            'id_cidade': 4304689,
            'nomeCidade': 'Capela de Santana',
          },
          {
            'id_cidade': 4304697,
            'nomeCidade': 'Capitão',
          },
          {
            'id_cidade': 4304671,
            'nomeCidade': 'Capivari do Sul',
          },
          {
            'id_cidade': 4304713,
            'nomeCidade': 'Caraá',
          },
          {
            'id_cidade': 4304705,
            'nomeCidade': 'Carazinho',
          },
          {
            'id_cidade': 4304804,
            'nomeCidade': 'Carlos Barbosa',
          },
          {
            'id_cidade': 4304853,
            'nomeCidade': 'Carlos Gomes',
          },
          {
            'id_cidade': 4304903,
            'nomeCidade': 'Casca',
          },
          {
            'id_cidade': 4304952,
            'nomeCidade': 'Caseiros',
          },
          {
            'id_cidade': 4305009,
            'nomeCidade': 'Catuípe',
          },
          {
            'id_cidade': 4305108,
            'nomeCidade': 'Caxias do Sul',
          },
          {
            'id_cidade': 4305116,
            'nomeCidade': 'Centenário',
          },
          {
            'id_cidade': 4305124,
            'nomeCidade': 'Cerrito',
          },
          {
            'id_cidade': 4305132,
            'nomeCidade': 'Cerro Branco',
          },
          {
            'id_cidade': 4305157,
            'nomeCidade': 'Cerro Grande',
          },
          {
            'id_cidade': 4305173,
            'nomeCidade': 'Cerro Grande do Sul',
          },
          {
            'id_cidade': 4305207,
            'nomeCidade': 'Cerro Largo',
          },
          {
            'id_cidade': 4305306,
            'nomeCidade': 'Chapada',
          },
          {
            'id_cidade': 4305355,
            'nomeCidade': 'Charqueadas',
          },
          {
            'id_cidade': 4305371,
            'nomeCidade': 'Charrua',
          },
          {
            'id_cidade': 4305405,
            'nomeCidade': 'Chiapetta',
          },
          {
            'id_cidade': 4305439,
            'nomeCidade': 'Chuí',
          },
          {
            'id_cidade': 4305447,
            'nomeCidade': 'Chuvisca',
          },
          {
            'id_cidade': 4305454,
            'nomeCidade': 'Cidreira',
          },
          {
            'id_cidade': 4305504,
            'nomeCidade': 'Ciríaco',
          },
          {
            'id_cidade': 4305587,
            'nomeCidade': 'Colinas',
          },
          {
            'id_cidade': 4305603,
            'nomeCidade': 'Colorado',
          },
          {
            'id_cidade': 4305702,
            'nomeCidade': 'Condor',
          },
          {
            'id_cidade': 4305801,
            'nomeCidade': 'Constantina',
          },
          {
            'id_cidade': 4305835,
            'nomeCidade': 'Coqueiro Baixo',
          },
          {
            'id_cidade': 4305850,
            'nomeCidade': 'Coqueiros do Sul',
          },
          {
            'id_cidade': 4305871,
            'nomeCidade': 'Coronel Barros',
          },
          {
            'id_cidade': 4305900,
            'nomeCidade': 'Coronel Bicaco',
          },
          {
            'id_cidade': 4305934,
            'nomeCidade': 'Coronel Pilar',
          },
          {
            'id_cidade': 4305959,
            'nomeCidade': 'Cotiporã',
          },
          {
            'id_cidade': 4305975,
            'nomeCidade': 'Coxilha',
          },
          {
            'id_cidade': 4306007,
            'nomeCidade': 'Crissiumal',
          },
          {
            'id_cidade': 4306056,
            'nomeCidade': 'Cristal',
          },
          {
            'id_cidade': 4306072,
            'nomeCidade': 'Cristal do Sul',
          },
          {
            'id_cidade': 4306106,
            'nomeCidade': 'Cruz Alta',
          },
          {
            'id_cidade': 4306130,
            'nomeCidade': 'Cruzaltense',
          },
          {
            'id_cidade': 4306205,
            'nomeCidade': 'Cruzeiro do Sul',
          },
          {
            'id_cidade': 4306304,
            'nomeCidade': 'David Canabarro',
          },
          {
            'id_cidade': 4306320,
            'nomeCidade': 'Derrubadas',
          },
          {
            'id_cidade': 4306353,
            'nomeCidade': 'Dezesseis de Novembro',
          },
          {
            'id_cidade': 4306379,
            'nomeCidade': 'Dilermando de Aguiar',
          },
          {
            'id_cidade': 4306403,
            'nomeCidade': 'Dois Irmãos',
          },
          {
            'id_cidade': 4306429,
            'nomeCidade': 'Dois Irmãos das Missões',
          },
          {
            'id_cidade': 4306452,
            'nomeCidade': 'Dois Lajeados',
          },
          {
            'id_cidade': 4306502,
            'nomeCidade': 'Dom Feliciano',
          },
          {
            'id_cidade': 4306601,
            'nomeCidade': 'Dom Pedrito',
          },
          {
            'id_cidade': 4306551,
            'nomeCidade': 'Dom Pedro de Alcântara',
          },
          {
            'id_cidade': 4306700,
            'nomeCidade': 'Dona Francisca',
          },
          {
            'id_cidade': 4306734,
            'nomeCidade': 'Doutor Maurício Cardoso',
          },
          {
            'id_cidade': 4306759,
            'nomeCidade': 'Doutor Ricardo',
          },
          {
            'id_cidade': 4306767,
            'nomeCidade': 'Eldorado do Sul',
          },
          {
            'id_cidade': 4306809,
            'nomeCidade': 'Encantado',
          },
          {
            'id_cidade': 4306908,
            'nomeCidade': 'Encruzilhada do Sul',
          },
          {
            'id_cidade': 4306924,
            'nomeCidade': 'Engenho Velho',
          },
          {
            'id_cidade': 4306957,
            'nomeCidade': 'Entre Rios do Sul',
          },
          {
            'id_cidade': 4306932,
            'nomeCidade': 'Entre-Ijuís',
          },
          {
            'id_cidade': 4306973,
            'nomeCidade': 'Erebango',
          },
          {
            'id_cidade': 4307005,
            'nomeCidade': 'Erechim',
          },
          {
            'id_cidade': 4307054,
            'nomeCidade': 'Ernestina',
          },
          {
            'id_cidade': 4307203,
            'nomeCidade': 'Erval Grande',
          },
          {
            'id_cidade': 4307302,
            'nomeCidade': 'Erval Seco',
          },
          {
            'id_cidade': 4307401,
            'nomeCidade': 'Esmeralda',
          },
          {
            'id_cidade': 4307450,
            'nomeCidade': 'Esperança do Sul',
          },
          {
            'id_cidade': 4307500,
            'nomeCidade': 'Espumoso',
          },
          {
            'id_cidade': 4307559,
            'nomeCidade': 'Estação',
          },
          {
            'id_cidade': 4307609,
            'nomeCidade': 'Estância Velha',
          },
          {
            'id_cidade': 4307708,
            'nomeCidade': 'Esteio',
          },
          {
            'id_cidade': 4307807,
            'nomeCidade': 'Estrela',
          },
          {
            'id_cidade': 4307815,
            'nomeCidade': 'Estrela Velha',
          },
          {
            'id_cidade': 4307831,
            'nomeCidade': 'Eugênio de Castro',
          },
          {
            'id_cidade': 4307864,
            'nomeCidade': 'Fagundes Varela',
          },
          {
            'id_cidade': 4307906,
            'nomeCidade': 'Farroupilha',
          },
          {
            'id_cidade': 4308003,
            'nomeCidade': 'Faxinal do Soturno',
          },
          {
            'id_cidade': 4308052,
            'nomeCidade': 'Faxinalzinho',
          },
          {
            'id_cidade': 4308078,
            'nomeCidade': 'Fazenda Vilanova',
          },
          {
            'id_cidade': 4308102,
            'nomeCidade': 'Feliz',
          },
          {
            'id_cidade': 4308201,
            'nomeCidade': 'Flores da Cunha',
          },
          {
            'id_cidade': 4308250,
            'nomeCidade': 'Floriano Peixoto',
          },
          {
            'id_cidade': 4308300,
            'nomeCidade': 'Fontoura Xavier',
          },
          {
            'id_cidade': 4308409,
            'nomeCidade': 'Formigueiro',
          },
          {
            'id_cidade': 4308433,
            'nomeCidade': 'Forquetinha',
          },
          {
            'id_cidade': 4308458,
            'nomeCidade': 'Fortaleza dos Valos',
          },
          {
            'id_cidade': 4308508,
            'nomeCidade': 'Frederico Westphalen',
          },
          {
            'id_cidade': 4308607,
            'nomeCidade': 'Garibaldi',
          },
          {
            'id_cidade': 4308656,
            'nomeCidade': 'Garruchos',
          },
          {
            'id_cidade': 4308706,
            'nomeCidade': 'Gaurama',
          },
          {
            'id_cidade': 4308805,
            'nomeCidade': 'General Câmara',
          },
          {
            'id_cidade': 4308854,
            'nomeCidade': 'Gentil',
          },
          {
            'id_cidade': 4308904,
            'nomeCidade': 'Getúlio Vargas',
          },
          {
            'id_cidade': 4309001,
            'nomeCidade': 'Giruá',
          },
          {
            'id_cidade': 4309050,
            'nomeCidade': 'Glorinha',
          },
          {
            'id_cidade': 4309100,
            'nomeCidade': 'Gramado',
          },
          {
            'id_cidade': 4309126,
            'nomeCidade': 'Gramado dos Loureiros',
          },
          {
            'id_cidade': 4309159,
            'nomeCidade': 'Gramado Xavier',
          },
          {
            'id_cidade': 4309209,
            'nomeCidade': 'Gravataí',
          },
          {
            'id_cidade': 4309258,
            'nomeCidade': 'Guabiju',
          },
          {
            'id_cidade': 4309308,
            'nomeCidade': 'Guaíba',
          },
          {
            'id_cidade': 4309407,
            'nomeCidade': 'Guaporé',
          },
          {
            'id_cidade': 4309506,
            'nomeCidade': 'Guarani das Missões',
          },
          {
            'id_cidade': 4309555,
            'nomeCidade': 'Harmonia',
          },
          {
            'id_cidade': 4307104,
            'nomeCidade': 'Herval',
          },
          {
            'id_cidade': 4309571,
            'nomeCidade': 'Herveiras',
          },
          {
            'id_cidade': 4309605,
            'nomeCidade': 'Horizontina',
          },
          {
            'id_cidade': 4309654,
            'nomeCidade': 'Hulha Negra',
          },
          {
            'id_cidade': 4309704,
            'nomeCidade': 'Humaitá',
          },
          {
            'id_cidade': 4309753,
            'nomeCidade': 'Ibarama',
          },
          {
            'id_cidade': 4309803,
            'nomeCidade': 'Ibiaçá',
          },
          {
            'id_cidade': 4309902,
            'nomeCidade': 'Ibiraiaras',
          },
          {
            'id_cidade': 4309951,
            'nomeCidade': 'Ibirapuitã',
          },
          {
            'id_cidade': 4310009,
            'nomeCidade': 'Ibirubá',
          },
          {
            'id_cidade': 4310108,
            'nomeCidade': 'Igrejinha',
          },
          {
            'id_cidade': 4310207,
            'nomeCidade': 'Ijuí',
          },
          {
            'id_cidade': 4310306,
            'nomeCidade': 'Ilópolis',
          },
          {
            'id_cidade': 4310330,
            'nomeCidade': 'Imbé',
          },
          {
            'id_cidade': 4310363,
            'nomeCidade': 'Imigrante',
          },
          {
            'id_cidade': 4310405,
            'nomeCidade': 'Independência',
          },
          {
            'id_cidade': 4310413,
            'nomeCidade': 'Inhacorá',
          },
          {
            'id_cidade': 4310439,
            'nomeCidade': 'Ipê',
          },
          {
            'id_cidade': 4310462,
            'nomeCidade': 'Ipiranga do Sul',
          },
          {
            'id_cidade': 4310504,
            'nomeCidade': 'Iraí',
          },
          {
            'id_cidade': 4310538,
            'nomeCidade': 'Itaara',
          },
          {
            'id_cidade': 4310553,
            'nomeCidade': 'Itacurubi',
          },
          {
            'id_cidade': 4310579,
            'nomeCidade': 'Itapuca',
          },
          {
            'id_cidade': 4310603,
            'nomeCidade': 'Itaqui',
          },
          {
            'id_cidade': 4310652,
            'nomeCidade': 'Itati',
          },
          {
            'id_cidade': 4310702,
            'nomeCidade': 'Itatiba do Sul',
          },
          {
            'id_cidade': 4310751,
            'nomeCidade': 'Ivorá',
          },
          {
            'id_cidade': 4310801,
            'nomeCidade': 'Ivoti',
          },
          {
            'id_cidade': 4310850,
            'nomeCidade': 'Jaboticaba',
          },
          {
            'id_cidade': 4310876,
            'nomeCidade': 'Jacuizinho',
          },
          {
            'id_cidade': 4310900,
            'nomeCidade': 'Jacutinga',
          },
          {
            'id_cidade': 4311007,
            'nomeCidade': 'Jaguarão',
          },
          {
            'id_cidade': 4311106,
            'nomeCidade': 'Jaguari',
          },
          {
            'id_cidade': 4311122,
            'nomeCidade': 'Jaquirana',
          },
          {
            'id_cidade': 4311130,
            'nomeCidade': 'Jari',
          },
          {
            'id_cidade': 4311155,
            'nomeCidade': 'Jóia',
          },
          {
            'id_cidade': 4311205,
            'nomeCidade': 'Júlio de Castilhos',
          },
          {
            'id_cidade': 4311239,
            'nomeCidade': 'Lagoa Bonita do Sul',
          },
          {
            'id_cidade': 4311270,
            'nomeCidade': 'Lagoa dos Três Cantos',
          },
          {
            'id_cidade': 4311304,
            'nomeCidade': 'Lagoa Vermelha',
          },
          {
            'id_cidade': 4311254,
            'nomeCidade': 'Lagoão',
          },
          {
            'id_cidade': 4311403,
            'nomeCidade': 'Lajeado',
          },
          {
            'id_cidade': 4311429,
            'nomeCidade': 'Lajeado do Bugre',
          },
          {
            'id_cidade': 4311502,
            'nomeCidade': 'Lavras do Sul',
          },
          {
            'id_cidade': 4311601,
            'nomeCidade': 'Liberato Salzano',
          },
          {
            'id_cidade': 4311627,
            'nomeCidade': 'Lindolfo Collor',
          },
          {
            'id_cidade': 4311643,
            'nomeCidade': 'Linha Nova',
          },
          {
            'id_cidade': 4311718,
            'nomeCidade': 'Maçambará',
          },
          {
            'id_cidade': 4311700,
            'nomeCidade': 'Machadinho',
          },
          {
            'id_cidade': 4311734,
            'nomeCidade': 'Mampituba',
          },
          {
            'id_cidade': 4311759,
            'nomeCidade': 'Manoel Viana',
          },
          {
            'id_cidade': 4311775,
            'nomeCidade': 'Maquiné',
          },
          {
            'id_cidade': 4311791,
            'nomeCidade': 'Maratá',
          },
          {
            'id_cidade': 4311809,
            'nomeCidade': 'Marau',
          },
          {
            'id_cidade': 4311908,
            'nomeCidade': 'Marcelino Ramos',
          },
          {
            'id_cidade': 4311981,
            'nomeCidade': 'Mariana Pimentel',
          },
          {
            'id_cidade': 4312005,
            'nomeCidade': 'Mariano Moro',
          },
          {
            'id_cidade': 4312054,
            'nomeCidade': 'Marques de Souza',
          },
          {
            'id_cidade': 4312104,
            'nomeCidade': 'Mata',
          },
          {
            'id_cidade': 4312138,
            'nomeCidade': 'Mato Castelhano',
          },
          {
            'id_cidade': 4312153,
            'nomeCidade': 'Mato Leitão',
          },
          {
            'id_cidade': 4312179,
            'nomeCidade': 'Mato Queimado',
          },
          {
            'id_cidade': 4312203,
            'nomeCidade': 'Maximiliano de Almeida',
          },
          {
            'id_cidade': 4312252,
            'nomeCidade': 'Minas do Leão',
          },
          {
            'id_cidade': 4312302,
            'nomeCidade': 'Miraguaí',
          },
          {
            'id_cidade': 4312351,
            'nomeCidade': 'Montauri',
          },
          {
            'id_cidade': 4312377,
            'nomeCidade': 'Monte Alegre dos Campos',
          },
          {
            'id_cidade': 4312385,
            'nomeCidade': 'Monte Belo do Sul',
          },
          {
            'id_cidade': 4312401,
            'nomeCidade': 'Montenegro',
          },
          {
            'id_cidade': 4312427,
            'nomeCidade': 'Mormaço',
          },
          {
            'id_cidade': 4312443,
            'nomeCidade': 'Morrinhos do Sul',
          },
          {
            'id_cidade': 4312450,
            'nomeCidade': 'Morro Redondo',
          },
          {
            'id_cidade': 4312476,
            'nomeCidade': 'Morro Reuter',
          },
          {
            'id_cidade': 4312500,
            'nomeCidade': 'Mostardas',
          },
          {
            'id_cidade': 4312609,
            'nomeCidade': 'Muçum',
          },
          {
            'id_cidade': 4312617,
            'nomeCidade': 'Muitos Capões',
          },
          {
            'id_cidade': 4312625,
            'nomeCidade': 'Muliterno',
          },
          {
            'id_cidade': 4312658,
            'nomeCidade': 'Não-Me-Toque',
          },
          {
            'id_cidade': 4312674,
            'nomeCidade': 'Nicolau Vergueiro',
          },
          {
            'id_cidade': 4312708,
            'nomeCidade': 'Nonoai',
          },
          {
            'id_cidade': 4312757,
            'nomeCidade': 'Nova Alvorada',
          },
          {
            'id_cidade': 4312807,
            'nomeCidade': 'Nova Araçá',
          },
          {
            'id_cidade': 4312906,
            'nomeCidade': 'Nova Bassano',
          },
          {
            'id_cidade': 4312955,
            'nomeCidade': 'Nova Boa Vista',
          },
          {
            'id_cidade': 4313003,
            'nomeCidade': 'Nova Bréscia',
          },
          {
            'id_cidade': 4313011,
            'nomeCidade': 'Nova Candelária',
          },
          {
            'id_cidade': 4313037,
            'nomeCidade': 'Nova Esperança do Sul',
          },
          {
            'id_cidade': 4313060,
            'nomeCidade': 'Nova Hartz',
          },
          {
            'id_cidade': 4313086,
            'nomeCidade': 'Nova Pádua',
          },
          {
            'id_cidade': 4313102,
            'nomeCidade': 'Nova Palma',
          },
          {
            'id_cidade': 4313201,
            'nomeCidade': 'Nova Petrópolis',
          },
          {
            'id_cidade': 4313300,
            'nomeCidade': 'Nova Prata',
          },
          {
            'id_cidade': 4313334,
            'nomeCidade': 'Nova Ramada',
          },
          {
            'id_cidade': 4313359,
            'nomeCidade': 'Nova Roma do Sul',
          },
          {
            'id_cidade': 4313375,
            'nomeCidade': 'Nova Santa Rita',
          },
          {
            'id_cidade': 4313490,
            'nomeCidade': 'Novo Barreiro',
          },
          {
            'id_cidade': 4313391,
            'nomeCidade': 'Novo Cabrais',
          },
          {
            'id_cidade': 4313409,
            'nomeCidade': 'Novo Hamburgo',
          },
          {
            'id_cidade': 4313425,
            'nomeCidade': 'Novo Machado',
          },
          {
            'id_cidade': 4313441,
            'nomeCidade': 'Novo Tiradentes',
          },
          {
            'id_cidade': 4313466,
            'nomeCidade': 'Novo Xingu',
          },
          {
            'id_cidade': 4313508,
            'nomeCidade': 'Osório',
          },
          {
            'id_cidade': 4313607,
            'nomeCidade': 'Paim Filho',
          },
          {
            'id_cidade': 4313656,
            'nomeCidade': 'Palmares do Sul',
          },
          {
            'id_cidade': 4313706,
            'nomeCidade': 'Palmeira das Missões',
          },
          {
            'id_cidade': 4313805,
            'nomeCidade': 'Palmitinho',
          },
          {
            'id_cidade': 4313904,
            'nomeCidade': 'Panambi',
          },
          {
            'id_cidade': 4313953,
            'nomeCidade': 'Pantano Grande',
          },
          {
            'id_cidade': 4314001,
            'nomeCidade': 'Paraí',
          },
          {
            'id_cidade': 4314027,
            'nomeCidade': 'Paraíso do Sul',
          },
          {
            'id_cidade': 4314035,
            'nomeCidade': 'Pareci Novo',
          },
          {
            'id_cidade': 4314050,
            'nomeCidade': 'Parobé',
          },
          {
            'id_cidade': 4314068,
            'nomeCidade': 'Passa Sete',
          },
          {
            'id_cidade': 4314076,
            'nomeCidade': 'Passo do Sobrado',
          },
          {
            'id_cidade': 4314100,
            'nomeCidade': 'Passo Fundo',
          },
          {
            'id_cidade': 4314134,
            'nomeCidade': 'Paulo Bento',
          },
          {
            'id_cidade': 4314159,
            'nomeCidade': 'Paverama',
          },
          {
            'id_cidade': 4314175,
            'nomeCidade': 'Pedras Altas',
          },
          {
            'id_cidade': 4314209,
            'nomeCidade': 'Pedro Osório',
          },
          {
            'id_cidade': 4314308,
            'nomeCidade': 'Pejuçara',
          },
          {
            'id_cidade': 4314407,
            'nomeCidade': 'Pelotas',
          },
          {
            'id_cidade': 4314423,
            'nomeCidade': 'Picada Café',
          },
          {
            'id_cidade': 4314456,
            'nomeCidade': 'Pinhal',
          },
          {
            'id_cidade': 4314464,
            'nomeCidade': 'Pinhal da Serra',
          },
          {
            'id_cidade': 4314472,
            'nomeCidade': 'Pinhal Grande',
          },
          {
            'id_cidade': 4314498,
            'nomeCidade': 'Pinheirinho do Vale',
          },
          {
            'id_cidade': 4314506,
            'nomeCidade': 'Pinheiro Machado',
          },
          {
            'id_cidade': 4314548,
            'nomeCidade': 'Pinto Bandeira',
          },
          {
            'id_cidade': 4314555,
            'nomeCidade': 'Pirapó',
          },
          {
            'id_cidade': 4314605,
            'nomeCidade': 'Piratini',
          },
          {
            'id_cidade': 4314704,
            'nomeCidade': 'Planalto',
          },
          {
            'id_cidade': 4314753,
            'nomeCidade': 'Poço das Antas',
          },
          {
            'id_cidade': 4314779,
            'nomeCidade': 'Pontão',
          },
          {
            'id_cidade': 4314787,
            'nomeCidade': 'Ponte Preta',
          },
          {
            'id_cidade': 4314803,
            'nomeCidade': 'Portão',
          },
          {
            'id_cidade': 4314902,
            'nomeCidade': 'Porto Alegre',
          },
          {
            'id_cidade': 4315008,
            'nomeCidade': 'Porto Lucena',
          },
          {
            'id_cidade': 4315057,
            'nomeCidade': 'Porto Mauá',
          },
          {
            'id_cidade': 4315073,
            'nomeCidade': 'Porto Vera Cruz',
          },
          {
            'id_cidade': 4315107,
            'nomeCidade': 'Porto Xavier',
          },
          {
            'id_cidade': 4315131,
            'nomeCidade': 'Pouso Novo',
          },
          {
            'id_cidade': 4315149,
            'nomeCidade': 'Presidente Lucena',
          },
          {
            'id_cidade': 4315156,
            'nomeCidade': 'Progresso',
          },
          {
            'id_cidade': 4315172,
            'nomeCidade': 'Protásio Alves',
          },
          {
            'id_cidade': 4315206,
            'nomeCidade': 'Putinga',
          },
          {
            'id_cidade': 4315305,
            'nomeCidade': 'Quaraí',
          },
          {
            'id_cidade': 4315313,
            'nomeCidade': 'Quatro Irmãos',
          },
          {
            'id_cidade': 4315321,
            'nomeCidade': 'Quevedos',
          },
          {
            'id_cidade': 4315354,
            'nomeCidade': 'Quinze de Novembro',
          },
          {
            'id_cidade': 4315404,
            'nomeCidade': 'Redentora',
          },
          {
            'id_cidade': 4315453,
            'nomeCidade': 'Relvado',
          },
          {
            'id_cidade': 4315503,
            'nomeCidade': 'Restinga Sêca',
          },
          {
            'id_cidade': 4315552,
            'nomeCidade': 'Rio dos Índios',
          },
          {
            'id_cidade': 4315602,
            'nomeCidade': 'Rio Grande',
          },
          {
            'id_cidade': 4315701,
            'nomeCidade': 'Rio Pardo',
          },
          {
            'id_cidade': 4315750,
            'nomeCidade': 'Riozinho',
          },
          {
            'id_cidade': 4315800,
            'nomeCidade': 'Roca Sales',
          },
          {
            'id_cidade': 4315909,
            'nomeCidade': 'Rodeio Bonito',
          },
          {
            'id_cidade': 4315958,
            'nomeCidade': 'Rolador',
          },
          {
            'id_cidade': 4316006,
            'nomeCidade': 'Rolante',
          },
          {
            'id_cidade': 4316105,
            'nomeCidade': 'Ronda Alta',
          },
          {
            'id_cidade': 4316204,
            'nomeCidade': 'Rondinha',
          },
          {
            'id_cidade': 4316303,
            'nomeCidade': 'Roque Gonzales',
          },
          {
            'id_cidade': 4316402,
            'nomeCidade': 'Rosário do Sul',
          },
          {
            'id_cidade': 4316428,
            'nomeCidade': 'Sagrada Família',
          },
          {
            'id_cidade': 4316436,
            'nomeCidade': 'Saldanha Marinho',
          },
          {
            'id_cidade': 4316451,
            'nomeCidade': 'Salto do Jacuí',
          },
          {
            'id_cidade': 4316477,
            'nomeCidade': 'Salvador das Missões',
          },
          {
            'id_cidade': 4316501,
            'nomeCidade': 'Salvador do Sul',
          },
          {
            'id_cidade': 4316600,
            'nomeCidade': 'Sananduva',
          },
          {
            'id_cidade': 4316709,
            'nomeCidade': 'Santa Bárbara do Sul',
          },
          {
            'id_cidade': 4316733,
            'nomeCidade': 'Santa Cecília do Sul',
          },
          {
            'id_cidade': 4316758,
            'nomeCidade': 'Santa Clara do Sul',
          },
          {
            'id_cidade': 4316808,
            'nomeCidade': 'Santa Cruz do Sul',
          },
          {
            'id_cidade': 4316972,
            'nomeCidade': 'Santa Margarida do Sul',
          },
          {
            'id_cidade': 4316907,
            'nomeCidade': 'Santa Maria',
          },
          {
            'id_cidade': 4316956,
            'nomeCidade': 'Santa Maria do Herval',
          },
          {
            'id_cidade': 4317202,
            'nomeCidade': 'Santa Rosa',
          },
          {
            'id_cidade': 4317251,
            'nomeCidade': 'Santa Tereza',
          },
          {
            'id_cidade': 4317301,
            'nomeCidade': 'Santa Vitória do Palmar',
          },
          {
            'id_cidade': 4317004,
            'nomeCidade': 'Santana da Boa Vista',
          },
          {
            'id_cidade': 4317103,
            'nomeCidade': "Sant'Ana do Livramento",
          },
          {
            'id_cidade': 4317400,
            'nomeCidade': 'Santiago',
          },
          {
            'id_cidade': 4317509,
            'nomeCidade': 'Santo Ângelo',
          },
          {
            'id_cidade': 4317608,
            'nomeCidade': 'Santo Antônio da Patrulha',
          },
          {
            'id_cidade': 4317707,
            'nomeCidade': 'Santo Antônio das Missões',
          },
          {
            'id_cidade': 4317558,
            'nomeCidade': 'Santo Antônio do Palma',
          },
          {
            'id_cidade': 4317756,
            'nomeCidade': 'Santo Antônio do Planalto',
          },
          {
            'id_cidade': 4317806,
            'nomeCidade': 'Santo Augusto',
          },
          {
            'id_cidade': 4317905,
            'nomeCidade': 'Santo Cristo',
          },
          {
            'id_cidade': 4317954,
            'nomeCidade': 'Santo Expedito do Sul',
          },
          {
            'id_cidade': 4318002,
            'nomeCidade': 'São Borja',
          },
          {
            'id_cidade': 4318051,
            'nomeCidade': 'São Domingos do Sul',
          },
          {
            'id_cidade': 4318101,
            'nomeCidade': 'São Francisco de Assis',
          },
          {
            'id_cidade': 4318200,
            'nomeCidade': 'São Francisco de Paula',
          },
          {
            'id_cidade': 4318309,
            'nomeCidade': 'São Gabriel',
          },
          {
            'id_cidade': 4318408,
            'nomeCidade': 'São Jerônimo',
          },
          {
            'id_cidade': 4318424,
            'nomeCidade': 'São João da Urtiga',
          },
          {
            'id_cidade': 4318432,
            'nomeCidade': 'São João do Polêsine',
          },
          {
            'id_cidade': 4318440,
            'nomeCidade': 'São Jorge',
          },
          {
            'id_cidade': 4318457,
            'nomeCidade': 'São José das Missões',
          },
          {
            'id_cidade': 4318465,
            'nomeCidade': 'São José do Herval',
          },
          {
            'id_cidade': 4318481,
            'nomeCidade': 'São José do Hortêncio',
          },
          {
            'id_cidade': 4318499,
            'nomeCidade': 'São José do Inhacorá',
          },
          {
            'id_cidade': 4318507,
            'nomeCidade': 'São José do Norte',
          },
          {
            'id_cidade': 4318606,
            'nomeCidade': 'São José do Ouro',
          },
          {
            'id_cidade': 4318614,
            'nomeCidade': 'São José do Sul',
          },
          {
            'id_cidade': 4318622,
            'nomeCidade': 'São José dos Ausentes',
          },
          {
            'id_cidade': 4318705,
            'nomeCidade': 'São Leopoldo',
          },
          {
            'id_cidade': 4318804,
            'nomeCidade': 'São Lourenço do Sul',
          },
          {
            'id_cidade': 4318903,
            'nomeCidade': 'São Luiz Gonzaga',
          },
          {
            'id_cidade': 4319000,
            'nomeCidade': 'São Marcos',
          },
          {
            'id_cidade': 4319109,
            'nomeCidade': 'São Martinho',
          },
          {
            'id_cidade': 4319125,
            'nomeCidade': 'São Martinho da Serra',
          },
          {
            'id_cidade': 4319158,
            'nomeCidade': 'São Miguel das Missões',
          },
          {
            'id_cidade': 4319208,
            'nomeCidade': 'São Nicolau',
          },
          {
            'id_cidade': 4319307,
            'nomeCidade': 'São Paulo das Missões',
          },
          {
            'id_cidade': 4319356,
            'nomeCidade': 'São Pedro da Serra',
          },
          {
            'id_cidade': 4319364,
            'nomeCidade': 'São Pedro das Missões',
          },
          {
            'id_cidade': 4319372,
            'nomeCidade': 'São Pedro do Butiá',
          },
          {
            'id_cidade': 4319406,
            'nomeCidade': 'São Pedro do Sul',
          },
          {
            'id_cidade': 4319505,
            'nomeCidade': 'São Sebastião do Caí',
          },
          {
            'id_cidade': 4319604,
            'nomeCidade': 'São Sepé',
          },
          {
            'id_cidade': 4319703,
            'nomeCidade': 'São Valentim',
          },
          {
            'id_cidade': 4319711,
            'nomeCidade': 'São Valentim do Sul',
          },
          {
            'id_cidade': 4319737,
            'nomeCidade': 'São Valério do Sul',
          },
          {
            'id_cidade': 4319752,
            'nomeCidade': 'São Vendelino',
          },
          {
            'id_cidade': 4319802,
            'nomeCidade': 'São Vicente do Sul',
          },
          {
            'id_cidade': 4319901,
            'nomeCidade': 'Sapiranga',
          },
          {
            'id_cidade': 4320008,
            'nomeCidade': 'Sapucaia do Sul',
          },
          {
            'id_cidade': 4320107,
            'nomeCidade': 'Sarandi',
          },
          {
            'id_cidade': 4320206,
            'nomeCidade': 'Seberi',
          },
          {
            'id_cidade': 4320230,
            'nomeCidade': 'Sede Nova',
          },
          {
            'id_cidade': 4320263,
            'nomeCidade': 'Segredo',
          },
          {
            'id_cidade': 4320305,
            'nomeCidade': 'Selbach',
          },
          {
            'id_cidade': 4320321,
            'nomeCidade': 'Senador Salgado Filho',
          },
          {
            'id_cidade': 4320354,
            'nomeCidade': 'Sentinela do Sul',
          },
          {
            'id_cidade': 4320404,
            'nomeCidade': 'Serafina Corrêa',
          },
          {
            'id_cidade': 4320453,
            'nomeCidade': 'Sério',
          },
          {
            'id_cidade': 4320503,
            'nomeCidade': 'Sertão',
          },
          {
            'id_cidade': 4320552,
            'nomeCidade': 'Sertão Santana',
          },
          {
            'id_cidade': 4320578,
            'nomeCidade': 'Sete de Setembro',
          },
          {
            'id_cidade': 4320602,
            'nomeCidade': 'Severiano de Almeida',
          },
          {
            'id_cidade': 4320651,
            'nomeCidade': 'Silveira Martins',
          },
          {
            'id_cidade': 4320677,
            'nomeCidade': 'Sinimbu',
          },
          {
            'id_cidade': 4320701,
            'nomeCidade': 'Sobradinho',
          },
          {
            'id_cidade': 4320800,
            'nomeCidade': 'Soledade',
          },
          {
            'id_cidade': 4320859,
            'nomeCidade': 'Tabaí',
          },
          {
            'id_cidade': 4320909,
            'nomeCidade': 'Tapejara',
          },
          {
            'id_cidade': 4321006,
            'nomeCidade': 'Tapera',
          },
          {
            'id_cidade': 4321105,
            'nomeCidade': 'Tapes',
          },
          {
            'id_cidade': 4321204,
            'nomeCidade': 'Taquara',
          },
          {
            'id_cidade': 4321303,
            'nomeCidade': 'Taquari',
          },
          {
            'id_cidade': 4321329,
            'nomeCidade': 'Taquaruçu do Sul',
          },
          {
            'id_cidade': 4321352,
            'nomeCidade': 'Tavares',
          },
          {
            'id_cidade': 4321402,
            'nomeCidade': 'Tenente Portela',
          },
          {
            'id_cidade': 4321436,
            'nomeCidade': 'Terra de Areia',
          },
          {
            'id_cidade': 4321451,
            'nomeCidade': 'Teutônia',
          },
          {
            'id_cidade': 4321469,
            'nomeCidade': 'Tio Hugo',
          },
          {
            'id_cidade': 4321477,
            'nomeCidade': 'Tiradentes do Sul',
          },
          {
            'id_cidade': 4321493,
            'nomeCidade': 'Toropi',
          },
          {
            'id_cidade': 4321501,
            'nomeCidade': 'Torres',
          },
          {
            'id_cidade': 4321600,
            'nomeCidade': 'Tramandaí',
          },
          {
            'id_cidade': 4321626,
            'nomeCidade': 'Travesseiro',
          },
          {
            'id_cidade': 4321634,
            'nomeCidade': 'Três Arroios',
          },
          {
            'id_cidade': 4321667,
            'nomeCidade': 'Três Cachoeiras',
          },
          {
            'id_cidade': 4321709,
            'nomeCidade': 'Três Coroas',
          },
          {
            'id_cidade': 4321808,
            'nomeCidade': 'Três de Maio',
          },
          {
            'id_cidade': 4321832,
            'nomeCidade': 'Três Forquilhas',
          },
          {
            'id_cidade': 4321857,
            'nomeCidade': 'Três Palmeiras',
          },
          {
            'id_cidade': 4321907,
            'nomeCidade': 'Três Passos',
          },
          {
            'id_cidade': 4321956,
            'nomeCidade': 'Trindade do Sul',
          },
          {
            'id_cidade': 4322004,
            'nomeCidade': 'Triunfo',
          },
          {
            'id_cidade': 4322103,
            'nomeCidade': 'Tucunduva',
          },
          {
            'id_cidade': 4322152,
            'nomeCidade': 'Tunas',
          },
          {
            'id_cidade': 4322186,
            'nomeCidade': 'Tupanci do Sul',
          },
          {
            'id_cidade': 4322202,
            'nomeCidade': 'Tupanciretã',
          },
          {
            'id_cidade': 4322251,
            'nomeCidade': 'Tupandi',
          },
          {
            'id_cidade': 4322301,
            'nomeCidade': 'Tuparendi',
          },
          {
            'id_cidade': 4322327,
            'nomeCidade': 'Turuçu',
          },
          {
            'id_cidade': 4322343,
            'nomeCidade': 'Ubiretama',
          },
          {
            'id_cidade': 4322350,
            'nomeCidade': 'União da Serra',
          },
          {
            'id_cidade': 4322376,
            'nomeCidade': 'Unistalda',
          },
          {
            'id_cidade': 4322400,
            'nomeCidade': 'Uruguaiana',
          },
          {
            'id_cidade': 4322509,
            'nomeCidade': 'Vacaria',
          },
          {
            'id_cidade': 4322533,
            'nomeCidade': 'Vale do Sol',
          },
          {
            'id_cidade': 4322541,
            'nomeCidade': 'Vale Real',
          },
          {
            'id_cidade': 4322525,
            'nomeCidade': 'Vale Verde',
          },
          {
            'id_cidade': 4322558,
            'nomeCidade': 'Vanini',
          },
          {
            'id_cidade': 4322608,
            'nomeCidade': 'Venâncio Aires',
          },
          {
            'id_cidade': 4322707,
            'nomeCidade': 'Vera Cruz',
          },
          {
            'id_cidade': 4322806,
            'nomeCidade': 'Veranópolis',
          },
          {
            'id_cidade': 4322855,
            'nomeCidade': 'Vespasiano Corrêa',
          },
          {
            'id_cidade': 4322905,
            'nomeCidade': 'Viadutos',
          },
          {
            'id_cidade': 4323002,
            'nomeCidade': 'Viamão',
          },
          {
            'id_cidade': 4323101,
            'nomeCidade': 'Vicente Dutra',
          },
          {
            'id_cidade': 4323200,
            'nomeCidade': 'Victor Graeff',
          },
          {
            'id_cidade': 4323309,
            'nomeCidade': 'Vila Flores',
          },
          {
            'id_cidade': 4323358,
            'nomeCidade': 'Vila Lângaro',
          },
          {
            'id_cidade': 4323408,
            'nomeCidade': 'Vila Maria',
          },
          {
            'id_cidade': 4323457,
            'nomeCidade': 'Vila Nova do Sul',
          },
          {
            'id_cidade': 4323507,
            'nomeCidade': 'Vista Alegre',
          },
          {
            'id_cidade': 4323606,
            'nomeCidade': 'Vista Alegre do Prata',
          },
          {
            'id_cidade': 4323705,
            'nomeCidade': 'Vista Gaúcha',
          },
          {
            'id_cidade': 4323754,
            'nomeCidade': 'Vitória das Missões',
          },
          {
            'id_cidade': 4323770,
            'nomeCidade': 'Westfália',
          },
          {
            'id_cidade': 4323804,
            'nomeCidade': 'Xangri-lá',
          },
        ],
      },
      {
        'id': 11,
        'nomeEstado': 'Rondônia',
        'sigla': 'RO',
        'cidades': [
          {
            'id_cidade': 1100015,
            'nomeCidade': "Alta Floresta D'Oeste",
          },
          {
            'id_cidade': 1100379,
            'nomeCidade': 'Alto Alegre dos Parecis',
          },
          {
            'id_cidade': 1100403,
            'nomeCidade': 'Alto Paraíso',
          },
          {
            'id_cidade': 1100346,
            'nomeCidade': "Alvorada D'Oeste",
          },
          {
            'id_cidade': 1100023,
            'nomeCidade': 'Ariquemes',
          },
          {
            'id_cidade': 1100452,
            'nomeCidade': 'Buritis',
          },
          {
            'id_cidade': 1100031,
            'nomeCidade': 'Cabixi',
          },
          {
            'id_cidade': 1100601,
            'nomeCidade': 'Cacaulândia',
          },
          {
            'id_cidade': 1100049,
            'nomeCidade': 'Cacoal',
          },
          {
            'id_cidade': 1100700,
            'nomeCidade': 'Campo Novo de Rondônia',
          },
          {
            'id_cidade': 1100809,
            'nomeCidade': 'Candeias do Jamari',
          },
          {
            'id_cidade': 1100908,
            'nomeCidade': 'Castanheiras',
          },
          {
            'id_cidade': 1100056,
            'nomeCidade': 'Cerejeiras',
          },
          {
            'id_cidade': 1100924,
            'nomeCidade': 'Chupinguaia',
          },
          {
            'id_cidade': 1100064,
            'nomeCidade': 'Colorado do Oeste',
          },
          {
            'id_cidade': 1100072,
            'nomeCidade': 'Corumbiara',
          },
          {
            'id_cidade': 1100080,
            'nomeCidade': 'Costa Marques',
          },
          {
            'id_cidade': 1100940,
            'nomeCidade': 'Cujubim',
          },
          {
            'id_cidade': 1100098,
            'nomeCidade': "Espigão D'Oeste",
          },
          {
            'id_cidade': 1101005,
            'nomeCidade': 'Governador Jorge Teixeira',
          },
          {
            'id_cidade': 1100106,
            'nomeCidade': 'Guajará-Mirim',
          },
          {
            'id_cidade': 1101104,
            'nomeCidade': 'Itapuã do Oeste',
          },
          {
            'id_cidade': 1100114,
            'nomeCidade': 'Jaru',
          },
          {
            'id_cidade': 1100122,
            'nomeCidade': 'Ji-Paraná',
          },
          {
            'id_cidade': 1100130,
            'nomeCidade': "Machadinho D'Oeste",
          },
          {
            'id_cidade': 1101203,
            'nomeCidade': 'Ministro Andreazza',
          },
          {
            'id_cidade': 1101302,
            'nomeCidade': 'Mirante da Serra',
          },
          {
            'id_cidade': 1101401,
            'nomeCidade': 'Monte Negro',
          },
          {
            'id_cidade': 1100148,
            'nomeCidade': "Nova Brasilândia D'Oeste",
          },
          {
            'id_cidade': 1100338,
            'nomeCidade': 'Nova Mamoré',
          },
          {
            'id_cidade': 1101435,
            'nomeCidade': 'Nova União',
          },
          {
            'id_cidade': 1100502,
            'nomeCidade': 'Novo Horizonte do Oeste',
          },
          {
            'id_cidade': 1100155,
            'nomeCidade': 'Ouro Preto do Oeste',
          },
          {
            'id_cidade': 1101450,
            'nomeCidade': 'Parecis',
          },
          {
            'id_cidade': 1100189,
            'nomeCidade': 'Pimenta Bueno',
          },
          {
            'id_cidade': 1101468,
            'nomeCidade': 'Pimenteiras do Oeste',
          },
          {
            'id_cidade': 1100205,
            'nomeCidade': 'Porto Velho',
          },
          {
            'id_cidade': 1100254,
            'nomeCidade': 'Presidente Médici',
          },
          {
            'id_cidade': 1101476,
            'nomeCidade': 'Primavera de Rondônia',
          },
          {
            'id_cidade': 1100262,
            'nomeCidade': 'Rio Crespo',
          },
          {
            'id_cidade': 1100288,
            'nomeCidade': 'Rolim de Moura',
          },
          {
            'id_cidade': 1100296,
            'nomeCidade': "Santa Luzia D'Oeste",
          },
          {
            'id_cidade': 1101484,
            'nomeCidade': "São Felipe D'Oeste",
          },
          {
            'id_cidade': 1101492,
            'nomeCidade': 'São Francisco do Guaporé',
          },
          {
            'id_cidade': 1100320,
            'nomeCidade': 'São Miguel do Guaporé',
          },
          {
            'id_cidade': 1101500,
            'nomeCidade': 'Seringueiras',
          },
          {
            'id_cidade': 1101559,
            'nomeCidade': 'Teixeirópolis',
          },
          {
            'id_cidade': 1101609,
            'nomeCidade': 'Theobroma',
          },
          {
            'id_cidade': 1101708,
            'nomeCidade': 'Urupá',
          },
          {
            'id_cidade': 1101757,
            'nomeCidade': 'Vale do Anari',
          },
          {
            'id_cidade': 1101807,
            'nomeCidade': 'Vale do Paraíso',
          },
          {
            'id_cidade': 1100304,
            'nomeCidade': 'Vilhena',
          },
        ],
      },
      {
        'id': 14,
        'nomeEstado': 'Roraima',
        'sigla': 'RR',
        'cidades': [
          {
            'id_cidade': 1400050,
            'nomeCidade': 'Alto Alegre',
          },
          {
            'id_cidade': 1400027,
            'nomeCidade': 'Amajari',
          },
          {
            'id_cidade': 1400100,
            'nomeCidade': 'Boa Vista',
          },
          {
            'id_cidade': 1400159,
            'nomeCidade': 'Bonfim',
          },
          {
            'id_cidade': 1400175,
            'nomeCidade': 'Cantá',
          },
          {
            'id_cidade': 1400209,
            'nomeCidade': 'Caracaraí',
          },
          {
            'id_cidade': 1400233,
            'nomeCidade': 'Caroebe',
          },
          {
            'id_cidade': 1400282,
            'nomeCidade': 'Iracema',
          },
          {
            'id_cidade': 1400308,
            'nomeCidade': 'Mucajaí',
          },
          {
            'id_cidade': 1400407,
            'nomeCidade': 'Normandia',
          },
          {
            'id_cidade': 1400456,
            'nomeCidade': 'Pacaraima',
          },
          {
            'id_cidade': 1400472,
            'nomeCidade': 'Rorainópolis',
          },
          {
            'id_cidade': 1400506,
            'nomeCidade': 'São João da Baliza',
          },
          {
            'id_cidade': 1400605,
            'nomeCidade': 'São Luiz',
          },
          {
            'id_cidade': 1400704,
            'nomeCidade': 'Uiramutã',
          },
        ],
      },
      {
        'id': 42,
        'nomeEstado': 'Santa Catarina',
        'sigla': 'SC',
        'cidades': [
          {
            'id_cidade': 4200051,
            'nomeCidade': 'Abdon Batista',
          },
          {
            'id_cidade': 4200101,
            'nomeCidade': 'Abelardo Luz',
          },
          {
            'id_cidade': 4200200,
            'nomeCidade': 'Agrolândia',
          },
          {
            'id_cidade': 4200309,
            'nomeCidade': 'Agronômica',
          },
          {
            'id_cidade': 4200408,
            'nomeCidade': 'Água Doce',
          },
          {
            'id_cidade': 4200507,
            'nomeCidade': 'Águas de Chapecó',
          },
          {
            'id_cidade': 4200556,
            'nomeCidade': 'Águas Frias',
          },
          {
            'id_cidade': 4200606,
            'nomeCidade': 'Águas Mornas',
          },
          {
            'id_cidade': 4200705,
            'nomeCidade': 'Alfredo Wagner',
          },
          {
            'id_cidade': 4200754,
            'nomeCidade': 'Alto Bela Vista',
          },
          {
            'id_cidade': 4200804,
            'nomeCidade': 'Anchieta',
          },
          {
            'id_cidade': 4200903,
            'nomeCidade': 'Angelina',
          },
          {
            'id_cidade': 4201000,
            'nomeCidade': 'Anita Garibaldi',
          },
          {
            'id_cidade': 4201109,
            'nomeCidade': 'Anitápolis',
          },
          {
            'id_cidade': 4201208,
            'nomeCidade': 'Antônio Carlos',
          },
          {
            'id_cidade': 4201257,
            'nomeCidade': 'Apiúna',
          },
          {
            'id_cidade': 4201273,
            'nomeCidade': 'Arabutã',
          },
          {
            'id_cidade': 4201307,
            'nomeCidade': 'Araquari',
          },
          {
            'id_cidade': 4201406,
            'nomeCidade': 'Araranguá',
          },
          {
            'id_cidade': 4201505,
            'nomeCidade': 'Armazém',
          },
          {
            'id_cidade': 4201604,
            'nomeCidade': 'Arroio Trinta',
          },
          {
            'id_cidade': 4201653,
            'nomeCidade': 'Arvoredo',
          },
          {
            'id_cidade': 4201703,
            'nomeCidade': 'Ascurra',
          },
          {
            'id_cidade': 4201802,
            'nomeCidade': 'Atalanta',
          },
          {
            'id_cidade': 4201901,
            'nomeCidade': 'Aurora',
          },
          {
            'id_cidade': 4201950,
            'nomeCidade': 'Balneário Arroio do Silva',
          },
          {
            'id_cidade': 4202057,
            'nomeCidade': 'Balneário Barra do Sul',
          },
          {
            'id_cidade': 4202008,
            'nomeCidade': 'Balneário Camboriú',
          },
          {
            'id_cidade': 4202073,
            'nomeCidade': 'Balneário Gaivota',
          },
          {
            'id_cidade': 4212809,
            'nomeCidade': 'Balneário Piçarras',
          },
          {
            'id_cidade': 4220000,
            'nomeCidade': 'Balneário Rincão',
          },
          {
            'id_cidade': 4202081,
            'nomeCidade': 'Bandeirante',
          },
          {
            'id_cidade': 4202099,
            'nomeCidade': 'Barra Bonita',
          },
          {
            'id_cidade': 4202107,
            'nomeCidade': 'Barra Velha',
          },
          {
            'id_cidade': 4202131,
            'nomeCidade': 'Bela Vista do Toldo',
          },
          {
            'id_cidade': 4202156,
            'nomeCidade': 'Belmonte',
          },
          {
            'id_cidade': 4202206,
            'nomeCidade': 'Benedito Novo',
          },
          {
            'id_cidade': 4202305,
            'nomeCidade': 'Biguaçu',
          },
          {
            'id_cidade': 4202404,
            'nomeCidade': 'Blumenau',
          },
          {
            'id_cidade': 4202438,
            'nomeCidade': 'Bocaina do Sul',
          },
          {
            'id_cidade': 4202503,
            'nomeCidade': 'Bom Jardim da Serra',
          },
          {
            'id_cidade': 4202537,
            'nomeCidade': 'Bom Jesus',
          },
          {
            'id_cidade': 4202578,
            'nomeCidade': 'Bom Jesus do Oeste',
          },
          {
            'id_cidade': 4202602,
            'nomeCidade': 'Bom Retiro',
          },
          {
            'id_cidade': 4202453,
            'nomeCidade': 'Bombinhas',
          },
          {
            'id_cidade': 4202701,
            'nomeCidade': 'Botuverá',
          },
          {
            'id_cidade': 4202800,
            'nomeCidade': 'Braço do Norte',
          },
          {
            'id_cidade': 4202859,
            'nomeCidade': 'Braço do Trombudo',
          },
          {
            'id_cidade': 4202875,
            'nomeCidade': 'Brunópolis',
          },
          {
            'id_cidade': 4202909,
            'nomeCidade': 'Brusque',
          },
          {
            'id_cidade': 4203006,
            'nomeCidade': 'Caçador',
          },
          {
            'id_cidade': 4203105,
            'nomeCidade': 'Caibi',
          },
          {
            'id_cidade': 4203154,
            'nomeCidade': 'Calmon',
          },
          {
            'id_cidade': 4203204,
            'nomeCidade': 'Camboriú',
          },
          {
            'id_cidade': 4203303,
            'nomeCidade': 'Campo Alegre',
          },
          {
            'id_cidade': 4203402,
            'nomeCidade': 'Campo Belo do Sul',
          },
          {
            'id_cidade': 4203501,
            'nomeCidade': 'Campo Erê',
          },
          {
            'id_cidade': 4203600,
            'nomeCidade': 'Campos Novos',
          },
          {
            'id_cidade': 4203709,
            'nomeCidade': 'Canelinha',
          },
          {
            'id_cidade': 4203808,
            'nomeCidade': 'Canoinhas',
          },
          {
            'id_cidade': 4203253,
            'nomeCidade': 'Capão Alto',
          },
          {
            'id_cidade': 4203907,
            'nomeCidade': 'Capinzal',
          },
          {
            'id_cidade': 4203956,
            'nomeCidade': 'Capivari de Baixo',
          },
          {
            'id_cidade': 4204004,
            'nomeCidade': 'Catanduvas',
          },
          {
            'id_cidade': 4204103,
            'nomeCidade': 'Caxambu do Sul',
          },
          {
            'id_cidade': 4204152,
            'nomeCidade': 'Celso Ramos',
          },
          {
            'id_cidade': 4204178,
            'nomeCidade': 'Cerro Negro',
          },
          {
            'id_cidade': 4204194,
            'nomeCidade': 'Chapadão do Lageado',
          },
          {
            'id_cidade': 4204202,
            'nomeCidade': 'Chapecó',
          },
          {
            'id_cidade': 4204251,
            'nomeCidade': 'Cocal do Sul',
          },
          {
            'id_cidade': 4204301,
            'nomeCidade': 'Concórdia',
          },
          {
            'id_cidade': 4204350,
            'nomeCidade': 'Cordilheira Alta',
          },
          {
            'id_cidade': 4204400,
            'nomeCidade': 'Coronel Freitas',
          },
          {
            'id_cidade': 4204459,
            'nomeCidade': 'Coronel Martins',
          },
          {
            'id_cidade': 4204558,
            'nomeCidade': 'Correia Pinto',
          },
          {
            'id_cidade': 4204509,
            'nomeCidade': 'Corupá',
          },
          {
            'id_cidade': 4204608,
            'nomeCidade': 'Criciúma',
          },
          {
            'id_cidade': 4204707,
            'nomeCidade': 'Cunha Porã',
          },
          {
            'id_cidade': 4204756,
            'nomeCidade': 'Cunhataí',
          },
          {
            'id_cidade': 4204806,
            'nomeCidade': 'Curitibanos',
          },
          {
            'id_cidade': 4204905,
            'nomeCidade': 'Descanso',
          },
          {
            'id_cidade': 4205001,
            'nomeCidade': 'Dionísio Cerqueira',
          },
          {
            'id_cidade': 4205100,
            'nomeCidade': 'Dona Emma',
          },
          {
            'id_cidade': 4205159,
            'nomeCidade': 'Doutor Pedrinho',
          },
          {
            'id_cidade': 4205175,
            'nomeCidade': 'Entre Rios',
          },
          {
            'id_cidade': 4205191,
            'nomeCidade': 'Ermo',
          },
          {
            'id_cidade': 4205209,
            'nomeCidade': 'Erval Velho',
          },
          {
            'id_cidade': 4205308,
            'nomeCidade': 'Faxinal dos Guedes',
          },
          {
            'id_cidade': 4205357,
            'nomeCidade': 'Flor do Sertão',
          },
          {
            'id_cidade': 4205407,
            'nomeCidade': 'Florianópolis',
          },
          {
            'id_cidade': 4205431,
            'nomeCidade': 'Formosa do Sul',
          },
          {
            'id_cidade': 4205456,
            'nomeCidade': 'Forquilhinha',
          },
          {
            'id_cidade': 4205506,
            'nomeCidade': 'Fraiburgo',
          },
          {
            'id_cidade': 4205555,
            'nomeCidade': 'Frei Rogério',
          },
          {
            'id_cidade': 4205605,
            'nomeCidade': 'Galvão',
          },
          {
            'id_cidade': 4205704,
            'nomeCidade': 'Garopaba',
          },
          {
            'id_cidade': 4205803,
            'nomeCidade': 'Garuva',
          },
          {
            'id_cidade': 4205902,
            'nomeCidade': 'Gaspar',
          },
          {
            'id_cidade': 4206009,
            'nomeCidade': 'Governador Celso Ramos',
          },
          {
            'id_cidade': 4206108,
            'nomeCidade': 'Grão Pará',
          },
          {
            'id_cidade': 4206207,
            'nomeCidade': 'Gravatal',
          },
          {
            'id_cidade': 4206306,
            'nomeCidade': 'Guabiruba',
          },
          {
            'id_cidade': 4206405,
            'nomeCidade': 'Guaraciaba',
          },
          {
            'id_cidade': 4206504,
            'nomeCidade': 'Guaramirim',
          },
          {
            'id_cidade': 4206603,
            'nomeCidade': 'Guarujá do Sul',
          },
          {
            'id_cidade': 4206652,
            'nomeCidade': 'Guatambú',
          },
          {
            'id_cidade': 4206702,
            'nomeCidade': "Herval d'Oeste",
          },
          {
            'id_cidade': 4206751,
            'nomeCidade': 'Ibiam',
          },
          {
            'id_cidade': 4206801,
            'nomeCidade': 'Ibicaré',
          },
          {
            'id_cidade': 4206900,
            'nomeCidade': 'Ibirama',
          },
          {
            'id_cidade': 4207007,
            'nomeCidade': 'Içara',
          },
          {
            'id_cidade': 4207106,
            'nomeCidade': 'Ilhota',
          },
          {
            'id_cidade': 4207205,
            'nomeCidade': 'Imaruí',
          },
          {
            'id_cidade': 4207304,
            'nomeCidade': 'Imbituba',
          },
          {
            'id_cidade': 4207403,
            'nomeCidade': 'Imbuia',
          },
          {
            'id_cidade': 4207502,
            'nomeCidade': 'Indaial',
          },
          {
            'id_cidade': 4207577,
            'nomeCidade': 'Iomerê',
          },
          {
            'id_cidade': 4207601,
            'nomeCidade': 'Ipira',
          },
          {
            'id_cidade': 4207650,
            'nomeCidade': 'Iporã do Oeste',
          },
          {
            'id_cidade': 4207684,
            'nomeCidade': 'Ipuaçu',
          },
          {
            'id_cidade': 4207700,
            'nomeCidade': 'Ipumirim',
          },
          {
            'id_cidade': 4207759,
            'nomeCidade': 'Iraceminha',
          },
          {
            'id_cidade': 4207809,
            'nomeCidade': 'Irani',
          },
          {
            'id_cidade': 4207858,
            'nomeCidade': 'Irati',
          },
          {
            'id_cidade': 4207908,
            'nomeCidade': 'Irineópolis',
          },
          {
            'id_cidade': 4208005,
            'nomeCidade': 'Itá',
          },
          {
            'id_cidade': 4208104,
            'nomeCidade': 'Itaiópolis',
          },
          {
            'id_cidade': 4208203,
            'nomeCidade': 'Itajaí',
          },
          {
            'id_cidade': 4208302,
            'nomeCidade': 'Itapema',
          },
          {
            'id_cidade': 4208401,
            'nomeCidade': 'Itapiranga',
          },
          {
            'id_cidade': 4208450,
            'nomeCidade': 'Itapoá',
          },
          {
            'id_cidade': 4208500,
            'nomeCidade': 'Ituporanga',
          },
          {
            'id_cidade': 4208609,
            'nomeCidade': 'Jaborá',
          },
          {
            'id_cidade': 4208708,
            'nomeCidade': 'Jacinto Machado',
          },
          {
            'id_cidade': 4208807,
            'nomeCidade': 'Jaguaruna',
          },
          {
            'id_cidade': 4208906,
            'nomeCidade': 'Jaraguá do Sul',
          },
          {
            'id_cidade': 4208955,
            'nomeCidade': 'Jardinópolis',
          },
          {
            'id_cidade': 4209003,
            'nomeCidade': 'Joaçaba',
          },
          {
            'id_cidade': 4209102,
            'nomeCidade': 'Joinville',
          },
          {
            'id_cidade': 4209151,
            'nomeCidade': 'José Boiteux',
          },
          {
            'id_cidade': 4209177,
            'nomeCidade': 'Jupiá',
          },
          {
            'id_cidade': 4209201,
            'nomeCidade': 'Lacerdópolis',
          },
          {
            'id_cidade': 4209300,
            'nomeCidade': 'Lages',
          },
          {
            'id_cidade': 4209409,
            'nomeCidade': 'Laguna',
          },
          {
            'id_cidade': 4209458,
            'nomeCidade': 'Lajeado Grande',
          },
          {
            'id_cidade': 4209508,
            'nomeCidade': 'Laurentino',
          },
          {
            'id_cidade': 4209607,
            'nomeCidade': 'Lauro Müller',
          },
          {
            'id_cidade': 4209706,
            'nomeCidade': 'Lebon Régis',
          },
          {
            'id_cidade': 4209805,
            'nomeCidade': 'Leoberto Leal',
          },
          {
            'id_cidade': 4209854,
            'nomeCidade': 'Lindóia do Sul',
          },
          {
            'id_cidade': 4209904,
            'nomeCidade': 'Lontras',
          },
          {
            'id_cidade': 4210001,
            'nomeCidade': 'Luiz Alves',
          },
          {
            'id_cidade': 4210035,
            'nomeCidade': 'Luzerna',
          },
          {
            'id_cidade': 4210050,
            'nomeCidade': 'Macieira',
          },
          {
            'id_cidade': 4210100,
            'nomeCidade': 'Mafra',
          },
          {
            'id_cidade': 4210209,
            'nomeCidade': 'Major Gercino',
          },
          {
            'id_cidade': 4210308,
            'nomeCidade': 'Major Vieira',
          },
          {
            'id_cidade': 4210407,
            'nomeCidade': 'Maracajá',
          },
          {
            'id_cidade': 4210506,
            'nomeCidade': 'Maravilha',
          },
          {
            'id_cidade': 4210555,
            'nomeCidade': 'Marema',
          },
          {
            'id_cidade': 4210605,
            'nomeCidade': 'Massaranduba',
          },
          {
            'id_cidade': 4210704,
            'nomeCidade': 'Matos Costa',
          },
          {
            'id_cidade': 4210803,
            'nomeCidade': 'Meleiro',
          },
          {
            'id_cidade': 4210852,
            'nomeCidade': 'Mirim Doce',
          },
          {
            'id_cidade': 4210902,
            'nomeCidade': 'Modelo',
          },
          {
            'id_cidade': 4211009,
            'nomeCidade': 'Mondaí',
          },
          {
            'id_cidade': 4211058,
            'nomeCidade': 'Monte Carlo',
          },
          {
            'id_cidade': 4211108,
            'nomeCidade': 'Monte Castelo',
          },
          {
            'id_cidade': 4211207,
            'nomeCidade': 'Morro da Fumaça',
          },
          {
            'id_cidade': 4211256,
            'nomeCidade': 'Morro Grande',
          },
          {
            'id_cidade': 4211306,
            'nomeCidade': 'Navegantes',
          },
          {
            'id_cidade': 4211405,
            'nomeCidade': 'Nova Erechim',
          },
          {
            'id_cidade': 4211454,
            'nomeCidade': 'Nova Itaberaba',
          },
          {
            'id_cidade': 4211504,
            'nomeCidade': 'Nova Trento',
          },
          {
            'id_cidade': 4211603,
            'nomeCidade': 'Nova Veneza',
          },
          {
            'id_cidade': 4211652,
            'nomeCidade': 'Novo Horizonte',
          },
          {
            'id_cidade': 4211702,
            'nomeCidade': 'Orleans',
          },
          {
            'id_cidade': 4211751,
            'nomeCidade': 'Otacílio Costa',
          },
          {
            'id_cidade': 4211801,
            'nomeCidade': 'Ouro',
          },
          {
            'id_cidade': 4211850,
            'nomeCidade': 'Ouro Verde',
          },
          {
            'id_cidade': 4211876,
            'nomeCidade': 'Paial',
          },
          {
            'id_cidade': 4211892,
            'nomeCidade': 'Painel',
          },
          {
            'id_cidade': 4211900,
            'nomeCidade': 'Palhoça',
          },
          {
            'id_cidade': 4212007,
            'nomeCidade': 'Palma Sola',
          },
          {
            'id_cidade': 4212056,
            'nomeCidade': 'Palmeira',
          },
          {
            'id_cidade': 4212106,
            'nomeCidade': 'Palmitos',
          },
          {
            'id_cidade': 4212205,
            'nomeCidade': 'Papanduva',
          },
          {
            'id_cidade': 4212239,
            'nomeCidade': 'Paraíso',
          },
          {
            'id_cidade': 4212254,
            'nomeCidade': 'Passo de Torres',
          },
          {
            'id_cidade': 4212270,
            'nomeCidade': 'Passos Maia',
          },
          {
            'id_cidade': 4212304,
            'nomeCidade': 'Paulo Lopes',
          },
          {
            'id_cidade': 4212403,
            'nomeCidade': 'Pedras Grandes',
          },
          {
            'id_cidade': 4212502,
            'nomeCidade': 'Penha',
          },
          {
            'id_cidade': 4212601,
            'nomeCidade': 'Peritiba',
          },
          {
            'id_cidade': 4212650,
            'nomeCidade': 'Pescaria Brava',
          },
          {
            'id_cidade': 4212700,
            'nomeCidade': 'Petrolândia',
          },
          {
            'id_cidade': 4212908,
            'nomeCidade': 'Pinhalzinho',
          },
          {
            'id_cidade': 4213005,
            'nomeCidade': 'Pinheiro Preto',
          },
          {
            'id_cidade': 4213104,
            'nomeCidade': 'Piratuba',
          },
          {
            'id_cidade': 4213153,
            'nomeCidade': 'Planalto Alegre',
          },
          {
            'id_cidade': 4213203,
            'nomeCidade': 'Pomerode',
          },
          {
            'id_cidade': 4213302,
            'nomeCidade': 'Ponte Alta',
          },
          {
            'id_cidade': 4213351,
            'nomeCidade': 'Ponte Alta do Norte',
          },
          {
            'id_cidade': 4213401,
            'nomeCidade': 'Ponte Serrada',
          },
          {
            'id_cidade': 4213500,
            'nomeCidade': 'Porto Belo',
          },
          {
            'id_cidade': 4213609,
            'nomeCidade': 'Porto União',
          },
          {
            'id_cidade': 4213708,
            'nomeCidade': 'Pouso Redondo',
          },
          {
            'id_cidade': 4213807,
            'nomeCidade': 'Praia Grande',
          },
          {
            'id_cidade': 4213906,
            'nomeCidade': 'Presidente Castello Branco',
          },
          {
            'id_cidade': 4214003,
            'nomeCidade': 'Presidente Getúlio',
          },
          {
            'id_cidade': 4214102,
            'nomeCidade': 'Presidente Nereu',
          },
          {
            'id_cidade': 4214151,
            'nomeCidade': 'Princesa',
          },
          {
            'id_cidade': 4214201,
            'nomeCidade': 'Quilombo',
          },
          {
            'id_cidade': 4214300,
            'nomeCidade': 'Rancho Queimado',
          },
          {
            'id_cidade': 4214409,
            'nomeCidade': 'Rio das Antas',
          },
          {
            'id_cidade': 4214508,
            'nomeCidade': 'Rio do Campo',
          },
          {
            'id_cidade': 4214607,
            'nomeCidade': 'Rio do Oeste',
          },
          {
            'id_cidade': 4214805,
            'nomeCidade': 'Rio do Sul',
          },
          {
            'id_cidade': 4214706,
            'nomeCidade': 'Rio dos Cedros',
          },
          {
            'id_cidade': 4214904,
            'nomeCidade': 'Rio Fortuna',
          },
          {
            'id_cidade': 4215000,
            'nomeCidade': 'Rio Negrinho',
          },
          {
            'id_cidade': 4215059,
            'nomeCidade': 'Rio Rufino',
          },
          {
            'id_cidade': 4215075,
            'nomeCidade': 'Riqueza',
          },
          {
            'id_cidade': 4215109,
            'nomeCidade': 'Rodeio',
          },
          {
            'id_cidade': 4215208,
            'nomeCidade': 'Romelândia',
          },
          {
            'id_cidade': 4215307,
            'nomeCidade': 'Salete',
          },
          {
            'id_cidade': 4215356,
            'nomeCidade': 'Saltinho',
          },
          {
            'id_cidade': 4215406,
            'nomeCidade': 'Salto Veloso',
          },
          {
            'id_cidade': 4215455,
            'nomeCidade': 'Sangão',
          },
          {
            'id_cidade': 4215505,
            'nomeCidade': 'Santa Cecília',
          },
          {
            'id_cidade': 4215554,
            'nomeCidade': 'Santa Helena',
          },
          {
            'id_cidade': 4215604,
            'nomeCidade': 'Santa Rosa de Lima',
          },
          {
            'id_cidade': 4215653,
            'nomeCidade': 'Santa Rosa do Sul',
          },
          {
            'id_cidade': 4215679,
            'nomeCidade': 'Santa Terezinha',
          },
          {
            'id_cidade': 4215687,
            'nomeCidade': 'Santa Terezinha do Progresso',
          },
          {
            'id_cidade': 4215695,
            'nomeCidade': 'Santiago do Sul',
          },
          {
            'id_cidade': 4215703,
            'nomeCidade': 'Santo Amaro da Imperatriz',
          },
          {
            'id_cidade': 4215802,
            'nomeCidade': 'São Bento do Sul',
          },
          {
            'id_cidade': 4215752,
            'nomeCidade': 'São Bernardino',
          },
          {
            'id_cidade': 4215901,
            'nomeCidade': 'São Bonifácio',
          },
          {
            'id_cidade': 4216008,
            'nomeCidade': 'São Carlos',
          },
          {
            'id_cidade': 4216057,
            'nomeCidade': 'São Cristóvão do Sul',
          },
          {
            'id_cidade': 4216107,
            'nomeCidade': 'São Domingos',
          },
          {
            'id_cidade': 4216206,
            'nomeCidade': 'São Francisco do Sul',
          },
          {
            'id_cidade': 4216305,
            'nomeCidade': 'São João Batista',
          },
          {
            'id_cidade': 4216354,
            'nomeCidade': 'São João do Itaperiú',
          },
          {
            'id_cidade': 4216255,
            'nomeCidade': 'São João do Oeste',
          },
          {
            'id_cidade': 4216404,
            'nomeCidade': 'São João do Sul',
          },
          {
            'id_cidade': 4216503,
            'nomeCidade': 'São Joaquim',
          },
          {
            'id_cidade': 4216602,
            'nomeCidade': 'São José',
          },
          {
            'id_cidade': 4216701,
            'nomeCidade': 'São José do Cedro',
          },
          {
            'id_cidade': 4216800,
            'nomeCidade': 'São José do Cerrito',
          },
          {
            'id_cidade': 4216909,
            'nomeCidade': 'São Lourenço do Oeste',
          },
          {
            'id_cidade': 4217006,
            'nomeCidade': 'São Ludgero',
          },
          {
            'id_cidade': 4217105,
            'nomeCidade': 'São Martinho',
          },
          {
            'id_cidade': 4217154,
            'nomeCidade': 'São Miguel da Boa Vista',
          },
          {
            'id_cidade': 4217204,
            'nomeCidade': 'São Miguel do Oeste',
          },
          {
            'id_cidade': 4217253,
            'nomeCidade': 'São Pedro de Alcântara',
          },
          {
            'id_cidade': 4217303,
            'nomeCidade': 'Saudades',
          },
          {
            'id_cidade': 4217402,
            'nomeCidade': 'Schroeder',
          },
          {
            'id_cidade': 4217501,
            'nomeCidade': 'Seara',
          },
          {
            'id_cidade': 4217550,
            'nomeCidade': 'Serra Alta',
          },
          {
            'id_cidade': 4217600,
            'nomeCidade': 'Siderópolis',
          },
          {
            'id_cidade': 4217709,
            'nomeCidade': 'Sombrio',
          },
          {
            'id_cidade': 4217758,
            'nomeCidade': 'Sul Brasil',
          },
          {
            'id_cidade': 4217808,
            'nomeCidade': 'Taió',
          },
          {
            'id_cidade': 4217907,
            'nomeCidade': 'Tangará',
          },
          {
            'id_cidade': 4217956,
            'nomeCidade': 'Tigrinhos',
          },
          {
            'id_cidade': 4218004,
            'nomeCidade': 'Tijucas',
          },
          {
            'id_cidade': 4218103,
            'nomeCidade': 'Timbé do Sul',
          },
          {
            'id_cidade': 4218202,
            'nomeCidade': 'Timbó',
          },
          {
            'id_cidade': 4218251,
            'nomeCidade': 'Timbó Grande',
          },
          {
            'id_cidade': 4218301,
            'nomeCidade': 'Três Barras',
          },
          {
            'id_cidade': 4218350,
            'nomeCidade': 'Treviso',
          },
          {
            'id_cidade': 4218400,
            'nomeCidade': 'Treze de Maio',
          },
          {
            'id_cidade': 4218509,
            'nomeCidade': 'Treze Tílias',
          },
          {
            'id_cidade': 4218608,
            'nomeCidade': 'Trombudo Central',
          },
          {
            'id_cidade': 4218707,
            'nomeCidade': 'Tubarão',
          },
          {
            'id_cidade': 4218756,
            'nomeCidade': 'Tunápolis',
          },
          {
            'id_cidade': 4218806,
            'nomeCidade': 'Turvo',
          },
          {
            'id_cidade': 4218855,
            'nomeCidade': 'União do Oeste',
          },
          {
            'id_cidade': 4218905,
            'nomeCidade': 'Urubici',
          },
          {
            'id_cidade': 4218954,
            'nomeCidade': 'Urupema',
          },
          {
            'id_cidade': 4219002,
            'nomeCidade': 'Urussanga',
          },
          {
            'id_cidade': 4219101,
            'nomeCidade': 'Vargeão',
          },
          {
            'id_cidade': 4219150,
            'nomeCidade': 'Vargem',
          },
          {
            'id_cidade': 4219176,
            'nomeCidade': 'Vargem Bonita',
          },
          {
            'id_cidade': 4219200,
            'nomeCidade': 'Vidal Ramos',
          },
          {
            'id_cidade': 4219309,
            'nomeCidade': 'Videira',
          },
          {
            'id_cidade': 4219358,
            'nomeCidade': 'Vitor Meireles',
          },
          {
            'id_cidade': 4219408,
            'nomeCidade': 'Witmarsum',
          },
          {
            'id_cidade': 4219507,
            'nomeCidade': 'Xanxerê',
          },
          {
            'id_cidade': 4219606,
            'nomeCidade': 'Xavantina',
          },
          {
            'id_cidade': 4219705,
            'nomeCidade': 'Xaxim',
          },
          {
            'id_cidade': 4219853,
            'nomeCidade': 'Zortéa',
          },
        ],
      },
      {
        'id': 35,
        'nomeEstado': 'São Paulo',
        'sigla': 'SP',
        'cidades': [
          {
            'id_cidade': 3500105,
            'nomeCidade': 'Adamantina',
          },
          {
            'id_cidade': 3500204,
            'nomeCidade': 'Adolfo',
          },
          {
            'id_cidade': 3500303,
            'nomeCidade': 'Aguaí',
          },
          {
            'id_cidade': 3500402,
            'nomeCidade': 'Águas da Prata',
          },
          {
            'id_cidade': 3500501,
            'nomeCidade': 'Águas de Lindóia',
          },
          {
            'id_cidade': 3500550,
            'nomeCidade': 'Águas de Santa Bárbara',
          },
          {
            'id_cidade': 3500600,
            'nomeCidade': 'Águas de São Pedro',
          },
          {
            'id_cidade': 3500709,
            'nomeCidade': 'Agudos',
          },
          {
            'id_cidade': 3500758,
            'nomeCidade': 'Alambari',
          },
          {
            'id_cidade': 3500808,
            'nomeCidade': 'Alfredo Marcondes',
          },
          {
            'id_cidade': 3500907,
            'nomeCidade': 'Altair',
          },
          {
            'id_cidade': 3501004,
            'nomeCidade': 'Altinópolis',
          },
          {
            'id_cidade': 3501103,
            'nomeCidade': 'Alto Alegre',
          },
          {
            'id_cidade': 3501152,
            'nomeCidade': 'Alumínio',
          },
          {
            'id_cidade': 3501202,
            'nomeCidade': 'Álvares Florence',
          },
          {
            'id_cidade': 3501301,
            'nomeCidade': 'Álvares Machado',
          },
          {
            'id_cidade': 3501400,
            'nomeCidade': 'Álvaro de Carvalho',
          },
          {
            'id_cidade': 3501509,
            'nomeCidade': 'Alvinlândia',
          },
          {
            'id_cidade': 3501608,
            'nomeCidade': 'Americana',
          },
          {
            'id_cidade': 3501707,
            'nomeCidade': 'Américo Brasiliense',
          },
          {
            'id_cidade': 3501806,
            'nomeCidade': 'Américo de Campos',
          },
          {
            'id_cidade': 3501905,
            'nomeCidade': 'Amparo',
          },
          {
            'id_cidade': 3502002,
            'nomeCidade': 'Analândia',
          },
          {
            'id_cidade': 3502101,
            'nomeCidade': 'Andradina',
          },
          {
            'id_cidade': 3502200,
            'nomeCidade': 'Angatuba',
          },
          {
            'id_cidade': 3502309,
            'nomeCidade': 'Anhembi',
          },
          {
            'id_cidade': 3502408,
            'nomeCidade': 'Anhumas',
          },
          {
            'id_cidade': 3502507,
            'nomeCidade': 'Aparecida',
          },
          {
            'id_cidade': 3502606,
            'nomeCidade': "Aparecida d'Oeste",
          },
          {
            'id_cidade': 3502705,
            'nomeCidade': 'Apiaí',
          },
          {
            'id_cidade': 3502754,
            'nomeCidade': 'Araçariguama',
          },
          {
            'id_cidade': 3502804,
            'nomeCidade': 'Araçatuba',
          },
          {
            'id_cidade': 3502903,
            'nomeCidade': 'Araçoiaba da Serra',
          },
          {
            'id_cidade': 3503000,
            'nomeCidade': 'Aramina',
          },
          {
            'id_cidade': 3503109,
            'nomeCidade': 'Arandu',
          },
          {
            'id_cidade': 3503158,
            'nomeCidade': 'Arapeí',
          },
          {
            'id_cidade': 3503208,
            'nomeCidade': 'Araraquara',
          },
          {
            'id_cidade': 3503307,
            'nomeCidade': 'Araras',
          },
          {
            'id_cidade': 3503356,
            'nomeCidade': 'Arco-Íris',
          },
          {
            'id_cidade': 3503406,
            'nomeCidade': 'Arealva',
          },
          {
            'id_cidade': 3503505,
            'nomeCidade': 'Areias',
          },
          {
            'id_cidade': 3503604,
            'nomeCidade': 'Areiópolis',
          },
          {
            'id_cidade': 3503703,
            'nomeCidade': 'Ariranha',
          },
          {
            'id_cidade': 3503802,
            'nomeCidade': 'Artur Nogueira',
          },
          {
            'id_cidade': 3503901,
            'nomeCidade': 'Arujá',
          },
          {
            'id_cidade': 3503950,
            'nomeCidade': 'Aspásia',
          },
          {
            'id_cidade': 3504008,
            'nomeCidade': 'Assis',
          },
          {
            'id_cidade': 3504107,
            'nomeCidade': 'Atibaia',
          },
          {
            'id_cidade': 3504206,
            'nomeCidade': 'Auriflama',
          },
          {
            'id_cidade': 3504305,
            'nomeCidade': 'Avaí',
          },
          {
            'id_cidade': 3504404,
            'nomeCidade': 'Avanhandava',
          },
          {
            'id_cidade': 3504503,
            'nomeCidade': 'Avaré',
          },
          {
            'id_cidade': 3504602,
            'nomeCidade': 'Bady Bassitt',
          },
          {
            'id_cidade': 3504701,
            'nomeCidade': 'Balbinos',
          },
          {
            'id_cidade': 3504800,
            'nomeCidade': 'Bálsamo',
          },
          {
            'id_cidade': 3504909,
            'nomeCidade': 'Bananal',
          },
          {
            'id_cidade': 3505005,
            'nomeCidade': 'Barão de Antonina',
          },
          {
            'id_cidade': 3505104,
            'nomeCidade': 'Barbosa',
          },
          {
            'id_cidade': 3505203,
            'nomeCidade': 'Bariri',
          },
          {
            'id_cidade': 3505302,
            'nomeCidade': 'Barra Bonita',
          },
          {
            'id_cidade': 3505351,
            'nomeCidade': 'Barra do Chapéu',
          },
          {
            'id_cidade': 3505401,
            'nomeCidade': 'Barra do Turvo',
          },
          {
            'id_cidade': 3505500,
            'nomeCidade': 'Barretos',
          },
          {
            'id_cidade': 3505609,
            'nomeCidade': 'Barrinha',
          },
          {
            'id_cidade': 3505708,
            'nomeCidade': 'Barueri',
          },
          {
            'id_cidade': 3505807,
            'nomeCidade': 'Bastos',
          },
          {
            'id_cidade': 3505906,
            'nomeCidade': 'Batatais',
          },
          {
            'id_cidade': 3506003,
            'nomeCidade': 'Bauru',
          },
          {
            'id_cidade': 3506102,
            'nomeCidade': 'Bebedouro',
          },
          {
            'id_cidade': 3506201,
            'nomeCidade': 'Bento de Abreu',
          },
          {
            'id_cidade': 3506300,
            'nomeCidade': 'Bernardino de Campos',
          },
          {
            'id_cidade': 3506359,
            'nomeCidade': 'Bertioga',
          },
          {
            'id_cidade': 3506409,
            'nomeCidade': 'Bilac',
          },
          {
            'id_cidade': 3506508,
            'nomeCidade': 'Birigui',
          },
          {
            'id_cidade': 3506607,
            'nomeCidade': 'Biritiba Mirim',
          },
          {
            'id_cidade': 3506706,
            'nomeCidade': 'Boa Esperança do Sul',
          },
          {
            'id_cidade': 3506805,
            'nomeCidade': 'Bocaina',
          },
          {
            'id_cidade': 3506904,
            'nomeCidade': 'Bofete',
          },
          {
            'id_cidade': 3507001,
            'nomeCidade': 'Boituva',
          },
          {
            'id_cidade': 3507100,
            'nomeCidade': 'Bom Jesus dos Perdões',
          },
          {
            'id_cidade': 3507159,
            'nomeCidade': 'Bom Sucesso de Itararé',
          },
          {
            'id_cidade': 3507209,
            'nomeCidade': 'Borá',
          },
          {
            'id_cidade': 3507308,
            'nomeCidade': 'Boracéia',
          },
          {
            'id_cidade': 3507407,
            'nomeCidade': 'Borborema',
          },
          {
            'id_cidade': 3507456,
            'nomeCidade': 'Borebi',
          },
          {
            'id_cidade': 3507506,
            'nomeCidade': 'Botucatu',
          },
          {
            'id_cidade': 3507605,
            'nomeCidade': 'Bragança Paulista',
          },
          {
            'id_cidade': 3507704,
            'nomeCidade': 'Braúna',
          },
          {
            'id_cidade': 3507753,
            'nomeCidade': 'Brejo Alegre',
          },
          {
            'id_cidade': 3507803,
            'nomeCidade': 'Brodowski',
          },
          {
            'id_cidade': 3507902,
            'nomeCidade': 'Brotas',
          },
          {
            'id_cidade': 3508009,
            'nomeCidade': 'Buri',
          },
          {
            'id_cidade': 3508108,
            'nomeCidade': 'Buritama',
          },
          {
            'id_cidade': 3508207,
            'nomeCidade': 'Buritizal',
          },
          {
            'id_cidade': 3508306,
            'nomeCidade': 'Cabrália Paulista',
          },
          {
            'id_cidade': 3508405,
            'nomeCidade': 'Cabreúva',
          },
          {
            'id_cidade': 3508504,
            'nomeCidade': 'Caçapava',
          },
          {
            'id_cidade': 3508603,
            'nomeCidade': 'Cachoeira Paulista',
          },
          {
            'id_cidade': 3508702,
            'nomeCidade': 'Caconde',
          },
          {
            'id_cidade': 3508801,
            'nomeCidade': 'Cafelândia',
          },
          {
            'id_cidade': 3508900,
            'nomeCidade': 'Caiabu',
          },
          {
            'id_cidade': 3509007,
            'nomeCidade': 'Caieiras',
          },
          {
            'id_cidade': 3509106,
            'nomeCidade': 'Caiuá',
          },
          {
            'id_cidade': 3509205,
            'nomeCidade': 'Cajamar',
          },
          {
            'id_cidade': 3509254,
            'nomeCidade': 'Cajati',
          },
          {
            'id_cidade': 3509304,
            'nomeCidade': 'Cajobi',
          },
          {
            'id_cidade': 3509403,
            'nomeCidade': 'Cajuru',
          },
          {
            'id_cidade': 3509452,
            'nomeCidade': 'Campina do Monte Alegre',
          },
          {
            'id_cidade': 3509502,
            'nomeCidade': 'Campinas',
          },
          {
            'id_cidade': 3509601,
            'nomeCidade': 'Campo Limpo Paulista',
          },
          {
            'id_cidade': 3509700,
            'nomeCidade': 'Campos do Jordão',
          },
          {
            'id_cidade': 3509809,
            'nomeCidade': 'Campos Novos Paulista',
          },
          {
            'id_cidade': 3509908,
            'nomeCidade': 'Cananéia',
          },
          {
            'id_cidade': 3509957,
            'nomeCidade': 'Canas',
          },
          {
            'id_cidade': 3510005,
            'nomeCidade': 'Cândido Mota',
          },
          {
            'id_cidade': 3510104,
            'nomeCidade': 'Cândido Rodrigues',
          },
          {
            'id_cidade': 3510153,
            'nomeCidade': 'Canitar',
          },
          {
            'id_cidade': 3510203,
            'nomeCidade': 'Capão Bonito',
          },
          {
            'id_cidade': 3510302,
            'nomeCidade': 'Capela do Alto',
          },
          {
            'id_cidade': 3510401,
            'nomeCidade': 'Capivari',
          },
          {
            'id_cidade': 3510500,
            'nomeCidade': 'Caraguatatuba',
          },
          {
            'id_cidade': 3510609,
            'nomeCidade': 'Carapicuíba',
          },
          {
            'id_cidade': 3510708,
            'nomeCidade': 'Cardoso',
          },
          {
            'id_cidade': 3510807,
            'nomeCidade': 'Casa Branca',
          },
          {
            'id_cidade': 3510906,
            'nomeCidade': 'Cássia dos Coqueiros',
          },
          {
            'id_cidade': 3511003,
            'nomeCidade': 'Castilho',
          },
          {
            'id_cidade': 3511102,
            'nomeCidade': 'Catanduva',
          },
          {
            'id_cidade': 3511201,
            'nomeCidade': 'Catiguá',
          },
          {
            'id_cidade': 3511300,
            'nomeCidade': 'Cedral',
          },
          {
            'id_cidade': 3511409,
            'nomeCidade': 'Cerqueira César',
          },
          {
            'id_cidade': 3511508,
            'nomeCidade': 'Cerquilho',
          },
          {
            'id_cidade': 3511607,
            'nomeCidade': 'Cesário Lange',
          },
          {
            'id_cidade': 3511706,
            'nomeCidade': 'Charqueada',
          },
          {
            'id_cidade': 3557204,
            'nomeCidade': 'Chavantes',
          },
          {
            'id_cidade': 3511904,
            'nomeCidade': 'Clementina',
          },
          {
            'id_cidade': 3512001,
            'nomeCidade': 'Colina',
          },
          {
            'id_cidade': 3512100,
            'nomeCidade': 'Colômbia',
          },
          {
            'id_cidade': 3512209,
            'nomeCidade': 'Conchal',
          },
          {
            'id_cidade': 3512308,
            'nomeCidade': 'Conchas',
          },
          {
            'id_cidade': 3512407,
            'nomeCidade': 'Cordeirópolis',
          },
          {
            'id_cidade': 3512506,
            'nomeCidade': 'Coroados',
          },
          {
            'id_cidade': 3512605,
            'nomeCidade': 'Coronel Macedo',
          },
          {
            'id_cidade': 3512704,
            'nomeCidade': 'Corumbataí',
          },
          {
            'id_cidade': 3512803,
            'nomeCidade': 'Cosmópolis',
          },
          {
            'id_cidade': 3512902,
            'nomeCidade': 'Cosmorama',
          },
          {
            'id_cidade': 3513009,
            'nomeCidade': 'Cotia',
          },
          {
            'id_cidade': 3513108,
            'nomeCidade': 'Cravinhos',
          },
          {
            'id_cidade': 3513207,
            'nomeCidade': 'Cristais Paulista',
          },
          {
            'id_cidade': 3513306,
            'nomeCidade': 'Cruzália',
          },
          {
            'id_cidade': 3513405,
            'nomeCidade': 'Cruzeiro',
          },
          {
            'id_cidade': 3513504,
            'nomeCidade': 'Cubatão',
          },
          {
            'id_cidade': 3513603,
            'nomeCidade': 'Cunha',
          },
          {
            'id_cidade': 3513702,
            'nomeCidade': 'Descalvado',
          },
          {
            'id_cidade': 3513801,
            'nomeCidade': 'Diadema',
          },
          {
            'id_cidade': 3513850,
            'nomeCidade': 'Dirce Reis',
          },
          {
            'id_cidade': 3513900,
            'nomeCidade': 'Divinolândia',
          },
          {
            'id_cidade': 3514007,
            'nomeCidade': 'Dobrada',
          },
          {
            'id_cidade': 3514106,
            'nomeCidade': 'Dois Córregos',
          },
          {
            'id_cidade': 3514205,
            'nomeCidade': 'Dolcinópolis',
          },
          {
            'id_cidade': 3514304,
            'nomeCidade': 'Dourado',
          },
          {
            'id_cidade': 3514403,
            'nomeCidade': 'Dracena',
          },
          {
            'id_cidade': 3514502,
            'nomeCidade': 'Duartina',
          },
          {
            'id_cidade': 3514601,
            'nomeCidade': 'Dumont',
          },
          {
            'id_cidade': 3514700,
            'nomeCidade': 'Echaporã',
          },
          {
            'id_cidade': 3514809,
            'nomeCidade': 'Eldorado',
          },
          {
            'id_cidade': 3514908,
            'nomeCidade': 'Elias Fausto',
          },
          {
            'id_cidade': 3514924,
            'nomeCidade': 'Elisiário',
          },
          {
            'id_cidade': 3514957,
            'nomeCidade': 'Embaúba',
          },
          {
            'id_cidade': 3515004,
            'nomeCidade': 'Embu das Artes',
          },
          {
            'id_cidade': 3515103,
            'nomeCidade': 'Embu-Guaçu',
          },
          {
            'id_cidade': 3515129,
            'nomeCidade': 'Emilianópolis',
          },
          {
            'id_cidade': 3515152,
            'nomeCidade': 'Engenheiro Coelho',
          },
          {
            'id_cidade': 3515186,
            'nomeCidade': 'Espírito Santo do Pinhal',
          },
          {
            'id_cidade': 3515194,
            'nomeCidade': 'Espírito Santo do Turvo',
          },
          {
            'id_cidade': 3557303,
            'nomeCidade': 'Estiva Gerbi',
          },
          {
            'id_cidade': 3515301,
            'nomeCidade': 'Estrela do Norte',
          },
          {
            'id_cidade': 3515202,
            'nomeCidade': "Estrela d'Oeste",
          },
          {
            'id_cidade': 3515350,
            'nomeCidade': 'Euclides da Cunha Paulista',
          },
          {
            'id_cidade': 3515400,
            'nomeCidade': 'Fartura',
          },
          {
            'id_cidade': 3515608,
            'nomeCidade': 'Fernando Prestes',
          },
          {
            'id_cidade': 3515509,
            'nomeCidade': 'Fernandópolis',
          },
          {
            'id_cidade': 3515657,
            'nomeCidade': 'Fernão',
          },
          {
            'id_cidade': 3515707,
            'nomeCidade': 'Ferraz de Vasconcelos',
          },
          {
            'id_cidade': 3515806,
            'nomeCidade': 'Flora Rica',
          },
          {
            'id_cidade': 3515905,
            'nomeCidade': 'Floreal',
          },
          {
            'id_cidade': 3516002,
            'nomeCidade': 'Flórida Paulista',
          },
          {
            'id_cidade': 3516101,
            'nomeCidade': 'Florínea',
          },
          {
            'id_cidade': 3516200,
            'nomeCidade': 'Franca',
          },
          {
            'id_cidade': 3516309,
            'nomeCidade': 'Francisco Morato',
          },
          {
            'id_cidade': 3516408,
            'nomeCidade': 'Franco da Rocha',
          },
          {
            'id_cidade': 3516507,
            'nomeCidade': 'Gabriel Monteiro',
          },
          {
            'id_cidade': 3516606,
            'nomeCidade': 'Gália',
          },
          {
            'id_cidade': 3516705,
            'nomeCidade': 'Garça',
          },
          {
            'id_cidade': 3516804,
            'nomeCidade': 'Gastão Vidigal',
          },
          {
            'id_cidade': 3516853,
            'nomeCidade': 'Gavião Peixoto',
          },
          {
            'id_cidade': 3516903,
            'nomeCidade': 'General Salgado',
          },
          {
            'id_cidade': 3517000,
            'nomeCidade': 'Getulina',
          },
          {
            'id_cidade': 3517109,
            'nomeCidade': 'Glicério',
          },
          {
            'id_cidade': 3517208,
            'nomeCidade': 'Guaiçara',
          },
          {
            'id_cidade': 3517307,
            'nomeCidade': 'Guaimbê',
          },
          {
            'id_cidade': 3517406,
            'nomeCidade': 'Guaíra',
          },
          {
            'id_cidade': 3517505,
            'nomeCidade': 'Guapiaçu',
          },
          {
            'id_cidade': 3517604,
            'nomeCidade': 'Guapiara',
          },
          {
            'id_cidade': 3517703,
            'nomeCidade': 'Guará',
          },
          {
            'id_cidade': 3517802,
            'nomeCidade': 'Guaraçaí',
          },
          {
            'id_cidade': 3517901,
            'nomeCidade': 'Guaraci',
          },
          {
            'id_cidade': 3518008,
            'nomeCidade': "Guarani d'Oeste",
          },
          {
            'id_cidade': 3518107,
            'nomeCidade': 'Guarantã',
          },
          {
            'id_cidade': 3518206,
            'nomeCidade': 'Guararapes',
          },
          {
            'id_cidade': 3518305,
            'nomeCidade': 'Guararema',
          },
          {
            'id_cidade': 3518404,
            'nomeCidade': 'Guaratinguetá',
          },
          {
            'id_cidade': 3518503,
            'nomeCidade': 'Guareí',
          },
          {
            'id_cidade': 3518602,
            'nomeCidade': 'Guariba',
          },
          {
            'id_cidade': 3518701,
            'nomeCidade': 'Guarujá',
          },
          {
            'id_cidade': 3518800,
            'nomeCidade': 'Guarulhos',
          },
          {
            'id_cidade': 3518859,
            'nomeCidade': 'Guatapará',
          },
          {
            'id_cidade': 3518909,
            'nomeCidade': 'Guzolândia',
          },
          {
            'id_cidade': 3519006,
            'nomeCidade': 'Herculândia',
          },
          {
            'id_cidade': 3519055,
            'nomeCidade': 'Holambra',
          },
          {
            'id_cidade': 3519071,
            'nomeCidade': 'Hortolândia',
          },
          {
            'id_cidade': 3519105,
            'nomeCidade': 'Iacanga',
          },
          {
            'id_cidade': 3519204,
            'nomeCidade': 'Iacri',
          },
          {
            'id_cidade': 3519253,
            'nomeCidade': 'Iaras',
          },
          {
            'id_cidade': 3519303,
            'nomeCidade': 'Ibaté',
          },
          {
            'id_cidade': 3519402,
            'nomeCidade': 'Ibirá',
          },
          {
            'id_cidade': 3519501,
            'nomeCidade': 'Ibirarema',
          },
          {
            'id_cidade': 3519600,
            'nomeCidade': 'Ibitinga',
          },
          {
            'id_cidade': 3519709,
            'nomeCidade': 'Ibiúna',
          },
          {
            'id_cidade': 3519808,
            'nomeCidade': 'Icém',
          },
          {
            'id_cidade': 3519907,
            'nomeCidade': 'Iepê',
          },
          {
            'id_cidade': 3520004,
            'nomeCidade': 'Igaraçu do Tietê',
          },
          {
            'id_cidade': 3520103,
            'nomeCidade': 'Igarapava',
          },
          {
            'id_cidade': 3520202,
            'nomeCidade': 'Igaratá',
          },
          {
            'id_cidade': 3520301,
            'nomeCidade': 'Iguape',
          },
          {
            'id_cidade': 3520426,
            'nomeCidade': 'Ilha Comprida',
          },
          {
            'id_cidade': 3520442,
            'nomeCidade': 'Ilha Solteira',
          },
          {
            'id_cidade': 3520400,
            'nomeCidade': 'Ilhabela',
          },
          {
            'id_cidade': 3520509,
            'nomeCidade': 'Indaiatuba',
          },
          {
            'id_cidade': 3520608,
            'nomeCidade': 'Indiana',
          },
          {
            'id_cidade': 3520707,
            'nomeCidade': 'Indiaporã',
          },
          {
            'id_cidade': 3520806,
            'nomeCidade': 'Inúbia Paulista',
          },
          {
            'id_cidade': 3520905,
            'nomeCidade': 'Ipaussu',
          },
          {
            'id_cidade': 3521002,
            'nomeCidade': 'Iperó',
          },
          {
            'id_cidade': 3521101,
            'nomeCidade': 'Ipeúna',
          },
          {
            'id_cidade': 3521150,
            'nomeCidade': 'Ipiguá',
          },
          {
            'id_cidade': 3521200,
            'nomeCidade': 'Iporanga',
          },
          {
            'id_cidade': 3521309,
            'nomeCidade': 'Ipuã',
          },
          {
            'id_cidade': 3521408,
            'nomeCidade': 'Iracemápolis',
          },
          {
            'id_cidade': 3521507,
            'nomeCidade': 'Irapuã',
          },
          {
            'id_cidade': 3521606,
            'nomeCidade': 'Irapuru',
          },
          {
            'id_cidade': 3521705,
            'nomeCidade': 'Itaberá',
          },
          {
            'id_cidade': 3521804,
            'nomeCidade': 'Itaí',
          },
          {
            'id_cidade': 3521903,
            'nomeCidade': 'Itajobi',
          },
          {
            'id_cidade': 3522000,
            'nomeCidade': 'Itaju',
          },
          {
            'id_cidade': 3522109,
            'nomeCidade': 'Itanhaém',
          },
          {
            'id_cidade': 3522158,
            'nomeCidade': 'Itaoca',
          },
          {
            'id_cidade': 3522208,
            'nomeCidade': 'Itapecerica da Serra',
          },
          {
            'id_cidade': 3522307,
            'nomeCidade': 'Itapetininga',
          },
          {
            'id_cidade': 3522406,
            'nomeCidade': 'Itapeva',
          },
          {
            'id_cidade': 3522505,
            'nomeCidade': 'Itapevi',
          },
          {
            'id_cidade': 3522604,
            'nomeCidade': 'Itapira',
          },
          {
            'id_cidade': 3522653,
            'nomeCidade': 'Itapirapuã Paulista',
          },
          {
            'id_cidade': 3522703,
            'nomeCidade': 'Itápolis',
          },
          {
            'id_cidade': 3522802,
            'nomeCidade': 'Itaporanga',
          },
          {
            'id_cidade': 3522901,
            'nomeCidade': 'Itapuí',
          },
          {
            'id_cidade': 3523008,
            'nomeCidade': 'Itapura',
          },
          {
            'id_cidade': 3523107,
            'nomeCidade': 'Itaquaquecetuba',
          },
          {
            'id_cidade': 3523206,
            'nomeCidade': 'Itararé',
          },
          {
            'id_cidade': 3523305,
            'nomeCidade': 'Itariri',
          },
          {
            'id_cidade': 3523404,
            'nomeCidade': 'Itatiba',
          },
          {
            'id_cidade': 3523503,
            'nomeCidade': 'Itatinga',
          },
          {
            'id_cidade': 3523602,
            'nomeCidade': 'Itirapina',
          },
          {
            'id_cidade': 3523701,
            'nomeCidade': 'Itirapuã',
          },
          {
            'id_cidade': 3523800,
            'nomeCidade': 'Itobi',
          },
          {
            'id_cidade': 3523909,
            'nomeCidade': 'Itu',
          },
          {
            'id_cidade': 3524006,
            'nomeCidade': 'Itupeva',
          },
          {
            'id_cidade': 3524105,
            'nomeCidade': 'Ituverava',
          },
          {
            'id_cidade': 3524204,
            'nomeCidade': 'Jaborandi',
          },
          {
            'id_cidade': 3524303,
            'nomeCidade': 'Jaboticabal',
          },
          {
            'id_cidade': 3524402,
            'nomeCidade': 'Jacareí',
          },
          {
            'id_cidade': 3524501,
            'nomeCidade': 'Jaci',
          },
          {
            'id_cidade': 3524600,
            'nomeCidade': 'Jacupiranga',
          },
          {
            'id_cidade': 3524709,
            'nomeCidade': 'Jaguariúna',
          },
          {
            'id_cidade': 3524808,
            'nomeCidade': 'Jales',
          },
          {
            'id_cidade': 3524907,
            'nomeCidade': 'Jambeiro',
          },
          {
            'id_cidade': 3525003,
            'nomeCidade': 'Jandira',
          },
          {
            'id_cidade': 3525102,
            'nomeCidade': 'Jardinópolis',
          },
          {
            'id_cidade': 3525201,
            'nomeCidade': 'Jarinu',
          },
          {
            'id_cidade': 3525300,
            'nomeCidade': 'Jaú',
          },
          {
            'id_cidade': 3525409,
            'nomeCidade': 'Jeriquara',
          },
          {
            'id_cidade': 3525508,
            'nomeCidade': 'Joanópolis',
          },
          {
            'id_cidade': 3525607,
            'nomeCidade': 'João Ramalho',
          },
          {
            'id_cidade': 3525706,
            'nomeCidade': 'José Bonifácio',
          },
          {
            'id_cidade': 3525805,
            'nomeCidade': 'Júlio Mesquita',
          },
          {
            'id_cidade': 3525854,
            'nomeCidade': 'Jumirim',
          },
          {
            'id_cidade': 3525904,
            'nomeCidade': 'Jundiaí',
          },
          {
            'id_cidade': 3526001,
            'nomeCidade': 'Junqueirópolis',
          },
          {
            'id_cidade': 3526100,
            'nomeCidade': 'Juquiá',
          },
          {
            'id_cidade': 3526209,
            'nomeCidade': 'Juquitiba',
          },
          {
            'id_cidade': 3526308,
            'nomeCidade': 'Lagoinha',
          },
          {
            'id_cidade': 3526407,
            'nomeCidade': 'Laranjal Paulista',
          },
          {
            'id_cidade': 3526506,
            'nomeCidade': 'Lavínia',
          },
          {
            'id_cidade': 3526605,
            'nomeCidade': 'Lavrinhas',
          },
          {
            'id_cidade': 3526704,
            'nomeCidade': 'Leme',
          },
          {
            'id_cidade': 3526803,
            'nomeCidade': 'Lençóis Paulista',
          },
          {
            'id_cidade': 3526902,
            'nomeCidade': 'Limeira',
          },
          {
            'id_cidade': 3527009,
            'nomeCidade': 'Lindóia',
          },
          {
            'id_cidade': 3527108,
            'nomeCidade': 'Lins',
          },
          {
            'id_cidade': 3527207,
            'nomeCidade': 'Lorena',
          },
          {
            'id_cidade': 3527256,
            'nomeCidade': 'Lourdes',
          },
          {
            'id_cidade': 3527306,
            'nomeCidade': 'Louveira',
          },
          {
            'id_cidade': 3527405,
            'nomeCidade': 'Lucélia',
          },
          {
            'id_cidade': 3527504,
            'nomeCidade': 'Lucianópolis',
          },
          {
            'id_cidade': 3527603,
            'nomeCidade': 'Luís Antônio',
          },
          {
            'id_cidade': 3527702,
            'nomeCidade': 'Luiziânia',
          },
          {
            'id_cidade': 3527801,
            'nomeCidade': 'Lupércio',
          },
          {
            'id_cidade': 3527900,
            'nomeCidade': 'Lutécia',
          },
          {
            'id_cidade': 3528007,
            'nomeCidade': 'Macatuba',
          },
          {
            'id_cidade': 3528106,
            'nomeCidade': 'Macaubal',
          },
          {
            'id_cidade': 3528205,
            'nomeCidade': 'Macedônia',
          },
          {
            'id_cidade': 3528304,
            'nomeCidade': 'Magda',
          },
          {
            'id_cidade': 3528403,
            'nomeCidade': 'Mairinque',
          },
          {
            'id_cidade': 3528502,
            'nomeCidade': 'Mairiporã',
          },
          {
            'id_cidade': 3528601,
            'nomeCidade': 'Manduri',
          },
          {
            'id_cidade': 3528700,
            'nomeCidade': 'Marabá Paulista',
          },
          {
            'id_cidade': 3528809,
            'nomeCidade': 'Maracaí',
          },
          {
            'id_cidade': 3528858,
            'nomeCidade': 'Marapoama',
          },
          {
            'id_cidade': 3528908,
            'nomeCidade': 'Mariápolis',
          },
          {
            'id_cidade': 3529005,
            'nomeCidade': 'Marília',
          },
          {
            'id_cidade': 3529104,
            'nomeCidade': 'Marinópolis',
          },
          {
            'id_cidade': 3529203,
            'nomeCidade': 'Martinópolis',
          },
          {
            'id_cidade': 3529302,
            'nomeCidade': 'Matão',
          },
          {
            'id_cidade': 3529401,
            'nomeCidade': 'Mauá',
          },
          {
            'id_cidade': 3529500,
            'nomeCidade': 'Mendonça',
          },
          {
            'id_cidade': 3529609,
            'nomeCidade': 'Meridiano',
          },
          {
            'id_cidade': 3529658,
            'nomeCidade': 'Mesópolis',
          },
          {
            'id_cidade': 3529708,
            'nomeCidade': 'Miguelópolis',
          },
          {
            'id_cidade': 3529807,
            'nomeCidade': 'Mineiros do Tietê',
          },
          {
            'id_cidade': 3530003,
            'nomeCidade': 'Mira Estrela',
          },
          {
            'id_cidade': 3529906,
            'nomeCidade': 'Miracatu',
          },
          {
            'id_cidade': 3530102,
            'nomeCidade': 'Mirandópolis',
          },
          {
            'id_cidade': 3530201,
            'nomeCidade': 'Mirante do Paranapanema',
          },
          {
            'id_cidade': 3530300,
            'nomeCidade': 'Mirassol',
          },
          {
            'id_cidade': 3530409,
            'nomeCidade': 'Mirassolândia',
          },
          {
            'id_cidade': 3530508,
            'nomeCidade': 'Mococa',
          },
          {
            'id_cidade': 3530607,
            'nomeCidade': 'Mogi das Cruzes',
          },
          {
            'id_cidade': 3530706,
            'nomeCidade': 'Mogi Guaçu',
          },
          {
            'id_cidade': 3530805,
            'nomeCidade': 'Mogi Mirim',
          },
          {
            'id_cidade': 3530904,
            'nomeCidade': 'Mombuca',
          },
          {
            'id_cidade': 3531001,
            'nomeCidade': 'Monções',
          },
          {
            'id_cidade': 3531100,
            'nomeCidade': 'Mongaguá',
          },
          {
            'id_cidade': 3531209,
            'nomeCidade': 'Monte Alegre do Sul',
          },
          {
            'id_cidade': 3531308,
            'nomeCidade': 'Monte Alto',
          },
          {
            'id_cidade': 3531407,
            'nomeCidade': 'Monte Aprazível',
          },
          {
            'id_cidade': 3531506,
            'nomeCidade': 'Monte Azul Paulista',
          },
          {
            'id_cidade': 3531605,
            'nomeCidade': 'Monte Castelo',
          },
          {
            'id_cidade': 3531803,
            'nomeCidade': 'Monte Mor',
          },
          {
            'id_cidade': 3531704,
            'nomeCidade': 'Monteiro Lobato',
          },
          {
            'id_cidade': 3531902,
            'nomeCidade': 'Morro Agudo',
          },
          {
            'id_cidade': 3532009,
            'nomeCidade': 'Morungaba',
          },
          {
            'id_cidade': 3532058,
            'nomeCidade': 'Motuca',
          },
          {
            'id_cidade': 3532108,
            'nomeCidade': 'Murutinga do Sul',
          },
          {
            'id_cidade': 3532157,
            'nomeCidade': 'Nantes',
          },
          {
            'id_cidade': 3532207,
            'nomeCidade': 'Narandiba',
          },
          {
            'id_cidade': 3532306,
            'nomeCidade': 'Natividade da Serra',
          },
          {
            'id_cidade': 3532405,
            'nomeCidade': 'Nazaré Paulista',
          },
          {
            'id_cidade': 3532504,
            'nomeCidade': 'Neves Paulista',
          },
          {
            'id_cidade': 3532603,
            'nomeCidade': 'Nhandeara',
          },
          {
            'id_cidade': 3532702,
            'nomeCidade': 'Nipoã',
          },
          {
            'id_cidade': 3532801,
            'nomeCidade': 'Nova Aliança',
          },
          {
            'id_cidade': 3532827,
            'nomeCidade': 'Nova Campina',
          },
          {
            'id_cidade': 3532843,
            'nomeCidade': 'Nova Canaã Paulista',
          },
          {
            'id_cidade': 3532868,
            'nomeCidade': 'Nova Castilho',
          },
          {
            'id_cidade': 3532900,
            'nomeCidade': 'Nova Europa',
          },
          {
            'id_cidade': 3533007,
            'nomeCidade': 'Nova Granada',
          },
          {
            'id_cidade': 3533106,
            'nomeCidade': 'Nova Guataporanga',
          },
          {
            'id_cidade': 3533205,
            'nomeCidade': 'Nova Independência',
          },
          {
            'id_cidade': 3533304,
            'nomeCidade': 'Nova Luzitânia',
          },
          {
            'id_cidade': 3533403,
            'nomeCidade': 'Nova Odessa',
          },
          {
            'id_cidade': 3533254,
            'nomeCidade': 'Novais',
          },
          {
            'id_cidade': 3533502,
            'nomeCidade': 'Novo Horizonte',
          },
          {
            'id_cidade': 3533601,
            'nomeCidade': 'Nuporanga',
          },
          {
            'id_cidade': 3533700,
            'nomeCidade': 'Ocauçu',
          },
          {
            'id_cidade': 3533809,
            'nomeCidade': 'Óleo',
          },
          {
            'id_cidade': 3533908,
            'nomeCidade': 'Olímpia',
          },
          {
            'id_cidade': 3534005,
            'nomeCidade': 'Onda Verde',
          },
          {
            'id_cidade': 3534104,
            'nomeCidade': 'Oriente',
          },
          {
            'id_cidade': 3534203,
            'nomeCidade': 'Orindiúva',
          },
          {
            'id_cidade': 3534302,
            'nomeCidade': 'Orlândia',
          },
          {
            'id_cidade': 3534401,
            'nomeCidade': 'Osasco',
          },
          {
            'id_cidade': 3534500,
            'nomeCidade': 'Oscar Bressane',
          },
          {
            'id_cidade': 3534609,
            'nomeCidade': 'Osvaldo Cruz',
          },
          {
            'id_cidade': 3534708,
            'nomeCidade': 'Ourinhos',
          },
          {
            'id_cidade': 3534807,
            'nomeCidade': 'Ouro Verde',
          },
          {
            'id_cidade': 3534757,
            'nomeCidade': 'Ouroeste',
          },
          {
            'id_cidade': 3534906,
            'nomeCidade': 'Pacaembu',
          },
          {
            'id_cidade': 3535002,
            'nomeCidade': 'Palestina',
          },
          {
            'id_cidade': 3535101,
            'nomeCidade': 'Palmares Paulista',
          },
          {
            'id_cidade': 3535200,
            'nomeCidade': "Palmeira d'Oeste",
          },
          {
            'id_cidade': 3535309,
            'nomeCidade': 'Palmital',
          },
          {
            'id_cidade': 3535408,
            'nomeCidade': 'Panorama',
          },
          {
            'id_cidade': 3535507,
            'nomeCidade': 'Paraguaçu Paulista',
          },
          {
            'id_cidade': 3535606,
            'nomeCidade': 'Paraibuna',
          },
          {
            'id_cidade': 3535705,
            'nomeCidade': 'Paraíso',
          },
          {
            'id_cidade': 3535804,
            'nomeCidade': 'Paranapanema',
          },
          {
            'id_cidade': 3535903,
            'nomeCidade': 'Paranapuã',
          },
          {
            'id_cidade': 3536000,
            'nomeCidade': 'Parapuã',
          },
          {
            'id_cidade': 3536109,
            'nomeCidade': 'Pardinho',
          },
          {
            'id_cidade': 3536208,
            'nomeCidade': 'Pariquera-Açu',
          },
          {
            'id_cidade': 3536257,
            'nomeCidade': 'Parisi',
          },
          {
            'id_cidade': 3536307,
            'nomeCidade': 'Patrocínio Paulista',
          },
          {
            'id_cidade': 3536406,
            'nomeCidade': 'Paulicéia',
          },
          {
            'id_cidade': 3536505,
            'nomeCidade': 'Paulínia',
          },
          {
            'id_cidade': 3536570,
            'nomeCidade': 'Paulistânia',
          },
          {
            'id_cidade': 3536604,
            'nomeCidade': 'Paulo de Faria',
          },
          {
            'id_cidade': 3536703,
            'nomeCidade': 'Pederneiras',
          },
          {
            'id_cidade': 3536802,
            'nomeCidade': 'Pedra Bela',
          },
          {
            'id_cidade': 3536901,
            'nomeCidade': 'Pedranópolis',
          },
          {
            'id_cidade': 3537008,
            'nomeCidade': 'Pedregulho',
          },
          {
            'id_cidade': 3537107,
            'nomeCidade': 'Pedreira',
          },
          {
            'id_cidade': 3537156,
            'nomeCidade': 'Pedrinhas Paulista',
          },
          {
            'id_cidade': 3537206,
            'nomeCidade': 'Pedro de Toledo',
          },
          {
            'id_cidade': 3537305,
            'nomeCidade': 'Penápolis',
          },
          {
            'id_cidade': 3537404,
            'nomeCidade': 'Pereira Barreto',
          },
          {
            'id_cidade': 3537503,
            'nomeCidade': 'Pereiras',
          },
          {
            'id_cidade': 3537602,
            'nomeCidade': 'Peruíbe',
          },
          {
            'id_cidade': 3537701,
            'nomeCidade': 'Piacatu',
          },
          {
            'id_cidade': 3537800,
            'nomeCidade': 'Piedade',
          },
          {
            'id_cidade': 3537909,
            'nomeCidade': 'Pilar do Sul',
          },
          {
            'id_cidade': 3538006,
            'nomeCidade': 'Pindamonhangaba',
          },
          {
            'id_cidade': 3538105,
            'nomeCidade': 'Pindorama',
          },
          {
            'id_cidade': 3538204,
            'nomeCidade': 'Pinhalzinho',
          },
          {
            'id_cidade': 3538303,
            'nomeCidade': 'Piquerobi',
          },
          {
            'id_cidade': 3538501,
            'nomeCidade': 'Piquete',
          },
          {
            'id_cidade': 3538600,
            'nomeCidade': 'Piracaia',
          },
          {
            'id_cidade': 3538709,
            'nomeCidade': 'Piracicaba',
          },
          {
            'id_cidade': 3538808,
            'nomeCidade': 'Piraju',
          },
          {
            'id_cidade': 3538907,
            'nomeCidade': 'Pirajuí',
          },
          {
            'id_cidade': 3539004,
            'nomeCidade': 'Pirangi',
          },
          {
            'id_cidade': 3539103,
            'nomeCidade': 'Pirapora do Bom Jesus',
          },
          {
            'id_cidade': 3539202,
            'nomeCidade': 'Pirapozinho',
          },
          {
            'id_cidade': 3539301,
            'nomeCidade': 'Pirassununga',
          },
          {
            'id_cidade': 3539400,
            'nomeCidade': 'Piratininga',
          },
          {
            'id_cidade': 3539509,
            'nomeCidade': 'Pitangueiras',
          },
          {
            'id_cidade': 3539608,
            'nomeCidade': 'Planalto',
          },
          {
            'id_cidade': 3539707,
            'nomeCidade': 'Platina',
          },
          {
            'id_cidade': 3539806,
            'nomeCidade': 'Poá',
          },
          {
            'id_cidade': 3539905,
            'nomeCidade': 'Poloni',
          },
          {
            'id_cidade': 3540002,
            'nomeCidade': 'Pompéia',
          },
          {
            'id_cidade': 3540101,
            'nomeCidade': 'Pongaí',
          },
          {
            'id_cidade': 3540200,
            'nomeCidade': 'Pontal',
          },
          {
            'id_cidade': 3540259,
            'nomeCidade': 'Pontalinda',
          },
          {
            'id_cidade': 3540309,
            'nomeCidade': 'Pontes Gestal',
          },
          {
            'id_cidade': 3540408,
            'nomeCidade': 'Populina',
          },
          {
            'id_cidade': 3540507,
            'nomeCidade': 'Porangaba',
          },
          {
            'id_cidade': 3540606,
            'nomeCidade': 'Porto Feliz',
          },
          {
            'id_cidade': 3540705,
            'nomeCidade': 'Porto Ferreira',
          },
          {
            'id_cidade': 3540754,
            'nomeCidade': 'Potim',
          },
          {
            'id_cidade': 3540804,
            'nomeCidade': 'Potirendaba',
          },
          {
            'id_cidade': 3540853,
            'nomeCidade': 'Pracinha',
          },
          {
            'id_cidade': 3540903,
            'nomeCidade': 'Pradópolis',
          },
          {
            'id_cidade': 3541000,
            'nomeCidade': 'Praia Grande',
          },
          {
            'id_cidade': 3541059,
            'nomeCidade': 'Pratânia',
          },
          {
            'id_cidade': 3541109,
            'nomeCidade': 'Presidente Alves',
          },
          {
            'id_cidade': 3541208,
            'nomeCidade': 'Presidente Bernardes',
          },
          {
            'id_cidade': 3541307,
            'nomeCidade': 'Presidente Epitácio',
          },
          {
            'id_cidade': 3541406,
            'nomeCidade': 'Presidente Prudente',
          },
          {
            'id_cidade': 3541505,
            'nomeCidade': 'Presidente Venceslau',
          },
          {
            'id_cidade': 3541604,
            'nomeCidade': 'Promissão',
          },
          {
            'id_cidade': 3541653,
            'nomeCidade': 'Quadra',
          },
          {
            'id_cidade': 3541703,
            'nomeCidade': 'Quatá',
          },
          {
            'id_cidade': 3541802,
            'nomeCidade': 'Queiroz',
          },
          {
            'id_cidade': 3541901,
            'nomeCidade': 'Queluz',
          },
          {
            'id_cidade': 3542008,
            'nomeCidade': 'Quintana',
          },
          {
            'id_cidade': 3542107,
            'nomeCidade': 'Rafard',
          },
          {
            'id_cidade': 3542206,
            'nomeCidade': 'Rancharia',
          },
          {
            'id_cidade': 3542305,
            'nomeCidade': 'Redenção da Serra',
          },
          {
            'id_cidade': 3542404,
            'nomeCidade': 'Regente Feijó',
          },
          {
            'id_cidade': 3542503,
            'nomeCidade': 'Reginópolis',
          },
          {
            'id_cidade': 3542602,
            'nomeCidade': 'Registro',
          },
          {
            'id_cidade': 3542701,
            'nomeCidade': 'Restinga',
          },
          {
            'id_cidade': 3542800,
            'nomeCidade': 'Ribeira',
          },
          {
            'id_cidade': 3542909,
            'nomeCidade': 'Ribeirão Bonito',
          },
          {
            'id_cidade': 3543006,
            'nomeCidade': 'Ribeirão Branco',
          },
          {
            'id_cidade': 3543105,
            'nomeCidade': 'Ribeirão Corrente',
          },
          {
            'id_cidade': 3543204,
            'nomeCidade': 'Ribeirão do Sul',
          },
          {
            'id_cidade': 3543238,
            'nomeCidade': 'Ribeirão dos Índios',
          },
          {
            'id_cidade': 3543253,
            'nomeCidade': 'Ribeirão Grande',
          },
          {
            'id_cidade': 3543303,
            'nomeCidade': 'Ribeirão Pires',
          },
          {
            'id_cidade': 3543402,
            'nomeCidade': 'Ribeirão Preto',
          },
          {
            'id_cidade': 3543600,
            'nomeCidade': 'Rifaina',
          },
          {
            'id_cidade': 3543709,
            'nomeCidade': 'Rincão',
          },
          {
            'id_cidade': 3543808,
            'nomeCidade': 'Rinópolis',
          },
          {
            'id_cidade': 3543907,
            'nomeCidade': 'Rio Claro',
          },
          {
            'id_cidade': 3544004,
            'nomeCidade': 'Rio das Pedras',
          },
          {
            'id_cidade': 3544103,
            'nomeCidade': 'Rio Grande da Serra',
          },
          {
            'id_cidade': 3544202,
            'nomeCidade': 'Riolândia',
          },
          {
            'id_cidade': 3543501,
            'nomeCidade': 'Riversul',
          },
          {
            'id_cidade': 3544251,
            'nomeCidade': 'Rosana',
          },
          {
            'id_cidade': 3544301,
            'nomeCidade': 'Roseira',
          },
          {
            'id_cidade': 3544400,
            'nomeCidade': 'Rubiácea',
          },
          {
            'id_cidade': 3544509,
            'nomeCidade': 'Rubinéia',
          },
          {
            'id_cidade': 3544608,
            'nomeCidade': 'Sabino',
          },
          {
            'id_cidade': 3544707,
            'nomeCidade': 'Sagres',
          },
          {
            'id_cidade': 3544806,
            'nomeCidade': 'Sales',
          },
          {
            'id_cidade': 3544905,
            'nomeCidade': 'Sales Oliveira',
          },
          {
            'id_cidade': 3545001,
            'nomeCidade': 'Salesópolis',
          },
          {
            'id_cidade': 3545100,
            'nomeCidade': 'Salmourão',
          },
          {
            'id_cidade': 3545159,
            'nomeCidade': 'Saltinho',
          },
          {
            'id_cidade': 3545209,
            'nomeCidade': 'Salto',
          },
          {
            'id_cidade': 3545308,
            'nomeCidade': 'Salto de Pirapora',
          },
          {
            'id_cidade': 3545407,
            'nomeCidade': 'Salto Grande',
          },
          {
            'id_cidade': 3545506,
            'nomeCidade': 'Sandovalina',
          },
          {
            'id_cidade': 3545605,
            'nomeCidade': 'Santa Adélia',
          },
          {
            'id_cidade': 3545704,
            'nomeCidade': 'Santa Albertina',
          },
          {
            'id_cidade': 3545803,
            'nomeCidade': "Santa Bárbara d'Oeste",
          },
          {
            'id_cidade': 3546009,
            'nomeCidade': 'Santa Branca',
          },
          {
            'id_cidade': 3546108,
            'nomeCidade': "Santa Clara d'Oeste",
          },
          {
            'id_cidade': 3546207,
            'nomeCidade': 'Santa Cruz da Conceição',
          },
          {
            'id_cidade': 3546256,
            'nomeCidade': 'Santa Cruz da Esperança',
          },
          {
            'id_cidade': 3546306,
            'nomeCidade': 'Santa Cruz das Palmeiras',
          },
          {
            'id_cidade': 3546405,
            'nomeCidade': 'Santa Cruz do Rio Pardo',
          },
          {
            'id_cidade': 3546504,
            'nomeCidade': 'Santa Ernestina',
          },
          {
            'id_cidade': 3546603,
            'nomeCidade': 'Santa Fé do Sul',
          },
          {
            'id_cidade': 3546702,
            'nomeCidade': 'Santa Gertrudes',
          },
          {
            'id_cidade': 3546801,
            'nomeCidade': 'Santa Isabel',
          },
          {
            'id_cidade': 3546900,
            'nomeCidade': 'Santa Lúcia',
          },
          {
            'id_cidade': 3547007,
            'nomeCidade': 'Santa Maria da Serra',
          },
          {
            'id_cidade': 3547106,
            'nomeCidade': 'Santa Mercedes',
          },
          {
            'id_cidade': 3547502,
            'nomeCidade': 'Santa Rita do Passa Quatro',
          },
          {
            'id_cidade': 3547403,
            'nomeCidade': "Santa Rita d'Oeste",
          },
          {
            'id_cidade': 3547601,
            'nomeCidade': 'Santa Rosa de Viterbo',
          },
          {
            'id_cidade': 3547650,
            'nomeCidade': 'Santa Salete',
          },
          {
            'id_cidade': 3547205,
            'nomeCidade': 'Santana da Ponte Pensa',
          },
          {
            'id_cidade': 3547304,
            'nomeCidade': 'Santana de Parnaíba',
          },
          {
            'id_cidade': 3547700,
            'nomeCidade': 'Santo Anastácio',
          },
          {
            'id_cidade': 3547809,
            'nomeCidade': 'Santo André',
          },
          {
            'id_cidade': 3547908,
            'nomeCidade': 'Santo Antônio da Alegria',
          },
          {
            'id_cidade': 3548005,
            'nomeCidade': 'Santo Antônio de Posse',
          },
          {
            'id_cidade': 3548054,
            'nomeCidade': 'Santo Antônio do Aracanguá',
          },
          {
            'id_cidade': 3548104,
            'nomeCidade': 'Santo Antônio do Jardim',
          },
          {
            'id_cidade': 3548203,
            'nomeCidade': 'Santo Antônio do Pinhal',
          },
          {
            'id_cidade': 3548302,
            'nomeCidade': 'Santo Expedito',
          },
          {
            'id_cidade': 3548401,
            'nomeCidade': 'Santópolis do Aguapeí',
          },
          {
            'id_cidade': 3548500,
            'nomeCidade': 'Santos',
          },
          {
            'id_cidade': 3548609,
            'nomeCidade': 'São Bento do Sapucaí',
          },
          {
            'id_cidade': 3548708,
            'nomeCidade': 'São Bernardo do Campo',
          },
          {
            'id_cidade': 3548807,
            'nomeCidade': 'São Caetano do Sul',
          },
          {
            'id_cidade': 3548906,
            'nomeCidade': 'São Carlos',
          },
          {
            'id_cidade': 3549003,
            'nomeCidade': 'São Francisco',
          },
          {
            'id_cidade': 3549102,
            'nomeCidade': 'São João da Boa Vista',
          },
          {
            'id_cidade': 3549201,
            'nomeCidade': 'São João das Duas Pontes',
          },
          {
            'id_cidade': 3549250,
            'nomeCidade': 'São João de Iracema',
          },
          {
            'id_cidade': 3549300,
            'nomeCidade': "São João do Pau d'Alho",
          },
          {
            'id_cidade': 3549409,
            'nomeCidade': 'São Joaquim da Barra',
          },
          {
            'id_cidade': 3549508,
            'nomeCidade': 'São José da Bela Vista',
          },
          {
            'id_cidade': 3549607,
            'nomeCidade': 'São José do Barreiro',
          },
          {
            'id_cidade': 3549706,
            'nomeCidade': 'São José do Rio Pardo',
          },
          {
            'id_cidade': 3549805,
            'nomeCidade': 'São José do Rio Preto',
          },
          {
            'id_cidade': 3549904,
            'nomeCidade': 'São José dos Campos',
          },
          {
            'id_cidade': 3549953,
            'nomeCidade': 'São Lourenço da Serra',
          },
          {
            'id_cidade': 3550001,
            'nomeCidade': 'São Luiz do Paraitinga',
          },
          {
            'id_cidade': 3550100,
            'nomeCidade': 'São Manuel',
          },
          {
            'id_cidade': 3550209,
            'nomeCidade': 'São Miguel Arcanjo',
          },
          {
            'id_cidade': 3550308,
            'nomeCidade': 'São Paulo',
          },
          {
            'id_cidade': 3550407,
            'nomeCidade': 'São Pedro',
          },
          {
            'id_cidade': 3550506,
            'nomeCidade': 'São Pedro do Turvo',
          },
          {
            'id_cidade': 3550605,
            'nomeCidade': 'São Roque',
          },
          {
            'id_cidade': 3550704,
            'nomeCidade': 'São Sebastião',
          },
          {
            'id_cidade': 3550803,
            'nomeCidade': 'São Sebastião da Grama',
          },
          {
            'id_cidade': 3550902,
            'nomeCidade': 'São Simão',
          },
          {
            'id_cidade': 3551009,
            'nomeCidade': 'São Vicente',
          },
          {
            'id_cidade': 3551108,
            'nomeCidade': 'Sarapuí',
          },
          {
            'id_cidade': 3551207,
            'nomeCidade': 'Sarutaiá',
          },
          {
            'id_cidade': 3551306,
            'nomeCidade': 'Sebastianópolis do Sul',
          },
          {
            'id_cidade': 3551405,
            'nomeCidade': 'Serra Azul',
          },
          {
            'id_cidade': 3551603,
            'nomeCidade': 'Serra Negra',
          },
          {
            'id_cidade': 3551504,
            'nomeCidade': 'Serrana',
          },
          {
            'id_cidade': 3551702,
            'nomeCidade': 'Sertãozinho',
          },
          {
            'id_cidade': 3551801,
            'nomeCidade': 'Sete Barras',
          },
          {
            'id_cidade': 3551900,
            'nomeCidade': 'Severínia',
          },
          {
            'id_cidade': 3552007,
            'nomeCidade': 'Silveiras',
          },
          {
            'id_cidade': 3552106,
            'nomeCidade': 'Socorro',
          },
          {
            'id_cidade': 3552205,
            'nomeCidade': 'Sorocaba',
          },
          {
            'id_cidade': 3552304,
            'nomeCidade': 'Sud Mennucci',
          },
          {
            'id_cidade': 3552403,
            'nomeCidade': 'Sumaré',
          },
          {
            'id_cidade': 3552551,
            'nomeCidade': 'Suzanápolis',
          },
          {
            'id_cidade': 3552502,
            'nomeCidade': 'Suzano',
          },
          {
            'id_cidade': 3552601,
            'nomeCidade': 'Tabapuã',
          },
          {
            'id_cidade': 3552700,
            'nomeCidade': 'Tabatinga',
          },
          {
            'id_cidade': 3552809,
            'nomeCidade': 'Taboão da Serra',
          },
          {
            'id_cidade': 3552908,
            'nomeCidade': 'Taciba',
          },
          {
            'id_cidade': 3553005,
            'nomeCidade': 'Taguaí',
          },
          {
            'id_cidade': 3553104,
            'nomeCidade': 'Taiaçu',
          },
          {
            'id_cidade': 3553203,
            'nomeCidade': 'Taiúva',
          },
          {
            'id_cidade': 3553302,
            'nomeCidade': 'Tambaú',
          },
          {
            'id_cidade': 3553401,
            'nomeCidade': 'Tanabi',
          },
          {
            'id_cidade': 3553500,
            'nomeCidade': 'Tapiraí',
          },
          {
            'id_cidade': 3553609,
            'nomeCidade': 'Tapiratiba',
          },
          {
            'id_cidade': 3553658,
            'nomeCidade': 'Taquaral',
          },
          {
            'id_cidade': 3553708,
            'nomeCidade': 'Taquaritinga',
          },
          {
            'id_cidade': 3553807,
            'nomeCidade': 'Taquarituba',
          },
          {
            'id_cidade': 3553856,
            'nomeCidade': 'Taquarivaí',
          },
          {
            'id_cidade': 3553906,
            'nomeCidade': 'Tarabai',
          },
          {
            'id_cidade': 3553955,
            'nomeCidade': 'Tarumã',
          },
          {
            'id_cidade': 3554003,
            'nomeCidade': 'Tatuí',
          },
          {
            'id_cidade': 3554102,
            'nomeCidade': 'Taubaté',
          },
          {
            'id_cidade': 3554201,
            'nomeCidade': 'Tejupá',
          },
          {
            'id_cidade': 3554300,
            'nomeCidade': 'Teodoro Sampaio',
          },
          {
            'id_cidade': 3554409,
            'nomeCidade': 'Terra Roxa',
          },
          {
            'id_cidade': 3554508,
            'nomeCidade': 'Tietê',
          },
          {
            'id_cidade': 3554607,
            'nomeCidade': 'Timburi',
          },
          {
            'id_cidade': 3554656,
            'nomeCidade': 'Torre de Pedra',
          },
          {
            'id_cidade': 3554706,
            'nomeCidade': 'Torrinha',
          },
          {
            'id_cidade': 3554755,
            'nomeCidade': 'Trabiju',
          },
          {
            'id_cidade': 3554805,
            'nomeCidade': 'Tremembé',
          },
          {
            'id_cidade': 3554904,
            'nomeCidade': 'Três Fronteiras',
          },
          {
            'id_cidade': 3554953,
            'nomeCidade': 'Tuiuti',
          },
          {
            'id_cidade': 3555000,
            'nomeCidade': 'Tupã',
          },
          {
            'id_cidade': 3555109,
            'nomeCidade': 'Tupi Paulista',
          },
          {
            'id_cidade': 3555208,
            'nomeCidade': 'Turiúba',
          },
          {
            'id_cidade': 3555307,
            'nomeCidade': 'Turmalina',
          },
          {
            'id_cidade': 3555356,
            'nomeCidade': 'Ubarana',
          },
          {
            'id_cidade': 3555406,
            'nomeCidade': 'Ubatuba',
          },
          {
            'id_cidade': 3555505,
            'nomeCidade': 'Ubirajara',
          },
          {
            'id_cidade': 3555604,
            'nomeCidade': 'Uchoa',
          },
          {
            'id_cidade': 3555703,
            'nomeCidade': 'União Paulista',
          },
          {
            'id_cidade': 3555802,
            'nomeCidade': 'Urânia',
          },
          {
            'id_cidade': 3555901,
            'nomeCidade': 'Uru',
          },
          {
            'id_cidade': 3556008,
            'nomeCidade': 'Urupês',
          },
          {
            'id_cidade': 3556107,
            'nomeCidade': 'Valentim Gentil',
          },
          {
            'id_cidade': 3556206,
            'nomeCidade': 'Valinhos',
          },
          {
            'id_cidade': 3556305,
            'nomeCidade': 'Valparaíso',
          },
          {
            'id_cidade': 3556354,
            'nomeCidade': 'Vargem',
          },
          {
            'id_cidade': 3556404,
            'nomeCidade': 'Vargem Grande do Sul',
          },
          {
            'id_cidade': 3556453,
            'nomeCidade': 'Vargem Grande Paulista',
          },
          {
            'id_cidade': 3556503,
            'nomeCidade': 'Várzea Paulista',
          },
          {
            'id_cidade': 3556602,
            'nomeCidade': 'Vera Cruz',
          },
          {
            'id_cidade': 3556701,
            'nomeCidade': 'Vinhedo',
          },
          {
            'id_cidade': 3556800,
            'nomeCidade': 'Viradouro',
          },
          {
            'id_cidade': 3556909,
            'nomeCidade': 'Vista Alegre do Alto',
          },
          {
            'id_cidade': 3556958,
            'nomeCidade': 'Vitória Brasil',
          },
          {
            'id_cidade': 3557006,
            'nomeCidade': 'Votorantim',
          },
          {
            'id_cidade': 3557105,
            'nomeCidade': 'Votuporanga',
          },
          {
            'id_cidade': 3557154,
            'nomeCidade': 'Zacarias',
          },
        ],
      },
      {
        'id': 28,
        'nomeEstado': 'Sergipe',
        'sigla': 'SE',
        'cidades': [
          {
            'id_cidade': 2800100,
            'nomeCidade': 'Amparo de São Francisco',
          },
          {
            'id_cidade': 2800209,
            'nomeCidade': 'Aquidabã',
          },
          {
            'id_cidade': 2800308,
            'nomeCidade': 'Aracaju',
          },
          {
            'id_cidade': 2800407,
            'nomeCidade': 'Arauá',
          },
          {
            'id_cidade': 2800506,
            'nomeCidade': 'Areia Branca',
          },
          {
            'id_cidade': 2800605,
            'nomeCidade': 'Barra dos Coqueiros',
          },
          {
            'id_cidade': 2800670,
            'nomeCidade': 'Boquim',
          },
          {
            'id_cidade': 2800704,
            'nomeCidade': 'Brejo Grande',
          },
          {
            'id_cidade': 2801009,
            'nomeCidade': 'Campo do Brito',
          },
          {
            'id_cidade': 2801108,
            'nomeCidade': 'Canhoba',
          },
          {
            'id_cidade': 2801207,
            'nomeCidade': 'Canindé de São Francisco',
          },
          {
            'id_cidade': 2801306,
            'nomeCidade': 'Capela',
          },
          {
            'id_cidade': 2801405,
            'nomeCidade': 'Carira',
          },
          {
            'id_cidade': 2801504,
            'nomeCidade': 'Carmópolis',
          },
          {
            'id_cidade': 2801603,
            'nomeCidade': 'Cedro de São João',
          },
          {
            'id_cidade': 2801702,
            'nomeCidade': 'Cristinápolis',
          },
          {
            'id_cidade': 2801900,
            'nomeCidade': 'Cumbe',
          },
          {
            'id_cidade': 2802007,
            'nomeCidade': 'Divina Pastora',
          },
          {
            'id_cidade': 2802106,
            'nomeCidade': 'Estância',
          },
          {
            'id_cidade': 2802205,
            'nomeCidade': 'Feira Nova',
          },
          {
            'id_cidade': 2802304,
            'nomeCidade': 'Frei Paulo',
          },
          {
            'id_cidade': 2802403,
            'nomeCidade': 'Gararu',
          },
          {
            'id_cidade': 2802502,
            'nomeCidade': 'General Maynard',
          },
          {
            'id_cidade': 2802601,
            'nomeCidade': 'Gracho Cardoso',
          },
          {
            'id_cidade': 2802700,
            'nomeCidade': 'Ilha das Flores',
          },
          {
            'id_cidade': 2802809,
            'nomeCidade': 'Indiaroba',
          },
          {
            'id_cidade': 2802908,
            'nomeCidade': 'Itabaiana',
          },
          {
            'id_cidade': 2803005,
            'nomeCidade': 'Itabaianinha',
          },
          {
            'id_cidade': 2803104,
            'nomeCidade': 'Itabi',
          },
          {
            'id_cidade': 2803203,
            'nomeCidade': "Itaporanga d'Ajuda",
          },
          {
            'id_cidade': 2803302,
            'nomeCidade': 'Japaratuba',
          },
          {
            'id_cidade': 2803401,
            'nomeCidade': 'Japoatã',
          },
          {
            'id_cidade': 2803500,
            'nomeCidade': 'Lagarto',
          },
          {
            'id_cidade': 2803609,
            'nomeCidade': 'Laranjeiras',
          },
          {
            'id_cidade': 2803708,
            'nomeCidade': 'Macambira',
          },
          {
            'id_cidade': 2803807,
            'nomeCidade': 'Malhada dos Bois',
          },
          {
            'id_cidade': 2803906,
            'nomeCidade': 'Malhador',
          },
          {
            'id_cidade': 2804003,
            'nomeCidade': 'Maruim',
          },
          {
            'id_cidade': 2804102,
            'nomeCidade': 'Moita Bonita',
          },
          {
            'id_cidade': 2804201,
            'nomeCidade': 'Monte Alegre de Sergipe',
          },
          {
            'id_cidade': 2804300,
            'nomeCidade': 'Muribeca',
          },
          {
            'id_cidade': 2804409,
            'nomeCidade': 'Neópolis',
          },
          {
            'id_cidade': 2804458,
            'nomeCidade': 'Nossa Senhora Aparecida',
          },
          {
            'id_cidade': 2804508,
            'nomeCidade': 'Nossa Senhora da Glória',
          },
          {
            'id_cidade': 2804607,
            'nomeCidade': 'Nossa Senhora das Dores',
          },
          {
            'id_cidade': 2804706,
            'nomeCidade': 'Nossa Senhora de Lourdes',
          },
          {
            'id_cidade': 2804805,
            'nomeCidade': 'Nossa Senhora do Socorro',
          },
          {
            'id_cidade': 2804904,
            'nomeCidade': 'Pacatuba',
          },
          {
            'id_cidade': 2805000,
            'nomeCidade': 'Pedra Mole',
          },
          {
            'id_cidade': 2805109,
            'nomeCidade': 'Pedrinhas',
          },
          {
            'id_cidade': 2805208,
            'nomeCidade': 'Pinhão',
          },
          {
            'id_cidade': 2805307,
            'nomeCidade': 'Pirambu',
          },
          {
            'id_cidade': 2805406,
            'nomeCidade': 'Poço Redondo',
          },
          {
            'id_cidade': 2805505,
            'nomeCidade': 'Poço Verde',
          },
          {
            'id_cidade': 2805604,
            'nomeCidade': 'Porto da Folha',
          },
          {
            'id_cidade': 2805703,
            'nomeCidade': 'Propriá',
          },
          {
            'id_cidade': 2805802,
            'nomeCidade': 'Riachão do Dantas',
          },
          {
            'id_cidade': 2805901,
            'nomeCidade': 'Riachuelo',
          },
          {
            'id_cidade': 2806008,
            'nomeCidade': 'Ribeirópolis',
          },
          {
            'id_cidade': 2806107,
            'nomeCidade': 'Rosário do Catete',
          },
          {
            'id_cidade': 2806206,
            'nomeCidade': 'Salgado',
          },
          {
            'id_cidade': 2806305,
            'nomeCidade': 'Santa Luzia do Itanhy',
          },
          {
            'id_cidade': 2806503,
            'nomeCidade': 'Santa Rosa de Lima',
          },
          {
            'id_cidade': 2806404,
            'nomeCidade': 'Santana do São Francisco',
          },
          {
            'id_cidade': 2806602,
            'nomeCidade': 'Santo Amaro das Brotas',
          },
          {
            'id_cidade': 2806701,
            'nomeCidade': 'São Cristóvão',
          },
          {
            'id_cidade': 2806800,
            'nomeCidade': 'São Domingos',
          },
          {
            'id_cidade': 2806909,
            'nomeCidade': 'São Francisco',
          },
          {
            'id_cidade': 2807006,
            'nomeCidade': 'São Miguel do Aleixo',
          },
          {
            'id_cidade': 2807105,
            'nomeCidade': 'Simão Dias',
          },
          {
            'id_cidade': 2807204,
            'nomeCidade': 'Siriri',
          },
          {
            'id_cidade': 2807303,
            'nomeCidade': 'Telha',
          },
          {
            'id_cidade': 2807402,
            'nomeCidade': 'Tobias Barreto',
          },
          {
            'id_cidade': 2807501,
            'nomeCidade': 'Tomar do Geru',
          },
          {
            'id_cidade': 2807600,
            'nomeCidade': 'Umbaúba',
          },
        ],
      },
      {
        'id': 17,
        'nomeEstado': 'Tocantins',
        'sigla': 'TO',
        'cidades': [
          {
            'id_cidade': 1700251,
            'nomeCidade': 'Abreulândia',
          },
          {
            'id_cidade': 1700301,
            'nomeCidade': 'Aguiarnópolis',
          },
          {
            'id_cidade': 1700350,
            'nomeCidade': 'Aliança do Tocantins',
          },
          {
            'id_cidade': 1700400,
            'nomeCidade': 'Almas',
          },
          {
            'id_cidade': 1700707,
            'nomeCidade': 'Alvorada',
          },
          {
            'id_cidade': 1701002,
            'nomeCidade': 'Ananás',
          },
          {
            'id_cidade': 1701051,
            'nomeCidade': 'Angico',
          },
          {
            'id_cidade': 1701101,
            'nomeCidade': 'Aparecida do Rio Negro',
          },
          {
            'id_cidade': 1701309,
            'nomeCidade': 'Aragominas',
          },
          {
            'id_cidade': 1701903,
            'nomeCidade': 'Araguacema',
          },
          {
            'id_cidade': 1702000,
            'nomeCidade': 'Araguaçu',
          },
          {
            'id_cidade': 1702109,
            'nomeCidade': 'Araguaína',
          },
          {
            'id_cidade': 1702158,
            'nomeCidade': 'Araguanã',
          },
          {
            'id_cidade': 1702208,
            'nomeCidade': 'Araguatins',
          },
          {
            'id_cidade': 1702307,
            'nomeCidade': 'Arapoema',
          },
          {
            'id_cidade': 1702406,
            'nomeCidade': 'Arraias',
          },
          {
            'id_cidade': 1702554,
            'nomeCidade': 'Augustinópolis',
          },
          {
            'id_cidade': 1702703,
            'nomeCidade': 'Aurora do Tocantins',
          },
          {
            'id_cidade': 1702901,
            'nomeCidade': 'Axixá do Tocantins',
          },
          {
            'id_cidade': 1703008,
            'nomeCidade': 'Babaçulândia',
          },
          {
            'id_cidade': 1703057,
            'nomeCidade': 'Bandeirantes do Tocantins',
          },
          {
            'id_cidade': 1703073,
            'nomeCidade': 'Barra do Ouro',
          },
          {
            'id_cidade': 1703107,
            'nomeCidade': 'Barrolândia',
          },
          {
            'id_cidade': 1703206,
            'nomeCidade': 'Bernardo Sayão',
          },
          {
            'id_cidade': 1703305,
            'nomeCidade': 'Bom Jesus do Tocantins',
          },
          {
            'id_cidade': 1703602,
            'nomeCidade': 'Brasilândia do Tocantins',
          },
          {
            'id_cidade': 1703701,
            'nomeCidade': 'Brejinho de Nazaré',
          },
          {
            'id_cidade': 1703800,
            'nomeCidade': 'Buriti do Tocantins',
          },
          {
            'id_cidade': 1703826,
            'nomeCidade': 'Cachoeirinha',
          },
          {
            'id_cidade': 1703842,
            'nomeCidade': 'Campos Lindos',
          },
          {
            'id_cidade': 1703867,
            'nomeCidade': 'Cariri do Tocantins',
          },
          {
            'id_cidade': 1703883,
            'nomeCidade': 'Carmolândia',
          },
          {
            'id_cidade': 1703891,
            'nomeCidade': 'Carrasco Bonito',
          },
          {
            'id_cidade': 1703909,
            'nomeCidade': 'Caseara',
          },
          {
            'id_cidade': 1704105,
            'nomeCidade': 'Centenário',
          },
          {
            'id_cidade': 1705102,
            'nomeCidade': 'Chapada da Natividade',
          },
          {
            'id_cidade': 1704600,
            'nomeCidade': 'Chapada de Areia',
          },
          {
            'id_cidade': 1705508,
            'nomeCidade': 'Colinas do Tocantins',
          },
          {
            'id_cidade': 1716703,
            'nomeCidade': 'Colméia',
          },
          {
            'id_cidade': 1705557,
            'nomeCidade': 'Combinado',
          },
          {
            'id_cidade': 1705607,
            'nomeCidade': 'Conceição do Tocantins',
          },
          {
            'id_cidade': 1706001,
            'nomeCidade': 'Couto Magalhães',
          },
          {
            'id_cidade': 1706100,
            'nomeCidade': 'Cristalândia',
          },
          {
            'id_cidade': 1706258,
            'nomeCidade': 'Crixás do Tocantins',
          },
          {
            'id_cidade': 1706506,
            'nomeCidade': 'Darcinópolis',
          },
          {
            'id_cidade': 1707009,
            'nomeCidade': 'Dianópolis',
          },
          {
            'id_cidade': 1707108,
            'nomeCidade': 'Divinópolis do Tocantins',
          },
          {
            'id_cidade': 1707207,
            'nomeCidade': 'Dois Irmãos do Tocantins',
          },
          {
            'id_cidade': 1707306,
            'nomeCidade': 'Dueré',
          },
          {
            'id_cidade': 1707405,
            'nomeCidade': 'Esperantina',
          },
          {
            'id_cidade': 1707553,
            'nomeCidade': 'Fátima',
          },
          {
            'id_cidade': 1707652,
            'nomeCidade': 'Figueirópolis',
          },
          {
            'id_cidade': 1707702,
            'nomeCidade': 'Filadélfia',
          },
          {
            'id_cidade': 1708205,
            'nomeCidade': 'Formoso do Araguaia',
          },
          {
            'id_cidade': 1708254,
            'nomeCidade': 'Fortaleza do Tabocão',
          },
          {
            'id_cidade': 1708304,
            'nomeCidade': 'Goianorte',
          },
          {
            'id_cidade': 1709005,
            'nomeCidade': 'Goiatins',
          },
          {
            'id_cidade': 1709302,
            'nomeCidade': 'Guaraí',
          },
          {
            'id_cidade': 1709500,
            'nomeCidade': 'Gurupi',
          },
          {
            'id_cidade': 1709807,
            'nomeCidade': 'Ipueiras',
          },
          {
            'id_cidade': 1710508,
            'nomeCidade': 'Itacajá',
          },
          {
            'id_cidade': 1710706,
            'nomeCidade': 'Itaguatins',
          },
          {
            'id_cidade': 1710904,
            'nomeCidade': 'Itapiratins',
          },
          {
            'id_cidade': 1711100,
            'nomeCidade': 'Itaporã do Tocantins',
          },
          {
            'id_cidade': 1711506,
            'nomeCidade': 'Jaú do Tocantins',
          },
          {
            'id_cidade': 1711803,
            'nomeCidade': 'Juarina',
          },
          {
            'id_cidade': 1711902,
            'nomeCidade': 'Lagoa da Confusão',
          },
          {
            'id_cidade': 1711951,
            'nomeCidade': 'Lagoa do Tocantins',
          },
          {
            'id_cidade': 1712009,
            'nomeCidade': 'Lajeado',
          },
          {
            'id_cidade': 1712157,
            'nomeCidade': 'Lavandeira',
          },
          {
            'id_cidade': 1712405,
            'nomeCidade': 'Lizarda',
          },
          {
            'id_cidade': 1712454,
            'nomeCidade': 'Luzinópolis',
          },
          {
            'id_cidade': 1712504,
            'nomeCidade': 'Marianópolis do Tocantins',
          },
          {
            'id_cidade': 1712702,
            'nomeCidade': 'Mateiros',
          },
          {
            'id_cidade': 1712801,
            'nomeCidade': 'Maurilândia do Tocantins',
          },
          {
            'id_cidade': 1713205,
            'nomeCidade': 'Miracema do Tocantins',
          },
          {
            'id_cidade': 1713304,
            'nomeCidade': 'Miranorte',
          },
          {
            'id_cidade': 1713601,
            'nomeCidade': 'Monte do Carmo',
          },
          {
            'id_cidade': 1713700,
            'nomeCidade': 'Monte Santo do Tocantins',
          },
          {
            'id_cidade': 1713957,
            'nomeCidade': 'Muricilândia',
          },
          {
            'id_cidade': 1714203,
            'nomeCidade': 'Natividade',
          },
          {
            'id_cidade': 1714302,
            'nomeCidade': 'Nazaré',
          },
          {
            'id_cidade': 1714880,
            'nomeCidade': 'Nova Olinda',
          },
          {
            'id_cidade': 1715002,
            'nomeCidade': 'Nova Rosalândia',
          },
          {
            'id_cidade': 1715101,
            'nomeCidade': 'Novo Acordo',
          },
          {
            'id_cidade': 1715150,
            'nomeCidade': 'Novo Alegre',
          },
          {
            'id_cidade': 1715259,
            'nomeCidade': 'Novo Jardim',
          },
          {
            'id_cidade': 1715507,
            'nomeCidade': 'Oliveira de Fátima',
          },
          {
            'id_cidade': 1721000,
            'nomeCidade': 'Palmas',
          },
          {
            'id_cidade': 1715705,
            'nomeCidade': 'Palmeirante',
          },
          {
            'id_cidade': 1713809,
            'nomeCidade': 'Palmeiras do Tocantins',
          },
          {
            'id_cidade': 1715754,
            'nomeCidade': 'Palmeirópolis',
          },
          {
            'id_cidade': 1716109,
            'nomeCidade': 'Paraíso do Tocantins',
          },
          {
            'id_cidade': 1716208,
            'nomeCidade': 'Paranã',
          },
          {
            'id_cidade': 1716307,
            'nomeCidade': "Pau D'Arco",
          },
          {
            'id_cidade': 1716505,
            'nomeCidade': 'Pedro Afonso',
          },
          {
            'id_cidade': 1716604,
            'nomeCidade': 'Peixe',
          },
          {
            'id_cidade': 1716653,
            'nomeCidade': 'Pequizeiro',
          },
          {
            'id_cidade': 1717008,
            'nomeCidade': 'Pindorama do Tocantins',
          },
          {
            'id_cidade': 1717206,
            'nomeCidade': 'Piraquê',
          },
          {
            'id_cidade': 1717503,
            'nomeCidade': 'Pium',
          },
          {
            'id_cidade': 1717800,
            'nomeCidade': 'Ponte Alta do Bom Jesus',
          },
          {
            'id_cidade': 1717909,
            'nomeCidade': 'Ponte Alta do Tocantins',
          },
          {
            'id_cidade': 1718006,
            'nomeCidade': 'Porto Alegre do Tocantins',
          },
          {
            'id_cidade': 1718204,
            'nomeCidade': 'Porto Nacional',
          },
          {
            'id_cidade': 1718303,
            'nomeCidade': 'Praia Norte',
          },
          {
            'id_cidade': 1718402,
            'nomeCidade': 'Presidente Kennedy',
          },
          {
            'id_cidade': 1718451,
            'nomeCidade': 'Pugmil',
          },
          {
            'id_cidade': 1718501,
            'nomeCidade': 'Recursolândia',
          },
          {
            'id_cidade': 1718550,
            'nomeCidade': 'Riachinho',
          },
          {
            'id_cidade': 1718659,
            'nomeCidade': 'Rio da Conceição',
          },
          {
            'id_cidade': 1718709,
            'nomeCidade': 'Rio dos Bois',
          },
          {
            'id_cidade': 1718758,
            'nomeCidade': 'Rio Sono',
          },
          {
            'id_cidade': 1718808,
            'nomeCidade': 'Sampaio',
          },
          {
            'id_cidade': 1718840,
            'nomeCidade': 'Sandolândia',
          },
          {
            'id_cidade': 1718865,
            'nomeCidade': 'Santa Fé do Araguaia',
          },
          {
            'id_cidade': 1718881,
            'nomeCidade': 'Santa Maria do Tocantins',
          },
          {
            'id_cidade': 1718899,
            'nomeCidade': 'Santa Rita do Tocantins',
          },
          {
            'id_cidade': 1718907,
            'nomeCidade': 'Santa Rosa do Tocantins',
          },
          {
            'id_cidade': 1719004,
            'nomeCidade': 'Santa Tereza do Tocantins',
          },
          {
            'id_cidade': 1720002,
            'nomeCidade': 'Santa Terezinha do Tocantins',
          },
          {
            'id_cidade': 1720101,
            'nomeCidade': 'São Bento do Tocantins',
          },
          {
            'id_cidade': 1720150,
            'nomeCidade': 'São Félix do Tocantins',
          },
          {
            'id_cidade': 1720200,
            'nomeCidade': 'São Miguel do Tocantins',
          },
          {
            'id_cidade': 1720259,
            'nomeCidade': 'São Salvador do Tocantins',
          },
          {
            'id_cidade': 1720309,
            'nomeCidade': 'São Sebastião do Tocantins',
          },
          {
            'id_cidade': 1720499,
            'nomeCidade': 'São Valério',
          },
          {
            'id_cidade': 1720655,
            'nomeCidade': 'Silvanópolis',
          },
          {
            'id_cidade': 1720804,
            'nomeCidade': 'Sítio Novo do Tocantins',
          },
          {
            'id_cidade': 1720853,
            'nomeCidade': 'Sucupira',
          },
          {
            'id_cidade': 1720903,
            'nomeCidade': 'Taguatinga',
          },
          {
            'id_cidade': 1720937,
            'nomeCidade': 'Taipas do Tocantins',
          },
          {
            'id_cidade': 1720978,
            'nomeCidade': 'Talismã',
          },
          {
            'id_cidade': 1721109,
            'nomeCidade': 'Tocantínia',
          },
          {
            'id_cidade': 1721208,
            'nomeCidade': 'Tocantinópolis',
          },
          {
            'id_cidade': 1721257,
            'nomeCidade': 'Tupirama',
          },
          {
            'id_cidade': 1721307,
            'nomeCidade': 'Tupiratins',
          },
          {
            'id_cidade': 1722081,
            'nomeCidade': 'Wanderlândia',
          },
          {
            'id_cidade': 1722107,
            'nomeCidade': 'Xambioá',
          },
        ],
      },
    ],
  },
];

export const OperatingUnit = [
  {
    'type': 'FeatureCollection',
    'features': [
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fff',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.452778, -25.643949],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 110,
          'unidade_operacional': 'ETE PONTAL E',
          'endereco': 'Av Itacolomi Ipanema',
          'latitude': '-25.643949',
          'longitude': '-48.452778',
          'unidade_consumidora': '77800699',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ffe',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.838714, -23.91887],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 152,
          'unidade_operacional': 'SAA EEB-1',
          'endereco': 'EST NATUREZA, Wenceslau Braz',
          'latitude': '-23.918870',
          'longitude': '-49.838714',
          'unidade_consumidora': '31497861',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ffd',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.658944, -24.630742],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 241,
          'unidade_operacional': 'POCO 05',
          'endereco': 'CAETANO MENDES, TIBAGI',
          'latitude': '-24.630742',
          'longitude': '-50.658944',
          'unidade_consumidora': '43908764',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ffc',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.152197, -25.441396],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 2,
          'unidade_operacional': 'SAA EET ETA IRAI',
          'endereco': 'Rodovia Dep. João Leopoldo Jacomel, 8949,\nPinhais/PR',
          'latitude': '-25.441396',
          'longitude': '-49.152197',
          'unidade_consumidora': '46171622',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ffb',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.181185, -25.419573],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 3,
          'unidade_operacional': 'SAA EET RAP JACOB\nMACANHAN',
          'endereco': 'Avenida Jacob Macanhan, Pinhais/PR',
          'latitude': '-25.419573',
          'longitude': '-49.181185',
          'unidade_consumidora': '46349030',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ffa',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.205701, -25.477134],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 4,
          'unidade_operacional': 'SAA EET ETA IGUAÇU',
          'endereco': 'BR 277, Curitiba/PR',
          'latitude': '-25.477134',
          'longitude': '-49.205701',
          'unidade_consumidora': '19450737',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ff9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.190882, -25.483219],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 5,
          'unidade_operacional': 'SAA CAPTAÇÃO IGUAÇU',
          'endereco': 'BR 277, Curitiba/PR',
          'latitude': '-25.483219',
          'longitude': '-49.190882',
          'unidade_consumidora': '19450753',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ff8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.352823, -25.514348],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 6,
          'unidade_operacional': 'SAA EET ETA PASSAUNA',
          'endereco': 'Rua dos Palmenses, Curitiba/PR',
          'latitude': '-25.514348',
          'longitude': '-49.352823',
          'unidade_consumidora': '22963677',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ff7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.368669, -25.512738],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 7,
          'unidade_operacional': 'SAA CAPTAÇÃO PASSAUNA',
          'endereco': 'Estrada Velha do Barigui, Curitiba/PR',
          'latitude': '-25.512738',
          'longitude': '-49.368669',
          'unidade_consumidora': '19451113_GD',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ff6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.235478, -25.469995],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 8,
          'unidade_operacional': 'SAA EET RAP CORTE BRANCO',
          'endereco':
            'Rua Cel. Francisco Heráclito dos Santos, 2505\n/Esq Rua Gal. Ary Duarte Nunes, Curitiba/PR',
          'latitude': '-25.469995',
          'longitude': '-49.235478',
          'unidade_consumidora': '19450745',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ff5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.497462, -23.495942],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 161,
          'unidade_operacional': 'SAA EEB-01 (CAPTAÇÃO)',
          'endereco': 'EST BARREIROS, Apucarana/PR',
          'latitude': '-23.495942',
          'longitude': '-51.497462',
          'unidade_consumidora': '19451512',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ff4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.690587, -25.806943],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 335,
          'unidade_operacional': 'SAA EEB1',
          'endereco': 'SAO JOAO - SANEPAR EEB1 - RURAL SÃO JOAO -PR',
          'latitude': '-25.806943',
          'longitude': '-52.690587',
          'unidade_consumidora': '47989050',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ff3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.494335, -25.869883],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 336,
          'unidade_operacional': 'CSP01 EEB01 ETA01',
          'endereco': 'ROD PR 281 - Chopinzinho',
          'latitude': '-25.869883',
          'longitude': '-52.494335',
          'unidade_consumidora': '92828760',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ff2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.546717, -25.8561],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 337,
          'unidade_operacional': 'EEB01',
          'endereco': 'Rua Mario Ceni - Chopinzinho',
          'latitude': '-25.856100',
          'longitude': '-52.546717',
          'unidade_consumidora': '34851151',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ff1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.36736, -26.423488],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 338,
          'unidade_operacional': 'CSB04 PASSOS',
          'endereco': 'R Prfa Anita Pacheco- Clevelândia',
          'latitude': '-26.423488',
          'longitude': '-52.367360',
          'unidade_consumidora': '36752118',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ff0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.345629, -26.399233],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 339,
          'unidade_operacional': 'EET04 EET05 CSB06 VILA\nOPERARIA',
          'endereco': 'R. Cap Pedro Bello- Clevelândia',
          'latitude': '-26.399233',
          'longitude': '-52.345629',
          'unidade_consumidora': '56544545',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fef',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.328633, -26.382505],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 340,
          'unidade_operacional': 'CSB07',
          'endereco': 'R Sete de Setembro- Clevelândia',
          'latitude': '-26.382505',
          'longitude': '-52.328633',
          'unidade_consumidora': '81777302',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fee',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.378224, -26.402753],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 341,
          'unidade_operacional': 'PE 07872 2021 CSB 08',
          'endereco': 'Rua Manoel Lustosa Martins EAPI - Clevelândia',
          'latitude': '-26.402753',
          'longitude': '-52.378224',
          'unidade_consumidora': '108565726',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fed',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.521979, -26.013797],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 342,
          'unidade_operacional': 'CIA SANEAMENTO PR -EEB02',
          'endereco': 'Loc São Pedro, s/nº Coronel Vivida',
          'latitude': '-26.013797',
          'longitude': '-52.521979',
          'unidade_consumidora': '42390001',
          'concessionaria': 'Forcel',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fec',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.558462, -25.98343],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 343,
          'unidade_operacional': 'CIA SANEAMENTO PR -ESCRI',
          'endereco': 'Rua Luiz Rufatto, 301 Coronel Vivida',
          'latitude': '-25.983430',
          'longitude': '-52.558462',
          'unidade_consumidora': '30226007',
          'concessionaria': 'Forcel',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7feb',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.579622, -26.003534],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 344,
          'unidade_operacional': 'Sanepar ETE',
          'endereco': 'Rua Ari Zamarchi Coronel Vivida',
          'latitude': '-26.003534',
          'longitude': '-52.579622',
          'unidade_consumidora': '90978501',
          'concessionaria': 'Forcel',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fea',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.867068, -25.97173],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 345,
          'unidade_operacional': 'EEB03 LAGEADO BONITO',
          'endereco': 'Lin Lageado Bonito, EEB3 - Itapejara do Oeste',
          'latitude': '-25.971730',
          'longitude': '-52.867068',
          'unidade_consumidora': '91075700',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fe9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.905937, -25.696416],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 346,
          'unidade_operacional': 'CSB03 BARBARA EET02',
          'endereco': 'Lin Sta Barbara - São Jorge do Oeste',
          'latitude': '-25.696416',
          'longitude': '-52.905937',
          'unidade_consumidora': '62391453',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fe8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.667436, -26.228472],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 347,
          'unidade_operacional': 'EET02 EET05 ITAPUA',
          'endereco': 'R Itapuã, 703 Pato Branco',
          'latitude': '-26.228472',
          'longitude': '-52.667436',
          'unidade_consumidora': '68385447',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fe7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.67827, -26.227219],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 348,
          'unidade_operacional': 'ETA',
          'endereco': 'R Itabira, 745',
          'latitude': '-26.227219',
          'longitude': '-52.678270',
          'unidade_consumidora': '68447450',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fe6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.677316, -26.226726],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 349,
          'unidade_operacional': 'PE 00500 2019 SANEPAR RSE\n04 EET 1A',
          'endereco': 'R PARANA, 1060',
          'latitude': '-26.226726',
          'longitude': '-52.677316',
          'unidade_consumidora': '105277002',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fe5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.729136, -24.731019],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 350,
          'unidade_operacional': 'SAA EEB 1 CAPTACAO',
          'endereco':
            'R QUINZE DE NOVEMBRO - SANEPAR EEB 1\nCAPTACAO CENTRO - TOLEDO - PR',
          'latitude': '-24.731019',
          'longitude': '-53.729136',
          'unidade_consumidora': '35322101',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fe4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.494962, -24.417082],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 351,
          'unidade_operacional': 'SAA ETA EET1 CSB1 REN1',
          'endereco':
            'RM SANEPAR - SANEPAR ETA EET1 CSB1 REN1\nFRIGORIFICO - ASSIS CHATEAUBRIAND - PR',
          'latitude': '-24.417082',
          'longitude': '-53.494962',
          'unidade_consumidora': '36222852',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fe3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.559839, -24.401847],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 352,
          'unidade_operacional': 'SAA EEB 02',
          'endereco':
            'RM H - SANEPAR EEB 02 ASFUCA - ASSIS\nCHATEAUBRIAND - PR',
          'latitude': '-24.401847',
          'longitude': '-53.559839',
          'unidade_consumidora': '92912290',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fe2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.719203, -24.672508],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 353,
          'unidade_operacional': 'SAA CSB15 EAB2',
          'endereco':
            'LIN AEROPORTO - SANEPAR CSB15 EAB2 RURAL DE\nBOA VISTA - TOLEDO - PR',
          'latitude': '-24.672508',
          'longitude': '-53.719203',
          'unidade_consumidora': '44476256',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fe1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.682992, -24.660242],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 354,
          'unidade_operacional': 'SAA CBS 18',
          'endereco': 'LIN BUE CAE - CBS 18 RURAL DE BOA VISTA -TOLEDO - PR',
          'latitude': '-24.660242',
          'longitude': '-53.682992',
          'unidade_consumidora': '93950993',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fe0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.757992, -24.754693],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 355,
          'unidade_operacional': 'SES ETE Rio Toledo',
          'endereco':
            'LIN CERAMICA PRATA, 0 - PE 03193 2017 ETE RIO\nTOLEDO - RURAL DE TOLEDO - PR',
          'latitude': '-24.754693',
          'longitude': '-53.757992',
          'unidade_consumidora': '103706992',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fdf',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.552701, -24.400183],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 356,
          'unidade_operacional': 'EET-05 - Assis\nChateaubriand',
          'endereco':
            'Rodovia Assis Chateaubriand a encantado, s/nº,\nzona rural',
          'latitude': '-24.400183',
          'longitude': '-53.552701',
          'unidade_consumidora': '90453450',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fde',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.233631, -24.107733],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 357,
          'unidade_operacional': 'CSB-14 - Guaíra',
          'endereco': 'Ramal água do bugre, s/nº, zona rural, Guaíra/PR',
          'latitude': '-24.107733',
          'longitude': '-54.233631',
          'unidade_consumidora': '84983981',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fdd',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.235172, -24.093357],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 358,
          'unidade_operacional': 'EET-02 - Anhembi - Guaíra',
          'endereco': 'Rua Luiz Gonzaga, s/nº, Anhembi Colagui,\nGuaíra/PR',
          'latitude': '-24.093357',
          'longitude': '-54.235172',
          'unidade_consumidora': '103785558',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fdc',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.331527, -24.308384],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 359,
          'unidade_operacional': 'EEB-01 - Formosa do Oeste',
          'endereco':
            'Rod PR 317 Pref. Antonio Fregulia, zona rural,\nformosa do Oeste/PR',
          'latitude': '-24.308384',
          'longitude': '-53.331527',
          'unidade_consumidora': '48843075',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fdb',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.808761, -24.300924],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 360,
          'unidade_operacional': 'CSB-08 - Palotina',
          'endereco': 'Linha Pioneiro, zona rural, Palotina/PR',
          'latitude': '-24.300924',
          'longitude': '-53.808761',
          'unidade_consumidora': '79730701',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fda',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.028664, -24.864321],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 361,
          'unidade_operacional': 'CSB-01 - Luz Marina',
          'endereco':
            'Estrada Gleba Nova, zona rural, Distrito de Luz\nMarina, São Pedro do Iguaçu/PR',
          'latitude': '-24.864321',
          'longitude': '-54.028664',
          'unidade_consumidora': '78962188',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fd9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.25585, -24.076686],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 362,
          'unidade_operacional': 'EEE-01 - Elevatória Prainha\n- Guaíra',
          'endereco': 'Av Presidente Getulio Vargas s/nº, Centro,\nGuaíra/PR',
          'latitude': '-24.076686',
          'longitude': '-54.255850',
          'unidade_consumidora': '39545377',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fd8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.822241, -24.272754],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 363,
          'unidade_operacional': 'EEE-01 - Elevatória esgoto\nPalotina',
          'endereco': 'Rua Rio Grande do Sul s/nº, Imigrantes,\nPalotina/PR',
          'latitude': '-24.272754',
          'longitude': '-53.822241',
          'unidade_consumidora': '92334865',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fd7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.241482, -25.428441],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 9,
          'unidade_operacional': 'SAA EET REN CAJURU',
          'endereco':
            'Rua Marechal Humberto de Alencar Castelo\nBranco, 30, Curitiba / PR',
          'latitude': '-25.428441',
          'longitude': '-49.241482',
          'unidade_consumidora': '66244013',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fd6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.222967, -25.429311],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 10,
          'unidade_operacional': 'SAA EET RAP TARUMÃ',
          'endereco': 'Avenida Victor Ferreira do Amaral, 1760,\nCuritiba/PR',
          'latitude': '-25.429311',
          'longitude': '-49.222967',
          'unidade_consumidora': '19450303',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fd5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.246258, -25.399441],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 11,
          'unidade_operacional': 'SAA EET RSE BACACHERI',
          'endereco': 'Avenida Paraná, 1561, Curitiba/PR',
          'latitude': '-25.399441',
          'longitude': '-49.246258',
          'unidade_consumidora': '44818815',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fd4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.236286, -25.370107],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 12,
          'unidade_operacional': 'SAA EET RAP STA CANDIDA\nNOVO',
          'endereco':
            'Rua Professor Rodolfo Belz, 1109 / Esquina\nRua Vicente Spisla, Curitiba/PR',
          'latitude': '-25.370107',
          'longitude': '-49.236286',
          'unidade_consumidora': '43750583',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fd3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.269021, -25.362783],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 13,
          'unidade_operacional': 'SAA BOO BARREIRINHA',
          'endereco':
            'Rua Capitão Ismael Roxo (próximo ponte de\nmadeira), Curitiba/PR',
          'latitude': '-25.362783',
          'longitude': '-49.269021',
          'unidade_consumidora': '62095340',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fd2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.279295, -25.424721],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 14,
          'unidade_operacional': 'SAA EET RSE SÃO FRANCISCO',
          'endereco':
            'Praça Odilon Mader (Esquina com Manoel\nRibas / Rua dos Presbíteros), Curitiba/PR',
          'latitude': '-25.424721',
          'longitude': '-49.279295',
          'unidade_consumidora': '19450133',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fd1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.3474, -25.451791],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 15,
          'unidade_operacional': 'SAA EET RSE CAMPO\nCOMPRIDO',
          'endereco': 'Rua Angelo Rossa, 343, Curitiba/PR',
          'latitude': '-25.451791',
          'longitude': '-49.347400',
          'unidade_consumidora': '28426193',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fd0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.285324, -25.476147],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 16,
          'unidade_operacional': 'SAA EET RAP PORTÃO',
          'endereco':
            'Avenida da República, 7357 / Esquina Rua\nPedro Nogas, Curitiba/PR',
          'latitude': '-25.476147',
          'longitude': '-49.285324',
          'unidade_consumidora': '19450672',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fcf',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.333474, -25.400116],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 17,
          'unidade_operacional': 'SAA EET RSE SANTA FELICIDADE',
          'endereco': 'Rua Francisco Manfron, 82, Curitiba/PR',
          'latitude': '-25.400116',
          'longitude': '-49.333474',
          'unidade_consumidora': '33625930',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fce',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.294407, -25.544032],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 18,
          'unidade_operacional': 'SAA EET RSE TATUQUARA',
          'endereco': 'Rua Nicola Pellanda, 1730, Curitiba/PR',
          'latitude': '-25.544032',
          'longitude': '-49.294407',
          'unidade_consumidora': '62515438',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fcd',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.254812, -25.510221],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 19,
          'unidade_operacional': 'SAA EET RAP XAXIM',
          'endereco':
            'Rua Waldemar Loureiro Campos s/n, Ld. 3400,\nCuritiba/PR',
          'latitude': '-25.510221',
          'longitude': '-49.254812',
          'unidade_consumidora': '62191780',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fcc',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.309045, -25.556197],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 20,
          'unidade_operacional': 'SAA EET RSE CEASA',
          'endereco': 'Rua Francisco Sobania, 1050, Curitiba/PR',
          'latitude': '-25.556197',
          'longitude': '-49.309045',
          'unidade_consumidora': '26139111',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fcb',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.338755, -25.417492],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 21,
          'unidade_operacional': 'SAA EET RSE SÃO BRAZ',
          'endereco':
            'Rua José Tomasi, 755 / Esquina Rua João Balla,\nCuritiba/PR',
          'latitude': '-25.417492',
          'longitude': '-49.338755',
          'unidade_consumidora': '45483779',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fca',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.299796, -25.530302],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 22,
          'unidade_operacional': 'SAA EET RAP PINHEIRINHO',
          'endereco':
            'Rua Valdeci dos Santos (esquina com a Via\nMarginal da Linha Verde), Curitiba/PR',
          'latitude': '-25.530302',
          'longitude': '-49.299796',
          'unidade_consumidora': '31564496',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fc9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.397301, -25.599034],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 23,
          'unidade_operacional': 'SAA EET ETA INDUST\nARAUCARIA',
          'endereco': 'Rua Rio Grande do Sul, Araucária/PR',
          'latitude': '-25.599034',
          'longitude': '-49.397301',
          'unidade_consumidora': '46021191',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fc8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.168131, -25.585372],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 24,
          'unidade_operacional': 'SAA EET ETA MIRINGUAVA',
          'endereco':
            'Rua Antonio Moleta Filho, esquina Rua\nEugênio Moro, São José dos Pinhais/PR',
          'latitude': '-25.585372',
          'longitude': '-49.168131',
          'unidade_consumidora': '76514587',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fc7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.150079, -25.596844],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 25,
          'unidade_operacional': 'SAA CAPTAÇÃO MIRINGUAVA',
          'endereco': 'Rua Rosária da Rocha Rabêlo, São José dos\nPinhais/PR',
          'latitude': '-25.596844',
          'longitude': '-49.150079',
          'unidade_consumidora': '76288714',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fc6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.11298, -25.536243],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 26,
          'unidade_operacional': 'SAA ETA RIO PEQUENO',
          'endereco':
            'Rua Hermenegildo Soares Machado (próximo\nà fabrica da Renault), São José dos Pinhais/PR',
          'latitude': '-25.536243',
          'longitude': '-49.112980',
          'unidade_consumidora': '38109280',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fc5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.122915, -25.555021],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 27,
          'unidade_operacional': 'SAA CAPTAÇÃO RIO PEQUENO',
          'endereco':
            'Estrada Colônia Acioli (Rua João Beger), São\nJosé dos Pinhais/PR',
          'latitude': '-25.555021',
          'longitude': '-49.122915',
          'unidade_consumidora': '41381823',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fc4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.111896, -25.538392],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 28,
          'unidade_operacional': 'SAA EET RAP RIO PEQUENO',
          'endereco':
            'Rua Hermenegildo Soares Machado (próximo\nà fabrica da Renault), São José dos Pinhais/PR',
          'latitude': '-25.538392',
          'longitude': '-49.111896',
          'unidade_consumidora': '41379136',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fc3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.184296, -25.516952],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 29,
          'unidade_operacional': 'SAA CR AEROPORTO',
          'endereco': 'Rua Dabid Campista, 330, São José dos Pinhais',
          'latitude': '-25.516952',
          'longitude': '-49.184296',
          'unidade_consumidora': '77217896_GD',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fc2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.185034, -25.375845],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 30,
          'unidade_operacional': 'SAA EET RAP VILA GUARANI',
          'endereco': 'Travessa Lamenha Lins, 39, Colombo/PR',
          'latitude': '-25.375845',
          'longitude': '-49.185034',
          'unidade_consumidora': '55454500',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fc1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.152242, -25.354496],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 31,
          'unidade_operacional': 'SAA BOO GUARAITUBA',
          'endereco':
            'Avenida Nossa Senhora de Fátima, esquina\nRua Ângelo Tognato, Colombo/PR',
          'latitude': '-25.354496',
          'longitude': '-49.152242',
          'unidade_consumidora': '55456812',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fc0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.29099, -25.404709],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 32,
          'unidade_operacional': 'CR MERCES',
          'endereco': 'Rua João Tscharnell, 857 - Curitiba PR',
          'latitude': '-25.404709',
          'longitude': '-49.290990',
          'unidade_consumidora': '64104770',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fbf',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.295931, -25.436237],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 33,
          'unidade_operacional': 'CR BATEL',
          'endereco': 'Rua Edgard Roquete Pinto, 55 - Curitiba/PR',
          'latitude': '-25.436237',
          'longitude': '-49.295931',
          'unidade_consumidora': '38718502',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fbe',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.264724, -25.462616],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 34,
          'unidade_operacional': 'CR PAROLIN',
          'endereco': 'Rua Lamenha Lins, 3116 - Curitiba/PR',
          'latitude': '-25.462616',
          'longitude': '-49.264724',
          'unidade_consumidora': '63604116',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fbd',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.208598, -25.533874],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 35,
          'unidade_operacional': 'CR SJ PINHAIS CENTRO',
          'endereco': 'Rua Passos Oliveira, 951 - São José dos\nPinhais/PR',
          'latitude': '-25.533874',
          'longitude': '-49.208598',
          'unidade_consumidora': '40490688',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fbc',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.140079, -25.469472],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 36,
          'unidade_operacional': 'CR GUARITUBA REDONDO',
          'endereco': 'Rua Hartwig Muller - Piraquara/PR',
          'latitude': '-25.469472',
          'longitude': '-49.140079',
          'unidade_consumidora': '1205102',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fbb',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.209989, -25.4092],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 37,
          'unidade_operacional': 'CR BAIRRO ALTO',
          'endereco': 'Avenida da Integração, 1060 - Curitiba/PR',
          'latitude': '-25.409200',
          'longitude': '-49.209989',
          'unidade_consumidora': '66198666',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fba',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.155339, -25.432161],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 38,
          'unidade_operacional': 'CR VILA AMELIA',
          'endereco': 'Rua Romeu Pires, 802 - Pinhais/PR',
          'latitude': '-25.432161',
          'longitude': '-49.155339',
          'unidade_consumidora': '53226429',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fb9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.066459, -25.459485],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 39,
          'unidade_operacional': 'CR PIRAQUARA',
          'endereco': 'Rua Canela ft 65 - Piraquara / PR',
          'latitude': '-25.459485',
          'longitude': '-49.066459',
          'unidade_consumidora': '47472006',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fb8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.345894, -25.39641],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 40,
          'unidade_operacional': 'CR BUTIATUVINHA',
          'endereco': 'Rua João Santo Miola - Curitiba/PR',
          'latitude': '-25.396410',
          'longitude': '-49.345894',
          'unidade_consumidora': '106868250',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fb7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.32801, -25.366477],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 41,
          'unidade_operacional': 'CR LAMENHA PEQUENA',
          'endereco': 'Rua João Antonio Zen - Curitiba/PR',
          'latitude': '-25.366477',
          'longitude': '-49.328010',
          'unidade_consumidora': '106851217',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fb6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.385906, -25.568861],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 42,
          'unidade_operacional': 'CR ARAUCARIA SABIA',
          'endereco': 'Rua Tenente Benedito Nepomuceno, 101 -Araucária/PR',
          'latitude': '-25.568861',
          'longitude': '-49.385906',
          'unidade_consumidora': '32140215',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fb5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.401911, -25.592016],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 43,
          'unidade_operacional': 'CR ARAUCARIA CENTRO',
          'endereco': 'Rua Miguel Antonio Pizato, 186 - Araucária/PR',
          'latitude': '-25.592016',
          'longitude': '-49.401911',
          'unidade_consumidora': '22935940',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fb4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.321149, -25.602984],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 44,
          'unidade_operacional': 'CR CAMPO SANTANA',
          'endereco': 'Rua Jorge Tortato, 271\nCuritiba/PR',
          'latitude': '-25.602984',
          'longitude': '-49.321149',
          'unidade_consumidora': '74755897',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fb3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.367788, -25.60734],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 45,
          'unidade_operacional': 'CR COSTEIRA',
          'endereco': 'Rua Presidente Costa e Silva - Araucária/PR',
          'latitude': '-25.607340',
          'longitude': '-49.367788',
          'unidade_consumidora': '74880462',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fb2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.317229, -25.647395],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 46,
          'unidade_operacional': 'CR FAZENDA RIO GRANDE',
          'endereco':
            'Rua Rio Eufrates, esquina Rua Rio Volga, 178 -Fazenda Rio Grande',
          'latitude': '-25.647395',
          'longitude': '-49.317229',
          'unidade_consumidora': '75768879',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fb1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.295252, -25.310645],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 47,
          'unidade_operacional': 'SAA EET CENTRAL ALM\nTAMANDARE',
          'endereco': 'Rua Athaide de Siqueira, Almirante\nTamandaré',
          'latitude': '-25.310645',
          'longitude': '-49.295252',
          'unidade_consumidora': '41490533',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fb0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.166296, -25.335525],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 48,
          'unidade_operacional': 'SAA EET ETA RIO PALMITAL',
          'endereco': 'Rua da Mangueira, Colombo/PR',
          'latitude': '-25.335525',
          'longitude': '-49.166296',
          'unidade_consumidora': '44001681',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7faf',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.15263, -25.262038],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 49,
          'unidade_operacional': 'SAA EET1 EET2 ETA CAPIVARI',
          'endereco': 'Rua Virgilio Arcie, 215, Colombo/PR',
          'latitude': '-25.262038',
          'longitude': '-49.152630',
          'unidade_consumidora': '40429458',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fae',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.141034, -25.26711],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 50,
          'unidade_operacional': 'SAA CAPT CAPIVARI',
          'endereco': 'Est da Ribeira BR 476, pe 06563 2021,\nColombo/PR',
          'latitude': '-25.267110',
          'longitude': '-49.141034',
          'unidade_consumidora': '107674149',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fad',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.215842, -25.283379],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 51,
          'unidade_operacional': 'SAA EET REN KARST',
          'endereco': 'Estrada Bacaetava (Rua Antonio Gasparin),\nColombo/PR',
          'latitude': '-25.283379',
          'longitude': '-49.215842',
          'unidade_consumidora': '45115745',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fac',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.722624, -25.751189],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 52,
          'unidade_operacional': 'SAA EET1 LAPA',
          'endereco': 'Colônia Boqueirão, Lapa/PR',
          'latitude': '-25.751189',
          'longitude': '-49.722624',
          'unidade_consumidora': '75789981',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fab',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.726658, -25.713747],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 53,
          'unidade_operacional': 'SAA CSB03 LAPA',
          'endereco': 'Colônia Lara, Lapa/PR',
          'latitude': '-25.713747',
          'longitude': '-49.726658',
          'unidade_consumidora': '92763219',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7faa',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.713064, -25.794262],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 54,
          'unidade_operacional': 'SAA EEB1 LAPA',
          'endereco': 'Colônia Piripau, Lapa/PR',
          'latitude': '-25.794262',
          'longitude': '-49.713064',
          'unidade_consumidora': '70427054',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fa9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.301517, -25.183651],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 55,
          'unidade_operacional': 'SAA POÇO PE 597 13',
          'endereco': 'Rua Canário, Rio Branco do Sul/PR',
          'latitude': '-25.183651',
          'longitude': '-49.301517',
          'unidade_consumidora': '97353590',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fa8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.281199, -25.651036],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 56,
          'unidade_operacional': 'SAA ETA DESPIQUE',
          'endereco':
            'Estrada vicinal (Próximo ao final da\nAvenida Venezuela), Fazenda Rio\nGrande/PR',
          'latitude': '-25.651036',
          'longitude': '-49.281199',
          'unidade_consumidora': '40207285',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fa7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.258832, -25.646902],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 57,
          'unidade_operacional': 'SAA CAPTAÇÃO RIO DESPIQUE',
          'endereco':
            'Avenida Francisco Ferreida da Cruz (Rua\nReinaldo Baldan), Fazenda Rio Grande/PR',
          'latitude': '-25.646902',
          'longitude': '-49.258832',
          'unidade_consumidora': '40207650',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fa6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.491174, -25.498637],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 58,
          'unidade_operacional': 'SAA CAPTAÇÃO RIO VERDE',
          'endereco': 'Estrada Rio Verde, Campo Largo/PR',
          'latitude': '-25.498637',
          'longitude': '-49.491174',
          'unidade_consumidora': '16128060',
          'concessionaria': 'COCEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fa5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.490798, -25.494744],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 59,
          'unidade_operacional': 'SAA ETA RIO VERDE',
          'endereco': 'Estrada Rio Verde, Campo Largo/PR',
          'latitude': '-25.494744',
          'longitude': '-49.490798',
          'unidade_consumidora': '16121490',
          'concessionaria': 'COCEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fa4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.790408, -26.102515],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 60,
          'unidade_operacional': 'SAA CSP1 EEB1 B2022',
          'endereco': 'Rua Buleslau Paluch, Rio Negro/PR',
          'latitude': '-26.102515',
          'longitude': '-49.790408',
          'unidade_consumidora': '213747928',
          'concessionaria': 'CELESC',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fa3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.56331802, -25.40613445],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 61,
          'unidade_operacional': 'EEB1 ELEV.',
          'endereco': 'R. FELPUDO, S/N, CAMPO LARGO',
          'latitude': '-25.40613445031278',
          'longitude': '-49.563318015392454',
          'unidade_consumidora': '15981401',
          'concessionaria': 'COCEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fa2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.55623245, -25.39513978],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 62,
          'unidade_operacional': 'CSB9',
          'endereco': 'R. FELPUDO, S/N, CAMPO LARGO',
          'latitude': '-25.395139781318942',
          'longitude': '-49.55623245235532',
          'unidade_consumidora': '15981444',
          'concessionaria': 'COCEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fa1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.795089, -26.101747],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 63,
          'unidade_operacional': 'ETAEET',
          'endereco': 'E CEL JOAQIM SABOIA, RIO NEGRO',
          'latitude': '-26.101747',
          'longitude': '-49.795089',
          'unidade_consumidora': '269874151',
          'concessionaria': 'CELESC',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7fa0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.664767, -25.699699],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 64,
          'unidade_operacional': 'POCO 03 USPD MARIENTAL',
          'endereco': 'COL BONSUCESSO, LAPA',
          'latitude': '-25.699699',
          'longitude': '-49.664767',
          'unidade_consumidora': '104499664',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f9f',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.72623, -25.727182],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 65,
          'unidade_operacional': 'POCO 02 ACS PX LINHA FERREA',
          'endereco': 'COL LARA, 243, LAPA',
          'latitude': '-25.727182',
          'longitude': '-49.726230',
          'unidade_consumidora': '81859945',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f9e',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.208305, -25.655615],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 66,
          'unidade_operacional': 'CSP 01 CAP RIO COTIA',
          'endereco': 'EST CAMPO LARGO DA ROSEIRA, SAO JOSE\nDOS PINHAIS',
          'latitude': '-25.655615',
          'longitude': '-49.208305',
          'unidade_consumidora': '39261565',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f9d',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.292705, -25.266575],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 67,
          'unidade_operacional': 'PE 16 99 CSB 12 TRANQUEIRA',
          'endereco': 'R VER GUSTAVO JOPPERT, 0, ALMIRANTE\nTAMANDARE',
          'latitude': '-25.266575',
          'longitude': '-49.292705',
          'unidade_consumidora': '41422775',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f9c',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.285155, -25.259771],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 68,
          'unidade_operacional': 'PE 21 04 CSB 18 TRANQUEIRA',
          'endereco': 'EST VENDA VELHA, ALMIRANTE\nTAMANDARE',
          'latitude': '-25.259771',
          'longitude': '-49.285155',
          'unidade_consumidora': '53330668',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f9b',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.30423, -25.26408],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 69,
          'unidade_operacional': 'CSB 01 CSB 05 TRANQUEIRA',
          'endereco': 'R MARIA COSTA GABARDO, ALMIRANTE\nTAMANDARE',
          'latitude': '-25.26408',
          'longitude': '-49.30423',
          'unidade_consumidora': '74865145',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f9a',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.325268, -25.33392],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 70,
          'unidade_operacional': 'POCO 04 BOTIATUBA',
          'endereco': 'R PEDRO JORGE KOTOWISKI, 0,\nALMIRANTE TAMANDARE',
          'latitude': '-25.333920',
          'longitude': '-49.325268',
          'unidade_consumidora': '99753154',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f99',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.149051, -25.256231],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 71,
          'unidade_operacional': 'CSB 04 SAO DIMAS',
          'endereco': 'EST LAGO, BOCAIUVA DO SUL',
          'latitude': '-25.256231',
          'longitude': '-49.149051',
          'unidade_consumidora': '43626955',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f98',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.21642, -25.28882],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 72,
          'unidade_operacional': 'CSB 16 KARST SEDE',
          'endereco': 'R COM ORLANDO CECCON, 595, COLOMBO',
          'latitude': '-25.28882',
          'longitude': '-49.21642',
          'unidade_consumidora': '24017353',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f97',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.197157, -25.269979],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 73,
          'unidade_operacional': 'CSB 01 KARST SEDE',
          'endereco': 'R ANTENOR LAZARINE, COLOMBO',
          'latitude': '-25.269979',
          'longitude': '-49.197157',
          'unidade_consumidora': '33670420',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f96',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.193919, -25.281977],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 74,
          'unidade_operacional': 'CSB 15 KARST FERVIDA',
          'endereco': 'R SAO JOAO, 688, COLOMBO',
          'latitude': '-25.281977',
          'longitude': '-49.193919',
          'unidade_consumidora': '35809329',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f95',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.193333, -25.267866],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 75,
          'unidade_operacional': 'POCO 10 FERVIDA',
          'endereco': 'R GUERINO FIORESE, COLOMBO',
          'latitude': '-25.267866',
          'longitude': '-49.193333',
          'unidade_consumidora': '35825448',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f94',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.188696, -25.275837],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 76,
          'unidade_operacional': 'CSB 01 KARST FERVIDA',
          'endereco': 'R SAO JOAO, COLOMBO',
          'latitude': '-25.275837',
          'longitude': '-49.188696',
          'unidade_consumidora': '48004936',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f93',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.31914, -25.75729],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 77,
          'unidade_operacional': 'EET01 CSB01 ETA B JESUS',
          'endereco': 'ROD BR 116, MANDIRITUBA',
          'latitude': '-25.75729',
          'longitude': '-49.31914',
          'unidade_consumidora': '44634374',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f92',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.346021, -25.210891],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 78,
          'unidade_operacional': 'POCO 03',
          'endereco':
            'R OTAVIO STOCHERO, ITAPERUCU JD GUARANY, KM 104 POC03, CAMPO',
          'latitude': '-25.210891',
          'longitude': '-49.346021',
          'unidade_consumidora': '58072004',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f91',
        'geometry': null,
        'geometry_name': 'geog',
        'properties': {
          'id': 79,
          'unidade_operacional': 'CSB3 EET3 SIC3',
          'endereco': 'LARGO',
          'latitude': '49.40439413087251',
          'longitude': null,
          'unidade_consumidora': '15397009',
          'concessionaria': 'COCEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f90',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.445702, -25.676521],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 80,
          'unidade_operacional': 'CSB 01 SIC 01 LAGOA GRANDE',
          'endereco': 'R MIGUEL SIROTA, ARAUCARIA',
          'latitude': '-25.676521',
          'longitude': '-49.445702',
          'unidade_consumidora': '37267051',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f8f',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.448446, -25.439873],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 81,
          'unidade_operacional': 'EET 17',
          'endereco': 'RUA JOAO SOARES S/N, CAMPO LARGO',
          'latitude': '-25.439873',
          'longitude': '-49.448446',
          'unidade_consumidora': '16109422_BT',
          'concessionaria': 'COCEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f8e',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.73098356, -26.05016654],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 82,
          'unidade_operacional': 'CSB1S1CEET1 B5698',
          'endereco': 'R B ROSEIRA, RIO NEGRO',
          'latitude': '-26.050166544002202',
          'longitude': '-49.730983555141975',
          'unidade_consumidora': '120262428_BT',
          'concessionaria': 'CELESC',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f8d',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.21482, -25.515866],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 83,
          'unidade_operacional': 'SES ETE BELÉM',
          'endereco': 'Rua Pr. Antônio Pólito, Curitiba/PR',
          'latitude': '-25.515866',
          'longitude': '-49.214820',
          'unidade_consumidora': '72702141',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f8c',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.247069, -25.564649],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 84,
          'unidade_operacional': 'SES ETE PADILHA SUL',
          'endereco': 'R Pedrina Accordes Costa, Curitiba/PR',
          'latitude': '-25.564649',
          'longitude': '-49.247069',
          'unidade_consumidora': '77411609',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f8b',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.187997, -25.472191],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 85,
          'unidade_operacional': 'SES ETE ATUBA SUL',
          'endereco': 'Rua Miguel Pedro Abib, Curitiba/PR',
          'latitude': '-25.472191',
          'longitude': '-49.187997',
          'unidade_consumidora': '37998447',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f8a',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.320247, -25.46376],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 86,
          'unidade_operacional': 'SES ETE SANTA\nQUITÉRIA',
          'endereco': 'Rua Dr. Celso Luiz Peixoto Ribas,\nCuritiba/PR',
          'latitude': '-25.463760',
          'longitude': '-49.320247',
          'unidade_consumidora': '81217846',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f89',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.341399, -25.575978],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 87,
          'unidade_operacional': 'SES ETE CIC XISTO',
          'endereco': 'Rua Paulina Kavinski Pontarolla,\nCuritiba/PR',
          'latitude': '-25.575978',
          'longitude': '-49.341399',
          'unidade_consumidora': '77405340',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f88',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.094608, -25.370264],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 88,
          'unidade_operacional': 'SES EEE MENINO DEUS',
          'endereco': 'Rua Doze de Outubro, Quatro Barras/PR',
          'latitude': '-25.370264',
          'longitude': '-49.094608',
          'unidade_consumidora': '39208869',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f87',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.334923, -25.643365],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 89,
          'unidade_operacional': 'SES ETE FAZENDA RIO\nGRANDE',
          'endereco': 'Rua Rio Iraí, Fazenda Rio Grande/PR',
          'latitude': '-25.643365',
          'longitude': '-49.334923',
          'unidade_consumidora': '71798595',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f86',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.173704, -25.460156],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 90,
          'unidade_operacional': 'SES EEB PIRAQUARA',
          'endereco': 'Rua Governador Valadares, s/n,\nPiraquara/PR',
          'latitude': '-25.460156',
          'longitude': '-49.173704',
          'unidade_consumidora': '76636291',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f85',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.38386226, -25.45702727],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 92,
          'unidade_operacional': 'EEE Dona Fina',
          'endereco': 'Rua Mato Grosso s/n, Ferraria, Campo\nLargo-PR.',
          'latitude': '-25.457027269600704',
          'longitude': '-49.38386225970482',
          'unidade_consumidora': '16003500',
          'concessionaria': 'COCEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f84',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.72225662, -25.7510419],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 93,
          'unidade_operacional': 'ETE Lapa',
          'endereco': 'Col. Boqueirão, Centro, Lapa-PR.',
          'latitude': '-25.751041899917244',
          'longitude': '-49.72225661737168',
          'unidade_consumidora': '75789981',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f83',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.11202384, -25.39663185],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 94,
          'unidade_operacional': 'EEE Adalto Botelho\n(Manicomio Judiciário)',
          'endereco':
            'Rua Ivone Pimentel, n°5, Parque das\nNascentes, Pinhais - PR.',
          'latitude': '-25.39663184878662',
          'longitude': '-49.11202383643102',
          'unidade_consumidora': '46677615',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f82',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.12035999, -25.37811555],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 95,
          'unidade_operacional': 'EEE Canguiri',
          'endereco': 'Alphaville Graciosa, Pinhais-PR',
          'latitude': '-25.37811554672733',
          'longitude': '-49.12035998854193',
          'unidade_consumidora': '53747755',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f81',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.19243651, -25.39063902],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 96,
          'unidade_operacional': 'EEE Maracanã',
          'endereco': 'Rua Bastista Ramos, Atuba, Piraquara-PR.',
          'latitude': '-25.390639022231078',
          'longitude': '-49.19243650618899',
          'unidade_consumidora': '96125225',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f80',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.18149536, -25.46516204],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 97,
          'unidade_operacional': 'EEE Weissópolis',
          'endereco': 'Rua Rio Madeira, Weissópolis, Piraquara-PR.',
          'latitude': '-25.465162037054018',
          'longitude': '-49.18149535828965',
          'unidade_consumidora': '36648329',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f7f',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.05751326, -25.37576967],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 98,
          'unidade_operacional': 'EEE Itapira',
          'endereco': 'Rua aleluias, Quatro Barras, Quatro Barras-PR.',
          'latitude': '-25.375769672905324',
          'longitude': '-49.05751325633808',
          'unidade_consumidora': '46364013',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f7e',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.5824003, -25.8952826],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 99,
          'unidade_operacional': 'SES ETE ESPERANÇA',
          'endereco': 'R Wenceslau Braz',
          'latitude': '-25.8952826',
          'longitude': '-48.5824003',
          'unidade_consumidora': '81500513',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f7d',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.7030997, -25.9601392],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 100,
          'unidade_operacional': 'SAA EET1 MORRO GRANDE A',
          'endereco': 'Vl Morro Grande',
          'latitude': '-25.9601392',
          'longitude': '-48.7030997',
          'unidade_consumidora': '30345359',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f7c',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.5330407, -25.6885441],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 101,
          'unidade_operacional': 'SAA EEB6 A10 CAP POMBAS A',
          'endereco': 'Pr-407 Engº Argus Tha Hein',
          'latitude': '-25.6885441',
          'longitude': '-48.5330407',
          'unidade_consumidora': '29985404',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f7b',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.4789564, -25.6979901],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 102,
          'unidade_operacional': 'SAA ETA3 A5 PRAIA LESTE A',
          'endereco': 'R Waldir Giglio Jr',
          'latitude': '-25.6979901',
          'longitude': '-48.4789564',
          'unidade_consumidora': '27895602',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f7a',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.591852, -25.730827],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 103,
          'unidade_operacional': 'SAA EEB7 A13 CAP CAMBARA A',
          'endereco': 'Rod Alexandra Matinhos',
          'latitude': '-25.730827',
          'longitude': '-48.591852',
          'unidade_consumidora': '41213947',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f79',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.6778707, -25.9773742],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 104,
          'unidade_operacional': 'SAA EEB02 CAP SAIGUACU A',
          'endereco': 'Rod Maximo J. PR 412',
          'latitude': '-25.9773742',
          'longitude': '-48.6778707',
          'unidade_consumidora': '61588555',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f78',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.4691482, -25.6838958],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 105,
          'unidade_operacional': 'SAA EET4 EET5 A4 CANOAS A',
          'endereco': 'R Irene Marta Troiani Lima',
          'latitude': '-25.6838958',
          'longitude': '-48.4691482',
          'unidade_consumidora': '27895610',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f77',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.5838628, -25.9125524],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 106,
          'unidade_operacional': 'SAA EET6EET7EET8 BREJATUBA',
          'endereco': 'R Angra dos Reis',
          'latitude': '-25.9125524',
          'longitude': '-48.5838628',
          'unidade_consumidora': '70574502',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f76',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.6424337, -25.9514942],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 107,
          'unidade_operacional': 'SAA ETA2 SAIGUACU A',
          'endereco': 'Rod Maximo J. PR 412',
          'latitude': '-25.9514942',
          'longitude': '-48.6424337',
          'unidade_consumidora': '61583758',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f75',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.4442682, -25.6493488],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 108,
          'unidade_operacional': 'SAA EET6 EET7 A3 IPANEMA A',
          'endereco': 'R Antonio V. Silva',
          'latitude': '-25.6493488',
          'longitude': '-48.4442682',
          'unidade_consumidora': '37507940',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f74',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.512903, -25.765574],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 111,
          'unidade_operacional': 'EEE07 SOLIMAR E',
          'endereco': 'R Argentina',
          'latitude': '-25.765574',
          'longitude': '-48.512903',
          'unidade_consumidora': '79665306',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f73',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.521564, -25.783683],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 112,
          'unidade_operacional': 'EET13EET14 A8 PGRANDE A',
          'endereco': 'R Dezenove Dezembro 3461',
          'latitude': '-25.783683',
          'longitude': '-48.521564',
          'unidade_consumidora': '39452336',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f72',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.441062, -25.649642],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 113,
          'unidade_operacional': 'EEE IPANEMA',
          'endereco': 'R. Dona Alba de Souza e Silva',
          'latitude': '-25.649642',
          'longitude': '-48.441062',
          'unidade_consumidora': '75565730',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f71',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.512818, -25.765399],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 114,
          'unidade_operacional': 'EEE SOLIMAR',
          'endereco': 'R. Argentina',
          'latitude': '-25.765399',
          'longitude': '-48.512818',
          'unidade_consumidora': '79665306',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f70',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.259014, -25.441549],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 115,
          'unidade_operacional': 'SEDE SANEPAR',
          'endereco': 'Rua Engenheiros Rebouças 1376, Curitiba/PR',
          'latitude': '-25.441549',
          'longitude': '-49.259014',
          'unidade_consumidora': '20830807_GD',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f6f',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.214819, -25.515866],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 116,
          'unidade_operacional': 'CS BIOENERGIA',
          'endereco': 'Rua Pr. Antônio Pólito, Curitiba/PR',
          'latitude': '-25.515866',
          'longitude': '-49.214819',
          'unidade_consumidora': '100627188_GD',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f6e',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.204859, -25.503042],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 117,
          'unidade_operacional': 'PARQUE CENTENÁRIO\nUBERABA',
          'endereco': 'Av. Comendador Franco, 9061,\nCuritiba',
          'latitude': '-25.503042',
          'longitude': '-49.204859',
          'unidade_consumidora': '97425907_GD',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f6d',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.001332, -23.373658],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 118,
          'unidade_operacional': 'SAA EEB13EEB14',
          'endereco': 'Estrada Gioffre, Londrina/PR',
          'latitude': '-23.373658',
          'longitude': '-51.001332',
          'unidade_consumidora': '20587171',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f6c',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.071891, -23.340786],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 119,
          'unidade_operacional': 'SAA EEB15',
          'endereco': 'Rodovia Major Achielles Pimpão, Londrina/PR',
          'latitude': '-23.340786',
          'longitude': '-51.071891',
          'unidade_consumidora': '20587155',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f6b',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.096274, -23.338647],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 120,
          'unidade_operacional': 'SAA ETA4EET20',
          'endereco': 'Rodovia Major Achielles Pimpão, Londrina/PR',
          'latitude': '-23.338647',
          'longitude': '-51.096274',
          'unidade_consumidora': '20587163',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f6a',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.193244, -23.356382],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 121,
          'unidade_operacional': 'SAA EEB1',
          'endereco': 'Estrada Fundos Enaus, Londrina/PR',
          'latitude': '-23.356382',
          'longitude': '-51.193244',
          'unidade_consumidora': '19452667',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f69',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.17086, -23.311803],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 122,
          'unidade_operacional': 'SAA ETA1ETA2EET0',
          'endereco': 'Avenida Juscelino Kubitschek, Londrina/PR',
          'latitude': '-23.311803',
          'longitude': '-51.170860',
          'unidade_consumidora': '19452632',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f68',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.166276, -23.309751],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 123,
          'unidade_operacional': 'SAA EET21',
          'endereco': 'Rua Sergipe, 1354, Londrina/PR',
          'latitude': '-23.309751',
          'longitude': '-51.166276',
          'unidade_consumidora': '20587147',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f67',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.110228, -23.248995],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 124,
          'unidade_operacional': 'SAA ETA GUARANI',
          'endereco': 'Gleba Jacutinga, Londrina/PR',
          'latitude': '-23.248995',
          'longitude': '-51.110228',
          'unidade_consumidora': '99786184',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f66',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.122195, -23.277095],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 125,
          'unidade_operacional': 'SAA EEE7',
          'endereco': 'Rua José Paiva Cavalcante, Londrina/PR',
          'latitude': '-23.277095',
          'longitude': '-51.122195',
          'unidade_consumidora': '69492026',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f65',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.102932, -23.242829],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 126,
          'unidade_operacional': 'SAA GUARANI NORT',
          'endereco': 'Gleba Jacutinga, Londrina/PR',
          'latitude': '-23.242829',
          'longitude': '-51.102932',
          'unidade_consumidora': '91359511',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f64',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.203227, -23.304728],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 127,
          'unidade_operacional': 'SAA EET06',
          'endereco': 'Rua Serra Paranapiaca, Londrina/PR',
          'latitude': '-23.304728',
          'longitude': '-51.203227',
          'unidade_consumidora': '5330351',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f63',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.140786, -23.320225],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 128,
          'unidade_operacional': 'SAA EET07',
          'endereco': 'Rua Catarina de Bora, Londrina/PR',
          'latitude': '-23.320225',
          'longitude': '-51.140786',
          'unidade_consumidora': '5329582',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f62',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.178612, -23.335829],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 129,
          'unidade_operacional': 'SAA EET22',
          'endereco': 'Av. Md. Leonia Milito, Londrina/PR',
          'latitude': '-23.335829',
          'longitude': '-51.178612',
          'unidade_consumidora': '97856681',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f61',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.156187, -23.282232],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 130,
          'unidade_operacional': 'SAA EET12EET13',
          'endereco': 'Av. Henrique Mansano 2160, Londrina/PR',
          'latitude': '-23.282232',
          'longitude': '-51.156187',
          'unidade_consumidora': '5330939',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f60',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.170212, -23.244559],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 131,
          'unidade_operacional': 'SAA EEB5EET14',
          'endereco':
            'Estrada Cj. Chefe Newton (Est. Velha Warta),\nLondrina/PR',
          'latitude': '-23.244559',
          'longitude': '-51.170212',
          'unidade_consumidora': '40184188',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f5f',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.148222, -23.355925],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 132,
          'unidade_operacional': 'SAA EET51',
          'endereco': 'Rua Sebastião A. S. Callero 100, Londrina/PR',
          'latitude': '-23.355925',
          'longitude': '-51.148222',
          'unidade_consumidora': '65141024',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f5e',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.109448, -23.288928],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 133,
          'unidade_operacional': 'SES EEE8',
          'endereco': 'Rua Ébio Ferraz de Carvalho, Londrina/PR',
          'latitude': '-23.288928',
          'longitude': '-51.109448',
          'unidade_consumidora': '32727917',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f5d',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.106482, -23.333354],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 134,
          'unidade_operacional': 'SES BACIA LIMOEIRO EEE11',
          'endereco': 'Rua Maurilio Mazzer, Londrina/PR',
          'latitude': '-23.333354',
          'longitude': '-51.106482',
          'unidade_consumidora': '39672131',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f5c',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.143191, -23.272278],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 135,
          'unidade_operacional': 'SAA EEB 11 EEB 17',
          'endereco': 'Rua Abilio Justiniano de Queiroz 1536,\nLondrina/PR',
          'latitude': '-23.272278',
          'longitude': '-51.143191',
          'unidade_consumidora': '41499255',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f5b',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.206333, -23.2865],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 136,
          'unidade_operacional': 'SAA EET-09 CR MARIA LÚCIA',
          'endereco': 'Rua Edivaldo Contato, 561, Maria Lúcia,\nLondrina/PR',
          'latitude': '-23.286500',
          'longitude': '-51.206333',
          'unidade_consumidora': '40611914',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f5a',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.165263, -23.310489],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 137,
          'unidade_operacional': 'SAA EET-05 CR HIGIENÓPOLIS',
          'endereco': 'Avenida Higienópolis, 75, Londrina/PR',
          'latitude': '-23.310489',
          'longitude': '-51.165263',
          'unidade_consumidora': '69481750_BT',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f59',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.119119, -23.288357],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 138,
          'unidade_operacional': 'SES ETE-01 NORTE',
          'endereco': 'JOSE PAIVA CAVALCANTE, Londrina',
          'latitude': '-23.288357',
          'longitude': '-51.119119',
          'unidade_consumidora': '32594852',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f58',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.121177, -23.275551],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 139,
          'unidade_operacional': 'SES ETE-02 SUL',
          'endereco': 'EST ORBASA, Londrina',
          'latitude': '-23.275551',
          'longitude': '-51.121177',
          'unidade_consumidora': '42396905',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f57',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.262638, -23.287075],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 140,
          'unidade_operacional': 'SES ETE1 SÃO DOMINGOS',
          'endereco': 'Rua da Esperança, 999, Cambé/PR',
          'latitude': '-23.287075',
          'longitude': '-51.262638',
          'unidade_consumidora': '19541627',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f56',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.264865, -23.281353],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 141,
          'unidade_operacional': 'SAA EET-27/28 CR ESPERANÇA',
          'endereco': 'Rua da Esperança, s/n, Cambé/PR',
          'latitude': '-23.281353',
          'longitude': '-51.264865',
          'unidade_consumidora': '24083542',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f55',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.650198, -23.187029],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 142,
          'unidade_operacional': 'SAA ETA1 EET1 EET2',
          'endereco': 'Avenida São Paulo, 790, Cornélio Procópio',
          'latitude': '-23.187029',
          'longitude': '-50.650198',
          'unidade_consumidora': '12908398',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f54',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.733505, -23.232065],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 143,
          'unidade_operacional': 'SAA EEB1 EEB2',
          'endereco': 'Rodovia BRO Congonhas LT 2A, Cornélio Procópio',
          'latitude': '-23.232065',
          'longitude': '-50.733505',
          'unidade_consumidora': '19452160',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f53',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.730557, -23.230595],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 144,
          'unidade_operacional': 'SAA EEB-03',
          'endereco': 'Rodovia BRO Congonhas KM 2, Cornélio Procópio',
          'latitude': '-23.230595',
          'longitude': '-50.730557',
          'unidade_consumidora': '19452179',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f52',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.308873, -23.466473],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 145,
          'unidade_operacional': 'SAA EEB-01',
          'endereco': 'GLB SETE CASAS, Arapongas',
          'latitude': '-23.466473',
          'longitude': '-51.308873',
          'unidade_consumidora': '19451610',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f51',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.348652, -23.431798],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 146,
          'unidade_operacional': 'SAA EEB-02',
          'endereco': 'EST DO BULLE, Arapongas',
          'latitude': '-23.431798',
          'longitude': '-51.348652',
          'unidade_consumidora': '19451601',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f50',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.381114, -23.372443],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 147,
          'unidade_operacional': 'SAA POÇO',
          'endereco': 'EST ABATEDOURO PINHEIROS, Arapongas',
          'latitude': '-23.372443',
          'longitude': '-51.381114',
          'unidade_consumidora': '90469259',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f4f',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.230265, -23.035649],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 148,
          'unidade_operacional': 'SAA EEB-01',
          'endereco': 'FAZ CASCATA, Bela Vista do Paraíso',
          'latitude': '-23.035649',
          'longitude': '-51.230265',
          'unidade_consumidora': '41640870',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f4e',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.381115, -23.372468],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 149,
          'unidade_operacional': 'SAA ETA',
          'endereco': 'Rodovia dos Pioneiros, S/N Rolândia',
          'latitude': '-23.372468',
          'longitude': '-51.381115',
          'unidade_consumidora': '40701301',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f4d',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.988491, -23.191466],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 150,
          'unidade_operacional': 'SAA EEB-1',
          'endereco': 'BR 153 KM 7 ESTR STO A.PLATINA, Jacarezinho',
          'latitude': '-23.191466',
          'longitude': '-49.988491',
          'unidade_consumidora': '3030784010',
          'concessionaria': 'CPFL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f4c',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.172222, -23.879056],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 151,
          'unidade_operacional': 'SAA EEB-1',
          'endereco': 'EST PATRIMONIO DO CAFE, Ibaiti',
          'latitude': '-23.879056',
          'longitude': '-50.172222',
          'unidade_consumidora': '64716929',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f4b',
        'geometry': {
          'type': 'Point',
          'coordinates': [-48.525277, -25.763038],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 109,
          'unidade_operacional': 'ETE MATINHOS',
          'endereco': 'R Itamar',
          'latitude': '-25.763038',
          'longitude': '-48.525277',
          'unidade_consumidora': '76229815',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f4a',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.799402, -23.653466],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 153,
          'unidade_operacional': 'SAA EEB-04',
          'endereco': 'EST GRAMADO DE BAIXO, Siqueira Campos',
          'latitude': '-23.653466',
          'longitude': '-49.799402',
          'unidade_consumidora': '21817405',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f49',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.077611, -23.043488],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 154,
          'unidade_operacional': 'SAA ETA-1',
          'endereco': 'R CEL BATISTA, 99 Cambará',
          'latitude': '-23.043488',
          'longitude': '-50.077611',
          'unidade_consumidora': '42089808',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f48',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.892056, -23.496333],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 155,
          'unidade_operacional': 'SAA EEB-1',
          'endereco': 'BRO DA PEDREIRA, Joaquim Távora',
          'latitude': '-23.496333',
          'longitude': '-49.892056',
          'unidade_consumidora': '28166159',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f47',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.109522, -23.245759],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 156,
          'unidade_operacional': 'SAA EEB-10',
          'endereco': 'ROD PR 92, Santo Ant. Platina',
          'latitude': '-23.245759',
          'longitude': '-50.109522',
          'unidade_consumidora': '60720514',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f46',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.427324, -23.536637],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 157,
          'unidade_operacional': 'SES EEE RAPOSA (EEE-05\nelevatória RAPOSA)',
          'endereco': 'Av. Rafael Sorpile, Apucarana/PR',
          'latitude': '-23.536637',
          'longitude': '-51.427324',
          'unidade_consumidora': '92096115',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f45',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.455589, -23.551277],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 158,
          'unidade_operacional': 'SAA EET-02/ETA RESERVAÇÃO\nCENTRAL',
          'endereco': 'R PONTA GROSSA, 1945, Apucarana/PR',
          'latitude': '-23.551277',
          'longitude': '-51.455589',
          'unidade_consumidora': '46523286',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f44',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.47581, -23.544983],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 159,
          'unidade_operacional': 'SAA EET-01 / ETA / ETL (ETA Vila\nRegina)',
          'endereco': 'Rua João XXIII, Apucarana/PR',
          'latitude': '-23.544983',
          'longitude': '-51.475810',
          'unidade_consumidora': '38055660',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f43',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.424607, -23.535294],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 160,
          'unidade_operacional': 'SAA POCO3RAPOSA EET16 (Área\nRAP-12)',
          'endereco': 'Rua Antônio Bertasso, Apucarana/PR',
          'latitude': '-23.535294',
          'longitude': '-51.424607',
          'unidade_consumidora': '104614285',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f42',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.475313, -23.570629],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 162,
          'unidade_operacional': 'SAA EET-27 (P28 JABOTI)',
          'endereco': 'AV JABOTI, Apucarana/PR',
          'latitude': '-23.570629',
          'longitude': '-51.475313',
          'unidade_consumidora': '98598945',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f41',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.487093, -23.549514],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 163,
          'unidade_operacional': 'SES EEE-6 (engenheiros)',
          'endereco': 'R CAPANEMA, Apucarana/PR',
          'latitude': '-23.549514',
          'longitude': '-51.487093',
          'unidade_consumidora': '99353598',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f40',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.67503, -24.264511],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 164,
          'unidade_operacional': 'SAA EEB-01 (Captação\nPindauvinha)',
          'endereco': 'RIO PINDAUVA, Ivaiporã/PR',
          'latitude': '-24.264511',
          'longitude': '-51.675030',
          'unidade_consumidora': '31903991',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f3f',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.581073, -23.974282],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 165,
          'unidade_operacional': 'SAA EEB-01 (Captação)',
          'endereco': 'EST RIO VARGINHA, Borrazópolis',
          'latitude': '-23.974282',
          'longitude': '-51.581073',
          'unidade_consumidora': '12424188',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f3e',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.331485, -23.667056],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 166,
          'unidade_operacional': 'SAA EEB-01 (Captação)',
          'endereco': 'EST JANGADINHA, Califórnia',
          'latitude': '-23.667056',
          'longitude': '-51.331485',
          'unidade_consumidora': '12446645',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f3d',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.539005, -23.613046],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 167,
          'unidade_operacional': 'SAA EEB-05 (Captação Rio)',
          'endereco': 'R EURIDES CAVALHEIRO DE MEIRA, Cambira',
          'latitude': '-23.613046',
          'longitude': '-51.539005',
          'unidade_consumidora': '31900852',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f3c',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.327498, -23.99326],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 168,
          'unidade_operacional':
            'SAA EEB-10 (Terreno Captação\nRio + Poço SIA4742(EEB-03)',
          'endereco': 'EST MARUMBIZINHO, Faxinal',
          'latitude': '-23.993260',
          'longitude': '-51.327498',
          'unidade_consumidora': '12956465',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f3b',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.322651, -23.981627],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 169,
          'unidade_operacional': 'SAA EEB-05 (Poço SAI 1485)',
          'endereco': 'EST FAXINALZINHO, Faxinal',
          'latitude': '-23.981627',
          'longitude': '-51.322651',
          'unidade_consumidora': '85205672',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f3a',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.612456, -23.636682],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 170,
          'unidade_operacional': 'SAA EEB-10 (Captação\nMarumbizinho)',
          'endereco': 'EST MARUMBIZINHO, Jandaia do Sul',
          'latitude': '-23.636682',
          'longitude': '-51.612456',
          'unidade_consumidora': '44805616',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f39',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.62743, -23.623424],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 171,
          'unidade_operacional':
            'SAA EEB-02 (Intermediária\nCaptação Marumbizinho)',
          'endereco': 'EST MARUMBIZINHO, Jandaia do Sul',
          'latitude': '-23.623424',
          'longitude': '-51.627430',
          'unidade_consumidora': '44805853',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f38',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.69175, -24.1938],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 172,
          'unidade_operacional': 'SAA EEB-08 EEB-09 (SIA3659 -poço Guarani)',
          'endereco': 'R SEBASTIANA MARIA APARECIDA D, Jardim\nAlegre',
          'latitude': '-24.193800',
          'longitude': '-51.691750',
          'unidade_consumidora': '98370200',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f37',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.789181, -23.9833],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 173,
          'unidade_operacional': 'SAA EEB-04 (captação Córrego\ndo Macaco)',
          'endereco': 'EST SJI UBAUNA, São João do Ivaí',
          'latitude': '-23.983300',
          'longitude': '-51.789181',
          'unidade_consumidora': '14619563',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f36',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.846147, -23.326898],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 174,
          'unidade_operacional': 'SAA CSP1',
          'endereco':
            'ROD PR 317 ROD DEP SILVIO BARROS, 14500\n- LT 37 38 PE 565 13',
          'latitude': '-23.326898',
          'longitude': '-51.846147',
          'unidade_consumidora': '19452934',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f35',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.924075, -23.409564],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 175,
          'unidade_operacional': 'SAA ETA',
          'endereco': 'PEDRO TAQUES , 1301 - MARINGÁ',
          'latitude': '-23.409564',
          'longitude': '-51.924075',
          'unidade_consumidora': '19452900',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f34',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.855849, -23.411776],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 176,
          'unidade_operacional': 'SES EEE1',
          'endereco': 'PNO JOAO NUNES, MARINGÁ',
          'latitude': '-23.411776',
          'longitude': '-51.855849',
          'unidade_consumidora': '102260460',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f33',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.844007, -23.328226],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 177,
          'unidade_operacional': 'SAA CSP EEB0',
          'endereco': 'EST MORANGUEIRA, 14500 - LT 41 A CSP 0\nEEB 0',
          'latitude': '-23.328226',
          'longitude': '-51.844007',
          'unidade_consumidora': '33760900',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f32',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.09078, -23.34783],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 178,
          'unidade_operacional': 'QD 11 LT 04 EEE MANDAGUAÇU',
          'endereco': 'R TAMANDARÉ - QD 11 LT 04',
          'latitude': '-23.34783',
          'longitude': '-52.09078',
          'unidade_consumidora': '33676208',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f31',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.655036, -23.646707],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 179,
          'unidade_operacional': 'SAA CSP1',
          'endereco': 'EST DOS AMORES, 62 Cianorte',
          'latitude': '-23.646707',
          'longitude': '-52.655036',
          'unidade_consumidora': '32629095',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f30',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.606406, -23.653279],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 180,
          'unidade_operacional': 'SAA ETA',
          'endereco': 'PCA JOAO XXIII, 344 Cianorte',
          'latitude': '-23.653279',
          'longitude': '-52.606406',
          'unidade_consumidora': '15348075',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f2f',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.286791, -23.692327],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 181,
          'unidade_operacional': 'SAA CSP1',
          'endereco': 'EST JABORANDI, Umuarama',
          'latitude': '-23.692327',
          'longitude': '-53.286791',
          'unidade_consumidora': '19453779',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f2e',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.27536, -23.781909],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 182,
          'unidade_operacional': 'SES EEE1',
          'endereco': 'R STO ANDRE, Umuarama',
          'latitude': '-23.781909',
          'longitude': '-53.275360',
          'unidade_consumidora': '44024924',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f2d',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.33247, -23.76842],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 183,
          'unidade_operacional': 'SES EEE DOM PEDRO',
          'endereco': 'R ITU, Umuarama',
          'latitude': '-23.768420',
          'longitude': '-53.332470',
          'unidade_consumidora': '50792180',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f2c',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.326921, -23.746615],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 184,
          'unidade_operacional': 'SES EEE GUARANI',
          'endereco': 'R STA ROSA, Umuarama',
          'latitude': '-23.746615',
          'longitude': '-53.326921',
          'unidade_consumidora': '70556997',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f2b',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.288621, -23.778802],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 185,
          'unidade_operacional': 'SES EEE323',
          'endereco': 'Rod Umu Mga, 0, Pe 07669 2019 km 323, Umuarama',
          'latitude': '-23.778802',
          'longitude': '-53.288621',
          'unidade_consumidora': '105704008',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f2a',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.423818, -24.06709],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 186,
          'unidade_operacional': 'SAA CSP1',
          'endereco': 'AV JOAO RIBEIRO HAENISCH, Campo Mourão',
          'latitude': '-24.067090',
          'longitude': '-52.423818',
          'unidade_consumidora': '31792634',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f29',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.397294, -24.050429],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 187,
          'unidade_operacional': 'SAA ETA',
          'endereco': 'AV MANOEL NOGUEIRA, 615, Campo Mourão',
          'latitude': '-24.050429',
          'longitude': '-52.397294',
          'unidade_consumidora': '31846882',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f28',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.3686, -23.9955],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 188,
          'unidade_operacional': 'PE 06786 2019 ESGOTO PROX\nINTEGR',
          'endereco': 'Est. Velha Cmo Aru',
          'latitude': '-23.9955',
          'longitude': '-52.3686',
          'unidade_consumidora': '92638775',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f27',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.3622, -24.055],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 189,
          'unidade_operacional': 'ASSOCIAÇÃO EEE 03',
          'endereco': 'Rua Formosa',
          'latitude': '-24.055',
          'longitude': '-52.3622',
          'unidade_consumidora': '41418417',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f26',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.350163, -24.020583],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 190,
          'unidade_operacional': 'EEE 02 J PAULISTA',
          'endereco': 'Rua Antonio Justino Ferreira',
          'latitude': '-24.020583',
          'longitude': '-52.350163',
          'unidade_consumidora': '35436778',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f25',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.513058, -23.062598],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 191,
          'unidade_operacional': 'SAA CSP1',
          'endereco':
            'Paranavaí - ESTRADA PARANAVAI REPRESA BARBALHO (Zona\nRural entrada prox. FACULDADE FAFIPA)',
          'latitude': '-23.062598',
          'longitude': '-52.513058',
          'unidade_consumidora': '44010141',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f24',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.468674, -23.086443],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 192,
          'unidade_operacional': 'SAA ETA',
          'endereco':
            'Paranavaí - AV: PRES. JUSCELINO KUBITSCHEK DE OLIVEIRA,\n780',
          'latitude': '-23.086443',
          'longitude': '-52.468674',
          'unidade_consumidora': '44436637',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f23',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.529704, -23.060888],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 193,
          'unidade_operacional': 'SAA EEB2',
          'endereco': 'Paranavaí - ESTRADA VELHA PARANAVAÍ GUAIRACÁ',
          'latitude': '-23.060888',
          'longitude': '-52.529704',
          'unidade_consumidora': '52358259',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f22',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.128011, -25.034984],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 194,
          'unidade_operacional': 'CSP 02 EEB 02 CASA\nOPERADOR',
          'endereco': 'JD PITANGUI, PONTA GROSSA',
          'latitude': '-25.034984',
          'longitude': '-50.128011',
          'unidade_consumidora': '19453302',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f21',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.062286, -25.019245],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 195,
          'unidade_operacional': 'CSP 01 EEB10 REPRESA',
          'endereco': 'VL DO ALAGADOS, PONTA GROSSA',
          'latitude': '-25.019245',
          'longitude': '-50.062286',
          'unidade_consumidora': '19453299',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f20',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.155758, -25.079052],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 196,
          'unidade_operacional': 'RAPS EET 05 SCO AREA\n06 LA',
          'endereco': 'R CONRADO PEREIRA RAMOS, PONTA GROSSA',
          'latitude': '-25.079052',
          'longitude': '-50.155758',
          'unidade_consumidora': '4334221',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f1f',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.533366, -25.464391],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 197,
          'unidade_operacional': 'CSP 01 EEB 01',
          'endereco': 'ROD BR 277, IRATI',
          'latitude': '-25.464391',
          'longitude': '-50.533366',
          'unidade_consumidora': '40934756',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f1e',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.961241, -25.237668],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 198,
          'unidade_operacional': 'CSP 01 EEB 01 02 03 R\nDOS PATOS',
          'endereco': 'R CEL JOAO PEDRO MARTINS, PRUDENTOPOLIS',
          'latitude': '-25.237668',
          'longitude': '-50.961241',
          'unidade_consumidora': '35559217',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f1d',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.630971, -25.242484],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 199,
          'unidade_operacional': 'CSP 01 EEB10',
          'endereco': 'R ARGEMIRO DA SILVA, IMBITUVA',
          'latitude': '-25.242484',
          'longitude': '-50.630971',
          'unidade_consumidora': '76376249',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f1c',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.978222, -25.426052],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 200,
          'unidade_operacional': 'EET 05 BOOSTER',
          'endereco': 'ST MUNICIPAL DE PALMEIRA, PALMEIRA',
          'latitude': '-25.426052',
          'longitude': '-49.978222',
          'unidade_consumidora': '44219393',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f1b',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.584146, -25.465566],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 201,
          'unidade_operacional': 'KM 235',
          'endereco': 'ROD BR 277, 0 IRATI',
          'latitude': '-25.465566',
          'longitude': '-50.584146',
          'unidade_consumidora': '99959739',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f1a',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.620445, -25.448704],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 202,
          'unidade_operacional': 'POCO 2 KM 01',
          'endereco': 'ROD PR 153, IRATI',
          'latitude': '-25.448704',
          'longitude': '-50.620445',
          'unidade_consumidora': '48511978',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f19',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.648747, -25.476191],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 203,
          'unidade_operacional': 'RAP 01 EET 03',
          'endereco': 'R ABILON SOUZA NAVES, IRATI',
          'latitude': '-25.476191',
          'longitude': '-50.648747',
          'unidade_consumidora': '45239282',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f18',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.858723, -25.015655],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 204,
          'unidade_operacional': 'FRENTE HOSPITAL',
          'endereco': 'R RIO BRANCO, IVAI',
          'latitude': '-25.015655',
          'longitude': '-50.858723',
          'unidade_consumidora': '18138373',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f17',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.636559, -25.457753],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 205,
          'unidade_operacional': 'SIST ESG SANIT',
          'endereco': 'R SYLVIO AMARAL GRUBER, IRATI',
          'latitude': '-25.457753',
          'longitude': '-50.636559',
          'unidade_consumidora': '30290635',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f16',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.624575, -25.464195],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 206,
          'unidade_operacional': 'POCO 01\nNHAPINDAZAL',
          'endereco': 'POV NHAPINDAZAL, IRATI',
          'latitude': '-25.464195',
          'longitude': '-50.624575',
          'unidade_consumidora': '39466787',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f15',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.1277568, -25.0465359],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 207,
          'unidade_operacional': 'ETE VERDE',
          'endereco': 'RUA WASHINGTON SUBTIL CHUEIRE S/N JD CARVALHO',
          'latitude': '-25.0465359',
          'longitude': '-50.1277568',
          'unidade_consumidora': '46920617',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f14',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.189589, -25.1358551],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 208,
          'unidade_operacional': 'ETE RONDA',
          'endereco': 'RUA OZORIO GUIMARÃES MARTINS S/N DER',
          'latitude': '-25.1358551',
          'longitude': '-50.189589',
          'unidade_consumidora': '106643517',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f13',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.267136, -25.282049],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 209,
          'unidade_operacional': 'CSB-01',
          'endereco': 'R TTE TIBURCIO PUPO - PROLONG SANEPAR GUARAGI\nPOCO 04',
          'latitude': '-25.282049',
          'longitude': '-50.267136',
          'unidade_consumidora': '65191404',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f12',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.095314, -25.568662],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 210,
          'unidade_operacional': 'CSB-05',
          'endereco': 'R SAIDA GUARAPUAVA - CSB 05',
          'latitude': '-25.568662',
          'longitude': '-51.095314',
          'unidade_consumidora': '85312185',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f11',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.007117, -25.419736],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 211,
          'unidade_operacional': 'SCF-04',
          'endereco': 'ST MUNICIPAL DE PALMEIRA - SANEPAR BSB1',
          'latitude': '-25.419736',
          'longitude': '-50.007117',
          'unidade_consumidora': '18267211',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f10',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.007117, -25.419736],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 212,
          'unidade_operacional': 'CSB-04',
          'endereco': 'R SANTOS DUMONT - PROFAZPALM SANEPAR P 04',
          'latitude': '-25.419736',
          'longitude': '-50.007117',
          'unidade_consumidora': '46708154',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f0f',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.163568, -25.089767],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 213,
          'unidade_operacional': 'RES/EET',
          'endereco': 'R BR DO CERRO AZUL - SANEPAR CHIST\nRES1REL1EET3A5CSB1',
          'latitude': '-25.089767',
          'longitude': '-50.163568',
          'unidade_consumidora': '45340340',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f0e',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.425123, -25.355324],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 214,
          'unidade_operacional': 'CSB-07 EEB-01 ETA',
          'endereco': 'POV GUABIROBA - SANEPAR CSP1 EEB1',
          'latitude': '-25.355324',
          'longitude': '-50.425123',
          'unidade_consumidora': '72507136',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f0d',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.453249, -25.348552],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 215,
          'unidade_operacional': 'CSB-09',
          'endereco': 'POV BOM RETIRO - PE 02959 2018 CSB 9 POCO 9',
          'latitude': '-25.348552',
          'longitude': '-50.453249',
          'unidade_consumidora': '104309555',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f0c',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.46088, -25.51462],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 216,
          'unidade_operacional': 'Booster',
          'endereco': 'AV JUSCELINO KUBITSCHEK, - S N SANEPAR EET1\nBOOSTER',
          'latitude': '-25.51462',
          'longitude': '-50.46088',
          'unidade_consumidora': '17736064',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f0b',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.60349, -24.335104],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 217,
          'unidade_operacional': 'ETA 01 REN 01 EET 01 REL',
          'endereco': 'R BELO HORIZONTE, TELEMACO BORBA',
          'latitude': '-24.335104',
          'longitude': '-50.603490',
          'unidade_consumidora': '43338739',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f0a',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.600478, -24.99798],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 218,
          'unidade_operacional': 'EEB 01 CSP 01',
          'endereco': 'R MANAUS, TELEMACO BORBA',
          'latitude': '-24.997980',
          'longitude': '-50.600478',
          'unidade_consumidora': '41611187',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f09',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.002309, -24.777543],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 219,
          'unidade_operacional': 'CSP 01 IAPO',
          'endereco': 'R GUSTAVO ARNOLDO AGUSTAT, CASTRO',
          'latitude': '-24.777543',
          'longitude': '-50.002309',
          'unidade_consumidora': '42034515',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f08',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.143724, -24.909149],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 220,
          'unidade_operacional': 'CSP 01 EEB 02 ETA 01 RAP',
          'endereco': 'R ATLANTA, CARAMBEI',
          'latitude': '-24.909149',
          'longitude': '-50.143724',
          'unidade_consumidora': '43195300',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f07',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.941718, -24.18675],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 221,
          'unidade_operacional': 'CSB EEB RAP ETA EET',
          'endereco': 'EST VELHA APUCARANA ORTIGUEIRA',
          'latitude': '-24.186750',
          'longitude': '-50.941718',
          'unidade_consumidora': '18229077',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f06',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.754971, -24.44796],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 222,
          'unidade_operacional': 'ETA IMBAU',
          'endereco': 'R MARINGA, IMBAU',
          'latitude': '-24.447960',
          'longitude': '-50.754971',
          'unidade_consumidora': '64175251',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f05',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.015444, -24.813771],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 223,
          'unidade_operacional': 'EEB 02 SAO CRISTOVAO',
          'endereco': 'ROD PR CENTO E CINQUENTA E UM, CASTRO',
          'latitude': '-24.813771',
          'longitude': '-50.015444',
          'unidade_consumidora': '39964973',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f04',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.470083, -24.105877],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 224,
          'unidade_operacional': 'EET 05 SITIO TANAKA',
          'endereco': 'AV PREF DOMINGOS CUNHA, SENGES',
          'latitude': '-24.105877',
          'longitude': '-49.470083',
          'unidade_consumidora': '52131718',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f03',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.861826, -24.64459],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 225,
          'unidade_operacional': 'CSP 01 EEB 01 RESERVA',
          'endereco': 'VL LOTEAMENTO ESPLANADA, RESERVA',
          'latitude': '-24.644590',
          'longitude': '-50.861826',
          'unidade_consumidora': '64167364',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f02',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.411259, -24.523631],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 226,
          'unidade_operacional': 'CSB 01 EEB 01',
          'endereco': 'R CEL ESPIRITO SANTO, TIBAGI',
          'latitude': '-24.523631',
          'longitude': '-50.411259',
          'unidade_consumidora': '62362801',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f01',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.831131, -24.153908],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 227,
          'unidade_operacional': 'EET 06 MINA 4',
          'endereco': 'R JOSE DIAS, ARAPOTI',
          'latitude': '-24.153908',
          'longitude': '-49.831131',
          'unidade_consumidora': '59059729',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7f00',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.631591, -24.333647],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 228,
          'unidade_operacional': 'EET-04 PE 02299 2017',
          'endereco': 'R JOSE LINHARES, TELEMACO BORBA',
          'latitude': '-24.333647',
          'longitude': '-50.631591',
          'unidade_consumidora': '53263081',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eff',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.335172, -24.566515],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 229,
          'unidade_operacional': 'CSP 01 EEB 01 ETA 01 EET',
          'endereco': 'GLB RIO COLECHO, CANDIDO DE ABREU',
          'latitude': '-24.566515',
          'longitude': '-51.335172',
          'unidade_consumidora': '12718750',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7efe',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.853815, -24.656702],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 230,
          'unidade_operacional': 'ETA',
          'endereco': 'R AMANDO SEDELAK, RESERVA',
          'latitude': '-24.656702',
          'longitude': '-50.853815',
          'unidade_consumidora': '78084237',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7efd',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.0136, -24.796901],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 231,
          'unidade_operacional': 'ETA EET 03',
          'endereco': 'R TELLES, CASTRO',
          'latitude': '-24.796901',
          'longitude': '-50.013600',
          'unidade_consumidora': '63416794',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7efc',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.19942, -24.223477],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 232,
          'unidade_operacional': 'POCO 02 KM 157 7',
          'endereco': 'EST ROD VENTANIA IBAITI, VENTANIA',
          'latitude': '-24.223477',
          'longitude': '-50.199420',
          'unidade_consumidora': '75403943',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7efb',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.815982, -24.143066],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 233,
          'unidade_operacional': 'EEB 01 EET 01',
          'endereco': 'R ORLANDO RODRIGUES DA SILVA, ARAPOTI',
          'latitude': '-24.143066',
          'longitude': '-49.815982',
          'unidade_consumidora': '59144017',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7efa',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.409137, -24.538868],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 234,
          'unidade_operacional': 'EEB-01 CAPT PE 022 17',
          'endereco': 'CID TIBAGI, 0 TIBAGI',
          'latitude': '-24.538868',
          'longitude': '-50.409137',
          'unidade_consumidora': '102159459',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ef9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.643643, -24.32907],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 235,
          'unidade_operacional': 'SER 01 EET 06 BOOSTER',
          'endereco': 'R CAMPINA ALTA, TELEMACO BORBA',
          'latitude': '-24.329070',
          'longitude': '-50.643643',
          'unidade_consumidora': '78084113',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ef8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.463355, -24.104545],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 236,
          'unidade_operacional': 'POCO 06 FITRO',
          'endereco': 'R VER SEBASTIAO MELO CEZAR, SENGES',
          'latitude': '-24.104545',
          'longitude': '-49.463355',
          'unidade_consumidora': '102135541',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ef7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.144588, -24.90859],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 237,
          'unidade_operacional': 'CSP 04',
          'endereco': 'R ATLANTA, CARAMBEI',
          'latitude': '-24.908590',
          'longitude': '-50.144588',
          'unidade_consumidora': '75299372',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ef6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.838858, -24.156814],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 239,
          'unidade_operacional': 'EEB 8',
          'endereco': 'ESTR.MUN.ALPHAVILLE RINCAO S/N, ARAPOTI',
          'latitude': '-24.156814',
          'longitude': '-49.838858',
          'unidade_consumidora': '12629',
          'concessionaria': 'CERAL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ef5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.834102, -24.140753],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 240,
          'unidade_operacional': 'EET 02 EET 04 RAP 02 REL',
          'endereco': 'R. AMADEU LUÍS ESTEVES, 1',
          'latitude': '-24.140753',
          'longitude': '-49.834102',
          'unidade_consumidora': '82724873',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ef4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.335156, -24.566765],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 242,
          'unidade_operacional': 'EET 02 REN 01 REL 01 ESCR',
          'endereco': 'TV. GUILHERME MULLER',
          'latitude': '-24.566765',
          'longitude': '-51.335156',
          'unidade_consumidora': '47899816',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ef3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.112338, -24.941966],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 243,
          'unidade_operacional': 'CSB 02 CSB 03',
          'endereco': 'CARAMBEÍ',
          'latitude': '-24.941966',
          'longitude': '-50.112338',
          'unidade_consumidora': '84768754',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ef2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.928617, -24.529371],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 244,
          'unidade_operacional': 'PE 18525 2020 ETA 01 REL 01\nREN',
          'endereco': 'R. PREF. JOAO MARIA DE QUEIROS 600',
          'latitude': '-24.529371',
          'longitude': '-49.928617',
          'unidade_consumidora': '18339654',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ef1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.909156, -24.540178],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 245,
          'unidade_operacional': 'CSP 02 EEB 01',
          'endereco': 'AV NOSSA SENHORA DAS BROTAS',
          'latitude': '-24.540178',
          'longitude': '-49.909156',
          'unidade_consumidora': '18339603',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ef0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.477197, -24.115832],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 246,
          'unidade_operacional': 'CSB 04',
          'endereco': 'R. VEREADOR ANTONIO GONÇALVES DE CASTRO',
          'latitude': '-24.115832',
          'longitude': '-49.477197',
          'unidade_consumidora': '51026279',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eef',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.603694, -24.336262],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 247,
          'unidade_operacional': 'AREA 2A EET11 EET12 RAP04',
          'endereco': 'R. CUIABA - VILA SANTA RITA',
          'latitude': '-24.336262',
          'longitude': '-50.603694',
          'unidade_consumidora': '108332438',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eee',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.608231, -24.334684],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 248,
          'unidade_operacional': 'RAP PE 07585 2021 - FRENTE\nCOLÉGIO IDEAL',
          'endereco': 'NOSSA SRA DE FÁTIMA',
          'latitude': '-24.334684',
          'longitude': '-50.608231',
          'unidade_consumidora': '108331024',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eed',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.207591, -24.224572],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 249,
          'unidade_operacional': 'RAP 01 EAB 01 KM 157',
          'endereco': 'ROD. TRANSBRASILIANA',
          'latitude': '-24.224572',
          'longitude': '-50.207591',
          'unidade_consumidora': '75402351',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eec',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.471423, -24.108203],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 250,
          'unidade_operacional': 'CSB 01',
          'endereco': 'SENGES',
          'latitude': '-24.108203',
          'longitude': '-49.471423',
          'unidade_consumidora': '18643582',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eeb',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.437148, -25.399848],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 251,
          'unidade_operacional': 'GER/ETA/CAPTACAO',
          'endereco': 'R ROSA LUSTOSA SIQUEIRA, GUARAPUAVA',
          'latitude': '-25.399848',
          'longitude': '-51.437148',
          'unidade_consumidora': '9/3525891-2',
          'concessionaria': 'ENERGISA',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eea',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.46193, -25.35748],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 252,
          'unidade_operacional': 'ETE EEE8',
          'endereco': 'R ENG LENTSCH 363 V KAREN - GUARAPUAVA',
          'latitude': '-25.357480',
          'longitude': '-51.46193',
          'unidade_consumidora': '9/3530431-0_BT',
          'concessionaria': 'ENERGISA',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ee9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.51173, -25.41042],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 253,
          'unidade_operacional': 'ETE VASSORAL',
          'endereco': 'R ROSA DOMINICO 950 GUARAPUAVA',
          'latitude': '-25.410420',
          'longitude': '-51.51173',
          'unidade_consumidora': '9/4758012-1',
          'concessionaria': 'ENERGISA',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ee8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.461934, -25.426786],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 254,
          'unidade_operacional': 'EET 1 EEB ETA 1',
          'endereco': 'LIN RIO DO LEAO KM 460 - RURAL',
          'latitude': '-25.426786',
          'longitude': '-52.461934',
          'unidade_consumidora': '33799733',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ee7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.047405, -26.230312],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 255,
          'unidade_operacional': 'CX DAGUA EEB 01 ETA 01 RAP 01 EE',
          'endereco': 'R JOSE RUDNIKI, 1080 UNIAO DA VITORIA',
          'latitude': '-26.230312',
          'longitude': '-51.047405',
          'unidade_consumidora': '18898068',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ee6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.562122, -26.16809],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 256,
          'unidade_operacional': 'EET08',
          'endereco': 'Av. LUIZ VERSETTI S/N°',
          'latitude': '-26.168090',
          'longitude': '-51.562122',
          'unidade_consumidora': '78478782',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ee5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.346706, -26.014216],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 257,
          'unidade_operacional': 'POÇO/EET01',
          'endereco': 'R. EDMUNDO OTTO, 100',
          'latitude': '-26.014216',
          'longitude': '-51.346706',
          'unidade_consumidora': '68029098',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ee4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.321343, -26.426832],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 258,
          'unidade_operacional': 'ETA',
          'endereco': 'R. PARANA, 241',
          'latitude': '-26.426832',
          'longitude': '-51.321343',
          'unidade_consumidora': '90439325',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ee3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.832309, -25.883648],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 259,
          'unidade_operacional': 'ETA',
          'endereco': 'AV. JOÃO PESSOA, 120',
          'latitude': '-25.883648',
          'longitude': '-50.832309',
          'unidade_consumidora': '93430442',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ee2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.935279, -26.189466],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 260,
          'unidade_operacional': 'CSB2',
          'endereco': 'EST. BR 476, S/N°',
          'latitude': '-26.189466',
          'longitude': '-50.935279',
          'unidade_consumidora': '56026684',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ee1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-50.78694, -25.716564],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 261,
          'unidade_operacional': 'ETE FAXINAL',
          'endereco': 'R. JACOB BURKO, S/N°',
          'latitude': '-25.716564',
          'longitude': '-50.786940',
          'unidade_consumidora': '38657414',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ee0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.464798, -25.028888],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 262,
          'unidade_operacional': 'SAA ETA3EEB1EEB5EET11ACSP1',
          'endereco': 'Linha Sanepar, Cascavel/PR',
          'latitude': '-25.028888',
          'longitude': '-53.464798',
          'unidade_consumidora': '42223750',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7edf',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.455668, -24.969132],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 263,
          'unidade_operacional': 'SAA ETA1EET1EET2EET3 CR1',
          'endereco': 'Rua Cassiano Jorge Fernandes, 930,\nCascavel/PR',
          'latitude': '-24.969132',
          'longitude': '-53.455668',
          'unidade_consumidora': '32605579',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ede',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.474249, -24.929883],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 264,
          'unidade_operacional': 'SAA EEB 19 EET 17',
          'endereco': 'R Alfeu Vieiro, 167, Cascavel/PR',
          'latitude': '-24.929883',
          'longitude': '-53.474249',
          'unidade_consumidora': '79937667',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7edd',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.42294, -24.951032],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 265,
          'unidade_operacional': 'SAA EEB23 CSB39',
          'endereco': 'Rua Laranjeira do Sul, Cascavel/PR',
          'latitude': '-24.951032',
          'longitude': '-53.422940',
          'unidade_consumidora': '99379422',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7edc',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.364851, -24.921722],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 266,
          'unidade_operacional': 'SAA CSB 31 EEB 16',
          'endereco': 'Linha Rio Barreiro, Cascavel/PR',
          'latitude': '-24.921722',
          'longitude': '-53.364851',
          'unidade_consumidora': '91392454',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7edb',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.383363, -24.96116],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 267,
          'unidade_operacional': 'SAA CSB32 EEB17',
          'endereco': 'Linha Rio Barreiro, Cascavel/PR',
          'latitude': '-24.961160',
          'longitude': '-53.383363',
          'unidade_consumidora': '91393973',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eda',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.476742, -24.951689],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 268,
          'unidade_operacional': 'SAA EET16RAP3CR3JLACERDA',
          'endereco': 'Rua Jorge Lacerda, 300, Cascavel/PR',
          'latitude': '-24.951689',
          'longitude': '-53.476742',
          'unidade_consumidora': '79545793',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ed9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.435422, -24.953954],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 269,
          'unidade_operacional': 'CASCAVEL - EEE01 ELEVAT LAGO MUNICIPAL',
          'endereco': 'R TIRADENTES, 2',
          'latitude': '-24.953954',
          'longitude': '-53.435422',
          'unidade_consumidora': '8529205',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ed8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.979001, -25.233058],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 270,
          'unidade_operacional': 'MATELANDIA - EEE2 JD GUAIRACA',
          'endereco': 'R HELMUTH BLOSFELD,',
          'latitude': '-25.233058',
          'longitude': '-53.979001',
          'unidade_consumidora': '60602643',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ed7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.99291, -25.252153],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 271,
          'unidade_operacional': 'MATELANDIA - ETE1RALF1BR277 RIO',
          'endereco': 'EST MEDIANEIRA, 1',
          'latitude': '-25.252153',
          'longitude': '-53.992910',
          'unidade_consumidora': '105799653',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ed6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.17618, -25.42934],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 272,
          'unidade_operacional':
            'TRES BARRAS DO PARANA - ETE1 FUNDOS\nLATICINIO',
          'endereco': 'R VER ELIANE DEZAN,',
          'latitude': '-25.42934',
          'longitude': '-53.17618',
          'unidade_consumidora': '105798908',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ed5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.631614, -25.474627],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 273,
          'unidade_operacional':
            'CAPITAO LEONIDAS MARQUES - CSB04EEB1 L\nBIVILAQUA',
          'endereco': 'LIN BEVILAQUA,',
          'latitude': '-25.474627',
          'longitude': '-53.631614',
          'unidade_consumidora': '68081251',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ed4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.612368, -25.504866],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 274,
          'unidade_operacional':
            'CAPITAO LEONIDAS MARQUES - CSB08EEB2 L\nMALVARI',
          'endereco': 'LIN MALVARI,',
          'latitude': '-25.504866',
          'longitude': '-53.612368',
          'unidade_consumidora': '102220824',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ed3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.624036, -25.468644],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 275,
          'unidade_operacional':
            'CAPITAO LEONIDAS MARQUES - EET1RAP1SIC1\nRIO MONTEIRO',
          'endereco': 'R RIO MONTEIRO,',
          'latitude': '-25.468644',
          'longitude': '-53.624036',
          'unidade_consumidora': '8866163',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ed2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.486129, -25.147202],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 276,
          'unidade_operacional': 'CASCAVEL - EEB03CSP3 CAP RIO SALTINHO',
          'endereco': 'LIN SANEPAR,',
          'latitude': '-25.147202',
          'longitude': '-53.486129',
          'unidade_consumidora': '68085559',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ed1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.371962, -24.935936],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 277,
          'unidade_operacional': 'CASCAVEL - EEB18 ELEV BARREIROS',
          'endereco': 'LIN RIO BARREIRO,',
          'latitude': '-24.935936',
          'longitude': '-53.371962',
          'unidade_consumidora': '91394031',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ed0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.390358, -25.104071],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 278,
          'unidade_operacional': 'CASCAVEL - EEB20EEB21CSP4 CAP SAO JOSE',
          'endereco': 'LIN S ROQUE, 1',
          'latitude': '-25.104071',
          'longitude': '-53.390358',
          'unidade_consumidora': '99713349',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ecf',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.4096, -24.929442],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 279,
          'unidade_operacional': 'CASCAVEL - EET07CR11RAP11 BELA VISTA',
          'endereco': 'R ALTEMAR DUTRA,',
          'latitude': '-24.929442',
          'longitude': '-53.409600',
          'unidade_consumidora': '61792098',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ece',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.853987, -25.146405],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 280,
          'unidade_operacional': 'CEU AZUL - CSB01EEB1',
          'endereco': 'R DUQUE DE CAXIAS,',
          'latitude': '-25.146405',
          'longitude': '-53.853987',
          'unidade_consumidora': '8899738',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ecd',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.850694, -25.150937],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 281,
          'unidade_operacional': 'CEU AZUL - EET1RAP1SIC1',
          'endereco': 'AV MARECHAL CANDIDO',
          'latitude': '-25.150937',
          'longitude': '-53.850694',
          'unidade_consumidora': '78564662',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ecc',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.279124, -24.802645],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 282,
          'unidade_operacional': 'CORBELIA - CSB01CSB02EEB1AEEB1BEEB2',
          'endereco': 'RM SANTA ROSA CASCAT',
          'latitude': '-24.802645',
          'longitude': '-53.279124',
          'unidade_consumidora': '9027092',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ecb',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.310937, -24.774248],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 283,
          'unidade_operacional': 'CORBELIA - CSB05CSB06EEB5EEB6EEB2ARAP1',
          'endereco': 'RM SAMAMBAIAL, 1683',
          'latitude': '-24.774248',
          'longitude': '-53.310937',
          'unidade_consumidora': '22358609',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eca',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.34355, -24.775385],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 284,
          'unidade_operacional': 'CORBELIA - CSB07EEB7EEB03 BARRA BONITA',
          'endereco': 'LIN BARRA BONITA,',
          'latitude': '-24.775385',
          'longitude': '-53.343550',
          'unidade_consumidora': '75753758',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ec9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.897837, -25.036372],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 285,
          'unidade_operacional': 'GUARANIACU - CSB06EEB03',
          'endereco': 'LIN SAO PEDRO,',
          'latitude': '-25.036372',
          'longitude': '-52.897837',
          'unidade_consumidora': '86398652',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ec8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.88018, -25.079745],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 286,
          'unidade_operacional': 'GUARANIACU - EEB02 INTERMEDIARIA',
          'endereco': 'LIN LIRA,',
          'latitude': '-25.079745',
          'longitude': '-52.880180',
          'unidade_consumidora': '9823000',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ec7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.888987, -25.065897],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 287,
          'unidade_operacional': 'GUARANIACU - EEB1CSP1',
          'endereco': 'LIN RIO FIVELA,',
          'latitude': '-25.065897',
          'longitude': '-52.888987',
          'unidade_consumidora': '9822950',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ec6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.035454, -25.12317],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 288,
          'unidade_operacional': 'IBEMA - CSB01EEB1',
          'endereco': 'LIN CAMPOS SALES,',
          'latitude': '-25.123170',
          'longitude': '-53.035454',
          'unidade_consumidora': '75192900',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ec5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.013853, -25.124415],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 289,
          'unidade_operacional': 'IBEMA - CSB04RAP3EEB4EEB5',
          'endereco': 'R IBEMA,',
          'latitude': '-25.124415',
          'longitude': '-53.013853',
          'unidade_consumidora': '46861009',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ec4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.588912, -25.286248],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 290,
          'unidade_operacional': 'LINDOESTE - CSB03EEB3 SANTA IZABEL',
          'endereco': 'LIN DO GORDO,',
          'latitude': '-25.286248',
          'longitude': '-53.588912',
          'unidade_consumidora': '84599391',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ec3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.934538, -25.28374],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 291,
          'unidade_operacional': 'MATELANDIA - CSB01EEB1EET8RSE5SCF1',
          'endereco': 'EST MATELANDIA, 1170',
          'latitude': '-25.283740',
          'longitude': '-53.934538',
          'unidade_consumidora': '40994724',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ec2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.954282, -25.256934],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 292,
          'unidade_operacional': 'MATELANDIA - EET07RSE4',
          'endereco': 'EST MATELANDIA, 1156',
          'latitude': '-25.256934',
          'longitude': '-53.954282',
          'unidade_consumidora': '40995305',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ec1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.616192, -25.072346],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 293,
          'unidade_operacional': 'SANTA TEREZA DO OESTE - CSB01EEB1',
          'endereco': 'LIN GONCALVES DIAS,',
          'latitude': '-25.072346',
          'longitude': '-53.616192',
          'unidade_consumidora': '11013958',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ec0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.179525, -25.439256],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 294,
          'unidade_operacional': 'TRES BARRAS DO PARANA - EEB1CSP1',
          'endereco': 'LIN TRIGOLANDIA,',
          'latitude': '-25.439256',
          'longitude': '-53.179525',
          'unidade_consumidora': '11346221',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ebf',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.551566, -25.446202],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 295,
          'unidade_operacional': 'SAA EEB2 VILA C',
          'endereco': 'Alameda Teresina, Foz do Iguaçu/PR',
          'latitude': '-25.446202',
          'longitude': '-54.551566',
          'unidade_consumidora': '36033111',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ebe',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.526453, -25.581794],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 296,
          'unidade_operacional': 'SAA ETA TAMANDUA',
          'endereco': 'Av. Cataratas, Foz do Iguaçu/PR',
          'latitude': '-25.581794',
          'longitude': '-54.526453',
          'unidade_consumidora': '19452306',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ebd',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.555854, -25.450759],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 297,
          'unidade_operacional': 'SAA ETA VILA C',
          'endereco': 'Alameda Teresina, Foz do Iguaçu/PR',
          'latitude': '-25.450759',
          'longitude': '-54.555854',
          'unidade_consumidora': '35119624',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ebc',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.558132, -25.494672],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 298,
          'unidade_operacional': 'SAA COMPLEXO VILA A',
          'endereco': 'Tr Uacari, Foz do Iguaçu/PR',
          'latitude': '-25.494672',
          'longitude': '-54.558132',
          'unidade_consumidora': '44774494',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ebb',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.518551, -25.56927],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 299,
          'unidade_operacional': 'SAA EEB3 TAMANDUÁ',
          'endereco': 'Rua da Cosmoética, Foz do Iguaçu/PR',
          'latitude': '-25.569270',
          'longitude': '-54.518551',
          'unidade_consumidora': '82743339',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eba',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.524765, -25.501091],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 300,
          'unidade_operacional': 'SAA EET20 EET21 PORTAL',
          'endereco': 'Rua Urano, Foz do Iguaçu/PR',
          'latitude': '-25.501091',
          'longitude': '-54.524765',
          'unidade_consumidora': '42584418',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eb9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.558934, -25.535537],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 301,
          'unidade_operacional': 'SAA COMPLEXO 4000',
          'endereco': 'Av. Rep. Argentina, 3300, Foz do Iguaçu/PR',
          'latitude': '-25.535537',
          'longitude': '-54.558934',
          'unidade_consumidora': '80201555',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eb8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.589435, -25.561639],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 302,
          'unidade_operacional': 'SES ETE Ouro Verde',
          'endereco': 'Rua Otto Welter, 123, Foz do Iguaçu/PR',
          'latitude': '-25.561639',
          'longitude': '-54.589435',
          'unidade_consumidora': '37708678_BT_GD',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eb7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.075899, -25.304221],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 303,
          'unidade_operacional': 'SAA ETA 1',
          'endereco': 'R Paulo VI, 1711, Foz do Iguaçu/PR',
          'latitude': '-25.304221',
          'longitude': '-54.075899',
          'unidade_consumidora': '28528646',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eb6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.101877, -25.445142],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 91,
          'unidade_operacional': 'SES EEE IRAIZINHO',
          'endereco': 'Av. São Roque, Piraquara/PR',
          'latitude': '-25.445142',
          'longitude': '-49.101877',
          'unidade_consumidora': '74311875',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eb5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-49.854249, -24.162409],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 238,
          'unidade_operacional': 'POÇO 09',
          'endereco': 'ROD. MAL. CANDIDO RONDON, ARAPOTI',
          'latitude': '-24.162409',
          'longitude': '-49.854249',
          'unidade_consumidora': '12866',
          'concessionaria': 'CERAL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eb4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.748076, -25.683984],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 321,
          'unidade_operacional': 'SAA EEB01 CAPTACAO',
          'endereco':
            'LIN SANTA LUZIA - SANEPAR EEB01\nCAPTACAO - RURAL DE PLANALTO - PR',
          'latitude': '-25.683984',
          'longitude': '-53.748076',
          'unidade_consumidora': '43036830',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eb3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.077821, -25.750711],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 322,
          'unidade_operacional': 'SAA EEB01 CSB02 CAPTACAO',
          'endereco':
            'VL DOIS VIZINHOS - SANEPAR EEB01 CSB02\nCAPTACAO - RURAL DE DOIS VIZINHOS - PR',
          'latitude': '-25.750711',
          'longitude': '-53.077821',
          'unidade_consumidora': '44502931',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eb2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.395966, -25.646314],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 323,
          'unidade_operacional': 'SAA EEB01 CAPTACAO',
          'endereco':
            'LIN SANTA CRUZ - SANEPAR EEB01\nCAPTACAO - RURAL DE NOVA PRATA DO\nIGUACU - PR',
          'latitude': '-25.646314',
          'longitude': '-53.395966',
          'unidade_consumidora': '43875718',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eb1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.083578, -25.299092],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 304,
          'unidade_operacional': 'SAA CR CENTRAL',
          'endereco': 'R Getúlio Vargas, 2491, Medianeira',
          'latitude': '-25.299092',
          'longitude': '-54.083578',
          'unidade_consumidora': '68904401',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eb0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.592306, -25.5455],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 305,
          'unidade_operacional': 'SES EEE 07 1',
          'endereco': 'Al. Dez de Junho, Medianeira',
          'latitude': '-25.545500',
          'longitude': '-54.592306',
          'unidade_consumidora': '72858710',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eaf',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.53442, -25.47942],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 306,
          'unidade_operacional': 'FOZ DO IGUACU - ETE 08 TRES LAGOAS',
          'endereco': 'LIN GLB GUARANI - SANEPAR ETE 08 TRES\nLAGOAS',
          'latitude': '-25.47942',
          'longitude': '-54.53442',
          'unidade_consumidora': '64669360',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eae',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.49964478, -25.48300277],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 307,
          'unidade_operacional': 'FOZ DO IGUACU - CR TRES LAGOAS',
          'endereco': 'R PERI SIQUEIRA CERCAL - SANEPAR CR TRES\nLAGOAS',
          'latitude': '-25.483002774432094',
          'longitude': '-54.49964478300966',
          'unidade_consumidora': '68376391',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ead',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.12276148, -25.28225317],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 308,
          'unidade_operacional': 'MEDIANEIRA - PEW 07767 2020 CSB 06 JD\nIRENE',
          'endereco': 'R VALDECIR SOARES, 1 - PEW 07767 2020 CSB\n06 JD IRENE',
          'latitude': '-25.282253170518768',
          'longitude': '-54.12276147649573',
          'unidade_consumidora': '106755030',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eac',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.32479027, -24.87348417],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 309,
          'unidade_operacional': 'SANTA HELENA - CSB1',
          'endereco': 'LIN GAUCHA - SANEPAR CSB1',
          'latitude': '-24.8734841728365',
          'longitude': '-54.32479027200704',
          'unidade_consumidora': '68902824',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eab',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.25082609, -25.34149662],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 310,
          'unidade_operacional': 'SAO MIGUEL DO IGUACU - CSB 2',
          'endereco': 'R J K DE OLIVEIRA - SANEPAR CSB 2',
          'latitude': '-25.341496622991325',
          'longitude': '-54.250826085123386',
          'unidade_consumidora': '35766514',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7eaa',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.26547755, -25.33686524],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 311,
          'unidade_operacional': 'SAO MIGUEL DO IGUACU - CSB 05',
          'endereco': 'LIN PIAZZA - SANEPAR CSB 05',
          'latitude': '-25.336865236602108',
          'longitude': '-54.26547755233808',
          'unidade_consumidora': '41755138',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ea9',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.23220501, -25.33529966],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 312,
          'unidade_operacional': 'SAO MIGUEL DO IGUACU - CSB 4 HORTO\nMUN',
          'endereco': 'R ALBINO MARCON - SANEPAR CSB 4 HORTO\nMUN',
          'latitude': '-25.335299657452143',
          'longitude': '-54.23220500856482',
          'unidade_consumidora': '43814166',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ea8',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.24887737, -25.35793335],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 313,
          'unidade_operacional': 'SAO MIGUEL DO IGUACU - CSB 1 PROX\nBR277',
          'endereco': 'R JOSE BORTOLAZZI - SANEPAR CSB 1 PROX\nBR277',
          'latitude': '-25.357933351653543',
          'longitude': '-54.248877367809115',
          'unidade_consumidora': '68906161',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ea7',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.26904166, -25.33180639],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 314,
          'unidade_operacional': 'SAO MIGUEL DO IGUACU - ETE 01 TRAT\nESGOTO',
          'endereco': 'LIN MINOSSO - SANEPAR ETE 01 TRAT ESGOTO',
          'latitude': '-25.331806387163432',
          'longitude': '-54.269041663912574',
          'unidade_consumidora': '70447330',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ea6',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.23143611, -25.06990121],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 315,
          'unidade_operacional': 'MISSAL - CSB 3',
          'endereco': 'LIN PE RICK - SANEPAR CSB 3',
          'latitude': '-25.06990121471751',
          'longitude': '-54.23143610913443',
          'unidade_consumidora': '97837350',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ea5',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.38720497, -25.42785145],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 316,
          'unidade_operacional': 'SANTA TEREZINHA ITAIPU - CSB 3',
          'endereco': 'R GETULIO VARGAS - SANEPAR CSB 3',
          'latitude': '-25.4278514506198',
          'longitude': '-54.38720496715795',
          'unidade_consumidora': '45602387',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ea4',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.43424782, -25.45521455],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 317,
          'unidade_operacional': 'SANTA TEREZINHA ITAIPU - CSB 6',
          'endereco': 'ROD BR 277 - SANEPAR CSB 6',
          'latitude': '-25.4552145523828',
          'longitude': '-54.434247822833825',
          'unidade_consumidora': '50940694',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ea3',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.44613903, -25.45009947],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 318,
          'unidade_operacional': 'SANTA TEREZINHA ITAIPU - CSB 7',
          'endereco': 'ROD BR 277 - SANEPAR CSB 7',
          'latitude': '-25.450099473913035',
          'longitude': '-54.44613903157515',
          'unidade_consumidora': '82744181',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ea2',
        'geometry': {
          'type': 'Point',
          'coordinates': [-54.38677581, -25.42897543],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 319,
          'unidade_operacional': 'SANTA TEREZINHA ITAIPU - CSB 4',
          'endereco': 'R GETULIO VARGAS - SANEPAR CSB 4',
          'latitude': '-25.428975427136184',
          'longitude': '-54.3867758137329',
          'unidade_consumidora': '45603391',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ea1',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.06386, -26.083536],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 324,
          'unidade_operacional': 'SAA ETA01 EET01 EET21',
          'endereco':
            'R ANTONIO CARNEIRO NETO - SANEPAR\nETA01 EET01 EET21 ALVORADA - FRANCISCO\nBELTRAO - PR',
          'latitude': '-26.083536',
          'longitude': '-53.063860',
          'unidade_consumidora': '32403011',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7ea0',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.047571, -26.069111],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 325,
          'unidade_operacional': 'ETE MARRECAS',
          'endereco': 'RUA XEREM - FRANCICO BELTRÃO',
          'latitude': '-26.069111',
          'longitude': '-53.047571',
          'unidade_consumidora': '83623256',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7e9f',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.170034, -25.933462],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 326,
          'unidade_operacional': 'CSP 01 ETA - EET 01 - ESCRITÓRIO',
          'endereco': 'LINHA ENÉAS MARQUES - RURAL - ENÉAS\nMARQUES',
          'latitude': '-25.933462',
          'longitude': '-53.170034',
          'unidade_consumidora': '22162330',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7e9e',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.744007, -25.843638],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 327,
          'unidade_operacional': 'RAP 01 - EET 01',
          'endereco': "LINHA KM 31 RAP 01 - EET 01 - PEROLA\nD'OESTE",
          'latitude': '-25.843638',
          'longitude': '-53.744007',
          'unidade_consumidora': '48651940',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7e9d',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.974072, -26.170075],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 328,
          'unidade_operacional': 'CSB - 03',
          'endereco': 'LINHA ALTO ALEGRE - RENASCENÇA',
          'latitude': '-26.170075',
          'longitude': '-52.974072',
          'unidade_consumidora': '63137399',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7e9c',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.468796, -25.821726],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 329,
          'unidade_operacional': 'ETA - 01',
          'endereco': 'RUA JACARANDA - SANTA IZABEL DO OESTE',
          'latitude': '-25.821726',
          'longitude': '-53.468796',
          'unidade_consumidora': '55316883',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7e9b',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.117534, -25.614488],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 330,
          'unidade_operacional': 'CSB - 02',
          'endereco': 'VILA CRUZEIRO - CRUZEIRO DO IGUAÇU',
          'latitude': '-25.614488',
          'longitude': '-53.117534',
          'unidade_consumidora': '73887692',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7e9a',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.588429, -26.283734],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 331,
          'unidade_operacional': 'SAA RIO PTO EAB 01 EAB 02\nCAPTACAO E',
          'endereco':
            'LIN FAZENDA DA BARRA - RIO PTO EAB 01 EAB\n02 CAPTACAO - PATO BRANCO - PR',
          'latitude': '-26.283734',
          'longitude': '-52.588429',
          'unidade_consumidora': '33235953',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7e99',
        'geometry': {
          'type': 'Point',
          'coordinates': [-51.978606, -26.500956],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 332,
          'unidade_operacional': 'SAA EEB1',
          'endereco':
            'AV UBIRAJARA ARAUJO - SANEPAR EEB1\nKLUBEGI - PALMAS - PR',
          'latitude': '-26.500956',
          'longitude': '-51.978606',
          'unidade_consumidora': '86207563',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7e98',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.675854, -26.240195],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 333,
          'unidade_operacional': 'SAA PE 00118 2018 EAR 04 J\nPESSOA E8',
          'endereco':
            'R JOAO PESSOA - PE 00118 2018 EAR 04 J\nPESSOA CENTRO - PATO BRANCO - PR',
          'latitude': '-26.240195',
          'longitude': '-52.675854',
          'unidade_consumidora': '48339610',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7e97',
        'geometry': {
          'type': 'Point',
          'coordinates': [-52.18706, -25.938764],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 334,
          'unidade_operacional': 'SAA EET1 EEB1 ETA',
          'endereco':
            'LIN VILA NOVA - SANEPAR EET1 EEB1 ETA -RURAL DE MANGUEIRINHA - PR',
          'latitude': '-25.938764',
          'longitude': '-52.187060',
          'unidade_consumidora': '49457012',
          'concessionaria': 'COPEL',
        },
      },
      {
        'type': 'Feature',
        'id': 'vm_pontos_geo.fid-deb450b_188d329d6f6_-7e96',
        'geometry': {
          'type': 'Point',
          'coordinates': [-53.074781, -26.081493],
        },
        'geometry_name': 'geog',
        'properties': {
          'id': 320,
          'unidade_operacional': 'SAA EEB01 CAPTACAO',
          'endereco':
            'R BELTRAO - SANEPAR EEB01 CAPTACAO\nMARRECAS - FRANCISCO BELTRAO - PR',
          'latitude': '-26.081493',
          'longitude': '-53.074781',
          'unidade_consumidora': '32627572',
          'concessionaria': 'COPEL',
        },
      },
    ],
    'totalFeatures': 362,
    'numberMatched': 362,
    'numberReturned': 362,
    'timeStamp': '2023-06-19T14:12:16.441Z',
    'crs': {
      'type': 'name',
      'properties': {
        'name': 'urn:ogc:def:crs:EPSG::4326',
      },
    },
  },
];
