import React, { createContext, useContext, useState, useEffect } from 'react';
import LocalStorage from 'common/local_storage_api';

const ClientContext = createContext({});

export default function ClientProvider(props) {
  const [client, setClientContext] = useState(null);
  const [unity, setUnityContext] = useState(null);

  useEffect(() => {
    verifyStorageData();
  }, []);

  const verifyStorageData = async () => {
    const storageClient = await LocalStorage.getClientData();
    const storageUnity = await LocalStorage.getUnityData();

    storageClient && setClientContext(storageClient);
    storageUnity && setUnityContext(storageUnity);
  };

  const setClient = async (clientData) => {
    setClientContext(clientData);
    await LocalStorage.storageClientData(clientData);
  };

  const setUnity = async (unityData) => {
    setUnityContext(unityData);
    await LocalStorage.storageUnityData(unityData);
  };

  return (
    <ClientContext.Provider value={{ client, setClient, unity, setUnity }}>
      {props.children}
    </ClientContext.Provider>
  );
}

export function useClient() {
  const context = useContext(ClientContext);
  const { client, setClient } = context;
  return { client, setClient };
}

export function useUnity() {
  const context = useContext(ClientContext);
  const { unity, setUnity } = context;
  return { unity, setUnity };
}
