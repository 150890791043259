import styled from 'styled-components';

//Import from Material UI Components
import { Button, Typography, Grid } from '@material-ui/core';

//Images
import PhotoCover from './img/temporaria.jpg';

//Import from React
import { Link } from 'react-router-dom';

//Grids
export const GridMain = styled(Grid).attrs(() => ({
  container: true,
}))`
  height: 100vh;
`;

export const GridImage = styled(Grid).attrs(() => ({
  item: true,
  xs: false,
  sm: 4,
  md: 7,
}))`
  background-image: url(${PhotoCover});
  background-repeat: no-repeat;
  background-color: var(--base);
  background-size: cover;
  background-position: 'center';
`;

export const GridContent = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  sm: 8,
  md: 5,
}))`
  background-image: linear-gradient(to top, #faf7eb 10%, #015a9e, #015a9e);
`;

//Images
export const Illustration = styled.img.attrs((props) => ({
  src: props.image,
}))`
  background-size: contain;
  height: 150px;
  width: 240px;
`;

//Typography
export const ErroText = styled(Typography)`
  color: var(--alert);
  margin-top: 24px;
  font-weight: bold;
  text-align: center;
`;

export const Information = styled(Typography)`
  margin: 2% 0%;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
`;

//Others
export const ButtonConfirmation = styled(Button).attrs(() => ({
  type: 'submit',
  fullWidth: true,
  variant: 'contained',
}))`
  background-color: var(--primary);
  margin: 32px 0px 8px;

  &:hover {
    background-color: var(--base);
    span {
      color: var(--primary);
    }
  }

  span {
    color: var(--secondary);
  }
`;

export const BoxLoginContent = styled.div`
  margin: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;

  @media (min-width: 2000px) {
    margin: 10% 20%;
  }
`;

export const Form = styled.form`
  width: 100%;
  text-align: left;
`;

export const LinkForgotPassword = styled(Link)`
  color: var(--alert);
  &:hover {
    font-weight: bold;
  }
`;
