import React from 'react';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Modal, Grow } from '@material-ui/core';

import {
  TextFieldStyled,
  IoseAlertErroModal,
  IoseAlertSuccessModal,
  IoseModalButton,
} from 'components';

import * as S from './style.js';
import validationsForm from 'common/ValidationsForms.js';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function IoseSolarEcuEditModal({
  open,
  onClose,
  loading,
  notificationType,
  showNotification,
  submit,
  selectedCard,
  editNotification,
}) {
  const classes = useStyles();

  const handleUpdateSolarEcu = (values) => {
    setTimeout(() => {
      const { ecu_number } = values;
      submit(selectedCard.uuid_ecu, ecu_number);
    }, 500);
  };

  const solarEcuSchema = Yup.object().shape({
    ecu_number: validationsForm.number_required,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <div>
          {/* Cabeçalho */}
          <S.StyledHeaderModal>
            <S.StyledLeftHeaderModal>
              <S.RoomIcon />
            </S.StyledLeftHeaderModal>
            <S.StyledRightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                SOLAR - ECU's
              </S.ModalTitleHeader>
            </S.StyledRightHeaderModal>
          </S.StyledHeaderModal>

          {/* Formulário */}
          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <S.ModalTitle variant="h5" gutterBottom>
                EDITAR ECU's
              </S.ModalTitle>
              <Formik
                initialValues={{
                  ecu_number: selectedCard?.ecu_number,
                }}
                validationSchema={solarEcuSchema}
                onSubmit={handleUpdateSolarEcu}
              >
                {({
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  errors,
                  touched,
                  initialValues,
                }) => (
                  <Form>
                    <S.DivInputs marginBottom="10px">
                      <S.StyledField
                        component={TextFieldStyled}
                        fullWidth
                        id={'ecu_number'}
                        name={'ecu_number'}
                        type={'text'}
                        label={'Número da ECU'}
                        defaultvalue={initialValues['ecu_number']}
                        onchange={handleChange}
                        onblur={handleBlur}
                        helpertext={
                          errors['ecu_number'] && touched['ecu_number']
                            ? errors['ecu_number']
                            : ''
                        }
                      />
                    </S.DivInputs>

                    {loading ? (
                      <S.DivCircularProgress>
                        <S.CircularProgressStyled />
                      </S.DivCircularProgress>
                    ) : showNotification ? (
                      <>
                        {notificationType === 1 && (
                          <IoseAlertSuccessModal text={editNotification} />
                        )}

                        {notificationType === 2 && (
                          <IoseAlertErroModal text={editNotification} />
                        )}
                      </>
                    ) : (
                      <S.ButtonContainer>
                        <IoseModalButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isSubmitting}
                        >
                          Editar
                        </IoseModalButton>
                      </S.ButtonContainer>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </S.StyledWrappedModal>
        </div>
      </Grow>
    </Modal>
  );
}
