import React from 'react';
import { Modal, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { IoseSelection, IoseCircuitModalChart } from 'components';

import * as S from './style';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#015A9E',
    color: 'white',
    height: '400px',
    marginTop: '15px',
  },
  whiteText: {
    color: 'white',
  },
}));

export default function IoseCircuitChartModal({
  open,
  onClose,
  selectedCard,
  chartMode,
  setChartMode,
}) {
  const classes = useStyles();

  const renderChartModalHeader = () => {
    return (
      <>
        <S.HeaderContainer>
          <S.IconValue>
            <S.UnityIcon />
            <S.TypographyTitle>{selectedCard?.unity_name}</S.TypographyTitle>
          </S.IconValue>
          <S.ChartTypeContainer>
            <p>Tipo de gráfico: </p>
            <IoseSelection
              title=""
              object={['Acumulado', 'Diário']}
              defaultvalue={chartMode}
              func={setChartMode}
              top={'0px'}
            />
          </S.ChartTypeContainer>
        </S.HeaderContainer>
      </>
    );
  };

  const renderChart = () => {
    return (
      <>
        <IoseCircuitModalChart
          selectedCard={selectedCard}
          chartMode={chartMode}
        />
      </>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Slide
        direction="down"
        in={open}
        timeout={600}
        mountOnEnter
        unmountOnExit
      >
        <div>
          {/* Cabeçalho */}
          <S.HeaderModal>
            <S.LeftHeaderModal>
              <S.CircuitsIcon />
            </S.LeftHeaderModal>
            <S.RightHeaderModal>
              <S.ModalTitleHeader variant="h5" gutterBottom>
                {selectedCard?.uuid_circuit}
              </S.ModalTitleHeader>
            </S.RightHeaderModal>
          </S.HeaderModal>

          {/* Formulário */}
          <S.WrappedModal>
            <S.ObjectPaper>
              {renderChartModalHeader()}
              {renderChart()}
            </S.ObjectPaper>
          </S.WrappedModal>
        </div>
      </Slide>
    </Modal>
  );
}
