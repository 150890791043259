import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import * as S from './style';

import { IoseBackButton } from 'components/IoseButtonIcon';

import Permissions from 'common/permissions';

import SpeckleViewer from 'components/SpeckleViewer';
import { mapToMeasurement } from 'models/measurement';
import OffCanvas from 'components/OffCanvas';
import { AccountContext, LocalStorage } from 'common';
import { useGroupPermissions, useObjects } from 'hooks';
import { IoseAlertLoadingCards } from 'components';
import { getCircuitUUIDFromObjectUUID } from 'common/mappings/deviceMapping';
import { ioseIotAPI } from 'common/ioseIotAPI';

export default function Viewer3dContainer() {
  const { state } = useLocation();
  const { unityData } = state;
  const { bin_link } = unityData;

  const history = useHistory();
  const userSession = LocalStorage.getSession();
  const { groupPermissions } = useGroupPermissions();
  const { objects, device3dMapping } = useObjects();
  const { getSession } = useContext(AccountContext);

  const [error, setError] = useState('');
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [latestMeasurements, setLatestMeasurements] = useState();
  const [selectedMeasurement, setSelectedMeasurement] = useState();
  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const readPath = ['dashboards', '3d', 'leitura'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    render3dViewer();
    //eslint-disable-next-line
  }, [latestMeasurements]);

  async function getCurrentMeasure(circuit) {
    try {
      const { headers } = await getSession();

      const data = await ioseIotAPI.getCurrentMeasure(headers, circuit);

      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async function getLatestMeasurements() {
    // Use Promise.all to wait for all promises to resolve
    const responses = await Promise.all(
      device3dMapping?.map((device) => getCurrentMeasure(device.uuid_circuit))
    );

    // Extract and map relevant data from each response
    const data = responses.map((response) => {
      const measurementData = response.data.data.lastMeasure;
      return mapToMeasurement(measurementData);
    });

    return data;
  }

  useEffect(() => {
    if (device3dMapping) {
      getLatestMeasurements().then((lm) => {
        setLatestMeasurements(lm);
      });
    }

    //eslint-disable-next-line
  }, []);

  const onHideOffCanvas = () => {
    return setShowOffCanvas(false);
  };

  useEffect(() => {
    const socket = new WebSocket(
      'wss://kinazamel2.execute-api.us-east-1.amazonaws.com/production/'
    );

    socket.addEventListener('open', (event) => {});

    socket.addEventListener('message', (event) => {
      getLatestMeasurements().then((lm) => {
        setLatestMeasurements(lm);
      });
    });

    socket.addEventListener('close', (event) => {});

    return () => {
      socket.close();
    };
    //eslint-disable-next-line
  }, []);

  const onElementSelected = (objectUUID) => {
    const circuitUUID = getCircuitUUIDFromObjectUUID(
      objectUUID,
      device3dMapping
    );

    const objectInfo = objects?.find(
      (object) => object.uuid_circuit === circuitUUID
    );

    setLatestMeasurements((prevLatestMeasurements) => {
      const measurementIndex = prevLatestMeasurements.findIndex(
        (m) => m.uuid_circuit === circuitUUID
      );

      if (measurementIndex !== -1) {
        const measurementWithoutObjectInfo = {
          ...prevLatestMeasurements[measurementIndex],
        };

        const measureInfo = {
          ...measurementWithoutObjectInfo,
        };

        const combinedArray = [{ measureInfo, objectInfo }];

        setSelectedMeasurement(combinedArray);
        setShowOffCanvas(true);
      }

      return prevLatestMeasurements;
    });
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      // <IoseSubHeaderBar
      //   title={clientName}
      //   subtitle={`Visualizador 3D da unidade ${unityData.name}`}
      //   // description={'Total: ' + cards?.length}
      //   button={<></>}
      // >

      // </IoseSubHeaderBar>

      <S.SubHeaderDiv>
        <IoseBackButton onClick={() => goBack()} />
      </S.SubHeaderDiv>
    );
  };

  const render3dViewer = () => {
    return (
      <SpeckleViewer
        data={latestMeasurements}
        onElementSelected={onElementSelected}
        endpoint={bin_link}
      />
    );
  };

  const renderJSXForMeasurement = (selectedMeasurement) => {
    if (!selectedMeasurement || selectedMeasurement.length === 0) return <></>;

    const measureKeysToRemove = [
      'offset_eae',
      'offset_ere',
      'offset_iae',
      'offset_ire',
    ];

    const objectKeysToRemove = [
      'uuid_circuit',
      'name',
      'update_at',
      'id_3d',
      'uuid_environment',
    ];

    const keyMappings = {
      index: 'Índice da medida',
      reactive_power: 'Potência reativa lida (VAr)',
      status_measure: 'Status da medição',
      uuid_measure: 'UUID da medição',
      current: 'Corrente (A)',
      import_reactive_energy: 'Medida da importação de energia reativa (Wh)',
      thermistor_voltage: 'Temperatura interna do Módulo',
      line_frequency: 'Frequência da rede elétrica medida (Hz)',
      export_reactive_energy: 'Energia reativa exportada lida (Wh)',
      voltage: 'Tensão RMS da fase medida (V)',
      export_active_energy: 'Energia ativa exportada lida (Wh)',
      relay_state: 'Estado atual do relé',
      import_active_energy: 'Energia ativa consumida lida (Wh)',
      apparent_power: 'Potência aparente lida (VA)',
      active_power: 'Potência aparente lida (VA)',
      power_factor: 'Fator de potência lido pelo sensor',
      uuid_circuit: 'UUID do módulo',
      created_at: 'Hora de criação do payload',
      counter: 'Medida do contador',
      system_status: 'Número do status do sistema',
      arrival_date: 'Hora de registro do payload',
      uuid_object: 'UUID do objeto',
      condition: 'Condição do objeto',
      temperature: 'Temperatura de funcionamento (C°)',
      power: 'Potência (W)',
      charge_type: 'Tipo da Carga',
    };

    return (
      <div>
        {selectedMeasurement.map(({ measureInfo, objectInfo }, index) => {
          if (!measureInfo) return null;

          const filteredMeasurement = Object.fromEntries(
            Object.entries(measureInfo).filter(
              ([key]) => !measureKeysToRemove.includes(key)
            )
          );

          const filteredObject = Object.fromEntries(
            Object.entries(objectInfo).filter(
              ([key]) => !objectKeysToRemove.includes(key)
            )
          );

          return (
            <div key={index}>
              <S.Title>{`ÚLTIMA MEDIÇÃO DO CIRCUITO: ${measureInfo.uuid_circuit}`}</S.Title>

              <S.ListDiv>
                {Object.entries(filteredMeasurement).map(([key, value]) => {
                  const displayName = keyMappings[key] || key;

                  return (
                    <S.SpanLine key={key + value}>
                      <strong>{displayName}</strong>: <span>{value}</span>
                      <br />
                    </S.SpanLine>
                  );
                })}
              </S.ListDiv>

              <S.Title>{`DADOS DO OBJETO: ${objectInfo.name}`}</S.Title>

              <S.ListDiv>
                {Object.entries(filteredObject).map(([key, value]) => {
                  const displayName = keyMappings[key] || key;

                  return (
                    <S.SpanLine key={key + value}>
                      <strong>{displayName}</strong>: <span>{value}</span>
                      <br />
                    </S.SpanLine>
                  );
                })}
              </S.ListDiv>
            </div>
          );
        })}
      </div>
    );
  };

  const renderOffCanvas = () => {
    return (
      <OffCanvas
        name={selectedMeasurement?.uuid_circuit}
        show={showOffCanvas}
        onHide={onHideOffCanvas}
      >
        {renderJSXForMeasurement(selectedMeasurement)}
      </OffCanvas>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          {hasReadPermission
            ? render3dViewer()
            : setError(
                'Seu grupo de usuários não tem permissão de visualização destes dados!'
              )}
        </>
      );
    }
  };

  return (
    <S.WrapContainer>
      {renderSubHeaderAndSearchBar()}
      {renderContent()}
      {renderOffCanvas()}
    </S.WrapContainer>
  );
}
