import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useCreateLogs } from 'hooks';

const useDeleteConsumerGroup = () => {
  const [deleteNotification, setDeleteNotification] = useState('');
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();

  const deleteConsumerGroup = async (uuid_consumer_group) => {
    setDeleteNotification(0);
    try {
      const { headers } = await getSession();

      const response = await ioseAPI.deleteConsumerGroupV2(
        headers,
        uuid_consumer_group
      );

      setDeleteNotification(response.data.message);

      const operacao = 'DELETAR';
      const form = 'DELETAR UNIDADE CONSUMIDORA';
      const logContent = {
        uuid_consumer_group: uuid_consumer_group,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.concessionairesNoRegistered;
      setDeleteErrorMessage(errorMessage);
    }
  };

  return {
    deleteConsumerGroup,
    deleteNotification,
    deleteErrorMessage,
  };
};

export default useDeleteConsumerGroup;
