import Exception from 'common/exceptions';
import POOL_DATA from 'common/Cognito_config';

const {
  CognitoRefreshToken,
  CognitoUser,
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoUserSession,
} = require('amazon-cognito-identity-js');

const userPool = POOL_DATA;

const IoseCognito = {};

IoseCognito.isLogged = () => {
  const currentUser = userPool.getCurrentUser();

  if (currentUser) {
    return true;
  } else {
    return false;
  }
};

IoseCognito.getSessionParams = () => {
  return new Promise((resolve, reject) => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.getSession(async (err, session) => {
        if (err) {
          reject(Exception.CanNotGetSessionException);
        } else {
          const data = session.idToken.payload;

          const username = data['cognito:username'];
          const groups = data['cognito:groups'];
          const name = data['custom:user_nick'] || data.given_name;

          if (username.includes('SANEPAR')) {
            // Verifica se o usuário tem mais de um grupo, excluindo "us-east-1_hWmApDv4W_SANEPAR"
            if (
              groups.length > 1 &&
              groups.includes('us-east-1_hWmApDv4W_SANEPAR')
            ) {
              const filteredGroups = groups.filter(
                (group) => group !== 'us-east-1_hWmApDv4W_SANEPAR'
              );

              resolve({
                group: filteredGroups[0],
                userId: username,
                email: data.email,
                name: name,
              });
            } else {
              // Caso o usuário tenha apenas o grupo "us-east-1_hWmApDv4W_SANEPAR"
              resolve({
                group: 'visualizador',
                userId: username,
                email: data.email,
                name: name,
              });
            }
          } else {
            // Caso o username não inclua 'SANEPAR'
            resolve({
              group: groups[0],
              userId: username,
              email: data.email,
              name: name,
            });
          }
        }
      });
    } else {
      reject(Exception.NotLoggedException);
    }
  });
};

IoseCognito.logout = () => {
  const user = userPool.getCurrentUser();

  if (user) {
    user.signOut();
  }
};

IoseCognito.login = (email, password) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        resolve({
          accessToken: data.getAccessToken().getJwtToken(),
          idToken: data.getIdToken().getJwtToken(),
          refreshToken: data.getRefreshToken().getToken(),
          expiration: data.getIdToken().getExpiration(),
        });
      },
      onFailure: (err) => {
        switch (err.code) {
          case 'UserNotFoundException':
            reject(Exception.NotAuthorizedException);
            break;
          case 'InvalidParameterException':
            reject(Exception.InvalidParameterException);
            break;
          case 'NotAuthorizedException':
            reject(Exception.NotAuthorizedException);
            break;
          default:
            reject(err);
            break;
        }
      },
      newPasswordRequired: (data) => {
        delete data.email_verified;
        delete data.email;

        resolve({
          status: 'newPasswordRequired',
          sessionUserAttributes: data,
          user: user,
        });
      },
    });
  });
};

IoseCognito.checkTokenExpiration = async (expiration, email, refreshToken) => {
  return new Promise((resolve, reject) => {
    const currentDate = new Date();
    const expirationDate = new Date(expiration * 1000);

    const RefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken,
    });

    if (currentDate > expirationDate) {
      const user = new CognitoUser({
        Username: email,
        Pool: userPool,
      });

      user.refreshSession(RefreshToken, (err, session) => {
        if (err) {
          // reject(err.message);
          reject(Exception.ErrorRefreshToken);
        } else {
          const newTokenData = {
            accessToken: session.getAccessToken().getJwtToken(),
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken(),
            expiration: session.getIdToken().getExpiration(),
          };

          resolve({ status: true, error: false, data: newTokenData });
        }
      });
    } else {
      resolve({ status: false });
    }
  });
};

IoseCognito.doChangePassword = (newPassword, sessionAttributes, user) => {
  return new Promise((resolve, reject) => {
    user.completeNewPasswordChallenge(newPassword, sessionAttributes, {
      onSuccess: (data) => {
        resolve(true);
      },
      onFailure: (err) => {
        switch (err.code) {
          case 'UserNotFoundException':
            reject(Exception.NotAuthorizedException);
            break;
          case 'InvalidParameterException':
            reject(Exception.InvalidParameterException);
            break;
          case 'NotAuthorizedException':
            reject(Exception.NotAuthorizedException);
            break;
          case 'InvalidPasswordException':
            reject(Exception.PasswordFormatInvalid);
            break;
          default:
            reject(err);
            break;
        }
      },
    });
  });
};

IoseCognito.sendCode = (email) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.forgotPassword({
      onSuccess: (data) => {
        resolve(data);
      },
      onFailure: (err) => {
        switch (err.code) {
          case 'UserNotFoundException':
            reject(Exception.UserNotFoundException);
            break;
          case 'InvalidParameterException':
            reject(Exception.InvalidParameterException);
            break;
          case 'Attempt limit exceeded, please try after some time.':
            reject(Exception.AttemptLimitExceeded);
            break;
          default:
            reject(err);
            break;
        }
      },

      inputVerificationCode: (data) => {
        resolve(data);
      },
    });
  });
};

IoseCognito.resetPassword = (email, code, password, confirmationPassword) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    if (password !== confirmationPassword) {
      reject(Exception.notSamePasswords);
    } else {
      user.confirmPassword(code, password, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          console.error('onFailure: ', err);
          switch (err.code) {
            case 'CodeMismatchException':
              reject(Exception.InvalidCode);
              break;
            case 'UserNotFoundException':
              reject(Exception.UserNotFoundException);
              break;
            case 'InvalidParameterException':
              reject(Exception.PasswordFormatInvalid);
              break;
            case 'Attempt limit exceeded, please try after some time.':
              reject(Exception.AttemptLimitExceeded);
              break;
            default:
              reject(err);
              break;
          }
        },
      });
    }
  });
};

IoseCognito.initCognitoSession = (tokens, username) => {
  try {
    const accessToken = new CognitoAccessToken({
      AccessToken: tokens.accessToken,
    });

    const idToken = new CognitoIdToken({
      IdToken: tokens.idToken,
    });

    const refreshToken = new CognitoRefreshToken({
      RefreshToken: tokens.refreshToken,
    });

    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken,
    };

    const session = new CognitoUserSession(sessionData);

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: POOL_DATA,
    });

    cognitoUser.setSignInUserSession(session);

    return session;
  } catch (error) {
    console.error('Erro ao iniciar sessão no Cognito:', error);
  }
};

export default IoseCognito;
