// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useSystemVariables() {
  const [noSystemVariablesError, setNoSystemVariablesError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getSystemVariables = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllSystemVariables(headers, 3000, 300);

    const systemVariables = response.data.data || [];

    if (systemVariables.length === 0) {
      setNoSystemVariablesError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return systemVariables;
  };

  const {
    data: systemVariables,
    error: systemVariablesError,
    refetch: refreshSystemVariables,
    isLoading,
  } = useQuery(['systemVariables'], getSystemVariables);

  return {
    systemVariables,
    systemVariablesError,
    noSystemVariablesError,
    refreshSystemVariables,
    isLoading,
  };
}

export default useSystemVariables;
