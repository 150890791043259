import React from 'react';

import { BatteryChargingFull as BatteryChargingFullIcon } from '@material-ui/icons';
import { useCreditConnections } from 'hooks';

import * as S from './style';

import { LoadingValueCards } from 'components';

const formatNumber = (number) => {
  const formattedNumber = number.toLocaleString();

  return formattedNumber;
};

export default function IoseCardCreditDash({ loading, auditExtract }) {
  const { creditConnectionData } = useCreditConnections();
  // const { consumerGroups } = useConsumerGroup();

  const creditConnection = creditConnectionData ? creditConnectionData : [];

  const geradora = creditConnection?.filter((item) => item.geradora === 'SIM');
  const uniqueGeradoras = [
    ...new Set(geradora?.map((item) => item.uc_geradora)),
  ].length;

  const beneficiaria = creditConnection?.filter(
    (item) => item.beneficiaria_ativa === 'SIM'
  ).length;

  // const beneficiaria_inativa = consumer?.filter(
  //   (item) => item.beneficiaria === 'SIM' && item.beneficiaria_ativa === 'NAO'
  // ).length;
  const beneficiaria_inativa2 = creditConnection?.filter(
    (item) => item.beneficiaria_ativa === 'NAO'
  ).length;

  const cardsData = [
    {
      icon: <BatteryChargingFullIcon />,
      title: 'UNIDADES GERADORAS',
      data: [
        {
          key: 'TOTAL: ',
          value:
            creditConnection !== undefined
              ? `${formatNumber(uniqueGeradoras)} unidades`
              : 'Carregando...',
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <BatteryChargingFullIcon />,

      title: 'UNIDADES BENEFICIÁRIAS',
      data: [
        {
          key: 'TOTAL ATIVAS: ',
          value:
            beneficiaria !== undefined
              ? `${formatNumber(beneficiaria)} unidades`
              : 'Carregando...',
        },
        {
          key: 'TOTAL INATIVAS: ',
          value:
            beneficiaria !== undefined
              ? `${formatNumber(beneficiaria_inativa2)} unidades`
              : 'Carregando...',
        },
      ],
      bgColor: 'var(--base)',
    },
    // {
    //   icon: <BatteryChargingFullIcon />,

    //   title: 'UNIDADES BENEFICIÁRIAS',
    //   data: [
    //     {
    //       key: 'TOTAL ATIVAS: ',
    //       value:
    //         beneficiaria !== undefined
    //           ? `${formatNumber(beneficiaria)} unidades`
    //           : 'Carregando...',
    //     },
    //     {
    //       key: 'TOTAL INATIVAS: ',
    //       value:
    //         beneficiaria !== undefined
    //           ? `${formatNumber(beneficiaria_inativa2)} unidades`
    //           : 'Carregando...',
    //     },
    //   ],
    //   bgColor: 'var(--base)',
    // },
  ];

  return (
    <S.CardGrid container spacing={2}>
      {cardsData.map(({ icon, chart, title, data, bgColor }) => (
        <S.BoxContainer
          key={title}
          style={{ transition: '0.5s ease', backgroundColor: bgColor }}
        >
          <S.HeaderContainer>
            <S.IconHeader>{icon}</S.IconHeader>
            <S.CardTitle>{title}</S.CardTitle>
          </S.HeaderContainer>
          {data?.map(({ key, value }) => (
            <S.DataContainer key={key}>
              <S.CardDataKey>{key}</S.CardDataKey>
              {loading ? (
                <LoadingValueCards loading={loading} />
              ) : (
                <S.CardDataValue>{value}</S.CardDataValue>
              )}
            </S.DataContainer>
          ))}
          {chart}
        </S.BoxContainer>
      ))}
    </S.CardGrid>
  );
}
