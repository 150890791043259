import styled from 'styled-components';
import { Grid, Card } from '@material-ui/core/';

export const ContainerCards = styled(Grid).attrs((props) => ({
  container: true,
  justify: 'center',
}))``;

export const WrapContainer = styled.div`
  padding: 0px 15px 5px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SubHeaderDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
`;

export const Title = styled.h2`
  margin-bottom: 5px;
`;

export const ListDiv = styled(Card)`
  margin-bottom: 15px;
  background-color: #ecf0f5;
  padding: 10px;
  border-radius: 10px;
`;

export const SpanLine = styled.span`
  margin-bottom: 10px;
`;
