import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useCreateLogs } from 'hooks';

const useEditConsumerGroup = () => {
  const [editNotification, setEditNotification] = useState('');
  const [editErrorMessage, setEditErrorMessage] = useState('');

  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();

  const editConsumerGroup = async (
    uuid_consumer_group,
    concessionaire,
    identification_number,
    contract,
    operating_unity,
    general_management,
    regional_management,
    coordinate_x,
    coordinate_y,
    address,
    city,
    carbon_credit,
    telemetry,
    meter,
    consumer_group_load,
    atividade,
    cod_local,
    cod_unidade,
    descricao_unidade,
    cod_orgao_pagador,
    cogeracao,
    csbio,
    geradora,
    beneficiaria,
    potencia_transformador,
    subgrupo,
    modalidade,
    ativo,
    mercado_livre,
    startValidity,
    endValidity,
    startTest,
    endTest,
    constante,
    relogio_reset,
    demanda_fora,
    demanda_ponta,
    tensao
  ) => {
    setEditNotification(0);
    try {
      const { headers } = await getSession();

      const response = await ioseAPI.updateConsumerGroupV2(
        headers,
        uuid_consumer_group,
        concessionaire,
        identification_number,
        contract,
        operating_unity,
        general_management,
        regional_management,
        coordinate_x,
        coordinate_y,
        address,
        city,
        carbon_credit,
        telemetry,
        meter,
        consumer_group_load,
        atividade,
        cod_local,
        cod_unidade,
        descricao_unidade,
        cod_orgao_pagador,
        cogeracao,
        csbio,
        geradora,
        beneficiaria,
        potencia_transformador,
        subgrupo,
        modalidade,
        ativo,
        mercado_livre,
        startValidity,
        endValidity,
        startTest,
        endTest,
        constante,
        relogio_reset,
        demanda_fora,
        demanda_ponta,
        tensao
      );

      setEditNotification(response.data.message);

      const operacao = 'EDITAR';
      const form = 'EDITAR UNIDADE CONSUMIDORA';
      const logContent = {
        uuid_consumer_group: uuid_consumer_group,
        concessionaire: concessionaire,
        identification_number: identification_number,
        contract: contract,
        operating_unity: operating_unity,
        general_management: general_management,
        regional_management: regional_management,
        coordinate_x: coordinate_x,
        coordinate_y: coordinate_y,
        address: address,
        city: city,
        carbon_credit: carbon_credit,
        telemetry: telemetry,
        meter: meter,
        consumer_group_load: consumer_group_load,
        atividade: atividade,
        cod_local: cod_local,
        cod_unidade: cod_unidade,
        descricao_unidade: descricao_unidade,
        cod_orgao_pagador: cod_orgao_pagador,
        cogeracao: cogeracao,
        csbio: csbio,
        geradora: geradora,
        beneficiaria: beneficiaria,
        potencia_transformador: potencia_transformador,
        subgrupo: subgrupo,
        modalidade: modalidade,
        constante: constante,
        relogio_reset: relogio_reset,
        demanda_fora: demanda_fora,
        demanda_ponta: demanda_ponta,
        tensao: tensao,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.concessionairesNoRegistered;
      setEditErrorMessage(errorMessage);
    }
  };

  return {
    editConsumerGroup,
    editNotification,
    editErrorMessage,
  };
};

export default useEditConsumerGroup;
