import { useQuery } from '@tanstack/react-query';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useContext } from 'react';

function useConsumerGroupData(identification_number) {
  const { getSession } = useContext(AccountContext);

  // const pageSize = 300;

  const getConsumergroupData = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getConsumerGroupByIdentificationNumber(
      headers,
      identification_number
    );

    const consumerGroup = response.data.data || [];

    if (consumerGroup.length === 0) {
      console.error(Messages.noRegisteredConsumerGroups);
      throw new Error(Messages.noRegisteredConsumerGroups);
    }
    return consumerGroup;
  };

  const {
    data: consumerGroup,
    error: consumerGroupError,
    isLoading,
  } = useQuery(
    ['consumerGroupData', identification_number],
    getConsumergroupData
  );

  return {
    consumerGroup,
    consumerGroupError,
    isLoading,
  };
}

export default useConsumerGroupData;
