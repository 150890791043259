import React from 'react';

import {
  AttachMoney as AttachMoneyIcon,
  BatteryChargingFull as BatteryChargingFullIcon,
  Flag as FlagIcon,
} from '@material-ui/icons';

import * as S from './style';

import {
  // IoseEnergyBillsCardAreaChart,
  // IoseEnergyBillsCardPieChart,
  LoadingValueCards,
} from 'components';
import IoseEnergyBillsCardValuesBarChart from 'components/IoseEnergyBillsCardValuesBarChart';

const convertNumberToMonetaryStyle = (number = 0) => {
  const money = number?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
  return money;
};

const formatNumber = (number) => {
  const formattedNumber = number?.toLocaleString();

  return formattedNumber;
};

export default function IoseCardsEnergyBills({
  loading,
  auditValues,
  auditConsumptions,
  auditExtract,
  averages,
}) {
  const {
    totalBillValue,
    totalDifValue,
    totalRecalcValue,
    totalConsumerGroup,
  } = auditValues ?? {};

  const { totalBillConsumption } = auditConsumptions ?? {};

  const { valueAverage, consumptionAverage } = averages;

  const {
    totalDemanContFora,
    totalDemanContPonta,
    totalDemanFora,
    totalDemanPonta,
    totalConsumPonta,
    totalConsumFora,
  } = auditExtract ?? {};

  const valueBalance = totalBillValue - totalRecalcValue;

  const cardsData = [
    {
      icon: <AttachMoneyIcon />,
      chart: (
        <IoseEnergyBillsCardValuesBarChart
          loading={loading}
          data={auditValues}
        />
      ),
      title: 'VALOR TOTAL (R$)',
      data: [
        {
          key: 'FATURAS:',
          value:
            totalBillValue !== undefined
              ? convertNumberToMonetaryStyle(totalBillValue)
              : 0,
        },
        {
          key: 'RECÁLCULO:',
          value:
            totalRecalcValue !== undefined
              ? convertNumberToMonetaryStyle(totalRecalcValue)
              : 0,
        },
        {
          key: 'DIFERENÇA:',
          value:
            totalDifValue !== undefined
              ? convertNumberToMonetaryStyle(valueBalance)
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <BatteryChargingFullIcon />,
      // chart: (
      //   <IoseEnergyBillsCardBarChart
      //     loading={loading}
      //     data={auditConsumptions}
      //   />
      // ),
      title: 'CONSUMO TOTAL (kWh)',
      data: [
        {
          key: 'FATURAS:',
          value:
            totalBillConsumption !== undefined
              ? `${formatNumber(totalBillConsumption)}`
              : 0,
        },
        // {
        //   key: 'RECÁLCULO:',
        //   value:
        //     totalRecalcConsumption !== undefined
        //       ? `${formatNumber(totalRecalcConsumption)}`
        //       : 0,
        // },
        // {
        //   key: 'DIFERENÇA:',
        //   value:
        //     totalDifConsumption !== undefined
        //       ? `${formatNumber(totalDifConsumption)}`
        //       : 0,
        // },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <BatteryChargingFullIcon />,
      // chart: (
      //   <IoseEnergyBillsCardAreaChart
      //     loading={loading}
      //     auditValues={auditValues}
      //   />
      // ),
      title: 'DEMANDAS (kWh)',
      data: [
        {
          key: 'DEMANDA PTA: ',
          value:
            totalDemanPonta !== undefined
              ? `${formatNumber(totalDemanPonta)}`
              : 0,
        },
        {
          key: 'DEMANDA F. PTA: ',
          value:
            totalDemanFora !== undefined
              ? `${formatNumber(totalDemanFora)}`
              : 0,
        },
        {
          key: 'DEMANDA CONT. PTA: ',
          value:
            totalDemanContFora !== undefined
              ? `${formatNumber(totalDemanContFora)}`
              : 0,
        },
        {
          key: 'DEMANDA CONT. F. PTA: ',
          value:
            totalDemanContPonta !== undefined
              ? `${formatNumber(totalDemanContPonta)}`
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <FlagIcon />,
      // chart: (
      //   <IoseEnergyBillsCardPieChart
      //     loading={loading}
      //     name={'Média (kWh)'}
      //     dataName={'Média'}
      //     itemStyleColor={'#015A9E'}
      //     color={'#015A9E'}
      //   />
      // ),
      title: 'RESUMO DE CONSUMOS (kWh)',
      data: [
        {
          key: 'CONSUMO PTA: ',
          value:
            totalConsumPonta !== undefined
              ? `${formatNumber(totalConsumPonta)}`
              : 0,
        },
        {
          key: 'CONSUMO F. PTA: ',
          value:
            totalConsumFora !== undefined
              ? `${formatNumber(totalConsumFora)}`
              : 0,
        },
      ],
      bgColor: 'var(--base)',
    },
    {
      icon: <FlagIcon />,
      // chart: (
      //   <IoseEnergyBillsCardPieChart
      //     loading={loading}
      //     name={'Média'}
      //     dataName={'Média'}
      //     itemStyleColor={'#015A9E'}
      //     color={'#015A9E'}
      //     average={average}
      //   />
      // ),
      title: 'RESUMOS',
      data: [
        {
          key: 'MÉDIA POR U.C.',
          value:
            valueAverage > 0 ? convertNumberToMonetaryStyle(valueAverage) : 0,
        },
        {
          key: 'MÉDIA POR U.C. (kWh)',
          value:
            consumptionAverage > 0 ? `${formatNumber(consumptionAverage)}` : 0,
        },
        {
          key: 'TOTAL DE  U.C.',
          value:
            totalConsumerGroup > 0 ? `${formatNumber(totalConsumerGroup)}` : 0,
        },
        // {
        //   key: 'U.C. ALTA TENSÃO',
        //   value: altaTensaoCount > 0 ? `${formatNumber(altaTensaoCount)}` : 0,
        // },
        // {
        //   key: 'U.C. BAIXA TENSÃO',
        //   value: baixaTensaoCount > 0 ? `${formatNumber(baixaTensaoCount)}` : 0,
        // },
      ],
      bgColor: 'var(--base)',
    },
    // {
    //   icon: <FlagIcon />,
    //   chart: <IoseAdmDashCardsGeneratedChart />,
    //   title: 'BANDEIRA ESCASSEZ HÍDRICA',
    //   data: [{ key: 'TOTAL: ', value: 'R$ 0,00' }],
    //   bgColor: '#FA9F1B',
    // },
  ];

  return (
    <S.CardGrid container spacing={2}>
      {cardsData.map(({ icon, chart, title, data, bgColor }) => (
        <S.BoxContainer
          key={title}
          style={{ transition: '0.5s ease', backgroundColor: bgColor }}
        >
          <S.HeaderContainer>
            <S.IconHeader>{icon}</S.IconHeader>
            <S.CardTitle>{title}</S.CardTitle>
          </S.HeaderContainer>
          {data.map(({ key, value }) => (
            <S.DataContainer key={key}>
              <S.CardDataKey>{key}</S.CardDataKey>
              {loading ? (
                <LoadingValueCards loading={loading} />
              ) : (
                <S.CardDataValue>{value}</S.CardDataValue>
              )}
            </S.DataContainer>
          ))}
          {chart}
        </S.BoxContainer>
      ))}
    </S.CardGrid>
  );
}
