import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useClient, LocalStorage } from 'common';

import * as S from './style';

import {
  energyBillSaneparMonthReport,
  energyBillPercentageReport,
} from 'reports';

import {
  useCreditGeneration,
  useBeneficiaryJoin,
  useGroupPermissions,
  useConsumerGroup,
  useCreateLogs,
} from 'hooks';

import {
  IoseButton,
  IoseFilterConsumerGroup,
  IoseMapRedirectButton,
  IoseSelectFilter,
  IoseSubHeaderBar,
  IoseSelectReferenceMonth,
  LoadingIndicator,
  NoDataMessage,
  ReportMessage,
  IoseAlertLoadingCards,
} from 'components';

import Permissions from 'common/permissions';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

export default function SaneparReportContainer() {
  const { client } = useClient();
  const history = useHistory();

  const [clientData, setClientData] = useState({});
  const [group, setGroup] = useState();
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [loading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [referenceMonth, setReferenceMonth] = useState(null);
  const [identificationNumber, setIdentificationNumber] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('REF: MÊS/ANO');
  const [noDataError, setNoDataError] = useState(false);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { creditGenerationData } = useCreditGeneration();
  const { BeneficiaryJoinData } = useBeneficiaryJoin();
  const { consumerGroups } = useConsumerGroup();
  const { createLog } = useCreateLogs();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const reportPath = ['relatorios', 'creditos', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  const creditGenerationDataValue = creditGenerationData
    ? creditGenerationData
    : [];

  const creditJoin = BeneficiaryJoinData ? BeneficiaryJoinData : [];

  function redirectToMapPage() {
    history.push('/map');
  }

  const uniqueValues = [
    ...new Set(creditGenerationDataValue?.map((item) => item.uc_geradora)),
  ];

  const consumerGroupsOptionsGeradora = consumerGroups
    ?.filter((item) => item.geradora === 'SIM')
    .map((item) => ({
      value: item.identification_number,
      label: item.identification_number,
    }));

  const consumerGroupsOptions = consumerGroupsOptionsGeradora;

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsFilterEnabled(selectedDate || identificationNumber);
  }, [selectedDate, identificationNumber]);

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    }

    return LocalStorage.getClientData();
  };

  const getClientData = async () => {
    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleDateChange = (date) => {
    const formattedDate = formatToBrazilianDate(date);

    setSelectedDate(date);
    setReferenceMonth(formattedDate);
  };

  const handleIdentificationNumberChange = (identification_number) => {
    setIdentificationNumber(identification_number);
  };

  const getAuditData = async () => {
    const ucGeradorasInCreditJoin = creditJoin?.map((item) => item.uc_geradora);

    const ucGeradorasWithoutMatch = uniqueValues?.filter(
      (value) => !ucGeradorasInCreditJoin.includes(value)
    );

    if (ucGeradorasWithoutMatch.length > 0) {
      setNoDataError(true);
      setTimeout(() => {
        setNoDataError(false);
      }, 5000);
      return;
    }

    const renderData = BeneficiaryJoinData?.filter(
      (audit) => audit.uc_geradora === identificationNumber
    )
      .map((audit) => {
        const filteredBeneficiarias = audit.uc_beneficiarias?.filter(
          (beneficiaria) => {
            const [
              mesCredGerado,
              anoCredGerado,
            ] = beneficiaria.mes_cred_utilizado
              ? beneficiaria.mes_cred_utilizado.split('/')
              : ['', ''];
            const [mesRef, anoRef] = audit.mes_referencia
              ? audit.mes_referencia.split('/')
              : ['', ''];
            return (
              (mesCredGerado === mesRef && anoCredGerado === anoRef) ||
              beneficiaria.mes_cred_gerado === 'NULL'
            );
          }
        );

        return {
          ...audit,
          uc_beneficiarias: filteredBeneficiarias?.map((beneficiaria) => ({
            ...beneficiaria,
            mes_referencia: audit.mes_referencia,
          })),
        };
      })
      .filter((audit) =>
        audit.uc_beneficiarias?.map((beneficiaria) => [
          beneficiaria.uc_beneficiaria,
          beneficiaria.mes_cred_gerado,
          beneficiaria.cred_recebido,
          'kWh',
          beneficiaria.mes_cred_utilizado,
        ])
      );

    if (selectedFilter === 'REF: MÊS/ANO' && !selectedDate) {
      energyBillPercentageReport(renderData);

      return;
    }
    const filteredAudits = creditJoin
      ?.map((audit) => {
        const filteredBeneficiarias = audit.uc_beneficiarias.filter(
          (beneficiaria) => {
            const [
              mesCredGerado,
              anoCredGerado,
            ] = beneficiaria.mes_cred_utilizado.split('/');
            const [mesRef, anoRef] = referenceMonth.split('/');

            return (
              (mesCredGerado === mesRef && anoCredGerado === anoRef) ||
              beneficiaria.mes_cred_gerado === 'NULL'
            );
          }
        );
        return {
          ...audit,
          uc_beneficiarias: filteredBeneficiarias,
        };
      })
      .filter((audit) => {
        return (
          audit.uc_geradora === identificationNumber &&
          audit.mes_referencia === referenceMonth &&
          audit.uc_beneficiarias.length > 0
        );
      });
    if (filteredAudits.length === 0) {
      setNoDataError(true);
    } else {
      energyBillSaneparMonthReport(filteredAudits);

      const operacao = 'EMITIR RELATÓRIO';
      const form = 'RELATÓRIO DE CRÉDITOS';
      const logContent = {
        selected_date: selectedDate,
        identification_number: identificationNumber,
      };

      await createLog(operacao, form, logContent);
    }

    setTimeout(() => {
      setNoDataError(false);
    }, 5000);
  };

  const renderSubHeaderAndSearchBar = () => {
    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle={`Relatório de Créditos`}
        button={<></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <IoseMapRedirectButton
            tooltip="IR PARA MAPA DE UNIDADES CONSUMIDORAS"
            bottom="5px"
            onClick={() => redirectToMapPage()}
          />
        </div>
      </IoseSubHeaderBar>
    );
  };

  const renderFilters = () => {
    const filterOptions = [{ value: 'REF: MÊS/ANO', label: 'REF: MÊS/ANO' }];

    const renderDynamicFilters = () => {
      if (selectedFilter === 'REF: MÊS/ANO') {
        return (
          <S.DatesContainer>
            <IoseSelectReferenceMonth
              label="REF: MÊS/ANO"
              handleChange={handleDateChange}
              selectedDate={selectedDate}
            />

            <IoseFilterConsumerGroup
              label="U.C."
              value={identificationNumber}
              onChange={handleIdentificationNumberChange}
              options={consumerGroupsOptions}
            />
          </S.DatesContainer>
        );
      }

      return (
        <IoseSelectReferenceMonth
          label="REF: MÊS/ANO"
          handleChange={handleDateChange}
          selectedDate={selectedDate}
        />
      );
    };

    return (
      <S.FilterWrappedContainer>
        <div>
          <S.FilterText>Filtro:</S.FilterText>
        </div>

        <div style={{ marginRight: '15px' }}>
          <IoseSelectFilter
            label="Selecione um filtro"
            handleChange={handleFilterChange}
            selectedOption={selectedFilter}
            options={filterOptions}
          />
        </div>

        <div>{renderDynamicFilters()}</div>

        <div></div>

        <div>
          <IoseButton
            onClick={() => getAuditData()}
            disabled={!isFilterEnabled}
          >
            EMITIR RELATÓRIO
          </IoseButton>
        </div>
      </S.FilterWrappedContainer>
    );
  };

  const renderTableorImage = () => {
    return (
      <div>
        {loading ? (
          <>
            <LoadingIndicator loading={loading} />
            <S.StyledLoadingMessage>
              Carregando dados e emitindo o relatório...
            </S.StyledLoadingMessage>
          </>
        ) : (
          <div>
            {noDataError ? (
              <NoDataMessage message="Ops! Nenhum dado encontrado. Por favor, verifique se as configurações do filtro estão corretas e tente novamente." />
            ) : (
              <ReportMessage message="Para emitir o relatório, selecione os filtros desejados e clique em EMITIR RELATÓRIO." />
            )}
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {renderFilters()}
          {renderTableorImage()}
        </div>
      </>
    );
  };

  return (
    <S.WrappedContainer>
      {renderSubHeaderAndSearchBar()}
      {hasReportPermission ? (
        renderContent()
      ) : (
        <IoseAlertLoadingCards
          text={
            'Seu grupo de usuários não tem permissão para emissão deste relatório!'
          }
        />
      )}
    </S.WrappedContainer>
  );
}
