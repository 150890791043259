import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const GridStyled = styled(Grid)`
  margin: 0px;
  padding: 5px 10px 5px 0px;
  width: 100%;

  @media (max-width: 500px) {
    padding: 5px 0px;
  }
`;

export const GridContainer = styled.div`
  padding: 0px 10px 5px 25px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const WrapContainer = styled.div`
  padding: 0px 15px 5px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
