import React, { useContext, useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
  LoadingIndicator,
  IoseSelectReferenceMonth,
  IoseButton,
} from 'components';

import { useClient, LocalStorage, AccountContext, ioseAPI } from 'common';

import {
  useUniqueConcessionaires,
  useAuditDifference,
  useGroupPermissions,
} from 'hooks';

import IoseDynamicFilter from 'components/IoseDynamicFilter';
import { IoseAddFilterButton } from 'components/IoseButtonIcon';
import IoseRecaclTable from 'components/IoseRecaclTable';
import IoseSearchMessage from 'components/IoseSearchMessage';
import IoseBillSearchModalDifferece from 'components/Modals/IoseBillSearchModalDifferece';
import Permissions from 'common/permissions';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};
export default function RecalcEnergyBillsContainer() {
  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { uniqueConcessionaires } = useUniqueConcessionaires();
  const { differences } = useAuditDifference();
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedConcessionaire, setSelectedConcessionaire] = useState('');
  const [selectedRefMonth, setSelectedRefMonth] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [
    selectedIdentificationNumber,
    setSelectedIdentificationNumber,
  ] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({});
  const [consumerGroupOptions, setConsumerGroupOptions] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [filteredData, setFilteredData] = useState();
  //eslint-disable-next-line
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  // Permissions States
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['faturas', 'recalculo_faturas', 'leitura'];
    const reportPath = ['faturas', 'recalculo_faturas', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const consumerGroupOptions = differences?.map((differences) => ({
      value: differences?.identification_number,
      label: differences?.identification_number,
    }));

    setConsumerGroupOptions(consumerGroupOptions);
  }, [differences]);

  const getClientData = async () => {
    setError('');

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client?.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const openAuditModal = () => {
    if (openModal) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
    }
  };

  const RenderFilterContainer = () => {
    const filterOptions = ['UC', 'Concessionaria', 'Mês de referência'];

    const toggleFilters = () => {
      setShowFilters(!showFilters);
    };

    const handleOptionClick = (option) => {
      setSelectedOption(option);
      setShowFilters(false);
    };

    const handleConcessionariaChange = (value) => {
      setSelectedConcessionaire(value);
    };

    const handleRefMonthChange = (value) => {
      const formattedDate = formatToBrazilianDate(value);

      setSelectedDate(value);
      setSelectedRefMonth(formattedDate);
    };
    const handleIdentificationNumberChange = (value) => {
      setSelectedIdentificationNumber(value);
    };

    const renderSelectedComponent = () => {
      const filterOptions = {
        'Concessionaria': {
          label: 'Concessionaria',
          databaseName: 'concessionaire',
          value: selectedConcessionaire,
          onChange: handleConcessionariaChange,
          options: uniqueConcessionaires,
        },
        'Mês de referência': {
          label: 'Mês de referência',
          databaseName: 'reference_month',
          value: selectedRefMonth,
          onChange: handleRefMonthChange,
        },

        'UC': {
          label: 'UC',
          databaseName: 'identification_number',
          value: selectedIdentificationNumber,
          onChange: handleIdentificationNumberChange,
          options: consumerGroupOptions,
        },
      };

      const selectedFilter = filterOptions[selectedOption];

      const handleButtonClick = () => {
        if (selectedFilter && selectedFilter.databaseName) {
          const formattedFilter = { ...selectedFilters };

          if (selectedFilter.databaseName === 'previous_reading') {
            if (selectedFilter.initial_value && selectedFilter.final_value) {
              formattedFilter[
                'previous_reading_start'
              ] = selectedFilter.initial_value.toLocaleDateString();
              formattedFilter[
                'previous_reading_end'
              ] = selectedFilter.final_value.toLocaleDateString();
            }
          }
          if (selectedFilter.databaseName === 'vencimento') {
            if (selectedFilter.initial_value && selectedFilter.final_value) {
              formattedFilter[
                'vencimento_start'
              ] = selectedFilter.initial_value.toLocaleDateString();
              formattedFilter[
                'vencimento_end'
              ] = selectedFilter.final_value.toLocaleDateString();
            }
          } else {
            if (selectedFilter.value) {
              formattedFilter[selectedFilter.databaseName] =
                selectedFilter.value;
            }
          }

          setSelectedFilters(formattedFilter);
        }

        setShowFilters(false);
        setSelectedOption(null);
      };

      const handleRemoveFilter = (key) => {
        const updatedFilters = { ...selectedFilters };

        if (updatedFilters[key]) {
          delete updatedFilters[key];

          setSelectedFilters(updatedFilters);
        }
      };

      async function getEnergyBills(object) {
        try {
          setLoading(true);
          setSearchButtonClicked(true); // Set the state when the button is clicked

          const sessionData = await getSession();

          // dados da segunda tabela
          const response = await ioseAPI.getFilteredSearchEnergyBill(
            sessionData.headers,
            object
          );
          const secondTableData = response.data;

          // dados da primeira tabela
          const newFilteredData = differences
            ?.map((item) => {
              const filtersMatch = Object.entries(selectedFilters).every(
                ([key, value]) => item[key] === value
              );

              if (filtersMatch) {
                const matchingItem = secondTableData.data.find((secondItem) => {
                  return (
                    secondItem.identification_number ===
                      item.identification_number &&
                    secondItem.reference_month === item.reference_month
                  );
                });

                if (matchingItem) {
                  return {
                    ...item,
                    uuid_original_bill: matchingItem.uuid_original_bill,
                    link_pdf: matchingItem.link_pdf,
                  };
                }
              }
              return null;
            })
            .filter(Boolean); // Remova os itens nulos do array

          // Ordenar os objetos com base na propriedade identification_number
          newFilteredData?.sort((a, b) =>
            a.identification_number.localeCompare(b.identification_number)
          );

          setFilteredData(newFilteredData);
          setSelectedFilters({});
          setLoading(false);
        } catch (error) {
          console.error(error);
        }
      }

      return (
        <S.FilterBox>
          {selectedFilter && (
            <>
              {selectedFilter.label === 'Mês de referência' ? (
                <IoseSelectReferenceMonth
                  label={selectedFilter.label}
                  handleChange={selectedFilter.onChange}
                  selectedDate={selectedDate}
                />
              ) : (
                <IoseDynamicFilter
                  label={selectedFilter.label}
                  value={selectedFilter.value}
                  onChange={selectedFilter.onChange}
                  options={selectedFilter.options}
                />
              )}
              <IoseButton onClick={handleButtonClick}>
                ADICIONAR FILTRO
              </IoseButton>
            </>
          )}

          <div style={{ marginLeft: '25px' }}>
            {Object.entries(selectedFilters)?.length > 0 && (
              <S.FilterCardContainer>
                <h3>Filtros Selecionados:</h3>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {Object.entries(selectedFilters).map(([key, value]) => (
                    <S.FilterCardDiv key={key}>
                      <S.FilterCardValue>{value}</S.FilterCardValue>
                      <S.CloseButton onClick={() => handleRemoveFilter(key)}>
                        <S.CloseCardIcon />
                      </S.CloseButton>
                    </S.FilterCardDiv>
                  ))}
                </div>
                <IoseButton
                  left={'15px'}
                  onClick={() => getEnergyBills(selectedFilters)}
                >
                  BUSCAR FATURAS
                </IoseButton>
              </S.FilterCardContainer>
            )}
          </div>
        </S.FilterBox>
      );
    };

    return (
      <S.FilterContainer>
        <IoseAddFilterButton
          onClick={toggleFilters}
          tooltip={'ADICIONAR FILTRO'}
        />

        {showFilters && (
          <S.FilterBox>
            {filterOptions.map((option, index) => (
              <S.OptionsButton
                key={index}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </S.OptionsButton>
            ))}
          </S.FilterBox>
        )}

        {renderSelectedComponent()}
      </S.FilterContainer>
    );
  };

  //render ioserBillsAuditModal

  const renderTable = () => {
    const getEnergyBill = async (rowData) => {
      setLoading(true);
      const { identification_number, link_pdf, pdf_name } = rowData;

      try {
        const result = await getBillByUUID(identification_number, pdf_name);

        if (result && result?.length > 0) {
          // Agrupar os dados por identification_number
          const groupedResult = result.reduce((groups, item) => {
            const key = item.identification_number;

            if (!groups[key]) {
              groups[key] = [];
            }

            groups[key].push(item);
            return groups;
          }, {});

          // Ordenar os grupos por identification_number
          const sortedGroups = Object?.values(groupedResult).sort((a, b) => {
            const identificationNumberA = a[0].identification_number;
            const identificationNumberB = b[0].identification_number;

            return identificationNumberA - identificationNumberB;
          });

          // Exibir os resultados ordenados
          const flattenedResult = sortedGroups?.reduce((flattened, group) => {
            return flattened.concat(group);
          }, []);

          const url = `${link_pdf}${flattenedResult[0].pdf_name}`;
          flattenedResult[0].link_completo = url;

          setSelectedRowData(flattenedResult);
          setLoading(false);
          openAuditModal();
        } else {
          console.log(
            'Fatura não encontrada para o identification_number e pdf_name fornecidos.'
          );
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }

      function processBillsData(energyBills) {
        const result = [];
        energyBills?.forEach((data) => {
          const keys = Object.keys(data).filter((key) =>
            /^item_\w+_\d+$/.test(key)
          );

          keys?.sort((a, b) => {
            const numA = parseInt(a.substring(a.lastIndexOf('_') + 1));
            const numB = parseInt(b.substring(b.lastIndexOf('_') + 1));

            return numA - numB;
          });

          const processedData = {};

          for (let i = 0; i < keys?.length; i++) {
            const key = keys[i];
            const index = key.substring(key.lastIndexOf('_') + 1);
            const propertyName = key.substring(
              key.indexOf('_') + 1,
              key.lastIndexOf('_')
            );
            const value = data[key];

            if (!processedData[index]) {
              processedData[index] = {};
            }

            processedData[index][propertyName] = value;
          }

          for (let i = 0; i < keys?.length; i++) {
            const key = keys[i];
            const index = key.substring(key.lastIndexOf('_') + 1);
            const propertyName = key.substring(
              key.indexOf('_') + 1,
              key.lastIndexOf('_')
            );
            const value = data[key];

            if (!processedData[index]) {
              processedData[index] = {};
            }

            processedData[index][propertyName] = value;
            // Mantenha o campo original
            processedData[index][`${propertyName}_original`] = key;
          }

          const lines = 12;
          const properties = [
            'measurer',
            'magnitude',
            'timetables',
            'measurer_previous_reading',
            'measurer_current_reading',
            'measurer_constant',
            'kwh_consumption',
          ];

          const measurer_data = {};

          properties.forEach((property) => {
            for (let i = 1; i <= lines; i++) {
              measurer_data[`${property}_${i}`] = data?.[`${property}_${i}`];
            }
          });

          const taxes_data = {
            icms_calculation_basis_total: data?.icms_calculation_basis_total,
            cofins_calculation_basis: data?.cofins_calculation_basis,
            pis_calculation_basis: data?.pis_calculation_basis,
            icms_aliquot: data?.icms_aliquot,
            cofins_aliquot: data?.cofins_aliquot,
            pis_aliquot: data?.pis_aliquot,
            icms_total: data?.icms_total,
            cofins_total: data?.cofins_total,
            pis_total: data?.pis_total,
          };

          const itensLines = 17;
          const itensProperties = [
            'item_description',
            'item_unity',
            'item_consumption',
            'item_unitary_value',
            'item_total_value',
            'item_piscofins',
            'item_icms',
            'item_tax',
          ];

          const itens_data = {};

          itensProperties.forEach((property) => {
            for (let i = 1; i <= itensLines; i++) {
              itens_data[`${property}_${i}`] = data?.[`${property}_${i}`];
            }
          });

          const completeBillData = {
            uuid_energy_bill: data?.uuid_energy_bill,
            bill_name: data?.bill_name,
            classification: data?.classification,
            identification_number: data?.identification_number,
            reference_month: data?.reference_month,
            number_days: data?.number_days,
            current_reading: data?.current_reading,
            previous_reading: data?.previous_reading,
            measurer: data?.measurer,
            account_value: data?.account_value,
            cnpj: data?.cnpj,
            pdf_name: data?.pdf_name,

            measurer_data: measurer_data,

            taxes_data: taxes_data,

            itens_data: itens_data,

            table_data: Object.values(processedData),
          };

          result.push(completeBillData);
        });

        return result;
      }

      async function getBillByUUID(identification_number, pdf_name) {
        try {
          const sessionData = await getSession();

          const response = await ioseAPI.getAllIdentificatioNumberBills(
            sessionData.headers,
            identification_number
          );

          const { data } = response.data;

          const filteredBill = data?.filter(
            (item) => item.pdf_name === pdf_name
          );

          const formatedBill = processBillsData(filteredBill);

          return formatedBill;
        } catch (error) {
          console.log(error);
          throw error;
        }
      }

      let result;

      try {
        result = await getBillByUUID(identification_number, pdf_name);

        if (result && result?.length > 0) {
          const url = `${link_pdf}${result[0].pdf_name}`;
          result[0].link_completo = url;
        } else {
          console.log(
            'Fatura não encontrada para o identification_number e pdf_name fornecidos.'
          );
        }
      } catch (error) {
        console.log(error);
      }

      setSelectedRowData(result);
      setLoading(false);
      openAuditModal();
    };

    if (filteredData) {
      return (
        <S.ContainerTable>
          <IoseRecaclTable
            tableTitle=""
            searchInTable={false}
            data={filteredData}
            loading={loading}
            onClickFunction={getEnergyBill}
            hasPermission={hasReportPermission}
          />
        </S.ContainerTable>
      );
    } else {
      return (
        <IoseSearchMessage
          message={`Selecione os filtros de busca desejados e clique em "BUSCAR FATURAS".`}
          height="400px"
          width="400px"
        />
      );
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    // const showButton = group === 'super' || group === 'admin' ? true : false;

    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle="Fatura x Recálculo"
        button={showButton ? button : <></>}
      />
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div>
            {renderTable()}
            {renderBillModal()}
          </div>
        </>
      );
    }
  };

  const renderBillModal = () => {
    return (
      <IoseBillSearchModalDifferece
        open={openModal}
        onClose={openAuditModal}
        data={selectedRowData}
        refreshBills={() => {}}
        refreshFaturas={() => {}}
      />
    );
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {RenderFilterContainer()}
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : hasReadPermission ? (
          renderContent()
        ) : (
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

RecalcEnergyBillsContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
