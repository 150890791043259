import React, { useState, useEffect } from 'react';

import IoseApiLib from 'iose-api-lib';

import { useFormik } from 'formik';

import { useHistory, useLocation } from 'react-router-dom';

import { IoseTextField, LoadingIndicatorResponsive } from 'components';

import { Messages, LocalStorage, LoginSchema, IoseCognito } from 'common';

import { useCreateLogs } from 'hooks';

import {
  BoxLoginContent,
  Logo,
  Form,
  ButtonConfirmation,
  LinkForgotPassword,
  ErroText,
  Illustration,
  InstructionsText,
  Container,
  FormLogin,
} from './style';

/**

 *
 *This is the Login Page with all elements
 * @export
 * @returns

 */

export default function LoginPage() {
  const [authResponse, setAuthResponse] = useState({});
  const [email, setEmail] = useState('');
  const [inFirstAccessSection, setInFirstAccessSection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const history = useHistory();
  const location = useLocation();
  const session = LocalStorage.getSession();
  const [error, setError] = useState(
    location.state ? location.state.message : ''
  );

  const { createLoginLog } = useCreateLogs();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codeParam = urlParams.get('code');

    if (codeParam !== null) {
      history.push('/auth', { code: codeParam });
    }
    //eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      email: email,
      password: password,
    },

    validationSchema: LoginSchema,

    onSubmit: (values) => doLogin(values.email, values.password),
  });

  useEffect(() => {
    verifySessionLocalStorage();

    //eslint-disable-next-line
  }, []);

  const redirectToHostedUi = () => {
    const url =
      'https://iose-integration.auth.us-east-1.amazoncognito.com/login?client_id=es40tj9k20ou7e9omcurc4b44&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Femos-sanepar.com%2F';

    // const url =
    //   'https://iose-integration.auth.us-east-1.amazoncognito.com/login?client_id=hscorv98aq4v8a0s3bg7icshl&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F';

    window.location.href = url;
  };

  const verifySessionLocalStorage = () => {
    if (session) {
      const path =
        session.group === 'super' ? '/global/super' : '/admin-dashboard/client';

      history.replace(path);
    }
  };

  const getDataClient = async (uuid_client) => {
    try {
      const { data } = await IoseApiLib.Client.get(uuid_client);

      return data;
    } catch (err) {
      throw err;
    }
  };

  // const getDataDynamoEmployee = async (group, id) => {
  //   try {
  //     let employeeData = {};

  //     if (group === 'admin') {
  //       employeeData = await IoseApiLib.Admin.getAdmin(id);
  //     } else {
  //       employeeData = await IoseApiLib.Employee.getEmployee(id);
  //     }

  //     return employeeData.data;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  const handleSessionData = async (sessionData, tokenData) => {
    try {
      let clientData;

      if (sessionData.group !== 'super') {
        const uuid_client = process.env.REACT_APP_UUID_CLIENT;

        clientData = await getDataClient(uuid_client);

        LocalStorage.storageClientData(clientData);

        // LocalStorage.storageSessionDynamoData(dataDynamoEmployee);
      }

      const session = {
        userId: sessionData.userId,
        name: sessionData.name,
        email: sessionData.email,
        group: sessionData.group,
        accessToken: tokenData.accessToken,
        refreshToken: tokenData.refreshToken,
        expiration: tokenData.expiration,
        idToken: tokenData.idToken,
      };

      LocalStorage.storageSession(session);

      const operacao = 'LOGIN';
      const form = 'LOGIN';
      const logContent = {};

      await createLoginLog(session, operacao, form, logContent);
    } catch (err) {
      throw err;
    }
  };

  const doLogin = async (email, password) => {
    setLoading(true);

    setError('');

    try {
      const EMAIL = email;

      const PASSWORD = password;

      const tokenData = await IoseCognito.login(EMAIL, PASSWORD);

      if (tokenData.status === 'newPasswordRequired') {
        setLoading(false);

        setInFirstAccessSection(true);

        setEmail(email);

        setAuthResponse({
          sessionUserAttributes: tokenData.sessionUserAttributes,

          user: tokenData.user,
        });
      } else {
        IoseApiLib.setToken(tokenData.idToken);

        const sessionData = await IoseCognito.getSessionParams();

        await handleSessionData(sessionData, tokenData);

        sessionData.group === 'super'
          ? history.replace('global/super')
          : history.replace('/map');
      }
    } catch (err) {
      const message = err.response ? err.response.data.message : err.message;

      setLoading(false);

      setError(message);
    }
  };

  const changePassword = async (event) => {
    setLoading(true);

    event.preventDefault();

    event.persist();

    setError('');

    try {
      const response = await IoseCognito.doChangePassword(
        password,

        authResponse.sessionUserAttributes,
        authResponse.user
      );

      response && doLogin(email, password);
    } catch (err) {
      setLoading(false);

      setError(err.message);
    }
  };

  const renderFirstAccessSection = () => (
    <>
      <Illustration />

      <Form onSubmit={changePassword}>
        <InstructionsText>
          {Messages.definitivePassword}

          <b> {Messages.requisitionPassowordCognito} </b>
        </InstructionsText>

        <IoseTextField
          label="Senha"
          autoComplete="true"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <ErroText>{error}</ErroText>

        {loading ? (
          <LoadingIndicatorResponsive
            loading={loading}
            color={'var(--primary)'}
          />
        ) : (
          <ButtonConfirmation type="submit">Entrar</ButtonConfirmation>
        )}
      </Form>
    </>
  );

  const renderDefaultSection = () => {
    const fieldErrorEmail = {
      state: formik.errors.email ? true : false,

      msg: formik.errors.email ? formik.errors.email : null,
    };

    const fieldErrorPass = {
      state: formik.errors.password ? true : false,

      msg: formik.errors.password ? formik.errors.password : null,
    };

    return (
      <>
        <Logo />

        <Form onSubmit={formik.handleSubmit}>
          <ErroText>{error}</ErroText>

          <IoseTextField
            label="Email"
            autoComplete="true"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={fieldErrorEmail.state}
            helperText={fieldErrorEmail.msg}
          />

          <IoseTextField
            label="Senha"
            autoComplete="true"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={fieldErrorPass.state}
            helperText={fieldErrorPass.msg}
            type="password"
          />

          <LinkForgotPassword to="/forgot">
            Esqueceu a Senha?
          </LinkForgotPassword>

          {loading ? (
            <LoadingIndicatorResponsive
              loading={loading}
              color={'var(--primary)'}
            />
          ) : (
            <ButtonConfirmation type="submit">Entrar</ButtonConfirmation>
          )}
        </Form>

        <div
          style={{
            width: '100%',
          }}
        >
          <p>Ou faça login com: </p>
          <ButtonConfirmation onClick={() => redirectToHostedUi()}>
            SSO
          </ButtonConfirmation>
        </div>
      </>
    );
  };

  return (
    <Container>
      <FormLogin>
        <BoxLoginContent>
          {inFirstAccessSection
            ? renderFirstAccessSection()
            : renderDefaultSection()}
        </BoxLoginContent>

        {/* <IoseFooterLogin /> */}
      </FormLogin>
    </Container>
  );
}
