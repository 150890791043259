import React, { useState } from 'react';
import * as XLSX from 'xlsx';

import * as S from './style';

import {
  IoseNoDataMessage,
  IoseButton2,
  IoseEnergyBillTable,
} from 'components';

import { EnergyBillReport } from 'reports';

function exportToExcel(data) {
  // Reordena as colunas
  const reorderedColumns = [
    'bill_name',
    'cnpj',
    'identification_number',
    'reference_month',
    'account_value',
  ];

  // Reordena os dados
  const reorderedData = data?.map((obj) => {
    const reorderedObj = {};
    reorderedColumns.forEach((column) => {
      reorderedObj[column] = obj[column];
    });
    return reorderedObj;
  });

  const worksheet = XLSX.utils.json_to_sheet(reorderedData);

  const headers = {
    bill_name: 'Endereço',
    cnpj: 'Cnpj',
    identification_number: 'Unidade Consumidora',
    reference_month: 'REF: MÊS/ANO',
    account_value: 'Valor da conta',
  };

  // Altere o nome das colunas no cabeçalho da planilha
  const columns = Object.keys(headers);
  columns.forEach((column, index) => {
    const headerCell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    headerCell.v = headers[column];
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas de Energia');
  XLSX.writeFile(workbook, 'faturas.xlsx');
}

export default function IoseEnergyBillsTable({ energyBillData, loading }) {
  const [searchIdentificationNumber, setSearchIdentificationNumber] = useState(
    ''
  );

  const filteredIdentificationNumber = energyBillData?.filter((id) =>
    id.identification_number.includes(searchIdentificationNumber)
  );

  function renderTableOrErrorMessage() {
    if (
      filteredIdentificationNumber &&
      filteredIdentificationNumber.length > 0
    ) {
      return (
        <IoseEnergyBillTable
          energyBillData={filteredIdentificationNumber}
          loading={loading}
        />
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="300px"
          width="300px"
        />
      );
    }
  }

  function handleDownloadButtonClick() {
    exportToExcel(energyBillData);
  }

  return (
    <S.TableContainer id="scrollableDiv">
      <S.TableBox>
        <S.TableHeaderBox>
          <S.TableTitle>
            <h3>TABELA DE CONTAS DE ENERGIA</h3>
          </S.TableTitle>
          <S.DivSearch>
            <S.InputBaseStyled
              type="text"
              placeholder="Pesquisar por Unidade Consumidora..."
              value={searchIdentificationNumber}
              onChange={(event) =>
                setSearchIdentificationNumber(event.target.value)
              }
            />
            <S.SearchIconStyled />
          </S.DivSearch>
          <IoseButton2
            onClick={() => EnergyBillReport(filteredIdentificationNumber)}
          >
            Relatório PDF
          </IoseButton2>
          <IoseButton2 onClick={handleDownloadButtonClick}>
            Download XLSX
          </IoseButton2>
        </S.TableHeaderBox>
        {renderTableOrErrorMessage()}
      </S.TableBox>
    </S.TableContainer>
  );
}
