import React, { useEffect, useState } from 'react';
import { Modal, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IoseNavButton from 'components/IoseNavButton';

import * as S from './style';
import {
  IoseAddButton,
  IoseCloseModalButton,
  IoseDownloadButton,
  IoseEditBillButton,
  IoseEditTaxesBillButton,
} from 'components/IoseButtonIcon';

import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import { LocalStorage, useClient } from 'common';
import { IoseAlertLoadingCards } from 'components/IoseAlert';
import { IoseModalTextField } from 'components';

//Import icons
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import BillDataTable from 'components/IoseTables';
import IoseBillsEditMeasurerModal from '../IoseBillsEditMeasurerModal';
import IoseBillsEditTaxesModal from '../IoseBillsEditTaxesModal';
import IoseBillsEditItensModal from '../IoseBillsEditItensModal';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
    backgroundColor: '#ECF0F5',
    height: '85vh',
    color: 'white',
  },
  whiteText: {
    color: '#ffff',
    opacity: '80%',
  },
  disabledButton: {
    color: 'white',
  },
}));

export default function IoseBillSearchModalDifferece({
  open,
  onClose,
  data,
  refreshBills,
  refreshFaturas,
}) {
  const classes = useStyles();
  const { client } = useClient();

  const [billsArray, setBillsArray] = useState([]);
  const [clientData, setClientData] = useState({});
  const [currentBill, setCurrentBill] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openTaxesModal, setOpenTaxesModal] = useState(false);
  const [openItensModal, setOpenItensModal] = useState(false);
  const [editMeasurerInitialValues, setEditMeasurerInitialValues] = useState(
    []
  );
  const [editTaxesInitialValues, setEditTaxesInitialValues] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);

  // const [forceTableRender, setForceTableRender] = useState(false);

  useEffect(() => {
    setBillsArray(data);
  }, [data]);

  useEffect(() => {
    if (billsArray) {
      setCurrentBill(billsArray[0]);
    }
  }, [billsArray]);

  useEffect(() => {
    const linkCompleto = currentBill?.link_completo;

    if (linkCompleto) {
      const modifiedLink = linkCompleto.replace('faturas-san/', '');

      const linkParts = modifiedLink?.split('/');

      if (linkParts?.length >= 2) {
        const objectKey = linkParts?.join('/');

        const s3BaseUrl = `https://iose-prod-pdf-bucket.s3.amazonaws.com/`;

        const fullS3Url = `${s3BaseUrl}${objectKey}`;

        setDownloadLink(fullS3Url);
      } else {
        console.error('Link de PDF inválido.');
      }
    } else {
      console.error('Link de PDF inválido.');
    }
  }, [currentBill]);

  useEffect(() => {
    const initialData = {};

    if (currentBill && currentBill.measurer_data) {
      for (let i = 1; i <= 12; i++) {
        initialData[`measurer_${i}`] =
          currentBill.measurer_data[`measurer_${i}`];
        initialData[`magnitude_${i}`] =
          currentBill.measurer_data[`magnitude_${i}`];
        initialData[`timetables_${i}`] =
          currentBill.measurer_data[`timetables_${i}`];
        initialData[`measurer_previous_reading_${i}`] =
          currentBill.measurer_data[`measurer_previous_reading_${i}`];
        initialData[`measurer_current_reading_${i}`] =
          currentBill.measurer_data[`measurer_current_reading_${i}`];
        initialData[`measurer_constant_${i}`] =
          currentBill.measurer_data[`measurer_constant_${i}`];
        initialData[`kwh_consumption_${i}`] =
          currentBill.measurer_data[`kwh_consumption_${i}`];
      }
    }

    setEditMeasurerInitialValues(initialData);
  }, [currentBill]);

  useEffect(() => {
    const initialData = {};

    if (currentBill && currentBill.taxes_data) {
      initialData['icms_calculation_basis_total'] =
        currentBill.taxes_data.icms_calculation_basis_total;
      initialData['cofins_calculation_basis'] =
        currentBill.taxes_data.cofins_calculation_basis;
      initialData['pis_calculation_basis'] =
        currentBill.taxes_data.pis_calculation_basis;
      initialData['icms_aliquot'] = currentBill.taxes_data.icms_aliquot;
      initialData['cofins_aliquot'] = currentBill.taxes_data.cofins_aliquot;
      initialData['pis_aliquot'] = currentBill.taxes_data.pis_aliquot;
      initialData['icms_total'] = currentBill.taxes_data.icms_total;
      initialData['cofins_total'] = currentBill.taxes_data.cofins_total;
      initialData['pis_total'] = currentBill.taxes_data.pis_total;
    }

    setEditTaxesInitialValues(initialData);
  }, [currentBill]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleForceTableRender = () => {
  //   setForceTableRender(!forceTableRender);
  // };

  const openEditModal = () => {
    if (openModal) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
    }
  };

  const openEditTaxesModal = () => {
    if (openTaxesModal) {
      setOpenTaxesModal(false);
    } else {
      setOpenTaxesModal(true);
    }
  };
  const openEditItensModal = (rowData) => {
    if (openItensModal) {
      setOpenItensModal(false);
    } else {
      setSelectedRowData(rowData);
      setOpenItensModal(true);
    }
  };

  // const selectRowData = (rowData) => {
  //   setSelectedRowData(rowData);
  // };

  function convertNumberToDecimalStyle(number = 0) {
    const decimalNumber = number.toLocaleString('pt-br');
    return decimalNumber;
  }

  const getClientData = async () => {
    setError('');
    // setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const handleDownload = (downloadLink) => {
    if (downloadLink) {
      window.open(downloadLink, '_blank');
    } else {
      console.error('Link de download não disponível.');
    }
  };

  const nextBillNavigation = () => {
    if (currentIndex < billsArray[0].energyBills?.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setCurrentBill(billsArray[0].energyBills[currentIndex + 1]);
    }

    setIsPrevButtonDisabled(false);

    setIsNextButtonDisabled(
      currentIndex + 1 === billsArray[0].energyBills?.length - 1
    );
  };

  const prevBillNavigation = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setCurrentBill(billsArray[0].energyBills[currentIndex - 1]);
    }

    setIsNextButtonDisabled(false);

    setIsPrevButtonDisabled(currentIndex - 1 === 0);
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle={`Detalhamento da fatura de energia`}
        // description={'Total: ' + allContracts.length}
        button={showButton ? button : <></>}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <IoseDownloadButton
            tooltip="BAIXAR FATURA"
            bottom="5px"
            onClick={() => handleDownload(downloadLink)}
          />

          <IoseEditBillButton
            tooltip="EDITAR DADOS DO MEDIDOR DA FATURA"
            bottom="5px"
            onClick={() => openEditModal()}
          />

          <IoseEditTaxesBillButton
            tooltip="EDITAR DADOS DOS TRIBUTOS DA FATURA"
            bottom="5px"
            onClick={() => openEditTaxesModal()}
          />

          {/* <IoseEditItensBillButton
            tooltip="EDITAR DADOS DOS ITENS DA FATURA"
            bottom="5px"
            onClick={() => openEditItensModal()}
          /> */}

          {/* <IoseMapRedirectButton
            tooltip="RETORNAR PARA O MAPA"
            bottom="5px"
            onClick={() => redirectToMapPage()}
          /> */}
        </div>
      </IoseSubHeaderBar>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <S.Container>
          <IoseNavButton
            icon={<NavigateBeforeIcon />}
            tooltip="ANTERIOR"
            onClick={() => prevBillNavigation()}
            disabled={isPrevButtonDisabled}
          />
          <div style={{ width: '96vw' }}>
            <div>
              <S.Header>
                <S.HeaderItem>
                  <S.TypographyTitle>
                    DADOS DA CONTA DE ENERGIA
                  </S.TypographyTitle>
                </S.HeaderItem>
              </S.Header>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <div style={{ width: '50%' }}>
                  <IoseModalTextField
                    label="Endereço"
                    value={currentBill?.bill_name}
                  />
                </div>

                <div style={{ width: '50%' }}>
                  <IoseModalTextField
                    label="Classificação"
                    value={currentBill?.classification}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '10px',
              }}
            >
              <IoseModalTextField
                label="Unidade Consumidora"
                value={currentBill?.identification_number}
              />

              <IoseModalTextField
                label="Mês de vigência"
                value={currentBill?.reference_month}
              />

              <IoseModalTextField
                label="Leitura Anterior"
                value={currentBill?.previous_reading}
              />
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '10px',
              }}
            >
              <IoseModalTextField
                label="Dias medidos"
                value={currentBill?.number_days}
              />

              <IoseModalTextField label="Fator multiplicador" value="1,00" />

              <IoseModalTextField
                label="Leitura atual"
                value={currentBill?.current_reading}
              />
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              <IoseModalTextField
                label="Número do medidor"
                value={currentBill?.measurer}
              />

              <IoseModalTextField
                label="Total a pagar"
                value={convertNumberToDecimalStyle(currentBill?.account_value)}
              />
            </div>

            <div>
              <BillDataTable
                // key={forceTableRender}
                data={currentBill?.table_data}
                openEditItensModal={openEditItensModal}
              />
            </div>
          </div>
          <IoseNavButton
            icon={<NavigateNextIcon />}
            tooltip="PRÓXIMO"
            onClick={() => {
              nextBillNavigation();
            }}
            disabled={isNextButtonDisabled}
          />
        </S.Container>
      );
    }
  };

  const renderEditMeasurerModal = () => {
    return (
      <IoseBillsEditMeasurerModal
        open={openModal}
        onClose={openEditModal}
        data={currentBill}
        editInitialValues={editMeasurerInitialValues}
        refreshBills={refreshBills}
        refreshFaturas={refreshFaturas}
        closeAuditModal={onClose}
      />
    );
  };

  const renderEditTaxesModal = () => {
    return (
      <IoseBillsEditTaxesModal
        open={openTaxesModal}
        onClose={openEditTaxesModal}
        data={currentBill}
        editInitialValues={editTaxesInitialValues}
        refreshBills={refreshBills}
        refreshFaturas={refreshFaturas}
        closeAuditModal={onClose}
      />
    );
  };

  const renderEditItensModal = () => {
    return (
      <IoseBillsEditItensModal
        open={openItensModal}
        onClose={openEditItensModal}
        data={currentBill}
        editInitialValues={selectedRowData}
        refreshBills={refreshBills}
        refreshFaturas={refreshFaturas}
        closeAuditModal={onClose}
        // handleForceTableRender={handleForceTableRender}
      />
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{ classes: { root: classes.backdrop } }}
    >
      <Grow in={open} timeout={500}>
        <S.ModalContainer>
          <S.StyledHeaderModal>
            <S.RoomIcon />

            <S.ModalTitleHeader variant="h5" gutterBottom>
              CONTAS DE ENERGIA DA UNIDADE CONSUMIDORA
            </S.ModalTitleHeader>

            <S.ButtonsContainer>
              <IoseCloseModalButton
                tooltip="FECHAR MODAL"
                bottom="5px"
                onClick={() => onClose()}
                left="150px"
              />
            </S.ButtonsContainer>
          </S.StyledHeaderModal>

          <S.StyledWrappedModal>
            <div className={classes.paper}>
              <>
                {renderSubHeaderAndSearchBar()}
                {renderContent()}
                {renderEditMeasurerModal()}
                {renderEditTaxesModal()}
                {renderEditItensModal()}
              </>
            </div>
          </S.StyledWrappedModal>
        </S.ModalContainer>
      </Grow>
    </Modal>
  );
}
