import ReactECharts from 'echarts-for-react';
import React from 'react';

export default function IoseAreaChartConsumptionComparision({ data }) {
  // Função de comparação para ordenar por reference_month
  function compareByReferenceMonth(a, b) {
    const [monthA, yearA] = a.reference_month.split('/');
    const [monthB, yearB] = b.reference_month.split('/');
    return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
  }

  data?.sort(compareByReferenceMonth);

  const summedData = {};

  data.forEach((obj) => {
    const { reference_month, bill_consumption, measured_consumption } = obj;

    const [month, year] = reference_month.split('/');

    const key = `${month}/${year}`;

    if (summedData[key]) {
      // Se existir, adicionar os valores de bill_consumption e measured_consumption
      summedData[key].bill_consumption += bill_consumption;
      summedData[key].measured_consumption += measured_consumption;
    } else {
      // Caso contrário, criar uma entrada para o mês com os valores iniciais
      summedData[key] = {
        bill_consumption,
        measured_consumption,
      };
    }
  });

  // Converter o objeto de soma novamente em um array de objetos
  const summedDataArray = Object.keys(summedData).map((key) => ({
    reference_month: key,
    bill_consumption: summedData[key].bill_consumption,
    measured_consumption: summedData[key].measured_consumption,
  }));

  const date = [];
  const bill = [];
  const measured = [];

  summedDataArray.forEach((obj) => {
    const { reference_month, bill_consumption, measured_consumption } = obj;

    date.push(reference_month);
    bill.push(bill_consumption);
    measured.push(measured_consumption);
  });

  const option = {
    // title: {
    //   text: 'Temperature Change in the Coming Week',
    // },
    grid: {
      left: '2%',
      right: '3%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        if (params.length > 0) {
          const tooltipContent = params
            .map((param) => {
              const seriesName = param.seriesName;
              const value =
                param.value !== undefined && param.value !== null
                  ? param.value
                  : 'Sem dados';

              const numericValue =
                typeof value === 'string' ? parseFloat(value) : value;

              return `<div><strong>${seriesName}</strong>: ${numericValue.toLocaleString(
                'pt-BR'
              )}</div>`;
            })
            .join('');

          return tooltipContent;
        }

        return 'Sem dados';
      },
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: date,
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
      },
    ],
    legend: {
      data: ['Consumido Iose', 'Consumido Fatura'],
    },
    series: [
      {
        name: 'Consumido Iose',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series',
        },
        data: measured,
        color: '#67C6E3',
      },
      {
        name: 'Consumido Fatura',
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series',
        },
        data: bill,
        color: '#015A9E',
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: 260 }} />;
}
