import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import IoseApiLib from 'iose-api-lib';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseSearch,
  IoseCardUnity,
  IoseAlertLoadingCards,
  IoseInfinityScroll,
  IoseCircuitCommissioning,
  IoseAddButton2,
} from 'components';

import {
  Messages,
  LocalStorage,
  useSession,
  useClient,
  useUnity,
} from 'common';

import { useCreateLogs, useGroupPermissions } from 'hooks';
import Permissions from 'common/permissions';

/**This component create a container for put content inside pages */
export default function UnityContainer({
  openSide,
  getcards,
  setGetcards,
  group,
}) {
  const history = useHistory();
  const alert = useAlert();

  const { verifySession } = useSession();
  const { client } = useClient();
  const { setUnity } = useUnity();
  const { createLog } = useCreateLogs();
  const { groupPermissions } = useGroupPermissions();

  const session = LocalStorage.getSession();

  const [cards, setCards] = useState([]);
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [clientData, setClientData] = useState({});
  const [circuitsNew, setCircuitsNew] = useState([]);
  const [endListInfinityScroll, setEndListInfinityScroll] = useState(false);
  const [error, setError] = useState('');
  const [errorCommiss, setErrorCommiss] = useState('');
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(0);
  const [nextPageNewCircuits, setNextPageNewCircuits] = useState(0);
  const [OpenCardComiss, setOpenCardComiss] = useState(true);
  const [search, setSearch] = useState(false);
  const [hasReadPermission, setHasReadPermission] = useState();
  const [hasAddPermission, setHasAddPermission] = useState();
  const [hasEditPermission, setHasEditPermission] = useState();
  const [hasDeletePermission, setHasDeletePermission] = useState();

  useEffect(() => {
    getAllData();
    //eslint-disable-next-line
  }, [getcards]);

  const userSession = LocalStorage.getSession();

  const uuid_client = process.env.REACT_APP_UUID_CLIENT;

  useEffect(() => {
    const readPath = ['cadastro', 'unidades', 'leitura'];
    const addPath = ['cadastro', 'unidades', 'criar'];
    const editPath = ['cadastro', 'unidades', 'editar'];
    const deletePath = ['cadastro', 'unidades', 'deletar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);

      const editPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        editPath
      );
      setHasEditPermission(editPermission);

      const deletePermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        deletePath
      );
      setHasDeletePermission(deletePermission);
    }
  }, [userSession, groupPermissions]);

  //Function related UNITY
  const getAllData = async (moreUnitys) => {
    setError('');
    setLoading(true);

    try {
      const clientData = getClientDataByGroup(group);

      setClientData(clientData);

      const sessionVerified = await verifySession(session);

      if (sessionVerified) {
        if (group === 'super' || group === 'admin') {
          await getHandleNewCircuits(false, uuid_client);
          await getMoreThanOneUnity(clientData, moreUnitys);
        } else {
          await getOneUnity();
        }
      }
    } catch (err) {
      const message = err.response
        ? err.response.data.message
        : Messages.errorGetData;
      setError(message);
    } finally {
      setGetcards(false);
      setLoading(false);
    }
  };

  const getClientDataByGroup = (group) => {
    let clientData = {};

    if (group === 'super') {
      clientData['name'] = client.name;
      clientData['uuid_client'] = client.uuid_client;
    } else {
      clientData = LocalStorage.getClientData();
    }

    return clientData;
  };

  const getMoreThanOneUnity = async (clientData, moreUnitys) => {
    let allUnitys = [];

    if (!moreUnitys) {
      allUnitys = await getAllUnitys(uuid_client);
      allUnitys.data.length === 0 && setError(Messages.noRegisteredUnits);

      setCards(allUnitys.data);
    } else if (nextPage) {
      allUnitys = await getAllUnitys(uuid_client, nextPage);
      !allUnitys.next_page && setEndListInfinityScroll(true);

      setCards(cards.concat(allUnitys.data));
    }

    setNextPage(allUnitys.next_page);
  };

  const getOneUnity = async () => {
    const { uuid_unity } = LocalStorage.getSessionDynamoData();

    const unity = await getUnity(uuid_unity);
    unity.length === 0 && setError(Messages.noRegisteredUnits);

    setCards(unity);
  };

  const getAllUnitys = async (uuid_client, nextpage = 0) => {
    try {
      let response = await IoseApiLib.Unity.getAllUnity(uuid_client, nextpage);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const getUnity = async (uuid_unity, nextpage = 0) => {
    try {
      let response = await IoseApiLib.Unity.getUnity(uuid_unity, nextpage);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  const getMoreUnitys = () => nextPage && getAllData(true);

  const deleteUnity = async (uuidUnity) => {
    try {
      await IoseApiLib.Unity.deleteUnity(uuidUnity);
      getAllData();

      const operacao = 'DELETAR';
      const form = 'DELETAR UNIDADE';
      const logContent = {
        uuid_unity: uuidUnity,
      };

      await createLog(operacao, form, logContent);

      renderAlert(Messages.successDeleteUnity, 'Sucesso!');
    } catch (err) {
      const message = err.response
        ? err.response.data.message
        : Messages.errorDeleteUnity;

      renderAlert(message, 'Erro!');
    }
  };

  //Function to filter Unity
  const filterUnity = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppcase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards.filter((item) =>
        item.name.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered.length !== 0
        ? setCardsFiltered(itemFiltered)
        : setError(Messages.noFindUnitys);
    } else {
      setSearch(false);
    }
  };
  ///////////////////////////////////

  //Functions related COMMISSIONING
  const getHandleNewCircuits = async (
    moreNewCircuits,
    uuidClient = uuid_client
  ) => {
    try {
      const sessionVerificated = await verifySession(session);

      let newCircuits = {};

      if (sessionVerificated) {
        if (!moreNewCircuits) {
          newCircuits = await getNewCircuits(uuidClient);

          const circuits = newCircuits === null ? [] : newCircuits.data;

          setCircuitsNew(circuits);
        } else if (nextPageNewCircuits) {
          newCircuits = await getNewCircuits(uuidClient, nextPageNewCircuits);
          const circuits = newCircuits === null ? [] : newCircuits.data;

          setCircuitsNew(circuitsNew.concat(circuits));
        }

        setNextPageNewCircuits(newCircuits.next_page);
        setOpenCardComiss(true);
      }
    } catch (err) {
      const message = err.response
        ? err.response.data.message
        : Messages.erroGetNewCircuits;
      setErrorCommiss(message);
    }
  };

  const getNewCircuits = async (uuidClient, nextpage = 0) => {
    try {
      let response = await IoseApiLib.Circuit.getNewCircuit(
        uuidClient,
        nextpage,
        1000
      );
      console.log('response:', response);

      return response;
    } catch (err) {
      throw err;
    }
  };

  const getMoreNewCircuits = () =>
    nextPageNewCircuits && getHandleNewCircuits(true);

  const getAllGroups = async (uuid_unity) => {
    try {
      let response = await IoseApiLib.Group.getAllGroup(uuid_unity);
      return { status: true, data: response.data };
    } catch (err) {
      return { status: false, message: Messages.errorGetAllGroups };
    }
  };

  const updateNewCircuits = async (
    newCircuitsSelecteds,
    uuid_unity,
    uuid_group
  ) => {
    let responsesRequisition = await newCircuitsSelecteds.map(
      async (circuit) => {
        let statusRequistion = await updatePropertyCircuit(
          circuit.uuid_circuit,
          uuid_unity,
          uuid_group
        );

        return statusRequistion === true ? true : false;
      }
    );

    let result = Promise.all(responsesRequisition).then((reponses) => {
      const allSuccess = reponses.every((response) => response === true);

      if (allSuccess) {
        return { status: true, message: Messages.successCommissioning };
      } else {
        return { status: false, message: Messages.erroCommissioning };
      }
    });

    return result;
  };

  const updatePropertyCircuit = async (
    uuid_circuit,
    uuid_unity,
    uuid_group
  ) => {
    try {
      await IoseApiLib.Circuit.updatePropertyCircuit(
        uuid_circuit,
        uuid_unity,
        uuid_group
      );
      return true;
    } catch (err) {
      return false;
    }
  };
  ///////////////////////////////////

  //Functions to REDIRECT for determined SidebarContainer
  const redirectCreateUnity = () => {
    history.push({
      pathname: '/global/client/unity',
      state: {
        uuid_client: uuid_client,
        clientName: clientData.name,
        unityName: 'Unidade',
      },
    });
    openSide();
  };

  const redirectEditUnity = (unityData) => {
    setUnity(unityData);
    history.push({
      pathname: '/global/client/unity/edit',
      state: {
        uuid_client: uuid_client,
        clientName: clientData.name,
        unityData: unityData,
      },
    });
    openSide();
  };

  const redirectViewSchedule = (unityData) => {
    setUnity(unityData);
    history.push({
      pathname: '/global/schedule',
      state: {
        uuid_client: uuid_client,
        clientName: clientData.name,
        unityData: unityData,
      },
    });

    setGetcards(true);
  };

  const redirectOpenSwitchboards = (unityData) => {
    setUnity(unityData);
    history.push({
      pathname: '/global/client/switchboard',
      state: {
        uuid_client: uuid_client,
        clientName: clientData.name,
        uuid_unity: unityData.uuid_unity,
        unityName: unityData.name,
        tariff_period: unityData.tariff_period,
        tariff_data: unityData.tariff_data,
        contracted_demand: unityData.contracted_demand,
      },
    });
    openSide();
  };
  ///////////////////////////////////

  //Function to RENDER
  const renderAlert = (message, type) => {
    return alert.show(`${message}`, {
      title: type,
      closeCopy: 'Fechar',
    });
  };

  const renderCards = (card) => {
    return (
      <IoseCardUnity
        key={card?.uuid_unity}
        uuid_unity={card?.uuid_unity}
        name={card?.name}
        description={card?.description}
        group={group}
        energy={card?.energy}
        money={card?.money}
        tariff_period={card?.tariff_period}
        tariff_data={card?.tariff_data}
        contracted_demand={card?.contracted_demand}
        identification_number={card?.identification_number}
        bin_link={card?.bin_link}
        id_3d={card?.id_3d}
        clickCard={redirectOpenSwitchboards}
        clickDelete={deleteUnity}
        clickSchedule={redirectViewSchedule}
        clickEdit={redirectEditUnity}
        showEdit={hasEditPermission}
        showDelete={hasDeletePermission}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    const button = (
      <IoseAddButton2
        onClick={redirectCreateUnity}
        tooltip="CRIAR UNIDADE"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle="Unidades"
        description={'Total: ' + cards?.length}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar Unidades…"
          funcSearch={filterUnity}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderCircuitCommissing = () => {
    if (errorCommiss !== '') {
      return <IoseAlertLoadingCards text={errorCommiss} bottom={'56px'} />;
    } else if (circuitsNew.length !== 0) {
      return (
        <IoseCircuitCommissioning
          circuitsNew={circuitsNew}
          allUnitys={cards}
          getAllGroups={getAllGroups}
          updateNewCircuit={updateNewCircuits}
          getMoreNewCircuits={getMoreNewCircuits}
          open={OpenCardComiss}
          setOpen={setOpenCardComiss}
          nextpage={nextPageNewCircuits}
        />
      );
    }
  };

  const renderAllCards = () => {
    return (
      <S.ContainerCards>
        {search ? cardsFiltered.map(renderCards) : cards.map(renderCards)}
      </S.ContainerCards>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          {renderCircuitCommissing()}
          <IoseInfinityScroll
            dataLength={cards.length}
            next={getMoreUnitys}
            hasMore={true}
            loading={loading}
            endList={endListInfinityScroll}
            scrollableTarget={'container'}
          >
            {hasReadPermission
              ? renderAllCards()
              : setError(
                  'Seu grupo de usuários não tem permissão de leitura destes dados!'
                )}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  return (
    <S.WrapContainer>
      {renderSubHeaderAndSearchBar()}
      {renderContent()}
    </S.WrapContainer>
  );
}

UnityContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
