// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useUniqueAtividades() {
  const [noAtividadesError, setNoAtividadesError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getUniqueAtividades = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getUniqueAtividades(headers);

    const uniqueAtividades = response.data.data || [];

    if (uniqueAtividades.length === 0) {
      setNoAtividadesError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return uniqueAtividades;
  };

  const {
    data: uniqueAtividades,
    error: uniqueAtividadesError,
    refetch: refreshUniqueAtividades,
    isLoading,
  } = useQuery(['uniqueAtividades'], getUniqueAtividades);

  return {
    uniqueAtividades,
    uniqueAtividadesError,
    noAtividadesError,
    refreshUniqueAtividades,
    isLoading,
  };
}

export default useUniqueAtividades;
