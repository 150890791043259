import React, { useState } from 'react';
import { Slide } from '@material-ui/core';

import * as S from './style';

import {
  IoseNoDataMessage,
  IoseAdmDashTableSwitchBoard,
  LoadingIndicatorDashboard,
} from 'components';

export default function IoseAdmDashContainerSwitchboard({
  loading,
  allCircuits,
  data,
}) {
  const [searchGroups, setSearchGroups] = useState('');

  const filteredGroups = data?.filter((group) =>
    group.group_name.toLowerCase().includes(searchGroups.toLowerCase())
  );

  const GroupsData = filteredGroups?.map((group) => ({
    name: group.group_name,
    unity: group.unity_name,
    measure: group.consumption,
    uuid_group: group.uuid_group,
  }));

  const renderHeader = () => {
    return (
      <>
        <S.Header>
          <S.SwitchboardIcon />
          <h3>QUADROS</h3>
          <S.DivSearch>
            <S.InputBaseStyled
              type="text"
              placeholder="Pesquisar por nome do quadro..."
              value={searchGroups}
              onChange={(event) => setSearchGroups(event.target.value)}
            />
            <S.SearchIconStyled />
          </S.DivSearch>
        </S.Header>
      </>
    );
  };

  const renderTableOrErrorMessage = () => {
    if (GroupsData && GroupsData.length > 0) {
      return (
        <IoseAdmDashTableSwitchBoard
          loading={loading}
          GroupsData={GroupsData}
          allCircuits={allCircuits}
        />
      );
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir a tabela!"
          height="280px"
          width="250px"
        />
      );
    }
  };

  return (
    <>
      <Slide
        direction="right"
        in={true}
        timeout={2000}
        mountOnEnter
        unmountOnExit
      >
        {loading ? (
          <LoadingIndicatorDashboard loading={loading} />
        ) : (
          <S.GridStyled item xs={8} md={6}>
            <S.StyledContainer>
              {renderHeader()}
              {renderTableOrErrorMessage()}
            </S.StyledContainer>
          </S.GridStyled>
        )}
      </Slide>
    </>
  );
}
