import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';

export default function AuditValueBarChart({ data }) {
  const [filteredData, setFilteredData] = useState();

  // Ordenar os meses do mais antigo para o mais atual
  const sortedMonths = data?.xAxys?.data.sort((a, b) => {
    const [monthA, yearA] = a.split('/');
    const [monthB, yearB] = b.split('/');
    const dateA = new Date(yearA, monthA - 1);
    const dateB = new Date(yearB, monthB - 1);
    return dateA - dateB;
  });

  useEffect(() => {
    const series = data?.series;

    const findTop5 = (arr) => {
      return arr
        .map((value, index) => ({ value, index }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 5)
        .map((item) => item.index);
    };

    const filterTop5 = (series) => {
      const filteredData = [];

      // Verificar se 'series' é definido e possui pelo menos um elemento
      if (series && series.length > 0 && series[0]?.data) {
        const maxValues = series[0].data.map((_, i) => {
          const values = series.map((obj) => obj?.data?.[i]);
          const top5Indices = findTop5(values);
          return top5Indices.map((index) => series[index]);
        });

        maxValues.forEach((values) => {
          values.forEach((obj) => {
            if (!filteredData.includes(obj)) {
              filteredData.push(obj);
            }
          });
        });
      }

      return filteredData;
    };

    // Chamar a função para obter o array filtrado
    const result = filterTop5(series);

    setFilteredData(result);
  }, [data]);

  const option = {
    grid: {
      left: '2%',
      right: '2%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const formattedValues = params.map((param) => {
          const numericValue =
            typeof param.value === 'string'
              ? parseFloat(param.value)
              : param.value;

          const formattedValue = numericValue.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue} (kWh)`;
        });

        return formattedValues.join('<br>') || 'Sem dados';
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: [
      {
        type: 'category',
        data: sortedMonths,
        axisTick: {
          alignWithLabel: true,
        },
        show: true,
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            if (Math.abs(value) >= 1e6) {
              // Se o valor for maior ou igual a 1 milhão, exibe em milhões (M)
              return (value / 1e6).toFixed(0) + 'M';
            } else {
              // Caso contrário, exibe em milhares (K)
              return (value / 1e3).toFixed(0) + 'K';
            }
          },
        },
      },
    ],
    series: filteredData,
  };

  return data?.series?.length > 0 ? (
    <ReactECharts
      option={option}
      style={{ height: 400 }}
      key={JSON.stringify(option)}
    />
  ) : (
    <IoseNoDataMessage
      message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
      height="300px"
      width="280px"
    />
  );
}
