import React, { useEffect, useState, useContext } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';
import { useAlert } from 'react-alert';

import {
  IoseContractsDeleteModal,
  IoseAlertLoadingCards,
  IoseSubHeaderBar,
  IoseSearch,
  IoseAddButton,
} from 'components';

import {
  AccountContext,
  ioseAPI,
  LocalStorage,
  Messages,
  useClient,
} from 'common';

import { ContainerCards, WrappedContainer } from './style';

import { useSystemVariables, useGroupPermissions } from 'hooks';

import Permissions from 'common/permissions';

import IoseSystemVariablesTable from 'components/Tables/IoseSystemVariablesTable';

import {
  IoseAddSystemVariables,
  IoseEditSystemVariables,
} from 'components/IoseNewModals';

export default function SystemVariablesContainer({ getcards }) {
  const [addNotification, setAddNotification] = useState('');

  //eslint-disable-next-line
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [clientData, setClientData] = useState({});
  const [deleteNotificationType, setDeleteNotificationType] = useState(0);
  const [deleteNotification, setDeleteNotification] = useState('');
  const [editNotificationType, setEditNotificationType] = useState(0);
  const [editNotification, setEditNotification] = useState('');
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [notificationType, setNotificationType] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState('');

  const alert = useAlert();
  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { systemVariables, refreshSystemVariables } = useSystemVariables();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['parametros', 'variaveis_sistema', 'leitura'];
    const addPath = ['parametros', 'variaveis_sistema', 'criar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  function errorHandlingAddConcessionaires(error) {
    setLoading(false);

    setNotification(error);
    setNotificationType(2);
  }

  function errorHandlingEditConcessionaires(error) {
    setLoading(false);
    setNotification(error);
    setEditNotificationType(2);
  }

  function errorHandlingDeleteConcessionaires(error) {
    alert.show(error ? error : `${Messages.errorDeleteConcessionaires}`, {
      title: 'Erro!',
      closeCopy: 'Fechar',
    });
  }

  /// ========== OPERATIONS ===========

  async function addSystemVariable(item_type, item_constant, item_description) {
    setNotificationType(0);
    setLoading(true);

    let sessionData;

    try {
      sessionData = await getSession();
      const response = await ioseAPI.createSystemVariable(
        sessionData.headers,
        item_type,
        item_constant,
        item_description
      );

      setAddNotification(response.data.message);
      setNotificationType(1);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.contractsNoRegistered;
      errorHandlingAddConcessionaires(errorMessage);
    } finally {
      setLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseAddModal();
        refreshSystemVariables();
        setNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  async function editContract(
    uuid_contract,
    contractNumber,
    marketType,
    concessionaire,
    termOfContract,
    readjustmentIndex,
    readjustmentRate,
    initialDate,
    finalDate,
    totalContracts,
    monthlyValue,
    note,
    daysExpire,
    s3Link
  ) {
    setEditNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.updateContract(
        sessionData.headers,
        uuid_contract,
        contractNumber,
        marketType,
        concessionaire,
        termOfContract,
        readjustmentIndex,
        readjustmentRate,
        initialDate,
        finalDate,
        totalContracts,
        monthlyValue,
        note,
        daysExpire,
        s3Link
      );

      setEditNotification(response.data.message);
      setEditNotificationType(1);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.concessionairesNoRegistered;

      errorHandlingEditConcessionaires(errorMessage);
      setEditNotificationType(2);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseEditModal();
        setEditNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  async function deleteContract(uuid_contract) {
    setDeleteNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.deleteContract(
        sessionData.headers,
        uuid_contract
      );

      setDeleteNotification(response.data.message);
      setDeleteNotificationType(1);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : Messages.errorDeleteContracts;

      errorHandlingDeleteConcessionaires(errorMessage);
      setDeleteNotificationType(2);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setShowNotification(true);

      setTimeout(() => {
        handleCloseDeleteModal();
        setDeleteNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  /// ========== OPERATIONS ===========

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const filterCircuits = (searchName) => {
    setError('');
    const searchNameUppcase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = systemVariables.filter(
        (item) =>
          item?.item_type?.toUpperCase().includes(searchNameUppcase) ||
          item.item_constant?.toUpperCase().includes(searchNameUppcase)
      );

      if (itemFiltered.length !== 0) {
        setCardsFiltered(itemFiltered);
      } else {
        setError(Messages.noSystemVariables);
      }
    } else {
      setCardsFiltered([]);
    }
  };

  //Functions to RENDER
  // eslint-disable-next-line
  // const renderAlert = (message, type) => {
  //   return alert.show(`${message}`, {
  //     title: type,
  //     closeCopy: 'Fechar',
  //   });
  // };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    // const showButton = true;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="ADICIONAR VARIÁVEL"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle="Variáveis do Sistema"
        description={'Variáveis cadastradas: ' + systemVariables?.length}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar Variáveis"
          funcSearch={filterCircuits}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderTable = () => {
    const dataToRender =
      cardsFiltered.length !== 0 ? cardsFiltered : systemVariables;

    return (
      <ContainerCards>
        <IoseSystemVariablesTable
          data={dataToRender}
          setSelectedRow={setSelectedRow}
          handleOpenEditModal={handleOpenEditModal}
        />
      </ContainerCards>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return <>{renderTable()}</>;
    }
  };

  const renderModals = () => {
    return (
      <>
        <IoseAddSystemVariables
          open={openAddModal}
          onClose={handleCloseAddModal}
          submit={addSystemVariable}
          notificationType={notificationType}
          notification={notification}
          modalHeaderTitle="VARIÁVEIS"
          modalTitle="CADASTRO DE VARIÁVEIS"
          showNotification={showNotification}
          addNotification={addNotification}
          loading={loading}
        />

        <IoseEditSystemVariables
          open={openEditModal}
          onClose={handleCloseEditModal}
          submit={editContract}
          notificationType={editNotificationType}
          notification={notification}
          modalHeaderTitle="VARIÁVEIS"
          modalTitle="EDITAR VARIÁVEIS"
          showNotification={showNotification}
          editNotification={editNotification}
          loading={loading}
          initialValues={selectedRow}
        />

        <IoseContractsDeleteModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          submit={deleteContract}
          notificationType={deleteNotificationType}
          notification={notification}
          showNotification={showNotification}
          deleteNotification={deleteNotification}
          loading={loading}
          modalTitle="DELETAR VARIÁVEIS"
          modalHeaderTitle="VARIÁVEIS"
        />
      </>
    );
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {renderModals()}

        {hasReadPermission ? (
          renderContent()
        ) : (
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        )}
      </WrappedContainer>
    </Grow>
  );
}

SystemVariablesContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
