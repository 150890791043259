import React, { useEffect, useState, useContext } from 'react';
import PropType from 'prop-types';
import { Grow } from '@material-ui/core/';
import ioseApiLib from 'iose-api-lib';

import {
  IoseEnvironmentEditModal,
  IoseEnvironmentOpenObjects,
  IoseCardNew,
  IoseInfinityScroll,
  CreateEnviromentModal,
  IoseSearch,
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
} from 'components';

import { Messages, ioseAPI, AccountContext, LocalStorage } from 'common';

import * as S from './style';

import {
  useClientData,
  useObjects,
  useEnvironments,
  useUnitys,
  useCircuits,
  useCreateLogs,
  useGroupPermissions,
} from 'hooks';

import Permissions from 'common/permissions';
export default function EnvironmentContainer({ getcards }) {
  const [cards, setCards] = useState([]);
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [editNotificationType, setEditNotificationType] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openObjectsModal, setOpenObjectsModal] = useState(false);
  const [search, setSearch] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedUnity, setSelectedUnity] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState();
  const [hasEditPermission, setHasEditPermission] = useState();

  const { getSession } = useContext(AccountContext);
  const { clientData, uuidClient, group } = useClientData();

  const { unitys } = useUnitys(uuidClient);
  const { circuits } = useCircuits(uuidClient);
  const { objects, refreshObjects } = useObjects();
  const { createLog } = useCreateLogs();
  const { groupPermissions } = useGroupPermissions();
  const {
    environments,
    noEnvironmentsError,
    refreshEnvironments,
  } = useEnvironments();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['cadastro', 'ambientes', 'leitura'];
    const addPath = ['cadastro', 'ambientes', 'criar'];
    const editPath = ['cadastro', 'ambientes', 'editar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);

      const editPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        editPath
      );
      setHasEditPermission(editPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    environments?.length > 0
      ? setCards(environments)
      : setError(noEnvironmentsError);
  }, [environments, noEnvironmentsError]);

  useEffect(() => {
    function handleSelectedUnity(selectedCard) {
      const selectedUnity = unitys?.find(
        (unity) => unity.uuid_unity === selectedCard?.uuid_unity
      );

      setSelectedUnity(selectedUnity);
    }

    handleSelectedUnity(selectedCard);
  }, [selectedCard, unitys]);

  useEffect(() => {
    function handleSelectedObject(selectedCard, selectedUnity) {
      if (selectedCard && selectedUnity) {
        const objectsWithSameEnvironment = objects?.filter(
          (obj) => obj.uuid_environment === selectedCard.uuid_environment
        );

        const newObject = {
          name: selectedCard?.name ? selectedCard?.name : 'Não cadastrado',
          description: selectedCard?.description,
          name_unity: selectedUnity?.name,
          uuid_unity: selectedCard.uuid_unity,
          uuid_environment: selectedCard.uuid_environment,
          id_3d: selectedCard?.id_3d,
          objects: objectsWithSameEnvironment,
        };

        setSelectedObject(newObject);
      }
    }
    handleSelectedObject(selectedCard, selectedUnity);
  }, [selectedCard, selectedUnity, objects]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenEditModal = (environment) => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedCard(null);
    setOpenEditModal(false);
  };

  const handleOpenObjectsModal = (environment) => {
    setOpenObjectsModal(true);
  };

  const handleCloseObjectsModal = () => {
    setSelectedCard(null);
    setOpenObjectsModal(false);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const filterEnvironments = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppcase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards?.filter((item) =>
        item?.name?.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered.length !== 0
        ? setCardsFiltered(itemFiltered)
        : setError(Messages.noFindEnvironments);
    } else {
      setSearch(false);
    }
  };

  const renderCards = (card) => {
    return (
      <IoseCardNew
        key={card.uuid_environment}
        uuid_environment={card.uuid_environment}
        name={card?.name}
        description={card.description}
        id_3d={card.id_3d}
        handleOpenEditModal={handleOpenEditModal}
        handleOpenObjectsModal={handleOpenObjectsModal}
        onClose={handleCloseEditModal}
        onClick={() => handleCardClick(card)}
        cardDeleteTitle={'DELETAR AMBIENTE'}
        cardEditTitle={'EDITAR AMBIENTE'}
        modalTitle={'VISUALIZAR OBJETOS DO AMBIENTE'}
        cardIcon={<S.RoomIcon />}
        showDeleteButton={false}
        showEditButton={hasEditPermission}
        showChartButton={false}
        showInfo={false}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="Criar Ambiente"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle="Ambientes"
        description={'Total: ' + cards.length}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar Ambientes…"
          funcSearch={filterEnvironments}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderAllCards = () => {
    return (
      <S.ContainerCards>
        {search ? cardsFiltered.map(renderCards) : cards.map(renderCards)}
      </S.ContainerCards>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards?.length ? cards.length : 5}
            hasMore={true}
            loading={loading}
            endList={false}
            scrollableTarget={'container'}
          >
            {hasReadPermission
              ? renderAllCards()
              : setError(
                  'Seu grupo de usuários não tem permissão de leitura destes dados!'
                )}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <CreateEnviromentModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          unitys={unitys}
          addEnvironment={addEnvironment}
          loading={loading}
        />
        <IoseEnvironmentEditModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          unitys={unitys}
          selectedObject={selectedObject}
          editEnvironment={editEnvironment}
          loading={loading}
          notification={notification}
          editNotificationType={editNotificationType}
        />
        <IoseEnvironmentOpenObjects
          open={openObjectsModal}
          selectedObject={selectedObject}
          onClose={handleCloseObjectsModal}
          group={group}
          loading={loading}
          handleOpenAddModal={handleOpenAddModal}
          environments={environments}
          getObjects={refreshObjects}
          allCircuits={circuits}
          errorAlertText={'Nenhum objeto encontrado neste ambiente!'}
        />
      </>
    );
  };

  ////////////////// OPERATIONS //////////////////

  // Functions to ADD Environment
  function errorHandlingAddUnity(error) {
    setLoading(false);

    setNotification(error);
  }

  async function addEnvironment(name, description, uuidUnity, id_3d) {
    setLoading(true);

    try {
      const response = await ioseApiLib.Environment.createEnvironment(
        name,
        description,
        uuidUnity,
        id_3d
      );
      const operacao = 'CRIAR';
      const form = 'CRIAR AMBIENTE';
      const logContent = {
        name: name,
        description: description,
        uuid_unity: uuidUnity,
        id_3d: id_3d,
      };
      await createLog(operacao, form, logContent);
      setLoading(false);
      setNotification(response.data.message);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.environmentsNoRegistered;

      errorHandlingAddUnity(errorMessage);
    } finally {
      setLoading(false);
      handleCloseAddModal();
      refreshEnvironments();
    }
  }

  //Functions to EDIT Unity
  function errorHandlingEditUnity(error) {
    setLoading(false);
    setNotification(error);
  }

  async function editEnvironment(
    uuidEnvironment,
    name,
    description,
    uuidUnity,
    id_3d
  ) {
    setEditNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.updateEnvironment(
        sessionData.headers,
        uuidEnvironment,
        name,
        description,
        uuidUnity,
        id_3d
      );

      setLoading(false);
      setNotification(response.data.message);
      setEditNotificationType(1);

      const operacao = 'EDITAR';
      const form = 'EDITAR AMBIENTE';
      const logContent = {
        uuid_environment: uuidEnvironment,
        name: name,
        description: description,
        uuid_unity: uuidUnity,
        id_3d: id_3d,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.environmentsNoRegistered;

      errorHandlingEditUnity(errorMessage);
      setEditNotificationType(2);
    } finally {
      setLoading(false);
      setTimeout(() => {
        handleCloseEditModal();
        refreshEnvironments();
        setEditNotificationType(0);
      }, 2500);
    }
  }

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrapContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </S.WrapContainer>
    </Grow>
  );
}

EnvironmentContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
