import React, { useEffect, useState, useContext } from 'react';
import PropType from 'prop-types';
import { Grow } from '@material-ui/core/';
import { useHistory } from 'react-router-dom';

import {
  IoseAddButton,
  IoseAlertLoadingCards,
  IoseInfinityScroll,
  IoseSearch,
  IoseSubHeaderBar,
} from 'components';

import {
  AccountContext,
  ioseAPI,
  LocalStorage,
  Messages,
  useClient,
} from 'common';

import { ContainerCards, GroupsIcon, WrappedContainer } from './style';

import IoseAddUserGroupModal from 'components/Modals/IoseAddUserGroupModal';
import IosePermissionsCard from 'components/Cards/IosePermissionsCard';
import { defaultPermissions } from 'constants/permissions';
import { useUserGroups, useGroupPermissions, useCreateLogs } from 'hooks';

export default function UserGroupsContainer({ getcards }) {
  const [addNotification, setAddNotification] = useState('');
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [cards, setCards] = useState([]);
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [notificationType, setNotificationType] = useState(0);
  const [notification, setNotification] = useState('');
  const [openAddModal, setOpenAddModal] = useState(false);

  const [search, setSearch] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [usualGroups, setUsualGroups] = useState([]);

  const { groups, refreshCognitoGroups } = useUserGroups();
  const { groupPermissions } = useGroupPermissions();

  const history = useHistory();

  useEffect(() => {
    if (selectedCard && groupPermissions && groupPermissions.length > 0) {
      const foundGroup = groupPermissions.find(
        (group) => group.group_name === selectedCard.GroupName
      );

      if (foundGroup) {
        setSelectedGroup(foundGroup);
      }
    }
  }, [selectedCard, groupPermissions]);

  useEffect(() => {
    const usualGroups = groups?.filter(
      (group) => group?.GroupName !== 'us-east-1_hWmApDv4W_SANEPAR'
    );
    setUsualGroups(usualGroups);
  }, [groups]);

  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { createLog } = useCreateLogs();

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usualGroups?.length > 0) {
      setCards(usualGroups);
    }
  }, [usualGroups]);

  function redirectToPermissions() {
    history.push({
      pathname: `/permissions-management`,
      state: selectedGroup,
    });
  }

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  function errorHandlingAddGroup(error) {
    setLoading(false);
    setNotification(error);
    setNotificationType(2);
  }

  /// ========== OPERATIONS ===========

  async function addGroup(groupName) {
    setNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      await ioseAPI.createCognitoGroup(sessionData.headers, groupName);

      await ioseAPI.createGroupPermissions(
        sessionData.headers,
        groupName,
        defaultPermissions
      );

      setAddNotification('Grupo cadastrado com sucesso!');
      setNotificationType(1);

      const operacao = 'CRIAR';
      const form = 'CRIAR GRUPO';
      const logContent = {
        group_name: groupName,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.groupNoRegistered;
      errorHandlingAddGroup(errorMessage);
    } finally {
      setLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseAddModal();
        setNotificationType(0);
        setShowNotification(false);
        refreshCognitoGroups();
      }, 2500);
    }
  }

  /// ========== OPERATIONS ===========
  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client?.name,
        uuid_client: client?.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const filterCircuits = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppcase = searchName?.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards?.filter((item) =>
        item?.GroupName?.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered.length !== 0
        ? setCardsFiltered(itemFiltered)
        : setError(Messages.noFindGroups);
    } else {
      setSearch(false);
    }
  };
  const renderCards = (card) => {
    return (
      <IosePermissionsCard
        key={card?.GroupName}
        name={card?.GroupName}
        description={card.UserPoolId}
        onClick={() => handleCardClick(card)}
        cardDeleteTitle={'DELETAR GRUPO'}
        cardEditTitle={'EDITAR GRUPO'}
        handleOpenEditModal={() => {}}
        handleOpenDeleteModal={() => {}}
        cardIcon={<GroupsIcon />}
        showDeleteButton={false}
        showEditButton={false}
        showChartButton={false}
        selectedCard={selectedCard}
        showInfo={true}
        redirect={redirectToPermissions}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = group === 'super' || group === 'admin' ? true : false;

    // const showButton = true;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="CRIAR GRUPO DE USUÁRIOS"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle="Grupos"
        description={'Total: ' + cards?.length}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar grupos"
          funcSearch={filterCircuits}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderAllCards = () => {
    return (
      <ContainerCards>
        {search ? cardsFiltered.map(renderCards) : cards.map(renderCards)}
      </ContainerCards>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards.length ? cards.length : 5}
            hasMore={true}
            loading={loading}
            scrollableTarget={'container'}
          >
            {renderAllCards()}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <IoseAddUserGroupModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          submit={addGroup}
          notificationType={notificationType}
          notification={notification}
          showNotification={showNotification}
          addNotification={addNotification}
          loading={loading}
        />
      </>
    );
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </WrappedContainer>
    </Grow>
  );
}

UserGroupsContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
