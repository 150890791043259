import React from 'react';
import { Slide } from '@material-ui/core';
import WbSunnyIcon from '@material-ui/icons/WbSunny';

import * as S from './style';

import { IoseNoDataMessage } from 'components';
import IoseAdmDashChartSolarEcu from 'components/Charts/IoseAdmDashChartSolarEcu';
import IoseAdmDashListSummarySolar from 'components/Lists/IoseAdmDashListSummarySolar';

export default function IoseAdmDashContainerSolar({ ecuData }) {
  const renderHeader = () => {
    return (
      <S.HeaderContainer>
        <S.Header>
          <WbSunnyIcon style={{ color: '#373435' }} />
          <h3>SOLAR</h3>
        </S.Header>
      </S.HeaderContainer>
    );
  };

  function renderAdmDashOrErrorMessage() {
    if (ecuData) {
      return <IoseAdmDashChartSolarEcu data={ecuData} />;
    } else {
      return (
        <IoseNoDataMessage
          message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
          height="250px"
          width="220px"
        />
      );
    }
  }

  const renderContent = () => {
    return (
      <S.ContentContainer>
        <S.LeftContainer>
          {/* <IoseAdmDashCardsSolar /> */}
          {renderAdmDashOrErrorMessage()}
        </S.LeftContainer>
        <S.RightContainer>
          <IoseAdmDashListSummarySolar data={ecuData} />
          {/* <IoseAdmDashListAlertsSolar /> */}
        </S.RightContainer>
      </S.ContentContainer>
    );
  };

  return (
    <Slide direction="left" in={true} timeout={2000} mountOnEnter unmountOnExit>
      <S.GridStyled item xs={8} md={6}>
        <S.StyledContainer>
          {renderHeader()}
          {renderContent()}
        </S.StyledContainer>
      </S.GridStyled>
    </Slide>
  );
}
