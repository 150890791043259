import styled from 'styled-components';
import Loader from 'react-loader-spinner';

export const LoaderStyle = styled(Loader)`
  margin: ${(props) => (props.margin ? props.margin : '10% 0% 0% 0%')};
`;

export const LoaderSidebarStyle = styled(Loader)`
  margin: ${(props) => (props.margin ? props.margin : '0px')};

  @media (max-width: 767px) {
    margin: ${(props) => (props.margin767px ? props.margin767px : '0px')};
  }
`;

export const LoaderDashboardStyle = styled(Loader)`
  margin: ${(props) => (props.margin ? props.margin : '10% 0% 0% 0%')};
`;
