import React from 'react';
import ReactECharts from 'echarts-for-react';
import IoseNoDataMessage from 'components/IoseNoDataMessage';

export default function GeneralManagementChart({ data }) {
  // Ordenar os meses do mais antigo para o mais atual
  const sortedData = data?.xAxis?.data.sort((a, b) => {
    const [monthA, yearA] = a.split('/');
    const [monthB, yearB] = b.split('/');
    const dateA = new Date(yearA, monthA - 1);
    const dateB = new Date(yearB, monthB - 1);
    return dateA - dateB;
  });

  let namesToRemove = ['0', 'NULL', '3 A 25 KV CONV O', '3 A 25 KV HORO-S'];

  data.series = data?.series?.filter(
    (serie) => !namesToRemove.includes(serie.name)
  );

  const option = {
    grid: {
      left: '2%',
      right: '2%',
      bottom: '5%',
      top: '5%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const formattedValues = params.map((param) => {
          const numericValue =
            typeof param.value === 'string'
              ? parseFloat(param.value)
              : param.value;

          const formattedValue = numericValue.toLocaleString('pt-BR');

          return `<strong>${param.seriesName}</strong>: ${formattedValue} (kWh)`;
        });

        return formattedValues.join('<br>') || 'Sem dados';
      },
    },
    initOpts: {
      locale: 'BR',
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: [
      {
        type: 'category',
        data: sortedData,
        axisTick: {
          alignWithLabel: true,
        },
        show: true,
      },
    ],
    yAxis: [
      {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            if (Math.abs(value) >= 1e6) {
              // Se o valor for maior ou igual a 1 milhão, exibe em milhões (M)
              return (value / 1e6).toFixed(0) + 'M';
            } else {
              // Caso contrário, exibe em milhares (K)
              return (value / 1e3).toFixed(0) + 'K';
            }
          },
        },
      },
    ],
    series: data?.series,
  };

  return data?.series?.length > 0 ? (
    <ReactECharts
      option={option}
      style={{ height: 400 }}
      key={JSON.stringify(option)}
    />
  ) : (
    <IoseNoDataMessage
      message="Ops... Parece que não há dados suficientes para exibir o gráfico!"
      height="300px"
      width="280px"
    />
  );
}
