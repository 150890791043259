import React, { useState, useEffect } from 'react';
import { AccountContext } from 'common/contexts/Accounts';
import { ConcessionaireIcon, GridContainer } from './style';
import { ContainerCards } from 'containers/SuperUserContainer/style';
import { IoseAddButton } from 'components/IoseButtonIcon';
import { IoseAlertLoadingCards } from 'components/IoseAlert';
import { IoseInfinityScroll } from 'components/IoseInfinityScroll';
import { IoseMapCard } from 'components/Cards/IoseNewCards';
import { ioseAPI } from 'common/ioseAPI';
import IoseUrlEditModal from 'components/Modals/IoseUrlEditModal';
import IoseUrlAddModal from 'components/Modals/IoseUrlAddModal';
import IoseSubHeaderBar from 'components/IoseSubHeaderBar';
import LocalStorage from 'common/local_storage_api';
import { useClient } from 'common/contexts/client';
import { useContext } from 'react';

export default function MapConfigurationContainer() {
  const [cards, setCards] = useState([]);
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const { client } = useClient();
  const { getSession } = useContext(AccountContext);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllUrls();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const page_size = 300;

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  function getAllUrls() {
    getSession().then(({ headers }) => {
      ioseAPI.getAllMapUrl(headers, 1000, page_size).then((response) => {
        let allUrls = response.data.data[0] === null ? [] : response.data.data;

        setCards(allUrls);
      });
    });
  }

  async function addMapUrl(url_vector, url_image, description) {
    let sessionData;
    try {
      sessionData = await getSession();
      //eslint-disable-next-line
      const response = await ioseAPI.createMapUrl(
        sessionData.headers,
        url_vector,
        url_image,
        description
      );
    } catch (err) {
      const errorMessage = err.response;
      throw new Error(errorMessage);
    }
  }

  const renderSubHeaderAndSearchBar = () => {
    const showButton = group === 'super' || group === 'admin' ? true : false;

    // const showButton = true;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="CADASTRAR URL"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={clientData?.name}
        subtitle="Configuração de Mapa"
        description={'Total: ' + cards.length}
        button={showButton ? button : <></>}
      >
        <p>
          Alterar
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
          ></input>
        </p>
      </IoseSubHeaderBar>
    );
  };

  const renderAllCards = () => {
    return <ContainerCards>{cards?.map(renderCards)}</ContainerCards>;
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards?.length}
            hasMore={true}
            loading={loading}
            scrollableTarget={'container'}
          >
            {renderAllCards()}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderCards = (card) => {
    return (
      <IoseMapCard
        key={card.uuid_url}
        name={card.url_vector}
        description={card.description}
        onClick={() => handleCardClick(card)}
        cardDeleteTitle={'DELETAR CONCESSIONÁRIA'}
        cardEditTitle={'EDITAR URL'}
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDeleteModal={() => {}}
        cardIcon={<ConcessionaireIcon />}
        showDeleteButton={false}
        showEditButton={true}
        showChartButton={false}
        selectedCard={selectedCard}
        showInfo={true}
      />
    );
  };

  const renderModals = () => {
    return (
      <>
        <IoseUrlAddModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          loading={loading}
          submit={addMapUrl}
        />

        <IoseUrlEditModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          loading={loading}
          selectedCard={selectedCard}
          submit={editUrl}
        />
      </>
    );
  };

  async function editUrl(uuid_url, url_vector, url_image, description) {
    try {
      const sessionData = await getSession();

      //eslint-disable-next-line
      const response = await ioseAPI.updateMapUrl(
        sessionData.headers,
        uuid_url,
        url_vector,
        url_image,
        description
      );
    } catch (err) {
      const errorMessage = err.response;
      console.error(errorMessage);
    } finally {
      setTimeout(() => {
        handleCloseEditModal();
        getAllUrls();
      }, 2500);
    }
  }

  return (
    <GridContainer container>
      {renderSubHeaderAndSearchBar()}
      {renderContent()}
      {renderModals()}
    </GridContainer>
  );
}
