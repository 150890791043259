import styled from 'styled-components';

//Imports from Material Ui Components
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EqualizerIcon from '@material-ui/icons/Equalizer';

//Import icons
import MapIcon from '@material-ui/icons/Map';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import GetAppIcon from '@material-ui/icons/GetApp';
// import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SpeedIcon from '@material-ui/icons/Speed';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import DescriptionIcon from '@material-ui/icons/Description';
//Import Image
import AdminIconImage from './img/Admin.svg';

import { CircularProgress } from '@material-ui/core';

export const StyledButton = styled(IconButton)`
  color: var(--dark);
  /* margin-top: auto; */
  margin-top: ${(props) => props.top || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
  margin-bottom: ${(props) => props.bottom || 'auto'};
  width: 30px;
  height: 30px;
  transition: all 0.3s linear;
  background-color: ${(props) =>
    props.primary ? 'transparent' : 'var(--primary)'};
  color: ${(props) => (props.primary ? 'var(--primary)' : 'var(--secondary)')};
  box-shadow: ${(props) =>
    props.card === 'true' ? 'none' : '7px 0px 22px -7px rgba(0, 0, 0, 0.75)'};
  opacity: ${(props) => props.opacity || '100%'};

  &:hover {
    background-color: ${(props) =>
      props.primary ? 'var(--primaryback)' : 'var(--base)'};
    color: ${(props) => (props.primary ? 'var(--secondary)' : 'var(--dark)')};
    opacity: 100%;
  }

  .MuiIconButton-label .MuiSvgIcon-root {
    font-size: 19px;
  }

  &:hover {
    .MuiIconButton-label .MuiSvgIcon-root {
      font-size: 23px;
      transition: all 0.15s linear;
    }
  }

  @media (max-width: 767px) {
    margin-right: ${(props) => (props.rightmobile ? props.rightmobile : '0px')};
  }
`;

export const StyledButtonAdmDash = styled(IconButton)`
  color: var(--dark);
  /* margin-top: auto; */
  margin-top: ${(props) => props.top || '5px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
  margin-bottom: ${(props) => props.bottom || 'auto'};
  width: 35px;
  height: 35px;
  transition: all 0.3s linear;
  background-color: ${(props) =>
    props.primary ? 'transparent' : 'var(--primary)'};
  color: ${(props) => (props.primary ? 'var(--secondary)' : 'var(--dark)')};
  box-shadow: ${(props) =>
    props.card === 'true' ? 'none' : '7px 0px 22px -7px rgba(0, 0, 0, 0.75)'};
  opacity: ${(props) => props.opacity || '100%'};

  &:hover {
    background-color: ${(props) =>
      props.primary ? 'var(--primaryback)' : 'var(--primary)'};
    color: ${(props) => (props.primary ? 'var(--secondary)' : 'var(--dark)')};
    opacity: 100%;
  }

  .MuiIconButton-label .MuiSvgIcon-root {
    font-size: 19px;
  }

  &:hover {
    .MuiIconButton-label .MuiSvgIcon-root {
      font-size: 23px;
      transition: all 0.15s linear;
    }
  }

  @media (max-width: 767px) {
    margin-right: ${(props) => (props.rightmobile ? props.rightmobile : '0px')};
  }
`;

export const StyledDeleteButton = styled(StyledButton)`
  background-color: ${(props) =>
    props.primary ? 'transparent' : 'var(--alert)'};
  color: ${(props) => (props.primary ? 'var(--alert)' : 'var(--dark)')};
  box-shadow: ${(props) => (props.card === 'true' ? 'none' : '')};

  &:hover {
    background-color: ${(props) =>
      props.primary ? 'var(--primaryback)' : 'var(--alert)'};
    color: ${(props) => (props.primary ? 'var(--primary)' : 'var(--dark)')};
    opacity: ${(props) => (props.card ? '100%' : '')};
  }
`;

export const StyledCalendarButton = styled(StyledButton)`
  padding: ${(props) => (props.padding ? props.padding : '8px')};
  box-shadow: ${(props) => (props.card ? 'none' : '')};
  background-color: ${(props) =>
    props.card === 'true'
      ? 'none'
      : props.currentsection === props.section
      ? 'var(--base)'
      : 'var(--primary)'};

  &:hover {
    opacity: ${(props) => (props.card ? '100%' : '')};
  }
`;

export const StyledOperatorButton = styled(StyledButton)`
  background-color: ${(props) =>
    props.primary ? 'transparent' : 'var(--primaryback)'};
  color: ${(props) => (props.primary ? 'var(--dark)' : 'var(--secondary)')};

  &:hover {
    background-color: ${(props) =>
      props.primary ? 'var(--primaryback)' : 'var(--primaryback)'};
    color: ${(props) => (props.primary ? 'var(--secondary)' : 'var(--base)')};
  }
`;

export const StyledSearchButton = styled(StyledButton)`
  background-color: ${(props) =>
    props.primary ? 'transparent' : 'var(--primary)'};
  color: ${(props) => (props.primary ? 'var(--dark)' : 'var(--dark)')};
  position: absolute;

  &:hover {
    background-color: ${(props) =>
      props.primary ? 'var(--primaryback)' : 'var(--primaryback)'};
    color: ${(props) => (props.primary ? 'var(--secondary)' : 'var(--dark)')};
  }
`;

export const StyledCloseButton = styled(StyledButton)`
  position: ${(props) => (props.position === 'absolute' ? 'absolute' : '')};
`;

export const StyledBackButton = styled(StyledButton)`
  background-color: ${(props) =>
    props.headerbar ? 'var(--primary)' : '(transparent)'};
  color: ${(props) =>
    props.headerbar ? 'var(--secondary)' : 'var(--secondary)'};
  margin-right: ${(props) => props.right || '0px'};
  box-shadow: ${(props) => (props.headerbar ? 'none' : 'none')};
  padding: 10px;

  &:hover {
    background-color: ${(props) =>
      props.headerbar ? 'var(--primaryback)' : 'var(--primaryback)'};
    color: ${(props) =>
      props.headerbar ? 'var(--secondary)' : 'var(--secondary)'};
  }
`;

export const AdminIcon = styled.img.attrs((props) => ({
  src: AdminIconImage,
}))`
  width: 18px;
  height: 18px;
`;

export const StyledDashboardButton = styled(StyledButton)`
  background-color: var(--primary);
  color: var(--secondary);

  &:hover {
    svg {
      color: var(--secondary);
    }
  }
`;

export const StyledMapButton = styled(MapIcon)`
  background-color: transparent;
  color: var(--secondary);

  &:hover {
    svg {
      color: var(--secondary);
    }
  }
`;

export const StyledCloseModalButton = styled(CloseIcon)`
  background-color: transparent;
  color: var(--secondary);

  &:hover {
    svg {
      color: var(--secondary);
    }
  }
`;

export const StyledBackAuditButton = styled(NavigateBeforeIcon)`
  background-color: transparent;
  color: var(--secondary);

  &:hover {
    svg {
      color: var(--secondary);
    }
  }
`;

export const StyledGetAppIconButton = styled(GetAppIcon)`
  background-color: transparent;
  color: var(--secondary);

  &:hover {
    svg {
      color: var(--secondary);
    }
  }
`;

export const StyledEditMeasurerIconButton = styled(SpeedIcon)`
  background-color: transparent;
  color: var(--secondary);

  &:hover {
    svg {
      color: var(--secondary);
    }
  }
`;

export const StyledEditTaxesIconButton = styled(AssignmentLateIcon)`
  background-color: transparent;
  color: var(--secondary);

  &:hover {
    svg {
      color: var(--secondary);
    }
  }
`;

export const StyledEditItensIconButton = styled(DescriptionIcon)`
  background-color: transparent;
  color: var(--secondary);

  &:hover {
    svg {
      color: var(--secondary);
    }
  }
`;

export const StyledBillsButton = styled(InsertDriveFileIcon)`
  background-color: transparent;
  color: var(--secondary);

  &:hover {
    svg {
      color: var(--secondary);
    }
  }
`;

export const StyledResendButton = styled(StyledButton)`
  background-color: ${(props) =>
    props.sidebar === 'true' ? 'var(--success)' : 'rgb(0, 166, 90, 0.5)'};
  color: var(--primary);
  align-items: ${(props) => (!props.sidebar ? 'left' : 'center')};

  &:hover {
    svg {
      color: var(--secondary);
    }
  }
`;

export const StyledUserButton = styled(StyledButton)`
  background-color: none;
  color: var(--secondary);
  box-shadow: 7px 0px 22px -7px rgba(0, 0, 0, 0.5);
`;

export const StyledInfoButton = styled(StyledButton)`
  background-color: ${(props) =>
    props.currentsection === props.section ? 'var(--base)' : 'var(--primary)'};
`;

export const StyledLogButton = styled(StyledButton)`
  background-color: ${(props) =>
    props.currentsection === props.section ? 'var(--base)' : 'var(--primary)'};
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: var(--secondary);
  width: 16px !important;
  height: 16px !important;
`;

export const DivCircularProgress = styled.div`
  padding-top: 3px;
  text-align: center;
`;

export const StyledCollapseButton = styled(StyledButton)`
  color: ${(props) => (props.color ? props.color : 'white')};
  box-shadow: none;
  transition: all 0.1s ease-in-out;
  transform: ${(props) =>
    props.expand === 'true' ? 'rotate(180deg)' : 'rotate(0deg)'};

  &:hover {
    background: var(--gray);
  }
`;

export const StyledAdmDashButton = styled(ArrowForwardIcon)`
  color: var(--secondary);
  width: 25px;
  height: 25px;
  transition: 0.3s ease;

  &:hover {
    width: 30px;
    height: 30px;
    color: var(--base);
  }
`;

export const ChartIcon = styled(EqualizerIcon)`
  color: var(--primary);
`;
