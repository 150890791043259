// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useUniqueDescriptions() {
  const [noDescriptionsError, setNoDescriptionsError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getUniqueUnityDescriptions = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getUniqueUnityDescriptions(headers);

    const uniqueDescriptions = response.data.data || [];

    if (uniqueDescriptions.length === 0) {
      setNoDescriptionsError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return uniqueDescriptions;
  };

  const {
    data: uniqueDescriptions,
    error: uniqueDescriptionsError,
    refetch: refreshUniqueDescriptions,
    isLoading,
  } = useQuery(['uniqueDescriptions'], getUniqueUnityDescriptions);

  return {
    uniqueDescriptions,
    uniqueDescriptionsError,
    noDescriptionsError,
    refreshUniqueDescriptions,
    isLoading,
  };
}

export default useUniqueDescriptions;
