import React, { useState } from 'react';
import PropType from 'prop-types';

//Imports from IOSE components
import {
  IoseEditButton,
  IoseMoreOptions,
  IoseOpenChartModal,
  IoseOpenObjectsModal,
} from 'components/IoseButtonIcon';
import { IoseDeleteInputModal } from 'components/Modals/IoseModals';
import Tooltip from '@material-ui/core/Tooltip';

//Imports from Material Ui components
import { Grow } from '@material-ui/core';

//Imports from Style
import {
  CardStyled,
  AvatarStyled,
  CardHeaderStyled,
  CardContentStyled,
  DivHeaderButton,
  DivButtons,
} from './style';

/**
 * This component create a card with all information about environment and allows editing/deleting environment.
 */
export default function IoseCardNew({
  name,
  description,
  handleOpenEditModal,
  handleOpenObjectsModal,
  onClose,
  onClick,
  cardDeleteTitle,
  cardEditTitle,
  modalTitle,
  chartModalTitle,
  cardIcon,
  showDeleteButton,
  showEditButton,
  showChartButton,
}) {
  const [moreOptions, setMoreOptions] = useState(false);

  const handleClick = () => {
    onClick();
    setMoreOptions(!moreOptions);
  };

  const renderButtonsCard = () => {
    return (
      <Grow in={true} {...{ timeout: 1000 }}>
        <DivButtons>
          {showDeleteButton === true ? (
            <IoseDeleteInputModal
              title={cardDeleteTitle}
              name={name}
              cardbutton={'true'}
              primarybutton={'true'}
            />
          ) : (
            <></>
          )}
          {showEditButton === true ? (
            <IoseEditButton
              primary="true"
              card="true"
              tooltip={cardEditTitle}
              onClick={() => handleOpenEditModal()}
            />
          ) : (
            <></>
          )}

          <IoseOpenObjectsModal
            primary="true"
            card="true"
            tooltip={modalTitle}
            onClick={() => handleOpenObjectsModal()}
          />

          {showChartButton ? (
            <IoseOpenChartModal
              primary="true"
              card="true"
              tooltip={modalTitle}
              onClick={() => handleOpenObjectsModal()}
            />
          ) : (
            <></>
          )}
        </DivButtons>
      </Grow>
    );
  };

  const renderSubtitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{description}</p>
    </Tooltip>
  );

  const renderTitle = () => (
    <Tooltip title={description} placement="bottom" arrow>
      <p>{name}</p>
    </Tooltip>
  );

  return (
    <Grow in={true} {...{ timeout: 1000 }} onClick={handleClick}>
      <CardStyled open={moreOptions}>
        <DivHeaderButton disableSpacing>
          <CardHeaderStyled
            avatar={<AvatarStyled>{cardIcon}</AvatarStyled>}
            title={renderTitle()}
            subheader={renderSubtitle()}
          ></CardHeaderStyled>
          <div>
            <IoseMoreOptions
              primary="true"
              card="true"
              onClick={() =>
                moreOptions ? setMoreOptions(false) : setMoreOptions(true)
              }
            />
          </div>
        </DivHeaderButton>

        <CardContentStyled>
          {moreOptions && renderButtonsCard()}
          {/* {renderData()} */}
        </CardContentStyled>
      </CardStyled>
    </Grow>
  );
}

IoseCardNew.propTypes = {
  /** This prop get the name of unity */
  name: PropType.string,

  /** This prop get the description of unity */
  description: PropType.string,

  /** This prop get the function when click on the button delete*/
  clickDelete: PropType.func,

  /** This prop get the function when click on the button edit*/
  clickEdit: PropType.func,

  /** This prop get the function when click on the button schedule*/
  clickSchedule: PropType.func,

  /** This prop get the function when click on the card */
  clickCard: PropType.func,
};
