import React, { useState } from 'react';

//Imports from Material UI components
import {
  makeStyles,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
  Box,
  Table,
} from '@material-ui/core';

import { IoseDeleteInputModal } from 'components/Modals/IoseModals';
import { IoseResendButton, IoseEditButton } from 'components/IoseButtonIcon';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
// import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

//Imports from Style
import {
  TableStyled,
  TableContainerStyled,
  TablePaginationStyled,
  TableCellHeader,
  Error,
  DivBalls,
  Ball,
  TableCellActions,
  DivActionIcon,
  TableCellCollapse,
  CollapseStyled,
  TableRowStyled,
  IconButtonStyled,
  CircularProgressStyled,
  TableCellCollapseStatus,
  TilteRowCollapse,
  TableCellHeaderCollapse,
} from './style';

/** This component render a responsive table with unity's schedulings, pagination and ordination */
export default function IoseSchedulerTable({
  rows,
  group,
  dashboard,
  redirectEditSheduler,
  deleteScheduler,
  getSchedulingModulesByGroup,
  resendRequistion,
  sidebar,
}) {
  //Css class to hidden the order label
  const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
      textColor: '#FFFFFF',
    },
  }));

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

  const classes = useStyles();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //Const to calc the number empty Rows
  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  // const [allGroupsTable, setAllGroupsTable] = useState(allGroups);

  // useEffect(() => {
  //   setAllGroupsTable(allGroups);
  // }, [allGroups]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //Function to render header
  function EnhancedTableHead({ classes, order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    //Const to insert labels head
    const headCells = [
      {
        id: 'buttonCollapse',
        numeric: false,
        disablePadding: false,
        label: '',
        sort: false,
      },
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Nome',
        sort: true,
      },
      {
        id: 'run_at',
        numeric: false,
        disablePadding: false,
        label: 'Horário',
        sort: true,
      },
      {
        id: 'action_relay',
        numeric: false,
        disablePadding: false,
        label: 'Ação',
        sort: true,
      },
      {
        id: 'days',
        numeric: false,
        disablePadding: false,
        label: 'Dias',
        sort: false,
      },
      {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: '',
        sort: false,
      },
    ];

    const renderTableCellHeader = (headCell) => {
      const cellSortHeader = (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );

      const cellHeader = (
        <TableCellHeader
          key={headCell.id}
          sortDirection={orderBy === headCell.id ? order : false}
          align="center"
        >
          {headCell.sort ? (
            cellSortHeader
          ) : (
            <p className="NoSortLabel">{headCell.label}</p>
          )}
        </TableCellHeader>
      );

      return cellHeader;
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => renderTableCellHeader(headCell))}
        </TableRow>
      </TableHead>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function mountScheduleData(uuid_scheduling_group, action) {
    const scheduleSelected = rows.find(
      (row) => row.uuid_scheduling_group === uuid_scheduling_group
    );

    return action === 'delete'
      ? deleteScheduler(scheduleSelected.uuid_scheduling_group)
      : redirectEditSheduler(scheduleSelected);
  }

  function renderSheduleButtons(dashboard, row) {
    return dashboard === 'true' ? (
      <TableCellActions>
        <IoseEditButton
          tooltip="Editar Agendamento"
          card={'true'}
          onClick={(e) => mountScheduleData(row.uuid_scheduling_group, 'edit')}
        ></IoseEditButton>

        {(group === 'super' || group === 'admin') && (
          <IoseDeleteInputModal
            title="DELETAR AGENDAMENTO"
            name={row.name}
            deletefunc={(e) =>
              mountScheduleData(row.uuid_scheduling_group, 'delete')
            }
            left="10px"
            cardbutton={'true'}
          />
        )}
      </TableCellActions>
    ) : (
      <></>
    );
  }

  //Function to create indication week days
  function renderIndicatorDays(row) {
    const week = row.week;
    const days = [
      { name: 'Seg', state: week.mon },
      { name: 'Ter', state: week.tue },
      { name: 'Qua', state: week.wed },
      { name: 'Qui', state: week.thu },
      { name: 'Sex', state: week.fri },
      { name: 'Sáb', state: week.sat },
      { name: 'Dom', state: week.sun },
    ];

    const ballsIndicatorWeek = (
      <DivBalls>
        {days.map((day) => (
          <Ball active={day.state} key={day.name} dashboard={dashboard}>
            {day.name} <div></div>
          </Ball>
        ))}
      </DivBalls>
    );

    if (row.repeat) {
      return ballsIndicatorWeek;
    } else {
      return 'Uma vez';
    }
  }

  function renderOnOffIcon(row) {
    return (
      <DivActionIcon action={row.action_relay.toString()}>
        <EmojiObjectsIcon />
        {row.action_relay ? 'Ligar' : 'Desligar'}
      </DivActionIcon>
    );
  }

  function convertData(dateInfo) {
    const dateLocal = new Date(Date.parse(dateInfo));

    const hour = String(dateLocal.getHours()).padStart(2, '0');
    const minute = String(dateLocal.getMinutes()).padStart(2, '0');

    const date = `${hour}:${minute}`;
    return date;
  }

  function RowCollapseCircuit({ circuit }) {
    const [loadingResend, setloadingResend] = useState(false);
    const [state, setState] = useState(circuit.state);
    const [errorResend, setErrorResend] = useState({
      status: false,
      message: '',
    });

    const handleResendRequistion = async (
      uuid_scheduling_module,
      uuid_scheduling_group
    ) => {
      setloadingResend(true);
      setErrorResend({ status: false, message: '' });

      let action = state === 'pending_update' ? 'update' : 'create';

      let response = await resendRequistion(
        uuid_scheduling_module,
        uuid_scheduling_group,
        action
      );

      let reponseGetModules = await getSchedulingModulesByGroup(
        uuid_scheduling_group
      );

      //Get circuit updated after resend
      let circuitUpdated = reponseGetModules.message.find(
        (value) => value.uuid_circuit === circuit.uuid_circuit
      );

      if (response.result) {
        setloadingResend(false);

        //Assign new state in object circuit
        circuit.state = circuitUpdated.state;

        setState(circuitUpdated.state);
      } else {
        setloadingResend(false);

        //Assign new state in object circuit
        circuit.state = circuitUpdated.state;

        setState(circuitUpdated.state);

        setErrorResend({ status: true, message: response.message });
      }

      setTimeout(() => {
        setErrorResend({ status: false, message: '' });
      }, 20000);
    };

    const statusReturn = (state) => {
      switch (state) {
        case 'resolved':
          return 'Registrado com sucesso!';

        case 'no_slot':
          return 'Sem espaço! ';

        case 'invalid_slot':
          return 'Falha na operação! ';

        case 'failure_in_module':
          return 'Falha na operação! ';

        case 'pending_update':
          return 'Edição pendente! ';

        default:
          return 'Pendente';
      }
    };

    return (
      <TableRow key={circuit.uuid_circuit}>
        <TableCellCollapse>{circuit.circuit_name}</TableCellCollapse>
        <TableCellCollapse>{circuit.circuit_description}</TableCellCollapse>
        <TableCellCollapseStatus status={state}>
          {loadingResend ? <CircularProgressStyled /> : statusReturn(state)}
          {circuit.state !== 'resolved' && errorResend.status && (
            <Error
              bottom="0px"
              justify="flex-start"
              font="12px"
              top="5px"
              bold="false"
            >
              {errorResend.message}
            </Error>
          )}
        </TableCellCollapseStatus>
        {circuit.state !== 'resolved' && (
          <TableCellCollapse>
            <IoseResendButton
              tooltip="Reenviar Requisição"
              onClick={(e) =>
                handleResendRequistion(
                  circuit.uuid_scheduling_module,
                  circuit.uuid_scheduling_group
                )
              }
              sidebar={sidebar}
            />
          </TableCellCollapse>
        )}
      </TableRow>
    );
  }

  function renderContentsCollapse(loading, error, schedulingModules) {
    const headerLabels = ['Nome', 'Descrição', 'Status'];

    const headerRowCollapse = (
      <TableHead>
        <TableRow>
          {headerLabels.map((header, index) => (
            <TableCellHeaderCollapse collapse={'true'} key={index}>
              {header}
            </TableCellHeaderCollapse>
          ))}
        </TableRow>
      </TableHead>
    );

    const bodyRowCollapse = (
      <TableBody>
        {schedulingModules.map((circuit) => (
          <RowCollapseCircuit key={circuit.uuid_circuit} circuit={circuit} />
        ))}
      </TableBody>
    );

    const renderRowCollapse = (error) => {
      if (error.status) {
        return <Error>{error.message}</Error>;
      } else {
        return (
          <>
            <TilteRowCollapse>Circuitos do Agendamento</TilteRowCollapse>
            <Table>
              {headerRowCollapse}
              {bodyRowCollapse}
            </Table>
          </>
        );
      }
    };

    if (loading) {
      return <CircularProgressStyled />;
    } else if (schedulingModules.length !== 0) {
      return <Box margin={1}>{renderRowCollapse(error)}</Box>;
    } else {
      return (
        <Error>Não existe circuitos relacionados a esse agendamento!</Error>
      );
    }
  }

  //Function to create row colapsed
  function Row({ row }) {
    const classes = useRowStyles();
    const [open, setOpen] = useState(false);

    const [loading, setloading] = useState(false);
    const [error, setError] = useState({ status: false, message: '' });
    const [schedulingModules, setSchedulingModules] = useState([]);

    const handleOpen = async (uuid_scheduling_group) => {
      setOpen(!open);

      if (schedulingModules.length === 0) {
        setloading(true);
        setError({ status: false, message: '' });

        let response =
          !open && (await getSchedulingModulesByGroup(uuid_scheduling_group));

        if (response.result) {
          setloading(false);
          setSchedulingModules(response.message);
        } else {
          setloading(false);
          setError({ status: true, message: response.message });
        }
      }
    };

    const renderIconExpand = (row) => (
      <IconButtonStyled
        aria-label="expand row"
        size="small"
        dashboard={dashboard}
        onClick={() => handleOpen(row.uuid_scheduling_group)}
        disabled={loading}
      >
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButtonStyled>
    );

    return (
      <>
        <TableRowStyled
          className={classes.root}
          dashboard={dashboard}
          tabIndex={-1}
          hover
          open={open}
        >
          <TableCell align="center">{renderIconExpand(row)}</TableCell>
          <TableCell align="center">{row.name}</TableCell>
          <TableCell align="center">{convertData(row.run_at)}</TableCell>
          <TableCell align="center">{renderOnOffIcon(row)}</TableCell>
          <TableCell align="center">{renderIndicatorDays(row)}</TableCell>

          {renderSheduleButtons(dashboard, row)}
        </TableRowStyled>

        <TableRowStyled open={open} dashboard={dashboard}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <CollapseStyled in={open} timeout={0.3} unmountOnExit>
              {renderContentsCollapse(loading, error, schedulingModules)}
            </CollapseStyled>
          </TableCell>
        </TableRowStyled>
      </>
    );
  }

  const renderHeader = () => (
    <EnhancedTableHead
      classes={classes}
      order={order}
      orderBy={orderBy}
      onRequestSort={handleRequestSort}
      rowCount={rows.length}
      dashboard={dashboard}
    />
  );

  const renderBody = () => {
    return (
      <TableBody>
        {stableSort(rows, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            return <Row key={row.uuid_scheduling_group} row={row} />;
          })}
        {/* {emptyRows > 10 && (
          <TableRow style={{ height: 33 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )} */}
      </TableBody>
    );
  };

  const renderPagination = () => (
    <TablePaginationStyled
      rowsPerPageOptions={[10, 20, 30, 40]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      dashboard={dashboard}
      labelRowsPerPage={'Agendamentos por página'}
      labelDisplayedRows={({ from, to, count }) =>
        `${from} ao ${to} de ${count !== -1 ? count : `mais que ${to}`}`
      }
    />
  );

  return (
    <>
      <TableContainerStyled dashboard={dashboard}>
        <TableStyled dashboard={dashboard}>
          {renderHeader()}
          {renderBody()}
        </TableStyled>
      </TableContainerStyled>
      {renderPagination()}
    </>
  );
}
