import React, { useContext, useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
  LoadingIndicator,
  IoseSelectReferenceMonth,
  IoseButton,
} from 'components';

import { useClient, LocalStorage, AccountContext, ioseAPI } from 'common';

import {
  useUniqueConcessionaires,
  useEnergyBillLogs,
  useGroupPermissions,
} from 'hooks';
import IoseDynamicFilter from 'components/IoseDynamicFilter';
import { IoseAddFilterButton } from 'components/IoseButtonIcon';

import Permissions from 'common/permissions';
import IoseBillLogsTable from 'components/IoseBillLogsTable';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

export default function EnergyBillLogsContainer() {
  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedConcessionaire, setSelectedConcessionaire] = useState('');
  const [selectedRefMonth, setSelectedRefMonth] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);

  const [logValues, setLogValues] = useState([]);
  const [filteredLogValues, setFilteredLogValues] = useState();

  const [selectedFilters, setSelectedFilters] = useState({});

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState(true);

  const { client } = useClient();
  const { getSession } = useContext(AccountContext);

  const { uniqueConcessionaires } = useUniqueConcessionaires();
  const { energyBillLogs } = useEnergyBillLogs();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['faturas', 'log_faturas', 'leitura'];
    const reportPath = ['faturas', 'log_faturas', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filteredLogValues) {
      setLogValues(filteredLogValues);
    } else {
      setLogValues(energyBillLogs);
    }
  }, [energyBillLogs, filteredLogValues]);

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const RenderFilterContainer = () => {
    const filterOptions = ['Concessionaria', 'REF: MÊS/ANO'];

    const toggleFilters = () => {
      setShowFilters(!showFilters);
    };

    const handleOptionClick = (option) => {
      setSelectedOption(option);
      setShowFilters(false);
    };

    const handleConcessionariaChange = (value) => {
      setSelectedConcessionaire(value);
    };

    const handleRefMonthChange = (value) => {
      const formattedDate = formatToBrazilianDate(value);

      setSelectedDate(value);
      setSelectedRefMonth(formattedDate);
    };

    const renderSelectedComponent = () => {
      const filterOptions = {
        Concessionaria: {
          label: 'Concessionaria',
          databaseName: 'concessionaire',
          value: selectedConcessionaire,
          onChange: handleConcessionariaChange,
          options: uniqueConcessionaires,
        },

        'REF: MÊS/ANO': {
          label: 'REF: MÊS/ANO',
          databaseName: 'reference_month',
          value: selectedRefMonth,
          onChange: handleRefMonthChange,
        },
      };

      const selectedFilter = filterOptions[selectedOption];

      const handleButtonClick = () => {
        if (selectedFilter && selectedFilter.databaseName) {
          const formattedFilter = { ...selectedFilters };

          if (selectedFilter.databaseName === 'previous_reading') {
            if (selectedFilter.initial_value && selectedFilter.final_value) {
              formattedFilter[
                'previous_reading_start'
              ] = selectedFilter.initial_value.toLocaleDateString();
              formattedFilter[
                'previous_reading_end'
              ] = selectedFilter.final_value.toLocaleDateString();
            }
          }
          if (selectedFilter.databaseName === 'vencimento') {
            if (selectedFilter.initial_value && selectedFilter.final_value) {
              formattedFilter[
                'vencimento_start'
              ] = selectedFilter.initial_value.toLocaleDateString();
              formattedFilter[
                'vencimento_end'
              ] = selectedFilter.final_value.toLocaleDateString();
            }
          } else {
            if (selectedFilter.value) {
              formattedFilter[selectedFilter.databaseName] =
                selectedFilter.value;
            }
          }

          setSelectedFilters(formattedFilter);
        }

        setShowFilters(false);
        setSelectedOption(null);
      };

      const handleRemoveFilter = (key) => {
        const updatedFilters = { ...selectedFilters };

        if (updatedFilters[key]) {
          delete updatedFilters[key];

          setSelectedFilters(updatedFilters);
        }
      };

      async function getFilteredLogValues(object) {
        try {
          const sessionData = await getSession();

          const response = await ioseAPI.getFilteredEnergyBillLogs(
            sessionData.headers,
            object
          );

          const { data } = response.data;

          setFilteredLogValues(data);
          setSelectedFilters({});
        } catch (error) {
          console.log(error);
        }
      }

      return (
        <S.FilterBox>
          {selectedFilter && (
            <>
              {selectedFilter.label === 'REF: MÊS/ANO' ? (
                <IoseSelectReferenceMonth
                  label={selectedFilter.label}
                  handleChange={selectedFilter.onChange}
                  selectedDate={selectedDate}
                />
              ) : (
                <IoseDynamicFilter
                  label={selectedFilter.label}
                  value={selectedFilter.value}
                  onChange={selectedFilter.onChange}
                  options={selectedFilter.options}
                />
              )}
              <IoseButton onClick={handleButtonClick}>
                ADICIONAR FILTRO
              </IoseButton>
            </>
          )}

          <div style={{ marginLeft: '25px' }}>
            {Object.entries(selectedFilters).length > 0 && (
              <S.FilterCardContainer>
                <h3>Filtros Selecionados:</h3>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {Object.entries(selectedFilters).map(([key, value]) => (
                    <S.FilterCardDiv key={key}>
                      <S.FilterCardValue>{value}</S.FilterCardValue>
                      <S.CloseButton onClick={() => handleRemoveFilter(key)}>
                        <S.CloseCardIcon />
                      </S.CloseButton>
                    </S.FilterCardDiv>
                  ))}
                </div>
                <IoseButton
                  left={'15px'}
                  onClick={() => getFilteredLogValues(selectedFilters)}
                >
                  FILTRAR
                </IoseButton>
              </S.FilterCardContainer>
            )}
          </div>
        </S.FilterBox>
      );
    };

    return (
      <S.FilterContainer>
        <IoseAddFilterButton
          onClick={toggleFilters}
          tooltip={'ADICIONAR FILTRO'}
        />

        {showFilters && (
          <S.FilterBox>
            {filterOptions.map((option, index) => (
              <S.OptionsButton
                key={index}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </S.OptionsButton>
            ))}
          </S.FilterBox>
        )}

        {renderSelectedComponent()}
      </S.FilterContainer>
    );
  };

  const renderTable = () => {
    return (
      <S.ContainerTable>
        <IoseBillLogsTable
          tableTitle=""
          searchInTable={false}
          data={logValues}
          hasReportPermission={hasReportPermission}
        />
      </S.ContainerTable>
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    // const showButton = group === 'super' || group === 'admin' ? true : false;

    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle="Log de Faturas"
        // description={'Total: ' + allContracts?.length}
        button={showButton ? button : <></>}
      />
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div>{renderTable()}</div>
        </>
      );
    }
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <S.WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {RenderFilterContainer()}
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : hasReadPermission ? (
          renderContent()
        ) : (
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        )}
      </S.WrappedContainer>
    </Grow>
  );
}

EnergyBillLogsContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
