// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useSolarEcus() {
  const [noEcuError, setNoEcuError] = useState('');

  const { getSession } = useContext(AccountContext);

  const page_size = 300;

  const getSolarEcus = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllSolarEcus(headers, 10000, page_size);

    const solarEcus = response.data.data || [];

    if (solarEcus.length === 0) {
      setNoEcuError(Messages.noRegisteredEcus);
      throw new Error(Messages.noRegisteredEcus);
    }
    return solarEcus;
  };

  const {
    data: solarEcus,
    error: solarEcusError,
    refetch: refreshSolarEcus,
    isLoading,
  } = useQuery(['solarEcusData'], getSolarEcus);

  return {
    solarEcus,
    solarEcusError,
    noEcuError,
    refreshSolarEcus,
    isLoading,
  };
}

export default useSolarEcus;
