import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';

//Imports from IOSE components
import IoseButton from 'components/Buttons/IoseButton';
// import IoseChoosePeriod from 'components/IoseChoosePeriod';
import IoseCircuitTable from 'components/IoseCircuitTable';
import IoseCircuitMeasuresTable from 'components/IoseCircuitMeasuresTable';
import IoseSelection from 'components/IoseSelection';
import { LoadingIndicator } from 'components/IoseLoader';
import { IoseCloseButton } from 'components/IoseButtonIcon';
import { IoseDowloadCSVModal } from 'components/Modals/IoseModals';
import IoseSelectDayMouth from 'components/IoseSelectDayMouth';

//Imports from Material Icon
import BarChartIcon from '@material-ui/icons/BarChart';
import HistoryIcon from '@material-ui/icons/History';
import Toc from '@material-ui/icons/Toc';

//Imports to generate Delete Alert
// import { useAlert } from 'react-alert';

//Imports from Style
import {
  Container,
  GridContainer,
  GridItem,
  GridChart,
  Header,
  TitleTable,
  GridChartContainer,
  Error,
} from './style';
import IoseNewChartGeneration from 'components/IoseNewChartGeneration';
import IoseNewChartConsumption from 'components/IoseNewChartConsumption';

/** This component create a card in dashboard with groups of components includes charts and tables for analysis consumption */
export default function IoseDashboardAnalysisCard({
  group,
  showCircuit,
  dataTable,
  dataChartBar,
  dataTableHistoric,
  redirectEditCircuit,
  deleteCircuit,
  setDate,
  setHistoricDate,
  getAllMeasuresAllCircuit,
  loading,
  loadingChart,
  setPageCodeHistoricTable,
  setDataTableHistoric,
  goal,
  switchboardData,
  initialDateHistoric,
  finalDateHistoric,
  lastMeasuresError,
  alltMeasuresError,
  getInfoError,
  triggerCircuit,
  triggerCircuitAction,
  chartMode,
  setChartMode,
  chartType,
  setChartType,
  initialDateContainer,
  finalDateContainer,
  circuits,
  limitsChartBar,
  loadingSeeHistoric,
  changeChart,
  modifiedChart,
  showButton,
  dataChanged,
}) {
  const [seeHistoric, setSeeHistoric] = useState(false);
  const [initialDate, setInitialDate] = useState(initialDateContainer);
  const [finalDate, setFinalDate] = useState(finalDateContainer);
  const [error, setError] = useState('');

  const [ordernationCSV, setOrderingCSV] = useState({
    order: 'desc',
    orderBy: 'data',
  });

  console.log('dataChartBar: ', dataChartBar);

  const [suffixCSV, setSuffixCSV] = useState('');

  useEffect(() => {
    setSeeHistoric(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuits]);

  useEffect(() => {
    if (seeHistoric) {
      suffixFileCSV(initialDateHistoric, finalDateHistoric);
      getAllMeasuresAllCircuit(true);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDateHistoric, finalDateHistoric]);

  function goToHistoric() {
    suffixFileCSV(initialDateHistoric, finalDateHistoric);
    getAllMeasuresAllCircuit(true);
    return setSeeHistoric(true);
  }

  const headerFileCSV = [
    { id: 'data', displayName: 'Data e Hora' },
    { id: 'circuit', displayName: 'Circuito' },
    { id: 'voltage', displayName: 'Tensão (V)' },
    { id: 'current', displayName: 'Corrente (A)' },
    { id: 'export_active_energy', displayName: 'Energia Gerada Ativa (kWh)' },
    {
      id: 'import_active_energy',
      displayName: 'Energia Consumida Ativa (kWh)',
    },
    { id: 'active_power', displayName: 'Potência Ativa (W)' },
    { id: 'reactive_power', displayName: 'Potência Reativa (W)' },
    { id: 'apparent_power', displayName: 'Potência Aparente (W)' },
    {
      id: 'export_reactive_energy',
      displayName: 'Energia Gerada Reativa (kWh)',
    },
    {
      id: 'import_reactive_energy',
      displayName: 'Energia Consumida Reativa (kWh)',
    },
    { id: 'line_frequency', displayName: 'Frequência (Hz)' },
    { id: 'power_factor', displayName: 'Fator de Potência' },
    { id: 'relay_state', displayName: 'Estado do Relé' },
    { id: 'uuid', displayName: 'ID do Circuito' },
  ];

  const suffixFileCSV = (dateInitial, dateFinal) => {
    const initialDateSplitted = new Date(dateInitial).toString().split(' ');
    const finalDateSplitted = new Date(dateFinal).toString().split(' ');

    let suffixCSV = `_${switchboardData.name}__${initialDateSplitted[2]}_${initialDateSplitted[1]} à ${finalDateSplitted[2]}_${finalDateSplitted[1]}`;

    return setSuffixCSV(suffixCSV);
  };

  const handleDateContainer = () => {
    setError('');

    const initialDateObj = new Date(initialDate);
    const finalDateObj = new Date(finalDate);

    const diffMiliSeconds = finalDateObj - initialDateObj;
    const diffDays = Math.round(diffMiliSeconds / (1000 * 60 * 60 * 24));

    if (initialDateObj > finalDateObj) {
      setError('A data final está menor que a data inicial!');
    } else if (diffDays > 31) {
      setError('A diferença entre as datas está ultrapassando 31 dias!');
    } else {
      setDate(initialDate, finalDate);
    }
  };

  function handleCloseButton() {
    setDataTableHistoric([]);
    setSeeHistoric(false);
    setPageCodeHistoricTable([]);
    setHistoricDate(initialDateContainer, finalDateContainer);
  }

  const handleInitialDate = (dateInitial) => {
    let dateInitialISOString = dateInitial.toISOString();
    setInitialDate(dateInitialISOString);
  };

  const handleFinalDate = (dateFinal) => {
    let dateFinalISOString = dateFinal.toISOString();
    setFinalDate(dateFinalISOString);
  };

  return (
    <Container>
      <Header>
        <h3>
          <span>
            <BarChartIcon />
            GRÁFICO DE CONSUMO
          </span>
          <div>
            <IoseSelection
              title=""
              object={['Com Geração', 'Sem Geração']}
              defaultvalue={chartType}
              func={setChartType}
              top={'0px'}
              right={'16px'}
            />
            <IoseSelection
              title=""
              object={['Acumulado', 'Diário']}
              defaultvalue={chartMode}
              func={setChartMode}
              top={'0px'}
            />
          </div>
        </h3>
      </Header>

      <GridContainer
        justify="flex-start"
        marginmobile="0px"
        alignItems="center"
      >
        <GridItem
          xs={11}
          md={4}
          border="true"
          justifycontent="space-between"
          margintop="7px"
        >
          <IoseSelectDayMouth
            title="Dia inicial "
            theme="black"
            getDate={handleInitialDate}
            initialDate={initialDate}
            margin={'0xp 50px 0px 0px'}
          />

          <IoseSelectDayMouth
            title="Dia final "
            theme="black"
            getDate={handleFinalDate}
            initialDate={finalDate}
            margin={'0xp 50px 0px 0px'}
          />

          <IoseButton theme="black" onClick={() => handleDateContainer()}>
            Aplicar
          </IoseButton>
        </GridItem>
      </GridContainer>

      {/* {getInfoError !== '' && <Error>{getInfoError}</Error>} */}
      {error !== '' && <Error>{error}</Error>}

      {loadingChart && <LoadingIndicator loading={loadingChart} margin="0%" />}

      <GridChartContainer data={dataChartBar.length}>
        <GridChart data={dataChartBar.length}>
          {chartType === 'Sem Geração' ? (
            <IoseNewChartConsumption data={dataChartBar} goal={goal} />
          ) : (
            <IoseNewChartGeneration
              data={dataChartBar}
              goal={goal}
              changeChart={changeChart}
              modifiedChart={modifiedChart}
              dataChanged={dataChanged}
            />
          )}
        </GridChart>
      </GridChartContainer>

      <GridContainer
        direction="column"
        justify="flex-start"
        paddingmobile="0px"
        marginbottom="0px"
      >
        {seeHistoric ? (
          <>
            <TitleTable justify="space-between" margintop="30px">
              <span>
                <HistoryIcon />
                <h3>HISTÓRICO DE MEDIDAS</h3>
              </span>
              <span>
                <IoseDowloadCSVModal
                  title="Download Histórico de Medidas"
                  suffix={suffixCSV}
                  headerFileCSV={headerFileCSV}
                  dataCSV={dataTableHistoric}
                  loading={loadingSeeHistoric}
                  order={ordernationCSV.order}
                  orderBy={ordernationCSV.orderBy}
                />
                <IoseCloseButton
                  onClick={(e) => handleCloseButton(e)}
                  left={'10px'}
                />
              </span>
            </TitleTable>

            {loading ? (
              <LoadingIndicator loading={loading} />
            ) : (
              <>
                <IoseCircuitMeasuresTable
                  dashboard="true"
                  rows={dataTableHistoric}
                  getAllMeasuresAllCircuit={getAllMeasuresAllCircuit}
                  alltMeasuresError={alltMeasuresError}
                  setOrderingCSV={setOrderingCSV}
                />
              </>
            )}
          </>
        ) : (
          <>
            <TitleTable justify="space-between" marginright="20px">
              <span>
                <Toc />
                <h3>CONSUMO POR CIRCUITO</h3>
              </span>

              <IoseButton theme="black" onClick={goToHistoric}>
                <HistoryIcon style={{ marginRight: '8px' }} />
                Ver Histórico de medidas
              </IoseButton>
            </TitleTable>
            <GridContainer marginbottom="0px" direction="column">
              <IoseCircuitTable
                rows={dataTable}
                showCircuit={showCircuit}
                redirectEditCircuit={redirectEditCircuit}
                deleteCircuit={deleteCircuit}
                dashboard="true"
                group={group}
                triggerCircuit={triggerCircuit}
                triggerCircuitAction={triggerCircuitAction}
              />
            </GridContainer>
          </>
        )}
      </GridContainer>
    </Container>
  );
}

IoseDashboardAnalysisCard.propTypes = {
  /** This props is data to form IoseTableCircuit and its format was shown in IoseTableCircuit session. In IoseDashboardAnalysisCard this data is received from container */
  dataTable: PropType.array,
  /** This props is data to form IoseChartConsumption and its format was shown in IoseChartConsumption session. In IoseDashboardAnalysisCard this data is received from container */
  dataChartBar: PropType.array,
  /** This props is data to form IoseCircuitMeasureTable and its format was shown in IoseCircuitMeasureTable session. In IoseDashboardAnalysisCard this data is received from container */
  dataTableHistoric: PropType.array,
  /** This props is a function that redirect to IoseCircuitShowCard. In IoseDashboardAnalysisCard this function is just passed to IoseTableCircuit*/
  showCircuit: PropType.func,
  /** This props is a function that redirect to IoseFormCircuitEditCard. In IoseDashboardAnalysisCard this function is just passed to IoseTableCircuit  */
  redirectEditCircuit: PropType.func,
  /** This props is a function that to delete circuit. In IoseDashboardAnalysisCard this function is just passed to IoseTableCircuit*/
  deleteCircuit: PropType.func,
  /** This props is a function to set intial and final dates in container.In IoseDashboardAnalysisCard this function is just passed to IoseChartConsumption  */
  setDate: PropType.func,
  /** This props is a function to set intial and final dateshistoric in container.In IoseDashboardAnalysisCard this function is just passed to IoseCircuitMeasureTable */
  setHistoricDate: PropType.func,
  /** This props is a function when invoke get all measure that all circuits in container, it is used to pagination. In IoseDashboardAnalysisCard this function is just passed to IoseCircuitMeasureTable */
  getAllMeasuresAllCircuit: PropType.func,
  /** This props is a function when invoke set pageCodeHistoric variable in container. In IoseDashboardAnalysisCard is used to return first page measures in DB in next requisition */
  setPageCodeHistoricTable: PropType.func,
  /** This props is a function when invoke set dataTable variable in container. In IoseDashboardAnalysisCard to reset info in IoseCircuitMeasureTable when user close it*/
  setDataTableHistoric: PropType.func,
  /** This props indicates which group's user and from there show actuation or not. In IoseDashboardAnalysisCard this function is just passed to IoseTableCircuit*/
  group: PropType.string,
  /** This props is  initial historic date used in IoseCircuitMeasureTable*/
  initialDateHistoric: PropType.oneOfType([
    PropType.string,
    PropType.instanceOf(Date),
  ]),
  /** This props is  final historic date used in IoseCircuitMeasureTable*/
  finalDateHistoric: PropType.string,
  /** This props is  show a error related getlastmeasure requesition*/
  lastMeasuresError: PropType.string,
  /** This props is  show a error related getallmeasure requesition*/
  alltMeasuresError: PropType.string,
  /** This props is  show spin related to IoseChartConsumption. In IoseDashboardAnalysisCard this function is just passed to IoseChartConsumption */
  loading: PropType.bool,
  /** This props is  show spin related to IoseCircuitMeasureTable. In IoseDashboardAnalysisCard this function is just passed to IoseCircuitMeasureTable */
  loadingChart: PropType.bool,
  /** This props is  goal used in IoseChartConsumption. In IoseDashboardAnalysisCard this function is just passed to IoseChartConsumption */
  goal: PropType.number,
  /** This props is  use just to take switchboard name and put it in suffix name in file download CSV*/
  switchboardData: PropType.object,
};
