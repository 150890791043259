import React, { useState, useContext } from 'react';
import PropType from 'prop-types';

import IoseApiLib from 'iose-api-lib';
import { Content } from 'components/IoseSideContainer';

//Hook to send data throught urls
import { useLocation } from 'react-router-dom';

//Imports from Accounts
import { AccountContext } from 'common/contexts/Accounts';

//Import from ioseAPI
import { ioseAPI } from 'common/ioseAPI';

import { RoutesUnityOperations } from 'routers';
// import { useHistory } from 'react-router-dom';

//Import from Messages
import Messages from '../../common/Messages';

import { IoseAlertErro, IoseAlertSuccess } from 'components/IoseAlert';
import { LoadingIndicatorSidebar } from 'components/IoseLoader';

import { useCreateLogs } from 'hooks';

export default function SidebarUnityOperationsContainer({
  // addUnity,
  setGetcards,
  setEmpytArray,
  closeSide,
}) {
  let location = useLocation();
  const uuid_client = location.state.uuid_client;

  //This function return session data
  const { getSession } = useContext(AccountContext);

  const { createLog } = useCreateLogs();

  //State to change notification type and your data
  const [notificationType, setNotificationType] = useState(0);
  const [notification, setNotification] = useState('');

  //State to set spin loading
  const [loading, setLoading] = useState(false);

  const verifyAllTariffDataIsFilled = (tariffData) => {
    let emptyVerify = [];
    const tariffDataKeys = Object.keys(tariffData);

    for (let key of tariffDataKeys) {
      if (tariffData[key] === '') {
        emptyVerify.push(true);
      } else {
        emptyVerify.push(false);
      }
    }

    return emptyVerify.every((element) => element === false);
  };

  //Functions to EDIT Unity
  function errorHandlingEditUnity(error) {
    setLoading(false);
    setNotification(error);
    setNotificationType(2);
  }

  async function editUnity(
    uuid_unity,
    name,
    description,
    tariff_period,
    contracted_demand,
    tariff_data,
    identificationNumber,
    bin_link,
    id_3d
  ) {
    setNotificationType(0);

    try {
      const allFilled = verifyAllTariffDataIsFilled(tariff_data);

      if (allFilled) {
        setLoading(true);

        const sessionData = await getSession();

        const response = await ioseAPI.updateUnity(
          sessionData.headers,
          uuid_unity,
          name,
          description,
          tariff_period,
          contracted_demand,
          tariff_data,
          identificationNumber,
          bin_link,
          id_3d
        );

        setLoading(false);
        setNotification(response.data.message);
        setNotificationType(1);
        setGetcards(true);

        const operacao = 'EDITAR';
        const form = 'EDITAR UNIDADE';
        const logContent = {
          uuid_unity: uuid_unity,
          uuid_client: uuid_client,
          name: name,
          description: description,
          tariff_period: tariff_period,
          contracted_demand: contracted_demand,
          tariff_data: tariff_data,
          identification_Number: identificationNumber,
          bin_link: bin_link,
          id_3d: id_3d,
        };

        await createLog(operacao, form, logContent);
      } else {
        let err = {
          response: { data: { message: Messages.someEmptyTariffData } },
        };

        throw err;
      }
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.unityNoRegistered;

      errorHandlingEditUnity(errorMessage);
    }
  }

  // Functions to ADD Unity
  function errorHandlingAddUnity(error) {
    setLoading(false);

    setNotification(error);
    setNotificationType(2);
  }

  async function addUnity(
    name,
    description,
    tariff_period,
    contracted_demand,
    tariff_data,
    identificationNumber,
    bin_link,
    id_3d
  ) {
    setNotificationType(0);
    setLoading(true);

    try {
      const allFilled = verifyAllTariffDataIsFilled(tariff_data);

      if (allFilled) {
        const sessionData = await getSession();

        const response = await ioseAPI.createUnity(
          sessionData.headers,
          uuid_client,
          name,
          description,
          tariff_period,
          contracted_demand,
          tariff_data,
          identificationNumber,
          bin_link,
          id_3d
        );

        setLoading(false);
        setNotification(response.data.message);
        setNotificationType(1);
        setGetcards(true);
        setEmpytArray(false);

        const operacao = 'CRIAR';
        const form = 'CRIAR UNIDADE';
        const logContent = {
          uuid_client: uuid_client,
          name: name,
          description: description,
          tariff_period: tariff_period,
          contracted_demand: contracted_demand,
          tariff_data: tariff_data,
          identification_Number: identificationNumber,
          bin_link: bin_link,
          id_3d: id_3d,
        };

        await createLog(operacao, form, logContent);
      } else {
        let err = {
          response: { data: { message: Messages.someEmptyTariffData } },
        };

        throw err;
      }
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.unityNoRegistered;

      errorHandlingAddUnity(errorMessage);
    }
  }

  //Function to get all agents to fill combo boxes in forms
  async function getAllAgents() {
    try {
      const response = await IoseApiLib.Agent.getAllAgents();

      return response.data;
    } catch (error) {
      let messageError = error.response
        ? error.response.data.message
        : 'Error ao buscar dados para formulário!';

      setNotification(messageError);
      setNotificationType(2);
    }
  }

  async function getFilterAgents(
    name_agent,
    subgroup,
    modality,
    acessor,
    class_agent,
    subclass
  ) {
    try {
      const response = await IoseApiLib.Agent.getFilterAgents(
        name_agent,
        subgroup,
        modality,
        acessor,
        class_agent,
        subclass
      );

      return { response };
    } catch (error) {
      let messageError = error.response
        ? error.response.data.message
        : 'Error ao filtrar dados para formulário!';

      setNotification(messageError);
      setNotificationType(2);
    }
  }

  return (
    <>
      <Content height={'auto'}>
        {/**Notifications*/}
        {notificationType === 1 && <IoseAlertSuccess text={notification} />}

        {notificationType === 2 && <IoseAlertErro text={notification} />}
        <LoadingIndicatorSidebar loading={loading} />
        <RoutesUnityOperations
          addUnity={addUnity}
          editUnity={editUnity}
          getAllAgents={getAllAgents}
          getFilterAgents={getFilterAgents}
          closeSide={closeSide}
        />
      </Content>
    </>
  );
}

SidebarUnityOperationsContainer.propTypes = {
  /** This props get a function addUnity of UnityContainer*/
  addUnity: PropType.func,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
};
