// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useUniqueGeneralManagement() {
  const [noGeneralManagementError, setNoGeneralManagementError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getUniqueGeneralManagement = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getUniqueGeneralManagement(headers);

    const generalManagements = response.data.data || [];

    if (generalManagements.length === 0) {
      setNoGeneralManagementError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return generalManagements;
  };

  const {
    data: generalManagements,
    error: generalManagementsError,
    refetch: refreshGeneralManagement,
    isLoading,
  } = useQuery(['generalManagementss'], getUniqueGeneralManagement);

  return {
    generalManagements,
    generalManagementsError,
    noGeneralManagementError,
    refreshGeneralManagement,
    isLoading,
  };
}

export default useUniqueGeneralManagement;
