import React, { useEffect, useState, useContext } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';
import { useAlert } from 'react-alert';

import {
  IoseAddContractsModal,
  IoseContractsDeleteModal,
  IoseEditContractsModal,
  IoseAlertLoadingCards,
  IoseInfinityScroll,
  IoseCardContracts,
  IoseSubHeaderBar,
  IoseSearch,
  IoseAddButton,
} from 'components';

import {
  AccountContext,
  ioseAPI,
  LocalStorage,
  Messages,
  useClient,
} from 'common';

import { ContainerCards, ContractsIcon, WrappedContainer } from './style';

import {
  useConcessionaires,
  useContracts,
  useCreateLogs,
  useGroupPermissions,
} from 'hooks';

import Permissions from 'common/permissions';

export default function ContractContainer({ getcards }) {
  const [addNotification, setAddNotification] = useState('');
  const [allConcessionaires, setAllConcessionaires] = useState([]);
  const [cards, setCards] = useState([]);
  const [cardsFiltered, setCardsFiltered] = useState([]);
  const [clientData, setClientData] = useState({});
  const [deleteNotificationType, setDeleteNotificationType] = useState(0);
  const [deleteNotification, setDeleteNotification] = useState('');
  const [editNotificationType, setEditNotificationType] = useState(0);
  const [editNotification, setEditNotification] = useState('');
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const [notificationType, setNotificationType] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [search, setSearch] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasAddPermission, setHasAddPermission] = useState();
  const [hasEditPermission, setHasEditPermission] = useState();
  const [hasDeletePermission, setHasDeletePermission] = useState();

  const alert = useAlert();
  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { contracts, noContractsError, refreshContracts } = useContracts();
  const { concessionaires } = useConcessionaires();
  const { createLog } = useCreateLogs();
  const { groupPermissions } = useGroupPermissions();

  const userSession = LocalStorage.getSession();

  useEffect(() => {
    const readPath = ['cadastro', 'contratos', 'leitura'];
    const addPath = ['cadastro', 'contratos', 'criar'];
    const editPath = ['cadastro', 'contratos', 'editar'];
    const deletePath = ['cadastro', 'contratos', 'deletar'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const addPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        addPath
      );
      setHasAddPermission(addPermission);

      const editPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        editPath
      );
      setHasEditPermission(editPermission);

      const deletePermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        deletePath
      );
      setHasDeletePermission(deletePermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    contracts?.length > 0 ? setCards(contracts) : setError(noContractsError);
  }, [contracts, noContractsError]);

  useEffect(() => {
    setAllConcessionaires(concessionaires);
  }, [concessionaires]);

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  function errorHandlingAddConcessionaires(error) {
    setLoading(false);

    setNotification(error);
    setNotificationType(2);
  }

  function errorHandlingEditConcessionaires(error) {
    setLoading(false);
    setNotification(error);
    setEditNotificationType(2);
  }

  function errorHandlingDeleteConcessionaires(error) {
    alert.show(error ? error : `${Messages.errorDeleteConcessionaires}`, {
      title: 'Erro!',
      closeCopy: 'Fechar',
    });
  }

  /// ========== OPERATIONS ===========

  async function addContract(
    contractNumber,
    marketType,
    concessionaire,
    termOfContract,
    readjustmentIndex,
    readjustmentRate,
    initialDate,
    finalDate,
    totalContracts,
    monthlyValue,
    note,
    daysExpire,
    s3Link
  ) {
    setNotificationType(0);
    setLoading(true);

    let sessionData;

    try {
      sessionData = await getSession();
      const response = await ioseAPI.createContract(
        sessionData.headers,
        contractNumber,
        marketType,
        concessionaire,
        termOfContract,
        readjustmentIndex,
        readjustmentRate,
        initialDate,
        finalDate,
        totalContracts,
        monthlyValue,
        note,
        daysExpire,
        s3Link
      );

      setAddNotification(response.data.message);
      setNotificationType(1);

      const operacao = 'CRIAR';
      const form = 'CRIAR CONTRATO';
      const logContent = {
        contractNumber: contractNumber,
        marketType: marketType,
        concessionaire: concessionaire,
        termOfContract: termOfContract,
        readjustmentIndex: readjustmentIndex,
        readjustmentRate: readjustmentRate,
        initialDate: initialDate,
        totalContracts: totalContracts,
        monthlyValue: monthlyValue,
        note: note,
        daysExpire: daysExpire,
        s3Link: s3Link,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.contractsNoRegistered;
      errorHandlingAddConcessionaires(errorMessage);
    } finally {
      setLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseAddModal();
        refreshContracts();
        setNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  async function editContract(
    uuid_contract,
    contractNumber,
    marketType,
    concessionaire,
    termOfContract,
    readjustmentIndex,
    readjustmentRate,
    initialDate,
    finalDate,
    totalContracts,
    monthlyValue,
    note,
    daysExpire,
    s3Link
  ) {
    setEditNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.updateContract(
        sessionData.headers,
        uuid_contract,
        contractNumber,
        marketType,
        concessionaire,
        termOfContract,
        readjustmentIndex,
        readjustmentRate,
        initialDate,
        finalDate,
        totalContracts,
        monthlyValue,
        note,
        daysExpire,
        s3Link
      );

      setEditNotification(response.data.message);
      setEditNotificationType(1);

      const operacao = 'EDITAR';
      const form = 'EDITAR CONTRATO';
      const logContent = {
        uuid_contract: uuid_contract,
        contractNumber: contractNumber,
        marketType: marketType,
        concessionaire: concessionaire,
        termOfContract: termOfContract,
        readjustmentIndex: readjustmentIndex,
        readjustmentRate: readjustmentRate,
        initialDate: initialDate,
        totalContracts: totalContracts,
        monthlyValue: monthlyValue,
        note: note,
        daysExpire: daysExpire,
        s3Link: s3Link,
      };

      await createLog(operacao, form, logContent);
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : Messages.concessionairesNoRegistered;

      errorHandlingEditConcessionaires(errorMessage);
      setEditNotificationType(2);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setShowNotification(true);
      setTimeout(() => {
        handleCloseEditModal();
        refreshContracts();
        setEditNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  async function deleteContract(uuid_contract) {
    setDeleteNotificationType(0);
    setLoading(true);

    try {
      const sessionData = await getSession();

      const response = await ioseAPI.deleteContract(
        sessionData.headers,
        uuid_contract
      );

      setDeleteNotification(response.data.message);
      setDeleteNotificationType(1);

      const operacao = 'DELETAR';
      const form = 'DELETAR CONTRATO';
      const logContent = {
        uuid_contract: uuid_contract,
      };

      await createLog(operacao, form, logContent);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : Messages.errorDeleteContracts;

      errorHandlingDeleteConcessionaires(errorMessage);
      setDeleteNotificationType(2);
      setShowNotification(true);
    } finally {
      setLoading(false);
      setShowNotification(true);

      setTimeout(() => {
        handleCloseDeleteModal();
        refreshContracts();
        setDeleteNotificationType(0);
        setShowNotification(false);
      }, 2500);
    }
  }

  async function uploadFile(file) {
    try {
      const sessionData = await getSession();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async () => {
          const base64Data = reader.result.split(',')[1];

          try {
            const response = await ioseAPI.uploadContractFile(
              sessionData.headers,
              base64Data
            );

            resolve(response.data);
          } catch (error) {
            reject(error);
          }
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    } catch (error) {
      throw error;
    }
  }

  async function removeFile(file_link) {
    try {
      const sessionData = await getSession();

      const response = await ioseAPI.deleteContractFile(
        sessionData.headers,
        file_link
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  /// ========== OPERATIONS ===========

  const getClientData = async () => {
    setError('');
    setLoading(true);

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);

    setLoading(false);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  //Function to filter Unity
  const filterCircuits = (searchName) => {
    setError('');
    setSearch(true);

    const searchNameUppcase = searchName.toUpperCase();

    if (searchName !== '') {
      const itemFiltered = cards.filter((item) =>
        item?.contract_number?.toUpperCase().includes(searchNameUppcase)
      );

      itemFiltered.length !== 0
        ? setCardsFiltered(itemFiltered)
        : setError(Messages.noFindCircuits);
    } else {
      setSearch(false);
    }
  };

  //Functions to RENDER
  // eslint-disable-next-line
  const renderAlert = (message, type) => {
    return alert.show(`${message}`, {
      title: type,
      closeCopy: 'Fechar',
    });
  };

  const renderCards = (card) => {
    return (
      <IoseCardContracts
        key={card.uuid_contract}
        name={card.contract_number}
        description={card.concessionaire}
        onClick={() => handleCardClick(card)}
        cardDeleteTitle={'DELETAR CONTRATO'}
        cardEditTitle={'EDITAR CONTRATO'}
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        cardIcon={<ContractsIcon />}
        showDeleteButton={hasDeletePermission}
        showEditButton={hasEditPermission}
        showChartButton={false}
        selectedCard={selectedCard}
        showInfo={true}
      />
    );
  };

  const renderSubHeaderAndSearchBar = () => {
    const showButton = hasAddPermission ? true : false;

    // const showButton = true;

    const button = (
      <IoseAddButton
        onClick={handleOpenAddModal}
        tooltip="CRIAR CONTRATO"
        top="26px"
      />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle="Contratos"
        description={'Total: ' + cards.length}
        button={showButton ? button : <></>}
      >
        <IoseSearch
          placeholder="Pesquisar Contratos"
          funcSearch={filterCircuits}
          getcards={getcards}
        />
      </IoseSubHeaderBar>
    );
  };

  const renderAllCards = () => {
    return (
      <ContainerCards>
        {search ? cardsFiltered.map(renderCards) : cards.map(renderCards)}
      </ContainerCards>
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <IoseInfinityScroll
            dataLength={cards.length}
            hasMore={true}
            loading={loading}
            scrollableTarget={'container'}
          >
            {hasReadPermission
              ? renderAllCards()
              : setError(
                  'Seu grupo de usuários não tem permissão de leitura destes dados!'
                )}
          </IoseInfinityScroll>
        </>
      );
    }
  };

  const renderModals = () => {
    return (
      <>
        <IoseAddContractsModal
          open={openAddModal}
          onClose={handleCloseAddModal}
          submit={addContract}
          notificationType={notificationType}
          notification={notification}
          modalHeaderTitle="CONTRATOS"
          modalTitle="CADASTRO DE CONTRATOS"
          showNotification={showNotification}
          addNotification={addNotification}
          loading={loading}
          concessionaires={allConcessionaires}
          uploadFile={uploadFile}
        />

        <IoseEditContractsModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          submit={editContract}
          notificationType={editNotificationType}
          notification={notification}
          modalHeaderTitle="CONTRATOS"
          modalTitle="EDITAR CONTRATOS"
          showNotification={showNotification}
          editNotification={editNotification}
          loading={loading}
          concessionaires={allConcessionaires}
          selectedCard={selectedCard}
          uploadFile={uploadFile}
          removeFile={removeFile}
        />

        <IoseContractsDeleteModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          submit={deleteContract}
          notificationType={deleteNotificationType}
          notification={notification}
          showNotification={showNotification}
          deleteNotification={deleteNotification}
          loading={loading}
          selectedCard={selectedCard}
          modalTitle="DELETAR CONTRATOS"
          modalHeaderTitle="CONTRATOS"
          removeFile={removeFile}
        />
      </>
    );
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <WrappedContainer>
        {renderSubHeaderAndSearchBar()}
        {renderContent()}
        {renderModals()}
      </WrappedContainer>
    </Grow>
  );
}

ContractContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
