// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useUniqueBillAddress() {
  const [noBillAddressError, setNoBillAddressError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getUniqueBillAddress = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getUniqueBillAddress(headers);

    const billAddresses = response.data.data || [];

    if (billAddresses.length === 0) {
      setNoBillAddressError(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return billAddresses;
  };

  const {
    data: billAddresses,
    error: billAddressesError,
    isLoading,
  } = useQuery(['billAddresses'], getUniqueBillAddress);

  return {
    billAddresses,
    billAddressesError,
    noBillAddressError,
    isLoading,
  };
}

export default useUniqueBillAddress;
