// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useLogs() {
  const [noLogsError, setNoLogsError] = useState('');

  const { getSession } = useContext(AccountContext);

  const page_size = 300;

  const getLogs = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllUserLogs(headers, 10000, page_size);

    const logs = response.data.data || [];

    if (logs.length === 0) {
      setNoLogsError(Messages.noRegisteredLogs);
      throw new Error(Messages.noRegisteredLogs);
    }
    return logs;
  };

  const {
    data: logs,
    error: logsError,
    refetch: refreshLogs,
    isLoading,
  } = useQuery(['logsData'], getLogs);

  return {
    logs,
    logsError,
    noLogsError,
    refreshLogs,
    isLoading,
  };
}

export default useLogs;
