import ReactECharts from 'echarts-for-react';
import React from 'react';

export default function IoseAdmDashChartUnity({ data }) {
  const series = data?.map((obj) => {
    return {
      name: obj?.unity_name,
      type: 'bar',
      data: [obj?.consumption],
      itemStyle: {
        emphasis: {
          barBorderRadius: [50, 50],
        },
        normal: {
          barBorderRadius: [15, 15, 0, 0],
        },
      },
    };
  });

  const option = {
    grid: {
      left: '15%',
      bottom: '2%',
      top: '10%',
    },
    tooltip: {
      formatter: function (params) {
        return `<strong>${
          params.seriesName
        }</strong>: ${params.value.toLocaleString('pt-BR')}`;
      },
    },
    // legend: {},
    label: {
      show: false,
      position: 'top',
      valueAnimation: true,
    },
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {
      type: 'value',
      name: 'Energia Consumida (kWh)',
      axisLabel: {
        formatter: '{value} kWh',
      },
    },
    series: series,
  };

  return <ReactECharts option={option} style={{ height: 360 }} />;
}
