import styled from 'styled-components';

//Import from Material UI components
import { Grid, Collapse } from '@material-ui/core';

//Import from Material UI lab
import { Alert } from '@material-ui/lab';

//Import image
import IlustratioinImg from './img/warning.svg';

export const Header = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 100%;

  span {
    padding-bottom: 10px;
    width: 100%;
  }
`;

export const AlertStyled = styled(Alert)`
  .MuiAlert-message {
    width: 100%;
  }
`;

export const CollapseStyled = styled(Collapse)`
  margin-top: 10px;
`;

export const CollapseStyledModal = styled(Collapse)`
  margin-top: 10px;
  height: 80px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;

  p {
    font-size: 15px;
    padding: 0% 10%;
    text-align: center;
    font-weight: bold;
    margin-bottom: ${(props) => (props.bottom ? props.bottom : '15px')};
  }
`;

export const IllustrationAlert = styled.img.attrs((props) => ({
  src: IlustratioinImg,
}))`
  height: 100px;
  weight: 100px;
  margin: 10px 0px;
`;
