import React from 'react';

import * as S from './style';

import { DivPaper } from 'styles/defaultStyles';

import {
  // ConcessionaireLineChart,
  // FlagBarChart,
  // IoseAreaChartEnergyBills,
  // IoseBarChartEnergyBills,
  LoadingIndicator,
  // MapChart,
  // ComparisonList,
  AuditValueBarChart,
  GeneralManagementChart,
} from 'components';

export default function DashCharts({
  loading,
  auditValues,
  regionalData,
  generalConsumption,
}) {
  function renderChartOrErrorMessage(Component, title, width, height, data) {
    return (
      <DivPaper width={width} height={height}>
        <S.ChartTitle>
          <h3>{title}</h3>
        </S.ChartTitle>
        {loading ? (
          <LoadingIndicator loading={loading} />
        ) : (
          <Component data={data} />
        )}
      </DivPaper>
    );
  }

  return (
    <>
      <S.ChartsContainer>
        {renderChartOrErrorMessage(
          AuditValueBarChart,
          'MAIORES CONSUMOS - GERÊNCIA REGIONAL',
          '50%',
          '450px',
          regionalData
        )}
        {renderChartOrErrorMessage(
          GeneralManagementChart,
          ' CONSUMOS - GERÊNCIA GERAL',
          '50%',
          '450px',
          generalConsumption
        )}
        {/* {renderChartOrErrorMessage(ClassificationTable, '', '15%', '450px')} */}
        {/* {renderChartOrErrorMessage(
          IoseBarChartEnergyBills,
          'CONSUMO E VALOR TOTAL MENSAL DE ENERGIA ELÉTRICA',
          '35%',
          '300px'
        )}
        {renderChartOrErrorMessage(
          ConsumerGroupBarChart,
          'CONSUMO E VALOR TOTAL MENSAL POR REGIÃO',
          '35%',
          '300px'
        )} */}
      </S.ChartsContainer>
      <S.ChartsContainer>
        {/* {renderChartOrErrorMessage(
          FlagBarChart,
          'CUSTO POR BANDEIRA',
          '30%',
          '300px'
        )} */}
        {/* {renderChartOrErrorMessage(
          ConcessionaireLineChart,
          'CUSTO POR CONCESSIONÁRIA',
          '50%',
          '300px'
        )} */}
      </S.ChartsContainer>
      {/* <S.ChartsContainer>
        {renderChartOrErrorMessage(
          MapChart,
          'CONSUMO POR REGIÃO',
          '50%',
          '350px'
        )}
        {renderChartOrErrorMessage(
          ConsumerGroupBarChart,
          'CUSTO POR UNIDADE CONSUMIDORA',
          '50%',
          '350px'
        )}
        {renderChartOrErrorMessage(
          ComparisonList,
          'COMPARAÇÃO DE CONSUMO',
          '20%',
          '350px'
        )}
      </S.ChartsContainer> */}
    </>
  );
}
