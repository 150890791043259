import React from 'react';
import { TableBody, TableCell, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as S from './style';

import { LoadingIndicator, IoseInfinityScroll } from 'components';

const useStyles = makeStyles(() => ({
  tableHeaderCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const TableCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableCell}>{children}</TableCell>;
};

const TableHeaderCellCentered = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.tableHeaderCell}>{children}</TableCell>;
};

function convertNumberToDecimalStyle(number = 0) {
  const decimalNumber = number.toLocaleString('pt-br');
  return decimalNumber;
}

const CircuitsTableRow = ({ value, onClick }) => (
  <S.TableRowStyled align="center">
    <TableCellCentered>{value.name}</TableCellCentered>
    <TableCellCentered>{value.identification_number}</TableCellCentered>
    <TableCellCentered>{value.reference_month}</TableCellCentered>
    <TableCellCentered>
      {'R$ ' + convertNumberToDecimalStyle(value.account_value)}
    </TableCellCentered>
  </S.TableRowStyled>
);

const CircuitsTable = ({ data, onRowClick }) => (
  <S.StyledTable>
    <S.TableHeadStyled>
      <S.TableRowStyled align="center">
        <TableHeaderCellCentered>LOGRADOURO</TableHeaderCellCentered>
        <TableHeaderCellCentered>UNIDADE CONSUMIDORA</TableHeaderCellCentered>
        <TableHeaderCellCentered>REF: MÊS/ANO</TableHeaderCellCentered>
        <TableHeaderCellCentered>VALOR DA CONTA</TableHeaderCellCentered>
      </S.TableRowStyled>
    </S.TableHeadStyled>

    <TableBody>
      {data.map((value, index) => (
        <CircuitsTableRow key={index} value={value} onClick={onRowClick} />
      ))}
    </TableBody>
  </S.StyledTable>
);

export default function IoseEnergyBillTable({ loading, energyBillData }) {
  const BillsData = energyBillData.map((bill) => ({
    uuid: bill.uuid_energy_bill,
    name: bill.bill_name,
    identification_number: bill.identification_number,
    cnpj: bill.cnpj,
    reference_month: bill.reference_month,
    account_value: bill.account_value,
  }));

  return (
    <div>
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <IoseInfinityScroll
          dataLength={parseInt(BillsData.length)}
          next={() => {}}
          hasMore={false}
          loading={false}
          endList={false}
          sidebar={false}
          marginTop={'0'}
          padding={'0'}
          colortextend={null}
          sizetextend={null}
          scrollableTarget="scrollableDiv"
          height={400}
        >
          <Paper>
            <CircuitsTable data={BillsData} id="scrollableDiv" />
          </Paper>
        </IoseInfinityScroll>
      )}
    </div>
  );
}
