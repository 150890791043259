import axios from 'axios';

const path = process.env.REACT_APP_API_ENDPOINT;

axios.defaults.baseURL = path;

export const WebSocketEndPoint = process.env.REACT_APP_WEBSOCKET_ENDPOINT;
// const date = new Date();
// const dateISOString = date.toISOString();

//Client Requisitions
const getAllClients = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallclient', { params, headers: header });
};

const getClient = async (header, uuid_client) => {
  let params = {
    uuid_client: uuid_client,
  };

  const response = await axios.get('getclient', { params, headers: header });

  return response;
};

const createClient = (header, name, description) => {
  let data = {
    company_name: name,
    company_description: description,
  };

  return axios.post('createclient', data, { headers: header });
};

const deleteClient = (header, uuid_client) => {
  let data = {
    uuid_client: uuid_client,
  };

  return axios({
    method: 'delete',
    url: 'deleteclient',
    data,
    headers: header,
  });
};

const updateClient = (header, uuid_client, name, description) => {
  let data = {
    uuid_client: uuid_client,
    name: name,
    description: description,
  };

  return axios.put('updateclient', data, { headers: header });
};
///////////////////////////////////

//Admin Requisitions
const getAllAdmins = (header, uuid_client, page_code, page_size) => {
  let params = {
    uuid_client: uuid_client,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getalladmin', { params, headers: header });
};

const getAdmin = (header, uuid_admin) => {
  let params = {
    uuid_admin: uuid_admin,
  };

  return axios.get('getadmin', { params, headers: header });
};

const createAdmin = (header, name, email, uuid_client) => {
  let data = {
    name: name,
    email: email,
    uuid_client: uuid_client,
  };

  return axios.post('createadmin', data, { headers: header });
};

const deleteAdmin = (header, uuid_admin) => {
  let data = {
    uuid_admin: uuid_admin,
  };

  return axios({
    method: 'delete',
    url: 'deleteadmin',
    data,
    headers: header,
  });
};

const updateAdmin = (header, uuid_admin, name, email, dataAdmin) => {
  let data = {
    uuid_admin: uuid_admin,
    name: name,
    cep: dataAdmin.cep || '',
    cpf: dataAdmin.cpf || '',
    rua: dataAdmin.rua || '',
    numero: dataAdmin.numero || 0,
    bairro: dataAdmin.bairro || '',
    cidade: dataAdmin.cidade || '',
    estado: dataAdmin.estado || '',
  };

  return axios.put('updateadmin', data, { headers: header });
};

///////////////////////////////////

//Unity Requisitions
const getAllUnitys = (header, uuid_client, page_code, page_size) => {
  let params = {
    uuid_client: uuid_client,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallunity', { params, headers: header });
};

const getUnity = (header, uuid_unity) => {
  let params = {
    uuid_unity: uuid_unity,
  };

  return axios.get('getunity', { params, headers: header });
};

const createUnity = (
  header,
  uuid_client,
  name,
  description,
  tariff_period,
  contracted_demand,
  tariff_data,
  identificationNumber,
  bin_link,
  id_3d
) => {
  let data = {
    uuid_client: uuid_client,
    name: name,
    description: description,
    tariff_period: tariff_period,
    contracted_demand: contracted_demand,
    tariff_data: tariff_data,
    identification_number: identificationNumber,
    bin_link: bin_link,
    id_3d: id_3d,
  };

  return axios.post('createunity', data, { headers: header });
};

const deleteUnity = (header, uuid_unity) => {
  let data = {
    uuid_unity: uuid_unity,
  };

  return axios({
    method: 'delete',
    url: 'deleteunity',
    data,
    headers: header,
  });
};

const updateUnity = (
  header,
  uuid_unity,
  name,
  description,
  tariff_period,
  contracted_demand,
  tariff_data,
  identification_number,
  bin_link,
  id_3d
) => {
  let data = {
    uuid_unity: uuid_unity,
    name: name,
    description: description,
    tariff_period: tariff_period,
    contracted_demand: contracted_demand,
    tariff_data: tariff_data,
    identification_number: identification_number,
    bin_link: bin_link,
    id_3d: id_3d,
  };

  return axios.put('updateunity', data, { headers: header });
};

const getAllAgents = (header) => {
  return axios.get('getallagents', { headers: header });
};

//////////////////////////////////

//Employee Requisitions
const getAllEmployee = (
  header,
  uuid_unity,
  uuid_client,
  page_code,
  page_size
) => {
  let params = {
    uuid_unity: uuid_unity,
    uuid_client: uuid_client,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallemployee', { params, headers: header });
};

const getEmployee = (header, uuid_employee) => {
  let params = {
    uuid_employee: uuid_employee,
  };

  return axios.get('getemployee', { params, headers: header });
};

const createManager = (header, email, name, uuid_client, uuid_unity) => {
  let data = {
    email: email,
    name: name,
    uuid_client: uuid_client,
    uuid_unity: uuid_unity,
  };

  return axios.post('createmanager', data, { headers: header });
};

const createOperator = (
  header,
  email,
  name,
  uuid_client,
  uuid_unity,
  activate_circuits
) => {
  let data = {
    email: email,
    name: name,
    uuid_client: uuid_client,
    uuid_unity: uuid_unity,
    activate_circuits: activate_circuits,
  };

  return axios.post('createoperator', data, { headers: header });
};

const deleteEmployee = (header, uuid_employee) => {
  let data = {
    uuid_employee: uuid_employee,
  };

  return axios({
    method: 'delete',
    url: 'deleteemployee',
    data,
    headers: header,
  });
};

const updateEmployee = (header, uuid_employee, name, email, employeeData) => {
  let data = {
    uuid_employee: uuid_employee,
    name: name,
    cep: employeeData.cep || '',
    cpf: employeeData.cpf || '',
    rua: employeeData.rua || '',
    numero: employeeData.numero || 0,
    bairro: employeeData.bairro || '',
    cidade: employeeData.cidade || '',
    estado: employeeData.estado || '',
    complemento: employeeData.complemento || '',
    moradores: employeeData.moradores || 0,
  };

  return axios.put('updateemployee', data, { headers: header });
};

//////////////////////////////////

//Circuits Requisitions
const getNewCircuit = (header, uuid_client, page_code, page_size) => {
  let params = {
    uuid_client: uuid_client,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getnewcircuit', { params, headers: header });
};

const getAllCircuit = (header, uuid_unity, page_code, page_size) => {
  let params = {
    uuid_unity: uuid_unity,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallcircuit', { params, headers: header });
};

const getAllClientCircuits = (header, uuid_client, page_code, page_size) => {
  let params = {
    uuid_client: uuid_client,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallclientcircuits', { params, headers: header });
};

const updatePropertyCircuit = (
  header,
  uuid_circuit,
  uuid_unity,
  uuid_group
) => {
  let data = {
    uuid_circuit: uuid_circuit,
    uuid_unity: uuid_unity,
    uuid_group: uuid_group,
  };

  return axios.put('updatepropertycircuit', data, { headers: header });
};

const removePropertyCircuit = (header, uuid_circuit) => {
  let data = {
    uuid_circuit: uuid_circuit,
  };

  return axios({
    method: 'delete',
    url: 'removepropertycircuit',
    data,
    headers: header,
  });
};

const deleteCircuit = (header, uuid_circuit) => {
  let data = {
    uuid_circuit: uuid_circuit,
  };

  return axios({
    method: 'delete',
    url: 'deletecircuit',
    data,
    headers: header,
  });
};

const updateCircuit = (
  header,
  uuid_circuit,
  name,
  description,
  primary_tc,
  secondary_tc,
  identification_number
) => {
  let data = {
    uuid_circuit: uuid_circuit,
    name: name,
    description: description,
    primary_tc: primary_tc,
    secondary_tc: secondary_tc,
    identification_number: identification_number,
  };

  return axios.put('updatecircuit', data, { headers: header });
};

const triggerCircuit = (
  header,
  uuid_circuit,
  state_actuation,
  uuidEmployee,
  uuidAdmin
) => {
  let data = {
    uuid_circuit: uuid_circuit,
    state_actuation: state_actuation,
    uuid_employee: uuidEmployee,
    uuid_admin: uuidAdmin,
  };
  return axios.put('actioncircuit', data, { headers: header });
};

//////////////////////////////////

//Group Requisitions
const getAllGroup = (header, uuid_unity, page_code, page_size) => {
  let params = {
    uuid_unity: uuid_unity,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallgroup', { params, headers: header });
};

const deleteGroup = (header, uuid_group) => {
  let data = {
    uuid_group: uuid_group,
  };

  return axios({
    method: 'delete',
    url: 'deletegroup',
    data,
    headers: header,
  });
};

const createGroup = (
  header,
  uuid_unity,
  name,
  description,
  goal,
  identificationNumber
) => {
  let data = {
    uuid_unity: uuid_unity,
    name: name,
    description: description,
    goal: goal,
    identification_number: identificationNumber,
  };

  return axios.post('creategroup', data, { headers: header });
};

const updateGroup = (
  header,
  uuid_group,
  name,
  description,
  goal,
  identificationNumber
) => {
  let data = {
    uuid_group: uuid_group,
    name: name,
    description: description,
    goal: goal,
    identification_number: identificationNumber,
  };

  return axios.put('updategroup', data, { headers: header });
};
//////////////////////////////////

//Scheduler Requisitions
const getSchedulingGroup = (header, uuid_unity, page_code, page_size) => {
  let params = {
    uuid_unity: uuid_unity,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getschedulinggroup', { params, headers: header });
};

const getSchedulingModules = (header, uuid_unity, page_code, page_size) => {
  let params = {
    uuid_unity: uuid_unity,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getschedulingmodules', { params, headers: header });
};

const getSchedulingModulesByGroup = (
  header,
  uuid_scheduling_group,
  page_code,
  page_size
) => {
  let params = {
    uuid_scheduling_group: uuid_scheduling_group,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getschedulingmodulesbygroup', { params, headers: header });
};

const getSchedulingGroupByCircuit = (
  header,
  uuid_circuit,
  page_code,
  page_size
) => {
  let params = {
    uuid_circuit: uuid_circuit,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getschedulinggroupbycircuit', { params, headers: header });
};

const createScheduling = (
  header,
  circuits,
  name,
  uuid_unity,
  run_at,
  action_relay,
  repeat,
  week
) => {
  let data = {
    circuits: circuits,
    name: name,
    uuid_unity: uuid_unity,
    run_at: run_at,
    action_relay: action_relay,
    repeat: repeat,
    week: week,
  };

  return axios.post('createscheduling', data, { headers: header });
};

const includeScheduling = (header, uuid_scheduling_group, circuits) => {
  let data = {
    uuid_scheduling_group: uuid_scheduling_group,
    circuits: circuits,
  };

  return axios.post('includeScheduling', data, { headers: header });
};

const updateScheduling = (
  header,
  name,
  uuid_scheduling_group,
  run_at,
  action_relay,
  repeat,
  week
) => {
  let data = {
    name: name,
    uuid_scheduling_group: uuid_scheduling_group,
    run_at: run_at,
    action_relay: action_relay,
    repeat: repeat,
    week: week,
  };

  return axios.put('updatescheduling', data, { headers: header });
};

const deleteScheduling = (header, uuid_scheduling_group, uuid_unity) => {
  let data = {
    uuid_scheduling_group: uuid_scheduling_group,
    uuid_unity: uuid_unity,
  };

  return axios({
    method: 'delete',
    url: 'deletescheduling',
    data,
    headers: header,
  });
};

const deleteModuleSchedulings = (header, uuid_scheduling_group, circuits) => {
  let data = {
    uuid_scheduling_group: uuid_scheduling_group,
    circuits: circuits,
  };

  return axios({
    method: 'delete',
    url: 'deleteModuleSchedulings',
    data,
    headers: header,
  });
};

const retryCreateScheduling = (header, uuid_scheduling_module) => {
  let data = {
    uuid_scheduling_module: uuid_scheduling_module,
  };

  return axios.put('retrycreatescheduling', data, { headers: header });
};

const retryUpdateScheduling = (
  header,
  uuid_scheduling_group,
  uuid_scheduling_module
) => {
  let data = {
    uuid_scheduling_group: uuid_scheduling_group,
    uuid_scheduling_module: uuid_scheduling_module,
  };

  return axios.put('retryUpdateScheduling', data, { headers: header });
};

//////////////////////////////////
//Energy Bill Requisitions
const getAllEnergyBill = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallenergybill', { params, headers: header });
};
//////////////////////////////////

//////////////////////////////////
//Environment Requisitions
const getAllEnvironment = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallenvironments', { params, headers: header });
};

const updateEnvironment = (
  header,
  uuid_environment,
  name,
  description,
  uuid_unity,
  id_3d
) => {
  let data = {
    uuid_environment: uuid_environment,
    name: name,
    description: description,
    uuid_unity: uuid_unity,
    id_3d: id_3d,
  };

  return axios.put('updateenvironment', data, { headers: header });
};
//////////////////////////////////

//////////////////////////////////
//Objects Requisitions

const getAllObjects = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallobjects', { params, headers: header });
};

const createObjects = (
  header,
  uuid_environment,
  uuid_circuit,
  name,
  voltage,
  power,
  current,
  temperature,
  condition,
  charge_type,
  id_3d
) => {
  var params = {
    uuid_environment: uuid_environment,
    uuid_circuit: uuid_circuit,
    name: name,
    voltage: voltage,
    power: power,
    current: current,
    temperature: temperature,
    condition: condition,
    charge_type: charge_type,
    id_3d: id_3d,
  };

  return axios.post('createobjects', params, { headers: header });
};

const updateObjects = (
  header,
  uuid_object,
  uuid_environment,
  uuid_circuit,
  name,
  voltage,
  power,
  current,
  temperature,
  condition,
  charge_type,
  id_3d
) => {
  let data = {
    uuid_object: uuid_object,
    uuid_environment: uuid_environment,
    uuid_circuit: uuid_circuit,
    name: name,
    voltage: voltage,
    power: power,
    current: current,
    temperature: temperature,
    condition: condition,
    charge_type: charge_type,
    id_3d: id_3d,
  };

  return axios.put('updateobjects', data, { headers: header });
};

//Objects Requisitions
const createConcessionaires = (header, name, code, cnpj, state, city) => {
  var params = {
    name: name,
    code: code,
    cnpj: cnpj,
    state: state,
    city: city,
  };

  return axios.post('createconcessionaires', params, { headers: header });
};

const getAllConcessionaires = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallconcessionaires', { params, headers: header });
};

const updateConcessionaires = (
  header,
  uuid_concessionaire,
  name,
  code,
  cnpj,
  state,
  city
) => {
  let data = {
    uuid_concessionaire: uuid_concessionaire,
    name: name,
    code: code,
    cnpj: cnpj,
    state: state,
    city: city,
  };

  return axios.put('updateconcessionaires', data, { headers: header });
};

const deleteConcessionaires = (header, uuid_concessionaire) => {
  let data = {
    uuid_concessionaire: uuid_concessionaire,
  };

  return axios({
    method: 'delete',
    url: 'deleteconcessionaires',
    data,
    headers: header,
  });
};

const createConsumerGroup = (
  header,
  concessionaire_name,
  identification_number,
  contract_number,
  coordinate_x,
  coordinate_y,
  address,
  consumer_group_load
) => {
  var params = {
    concessionaire_name: concessionaire_name,
    identification_number: identification_number,
    contract_number: contract_number,
    coordinate_x: coordinate_x,
    coordinate_y: coordinate_y,
    address: address,
    consumer_group_load: consumer_group_load,
  };

  return axios.post('createconsumergroup', params, { headers: header });
};

const getAllConsumerGroup = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallconsumergroup', { params, headers: header });
};

const deleteConsumerGroup = (header, uuid_consumer_group) => {
  let data = {
    uuid_consumer_group: uuid_consumer_group,
  };

  return axios({
    method: 'delete',
    url: 'deleteconsumergroup',
    data,
    headers: header,
  });
};

const updateConsumerGroup = (
  header,
  uuid_consumer_group,
  concessionaire_name,
  identification_number,
  contract_number,
  coordinate_x,
  coordinate_y,
  address,
  consumer_group_load
) => {
  let data = {
    uuid_consumer_group: uuid_consumer_group,
    concessionaire_name: concessionaire_name,
    identification_number: identification_number,
    contract_number: contract_number,
    coordinate_x: coordinate_x,
    coordinate_y: coordinate_y,
    address: address,
    consumer_group_load: consumer_group_load,
  };

  return axios.put('updateconsumergroup', data, { headers: header });
};

const createMapUrl = (header, url_vector, url_image, description) => {
  var params = {
    url_vector: url_vector,
    url_image: url_image,
    description: description,
  };

  return axios.post('createmapurl', params, { headers: header });
};

const getAllMapUrl = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallmapurl', { params, headers: header });
};

const updateMapUrl = (header, uuid_url, url_vector, url_image, description) => {
  let data = {
    uuid_url: uuid_url,
    url_vector: url_vector,
    url_image: url_image,
    description: description,
  };

  return axios.put('updatemapurl', data, { headers: header });
};

const getAllContracts = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallcontracts', { params, headers: header });
};

const createContract = (
  header,
  contract_number,
  market_type,
  concessionaire,
  term_contract,
  readjustment_index,
  readjustment_rate,
  total_contracts,
  monthly_value,
  note,
  days_expire,
  initial_date,
  final_date,
  file_link
) => {
  var params = {
    contract_number: contract_number,
    market_type: market_type,
    concessionaire: concessionaire,
    term_contract: term_contract,
    readjustment_index: readjustment_index,
    readjustment_rate: readjustment_rate,
    total_contracts: total_contracts,
    monthly_value: monthly_value,
    note: note,
    days_expire: days_expire,
    initial_date: initial_date,
    final_date: final_date,
    file_link: file_link,
  };

  return axios.post('createcontracts', params, { headers: header });
};

const updateContract = (
  header,
  uuid_contract,
  contract_number,
  market_type,
  concessionaire,
  term_contract,
  readjustment_index,
  readjustment_rate,
  total_contracts,
  monthly_value,
  note,
  days_expire,
  initial_date,
  final_date,
  file_link
) => {
  let data = {
    uuid_contract: uuid_contract,
    contract_number: contract_number,
    market_type: market_type,
    concessionaire: concessionaire,
    term_contract: term_contract,
    readjustment_index: readjustment_index,
    readjustment_rate: readjustment_rate,
    total_contracts: total_contracts,
    monthly_value: monthly_value,
    note: note,
    days_expire: days_expire,
    initial_date: initial_date,
    final_date: final_date,
    file_link: file_link,
  };

  return axios.put('updatecontracts', data, { headers: header });
};

const deleteContract = (header, uuid_contract) => {
  let data = {
    uuid_contract: uuid_contract,
  };

  return axios({
    method: 'delete',
    url: 'deletecontracts',
    data,
    headers: header,
  });
};

const getTariffsByConcessionaireAndYear = (
  header,
  concessionaire = null,
  year = null
) => {
  let params = {
    concessionaire: concessionaire,
    year: year,
  };

  return axios.get('gettariffsbyconcessionaireandyear', {
    params,
    headers: header,
  });
};

const getTariffComponentsByConcessionaireAndYear = (
  header,
  concessionaire = null,
  year = null
) => {
  let params = {
    concessionaire: concessionaire,
    year: year,
  };

  return axios.get('gettariffcomponentsbyconcessionaireandyear', {
    params,
    headers: header,
  });
};

const getAllConsumptionComparison = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallconsumptioncomparison', { params, headers: header });
};

const getAllUnityConsumption = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallunityconsumption', { params, headers: header });
};

const getAllGroupsConsumption = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallgroupsconsumption', { params, headers: header });
};

const getAllCircuitsConsumption = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallcircuitsconsumption', { params, headers: header });
};

const getAllBillValueAudits = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallbillvalueaudits', { params, headers: header });
};

const getAllBills = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallbills', { params, headers: header });
};

const getAllConsumerGroupV2 = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallconsumergroupv2', { params, headers: header });
};

const createConsumerGroupV2 = (
  header,
  concessionaire,
  identification_number,
  contract,
  operating_unity,
  general_management,
  regional_management,
  coordinate_x,
  coordinate_y,
  address,
  city,
  carbon_credit,
  telemetry,
  meter,
  consumer_group_load,
  atividade,
  cod_local,
  cod_unidade,
  descricao_unidade,
  cod_orgao_pagador,
  cogeracao,
  csbio,
  geradora,
  beneficiaria,
  potencia_transformador,
  subgrupo,
  modalidade,
  ativo,
  mercado_livre,
  inicio_vigencia,
  fim_vigencia,
  inicio_teste,
  fim_teste,
  constante,
  relogio_reset,
  demanda_fora,
  demanda_ponta,
  tensao
) => {
  var params = {
    concessionaire: concessionaire,
    identification_number: identification_number,
    contract: contract,
    operating_unity: operating_unity,
    general_management: general_management,
    regional_management: regional_management,
    coordinate_x: coordinate_x,
    coordinate_y: coordinate_y,
    address: address,
    city: city,
    carbon_credit: carbon_credit,
    telemetry: telemetry,
    meter: meter,
    consumer_group_load: consumer_group_load,
    atividade: atividade,
    cod_local: cod_local,
    cod_unidade: cod_unidade,
    descricao_unidade: descricao_unidade,
    cod_orgao_pagador: cod_orgao_pagador,
    cogeracao: cogeracao,
    csbio: csbio,
    geradora: geradora,
    beneficiaria: beneficiaria,
    potencia_transformador: potencia_transformador,
    subgrupo: subgrupo,
    modalidade: modalidade,
    ativo: ativo,
    mercado_livre: mercado_livre,
    inicio_vigencia: inicio_vigencia,
    fim_vigencia: fim_vigencia,
    inicio_teste: inicio_teste,
    fim_teste: fim_teste,
    constante: constante,
    relogio_reset: relogio_reset,
    demanda_fora: demanda_fora,
    demanda_ponta: demanda_ponta,
    tensao: tensao,
  };

  return axios.post('createconsumergroupv2', params, { headers: header });
};

const updateConsumerGroupV2 = (
  header,
  uuid_consumer_group,
  concessionaire,
  identification_number,
  contract,
  operating_unity,
  general_management,
  regional_management,
  coordinate_x,
  coordinate_y,
  address,
  city,
  carbon_credit,
  telemetry,
  meter,
  consumer_group_load,
  atividade,
  cod_local,
  cod_unidade,
  descricao_unidade,
  cod_orgao_pagador,
  cogeracao,
  csbio,
  geradora,
  beneficiaria,
  potencia_transformador,
  subgrupo,
  modalidade,
  ativo,
  mercado_livre,
  inicio_vigencia,
  fim_vigencia,
  inicio_teste,
  fim_teste,
  constante,
  relogio_reset,
  demanda_fora,
  demanda_ponta,
  tensao
) => {
  let data = {
    uuid_consumer_group: uuid_consumer_group,
    concessionaire: concessionaire,
    identification_number: identification_number,
    contract: contract,
    operating_unity: operating_unity,
    general_management: general_management,
    regional_management: regional_management,
    coordinate_x: coordinate_x,
    coordinate_y: coordinate_y,
    address: address,
    city: city,
    carbon_credit: carbon_credit,
    telemetry: telemetry,
    meter: meter,
    consumer_group_load: consumer_group_load,
    atividade: atividade,
    cod_local: cod_local,
    cod_unidade: cod_unidade,
    descricao_unidade: descricao_unidade,
    cod_orgao_pagador: cod_orgao_pagador,
    cogeracao: cogeracao,
    csbio: csbio,
    geradora: geradora,
    beneficiaria: beneficiaria,
    potencia_transformador: potencia_transformador,
    subgrupo: subgrupo,
    modalidade: modalidade,
    ativo: ativo,
    mercado_livre: mercado_livre,
    inicio_vigencia: inicio_vigencia,
    fim_vigencia: fim_vigencia,
    inicio_teste: inicio_teste,
    fim_teste: fim_teste,
    constante: constante,
    relogio_reset: relogio_reset,
    demanda_fora: demanda_fora,
    demanda_ponta: demanda_ponta,
    tensao: tensao,
  };

  return axios.put('updateconsumergroupv2', data, { headers: header });
};

const deleteConsumerGroupV2 = (header, uuid_consumer_group) => {
  let data = {
    uuid_consumer_group: uuid_consumer_group,
  };

  return axios({
    method: 'delete',
    url: 'deleteconsumergroupv2',
    data,
    headers: header,
  });
};

const getAllIdentificatioNumberBills = (
  header,
  identification_number,
  page_size,
  page_code
) => {
  let params = {
    identification_number: identification_number,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getbillsbyidentificationnumber', {
    params,
    headers: header,
  });
};

const getConsumerGroupByIdentificationNumber = (
  header,
  identification_number,
  page_size,
  page_code
) => {
  let params = {
    identification_number: identification_number,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getbyidentificationnumber', {
    params,
    headers: header,
  });
};

const getAllConsumptionResult = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallconsumptionresults', { params, headers: header });
};

const getAllExtractCalculated = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallextractcalculated', { params, headers: header });
};

const getAllAudits = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallaudits', { params, headers: header });
};

const getAuditForReport = (
  header,
  identification_number = null,
  reference_month = null,
  initial_reading = null,
  final_reading = null,
  initial_expiration = null,
  final_expiration = null,
  concessionaire = null,
  general_management = null,
  regional_management = null
) => {
  let params = {
    identification_number: identification_number,
    reference_month: reference_month,
    initial_reading: initial_reading,
    final_reading: final_reading,
    initial_expiration: initial_expiration,
    final_expiration: final_expiration,
    concessionaire: concessionaire,
    general_management: general_management,
    regional_management: regional_management,
  };

  return axios.get('getauditforreport', {
    params,
    headers: header,
  });
};

const getAllSGFExports = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallsgfexports', { params, headers: header });
};

const getSGFForExport = (header, reference_month = null) => {
  let params = {
    reference_month: reference_month,
  };

  return axios.get('getsgfforexport', {
    params,
    headers: header,
  });
};

const getAllCreditGeneration = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallcreditgeneration', { params, headers: header });
};

const getAllCreditBeneficiary = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallcreditbeneficiary', { params, headers: header });
};

const getAllBIENEExports = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallbieneexports', { params, headers: header });
};

const getBIENEForExport = (header, fac_refer_fatura = null) => {
  let params = {
    fac_refer_fatura: fac_refer_fatura,
  };

  return axios.get('getbieneforexport', {
    params,
    headers: header,
  });
};

const getAllSystemVariables = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallsystemvariables', { params, headers: header });
};

const createSystemVariable = (
  header,
  item_type,
  item_constant,
  item_description
) => {
  var params = {
    item_type: item_type,
    item_constant: item_constant,
    item_description: item_description,
  };

  return axios.post('createsystemvariable', params, { headers: header });
};

const updateSystemVariable = (
  header,
  uuid_item,
  item_type,
  item_constant,
  item_description
) => {
  let data = {
    uuid_item: uuid_item,
    item_type: item_type,
    item_constant: item_constant,
    item_description: item_description,
  };

  return axios.put('updatesystemvariable', data, { headers: header });
};

const deleteSystemVariable = (header, uuid_item) => {
  let data = {
    uuid_item: uuid_item,
  };

  return axios({
    method: 'delete',
    url: 'deletesystemvariable',
    data,
    headers: header,
  });
};

const getAllCreditConnections = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallcreditconnection', { params, headers: header });
};

const createCreditConnections = (
  header,
  uc_beneficiaria,
  uc_geradora,
  mes_referencia,
  estimativa_inicio,
  porcentagem,
  usuario,
  dt_cadastro,
  estimativa_fim = null
) => {
  var params = {
    // uuid_connection: uuid_connection,
    uc_beneficiaria: uc_beneficiaria,
    uc_geradora: uc_geradora,
    mes_referencia: mes_referencia,
    estimativa_inicio: estimativa_inicio,
    porcentagem: porcentagem,
    usuario: usuario,
    dt_cadastro: dt_cadastro,
    estimativa_fim: estimativa_fim,
  };

  return axios.post('createcreditconnection', params, { headers: header });
};

const getCreditByGeradoraAndMonth = (
  header,
  uc_geradora = null,
  mes_referencia = null
) => {
  let params = {
    uc_geradora: uc_geradora,
    mes_referencia: mes_referencia,
  };

  return axios.get('getbygeradoraandmonth', {
    params,
    headers: header,
  });
};

const getAllGenerationAccumulated = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallgenerationaccumulated', { params, headers: header });
};
const getAllBeneficiaryPerMonth = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallcreditbeneficiarypermonth', {
    params,
    headers: header,
  });
};

const getAllCreditPercentage = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallcreditpercentage', { params, headers: header });
};

const getAllBeneficiaryJoin = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallbeneficiaryjoin', { params, headers: header });
};

const getAllFaturasPaginas = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallfaturaspaginas', { params, headers: header });
};

const getAllIdentificatioNumberFaturas = (
  header,
  identification_number,
  page_size,
  page_code
) => {
  let params = {
    identification_number: identification_number,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getfaturabyidentificationnumber', {
    params,
    headers: header,
  });
};

const updateEnergyBillMeasurer = (
  header,
  uuid_energy_bill,
  measurer_1,
  measurer_2,
  measurer_3,
  measurer_4,
  measurer_5,
  measurer_6,
  measurer_7,
  measurer_8,
  measurer_9,
  measurer_10,
  measurer_11,
  measurer_12,
  magnitude_1,
  magnitude_2,
  magnitude_3,
  magnitude_4,
  magnitude_5,
  magnitude_6,
  magnitude_7,
  magnitude_8,
  magnitude_9,
  magnitude_10,
  magnitude_11,
  magnitude_12,
  timetables_1,
  timetables_2,
  timetables_3,
  timetables_4,
  timetables_5,
  timetables_6,
  timetables_7,
  timetables_8,
  timetables_9,
  timetables_10,
  timetables_11,
  timetables_12,
  measurer_previous_reading_1,
  measurer_previous_reading_2,
  measurer_previous_reading_3,
  measurer_previous_reading_4,
  measurer_previous_reading_5,
  measurer_previous_reading_6,
  measurer_previous_reading_7,
  measurer_previous_reading_8,
  measurer_previous_reading_9,
  measurer_previous_reading_10,
  measurer_previous_reading_11,
  measurer_previous_reading_12,
  measurer_current_reading_1,
  measurer_current_reading_2,
  measurer_current_reading_3,
  measurer_current_reading_4,
  measurer_current_reading_5,
  measurer_current_reading_6,
  measurer_current_reading_7,
  measurer_current_reading_8,
  measurer_current_reading_9,
  measurer_current_reading_10,
  measurer_current_reading_11,
  measurer_current_reading_12,
  measurer_constant_1,
  measurer_constant_2,
  measurer_constant_3,
  measurer_constant_4,
  measurer_constant_5,
  measurer_constant_6,
  measurer_constant_7,
  measurer_constant_8,
  measurer_constant_9,
  measurer_constant_10,
  measurer_constant_11,
  measurer_constant_12,
  kwh_consumption_1,
  kwh_consumption_2,
  kwh_consumption_3,
  kwh_consumption_4,
  kwh_consumption_5,
  kwh_consumption_6,
  kwh_consumption_7,
  kwh_consumption_8,
  kwh_consumption_9,
  kwh_consumption_10,
  kwh_consumption_11,
  kwh_consumption_12
) => {
  let data = {
    uuid_energy_bill: uuid_energy_bill,
    measurer_1: measurer_1,
    measurer_2: measurer_2,
    measurer_3: measurer_3,
    measurer_4: measurer_4,
    measurer_5: measurer_5,
    measurer_6: measurer_6,
    measurer_7: measurer_7,
    measurer_8: measurer_8,
    measurer_9: measurer_9,
    measurer_10: measurer_10,
    measurer_11: measurer_11,
    measurer_12: measurer_12,
    magnitude_1: magnitude_1,
    magnitude_2: magnitude_2,
    magnitude_3: magnitude_3,
    magnitude_4: magnitude_4,
    magnitude_5: magnitude_5,
    magnitude_6: magnitude_6,
    magnitude_7: magnitude_7,
    magnitude_8: magnitude_8,
    magnitude_9: magnitude_9,
    magnitude_10: magnitude_10,
    magnitude_11: magnitude_11,
    magnitude_12: magnitude_12,
    timetables_1: timetables_1,
    timetables_2: timetables_2,
    timetables_3: timetables_3,
    timetables_4: timetables_4,
    timetables_5: timetables_5,
    timetables_6: timetables_6,
    timetables_7: timetables_7,
    timetables_8: timetables_8,
    timetables_9: timetables_9,
    timetables_10: timetables_10,
    timetables_11: timetables_11,
    timetables_12: timetables_12,
    measurer_previous_reading_1: measurer_previous_reading_1,
    measurer_previous_reading_2: measurer_previous_reading_2,
    measurer_previous_reading_3: measurer_previous_reading_3,
    measurer_previous_reading_4: measurer_previous_reading_4,
    measurer_previous_reading_5: measurer_previous_reading_5,
    measurer_previous_reading_6: measurer_previous_reading_6,
    measurer_previous_reading_7: measurer_previous_reading_7,
    measurer_previous_reading_8: measurer_previous_reading_8,
    measurer_previous_reading_9: measurer_previous_reading_9,
    measurer_previous_reading_10: measurer_previous_reading_10,
    measurer_previous_reading_11: measurer_previous_reading_11,
    measurer_previous_reading_12: measurer_previous_reading_12,
    measurer_current_reading_1: measurer_current_reading_1,
    measurer_current_reading_2: measurer_current_reading_2,
    measurer_current_reading_3: measurer_current_reading_3,
    measurer_current_reading_4: measurer_current_reading_4,
    measurer_current_reading_5: measurer_current_reading_5,
    measurer_current_reading_6: measurer_current_reading_6,
    measurer_current_reading_7: measurer_current_reading_7,
    measurer_current_reading_8: measurer_current_reading_8,
    measurer_current_reading_9: measurer_current_reading_9,
    measurer_current_reading_10: measurer_current_reading_10,
    measurer_current_reading_11: measurer_current_reading_11,
    measurer_current_reading_12: measurer_current_reading_12,
    measurer_constant_1: measurer_constant_1,
    measurer_constant_2: measurer_constant_2,
    measurer_constant_3: measurer_constant_3,
    measurer_constant_4: measurer_constant_4,
    measurer_constant_5: measurer_constant_5,
    measurer_constant_6: measurer_constant_6,
    measurer_constant_7: measurer_constant_7,
    measurer_constant_8: measurer_constant_8,
    measurer_constant_9: measurer_constant_9,
    measurer_constant_10: measurer_constant_10,
    measurer_constant_11: measurer_constant_11,
    measurer_constant_12: measurer_constant_12,
    kwh_consumption_1: kwh_consumption_1,
    kwh_consumption_2: kwh_consumption_2,
    kwh_consumption_3: kwh_consumption_3,
    kwh_consumption_4: kwh_consumption_4,
    kwh_consumption_5: kwh_consumption_5,
    kwh_consumption_6: kwh_consumption_6,
    kwh_consumption_7: kwh_consumption_7,
    kwh_consumption_8: kwh_consumption_8,
    kwh_consumption_9: kwh_consumption_9,
    kwh_consumption_10: kwh_consumption_10,
    kwh_consumption_11: kwh_consumption_11,
    kwh_consumption_12: kwh_consumption_12,
  };

  return axios.put('updateenergybillmeasurer', data, { headers: header });
};

const updateEnergyBillTaxes = (
  header,
  uuid_energy_bill,
  icms_calculation_basis_total,
  cofins_calculation_basis_total,
  pis_calculation_basis_total,
  icms_aliquot,
  cofins_aliquot,
  pis_aliquot,
  icms_total,
  cofins_total,
  pis_total
) => {
  let data = {
    uuid_energy_bill: uuid_energy_bill,
    icms_calculation_basis_total: icms_calculation_basis_total,
    cofins_calculation_basis_total: cofins_calculation_basis_total,
    pis_calculation_basis_total: pis_calculation_basis_total,
    icms_aliquot: icms_aliquot,
    cofins_aliquot: cofins_aliquot,
    pis_aliquot: pis_aliquot,
    icms_total: icms_total,
    cofins_total: cofins_total,
    pis_total: pis_total,
  };

  return axios.put('updateenergybilltaxes', data, { headers: header });
};

const updateEnergyBillLineItens = (header, uuid_energy_bill, object) => {
  let data = {
    uuid_energy_bill: uuid_energy_bill,
    object: object,
  };

  return axios.put('updateenergybilllineitens', data, { headers: header });
};

const getUniqueAtividades = (header) => {
  return axios.get('getuniqueatividades', { headers: header });
};

const getUniqueConcessionaires = (header) => {
  return axios.get('getuniqueconcessionaires', { headers: header });
};

const getUniqueUnityDescriptions = (header) => {
  return axios.get('getuniqueunitydescriptions', { headers: header });
};

const getUniqueRegionalManagement = (header) => {
  return axios.get('getuniqueregionalmanagement', { headers: header });
};

const getUniqueGeneralManagement = (header) => {
  return axios.get('getuniquegeneralmanagement', { headers: header });
};

const getFilteredConsumerGroups = (header, filters) => {
  let params = {
    object: filters,
  };

  return axios.get('getfilteredconsumergroups', { params, headers: header });
};

const getFilteredConsumptionResult = (header, filters) => {
  let params = {
    object: filters,
  };

  return axios.get('getfilteredconsumptionresult', { params, headers: header });
};

const getCalculatedTotalFilteredConsumption = (header, filters) => {
  let params = {
    object: filters,
  };

  return axios.get('calculatetotalfilteredconsumption', {
    params,
    headers: header,
  });
};

const getCalculateTotalConsumption = (header) => {
  return axios.get('calculatetotalconsumption', { headers: header });
};

const getCalculateAllRMConsumption = (header) => {
  return axios.get('calculateallregionalmanagementconsumption', {
    headers: header,
  });
};

const getCalculatedRegionalFilteredConsumption = (header, filters) => {
  let params = {
    object: filters,
  };

  return axios.get('calculateregionalmanagementconsumption', {
    params,
    headers: header,
  });
};

const getCalculateAllGMConsumption = (header) => {
  return axios.get('calculateallgeneralmanagementconsumption', {
    headers: header,
  });
};

const getCalculatedGeneralFilteredConsumption = (header, filters) => {
  let params = {
    object: filters,
  };

  return axios.get('calculategeneralmanagementconsumption', {
    params,
    headers: header,
  });
};

const getCalculateTotalBillValues = (header) => {
  return axios.get('calculatetotalbillvalue', { headers: header });
};

const getCalculateFilteredTotalBillValues = (header, filters) => {
  let params = {
    object: filters,
  };

  return axios.get('calculatetotalfilteredbillvalue', {
    params,
    headers: header,
  });
};

const getCalculateTotalExtract = (header) => {
  return axios.get('calculatetotalextract', { headers: header });
};

const getCalculateFilteredExtract = (header, filters) => {
  let params = {
    object: filters,
  };

  return axios.get('calculatetotalfilteredextract', {
    params,
    headers: header,
  });
};

const getAllSolarEcus = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallsolarecu', { params, headers: header });
};

const createSolarEcu = (header, ecu_number) => {
  var params = {
    ecu_number: ecu_number,
  };

  return axios.post('createsolarecu', params, { headers: header });
};

const updateSolarEcu = (header, uuid_ecu, ecu_number) => {
  let data = {
    uuid_ecu: uuid_ecu,
    ecu_number: ecu_number,
  };

  return axios.put('updatesolarecu', data, { headers: header });
};

const deleteSolarEcu = (header, uuid_ecu) => {
  let data = {
    uuid_ecu: uuid_ecu,
  };

  return axios({
    method: 'delete',
    url: 'deletesolarecu',
    data,
    headers: header,
  });
};

const getSummaryEnergyByEcuList = (header) => {
  return axios.get('getsolarsummaryenergybyeculist', {
    headers: header,
  });
};

const getAllEnergyBillLogs = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallenergybilllogs', { params, headers: header });
};

const getFilteredEnergyBillLogs = (header, filters) => {
  let params = {
    object: filters,
  };

  return axios.get('getfilteredenergybilllogs', { params, headers: header });
};

const getAllSearchEnergyBill = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallsearchenergybill', { params, headers: header });
};

const getFilteredSearchEnergyBill = (header, filters) => {
  let params = {
    object: filters,
  };

  return axios.get('getfilteredsearchenergybill', { params, headers: header });
};

const getUniqueBillAddress = (header) => {
  return axios.get('getuniquebilladdress', { headers: header });
};

const getUniqueSearchClassification = (header) => {
  return axios.get('getuniquesearchclassifications', { headers: header });
};

const getBillsByUUid = (header, uuid_energy_bill, page_size, page_code) => {
  let params = {
    uuid_energy_bill: uuid_energy_bill,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getbillsbyuuid', {
    params,
    headers: header,
  });
};

const getAllTariffs = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getalltariffs', { params, headers: header });
};

const createTariff = (
  header,
  tarifa,
  subgrupo,
  posto,
  modalidade,
  inicio_vigencia,
  fim_vigencia,
  valor,
  porcentagem
) => {
  let data = {
    tarifa: tarifa,
    subgrupo: subgrupo,
    posto: posto,
    modalidade: modalidade,
    inicio_vigencia: inicio_vigencia,
    fim_vigencia: fim_vigencia,
    valor: valor,
    porcentagem: porcentagem,
  };

  return axios.post('createtariff', data, { headers: header });
};

const getSsoToken = (authorizationCode) => {
  const cognitoUrl =
    'https://q9frd8joz6.execute-api.us-east-1.amazonaws.com/prod/authSanepar';
  return axios.post(cognitoUrl, { authorizationCode });
};

const getAllUserLogs = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getllluserlogs', { params, headers: header });
};

const createUserLog = (
  header,
  log_user,
  log_date,
  log_operation,
  log_form,
  log_content,
  log_user_email
) => {
  let data = {
    log_user: log_user,
    log_date: log_date,
    log_operation: log_operation,
    log_form: log_form,
    log_content: log_content,
    log_user_email: log_user_email,
  };

  return axios.post('createuserlog', data, { headers: header });
};

const getAllAuditDifference = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallauditdifference', { params, headers: header });
};

const getAllGroupPermissions = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallgrouppermissions', { params, headers: header });
};

const updateGroupPermissions = (
  header,
  uuid_group,
  group_name,
  permissions
) => {
  let data = {
    uuid_group: uuid_group,
    group_name: group_name,
    permissions: permissions,
  };

  return axios.put('updategrouppermissions', data, { headers: header });
};

const createGroupPermissions = (header, group_name, permissions) => {
  let data = {
    group_name: group_name,
    permissions: permissions,
  };

  return axios.post('creategrouppermissions', data, { headers: header });
};

const getCarbonTariff = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios
    .get('carbontariffs', { params, headers: header })
    .then((response) => {
      return response; // Se desejar, você pode retornar a resposta original para uso posterior
    })
    .catch((error) => {
      console.error('Erro ao receber dados da ioseapi:', error);
      throw error; // Você pode escolher relançar o erro ou manipulá-lo de outra forma
    });
};

const createCarbonTariff = (headers, tarifaValue, yearTariff, month, year) => {
  let data = {
    tarifa: tarifaValue,
    yearTariff: yearTariff,
    month: month,
    year: year,
  };
  return axios.post('createCarbonTariff', data, { headers });
};

const createEnvironment = (header, name, description, uuidUnity, id_3d) => {
  let data = {
    name: name,
    description: description,
    uuidUnity: uuidUnity,
    id_3d: id_3d,
  };

  return axios.post('createenvironment', data, { headers: header });
};

const createEnvironmentNew = (header, name, description, uuidUnity, id_3d) => {
  var params = {
    name: name,
    description: description,
    uuidUnity: uuidUnity,
    id_3d: id_3d,
  };

  return axios.post('createenvironment', params, { headers: header });
};

const uploadContractFile = (header, file) => {
  let params = {
    file: file,
  };

  return axios.post('uploadcontractfile', { params, headers: header });
};

const deleteContractFile = (header, file_name) => {
  let data = {
    file_name: file_name,
  };

  return axios({
    method: 'delete',
    url: 'removefilecontracts',
    data,
    headers: header,
  });
};

const getBieneFileOnS3 = (header, file_key) => {
  let params = {
    file_key: file_key,
  };

  return axios.get('getbienefileons3', { params, headers: header });
};

const getAllConsumptionComparisonTelemetry = (header, page_code, page_size) => {
  let params = {
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get('getallconsumptioncomparisontelemetry', {
    params,
    headers: header,
  });
};

const getFilteredBillItems = (header, filters) => {
  let params = {
    object: filters,
  };

  return axios.get('getilteredbillitems', { params, headers: header });
};

const getCognitoGroups = (header) => {
  return axios.get('listallcognitogroups', { headers: header });
};

const createCognitoGroup = (header, group_name) => {
  let data = {
    group_name: group_name,
  };

  return axios.post('createcognitogroup', data, { headers: header });
};

const listCognitoUsersAndGroups = (header) => {
  return axios.get('listusersandgroups', { headers: header });
};

const getUserByEmail = (header, email) => {
  let params = {
    email: email,
  };

  return axios.get('getuserbyemail', { params, headers: header });
};

const createAndRegisterUserToGroup = (header, user_name, email, group_name) => {
  let data = {
    user_name: user_name,
    email: email,
    group_name: group_name,
  };

  return axios.post('createandregisterusertogroup', data, { headers: header });
};

const changeCognitoUserGroup = (
  header,
  user_name,
  current_group,
  new_group
) => {
  let data = {
    user_name: user_name,
    current_group: current_group,
    new_group: new_group,
  };

  return axios.put('changecognitousergroup', data, { headers: header });
};

const addUserToCognitoGroup = (user_name, group_name) => {
  const url =
    'https://q9frd8joz6.execute-api.us-east-1.amazonaws.com/prod/addUserToCognitoGroup';

  return axios.post(url, { user_name, group_name });
};

const getAllSystemUrls = (header) => {
  return axios.get('getallsystemurls', {
    headers: header,
  });
};

export const ioseAPI = {
  getAllClients,
  getClient,
  createClient,
  deleteClient,
  updateClient,
  getAllAdmins,
  getAdmin,
  createAdmin,
  deleteAdmin,
  updateAdmin,
  getAllUnitys,
  getUnity,
  createUnity,
  deleteUnity,
  updateUnity,
  getAllEmployee,
  getEmployee,
  createManager,
  createOperator,
  deleteEmployee,
  updateEmployee,
  getNewCircuit,
  getAllCircuit,
  updateCircuit,
  removePropertyCircuit,
  deleteCircuit,
  updatePropertyCircuit,
  triggerCircuit,
  getAllGroup,
  createGroup,
  deleteGroup,
  updateGroup,
  getSchedulingGroup,
  getSchedulingModules,
  getSchedulingModulesByGroup,
  getSchedulingGroupByCircuit,
  createScheduling,
  includeScheduling,
  updateScheduling,
  deleteScheduling,
  deleteModuleSchedulings,
  retryCreateScheduling,
  retryUpdateScheduling,
  getAllAgents,
  getAllClientCircuits,
  getAllEnergyBill,
  getAllEnvironment,
  createEnvironment,
  updateEnvironment,
  getAllObjects,
  createObjects,
  updateObjects,
  createConcessionaires,
  getAllConcessionaires,
  updateConcessionaires,
  deleteConcessionaires,
  createConsumerGroup,
  deleteConsumerGroup,
  updateConsumerGroup,
  getAllConsumerGroup,
  createMapUrl,
  getAllMapUrl,
  updateMapUrl,
  getAllContracts,
  createContract,
  updateContract,
  deleteContract,
  getTariffsByConcessionaireAndYear,
  getTariffComponentsByConcessionaireAndYear,
  getAllConsumptionComparison,
  getAllUnityConsumption,
  getAllGroupsConsumption,
  getAllCircuitsConsumption,
  getAllBillValueAudits,
  getAllBills,
  getAllConsumerGroupV2,
  getAllIdentificatioNumberBills,
  getConsumerGroupByIdentificationNumber,
  createConsumerGroupV2,
  updateConsumerGroupV2,
  deleteConsumerGroupV2,
  getAllConsumptionResult,
  getAllExtractCalculated,
  getAllAudits,
  getAuditForReport,
  getAllSGFExports,
  getSGFForExport,
  getAllCreditGeneration,
  getAllCreditBeneficiary,
  getAllBIENEExports,
  getBIENEForExport,
  getAllSystemVariables,
  createSystemVariable,
  updateSystemVariable,
  deleteSystemVariable,
  getAllCreditConnections,
  createCreditConnections,
  getCreditByGeradoraAndMonth,
  getAllGenerationAccumulated,
  getAllBeneficiaryPerMonth,
  getAllCreditPercentage,
  getAllBeneficiaryJoin,
  getAllFaturasPaginas,
  getAllIdentificatioNumberFaturas,
  updateEnergyBillMeasurer,
  updateEnergyBillTaxes,
  updateEnergyBillLineItens,
  getUniqueAtividades,
  getUniqueConcessionaires,
  getUniqueUnityDescriptions,
  getUniqueRegionalManagement,
  getUniqueGeneralManagement,
  getFilteredConsumerGroups,
  getFilteredConsumptionResult,
  getCalculatedTotalFilteredConsumption,
  getCalculateTotalConsumption,
  getCalculateAllRMConsumption,
  getCalculatedRegionalFilteredConsumption,
  getCalculateAllGMConsumption,
  getCalculatedGeneralFilteredConsumption,
  getCalculateTotalBillValues,
  getCalculateFilteredTotalBillValues,
  getCalculateTotalExtract,
  getCalculateFilteredExtract,
  getAllSolarEcus,
  createSolarEcu,
  updateSolarEcu,
  deleteSolarEcu,
  getSummaryEnergyByEcuList,
  getAllEnergyBillLogs,
  getFilteredEnergyBillLogs,
  getAllSearchEnergyBill,
  getFilteredSearchEnergyBill,
  getUniqueBillAddress,
  getUniqueSearchClassification,
  getBillsByUUid,
  getAllTariffs,
  createTariff,
  getSsoToken,
  getAllUserLogs,
  createUserLog,
  getAllAuditDifference,
  getAllGroupPermissions,
  updateGroupPermissions,
  createGroupPermissions,
  createCarbonTariff,
  getCarbonTariff,
  createEnvironmentNew,
  uploadContractFile,
  deleteContractFile,
  getBieneFileOnS3,
  getAllConsumptionComparisonTelemetry,
  getFilteredBillItems,
  getCognitoGroups,
  createCognitoGroup,
  listCognitoUsersAndGroups,
  getUserByEmail,
  createAndRegisterUserToGroup,
  changeCognitoUserGroup,
  addUserToCognitoGroup,
  getAllSystemUrls,
};
