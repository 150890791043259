import { useState, useContext } from 'react';
import { Messages, ioseAPI, AccountContext } from '../common';
import { useQuery } from '@tanstack/react-query';

function useObjects() {
  const [noObjectsError, setNoObjectsError] = useState('');
  const { getSession } = useContext(AccountContext);
  const page_size = 300;

  const getObjects = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllObjects(headers, 10000, page_size);

    const objects = response.data.data || [];

    if (objects.length === 0) {
      setNoObjectsError(Messages.noRegisteredObjects);
      throw new Error(Messages.noRegisteredObjects);
    }

    return objects;
  };

  const {
    data: objects,
    error: objectsError,
    refetch: refreshObjects,
    isLoading,
  } = useQuery(['objectsData'], getObjects);

  // Geração do mapeamento dinâmico do 3D
  const device3dMapping = objects
    ?.filter((object) => object.id_3d && object.uuid_circuit)
    .map((object) => ({
      uuid_circuit: object.uuid_circuit,
      uuid_object: object.id_3d,
    }));

  return {
    objects,
    objectsError,
    noObjectsError,
    refreshObjects,
    isLoading,
    device3dMapping,
  };
}

export default useObjects;
