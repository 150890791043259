// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { ioseAPI, Messages, AccountContext } from 'common';
import { useQuery } from '@tanstack/react-query';

function useCreditConnections() {
  const [noCreditConnection, setNoCreditConnection] = useState('');

  const { getSession } = useContext(AccountContext);

  const getCreditConnection = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getAllCreditConnections(headers, 3000, 3000);

    const { data } = response.data;

    const creditConnectionData = data || [];

    if (creditConnectionData.length === 0) {
      setNoCreditConnection(Messages.noFindResults);
      throw new Error(Messages.noFindResults);
    }
    return creditConnectionData;
  };

  const {
    data: creditConnectionData,
    error: creditConnectionDataError,
    refetch: refreshCreditConnection,
    isLoading,
  } = useQuery(['creditConnection'], getCreditConnection);

  return {
    creditConnectionData,
    creditConnectionDataError,
    noCreditConnection,
    refreshCreditConnection,
    isLoading,
  };
}

export default useCreditConnections;
