import styled from 'styled-components';
import { Grid } from '@material-ui/core/';

export const ContainerCards = styled(Grid).attrs((props) => ({
  container: true,
  justify: 'center',
}))``;

export const WrapContainer = styled.div`
  padding: 0px 30px 5px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
