// hooks/useContractEffects.js
import { useState, useContext } from 'react';
import { AccountContext, Messages, ioseAPI } from 'common';
import { useQuery } from '@tanstack/react-query';

function useSummaryEnergyByEcuList() {
  const [noEnergyError, setNoEnergyError] = useState('');

  const { getSession } = useContext(AccountContext);

  const getSummaryEnergyByEcuList = async () => {
    const { headers } = getSession();

    const response = await ioseAPI.getSummaryEnergyByEcuList(headers);

    const energyByEcu = response?.data?.ecuList || [];

    if (energyByEcu.length === 0) {
      setNoEnergyError(Messages.noRegisteredEcus);
      throw new Error(Messages.noRegisteredEcus);
    }
    return energyByEcu;
  };

  const { data: energyByEcu, error: energyByEcuError, isLoading } = useQuery(
    ['energyByEcuData'],
    getSummaryEnergyByEcuList
  );

  return {
    energyByEcu,
    energyByEcuError,
    noEnergyError,
    isLoading,
  };
}

export default useSummaryEnergyByEcuList;
