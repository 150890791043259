import React, { useContext, useEffect, useState } from 'react';
import { Grow } from '@material-ui/core/';
import PropType from 'prop-types';

import * as S from './style';

import {
  IoseSubHeaderBar,
  IoseAddButton,
  IoseAlertLoadingCards,
  LoadingIndicator,
  IoseSelectReferenceMonth,
  IoseButton,
  IoseSelectCompleteDate,
  IoseDynamicFilter,
  IoseAddFilterButton,
  IoseItemBillSearchTable,
  IoseSearchMessage,
} from 'components';

import {
  useClient,
  LocalStorage,
  Permissions,
  AccountContext,
  ioseAPI,
} from 'common';
import { useConsumerGroup, useGroupPermissions } from 'hooks';

import { itens } from './data';

const formatToBrazilianDate = (date) => {
  if (date) {
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
    return `${month}/${year}`;
  }
  return '';
};

export default function SearchItemBillContainer() {
  const { client } = useClient();
  const { getSession } = useContext(AccountContext);
  const { groupPermissions } = useGroupPermissions();
  const userSession = LocalStorage.getSession();
  const { consumerGroups } = useConsumerGroup();

  const [clientData, setClientData] = useState({});
  const [error, setError] = useState('');
  const [group, setGroup] = useState();
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedRefMonth, setSelectedRefMonth] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  //eslint-disable-next-line
  const [energyBills, setEnergyBills] = useState();
  const [energyBillItems, setEnergyBillItems] = useState();
  const [filteredItems, setFilteredItems] = useState();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [consumerGroupOptions, setConsumerGroupOptions] = useState();
  const [hasReadPermission, setHasReadPermission] = useState(true);
  const [hasReportPermission, setHasReportPermission] = useState(true);
  const [referenteOptions, setReferenteOptions] = useState();
  const [
    selectedIdentificationNumber,
    setSelectedIdentificationNumber,
  ] = useState('');

  useEffect(() => {
    const readPath = ['relatorios', 'itens_fatura', 'leitura'];
    const reportPath = ['relatorios', 'itens_fatura', 'emitir_relatorio'];

    if (userSession && groupPermissions) {
      const readPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        readPath
      );
      setHasReadPermission(readPermission);

      const reportPermission = Permissions.hasPermissions(
        userSession,
        groupPermissions,
        reportPath
      );
      setHasReportPermission(reportPermission);
    }
  }, [userSession, groupPermissions]);

  useEffect(() => {
    getClientData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const consumerGroupOptions = consumerGroups?.map((consumerGroup) => ({
      value: consumerGroup?.identification_number,
      label: consumerGroup?.identification_number,
    }));

    setConsumerGroupOptions(consumerGroupOptions);
  }, [consumerGroups]);

  useEffect(() => {
    const uniqueValues = new Set();

    itens.forEach((item) => {
      uniqueValues.add(item.referente);
    });

    const options = Array.from(uniqueValues).map((valor) => ({
      value: valor,
      label: valor,
    }));

    setReferenteOptions(options);
  }, []);

  useEffect(() => {
    const filteredItems = energyBillItems?.filter(
      (item) => item.item_description_1 !== '0'
    );

    setFilteredItems(filteredItems);
  }, [energyBillItems]);

  const getClientData = async () => {
    setError('');

    const clientData = getClientDataByGroup(group);
    setClientData(clientData);

    const session = LocalStorage.getSession();
    setGroup(session.group);
  };

  const getClientDataByGroup = (group) => {
    if (group === 'super') {
      return {
        name: client.name,
        uuid_client: client.uuid_client,
      };
    } else {
      return LocalStorage.getClientData();
    }
  };

  const RenderFilterContainer = () => {
    const filterOptions = ['UC', 'Itens da fatura', 'REF: MÊS/ANO'];

    const toggleFilters = () => {
      setShowFilters(!showFilters);
    };

    const handleOptionClick = (option) => {
      setSelectedOption(option);
      setShowFilters(false);
    };

    const handleItemsChange = (value) => {
      setSelectedItem(value);
    };

    const handleRefMonthChange = (value) => {
      const formattedDate = formatToBrazilianDate(value);

      setSelectedDate(value);
      setSelectedRefMonth(formattedDate);
    };

    const handleIdentificationNumberChange = (value) => {
      setSelectedIdentificationNumber(value);
    };

    const renderSelectedComponent = () => {
      const filterOptions = {
        'REF: MÊS/ANO': {
          label: 'REF: MÊS/ANO',
          databaseName: 'reference_month',
          value: selectedRefMonth,
          onChange: handleRefMonthChange,
        },
        'Itens da fatura': {
          label: 'Itens da fatura',
          databaseName: 'referente',
          value: selectedItem,
          onChange: handleItemsChange,
          options: referenteOptions,
        },

        'UC': {
          label: 'UC',
          databaseName: 'identification_number',
          value: selectedIdentificationNumber,
          onChange: handleIdentificationNumberChange,
          options: consumerGroupOptions,
        },
      };

      const selectedFilter = filterOptions[selectedOption];

      const handleButtonClick = () => {
        if (selectedFilter && selectedFilter.databaseName) {
          const formattedFilter = { ...selectedFilters };

          if (selectedFilter.databaseName === 'previous_reading') {
            if (selectedFilter.initial_value && selectedFilter.final_value) {
              formattedFilter[
                'previous_reading_start'
              ] = selectedFilter.initial_value.toLocaleDateString();
              formattedFilter[
                'previous_reading_end'
              ] = selectedFilter.final_value.toLocaleDateString();
            }
          }
          if (selectedFilter.databaseName === 'vencimento') {
            if (selectedFilter.initial_value && selectedFilter.final_value) {
              formattedFilter[
                'vencimento_start'
              ] = selectedFilter.initial_value.toLocaleDateString();
              formattedFilter[
                'vencimento_end'
              ] = selectedFilter.final_value.toLocaleDateString();
            }
          } else {
            if (selectedFilter.value) {
              formattedFilter[selectedFilter.databaseName] =
                selectedFilter.value;
            }
          }

          setSelectedFilters(formattedFilter);
        }

        setShowFilters(false);
        setSelectedOption(null);
      };

      const handleRemoveFilter = (key) => {
        const updatedFilters = { ...selectedFilters };

        if (updatedFilters[key]) {
          delete updatedFilters[key];

          setSelectedFilters(updatedFilters);
        }
      };

      async function getEnergyBillsItems(object) {
        try {
          setLoading(true);
          const sessionData = await getSession();

          const response = await ioseAPI.getFilteredBillItems(
            sessionData.headers,
            object
          );

          const { data } = response.data;

          setEnergyBillItems(data);
          setSelectedFilters({});
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }

      return (
        <S.FilterBox>
          {selectedFilter && (
            <>
              {selectedFilter.label === 'REF: MÊS/ANO' ? (
                <IoseSelectReferenceMonth
                  label={selectedFilter.label}
                  handleChange={selectedFilter.onChange}
                  selectedDate={selectedDate}
                />
              ) : selectedFilter.label === 'Leitura anterior' ? (
                <div style={{ display: 'flex', margin: '0 0 0 15px' }}>
                  <IoseSelectCompleteDate
                    label="Data inicial"
                    handleChange={selectedFilter.initial_onChange}
                    selectedDate={selectedFilter.initial_value}
                  />

                  <IoseSelectCompleteDate
                    label="Data final"
                    handleChange={selectedFilter.final_onChange}
                    selectedDate={selectedFilter.final_value}
                  />
                </div>
              ) : selectedFilter.label === 'Vencimento' ? (
                <div style={{ display: 'flex', margin: '0 0 0 15px' }}>
                  <IoseSelectCompleteDate
                    label="Data inicial"
                    handleChange={selectedFilter.initial_onChange}
                    selectedDate={selectedFilter.initial_value}
                  />

                  <IoseSelectCompleteDate
                    label="Data final"
                    handleChange={selectedFilter.final_onChange}
                    selectedDate={selectedFilter.final_value}
                  />
                </div>
              ) : (
                <IoseDynamicFilter
                  label={selectedFilter.label}
                  value={selectedFilter.value}
                  onChange={selectedFilter.onChange}
                  options={selectedFilter.options}
                />
              )}
              <IoseButton onClick={handleButtonClick}>
                ADICIONAR FILTRO
              </IoseButton>
            </>
          )}

          <div style={{ marginLeft: '25px' }}>
            {Object.entries(selectedFilters).length > 0 && (
              <S.FilterCardContainer>
                <h3>Filtros Selecionados:</h3>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {Object.entries(selectedFilters).map(([key, value]) => (
                    <S.FilterCardDiv key={key}>
                      <S.FilterCardValue>{value}</S.FilterCardValue>
                      <S.CloseButton onClick={() => handleRemoveFilter(key)}>
                        <S.CloseCardIcon />
                      </S.CloseButton>
                    </S.FilterCardDiv>
                  ))}
                </div>
                <IoseButton
                  left={'15px'}
                  onClick={() => getEnergyBillsItems(selectedFilters)}
                >
                  BUSCAR FATURAS
                </IoseButton>
              </S.FilterCardContainer>
            )}
          </div>
        </S.FilterBox>
      );
    };

    return (
      <S.FilterContainer>
        <IoseAddFilterButton
          onClick={toggleFilters}
          tooltip={'ADICIONAR FILTRO'}
        />

        {showFilters && (
          <S.FilterBox>
            {filterOptions.map((option, index) => (
              <S.OptionsButton
                key={index}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </S.OptionsButton>
            ))}
          </S.FilterBox>
        )}

        {renderSelectedComponent()}
      </S.FilterContainer>
    );
  };

  const renderTable = () => {
    if (filteredItems) {
      return (
        <S.ContainerTable>
          <IoseItemBillSearchTable
            tableTitle=""
            searchInTable={false}
            data={filteredItems}
            loading={loading}
            onClickFunction={() => {}}
            hasReportPermission={hasReportPermission}
          />
        </S.ContainerTable>
      );
    } else {
      return (
        <IoseSearchMessage
          message={`Selecione os filtros de busca desejados e clique em "BUSCAR FATURAS".`}
          height="400px"
          width="400px"
        />
      );
    }
  };

  const renderSubHeaderAndSearchBar = () => {
    // const showButton = group === 'super' || group === 'admin' ? true : false;

    const showButton = false;

    const button = (
      <IoseAddButton onClick={() => {}} tooltip="CRIAR CONTRATO" top="26px" />
    );

    return (
      <IoseSubHeaderBar
        title={clientData.name}
        subtitle="Busca de itens da fatura"
        button={showButton ? button : <></>}
      />
    );
  };

  const renderContent = () => {
    if (error !== '') {
      return <IoseAlertLoadingCards text={error} />;
    } else {
      return (
        <>
          <div>{renderTable()}</div>
        </>
      );
    }
  };

  ////////////////// OPERATIONS //////////////////
  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      {hasReadPermission ? (
        <S.WrappedContainer>
          {renderSubHeaderAndSearchBar()}
          {RenderFilterContainer()}
          {loading ? (
            <LoadingIndicator loading={loading} />
          ) : hasReadPermission ? (
            renderContent()
          ) : (
            <IoseAlertLoadingCards
              text={
                'Seu grupo de usuários não tem permissão de leitura destes dados!'
              }
            />
          )}
        </S.WrappedContainer>
      ) : (
        <div style={{ marginTop: '100px' }}>
          <IoseAlertLoadingCards
            text={
              'Seu grupo de usuários não tem permissão de leitura destes dados!'
            }
          />
        </div>
      )}
    </Grow>
  );
}

SearchItemBillContainer.propTypes = {
  /** This props get a function to opne Sidebar*/
  openSide: PropType.func,
  /** This props get a boolean to identify when reloading cards*/
  getcards: PropType.bool,
  /** This props get a function to set getcards*/
  setGetcards: PropType.func,
  /** This props get a boolena to identify the end of infinity scroll*/
  empytArray: PropType.bool,
  /** This props get a function to set empytArray state*/
  setEmpytArray: PropType.func,
  /** This props get a group user */
  group: PropType.string,
  /** This props get a user key logged in */
  username: PropType.string,
};
