import ReactECharts from 'echarts-for-react';
import React from 'react';

export default function IoseAdmDashChartSolarEcu({ data }) {
  // Extrair as informações necessárias dos dados de entrada
  const yAxisData = data?.map((obj) => obj.ecu);
  const totalData = data?.map((obj) => parseFloat(obj.total));
  const monthData = data?.map((obj) => parseFloat(obj.month));
  const yearData = data?.map((obj) => parseFloat(obj.year));

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    toolbox: {
      show: true,
      feature: {
        magicType: { type: ['line', 'bar'] },
        saveAsImage: {},
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
      name: 'Energia Gerada (kWh)',
    },
    series: [
      {
        name: 'Total',
        type: 'bar',
        stack: 'total',
        // label: {
        //   show: true,
        // },
        emphasis: {
          focus: 'series',
        },
        data: totalData,
      },
      {
        name: 'Mês',
        type: 'bar',
        stack: 'total',
        // label: {
        //   show: true,
        // },
        emphasis: {
          focus: 'series',
        },
        data: monthData,
      },
      {
        name: 'Ano',
        type: 'bar',
        stack: 'total',
        // label: {
        //   show: true,
        // },
        emphasis: {
          focus: 'series',
        },
        data: yearData,
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: 360 }} />;
}
