import axios from 'axios';

const iotPath = process.env.REACT_APP_API_IOT_ENDPOINT;

// Iot
const getAllMeasure = (
  header,
  uuid_circuit,
  start_date,
  end_date,
  page_code,
  page_size
) => {
  let params = {
    uuid_circuit: uuid_circuit,
    start_date: start_date,
    end_date: end_date,
    page_code: page_code,
    page_size: page_size,
  };

  return axios.get(iotPath + '/getallmeasure', { params, headers: header });
};

const getCurrentMeasure = (header, uuid_circuit, start_date, end_date) => {
  let params = {
    uuid_circuit: uuid_circuit,
    start_date: start_date,
    end_date: end_date,
  };

  return axios.get(iotPath + '/getcurrentmeasure', { params, headers: header });
};

const getLastFlag = (header) => {
  return axios.get(iotPath + '/getlastflag', { headers: header });
};

const getLastMeasure = (header, uuid_circuit, start_date, end_date) => {
  let params = {
    uuid_circuit: uuid_circuit,
    start_date: start_date,
    end_date: end_date,
  };

  return axios.get(iotPath + '/getlastmeasure', { params, headers: header });
};

const getTariffBasedUnityType = (
  header,
  uuid_unity,
  circuits,
  startInterval,
  endInterval
) => {
  let circuitsString = JSON.stringify(circuits);

  let params = {
    uuid_unity: uuid_unity,
    circuits: circuitsString,
    startInterval: startInterval,
    endInterval: endInterval,
  };

  return axios.get(iotPath + '/getTariffBasedUnityType', {
    params,
    headers: header,
  });
};

export const ioseIotAPI = {
  getLastMeasure,
  getAllMeasure,
  getTariffBasedUnityType,
  getLastFlag,
  getCurrentMeasure,
};
